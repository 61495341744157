import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';

import { retrieveEntityPermissionsForVPAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { EntityPermissionVPActionParams } from 'services/api/permissions/permissionsServiceTypes';
import {
	isIdAutoGenerated,
	getCustodyTransfer
} from 'store/vesselProgramme/selectors';

export default function* rotationStepPermissionSaga(
	params: EntityPermissionVPActionParams,
	api: typeof Api.Permissions.retrieveEntityPermissionsForVP
): SagaIterator {
	const custodyTransfer = yield select(getCustodyTransfer);
	const request = {
		...params,
		optionalParams: {
			portCallId: params.optionalParams.portCallId,
			custodyTransfer: custodyTransfer ? custodyTransfer.linePosition : null
		}
	};
	if (isIdAutoGenerated(request.entityKey)) {
		yield put(
			retrieveEntityPermissionsForVPAsync.done({
				result: { canAdd: true, canUpdate: true, canDelete: true },
				params: request,
				response: null
			})
		);
		return;
	}
	yield put(retrieveEntityPermissionsForVPAsync.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			retrieveEntityPermissionsForVPAsync.done({
				result: response.data,
				params: request,
				response
			})
		);

		if (!response.data.canUpdate) {
			yield put(
				notify.warning(
					`POI can't be changed as it has documents associated with it. Please remove documents first in order to change POI.`
				)
			);
		}
	} catch (error) {
		yield put(
			retrieveEntityPermissionsForVPAsync.failed({
				error,
				params: request
			})
		);
	}
}
