import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { put, call } from 'redux-saga/effects';
import { DownloadFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import { downloadFinanceDocumentAsync as async } from '../actions';
import { downloadExecutor } from 'store/documents/sagas/pollDownloadUrlSaga';
import { getActivePortCallIdExecutor } from 'utils/sagaHelpers/portCallSagaExecutor';
import { notify } from 'store/notifications/actions';

const POSTApi = Api.Finance.downloadFinanceDocument;
const GETApi = Api.Finance.pollDownloadFinanceDocument;

/** Custom type to make 'portCallId' a required field */
interface DownloadFinanceDocumentWithPortCallRequest
	extends DownloadFinanceDocumentRequest {
	portCallId: string;
}

function* worker(params: DownloadFinanceDocumentRequest) {
	yield put(async.started(params));
	try {
		/** Get portCall.id from the store instead of params */
		const portCallId: string = yield call(getActivePortCallIdExecutor, params);

		const payload: DownloadFinanceDocumentWithPortCallRequest = {
			...params,
			portCallId
		};

		yield put(notify.info('Your download is in progress...'));

		yield call(downloadExecutor, {
			params: payload,
			POSTApi,
			GETApi,
			isMultiFileDownload: params.isMultiFileDownload
		});

		yield put(async.done({ params, result: null, response: null }));
	} catch (error) {
		yield put(
			async.failed({
				params,
				error
			})
		);
	}
}

export default makeTakeLatestWatcher<
	DownloadFinanceDocumentRequest,
	null,
	Error
>({ async }, worker);
