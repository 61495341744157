import {
	VesselProgrammeState,
	UpdateCanalRequest
} from '../vesselProgrammeState';

export const onUpdateCanal = (
	state: VesselProgrammeState,
	{ rotationStepId, canal }: UpdateCanalRequest
): VesselProgrammeState => ({
	...state,
	edited: true,
	rotationStepsById:
		rotationStepId in state.rotationStepsById
			? {
					...state.rotationStepsById,
					[rotationStepId]: {
						...state.rotationStepsById[rotationStepId],
						canal,
						poiId: canal
							? canal.id
							: state.rotationStepsById[rotationStepId].poiId
					}
				}
			: state.rotationStepsById
});
