import actionCreator from '../financeActionCreator';
import {
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse
} from 'services/api/finance/financeServiceTypes';

const ACTION_NAME = 'RETRIEVE_DA_STATUS_HISTORY_BY_ID';

export type RetrieveDAStatusHistoryByIdParams = Pick<
	RetrieveDAStatusHistoryByIdRequest,
	'daId'
>;

export const retrieveDAStatusHistoryByIdAsync = actionCreator.async<
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse,
	Error
>(ACTION_NAME);

export const retrieveDAStatusHistoryById = actionCreator<
	RetrieveDAStatusHistoryByIdParams
>(ACTION_NAME);
