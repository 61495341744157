import { PortCallState as State } from '../portCallState';
import {
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse,
	PortCallMetadataActionCode
} from 'services/api/portCall/portCallServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrievePortCallMetadataSuccess = (
	state: State,
	portCallId: string,
	actions: PortCallMetadataActionCode[]
): State => ({
	...state,
	metadata: {
		...state.metadata,
		[portCallId]: actions
	}
});

export const onRetrievePortCallMetadata = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse
>('fetchStatuses', 'metadata', {
	doneReducer: (
		state: State,
		{
			params: { portCallId },
			result: { actions }
		}: Success<
			RetrievePortCallMetadataRequest,
			RetrievePortCallMetadataResponse
		>
	): State => onRetrievePortCallMetadataSuccess(state, portCallId, actions)
});
