import React, { FC } from 'react';
import { Icon } from 'components/antd';

const YesOrNoMark: FC<{ isSuccess?: boolean }> = ({ isSuccess }) => {
	return isSuccess ? (
		<Icon type="check" color="success" />
	) : (
		<Icon type="close" color="error" />
	);
};

export default YesOrNoMark;
