import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import Config from '../config';
import { store } from 'redux/store';
import { LabeledValueItem } from 'app-types';
import { AppState } from 'store-types';
import { OidcUser } from 'store/auth/authTypes';
import { getAuth, getAuthActAs } from 'store/auth/selectors';
import Matomo from 'services/analytics/adapters/Matomo';
import {
	getErrorCorrelationId,
	isResponseError,
	errorStatusCode
} from 'services/api/apiErrorUtils';

const client = axios.create({
	baseURL: Config.apiBaseUrl
});

client.interceptors.request.use((config: AxiosRequestConfig) => {
	const state: AppState | undefined = store.getState();
	let auth: OidcUser | null = null;
	let authActAs: LabeledValueItem | null = null;
	if (state) {
		auth = getAuth(state);
		authActAs = getAuthActAs(state);
	}
	return new Promise(resolve => {
		if (auth) {
			config.headers.Authorization = `Bearer ${auth.access_token}`;
			config.headers.tsid = auth.profile.sid;
		}
		if (authActAs) {
			config.headers['X-ActAs'] = authActAs.key;
		}
		resolve(config);
	});
});

client.interceptors.response.use(
	response => {
		return response;
	},
	(error: AxiosError) => {
		// don't track errors related to connection
		if (!isResponseError(error)) {
			return;
		}
		const { config } = error;

		const method = config.method?.toUpperCase();
		const url = config.url?.replace(client.defaults.baseURL || '', '');
		const status = errorStatusCode(error);
		const correlationId = getErrorCorrelationId(error) || '--';

		if (method && status && url) {
			Matomo.trackEvent({
				category: 'SystemEvent',
				action: `${method} ${status} ${url}`,
				name: `Correlation ID: ${correlationId ?? '--'}`,
				value: 1
			});
		}
		return Promise.reject(error);
	}
);

// eslint-disable-next-line
const mockifier = require('../api/mockifier').default;
mockifier(client);

export default client;
