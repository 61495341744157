import {
	VesselProgrammeState,
	EventsById,
	OperationUnitsById
} from 'store/vesselProgramme/vesselProgrammeState';
import { pickBy } from 'lodash';

export const onUnallocateOperationUnit = ({
	operationUnitsById,
	draggedOperationUnitId,
	rotationStepsById,
	...state
}: VesselProgrammeState): VesselProgrammeState => {
	const rotationStepId =
		operationUnitsById[draggedOperationUnitId]?.allocatedId;
	const rotationStep = rotationStepsById[rotationStepId];
	// more often than not this happens because the user dragged an unallocated cargo unit
	// straight into the deallocation pool.
	// Stopping the dragging by hand prevents inconsistent state later
	if (!rotationStep) {
		return {
			...state,
			operationUnitsById,
			draggedOperationUnitId,
			rotationStepsById
		};
	}
	const rotationStepsMinusActiveOperation = {
		...rotationStepsById,
		[rotationStepId]: {
			...rotationStep,
			units: rotationStep.units?.filter(unit => unit !== draggedOperationUnitId)
		}
	};
	const eventsForDraggedUnit =
		operationUnitsById[draggedOperationUnitId]?.events;
	const eventsById = pickBy<EventsById>(
		state.eventsById,
		item => !eventsForDraggedUnit.includes(item.id)
		/**
		 * TODO
		 * TS upgrade
		 */
	) as EventsById;

	// unallocated cargo should be placed on top
	const updatedOperationUnitsById: OperationUnitsById = {
		...operationUnitsById
	};
	// Doing this because FE does not have all the necessary data to sort unallocated cargoes,
	// so we are keeping sorted them in the way they comes from back-end.
	updatedOperationUnitsById[draggedOperationUnitId] = {
		...operationUnitsById[draggedOperationUnitId],
		allocatedId: '',
		events: []
	};

	return {
		...state,
		edited: true,
		rotationStepsById: rotationStepsMinusActiveOperation,
		draggedOperationUnitId: '',
		eventsById,
		operationUnitsById: updatedOperationUnitsById
	};
};
