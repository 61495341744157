import { AppState } from 'store-types';
import { getValues } from 'store/form/selectors';
import { getIsLoaded } from 'store/selectors';
import {
	PRE_REGISTRATION_FORM_NAME,
	PreRegistrationFormData
} from '../registrationTypes';

export const getRetrievePreRegistrationAgentCompanyFetchStatus = (
	state: AppState
) => state.registration.fetchStatuses.retrievePreRegistrationAgentCompany;

export const getPreRegistrationVerifyFetchStatus = (state: AppState) =>
	state.registration.fetchStatuses.preRegisterVerify;

export const getPreRegistrationAgentCompany = (state: AppState) =>
	state.registration.preRegistration.agentCompany;

export const getPreRegistrationUserEmail = (state: AppState) =>
	state.registration.preRegistration.userEmail;

export const getIsPreRegistered = (state: AppState) =>
	getIsLoaded(state.registration.fetchStatuses.preRegister);

export const getPreRegistrationFormValues = (state: AppState) =>
	getValues<PreRegistrationFormData>(state, PRE_REGISTRATION_FORM_NAME);
