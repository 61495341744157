import { first, isEmpty } from 'lodash';
import runValidations, { required } from 'utils/validations';

import {
	FormData,
	FormDataErrors
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

const validate = (values: FormData): FormDataErrors => {
	if (!isEmpty(values.bunkeringOperationGrades)) {
		return {};
	}
	return {
		// `Details` field should be mandatory if no grades are added.
		details: first(runValidations(values.details, [required]))
	};
};

export default validate;
