import actionCreator from '../vesselsActionCreator';
import {
	RetrieveCompanyVesselRequest,
	VesselDetails
} from 'services/api/vessels/vesselsServiceTypes';

const ACTION_NAME = 'RETRIEVE_VESSEL_EXTEND';

export const retrieveVesselExtendAsync = actionCreator.async<
	RetrieveCompanyVesselRequest,
	VesselDetails,
	Error
>(ACTION_NAME);

export const retrieveVesselExtend = actionCreator<RetrieveCompanyVesselRequest>(
	ACTION_NAME
);
