import { CreateFeedbackManagementRequest } from 'services/api/companies/companiesServiceTypes';
import {
	deleteAllRequestFeedbackFileInfo,
	retrieveFeedbackManagementGrid,
	updateFeedbackManagementRequestAsync
} from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getCreateFeedbackManagementRequest } from '../selectors';
import { select, put } from 'redux-saga/effects';
import { closeModal } from 'store/modals/actions';
import { REQUEST_FEEDBACK_MODAL } from 'sections/Administration/Configuration/FeedbackManagement/FeedbackManagementTypes';

export default makeTakeLatestWatcher<
	CreateFeedbackManagementRequest,
	null,
	Error
>({
	api: Api.Companies.updateFeedbackManagementRequest,
	async: updateFeedbackManagementRequestAsync,
	*getApiParams() {
		const params = yield select(getCreateFeedbackManagementRequest);
		return params;
	},
	*onSuccess() {
		const params = yield select(getCreateFeedbackManagementRequest);
		yield put(closeModal(`${REQUEST_FEEDBACK_MODAL}-${params.feedbackId}`));
		yield put(deleteAllRequestFeedbackFileInfo());
		yield put(retrieveFeedbackManagementGrid());
	}
});
