import { Success } from 'typescript-fsa';
import { SupplierInvoiceState } from '../supplierInvoiceState';
import {
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse,
	SupplierInvoiceRow
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { keyBy } from 'lodash';

export const onRetrieveSupplierInvoicesFailure = (
	state: SupplierInvoiceState
) => ({
	...state,
	hasMore: false
});

export const onRetrieveSupplierInvoicesSuccess = (
	state: SupplierInvoiceState,
	action: Success<
		RetrieveSupplierInvoicesParams,
		RetrieveSupplierInvoicesResponse
	>
) => ({
	...state,
	byId: {
		...(!action.params.preventConcatExistingInvoices && state.byId),
		...keyBy<SupplierInvoiceRow>(action.result.elements, element => element.id)
	},
	hasMore: action.params.limit === action.result.elements.length
});

export const onRetrieveSupplierInvoices = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse
>('fetchStatuses', 'all', {
	doneReducer: onRetrieveSupplierInvoicesSuccess,
	failedReducer: onRetrieveSupplierInvoicesFailure
});
