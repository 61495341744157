import { ThreadsState, initialState } from '../threadsState';
import { EditComposeMessageMetaData } from 'services/api/threads/threadsServiceTypes';

export const onSaveEditThreadData = (
	state: ThreadsState,
	editThreadData: EditComposeMessageMetaData
): ThreadsState => ({
	...state,
	editThreadData: {
		...state.editThreadData,
		...editThreadData
	}
});

export const onResetEditThreadData = (state: ThreadsState): ThreadsState => ({
	...state,
	editThreadData: initialState.editThreadData
});
