import React from 'react';
import { connect } from 'react-redux';

import DocumentViewerContainer from 'sections/DocumentViewer/DocumentViewerContainer';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import Contextualization from './Contextualization';

import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';

import { PortCallTabBaseProps } from 'sections/PortCall';
import { BreadcrumbItem } from 'store/navigation';
import { AppState } from 'store-types';

interface ContextualizationRouteProps extends PortCallTabBaseProps {
	breadcrumbs: BreadcrumbItem[];
}
class ContextualizationRoute extends React.Component<
	ContextualizationRouteProps
> {
	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([
			{
				title: 'Upload Documentation'
			}
		]);

	render() {
		const {
			match: { params: { portCallId, portJobCode, documentId } }
		} = this.props;
		return (
			<DocumentViewerContainer match={this.props.match}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<Contextualization
					portCallId={portCallId}
					documentId={documentId}
					jobCode={portJobCode}
				/>
			</DocumentViewerContainer>
		);
	}
}

export default connect((state: AppState) => ({
	breadcrumbs: getPortCallLayoutBreadcrumbs(state)
}))(ContextualizationRoute);
