import {
	Company,
	MainPrincipalTypeCodes
} from 'services/api/companies/companiesServiceTypes';
import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';

export const PAGE_1_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE = [
	FormFieldName.JOB_TYPE_ID,
	FormFieldName.VESSEL,
	FormFieldName.PORT,
	FormFieldName.ETA_PLT,
	FormFieldName.ETS_PLT
];

export const PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE = [
	FormFieldName.SHOW_ALL,
	FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL,
	FormFieldName.APPOINTER_COMPANY,
	FormFieldName.CONTACT_1,
	FormFieldName.CONTACT_2,
	FormFieldName.AGENT_ROLE,
	FormFieldName.PAYING_PARTY // is pre populated by default with value from the `APPOINTER_COMPANY` field or 'NOMINATOR' field based on jobtype
];

export const PAGE_2_FIELDS_TO_RESET_ON_JOBTYPE_CHANGE = [
	...PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE,
	FormFieldName.NOMINATOR,
	FormFieldName.CUSTOMER_REF,
	FormFieldName.AGENCY_TYPE,
	FormFieldName.PERFORMING_AGENT,
	FormFieldName.CONTROLLING_AGENT
];

export const prepareHubPrincipalOptions = (
	companies: Company[],
	isRemoveCLSCompany = false
) =>
	companies.reduce((options, company) => {
		if (
			company.isReadyToGo &&
			(!isRemoveCLSCompany ||
				company.mainPrincipalType.code !== MainPrincipalTypeCodes.CLS)
		) {
			return [
				...options,
				{
					name: company.companyName,
					id: company.id,
					key: company.id
				}
			];
		}
		return options;
	}, []);
