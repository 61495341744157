import actionCreator from '../wizardFormActionCreator';

export interface WizardFormSetOriginUrl {
	name?: string;
	originUrl: string;
}

const SET_ORIGIN_URL = 'SET_ORIGIN_URL';
export const setOriginUrl = actionCreator<WizardFormSetOriginUrl>(
	SET_ORIGIN_URL
);
