import * as React from 'react';
import classNames from 'classnames';
import styles from './Progress.module.scss';
import AntProgress, {
	ProgressProps as AntPopoverProps
} from 'antd/lib/progress';

export class Progress extends React.Component<AntPopoverProps> {
	render() {
		return (
			<AntProgress
				{...this.props}
				className={classNames(styles.root, this.props.className)}
			/>
		);
	}
}

export default Progress;
