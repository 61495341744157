import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './portCallOperationsState';

import {
	resetPortCallSections,
	retrievePortCallSectionsAsync,
	updatePortCallEventAsync,
	showPortCallEventDeleteModal,
	hidePortCallEventDeleteModal,
	retrievePortCallEventTemplatesAsync,
	resetPortCallEventTemplates,
	setPortCallEventAsNotHappened,
	removePortCallEventFromList,
	addPortCallEventAsync,
	retrievePortCallAccessiblePortJobsAsync,
	resetPortCallAccessiblePortJobs,
	updatePortCallEventJobAssignmentAsync,
	resetPortCallEventAsync,
	updatePortCallEventFromFinanceAsync
} from './actions';
import {
	onRetrievePortCallSectionsStarted,
	onRetrievePortCallSectionsSuccess,
	onRetrievePortCallSectionsFailed,
	onResetPortCallSections,
	onUpdatePortCallEventStarted,
	onUpdatePortCallEventSuccess,
	onUpdatePortCallEventFailed,
	onHideDeleteModal,
	onShowDeleteModal,

	// Retrieve Event Templates
	onRetrievePortCallEventTemplatesStarted,
	onRetrievePortCallEventTemplatesSuccess,
	onRetrievePortCallEventTemplatesFailed,
	onResetPortCallEventTemplates,
	onResetPortCallEvent,

	// Add Port Call Event
	onAddPortCallEventStarted,
	onAddPortCallEventSuccess,
	onAddPortCallEventFailed,
	onSetPortCallEventAsNotHappened,
	onRemovePortCallEventFromList,

	// Get accessible PortJobs
	onRetrievePortCallAccessiblePortJobsStarted,
	onRetrievePortCallAccessiblePortJobsSuccess,
	onRetrievePortCallAccessiblePortJobsFailed,
	onResetPortCallAccessiblePortJobs,

	// Update Port Call Event job assignment
	onUpdatePortCallEventJobAssignmentStarted,
	onUpdatePortCallEventJobAssignmentSuccess,
	onUpdatePortCallEventJobAssignmentFailed,
	onUpdatePortCallEventFromFinanceStarted,
	onUpdatePortCallEventFromFinanceSuccess,
	onUpdatePortCallEventFromFinanceFailed
} from './reducers';
import { onSetPortCallEventAsHappened } from './reducers/setPortCallEventAsHappened';
import { setPortCallEventAsHappened } from './actions/restorePortCallEvent';

export default reducerWithInitialState(initialState)
	.case(
		retrievePortCallSectionsAsync.started,
		onRetrievePortCallSectionsStarted
	)
	.case(retrievePortCallSectionsAsync.done, onRetrievePortCallSectionsSuccess)
	.case(retrievePortCallSectionsAsync.failed, onRetrievePortCallSectionsFailed)
	.case(resetPortCallSections, onResetPortCallSections)
	.case(updatePortCallEventAsync.started, onUpdatePortCallEventStarted)
	.case(updatePortCallEventAsync.done, onUpdatePortCallEventSuccess)
	.case(updatePortCallEventAsync.failed, onUpdatePortCallEventFailed)
	.case(showPortCallEventDeleteModal, onShowDeleteModal)
	.case(hidePortCallEventDeleteModal, onHideDeleteModal)
	.case(setPortCallEventAsNotHappened, onSetPortCallEventAsNotHappened)
	.case(removePortCallEventFromList, onRemovePortCallEventFromList)
	.case(setPortCallEventAsHappened, onSetPortCallEventAsHappened)
	// Retrieve Event Templates
	.case(
		retrievePortCallEventTemplatesAsync.started,
		onRetrievePortCallEventTemplatesStarted
	)
	.case(
		retrievePortCallEventTemplatesAsync.done,
		onRetrievePortCallEventTemplatesSuccess
	)
	.case(
		retrievePortCallEventTemplatesAsync.failed,
		onRetrievePortCallEventTemplatesFailed
	)
	.case(resetPortCallEventTemplates, onResetPortCallEventTemplates)
	.case(resetPortCallEventAsync.done, onResetPortCallEvent)
	.case(addPortCallEventAsync.started, onAddPortCallEventStarted)
	.case(addPortCallEventAsync.done, onAddPortCallEventSuccess)
	.case(addPortCallEventAsync.failed, onAddPortCallEventFailed)
	.case(
		retrievePortCallAccessiblePortJobsAsync.started,
		onRetrievePortCallAccessiblePortJobsStarted
	)
	.case(
		retrievePortCallAccessiblePortJobsAsync.done,
		onRetrievePortCallAccessiblePortJobsSuccess
	)
	.case(
		retrievePortCallAccessiblePortJobsAsync.failed,
		onRetrievePortCallAccessiblePortJobsFailed
	)
	.case(resetPortCallAccessiblePortJobs, onResetPortCallAccessiblePortJobs)
	.case(
		updatePortCallEventJobAssignmentAsync.started,
		onUpdatePortCallEventJobAssignmentStarted
	)
	.case(
		updatePortCallEventJobAssignmentAsync.done,
		onUpdatePortCallEventJobAssignmentSuccess
	)
	.case(
		updatePortCallEventJobAssignmentAsync.failed,
		onUpdatePortCallEventJobAssignmentFailed
	)
	.case(
		updatePortCallEventFromFinanceAsync.started,
		onUpdatePortCallEventFromFinanceStarted
	)
	.case(
		updatePortCallEventFromFinanceAsync.done,
		onUpdatePortCallEventFromFinanceSuccess
	)
	.case(
		updatePortCallEventFromFinanceAsync.failed,
		onUpdatePortCallEventFromFinanceFailed
	);
