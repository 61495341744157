import { BreadcrumbItem } from 'store/navigation/navigationState';
import { PATHS } from 'sections/App/RouteParams';
import { createSelector } from 'reselect';
import { getActiveSupplierInvoiceId } from 'store/supplierInvoice/selectors';

export const getSupplierInvoiceBreadcrumbs = createSelector(
	getActiveSupplierInvoiceId,
	(supplierInvoiceId): BreadcrumbItem[] => {
		const breadcrumbs = [
			{
				title: 'Supplier Invoices',
				link: `/${PATHS.supplierinvoices}`
			}
		];

		if (supplierInvoiceId) {
			return [
				...breadcrumbs,
				{
					title: 'Supplier Invoice',
					link: `/${PATHS.supplierinvoices}/${supplierInvoiceId}`
				}
			];
		}

		return breadcrumbs;
	}
);
