import React, { FC } from 'react';
import { Tooltip } from 'components/antd';
import { TooltipProps } from 'antd/lib/tooltip';
import { Permission } from 'utils/components';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { PermissionsCheckMode, hasPermissionsConsideringCheckMode } from '..';
import styles from './Wrapper.module.scss';

export interface PermissionWrapperProps {
	permissionCode: PermissionCode | PermissionCode[];
	permissionsCheckMode?: PermissionsCheckMode;
	message?: string;
	tooltip?: Partial<TooltipProps>;
	children: (hasPermission: boolean) => React.ReactNode;
}

export const PermissionWrapper: FC<PermissionWrapperProps> = ({
	permissionCode,
	permissionsCheckMode = PermissionsCheckMode.AND,
	message = `You don't have permission to perform this action`,
	tooltip = {},
	children
}) => (
	<Permission permissionCode={permissionCode}>
		{(...hasPermissions) => {
			const hasPermission = hasPermissionsConsideringCheckMode(
				hasPermissions,
				permissionsCheckMode
			);
			return !hasPermission ? (
				<Tooltip {...tooltip} title={message} trigger="hover">
					<span className={styles.wrapper}>{children(hasPermission)}</span>
				</Tooltip>
			) : (
				<>{children(hasPermission)}</>
			);
		}}
	</Permission>
);
