import { Success } from 'typescript-fsa';
import { ConfigurationState } from '../configurationState';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { RetrieveCompaniesForOrganisationResponse } from 'services/api/companies/companiesServiceTypes';

export const onRetrieveConfigurationCompaniesSuccess = (
	state: ConfigurationState,
	{
		result
	}: Success<PermissionCode[], RetrieveCompaniesForOrganisationResponse>
) => ({
	...state,
	companies: result.elements
});
