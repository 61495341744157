import { DeleteTimeSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import actionCreator from '../savingsActionCreator';

const DELETE_TIME_SAVINGS = 'DELETE_TIME_SAVINGS';

export const deleteTimeSavingsAsync = actionCreator.async<
	DeleteTimeSavingsRequest,
	undefined,
	Error
>(DELETE_TIME_SAVINGS);

export const deleteTimeSavings = actionCreator<DeleteTimeSavingsRequest>(
	DELETE_TIME_SAVINGS
);
