import { RegistrationState } from '../registrationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PreRegisterRequest } from 'services/api/registration/registrationServiceTypes';

export const onPreRegister = makeFetchStatusReducers<
	RegistrationState,
	'fetchStatuses',
	PreRegisterRequest,
	null
>('fetchStatuses', 'preRegister');
