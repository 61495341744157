import React from 'react';
import { connect } from 'react-redux';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';

import { retrieveMainCommodities } from 'store/commodities/actions';
import { retrieveUnits } from 'store/units';
import { retrieveCountries } from 'store/countries';
import { retrieveBunkerGrades } from 'store/operations';

import OperationsConfig from './OperationsConfig';

interface OperationsProps {
	operationTypeCode: OperationTypeCode;
	// from mapDispatchToProps
	retrieveMainCommodities: typeof retrieveMainCommodities;
	retrieveUnits: typeof retrieveUnits;
	retrieveCountries: typeof retrieveCountries;
	retrieveBunkerGrades: typeof retrieveBunkerGrades;
}

class Operations extends React.Component<OperationsProps> {
	componentDidMount() {
		this.props.retrieveMainCommodities();
		this.props.retrieveUnits();
		this.props.retrieveBunkerGrades();
		this.props.retrieveCountries(undefined);
	}

	render() {
		const { operationTypeCode } = this.props;

		const operationConfig = OperationsConfig[operationTypeCode] || {};

		const OperationForm = operationConfig.component;

		if (OperationForm) {
			return <OperationForm />;
		}
		return null;
	}
}

export default connect(null, {
	retrieveMainCommodities,
	retrieveUnits,
	retrieveCountries,
	retrieveBunkerGrades
})(Operations);
