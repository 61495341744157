import * as React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import {
	getServicesControlTotal,
	getAddSupplierInvoiceCurrencyDecimalPlaces
} from 'store/supplierInvoice/selectors/supplierInvoicesSelectors';
import { AppState } from 'store-types';
import { Flex, Amount } from 'components';
import Gap from 'components/Styles/Gap/Gap';
import Text from 'components/Styles/Text/Text';
import { Button } from 'components/antd/Button/Button';
import { Justify } from 'components/types';
import { getIsCreditInvoice } from 'store/form/supplierInvoiceContextualization/selectors';
import {
	FormData,
	FormField
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import InvoiceDetailsSearchJob from './InvoiceDetailsSearchJob/InvoiceDetailsSearchJob';
import styles from './InvoiceDetails.module.scss';
import InvoiceDetailsFieldArray from './InvoiceDetailsFieldArray';

type InvoiceDetailsFormSectionProps = ReturnType<typeof mapStateToProps> & {
	activePage: number;
	change: InjectedFormProps['change'];
	retrievePortJobs: (isFromRefresh: boolean) => void;
	formValues: FormData;
	isCredit: boolean;
};

const InvoiceDetailsFormSection: React.SFC<InvoiceDetailsFormSectionProps> = ({
	retrievePortJobs,
	controlTotal,
	activePage,
	change,
	formValues,
	isCredit,
	supplierInvoiceCurrencyDecimalPlaces
}) => (
	<div className={styles.wrapper}>
		<hr className={styles.topBorder} />
		<Gap bottom="sm" top="sm" isBlock>
			<Flex justify={Justify.BETWEEN} align="center">
				<div>
					<Gap right="md">
						<Text weight="semi-bold">Invoice Details</Text>
					</Gap>
					<Button
						icon="refresh"
						transparent
						type="primary"
						onClick={() => retrievePortJobs(true)}
					>
						Refresh
					</Button>
				</div>
				<span>
					<span>Control Total: </span>
					<Text weight="bold">
						<Amount
							value={controlTotal}
							formatType="finance"
							negative={isCredit}
							decimalPart={supplierInvoiceCurrencyDecimalPlaces}
						/>
					</Text>
				</span>
			</Flex>
		</Gap>
		<InvoiceDetailsFieldArray
			name={FormField.PAGES}
			activePage={activePage}
			formValues={formValues}
			change={change}
		/>
		<InvoiceDetailsSearchJob />
	</div>
);

const mapStateToProps = (state: AppState) => ({
	controlTotal: getServicesControlTotal(state),
	isCredit: getIsCreditInvoice(state),
	supplierInvoiceCurrencyDecimalPlaces: getAddSupplierInvoiceCurrencyDecimalPlaces(
		state
	)
});

export default connect(mapStateToProps)(InvoiceDetailsFormSection);
