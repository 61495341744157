import { Metadata, MetadataAction } from 'app-types';
import {
	FinanceStage,
	FinanceTransaction,
	Invoice
} from '../finance/financeServiceTypes';

export enum FundingType {
	PREFUNDING = 'Prefunding',
	SETTLEMENT = 'Settlement',
	CUSTOMER_PREFUNDING = 'CustomerPrefunding'
}

export enum FundingRequestStatus {
	PENDING_REVIEW = 'PendingReview',
	REJECTED = 'Rejected',
	REVIEWED = 'Reviewed',
	SENT_FOR_PAYMENT = 'SentForPayment',
	COMPLETE = 'Complete',
	FAILED = 'Failed',
	PENDING = 'Pending',
	CANCELLED = 'Cancelled',
	PARTIAL_FUNDS_ACCEPTED = 'PartialFundsAccepted',
	FUNDS_REQUESTED = 'FundsRequested',
	PARTIALLY_FUNDED = 'PartiallyFunded'
}

export enum FundingRequestMetadataKey {
	COMMENT = 'comment',
	FUNDING_AMOUNT = 'fundingAmount',
	FUNDING_PERCENT = 'fundingPercent',
	URGENT = 'isUrgent'
}

export enum FundingColumnsMetadataKey {
	TYPE_CODE = 'typeCode',
	AMOUNT = 'amount',
	AMOUNT_FROM_PRINCIPAL = 'amountFromPrincipal',
	BANK_ACCOUNT_FX = 'bankAccountFX',
	CURRENCY = 'currencyCode',
	DA_IN = 'daInId',
	DA_OUT = 'daOutId',
	CREATED_DATE = 'createdDateUTC'
}

export enum FundingRequestMetadataActionCode {
	CREATE = 'create',
	APPROVE = 'approve',
	REJECT = 'reject'
}

export type FundingRequestMetadataAction = MetadataAction<
	FundingRequestMetadataActionCode
>;

export enum BaseAmountTypeCodes {
	DA_IN_AMOUNT_WITHOUT_AGENCY_COSTS = 'DaInAmountWithoutAgencyCosts',
	DA_IN_AMOUNT = 'DaInAmount'
}

export interface PrefundRequest {
	daOutId: string;
	totalAmount: string;
	balanceAmount: string;
	disbursementAccountLabel: string;
	selectDA: boolean;
	jobStatus?: FinanceStage;
}

export interface FundingRequest extends PrefundRequest {
	id: string;
	type: FundingType;
	fundingAmount: string;
	fundingPercent: string;
	isUrgent: boolean;
	comment: string;
	requestDate: string;
	daInBankDetailId: string;
	daInId: string;
	currencyCode: string;
	daInAmountWithoutAgencyCosts: number;
	daInAmount: number;
	processedByUserName?: string;
	status: FundingRequestStatus;
	metadata: Metadata<FundingRequestMetadataActionCode>;
	transactions: FinanceTransaction[];
	baseAmountTypeCode: BaseAmountTypeCodes;
	currentFundingSentOrRequested: number;
	balanceForDaInAmount: number;
	balanceForDaInAmountWithoutAgencyCosts: number;
	fundingReceived: string;
	fundingReceivedPercent: string;
	daOutAmount: string;
}

export interface UpdatePrefundingStatusPayload {
	fundingRequestId: string;
	comments: string;
	status: string;
}

export interface UpdatePrefundingStatusRequest
	extends UpdatePrefundingStatusPayload {
	jobCode: string;
	portCallId: string;
}

export interface PrefundingsRequest {
	portCallId: string;
	jobCode: string;
	selectedStatus: string;
	sendPrefunding: boolean;
	portName: string;
	vesselName: string;
	fundingRequestUpdateFundingRequestList: FundingRequest[];
}

export enum FundingTypeCode {
	IN_SETTLEMENT = 'InSettlement',
	IN_ADVANCE = 'InAdvance',
	OUT_ADVANCE = 'OutAdvance',
	OUT_SETTLEMENT = 'OutSettlement',
	SUPPLIER_INVOICE = 'SupplierInvoice'
}

export interface Funding {
	id: string;
	currencyCode: string;
	amount: number;
	amountFromPrincipal?: number;
	bankAccountFX?: number;
	bankAccountCurrencyCode?: string;
	typeCode: FundingTypeCode;
	daInId?: string;
	daOutId?: string;
	metadata: Metadata<FundingRequestMetadataActionCode>;
	transactions?: FinanceTransaction[];
	createdDateUTC?: string;
	invoices?: Invoice[];
}

interface FundingUpdate {
	id: string;
	amount: number;
	typeCode: string;
	currencyCode: string;
	daInId?: string;
	daOutId?: string;
	metadata: Metadata<FundingRequestMetadataActionCode>;
}

export interface FundingRequestUpdate {
	id: string;
	fundingAmount: number;
	fundingPercent: string;
	isUrgent: boolean;
	comment: string;
	baseAmountTypeCode: string;
	fundsRequestedSent: number;
	fundsRequestedBalanceAmount: number;
}

/**
 * Funding Request API interfaces
 */
interface CommonFundingRequest {
	portCallId: string;
	jobCode: string;
	fundingId?: string;
	daId?: string;
}

export interface RetrieveFundingRequestRequest {
	portCallId: string;
	jobCode: string;
	fundingId: string;
}
export type RetrieveFundingRequestResponse = FundingRequest;

export type RetrieveFundingRequestsPageDataRequest = CommonFundingRequest;
export interface RetrieveNewFundingDataRequest {
	portCallId: string;
	jobCode: string;
	daId: string;
}

export interface RetrieveNewFundingDataResponse {}

export interface UpdateFundingRequestRequest extends CommonFundingRequest {
	funding?: FundingRequestUpdate;
	action: FundingRequestMetadataActionCode;
}

export interface UpdateFundingRequestResponse {
	// returns true if ETA >= 5 days before vessel arrives
	arrivalDateExceedsThreshold: boolean;
}

interface CreateFundingRequest {
	fundingAmount: string;
	fundingPercent: string;
	daInId: string;
	isUrgent: boolean;
	comment: string;
	action: FundingRequestMetadataActionCode;
}

export interface CreateNewFundingRequestRequest {
	portCallId: string;
	jobCode: string;
	action: FundingRequestMetadataActionCode;
	request: CreateFundingRequest;
}

export interface CreateNewFundingRequestResponse {}

/**
 * Funding API interfaces
 */
export interface UpdateFundingRequest extends FundingUpdate {
	id: string;
	jobCode: string;
	portCallId: string;
}

export interface UpdateFundingResponse {}

export enum DisbursementAccountTypeCode {
	DA_IN = 'DaIn',
	DA_OUT = 'DaOut'
}

export interface PrefundingDAs {
	id: string;
	currencyCode: string;
	totalAmountInUSD: number;
	totalAmount: string;
	balanceAmount: string;
	financeStage: FinanceStage;
	disbursementAccountTypeCode: DisbursementAccountTypeCode;
	disbursementAccountNumber: string;
	disbursementAccountLabel: string;
}

export interface RetrievePrefundingRequest {
	portCallId: string;
	jobCode: string;
}

export interface PrefundingRequest {
	disbursementAccounts: PrefundingDAs[];
	isPDAAvailable: boolean;
	isCEAvailable: boolean;
	isDAAvailable: boolean;
}

export interface RetrievePrefundingRequestResponse {}
