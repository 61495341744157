import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
	retrieveCompaniesForUser,
	retrieveCompaniesForUserAsync
} from 'store/companies/actions/retrieveCompaniesForUser';
import { RetrieveCompaniesForUserRequest } from 'services/api/companies/companiesServiceTypes';
import { getCompaniesForUserCount } from '../selectors';

import { Action } from 'typescript-fsa';
import Api from 'services/api';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

const apiCall = Api.Companies.retrieveCompaniesForUser;
export function* retrieveCompaniesForUserExecutor(
	payload: RetrieveCompaniesForUserRequest,
	api: typeof apiCall
) {
	const count = yield select(getCompaniesForUserCount);

	const request: RetrieveCompaniesForUserRequest = {
		limit: DEFAULT_LIST_LIMIT,
		index: count,
		sortBy: 'name:asc',
		...payload
	};

	yield put(retrieveCompaniesForUserAsync.started(request));

	try {
		const response = yield call(api, request);
		yield put(
			retrieveCompaniesForUserAsync.done({
				result: response.data,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveCompaniesForUserAsync.failed({
				error,
				params: request
			})
		);
	}
}

function* worker({ payload }: Action<RetrieveCompaniesForUserRequest>) {
	yield call(retrieveCompaniesForUserExecutor, payload, apiCall);
}

export default function*() {
	yield takeEvery(retrieveCompaniesForUser.type, worker);
}
