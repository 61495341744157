import { Success } from 'typescript-fsa';
import { CompaniesState } from '../companiesState';
import {
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export function onRetrieveCompanyJobTypesSuccess(
	state: CompaniesState,
	{
		result
	}: Success<RetrieveCompanyJobTypesRequest, RetrieveCompanyJobTypesResponse>
): CompaniesState {
	return {
		...state,
		jobTypes: [...result.jobTypes]
	};
}

export const onRetrieveCompanyJobTypes = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse
>('fetchStatuses', 'retrieveCompanyJobTypes', {
	doneReducer: onRetrieveCompanyJobTypesSuccess
});
