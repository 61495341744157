import { Modal } from 'components/antd';
import React, { FC, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getFormValues, isPristine } from 'redux-form';
import { AppState } from 'store-types';
import { closeModal, openModal } from 'store/modals/actions';
import {
	updateThreadsCategorize,
	resetThreadsGroupsAndTags
} from 'store/threads/actions';
import { isModalVisible } from 'store/modals/selectors';
import {
	ThreadsSettingsMenuModalMap,
	ThreadsSettingsMenuAction
} from '../constants';
import CategorizeMsgClosePopUpModal from './CategorizeMsgClosePopUpModal';
import CategorizeMsgModalForm from './CategorizeMsgModalForm';
import {
	CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL,
	CATEGORIZE_MSG_MODAL_FORM,
	ThreadsCatergorizeMsgFormData
} from './CategorizeMsgModalFormTypes';
import { IdField } from 'app-types';
import { isUpdateCategorizePending } from 'store/threads/selectors';

export interface ThreadCategorizeMessagesModalProps {
	threadIds: IdField[];
	mainPrincipalId: string | null;
}

const ThreadCategorizeMessagesModal: FC<ThreadCategorizeMessagesModalProps> = props => {
	const dispatch = useDispatch();

	const isFormValid = useSelector(isPristine(CATEGORIZE_MSG_MODAL_FORM));
	const isCategorizePending = useSelector(isUpdateCategorizePending);

	const isVisible = useSelector((state: AppState) =>
		isModalVisible(
			state,
			ThreadsSettingsMenuModalMap[ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES]
		)
	);
	const formValues = useSelector(
		getFormValues(CATEGORIZE_MSG_MODAL_FORM),
		shallowEqual
	) as ThreadsCatergorizeMsgFormData;

	const { threadContextId, status, codes, jobcodes } = formValues || {};

	const closeCatergorizeModal = () => {
		dispatch(
			closeModal(
				ThreadsSettingsMenuModalMap[
					ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES
				]
			)
		);
		dispatch(resetThreadsGroupsAndTags());
	};

	const onSubmit = useCallback(() => {
		dispatch(
			updateThreadsCategorize({
				threadIds: props.threadIds.map(({ id }) => id),
				status: status || '',
				threadContextId: threadContextId || '',
				codes: codes,
				jobcodes: jobcodes
			})
		);
	}, [codes, dispatch, jobcodes, props.threadIds, status, threadContextId]);

	const isDisabled = !isFormValid
		? !codes?.length && !jobcodes?.length && !threadContextId && !status
		: true;

	const onCancelModal = () => {
		isDisabled
			? closeCatergorizeModal()
			: dispatch(openModal(CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL));
	};

	return (
		<>
			<Modal
				destroyOnClose
				title="Categorize Messages"
				visible={isVisible}
				onCancel={onCancelModal}
				okDisabled={isDisabled || isCategorizePending}
				onOk={onSubmit}
				width={390}
			>
				<CategorizeMsgModalForm mainPrincipalId={props.mainPrincipalId} />
			</Modal>
			<CategorizeMsgClosePopUpModal />
		</>
	);
};

export default ThreadCategorizeMessagesModal;
