import * as React from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading/Loading';
import SubHeader from 'sections/PortJob/SubHeader/SubHeader';
import PortCallInfo from 'sections/PortJob/SubHeader/Info/PortCallInfo';
import PortJobInfo from 'sections/PortJob/SubHeader/Info/PortJobInfo';
import {
	isPortJobSummaryFetching,
	getPortJobSummary,
	PortJobSummary
} from 'store/portJobs/portJobsSelectors';
import { Col, Row } from 'components/antd';
import { AppState } from 'store-types';

interface OutsideProps {
	portCallId: string;
	portJobCode: string;
}

interface InsideProps {
	isPortJobDetailsHidden?: boolean;
	title?: string;
	// from mapStateToProps
	isFetching: boolean;
	summary: PortJobSummary | null;
}

type FullProps = OutsideProps & InsideProps;

class Header extends React.Component<FullProps> {
	render() {
		const {
			isFetching,
			summary,
			isPortJobDetailsHidden,
			title = 'Operation Details'
		} = this.props;
		if (!summary) {
			return <Loading show={isFetching} />;
		}
		return (
			<SubHeader title={title}>
				<Row>
					<Col md={6} lg={5}>
						<PortCallInfo
							vesselName={summary.vesselName}
							portName={summary.portName}
							etaPlt={summary.etaPlt}
							etsPlt={summary.etsPlt}
						/>
					</Col>
					{!isPortJobDetailsHidden && (
						<Col md={6} lg={5}>
							<PortJobInfo
								jobTypeName={summary.jobTypeName}
								performingAgentLabel={summary.performingAgentLabel}
							/>
						</Col>
					)}
				</Row>
			</SubHeader>
		);
	}
}

export default connect((state: AppState) => ({
	isFetching: isPortJobSummaryFetching(state),
	summary: getPortJobSummary(state)
}))(Header);
