import { FinanceState } from 'store/finance/financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CancelServiceTransactionRequest } from 'services/api/finance/financeServiceTypes';

export const onCancelServiceTransaction = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	CancelServiceTransactionRequest,
	null
>('fetchStatuses', 'cancelServiceTransaction');
