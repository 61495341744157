import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './portCallDocumentsState';

import {
	retrieveRequiredDocumentsAsync,
	resetRequiredDocuments,
	retrieveAllowedDocumentsAsync,
	retrieveUploadedDocumentsAsync,
	editUploadedDocumentCommentAsync,
	resetUploadedDocuments,
	createPortCallDocumentAsync,
	deletePortCallDocumentAsync,
	markPortCallDocumentNotIssuedAsync,
	editNotIssuedPortCallDocumentAsync,
	downloadPortCallDocumentsAsync,
	retrievePortCallDocumentAsync,
	deletePortCallDocumentPageAsync
} from './actions';

import {
	// Required Documents
	onRetrieveRequiredDocumentsStarted,
	onRetrieveRequiredDocumentsDone,
	onRetrieveRequiredDocumentsFailed,
	onResetRequiredDocuments,

	// Allowed Documents
	onRetrieveAllowedDocumentsStarted,
	onRetrieveAllowedDocumentsDone,
	onRetrieveAllowedDocumentsFailed,

	// Uploaded Documents
	onRetrieveUploadedDocumentsStarted,
	onRetrieveUploadedDocumentsSuccess,
	onRetrieveUploadedDocumentsFailed,
	onResetUploadedDocuments,

	// Edit Uploaded Document Comment
	onEditUploadedDocumentCommentStarted,
	onEditUploadedDocumentCommentSuccess,
	onEditUploadedDocumentCommentFailed,

	// Create Document
	onCreatePortCallDocumentStarted,
	onCreatePortCallDocumentSuccess,
	onCreatePortCallDocumentFailed,

	// Mark Document Not Issued
	onMarkPortCallDocumentNotIssuedSuccess,

	// Edit Not Issued Document
	onEditNotIssuedPortCallDocumentSuccess,

	// Delete Document
	onDeletePortCallDocumentStarted,
	onDeletePortCallDocumentSuccess,
	onDeletePortCallDocumentFailed,

	// Delete Document Page
	onDeletePortCallDocumentPageStarted,
	onDeletePortCallDocumentPageSuccess,
	onDeletePortCallDocumentPageFailed,

	// Download Document
	onDownloadPortCallDocumentStarted,
	onDownloadPortCallDocumentSuccess,
	onDownloadPortCallDocumentFailed,

	// Retrieve A PortCallDocument
	onRetrievePortCallDocumentStarted,
	onRetrievePortCallDocumentSuccess,
	onRetrievePortCallDocumentFailed
} from './reducers';

export default reducerWithInitialState(initialState)
	// Required Documents
	.case(
		retrieveRequiredDocumentsAsync.started,
		onRetrieveRequiredDocumentsStarted
	)
	.case(retrieveRequiredDocumentsAsync.done, onRetrieveRequiredDocumentsDone)
	.case(
		retrieveRequiredDocumentsAsync.failed,
		onRetrieveRequiredDocumentsFailed
	)
	.case(resetRequiredDocuments, onResetRequiredDocuments)
	// Allowed Documents
	.case(
		retrieveAllowedDocumentsAsync.started,
		onRetrieveAllowedDocumentsStarted
	)
	.case(retrieveAllowedDocumentsAsync.done, onRetrieveAllowedDocumentsDone)
	.case(retrieveAllowedDocumentsAsync.failed, onRetrieveAllowedDocumentsFailed)
	// Uploaded Documents
	.case(
		retrieveUploadedDocumentsAsync.started,
		onRetrieveUploadedDocumentsStarted
	)
	.case(retrieveUploadedDocumentsAsync.done, onRetrieveUploadedDocumentsSuccess)
	.case(
		retrieveUploadedDocumentsAsync.failed,
		onRetrieveUploadedDocumentsFailed
	)
	.case(resetUploadedDocuments, onResetUploadedDocuments)
	// Edit Uploaded Document Comment
	.case(
		editUploadedDocumentCommentAsync.started,
		onEditUploadedDocumentCommentStarted
	)
	.case(
		editUploadedDocumentCommentAsync.done,
		onEditUploadedDocumentCommentSuccess
	)
	.case(
		editUploadedDocumentCommentAsync.failed,
		onEditUploadedDocumentCommentFailed
	)
	// Create Document
	.case(createPortCallDocumentAsync.started, onCreatePortCallDocumentStarted)
	.case(createPortCallDocumentAsync.done, onCreatePortCallDocumentSuccess)
	.case(createPortCallDocumentAsync.failed, onCreatePortCallDocumentFailed)
	// Mark Document Not Issued
	.case(
		markPortCallDocumentNotIssuedAsync.done,
		onMarkPortCallDocumentNotIssuedSuccess
	)
	// Delete Document
	.case(deletePortCallDocumentAsync.started, onDeletePortCallDocumentStarted)
	.case(deletePortCallDocumentAsync.done, onDeletePortCallDocumentSuccess)
	.case(deletePortCallDocumentAsync.failed, onDeletePortCallDocumentFailed)
	// Delete Document Page
	.case(
		deletePortCallDocumentPageAsync.started,
		onDeletePortCallDocumentPageStarted
	)
	.case(
		deletePortCallDocumentPageAsync.done,
		onDeletePortCallDocumentPageSuccess
	)
	.case(
		deletePortCallDocumentPageAsync.failed,
		onDeletePortCallDocumentPageFailed
	)
	// Edit Not Issued Document
	.case(
		editNotIssuedPortCallDocumentAsync.done,
		onEditNotIssuedPortCallDocumentSuccess
	)
	// Download Document
	.case(
		downloadPortCallDocumentsAsync.started,
		onDownloadPortCallDocumentStarted
	)
	.case(downloadPortCallDocumentsAsync.done, onDownloadPortCallDocumentSuccess)
	.case(downloadPortCallDocumentsAsync.failed, onDownloadPortCallDocumentFailed)
	// Retrieve A PortCallDocument
	.case(
		retrievePortCallDocumentAsync.started,
		onRetrievePortCallDocumentStarted
	)
	.case(retrievePortCallDocumentAsync.done, onRetrievePortCallDocumentSuccess)
	.case(retrievePortCallDocumentAsync.failed, onRetrievePortCallDocumentFailed);
