import * as React from 'react';
import { Select } from 'components/antd';
import { getMainPrincipalThreadTags } from 'store/threads/selectors';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { TagType } from 'services/api/threads/threadsServiceTypes';
import styles from '../CategorizeMsgModalForm.module.scss';
import { change } from 'redux-form';
import {
	CATEGORIZE_MSG_MODAL_FORM,
	ThreadsCatergorizeMsgFormFields
} from '../CategorizeMsgModalFormTypes';

interface ThreadTagsProps {
	tags: TagType[] | undefined;
	change: typeof change;
}

class Tags extends React.Component<ThreadTagsProps> {
	handleChange = (values: string[]) => {
		const { change, tags } = this.props;
		const codes: string[] = tags
			? tags.filter(tag => values.includes(tag.name)).map(tag => tag.code)
			: [];

		change(
			CATEGORIZE_MSG_MODAL_FORM,
			`${ThreadsCatergorizeMsgFormFields.THREAD_TAGS}`,
			codes
		);
	};
	userSelectedTags = () => {
		const { tags } = this.props;
		return tags ? tags.filter(tag => tag.isSelected).map(tag => tag.name) : [];
	};

	getSelectOptions = () => {
		const { tags } = this.props;
		return tags
			? tags.map(tag => (
					<Select.Option key={tag.code} value={tag.name}>
						{tag.name}
					</Select.Option>
			  ))
			: null;
	};
	render() {
		const selectedTags = this.userSelectedTags();
		return (
			<section className={styles.tags}>
				<span>Tags</span>
				<Select
					className={styles.selectSpan}
					mode="multiple"
					defaultValue={selectedTags}
					placeholder="Type to select the Tags"
					onChange={this.handleChange}
				>
					{this.getSelectOptions()}
				</Select>
			</section>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			tags: getMainPrincipalThreadTags(state)
		};
	},
	{
		change
	}
)(Tags);
