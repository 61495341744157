import {
	RetrievePortCallEventTemplatesRequest,
	RetrievePortCallEventTemplatesResponse
} from 'services/api/portCall/portCallServiceTypes';

import actionCreator from '../portCallOperationsActionCreator';

const RETRIEVE_EVENT_TEMPLATES = 'RETRIEVE_EVENT_TEMPLATES';
export const retrievePortCallEventTemplatesAsync = actionCreator.async<
	RetrievePortCallEventTemplatesRequest,
	RetrievePortCallEventTemplatesResponse,
	Error
>(RETRIEVE_EVENT_TEMPLATES);

export const retrievePortCallEventTemplates = actionCreator<
	RetrievePortCallEventTemplatesRequest
>(RETRIEVE_EVENT_TEMPLATES);

export const resetPortCallEventTemplates = actionCreator<string>(
	'RESET_EVENT_TEMPLATES'
);
