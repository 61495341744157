import { Action } from 'typescript-fsa';
import { call, put, takeLatest, take } from 'redux-saga/effects';

import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';

import {
	openModal,
	closeModal,
	closeAllModals,
	ModalParams
} from 'store/modals/actions';
import {
	deleteConfigurationFile,
	deleteConfigurationFileAsync,
	openConfigurationFileDeleteModal
} from '../actions';

import { DeleteCompanyTemplateFileRequest } from 'services/api/templates/templatesServiceTypes';

export const OPEN_MODAL_PAYLOAD: ModalParams = {
	name: 'DELETE',
	type: 'confirm',
	data: {
		title: 'Delete File Mapping',
		description: 'Are you sure you want to delete this file mapping?'
	}
};

export function* worker({ payload }: Action<DeleteCompanyTemplateFileRequest>) {
	yield put(openModal(OPEN_MODAL_PAYLOAD));

	const action = yield take(closeModal.type);

	if (!action.payload.isConfirmed) {
		return;
	}

	yield put(deleteConfigurationFile(payload));
	yield call(waitAsyncResolve, deleteConfigurationFileAsync);
	yield put(closeAllModals());
}

export default function*() {
	yield takeLatest(openConfigurationFileDeleteModal.type, worker);
}
