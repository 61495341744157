import { Success, Failure } from 'typescript-fsa';
import { PortCallOperationsState } from '../portCallOperationsState';
import { FetchStatus } from 'services/api/apiTypes';
import { RetrievePortCallAccessiblePortJobsResponse } from 'services/api/portCall/portCallServiceTypes';

export const onRetrievePortCallAccessiblePortJobsStarted = (
	state: PortCallOperationsState,
	action: string
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		accessiblePortJobs: {
			...state.fetchStatuses.accessiblePortJobs,
			[action]: FetchStatus.PENDING
		}
	}
});

export const onRetrievePortCallAccessiblePortJobsSuccess = (
	state: PortCallOperationsState,
	{
		params: portCallId,
		result
	}: Success<string, RetrievePortCallAccessiblePortJobsResponse>
): PortCallOperationsState => {
	return {
		...state,
		accessiblePortJobs: {
			...state.accessiblePortJobs,
			[portCallId]: result.jobs
		},
		fetchStatuses: {
			...state.fetchStatuses,
			accessiblePortJobs: {
				...state.fetchStatuses.accessiblePortJobs,
				[portCallId]: FetchStatus.SUCCESS
			}
		}
	};
};

export const onRetrievePortCallAccessiblePortJobsFailed = (
	state: PortCallOperationsState,
	action: Failure<string, Error>
): PortCallOperationsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		accessiblePortJobs: {
			...state.fetchStatuses.accessiblePortJobs,
			[action.params]: FetchStatus.FAILURE
		}
	}
});

export const onResetPortCallAccessiblePortJobs = (
	state: PortCallOperationsState,
	portCallId: string
): PortCallOperationsState => ({
	...state,
	accessiblePortJobs: {
		...state.accessiblePortJobs,
		[portCallId]: []
	},
	fetchStatuses: {
		...state.fetchStatuses,
		accessiblePortJobs: {
			[portCallId]: FetchStatus.IDLE
		}
	}
});
