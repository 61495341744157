import client from 'services/client';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import queryString from 'query-string';

import {
	AddGroupRequest,
	AddOrDeleteGroupUserRequest,
	DeleteGroupRequest,
	Group,
	GroupScopePermissions,
	GroupType,
	GroupUserSearch,
	RetrieveGroupScopePermissionsRequest,
	RetrieveGroupsRequest,
	RetrieveGroupTypesRequest,
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	SearchGroupUsersRequest,
	UpdateGroupRequest,
	CheckGroupPermissionRequest,
	CheckGroupPermissionResponse,
	GroupSearchByType,
	GroupSearchResultCollection,
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse,
	ThirdPartyLPAUserInvite,
	ThirdPartyLPAUserInviteResponse
} from './groupsServiceTypes';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';

const retrieveGroups = (
	request: RetrieveGroupsRequest
): AxiosTypedPromise<ResultCollection<Group>> => {
	return client.get('/groups', {
		params: request,
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});
};

const retrieveGroup = (id: string): AxiosTypedPromise<Group> => {
	return client.get(`/groups/${id}`);
};

const retrieveGroupTypes = (
	{ organisationType }: RetrieveGroupTypesRequest = { organisationType: 'HUB' }
): AxiosTypedPromise<ResultCollection<GroupType>> =>
	client.get(`/group-types?organisationType=${organisationType}`);

const addGroup = (request: AddGroupRequest) => {
	return client.post('/groups', request);
};

const updateGroup = (groupId: string, request: UpdateGroupRequest) => {
	return client.put(`/groups/${groupId}`, request);
};

const checkGroupPermission = (request: CheckGroupPermissionRequest) => {
	return client.get<CheckGroupPermissionResponse>('/groups/check-permission', {
		params: request
	});
};

const retrieveUsersFromGroup = ({
	id: groupId,
	onlyInThisGroup,
	...request
}: RetrieveGroupUsersRequest): AxiosTypedPromise<RetrieveGroupUsersResponse> => {
	return client.get(`/groups/${groupId}/users`, {
		params: {
			...request,
			onlyInThisGroup
		}
	});
};

const searchGroupUsers = (
	groupId: string,
	request: SearchGroupUsersRequest
): AxiosTypedPromise<ResultCollection<GroupUserSearch>> => {
	return client.get(`/groups/${groupId}/user-search`, {
		params: request
	});
};

const addGroupUser = ({
	groupId,
	userId
}: AddOrDeleteGroupUserRequest): AxiosTypedPromise<void> => {
	return client.post(`/groups/${groupId}/users`, {
		userId,
		groupId
	});
};

const deleteGroup = ({ id }: DeleteGroupRequest) => {
	return client.delete(`/groups/${id}`);
};

const deleteGroupUser = ({
	groupId,
	userId
}: AddOrDeleteGroupUserRequest): AxiosTypedPromise<void> => {
	return client.delete(`/groups/${groupId}/users/${userId}`);
};

const retrieveGroupScopePermissions = ({
	groupId,
	scopeType
}: RetrieveGroupScopePermissionsRequest): AxiosTypedPromise<ResultCollection<
	GroupScopePermissions
>> => {
	return client.get(`/groups/${groupId}/available-data-permissions`, {
		params: {
			scopeType
		}
	});
};

const searchGroups = (
	searchTerm: string,
	showMoreInGroup: GroupSearchByType | null
) => {
	const request: SearchGroupUsersRequest = {
		searchTerm,
		showMore: false
	};
	if (showMoreInGroup !== null) {
		request.showMore = true;
		request.groupType = showMoreInGroup;
	}
	return client.get<{ groups: GroupSearchResultCollection[] }>(
		`/groups/search`,
		{
			params: request
		}
	);
};

const searchGroupsForAutocomplete = (
	searchTerm: string,
	groupType?: GroupSearchByType | null
) =>
	searchEntitiesForAutocompleteSearch<GroupSearchByType | null>(
		searchGroups,
		searchTerm,
		groupType
	);

const searchThirdPartyLPAGroups = (
	request: RetrieveThirdPartyLpaGroupsRequest
) => {
	return client.get<RetrieveThirdPartyLpaGroupsResponse>(
		'groups/third-party-lpas',
		{ params: request }
	);
};

const deleteThirdPartyGroupUser = ({
	groupId,
	userId
}: AddOrDeleteGroupUserRequest) => {
	return client.delete(`/groups/third-party-lpas/${groupId}/users/${userId}`);
};

const searchThirdPartyLPAGroupsAutoComplete = () =>
	searchThirdPartyLPAGroups({}).then((response): AutocompleteOption[] =>
		response.data.elements.map(group => ({ id: group.id, name: group.name }))
	);

const sendThirdPartyLPAUserInvitation = (request: ThirdPartyLPAUserInvite) => {
	return client.post<ThirdPartyLPAUserInviteResponse>(
		'/users/third-party-lpas/invite',
		request
	);
};

export default {
	checkGroupPermission,
	retrieveGroups,
	retrieveGroupTypes,
	retrieveGroup,
	retrieveUsersFromGroup,
	addGroup,
	updateGroup,
	deleteGroupUser,
	searchGroupUsers,
	addGroupUser,
	deleteGroup,
	retrieveGroupScopePermissions,
	searchGroups,
	searchGroupsForAutocomplete,
	searchThirdPartyLPAGroups,
	deleteThirdPartyGroupUser,
	searchThirdPartyLPAGroupsAutoComplete,
	sendThirdPartyLPAUserInvitation
};
