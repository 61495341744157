import * as React from 'react';
import { Icon } from 'components/antd';
import portCallStyles from '../../PortCalls.module.scss';
import { AlertMetricType } from 'app-constants';

interface JobFundingIconProps {
	hasAlert: AlertMetricType[];
}

const JobFundingIcon: React.SFC<JobFundingIconProps> = ({
	hasAlert,
	children
}) => {
	const alert = (data: AlertMetricType[]) => {
		if (
			data.includes(AlertMetricType.FUNDS_REQUESTED_FROM_PRINCIPAL) &&
			data.includes(AlertMetricType.FUNDING_REQUEST_PENDING)
		) {
			return (
				<Icon
					color="warning"
					type="dollar-in-out"
					className={portCallStyles.dollarInOut}
				/>
			);
		} else if (data.includes(AlertMetricType.FUNDS_REQUESTED_FROM_PRINCIPAL)) {
			return (
				<Icon
					color="warning"
					type="dollar-in"
					className={portCallStyles.dollarIn}
				/>
			);
		} else if (data.includes(AlertMetricType.FUNDING_REQUEST_PENDING)) {
			return (
				<Icon
					color="warning"
					type="dollar-out"
					className={portCallStyles.dollarOut}
				/>
			);
		}
		return '';
	};
	return (
		<div className={portCallStyles.tagWrapper}>
			{alert(hasAlert)}
			{children}
		</div>
	);
};

export default JobFundingIcon;
