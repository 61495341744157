import { CreateFeedbackManagementRequest } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onCreateFeedbackManagementRequest = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	CreateFeedbackManagementRequest,
	null
>('fetchStatuses', 'createFeedbackManagementStatus');
