import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import styles from '../../Contextualization.module.scss';
import { Text, FileIcon } from 'components';
import { Checkbox, Row, Col } from 'components/antd';
import Category from '../components/Category';
import CategoryServices from '../components/CategoryServices';

import {
	getContextualizationDAByIdSelection,
	getContextualizationServicesByIdSelection,
	getCanContextualizeFinanceDocumentCoverSheetById,
	getCanContextualizeFinanceDocumentServicesById
} from 'store/form/financeContextualization/selectors';

import { EntitiesSelectMap } from 'app-types';
import { Justify } from 'components/types';
import { FormField } from 'store/form/financeContextualization/financeContextualizationFormData';
import { FinanceContextualizationDisbursementAccount } from 'services/api/finance/financeServiceTypes';
import { AppState } from 'store-types';

interface FormPageContentOwnProps {
	field: string;
	data: FinanceContextualizationDisbursementAccount;
}
interface FormPageContentProps extends FormPageContentOwnProps {
	daSelection: EntitiesSelectMap<number>;
	servicesSelection: EntitiesSelectMap<number>;
	isCoverSheetContextualizationEnabled: boolean;
	isServicesContextualizationEnabled: boolean;
}
const FormPageContent: React.SFC<FormPageContentProps> = ({
	field,
	data: { id, serviceCategories, currency },
	daSelection,
	servicesSelection,
	isCoverSheetContextualizationEnabled,
	isServicesContextualizationEnabled
}) => {
	const total = daSelection[id];
	return (
		<div>
			<Row
				className={classNames(styles.coverSheet, {
					[styles.coverSheetDisabled]: !isCoverSheetContextualizationEnabled
				})}
			>
				<Col sm={8}>
					<Checkbox.ReduxFormItem
						name={`${field}.${FormField.DAS}.${id}`}
						disabled={!isCoverSheetContextualizationEnabled}
					>
						<Text weight="normal">Cover Sheet</Text>
					</Checkbox.ReduxFormItem>
				</Col>
				<Col sm={4} justify={Justify.END}>
					{total > 0 && <FileIcon count={total} />}
				</Col>
			</Row>
			{serviceCategories.map((category, categoryIndex) => (
				<Category key={categoryIndex} name={category.name}>
					<CategoryServices
						services={category.services}
						field={field}
						currency={currency}
						servicesSelection={servicesSelection}
						disabled={!isServicesContextualizationEnabled}
					/>
				</Category>
			))}
		</div>
	);
};

export default connect(
	(state: AppState, ownProps: FormPageContentOwnProps) => ({
		daSelection: getContextualizationDAByIdSelection(state),
		servicesSelection: getContextualizationServicesByIdSelection(state),
		isCoverSheetContextualizationEnabled: getCanContextualizeFinanceDocumentCoverSheetById(
			state,
			ownProps.data.id
		),
		isServicesContextualizationEnabled: getCanContextualizeFinanceDocumentServicesById(
			state,
			ownProps.data.id
		)
	})
)(FormPageContent);
