import actionCreator from '../configurationFilesActionCreator';

import {
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse
} from 'services/api/templates/templatesServiceTypes';

const ACTION_NAME = 'RETRIEVE_CONFIGURATION_FILES';

export const retrieveConfigurationFiles = actionCreator<
	RetrieveCompanyTemplateFilesRequest
>(ACTION_NAME);
export const retrieveConfigurationFilesAsync = actionCreator.async<
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse,
	Error
>(ACTION_NAME);

export const resetConfigurationFiles = actionCreator(`RESET_${ACTION_NAME}`);
