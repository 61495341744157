import * as React from 'react';
import H from 'history';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Loading } from 'components';
import { ApmTracker, MatomoTracker } from 'containers';
import { checkAuth } from 'store/auth/actions';
import { RouteComponentProps } from 'react-router';
import {
	isAuthenticationInProgressSelector,
	isUserAuthenticated
} from 'store/auth/selectors';
import { AppState } from 'store-types';
import IdleTimer from './IdleTimer';
import AppErrorBoundary from './AppErrorBoundary';

interface PrivateRouteOwnProps {
	component: React.ComponentType<RouteComponentProps<{}>>;
	redirect: string;
}
interface PrivateRouteProps extends PrivateRouteOwnProps {
	// from mapStateToProps
	isAuthenticationInProgress: boolean;
	isAuthenticated: boolean;
	location?: H.Location;
	// from mapDispatchToProps
	checkAuth: typeof checkAuth;
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
	componentDidMount() {
		this.props.checkAuth();
	}

	renderComponent = (props: RouteComponentProps<{}>) => {
		const Component = this.props.component;
		return (
			<ApmTracker>
				<MatomoTracker>
					<IdleTimer>
						<Component {...props} />
					</IdleTimer>
				</MatomoTracker>
			</ApmTracker>
		);
	};

	redirect = () => {
		return (
			<Redirect
				to={{
					pathname: this.props.redirect,
					search: queryString.stringify({
						returnUrl: [
							this.props.location ? this.props.location.pathname : null,
							this.props.location ? this.props.location.search : null
						]
							.filter(_ => _)
							.join('')
					})
				}}
			/>
		);
	};

	render() {
		if (this.props.isAuthenticationInProgress) {
			return <Loading />;
		}
		return (
			<AppErrorBoundary>
				<Route
					render={
						this.props.isAuthenticated ? this.renderComponent : this.redirect
					}
				/>
			</AppErrorBoundary>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isAuthenticated: isUserAuthenticated(state),
		isAuthenticationInProgress: isAuthenticationInProgressSelector(state)
	}),
	{ checkAuth }
)(PrivateRoute);
