import { call, put, takeLatest, select } from 'redux-saga/effects';

import { preRegisterSubmit, preRegister, preRegisterAsync } from '../actions';
import {
	getPreRegistrationFormValues,
	getPreRegistrationAgentCompany
} from '../selectors';

import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';
import {
	PRE_REGISTRATION_FORM_NAME,
	PreRegistrationFormData
} from '../registrationTypes';

function* worker() {
	const formValues: PreRegistrationFormData = yield select(
		getPreRegistrationFormValues
	);
	const agentCompany = yield select(getPreRegistrationAgentCompany);

	if (!agentCompany) {
		return;
	}

	yield put(
		preRegister(
			{
				...formValues,
				agentCompanyId: agentCompany.id
			},
			{
				behaviour: {
					type: 'submit',
					formName: PRE_REGISTRATION_FORM_NAME
				}
			}
		)
	);

	const response = yield call(waitAsyncResolve, preRegisterAsync);

	if (!response.error) {
		return;
	}
}

export default function*() {
	yield takeLatest(preRegisterSubmit.type, worker);
}
