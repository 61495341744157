import actionCreator from '../portJobsActionCreator';
import {
	PortJob,
	RetrieveJobParams
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTJOB';

export const retrievePortJobAsync = actionCreator.async<
	RetrieveJobParams,
	PortJob,
	Error
>(ACTION_NAME);

export const retrievePortJob = actionCreator<RetrieveJobParams>(ACTION_NAME);

export const resetPortJobs = actionCreator(`RESET_ALL_${ACTION_NAME}`);
