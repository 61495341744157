import actionCreator from '../authActionCreator';
import { AuthActAsConfig, AuthActAsOption } from '../authState';
import { LabeledValueItem } from 'app-types';

export type SetAuthActingAsPayload = LabeledValueItem | null;

export const setAuthCanActAs = actionCreator<boolean>('SET_CAN_ACT_AS');
export const setAuthActAsOptions = actionCreator<AuthActAsOption[]>(
	'SET_ACT_AS_OPTIONS'
);
export const setAuthActAsConfig = actionCreator<AuthActAsConfig>(
	'SET_ACT_AS_CONFIG'
);
export const setAuthActingAs = actionCreator<SetAuthActingAsPayload>(
	'SET_ACTING_AS'
);
export const resetAuthActAs = actionCreator('RESET_ACT_AS');
