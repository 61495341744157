import { Action } from 'typescript-fsa';
import { select, takeLatest, put } from 'redux-saga/effects';
import { uploadLOBDDone, updateBankAccount } from '../actions';
import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { getActiveBank } from '../selectors';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

export const uploadLOBDDoneSaga = function*({
	payload: { document, isDoneSuccess }
}: Action<UploadDocumentDoneResponse>) {
	if (!isDoneSuccess) {
		return;
	}
	// Reset state
	yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
	// patch BankAccount
	const bank: BankAccount | undefined = yield select(getActiveBank);
	const lobdDocumentId = document.id;
	if (bank && lobdDocumentId) {
		yield put(
			updateBankAccount({
				bankDetailId: bank.id,
				lobdDocumentId,
				lobd: false
			})
		);
	}
};

export default function*() {
	yield takeLatest(uploadLOBDDone.type, uploadLOBDDoneSaga);
}
