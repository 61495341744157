import { Group } from 'services/api/groups/groupsServiceTypes';
import { Action } from 'typescript-fsa';
import { put, call, takeLatest } from 'redux-saga/effects';
import { removeAvailableThreadGroup } from '../../actions/availableGroups/removeAvailableThreadGroup';
import { addThreadPermissions } from '../../actions/availableGroups/addThreadPermissions';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api';

/**
 * Add a new group into the redux-store
 * Removes the group from the thread.newPermission.list
 */
export const executor = function*(params: Action<Group>): SagaIterator {
	const group: Group = params.payload;

	/** 1. Get users from group that's going to be added to the thread.newPermission.list */
	yield call(Api.Groups.retrieveUsersFromGroup, { id: group.id });

	/** 2. Add the group to the thread.newPermission.list */
	yield put(addThreadPermissions(group));

	/** 3. Remove the group from the availableGroups.groups (Dropdown) */
	yield put(removeAvailableThreadGroup(group));
};

export function* worker(action: Action<Group>) {
	yield call(executor, action);
}

const watcher = function*() {
	yield takeLatest(addThreadPermissions.type, worker);
};

export default watcher;
