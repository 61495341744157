import {
	UpdateFinanceServiceRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';

import actionCreator from '../financeActionCreator';

const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const updateServiceAsync = actionCreator.async<
	UpdateFinanceServiceRequest,
	PortJobService,
	Error
>(UPDATE_SERVICE, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateService = actionCreator<UpdateFinanceServiceRequest>(
	UPDATE_SERVICE
);
