import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';
import {
	UploadedDocument,
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const onRetrieveUploadedDocumentsStarted = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		uploadedById: FetchStatus.PENDING
	}
});

const onRetrieveUploadedDocumentsSuccess = (
	state: PortCallDocumentsState,
	{
		params,
		result
	}: Success<
		RetrieveUploadedDocumentsRequest,
		RetrieveUploadedDocumentsResponse
	>
): PortCallDocumentsState => ({
	...state,
	uploadedById: keyBy<UploadedDocument>(
		result.documents.elements,
		item => item.id
	),
	metadata: {
		[params.portCallId]: result.metadata
	},
	fetchStatuses: {
		...state.fetchStatuses,
		uploadedById: FetchStatus.SUCCESS
	}
});

const onRetrieveUploadedDocumentsFailed = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		uploadedById: FetchStatus.FAILURE
	}
});

export const onResetUploadedDocuments = (
	state: PortCallDocumentsState
): PortCallDocumentsState => ({
	...state,
	uploadedById: {},
	fetchStatuses: {
		...state.fetchStatuses,
		uploadedById: FetchStatus.IDLE
	}
});

export {
	onRetrieveUploadedDocumentsStarted,
	onRetrieveUploadedDocumentsSuccess,
	onRetrieveUploadedDocumentsFailed
};
