import { retrievePortCallCargoMeasurementsAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement[],
	Error
>({
	api: Api.PortCallMeasurements.retrievePortCallCargoMeasurements,
	async
});
