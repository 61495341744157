import { Success } from 'typescript-fsa';
import { omit } from 'lodash';
import { CompaniesState } from '../companiesState';
import {
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FetchStatus } from 'services/api/apiTypes';
import { FileBaseInfo } from 'services/api/documents/documentsServiceTypes';

const onRetrieveCompanyPreferredAgentsFileInfoSuccess = (
	state: CompaniesState,
	{
		params,
		result
	}: Success<
		RetrieveCompanyPreferredAgentsFileInfoRequest,
		RetrieveCompanyPreferredAgentsFileInfoResponse
	>
): CompaniesState =>
	result
		? {
				...state,
				preferredAgentsFileInfo: {
					...state.preferredAgentsFileInfo,
					// TODO: When BE has unified type for documents it should be removed
					[params.companyId]: {
						id: result.id,
						name: result.fileName,
						createdByUserName: result.uploadedBy,
						createdOnUtc: result.uploadedAtUtc
					} as FileBaseInfo
				}
		  }
		: state;

export const onResetCompanyPreferredAgentsFileInfo = (
	state: CompaniesState,
	companyId: string
): CompaniesState => ({
	...state,
	preferredAgentsFileInfo: omit(state.preferredAgentsFileInfo, companyId),
	fetchStatuses: {
		...state.fetchStatuses,
		retrievePreferredAgentsFileInfo: FetchStatus.IDLE
	}
});

export const onRetrieveCompanyPreferredAgentsFileInfo = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse
>('fetchStatuses', 'retrievePreferredAgentsFileInfo', {
	doneReducer: onRetrieveCompanyPreferredAgentsFileInfoSuccess
});
