import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { DataFetcher } from 'utils/components';
import {
	retrievePortCall,
	setPortCallContext,
	resetActivePortCallId
} from 'store/portcalls/actions';
import { BreadcrumbItem } from 'store/navigation';
import {
	getActivePortCall,
	getPortJobsCodesforActivePortCall,
	getActivePortCallFetchStatus
} from 'store/portcalls/selectors';
import { getPreviousPath } from 'store/route/selectors';
import { assignJobsToNewThread } from 'store/threads/actions';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import {
	getPortCallId,
	getPortJobCode
} from 'sections/PortCall/Layout/PortCallLayout.func';
import { PATHS } from 'sections/App/RouteParams';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { getNewPortCallMessageBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';
import { FetchStatus } from 'services/api/apiTypes';

const NewComposeMessage = lazy(() => import('./NewComposeMessage'));

interface INewComposeMessageRouteProps extends PortCallTabBaseProps {
	// from mapStateToProps
	portCall: PortCall;
	fetchStatus: FetchStatus;
	breadcrumbs: BreadcrumbItem[];
	portJobsCodesforActivePortCall: string[];
	previousPath: string | undefined;
	// from mapDispatchToProps
	retrievePortCall: typeof retrievePortCall;
	setPortCallContext: typeof setPortCallContext;
	resetActivePortCallId: typeof resetActivePortCallId;
	assignJobsToNewThread: typeof assignJobsToNewThread;
}

class NewComposeMessagePortCallRoute extends React.Component<
	INewComposeMessageRouteProps
> {
	private portCallId = getPortCallId(this.props.match);
	private jobCode = getPortJobCode(this.props.match);

	retrievePortCall = () => {
		this.props.setPortCallContext({
			activePortCallTabId: PortCallTabKey.MESSAGES,
			activePortCallId: this.portCallId
		});
		this.props.retrievePortCall({
			id: this.portCallId,
			expand: 'PortJobsBasic'
		});
	};

	componentDidUpdate(prevProps: INewComposeMessageRouteProps) {
		const userCameBackFromJobAssignment =
			this.props.previousPath === `/${PATHS.messages}/${NEW_THREAD_ID}/job`;

		if (
			!prevProps.portCall &&
			this.props.portCall &&
			!userCameBackFromJobAssignment
		) {
			this.props.assignJobsToNewThread(
				this.props.portJobsCodesforActivePortCall
			);
		}
	}

	reset = () => {
		this.props.resetActivePortCallId();
	};

	render() {
		const { fetchStatus } = this.props;
		return (
			<DataFetcher
				fetchStatus={fetchStatus}
				dispatch={this.retrievePortCall}
				reset={this.reset}
			>
				{() => (
					<NewComposeMessage
						breadcrumbs={this.props.breadcrumbs}
						previousRoutePath={`/${PATHS.portCalls}/${this.portCallId}/jobs/${this.jobCode}/messages`}
					/>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(state: AppState) => ({
		portCall: getActivePortCall(state),
		fetchStatus: getActivePortCallFetchStatus(state),
		breadcrumbs: getNewPortCallMessageBreadcrumbs(state),
		portJobsCodesforActivePortCall: getPortJobsCodesforActivePortCall(state),
		previousPath: getPreviousPath(state)
	}),
	{
		retrievePortCall,
		setPortCallContext,
		resetActivePortCallId,
		assignJobsToNewThread
	}
)(NewComposeMessagePortCallRoute);
