import { keyBy } from 'lodash';
import { PortsState, initialState } from '../portsState';
import { RetrievePortsResponse as Response } from 'services/api/ports/portsServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: PortsState,
	{ result }: Success<null, Response>
) => ({
	...state,
	portsById: keyBy(result.elements, item => item.id)
});

export const onResetPorts = (state: PortsState) => ({
	...state,
	portsById: initialState.portsById
});

export const onRetrievePorts = makeFetchStatusReducers<
	PortsState,
	'fetchStatuses',
	null,
	Response
>('fetchStatuses', 'retrievePorts', {
	doneReducer
});
