import React from 'react';
import { reduxForm, InjectedFormProps, clearSubmitErrors } from 'redux-form';
import { Text } from 'components';
import { CreateGroupFormData } from '../GroupsModalsTypes';
import CreateGroupFormFields, {
	CreateGroupFormFieldsProps
} from './CreateGroupFormFields';
import validate from './validate';

type CreateGroupFormOwnProps = CreateGroupFormFieldsProps;
export type CreateGroupFormProps = CreateGroupFormOwnProps &
	InjectedFormProps<CreateGroupFormData, CreateGroupFormOwnProps>;
function CreateGroupForm({
	companies,
	groupTypes,
	isSelectedCompanyIss,
	isCurrentUserOfISSAdminGroupType,
	error
}: CreateGroupFormProps) {
	return (
		<>
			{error && <Text color="error">{error}</Text>}
			<CreateGroupFormFields
				companies={companies}
				groupTypes={groupTypes}
				isSelectedCompanyIss={isSelectedCompanyIss}
				isCurrentUserOfISSAdminGroupType={isCurrentUserOfISSAdminGroupType}
			/>
		</>
	);
}

export default reduxForm<CreateGroupFormData, CreateGroupFormOwnProps>({
	validate,
	onChange: (_values, dispatch, props) => {
		// reset submit errors on any change after subbmittion failure since `name` field is
		// validated in combination with the selected compoany
		// added type assertion due to missing property in redux-form declaration file
		if (
			props.submitFailed &&
			(props as (typeof props & { validExceptSubmit: boolean }))
				.validExceptSubmit &&
			!props.valid
		) {
			dispatch(clearSubmitErrors(props.form));
		}
	}
})(CreateGroupForm);
