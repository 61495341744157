import React, { FC, useMemo } from 'react';
import { isArray } from 'lodash';
import { Input, Select, TextArea } from 'components/antd';
import { AutocompleteCompany } from 'containers';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

import { InviteUserFormData } from '../UsersModalsTypes';
import { Group } from 'services/api/groups/groupsServiceTypes';

interface InviteUserFormFieldsProps {
	formValues: InviteUserFormData;
	groups: Group[];
	isGroupsLoading: boolean;
	isCompanyDisabled: boolean;
	onCompanySelect: (value: InviteUserFormData['company']) => void;
}

const InviteUserFormFields: FC<InviteUserFormFieldsProps> = ({
	formValues,
	groups,
	isGroupsLoading,
	isCompanyDisabled,
	onCompanySelect
}) => {
	const selectedGroupTypeCode = useMemo(() => {
		if (!formValues.groups || !formValues.groups.length) {
			return null;
		}
		const firstSelectedGroup = groups.find(
			group => group.id === formValues.groups[0]
		);
		if (!firstSelectedGroup) {
			return null;
		}
		return firstSelectedGroup.groupType.code;
	}, [formValues.groups, groups]);

	return (
		<>
			<AutocompleteCompany
				name="company"
				label="Company:"
				placeholder="Choose company"
				required
				disabled={isCompanyDisabled}
				onSelect={onCompanySelect}
				params={{
					organisationTypes: [
						OrganisationType.ISS,
						OrganisationType.LPA,
						OrganisationType.MAIN_PRINCIPAL
					],
					actionType: [PermissionCode.MANAGE_USERS]
				}}
				help
				dataQa="invite-users-company"
			/>
			<Select.ReduxFormItem
				name="groups"
				label="Groups:"
				placeholder={
					!groups.length && Boolean(formValues.company)
						? `The selected company doesn't have any groups`
						: formValues.company
						? 'Select one or more Groups'
						: 'Please select a company first'
				}
				mode="multiple"
				required
				help
				disabled={!groups.length || !formValues.company}
				isLoading={isGroupsLoading}
				optionFilterProp="children"
				filterOption={(input, option) => {
					if (!isArray(option.props.children)) {
						return true;
					}
					return option.props.children
						.join('')
						.toLowerCase()
						.includes(input.toLowerCase());
				}}
				dataQa="invite-users-groups"
			>
				{groups.map(group => (
					<Select.Option
						key={group.id}
						disabled={
							Boolean(selectedGroupTypeCode) &&
							selectedGroupTypeCode !== group.groupType.code
						}
					>
						{group.name} ({group.groupType.name})
					</Select.Option>
				))}
			</Select.ReduxFormItem>
			<Input.ReduxFormItem
				type="email"
				name="email"
				label="Email address:"
				placeholder="mail@mail.com"
				maxLength={256}
				required
				help
				data-qa="invite-users-email"
			/>
			<Input.ReduxFormItem
				name="firstName"
				label="First Name:"
				placeholder="John"
				maxLength={256}
				required
				help
				data-qa="invite-users-firstname"
			/>
			<Input.ReduxFormItem
				name="lastName"
				label="Last Name:"
				placeholder="Doe"
				maxLength={256}
				required
				help
				data-qa="invite-users-lastname"
			/>
			<TextArea.ReduxFormItem
				name="invitationMessage"
				label="Invitation Message (Optional):"
				maxLength={1024}
				rows={3}
				help
				data-qa="invite-users-invitation-message"
			/>
		</>
	);
};

export default InviteUserFormFields;
