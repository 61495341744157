import {
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';

import actionCreator from '../bankAccountsActionCreator';

const ACTION_NAME = 'RETRIEVE_BANK_ACCOUNTS';
const ACTION_NAME_SECTION = `${ACTION_NAME}_SECTION`;
export const retrieveBankAccountsSectionAsync = actionCreator.async<
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse,
	Error
>(ACTION_NAME_SECTION, {
	behaviour: { type: 'section' }
});
export const retrieveBankAccountsSection = actionCreator<
	RetrieveBankAccountsRequest
>(ACTION_NAME_SECTION);
export const retrieveBankAccountsAsync = actionCreator.async<
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse,
	Error
>(ACTION_NAME);

export const retrieveBankAccounts = actionCreator<RetrieveBankAccountsRequest>(
	ACTION_NAME
);
