import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { BankAccountsState } from '../bankAccountsState';
import {
	RetrieveBankAccountRequest as Request,
	LOBDDocument as Response
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Success } from 'typescript-fsa';

const retrieveLOBDDoneReducer = (
	state: BankAccountsState,
	action: Success<Request, Response>
): BankAccountsState => ({
	...state,
	LOBD: action.result
});

export const onRetrieveLOBD = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'retrieveLOBD', {
	doneReducer: retrieveLOBDDoneReducer
});
