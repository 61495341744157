import actionCreator from '../actionCreator';
import {
	RetrieveBerthsResponse,
	RetrieveBerthsRequest
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'vesselProgramme/RETRIEVE_BERTHS';

export const retrieveBerthsAsync = actionCreator.async<
	RetrieveBerthsRequest,
	RetrieveBerthsResponse,
	Error
>(ACTION_NAME);

export const retrieveBerths = actionCreator<RetrieveBerthsRequest>(ACTION_NAME);
