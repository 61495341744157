import {
	AuthState,
	initialState,
	AuthActAsConfig,
	AuthActAsOption
} from '../authState';
import { SetAuthActingAsPayload } from '../actions/setAuthActAs';

export const onSetAuthCanActAs = (
	state: AuthState,
	payload: boolean
): AuthState => {
	return {
		...state,
		actAs: {
			...state.actAs,
			canActAs: payload
		}
	};
};

export const onSetAuthActingAs = (
	state: AuthState,
	payload: SetAuthActingAsPayload
): AuthState => {
	const newState = {
		...state,
		actAs: {
			...state.actAs,
			value: payload
		}
	};
	if (!newState.actAs.value) {
		newState.actingUser = initialState.actingUser;
		newState.actingPermissions = initialState.actingPermissions;
	}
	return newState;
};

export const onSetAuthActAsConfig = (
	state: AuthState,
	payload: AuthActAsConfig
): AuthState => {
	return {
		...state,
		actAs: {
			...state.actAs,
			config: {
				...state.actAs.config,
				...payload
			}
		}
	};
};

export const onSetAuthActAsOptions = (
	state: AuthState,
	payload: AuthActAsOption[]
): AuthState => {
	return {
		...state,
		actAs: {
			...state.actAs,
			options: payload
		}
	};
};

export const onResetAuthActAs = (state: AuthState) => ({
	...state,
	actAs: initialState.actAs,

	actingUser: initialState.actingUser,
	actingPermissions: initialState.actingPermissions
});
