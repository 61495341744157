import * as React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { change } from 'redux-form';
import styles from './CreatePortJobCharterer.module.scss';
import {
	PortJobCharterer,
	FormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { getValues } from 'store/form/selectors';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { getChartererCompanyNameFromPortJobCargoLines } from 'sections/PortJob/CreatePortJob/Pages/Page3/page3Selectors';
import ChartererFields from './ChartererFields';
import ChartererFormFieldName from './CharterersFieldNames';
import { AppState } from 'store-types';

const {
	FINANCIAL_INSTRUCTIONS,
	IS_WORLDSCALE_SERVICES_APPLIED,
	// STAND_BY_TUGS,
	// BERTHAGE_WHARFAGE,
	IS_OTHER_REBILLABLES_APPLIED,
	COST_SETTLEMENT_IS_DIRECT,
	TIME_BAR_IS_APPLIED,
	TIME_BAR_DAYS,
	MAX_PORT_COST,
	MAX_PORT_COST_AMOUNT
} = ChartererFormFieldName;

interface ChartererOwnProps {
	field: string;
	values: PortJobCharterer;
}

interface ChartererConnectedProps {
	companyName: string;
	change: typeof change;
}

export interface ChartererProps
	extends ChartererOwnProps,
		ChartererConnectedProps {}

class Charterer extends React.Component<ChartererProps> {
	componentDidUpdate(prevProps: ChartererProps) {
		const { values, field } = this.props;
		if (!isEqual(values, prevProps.values)) {
			if (!values[TIME_BAR_IS_APPLIED]) {
				this.props.change(FORM.portJob, `${field}.${TIME_BAR_DAYS}`, '');
			}
			if (!values[IS_OTHER_REBILLABLES_APPLIED]) {
				this.props.change(
					FORM.portJob,
					`${field}.${FINANCIAL_INSTRUCTIONS}`,
					''
				);
			}
			if (!values[MAX_PORT_COST]) {
				this.props.change(FORM.portJob, `${field}.${MAX_PORT_COST_AMOUNT}`, '');
			}
			/**
			 * Temporary disabling of the fields according to the issue
			 * https://support.dataart.com/browse/IPP-7158
			 */
			// if (!values[IS_WORLDSCALE_SERVICES_APPLIED]) {
			// 	this.props.change(
			// 		FORM.portJob,
			// 		`${field}.${STAND_BY_TUGS}`,
			// 		''
			// 	);
			// 	this.props.change(
			// 		FORM.portJob,
			// 		`${field}.${BERTHAGE_WHARFAGE}`,
			// 		''
			// 	);
			// }
			if (
				!values[IS_WORLDSCALE_SERVICES_APPLIED] &&
				!values[IS_OTHER_REBILLABLES_APPLIED]
			) {
				this.props.change(
					FORM.portJob,
					`${field}.${COST_SETTLEMENT_IS_DIRECT}`,
					// reset to default value
					'true'
				);
			}
		}
	}
	render() {
		const { field, values, companyName } = this.props;
		return (
			<div className={styles.wrapper}>
				<h2 className={styles.companyName}>{companyName}</h2>
				<ChartererFields field={field} values={values} />
			</div>
		);
	}
}

export default connect(
	(state: AppState, props: ChartererOwnProps) => {
		const formValues = getValues<FormData>(state, FORM.portJob);
		return {
			companyName: getChartererCompanyNameFromPortJobCargoLines(
				formValues.cargoes,
				props.values.chartererCompanyId
			)
		};
	},
	{
		change
	}
)(Charterer);
