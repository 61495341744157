import {
	Message,
	AddThreadMessageRequest,
	AddThreadMessageResponse,
	RetrieveThreadMessageByIdParams,
	ContactRequest,
	ContactType,
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse,
	UpdateThreadReadRequest,
	RetrieveMessageHistoryRequest,
	DownloadEmlFileRequest,
	DownloadEmlFileResponse
} from 'services/api/messages/messagesServiceTypes';
import { AxiosTypedPromise } from '../apiTypes';
import client from 'services/client';

const retrieveThreadMessageById = (
	request: RetrieveThreadMessageByIdParams
): AxiosTypedPromise<Message> => {
	return client.get(
		`/threads/${request.threadId}/messages/${request.messageId}`,
		{
			params: request.params
		}
	);
};

const updateThreadMessageRead = ({
	threadId,
	messageId
}: UpdateThreadReadRequest) => {
	return client.patch(`/threads/${threadId}/messages/${messageId}/read`);
};

const retrieveThreadMessageHistoryById = ({
	threadId,
	messageId
}: RetrieveMessageHistoryRequest) => {
	return client.get(
		`/threads/${threadId}/messages/${messageId}?expand=contentHistory`
	);
};

const createThreadMessage = ({
	threadId,
	message
}: AddThreadMessageRequest): AxiosTypedPromise<AddThreadMessageResponse> => {
	return client.post(`/threads/${threadId}/messages`, message);
};

const getFileUrl = ({
	threadId,
	messageId,
	attachmentId
}: RetrieveMessageAttachmentRequest) => {
	return client.get<RetrieveMessageAttachmentResponse>(
		`/threads/${threadId}/messages/${messageId}/attachments/${attachmentId}`
	);
};

function retrieveContact({ userCompanyId, email }: ContactRequest) {
	return client.get(`/messages/companies/${userCompanyId}/contacts/${email}`);
}

function addContact({ userCompanyId, ...request }: ContactType) {
	return client.post(`/messages/companies/${userCompanyId}/contacts`, request);
}

function updateContact({ userCompanyId, email, ...request }: ContactType) {
	return client.put(
		`/messages/companies/${userCompanyId}/contacts/${email}`,
		request
	);
}

const downloadEml = ({ threadId, messageId }: DownloadEmlFileRequest) => {
	return client.get<DownloadEmlFileResponse>(
		`/threads/${threadId}/messages/${messageId}/eml`
	);
};

export default {
	retrieveContact,
	createThreadMessage,
	retrieveThreadMessageById,
	retrieveThreadMessageHistoryById,
	updateThreadMessageRead,
	addContact,
	updateContact,
	// Files
	getFileUrl,
	downloadEml
};
