import React, { FC } from 'react';
import { FiltersStateAndHelpers } from 'components/Filters/Filters';
import { Row, Col } from 'components/antd';
import { Title, AutocompleteSearch } from 'components';
import { FilterItem } from 'store/filters/filtersState';
import Api from 'services/api';

type CompaniesFiltersProps = FiltersStateAndHelpers;

const searchCompaniesForAutocompleteSearch = (
	searchTerm: string,
	groupType?: string
) =>
	Api.Companies.searchCompanies({
		searchTerm,
		limit: groupType ? undefined : 3
	}).then(res => ({
		searchTerm,
		items: [
			{
				groupType: 'Companies',
				results: res.data.results.map(({ id, name }) => ({
					key: id,
					label: name
				})),
				needShowMore: res.data.needShowMore
			}
		]
	}));

const CompaniesFilters: FC<CompaniesFiltersProps> = ({ onChange }) => {
	const onChangeCompany = (val: FilterItem) => {
		onChange({ companies: [{ ...val, type: 'companies' }] });
	};

	return (
		<Row>
			<Col sm={4}>
				<Title.Label>Search</Title.Label>
				<AutocompleteSearch
					onSearch={searchCompaniesForAutocompleteSearch}
					onChange={onChangeCompany}
					placeholder="Start typing to search"
				/>
			</Col>
		</Row>
	);
};

export default CompaniesFilters;
