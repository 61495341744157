import { without } from 'lodash';

import { GroupUsersState } from '../groupUsersState';
import { AddOrDeleteGroupUserRequest } from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export function onDeleteThirdPartyLPAGroupUserStarted(
	state: GroupUsersState
): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.PENDING
	};
	return {
		...state,
		fetchStatuses
	};
}

export function onDeleteThirdPartyLPAGroupUserSuccess(
	state: GroupUsersState,
	action: { params: AddOrDeleteGroupUserRequest }
): GroupUsersState {
	const { groupId, userId } = action.params;

	const byGroupId = {
		...state.byGroupId,
		[groupId]: without(state.byGroupId[groupId], userId)
	};
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.SUCCESS
	};
	return {
		...state,
		fetchStatuses,
		byGroupId
	};
}

export function onDeleteThirdPartyLPAGroupUserFailed(
	state: GroupUsersState
): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.FAILURE
	};
	return {
		...state,
		fetchStatuses
	};
}
