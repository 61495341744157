import { FinanceState } from '../financeState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	FinanceServiceQueries,
	RetrieveServiceQueriesRequest
} from 'services/api/finance/financeServiceTypes';
import { Success, Failure } from 'typescript-fsa';

export function onRetrieveServiceQueriesStarted(
	state: FinanceState,
	payload: RetrieveServiceQueriesRequest
): FinanceState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			serviceQueries: {
				[payload.jobServiceId]: FetchStatus.PENDING
			}
		}
	};
}

export function onRetrieveServiceQueriesSuccess(
	state: FinanceState,
	{
		params,
		result
	}: Success<RetrieveServiceQueriesRequest, FinanceServiceQueries>
): FinanceState {
	const { jobServiceId } = params;
	return {
		...state,
		context: {
			...state.context,
			serviceQueries: {
				...state.context.serviceQueries,
				[jobServiceId]: result
			}
		},
		fetchStatuses: {
			...state.fetchStatuses,
			serviceQueries: {
				[jobServiceId]: FetchStatus.SUCCESS
			}
		}
	};
}

export function onRetrieveServiceQueriesFailure(
	state: FinanceState,
	action: Failure<RetrieveServiceQueriesRequest, Error>
): FinanceState {
	const { jobServiceId } = action.params;
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			serviceQueries: {
				[jobServiceId]: FetchStatus.FAILURE
			}
		}
	};
}

export function onResetServiceQueries(state: FinanceState) {
	return {
		...state,
		context: {
			...state.context,
			serviceQueries: {}
		},
		fetchStatuses: {
			...state.fetchStatuses,
			serviceQueries: {}
		}
	};
}
