import {
	FundingRequestStatus,
	FundingType
} from 'services/api/financeFundings/financeFundingsServiceTypes';

export const NEW_FUNDING_ID = '-1';

export enum FundingStatusLabel {
	PENDING_REVIEW = 'Pending review',
	PENDING = 'Pending',
	REJECTED = 'Rejected',
	REVIEWED = 'Reviewed',
	SENT_FOR_PAYMENT = 'Sent for payment',
	COMPLETE = 'Complete',
	FAILED = 'Failed',
	CANCELLED = 'Cancelled',
	PARTIAL_FUNDS_ACCEPTED = 'Partial Funds Accepted',
	FUNDS_REQUESTED = 'Funds Requested',
	PARTIALLY_FUNDED = 'Partially funded'
}

export const fundingRequestStatusMap = {
	[FundingRequestStatus.PENDING_REVIEW]: FundingStatusLabel.PENDING_REVIEW,
	[FundingRequestStatus.PENDING]: FundingStatusLabel.PENDING,
	[FundingRequestStatus.REJECTED]: FundingStatusLabel.REJECTED,
	[FundingRequestStatus.REVIEWED]: FundingStatusLabel.REVIEWED,
	[FundingRequestStatus.SENT_FOR_PAYMENT]: FundingStatusLabel.SENT_FOR_PAYMENT,
	[FundingRequestStatus.FAILED]: FundingStatusLabel.FAILED,
	[FundingRequestStatus.COMPLETE]: FundingStatusLabel.COMPLETE,
	[FundingRequestStatus.CANCELLED]: FundingStatusLabel.CANCELLED,
	[FundingRequestStatus.PARTIAL_FUNDS_ACCEPTED]:
		FundingStatusLabel.PARTIAL_FUNDS_ACCEPTED,
	[FundingRequestStatus.FUNDS_REQUESTED]: FundingStatusLabel.FUNDS_REQUESTED,
	[FundingRequestStatus.PARTIALLY_FUNDED]: FundingStatusLabel.PARTIALLY_FUNDED
};

export const fundingTypeMap = {
	[FundingType.PREFUNDING]: 'Pre-funding',
	[FundingType.SETTLEMENT]: 'Settlement',
	[FundingType.CUSTOMER_PREFUNDING]: 'Pre-funding'
};
