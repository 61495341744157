import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState } from '../portJobsState';
import { ClonePortJobRequest as Request } from 'services/api/portJobs/portJobsServiceTypes';

export const onCloneJob = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	Request,
	void
>('fetchStatuses', 'clonePortJob');
