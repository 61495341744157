import React from 'react';
import { Flex, Gap, Text, FileTypeIcon } from 'components';
import { Button, Icon } from 'components/antd';
import { FormattedTime } from 'containers';
import { FileBaseInfo } from 'services/api/documents/documentsServiceTypes';
import styles from './UploadDraggerBox.module.scss';

interface UploadedFilesListProps {
	title: React.ReactElement<null> | string;
	isDeleteAvailable?: boolean;
	filesInfo: FileBaseInfo[];
	showFileTypeIcon?: boolean;
	onDeleteFileInfo?: (fileId: string) => void;
}

const UploadedFilesList: React.FC<UploadedFilesListProps> = ({
	title,
	isDeleteAvailable,
	filesInfo,
	showFileTypeIcon,
	onDeleteFileInfo
}) => {
	if (!filesInfo.length) {
		return null;
	}
	return (
		<Gap top="md">
			<Gap isBlock bottom="md">
				<Text upper weight="bold">
					{title}
				</Text>
			</Gap>
			{filesInfo.map(file => (
				<Flex key={file.id} inline className={styles.fileInfo}>
					{showFileTypeIcon ? (
						<FileTypeIcon fileName={file.name} offset="right" />
					) : (
						<Icon type="document" color="standard" offset="right" />
					)}
					<div>
						<Gap isBlock bottom="sm">
							<Text color="primary" weight="bold">
								{file.name}
							</Text>
						</Gap>
						<Text color="default" size="xs">
							{file.createdByUserName && `${file.createdByUserName}`}{' '}
							{file.createdOnUtc && (
								<FormattedTime
									value={file.createdOnUtc}
									format="DD MMM YYYY, HH:mm"
								/>
							)}
						</Text>
					</div>
					{isDeleteAvailable && (
						<Button
							type="danger"
							transparent
							icon="delete"
							className={styles.deleteFileInfoBtn}
							onClick={() => onDeleteFileInfo?.(file.id)}
						/>
					)}
				</Flex>
			))}
		</Gap>
	);
};

export default UploadedFilesList;
