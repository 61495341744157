import { AxiosError } from 'axios';
import { Success, Failure } from 'typescript-fsa';
import { UserStatusesOption } from 'services/api/users/userServiceTypes';
import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveUserStatusesStarted = (
	state: UsersState
): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrievingStatuses: FetchStatus.PENDING
	}
});
export const onRetrieveUserStatusesSuccess = (
	state: UsersState,
	action: Success<undefined, UserStatusesOption[]>
): UsersState => ({
	...state,
	statuses: action.result,
	fetchStatuses: {
		...state.fetchStatuses,
		retrievingStatuses: FetchStatus.SUCCESS
	}
});
export const onRetrieveUserStatusesFailed = (
	state: UsersState,
	action: Failure<undefined, AxiosError>
): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrievingStatuses: FetchStatus.FAILURE
	},
	error: action.error
});
