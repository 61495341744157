import {
	SubmitUnitCostForm,
	SubmitUnitCostFormPayload,
	SubmitUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const SUBMIT_UNIT_COST_FORM = 'SUBMIT_UNIT_COST_FORM';
export const submitUnitCostFormAsync = actionCreator.async<
	SubmitUnitCostForm,
	SubmitUnitCostFormResponse,
	Error
>(SUBMIT_UNIT_COST_FORM);

export const submitUnitCostForm = actionCreator<{
	jobServiceId: string;
	formValues: SubmitUnitCostFormPayload;
}>(SUBMIT_UNIT_COST_FORM);
