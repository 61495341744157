import {
	CommenceOfSeaPassageRequest,
	CommenceOfSeaPassageResponse
} from 'services/api/portCall/portCallServiceTypes';

import actionCreator from 'store/portCall/portCallActionCreator';

const COMMENCE_OF_SEA_PASSAGE = 'COMMENCE_OF_SEA_PASSAGE';
const commenceOfSeaPassageAsync = actionCreator.async<
	CommenceOfSeaPassageRequest,
	CommenceOfSeaPassageResponse,
	Error
>(COMMENCE_OF_SEA_PASSAGE);
export const commenceOfSeaPassage = actionCreator<CommenceOfSeaPassageRequest>(
	COMMENCE_OF_SEA_PASSAGE
);

export const commenceOfSeaPassageAction = {
	action: commenceOfSeaPassage,
	async: commenceOfSeaPassageAsync
};
