import { FinanceState } from '../financeState';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import {
	UpdateDAOutActionPayload,
	PortCallFinance,
	DisbursementAccount
} from 'services/api/finance/financeServiceTypes';

const getSetUpdateDas = (
	state: FinanceState,
	daId: string,
	status: FetchStatus
) => {
	return {
		...state.updateStatuses,
		das: {
			...state.updateStatuses.das,
			[daId]: status
		}
	};
};

export function onUpdateDAStarted(
	state: FinanceState,
	{ disbursementAccountId }: UpdateDAOutActionPayload
): FinanceState {
	const updateStatuses = getSetUpdateDas(
		state,
		disbursementAccountId as string,
		FetchStatus.PENDING
	);

	return {
		...state,
		updateStatuses
	};
}

export function onUpdateDASuccess(
	state: FinanceState,
	{
		result,
		params
	}: Success<UpdateDAOutActionPayload, UpdateDAOutActionPayload>
): FinanceState {
	const finance = state.finance || ({} as PortCallFinance);
	const getDA = (da: DisbursementAccount) =>
		da.id === params.disbursementAccountId ? { ...da, ...result.data } : da;

	const updateStatuses = getSetUpdateDas(
		state,
		params.disbursementAccountId as string,
		FetchStatus.SUCCESS
	);

	return {
		...state,
		finance: {
			...finance,
			disbursementAccountIns: {
				...finance.disbursementAccountIns,
				elements: finance.disbursementAccountIns.elements.map(getDA)
			},
			disbursementAccountOuts: {
				...finance.disbursementAccountOuts,
				elements: finance.disbursementAccountOuts.elements.map(getDA)
			}
		},
		updateStatuses
	};
}

export function onUpdateDAFailed(
	state: FinanceState,
	action: Failure<UpdateDAOutActionPayload, Error>
): FinanceState {
	const updateStatuses = getSetUpdateDas(
		state,
		action.params.disbursementAccountId as string,
		FetchStatus.FAILURE
	);

	return {
		...state,
		updateStatuses
	};
}
