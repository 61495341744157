import { GroupUsersState } from '../groupUsersState';
import { FetchStatus } from 'services/api/apiTypes';
export function onResetGroupUsers(state: GroupUsersState, groupId: string) {
	return {
		...state,
		byGroupId: {
			...state.byGroupId,
			[groupId]: []
		},
		fetchStatuses: {
			...state.fetchStatuses,
			[groupId]: FetchStatus.IDLE
		},
		hasMore: true
	} as GroupUsersState;
}
