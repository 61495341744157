import React from 'react';
import { Title, Gap, Text } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import CargosContainer from './CargosContainer';
import {
	getDispalyCargos,
	getLinkedAppointmentCargos,
	getNominationCargos,
	getUnlinkedAppointmentCargos
} from 'store/linkJob/selectors/linkJobSelectors';
import { AppState } from 'store-types';
import { deleteLinkedAppointmentCargo } from 'store/linkJob/actions';

const CargoLinkage: React.SFC<{}> = () => {
	const dispatch = useDispatch();
	const displayNominationCargos = useSelector((state: AppState) =>
		getDispalyCargos(state, getNominationCargos)
	);
	const displayAppointmentCargos = useSelector((state: AppState) =>
		getDispalyCargos(state, getLinkedAppointmentCargos)
	);
	const displayUnlikedAppointmentCargos = useSelector((state: AppState) =>
		getDispalyCargos(state, getUnlinkedAppointmentCargos)
	);

	const onDelete = (id: string) => {
		dispatch(deleteLinkedAppointmentCargo({ id }));
	};

	return (
		<>
			<Gap top left bottom="sm" isBlock outside={false}>
				<Title.Subject>
					<Text.Box upper weight="bold">
						Cargo Linkage
					</Text.Box>
				</Title.Subject>
				{!displayNominationCargos.length ? (
					<Gap top left bottom="sm" isBlock outside={false}>
						<Text.Box weight="semi-bold">
							There is no cargos operations found in the Nomination job. Please
							create cargo operations and start linking.
						</Text.Box>
					</Gap>
				) : (
					<CargosContainer
						nominationCargos={displayNominationCargos}
						appointmentCargos={displayAppointmentCargos}
						unlinkedAppointmentCargos={displayUnlikedAppointmentCargos}
						onDelete={onDelete}
					/>
				)}
			</Gap>
		</>
	);
};

export default CargoLinkage;
