import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AppState } from 'store-types';
import {
	PageFooter,
	PageHeader,
	Flex,
	Loading,
	Gap,
	ScrollableLayout
} from 'components';
import { Button } from 'components/antd';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';
import { getCompanyById } from 'store/companies/selectors';
import { retrieveCompany } from 'store/companies';
import PrettyJSON from 'sections/MasterDataExplorer/components/PrettyJSON/PrettyJSON';
import { Justify } from 'components/types';
import { MasterDataExplorerTab } from 'sections/MasterDataExplorer/MasterDataExplorerSectionTypes';
import CopyToClipboardWrapper from 'components/CopyToClipboardWrapper/CopyToClipboardWrapper';
import { usePrettifiedJSON } from 'sections/MasterDataExplorer/MasterDataExplorerHooks';

type CompanyDetailsProps = RouteComponentProps<{ id: string }>;

const goBack = () =>
	navigateTo(`/${PATHS.masterDataExplorer}/${MasterDataExplorerTab.COMPANIES}`);

const CompanyDetails: React.FC<CompanyDetailsProps> = ({
	match: {
		params: { id }
	}
}) => {
	const dispatch = useDispatch();
	const company = useSelector(
		(state: AppState) => getCompanyById(state, id),
		shallowEqual
	);
	const { prettifiedJSON } = usePrettifiedJSON(company);

	useEffect(() => {
		if (!company) {
			dispatch(retrieveCompany(id));
		}
	}, [id, dispatch, company]);

	const goToParent = useCallback(() => {
		navigateTo(
			`/${PATHS.masterDataExplorer}/${MasterDataExplorerTab.COMPANIES}/${company.parentCompany.id}`
		);
	}, [company]);

	if (!company) {
		return <Loading />;
	}

	return (
		<ScrollableLayout>
			<PageHeader.NewTab onClose={goBack} isNewTab shouldNewTabClose={false}>
				{company.companyName}
			</PageHeader.NewTab>
			<PageHeader shadowed>
				<Flex justify={Justify.BETWEEN}>
					<div>
						{company.parentCompany && (
							<Gap right>
								<Button type="link" onClick={goToParent}>
									Parent Company
								</Button>
							</Gap>
						)}
					</div>
					<Flex>
						<CopyToClipboardWrapper copyContent={prettifiedJSON} />
					</Flex>
				</Flex>
			</PageHeader>
			<ScrollableLayout>
				<Gap top="lg" bottom="md" isBlock>
					<PrettyJSON objToPrettify={company} />
				</Gap>
			</ScrollableLayout>
			<PageFooter>
				<Flex justify={Justify.BETWEEN}>
					<Button type="primary" transparent icon="left" onClick={goBack}>
						Back
					</Button>
				</Flex>
			</PageFooter>
		</ScrollableLayout>
	);
};

export default CompanyDetails;
