import { CategorizeThreadsRequest } from 'services/api/threads/threadsServiceTypes';
import actionCreator from '../threadsActionCreator';

const UPDATE_THREADS_CATEGORIZE = 'UPDATE_THREADS_CATEGORIZE';

export const updateThreadsCategorizeAsync = actionCreator.async<
	CategorizeThreadsRequest,
	null,
	Error
>(UPDATE_THREADS_CATEGORIZE, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateThreadsCategorize = actionCreator<CategorizeThreadsRequest>(
	UPDATE_THREADS_CATEGORIZE
);
