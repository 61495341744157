import { all, fork } from 'redux-saga/effects';
import retrievePortByIdWatcher from './retrievePortByIdSaga';
import retrieveAnchoragesWatcher from './retrieveAnchoragesSaga';
import retrieveBuoysWatcher from './retrieveBuoysSaga';
import retrieveCanalsWatcher from './retrieveCanalsSaga';
import retrievePortsWatcher from './retrievePortsSaga';

export default function*() {
	yield all([
		fork(retrievePortByIdWatcher),
		fork(retrieveAnchoragesWatcher),
		fork(retrieveBuoysWatcher),
		fork(retrieveCanalsWatcher),
		fork(retrievePortsWatcher)
	]);
}
