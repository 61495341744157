import { initialState } from './opticConfigState';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';

import {
	onRetrieveKpiActiveSectionsStarted,
	onRetrieveKpiActiveSectionsSuccess,
	onRetrieveKpiAlertsSectionsFailed,
	onRetrieveKpiDetailsById,
	onUpdateKpiDetailsById
} from './reducers';
import {
	retrieveKpiActiveSectionAsync,
	retrieveKpiDetailsByIdAsync,
	updateKpiDetailsByIdAsync
} from './actions';

export default reducerWithInitialState(initialState)
	.case(
		retrieveKpiActiveSectionAsync.started,
		onRetrieveKpiActiveSectionsStarted
	)
	.case(retrieveKpiActiveSectionAsync.done, onRetrieveKpiActiveSectionsSuccess)
	.case(retrieveKpiActiveSectionAsync.failed, onRetrieveKpiAlertsSectionsFailed)
	.case(retrieveKpiDetailsByIdAsync.started, onRetrieveKpiDetailsById.started)
	.case(retrieveKpiDetailsByIdAsync.done, onRetrieveKpiDetailsById.done)
	.case(retrieveKpiDetailsByIdAsync.failed, onRetrieveKpiDetailsById.failed)
	.case(updateKpiDetailsByIdAsync.started, onUpdateKpiDetailsById.started)
	.case(updateKpiDetailsByIdAsync.done, onUpdateKpiDetailsById.done)
	.case(updateKpiDetailsByIdAsync.failed, onUpdateKpiDetailsById.failed);
