import { ThreadsAssignedToUserFilter } from 'services/api/threads/threadsServiceTypes';

export enum ThreadsFilterStatus {
	ALL = 'All',
	UNRESOLVED = 'Unresolved',
	OPEN = 'Open',
	QUERIED = 'Queried',
	RESOLVED = 'Resolved',
	OVERDUE = 'Overdue'
}

export const threadsStatusesOptions = [
	{
		label: ThreadsFilterStatus.ALL,
		key: ThreadsFilterStatus.ALL
	},
	{
		label: ThreadsFilterStatus.UNRESOLVED,
		key: ThreadsFilterStatus.UNRESOLVED
	},
	{ label: ThreadsFilterStatus.OPEN, key: ThreadsFilterStatus.OPEN },
	{ label: ThreadsFilterStatus.QUERIED, key: ThreadsFilterStatus.QUERIED },
	{ label: ThreadsFilterStatus.RESOLVED, key: ThreadsFilterStatus.RESOLVED },
	{ label: ThreadsFilterStatus.OVERDUE, key: ThreadsFilterStatus.OVERDUE }
];

export const threadsAssignedToUserKeyMap = {
	[ThreadsAssignedToUserFilter.ME]: 'Assigned to me',
	[ThreadsAssignedToUserFilter.UNASSIGNED]: 'Unassigned'
};
