import { UploadDocumentRequest } from 'services/api/documents/documentsServiceTypes';
import actionCreator from '../documentsActionCreator';

export interface CancelUploadAndProcessDocumentRequest {
	shouldResetFile: boolean;
}

const UPLOAD_AND_PROCESS_DOCUMENT = 'UPLOAD_AND_PROCESS_DOCUMENT';
export const uploadAndProcessDocumentAsync = actionCreator.async<
	UploadDocumentRequest,
	null,
	Error
>(UPLOAD_AND_PROCESS_DOCUMENT, {
	failed: {
		omit: true
	}
});

export const uploadAndProcessDocument = actionCreator<UploadDocumentRequest>(
	UPLOAD_AND_PROCESS_DOCUMENT
);

// Cancel externally
export const cancelUploadAndProcessDocument = actionCreator<
	CancelUploadAndProcessDocumentRequest
>('CANCEL_UPLOAD_AND_PROCESS_DOCUMENT');
