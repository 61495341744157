import actionCreatorFactory from 'typescript-fsa';
import {
	AddOrDeleteGroupUserRequest,
	GroupUser
} from 'services/api/groups/groupsServiceTypes';

export const actionCreator = actionCreatorFactory();

const ADD_GROUP_USER = 'group/ADD_GROUP_USER';
export const addGroupUserAsync = actionCreator.async<
	AddOrDeleteGroupUserRequest,
	GroupUser,
	Error
>(ADD_GROUP_USER, {
	templateParams: {
		entityName: 'Group User'
	}
});
export const addGroupUser = actionCreator<AddOrDeleteGroupUserRequest>(
	addGroupUserAsync.type
);
