import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	// Initialize Wizard
	initialize,
	// Destroy Wizard
	destroy,
	// Set where the wizard will start
	setStartPage,
	// Set Active Page
	setActivePage,
	// Set Active Page State
	setActivePageState,
	// Page Navigation - Prev
	prevPage,
	// Page Navigation - Next
	nextPage,
	// Set mode
	setMode,
	// Update State
	updateState,
	submitPage,
	//set from path
	setFromPath,
	updatePageState
} from './actions';
import {
	// Update State
	onUpdateState,
	onInitialize,
	onDestroy,
	onSetStartPage,
	onSetActivePage,
	onSetActivePageState,
	onPrevPage,
	onNextPage,
	onSetMode,
	onSubmitPage,
	onSetOriginUrl,
	onSetFromPath,
	onUpdatePageState
} from './reducers';
import { setOriginUrl } from './actions/setOriginUrl';

import { initialState } from './wizardFormState';

export default reducerWithInitialState(initialState)
	.case(initialize, onInitialize)
	.case(updateState, onUpdateState)
	.case(setStartPage, onSetStartPage)
	.case(setActivePage, onSetActivePage)
	.case(setActivePageState, onSetActivePageState)
	.case(prevPage, onPrevPage)
	.case(nextPage, onNextPage)
	.case(setMode, onSetMode)
	.case(setOriginUrl, onSetOriginUrl)
	.case(destroy, onDestroy)
	.case(submitPage, onSubmitPage)
	.case(setFromPath, onSetFromPath)
	.case(updatePageState, onUpdatePageState);
