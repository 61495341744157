import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
import Api from 'services/api';
import { deleteCompanyVesselTypeAsync } from '../actions';

export default makeTakeLatestWatcher<
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse,
	Error
>({
	api: Api.Companies.deleteCompanyVesselType,
	async: deleteCompanyVesselTypeAsync
});
