import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';

interface ReduxFormBaseProps {
	className?: string;
}

export type ReduxFormProps<
	FormData = {},
	P = ReduxFormBaseProps
> = InjectedFormProps<FormData, P> & P;

export const ReduxForm: React.FC<ReduxFormProps> = props => (
	<div className={props.className}>{props.children}</div>
);

export default reduxForm<{}, ReduxFormBaseProps>(DEFAULT_REDUX_FORM_CONFIG)(
	ReduxForm
);
