import { WizardFormState } from '../wizardFormState';

import { UpdatePageStateAction } from '../actions/updatePageState';

export const onUpdatePageState = (
	state: WizardFormState,
	action: UpdatePageStateAction
) => {
	const { name, pages } = action;
	const currentForm = state.forms[name];
	const form = {
		...currentForm,
		pages: {
			...pages
		}
	};
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
