import { FetchStatus } from 'services/api/apiTypes';
import {
	PortJob,
	PortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';
import { LinkedCargo } from './linkJobTypes';

export interface LinkJobState {
	linkedNominationCargoes: LinkedCargo[];
	linkedAppointmentCargoes: LinkedCargo[];
	linkedAppointmentJob: PortJob | undefined;
	unLinkedAppointmentCargoes: LinkedCargo[];
	operationsNomination: PortJobOperationTypes[];
	operationsAppointment: PortJobOperationTypes[];
	fetchStatuses: {
		linkJob: FetchStatus;
		linkedJob: FetchStatus;
		saveUpdatedLinkCargo: FetchStatus;
	};
}

export const initialState: LinkJobState = {
	linkedNominationCargoes: [],
	linkedAppointmentCargoes: [],
	linkedAppointmentJob: undefined,
	unLinkedAppointmentCargoes: [],
	operationsNomination: [],
	operationsAppointment: [],
	fetchStatuses: {
		linkJob: FetchStatus.IDLE,
		linkedJob: FetchStatus.IDLE,
		saveUpdatedLinkCargo: FetchStatus.IDLE
	}
};
