import { LabeledId } from './../../../app-types.d';
import { ResultCollection, PagedRequest } from 'services/api/apiTypes';
import { Entity } from 'app-types';
import { OrganisationType } from '../companies/companiesServiceTypes';

export interface Bank {
	bankName: string;
	bankShortName: string;
	code: string;
}

export enum BankAccountTypeCode {
	BENEFICIARY = 'Beneficiary',
	CORRESPONDING = 'Corresponding',
	INTERMEDIARY = 'Intermediary',
	CUST_BENEFICIARY = 'CustBeneficiary',
	CUST_CORRESPONDING = 'CustCorresponding',
	CUST_INTERMEDIARY = 'CustIntermediary'
}

export interface RetrieveBanksRequest {
	bankName?: string;
}
export type RetrieveBanksResponse = ResultCollection<Bank>;

export interface BankAddress {
	addressLine1: string;
	addressLine2?: string;
	addressLine3?: string;
	addressLine4?: string;
	city?: string;
	countryCode: string;
	postalCode?: string;
}

export interface BankAccountBase {
	bankAccountCurrency: string;
	bankAccountName: string;
	bankAccountNumber: string;
	bankAccountTypeCode: string;
	address?: BankAddress;
	aba?: string;
	agencia?: string;
	agencyNumber?: string;
	bankAccountRemarks?: string;
	bankSortCode?: string;
	bankSwiftCode?: string;
	bic?: string;
	bsb?: string;
	cnpjCode?: string;
	cpf?: string;
	iban?: string;
	id?: string;
	ifc?: string;
	inn?: string;
	isDeleted?: boolean;
	isDraft?: boolean;
	lobd?: boolean;
	lobdDocumentId?: string;
	label?: string; // ?
	purposeCode?: string;
	purposeReason?: string;
	routingNumber?: string;
	transactionPurpose?: string;
	notes?: string;
}

export interface BankAccount extends BankAccountBase {
	bankName: string;
	bankCode: string;
	label: string;
	id: string;
	companyInfo?: {
		company: Entity;
		organisationTypeCode: OrganisationType;
	};
}

export interface BankAccountEntity extends LabeledId {
	lobd: boolean;
	isDraft: boolean;
}

// Bank Accounts
export interface RetrieveBankAccountsRequest extends PagedRequest {
	ids?: string[];
	companyId?: string;
	includeDeleted?: boolean;
}
export interface RetrieveBankAccountsResponse
	extends ResultCollection<BankAccount> {}

export interface AddBankAccountRequest extends BankAccountBase {
	bankName: string;
	bankCode: string;
	bankAccountTypeCode: BankAccountTypeCode;
}

export interface UpdateBankAccountRequest {
	bankDetailId: string;
	lobdDocumentId?: string | null;
	lobd?: boolean;
}

export interface RetrieveBankAccountRequest {
	id: string;
}
export interface RetrieveBankAccountResponse extends BankAccount {}

export interface DownloadLOBDRequest {
	documentId: string;
	letterOfBankDetailDocumentId: string;
}

// current BE response actually differs from other file responses
// so can't use Document or other interfaces from documentServiceTypes
export interface LOBDDocument {
	contentType: string;
	createdByUserName: string;
	createdByUserType: string;
	createdOn: string;
	id: string;
	name: string;
}
