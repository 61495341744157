import { SupplierInvoiceState } from '../supplierInvoiceState';
import { UpdateSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateSupplierInvoice = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	UpdateSupplierInvoiceRequest,
	null
>('fetchStatuses', 'update');
