import * as React from 'react';
import styles from './AssignTo.module.scss';

import { Loading, Flex } from 'components';
import { Icon } from 'components/antd';
import ConditionalTitle from './ConditionalTitle';
import GroupLine from './GroupLine';
import UserLine from './UserLine';
import { useAssignGroupModal } from '../AssignGroupModal/AssignGroupModal.hooks';
import AssignGroupModal from '../AssignGroupModal/AssignGroupModalWrapper';
import { Entity } from 'app-types';

interface AssignToProps {
	assignedTo: {
		group: { id: string; name: string; isDeleted: boolean };
		user: { id: string; name: string };
	};
	loading?: boolean;
	showTitle?: boolean;
	hideMoveBtn?: boolean;
	editAssignTo?: boolean;
	threadCompany: Entity<string>;
	threadId: string;
}

const AssignTo: React.SFC<AssignToProps> = props => {
	const {
		assignedTo,
		loading,
		showTitle,
		threadCompany,
		threadId,
		hideMoveBtn,
		editAssignTo
	} = props;

	const { showAssignGroupModal } = useAssignGroupModal();

	if (!assignedTo) {
		return null;
	}

	const isShowingTitle = showTitle !== false;

	return (
		<section>
			<ConditionalTitle
				threadId={threadId}
				showingTitle
				onClick={showAssignGroupModal}
				assignedGroupId={assignedTo.group.id}
				hideMoveBtn={hideMoveBtn}
				editAssignTo={editAssignTo}
			/>

			<Flex align="center">
				{loading && <Loading size="xs" inline />}
				{!loading && (
					<UserLine assignedTo={assignedTo} showingTitle={isShowingTitle} />
				)}
			</Flex>
			<Flex align="center">
				<Icon
					type="users"
					color="standard"
					offset="right"
					className={styles.groupIcon}
				/>
				{loading && <Loading size="xs" inline />}
				{!loading && assignedTo.group && (
					<GroupLine assignedTo={assignedTo} showingTitle={isShowingTitle} />
				)}
			</Flex>
			{threadCompany && (
				<AssignGroupModal threadId={threadId} assignedTo={assignedTo} />
			)}
		</section>
	);
};

export default AssignTo;
