import {
	AddPortJobCargoLineDischarge,
	AddPortJobCargoLineLoad,
	CompanyDraftResponse,
	AddPortJobCharterer,
	AddPortJobLoadOperation,
	AddPortJobDischargeOperation
} from 'services/api/portJobs/portJobsServiceTypes';
import { groupBy } from 'lodash';

const replaceCompanyId = <
	T extends
		| AddPortJobCargoLineLoad
		| AddPortJobCargoLineDischarge
		| AddPortJobCharterer
>(
	items: T[],
	portJobCompanies?: CompanyDraftResponse[]
): T[] => {
	if (!portJobCompanies) {
		return items;
	}
	return items.map<T>(item => {
		const portJobCompany = portJobCompanies.find(
			company =>
				!!item.chartererCompany &&
				company.companyId === item.chartererCompany.id
		);
		let chartererCompanyName;
		if (portJobCompany && item.chartererCompany) {
			chartererCompanyName =
				item.chartererCompany.name !== portJobCompany.companyName
					? item.chartererCompany.name
					: portJobCompany.companyName;
		}
		return portJobCompany
			? {
					...item,
					chartererCompany: {
						id: portJobCompany.id,
						name: chartererCompanyName
					}
			  }
			: item;
	});
};

const filterChartererDuplicates = (items: AddPortJobCharterer[]) => {
	const groupedCharterers = groupBy(items, 'chartererCompany.id');
	return items.filter(item => {
		if (!item.chartererCompany) {
			return true;
		}
		const charterersGroup = groupedCharterers[item.chartererCompany.id];
		return charterersGroup.length > 1 ? item.id !== undefined : true;
	});
};

export const populatePortJobCompanies = (
	operation: AddPortJobLoadOperation | AddPortJobDischargeOperation,
	portJobCompanies?: CompanyDraftResponse[]
): AddPortJobLoadOperation | AddPortJobDischargeOperation => {
	return {
		...operation,
		cargoes: replaceCompanyId<
			AddPortJobCargoLineLoad | AddPortJobCargoLineDischarge
		>(operation.cargoes, portJobCompanies),
		charterers: filterChartererDuplicates(
			replaceCompanyId<AddPortJobCharterer>(
				operation.charterers,
				portJobCompanies
			)
		)
	};
};
