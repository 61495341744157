import React from 'react';
import { connect } from 'react-redux';
import { isEqual, isFunction } from 'lodash';
import history from 'services/history';

import { getAuthActAs } from 'store/auth/selectors';
import { AuthActAsConfig, AuthActAsOption } from 'store/auth/authState';
import {
	setAuthCanActAs,
	setAuthActAsConfig,
	setAuthActAsOptions
} from 'store/auth/actions';

import { LabeledValueItem } from 'app-types';
import { AppState } from 'store-types';

interface ActAsSwitcherProps {
	value: LabeledValueItem | null;
	enableIfActive?: boolean;
	/**
	 * Data to provide
	 */
	initialConfig?: Partial<AuthActAsConfig>;
	options?: AuthActAsOption[];
	children?: () => React.ReactNode;
	setAuthCanActAs: typeof setAuthCanActAs;
	setAuthActAsConfig: typeof setAuthActAsConfig;
	setAuthActAsOptions: typeof setAuthActAsOptions;
}

class ActAsSwitcher extends React.Component<ActAsSwitcherProps> {
	componentDidMount() {
		if (this.getIsEnabled()) {
			this.props.setAuthCanActAs(true);
			this.setAuthActAsConfig();
			this.setAuthActAsOptions();
		}
	}
	componentDidUpdate(prevProps: ActAsSwitcherProps) {
		const { options, initialConfig } = this.props;

		if (!isEqual(prevProps.options, options)) {
			this.setAuthActAsOptions();
		}
		if (
			initialConfig?.leaveModeText !== prevProps.initialConfig?.leaveModeText ||
			initialConfig?.disabledMessage !==
				prevProps.initialConfig?.disabledMessage
		) {
			this.setAuthActAsConfig();
		}
	}
	componentWillUnmount() {
		if (this.getIsEnabled()) {
			this.props.setAuthCanActAs(false);
		}
	}

	getIsEnabled = () => !this.props.enableIfActive || Boolean(this.props.value);

	setAuthActAsConfig = () => {
		const { initialConfig = {} } = this.props;

		const { pathname } = history.location;

		this.props.setAuthActAsConfig({
			...initialConfig,
			urlPrefix: initialConfig.urlPrefix || pathname,
			leaveModeText:
				initialConfig.leaveModeText ||
				'You are leaving "Act as LPA" mode. All unsaved changes will be lost. Do you want to proceed?'
		});
	};

	setAuthActAsOptions = () => {
		if (this.props.options) {
			this.props.setAuthActAsOptions(this.props.options);
		}
	};

	render() {
		const { value, children } = this.props;

		// Do not mount children that should be fetching data
		// if value is set
		if (value || !children) {
			return null;
		}

		return <>{isFunction(children) ? children() : children}</>;
	}
}

export default connect(
	(state: AppState) => ({
		value: getAuthActAs(state)
	}),
	{
		setAuthCanActAs,
		setAuthActAsConfig,
		setAuthActAsOptions
	}
)(ActAsSwitcher);
