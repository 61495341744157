import { createSelector } from 'reselect';
import {
	getEventsById,
	getEventById,
	getPositionedRotationStep,
	getAllocatedOperations,
	getRotationStepByPosition
} from './';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';
import { AppState } from 'store-types';

/**
 * POI may have events up to 2 events.
 * If POI doesn't have custody line - all events should be outputted,
 * for those with custody line which is `during` POI - first event - for `Inbound` and last event - for `Outbound`
 */
export const getEventsByPosition = createSelector(
	[getRotationStepByPosition, getPositionedRotationStep, getEventsById],
	(rotationStep, rotationStepByPosition, eventsById) => {
		const { events } = rotationStep;
		if (rotationStepByPosition.hasCustodyLine) {
			const [openingEvent, closingEvent] = events;
			if (rotationStepByPosition.agentMode === 'Inbound') {
				if (!openingEvent || !eventsById[openingEvent]) {
					return [];
				}
				return [eventsById[openingEvent]];
			}
			if (rotationStepByPosition.agentMode === 'Outbound') {
				if (!closingEvent || !eventsById[closingEvent]) {
					return [];
				}
				return [eventsById[closingEvent]];
			}
		}
		return events.map((id: string) => eventsById[id]);
	}
);

export const getIsEventInStatus = createSelector(
	getEventById,
	(_state: AppState, _eventId: string, status: PortCallEventStatus) => status,
	(event, status) => event.status === status
);

export const getAllEventsByPosition = createSelector(
	[
		getPositionedRotationStep,
		getEventsByPosition,
		getAllocatedOperations,
		getEventsById
	],
	(rotationStepByPosition, events, allocatedOperations, eventsById) => {
		const operationUnitsEvents = allocatedOperations
			.filter(o => o.events.length)
			.map(o => o.events.map(e => eventsById[e]));
		if (events.length === 0 || !operationUnitsEvents.length) {
			return events;
		}

		// If POI has custody line, we should make sure events aren't duplicated and rendered under precise order with units,
		// 1. `Inbound` - first comes event, then units
		// 2. `Outbound` - first come units, then event
		// Other cases should be covered as if user is with `Full` control and POI may have up to 2 events
		if (rotationStepByPosition.hasCustodyLine) {
			if (rotationStepByPosition.agentMode === 'Inbound') {
				return [events[0], operationUnitsEvents];
			}
			if (rotationStepByPosition.agentMode === 'Outbound') {
				return [operationUnitsEvents, events[0]];
			}
		}
		if (events.length === 2) {
			return [events[0], operationUnitsEvents, events[1]];
		}
		return [events[0], operationUnitsEvents];
	}
);
