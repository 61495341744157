import { Action } from 'typescript-fsa';
import {
	put,
	call,
	take,
	takeLatest,
	fork,
	all,
	select,
	cancel,
	race,
	delay
} from 'redux-saga/effects';
import {
	updateDocumentProcessing,
	saveDocument,
	saveDocumentAsync,
	retrieveDocument,
	retrieveDocumentAsync,
	processDocumentAsync,
	abortProcessDocument,
	cancelUploadAndProcessDocument
} from '../actions';
import { getIsDocumentProcessingDone } from '../selectors';
import {
	SaveDocumentRequest,
	SaveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
const async = processDocumentAsync;

const worker = function*(payload: SaveDocumentResponse) {
	try {
		yield put(retrieveDocument(payload));
		const action = yield take(retrieveDocumentAsync.done);

		// Update status
		yield put(updateDocumentProcessing(action.payload.result));

		// Stop if processing is done based on document validation/split status
		const done = yield select(getIsDocumentProcessingDone);
		if (done) {
			yield all([
				put(
					async.done({
						params: null,
						result: action.payload.result,
						response: null
					})
				),

				// Proceed to task cancellation
				put(abortProcessDocument())
			]);
		}
	} catch (error) {
		// Proceed to task cancellation
		yield put(abortProcessDocument());
	}
};

const workerBgTask = function*(payload: SaveDocumentResponse) {
	while (true) {
		yield call(worker, payload);
		yield delay(3000);
	}
};

const mainWorker = function*(action: Action<SaveDocumentRequest>) {
	try {
		yield put(async.started(null));

		// Save document
		yield put(saveDocument({ ...action.payload }));
		const { done, failed } = yield race({
			done: take(saveDocumentAsync.done),
			failed: take(saveDocumentAsync.failed)
		});
		if (done) {
			const {
				payload: { result }
			} = done;
			// Begin checking for document status
			const task = yield fork(workerBgTask, result);

			/**
			 * Wait for cancellation
			 */
			yield take(abortProcessDocument.type);

			yield cancel(task);
		}
		if (failed) {
			throw failed.payload.error;
		}
	} catch (error) {
		yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));

		// Proceed to task cancellation
		yield put(abortProcessDocument());
	}
};

export default function*() {
	yield takeLatest(async.type, mainWorker);
}
