export enum KpiNameList {
	CONFIRM_JOB = 'Confirm Job',
	PROCESS_JOB = 'Process job',
	ACCEPT_APPOINTMENT = 'Accept appointment',

	SUBMIT_PDA = 'Submit PDA',
	VERIFY_PDA = 'Verify PDA',
	APPROVE_PDA = 'Approve PDA',
	SUBMIT_CE = 'Submit CE',
	VERIFY_CE = 'Verify CE',
	APPROVE_CE = 'Approve CE',
	UNASSIGNED_DA = 'UnassignedDA',
	SUBMIT_DA = 'Submit DA',
	VERIFY_DA = 'Verify DA',
	APPROVE_DA = 'Approve DA',
	REPLY_TO_HUB_QUERY_PDA = 'Reply to Hub Query (PDA)',
	REPLY_TO_HUB_QUERY_CE = 'Reply to Hub Query (CE)',
	REPLY_TO_HUB_QUERY_DA = 'Reply to Hub Query (DA)',
	REPLY_TO_CLIENT_QUERY_PDA = 'Reply to Client Query (PDA)',
	REPLY_TO_CLIENT_QUERY_CE = 'Reply to Client Query (CE)',
	REPLY_TO_CLIENT_QUERY_DA = 'Reply to Client Query (DA)',
	FUNDING_REQUEST_PENDING_REVIEW = 'Funding request pending review',

	EVENTS_UPDATE = 'Events update',
	DOCUMENTS_UPDATE = 'Documents update',
	PORT_CALL_DUPLICATES = 'Port call duplicates',
	CARGO_MEASUREMENTS_UPDATES = 'Cargo Measurement updates',
	BUNKERING_MEASUREMENTS_UPDATES = 'Bunkering Measurement updates',
	SHIP_MEASUREMENTS_UPDATES_ON_ARRIVAL = 'Ship measurement updates on arrival',
	SHIP_MEASUREMENTS_UPDATES_ON_DEPARTURE = 'Ship measurement updates on departure',
	PDA_REQUESTED = 'PDARequested',
	WAITING_FOR_APPOINTMENT = 'WaitingForAppointment',
	WAITING_FOR_INVITATION = 'WaitingForInvitation'
}

export const kpiNameDisplayListMap = {
	[KpiNameList.CONFIRM_JOB]: 'Awaiting confirmation',
	[KpiNameList.PROCESS_JOB]: 'Process pending',
	[KpiNameList.ACCEPT_APPOINTMENT]: 'Acceptance pending',

	[KpiNameList.SUBMIT_PDA]: 'PDA pending',
	[KpiNameList.VERIFY_PDA]: 'PDA verification pending',
	[KpiNameList.APPROVE_PDA]: 'PDA approval pending',
	[KpiNameList.SUBMIT_CE]: 'CE pending',
	[KpiNameList.VERIFY_CE]: 'CE verification pending',
	[KpiNameList.APPROVE_CE]: 'CE approval pending',
	[KpiNameList.SUBMIT_DA]: 'DA pending',
	[KpiNameList.VERIFY_DA]: 'DA verification pending',
	[KpiNameList.UNASSIGNED_DA]: 'DA unassigned',
	[KpiNameList.APPROVE_DA]: 'DA approval pending',
	[KpiNameList.REPLY_TO_HUB_QUERY_PDA]: 'PDA queried (LPA)',
	[KpiNameList.REPLY_TO_HUB_QUERY_DA]: 'DA queried (LPA)',
	[KpiNameList.REPLY_TO_HUB_QUERY_CE]: 'CE queried (LPA)',
	[KpiNameList.REPLY_TO_CLIENT_QUERY_PDA]: 'PDA queried (HUB)',
	[KpiNameList.REPLY_TO_CLIENT_QUERY_DA]: 'DA queried (HUB)',
	[KpiNameList.REPLY_TO_CLIENT_QUERY_CE]: 'CE queried (HUB)',
	[KpiNameList.FUNDING_REQUEST_PENDING_REVIEW]: 'Funding validation pending',

	[KpiNameList.EVENTS_UPDATE]: 'Events',
	[KpiNameList.DOCUMENTS_UPDATE]: 'Documents',
	[KpiNameList.PORT_CALL_DUPLICATES]: 'Portcall duplicates',
	[KpiNameList.CARGO_MEASUREMENTS_UPDATES]: 'Measurements',
	[KpiNameList.BUNKERING_MEASUREMENTS_UPDATES]: 'Bunkering Measurement updates',
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_ARRIVAL]:
		'Ship measurement updates on arrival',
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_DEPARTURE]:
		'Ship measurement updates on departure',
	[KpiNameList.PDA_REQUESTED]: 'PDA requested',
	[KpiNameList.WAITING_FOR_APPOINTMENT]: 'Appointment pending',
	[KpiNameList.WAITING_FOR_INVITATION]: 'Invitation pending'
};

export const opticConfigTooltipMap = {
	[KpiNameList.CONFIRM_JOB]: `KPI triggered when "Job Status = Created"`,
	[KpiNameList.PROCESS_JOB]: `KPI triggered when "Job Status = Confirmed"`,
	[KpiNameList.ACCEPT_APPOINTMENT]: `KPI triggered when "Job Status =Appointed"`,

	[KpiNameList.SUBMIT_PDA]: `KPI triggered when "(Job Status = PDA or Execution) AND Finance Status = Draft"`,
	[KpiNameList.VERIFY_PDA]: `KPI triggered when "(Job Status = PDA or Execution) AND Finance Status = Submitted"`,
	[KpiNameList.APPROVE_PDA]: `KPI triggered when "(Job Status = PDA or Execution) AND Finance Status = Verified"`,
	[KpiNameList.SUBMIT_CE]: `KPI triggered when "Job Status = CE AND Finance Status = Draft"`,
	[KpiNameList.VERIFY_CE]: `KPI triggered when "Job Status = CE AND Finance Status = Submitted"`,
	[KpiNameList.APPROVE_CE]: `KPI triggered when "Job Status = CE AND Finance Status = Verified"`,
	[KpiNameList.SUBMIT_DA]: `KPI triggered when "Job Status = DA AND Finance Status = Draft"`,
	[KpiNameList.VERIFY_DA]: `KPI triggered when "Job Status = DA AND Finance Status = Submitted"`,
	[KpiNameList.UNASSIGNED_DA]: `KPI triggered when "Port call job not assigned for DA verification"`,
	[KpiNameList.APPROVE_DA]: `KPI triggered when "Job Status = DA AND (DA OUT status = (Submitted OR Principal Submission pending)) AND (all Revenue service, including Credit ones, assigned to that DA OUT have Post transactions (last non-cancelled direct or credit transaction, depending on the revenue service) in Complete status)"`,
	[KpiNameList.REPLY_TO_HUB_QUERY_PDA]: `KPI triggered when "Job status = PDA/Execution AND Finance status= 'Queried' ('QueriedToLPA'=true; QueriedToHub = any)"`,
	[KpiNameList.REPLY_TO_HUB_QUERY_DA]: `KPI triggered when "Job status = DA AND Finance status= 'Queried' ('QueriedToLPA'=true; QueriedToHub = any)"`,
	[KpiNameList.REPLY_TO_HUB_QUERY_CE]: `KPI triggered when "Job status = CE AND Finance status= 'Queried' ('QueriedToLPA'=true; QueriedToHub = any)"`,
	[KpiNameList.REPLY_TO_CLIENT_QUERY_PDA]: `KPI triggered when "Job status = PDA/Execution AND Finance status='Queried' ('QueriedToLPA'=any; QueriedToHub = true)"`,
	[KpiNameList.REPLY_TO_CLIENT_QUERY_DA]: `KPI triggered when "Job status = DA AND Finance status='Queried' ('QueriedToLPA'=any; QueriedToHub = true)"`,
	[KpiNameList.REPLY_TO_CLIENT_QUERY_CE]: `KPI triggered when "Job status = CE AND Finance status='Queried' ('QueriedToLPA'=any; QueriedToHub = true)"`,
	[KpiNameList.FUNDING_REQUEST_PENDING_REVIEW]: `KPI triggered when "Funding request status = Pending review AND Job status = PDA/Execution/CE"`,

	[KpiNameList.EVENTS_UPDATE]: `KPI triggered when "Any job's event the agent is responsible for is in status 'Estimated' AND Event EstimatedDate < Current time (all dates/time are in port time zone)"`,
	[KpiNameList.DOCUMENTS_UPDATE]: `KPI triggered when "Current Port Time > Vessel Estimated or Actual sailing time AND there are placeholders with no real mandatory documents uploaded for the job agent is responsible for"`,
	[KpiNameList.PORT_CALL_DUPLICATES]: `KPI triggered when "Port call has potential duplicates"`,
	[KpiNameList.CARGO_MEASUREMENTS_UPDATES]: `KPI triggered when "Current Port Time > Actual Sailing time (COSP) AND there are missing cargo measurements"`,
	[KpiNameList.BUNKERING_MEASUREMENTS_UPDATES]: `KPI triggered when "Current Port Time > Actual Sailing time (COSP) AND there are missing bunkering measurements"`,
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_ARRIVAL]: `KPI triggered when "(Current Port Time > Estimated or Actual Berthing Time (if exists)) AND there are missing mandatory fields for measurement for Arrival in the port call"`,
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_DEPARTURE]: `KPI triggered when "Current Port Time > Actual Sailing time (COSP) AND there are missing mandatory fields for measurement for Departure in the port call"`,
	[KpiNameList.PDA_REQUESTED]: `KPI triggered when "Job Status = PDA Requested"`,
	[KpiNameList.WAITING_FOR_APPOINTMENT]: `KPI triggered when "Job Status = Appointment pending"`,
	[KpiNameList.WAITING_FOR_INVITATION]: `KPI triggered when "Job Status = Invitation pending"`
};

export enum KPIGroup {
	JOB_KPIS = 'Job Status',
	FINANCE_KPIS = 'Finance',
	OPERATION_KPIS = 'Operations'
}

export const kpiGroupsIndex = {
	[KpiNameList.CONFIRM_JOB]: {
		groupName: KPIGroup.JOB_KPIS,
		index: 'JOB01'
	},
	[KpiNameList.PROCESS_JOB]: {
		groupName: KPIGroup.JOB_KPIS,
		index: 'JOB02'
	},
	[KpiNameList.ACCEPT_APPOINTMENT]: {
		groupName: KPIGroup.JOB_KPIS,
		index: 'JOB03'
	},
	[KpiNameList.SUBMIT_PDA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN01'
	},
	[KpiNameList.VERIFY_PDA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN02'
	},
	[KpiNameList.APPROVE_PDA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN03'
	},
	[KpiNameList.SUBMIT_CE]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN04'
	},
	[KpiNameList.VERIFY_CE]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN05'
	},
	[KpiNameList.APPROVE_CE]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN06'
	},
	[KpiNameList.UNASSIGNED_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN07'
	},
	[KpiNameList.SUBMIT_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN08'
	},
	[KpiNameList.VERIFY_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN09'
	},
	[KpiNameList.APPROVE_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN10'
	},
	[KpiNameList.REPLY_TO_HUB_QUERY_PDA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN11'
	},
	[KpiNameList.REPLY_TO_HUB_QUERY_CE]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN12'
	},
	[KpiNameList.REPLY_TO_HUB_QUERY_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN13'
	},
	[KpiNameList.REPLY_TO_CLIENT_QUERY_PDA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN14'
	},
	[KpiNameList.REPLY_TO_CLIENT_QUERY_CE]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN15'
	},
	[KpiNameList.REPLY_TO_CLIENT_QUERY_DA]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN16'
	},
	[KpiNameList.FUNDING_REQUEST_PENDING_REVIEW]: {
		groupName: KPIGroup.FINANCE_KPIS,
		index: 'FIN17'
	},
	[KpiNameList.EVENTS_UPDATE]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE01'
	},
	[KpiNameList.DOCUMENTS_UPDATE]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE02'
	},
	[KpiNameList.PORT_CALL_DUPLICATES]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE03'
	},
	[KpiNameList.CARGO_MEASUREMENTS_UPDATES]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE04'
	},
	[KpiNameList.BUNKERING_MEASUREMENTS_UPDATES]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE05'
	},
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_ARRIVAL]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE06'
	},
	[KpiNameList.SHIP_MEASUREMENTS_UPDATES_ON_DEPARTURE]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE07'
	},
	[KpiNameList.PDA_REQUESTED]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE08'
	},
	[KpiNameList.WAITING_FOR_APPOINTMENT]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE09'
	},
	[KpiNameList.WAITING_FOR_INVITATION]: {
		groupName: KPIGroup.OPERATION_KPIS,
		index: 'OPE10'
	}
};

export enum AlertPermissions {
	MANAGE_PDA_CE = 'ManagePDACE',
	MANAGE_PORTCALL = 'ManagePortCall',
	VIEW_PORTCALLS = 'ViewPortCalls',
	VIEW_FINANCE = 'ViewFinance',
	MANAGE_DA = 'ManageDA',
	APPROVE_ASSIGNED_DA = 'ApproveAssignedDA',
	MANAGE_OPERATIONS = 'ManageOperations',
	APPOINT_AGENT = 'AppointAgent'
}

export enum AlertPermissionsCode {
	APPROVE_PDA_CE_LEVEL_1 = 'ApprovePDACELevel1',
	APPROVE_PDA_CE_LEVEL_2 = 'ApprovePDACELevel2',
	APPROVE_PDA_CE_LEVEL_3 = 'ApprovePDACELevel3',
	APPROVE_PDA_CE_LEVEL_4 = 'ApprovePDACELevel4',
	APPROVE_PDA_CE_LEVEL_5 = 'ApprovePDACELevel5',
	APPROVE_DA_ALL_LEVEL_1 = 'ApproveDAallLevel1',
	APPROVE_DA_ALL_LEVEL_2 = 'ApproveDAallLevel2',
	APPROVE_DA_ALL_LEVEL_3 = 'ApproveDAallLevel3',
	APPROVE_DA_ALL_LEVEL_4 = 'ApproveDAallLevel4',
	APPROVE_DA_ALL_LEVEL_5 = 'ApproveDAallLevel5',
	APPROVE_ASSIGNED_DA_LEVEL_1 = 'ApproveAssignedDALevel1',
	APPROVE_ASSIGNED_DA_LEVEL_2 = 'ApproveAssignedDALevel2',
	APPROVE_ASSIGNED_DA_LEVEL_3 = 'ApproveAssignedDALevel3',
	APPROVE_ASSIGNED_DA_LEVEL_4 = 'ApproveAssignedDALevel4',
	APPROVE_ASSIGNED_DA_LEVEL_5 = 'ApproveAssignedDALevel5'
}

export const AlertPermissionsMap = {
	[AlertPermissionsCode.APPROVE_PDA_CE_LEVEL_1]: `Approve PDA/CE - 1th Level Approval`,
	[AlertPermissionsCode.APPROVE_PDA_CE_LEVEL_2]: `Approve PDA/CE - 2th Level Approval`,
	[AlertPermissionsCode.APPROVE_PDA_CE_LEVEL_3]: `Approve PDA/CE - 3th Level Approval`,
	[AlertPermissionsCode.APPROVE_PDA_CE_LEVEL_4]: `Approve PDA/CE - 4th Level Approval`,
	[AlertPermissionsCode.APPROVE_PDA_CE_LEVEL_5]: `Approve PDA/CE - 5th Level Approval`,
	[AlertPermissionsCode.APPROVE_DA_ALL_LEVEL_1]: `Approve all DA (all) - 1th  Level Approval`,
	[AlertPermissionsCode.APPROVE_DA_ALL_LEVEL_2]: `Approve all DA (all) - 2th  Level Approval`,
	[AlertPermissionsCode.APPROVE_DA_ALL_LEVEL_3]: `Approve all DA (all) - 3th  Level Approval`,
	[AlertPermissionsCode.APPROVE_DA_ALL_LEVEL_4]: `Approve all DA (all) - 4th  Level Approval`,
	[AlertPermissionsCode.APPROVE_DA_ALL_LEVEL_5]: `Approve all DA (all) - 5th  Level Approval`,
	[AlertPermissionsCode.APPROVE_ASSIGNED_DA_LEVEL_1]: `Approve assigned DA - 1nd Level Approval`,
	[AlertPermissionsCode.APPROVE_ASSIGNED_DA_LEVEL_2]: `Approve assigned DA - 2nd Level Approval`,
	[AlertPermissionsCode.APPROVE_ASSIGNED_DA_LEVEL_3]: `Approve assigned DA - 3nd Level Approval`,
	[AlertPermissionsCode.APPROVE_ASSIGNED_DA_LEVEL_4]: `Approve assigned DA - 4nd Level Approval`,
	[AlertPermissionsCode.APPROVE_ASSIGNED_DA_LEVEL_5]: `Approve assigned DA - 5nd Level Approval`
};
