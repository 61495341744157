import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';
import { getIsLoading } from 'store/selectors';

export const getPortCallMeasurementsAccess = (
	state: AppState,
	portCallId: string
) => state.portCallMeasurements.access[portCallId];

export const getPortCallBunkeringMeasurementsFetchStatus = (state: AppState) =>
	state.portCallMeasurements.fetchStatuses.bunkering;

export const isUpdatingBunkeringOperationGrade = (
	state: AppState,
	id: string
) =>
	state.portCallMeasurements.updateStatuses.bunkering[id] ===
	FetchStatus.PENDING;

export const isUpdatingShipMeasurements = (state: AppState, type: string) =>
	state.portCallMeasurements.updateStatuses.ship[type] === FetchStatus.PENDING;

export const isUpdatingCargoMeasurements = (state: AppState, id: string) =>
	getIsLoading(state.portCallMeasurements.updateStatuses.cargo[id]);
