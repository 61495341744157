import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	MoveToStageJobFinanceResponse,
	FinanceRequest
} from 'services/api/finance/financeServiceTypes';
import { onResetComparisonColumn } from './resetComparisonColumnReducer';

export const moveToStage = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	FinanceRequest,
	MoveToStageJobFinanceResponse
>('fetchStatuses', 'moveToStage', {
	doneReducer: onResetComparisonColumn
});
