import React from 'react';
import AutocompleteEntity, {
	AutocompleteEntityProps
} from '../AutocompleteEntity';
import Api from 'services/api';
import { LabeledValue } from 'app-types';

interface AutocompletePortsProps extends AutocompleteEntityProps {
	onSelect?: (value: LabeledValue) => void;
	hasAny?: boolean;
}

class AutocompletePorts extends React.Component<AutocompletePortsProps> {
	onSearch = (searchTerm: string) => {
		const { hasAny = false } = this.props;
		return Api.Ports.searchPortsForAutocomplete(searchTerm).then(results => {
			if (hasAny) {
				return [{ id: 'Any', name: 'Any' }, ...results];
			}
			return results;
		});
	};

	onSelect = (value: LabeledValue) => {
		if (this.props.onSelect) {
			this.props.onSelect(value);
		}
	};

	render() {
		return (
			<AutocompleteEntity
				{...this.props}
				onSearch={this.onSearch}
				onSelect={this.onSelect}
			/>
		);
	}
}

export default AutocompletePorts;
