import { SagaIterator } from 'redux-saga';
import { Effect, call, put } from 'redux-saga/effects';
import { AsyncActionCreators, Success } from 'typescript-fsa';
import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';
import {
	DownloadExecutorConfig,
	downloadExecutor
} from 'store/documents/sagas/pollDownloadUrlSaga';
import { isFunction } from 'lodash';

interface PollDownloadExecutorConfig<P, S, E> {
	async: AsyncActionCreators<P, S, E>;
	POSTApi: DownloadExecutorConfig<P>['POSTApi'];
	GETApi: DownloadExecutorConfig<P>['GETApi'];
	onSuccess?: (action: Success<P, S>) => SagaIterator;
	onError?: (params: P, error: Error) => SagaIterator;
	getApiParams?: (params: P) => IterableIterator<Effect | Effect[]>;
}

/**
 * Returns an executor (SagaIterator) which handles the
 * POST & Polling to the API automatically as well as dispatching
 * the async actions against the store
 *
 * TODO move `makePollDownloadExecutor` out of this file
 */
export function makePollDownloadExecutor<P, S, E>(
	config: PollDownloadExecutorConfig<P, S, E>
) {
	const { async, POSTApi, GETApi, onSuccess, onError, getApiParams } = config;

	return function* executor(params: P): SagaIterator {
		yield put(async.started(params));
		try {
			const portCallParams = yield call(getActivePortCallAndPortJobIdsExecutor);

			const apiParams: P = getApiParams
				? yield call(getApiParams, params)
				: params;

			const payload = {
				result: Object.assign({}, portCallParams, apiParams),
				params: apiParams
			} as Success<P, S>;

			const downloadUrl = yield call(downloadExecutor, {
				params: payload.result,
				POSTApi,
				GETApi
			});

			yield put(async.done({ params, result: downloadUrl, response: null }));
			if (isFunction(onSuccess)) {
				yield call(onSuccess, payload);
			}
		} catch (error) {
			yield put(
				async.failed({
					error,
					params
				})
			);
			if (isFunction(onError)) {
				yield call(onError, params, error);
			}
		}
	};
}
