import {
	RetrieveCompaniesContactDetailsRequest,
	Company,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading, getIsDone } from 'store/selectors';
import {
	getIsCurrentUsersGroupTypeISSCluster,
	getIsCurrentUserPrincipal,
	getUserCompanyId
} from 'store/auth/selectors';

import { getPortCallForm } from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import { get } from 'lodash';
import { PortJobTypeCode } from 'services/api/portJobs/portJobsServiceTypes';
import { getContactDetailsCompany } from 'sections/PortJob/CreatePortJob/Pages/Page2/page2Selectors';

const getCompaniesState = (state: AppState) => state.companies;

export const getCompaniesHasMore = (state: AppState) =>
	getCompaniesState(state).hasMore;

export const getCompanyJobTypes = (state: AppState) =>
	getCompaniesState(state).jobTypes;

export const getCompanyJobTypesFetchStatus = (state: AppState) =>
	getCompaniesFetchStatuses(state).retrieveCompanyJobTypes;

export const getCompaniesMapSelector = (state: AppState) =>
	getCompaniesState(state).byId;

export const getPrincipals = (state: AppState) => state.companies.principals;

export const getCompaniesFetchStatuses = (state: AppState) =>
	getCompaniesState(state).fetchStatuses;

const getCompaniesConfigurationSettings = (state: AppState) =>
	getCompaniesState(state).configurationSettings;

export const getIsRetrieveGeneralConfigurationReportsDoneLoading = (
	state: AppState
) =>
	getIsDone(
		getCompaniesState(state).fetchStatuses.retrieveGeneralConfigurationReports
	);

export const getCompanyHasAccessToReportServer = (
	state: AppState,
	companyId: string
): boolean | null => {
	const reports = getCompaniesState(state).generalConfiguration.reports[
		companyId
	];
	return reports ? reports.hasAccessToReportServer : null;
};

export const getIsRetrieveGeneralConfigurationBenchmarkDoneLoading = (
	state: AppState
) => getIsDone(getCompaniesState(state).fetchStatuses.retrieveCompanyBenchmark);

export const getCompanyHasAccessToBlt = (
	state: AppState,
	companyId: string
): boolean | null => {
	const benchmark = getCompaniesState(state).generalConfiguration.benchmark[
		companyId
	];
	return benchmark ? benchmark.isBenchmarksEnabled : null;
};

export const getCentralInboxEmailDomain = createSelector(
	getCompaniesConfigurationSettings,
	settings => (settings ? settings.centralInboxEmailDomain : '')
);

export const isFetchingCompaniesSelector = (state: AppState) =>
	getIsLoading(state.companies.fetchStatuses.all);

export const getCompanyPreferredAgentsFileInfo = (
	state: AppState,
	companyId: string
) => getCompaniesState(state).preferredAgentsFileInfo[companyId] || [];

export const getCompanyPreferredAgentsFileInfoFetchStatus = (state: AppState) =>
	getCompaniesFetchStatuses(state).retrievePreferredAgentsFileInfo;

/**
 * Return a filtered list of companies by a given (company) role
 */
function getByRole(company: Company, providedRole: OrganisationType) {
	return company.organisationTypes.filter(
		organisationType => organisationType.organisationTypeCode === providedRole
	);
}

export const getCompanyById = (state: AppState, id: string) =>
	getCompaniesState(state).byId[id];

export const getCompanyForUserById = (state: AppState, id: string) =>
	state.companiesForUser.byId[id];

/**
 * Companies
 */
export const getAllCompaniesSelector = createSelector(
	getCompaniesMapSelector,
	(byId): Company[] => Object.values(byId)
);

export const getCompaniesCount = createSelector(
	getAllCompaniesSelector,
	companies => companies.length
);

/**
 * Retrieve Companies by role
 */
const getCompaniesByRole = (providedRole: OrganisationType) =>
	createSelector(getAllCompaniesSelector, (companies: Company[]) => {
		return companies.filter(company => getByRole(company, providedRole).length);
	});

export const getGroupTypes = (state: AppState) =>
	state.groups.groups.groupTypes;

export const getISSCompanyId = createSelector(
	getAllCompaniesSelector,
	companies => {
		const IssCompany = companies.find(company => company.companyName === 'ISS');
		return IssCompany ? IssCompany.id : undefined;
	}
);

export const isCompanyMainPrincipal = (
	state: AppState,
	companyId: string
): boolean => {
	return (
		Boolean(companyId) &&
		getCompaniesByRole(OrganisationType.MAIN_PRINCIPAL)(state)
			.map(c => c.id)
			.includes(companyId)
	);
};

export const getCompanyIds = createSelector(
	getAllCompaniesSelector,
	(_state: AppState, companyIds: string[]) => companyIds,
	(companies, ids) => {
		return companies
			.map(company => company.id)
			.filter(companyId => ids.includes(companyId));
	}
);

export const getCompanyIdsByCompanyIds = createSelector(
	getAllCompaniesSelector,
	(_state: AppState, companyIds: string[]) => companyIds,
	(companies, ids: string[]): string[] => {
		return companies
			.filter(company => ids.includes(company.id))
			.map(company => company.id);
	}
);

export const filterCompaniesByOrganisationTypes = (
	companies: Company[],
	organisationTypes: OrganisationType[]
): Company[] => {
	return companies.filter(company =>
		Boolean(
			company.organisationTypes.find(organisationType =>
				organisationTypes.includes(organisationType.organisationTypeCode)
			)
		)
	);
};

export const getMainPrincipalCompanies = createSelector(
	getAllCompaniesSelector,
	companies =>
		filterCompaniesByOrganisationTypes(companies, [
			OrganisationType.MAIN_PRINCIPAL
		])
);

/**
 * Contact Details
 **/
export const getCompaniesContactDetailsSelector = (state: AppState) =>
	getCompaniesState(state).contactDetails;

const logEmptyCompanyIdErrorMessage = (field: string) =>
	// eslint-disable-next-line
	console.error(`Theres no company id in field: ${field}`);

/**
 * Get params for Field.AppointerContact in `contactDetailsCompany` prop
 */
export const getCompaniesContactDetailsRequestParamsSelector = createSelector(
	getPortCallForm,
	getIsCurrentUserPrincipal,
	getIsCurrentUsersGroupTypeISSCluster,
	getContactDetailsCompany,
	getUserCompanyId,
	(
		form,
		isUserPrincipal,
		isUserGroupISSCluster,
		contactDetailsCompany,
		principalCompanyId
	): RetrieveCompaniesContactDetailsRequest => {
		let params: RetrieveCompaniesContactDetailsRequest = {
			includingChildCompanies: false,
			getParentCompany: true
		};
		const portJobType: PortJobTypeCode = get(form.values, 'jobTypeId.key');

		if (!contactDetailsCompany) {
			return params;
		}
		if (isUserPrincipal) {
			const hubPrincipalCompany =
				form.values[FormFieldName.HUB_PRINCIPAL_COMPANY];
			const companyId = hubPrincipalCompany
				? hubPrincipalCompany.key
				: principalCompanyId;
			params = {
				companyId,
				includingChildCompanies: true
			};
		} else {
			if (isUserGroupISSCluster) {
				const appointerCompany = form.values[FormFieldName.APPOINTER_COMPANY];
				const nominator = form.values[FormFieldName.NOMINATOR];
				const companyId =
					portJobType === PortJobTypeCode.APPOINTMENT
						? appointerCompany.key
						: nominator.key;

				if (!companyId) {
					logEmptyCompanyIdErrorMessage(FormFieldName.HUB_PRINCIPAL_COMPANY);
				}

				params = {
					companyId,
					includingChildCompanies: false,
					getParentCompany: true
				};
			} else if (form.values[FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL]) {
				const appointerCompany = form.values[FormFieldName.APPOINTER_COMPANY];
				const nominator = form.values[FormFieldName.NOMINATOR];
				const companyId =
					portJobType === PortJobTypeCode.APPOINTMENT
						? appointerCompany?.key
						: nominator?.key;

				if (!companyId) {
					logEmptyCompanyIdErrorMessage(FormFieldName.HUB_PRINCIPAL_COMPANY);
				}

				params = {
					companyId,
					includingChildCompanies: true,
					getParentCompany: true
				};
			} else {
				const hubPrincipalCompany =
					form.values[FormFieldName.HUB_PRINCIPAL_COMPANY];
				const companyId = hubPrincipalCompany?.key;

				if (!companyId) {
					logEmptyCompanyIdErrorMessage(FormFieldName.HUB_PRINCIPAL_COMPANY);
				}

				params = {
					companyId,
					includingChildCompanies: true
				};
			}
		}
		return params;
	}
);

export const filterContacts = (
	input: string,
	option: React.ReactElement<{ children: string }>,
	anotherContactId: string
) => {
	const { children } = option.props;

	return (
		children.toLowerCase().includes(input.toLowerCase()) &&
		option.key !== anotherContactId
	);
};
