import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import {
	ShipMeasurementsType,
	UpdateShipMeasurementsRequest
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { Failure, Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';

const getSetUpdateStatuses = (
	state: State,
	status: FetchStatus,
	measurementType: ShipMeasurementsType
) => {
	return {
		...state.updateStatuses,
		ship: {
			...state.updateStatuses.ship,
			[measurementType]: status
		}
	};
};

export const onUpdateShipMeasurementStarted = (
	state: State,
	{ updatingData }: UpdateShipMeasurementsRequest
) => {
	const { ship } = state;
	if (!ship) {
		return state;
	}
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.PENDING,
		updatingData.measurementType
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdateShipMeasurementsSuccess = (
	state: State,
	action: Success<UpdateShipMeasurementsRequest, null>
) => {
	const { updatingData } = action.params;
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.SUCCESS,
		updatingData.measurementType
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdateShipMeasurementsFailed = (
	state: State,
	action: Failure<UpdateShipMeasurementsRequest, Error>
) => {
	const { updatingData } = action.params;
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.FAILURE,
		updatingData.measurementType
	);
	return {
		...state,
		updateStatuses,
		error: action.error
	};
};
