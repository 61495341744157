import {
	PortCall,
	PortCallStatus
} from 'services/api/portCalls/portCallsServiceTypes';
import { portCallStatusOrdered } from 'services/api/portCalls/portCallsConsts';
import { getActivePortCall } from 'store/portcalls/selectors';
import { AppState } from 'store-types';

const getSafetyActivePortCall = (state: AppState) =>
	getActivePortCall(state) || ({} as PortCall);

export const getIsActivePortCallStatusEqualOrAboveStatus = (
	state: AppState,
	status: PortCallStatus
) =>
	portCallStatusOrdered.indexOf(getSafetyActivePortCall(state).status) >=
	portCallStatusOrdered.indexOf(status);

export const getIsActivePortCallStatusBelowStatus = (
	state: AppState,
	status: PortCallStatus
) =>
	portCallStatusOrdered.indexOf(getSafetyActivePortCall(state).status) <
	portCallStatusOrdered.indexOf(status);

export const getIsPossibleToUpdateActivePortCallInTermsOfStatus = (
	state: AppState
) => getIsActivePortCallStatusBelowStatus(state, PortCallStatus.ARRIVED);

export const isPortCallInClosedStatus = (state: AppState) =>
	getSafetyActivePortCall(state).status === PortCallStatus.CLOSED;
