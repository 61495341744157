import React from 'react';
import { omit } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import {
	ContextBarButtonAction,
	ContextBarsActionCode,
	ContextBarView
} from 'store/contextBars/contextBarsTypes';
import { contextBarsActionMap } from 'store/contextBars/constants';
import { AlertProps } from 'antd/lib/alert';
import {
	getPortCallPrincipalEmail,
	getEmailSubject
} from 'store/portJobs/selectors';
import { getTransformedContextBars } from 'store/contextBars/selectors/contextBarsSelector';
import NewActionAlert from 'components/ActionAlert/NewActionAlert';

interface EmailOptions {
	linkText: string;
	linkEmail: string;
	emailSubject: string;
}

interface ContextBarProps {
	title: string;
	subtitle: string;
	type: AlertProps['type'];
	emailLinkProps?: EmailOptions;
	buttons?: ContextBarButtonAction[];
}

const ContextBar = function(props: ContextBarView) {
	const { actionCodes } = props;
	const dispatch = useDispatch();
	const linkEmail = useSelector(getPortCallPrincipalEmail);
	const emailSubject = useSelector(getEmailSubject);
	const alertProps: ContextBarProps = {
		...omit(props, 'actionCodes')
	};
	if (actionCodes?.length) {
		const hasEmailAction = actionCodes.includes(
			ContextBarsActionCode.EMAIL_TO_MAIN_PRINCIPAL
		);
		if (hasEmailAction) {
			alertProps.emailLinkProps = {
				linkText: 'Request Assistance',
				linkEmail,
				emailSubject
			};
		} else {
			alertProps.buttons = actionCodes.map((code: ContextBarsActionCode) => {
				const actionData = contextBarsActionMap[code];
				return {
					...actionData,
					action: () => actionData.action(dispatch)
				};
			});
		}
	}

	return <NewActionAlert {...alertProps} />;
};

export function ContextBars() {
	const transformedContextBars = useSelector(getTransformedContextBars);

	return (
		<div>
			{transformedContextBars.map((bar, index) => (
				<ContextBar key={index} {...bar} />
			))}
		</div>
	);
}

export default ContextBars;
