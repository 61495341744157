import { SupplierInvoiceState } from '../supplierInvoiceState';

export function onResetActiveSupplierInvoice(
	state: SupplierInvoiceState
): SupplierInvoiceState {
	return {
		...state,
		activeSupplierInvoice: undefined
	};
}
