import { FinanceState } from '../financeState';
import {
	UpdateUnitCostForm,
	UpdateUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateUnitCostForm = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateUnitCostForm,
	UpdateUnitCostFormResponse
>('fetchStatuses', 'updateUnitForm');
