import { AxiosTypedResponse } from 'services/api/apiTypes';
import { RetrieveThreadsResponse } from 'services/api/threads/threadsServiceTypes';
import {
	getThreadsCountSelector,
	getThreadsFilters,
	getThreadsCurrentAmount
} from '../../selectors';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	retrieveThreads as syncAction,
	retrieveThreadsAsync as asyncAction
} from '../../actions/retrieveThreads';
import Api from 'services/api';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import prepareRequest from './prepareRequest/prepareRequest';

const apiCall = Api.Threads.retrieveThreads;

export function* executor(api: typeof apiCall): SagaIterator {
	const count = yield select(getThreadsCountSelector);
	const limit = yield select(getThreadsCurrentAmount);
	const filters = yield select(getThreadsFilters);
	const extraRequestParams = prepareRequest(filters);

	const params = {
		limit: limit || DEFAULT_LIST_LIMIT,
		index: count,
		sortBy: 'UpdatedOn:desc',
		...extraRequestParams
	};

	yield put(asyncAction.started(params));

	try {
		const response: AxiosTypedResponse<RetrieveThreadsResponse> = yield call(
			api,
			params
		);
		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params
			})
		);
	}
}

function* worker(): SagaIterator {
	yield call(executor, apiCall);
}

export default function*(): SagaIterator {
	yield takeLatest(syncAction.type, worker);
}
