import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Col, Select } from 'components/antd';
import { FiltersStateAndHelpers } from 'components/Filters/Filters';
import { FilterItem } from 'store/filters/filtersState';
import { GroupsFiltersParam } from 'store/groups/filtersSync';
import styles from './GroupsFilters.module.scss';
import { getUserGroupType } from 'store/auth/selectors';
import {
	GroupSearchResultItem,
	GroupTypeCodes
} from 'services/api/groups/groupsServiceTypes';
import { AutocompleteSearch } from 'components';
import { ItemObject } from 'components/AutocompleteSearch/AutocompleteSearchTypes';
import {
	GroupFilters,
	GroupFilterSearchByType
} from 'store/groups/groupsState';

import Api from 'services/api';
import { groupsFiltersTypeMap } from 'sections/Groups/GroupsConstants';

interface GroupsFiltersProps extends FiltersStateAndHelpers {
	filters: GroupFilters;
}

const GroupsFilters: FC<GroupsFiltersProps> = ({
	filters,
	onChange,
	clearOne
}) => {
	const userGroup = useSelector(getUserGroupType);

	const onFilterSelect = useCallback(
		(filter: FilterItem, name: string, override?: boolean) => {
			const oldFilter = Object(
				filters.search?.find(value => value.type === 'Search')
			);
			if (oldFilter?.type && filter.type === 'Search') {
				clearOne(oldFilter as FilterItem);
			}
			onChange({ [name]: [filter] }, override);
		},
		[filters, clearOne, onChange]
	);

	const onFilterChange = useCallback(
		(name: string) => (filter: FilterItem) => {
			onFilterSelect({ ...filter, type: name }, name);
		},
		[onFilterSelect]
	);

	const onChangeSearch = (
		filter: FilterItem,
		groupType: GroupFilterSearchByType
	) => {
		let key = filter.key;
		if (groupType === 'Search') {
			key = filter.label;
		}
		onFilterSelect({ ...filter, type: groupType, key }, 'search', false);
	};

	const itemToString = (item: ItemObject<GroupSearchResultItem>) => {
		if (!item) {
			return '';
		}
		return item.label;
	};

	const groupList =
		userGroup === GroupTypeCodes.ISS_ADMIN
			? [
					{
						key: 'All',
						value: 'All'
					},
					{
						key: 'Principal',
						value: 'Principal'
					},
					{
						key: 'ISSGroupHolding',
						value: 'ISS Group Holdings Ltd'
					},
					{
						key: 'ThirdPartyLPA',
						value: '3rd Party LPA'
					}
			  ]
			: [
					{
						key: 'Principal',
						value: 'Principal'
					}
			  ];
	return (
		<div className={styles.root}>
			<Col xs={6}>
				<AutocompleteSearch
					placeholder="Start typing to search by group name or group company"
					onChange={onChangeSearch}
					itemToString={itemToString}
					typeMap={groupsFiltersTypeMap}
					onSearch={Api.Groups.searchGroupsForAutocomplete}
				/>
			</Col>

			<Col xs={2}>
				<Select
					value={filters?.[GroupsFiltersParam.GROUP_TYPE]?.[0]}
					labelInValue
					onChange={onFilterChange(GroupsFiltersParam.GROUP_TYPE)}
				>
					{groupList.map(group => (
						<Select.Option key={group.key} title={group.value}>
							{group.value}
						</Select.Option>
					))}
				</Select>
			</Col>
		</div>
	);
};

export default GroupsFilters;
