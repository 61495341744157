import React from 'react';
import { connect } from 'react-redux';

import { DataFetcher } from 'utils/components';
import PortCallContainer from 'sections/PortCall/PortCallContainer';
import DocumentPageViewer from './FinanceDocumentPageViewer';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';

import {
	restoreOriginalDocument,
	retrieveFinanceDocument,
	uploadAnnotatedDocumentationDone
} from 'store/finance/actions';

import {
	getRetrieveFinanceDocumentFetchStatus,
	getFinanceDocument
} from 'store/finance/selectors';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';

import { BreadcrumbItem } from 'store/navigation';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { AppState } from 'store-types';
import {
	cancelUploadAndProcessDocument,
	uploadAndProcessDocument
} from 'store/documents/actions';
import {
	UploadCustomRequest,
	UploadType
} from 'services/api/documents/documentsServiceTypes';
import { FinanceDocumentType } from 'store/finance/constants';
import { AppNotifications } from 'containers';

interface FinanceDocumentPageViewerRouteProps extends PortCallTabBaseProps {
	document: PortCallDocument;
	breadcrumbs: BreadcrumbItem[];
	fetchStatus: FetchStatus;
	retrieveFinanceDocument: typeof retrieveFinanceDocument;
	uploadAndProcessDocument: typeof uploadAndProcessDocument;
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
	restoreOriginalDocument: typeof restoreOriginalDocument;
}
class FinanceDocumentPageViewerRoute extends React.Component<
	FinanceDocumentPageViewerRouteProps
> {
	componentWillUnmount(): void {
		this.props.cancelUploadAndProcessDocument({ shouldResetFile: true });
	}

	retrieveFinanceDocument = () => {
		const {
			match: {
				params: { portCallId, portJobCode, documentType, id }
			}
		} = this.props;
		this.props.retrieveFinanceDocument({
			portCallId,
			jobCode: portJobCode,
			id,
			documentType
		});
	};

	onSaveAnnotation = (request: UploadCustomRequest, actualNumber: number) => {
		const {
			match: {
				params: { portCallId, portJobCode, documentType, id, pageNumber }
			},
			document
		} = this.props;
		this.props.uploadAndProcessDocument({
			type: UploadType.FINANCE_DOCUMENT,
			request,
			showProgress: true,
			/**
			 * Once upload process starts from the FE we can stop polling for the completion.
			 * But in background document will process and attaches to the service.
			 * So need to hide the close button.
			 */
			hideClose: true,
			onDone: uploadAnnotatedDocumentationDone,
			metadata: {
				contextualizePdf: true,
				portCallId: portCallId,
				pageNumber: Number(actualNumber),
				actNumber: Number(pageNumber),
				isAnnotated: true,
				jobCode: portJobCode,
				jobFinanceId: document.jobFinanceId,
				...(documentType === FinanceDocumentType.DAS
					? { disbursementAccountId: id }
					: { portJobServiceId: id })
			}
		});
	};

	onRestoreOriginal = () => {
		const {
			match: {
				params: { portCallId, portJobCode, documentType, id }
			}
		} = this.props;

		this.props.restoreOriginalDocument({
			portCallId: portCallId,
			jobCode: portJobCode,
			id,
			documentType
		});
	};

	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([
			{
				title: 'View Receipt'
			}
		]);

	render() {
		const {
			match: {
				params: {
					portCallId,
					portJobCode,
					pageNumber,
					pageType,
					id,
					documentType
				}
			},
			fetchStatus,
			document
		} = this.props;
		const baseUrl = `/portcalls/${portCallId}/jobs/${portJobCode}/finance/documents/${documentType}/${id}/preview`;

		return (
			<PortCallContainer match={this.props.match}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<AppNotifications.Notification />
				<DataFetcher
					fetchStatus={fetchStatus}
					dispatch={this.retrieveFinanceDocument}
				>
					{() => (
						<DocumentPageViewer
							id={id}
							portCallId={portCallId}
							jobCode={portJobCode}
							document={document}
							documentType={documentType}
							baseUrl={baseUrl}
							activePage={Number(pageNumber)}
							activePageType={pageType}
							onSaveAnnotation={this.onSaveAnnotation}
							onRestoreOriginal={this.onRestoreOriginal}
						/>
					)}
				</DataFetcher>
			</PortCallContainer>
		);
	}
}

export default connect(
	(state: AppState) => ({
		fetchStatus: getRetrieveFinanceDocumentFetchStatus(state),
		document: getFinanceDocument(state),
		breadcrumbs: getPortCallLayoutBreadcrumbs(state)
	}),
	{
		retrieveFinanceDocument,
		uploadAndProcessDocument,
		cancelUploadAndProcessDocument,
		restoreOriginalDocument
	}
)(FinanceDocumentPageViewerRoute);
