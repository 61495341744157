import { createSelector } from 'reselect';
import { uniqBy, orderBy, last, replace } from 'lodash';
import { AppState } from 'store-types';
import { getTimezoneOffset } from 'utils/formatDate';
import {
	Port,
	Anchorage,
	Buoy,
	Canal
} from 'services/api/ports/portsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import {
	getActivePortCallPortId,
	getActivePortCall
} from 'store/portcalls/selectors';

const getPortsMap = (state: AppState) => state.masterdata.ports.portsById;

const getPorts = createSelector(getPortsMap, portsMap =>
	Object.values(portsMap)
);

export const getPortsCodeToNameAppended = createSelector(getPorts, ports =>
	ports.map(port => ({ ...port, name: `${port.name} (${port.code})` }))
);

export const getPortsCountries = createSelector(getPorts, ports =>
	orderBy(
		uniqBy(ports, port => port.countryCode).map(port => ({
			code: port.countryCode,
			name: port.country
		})),
		['name'],
		['asc']
	)
);

export const getPortById = (state: AppState, id: string): Port =>
	getPortsMap(state)[id];

export const getPortFetchStatusById = (
	state: AppState,
	id: string
): FetchStatus => state.masterdata.ports.fetchStatuses[id];

export const getPortAnchorages = (state: AppState, id: string): Anchorage[] =>
	state.masterdata.ports.anchoragesByPort[id];

export const getPortBuoys = (state: AppState, id: string): Buoy[] =>
	state.masterdata.ports.buoysByPort[id];

export const getPortCanals = (state: AppState, id: string): Canal[] =>
	state.masterdata.ports.canalsByPort[id];

const getActivePort = createSelector(
	getPortsMap,
	getActivePortCallPortId,
	(portsMap, portId) => (portId && portsMap[portId]) || null
);

export const getPortTimeZoneById = createSelector(
	getPortById,
	port => port?.timeZoneId || ''
);

export const getActivePortTimeZoneExtended = createSelector(
	getActivePortCall,
	getActivePort,
	getPortTimeZoneById,
	(activePortCall, activePort, portTimeZoneById) =>
		activePortCall?.portTimezoneId || activePort?.timeZoneId || portTimeZoneById
);

export const getActivePortFormattedTimezone = createSelector(
	getActivePortTimeZoneExtended,
	timezoneId => {
		const offset = getTimezoneOffset(timezoneId);
		const city = last(timezoneId.split('/')) || '';
		const formattedCity = replace(city, '_', ' ');
		return `(GMT${offset}) ${formattedCity}`;
	}
);
