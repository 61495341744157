import { Action, Failure } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash';
import {
	createPortCallDocumentAsync,
	deletePortCallDocumentAsync,
	deletePortCallDocumentPageAsync,
	downloadPortCallDocumentsAsync,
	editNotIssuedPortCallDocumentAsync,
	editUploadedDocumentCommentAsync,
	markPortCallDocumentNotIssuedAsync,
	retrieveAllowedDocuments,
	retrieveRequiredDocuments,
	retrieveUploadedDocuments,
	retrievePortCallDocument,
	DeletePortCallDocumentPagePayload
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	isBadRequestError,
	isNotFoundError,
	isConcurrencyError
} from 'services/api/apiErrorUtils';

export function* handleConcurrentFailedRequestsWorker(
	/* eslint-disable @typescript-eslint/no-explicit-any */
	action: Action<any>
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	const { payload } = action;
	const error = get(payload, 'error');
	if (
		isBadRequestError(error) ||
		isNotFoundError(error) ||
		isConcurrencyError(error)
	) {
		yield all([
			put(retrieveAllowedDocuments({ portCallId })),
			put(retrieveRequiredDocuments({ portCallId })),
			put(retrieveUploadedDocuments({ portCallId }))
		]);
	}
}

export function* handleConcurrentSuccessRequestsWorker(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	yield all([
		put(retrieveAllowedDocuments({ portCallId })),
		put(retrieveRequiredDocuments({ portCallId })),
		put(retrieveUploadedDocuments({ portCallId }))
	]);
}

export function* refetchPortCallDocumentWorker({
	payload: {
		params: { portCallId, documentId }
	}
}: Action<Failure<DeletePortCallDocumentPagePayload, Error>>): SagaIterator {
	yield put(
		retrievePortCallDocument({
			portCallId,
			documentId,
			isSilent: true
		})
	);
}

export default function* reactToChangesForEventsWatcher(): SagaIterator {
	yield all([
		takeLatest(
			[
				createPortCallDocumentAsync.failed,
				deletePortCallDocumentPageAsync.failed,
				downloadPortCallDocumentsAsync.failed,
				editNotIssuedPortCallDocumentAsync.failed,
				editUploadedDocumentCommentAsync.failed,
				markPortCallDocumentNotIssuedAsync.failed
			],
			handleConcurrentFailedRequestsWorker
		),
		takeLatest(
			[
				createPortCallDocumentAsync.done,
				deletePortCallDocumentAsync.done,
				deletePortCallDocumentPageAsync.done,
				downloadPortCallDocumentsAsync.done,
				editNotIssuedPortCallDocumentAsync.done,
				editUploadedDocumentCommentAsync.done,
				markPortCallDocumentNotIssuedAsync.done
			],
			handleConcurrentSuccessRequestsWorker
		),
		takeLatest(
			deletePortCallDocumentPageAsync.failed,
			refetchPortCallDocumentWorker
		)
	]);
}
