import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { flatten, isEmpty } from 'lodash';

export const getFilterValues = (state: AppState, filterName: string) => {
	return state.filters[filterName];
};

export const getFiltersResultByKey = createSelector(getFilterValues, values => {
	if (!values) {
		return [];
	}
	return flatten(Object.values(values));
});

export const getAreFiltersEmpty = (state: AppState) => isEmpty(state.filters);
