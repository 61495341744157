import { AppState } from 'store-types';
import { get } from 'lodash';

import { FormStateGet } from '../formTypes';
import { isPristine, isValid } from 'redux-form';
import { FormState } from 'store/form/formState';

export function getForm<P>(state: AppState, formName: string): FormStateGet<P> {
	const form = state.form[formName] as FormState<P>;
	return {
		...form,
		values: getFormValues<P>(form),
		valid: isValid(formName)(state),
		pristine: isPristine(formName)(state),
		initialized: !!state.form[formName]
	};
}

export function getFormValues<P>(form: FormState<P>): P {
	return form?.values ? form.values : ({} as P);
}

export function getValues<P = {}>(state: AppState, formName: string) {
	const form = state.form[formName];
	return getFormValues<P>(form);
}

export function getFormSingleValue<P = {}>(
	state: AppState,
	formName: string,
	fieldName: keyof P
) {
	return getValues<P>(state, formName)[fieldName];
}

export function getErrorByFieldId(
	state: AppState,
	formId: string,
	fieldId: string
) {
	const form = getForm(state, formId);
	const errors = form?.syncErrors;

	return get(errors, fieldId);
}
