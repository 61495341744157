import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Text } from 'components';

import { InviteUserFormData } from '../UsersModalsTypes';
import InviteUserFormFields from './InviteUserFormFields';

import { resetGroups } from 'store/groups';
import validate from './validate';
import { useInviteUserForm } from './InviteUserForm.hooks';

interface InviteUserModalFormOwnProps {
	isCurrentUserCompanyAssigned: boolean;
	onSubmit: (values: InviteUserFormData) => void;
}
export type InviteUserModalFormProps = InjectedFormProps<
	InviteUserFormData,
	InviteUserModalFormOwnProps
> &
	InviteUserModalFormOwnProps;

function InviteUserModalForm({
	onSubmit,
	isCurrentUserCompanyAssigned,
	form,
	handleSubmit,
	change,
	error
}: InviteUserModalFormProps) {
	const dispatch = useDispatch();
	const {
		formValues,
		groups,
		isGroupsLoading,
		onRetrieveGroups
	} = useInviteUserForm({ form });

	const onCompanySelect = useCallback(() => {
		/**
		 * Groups should belong to selected company, so they should be reset when new company has been selected
		 */
		if (formValues.company) {
			change('groups', []);
			dispatch(resetGroups());
		}
		onRetrieveGroups();
	}, [formValues.company, dispatch, change, onRetrieveGroups]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{error && <Text color="error">{error}</Text>}
			<InviteUserFormFields
				formValues={formValues}
				isCompanyDisabled={isCurrentUserCompanyAssigned}
				onCompanySelect={onCompanySelect}
				groups={groups}
				isGroupsLoading={isGroupsLoading}
			/>
		</form>
	);
}

export default reduxForm<InviteUserFormData, InviteUserModalFormOwnProps>({
	validate,
	enableReinitialize: true
})(InviteUserModalForm);
