import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';

export const onShowDeleteModal = (
	state: PortCallOperationsState,
	eventId: string
): PortCallOperationsState => ({
	...state,
	context: {
		...state.context,
		deleteEventModal: {
			visible: true,
			eventId
		}
	}
});

export const onHideDeleteModal = (
	state: PortCallOperationsState
): PortCallOperationsState => ({
	...state,
	context: {
		...state.context,
		deleteEventModal: {
			visible: false,
			eventId: undefined
		}
	}
});
