import client from 'services/client';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	MailboxAvailableGroup,
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse,
	RetrieveMailboxAvailableGroupRequest
} from 'services/api/mailboxes/mailboxesServiceTypes';

export const retrieveMailboxes = (request?: RetrieveMailboxesRequest) =>
	client.get<RetrieveMailboxesResponse>('/messages/mailboxes', {
		params: request
	});

export const retrieveMailboxesForPrincipal = (
	request?: RetrieveMailboxesRequest
) =>
	client.get<RetrieveMailboxesResponse>('/mailboxes', {
		params: request
	});

export const retrieveMailboxAvailableGroups = ({
	mailboxId
}: RetrieveMailboxAvailableGroupRequest): AxiosTypedPromise<
	ResultCollection<MailboxAvailableGroup>
> => client.get(`/messages/mailboxes/${mailboxId}/available-groups`);

export default {
	retrieveMailboxes,
	retrieveMailboxesForPrincipal,
	retrieveMailboxAvailableGroups
};
