import { createSelector } from 'reselect';
import {
	filter,
	get,
	includes,
	intersectionWith,
	some,
	find,
	values,
	has,
	every
} from 'lodash';
import { Entity, AlertsByMetricType, PortCallAlertsMap } from 'app-types';
import { AppState } from 'store-types';

import { FetchStatus } from 'services/api/apiTypes';
import {
	PortCall,
	PortCallRowVisibleAlert
} from 'services/api/portCalls/portCallsServiceTypes';
import {
	PortJob,
	PortJobStatusLabel
} from 'services/api/portJobs/portJobsServiceTypes';

import { PORT_CALLS_FILTER_NAME } from './filtersSync';
import { PortJobStatus } from '../portJobs/constants';
import { AlertMetricType, Severity, severityColorMap } from 'app-constants';
import { ThemeScheme } from 'components/types';
import { PortCallOperationsTabKey, PortCallTabKey } from './portCallsTypes';
import { getIsLoading } from 'store/selectors';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { PortCallContext, MergePortCallsContext } from './portCallsState';
import { isPortJobCLS } from './portCalls.func';

const getPortCallsMapSelector = (state: AppState) => state.portcalls.byId;

export const getPortCallData = (state: AppState) => state.portcalls.portCall;

export const getPortCallTimeZone = createSelector(getPortCallData, portCall =>
	portCall ? portCall.portTimezoneId : undefined
);

export const getPortJobAssignUser = (state: AppState, id: string) =>
	state.portcalls?.byId[id];

export const getPortCallsState = (state: AppState) => state.portcalls;

const getPortCallsFetchStatuses = (state: AppState) =>
	state.portcalls.fetchStatuses;

export const getAllPortCallsSelector = createSelector(
	getPortCallsMapSelector,
	(ids): PortCall[] => Object.values(ids)
);

export const getPortCallContext = (
	state: AppState,
	key: keyof PortCallContext
) => state.portcalls.context[key];

export const getActivePortCallId = (state: AppState) =>
	state.portcalls.context.activePortCallId;

export const getActivePortCall = createSelector(
	getActivePortCallId,
	getPortCallsState,
	(id, portcalls) => (id ? portcalls.portCall : null)
);

export const getActivePortId = createSelector(
	getActivePortCall,
	portCall => portCall?.port.id || ''
);

export const getHasActivePortCallDuplicates = createSelector(
	getActivePortCall,
	portCall => Boolean(portCall?.hasDuplicates)
);

export const getRetrievedSinglePortCall = (state: AppState) =>
	getPortCallsState(state).portCall;

export const getActivePortCallFetchStatus = createSelector(
	getActivePortCallId,
	getPortCallsFetchStatuses,
	(id, fetchStatuses) => fetchStatuses[id]
);

export const getIsActivePortCallCancelled = createSelector(
	getActivePortCall,
	portCall => Boolean(portCall?.isCancelled)
);

export const getPortCallByIdFetchStatus = (state: AppState, id: string) =>
	state.portcalls.fetchStatuses[id];

export const getIsPortCallsFetching = (state: AppState) =>
	state.portcalls.fetchStatuses.all === FetchStatus.PENDING;

export const getIsPortCallAdding = (state: AppState) =>
	state.portcalls.fetchStatuses.adding === FetchStatus.PENDING;

export const getPortCallsFilters = (state: AppState) =>
	state.filters[PORT_CALLS_FILTER_NAME];

export const getIsActivePortCallLoading = createSelector(
	getActivePortCallId,
	getPortCallsFetchStatuses,
	(id, fetchStatuses) => getIsLoading(fetchStatuses[id])
);

export const portCallsHasMoreSelector = (state: AppState) =>
	state.portcalls.hasMore;

export const getPortCallsCount = createSelector(
	getAllPortCallsSelector,
	portcalls => portcalls.length
);

export const getPortCallById = (state: AppState, id: string): PortCall => {
	return state.portcalls.byId[id];
};

/**
 * Get the list of PortJobs from the active PortCall
 */
export const getPortJobsForActivePortCall = createSelector(
	getActivePortCall,
	portCall => portCall?.jobs || []
);

export const getSavingsPortJobsForActivePortCall = createSelector(
	getActivePortCall,
	portCall => {
		if (portCall?.jobs) {
			return portCall.jobs.filter(
				job => job.status !== PortJobStatus.CREATED && !job.isCancelled
			);
		} else return [];
	}
);

export const activeSavingsPortJobs = createSelector(
	getSavingsPortJobsForActivePortCall,
	jobs => jobs.length > 0
);

export const getPortJobsCodesforActivePortCall = createSelector(
	getPortJobsForActivePortCall,
	portJobs => portJobs.map(({ code }) => code)
);

/**
 * Get the value of hubPrincipalCompany.id from the first
 * PortJob on the active PortCall
 */
export const getHubPrincipalFromPortJob = createSelector(
	getPortJobsForActivePortCall,
	(activePortCallJobs): Entity | null => {
		if (!activePortCallJobs.length) {
			return null;
		}
		const job: PortJob = activePortCallJobs[0];
		return get(job, 'hubPrincipalCompany', null);
	}
);

export const getPortCallDuplicates = createSelector(
	getPortCallsState,
	(_state: AppState, portCallId: string) => portCallId,
	(portCallsState, portCallId) => portCallsState.duplicates[portCallId] || []
);

export const getPortCallDuplicate = createSelector(
	(state: AppState, currentPortCallId: string, duplicateId: string) => ({
		duplicates: getPortCallDuplicates(state, currentPortCallId),
		duplicateId
	}),
	({ duplicates, duplicateId }) =>
		duplicates.find(duplicate => duplicate.id === duplicateId) ||
		({} as PortCall)
);

/**
 * Selector is additionally checking the portcall in the state
 * */
export const getJobInPortCallByCode = (
	state: AppState,
	portCallId: string,
	code: string
): PortJob | undefined => {
	const portCall = getPortCallDuplicate(state, 'all', portCallId);
	const portJob = portCall
		? portCall.jobs.find(job => job.code === code)
		: undefined;
	return portJob;
};

/**
 * Return true if there's an active PortCall
 */
export const hasActivePortCall = (state: AppState): boolean => {
	return Boolean(getActivePortCallId(state));
};

export const isActivePortCallDataExists = createSelector(
	getActivePortCall,
	portCall => Boolean(portCall)
);

export const isActiveJobsInExecutionStatus = createSelector(
	getPortJobsForActivePortCall,
	portJobs => {
		const executionJobs = filter(portJobs, { status: PortJobStatus.EXECUTION });
		return portJobs.length > 0 && executionJobs.length === portJobs.length;
	}
);

export const isAllJobsInCEDraftStatus = createSelector(
	getPortJobsForActivePortCall,
	portJobs => {
		const executionJobs = filter(
			portJobs,
			({ status }) => status === PortJobStatus.CE
		);
		return portJobs.length > 0 && executionJobs.length === portJobs.length;
	}
);

export const areAllNonCancelledJobsInActivePortCallCLS = createSelector(
	getPortJobsForActivePortCall,
	portJobs => {
		const nonCancelledPortJobs = portJobs.filter(
			({ isCancelled }) => !isCancelled
		);
		return (
			!!nonCancelledPortJobs.length && every(nonCancelledPortJobs, isPortJobCLS)
		);
	}
);

const validFinanceStatus = [
	PortJobStatus.PDA,
	PortJobStatus.CE,
	PortJobStatus.DA,
	PortJobStatus.LPA_SETTLEMENTS,
	PortJobStatus.DA_OUT_POSTINGS,
	PortJobStatus.HUB_SETTLEMENTS,
	PortJobStatus.DA_SETTLEMENTS,
	PortJobStatus.EXECUTION,
	PortJobStatus.CLOSED
];

export const getValidFinanceJobs = createSelector(
	getActivePortCall,
	(portCall): PortJob[] => {
		if (!portCall) {
			return [];
		}
		return portCall.jobs.filter(job =>
			includes(validFinanceStatus, job.status)
		);
	}
);

export const isJobAccepted = (job: PortJob) => {
	return (
		job.status !== PortJobStatus.CONFIRMED &&
		job.status !== PortJobStatus.CREATED &&
		job.status !== PortJobStatus.APPOINTED &&
		job.status !== PortJobStatus.AWAITING_APPOINTMENT &&
		job.status !== PortJobStatus.AWAITING_INVITATION &&
		job.status !== PortJobStatus.AWAITING_ACCEPTANCE &&
		job.status !== PortJobStatus.PDA_REQUESTED
	);
};

export const getMetricTypeGroups = (state: AppState) =>
	state.portcalls.metricTypeGroupsByPortCall;

export const getIsJobHasFundingAlert = createSelector(
	getMetricTypeGroups,
	(_state: AppState, id: string) => id,
	(groups, id) => {
		const jobAlerts = values(groups)
			.map(jobAlets => jobAlets.portJobs)
			.find(group => has(group, id));
		if (!jobAlerts) {
			return [];
		}
		const jobHasFundingAlert = jobAlerts[id].portJobAlertGroupsByMetricType.map(
			alert => alert.metricType
		);
		return jobHasFundingAlert;
	}
);

const getUrlsForOperationDisabled = (id: string, jobCode?: string) => ({
	[AlertMetricType.PORT_CALL_VESSEL_PROGRAMME]: {
		url: `portcalls/${id}/jobs/${jobCode}/${PortCallTabKey.FINANCE}`,
		text: 'Vessel Programme'
	},
	[AlertMetricType.PORT_CALL_EVENTS]: {
		url: `portcalls/${id}/jobs/${jobCode}/${PortCallTabKey.FINANCE}`,
		text: 'Events'
	}
});

const getUrls = (id: string) => ({
	[AlertMetricType.PORT_CALL_VESSEL_PROGRAMME]: {
		url: `portcalls/${id}/vessel-programme`,
		text: 'Vessel Programme'
	},
	[AlertMetricType.PORT_CALL_EVENTS]: {
		url: `portcalls/${id}/operations/${PortCallOperationsTabKey.EVENTS}`,
		text: 'Events'
	},
	[AlertMetricType.PORT_CALL_DOCUMENTS]: {
		url: `portcalls/${id}/operations/${PortCallOperationsTabKey.DOCUMENTS}`,
		text: 'Documents'
	},
	[AlertMetricType.PORT_CALL_MEASURMENTS]: {
		url: `portcalls/${id}/operations/${PortCallOperationsTabKey.MEASUREMENTS}`,
		text: 'Measurements'
	}
});

export const getPortCallRowAlerts = (
	group: PortCallAlertsMap | undefined,
	portCallId: string,
	isOperationsDisabled: boolean,
	jobCode?: string
): PortCallRowVisibleAlert[] => {
	const result: PortCallRowVisibleAlert[] = [];

	if (!group) {
		return result;
	}

	const urls = getUrls(portCallId);
	const urlsForOperationDisabled = getUrlsForOperationDisabled(
		portCallId,
		jobCode
	);
	const vesselTypes = [
		AlertMetricType.PORT_CALL_VESSEL_PROGRAMME,
		AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_ACKNOWLEDGE,
		AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_COMMIT,
		AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_ON_TRACK
	];

	group.portCallAlertGroupsByMetricType.forEach(alert => {
		const data = urls[alert.metricType];
		const alertsData = urlsForOperationDisabled[alert.metricType];
		if (
			data &&
			!includes(vesselTypes, alert.metricType) &&
			!isOperationsDisabled
		) {
			result.push({
				...data,
				theme: severityColorMap[alert.maxAlertSeverity] as ThemeScheme
			});
		}
		if (
			alertsData &&
			!includes(vesselTypes, alert.metricType) &&
			isOperationsDisabled
		) {
			result.push({
				...alertsData,
				theme: severityColorMap[alert.maxAlertSeverity] as ThemeScheme
			});
		}
	});

	// multiple vessels alerts must be displayed as one alert with highest severity
	const vesselAlerts = intersectionWith(
		group.portCallAlertGroupsByMetricType,
		vesselTypes,
		(alert: AlertsByMetricType, type: AlertMetricType) =>
			alert.metricType === type
	);

	if (vesselAlerts.length) {
		const theme = (some(
			vesselAlerts,
			(vessel: AlertsByMetricType) => vessel.maxAlertSeverity === Severity.RED
		)
			? severityColorMap[Severity.RED]
			: severityColorMap[Severity.YELLOW]) as ThemeScheme;
		if (!isOperationsDisabled) {
			result.push({
				...urls[AlertMetricType.PORT_CALL_VESSEL_PROGRAMME],
				theme
			});
		}
		if (isOperationsDisabled) {
			result.push({
				...urlsForOperationDisabled[AlertMetricType.PORT_CALL_VESSEL_PROGRAMME],
				theme
			});
		}
	}
	return result;
};

export const getThemeByMetricTypeGroup = (
	jobId: string,
	metric?: PortCallAlertsMap,
	filterTypes?: AlertMetricType[]
): ThemeScheme | undefined => {
	const job = metric?.portJobs[jobId];
	let alerts = job?.portJobAlertGroupsByMetricType;

	if (!alerts) {
		return undefined;
	}
	if (filterTypes) {
		alerts = alerts.filter(alert => includes(filterTypes, alert.metricType));
	}
	if (find(alerts, alert => alert.maxAlertSeverity === Severity.RED)) {
		return severityColorMap[Severity.RED] as ThemeScheme;
	}
	if (find(alerts, alert => alert.maxAlertSeverity === Severity.YELLOW)) {
		return severityColorMap[Severity.YELLOW] as ThemeScheme;
	}
	return undefined;
};

// it is addon to statusesTypeMap from portCallsConstants
// because due to new story [QS_07] we need some new dynamic parameters in filtering by jobStatus request
export const getQueriedStatusParamsForPortCallsFilter = createSelector(
	getUserType,
	(_state: AppState, jobStatusLabel: PortJobStatusLabel) => jobStatusLabel,
	(userType, jobStatusLabel) => {
		switch (userType) {
			case UserType.HUB:
				switch (jobStatusLabel) {
					case PortJobStatusLabel.PDA_QUERIED_HUB:
					case PortJobStatusLabel.CE_QUERIED_HUB:
					case PortJobStatusLabel.DA_QUERIED_HUB:
						return {
							queriedToHub: true
						};
					case PortJobStatusLabel.PDA_QUERIED_LPA:
					case PortJobStatusLabel.CE_QUERIED_LPA:
					case PortJobStatusLabel.DA_QUERIED_LPA:
						return {
							queriedToLPA: true,
							queriedToHub: false
						};
					case PortJobStatusLabel.PDA_QUERIED_HUB_LPA:
					case PortJobStatusLabel.CE_QUERIED_HUB_LPA:
					case PortJobStatusLabel.DA_QUERIED_HUB_LPA:
						return {
							queriedToLPA: true,
							queriedToHub: true
						};
					default:
						return {};
				}
			case UserType.PRINCIPAL:
				switch (jobStatusLabel) {
					case PortJobStatusLabel.PDA_QUERIED:
					case PortJobStatusLabel.CE_QUERIED:
					case PortJobStatusLabel.DA_QUERIED:
						return {
							queriedToHub: true
						};
					case PortJobStatusLabel.PDA_VERIFICATION_PENDING:
					case PortJobStatusLabel.CE_VERIFICATION_PENDING:
					case PortJobStatusLabel.DA_VERIFICATION_PENDING:
						return {
							financeStatus: [FinanceStatus.QUERIED],
							queriedToLPA: true,
							queriedToHub: false
						};
					default:
						return {};
				}
			case UserType.LPA:
				switch (jobStatusLabel) {
					case PortJobStatusLabel.PDA_QUERIED:
					case PortJobStatusLabel.CE_QUERIED:
					case PortJobStatusLabel.DA_QUERIED:
						return {
							queriedToLPA: true
						};
					case PortJobStatusLabel.PDA_APPROVAL_PENDING:
					case PortJobStatusLabel.CE_APPROVAL_PENDING:
					case PortJobStatusLabel.DA_APPROVAL_PENDING:
						return {
							financeStatus: [FinanceStatus.QUERIED, FinanceStatus.SUBMITTED],
							queriedToLPA: false,
							queriedToHub: true
						};
					default:
						return {};
				}
			default:
				return {};
		}
	}
);

export const getPortCallsDuplicates = createSelector(
	getPortCallsState,
	portCallsState => portCallsState.duplicates.all
);

export const getIsOperationDisabled = createSelector(
	getPortJobsForActivePortCall,
	jobs => jobs.some(job => !job.isOperationsDisabled)
);

export const getIsPortCallsDuplicatesLoading = (state: AppState) =>
	getIsLoading(state.portcalls.fetchStatuses.duplicates);

export const getPortCallDuplicatesFetchStatus = createSelector(
	getPortCallsState,
	portCallState => portCallState.fetchStatuses.duplicates
);

export const getMergePortCallsContext = (state: AppState) =>
	getPortCallContext(state, 'mergePortCallsContext') as MergePortCallsContext;

export const getActivePortCallPortId = (state: AppState) =>
	state.portcalls.context.activePortCallPortId;

export const getIsPortCallsFinanceEnabled = createSelector(
	getPortJobsForActivePortCall,
	jobs => jobs.some(job => job.financeEnabled)
);

export const getHasDupilcatePortCalls = (state: AppState) =>
	state.portcalls.portCall?.hasDuplicates;

export const getEOSPorETAdate = createSelector(getActivePortCall, portCall =>
	portCall?.eospPlt ? portCall.eospPlt : portCall?.etaPlt
);
