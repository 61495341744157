import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { UpdateKpiDetailsByIdRequest as Request } from 'services/api/opticConfiguration/opticConfigServiceTypes';

import { Action } from 'typescript-fsa';
import { updateKpiDetailsByIdAsync, updateKpiDetailsById } from '../actions';
import { getPreparedFormData } from '../selectors';
import { navigateTo } from 'utils';

const apiCall = Api.OpticConfiguration.updateKPIDetailsById;

export function* updateKpiDetailsByIdExecutor(
	actionParams: Request,
	api: typeof apiCall
): SagaIterator {
	yield put(updateKpiDetailsByIdAsync.started(actionParams));
	try {
		const formData = yield select(getPreparedFormData);
		const request: Request = {
			id: formData.id,
			payload: { ...formData }
		};
		const response = yield call(api, request);

		yield put(
			updateKpiDetailsByIdAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		navigateTo('/optic-configuration/generic-KPIs-and-alerts');
	} catch (error) {
		yield put(
			updateKpiDetailsByIdAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* worker({ payload }: Action<Request>): SagaIterator {
	yield call(updateKpiDetailsByIdExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(updateKpiDetailsById.type, worker);
}
