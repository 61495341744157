import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
	FieldArray,
	WrappedFieldArrayProps,
	BaseFieldArrayProps
} from 'redux-form';
import FormPage from './FormPage';
import { FormPageData } from 'store/form/financeContextualization/financeContextualizationFormData';
import { Content } from 'components';
import { getFinanceContextualizationData } from 'store/form/financeContextualization/selectors';
import { AppState } from 'store-types';

export interface FieldArrayPagesProps {
	activePage: number;
	hasPagination: boolean;
}

type FormPagesOwnProps = FieldArrayPagesProps;
type FormPagesProps = WrappedFieldArrayProps<FormPageData> & FormPagesOwnProps;

const FormPages: FC<FormPagesProps> = ({ fields, activePage }) => {
	const [activeKeys, setActiveKeys] = useState<string[]>([]);
	const data =
		useSelector<AppState, ReturnType<typeof getFinanceContextualizationData>>(
			getFinanceContextualizationData,
			shallowEqual
		) || [];

	// set all collapsible panels expanded on init (depends on redux state)
	useEffect(() => {
		const activeKeys = data.map((_item, i) => String(i + 1));
		setActiveKeys(activeKeys);
	}, [data]);

	const currentFieldIndex = Math.max(activePage - 1, 0);

	const onActiveKeysChange = useCallback((keys: string[]) => {
		setActiveKeys(keys);
	}, []);

	return (
		<Content>
			{fields.map((field, i) => {
				if (i !== currentFieldIndex) {
					return null;
				}
				return (
					<FormPage
						key={field}
						field={field}
						activeKeys={activeKeys}
						data={data}
						onActiveKeysChange={onActiveKeysChange}
					/>
				);
			})}
		</Content>
	);
};

const FormPagesFieldArray = (
	props: Pick<BaseFieldArrayProps, 'name'> & FormPagesOwnProps
) => <FieldArray<FormPagesOwnProps> {...props} component={FormPages} />;

export default FormPagesFieldArray;
