import * as React from 'react';
import { connect } from 'react-redux';
import styles from './RemoveGroupUser.module.scss';

import { Alert, Modal } from 'components/antd';

import { deleteGroupUser } from 'store/groupUsers';
import { Group, GroupUser } from 'services/api/groups/groupsServiceTypes';

interface RemoveGroupUserProps {
	group: Group;
	groupUser: GroupUser;
	onCancel: () => void;
	deleteGroupUser: typeof deleteGroupUser;
}

export const AlertForSingleGroupUser: React.SFC<{
	userName: string;
}> = (props: { userName: string }) => (
	<p>
		Note: <strong>{props.userName}</strong> will not belong to any group and
		will not be able to login.
	</p>
);

class RemoveGroupUser extends React.Component<RemoveGroupUserProps> {
	onSubmit = () => {
		this.props.deleteGroupUser({
			groupId: this.props.group.id,
			userId: this.props.groupUser.id
		});

		this.props.onCancel();
	};

	getUserName = () => {
		const { groupUser } = this.props;
		return `${groupUser.firstName} ${groupUser.lastName}`;
	};

	getGroupNameFormatted = () => {
		const {
			group: { name }
		} = this.props;
		return name.split(' ').some(s => s.length > 45)
			? `${name.substring(0, 45)}...`
			: name;
	};

	render() {
		const { groupUser } = this.props;
		return (
			<Modal
				visible
				onOk={this.onSubmit}
				onCancel={this.props.onCancel}
				closable={false}
				okText="Remove"
			>
				Are you sure you want to delete <strong>{this.getUserName()}</strong>{' '}
				from{' '}
				<strong className={styles.noBreakOutText}>
					{this.getGroupNameFormatted()}
				</strong>
				?
				{groupUser.singleGroupUser && (
					<Alert
						className={styles.alertForSingleGroupUser}
						message={<AlertForSingleGroupUser userName={this.getUserName()} />}
						type="warning"
						showIcon
					/>
				)}
			</Modal>
		);
	}
}

export default connect(
	null,
	{
		deleteGroupUser
	}
)(RemoveGroupUser);
