import actionCreatorFactory from 'typescript-fsa';

import {
	Group,
	UpdateGroupParams,
	DataPermission
} from 'services/api/groups/groupsServiceTypes';

const actionCreator = actionCreatorFactory();

export interface UpdateGroupActionPayload {
	groupId: string;
	name: string;
	signature: string;
	isMessagingGroup: boolean;
	companyIds?: string[];
	functionalPermissions?: string[];
	groupType: string;
	scopeType?: string;
	dataPermissions?: DataPermission[];
}

const UPDATE_GROUP = 'groups/UPDATE_GROUP';
export const updateGroupAsync = actionCreator.async<
	UpdateGroupParams,
	Group,
	Error
>(UPDATE_GROUP, {
	templateParams: {
		entityName: 'Group'
	}
});
export const updateGroup = actionCreator<UpdateGroupParams>(
	updateGroupAsync.type
);
