import {
	put,
	select,
	takeLatest,
	all,
	fork,
	take,
	race
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	createActiveCompanyVesselType,
	setVesselTypesTabMode
} from '../actions';
import { getActiveCompanyId } from '../selectors';
import {
	createCompanyVesselType,
	createCompanyVesselTypeAsync
} from 'store/companyVesselTypes/actions';
import { CreateUpdateCompanyVesselTypeRequestData } from 'services/api/companies/companiesServiceTypes';
import { Action } from 'typescript-fsa';
import { VesselTypesMode } from '../configurationState';

export function* worker({
	payload: { name, externalCode }
}: Action<CreateUpdateCompanyVesselTypeRequestData>): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	yield put(
		createCompanyVesselType({
			companyId,
			data: { name: name.trim(), externalCode }
		})
	);
	yield race({
		done: take(createCompanyVesselTypeAsync.done),
		failed: take(createCompanyVesselTypeAsync.failed)
	});
	yield put(setVesselTypesTabMode({ mode: VesselTypesMode.DEFAULT }));
}

function* watcher(): SagaIterator {
	yield takeLatest(createActiveCompanyVesselType, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
