import { Success } from 'typescript-fsa';
import { put } from 'redux-saga/effects';
import Api from 'services/api';
import { retrieveFinanceServicesForContextualizationAsync } from '../actions';
import { initializeFinanceContextualizationForm } from 'store/form/financeContextualization/actions';
import {
	RetrieveFinanceServicesForContextualizationRequest,
	RetrieveFinanceServicesForContextualizationResponse
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	RetrieveFinanceServicesForContextualizationRequest,
	RetrieveFinanceServicesForContextualizationResponse,
	Error
>({
	api: Api.Finance.retrieveFinanceServicesForContextualization,
	async: retrieveFinanceServicesForContextualizationAsync,
	*onSuccess({
		result
	}: Success<
		RetrieveFinanceServicesForContextualizationRequest,
		RetrieveFinanceServicesForContextualizationResponse
	>) {
		yield put(initializeFinanceContextualizationForm(result));
	}
});
