import React from 'react';
import { Row, Col } from 'components/antd';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Title, LabeledText, Content } from 'components';
import styles from './BankAccountDetails.module.scss';

interface CountrySpecificInformationProps {
	bank: BankAccount;
}
const CountrySpecificInformation = (props: CountrySpecificInformationProps) => {
	const {
		aba,
		cpf,
		inn,
		bsb,
		ifc,
		purposeReason,
		bic,
		transactionPurpose,
		purposeCode,
		agencyNumber,
		routingNumber,
		cnpjCode
	} = props.bank;
	return (
		<>
			<Title>COUNTRY SPECIFIC INFORMATION</Title>
			<Content>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="ABA Number" value={aba} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="CPF Code" value={cpf} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="INN Number" value={inn} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Purpose Reason" value={purposeReason} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="BIK Number" value={bic} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="BSB Number" value={bsb} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Agency Number" value={agencyNumber} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Routing Number" value={routingNumber} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="CNPJ Code" value={cnpjCode} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="IFSC Code" value={ifc} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Purpose Code" value={purposeCode} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText
							label="Transaction Purpose Code"
							value={transactionPurpose}
						/>
					</Col>
				</Row>
			</Content>
			<hr />
		</>
	);
};

export default CountrySpecificInformation;
