import {
	DeleteDAOutResponse,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

type DeleteDaOutParams = { serviceId: PortJobService['id'] };
const ACTION_NAME = 'DELETE_DA_OUT';
export const deleteDAOutAsync = actionCreator.async<
	DeleteDaOutParams,
	DeleteDAOutResponse,
	Error
>(ACTION_NAME);

export const deleteDAOut = actionCreator<DeleteDaOutParams>(ACTION_NAME);
