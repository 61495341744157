import { MainCommodityCode } from 'services/api/commodities/commoditiesServiceTypes';
import { UnitType } from 'services/api/units/unitsServiceTypes';

export const UNIT_TYPES_BY_MAIN_COMMODITY = {
	[MainCommodityCode.GAS]: [UnitType.VOLUME],
	[MainCommodityCode.WET]: [UnitType.VOLUME],
	[MainCommodityCode.DRY]: [
		UnitType.VOLUME,
		UnitType.MASS,
		UnitType.AREA,
		UnitType.QUANTITY
	]
};
