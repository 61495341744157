import * as React from 'react';
import { AutocompleteVessels } from 'containers';
import Api from 'services/api';
import { AutocompleteEntityProps } from 'containers/Autocomplete/AutocompleteEntity';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

interface FieldVesselsProps extends AutocompleteEntityProps {
	hubPrincipalCompany: Page1FormValues['hubPrincipalCompany'];
}

export default class FieldVessels extends React.Component<FieldVesselsProps> {
	onSearch = (searchTerm: string) => {
		const { hubPrincipalCompany } = this.props;

		const companyId = hubPrincipalCompany ? hubPrincipalCompany.key : '';

		if (!companyId) {
			return Promise.reject(`There's no Hub Principal Company defined`);
		}

		return Api.Companies.searchCompanyVesselsForAutocomplete({
			searchTerm,
			companyId
		});
	};

	render() {
		const { hubPrincipalCompany, onSearch, ...autocompleteProps } = this.props;
		return (
			<AutocompleteVessels onSearch={this.onSearch} {...autocompleteProps} />
		);
	}
}
