import actionCreator from '../companyVesselTypesActionCreator';
import {
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
const ACTION_NAME = 'CREATE';

export const createCompanyVesselTypeAsync = actionCreator.async<
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Vessel Type'
	}
});

export const createCompanyVesselType = actionCreator<
	CreateCompanyVesselTypeRequest
>(ACTION_NAME);
