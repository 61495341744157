import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveThreadEventsAsync, resetThreadEvents } from './actions';
import { onRetrieveThreadEvents, onResetThreadEvents } from './reducers';
import { initialState } from './eventsState';

export default reducerWithInitialState(initialState)
	// Retrieve Thread Events
	.case(retrieveThreadEventsAsync.started, onRetrieveThreadEvents.started)
	.case(retrieveThreadEventsAsync.failed, onRetrieveThreadEvents.failed)
	.case(retrieveThreadEventsAsync.done, onRetrieveThreadEvents.done)
	.case(resetThreadEvents, onResetThreadEvents);
