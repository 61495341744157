import React from 'react';
import { ActionButtonTypes } from 'sections/PortCall/PortJob/PortJobConstants';
import { useSelector } from 'react-redux';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getActionButtonType
} from 'store/portJobs/selectors';

import AcceptPortJobModal from './ActionModal/AcceptPortJobModal';
import AppointToPortJobModal from './ActionModal/AppointToPortJobModal/AppointToPortJobModal';
import ConfirmPortJobModal from './ActionModal/ConfirmPortJobModal';
import LinkJobButton from './ActionModal/LinkJobButton';
import InviteAgentModal from './ActionModal/InviteAgentModal/InviteAgentModal';
import LinkCargosButton from './ActionModal/LinkCargoButton';
import uuidv1 from 'uuid';

// any further buttons with modals should be added here
type PortJobModal =
	| typeof AcceptPortJobModal
	| typeof ConfirmPortJobModal
	| typeof AppointToPortJobModal
	| typeof LinkJobButton
	| typeof InviteAgentModal;

const JobActions = {
	[ActionButtonTypes.CONFIRM]: ConfirmPortJobModal,
	[ActionButtonTypes.APPOINT]: AppointToPortJobModal,
	[ActionButtonTypes.ACCEPT]: AcceptPortJobModal,
	[ActionButtonTypes.LINK_JOB]: LinkJobButton,
	[ActionButtonTypes.INVITE_AGENT]: InviteAgentModal,
	[ActionButtonTypes.LINK_CARGO]: LinkCargosButton
};

function PortJobActionButton() {
	const activePortJobCode = useSelector(getActivePortJobCode);
	const activePortCallId = useSelector(getActivePortCallId);
	const actionButtonType = useSelector(getActionButtonType);
	const modalId = React.useMemo(() => {
		return `${actionButtonType}Modal${uuidv1()}`;
	}, [actionButtonType]);
	if (!actionButtonType) {
		// NoButton
		return null;
	}
	const JobAction: PortJobModal = JobActions[actionButtonType];
	return (
		<JobAction
			jobCode={activePortJobCode}
			portCallId={activePortCallId}
			modalId={modalId}
		/>
	);
}

export default PortJobActionButton;
