import { DraftsState, POIDraft } from '../draftsState';

export const onAddPOIDrafts = (
	state: DraftsState,
	POIDrafts: POIDraft[]
): DraftsState => {
	return {
		...state,
		draftsByType: {
			...state.draftsByType,
			pointOfInterest: [...POIDrafts]
		}
	};
};
