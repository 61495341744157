import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { updatePortCallEventJobAssignmentAsync } from '../actions';
import { UpdatePortCallEventJobAssignmentPayload } from '../actions/updatePortCallEventJobAssignment';
import { getActivePortCallId } from '../../portcalls/selectors';
import { getPortCallEventConcurrencyToken } from '../selectors';
import Api from 'services/api';

export function* updatePortCallEventJobAssignmentWorker({
	payload
}: Action<UpdatePortCallEventJobAssignmentPayload>): SagaIterator {
	yield put(updatePortCallEventJobAssignmentAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const concurrencyToken = yield select(
		getPortCallEventConcurrencyToken,
		payload.eventId
	);
	const updateRequest = { ...payload, portCallId };
	try {
		const response = yield call(Api.PortCall.updatePortCallEventJobAssignment, {
			...updateRequest,
			concurrencyToken
		});
		yield put(
			updatePortCallEventJobAssignmentAsync.done({
				result: response.data,
				params: updateRequest,
				response
			})
		);
	} catch (error) {
		yield put(
			updatePortCallEventJobAssignmentAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* updatePortCallEventJobAssignmentWatcher(): SagaIterator {
	yield takeLatest(
		updatePortCallEventJobAssignmentAsync.type,
		updatePortCallEventJobAssignmentWorker
	);
}
