import { Currency } from 'services/api/currencies/currenciesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface CurrenciesState {
	byCode: { [code: string]: Currency };
	fetchStatuses: {
		all: FetchStatus;
	};
}

export const initialState: CurrenciesState = {
	byCode: {},
	fetchStatuses: {
		all: FetchStatus.IDLE
	}
};
