import {
	Group,
	GroupTypeCodes,
	GroupType
} from 'services/api/groups/groupsServiceTypes';
import { Permission } from 'services/api/permissions/permissionsServiceTypes';
import { PagedRequest, SearchResultCollection } from 'services/api/apiTypes';
import { Entity } from 'app-types';

export enum UserType {
	HUB = 'Hub',
	PRINCIPAL = 'Principal',
	LPA = 'Lpa',
	SYSTEM = 'System'
}

export enum UserStatus {
	ACTIVE = 'Active',
	DISABLED = 'Disabled',
	PERM_LOCKED = 'PermLocked',
	REGISTRATION_PENDING = 'RegistrationPending',
	VERIFICATION_PENDING = 'VerificationPending',
	TEMP_LOCKED = 'TempLocked'
}

export type UserGetResponseStatusCode =
	| 'Active'
	| 'Disabled'
	| 'PermLocked'
	| 'RegistrationPending'
	| 'VerificationPending'
	| 'TempLocked';

export interface UserAuth {
	id: string;
	fullName: string;
	email: string;
	groups: UserAuthGroup[];
	userType: UserType;
	userGroupType: GroupTypeCodes;
	companyId: string;
	timeFormat: string;
	timeZone: string;
	dateFormat: string;
	hasLpaApplicationAccess: boolean;
	hasQuarterMasterApplicationAccess: boolean;
	restrictAccessToOptic: boolean;
}

export enum GroupAccessType {
	SPECIFIC = 'Specific',
	ALL = 'All'
}

export interface UserAuthGroup {
	id: string;
	mainPrincipals: {
		accessType: GroupAccessType;
		permissions: Array<{
			entityId: string;
			entityType: string;
		}>;
	};
	functionalPermissions: Permission[];
}

/** UserGetResponse */
interface BaseUser {
	cellphone: string;
	company: UserCompany;
	companyId: string; // Model should be updated and this field should be removed
	createdOn: string;
	dateFormat: string;
	email: string;
	firstName: string;
	fullName: string;
	groups: Group[];
	id: string;
	lastActivityOn: string;
	lastName: string;
	middleName: string;
	status: UserGetResponseStatusCode;
	timeFormat: string;
	timeZone: string;
	updatedOn: string;
	userGroupType: GroupTypeCodes;
	userType: UserType;
}

interface UserCompany {
	companyId: string;
	companies: string[];
	name: string;
	parentCompanyId: string | null;
	// Model should be updated to the fields below
	id?: string;
	role?: string[];
	isDeleted: boolean;
}
export interface User extends BaseUser {
	identityProvider: string;
	identityProviderId: string;
	invitationToken: string;
	invitationSentOn: string;
	invitationAcceptedOn: string;
	termsOfServiceAcceptedOn: string;
	termsOfServiceAcceptedVersion: string;
	statusReasonCode: string | null;
	isInternalUser: boolean;
}

export interface RetrieveUsersRequest extends PagedRequest {
	status?: string;
	companiesIds?: string;
	ids?: string;
	searchTerm?: string;
}

enum UserRequestExpand {
	GROUPS = 'Groups'
}

interface RetrieveUserRequestParams {
	expand?: UserRequestExpand;
}

export interface RetrieveUserRequest {
	userId: string;
	params?: RetrieveUserRequestParams;
}

export interface InviteUserRequest {
	companyId: string;
	email: string;
	firstName: string;
	lastName: string;
	groups: string[];
	invitationMessage: string;
}

interface BaseUserFormData {
	cellphone: string;
	email: string;
	firstName: string;
	middleName: string;
	lastName: string;
	timeZone: string;
	dateFormat: string;
	timeFormat: string;
	groups?: string[];
}

export interface UserUpdateRequest extends BaseUserFormData {}
export interface UpdateUserParams {
	id: User['id'];
	request: UserUpdateRequest;
	isProfilePage: boolean;
}

export interface UserUpdateStatusRequest {
	status: UserGetResponseStatusCode;
	reason?: string;
}
export interface UserUpdateStatusParams {
	id: User['id'];
	request: UserUpdateStatusRequest;
}

export interface ResendInvitationRequest extends InviteUserRequest {
	userId: string;
}

export interface UserStatusesOption {
	code: string;
	name: string;
}

export interface GroupUser {
	id: string;
	isDeleted: boolean;
	isMessagingGroup: boolean;
	name: string;
	groupType: GroupType;
}
export interface UserProfile extends User {
	assignedGroups: GroupUser[];
	availableGroups: GroupUser[];
}

export interface UserContact {
	id?: string;
	email: string;
	fullName?: string;
}

export interface UserSearchRequest {
	searchTerm: string;
	groupType?: UserSearchGroupType;
	showMore?: boolean;
}

export type UserSearchGroupType = 'Emails' | 'Companies' | 'Usernames';

export interface UserSearchResultItem {
	id: string;
	name: string;
	email?: string;
}

export type UserSearchResultCollection = SearchResultCollection<
	UserSearchGroupType,
	UserSearchResultItem
>;

export interface UserDetailsFormData extends BaseUserFormData {
	companyName: string;
}

export interface RetrieveUsersOfMainPrincipalRequest {
	companyId: string;
}

export type RetrieveUsersOfMainPrincipalResponse = Entity[];
