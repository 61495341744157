import React, { FC } from 'react';
import { PageHeader, Text } from 'components';
import { FormattedTime } from 'containers';
import { UserProfile } from 'services/api/users/userServiceTypes';

interface EditUserPageHeader {
	userProfile: UserProfile;
}

const EditUserPageHeader: FC<EditUserPageHeader> = ({ userProfile }) => {
	return (
		<>
			<PageHeader.NewTab>
				{userProfile.fullName || 'User Details'}
			</PageHeader.NewTab>
			<PageHeader shadowed justified>
				<div>
					Last Login:{' '}
					<Text weight="bold">
						<FormattedTime value={userProfile.lastActivityOn} convertFromUtc />
					</Text>
				</div>
			</PageHeader>
		</>
	);
};

export default EditUserPageHeader;
