import * as React from 'react';
import { connect } from 'react-redux';
import {
	getIsSupplierSelectDisabled,
	getIsCreditInvoice
} from 'store/form/supplierInvoiceContextualization/selectors';
import { getAddSupplierInvoiceCurrencyDecimalPlaces } from 'store/supplierInvoice/selectors';
import { FormField } from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { searchEntitiesForAutocomplete } from 'containers/Autocomplete/Autocomplete.func';
import { Row, Col, Checkbox } from 'components/antd';
import InputNumber from 'components/antd/InputNumber/InputNumber';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';
import Input from 'components/antd/Input/Input';
import TextArea from 'components/antd/Input/TextArea';
import Text from 'components/Styles/Text/Text';
import { PermissionWrapper } from 'components/Permission';
import Api from 'services/api';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import SupplierDetailsColumn from 'sections/SupplierInvoice/components/SupplierDetailsColumn/SupplierDetailsColumn';
import { AppState } from 'store-types';
import WithMinus, { MinusPosition } from 'components/WithMinus/WithMinus';
import styles from './SupplierDetailsFormSection.module.scss';

interface SupplierDetailsFormSectionProps {
	isCreditCheckboxDisabled?: boolean;
	isTriggeredViaPostCredit?: boolean;
	// from mapStateToProps
	isSupplierSelectDisabled: boolean;
	isCredit: boolean;
	currencyDecimalPlaces?: number;
}

const onCurrencySearch = (searchTerm?: string) =>
	searchEntitiesForAutocomplete(
		Api.Currencies.retrieveCurrenciesForAutocomplete,
		searchTerm
	);

const onMainPrincipalSearch = (searchTerm?: string) =>
	searchEntitiesForAutocomplete<{
		actionType: PermissionCode.MANAGE_SUPPLIER_INVOICES;
	}>(Api.Companies.searchMainPrincipalsForAutocomplete, searchTerm, {
		actionType: PermissionCode.MANAGE_SUPPLIER_INVOICES
	});

const SupplierDetailsFormSection: React.SFC<SupplierDetailsFormSectionProps> = ({
	isSupplierSelectDisabled,
	isCredit,
	isTriggeredViaPostCredit,
	isCreditCheckboxDisabled,
	currencyDecimalPlaces
}) => (
	<>
		<Row>
			<SupplierDetailsColumn>
				<Text weight="semi-bold">Supplier Details</Text>
			</SupplierDetailsColumn>
		</Row>
		<Row>
			<SupplierDetailsColumn isBlock={false} bottom={false}>
				{isCreditCheckboxDisabled || isTriggeredViaPostCredit ? (
					<Checkbox.ReduxFormItem name={FormField.IS_CREDIT} disabled>
						Credit invoice
					</Checkbox.ReduxFormItem>
				) : (
					<PermissionWrapper
						permissionCode={PermissionCode.MANAGE_CREDIT_SUPPLIER_INVOICES}
					>
						{hasPermission => (
							<Checkbox.ReduxFormItem
								name={FormField.IS_CREDIT}
								disabled={!hasPermission}
							>
								Credit invoice
							</Checkbox.ReduxFormItem>
						)}
					</PermissionWrapper>
				)}
			</SupplierDetailsColumn>
		</Row>
		<Row>
			<SupplierDetailsColumn>
				<AutocompleteEntity
					name={FormField.MAIN_PRINCIPAL_COMPANY}
					label="Hub Principal"
					required
					onSearch={onMainPrincipalSearch}
					allowClear
					shouldSearchAllOptionsOnFocus
					minLength={1}
					disabled={isTriggeredViaPostCredit}
				/>
			</SupplierDetailsColumn>
			<SupplierDetailsColumn>
				<AutocompleteEntity
					name={FormField.SUPPLIER_COMPANY}
					label="Supplier"
					required
					disabled={isSupplierSelectDisabled || isTriggeredViaPostCredit}
					onSearch={Api.Companies.searchVendorsForAutocomplete}
					allowClear
				/>
			</SupplierDetailsColumn>
		</Row>
		<Row>
			<SupplierDetailsColumn>
				<DatePickerExtendedFormItem
					name={FormField.INVOICE_DATE}
					label="Supplier Invoice Date"
					showTime={false}
					required
					local
					help
				/>
			</SupplierDetailsColumn>
			<SupplierDetailsColumn>
				<DatePickerExtendedFormItem
					name={FormField.RECEIPT_DATE}
					label="Invoice Receipt Date"
					showTime={false}
					required
					local
					help
				/>
			</SupplierDetailsColumn>
		</Row>
		<Row>
			<SupplierDetailsColumn xs={6}>
				<Input.ReduxFormItem
					name={FormField.REFERENCE_NUMBER}
					label="Invoice reference"
					maxLength={100}
					required
				/>
			</SupplierDetailsColumn>
			<Col xs={6}>
				<Row>
					<SupplierDetailsColumn>
						<AutocompleteEntity
							name={FormField.CURRENCY_CODE}
							label="Currency"
							disabled={isTriggeredViaPostCredit}
							required
							onSearch={onCurrencySearch}
							allowClear
							shouldSearchAllOptionsOnFocus
							minLength={1}
							dropdownClassName={styles.currencySelect}
						/>
					</SupplierDetailsColumn>
					<SupplierDetailsColumn>
						<WithMinus
							position={isCredit ? MinusPosition.BEFORE : undefined}
							beforeFormItem
						>
							<InputNumber.ReduxFormItem
								decimalPart={currencyDecimalPlaces}
								maxDigits={100}
								min={1}
								withDelimiter={false}
								name={FormField.TOTAL}
								label="Invoice Total"
								required
							/>
						</WithMinus>
					</SupplierDetailsColumn>
				</Row>
			</Col>
		</Row>
		<Row>
			<SupplierDetailsColumn>
				<InputNumber.ReduxFormItem
					name={FormField.DISCOUNT}
					label="Discount"
					integerPart={13}
					decimalPart={6}
					withDelimiter={false}
				/>
			</SupplierDetailsColumn>
			<SupplierDetailsColumn>
				<TextArea.ReduxFormItem
					name={FormField.COMMENT}
					label="Comment"
					maxLength={10000}
					rows={2}
				/>
			</SupplierDetailsColumn>
		</Row>
	</>
);

export default connect((state: AppState) => ({
	isSupplierSelectDisabled: getIsSupplierSelectDisabled(state),
	isCredit: getIsCreditInvoice(state),
	currencyDecimalPlaces: getAddSupplierInvoiceCurrencyDecimalPlaces(state)
}))(SupplierDetailsFormSection);
