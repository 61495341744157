import { createSelector } from 'reselect';
import { uniqBy, flow, isEmpty } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { getIsLoading, getIsIdle } from 'store/selectors';
import { getUserIdSelector } from 'store/auth/selectors';
import { AppState } from 'store-types';
import { Entity } from 'app-types';
import {
	PermissionGroupWithUsers,
	ContextType
} from 'services/api/threads/threadsServiceTypes';
import { MailboxAvailableGroup } from 'services/api/mailboxes/mailboxesServiceTypes';
import { PATHS } from 'sections/App/RouteParams';
import { passArgument, passArgument2 } from 'store/utils';
import { PortCallJobSearchResult } from 'services/api/portCalls/portCallsServiceTypes';
import { getCurrentPath } from 'store/route/selectors';
import { isInComposeMessageRoute } from 'store/route/routeUtils';
import { THREAD_GROUP_ASSIGNMENT_FORM_ID } from 'sections/Thread/Settings/AssignGroupModal/AssignGroupModalTypes';
import { THREADS_ASSIGNTO_FORM } from 'sections/ThreadsList/ThreadCategorizing/AssignToModal/AssignToModalFormTypes';
import { getFormSingleValue } from 'store/form/selectors';
import { GroupUser } from 'services/api/groups/groupsServiceTypes';

const getThread = (state: AppState) => state.thread.thread;
export const getThreadContext = (state: AppState): ContextType | null =>
	state.thread.thread.item ? state.thread.thread.item.context : null;
export const getThreadNewPermissionsSelector = (state: AppState): Entity[] =>
	state.thread.thread.newPermissions;
export const getMailboxAvailableGroupsSelector = (
	state: AppState
): MailboxAvailableGroup[] => state.thread.thread.mailboxAvailableGroups;
const getAvailableUsersForGroupSelector = (state: AppState) =>
	state.thread.availableGroups.groupUsers;
const getAvailableGroupsSelector = (state: AppState) =>
	state.thread.availableGroups.groups;
export const getUpdatingFetchStatus = (state: AppState) =>
	state.thread.thread.fetchStatuses.updating;
/**
 * Shorthand for getting data from the store
 */
export const getComposeMessage = (state: AppState) =>
	state.thread.thread.composeMessage;

export const getIsContextAndJobVisible = (state: AppState) =>
	state.thread.thread.isContextAndJobVisible;

export const getActiveThread = (state: AppState) =>
	state.thread.thread.item || undefined;

export const getThreadMainPrincipalId = createSelector(
	getActiveThread,
	thread => (thread ? thread.assignedToInbox.companyId : undefined)
);

export const getThreadTags = createSelector(getActiveThread, thread =>
	thread ? thread.tags : undefined
);

export const getThreadLastReadMessage = createSelector(
	getActiveThread,
	thread => thread?.lastRead
);

export const getThreadGroupPermissions = createSelector(
	getActiveThread,
	thread =>
		thread
			? thread.permissions.filter(
					group => group.id !== thread.assignedToGroup.id
			  )
			: []
);

export const getThreadFetchStatus = (state: AppState) =>
	state.thread.thread.fetchStatuses.retrieving;

export const getIsThreadLoading = (state: AppState) =>
	getIsLoading(state.thread.thread.fetchStatuses.retrieving);

export const getIsAddThreadMessageLoading = (state: AppState) =>
	getIsLoading(state.thread.thread.fetchStatuses.addMessage);

export const isMailboxAvailableGroupsRetrieving = (state: AppState) =>
	state.thread.thread.fetchStatuses.retrievingMailboxAvailableGroups ===
	FetchStatus.PENDING;

export const isJobBeingAssigningToThread = (state: AppState, jobCode: string) =>
	getIsLoading(state.thread.thread.fetchStatuses.assigning[jobCode]);

export const isFetchingUsersSelector = (state: AppState) =>
	state.thread.availableGroups.fetchStatuses.users;
export const isFetchingGroupsSelector = (state: AppState) =>
	state.thread.availableGroups.fetchStatuses.groups;

/** Default unnasigned option */
export const OPTION_UNASSIGNED = 'unassigned';

export const getPermissionsFromAssignGroupForm = (
	state: AppState
): PermissionGroupWithUsers[] =>
	getFormSingleValue(
		state,
		THREAD_GROUP_ASSIGNMENT_FORM_ID,
		'threadPermissionGroups'
	) || [];

export const getThreadsPermissionsFromAssignGroupForm = (
	state: AppState
): PermissionGroupWithUsers[] =>
	getFormSingleValue(state, THREADS_ASSIGNTO_FORM, 'threadPermissionGroups') ||
	[];

/**
 * Get the thread permissions groups
 * (The list that's rendered on the AssignGroupModal -> AssignGroupForm)
 * @return {array}
 */

const getThreadPermissionGroupsOptions = (
	permissions: PermissionGroupWithUsers[],
	usersByGroup: { [groupId: string]: GroupUser[] }
) => {
	return permissions.reduce((acc: PermissionGroupWithUsers[], permission) => {
		const groupId = permission.id;

		const groupUsers = usersByGroup[groupId] || [];

		let options = [
			{
				name: '-- Unassigned --',
				id: OPTION_UNASSIGNED // Can't be null because <Select /> needs a string as value
			}
		];
		if (groupUsers) {
			options = [
				...options,
				...groupUsers.map(user => ({
					name: `${user.firstName} ${user.lastName}`,
					id: user.id
				}))
			];
		}

		const groupWithOptions: PermissionGroupWithUsers = {
			...permission,
			id: groupId,
			name: permission.name,
			options
		};

		return [...acc, groupWithOptions];
	}, []);
};

export const getThreadPermissionGroupsOptionsSelector = createSelector(
	getPermissionsFromAssignGroupForm,
	getAvailableUsersForGroupSelector,
	(permissions, usersByGroup): PermissionGroupWithUsers[] => {
		return getThreadPermissionGroupsOptions(permissions, usersByGroup);
	}
);

export const getThreadsPermissionGroupsOptionsSelector = createSelector(
	getThreadsPermissionsFromAssignGroupForm,
	getAvailableUsersForGroupSelector,
	(threadsPermission, usersByGroup): PermissionGroupWithUsers[] => {
		return getThreadPermissionGroupsOptions(threadsPermission, usersByGroup);
	}
);

const removeDuplicateGroups = (groups: PermissionGroupWithUsers[]) => {
	return (
		groups
			// Get only the group.ids
			.map(group => group.id)
			// Remove duplicates
			.filter((group, index, self) => self.indexOf(group) === index)
	);
};

const getPermissionsIdSelector = createSelector(
	getThreadPermissionGroupsOptionsSelector,
	permissions => {
		return removeDuplicateGroups(permissions);
	}
);

const getThreadsPermissionsIdSelector = createSelector(
	getThreadsPermissionGroupsOptionsSelector,
	permissions => {
		return removeDuplicateGroups(permissions);
	}
);

/**
 * getAvailableGroupsOptionsSelector
 * Get the thread permissions and prepare the modal dropdown options
 * @return {array}
 */
export const getAvailableGroupsOptionsSelector = (
	groups: Entity<string>[],
	permissionsId: string[]
) => {
	const result = uniqBy(groups, 'id')
		.reduce((acc: Entity[], group) => {
			const groupId = group.id;

			/**
			 * Check if each group doesn't exist on the newPermission list
			 * if so, exclude it
			 */
			const exists = permissionsId.indexOf(groupId);

			if (exists === -1) {
				acc.push(group);
			}
			return acc;
		}, [])
		.sort((first, second) => {
			if (first.name > second.name) {
				return 1;
			}
			if (first.name < second.name) {
				return -1;
			}
			return 0;
		});
	return result;
};

export const getThreadAvailableGroupsOptionsSelector = createSelector(
	getAvailableGroupsSelector,
	getPermissionsIdSelector,
	(threadAvailableGroups, permissions) => {
		return getAvailableGroupsOptionsSelector(
			threadAvailableGroups,
			permissions
		);
	}
);

export const getThreadsAvailableGroupsOptionsSelector = createSelector(
	getThreadsPermissionsIdSelector,
	(_state: AppState, threadsAvailableGroups: Entity[]) =>
		threadsAvailableGroups,
	(permissions, threadsAvailableGroups) => {
		return getAvailableGroupsOptionsSelector(
			threadsAvailableGroups,
			permissions
		);
	}
);

export const getIsCurrentUserAssignedToActiveThread = createSelector(
	getActiveThread,
	getUserIdSelector,
	(thread, userId): boolean => {
		if (!thread) {
			return false;
		}
		const { assignedToUser } = thread;
		return !isEmpty(assignedToUser) && assignedToUser.id === userId;
	}
);

export const getThreadLastConcurrencyToken = (state: AppState) =>
	state.thread.thread.context.lastConcurrencyToken;

export const getIsContextDisabled = createSelector(
	getActiveThread,
	(_state: AppState, isNew: boolean) => isNew,
	(thread, isNew) => !isNew && !!thread && thread.isProtected
);

export const getRetrieveMessageDataFetchStatus = createSelector(
	getThread,
	({ fetchStatuses }) => fetchStatuses.retrieveMessageData
);

export const getComposeMessagePrevPath = createSelector(
	getIsContextAndJobVisible,
	passArgument<string>(),
	passArgument2<[string, string] | undefined>(),
	(jobContextVisible, threadId, jobData) => {
		const messagesPath = `/${PATHS.messages}`;
		const threadPath = `${messagesPath}/${threadId}`;
		if (!jobData) {
			if (jobContextVisible) {
				return messagesPath; // there will be created new thread, we go out from the current one
			}
			return threadPath;
		}
		const [portCallId, portJobCode] = jobData;
		return `/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}${threadPath}`;
	}
);

export const getMergedAssignedToNewThreadJobCodes = createSelector(
	getActiveThread,
	(state: AppState) => state.threads.jobCodesForNewThread, // this is exact copy of getAssignedToNewThreadJobCodes but threads and thread selector cannot have cycle dependencies
	(thread, jobCodesInNewThread = []) => {
		return (thread ? thread.jobCodes : []).concat(jobCodesInNewThread);
	}
);

const getQuickJobAssignToThread = (state: AppState) =>
	state.thread.thread.quickJobAssigment;

const setNameAsCode = (portJob: PortCallJobSearchResult) => ({
	...portJob,
	name: portJob.code
});
const setDisabled = (assignedCodes: string[]) => (
	portJob: PortCallJobSearchResult
) => ({ ...portJob, disabled: assignedCodes.includes(portJob.code) });

export type PortCallJobSearchResultWithDisabled = PortCallJobSearchResult & {
	disabled: boolean;
};
export const getQuickJobAssignToThreadResults = createSelector(
	getQuickJobAssignToThread,
	getMergedAssignedToNewThreadJobCodes,
	({ jobs }, assignedJobCodes): PortCallJobSearchResultWithDisabled[] =>
		jobs.map(flow(setNameAsCode, setDisabled(assignedJobCodes)))
);

export const getIsQuickSearchInIdle = (state: AppState) =>
	getIsIdle(getQuickJobAssignToThread(state).fetchStatus);

export const getIsJobForThreadAssigmentLoading = (state: AppState) =>
	getIsLoading(getQuickJobAssignToThread(state).fetchStatus) ||
	getIsLoading(getQuickJobAssignToThread(state).assignStatus) ||
	getIsThreadLoading(state);

const getNewThreadCurrentMessageMailboxCompany = (state: AppState) =>
	state.thread.thread.context.messageCurrentSelectedMailboxCompany;
export const getNewTreadMainPrincipalId = createSelector(
	getNewThreadCurrentMessageMailboxCompany,
	company => company?.id
);

export const getIsQuickAssignJobToThreadAvailable = createSelector(
	getNewThreadCurrentMessageMailboxCompany,
	getCurrentPath,
	(mailbox, path = '') => !isInComposeMessageRoute(path) || !!mailbox // either it is not compose message page or it is and mailbox is set
);
