import {
	DraftsState,
	DraftsByType,
	CompanyDraft,
	DraftKind
} from '../draftsState';
import { AddCompaniesDraftPayload } from '../actions';
import { CompanyDraftResponse } from 'services/api/portJobs/portJobsServiceTypes';
import { getDraftTypeFromOrganisationType } from '../draftsHelpers';

const getCompanyWithSavedCompanyData = (
	company: CompanyDraftResponse
): CompanyDraft => ({
	...company,
	kind: DraftKind.SAVED,
	name: company.companyName
});

export const onAddCompaniesDraft = (
	state: DraftsState,
	companies: AddCompaniesDraftPayload
): DraftsState => {
	const draftsByType = companies.reduce((byType, company): DraftsByType => {
		const draftType = getDraftTypeFromOrganisationType(
			company.companyOrganizationTypeCode
		);
		return {
			...byType,
			...draftType
				? {
						[draftType]: [
							...byType[draftType],
							getCompanyWithSavedCompanyData(company)
						]
					}
				: {}
		};
	}, state.draftsByType);
	return {
		...state,
		draftsByType
	};
};
