import {
	UpdateDirectBillResponse,
	UpdateDirectBillRequest
} from 'services/api/directBills/directBillsServiceTypes';
import actionCreator from '../directBillsActionCreator';

const ACTION_NAME = 'UPDATE_DIRECT_BILL';

export const updateDirectBillAsync = actionCreator.async<
	UpdateDirectBillRequest,
	UpdateDirectBillResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Direct Bill'
	}
});

export const updateDirectBill = actionCreator(ACTION_NAME);
