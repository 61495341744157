import { LabeledValue } from 'app-types';
import { FeedbackManagementRequestId } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationTabKey } from '../ConfigurationTypes';

export const FEEDBACK_MANAGEMENT_FORM_NAME =
	ConfigurationTabKey.FEEDBACK_MANAGEMENT;

export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';

export const DELETE_CONFIRMATION_MODAL = 'DELETE_CONFIRMATION_MODAL';

export enum FeedbackManagementFormFields {
	NAME = 'name',
	APPOINTMENT = 'appointment',
	NOMINATION = 'nomination',
	CHARTERER_OVERSIGHT = 'chartereroversight',
	APPOINTMENT_FROM_NOMINATION = 'appointmentfromnomination',
	COMPANY_ID = 'companyId',
	IS_COMPANY_EMAIL = 'isCompanyEmail',
	IS_VESSEL_EMAIL = 'isVesselEmail',
	IS_CUSTOM_EMAIL = 'isCustomEmail',
	IS_FIRST_INSTANCE = 'isFirstInstance',
	CUSTOM_EMAILS = 'customEmails',
	EMAIL_CONTENT = 'emailContent',
	ONLINE_SURVEY = 'onlineSurvey',
	SURVEY_URL = 'surveyUrl',
	DOCUMENT_ID = 'documentId',
	FEEDBACK_EVENT = 'feedbackEvent',
	FEEDBACK_VARIABLES = 'feedbackVariables',
	YES = 'yes',
	NO = 'no'
}

export const feedbackJobTypeLabel = {
	[FeedbackManagementFormFields.NOMINATION]: 'Nomination',
	[FeedbackManagementFormFields.APPOINTMENT]: 'Appointment',
	[FeedbackManagementFormFields.CHARTERER_OVERSIGHT]: 'Charterer Oversight',
	[FeedbackManagementFormFields.APPOINTMENT_FROM_NOMINATION]:
		'Appointment From Nomination'
};

export interface FeedbackManagementFormData
	extends FeedbackManagementRequestId {
	name: string;
	companyId: string;
	isCompanyEmail: boolean;
	isVesselEmail: boolean;
	isCustomEmail: boolean;
	appointment: boolean;
	nomination: boolean;
	chartereroversight: boolean;
	appointmentfromnomination: boolean;
	isFirstInstance: boolean;
	customEmails: { label: string }[];
	emailContent: string;
	onlineSurvey: string;
	surveyUrl: string;
	documentId: string[];
	feedbackEvent: string[];
	feedbackVariables: LabeledValue[];
}

export enum TriggerEvent {
	ALL_FAST = 'AllFast',
	APPOINTMENT_ACCEPTANCE = 'AppointmentAcceptance',
	CE_APPROVED = 'CEApproved',
	COMPLETE = 'Complete',
	COSP = 'COSP',
	EOSP = 'EOSP',
	JOB_CREATION = 'JobCreation',
	HUB_SETTLEMENT = 'HubSettlement',
	LPA_SETTLEMENT = 'LPASettlement',
	PDA_APPROVE = 'PDAApproved'
}

export enum OpticVariable {
	AGGENT_NAME = 'AgentName',
	COSP = 'COSP',
	CUSTOMER_NAME = 'CustomerName',
	CUSTOMER_REFERENCE = 'CustomerReference',
	EOSP = 'EOSP',
	ETA = 'ETA',
	ETS = 'ETS',
	JOB_ID = 'JobID',
	PORT_NAME = 'PortName',
	VESSEL_NAME = 'VesselName'
}

export const triggerEventMap = {
	[TriggerEvent.ALL_FAST]: 'All Fast',
	[TriggerEvent.APPOINTMENT_ACCEPTANCE]: 'Appointment Acceptance',
	[TriggerEvent.CE_APPROVED]: 'CE Approved',
	[TriggerEvent.COMPLETE]: 'Complete',
	[TriggerEvent.COSP]: 'COSP',
	[TriggerEvent.EOSP]: 'EOSP',
	[TriggerEvent.JOB_CREATION]: 'Job Creation',
	[TriggerEvent.HUB_SETTLEMENT]: 'Hub Settlement',
	[TriggerEvent.LPA_SETTLEMENT]: 'LPA Settlement',
	[TriggerEvent.PDA_APPROVE]: 'PDA Approved'
};

export const opticVariableMap = {
	[OpticVariable.AGGENT_NAME]: 'Agent Name',
	[OpticVariable.COSP]: 'COSP',
	[OpticVariable.CUSTOMER_NAME]: 'Customer Name (Appointer)',
	[OpticVariable.CUSTOMER_REFERENCE]: 'Customer Reference',
	[OpticVariable.EOSP]: 'EOSP',
	[OpticVariable.ETA]: 'ETA',
	[OpticVariable.ETS]: 'ETS',
	[OpticVariable.JOB_ID]: 'Job ID',
	[OpticVariable.PORT_NAME]: 'Port Name',
	[OpticVariable.VESSEL_NAME]: 'Vessel Name'
};

export const REQUEST_FEEDBACK_MODAL = 'REQUEST_FEEDBACK_MODAL';

export enum FeedbackManagementColumns {
	ID = 'id',
	NAME = 'name',
	ONLINE_SURVEY = 'onlineSurvey',
	EMAILS = 'emails',
	EDIT = 'edit',
	FEEDBACK_DOCUMENTS = 'feedbackDocuments',
	FEEDBACK_EVENTS = 'feedbackEvents',
	FEEDBACK_JOB_TYPE = 'feedBackJobType',
	FEEDBACK_VARIABLES = 'feedBackVariables'
}
