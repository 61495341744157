import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { formValueSelector } from 'redux-form';
import {
	FORM,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	FormData,
	CargoLineFormDataTypes
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { getOperationTypeCodeById } from 'store/operations/operationsSelectors';

import { Asteriks } from 'components';
import { Table } from 'components/antd';
import getCargoGridColumns from './CargoGridColumns';
import { getUnitsByCode } from 'store/units/unitsSelectors';
import { getCountriesMap } from 'store/countries/countriesSelectors';
import { UnitByCode } from 'store/units/unitsState';
import { Country } from 'services/api/countries/countriesServiceTypes';
import { AppState } from 'store-types';

export interface CargoGridProps extends Pick<FormData, 'cargoes'> {
	onDeleteClicked: (
		cargoId: string,
		chartererCompanyId: string,
		isParent: boolean
	) => void;
	onEditClicked: (cargoId: string) => void;
	// from mapStateToProps
	operationTypeCode: OperationTypeCode;
	unitsByCode: UnitByCode;
	countriesByCode: {
		[id: string]: Country;
	};
}

export interface Column extends CargoLineFormDataTypes {}

const CargoGrid = (props: CargoGridProps) => {
	const Columns = getCargoGridColumns(props);
	return (
		<Table
			dataSource={props.cargoes}
			pagination={false}
			columns={Columns}
			showHeader
			locale={{
				emptyText: (
					<Asteriks position="right">Add Details for First Cargo Line</Asteriks>
				)
			}}
		/>
	);
};

const formSelector = formValueSelector(FORM.portJob);

export default connect((state: AppState) => {
	const operationType = formSelector(state, FormFieldName.OPERATION_TYPE);
	return {
		operationTypeCode: getOperationTypeCodeById(
			state,
			get(operationType, 'key')
		),
		unitsByCode: getUnitsByCode(state),
		countriesByCode: getCountriesMap(state)
	};
})(CargoGrid);
