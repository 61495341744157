import {
	DownloadAllDAsRequest,
	ConvertDBStatusRequest,
	RetrieveFinanceDocumentImageRequest,
	RetrieveFinanceDocumentImageResponse,
	ReopenDaRequest,
	ReverseSplitServiceRequest,
	ReverseSplitResponse,
	UpdateFinanceStatusRequest,
	CancelCreditHubRevenueRequest,
	AddCreditHubRevenueServiceRequest,
	RetrieveDAStatusHistoryByIdRequest,
	AddFinanceComments,
	AddDAOutRequest,
	AddDAOutResponse,
	DeleteDAOutRequest,
	DeleteDAOutResponse,
	RefreshBenchmarkRequest,
	UpdateBenchmarkRequest,
	ViewBenchmarkRequest,
	ViewBenchmarkResponse,
	ToggleFinanceRequest,
	RetrieveRefreshDirectBillResponse,
	RetrieveRefreshDirectBillRequest,
	UpdateRefreshDirectBillRequest,
	UpdateRefreshDirectBillResponse,
	UpdateFixedRateExchangeRequest,
	MoveServiceRequest,
	RetrieveAvailableSectionCodeResponse,
	RetrieveSupplierInvoiceDocumentImageRequest,
	RetrieveSupplierInvoiceDocumentImageResponse,
	RestoreOriginalFinanceDocumentRequest,
	DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest,
	RetrieveDAInCoverSheetRequest
} from 'services/api/finance/financeServiceTypes';
import client from 'services/client';
import queryString from 'query-string';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import {
	UpdateFinanceServiceRequest,
	MoveJobFinanceResponse,
	AddDARequest,
	UpdateDARequest,
	AddDAResponse,
	AddServiceRequest,
	SplitServiceRequest,
	ValidateJobFinanceRequest,
	ValidateJobFinanceResponse,
	MoveJobFinanceRequest,
	MoveToStageJobFinanceRequest,
	SearchApprovalGroupsRequest,
	DeleteDARequest,
	RetrieveFinanceDetailsRequest,
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse,
	RetrieveDAOutCoverSheetRequest,
	RetrieveDAOutCoverSheetResponse,
	FinanceDetails,
	RetrieveAvailableServicesRequest,
	RetrieveAvailableServicesResponse,
	AddServiceResponse,
	SplitServiceResponse,
	CancelServiceTransactionRequest,
	DeleteServiceRequest,
	UpdateServiceStatusRequest,
	ConvertServiceRequest,
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse,
	RetrieveServiceRequest,
	RetrieveFinanceServicesForContextualizationRequest,
	RetrieveFinanceServicesForContextualizationResponse,
	CreateFinanceDocumentsRequest,
	RetrieveFinanceDocumentRequest,
	DeleteFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse,
	RevertJobStatusRequest,
	FinanceRequest,
	DownloadFinanceDocumentRequest,
	DownloadJobFinanceRevisionRequest,
	DeleteFinanceDocumentPageRequest,
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse,
	RetrieveFinanceRequest,
	RetrieveFinanceResponse,
	DownloadSingleDARequest,
	DownloadSingleDAResponse,
	RetrieveServiceQueriesRequest,
	DownloadAllDAsResponse,
	RetrieveFinanceCurrenciesResponse,
	RetrieveAvailableDasRequest,
	RetrieveSDAReasonRequest,
	SDAReasons,
	UpdateSDAReasonRequest,
	DaAvailabilityItem,
	UpdateUnitCostFormPayload,
	DeleteUnitCostFormRequest,
	SubmitUnitCostFormRequest
} from './financeServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { GetPOICostAssociationResponse } from '../vesselProgramme/vesselProgrammeServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

const retrieveFinance = ({
	portCallId,
	jobCode,
	expand
}: RetrieveFinanceRequest): AxiosTypedPromise<RetrieveFinanceResponse> => {
	const expandParams = ['metadata', ...(expand ? expand : [])];

	return client.get(`/finances/${portCallId}/jobs/${jobCode}`, {
		params: {
			expand: expandParams
		},
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});
};

const retrieveFinanceDetails = ({
	portCallId
}: RetrieveFinanceDetailsRequest) =>
	client.get<FinanceDetails>(`/finances/${portCallId}`);

export const retrieveService = ({
	portCallId,
	jobCode,
	jobServiceId
}: RetrieveServiceRequest) =>
	client.get(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}?expand=metadata`
	);

export const retrieveServiceQueries = ({
	portCallId,
	jobCode,
	jobServiceId
}: RetrieveServiceQueriesRequest) =>
	client.get(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/queries`
	);

export const retrieveSDAReason = ({ userType }: RetrieveSDAReasonRequest) =>
	client.get<SDAReasons[]>(`/finances/static/SDAReason?userType=${userType}`);

export const updateSDAReason = ({
	portCallId,
	jobCode,
	disbursementAccountSetId,
	...params
}: UpdateSDAReasonRequest) =>
	client.put(
		`finances/${portCallId}/jobs/${jobCode}/sets/${disbursementAccountSetId}/SDAReason`,
		params
	);

export const retrieveServiceErrors = () =>
	client.get(`/finances/static/service-errors`);

export const retrieveFinanceCurrencies = () =>
	client.get<RetrieveFinanceCurrenciesResponse>(`finances/static/currencies`);

export const updateFinanceService = (
	portCallId: string,
	portJobCode: string,
	request: UpdateFinanceServiceRequest
) => {
	return client.patch(
		`/finances/${portCallId}/jobs/${portJobCode}/services/${request.id}`,
		request
	);
};

const updateServiceStatus = ({
	portCallId,
	jobCode,
	jobServiceId,
	action,
	queries
}: UpdateServiceStatusRequest) =>
	client.put(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/${action}`,
		queries
	);

const convertService = ({
	portCallId,
	jobCode,
	jobServiceId
}: ConvertServiceRequest) =>
	client.post(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/convert`
	);

const getHasPointOfInterestAssociatedCosts = (
	portCallId: string,
	rotationStepId: string
): AxiosTypedPromise<GetPOICostAssociationResponse> =>
	client.get(
		`finances/${portCallId}/services/cost-associations/rotation-step/${rotationStepId}`
	);

const validateJobFinance = ({
	portCallId,
	jobCode
}: ValidateJobFinanceRequest) =>
	client.get<ValidateJobFinanceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/move-validation`
	);

const moveJobFinance = ({ portCallId, jobCode }: MoveJobFinanceRequest) =>
	client.put<MoveJobFinanceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/move`
	);

const setFixedRate = ({
	portCallId,
	jobCode,
	isFixedRate,
	daType,
	exchangeRates
}: UpdateFixedRateExchangeRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/das/exchange`, {
		isFixedRate,
		daType,
		exchangeRates
	});

const moveJobFinanceWithoutReApproval = ({
	portCallId,
	jobCode,
	isSubmitWithoutPrinicipalReApproval
}: MoveJobFinanceRequest) =>
	client.put<MoveJobFinanceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/${isSubmitWithoutPrinicipalReApproval}/move`
	);

const moveToStageJobFinance = ({
	portCallId,
	jobCode,
	stage
}: MoveToStageJobFinanceRequest) =>
	client.put<MoveJobFinanceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/moveTo${stage}`
	);

const revertJobStatus = ({ portCallId, jobCode }: RevertJobStatusRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/revert-status`);

const updateFinanceStatus = ({
	portCallId,
	jobCode,
	action,
	comment
}: UpdateFinanceStatusRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/${action}`, { comment });

const updateBenchmark = ({
	portCallId,
	jobCode,
	id,
	comment
}: UpdateBenchmarkRequest) =>
	client.patch(`/finances/${portCallId}/jobs/${jobCode}/benchmark`, {
		id,
		comment
	});

const approveAllServices = ({ portCallId, jobCode }: FinanceRequest) =>
	client.put<MoveJobFinanceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/approve-all-services`
	);

const addDA = ({ portCallId, jobCode, payload }: AddDARequest) =>
	client.post<AddDAResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das`,
		payload
	);

const addDAOut = ({ portCallId, jobCode, serviceId }: AddDAOutRequest) =>
	client.post<AddDAOutResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${serviceId}/addDaOut`
	);

const deleteDAOut = ({ portCallId, jobCode, serviceId }: DeleteDAOutRequest) =>
	client.post<DeleteDAOutResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${serviceId}/deleteDaOut`
	);

const addService = ({ portCallId, jobCode, payload }: AddServiceRequest) =>
	client.post<AddServiceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services`,
		payload
	);

const deleteService = ({
	portCallId,
	jobCode,
	jobServiceId
}: DeleteServiceRequest) =>
	client.delete(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}`
	);

const retrieveAvailableServices = ({
	portCallId,
	sectionCode,
	jobCode,
	entityId,
	action,
	portJobServiceType
}: RetrieveAvailableServicesRequest) =>
	client.get<RetrieveAvailableServicesResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/available`,
		{
			params: {
				sectionTypeCode: sectionCode,
				entityId,
				action,
				portJobServiceType
			}
		}
	);

const moveService = ({
	portCallId,
	jobCode,
	jobServiceId,
	...params
}: MoveServiceRequest) =>
	client.patch(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/moveService`,
		params
	);

const retrieveAvailableSections = ({ portCallId, jobCode }: FinanceRequest) =>
	client.get<RetrieveAvailableSectionCodeResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/FinanceAllSection`
	);

const splitService = ({
	portCallId,
	jobCode,
	serviceId,
	payload
}: SplitServiceRequest) =>
	client.post<SplitServiceResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${serviceId}/split`,
		payload
	);

const reverseSplitService = ({
	portCallId,
	jobCode,
	serviceId
}: ReverseSplitServiceRequest) =>
	client.post<ReverseSplitResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${serviceId}/reverse-split`
	);

const cancelServiceTransaction = ({
	portCallId,
	jobCode,
	jobServiceId
}: CancelServiceTransactionRequest) =>
	client.put(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/cancelRevenueInvoiceTransaction`
	);

const cancelCreditHubRevenue = ({
	portCallId,
	jobCode,
	jobServiceId
}: CancelCreditHubRevenueRequest) =>
	client.put(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/cancelRevenueInvoiceCreditNoteTransaction`
	);

const retrievePrincipalServices = ({
	portCallId,
	jobCode,
	jobServiceId
}: RetrievePrincipalServicesRequest) =>
	client.get<RetrievePrincipalServicesResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/principal-services`
	);

const updateDA = ({
	portCallId,
	jobCode,
	disbursementAccountId,
	payload
}: UpdateDARequest) =>
	client.patch<AddDAResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/${disbursementAccountId}`,
		payload
	);

const deleteDA = ({ portCallId, jobCode, id }: DeleteDARequest) =>
	client.delete(`/finances/${portCallId}/jobs/${jobCode}/das/${id}`);

const reopenDaSet = ({ portCallId, jobCode, daSetId }: ReopenDaRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/sets/${daSetId}/reopen`);

const searchApprovalGroups = ({
	portCallId,
	jobCode,
	searchTerm
}: SearchApprovalGroupsRequest) =>
	client.get<ResultCollection<{ id: string; name: string }>>(
		`/finances/${portCallId}/jobs/${jobCode}/approving-groups`,
		{
			params: {
				limit: DEFAULT_LIST_LIMIT,
				searchTerm
			}
		}
	);

const searchApprovalGroupsForAutocomplete = (
	request: SearchApprovalGroupsRequest
) =>
	searchApprovalGroups(request).then(
		(response): AutocompleteOption[] => response.data.elements
	);

const retrieveDAOutDocumentId = ({
	portCallId,
	jobCode,
	daOutId
}: RetrieveDAOutDocumentIdRequest) =>
	client.post<RetrieveDAOutDocumentIdResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/${daOutId}/cover-sheet`
	);

const retrieveDAOutCoverSheet = ({
	portCallId,
	jobCode,
	daOutId,
	documentId
}: RetrieveDAOutCoverSheetRequest) =>
	client.get<RetrieveDAOutCoverSheetResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/${daOutId}/cover-sheet/${documentId}`
	);

const retrieveDAInCoverSheet = ({
	portCallId,
	jobCode,
	daInId
}: RetrieveDAInCoverSheetRequest) =>
	client.post<RetrieveDAOutCoverSheetResponse>(
		`/finances-lpa/${portCallId}/jobs/${jobCode}/das/${daInId}/cover-sheet`
	);

const retrieveJobFinanceRevisions = ({
	portCallId,
	jobCode
}: RetrieveFinanceRevisionsRequest) =>
	client.get<RetrieveFinanceRevisionsResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/revisions`
	);

const retrieveFinanceServicesForContextualization = ({
	portCallId,
	jobCode
}: RetrieveFinanceServicesForContextualizationRequest) =>
	client.get<RetrieveFinanceServicesForContextualizationResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/documents/contextualization`
	);

const createFinanceDocuments = ({
	portCallId,
	jobCode,
	data
}: CreateFinanceDocumentsRequest) =>
	client.post(`/finances/${portCallId}/jobs/${jobCode}/documents`, data);

const retrieveFinanceDocument = ({
	portCallId,
	jobCode,
	id,
	documentType
}: RetrieveFinanceDocumentRequest) =>
	client.get<RetrieveFinanceDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/documents/${documentType}/${id}`
	);

const retrieveFinanceDocumentImage = ({
	portCallId,
	documentId,
	page
}: RetrieveFinanceDocumentImageRequest) =>
	client.get<RetrieveFinanceDocumentImageResponse>(
		`/finances/${portCallId}/documents/${documentId}/pages/${page}`
	);

const deleteFinanceDocument = ({
	portCallId,
	documentId
}: DeleteFinanceDocumentRequest) =>
	client.delete(`/finances/${portCallId}/documents/${documentId}`);

const downloadFinanceDocument = ({
	portCallId,
	documentId
}: DownloadFinanceDocumentRequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`finances/${portCallId}/documents/${documentId}/download`
	);

const pollDownloadFinanceDocument = ({
	portCallId,
	documentId
}: DownloadFinanceDocumentRequest) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`finances/${portCallId}/documents/${documentId}/download`
	);

const downloadJobFinanceRevision = ({
	portCallId,
	jobCode,
	revisionId,
	disbursementAccountType
}: DownloadJobFinanceRevisionRequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/revisions/${revisionId}/download`,
		{
			disbursementAccountType
		}
	);

const pollDownloadJobFinanceRevision = (
	{
		portCallId,
		jobCode,
		revisionId,
		disbursementAccountType
	}: DownloadJobFinanceRevisionRequest,
	{ documentId }: RetrieveDownloadDocumentResponse
) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/revisions/${revisionId}/download`,
		{
			params: { disbursementAccountType, documentId }
		}
	);

/**
 * Single DA
 */
const downloadSingleDA = ({
	portCallId,
	jobCode,
	disbursementAccountId
}: DownloadSingleDARequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/${disbursementAccountId}/download`
	);
const pollDownloadSingleDA = (
	{ portCallId, jobCode, disbursementAccountId }: DownloadSingleDAResponse,
	{ documentId }: RetrieveDownloadDocumentResponse
) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/${disbursementAccountId}/download`,
		{ params: { documentId } }
	);

/**
 * All DAs
 */
const downloadAllDAs = ({
	portCallId,
	jobCode,
	disbursementAccountType
}: DownloadAllDAsRequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/download`,
		{
			disbursementAccountType
		}
	);
const pollDownloadAllDAs = (
	{ portCallId, jobCode }: DownloadAllDAsResponse,
	{ documentId }: RetrieveDownloadDocumentResponse
) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/das/download`,
		{
			params: { documentId }
		}
	);

const deleteDocumentPage = ({
	portCallId,
	documentId,
	actualNumber,
	pageType
}: DeleteFinanceDocumentPageRequest) =>
	client.delete(
		`finances/${portCallId}/documents/${documentId}/pages/${actualNumber}/${pageType}`
	);

const deleteFinanceDocumentOriginalAndAnnotatedPages = ({
	portCallId,
	jobCode,
	documentId,
	documentType,
	actualNumber
}: DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest) =>
	client.delete(
		`finances/${portCallId}/jobs/${jobCode}/documents/${documentType}/${documentId}/pages/${actualNumber}`
	);

const convertToDirectBill = ({
	portCallId,
	jobCode,
	jobServiceId,
	comment
}: ConvertDBStatusRequest) =>
	client.post(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/convertToDirectBill`,
		{
			comment
		}
	);

const convertToRegular = ({
	portCallId,
	jobCode,
	jobServiceId,
	comment
}: ConvertDBStatusRequest) =>
	client.post(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/convertToRegular`,
		{
			comment
		}
	);

const addCreditHubRevenueService = ({
	portCallId,
	jobCode,
	jobServiceId,
	isCreditHubRevenueChangeRequested
}: AddCreditHubRevenueServiceRequest) =>
	isCreditHubRevenueChangeRequested
		? client.post(
				`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/addCreditHubRevenue/${isCreditHubRevenueChangeRequested}`
		  )
		: client.post(
				`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/addCreditHubRevenue/false`
		  );

const retrieveAvailableDas = ({
	portCallId,
	jobCode,
	jobServiceId,
	daType
}: RetrieveAvailableDasRequest) =>
	client.get<ResultCollection<DaAvailabilityItem>>(
		`/finances/${portCallId}/jobs/${jobCode}/services/${jobServiceId}/allowed-das?type=${daType}`
	);

const retrieveDAStatusHistoryById = ({
	portCallId,
	jobCode,
	daId
}: RetrieveDAStatusHistoryByIdRequest) =>
	client.get(
		`/finances/${portCallId}/jobs/${jobCode}/das/${daId}/status-history`
	);

const retrieveFinanceComments = ({ portCallId, jobCode }: FinanceRequest) =>
	client.get(`/finances/${portCallId}/jobs/${jobCode}/comments`);

const addFinanceComments = ({
	portCallId,
	jobCode,
	payload
}: AddFinanceComments) =>
	client.post(`/finances/${portCallId}/jobs/${jobCode}/comment`, payload);

const refreshBenchmark = ({ portCallId, jobCode }: RefreshBenchmarkRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/benchmark/refresh`);

const viewBenchmark = ({
	portCallId,
	jobCode,
	benchmarkId
}: ViewBenchmarkRequest) =>
	client.get<ViewBenchmarkResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/benchmark/view-benchmark/${benchmarkId}`
	);

const retrieveBenchmarkStatus = ({ portCallId, jobCode }: FinanceRequest) =>
	client.get(
		`/finances/${portCallId}/jobs/${jobCode}/benchmark/synchronization-status`
	);

const toggleFinance = ({
	portCallId,
	jobCode,
	isFinanceEnabled
}: ToggleFinanceRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/toggle-finance`, {
		isFinanceEnabled
	});

const RetrieveRefreshDirectBill = ({
	portCallId,
	jobCode,
	arrivalDate
}: RetrieveRefreshDirectBillRequest) =>
	client.get<RetrieveRefreshDirectBillResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/direct-bills`,
		{
			params: {
				arrivalDate
			}
		}
	);

const UpdateRefreshDirectBill = ({
	portCallId,
	jobCode,
	payload
}: UpdateRefreshDirectBillRequest) =>
	client.put<UpdateRefreshDirectBillResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/update-directbills`,
		payload
	);

const retrieveSupplierInvoiceImage = ({
	documentId,
	page
}: RetrieveSupplierInvoiceDocumentImageRequest) =>
	client.get<RetrieveSupplierInvoiceDocumentImageResponse>(
		`/finances/supplier-invoices/documents/${documentId}/pages/${page}`
	);

const restoreOriginalFinanceDocument = ({
	portCallId,
	jobCode,
	documentType,
	id
}: RestoreOriginalFinanceDocumentRequest) =>
	client.post(
		`/finances/${portCallId}/jobs/${jobCode}/documents/${documentType}/${id}/restoreOriginal`
	);

const submitUnitForm = ({
	portCallId,
	portJobCode,
	serviceId,
	payload
}: SubmitUnitCostFormRequest) =>
	client.post(
		`/finances/${portCallId}/jobs/${portJobCode}/services/${serviceId}/unitcostrate`,
		payload
	);

const updateUnitForm = ({
	portCallId,
	portJobCode,
	serviceId,
	unitCostRateId,
	payload
}: UpdateUnitCostFormPayload) =>
	client.put(
		`/finances/${portCallId}/jobs/${portJobCode}/services/${serviceId}/unitcostrate/${unitCostRateId}`,
		payload
	);

const deleteUnitForm = ({
	portCallId,
	portJobCode,
	serviceId,
	unitCostRateId
}: DeleteUnitCostFormRequest) =>
	client.delete(
		`/finances/${portCallId}/jobs/${portJobCode}/services/${serviceId}/unitcostrate/${unitCostRateId}`
	);

export default {
	addDA,
	addDAOut,
	addService,
	deleteDA,
	deleteDAOut,
	deleteService,
	getHasPointOfInterestAssociatedCosts,
	retrieveDAInCoverSheet,
	retrieveAvailableServices,
	retrieveDAOutCoverSheet,
	retrieveDAOutDocumentId,
	retrieveFinance,
	retrieveSDAReason,
	updateSDAReason,
	retrieveFinanceDetails,
	retrieveServiceQueries,
	retrieveServiceErrors,
	retrieveFinanceCurrencies,
	retrieveService,
	updateFinanceService,
	updateServiceStatus,
	updateBenchmark,
	convertService,
	retrieveJobFinanceRevisions,
	searchApprovalGroupsForAutocomplete,
	validateJobFinance,
	moveJobFinance,
	moveToStageJobFinance,
	revertJobStatus,
	approveAllServices,
	updateDA,
	retrieveFinanceServicesForContextualization,
	createFinanceDocuments,
	retrieveFinanceDocument,
	retrieveFinanceDocumentImage,
	deleteFinanceDocument,
	splitService,
	reverseSplitService,
	downloadFinanceDocument,
	pollDownloadFinanceDocument,
	downloadJobFinanceRevision,
	pollDownloadJobFinanceRevision,
	deleteDocumentPage,
	retrievePrincipalServices,
	downloadSingleDA,
	pollDownloadSingleDA,
	downloadAllDAs,
	pollDownloadAllDAs,
	convertToDirectBill,
	convertToRegular,
	cancelServiceTransaction,
	cancelCreditHubRevenue,
	updateFinanceStatus,
	reopenDaSet,
	retrieveAvailableDas,
	addCreditHubRevenueService,
	retrieveDAStatusHistoryById,
	retrieveFinanceComments,
	addFinanceComments,
	refreshBenchmark,
	viewBenchmark,
	retrieveBenchmarkStatus,
	moveJobFinanceWithoutReApproval,
	toggleFinance,
	RetrieveRefreshDirectBill,
	UpdateRefreshDirectBill,
	setFixedRate,
	moveService,
	retrieveAvailableSections,
	retrieveSupplierInvoiceImage,
	restoreOriginalFinanceDocument,
	deleteFinanceDocumentOriginalAndAnnotatedPages,
	submitUnitForm,
	updateUnitForm,
	deleteUnitForm
};
