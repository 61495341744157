import { Success } from 'typescript-fsa';
import { FundingRequestsState } from '../financeFundingsState';
import {
	RetrieveFinanceRequest,
	RetrieveFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import { keyBy } from 'lodash';

export const onRetrieveFundingRequests = (
	state: FundingRequestsState,
	{ result }: Success<RetrieveFinanceRequest, RetrieveFinanceResponse>
): FundingRequestsState => {
	const { fundingRequests } = result;
	if (!fundingRequests) {
		return state;
	}
	const byId = keyBy(fundingRequests.elements, 'id');
	const allIds = fundingRequests.elements.map(funding => funding.id);

	return {
		...state,
		byId,
		allIds,
		propertiesToView: fundingRequests.propertiesToView,
		metadata: fundingRequests.metadata || null
	};
};
