import { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
	setActiveSupplierInvoiceId,
	resetActiveSupplierInvoice,
	resetActiveSupplierInvoiceId
} from 'store/supplierInvoice/actions';
import {
	getDocumentIdFromActiveSupplierInvoice,
	getDocumentFromActiveSupplierInvoice,
	getIsDocumentUpdateLoading,
	getIsDocumentCreateLoading,
	getIsLoadingActiveSupplierInvoice,
	getActiveSupplierInvoice
} from 'store/supplierInvoice/selectors';

export function useActiveSupplierInvoice(
	invoiceId?: string,
	shouldSetActiveInvoice?: boolean
) {
	const dispatch = useDispatch();
	const documentId = useSelector(getDocumentIdFromActiveSupplierInvoice);
	const saving = useSelector(getIsDocumentUpdateLoading);
	const creating = useSelector(getIsDocumentCreateLoading);
	const loading = useSelector(getIsLoadingActiveSupplierInvoice);
	const activeSupplierInvoice = useSelector(getActiveSupplierInvoice);
	const document = useSelector(
		getDocumentFromActiveSupplierInvoice,
		shallowEqual
	);

	useEffect(() => {
		if (invoiceId && shouldSetActiveInvoice) {
			dispatch(setActiveSupplierInvoiceId(invoiceId));
		}
		if (!shouldSetActiveInvoice) {
			return;
		}
		return () => {
			dispatch(resetActiveSupplierInvoice());
			dispatch(resetActiveSupplierInvoiceId());
		};
	}, [invoiceId, shouldSetActiveInvoice, dispatch]);

	return {
		activeSupplierInvoice,
		documentId,
		document,
		loading,
		saving,
		creating
	};
}
