import { all, fork } from 'redux-saga/effects';
import addThreadMessageSaga from './addThreadMessageSaga';
import retrieveThreadByIdSaga from './retrieveThreadByIdSaga';
import retrieveThreadMessageByIdSaga from './retrieveThreadMessageByIdSaga';
import updateContextSaga from './updateContextSaga';
import updateTagsWatcher from './updateTagsSaga';
import assignJobToThreadSaga from './assignJobToThreadSaga';
import unassignJobFromThreadSaga from 'store/thread/sagas/unassignJobFromThreadSaga';
import retrieveActiveThreadSaga from 'store/thread/sagas/retrieveActiveThreadSaga';
import retrieveMailboxAvailableGroupsSaga from 'store/thread/sagas/retrieveMailboxAvailableGroupsSaga';
import retrieveThreadByIdCycleSaga from './reactingOnChanges/retrieveThreadByIdCycleSaga';
import retrieveThreadEventsSaga from './retrieveThreadEventsSaga';
import errorHandlingThreadByIdWatcher from './reactingOnChanges/errorHandlingThreadById';
import errorHandlingThreadActionsWatcher from './reactingOnChanges/errorHandlingThreadActions';
import patchThreadPermissionsSaga from 'store/thread/sagas/patchThreadPermissionsSaga';
import updateThreadMessageReadWatcher from 'store/thread/sagas/updateThreadMessageReadSaga';
// Available Groups Sagas
import addThreadPermissionsSaga from './availableGroups/addThreadPermissionsSaga';
import retrieveUsersFromGroupSaga from 'store/thread/sagas/availableGroups/retrieveUsersFromGroupSaga';
import retrieveAvailableThreadGroupsSaga from 'store/thread/sagas/availableGroups/retrieveAvailableThreadGroupsSaga';
import retrieveMessageDataWatcher from 'store/thread/sagas/retrieveMessageDataSaga';
import updateThreadStatusWatcher from 'store/thread/sagas/updateThreadStatusSaga';
import quickJobAssignToThreadSaga from './quickJobAssignToThreadSaga';

export default function*() {
	yield all([
		fork(addThreadMessageSaga),
		fork(assignJobToThreadSaga),
		fork(errorHandlingThreadActionsWatcher),
		fork(errorHandlingThreadByIdWatcher),
		fork(retrieveActiveThreadSaga),
		fork(retrieveMailboxAvailableGroupsSaga),
		fork(retrieveThreadByIdCycleSaga),
		fork(retrieveThreadByIdSaga),
		fork(retrieveThreadEventsSaga),
		fork(retrieveThreadMessageByIdSaga),
		fork(unassignJobFromThreadSaga),
		fork(updateContextSaga),
		fork(updateTagsWatcher),
		fork(patchThreadPermissionsSaga),
		fork(retrieveMessageDataWatcher),
		fork(updateThreadStatusWatcher),
		// Available Groups Sagas
		fork(addThreadPermissionsSaga),
		fork(retrieveUsersFromGroupSaga),
		fork(retrieveAvailableThreadGroupsSaga),
		fork(updateThreadMessageReadWatcher),
		fork(quickJobAssignToThreadSaga)
	]);
}
