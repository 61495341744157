import * as React from 'react';
import { InfiniteTable, ScrollableLayout } from 'components';
import cn from 'classnames';
import { connect } from 'react-redux';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import getSupplierInvoiceColumns, {
	SupplierInvoiceColumn
} from './supplierInvoiceColumns';
import { AppState } from 'store-types';
import {
	getSupplierInvoicesSelector,
	getIsFetchingSupplierInvoices,
	getHasInvoiceSplitRequest
} from 'store/supplierInvoice/selectors';
import { SupplierInvoiceRow } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import {
	retrieveSupplierInvoices,
	resetSupplierInvoices,
	retrieveSupplierInvoicesCycleStart,
	retrieveSupplierInvoicesCycleStop
} from 'store/supplierInvoice/actions';
import { retrieveFinanceCurrencies } from 'store/finance/actions';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';
import styles from './SupplierInvoiceTable.module.scss';
import { AppNotifications } from 'containers';

interface SupplierInvoiceTableProps {
	dataSource: SupplierInvoiceRow[];
	columns: SupplierInvoiceColumn[];
	isLoading: boolean;
	hasMore: boolean;
	// from mapDispatchToProps
	retrieveSupplierInvoices: typeof retrieveSupplierInvoices;
	resetSupplierInvoices: typeof resetSupplierInvoices;
	retrieveSupplierInvoicesCycleStart: typeof retrieveSupplierInvoicesCycleStart;
	retrieveSupplierInvoicesCycleStop: typeof retrieveSupplierInvoicesCycleStop;
	retrieveFinanceCurrencies: typeof retrieveFinanceCurrencies;
}

export const SUPPLIER_INVOICES_TABLE_ID = 'SUPPLIER_INVOICES_TABLE_ID';

class SupplierInvoiceTable extends React.Component<SupplierInvoiceTableProps> {
	private retrieveSupplierInvoices = () => {
		this.props.retrieveSupplierInvoices({ limit: DEFAULT_LIST_LIMIT });
	};

	componentDidMount() {
		this.props.retrieveSupplierInvoicesCycleStart();
		this.props.retrieveFinanceCurrencies();
	}

	componentWillUnmount() {
		this.props.retrieveSupplierInvoicesCycleStop();
		this.props.resetSupplierInvoices();
	}

	onRow = (record: SupplierInvoiceRow) => ({
		onClick: () => navigateTo(`/${PATHS.supplierinvoices}/${record.id}/`)
	});

	getRowClass = (invoice: SupplierInvoiceRow) =>
		cn({
			[styles.rowNoBorderBottom]: getHasInvoiceSplitRequest(invoice),
			[styles.creditRow]: invoice.isCredit
		});

	getRowKey = (record: SupplierInvoiceRow) =>
		`${record.id}-${Number(!!record.split)}`;

	public render() {
		return (
			<ScrollableLayout stretch id={SUPPLIER_INVOICES_TABLE_ID}>
				<AppNotifications.Table
					entityName="Invoices"
					actionType={retrieveSupplierInvoices.type}
				>
					{({ failedLoading }) => (
						<InfiniteTable
							onRow={this.onRow}
							dataSource={this.props.dataSource}
							columns={this.props.columns}
							rowKey={this.getRowKey}
							normalizeColumns={true}
							lazyLoad={{
								loading: this.props.isLoading,
								onEnter: this.retrieveSupplierInvoices,
								hasMore: this.props.hasMore,
								doneLoading: 'No more invoices to show',
								failedLoading
							}}
							locale={{ emptyText: 'There are no invoices to show' }}
							rowClassName={this.getRowClass}
						/>
					)}
				</AppNotifications.Table>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => {
		const supplierInvoices = getSupplierInvoicesSelector(state);
		return {
			columns: getSupplierInvoiceColumns(supplierInvoices),
			dataSource: supplierInvoices,
			isLoading: getIsFetchingSupplierInvoices(state),
			hasMore: state.supplierInvoice.hasMore
		};
	},
	{
		retrieveSupplierInvoices,
		resetSupplierInvoices,
		retrieveSupplierInvoicesCycleStart,
		retrieveSupplierInvoicesCycleStop,
		retrieveFinanceCurrencies
	}
)(SupplierInvoiceTable);
