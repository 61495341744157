import { Config } from './createWizardForm';
import createWizardFormStep from './createWizardFormStep';

export function injectAdditionalPropsForPages(initialConfig: Config) {
	return (initialConfig.pages || []).map((page, key) => {
		return {
			...page,
			component: createWizardFormStep({
				mode: initialConfig.mode,
				name: initialConfig.name,
				page: key + 1
			})(page.component)
		};
	});
}
