import { retrievePreRegistrationAgentCompanyAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse
} from 'services/api/registration/registrationServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse,
	Error
>({
	api: Api.Registration.retrievePreRegistrationAgentCompany,
	async
});
