import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
	reduxForm,
	reset,
	InjectedFormProps,
	initialize,
	getFormMeta
} from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { Row, Col, Modal } from 'components/antd';
import { retrieveOperations } from 'store/operations';
import Operations from '../Operations/Operations';
import {
	getOperations,
	getOperationTypeCodeById
} from 'store/operations/operationsSelectors';
import { getValues, getForm } from 'store/form/selectors';

import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import validate from './validate';
import warn from './warn';
import {
	FORM,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	FormData,
	OperationType
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { VesselCondition } from 'services/api/portJobs/portJobsServiceTypes';
import { setPortCallContext } from 'store/portcalls';
import {
	getCurrentPortJobOperations,
	getCurrentMainPrincipalId,
	getPortIdSummary,
	getEditableOperation,
	getOperationInitialValues
} from 'store/portJobs/selectors';
import { CanalTransitFormFieldName } from '../Operations/CanalTransit/CanalTransit';
import { shouldShowChangeOperationWarning } from './Form.func';
import AddCompanyDraftModal from 'containers/Drafts/company/AddCompanyDraftModal';
import CancelProcessModal from '../Operations/CancelProcessModal';

import OperationsConfig from '../Operations/OperationsConfig';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import { searchEntitiesForAutocomplete } from 'containers/Autocomplete/Autocomplete.func';
import { AppState } from 'store-types';
import Api from 'services/api';

const { OPERATION_TYPE } = FormFieldName;

export type PageProps = ReturnType<typeof mapStateToProps> & {
	onFormStateChange: (valid: boolean) => void;
	operationId?: string;
	isInEditMode?: boolean;
	// from mapDispatchToProps
	retrieveOperations: typeof retrieveOperations;
	resetForm: typeof reset;
	initializeForm: typeof initialize;
	setPortCallContext: typeof setPortCallContext;
};

export interface PageFormProps
	extends InjectedFormProps<FormData, PageProps>,
		PageProps {}

interface PageFormState {
	showChangeOperationsModal: boolean;
	nextOperationValue?: OperationType;
}

class Page extends React.Component<PageFormProps, PageFormState> {
	constructor(props: PageFormProps) {
		super(props);
		this.state = {
			showChangeOperationsModal: false
		};
	}

	componentDidMount() {
		const portId = this.getPortId();
		const mainPrincipalId = this.getMainPrincipalId();
		if (portId) {
			const params = this.props.isInEditMode
				? { portId }
				: { portId, mainPrincipalId };
			this.props.retrieveOperations(params);
			this.props.setPortCallContext({ activePortCallPortId: portId });
		}
	}

	componentDidUpdate(prevProps: PageFormProps) {
		const {
			portIdSummary,
			isInEditMode,
			form,
			initialValues,
			operations,
			valid,
			operationTypeCode
		} = this.props;
		if (!prevProps.portIdSummary && portIdSummary) {
			this.retrieveOperations();
		}
		if (
			isInEditMode &&
			(!prevProps.operations || !prevProps.operations.length) &&
			operations?.length
		) {
			this.props.initializeForm(form, initialValues);
		}
		this.props.onFormStateChange(valid);
		// Move to CanalTransit component
		if (
			!prevProps.isInEditMode &&
			operationTypeCode === OperationTypeCode.CANAL_TRANSIT &&
			prevProps.operationTypeCode !== OperationTypeCode.CANAL_TRANSIT
		) {
			this.props.change(
				CanalTransitFormFieldName.VESSEL_CONDITION,
				VesselCondition.LADEN
			);
		}
	}

	retrieveOperations = () => {
		this.props.retrieveOperations({
			portId: this.getPortId(),
			mainPrincipalId: this.getMainPrincipalId()
		});
	};

	resetOperation() {
		const { operationTypeCode } = this.props;
		const operationConfig =
			operationTypeCode && OperationsConfig[operationTypeCode];

		if (operationConfig?.resetOperation) {
			operationConfig.resetOperation(
				this.props.change,
				this.props.resetForm,
				this.props.untouch
			);
		}
	}

	getPortId() {
		if (this.props.formValues.portCall) {
			return this.props.formValues.portCall.port.key;
		}
		if (this.props.portIdSummary) {
			return this.props.portIdSummary;
		}
		return;
	}

	getMainPrincipalId() {
		if (this.props.formValues.hubPrincipalCompany) {
			return this.props.formValues.hubPrincipalCompany.key;
		}
		if (this.props.currentMainPrincipalId) {
			return this.props.currentMainPrincipalId;
		}
		return;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onOperationChange = (value: any) => {
		const shouldWarn = shouldShowChangeOperationWarning(this.props);
		if (shouldWarn) {
			this.setState({
				nextOperationValue: value,
				showChangeOperationsModal: true
			});
		}
		// prevent default
		return shouldWarn;
	};

	onConfirmOperationChange = () => {
		if (!this.state.nextOperationValue) {
			return;
		}
		this.resetOperation();

		this.props.change(OPERATION_TYPE, this.state.nextOperationValue);
		this.setState({
			showChangeOperationsModal: false
		});
	};

	hideChangeOperationsModal = () => {
		this.setState({
			showChangeOperationsModal: false
		});
	};

	onOperationSearch = (searchTerm?: string) =>
		searchEntitiesForAutocomplete(() =>
			Api.Operations.retrieveOperationsForAutocomplete({
				portId: this.getPortId(),
				mainPrincipalId: this.getMainPrincipalId(),
				searchTerm
			})
		);

	render() {
		const { currentJobOperations, operationTypeCode } = this.props;
		const selectedBeforeOperationIds = currentJobOperations.map(op => op.id);
		return (
			<div>
				<Row>
					<Col xs={12} sm={3}>
						<AutocompleteEntity
							name={OPERATION_TYPE}
							disabled={this.props.isInEditMode}
							disabledKeys={selectedBeforeOperationIds}
							onChange={this.onOperationChange}
							onSearch={this.onOperationSearch}
							shouldSearchAllOptionsOnFocus
							minLength={1}
							label="Operation"
							required
						/>
					</Col>
				</Row>
				{operationTypeCode ? (
					<>
						<Operations operationTypeCode={operationTypeCode} />
						<CancelProcessModal operationTypeCode={operationTypeCode} />
					</>
				) : null}
				<AddCompanyDraftModal />
				<Modal
					visible={this.state.showChangeOperationsModal}
					onCancel={this.hideChangeOperationsModal}
					onOk={this.onConfirmOperationChange}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>
						Selecting another operation will result in all operation data
						already entered to be lost. Are you sure you want to proceed?
					</p>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (
	state: AppState,
	ownProps: { isInEditMode?: boolean }
) => {
	const formValues = getValues<FormData>(state, FORM.portJob);
	return {
		formValues,
		operations: getOperations(state),
		initialValues: ownProps.isInEditMode
			? getOperationInitialValues(state)
			: {},
		editableOperation: getEditableOperation(state),
		currentJobOperations: getCurrentPortJobOperations(state),
		currentMainPrincipalId: getCurrentMainPrincipalId(state),
		cargoLineForm: getForm(state, FORM.cargoLine),
		areCharterersTouched: getFormMeta(FORM.portJob)(state)?.[
			FormFieldName.CHARTERERS
		]?.touched,
		portIdSummary: getPortIdSummary(state),
		operationTypeCode: getOperationTypeCodeById(
			state,
			get(formValues.operationType, 'key')
		)
	};
};

export default connect(mapStateToProps, {
	retrieveOperations,
	resetForm: reset,
	initializeForm: initialize,
	setPortCallContext
})(
	reduxForm<FormData, PageProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		forceUnregisterOnUnmount: true,
		validate,
		warn
	})(Page)
);
