import { SagaIterator } from 'redux-saga';
import {
	fork,
	cancel,
	put,
	take,
	select,
	takeEvery,
	race,
	delay
} from 'redux-saga/effects';
import {
	retrievePortCallShipMeasurements,
	retrievePortCallCargoMeasurements,
	retrievePortCallBunkeringMeasurements,
	retrievePortCallMeasurementsAccess,
	retrievePortCallMeasurementsAccessAsync
} from '../actions';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	startPortCallMeasurements,
	stopPortCallMeasurements
} from '../actions/retrievePortCallMeasurments';
import { getPortCallMeasurementsAccess } from '../selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { MeasurementsAccess } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const measurmentsDataRetrievalCycle = function*(): SagaIterator {
	while (true) {
		const portCallId = yield select(getActivePortCallId);
		const access: MeasurementsAccess[] = yield select(
			getPortCallMeasurementsAccess,
			portCallId
		);
		// fetch only tabs available for the user
		if (access.includes(MeasurementsAccess.SHIP)) {
			yield put(retrievePortCallShipMeasurements({ portCallId }));
		}
		if (access.includes(MeasurementsAccess.CARGO)) {
			yield put(retrievePortCallCargoMeasurements({ portCallId }));
		}
		if (access.includes(MeasurementsAccess.BUNKERING)) {
			yield put(retrievePortCallBunkeringMeasurements({ portCallId }));
		}
		yield delay(DEFAULT_POLLING_INTERVAL);
	}
};

const portCallMeasurmentsDataFlow = function*(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	yield put(retrievePortCallMeasurementsAccess({ portCallId }));
	const { failed } = yield race({
		done: take(retrievePortCallMeasurementsAccessAsync.done),
		failed: take(retrievePortCallMeasurementsAccessAsync.failed)
	});
	if (failed) {
		return;
	}
	// start of the cycle
	const task = yield fork(measurmentsDataRetrievalCycle);
	yield take(stopPortCallMeasurements.type);
	yield cancel(task);
};

export default function* portCallMeasurments() {
	yield takeEvery(startPortCallMeasurements.type, portCallMeasurmentsDataFlow);
}
