import retrieveCompanyVesselsWatcher from './retrieveCompanyVesselsSaga';
import retrieveCompanyVesselByIdWatcher from './retrieveCompanyVesselByIdSaga';
import retrieveCompanyVesselWatcher from './retrieveCompanyVesselSaga';
import { all, fork } from 'redux-saga/effects';
import createCompanyVesselsWatcher from './createCompanyVesselsSaga';
import updateCompanyVesselsWatcher from './updateCompanyVesselsSaga';
import deleteCompanyVesselsWatcher from './deleteCompanyVesselsSaga';
import retrieveEditCompanyVesselDataWatcher from './retrieveEditCompanyVesselDataSaga';
import retrieveEditCompanyVesselByIdDataWatcher from './retrieveEditCompanyVesselByIdDataSaga';
import retrievePortCallCompanyVesselsWatcher from './retrievePortCallCompanyVesselsSaga';

export default function*() {
	yield all([
		fork(retrieveCompanyVesselsWatcher),
		fork(retrieveEditCompanyVesselDataWatcher),
		fork(retrieveCompanyVesselWatcher),
		fork(createCompanyVesselsWatcher),
		fork(deleteCompanyVesselsWatcher),
		fork(retrievePortCallCompanyVesselsWatcher),
		fork(updateCompanyVesselsWatcher),
		fork(retrieveEditCompanyVesselByIdDataWatcher),
		fork(retrieveCompanyVesselByIdWatcher)
	]);
}
