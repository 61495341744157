import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { deletePortJobAsync } from '../portJobs/actions/deletePortJob';
import {
	setActivePortCallId,
	resetActivePortCallId,
	retrievePortCallsAsync,
	retrievePortCallsDuplicatesAsync,
	retrievePortCallDuplicatesAsync,
	retrievePortCallAsync,
	retrievePortCallsStaticAsync,
	addPortCallAsync,
	setActivePortCallTab,
	resetActivePortCallTab,
	resetPortCalls,
	resetPortCallsDuplicates,
	resetPortCallDuplicates,
	setPortCallContext,
	resetPortCall,
	retrievePortCallsSettingsAsync,
	retrievePortCallsAlertsAsync,
	mergePortCallsAsync
} from './actions';
import {
	onDeletePortCallJob,
	onRetrievePortCallsStaticStarted,
	onRetrievePortCallsStaticSuccess,
	onRetrievePortCallsStaticFailed,
	onRetrievePortCallStarted,
	onRetrievePortCallFailed,
	onSetActivePortCallId,
	onRetrievePortCallSuccess,
	onResetActivePortCallId,
	onAddPortCallStarted,
	onAddPortCallSuccess,
	onAddPortCallFailed,
	onSetActivePortCallTab,
	onResetActivePortCallTab,
	onResetPortCalls,
	onResetPortCallsDuplicates,
	onResetPortCallDuplicates,
	onSetPortCallContext,
	onRetrievePortCalls,
	onRetrievePortCallsDuplicates,
	onRetrievePortCallDuplicates,
	onRetrievePortCallsSettings,
	onResetPortCall,
	onRetrievePortCallsAlerts,
	onMergePortCalls
} from './reducers';
import { initialState } from './portCallsState';

export default reducerWithInitialState(initialState)
	.case(resetPortCalls, onResetPortCalls)
	.case(resetPortCallsDuplicates, onResetPortCallsDuplicates)
	.case(resetPortCallDuplicates, onResetPortCallDuplicates)
	// All Port Calls
	.case(retrievePortCallsAsync.started, onRetrievePortCalls.started)
	.case(retrievePortCallsAsync.failed, onRetrievePortCalls.failed)
	.case(retrievePortCallsAsync.done, onRetrievePortCalls.done)
	// Port Calls duplicates
	.case(
		retrievePortCallsDuplicatesAsync.started,
		onRetrievePortCallsDuplicates.started
	)
	.case(
		retrievePortCallsDuplicatesAsync.failed,
		onRetrievePortCallsDuplicates.failed
	)
	.case(
		retrievePortCallsDuplicatesAsync.done,
		onRetrievePortCallsDuplicates.done
	)
	// Port Call duplicates
	.case(
		retrievePortCallDuplicatesAsync.started,
		onRetrievePortCallDuplicates.started
	)
	.case(
		retrievePortCallDuplicatesAsync.failed,
		onRetrievePortCallDuplicates.failed
	)
	.case(retrievePortCallDuplicatesAsync.done, onRetrievePortCallDuplicates.done)
	// Merge Port Calls
	.case(mergePortCallsAsync.started, onMergePortCalls.started)
	.case(mergePortCallsAsync.failed, onMergePortCalls.failed)
	.case(mergePortCallsAsync.done, onMergePortCalls.done)
	// Alerts
	.case(retrievePortCallsAlertsAsync.started, onRetrievePortCallsAlerts.started)
	.case(retrievePortCallsAlertsAsync.failed, onRetrievePortCallsAlerts.failed)
	.case(retrievePortCallsAlertsAsync.done, onRetrievePortCallsAlerts.done)
	// Port call static data
	.case(retrievePortCallsStaticAsync.started, onRetrievePortCallsStaticStarted)
	.case(retrievePortCallsStaticAsync.done, onRetrievePortCallsStaticSuccess)
	.case(retrievePortCallsStaticAsync.failed, onRetrievePortCallsStaticFailed)
	// Port Call settings
	.case(
		retrievePortCallsSettingsAsync.started,
		onRetrievePortCallsSettings.started
	)
	.case(retrievePortCallsSettingsAsync.done, onRetrievePortCallsSettings.done)
	.case(
		retrievePortCallsSettingsAsync.failed,
		onRetrievePortCallsSettings.failed
	)
	// Single Port Call
	.case(retrievePortCallAsync.started, onRetrievePortCallStarted)
	.case(retrievePortCallAsync.failed, onRetrievePortCallFailed)
	.case(retrievePortCallAsync.done, onRetrievePortCallSuccess)
	.case(resetPortCall, onResetPortCall)
	// Set Active PortCall
	.case(setActivePortCallId, onSetActivePortCallId)
	.case(resetActivePortCallId, onResetActivePortCallId)
	// Set Active PortCall Tab
	.case(setActivePortCallTab, onSetActivePortCallTab)
	.case(resetActivePortCallTab, onResetActivePortCallTab)
	// Add Port Call
	.case(addPortCallAsync.started, onAddPortCallStarted)
	.case(addPortCallAsync.failed, onAddPortCallFailed)
	.case(addPortCallAsync.done, onAddPortCallSuccess)
	// Set port call context
	.case(setPortCallContext, onSetPortCallContext)
	// Delete port call job
	.case(deletePortJobAsync.done, onDeletePortCallJob);
