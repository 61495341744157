import actionCreator from '../portCallsActionCreator';
import { RetrievePortCallsStaticResponse } from '../portCallsState';

const RETRIEVE_STATIC = 'RETRIEVE_STATIC';

export const retrievePortCallsStatic = actionCreator(RETRIEVE_STATIC);

export const retrievePortCallsStaticAsync = actionCreator.async<
	undefined,
	RetrievePortCallsStaticResponse,
	Error
>(RETRIEVE_STATIC);
