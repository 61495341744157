import { FetchStatus } from 'services/api/apiTypes';
import {
	DeveloperPortalData,
	ApiGroupPermissionList
} from 'services/api/developerPortal/developerPortalServiceTypes';

export interface DeveloperPortalState {
	readonly context: {
		initialDeveloperPortal: DeveloperPortalData | null;
		editingWebHookId: string;
		selectedOutBoundApi: string[];
		outboundApiList: ApiGroupPermissionList[];
	};
	fetchStatuses: {
		retrieveDeveloperPortal: FetchStatus;
		createNewApiSubscription: FetchStatus;
		updateApiSubscription: FetchStatus;
		downloadApiSubscription: FetchStatus;
	};
}

export const initialState: DeveloperPortalState = {
	context: {
		initialDeveloperPortal: null,
		editingWebHookId: '',
		selectedOutBoundApi: [],
		outboundApiList: []
	},
	fetchStatuses: {
		retrieveDeveloperPortal: FetchStatus.IDLE,
		createNewApiSubscription: FetchStatus.IDLE,
		updateApiSubscription: FetchStatus.IDLE,
		downloadApiSubscription: FetchStatus.IDLE
	}
};
