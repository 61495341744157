import React from 'react';
import { Table } from 'components/antd';
import styles from './CostSavingsGrid.module.scss';
import { useSelector } from 'react-redux';
import { getSavingsTableData } from 'store/savings/savingsSelectors';
import { getColumns } from './CostSavingGridColumns';
import { getActivePortJobStatus } from 'store/portJobs/portJobsSelectors';
import { PortJobStatus } from 'store/portJobs/constants';
import { UserType } from 'services/api/users/userServiceTypes';

interface GridProps {
	userType: UserType;
}

function CostSavingsGridTable({ userType }: GridProps) {
	const savingsData = useSelector(getSavingsTableData);
	const jobStatus = useSelector(getActivePortJobStatus);
	const isClosedJob = jobStatus === PortJobStatus.CLOSED;
	return (
		<Table
			className={styles.table}
			pagination={false}
			dataSource={savingsData}
			columns={getColumns(userType, isClosedJob)}
			bordered={false}
			locale={{ emptyText: 'No Savings information available' }}
			showHeader
		/>
	);
}

export default CostSavingsGridTable;
