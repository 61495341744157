import * as React from 'react';
import { Icon, Tooltip } from 'components/antd';

interface MultipleEntitiesProps {
	entities: string[];
}

const MultipleEntities: React.FC<MultipleEntitiesProps> = ({ entities }) => {
	return (
		<>
			Multiple&nbsp;
			<Tooltip placement="bottom" title={entities.join(', ')}>
				<Icon type="info" color="primary" />
			</Tooltip>
		</>
	);
};

export default MultipleEntities;
