import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Flex, Text } from 'components';
import { Justify } from 'components/types';
import { DataFetcher } from 'utils/components';

import { retrieveDashboardTableauInfo } from 'store/dashboard/actions';
import {
	getDashboardTableauInfo,
	getDashboardTableauInfoFetchStatus
} from 'store/dashboard/selectors';

import { DashboardTableauInfo } from 'services/api/dashboard/dashboardServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

interface TableauInfoProps {
	companyId: string;
	tableauCode: string;
	fetchStatus: FetchStatus;
	tableauInfo: DashboardTableauInfo;
	retrieveTableauInfo: typeof retrieveDashboardTableauInfo;
}
const TableauInfo = ({
	companyId,
	tableauCode,
	fetchStatus,
	tableauInfo,
	retrieveTableauInfo
}: TableauInfoProps) => {
	const [loadIframe, setLoadIframe] = useState(false);

	useEffect(() => {
		// For some reason, error is thrown `Permission denied`
		// when loading iframe for the second time and more
		// Error disappears when loading an iframe after a `slight` queue
		setTimeout(() => {
			setLoadIframe(true);
		});
	}, []);

	return (
		<DataFetcher
			fetchStatus={fetchStatus}
			dispatch={() =>
				retrieveTableauInfo({ mainPrincipalId: companyId, tableauCode })
			}
			data={tableauInfo}
			error={() => (
				<Flex fit align="center" justify={Justify.CENTER}>
					<Text color="default">Report Server failed to authenticate you</Text>
				</Flex>
			)}
		>
			{() => {
				if (!loadIframe || !tableauInfo) {
					return null;
				}
				return <iframe title="dashboard tableau info" src={tableauInfo.url} />;
			}}
		</DataFetcher>
	);
};

export default connect(
	(
		state: AppState,
		{ tableauCode }: Pick<TableauInfoProps, 'tableauCode'>
	) => ({
		fetchStatus: getDashboardTableauInfoFetchStatus(state, tableauCode),
		tableauInfo: getDashboardTableauInfo(state, tableauCode)
	}),
	{
		retrieveTableauInfo: retrieveDashboardTableauInfo
	}
)(TableauInfo);
