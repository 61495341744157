import { createSelector } from 'reselect';
import { difference } from 'lodash';
import { AppState } from 'store-types';
import {
	PermissionCode,
	Permission
} from 'services/api/permissions/permissionsServiceTypes';
import {
	GroupAccessType,
	UserAuthGroup
} from 'services/api/users/userServiceTypes';
import { getCurrentUser } from './user/userSelectors';
import { createDeepEqualSelector } from 'store/selectors';

const getAuthPermissions = (state: AppState) => state.auth.authPermissions;
const getActingPermissions = (state: AppState) => state.auth.actingPermissions;

const getCurrentPermissions = createSelector(
	getAuthPermissions,
	getActingPermissions,
	(authPermissions, actingPermissions) =>
		actingPermissions.length ? actingPermissions : authPermissions
);

/**
 * Created for re-usage among auth/acting permissions
 */
const makeGetPermissionCodes = (
	permissionsSelector: (state: AppState) => Permission[]
) =>
	createSelector(permissionsSelector, (permissions): PermissionCode[] =>
		permissions.map(permission => permission.code)
	);

/**
 * Created for re-usage among auth/acting permissions
 */
const makeGetHasPermission = (
	permissionsSelector: (
		state: AppState,
		permissionCode: PermissionCode
	) => PermissionCode[]
) =>
	createSelector(
		permissionsSelector,
		(_state: AppState, code: PermissionCode) => code,
		(permCodes, code) => permCodes.includes(code)
	);

// Current Permissions
// ------------------
export const getPermissionCodes = makeGetPermissionCodes(getCurrentPermissions);
export const getHasPermission = makeGetHasPermission(getPermissionCodes);

export const getPermissionCodesByCompanyId = createSelector(
	getCurrentUser,
	(_: AppState, companyId: string | null) => companyId,
	(currentUser, companyId) => {
		if (!companyId) {
			return null;
		}
		return getPermissionCodesByCompanyIdHelper(currentUser.groups, companyId);
	}
);

export const makeGetHasPermissionTable = () =>
	createDeepEqualSelector(
		getCurrentUser,
		getPermissionCodes,
		(_state: AppState, codes: PermissionCode[]) => codes,
		(_state: AppState, _, companyId: string | null) => companyId,
		(currentUser, allCodes, codes, companyId) => {
			const permissionCodes =
				currentUser && companyId
					? getPermissionCodesByCompanyIdHelper(currentUser.groups, companyId)
					: allCodes;
			return codes.map(code => permissionCodes.includes(code));
		}
	);

export const getHasPermissionTable = makeGetHasPermissionTable();

function getPermissionCodesByCompanyIdHelper(
	groups: UserAuthGroup[],
	companyId: string
) {
	return groups.reduce<PermissionCode[]>((acc, group) => {
		const {
			functionalPermissions,
			mainPrincipals: { accessType, permissions }
		} = group;

		const companyGroup = permissions.some(
			company => company.entityId === companyId
		);
		let codes: PermissionCode[] = [];

		if (
			accessType === GroupAccessType.ALL ||
			(accessType === GroupAccessType.SPECIFIC && companyGroup)
		) {
			codes = functionalPermissions.map(permission => permission.code);
		}

		const diff = difference(codes, acc);
		acc.push(...diff);
		return acc;
	}, []);
}

// Auth Permissions
// ------------------
const getAuthPermissionCodes = makeGetPermissionCodes(getAuthPermissions);
export const getAuthHasPermission = makeGetHasPermission(
	getAuthPermissionCodes
);
