import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { makePollDownloadExecutor } from 'utils/sagaHelpers/pollDownloadExecutor';
import { DownloadAllDAsRequest } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import { downloadAllDAsAsync as async } from '../actions';

const POSTApi = Api.Finance.downloadAllDAs;
const GETApi = Api.Finance.pollDownloadAllDAs;

const downloadExecutor = makePollDownloadExecutor<
	DownloadAllDAsRequest,
	RetrieveDownloadDocumentResponse,
	Error
>({
	async,
	POSTApi,
	GETApi
});

export default makeTakeLatestWatcher<
	DownloadAllDAsRequest,
	RetrieveDownloadDocumentResponse,
	Error
>({ async }, downloadExecutor);
