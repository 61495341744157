import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Button } from 'components/antd';
import { PageHeader, ScrollableLayout, Flex } from 'components';
import BankAccountsActAs from './BankAccountsActAs/BankAccountsActAs';
import { Justify } from 'components/types';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import {
	retrieveBankAccountsSection,
	resetBankAccounts
} from 'store/masterdata/bankAccounts/actions';
import {
	getBankAccountsFetchStatus,
	getSortedBankAccounts
} from 'store/masterdata/bankAccounts/selectors';
import { DataFetcher } from 'utils/components';
import { FetchStatus } from 'services/api/apiTypes';
import bankAccountColumns from './BankAccountColumns';
import { navigateTo } from 'utils';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { url, breadCrumbs } from './BankAccountConstants';
import { getUserCompanyId } from 'store/auth/selectors';
import { AppState } from 'store-types';
import { AppNotifications } from 'containers';

interface BankAccountsProps {
	// mapStateToProps
	bankAccounts: BankAccount[];
	fetchStatus: FetchStatus;
	companyId: string;
	// mapDispatchToProps
	retrieve: typeof retrieveBankAccountsSection;
	resetBankAccounts: typeof resetBankAccounts;
}

class BankAccountsRoute extends React.Component<BankAccountsProps> {
	componentWillUnmount() {
		this.props.resetBankAccounts();
	}

	render() {
		const { retrieve, fetchStatus, bankAccounts, companyId } = this.props;

		return (
			<BankAccountsActAs>
				<ScrollableLayout>
					<AppNotifications.Notification />
					<RouteBreadcrumbs items={breadCrumbs.main} />
					<PageHeader shadowed stretch>
						<Flex justify={Justify.END}>
							<Button type="primary" onClick={() => navigateTo(url.add)}>
								Add Bank details
							</Button>
						</Flex>
					</PageHeader>
					<ScrollableLayout>
						<DataFetcher
							dispatch={() => retrieve({ companyId })}
							fetchStatus={fetchStatus}
						>
							{() => (
								<Table dataSource={bankAccounts} columns={bankAccountColumns} />
							)}
						</DataFetcher>
					</ScrollableLayout>
				</ScrollableLayout>
			</BankAccountsActAs>
		);
	}
}

export default connect(
	(state: AppState) => ({
		bankAccounts: getSortedBankAccounts(state),
		fetchStatus: getBankAccountsFetchStatus(state),
		companyId: getUserCompanyId(state)
	}),
	{
		retrieve: retrieveBankAccountsSection,
		resetBankAccounts
	}
)(BankAccountsRoute);
