import { UpdateSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../supplierInvoiceActionCreator';

const ACTION_NAME = 'UPDATE_SUPPLIER_INVOICE';
export const updateSupplierInvoiceAsync = actionCreator.async<
	UpdateSupplierInvoiceRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Supplier Invoice'
	}
});

export const updateSupplierInvoice = actionCreator<{
	supplierInvoiceId: string;
	documentId: string;
}>(ACTION_NAME);
