import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveVesselAsync,
	retrieveVesselSectionAsync,
	retrieveVesselExtendAsync
} from './actions';
import { onRetrieveExtendVessel, onRetrieveVessel } from './reducers';
import { initialState } from './vesselsState';

export default reducerWithInitialState(initialState)
	.cases(
		[retrieveVesselAsync.started, retrieveVesselSectionAsync.started],
		onRetrieveVessel.started
	)
	.cases(
		[retrieveVesselAsync.done, retrieveVesselSectionAsync.done],
		onRetrieveVessel.done
	)
	.cases(
		[retrieveVesselAsync.failed, retrieveVesselSectionAsync.failed],
		onRetrieveVessel.failed
	)
	.cases([retrieveVesselExtendAsync.started], onRetrieveExtendVessel.started)
	.cases([retrieveVesselExtendAsync.done], onRetrieveExtendVessel.done)
	.cases([retrieveVesselExtendAsync.failed], onRetrieveExtendVessel.failed);
