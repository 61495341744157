import React from 'react';
import { connect } from 'react-redux';
import { Fit, Loading } from 'components';
import { DataFetcher } from 'utils/components';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import JobDetailsTab, {
	PortJobTab
} from 'sections/PortCall/PortJob/JobDetailsTabs/JobDetailsTabs';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';
import SavingsContent from 'sections/PortCall/Savings/SavingsContent';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import {
	getActivePortJobCode,
	getActivePortJob
} from 'store/portJobs/selectors';
import {
	getActivePortCall,
	getSavingsPortJobsForActivePortCall
} from 'store/portcalls/portCallsSelectors';
import { setActivePortJobCode } from 'store/portJobs/actions';
import * as styles from './Savings.module.scss';
import { AppState } from 'store-types';
import { retrieveSavings, retrieveTimeSavings } from 'store/savings/actions';
import { getSavingsFetchStatus } from 'store/savings/savingsSelectors';
import { Icon } from 'components/antd';

interface SavingsProps {
	// from parent
	portCallId: string;
	portJobCode: string;
	// from mapStateToProps
	activePortCall: PortCall;
	activePortJob: PortJob;
	activePortJobCode: string;
	savingsPortJobs: PortJob[];
	isLoading: boolean;
	// from mapDispatchToProps
	retrieveSavings: typeof retrieveSavings;
	setActivePortJobCode: typeof setActivePortJobCode;
	retrieveTimeSavings: typeof retrieveTimeSavings;
}
class Savings extends React.PureComponent<SavingsProps> {
	componentDidMount() {
		const { savingsPortJobs } = this.props;

		const validJob = this.isPortJobVisibleInSavings();
		if (!validJob && savingsPortJobs.length) {
			const nextActivePortJobCode = savingsPortJobs[0].code;
			this.props.setActivePortJobCode(nextActivePortJobCode);
			navigateTo(
				`/${PATHS.portCalls}/${this.props.activePortCall.id}/jobs/${nextActivePortJobCode}/savings`
			);
		}
	}

	private isPortJobVisibleInSavings = (
		nextPortJobCode: string = this.props.activePortJobCode
	): boolean =>
		this.props.savingsPortJobs &&
		this.props.savingsPortJobs.some(({ code }) => {
			return nextPortJobCode === code;
		});

	retrieveSavings(code?: string) {
		const { activePortCall, activePortJobCode } = this.props;
		const jobCode = code || activePortJobCode;
		this.props.retrieveSavings({
			portCallId: activePortCall.id,
			jobCode
		});
		this.props.retrieveTimeSavings({
			portCallId: activePortCall.id,
			jobCode
		});
	}

	onJobCodeChange = (code: string) => {
		this.props.setActivePortJobCode(code);
		navigateTo(
			`/portcalls/${this.props.activePortCall.id}/jobs/${code}/savings`
		);
		this.retrieveSavings(code);
	};

	render() {
		const {
			activePortCall,
			activePortJobCode,
			savingsPortJobs,
			isLoading
		} = this.props;

		return (
			<DataFetcher
				dispatch={() => {
					this.retrieveSavings(activePortJobCode);
				}}
				isLoading={isLoading}
			>
				{() => (
					<>
						<Fit className={styles.root}>
							{activePortCall ? (
								<JobDetailsTab
									onJobCodeChange={this.onJobCodeChange}
									tabs={savingsPortJobs.map(
										(job): PortJobTab => ({
											jobCode: job.code,
											operationIndicator: job.isOperationsDisabled && (
												<Icon
													type="operations-disable"
													color="standard"
													offset="left"
													size={20}
												/>
											),
											financeIndicator: !job.financeEnabled && (
												<Icon
													type="finance-disable"
													color="standard"
													offset="left"
													size={20}
												/>
											),
											children: activePortJobCode === job.code && (
												<SavingsContent job={job} />
											)
										})
									)}
									tabBarExtraContent={null}
								/>
							) : (
								<Loading size="lg" />
							)}
						</Fit>
					</>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(state: AppState) => ({
		activePortCall: getActivePortCall(state),
		activePortJob: getActivePortJob(state),
		activePortJobCode: getActivePortJobCode(state),
		isLoading: getSavingsFetchStatus(state),
		savingsPortJobs: getSavingsPortJobsForActivePortCall(state)
	}),
	{
		retrieveSavings,
		setActivePortJobCode,
		retrieveTimeSavings
	}
)(Savings);
