import Api from 'services/api';
import { UserSearchGroupType } from 'services/api/users/userServiceTypes';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';

export const onSearch = (searchTerm: string, groupType?: UserSearchGroupType) =>
	searchEntitiesForAutocompleteSearch(
		Api.Threads.searchThreads,
		searchTerm,
		groupType
	);
