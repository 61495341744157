import Api from 'services/api';
import { retrieveBuoysAsync as async } from 'store/masterdata';
import {
	RetrieveBuoysRequest as request,
	RetrieveBuoysResponse as response
} from 'services/api/ports/portsServiceTypes';

import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Ports.retrieveBuoys;

export const executor = makeDefaultExecutor<request, response, Error>({
	async,
	api
});

export default makeTakeLatestWatcher<request, response, Error>(
	{ api, async },
	executor
);
