import Api from 'services/api';
import { select, put, call, all, fork, takeLatest } from 'redux-saga/effects';
import { updatePrefundingStatusAsync as async } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	UpdatePrefundingStatusPayload,
	UpdatePrefundingStatusRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { retrieveFinance } from 'store/finance/actions';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { closeModal } from 'store/modals/actions';

const api = Api.FinanceFundings.updatePrefundingStatus;

function* executor({ ...params }: UpdatePrefundingStatusPayload): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const request: UpdatePrefundingStatusRequest = {
		...params,
		portCallId,
		jobCode
	};
	yield put(async.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			async.done({
				result: response,
				params: request,
				response
			})
		);
		yield put(closeModal(`cancel-${params.fundingRequestId}`));
		yield put(closeModal(`accept-${params.fundingRequestId}`));
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
}

function* worker({
	payload
}: Action<UpdatePrefundingStatusRequest>): SagaIterator {
	yield call(executor, payload);
}

function* watcher(): SagaIterator {
	yield takeLatest(async.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
