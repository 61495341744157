import { PortCallsState } from '../portCallsState';
import { Success } from 'typescript-fsa';
import {
	DeletePortJobRequest,
	DeletePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';

export const onDeletePortCallJob = (
	state: PortCallsState,
	action: Success<DeletePortJobRequest, DeletePortJobResponse>
): PortCallsState => {
	if (!state.portCall) {
		return state;
	}
	return {
		...state,
		portCall: {
			...state.portCall,
			jobs: state.portCall.jobs.filter(
				job => job.code !== action.params.jobCode
			)
		}
	};
};
