import { PortJobState } from '../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';

export function onRetrieveEditPortJobDataStarted(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortJobData: FetchStatus.PENDING
		}
	};
}

export function onRetrieveEditPortJobDataSuccess(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortJobData: FetchStatus.SUCCESS
		}
	};
}

export function onRetrieveEditPortJobDataFailed(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortJobData: FetchStatus.FAILURE
		}
	};
}
