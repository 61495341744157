import { Port, Buoy, Canal } from 'services/api/ports/portsServiceTypes';
import { Anchorage } from 'services/api/anchorage/anchorageServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

interface PortMapped<T> {
	[portId: string]: T;
}

export interface PortsState {
	error?: Error;
	portsById: PortMapped<Port>;
	anchoragesByPort: PortMapped<Anchorage[]>;
	buoysByPort: PortMapped<Buoy[]>;
	canalsByPort: PortMapped<Canal[]>;
	// TO DO: refactor "isFetching" to "fetchStatuses"
	isFetching: FetchStatus;
	fetchStatuses: {
		retrievePorts: FetchStatus;
		canals: FetchStatus;
		[key: string]: FetchStatus;
	};
}

export const initialState: PortsState = {
	portsById: {},
	anchoragesByPort: {},
	buoysByPort: {},
	canalsByPort: {},
	isFetching: FetchStatus.IDLE,
	fetchStatuses: {
		retrievePorts: FetchStatus.IDLE,
		canals: FetchStatus.IDLE
	}
};
