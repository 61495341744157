export const EventTypes = {
	MessageAdded: 'MessageAdded',
	StatusUpdated: 'StatusUpdated',
	AssignedGroupUpdated: 'AssignedGroupUpdated',
	AssignedUserUpdated: 'AssignedUserUpdated'
};

export const EventTypeTemplates = {
	[EventTypes.StatusUpdated]: '{from} marked Thread as {status}, {date}',
	[EventTypes.AssignedGroupUpdated]:
		'{from} reassigned the Thread to {to}, {group}, {date}',
	[EventTypes.AssignedUserUpdated]:
		'{from} reassigned the Thread to {to}, {group}, {date}'
};
