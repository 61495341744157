import React from 'react';
import { connect } from 'react-redux';
import {
	setActiveDraftType,
	setActiveDraftFormAndField
} from 'store/drafts/actions';
import { CompanyDraft, DraftType } from 'store/drafts/draftsState';
import { openModal } from 'store/modals/actions';
import { ADD_COMPANY_DRAFT_MODAL } from 'containers/Drafts/constants';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { CargoLineDischargeOperation } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { getValues } from 'store/form/selectors';

import CargoLineForm, {
	CargoLineProps
} from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineForm';
import CargoLineFields from './CargoLineFields';
import validate from './validate';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	getLoadCargoLineInitialValues,
	getIsEditableCargoSplit
} from 'store/portJobs/selectors';
import { AppState } from 'store-types';

export interface CargoLineFormProps extends CargoLineProps {
	isCargoSplit: boolean;
	formValues: CargoLineDischargeOperation;
	initialValues: CargoLineDischargeOperation;
	chartererDrafts: CompanyDraft[];
	receiverDrafts: CompanyDraft[];
	setActiveDraftType: typeof setActiveDraftType;
	setActiveDraftFormAndField: typeof setActiveDraftFormAndField;
	openModal: typeof openModal;
}

class CargoLine extends React.Component<CargoLineFormProps> {
	onAddDraftClick = (
		activeDraftType: DraftType,
		formFieldName: string,
		activeDraftTypeAlias: string
	) => {
		this.props.setActiveDraftType({ activeDraftType, activeDraftTypeAlias });
		this.props.setActiveDraftFormAndField({
			formId: FORM.cargoLine,
			fieldName: formFieldName
		});
		this.props.openModal({ name: ADD_COMPANY_DRAFT_MODAL });
	};

	render() {
		const {
			onAdd,
			formValues,
			initialValues,
			onCancel,
			chartererDrafts,
			receiverDrafts,
			isCargoSplit
		} = this.props;
		return (
			<CargoLineForm
				onAdd={onAdd}
				onCancel={onCancel}
				validate={validate}
				initialValues={initialValues}
			>
				{props => (
					<CargoLineFields
						operationTypeCode={props.operationTypeCode}
						commodities={props.commodities}
						units={props.units}
						formValues={formValues}
						isSplit={isCargoSplit}
						onAddDraftClick={this.onAddDraftClick}
						chartererDrafts={chartererDrafts}
						receiverDrafts={receiverDrafts}
					/>
				)}
			</CargoLineForm>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isCargoSplit: getIsEditableCargoSplit(state),
		formValues: getValues<CargoLineDischargeOperation>(state, FORM.cargoLine),
		initialValues: getLoadCargoLineInitialValues(state),
		chartererDrafts: getCompanyDraftsByType(state, DraftType.CHARTERER),
		receiverDrafts: getCompanyDraftsByType(state, DraftType.SHIPPER_RECEIVER)
	}),
	{
		openModal,
		setActiveDraftType,
		setActiveDraftFormAndField
	}
)(CargoLine);
