import actionCreator from '../portJobsActionCreator';
import {
	ToggleOperationsStatusParams,
	ToggleOperationsResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'TOGGLE_OPERATIONS';

export const toggleOperationsStatusAsync = actionCreator.async<
	ToggleOperationsStatusParams,
	ToggleOperationsResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Operations'
	}
});

export const toggleOperationsStatus = actionCreator<
	ToggleOperationsStatusParams
>(ACTION_NAME);
