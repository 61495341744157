import { FundingRequest } from 'services/api/financeFundings/financeFundingsServiceTypes';

export const SEND_FINANCE_MODAL = 'sendFinanceModal';

export const SEND_FINANCE_FORM = 'sendFinanceForm';
export const SEND_FINANCE_CONFIRMATION_MODAL = 'sendFinanceConfirmationModal';

export enum SendFinanceFormFields {
	JOB_STATUS = 'jobStatus',
	PDA = 'PDA',
	CE = 'CE',
	DA = 'DA',
	PREFUND_REQUEST = 'isPrefundRequested',
	REQUEST_PREFUND_YES = 'requestPrefundYes',
	REQUEST_PREFUND_NO = 'requestPrefundNo',
	SELECT_DA = 'selectDA',
	FUNDING_TABLE = 'fundingTable',
	FUNDING_REQUESTED = 'fundRequestedAmt',
	FUNDING_PERCENT = 'fundPercent',
	DOCUMENT_ID = 'documentId'
}

export interface SendFinanceFormValues {
	[SendFinanceFormFields.JOB_STATUS]: string;
	[SendFinanceFormFields.PREFUND_REQUEST]: string;
	[SendFinanceFormFields.FUNDING_TABLE]: FundingRequest[];
}

export interface SendFinanceFormErrors {
	[SendFinanceFormFields.JOB_STATUS]?: string;
	[SendFinanceFormFields.PREFUND_REQUEST]?: string;
	[SendFinanceFormFields.FUNDING_TABLE]?: FundingRequest[];
}
