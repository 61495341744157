import {
	InternalCommentCallFailure,
	PostInternalCommentsRequest
} from 'services/api/threads/threadsServiceTypes';
import { InternalCommentsState } from '../internalCommentsState';
import { FetchStatus } from 'services/api/apiTypes';
export const onAddInternalCommentStarted = (
	state: InternalCommentsState,
	action: PostInternalCommentsRequest
): InternalCommentsState => ({
	...state,
	lastComment: {
		text: action.text,
		isPosting: FetchStatus.PENDING
	}
});
export const onAddInternalCommentFailed = (
	state: InternalCommentsState,
	action: InternalCommentCallFailure
): InternalCommentsState => ({
	...state,
	lastComment: {
		...state.lastComment,
		isPosting: FetchStatus.FAILURE,
		error: action.error
	}
});
export const onAddInternalCommentsDone = (
	state: InternalCommentsState
): InternalCommentsState => ({
	...state,
	lastComment: {
		...state.lastComment,
		isPosting: FetchStatus.SUCCESS
	}
});
