import { createSelector } from 'reselect';
import { getPortJobServiceVendors } from 'store/portJobs/selectors';
import { flatMap, flow, uniqWith } from 'lodash';
import {
	AppointDataService,
	AppointDataVendor
} from 'services/api/portJobs/portJobsServiceTypes';
import { DraftVendorsWithServices } from './appointToPortJobModalTypes';

type Services = AppointDataService[];

const filterServicesForContainingVendorId = (id: string) => (
	services: Services
) =>
	services.filter(({ draftVendors }) =>
		draftVendors.some(vendor => vendor.id === id)
	);

const mapIntoServiceNameCollection = (services: Services) =>
	services.map(({ serviceName }) => serviceName);

const appendServicesToDraftVendors = (services: Services) => (
	vendors: AppointDataVendor[]
): DraftVendorsWithServices =>
	vendors.reduce(
		(acc, { id, name }) => [
			...acc,
			{
				name,
				services: flow(
					filterServicesForContainingVendorId(id),
					mapIntoServiceNameCollection
				)(services)
			}
		],
		[]
	);

const extractDraftVendorsFromServices = (services: Services) =>
	uniqWith(
		flatMap(services, ({ draftVendors = [] }) => draftVendors),
		(service1, service2) => service1.id === service2.id
	);

export const getDraftVendors = createSelector(
	getPortJobServiceVendors,
	(services): DraftVendorsWithServices =>
		flow(
			extractDraftVendorsFromServices,
			appendServicesToDraftVendors(services)
		)(services)
);
