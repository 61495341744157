import { ColumnProps } from 'components/antd/Table/TableTypes';
import {
	Group,
	GroupSearchByType
} from 'services/api/groups/groupsServiceTypes';
import { GroupsFiltersParam } from 'store/groups/filtersSync';

export type GroupColumnsType = Array<ColumnProps<Group>>;

export const SELECTED_COMPANY_ID = 'selectedCompanyId';

export const groupsFiltersTypeMap = {
	[GroupsFiltersParam.SEARCH_TERM]: 'Search Term',
	[GroupsFiltersParam.COMPANY]: 'Company',
	[GroupsFiltersParam.GROUP_TYPE]: 'Group Type',
	[GroupSearchByType.GroupNames]: 'Group Name',
	[GroupSearchByType.GroupCompanyNames]: 'Group Company Name'
};
