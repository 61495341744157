import { AxiosTypedPromise } from 'services/api/apiTypes';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import { SearchEntitiesForAutocompleteResponse } from './AutocompleteTypes';

export function searchEntitiesForAutocomplete<P>(
	apiCall: (
		searchTerm?: string,
		otherParams?: P
	) => AxiosTypedPromise<SearchEntitiesForAutocompleteResponse>,
	searchTerm?: string,
	otherParams?: P
) {
	return apiCall(searchTerm, otherParams).then(
		({ data: { results, elements } }) => {
			const items = results || elements || [];
			return items.map(
				({ id, code, name }) => ({ id: id || code, name } as AutocompleteOption)
			);
		}
	);
}
