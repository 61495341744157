import { all, fork } from 'redux-saga/effects';
import retrieveFundingWatcher from './retrieveFundingRequestSaga';
import updateFundingWatcher from './updateFundingRequestSaga';
import updateFundingTransactionWatcher from './updateFundingSaga';
import createFundingWatcher from './createFundingRequestSaga';
import retrieveFundingPageDataWatcher from './retrieveFundingPageDataSaga';
import retrieveNewFundingDataWatcher from './retrieveNewFundingDataSaga';
import retrievePrefundingWatcher from './retrievePreFundingRequestSaga';
import sendPrefundingsWatcher from './sendPrefundingsRequestSaga';
import updatePrefundingStatusWatcher from './updatePrefundingStatusSaga';

export default function*() {
	yield all([
		fork(retrieveFundingPageDataWatcher),
		fork(retrieveNewFundingDataWatcher),
		fork(retrieveFundingWatcher),
		fork(retrievePrefundingWatcher),
		fork(sendPrefundingsWatcher),
		fork(updateFundingWatcher),
		fork(updateFundingTransactionWatcher),
		fork(createFundingWatcher),
		fork(updatePrefundingStatusWatcher)
	]);
}
