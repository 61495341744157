import { notify } from 'store/notifications/actions';
import { getNotificationByUuid } from 'store/notifications/selectors';

import {
	HandleApiArg,
	ApiMiddlewareHandleType
} from '../../apiMiddlewareTypes';
import { createApiMiddlewareTypeHandler } from '../../apiMiddlewareUtils';
import config from '../../apiMiddlewareConfig';

const CONNECTION_CONFIG = config[ApiMiddlewareHandleType.CONNECTION];

const NOTIFICATION_UUID = 'connection-error';

const handler = createApiMiddlewareTypeHandler(
	ApiMiddlewareHandleType.CONNECTION,
	connectionHandler
);

const getHasConnectionErrorNotification = ({
	api: { getState }
}: HandleApiArg) => {
	const state = getState();
	const hasConnectionErrorNotification = getNotificationByUuid(
		state,
		NOTIFICATION_UUID
	);
	return hasConnectionErrorNotification;
};

function connectionHandler(args: HandleApiArg) {
	if (getHasConnectionErrorNotification(args)) {
		return;
	}
	const description = navigator.onLine
		? CONNECTION_CONFIG.online
		: CONNECTION_CONFIG.offline;

	// only connection errors - client has no connection, or service is unreachable, block propagation of error and show notification
	return notify.error({
		uuid: NOTIFICATION_UUID,
		description,
		duration: 0 // don't close automatically
	});
}

export default handler;
