import React, { useEffect } from 'react';
import { Loading } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveThread } from 'store/thread/selectors';
import { retrieveThreadById, resetThread } from 'store/thread';
import Thread from './Thread';

interface ThreadPreviewProps {
	threadId: string;
	isEditAssignTo: boolean;
}

function ThreadPreview(props: ThreadPreviewProps) {
	const { threadId, isEditAssignTo } = props;
	const dispatch = useDispatch();
	const thread = useSelector(getActiveThread);
	useEffect(() => {
		dispatch(resetThread());
		dispatch(retrieveThreadById({ threadId }));
	}, [threadId, dispatch]);
	return thread ? (
		<Thread
			thread={thread}
			isNewTab={false}
			isPreviewModeEnabled
			isExpand={false}
			editAssignTo={isEditAssignTo}
		/>
	) : (
		<Loading />
	);
}

export default ThreadPreview;
