import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { updateContextAsync } from 'store/thread/actions';
import { notify } from 'store/notifications/actions';
import { get } from 'lodash';
import { THREAD_IGNORED_CODES } from 'store/thread/threadConstants';
import { addThreadMessageAsync } from 'store/thread/actions/addThreadMessage';
import { assignJobToThreadAsync } from 'store/thread/actions/assignJobToThread';
import { getActiveThreadId } from 'store/threads/selectors';
import { patchThreadPermissionsAsync } from 'store/thread/actions/patchThreadPermissions';
import { retrieveThreadWithSubDataById } from '../retrieveThreadWithRelationsByIdSaga';
import { postInternalCommentsAsync } from 'store/internalComments/actions/postInternalComments';
const getErrorMessage = (errorCode: number) => {
	switch (errorCode) {
		case 403:
			return `You have no access to the thread you were looking at.`;
		default:
			return `Action cannot be performed on the thread.`;
	}
};

export function* errorHandlingThreadActionsWorker({
	payload
}: /* eslint-disable @typescript-eslint/no-explicit-any */
Action<any>): SagaIterator {
	const status = get(payload, 'error.response.status', 400);
	if (!THREAD_IGNORED_CODES.includes(status)) {
		return;
	}
	yield put(notify.warning(getErrorMessage(status)));
	const activeThreadId: string = yield select(getActiveThreadId);
	yield call(retrieveThreadWithSubDataById, activeThreadId);
}

export default function* errorHandlingThreadActionsWatcher(): SagaIterator {
	yield takeLatest(
		[
			postInternalCommentsAsync.failed,
			addThreadMessageAsync.failed,
			assignJobToThreadAsync.failed,
			updateContextAsync.failed,
			patchThreadPermissionsAsync.failed
		],
		errorHandlingThreadActionsWorker
	);
}
