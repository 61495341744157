import React from 'react';
import { SubHeader } from 'components/PageHeader/components';
import styles from 'components/PageHeader/PageHeader.module.scss';

const PageSubHeader: React.SFC = ({ children }) => (
	<div className={styles.gullGray}>
		<SubHeader componentClass="div">{children}</SubHeader>
	</div>
);

export default PageSubHeader;
