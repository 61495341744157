import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import {
	DEFAULT_REDUX_FORM_CONFIG,
	DEFAULT_TEXTAREA_MAX_LENGTH
} from 'app-constants';
import { Row, Col, Input, TextArea } from 'components/antd';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { retrieveCurrencies } from 'store/currencies';
import TotalAmountLines from 'sections/PortCall/PortJob/Operations/Operation/TotalAmount/TotalAmountLines';

export enum CashToMasterFormNames {
	CASH_TO_MASTER_MASTER_NAME = 'cashToMasterMasterName',
	CASH_TO_MASTER_DETAILS = 'cashToMasterDetails'
}

interface CashToMasterFormBaseProps {
	// from mapDispatchToProps
	retrieveCurrencies: typeof retrieveCurrencies;
}
interface CashToMasterFormProps
	extends InjectedFormProps<FormData, CashToMasterFormBaseProps>,
		CashToMasterFormBaseProps {}

class CashToMasterForm extends React.Component<CashToMasterFormProps> {
	componentDidMount() {
		this.props.retrieveCurrencies({});
	}

	render() {
		return (
			<>
				<Row>
					<Col xs={4}>
						<Input.ReduxFormItem
							type="text"
							name={CashToMasterFormNames.CASH_TO_MASTER_MASTER_NAME}
							label="Master"
							help
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={4}>
						<TotalAmountLines />
					</Col>
				</Row>
				<TextArea.ReduxFormItem
					name={CashToMasterFormNames.CASH_TO_MASTER_DETAILS}
					maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
					label="Operation Details"
					rows={4}
				/>
			</>
		);
	}
}

export default connect(null, { retrieveCurrencies })(
	reduxForm<FormData, CashToMasterFormBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true
	})(CashToMasterForm)
);
