import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { retrieveBerths, retrieveBerthsAsync } from '../actions/retrieveBerths';
import { RetrieveBerthsRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

import { Action } from 'typescript-fsa';

export function* retrieveBerthsExecutor(
	actionParams: RetrieveBerthsRequest,
	api: typeof Api.VesselProgramme.retrieveBerths
): SagaIterator {
	const { id, terminal } = actionParams;
	const params = { id, terminal };

	yield put(retrieveBerthsAsync.started(params));

	try {
		const response = yield call(api, id, terminal);

		yield put(
			retrieveBerthsAsync.done({
				result: response.data,
				params,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveBerthsAsync.failed({
				error,
				params
			})
		);
	}
}

export function* retrieveBerthsWorker(
	action: Action<RetrieveBerthsRequest>
): SagaIterator {
	yield call(
		retrieveBerthsExecutor,
		action.payload,
		Api.VesselProgramme.retrieveBerths
	);
}

export function* retrieveTerminalsWatcher(): SagaIterator {
	yield takeLatest(retrieveBerths.type, retrieveBerthsWorker);
}

export default function*() {
	yield all([fork(retrieveTerminalsWatcher)]);
}
