import * as React from 'react';
import { TRS, TR, TD, FormattedTimeExtended, Flex } from 'components';
import { Tag, Badge } from 'components/antd';
import { Justify } from 'components/types';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import PortCallCol from '../PortCallCol/PortCallCol';
import PortJobList from '../PortJobList/PortJobList';
import { portCallStatusToLabel } from 'store/portJobs/constants';
import styles from './PortCallRow.module.scss';
import portCallStyles from '../PortCalls.module.scss';
import { Link } from 'react-router-dom';
import { getPortCallRowAlerts } from 'store/portcalls/selectors';
import { PortCallAlertsMap } from 'app-types';
import { sortBy } from 'lodash';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';

export interface PortCallRowProps {
	portCall: PortCall;
	alertsMap?: PortCallAlertsMap;
	flex?: boolean;
	onPortJobClick?: (link: string) => void;
	checkPortJobCanView?: boolean;
	onOptionChange?: (portJobCode: string, portCallId: string) => void;
}

export class PortCallRow extends React.PureComponent<PortCallRowProps> {
	render() {
		const {
			portCall,
			alertsMap,
			flex,
			onPortJobClick,
			checkPortJobCanView,
			onOptionChange
		} = this.props;

		const valueEospPlt = portCall.eospPlt ? portCall.eospPlt : portCall.etaPlt;
		const valueCospPlt = portCall.cospPlt ? portCall.cospPlt : portCall.etsPlt;
		const jobs = sortBy<PortJob>(portCall.jobs, job => job.code);
		const isOperationsDisabled = jobs.every(
			job => job.isOperationsDisabled === true
		);
		const jobCode = jobs.find(job => job.isOperationsDisabled === true)?.code;
		const alerts = getPortCallRowAlerts(
			alertsMap,
			portCall.id,
			isOperationsDisabled,
			jobCode
		);
		return (
			<TR flex={flex}>
				<TD flex={flex}>
					<table className={styles.table}>
						<TRS header>
							<TR className={styles.header}>
								<TD>
									<PortCallCol label="Port" icon="anchor">
										{portCall.port.name}
									</PortCallCol>
								</TD>
								<TD>
									<PortCallCol label="Vessel" icon="vessel">
										{portCall.vessel.name}
									</PortCallCol>
								</TD>
								<TD colspan={2}>
									<PortCallCol
										label={portCall.eospPlt ? 'EOSP' : 'ETA'}
										icon="calendar-check"
									>
										<FormattedTimeExtended
											value={valueEospPlt}
											directPortTimeZoneId={portCall.portTimezoneId}
										/>
									</PortCallCol>
								</TD>
								<TD colspan={2}>
									<PortCallCol
										label={portCall.cospPlt ? 'COSP' : 'ETS'}
										icon="calendar-check"
									>
										<FormattedTimeExtended
											value={valueCospPlt}
											directPortTimeZoneId={portCall.portTimezoneId}
										/>
									</PortCallCol>
								</TD>
								<TD colspan={2}>
									<Flex justify={Justify.END}>
										{alerts.map(alert => (
											<Badge
												dot
												placement="topRight"
												key={alert.url}
												theme={alert.theme}
											>
												<Link to={alert.url}>{alert.text}</Link>
											</Badge>
										))}
									</Flex>
								</TD>
								<TD>
									<PortCallCol label="Status" justify={Justify.CENTER}>
										<Tag className={portCallStyles.tag}>
											{portCallStatusToLabel(
												portCall.status,
												portCall.isCancelled
											)}
										</Tag>
									</PortCallCol>
								</TD>
							</TR>
						</TRS>
					</table>
					{jobs.length ? (
						<PortJobList
							portJobs={jobs}
							portCallId={portCall.id}
							portId={portCall.port.id}
							portCallAlertsMap={alertsMap}
							onPortJobClick={onPortJobClick}
							checkPortJobCanView={checkPortJobCanView}
							onOptionChange={onOptionChange}
						/>
					) : (
						<div className={styles.noJobsPlaceholder}>
							This Port Call doesn't have any Job yet
						</div>
					)}
				</TD>
			</TR>
		);
	}
}
