import actionCreator from '../configurationActionCreator';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { RetrieveCompaniesForOrganisationResponse } from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_CONFIGURATION_COMPANIES';
export const retrieveConfigurationCompanies = actionCreator<PermissionCode[]>(
	ACTION_NAME
);
export const retrieveConfigurationCompaniesAsync = actionCreator.async<
	PermissionCode[],
	RetrieveCompaniesForOrganisationResponse,
	Error
>(ACTION_NAME);
