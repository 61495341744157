import { Success } from 'typescript-fsa';
import {
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { PortJobState } from './../../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrievePortJobCustodyRolesStarted = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		custodyRoles: FetchStatus.PENDING
	}
});
export const onRetrievePortJobCustodyRolesSuccess = (
	state: PortJobState,
	action: Success<PortJobCustodyRolesRequest, PortJobCustodyRolesResponse>
) => ({
	...state,
	dictionaries: {
		...state.dictionaries,
		custodyRoles: action.result.elements
	},
	fetchStatuses: {
		...state.fetchStatuses,
		custodyRoles: FetchStatus.SUCCESS
	}
});
export const onRetrievePortJobCustodyRolesFailed = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		custodyRoles: FetchStatus.FAILURE
	}
});

export const onPortJobCustodyRolesReset = (state: PortJobState) => ({
	...state,
	dictionaries: {
		...state.dictionaries,
		custodyRoles: []
	},
	fetchStatuses: {
		...state.fetchStatuses,
		custodyRoles: FetchStatus.IDLE
	}
});
