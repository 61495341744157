import { deleteConfigurationFileAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { DeleteCompanyTemplateFileRequest } from 'services/api/templates/templatesServiceTypes';

export default makeTakeEveryWatcher<
	DeleteCompanyTemplateFileRequest,
	null,
	Error
>({
	api: Api.Templates.deleteCompanyTemplateFile,
	async
});
