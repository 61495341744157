import {
	AddServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../servicesActionCreator';

const ACTION_NAME = 'ADD_SERVICE_CATEGORY';

export const addServiceCategoryAsync = actionCreator.async<
	AddServiceCategoryRequest,
	ServiceCategory
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Service Category'
	},
	behaviour: {
		type: 'submit'
	}
});

export const addServiceCategory = actionCreator<AddServiceCategoryRequest>(
	ACTION_NAME
);
