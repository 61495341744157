import { ContactType } from 'services/api/messages/messagesServiceTypes';
import actionCreator from 'store/messages/messagesActionCreator';

const ADD_CONTACT = 'ADD_CONTACT';
export const addContactAsync = actionCreator.async<
	ContactType,
	undefined,
	Error
>(ADD_CONTACT, {
	templateParams: {
		entityName: 'Contact'
	}
});
export const addContact = actionCreator<ContactType>(ADD_CONTACT);
