import * as React from 'react';
import classNames from 'classnames';
import { Position } from 'components';
import styles from './Timeline.module.scss';
import { Body } from './components';
/**
 * @example
 * <Timeline>
 *     <Timeline.Item>
 *          // children
 *         <Timeline.Line>
 *				// children
 *         </Timeline.Line>
 *     </Timeline.Item>
 * </Timeline>
 */

const Icon: React.FC = props => {
	const { children } = props;
	return <span className={styles.icon}>{children}</span>;
};

interface MaybeIconProps {
	icon?: React.ReactNode;
}
const MaybeIcon: React.FC<MaybeIconProps> = props => {
	const { icon } = props;
	if (!icon) {
		return null;
	}
	return (
		<Position>
			<Icon>{icon}</Icon>
		</Position>
	);
};

interface ItemProps extends MaybeIconProps {
	offset?: boolean;
	className?: string;
	dataQa?: string; // for QAA
}

const Item: React.FC<ItemProps> = props => {
	const { children, className, offset, dataQa, icon } = props;
	const hasIcon = Boolean(icon);
	return (
		<div
			className={classNames(styles.item, className, {
				[styles.offset]: offset,
				[styles.placeholder]: hasIcon,
				[styles.line]: hasIcon
			})}
			data-qa={dataQa}
		>
			<MaybeIcon icon={icon} />
			{children}
		</div>
	);
};
interface LineProps {
	icon?: React.ReactNode;
	push?: boolean;
	dataQa?: string; // for QAA
}

const Line: React.FC<LineProps> = props => {
	const { icon, children, push, dataQa } = props;
	return (
		<div
			className={classNames(styles.line, { [styles.push]: push })}
			data-qa={dataQa}
		>
			<MaybeIcon icon={icon} />
			{children}
		</div>
	);
};

const Element: React.FC = props => {
	const { children } = props;
	return <div className={styles.element}>{children}</div>;
};

interface TimelineProps {
	pull?: boolean;
	className?: string;
}

class Timeline extends React.PureComponent<TimelineProps> {
	static Item: typeof Item;
	static Body: typeof Body;
	static Line: typeof Line;
	static Icon: typeof Icon;
	static Element: typeof Element;
	render() {
		const { children, pull, className } = this.props;
		return (
			<div
				className={classNames(styles.root, { [styles.pull]: pull }, className)}
			>
				{children}
			</div>
		);
	}
}

Timeline.Item = Item;
Timeline.Line = Line;
Timeline.Icon = Icon;
Timeline.Element = Element;
Timeline.Body = Body;

export default Timeline;
