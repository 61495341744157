import { ThreadsState } from 'store/threads/threadsState';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';

export const onUpdateThreads = (
	state: ThreadsState,
	payload: ThreadItem
): ThreadsState => {
	const threads = { ...state.byId };

	for (const key in threads) {
		if (key === payload.id) {
			threads[key] = payload;
		}
	}

	return {
		...state,
		byId: {
			...threads
		}
	};
};
