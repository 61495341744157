import * as React from 'react';
import classNames from 'classnames';
import { Title, Gap, Text } from 'components';
import { Select, Row, Col, Table } from 'components/antd';
import { Align } from 'components/types';
import appointStyles from './AppointToPortJobModal.module.scss';

import {
	AppointDataService,
	AppointDataSelectedVendor
} from 'services/api/portJobs/portJobsServiceTypes';
import styles from '../../JobDetails.module.scss';
import { connect } from 'react-redux';
import { getDraftVendors } from './appointToPortJobModalSelectors';
import { getPortJobServiceVendorsWithManyAvailableVendors } from 'store/portJobs/selectors';
import { vendorDraftsColumns } from './appointToPortJobModalColumns';
import { DraftVendorsWithServices } from './appointToPortJobModalTypes';
import { isEmpty } from 'lodash';
import { AppState } from 'store-types';

interface AppointVendorsProps {
	availableServices: AppointDataService[];
	draftVendors: DraftVendorsWithServices;
	onServiceVendorSelect: (vendorSelected: AppointDataSelectedVendor) => void;
}
interface AppointVendorsState {
	vendorsEmails: {
		[serviceCode: string]: string;
	};
}

class AppointVendors extends React.PureComponent<
	AppointVendorsProps,
	AppointVendorsState
> {
	state = {
		vendorsEmails: {}
	};

	onServiceVendorSelect = (
		value: string,
		serviceVendor: AppointDataService
	) => {
		const currentAvailableService = serviceVendor.availableVendors.find(
			availableVendor => availableVendor.id === value
		);
		this.setState({
			vendorsEmails: {
				...this.state.vendorsEmails,
				[serviceVendor.serviceCode]: currentAvailableService?.email
			}
		});

		this.props.onServiceVendorSelect({
			serviceCode: serviceVendor.serviceCode,
			vendorId: value
		});
	};

	render() {
		const { availableServices, draftVendors } = this.props;
		const { vendorsEmails } = this.state;

		return (
			<Gap top="md" isBlock>
				{!isEmpty(draftVendors) && (
					<>
						<Title.H4>Vendors:</Title.H4>
						<Gap isBlock top>
							<Text color="error" weight="bold">
								The following services are in Draft and therefore will not be
								considered:
							</Text>
							<Table
								rowKey="key"
								className={appointStyles.draftVendorsTable}
								columns={vendorDraftsColumns}
								dataSource={draftVendors}
							/>
						</Gap>
					</>
				)}
				{!isEmpty(availableServices) && (
					<Gap isBlock top>
						<Text weight="bold">
							Please select appropriate vendor for the following service(s):
						</Text>
					</Gap>
				)}
				{availableServices.map(service => (
					<Gap top="md" isBlock key={service.serviceCode}>
						<div>{service.serviceName}</div>
						<Row>
							<Col md={6} sm={6} xs={6}>
								<Select
									placeholder="Select vendor"
									onSelect={(value: string) =>
										this.onServiceVendorSelect(value, service)
									}
									getPopupContainer={() => document.body}
								>
									{service.availableVendors.map(({ id, name }) => (
										<Select.Option key={id} value={id}>
											{name}
										</Select.Option>
									))}
								</Select>
							</Col>
							<Col md={6} sm={6} xs={6} align={Align.MIDDLE}>
								{vendorsEmails[service.serviceCode] && (
									<span className={classNames(styles.tag, styles.email)}>
										{vendorsEmails[service.serviceCode]}
									</span>
								)}
							</Col>
						</Row>
					</Gap>
				))}
			</Gap>
		);
	}
}

export default connect((state: AppState) => ({
	draftVendors: getDraftVendors(state),
	availableServices: getPortJobServiceVendorsWithManyAvailableVendors(state) // do not show select boxes for services with 1 or none available vendors
}))(AppointVendors);
