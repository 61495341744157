import { put, takeEvery, take } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { saveAttachmentAsync } from 'store/documents/actions/saveAttachment';
import { DocumentWithOriginalFile } from 'services/api/documents/documentsServiceTypes';
import { processDocument, processDocumentAsync } from '../actions';
import { notify } from 'store/notifications/actions';

const async = saveAttachmentAsync;

/**
 * Upload an attachment
 */
function* executor(action: Action<DocumentWithOriginalFile>) {
	const document = action.payload;

	yield put(async.started(document));

	try {
		yield put(
			processDocument({
				...document
			})
		);

		const {
			payload: { result }
		} = yield take(processDocumentAsync.done);

		yield put(
			async.done({
				result: {
					...result,
					originFileObj: document.originFileObj
				},
				params: document,
				response: null
			})
		);
		if (!result.processed) {
			yield put(notify.error('File upload failed - virus detected'));
		} else {
			yield put(notify.success('Attachment has been successfully created.'));
		}
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: document
			})
		);
	}
}

export default function*() {
	yield takeEvery(async.type, executor);
}
