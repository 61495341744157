import client from 'services/client';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	RetrievePermissionsResponse,
	RetrievePermissionsRequest,
	EntityPermissionRequest,
	EntityPermissionVPRequestParams,
	EntityPermissionResponse
} from './permissionsServiceTypes';

const retrievePermissions = ({
	groupTypeCode,
	groupId
}: RetrievePermissionsRequest): AxiosTypedPromise<RetrievePermissionsResponse> =>
	client.get('/permissions', {
		params: {
			groupType: groupTypeCode,
			groupId
		}
	});

const retrieveEntityPermissionsForPortCall = ({
	entityType,
	entityKey
}: EntityPermissionRequest): AxiosTypedPromise<ResultCollection<
	EntityPermissionResponse
>> => client.get(`/portcalls/permissions/${entityKey}/${entityType}`);

const retrieveEntityPermissionsForVP = ({
	optionalParams,
	entityType,
	entityKey
}: EntityPermissionVPRequestParams): AxiosTypedPromise<ResultCollection<
	EntityPermissionResponse
>> => {
	const { portCallId, ...otherParams } = optionalParams;
	return client.get(
		`/portcalls/${portCallId}/vessel-programme/permissions/${entityKey}/${entityType}`,
		{
			params: otherParams
		}
	);
};

export default {
	retrievePermissions,
	retrieveEntityPermissionsForPortCall,
	retrieveEntityPermissionsForVP
};
