import { UserType } from 'services/api/users/userServiceTypes';
import { PortJobStatus, JobType } from 'store/portJobs/constants';

export enum ActionButtonTypes {
	CONFIRM = 'confirm',
	ACCEPT = 'accept',
	APPOINT = 'appoint',
	LINK_JOB = 'linkJob',
	INVITE_AGENT = 'inviteAgent',
	LINK_CARGO = 'linkCargo'
}

export const ActionButtonsByUserRoleAndJobStatus = {
	[JobType.APPOINTMENT]: {
		[UserType.HUB]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.CONFIRMED]: ActionButtonTypes.APPOINT,
			[PortJobStatus.PDA_REQUESTED]: ActionButtonTypes.APPOINT,
			[PortJobStatus.PDA]: null
		},
		[UserType.LPA]: {
			[PortJobStatus.CREATED]: null,
			[PortJobStatus.APPOINTED]: ActionButtonTypes.ACCEPT,
			[PortJobStatus.PDA]: null
		},
		[UserType.PRINCIPAL]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.CONFIRMED]: null,
			[PortJobStatus.PDA]: null
		}
	},
	[JobType.NOMINATION]: {
		[UserType.HUB]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.AWAITING_APPOINTMENT]: ActionButtonTypes.LINK_JOB,
			[PortJobStatus.CONFIRMED]: null,
			[PortJobStatus.PDA]: null,
			[PortJobStatus.AWAITING_INVITATION]: ActionButtonTypes.INVITE_AGENT
		},
		[UserType.LPA]: {
			[PortJobStatus.CREATED]: null,
			[PortJobStatus.APPOINTED]: null,
			[PortJobStatus.PDA]: null
		},
		[UserType.PRINCIPAL]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.CONFIRMED]: null,
			[PortJobStatus.PDA]: null
		}
	},
	[JobType.CHARTERER_OVERSIGHT]: {
		[UserType.HUB]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.CONFIRMED]: null,
			[PortJobStatus.PDA]: null,
			[PortJobStatus.AWAITING_INVITATION]: ActionButtonTypes.INVITE_AGENT
		},
		[UserType.LPA]: {
			[PortJobStatus.CREATED]: null,
			[PortJobStatus.APPOINTED]: null,
			[PortJobStatus.PDA]: null
		},
		[UserType.PRINCIPAL]: {
			[PortJobStatus.CREATED]: ActionButtonTypes.CONFIRM,
			[PortJobStatus.CONFIRMED]: null,
			[PortJobStatus.PDA]: null
		}
	}
};

export const SHOW_ALL_JOBS_KEY = 'all';

export enum EXTENDED_MAIL_TYPE {
	APPOINTMENT_EMAIL = 'manageappointmentemail',
	INVITATION_EMAIL = 'manageinvitationemail',
	CANCEL_EMAIL = 'managecancelemail',
	PRE_FUNDING_EMAIL = 'customerprefundingemail',
	REQUEST_QUOTE_EMAIL = 'managerequestpdaemail'
}
