import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { MessagesState, initialState } from '../messagesState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FetchStatus } from 'services/api/apiTypes';
import {
	Mailbox,
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

const onRetrieveMessagesMailboxesSuccess = (
	state: MessagesState,
	{ result }: Success<RetrieveMailboxesRequest, RetrieveMailboxesResponse>
): MessagesState => ({
	...state,
	mailboxesById: keyBy<Mailbox>(result.elements, item => item.id)
});

export const onResetMessagesMailboxes = (state: MessagesState) => ({
	...state,
	mailboxesById: initialState.mailboxesById,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveMailboxes: FetchStatus.IDLE
	}
});

export const onRetrieveMessagesMailboxes = makeFetchStatusReducers<
	MessagesState,
	'fetchStatuses',
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse
>('fetchStatuses', 'retrieveMailboxes', {
	doneReducer: onRetrieveMessagesMailboxesSuccess
});
