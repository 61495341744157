import React from 'react';
import { Button } from 'components/antd';
import { Flex, Text } from 'components';
import styles from './NewToolbar.module.scss';
import { UseDocumentContext } from '../../DocumentContext';

const toPercent = (scale: number) => (100 * scale).toFixed();

const ZoomComponent = () => {
	const scaleStep = 0.1;
	const { scale, setScale } = React.useContext(UseDocumentContext);

	const zoomIn = () => {
		const value = scale + scaleStep;
		setScale(value);
	};

	const zoomOut = () => {
		const value = scale - scaleStep;
		if (value < scaleStep) {
			return;
		}
		setScale(value);
	};
	return (
		<Flex direction="horizontal">
			<Button icon="zoom-out" iconSize="lg" onClick={zoomOut} />
			<Text weight="light" className={styles.zoomValue}>
				{toPercent(scale)}%
			</Text>
			<Button icon="zoom-in" iconSize="lg" onClick={zoomIn} />
			<span className={styles.spacer1} />
		</Flex>
	);
};

export default ZoomComponent;
