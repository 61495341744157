import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollableLayout, Content } from 'components';
import UserDetailsForm from './UserDetailsForm';
import UserGroups from './UserGroups/UserGroups';
import {
	UserDetailsFormData,
	UserProfile,
	GroupUser
} from 'services/api/users/userServiceTypes';
import { updateUser } from 'store/users/actions';
import { isUserInMessagingTeam } from 'store/auth/selectors';
import styles from './EditUser.module.scss';

interface EditUserProps {
	userProfile: UserProfile;
	isProfilePage: boolean;
	onGroupsToAssignChange: () => void;
}

const EditUser: FC<EditUserProps> = ({
	userProfile,
	isProfilePage,
	onGroupsToAssignChange
}) => {
	const dispatch = useDispatch();
	const [groupsToAssign, setGroupsToAssign] = useState(
		userProfile.assignedGroups
	);

	useEffect(() => {
		setGroupsToAssign(() => userProfile.assignedGroups);
	}, [userProfile.assignedGroups]);

	const onSubmit = useCallback(
		(formValues: UserDetailsFormData) => {
			const request = {
				...formValues,
				cellphone: formValues.cellphone.replace(/\s/g, ''),
				groups: groupsToAssign.map(group => group.id)
			};
			dispatch(
				updateUser({
					id: userProfile.id,
					request,
					isProfilePage
				})
			);
		},
		[dispatch, userProfile, isProfilePage, groupsToAssign]
	);

	const onGroupAdd = useCallback(
		(addedGroup: GroupUser) => {
			setGroupsToAssign(() => [...groupsToAssign, addedGroup]);
			onGroupsToAssignChange();
		},
		[groupsToAssign, onGroupsToAssignChange]
	);

	const onGroupDelete = useCallback(
		(deletedGroup: GroupUser) => {
			setGroupsToAssign(() =>
				groupsToAssign.filter(group => group.id !== deletedGroup.id)
			);
			onGroupsToAssignChange();
		},
		[groupsToAssign, onGroupsToAssignChange]
	);
	return (
		<ScrollableLayout>
			<ScrollableLayout>
				<UserDetailsForm
					onSubmit={onSubmit}
					isProfilePage={isProfilePage}
					isInternalUser={userProfile.isInternalUser}
				/>
			</ScrollableLayout>

			<Content size="md" className={styles.userGroupsAside}>
				<UserGroups
					onAdd={onGroupAdd}
					onDelete={onGroupDelete}
					assignedGroups={groupsToAssign}
					availableGroups={userProfile.availableGroups}
					isUserInMessagingTeam={isUserInMessagingTeam(
						userProfile.userGroupType
					)}
					profilePage={isProfilePage}
				/>
			</Content>
		</ScrollableLayout>
	);
};

export default EditUser;
