import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { set, isFunction, cloneDeep } from 'lodash';

export const makeFetchStatusReducer = <S, K extends keyof S, A>(
	statusesMapKey: K,
	statusKey: keyof S[K] | ((action: A) => string),
	status: FetchStatus,
	additionalReducer?: (state: S, action: A) => S
) => (state: S, action: A): S => {
	// using Objec.assign because of https://github.com/Microsoft/TypeScript/issues/10727
	// generics cannot be used with spread operator
	const key = isFunction(statusKey) ? statusKey(action) : statusKey;
	const stateWithSetFetchStatus = Object.assign({} as S, state, {
		[statusesMapKey]: Object.assign(
			{},
			// set() is used here because key can be either
			// a one level deep string or not ('prop' or 'prop.prop')
			// This was done to support `byId[id]` type of fetchStatuses
			set(
				/* TODO: TS upgrade cast as string */
				cloneDeep(state[statusesMapKey as string]),
				key,
				status
			)
		)
	}) as S;
	return additionalReducer && action
		? additionalReducer(stateWithSetFetchStatus, action)
		: stateWithSetFetchStatus;
};

interface FetchStatusReducers<State, P, S, F> {
	startedReducer?: (state: State, action: P) => State;
	failedReducer?: (state: State, action: Failure<P, F>) => State;
	doneReducer?: (state: State, action: Success<P, S>) => State;
}

export const makeFetchStatusReducers = <
	State,
	K extends keyof State,
	P,
	S,
	F = Error
>(
	statusesMapKey: K,
	statusKey: keyof State[K] | ((action: P) => string),
	reducers?: FetchStatusReducers<State, P, S, F>
) => {
	return {
		started: makeFetchStatusReducer<State, K, P>(
			statusesMapKey,
			statusKey,
			FetchStatus.PENDING,
			reducers ? reducers.startedReducer : undefined
		),
		failed: makeFetchStatusReducer<State, K, Failure<P, F>>(
			statusesMapKey,
			isFunction(statusKey)
				? ({ params }: Failure<P, F>) => statusKey(params)
				: statusKey,
			FetchStatus.FAILURE,
			reducers ? reducers.failedReducer : undefined
		),
		done: makeFetchStatusReducer<State, K, Success<P, S>>(
			statusesMapKey,
			isFunction(statusKey)
				? ({ params }: Success<P, S>) => statusKey(params)
				: statusKey,
			FetchStatus.SUCCESS,
			reducers ? reducers.doneReducer : undefined
		)
	};
};
