import { PortCallState } from '../portCallState';
import { FetchStatus } from 'services/api/apiTypes';
export function onEndOfSeaPassageStarted(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			endOfSeaPassage: FetchStatus.PENDING
		}
	};
}
export function onEndOfSeaPassageSuccess(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			endOfSeaPassage: FetchStatus.SUCCESS
		}
	};
}
export function onEndOfSeaPassageFailed(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			endOfSeaPassage: FetchStatus.FAILURE
		}
	};
}
