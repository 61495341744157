import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FundingRequestsState } from '../financeFundingsState';
import { RetrieveFundingRequestsPageDataRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';

export const retrieveFundingPageData = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	Request,
	void
>('fetchStatuses', 'retrieveFundingPageData');
