import {
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusPayload,
	UpdateDirectBillStatusResponse
} from 'services/api/directBills/directBillsServiceTypes';
import actionCreator from '../directBillsActionCreator';

const ACTION_NAME = 'UPDATE_DIRECT_BILL_STATUS';

export const updateDirectBillStatusAsync = actionCreator.async<
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Direct Bill'
	}
});

export const updateDirectBillStatus = actionCreator<
	UpdateDirectBillStatusPayload
>(ACTION_NAME);
