import * as React from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import Charterer from './Charterer';
import { PortJobCharterer } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

const Charterers: React.SFC<
	WrappedFieldArrayProps<PortJobCharterer>
> = props => (
	<div>
		{props.fields.map((field, i) => {
			return <Charterer key={i} field={field} values={props.fields.get(i)} />;
		})}
	</div>
);

export default Charterers;
