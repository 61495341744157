export enum NotificationDisplayType {
	ALERT = 'alert',
	NOTIFICATION = 'notification',
	FULL_PAGE = 'fullPage'
}

export type NotificationType = 'success' | 'warning' | 'info' | 'error';

export interface NotificationErrorDescription {
	correlationId?: string;
	status: number | 'UNHANDLED';
}

interface NotificationCommon {
	uuid?: string;
	title?: string;
	description: string | string[];
	duration?: number | null;
	confirmation?: {
		title: string;
	};
	/**
	 * To be able to track potentionally duplicated notifications
	 */
	actionType?: string;
}

interface NotificationCommonError {
	error: NotificationErrorDescription;
}

export type Notification = NotificationCommon &
	Partial<NotificationCommonError>;

export type NotificationVariant = Pick<NotificationCommon, 'duration'> & {
	displayType: NotificationDisplayType;
	type: NotificationType;
};

export type NotificationVariantItem = NotificationVariant & Notification;

export type NotificationItem = NotificationVariantItem & {
	uuid: string;
};

export interface NotificationsDisplayTypeConfig {
	[actionType: string]: NotificationDisplayType;
}

export interface NotificationAddons {
	[actionType: string]: {
		loaded?: boolean;
		errors?: boolean;
	};
}

export interface NotificationsState {
	notifications: { [uuid: string]: NotificationItem };
	notificationsConfig: NotificationsDisplayTypeConfig;
	addons: Partial<NotificationAddons>;
}

export const initialState: NotificationsState = {
	notifications: {},
	notificationsConfig: {},
	addons: {}
};
