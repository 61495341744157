import React, { lazy } from 'react';
import { BreadcrumbItem } from 'store/navigation';

const NewComposeMessage = lazy(() => import('./NewComposeMessage'));

const breadcrumbs: BreadcrumbItem[] = [
	{ title: 'Messages', link: '/messages' },
	{ title: 'Compose message' }
];

const NewComposeMessageRoute = () => (
	<NewComposeMessage
		isMessageHeaderCollapsible={true}
		breadcrumbs={breadcrumbs}
	/>
);

export default NewComposeMessageRoute;
