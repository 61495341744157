import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallState, initialState } from '../portCallState';
import {
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse
} from 'services/api/portCall/portCallServiceTypes';

const onRetrievePortCallActAsSuccess = (
	state: PortCallState,
	action: Success<RetrievePortCallActAsRequest, RetrievePortCallActAsResponse>
): PortCallState => ({
	...state,
	actAs: action.result.elements
});

export const onResetPortCallActAs = (state: PortCallState): PortCallState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveActAs: initialState.fetchStatuses.retrieveActAs
	},
	actAs: initialState.actAs
});

export const onRetrievePortCallActAs = makeFetchStatusReducers<
	PortCallState,
	'fetchStatuses',
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse
>('fetchStatuses', 'retrieveActAs', {
	doneReducer: onRetrievePortCallActAsSuccess
});
