import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import {
	CostSavingsFormData,
	COST_SAVINGS_MODAL
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import CostSavingsForm from './CostSavingsForm';
import { getUpdateCostSavings } from 'store/savings/savingsSelectors';
import { resetCostSavingsContext } from 'store/savings/actions';

interface CostSavingsModalProps {
	// from mapStateToProps
	isModalVisible: boolean;
	initialValues: Partial<CostSavingsFormData>;
	// from mapDispatchToProps
	resetCostSavingsContext: typeof resetCostSavingsContext;
	closeModal: typeof closeModal;
}

const CostSavingsModal = ({
	isModalVisible,
	initialValues,
	...props
}: CostSavingsModalProps) => {
	const onCancel = () => {
		props.closeModal(COST_SAVINGS_MODAL);
		props.resetCostSavingsContext();
	};
	return (
		<Modal
			destroyOnClose
			visible={isModalVisible}
			title={
				initialValues.category ? 'Edit Cost Savings' : 'Add New Cost Savings'
			}
			size="md"
			footer={null}
			onCancel={onCancel}
		>
			<CostSavingsForm />
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isModalVisible: isModalVisible(state, COST_SAVINGS_MODAL),
		initialValues: getUpdateCostSavings(state)
	}),
	{
		resetCostSavingsContext,
		closeModal
	}
)(CostSavingsModal);
