import { initialState } from '../portCallState';
import { SetPortCallThreadsFiltersParams } from '../actions/setPortCallThreadsFilters';
import { paramsStatusMap } from 'store/threads/filtersSync';
import { PortCallThreadsFiltersState } from '../portCallTypes';

export const onSetPortCallThreadsFilters = (
	state: PortCallThreadsFiltersState,
	params: SetPortCallThreadsFiltersParams
): PortCallThreadsFiltersState => ({
	...state,
	...params,
	statuses: params.currentFilter
		? paramsStatusMap[params.currentFilter]
		: state.statuses
});

export const onResetPortCallThreadsFilters = (): PortCallThreadsFiltersState =>
	initialState.threadsFilters;
