import { RetrieveCompaniesConfigurationSettingsResponse } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANIES_CONFIG_SETTINGS';
export const retrieveCompaniesConfigurationSettingsAsync = actionCreator.async<
	null,
	RetrieveCompaniesConfigurationSettingsResponse,
	Error
>(ACTION_NAME);

export const retrieveCompaniesConfigurationSettings = actionCreator(
	ACTION_NAME
);
export const resetCompaniesConfigurationSettings = actionCreator(
	'RESET_COMPANIES_CONFIG_SETTINGS'
);
