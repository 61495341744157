import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { LinkJobState } from '../linkJobState';
import {
	GetLinkedCargosRequest,
	GetLinkedCargosResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { Success } from 'typescript-fsa';

function onRetrieveLinkJobCargosSuccess(
	state: LinkJobState,
	action: Success<GetLinkedCargosRequest, GetLinkedCargosResponse>
): LinkJobState {
	return {
		...state,
		...action.result
	};
}

export const onRetrieveLinkJobCargos = makeFetchStatusReducers<
	LinkJobState,
	'fetchStatuses',
	GetLinkedCargosRequest,
	GetLinkedCargosResponse
>('fetchStatuses', 'linkJob', {
	doneReducer: onRetrieveLinkJobCargosSuccess
});
