import {
	UpdateServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../servicesActionCreator';

const ACTION_NAME = 'UPDATE_SERVICE_CATEGORY';

export const updateServiceCategoryAsync = actionCreator.async<
	UpdateServiceCategoryRequest,
	ServiceCategory
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Service Category'
	},
	behaviour: {
		type: 'submit'
	}
});

export const updateServiceCategory = actionCreator<
	UpdateServiceCategoryRequest
>(ACTION_NAME);
