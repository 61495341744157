import { ThreadEventsReducerState, initialState } from '../eventsState';
import {
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse
} from 'services/api/threads/threadsServiceTypes';
import { Success } from 'typescript-fsa';
import { uniqBy, sortBy, isNumber } from 'lodash';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FetchStatus } from 'services/api/apiTypes';

const onRetrieveThreadEventsSuccess = (
	state: ThreadEventsReducerState,
	{
		params,
		result: { elements }
	}: Success<RetrieveThreadEventsRequest, RetrieveThreadEventsResponse>
): ThreadEventsReducerState => ({
	...state,
	list: sortBy(
		uniqBy([...state.list, ...elements], 'id'),
		event => event.createdOn
	),
	hasMore: isNumber(params.limit) && elements.length === params.limit
});

export const onResetThreadEvents = (state: ThreadEventsReducerState) => ({
	...state,
	list: initialState.list,
	hasMore: initialState.hasMore,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveEvents: FetchStatus.IDLE
	}
});

export const onRetrieveThreadEvents = makeFetchStatusReducers<
	ThreadEventsReducerState,
	'fetchStatuses',
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse
>('fetchStatuses', 'retrieveEvents', {
	doneReducer: onRetrieveThreadEventsSuccess,
	failedReducer: state => ({
		...state,
		hasMore: false
	})
});
