import {
	CancelCreditHubRevenuePayload,
	CancelCreditHubRevenueRequest
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'CANCEL_HUB_REVENUE_CREDIT_NOTE';

export const cancelCreditHubRevenue = actionCreator<
	CancelCreditHubRevenuePayload
>(ACTION_NAME);

export const cancelCreditHubRevenueAsync = actionCreator.async<
	CancelCreditHubRevenueRequest,
	null,
	Error
>(ACTION_NAME);
