import actionCreator from '../companyVesselsActionCreator';
import {
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';
const ACTION_NAME = 'RETRIEVE_COMPANY_VESSEL';

// TODO review
// might not used in terms of API response
export const retrieveCompanyVesselAsync = actionCreator.async<
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanyVessel = actionCreator<
	RetrieveCompanyVesselRequest
>(ACTION_NAME);
