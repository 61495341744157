import { EventListAndOpticVariablesData } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrieveEventListAndOpticVariablesSuccess = (
	state: ConfigurationState,
	{ result }: Success<null, EventListAndOpticVariablesData>
) => ({
	...state,
	triggerEvent: result.triggerEvent,
	opticVariable: result.opticVariable
});

export const onRetrieveEventListAndOpticVariables = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	null,
	EventListAndOpticVariablesData
>('fetchStatuses', 'retrieveEventListAndOpticVariables', {
	doneReducer: onRetrieveEventListAndOpticVariablesSuccess
});
