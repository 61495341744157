import React from 'react';
import { Select } from 'components/antd';
import { SelectValue } from 'antd/lib/select';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
interface EditDirectBillMultipleAutocompleteProps {
	name: string;
	label: string;
	placeholder: string;
	source: (searchParams: string) => Promise<AutocompleteOption[]>;
	required?: boolean;
	disabled?: boolean;
	help?: boolean;
	mainPrincipalId?: string;
}

interface EditDirectBillMultipleAutocompleteState {
	options: AutocompleteOption[];
	value: SelectValue;
}

export class EditDirectBillMultipleAutocomplete extends React.Component<
	EditDirectBillMultipleAutocompleteProps,
	EditDirectBillMultipleAutocompleteState
> {
	constructor(props: EditDirectBillMultipleAutocompleteProps) {
		super(props);
		this.state = {
			options: [],
			value: []
		};
	}

	onSearch = (searchParams: string) => {
		if (searchParams.length < 2) {
			return;
		}
		this.props.source(searchParams).then(response => {
			this.setState({ options: response });
		});
	};

	componentDidUpdate(prevProps: EditDirectBillMultipleAutocompleteProps) {
		if (
			prevProps.mainPrincipalId &&
			prevProps.mainPrincipalId !== this.props.mainPrincipalId
		) {
			// reset cached options when mainPrincipal is changed
			this.setState({ options: [] });
		}
	}

	// Unclear type declaration in lib. The same is done in PortJobs (page3/form)
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange = (selectedOptions: any) => {
		this.setState({ value: selectedOptions });
	};

	render() {
		return (
			<Select.ReduxFormItem
				disabled={this.props.disabled}
				filterOption={false}
				label={this.props.label}
				labelInValue
				mode="multiple"
				name={this.props.name}
				onChange={this.onChange}
				onSearch={this.onSearch}
				placeholder={this.props.placeholder}
				required={this.props.required}
				value={this.state.value}
				help={this.props.help}
				getPopupContainer={() => document.body}
			>
				{this.state.options.map(option => (
					<Select.Option key={option.id} value={option.id}>
						{option.name}
					</Select.Option>
				))}
			</Select.ReduxFormItem>
		);
	}
}
