import { DraftsState, POIDraft } from '../draftsState';
import { AddPOIDraftRequest } from 'containers/Drafts/AddPOIDraft/addPOIDraftTypes';
import { uniqBy } from 'lodash';

export const onAddPOIDraft = (
	state: DraftsState,
	{
		POIType,
		berthName: _berthName,
		rotationStepId: _rotationStepId,
		...formData
	}: AddPOIDraftRequest
): DraftsState => {
	const draftData: POIDraft = {
		...formData,
		portPOITypeCode: POIType,
		portPOITypeName: POIType
	};
	return {
		...state,
		draftsByType: {
			...state.draftsByType,
			pointOfInterest: uniqBy(
				[...state.draftsByType.pointOfInterest, draftData],
				'id'
			)
		}
	};
};
