import React, { useState, useCallback } from 'react';
import { SketchPicker } from 'react-color';
import { Popover, Button } from 'components/antd';

import { localStorageService } from 'services/storage';
import styles from './NewToolbar.module.scss';

interface ColorSelectorPopOverProps {
	color: string;
	onColorChange: (color: string) => void;
}

function ColorSelectorPopOver(props: ColorSelectorPopOverProps) {
	const DEFAULT_COLORS = ['#338adb', '#e75865', '#47b65d', '#ff8000'];
	const [visibility, setVisibility] = useState(false);
	const [color, setColor] = useState('#338adb');

	React.useEffect(() => {
		const colors = localStorageService.get('colors');
		colors &&
			colors.length <= 0 &&
			localStorageService.set('colors', DEFAULT_COLORS);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onOpenPalette = useCallback(() => {
		setVisibility(true);
	}, []);

	const onVisibleChange = (value: boolean) => {
		setVisibility(value);
		if (!value) {
			props.onColorChange(color);
			const colors = localStorageService.get('colors') || [];
			const index = colors.findIndex(col => col === color);
			if (index < 0) {
				colors.unshift(color);
				colors.pop();
			} else {
				colors.splice(index, 1);
				colors.unshift(color);
			}
			localStorageService.set('colors', colors);
		}
	};

	return (
		<Popover
			overlayClassName={styles.palettePopover}
			content={
				<SketchPicker
					onChange={color => {
						setColor(color.hex);
					}}
					color={color}
					disableAlpha={true}
					presetColors={localStorageService.get('colors') || DEFAULT_COLORS}
				/>
			}
			trigger="click"
			visible={visibility}
			placement="bottom"
			onVisibleChange={onVisibleChange}
		>
			<Button icon="color-new" iconSize="lg" onClick={onOpenPalette} />
		</Popover>
	);
}

export default ColorSelectorPopOver;
