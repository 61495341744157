import * as React from 'react';
import { AutocompleteSearch } from 'components';
import { searchTypeMap } from 'store/threads/filtersSync';
import { FilterItem } from 'store/filters/filtersState';
import { onSearch } from '../ThreadsFilters.func';
import { ThreadSearchGroupType } from 'services/api/threads/threadsServiceTypes';

interface ThreadsSearchFieldProps {
	label: string;
	onChange: (filter: FilterItem, groupType: ThreadSearchGroupType) => void;
}

export default class ThreadsSearchField extends React.Component<
	ThreadsSearchFieldProps
> {
	onChange: ThreadsSearchFieldProps['onChange'] = (filter, groupType) => {
		this.props.onChange(filter, groupType);
	};

	public render() {
		return (
			<>
				{this.props.label}
				<AutocompleteSearch
					minLength={3}
					onSearch={onSearch}
					onChange={this.onChange}
					placeholder="Search in Messages"
					typeMap={searchTypeMap}
				/>
			</>
		);
	}
}
