import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ButtonRow, Flex, PageHeader, PageFooter } from 'components';
import { AppNotifications } from 'containers';
import { Justify } from 'components/types';
import { Modal, Row, Col, Button } from 'components/antd';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';
import { notify } from 'store/notifications/actions';
import { openModal, closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import { navigateTo } from 'utils';
import EditPortJobPermissionWrapper from './EditPortJobPermissionWrapper';
import { editPortJob, retrievePortJob } from 'store/portJobs/actions';
import { destroy, isValid } from 'redux-form';

import { PORT_JOB_WIZARD_ID } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { resetDrafts } from 'store/drafts/actions';
import { getIsPortJobDataUpToDate } from 'store/portJobs/selectors';
import { refreshEditPortJob } from 'store/portJobs/actions/editPortJob';

const CANCEL_MODAL = 'edit-portJob-cancel';

interface EditPortJobRouteParams {
	portCallId: string;
	portJobCode: string;
}

interface EditPortJobRouteProps
	extends RouteComponentProps<EditPortJobRouteParams> {
	isCancelModalVisible: boolean;
	isSaveButtonDisabled: boolean;
	refreshIsVisible: boolean;
	// from mapDispatchToProps
	notifySuccess: () => void;
	openCancelModal: () => void;
	closeCancelModal: () => void;
	openRefreshModal: () => void;
	cancelPortCallEdition: () => void;
	savePortJob: () => void;
	resetDrafts: typeof resetDrafts;
	destroy: typeof destroy;
	retrievePortJob: () => void;
}

class EditPortJobRoute extends React.Component<EditPortJobRouteProps> {
	componentDidMount() {
		// PortJob status is being considered inside the EntityPermissionWrapper
		this.props.retrievePortJob();
	}

	componentWillUnmount() {
		this.props.closeCancelModal();
		this.props.resetDrafts();
		// the form should be destroyed only for editJob page, as it's independent from other creation steps
		this.props.destroy();
	}

	onCloseTab = () => {
		this.props.closeCancelModal();
	};

	onSubmit = () => {
		const { savePortJob } = this.props;
		savePortJob();
	};

	leavePortJobCreation = () => {
		if (this.props.history.action === 'PUSH') {
			this.props.history.goBack();
		} else {
			this.props.history.push('/portcalls');
		}
	};

	onConfirmCancelCreation = () => {
		this.leavePortJobCreation();
		this.props.notifySuccess();
	};

	render() {
		const {
			match: {
				params: { portCallId, portJobCode }
			},
			openCancelModal,
			isCancelModalVisible,
			closeCancelModal,
			openRefreshModal,
			isSaveButtonDisabled,
			refreshIsVisible
		} = this.props;
		return (
			<Flex fit direction="vertical" stretch>
				<PageHeader.NewTab onClose={openCancelModal} hidePopout>
					Edit Job
				</PageHeader.NewTab>
				<AppNotifications.Notification />
				<EditPortJobPermissionWrapper
					portCallId={portCallId}
					portJobCode={portJobCode}
				/>
				<Modal
					visible={isCancelModalVisible}
					cancelText={'No, return'}
					okText={'Yes, Cancel'}
					onOk={this.onConfirmCancelCreation}
					onCancel={closeCancelModal}
					closable={false}
				>
					All changes will be lost. Are you sure you want to continue?
				</Modal>
				<PageFooter>
					<Row>
						<Col xs={12} justify={Justify.END}>
							<ButtonRow>
								{refreshIsVisible && (
									<Button type="primary" transparent onClick={openRefreshModal}>
										Refresh
									</Button>
								)}
								<Button type="primary" transparent onClick={openCancelModal}>
									Cancel
								</Button>
								<MaybeTooltip
									placement="topLeft"
									show={refreshIsVisible}
									title="This Job has been updated by another user while you were editing it. You cannot save your changes due to that, please refresh the page."
								>
									<Button
										type={'primary'}
										onClick={this.onSubmit}
										disabled={isSaveButtonDisabled}
									>
										Save
									</Button>
								</MaybeTooltip>
							</ButtonRow>
						</Col>
					</Row>
				</PageFooter>
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isCancelModalVisible: isModalVisible(state, CANCEL_MODAL),
		isSaveButtonDisabled:
			!isValid(PORT_JOB_WIZARD_ID)(state) || !getIsPortJobDataUpToDate(state),
		refreshIsVisible: !getIsPortJobDataUpToDate(state)
	}),
	(
		dispatch,
		{
			match: {
				params: { portCallId, portJobCode }
			}
		}: EditPortJobRouteProps
	) => ({
		retrievePortJob: () => {
			dispatch(retrievePortJob({ portCallId, jobCode: portJobCode }));
		},
		destroy: () => {
			dispatch(destroy(PORT_JOB_WIZARD_ID));
		},
		openRefreshModal: () => {
			dispatch(refreshEditPortJob);
		},
		openCancelModal: () => {
			dispatch(openModal({ name: CANCEL_MODAL }));
		},
		closeCancelModal: () => {
			dispatch(closeModal({ name: CANCEL_MODAL }));
		},
		cancelPortCallEdition: () => {
			navigateTo(`/portcalls/${portCallId}/overview`);
		},
		savePortJob: () => {
			dispatch(editPortJob());
		},
		resetDrafts: () => {
			dispatch(resetDrafts());
		},
		notifySuccess: () => {
			dispatch(
				notify.success('Job edit process has been successfully cancelled.')
			);
		}
	})
)(EditPortJobRoute);
