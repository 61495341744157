import React from 'react';
import { Text } from 'components';

interface CategoryProps {
	name: string;
}

const Category: React.SFC<CategoryProps> = ({ name, children }) => {
	return (
		<>
			{name && (
				<Text.Box weight="bold" top bottom>
					{name}
				</Text.Box>
			)}
			{children}
		</>
	);
};

export default Category;
