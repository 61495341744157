import { isEmpty, size } from 'lodash';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { WizardFormPageState } from './wizardFormState';

import { DOCUMENT_VIEWER_WIZARD_FORM_NAME } from 'sections/DocumentViewer/DocumentViewerWizardFormTypes';

const getActiveWizardId = (state: AppState) =>
	state.wizardForm.activeWizardFormId;

export const getWizardById = (state: AppState, wizardId: string) =>
	state.wizardForm.forms[wizardId] || {};

export const getActiveWizard = createSelector(
	getActiveWizardId,
	(state: AppState) => state.wizardForm.forms,
	(id, forms) => forms[id]
);

export const getWizardActivePage = createSelector(getActiveWizard, (form):
	| number
	| null => (form ? form.activePage : null));

export const getActiveWizardTotal = createSelector(getActiveWizard, form =>
	form ? size(form.pages) : 0
);

export const getWizardActivePageById = createSelector(getWizardById, (form):
	| number
	| null => (form ? form.activePage : null));

export const getWizardActivePageState = createSelector(getWizardById, form => {
	const { activePage, pages } = form;
	return activePage && pages[activePage]
		? pages[activePage]
		: ({} as WizardFormPageState);
});

export const isWizardActivePageFirst = createSelector(getWizardById, form => {
	const { activePage, startPage } = form;
	return activePage === startPage;
});

export const isWizardActivePageLast = createSelector(getWizardById, form => {
	const { activePage, pages } = form;
	return activePage && activePage === Object.keys(pages).length;
});

export const getOriginUrl = createSelector(
	getActiveWizard,
	form => form?.originUrl
);

export const getWizardExist = createSelector(
	getWizardById,
	(wizard): boolean => !isEmpty(wizard)
);

// Custom Wizards

export const getContextualizationWizardForm = (state: AppState) =>
	getWizardById(state, DOCUMENT_VIEWER_WIZARD_FORM_NAME);
