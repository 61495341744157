import {
	retrieveThreadByIdCycleStart,
	retrieveThreadByIdCycleStop
} from '../../actions/retrieveThreadByIdCycle';
import { SagaIterator } from 'redux-saga';
import {
	all,
	call,
	cancel,
	fork,
	put,
	take,
	select,
	delay
} from 'redux-saga/effects';
import { retrieveThreadByIdAsync } from '../../actions';
import { getThreadLastConcurrencyToken } from 'store/thread/selectors';
import { getActiveThreadId } from 'store/threads/selectors';
import Api from 'services/api';
import { isEmpty } from 'lodash';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { retrieveThreadSubData } from '../retrieveThreadWithRelationsByIdSaga';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
const apiCall = Api.Threads.retrieveThreadById;

function* worker(): SagaIterator {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL); // for messages refreshing needs to be faster

		const threadId = yield select(getActiveThreadId);
		if (!threadId) {
			return;
		}
		const concurrencyToken = yield select(getThreadLastConcurrencyToken);

		try {
			const response = yield call(apiCall, {
				threadId,
				concurrencyToken
			});
			if (isEmpty(response.data)) {
				continue;
			}
			const activeJobCode = yield select(getActivePortJobCode);
			if (activeJobCode) {
				const jobs = (response.data as ThreadItem).jobCodes;
				if (!jobs.includes(activeJobCode) && activeJobCode !== 'all') {
					/* eslint-disable  no-throw-literal */
					throw Error('Something went wrong');
				}
			}
			yield all([
				put(
					retrieveThreadByIdAsync.done({
						result: response.data,
						params: { threadId, concurrencyToken },
						response
					})
				),
				call(retrieveThreadSubData, threadId)
			]);
		} catch (error) {
			yield put(
				retrieveThreadByIdAsync.failed({
					error,
					params: { threadId, concurrencyToken }
				})
			);
		}
	}
}

export default function*(): SagaIterator {
	while (yield take(retrieveThreadByIdCycleStart)) {
		yield put(
			setNotificationActionTypeToNotification([retrieveThreadByIdAsync.type])
		);
		const pollThreadsTask = yield fork(worker);
		yield take(retrieveThreadByIdCycleStop);
		yield cancel(pollThreadsTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([retrieveThreadByIdAsync.type])
		);
	}
}
