import { Action } from 'typescript-fsa';
import { put, takeLatest, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';

import { openModal } from 'store/modals/actions';
import { onAddUpdatePrincipalService } from '../actions';

import { getPrincipalServiceById } from 'store/configurationServices/selectors';

import {
	Form,
	ModalAction,
	PrincipalServicesFormData
} from '../principalServicesFormData';
import { PrincipalService } from 'services/api/services/servicesServiceTypes';

export function* worker({ payload: serviceId }: Action<string | null>) {
	let selectedService: PrincipalService | null = null;
	let modalAction = ModalAction.ADD;
	if (serviceId) {
		modalAction = ModalAction.UPDATE;
		selectedService = yield select(getPrincipalServiceById, serviceId);
		// this case musn't happen, type oriented check
		if (!selectedService) {
			return;
		}
		const data: PrincipalServicesFormData = {
			...selectedService,
			principalServiceCategoryId: selectedService.principalServiceCategory.id
		};
		yield put(initialize(Form.PRINCIPAL_SPECIFIC_SERVICES, data));
	}

	yield put(openModal({ name: modalAction }));
}

export default function*() {
	yield takeLatest(onAddUpdatePrincipalService.type, worker);
}
