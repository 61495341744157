import { isActionAsyncFailure } from 'store/utils';
import {
	HandleApiArg,
	ApiMiddlewareHandleType
} from '../../apiMiddlewareTypes';
import { createApiMiddlewareTypeHandler } from '../../apiMiddlewareUtils';
import { FormErrors, stopSubmit } from 'redux-form';
import { reject, isUndefined } from 'lodash';
import { getFormErrors } from 'utils/sagaHelpers/form/formSagaUtils';

const handler = createApiMiddlewareTypeHandler(
	ApiMiddlewareHandleType.SUBMIT,
	submitHandler
);

function submitHandler({ action }: HandleApiArg) {
	if (!isActionAsyncFailure(action)) {
		return;
	}

	const {
		payload: { params, error },
		meta
	} = action;

	if (meta?.behaviour?.type !== 'submit') {
		return;
	}
	const formName = meta?.behaviour?.formName || params.form;
	// `form` has to be provided in order to know which one to `submit`
	if (formName) {
		const unpackedErrors = error.response
			? getFormErrors(error.response.data)
			: {};

		let result: FormErrors = {};

		if (unpackedErrors.errorCode) {
			result._error = unpackedErrors.error;
			if (unpackedErrors.correlationId) {
				result._error += ` Correlation Id: ${unpackedErrors.correlationId}`;
			}
		} else {
			result = {
				...unpackedErrors,
				// form level errors, not related to a specific field
				_error:
					reject(
						[unpackedErrors.error, unpackedErrors.validationError],
						isUndefined
					).join(',') || undefined
			};
		}

		return stopSubmit(formName, result);
	}

	return;
}

export default handler;
