import actionCreator from 'store/finance/financeActionCreator';
import {
	ConvertDBStatusRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
const ACTION_NAME = 'CONVERT_TO_REGULAR';

export const convertToRegular = actionCreator<{
	jobServiceId: string;
	comment: string;
}>(ACTION_NAME);

export const convertToRegularAsync = actionCreator.async<
	ConvertDBStatusRequest,
	PortJobService,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Service'
	}
});
