import React from 'react';
import { isFunction } from 'lodash';
import { ListProps } from 'antd/lib/list';
import { Flex, Text } from 'components';
import { Justify } from 'components/types';
import { Popover, Icon, Tooltip } from 'components/antd';
import { PopoverProps } from 'antd/lib/popover';
import classNames from 'classnames';
import styles from './SearchablePopover.module.scss';
import { IconType } from 'components/antd/Icon/IconTypes';
import { SearchablePopoverDefaultItem } from './searchablePopoverTypes';
import SearchablePopoverContent from './SearchablePopoverContent';
import { KeyboardCode } from 'app-constants';

export interface SearchablePopoverProps<
	Item extends SearchablePopoverDefaultItem = SearchablePopoverDefaultItem
> {
	placeholder?: string;
	listLocaleProps?: ListProps<Item>['locale'];
	data: Item[];
	loading: boolean;
	loadingType?: LoadingType;
	showResults?: boolean;
	hasSearchField?: boolean;
	disableTooltip?: (item: Item) => string;
	disableIcon?: keyof IconType;
	hideAfterSelect?: boolean;
	title?: string | React.ReactNode;
	bordered?: boolean;
	placement?: PopoverProps['placement'];
	setStateOnPopupVisible?: boolean;
	onChange?: (value: string) => void;
	renderItem?: (item: Item) => React.ReactNode;
	onSelect?: (item: Item) => void;
	onHideServices?: () => void;
	onVisibleChange?: (visible: boolean) => void;
}

interface SearchablePopoverState<Item> {
	data: Item[];
	visible: boolean;
	searchTerm: string;
}

export enum LoadingType {
	Full,
	Input
}

class SearchablePopover<
	Item extends SearchablePopoverDefaultItem
> extends React.Component<
	SearchablePopoverProps<Item>,
	SearchablePopoverState<Item>
> {
	el: HTMLDivElement;

	position = 0;
	constructor(props: SearchablePopoverProps<Item>) {
		super(props);

		this.state = {
			searchTerm: '',
			data: props.data,
			visible: false
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll, true);
	}

	componentDidUpdate(prevProps: SearchablePopoverProps<Item>) {
		// relying on check by reference
		// considering that the component may be permanently mounted and the data may be re-fetched at some point(s)
		if (prevProps.data !== this.props.data) {
			this.setState({
				data: this.props.data
			});
		}

		if (
			prevProps.setStateOnPopupVisible !== this.props.setStateOnPopupVisible
		) {
			this.setState({
				data: this.props.data
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScroll, true);
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	onScroll = (e: any) => {
		const scrollTop = Math.round(e.target.scrollTop);
		if (scrollTop === this.position || this.position === 0) {
			// Ignore phantom scroll events which doesn't really scroll
			this.position = scrollTop;
			return;
		}
		this.position = scrollTop;
		if (this.state.visible && (!e.target.dataset || !e.target.dataset.list)) {
			this.hidePopover();
		}
	};

	onChange = ({
		currentTarget: { value }
	}: React.ChangeEvent<HTMLInputElement>) => {
		const { onChange, data } = this.props;

		if (onChange) {
			onChange(value);
			this.setState({ searchTerm: value });
			return;
		}
		this.filterItems(data, value); // default behavior with local filtering
	};

	onKeyDown = (event: KeyboardEvent) => {
		const { keyCode } = event;
		if (keyCode === KeyboardCode.ESC) {
			this.hidePopover();
		}
	};

	filterItem(inputValue: string, item: Item) {
		const origin = item.name.toLocaleLowerCase();
		const target = inputValue.toLocaleLowerCase();
		return origin.includes(target);
	}

	filterItems(items: Item[], inputValue: string) {
		this.setState({
			data: items.filter(item => this.filterItem(inputValue, item)),
			searchTerm: inputValue
		});
	}

	onSelect = (item: Item) => {
		const { hideAfterSelect = false, onSelect } = this.props;
		if (item.disabled) {
			return;
		}
		if (hideAfterSelect) {
			this.hidePopover();
		}
		if (onSelect) {
			onSelect(item);
		}
	};

	hidePopover = () => {
		this.setState({ visible: false, searchTerm: '' });
		if (isFunction(this.props.onHideServices)) {
			this.props.onHideServices();
		}
		document.removeEventListener('keydown', this.onKeyDown);
	};

	handleVisibleChange = (visible: boolean) => {
		this.setState({
			visible,
			searchTerm: !visible ? '' : this.state.searchTerm
		});
		document.addEventListener('keydown', this.onKeyDown);
		if (this.props.onVisibleChange) {
			this.props.onVisibleChange(visible);
		}
	};

	getDisableTooltip = (item: Item) => {
		const { disableTooltip } = this.props;
		if (!disableTooltip) {
			return;
		}
		if (typeof disableTooltip === 'string') {
			return disableTooltip;
		}
		return disableTooltip(item);
	};

	/**
	 * Render item in the list. Show `i` icon with Tooltip if needed.
	 * Tooltip isn't rendered locally inside the item due to the reason,
	 * that the whole list must be verically scrollable, tooltip may overlap
	 * available height that may lead to incorrent positioning.
	 * Tooltip container is defined to be popover wrapper
	 */
	renderItem = (item: Item) => {
		const {
			disableTooltip,
			loadingType = LoadingType.Input,
			loading,
			disableIcon
		} = this.props;
		const hasTooltip = item.disabled && disableTooltip;
		const rowClassName = classNames(styles.rowItem, {
			[styles.disabledRow]:
				item.disabled || (loading && loadingType === LoadingType.Input)
		});
		return (
			<Flex
				fit
				direction="horizontal"
				justify={Justify.BETWEEN}
				className={rowClassName}
			>
				<Text>
					{disableIcon && item.disabled && (
						<Icon type={disableIcon} color="primary" />
					)}{' '}
					{item.name}
				</Text>
				{hasTooltip && (
					<Tooltip
						title={this.getDisableTooltip(item)}
						trigger="hover"
						getPopupContainer={() => this.el}
					>
						<Icon type="info-circle" color="primary" />
					</Tooltip>
				)}
			</Flex>
		);
	};

	setPopoverContentElRef = (el: HTMLDivElement) => {
		if (el) {
			this.el = el;
		}
	};

	render() {
		const {
			loading,
			loadingType,
			placeholder,
			title,
			hasSearchField = true,
			placement,
			showResults = true
		} = this.props;
		return (
			<div ref={this.setPopoverContentElRef}>
				<Popover
					title={title}
					content={
						<SearchablePopoverContent
							visible={this.state.visible}
							listLocaleProps={this.props.listLocaleProps}
							loading={loading}
							loadingType={loadingType}
							searchTerm={this.state.searchTerm}
							data={this.state.data}
							showResults={showResults}
							hasSearchField={hasSearchField}
							placeholder={placeholder}
							renderItem={this.props.renderItem || this.renderItem}
							onSelect={this.onSelect}
							onChange={this.onChange}
						/>
					}
					trigger="click"
					overlayClassName={styles.root}
					visible={this.state.visible}
					placement={placement}
					onVisibleChange={this.handleVisibleChange}
				>
					{this.props.children}
				</Popover>
			</div>
		);
	}
}

export default SearchablePopover;
