import actionCreator from '../portCallOperationsActionCreator';
import { UpdatePortCallEventJobAssignmentRequest } from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'UPDATE_PORTCALL_EVENT_JOB_ASSIGNMENT';

export type UpdatePortCallEventJobAssignmentPayload = Pick<
	UpdatePortCallEventJobAssignmentRequest,
	'eventId' | 'portJobs'
>;

export const updatePortCallEventJobAssignmentAsync = actionCreator.async<
	UpdatePortCallEventJobAssignmentPayload,
	void,
	Error
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updatePortCallEventJobAssignment = actionCreator<
	UpdatePortCallEventJobAssignmentPayload
>(ACTION_NAME);
