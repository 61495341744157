import { put, select } from 'redux-saga/effects';
import {
	RetrievePortCallRequest,
	PortCall
} from 'services/api/portCalls/portCallsServiceTypes';
import {
	retrievePortCallAsync,
	retrievePortCall
} from '../actions/retrievePortCall';
import Api from 'services/api';
import { setPortCallUpToDate } from 'store/portCall/actions/setPortCallUpToDate';
import { getIsAgentInvited } from 'store/portJobs/portJobsSelectors';
import { setPortJobContext } from 'store/portJobs/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { PortJobStatus, JobType } from 'store/portJobs/constants';
import { getBreadcrumbs } from 'store/navigation/navigationSelectors';
import { BreadcrumbItem } from 'store/navigation/navigationState';

export const retrievePortCallWatcher = makeTakeLatestWatcher<
	RetrievePortCallRequest,
	PortCall,
	Error
>({
	api: Api.PortCalls.retrievePortCall,
	async: retrievePortCallAsync,
	*onSuccess({ result }) {
		yield put(setPortCallUpToDate(true));

		const breadcrumbs = yield select(getBreadcrumbs);
		const isAgentInvited = yield select(getIsAgentInvited);

		const isCurrentTabPortcallOverview = breadcrumbs.some(
			(breadcrumb: BreadcrumbItem) => breadcrumb.title === 'Overview'
		);

		if (!isCurrentTabPortcallOverview) {
			yield put(
				setPortJobContext({
					isAgentInvited: false
				})
			);
			return;
		}

		const isRefreshRequired = result.jobs.some(
			job =>
				job.jobType !== JobType.APPOINTMENT &&
				job.status === PortJobStatus.EXECUTION &&
				!job.financeStatus &&
				isAgentInvited
		);

		if (isRefreshRequired) {
			yield put(
				retrievePortCall({
					id: result.id,
					expand: 'PortJobsBasic',
					isSilent: false
				})
			);
		} else {
			yield put(
				setPortJobContext({
					isAgentInvited: false
				})
			);
		}
	}
});
