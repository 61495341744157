import * as React from 'react';
import classNames from 'classnames';
import { indexOf, find } from 'lodash';
import styles from './WizardProgress.module.scss';
import WizardProgressItem, { WizardProgressStep } from './WizardProgressItem';

interface WizardProgressProps {
	activeKey: string;
	steps: WizardProgressStep[];
	vertical?: boolean;
	showCompleted?: boolean;
	className?: string;
}
const WizardProgress: React.FC<WizardProgressProps> = ({
	steps,
	activeKey,
	vertical,
	className,
	showCompleted
}) => {
	return (
		<ul
			className={classNames(styles.root, className, {
				[styles.vertical]: vertical
			})}
		>
			{steps.map((step, index) => {
				const currentIndex = indexOf(steps, step);
				const activeIndex = indexOf(
					steps,
					find(steps, { key: activeKey }) || steps[activeKey]
				);
				const completed = Boolean(showCompleted) && currentIndex < activeIndex;
				return (
					<WizardProgressItem
						key={index}
						step={step}
						active={index === activeIndex}
						completed={completed}
					/>
				);
			})}
		</ul>
	);
};

export default WizardProgress;
