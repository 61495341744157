import { Success, Failure } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrievePortCallSectionsRequest,
	RetrievePortCallSectionsResponse,
	PortCallSection
} from 'services/api/portCall/portCallServiceTypes';
import { PortCallOperationsState } from '../portCallOperationsState';
import { getPortCallEventsFlatMapChanges } from './retrievePortCallEvents/getPortCallEventsFlatMap';
import getEventIdsBySectionId from './retrievePortCallEvents/getPortCallEventIdsBySectionId';

const getSetUpdateStatuses = (
	state: PortCallOperationsState,
	eventId: string,
	status: FetchStatus
) => {
	return {
		...state.updateStatuses,
		events: {
			...state.updateStatuses.events,
			[eventId]: status
		}
	};
};

const getFetchStatuses = (
	{ fetchStatuses }: PortCallOperationsState,
	{ portCallId, isSilent }: RetrievePortCallSectionsRequest,
	status: FetchStatus
): PortCallOperationsState['fetchStatuses'] => {
	return isSilent
		? fetchStatuses
		: {
				...fetchStatuses,
				eventSectionsByPortCallId: {
					...fetchStatuses.eventSectionsByPortCallId,
					[portCallId]: status
				}
		  };
};

export const onRetrievePortCallSectionsStarted = (
	state: PortCallOperationsState,
	params: RetrievePortCallSectionsRequest
): PortCallOperationsState => ({
	...state,
	fetchStatuses: getFetchStatuses(state, params, FetchStatus.PENDING)
});

export const onRetrievePortCallSectionsSuccess = (
	state: PortCallOperationsState,
	{
		params,
		result
	}: Success<RetrievePortCallSectionsRequest, RetrievePortCallSectionsResponse>
): PortCallOperationsState => {
	const eventsById =
		(state.eventSectionsByPortCallId[params.portCallId] &&
			state.eventSectionsByPortCallId[params.portCallId].eventsById) ||
		{};
	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[params.portCallId]: {
				...state.eventSectionsByPortCallId[params.portCallId],
				sections: keyBy<PortCallSection>(result, item => item.code),
				eventsById: {
					...eventsById,
					...getPortCallEventsFlatMapChanges(result, eventsById)
				},
				eventsIdsBySectionId: getEventIdsBySectionId(result)
			}
		},
		fetchStatuses: getFetchStatuses(state, params, FetchStatus.SUCCESS),
		updateStatuses: params.eventId
			? getSetUpdateStatuses(state, params.eventId, FetchStatus.SUCCESS)
			: state.updateStatuses
	};
};

export const onRetrievePortCallSectionsFailed = (
	state: PortCallOperationsState,
	{ params }: Failure<RetrievePortCallSectionsRequest, Error>
): PortCallOperationsState => ({
	...state,
	fetchStatuses: getFetchStatuses(state, params, FetchStatus.FAILURE)
});

export const onResetPortCallSections = (
	state: PortCallOperationsState,
	portCallId: string
): PortCallOperationsState => ({
	...state,
	eventSectionsByPortCallId: {
		...state.eventSectionsByPortCallId,
		[portCallId]: {
			sections: {},
			eventsById: {},
			eventsIdsBySectionId: {}
		}
	},
	fetchStatuses: getFetchStatuses(state, { portCallId }, FetchStatus.IDLE)
});
