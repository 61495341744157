import React, { useCallback } from 'react';
import styles from './Alerts.module.scss';

import { Alert } from 'components/antd';
import ErrorCorrelationId from 'components/ErrorPage/ErrorCorrelationId';
import {
	NotificationItem,
	NotificationDisplayType
} from 'store/notifications/notificationsState';
import { useNotifications } from '../Notifications.hooks';

interface AlertsBaseProps {
	onRemoveNotification: (uuid: string) => void;
}

type AlertItemProps = AlertsBaseProps & {
	item: NotificationItem;
};

function AlertItem({ item, onRemoveNotification }: AlertItemProps) {
	const onRemove = useCallback(() => {
		onRemoveNotification(item.uuid);
	}, [item.uuid, onRemoveNotification]);

	const message = !item.error ? (
		item.description
	) : (
		<>
			{item.description}
			<ErrorCorrelationId correlationId={item.error.correlationId} />
		</>
	);
	return (
		<Alert
			key={item.uuid}
			message={<p>{message}</p>}
			onClose={onRemove}
			type={item.type}
			showIcon
			closable
		/>
	);
}

function Alerts() {
	const { list, onRemoveNotification } = useNotifications({
		displayType: NotificationDisplayType.ALERT
	});

	if (!list.length) {
		return null;
	}

	return (
		<div className={styles.root}>
			{list.map((item: NotificationItem) => (
				<AlertItem item={item} onRemoveNotification={onRemoveNotification} />
			))}
		</div>
	);
}

export default Alerts;
