import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { PreFundingRequestsState } from '../financeFundingsState';
import {
	RetrievePrefundingRequest,
	RetrievePrefundingRequestResponse,
	PrefundingRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

const onRetrievePrefundingRequestSuccess = (
	state: PreFundingRequestsState,
	{
		result
	}: Success<RetrievePrefundingRequest, RetrievePrefundingRequestResponse>
): PreFundingRequestsState => {
	const newPrefunding = { ...result } as PrefundingRequest;

	if (!newPrefunding) {
		return state;
	}
	return {
		...state,
		prefunding: newPrefunding,
		...state.fetchStatuses,
		fetchStatuses: {
			retrievePreFundingRequest: FetchStatus.SUCCESS,
			sendPrefundingsRequest: FetchStatus.IDLE
		}
	};
};

export const onRetrievePrefundingRequest = makeFetchStatusReducers<
	PreFundingRequestsState,
	'fetchStatuses',
	RetrievePrefundingRequest,
	RetrievePrefundingRequestResponse
>('fetchStatuses', 'retrievePreFundingRequest', {
	doneReducer: onRetrievePrefundingRequestSuccess
});
