/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext } from 'react';
import { DrawingMode } from './Components/ImageViewer/ImageViewerV2Types';

export interface DocumentContextActions {
	onUndo: () => void;
	onSave: () => void;
	onModeChange: () => void;
	onUploadAnnotation: () => void;
}

export interface DocumentContextValue {
	mode: string;
	scale: number;
	color: string;
	isAnnotated: boolean;
	isHistoryEmpty: boolean;
	isDocumentPreparing: boolean;
	actions: DocumentContextActions;
	setActions: (actions: DocumentContextActions) => void;
	setMode: (mode: DrawingMode) => void;
	setScale: (scale: number) => void;
	setColor: (color: string) => void;
	setIsAnnotated: (isAnnotated: boolean) => void;
	setIsHistoryEmpty: (isHistoryEmpty: boolean) => void;
	setIsDocumentPreparing: (isDocumentPreparing: boolean) => void;
}

const initialValues = {
	mode: DrawingMode.LINE,
	scale: 1,
	color: '#338adb',
	isAnnotated: false,
	isHistoryEmpty: true,
	isDocumentPreparing: false,
	actions: {
		onUndo: () => {},
		onSave: () => {},
		onModeChange: () => {},
		onUploadAnnotation: () => {}
	},
	setActions: () => {},
	setMode: () => {},
	setScale: () => {},
	setColor: () => {},
	setIsAnnotated: () => {},
	setIsHistoryEmpty: () => {},
	setIsDocumentPreparing: () => {}
};

export const UseDocumentContext = createContext<DocumentContextValue>(
	initialValues
);

const DocumentProvider: React.FC = ({ children }) => {
	const [mode, setMode] = React.useState(DrawingMode.LINE);
	const [scale, setScale] = React.useState(1);
	const [color, setColor] = React.useState('#338abd');
	const [isAnnotated, setIsAnnotated] = React.useState(false);
	const [isHistoryEmpty, setIsHistoryEmpty] = React.useState(true);
	const [isDocumentPreparing, setIsDocumentPreparing] = React.useState(false);
	const [actions, setActions] = React.useState({
		onUndo: () => {},
		onSave: () => {},
		onModeChange: () => {},
		onUploadAnnotation: () => {}
	});

	return (
		<UseDocumentContext.Provider
			value={{
				mode,
				scale,
				color,
				actions,
				isAnnotated,
				isHistoryEmpty,
				isDocumentPreparing,
				setActions,
				setMode,
				setColor,
				setScale,
				setIsAnnotated,
				setIsHistoryEmpty,
				setIsDocumentPreparing
			}}
		>
			{children}
		</UseDocumentContext.Provider>
	);
};

class DocumentContext extends React.Component {
	static Consumer = UseDocumentContext.Consumer;
	static Provider = DocumentProvider;

	render() {
		return null;
	}
}

export default DocumentContext;
