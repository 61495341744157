import {
	takeEvery,
	take,
	put,
	race,
	call,
	fork,
	cancel
} from 'redux-saga/effects';
import {
	redirectToVP,
	retrieveActiveSectionAsync,
	retrieveActiveSection,
	startVPAlertResolving,
	VesselProgrammeDashboardAlertResolvingStartPayload
} from '../actions';
import { Action, ActionCreator, AsyncActionCreators } from 'typescript-fsa';
import { AlertMetricCode } from 'app-constants';
import { DASHBOARD_COMMIT_MODAL, DASHBOARD_TRACK_MODAL } from '../constants';
import {
	openModal,
	closeModal,
	closeAllModals,
	CloseModalParams
} from 'store/modals/actions';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';
import {
	commitVP,
	trackVP,
	commitVPAsync,
	trackVPAsync
} from 'store/vesselProgramme/actions';
import {
	removeVPAlertFromPendingList,
	addVPAlertToPendingResolveList
} from 'store/vesselProgramme/actions/pendingAlerts';
import { changeRoute } from 'store/route/actions';
import { notify } from 'store/notifications/actions';
import { vesselProgrammePendingAlertsPolling } from 'store/vesselProgramme/sagas/pollingVPAlertsSaga';

const modalNameByCode = (alertCode: AlertMetricCode): string =>
	({
		[AlertMetricCode.VESSEL_PROGRAMME_COMMIT]: DASHBOARD_COMMIT_MODAL,
		[AlertMetricCode.VESSEL_PROGRAMME_ON_TRACK]: DASHBOARD_TRACK_MODAL
	}[alertCode]);

const actionByCode = (alertCode: AlertMetricCode): ActionCreator<string> =>
	({
		[AlertMetricCode.VESSEL_PROGRAMME_COMMIT]: commitVP,
		[AlertMetricCode.VESSEL_PROGRAMME_ON_TRACK]: trackVP
	}[alertCode]);

const asyncActionByCode = (
	alertCode: AlertMetricCode
): AsyncActionCreators<string, null, Error> =>
	({
		[AlertMetricCode.VESSEL_PROGRAMME_COMMIT]: commitVPAsync,
		[AlertMetricCode.VESSEL_PROGRAMME_ON_TRACK]: trackVPAsync
	}[alertCode]);

export function* dashboardVPAlertsWorker(
	portCallId: string,
	alertId: string,
	modalName: string,
	action: ActionCreator<string>,
	asyncAction: AsyncActionCreators<string, null, Error>
) {
	yield put(openModal(modalName));
	const {
		redirect,
		close
	}: {
		redirect: Action<string>;
		close: Action<CloseModalParams>;
	} = yield race({
		redirect: take(redirectToVP.type),
		close: take(closeModal.type)
	});

	if (redirect) {
		yield call(navigateTo, `${PATHS.portCalls}/${portCallId}/vessel-programme`);
		yield put(closeAllModals());
		return;
	}
	// closed modal by cancel or confirm
	if (!close.payload.isConfirmed) {
		return;
	}
	// it is confirm
	yield put(action(portCallId));
	yield put(addVPAlertToPendingResolveList(alertId));

	const { failed }: { failed: Action<string> } = yield race({
		_: take(asyncAction.done.type),
		failed: take(asyncAction.failed.type)
	});

	if (failed) {
		yield put(removeVPAlertFromPendingList(alertId));
		yield put(notify.warning('Alert was not resolved.'));
		return;
	}
	yield call(vesselProgrammePendingAlertsPolling, portCallId, alertId);

	yield put(retrieveActiveSection());

	yield race({
		done: take(retrieveActiveSectionAsync.done),
		failed: take(retrieveActiveSectionAsync.failed)
	});

	yield put(removeVPAlertFromPendingList(alertId));
}

export default function* watchDashboardVPAlertsResolve() {
	yield takeEvery(startVPAlertResolving.type, function*({
		payload: { alertCode, portCallId, alertId }
	}: Action<VesselProgrammeDashboardAlertResolvingStartPayload>) {
		const task = yield fork(
			dashboardVPAlertsWorker,
			portCallId,
			alertId,
			modalNameByCode(alertCode),
			actionByCode(alertCode),
			asyncActionByCode(alertCode)
		);
		yield take(changeRoute);
		yield put(removeVPAlertFromPendingList(alertId));
		yield cancel(task);
	});
}
