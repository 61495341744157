import { Action } from 'typescript-fsa';
import { call, select, takeLatest, put } from 'redux-saga/effects';
import { PATHS } from 'sections/App/RouteParams';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { navigateTo } from 'utils';
import { uploadDocumentDone, postCreditUploadDocumentDone } from '../actions';
import { getActiveSupplierInvoiceId } from '../selectors';

export const getWorker = (isPostCredit?: boolean) =>
	function*({
		payload: {
			document: { id },
			isDoneSuccess
		}
	}: Action<UploadDocumentDoneResponse>) {
		const activeInvoiceId = yield select(getActiveSupplierInvoiceId);

		if (isDoneSuccess) {
			yield call(
				navigateTo,
				`/${PATHS.supplierinvoices}/documents/${id}/contextualize${
					isPostCredit ? `-via-post-credit/${activeInvoiceId}` : ''
				}`
			);

			// Reset state
			yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
		}
	};

export default function*() {
	yield takeLatest(uploadDocumentDone.type, getWorker());
	yield takeLatest(postCreditUploadDocumentDone.type, getWorker(true));
}
