import * as React from 'react';
import { isNumber, isString } from 'lodash';
import classNames from 'classnames';
import styles from './Icon.module.scss';

import AntIcon, { IconProps as AntIconProps } from 'antd/lib/icon';
import { IconType } from './IconTypes';
import { IconMap } from 'icons/Icons';
import { IconColor, Size } from 'components/types';

export type IconSize = 'xxs' | Size | 'xl' | number;

export type IconProps = AntIconProps & {
	type: keyof IconType;
	size?: IconSize;
	color?: IconColor;
	offset?: 'left' | 'right';
	valign?: 'middle' | 'baseline';
};

export const Icon: React.FC<IconProps> = ({
	color,
	offset,
	valign = 'middle',
	className,
	size,
	...props
}) => {
	const iconColors = styles[color + 'Color'];
	const iconOffset = {
		[styles.offsetRight]: offset === 'right',
		[styles.offsetLeft]: offset === 'left'
	};
	const iconVAlign = {
		[styles.valignMiddle]: valign === 'middle'
	};
	const iconSize = styles[isString(size) ? size : ''];

	return (
		<AntIcon
			{...props}
			className={classNames(
				styles.root,
				className,
				iconSize,
				iconColors,
				iconOffset,
				iconVAlign,
				{ [styles.clickable]: Boolean(props.onClick) }
			)}
			component={IconMap[props.type]}
			style={{
				...props.style,
				...(isNumber(size) ? { fontSize: `${size}px` } : {})
			}}
		/>
	);
};

export default Icon;
