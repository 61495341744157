import { groupBy, keys, reduce, map, isFunction } from 'lodash';
import { FilterItem } from 'store/filters/filtersState';

/**
 * Extract search values from the provided Filters and returns
 * an object mapping the selected { Key: Values[] }
 */
export function extractSearchParams(
	filterTypeMap: {},
	filter?: FilterItem[],
	filterIdentifiers?: {}
) {
	const byGroupType = groupBy(filter, 'type');
	const groupTypes = keys(byGroupType);
	// map all filter objects to collections of their corresponding filter parameter and filter value
	const extractedParameters = reduce(
		groupTypes,
		(result, groupType) => {
			const key = filterTypeMap[groupType];
			if (!key) {
				throw Error(
					`There's no such GroupType in FilterTypeMap. Ensure that there's a case for every value in your filter map object`
				);
			}
			if (filterIdentifiers && !filterIdentifiers[groupType]) {
				throw Error(
					`There's no such GroupType in the given FilterIdentifiers. Ensure that there's a case for every value in your filter map object`
				);
			}
			const identifier = filterIdentifiers
				? filterIdentifiers[groupType]
				: 'key';

			return {
				...result,
				[key]: map(byGroupType[groupType], identifier)
			};
		},
		{}
	);

	return extractedParameters;
}

type CallbackResultType<R> = (filter: FilterItem[]) => R;
/**
 * Check if the provided filter exist and if so, returns the result
 */
export const filterExist = <R extends {}>(
	filter: FilterItem[],
	result: R | CallbackResultType<R>
) => {
	if (!filter) {
		return {};
	}
	if (isFunction(result)) {
		return result(filter);
	}
	return result;
};
