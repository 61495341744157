import {
	FinanceRequest as request,
	RetrieveAvailableSectionCodeResponse as response
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_AVAILABLE_SECTIONS = 'RETRIEVE_AVAILABLE_SECTIONS';
export const retrieveAvailableSectionCodeAsync = actionCreator.async<
	request,
	response,
	Error
>(RETRIEVE_AVAILABLE_SECTIONS);

export const retrieveAvailableSectionCode = actionCreator<request>(
	RETRIEVE_AVAILABLE_SECTIONS
);
