import {
	RetrievePortCallSectionsRequest,
	RetrievePortCallSectionsResponse
} from 'services/api/portCall/portCallServiceTypes';
import actionCreator from '../portCallOperationsActionCreator';

const RETRIEVE_SECTIONS_ACTION = 'RETRIEVE_SECTIONS';

export const retrievePortCallSectionsAsync = actionCreator.async<
	RetrievePortCallSectionsRequest,
	RetrievePortCallSectionsResponse,
	Error
>(RETRIEVE_SECTIONS_ACTION);

export const retrievePortCallSections = actionCreator<
	RetrievePortCallSectionsRequest
>(RETRIEVE_SECTIONS_ACTION);

const RESET_SECTIONS_ACTION = 'RESET_SECTIONS';

export const resetPortCallSections = actionCreator<string>(
	RESET_SECTIONS_ACTION
);
