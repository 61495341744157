import { FetchStatus } from 'services/api/apiTypes';
import { PortJobState } from './../portJobsState';
export function onAddPortJobOperationStarted(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}
export function onAddPortJobOperationSuccess(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
}
export function onAddPortJobOperationFailed(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}
