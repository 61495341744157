import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading } from 'store/selectors';
import { forIn, get } from 'lodash';
import {
	Group,
	GroupTypeCodes,
	GroupPermissionScopeAccessTypes
} from 'services/api/groups/groupsServiceTypes';
import {
	getScopeType,
	getCurrentPermission,
	isVisibleTab
} from 'store/groups/groupsPermissionScopeSelectors';
import { getFilterValues } from 'store/filters/filtersSelectors';
import { getCompaniesForUserSelector } from 'store/companies/selectors';
import { getUserCompanyId, getUserGroupType } from 'store/auth/selectors';
import {
	FORMS,
	PermissionScopeTabsKeys
} from 'sections/Groups/Group/EditGroup/constants';
import { formInitialValues } from 'sections/Groups/Group/EditGroup/Permissions/Permissions.func';
import { GroupsFiltersParam } from './filtersSync';

export const getGroupsMapSelector = (state: AppState) =>
	state.groups.groups.byId;

export const isGroupsFetchingSelector = (state: AppState) =>
	getIsLoading(state.groups.groups.fetchStatuses.all);
export const groupsHasMoreSelector = (state: AppState) =>
	state.groups.groups.hasMore;

export const getInitializeEditGroupFetchStatus = (state: AppState) =>
	state.groups.groups.fetchStatuses.initializeEditGroup;

export const getAllGroupsSelector = createSelector(getGroupsMapSelector, byId =>
	Object.values(byId)
);

export const getGroupById = (state: AppState, groupId: string): Group => {
	return state.groups.groups.byId[groupId];
};

export const getOpenedGroup = (state: AppState): Group | null => {
	return state.groups.groups.context.openedGroup;
};

export const getIsGroupsFetching = (state: AppState) =>
	getIsLoading(state.groups.groups.fetchStatuses.all);

export const getGroupAddingFetchStatus = (state: AppState) =>
	state.groups.groups.fetchStatuses.adding;

export const getGroupDeletingFetchStatus = (state: AppState) =>
	state.groups.groups.fetchStatuses.deleting;

export const isGroupAddingSelector = (state: AppState): boolean =>
	getIsLoading(state.groups.groups.fetchStatuses.adding);

export const isGroupUpdatingSelector = (state: AppState): boolean =>
	getIsLoading(state.groups.groups.fetchStatuses.updating);

export const isGroupHubOrManageOrCluster = createSelector(
	getGroupById,
	group =>
		GroupTypeCodes.ISS_HUB === group.groupType.code ||
		GroupTypeCodes.ISS_MANAGE === group.groupType.code ||
		GroupTypeCodes.ISS_CLUSTER === group.groupType.code
);

export const getGroupPermissionScopeSelector = (
	state: AppState
): { [type: string]: string[] } => ({
	companies: Object.keys(
		get(state, ['form', 'PermissionScopeForm', 'values'], {})
	)
});

export const getGroupsCount = createSelector(
	getAllGroupsSelector,
	groups => groups.length
);

export const getErrorWorkingOnGroups = (state: AppState) =>
	state.groups.groups.error;

const getPermissionScopeFormValues = (group: Group, tabKey: string) => {
	const permission = getCurrentPermission(
		group.dataPermissions,
		getScopeType(tabKey, group.applicableScopeTypes)
	);

	return {
		permissionScopeType: permission
			? permission.accessType
			: GroupPermissionScopeAccessTypes.SPECIFIC,
		values: permission ? permission.permissions : []
	};
};

export const getFormsInitialValue = (group: Group | null) => {
	const forms = {};

	if (!group) {
		return forms;
	}

	forms[FORMS.GeneralInfoForm] = {
		...forms[FORMS.GeneralInfoForm],
		id: group.id,
		name: group.name,
		isMessagingGroup: group.isMessagingGroup,
		/**
		 * It's a hack for RTE, because its default value couldn't be empty, so we set it right away
		 * This way we prevent immediate form changing after initialization
		 */
		groupSignature: group.groupSignature || `<div><br/></div>`,
		groupType: group.groupType.code
	};
	forms[FORMS.PermissionsForm] = {
		...formInitialValues(group.functionalPermissions)
	};

	forms[FORMS.PermissionScopeForm] = {};
	forIn(PermissionScopeTabsKeys, tabKeyValue => {
		if (isVisibleTab(group.applicableScopeTypes, tabKeyValue)) {
			forms[FORMS.PermissionScopeForm][
				tabKeyValue
			] = getPermissionScopeFormValues(group, tabKeyValue);
		}
	});

	return forms;
};

const defaultFilters = {
	[GroupsFiltersParam.SEARCH_TERM]: [],
	[GroupsFiltersParam.COMPANY]: [],
	[GroupsFiltersParam.GROUP_TYPE]: [
		{
			key: 'All',
			label: 'All',
			type: GroupsFiltersParam.GROUP_TYPE
		}
	]
};

export const getDefaultCompanyForGroupsFilters = createSelector(
	getCompaniesForUserSelector,
	getUserCompanyId,
	(companies, userCompanyId) => {
		const company = companies.find(company => company.id === userCompanyId);
		return company;
	}
);

export const getGroupsFilters = createSelector(
	getDefaultCompanyForGroupsFilters,
	getFilterValues,
	getUserGroupType,
	(defaultCompany, filters, userGroup) => ({
		...defaultFilters,
		...(defaultCompany
			? {
					[GroupsFiltersParam.COMPANY]:
						userGroup !== GroupTypeCodes.ISS_ADMIN
							? [
									{
										key: defaultCompany.id,
										label: defaultCompany.companyName,
										type: GroupsFiltersParam.COMPANY
									}
							  ]
							: []
			  }
			: {}),
		...(userGroup === GroupTypeCodes.CUSTOMER
			? {
					[GroupsFiltersParam.GROUP_TYPE]: [
						{
							key: 'Principal',
							label: 'Principal',
							type: GroupsFiltersParam.GROUP_TYPE
						}
					]
			  }
			: {}),
		...filters
	})
);

export const getThirdPartyLPAGroupsList = (state: AppState) =>
	state.groups.groups.thirdPartyLPAGroups;

export const retrieveThirdPartyLPAGroupsFetchStatus = (state: AppState) =>
	state.groups.groups.fetchStatuses.retrieveThirdPartyLPAGroups;
