import * as React from 'react';
import { Status, Flex } from 'components';
import { Col } from 'components/antd';
import styles from './MessagesThreadHeader.module.scss';
import threadStyles from 'sections/Thread/Thread.module.scss';
import {
	ThreadItem,
	ThreadStatus
} from 'services/api/threads/threadsServiceTypes';
import {
	getIsCurrentUserAssignedToActiveThread,
	getActiveThread
} from 'store/thread/selectors';
import { connect } from 'react-redux';
import { THREAD_STATUS_OPTIONS } from 'store/thread/threadConstants';
import { updateThreadStatus } from 'store/thread';
import { AppState } from 'store-types';

interface MessagesThreadHeaderProps {
	threadId: string;
	thread: ThreadItem;
	isReadOnly: boolean;
	isCurrentUserAssignedToThread: boolean;
	updateThreadStatus: typeof updateThreadStatus;
}

export class MessagesThreadHeader extends React.PureComponent<
	MessagesThreadHeaderProps
> {
	updateThreadStatus = (status: string) => {
		this.props.updateThreadStatus({
			threadId: this.props.threadId,
			status
		});
	};

	render() {
		const { thread, isCurrentUserAssignedToThread, isReadOnly } = this.props;
		const statusUpdatedOn =
			thread.status !== ThreadStatus.RESOLVED ? thread.statusUpdatedOn : '';
		return (
			<Flex className={styles.root} align="center">
				<Col xs={9}>
					<h3>{thread.lastMessage ? thread.lastMessage.subject : ''}</h3>
				</Col>

				<Col xs={3} style={{ paddingLeft: 0 }}>
					{!isReadOnly && (
						<Status.Dropdown
							onChange={this.updateThreadStatus}
							value={thread.status}
							disabled={!isCurrentUserAssignedToThread}
							options={THREAD_STATUS_OPTIONS.filter(
								option => option !== thread.status
							)}
						/>
					)}
					{statusUpdatedOn && (
						<span className={threadStyles.threadHeaderStatusUpdated}>
							Last Update:{' '}
							<strong>
								<Status.TimeAgo date={statusUpdatedOn} />
							</strong>
						</span>
					)}
				</Col>
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		thread: getActiveThread(state),
		isCurrentUserAssignedToThread: getIsCurrentUserAssignedToActiveThread(state)
	}),
	{
		// TODO re-write to action creator
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		updateThreadStatus: updateThreadStatus as any
	}
)(MessagesThreadHeader);
