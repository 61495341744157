import {
	VesselProgrammeState,
	UpdateBuoyRequest
} from '../vesselProgrammeState';

export const onUpdateBuoy = (
	state: VesselProgrammeState,
	{ rotationStepId, buoy }: UpdateBuoyRequest
): VesselProgrammeState => ({
	...state,
	edited: true,
	rotationStepsById:
		rotationStepId in state.rotationStepsById
			? {
					...state.rotationStepsById,
					[rotationStepId]: {
						...state.rotationStepsById[rotationStepId],
						buoy,
						poiId: buoy?.id ?? state.rotationStepsById[rotationStepId].poiId
					}
			  }
			: state.rotationStepsById
});
