import * as React from 'react';
import { Badge, Button } from 'components/antd';

import { IconType } from 'components/antd/Icon/IconTypes';
import { ThemeScheme, ButtonType } from 'components/types';

import Link from 'components/Link/Link';
import { ButtonProps } from 'components/antd/Button/Button';
import financeStyles from 'sections/PortCall/Finance/Finance.module.scss';

export type DocIconType = ThemeScheme;

interface FileIconProps {
	docIcon?: keyof IconType;
	docType?: DocIconType;
	count?: number;
	/** Toggle visibility of `count` inside <Badge /> component */
	show?: boolean;
	/** Enables `display: block` mode */
	isBlock?: boolean;

	size?: ButtonProps['iconSize'];
	disabled?: ButtonProps['disabled'];
	loading?: ButtonProps['loading'];
	onClick?: ButtonProps['onClick'];
}

const BadgeThemeToButtonTypeMap: { [i in DocIconType]: ButtonType } = {
	primary: 'primary',
	error: 'danger',
	warning: 'danger',
	success: 'primary',
	info: 'standard',
	default: 'standard',
	standard: 'standard'
};

class FileIcon extends React.PureComponent<FileIconProps> {
	static defaultProps = {
		count: 0
	};

	onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		if (this.props.onClick) {
			event.stopPropagation();
			this.props.onClick(event);
		}
	};

	render() {
		const {
			loading,
			disabled,
			docType,
			docIcon,
			count,
			size,
			show,
			isBlock = true
		} = this.props;

		const icon = !docIcon
			? this.props.count && this.props.count > 1
				? 'document-stack'
				: 'document'
			: docIcon;

		const buttonType = docType ? BadgeThemeToButtonTypeMap[docType] : 'primary';

		/**
		 * The idea of wrapping a <Button> inside a <Link> came from
		 * antd documentation https://ant.design/components/badge/#
		 * @see "Clickable" example
		 */
		return (
			<Link
				type="default"
				onClick={this.onClick}
				style={{ marginRight: 0, display: isBlock ? 'block' : 'inherit' }}
				className={financeStyles.cannotBeDisabled}
			>
				<Badge count={count} theme={docType} overflowCount={count} show={show}>
					<Button
						type={buttonType}
						icon={icon}
						loading={loading}
						disabled={disabled}
						iconSize={size}
						transparent
					/>
				</Badge>
			</Link>
		);
	}
}

export default FileIcon;
