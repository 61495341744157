import client from 'services/client';
import { AxiosTypedPromise } from 'services/api/apiTypes';
import queryString from 'query-string';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';
import {
	RetrievePortCallsRequest,
	AddPortCallRequest,
	PortCallJobGroupType,
	PortCallJobSearchGroup,
	PortCallJobSearchRequest,
	PortCall,
	RetrievePortCallsAlertsRequest,
	MergePortCallsRequest,
	MergePortCallsResponse,
	RetrievePortCallsResponse,
	GetLinkedCargosRequest,
	AddAssinedUserRequest,
	SaveLinkedCargosDetailsRequest
} from './portCallsServiceTypes';
import { RetrievePortCallRequest } from 'services/api/portCalls/portCallsServiceTypes';

export const retrievePortCalls = (request: RetrievePortCallsRequest) => {
	return client.get<RetrievePortCallsResponse>('/portcalls', {
		params: request,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'none' })
	});
};

export const retrievePortCallsDuplicates = (
	request: RetrievePortCallsRequest
) => {
	return client.get('/portcalls/duplicates', {
		params: request,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, {
				arrayFormat: 'none'
			})
	});
};

export const retrievePortCall = (request: RetrievePortCallRequest) => {
	return client.get(`/portcalls/${request.id}`, {
		params: { expand: request.expand }
	});
};

export const retrievePortCallsStatic = () => {
	return client.get('/portcalls/static');
};

export const retrievePortCallsAlerts = (
	request: RetrievePortCallsAlertsRequest
) => {
	return client.get('/dashboard/portcalls', {
		params: {
			IncludeAlerts: request.includeAlerts,
			IncludePortJobs: true,
			PortCallIds: request.portCallIds,
			AlertSeverities: request.alertSeverities
		},
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'index' })
	});
};

const retrieveUnitsOfMeasurement = () => {
	return client.get('/measurement-units');
};

export const retrievePortCallsSettings = () => {
	return client.get('/portcalls/settings');
};

export const addPortCall = (request: AddPortCallRequest) => {
	return client.post<PortCall>('/portcalls', request);
};

export const searchPortCalls = (
	searchTerm: string,
	showMoreInGroup: PortCallJobGroupType | null,
	mainPrincipalId?: string
): AxiosTypedPromise<{ groups: PortCallJobSearchGroup[] }> => {
	const request: PortCallJobSearchRequest = {
		searchTerm,
		showMore: false,
		mainPrincipalId
	};
	if (showMoreInGroup !== null) {
		request.showMore = true;
		request.groupType = showMoreInGroup;
	}
	return client.get('/portcalls/jobs/search', {
		params: request
	});
};

export const searchPortCallJobsByCode = async (
	searchTerm: string,
	mainPrincipalId: string
) => {
	const results = await searchPortCalls(
		searchTerm,
		'PortJobCode',
		mainPrincipalId
	);
	const group = results.data.groups.find(g => g.groupType === 'PortJobCode');
	return group?.results || [];
};

export const searchPortCallsForAutocomplete = (
	searchTerm: string,
	groupType: PortCallJobGroupType | null
) =>
	searchEntitiesForAutocompleteSearch<PortCallJobGroupType | null>(
		searchPortCalls,
		searchTerm,
		groupType
	);

export const mergePortCalls = ({
	portCallId,
	...request
}: MergePortCallsRequest) =>
	client.post<MergePortCallsResponse>(
		`/portcalls/${portCallId}/duplicates`,
		request
	);

export const addAssignedUserToPortJob = ({
	portCallId,
	jobCode,
	...request
}: AddAssinedUserRequest) =>
	client.put(
		`/portcalls/${portCallId}/jobs/${jobCode}/update-assignedDa`,
		request
	);

export const getLinkedCargoDetils = ({
	portCallId,
	portJob,
	linkJob
}: GetLinkedCargosRequest) =>
	client.get(
		`/portcalls/${portCallId}/jobs/${portJob}/GetLinkedCargos?expand=Operations&LinkedAppJobCode=${linkJob}`
	);

export const saveLinkedCargoDetails = ({
	portCallId,
	portJob,
	request
}: SaveLinkedCargosDetailsRequest) =>
	client.post<MergePortCallsResponse>(
		`/portcalls/${portCallId}/jobs/${portJob}/AddLinkedJobs`,
		request
	);

export const saveUpdatedLinkedCargoDetails = ({
	portCallId,
	portJob,
	request
}: SaveLinkedCargosDetailsRequest) =>
	client.put<MergePortCallsResponse>(
		`/portcalls/${portCallId}/jobs/${portJob}/UpdateLinkedCargoes`,
		request
	);

export default {
	retrievePortCalls,
	retrievePortCallsDuplicates,
	retrievePortCallsAlerts,
	retrievePortCallsStatic,
	retrievePortCall,
	retrieveUnitsOfMeasurement,
	retrievePortCallsSettings,
	addPortCall,
	searchPortCalls,
	searchPortCallsForAutocomplete,
	mergePortCalls,
	getLinkedCargoDetils,
	addAssignedUserToPortJob,
	saveLinkedCargoDetails,
	saveUpdatedLinkedCargoDetails
};
