import { VesselProgrammeState, initialState } from '../vesselProgrammeState';

export const onResetVesselProgramme = (
	state: VesselProgrammeState
): VesselProgrammeState => {
	if (state.context.showErrorPage) {
		// avoid resetting state if VP was not able to perform getting
		return state;
	}
	return initialState;
};
