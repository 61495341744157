import actionCreator from '../portJobsActionCreator';
import {
	ExtendedMessagingActionRequest,
	ExtendedMessagingData,
	ExtendedMessagingRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'EXTENDED_MESSAGING_DATA';

export const retrieveExtendedMessagingDataAsync = actionCreator.async<
	ExtendedMessagingRequest,
	ExtendedMessagingData,
	Error
>(ACTION_NAME);

export const retrieveExtendedMessagingData = actionCreator<
	ExtendedMessagingActionRequest
>(ACTION_NAME);
