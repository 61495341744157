import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import { change, getFormValues } from 'redux-form';
import Tree, { TreeProps } from 'components/antd/Tree/Tree';
import {
	ApiSubscriptionMethodName,
	ApiSubscriptionToolTip,
	DEVELOPER_PORTAL_FORM,
	RequestType,
	ApiGroupName,
	EMDGroupName,
	EMDApiName,
	EMD_FORM,
	ApiGroupList
} from './constants';
import { isArray, reduce, isEmpty, flatten, sortBy } from 'lodash';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import {
	Icon,
	Tooltip,
	Tag,
	Row,
	Col,
	Autocomplete,
	Select,
	Checkbox
} from 'components/antd';
import { DataFetcher } from 'utils/components';
import {
	retrieveDeveloperPortal,
	setSelectedOutBoundList
} from 'store/developerPortal/actions';
import {
	getDeveloperPortalValue,
	getFormInitialValue,
	getInitialValueFetchStatus,
	getEmdApiId
} from 'store/developerPortal/selectors';
import {
	requestType,
	checkCount,
	getChildrenSize
} from './DeveloperPortalFunc';
import styles from '../DeveloperPortal.module.scss';
import ApiPermissionHeader from './ApiPermissionHeader';
import { ApiGroupPermissionList } from 'services/api/developerPortal/developerPortalServiceTypes';
import { Gap, Loading, Title } from 'components';
import DeveloperPortalHeader from './DeveloperPortalHeader';
import { FetchStatus } from 'services/api/apiTypes';
import DownloadApiPopUp from './DownloadApiPopUp';
import { LabeledValueItem } from 'app-types';
import WebHookDetails from './WebHookComponents/WebHookDetails';

const DeveloperPortal = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(retrieveDeveloperPortal());
	}, [dispatch]);

	const [emdValue, setEmdValue] = useState(String);

	const initialValues = useSelector(getDeveloperPortalValue);
	const emdApiId = useSelector(getEmdApiId);
	const fetchStatus = useSelector(getInitialValueFetchStatus);
	const initialFormValues = useSelector(
		() => getFormInitialValue(initialValues),
		shallowEqual
	);
	const formValues = useSelector(
		getFormValues(DEVELOPER_PORTAL_FORM),
		shallowEqual
	);
	const emdFormValue = useSelector(getFormValues(EMD_FORM), shallowEqual);

	const mapKeysToStateShape = (checkedKeys: string[]) => {
		return reduce(
			checkedKeys,
			(obj, param) => {
				obj[param] = true;
				return obj;
			},
			{}
		);
	};

	const onCheckCustom: TreeProps['onCheck'] = initialCheckedKeys => {
		let checked = !isArray(initialCheckedKeys)
			? (initialCheckedKeys as { checked: string[] }).checked
			: initialCheckedKeys;

		//if there is any one api is checked then need to add EMD id also in the list.
		//If all are unchecked need to remove the EMD id
		if (checked.length === 1 && emdApiId) {
			if (checked[0] === emdApiId) {
				checked = [];
			} else {
				checked = [...checked, emdApiId];
			}
		}
		dispatch(setSelectedOutBoundList(checked));
		dispatch(
			change(
				DEVELOPER_PORTAL_FORM,
				`apiCheckedKeys`,
				mapKeysToStateShape(checked)
			)
		);
	};

	const getHeaderComponent = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		item: any,
		_treeProps: TreeProps
	): React.ReactNode => {
		return (
			<ApiPermissionHeader
				title={ApiGroupName[item.groupName]}
				checked={checkCount(item, formValues?.apiCheckedKeys || [], false)}
				size={getChildrenSize(item)}
				requestType={item.direction}
			/>
		);
	};

	const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	const getRowComponent = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		item: any,
		_treeProps: TreeProps
	): React.ReactNode => {
		const style = {
			[styles.putClass]: Boolean(RequestType.PUT === item.httpMethod),
			[styles.getClass]: Boolean(RequestType.GET === item.httpMethod),
			[styles.postClass]: Boolean(RequestType.POST === item.httpMethod),
			[styles.deleteClass]: Boolean(RequestType.DELETE === item.httpMethod)
		};
		return (
			<Row>
				<Col sm={8}>
					<Tag size="xs" className={classNames(styles.httpRequestClass, style)}>
						{requestType(item.httpMethod)}
					</Tag>
					&nbsp;
					{ApiSubscriptionMethodName[item.methodName]}
					&nbsp;
					<Tooltip
						title={ApiSubscriptionToolTip[item.methodName]}
						placement="right"
						trigger="hover"
						getPopupContainer={() => document.body}
					>
						<Icon type="info-circle" color="primary" />
					</Tooltip>
				</Col>
				<Col sm={2}>
					<span onClick={stopPropagation}>
						<DownloadApiPopUp
							id={item.id}
							apiDetails={item}
							isOutbound={getIsOutbound(item.apiGroupId)}
						/>
					</span>
				</Col>
			</Row>
		);
	};

	const onEMDChange = (value: LabeledValueItem) => {
		dispatch(change(EMD_FORM, `EMDValue`, value));
	};

	const emdData = () => {
		const value = initialValues?.apiPermission.find(
			api => api.groupName === 'EMD' && api.children[0].id
		);
		if (value?.children) {
			setEmdValue(value.children[0].id);
		}
	};

	const displayAPIList = useMemo(() => {
		let apiList = initialValues?.apiPermission.filter(api =>
			api?.groupName ? ApiGroupName[api.groupName] : {}
		);

		apiList = apiList?.map(api => ({
			...api,
			children: sortBy(api.children, 'index')
		}));
		return apiList;
	}, [initialValues]);

	const displayEMDList = useMemo(
		() =>
			initialValues?.apiPermission.filter(api =>
				api?.groupName ? EMDGroupName[api.groupName] : {}
			),
		[initialValues]
	);

	const getAPIDetails = useCallback(
		id =>
			flatten(displayEMDList?.map(list => list.children)).find(
				child => child.id === id
			),
		[displayEMDList]
	);

	const getIsOutbound = useCallback(
		id =>
			displayAPIList?.find(api => api.id === id)?.direction ===
			RequestType.OUTBOUND,
		[displayAPIList]
	);

	const getNotificationAPIDetails = useMemo(
		() =>
			initialValues?.apiPermission.find(
				api =>
					api?.groupName && api.groupName === ApiGroupList.WEB_HOOK_NOTIFICATION
			)?.children[0],
		[initialValues]
	);

	return (
		<>
			<Loading show={fetchStatus === FetchStatus.PENDING} />
			{fetchStatus === FetchStatus.SUCCESS && (
				<ReduxForm
					form={DEVELOPER_PORTAL_FORM}
					initialValues={initialFormValues?.developerPortalForm}
				>
					<div>
						<Gap all="md">
							<DeveloperPortalHeader
								token={initialFormValues?.developerPortalForm?.token}
							/>
						</Gap>
						<hr />
						<Gap all="md">
							<Row>
								<Title>SELECT API FOR SUBSCRIPTION</Title>
							</Row>
							<Row>
								<DataFetcher data={displayAPIList} fetchStatus={fetchStatus}>
									{(perms: ApiGroupPermissionList[]) => (
										<Tree
											checkable
											defaultExpandAll
											idProp="id"
											typeProp="direction"
											nameProp={'methodName' || 'groupName'}
											getHeaderComponent={getHeaderComponent}
											getRowComponent={getRowComponent}
											treeData={perms}
											checkedKeys={Object.keys(
												formValues?.apiCheckedKeys
													? formValues.apiCheckedKeys
													: {}
											)}
											checkStrictly
											includeParentRefs
											showParentNodeCheckBox={false}
											onCheck={onCheckCustom}
											className={styles.apiGroupPermissionTree}
										/>
									)}
								</DataFetcher>
							</Row>
						</Gap>
						<Gap left="md" right="md" bottom="md" isBlock>
							<Row>
								<Col>
									<Title>
										<Checkbox
											checked={!isEmpty(formValues?.apiCheckedKeys)}
											disabled
										/>
										ENTERPRISE MASTER DATA API &nbsp;
										<Tooltip
											title={
												'Provides external system the full load & delta load from EMD so that the requests are received with exact information from EMD.'
											}
											placement="right"
											trigger="hover"
										>
											<Icon type="info-circle" color="primary" />
										</Tooltip>
									</Title>
								</Col>
								<Gap left="md">
									<Col className={styles.emdDownloadApi}>
										<span onClick={emdData}>
											<DownloadApiPopUp id={emdValue} downloadAll />
										</span>
									</Col>
								</Gap>
							</Row>
							<Row>
								<Col xs={4}>
									<Gap top="sm" isBlock>
										<Row>
											<Col xs={8}>
												<Autocomplete
													onSelect={onEMDChange}
													placeholder="Select"
													localSearch
													labelInValue
													filterOption
													optionFilterProp="children"
												>
													{displayEMDList?.map(
														(api: ApiGroupPermissionList) => (
															<Select.OptGroup
																key={
																	api?.groupName
																		? EMDGroupName[api.groupName]
																		: ''
																}
															>
																{api.children.map(item => (
																	<Select.Option
																		key={item.id}
																		title={
																			api?.groupName && item?.methodName
																				? `${EMDGroupName[api.groupName]}-${
																						EMDApiName[item.methodName]
																				  }`
																				: ''
																		}
																	>
																		{item?.methodName &&
																			EMDApiName[item.methodName]}
																	</Select.Option>
																))}
															</Select.OptGroup>
														)
													)}
												</Autocomplete>
											</Col>
											<Col xs={4} className={styles.emdDownloadApi}>
												{emdFormValue?.EMDValue && (
													<DownloadApiPopUp
														id={emdFormValue.EMDValue.key}
														apiDetails={getAPIDetails(
															emdFormValue.EMDValue.key
														)}
													/>
												)}
											</Col>
										</Row>
									</Gap>
								</Col>
							</Row>
							<Row className={styles.colWidth}>
								<Col xs={2}>
									<Title>
										NOTIFICATION{' '}
										<Tooltip
											title={
												'Allow to send notification for jobs updated in Optic.'
											}
											placement="right"
											trigger="hover"
										>
											<Icon type="info-circle" color="primary" />
										</Tooltip>
									</Title>
								</Col>
								<Col
									className={classNames(
										styles.emdDownloadApi,
										styles.notificationMargin
									)}
									xs={1}
								>
									{getNotificationAPIDetails && (
										<DownloadApiPopUp
											id={getNotificationAPIDetails.id}
											apiDetails={getNotificationAPIDetails}
											isOutbound={true}
										/>
									)}
								</Col>
							</Row>
						</Gap>
						<WebHookDetails />
					</div>
				</ReduxForm>
			)}
		</>
	);
};

export default DeveloperPortal;
