import actionCreatorFactory from 'typescript-fsa';
import {
	RetrieveThreadsResponse,
	RetrieveThreadsRequest
} from 'services/api/threads/threadsServiceTypes';

const actionCreator = actionCreatorFactory();

const ACTION_NAME = 'threads/RETRIEVE_THREADS';

export const retrieveThreadsAsync = actionCreator.async<
	RetrieveThreadsRequest,
	RetrieveThreadsResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'section', infiniteLoad: true } });

export const retrieveThreads = actionCreator(ACTION_NAME);
