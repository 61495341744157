import {
	retrieveDashboardSummaryCycleStart,
	retrieveDashboardSummaryCycleStop
} from '../actions/retrieveDashboardSummaryCycle';
import { SagaIterator } from 'redux-saga';
import { cancel, fork, take, put, delay } from 'redux-saga/effects';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	retrieveDashboardPortCallsSummary,
	retrieveDashboardPortCallsSummaryAsync
} from '../actions';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';

function* worker(): SagaIterator {
	// wait for init call to be finished
	yield take([
		retrieveDashboardPortCallsSummaryAsync.done,
		retrieveDashboardPortCallsSummaryAsync.failed
	]);
	yield put(
		setNotificationActionTypeToNotification([
			retrieveDashboardPortCallsSummary.type
		])
	);
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL / 3);
		yield put(retrieveDashboardPortCallsSummary());
	}
}

export default function*() {
	while (yield take(retrieveDashboardSummaryCycleStart)) {
		const pollDashboardSummaryTask = yield fork(worker);
		yield take(retrieveDashboardSummaryCycleStop);
		yield cancel(pollDashboardSummaryTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrieveDashboardPortCallsSummary.type
			])
		);
	}
}
