import React from 'react';
import { connect } from 'react-redux';
import styles from './TopNavBar.module.scss';

import UserProfile from '../UserProfile/UserProfile';
import { Layout } from 'components/antd';
import { Flex } from 'components/Styles';

import { BreadcrumbItem } from 'store/navigation/navigationState';
import { getBreadcrumbs } from 'store/navigation/navigationSelectors';
import { Justify } from 'components/types';

import Navbar from './components/Navbar';
import Breadcrumbs from './components/Breadcrumbs';
import { AppState } from 'store-types';

const { Header } = Layout;

interface TopNavBarProps {
	isLoggedIn: boolean;
	showLogo: boolean;
	breadcrumbs: BreadcrumbItem[];
	onToggleSidebar(): void;
}

export const TopNavBar: React.SFC<TopNavBarProps> = (props: TopNavBarProps) => {
	const breadcrumbs = props.breadcrumbs || [];
	const { showLogo, isLoggedIn, onToggleSidebar } = props;
	return (
		<Header className={styles.header}>
			<Flex
				justify={Justify.BETWEEN}
				className={styles.headerContent}
				align="center"
			>
				<Navbar
					showLogo={showLogo}
					showMenu={isLoggedIn}
					onToggleSidebar={onToggleSidebar}
				/>
				{breadcrumbs.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
				{isLoggedIn ? <UserProfile /> : null}
			</Flex>
		</Header>
	);
};

export default connect((state: AppState) => ({
	breadcrumbs: getBreadcrumbs(state)
}))(TopNavBar);
