import { Success, Failure } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';
import {
	RequiredDocument,
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

export const onRetrieveRequiredDocumentsStarted = (
	state: PortCallDocumentsState
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		requiredById: FetchStatus.PENDING
	}
});

export const onRetrieveRequiredDocumentsDone = (
	state: PortCallDocumentsState,
	action: Success<
		RetrieveRequiredDocumentsRequest,
		RetrieveRequiredDocumentsResponse
	>
): PortCallDocumentsState => ({
	...state,
	requiredById: keyBy<RequiredDocument>(
		action.result.elements,
		item => item.id
	),
	fetchStatuses: {
		...state.fetchStatuses,
		requiredById: FetchStatus.SUCCESS
	}
});

export const onRetrieveRequiredDocumentsFailed = (
	state: PortCallDocumentsState,
	action: Failure<RetrieveRequiredDocumentsRequest, Error>
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		requiredById: FetchStatus.FAILURE
	},
	error: action.error
});

export const onResetRequiredDocuments = (
	state: PortCallDocumentsState
): PortCallDocumentsState => ({
	...state,
	requiredById: {},
	fetchStatuses: {
		...state.fetchStatuses,
		requiredById: FetchStatus.IDLE
	}
});
