import {
	CustodyTransfer,
	CustodyLineRotationStep,
	ReducerOperationUnit,
	EventsById,
	OperationUnitsById
} from 'store/vesselProgramme/vesselProgrammeState';
import {
	RetrieveRotationStep,
	RetrieveOperationUnit,
	RotationStepEvent,
	RotationStepEventParentType,
	CustodyAgentType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import {
	map,
	isUndefined,
	flatMap,
	filter,
	keyBy,
	flatten,
	concat
} from 'lodash';

export const buildUnitsById = (
	unallocatedUnits: RetrieveOperationUnit[],
	allocatedUnits: ReducerOperationUnit[]
): OperationUnitsById =>
	keyBy(
		concat(
			allocatedUnits,
			map(unallocatedUnits, unit => ({
				...unit,
				events: map(unit.events, event => event.id),
				allocatedId: ''
			}))
		),
		unit => unit.id
	);

export const buildEventsByIdForSingleRotationStep = (
	events: RotationStepEvent[],
	rotationStepId: string,
	parentType: RotationStepEventParentType = RotationStepEventParentType.ROTATION_STEP
): EventsById =>
	keyBy(
		events.map(event => ({
			...event,
			parentId: rotationStepId,
			parentType
		})),
		event => event.id
	);

export const buildEventsById = (
	rotationSteps: RetrieveRotationStep[],
	unallocatedUnits: RetrieveOperationUnit[],
	allocatedUnits: RetrieveOperationUnit[]
): EventsById =>
	keyBy(
		flatten([
			...rotationSteps.map(rotationStep =>
				rotationStep.events.map(event => ({
					...event,
					parentId: rotationStep.id,
					parentType: RotationStepEventParentType.ROTATION_STEP
				}))
			),
			...unallocatedUnits.map(unit =>
				unit.events.map(event => ({
					...event,
					parentId: unit.id,
					parentType: RotationStepEventParentType.OPERATION_UNIT
				}))
			),
			...allocatedUnits.map(unit =>
				unit.events.map(event => ({
					...event,
					parentId: unit.id,
					parentType: RotationStepEventParentType.OPERATION_UNIT
				}))
			),
			[]
		]),
		unit => unit.id
	);

export const buildSplitUnitsByParentUnitId = (
	unallocatedUnits: RetrieveOperationUnit[],
	allocatedUnits: ReducerOperationUnit[]
) =>
	unallocatedUnits
		.filter(unit => Boolean(unit.parentOperationUnitId))
		.map(unit => ({
			parentOperationUnitId: unit.parentOperationUnitId,
			id: unit.id
		}))
		.concat(
			allocatedUnits
				.filter(unit => Boolean(unit.parentOperationUnitId))
				.map(unit => ({
					parentOperationUnitId: unit.parentOperationUnitId,
					id: unit.id
				}))
		)
		.reduce(
			(splittedUnits, unit) => ({
				...splittedUnits,
				[unit.parentOperationUnitId as string]: [
					...(splittedUnits[unit.parentOperationUnitId as string] || []),
					unit.id
				]
			}),
			{}
		);

export const buildAllocatedUnits = (
	rotationSteps: RetrieveRotationStep[]
): ReducerOperationUnit[] =>
	flatMap(
		filter(rotationSteps, step => Boolean(step.units)),
		rotationStep =>
			map(rotationStep.units, (unit: RetrieveOperationUnit) => ({
				...unit,
				events: map(unit.events, singleUnit => singleUnit.id),
				allocatedId: rotationStep.id
			}))
	);

export const buildAllocatedUnitsWithFullEvents = (
	rotationSteps: RetrieveRotationStep[]
): RetrieveOperationUnit[] =>
	flatMap(
		filter(rotationSteps, step => Boolean(step.units)),
		rotationStep =>
			map(rotationStep.units, (unit: RetrieveOperationUnit) => ({
				...unit,
				allocatedId: rotationStep.id
			}))
	);

export const buildCustodyTransfer = (rotationSteps: RetrieveRotationStep[]) =>
	rotationSteps.reduce(
		(cTransfer, step) =>
			!isUndefined(step.custodyTransfer)
				? { linePosition: step.custodyTransfer, rotationStepId: step.id }
				: cTransfer,
		null
	);

export const buildRotationStepsById = (rotationSteps: RetrieveRotationStep[]) =>
	rotationSteps.reduce(
		(acc, rotationStep) => ({
			...acc,
			[rotationStep.id]: {
				...rotationStep,
				events: map(rotationStep.events, event => event.id),
				units: map(rotationStep.units || [], unit => unit.id)
			}
		}),
		{}
	);

export const buildPositionedRotationSteps = (
	rotationStepIds: string[],
	custodyTransfer: CustodyTransfer | null
) => {
	const indexRotationStepWithLine = custodyTransfer
		? rotationStepIds.findIndex(
				stepId => stepId === custodyTransfer.rotationStepId
		  )
		: -1;
	return rotationStepIds.reduce(
		(result, stepId, index): CustodyLineRotationStep[] => {
			if (
				indexRotationStepWithLine === -1 ||
				!custodyTransfer ||
				!custodyTransfer.linePosition
			) {
				// no line all full
				return [
					...result,
					{
						rotationStepId: stepId,
						agentMode: CustodyAgentType.FULL,
						hasCustodyLine: false,
						position: result.length
					}
				];
			}

			if (index < indexRotationStepWithLine) {
				// step before line
				return [
					...result,
					{
						rotationStepId: stepId,
						agentMode: CustodyAgentType.INBOUND,
						hasCustodyLine: false,
						position: result.length
					}
				];
			}

			if (index === indexRotationStepWithLine) {
				if (custodyTransfer.linePosition === 'During') {
					// steps with line
					return [
						...result,
						{
							rotationStepId: stepId,
							agentMode: CustodyAgentType.INBOUND,
							hasCustodyLine: true,
							position: result.length
						},
						{
							rotationStepId: stepId,
							agentMode: CustodyAgentType.OUTBOUND,
							hasCustodyLine: true,
							position: result.length + 1
						}
					];
				}
				if (custodyTransfer.linePosition === 'Before') {
					return [
						...result,
						{
							rotationStepId: stepId,
							agentMode: CustodyAgentType.OUTBOUND,
							hasCustodyLine: false,
							position: result.length
						}
					];
				}
				if (custodyTransfer.linePosition === 'After') {
					return [
						...result,
						{
							rotationStepId: stepId,
							agentMode: CustodyAgentType.INBOUND,
							hasCustodyLine: false,
							position: result.length
						}
					];
				}
			}

			if (index > indexRotationStepWithLine) {
				// step after line
				return [
					...result,
					{
						rotationStepId: stepId,
						agentMode: CustodyAgentType.OUTBOUND,
						hasCustodyLine: false,
						position: result.length
					}
				];
			}

			return result;
		},
		[]
	);
};
