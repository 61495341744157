import * as React from 'react';
import {
	RetrieveThreadsStatsResponse,
	ThreadStatus,
	ThreadsAssignedToUserFilter
} from 'services/api/threads/threadsServiceTypes';
import { Flex, Count } from 'components';
import { Justify } from 'components/types';
import styles from './MessagesSummary.module.scss';
import { connect } from 'react-redux';
import { setFilters, destroy } from 'store/filters/actions';
import { THREADS_FILTER_NAME } from 'store/threads/filtersSync';
import { navigateTo } from 'utils';
import { getStatusPayload, getAssignedToPayload } from './MessagesSummary.func';

interface SummaryContentProps {
	stats: RetrieveThreadsStatsResponse;
	setFilters: typeof setFilters;
	destroy: typeof destroy;
}

export class SummaryContent extends React.PureComponent<SummaryContentProps> {
	componentDidMount() {
		this.props.destroy(THREADS_FILTER_NAME);
	}

	sendToOpenThreads = () => {
		this.props.setFilters(getStatusPayload(ThreadStatus.OPEN));
		this.goToMessages();
	};

	sendToOverdueThreads = () => {
		this.props.setFilters(getStatusPayload(ThreadStatus.OVERDUE));
		this.goToMessages();
	};

	sendToSelfAssigned = () => {
		this.props.setFilters(getAssignedToPayload(ThreadsAssignedToUserFilter.ME));

		this.goToMessages();
	};
	sendToUnassigned = () => {
		this.props.setFilters(
			getAssignedToPayload(ThreadsAssignedToUserFilter.UNASSIGNED)
		);
		this.goToMessages();
	};

	goToMessages = () => requestAnimationFrame(() => navigateTo('/messages')); // TODO: Figure out exactly WHY an artificial delay is needed

	render() {
		const { stats: { open, assigned, unassigned, overdue } } = this.props;

		return (
			<Flex className={styles.content} justify={Justify.AROUND}>
				<Count
					count={open}
					title="Open threads"
					type="attention"
					onClick={this.sendToOpenThreads}
				/>
				<Count
					count={assigned}
					title="Assigned to you"
					type="positive"
					onClick={this.sendToSelfAssigned}
				/>
				<Count
					count={unassigned}
					title="Unassigned threads"
					type="positive"
					onClick={this.sendToUnassigned}
				/>
				<Count
					count={overdue}
					title="Overdue threads"
					type="negative"
					onClick={this.sendToOverdueThreads}
				/>
			</Flex>
		);
	}
}

export default connect(null, {
	setFilters,
	destroy
})(SummaryContent);
