import { FinanceState } from '../financeState';
import {
	UpdateRefreshDirectBillResponse,
	UpdateRefreshDirectBillRequest
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateRefreshDirectBill = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateRefreshDirectBillRequest,
	UpdateRefreshDirectBillResponse
>('fetchStatuses', 'updateRefreshDirectBill');
