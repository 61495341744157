import { createSelector } from 'reselect';
import {
	getFundingRequestById,
	hasFundingRequestsActionById,
	getBankAccountByFundingRequestId
} from 'store/financeFundings/selectors';
import {
	FinanceFundingFormData,
	FINANCE_FUNDING_FORM_NAME
} from '../financeFundingTypes';

import {
	FundingRequestUpdate,
	FundingRequestMetadataActionCode,
	BaseAmountTypeCodes,
	FundingRequestStatus
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { NEW_FUNDING_ID } from 'store/financeFundings/financeFundingsConstants';
import { AppState } from 'store-types';
import { getValues } from 'store/form/selectors';
import { isValid } from 'redux-form';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { TooltipProps } from 'antd/lib/tooltip';
import { MetadataStatusCode } from 'app-constants';
import { getDaInAmount } from 'sections/PortCall/Finance/FundingRequestPage/FundingRequestTable/FundingTableColumns/FundingRequestNumberInput/FundingRequestNumberInput.func';

export const getFinanceFundingFormValues = (state: AppState) =>
	getValues<FinanceFundingFormData>(state, FINANCE_FUNDING_FORM_NAME);

export const getFundingInitialValues = createSelector(
	getFundingRequestById,
	(funding): Partial<FinanceFundingFormData> => {
		const baseAmountTypeCode =
			funding.balanceForDaInAmountWithoutAgencyCosts <= 0
				? BaseAmountTypeCodes.DA_IN_AMOUNT
				: funding.baseAmountTypeCode;
		return {
			...funding,
			baseAmountTypeCode,
			fundingAmount: String(funding.fundingAmount),
			fundingPercent: String(funding.fundingPercent)
		};
	}
);

export const getFundingUpdate = createSelector(
	getFinanceFundingFormValues,
	(formValues: FinanceFundingFormData): FundingRequestUpdate => {
		return {
			id: formValues.id,
			fundingAmount: parseFloat(formValues.fundingAmount.replace(/,/g, '')),
			fundingPercent: formValues.fundingPercent,
			isUrgent: formValues.isUrgent,
			comment: formValues.comment,
			baseAmountTypeCode: formValues.baseAmountTypeCode,
			fundsRequestedSent: formValues.currentFundingSentOrRequested,
			fundsRequestedBalanceAmount: getDaInAmount(
				formValues.balanceForDaInAmount,
				formValues.balanceForDaInAmountWithoutAgencyCosts,
				formValues.baseAmountTypeCode
			)
		};
	}
);

export const getIsRejectable = createSelector(
	(_state: AppState, fundingId?: string) => fundingId,
	(state: AppState, fundingId: string) =>
		hasFundingRequestsActionById(
			state,
			fundingId,
			FundingRequestMetadataActionCode.REJECT
		),
	(fundingId: string, hasAction) => hasAction || fundingId === NEW_FUNDING_ID
);

export const getIsApprovable = createSelector(
	(_state: AppState, fundingId?: string) => fundingId,
	(state: AppState, fundingId: string) =>
		hasFundingRequestsActionById(
			state,
			fundingId,
			FundingRequestMetadataActionCode.APPROVE
		),
	(fundingId: string, hasAction: boolean) =>
		hasAction || fundingId === NEW_FUNDING_ID
);

export const getCanRejectFunding = createSelector(
	(_state: AppState, isRejectable: boolean) => isRejectable,
	(state: AppState) => getFinanceFundingFormValues(state).comment,
	(state: AppState) => isValid(FINANCE_FUNDING_FORM_NAME)(state),
	(isRejectable: boolean, comment: string, valid: boolean) =>
		isRejectable && valid && !!comment
);

export const getCanApproveFunding = createSelector(
	(_state: AppState, isApprovable: boolean) => isApprovable,
	(state: AppState, _isApprovable: boolean, fundingId: string) =>
		getBankAccountByFundingRequestId(state, fundingId),
	(state: AppState) => isValid(FINANCE_FUNDING_FORM_NAME)(state),
	(isApprovable: boolean, bank: BankAccount, valid: boolean) =>
		isApprovable && valid && Boolean(bank?.lobd) && !bank.isDraft
);

export const getIsAllDaSetLocked = createSelector(
	getFundingRequestById,
	fundingRequest =>
		fundingRequest.metadata.actions.some(
			action =>
				action.statusCode === MetadataStatusCode.LOCKED &&
				action.actionCode === FundingRequestMetadataActionCode.APPROVE
		)
);

export const getIsStatusPendingReview = createSelector(
	getFundingRequestById,
	fundingRequest =>
		fundingRequest.status === FundingRequestStatus.PENDING_REVIEW
);

export const getApproveTooltip = createSelector(
	(state: AppState, _isApprovable: boolean, fundingId: string) =>
		getBankAccountByFundingRequestId(state, fundingId),
	(bank?: BankAccount) => {
		if (!bank) {
			return;
		}
		if (!bank.lobd) {
			return {
				title: 'In order to approve the request please validate LOBD'
			} as TooltipProps;
		}
		if (bank.isDraft) {
			return {
				title:
					'In order to approve the request bank account details have to be confirmed. Please contact master data team'
			} as TooltipProps;
		}
		return;
	}
);
