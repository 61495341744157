import { getWizardById } from './../wizardFormSelectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';
import { select, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { isEmpty } from 'lodash';

export function* getActiveWizardExecutor(): SagaIterator {
	return yield select(getActiveWizard);
}

/**
 * Get mode of current activeWizard if exist
 */
export function* getActiveWizardModeExecutor(): SagaIterator {
	const activeWizard: WizardFormProps = yield call(getActiveWizardExecutor);
	if (!isEmpty(activeWizard)) {
		return activeWizard.mode;
	}
}

export function* getWizardByIdExecutor(wizardFormId: string): SagaIterator {
	const wizardForm: WizardFormProps = yield select(getWizardById, wizardFormId);
	return wizardForm;
}
