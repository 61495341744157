import actionCreator from '../bankAccountsActionCreator';
import {
	UpdateBankAccountRequest as Request,
	BankAccount as Response
} from 'services/api/bankAccounts/bankAccountsServiceTypes';

const ACTION_NAME = 'UPDATE_BANK_ACCOUNT';

export const updateBankAccount = actionCreator<Request>(ACTION_NAME);
export const updateBankAccountAsync = actionCreator.async<Request, Response>(
	ACTION_NAME
);
