import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Amount } from 'components';
import { getFinanceCurrencyDecimalPart } from 'store/finance/selectors';
import { AppState } from 'store-types';

interface SupplierInvoiceTotalProps {
	currencyCode: string;
	total: number | string;
	isCredit?: boolean;
}

const SupplierInvoiceTotal: FC<SupplierInvoiceTotalProps> = ({
	currencyCode,
	total,
	isCredit
}) => {
	const supplierInvoiceCurrencyCodeDecimalPlaces = useSelector(
		(state: AppState) => getFinanceCurrencyDecimalPart(state, currencyCode)
	);

	return (
		<Amount
			formatType="finance"
			value={total}
			negative={isCredit}
			decimalPart={supplierInvoiceCurrencyCodeDecimalPlaces}
		/>
	);
};

export default SupplierInvoiceTotal;
