import { PortJobState } from '../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';

export function onEditPortJobStarted(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}

export function onEditPortJobSuccess(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
}

export function onEditPortJobFailed(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}
