import React from 'react';
import {
	Gap,
	Text,
	Flex,
	TRS,
	TR,
	TD,
	FormattedTimeExtended
} from 'components';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import JobStatus from 'sections/PortCalls/PortJobList/Columns/JobStatus';
import styles from '../../LinkJob.module.scss';
import { Justify } from 'components/types';
import { Tag } from 'components/antd';
import { portCallStatusToLabel } from 'store/portJobs/constants';
import PortCallCol from 'sections/PortCalls/PortCallCol/PortCallCol';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

interface PortCallDetailsProps {
	portJob: PortJob;
	linkedJob: PortJob;
	portCall: PortCall;
}

const PortCallDetails = ({
	portJob,
	linkedJob,
	portCall
}: PortCallDetailsProps) => {
	const valueEospPlt = portCall.eospPlt ? portCall.eospPlt : portCall.etaPlt;
	const valueCospPlt = portCall.cospPlt ? portCall.cospPlt : portCall.etsPlt;

	return (
		<>
			<table className={styles.table}>
				<TRS header>
					<TR className={styles.header}>
						<TD>
							<PortCallCol label="Port" icon="anchor">
								{portCall.port.name}
							</PortCallCol>
						</TD>
						<TD>
							<PortCallCol label="Vessel" icon="vessel">
								{portCall.vessel.name}
							</PortCallCol>
						</TD>
						<TD colspan={2}>
							<PortCallCol
								label={portCall.eospPlt ? 'EOSP' : 'ETA'}
								icon="calendar-check"
							>
								<FormattedTimeExtended
									value={valueEospPlt}
									directPortTimeZoneId={portCall.portTimezoneId}
								/>
							</PortCallCol>
						</TD>
						<TD colspan={2}>
							<PortCallCol
								label={portCall.cospPlt ? 'COSP' : 'ETS'}
								icon="calendar-check"
							>
								<FormattedTimeExtended
									value={valueCospPlt}
									directPortTimeZoneId={portCall.portTimezoneId}
								/>
							</PortCallCol>
						</TD>
						<TD colspan={2}></TD>
						<TD>
							<PortCallCol label="Status" justify={Justify.CENTER}>
								<Tag className={styles.tag}>
									{portCallStatusToLabel(portCall.status, portCall.isCancelled)}
								</Tag>
							</PortCallCol>
						</TD>
					</TR>
				</TRS>
			</table>

			<Gap left="lg" isBlock outside={false}>
				<Flex justify={Justify.BETWEEN}>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Original Job</Text>
							<br />
							<Text weight="bold" color="primary">
								{portJob.code}
							</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Linked to </Text>
							<br />
							<Text weight="bold" color="primary">
								{linkedJob.code}
							</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Job Type</Text> <br />
							<Text weight="bold">{portJob.jobType}</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Job Type</Text>
							<br /> <Text weight="bold">{linkedJob.jobType}</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Nominator</Text>
							<br /> <Text weight="bold">{portJob.nominatorCompany?.name}</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Appointer</Text>
							<br />{' '}
							<Text weight="bold">{linkedJob.appointerCompany.name}</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Appointer</Text>
							<br /> <Text weight="bold">{portJob.appointerCompany.name}</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Nominator</Text>
							<br />
							<Text weight="bold">
								{linkedJob.nominatorCompany
									? linkedJob.nominatorCompany.name
									: '--'}
							</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Performing Agent</Text>
							<br />
							<Text weight="bold">{portJob.performingAgentCompany.name}</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Performing Agent</Text>
							<br />
							<Text weight="bold">{linkedJob.performingAgentCompany.name}</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Agent Type</Text>
							<br /> <Text weight="bold">{portJob.agentType}</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Agent Type</Text>
							<br /> <Text weight="bold">{linkedJob.agentType}</Text>
						</Text.Box>
					</Flex>
					<Flex justify={Justify.CENTER} direction="vertical">
						<Text.Box right bottom top="sm">
							<Text className={styles.subHeader}>Status</Text>
							<br />
							<Text weight="bold">
								<JobStatus status={portJob.status} portJob={portJob} />
							</Text>
						</Text.Box>
						<Text.Box right bottom>
							<Text className={styles.subHeader}>Status</Text>
							<br />
							<Text weight="bold">
								<JobStatus status={linkedJob.status} portJob={linkedJob} />
							</Text>
						</Text.Box>
					</Flex>
				</Flex>
			</Gap>
		</>
	);
};

export default PortCallDetails;
