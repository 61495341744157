import * as React from 'react';
import { Menu, MaybeTooltip } from 'components/antd';
import { EntityPermission } from 'services/api/permissions/permissionsServiceTypes';
import { getIsPossibleToUpdateActivePortCallInTermsOfStatus } from 'store/portCall/selectors';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { NO_PERMISSION_MESSAGE } from './contants';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { navigateTo } from 'utils';

const getDocumentBody = () => document.body;

interface PortCallSettingsMenuProps extends EntityPermission {
	canUpdate: boolean;
	cannotUpdateMessage: string;
	portCallId: string;
}
const PortCallSettingsMenu = ({
	canUpdate,
	cannotUpdateMessage,
	portCallId
}: PortCallSettingsMenuProps) => (
	<Menu onClick={() => navigateTo(`/portcalls/${portCallId}/edit`)}>
		<Menu.Item disabled={!canUpdate} key="update">
			<MaybeTooltip
				placement="left"
				show={!canUpdate}
				title={cannotUpdateMessage}
				getPopupContainer={getDocumentBody}
			>
				Update
			</MaybeTooltip>
		</Menu.Item>
	</Menu>
);

export default connect((state: AppState, { canUpdate }: EntityPermission) => {
	const isUpdatePortCallPossible = getIsPossibleToUpdateActivePortCallInTermsOfStatus(
		state
	);
	return {
		portCallId: getActivePortCallId(state),
		canUpdate: canUpdate && isUpdatePortCallPossible,
		cannotUpdateMessage: isUpdatePortCallPossible
			? NO_PERMISSION_MESSAGE
			: `Port Call cannot be edited`
	};
})(PortCallSettingsMenu);
