import {
	ConfirmPortJobResponse,
	ConfirmPortJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { PortJobState } from '../portJobsState';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';

export const onConfirmPortJobStarted = (state: PortJobState): PortJobState => ({
	...state,
	error: undefined,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.PENDING
	}
});

export const onConfirmPortJobDone = (
	state: PortJobState,
	action: Success<ConfirmPortJobParams, ConfirmPortJobResponse>
): PortJobState => {
	const jobCodeToUpdate = action.params.jobCode;
	const newById = Object.keys(state.byId).reduce((acc, key) => {
		acc[key] = {
			...state.byId[key],
			status:
				state.byId[key].code === jobCodeToUpdate
					? action.result.status
					: state.byId[key].status
		};
		return acc;
	}, {});
	return {
		...state,
		byId: newById,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
};

export const onConfirmPortJobFailed = (
	state: PortJobState,
	{ error }: Failure<ConfirmPortJobParams, Error>
): PortJobState => {
	return {
		...state,
		error,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		}
	};
};
