import actionCreator from '../companyVesselsActionCreator';
import {
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselByIdResponse
} from 'services/api/companies/companiesServiceTypes';
const ACTION_NAME = 'RETRIEVE_COMPANY_VESSEL_BY_ID';

// TODO review
// might not used in terms of API response
export const retrieveCompanyVesselByIdAsync = actionCreator.async<
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselByIdResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanyVesselById = actionCreator<
	RetrieveCompanyVesselByIdRequest
>(ACTION_NAME);
