import React from 'react';
import { Loading } from 'components';
import { FetchStatus } from 'services/api/apiTypes';
import { LoadingSize } from 'components/Loading/Loading';

export interface DataFetcherProps {
	/** function which dispatch fetching */
	dispatch?: () => void;
	reset?: () => void;
	isLoading?: boolean;
	/** loading indicator */
	fetchStatus?: FetchStatus;
	/** consumed data */
	data?: object | null;
	/** sub component consuming data */
	children: (data?: object | null) => React.ReactNode;
	/** error handler */
	error?: () => React.ReactNode;
	/** show content and skip loading/failure if data is in the state */
	skipIfHasData?: boolean;
	/** always fetch data even if data prop is truthy */
	forceFetch?: boolean;
	/** show loader during FetchStatus.PENDING status */
	showLoader?: boolean;
	/** define custom loading size */
	loadingSize?: LoadingSize | number;
	/** Specify minimal preloader content height */
	minHeight?: number;
}

class DataFetcher extends React.PureComponent<DataFetcherProps> {
	static defaultProps = {
		forceFetch: true,
		showLoader: true
	};

	constructor(props: DataFetcherProps) {
		super(props);
		if (props.isLoading !== undefined && props.fetchStatus !== undefined) {
			throw new Error(
				`Only one prop from a pair 'isLoading' and 'fetchStatus' should be used.`
			);
		}
	}

	componentDidMount() {
		const { dispatch, forceFetch, data } = this.props;
		if (dispatch) {
			if (forceFetch || !data) {
				// fetch only if no data or if forceFetch is true
				dispatch();
			}
		}
	}

	componentWillUnmount() {
		const { reset } = this.props;
		if (reset) {
			reset();
		}
	}

	renderPreloader = () => {
		const spinner = <Loading size={this.props.loadingSize} />;
		if (!this.props.showLoader) {
			return null;
		}
		if (this.props.minHeight) {
			return <div style={{ minHeight: this.props.minHeight }}>{spinner}</div>;
		}
		return spinner;
	};

	private fragmented(content: React.ReactNode | null = null) {
		return <>{content}</>;
	}

	render() {
		const {
			fetchStatus,
			isLoading,
			children,
			error,
			data,
			skipIfHasData = false
		} = this.props;

		if (skipIfHasData && data) {
			return this.fragmented(children(data));
		}

		// for using of isLoading
		if (isLoading !== undefined) {
			if (isLoading) {
				return this.renderPreloader();
			}
			return this.fragmented(children(data));
		}
		// for using of fetchStatus
		if (fetchStatus === FetchStatus.SUCCESS) {
			return this.fragmented(children(data));
		}

		if (fetchStatus === FetchStatus.FAILURE) {
			return this.fragmented(error?.());
		}
		return this.renderPreloader();
	}
}

export default DataFetcher;
