import * as React from 'react';
import { Select } from 'components/antd';
import { AllowedDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { connect } from 'react-redux';
import { getAllowedDocuments } from 'store/portCallDocuments/selectors';
import { AppState } from 'store-types';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';

interface DocumentTypeFieldProps {
	name: string;
	tooltip?: TooltipProps;
	// from mapStateToProps
	documents: AllowedDocument[];
	disabled: boolean;
}

const DocumentTypeField: React.SFC<DocumentTypeFieldProps> = ({
	documents,
	name,
	disabled,
	tooltip
}) => {
	return (
		<MaybeTooltip show={Boolean(tooltip)} {...tooltip}>
			<Select.ReduxFormItem
				name={name}
				placeholder={
					disabled && !tooltip
						? 'Uncheck Ignore Page to select Document Type'
						: 'Select Document Type'
				}
				label="Document Type"
				disabled={disabled}
				required
			>
				{documents.map(document => (
					<Select.Option key={document.code}>{document.name}</Select.Option>
				))}
			</Select.ReduxFormItem>
		</MaybeTooltip>
	);
};

export default connect((state: AppState) => ({
	documents: getAllowedDocuments(state)
}))(DocumentTypeField);
