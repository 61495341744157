import { VesselProgrammeState } from '../vesselProgrammeState';
import {
	RetrieveEventsResponse,
	RetrieveEventsParams,
	RotationStepEventParentType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { Success } from 'typescript-fsa';
import { buildEventsByIdForSingleRotationStep } from './retrieveVP/normalization';
import { FetchStatus } from 'services/api/apiTypes';
import { getVPEventWithGeneratedUniqueIds } from '../utils/adapters';
export function onRetrieveEventsStarted(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			events: FetchStatus.PENDING
		}
	};
}
export function onRetrieveEventsSuccess(
	state: VesselProgrammeState,
	action: Success<RetrieveEventsParams, RetrieveEventsResponse>
): VesselProgrammeState {
	const {
		result,
		params: { stepPosition, draggedUnitId }
	} = action;
	const events = result.elements.map(event => {
		return getVPEventWithGeneratedUniqueIds(event);
	});
	const rotationStepId = state.orderedRotationSteps[stepPosition];
	const retrievedEventsById = buildEventsByIdForSingleRotationStep(
		events,
		rotationStepId,
		RotationStepEventParentType.OPERATION_UNIT
	);
	const retrievedEventIds = events.map(event => event.id);
	return {
		...state,
		eventsById: {
			...state.eventsById,
			...retrievedEventsById
		},
		operationUnitsById: {
			...state.operationUnitsById,
			[draggedUnitId]: {
				...state.operationUnitsById[draggedUnitId],
				events: retrievedEventIds
			}
		},
		fetchStatuses: {
			...state.fetchStatuses,
			events: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveEventsFailed(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			events: FetchStatus.FAILURE
		}
	};
}
