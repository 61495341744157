import { Action } from 'typescript-fsa';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
	initializeEditGroupAsync as async,
	retrieveGroup,
	retrieveGroupAsync
} from '../actions';
import {
	retrieveCompanyGeneralConfigurationReports,
	retrieveCompanyGeneralConfigurationReportsAsync,
	retrieveCompanyBenchmark,
	retrieveCompanyBenchmarkAsync
} from 'store/companies/actions';

import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';

import { Group, GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

export function* worker({ payload: groupId }: Action<string>) {
	yield put(async.started(groupId));
	try {
		yield put(retrieveGroup(groupId));
		const result = yield call(waitAsyncResolve, retrieveGroupAsync);

		if (!result.payload.error) {
			const {
				groupType: { code },
				companyId
			}: Group = result.payload.result;

			if (code === GroupTypeCodes.CUSTOMER) {
				yield put(retrieveCompanyGeneralConfigurationReports({ companyId }));
				yield call(
					waitAsyncResolve,
					retrieveCompanyGeneralConfigurationReportsAsync
				);
			}
			if (code === GroupTypeCodes.CUSTOMER) {
				yield put(retrieveCompanyBenchmark({ companyId }));
				yield call(waitAsyncResolve, retrieveCompanyBenchmarkAsync);
			}
		}

		yield put(
			async.done({
				result: null,
				params: groupId,
				response: null
			})
		);
	} catch (e) {
		yield put(
			async.failed({
				error: e,
				params: groupId
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
