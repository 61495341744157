import React from 'react';
import { Gap, Title, Text, Flex, FormattedTimeExtended } from 'components';
import { FormattedTime } from 'containers';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

interface PortCallDetailsProps {
	portCall: PortCall;
	titlePrefix?: string;
	showExtendedTime?: boolean;
}

const PortCallDetails = ({
	portCall,
	titlePrefix,
	showExtendedTime
}: PortCallDetailsProps) => (
	<>
		<Title.Subject>
			<Text.Box upper weight="bold" bottom="sm">
				{titlePrefix && `${titlePrefix} `}Port Call Details
			</Text.Box>
		</Title.Subject>
		<Gap left="lg" isBlock outside={false}>
			<Flex>
				<Flex>
					<Text.Box right>
						Vessel Name
						<br />
						<Text weight="semi-bold">
							{portCall.vessel && portCall.vessel.name}
						</Text>
					</Text.Box>
				</Flex>
				<Flex>
					<Text.Box left right>
						Port
						<br />
						<Text weight="semi-bold">
							{portCall.port && portCall.port.name}
						</Text>
					</Text.Box>
				</Flex>
				<Flex>
					<Text.Box left right>
						{portCall.eospPlt ? 'EOSP' : 'ETA'}
						<br />
						<Text weight="semi-bold">
							{showExtendedTime ? (
								<FormattedTimeExtended
									value={portCall.eospPlt || portCall.etaPlt}
									directPortTimeZoneId={portCall.portTimezoneId}
								/>
							) : (
								<FormattedTime value={portCall.eospPlt || portCall.etaPlt} />
							)}
						</Text>
					</Text.Box>
				</Flex>
				<Flex>
					<Text.Box left>
						{portCall.cospPlt ? 'COSP' : 'ETS'}
						<br />
						<Text weight="semi-bold">
							{portCall.cospPlt || portCall.etsPlt ? (
								<FormattedTimeExtended
									value={portCall.cospPlt || portCall.etsPlt}
									directPortTimeZoneId={portCall.portTimezoneId}
								/>
							) : (
								'TBD'
							)}
						</Text>
					</Text.Box>
				</Flex>
			</Flex>
		</Gap>
	</>
);

export default PortCallDetails;
