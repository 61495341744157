import { Success, Failure } from 'typescript-fsa';
import { reject } from 'lodash';
import { PortCallDocumentsState } from '../portCallDocumentsState';
import { FetchStatus } from 'services/api/apiTypes';
import { DeletePortCallDocumentPagePayload } from '../actions/deletePortCallDocumentPage';

const updateFetchStatuses = (
	state: PortCallDocumentsState,
	params: DeletePortCallDocumentPagePayload,
	status: FetchStatus
) => ({
	...state.fetchStatuses,
	deleteDocumentPageById: {
		...state.fetchStatuses.deleteDocumentPageById,
		[params.documentId]: {
			...state.fetchStatuses.deleteDocumentPageById[params.documentId],
			[params.actualNumber]: status
		}
	}
});

export const onDeletePortCallDocumentPageStarted = (
	state: PortCallDocumentsState,
	params: DeletePortCallDocumentPagePayload
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.PENDING)
});

export const onDeletePortCallDocumentPageSuccess = (
	state: PortCallDocumentsState,
	{ params }: Success<DeletePortCallDocumentPagePayload, null>
) => {
	const document = !state.document
		? state.document
		: {
				...state.document,
				pages: reject(state.document.pages, {
					actualNumber: params.actualNumber
				})
		  };
	return {
		...state,
		document,
		fetchStatuses: updateFetchStatuses(state, params, FetchStatus.SUCCESS)
	};
};

export const onDeletePortCallDocumentPageFailed = (
	state: PortCallDocumentsState,
	{ params }: Failure<DeletePortCallDocumentPagePayload, Error>
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.FAILURE)
});
