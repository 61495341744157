import { ClonePortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

export const ACTION_NAME = 'CLONE_PORTJOB';
export const clonePortJobAsync = actionCreator.async<
	ClonePortJobRequest,
	void,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Clone Port Job'
	}
});
export const clonePortJob = actionCreator<ClonePortJobRequest>(ACTION_NAME);
