import { ContextType } from './../portJobsState';
import actionCreator from '../portJobsActionCreator';

const SET_PORT_JOB_CONTEXT = 'SET_PORT_JOB_CONTEXT';
export const setPortJobContext = actionCreator<ContextType>(
	SET_PORT_JOB_CONTEXT
);

export default {
	action: setPortJobContext
};
