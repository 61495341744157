import {
	select,
	call,
	put,
	take,
	fork,
	cancel,
	delay
} from 'redux-saga/effects';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import Api from 'services/api';
import { RetrieveSupplierInvoicesParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { getSupplierInvoicesWithoutSplitCount } from '../selectors';
import {
	retrieveSupplierInvoicesAsync as async,
	retrieveSupplierInvoicesCycleStart,
	retrieveSupplierInvoicesCycleStop
} from '../actions';
import getSupplierInvoicesFiltersSaga from './getSupplierInvoicesFiltersSaga';

export function* worker() {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);

		const count = yield select(getSupplierInvoicesWithoutSplitCount);
		const filters = yield call(getSupplierInvoicesFiltersSaga);
		const request: RetrieveSupplierInvoicesParams = {
			index: 0,
			limit: count,
			...filters
		};

		try {
			const response = yield call(
				Api.SupplierInvoice.retrieveSupplierInvoices,
				request
			);
			const payload = {
				result: response?.data,
				params: { ...request, preventConcatExistingInvoices: true },
				response
			};
			yield put(async.done(payload));
		} catch (error) {
			yield put(
				async.failed({
					error,
					params: request
				})
			);
		}
	}
}

export default function*() {
	while (yield take(retrieveSupplierInvoicesCycleStart)) {
		const pollRetrieveSupplierInvoices = yield fork(worker);
		yield take(retrieveSupplierInvoicesCycleStop);
		yield cancel(pollRetrieveSupplierInvoices);
	}
}
