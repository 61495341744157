import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	FinanceRequest,
	ApproveAllServicesResponse
} from 'services/api/finance/financeServiceTypes';

export const approveAllServices = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	FinanceRequest,
	ApproveAllServicesResponse
>('fetchStatuses', 'approveAllServices');
