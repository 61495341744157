import React, { useCallback, useMemo } from 'react';
import { Menu, Popover } from 'components/antd';
import { ClickParam } from 'antd/lib/menu';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { PermissionButton } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { PATHS } from 'sections/App/RouteParams';
import { useGroupsModals } from '../GroupsModals/GroupsModals.hooks';
import { GroupsModalsName } from '../GroupsModals/GroupsModalsTypes';
import { navigateTo } from 'utils';

enum MenuKey {
	EDIT = 'edit',
	DELETE = 'delete'
}

interface GroupsSettingsColumnProps {
	group: Group;
}
function GroupsSettingsColumn({ group }: GroupsSettingsColumnProps) {
	const { name, showDeleteGroupModal } = useGroupsModals();

	const onMenuItemClick = useCallback(
		({ key, domEvent }: ClickParam) => {
			domEvent.stopPropagation();

			switch (key) {
				case MenuKey.EDIT:
					navigateTo(`/${PATHS.groups}/${group.id}/edit`);
					break;
				case MenuKey.DELETE:
					showDeleteGroupModal(group);
					break;
				default:
					break;
			}
		},
		[showDeleteGroupModal, group]
	);

	const selectedKeys = useMemo(
		() => (name === GroupsModalsName.DELETE_GROUP ? [MenuKey.DELETE] : []),
		[name]
	);
	const content = (
		<Menu onClick={onMenuItemClick} selectedKeys={selectedKeys}>
			<Menu.Item key={MenuKey.EDIT}>Edit Group</Menu.Item>
			<Menu.Item key={MenuKey.DELETE}>Delete Group</Menu.Item>
		</Menu>
	);
	return (
		<Popover
			content={content}
			type="dropdown"
			trigger="click"
			placement="bottomRight"
		>
			<PermissionButton
				permissionCode={PermissionCode.MANAGE_GROUP}
				tooltip={{ placement: 'left' }}
				type="primary"
				icon="settings"
				transparent
				onClick={e => e.stopPropagation()}
			/>
		</Popover>
	);
}

export default GroupsSettingsColumn;
