import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { FormData } from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import { FormState } from '../formState';

import { copyFromPreviousPageAsync } from './actions';
import {
	onCopyFromPreviousPageStarted,
	onCopyFromPreviousPageSuccess,
	onCopyFromPreviousPageFailed
} from './reducers';

export type OperationsContextualizationFormState = FormState<FormData>;

const contextualizationFormState: OperationsContextualizationFormState = {
	values: {} as FormData
};

export default reducerWithInitialState(contextualizationFormState)
	.case(copyFromPreviousPageAsync.started, onCopyFromPreviousPageStarted)
	.case(copyFromPreviousPageAsync.done, onCopyFromPreviousPageSuccess)
	.case(copyFromPreviousPageAsync.failed, onCopyFromPreviousPageFailed);
