import { FetchStatus } from 'services/api/apiTypes';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { ThreadReducerState } from 'store/thread/threadState';
import {
	AssignJobToThreadSuccessAction,
	AssignJobToThreadFailureAction
} from '../actions/unassignJobFromThread';
export const onUnassignJobFromThreadStarted = (state: ThreadReducerState) => ({
	...state,
	fetchStatuses: { ...state.fetchStatuses, updating: FetchStatus.PENDING }
});
export const onUnassignJobFromThreadSuccess = (
	state: ThreadReducerState,
	action: AssignJobToThreadSuccessAction
) => ({
	...state,
	fetchStatuses: { ...state.fetchStatuses, updating: FetchStatus.SUCCESS },
	item: (state.item
		? {
				...state.item,
				jobCodes: state.item.jobCodes.filter(
					code => code !== action.params.jobCode
				)
			}
		: {}) as ThreadItem
});
export const onUnassignJobFromThreadFailed = (
	state: ThreadReducerState,
	action: AssignJobToThreadFailureAction
) => ({
	...state,
	fetchStatuses: { ...state.fetchStatuses, updating: FetchStatus.FAILURE },
	error: action.error
});
