import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
	getActivePortCallId,
	getActivePortCall
} from 'store/portcalls/selectors';
import {
	EDIT_PORTCALL_FORM,
	EditPortCallFormData
} from './editPortCallDataTypes';
import { updatePortCall, updatePortCallAsync } from 'store/portCall/actions';
import { getValues } from 'store/form/selectors';
import { UpdatePortCallRequest } from 'services/api/portCall/portCallServiceTypes';
import Api from 'services/api';
import { navigateTo } from 'utils';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { isConcurrencyError } from 'services/api/apiErrorUtils';
import { setPortCallUpToDate } from 'store/portCall/actions/setPortCallUpToDate';

export function* worker(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const formValues: EditPortCallFormData = yield select(
		getValues,
		EDIT_PORTCALL_FORM
	);
	const { concurrencyToken }: PortCall = yield select(getActivePortCall);
	const externalCode = (formValues.vessel.label as string)
		.split('(')[2]
		?.split(')')[0];
	const payload: UpdatePortCallRequest = {
		portCallId,
		payload: {
			vessel: {
				id: formValues.vessel.key,
				// TODO: this part will be reworked: 'imo' part shouldn't be sent
				// it was done to avoid situation with name: `${vessel.name} (${imo})`
				name: (formValues.vessel.label as string).split(' (')[0]
			},
			etaPlt: getLocalDateDefaultFormatted(formValues.etaPlt),
			etsPlt: formValues.etsPlt
				? getLocalDateDefaultFormatted(formValues.etsPlt)
				: null,
			externalCode: externalCode,
			concurrencyToken
		}
	};
	yield put(updatePortCallAsync.started(payload));
	try {
		const response = yield call(Api.PortCall.updatePortCall, payload);
		yield put(
			updatePortCallAsync.done({
				params: payload,
				result: response,
				response
			})
		);
		yield call(navigateTo, `/portcalls/${portCallId}/overview`);
	} catch (error) {
		yield put(
			updatePortCallAsync.failed({
				error,
				params: payload
			})
		);
		if (isConcurrencyError(error)) {
			yield put(setPortCallUpToDate(false));
		}
	}
}

export default function*() {
	yield takeLatest(updatePortCall.type, worker);
}
