import { LabeledValueItem } from 'app-types';

export const MOVE_SERVICE_FORM = 'MOVE_SERVICE_FORM';

export interface MoveServiceFormData {
	section: LabeledValueItem;
	serviceCode: LabeledValueItem;
}

export enum MoveServiceField {
	SECTION = 'section',
	SERVICE_CODE = 'serviceCode'
}

export const MoveServiceLabel = {
	[MoveServiceField.SECTION]: 'Section',
	[MoveServiceField.SERVICE_CODE]: 'Service Code'
};
