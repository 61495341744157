export enum ActionType {
	ENABLE_LINK_TRACKING = 'enableLinkTracking',
	SET_TRACKER_LINK = 'setTrackerUrl',
	SET_SITE_ID = 'setSiteId',
	TRACK_PAGE_VIEW = 'trackPageView',
	SET_CUSTOM_URL = 'setCustomUrl',
	SET_DOCUMENT_TITLE = 'setDocumentTitle',
	SET_USER_ID = 'setUserId',
	RESET_USER_ID = 'resetUserId',
	SET_CUSTOM_DIMENSION = 'setCustomDimension',
	ENABLE_JS_ERRORS_TRACKING = 'enableJSErrorTracking',
	TRACK_EVENT = 'trackEvent',
	CONTENT_IMPRESSIONS_NODE = 'trackContentImpressionsWithinNode'
}

export enum CustomDimension {
	USER_TYPE = 1
}
