import { Success } from 'typescript-fsa';
import { ConfigurationFilesState } from '../configurationFilesState';
import {
	UpdateCompanyTemplateFileRequest as Request,
	UpdateCompanyTemplateFileResponse as Response
} from 'services/api/templates/templatesServiceTypes';

export const onUpdateConfigurationFileSuccess = (
	state: ConfigurationFilesState,
	{ result }: Success<Request, Response>
): ConfigurationFilesState => ({
	...state,
	byId: {
		...state.byId,
		[result.id]: result
	}
});
