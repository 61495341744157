import * as React from 'react';
import { connect, useDispatch } from 'react-redux';

import { Flex, PageHeader, Status, Link } from 'components';
import { Row, Col, Tooltip, Icon } from 'components/antd';
import { PermissionWrapper, PermissionsCheckMode } from 'components/Permission';
import DefaultMessagingGroupSelect from './DefaultMessagingGroupSelect';
import { EntityPermissionFetcher } from 'containers';
import { Justify } from 'components/types';
import {
	PortJob,
	PortJobTypeCode,
	LinkedPortJobs
} from 'services/api/portJobs/portJobsServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	PermissionCode,
	EntityPermissionType
} from 'services/api/permissions/permissionsServiceTypes';
import { AppState } from 'store-types';

import { getUserType } from 'store/auth/selectors';
import {
	lookupPortJobStatusLabel,
	getActivePortJob
} from 'store/portJobs/portJobsSelectors';
import styles from './JobDetails.module.scss';
import { setActivePortJobCode } from 'store/portJobs/actions';
import { navigateTo } from 'utils';
import classNames from 'classnames';
import { setPortCallContext } from 'store/portcalls';

interface JobInfoHeaderProps {
	userType: UserType;
	activePortJob: PortJob;
	portCallId: string;
}

export const JobInfoHeader: React.SFC<JobInfoHeaderProps> = ({
	portCallId,
	activePortJob: {
		code,
		status,
		financeStatus,
		jobType,
		queriedToHub,
		queriedToLPA,
		isCancelled,
		linkedPortJobDetails
	},
	userType
}: JobInfoHeaderProps) => {
	const dispatch = useDispatch();

	const onJobCodeChange = React.useCallback(
		(jobDetails: LinkedPortJobs) => {
			dispatch(setActivePortJobCode(jobDetails.portJobCode));
			if (portCallId !== jobDetails.portCallId) {
				dispatch(
					setPortCallContext({
						activePortCallId: jobDetails.portCallId
					})
				);
			}
			navigateTo(
				`/portcalls/${jobDetails.portCallId}/jobs/${jobDetails.portJobCode}/overview`
			);
		},
		[portCallId, dispatch]
	);

	const getLinkedJobs = React.useCallback(() => {
		return linkedPortJobDetails.map(job => (
			<p className={styles.tooltipContent}>
				<Link
					onClick={() => onJobCodeChange(job)}
					disabled={!job.hasPermission}
					className={classNames(styles.multipleTooltipLinks, {
						[styles.disabledLink]: !job.hasPermission
					})}
					tooltip={
						!job.hasPermission
							? {
									title: `You don't have permission to view this Job`,
									placement: 'right'
							  }
							: undefined
					}
				>
					<span>{job.portJobCode}</span>
					{job.isLeadNomination && <span> (Lead)</span>}
				</Link>
			</p>
		));
	}, [linkedPortJobDetails, onJobCodeChange]);

	const showDefaultMessagingGroupDropdown = userType === UserType.HUB;

	return (
		<PageHeader stretch row className={styles.header}>
			<Row>
				<Col xs={12} sm={12}>
					<Flex row justify={Justify.BETWEEN} align="center">
						<h1 className={styles.jobType}>
							Job Type:
							<span className={styles.jobType}>
								{jobType === PortJobTypeCode.CHARTERER_OVERSIGHT
									? 'Charterers Oversight'
									: jobType}
							</span>
						</h1>
						{linkedPortJobDetails.length > 0 &&
							userType !== UserType.PRINCIPAL && (
								<h1 className={styles.linkedJob}>
									Linked job:
									{linkedPortJobDetails.length === 1 ? (
										<Link
											disabled={!linkedPortJobDetails[0].hasPermission}
											onClick={() => onJobCodeChange(linkedPortJobDetails[0])}
											className={
												!linkedPortJobDetails[0].hasPermission
													? styles.disabledLink
													: ''
											}
											tooltip={
												!linkedPortJobDetails[0].hasPermission
													? {
															title: `You don't have permission to view this Job`
													  }
													: undefined
											}
										>
											{linkedPortJobDetails[0].portJobCode}
										</Link>
									) : (
										<>
											<span>Multiple jobs </span>
											<Tooltip title={getLinkedJobs()} placement="top">
												<Icon type="info" color="success" size="md" />
											</Tooltip>
										</>
									)}
								</h1>
							)}

						{showDefaultMessagingGroupDropdown && (
							<Flex
								justify={Justify.START}
								align="center"
								className={styles.defaultMessagingGroupWrapper}
							>
								<h1>Default messaging group for automatic emails:</h1>
								<PermissionWrapper
									permissionCode={[
										PermissionCode.VIEW_MESSAGES,
										PermissionCode.MANAGE_PORTCALL
									]}
									permissionsCheckMode={PermissionsCheckMode.AND}
									message="You don't have appropriate permissions to edit this field"
								>
									{hasPermission => (
										<EntityPermissionFetcher
											entityKey={code}
											entityType={EntityPermissionType.PORTJOB}
											showLoader={false}
											skipIfHasData
										>
											{({ canUpdate }) => (
												<DefaultMessagingGroupSelect
													portCallId={portCallId}
													jobCode={code}
													disabled={!hasPermission || !canUpdate}
												/>
											)}
										</EntityPermissionFetcher>
									)}
								</PermissionWrapper>
							</Flex>
						)}
						<h1>
							Status:
							<Status.Label
								value={lookupPortJobStatusLabel(
									status,
									financeStatus,
									userType,
									queriedToHub,
									queriedToLPA,
									isCancelled
								)}
								generic
								className={styles.statusLabel}
							/>
						</h1>
					</Flex>
				</Col>
			</Row>
		</PageHeader>
	);
};

export default connect((state: AppState) => ({
	activePortJob: getActivePortJob(state),
	userType: getUserType(state)
}))(JobInfoHeader);
