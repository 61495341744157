import { Success } from 'typescript-fsa';
import { put, select } from 'redux-saga/effects';
import { inviteAgentToPortJobAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getPortJobConcurrencyTokenByCode } from '../portJobsSelectors';
import {
	InviteAgentToPortJobPayload,
	InviteAgentToPortJobParams,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { closeModal } from 'store/modals/actions';
import { retrievePortCall } from 'store/portcalls';

export default makeTakeLatestWatcher<
	InviteAgentToPortJobPayload,
	PortJobStatusUpdateResponse,
	Error
>({
	async,
	api: Api.PortJobs.inviteAgentToPortJob,
	*getApiParams(params: InviteAgentToPortJobParams) {
		yield put(closeModal(params.modalId));
		const concurrencyToken = yield select(
			getPortJobConcurrencyTokenByCode,
			params.jobCode
		);
		return {
			...params,
			concurrencyToken
		};
	},
	*onSuccess({
		params
	}: Success<InviteAgentToPortJobPayload, PortJobStatusUpdateResponse>) {
		yield put(
			retrievePortCall({ id: params.portCallId, expand: 'PortJobsBasic' })
		);
	}
});
