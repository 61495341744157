import React, { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'components/antd';
import { TooltipProps } from 'antd/lib/tooltip';
import { EntityPermissionFetcher } from 'containers';
import {
	EntityPermission,
	EntityPermissionComposeId
} from 'services/api/permissions/permissionsServiceTypes';
import { getIsEntityPermissionLoading } from 'store/permissions/selectors/entityPermissionSelectors';
import { AppState } from 'store-types';
import { findKey } from 'lodash';
import styles from './EntityPermissionTooltipWrapper.module.scss';

interface EntityPermissionTooltipWrapperProps
	extends EntityPermissionComposeId {
	permission?: keyof EntityPermission;
	message?: string;
	tooltip?: Partial<TooltipProps>;
	showLoader?: boolean;
	skipIfHasData?: boolean;
	checkAllPermissions?: boolean;
	children: (
		entityPermission: EntityPermission,
		isLoading: boolean,
		hasNoPermission: boolean
	) => React.ReactNode;
}

const getHasNoPermission = (entityPermission: EntityPermission): boolean =>
	!findKey(entityPermission, permission => permission);

const EntityPermissionTooltipWrapper: FC<EntityPermissionTooltipWrapperProps> = ({
	entityType,
	entityKey,
	message = `You don't have permission to perform this action`,
	tooltip = {},
	showLoader = false,
	skipIfHasData,
	checkAllPermissions,
	permission,
	children
}) => {
	const isLoading = useSelector((state: AppState) =>
		getIsEntityPermissionLoading(state, { entityType, entityKey })
	);
	const getShouldShowTooltip = useCallback(
		(entityPermission: EntityPermission): boolean => {
			if (checkAllPermissions) {
				return getHasNoPermission(entityPermission);
			}
			return !!permission && !entityPermission[permission];
		},
		[checkAllPermissions, permission]
	);

	return (
		<EntityPermissionFetcher
			entityType={entityType}
			entityKey={entityKey}
			showLoader={showLoader}
			skipIfHasData={skipIfHasData}
		>
			{entityPermission => {
				if (getShouldShowTooltip(entityPermission)) {
					return (
						<Tooltip {...tooltip} title={message} trigger="hover">
							<span className={styles.wrapper}>
								{children(
									entityPermission,
									isLoading,
									getHasNoPermission(entityPermission)
								)}
							</span>
						</Tooltip>
					);
				}
				return (
					<>
						{children(
							entityPermission,
							isLoading,
							getHasNoPermission(entityPermission)
						)}
					</>
				);
			}}
		</EntityPermissionFetcher>
	);
};

export default EntityPermissionTooltipWrapper;
