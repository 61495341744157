import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse
} from 'services/api/threads/threadsServiceTypes';

export const onRetrieveThreadsMainPrincipalTagsSuccess = (
	state: ThreadsState,
	{
		result
	}: Success<RetrieveThreadsMainPrincipalTagsParam, RetrieveThreadTagsResponse>
): ThreadsState => ({
	...state,
	mainPrincipalThreadsTags: result.elements
});

export const onRetrieveThreadsMainPrincipalTags = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse
>('fetchStatuses', 'retrievingThreadsTags', {
	doneReducer: onRetrieveThreadsMainPrincipalTagsSuccess
});
