import runValidations, { required } from 'utils/validations';
import {
	FormData,
	FormDataErrors
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { CrewChangeFormNames } from './CrewChange';
import { first } from 'lodash';

const reverseMap = {
	[CrewChangeFormNames.OFF_SIGNERS]: CrewChangeFormNames.ON_SIGNERS,
	[CrewChangeFormNames.ON_SIGNERS]: CrewChangeFormNames.OFF_SIGNERS
};

const isMoreThanZero = (value: string) => parseInt(value, 10) > 0;
const isMoreThanZeroValidation = (value: string) =>
	isMoreThanZero(value) ? undefined : 'value needs to be more than 0';

const validateField = (name: CrewChangeFormNames, values: FormData) => {
	const secondFieldName = reverseMap[name];
	const valueOfSecondField = values[secondFieldName];
	if (isMoreThanZero(valueOfSecondField)) {
		return;
	}
	return first(
		runValidations(values[name], [required, isMoreThanZeroValidation])
	);
};

const validate = (values: FormData): FormDataErrors => ({
	[CrewChangeFormNames.ON_SIGNERS]: validateField(
		CrewChangeFormNames.ON_SIGNERS,
		values
	),
	[CrewChangeFormNames.OFF_SIGNERS]: validateField(
		CrewChangeFormNames.OFF_SIGNERS,
		values
	)
});

export default validate;
