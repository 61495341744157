import { put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	EMAIL_FRAGMENTS_FORM_NAME,
	FormData
} from 'sections/Administration/Configuration/EmailFragments/EmailFragmentsFormTypes';
import { RetrieveCompanyTemplatesResponse } from 'services/api/templates/templatesServiceTypes';
import { initialize } from 'redux-form';

import { getConfigurationEmailFragmentsFormValues } from '../selectors';

const prepareDataWithDefaultValues = (
	data: RetrieveCompanyTemplatesResponse
): FormData => ({
	list: data.map(item => {
		return {
			...item,
			options: item.options.map(option => {
				if (option.value) {
					return option;
				}
				return {
					...option,
					value: option.values[0].code
				};
			})
		};
	})
});

export default function* initializeConfigurationEmailFragmentsFormWorker(
	response?: RetrieveCompanyTemplatesResponse
): SagaIterator {
	let data: FormData | undefined;
	if (response) {
		data = prepareDataWithDefaultValues(response);
	} else {
		const formValues = yield select(getConfigurationEmailFragmentsFormValues);
		data = prepareDataWithDefaultValues(formValues.list);
	}

	if (!data) {
		return;
	}

	/**
	 * Form initialization resets form, e.g. initialValues, pristine state, etc.
	 */
	yield put(initialize(EMAIL_FRAGMENTS_FORM_NAME, data));
}
