import { all, call, fork, put, select } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveDocumentAsync, retrieveDocumentImageAsync } from '../actions';
import {
	RetrieveDocumentRequest,
	RetrieveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
import {
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { getActivePortCallTabId } from 'store/portcalls/selectors';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import { getIsSupplierInvoiceContextualization } from 'store/supplierInvoice/selectors';

const retrieveDocumentWatcher = makeTakeEveryWatcher<
	RetrieveDocumentRequest,
	RetrieveDocumentResponse,
	Error
>({
	api: Api.Documents.retrieveDocument,
	async: retrieveDocumentAsync
});

export function* retrieveDocumentImageExecutor(
	params: RetrievePortCallDocumentImageRequest
): SagaIterator {
	const async = retrieveDocumentImageAsync;

	yield put(async.started(params));

	try {
		const currentTabId = yield select(getActivePortCallTabId);
		const isSupplierInvoice = yield select(
			getIsSupplierInvoiceContextualization
		);
		let apiCall =
			currentTabId === PortCallTabKey.FINANCE
				? Api.Finance.retrieveFinanceDocumentImage
				: Api.PortCallDocuments.retrievePortCallDocumentImage;
		if (isSupplierInvoice) {
			apiCall = Api.Finance.retrieveSupplierInvoiceImage;
		}
		const response = yield call(apiCall, params);
		const payload = {
			result: response?.data,
			params,
			response
		};
		yield put(async.done(payload));
	} catch (error) {
		yield put(
			async.failed({
				error,
				params
			})
		);
	}
}

const retrieveDocumentImageWatcher = makeTakeEveryWatcher<
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse,
	Error
>(
	{
		async: retrieveDocumentImageAsync
	},
	retrieveDocumentImageExecutor
);

export default function*() {
	yield all([
		fork(retrieveDocumentWatcher),
		fork(retrieveDocumentImageWatcher)
	]);
}
