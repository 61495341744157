import { Entity, EntityCode, LabeledValue } from 'app-types';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import {
	PagedRequest,
	SearchRequest,
	ResultCollection
} from 'services/api/apiTypes';
import { FinanceStage } from 'services/api/finance/financeServiceTypes';
import { UploadType } from '../documents/documentsServiceTypes';

export enum MainPrincipalTypeCodes {
	CLS = 'CLS',
	COM = 'COM'
}

export enum OrganisationType {
	ISS = 'ISS',
	OPERATING_ENTITY = 'OperatingEntity',
	LPA = 'ThirdPartyLPA',
	ISS_LPA = 'ISSLPA',
	MAIN_PRINCIPAL = 'MainPrincipal',
	PRINCIPAL = 'Principal',
	CHARTERER = 'Charterer',
	SHIPPER = 'ShipperReceiver',
	VENDOR = 'Vendor'
}

interface MainPrincipalType {
	code: MainPrincipalTypeCodes;
	name: string;
}

export interface CompanyOrganisationType {
	companyOrganisationTypeId: string;
	companyId: string;
	companyName: string;
	organisationTypeName: string;
	organisationTypeCode: OrganisationType;
}

export interface ParentCompany {
	id: string;
	companyName: string;
	code: string;
}

export interface CompanyAddress {
	id: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressLine4: string;
	state: string;
	postalCode: string;
	city: string;
	countryCode: string;
	isDeleted: boolean;
	createdOn: string;
	updatedOn: string;
}

export interface Company {
	id: string;
	companyName: string;
	code: string;
	companyEmail: string;
	parentCompany: ParentCompany;
	isActive: boolean;
	isDeleted: boolean;
	createdOn: string;
	updatedOn: string;
	mailingAddress: CompanyAddress;
	billingAddress: CompanyAddress;
	organisationTypes: CompanyOrganisationType[];
	mainPrincipalType: MainPrincipalType;
	isReadyToGo?: boolean;
}

export type CompaniesContactDetails = Entity;

export interface SearchedCompany extends Company {
	// Coming from API
	id: string;
	name: string;
	// Custom props added in reducer
	groupType: string;
}

export interface CompanyVesselData {
	companyVesselTypeMapping: Entity;
	email: string;
	externalCode: string;
	additionalContactDetails: string;
	fax: string;
	imo: string;
	mobilePhone: string;
	operatorEmails: string[];
	telephone1: string;
	telephone2: string;
	telex1: string;
	telex2: string;
	gtLessSBT: number;
	generalVesselDetails: string;
}

export interface CreateCompanyVesselRequest {
	data: CompanyVesselData;
	companyId: string;
}

export interface UpdateCompanyVesselRequest {
	data: CompanyVesselData;
	companyId: string;
	companyVesselId: string;
}

export interface RetrieveCompanyVessel extends CompanyVesselData {
	id: string;
	vessel: Entity;
	createdOn: string;
	updatedOn: string;
	mappingCount: number;
}

export type CompanyVessel = RetrieveCompanyVessel & {
	companyId: string;
	companyName?: string;
	vesselId?: string;
};

export interface RetrieveCompanyJobTypesRequest {
	companyId: string;
}

export interface RetrieveCompanyJobTypesResponse {
	jobTypes: string[] | null;
}

// Companies For User
// ---------------------
export interface RetrieveCompaniesForUserRequest extends PagedRequest {
	ids?: string[];
	onlyActive?: boolean;
	withParentId?: string | null;
	isNonIssHubPrincipal?: boolean;
}

export type RetrieveCompaniesForUserResponse = ResultCollection<Company>;

// Companies
// ---------------------
export interface RetrieveCompaniesRequest
	extends RetrieveCompaniesForUserRequest {
	organisationTypes?: OrganisationType[];
	actionType?: PermissionCode[];
	forOperationalDashboard?: boolean;
}

export type RetrieveCompaniesResponse = ResultCollection<Company>;
export interface RetrievePrincipalsForMainPrincipalRequest {
	mainPrincipalId: string;
	actionType?: PermissionCode;
}

export interface SearchCompaniesRequest extends RetrieveCompaniesRequest {
	searchTerm?: string;
	onlyActive?: boolean; // @TODO Review this param,
	types?: OrganisationType[];
	isNonIssHubPrincipal?: boolean;
}

export interface RetrieveCompaniesContactDetailsRequest {
	companyId?: string;
	includingChildCompanies: boolean;
	getParentCompany?: boolean;
}
export interface SearchVesselsByCompanyRequest
	extends PagedRequest,
		SearchRequest {
	companyId: string;
}

export type RetrieveCompaniesContactDetailsResponse = ResultCollection<
	CompaniesContactDetails
>;

// Company General Configuration: Retrieve/Update
// ---------------------

export interface RetrieveCompanyGeneralConfigurationRequest {
	companyId: string;
}

export type RetrieveCompanyGeneralConfigurationResponse = RetrieveCompanyGeneralConfigurationBaseResponse<
	CompanyGeneralConfigurationDetails
>;

export type UpdateCompanyGeneralConfigurationRequest = RetrieveCompanyGeneralConfigurationBaseResponse<
	CompanyGeneralConfigurationDetails
>;

export interface RetrieveCompanyGeneralConfigurationBaseResponse<T = {}> {
	companyId: string;
	isReadyToGo: boolean;
	details: T;
}

export interface CompanyConfigurationEmail {
	email: string;
	group: Entity;
	isEnabled: boolean;
	isSplitThreadByGroup?: boolean;
}

interface CompanyEmailSettings {
	centralInboxEmail?: CompanyConfigurationEmail;
	secondaryMailboxes?: CompanyConfigurationEmail[];
}

export interface CompanyFundingSettings {
	sendFunding: boolean;
	fundingPercentage?: number;
	fundingStage?: FinanceStage;
}

export type PrincipalMapping = EntityCode & { isSelected: boolean };

export interface BiDashboardConfiguration {
	includeOperationalData: boolean;
	allowOperationalDashboard: boolean;
	allowFinancialDashboard: boolean;
}

export interface ApprovalLevelSettings {
	level: number;
	thresholdValue?: number | string;
}

export interface ApprovalSettings {
	isApprovalRequired: boolean;
	isMultilevelApprovalRequired?: boolean;
	levels?: ApprovalLevelSettings[];
	isBypassApprovalRequired?: boolean;
	isMandateApprovalRequired?: boolean;
	bypassTotalAmount?: number | string;
	isBypassPrincipalReApprovalRequired?: boolean;
}

export interface CommonSettings {
	usesExchangeRate?: string;
	pdaApprovalSettings: ApprovalSettings;
	ceApprovalSettings: ApprovalSettings;
	daApprovalSettings: ApprovalSettings;
	defaultBankAccountCurrencyCode: string | null;
	sendCustomerAlertsOption?: string;
	accessToPortCallMessagesEnabled: boolean;
	appointmentIsUsed: boolean;
	nominationIsUsed: boolean;
	charterersOversightIsUsed: boolean;
	isAppointmentFinanceDisabled: boolean;
	isNominationFinanceDisabled: boolean;
	isChartererOversightFinanceDisabled: boolean;
	isAppointmentOperationsDisabled: false;
	isChartererOversightOperationsDisabled: false;
	isNominationOperationsDisabled: false;
	nominationOption?: string;
	defaultMasterFeedback: boolean;
	benchmarkIsUsed: boolean;
}

export interface CompanyGeneralConfigurationDetails {
	commonSettings: CommonSettings;
	fundingSettings: CompanyFundingSettings;
	sendCopyToOperatorsEmail: boolean;
	appointmentAcceptanceInstruction: string;
	nominationAcceptanceInstruction: string;
	emailSettings: CompanyEmailSettings;
	issOperatorContacts: CompanyISSOperatorContact[];
	operationsMapping: PrincipalMapping[];
	tagMapping: PrincipalMapping[];
	reportsSettings: {
		hasAccessToReportServer: boolean;
	};
	biDashboardConfiguration: BiDashboardConfiguration;
	principalIntegrationSettings: PrincipalIntegrationSettings;
}

export interface PrincipalIntegrationSettings {
	isPrincipalIntegrationEnabled: boolean;
	integrationUserId?: string;
	token?: string;
	generateToken?: boolean;
	isAccessible?: boolean;
}

export interface CompanyISSOperatorContact {
	unRegion: Entity;
	email?: string;
	telephone?: string;
}

export interface RetrieveCompanyGeneralConfigurationReportsRequest {
	companyId: string;
}
export interface RetrievePrincipalIntegrationSettingsRequest {
	companyId: string;
}
export interface RetrieveCompanyGeneralConfigurationReportsResponse {
	hasAccessToReportServer: boolean;
}
// ---------------------

export interface RetrieveCompanyVesselTypesRequest {
	companyId: string;
}
export interface RetrieveCompanyVesselType {
	id: string;
	name: string;
	code?: string;
	externalCode?: string | null;
	createdOn: string;
	updatedOn: string;
}
export type RetrieveCompanyVesselTypesResponse = ResultCollection<
	RetrieveCompanyVesselType
>;

export type CreateUpdateCompanyVesselTypeRequestData = Pick<
	RetrieveCompanyVesselType,
	'name' | 'externalCode'
>;

export interface CreateUpdateCompanyVesselTypeRequestBase {
	companyId: string;
	data: CreateUpdateCompanyVesselTypeRequestData;
}

export type CreateCompanyVesselTypeRequest = CreateUpdateCompanyVesselTypeRequestBase;
export type CreateCompanyVesselTypeResponse = string;

export type UpdateCompanyVesselTypeRequest = CreateCompanyVesselTypeRequest & {
	id: string;
};
export type UpdateCompanyVesselTypeResponse = null;

export type DeleteCompanyVesselTypeRequest = RetrieveCompanyVesselTypesRequest & {
	id: string;
};

export type DeleteCompanyVesselTypeResponse = null;

export type RetrieveCompanyVesselsRequest = PagedRequest & {
	companyId: string;
};

export interface RetrieveCompanyVesselRequest {
	companyId: string;
	vesselId: string;
}

export interface RetrieveCompanyVesselByIdRequest
	extends RetrieveCompanyVesselRequest {
	id?: string;
}

export type RetrieveCompanyVesselsResponse = ResultCollection<
	RetrieveCompanyVessel
>;

export type RetrieveCompanyVesselResponse = RetrieveCompanyVessel;
export interface RetrieveCompanyVesselByIdResponse {
	companyId: string;
	companyVesselDetails: RetrieveCompanyVessel;
}

export interface DeleteCompanyVesselRequest {
	id: string;
	companyId: string;
}

export type DeleteCompanyVesselResponse = null;

// Companies For Organisation
// ---------------------

export interface RetrieveCompaniesForOrganisationRequest
	extends RetrieveCompaniesRequest {
	organisationType: OrganisationType;
}
export type RetrieveCompaniesForOrganisationResponse = ResultCollection<
	Company
>;

// Upload/Retrieve Files
// ---------------------

export interface UploadCompanyPreferredAgentsFileRequest {
	companyId: string;
	fileName: string;
	documentId: string;
}

export interface RetrieveCompanyPreferredAgentsFileInfoRequest {
	companyId: string;
}

export type RetrieveCompanyPreferredAgentsFileInfoResponse =
	| {
			id: string;
			fileName: string;
			uploadedAtUtc: string;
			uploadedBy?: string;
	  }
	| undefined;

// Configuration Settings
// ---------------------

export type RetrieveCompaniesConfigurationSettingsResponse = CompaniesConfigurationSettings;
export interface CompaniesConfigurationSettings {
	centralInboxEmailDomain: string;
}

export interface RetrieveConfigurationBenchmarkRequest {
	companyId: string;
}
export interface ConfigurationBenchmarkData {
	isBenchmarksEnabled: boolean;
}

export interface UpdateConfigurationBenchmarkRequest {
	companyId: string;
	data: ConfigurationBenchmarkData;
}

export interface RetrieveConfigurationFeedbackManagementRequest {
	companyId: string;
}
export interface ConfigurationFeedbackManagementData {
	isFeedbackManagementEnabled: boolean;
}

export interface FeedbackManagementRequestId {
	feedbackId: string;
}

export interface EventListAndOpticVariablesData {
	triggerEvent: string[];
	opticVariable: string[];
}

export interface CreateFeedbackManagementRequest
	extends FeedbackManagementRequestId {
	name: string;
	companyId: string;
	isCompanyEmail: boolean;
	isVesselEmail: boolean;
	isCustomEmail: boolean;
	jobType: string[];
	isFirstInstance: boolean;
	customEmails: string[];
	emailContent: string;
	onlineSurvey: boolean;
	surveyUrl: string;
	documentId: string[];
	feedbackEvent: string[];
	feedbackVariables: LabeledValue[];
}

export interface FeedbackVariables {
	id: string;
	surveyVariable: string;
	opticVariable: string;
}

export interface FeedbackDocument extends FeedbackManagementRequestId {
	id: string;
	documentId: string;
	documentType: UploadType;
	documentName: string;
	documentCreatedBy: string;
	documentCreatedOn: string;
	documentContentType: string;
}

export interface RetrieveFeedbackManagementGridResponse {
	id: string;
	name: string;
	companyId: string;
	isCompanyEmail: boolean;
	isVesselEmail: boolean;
	isCustomEmail: boolean;
	isFirstInstance: boolean;
	customEmails: string[];
	emailContent: string;
	onlineSurvey: boolean;
	surveyUrl: string;
	feedbackDocuments: FeedbackDocument[];
	feedbackEvents: string[];
	feedBackJobType: string[];
	feedBackVariables: FeedbackVariables[];
}

export interface UpdateConfigurationFeedbackManagementRequest {
	companyId: string;
	data: ConfigurationFeedbackManagementData;
}

export interface RetrieveExtendedMessagingRequest {
	companyId: string;
}

export interface ConfigurationExtendedMessagingData {
	extendedMessagingOption: string;
}

export interface UpdateConfigurationExtendedMessageRequest {
	companyId: string;
	data: ConfigurationExtendedMessagingData;
}

export interface MainContactResponse {
	id: string;
	fullName: string;
}
