// SPLIT OPERATION MODAL SELECTORS
import { createSelector } from 'reselect';
import { getOperationById } from '../selectors';
import { AppState } from 'store-types';

export const isSplitOperationUnitModalVisible = (state: AppState) =>
	Boolean(state.vesselProgramme.splitModalOperationUnitId);
export const getOperationForSplitModal = (state: AppState) => {
	const { splitModalOperationUnitId } = state.vesselProgramme;
	return splitModalOperationUnitId
		? getOperationById(state, splitModalOperationUnitId)
		: undefined;
};

const getParentOperationId = (state: AppState, operationId: string) =>
	state.vesselProgramme.operationUnitsById[operationId].parentOperationUnitId;
const getSplittedOperations = (state: AppState) =>
	state.vesselProgramme.splittedUnitsByParentUnitId;

const getOperationDetails = (state: AppState, operationId: string) =>
	state.vesselProgramme.operationUnitsById[operationId];
export const getOperationTransshipmentVesselName = createSelector(
	getOperationDetails,
	operation => operation.transshipmentVesselName
);
export const getOperationIsTransshipment = createSelector(
	getOperationDetails,
	operation => operation.isTransshipment
);

export const getSplittedOperationIdsByOperationId = createSelector(
	getParentOperationId,
	getSplittedOperations,
	(parentOperationId, splittedOperations) =>
		parentOperationId && splittedOperations[parentOperationId]
);
