import { Theme } from 'components/types';

export const DEFAULT_CURRENCY_CODE = 'USD';
export const DEFAULT_TEXTAREA_MAX_LENGTH = 32000;
export const DEFAULT_INPUTNUMBER_MAX_DIGITS = 10;
export const DEFAULT_LIST_LIMIT = 20;
export const DEFAULT_MAX_POLL_ATTEMPTS = 50;
export const DEFAULT_DEBOUNCE_DELAY = 500;
export const DEFAULT_POLLING_INTERVAL = 30000;
export const DEFAULT_IGNORED_CODES = [400, 404];
export const DEFAULT_UPDATE_CONFLICT_CODE = 412;
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const DEFAULT_DECIMAL_SEPARATOR = '.';
export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const isEMDDraftEnabled = true;
export const DEFAULT_NO_VALUE_PLACEHOLDER = '--';
export const DEFAULT_NOT_AVAILABLE_PLACEHOLDER = 'N/A';
export const DEFAULT_SELECT_PLACEHOLDER = '-- Select --';
export const DEFAULT_AUTOCOMPLETE_PLACEHOLDER = 'Type to search';
export const DEFAULT_NOT_PROVIDED_PLACEHOLDER = 'Not provided';
/** Set the max length of `searchTerm`s param that can be sent to the API during search */
export const DEFAULT_AMOUNT_OF_SEARCH_TERM = 10;
export const DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT = 'No results found';
export const DEFAULT_EMAIL_VALIDATION_REGEX =
	"^((([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-z]|\\d|-||_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)*(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+|(([a-z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+([a-z]+|\\d|-|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))$";
export const DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE = `Sorry, the page can't be opened: you don't have permission to access the page or the page doesn't exist.`;
export const DEFAULT_DECIMAL_PLACES = 2;
export const DEFAULT_NO_TAX_SHORT_TEXT = 'No Tax';
export const DEFAULT_NO_TAX_LONG_TEXT =
	'Tax rate will be applied after specifying of Liable Party for DA OUT';
export const UNSPECIFIED = 'Unspecified';
export const USER_SWITCH_TO_QM = 'quartermaster';
export const USER_SWITCH_TO_OPTIC = 'optic';
export const USER_SWITCH_TO_LPA = 'lpa';
export const USER_SWITCH_PREFIX = 'hub.';
export const USER_SWITCH_OPTIC_PARAM = 'application=optic';
export const USER_SWITCH_QM_PARAM = 'application=onecape';
export const USER_SWITCH_LPA_PARAM = 'application=lpa';

export enum CommonMetadataAction {
	VIEW = 'view',
	EDIT = 'edit',
	DELETE = 'delete'
}

export enum FileExtension {
	PDF = 'pdf',
	DOC = 'doc',
	DOCX = 'docx',
	XLS = 'xls',
	XLSX = 'xlsx',
	CSV = 'csv'
}

export enum Severity {
	NORMAL = 'Normal',
	YELLOW = 'Yellow',
	RED = 'Red'
}

export const severityColorMap = {
	[Severity.RED]: Theme.ERROR,
	[Severity.YELLOW]: Theme.WARNING
};

export enum AlertMetricType {
	PORT_JOB_STATUS = 'PortJobStatus',
	PORT_CALL_VESSEL_PROGRAMME = 'PortCallVesselProgramme',
	PORT_CALL_DOCUMENTS = 'PortCallDocuments',
	PORT_CALL_EVENTS = 'PortCallEvents',
	PORT_CALL_MEASURMENTS = 'PortCallMeasurements',
	FUNDING_REQUEST_PENDING = 'FundingRequestPending',
	FUNDS_REQUESTED_FROM_PRINCIPAL = 'FundsRequestedFromPrincipal',
	PORT_CALL_VESSEL_PROGRAMME_ON_TRACK = 'PortCallVesselProgrammeOnTrack',
	PORT_CALL_VESSEL_PROGRAMME_COMMIT = 'PortCallVesselProgrammeCommit',
	VESSEL_PROGRAMME = 'VesselProgramme',
	PORT_CALL_VESSEL_PROGRAMME_ACKNOWLEDGE = 'PortCallVesselProgrammeAcknowledge',
	PORT_CALL_DUPLICATES = 'PortCallDuplicates',
	PORTCALL_CARGO_MEASUREMENTS = 'PortCallCargoMeasurements'
}

export enum AlertMetricCode {
	CONFIRM_JOB = 'ConfirmJob',
	PRINCIPAL_CONFIRM_JOB = 'PrincipalConfirmJob',
	PROCESS_JOB = 'ProcessJob',
	WAITING_FOR_INVITATION = 'WaitingForInvitation',
	WAITING_FOR_APPOINTMENT = 'WaitingForAppointment',
	ACCEPT_APPOINTMENT = 'AcceptAppointment',
	LPA_ACCEPT_APPOINTMENT = 'LpaAcceptAppointment',
	PDA_REQUESTED = 'PDARequested',
	SUBMIT_PDA = 'SubmitPDA',
	LPA_SUBMIT_PDA = 'LpaSubmitPDA',
	VERIFY_PDA = 'VerifyPDA',
	APPROVE_PDA = 'ApprovePDA',
	PRINCIPAL_APPROVE_PDA = 'PrincipalApprovePDA',
	SUBMIT_CE = 'SubmitCE',
	LPA_SUBMIT_CE = 'LpaSubmitCE',
	VERIFY_CE = 'VerifyCE',
	APPROVE_CE = 'ApproveCE',
	PRINCIPAL_APPROVE_CE = 'PrincipalApproveCE',
	SUBMIT_DA = 'SubmitDA',
	LPA_SUBMIT_DA = 'LpaSubmitDA',
	VERIFY_DA = 'VerifyDA',
	UNASSIGNED_DA = 'UnassignedDA',
	APPROVE_ASSIGNED_DA = 'ApproveAssignedDA',
	PRINCIPAL_APPROVE_DA = 'PrincipalApproveDA',
	PORT_CALL_EVENTS = 'PortCallEvents',
	PORT_CALL_DOCUMENTS = 'PortCallDocuments',
	LPA_PORT_CALL_EVENTS = 'LpaPortCallEvents',
	LPA_PORT_CALL_DOCUMENTS = 'LpaPortCallDocuments',
	FUNDING_REQUEST_PENDING = 'FundingRequestPending',
	FUNDS_REQUESTED_FROM_PRINCIPAL = 'FundsRequestedFromPrincipal',

	VESSEL_PROGRAMME_ON_TRACK = 'VesselProgrammeOnTrack',
	VESSEL_PROGRAMME_ON_TRACK_HUB = 'VesselProgrammeOnTrackHub',
	VESSEL_PROGRAMME_ON_TRACK_LPA_YELLOW = 'VesselProgrammeOnTrackLpaYellow',
	VESSEL_PROGRAMME_ON_TRACK_LPA_RED = 'VesselProgrammeOnTrackLpaRed',

	VESSEL_PROGRAMME_COMMIT = 'VesselProgrammeCommit',
	VESSEL_PROGRAMME_COMMIT_HUB = 'VesselProgrammeCommitHub',
	VESSEL_PROGRAMME_COMMIT_LPA = 'VesselProgrammeCommitLpa',

	VESSEL_PROGRAMME = 'VesselProgramme',
	VESSEL_PROGRAMME_ACKNOWLEDGE = 'VesselProgrammeAcknowledge',
	PORT_CALL_CARGO_MEASUREMENTS = 'PortCallCargoMeasurements',
	PORT_CALL_BUNKERING_MEASUREMENTS = 'PortCallBunkeringMeasurements',
	SHIP_MEASUREMENTS_DEPARTURE = 'ShipMeasurementDeparture',
	SHIP_MEASUREMENTS_ARRIVAL = 'ShipMeasurementArrival',
	REPLY_TO_HUB_QUERY_PDA_FOR_LPA = 'ReplyToHubQueryPdaForLpa',
	REPLY_TO_HUB_QUERY_CE_FOR_LPA = 'ReplyToHubQueryCeForLpa',
	REPLY_TO_HUB_QUERY_DA_FOR_LPA = 'ReplyToHubQueryDaForLpa',
	REPLY_TO_HUB_QUERY_PDA_FOR_HUB = 'ReplyToHubQueryPdaForHub',
	REPLY_TO_HUB_QUERY_CE_FOR_HUB = 'ReplyToHubQueryCeForHub',
	REPLY_TO_HUB_QUERY_DA_FOR_HUB = 'ReplyToHubQueryDaForHub',
	REPLY_TO_CLIENT_QUERY_PDA = 'ReplyToClientQueryPda',
	REPLY_TO_CLIENT_QUERY_CE = 'ReplyToClientQueryCe',
	REPLY_TO_CLIENT_QUERY_DA = 'ReplyToClientQueryDa'
}

export const DEFAULT_REDUX_FORM_CONFIG = {
	touchOnChange: true
};

export enum KeyboardEventKey {
	ENTER = 'Enter',
	ARROW_UP = 'ArrowUp'
}

export const FORBIDDEN_TAGS = [
	'script',
	'style',
	'link',
	'base',
	'plaintext',
	'meta'
];

export enum MetadataStatusCode {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
	LOCKED = 'locked',
	CONFIRMATION_REQUIRED = 'confirmationRequired'
}

export enum MetadataDisabledReasonCode {
	NO_AVAILABLE_SERVICES = 'create_NoAvailableServices',

	DA_SET_IS_PENDING = 'reopenSet_DASetIsBeingReversed',
	ANOTHER_DA_SET_IS_PENDING = 'reopenSet_AnotherDASetIsBeingReversed',
	DA_SET_IS_DONE = 'reopenSet_DASetWorkIsBeingDone',
	LAST_DA_IN_DA_SET = 'delete_LastDaInDaSet',
	DA_HAS_SERVICES = 'delete_DAHasServicesAssignedToIt',

	FINANCE_IS_BEING_UPDATED_OR_APPROVED = 'move_FinanceIsBeingUpdatedOrApproved',
	FINANCE_ALREADY_SUBMITTED_BY_USER_ON_DIFFERENT_LEVEL = 'move_FinanceAlreadySubmittedByUserOnDifferentApprovalLevel',

	CONVERT_TO_REGULAR_DISABLED_BY_SUPPLIER_INVOICE = 'convertToRegular_ServiceHasAssociatedSupplierInvoice',
	CONVERT_TO_REGULAR_DISABLED = 'convertToRegular_ServiceDoesNotHaveDAInInActiveSet',
	CONVERT_TO_DIRECT_BILL_DISABLED = 'convertToDirectBill_DaOutDeleted',

	SPLIT_DISABLED_TRANSACTION_NOT_COMPLETED_OR_INVOICE_ASSIGNED_TO_LOCKED_DA_SET = 'split_DirectBillTransactionNotCompletedOrSupplierInvoiceAssignedToLockedDaSet',
	REVERSE_SPLIT_DISABLED_TRANSACTION_NOT_COMPLETED_OR_INVOICE_ASSIGNED_TO_LOCKED_DA_SET = 'reverseSplit_DirectBillTransactionNotCompletedOrSupplierInvoiceAssignedToLockedDaSet',

	SPLIT_DISABLED_HAS_CREDIT_SERVICE = 'split_HasCreditChildService',
	REVERSE_SPLIT_DISABLED_HAS_CREDIT_SERVICE = 'reverseSplit_HasCreditChildService',

	SUPPLIER_INVOICE_CANNOT_BE_EDITED = 'editSupplierInvoice_CannotBeEditedInCurrentStatus',
	SERVICE_CANNOT_BE_ASSOCIATED_TO_INVOICE_IN_CURRENT_STAGE = 'contextualize_StageNotValid',
	SUPPLIER_INVOICE_CANNOT_BE_CANCELED = 'cancelCreditSupplierInvoice_NoPermissionOrCannotBeCancelledInCurrentStatus',

	SUPPLIER_INVOICE_CONTEXTUALIZE_IN_APPROVAL_PROCESS = 'supplierInvoiceContextualize_InApprovalProcess',
	SUPPLIER_INVOICE_CONTEXTUALIZE_HAS_ASSOCIATED_SUPPLIER_INVOICE = 'supplierInvoiceContextualize_HasAssociatedSupplierInvoice',

	SWAP_PERFORMING_CONTROL_AGENT_PREFUNDED = 'swapPerformingControllingAgents_Prefunded',
	SWAP_PERFORMING_CONTROL_AGENT_PAID = 'swapPerformingControllingAgents_Paid',

	DA_IN_ADVANCE_PAYMENT_ACCOIATED_TO_IT = 'delete_DaInHasAdvancePaymentAssociatedToIt',
	DA_IN_SETTLEMENT_PAYMENT_ACCOIATED_TO_IT = 'delete_DaInHasSettlementPaymentAssociatedToIt',

	CANCEL_JOB_HAS_ACTIVE_TRANSACTIONS = 'cancelJob_HasActiveTransactions',
	CANCEL_HUB_REVENUE_CREDIT_NOTE = 'cancelPostHubRevenueCreditNoteTransaction_CannotBeCancelledInCurrentStatus',

	ADD_CREDIT_REVENUE_SERVICE = 'addCreditHubRevenueService_UserHasNoPermission',
	CANCEL_HUB_REVENUE_SERVICE = 'cancelPostHubRevenueTransaction_HasCreditRevenueService',

	CREATE_CREDIT_SUPPLIER_INVOICE_BASED_ON_REGULAR_ONE = 'createCreditSupplierInvoiceBasedOnRegularOne_StageNotValid',
	DELETE_DA_OUT_WITH_ADVANCED_PAYMENT = 'delete_DaOutHasAdvancePaymentAssociatedToIt',

	DELETE_DA_OUT_SERVICE_CANNOT_BE_ASSIGNED_TO_DA_IN_AND_DA_OUT = 'deleteDaOut_ServiceCannotBeAssignedToDaInAndDaOut',

	DELETE_PORTJOB_OPERATION_HAS_COST_ASSOCIATION = 'delete_PortJobOperationHasCostAssociation',
	DELETE_PORTJOB_OPERATION_HAS_RECORDED_EVENTS_OR_DOCUMENT_ASSIGNMENTS = 'delete_PortJobOperationHasRecordedEventsOrDocumentAssignments',

	CONVERT_TO_DIRECT_BILL_AMOUNT_IS_LESS_THAN_ZERO = 'convertToDirectBill_AmountIsLessThanZero',
	REFRESH_BENCHMARK_NO_PERMISSION_OR_NO_VALID_OPERATION = 'refreshBenchmark_NoPermissionOrNoValidOperation'
}

export enum MetadataConfirmationMessageCode {
	TEMPORARY = 'temp'
}

export enum TagName {
	IMAGE = 'IMG'
}

export enum KeyboardCode {
	ESC = 27,
	TAB = 9
}

export enum UnlinkWarningMessages {
	WARNING_MESSAGE_NORMAL = 'Performing this action will result in linked jobs being unlinked. Continue?',
	WARNING_MESSAGE_LEAD_NOMINATION = 'Performing this action will result in all jobs, linked to the corresponding Appointment being unlinked. Continue?'
}
