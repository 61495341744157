import React from 'react';
import { Align } from 'components';
import { Button } from 'components/antd';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { BunkeringOperationGrade } from 'services/api/portJobs/portJobsServiceTypes';
import GradeLineFormFieldName from '../GradeLineForm/GradeLineFormFieldNames';

const { GRADE, NOMINAL_QUANTITY, UNIT_OF_MEASUREMENT } = GradeLineFormFieldName;

type Column = ColumnProps<{}>;
interface ColumnsProps {
	onDelete: (index: string) => void;
	onEdit: (index: string) => void;
}
const getColumns = (props: ColumnsProps): Column[] => [
	{
		title: 'Grade',
		dataIndex: `${GRADE}.label`,
		key: GRADE,
		width: '20%'
	},
	{
		title: 'Nomination Qty',
		dataIndex: NOMINAL_QUANTITY,
		key: NOMINAL_QUANTITY,
		width: '30%'
	},
	{
		title: 'UOM',
		dataIndex: `${UNIT_OF_MEASUREMENT}.label`,
		key: UNIT_OF_MEASUREMENT,
		width: '30%'
	},
	{
		dataIndex: 'actions',
		width: '20%',
		render: (_text: string, record: BunkeringOperationGrade) => (
			<Align align="right">
				<Button
					type="primary"
					icon="edit"
					transparent
					onClick={() => props.onEdit(record.id)}
				/>
				<Button
					type="danger"
					icon="delete"
					transparent
					onClick={() => props.onDelete(record.id)}
				/>
			</Align>
		)
	}
];

export default getColumns;
