import Api from 'services/api';
import {
	updateDA as action,
	updateDAAsync as asyncAction
} from 'store/finance/actions/updateDA';
import {
	FinanceRequest,
	UpdateDAOutActionPayload,
	UpdateDARequest,
	DisbursementAccount
} from 'services/api/finance/financeServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, all, fork, put, takeEvery, select } from 'redux-saga/effects';
import { closeAddDAModal } from '../actions/addDA';
import { getDaById, getIsAddDAModalShown } from '../selectors/financeSelectors';
import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';
import { notify } from 'store/notifications/actions';
import { getFormErrors } from 'utils/sagaHelpers/form/formSagaUtils';
import { ErrorOther } from 'redux-form';

const apiCall = Api.Finance.updateDA;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormError = ErrorOther<string> & { [key: string]: any };

export function* executor(
	actionParams: UpdateDAOutActionPayload,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const params: FinanceRequest = yield call(
			getActivePortCallAndPortJobIdsExecutor
		);

		/**
		 * Get and destructure currentDA to prevent data loss during PATCH update
		 */
		let currentDa: DisbursementAccount | undefined;
		if (actionParams.disbursementAccountId) {
			currentDa = yield select(getDaById, actionParams.disbursementAccountId);
		}

		const payload = {
			...currentDa,
			...actionParams.data,
			daType: actionParams.daType
		} as DisbursementAccount;

		const response = yield call(api, {
			...params,
			disbursementAccountId: actionParams.disbursementAccountId,
			payload
		} as UpdateDARequest);

		yield put(
			asyncAction.done({
				result: actionParams,
				params: actionParams,
				response
			})
		);
		yield call(handleDAModal, actionParams);
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
		const unpackedErrors = error.response
			? getFormErrors(error.response.data)
			: {};
		yield call(handleDAModal, actionParams, unpackedErrors);
	}
}

function* handleDAModal(
	actionParams: UpdateDAOutActionPayload,
	error?: FormError
) {
	const daModal = yield select(getIsAddDAModalShown);
	if (daModal[actionParams.daType]) {
		if (!error) {
			yield put(closeAddDAModal(actionParams.daType));
			yield put(notify.success('DA has been successfully updated.'));
		}
		if (error) {
			yield put(notify.error(Object.values(error).join()));
		}
	}
}

function* worker({ payload }: Action<UpdateDAOutActionPayload>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeEvery(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
