import { AlertProps } from 'antd/lib/alert';
import {
	contextBarsTitleMap,
	contextBarsSubtitleMap,
	contextBarsTypeMap
} from '../constants';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import {
	ContextBarView,
	ContextBar,
	ContextBarPlaceholder
} from '../contextBarsTypes';

export const getContextBars = (state: AppState) => state.contextBars.list;

export function replaceVariablesWithValues(
	str: string,
	placeholders: ContextBarPlaceholder[] = []
) {
	let resultString = str;
	placeholders.forEach(({ name, value }) => {
		resultString = resultString.replace(`{{${name}}}`, value);
	});
	return resultString;
}

export const getTransformedContextBars = createSelector(
	getContextBars,
	(bars: ContextBar[]) => {
		return bars.map(bar => {
			const result: ContextBarView = {
				title: contextBarsTitleMap[bar.titleCode],
				subtitle: replaceVariablesWithValues(
					contextBarsSubtitleMap[bar.subtitleCode],
					bar.placeholders
				),
				type: contextBarsTypeMap[bar.type] as AlertProps['type'],
				actionCodes: bar.actionCodes
			};
			return result;
		});
	}
);
