import React from 'react';
import { map } from 'lodash';
import { Table } from 'components/antd';
import { Text } from 'components';
import styles from './JobSummaryWidget.module.scss';
import {
	SummarySection,
	SummaryJobMetric,
	SummaryType
} from 'services/api/dashboard/dashboardServiceTypes';
import getJobsSummaryColumnConfig from './ColumnComponents/JobSummaryColumns';

interface JobSummaryWidgetProps {
	title: string;
	section: SummarySection;
}

class JobSummaryWidget extends React.Component<JobSummaryWidgetProps> {
	render() {
		const { section } = this.props;
		const isJobSection =
			section.code === SummaryType.HUB_TASKS ||
			section.code === SummaryType.ACTIVE_JOBS;
		return (
			<div className={styles.root}>
				<Text upper weight="bold">
					{this.props.title}
				</Text>
				{map(section.elements, subsection => (
					<Table
						showHeader
						className={styles.summaryTable}
						key={subsection.code}
						pagination={false}
						dataSource={subsection.elements}
						rowKey={(record: SummaryJobMetric) => `metric-${record.code}`}
						columns={getJobsSummaryColumnConfig(subsection.name, isJobSection)}
						locale={{
							emptyText: 'No Jobs'
						}}
					/>
				))}
			</div>
		);
	}
}

export default JobSummaryWidget;
