import { ReadMoreType } from '../ReadMoreTypes';

export const getLineHeight = (element: HTMLElement | null): number => {
	let lineHeight = '1';
	if (!element) {
		return +lineHeight;
	}

	if (document.defaultView) {
		lineHeight =
			element.style.lineHeight ||
			document.defaultView
				.getComputedStyle(element)
				.getPropertyValue('line-height');
	}

	lineHeight = lineHeight.replace('px', '');
	return parseFloat(lineHeight);
};

export const getElementContentHeight = (element: HTMLElement) => {
	const height = element.clientHeight;
	const cs: CSSStyleDeclaration = getComputedStyle(element);
	const paddingTop = cs.paddingTop || '0';
	const paddingBottom = cs.paddingBottom || '0';
	return height - (parseFloat(paddingTop) + parseFloat(paddingBottom));
};

export const getContentLinesLength = (element: HTMLElement | null): number => {
	if (!element) {
		return 0;
	}
	const lineHeight = getLineHeight(element);
	const contentHeight = getElementContentHeight(element);
	return Math.round(contentHeight / lineHeight);
};

export const getContentHeightWhenCollapsed = (
	element: HTMLElement | null,
	type: string | undefined,
	maxItems: number | undefined
) => {
	let height;
	switch (type) {
		case ReadMoreType.PRIMARY:
			height = 0;
			break;
		case ReadMoreType.LINE:
			if (maxItems) {
				const lineHeight = getLineHeight(element);
				height = maxItems * lineHeight;
			}
			break;
		default:
			break;
	}
	return height;
};
