import actionCreator from '../draftsActionCreator';

export interface SetActiveDraftFormAndFieldActionPayload {
	formId: string;
	fieldName: string;
}

export const setActiveDraftFormAndField = actionCreator<
	SetActiveDraftFormAndFieldActionPayload
>('SET_ACTIVE_FORM_AND_FIELD');
