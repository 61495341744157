import actionCreator from 'store/portJobs/portJobsActionCreator';
import { ResultCollection } from 'services/api/apiTypes';
import {
	AppointerType,
	RetrieveAppointerRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const RETRIEVE_APPOINTERS = 'RETRIEVE_APPOINTERS';
const retrieveAppointersAsync = actionCreator.async<
	RetrieveAppointerRequest,
	ResultCollection<AppointerType>,
	Error
>(RETRIEVE_APPOINTERS);

export const retrieveAppointers = actionCreator<RetrieveAppointerRequest>(
	RETRIEVE_APPOINTERS
);

export const retrieveAppointersAction = {
	action: retrieveAppointers,
	async: retrieveAppointersAsync
};

export default retrieveAppointersAction;
