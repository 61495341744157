import { EditContactFormData } from './EditContactForm';
import { EditContactFieldName } from './EditContactConstants';
import {
	validatePhoneFormatCustomMaxLength,
	validateNameMax,
	validateTextAreaCustomMaxLength,
	validateRequired
} from 'utils/validations';
import { FormErrors } from 'redux-form';

const validate = (
	values: EditContactFormData
): FormErrors<EditContactFormData> => {
	const errors: FormErrors<EditContactFormData> = {
		[EditContactFieldName.COMPANY]: validateRequired(
			values[EditContactFieldName.COMPANY]
		),
		[EditContactFieldName.FIRST_NAME]: validateNameMax(
			values[EditContactFieldName.FIRST_NAME]
		),
		[EditContactFieldName.LAST_NAME]: validateNameMax(
			values[EditContactFieldName.LAST_NAME]
		),
		[EditContactFieldName.PHONE]: validatePhoneFormatCustomMaxLength(100)(
			values[EditContactFieldName.PHONE]
		),
		[EditContactFieldName.NOTES]: validateTextAreaCustomMaxLength(3000)(
			values[EditContactFieldName.NOTES]
		)
	};

	return errors;
};
export default validate;
