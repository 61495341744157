import actionCreator from '../developerPortalActionCreator';
import { ApiSubscriptionParams as Request } from 'services/api/developerPortal/developerPortalServiceTypes';

const CREATE_NEW_API_SUBSCRIPTION = 'CREATE_NEW_API_SUBSCRIPTION';

export const createNewApiSubscriptionAsync = actionCreator.async<
	Request,
	null,
	Error
>(CREATE_NEW_API_SUBSCRIPTION);

export const createNewApiSubscription = actionCreator(
	CREATE_NEW_API_SUBSCRIPTION
);
