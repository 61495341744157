import actionCreator from '../groupsActionCreator';
import { DeleteGroupRequest } from 'services/api/groups/groupsServiceTypes';

const ACTION_NAME = 'DELETE_GROUP';
export const deleteGroupAsync = actionCreator.async<
	DeleteGroupRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Group'
	}
});

export const deleteGroup = actionCreator<DeleteGroupRequest>(ACTION_NAME);
