import { Action } from 'typescript-fsa';
import { take, put, takeLatest, select } from 'redux-saga/effects';

import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';

import {
	onRemovePrincipalService,
	removePrincipalService,
	removePrincipalServiceAsync
} from '../actions';
import { openModal, closeModal } from 'store/modals/actions';

import { getPrincipalServiceById } from '../selectors';

const MODAL_NAME = 'DELETE';
function* worker({ payload: serviceId }: Action<string>) {
	const service = yield select(getPrincipalServiceById, serviceId);

	const description = service.services.length
		? `The service you want to delete is mapped to one or many ISS services.
		If you delete it, Principals will see ISS service name/code instead of their preferred one. Continue?`
		: `Are you sure you want to delete the service ${service.name}?`;

	yield put(
		openModal({
			name: MODAL_NAME,
			type: 'confirm',
			data: {
				title: 'Delete Principal Specific Service',
				description
			}
		})
	);

	const action = yield take(closeModal.type);

	if (action.payload.isConfirmed) {
		yield put(removePrincipalService(service.id));

		yield waitAsyncResolve(removePrincipalServiceAsync);

		yield put(closeModal({ name: MODAL_NAME }));
	}
}

export default function*() {
	yield takeLatest(onRemovePrincipalService.type, worker);
}
