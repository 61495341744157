import { first } from 'lodash';
import runValidations, { arrayMin } from 'utils/validations';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validate = (values: FormData): any => ({
	totalAmounts: {
		_error: first(runValidations(values.totalAmounts, [arrayMin(1)]))
	}
});

export default validate;
