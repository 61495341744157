import { UsersState } from './../usersState';
import {
	UserUpdateStatusParams,
	UserProfile
} from 'services/api/users/userServiceTypes';
import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
export const onUpdateUserStatusStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		updatingStatus: FetchStatus.PENDING
	}
});
export const onUpdateUserStatusSuccess = (
	state: UsersState,
	action: Success<UserUpdateStatusParams, UserProfile>
): UsersState => {
	return {
		...state,
		profile: {
			...state.profile,
			...action.result,
			assignedGroups: state.profile?.assignedGroups || [],
			availableGroups: state.profile?.availableGroups || []
		},
		byId: {
			...state.byId,
			[action.result.id]: action.result
		},
		fetchStatuses: {
			...state.fetchStatuses,
			updatingStatus: FetchStatus.SUCCESS
		}
	};
};
export const onUpdateUserStatusFailed = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		updatingStatus: FetchStatus.FAILURE
	}
});
