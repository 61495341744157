export const ADD_OR_EDIT_UNIT_COST_MODAL = 'addOrEditUnitCostModal';
export const VIEW_UNIT_COST_MODAL = 'viewUnitCostModal';
export const ADD_UNIT_COST_FORM = 'addUnitCostForm';
export const NEW_COST_ID = 'NewCostId';
export const DELETE_UNIT_COST = 'deleteUnitCost';

export enum AddUnitCostFormNames {
	SERVICE_TYPE = 'serviceType',
	PRIMARY_UNITS = 'primaryUnits',
	SECONDARY_UNITS = 'secondaryUnits',
	CREW_COUNT = 'crewCount',
	UNIT_RATE = 'unitRate',
	SERVICE_AMOUNT = 'serviceAmount'
}

export interface AddUnitCostFormData {
	id: string;
	serviceType: string;
	primaryUnits: string;
	secondaryUnits: string;
	crewCount: string;
	unitRate: string;
	serviceAmount: string;
	isCreditLine?: boolean;
}
