import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveServiceErrorsResponse } from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';

export const retrieveServiceErrors = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	null,
	RetrieveServiceErrorsResponse
>('fetchStatuses', 'retrieveServiceErrors', {
	doneReducer: (
		state: FinanceState,
		action: Success<null, RetrieveServiceErrorsResponse>
	): FinanceState => ({
		...state,
		serviceErrors: action.result.elements
	})
});
