import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading } from 'store/selectors';
import { VesselTypesMode } from '../configurationState';
import { getCompanyVesselsMap } from 'store/companyVessels/selectors';
import { MainPrincipalTypeCodes } from 'services/api/companies/companiesServiceTypes';

const getConfiguration = (state: AppState) => state.configuration;

export const getVesselTypesTabMode = (state: AppState) =>
	getConfiguration(state).context.vesselTypesTabMode;

export const getIsVesselTypesTabInMode = (
	state: AppState,
	mode: VesselTypesMode
) => getVesselTypesTabMode(state).mode === mode;

export const getActiveVesselTypeId = (state: AppState) =>
	getVesselTypesTabMode(state).vesselTypeId;

export const getActiveCompanyId = (state: AppState) =>
	state.configuration.context.activeCompanyId || '';

export const isMainPrincipleCLSType = createSelector(
	getConfiguration,
	getActiveCompanyId,
	(configuration, id) => {
		const company = configuration.companies.find(
			companie => companie.id === id
		);
		return company?.mainPrincipalType.code === MainPrincipalTypeCodes.CLS;
	}
);

export const getHasConfigurationUnsavedData = (state: AppState) =>
	state.configuration.context.hasUnsavedData;

export const getConfigurationFetchStatuses = createSelector(
	getConfiguration,
	configuration => configuration.fetchStatuses
);

export const getConfigurationInitializeFetchStatus = (state: AppState) =>
	state.configuration.fetchStatuses.initialize;

export const getIsConfigurationGeneralDetailsUpdating = (state: AppState) =>
	getIsLoading(state.configuration.fetchStatuses.updateGeneralDetails);

export const getIsConfigurationEmailFragmentsUpdating = (state: AppState) =>
	getIsLoading(state.configuration.fetchStatuses.updateEmailFragments);

export const getIsConfigurationPreferredAgentsFileUploading = (
	state: AppState
) => getIsLoading(state.configuration.fetchStatuses.uploadPreferredAgentsFile);

export const getConfigurationBenchmarkUpdating = createSelector(
	getConfigurationFetchStatuses,
	fetchStatuses =>
		getIsLoading(fetchStatuses.retrieveBenchmark) ||
		getIsLoading(fetchStatuses.updateBenchmark)
);

export const getConfigurationFeedbackManagementUpdating = createSelector(
	getConfigurationFetchStatuses,
	fetchStatuses =>
		getIsLoading(fetchStatuses.retrieveFeedbackManagement) ||
		getIsLoading(fetchStatuses.updateFeedbackManagement) ||
		getIsLoading(fetchStatuses.retrieveFeedbackManagementGridStatus)
);

export const getFeedbackManagementEnabled = createSelector(
	getConfiguration,
	configuration => configuration.isFeedbackManagementEnabled
);

export const getSingleVesselById = createSelector(
	getCompanyVesselsMap,
	(_state: AppState, id: string) => id,
	(vessels, id) => (id ? vessels[id] : undefined)
);

const getGeneralDetailsGroups = (state: AppState) => {
	return state.configuration.generalDetails.groups;
};

export const getGeneralDetailsMessagingGroups = createSelector(
	getGeneralDetailsGroups,
	groups => groups.filter(group => group.isMessagingGroup)
);

export const getConfigurationCompanies = (state: AppState) =>
	state.configuration.companies;

export const getIsPrincipalIntegrationEnabled = (state: AppState) =>
	state.configuration.principalIntegrationSettings
		.isPrincipalIntegrationEnabled;

export const getEventsList = (state: AppState) =>
	state.configuration.triggerEvent;

export const getOpticVariables = (state: AppState) =>
	state.configuration.opticVariable;

export const getUploadedRequestFeedbackDocuments = (state: AppState) =>
	state.configuration.context.uploadedRequestFeedbackDocuments;

export const getIsRequestFeedbackManagementSubmitted = (state: AppState) =>
	getIsLoading(
		state.configuration.fetchStatuses.createFeedbackManagementStatus
	);

export const getFeedbackManagementGrid = (state: AppState) =>
	state.configuration.feedbackManagementGrid;
