import * as React from 'react';
import styles from './UploadProgressModal.module.scss';
import {
	WizardProgress,
	Box,
	Flex,
	Text,
	Ellipsis,
	FileTypeIcon
} from 'components';
import { Modal, Alert, Progress } from 'components/antd';
import { UploadFile } from 'antd/lib/upload/interface';
import {
	ModalStatus,
	MODAL_STATUS_SWITCH_MAP
} from './UploadProgressModalTypes';
import formatBytes from 'utils/formatBytes';
import Config from './UploadProgressModalConfig';
import { ProcessingStatus } from 'store/documents/documentsState';

const FileInfo: React.FC<Pick<UploadProgressModalProps, 'file'>> = ({
	file
}) => {
	if (!file) {
		return null;
	}

	return (
		<Flex className={styles.file}>
			<FileTypeIcon
				fileName={file.name}
				defaultColor="grayBlue"
				defaultColorMap
			/>
			<div className={styles.fileInfo}>
				<Text weight="bold">{file.name}</Text>
				<br />
				<Text size="xs" className={styles.size}>
					{formatBytes(file.size)}
				</Text>
			</div>
		</Flex>
	);
};

interface UploadProgressModalProps {
	visible: boolean;
	status: ProcessingStatus;
	statusTerminatedDescription?: string;
	alertMessage?: string;
	file?: UploadFile;
	onClose?: () => void;
	percent?: number;
	hasSplit: boolean;
	done: boolean;
	hideClose: boolean;
}

interface UploadProgressModalState {
	modalStatus: ModalStatus;
}
class UploadProgressModal extends React.Component<
	UploadProgressModalProps,
	UploadProgressModalState
> {
	constructor(props: UploadProgressModalProps) {
		super(props);

		this.state = {
			modalStatus: ModalStatus.PROGRESS
		};
	}

	proceed = () => {
		this.toggleModalStatus();
	};

	close = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onCancel = () => {
		this.toggleModalStatus();

		if (this.state.modalStatus !== ModalStatus.PROGRESS || this.props.done) {
			this.close();
		}
	};

	toggleModalStatus = () => {
		const status = MODAL_STATUS_SWITCH_MAP[this.state.modalStatus];
		if (status) {
			this.setState({
				modalStatus: status
			});
		}
	};

	getStepStatusTerminatedDescription = () => {
		if (!this.props.statusTerminatedDescription) {
			return;
		}
		return <Text color="error">{this.props.statusTerminatedDescription}</Text>;
	};

	getStepDescription = (done: boolean, label: string) =>
		this.getStepStatusTerminatedDescription() ||
		(done ? 'Done' : <Ellipsis>{label} in progress</Ellipsis>);

	getSteps = () => {
		const { done, hasSplit, percent } = this.props;
		return [
			{
				key: ProcessingStatus.UPLOAD,
				label: 'Uploading File',
				description: <Progress percent={percent} />
			},
			{
				key: ProcessingStatus.SCAN,
				label: 'Scanning For Viruses',
				description: this.getStepDescription(done, 'Scan')
			}
		].concat(
			hasSplit
				? [
						{
							key: ProcessingStatus.SPLIT,
							label: 'Splitting File',
							description: this.getStepDescription(done, 'Split')
						}
				  ]
				: [
						{
							key: ProcessingStatus.PROCESSING,
							label: 'Processing File',
							description: this.getStepDescription(done, 'Process')
						}
				  ]
		);
	};

	render() {
		const config = Config[this.state.modalStatus];
		const footer = config.footer(this.proceed, this.close);
		const { visible, file, status, alertMessage, hideClose } = this.props;
		return (
			<Modal
				visible={visible}
				footer={footer}
				title="Upload In Progress"
				width={360}
				className={styles.root}
				onCancel={this.onCancel}
				onClose={this.onCancel}
				closable={!hideClose}
			>
				{alertMessage && (
					<Alert type="error" message={<p>{alertMessage}</p>} showIcon />
				)}
				{!alertMessage && (
					<>
						<Box>
							<Alert
								type={config.alertType}
								message={<p>{config.alertMessage}</p>}
								showIcon
							/>
						</Box>
						<FileInfo file={file} />
						{config.showProgress && (
							<WizardProgress
								activeKey={status}
								steps={this.getSteps()}
								vertical
								className={styles.progressContainer}
								showCompleted
							/>
						)}
					</>
				)}
			</Modal>
		);
	}
}

export default UploadProgressModal;
