import actionCreator from '../portJobsActionCreator';

const SET_ACTIVE_PORT_JOB_CODE = 'SET_ACTIVE_PORT_JOB_CODE';
export const setActivePortJobCode = actionCreator<string>(
	SET_ACTIVE_PORT_JOB_CODE
);

const RESET_ACTIVE_PORT_JOB_CODE = 'RESET_ACTIVE_PORT_JOB_CODE';
export const resetActivePortJobCode = actionCreator(RESET_ACTIVE_PORT_JOB_CODE);

export default {
	set: setActivePortJobCode,
	reset: resetActivePortJobCode
};
