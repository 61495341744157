import React from 'react';

import { Alert, Modal } from 'components/antd';
import { ModalParams, closeModal } from 'store/modals/actions';

const ModalDescription: React.SFC<ConfirmationModalProps> = props => {
	const { data, alertType } = props;
	if (!data) {
		return null;
	}
	if (!alertType) {
		return <>{data.description}</>;
	}
	return (
		<Alert
			type={alertType}
			message={
				<>
					<p>{data.description}</p>
				</>
			}
			showIcon
			transparent
		/>
	);
};

export interface ConfirmationModalProps extends ModalParams {
	closeModal: typeof closeModal;
	onOk?: () => void;
	onClose?: () => void;
}

class ConfirmationModal extends React.PureComponent<ConfirmationModalProps> {
	onCancel = () => {
		this.props.closeModal({
			name: this.props.name,
			type: this.props.type,
			isConfirmed: false
		});
	};

	onOk = () => {
		this.props.closeModal({
			name: this.props.name,
			type: this.props.type,
			isConfirmed: true
		});

		const { onOk } = this.props;
		if (onOk) {
			onOk();
		}
	};

	onClose = () => {
		this.onCancel();
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	render() {
		if (!this.props.data) {
			return null;
		}
		const {
			title,
			okText = 'Confirm',
			cancelText = 'Cancel',
			okButton,
			okProps,
			cancelButton,
			hideCancelButton,
			cancelProps
		} = this.props.data;

		return (
			<Modal
				visible
				closable={!!title}
				title={title}
				cancelText={cancelText}
				okText={okText}
				onCancel={this.onCancel}
				onOk={this.onOk}
				onClose={this.onClose}
				okButton={okButton}
				okProps={okProps}
				cancelProps={cancelProps}
				cancelButton={cancelButton}
				hideCancelButton={hideCancelButton}
			>
				<ModalDescription {...this.props} />
			</Modal>
		);
	}
}

export default ConfirmationModal;
