import * as React from 'react';
import styles from '../JobDetails.module.scss';
import { Amount } from 'components';
import { Row, Col } from 'components/antd';
import { Flex } from 'components/Styles';
import InfoAgentPopover from './InfoAgentPopover/InfoAgentPopover';
import { getColumns } from './InfoSelectors';
import { useSelector } from 'react-redux';
import { AppState } from 'store-types';
import classnames from 'classnames';

import {
	Column,
	ColumnEntry,
	ColumnEntryKey,
	ColumnEntryItemAgent
} from './InfoTypes';

interface InfoProps {
	portJobCode: string;
}

interface InfoColumnProps {
	column: ColumnEntry;
	valueComponent?: React.ReactNode;
}

const InfoColumn = ({
	column: { title, value, isFormattedValue },
	valueComponent = isFormattedValue ? <Amount value={value} /> : value
}: InfoColumnProps) => (
	<Flex
		className={classnames(styles.job, {
			[styles.disabledContainerWidth]: valueComponent === 'Finance not enabled'
		})}
	>
		<div
			className={classnames(styles.label, {
				[styles.disabledLabel]: valueComponent === 'Finance not enabled'
			})}
		>
			{title}
		</div>
		<div
			className={classnames(styles.value, {
				[styles.disabledValue]: valueComponent === 'Finance not enabled'
			})}
		>
			{valueComponent}
		</div>
	</Flex>
);

const isColumnAgentHasDetails = (
	column: Column,
	entry: ColumnEntry
): entry is ColumnEntryItemAgent => {
	return (
		column.key === ColumnEntryKey.AGENT &&
		Boolean((entry as ColumnEntryItemAgent).details)
	);
};

const Info = ({ portJobCode }: InfoProps) => {
	const columns = useSelector((state: AppState) =>
		getColumns(state, portJobCode)
	);

	return (
		<Row>
			{columns.map((col, i) => (
				<Col xs={6} sm={6} md={3} key={i}>
					{col.columns.map((column, j) => (
						<InfoColumn
							column={column}
							key={j}
							valueComponent={
								isColumnAgentHasDetails(col, column) ? (
									<InfoAgentPopover
										data={{
											...column.details,
											name: column.details.companyName
										}}
										icon="info-circle"
									/>
								) : (
									undefined
								)
							}
						/>
					))}
				</Col>
			))}
		</Row>
	);
};
export default Info;
