import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NotificationDisplayType } from 'store/notifications/notificationsState';
import { makeGetNotificationsByDisplayType } from 'store/notifications/selectors';
import {
	resetNotifications,
	removeNotification,
	confirmNotification
} from 'store/notifications/actions';
import { AppState } from 'store-types';

interface UseNotificationsProps {
	displayType: NotificationDisplayType;
}

export function useNotifications({ displayType }: UseNotificationsProps) {
	const dispatch = useDispatch();

	const getList = useMemo(() => {
		return makeGetNotificationsByDisplayType(displayType);
	}, [displayType]);

	const list = useSelector<AppState, ReturnType<typeof getList>>(getList);

	useEffect(() => {
		// reset notifications by display type
		return () => {
			dispatch(resetNotifications(displayType));
		};
		// is used for mount/unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onRemoveNotification = useCallback(
		(uuid: string) => {
			dispatch(removeNotification(uuid));
		},
		[dispatch]
	);

	const onConfirmNotification = useCallback(
		(uuid: string) => {
			dispatch(confirmNotification(uuid));
		},
		[dispatch]
	);

	return {
		list,
		onRemoveNotification,
		onConfirmNotification
	};
}
