import actionCreator from '../documentsActionCreator';
import {
	RetrieveDocumentRequest,
	RetrieveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
import {
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

// Retrieve Document
const RETRIEVE_DOCUMENT = 'RETRIEVE_DOCUMENT';
export const retrieveDocumentAsync = actionCreator.async<
	RetrieveDocumentRequest,
	RetrieveDocumentResponse,
	Error
>(RETRIEVE_DOCUMENT);
export const retrieveDocument = actionCreator<RetrieveDocumentRequest>(
	RETRIEVE_DOCUMENT
);

// Retrieve Document Image
const RETRIEVE_DOCUMENT_IMAGE = 'RETRIEVE_DOCUMENT_IMAGE';
export const retrieveDocumentImageAsync = actionCreator.async<
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse,
	Error
>(RETRIEVE_DOCUMENT_IMAGE);
export const retrieveDocumentImage = actionCreator<
	RetrievePortCallDocumentImageRequest
>(RETRIEVE_DOCUMENT_IMAGE);
