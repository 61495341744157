import { BreadcrumbItem } from 'store/navigation/navigationState';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { getOpenedGroup } from 'store/groups/selectors';
import { createSelector } from 'reselect';
import { PATHS } from 'sections/App/RouteParams';

export const getGroupsBreadcrumbs = createSelector(
	getOpenedGroup,
	(group: Group): BreadcrumbItem[] => {
		const breadcrumbs: BreadcrumbItem[] = [
			{ title: 'Manage Groups', link: `/${PATHS.groups}` },
			...(group?.name
				? [{ title: group.name, link: `/${PATHS.groups}/${group.id}` }]
				: [])
		];
		return breadcrumbs;
	}
);
