import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveCurrenciesAsync } from './actions';
import {
	onRetrieveCurrenciesStarted as started,
	onRetrieveCurrenciesSuccess as success,
	onRetrieveCurrenciesFailed as failed
} from './reducers';

import { initialState } from './currenciesState';

export default reducerWithInitialState(initialState)
	.case(retrieveCurrenciesAsync.started, started)
	.case(retrieveCurrenciesAsync.done, success)
	.case(retrieveCurrenciesAsync.failed, failed);
