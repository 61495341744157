import * as React from 'react';
import { connect } from 'react-redux';
import { Flex, Gap } from 'components';

import styles from './AcceptPortJobModal.module.scss';
import AcceptanceVendors from './AcceptanceVendors';
import AppointmentAttachments from 'sections/PortCall/PortJob/AppointmentAttachments';
import {
	getPortJobAcceptanceAttachments,
	getPortJobAcceptanceInstruction
} from 'store/portJobs/portJobsSelectors';
import { AppointmentAttachment } from 'services/api/portJobs/portJobsServiceTypes';
import { AppState } from 'store-types';

interface ModalProps {
	attachments: AppointmentAttachment[];
	instruction?: string;
}

const AcceptModal = ({ attachments, instruction }: ModalProps) => (
	<Flex direction="vertical">
		<div className={styles.scrollableText}>
			<div className={styles.SLAInfoBoxInner}>
				{attachments.length > 0 && (
					<AppointmentAttachments attachments={attachments} />
				)}
			</div>
			{instruction && (
				<Gap isBlock bottom="md">
					<h4>General Instructions:</h4>
					<div>
						{instruction
							.split('\n')
							.map(
								(paragraph, key) =>
									paragraph ? <p key={key}>{paragraph}</p> : <br />
							)}
					</div>
				</Gap>
			)}
			<AcceptanceVendors />
		</div>
	</Flex>
);

export default connect((state: AppState) => ({
	attachments: getPortJobAcceptanceAttachments(state),
	instruction: getPortJobAcceptanceInstruction(state)
}))(AcceptModal);
