import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { openModal, closeModal } from 'store/modals/actions';
import {
	onDeletePortCallDocument,
	deletePortCallDocumentAsync,
	retrieveRequiredDocuments,
	DeletePortCallDocumentPayload
} from '../actions';
import Api from 'services/api';
import { getUploadedDocumentConcurrencyToken } from '../selectors';

const MODAL_NAME = 'deletePortCallDocument';

const executor = function*(
	payload: DeletePortCallDocumentPayload
): SagaIterator {
	yield put(deletePortCallDocumentAsync.started(payload));
	const concurrencyToken = yield select(
		getUploadedDocumentConcurrencyToken,
		payload.documentId
	);
	const deleteRequest = { ...payload, concurrencyToken };
	try {
		const response = yield call(
			Api.PortCallDocuments.deleteDocument,
			deleteRequest
		);
		yield put(
			deletePortCallDocumentAsync.done({
				result: response.data,
				params: deleteRequest,
				response
			})
		);
		yield put(
			retrieveRequiredDocuments({ portCallId: deleteRequest.portCallId })
		);
	} catch (error) {
		yield put(
			deletePortCallDocumentAsync.failed({ error, params: deleteRequest })
		);
	}
};

const worker = function*({ payload }: Action<DeletePortCallDocumentPayload>) {
	yield put(
		openModal({
			name: MODAL_NAME,
			type: 'confirm',
			data: {
				title: 'Delete File',
				description: 'Are you sure you want to permanently delete this file?'
			}
		})
	);

	const {
		payload: { isConfirmed }
	} = yield take(closeModal.type);

	if (isConfirmed) {
		// Proceed with deletion
		yield call(executor, payload);
	}
};

export default function*() {
	yield takeLatest(onDeletePortCallDocument.type, worker);
}
