import * as React from 'react';
import { IThread } from 'services/api/threads/threadsServiceTypes';
import { Flex, Status, TR, TD, Title, JobCodeTag } from 'components';
import { Icon, Checkbox } from 'components/antd';
import { Justify } from 'components/types';
import { FormattedTime } from 'containers';
import styles from './ThreadRowSplitView.module.scss';
import classNames from 'classnames';
import { get } from 'lodash';
import MessageSendFailureIndicator from 'sections/Thread/Message/MessageSendFailureIndicator';

interface ThreadRowSplitViewProps {
	thread: IThread;
	onClick: (id: string) => void;
	active?: boolean;
	menuItem: string | null;
	isCheckBoxDisabled: boolean;
	isThreadIdChecked: boolean;
	onCheckBoxClick: (
		id: string,
		allowedGroupId: string,
		group: IThread['assignedToGroup']
	) => void;
}

const ThreadRowSplitView = (props: ThreadRowSplitViewProps) => {
	const {
		thread,
		active,
		menuItem,
		isThreadIdChecked,
		isCheckBoxDisabled
	} = props;
	return (
		<TR
			className={styles.threadRow}
			onClick={props.onClick}
			index={thread.id}
			key={thread.id}
		>
			<TD>
				<div
					className={classNames(styles.root, {
						selected: thread.isRead,
						[styles.active]: active
					})}
				>
					<Flex justify={Justify.BETWEEN} align="center">
						<div>
							{menuItem && (
								<Checkbox
									key={thread.id}
									checked={isThreadIdChecked}
									disabled={isCheckBoxDisabled}
									className={styles.checkboxSpan}
									onClick={() =>
										props.onCheckBoxClick(
											thread.id,
											thread.company.id,
											thread.assignedToGroup
										)
									}
								></Checkbox>
							)}
							<MessageSendFailureIndicator
								message={
									thread.hasFailedMessages
										? `There's a message in this thread which wasn't sent`
										: ''
								}
								style
							/>
							<FormattedTime
								value={
									thread.lastMessage.updatedOn ||
									thread.updatedOn ||
									thread.createdOn
								}
								format="D MMM YYYY, HH:mm"
								convertFromUtc
								className={classNames({
									[styles.dateSpan]: thread.hasFailedMessages
								})}
							/>
						</div>
						<Status.Label value={thread.status} date={thread.statusUpdatedOn} />
					</Flex>
					<Flex className={styles.subject}>
						<Title.Subject unread={!thread.isRead}>
							{thread.lastMessage.subject}({thread.messageCount})
						</Title.Subject>
					</Flex>
					<Flex align="center" justify={Justify.BETWEEN}>
						<Flex inline>
							<ul>
								{thread.jobCodes.length > 0 ? (
									thread.jobCodes.map(code => (
										<li key={code} className={styles.jobCodeTagItem}>
											<JobCodeTag jobCode={code}>{code}</JobCodeTag>
										</li>
									))
								) : (
									<li className={styles.jobCodeTagItem}>No Job Defined:</li>
								)}
								<li className={styles.jobCodeTagItem}>
									{get(thread.context, 'name') || 'No Context Defined'}
								</li>
								{thread.hasAttachments && (
									<li className={styles.jobCodeTagItem}>
										<Icon type="paper-clip" />
									</li>
								)}
							</ul>
						</Flex>
					</Flex>
				</div>
			</TD>
		</TR>
	);
};

export default ThreadRowSplitView;
