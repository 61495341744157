import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	RetrievePortCallEventTemplatesRequest,
	RetrievePortCallEventTemplatesResponse
} from 'services/api/portCall/portCallServiceTypes';
import { retrievePortCallEventTemplatesAsync } from '../actions';

const apiCall = Api.PortCall.retrievePortCallEventTemplates;

export default makeTakeLatestWatcher<
	RetrievePortCallEventTemplatesRequest,
	RetrievePortCallEventTemplatesResponse,
	Error
>({
	api: apiCall,
	async: retrievePortCallEventTemplatesAsync
});
