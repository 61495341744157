import { select, put } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveMessageAttachmentAsync } from '../actions';
import {
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse
} from 'services/api/messages/messagesServiceTypes';
import Api from 'services/api';
import { Success } from 'typescript-fsa';
import { getAttachmentStatusMessage } from '../selectors';
import { openModal } from 'store/modals/actions';

export default makeTakeEveryWatcher<
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse,
	Error
>({
	api: Api.Messages.getFileUrl,
	async: retrieveMessageAttachmentAsync,
	*onSuccess({
		result: { id }
	}: Success<
		RetrieveMessageAttachmentRequest,
		RetrieveMessageAttachmentResponse
	>) {
		const statusMessage = yield select(getAttachmentStatusMessage, id);
		if (statusMessage) {
			yield put(
				openModal({
					name: 'DownloadAttachment',
					type: 'confirm',
					alertType: 'warning',
					data: {
						title: 'File cannot be downloaded',
						description: statusMessage,
						okText: 'OK',
						hideCancelButton: true
					}
				})
			);
		}
	}
});
