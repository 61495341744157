import {
	DeleteServiceRequest,
	DeleteServiceResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const DELETE_SERVICE = 'DELETE_SERVCE';
export const deleteServiceAsync = actionCreator.async<
	DeleteServiceRequest,
	DeleteServiceResponse,
	Error
>(DELETE_SERVICE, {
	templateParams: {
		entityName: 'Service'
	}
});

export const deleteService = actionCreator<{ jobServiceId: string }>(
	DELETE_SERVICE
);
