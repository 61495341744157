import actionCreator from '../portCallOperationsActionCreator';

const CYCLE_START_ACTION = 'RETRIEVE_PORTCALL_SECTIONS_CYCLE_START';

const CYCLE_STOP_ACTION = 'RETRIEVE_PORTCALL_SECTIONS_CYCLE_STOP';

export const retrievePortCallSectionsCycleStart = actionCreator<string>(
	CYCLE_START_ACTION
);

export const retrievePortCallSectionsCycleStop = actionCreator(
	CYCLE_STOP_ACTION
);
