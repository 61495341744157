import { LinkJobState } from '../linkJobState';
import { CreateCargoFromNominationRequest } from 'services/api/portCalls/portCallsServiceTypes';

export const onCreateCargoFromNomination = (
	state: LinkJobState,
	action: CreateCargoFromNominationRequest
): LinkJobState => {
	const index = state.linkedNominationCargoes.findIndex(
		cargo => cargo.cargoId === action.nominationCargoId
	);
	const cargo = state.linkedNominationCargoes.find(
		cargo => cargo.cargoId === action.nominationCargoId
	);
	const updatedAppointmentCargos = state.linkedAppointmentCargoes;
	updatedAppointmentCargos[index] = { ...cargo, createdFromNomination: true };
	return {
		...state,
		linkedAppointmentCargoes: [...updatedAppointmentCargos]
	};
};
