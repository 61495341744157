import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
	retrievePortJobThreadsAsync,
	resetPortJobThreads
} from 'store/portJobs/actions/retrievePortJobThreads';
import {
	onSavePortJobThreads,
	onResetPortJobThreads,
	onRetrievePortJobsThreadsStatusStarted,
	onRetrievePortJobsThreadsStatusFailed
} from 'store/portJobs/reducers';
import { addThreadMessageAsync } from 'store/thread/actions/addThreadMessage';
import { retrieveThreadByIdAsync } from 'store/thread/actions/retrieveThreadById';
import { initialState } from './threadsState';
import {
	resetThreads,
	assignJobsToNewThread,
	resetJobsInNewThread,
	unassignJobFromNewThread,
	saveNewThreadData,
	saveEditThreadData,
	resetEditThreadData,
	resetNewThreadData,
	retrieveThreadsAsync,
	retrieveThreadContextsAsync,
	retrieveThreadsStatsAsync,
	retrieveThreadsPendingAlertsAsync,
	createNewThreadAction,
	setActiveThreadId,
	retrieveThreadsAvailableGroupsAsync,
	retrieveThreadsMainPrincipalTagsAsync,
	resetThreadsAvailableGroups,
	updateThreadsContext,
	resetThreadsContext,
	retrieveThreadTagsAsync,
	updateThreadMode,
	updateThreads,
	updateUnreadCount,
	updateFullScreenMode,
	updateAssignToThreadsAsync,
	retrieveThreadsMainPrincipalGroupsAsync,
	resetThreadsGroupsAndTags,
	updateThreadsCategorizeAsync,
	setReloadThreadsFlag
} from './actions';

import {
	onRetrieveThreadStarted,
	onRetrieveThreadSuccess,
	onRetrieveThreadFailed,
	onUpdateThreadStatusInThreadsReducer
} from 'store/thread/reducers';

import {
	onResetThreads,
	onAssignJobsToNewThread,
	onResetJobsInNewThread,
	onUnassignJobFromNewThread,
	onSaveNewThreadData,
	onSaveEditThreadData,
	onResetEditThreadData,
	onResetNewThreadData,
	onRetrieveThreads,
	onSetActiveThreadId,
	onCreateNewThread,
	onRetrieveThreadContexts,
	onRetrieveThreadsAvailableGroups,
	onRetrieveThreadsMainPrincipalTags,
	onResetThreadsAvailableGroups,
	onRetrieveThreadsStatsStarted,
	onRetrieveThreadsStatsFailed,
	onRetrieveThreadsStatsSuccess,
	onRetrieveThreadsPendingAlerts,
	onUpdateThreadsContext,
	onResetThreadsContext,
	onUpdateThreadsCategorize,
	onRetrieveThreadTags,
	onUpdateThreadMode,
	onUpdateThreads,
	onUpdateUnreadCount,
	onUpdateFullScreenMode,
	onUpdateAssignToThreads,
	onRetrieveThreadsMainPrincipalGroups,
	onResetThreadsGroupsAndTags,
	onSetReloadThreadsFlag
} from './reducers';

export default reducerWithInitialState(initialState)
	.case(resetThreads, onResetThreads)
	.case(assignJobsToNewThread, onAssignJobsToNewThread)
	.case(resetJobsInNewThread, onResetJobsInNewThread)
	.case(unassignJobFromNewThread, onUnassignJobFromNewThread)
	.case(saveNewThreadData, onSaveNewThreadData)
	.case(saveEditThreadData, onSaveEditThreadData)
	.case(resetEditThreadData, onResetEditThreadData)
	.case(resetNewThreadData, onResetNewThreadData)
	// Set Active Thread
	.case(setActiveThreadId, onSetActiveThreadId)
	// Update Thread Status if necessary
	.case(addThreadMessageAsync.done, onUpdateThreadStatusInThreadsReducer)
	// Retrieve Threads
	.case(retrieveThreadsAsync.started, onRetrieveThreads.started)
	.case(retrieveThreadsAsync.failed, onRetrieveThreads.failed)
	.case(retrieveThreadsAsync.done, onRetrieveThreads.done)
	// Retrieve Thread Contexts
	.case(retrieveThreadContextsAsync.started, onRetrieveThreadContexts.started)
	.case(retrieveThreadContextsAsync.failed, onRetrieveThreadContexts.failed)
	.case(retrieveThreadContextsAsync.done, onRetrieveThreadContexts.done)
	// Retrieve MainPrincipal Thread Tags
	.case(
		retrieveThreadsMainPrincipalTagsAsync.started,
		onRetrieveThreadsMainPrincipalTags.started
	)
	.case(
		retrieveThreadsMainPrincipalTagsAsync.failed,
		onRetrieveThreadsMainPrincipalTags.failed
	)
	.case(
		retrieveThreadsMainPrincipalTagsAsync.done,
		onRetrieveThreadsMainPrincipalTags.done
	)
	// Retrieve MainPrincipal Thread Groups
	.case(
		retrieveThreadsMainPrincipalGroupsAsync.started,
		onRetrieveThreadsMainPrincipalGroups.started
	)
	.case(
		retrieveThreadsMainPrincipalGroupsAsync.failed,
		onRetrieveThreadsMainPrincipalGroups.failed
	)
	.case(
		retrieveThreadsMainPrincipalGroupsAsync.done,
		onRetrieveThreadsMainPrincipalGroups.done
	)
	// Retrieve Thread Tags
	.case(retrieveThreadTagsAsync.started, onRetrieveThreadTags.started)
	.case(retrieveThreadTagsAsync.failed, onRetrieveThreadTags.failed)
	.case(retrieveThreadTagsAsync.done, onRetrieveThreadTags.done)
	// Retrieve Thread by id
	.case(retrieveThreadByIdAsync.started, onRetrieveThreadStarted)
	.case(retrieveThreadByIdAsync.failed, onRetrieveThreadFailed)
	.case(retrieveThreadByIdAsync.done, onRetrieveThreadSuccess)
	// Retrieve Port Job Threads
	.case(retrievePortJobThreadsAsync.done, onSavePortJobThreads)
	.case(
		retrievePortJobThreadsAsync.started,
		onRetrievePortJobsThreadsStatusStarted
	)
	.case(
		retrievePortJobThreadsAsync.failed,
		onRetrievePortJobsThreadsStatusFailed
	)

	// Reset Port Job Threads
	.case(resetPortJobThreads, onResetPortJobThreads)
	// create new thread
	.case(createNewThreadAction.async.started, onCreateNewThread.started)
	.case(createNewThreadAction.async.failed, onCreateNewThread.failed)
	.case(createNewThreadAction.async.done, onCreateNewThread.done)
	// statistics
	.case(retrieveThreadsStatsAsync.started, onRetrieveThreadsStatsStarted)
	.case(retrieveThreadsStatsAsync.done, onRetrieveThreadsStatsSuccess)
	.case(retrieveThreadsStatsAsync.failed, onRetrieveThreadsStatsFailed)
	// pending alerts
	.case(
		retrieveThreadsPendingAlertsAsync.started,
		onRetrieveThreadsPendingAlerts.started
	)
	.case(
		retrieveThreadsPendingAlertsAsync.failed,
		onRetrieveThreadsPendingAlerts.failed
	)
	.case(
		retrieveThreadsPendingAlertsAsync.done,
		onRetrieveThreadsPendingAlerts.done
	)
	// Threads Available Groups
	.case(
		retrieveThreadsAvailableGroupsAsync.started,
		onRetrieveThreadsAvailableGroups.started
	)
	.case(
		retrieveThreadsAvailableGroupsAsync.failed,
		onRetrieveThreadsAvailableGroups.failed
	)
	.case(
		retrieveThreadsAvailableGroupsAsync.done,
		onRetrieveThreadsAvailableGroups.done
	)
	.case(updateAssignToThreadsAsync.started, onUpdateAssignToThreads.started)
	.case(updateAssignToThreadsAsync.done, onUpdateAssignToThreads.done)
	.case(updateAssignToThreadsAsync.failed, onUpdateAssignToThreads.failed)
	.case(updateThreadsCategorizeAsync.started, onUpdateThreadsCategorize.started)
	.case(updateThreadsCategorizeAsync.done, onUpdateThreadsCategorize.done)
	.case(updateThreadsCategorizeAsync.failed, onUpdateThreadsCategorize.failed)
	.case(resetThreadsAvailableGroups, onResetThreadsAvailableGroups)
	.case(resetThreadsGroupsAndTags, onResetThreadsGroupsAndTags)
	.case(updateThreadsContext, onUpdateThreadsContext)
	.case(resetThreadsContext, onResetThreadsContext)
	.case(updateThreadMode, onUpdateThreadMode)
	.case(updateThreads, onUpdateThreads)
	.case(updateUnreadCount, onUpdateUnreadCount)
	.case(updateFullScreenMode, onUpdateFullScreenMode)
	.case(setReloadThreadsFlag, onSetReloadThreadsFlag);
