import React from 'react';
import ReadMore from '../ReadMore/ReadMore';
import Icon from 'components/antd/Icon/Icon';
import styles from './ShowMoreText.module.scss';

interface ShowMoreProps {
	showLines?: number;
	children: React.ReactNode;
}

export default ({ showLines = 3, children }: ShowMoreProps) => (
	<ReadMore
		maxItems={showLines}
		className={styles.showMore}
		renderFooterContent={props => {
			return (
				<span className={styles.showMoreIndicator}>
					<Icon
						type={props.collapsed ? 'plus-square' : 'minus-square'}
						size="lg"
						valign="middle"
					/>
					&nbsp;
					{props.collapsed ? props.expandText : props.collapseText}
				</span>
			);
		}}
		source={children}
	/>
);
