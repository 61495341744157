import { FundingRequestsState } from '../financeFundingsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	UpdateFundingRequestRequest as Request,
	UpdateFundingRequestResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';

export const updateFundingRequest = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'updateFundingRequest');
