import { UpdateTimeSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import actionCreator from '../savingsActionCreator';

const UPDATE_TIME_SAVINGS = 'UPDATE_TIME_SAVINGS';

export const updateTimeSavingsAsync = actionCreator.async<
	UpdateTimeSavingsRequest,
	void,
	Error
>(UPDATE_TIME_SAVINGS);

export const updateTimeSavings = actionCreator(UPDATE_TIME_SAVINGS);
