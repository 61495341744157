import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse
} from 'services/api/services/servicesServiceTypes';
import Api from 'services/api';
import { retrieveServiceCategoriesAsync as async } from '../actions';

export default makeTakeLatestWatcher<
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse,
	Error
>({
	api: Api.Services.retrieveServiceCategories,
	async
});
