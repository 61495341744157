import actionCreator from '../portJobsActionCreator';
import {
	AddPortJobResponse,
	AddAppointmentFromNominationActionParams
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'ADD_APPOINTMENTJOB_FROM_NOMINATION';

export const addAppointmentJobFromNominationAsync = actionCreator.async<
	AddAppointmentFromNominationActionParams,
	AddPortJobResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Port Job'
	}
});

export const addAppointmentJobFromNomination = actionCreator<
	AddAppointmentFromNominationActionParams
>(ACTION_NAME);
