import {
	RegisterUserRequest,
	PreRegisterVerifyResponse
} from 'services/api/registration/registrationServiceTypes';
import { DateSettings, DefaultTimezone, TimeSettings } from './settings';
import { FetchStatus } from 'services/api/apiTypes';

export interface PreRegistrationAgentCompany {
	id: string;
	name: string;
	email: string;
}

export interface RegistrationState {
	readonly invitationToken: string;
	readonly initialValues: RegisterUserRequest;
	readonly isFetching: boolean;
	readonly fetchStatuses: {
		retrieving: FetchStatus;
		adding: FetchStatus;
		retrievePreRegistrationAgentCompany: FetchStatus;
		preRegister: FetchStatus;
		preRegisterVerify: FetchStatus;
	};
	readonly preRegistration: {
		agentCompany?: PreRegistrationAgentCompany;
		userEmail?: string;
	};
	readonly preRegistrationAgentCompany: PreRegistrationAgentCompany | null;
	readonly preRegistrationVerify: PreRegisterVerifyResponse | null;
	readonly error: Error | boolean;
}

export const initialState: RegistrationState = {
	invitationToken: '',
	initialValues: {
		cellphone: '',
		email: 'demo@optic.co.uk',
		firstName: 'name',
		lastName: 'lastname',
		middleName: '',
		password: '',
		isInternalUser: false,
		dateFormat: DateSettings.DayFirst,
		termsOfServiceVersion: '',
		timeFormat: TimeSettings.Hours12,
		timeZone: DefaultTimezone,
		passwordValidationRules: {
			passwordValidationMask: '',
			rules: []
		},
		deviceId: ''
	},
	isFetching: false,
	fetchStatuses: {
		retrieving: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		retrievePreRegistrationAgentCompany: FetchStatus.IDLE,
		preRegister: FetchStatus.IDLE,
		preRegisterVerify: FetchStatus.IDLE
	},
	preRegistration: {},
	preRegistrationAgentCompany: null,
	preRegistrationVerify: null,
	error: false
};
