/**
 * @file retrieve user profile
 */

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, all, fork } from 'redux-saga/effects';

import { retrieveUser, retrieveUserAsync } from '../actions/retrieveUser';

import Api from 'services/api';

export function* retrieveUserWorker(action: Action<string>): SagaIterator {
	const id = action.payload;
	yield put(retrieveUserAsync.started(id));

	try {
		const response = yield call(Api.Users.retrieveUserProfile, id);

		yield put(
			retrieveUserAsync.done({
				result: response.data || response,
				params: id,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveUserAsync.failed({
				error,
				params: id
			})
		);
	}
}

function* retrieveUserWatcher(): SagaIterator {
	yield takeLatest(retrieveUser.type, retrieveUserWorker);
}

export default function*() {
	yield all([fork(retrieveUserWatcher)]);
}
