import * as React from 'react';
import { GenericPortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { Text } from 'components';
import { ShowMoreText } from 'components/ReadMore';

export default ({ operation }: { operation: GenericPortJobOperation }) => (
	<>
		<Text upper weight="bold">
			Operation Details
		</Text>
		<ShowMoreText showLines={3}>{operation.details}</ShowMoreText>
	</>
);
