import { FetchStatus } from 'services/api/apiTypes';
import {
	ContactType,
	MessageAttachment,
	Message
} from 'services/api/messages/messagesServiceTypes';
import { Mailbox } from 'services/api/mailboxes/mailboxesServiceTypes';

export interface MessagesState {
	readonly mailboxesById: { [mailboxId: string]: Mailbox };
	readonly messagesHistory: {
		[key: string]: Message;
	};
	readonly fetchStatuses: {
		retrieving: FetchStatus;
		adding: FetchStatus;
		updating: FetchStatus;
		retrieveMailboxes: FetchStatus;
		downloadingEmlFile: FetchStatus;
	};
	readonly currentContact: ContactType | undefined;
	readonly attachmentsById: {
		[key: string]: MessageAttachment;
	};
	readonly attachmentsByIdStatusMessage: {
		[key: string]: string;
	};
	readonly attachmentsByIdFetchStatuses: {
		[key: string]: FetchStatus;
	};
	readonly error: Error | boolean;
}

export const initialState: MessagesState = {
	mailboxesById: {},
	messagesHistory: {},
	fetchStatuses: {
		retrieving: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		retrieveMailboxes: FetchStatus.IDLE,
		downloadingEmlFile: FetchStatus.IDLE
	},
	currentContact: undefined,
	attachmentsById: {},
	attachmentsByIdStatusMessage: {},
	attachmentsByIdFetchStatuses: {},
	error: false
};
