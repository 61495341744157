import React from 'react';
import { Row, Col } from 'components/antd';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Title, LabeledText, Content } from 'components';
import styles from './BankAccountDetails.module.scss';

interface AccountInformationProps {
	bank: BankAccount;
}

const AccountInformation = (props: AccountInformationProps) => {
	const {
		bankAccountName,
		bankAccountCurrency,
		bankAccountNumber,
		bankSortCode,
		bankSwiftCode,
		iban,
		notes
	} = props.bank;
	return (
		<>
			<Title>ACCOUNT INFORMATION</Title>
			<Content>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText
							label="Account/Beneficiary Name"
							value={bankAccountName}
						/>
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Account Currency" value={bankAccountCurrency} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="Account Number" value={bankAccountNumber} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Sort Code" value={bankSortCode} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="IBAN" value={iban} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="SWIFT BIC" value={bankSwiftCode} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="Notes" value={notes} />
					</Col>
				</Row>
			</Content>
			<hr />
		</>
	);
};

export default AccountInformation;
