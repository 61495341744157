import React from 'react';

import CargoLines from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLines/CargoLines';
import CargoLine from './CargoLine/CargoLine';

const LoadForm = () => (
	<CargoLines>
		{props => <CargoLine onAdd={props.onSubmit} onCancel={props.onCancel} />}
	</CargoLines>
);

export default LoadForm;
