import React from 'react';
import classNames from 'classnames';
import { defaultStatusMap } from 'components/Status/constants';
import TimeAgo from 'components/Status/TimeAgo/TimeAgo';
import styles from './Label.module.scss';
import { Size } from 'components/types';

interface LabelProps {
	value: string;
	date?: string;
	generic?: boolean;
	ghost?: boolean;
	unstyled?: boolean;
	className?: string;
	statusDot?: string;
	size?: Size;
}

/**
 * @example
 * 		<Status.Label value="Open"  />
 * 		<Status.Label value="Open" generic />
 * 		<Status.Label value="Open" date="2018-02-15T10:52:58.384064Z" />
 */
const Label = ({ className, ...props }: LabelProps) => {
	const statusName = props.statusDot ? `status-${props.statusDot}` : '';

	const statusSize = props.size ? `size-${props.size}` : '';

	const statusClassNames = classNames(styles.statusDot, [styles[statusName]]);

	return (
		<span
			className={classNames(
				styles.label,
				{
					[styles.generic]: props.generic,
					[styles.ghost]: props.ghost,
					[styles[defaultStatusMap[props.value]]]:
						!props.unstyled && Boolean(defaultStatusMap[props.value]),
					[styles.unstyled]: props.unstyled,
					[styles[statusSize]]: Boolean(props.size)
				},
				className
			)}
		>
			{props.statusDot ? <i className={statusClassNames} /> : null}
			<span>{props.value}</span>
			{props.date && <TimeAgo className={styles.timeAgo} date={props.date} />}
		</span>
	);
};

export default Label;
