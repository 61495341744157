import { EventListAndOpticVariablesData } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_EVENT_LIST_AND_OPTIC_VARIABLES';
export const retrieveEventListAndOpticVariables = actionCreator(ACTION_NAME);
export const retrieveEventListAndOpticVariablesAsync = actionCreator.async<
	null,
	EventListAndOpticVariablesData,
	Error
>(ACTION_NAME);
