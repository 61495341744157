import { LabeledValue } from 'app-types.d';
import { FilterItem } from 'store/filters/filtersState';
import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';

export const SUPPLIER_INVOICE_FORM_ID = 'supplier_invoices';

export const AllHubPrincipalsOption: LabeledValue<string> = {
	key: 'all',
	label: 'All'
};

export enum SupplierInvoiceFilterParam {
	SEARCH = 'searchTerm',
	HUB_PRINCIPAL = 'mainPrincipal',
	SUPPLIERS = 'suppliers',
	GL_NUMBERS = 'glNumbers',
	JOBS = 'jobs',
	REFERENCE_NUMBERS = 'referenceNumbers',
	CURRENCIES = 'currencies',
	SHOW_CANCELLED = 'includeCancelled'
}

export interface SupplierInvoiceFilters {
	mainPrincipal?: FilterItem;
	searchTerm?: FilterItem;
}

export interface SupplierInvoiceParam {
	mainPrincipal?: string;
	suppliers?: string[];
	glNumbers?: string[];
	jobs?: string[];
	referenceNumbers?: string[];
	currencies?: string[];
	companies?: string[];
}

export const SupplierInvoicesFilterLabels = {
	[SupplierInvoiceFilterParam.SEARCH]: 'Search',
	[SupplierInvoiceFilterParam.HUB_PRINCIPAL]: 'Main Principal'
};

/** How groupType names came from the API */
export enum SupplierInvoicesGroupType {
	SEARCH = 'SearchTerm',
	COMPANIES = 'Companies',
	HUB_PRINCIPAL = 'MainPrincipal',
	INVOICE_REFERENCES = 'SupplierInvoiceReferences',
	GL_NUMBERS = 'SupplierInvoiceGLNumbers',
	PORT_JOB_CODE = 'PortJobCode',
	CURRENCIES = 'Currencies'
}

/**
 * Param Name to be sent to the API
 **/
export const filterTypeMap = {
	[SupplierInvoicesGroupType.SEARCH]: SupplierInvoiceFilterParam.SEARCH,
	[SupplierInvoicesGroupType.HUB_PRINCIPAL]:
		SupplierInvoiceFilterParam.HUB_PRINCIPAL,
	[SupplierInvoicesGroupType.COMPANIES]: SupplierInvoiceFilterParam.SUPPLIERS,
	[SupplierInvoicesGroupType.GL_NUMBERS]: SupplierInvoiceFilterParam.GL_NUMBERS,
	[SupplierInvoicesGroupType.PORT_JOB_CODE]: SupplierInvoiceFilterParam.JOBS,
	[SupplierInvoicesGroupType.INVOICE_REFERENCES]:
		SupplierInvoiceFilterParam.REFERENCE_NUMBERS,
	[SupplierInvoicesGroupType.CURRENCIES]: SupplierInvoiceFilterParam.CURRENCIES
};

/**
 * Name for the groups dividers inside the Search
 * and the labels presented in <Filter.Tags>
 **/
export const searchTypeMap = {
	[SupplierInvoiceFilterParam.SEARCH]: 'Search Term',
	[SupplierInvoiceFilterParam.HUB_PRINCIPAL]: 'Main Principal',
	[SupplierInvoiceFilterParam.SHOW_CANCELLED]: 'Include Cancelled',
	[SupplierInvoicesGroupType.COMPANIES]: 'Suppliers',
	[SupplierInvoicesGroupType.INVOICE_REFERENCES]: 'Invoice References',
	[SupplierInvoicesGroupType.GL_NUMBERS]: 'GL Document #',
	[SupplierInvoicesGroupType.PORT_JOB_CODE]: 'Job Id',
	[SupplierInvoicesGroupType.INVOICE_REFERENCES]: 'Reference Numbers',
	[SupplierInvoicesGroupType.CURRENCIES]: 'Currencies'
};

export const filterIdentifiers = {
	[SupplierInvoicesGroupType.SEARCH]: 'label',
	[SupplierInvoicesGroupType.COMPANIES]: 'key',
	[SupplierInvoicesGroupType.HUB_PRINCIPAL]: 'key',
	[SupplierInvoicesGroupType.INVOICE_REFERENCES]: 'key',
	[SupplierInvoicesGroupType.GL_NUMBERS]: 'key',
	[SupplierInvoicesGroupType.PORT_JOB_CODE]: 'key',
	[SupplierInvoicesGroupType.CURRENCIES]: 'key'
};

const filtersSync = {
	[SUPPLIER_INVOICE_FORM_ID]: {
		selector: (state: AppState) => state.filters[SUPPLIER_INVOICE_FORM_ID],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return { name: SUPPLIER_INVOICE_FORM_ID, filters: JSON.parse(value) };
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
