import React from 'react';
import UploadWithValidation from 'components/antd/Upload/UploadWithValidation';
import { postCreditUploadDocumentDone } from 'store/supplierInvoice/actions';
import { UploadType } from 'services/api/documents/documentsServiceTypes';
import { UploadProcessDocumentProvider } from 'containers';
import { useActiveSupplierInvoice } from 'sections/SupplierInvoice/SupplierInvoice.hooks';

interface PostCreditSettingsItemProps {
	label: string;
	invoiceId: string;
}

const uploadType = UploadType.SUPPLIER_INVOICE;

const PostCreditSettingsItem: React.SFC<PostCreditSettingsItemProps> = ({
	label,
	invoiceId
}) => {
	useActiveSupplierInvoice(invoiceId, true);

	return (
		<UploadProcessDocumentProvider
			onDone={postCreditUploadDocumentDone}
			uploadType={uploadType}
			showProgress
			metadata={{ contextualizePdf: true }}
		>
			{({ accept, customRequest }) => (
				<UploadWithValidation
					accept={accept}
					showUploadList={false}
					uploadType={uploadType}
					customRequest={customRequest}
				>
					{label}
				</UploadWithValidation>
			)}
		</UploadProcessDocumentProvider>
	);
};

export default PostCreditSettingsItem;
