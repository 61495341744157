import { Success } from 'typescript-fsa';
import { CompaniesState } from '../companiesState';
import {
	RetrieveCompaniesForOrganisationRequest,
	RetrieveCompaniesForOrganisationResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveCompaniesForOrganisationSuccess = (
	state: CompaniesState,
	{
		params,
		result
	}: Success<
		RetrieveCompaniesForOrganisationRequest,
		RetrieveCompaniesForOrganisationResponse
	>
) => ({
	...state,
	byOrganisation: {
		...state.byOrganisation,
		[params.organisationType]: result.elements
	}
});

export const onRetrieveCompaniesForOrganisation = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompaniesForOrganisationRequest,
	RetrieveCompaniesForOrganisationResponse
>('fetchStatuses', 'retrieveForOrganisation', {
	doneReducer: onRetrieveCompaniesForOrganisationSuccess
});
