import * as React from 'react';
import { CashToMasterPortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { ShowMoreText } from 'components/ReadMore';
import { Row, Col } from 'components/antd';
import { OptionalValue, Text } from 'components';
import OverviewTotalAmountGrid from '../TotalAmount/OverviewTotalAmountGrid';
import styles from '../../../JobDetails.module.scss';

export default ({ operation }: { operation: CashToMasterPortJobOperation }) => (
	<>
		<Row>
			<Col xs={4} lg={3}>
				<Row>
					<Col xs={3}>
						<Text weight="bold">Master</Text>
					</Col>
					<Col xs={9}>
						<OptionalValue value={operation.masterFullName}>
							<Text className={styles.master}>{operation.masterFullName}</Text>
						</OptionalValue>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<OverviewTotalAmountGrid data={operation.totalAmounts} />
					</Col>
				</Row>
			</Col>
			<Col xs={8} lg={9}>
				<Text upper weight="bold">
					Operation Details
				</Text>
				<ShowMoreText showLines={3}>{operation.details || 'None'}</ShowMoreText>
			</Col>
		</Row>
	</>
);
