import React, { useState, useCallback } from 'react';
import { TextArea, Icon } from 'components/antd';
import styles from './EditToolbar.module.scss';
import { Flex } from 'components';

interface TextInsertPopoverProps {
	x: number;
	y: number;
	text: string;
	color: string;
	onSaveText: (text: string) => void;
	onClose: () => void;
}

function TextInsertPopover(props: TextInsertPopoverProps) {
	const [text, setText] = useState(props.text);
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};
	const onSaveText = useCallback(() => props.onSaveText(text), [props, text]);

	return (
		<div className={styles.textForm} style={{ top: props.y, left: props.x }}>
			<Flex direction="horizontal">
				<TextArea
					style={{ color: props.color }}
					value={text}
					onChange={onChange}
				/>
				<Flex className={styles.textTools} direction="vertical">
					<Icon type="check" color="primary" size="lg" onClick={onSaveText} />
					<Icon
						type="close"
						color="primary"
						size="lg"
						onClick={props.onClose}
					/>
				</Flex>
			</Flex>
		</div>
	);
}

export default React.memo(TextInsertPopover);
