import React from 'react';
import { HeroPanel, Link } from 'components';
import { PreRegistrationAgentCompany } from 'store/registration/registrationState';

type PreRegistrationPanelProgressProps = PreRegistrationAgentCompany;

const PreRegistrationPanelProgress = ({
	id,
	name,
	email
}: PreRegistrationPanelProgressProps) => (
	<HeroPanel type="info" center>
		<HeroPanel.Title>Welcome {name} user,</HeroPanel.Title>
		Please enter your details below to create an Optic account. A personal email
		address is required to use the system. For security reasons a verification
		email will be sent to your company's registered email address, {email}. Once
		your request has been approved, you will receive an email with a link to
		continue the registration process.
		<br />
		<Link href={`/login?agentCompany=${id}`}>Back to Login</Link>
	</HeroPanel>
);

interface PreRegistrationPanelSuccessProps {
	id?: string;
	email: string;
}

const PreRegistrationPanelSuccess = ({
	id,
	email
}: PreRegistrationPanelSuccessProps) => (
	<HeroPanel type="success" center>
		<HeroPanel.Title>
			Registration request verified successfully.
		</HeroPanel.Title>
		{id ? (
			<>
				Verification email was sent to your company's registered email address,{' '}
				{email}.
			</>
		) : (
			<>Verification email was sent to your email address, {email}.</>
		)}{' '}
		Follow the instructions in email to continue registration.
	</HeroPanel>
);

const PreRegistrationPanelFailed: React.SFC = ({ children }) => (
	<HeroPanel type="error" center>
		<HeroPanel.Title>
			{children || `Sorry, the link is not valid.`}
		</HeroPanel.Title>
	</HeroPanel>
);

export {
	PreRegistrationPanelProgress,
	PreRegistrationPanelSuccess,
	PreRegistrationPanelFailed
};
