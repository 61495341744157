import Api from 'services/api';
import { makeTakeEveryWatcher, makeDefaultExecutor } from 'utils/sagaHelpers';
import { call, select } from 'redux-saga/effects';
import {
	DashboardPortCallsResponse as Response,
	RetrieveDashboardPortCallsRequest as Request
} from 'services/api/dashboard/dashboardServiceTypes';
import { retrieveActiveSectionAsync as async } from '../actions';
import { getActiveSectionCode } from '../selectors';
import { DASHBOARD_JOBS_LIMIT } from '../constants';

const api = Api.Dashboard.retrieveDashBoardPortCallsByStatusCode;

export const commonExecutor = makeDefaultExecutor<Request, Response, Error>({
	api,
	async
});

export const executor = function*() {
	const statusCode = yield select(getActiveSectionCode);
	if (!statusCode) {
		// refresh cycle happend but no active section
		return;
	}
	yield call(commonExecutor, {
		index: 0,
		limit: DASHBOARD_JOBS_LIMIT,
		statusCode
	});
};

export default makeTakeEveryWatcher<Request, Response, Error>(
	{ api, async },
	executor
);
