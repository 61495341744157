import { call, select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	FinanceRequest,
	ApproveAllServicesResponse
} from 'services/api/finance/financeServiceTypes';
import { approveAllServicesAsync } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.approveAllServices;
const commonExecutor = makeDefaultExecutor<
	FinanceRequest,
	ApproveAllServicesResponse,
	Error
>({
	api,
	async: approveAllServicesAsync
});

export const executor = function*() {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);

	yield call(commonExecutor, {
		portCallId,
		jobCode
	});
};

export default makeTakeEveryWatcher<
	FinanceRequest,
	ApproveAllServicesResponse,
	Error
>(
	{
		api,
		async: approveAllServicesAsync
	},
	executor
);
