import { FinanceState } from '../financeState';
import { UpdateFinanceStatusPayload } from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateFinanceStatus = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateFinanceStatusPayload,
	null
>('fetchStatuses', 'updateFinanceStatus');
