import actionCreator from '../configurationFilesActionCreator';
import { DeleteCompanyTemplateFileRequest } from 'services/api/templates/templatesServiceTypes';
const ACTION_NAME = 'DELETE_CONFIGURATION_FILE';

export const deleteConfigurationFileAsync = actionCreator.async<
	DeleteCompanyTemplateFileRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'File Mapping'
	}
});

export const deleteConfigurationFile = actionCreator<
	DeleteCompanyTemplateFileRequest
>(ACTION_NAME);

export const openConfigurationFileDeleteModal = actionCreator<
	DeleteCompanyTemplateFileRequest
>(`ON_${ACTION_NAME}`);
