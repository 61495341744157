import actionCreator from '../wizardFormActionCreator';

import { WizardFormBaseAction, WizardFormPageState } from '../wizardFormState';

export interface WizardFormSetActivePageState
	extends WizardFormBaseAction,
		WizardFormPageState {}

const SET_ACTIVE_PAGE_STATE = 'SET_ACTIVE_PAGE_STATE';
export const setActivePageState = actionCreator<WizardFormSetActivePageState>(
	SET_ACTIVE_PAGE_STATE
);
