import * as React from 'react';
import { ScrollableLayout, Content } from 'components';
import Header from './Header';
import Form from './Form/Form';
import { InjectedWizardFormBaseProps } from 'components/WizardForm';
import { getPortCallContext } from 'store/portCall/portCallSelectors';
import { connect } from 'react-redux';
import { getIsFormOperationTypeLoadingOrDischarge } from './page3Selectors';
import { isOperationFormValid } from './helpers';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { AppState } from 'store-types';

export type PageProps = InjectedWizardFormBaseProps & {
	isOperationFormOpen: boolean;
	isLoadingOrDischargeOperation: boolean;
};
class Page extends React.Component<PageProps> {
	onFormStateChange = (formValid: boolean) => {
		const { isOperationFormOpen, isLoadingOrDischargeOperation } = this.props;
		const valid = isOperationFormValid({
			formValid,
			isOperationFormOpen,
			isLoadingOrDischargeOperation
		});
		if (this.props.activePageState.valid !== valid) {
			this.props.setActivePageState({ valid });
		}
	};

	render() {
		return (
			<>
				<Header />
				<ScrollableLayout>
					<Content>
						<ContextBar
							title="Please provide the details of the Operation"
							type={ContextBarType.ACTION}
							actor={[UserType.PRINCIPAL, UserType.HUB]}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
							subtitle="First select the Operation Type, and then fill out the other requested details"
							condition={true}
						/>
						<Form
							destroyOnUnmount={false}
							onFormStateChange={this.onFormStateChange}
						/>
					</Content>
				</ScrollableLayout>
			</>
		);
	}
}
export default connect((state: AppState) => ({
	isOperationFormOpen: !!getPortCallContext(state, 'operationFormVisible'),
	isLoadingOrDischargeOperation: getIsFormOperationTypeLoadingOrDischarge(state)
}))(Page);
