import React from 'react';
import { connect } from 'react-redux';
import AgentField from '../AgentField';
import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { AgentProps } from '../types';
import { getPortJobForControllingAgentCompositeKey } from '../../../../page2Selectors';
import { AppState } from 'store-types';

type PerformingAgentFieldProps = AgentProps & {
	controllingAgentKeys: string[];
};

const PerformingAgentField = ({
	disabled,
	search,
	withParentId,
	onAddDraftClick,
	controllingAgentKeys
}: PerformingAgentFieldProps) => (
	<AgentField
		name={FormFieldName.PERFORMING_AGENT}
		label="Performing Agent:"
		disabled={disabled}
		disabledKeys={controllingAgentKeys} // block choosing the item chosen in controlling agent
		required
		search={search}
		withParentId={withParentId}
		onAddDraftClick={onAddDraftClick}
	/>
);

export default connect((state: AppState) => ({
	controllingAgentKeys: getPortJobForControllingAgentCompositeKey(state)
}))(PerformingAgentField);
