import React from 'react';
import { connect } from 'react-redux';
import { isFunction, isEqual, has } from 'lodash';
import { Button, Upload } from 'components/antd';
import { UploadChangeParam, UploadFile } from 'components/antd/Upload/Upload';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import {
	retrieveRelativeDocumentConfig,
	removeAttachment,
	beforeUploadAttachment,
	saveAttachment,
	retrieveStaticDocumentConfig
} from 'store/documents/actions';
import { openModal } from 'store/modals/actions';
import { UploadProps } from 'antd/lib/upload/Upload';
import {
	RetrieveRelativeDocumentConfigResponse,
	RetrieveStaticDocumentConfigResponse,
	UploadParams,
	UploadType
} from 'services/api/documents/documentsServiceTypes';
import styles from './UploadAttachment.module.scss';
import { toMb } from 'services/api/documents/documentsUtils';
import { Attachment as MessageAttachment } from 'services/api/messages/messagesServiceTypes';
import {
	getDocumentAllowedExtensions,
	getDocumentRelativeConfig,
	getDocumentStaticConfigByType
} from 'store/documents/selectors';
import { getInvalidFileNameError } from 'utils/validations';
import { AppState } from 'store-types';
import { isUploadingAttachmentSelector } from 'sections/ComposeMessage/ComposeMessageSelector';

interface UploadAttachmentProps extends UploadProps {
	/**
	 * Document configuration (static/relative)
	 */
	documentConfig: {
		static: RetrieveStaticDocumentConfigResponse;
		relative: RetrieveRelativeDocumentConfigResponse;
	};
	allowedExtensions: string;
	/**
	 * Action for getting static document configuration
	 */
	retrieveStaticDocumentConfig: typeof retrieveStaticDocumentConfig;
	retrieveRelativeDocumentConfig: typeof retrieveRelativeDocumentConfig;
	beforeUploadAttachment: typeof beforeUploadAttachment;
	saveAttachment: typeof saveAttachment;
	removeAttachment: typeof removeAttachment;
	onRemoveAttachment?: (id: string) => void;
	attachments?: MessageAttachment[];

	openModal: typeof openModal;

	// Property for quick fix
	onAddAttachmentStarted?: () => void;

	isUploadingAttachment: boolean;
}

/**
 * DocumentType in `/document` GET request
 */
const defaultType: UploadType = UploadType.ATTACHMENT;

interface UploadAttachmentState {
	currentSize: number;
	/**
	 * Flag to use when file(s) size(s) exceed the `maxFileSize`
	 */
	sizeError: boolean;
	contentTypeError: boolean;
	fileNameError?: string;
	emptyFileError: boolean;
	maxFileListError: boolean;
	maxFileList: number;
	/**
	 * Data for POST to AWS
	 */
	fileList: UploadFile[];
}

// TODO: Don't re-use it. There is inconsitency in file interfaces that why we need this interface here
interface Attachment extends UploadFile {
	id?: string;
}

interface UploadAttachmentPopoverError {
	message: string;
	type: 'error' | 'warning' | 'info';
	title?: string;
}
// TODO: we should re-write this component in order to get rid of unnecessary logic inside the state and adjusting design to current version
class UploadAttachment extends React.Component<
	UploadAttachmentProps,
	UploadAttachmentState
> {
	constructor(props: UploadAttachmentProps) {
		super(props);
		this.state = {
			currentSize: 0,
			sizeError: false,
			contentTypeError: false,
			fileNameError: undefined,
			emptyFileError: false,
			maxFileListError: false,
			maxFileList: 150,
			fileList: this.makeAttachment(props.attachments || [])
		};
	}

	/**
	 * TODO: Remove it. We need totally refactor this component and approach for working with attachments.
	 */
	makeAttachment = (attachments: MessageAttachment[]): UploadFile[] => {
		return attachments.map((file, index) => ({
			id: file.id,
			uid: (file.originFileObj && file.originFileObj.uid) || String(index),
			name: file.name,
			size: file.size,
			type: file.contentType || ''
		}));
	};

	componentDidMount() {
		this.props.retrieveStaticDocumentConfig({ type: defaultType });
	}

	componentDidUpdate(prevProps: UploadAttachmentProps) {
		/**
		 * props.attachments and state.fileList are to be synchronized
		 * in order attachments to have "id" for proper removal
		 */
		if (!isEqual(prevProps.attachments, this.props.attachments)) {
			/* When we forward messages need to preserve the previous attachments
				Those attachments doesn't has the processed property.
				Processed property will be available just uploaded attachments */

			const attachments = this.props.attachments?.filter(attachment => {
				if (has(attachment, 'processed')) {
					if (attachment.processed) {
						return true;
					} else {
						this.onRemove(this.makeAttachment([attachment])[0]);
						return false;
					}
				} else {
					return true;
				}
			});

			this.setState({
				fileList: this.makeAttachment(attachments || [])
			});
		}
	}

	prepareData = (
		file: UploadFile
		// response: RetrieveRelativeDocumentConfigResponse
	): UploadParams => {
		const { documentConfig } = this.props;
		const { form } = documentConfig.relative;
		return {
			...form,
			key: form.key.replace('{filename}', file.name),
			file
		};
	};

	onBeforeUpload = (file: UploadFile) => {
		const { documentConfig } = this.props;
		const { maxFileSize, allowedFileNameCharacters } = documentConfig.static;
		const { fileList, maxFileList } = this.state;
		let maxFileListError = false;

		let emptyFileError = false;
		let contentTypeError = false;
		let sizeError = false;
		let currentSize = this.state.currentSize;

		if (file.size === 0) {
			emptyFileError = true;
		}

		if (!this.itsValidType(file)) {
			contentTypeError = true;
		}

		const fileNameError = getInvalidFileNameError(
			file.name,
			allowedFileNameCharacters
		);

		/**
		 * Validation for display or hide the poppup
		 */
		if (file.size <= maxFileSize) {
			sizeError = false;
			currentSize = currentSize + file.size;
		} else {
			sizeError = true;
		}
		if (fileList.length === maxFileList) {
			maxFileListError = true;
		}
		this.setState(
			{
				emptyFileError,
				contentTypeError,
				fileNameError,
				sizeError,
				currentSize,
				maxFileListError
			},
			() => {
				/**
				 * Notify about upload start
				 * We have to check there is any error here, otherwise we lock send button witn spinner
				 */
				if (!this.getError()) {
					this.props.beforeUploadAttachment();
				} else {
					const {
						type,
						message,
						title = 'Attachment Error'
					} = this.getError() as UploadAttachmentPopoverError;

					this.props.openModal({
						name: 'UploadAttachment',
						type: 'confirm',
						alertType: type,
						data: {
							title,
							description: message,
							okText: 'Accept',
							cancelButton: <span />
						}
					});
				}
			}
		);

		// Return true and handle the fileList during onChange callback
		return true;
	};

	decreaseSize = (size: number) => {
		this.setState({
			currentSize: this.state.currentSize - size,
			sizeError: false
		});
	};

	onButtonClick = () => {
		this.props.retrieveRelativeDocumentConfig({
			type: defaultType
		});
	};

	itsValidType = (file: UploadFile) => {
		const { documentConfig } = this.props;
		const { allowedExtensions } = documentConfig.static;

		const extension = file.name.split('.').pop() || '';
		const valid = allowedExtensions.some(
			(ext: string) => ext.toLowerCase() === extension.toLowerCase()
		);
		return valid;
	};

	onRemove = (file: Attachment) => {
		this.decreaseSize(file.size);
		this.props.removeAttachment({ uid: file.uid });
		// Only for cases with not new messages
		if (isFunction(this.props.onRemoveAttachment) && file.id) {
			this.props.onRemoveAttachment(file.id);
		}
		return true;
	};

	onChange = (info: UploadChangeParam) => {
		const { documentConfig } = this.props;
		const {
			maxFileList,
			contentTypeError,
			fileNameError,
			sizeError,
			emptyFileError
		} = this.state;
		const { documentType } = documentConfig.static;
		const { id } = documentConfig.relative;
		const file: UploadFile = info.file;

		let maxFileListError = false;
		let fileList = info.fileList;

		if (fileList.length > maxFileList) {
			maxFileListError = true;
		}

		const document = {
			id,
			name: file.name,
			documenttype: documentType,
			size: file.size,
			originFileObj: file
		};

		if (file.status === 'success' || file.status === 'done') {
			// Fire request to /document
			this.props.saveAttachment(document);
			if (this.props.onAddAttachmentStarted) {
				this.props.onAddAttachmentStarted();
			}
		}

		if (
			sizeError ||
			emptyFileError ||
			maxFileListError ||
			fileNameError ||
			contentTypeError
		) {
			/**
			 * Manually remove it from list if it surpass the maxFileSize, the maxFileListError or if it's empty
			 */
			fileList = info.fileList.filter((fileFromList: UploadFile) => {
				return !(fileFromList.uid === file.uid);
			});
		}
		/**
		 * Update the custom file list
		 */
		this.setState({ fileList, maxFileListError });
	};

	closePopover = () => {
		this.setState({
			sizeError: false,
			contentTypeError: false,
			emptyFileError: false,
			maxFileListError: false
		});
	};

	getError = (): UploadAttachmentPopoverError | undefined => {
		const {
			sizeError,
			contentTypeError,
			fileNameError,
			emptyFileError,
			maxFileListError,
			maxFileList
		} = this.state;

		const { maxFileSize } = this.props.documentConfig.static;

		if (sizeError) {
			return {
				message: `Maximum file size allowed: ${toMb(maxFileSize)}Mb`,
				type: 'warning'
			};
		}

		if (contentTypeError) {
			return {
				message: 'Forbidden file type',
				type: 'error'
			};
		}

		if (fileNameError) {
			return {
				message: fileNameError,
				type: 'error',
				title: `File can't be uploaded`
			};
		}

		if (emptyFileError) {
			return {
				message: `An attachment's could not be empty`,
				type: 'warning'
			};
		}

		if (maxFileListError) {
			return {
				message: `You have reached the limit of attachments (${maxFileList})`,
				type: 'warning'
			};
		}

		return;
	};

	isUploadForAnyFilePending = (fileList: UploadFile[]) =>
		fileList.some(file => file.status === 'uploading');

	render() {
		const { fileList } = this.state;
		const {
			documentConfig,
			allowedExtensions,
			isUploadingAttachment
		} = this.props;
		const { url } = documentConfig.static;
		const isUploadButtonDisabled =
			!url || this.isUploadForAnyFilePending(fileList);
		const tooltip: TooltipProps | undefined = isUploadButtonDisabled
			? {
					title: 'Please wait until the upload is complete',
					placement: 'bottomLeft',
					getPopupContainer: () => document.body
			  }
			: isUploadingAttachment
			? {
					title: 'Scanning for viruses in progress',
					placement: 'bottomLeft',
					getPopupContainer: () => document.body
			  }
			: undefined;

		return (
			<div className={styles.root}>
				<Upload
					name="attachment"
					action={url}
					accept={allowedExtensions}
					data={file => this.prepareData(file)}
					className={styles.uploadAttachment}
					fileList={fileList}
					listType="text"
					multiple={false}
					showUploadList={{
						showPreviewIcon: true,
						showRemoveIcon:
							true && (isUploadButtonDisabled || !isUploadingAttachment)
					}}
					onRemove={this.onRemove}
					onChange={this.onChange}
					beforeUpload={this.onBeforeUpload}
					uploadType={UploadType.ATTACHMENT}
					preventDefaultBeforeUpload
				>
					<Button
						type="primary"
						transparent
						icon="plus"
						disabled={isUploadButtonDisabled || isUploadingAttachment}
						tooltip={tooltip}
						onClick={this.onButtonClick}
					>
						{this.props.children}
					</Button>
				</Upload>
			</div>
		);
	}
}

export default connect(
	(state: AppState) => ({
		documentConfig: {
			static: getDocumentStaticConfigByType(state, defaultType),
			relative: getDocumentRelativeConfig(state)
		},
		allowedExtensions: getDocumentAllowedExtensions(state, defaultType),
		isUploadingAttachment: isUploadingAttachmentSelector(state)
	}),
	{
		retrieveStaticDocumentConfig,
		retrieveRelativeDocumentConfig,
		beforeUploadAttachment,
		saveAttachment,
		removeAttachment,
		openModal
	}
)(UploadAttachment);
