import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { LabeledValue } from 'app-types';

export const CANCEL_VESSEL_FORM = 'CancelVesselAdding';
export const VESSEL_FORM = 'AddVesselForm';

export enum PageMode {
	EDIT = 'edit',
	ADD = 'add'
}

export enum VesselFormNames {
	VESSEL_TYPE = 'companyVesselTypeMapping',
	PRINCIPAL_NUMBER = 'externalCode',
	EMAIL = 'email',
	OPERATOR_EMAIL = 'operatorEmails',
	PHONE_1 = 'telephone1',
	PHONE_2 = 'telephone2',
	MOBILE_PHONE = 'mobilePhone',
	FAX = 'fax',
	TELEX_1 = 'telex1',
	TELEX_2 = 'telex2',
	DETAILS = 'additionalContactDetails',
	GENERAL_VESSEL_DETAILS = 'generalVesselDetails',
	GT_LESS_SBT = 'gtLessSBT'
}

export type VesselFormData = Omit<
	CompanyVessel,
	'companyVesselTypeMapping' | 'operatorEmails'
> & {
	companyVesselTypeMapping: string;
	operatorEmails?: LabeledValue[];
};
