import { first } from 'lodash';
import { FormWarnings } from 'redux-form';
import runValidations, { arrayMin } from 'utils/validations';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

const warn = (values: FormData): FormWarnings<FormData> => {
	return {
		// `Details` field should be mandatory if no grades are added.
		// This is used as helper in marking field as required from UI perspective
		bunkeringOperationGrades: first(
			runValidations(values.bunkeringOperationGrades, [arrayMin(1)])
		)
	};
};

export default warn;
