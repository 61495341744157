import * as React from 'react';
import { Select } from 'components/antd';
import { connect } from 'react-redux';
import { AppState } from 'store-types';

import { RetrieveCompaniesContactDetailsRequest } from 'services/api/companies/companiesServiceTypes';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { Entity, LabeledValue } from 'app-types';

import {
	getCompaniesContactDetailsRequestParamsSelector,
	filterContacts,
	getCompaniesContactDetailsSelector
} from 'store/companies/selectors';

import {
	retrieveCompaniesContactDetails,
	resetCompaniesContactDetails
} from 'store/companies/actions/retrieveCompaniesContactDetails';
import { AutocompleteEntityProps } from 'containers/Autocomplete/AutocompleteEntity';
import {
	FormFieldName,
	FORM
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { get } from 'lodash';
import { getValues } from 'store/form/selectors';
import { getFieldAppointerContactDisabled } from '../../page2Selectors';
import { change } from 'redux-form';
import { DEFAULT_AUTOCOMPLETE_PLACEHOLDER } from 'app-constants';
import { getIsJobLinked } from 'store/portJobs/portJobsSelectors';

interface FieldAppointerContactProps extends AutocompleteEntityProps {
	// In future, when Nominator logic is added
	// we also will need to use NOMINATOR_COMPANY in companiesContactDetails in Fields params
	contactDetailsCompany:
		| FormData[FormFieldName.HUB_PRINCIPAL_COMPANY]
		| FormData[FormFieldName.APPOINTER_COMPANY]
		| null;
	name: string;
	showAllChecked?: boolean;
	// from mapStateToProps
	formValues: FormData;
	companiesContactDetails: Array<Entity<string>>;
	contactDetailsRequestParams: RetrieveCompaniesContactDetailsRequest;
	isDisabled: boolean;
	anotherContact: LabeledValue<string> | null;
	isJobLinked: boolean;
	// from mapDispatchToProps
	retrieveCompaniesContactDetails: typeof retrieveCompaniesContactDetails;
	change: typeof change;
	resetCompaniesContactDetails: typeof resetCompaniesContactDetails;
}

class FieldAppointerContact extends React.Component<
	FieldAppointerContactProps,
	{
		isContactDetailsFetched: boolean;
	}
> {
	constructor(props: FieldAppointerContactProps) {
		super(props);
		this.state = {
			isContactDetailsFetched: false
		};
	}

	retrieveContactDetails(props: FieldAppointerContactProps = this.props) {
		const {
			name,
			isDisabled,
			contactDetailsRequestParams,
			companiesContactDetails
		} = props;
		const { isContactDetailsFetched } = this.state;

		if (
			name === FormFieldName.CONTACT_1 &&
			!isDisabled &&
			!isContactDetailsFetched &&
			!companiesContactDetails.length
		) {
			this.props.retrieveCompaniesContactDetails(contactDetailsRequestParams);
			this.setState({ isContactDetailsFetched: true });
		}
	}

	componentDidMount() {
		if (
			this.props.isJobLinked &&
			this.props.formValues.jobTypeId?.key === 'Appointment'
		) {
			this.props.retrieveCompaniesContactDetails({
				companyId: this.props.formValues.appointerCompany?.key,
				includingChildCompanies: true,
				getParentCompany: true
			});
		} else {
			if (
				this.props.formValues.appointerCompany ||
				this.props.formValues.nominator
			) {
				this.retrieveContactDetails(this.props);
			}
		}
	}

	componentDidUpdate(prevProps: FieldAppointerContactProps) {
		// in some cases we need to retrieve contact details
		// just after Appointer field is filled
		// 'disabled' prop marks this case
		if (
			Boolean(this.props.contactDetailsCompany) &&
			get(prevProps, 'contactDetailsCompany.key') !==
				get(this.props, 'contactDetailsCompany.key')
		) {
			this.retrieveContactDetails(this.props);
		}

		if (
			prevProps.companiesContactDetails.length !==
				this.props.companiesContactDetails.length &&
			!this.props.companiesContactDetails.length
		) {
			this.setState({ isContactDetailsFetched: false });
			this.props.change(FORM.portJob, this.props.name, null);
		}

		// check if appointer field has changed
		if (
			prevProps.formValues.appointerCompany !==
				this.props.formValues.appointerCompany ||
			prevProps.formValues.nominator !== this.props.formValues.nominator
		) {
			this.setState({ isContactDetailsFetched: false }, () => {
				if (this.props.showAllChecked) {
					this.props.retrieveCompaniesContactDetails({
						companyId: this.props.formValues.appointerCompany?.key,
						includingChildCompanies: true,
						getParentCompany: true
					});
				} else {
					this.retrieveContactDetails(this.props);
				}
			});
		}
	}

	componentWillUnmount() {
		this.props.resetCompaniesContactDetails();
	}

	filterContacts = (
		input: string,
		option: React.ReactElement<{ children: string }>
	) => {
		const { anotherContact } = this.props;

		return filterContacts(input, option, anotherContact?.key || '');
	};

	render() {
		const {
			companiesContactDetails,
			anotherContact,
			isDisabled,
			required,
			...selectProps
		} = this.props;
		return (
			<Select.ReduxFormItem
				allowClear={!required}
				required={required}
				showSearch
				labelInValue
				optionFilterProp="children"
				filterOption={this.filterContacts}
				placeholder={DEFAULT_AUTOCOMPLETE_PLACEHOLDER}
				{...selectProps}
				disabled={isDisabled}
				enableSameOptionChangeDetection
			>
				{companiesContactDetails.map(option => (
					<Select.Option
						key={option.id}
						disabled={
							anotherContact ? option.id === anotherContact.key : undefined
						}
					>
						{option.name}
					</Select.Option>
				))}
			</Select.ReduxFormItem>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			formValues: getValues<FormData>(state, FORM.portJob),
			companiesContactDetails: getCompaniesContactDetailsSelector(state),
			contactDetailsRequestParams: getCompaniesContactDetailsRequestParamsSelector(
				state
			),
			isDisabled: getFieldAppointerContactDisabled(state),
			isJobLinked: getIsJobLinked(state)
		};
	},
	{
		retrieveCompaniesContactDetails,
		change,
		resetCompaniesContactDetails
	}
)(FieldAppointerContact);
