import {
	RetrieveCompaniesForUserRequest,
	RetrieveCompaniesForUserResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANIES_FOR_USER';
export const retrieveCompaniesForUserAsync = actionCreator.async<
	RetrieveCompaniesForUserRequest,
	RetrieveCompaniesForUserResponse,
	Error
>(ACTION_NAME);
export const retrieveCompaniesForUser = actionCreator<
	RetrieveCompaniesForUserRequest | undefined
>(ACTION_NAME);
