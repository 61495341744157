import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import {
	Company,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';

const getCompaniesForUserMapSelector = (state: AppState) =>
	state.companiesForUser.byId;

/**
 * Retrieve Companies for user
 */
export const getCompaniesForUserSelector = createSelector(
	getCompaniesForUserMapSelector,
	(byId): Company[] => Object.values(byId)
);
export const getCompaniesForUserCount = createSelector(
	getCompaniesForUserSelector,
	companiesForUser => companiesForUser.length
);

const getCompanyFromUserById = (state: AppState, id: string) =>
	state.companiesForUser.byId[id];

export const getIsCompanyFromUserType = createSelector(
	getCompanyFromUserById,
	(_state: AppState, _id: string, type: OrganisationType) => type,
	(company: Company, type: OrganisationType) => {
		return (
			company &&
			!!company.organisationTypes.find(
				role => role.organisationTypeCode === type
			)
		);
	}
);

export const getAllCompaniesForUserFetchStatus = (state: AppState) =>
	state.companiesForUser.fetchStatuses.all;
