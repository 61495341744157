import { ButtonType } from 'components/types';
import {
	User,
	UserStatus,
	UserGetResponseStatusCode
} from 'services/api/users/userServiceTypes';
import {
	OppositeUserStatus,
	OppositeUserStatusText
} from 'store/users/usersConstants';

export const getOppositeUserStatusText = (status: User['status']) =>
	OppositeUserStatusText[status];

export const getNextUserStatus = (
	status: User['status']
): UserGetResponseStatusCode => OppositeUserStatus[status];

export const getButtonType = (status: User['status']): ButtonType => {
	const oppositeStatus = OppositeUserStatus[status];
	if (
		oppositeStatus === UserStatus.DISABLED ||
		oppositeStatus === UserStatus.PERM_LOCKED ||
		oppositeStatus === UserStatus.TEMP_LOCKED
	) {
		return 'danger';
	}
	return 'primary';
};
