import { ThreadReducerState } from '../threadState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveMessageDataRequest } from 'services/api/threads/threadsServiceTypes';

export const onRetrieveMessageData = makeFetchStatusReducers<
	ThreadReducerState,
	'fetchStatuses',
	RetrieveMessageDataRequest,
	null
>('fetchStatuses', 'retrieveMessageData');
