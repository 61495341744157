import React from 'react';

import { isBoolean, omitBy, isArray, identity } from 'lodash';

import { Form } from 'components/antd';
import { FormItemProps } from '../FormItem';

export type FieldComponentProps = FormItemProps;
function createFieldComponent<T>(
	WrappedComponent: React.ComponentType<T>,
	mapProps: (
		props: Readonly<T & FieldComponentProps>
	) => Partial<T & FieldComponentProps> = identity
) {
	class FieldComponent extends React.Component<T & FieldComponentProps> {
		getMappedAndSortedProps = (): {
			formItemProps: FormItemProps;
			wrappedComponentProps: T;
		} => {
			const props: Partial<FieldComponentProps> = mapProps(this.props);

			const formItemProps = {
				label: props.label,
				labelCol: props.labelCol,
				wrapperCol: props.wrapperCol,
				help: isArray(props.help) ? props.help.join('. ') : props.help,
				extra: props.extra,
				validateStatus: props.validateStatus,
				hasFeedback: isBoolean(props.hasFeedback) ? props.hasFeedback : false,
				colon: props.colon,
				required: props.required,
				marginBottom: props.marginBottom
			};

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const formItemPropsKeys: string[] = Object.keys(formItemProps) as any;

			return {
				formItemProps,
				wrappedComponentProps: omitBy(props, (_, key) =>
					formItemPropsKeys.includes(key)
				) as T
			};
		};

		render() {
			const {
				formItemProps,
				wrappedComponentProps
			} = this.getMappedAndSortedProps();
			return (
				<Form.Item {...formItemProps}>
					<WrappedComponent {...wrappedComponentProps} />
				</Form.Item>
			);
		}
	}
	return FieldComponent;
}

export default createFieldComponent;
