import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateGroupFormData } from '../GroupsModalsTypes';

import { addGroup } from 'store/groups';
import { getGroupAddingFetchStatus } from 'store/groups/groupsSelectors';
import { getGroupTypeCodeByUserCompanyOrganisationType } from './createGroupModalSelectors';

interface UseModalsGroupsProps {
	form: string;
}
export function useCreateGroupModal({ form }: UseModalsGroupsProps) {
	const dispatch = useDispatch();
	const groupTypeCode = useSelector(
		getGroupTypeCodeByUserCompanyOrganisationType
	);
	const fetchStatus = useSelector(getGroupAddingFetchStatus);

	const onSubmit = useCallback(
		(values: CreateGroupFormData) => {
			const requestParams = {
				...values,
				groupType: {
					code: groupTypeCode
				}
			};
			dispatch(addGroup({ form, requestParams }));
		},
		[dispatch, form, groupTypeCode]
	);

	return {
		fetchStatus,
		onSubmit
	};
}
