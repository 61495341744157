import numeral from 'numeral';
import { AmountProps, FormatType } from './Amount';

const FORMATS = {
	default: '0,0.00',
	rounding: '0,0.[000000]',
	finance: ''
};

const getCustomFormat = (decimalPart: number, signed: boolean) => {
	return `${signed ? '+' : ''}0,0.${'0'.repeat(decimalPart)}`;
};

export const getFormattedValue = (
	value: AmountProps['value'],
	formatType: FormatType = 'default',
	decimalPart = 0,
	signed = false
) => {
	let format = FORMATS[formatType];
	if (formatType === 'finance') {
		format = getCustomFormat(decimalPart, signed);
	}
	return numeral(value).format(format);
};
