import {
	updateShipMeasurementsAsync as async,
	retrievePortCallShipMeasurements
} from '../actions';
import Api from 'services/api';
import { UpdateShipMeasurementsRequest } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { select, put } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';

export default makeTakeLatestWatcher<
	UpdateShipMeasurementsRequest,
	null,
	Error
>({
	api: Api.PortCallMeasurements.updateShipMeasurements,
	async,
	onSuccess: function* onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		yield put(retrievePortCallShipMeasurements({ portCallId }));
	}
});
