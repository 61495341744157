import React from 'react';
import { TextArea } from 'components/antd';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';

export enum GenericFormFieldName {
	DETAILS = 'details'
}

const Generic = () => (
	<TextArea.ReduxFormItem
		name={GenericFormFieldName.DETAILS}
		required
		label="Operation Details"
		rows={4}
		maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
	/>
);

export default Generic;
