import Api from 'services/api';
import { select, call, put, delay } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { updateFundingRequestAsync as async } from '../actions/updateFundingRequest';
import {
	UpdateFundingRequestRequest as Request,
	UpdateFundingRequestResponse as Response,
	FundingRequestMetadataActionCode
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getFundingUpdate } from 'store/form/financeFundings/selectors/financeFundingFormSelectors';
import { navigateTo } from 'utils';
import { notify } from 'store/notifications/actions';
import { Success } from 'typescript-fsa';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';

const api = Api.FinanceFundings.updateFundingRequest;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*getApiParams(params: {
		fundingId?: string;
		action: FundingRequestMetadataActionCode;
	}) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const funding = yield select(getFundingUpdate);
		const request: Request = {
			portCallId,
			jobCode,
			funding,
			...params
		};
		return request;
	},
	*onSuccess({ params, result }: Success<Request, Response>) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const funding = yield select(getFundingUpdate);
		yield call(navigateTo, `/portcalls/${portCallId}/jobs/${jobCode}/finance`);
		const message =
			params.action === FundingRequestMetadataActionCode.REJECT
				? `What's next: you are done! Create a new funding request manually if funding is required in the future`
				: result.arrivalDateExceedsThreshold && !funding?.isUrgent
				? `What's next: the request will be sent automatically 5 days before vessel arrival`
				: `What's next: the request has been sent for payment `;
		// Without it the first notification triggered by default notificationHandler is overlapped - IPP-33997
		yield delay(DEFAULT_DEBOUNCE_DELAY);
		yield put(notify.success(message));
	}
});
