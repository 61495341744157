// import { Success } from 'typescript-fsa';
import { DirectBillsState } from '../directBillsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	UpdateDirectBillRequest,
	UpdateDirectBillResponse
} from 'services/api/directBills/directBillsServiceTypes';

export const onUpdateDirectBill = makeFetchStatusReducers<
	DirectBillsState,
	'fetchStatuses',
	UpdateDirectBillRequest,
	UpdateDirectBillResponse
>('fetchStatuses', 'update');
