import { PortCallState } from 'store/portCall/portCallState';

export const onSetEditableTotalAmountId = (
	state: PortCallState,
	editableTotalAmountId: string | undefined
): PortCallState => ({
	...state,
	context: {
		...state.context,
		editableTotalAmountId
	}
});
