import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';

export enum ThreadModalName {
	ASSIGN_GROUP = 'assign'
}

export const THREAD_GROUP_ASSIGNMENT_FORM_ID = 'assignGroupForm';

export enum ThreadGroupAssignmentFormFields {
	ASSIGNED_GROUP_ID = 'assignedGroupId',
	ASSIGNED_USER_ID = 'assignedUserId',
	THREAD_PERMISSION_GROUPS = 'threadPermissionGroups',
	CURRENT_GROUP_ID_TO_ADD = 'currentGroupIdToAdd'
}

export interface ThreadGroupAssignmentFormData {
	[ThreadGroupAssignmentFormFields.ASSIGNED_GROUP_ID]?: string;
	[ThreadGroupAssignmentFormFields.ASSIGNED_USER_ID]?: string;
	[ThreadGroupAssignmentFormFields.THREAD_PERMISSION_GROUPS]: PermissionGroupWithUsers[];
	[ThreadGroupAssignmentFormFields.CURRENT_GROUP_ID_TO_ADD]?: string;
}
