import React from 'react';

import { PortCallsBaseProps } from 'sections/PortCalls';

interface PortCallsContainerProps {
	match: PortCallsBaseProps['match'];
}

const PortCallsContainer: React.SFC<PortCallsContainerProps> = ({
	children
}) => <>{children}</>;

export default PortCallsContainer;
