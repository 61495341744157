import actionCreator from '../portJobsActionCreator';
import {
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

const ACTION_NAME = 'RETRIEVE_MAILBOXES_FOR_PRINCIPAL';

export const retrieveMailboxesForPrincipalAsync = actionCreator.async<
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse,
	Error
>(ACTION_NAME);

export const retrieveMailboxesForPrincipal = actionCreator<
	RetrieveMailboxesForPrincipalRequest
>(ACTION_NAME);
