import React from 'react';
import { get, initial } from 'lodash';
import jsPDF from 'jspdf';
import {
	Layer,
	Line,
	Rect,
	Stage,
	Image as CustomImage,
	Text
} from 'react-konva';
import { Pan, Zoom } from './components';
import {
	DrawingMode,
	DrawingAction,
	TextNode,
	LineNode,
	RectNode
} from './ImageViewerTypes';
import { Stage as KonvaStage } from 'konva/types/Stage';
import EditToolbar from './components/EditToolbar/EditToolbar';
import TextInsertPopover from './components/EditToolbar/TextInsertPopover';

import toolbarStyles from './components/EditToolbar/EditToolbar.module.scss';
import { getScaledPoint, getEmptyRect } from './ImageViewer.func';
import styles from './ImageViewer.module.scss';

interface ImageDimensions {
	width: number;
	height: number;
}

interface ImageData {
	image: HTMLImageElement;
	width: number;
	height: number;
}

interface HistoryItem {
	type: DrawingMode;
	action: DrawingAction;
	id: string;
}

interface ImageViewerProps {
	imageUrl: string;
	fileName?: string;
	isAnnotatable?: boolean;
}

interface ImageViewerState {
	mode: DrawingMode;
	scale: number;
	dimensions: ImageDimensions;
	color: string;
	textPopover: TextNode | null;
	imageData: ImageData | null;
	isPainting: boolean;
	linePoints: number[];
	lines: LineNode[];
	rect: RectNode;
	rects: RectNode[];
	history: HistoryItem[];
	texts: TextNode[];
}

const initialViewerState = {
	scale: 1,
	dimensions: { width: 1, height: 1 },
	mode: DrawingMode.LINE,
	imageData: null,
	color: toolbarStyles['--blueColor'],
	textPopover: null,
	isPainting: false,
	linePoints: [],
	rect: getEmptyRect(toolbarStyles['--blueColor']),
	texts: [],
	lines: [],
	rects: [],
	history: []
};

class ImageViewer extends React.PureComponent<
	ImageViewerProps,
	ImageViewerState
> {
	stage: KonvaStage;
	viewerRef: HTMLDivElement;

	constructor(props: ImageViewerProps) {
		super(props);

		this.state = initialViewerState;
	}

	componentDidMount() {
		const newImage = new Image();
		newImage.crossOrigin = 'Anonymous';
		newImage.addEventListener('load', this.onLoad);
		newImage.src = `${
			this.props.imageUrl
		}&X-Amz-Date=${new Date().toISOString()}`;
	}

	componentDidUpdate(prevProps: ImageViewerProps) {
		if (this.props.imageUrl !== prevProps.imageUrl) {
			this.setState(initialViewerState, () => {
				const newImage = new Image();
				newImage.crossOrigin = 'Anonymous';
				newImage.addEventListener('load', this.onLoad);
				newImage.src = `${
					this.props.imageUrl
				}&X-Amz-Date=${new Date().toISOString()}`;
			});
		}
	}

	componentWillUnmount() {
		const { imageData } = this.state;
		if (imageData?.image) {
			imageData.image.removeEventListener('load', this.onLoad);
		}
		window.removeEventListener('mouseup', this.resetDrawing);
	}

	// eslint-disable-next-line
	onLoad = (event: any) => {
		const image = event.currentTarget;
		const { scale } = this.state;
		let width = get(this.viewerRef, 'clientWidth', 0);
		let height = get(this.viewerRef, 'clientHeight', 0);
		let calculatedScale = scale;
		if (image) {
			calculatedScale = width / image.width;
			width = image.width * calculatedScale;
			height = image.height * calculatedScale;
		}
		this.setState({
			imageData: { image, width: image.width, height: image.height },
			dimensions: { width, height }
		});
	};

	setViewerRef = (ref: HTMLDivElement) => {
		if (ref) {
			this.viewerRef = ref;
		}
	};

	// eslint-disable-next-line
	setStageRef = (ref: any) => {
		if (ref) {
			this.stage = ref;
		}
	};

	onChange = (scale: number) => {
		this.setState({ scale, textPopover: null });
	};

	colorChange = (color: string) => {
		this.setState({ color: toolbarStyles[`--${color}Color`] });
	};

	setMode = (mode: DrawingMode) => {
		this.setState({
			mode,
			rect: getEmptyRect(this.state.color),
			linePoints: [],
			textPopover: null,
			isPainting: false
		});
	};

	onCloseTextPopover = () => {
		this.setState({
			textPopover: null,
			isPainting: false
		});
	};

	onMouseDown = () => {
		const { scale, mode, linePoints, color } = this.state;
		this.setState({ isPainting: true });
		const { x, y } = getScaledPoint(this.stage, scale);
		switch (mode) {
			case DrawingMode.TEXT:
				this.setState({
					textPopover: { x, y, text: '', color }
				});
				break;
			case DrawingMode.LINE:
				this.setState({
					linePoints: [...linePoints, x, y]
				});
				break;
			case DrawingMode.RECT:
				this.setState({ rect: { x, y, width: 1, height: 1, color } });
				break;
			default:
		}
		window.addEventListener('mouseup', this.resetDrawing);
	};

	resetDrawing = () => {
		if (!this.state.isPainting) {
			return;
		}
		this.onMouseUp();
	};

	onMouseMove = () => {
		const { isPainting, scale, linePoints, rect, mode, color } = this.state;
		if (!isPainting || !this.stage) {
			return;
		}
		const { x, y } = getScaledPoint(this.stage, scale);
		switch (mode) {
			case DrawingMode.LINE:
				this.setState({
					linePoints: [...linePoints, x, y]
				});
				break;
			case DrawingMode.RECT:
				this.setState({
					rect: {
						x: rect.x,
						y: rect.y,
						width: x - rect.x,
						height: y - rect.y,
						color
					}
				});
				break;
			default:
		}
	};

	onMouseUp = () => {
		if (!this.stage) {
			return;
		}

		const {
			history,
			linePoints,
			rect,
			lines,
			rects,
			color,
			scale,
			mode
		} = this.state;
		const { x, y } = getScaledPoint(this.stage, scale);
		switch (mode) {
			case DrawingMode.LINE:
				this.setState({
					lines: [
						...lines,
						{
							points: [...linePoints, x, y],
							color
						}
					],
					linePoints: []
				});
				break;
			case DrawingMode.RECT:
				this.setState({
					rects: [
						...rects,
						{
							...rect,
							width: x - rect.x,
							height: y - rect.y
						}
					],
					rect: getEmptyRect(color)
				});
				break;
			default:
		}
		this.setState({
			isPainting: false,
			history: [
				...history,
				{ type: mode, action: DrawingAction.ADD, id: 'null' }
			]
		});
	};

	onSaveText = (text: string) => {
		const { history, texts, textPopover } = this.state;
		if (textPopover) {
			this.setState({
				texts: [...texts, { ...textPopover, text }],
				textPopover: null,
				isPainting: false,
				history: [
					...history,
					{ type: DrawingMode.TEXT, action: DrawingAction.ADD, id: 'null' }
				]
			});
		}
	};

	onSave = () => {
		// Reset textpopover from canvas
		this.setState({
			textPopover: null
		});
		// We have to use setTimeout here in order to unblock main thread and allow to hide textPopup
		setTimeout(() => {
			const pdf = new jsPDF();
			const hiddenLayer = this.stage.getLayers()[0].clone();
			hiddenLayer
				.x(0)
				.y(0)
				.size({ ...this.state.dimensions });
			pdf.addImage(
				hiddenLayer.toDataURL(),
				'PNG',
				1,
				1,
				210,
				297,
				undefined,
				'FAST'
			);
			pdf.save(`${this.props.fileName}.annotated.pdf`);
		});
	};

	onUndo = () => {
		const { history, rects, texts, lines } = this.state;
		if (!history.length) {
			return;
		}
		const lastItem = history[history.length - 1];
		switch (lastItem.type) {
			case DrawingMode.RECT:
				this.setState({ rects: initial(rects) });
				break;
			case DrawingMode.TEXT:
				this.setState({
					texts: initial(texts)
				});
				break;
			default:
				this.setState({
					lines: initial(lines)
				});
		}
		this.setState({
			history: initial(history)
		});
	};

	render() {
		const { isAnnotatable = false } = this.props;
		const {
			textPopover,
			dimensions,
			imageData,
			color,
			mode,
			scale,
			linePoints,
			rect,
			lines,
			rects,
			texts
		} = this.state;

		return (
			<div className={styles.root} ref={this.setViewerRef}>
				{isAnnotatable ? (
					<EditToolbar
						mode={mode}
						color={color}
						scale={scale}
						onChangeDrawingMode={this.setMode}
						onColorChange={this.colorChange}
						onSave={this.onSave}
						onUndo={this.onUndo}
						onScaleChange={this.onChange}
					/>
				) : (
					<Zoom type="default" defaultValue={scale} onChange={this.onChange} />
				)}
				{!isAnnotatable ? (
					<Pan>
						<div
							className={styles.content}
							style={{ transform: `scale(${scale})` }}
						>
							{imageData && this.props.children}
						</div>
					</Pan>
				) : (
					<div className={styles.scrollable}>
						<div
							className={styles.konvaContainer}
							style={{
								width: `${dimensions.width * scale}px`,
								height: `${dimensions.height * scale}px`
							}}
						>
							<Stage
								ref={this.setStageRef}
								width={dimensions.width * scale}
								height={dimensions.height * scale}
								onMouseDown={this.onMouseDown}
								onMouseMove={this.onMouseMove}
								onMouseUp={this.onMouseUp}
							>
								<Layer>
									{imageData && (
										<CustomImage
											x={0}
											y={0}
											width={dimensions.width * scale}
											height={dimensions.height * scale}
											image={imageData.image}
										/>
									)}
									{lines.map((line, index) => (
										<Line
											key={index}
											stroke={line.color}
											scale={{ x: scale, y: scale }}
											strokeWidth={5}
											points={line.points}
										/>
									))}
									<Line
										stroke={color}
										scale={{ x: scale, y: scale }}
										strokeWidth={5}
										points={linePoints}
									/>
									{rect && (
										<Rect
											x={rect.x * scale}
											y={rect.y * scale}
											width={rect.width * scale}
											height={rect.height * scale}
											stroke={color}
											strokeWidth={5}
										/>
									)}
									{rects.map((rect, index) => (
										<Rect
											key={index}
											x={rect.x * scale}
											y={rect.y * scale}
											width={rect.width * scale}
											height={rect.height * scale}
											stroke={rect.color}
											strokeWidth={5}
										/>
									))}
									{texts?.map((textNode, index) => (
										<Text
											key={index}
											x={textNode.x * scale}
											y={textNode.y * scale}
											text={textNode.text}
											fill={textNode.color}
											width={300}
											fontSize={46}
											strokeWidth={1}
											fontStyle="italic"
											scale={{ x: scale, y: scale }}
										/>
									))}
								</Layer>
							</Stage>
							{textPopover && (
								<TextInsertPopover
									{...textPopover}
									x={textPopover.x * scale}
									y={textPopover.y * scale}
									onSaveText={this.onSaveText}
									onClose={this.onCloseTextPopover}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default ImageViewer;
