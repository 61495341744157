import React from 'react';
import { Row, Col, Select, InputNumber } from 'components/antd';
import { GradeLineFormBaseProps } from './GradeLineForm';
import GradeLineFormFieldName from './GradeLineFormFieldNames';
import {
	DEFAULT_INPUTNUMBER_MAX_DIGITS,
	DEFAULT_SELECT_PLACEHOLDER
} from 'app-constants';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import { onUOMSearch } from 'containers/Autocomplete/AutocompleteEntity.func';

const { GRADE, NOMINAL_QUANTITY, UNIT_OF_MEASUREMENT } = GradeLineFormFieldName;

const GradeLineFormFields = (
	props: Pick<GradeLineFormBaseProps, 'units' | 'grades'>
) => {
	return (
		<Row>
			<Col sm={4}>
				<Select.ReduxFormItem
					name={GRADE}
					label="Grade"
					placeholder={DEFAULT_SELECT_PLACEHOLDER}
					labelInValue
					required
					getPopupContainer={() => document.body}
				>
					{props.grades.map(grade => (
						<Select.Option key={grade.id}>{grade.name}</Select.Option>
					))}
				</Select.ReduxFormItem>
			</Col>
			<Col sm={4}>
				<InputNumber.ReduxFormItem
					name={NOMINAL_QUANTITY}
					label="Nomination Qty"
					maxDigits={DEFAULT_INPUTNUMBER_MAX_DIGITS}
					decimalPart={4}
					required
					help
				/>
			</Col>
			<Col sm={4}>
				<AutocompleteEntity
					name={UNIT_OF_MEASUREMENT}
					label="UOM"
					getPopupContainer={() => document.body}
					onSearch={onUOMSearch}
					shouldSearchAllOptionsOnFocus
					minLength={1}
					required
				/>
			</Col>
		</Row>
	);
};

export default GradeLineFormFields;
