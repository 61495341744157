import { CommoditiesState } from '../commoditiesState';
import {
	Commodity,
	RetrieveCommoditiesRequest
} from 'services/api/commodities/commoditiesServiceTypes';
import { uniq } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveCommoditiesStarted(
	state: CommoditiesState
): CommoditiesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.PENDING
		}
	};
}
export function onRetrieveCommoditiesSuccess(
	state: CommoditiesState,
	action: {
		result: Commodity[];
		params: RetrieveCommoditiesRequest;
	}
): CommoditiesState {
	const {
		result,
		params: { limit }
	} = action;
	const byId = {};
	const allIds: string[] = [];
	result.forEach((commodity: Commodity) => {
		byId[commodity.id] = commodity;
		allIds.push(commodity.id);
	});
	return {
		...state,
		byId,
		allIds: uniq(allIds),
		hasMore: limit === result.length,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveCommoditiesFailed(
	state: CommoditiesState
): CommoditiesState {
	return {
		...state,
		hasMore: false,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		}
	};
}
