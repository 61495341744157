import {
	GroupScopePermissions,
	RetrieveGroupScopePermissionsRequest
} from 'services/api/groups/groupsServiceTypes';
import actionCreator from 'store/groups/groupsActionCreator';
import { ResultCollection } from 'services/api/apiTypes';

const RETRIEVE_GROUP_PERMISSION = 'RETRIEVE_GROUP_PERMISSION';
export const retrieveGroupScopePermissionsAsync = actionCreator.async<
	RetrieveGroupScopePermissionsRequest,
	ResultCollection<GroupScopePermissions>,
	Error
>(RETRIEVE_GROUP_PERMISSION);
export const retrieveGroupScopePermissions = actionCreator<
	RetrieveGroupScopePermissionsRequest
>(RETRIEVE_GROUP_PERMISSION);
