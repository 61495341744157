import Api from 'services/api';
import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrievePrefundingRequestAsync as async } from '../actions/retrievePreFundingRequest';
import {
	RetrievePrefundingRequest as Request,
	RetrievePrefundingRequestResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.FinanceFundings.retrievePrefundingRequest;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*getApiParams(params: Request) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		return {
			...params,
			portCallId,
			jobCode
		};
	}
});
