export const NO_PERMISSION_MESSAGE = `You don't have permission to perform this action`;

export enum PortJobSettingsMenuAction {
	DELETE = 'delete-portjob',
	UPDATE = 'update-portjob',
	CANCEL = 'cancel-portjob',
	CHANGE_CUSTODY_ROLE = 'change-custody-role',
	CHANGE_JOB_STATUS_WAIT = 'change-job-status-wait',
	CHANGE_JOB_STATUS_DONTWAIT = 'change-job-status-dontwait',
	DISABLE_OPERATIONS = 'disable-operations',
	ENABLE_OPERATIONS = 'enable-operations',
	MOVE_TO_PDA_PENDING = 'move-to-pda-pending',
	MOVE_TO_CE_PENDING = 'move-to-ce-pending',
	UNLINK_JOBS = 'unlink-jobs',
	SEND_CANCEL_EMAIL = 'send-cancel-email',
	CLONE_JOB = 'clone-job',
	RE_OPEN_JOB = 're-open-job',
	CREATE_APPOINTMENT_FROM_NOMINATION = 'create-appointment-from-nomination'
}

export const PortJobSettingsMenuModalMap = {
	[PortJobSettingsMenuAction.DELETE]: 'delete-portjob-modal',
	[PortJobSettingsMenuAction.CANCEL]: 'cancel-portjob-modal',
	[PortJobSettingsMenuAction.CHANGE_CUSTODY_ROLE]: 'change-custody-role-modal',
	[PortJobSettingsMenuAction.UNLINK_JOBS]: 'unlink-jobs-modal',
	[PortJobSettingsMenuAction.SEND_CANCEL_EMAIL]: 'send-cancel-email-modal',
	[PortJobSettingsMenuAction.CLONE_JOB]: 'clone-portjob-modal',
	[PortJobSettingsMenuAction.ENABLE_OPERATIONS]: 'enable-operations-modal'
};
