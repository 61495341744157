import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Drawer,
	Icon,
	Form,
	Collapse,
	Autocomplete,
	Menu,
	Select,
	Button
} from 'components/antd';
import { Title, Text } from 'components';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	PortCallsFiltersParamEnum,
	PORT_CALLS_FILTER_ASSIGNED_TO_ID,
	AssignedToUserFilter,
	AssignedToUserKeyMap,
	PORT_CALLS_FILTER_CHARTERER_ID,
	PORT_CALLS_FILTER_CONTACT_ID,
	PORT_CALLS_FILTER_AGENT_TYPE_ID,
	PortCallsFiltersParam,
	PORT_CALLS_FILTER_OPERATION_ID
} from 'store/portcalls/filtersSync';
import {
	advanceFilterTypeMap,
	advanceFilterJobTypeMap,
	PortJobType,
	advanceFilterJobCategoryTypeMap,
	PortJobCategory,
	advanceFilterAgentTypeMap,
	AgentType
} from '../PortCallsFiltersConstants';
import { FilterItem } from 'store/filters/filtersState';
import styles from './../PortCallsFilters.module.scss';
import Api from 'services/api';
import { searchEntitiesForAutocomplete } from 'containers/Autocomplete/Autocomplete.func';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { AppState } from 'store-types';
import { getUserType } from 'store/auth/selectors';
import { getPortJobAgencyTypes } from 'store/portJobs/selectors';
import { StaticItem } from 'store/portcalls/portCallsState';
import DateFilter from './DateFilter';
import { forIn } from 'lodash';

interface AdvancedFiltersProps {
	values: PortCallsFiltersParam;
	onFilterSelect: (
		filter: FilterItem,
		name: string,
		override?: boolean
	) => void;
	//fromMapStateToProps
	userType: UserType;
	agentTypes: StaticItem[];
}

interface AdvancedFiltersState {
	visible: boolean;
}

class AdvanceFilters extends Component<
	AdvancedFiltersProps,
	AdvancedFiltersState
> {
	constructor(props: AdvancedFiltersProps) {
		super(props);
		this.state = {
			visible: false
		};
	}
	showDrawer = () => {
		this.setState({
			visible: true
		});
	};

	onClose = () => {
		this.setState({
			visible: false
		});
	};

	onChange = (name: string) => (filter: FilterItem) => {
		this.props.onFilterSelect({ ...filter, type: name }, name);
		this.setState({
			visible: false
		});
	};

	onAssignedToChange = (filter: FilterItem) => {
		const { values } = this.props;
		const isAlreadySelected =
			values.daAssignedTo &&
			values.daAssignedTo.find(status => status.key === filter.key);
		if (!isAlreadySelected) {
			if (
				filter.key === 'me' ||
				filter.key === 'unassigned' ||
				filter.key === 'inactive'
			) {
				this.props.onFilterSelect(
					{
						...filter,
						label: AssignedToUserKeyMap[filter.key],
						type: PortCallsFiltersParamEnum.ASSIGNED_TO
					},
					PortCallsFiltersParamEnum.ASSIGNED_TO,
					false
				);
			} else {
				this.props.onFilterSelect(
					{ ...filter, type: PortCallsFiltersParamEnum.ASSIGNED_TO_ID },
					PortCallsFiltersParamEnum.ASSIGNED_TO_ID,
					false
				);
			}
		}
		this.onClose();
	};

	onMenuClick = (filter: FilterItem) => {
		this.props.onFilterSelect(
			{ ...filter, type: filter.type },
			filter.type,
			true
		);
		this.onClose();
	};

	onAssignedToSearch = (searchTerm?: string) =>
		searchEntitiesForAutocomplete(
			Api.Companies.searchAssignedToForAutocomplete,
			searchTerm
		);

	onMainContactSearch = (searchTerm?: string) =>
		Api.Companies.searchMainContactForAutoComplete(searchTerm);

	onOperationsSearch = (searchTerm: string) =>
		searchEntitiesForAutocomplete(() =>
			Api.Operations.retrieveOperationsForAutocomplete({ searchTerm })
		);

	searchCharterers = (searchTerm: string) =>
		Api.Companies.searchCompaniesForAutocomplete({
			types: [OrganisationType.CHARTERER],
			searchTerm
		});

	getActiveKeys = (values: PortCallsFiltersParam) => {
		const activeKeys: string[] = [];
		forIn(values, (value, key) => {
			if (
				(value?.[0] && value[0].key) ||
				(!Array.isArray(value) && value?.key)
			) {
				activeKeys.push(key);
			}
		});
		return activeKeys;
	};

	render() {
		const { values, agentTypes } = this.props;
		return (
			<>
				<Icon
					type="advanced-filter"
					onClick={this.showDrawer}
					size={26}
					offset="left"
					color="primary"
				/>
				<Drawer
					closable={false}
					visible={this.state.visible}
					onClose={this.onClose}
					destroyOnClose
				>
					<Title>
						Advanced Filter
						<Icon
							className={styles.iconButton}
							type="close"
							onClick={this.onClose}
						></Icon>
					</Title>
					<Form.Item style={{ marginBottom: 14 }}></Form.Item>
					<Collapse type="filter" defaultActiveKey={this.getActiveKeys(values)}>
						{this.props.userType !== UserType.LPA && (
							<Collapse.Panel
								key={PortCallsFiltersParamEnum.ASSIGNED_TO}
								header={
									advanceFilterTypeMap[PortCallsFiltersParamEnum.ASSIGNED_TO]
								}
							>
								<Autocomplete
									isDefaultOptionsEnabled
									onSearch={this.onAssignedToSearch}
									value={undefined}
									allowClear
									onChange={this.onAssignedToChange}
									placeholder="Type to search users…"
									id={PORT_CALLS_FILTER_ASSIGNED_TO_ID}
									getPopupContainer={() =>
										document.getElementById(PORT_CALLS_FILTER_ASSIGNED_TO_ID) ||
										document.body
									}
									defaultOptions={[
										{
											id: AssignedToUserFilter.ME,
											name: AssignedToUserKeyMap[AssignedToUserFilter.ME]
										},
										{
											id: AssignedToUserFilter.UNASSIGNED,
											name:
												AssignedToUserKeyMap[AssignedToUserFilter.UNASSIGNED]
										}
									]}
									getCustomOption={option =>
										option.id === AssignedToUserFilter.ME ||
										option.id === AssignedToUserFilter.UNASSIGNED ? (
											<Text color="primary" weight="semi-bold">
												{option.name}
											</Text>
										) : (
											option.name
										)
									}
								/>
							</Collapse.Panel>
						)}
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.PORT_JOB_TYPE}
							header={
								advanceFilterTypeMap[PortCallsFiltersParamEnum.PORT_JOB_TYPE]
							}
						>
							<Menu
								key={PortCallsFiltersParamEnum.PORT_JOB_TYPE}
								className={styles.menu}
								selectedKeys={values.portJobType && [values.portJobType.label]}
								onClick={e =>
									this.onMenuClick({
										key: advanceFilterJobTypeMap[e.key],
										label: e.key,
										type: PortCallsFiltersParamEnum.PORT_JOB_TYPE
									})
								}
							>
								<Menu.Item key={PortJobType.APPOINTMENT}>
									{PortJobType.APPOINTMENT}
								</Menu.Item>
								<Menu.Item key={PortJobType.NOMINATION}>
									{PortJobType.NOMINATION}
								</Menu.Item>
								<Menu.Item key={PortJobType.CHARTERER_OVERSIGHT}>
									{PortJobType.CHARTERER_OVERSIGHT}
								</Menu.Item>
								<Menu.Item key={PortJobType.APPOINTMENT_FROM_NOMINATION}>
									{PortJobType.APPOINTMENT_FROM_NOMINATION}
								</Menu.Item>
							</Menu>
						</Collapse.Panel>
						{this.props.userType === UserType.HUB && (
							<Collapse.Panel
								key={PortCallsFiltersParamEnum.PORT_JOB_CATEGORY}
								header={
									advanceFilterTypeMap[
										PortCallsFiltersParamEnum.PORT_JOB_CATEGORY
									]
								}
							>
								<Menu
									key={PortCallsFiltersParamEnum.PORT_JOB_CATEGORY}
									className={styles.menu}
									selectedKeys={
										values.portJobCategory && [values.portJobCategory.label]
									}
									onClick={e =>
										this.onMenuClick({
											key: advanceFilterJobCategoryTypeMap[e.key],
											label: e.key,
											type: PortCallsFiltersParamEnum.PORT_JOB_CATEGORY
										})
									}
								>
									<Menu.Item key={PortJobCategory.CLS}>
										{PortJobCategory.CLS}
									</Menu.Item>
									<Menu.Item key={PortJobCategory.COMMERCIAL}>
										{PortJobCategory.COMMERCIAL}
									</Menu.Item>
								</Menu>
							</Collapse.Panel>
						)}
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.CHARTERER}
							header={advanceFilterTypeMap[PortCallsFiltersParamEnum.CHARTERER]}
						>
							<Autocomplete
								onSearch={this.searchCharterers}
								value={values.chartererCompanyId}
								onSelect={this.onChange(PortCallsFiltersParamEnum.CHARTERER)}
								placeholder="Type to search charterer…"
								// getCustomOption={option => option.name}
								id={PORT_CALLS_FILTER_CHARTERER_ID}
								getPopupContainer={() =>
									document.getElementById(PORT_CALLS_FILTER_CHARTERER_ID) ||
									document.body
								}
							/>
						</Collapse.Panel>
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.DATE}
							header={advanceFilterTypeMap[PortCallsFiltersParamEnum.DATE]}
						>
							<DateFilter date={values.dateType} onChange={this.onChange} />
						</Collapse.Panel>
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.OPERATION}
							header={advanceFilterTypeMap[PortCallsFiltersParamEnum.OPERATION]}
						>
							<Autocomplete
								onSearch={this.onOperationsSearch}
								value={values.operation}
								onSelect={this.onChange(PortCallsFiltersParamEnum.OPERATION)}
								placeholder="Type to search operation…"
								id={PORT_CALLS_FILTER_OPERATION_ID}
								getPopupContainer={() =>
									document.getElementById(PORT_CALLS_FILTER_OPERATION_ID) ||
									document.body
								}
							/>
						</Collapse.Panel>
						{this.props.userType !== UserType.LPA && (
							<Collapse.Panel
								key={PortCallsFiltersParamEnum.MAIN_CONTACT}
								header={
									advanceFilterTypeMap[PortCallsFiltersParamEnum.MAIN_CONTACT]
								}
							>
								<Autocomplete
									onSearch={this.onMainContactSearch}
									value={values.customerContact1Id}
									onSelect={this.onChange(
										PortCallsFiltersParamEnum.MAIN_CONTACT
									)}
									placeholder="Type to search contact…"
									id={PORT_CALLS_FILTER_CONTACT_ID}
									getPopupContainer={() =>
										document.getElementById(PORT_CALLS_FILTER_CONTACT_ID) ||
										document.body
									}
								/>
							</Collapse.Panel>
						)}
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.AGENT_TYPE}
							header={
								advanceFilterTypeMap[PortCallsFiltersParamEnum.AGENT_TYPE]
							}
						>
							<Autocomplete
								id={PORT_CALLS_FILTER_AGENT_TYPE_ID}
								onSelect={this.onChange(PortCallsFiltersParamEnum.AGENT_TYPE)}
								value={values.agentType}
								placeholder="Type to search agent type…"
								localSearch
								showSearch
								labelInValue
								filterOption
								optionFilterProp="children"
								getPopupContainer={() =>
									document.getElementById(PORT_CALLS_FILTER_AGENT_TYPE_ID) ||
									document.body
								}
							>
								{agentTypes?.map(status => (
									<Select.Option key={status.code} title={status.name}>
										{status.name}
									</Select.Option>
								))}
							</Autocomplete>
						</Collapse.Panel>
						<Collapse.Panel
							key={PortCallsFiltersParamEnum.AGENT_ROLE}
							header={
								advanceFilterTypeMap[PortCallsFiltersParamEnum.AGENT_ROLE]
							}
						>
							<Menu
								key={PortCallsFiltersParamEnum.AGENT_ROLE}
								selectedKeys={values.agentRole && [values.agentRole.label]}
								onClick={e =>
									this.onMenuClick({
										key: advanceFilterAgentTypeMap[e.key],
										label: e.key,
										type: PortCallsFiltersParamEnum.AGENT_ROLE
									})
								}
								className={styles.menu}
							>
								<Menu.Item key={AgentType.FULL}>{AgentType.FULL}</Menu.Item>
								<Menu.Item key={AgentType.INBOUND}>
									{AgentType.INBOUND}
								</Menu.Item>
								<Menu.Item key={AgentType.OUTBOUND}>
									{AgentType.OUTBOUND}
								</Menu.Item>
								<Menu.Item key={AgentType.HUSBANDRY}>
									{AgentType.HUSBANDRY}
								</Menu.Item>
							</Menu>
						</Collapse.Panel>
						<Button
							className={styles.filterButton}
							transparent
							onClick={() =>
								this.onMenuClick({
									key: 'true',
									label:
										advanceFilterTypeMap[
											PortCallsFiltersParamEnum.FINANCE_DISABLED
										],
									type: PortCallsFiltersParamEnum.FINANCE_DISABLED
								})
							}
						>
							{advanceFilterTypeMap[PortCallsFiltersParamEnum.FINANCE_DISABLED]}
						</Button>
						<Button
							className={styles.filterButton}
							transparent
							onClick={() =>
								this.onMenuClick({
									key: 'true',
									label:
										advanceFilterTypeMap[
											PortCallsFiltersParamEnum.OPERATION_DISABLED
										],
									type: PortCallsFiltersParamEnum.OPERATION_DISABLED
								})
							}
						>
							{
								advanceFilterTypeMap[
									PortCallsFiltersParamEnum.OPERATION_DISABLED
								]
							}
						</Button>
					</Collapse>
				</Drawer>
			</>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	userType: getUserType(state),
	agentTypes: getPortJobAgencyTypes(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceFilters);
