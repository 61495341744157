import { ThreadReducerState } from './../threadState';
import {
	RetrieveThreadMessageByIdParams,
	Message
} from 'services/api/messages/messagesServiceTypes';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveThreadMessageByIdStarted = (
	state: ThreadReducerState
): ThreadReducerState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrievingMessage: FetchStatus.PENDING
	}
});
export const onRetrieveThreadMessageByIdFailed = (
	state: ThreadReducerState,
	action: Failure<RetrieveThreadMessageByIdParams, Error>
): ThreadReducerState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrievingMessage: FetchStatus.FAILURE
	},
	error: action.error
});
export const onRetrieveThreadMessageByIdSuccess = (
	state: ThreadReducerState,
	action: Success<RetrieveThreadMessageByIdParams, Message>
): ThreadReducerState => {
	return {
		...state,
		composeMessage: action.result,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievingMessage: FetchStatus.SUCCESS
		},
		error: false
	};
};
