import Api from 'services/api';
import { retrievePortCallsSettingsAsync } from '../actions';
import { RetrievePortCallsSettingsResponse } from '../portCallsState';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const apiCall = Api.PortCalls.retrievePortCallsSettings;

export const retrievePortCallsSettingsWatcher = makeTakeLatestWatcher<
	void,
	RetrievePortCallsSettingsResponse,
	Error
>({ api: apiCall, async: retrievePortCallsSettingsAsync });
