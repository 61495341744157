import { FinanceState } from '../financeState';
import { Success } from 'typescript-fsa';
import {
	DeleteDAActionPayload,
	PortCallFinance
} from 'services/api/finance/financeServiceTypes';

import { reject, find } from 'lodash';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function onDeleteDASuccess(
	state: FinanceState,
	{ params }: Success<DeleteDAActionPayload, null>
): FinanceState {
	const finance = state.finance || ({} as PortCallFinance);
	let dasIn = finance.disbursementAccountIns.elements;
	let dasOut = finance.disbursementAccountOuts.elements;

	if (find(dasIn, da => da.id === params.id)) {
		dasIn = reject(dasIn, { id: params.id });
	} else {
		dasOut = reject(dasOut, { id: params.id });
	}

	/**
	 * Remove cover sheet document related information of the DA
	 */
	const newDocumentByDaOutIds = Object.keys(state.documentByDaOutId)
		.filter(id => id !== params.id)
		.reduce(
			(acc, daOutId) => (acc[daOutId] = state.documentByDaOutId[daOutId]),
			{}
		);

	return {
		...state,
		finance: {
			...finance,
			disbursementAccountIns: {
				...finance.disbursementAccountIns,
				elements: dasIn
			},
			disbursementAccountOuts: {
				...finance.disbursementAccountOuts,
				elements: dasOut
			}
		},
		documentByDaOutId: newDocumentByDaOutIds
	};
}

export const onDeleteDA = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DeleteDAActionPayload,
	null
>('fetchStatuses', 'deleteDA', {
	doneReducer: onDeleteDASuccess
});
