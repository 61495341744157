import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { RequestPDARequest } from 'services/api/portJobs/portJobsServiceTypes';
import { requestPDAAsync as async } from 'store/portJobs/actions';
import { getActivePortJobCode } from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { retrievePortCall } from 'store/portcalls';
import { includes } from 'lodash';
import { notify } from 'store/notifications/actions/notification';

const apiCall = Api.PortJobs.requestPDA;

export const executor = function*(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const request = {
		portCallId,
		portJobCode
	};
	yield put(async.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			async.done({
				result: undefined,
				params: request,
				response
			})
		);
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
	} catch (error) {
		if (
			error.response.status === 400 &&
			includes(error.response.data.error, 'BadRequest')
		) {
			yield put(
				notify.error({
					description: 'Performing Agent email address mandatory.',
					duration: 0
				})
			);
		} else if (error.response.status === 400) {
			yield put(notify.error('Error occurred while updating PDA request'));
		}
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
};

export const requestPDAWatcher = makeTakeLatestWatcher<
	RequestPDARequest,
	void,
	Error
>(
	{
		api: apiCall,
		async
	},
	executor
);

export default requestPDAWatcher;
