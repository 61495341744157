import { select } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	retrieveDAStatusHistoryByIdAsync,
	RetrieveDAStatusHistoryByIdParams
} from '../actions/retrieveDAStatusHistoryById';
import {
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse
} from 'services/api/finance/financeServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import Api from 'services/api';

export default makeTakeEveryWatcher<
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse,
	Error
>({
	api: Api.Finance.retrieveDAStatusHistoryById,
	async: retrieveDAStatusHistoryByIdAsync,
	*getApiParams(params: RetrieveDAStatusHistoryByIdParams) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: RetrieveDAStatusHistoryByIdRequest = {
			portCallId,
			jobCode,
			...params
		};
		return request;
	}
});
