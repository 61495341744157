import React, { useCallback } from 'react';
import { Row, Col } from 'components/antd';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import { AutocompletePorts, AutocompleteVessels } from 'containers';
import { connect, useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store-types';
import { get } from 'lodash';
import { touch, getFormMeta } from 'redux-form';
import {
	EDIT_PORTCALL_FORM,
	EditPortCallFormFields,
	EditPortCallFormData
} from 'store/portCall/sagas/editPortCallData/editPortCallDataTypes';
import {
	getIsCurrentUsersGroupTypeISSHub,
	getIsCurrentUsersGroupTypeISSCluster,
	getUserCompanyId
} from 'store/auth/selectors';
import FieldVessels from 'sections/PortJob/CreatePortJob/Pages/Page1/PagePartials/Form/Field.Vessels';
import { validatePortCall } from 'sections/PortJob/CreatePortJob/Pages/Page1/PagePartials/Form/validate';
import {
	getActivePortCall,
	getHubPrincipalFromPortJob
} from 'store/portcalls/selectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

const validate = (values: EditPortCallFormData) => {
	return validatePortCall(values);
};

interface EditPortCallFormProps {
	isGettingAllVesselsEnabled: boolean;
	companyId: string;
	isDateFieldDisabled: boolean;
}

const EditPortCallForm: React.SFC<EditPortCallFormProps> = ({
	isGettingAllVesselsEnabled,
	companyId,
	isDateFieldDisabled
}) => {
	const dispatch = useDispatch();
	const formMetadata = useSelector(getFormMeta(EDIT_PORTCALL_FORM)) as {
		etsPlt?: { touched: boolean };
	};
	const isEtsFieldTouched = formMetadata.etsPlt?.touched;
	const touchEts = useCallback(() => {
		if (!isEtsFieldTouched) {
			dispatch(touch(EDIT_PORTCALL_FORM, EditPortCallFormFields.ETS_PLT));
		}
	}, [dispatch, isEtsFieldTouched]);
	return (
		<ReduxForm
			form={EDIT_PORTCALL_FORM}
			validate={
				validate as any /*eslint-disable-line @typescript-eslint/no-explicit-any*/
			}
		>
			<Row>
				<Col sm={2} xs={12}>
					<AutocompletePorts
						name={EditPortCallFormFields.PORT}
						label="Port"
						required
						disabled
						afterFocusClick
					/>
				</Col>
				<Col sm={2} xs={12}>
					{isGettingAllVesselsEnabled ? (
						<AutocompleteVessels
							name={EditPortCallFormFields.VESSEL}
							label="Vessel"
							required
							afterFocusClick
						/>
					) : (
						<FieldVessels
							hubPrincipalCompany={{ key: companyId, label: '' }}
							name={EditPortCallFormFields.VESSEL}
							label="Vessel"
							required
							afterFocusClick
						/>
					)}
				</Col>
				<Col md={2} sm={2} xs={12}>
					<DatePickerExtendedFormItem
						name={EditPortCallFormFields.ETA_PLT}
						label="ETA"
						required
						onChange={touchEts}
						disabled={isDateFieldDisabled}
						help
						local
					/>
				</Col>
				<Col md={2} sm={2} xs={12}>
					<DatePickerExtendedFormItem
						name={EditPortCallFormFields.ETS_PLT}
						label="ETS"
						disabled={isDateFieldDisabled}
						help
						local
					/>
				</Col>
			</Row>
		</ReduxForm>
	);
};

export default connect((state: AppState) => {
	const companyId = getIsCurrentUsersGroupTypeISSHub(state)
		? get(getHubPrincipalFromPortJob(state), 'id', getUserCompanyId(state))
		: getUserCompanyId(state);
	return {
		isGettingAllVesselsEnabled: getIsCurrentUsersGroupTypeISSCluster(state),
		companyId,
		isDateFieldDisabled: (getActivePortCall(state) || ({} as PortCall))
			.containsAppointedJobs
	};
})(EditPortCallForm);
