import { reduce, includes, has } from 'lodash';
import {
	VesselProgrammeState,
	ReducerRotationStep,
	RotationStepsById,
	OperationUnitsById
} from './../vesselProgrammeState';
import { Success } from 'typescript-fsa';
import { RotationStepMovementType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { buildPositionedRotationSteps } from './retrieveVP';

export const isRotationStepShifting = (
	id: string,
	rotationStepsById: RotationStepsById
): string =>
	has(rotationStepsById[id], 'movementType') &&
	rotationStepsById[id].movementType === RotationStepMovementType.SHIFTING
		? id
		: '';
export const getPrevRotationStepId = (
	pos: number,
	orderedIds: string[]
): string => orderedIds[pos - 1];
export const getNextRotationStepId = (
	pos: number,
	orderedIds: string[]
): string => orderedIds[pos + 1];
export const filterRotationSteps = (
	currentRotationStepId: string,
	rotationStepsById: RotationStepsById,
	orderedRotationSteps: string[]
): RotationStepsById => {
	const rotationStepIdPos = orderedRotationSteps.indexOf(currentRotationStepId);
	const prevRotationStepId = getPrevRotationStepId(
		rotationStepIdPos,
		orderedRotationSteps
	);
	const nextRotationStepId = getNextRotationStepId(
		rotationStepIdPos,
		orderedRotationSteps
	);
	const prevShiftingId = isRotationStepShifting(
		prevRotationStepId,
		rotationStepsById
	);
	const nextShiftingId = isRotationStepShifting(
		nextRotationStepId,
		rotationStepsById
	);
	let result: string[] = [...orderedRotationSteps].filter(
		id => id !== currentRotationStepId
	);
	if (prevShiftingId && nextShiftingId) {
		/**
		 * if there is Shifting before AND after POI being deleted
		 * the shifting after POI being deleted is also deleted (that is, only one Shifting is left)
		 */
		result = result.filter(id => id !== nextShiftingId);
	} else if (prevShiftingId || nextShiftingId) {
		/**
		 * if there is POI 'Shifting' before OR after the POI being deleted
		 * Shifiting must be also deleted when original POI is deleted
		 */
		result = result
			.filter(id => id !== prevShiftingId)
			.filter(id => id !== nextShiftingId);
	}
	return result.reduce((acc, id) => {
		acc[id] = rotationStepsById[id];
		return acc;
	}, {});
};
export const filterOrderedRotationSteps = (
	orderedIds: string[],
	idsToPreserve: string[]
): string[] => orderedIds.filter(id => includes(idsToPreserve, id));
export const unnalocateOperationsFromRotationStep = (
	id: string,
	operationUnitsById: OperationUnitsById
) =>
	reduce(
		operationUnitsById,
		(acc, operation) => {
			acc[operation.id] = {
				...operation,
				allocatedId: operation.allocatedId === id ? '' : operation.allocatedId
			};
			return acc;
		},
		{}
	);

export const onDeletePOIStarted = (
	state: VesselProgrammeState
): VesselProgrammeState => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			delete: FetchStatus.PENDING
		}
	};
};
export const onDeletePOIFailed = (
	state: VesselProgrammeState
): VesselProgrammeState => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			delete: FetchStatus.FAILURE
		}
	};
};
export const onDeletePOISuccess = (
	state: VesselProgrammeState,
	action: Success<ReducerRotationStep['id'], undefined>
): VesselProgrammeState => {
	const rotationStepId = action.params;
	const rotationStepsById = filterRotationSteps(
		rotationStepId,
		state.rotationStepsById,
		state.orderedRotationSteps
	);
	const orderedRotationSteps = filterOrderedRotationSteps(
		state.orderedRotationSteps,
		Object.keys(rotationStepsById)
	);
	const operationUnitsById = unnalocateOperationsFromRotationStep(
		rotationStepId,
		state.operationUnitsById
	);
	return {
		...state,
		edited: true,
		orderedRotationSteps,
		operationUnitsById,
		rotationStepsById,
		rotationStepsPositionedToCustodyLine: buildPositionedRotationSteps(
			orderedRotationSteps,
			state.custodyTransfer
		),
		fetchStatuses: {
			...state.fetchStatuses,
			delete: FetchStatus.SUCCESS
		}
	};
};
