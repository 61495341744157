import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { retrieveSupplierInvoiceJobAsync } from '../actions';

const apiCall = Api.PortCalls.retrievePortCalls;

export default makeTakeLatestWatcher<
	RetrievePortCallsRequest,
	RetrievePortCallsResponse,
	Error
>({
	api: apiCall,
	async: retrieveSupplierInvoiceJobAsync
});
