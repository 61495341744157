import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { Flex, PageHeader, ScrollableLayout } from 'components';
import { AppNotifications } from 'containers';
import { BreadcrumbItem } from 'store/navigation';
import PortCallDuplicatesPage from './PortCallDuplicatesPage';
import PageFooter from './PageFooter';
import {
	CancelMergingPortCallsModal,
	MergePortCallsModal
} from './MergePortCallsModals';
import {
	CANCEL_MERGING_PORTCALLS_MODAL,
	MERGE_PORTCALLS_MODAL
} from './constants';
import { AppState } from 'store-types';
import {
	retrievePortCall,
	retrievePortCallDuplicates,
	setPortCallContext,
	mergePortCalls,
	resetPortCallDuplicates
} from 'store/portcalls/actions';
import { closeModal, openModal } from 'store/modals/actions';
import {
	getPortCallByIdFetchStatus,
	getMergePortCallsContext
} from 'store/portcalls/selectors';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { isModalVisible } from 'store/modals/selectors';
import { FetchStatus } from 'services/api/apiTypes';
import { MergePortCallsContext } from 'store/portcalls/portCallsState';
import { MergePortCallsRequest } from 'services/api/portCalls/portCallsServiceTypes';
import { DataFetcher } from 'utils/components';
import { navigateTo } from 'utils';

interface RouteParams {
	portCallId: string;
	newtab?: string;
}

const getBreadcrumbs = (
	breadcrumbs: BreadcrumbItem[],
	portCallId: string
): BreadcrumbItem[] => {
	if (!breadcrumbs) {
		return [];
	}
	const link = `/portcalls/${portCallId}/overview`;
	return [
		...breadcrumbs.slice(0, -1),
		{ title: 'Overview', link },
		{ title: 'Port Call duplicates' }
	];
};

interface PortCallDuplicatesRouteProps
	extends RouteComponentProps<RouteParams> {
	// from mapStateToProps
	portCallRetrieveFetchStatus: FetchStatus;
	breadcrumbs: BreadcrumbItem[];
	isCancelModalVisible: boolean;
	isMergePortCallsModalVisible: boolean;
	mergePortCallsContext: MergePortCallsContext;
	// from mapDispatchToProps
	initializeData: () => void;
	resetDuplicates: () => void;
	openCancelModal: () => void;
	onCancelConfirmed: (isConfirmed: boolean) => void;
	openConfirmModal: () => void;
	closeConfirmModal: () => void;
	mergePortCalls: (requestParams: MergePortCallsRequest) => void;
}

class PortCallDuplicatesRoute extends React.Component<
	PortCallDuplicatesRouteProps
> {
	mergePortCalls = () => {
		const { match, mergePortCallsContext } = this.props;
		this.props.mergePortCalls({
			portCallId: match.params.portCallId,
			...mergePortCallsContext
		});
	};

	onSubmit = () => {
		const { mergePortCallsContext } = this.props;
		if (mergePortCallsContext.isDuplicate) {
			this.props.openConfirmModal();
		} else {
			this.mergePortCalls();
		}
	};

	onMergeConfirmed = (isConfirmed: boolean) => {
		this.props.closeConfirmModal();
		if (isConfirmed) {
			this.mergePortCalls();
		}
	};

	render() {
		const {
			match: {
				params: { portCallId, newtab }
			},
			portCallRetrieveFetchStatus,
			breadcrumbs,
			isCancelModalVisible,
			isMergePortCallsModalVisible,
			mergePortCallsContext: { portCallDuplicateIds },
			initializeData,
			resetDuplicates,
			openCancelModal,
			onCancelConfirmed
		} = this.props;

		return (
			<DataFetcher
				dispatch={initializeData}
				fetchStatus={portCallRetrieveFetchStatus}
				reset={resetDuplicates}
			>
				{() => (
					<Flex fit direction="vertical" stretch>
						<RouteBreadcrumbs items={breadcrumbs} />
						<PageHeader.NewTab
							isNewTab={Boolean(newtab)}
							onClose={openCancelModal}
							shouldNewTabClose={false}
						>
							Port Call duplicates
						</PageHeader.NewTab>
						<AppNotifications.Alert />
						<AppNotifications.Notification />
						<ScrollableLayout>
							<PortCallDuplicatesPage portCallId={portCallId} />
							<PageFooter onCancel={openCancelModal} onSubmit={this.onSubmit} />
						</ScrollableLayout>
						<CancelMergingPortCallsModal
							isModalVisible={isCancelModalVisible}
							onOk={() => onCancelConfirmed(true)}
							onCancel={() => onCancelConfirmed(false)}
						/>
						{portCallDuplicateIds && (
							<MergePortCallsModal
								currentPortCallId={portCallId}
								masterPortCallId={portCallDuplicateIds[0]}
								isModalVisible={isMergePortCallsModalVisible}
								onOk={() => this.onMergeConfirmed(true)}
								onCancel={() => this.onMergeConfirmed(false)}
							/>
						)}
					</Flex>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			match: {
				params: { portCallId }
			}
		}: Pick<PortCallDuplicatesRouteProps, 'match'>
	) => {
		const breadcrumbs = getPortCallLayoutBreadcrumbs(state);
		return {
			portCallRetrieveFetchStatus: getPortCallByIdFetchStatus(
				state,
				portCallId
			),
			breadcrumbs: getBreadcrumbs(breadcrumbs, portCallId),
			isCancelModalVisible: isModalVisible(
				state,
				CANCEL_MERGING_PORTCALLS_MODAL
			),
			isMergePortCallsModalVisible: isModalVisible(
				state,
				MERGE_PORTCALLS_MODAL
			),
			mergePortCallsContext: getMergePortCallsContext(state)
		};
	},
	(
		dispatch,
		{
			match: {
				params: { portCallId, newtab }
			}
		}: Pick<PortCallDuplicatesRouteProps, 'match'>
	) => ({
		initializeData: () => {
			// need to set context for port call breadcrumbs
			dispatch(setPortCallContext({ activePortCallId: portCallId }));
			dispatch(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
			dispatch(retrievePortCallDuplicates({ portCallId }));
		},
		resetDuplicates: () => {
			dispatch(resetPortCallDuplicates({ portCallId }));
		},
		openCancelModal: () => {
			dispatch(openModal(CANCEL_MERGING_PORTCALLS_MODAL));
		},

		onCancelConfirmed: (isConfirmed?: boolean) => {
			dispatch(closeModal(CANCEL_MERGING_PORTCALLS_MODAL));
			if (isConfirmed) {
				newtab
					? window.close()
					: navigateTo(`/portcalls/${portCallId}/overview`);
			}
		},
		openConfirmModal: () => {
			dispatch(openModal(MERGE_PORTCALLS_MODAL));
		},
		closeConfirmModal: () => {
			dispatch(closeModal(MERGE_PORTCALLS_MODAL));
		},
		mergePortCalls: (requestParams: MergePortCallsRequest) => {
			dispatch(mergePortCalls(requestParams));
		}
	})
)(PortCallDuplicatesRoute);
