import { FormData } from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { AddSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { prepareRequest } from '../updateSupplierInvoice/prepareUpdateRequest';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';

export default (
	formData: FormData,
	documentId: string,
	wizardForm: WizardFormProps,
	originalSupplierInvoiceId?: string
): AddSupplierInvoiceRequest => {
	const request = prepareRequest(formData, wizardForm);
	return {
		...request,
		documentId,
		originalSupplierInvoiceId
	};
};
