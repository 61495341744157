import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

import {
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { retrievePortCallDocumentAsync } from 'store/portCallDocuments/actions';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse,
	Error
>({
	api: Api.PortCallDocuments.retrievePortCallDocument,
	async: retrievePortCallDocumentAsync
});
