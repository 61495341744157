import actionCreator from '../savingsActionCreator';
import {
	RetrieveSavingsRequest,
	RetrieveSavingsResponse
} from 'services/api/savings/savingsServiceTypes';

const RETRIEVE_SAVINGS = 'RETRIEVE_SAVINGS';
export const retrieveSavingsAsync = actionCreator.async<
	RetrieveSavingsRequest,
	RetrieveSavingsResponse,
	Error
>(RETRIEVE_SAVINGS);

export const retrieveSavings = actionCreator<RetrieveSavingsRequest>(
	RETRIEVE_SAVINGS
);
