import {
	RetrieveFundingRequestRequest,
	RetrieveFundingRequestResponse
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

export interface RetrieveFundingActionParams {
	fundingId: string;
}
const ACTION_NAME = 'RETRIEVE_FUNDING_REQUEST';
export const retrieveFundingRequestAsync = actionCreator.async<
	RetrieveFundingRequestRequest,
	RetrieveFundingRequestResponse,
	Error
>(ACTION_NAME);

export const retrieveFundingRequest = actionCreator<
	RetrieveFundingActionParams
>(ACTION_NAME);
