export const PORT_JOB_WIZARD_ID = 'portJob';
export const CARGO_LINE_MODAL = 'CargoLineModal';
export const GRADE_LINE_MODAL = 'CargoLineModal';
export const TOTAL_AMOUNT_MODAL = 'TotalAmountModal';
export const CANCEL_PROCESS_MODAL = 'CancelProcessModal';

export const FORM = {
	portJob: PORT_JOB_WIZARD_ID,
	cargoLine: 'cargoLine',
	gradeLine: 'gradeLineForm',
	totalAmountLine: 'totalAmountForm'
};

export enum FormFieldName {
	CREATE_NEW_PORT_CALL = 'createNewPortCall',

	// Page 1
	VESSEL = 'portCall.vessel',
	PORT = 'portCall.port',
	ETA_PLT = 'portCall.etaPlt',
	ETS_PLT = 'portCall.etsPlt',
	// End Page 1

	// Page 2
	HUB_PRINCIPAL_COMPANY = 'hubPrincipalCompany',
	JOB_TYPE_ID = 'jobTypeId',
	SHOW_ALL = 'showAll',
	APPOINTER_COMPANY = 'appointerCompany',
	CUSTOMER_REF = 'customerRef',
	CONTACT_1 = 'contact1',
	CONTACT_2 = 'contact2',
	AGENCY_TYPE = 'agencyType',
	PERFORMING_AGENT = 'performingAgent',
	AGENT_ROLE = 'agentRole',
	NOMINATOR = 'nominator',
	PAYING_PARTY = 'payingparty',
	CONTROLLING_AGENT = 'controllingAgent',
	INSTRUCTION_TO_ISS = 'instructionToISS',
	INSTRUCTION_TO_LPA = 'instructionToLPA',
	CHECK_NON_ISS_HUB_PRINCIPAL = 'checkIsNonIssHubPrincipal',
	// End Page 2

	// Page 3
	OPERATION_TYPE = 'operationType',

	CHARTERERS = 'charterers', // ChartererFormFieldName[]
	CARGO_LINES = 'cargoes' // CargoLineFormFieldName[]
	// End Page 3
}

export const TOTAL_AMOUNT_FIELD_NAME = 'totalAmounts';
