import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import {
	ConfigurationServicesState,
	initialState
} from '../configurationServicesState';
import {
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
} from 'services/api/services/servicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: ConfigurationServicesState,
	{
		result
	}: Success<
		RetrievePrincipalServicesRequest,
		RetrievePrincipalServicesResponse
	>
): ConfigurationServicesState => ({
	...state,
	principalServices: keyBy(result.elements, item => item.id)
});

export const onRetrievePrincipalServices = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
>('fetchStatuses', 'retrievePrincipalServices', {
	doneReducer
});

export const onResetPrincipalServices = (
	state: ConfigurationServicesState
) => ({
	...state,
	principalServices: initialState.principalServices
});
