import { WrappedFieldMetaProps, WrappedFieldProps } from 'redux-form';
import { isArray } from 'lodash';
import { FormItemProps } from '../FormItem';

export const getValidateStatus = (
	touched: WrappedFieldMetaProps['touched'],
	error: WrappedFieldMetaProps['error'],
	warning: WrappedFieldMetaProps['warning'],
	valid: WrappedFieldMetaProps['valid']
) => {
	if (touched) {
		if (isArray(error) ? error.length > 0 : error) {
			return 'error';
		}
		if (isArray(warning) ? warning.length > 0 : warning) {
			return 'warning';
		}
		if (isArray(valid) ? valid.length > 0 : valid) {
			return 'success';
		}
		return undefined;
	}
	return undefined;
};

type CustomMapProps = WrappedFieldProps & FormItemProps;

const mapError = ({
	meta: { touched, error, warning, valid } = {} as WrappedFieldMetaProps,
	input: { ...inputProps },
	...props
}: CustomMapProps) => ({
	...props,
	...inputProps,
	validateStatus: getValidateStatus(touched, error, warning, valid),
	help: props.help && touched && (error || warning)
});

export function customMap<P = {}>(
	customPropsFun: (props: P & CustomMapProps) => any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
	return (props: P & CustomMapProps) =>
		Object.assign(
			{},
			mapError(props),
			customPropsFun ? customPropsFun(props) : {}
		);
}

export default mapError;
