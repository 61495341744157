import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CompanyVesselTypeState } from '../companyVesselTypesState';
import {
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';

export const onCreateCompanyVesselType = makeFetchStatusReducers<
	CompanyVesselTypeState,
	'fetchStatuses',
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse
>('fetchStatuses', 'create', {
	doneReducer: (
		state,
		{ params: { companyId, data }, result /* result is id of created record */ }
	) => ({
		...state,
		byId: {
			...state.byId,
			[result]: {
				id: result,
				companyId,
				...data
			}
		}
	})
});
