import React from 'react';
import cn from 'classnames';
import styles from './WithMinus.module.scss';

export enum MinusPosition {
	BEFORE = 'before',
	AFTER = 'after'
}

interface WithMinusProps {
	position?: MinusPosition;
	children?: React.ReactNode;
	beforeFormItem?: boolean;
	gapRight?: boolean;
}

const WithMinus = ({
	position,
	children,
	beforeFormItem,
	gapRight
}: WithMinusProps) => (
	<span
		className={cn({
			[styles.minusBefore]:
				position === MinusPosition.BEFORE && !beforeFormItem,
			[styles.minusAfter]: position === MinusPosition.AFTER && !beforeFormItem,
			[styles.minusBeforeFormItem]:
				position === MinusPosition.BEFORE && beforeFormItem,
			[styles.gapRight]: gapRight
		})}
	>
		{children}
	</span>
);

export default WithMinus;
