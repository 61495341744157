import actionCreator from '../opticConfigActionCreator';
import { GenericOpticConfig as Response } from 'services/api/opticConfiguration/opticConfigServiceTypes';

const RETRIEVE_GENERIC_KPI_SECTION = 'RETRIEVE_GENERIC_KPI_SECTION';

export const retrieveKpiActiveSection = actionCreator(
	RETRIEVE_GENERIC_KPI_SECTION
);

export const retrieveKpiActiveSectionAsync = actionCreator.async<
	void,
	Response,
	Error
>(RETRIEVE_GENERIC_KPI_SECTION);
