import {
	put,
	select,
	takeLatest,
	all,
	fork,
	take,
	race
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	deleteActiveCompanyVessel,
	DeleteActiveCompanyVesselActionParams
} from '../actions';
import { getActiveCompanyId, getSingleVesselById } from '../selectors';
import {
	deleteCompanyVessel,
	deleteCompanyVesselAsync
} from 'store/companyVessels/actions';
import { Action } from 'typescript-fsa';
import { closeModal, openModal } from 'store/modals/actions';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';

export function* worker({
	payload: { id }
}: Action<DeleteActiveCompanyVesselActionParams>): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	const vessel: CompanyVessel = yield select(getSingleVesselById, id);
	const vesselName = vessel ? vessel.vessel.name : '';
	yield put(
		openModal({
			name: 'deleteVessel',
			type: 'confirm',
			data: {
				description: `Are you sure you want to delete the vessel ${vesselName} ?`
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		// user canceled the process
		return;
	}
	// user confirmed the deletion
	yield put(deleteCompanyVessel({ companyId, id }));
	yield race({
		done: take(deleteCompanyVesselAsync.done),
		failed: take(deleteCompanyVesselAsync.failed)
	});
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteActiveCompanyVessel, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
