import { retrieveConfigurationCompaniesAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';

import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import {
	RetrieveCompaniesForOrganisationRequest,
	RetrieveCompaniesForOrganisationResponse,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeEveryWatcher<
	PermissionCode[],
	RetrieveCompaniesForOrganisationResponse,
	Error,
	RetrieveCompaniesForOrganisationRequest
>({
	api: Api.Companies.retrieveCompanyType[
		OrganisationType.MAIN_PRINCIPAL
	] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
	async,
	*getApiParams(actionType: PermissionCode[]) {
		return {
			actionType,
			organisationType: OrganisationType.MAIN_PRINCIPAL
		};
	}
});
