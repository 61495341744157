import { AppState } from 'store-types';
import {
	getRotationStepPoiType,
	getOperationsById,
	getRotationStepsPositionedToCustodyLine,
	getPositionedRotationStep,
	getEventsById
} from '.';
import { createSelector } from 'reselect';
import { RotationStepPOIType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';

export const getIsDragging = (state: AppState) =>
	Boolean(state.vesselProgramme.draggedOperationUnitId);

export const getDraggedUnitId = (state: AppState) =>
	state.vesselProgramme.draggedOperationUnitId;

const getRotationStepIdOfDraggedUnit = createSelector(
	[getDraggedUnitId, getOperationsById],
	(unitId, operations) => Boolean(unitId) && operations[unitId].allocatedId
);

const getIsRotationStepTheOwnerOfDraggedUnit = (stepPosition: number) => (
	state: AppState,
	rotationStepId: string
) => {
	const draggedUnitRotationStepId = getRotationStepIdOfDraggedUnit(state);
	const stepAgentMode = getRotationStepsPositionedToCustodyLine(state)[
		stepPosition
	].agentMode;
	const unitAgentRole =
		getDraggedUnitId(state) &&
		getOperationsById(state)[getDraggedUnitId(state)].job.agentRole;

	return (
		rotationStepId === draggedUnitRotationStepId &&
		stepAgentMode === unitAgentRole
	);
};

export const getDraggedOperationUnit = createSelector(
	getOperationsById,
	getDraggedUnitId,
	(units, unitId) => units[unitId]
);

export const getShouldShowUnitDrop = (
	state: AppState,
	stepPosition: number
) => {
	const { rotationStepId } = getPositionedRotationStep(state, stepPosition);
	return createSelector(
		[
			getIsDragging,
			getRotationStepPoiType,
			getIsRotationStepTheOwnerOfDraggedUnit(stepPosition)
		],
		(
			isDragging: boolean,
			poiType: RotationStepPOIType | undefined,
			isUnitOwner: boolean
		) => isDragging && !!poiType && !isUnitOwner
	)(state, rotationStepId);
};

export const getCanUnallocateUnit = createSelector(
	getRotationStepIdOfDraggedUnit,
	rotationStepId => Boolean(rotationStepId)
);

export const getIsDraggedUnitHasRecordedEvent = createSelector(
	[getDraggedOperationUnit, getEventsById],
	(unit, events) =>
		unit
			? unit.events.some(
					eventId => events[eventId].status === PortCallEventStatus.RECORDED
				)
			: false
);
