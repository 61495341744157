import queryString from 'query-string';

export function getQueryParams(url: string): string {
	const queryParams = queryString.extract(url);
	if (!queryParams) {
		return '';
	}
	return queryParams;
}

export function getNewTabUrl(url: string, origin: string) {
	const host = origin.replace(/\/$/, '');
	const queryParams = queryString.extract(url);
	/**
	 * Remove QueryParams and Host from Url
	 */
	const pathname = url.replace(queryParams, '').replace(host, '');

	/**
	 * Split by '/' and remove remaining '?' and '&'
	 */
	const paths = pathname
		.split('/')
		.filter(a => a)
		.map(a => a.replace('?', '').replace('&', ''));
	const filteredPath = paths
		.filter(p => p !== 'newtab')
		.join('/')
		.replace(queryParams, '');
	const result = filteredPath ? `${filteredPath}/newtab` : 'newtab';

	return queryParams.length > 1
		? `${host}/${result}?${queryParams}`
		: `${host}/${result}`;
}

export function checkForNewTab(url: string): boolean {
	const paths = url.split('/').filter(p => p);
	return paths[paths.length - 1] === 'newtab';
}

export function checkForExpand(url: string): boolean {
	const paths = url.split('/').filter(p => p);
	return paths[paths.length - 1] === 'expand';
}
