import {
	DEFAULT_VALUE,
	ConfigurationFileFormData
} from '../../configurationFilesTypes';
import { AddCompanyTemplateFileRequestData } from 'services/api/templates/templatesServiceTypes';

const getPreparedEntities = <T>(
	value: string[],
	callback: (id: string) => T
) => {
	const result: T[] = [];
	value.forEach(id => {
		if (id !== DEFAULT_VALUE) {
			result.push(callback(id));
		}
	});
	return result;
};

const prepareRequest = (
	formValues: ConfigurationFileFormData
): AddCompanyTemplateFileRequestData => ({
	documentId: formValues.documentId,
	principals: getPreparedEntities(formValues.principals, id => ({ id })),
	ports: getPreparedEntities(
		formValues.ports.map(port => port.key),
		id => ({ id })
	),
	mainCommodities: getPreparedEntities(
		formValues.mainCommodities,
		code => code
	),
	messageTypes: getPreparedEntities(
		formValues.messageTypes,
		messageType => messageType
	)
});

export default prepareRequest;
