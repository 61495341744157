import Api from 'services/api';
import { select } from 'redux-saga/effects';
import { addDAOutAsync as async } from '../actions';
import {
	AddDAOutResponse,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Finance.addDAOut;
export default makeTakeLatestWatcher<
	{ serviceId: PortJobService['id'] },
	AddDAOutResponse,
	Error
>({
	api,
	async,
	*getApiParams({ serviceId }) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request = {
			portCallId,
			jobCode,
			serviceId
		};
		return request;
	}
});
