import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../portCallsActionCreator';

const RETRIEVE_ACTION_NAME = 'RETRIEVE_PORTCALLS_DUPLICATES';

export const retrievePortCallsDuplicatesAsync = actionCreator.async<
	RetrievePortCallsRequest,
	RetrievePortCallsResponse,
	Error
>(RETRIEVE_ACTION_NAME);

export const retrievePortCallsDuplicates = actionCreator<
	RetrievePortCallsRequest
>(RETRIEVE_ACTION_NAME);

const RESET_ACTION_NAME = 'PORTCALLS_DUPLICATES_RESET';

export const resetPortCallsDuplicates = actionCreator(RESET_ACTION_NAME);
