import { Success, Failure } from 'typescript-fsa';
import { DocumentsState } from '../documentsState';
import {
	RetrieveDocumentRequest,
	RetrieveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrieveDocumentStarted = (
	state: DocumentsState,
	params: RetrieveDocumentRequest
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		documents: {
			...state.fetchStatuses.documents,
			[params.id]: FetchStatus.SUCCESS
		}
	}
});

export const onRetrieveDocumentDone = (
	state: DocumentsState,
	{ result }: Success<RetrieveDocumentRequest, RetrieveDocumentResponse>
) => ({
	...state,
	byId: {
		...state.byId,
		[result.id]: result
	},
	fetchStatuses: {
		...state.fetchStatuses,
		documents: {
			...state.fetchStatuses.documents,
			[result.id]: FetchStatus.SUCCESS
		}
	}
});

export const onRetrieveDocumentFailed = (
	state: DocumentsState,
	{ params }: Failure<RetrieveDocumentRequest, Error>
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		documents: {
			...state.fetchStatuses.documents,
			[params.id]: FetchStatus.FAILURE
		}
	}
});
