import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { deletePortJob } from 'store/portJobs/actions/deletePortJob';
import { AppState } from 'store-types';

interface DeletePortJobModalProps {
	jobCode: string;
	portCallId: string;
	okText?: string;
	cancelText?: string;
	// from mapDispatchToProps
	deletePortJob: typeof deletePortJob;
	visible: boolean;
	closeModal: typeof closeModal;
}

class DeletePortJobModal extends React.PureComponent<DeletePortJobModalProps> {
	hideConfirmationModal = () => {
		this.props.closeModal({
			name: 'delete-portjob-modal'
		});
	};

	onJobDeletionConfirmed = () => {
		const { portCallId, jobCode } = this.props;
		this.props.deletePortJob({
			portCallId,
			jobCode
		});
	};

	render() {
		const { visible } = this.props;
		return (
			<Modal
				visible={visible}
				okText="Yes"
				closable={false}
				cancelText="No"
				onCancel={this.hideConfirmationModal}
				onOk={this.onJobDeletionConfirmed}
			>
				Are you sure you want to delete the job?
			</Modal>
		);
	}
}

export default connect(
	(state: AppState) => ({
		visible: isModalVisible(state, 'delete-portjob-modal') // WAT????
	}),
	{
		deletePortJob,
		closeModal
	}
)(DeletePortJobModal);
