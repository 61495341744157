import { Success } from 'typescript-fsa';
import { RegistrationState } from '../registrationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import {
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse
} from 'services/api/registration/registrationServiceTypes';

const doneReducer = (
	state: RegistrationState,
	{
		params,
		result
	}: Success<
		RetrievePreRegistrationAgentCompanyRequest,
		RetrievePreRegistrationAgentCompanyResponse
	>
): RegistrationState => ({
	...state,
	preRegistration: {
		...state.preRegistration,
		agentCompany: {
			id: params.agentCompany,
			name: result.agentCompanyName,
			email: result.agentCompanyEmail
		}
	}
});

export const onRetrievePreRegistrationAgentCompany = makeFetchStatusReducers<
	RegistrationState,
	'fetchStatuses',
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse
>('fetchStatuses', 'retrievePreRegistrationAgentCompany', {
	doneReducer
});
