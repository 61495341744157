import { DEFAULT_DECIMAL_SEPARATOR } from 'app-constants';
import { flow, isUndefined } from 'lodash';
import { InputNumberProps } from './InputNumber';

export const getAllowedDigits = (value: string, maxDigits: number) => {
	const isNumber = (char: string) => new RegExp(/^\d+$/).test(char);
	return value.split('').reduce(
		((digitsCount: number) => (acc: string, char: string) => {
			if (digitsCount === maxDigits) {
				return acc;
			}
			if (isNumber(char)) {
				digitsCount++;
			}
			return acc + char;
		})(0),
		''
	);
};

export const getWithAllowedIntegerPart = (
	value: string,
	maxIntegerPart: number
) => {
	const valueArr = value.split(DEFAULT_DECIMAL_SEPARATOR);
	const hasADot = value.includes(DEFAULT_DECIMAL_SEPARATOR);
	const integerPart = valueArr[0] || '';
	const decimalPart = valueArr[1] || '';

	return (
		getAllowedDigits(integerPart, maxIntegerPart) +
		(hasADot ? DEFAULT_DECIMAL_SEPARATOR : '') +
		decimalPart
	);
};

export const getWithAllowedDecimalPart = (
	value: string,
	maxDecimalPart: number
) => {
	if (value.search(/\./) === -1) {
		return value;
	}
	if (maxDecimalPart === 0) {
		return value.replace(DEFAULT_DECIMAL_SEPARATOR, '');
	}
	const valueArr = value.split(DEFAULT_DECIMAL_SEPARATOR);
	const integerPart = valueArr[0];
	const decimalPart = valueArr[1];

	return (
		integerPart +
		DEFAULT_DECIMAL_SEPARATOR +
		decimalPart.slice(0, maxDecimalPart)
	);
};

export const formatNumericValue = (
	value: string,
	withDelimiter = true,
	allowNegative = false,
	allowLeadingZeros?: boolean
) => {
	const initialMask = allowNegative ? /[^\d\.-]?/g : /[^\d\.]?/g;
	let parsedNumber = value
		// remove any character that is not a number, comma or dot
		.replace(initialMask, '')
		// replace dots with dot
		.replace(/\.+/g, DEFAULT_DECIMAL_SEPARATOR)
		.replace(/-+/g, '-');

	if (allowNegative) {
		// replace not leading '-' signs in the negative number
		parsedNumber = parsedNumber
			.replace(/([^-]+)(?:-)+/g, '$1')
			.replace(/^(-)(?:-)*/g, '$1');
	}

	// optionally remove leading zeros for numbers
	if (!allowLeadingZeros) {
		parsedNumber = parsedNumber.replace(
			/^(0+)([0-9]+)/g,
			(_match, _zeros, numbers) => numbers
		);
	}

	parsedNumber = parsedNumber
		// for situation of DECIMAL SEPARATOR on the beginnig append 0
		.replace(/^\./g, `0${DEFAULT_DECIMAL_SEPARATOR}`)
		.split(DEFAULT_DECIMAL_SEPARATOR)
		// add DECIMAL SEPARATOR to last part
		.map((str, index, whole) => {
			if (whole.length === 1) {
				return str;
			}
			if (index === whole.length - 1) {
				return DEFAULT_DECIMAL_SEPARATOR + str;
			}
			return str;
		})
		// join
		.reduce((acc, str) => acc + str, '');

	if (!withDelimiter) {
		return parsedNumber;
	}

	return parsedNumber
		.split(DEFAULT_DECIMAL_SEPARATOR)
		.map((str, index) =>
			index === 0
				? str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,') // add delimiters to integer part
				: DEFAULT_DECIMAL_SEPARATOR + str
		)
		.reduce((acc, str) => acc + str, '');
};

export const format = ({
	value = '',
	withDelimiter,
	decimalPart,
	integerPart,
	maxDigits,
	allowNegative,
	allowLeadingZeros
}: Pick<
	InputNumberProps,
	| 'value'
	| 'withDelimiter'
	| 'decimalPart'
	| 'integerPart'
	| 'maxDigits'
	| 'allowNegative'
	| 'allowLeadingZeros'
>) => {
	return flow(
		(val: string) =>
			!isUndefined(maxDigits) ? getAllowedDigits(val, maxDigits) : val,
		val =>
			!isUndefined(decimalPart)
				? getWithAllowedDecimalPart(val, decimalPart)
				: val,
		val =>
			!isUndefined(integerPart)
				? getWithAllowedIntegerPart(val, integerPart)
				: val,
		val =>
			formatNumericValue(val, withDelimiter, allowNegative, allowLeadingZeros)
	)(value);
};
