import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import {
	setPortCallThreadsFilters,
	resetPortCallThreadsFilters
} from '../actions';
import {
	onSetPortCallThreadsFilters,
	onResetPortCallThreadsFilters
} from './setPortCallThreadsFiltersReducer';
import { PortCallThreadsFiltersState } from '../portCallTypes';

export default reducerWithoutInitialState<PortCallThreadsFiltersState>()
	.case(setPortCallThreadsFilters, onSetPortCallThreadsFilters)
	.case(resetPortCallThreadsFilters, onResetPortCallThreadsFilters);
