import Api from 'services/api';
import { call, put, takeLatest } from 'redux-saga/effects';

import { retrieveBunkerGradesAction } from '../actions/retrieveBunkerGrades';

const { async } = retrieveBunkerGradesAction;

const apiCall = Api.Operations.retrieveBunkerGrades;

export const executor = function*(api: typeof apiCall) {
	yield put(async.started(null));
	try {
		const response = yield call(api);
		yield put(
			async.done({
				result: response.data,
				params: null,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: null
			})
		);
	}
};

export function* worker() {
	yield call(executor, apiCall);
}

export default function* watcher() {
	yield takeLatest(async.type, worker);
}
