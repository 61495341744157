import { actionCreatorFactory, Success, Failure } from 'typescript-fsa';
import { AssignJobToThreadParams } from 'services/api/threads/threadsServiceTypes';

const actionCreator = actionCreatorFactory();

export type AssignJobToThreadSuccessAction = Success<
	AssignJobToThreadParams,
	{}
>;
export type AssignJobToThreadFailureAction = Failure<
	AssignJobToThreadParams,
	Error
>;

export const unassignJobFromThread = actionCreator<AssignJobToThreadParams>(
	'thread/UNASSIGN_JOB_FROM_THREAD'
);
export const unassignJobFromThreadAsync = actionCreator.async<
	AssignJobToThreadParams,
	{},
	Error
>(unassignJobFromThread.type);
