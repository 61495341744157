import React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'components';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { Button, Row, Col } from 'components/antd';
import GradeLineFormFields from './GradeLineFormFields';
import { Justify } from 'components/types';
import { getUnits } from 'store/units/unitsSelectors';
import { getBunkerGrades } from 'store/operations/operationsSelectors';
import {
	getIsGradeLineFormInEditMode,
	getGradeLineInitialValues
} from 'store/portJobs/selectors';
import { GradeLineFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	FORM,
	GRADE_LINE_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { Unit } from 'services/api/units/unitsServiceTypes';
import { BunkerGrade } from 'services/api/operations/operationsServiceTypes';
import validate from './validate';
import { setEditableGradeId } from 'store/portCall/actions';
import { closeModal } from 'store/modals/actions';
import { getValues } from 'store/form/selectors';
import { AppState } from 'store-types';

export interface GradeLineFormBaseProps {
	formValues: GradeLineFormData;
	initialValues: Partial<GradeLineFormData>;
	onSubmit: (values: GradeLineFormData) => void;
	onCancel: () => void;
	// from mapStateToProps
	inEditMode: boolean;
	units: Unit[];
	grades: BunkerGrade[];
	setEditableGradeId: typeof setEditableGradeId;
	closeModal: typeof closeModal;
}
interface GradeLineFormProps
	extends InjectedFormProps<GradeLineFormData, GradeLineFormBaseProps>,
		GradeLineFormBaseProps {}

class GradeLineForm extends React.PureComponent<GradeLineFormProps> {
	onSubmit = (values: GradeLineFormData) => {
		this.props.onSubmit(values);
		this.props.setEditableGradeId(undefined);
		this.props.closeModal(GRADE_LINE_MODAL);
	};

	onCancel = () => this.props.onCancel();

	render() {
		return (
			<form onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<GradeLineFormFields
					units={this.props.units}
					grades={this.props.grades}
				/>
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button type="primary" transparent onClick={this.onCancel}>
								Cancel
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								disabled={!this.props.valid}
							>
								{this.props.inEditMode ? 'Save Details' : 'Add Grade Line'}
							</Button>
						</Flex>
					</Col>
				</Row>
			</form>
		);
	}
}

export default connect(
	(state: AppState) => ({
		inEditMode: getIsGradeLineFormInEditMode(state),
		formValues: getValues<GradeLineFormData>(state, FORM.gradeLine),
		initialValues: getGradeLineInitialValues(state) as Partial<
			GradeLineFormData
		>,
		units: getUnits(state),
		grades: getBunkerGrades(state)
	}),
	{
		closeModal,
		setEditableGradeId
	}
)(
	reduxForm<GradeLineFormData, GradeLineFormBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.gradeLine,
		validate,
		destroyOnUnmount: true,
		forceUnregisterOnUnmount: true
	})(GradeLineForm)
);
