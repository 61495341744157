import { Success } from 'typescript-fsa';
import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import { ResetPortCallEventPayload } from '../actions';
import { FetchStatus } from 'services/api/apiTypes';

const getSetUpdateStatuses = (
	state: PortCallOperationsState,
	eventId: string,
	status: FetchStatus
) => {
	return {
		...state.updateStatuses,
		events: {
			...state.updateStatuses.events,
			[eventId]: status
		}
	};
};

export const onResetPortCallEvent = (
	state: PortCallOperationsState,
	action: Success<ResetPortCallEventPayload, void>
) => {
	const { portCallId, eventId, comment, realDatePlt } = action.params;
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		state.updateStatuses.events[eventId]
	);

	const currentEvent =
		state.eventSectionsByPortCallId[portCallId].eventsById[eventId];
	const event = {
		...currentEvent,
		datePlt: realDatePlt,
		comment,
		error: {},
		// changing token to apply changes of the datepicker value
		// (causes its rerendering)
		concurrencyToken: 0
	};

	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: event
				}
			}
		},
		updateStatuses,
		error: null
	};
};
