import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { keys } from 'lodash';
import {
	retrieveFinance,
	moveJobFinance,
	moveJobFinanceAsync
} from '../actions';
import { notify } from 'store/notifications/actions';
import { MoveJobFinanceRequest as request } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import Matomo from 'services/analytics/adapters/Matomo';

const apiCall = Api.Finance.moveJobFinance;

enum ErrorCodeEnum {
	NoBankDetails
}

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(moveJobFinanceAsync.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(retrieveFinance(actionParams));
		yield put(
			moveJobFinanceAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Finances.Submit',
			name: `Finance has been submitted`,
			value: 1
		});
	} catch (error) {
		if (error.code === ErrorCodeEnum.NoBankDetails) {
			yield put(notify.warning(error.message));
		}
		// intentionally not dispatching `failed` for 400 http status code
		// reactToChanges watcher is listening to failed action in order to
		// add warning notification with `refresh` button, thus simulating concurrency status code error, thus showing that finance is outdated.
		// considering that 412 isnt always an indicator of an outdated version, also 400/500 are possible, but also not always :)
		// so here when we get `ValidationError` field, `failed` isn't dispatched to prevent showing notifications from `reactToChanges` watcher
		if (
			error.response &&
			keys(error.response.data).includes('ValidationError')
		) {
			const defaultMessage =
				'Some required data is missing. Please make sure all Disbursment accounts and services have all required information filled.';

			// in this situation, showing `correlationId` isn't needed since it is bussiness error
			yield put(
				notify.error(error.response.data.ValidationError[0] || defaultMessage)
			);
		} else {
			yield put(
				moveJobFinanceAsync.failed({
					error,
					params: actionParams
				})
			);
		}
		// Retrieve finance data in order to show to user why valdation is failed in case when someone has already changed finance
		yield put(retrieveFinance({ ...actionParams, isSilent: true }));
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(moveJobFinance.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
