import Api from 'services/api';
import { retrievePrincipalServicesAsync as async } from '../actions/retrievePrincipalServices';
import {
	RetrievePrincipalServicesPayload,
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse as response
} from 'services/api/finance/financeServiceTypes';
import { call } from 'redux-saga/effects';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';

const apiCall = Api.Finance.retrievePrincipalServices;

const commonExecutor = makeDefaultExecutor<
	RetrievePrincipalServicesPayload,
	response,
	Error
>({
	api: apiCall,
	async
});

export const executor = function*(
	params: Partial<RetrievePrincipalServicesRequest>
) {
	const commonRequest: RetrievePrincipalServicesRequest = yield call(
		getActivePortCallAndPortJobIdsExecutor,
		params
	);

	yield call(commonExecutor, commonRequest);
};

export default makeTakeEveryWatcher<
	Partial<RetrievePrincipalServicesRequest>,
	response,
	Error
>(
	{
		async
	},
	executor
);
