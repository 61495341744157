import { ThreadReducerState } from './../threadState';
import {
	RetrieveThreadByIdParams,
	ThreadItem
} from 'services/api/threads/threadsServiceTypes';
import { ThreadsState } from 'store/threads/threadsState';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
/**
 * Global Threads Reducer
 */
export const onRetrieveThreadStarted = (
	state: ThreadsState,
	{ isSilent }: RetrieveThreadByIdParams
): ThreadsState => ({
	...state,
	fetchStatuses: isSilent
		? state.fetchStatuses
		: {
				...state.fetchStatuses,
				retrieving: FetchStatus.PENDING
		  }
});
export const onRetrieveThreadFailed = (
	state: ThreadsState,
	action: Failure<RetrieveThreadByIdParams, Error>
): ThreadsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.FAILURE
	},
	error: action.error
});
export const onRetrieveThreadSuccess = (
	state: ThreadsState,
	action: Success<RetrieveThreadByIdParams, ThreadItem>
): ThreadsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.SUCCESS
	},
	context: {
		...state.context,
		activeThreadId: action.result.id
	}
});
/**
 * Single Thread Reducer
 */
export const onRetrieveThreadByIdStarted = (
	state: ThreadReducerState,
	{ isSilent }: RetrieveThreadByIdParams
): ThreadReducerState => ({
	...state,
	fetchStatuses: isSilent
		? state.fetchStatuses
		: {
				...state.fetchStatuses,
				retrieving: FetchStatus.PENDING
		  }
});
export const onRetrieveThreadByIdFailed = (
	state: ThreadReducerState,
	action: Failure<RetrieveThreadByIdParams, Error>
): ThreadReducerState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.FAILURE
	},
	error: action.error
});
export const onRetrieveThreadByIdSuccess = (
	state: ThreadReducerState,
	action: Success<RetrieveThreadByIdParams, ThreadItem>
): ThreadReducerState => ({
	...state,
	item: action.result,
	newPermissions: [...action.result.permissions],
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.SUCCESS
	},
	error: false,
	context: {
		lastConcurrencyToken: action.result.concurrencyToken
	}
});
