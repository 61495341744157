import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { editUploadedDocumentCommentAsync } from '../actions';
import { EditUploadedDocumentCommentPayload } from '../actions/editUploadedDocumentComment';
import { getActivePortCallId } from '../../portcalls/selectors';
import { getUploadedDocumentConcurrencyToken } from '../selectors';
import Api from 'services/api';

export function* editUploadedDocumentCommentWorker({
	payload
}: Action<EditUploadedDocumentCommentPayload>): SagaIterator {
	yield put(editUploadedDocumentCommentAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const concurrencyToken = yield select(
		getUploadedDocumentConcurrencyToken,
		payload.documentId
	);
	const updateRequest = { ...payload, portCallId };
	try {
		const response = yield call(
			Api.PortCallDocuments.editUploadedDocumentComment,
			{
				...updateRequest,
				concurrencyToken
			}
		);
		yield put(
			editUploadedDocumentCommentAsync.done({
				result: response.data,
				params: updateRequest,
				response
			})
		);
	} catch (error) {
		yield put(
			editUploadedDocumentCommentAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* editUploadedDocumentCommentWatcher(): SagaIterator {
	yield takeLatest(
		editUploadedDocumentCommentAsync.type,
		editUploadedDocumentCommentWorker
	);
}
