import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveServiceErrorsAsync } from '../actions/retrieveServiceErrors';
import { RetrieveServiceErrorsResponse } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';

export default makeTakeEveryWatcher<
	null,
	RetrieveServiceErrorsResponse,
	Error
>({
	api: Api.Finance.retrieveServiceErrors,
	async: retrieveServiceErrorsAsync
});
