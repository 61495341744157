import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';
import {
	EditUploadedDocumentCommentRequest,
	EditUploadedDocumentCommentResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const onEditUploadedDocumentCommentStarted = (
	state: PortCallDocumentsState,
	params: EditUploadedDocumentCommentRequest
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		editUploadedById: {
			[params.documentId]: FetchStatus.PENDING
		}
	}
});

const onEditUploadedDocumentCommentSuccess = (
	state: PortCallDocumentsState,
	{
		params,
		result
	}: Success<
		EditUploadedDocumentCommentRequest,
		EditUploadedDocumentCommentResponse
	>
): PortCallDocumentsState => ({
	...state,
	uploadedById: {
		...state.uploadedById,
		[params.documentId]: {
			// metadata isn't returned
			...state.uploadedById[params.documentId],
			// update document (`comment`/`updatedOn` are changed)
			...result
		}
	},
	fetchStatuses: {
		...state.fetchStatuses,
		editUploadedById: {
			[params.documentId]: FetchStatus.SUCCESS
		}
	}
});

const onEditUploadedDocumentCommentFailed = (
	state: PortCallDocumentsState,
	{ params }: Failure<EditUploadedDocumentCommentRequest, Error>
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		editUploadedById: {
			[params.documentId]: FetchStatus.FAILURE
		}
	}
});

export {
	onEditUploadedDocumentCommentStarted,
	onEditUploadedDocumentCommentSuccess,
	onEditUploadedDocumentCommentFailed
};
