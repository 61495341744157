import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallState as State, initialState } from '../portCallState';
import { Success } from 'typescript-fsa';
import {
	RetrievePortCallsAlertsResponse as Response,
	RetrievePortCallsAlertsRequest as Request
} from 'services/api/portCalls/portCallsServiceTypes';
import { keyBy } from 'lodash';

const doneReducer = (
	state: State,
	{ params, result }: Success<Request, Response>
): State => {
	if (!result.length) {
		return {
			...state,
			alerts: initialState.alerts
		};
	}
	// we request only for 1 portcall
	const group = result[0];
	if (group.portCallId !== params.portCallIds[0]) {
		return state;
	}
	return {
		...state,
		alerts: {
			...group,
			portJobs: keyBy(group.portJobs, job => job.portJobId)
		}
	};
};

export const onRetrievePortCallAlerts = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'alerts', { doneReducer });
