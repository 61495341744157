import { MoveServiceRequest } from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';
export interface OpenMoveServiceModalPayload {
	serviceId: string;
	serviceCode: string;
}

const MOVE_SERVICE = 'MOVE_SERVICE';
const MOVE_SERVICE_MODAL_OPEN = 'MOVE_SERVICE_MODAL_OPEN';
const MOVE_SERVICE_MODAL_CLOSE = 'MOVE_SERVICE_MODAL_CLOSE';

export const moveServiceAsync = actionCreator.async<
	MoveServiceRequest,
	null,
	Error
>(MOVE_SERVICE, {
	templateParams: {
		entityName: 'Move Service'
	}
});

export const moveService = actionCreator<MoveServiceRequest>(MOVE_SERVICE);
export const openMoveServiceModal = actionCreator<OpenMoveServiceModalPayload>(
	MOVE_SERVICE_MODAL_OPEN
);
export const closeMoveServiceModal = actionCreator(MOVE_SERVICE_MODAL_CLOSE);
