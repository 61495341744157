import {
	RotationStep,
	RotationStepEvent,
	OperationUnit,
	Terminal,
	Berth,
	CustodyAgent,
	CustodyTransferType,
	CustodyAgentType,
	RotationStepEventParentType,
	RotationEntity,
	Revision,
	VPMetadataActionCode
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Metadata } from 'app-types';

export interface ReducerRotationStep extends RotationStep {
	events: string[];
	units: string[];
}

export interface ReducerOperationUnit extends OperationUnit {
	events: string[];
	allocatedId: string;
}

export interface ReducerRotationStepEvent extends RotationStepEvent {
	parentId: string;
	parentType: RotationStepEventParentType;
}

/** HashMap of RotationSteps by id */
export interface RotationStepsById {
	[id: string]: ReducerRotationStep;
}
/** HashMap of Events by id */
export interface EventsById {
	[id: string]: ReducerRotationStepEvent;
}
/** HashMap of OperationUnits by id */
export interface OperationUnitsById {
	[id: string]: ReducerOperationUnit;
}

export interface MergeOperationUnitPayload {
	childOperationUnitId: string;
}

export interface SplitOperationUnitPayload {
	parentOperationUnitId: string;
	splitOperation: SplitOperationUnit;
}

export interface SplitOperationUnit {
	commodityQuantity: string;
	shipper: {
		key: string;
		label: string;
	};
	isTransshipment: boolean;
	transshipmentVesselName: string | null;
}

export interface UpdateBuoyRequest {
	rotationStepId: string;
	buoy: RotationEntity;
}

export interface UpdateCanalRequest {
	rotationStepId: string;
	canal: RotationEntity;
}

export interface SetVPEventTimeParams {
	eventId: string;
	time: string | null;
}

export interface UpdateAnchorageRequest {
	rotationStepId: string;
	anchorage: RotationEntity;
}

export interface UpdateBerthNameRequest {
	rotationStepId: string;
	berth: RotationEntity;
}

export interface UpdateTerminalRequest {
	rotationStepId: string;
	terminal: RotationEntity;
}

interface TerminalById {
	[i: string]: Terminal[];
}

interface BerthByTerminalId {
	[i: string]: Berth[];
}

interface SplittedUnitsIdsByParentUnitId {
	[id: string]: string[];
}

export interface CustodyTransfer {
	rotationStepId: string;
	linePosition: CustodyTransferType;
}

export interface CustodyLineRotationStep {
	position: number;
	rotationStepId: string;
	agentMode: CustodyAgentType;
	hasCustodyLine: boolean;
}

export interface VesselProgrammeState {
	readonly portCallId: string | null;
	readonly draggedOperationUnitId: string;
	readonly berthByTerminalId: BerthByTerminalId;
	readonly canEdit: boolean;
	readonly context: {
		readonly activePOIId: string;
		readonly showErrorPage: boolean;
	};
	readonly comment: string;
	readonly currentAgentRole: CustodyAgentType;
	readonly custodyAgents: CustodyAgent[];
	readonly custodyTransfer: CustodyTransfer | null;
	readonly edited: boolean;
	readonly editMode: boolean;
	readonly eventsById: EventsById;
	readonly fetchStatuses: {
		readonly addPOI: FetchStatus;
		readonly retrieve: FetchStatus;
		readonly save: FetchStatus;
		readonly delete: FetchStatus;
		readonly terminals: FetchStatus;
		readonly berths: FetchStatus;
		readonly commit: FetchStatus;
		readonly events: FetchStatus;
		readonly track: FetchStatus;
		readonly acknowledge: FetchStatus;
		readonly acknowledgeWithComment: FetchStatus;
		readonly updateVPFromFinance: FetchStatus;
		readonly downloadRevisionById: {
			[id: string]: FetchStatus;
		};
	};
	readonly mergeModalOperationUnitId?: string;
	readonly operationUnitsById: OperationUnitsById;
	readonly orderedRotationSteps: string[];
	readonly revisions: Revision[];
	readonly allRevisions: Revision[];
	readonly lastRevision?: Revision;
	readonly rotationStepsById: RotationStepsById;
	readonly rotationStepsPositionedToCustodyLine: CustodyLineRotationStep[];
	readonly splitModalOperationUnitId?: string;
	readonly splittedUnitsByParentUnitId: SplittedUnitsIdsByParentUnitId;
	readonly terminalById: TerminalById;
	readonly saveError?: {
		error: Error;
		errorDescriprion: string[];
	};
	readonly updatedOn: string;
	readonly updatedOnPlt: string;
	readonly saveEnabled: boolean;
	readonly hash: string | null;
	readonly metadata: Metadata<VPMetadataActionCode> | null;
	readonly concurrencyToken?: number;
	readonly pendingAlerts: string[];
	readonly isReady: boolean;
}

export const initialState: VesselProgrammeState = {
	portCallId: null,
	draggedOperationUnitId: '',
	berthByTerminalId: {},
	context: {
		activePOIId: '',
		showErrorPage: false
	},
	canEdit: false,
	comment: '',
	currentAgentRole: CustodyAgentType.NONE,
	custodyAgents: [],
	custodyTransfer: null, // no custody line
	edited: false,
	editMode: false,
	eventsById: {},
	fetchStatuses: {
		addPOI: FetchStatus.IDLE,
		retrieve: FetchStatus.IDLE,
		save: FetchStatus.IDLE,
		delete: FetchStatus.IDLE,
		terminals: FetchStatus.IDLE,
		berths: FetchStatus.IDLE,
		events: FetchStatus.IDLE,
		track: FetchStatus.IDLE,
		commit: FetchStatus.IDLE,
		acknowledge: FetchStatus.IDLE,
		acknowledgeWithComment: FetchStatus.IDLE,
		updateVPFromFinance: FetchStatus.IDLE,
		downloadRevisionById: {}
	},
	operationUnitsById: {},
	orderedRotationSteps: [],
	revisions: [],
	allRevisions: [],
	rotationStepsById: {},
	rotationStepsPositionedToCustodyLine: [],
	splittedUnitsByParentUnitId: {},
	terminalById: {},
	updatedOn: '',
	updatedOnPlt: '',
	saveEnabled: true,
	hash: null,
	metadata: null,
	pendingAlerts: [],
	isReady: false
};
