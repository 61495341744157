import * as React from 'react';
import classNames from 'classnames';
import AntTable from 'antd/lib/table/Table';
import { TableProps as AntTableProps } from 'antd/lib/table/interface';
import TableFilterIcon from './TableFilterIcon';
import { setCommonColumnWidth } from './Table.func';
import styles from './Table.module.scss';

export interface TableProps<T = {}> extends AntTableProps<T> {
	whiteRows?: boolean;
	showEmptyText?: boolean;
	locale?: {
		emptyText: React.ReactNode;
	};
	/** Normalize the width of each column, relative to the amount of existing columns */
	normalizeColumns?: boolean;
	/**
	 * If any indication of expand functionality has to be removed,
	 * works together with `expandedRowRender`
	 */
	expandable?: boolean;
}

export class Table<T = {}> extends React.PureComponent<TableProps<T>> {
	static Column = AntTable.Column;
	static ColumnGroup = AntTable.ColumnGroup;
	static FilterIcon = TableFilterIcon;

	static defaultProps: Partial<TableProps> = {
		rowKey: 'id',
		showEmptyText: true,
		expandable: false
	};

	render() {
		const {
			showEmptyText,
			whiteRows,
			columns,
			normalizeColumns,
			...tableProps
		} = this.props;

		const cols = normalizeColumns ? setCommonColumnWidth<T>(columns) : columns;

		const isExpandedRowStatic =
			Boolean(this.props.expandedRowRender) && !this.props.expandable;
		const expandedRowStaticProps = isExpandedRowStatic
			? {
					expandIconAsCell: false,
					expandIcon: () => null,
					defaultExpandAllRows: true
			  }
			: {};

		return (
			<AntTable
				pagination={false}
				columns={cols}
				{...expandedRowStaticProps}
				{...tableProps}
				className={classNames(
					styles.root,
					{
						[styles.whiteRows]: whiteRows,
						[styles.hideHeaderOfEmptyTable]: !this.props.showHeader,
						[styles.hidePlaceholderOfEmptyTable]: !showEmptyText,
						[styles.cursorPointer]: Boolean(
							this.props.onRowClick || this.props.onRow
						),
						[styles.isExpandedRowStatic]: isExpandedRowStatic
					},
					this.props.className
				)}
				rowKey={this.props.rowKey}
			/>
		);
	}
}

export default Table;
