import { DownloadJobFinanceRevisionActionParams } from '../actions/downloadJobFinanceRevision';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from 'store/finance/financeState';

export const onDownloadJobFinanceRevision = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DownloadJobFinanceRevisionActionParams,
	null
>(
	'fetchStatuses',
	(
		params: DownloadJobFinanceRevisionActionParams
	): keyof FinanceState['fetchStatuses'] => {
		const id = params.revisionId;
		return `downloadRevisionById.${id}` as keyof FinanceState['fetchStatuses'];
	}
);
