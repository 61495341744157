import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	updateCostSavings,
	updateCostSavingsAsync
} from '../actions/updateCostSavings';
import { UpdateCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';

import { Action } from 'typescript-fsa';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { resetCostSavingsContext, retrieveSavings } from '../actions';
import {
	getinitialStateId,
	getPreparedAddCostSavingsData
} from '../savingsSelectors';
import { COST_SAVINGS_MODAL } from 'sections/PortCall/Savings/SavingsGrid/Constants';
import { closeModal } from 'store/modals/actions';

const apiCall = Api.Savings.updateCostSavings;

export function* updateCostSavingsExecutor(
	actionParams: UpdateCostSavingsRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(updateCostSavingsAsync.started(actionParams));
	try {
		const formData = yield select(getPreparedAddCostSavingsData);
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const costSavingsId = yield select(getinitialStateId);

		const request: UpdateCostSavingsRequest = {
			portCallId,
			jobCode,
			data: { portJobCode: jobCode, id: costSavingsId, ...formData }
		};
		const response = yield call(api, request);

		yield put(
			updateCostSavingsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(retrieveSavings({ portCallId, jobCode }));
	} catch (error) {
		yield put(
			updateCostSavingsAsync.failed({
				error,
				params: actionParams
			})
		);
	} finally {
		yield put(resetCostSavingsContext());
		yield put(closeModal(COST_SAVINGS_MODAL));
	}
}

export function* worker({
	payload
}: Action<UpdateCostSavingsRequest>): SagaIterator {
	yield call(updateCostSavingsExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(updateCostSavings.type, worker);
}
