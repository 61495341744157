import actionCreatorFactory from 'typescript-fsa';
import { CreateThreadRequest } from 'services/api/threads/threadsServiceTypes';
import { ExecutorActionPayload } from '../sagas/createNewThreadSaga';

const actionCreator = actionCreatorFactory();
const ACTION_NAME = 'threads/CREATE_NEW_THREAD';

export const createNewThreadAsync = actionCreator.async<
	CreateThreadRequest,
	{ id: string },
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Thread'
	}
});

export const createNewThread = actionCreator<ExecutorActionPayload>(
	createNewThreadAsync.type
);

export const createNewThreadAction = {
	action: createNewThread,
	async: createNewThreadAsync
};
