import {
	RetrievePrincipalsForMainPrincipalRequest,
	RetrieveCompaniesResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_PRINCIPALS_FOR_MAIN_PRINCIPAL';

export const retrievePrincipalsForMainPrincipalAsync = actionCreator.async<
	RetrievePrincipalsForMainPrincipalRequest,
	RetrieveCompaniesResponse,
	Error
>(ACTION_NAME);

export const retrievePrincipalsForMainPrincipal = actionCreator<
	RetrievePrincipalsForMainPrincipalRequest
>(ACTION_NAME);

export const resetPrincipalsForMainPrincipal = actionCreator(
	`RESET_${ACTION_NAME}`
);
