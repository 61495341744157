import actionCreator from '../portCallDocumentsActionCreator';
import {
	EditUploadedDocumentCommentRequest,
	EditUploadedDocumentCommentResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'EDIT_UPLOADED_DOCUMENT_COMMENT';

export type EditUploadedDocumentCommentPayload = Pick<
	EditUploadedDocumentCommentRequest,
	'portCallId' | 'documentId' | 'data'
>;

export const editUploadedDocumentCommentAsync = actionCreator.async<
	EditUploadedDocumentCommentPayload,
	EditUploadedDocumentCommentResponse,
	Error
>(ACTION_NAME);

export const editUploadedDocumentComment = actionCreator<
	EditUploadedDocumentCommentPayload
>(ACTION_NAME);
