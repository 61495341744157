import { Action } from 'typescript-fsa';
import { select, takeLatest } from 'redux-saga/effects';

import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	deleteSupplierDocumentPageAsync,
	deleteSupplierDocumentPageActionParams
} from '../actions/deleteSupplierInvoiceDocument';
import Api from 'services/api';
import { deleteDocumentPageWorker } from 'store/documents/sagas/deleteDocumentPageSaga';
import { DeleteSupplierDocumentPageRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

function* worker({ payload }: Action<deleteSupplierDocumentPageActionParams>) {
	const portCallId: string = yield select(getActivePortCallId);
	const jobCode: string = yield select(getActivePortJobCode);

	yield deleteDocumentPageWorker<
		DeleteSupplierDocumentPageRequest,
		null,
		Error
	>({
		/**
		 ** Here count is always sending more than 1 because we wont delete the original
		 ** page of the supplier invoice.
		 */
		count: 2,
		returnUrl: `/portcalls/${portCallId}/jobs/${jobCode}/finance`,
		params: {
			...payload
		},
		async: deleteSupplierDocumentPageAsync,
		api: Api.SupplierInvoice.deleteSupplierDocumentPage
	});
}

export default function*() {
	yield takeLatest(deleteSupplierDocumentPageAsync.type, worker);
}
