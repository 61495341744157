import { Action } from 'typescript-fsa';
import { takeLatest, put, all } from 'redux-saga/effects';
import { change } from 'redux-form';

import { uploadConfigurationFileDone } from '../actions';
import { cancelUploadAndProcessDocument } from 'store/documents/actions';

import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

import {
	CONFIGURATION_FILE_FORM_NAME,
	ConfigurationFileFormField
} from '../configurationFilesTypes';

function* worker({
	payload: {
		document: { id, name },
		isDoneSuccess
	}
}: Action<UploadDocumentDoneResponse>) {
	if (!isDoneSuccess) {
		return;
	}
	yield all([
		put(
			change(
				CONFIGURATION_FILE_FORM_NAME,
				ConfigurationFileFormField.DOCUMENT_ID,
				id
			)
		),
		put(
			change(
				CONFIGURATION_FILE_FORM_NAME,
				ConfigurationFileFormField.FILE_NAME,
				name
			)
		)
	]);
	yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
}

export default function*() {
	yield takeLatest(uploadConfigurationFileDone.type, worker);
}
