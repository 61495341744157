import { SanitizeHtmlOptions } from 'sanitize-html';
import { imageParser } from './HtmlSanitizer.func';
import { FORBIDDEN_TAGS } from 'app-constants';

// Used for all cases: in RTE/Message
// Before editing, make sure the changes should be applied globally
export const sanitizeOptions: SanitizeHtmlOptions = {
	allowedTags: false,
	allowedAttributes: false,
	transformTags: { img: imageParser },
	exclusiveFilter: frame => {
		return FORBIDDEN_TAGS.includes(frame.tag);
	}
};
