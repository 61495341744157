import Api from 'services/api';
import { retrieveRefreshDirectBillAsync as asyncAction } from '../actions';
import {
	RetrieveRefreshDirectBillResponse,
	RetrieveRefreshDirectBillRequest
} from 'services/api/finance/financeServiceTypes';

import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	getActivePortCallId,
	getEOSPorETAdate
} from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.RetrieveRefreshDirectBill;

export default makeTakeLatestWatcher<
	RetrieveRefreshDirectBillRequest,
	RetrieveRefreshDirectBillResponse,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const arrivalDate = yield select(getEOSPorETAdate);
		const request = {
			portCallId,
			jobCode,
			arrivalDate
		};
		return request;
	}
});
