import Api from 'services/api';
import { retrievePortCallCompanyVesselsAsync } from 'store/companyVessels/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import {
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse
} from 'services/api/portCall/portCallServiceTypes';
export default makeTakeLatestWatcher<
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse,
	Error
>({
	api: Api.PortCall.retrievePortCallCompanyVessels,
	async: retrievePortCallCompanyVesselsAsync
});
