import * as React from 'react';
import { DataFetcher } from 'utils/components';
import { Error403 } from 'components';
import { connect } from 'react-redux';
import { EntityPermissionFetcher } from 'containers';
import { EntityPermissionType } from 'services/api/permissions/permissionsServiceTypes';
import { retrieveEditPortCallData } from 'store/portCall/actions';
import { getRetrieveEditPortCallDataFetchStatus } from 'store/portCall/portCallSelectors';
import { getValues } from 'store/form/selectors';
import {
	EditPortCallFormData,
	EDIT_PORTCALL_FORM
} from 'store/portCall/sagas/editPortCallData/editPortCallDataTypes';
import { FetchStatus } from 'services/api/apiTypes';
import EditPortCallForm from './EditPortCallForm';
import { getIsPossibleToUpdateActivePortCallInTermsOfStatus } from 'store/portCall/selectors';
import { AppState } from 'store-types';

interface EditPortCallPermissionWrapperProps {
	portCallId: string;
	isUpdatePortCallPossible: boolean;
	formValues: EditPortCallFormData;
	retrieveFetchStatus: FetchStatus;
	retrieveFormData: () => void;
}

const PermissionError = ({
	canUpdate,
	isUpdatePortCallPossible
}: {
	canUpdate: boolean;
	isUpdatePortCallPossible: boolean;
}) => {
	if (!canUpdate) {
		return <Error403 />;
	}
	if (!isUpdatePortCallPossible) {
		return <Error403 description="Port Call cannot be edited" />;
	}
	return null;
};

const EditPortCallPermissionWrapper = ({
	isUpdatePortCallPossible,
	portCallId,
	retrieveFetchStatus,
	formValues,
	retrieveFormData
}: EditPortCallPermissionWrapperProps) => (
	<EntityPermissionFetcher
		entityType={EntityPermissionType.PORTCALL}
		entityKey={portCallId}
	>
		{({ canUpdate }) => (
			<>
				<PermissionError
					canUpdate={canUpdate}
					isUpdatePortCallPossible={isUpdatePortCallPossible}
				/>
				{canUpdate && isUpdatePortCallPossible && (
					<DataFetcher
						fetchStatus={retrieveFetchStatus}
						data={formValues}
						dispatch={retrieveFormData}
					>
						{() => <EditPortCallForm />}
					</DataFetcher>
				)}
			</>
		)}
	</EntityPermissionFetcher>
);

export default connect(
	(state: AppState) => ({
		isUpdatePortCallPossible: getIsPossibleToUpdateActivePortCallInTermsOfStatus(
			state
		),
		formValues: getValues<EditPortCallFormData>(state, EDIT_PORTCALL_FORM),
		retrieveFetchStatus: getRetrieveEditPortCallDataFetchStatus(state)
	}),
	(
		dispatch,
		{ portCallId }: Pick<EditPortCallPermissionWrapperProps, 'portCallId'>
	) => ({
		retrieveFormData: () => {
			dispatch(retrieveEditPortCallData({ portCallId }));
		}
	})
)(EditPortCallPermissionWrapper);
