import {
	RetrievePrincipalServicesResponse,
	RetrievePrincipalServicesPayload
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'RETRIEVE_PRINCIPAL_SERVICES';

export const retrievePrincipalServicesAsync = actionCreator.async<
	RetrievePrincipalServicesPayload,
	RetrievePrincipalServicesResponse,
	Error
>(ACTION_NAME);

export const retrievePrincipalServices = actionCreator<
	RetrievePrincipalServicesPayload
>(ACTION_NAME);

const RESET_PRINCIPAL_SERVICES = 'RESET_PRINCIPAL_SERVICES';
export const resetPrincipleServices = actionCreator<{
	serviceId: string;
}>(RESET_PRINCIPAL_SERVICES);
