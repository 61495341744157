import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Select,
	InputNumber,
	TextArea,
	Autocomplete
} from 'components/antd';
import { Flex } from 'components';
import { Justify } from 'components/types';
import { AppState } from 'store-types';
import {
	TimeSavingsFormData,
	TimeSavingsFormFields,
	TimeSavingsFormLabels,
	TIME_SAVINGS_FORM,
	TIME_SAVINGS_MODAL
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import Api from 'services/api';
import { InjectedFormProps, reduxForm, change } from 'redux-form';
import {
	DEFAULT_AUTOCOMPLETE_PLACEHOLDER,
	DEFAULT_REDUX_FORM_CONFIG
} from 'app-constants';
import { EditDirectBillMultipleAutocomplete } from 'sections/Administration/DataManagement/DirectBills/DirectBill/EditDirectBill/Fields/EditDirectBillMultipleAutocomplete';
import styles from './TimeSavingsModal.module.scss';
import validate from './validate';
import { Operation } from 'services/api/savings/savingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import {
	addTimeSavings,
	resetTimeSavingsContext,
	updateTimeSavings
} from 'store/savings/actions';
import { FetchStatus } from 'services/api/apiTypes';
import {
	getAddTimeSavingFetchStatus,
	getUpdateTimeSavings,
	getinitialStateId
} from 'store/savings/savingsSelectors';

interface TimeSavingsFormBaseProps {
	activePortCallId: string;
	activePortJobCode: string;
	initialValues: Partial<TimeSavingsFormData>;
	initialStateId: string;
	isLoading: boolean;
	closeModal: typeof closeModal;
	change: typeof change;
	addTimeSavings: typeof addTimeSavings;
	updateTimeSavings: typeof updateTimeSavings;
	resetTimeSavingsContext: typeof resetTimeSavingsContext;
}

export type TimeSavingsFormProps = InjectedFormProps<
	TimeSavingsFormData,
	TimeSavingsFormBaseProps
> &
	TimeSavingsFormBaseProps;

interface TimeSavingsFormState {
	operationList: Operation[];
}

class TimeSavingsForm extends Component<
	TimeSavingsFormProps,
	TimeSavingsFormState
> {
	constructor(props: TimeSavingsFormProps) {
		super(props);
		this.state = {
			operationList: []
		};
	}

	componentDidMount() {
		const { activePortCallId, activePortJobCode } = this.props;
		Api.Savings.retrieveOperationsValues().then(result => {
			this.setState(prev => ({
				...prev,
				operationList: [...result.data]
			}));
		});
		Api.Savings.retrievePortjobCurrencies({
			portCallId: activePortCallId,
			jobCode: activePortJobCode
		});
	}

	onCancel = () => {
		this.props.resetTimeSavingsContext();
		this.props.closeModal(TIME_SAVINGS_MODAL);
	};

	onTimeSavings = () => {
		const { initialStateId } = this.props;
		if (initialStateId) {
			this.props.updateTimeSavings();
		} else {
			this.props.addTimeSavings();
		}
	};

	render() {
		const { operationList } = this.state;
		const { isLoading, initialValues } = this.props;
		return (
			<>
				<Row>
					<Col sm={6}>
						<Autocomplete.ReduxFormItem
							name={TimeSavingsFormFields.CATEGORY}
							label={TimeSavingsFormLabels[TimeSavingsFormFields.CATEGORY]}
							required
							placeholder="Type to search"
							showSearch
							allowClear
							localSearch
							optionFilterProp="children"
							filterOption
							getPopupContainer={() => document.body}
							dropdownClassName={styles.currencySelect}
							disabled={isLoading}
							help
						>
							{operationList.map(operation => (
								<Select.Option
									key={operation.categoryCode}
									title={operation.categoryName}
								>
									{operation.categoryName}
								</Select.Option>
							))}
						</Autocomplete.ReduxFormItem>
					</Col>
					<Col sm={6}>
						<EditDirectBillMultipleAutocomplete
							name={TimeSavingsFormFields.SERVICES}
							label={TimeSavingsFormLabels[TimeSavingsFormFields.SERVICES]}
							placeholder={DEFAULT_AUTOCOMPLETE_PLACEHOLDER}
							disabled={isLoading}
							source={Api.DirectBills.searchServicesForAutocomplete}
							help
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Select.ReduxFormItem
							name={TimeSavingsFormFields.SAVING_BY}
							label={TimeSavingsFormLabels[TimeSavingsFormFields.SAVING_BY]}
							required
							getPopupContainer={() => document.body}
							disabled={isLoading}
							help
						>
							<Select.Option key={'LPA'} title="LPA">
								LPA
							</Select.Option>
							<Select.Option key={'Hub Operations'} title="Hub Operations">
								Hub Operations
							</Select.Option>
							<Select.Option key={'Hub Finance'} title="Hub Finance">
								Hub Finance
							</Select.Option>
							<Select.Option key={'Principal'} title="Principal">
								Principal
							</Select.Option>
						</Select.ReduxFormItem>
					</Col>
					<Col sm={2}>
						<InputNumber.ReduxFormItem
							name={TimeSavingsFormFields.TIME_SAVED}
							label={TimeSavingsFormLabels[TimeSavingsFormFields.TIME_SAVED]}
							placeholder="HHH"
							allowNegative={false}
							integerPart={3}
							decimalPart={0}
							required
							min={0}
							maxDigits={3}
							disabled={isLoading}
							help
						/>
					</Col>
					<Col sm={2}>
						<InputNumber.ReduxFormItem
							name={TimeSavingsFormFields.TIME_SAVED_MM}
							label={' '}
							placeholder="MM"
							allowNegative={false}
							integerPart={2}
							decimalPart={0}
							min={0}
							maxDigits={2}
							disabled={isLoading}
							help
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<TextArea.ReduxFormItem
							name={TimeSavingsFormFields.COMMENTS}
							label={TimeSavingsFormLabels[TimeSavingsFormFields.COMMENTS]}
							maxLength={1000}
							rows={3}
							disabled={isLoading}
							placeholder="Enter comment"
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button
								type="primary"
								transparent
								disabled={isLoading}
								onClick={() => this.onCancel()}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								disabled={!this.props.valid || this.props.pristine}
								onClick={() => this.onTimeSavings()}
								loading={isLoading}
							>
								{initialValues.hours ? 'Save Time Savings' : 'Add Time Savings'}
							</Button>
						</Flex>
					</Col>
				</Row>
			</>
		);
	}
}

const TimeSavingsReduxForm = reduxForm<
	TimeSavingsFormData,
	TimeSavingsFormBaseProps
>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: TIME_SAVINGS_FORM,
	validate
})(TimeSavingsForm);

const mapStateToProps = (state: AppState) => {
	const addTimeSavingsFetchStatus = getAddTimeSavingFetchStatus(state);
	const initialValues = getUpdateTimeSavings(state);
	const initialStateId = getinitialStateId(state);
	return {
		activePortCallId: getActivePortCallId(state),
		activePortJobCode: getActivePortJobCode(state),
		initialValues,
		initialStateId,
		isLoading: addTimeSavingsFetchStatus === FetchStatus.PENDING
	};
};

const mapDispatchToProps = {
	resetTimeSavingsContext,
	updateTimeSavings,
	closeModal,
	change,
	addTimeSavings
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TimeSavingsReduxForm);
