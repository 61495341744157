import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveSupplierInvoiceAsync } from './../actions/retrieveSupplierInvoice';
import Api from 'services/api';
import {
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse,
	Error
>({
	async: retrieveSupplierInvoiceAsync,
	api: Api.SupplierInvoice.retrieveSupplierInvoice
});
