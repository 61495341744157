import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import { DeletePortCallEventRequest } from 'services/api/portCall/portCallServiceTypes';

export const onRemovePortCallEventFromList = (
	state: PortCallOperationsState,
	{ portCallId, eventId }: DeletePortCallEventRequest
): PortCallOperationsState => {
	const eventsByIdWithRemovedEvent = {
		...state.eventSectionsByPortCallId[portCallId].eventsById
	};
	delete eventsByIdWithRemovedEvent[eventId];

	const eventsIdsBySectionId =
		state.eventSectionsByPortCallId[portCallId].eventsIdsBySectionId;
	const eventsIdsBySectionIdWithRemovedEvent = Object.entries(
		eventsIdsBySectionId
	).reduce(
		(result, [sectionKey, eventIdsInSection]) => ({
			...result,
			[sectionKey]: eventIdsInSection.filter(id => id !== eventId)
		}),
		{}
	);

	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: eventsByIdWithRemovedEvent,
				eventsIdsBySectionId: eventsIdsBySectionIdWithRemovedEvent
			}
		}
	};
};
