/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse, AxiosError } from 'axios';

// for specific http codes error handling

export interface AxiosGeneralError extends AxiosError {
	response: AxiosResponse;
}

export interface AxiosConnectionError extends AxiosError {
	response: undefined;
}

// Bad Request - 400
export interface AxiosBadRequestErrorData {
	[key: string]: string[];
}
export interface AxiosBadRequestError extends AxiosError {
	response: AxiosResponse<AxiosBadRequestErrorData>;
}
// Concurrency error - 412
interface AxiosConcurencyErrorData {
	error: string;
}
export interface AxiosConcurencyError extends AxiosError {
	response: AxiosResponse<AxiosConcurencyErrorData>;
}

// Not Found 404
interface AxiosNotFoundErrorData {
	error: string;
	errorCode?: number;
}
export interface AxiosNotFoundError extends AxiosError {
	response: AxiosResponse<AxiosNotFoundErrorData>;
}

// Server error - 500
interface AxiosServerErrorData {
	id: string;
	error: string;
	errorCode?: number;
}
export interface AxiosServerError extends AxiosError {
	response: AxiosResponse<AxiosServerErrorData>;
}

export enum ErrorStatusCode {
	BAD_REQUEST = 400,
	SERVER = 500,
	CONCURRENCY = 412,
	NOT_FOUND = 404,
	FORBIDDEN = 403,
	UNAVAILABLE = 503
}
