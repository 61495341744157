import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ReverseSplitResponse } from 'services/api/finance/financeServiceTypes';
import { FinanceState } from '../financeState';

export const onReverseSplitService = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	{},
	ReverseSplitResponse
>('fetchStatuses', 'reverseSplitService');
