import { combineReducers } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	fundingRequestsInitialState,
	fundingsInitialState,
	preFundingRequestsInitialState
} from './financeFundingsState';
import {
	retrieveFundingRequestAsync,
	retrieveFundingPageDataAsync,
	updateFundingRequestAsync,
	retrieveNewFundingDataAsync,
	createFundingRequestAsync,
	resetFunding,
	setActiveFundingRequestId,
	updateFundingAsync,
	retrievePrefundingRequestAsync,
	resetPrefunding,
	sendPrefundingsRequestAsync,
	updatePrefundingStatusAsync
} from 'store/financeFundings/actions';
import {
	retrieveFundingRequest,
	retrieveFundingPageData,
	updateFundingRequest,
	onRetrieveFundingRequests,
	onRetrieveFundings,
	retrieveNewFundingData,
	createFundingRequest,
	onResetFunding,
	updateFunding,
	onSetActiveFundingRequestId,
	onRetrievePrefundingRequest,
	onResetPrefunding,
	onSendPrefundingsRequest,
	updatePrefundingStatus
} from 'store/financeFundings/reducers';
import { retrieveFinanceAsync } from 'store/finance/actions';

const financeFundingRequestsReducer = reducerWithInitialState(
	fundingRequestsInitialState
)
	// Retrieve page data
	.case(retrieveFundingPageDataAsync.started, retrieveFundingPageData.started)
	.case(retrieveFundingPageDataAsync.done, retrieveFundingPageData.done)
	.case(retrieveFundingPageDataAsync.failed, retrieveFundingPageData.failed)
	// Retrieve one funding
	.case(retrieveFundingRequestAsync.started, retrieveFundingRequest.started)
	.case(retrieveFundingRequestAsync.done, retrieveFundingRequest.done)
	.case(retrieveFundingRequestAsync.failed, retrieveFundingRequest.failed)
	// Retrieve data for creating new funding
	.case(retrieveNewFundingDataAsync.started, retrieveNewFundingData.started)
	.case(retrieveNewFundingDataAsync.done, retrieveNewFundingData.done)
	.case(retrieveNewFundingDataAsync.failed, retrieveNewFundingData.failed)
	// Update funding request
	.case(updateFundingRequestAsync.started, updateFundingRequest.started)
	.case(updateFundingRequestAsync.done, updateFundingRequest.done)
	.case(updateFundingRequestAsync.failed, updateFundingRequest.failed)
	// Update Prefunding Status
	.case(updatePrefundingStatusAsync.started, updatePrefundingStatus.started)
	.case(updatePrefundingStatusAsync.done, updatePrefundingStatus.done)
	.case(updatePrefundingStatusAsync.failed, updatePrefundingStatus.failed)
	// Create funding request
	.case(createFundingRequestAsync.started, createFundingRequest.started)
	.case(createFundingRequestAsync.done, createFundingRequest.done)
	.case(createFundingRequestAsync.failed, createFundingRequest.failed)
	// Retrieve funding requests from the finance
	.case(retrieveFinanceAsync.done, onRetrieveFundingRequests)
	// Reset funding
	.case(resetFunding, onResetFunding)
	// Set active funding id
	.case(setActiveFundingRequestId, onSetActiveFundingRequestId);

const financeFundingsReducer = reducerWithInitialState(fundingsInitialState)
	// Retrieve fundings from the finance
	.case(retrieveFinanceAsync.done, onRetrieveFundings)
	// Update funding
	.case(updateFundingAsync.started, updateFunding.started)
	.case(updateFundingAsync.done, updateFunding.done)
	.case(updateFundingAsync.failed, updateFunding.failed);

const financePrefundingsReducer = reducerWithInitialState(
	preFundingRequestsInitialState
)
	// Reset Prefunding
	.case(resetPrefunding, onResetPrefunding)
	.case(
		retrievePrefundingRequestAsync.started,
		onRetrievePrefundingRequest.started
	)
	.case(retrievePrefundingRequestAsync.done, onRetrievePrefundingRequest.done)
	.case(
		retrievePrefundingRequestAsync.failed,
		onRetrievePrefundingRequest.failed
	)
	.case(sendPrefundingsRequestAsync.started, onSendPrefundingsRequest.started)
	.case(sendPrefundingsRequestAsync.done, onSendPrefundingsRequest.done)
	.case(sendPrefundingsRequestAsync.failed, onSendPrefundingsRequest.failed);

export default combineReducers({
	fundingRequests: financeFundingRequestsReducer,
	fundings: financeFundingsReducer,
	preFundingRequests: financePrefundingsReducer
});
