import React from 'react';
import { getTimeAgo } from './TimeAgo.func';
interface TimeAgoProps {
	date: string;
	timeZone?: string;
	accurate?: boolean;
	className?: string;
}
const TimeAgo: React.FC<TimeAgoProps> = props => {
	return (
		<span className={props.className}>
			{getTimeAgo(props.date, props.timeZone, props.accurate)}
		</span>
	);
};

export default TimeAgo;
