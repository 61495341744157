import { all, fork } from 'redux-saga/effects';
import retrieveConfigurationCompaniesWatcher from './retrieveConfigurationCompaniesSaga';
import retrieveActiveCompanyVesselTypesWatcher from './retrieveActiveCompanyVesselTypesSaga';
import createVesselTypeForActiveCompanyWatcher from './createVesselTypeForActiveCompanySaga';
import initializeDataManagementWatcher from './initializeConfigurationSaga';
import initializeDataManagementGeneralDetailsWatcher from './initializeConfigurationGeneralDetailsSaga';
import initializeDataManagementEmailFragmentsWatcher from './initializeConfigurationEmailFragmentsSaga';
import updateConfigurationGeneralDetailsWatcher from './updateConfigurationGeneralDetails/updateConfigurationGeneralDetailsSaga';
import updateConfigurationEmailFragmentsWatcher from './updateConfigurationEmailFragments/updateConfigurationEmailFragmentsSaga';
import updateVesselTypeForActiveCompanyWatcher from './updateVesselTypeForActiveCompanySaga';
import deleteVesselTypeForActiveCompanyWatcher from './deleteVesselTypeForActiveCompanySaga';
import deleteVesselForActiveCompanyWatcher from './deleteVesselForActiveCompanySaga';
import uploadConfigurationPreferredAgentsFileWatcher from './uploadConfigurationPreferredAgentsFileSaga';
import retrieveMainPrincipalGroupsWatcher from './retrieveMainPrincipalGroupsSaga';
import retrievePrincipalIntegrationSettingsWatcher from './retrievePrincipalIntegrationSettingsSaga';
import retrieveConfigurationBenchmarkWatcher from './retrieveConfigurationBenchmarkSaga';
import updateConfigurationBenchmarkWatcher from './updateConfigurationBenchmarkSaga';
import retrieveConfigurationFeedbackManagementWatcher from './retrieveConfigurationFeedbackManagementSaga';
import retrieveEventListAndOpticVariablesWatcher from './retrieveEventListAndOpticVariablesSaga';
import createFeedbackManagementRequestWatcher from './createFeedbackManagementRequestSaga';
import retrieveFeedbackManagementGridWatcher from './retrieveFeedbackManagementGridSaga';
import uploadRequestFeedbackDocumentWatcher from './uploadRequestFeedbackDocumentSaga';
import deleteFeedbackManagementRequestWatcher from './deleteFeedbackManagementRequestSaga';
import downloadFeedbackManagementRequestWatcher from './downloadFeedbackManagementDocumentsSaga';
import updateConfigurationFeedbackManagementWatcher from './updateConfigurationFeedbackManagementSaga';
import updateFeedbackManagementGridWatcher from './updateFeedbackManagementGridSaga';

export default function*() {
	yield all([
		fork(retrieveConfigurationCompaniesWatcher),
		fork(initializeDataManagementWatcher),
		fork(initializeDataManagementGeneralDetailsWatcher),
		fork(initializeDataManagementEmailFragmentsWatcher),
		fork(updateConfigurationGeneralDetailsWatcher),
		fork(updateConfigurationEmailFragmentsWatcher),
		fork(retrieveActiveCompanyVesselTypesWatcher),
		fork(createVesselTypeForActiveCompanyWatcher),
		fork(updateVesselTypeForActiveCompanyWatcher),
		fork(deleteVesselTypeForActiveCompanyWatcher),
		fork(deleteVesselForActiveCompanyWatcher),
		fork(uploadConfigurationPreferredAgentsFileWatcher),
		fork(retrieveMainPrincipalGroupsWatcher),
		fork(retrievePrincipalIntegrationSettingsWatcher),
		fork(retrieveConfigurationBenchmarkWatcher),
		fork(updateConfigurationBenchmarkWatcher),
		fork(retrieveConfigurationFeedbackManagementWatcher),
		fork(createFeedbackManagementRequestWatcher),
		fork(retrieveFeedbackManagementGridWatcher),
		fork(deleteFeedbackManagementRequestWatcher),
		fork(downloadFeedbackManagementRequestWatcher),
		fork(retrieveEventListAndOpticVariablesWatcher),
		fork(uploadRequestFeedbackDocumentWatcher),
		fork(updateConfigurationFeedbackManagementWatcher),
		fork(updateFeedbackManagementGridWatcher)
	]);
}
