import { PortCallsState } from '../portCallsState';
import { FetchStatus } from 'services/api/apiTypes';
export function onAddPortCallStarted(state: PortCallsState): PortCallsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.PENDING
		}
	};
}
export function onAddPortCallSuccess(state: PortCallsState): PortCallsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.SUCCESS
		}
	};
}
export function onAddPortCallFailed(state: PortCallsState): PortCallsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.FAILURE
		}
	};
}
