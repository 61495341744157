import client from 'services/client';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	AddPOIRequest,
	AcknowledgeVPRequest,
	RetrieveVesselProgramme,
	SaveVesselProgrammeResponse,
	RetrieveTerminalsResponse,
	RetrieveBerthsResponse,
	RetrieveEventsRequest,
	DownloadVPRevisionRequest,
	AcknowledgeVPWithCommentRequest,
	UpdateVPFromFinanceRequest
} from './vesselProgrammeServiceTypes';
import { RetrieveDownloadDocumentResponse } from '../documents/documentsServiceTypes';

const retrieveVP = (
	portCallId: string,
	concurrencyToken?: number
): AxiosTypedPromise<ResultCollection<RetrieveVesselProgramme>> =>
	client.get(`/portcalls/${portCallId}/vessel-programme?expand=revisions`, {
		params: {
			expand: 'metadata',
			concurrencyToken
		}
	});

const retrieveEvents = ({
	poiType,
	operationType,
	mainCommodity
}: RetrieveEventsRequest) =>
	client.get(`portcalls/templates`, {
		params: {
			poiType,
			operationType,
			mainCommodity
		}
	});

const retrieveTerminals = (
	portId: string
): AxiosTypedPromise<ResultCollection<RetrieveTerminalsResponse>> =>
	client.get(`/ports/${portId}/terminals`, {
		params: { sortBy: 'name:asc' }
	});

const retrieveBerths = (
	portId: string,
	terminalId: string
): AxiosTypedPromise<ResultCollection<RetrieveBerthsResponse>> => {
	const terminal = terminalId ? `${terminalId}/` : '';
	return client.get(`/ports/${portId}/terminals/${terminal}berths`, {
		params: { sortBy: 'name:asc' }
	});
};

const saveVP = ({
	portCallId,
	vesselProgramme
}: {
	portCallId: string;
	vesselProgramme: RetrieveVesselProgramme;
}): AxiosTypedPromise<SaveVesselProgrammeResponse> =>
	client.put(`/portcalls/${portCallId}/vessel-programme`, vesselProgramme);

const acknowledgeVP = ({ portCallId, revisionId }: AcknowledgeVPRequest) =>
	client.post(
		`/portcalls/${portCallId}/vessel-programme/acknowledge/${revisionId}`
	);

const acknowledgeVPWithComment = ({
	portCallId,
	revisionId,
	comment
}: AcknowledgeVPWithCommentRequest) =>
	client.post(
		`/portcalls/${portCallId}/vessel-programme/acknowledge/${revisionId}/comment`,
		{ comment }
	);

const trackVP = (portCallId: string) =>
	client.patch(`/portcalls/${portCallId}/vessel-programme/track`);

const commitVP = (portCallId: string) =>
	client.patch(`/portcalls/${portCallId}/vessel-programme/commit`);

const addPOI = ({
	portcallId,
	poiType,
	previous: { type: prevType },
	next: { type: nextType }
}: AddPOIRequest) =>
	client.get(
		`/portcalls/${portcallId}/vessel-programme/rotation-steps-template`,
		{
			params: {
				poiType,
				beforeRotationStepType: prevType,
				afterRotationStepType: nextType
			}
		}
	);

const downloadVPRevision = ({ id, portCallId }: DownloadVPRevisionRequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`/portcalls/${portCallId}/vessel-programme/revisions/${id}/download`
	);

const pollDownloadVPRevision = (
	{ id, portCallId }: DownloadVPRevisionRequest,
	{ documentId }: RetrieveDownloadDocumentResponse
) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/portcalls/${portCallId}/vessel-programme/revisions/${id}/download`,
		{
			params: { documentId }
		}
	);

const updateVPFromFinance = ({
	portCallId,
	rotationStepName,
	payload
}: UpdateVPFromFinanceRequest) =>
	client.put(
		`/portcalls/${portCallId}/vessel-programme/${rotationStepName}`,
		payload
	);

export default {
	retrieveVP,
	saveVP,
	retrieveTerminals,
	retrieveEvents,
	retrieveBerths,
	trackVP,
	commitVP,
	acknowledgeVP,
	acknowledgeVPWithComment,
	addPOI,
	downloadVPRevision,
	pollDownloadVPRevision,
	updateVPFromFinance
};
