import {
	AddPortJobResponse,
	AddPortJobRequestPayload
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

/**
 * Add a new portjob (to an existing portcall)
 */
export const ACTION_NAME = 'ADD_PORTJOB';
export const addPortJobAsync = actionCreator.async<
	AddPortJobRequestPayload,
	AddPortJobResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Port Job'
	}
});
export const addPortJob = actionCreator(ACTION_NAME);
