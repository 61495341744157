import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
	ScrollableLayout,
	InfiniteTable,
	PageHeader,
	Filters
} from 'components';
import { retrieveCompanies, resetCompanies } from 'store/companies';
import {
	getAllCompaniesSelector,
	getCompaniesHasMore,
	isFetchingCompaniesSelector
} from 'store/companies/selectors';
import CompaniesFilters from './CompaniesFilters';
import {
	getFilterValues,
	getFiltersResultByKey
} from 'store/filters/filtersSelectors';
import { AppState } from 'store-types';
import { getCompaniesTableColumns } from './CompaniesTableColumns';
import { AppNotifications } from 'containers';

const MASTER_DATA_EXPLORER_COMPANY_FILTERS_ID = 'md-explorer-company-id';

const searchTypeMap = {
	companies: 'Companies'
};

const CompaniesTable = () => {
	const dispatch = useDispatch();
	const companies = useSelector(getAllCompaniesSelector);
	const hasMore = useSelector(getCompaniesHasMore);
	const isLoading = useSelector(isFetchingCompaniesSelector);
	const filters = useSelector(
		(state: AppState) =>
			getFilterValues(state, MASTER_DATA_EXPLORER_COMPANY_FILTERS_ID),
		shallowEqual
	);
	const tags = useSelector((state: AppState) =>
		getFiltersResultByKey(state, MASTER_DATA_EXPLORER_COMPANY_FILTERS_ID)
	);

	/* eslint-disable react-hooks/exhaustive-deps */
	const companyIds = useMemo(
		() =>
			filters
				? filters.companies.map(
						({ key, label }: { key: string; label: string }) => key || label
				  )
				: [],
		[filters]
	);
	/* eslint-enable */

	useEffect(() => {
		dispatch(
			retrieveCompanies(
				{ ids: companyIds },
				{ behaviour: { type: 'sub-section', infiniteLoad: true } }
			)
		);
	}, [companyIds, dispatch]);

	const onEnter = useCallback(() => {
		dispatch(
			retrieveCompanies(undefined, {
				behaviour: { type: 'sub-section', infiniteLoad: true }
			})
		);
	}, [dispatch]);

	const resetCompaniesInTable = useCallback(() => dispatch(resetCompanies()), [
		dispatch
	]);

	const columns = useMemo(getCompaniesTableColumns, []);

	return (
		<ScrollableLayout>
			<PageHeader shadowed>
				<Filters
					name={MASTER_DATA_EXPLORER_COMPANY_FILTERS_ID}
					invokeOnChange={resetCompaniesInTable}
				>
					{props => (
						<>
							<CompaniesFilters {...props} />
							<Filters.Tags
								tags={tags}
								clearOne={props.clearOne}
								clearAll={props.clearAll}
								typeMap={searchTypeMap}
							/>
						</>
					)}
				</Filters>
			</PageHeader>
			<ScrollableLayout>
				<AppNotifications.Table
					entityName="Companies"
					actionType={retrieveCompanies.type}
				>
					{({ failedLoading }) => (
						<InfiniteTable
							lazyLoad={{
								loading: isLoading,
								onEnter,
								hasMore,
								doneLoading: 'No more Companies to load',
								failedLoading
							}}
							dataSource={companies}
							columns={columns}
						/>
					)}
				</AppNotifications.Table>
			</ScrollableLayout>
		</ScrollableLayout>
	);
};

export default CompaniesTable;
