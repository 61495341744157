import * as React from 'react';
import { connect } from 'react-redux';
import { PageHeader, Filters } from 'components';
import { Align, Justify } from 'components/types';
import { Row, Col } from 'components/antd';
import { resetPortCalls } from 'store/portcalls/actions';
import PortCallsFilters from '../PortCallsFilters/PortCallsFilters';
import {
	getFilterValues,
	getFiltersResultByKey
} from 'store/filters/filtersSelectors';
import { PORT_CALLS_FILTER_NAME } from 'store/portcalls/filtersSync';

import { filterTypeMap } from '../PortCallsFilters/PortCallsFiltersConstants';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { AppState } from 'store-types';
import { PermissionButton } from 'components/Permission';
import {
	getIsCurrentUserHub,
	getIsCurrentUserPrincipal
} from 'store/auth/selectors';

type PortCallsHeaderProps = ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps & {
		onCreateJobClick: () => void;
	};
class PortCallsHeader extends React.Component<PortCallsHeaderProps> {
	static defaultProps: Partial<PortCallsHeaderProps> = {
		filters: {}
	};
	onFiltersChange = () => this.props.resetPortCalls();
	render() {
		const {
			filtersResult,
			isAddNewButtonVisible,
			onCreateJobClick
		} = this.props;
		return (
			<PageHeader stretch shadowed>
				<Filters
					name={PORT_CALLS_FILTER_NAME}
					invokeOnChange={this.onFiltersChange}
				>
					{props => (
						<>
							<Row>
								<Col sm={11}>
									<PortCallsFilters {...props} />
								</Col>
								{isAddNewButtonVisible && (
									<Col sm={1} align={Align.BOTTOM} justify={Justify.END}>
										<PermissionButton
											permissionCode={PermissionCode.MANAGE_PORTCALL}
											type="primary"
											onClick={onCreateJobClick}
											tooltip={{ placement: 'bottomRight' }}
										>
											Create Job
										</PermissionButton>
									</Col>
								)}
							</Row>
							<Filters.Tags
								tags={filtersResult}
								clearOne={props.clearOne}
								clearAll={props.clearAll}
								typeMap={filterTypeMap}
							/>
						</>
					)}
				</Filters>
			</PageHeader>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	filters: getFilterValues(state, PORT_CALLS_FILTER_NAME),
	filtersResult: getFiltersResultByKey(state, PORT_CALLS_FILTER_NAME),
	isAddNewButtonVisible:
		getIsCurrentUserHub(state) || getIsCurrentUserPrincipal(state)
});
const mapDispatchToProps = {
	resetPortCalls
};
export default connect(mapStateToProps, mapDispatchToProps)(PortCallsHeader);
