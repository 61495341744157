import actionCreator from '../portJobsActionCreator';
import { RetrieveJobThreadsRequest } from 'services/api/portJobs/portJobsServiceTypes';

const RETRIEVE_PORTJOB_THREADS_CYCLE_START =
	'RETRIEVE_PORTJOB_THREADS_CYCLE_START';

const RETRIEVE_PORTJOB_THREADS_CYCLE_STOP =
	'RETRIEVE_PORTJOB_THREADS_CYCLE_STOP';

export const retrievePortJobThreadsCycleStart = actionCreator<
	RetrieveJobThreadsRequest
>(RETRIEVE_PORTJOB_THREADS_CYCLE_START);

export const retrievePortJobThreadsCycleStop = actionCreator(
	RETRIEVE_PORTJOB_THREADS_CYCLE_STOP
);
