import React from 'react';
import { connect } from 'react-redux';
import { AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import DocumentViewerContainer from 'sections/DocumentViewer/DocumentViewerContainer';

import { retrieveAllowedDocuments } from 'store/portCallDocuments/actions';
import { retrieveUploadedDocuments } from 'store/portCallDocuments';

import { BreadcrumbItem } from 'store/navigation';

import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';

import { PortCallTabBaseProps } from 'sections/PortCall';

import Contextualization from './Contextualization';
import { AppState } from 'store-types';

interface ContextualizationRouteProps extends PortCallTabBaseProps {
	breadcrumbs: BreadcrumbItem[];
	// from mapDispatchToProps
	retrieveAllowedDocuments: typeof retrieveAllowedDocuments;
	retrieveUploadedDocuments: typeof retrieveUploadedDocuments;
}

class ContextualizationRoute extends React.Component<
	ContextualizationRouteProps
> {
	componentDidMount() {
		const { portCallId } = this.props.match.params;
		// Retrieve Data for the Form
		this.props.retrieveAllowedDocuments({
			portCallId
		});
		// Retrieve portcall documents metadata
		this.props.retrieveUploadedDocuments({
			portCallId,
			showDataInconsistencyAlert: true
		});
	}

	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([{ title: 'Upload New Document' }]);

	render() {
		const {
			match: {
				params: { portCallId, documentId }
			}
		} = this.props;
		return (
			<>
				<AppNotifications.Notification />
				<DocumentViewerContainer match={this.props.match}>
					<RouteBreadcrumbs items={this.getBreadcrumbs()} />
					<Contextualization portCallId={portCallId} documentId={documentId} />
				</DocumentViewerContainer>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		breadcrumbs: getPortCallLayoutBreadcrumbs(state)
	}),
	{
		retrieveAllowedDocuments,
		retrieveUploadedDocuments
	}
)(ContextualizationRoute);
