import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { custodyTransferOrdered } from '../vesselProgrammeConstants';
import { CustodyAgentType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
export const getCustodyTransfer = (state: AppState) =>
	state.vesselProgramme.custodyTransfer;

const getCustodyTransferLinePositionIndex = createSelector(
	getCustodyTransfer,
	custodyTransfer =>
		!custodyTransfer
			? 0
			: custodyTransferOrdered.findIndex(
					linePosition => linePosition === custodyTransfer.linePosition
			  )
);

export const getCanMoveUpCustodyTransferLine = createSelector(
	getCustodyTransferLinePositionIndex,
	index => index > 0
);

export const getCanMoveDownCustodyTransferLine = createSelector(
	getCustodyTransferLinePositionIndex,
	index => index < custodyTransferOrdered.length - 1
);

export const isCustodyTransferDuringRotationStep = (rotationStepId: string) =>
	createSelector(
		getCustodyTransfer,
		transfer =>
			transfer &&
			transfer.rotationStepId === rotationStepId &&
			transfer.linePosition === 'During'
	);

export const getRotationStepsPositionedToCustodyLine = (state: AppState) =>
	state.vesselProgramme.rotationStepsPositionedToCustodyLine;

export const getPositionedRotationStep = (state: AppState, position: number) =>
	getRotationStepsPositionedToCustodyLine(state)[position];

export const getRotationStepsPositionedById = (state: AppState) => (
	rotationStepId: string
) =>
	getRotationStepsPositionedToCustodyLine(state).filter(
		step => step.rotationStepId === rotationStepId
	);

export const isRotationStepWithProperties = createSelector(
	getPositionedRotationStep,
	(
		_state: AppState,
		_stepPosition: number,
		rotationStepProps: { agentMode: CustodyAgentType; hasLine: boolean }
	) => rotationStepProps,
	(step, { agentMode, hasLine }) => {
		if (!step) {
			return false;
		}
		return step.agentMode === agentMode && step.hasCustodyLine === hasLine;
	}
);
