import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveStaticDocumentConfigAsync } from '../actions/retrieveStaticConfiguration';
import {
	RetrieveDocumentConfigParams,
	RetrieveStaticDocumentConfigResponse
} from 'services/api/documents/documentsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Documents.retrieveUploadConfiguration;
const async = retrieveStaticDocumentConfigAsync;

/**
 * Get the Static config onMount for the upload 
 */
export default makeTakeEveryWatcher<
	RetrieveDocumentConfigParams,
	RetrieveStaticDocumentConfigResponse,
	Error
>({
	api: apiCall,
	async
});
