import { isNumber, omitBy, template, omit, isArray } from 'lodash';
import {
	ActionMeta,
	ActionMetaTemplateParams,
	ActionMetaConfig
} from 'app-types';
import {
	errorStatusCode,
	getIsClientSideError,
	getErrorCorrelationId
} from 'services/api/apiErrorUtils';
import { NotificationVariantItem } from 'store/notifications/notificationsState';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';

export function getNotificationVariantItemError(
	meta: ActionMeta = {},
	error: AxiosGeneralError
): NotificationVariantItem['error'] {
	const statusCode = errorStatusCode(error);
	const result: NotificationVariantItem['error'] = {
		status: statusCode
	};

	const isCorrelationIdIncludedForCurrentStatus = Boolean(
		((meta.failed && meta.failed.includeCorrelationId) || []).includes(
			statusCode
		)
	);

	if (isCorrelationIdIncludedForCurrentStatus || !getIsClientSideError(error)) {
		result.correlationId = getErrorCorrelationId(error);
	}
	return result;
}

export function getNotificationVariantItemInitial({
	displayType,
	type,
	title,
	description,
	duration
}: ActionMetaConfig = {}) {
	const variant = {
		displayType,
		type,
		title,
		description,
		duration
	};
	return omitBy(variant, value => !isNumber(value) && !value);
}

export function getDescription(
	description: string | string[],
	templateParams: ActionMetaTemplateParams
) {
	if (isArray(description)) {
		return description.map(item => template(item)(templateParams));
	}
	return template(description)(templateParams);
}

export function getNotificationVariantItem(
	config: ActionMetaConfig = {},
	templateParams: ActionMetaTemplateParams = {}
): Partial<NotificationVariantItem> | undefined {
	let variant = getNotificationVariantItemInitial(config);

	// omit config values in case template parameters aren't defined
	if (variant?.description) {
		try {
			variant = {
				...variant,
				description: getDescription(variant.description, templateParams)
			};
		} catch (e) {
			return omit(variant, 'description');
		}
	}

	return variant;
}
