import {
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from '../financeActionCreator';

const RETRIEVE_FINANCE_DOCUMENT = 'RETRIEVE_FINANCE_DOCUMENT';
export const retrieveFinanceDocumentAsync = actionCreator.async<
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse,
	Error
>(RETRIEVE_FINANCE_DOCUMENT);

export const retrieveFinanceDocument = actionCreator<
	RetrieveFinanceDocumentRequest
>(RETRIEVE_FINANCE_DOCUMENT);
