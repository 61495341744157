import React from 'react';
import PortCallsContainer from './PortCallsContainer';
import PortCallsLayout from './PortCallsLayout';
import { PortCallsBaseProps } from 'sections/PortCalls';

const PortCallsRoute: React.SFC<PortCallsBaseProps> = props => (
	<PortCallsContainer match={props.match}>
		<PortCallsLayout {...props} />
	</PortCallsContainer>
);

export default PortCallsRoute;
