import actionCreatorFactory from 'typescript-fsa';
import {
	PostInternalCommentsRequest,
	PostInternalCommentsResponse
} from 'services/api/threads/threadsServiceTypes';

const actionCreator = actionCreatorFactory();

const POST_INTERNAL_COMMENTS = 'comments/POST_INTERNAL_COMMENTS';

export const postInternalCommentsAsync = actionCreator.async<
	PostInternalCommentsRequest,
	PostInternalCommentsResponse,
	Error
>(POST_INTERNAL_COMMENTS, {
	behaviour: { type: 'react-to-changes-child' }
});

export const postInternalComments = actionCreator<
	PostInternalCommentsRequest | undefined
>(postInternalCommentsAsync.type);
