import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { FormState } from '../formState';
import { PrincipalServicesFormData } from './principalServicesFormData';

export type PrincipalServicesFormState = FormState;

export const initialState: PrincipalServicesFormState = {
	values: {} as PrincipalServicesFormData
};

export default reducerWithInitialState(initialState);
