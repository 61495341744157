import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	enableOperationsCheckAsync as async,
	toggleOperationsStatus
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getIsOperationsDisabled
} from '../portJobsSelectors';
import { PortJobBaseRequest } from 'services/api/portJobs/portJobsServiceTypes';
import {
	ENABLE_OPERATIONS_FORM,
	ServiceSection
} from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';
import { initialize } from 'redux-form';
import { openModal } from 'store/modals/actions';

const apiCall = Api.PortJobs.enableOperations;

const executor = function*(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const isOperationsDisabled = yield select(getIsOperationsDisabled);
	const request = {
		portCallId,
		jobCode
	};
	yield put(async.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(async.done({ result: response.data, params: request, response }));
		if (
			response.data.serviceSection.length > 0 ||
			response.data.alternateServiceSection.length > 0
		) {
			yield put(
				initialize(ENABLE_OPERATIONS_FORM, {
					list: [
						{
							serviceSection: response.data.serviceSection,
							alternateServiceSection: response.data.alternateServiceSection
						}
					]
				})
			);
			yield put(openModal('enable-operations-modal'));
		} else {
			yield call(Api.PortJobs.enableOperationsUpdate, {
				portCallId,
				jobCode,
				portJobServiceEnableOperationUpdateRequestData:
					response.data.serviceSection,
				alternateSectionUpdateRequestData: response.data.alternateServiceSection
			});
			yield put(
				toggleOperationsStatus({
					portCallId,
					jobCode,
					isOperationsDisabled: !isOperationsDisabled
				})
			);
		}
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
};

const enableOperationsCheckWatcher = makeTakeLatestWatcher<
	PortJobBaseRequest,
	ServiceSection[],
	Error
>({ api: apiCall, async }, executor);

export default enableOperationsCheckWatcher;
