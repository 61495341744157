import { SagaIterator } from 'redux-saga';
import {
	all,
	fork,
	call,
	cancel,
	put,
	take,
	select,
	delay
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	retrievePortCall,
	retrievePortCallCycleStart,
	retrievePortCallCycleStop,
	retrievePortCallAsync
} from '../actions';
import {
	retrievePortJobForCycle,
	retrievePortJobForCycleAsync
} from 'store/portJobs/actions';
import {
	retrieveFinanceDetails,
	retrieveFinanceDetailsAsync
} from 'store/finance/actions';
import {
	getActivePortJob,
	getActivePortJobCode
} from 'store/portJobs/selectors';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	retrieveEntityPermissionsForPortCall,
	retrieveEntityPermissionsForPortCallAsync
} from 'store/permissions/actions';
import {
	EntityPermissionType,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { getHasPermission } from 'store/auth/selectors/permissionsSelector';

const refreshCycle = function*(portCallId: string) {
	const portJob: PortJob = yield select(getActivePortJob);
	const hasViewFinancePermission = yield select(
		getHasPermission,
		PermissionCode.VIEW_FINANCE
	);
	yield all([
		put(
			retrievePortCall({
				id: portCallId,
				expand: 'PortJobsBasic',
				isSilent: true
			})
		),
		put(
			retrievePortJobForCycle({
				portCallId,
				jobCode: portJob.code,
				expand: ExpandPortJobType.OPERATIONS,
				isSilent: true
			})
		),
		...(hasViewFinancePermission
			? [put(retrieveFinanceDetails({ portCallId }))]
			: []),
		put(
			retrieveEntityPermissionsForPortCall({
				entityType: EntityPermissionType.PORTJOB,
				entityKey: portJob.code
			})
		)
	]);
};

const retrievePortCallCycleWorker = function*({
	payload: portCallId
}: Action<string>): SagaIterator {
	const jobCode = yield select(getActivePortJobCode);
	const hasViewFinancePermission = yield select(
		getHasPermission,
		PermissionCode.VIEW_FINANCE
	);
	// skip initial fetch if active port job hasn't been set yet
	if (jobCode) {
		// make a delay to avoid requesting the data twice on the start
		yield delay(DEFAULT_POLLING_INTERVAL);
		// first repeated fetch no change for error handling
		yield call(refreshCycle, portCallId);
		// wait for all request to end
		yield all([
			take([retrievePortCallAsync.done, retrievePortCallAsync.failed]),
			take([
				retrievePortJobForCycleAsync.done,
				retrievePortJobForCycleAsync.failed
			]),
			...(hasViewFinancePermission
				? [
						take([
							retrieveFinanceDetailsAsync.done,
							retrieveFinanceDetailsAsync.failed
						])
				  ]
				: []),
			take([
				retrieveEntityPermissionsForPortCallAsync.done,
				retrieveEntityPermissionsForPortCallAsync.failed
			])
		]);
	}
	// fallback to notification for the next ones
	yield put(
		setNotificationActionTypeToNotification([
			retrievePortCallAsync.type,
			retrievePortJobForCycleAsync.type,
			...(hasViewFinancePermission ? [retrieveFinanceDetailsAsync.type] : []),
			retrieveEntityPermissionsForPortCallAsync.type
		])
	);

	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield call(refreshCycle, portCallId);
	}
};

export const retrievePortCallCycleWatcher = function*() {
	while (true) {
		const hasViewFinancePermission = yield select(
			getHasPermission,
			PermissionCode.VIEW_FINANCE
		);
		const action = yield take(retrievePortCallCycleStart);
		const pollPortCallTask = yield fork(retrievePortCallCycleWorker, action);
		yield take(retrievePortCallCycleStop);
		yield cancel(pollPortCallTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrievePortCallAsync.type,
				retrievePortJobForCycleAsync.type,
				...(hasViewFinancePermission ? [retrieveFinanceDetailsAsync.type] : []),
				retrieveEntityPermissionsForPortCallAsync.type
			])
		);
	}
};
