import { DashboardState } from '../dashboardState';

export const onResetDashboardTableauCompanyId = (
	state: DashboardState
): DashboardState => ({
	...state,
	context: {
		...state.context,
		tableauCompanyId: undefined
	}
});
