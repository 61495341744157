import { put, call, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api';

import { updateDirectBillAsync as async } from '../actions';
import {
	getEditDirectBillRequest,
	getEditableDirectBillId
} from '../selectors';
import { navigateTo } from 'utils';
import { UpdateDirectBillRequest } from 'services/api/directBills/directBillsServiceTypes';
import { getNewlyCreatedCompanyDraftsFromType } from 'store/drafts/selectors';
import { DraftType, NewCompanyDraft } from 'store/drafts/draftsState';

// only append newly created drafts
function* appendDraftVendors(request: UpdateDirectBillRequest) {
	const newDrafts: NewCompanyDraft[] = yield select(
		getNewlyCreatedCompanyDraftsFromType,
		DraftType.VENDOR
	);
	return {
		...request,
		draftVendorCompanies: newDrafts
	} as UpdateDirectBillRequest;
}

export function* worker(): SagaIterator {
	const saveDirectBillRequestWithoutDrafts = yield select(
		getEditDirectBillRequest
	);
	const saveDirectBillRequest = yield call(
		appendDraftVendors,
		saveDirectBillRequestWithoutDrafts
	);

	const existingDirectBillId = yield select(getEditableDirectBillId);
	yield put(async.started(saveDirectBillRequest));
	try {
		let response;
		if (existingDirectBillId) {
			response = yield call(
				Api.DirectBills.updateDirectBill,
				saveDirectBillRequest,
				existingDirectBillId
			);
		} else {
			response = yield call(
				Api.DirectBills.createDirectBill,
				saveDirectBillRequest
			);
		}
		yield put(
			async.done({
				result: response,
				params: saveDirectBillRequest,
				response
			})
		);
		yield call(navigateTo, '/data-management');
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: saveDirectBillRequest
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
