import {
	ContactRequest,
	ContactType
} from 'services/api/messages/messagesServiceTypes';
import actionCreator from 'store/messages/messagesActionCreator';

const RETRIEVE_CONTACT = 'RETRIEVE_CONTACT';
export const retrieveContactAsync = actionCreator.async<
	ContactRequest,
	ContactType,
	Error
>(RETRIEVE_CONTACT);
export const retrieveContact = actionCreator<ContactRequest>(RETRIEVE_CONTACT);
