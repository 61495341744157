import { put, call, select } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers/sagaHelpers';

import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';

import { DownloadJobFinanceRevisionRequest } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import {
	downloadJobFinanceRevisionAsync as async,
	DownloadJobFinanceRevisionActionParams
} from '../actions/downloadJobFinanceRevision';
import { downloadExecutor } from 'store/documents/sagas/pollDownloadUrlSaga';

const POSTApi = Api.Finance.downloadJobFinanceRevision;
const GETApi = Api.Finance.pollDownloadJobFinanceRevision;
function* worker(params: DownloadJobFinanceRevisionActionParams) {
	const requestParams: DownloadJobFinanceRevisionRequest = {
		portCallId: yield select(getActivePortCallId),
		jobCode: yield select(getActivePortJobCode),
		revisionId: params.revisionId,
		disbursementAccountType: params.disbursementAccountType
	};
	yield put(async.started(requestParams));
	try {
		yield call(downloadExecutor, {
			params: requestParams,
			POSTApi,
			GETApi
		});

		yield put(
			async.done({ params: requestParams, result: null, response: null })
		);
	} catch (error) {
		yield put(
			async.failed({
				params: requestParams,
				error
			})
		);
	}
}

export default makeTakeEveryWatcher<
	DownloadJobFinanceRevisionActionParams,
	null,
	Error
>({ async }, worker);
