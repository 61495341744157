import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { notify } from 'store/notifications/actions';
import { DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE } from 'app-constants';

interface ErrorRedirectProps {
	targetUrl: string;
	errorMessage?: string;
}

const ErrorRedirect: FC<ErrorRedirectProps> = ({
	targetUrl,
	errorMessage = DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			notify.error({
				description: errorMessage,
				duration: 0
			})
		);
	}, [dispatch, errorMessage]);

	return <Redirect to={targetUrl} />;
};

export default ErrorRedirect;
