import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ScrollableLayout, Align } from 'components';
import { Button, Icon } from 'components/antd';
import { AppNotifications } from 'containers';

import JobDetailsTab, {
	PortJobTab
} from 'sections/PortCall/PortJob/JobDetailsTabs/JobDetailsTabs';
import {
	getActivePortCallId,
	getActivePortCall,
	getPortJobsForActivePortCall
} from 'store/portcalls/portCallsSelectors';
import styles from './Messages.module.scss';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { navigateTo } from 'utils';
import { resetPortJobThreads } from 'store/portJobs/actions';
import {
	setActivePortJobThreadId,
	resetActivePortJobThreadId
} from 'store/portJobs/actions/setActivePortJobThreadId';
import { SHOW_ALL_JOBS_KEY } from 'sections/PortCall/PortJob/PortJobConstants';
import { PATHS } from 'sections/App/RouteParams';
import { resetJobsInNewThread, resetThreads } from 'store/threads/actions';
import MessagesLayout from './MessagesLayout';
import { AppState } from 'store-types';

import Filters from 'components/Filters/Filters';
import { PORT_MESSAGES_FILTER } from 'store/portJobs/portCallMessagesFiltersSync';
import { FilterItem, FilterState } from 'store/filters/filtersState';

interface RouteParams {
	threadId?: string;
	portJobCode?: string;
}

type MessagesProps = RouteComponentProps<RouteParams> &
	ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps & {
		isReadOnly: boolean;
	};

class Messages extends React.Component<MessagesProps> {
	componentDidMount() {
		const {
			portJobs,
			isReadOnly,
			match: {
				params: { threadId, portJobCode }
			}
		} = this.props;
		if (threadId) {
			this.props.setActivePortJobThreadId(threadId);
		}
		if (isReadOnly) {
			// Go to the first port job tab in read only mode
			if (portJobCode === SHOW_ALL_JOBS_KEY && portJobs.length) {
				navigateTo(
					`/${PATHS.portCalls}/${this.props.activePortCallId}/jobs/${portJobs[0].code}/messages`
				);
			}
		}
	}

	componentWillUnmount() {
		this.props.resetJobsInNewThread();
	}

	private navigateToNewMessage = () => {
		const { activePortCallId, activePortJobCode } = this.props;
		navigateTo(
			`/${PATHS.portCalls}/${activePortCallId}/jobs/${activePortJobCode}/messages/new`
		);
	};

	private onJobCodeChange = (activeKey: string) => {
		// Reset threads onChange to reset index to 0
		this.props.resetActivePortJobThreadId();
		this.props.resetPortJobThreads();
		navigateTo(
			`/${PATHS.portCalls}/${this.props.activePortCallId}/jobs/${activeKey}/messages`
		);
	};

	getTabs = (
		onChange: (filters: FilterState, override?: boolean) => void,
		clearOne: (filter: FilterItem) => void,
		clearAll: () => void
	) => {
		const { portJobs, isReadOnly } = this.props;

		const tabs = portJobs.map(
			(job): PortJobTab => ({
				jobCode: job.code,
				operationIndicator: job.isOperationsDisabled && (
					<Icon
						type="operations-disable"
						color="standard"
						offset="left"
						size={20}
					/>
				),
				financeIndicator: !job.financeEnabled && (
					<Icon
						type="finance-disable"
						color="standard"
						offset="left"
						size={20}
					/>
				),
				children: (
					<MessagesLayout
						onChange={onChange}
						clearOne={clearOne}
						clearAll={clearAll}
					/>
				)
			})
		);
		if (isReadOnly) {
			return tabs;
		}
		return [
			{
				jobCode: SHOW_ALL_JOBS_KEY,
				children: (
					<MessagesLayout
						onChange={onChange}
						clearOne={clearOne}
						clearAll={clearAll}
					/>
				)
			},
			...tabs
		];
	};

	handleOnChangeInvoked = () => {
		this.props.resetThreads();
		this.props.resetPortJobThreads();
	};

	render() {
		return (
			<ScrollableLayout>
				<AppNotifications.Alert />
				<ScrollableLayout>
					<Filters
						name={PORT_MESSAGES_FILTER}
						invokeOnChange={this.handleOnChangeInvoked}
						destroyOnUnmount={false}
					>
						{({ onChange, clearOne, clearAll }) => (
							<JobDetailsTab
								fit
								className={styles.tabs}
								onJobCodeChange={activeKey => this.onJobCodeChange(activeKey)}
								tabs={this.getTabs(onChange, clearOne, clearAll)}
								destroyInactiveTabPane
								tabBarExtraContent={
									!this.props.isReadOnly ? (
										<Align align="right">
											<Button
												type="primary"
												className="qaa_new_message_button"
												onClick={this.navigateToNewMessage}
											>
												Compose Message
											</Button>
										</Align>
									) : null
								}
							/>
						)}
					</Filters>
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	const activePortCallId = getActivePortCallId(state);
	return {
		activePortCallId,
		activePortJobCode: getActivePortJobCode(state),
		portCall: getActivePortCall(state),
		portJobs: getPortJobsForActivePortCall(state)
	};
};
const mapDispatchToProps = {
	resetThreads,
	resetPortJobThreads,
	resetActivePortJobThreadId,
	setActivePortJobThreadId,
	resetJobsInNewThread
};
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
