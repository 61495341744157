import React from 'react';
import { ErrorUnhandled } from 'components';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
	hasError: boolean;
}

class AppErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidMount() {
		/**
		 * Reload page when runtime error has occurred
		 * and user clicks back/backspace to navigate
		 * previous page
		 */
		window.onpopstate = () => {
			const { hasError } = this.state;
			if (hasError) {
				window.location.reload();
			}
		};
	}

	componentDidCatch() {
		this.setState({ hasError: true });
	}

	componentWillUnmount() {
		/**
		 * Reset window popstate event listener
		 */
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		window.onpopstate = () => {};
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;
		if (!hasError) {
			return <>{children}</>;
		}
		return <ErrorUnhandled />;
	}
}

export default AppErrorBoundary;
