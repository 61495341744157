import { all, fork } from 'redux-saga/effects';
import retrieveStaticConfiguration from './retrieveStaticConfigurationSaga';
import retrieveRelativeConfiguration from './retrieveRelativeConfigurationSaga';
import saveDocument from './saveDocumentSaga';
import saveAttachment from './saveAttachmentSaga';
import retrieveDocument from './retrieveDocumentSaga';
import processDocument from './processDocumentSaga';
import uploadAndProcessDocument from './uploadAndProcessDocumentSaga';

export default function*() {
	yield all([
		fork(retrieveStaticConfiguration),
		fork(retrieveRelativeConfiguration),
		fork(saveDocument),
		fork(saveAttachment),
		fork(retrieveDocument),
		fork(processDocument),
		fork(uploadAndProcessDocument)
	]);
}
