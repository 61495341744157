import React from 'react';
import { connect } from 'react-redux';
import DocumentPageViewerPage, {
	DocumentPageProps
} from 'sections/DocumentPageViewer/DocumentPageViewerPage';

import { getIsPortCallDocumentPageDeleteLoading } from 'store/portCallDocuments/selectors';
import { AppState } from 'store-types';

interface OperationDocumentPageOwnProps extends DocumentPageProps {
	documentId: string;
}
interface OperationDocumentPageProps extends OperationDocumentPageOwnProps {
	loading: boolean;
}
const OperationDocumentPage: React.SFC<OperationDocumentPageProps> = ({
	documentId: _id,
	...props
}) => <DocumentPageViewerPage {...props} />;

export default connect(
	(state: AppState, ownProps: OperationDocumentPageOwnProps) => ({
		loading:
			(ownProps.page &&
				getIsPortCallDocumentPageDeleteLoading(
					state,
					ownProps.documentId,
					ownProps.page.actualNumber
				)) ||
			false
	})
)(OperationDocumentPage);
