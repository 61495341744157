import { omit } from 'lodash';
import { FinanceState } from '../financeState';
import {
	RetrieveFinanceRequest,
	RetrieveFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';

const updateFetchStatus = (
	state: FinanceState,
	params: RetrieveFinanceRequest,
	fetchStatus: FetchStatus
) => {
	return params.isSilent ? state.fetchStatuses.retrieve : fetchStatus;
};

export function onRetrieveFinanceStarted(
	state: FinanceState,
	params: RetrieveFinanceRequest
): FinanceState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: updateFetchStatus(state, params, FetchStatus.PENDING)
		}
	};
}
export function onRetrieveFinanceSuccess(
	state: FinanceState,
	action: Success<RetrieveFinanceRequest, RetrieveFinanceResponse>
): FinanceState {
	const documentByDaOutId = action.result.disbursementAccountOuts.elements.reduce(
		(acc, da) => {
			acc[da.id] = {
				documentId: '',
				fetchStatus: FetchStatus.IDLE
			};

			return acc;
		},
		{}
	);

	return {
		...state,
		// omitting in order not to keep data two times in the state
		finance: omit(action.result, 'fundingRequests', 'fundings', 'contextBars'),
		documentByDaOutId,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: updateFetchStatus(state, action.params, FetchStatus.SUCCESS)
		}
	};
}
export function onRetrieveFinanceFailed(
	state: FinanceState,
	action: Failure<RetrieveFinanceRequest, AxiosGeneralError>
): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: action.error.response.status === 404
		},
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: updateFetchStatus(state, action.params, FetchStatus.FAILURE)
		}
	};
}
