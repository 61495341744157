import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import {
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrievePortCallCargoMeasurementsSuccess = (
	state: State,
	action: Success<RetrievePortCallCargoMeasurementsRequest, CargoMeasurement[]>
): State => ({
	...state,
	cargo: {
		...state.cargo,
		[action.params.portCallId]: action.result
	}
});

export const onRetrievePortCallCargoMeasurements = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement[]
>('fetchStatuses', 'cargo', {
	doneReducer: onRetrievePortCallCargoMeasurementsSuccess
});
