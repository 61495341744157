import * as React from 'react';
import { Button } from 'components/antd';
import { IconSize } from 'components/antd/Icon/Icon';
import classNames from 'classnames';
import styles from '../ReadMore.module.scss';

export interface FooterLinkProps {
	className?: string;
	onToggle: () => void;
	collapsed: boolean;
	expandText: string;
	collapseText: string;
	loading?: boolean;
	iconSize?: IconSize;
	renderFooterContent?: (props: FooterLinkProps) => JSX.Element;
}

const FooterLink: React.FC<FooterLinkProps> = props => {
	const {
		className,
		collapsed,
		collapseText,
		expandText,
		loading,
		onToggle,
		iconSize,
		renderFooterContent
	} = props;
	const text = collapsed ? expandText : collapseText;
	return (
		<Button
			type="primary"
			transparent
			loading={loading}
			onClick={onToggle}
			className={classNames(className, styles.toggleBtn)}
			iconSize={iconSize}
		>
			{renderFooterContent ? renderFooterContent(props) : text}
		</Button>
	);
};

export default FooterLink;
