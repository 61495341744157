import React from 'react';
import { connect } from 'react-redux';
import { getIsAnyContextualizationPortJobInvalid } from 'store/form/supplierInvoiceContextualization/selectors';
import { DocumentViewerWizardForm } from 'sections/DocumentViewer';
import { PATHS } from 'sections/App/RouteParams';
import { createSupplierInvoice } from 'store/supplierInvoice/actions';
import { getIsDocumentCreateLoading } from 'store/supplierInvoice/selectors';
import ContextualizationForm from './ContextualizationForm/ContextualizationForm';
import ContextualizationHint from './ContextualizationHint';
import { AppState } from 'store-types';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';

interface ContextualizationProps {
	documentId: string;
	loading: boolean;
	createSupplierInvoice: typeof createSupplierInvoice;
	isAnyContextualizationPortJobInvalid: boolean;
}

interface ContextualizationState {
	isReduxFormValid: boolean;
}

class Contextualization extends React.Component<
	ContextualizationProps,
	ContextualizationState
> {
	constructor(props: ContextualizationProps) {
		super(props);
		this.state = {
			isReduxFormValid: false
		};
	}

	onValidChange = (isReduxFormValid: boolean) => {
		this.setState({ isReduxFormValid });
	};

	onSubmit = () =>
		this.props.createSupplierInvoice({ documentId: this.props.documentId });

	render() {
		const {
			documentId,
			loading,
			isAnyContextualizationPortJobInvalid
		} = this.props;
		const canSubmit =
			!isAnyContextualizationPortJobInvalid && this.state.isReduxFormValid;
		return (
			<DocumentViewerWizardForm
				documentId={documentId}
				title="Upload supplier invoice"
				returnUrl={`/${PATHS.supplierinvoices}`}
				loading={loading}
				onSubmit={this.onSubmit}
				canSubmit={canSubmit}
				hint={<ContextualizationHint />}
				pagination={PaginationState.ENABLED}
			>
				{({
					total,
					activePage,
					onValidChange: onPageValidChange,
					onResetWizardForm
				}) => (
					<ContextualizationForm
						pages={total}
						activePage={activePage}
						onValidChange={this.onValidChange} //this valid change to update the supplier redux form
						onPageValidChange={onPageValidChange} // this valid change to set true for the page in document viewer wizard form
						onResetWizardForm={onResetWizardForm}
					/>
				)}
			</DocumentViewerWizardForm>
		);
	}
}

export default connect(
	(state: AppState) => ({
		loading: getIsDocumentCreateLoading(state),
		isAnyContextualizationPortJobInvalid: getIsAnyContextualizationPortJobInvalid(
			state
		)
	}),
	{
		createSupplierInvoice
	}
)(Contextualization);
