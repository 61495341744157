import { Success } from 'typescript-fsa';
import { PortJobState, initialState } from '../portJobsState';
import {
	PortJob,
	RetrieveJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrievePortJobStarted = (
	state: PortJobState,
	{ isSilent }: RetrieveJobParams
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: isSilent ? state.fetchStatuses.all : FetchStatus.PENDING
	}
});
export const onRetrievePortJobSuccess = (
	state: PortJobState,
	action: Success<RetrieveJobParams, PortJob>
): PortJobState => ({
	...state,
	byId: {
		...state.byId,
		[action.result.id]: action.result
	},
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.SUCCESS
	}
});
export const onRetrievePortJobFailed = (state: PortJobState): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.FAILURE
	}
});

export const onResetPortJobs = (state: PortJobState): PortJobState => ({
	...state,
	byId: initialState.byId
});
