import React, { FC } from 'react';
import {
	BaseFieldArrayProps,
	FieldArray,
	WrappedFieldArrayProps,
	InjectedFormProps
} from 'redux-form';
import {
	FormData,
	PortJobError
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { connect } from 'react-redux';
import { getSupplierInvoiceContextualizationPortJobs } from 'store/supplierInvoice/selectors';
import InvoicePortJobCollapse from 'sections/SupplierInvoice/components/InvoicePortJobCollapse/InvoicePortJobCollapse';
import {
	getIsCreditInvoice,
	getIsPortJobServiceSelectedForPage,
	getContextualizationPortJobsFormErrors
} from 'store/form/supplierInvoiceContextualization/selectors';
import { AppState } from 'store-types';
import { partial, isEmpty } from 'lodash';
import InvoiceDetailsPortJobService from './InvoiceDetailsPortJob/InvoiceDetailsPortJobService';
import { SupplierInvoicePortJobService } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

type InvoiceDetailsFieldArrayProps = {
	activePage: number;
	change: InjectedFormProps['change'];
	formValues: FormData;
};

type InvoiceDetailsProps = ReturnType<typeof mapStateToProps> &
	InvoiceDetailsFieldArrayProps & {
		retrievePortJobs: () => void;
		isCredit: boolean;
		portJobsErrors: PortJobError | undefined;
	};

type FormPagesProps = WrappedFieldArrayProps<FormData> & InvoiceDetailsProps;

const InvoiceDetails: FC<FormPagesProps> = ({
	fields,
	activePage,
	portJobs,
	change,
	formValues,
	portJobsErrors,
	isCredit,
	getIsPortJobServiceSelectedPartial
}) => {
	const currentFieldIndex = Math.max(activePage - 1, 0);
	return (
		<>
			{fields.map((field, i) => {
				if (i !== currentFieldIndex) {
					return null;
				}
				return (
					<React.Fragment key={field}>
						{portJobs?.map((job, idx) => (
							<InvoicePortJobCollapse
								portJob={job}
								key={job.id}
								portJobIdx={idx}
							>
								{(
									service: SupplierInvoicePortJobService,
									serviceIdx: number
								) => (
									<InvoiceDetailsPortJobService
										service={service}
										portJobId={job.id}
										portJobIdx={idx}
										serviceIdx={serviceIdx}
										change={change}
										field={field}
										activePage={i}
										isInitialized={isEmpty(formValues.pages[i].portJobs)}
										getIsPortJobServiceSelected={
											getIsPortJobServiceSelectedPartial
										}
										portJobFormValues={
											formValues?.portJobs
												? formValues.portJobs[idx]
												: undefined
										}
										pagesPortJobFormValues={
											formValues.pages[i]?.portJobs
												? formValues.pages[i].portJobs[idx]
												: undefined
										}
										portJobErrors={portJobsErrors?.[idx]}
										isCredit={isCredit}
									/>
								)}
							</InvoicePortJobCollapse>
						))}
					</React.Fragment>
				);
			})}
		</>
	);
};

const mapStateToProps = (state: AppState) => ({
	portJobs: getSupplierInvoiceContextualizationPortJobs(state),
	portJobsErrors: getContextualizationPortJobsFormErrors(state),
	getIsPortJobServiceSelectedPartial: partial(
		getIsPortJobServiceSelectedForPage,
		state
	),
	isCredit: getIsCreditInvoice(state)
});

const InvoiceDetailsConnected = connect(mapStateToProps)(InvoiceDetails);

const InvoiceDetailsFieldArray = (
	props: Pick<BaseFieldArrayProps, 'name'> & InvoiceDetailsFieldArrayProps
) => (
	<FieldArray<InvoiceDetailsFieldArrayProps>
		{...props}
		component={InvoiceDetailsConnected}
	/>
);

export default InvoiceDetailsFieldArray;
