import Api from 'services/api';
import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { updateFundingAsync as async } from '../actions';
import {
	UpdateFundingRequest as Request,
	UpdateFundingResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.FinanceFundings.updateFunding;

export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*getApiParams(funding: Request) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: Request = {
			portCallId,
			jobCode,
			...funding
		};
		return request;
	}
});
