import {
	ConfigurationFilesState,
	initialState
} from '../configurationFilesState';

export const onOpenConfigurationFileModal = (
	state: ConfigurationFilesState,
	documentId: string | null
): ConfigurationFilesState => ({
	...state,
	context: {
		...state.context,
		isNew: !documentId
	}
});

export const onCloseConfigurationFileModal = (
	state: ConfigurationFilesState
): ConfigurationFilesState => ({
	...state,
	context: initialState.context
});
