import React from 'react';
import { Tooltip, Icon } from 'components/antd';
import FinanceAmount from 'sections/PortCall/Finance/FinanceAmount/FinanceAmount';

interface FundingDaAmountProps {
	value: string;
	currencyCode: string;
}

const FundingRequestDaAmount = (props: FundingDaAmountProps) => {
	return (
		<>
			<FinanceAmount value={props.value} currencyCode={props.currencyCode} />
			&nbsp;
			<Tooltip
				title={'Total DA Amount at the time funding was requested'}
				placement="topLeft"
			>
				<Icon type="info-circle" color="primary" size="md" />
			</Tooltip>
		</>
	);
};

export default FundingRequestDaAmount;
