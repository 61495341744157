import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	ValidateJobFinanceRequest,
	ValidateJobFinanceResponse
} from 'services/api/finance/financeServiceTypes';

export const onValidateJobFinance = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	ValidateJobFinanceRequest,
	ValidateJobFinanceResponse
>('fetchStatuses', 'validation');
