import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { InjectedFormProps, reduxForm, resetSection } from 'redux-form';
import {
	ClonePortCallOption,
	ClonePortJobFormData,
	ClonePortJobFormFieldName,
	CLONE_PORTJOB_MODAL_FORM,
	IntegrationOption,
	ParentCloneJobData
} from './constants';
import { Button, Col, MaybeTooltip, Radio, Row } from 'components/antd';
import { Text, Gap, Flex } from 'components';
import {
	getClonePortJobFormData,
	isCloneJobFetchPending
} from 'store/portJobs/selectors';
import { AppState } from 'store-types';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';
import { AutocompletePorts } from 'containers';
import FieldVessels from 'sections/PortJob/CreatePortJob/Pages/Page1/PagePartials/Form/Field.Vessels';
import { LabeledValue } from 'app-types';
import { getIsCurrentUsersGroupTypeISSCluster } from 'store/auth/selectors';
import AgentField from 'sections/PortJob/CreatePortJob/Pages/Page2/PagePartials/Form/Field/AgentField';
import { SearchCompaniesRequest } from 'services/api/companies/companiesServiceTypes';
import Api from 'services/api';
import AddCompanyDraftModal from 'containers/Drafts/company/AddCompanyDraftModal';
import {
	setActiveDraftFormAndField,
	setActiveDraftType
} from 'store/drafts/actions';
import { openModal } from 'store/modals/actions';
import { ADD_COMPANY_DRAFT_MODAL } from 'containers/Drafts/constants';
import { DraftType } from 'store/drafts/draftsState';
import { Justify } from 'components/types';
import styles from '../../JobDetails.module.scss';
import { validate } from './validation';
import { clonePortJob } from 'store/portJobs/actions';
import { PortJobTypeCode } from 'services/api/portJobs/portJobsServiceTypes';
import { retrievePortCallCycleStop } from 'store/portcalls/actions';

export interface ClonePortJobFormBaseProps {
	jobCode: string;
	jobType: string;
	portCallId: string;
	hubPrincipalCompany: LabeledValue<string>;
	parentCloneJobData: ParentCloneJobData;
	closeModal: () => void;
	isIntegrationDisabled: boolean;
	modalId: string;
	isIntegrationJob: boolean;
	//mapStateFromProps
	formValues: ClonePortJobFormData;
	withParentId: boolean;
	performingAgentKeys: string[];
	controllingAgentKeys: string[];
	isCloneJobFetchPending: boolean;
	//mapActionFromProps
	resetSection: typeof resetSection;
	setActiveDraftType: typeof setActiveDraftType;
	setActiveDraftFormAndField: typeof setActiveDraftFormAndField;
	openModal: typeof openModal;
	clonePortJob: typeof clonePortJob;
	retrievePortCallCycleStop: typeof retrievePortCallCycleStop;
}

export type ClonePortJobFormProps = InjectedFormProps<
	ClonePortJobFormData,
	ClonePortJobFormBaseProps
> &
	ClonePortJobFormBaseProps;

class ClonePortJobForm extends Component<ClonePortJobFormProps> {
	componentDidMount() {
		this.props.retrievePortCallCycleStop();
	}

	// eslint-disable-next-line
	updateFormValue = (field: ClonePortJobFormFieldName, value: any) => {
		this.props.change(field, value);
	};

	// eslint-disable-next-line
	updateAndResetFormValue = (field: ClonePortJobFormFieldName, value: any) => {
		this.props.change(field, value);
		this.props.resetSection(CLONE_PORTJOB_MODAL_FORM, field);
	};

	changeFormFields = (formFieldsToReset: ClonePortJobFormFieldName[]) => {
		formFieldsToReset.forEach(field =>
			this.updateAndResetFormValue(field, null)
		);
	};

	onAddDraftClick = (draftType: DraftType, formFieldName: string) => {
		this.props.setActiveDraftType({
			activeDraftType: draftType,
			activeDraftTypeAlias: 'Agent'
		});
		this.props.setActiveDraftFormAndField({
			fieldName: formFieldName,
			formId: CLONE_PORTJOB_MODAL_FORM
		});
		this.props.openModal({ name: ADD_COMPANY_DRAFT_MODAL });
	};

	search = (
		options: SearchCompaniesRequest = {} as SearchCompaniesRequest,
		withParentId = false
	) => (searchTerm: string) => {
		const formValues = this.props.formValues;
		const params: SearchCompaniesRequest = {
			searchTerm,
			sortBy: 'name:asc',
			onlyActive: true, // to not show deleted companies
			...options
		};

		const principalCompanyId = this.props.hubPrincipalCompany.key;
		if (withParentId && principalCompanyId) {
			params.withParentId = principalCompanyId;
		}

		return Api.Ports.searchPortLPAsForAutocomplete({
			portId: formValues.port.key,
			searchTerm: params.searchTerm
		});
	};

	onSubmit = () => {
		const { portCallId, jobCode, modalId } = this.props;
		this.props.clonePortJob({
			portCallId,
			jobCode,
			modalId
		});
	};

	onPortSelect = () => {
		const fieldsToReset = [
			ClonePortJobFormFieldName.PERFORMING_AGENT,
			ClonePortJobFormFieldName.CONTROLLING_AGENT
		];
		this.changeFormFields(fieldsToReset);
	};

	// eslint-disable-next-line
	onIntegrationOptionChange = (event: any) => {
		const { parentCloneJobData } = this.props;
		const fieldsToReset = [
			ClonePortJobFormFieldName.VESSEL,
			ClonePortJobFormFieldName.PORT,
			ClonePortJobFormFieldName.ETA,
			ClonePortJobFormFieldName.ETS
		];
		if (event.target.value === IntegrationOption.YES) {
			fieldsToReset.forEach(field =>
				this.updateFormValue(field, parentCloneJobData[field])
			);
			this.onPortSelect();
		} else if (event.target.value === IntegrationOption.NO) {
			this.changeFormFields(fieldsToReset);
			this.onPortSelect();
		}
	};

	render() {
		const {
			jobCode,
			jobType,
			formValues,
			hubPrincipalCompany,
			withParentId,
			isCloneJobFetchPending,
			isIntegrationDisabled,
			isIntegrationJob
		} = this.props;

		const isFormValid = formValues.isNewPortCall
			? formValues.isNewPortCall === ClonePortCallOption.CLONE_INSIDE
				? false
				: !this.props.valid
			: true;

		const isReIntegrationEnabled =
			formValues.enableIntegration === IntegrationOption.YES;

		return (
			<div>
				<Gap bottom="md" right="lg">
					<Text weight="bold">
						Clone job will create a copy of the{' '}
						{jobType === PortJobTypeCode.CHARTERER_OVERSIGHT
							? 'Charterers Oversight'
							: jobType}{' '}
						- {jobCode}. Please choose from one of the below options.
					</Text>
				</Gap>
				<Radio.Group.ReduxFormItem
					name={ClonePortJobFormFieldName.IS_NEW_PORTCALL}
				>
					<Radio value={ClonePortCallOption.CLONE_INSIDE}>
						Clone inside this Port Call
					</Radio>
					<Radio value={ClonePortCallOption.NEW_PORTCALL}>
						Clone and create a new Port Call
					</Radio>
				</Radio.Group.ReduxFormItem>
				{isIntegrationJob && (
					<MaybeTooltip
						show={isIntegrationDisabled}
						title="The job cannot be cloned since it has already linked to nomination/appointment job or job is progressed to Close Estimate/DA stage or already PDA approved atleast once or some GL transaction is in progress"
						placement="topLeft"
						getPopupContainer={() => document.body}
						overlayClassName={styles.tooltipContainer}
					>
						<Radio.Group.ReduxFormItem
							name={ClonePortJobFormFieldName.ENABLE_INTEGRATION}
							defaultValue={IntegrationOption.NO}
							label="Do you want to Re-link integration to new job?"
							onChange={this.onIntegrationOptionChange}
							disabled={isIntegrationDisabled}
						>
							<Radio value={IntegrationOption.YES}>Yes</Radio>
							<Radio value={IntegrationOption.NO}>No</Radio>
						</Radio.Group.ReduxFormItem>
					</MaybeTooltip>
				)}
				{formValues.isNewPortCall === ClonePortCallOption.NEW_PORTCALL && (
					<>
						Please provide below details to create new Port Call
						<Row>
							<Col sm={6} xs={6}>
								{
									<FieldVessels
										hubPrincipalCompany={hubPrincipalCompany}
										name={ClonePortJobFormFieldName.VESSEL}
										label="Vessel"
										required
										afterFocusClick
										disabled={isReIntegrationEnabled}
									/>
								}
							</Col>
							<Col sm={6} xs={6}>
								<AutocompletePorts
									name={ClonePortJobFormFieldName.PORT}
									label="Port"
									required
									afterFocusClick
									onSelect={this.onPortSelect}
									disabled={isReIntegrationEnabled}
								/>
							</Col>
						</Row>
						<Row>
							<Col md={6} sm={6} xs={6}>
								<DatePickerExtendedFormItem
									name={ClonePortJobFormFieldName.ETA}
									label="ETA"
									required
									help
									local
									disabled={isReIntegrationEnabled}
								/>
							</Col>
							<Col md={6} sm={6} xs={6}>
								<DatePickerExtendedFormItem
									name={ClonePortJobFormFieldName.ETS}
									label="ETS"
									help
									local
									disabled={isReIntegrationEnabled}
								/>
							</Col>
						</Row>
						{formValues.port && (
							<Row>
								<Col md={6} sm={6} xs={6}>
									<AgentField
										name={ClonePortJobFormFieldName.PERFORMING_AGENT}
										label="Performing Agent:"
										disabledKeys={
											formValues.controllingAgent
												? [formValues.controllingAgent.key]
												: []
										} // block choosing the item chosen in controlling agent
										required
										search={this.search}
										withParentId={withParentId}
										onAddDraftClick={this.onAddDraftClick}
										onlyShowNewDrafts
									/>
								</Col>
								<Col md={6} sm={6} xs={6}>
									<AgentField
										name={ClonePortJobFormFieldName.CONTROLLING_AGENT}
										label="Controlling Agent:"
										disabledKeys={
											formValues.performingAgent
												? [formValues.performingAgent.key]
												: []
										} // block choosing the item chosen in performing agent
										search={this.search}
										withParentId={withParentId}
										onAddDraftClick={this.onAddDraftClick}
										onlyShowNewDrafts
										allowClear
									/>
								</Col>
							</Row>
						)}
					</>
				)}
				<hr className={styles.horizontalRow} />
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button
								type="primary"
								transparent
								onClick={this.props.closeModal}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								disabled={isFormValid}
								loading={isCloneJobFetchPending}
								onClick={this.onSubmit}
							>
								Confirm
							</Button>
						</Flex>
					</Col>
				</Row>
				<AddCompanyDraftModal />
			</div>
		);
	}
}

const ClonePortjobReduxForm = reduxForm<
	ClonePortJobFormData,
	ClonePortJobFormBaseProps
>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: CLONE_PORTJOB_MODAL_FORM,
	validate
})(ClonePortJobForm);

const mapStateToProps = (state: AppState) => {
	const isCurrentUsersGroupTypeISSCluster = getIsCurrentUsersGroupTypeISSCluster(
		state
	);
	return {
		formValues: getClonePortJobFormData(state),
		withParentId: !isCurrentUsersGroupTypeISSCluster,
		performingAgentKeys: [],
		controllingAgentKeys: [],
		isCloneJobFetchPending: isCloneJobFetchPending(state)
	};
};

const mapDispatchToProps = {
	resetSection,
	setActiveDraftType,
	setActiveDraftFormAndField,
	openModal,
	clonePortJob,
	retrievePortCallCycleStop
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ClonePortjobReduxForm);
