import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';
import { KPIGroup } from 'sections/Administration/OpticConfiguration/KPIAndAlertsTab/constants';
import {
	OpticConfig,
	OpticConfigSectionsData,
	UpdateKpiDetailsByIdPayload
} from 'services/api/opticConfiguration/opticConfigServiceTypes';
import {
	EmailOption,
	KpiAlertsEditFormData,
	WeekendsOption,
	KPI_ALERTS_EDIT_FORM
} from 'sections/Administration/OpticConfiguration/KPIAndAlertsTab/KpiAlertsEdit/constants';
import { getValues } from 'store/form/selectors';

const getOpticConfigurationStateFetchStatus = (state: AppState) =>
	state.opticConfiguration.fetchStatuses;

export const getKpiAlertSections = (state: AppState) =>
	state.opticConfiguration.sections;

export const getKpiAlertEditDetails = (state: AppState) =>
	state.opticConfiguration.editKpi;

const kpiIndexSelection = (key: string) => {
	if (key === KPIGroup.JOB_KPIS) {
		return 'SEC01';
	} else if (key === KPIGroup.FINANCE_KPIS) {
		return 'SEC02';
	} else if (key === KPIGroup.OPERATION_KPIS) {
		return 'SEC03';
	} else {
		return '';
	}
};

export const getJobStatusesSection = createSelector(
	getKpiAlertSections,
	sections => {
		/** Here we are converting sections from object form to Array with groupName and index.
		 * Index is used to sort the sections
		 */
		let kpiSections: OpticConfigSectionsData[] = map(
			sections,
			(section, key) => ({
				groupName: key,
				section: section,
				index: kpiIndexSelection(key)
			})
		);
		/**
		 * Filtering the above kpiSections array to remove any unwanted
		 * sections introduced by API by any chance
		 */
		kpiSections = kpiSections.filter(section => {
			if (Object.values(KPIGroup).includes(section.groupName as KPIGroup)) {
				return {
					...section
				};
			} else {
				return false;
			}
		});
		return sortBy(kpiSections, ['index']);
	}
);

export const getRetrieveKPIStatus = createSelector(
	getOpticConfigurationStateFetchStatus,
	fetchStatuses =>
		fetchStatuses.retrieveKpiActiveSection === FetchStatus.PENDING
);

export const getRetrieveKpiDetailsByIdFetchStatus = (state: AppState) =>
	state.opticConfiguration.fetchStatuses.retrieveKPIDetailsById;

export const getEditKpiDetails = (state: AppState) =>
	state.opticConfiguration.editKpi;

const getHours = (value: number) => String(Math.floor(value / 3600));

const getMinutes = (value: number) => String(Math.floor((value % 3600) / 60));

export const getKpiAlertEditInitialValues = createSelector(
	getKpiAlertEditDetails,
	(editKpi: OpticConfig) => {
		let initialValues: Partial<KpiAlertsEditFormData> = {
			id: editKpi.id,
			kpiName: editKpi.name,
			userType: editKpi.userTypeCode,
			toleranceHours: getHours(editKpi.tolerance),
			toleranceMinutes: getMinutes(editKpi.tolerance),
			opticAlertPermission: {
				key: editKpi.functionalPermission.code,
				label: editKpi.functionalPermission.name
			},
			metricCode: editKpi.metricCode,
			isWeekendIncluded: editKpi.isWeekendIncluded
				? WeekendsOption.YES
				: WeekendsOption.NO
		};

		if (editKpi.hubAlert || editKpi.hubAlert === 0) {
			initialValues = {
				...initialValues,
				hubAlertHours: getHours(editKpi.hubAlert),
				hubAlertMinutes: getMinutes(editKpi.hubAlert),
				hubAlertPermission: editKpi.hubAlertPermission && {
					key: editKpi.hubAlertPermission.code,
					label: editKpi.hubAlertPermission.name
				}
			};
		}

		if (editKpi.notificationEmail || editKpi.notificationEmail === 0) {
			initialValues = {
				...initialValues,
				notificationEmailOption: EmailOption.HOURS_AND_MINUTES,
				notificationEmailHours: getHours(editKpi.notificationEmail),
				notificationEmailMinutes: getMinutes(editKpi.notificationEmail)
			};
		} else {
			initialValues = {
				...initialValues,
				notificationEmailOption: EmailOption.NOT_APPLICABLE
			};
		}

		if (editKpi.reminderEmail || editKpi.reminderEmail === 0) {
			initialValues = {
				...initialValues,
				reminderEmailOption: EmailOption.HOURS_AND_MINUTES,
				reminderEmailHours: getHours(editKpi.reminderEmail),
				reminderEmailMinutes: getMinutes(editKpi.reminderEmail)
			};
		} else {
			initialValues = {
				...initialValues,
				reminderEmailOption: EmailOption.NOT_APPLICABLE
			};
		}

		if (editKpi.lateEmail || editKpi.lateEmail === 0) {
			initialValues = {
				...initialValues,
				lateEmailOption: EmailOption.HOURS_AND_MINUTES,
				lateEmailHours: getHours(editKpi.lateEmail),
				lateEmailMinutes: getMinutes(editKpi.lateEmail)
			};
		} else {
			initialValues = {
				...initialValues,
				lateEmailOption: EmailOption.NOT_APPLICABLE
			};
		}

		return initialValues;
	}
);

export const getUpdateKpiDetailsFormData = (state: AppState) =>
	getValues<KpiAlertsEditFormData>(state, KPI_ALERTS_EDIT_FORM);

const getConvertedTime = (
	hours: string | undefined,
	minutes: string | undefined
) => {
	const time: number =
		(Number(hours) || 0) * 3600 + (Number(minutes) || 0) * 60;
	return time;
};

export const getPreparedFormData = createSelector(
	getUpdateKpiDetailsFormData,
	formData => {
		let data: Partial<UpdateKpiDetailsByIdPayload> = {
			id: formData.id,
			name: formData.kpiName,
			userTypeCode: formData.userType,
			tolerance: getConvertedTime(
				formData.toleranceHours,
				formData.toleranceMinutes
			),
			functionalPermission: formData.opticAlertPermission.key,
			metricCode: formData.metricCode,
			isWeekendIncluded: formData.isWeekendIncluded === WeekendsOption.YES
		};

		if (formData.hubAlertPermission) {
			data = {
				...data,
				hubAlert: getConvertedTime(
					formData.hubAlertHours,
					formData.hubAlertMinutes
				),
				hubAlertPermission: formData.hubAlertPermission.key
			};
		}

		if (formData.notificationEmailOption === EmailOption.HOURS_AND_MINUTES) {
			data = {
				...data,
				notificationEmail: getConvertedTime(
					formData.notificationEmailHours,
					formData.notificationEmailMinutes
				)
			};
		}

		if (formData.reminderEmailOption === EmailOption.HOURS_AND_MINUTES) {
			data = {
				...data,
				reminderEmail: getConvertedTime(
					formData.reminderEmailHours,
					formData.reminderEmailMinutes
				)
			};
		}

		if (formData.lateEmailOption === EmailOption.HOURS_AND_MINUTES) {
			data = {
				...data,
				lateEmail: getConvertedTime(
					formData.lateEmailHours,
					formData.lateEmailMinutes
				)
			};
		}

		return data;
	}
);
