import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { destroy } from 'redux-form';
import { EntityPermissionFetcher, AppNotifications } from 'containers';
import {
	PageHeader,
	Flex,
	ScrollableLayout,
	Loading,
	Error403
} from 'components';
import { Modal } from 'components/antd';
import SubHeader from '../SubHeader/SubHeaderWrapper';
import Form from '../CreatePortJob/Pages/Page3/Form/Form';
import { notify } from 'store/notifications/actions';
import { FORM } from '../CreatePortJob/createPortJobConstants';
import Footer from '../Footer/Footer';
import {
	retrievePortJobSummary,
	cleanPortJobSummary,
	addPortJobOperation,
	setActivePortJobCode,
	retrievePortJob
} from 'store/portJobs/actions';
import {
	isPortJobUpdating,
	getPortCallJobByCode
} from 'store/portJobs/portJobsSelectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import {
	EntityPermissionType,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { getPortCallContext } from 'store/portCall/portCallSelectors';
import { getIsFormOperationTypeLoadingOrDischarge } from '../CreatePortJob/Pages/Page3/page3Selectors';
import { isOperationFormValid } from '../CreatePortJob/Pages/Page3/helpers';
import { resetDrafts } from 'store/drafts/actions';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { AppState } from 'store-types';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';

interface PortJobRouteParams {
	portCallId: string;
	portJobCode: string;
}

interface AddOperationRouteProps
	extends RouteComponentProps<PortJobRouteParams> {
	isNewTab: boolean;
	destroy: typeof destroy;
	isPortJobUpdating: boolean;
	portJob: PortJob;
	notifySuccess: typeof notify.success;
	addPortJobOperation: typeof addPortJobOperation;
	setActivePortJobCode: typeof setActivePortJobCode;
	retrievePortJobSummary: typeof retrievePortJobSummary;
	cleanPortJobSummary: typeof cleanPortJobSummary;
	retrievePortJob: typeof retrievePortJob;
	isOperationFormOpen: boolean;
	isLoadingOrDischargeOperation: boolean;
	resetDrafts: typeof resetDrafts;
}

interface AddOperationRouteState {
	cancelCreationModalOpen: boolean;
	valid: boolean;
}

export class AddOperationRoute extends React.Component<
	AddOperationRouteProps,
	AddOperationRouteState
> {
	constructor(props: AddOperationRouteProps) {
		super(props);
		this.state = {
			cancelCreationModalOpen: false,
			valid: false
		};
	}

	componentDidMount() {
		const { portJobCode, portCallId } = this.props.match.params;
		this.props.setActivePortJobCode(portJobCode);
		this.props.retrievePortJobSummary({
			portCallId,
			jobCode: portJobCode
		});
		this.props.retrievePortJob({
			portCallId,
			jobCode: portJobCode,
			expand: ExpandPortJobType.OPERATIONS
		});
	}

	componentWillUnmount() {
		this.props.destroy(FORM.portJob);
		this.props.destroy(FORM.cargoLine);
		this.props.destroy(FORM.gradeLine);
		this.props.destroy(FORM.totalAmountLine);
		this.props.resetDrafts();
		this.props.cleanPortJobSummary();
	}

	onOpenTab = (_evt: React.MouseEvent<HTMLAnchorElement>) =>
		window.open(window.location.href, '_blank');

	onCloseTab = () => {
		this.onCancelCreationModalToggle();
	};

	onCancelCreationModalToggle = () => {
		this.setState({
			cancelCreationModalOpen: !this.state.cancelCreationModalOpen
		});
	};

	leavePortJobCreation = () => {
		if (this.props.isNewTab) {
			window.close();
		} else {
			if (this.props.history.action === 'PUSH') {
				this.props.history.goBack();
			} else {
				this.props.history.push(
					`/portcalls/${this.props.match.params.portCallId}/jobs/${this.props.match.params.portJobCode}/overview`
				);
			}
		}
	};

	onConfirmCancelCreation = () => {
		this.leavePortJobCreation();
		this.props.notifySuccess(
			'Operation adding process has been successfully cancelled.'
		);
	};

	onFormChange = (formValid: boolean) => {
		const { isOperationFormOpen, isLoadingOrDischargeOperation } = this.props;
		const valid = isOperationFormValid({
			formValid,
			isOperationFormOpen,
			isLoadingOrDischargeOperation
		});
		this.setState({ valid });
	};

	onAddClick = () => {
		this.props.addPortJobOperation({
			portCallId: this.props.match.params.portCallId,
			jobCode: this.props.match.params.portJobCode
		});
	};

	onCancelClick = () => {
		this.onCancelCreationModalToggle();
	};

	render() {
		if (this.props.isPortJobUpdating || !this.props.portJob) {
			return <Loading />;
		}
		const portJobCode = this.props.match.params.portJobCode;
		return (
			<Flex fit direction="vertical" stretch>
				<PageHeader.NewTab
					onClose={this.onCloseTab}
					isNewTab={false}
					hidePopout
				>
					Add Operation
				</PageHeader.NewTab>
				<ScrollableLayout>
					{portJobCode && (
						<EntityPermissionFetcher
							entityKey={portJobCode}
							entityType={EntityPermissionType.PORTJOB}
						>
							{({ canAdd }) =>
								(canAdd && (
									<>
										<AppNotifications.Alert />
										<SubHeader
											portCallId={this.props.match.params.portCallId}
											portJobCode={portJobCode}
										/>
										<ScrollableLayout asColumn>
											<ContextBar
												title="Please provide the details of the Operation"
												type={ContextBarType.ACTION}
												actor={[UserType.PRINCIPAL, UserType.HUB]}
												permissionCode={PermissionCode.MANAGE_PORTCALL}
												subtitle="First select the Operation Type, and then fill out the other requested details"
												condition={true}
											/>
											<Form onFormStateChange={this.onFormChange} />
										</ScrollableLayout>
										<Footer
											valid={this.state.valid}
											onOkClick={this.onAddClick}
											onCancelClick={this.onCancelClick}
										/>
									</>
								)) || <Error403 />
							}
						</EntityPermissionFetcher>
					)}
				</ScrollableLayout>
				<AppNotifications.Notification />
				{this.state.cancelCreationModalOpen && (
					<Modal
						visible
						okText={'Confirm'}
						closable={false}
						onOk={this.onConfirmCancelCreation}
						onCancel={this.onCancelCreationModalToggle}
					>
						Are you sure you want to cancel add operation process?
					</Modal>
				)}
			</Flex>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			match: {
				params: { portJobCode }
			}
		}: Pick<AddOperationRouteProps, 'match'>
	) => ({
		isPortJobUpdating: isPortJobUpdating(state),
		portJob: getPortCallJobByCode(state, portJobCode),
		isOperationFormOpen: !!getPortCallContext(state, 'operationFormVisible'),
		isLoadingOrDischargeOperation: getIsFormOperationTypeLoadingOrDischarge(
			state
		)
	}),
	{
		destroy,
		notifySuccess: notify.success,
		addPortJobOperation,
		setActivePortJobCode,
		retrievePortJob,
		retrievePortJobSummary,
		cleanPortJobSummary,
		resetDrafts
	}
)(AddOperationRoute);
