import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { retrieveUserByTokenAsync } from 'store/registration/actions/retrieveUserByToken';
import { registerUserByTokenAsync } from 'store/registration/actions/registerUserByToken';
import { initialState } from './registrationState';
import {
	retrievePreRegistrationAgentCompanyAsync,
	preRegisterAsync,
	preRegisterVerifyAsync,
	resetPreRegistration
} from './actions';
import {
	onRetrieveUserByTokenStarted,
	onRetrieveUserByTokenFailed,
	onRetrieveUserByTokenSuccess,
	onRegisterUserByTokenStarted,
	onRegisterUserByTokenFailed,
	onRegisterUserByTokenDone,
	onRetrievePreRegistrationAgentCompany,
	onPreRegister,
	onPreRegisterVerify,
	onResetPreRegistration
} from './reducers';

export default reducerWithInitialState(initialState)
	// Retrieve UserBy Token
	.case(retrieveUserByTokenAsync.started, onRetrieveUserByTokenStarted)
	.case(retrieveUserByTokenAsync.failed, onRetrieveUserByTokenFailed)
	.case(retrieveUserByTokenAsync.done, onRetrieveUserByTokenSuccess)
	// Register UserBy Token
	.case(registerUserByTokenAsync.started, onRegisterUserByTokenStarted)
	.case(registerUserByTokenAsync.failed, onRegisterUserByTokenFailed)
	.case(registerUserByTokenAsync.done, onRegisterUserByTokenDone)
	// Retrieve Secret Questions
	.case(
		retrievePreRegistrationAgentCompanyAsync.started,
		onRetrievePreRegistrationAgentCompany.started
	)
	.case(
		retrievePreRegistrationAgentCompanyAsync.done,
		onRetrievePreRegistrationAgentCompany.done
	)
	.case(
		retrievePreRegistrationAgentCompanyAsync.failed,
		onRetrievePreRegistrationAgentCompany.failed
	)
	.case(preRegisterAsync.started, onPreRegister.started)
	.case(preRegisterAsync.done, onPreRegister.done)
	.case(preRegisterAsync.failed, onPreRegister.failed)
	.case(preRegisterVerifyAsync.started, onPreRegisterVerify.started)
	.case(preRegisterVerifyAsync.done, onPreRegisterVerify.done)
	.case(preRegisterVerifyAsync.failed, onPreRegisterVerify.failed)
	.case(resetPreRegistration, onResetPreRegistration);
