import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { fullPage } from 'store/notifications/actions';
import rootReducer from 'store/reducers';
import { AppState } from 'store-types';
import middlewares from 'redux/middlewares';
import locationSync from './locationSync';

const createConfiguredSagaMiddleware = () =>
	createSagaMiddleware({
		onError: error => {
			store.dispatch(
				fullPage({
					error: {
						status: 'UNHANDLED'
					},
					description: error.message
				})
			);
		}
	});

declare const window: Window & {
	__REDUX_DEVTOOLS_EXTENSION_COMPOSE__: <R>(a: R) => R;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = (initialState: AppState | undefined) => {
	const sagaMiddleware = createConfiguredSagaMiddleware();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const store = createStore<AppState, AnyAction, any, any>(
		rootReducer,
		initialState,
		composeEnhancers(applyMiddleware(sagaMiddleware, thunk, ...middlewares))
	);

	return {
		store,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		runSaga: sagaMiddleware.run
	};
};

const { store, runSaga } = configureStore(undefined);

locationSync(store);

export { store, runSaga };
