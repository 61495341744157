import actionCreatorFactory from 'typescript-fsa';

import { AppState } from 'store-types';
import { UserFilters } from '../usersState';

/**
 * Actions
 */

const actionCreator = actionCreatorFactory();

const SET_USER_FILTERS = 'users/SET_USER_FILTERS';
export const setUserFilters = actionCreator<UserFilters>(SET_USER_FILTERS);

/**
 * Integration with redux-query-sync
 */

export const filtersSync = {
	uf: {
		selector: (state: AppState) => state.users.filters,
		action: setUserFilters,
		stringToValue: (value: string) => {
			try {
				return JSON.parse(value);
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: UserFilters) => JSON.stringify(value),
		defaultValue: {}
	}
};
