import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import {
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveCompanyVessel = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: (
		state: CompanyVesselsState,
		action: Success<RetrieveCompanyVesselRequest, RetrieveCompanyVesselResponse>
	): CompanyVesselsState => ({
		...state,
		byId: {
			...state.byId,
			[action.result.id]: {
				...action.result,
				companyId: action.params.companyId,
				vesselId: action.params.vesselId
			}
		}
	})
});
