import actionCreator from '../countriesActionCreator';

import {
	RetrieveCountriesRequest,
	RetrieveCountriesResponse
} from 'services/api/countries/countriesServiceTypes';

const RETRIEVE_COUNTRIES = 'RETRIEVE_COUNTRIES';
export const retrieveCountriesAsync = actionCreator.async<
	RetrieveCountriesRequest,
	RetrieveCountriesResponse,
	Error
>(RETRIEVE_COUNTRIES);

export const retrieveCountries = actionCreator<
	RetrieveCountriesRequest | undefined
>(RETRIEVE_COUNTRIES);

export const resetCountries = actionCreator(`RESET_${RETRIEVE_COUNTRIES}`);
