import client from 'services/client';
import queryString from 'query-string';
import {
	RetrieveCompanyTemplatesRequest,
	RetrieveCompanyTemplatesResponse,
	UpdateCompanyTemplatesRequest,
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse,
	DeleteCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileResponse,
	AddCompanyTemplateFileRequest,
	AddCompanyTemplateFileResponse
} from './templatesServiceTypes';
import {
	RetrieveTemplateRenderRequest,
	RetrieveTemplatesResponse,
	TemplateContent,
	TemplateRendered
} from 'services/api/templates/templateServiceTypes';
import { AxiosTypedPromise } from 'services/api/apiTypes';

const retrieveCompanyTemplates = ({
	companyId,
	templates
}: RetrieveCompanyTemplatesRequest) =>
	client.get<RetrieveCompanyTemplatesResponse>(
		`/companytemplates/${companyId}/templates`,
		{
			params: {
				templates
			},
			paramsSerializer: requestParams =>
				queryString.stringify(requestParams, { arrayFormat: 'index' })
		}
	);

const updateCompanyTemplates = ({
	companyId,
	data
}: UpdateCompanyTemplatesRequest) =>
	client.put(`/companytemplates/${companyId}/templates`, data);

const retrieveCompanyTemplateFiles = ({
	companyId
}: RetrieveCompanyTemplateFilesRequest) =>
	client.get<RetrieveCompanyTemplateFilesResponse>(
		`/templates/company-files/${companyId}`
	);

const retrieveTemplateRender = (
	request: RetrieveTemplateRenderRequest
): AxiosTypedPromise<TemplateRendered> => {
	const url = '/templates/' + request.name + '/render';

	return client.post(url, request.params);
};

const retrieveTemplate = (
	request: string
): AxiosTypedPromise<TemplateContent> => {
	const url = '/templates/' + request;
	return client.get(url);
};

const retrieveTemplates = (): AxiosTypedPromise<RetrieveTemplatesResponse> => {
	return client.get<RetrieveTemplatesResponse>('/templates');
};

const deleteCompanyTemplateFile = ({
	companyId,
	fileId
}: DeleteCompanyTemplateFileRequest) => {
	return client.delete(`/templates/company-files/${companyId}/${fileId}`);
};

const updateCompanyTemplateFile = ({
	companyId,
	fileId,
	data
}: UpdateCompanyTemplateFileRequest) => {
	return client.put<UpdateCompanyTemplateFileResponse>(
		`/templates/company-files/${companyId}/${fileId}`,
		data
	);
};

const uploadCompanyTemplateFile = ({
	companyId,
	data
}: AddCompanyTemplateFileRequest) => {
	return client.post<AddCompanyTemplateFileResponse>(
		`/templates/company-files/${companyId}`,
		data
	);
};

export default {
	retrieveTemplateRender,
	retrieveTemplate,
	retrieveTemplates,
	retrieveCompanyTemplates,
	updateCompanyTemplates,
	retrieveCompanyTemplateFiles,
	deleteCompanyTemplateFile,
	updateCompanyTemplateFile,
	uploadCompanyTemplateFile
};
