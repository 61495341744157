import { ConfigurationFeedbackManagementData } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateConfigurationFeedbackManagement = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	null,
	ConfigurationFeedbackManagementData
>('fetchStatuses', 'updateFeedbackManagement');
