import { FetchStatus } from 'services/api/apiTypes';
import { DirectBillsState } from '../directBillsState';
export const onResetDirectBills = (state: DirectBillsState) => ({
	...state,
	byId: {},
	allIds: [],
	hasMore: true,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.IDLE,
		update: FetchStatus.IDLE
	}
});
