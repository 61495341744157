import { mapValues, isArray } from 'lodash';
import {
	ConfigurationFileFormData,
	DEFAULT_VALUE
} from '../configurationFilesTypes';

export const prepareFormValuesForEdit = (
	formValues: ConfigurationFileFormData
) =>
	mapValues(formValues, (value, key) => {
		if (isArray(value) && !value.length) {
			if (key === 'ports') {
				return [{ key: DEFAULT_VALUE, label: DEFAULT_VALUE }];
			}
			return [DEFAULT_VALUE];
		}
		return value;
	});

export const prepareFormValuesForSubmit = (
	formValues: ConfigurationFileFormData
) =>
	mapValues(formValues, (value, key) => {
		if (!isArray(value)) {
			return value;
		}
		if (key === 'ports') {
			return value.filter(v => v.key !== DEFAULT_VALUE);
		}
		return value.includes(DEFAULT_VALUE)
			? value.filter(v => v !== DEFAULT_VALUE)
			: value;
	});
