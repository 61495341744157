import { AppState } from 'store-types';
import { EntitiesSelectMap } from 'app-types';
import { pickBy, keys } from 'lodash';
import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';
import { getFinanceContextualizationFormValues } from 'store/form/financeContextualization/selectors';
import {
	CreateFinanceDocumentsRequest,
	FinanceDocumentContextualization
} from 'services/api/finance/financeServiceTypes';

const getSelectedKeys = (value: EntitiesSelectMap = {}) =>
	keys(pickBy(value, v => v));

const prepareRequest = (state: AppState, documentId: string) => {
	const values = getFinanceContextualizationFormValues(state);
	const wizardForm = getContextualizationWizardForm(state);

	const contextualizations = values.pages.reduce<
		FinanceDocumentContextualization[]
	>((result, value, key) => {
		const pageNumber = key + 1;
		const page = wizardForm.pages[pageNumber];
		if (page?.valid) {
			result.push({
				number: pageNumber,
				das: getSelectedKeys(value.das),
				services: getSelectedKeys(value.services)
			});
		}
		return result;
	}, []);

	return {
		documentId,
		contextualizations
	} as CreateFinanceDocumentsRequest['data'];
};

export default prepareRequest;
