import { LabeledValue, Entity, EntityCode, EntityField } from 'app-types';

export const idToIdField = (id: string) => ({ id });
export const keyToIdField = (field: LabeledValue | null) => ({
	id: field ? field.key : ''
});

export const optionalKeyToStringField = (field?: LabeledValue) =>
	field ? field.key : undefined;
export const keyToStringField = (field: LabeledValue) => field.key;

export const labelToNameField = (
	field: LabeledValue<string> | null | undefined
): EntityField => {
	return field
		? {
				id: field.key,
				name: field.label
		  }
		: { id: '' };
};

export const optionalLabelToNameField = (field?: LabeledValue<string> | null) =>
	field ? labelToNameField(field) : undefined;

export const nameToLabelField = (field: Entity) => ({
	key: field.id,
	label: field.name,
	title: field.name
});

export const nameCodeToLabelField = (field: EntityCode) => ({
	key: field.code,
	label: field.name,
	title: field.name
});

export const optionalNameToLabelField = (field: Entity | undefined) =>
	field ? nameToLabelField(field) : undefined;

export const stringToLabelField = (field: string) => ({
	key: field,
	label: field,
	title: field
});

export const parseToIntIfExists = (formValue: string) =>
	formValue ? parseInt(formValue, 10) : undefined;
