import { DeveloperPortalState } from '../developerPortalState';
import { outBoundEventsMap } from 'sections/Administration/DeveloperPortal/DeveloperPortalTab/constants';

export const onSetSelectedOutBoundList = (
	state: DeveloperPortalState,
	list: string[]
): DeveloperPortalState => {
	const outboundList: string[] = [];
	list.forEach(apiId => {
		const api = state.context.outboundApiList.find(
			outboundApi => outboundApi.id === apiId
		);
		if (api?.methodName && outBoundEventsMap[api.methodName]) {
			outboundList.push(outBoundEventsMap[api.methodName]);
		}
	});
	return {
		...state,
		context: {
			...state.context,
			selectedOutBoundApi: [...outboundList]
		}
	};
};
