import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState, initialState } from 'store/threads/threadsState';
import {
	RetrieveThreadsAvailableGroupsResponse,
	ThreadsAvailableGroup
} from 'services/api/threads/threadsServiceTypes';
import { keyBy } from 'lodash';

export const onRetrieveThreadsAvailableGroupsSuccess = (
	state: ThreadsState,
	{ result }: Success<void, RetrieveThreadsAvailableGroupsResponse>
): ThreadsState => ({
	...state,
	availableGroups: keyBy<ThreadsAvailableGroup>(
		result.elements,
		item => item.id
	)
});

export const onResetThreadsAvailableGroups = (
	state: ThreadsState
): ThreadsState => ({
	...state,
	availableGroups: initialState.availableGroups,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveAvailableGroups: initialState.fetchStatuses.retrieveAvailableGroups
	}
});

export const onRetrieveThreadsAvailableGroups = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	undefined,
	RetrieveThreadsAvailableGroupsResponse
>('fetchStatuses', 'retrieveAvailableGroups', {
	doneReducer: onRetrieveThreadsAvailableGroupsSuccess
});
