export enum UnitType {
	LENGTH = 'LGH',
	VOLUME = 'VOL',
	MASS = 'MAS',
	QUANTITY = 'QTY',
	AREA = 'ARA',
	PRESSURE = 'PRS',
	TEMPERATURE = 'TMP',
	TIME = 'TME',
	POWER = 'PWR'
}

interface UnitOfMeasurementType {
	code: UnitType;
	name: string;
}

interface UnitOfMeasurementGetResponse {
	code: string;
	display: string;
	unitOfMeasurementTypes: UnitOfMeasurementType[];
	name: string;
	createdOn: Date;
	updatedOn: Date;
	isDisabled: boolean;
}

interface UnitOfMeasurementCreateRequest {
	name: string;
	code: string;
	display: string;
	unitOfMeasurementTypeId: string;
}
export interface Unit extends UnitOfMeasurementGetResponse {}

export interface CreateUnitRequest extends UnitOfMeasurementCreateRequest {}

export interface CreateUnitResponse {
	url: string;
	options: {
		method: 'POST';
		[key: string]: {};
	};
}

export interface SearchUnitsParams {
	unitsOfMeasurementTypes?: UnitType[];
}
