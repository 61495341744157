import { FinanceState } from '../financeState';
import { Success, Failure } from 'typescript-fsa';

import {
	RetrieveFinanceDetailsRequest,
	FinanceDetails
} from 'services/api/finance/financeServiceTypes';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveFinanceDetails = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveFinanceDetailsRequest,
	FinanceDetails
>('fetchStatuses', 'retrieveFinanceDetails', {
	doneReducer: onRetrieveFinanceDetailsSuccess,
	failedReducer: onRetrieveFinanceDetailsFailed
});

function onRetrieveFinanceDetailsSuccess(
	state: FinanceState,
	action: Success<RetrieveFinanceDetailsRequest, FinanceDetails>
): FinanceState {
	return {
		...state,
		financeDetails: action.result
	};
}

function onRetrieveFinanceDetailsFailed(
	state: FinanceState,
	action: Failure<RetrieveFinanceDetailsRequest, AxiosGeneralError>
): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: action.error.response.status === 404
		}
	};
}
