import { FilterItem, FilterState } from 'store/filters/filtersState';
import {
	ThreadsFilterParamName,
	paramsStatusMap,
	filterTypeMap,
	filterIdentifiers
} from 'store/threads/filtersSync';
import { omitBy, reduce } from 'lodash';
import { RetrieveThreadsFilters } from 'services/api/threads/threadsServiceTypes';
import { ThreadsFilterStatus } from 'types/sections/ThreadsTypes';
import { extractSearchParams, filterExist } from 'store/filters/filters.func';

const {
	SEARCH,
	ASSIGNED_TO,
	ONLY_UNREAD,
	STATUS,
	TAGS
} = ThreadsFilterParamName;

export const prepareForSearch = (filters: FilterItem[]) => {
	// map all filter objects to collections of their corresponding filter parameter and filter value
	return filterExist<RetrieveThreadsFilters>(
		filters,
		extractSearchParams(filterTypeMap, filters, filterIdentifiers)
	);
};

/** @TODO Missing Unit test */
function filterAssignedTo(filters: FilterItem[]) {
	const result: RetrieveThreadsFilters = {};
	const parameterValue = filters[0].key;
	const parameterType = filters[0].type;
	switch (parameterType) {
		case ThreadsFilterParamName.ASSIGNED_TO_USER:
			result.assignedToUser = parameterValue;
			break;
		case ThreadsFilterParamName.ASSIGNED_TO_GROUP:
			result.assignedToGroup = parameterValue;
			break;
		default:
			break;
	}
	return result;
}

const prepareForAssignedTo = (filter: FilterItem[]) => {
	return filterExist<RetrieveThreadsFilters>(filter, filterAssignedTo);
};

export default function prepareRequest(filters: FilterState = {}) {
	const parameters = Object.values(ThreadsFilterParamName);
	const preparedParameters = reduce<FilterState, RetrieveThreadsFilters>(
		filters,
		(result, value, key: ThreadsFilterParamName) => {
			// skip not expected filter parameters
			if (!parameters.includes(key)) {
				return result;
			}

			const parameterValue = value[0].key;

			if (key === SEARCH) {
				result = {
					...result,
					...prepareForSearch(value)
				};
				return result;
			}

			if (key === ASSIGNED_TO) {
				result = {
					...result,
					...prepareForAssignedTo(value)
				};
				return result;
			}

			if (key === ONLY_UNREAD && parameterValue) {
				result.onlyUnread = parameterValue === ONLY_UNREAD;
				return result;
			}

			if (key === STATUS) {
				result.statuses = paramsStatusMap[parameterValue];
				return result;
			}

			if (key === TAGS) {
				const tags = value.map(tag => tag.key);
				result.tags = tags;
				return result;
			}

			result[key] = parameterValue;
			return result;
		},
		{}
	);

	if (!preparedParameters.statuses) {
		// we should display statuses that belong to `UNRESOLVED` by default
		preparedParameters.statuses =
			paramsStatusMap[ThreadsFilterStatus.UNRESOLVED];
	}

	return omitBy<RetrieveThreadsFilters>(
		preparedParameters,
		parameter => !parameter
	);
}
