import { put } from 'redux-saga/effects';
import Api from 'services/api';
import {
	ConfigurationExtendedMessagingData,
	RetrieveExtendedMessagingRequest
} from 'services/api/companies/companiesServiceTypes';
import { notify } from 'store/notifications/actions';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveExtendedMessagingConfigurationAsync } from '../actions';

export default makeTakeEveryWatcher<
	RetrieveExtendedMessagingRequest,
	ConfigurationExtendedMessagingData,
	Error
>({
	api: Api.Companies.retrieveExtendedMessagingConfiguration,
	async: retrieveExtendedMessagingConfigurationAsync,
	*onError() {
		yield put(
			notify.error(
				`Error occurred while retrieving extended messaging for principal.`
			)
		);
	}
});
