import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { resetCompanies } from 'store/companies';
import { useDispatch } from 'react-redux';
import CompanyDetails from './CompanyDetails/CompanyDetails';
import CompaniesTable from './CompaniesTable/CompaniesTable';

interface CompaniesTabProps {
	path: string;
}

const CompaniesTab: React.SFC<CompaniesTabProps> = ({ path }) => {
	const dispatch = useDispatch();

	useEffect(
		() => () => {
			dispatch(resetCompanies());
		},
		[dispatch]
	);

	return (
		<>
			<Route component={CompaniesTable} path={path} exact />
			<Route path={`${path}/:id`} component={CompanyDetails} />
		</>
	);
};

export default CompaniesTab;
