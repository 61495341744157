import * as React from 'react';
import { Tag, Button } from 'components/antd';
import { TagProps } from 'components/antd/Tag/Tag';
import classNames from 'classnames';

import styles from './JobCodeTag.module.scss';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';

const JOB_CODE_CLASS_NAMES = [
	styles.jobCode1,
	styles.jobCode2,
	styles.jobCode3,
	styles.jobCode4,
	styles.jobCode5,
	styles.jobCode6
];

/**
 * In order to maintain a relation between jobcodes and colors,
 * we take ascii value of each jobcode character (with charCodeAt())
 * and sum all of those values to create a common hash.
 * Then (in JobCodeTag compoennt) we do modulus operation of the resultant value
 * against 6 (which is the length of the colors array)
 * to ensure that the gathered color would be the same on every situation for the same jobcode
 */
const getCodeHash = (jobCode: string): number => {
	const MULTIPLIER = 5;
	return jobCode
		.split('')
		.reduce(
			(prev, _current, i) => jobCode.charCodeAt(i) * MULTIPLIER + prev,
			0
		);
};

const getJobCodeClassName = (jobCode: string) =>
	JOB_CODE_CLASS_NAMES[getCodeHash(jobCode) % JOB_CODE_CLASS_NAMES.length];

interface JobCodeTagProps {
	jobCode: PortJob['code'];
	closable?: boolean;
	onClose?: TagProps['onClose'];
	afterClose?: TagProps['afterClose'];
}

export const JobCodeTag: React.FC<JobCodeTagProps> = ({
	jobCode,
	children,
	...handlers
}) => (
	<Tag
		borderless
		className={classNames(styles.jobLabel, getJobCodeClassName(jobCode))}
		{...handlers}
	>
		{children}
	</Tag>
);

export const NoColorTag: React.FC<{
	onClick?: () => void;
	isSelected?: boolean;
}> = ({ onClick, children, isSelected }) => (
	<Button type="primary" transparent onClick={onClick}>
		<span
			className={classNames(styles.noColorTag, {
				[styles.notSelected]: !isSelected
			})}
		>
			{children}
		</span>
	</Button>
);
