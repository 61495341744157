import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { makePollDownloadExecutor } from 'utils/sagaHelpers/pollDownloadExecutor';
import { DownloadVPRevisionRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import Api from 'services/api';
import { downloadVPRevisionAsync as async } from '../actions/downloadVPRevision';

const POSTApi = Api.VesselProgramme.downloadVPRevision;
const GETApi = Api.VesselProgramme.pollDownloadVPRevision;

const downloadExecutor = makePollDownloadExecutor<
	DownloadVPRevisionRequest,
	RetrieveDownloadDocumentResponse,
	Error
>({
	async,
	POSTApi,
	GETApi
});

export default makeTakeLatestWatcher<
	DownloadVPRevisionRequest,
	RetrieveDownloadDocumentResponse,
	Error
>({ async }, downloadExecutor);
