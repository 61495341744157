import {
	InviteAgentToPortJobPayload,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

const ACTION_NAME = 'INVITE_AGENT_TO_PORTJOB';

export const inviteAgentToPortJob = actionCreator<InviteAgentToPortJobPayload>(
	ACTION_NAME
);
export const inviteAgentToPortJobAsync = actionCreator.async<
	InviteAgentToPortJobPayload,
	PortJobStatusUpdateResponse,
	Error
>(ACTION_NAME);
