import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import {
	makeTakeLatestWatcher,
	makeDefaultExecutor
} from 'utils/sagaHelpers/sagaHelpers';
import {
	RetrieveThreadsPendingAlertsResponse,
	RetrieveThreadsPendingAlertsRequest
} from 'services/api/threads/threadsServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import Api from 'services/api';
import { retrieveThreadsPendingAlertsAsync as async } from '../actions/retrieveThreadsPendingAlerts';
import { makeGetHasPermissionTable } from 'store/auth/selectors/permissionsSelector';
import { getActivePortCallIdExecutor } from 'utils/sagaHelpers/portCallSagaExecutor';

export const defaultExecutor = makeDefaultExecutor<
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse,
	Error
>({
	api: Api.Threads.retrieveThreadsPendingAlerts,
	async
});

export const executor = function*(
	params: RetrieveThreadsPendingAlertsRequest
): SagaIterator {
	const getHasPermissionTable = makeGetHasPermissionTable();
	const [hasPermission] = yield select(getHasPermissionTable, [
		PermissionCode.VIEW_MESSAGES
	]);
	// Get PortCallId from the store if no param was specified
	const portCallId: string = yield call(getActivePortCallIdExecutor, params);
	if (!hasPermission || !portCallId) {
		// skip retrieving pending alerts if there is no VIEW_MESSAGES permission for a user or we don't have portCallId
		return;
	}
	yield call(defaultExecutor, { portCallId });
};

export default makeTakeLatestWatcher<
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse,
	Error
>(
	{
		async
	},
	executor
);
