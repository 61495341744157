import { ErrorOther } from 'redux-form';
import { camelCase, isEmpty, isString, forEach } from 'lodash';
import { isArray } from 'util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormError = ErrorOther<string> & { [key: string]: any };

export const getFormErrors = (error: object | string): FormError => {
	if (isEmpty(error)) {
		return {};
	}

	if (isString(error)) {
		return {
			_error: error
		};
	}

	const errors: FormError = {};
	const errorKeys = Object.keys(error);

	forEach(errorKeys, errorKey => {
		const key = camelCase(errorKey);
		const value = isArray(error[errorKey])
			? error[errorKey].join(', ')
			: error[errorKey];

		errors[key] = value;
	});

	return errors;
};
