import { AddPortJobOperationResponse } from 'services/api/portJobs/portJobsServiceTypes';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export interface AddPortJobOperationActionParams {
	portCallId: string;
	jobCode: string;
}

// ADD OPERATION TO EXISTING PORT JOB
export const ADD_PORTJOB = 'portjobs/ADD_OPERATION';
export const addPortJobOperationAsync = actionCreator.async<
	AddPortJobOperationActionParams,
	AddPortJobOperationResponse,
	Error
>(ADD_PORTJOB, {
	templateParams: {
		entityName: 'Operation'
	},
	done: {
		// handled in worker
		omit: true
	}
});
export const addPortJobOperation = actionCreator<
	AddPortJobOperationActionParams
>(addPortJobOperationAsync.type);
