import * as React from 'react';
import classNames from 'classnames';
import styles from './UsersFilters.module.scss';

import { Col, Row } from 'components/antd';
import { AutocompleteSearch } from 'components';
import UsersFiltersDropdown from './UsersFiltersDropdown';

import Api from 'services/api';
import { UserFilters, UserFilterSearchGroupType } from 'store/users/usersState';
import {
	UserSearchGroupType,
	UserSearchResultItem
} from 'services/api/users/userServiceTypes';
import { LabeledValue } from 'app-types';
import { ItemObject } from 'components/AutocompleteSearch/AutocompleteSearchTypes';

interface UsersFiltersProps {
	className?: string;
	filters: UserFilters;
	onChange: (filters: UserFilters) => void;
}

interface UsersFiltersState {
	status?: LabeledValue<string>;
}

export const DEFAULT_STATUS_FILTER_VALUE = {
	key: 'all',
	label: 'All'
};

export class UsersFilters extends React.Component<
	UsersFiltersProps,
	UsersFiltersState
> {
	constructor(props: UsersFiltersProps) {
		super(props);

		this.state = {
			status: props.filters.status || DEFAULT_STATUS_FILTER_VALUE
		};
	}

	onChangeStatus = (value: LabeledValue<string>) => {
		this.setState({ status: value }, () => {
			this.props.onChange({
				...this.props.filters,
				status: value
			});
		});
	};

	onSearch = (inputValue: string, groupType: UserSearchGroupType) => {
		return Api.Users.searchUsers(inputValue, groupType).then(
			result => result.data.groups
		);
	};

	onChangeSearch = (
		option: ItemObject,
		groupType: UserFilterSearchGroupType
	) => {
		let search = this.props.filters.search || [];
		if (groupType === 'Search') {
			search = search.filter(item => item.type !== 'Search');
		}
		search = [
			...search,
			{
				type: groupType,
				id: option.key,
				name: option.label
			}
		];
		this.props.onChange({
			...this.props.filters,
			search
		});
	};

	itemToString = (item: ItemObject<UserSearchResultItem>) => {
		if (!item) {
			return '';
		}
		return item.email ? `${item.label} (${item.email})` : item.label;
	};

	render() {
		return (
			<div className={classNames(styles.root, this.props.className)}>
				<Row>
					<Col xs={12} sm={5}>
						<AutocompleteSearch
							onSearch={Api.Users.searchUsersForAutocomplete}
							onChange={this.onChangeSearch}
							itemToString={this.itemToString}
							placeholder="Start typing to search by email, user name or company"
						/>
					</Col>
					<Col xs={2} sm={1}>
						<label>Status:</label>
					</Col>
					<Col xs={4} sm={2}>
						<UsersFiltersDropdown
							name="status"
							onChange={this.onChangeStatus}
							value={this.state.status}
						/>
					</Col>
				</Row>
			</div>
		);
	}
}

export default UsersFilters;
