import React from 'react';
import { Flex } from 'components';
import { ErrorStatusCode } from 'services/api/apiErrorTypes';
import { Justify } from 'components/types';
import styles from './ErrorPageScreen.module.scss';

const ERROR_ICON_STATUS_MAP = {
	[ErrorStatusCode.BAD_REQUEST]: require('../images/400.svg'),
	[ErrorStatusCode.NOT_FOUND]: require('../images/404.svg'),
	[ErrorStatusCode.FORBIDDEN]: require('../images/403.svg'),
	[ErrorStatusCode.SERVER]: require('../images/500.svg'),
	[ErrorStatusCode.UNAVAILABLE]: require('../images/503.svg'),
	UNHANDLED: require('../images/unhandled.svg')
};

export interface ErrorPageScreenProps {
	status?: number | 'UNHANDLED';
	title?: React.ReactNode;
	description?: React.ReactNode;
}
const ErrorPageScreen: React.FC<ErrorPageScreenProps> = ({
	status,
	title,
	description,
	children
}) => {
	const icon = status && ERROR_ICON_STATUS_MAP[status];
	return (
		<Flex
			fit
			align="center"
			direction="vertical"
			justify={Justify.CENTER}
			className={styles.root}
		>
			<div>
				{(icon || status) && (
					<>
						{icon ? (
							<img src={icon} alt="icon" />
						) : (
							<div className={styles.status}>{status}</div>
						)}
						<hr />
					</>
				)}
				{title && <h1>{title}</h1>}
				{description && <div className={styles.lead}>{description}</div>}
				{children && <div className={styles.lead}>{children}</div>}
			</div>
		</Flex>
	);
};

export default ErrorPageScreen;
