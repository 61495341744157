import { FinanceState } from '../financeState';
import { Success } from 'typescript-fsa';
import {
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveFinanceDocumentSuccess = (
	state: FinanceState,
	{
		result
	}: Success<RetrieveFinanceDocumentRequest, RetrieveFinanceDocumentResponse>
): FinanceState => ({
	...state,
	document: result
});

export const onRetrieveFinanceDocument = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse
>('fetchStatuses', 'retrieveFinanceDocument', {
	doneReducer: onRetrieveFinanceDocumentSuccess
});
