import { omitBy, isNil } from 'lodash';
import { PortCallsState } from 'store/portcalls/portCallsState';
import { PortCallContext } from '../portCallsState';
export const onSetPortCallContext = (
	state: PortCallsState,
	action: Partial<PortCallContext>
): PortCallsState => ({
	...state,
	context: {
		...state.context,
		...omitBy(action, isNil),
		portCallOperationsTab: state.context.portCallOperationsTab
	}
});
