import { SagaIterator, buffers } from 'redux-saga';
import {
	put,
	take,
	actionChannel,
	select,
	call,
	delay
} from 'redux-saga/effects';
import { retrievePortJobAsync } from 'store/portJobs/actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	retrieveVPAsync,
	refreshVPProcessAsync
} from 'store/vesselProgramme/actions';
import { retrieveFinanceAsync } from 'store/finance';
import { retrieveOperationsAsync } from 'store/operations/actions/retrieveOperations';
import {
	retrievePortCallShipMeasurementsAsync,
	retrievePortCallCargoMeasurementsAsync,
	retrievePortCallBunkeringMeasurementsAsync
} from 'store/portCallMeasurements/actions';
import { retrievePortCallAsync } from 'store/portcalls/actions';
import { retrieveUploadedDocumentsAsync } from 'store/portCallDocuments';
import { retrievePortCallSectionsAsync } from 'store/portCallOperations/actions';
import { retrievePortCallAlerts } from '../actions';

function* refreshAlerts(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (portCallId) {
		yield put(retrievePortCallAlerts({ portCallIds: [portCallId] }));
	}
}

export default function* watcher(): SagaIterator {
	const channel = yield actionChannel(
		[
			retrievePortCallAsync.done,
			retrievePortJobAsync.done,
			retrieveVPAsync.done,
			refreshVPProcessAsync.started,
			retrieveFinanceAsync.done,
			retrieveOperationsAsync.done,
			retrievePortCallShipMeasurementsAsync.done,
			retrievePortCallCargoMeasurementsAsync.done,
			retrievePortCallBunkeringMeasurementsAsync.done,
			retrieveUploadedDocumentsAsync.done, // operations/documents tab
			retrievePortCallSectionsAsync.done, // operations/events tab
			retrievePortCallCargoMeasurementsAsync.done,
			retrievePortCallShipMeasurementsAsync.done,
			retrievePortCallBunkeringMeasurementsAsync.done
		],
		buffers.none()
	);

	while (true) {
		yield take(channel);
		yield call(refreshAlerts);
		yield delay(1000); // debounce to avoid calling this handler again right after
	}
}
