import React from 'react';
import { PortJobCargoLineWithCountry } from 'services/api/portJobs/portJobsServiceTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import Terms from './Terms';
import { Amount, SplitIndex, Text } from 'components';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import { FormattedTime } from 'containers';
import { Icon } from 'components/antd';

export default (operationTypeCode: OperationTypeCode) => {
	const includeLoadFrom = operationTypeCode === OperationTypeCode.LOADING_STS;
	const columns: Array<ColumnProps<PortJobCargoLineWithCountry>> = [
		{
			title: 'Cargo Ref',
			dataIndex: 'referenceNumber',
			render: (text, record) => (
				<>
					<OptionalValue value={text} />
					{record.splitIndex && <SplitIndex splitIndex={record.splitIndex} />}
				</>
			),
			width: '10%'
		},
		{
			title: 'Principal Voyage Reference',
			dataIndex: 'principalVoyageReference',
			render: text => <OptionalValue value={text} />,
			width: '15%'
		},
		{
			title: 'Commodity detail',
			dataIndex: 'commodity.name',
			render: (commodity: string, record: PortJobCargoLineWithCountry) =>
				commodity ? commodity : record.mainCommodity.name,
			width: '10%'
		},
		{
			title: 'Nomination Qty',
			dataIndex: 'commodityQuantity',
			render: (
				commodityQuantity: number,
				record: PortJobCargoLineWithCountry
			) => (
				<OptionalValue value={commodityQuantity}>
					<Amount
						value={commodityQuantity}
						uomCode={record.commodityUnitOfMeasurementCode}
					/>
				</OptionalValue>
			),
			width: '9%'
		},
		{
			title: 'Shipper',
			dataIndex: 'shipperCompany.name',
			render: text => <OptionalValue value={text} />,
			width: '10%'
		},
		{
			title: <Text nowrap>Charter Party Date</Text>,
			dataIndex: 'charterPartyDate',
			render: (time: string) => (
				<OptionalValue value={time}>
					<Text nowrap>
						<FormattedTime value={time} format="DD MMM YYYY" />
					</Text>
				</OptionalValue>
			),
			width: includeLoadFrom ? '10%' : '12%'
		},
		{
			title: 'Laycan From',
			dataIndex: 'laycanFromPlt',
			render: (time: string) => (
				<OptionalValue value={time}>
					<Text nowrap>
						<FormattedTime value={time} />
					</Text>
				</OptionalValue>
			),
			width: includeLoadFrom ? '6%' : '8%'
		},
		{
			title: 'Laycan To',
			dataIndex: 'laycanToPlt',
			render: (time: string) => (
				<OptionalValue value={time}>
					<Text nowrap>
						<FormattedTime value={time} />
					</Text>
				</OptionalValue>
			),
			width: includeLoadFrom ? '6%' : '8%'
		},
		{
			title: 'Discharge Port',
			dataIndex: 'dischargePort.name',
			render: (text, record: PortJobCargoLineWithCountry) =>
				record.dischargePortIsUnknown || !text ? (
					<OptionalValue value={record.dischargeCountryName} />
				) : (
					<OptionalValue value={text} />
				),
			width: includeLoadFrom ? '6%' : '8%'
		},
		{
			title: 'Options/Terms',
			dataIndex: 'terms',
			render: (terms: string) => (
				<OptionalValue value={terms}>
					<Terms terms={terms} />
				</OptionalValue>
			),
			width: includeLoadFrom ? '8%' : '10%'
		},
		{
			title: '',
			dataIndex: 'isLinkedCargo',
			render: (isLinkedCargo: boolean) =>
				isLinkedCargo && <Icon type="link" color="primary" />,
			width: '2%'
		}
	];
	if (includeLoadFrom) {
		columns.splice(7, 0, {
			title: 'Load From',
			dataIndex: 'loadingFromVessel.name',
			render: text => <OptionalValue value={text} />,
			width: '10%'
		});
	}
	return columns;
};
