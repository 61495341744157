import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';
import { DeletePortJobOperationRequest } from 'services/api/portJobs/portJobsServiceTypes';

export const onDeletePortJobOperationStarted = (
	state: PortJobState
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		operationDeleting: FetchStatus.PENDING
	}
});

export const onDeletePortJobOperationSuccess = (
	state: PortJobState,
	{ params }: Success<DeletePortJobOperationRequest, void>
): PortJobState => {
	const portJob =
		state.byId &&
		Object.keys(state.byId)
			.map(key => state.byId[key])
			.find(job => job.code === params.portJobCode);
	if (!portJob) {
		return state;
	}
	return {
		...state,
		byId: {
			...state.byId,
			[portJob.id]: {
				...portJob,
				operations: portJob.operations.filter(
					operation =>
						operation.portJobOperationId !== params.portJobOperationId
				)
			}
		},
		fetchStatuses: {
			...state.fetchStatuses,
			operationDeleting: FetchStatus.SUCCESS
		}
	};
};

export const onDeletePortJobOperationFailed = (state: PortJobState) => ({
	...state,
	error: new Error('Cannot delete PortJob operation'),
	fetchStatuses: {
		...state.fetchStatuses,
		operationDeleting: FetchStatus.FAILURE
	}
});
