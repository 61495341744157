import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { Modal } from 'components/antd';
import { openModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { CommonActionModalProps } from '../ActionModalTypes';
import EnableOperationsForm from './EnableOperationsForm';
import {
	ENABLE_OPERATIONS_CONFIRMATION_MODAL,
	ENABLE_OPERATIONS_FORM
} from './EnableOperationsFormTypes';
import { isInvalid } from 'redux-form';
import { updateEnableOperations } from 'store/portJobs/actions';
import EnableOperationsClosePopUpModal from './EnableOperationsClosePopUpModal';

interface EnableOperationsModalProps extends CommonActionModalProps {
	// from mapStateToProps
	jobCode: string;
	portCallId: string;
	visible: boolean;
	isFormValid: boolean;
	// from mapDispatchToProps
	openModal: typeof openModal;
	updateEnableOprn: typeof updateEnableOperations;
}

class EnableOperationsModal extends Component<EnableOperationsModalProps> {
	hideConfirmationModal = () => {
		this.props.openModal(ENABLE_OPERATIONS_CONFIRMATION_MODAL);
	};

	onSubmit = () => {
		this.props.updateEnableOprn();
	};

	render() {
		const { visible, isFormValid } = this.props;
		return (
			<>
				<Modal
					visible={visible}
					title="Select Service Section / Choose alternative service"
					onCancel={this.hideConfirmationModal}
					onOk={this.onSubmit}
					okDisabled={isFormValid}
					size={'md'}
				>
					<EnableOperationsForm />
				</Modal>
				<EnableOperationsClosePopUpModal />
			</>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<EnableOperationsModalProps, 'modalId'>) => ({
		visible: isModalVisible(state, ownProps.modalId),
		isFormValid: isInvalid(ENABLE_OPERATIONS_FORM)(state)
	}),
	{
		openModal,
		updateEnableOprn: updateEnableOperations
	}
)(EnableOperationsModal);
