import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { NotificationsState, initialState } from './notificationsState';

import {
	addNotification,
	removeNotification,
	resetNotifications,
	resetAllNotifications,
	setNotificationActionTypeToDisplayType,
	unsetNotificationActionTypeToDisplayType,
	setNotificationAddons,
	resetNotificationAddons
} from './actions';
import {
	onAddNotification,
	onRemoveNotification,
	onResetNotifications,
	onResetAllNotifications,
	onSetNotificationActionTypeToDisplayType,
	onUnSetNotificationActionTypeToDisplayType,
	onSetNotificationAddons,
	onResetNotificationAddons
} from './reducers';

export default reducerWithInitialState<NotificationsState>(initialState)
	.case(addNotification, onAddNotification)
	.case(removeNotification, onRemoveNotification)
	.case(resetNotifications, onResetNotifications)
	.case(resetAllNotifications, onResetAllNotifications)
	.case(setNotificationAddons, onSetNotificationAddons)
	.case(resetNotificationAddons, onResetNotificationAddons)
	.case(
		setNotificationActionTypeToDisplayType,
		onSetNotificationActionTypeToDisplayType
	)
	.case(
		unsetNotificationActionTypeToDisplayType,
		onUnSetNotificationActionTypeToDisplayType
	);
