import {
	DisbursementAccount,
	AddDAActionPayload,
	AddDAResponse,
	DaType
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

export interface OpenDAModalPayload {
	daType: DaType;
	setId?: string;
	editItem?: DisbursementAccount;
}

const ADD_DA = 'ADD_DA';
const ADD_DA_MODAL_OPEN = 'ADD_DA_MODAL_OPEN';
const ADD_DA_MODAL_CLOSE = 'ADD_DA_MODAL_CLOSE';

export const addDAAsync = actionCreator.async<
	AddDAActionPayload,
	AddDAResponse,
	Error
>(ADD_DA, {
	templateParams: {
		entityName: 'DA'
	}
});

export const addDA = actionCreator<AddDAActionPayload>(ADD_DA);
export const openAddDAModal = actionCreator<OpenDAModalPayload>(
	ADD_DA_MODAL_OPEN
);
export const closeAddDAModal = actionCreator<DaType>(ADD_DA_MODAL_CLOSE);
