import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { deletePortJobAsync } from 'store/portJobs/actions';
import { DeletePortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { getPortJobConcurrencyTokenByCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import Api from 'services/api';
import { safeRedirectToPortCallOverview } from './portJobUtilsSagas';

const deletePortJobExecutor = function*({
	payload
}: Action<DeletePortJobRequest>): SagaIterator {
	yield put(deletePortJobAsync.started(payload));
	const { portCallId, jobCode } = payload;
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		jobCode
	);
	try {
		const response = yield call(Api.PortJobs.deletePortJob, {
			portCallId,
			jobCode,
			concurrencyToken
		});
		yield put(
			deletePortJobAsync.done({
				result: undefined,
				params: payload,
				response
			})
		);
		yield all([
			put(closeModal('delete-portjob-modal')),
			call(safeRedirectToPortCallOverview, payload.portCallId)
		]);
	} catch (error) {
		yield put(
			deletePortJobAsync.failed({
				error,
				params: payload
			})
		);
		yield put(closeModal('delete-portjob-modal'));
	}
};

export const deletePortJobWatcher = function*() {
	yield takeLatest(deletePortJobAsync.type, deletePortJobExecutor);
};
