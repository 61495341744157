import React from 'react';
import { Flex } from 'components';
import { Icon } from 'components/antd';
import styles from './EditToolbar.module.scss';
import { DrawingMode } from '../../ImageViewerTypes';
import ColorPaletePopover from './ColorPaletePopover';
import Zoom from '../Zoom';

interface EditToolbarProps {
	mode: DrawingMode;
	color: string;
	scale: number;
	onUndo: () => void;
	onChangeDrawingMode: (mode: DrawingMode) => void;
	onColorChange: (color: string) => void;
	onSave: () => void;
	onScaleChange: (value: number) => void;
}

const EditToolbar = (props: EditToolbarProps) => {
	const setRectMode = () => {
		props.onChangeDrawingMode(DrawingMode.RECT);
	};

	const setLineMode = () => {
		props.onChangeDrawingMode(DrawingMode.LINE);
	};

	const setTextMode = () => {
		props.onChangeDrawingMode(DrawingMode.TEXT);
	};

	return (
		<Flex className={styles.editTool} direction="vertical">
			<Flex className={styles.editPanel} direction="vertical">
				<Icon size="lg" type="download" onClick={props.onSave} />
				<Icon size="lg" type="undo" onClick={props.onUndo} />
				<Icon
					size="lg"
					type="add-shape"
					color={props.mode === DrawingMode.RECT ? 'primary' : undefined}
					onClick={setRectMode}
				/>
				<Icon
					size="lg"
					type="drawing"
					color={props.mode === DrawingMode.LINE ? 'primary' : undefined}
					onClick={setLineMode}
				/>
				<Icon
					size="lg"
					type="add-text"
					color={props.mode === DrawingMode.TEXT ? 'primary' : undefined}
					onClick={setTextMode}
				/>
				<ColorPaletePopover
					color={props.color}
					onColorChange={props.onColorChange}
				/>
			</Flex>
			<Zoom
				type="panel"
				defaultValue={props.scale}
				onChange={props.onScaleChange}
			/>
		</Flex>
	);
};

export default React.memo(EditToolbar);
