import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { RegionsState, initialState } from '../regionsState';
import {
	Region,
	RetrieveRegionsResponse
} from 'services/api/regions/regionsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveRegionsSuccess = (
	state: RegionsState,
	{ result }: Success<null, RetrieveRegionsResponse>
) => ({
	...state,
	byCode: keyBy<Region>(result.elements, item => item.code)
});

export const onResetRegions = (state: RegionsState) => ({
	...state,
	byCode: initialState.byCode,
	fetchStatuses: {
		...state.fetchStatuses,
		byCode: FetchStatus.IDLE
	}
});

export const onRetrieveRegions = makeFetchStatusReducers<
	RegionsState,
	'fetchStatuses',
	null,
	RetrieveRegionsResponse
>('fetchStatuses', 'byCode', {
	doneReducer: onRetrieveRegionsSuccess
});
