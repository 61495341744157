import * as React from 'react';
import AntDrawer, { DrawerProps } from 'antd/lib/drawer';

export class Drawer extends React.Component<DrawerProps, {}> {
	render() {
		return <AntDrawer {...this.props} />;
	}
}

export default Drawer;
