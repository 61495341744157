import React from 'react';

import { ActAsSwitcher } from 'containers';
import ActAsContainer from 'containers/ActAs/ActAsContainer';

const VesselInfoActAs: React.SFC = ({ children }) => (
	<>
		<ActAsContainer>{children}</ActAsContainer>
		<ActAsSwitcher enableIfActive />
	</>
);

export default VesselInfoActAs;
