import { initialState } from './developerPortalState';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';

import {
	onRetrieveDeveloperPortal,
	onCreateNewApiSubscription,
	onUpdateApiSubscription,
	onDownloadApiSubscription,
	onSetEditingWebhookReducer,
	onSetSelectedOutBoundList
} from './reducers';
import {
	retrieveDeveloperPortalAsync,
	createNewApiSubscriptionAsync,
	updateApiSubscriptionAsync,
	downloadApiSubscriptionAsync,
	setEditingWebHook,
	setSelectedOutBoundList
} from './actions';

export default reducerWithInitialState(initialState)
	.case(retrieveDeveloperPortalAsync.started, onRetrieveDeveloperPortal.started)
	.case(retrieveDeveloperPortalAsync.done, onRetrieveDeveloperPortal.done)
	.case(retrieveDeveloperPortalAsync.failed, onRetrieveDeveloperPortal.failed)
	.case(
		createNewApiSubscriptionAsync.started,
		onCreateNewApiSubscription.started
	)
	.case(createNewApiSubscriptionAsync.done, onCreateNewApiSubscription.done)
	.case(createNewApiSubscriptionAsync.failed, onCreateNewApiSubscription.failed)
	.case(updateApiSubscriptionAsync.started, onUpdateApiSubscription.started)
	.case(updateApiSubscriptionAsync.done, onUpdateApiSubscription.done)
	.case(updateApiSubscriptionAsync.failed, onUpdateApiSubscription.failed)
	.case(downloadApiSubscriptionAsync.started, onDownloadApiSubscription.started)
	.case(downloadApiSubscriptionAsync.done, onDownloadApiSubscription.done)
	.case(downloadApiSubscriptionAsync.failed, onDownloadApiSubscription.failed)
	.case(setEditingWebHook, onSetEditingWebhookReducer)
	.case(setSelectedOutBoundList, onSetSelectedOutBoundList);
