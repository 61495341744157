import { FetchStatus } from 'services/api/apiTypes';
import {
	BunkeringMeasurement,
	MeasurementsAccess,
	ShipMeasurement,
	CargoMeasurement,
	MeasurementsMetadataActionCode
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { Metadata } from 'app-types';

export interface PortCallMeasurementsState {
	readonly access: {
		[portCallId: string]: MeasurementsAccess[];
	};
	readonly bunkering: {
		[portCallId: string]: {
			metadata: Metadata<MeasurementsMetadataActionCode>;
			bunkeringMeasurements: BunkeringMeasurement[];
		};
	};
	readonly ship: {
		[portCallId: string]: ShipMeasurement[];
	};
	readonly cargo: {
		[portCallId: string]: CargoMeasurement[];
	};
	readonly fetchStatuses: {
		access: FetchStatus;
		bunkering: FetchStatus;
		ship: FetchStatus;
		cargo: FetchStatus;
	};
	readonly updateStatuses: {
		bunkering: {
			[id: string]: FetchStatus;
		};
		ship: {
			[type: string]: FetchStatus;
		};
		cargo: {
			[type: string]: FetchStatus;
		};
	};
}

export const initialState: PortCallMeasurementsState = {
	access: {},
	bunkering: {},
	ship: {},
	cargo: {},
	fetchStatuses: {
		access: FetchStatus.IDLE,
		bunkering: FetchStatus.IDLE,
		ship: FetchStatus.IDLE,
		cargo: FetchStatus.IDLE
	},
	updateStatuses: {
		bunkering: {},
		ship: {},
		cargo: {}
	}
};
