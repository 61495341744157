import * as React from 'react';
import isNumber from 'lodash/isNumber';

interface IStubProps {
	/**
	 * Specifies the width of the block. 100% by default;
	 */
	width?: string | number;
	/**
	 * Specifies the height of the block. 60px by default;
	 */
	height?: string | number;
	/**
	 * Specifies the type of the block
	 */
	type?: 'basic' | 'dashed';
	/**
	 * Optional title
	 */
	title?: React.ReactNode;
	/**
	 * Custom styles
	 */
	style?: object;
	/**
	 * Top margin
	 */
	top?: string | number;
	/**
	 * Left margin
	 */
	left?: string | number;
	/**
	 * Right margin
	 */
	right?: string | number;
	/**
	 * Bottom margin
	 */
	bottom?: string | number;
}

const themes = {
	basic: {
		display: 'inline-block',
		backgroundColor: '#f5f5f5',
		color: '#aaa',
		fontSize: '1.2em'
	},
	dashed: {
		display: 'inline-block',
		backgroundColor: '#fff',
		color: '#aaa',
		border: '1px dashed #ccc',
		fontSize: '1.2em'
	}
};

const getValue = (value?: string | number) =>
	isNumber(value) ? `${value}px` : value;

export const Stub: React.FC<IStubProps> = props => {
	const width = getValue(props.width);
	const height = getValue(props.height);
	const margin = `${getValue(props.top)} ${getValue(props.right)} ${getValue(
		props.bottom
	)} ${getValue(props.left)}`;
	const themeStyles =
		props.type && themes[props.type] ? themes[props.type] : {};
	const styles = props.style || {};

	return (
		<div
			style={{
				width,
				height,
				margin,
				...themeStyles,
				...styles,
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<div
				style={{
					width: '100%',
					textAlign: 'center',
					display: 'block'
				}}
			>
				{props.title || props.children}
			</div>
		</div>
	);
};

Stub.defaultProps = {
	width: '100%',
	height: 60,
	type: 'basic',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0
};

export default Stub;
