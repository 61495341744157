import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ConfigurationParams } from './ConfigurationTypes';
import { setActiveCompanyId } from 'store/configuration/actions';
import { useDispatch } from 'react-redux';
import { Fit } from 'components';

const ConfigurationLayout: React.SFC<
	RouteComponentProps<ConfigurationParams>
> = props => {
	const dispatch = useDispatch();
	useEffect(
		() => () => {
			dispatch(setActiveCompanyId(''));
		},
		[dispatch]
	);
	return <Fit>{props.children}</Fit>;
};

export default ConfigurationLayout;
