import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { GroupUsersState } from '../groupUsersState';
import { ThirdPartyLPAUserInvitePayload } from '../actions/inviteThirdPartyLpaUser';
import { ThirdPartyLPAUserInviteResponse } from 'services/api/groups/groupsServiceTypes';

export const onInviteThirdPartyLpaUser = makeFetchStatusReducers<
	GroupUsersState,
	'fetchStatuses',
	ThirdPartyLPAUserInvitePayload,
	ThirdPartyLPAUserInviteResponse
>('fetchStatuses', 'inviteThirdPartyLpaUser');
