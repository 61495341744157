import { WizardFormState } from '../wizardFormState';

import { UpdateStateAction } from '../actions/updateState';

import { initPages } from '../wizardFormUtils';

export const onUpdateState = (
	state: WizardFormState,
	action: UpdateStateAction
) => {
	const { name, pages } = action;
	const currentForm = state.forms[name];
	const form = {
		...currentForm,
		pages: pages ? initPages(pages) : currentForm.pages
	};
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
