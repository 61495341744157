import React from 'react';
import { SettingsButton } from 'components';
import { onClickEvent } from 'app-types';
import SupplierInvoiceSettingsMenu, {
	SupplierInvoiceSettingsMenuOwnProps
} from './SupplierInvoiceSettingsMenu';

export interface SupplierInvoiceSettingsButtonProps
	extends SupplierInvoiceSettingsMenuOwnProps {
	isCancelled?: boolean;
}

const SupplierInvoiceSettingsButton = ({
	id,
	metadata,
	isCancelled
}: SupplierInvoiceSettingsButtonProps) => {
	const onClick = (event: onClickEvent) => {
		event.stopPropagation();
	};

	return (
		<SettingsButton
			content={<SupplierInvoiceSettingsMenu id={id} metadata={metadata} />}
			handleClick={onClick}
			disabled={isCancelled}
			disabledMessage="There are no available options to apply"
		/>
	);
};

export default React.memo(SupplierInvoiceSettingsButton);
