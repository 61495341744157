import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface CompanyVesselsState {
	readonly byId: {
		[id: string]: CompanyVessel;
	};
	readonly hasMore: boolean;
	readonly byPortCallId: {
		[portCallId: string]: string[]; // ids in portcall
	};
	readonly fetchStatuses: {
		create: FetchStatus;
		update: FetchStatus;
		retrieve: FetchStatus;
		delete: FetchStatus;
	};
	/**
	 * TODO related to Configuration UI
	 */
	readonly context: {
		activeVesselSearchResult?: VesselDetails;
		activeVesselSearchResultMapped?: boolean;
	};
}

export const initialState: CompanyVesselsState = {
	byId: {},
	hasMore: true,
	byPortCallId: {},
	fetchStatuses: {
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE,
		retrieve: FetchStatus.IDLE,
		delete: FetchStatus.IDLE
	},
	context: {}
};
