import * as React from 'react';
import classNames from 'classnames';
import styles from './Gap.module.scss';
import { Size } from 'components/types';

import { createGetClassNameMapByPosition } from './Gap.func';

const getClassNameMapByPosition = createGetClassNameMapByPosition(styles);

export type GapSize = Size | boolean;

export interface GapPosition {
	top?: GapSize;
	right?: GapSize;
	bottom?: GapSize;
	left?: GapSize;
	all?: GapSize;
}

export interface GapProps extends GapPosition {
	className?: string;
	outside?: boolean; // true - margin, false - padding
	isBlock?: boolean; // for display: block
}

export const Gap: React.FC<GapProps> = props => {
	const {
		className,
		outside = true,
		isBlock = false,
		top,
		right,
		bottom,
		left,
		all,
		children
	} = props;

	const gapClasses = all
		? getClassNameMapByPosition('all', all)
		: {
				...getClassNameMapByPosition('top', top),
				...getClassNameMapByPosition('right', right),
				...getClassNameMapByPosition('bottom', bottom),
				...getClassNameMapByPosition('left', left)
		  };

	return (
		<div
			className={classNames(
				styles.root,
				gapClasses,
				{
					[styles.outside]: outside,
					[styles.isBlock]: isBlock
				},
				className
			)}
		>
			{children}
		</div>
	);
};

export default Gap;
