import { DeleteServiceCategoryRequest } from 'services/api/services/servicesServiceTypes';
import actionCreator from '../servicesActionCreator';

const ACTION_NAME = 'DELETE_SERVICE_CATEGORY';

export const deleteServiceCategoryAsync = actionCreator.async<
	DeleteServiceCategoryRequest,
	null
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Service Category'
	}
});

export const deleteServiceCategory = actionCreator<
	DeleteServiceCategoryRequest
>(ACTION_NAME);
