import { WizardFormState } from '../wizardFormState';
import { WizardFormSetActivePageState } from '../actions/setActivePageState';
export const onSetActivePageState = (
	state: WizardFormState,
	action: WizardFormSetActivePageState
) => {
	const { name, ...pageState } = action;
	const { activePage, pages } = state.forms[name];
	const page = pages[activePage];
	const form = {
		...state.forms[name],
		pages: {
			...pages,
			[activePage]: {
				...page,
				...pageState
			}
		}
	};
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
