import { SagaIterator } from 'redux-saga';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { put, call } from 'redux-saga/effects';
import { DownloadDirectBillsDocumentRequest } from 'services/api/directBills/directBillsServiceTypes';
import Api from 'services/api';
import { downloadDirectBillsDocumentsAsync } from '../actions';
import { downloadExecutor } from '../../documents/sagas/pollDownloadUrlSaga';

const async = downloadDirectBillsDocumentsAsync;
const POSTApi = Api.DirectBills.requestPostDirectBillsDocumentUrl;
const GETApi = Api.DirectBills.requestGetDirectBillsDocumentUrl;

export const executor = function*(
	params: DownloadDirectBillsDocumentRequest
): SagaIterator {
	//
	// Ignore non-request params
	const { meta, ...requestParams } = params;
	const payload: DownloadDirectBillsDocumentRequest = {
		...requestParams
	};

	yield put(async.started(payload));
	try {
		/**
		 * Call POSTApi service to get file at first
		 * If isReady = false, then the client should call GETApi service for polling
		 */
		yield call(downloadExecutor, {
			params: payload,
			POSTApi,
			GETApi
		});

		yield put(async.done({ params: payload, result: null, response: null }));
	} catch (error) {
		yield put(
			async.failed({
				params: payload,
				error
			})
		);
	}
};

export default makeTakeLatestWatcher<
	DownloadDirectBillsDocumentRequest,
	null,
	Error
>(
	{
		async
	},
	executor
);
