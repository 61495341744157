import { select } from 'redux-saga/effects';
import Api from 'services/api';
import {
	PortJobService,
	ReverseSplitServiceRequest
} from 'services/api/finance/financeServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { ResultCollection } from 'services/api/apiTypes';
import { reverseSplitServiceAsync as async } from '../actions/reverseSplitService';

const api = Api.Finance.reverseSplitService;

export interface ReverseSplitServiceRequestParams {
	serviceId: string;
}

export default makeTakeLatestWatcher<
	ReverseSplitServiceRequestParams,
	ResultCollection<PortJobService>,
	Error,
	ReverseSplitServiceRequest
>({
	async,
	api,
	*getApiParams({ serviceId }: ReverseSplitServiceRequestParams) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		return {
			portCallId,
			jobCode,
			serviceId
		};
	}
});
