import { retrieveCompanyVesselTypesAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse
} from 'services/api/companies/companiesServiceTypes';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse,
	Error
>({
	api: Api.Companies.retrieveCompanyVesselTypes,
	async: retrieveCompanyVesselTypesAsync
});
