import React, { FC } from 'react';
import { Badge, Select } from 'components/antd';
import { ReduxForm } from 'components';
import {
	CATEGORIZE_MSG_MODAL_FORM,
	ThreadsCatergorizeMsgFormData,
	ThreadsCatergorizeMsgFormFields
} from './CategorizeMsgModalFormTypes';
import Context from './Context/Context';
import QuickAssignToJobs from './QuickAssignToJobs';
import ThreadTags from './ThreadTags/ThreadTags';
import styles from './CategorizeMsgModalForm.module.scss';

export interface CategorizeMsgModalFormProps {
	mainPrincipalId: string | null;
	className?: string;
}

const CategorizeMsgModalForm: FC<CategorizeMsgModalFormProps> = props => {
	const statusList = [
		{
			key: 'Open',
			value: 'Open',
			theme: '#ff8000'
		},
		{
			key: 'Queried',
			value: 'Queried',
			theme: '#338adb'
		},
		{
			key: 'Resolved',
			value: 'Resolved',
			theme: '#869aaf'
		}
	];

	return (
		<>
			<ReduxForm
				form={CATEGORIZE_MSG_MODAL_FORM}
				initialValues={{} as ThreadsCatergorizeMsgFormData}
			>
				<Context />
				<ThreadTags />
				<QuickAssignToJobs
					isAssignJobButtonDisabled={false}
					mainPrincipalId={props.mainPrincipalId}
				/>

				<section className={styles.tags}>
					<span>Status</span>
					<Select.ReduxFormItem
						name={ThreadsCatergorizeMsgFormFields.THREAD_STATUS}
						placeholder="Search Status"
						getPopupContainer={() => document.body}
					>
						{statusList.map(status => (
							<Select.Option key={status.key} title={status.value}>
								<Badge
									dot
									color={status.theme}
									placement="leftBottom"
									className={styles.statusBadgeDot}
									text={status.value}
								></Badge>
							</Select.Option>
						))}
					</Select.ReduxFormItem>
				</section>
			</ReduxForm>
		</>
	);
};

export default CategorizeMsgModalForm;
