import React from 'react';
import { connect } from 'react-redux';

import { UserType } from 'services/api/users/userServiceTypes';
import {
	getUserCompanyId,
	getUserType,
	getAuthAccessToken
} from 'store/auth/selectors';
import {
	retrieveCompanyGeneralConfigurationReports,
	retrieveCompanyBenchmark
} from 'store/companies/actions';
import {
	getIsRetrieveGeneralConfigurationReportsDoneLoading,
	getCompanyHasAccessToReportServer,
	getIsRetrieveGeneralConfigurationBenchmarkDoneLoading,
	getCompanyHasAccessToBlt
} from 'store/companies/selectors';
import config from 'services/config';
import { AppState } from 'store-types';

interface AppSidebarDataProviderData {
	reportServer: {
		isVisible: boolean;
		url: string;
	};
	benchmarkApplication: {
		isVisible: boolean;
	};
}

interface AppSidebarDataProviderProps {
	userCompanyId: string;
	userType: UserType;
	hasAccessToReportServer: boolean | null;
	token: string;
	isLoaded: boolean;
	isBenchmarkLoaded: boolean;
	hasAccessToBenchmark: boolean | null;
	retrieveReports: typeof retrieveCompanyGeneralConfigurationReports;
	retrieveBenchmark: typeof retrieveCompanyBenchmark;
	children: (data: AppSidebarDataProviderData) => React.ReactNode;
}
class AppSidebarDataProvider extends React.Component<
	AppSidebarDataProviderProps
> {
	componentDidMount() {
		if (this.getShouldReportsLoad()) {
			this.props.retrieveReports({
				companyId: this.props.userCompanyId
			});
			this.props.retrieveBenchmark({
				companyId: this.props.userCompanyId
			});
		}
	}

	getShouldReportsLoad = () => this.props.userType === UserType.PRINCIPAL;

	getData = () => {
		return {
			reportServer: {
				isVisible:
					!this.getShouldReportsLoad() ||
					(this.props.isLoaded && Boolean(this.props.hasAccessToReportServer)),
				url: `${config.reportServerUrl}&token=${this.props.token}`
			},
			benchmarkApplication: {
				isVisible:
					!this.getShouldReportsLoad() ||
					(this.props.isBenchmarkLoaded &&
						Boolean(this.props.hasAccessToBenchmark))
			}
		};
	};

	render() {
		const { children } = this.props;

		return <>{children(this.getData())}</>;
	}
}

export default connect(
	(state: AppState) => {
		const userCompanyId = getUserCompanyId(state);
		return {
			userCompanyId,
			userType: getUserType(state),
			hasAccessToReportServer: getCompanyHasAccessToReportServer(
				state,
				userCompanyId
			),
			token: getAuthAccessToken(state),
			isLoaded: getIsRetrieveGeneralConfigurationReportsDoneLoading(state),
			isBenchmarkLoaded: getIsRetrieveGeneralConfigurationBenchmarkDoneLoading(
				state
			),
			hasAccessToBenchmark: getCompanyHasAccessToBlt(state, userCompanyId)
		};
	},
	{
		retrieveReports: retrieveCompanyGeneralConfigurationReports,
		retrieveBenchmark: retrieveCompanyBenchmark
	}
)(AppSidebarDataProvider);
