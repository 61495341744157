import { select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	AcceptanceData,
	PortJobBaseRequest
} from 'services/api/portJobs/portJobsServiceTypes';
import { retrieveAcceptanceDataAsync } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.PortJobs.retrieveAcceptanceData;

export default makeTakeEveryWatcher<PortJobBaseRequest, AcceptanceData, Error>({
	api,
	async: retrieveAcceptanceDataAsync,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		return {
			portCallId,
			jobCode
		};
	}
});
