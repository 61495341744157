import React, { useCallback } from 'react';
import { DashboardJob } from 'services/api/dashboard/dashboardServiceTypes';
import Table from 'antd/lib/table';
import { withInfo } from 'components/FormattedTimeExtended/FormattedTimeExtended.module.scss';
import { Link } from 'components';
import FormattedTimeTooltip from 'components/FormattedTimeExtended/FormattedTimeTooltip/FormattedTimeTooltip';
import styles, { portJobLink } from './JobSection.module.scss';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import JobStatus from './JobStatus';
import { Icon } from 'components/antd';

interface PortJobsProps {
	jobs: DashboardJob[];
	portCallId: string;
}

const getColumns = (portCallId: string) => [
	{
		title: 'Job ID',
		dataIndex: 'code',
		width: '20%',
		render: (code: string, portJob: DashboardJob) => (
			<Link
				href={`/portcalls/${portCallId}/jobs/${code}`}
				className={portJobLink}
			>
				{code}
				{portJob.isOperationsDisabled && (
					<Icon type="operations-disable" color="standard" size={22} />
				)}
				&nbsp;
				{!portJob.financeEnabled && (
					<Icon type="finance-disable" color="standard" size={22} />
				)}
			</Link>
		)
	},
	{
		title: 'Operation',
		dataIndex: 'operations',
		width: '20%',
		render: (operations: string[]) => (
			<OptionalValue value={operations.length !== 0}>
				{operations.length === 1 ? (
					operations[0]
				) : (
					<span className={withInfo}>
						Multiple <FormattedTimeTooltip title={operations.join(', ')} />
					</span>
				)}
			</OptionalValue>
		)
	},
	{
		title: 'Performing Agent',
		width: '20%',
		dataIndex: 'performingAgentCompany.name'
	},
	{
		title: 'Job Status',
		dataIndex: 'status',
		width: '40%',
		colSpan: 2,
		render: (status: string, record: DashboardJob) => (
			<JobStatus status={status} portJob={record} portCallId={portCallId} />
		)
	}
];

export const PortJobs: React.SFC<PortJobsProps> = ({ jobs, portCallId }) => {
	const getRowKey = useCallback(job => `${portCallId}-${job.code}`, [
		portCallId
	]);
	return (
		<Table
			className={styles.portJobsTable}
			dataSource={jobs}
			pagination={false}
			columns={getColumns(portCallId)}
			bordered={false}
			rowKey={getRowKey}
		/>
	);
};
