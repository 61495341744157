import { Success } from 'typescript-fsa';
import { FetchStatus, ResultCollection } from 'services/api/apiTypes';
import { PortJobState } from 'store/portJobs/portJobsState';
import {
	AppointerType,
	RetrieveAppointerRequest
} from 'services/api/portJobs/portJobsServiceTypes';
export const onRetrieveAppointersStarted = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		appointers: FetchStatus.PENDING
	}
});
export const onRetrieveAppointersSuccess = (
	state: PortJobState,
	action: Success<RetrieveAppointerRequest, ResultCollection<AppointerType>>
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		appointers: FetchStatus.SUCCESS
	},
	dictionaries: {
		...state.dictionaries,
		appointers: action.result.elements
	}
});
export const onRetrieveAppointersFailed = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		appointers: FetchStatus.FAILURE
	}
});
