import {
	AddCreditHubRevenueServiceRequest,
	AddCreditHubRevenueServiceResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'ADD_CREDIT_HUB_REVENUE_SERVICE';
export const addCreditHubRevenueServiceAsync = actionCreator.async<
	AddCreditHubRevenueServiceRequest,
	AddCreditHubRevenueServiceResponse,
	Error
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const addCreditHubRevenueService = actionCreator<{
	jobServiceId: string;
	isCreditHubRevenueChangeRequested?: boolean;
}>(ACTION_NAME);
