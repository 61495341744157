import {
	RetrieveBuoysRequest,
	RetrieveBuoysResponse
} from 'services/api/ports/portsServiceTypes';
import actionCreator from '../actionCreator';

const RETRIEVE_BUOYS = 'RETRIEVE_BUOYS';

export const retrieveBuoysAsync = actionCreator.async<
	RetrieveBuoysRequest,
	RetrieveBuoysResponse,
	Error
>(RETRIEVE_BUOYS);

export const retrieveBuoys = actionCreator<RetrieveBuoysRequest>(
	RETRIEVE_BUOYS
);
