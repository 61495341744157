import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import {
	setEditableCargoId,
	setEditableGradeId,
	setEditableTotalAmountId
} from 'store/portCall/actions';
import { closeModal, closeAllModals } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { CANCEL_PROCESS_MODAL } from '../../../createPortJobConstants';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { AppState } from 'store-types';

interface CancelProcessModalProps {
	operationTypeCode: OperationTypeCode;
	// from mapStateToProps
	isVisible: boolean;
	// from mapDispatchToProps
	setEditableCargoId: typeof setEditableCargoId;
	setEditableGradeId: typeof setEditableGradeId;
	setEditableTotalAmountId: typeof setEditableTotalAmountId;
	closeModal: typeof closeModal;
	closeAllModals: typeof closeAllModals;
}

const CancelProcessModal = ({
	operationTypeCode,
	isVisible,
	...props
}: CancelProcessModalProps) => (
	<Modal
		visible={isVisible}
		okText="Yes, Cancel"
		cancelText="No, return"
		onOk={() => {
			props.closeAllModals();
			if (
				[
					OperationTypeCode.LOADING,
					OperationTypeCode.LOADING_STS,
					OperationTypeCode.DISCHARGING,
					OperationTypeCode.DISCHARGING_STS
				].includes(operationTypeCode)
			) {
				props.setEditableCargoId(undefined);
			}
			if (operationTypeCode === OperationTypeCode.BUNKERING) {
				props.setEditableGradeId(undefined);
			}
			if (
				[
					OperationTypeCode.CASH_TO_MASTER,
					OperationTypeCode.CREW_WAGE_DISBURSEMENT
				].includes(operationTypeCode)
			) {
				props.setEditableTotalAmountId(undefined);
			}
		}}
		onCancel={() => props.closeModal(CANCEL_PROCESS_MODAL)}
		zIndex={1001}
	>
		Are you sure you want to cancel the process? All entered data will be lost
	</Modal>
);

export default connect(
	(state: AppState) => ({
		isVisible: isModalVisible(state, CANCEL_PROCESS_MODAL)
	}),
	{
		setEditableCargoId,
		setEditableGradeId,
		setEditableTotalAmountId,
		closeModal,
		closeAllModals
	}
)(CancelProcessModal);
