import UserPermission from 'components/UserPermission/UserPermission';
import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Text, JobCodeTag } from 'components';
import { getCurrentUser } from 'store/auth/selectors/user';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { unassignJobFromThread } from 'store/thread/actions/unassignJobFromThread';
import { unassignJobFromNewThread } from 'store/threads/actions/unassignJobFromNewThread';
import styles from './AssignToJob.module.scss';
import { Button } from 'components/antd';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { navigateTo } from 'utils';
import { setOriginUrl } from 'store/wizardForm/actions/setOriginUrl';
import { PORT_JOB_WIZARD_ID } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';
import { getMergedAssignedToNewThreadJobCodes } from 'store/thread/selectors';
import { AppState } from 'store-types';
import QuickAssignToJob from './QuickAssignToJob';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { PermissionWrapper } from 'components/Permission';

interface AssignToJobProps {
	// from mapStateToProps
	thread?: ThreadItem;
	threadId: string;
	jobCodes: string[];
	showTitle?: boolean;
	isUserAssignedToThread: boolean;
	isNewThread: boolean;
	isComposeMessage?: boolean;
	isAssignJobButtonDisabled: boolean;
	isPreviewModeEnabled?: boolean;
	// from mapDispatchToProps
	onAssignJob?: () => void;
	setOriginUrl: typeof setOriginUrl;
	unassignJobFromThread: typeof unassignJobFromThread;
	unassignJobFromNewThread: typeof unassignJobFromNewThread;
	isAppointmentFromNomination?: boolean;
}

class AssignToJob extends React.Component<AssignToJobProps> {
	static defaultProps = {
		showTitle: true
	};
	onAssignJob = () => {
		const { isAppointmentFromNomination } = this.props;
		this.props.setOriginUrl({
			name: PORT_JOB_WIZARD_ID,
			originUrl: window.location.pathname
		});

		if (this.props.onAssignJob) {
			this.props.onAssignJob();
		}

		if (isAppointmentFromNomination) {
			navigateTo(`/messages/${this.props.threadId}/job/nomination`, {
				shouldStayInNewTab: true
			});
		} else {
			navigateTo(`/messages/${this.props.threadId}/job`, {
				shouldStayInNewTab: true
			});
		}
	};

	onUnassignJob = (jobCode: string) => {
		const { isNewThread } = this.props;

		if (isNewThread) {
			this.props.unassignJobFromNewThread(jobCode);
		} else {
			this.props.unassignJobFromThread({
				jobCode,
				threadId: this.props.threadId
			});
		}
	};

	render() {
		const {
			jobCodes,
			showTitle,
			isUserAssignedToThread,
			isNewThread,
			isAssignJobButtonDisabled,
			isAppointmentFromNomination,
			isPreviewModeEnabled
		} = this.props;

		return (
			<section>
				{!isAppointmentFromNomination && showTitle && jobCodes.length !== 0 && (
					<Text color="primary" weight="semi-bold">
						Job
					</Text>
				)}
				{!isAppointmentFromNomination && jobCodes.length !== 0 && (
					<ul>
						{jobCodes.map(code => (
							<li className={styles.jobItem} key={code}>
								<JobCodeTag
									key={code}
									jobCode={code}
									closable={isUserAssignedToThread || isNewThread}
									onClose={() => this.onUnassignJob(code)}
								>
									{code}
								</JobCodeTag>
							</li>
						))}
					</ul>
				)}

				<UserPermission
					code={[GroupTypeCodes.ISS_CLUSTER, GroupTypeCodes.ISS_HUB]}
				>
					{!isAppointmentFromNomination && (
						<Button
							type="primary"
							transparent
							icon="plus"
							size="small"
							onClick={this.onAssignJob}
							disabled={isAssignJobButtonDisabled}
						>
							{isNewThread ? 'Assign Job' : 'Assign/Create Job'}
						</Button>
					)}
					{isAppointmentFromNomination && (
						<PermissionWrapper
							tooltip={{
								placement: 'topRight'
							}}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
						>
							{hasPermission => (
								<Button
									className={
										isPreviewModeEnabled ? styles.createAppointmentLink : ''
									}
									type="primary"
									transparent
									icon="plus"
									size="small"
									onClick={this.onAssignJob}
									disabled={isAssignJobButtonDisabled || !hasPermission}
								>
									Create Appointment from Nomination
								</Button>
							)}
						</PermissionWrapper>
					)}
					<br />
					{!isAppointmentFromNomination && (
						<QuickAssignToJob
							isAssignJobButtonDisabled={isAssignJobButtonDisabled}
						/>
					)}
				</UserPermission>
			</section>
		);
	}
}

export default connect(
	(
		state: AppState,
		ownProps: Pick<AssignToJobProps, 'thread' | 'isComposeMessage'>
	) => {
		const isUserAssignedToThread =
			get(ownProps, ['thread', 'assignedToUser', 'id']) ===
			getCurrentUser(state).id;
		const isNewThread = !ownProps.thread;

		return {
			isUserAssignedToThread,
			jobCodes: getMergedAssignedToNewThreadJobCodes(state),
			threadId: ownProps.thread ? ownProps.thread.id : NEW_THREAD_ID,
			isNewThread,
			isAssignJobButtonDisabled: !isUserAssignedToThread && !isNewThread
		};
	},
	{
		setOriginUrl,
		unassignJobFromThread,
		unassignJobFromNewThread
	}
)(AssignToJob);
