import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { LinkJobState } from '../linkJobState';
import { Success } from 'typescript-fsa';
import {
	PortJob,
	RetrieveJobParams
} from 'services/api/portJobs/portJobsServiceTypes';

function onRetrieveLinkedAppointmentJobSuccess(
	state: LinkJobState,
	action: Success<RetrieveJobParams, PortJob>
): LinkJobState {
	return {
		...state,
		linkedAppointmentJob: action.result
	};
}

export const onRetrieveLinkedAppointmentJob = makeFetchStatusReducers<
	LinkJobState,
	'fetchStatuses',
	RetrieveJobParams,
	PortJob
>('fetchStatuses', 'linkedJob', {
	doneReducer: onRetrieveLinkedAppointmentJobSuccess
});
