import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
	updateCompanyTemplatesAsync,
	retrieveTemplateAsync,
	retrieveTemplatesAsync,
	retrieveTemplateRenderAsync
} from './actions';

import {
	onRetrieveTemplatesStarted,
	onRetrieveTemplatesSuccess,
	onRetrieveTemplateStarted,
	onRetrieveTemplateSuccess,
	onRetrieveTemplateRenderStarted,
	onRetrieveTemplateRenderSuccess,
	onUpdateCompanyTemplates
} from './reducers';

import { initialState } from './templatesState';

export default reducerWithInitialState(initialState)
	// Update Company Template
	.case(updateCompanyTemplatesAsync.started, onUpdateCompanyTemplates.started)
	.case(updateCompanyTemplatesAsync.done, onUpdateCompanyTemplates.done)
	.case(updateCompanyTemplatesAsync.failed, onUpdateCompanyTemplates.failed)
	.case(retrieveTemplateAsync.started, onRetrieveTemplateStarted)
	.case(retrieveTemplateAsync.done, onRetrieveTemplateSuccess)
	.case(retrieveTemplatesAsync.started, onRetrieveTemplatesStarted)
	.case(retrieveTemplatesAsync.done, onRetrieveTemplatesSuccess)
	.case(retrieveTemplateRenderAsync.started, onRetrieveTemplateRenderStarted)
	.case(retrieveTemplateRenderAsync.done, onRetrieveTemplateRenderSuccess);
