import { omit } from 'lodash';
import {
	FormData,
	CargoLineLoadOperation
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AddPortJobCargoLineLoad } from 'services/api/portJobs/portJobsServiceTypes';
import numeral from 'numeral';
import {
	idToIdField,
	optionalKeyToStringField,
	optionalLabelToNameField
} from '../prepareRequest.func';
import { isIdAutoGenerated } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';

const prepareLoadCargoLineRequest = (
	cargoLine: CargoLineLoadOperation
): AddPortJobCargoLineLoad => {
	const dischargePortIsUnknown = Boolean(cargoLine.toOrders);
	let cargoLineData = {
		chartererCompany: optionalLabelToNameField(cargoLine.chartererCompany),
		cargoReferenceNumber: cargoLine.referenceNumber,
		principalVoyageRef: cargoLine.principalVoyageReference,
		mainCommodity: idToIdField(cargoLine.mainCommodity),
		commodity: optionalLabelToNameField(cargoLine.commodity),
		commodityQuantity: cargoLine.commodityQuantity
			? numeral(cargoLine.commodityQuantity).value()
			: undefined,
		commodityUnitOfMeasurementCode: cargoLine.commodityUnitOfMeasurementCode,
		shipperCompany: optionalLabelToNameField(cargoLine.shipperCompany),
		charterPartyDate: cargoLine.charterPartyDate,
		laycanFromPlt: cargoLine.laycanFromPlt,
		laycanToPlt: cargoLine.laycanToPlt,
		dischargePortIsUnknown,
		terms: cargoLine.terms,
		loadingFromVessel: optionalLabelToNameField(cargoLine.loadingFromVessel),
		toOrders: cargoLine.toOrders,
		dischargePort: optionalLabelToNameField(cargoLine.dischargePort),
		dischargeCountryCode: dischargePortIsUnknown
			? optionalKeyToStringField(cargoLine.dischargeCountry)
			: undefined,
		id: cargoLine.id
	};
	if (cargoLine.id && isIdAutoGenerated(cargoLine.id)) {
		cargoLineData = omit(cargoLineData, 'id');
	}
	return cargoLineData;
};

export default (form: FormData) => {
	return {
		cargoes: form.cargoes.map(prepareLoadCargoLineRequest)
	};
};
