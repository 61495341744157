import { DocumentWithOriginalFile } from 'services/api/documents/documentsServiceTypes';

export function addFetchFile(filesFetchingNumber: number): number {
	return filesFetchingNumber + 1;
}

export function removeFetchFile(filesFetchingNumber: number): number {
	return filesFetchingNumber > 0
		? filesFetchingNumber - 1
		: filesFetchingNumber;
}

export function removeAttachmentFromArr(
	attachments: DocumentWithOriginalFile[],
	uid: string
) {
	return attachments.filter(
		(attachment: DocumentWithOriginalFile) =>
			attachment.originFileObj.uid !== uid
	);
}
