import { Success } from 'typescript-fsa';
import { CompaniesState } from '../companiesState';
import {
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function doneReducer(
	state: CompaniesState,
	{
		params,
		result
	}: Success<
		RetrieveCompanyGeneralConfigurationReportsRequest,
		RetrieveCompanyGeneralConfigurationReportsResponse
	>
): CompaniesState {
	return {
		...state,
		generalConfiguration: {
			...state.generalConfiguration,
			reports: {
				...state.generalConfiguration.reports,
				[params.companyId]: result
			}
		}
	};
}

export const onRetrieveCompanyGeneralConfigurationReports = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse
>('fetchStatuses', 'retrieveGeneralConfigurationReports', {
	doneReducer
});
