import actionCreator from '../savingsActionCreator';
import { AddTimeSavingsRequest } from 'services/api/savings/savingsServiceTypes';

const ADD_TIME_SAVINGS = 'ADD_TIME_SAVINGS';

export const addTimeSavingsAsync = actionCreator.async<
	AddTimeSavingsRequest,
	void,
	Error
>(ADD_TIME_SAVINGS, {
	templateParams: {
		entityName: 'Time Saving'
	}
});

export const addTimeSavings = actionCreator(ADD_TIME_SAVINGS);
