import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	updateTimeSavings,
	updateTimeSavingsAsync
} from '../actions/updateTimeSavings';
import { UpdateTimeSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import { Action } from 'typescript-fsa';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { resetTimeSavingsContext, retrieveTimeSavings } from '../actions';
import {
	getinitialStateId,
	getPreparedAddTimeSavingsData
} from '../savingsSelectors';
import { TIME_SAVINGS_MODAL } from 'sections/PortCall/Savings/SavingsGrid/Constants';
import { closeModal } from 'store/modals/actions';

const apiCall = Api.Savings.updateTimeSavings;

export function* updateTimeSavingsExecutor(
	actionParams: UpdateTimeSavingsRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(updateTimeSavingsAsync.started(actionParams));
	try {
		const formData = yield select(getPreparedAddTimeSavingsData);
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const TimeSavingsId = yield select(getinitialStateId);

		const request: UpdateTimeSavingsRequest = {
			portCallId,
			jobCode,
			data: { portJobCode: jobCode, id: TimeSavingsId, ...formData }
		};
		const response = yield call(api, request);

		yield put(
			updateTimeSavingsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(retrieveTimeSavings({ portCallId, jobCode }));
	} catch (error) {
		yield put(
			updateTimeSavingsAsync.failed({
				error,
				params: actionParams
			})
		);
	} finally {
		yield put(resetTimeSavingsContext());
		yield put(closeModal(TIME_SAVINGS_MODAL));
	}
}

export function* worker({
	payload
}: Action<UpdateTimeSavingsRequest>): SagaIterator {
	yield call(updateTimeSavingsExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(updateTimeSavings.type, worker);
}
