import React from 'react';
import { connect } from 'react-redux';
import { Gap, LoadingPlaceholder, ScrollableLayout } from 'components';
import PortCallDetails from '../../../Duplicates/PortCallDetails';
import PageSubHeader from '../../../Duplicates/PageSubHeader';
import { AppState } from 'store-types';
import {
	setPortCallContext,
	retrievePortCallsDuplicates,
	resetPortCallsDuplicates
} from 'store/portcalls/actions';
import {
	getActivePortCall,
	getActivePortCallFetchStatus,
	getPortCallsDuplicates,
	getPortCallDuplicatesFetchStatus
} from 'store/portcalls/selectors';
import { FetchStatus } from 'services/api/apiTypes';
import {
	PortCall,
	PortCallDuplicate,
	RetrievePortCallsRequest
} from 'services/api/portCalls/portCallsServiceTypes';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { getActivePortJob } from 'store/portJobs/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import LinkJobOptionsList from './LinkJobOptionsList';

interface Page1Props {
	// from mapStateToProps
	portCall: PortCall;
	linkPortCalls: PortCallDuplicate[];
	portCallFetchStatus: FetchStatus;
	portCallDuplicatesFetchStatus: FetchStatus;
	portJob: PortJob;
	// from mapDispatchToProps
	setPortCallContext: typeof setPortCallContext;
	retrievePortCallsDuplicates: typeof retrievePortCallsDuplicates;
	resetPortCallsDuplicates: typeof resetPortCallsDuplicates;
}

class Page1 extends React.Component<Page1Props> {
	componentDidMount() {
		const { vessel, port, etaPlt } = this.props.portCall;
		const portCallSearchParams: RetrievePortCallsRequest = {
			vesselId: vessel.id,
			portId: port.id,
			eta: etaPlt && getLocalDateDefaultFormatted(etaPlt),
			jobType: 'Appointment',
			expand: 'Operations',
			portJobPerformingCompanyName: this.props.portJob.performingAgentCompany
				.name,
			mainPrincipalCompanyId: this.props.portJob.hubPrincipalCompany?.id
		};
		this.props.resetPortCallsDuplicates();
		this.props.retrievePortCallsDuplicates(portCallSearchParams);
	}
	render() {
		const {
			portCall,
			linkPortCalls,
			portCallFetchStatus,
			portCallDuplicatesFetchStatus
		} = this.props;
		return (
			<ScrollableLayout>
				<LoadingPlaceholder fetchStatus={portCallFetchStatus}>
					{() => (
						<Gap top left bottom="lg" isBlock outside={false}>
							<PortCallDetails portCall={portCall} showExtendedTime />
						</Gap>
					)}
				</LoadingPlaceholder>
				<PageSubHeader>Appointment Details</PageSubHeader>
				<LoadingPlaceholder fetchStatus={portCallDuplicatesFetchStatus}>
					{() => <LinkJobOptionsList portCalls={linkPortCalls} />}
				</LoadingPlaceholder>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		portCall: getActivePortCall(state),
		linkPortCalls: getPortCallsDuplicates(state),
		portCallFetchStatus: getActivePortCallFetchStatus(state),
		portCallDuplicatesFetchStatus: getPortCallDuplicatesFetchStatus(state),
		portJob: getActivePortJob(state)
	}),
	{
		setPortCallContext,
		retrievePortCallsDuplicates,
		resetPortCallsDuplicates
	}
)(Page1);
