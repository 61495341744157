/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppState } from 'store-types';
import { ActionFailure, ActionSuccess } from 'app-types';
import { AsyncActionCreators, Action, Success } from 'typescript-fsa';
import { take, all } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { FetchStatus } from 'services/api/apiTypes';

// handy for only passing the argument in selector
export const passArgument = <ARG>() => (_: AppState, arg: ARG) => arg;
export const passArgument2 = <ARG>() => (_: AppState, _arg: any, arg: ARG) =>
	arg;

export function* takeAllDoneOrFailed(
	...asyncActions: Array<AsyncActionCreators<any, any, any>>
) {
	const preparedTakes = asyncActions.map(asyncAction =>
		take([asyncAction.done.type, asyncAction.failed.type])
	);
	return yield all(preparedTakes);
}

export const isActionAsync = (
	action: AnyAction
): action is ActionSuccess | ActionFailure => {
	const keys = action.payload ? Object.keys(action.payload) : [];
	return Boolean(
		action.payload &&
			keys.includes('params') &&
			(keys.includes('result') || keys.includes('error'))
	);
};

export const isActionAsyncDone = (action: AnyAction): action is ActionSuccess =>
	isActionAsync(action) && !isActionAsyncFailure(action);

export const isActionAsyncFailure = (
	action: AnyAction
): action is ActionFailure =>
	Boolean(action.error && action.payload && action.payload.error) &&
	isActionAsync(action);

export const areAllActionsSuccess = (
	actions: Array<Action<any>>
): actions is Array<Action<Success<any, any>>> =>
	actions.some(action => !isActionAsyncFailure(action));

const setFetchStatus = <State extends object>(
	property: keyof State,
	state: State,
	status: FetchStatus
): State => ({ ...(state as object), [property]: status } as State); // casting to object because of  https://stackoverflow.com/questions/51189388/typescript-spread-types-may-only-be-created-from-object-types TOD0 trick for 2.x TS, should be removed in 3.x

export const setFetchFailed = <State extends object>(property: keyof State) => (
	state: State
) => setFetchStatus<State>(property, state, FetchStatus.FAILURE);

export const setFetchSuccess = <State extends object>(
	property: keyof State
) => (state: State) =>
	setFetchStatus<State>(property, state, FetchStatus.SUCCESS);

export const setFetchPending = <State extends object>(
	property: keyof State
) => (state: State) =>
	setFetchStatus<State>(property, state, FetchStatus.PENDING);

export const setFetchIdle = <State extends object>(property: keyof State) => (
	state: State
) => setFetchStatus<State>(property, state, FetchStatus.IDLE);
