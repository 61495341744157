import { ThreadStatus } from 'services/api/threads/threadsServiceTypes';
import { ThreadReducerState } from 'store/thread/threadState';
import { UpdateThreadStatusParams } from '../actions/updateThreadStatus';
import { Success } from 'typescript-fsa';
import moment from 'moment-timezone';

export const onUpdateThreadStatusSuccess = (
	state: ThreadReducerState,
	action: Success<UpdateThreadStatusParams, ThreadStatus>
): ThreadReducerState => {
	if (state.item === null) {
		return state;
	}
	return {
		...state,
		item: {
			...state.item,
			status: action.result,
			statusUpdatedOn: moment.tz().format()
		}
	};
};
