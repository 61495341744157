import * as React from 'react';
import AntTree, { TreeProps as AntTreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
import styles from './Tree.module.scss';

const TreeNode = AntTree.TreeNode;

interface TreeItem {
	entityId: string;
	entityType: string;
	name: string;
	children: TreeItem[] | null;
}

export interface TreeProps extends AntTreeProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	treeData: any[];
	nameProp: string;
	idProp: string;
	typeProp: string;

	showParentNodeCheckBox?: boolean;
	isEditableProp?: string;
	// Extended with `getIsDisabled` in order to be able to fully rely on an item being rendered
	// in addition to `isEditableProp`
	// eslint-disable-next-line
	getIsDisabled?: (treeItem: any) => boolean;

	getHeaderComponent: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		item: any,
		props: TreeProps,
		isDisabled: boolean
	) => React.ReactNode;
	getRowComponent: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		item: any,
		props: TreeProps,
		isDisabled: boolean
	) => React.ReactNode;
	includeParentRefs?: boolean;
}

export default class Tree extends React.Component<TreeProps> {
	onCheck: TreeProps['onCheck'] = (checkedKeys, event) => {
		const { onCheck } = this.props;
		if (onCheck) {
			onCheck(checkedKeys, event);
		}
	};

	onExpand: TreeProps['onExpand'] = (expandedKeys, info) => {
		const { onExpand } = this.props;
		if (onExpand) {
			onExpand(expandedKeys, info);
		}
	};

	onSelect: TreeProps['onSelect'] = (selectedKeys, event) => {
		const { onSelect } = this.props;
		if (onSelect) {
			onSelect(selectedKeys, event);
		}
	};

	renderTreeNodes = (
		data: TreeItem[],
		isRoot = false,
		parentNode?: TreeItem
	): JSX.Element[] => {
		const {
			idProp,
			typeProp,
			isEditableProp,
			getHeaderComponent,
			getRowComponent,
			includeParentRefs,
			showParentNodeCheckBox,
			getIsDisabled
		} = this.props;

		// eslint-disable-next-line
		return data.map((item: any) => {
			const isEditablePropDisabled = isEditableProp
				? !item[isEditableProp]
				: false;
			const isDisabled =
				isEditablePropDisabled || (getIsDisabled ? getIsDisabled(item) : false);

			const headerNode = isRoot
				? getHeaderComponent(item, this.props, isDisabled)
				: null;
			const rowNode =
				headerNode || getRowComponent(item, this.props, isDisabled);
			const dataRef = {
				...item,
				...(includeParentRefs && parentNode ? { parentNode } : {})
			};
			return (
				<TreeNode
					checkable={
						!(item.children && item.children.length)
							? true
							: showParentNodeCheckBox
					}
					selectable={false}
					type={item[typeProp]}
					title={rowNode}
					key={item[idProp]}
					dataRef={dataRef}
					disabled={isDisabled}
				>
					{item.children &&
						item.children.length &&
						this.renderTreeNodes(
							item.children,
							undefined,
							includeParentRefs && dataRef
						)}
				</TreeNode>
			);
		});
	};

	getAntTreeProps = (): AntTreeProps => ({
		className: classNames(styles.root, this.props.className),
		onCheck: this.onCheck,
		onExpand: this.onExpand,
		onSelect: this.onSelect,
		defaultExpandAll: this.props.defaultExpandAll,
		defaultCheckedKeys: this.props.defaultCheckedKeys,
		checkedKeys: this.props.checkedKeys,
		checkStrictly: this.props.checkStrictly,
		multiple: this.props.multiple,
		checkable: this.props.checkable,
		autoExpandParent: this.props.autoExpandParent
	});

	render() {
		const { treeData } = this.props;
		return (
			<AntTree {...this.getAntTreeProps()}>
				{this.renderTreeNodes(treeData, true)}
			</AntTree>
		);
	}
}
