import { CrewChangeOperationForm } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { parseToIntIfExists } from '../prepareRequest.func';

const getCrewChangeFormData = ({
	customerClearences,
	immigrationAssistances,
	lettersOfInvitation,
	lostLuggageAssistances,
	meetAndGreets,
	offSigners,
	okToBoardLetters,
	onSigners,
	shorePasses,
	visaAssistances,
	details
}: CrewChangeOperationForm) => ({
	customerClearences: parseToIntIfExists(customerClearences),
	immigrationAssistances: parseToIntIfExists(immigrationAssistances),
	lettersOfInvitation: parseToIntIfExists(lettersOfInvitation),
	lostLuggageAssistances: parseToIntIfExists(lostLuggageAssistances),
	meetAndGreets: parseToIntIfExists(meetAndGreets),
	offSigners: parseToIntIfExists(offSigners),
	okToBoardLetters: parseToIntIfExists(okToBoardLetters),
	onSigners: parseToIntIfExists(onSigners),
	shorePasses: parseToIntIfExists(shorePasses),
	visaAssistances: parseToIntIfExists(visaAssistances),
	details
});

export default getCrewChangeFormData;
