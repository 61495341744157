import { CategorizeThreadsRequest } from 'services/api/threads/threadsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from '../threadsState';

export const onUpdateThreadsCategorize = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	CategorizeThreadsRequest,
	null
>('fetchStatuses', 'categorizeMsgUpdating');
