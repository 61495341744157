import { OrganisationType } from 'services/api/companies/companiesServiceTypes';

export const ComponentOrganisationTypes = {
	PRINCIPAL: [OrganisationType.PRINCIPAL],
	MAIN_PRINCIPAL: [OrganisationType.MAIN_PRINCIPAL],
	LPA: [OrganisationType.LPA, OrganisationType.ISS_LPA],
	ALL: [
		OrganisationType.CHARTERER,
		OrganisationType.ISS,
		OrganisationType.ISS_LPA,
		OrganisationType.LPA,
		OrganisationType.MAIN_PRINCIPAL,
		OrganisationType.OPERATING_ENTITY,
		OrganisationType.PRINCIPAL,
		OrganisationType.SHIPPER,
		OrganisationType.VENDOR
	]
};
