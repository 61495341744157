import { call, takeEvery, all, fork, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	sendExtendedmessage as syncAction,
	sendExtendedmessageAsync as asyncAction
} from '../actions/sendExtendedMessages';
import Api from 'services/api';
import {
	CreateThreadRequest as Request,
	CreateThreadResponse
} from 'services/api/threads/threadsServiceTypes';
import { Action } from 'typescript-fsa';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import Matomo from 'services/analytics/adapters/Matomo';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from '../selectors';
import { sendPrefundingsRequest } from 'store/financeFundings/actions';
import { EmailTypes } from 'services/api/portJobs/portJobsServiceTypes';

const apiCall = Api.Threads.createThread;

export function* executor(
	actionParams: Request,
	api: typeof apiCall
): SagaIterator {
	const params = actionParams;

	yield put(asyncAction.started(params));

	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);

	try {
		const response: AxiosTypedResponse<CreateThreadResponse> = yield call(
			api,
			params
		);
		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
		if (params.message.emailType === EmailTypes.PrefundingEmail) {
			yield put(sendPrefundingsRequest({ portCallId, portJobCode }));
		}
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Messages.CreateThread',
			name: `Sent appointment email with id: ${response.data.id} for the portcall id: ${portCallId} and job id: ${portJobCode}`,
			value: 1
		});
	} catch (err) {
		yield put(
			asyncAction.failed({
				error: err,
				params
			})
		);
	}
}

function* worker(action: Action<Request>): SagaIterator {
	yield call(executor, action.payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeEvery(syncAction.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
