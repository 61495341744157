import { FormPageData } from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import { CopyFromPreviousPageRequest } from '../operationsContextualizationTypes';
import actionCreator from '../operationsContextualizationActionCreator';

export const COPY_FROM_PREVIOUS_PAGE = 'COPY_FROM_PREVIOUS_PAGE';
export const copyFromPreviousPageAsync = actionCreator.async<
	CopyFromPreviousPageRequest | undefined, // can be inferred from the store
	FormPageData,
	Error
>(COPY_FROM_PREVIOUS_PAGE);

export const copyFromPreviousPage = actionCreator<
	CopyFromPreviousPageRequest | undefined
>(COPY_FROM_PREVIOUS_PAGE);
