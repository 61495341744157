import {
	PortCallSection,
	PortCallEvent
} from 'services/api/portCall/portCallServiceTypes';
import { PortCallEventsById } from '../../portCallOperationsState';

const getEventsFromSectionsAsList = (
	sections: PortCallSection[]
): PortCallEvent[] =>
	sections.reduce(
		(events, section) => [
			...events,
			...(section.elements || []),
			...getEventsFromSectionsAsList(section.sections || [])
		],
		[]
	);

const transformEventsListIntoMap = (
	events: PortCallEvent[]
): PortCallEventsById =>
	events.reduce(
		(eventsMap, event) => ({ ...eventsMap, [event.id]: event }),
		{}
	);

export const getPortCallEventsFlatMap = (sections: PortCallSection[]) =>
	transformEventsListIntoMap(getEventsFromSectionsAsList(sections));

export const getPortCallEventsFlatMapChanges = (
	sections: PortCallSection[],
	prevState: PortCallEventsById
): PortCallEventsById => {
	const events = getEventsFromSectionsAsList(sections).filter(
		event =>
			!prevState[event.id] ||
			event.concurrencyToken !== prevState[event.id].concurrencyToken
	);
	return transformEventsListIntoMap(events);
};
