import * as React from 'react';
import { Gap, Title, Flex } from 'components';
import {
	Row,
	Col,
	Input,
	InputNumber,
	TextArea,
	Autocomplete
} from 'components/antd';
import { FieldName } from '../BankAccountConstants';
import AllCurrencySelect from 'containers/AllCurrencySelect/AllCurrencySelect';
import styles from './AddBankAccount.module.scss';
import { required } from 'utils/validations';
import AllCountrySelect from 'containers/AllCountrySelect/AllCountrySelect';
import Api from 'services/api';
import { DEFAULT_AUTOCOMPLETE_PLACEHOLDER } from 'app-constants';

const GeneralFormFields = () => (
	<>
		<Gap isBlock bottom="md" top="md">
			<Row>
				<Col xs={3} lg={2}>
					<Input.ReduxFormItem
						type="text"
						name={FieldName.LABEL}
						label="Label"
						maxLength={32}
					/>
				</Col>
				<Col xs={9} lg={6}>
					<Flex inline className={styles.labelHint}>
						This can be anything you like, and will be used in Optic wherever we
						refer to this account
					</Flex>
				</Col>
			</Row>
			<hr />
		</Gap>
		<Title marginBottom={10}>BANK INFORMATION</Title>
		<Row>
			<Col xs={3} lg={2}>
				<Autocomplete.ReduxFormItem
					name={FieldName.BANK}
					label="Bank Name"
					required
					validate={required}
					placeholder={DEFAULT_AUTOCOMPLETE_PLACEHOLDER}
					onSearch={bankName => Api.BankAccounts.retrieveBanks({ bankName })}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.ADDRESS_LINE_2}
					label="Address Line 2"
					maxLength={35}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					required
					validate={required}
					name={FieldName.ADDRESS_LINE_4}
					label="Address Line 4 (State/Province/County)"
					maxLength={35}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.ZIP_CODE}
					label="Post/ZIP Code"
					maxLength={12}
				/>
			</Col>
		</Row>
		<Row>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.ADDRESS_LINE_1}
					label="Address Line 1"
					required
					validate={required}
					maxLength={35}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.ADDRESS_LINE_3}
					label="Address Line 3"
					maxLength={35}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.CITY}
					label="City"
					maxLength={35}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<AllCountrySelect
					name={FieldName.COUNTRY}
					label="Country"
					required
					validate={required}
				/>
			</Col>
		</Row>
		<Gap isBlock bottom="md">
			<hr />
		</Gap>
		<Title marginBottom={10}>ACCOUNT INFORMATION</Title>
		<Row>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.ACCOUNT_BENEFICIARY_NAME}
					label="Account/Beneficiary Name"
					required
					validate={required}
					maxLength={100}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<AllCurrencySelect
					name={FieldName.CURRENCY}
					label="Account Currency"
					required
					validate={required}
				/>
			</Col>
		</Row>
		<Row>
			<Col xs={3} lg={2}>
				<InputNumber.ReduxFormItem
					name={FieldName.ACCOUNT_NUMBER}
					label="Account Number"
					decimalPart={0}
					maxDigits={30}
					required
					validate={required}
					withDelimiter={false}
					allowLeadingZeros
				/>
			</Col>
			<Col xs={3} lg={2}>
				<InputNumber.ReduxFormItem
					name={FieldName.SORT_CODE}
					label="Sort Code"
					decimalPart={0}
					maxDigits={6}
					required
					validate={required}
					withDelimiter={false}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.IBAN}
					label="IBAN"
					maxLength={34}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					type="text"
					name={FieldName.SWIFT_BIC}
					label="SWIFT BIC"
					maxLength={11}
				/>
			</Col>
		</Row>
		<Row>
			<Col xs={9} lg={6}>
				<TextArea.ReduxFormItem
					name={FieldName.NOTES}
					label="Notes"
					maxLength={200}
					rows={3}
				/>
			</Col>
		</Row>
	</>
);

export default GeneralFormFields;
