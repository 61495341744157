import actionCreatorFactory from 'typescript-fsa';
import { UpdateThreadReadRequest } from 'services/api/messages/messagesServiceTypes';

const actionCreator = actionCreatorFactory();

const ACTION_NAME = 'thread/UPDATE_MESSAGE_READ';

export const updateThreadMessageRead = actionCreator<UpdateThreadReadRequest>(
	ACTION_NAME
);

export const updateThreadMessageReadAsync = actionCreator.async<
	UpdateThreadReadRequest,
	null,
	Error
>(ACTION_NAME, {
	failed: {
		omit: true
	}
});
