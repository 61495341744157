import { all, fork } from 'redux-saga/effects';
import retrieveServicesWatcher from './retrieveServicesSaga';
import retrieveServiceCategoriesWatcher from './retrieveServiceCategoriesSaga';
import addServiceCategoryWatcher from './addServiceCategorySaga';
import updateServiceCategoryWatcher from './updateServiceCategorySaga';
import deleteServiceCategoryWatcher from './deleteServiceCategorySaga';
export default function*() {
	yield all([
		fork(retrieveServicesWatcher),
		fork(retrieveServiceCategoriesWatcher),
		fork(addServiceCategoryWatcher),
		fork(updateServiceCategoryWatcher),
		fork(deleteServiceCategoryWatcher)
	]);
}
