import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { AddCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import {
	addCostSavings,
	addCostSavingsAsync,
	retrieveSavings
} from '../actions';
import { getPreparedAddCostSavingsData } from '../savingsSelectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import { COST_SAVINGS_MODAL } from 'sections/PortCall/Savings/SavingsGrid/Constants';

const apiCall = Api.Savings.addCostSavings;

export function* addCostSavingsExecutor(
	actionParams: AddCostSavingsRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(addCostSavingsAsync.started(actionParams));
	try {
		const formData = yield select(getPreparedAddCostSavingsData);
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		const request: AddCostSavingsRequest = {
			portCallId,
			jobCode,
			data: { portJobCode: jobCode, ...formData }
		};
		const response = yield call(api, request);

		yield put(
			addCostSavingsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(retrieveSavings({ portCallId, jobCode }));
	} catch (error) {
		yield put(
			addCostSavingsAsync.failed({
				error,
				params: actionParams
			})
		);
	} finally {
		yield put(closeModal(COST_SAVINGS_MODAL));
	}
}

export function* worker({
	payload
}: Action<AddCostSavingsRequest>): SagaIterator {
	yield call(addCostSavingsExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(addCostSavings.type, worker);
}
