import client from 'services/client';
import {
	User,
	UserAuth,
	InviteUserRequest,
	ResendInvitationRequest,
	RetrieveUsersRequest,
	RetrieveUserRequest,
	UserContact,
	UserSearchResultCollection,
	UserSearchGroupType,
	UserSearchRequest,
	UserUpdateRequest,
	UserUpdateStatusRequest,
	UserStatusesOption,
	RetrieveUsersOfMainPrincipalRequest,
	RetrieveUsersOfMainPrincipalResponse
} from 'services/api/users/userServiceTypes';
import {
	AxiosTypedPromise,
	ResultCollection,
	PagedRequest
} from 'services/api/apiTypes';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';

const retrieveUsers = (
	request: RetrieveUsersRequest
): AxiosTypedPromise<ResultCollection<User>> => {
	return client.get('/users', {
		params: {
			...request,
			searchTerm: request.searchTerm ? encodeURI(request.searchTerm) : undefined
		}
	});
};

const retrieveUser = ({
	userId,
	params
}: RetrieveUserRequest): AxiosTypedPromise<User> => {
	return client.get<User>(`/users/${userId}`, { params });
};

const retrieveAuthUser = () => {
	return client.get<UserAuth>(`/users/auth`);
};

const retrieveUserProfile = (userId: string) => {
	return client.get<User>(`/users/${userId}/profile`);
};

const sendUserInvitation = (
	request: InviteUserRequest
): AxiosTypedPromise<User> => {
	return client.post('/users/invite', request);
};

const resendUserInvitation = (
	request: ResendInvitationRequest
): AxiosTypedPromise<User> => {
	return client.post('/users/resend-invitation', request);
};

const updateUser = (
	userId: User['id'],
	data: UserUpdateRequest
): AxiosTypedPromise<void> => {
	return client.put(`users/${userId}`, data);
};

const updateUserStatus = (userId: User['id'], data: UserUpdateStatusRequest) =>
	client.put(`users/${userId}/status`, data);

const searchUsers = (
	searchTerm: string,
	showMoreInGroup: UserSearchGroupType | null
) => {
	const request: UserSearchRequest = {
		searchTerm,
		showMore: false
	};
	if (showMoreInGroup !== null) {
		request.showMore = true;
		request.groupType = showMoreInGroup;
	}
	return client.get<{ groups: UserSearchResultCollection[] }>(`/users/search`, {
		params: request
	});
};

const searchUsersForAutocomplete = (
	searchTerm: string,
	groupType?: UserSearchGroupType | null
) =>
	searchEntitiesForAutocompleteSearch<UserSearchGroupType | null>(
		searchUsers,
		searchTerm,
		groupType
	);

const retrieveUserStatuses = () =>
	client.get<UserStatusesOption[]>('/users/statuses');

/**
 * Retrieve the User contact list with contacts that were used in TO or CC fields at least once
 * (and wasn't removed by hand by the user)
 */
const retrieveUserContacts = (
	userId: string,
	searchTerm: string,
	{ index, limit, sortBy }: PagedRequest
): AxiosTypedPromise<ResultCollection<UserContact>> => {
	return client.get(`/users/${userId}/contacts`, {
		params: {
			searchTerm,
			index,
			limit,
			sortBy
		}
	});
};

const retrieveUsersOfMainPrincipal = ({
	companyId
}: RetrieveUsersOfMainPrincipalRequest) => {
	return client.get<RetrieveUsersOfMainPrincipalResponse>(
		`/users/main-principal/${companyId}`
	);
};

export default {
	retrieveUsers,
	retrieveUser,
	retrieveAuthUser,
	retrieveUserProfile,
	sendUserInvitation,
	resendUserInvitation,
	updateUser,
	searchUsers,
	searchUsersForAutocomplete,
	retrieveUserStatuses,
	retrieveUserContacts,
	updateUserStatus,
	retrieveUsersOfMainPrincipal
};
