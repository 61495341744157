import {
	put,
	select,
	takeLatest,
	all,
	take,
	race,
	call,
	delay
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	retrievePortCallVesselInfo,
	RetrievePortCallVesselInfoActionParams,
	retrievePortCallVesselInfoAsync
} from '../actions';
import { Action } from 'typescript-fsa';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { getRetrievedSinglePortCall } from 'store/portcalls/selectors';
import {
	retrievePortCallCompanyVessels,
	retrievePortCallCompanyVesselsAsync
} from 'store/companyVessels/actions';
import {
	retrieveVesselExtendAsync,
	retrieveVesselExtend
} from 'store/masterdata/vessels/actions';
import { notify } from 'store/notifications/actions';
import { navigateTo } from 'utils';
import {
	resetPortCall,
	retrievePortCall,
	retrievePortCallAsync
} from 'store/portcalls/actions';

function* waitForSuccess() {
	return yield all([
		take(retrieveVesselExtendAsync.done),
		take(retrievePortCallCompanyVesselsAsync.done)
	]);
}

function* waitForFailure() {
	return yield race({
		vessel: take(retrieveVesselExtendAsync.failed),
		companyVessels: take(retrievePortCallCompanyVesselsAsync.failed)
	});
}

function* errorHandler(portCallId: string, jobCode: string) {
	yield put(
		retrievePortCallVesselInfoAsync.failed({
			params: null,
			error: new Error('Vessel Details error')
		})
	);
	yield call(navigateTo, `/portcalls/${portCallId}/jobs/${jobCode}/overview`);
	yield delay(1000);
	yield put(
		notify.error('Vessel Details information are currently not available.')
	);
}

function* worker({
	payload: { portCallId }
}: Action<RetrievePortCallVesselInfoActionParams>): SagaIterator {
	yield put(retrievePortCallVesselInfoAsync.started(null));

	yield put(resetPortCall());
	yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));

	const { portCallFailure } = yield race({
		portCallFailure: take(retrievePortCallAsync.failed),
		portCallSuccess: take(retrievePortCallAsync.done)
	});

	if (portCallFailure) {
		return; // the fail for retrieve portcall is already implemented in retrievePortCall
	}
	const {
		vessel: { id },
		jobs: [firstJob]
	}: PortCall = yield select(getRetrievedSinglePortCall);
	yield put(
		retrieveVesselExtend({
			vesselId: id,
			companyId: firstJob.hubPrincipalCompany?.id
		})
	);
	yield put(retrievePortCallCompanyVessels({ portCallId }));
	const { failure } = yield race({
		failure: call(waitForFailure),
		success: call(waitForSuccess)
	});
	if (failure) {
		yield call(errorHandler, portCallId, firstJob.code);
		return;
	}

	yield put(
		retrievePortCallVesselInfoAsync.done({
			params: null,
			result: null,
			response: null
		})
	);
}

export default function*() {
	yield takeLatest(retrievePortCallVesselInfo, worker);
}
