import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Apm from 'services/monitoring/Apm';
import { getCurrentUser } from 'store/auth/selectors';
import config from 'services/config';

const version =
	config.version === '%build.number%' ? 'local-unknown' : config.version;

// Initialize an Apm agent
const agent: Apm = new Apm();
// Set a version tag
agent.apm.addLabels({ version });

const ApmTracker: FC<{}> = props => {
	const user = useSelector(getCurrentUser);

	useEffect(() => {
		agent.apm.setUserContext({
			id: user.id,
			username: user.fullName,
			email: user.email
		});
		agent.apm.setCustomContext({
			userType: user.userType,
			userGroupType: user.userGroupType,
			userTimeZone: user.timeZone
		});
	}, [user]);

	return <>{props.children}</>;
};

export default ApmTracker;
