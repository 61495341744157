import { Action } from 'typescript-fsa';
import { isNil } from 'lodash';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosTypedResponse, ResultCollection } from 'services/api/apiTypes';

import {
	getPortJobThreadsCountSelector,
	getFirstThreadIdFromActiveJob,
	getActivePortJobThreadId,
	getMessagesFilters
} from '../../selectors/portJobMessagesSelector';
import {
	RetrieveJobThreadsRequest,
	PortJobThread
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	retrievePortJobThreads as syncAction,
	retrievePortJobThreadsAsync as asyncAction
} from '../../actions/retrievePortJobThreads';
import Api from 'services/api';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { resetActivePortJobCode } from '../../actions';
import {
	isConcurrencyError,
	isNotFoundError
} from 'services/api/apiErrorUtils';
import { setActivePortJobThreadId } from '../../actions/setActivePortJobThreadId';
import { showFailOpenPageNotification } from 'utils/sagaHelpers/sagaUtils';
import prepareRequest from './prepareRequest';

const apiCall = Api.PortJobs.retrievePortJobThreads;

export function* executor(
	actionParams: RetrieveJobThreadsRequest,
	api: typeof apiCall
): SagaIterator {
	let { index } = actionParams;
	if (isNil(index)) {
		index = yield select(getPortJobThreadsCountSelector);
	}
	const filters = yield select(getMessagesFilters);
	const extraRequestParams = prepareRequest(filters);
	const params: RetrieveJobThreadsRequest = {
		limit: DEFAULT_LIST_LIMIT,
		...actionParams,
		...extraRequestParams,
		index
	};
	yield put(asyncAction.started(params));

	try {
		const response: AxiosTypedResponse<ResultCollection<
			PortJobThread
		>> = yield call(api, params);

		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
		const firstThreadId: string | undefined = yield select(
			getFirstThreadIdFromActiveJob
		);
		const activeThreadId = yield select(getActivePortJobThreadId);
		if (firstThreadId && !activeThreadId) {
			// there is no active thread, pick the first one
			yield put(setActivePortJobThreadId(firstThreadId));
		}
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params
			})
		);
		if (isConcurrencyError(error) || isNotFoundError(error)) {
			yield put(resetActivePortJobCode());
			yield call(showFailOpenPageNotification);
		}
	}
}

function* worker({ payload }: Action<RetrieveJobThreadsRequest>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(syncAction.type, worker);
}
