import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from '../TopNavBar.module.scss';
import { Breadcrumb, Icon } from 'components/antd';
import { Flex } from 'components/Styles';

import { BreadcrumbItem } from 'store/navigation/navigationState';
const renderBreadcrumbContent = (crumb: BreadcrumbItem) => {
	return (
		<span key={crumb.title}>
			{crumb.icon && (
				<Icon type={crumb.icon} color="standard" valign="baseline" />
			)}
			{crumb.link ? <Link to={crumb.link}>{crumb.title}</Link> : crumb.title}
		</span>
	);
};

const Separator = () => <Icon type="right" color="primary" offset="right" />;

export default ({ breadcrumbs }: { breadcrumbs: BreadcrumbItem[] }) => {
	return (
		<Flex fit shrink={0} align="center">
			<Breadcrumb separator={<Separator />} className={styles.breadcrumbs}>
				{Boolean(breadcrumbs.length) && <Separator />}
				{breadcrumbs.map((crumb: BreadcrumbItem) => (
					<Breadcrumb.Item key={crumb.title}>
						{renderBreadcrumbContent(crumb)}
						{crumb.children && crumb.children.map(renderBreadcrumbContent)}
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
		</Flex>
	);
};
