import { put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	ThirdPartyLPAUserInvite,
	ThirdPartyLPAUserInviteResponse
} from 'services/api/groups/groupsServiceTypes';
import {
	inviteThirdPartyLpaUserAsync,
	ThirdPartyLPAUserInvitePayload
} from '../actions/inviteThirdPartyLpaUser';
import { notify } from 'store/notifications/actions';
import { retrieveGroupUsers, resetGroupUsers } from '..';

export default makeTakeLatestWatcher<
	ThirdPartyLPAUserInvitePayload,
	ThirdPartyLPAUserInviteResponse,
	Error,
	ThirdPartyLPAUserInvite,
	{ email: string }
>({
	api: Api.Groups.sendThirdPartyLPAUserInvitation,
	async: inviteThirdPartyLpaUserAsync,
	*getApiParams(params) {
		return params.requestParams;
	},
	*onSuccess({ result, params }) {
		if (result.isExistingOpticUser) {
			yield put(
				notify.success('User has been successfully added to the group.')
			);
		} else {
			yield put(
				notify.success(
					'The user has been invited and post registration would be added to the group.'
				)
			);
		}
		yield put(resetGroupUsers(params.requestParams.groupId));
		yield put(
			retrieveGroupUsers({
				id: params.requestParams.groupId,
				isRequestedByThirdPartyLpaUser: true
			})
		);
	}
});
