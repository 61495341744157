import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormWarnings } from 'redux-form';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { Alert, Modal, Checkbox, Stub } from 'components/antd';
import { Flex, ScrollableLayout, Loading } from 'components';
import PortCallsDuplication from './PagePartials/PortCallsDuplication/PortCallsDuplication';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import Form from './PagePartials/Form/Form';
import { InjectedWizardFormBaseProps } from 'components/WizardForm';
import {
	FormData,
	Page1FormValues
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import {
	retrievePortCallsDuplicates,
	resetPortCallsDuplicates
} from 'store/portcalls/actions';
import { submitPage } from 'store/wizardForm/actions';
import {
	getPortCallsDuplicates,
	getIsPortCallsDuplicatesLoading
} from 'store/portcalls/selectors';
import { isCreationPortJobFirstStepPermissionError } from 'store/portJobs/selectors';

import {
	PortCall,
	RetrievePortCallsRequest
} from 'services/api/portCalls/portCallsServiceTypes';

import {
	FORM,
	PORT_JOB_WIZARD_ID,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { isUndefined, isEmpty } from 'lodash';
import {
	getOriginUrl,
	getWizardById
} from 'store/wizardForm/wizardFormSelectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { AppState } from 'store-types';
import { getJobClassificationFilterForUserType } from 'store/portJobs/portJobsSelectors';
import { ClassificationCode } from 'services/api/portJobs/portJobsServiceTypes';
import { getValues } from 'store/form/selectors';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

export interface PageProps extends InjectedWizardFormBaseProps {
	// from mapStateToProps
	formValues: FormData;
	portCalls: PortCall[];
	activeWizard: WizardFormProps;
	hasMorePortCalls: boolean;
	isRetrievingPortCalls: boolean;
	jobClassificationFilterForUserType: ClassificationCode[];
	isAssigningJob: boolean;
	isPermissionError: boolean;
	// from mapDispatchToProps
	retrievePortCallsDuplicates: typeof retrievePortCallsDuplicates;
	resetPortCallsDuplicates: typeof resetPortCallsDuplicates;
	submitPage: typeof submitPage;
}

export interface PageState {
	values: Partial<Page1FormValues>;
	showWarningModal: boolean;
	showAlert: boolean;
}

class Page extends React.Component<PageProps, PageState> {
	constructor(props: PageProps) {
		super(props);
		this.state = {
			values: {},
			showWarningModal: false,
			showAlert: true
		};
	}

	componentDidMount() {
		this.props.setActivePageState({
			onNextClick: this.onFormNextClick
		});
		this.props.resetPortCallsDuplicates();
	}

	onFormStateChange = (params: {
		valid: boolean;
		warning: FormWarnings<FormData>;
	}) => {
		const { valid, warning } = params;
		const activePageValidState = valid && isUndefined(warning);
		const { portCalls, activePageState, activeWizard, formValues } = this.props;

		if (activePageValidState !== activePageState.valid) {
			if (activeWizard.mode === 'assign') {
				this.props.setActivePageState({
					valid: portCalls.length > 0 || valid
				});
			} else {
				const isValid =
					(!portCalls.length ||
						(portCalls.length > 0 &&
							Boolean(formValues[FormFieldName.CREATE_NEW_PORT_CALL]))) &&
					valid;
				this.props.setActivePageState({
					valid: isValid
				});
			}
		}
	};

	onFormValuesChange = (params: {
		values: FormData;
		valid: boolean;
		vesselId?: string;
	}) => {
		const { values, valid, vesselId } = params;
		const {
			isAssigningJob,
			jobClassificationFilterForUserType,
			activeWizard
		} = this.props;
		if (valid) {
			const { vessel, port, etaPlt } = values.portCall;
			const portCallSearchParams: RetrievePortCallsRequest = {
				vesselId: vesselId ? vesselId : vessel.key,
				portId: port.key,
				eta: etaPlt && getLocalDateDefaultFormatted(etaPlt),
				expand: 'Operations',
				jobType: activeWizard.fromPath === 'nomination' ? 'Nomination' : ''
			};

			if (isAssigningJob) {
				portCallSearchParams.classification = jobClassificationFilterForUserType;
			}

			this.props.resetPortCallsDuplicates();
			this.props.retrievePortCallsDuplicates(portCallSearchParams);
			this.setState({
				values
			});
		} else if (this.props.portCalls.length) {
			this.props.resetPortCallsDuplicates();
		}
	};

	private onFormNextClick = () => {
		const { values } = this.state;
		const { isPermissionError, activeWizard } = this.props;
		this.props.submitPage({
			name: PORT_JOB_WIZARD_ID,
			page: activeWizard.activePage
		});
		if (isPermissionError) {
			this.setState({
				showAlert: true
			});
		} else {
			if (moment().isAfter(values.portCall && values.portCall.etaPlt)) {
				this.setState({
					showWarningModal: true
				});
			} else {
				this.props.nextPage();
			}
		}
	};

	onAlertClose = () => {
		this.setState({
			showAlert: false
		});
	};

	renderDuplicationForm() {
		const { portCalls, activeWizard, hasMorePortCalls } = this.props;
		if (isEmpty(portCalls)) {
			return (
				activeWizard.mode === 'assign' &&
				!hasMorePortCalls && <Stub>No jobs found that match your criteria</Stub>
			);
		}
		return (
			<Flex fit direction="vertical">
				<PortCallsDuplication
					duplicationPortCalls={portCalls}
					isFromPathNomination={activeWizard.fromPath === 'nomination'}
				/>
				{activeWizard.mode !== 'assign' && (
					<ReduxForm
						form={FORM.portJob}
						destroyOnUnmount={false}
						forceUnregisterOnUnmount={true}
					>
						<Checkbox.ReduxFormItem name={FormFieldName.CREATE_NEW_PORT_CALL}>
							Continue by creating new Port Call
						</Checkbox.ReduxFormItem>
					</ReduxForm>
				)}
			</Flex>
		);
	}

	render() {
		const {
			portCalls,
			activePageState,
			isRetrievingPortCalls,
			isPermissionError,
			activeWizard
		} = this.props;
		const { showAlert } = this.state;
		const showPermissionError =
			isPermissionError && activePageState.submitted && showAlert;

		return (
			<ScrollableLayout asColumn>
				{showPermissionError && (
					<Alert
						message={`You are not allowed to create a job for the provided combination of Hub Principal, Vessel and Port. Please contact your Optic administrator.`}
						type="error"
						showIcon
						closable
						onClose={this.onAlertClose}
					/>
				)}
				<ContextBar
					title="Please provide the Port Call details"
					type={ContextBarType.ACTION}
					actor={[UserType.PRINCIPAL, UserType.HUB]}
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					subtitle="Please specify a Port Call. If Optic finds an existing match, we will show you below."
					condition={true}
				/>
				<Form
					onFormStateChange={this.onFormStateChange}
					onFormValuesChange={this.onFormValuesChange}
					hasDuplication={portCalls.length > 0}
					isAssignMode={activeWizard.mode === 'assign'}
				/>
				{isRetrievingPortCalls ? (
					<Loading show={false} />
				) : (
					this.renderDuplicationForm()
				)}
				<Modal
					visible={this.state.showWarningModal}
					cancelText="Cancel"
					okText="Continue"
					onOk={this.props.nextPage}
					onCancel={() => this.setState({ showWarningModal: false })}
				>
					<p>ETA is in the past. Are you sure you want to continue?</p>
				</Modal>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		formValues: getValues<FormData>(state, PORT_JOB_WIZARD_ID),
		portCalls: getPortCallsDuplicates(state),
		activeWizard: getWizardById(state, PORT_JOB_WIZARD_ID),
		hasMorePortCalls: state.portcalls.hasMoreDuplicates,
		isRetrievingPortCalls: getIsPortCallsDuplicatesLoading(state),
		jobClassificationFilterForUserType: getJobClassificationFilterForUserType(
			state
		),
		isAssigningJob: Boolean(getOriginUrl(state)),
		isPermissionError: isCreationPortJobFirstStepPermissionError(state)
	}),
	{
		retrievePortCallsDuplicates,
		resetPortCallsDuplicates,
		submitPage
	}
)(Page);
