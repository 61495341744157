import { LabeledValue } from 'app-types';

export const KPI_ALERTS_EDIT_FORM = 'KpiAlertsEditJobForm';
export const CANCEL_ALERTS_EDIT_FORM = 'CancelAlertsEdit';
export const SUBMIT_ALERTS_EDIT_FORM = 'SubmitAlertsEdit';

export interface KpiAlertsEditFormData {
	id: string;
	kpiName: string;
	userType: string;
	metricCode: string;

	toleranceHours: string;
	toleranceMinutes: string;
	opticAlertPermission: LabeledValue<string>;

	hubAlertHours?: string;
	hubAlertMinutes?: string;
	hubAlertPermission?: LabeledValue<string>;

	notificationEmailOption?: string;
	notificationEmailHours?: string;
	notificationEmailMinutes?: string;

	reminderEmailOption?: string;
	reminderEmailHours?: string;
	reminderEmailMinutes?: string;

	lateEmailOption?: string;
	lateEmailHours?: string;
	lateEmailMinutes?: string;

	isWeekendIncluded: string;
}
export enum KpiAlertsEditFormNames {
	KPI_NAME = 'kpiName',
	USER_TYPE = 'userType',

	TOLERANCE_HH = 'toleranceHours',
	TOLERANCE_MM = 'toleranceMinutes',
	OPTIC_ALERT_PERMISSION = 'opticAlertPermission',

	HUB_ALERT_HH = 'hubAlertHours',
	HUB_ALERT_MM = 'hubAlertMinutes',
	HUB_ALERT_PERMISSION = 'hubAlertPermission',

	NOTIFICATION_EMAIL_OPTION = 'notificationEmailOption',
	NOTIFICATION_EMAIL_HH = 'notificationEmailHours',
	NOTIFICATION_EMAIL_MM = 'notificationEmailMinutes',

	REMINDER_EMAIL_OPTION = 'reminderEmailOption',
	REMINDER_EMAIL_HH = 'reminderEmailHours',
	REMINDER_EMAIL_MM = 'reminderEmailMinutes',

	LATE_EMAIL_OPTION = 'lateEmailOption',
	LATE_EMAIL_HH = 'lateEmailHours',
	LATE_EMAIL_MM = 'lateEmailMinutes',

	INCLUDE_WEEKENDS = 'isWeekendIncluded'
}

export enum EmailOption {
	NOT_APPLICABLE = 'notApplicable',
	HOURS_AND_MINUTES = 'hoursAndMinutes'
}

export enum WeekendsOption {
	YES = 'yes',
	NO = 'no'
}
