import { SagaIterator } from 'redux-saga';
import {
	call,
	put,
	take,
	takeLatest,
	takeEvery,
	select,
	cancel
} from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getActivePortJob,
	getIsMainPrincipalTypeCLS
} from 'store/portJobs/portJobsSelectors';
import { EDIT_PORTJOB_FORM } from './editPortJobDataTypes';
import {
	editPortJob,
	editPortJobAsync,
	retrieveEditPortJobData
} from 'store/portJobs/actions';
import { getValues } from 'store/form/selectors';
import {
	UpdatePortJobRequest,
	PortJob,
	UpdatePortJobRequestPayload
} from 'services/api/portJobs/portJobsServiceTypes';
import Api from 'services/api';
import { navigateTo } from 'utils';
import { prepareInitialPortJobRequest } from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	getAllCompanyDraftsByType,
	getCompanyDraftsByType
} from 'store/drafts/selectors';
import { DraftType } from 'store/drafts/draftsState';
import {
	isConcurrencyError,
	isNotFoundError
} from 'services/api/apiErrorUtils';
import { setPortJobUpToDate } from 'store/portJobs/actions/setPortJobUpToDate';
import { openModal, closeModal } from 'store/modals/actions';
import { refreshEditPortJob } from 'store/portJobs/actions/editPortJob';
import { changeRoute } from 'store/route/actions';
import { showFailOutdatedNotification } from 'utils/sagaHelpers/sagaUtils';
import Matomo from 'services/analytics/adapters/Matomo';

function* showRefreshConfirmationWorker() {
	yield put(
		openModal({
			name: 'refresh',
			type: 'confirm',
			data: {
				title: '',
				description:
					'All changes will be lost. Are you sure you want to continue?',
				okText: 'Confirm',
				cancelText: 'Cancel'
			}
		})
	);
}
function* refreshEditPortJobWorker() {
	const portJobCode = yield select(getActivePortJobCode);
	const portCallId = yield select(getActivePortCallId);

	yield call(showRefreshConfirmationWorker);
	const { payload } = yield take(closeModal.type);
	if (payload.isConfirmed) {
		yield put(
			retrieveEditPortJobData({
				portCallId,
				portJobCode
			})
		);
	}
}

export function* worker(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const portJobAgentCompanies = yield select(
		getAllCompanyDraftsByType,
		DraftType.AGENT
	);
	const portJobPrincipalCompanies = yield select(
		getCompanyDraftsByType,
		DraftType.PRINCIPAL
	);
	const portJobChartererCompanies = yield select(
		getCompanyDraftsByType,
		DraftType.CHARTERER
	);
	const portJobCompanies = portJobAgentCompanies.concat(
		portJobPrincipalCompanies,
		portJobChartererCompanies
	);
	const { concurrencyToken }: PortJob = yield select(getActivePortJob);
	const formValues: FormData = yield select(getValues, EDIT_PORTJOB_FORM);
	const isMainPrincipalTypeCLS = yield select(getIsMainPrincipalTypeCLS);
	const initialPortJobRequest = prepareInitialPortJobRequest(
		formValues,
		isMainPrincipalTypeCLS
	);
	const portJobData: UpdatePortJobRequestPayload = {
		...initialPortJobRequest,
		portJobCompanies,
		concurrencyToken
	};
	const payload: UpdatePortJobRequest = {
		portCallId,
		jobCode,
		payload: portJobData
	};
	yield put(editPortJobAsync.started(payload));
	try {
		const response = yield call(Api.PortJobs.updatePortJob, payload);
		yield put(
			editPortJobAsync.done({
				params: payload,
				result: response,
				response
			})
		);
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'PortCall.UpdateJob',
			name: `Port job for port call ${payload.portCallId} has been updated ${payload.jobCode}`,
			value: 1
		});
		yield call(navigateTo, `/portcalls/${portCallId}/overview`);
	} catch (error) {
		yield put(
			editPortJobAsync.failed({
				error,
				params: payload
			})
		);
		if (isConcurrencyError(error)) {
			yield put(setPortJobUpToDate(false));
			const refreshTask = yield takeEvery(
				refreshEditPortJob,
				refreshEditPortJobWorker
			);
			yield take(changeRoute);
			yield cancel(refreshTask);
		}
		if (isNotFoundError(error)) {
			yield call(navigateTo, '/portcalls');
			yield call(showFailOutdatedNotification);
		}
	}
}

export default function*() {
	yield takeLatest(editPortJob.type, worker);
}
