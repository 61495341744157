import { flatten, isNil, differenceBy } from 'lodash';
import { isPristine, isValid } from 'redux-form';
import { LabeledValue } from 'app-types';
import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';
import { createSelector } from 'reselect';
import { getFilterValues } from 'store/filters/filtersSelectors';
// TODO: Rermove dependencies with form data and types with this selector
import {
	EditDirectBillFormData,
	CREATE_DIRECT_BILL_FORM_NAME
} from 'sections/Administration/DataManagement/DirectBills/DirectBill/EditDirectBill/EditDirectBillTypes';
import { getValues } from 'store/form/selectors';
import { DirectBillsFiltersParam } from '../filtersSync';
import {
	UpdateDirectBillRequest,
	DirectBillDiscountType,
	AgreementMakerCompanyOrganisationTypeCode
} from 'services/api/directBills/directBillsServiceTypes';

const getDirectBillsMap = (state: AppState) => state.directBills.byId;
const getDirectBillsAllIds = (state: AppState) => state.directBills.allIds;

export const getActiveDirectBillId = (state: AppState) =>
	state.directBills.context.activeDirectBillId;

export const getDirectBill = (state: AppState) =>
	state.directBills.context.directBill;

const defaultFilters = {
	[DirectBillsFiltersParam.STATUS]: [
		{ label: 'All', key: 'all', type: 'status' }
	],
	[DirectBillsFiltersParam.SEARCH]: []
};

export const getDirectBillsFilters = createSelector(
	getFilterValues,
	filters => {
		return {
			...defaultFilters,
			...filters
		};
	}
);

export const getDirectBillsFiltersTags = createSelector(
	getFilterValues,
	filters => {
		if (!filters) {
			return [];
		}
		return flatten(Object.values(filters));
	}
);

export const getIsDirectBillsFetching = (state: AppState) =>
	state.directBills.fetchStatuses.all === FetchStatus.PENDING;

export const getEditDirectBillInitializeFetchStatus = (state: AppState) =>
	state.directBills.fetchStatuses.edit;

export const getDirectBillsCount = createSelector(
	getDirectBillsAllIds,
	dbs => dbs.length
);

export const getDirectBillContext = (state: AppState) =>
	state.directBills.context;

export const getDirectBills = createSelector(
	getDirectBillsMap,
	getDirectBillsAllIds,
	(byId, allIds) => {
		return allIds.map((id: string) => byId[id]).filter(item => item);
	}
);

const directBillInitialValues = {
	discountTypeCode: DirectBillDiscountType.PERCENTAGE,
	agreementMakerCompanyOrganisationTypeCode:
		AgreementMakerCompanyOrganisationTypeCode.PRINCIPAL
};

const getEditableDirectBill = createSelector(
	getDirectBillContext,
	(context): Partial<EditDirectBillFormData> => {
		const { directBill } = context;
		if (!directBill) {
			return directBillInitialValues;
		}

		const multiselectFromArr = (key: string) => (entity: {
			name: string;
		}): LabeledValue<string> => ({
			key: entity[key],
			label: entity.name
		});
		return {
			agreementMakerCompanyOrganisationTypeCode:
				directBill.agreementMakerCompanyOrganisationTypeCode,
			appointmentInstruction: directBill.appointmentInstruction,
			notes: directBill.notes,
			validFrom: directBill.validFrom,
			validTo: directBill.validTo,
			validityShouldNotExpire: isNil(directBill.validTo),
			targetTypeCode: directBill.targetTypeCode,
			discountValue: directBill.discountValue
				? String(directBill.discountValue)
				: undefined,
			discountTypeCode: directBill.discountTypeCode,
			creditDays: String(directBill.creditDays),
			mainPrincipalCompany: multiselectFromArr('id')(
				directBill.mainPrincipalCompany
			),
			principals: directBill.principals.map(multiselectFromArr('id')),
			countries:
				directBill.countries &&
				directBill.countries.map(multiselectFromArr('code')),
			ports: directBill.ports && directBill.ports.map(multiselectFromArr('id')),
			services: directBill.services.map(multiselectFromArr('code')),
			vendorCompany: multiselectFromArr('id')(directBill.vendorCompany),
			documents: directBill.documents
		};
	}
);

export const getEditDirectBillFormValues = (
	state: AppState
): EditDirectBillFormData => {
	return getValues<EditDirectBillFormData>(state, CREATE_DIRECT_BILL_FORM_NAME);
};

export const getEditDirectBillInitialFormValues = createSelector(
	getEditableDirectBill,
	directBill => {
		return { ...directBill };
	}
);

export const getDirectBillsUploadedDocuments = (state: AppState) => {
	return state.directBills.context.uploadedDocuments;
};

export const getIsUpdateDirectBillsUpdating = (state: AppState) => {
	return state.directBills.fetchStatuses.update === FetchStatus.PENDING;
};

export const getIsSubmitDirectBillDisabled = createSelector(
	isValid(CREATE_DIRECT_BILL_FORM_NAME),
	isPristine(CREATE_DIRECT_BILL_FORM_NAME),
	getDirectBillContext,
	getDirectBillsUploadedDocuments,
	(isValid, isPristine, { directBill }, uploadedDocuments) => {
		const documents = directBill?.documents || [];
		const documentHasBeenRemoved = !!differenceBy(
			documents,
			uploadedDocuments,
			'id'
		).length;
		const documentHasBeenUploaded = !!differenceBy(
			uploadedDocuments,
			documents,
			'id'
		).length;
		const documentHasBeenRemovedOrUploaded =
			documentHasBeenRemoved || documentHasBeenUploaded;
		return !isValid || (isPristine && !documentHasBeenRemovedOrUploaded);
	}
);

export const getEditableDirectBillId = (state: AppState) => {
	const db = getDirectBillContext(state).directBill;
	return db?.id;
};

export const getEditDirectBillRequest = (
	state: AppState
): UpdateDirectBillRequest => {
	const form = getValues<EditDirectBillFormData>(
		state,
		CREATE_DIRECT_BILL_FORM_NAME
	);

	const formatMultipleFormField = (
		field: Array<{ key: string }>,
		key: string
	) => {
		if (Array.isArray(field) && field.length > 0) {
			return field.map(entry => ({ [key]: entry.key }));
		}
		return;
	};
	return {
		...form,
		mainPrincipalCompany: { id: form.mainPrincipalCompany.key },
		discountTypeCode: form.discountTypeCode,
		services: formatMultipleFormField(form.services, 'code'),
		vendorCompany: { id: form.vendorCompany.key },
		creditDays: parseFloat(form.creditDays.replace(/,/g, '')),
		discountValue: form.discountValue
			? parseFloat(form.discountValue.replace(/,/g, ''))
			: undefined,
		// Either Countries or Ports should be specified exlusively
		countries: formatMultipleFormField(form.countries, 'code'),
		ports: formatMultipleFormField(form.ports, 'id'),
		principals: formatMultipleFormField(form.principals, 'id'),
		documents: getDirectBillsUploadedDocuments(state),
		validTo: form.validityShouldNotExpire ? null : form.validTo,
		draftVendorCompanies: []
	} as UpdateDirectBillRequest;
};
