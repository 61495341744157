import {
	RetrieveDirectBillsResponse,
	RetrieveDirectBillsRequest
} from 'services/api/directBills/directBillsServiceTypes';
import actionCreator from '../directBillsActionCreator';

const ACTION_NAME = 'RETRIEVE_DIRECT_BILLS';

export const retrieveDirectBillsAsync = actionCreator.async<
	RetrieveDirectBillsRequest,
	RetrieveDirectBillsResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'sub-section', infiniteLoad: true } });

export const retrieveDirectBills = actionCreator(ACTION_NAME);
