import actionCreator from '../draftsActionCreator';
import { DraftType } from '../draftsState';

export interface SetActiveDraftTypeParams {
	activeDraftType: DraftType;
	activeDraftTypeAlias?: string;
}

export const setActiveDraftType = actionCreator<SetActiveDraftTypeParams>(
	'SET_ACTIVE_DRAFTS_TYPE'
);
