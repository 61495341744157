import * as React from 'react';
import classNames from 'classnames';
import { isString } from 'lodash';
import styles from './Truncate.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ellipsify(value: any, wordsCount: number) {
	if (!isString(value)) {
		return value;
	}
	const trimmedString = value.split(' ').splice(0, wordsCount);
	return trimmedString.length > wordsCount
		? `${trimmedString.join(' ')}...`
		: trimmedString.join(' ');
}

interface TruncateProps {
	className?: string;
	words?: number;
}

export const Truncate: React.SFC<TruncateProps> = props => {
	return (
		<span className={classNames(styles.truncate, props.className)}>
			{props.words ? ellipsify(props.children, props.words) : props.children}
		</span>
	);
};

export default Truncate;
