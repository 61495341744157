import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveThreadTagsAsync } from 'store/threads/actions';
import { RetrieveThreadTagsResponse } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Threads.retrieveThreadTags;

export const retrieveThreadTagsWatcher = makeTakeLatestWatcher<
	null,
	RetrieveThreadTagsResponse,
	Error
>({
	api: apiCall,
	async: retrieveThreadTagsAsync
});

export default retrieveThreadTagsWatcher;
