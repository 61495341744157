import {
	MoveToStageJobFinanceResponse,
	MoveToStageJobFinanceRequest,
	MoveToStagePayload as Payload
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const MOVE_TO_STAGE = 'MOVE_TO_STAGE';

export const moveToStageAsync = actionCreator.async<
	MoveToStageJobFinanceRequest,
	MoveToStageJobFinanceResponse,
	Error
>(MOVE_TO_STAGE, {
	behaviour: { type: 'react-to-changes-child' }
});

export const moveToStage = actionCreator<Payload>(MOVE_TO_STAGE);
