import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	CheckPortJobPermissionsRequest,
	CheckPortJobPermissionsResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onCheckPortJobPermissionsStarted = (
	state: PortJobState
): PortJobState => ({
	...state,
	error: undefined,
	fetchStatuses: {
		...state.fetchStatuses,
		creationPortJobPermissions: FetchStatus.PENDING
	}
});

export const onCheckPortJobPermissionsSuccess = (
	state: PortJobState,
	action: Success<
		CheckPortJobPermissionsRequest,
		CheckPortJobPermissionsResponse
	>
): PortJobState => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			creationPortJobPermissions: FetchStatus.SUCCESS
		},
		context: {
			...state.context,
			creationPortJobPermissions: {
				hasPermission: action.result.hasPermission
			}
		}
	};
};

export const onCheckPortJobPermissionsFailed = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		creationPortJobPermissions: FetchStatus.FAILURE
	}
});
