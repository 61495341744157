import { put, call, takeLatest } from 'redux-saga/effects';
import { retrievePortJobStatusesAsync } from 'store/portJobs/actions/retrievePortJobStatuses';
import Api from 'services/api';

const apiCall = Api.PortJobs.retrievePortJobStatuses;
export const executor = function*(api: typeof apiCall) {
	yield put(retrievePortJobStatusesAsync.started(null));
	try {
		const response = yield call(api);
		yield put(
			retrievePortJobStatusesAsync.done({
				result: response.data.elements,
				params: null,
				response
			})
		);
	} catch (error) {
		yield put(
			retrievePortJobStatusesAsync.failed({
				error,
				params: null
			})
		);
	}
};

export function* worker() {
	yield call(executor, apiCall);
}

export function* retrievePortJobStatusesWatcher() {
	yield takeLatest(retrievePortJobStatusesAsync.type, worker);
}
