interface Rules {
	/**
	 * Set of regexp patterns that target to normalize HTML string before deserialization
	 */
	rules: Rule[];
}

interface Rule {
	rule: RegExp;
	replaceValue: string | ((substring: string, ...args: any[]) => string); // eslint-disable-line
}

const RULES: Rules[] = [
	{
		rules: [
			// Remove new lines, tabs, etc inside tag brackets
			// Replace them with 1 space to ensure that strings aren't joined together
			{
				rule: /<[^>]*((\r?\n|\r|\t|↵)+)[^>]*>/g,
				replaceValue: (match, $1) => match.replace($1, ' ')
			},

			// Remove new lines, tabs, etc globally
			{
				rule: /(\r?\n|\r|\t|↵)/g,
				replaceValue: ''
			},

			// Don't preserve more than 1 space that may be caused by template formatting or previous regexp rules
			{
				rule: /[ ]{2,}/g,
				replaceValue: ' '
			},

			// Remove spaces around tags
			{
				rule: /(<[^>]+)>\s+(?=<[^>]+>)/gi,
				replaceValue: '$1>'
			},

			// Remove spaces around tags: at the beginning and end of the string
			{
				rule: /^\s+(<[^>]+>)|(<\/[^>]+>)\s+$/gi,
				replaceValue: '$1$2'
			},

			// Remove specific list of tags without content and attributes
			{
				rule: /<(p|div|span|tr)\s*><\/\1>/gi,
				replaceValue: ''
			},

			// Remove the only <br> in a tag since that results in 2 editable blocks instead of 1
			// Later, empty default block will be outputted again, with <br>
			{
				rule: /><br[ |\/]*><\//gi,
				replaceValue: '></'
			},

			// replace <br/> with the new line
			// br is related with newline due to issues appearing while editing content containing <br /> like
			// moving to the next line and it being void
			{
				rule: /<br[ |\/]*>/g,
				replaceValue: '\n'
			}
		]
	}
];

export default RULES;
