import { FinanceState } from '../financeState';
import {
	UpdateServiceStatusRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function onUpdateServiceStatusSuccess(
	state: FinanceState,
	action: Success<UpdateServiceStatusRequest, PortJobService>
): FinanceState {
	const finance = state.finance;
	const { id } = action.result;
	if (!finance) {
		return state;
	}
	return {
		...state,
		finance: {
			...finance,
			services: {
				...finance.services,
				elements: finance.services.elements.map(
					service => (service.id === id ? action.result : service)
				)
			}
		}
	};
}

export const onUpdateServiceStatus = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateServiceStatusRequest,
	PortJobService
>('fetchStatuses', 'updateServiceStatus', {
	doneReducer: onUpdateServiceStatusSuccess
});
