import * as React from 'react';
import AntBadge, { BadgeProps as AntBadgeProps } from 'antd/lib/badge';
import classNames from 'classnames';

import { Placement, ThemeScheme } from 'components/types';

import styles from './Badge.module.scss';

interface BadgeProps extends AntBadgeProps {
	placement?: Placement;
	theme?: ThemeScheme;
	/**
	 * Should color/size be applied to icon
	 * TODO: this prop has been introduced due to the styles applied to `anticon` by default
	 * Review the need in applying styles to `anticon` globally vs locally
	 */
	adjustIcon?: boolean;
	show?: boolean;
}
export const Badge: React.FC<BadgeProps> = ({
	placement = 'bottomRight',
	theme = 'success',
	show = true,
	showZero = false,
	adjustIcon = true,
	...props
}) => {
	if (!show) {
		return <>{props.children || null}</>;
	}
	return (
		<AntBadge
			{...props}
			className={classNames(
				styles.root,
				props.className,
				styles[theme],
				styles[placement],
				{
					[styles.adjustIcon]: adjustIcon
				}
			)}
			showZero={showZero}
		/>
	);
};

export default Badge;
