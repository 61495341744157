import { FinanceState } from '../financeState';
import { ServiceSectionCode } from 'services/api/finance/financeServiceTypes';

export function onResetAvailableServices(
	state: FinanceState,
	action: { sectionCode: ServiceSectionCode; entityId: string }
): FinanceState {
	const { sectionCode, entityId } = action;
	return {
		...state,
		context: {
			...state.context,
			availableServices: {
				...state.context.availableServices,
				[`${sectionCode}-${entityId}`]: []
			}
		}
	};
}
