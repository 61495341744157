import { FetchStatus } from 'services/api/apiTypes';
import {
	PortCallEvent,
	PortCallEventTemplate,
	AccessiblePortJob,
	PortCallSection
} from 'services/api/portCall/portCallServiceTypes';

export interface PortCallSectionState {
	[code: string]: PortCallSection;
}

export interface PortCallEventsById {
	[eventId: string]: PortCallEvent;
}

export interface PortCallEventIdsBySectionCode {
	[sectionId: string]: string[];
}

interface EventTemplatesState {
	[portCallId: string]: {
		[sectionId: string]: EventTemplatesMap;
	};
}

export interface EventTemplatesMap {
	[id: string]: PortCallEventTemplate;
}

export interface PortCallOperationsState {
	readonly context: {
		deleteEventModal: {
			visible: boolean;
			eventId: string | undefined;
		};
		lastUpdatedEventId?: string;
	};

	readonly eventSectionsByPortCallId: {
		[id: string]: {
			eventsById: PortCallEventsById;
			eventsIdsBySectionId: PortCallEventIdsBySectionCode;
			sections: PortCallSectionState;
		};
	};
	readonly eventTemplates: EventTemplatesState;
	readonly fetchStatuses: {
		eventSectionsByPortCallId: {
			[portCallId: string]: FetchStatus;
		};
		eventTemplates: {
			[portCallId: string]: {
				[sectionId: string]: FetchStatus;
			};
		};
		accessiblePortJobs: {
			[portCallId: string]: FetchStatus;
		};
	};
	readonly updateStatuses: {
		events: {
			[id: string]: FetchStatus;
		};
	};
	readonly addStatuses: {
		eventTemplates: {
			[code: string]: FetchStatus;
		};
	};
	readonly accessiblePortJobs: {
		[portCallId: string]: AccessiblePortJob[];
	};
}

export const initialState: PortCallOperationsState = {
	context: {
		deleteEventModal: {
			visible: false,
			eventId: undefined
		}
	},
	eventSectionsByPortCallId: {},
	eventTemplates: {},
	fetchStatuses: {
		eventSectionsByPortCallId: {},
		eventTemplates: {},
		accessiblePortJobs: {}
	},
	updateStatuses: {
		events: {}
	},
	addStatuses: {
		eventTemplates: {}
	},
	accessiblePortJobs: {}
};
