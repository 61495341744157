import actionCreator from '../groupsActionCreator';
import { Group } from 'services/api/groups/groupsServiceTypes';

const ACTION_NAME = 'RETRIEVE_GROUP';
export const retrieveGroupAsync = actionCreator.async<string, Group, Error>(
	ACTION_NAME,
	{
		failed: {
			returnUrl: '/groups'
		}
	}
);
export const retrieveGroup = actionCreator<string>(ACTION_NAME);
