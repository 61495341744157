import React from 'react';
import classNames from 'classnames';
import ReadMore from '../ReadMore/ReadMore';
import Icon from 'components/antd/Icon/Icon';
import styles from './ShowMoreBlock.module.scss';

interface ShowMoreTextBlockProps {
	showLines?: number;
	showControls?: boolean;
	children: React.ReactNode;
}

export default ({
	showLines = 3,
	showControls,
	children
}: ShowMoreTextBlockProps) => (
	<ReadMore
		maxItems={showLines}
		className={classNames(styles.root, {
			[styles.showControls]: showControls
		})}
		toggleBtnClassName={styles.toggleBtn}
		iconSize={'xxs'}
		renderFooterContent={props => {
			return (
				<>
					{props.collapsed ? props.expandText : props.collapseText}
					<Icon type={props.collapsed ? 'down' : 'up'} />
				</>
			);
		}}
		source={children}
	/>
);
