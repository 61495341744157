import { RetrieveFinanceCurrenciesResponse } from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'RETRIEVE_FINANCE_CURRENCIES';
export const retrieveFinanceCurrenciesAsync = actionCreator.async<
	null,
	RetrieveFinanceCurrenciesResponse,
	Error
>(ACTION_NAME);

export const retrieveFinanceCurrencies = actionCreator(ACTION_NAME);
