import actionCreator from '../bankAccountsActionCreator';
import { AddBankAccountRequest as Request } from 'services/api/bankAccounts/bankAccountsServiceTypes';

const ACTION_NAME = 'ADD_BANK_ACCOUNT';

export const addBankAccount = actionCreator(ACTION_NAME);
export const addBankAccountAsync = actionCreator.async<Request, null>(
	ACTION_NAME,
	{
		templateParams: {
			entityName: 'Bank Details'
		}
	}
);
