import {
	retrievePortJobThreadsCycleStart,
	retrievePortJobThreadsCycleStop
} from '../actions/retrievePortJobThreadsCycle';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	cancel,
	fork,
	take,
	put,
	select,
	delay,
	call
} from 'redux-saga/effects';
import { retrievePortJobThreadsAsync } from '../actions';
import {
	PortJobThread,
	RetrieveJobThreadsRequest
} from 'services/api/portJobs/portJobsServiceTypes';
import { DEFAULT_LIST_LIMIT, DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
import Api from 'services/api';
import {
	getMessagesFilters,
	getPortJobThreadsCountSelector
} from '../selectors';
import prepareRequest from './retrievePortJobThreads/prepareRequest';
import { isNil } from 'lodash';
import { AxiosTypedResponse, ResultCollection } from 'services/api/apiTypes';

const apiCall = Api.PortJobs.retrievePortJobThreads;

export function* worker({
	payload
}: Action<RetrieveJobThreadsRequest>): SagaIterator {
	// wait for initial request to be finished
	yield take(retrievePortJobThreadsAsync.done);

	// set fallback to notification
	yield put(
		setNotificationActionTypeToNotification([retrievePortJobThreadsAsync.type])
	);
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		let { index } = payload;
		if (isNil(index)) {
			index = yield select(getPortJobThreadsCountSelector);
		}

		const filters = yield select(getMessagesFilters);
		const extraRequestParams = prepareRequest(filters);

		const params: RetrieveJobThreadsRequest = {
			limit: DEFAULT_LIST_LIMIT,
			...payload,
			...extraRequestParams,
			index
		};
		try {
			const response: AxiosTypedResponse<ResultCollection<
				PortJobThread
			>> = yield call(apiCall, params);
			yield put(
				retrievePortJobThreadsAsync.done({
					result: response.data,
					params,
					response
				})
			);
		} catch (error) {
			yield put(retrievePortJobThreadsAsync.failed({ error, params }));
		}
	}
}

export default function*() {
	while (yield take(retrievePortJobThreadsCycleStart)) {
		const action = yield take(retrievePortJobThreadsCycleStart);
		const pollThreadsTask = yield fork(worker, action);
		yield take(retrievePortJobThreadsCycleStop);
		yield cancel(pollThreadsTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrievePortJobThreadsAsync.type
			])
		); // clear fallback
	}
}
