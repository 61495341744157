import React from 'react';
import { EntityCode } from 'app-types';
import { Select } from 'components/antd';
import { ReduxFieldProps } from 'components/antd/Form/ReduxField/createReduxField';
import { DataFetcher } from 'utils/components';
import { SelectProps } from 'antd/lib/select';
import { FetchStatus } from 'services/api/apiTypes';

interface AllSelectProps extends ReduxFieldProps<SelectProps> {
	labelName: string;
	fetchStatus: FetchStatus;
	options: EntityCode[];
	retrieve: () => void;
}

const LoadableSelect = React.memo(function(props: AllSelectProps) {
	const { options, ...restProps } = props;
	return (
		<DataFetcher
			loadingSize="xs"
			fetchStatus={props.fetchStatus}
			dispatch={() => {
				props.retrieve();
			}}
			data={options}
		>
			{() => (
				<Select.ReduxFormItem {...restProps}>
					{options.map(option => (
						<Select.Option key={option.code} title={option.code}>
							{option[props.labelName]}
						</Select.Option>
					))}
				</Select.ReduxFormItem>
			)}
		</DataFetcher>
	);
});

export default LoadableSelect;
