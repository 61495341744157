import * as React from 'react';
import { ButtonProps, Button } from 'components/antd/Button/Button';
import {
	PermissionWrapper,
	PermissionWrapperProps
} from 'components/Permission/Wrapper/Wrapper';

type PermissionButtonProps = Pick<
	PermissionWrapperProps,
	'permissionCode' | 'permissionsCheckMode' | 'message' | 'tooltip'
> &
	Omit<ButtonProps, 'tooltip'>;

const PermissionButton: React.FC<PermissionButtonProps> = ({
	permissionCode,
	permissionsCheckMode,
	message,
	tooltip,
	disabled,
	children,
	...buttonProps
}) => (
	<PermissionWrapper
		permissionCode={permissionCode}
		permissionsCheckMode={permissionsCheckMode}
		message={message}
		tooltip={tooltip}
	>
		{hasPermission => (
			<Button disabled={!hasPermission || Boolean(disabled)} {...buttonProps}>
				{children}
			</Button>
		)}
	</PermissionWrapper>
);

export default PermissionButton;
