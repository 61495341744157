import React, { FC, useCallback, useRef, useState } from 'react';
import { Flex } from 'components';
import { Button } from 'components/antd';
import { Justify } from 'components/types';
import { TooltipProps } from 'antd/lib/tooltip';
import { copyTextToClipboard } from './CopyToClipboardWrapper.func';
import classNames from 'classnames';
import styles from './CopyToClipboardWrapper.module.scss';

interface CopyToClipboardWrapperProps {
	copyContent?: string;
	floatingButton?: boolean;
	tooltip?: Partial<TooltipProps>;
	buttonTitle?: string;
	className?: string;
	buttonClassName?: string;
	initialTooltipTitle?: string;
}

const CopyToClipboardWrapper: FC<CopyToClipboardWrapperProps> = ({
	copyContent,
	floatingButton,
	tooltip,
	buttonTitle = 'Copy to clipboard',
	className,
	buttonClassName,
	initialTooltipTitle,
	children
}) => {
	const [copied, setCopied] = useState(false);
	const contentContainer = useRef<HTMLDivElement | null>(null);

	const copyToClipboard = useCallback(async () => {
		if (!copyContent && (!contentContainer || !contentContainer.current)) {
			return;
		}
		setCopied(true);
		await copyTextToClipboard(
			copyContent || contentContainer?.current?.innerText || ''
		);
	}, [copyContent]);

	const onVisibleChange = useCallback(
		(visible: boolean) => {
			if (!visible && copied) {
				setCopied(false);
			}
		},
		[copied]
	);

	const tooltipProps: TooltipProps = {
		arrowPointAtCenter: true,
		title: copied ? 'Copied' : initialTooltipTitle,
		onVisibleChange,
		...tooltip
	};

	return (
		<Flex
			align="center"
			justify={Justify.START}
			className={classNames(
				{
					[styles.withFloatingButton]: floatingButton
				},
				className
			)}
		>
			{children && <div ref={contentContainer}>{children}</div>}
			<Button
				ghost
				type="primary"
				icon="copy"
				iconSize="lg"
				tooltip={tooltipProps}
				transparent={floatingButton}
				onClick={copyToClipboard}
				className={classNames(styles.copyButton, buttonClassName)}
			>
				{!floatingButton ? buttonTitle : null}
			</Button>
		</Flex>
	);
};

export default CopyToClipboardWrapper;
