import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveCompanyVesselTypesAsync,
	createCompanyVesselTypeAsync,
	updateCompanyVesselTypeAsync,
	deleteCompanyVesselTypeAsync
} from './actions';
import {
	onRetrieveCompanyVesselTypes,
	onCreateCompanyVesselType,
	onUpdateCompanyVesselType,
	onDeleteCompanyVesselType
} from './reducers';

import { initialState } from './companyVesselTypesState';

export default reducerWithInitialState(initialState)
	.case(
		retrieveCompanyVesselTypesAsync.started,
		onRetrieveCompanyVesselTypes.started
	)
	.case(retrieveCompanyVesselTypesAsync.done, onRetrieveCompanyVesselTypes.done)
	.case(
		retrieveCompanyVesselTypesAsync.failed,
		onRetrieveCompanyVesselTypes.failed
	)
	.case(createCompanyVesselTypeAsync.started, onCreateCompanyVesselType.started)
	.case(createCompanyVesselTypeAsync.done, onCreateCompanyVesselType.done)
	.case(createCompanyVesselTypeAsync.failed, onCreateCompanyVesselType.failed)
	.case(updateCompanyVesselTypeAsync.started, onUpdateCompanyVesselType.started)
	.case(updateCompanyVesselTypeAsync.done, onUpdateCompanyVesselType.done)
	.case(updateCompanyVesselTypeAsync.failed, onUpdateCompanyVesselType.failed)
	.case(deleteCompanyVesselTypeAsync.started, onDeleteCompanyVesselType.started)
	.case(deleteCompanyVesselTypeAsync.done, onDeleteCompanyVesselType.done)
	.case(deleteCompanyVesselTypeAsync.failed, onDeleteCompanyVesselType.failed);
