import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { BankAccountsState } from '../bankAccountsState';
import { DownloadLOBDRequest as Request } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { RetrieveDownloadDocumentResponse as Response } from 'services/api/documents/documentsServiceTypes';

export const onDownloadLOBD = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'downloadLOBD');
