import { AppState } from 'store-types';
import numeral from 'numeral';
import {
	FormData,
	FormDataDetails,
	NominationOption
} from 'sections/Administration/Configuration/GeneralDetails/GeneralDetailsTypes';
import {
	CompanyGeneralConfigurationDetails,
	ApprovalSettings,
	CommonSettings
} from 'services/api/companies/companiesServiceTypes';

import {
	getConfigurationGeneralDetailsFormValues,
	getActiveCompanyId
} from 'store/configuration/selectors';

const prepareEmailSettings = (
	values: FormDataDetails['emailSettings']
): CompanyGeneralConfigurationDetails['emailSettings'] => {
	return {
		centralInboxEmail: {
			email: values.centralInboxEmail.email,
			group: values.centralInboxEmail.group,
			isEnabled: values.centralInboxEmail.isEnabled,
			isSplitThreadByGroup: values.centralInboxEmail.isSplitThreadByGroup
		},
		secondaryMailboxes: (values.secondaryMailboxes || []).map(value => ({
			email: value.email,
			group: value.group,
			isEnabled: value.isEnabled
		}))
	};
};

const prepareApprovalSettings = (
	approvalSettings: ApprovalSettings
): ApprovalSettings => {
	const optionalBypassTotalAmount = approvalSettings.isBypassApprovalRequired &&
		approvalSettings.bypassTotalAmount && {
			bypassTotalAmount: numeral(approvalSettings.bypassTotalAmount).value()
		};

	if (!approvalSettings.isApprovalRequired) {
		return {
			isApprovalRequired: false,
			isMultilevelApprovalRequired: false,
			levels: [],
			isBypassApprovalRequired: false,
			isBypassPrincipalReApprovalRequired: false,
			isMandateApprovalRequired: false,
			bypassTotalAmount: undefined
		};
	} else if (!approvalSettings.isMultilevelApprovalRequired) {
		return {
			isApprovalRequired: true,
			isMultilevelApprovalRequired: false,
			levels: [],
			isBypassApprovalRequired: approvalSettings.isBypassApprovalRequired,
			isBypassPrincipalReApprovalRequired:
				approvalSettings.isBypassPrincipalReApprovalRequired,
			isMandateApprovalRequired: approvalSettings.isMandateApprovalRequired,
			...optionalBypassTotalAmount
		};
	}
	return {
		isApprovalRequired: true,
		isMultilevelApprovalRequired: true,
		levels: (approvalSettings.levels || []).map(level =>
			level?.thresholdValue
				? { ...level, thresholdValue: numeral(level.thresholdValue).value() }
				: level
		),
		isBypassApprovalRequired: approvalSettings.isBypassApprovalRequired,
		isBypassPrincipalReApprovalRequired:
			approvalSettings.isBypassPrincipalReApprovalRequired,
		isMandateApprovalRequired: approvalSettings.isMandateApprovalRequired,
		...optionalBypassTotalAmount
	};
};

const prepareCommonSettings = (
	commonSettings: FormDataDetails['commonSettings']
): CompanyGeneralConfigurationDetails['commonSettings'] => ({
	...commonSettings,
	pdaApprovalSettings: prepareApprovalSettings(
		commonSettings.pdaApprovalSettings
	),
	ceApprovalSettings: prepareApprovalSettings(
		commonSettings.ceApprovalSettings
	),
	daApprovalSettings: prepareApprovalSettings(
		commonSettings.daApprovalSettings
	),
	nominationOption: prepareNominationOption(commonSettings),
	isAppointmentFinanceDisabled: commonSettings.appointmentIsUsed
		? commonSettings.isAppointmentFinanceDisabled
		: false,
	isNominationFinanceDisabled: commonSettings.nominationIsUsed
		? commonSettings.isNominationFinanceDisabled
		: true,
	isChartererOversightFinanceDisabled: commonSettings.charterersOversightIsUsed
		? commonSettings.isChartererOversightFinanceDisabled
		: true,
	isAppointmentOperationsDisabled: commonSettings.appointmentIsUsed
		? commonSettings.isAppointmentOperationsDisabled
		: false,
	isNominationOperationsDisabled: commonSettings.nominationIsUsed
		? commonSettings.isNominationOperationsDisabled
		: false,
	isChartererOversightOperationsDisabled: commonSettings.charterersOversightIsUsed
		? commonSettings.isChartererOversightOperationsDisabled
		: false
});

const prepareNominationOption = (
	commonSettings: FormDataDetails['commonSettings']
): CommonSettings['nominationOption'] => {
	if (commonSettings.nominationIsUsed && !commonSettings.nominationOption) {
		return NominationOption.WAIT;
	} else {
		return commonSettings.nominationOption;
	}
};

const preparePrincipalIntegrationSettings = (
	values: FormDataDetails['principalIntegrationSettings']
): CompanyGeneralConfigurationDetails['principalIntegrationSettings'] => {
	if (!values.isPrincipalIntegrationEnabled) {
		return {
			isPrincipalIntegrationEnabled: values.isPrincipalIntegrationEnabled
		};
	}
	return {
		isPrincipalIntegrationEnabled: values.isPrincipalIntegrationEnabled,
		integrationUserId: values.integrationUserId,
		generateToken: values.generateToken
	};
};

const prepareDetails = (
	values: FormData
): CompanyGeneralConfigurationDetails => {
	const {
		commonSettings,
		emailSettings,
		fundingSettings,
		sendCopyToOperatorsEmail,
		appointmentAcceptanceInstruction,
		nominationAcceptanceInstruction,
		issOperatorContacts,
		operationsMapping,
		tagMapping,
		reportsSettings,
		biDashboardConfiguration,
		principalIntegrationSettings
	} = values.details;
	return {
		commonSettings: prepareCommonSettings(commonSettings),
		emailSettings: prepareEmailSettings(emailSettings),
		fundingSettings,
		sendCopyToOperatorsEmail,
		appointmentAcceptanceInstruction,
		nominationAcceptanceInstruction,
		issOperatorContacts,
		operationsMapping,
		tagMapping,
		reportsSettings,
		biDashboardConfiguration,
		principalIntegrationSettings: preparePrincipalIntegrationSettings(
			principalIntegrationSettings
		)
	};
};

const prepareRequest = (state: AppState) => {
	const companyId = getActiveCompanyId(state);
	const values = getConfigurationGeneralDetailsFormValues(state);
	return {
		companyId,
		isReadyToGo: values.isReadyToGo,
		details: prepareDetails(values)
	};
};

export default prepareRequest;
