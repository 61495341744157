import actionCreatorFactory from 'typescript-fsa';
import { ContextType } from 'services/api/threads/threadsServiceTypes';
import { UpdateContextParams } from 'services/api/messages/messagesServiceTypes';

const actionCreator = actionCreatorFactory();

const UPDATE_CONTEXT = 'thread/UPDATE_CONTEXT';

export const updateContextAsync = actionCreator.async<
	UpdateContextParams,
	ContextType | null,
	Error
>(UPDATE_CONTEXT, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateContext = actionCreator<UpdateContextParams>(
	updateContextAsync.type
);
