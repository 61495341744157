import actionCreator from '../portJobsActionCreator';
import {
	PortJobBaseRequest,
	PortJobAvailableGroup
} from 'services/api/portJobs/portJobsServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';

const ACTION_NAME = 'RETRIEVE_AVAILABLE_GROUPS';

export const retrieveAvailableGroups = actionCreator<PortJobBaseRequest>(
	ACTION_NAME
);

export const retrieveAvailableGroupsAsync = actionCreator.async<
	PortJobBaseRequest,
	ResultCollection<PortJobAvailableGroup>
>(ACTION_NAME);
