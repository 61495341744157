import { FetchStatus } from 'services/api/apiTypes';
import { Region } from 'services/api/regions/regionsServiceTypes';

export interface RegionsState {
	byCode: { [regionCode: string]: Region };
	fetchStatuses: {
		byCode: FetchStatus;
	};
}
export const initialState: RegionsState = {
	byCode: {},
	fetchStatuses: {
		byCode: FetchStatus.IDLE
	}
};
