import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SearchablePopover } from 'components';
import { Button } from 'components/antd';
import {
	openThreadJobSearch,
	closeThreadJobSearch,
	searchJobForThread,
	quickAssignJobToThread
} from 'store/thread/actions/quickJobAssignToThread';
import { isEmpty, debounce } from 'lodash';
import {
	getQuickJobAssignToThreadResults,
	getIsJobForThreadAssigmentLoading,
	getIsQuickSearchInIdle,
	getIsQuickAssignJobToThreadAvailable,
	PortCallJobSearchResultWithDisabled
} from 'store/thread/selectors';
import { LoadingType } from 'components/SearchablePopover/SearchablePopover';
import { AppState } from 'store-types';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';

interface QuickAssignToJobProps {
	isAssignJobButtonDisabled: boolean;
}

const QuickAssignToJob: FC<QuickAssignToJobProps> = ({
	isAssignJobButtonDisabled
}) => {
	const dispatch = useDispatch();
	const searchResults = useSelector<
		AppState,
		ReturnType<typeof getQuickJobAssignToThreadResults>
	>(getQuickJobAssignToThreadResults, shallowEqual);
	const isLoading = useSelector(getIsJobForThreadAssigmentLoading);
	const isQuickSearchInIdle = useSelector(getIsQuickSearchInIdle);
	const isQuickAssignJobToThreadAvailable = useSelector(
		getIsQuickAssignJobToThreadAvailable
	);

	const wasSearched = useMemo(
		() => !(isQuickSearchInIdle && isEmpty(searchResults)),
		[isQuickSearchInIdle, searchResults]
	);
	const isDisabled = useMemo(
		() => isAssignJobButtonDisabled || !isQuickAssignJobToThreadAvailable,
		[isAssignJobButtonDisabled, isQuickAssignJobToThreadAvailable]
	);

	const onChange = useCallback(
		debounce(
			(searchTerm: string) => dispatch(searchJobForThread(searchTerm)),
			DEFAULT_DEBOUNCE_DELAY
		),
		[dispatch]
	);

	const onSelect = useCallback(
		(job: PortCallJobSearchResultWithDisabled) => {
			dispatch(quickAssignJobToThread(job.code));
		},
		[dispatch]
	);

	const onVisibleChange = useCallback(
		(visible: boolean) => {
			if (visible) {
				dispatch(openThreadJobSearch());
			} else {
				dispatch(closeThreadJobSearch());
			}
		},
		[dispatch]
	);

	return (
		<SearchablePopover
			title="Quick Job Assignment"
			data={searchResults}
			loading={isLoading}
			showResults={wasSearched}
			loadingType={LoadingType.Input}
			disableIcon="check"
			onChange={onChange}
			onSelect={onSelect}
			onVisibleChange={onVisibleChange}
			placeholder="Type to search"
			listLocaleProps={{ emptyText: 'Not found' }}
		>
			<Button
				type="primary"
				transparent
				icon="search"
				size="small"
				disabled={isDisabled}
			>
				Search by Job ID
			</Button>
		</SearchablePopover>
	);
};

export default QuickAssignToJob;
