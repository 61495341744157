import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState } from '../portJobsState';
import {
	CreateThreadRequest as Request,
	CreateThreadResponse as Response
} from 'services/api/threads/threadsServiceTypes';
import { Success } from 'typescript-fsa';

const onSendExtendedMessagesSuccess = (
	state: PortJobState,
	{ params }: Success<Request, Response>
): PortJobState => ({
	...state,
	context: {
		...state.context,
		lastSentThreadId: params.threadId
	}
});

export const onSendExtendedMessages = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'sendExtendedMessages', {
	doneReducer: onSendExtendedMessagesSuccess
});
