import React, { FC } from 'react';
import AntButton from 'antd/lib/button';
import {
	ButtonProps as AntButtonProps,
	ButtonType as AntButtonType,
	NativeButtonProps,
	AnchorButtonProps
} from 'antd/lib/button/button';
import { IconSize } from 'components/antd/Icon/Icon';
import { IconType } from 'components/antd/Icon/IconTypes';
import classNames from 'classnames';
import styles from './Button.module.scss';
import { TooltipProps } from 'antd/lib/tooltip';
import { Tooltip, Icon } from 'components/antd';
import { ButtonType } from 'components/types';
import { Omit } from 'app-types';

/**
 * 'xsmall' is not included in the UI kit in the button category
 * but it's used for components like "<DropdownTable />"
 * @TODO Values should be updated to 'xs', 'sm', 'md', etc
 */
export type ButtonSize = 'xsmall' | AntButtonProps['size'] | 'xlarge';

type AntButtonFinalProps =
	| Omit<NativeButtonProps, 'type' | 'size' | 'onClick'>
	| Omit<AnchorButtonProps, 'type' | 'size' | 'onClick'>;

export type ButtonProps = AntButtonFinalProps & {
	type?: AntButtonType | ButtonType;
	title?: string | null;
	icon?: keyof IconType;
	iconSize?: IconSize;
	iconPos?: 'left' | 'right';
	size?: ButtonSize;
	disabled?: boolean;
	transparent?: boolean;
	tooltip?: TooltipProps;
	thick?: boolean;
	// Explicitly describe `onClick` since it wasn't corretly inferred resulting in having `any` type
	// Original element type is HTMLAnchorElement/HTMLButtonElement. Used generic type to avoid efforts over it
	onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Button: FC<ButtonProps> & {
	Group: typeof AntButton.Group;
} = props => {
	const {
		className,
		tooltip,
		transparent,
		thick,
		iconSize,
		size,
		type,
		icon,
		children,
		...restProps
	} = props;
	const buttonTypes = {
		[styles.transparent]: transparent,
		[styles.standard]: props.type === 'standard'
	};

	const buttonSizeClassNames = {
		[styles.buttonXSmall]: props.size === 'xsmall'
	};

	const isIconOnly = Boolean(icon) && !children;

	const buttonClassNames = {
		[styles.thick]: thick,
		[styles.disabled]: restProps.disabled,
		// Since icon is overriden manually,
		// we will leave antd class in order to maintain consistency
		'ant-btn-icon-only': isIconOnly,
		[styles.iconOnly]: isIconOnly
	};

	const buttonType = type !== 'standard' ? type : undefined;

	const iconPosClasses = {
		[styles.iconLeft]: props.iconPos === 'left',
		[styles.iconRight]: props.iconPos === 'right'
	};

	/**
	 * Pass `undefined` as value if prop.size is 'xsmall' or 'xlarge'
	 * since those values are not supported by <AntButton />
	 */
	const antButtonSize =
		size === 'xsmall' || size === 'xlarge' ? undefined : size;

	const button = (
		<AntButton
			size={antButtonSize}
			{...restProps}
			className={classNames(
				styles.root,
				buttonTypes,
				buttonClassNames,
				buttonSizeClassNames,
				iconPosClasses,
				className
			)}
			type={buttonType}
		>
			{icon || children ? (
				<>
					{icon && <Icon type={icon} size={iconSize} />}
					{children && <span>{children}</span>}
				</>
			) : null}
		</AntButton>
	);

	return tooltip ? (
		<Tooltip {...tooltip}>
			<span className={styles.inlineBlock}>{button}</span>
		</Tooltip>
	) : (
		button
	);
};

Button.defaultProps = AntButton.defaultProps;
Button.Group = AntButton.Group;

export default Button;
