import actionCreator from '../portCallOperationsActionCreator';
import { RestorePortCallEventRequest } from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'RESTORE_PORTCALL_EVENT';

type SetPortCallEventAsHappenedPayload = Pick<
	RestorePortCallEventRequest,
	'portCallId' | 'eventId'
>;

export const restorePortCallEventAsync = actionCreator.async<
	string,
	undefined,
	Error
>(ACTION_NAME, {
	failed: {
		description: 'Error occurred while event restoring.'
	},
	templateParams: {
		entityName: 'Event'
	}
});

export const restorePortCallEvent = actionCreator<string>(ACTION_NAME);

export const setPortCallEventAsHappened = actionCreator<
	SetPortCallEventAsHappenedPayload
>('SET_PORTCALL_EVENT_AS_HAPPENED');
