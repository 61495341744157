import { FinanceState } from '../financeState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';
import {
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse
} from 'services/api/finance/financeServiceTypes';

export const onRetrieveDAOutDocumentIdStarted = (
	state: FinanceState,
	{ daOutId }: RetrieveDAOutDocumentIdRequest
): FinanceState => ({
	...state,
	documentByDaOutId: {
		...state.documentByDaOutId,
		[daOutId]: {
			documentId: '',
			fetchStatus: FetchStatus.PENDING
		}
	}
});

export const onRetrieveDAOutDocumentIdSuccess = (
	state: FinanceState,
	{
		params,
		result
	}: Success<RetrieveDAOutDocumentIdRequest, RetrieveDAOutDocumentIdResponse>
): FinanceState => ({
	...state,
	documentByDaOutId: {
		...state.documentByDaOutId,
		[params.daOutId]: {
			documentId: result.documentId,
			fetchStatus: FetchStatus.SUCCESS
		}
	}
});

export const onRetrieveDAOutDocumentIdFailure = (
	state: FinanceState,
	{ params }: Failure<RetrieveDAOutDocumentIdRequest, Error>
): FinanceState => ({
	...state,
	documentByDaOutId: {
		...state.documentByDaOutId,
		[params.daOutId]: {
			documentId: '',
			fetchStatus: FetchStatus.FAILURE
		}
	}
});
