import { all, fork } from 'redux-saga/effects';
import addContactWatcher from './addContactSaga';
import updateContactWatcher from './updateContactSaga';
import retrieveContactWatcher from './retrieveContactSaga';
import retrieveMessageAttachmentWatcher from './retrieveMessageAttachmentSaga';
import retrieveMessagesMailboxesWatcher from './retrieveMessagesMailboxesSaga';
import uploadInlineImageDoneWatcher from './uploadInlineImageDoneSaga';
import retrieveMessageHistoryWatcher from './retrieveMessageHistorySaga';
import downloadEmlFileWatcher from './downloadEmlFileSaga';

export default function*() {
	yield all([
		fork(updateContactWatcher),
		fork(retrieveContactWatcher),
		fork(addContactWatcher),
		fork(retrieveMessageAttachmentWatcher),
		fork(retrieveMessagesMailboxesWatcher),
		fork(uploadInlineImageDoneWatcher),
		fork(retrieveMessageHistoryWatcher),
		fork(downloadEmlFileWatcher)
	]);
}
