import { PortJobState } from '../portJobsState';
import { PortJobSummaryResponse } from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrievePortJobSummaryStarted(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			summary: FetchStatus.PENDING
		}
	};
}
export function onRetrievePortJobSummarySuccess(
	state: PortJobState,
	action: {
		result: PortJobSummaryResponse;
	}
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			summary: FetchStatus.SUCCESS
		},
		context: {
			...state.context,
			summary: action.result
		}
	};
}
export function onRetrievePortJobSummaryFailed(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			summary: FetchStatus.FAILURE
		}
	};
}
export function onRetrievePortJobSummaryClean(
	state: PortJobState
): PortJobState {
	return {
		...state,
		context: {
			...state.context,
			summary: undefined
		},
		fetchStatuses: {
			...state.fetchStatuses,
			summary: FetchStatus.IDLE
		}
	};
}
