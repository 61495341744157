import { MessagesState } from '../messagesState';
import { resetCurrentContact } from '../actions';

function onResetCurrentContact(state: MessagesState): MessagesState {
	return {
		...state,
		currentContact: undefined
	};
}

export const resetCurrentContactAction = {
	action: resetCurrentContact,
	reducer: onResetCurrentContact
};
