import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { getIsLoading } from 'store/selectors';

/**
 * Active Supplier Invoice
 */
export const getActiveSupplierInvoiceId = ({ supplierInvoice }: AppState) =>
	supplierInvoice.context.activeSupplierInvoiceId;

export const getActiveSupplierInvoice = ({ supplierInvoice }: AppState) =>
	supplierInvoice.activeSupplierInvoice;

export const getActiveSupplierInvoiceStatus = ({ supplierInvoice }: AppState) =>
	supplierInvoice.fetchStatuses.active;

export const getActiveSupplierInvoiceDocument = ({
	supplierInvoice
}: AppState) => supplierInvoice.activeSupplierInvoiceDocument;

export const getActiveSupplierInvoiceDocumentFetchStatus = ({
	supplierInvoice
}: AppState) => supplierInvoice.fetchStatuses.activeDocument;

export const getDocumentFromActiveSupplierInvoice = createSelector(
	getActiveSupplierInvoice,
	supplierInvoice => {
		return supplierInvoice?.document;
	}
);

export const getDocumentIdFromActiveSupplierInvoice = createSelector(
	getDocumentFromActiveSupplierInvoice,
	document => {
		return document ? document.documentId : '';
	}
);

export const getIsLoadingActiveSupplierInvoice = createSelector(
	getActiveSupplierInvoiceStatus,
	status => getIsLoading(status)
);

export const getDownloadUrlFromActiveSupplierInvoice = createSelector(
	getActiveSupplierInvoice,
	supplierInvoice => {
		return supplierInvoice?.document.downloadUrl;
	}
);
