import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ServicesState } from '../servicesState';
import {
	UpdateServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';

const doneReducer = (
	state: ServicesState,
	{ result }: Success<UpdateServiceCategoryRequest, ServiceCategory>
): ServicesState => ({
	...state,
	serviceCategories: {
		...state.serviceCategories,
		[result.id]: result
	}
});

export const onUpdateServiceCategory = makeFetchStatusReducers<
	ServicesState,
	'fetchStatuses',
	UpdateServiceCategoryRequest,
	ServiceCategory
>('fetchStatuses', 'updateServiceCategory', {
	doneReducer
});
