import { PortCallStatus } from './portCallsServiceTypes';

// not contains PortCallStatus.CANCELLED, as it is out of order
export const portCallStatusOrdered = [
	PortCallStatus.EN_ROUTE,
	PortCallStatus.ARRIVED,
	PortCallStatus.ALONGSIDE,
	PortCallStatus.SAILED,
	PortCallStatus.CLOSED
];
