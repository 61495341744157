import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveMessageHistoryAsync as async } from '../actions';
import {
	RetrieveMessageHistoryRequest,
	RetrieveMessageHistoryResponse
} from 'services/api/messages/messagesServiceTypes';

const api = Api.Messages.retrieveThreadMessageHistoryById;
export default makeTakeLatestWatcher<
	RetrieveMessageHistoryRequest,
	RetrieveMessageHistoryResponse,
	Error
>({ api, async });
