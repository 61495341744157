import { TemplatesState } from '../templatesState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import { UpdateCompanyTemplatesRequest } from 'services/api/templates/templatesServiceTypes';

export const onUpdateCompanyTemplates = makeFetchStatusReducers<
	TemplatesState,
	'fetchStatuses',
	UpdateCompanyTemplatesRequest,
	null
>('fetchStatuses', 'updateCompanyTemplates');
