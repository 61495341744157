import React from 'react';
import { ButtonRow, PageFooter as Footer } from 'components';
import { Justify } from 'components/types';
import { Row, Col, Button } from 'components/antd';

interface PageFooterProps {
	onCancel: () => void;
	onSubmit: () => void;
}

const PageFooter: React.SFC<PageFooterProps> = ({ onCancel, onSubmit }) => (
	<Footer>
		<Row>
			<Col sm={12} justify={Justify.END}>
				<ButtonRow>
					<Button type="primary" transparent onClick={onCancel}>
						Cancel
					</Button>
					<Button type="primary" onClick={onSubmit}>
						Submit
					</Button>
				</ButtonRow>
			</Col>
		</Row>
	</Footer>
);

export default PageFooter;
