import { Success } from 'typescript-fsa';
import { FormPageData } from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import { OperationsContextualizationFormState } from '../operationsContextualizationReducer';
import { CopyFromPreviousPageRequest } from '../operationsContextualizationTypes';

export const onCopyFromPreviousPageStarted = (
	state: OperationsContextualizationFormState
): OperationsContextualizationFormState => {
	return state;
};

export const onCopyFromPreviousPageSuccess = (
	state: OperationsContextualizationFormState,
	action: Success<CopyFromPreviousPageRequest, FormPageData>
): OperationsContextualizationFormState => {
	const { activePageIndex } = action.params;
	const previousPageValues = action.result;

	if (!state.values) {
		return state;
	}

	const newPages = state.values.pages.map((pageValues, index) => {
		if (index === activePageIndex) {
			return previousPageValues;
		}
		return pageValues;
	});

	return {
		...state,
		values: {
			...state.values,
			pages: newPages
		}
	};
};

export const onCopyFromPreviousPageFailed = (
	state: OperationsContextualizationFormState
): OperationsContextualizationFormState => {
	return state;
};
