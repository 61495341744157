import { FetchStatus } from 'services/api/apiTypes';
import { ThreadReducerState } from '../threadState';
import { updateTags, updateTagsAsync } from '../actions';

function onUpdateTagsStarted(state: ThreadReducerState): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}

function onUpdateTagsSuccess(state: ThreadReducerState): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
}

function onUpdateTagsFailed(state: ThreadReducerState): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}

export const updateTagsAction = {
	action: updateTags,
	async: updateTagsAsync,
	started: onUpdateTagsStarted,
	success: onUpdateTagsSuccess,
	failed: onUpdateTagsFailed
};
