import { retrieveMailboxesForPrincipalAsync } from 'store/portJobs/actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse,
	Error
>({
	api: Api.Mailboxes.retrieveMailboxesForPrincipal,
	async: retrieveMailboxesForPrincipalAsync
});
