import { Action, Success } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, take, race, call, select } from 'redux-saga/effects';
import { reopenDaSet, reopenDaSetAsync } from '../actions/reopenDaSet';
import {
	openModal,
	confirmModal,
	cancelModal,
	closeModal
} from 'store/modals/actions';
import { REOPEN_DASET_MODAL_ID } from '../constants';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { ReopenDaRequest } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { retrieveFinance } from '../actions';

export const reopenApiCall = makeDefaultExecutor<
	ReopenDaRequest,
	undefined,
	Error
>({
	api: Api.Finance.reopenDaSet,
	async: reopenDaSetAsync,
	*onError(_params: ReopenDaRequest) {
		yield put(closeModal(REOPEN_DASET_MODAL_ID));
	},
	*onSuccess({
		params: { jobCode, portCallId }
	}: Success<ReopenDaRequest, undefined>) {
		yield put(
			retrieveFinance({
				portCallId,
				jobCode
			})
		);
		yield put(closeModal(REOPEN_DASET_MODAL_ID));
	}
});

export function* reopenDaSetExecutor({
	payload: daSetId
}: Action<string>): SagaIterator {
	yield put(openModal(REOPEN_DASET_MODAL_ID));

	const { cancel } = yield race({
		confirm: take(confirmModal.type),
		cancel: take(cancelModal.type)
	});

	if (cancel) {
		yield put(closeModal(REOPEN_DASET_MODAL_ID));
		return;
	}
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	yield call(reopenApiCall, {
		portCallId,
		jobCode,
		daSetId
	} as ReopenDaRequest);
}

export default function* reopenDaSetWatcher(): SagaIterator {
	yield takeLatest(reopenDaSet, reopenDaSetExecutor);
}
