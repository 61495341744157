import { Entity } from 'app-types';
import { ThreadReducerState } from '../../threadState';

export const onAddThreadPermissionsSuccess = (
	state: ThreadReducerState,
	action: Entity
): ThreadReducerState => {
	return {
		...state,
		newPermissions: [...state.newPermissions, action]
	};
};
