import React from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'lodash';
import styles from './DocumentViewer.module.scss';
import { wizardForm, Text } from 'components';
import { InjectedWizardFormProps } from 'components/WizardForm';

import {
	getDocumentPagesCount,
	getIsDocumentPDF
} from 'store/documents/selectors';
import { getContextualizationFormPagesWithoutContext } from 'store/portCallDocuments/selectors';

import DocumentViewer from './DocumentViewer';
import { PaginationState } from './DocumentViewerTypes';
import DocumentViewerCompletionModal from './DocumentViewerCompletionModal';

import { DOCUMENT_VIEWER_WIZARD_FORM_NAME } from './DocumentViewerWizardFormTypes';
import { BaseFileDocumentForViewer } from 'sections/DocumentBrowserPageViewer';
import { AppState } from 'store-types';

interface DocumentViewerWizardFormBaseProps {
	documentId: string;
	title: string;
	returnUrl: string;
	hint?: React.ReactNode;
	fileDocument?: BaseFileDocumentForViewer;
	/**
	 * Indicates that document is split (applies to PDF files)
	 */
	isDocumentPDF: boolean;
	pagination?: PaginationState;
	documentPagesCount: number | undefined;
	pagesWithoutContext: number[];
	loading: boolean;
	onSubmit: () => void;
	canSubmit: boolean;
	children: (helpers: DocumentViewerWizardFormHelpers) => React.ReactNode;
	preventSplit?: boolean;
}

interface DocumentViewerWizardFormProps
	extends DocumentViewerWizardFormBaseProps,
		InjectedWizardFormProps {}

interface DocumentViewerWizardFormHelpers
	extends DocumentViewerWizardFormProps {
	onValidChange: (valid: boolean) => void;
	onResetWizardForm: () => void;
}

interface DocumentViewerWizardFormState {
	visible: boolean;
}
/**
 * Entry to `Contextualization` Wizard Form
 *
 * Initializes pages state of the wizard form by `documentPagesCount`
 * and marks wizard form active page as valid/invalid when form state changes
 */
class DocumentViewerWizardForm extends React.Component<
	DocumentViewerWizardFormProps,
	DocumentViewerWizardFormState
> {
	constructor(props: DocumentViewerWizardFormProps) {
		super(props);
		this.state = {
			visible: false
		};
	}

	componentDidMount() {
		this.initializeWizardFormPagesState();
	}

	componentDidUpdate(prevProps: DocumentViewerWizardFormProps) {
		this.initializeWizardFormPagesState(prevProps);
	}

	initializeWizardFormPagesState = (
		prevProps?: DocumentViewerWizardFormProps
	) => {
		const { documentPagesCount } = this.props;

		if (!isNumber(documentPagesCount)) {
			return;
		}

		/**
		 * Document is already in state when user navigates to the page after uploading,
		 * which means we'll get pages count on `didMount`, but not the same for reloading the page
		 */
		if (!prevProps || prevProps.documentPagesCount !== documentPagesCount) {
			const pages =
				documentPagesCount === 0 ? documentPagesCount + 1 : documentPagesCount;
			this.props.updateState({
				pages
			});
		}
	};

	onValidChange = (valid: boolean) => {
		if (this.props.activePageState.valid === valid) {
			return;
		}

		this.props.setActivePageState({
			valid
		});
	};

	/**
	 * To reset the wizard form to the initial state
	 */
	onResetWizardForm = () => {
		this.initializeWizardFormPagesState();
	};

	toggleModalVisibility = () => {
		if (this.props.preventSplit) {
			return;
		}
		this.setState({
			visible: !this.state.visible
		});
	};

	onFinish = () => {
		if (
			!this.props.preventSplit &&
			this.props.pagesWithoutContext.length &&
			!this.state.visible
		) {
			this.toggleModalVisibility();
			return;
		}

		this.onFinishProceed();
	};

	/**
	 * Create Document when
	 * Clicks `Done` Button
	 */
	onFinishProceed = () => {
		if (this.state.visible) {
			this.toggleModalVisibility();
		}

		this.props.onSubmit();
	};

	onFinishRevert = () => {
		this.toggleModalVisibility();
	};

	/**
	 * Returns specific hint for contextualization
	 * if it's pdf or not
	 */
	getHint = () =>
		this.props.isDocumentPDF ? (
			<>
				Please allocate each page to a document
				<p>
					Please go page by page to allocate each page of this PDF to the
					appropriate item by selecting the document type and (if applicable)
					the scope. If you skip the page it will be ignored.
				</p>
			</>
		) : (
			<>
				Please allocate the file to a document
				<p>Please select the document type and scope</p>
			</>
		);

	// TODO: check props for total, activePage etc. as they are used in Finance/Contextualization
	getHelpers = (): DocumentViewerWizardFormHelpers => {
		return {
			...this.props,
			onValidChange: this.onValidChange,
			onResetWizardForm: this.onResetWizardForm
		};
	};

	render() {
		const {
			documentId,
			isDocumentPDF,
			pagesWithoutContext,
			canSubmit,
			hint = this.getHint(),
			...props
		} = this.props;

		return (
			<DocumentViewer
				{...props}
				documentId={documentId}
				onFinish={this.onFinish}
				hint={hint}
				canSubmit={canSubmit}
			>
				{!this.props.preventSplit && isDocumentPDF && (
					<Text weight="semi-bold" className={styles.asideTitle}>
						Process Page {props.activePage} - What does the page shown on the
						left present?
					</Text>
				)}

				{this.props.children(this.getHelpers())}

				{this.state.visible && (
					<DocumentViewerCompletionModal
						pages={pagesWithoutContext}
						onOk={this.onFinish}
						onCancel={this.onFinishRevert}
					/>
				)}
			</DocumentViewer>
		);
	}
}

const WithWizard = wizardForm<DocumentViewerWizardFormBaseProps>({
	name: DOCUMENT_VIEWER_WIZARD_FORM_NAME,
	mode: 'create'
})(DocumentViewerWizardForm);

export default connect(
	(
		state: AppState,
		ownProps: Pick<DocumentViewerWizardFormProps, 'documentId'>
	) => ({
		isDocumentPDF: getIsDocumentPDF(state, ownProps.documentId),
		documentPagesCount: getDocumentPagesCount(state, ownProps.documentId),
		pagesWithoutContext: getContextualizationFormPagesWithoutContext(state)
	})
)(WithWizard);
