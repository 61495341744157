import * as React from 'react';
import classNames from 'classnames';
import Card from 'components/antd/Card/Card';
import styles from './InfoBlock.module.scss';

interface InfoBlockProps {
	className?: string;
}

class InfoBlock extends React.Component<InfoBlockProps> {
	render() {
		return (
			<Card className={classNames(styles.root, this.props.className)}>
				{this.props.children}
			</Card>
		);
	}
}

export default InfoBlock;
