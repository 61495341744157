import { call } from 'redux-saga/effects';
import { DeleteFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteFinanceDocumentAsync as async } from '../actions';
import Api from 'services/api';
import { getActivePortCallIdExecutor } from 'utils/sagaHelpers/portCallSagaExecutor';

export default makeTakeLatestWatcher<
	DeleteFinanceDocumentRequest,
	number, // status
	Error
>({
	async,
	api: Api.Finance.deleteFinanceDocument,
	*getApiParams(params: DeleteFinanceDocumentRequest) {
		const portCallId: string = yield call(getActivePortCallIdExecutor, params);
		return {
			portCallId,
			...params
		};
	}
});
