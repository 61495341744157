import React from 'react';
import { Align, HeroPanel, Link } from 'components';
import { navigateTo } from 'utils';
import AppLogoLayout from 'sections/AppLayout/AppLogoLayout/AppLogoLayout';
import { connect } from 'react-redux';
import { getWizardExist } from 'store/wizardForm/wizardFormSelectors';
import { USER_REGISTRATION_FORM_NAME } from 'store/registration/settings';
import { AppState } from 'store-types';

interface RegistrationFinishProps {
	registrationWizardExist: boolean;
}
class RegistrationFinish extends React.PureComponent<RegistrationFinishProps> {
	componentDidMount() {
		if (!this.props.registrationWizardExist) {
			navigateTo('/');
		}
	}

	render() {
		return (
			<AppLogoLayout>
				<HeroPanel type="success">
					<Align align="center">
						<HeroPanel.Title>Congratulations</HeroPanel.Title>
						You were successfully registered in Project Optic.
						<br />
						<Link href="/login">Back to Login</Link>
					</Align>
				</HeroPanel>
			</AppLogoLayout>
		);
	}
}

export default connect((state: AppState) => ({
	registrationWizardExist: getWizardExist(state, USER_REGISTRATION_FORM_NAME)
}))(RegistrationFinish);
