import { getUserType, getUserGroupType } from 'store/auth/selectors';
import { createSelector } from 'reselect';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';

export const getIsHubPrincipalFieldVisible = createSelector(
	getUserType,
	getUserGroupType,
	(userType, userGroupType): boolean => {
		return (
			userType === UserType.HUB && userGroupType === GroupTypeCodes.ISS_HUB
		);
	}
);
