import actionCreator from '../portCallMeasurementsActionCreator';
import {
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'RETRIEVE_MEASUREMENTS_ACCESS';
export const retrievePortCallMeasurementsAccessAsync = actionCreator.async<
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess[],
	Error
>(ACTION_NAME);

export const retrievePortCallMeasurementsAccess = actionCreator<
	RetrievePortCallMeasurementsAccessRequest
>(ACTION_NAME);
