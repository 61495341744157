import { DeleteGroupRequest } from 'services/api/groups/groupsServiceTypes';
import { GroupsState } from '../groupsState';
import { omit } from 'lodash';

import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function doneReducer(
	state: GroupsState,
	{ params: { id } }: Success<DeleteGroupRequest, null>
): GroupsState {
	return {
		...state,
		context: {
			...state.context,
			openedGroup: null
		},
		byId: omit(state.byId, id)
	};
}

export const onDeleteGroup = makeFetchStatusReducers<
	GroupsState,
	'fetchStatuses',
	DeleteGroupRequest,
	null
>('fetchStatuses', 'deleting', {
	doneReducer
});
