import actionCreator from '../portJobsActionCreator';
import {
	EditPortJobOperationResponse,
	EditOperationActionParams
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'EDIT_OPERATION';
export const editPortJobOperationAsync = actionCreator.async<
	EditOperationActionParams,
	EditPortJobOperationResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Operation'
	}
});

export const editPortJobOperation = actionCreator<EditOperationActionParams>(
	ACTION_NAME
);
