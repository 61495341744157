import actionCreatorFactory from 'typescript-fsa';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';

const actionCreator = actionCreatorFactory();

const SET_ACTIVE_PORTCALL_TAB = 'portcalls/SET_ACTIVE_PORTCALL_TAB';
export const setActivePortCallTab = actionCreator<PortCallTabKey>(
	SET_ACTIVE_PORTCALL_TAB
);

const RESET_ACTIVE_PORTCALL_TAB = 'portcalls/RESET_ACTIVE_PORTCALL_TAB';
export const resetActivePortCallTab = actionCreator<PortCallTabKey>(
	RESET_ACTIVE_PORTCALL_TAB
);
