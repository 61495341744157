import { Success } from 'typescript-fsa';
import { DirectBillsState } from '../directBillsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusResponse
} from 'services/api/directBills/directBillsServiceTypes';

const onUpdateDirectBillStatusSuccess = (
	state: DirectBillsState,
	{
		result
	}: Success<UpdateDirectBillStatusRequest, UpdateDirectBillStatusResponse>
): DirectBillsState => {
	return {
		...state,
		context: {
			...state.context,
			directBill: {
				...result
			}
		}
	};
};

export const onUpdateDirectBillStatus = makeFetchStatusReducers<
	DirectBillsState,
	'fetchStatuses',
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusResponse
>('fetchStatuses', 'update', {
	doneReducer: onUpdateDirectBillStatusSuccess
});
