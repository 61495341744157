import { AxiosTypedResponse } from 'services/api/apiTypes';
import { forEach, isUndefined } from 'lodash';
import { select, call, put, takeLatest, take } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	retrievePortCalls,
	retrievePortCallsAsync
} from '../actions/retrievePortCalls';
import {
	getPortCallsCount,
	getPortCallsFilters,
	getQueriedStatusParamsForPortCallsFilter
} from '../portCallsSelectors';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { Action } from 'typescript-fsa';
import Api from 'services/api';
import { FilterItem } from 'store/filters/filtersState';
import { PortCallsFiltersParamEnum } from '../filtersSync';
import { PortJobStatus } from 'store/portJobs/constants';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { statusesTypeMap } from 'store/portcalls/portCallsConstants';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import {
	retrievePortCallsAlerts,
	retrievePortCallsAlertsAsync
} from '../actions';
import { PortJobStatusLabel } from 'services/api/portJobs/portJobsServiceTypes';
const {
	SEARCH,
	DATE,
	JOB_STATUS,
	SHOW_CANCELLED,
	ASSIGNED_TO,
	ASSIGNED_TO_ID
} = PortCallsFiltersParamEnum;

interface QueriedStatusParams {
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
	financeStatus?: FinanceStatus[];
	includeCancelled?: boolean;
}

export function* retrievePortCallsWorker(
	action: Action<RetrievePortCallsRequest | undefined>
): SagaIterator {
	const count = yield select(getPortCallsCount);
	const filters = yield select(getPortCallsFilters);

	let queriedStatusParams: QueriedStatusParams = {};
	if (filters?.jobStatus) {
		queriedStatusParams = yield select(
			getQueriedStatusParamsForPortCallsFilter,
			filters.jobStatus[0].key
		);
	}

	const request: RetrievePortCallsRequest = {
		limit: DEFAULT_LIST_LIMIT,
		index: count,
		...action.payload
	};

	forEach(filters, (filter: FilterItem[], name: string) => {
		if (name === SEARCH) {
			request.ids = filter.reduce<string[]>((ids, current) => {
				if (current.type.toLowerCase() === SEARCH) {
					request.searchTerm = current.label;
					return [];
				}
				ids.push(current.key);
				return ids;
			}, []);
			return;
		}
		if (name === SHOW_CANCELLED) {
			request.includeCancelled = true;
			return;
		}
		if (name === ASSIGNED_TO || name === ASSIGNED_TO_ID) {
			request[name] = filter.map(value => value.key);
			return;
		}
		if (name === DATE) {
			const [dateType, fromDate, toDate] = filter[0].key.split(' ');
			request[`${dateType}From`] = fromDate;
			request[`${dateType}To`] = toDate;
			return;
		}
		if (name === JOB_STATUS) {
			const status: {
				financeStatus?: FinanceStatus;
				jobStatus: PortJobStatus;
			} = statusesTypeMap[filters.jobStatus[0].key.toLowerCase()];
			// data from filters on grid and dashboard differs only in letter case
			if (status?.financeStatus) {
				request.financeStatus = [status.financeStatus];
				if (queriedStatusParams.financeStatus) {
					request.financeStatus = [
						...request.financeStatus,
						...queriedStatusParams.financeStatus
					];
				}
			}
			if (status && !isUndefined(queriedStatusParams.queriedToHub)) {
				request.queriedToHub = queriedStatusParams.queriedToHub;
			}
			if (status && !isUndefined(queriedStatusParams.queriedToLPA)) {
				request.queriedToLPA = queriedStatusParams.queriedToLPA;
			}

			// Set flag for Awaiting Hub Review includes JobStatus either Confirmed or PDARequested in the backend
			if (filters.jobStatus[0].key === PortJobStatusLabel.AWAITING_HUB_REVIEW) {
				request.awaitingHubReview = true;
			}

			request.jobStatus = status?.jobStatus;
		} else {
			request[name] = filter[0].key;
		}
	});

	yield put(retrievePortCallsAsync.started(request));

	try {
		const response: AxiosTypedResponse<RetrievePortCallsResponse> = yield call(
			Api.PortCalls.retrievePortCalls,
			request
		);

		const portCallIds = response.data.elements.map(portCall => portCall.id);
		if (portCallIds.length) {
			yield put(retrievePortCallsAlerts({ portCallIds }));
			const { payload } = yield take([
				retrievePortCallsAlertsAsync.done,
				retrievePortCallsAlertsAsync.failed
			]);
			if (payload.error) {
				throw new Error('PortCalls alerts were not retrieved');
			}
		}
		yield put(
			retrievePortCallsAsync.done({
				result: response.data,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			retrievePortCallsAsync.failed({
				error,
				params: request
			})
		);
	}
}

export function* retrievePortCallsWatcher(): SagaIterator {
	yield takeLatest(retrievePortCalls.type, retrievePortCallsWorker);
}
