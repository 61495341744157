import actionCreator from '../developerPortalActionCreator';
import {
	DownloadApiSubscriptionRequest as Request,
	DownloadApiSubscriptionResponse as Response
} from 'services/api/developerPortal/developerPortalServiceTypes';

const DOWNLOAD_API_SUBSCRIPTION = 'DOWNLOAD_API_SUBSCRIPTION';

export const downloadApiSubscriptionAsync = actionCreator.async<
	Request,
	Response,
	Error
>(DOWNLOAD_API_SUBSCRIPTION);

export const downloadApiSubscription = actionCreator<Request>(
	DOWNLOAD_API_SUBSCRIPTION
);
