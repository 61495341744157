import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	RetrievePortCallSectionsRequest,
	RetrievePortCallSectionsResponse
} from 'services/api/portCall/portCallServiceTypes';
import { retrievePortCallSectionsAsync } from '../actions';

const apiCall = Api.PortCall.retrievePortCallSections;

export default makeTakeLatestWatcher<
	RetrievePortCallSectionsRequest,
	RetrievePortCallSectionsResponse,
	Error
>({
	api: apiCall,
	async: retrievePortCallSectionsAsync
});
