import { DownloadFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';

import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from 'store/finance/financeState';

export const onDownloadJobFinanceDocument = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DownloadFinanceDocumentRequest,
	null
>(
	'fetchStatuses',
	(
		params: DownloadFinanceDocumentRequest
	): keyof FinanceState['fetchStatuses'] => {
		const id = params.documentId;
		return `downloadDocumentById.${id}` as keyof FinanceState['fetchStatuses'];
	}
);
