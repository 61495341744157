import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	AddServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import Api from 'services/api';
import { addServiceCategoryAsync as async } from '../actions';

export default makeTakeLatestWatcher<
	AddServiceCategoryRequest,
	ServiceCategory,
	Error
>({
	api: Api.Services.addServiceCategory,
	async
});
