import * as React from 'react';
import styles from './SubHeader.module.scss';
import PageHeader from 'components/PageHeader/PageHeader';

interface SubHeaderProps {
	title: string;
}

const SubHeader: React.SFC<SubHeaderProps> = ({ title, children }) => (
	<div className={styles.root}>
		{children}
		<PageHeader justified stretch className={styles.divider}>
			<h2>{title}</h2>
		</PageHeader>
	</div>
);

export default SubHeader;
