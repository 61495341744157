import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';
import { isExternalLink } from 'utils/functional';
import { capitalize } from 'lodash';
import classnames from 'classnames';
import styles from './Link.module.scss';
import { Loading } from 'components';

type LinkType = 'standard' | 'black' | 'default';

// eslint-disable:no-any
interface LinkProps {
	/** String or object representation of the location */
	href?: string | LocationDescriptorObject;
	/** Link type (stylistic) */
	type?: LinkType;
	/** Defines how the link target relates to the current web page */
	rel?: string;
	/** Defines in which tab or window the clicked link will show up */
	target?: string;
	/** Tooltip options */
	tooltip?: TooltipProps;
	/** Set link inactive */
	disabled?: boolean;
	/** Download file when clicking on the link (instead of navigating to the file) */
	download?: boolean;
	/** Disables native redirect and makes possible custom actions */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick?: React.FormEventHandler<any>;
	/** Toggles a spinner wheel while true */
	loading?: boolean;
	/** Set's the font-weight */
	weight?: 'regular' | 'bold';
	className?: string;
	style?: React.CSSProperties;
}
// eslint-enable:no-any

export const Link: React.FC<LinkProps> = ({
	href,
	type = 'standard',
	tooltip,
	disabled,
	className = '',
	loading,
	weight,
	children,
	...props
}) => {
	const classNames = classnames(className, styles[type], {
		[styles.disabled]: disabled,
		[styles[`fontWeight${capitalize(weight)}`]]: Boolean(weight)
	});

	const content = (
		<>
			{loading && <Loading show={loading} size={'xxs'} inline />}
			{children}
		</>
	);
	const innerContent = tooltip ? (
		<MaybeTooltip show {...tooltip}>
			<span>{content}</span>
		</MaybeTooltip>
	) : (
		content
	);

	if (!href || disabled || props.onClick) {
		return (
			/* eslint-disable jsx-a11y/anchor-is-valid */
			<a
				onClick={event => {
					event.preventDefault();
					if (props.onClick && !disabled) {
						props.onClick(event);
					}
				}}
				className={classNames}
				style={props.style}
			>
				{innerContent}
			</a>
			/* eslint-enable */
		);
	}
	const isMailtoLink = String(href).includes('mailto:');
	if (typeof href === 'string' && (isExternalLink(href) || isMailtoLink)) {
		const {
			rel = 'noopener noreferrer',
			target = '_blank',
			...otherProps
		} = props;
		const externalProps = !isMailtoLink ? { rel, target } : {};
		return (
			<a href={href} className={classNames} {...externalProps} {...otherProps}>
				{innerContent}
			</a>
		);
	}
	return (
		<ReactRouterLink to={href} className={classNames} {...props}>
			{innerContent}
		</ReactRouterLink>
	);
};

export default Link;
