import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { AddSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { SupplierInvoiceState } from '../supplierInvoiceState';

export const createSupplierInvoice = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	AddSupplierInvoiceRequest,
	null
>('fetchStatuses', 'create');
