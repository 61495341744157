import { Success } from 'typescript-fsa';
import { TemplatesState } from '../templatesState';
import { FetchStatus } from '../../../services/api/apiTypes';
import {
	RetrieveTemplateRenderRequest,
	TemplateRendered
} from '../../../services/api/templates/templateServiceTypes';

export function onRetrieveTemplateRenderStarted(
	state: TemplatesState
): TemplatesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplates: FetchStatus.PENDING
		}
	};
}

export function onRetrieveTemplateRenderSuccess(
	state: TemplatesState,
	action: Success<RetrieveTemplateRenderRequest, TemplateRendered>
): TemplatesState {
	const { result } = action;

	return {
		...state,
		render: result,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplates: FetchStatus.SUCCESS
		}
	};
}
