import React from 'react';
import styles from './Content.module.scss';
import classNames from 'classnames';
import { Size } from 'components/types';

/**
 * Content Component
 * Used to create gutter for rows inside
 */
export interface ContentProps {
	className?: string;
	size?: Size;
	top?: boolean; // don't apply default top padding
	fullHeight?: boolean; // don't apply default top padding
}
const Content: React.FC<ContentProps> = ({
	className,
	size = 'sm',
	top = true,
	fullHeight = true,
	children
}) => {
	return (
		<div
			className={classNames(styles.root, styles[size], className, {
				[styles.noTop]: !top,
				[styles.fullHeight]: fullHeight
			})}
		>
			{children}
		</div>
	);
};

export default Content;
