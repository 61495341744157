import {
	uploadCompanyPreferredAgentsFileAsync as async,
	UploadCompanyPreferredAgentsFileActionRequest
} from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { UploadCompanyPreferredAgentsFileRequest } from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	UploadCompanyPreferredAgentsFileActionRequest,
	null,
	Error,
	UploadCompanyPreferredAgentsFileRequest
>({
	async,
	api: Api.Companies.uploadCompanyPreferredAgentsFile,
	*getApiParams(params: UploadCompanyPreferredAgentsFileActionRequest) {
		return {
			companyId: params.companyId,
			fileName: params.document.name,
			documentId: params.document.id
		};
	}
});
