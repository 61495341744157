import { call, fork, take, put, cancel, delay } from 'redux-saga/effects';
import {
	retrieveJobSectionsCycleStart,
	retrieveJobSections,
	retrieveJobSectionsCycleStop,
	retrieveActiveSection,
	retrieveJobSectionsAsync
} from '../actions';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
import Matomo from 'services/analytics/adapters/Matomo';

export function* worker() {
	// wait for initial requests to be finished
	yield take([retrieveJobSectionsAsync.done, retrieveJobSectionsAsync.failed]);
	// set polling actions to fallback to notification
	yield put(
		setNotificationActionTypeToNotification([
			retrieveJobSections.type,
			retrieveActiveSection.type
		])
	);
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL / 3);
		yield put(retrieveJobSections());
		yield put(retrieveActiveSection());
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Dashboard.JobsPolling',
			name: `Jobs polling triggered`,
			value: 1
		});
	}
}

export default function*() {
	while (yield take(retrieveJobSectionsCycleStart)) {
		const pollJobSectionsTask = yield fork(worker);
		yield take(retrieveJobSectionsCycleStop);
		yield cancel(pollJobSectionsTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrieveJobSections.type,
				retrieveActiveSection.type
			])
		);
	}
}
