import Api from 'services/api';
import { select, call, put, takeLatest, fork, all } from 'redux-saga/effects';
import {
	EditPortJobOperationResponse,
	EditOperationActionParams,
	AddPortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	getEditableOperation,
	getIsUnlinkNotificationShow
} from 'store/portJobs/portJobsSelectors';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { navigateTo } from 'utils';

import {
	editPortJobOperationAsync,
	setPortJobContext
} from 'store/portJobs/actions';
import { Action } from 'typescript-fsa';
import {
	getAddPortJobOperationFormData,
	getOperationIdFromAddOperationFormData
} from '../selectors';
import { getOperationById } from 'store/operations/operationsSelectors';
import {
	prepareOperationRequest,
	populatePortJobCompanies
} from 'store/portcalls/sagas/addPortCall/prepareRequest';
import { getAllCompanyDraftsByType } from 'store/drafts/selectors';
import { DraftType } from 'store/drafts/draftsState';
import { notify } from 'store/notifications/actions';

const apiCall = Api.PortJobs.editOperationInPortJob;

function* editPortJobOperationExecutor(
	actionParams: EditOperationActionParams,
	payload: AddPortJobOperationTypes,
	api: typeof apiCall
): SagaIterator {
	yield put(editPortJobOperationAsync.started(actionParams));
	const isNotificationShow = yield select(getIsUnlinkNotificationShow);
	try {
		const response: AxiosResponse<EditPortJobOperationResponse> = yield call(
			api,
			{
				portCallId: actionParams.portCallId,
				jobCode: actionParams.jobCode,
				operationId: actionParams.operationId,
				payload
			}
		);
		yield put(
			editPortJobOperationAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);

		yield call(
			navigateTo,
			`/portcalls/${actionParams.portCallId}/jobs/${actionParams.jobCode}/overview`
		);
		if (isNotificationShow) {
			yield put(notify.success('Jobs have been unlinked successfully.'));
			yield put(setPortJobContext({ isUnlinkNotificationToShow: false }));
		}
	} catch (error) {
		yield put(
			editPortJobOperationAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* editPortJobOperation(
	action: Action<EditOperationActionParams>
): SagaIterator {
	try {
		const operationId = yield select(getOperationIdFromAddOperationFormData);
		const formData = yield select(getAddPortJobOperationFormData);

		const operation = yield select(getOperationById, operationId);
		const { concurrencyToken } = yield select(getEditableOperation);
		const chartererDrafts = yield select(
			getAllCompanyDraftsByType,
			DraftType.CHARTERER
		);
		const shipperReceiverDrafts = yield select(
			getAllCompanyDraftsByType,
			DraftType.SHIPPER_RECEIVER
		);
		const portJobCompanies = chartererDrafts.concat(shipperReceiverDrafts);
		let preparedRequest = yield call(
			prepareOperationRequest,
			operation,
			formData
		);
		if (
			[
				OperationTypeCode.LOADING,
				OperationTypeCode.LOADING_STS,
				OperationTypeCode.DISCHARGING,
				OperationTypeCode.DISCHARGING_STS
			].includes(operation.operationTypeCode)
		) {
			preparedRequest = yield call(
				populatePortJobCompanies,
				preparedRequest,
				portJobCompanies
			);
		}
		const operationData = {
			...preparedRequest,
			portJobCompanies,
			concurrencyToken
		};

		yield call(
			editPortJobOperationExecutor,
			action.payload,
			operationData,
			apiCall
		);
	} catch (error) {
		// parsing data problems
		yield put(
			editPortJobOperationAsync.failed({
				error: new Error('Operation could not be updated. Form data issue.'),
				params: action.payload
			})
		);
	}
}

// WATCHERS
function* editWatcher() {
	yield takeLatest(editPortJobOperationAsync.type, editPortJobOperation);
}

export default function*(): SagaIterator {
	yield all([fork(editWatcher)]);
}
