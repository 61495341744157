import actionCreatorFactory from 'typescript-fsa';
import { UpdateUserParams, User } from 'services/api/users/userServiceTypes';

const actionCreator = actionCreatorFactory('user');

export const updateUserAsync = actionCreator.async<
	UpdateUserParams,
	User,
	Error
>('UPDATE_USER', {
	templateParams: {
		entityName: 'Profile'
	}
});

export const updateUser = updateUserAsync.started;
