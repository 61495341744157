import { VesselsState } from '../vesselsState';
import {
	RetrieveCompanyVesselRequest,
	VesselDetails
} from 'services/api/vessels/vesselsServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveExtendVessel = makeFetchStatusReducers<
	VesselsState,
	'fetchStatuses',
	RetrieveCompanyVesselRequest,
	VesselDetails
>('fetchStatuses', ({ vesselId }) => vesselId, {
	doneReducer: (
		state: VesselsState,
		action: Success<RetrieveCompanyVesselRequest, VesselDetails>
	): VesselsState => ({
		...state,
		byId: {
			...state.byId,
			[action.params.vesselId]: action.result
		}
	})
});
