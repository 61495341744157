import { retrieveCompaniesConfigurationSettingsAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { RetrieveCompaniesConfigurationSettingsResponse } from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	null,
	RetrieveCompaniesConfigurationSettingsResponse,
	Error
>({
	api: Api.Companies.retrieveCompaniesConfigurationSettings,
	async
});
