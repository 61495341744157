import { call, select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	ConvertServiceRequest as Request,
	ConvertServicePayload as Payload,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { convertServiceAsync as async } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { ResultCollection } from 'services/api/apiTypes';

const api = Api.Finance.convertService;
const commonExecutor = makeDefaultExecutor<
	Request,
	ResultCollection<PortJobService>,
	Error
>({
	api,
	async
});

export const executor = function*({ jobServiceId }: Payload) {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);

	yield call(commonExecutor, { portCallId, jobCode, jobServiceId });
};

export const convertServiceWatcher = makeTakeEveryWatcher<
	Payload,
	ResultCollection<PortJobService>,
	Error
>({ api, async }, executor);
