import { call, put, select, takeLatest, all, fork } from 'redux-saga/effects';
import { AxiosTypedResponse, ResultCollection } from 'services/api/apiTypes';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { first } from 'lodash';

import {
	RetrieveUsersRequest,
	User
} from 'services/api/users/userServiceTypes';
import { UserFilters } from '../usersState';
import { getUsersCount, getUsersFiltersSelector } from '../usersSelectors';

import { retrieveUsers, retrieveUsersAsync } from '../actions/retrieveUsers';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

export function* retrieveUsersWorker(): SagaIterator {
	const filters: UserFilters = yield select(getUsersFiltersSelector);
	const count = yield select(getUsersCount);

	const request: RetrieveUsersRequest = {
		index: count,
		limit: DEFAULT_LIST_LIMIT,
		sortBy: 'lastActivityOn:desc',
		...(filters.status ? { status: filters.status.key } : {})
	};

	if (filters.search && filters.search.length > 0) {
		request.companiesIds = filters.search
			.filter(item => item.type === 'Companies')
			.map(item => item.id)
			.join(',');
		request.ids = filters.search
			.filter(item => item.type === 'Usernames' || item.type === 'Emails')
			.map(item => item.id)
			.join(',');

		const searchTermSearch = first(
			filters.search.filter(item => item.type === 'Search')
		);
		if (searchTermSearch) {
			request.searchTerm = searchTermSearch.name;
		}
	}

	yield put(retrieveUsersAsync.started(request));

	try {
		const response: AxiosTypedResponse<ResultCollection<User>> = yield call(
			Api.Users.retrieveUsers,
			request
		);
		yield put(
			retrieveUsersAsync.done({
				result: response.data.elements,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveUsersAsync.failed({
				error,
				params: request
			})
		);
	}
}

function* retrieveUsersWatcher() {
	yield takeLatest(retrieveUsers.type, retrieveUsersWorker);
}

export default function*() {
	yield all([fork(retrieveUsersWatcher)]);
}
