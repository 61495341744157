import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Gap } from 'components';

import styles from './SidePanel.module.scss';
import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { PageType } from 'store/finance/constants';
import { getIsFinanceDocumentPageDeleteLoading } from 'store/finance/selectors/financeDocumentsSelectors';
import { AppState } from 'store-types';
import { getIsSupplierDocumentPageDeleteLoading } from 'store/supplierInvoice/selectors';
import DocumentDetailsRow from './DocumentRow';

export interface SidePanelProps {
	document: PortCallDocument;
	fileName: string;
	activePageDetails: PortCallDocumentPage;
	activePageType: PageType;
	onPageTypeSelect: (pageType: PageType) => void;
	canDeletePage: boolean;
	isSupplierInvoice?: boolean;
	onPageDelete: (page: PortCallDocumentPage, pageType: PageType) => void;
}

const SidePanel: React.FC<SidePanelProps> = props => {
	const getDeletePageFetchStatusMethod = props.isSupplierInvoice
		? getIsSupplierDocumentPageDeleteLoading
		: getIsFinanceDocumentPageDeleteLoading;

	const isOriginalPageDeleting = useSelector((state: AppState) =>
		getDeletePageFetchStatusMethod(
			state,
			props.activePageDetails.originalFinanceDocumentId,
			props.activePageDetails.actualNumber,
			PageType.ORIGINAL
		)
	);
	const isAnnotatedPageDeleting = useSelector((state: AppState) =>
		getDeletePageFetchStatusMethod(
			state,
			props.activePageDetails.annotatedFinanceDocumentId,
			props.activePageDetails.actualNumber,
			PageType.UPDATED
		)
	);

	const getFileName = (fileName: string) => {
		if (fileName.length > 20) {
			const value = fileName.substring(0, 20);
			return `${value}..${props.document.file.extension}`;
		}
		return `${fileName}${props.document.file.extension}`;
	};
	const { updatedOn, annotatedUpdatedOn } = props.activePageDetails;
	const onDelete = (e: React.MouseEvent<HTMLElement>, pageType: PageType) => {
		e.stopPropagation();
		props.onPageDelete(props.activePageDetails, pageType);
	};
	return (
		<>
			<Flex className={styles.subHeaderContainer}>
				<Gap all>
					<span className={styles.subHeader}>Document Versions</span>
				</Gap>
			</Flex>
			<DocumentDetailsRow
				{...props}
				isOriginalPage={true}
				updatedOn={updatedOn}
				fileName={getFileName(props.fileName)}
				fullFileName={`${props.fileName}${props.document.file.extension}`}
				onDelete={e => onDelete(e, PageType.ORIGINAL)}
				onDocumentSelect={() => props.onPageTypeSelect(PageType.ORIGINAL)}
				isDocumentActive={props.activePageType === PageType.ORIGINAL}
				isDocumentDeleting={isOriginalPageDeleting}
			/>
			{props.activePageDetails.isAnnotated && (
				<DocumentDetailsRow
					{...props}
					isOriginalPage={false}
					updatedOn={annotatedUpdatedOn}
					fileName={getFileName(props.fileName)}
					fullFileName={`${props.fileName}${props.document.file.extension}`}
					onDelete={e => onDelete(e, PageType.UPDATED)}
					onDocumentSelect={() => props.onPageTypeSelect(PageType.UPDATED)}
					isDocumentActive={props.activePageType === PageType.UPDATED}
					isDocumentDeleting={isAnnotatedPageDeleting}
				/>
			)}
		</>
	);
};

export default React.memo(SidePanel);
