import * as React from 'react';
import { PageFooter } from 'components';
import { InjectedWizardFormBaseProps } from 'components/WizardForm';
import { Justify } from 'components/types';
import { Row, Col, Button } from 'components/antd';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import { ButtonRow } from 'components/ButtonRow/ButtonRow';
import { connect } from 'react-redux';
import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import {
	getIsPortJobWizardSubmitBtnDisabled,
	getIsEtaSpecified
} from './createPortJobSelectors';
import {
	getIsPortJobFetching,
	isCreationPortJobFirstStepPermissionError
} from 'store/portJobs/selectors';
import { getIsPortJobCreatedFromNewThread } from './Pages/Page1/page1Selectors';
import { AppState } from 'store-types';
import styles from './CreatePortJob.module.scss';

interface WizardFormFooterProps extends InjectedWizardFormBaseProps {
	isSubmitDisabled: boolean;
	onFinish: () => void;
	onCancel: () => void;
	// from mapStateToProps
	isLoading: boolean;
	isCreateFromNewThread: boolean;
	activeWizard: WizardFormProps;
	isEtaSpecified: boolean;
	hasPermission: boolean;
}

class WizardFormFooter extends React.Component<WizardFormFooterProps> {
	onNext = () => {
		const { activePageLast, onFinish, activePageState, nextPage } = this.props;
		if (activePageLast) {
			return onFinish();
		}
		if (activePageState.onNextClick) {
			return activePageState.onNextClick();
		}
		return nextPage();
	};

	render() {
		const {
			activePageFirst,
			prevPage,
			onCancel,
			activePageLast,
			isSubmitDisabled,
			activeWizard,
			isLoading,
			isCreateFromNewThread,
			isEtaSpecified,
			hasPermission
		} = this.props;
		const isAssignMode = activeWizard.mode === 'assign';
		const hasTooltip = !isEtaSpecified || (isAssignMode && !hasPermission);
		const tooltipText = !isEtaSpecified
			? 'Please fill ETA in order to create new Job'
			: `You don't have permission to create new job`;

		const tooltip: TooltipProps | undefined = hasTooltip
			? {
					title: tooltipText,
					placement: 'topRight'
			  }
			: undefined;

		return (
			<PageFooter>
				<Row>
					<Col xs={4}>
						{!activePageFirst && (
							<Button
								type="primary"
								transparent
								icon="left"
								onClick={prevPage}
								className={styles.backButton}
							>
								Back
							</Button>
						)}
					</Col>
					<Col xs={8} justify={Justify.END}>
						<ButtonRow>
							<Button
								type="primary"
								transparent
								onClick={onCancel}
								className={styles.cancelButton}
							>
								{isAssignMode ? 'Return to Thread' : 'Cancel'}
							</Button>
							{/* Do not allow to create a job for new thread*/}
							{!isCreateFromNewThread &&
								activeWizard.fromPath !== 'nomination' && (
									<Button
										type={'primary'}
										loading={isLoading}
										onClick={this.onNext}
										disabled={
											(isAssignMode && !hasPermission) ||
											isSubmitDisabled ||
											!isEtaSpecified
										}
										tooltip={tooltip}
									>
										{isAssignMode || activePageLast
											? 'Create New Job'
											: 'Continue'}
									</Button>
								)}
						</ButtonRow>
					</Col>
				</Row>
			</PageFooter>
		);
	}
}

export default connect((state: AppState) => ({
	activeWizard: getActiveWizard(state),
	isLoading: getIsPortJobFetching(state),
	isSubmitDisabled: getIsPortJobWizardSubmitBtnDisabled(state),
	isCreateFromNewThread: getIsPortJobCreatedFromNewThread(state),
	isEtaSpecified: getIsEtaSpecified(state),
	hasPermission: !isCreationPortJobFirstStepPermissionError(state)
}))(WizardFormFooter);
