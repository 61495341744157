import React from 'react';
import { PATHS } from 'sections/App/RouteParams';
import { navigateTo } from 'utils';
import DocumentViewerContent from 'sections/DocumentViewer/DocumentViewerContent';
import { ScrollableLayout, Flex } from 'components';
import ViewSupplierDetailsSection from './ViewSupplierDetailsSection';
import ViewInvoiceDetailsSection from './ViewInvoiceDetailsSection';
import { useActiveSupplierInvoice } from '../../../SupplierInvoice.hooks';

interface ViewSupplierInvoiceBaseProps {
	supplierInvoiceId?: string;
}

const ViewSupplierInvoice: React.SFC<ViewSupplierInvoiceBaseProps> = props => {
	const {
		documentId,
		loading,
		document,
		activeSupplierInvoice
	} = useActiveSupplierInvoice(props.supplierInvoiceId);

	const onClose = () => navigateTo(`/${PATHS.supplierinvoices}/`);
	const shouldShowInvoiceDetailsSection =
		!activeSupplierInvoice ||
		(activeSupplierInvoice && !activeSupplierInvoice.isCancelled);

	return (
		<ScrollableLayout stretch>
			<Flex fit direction="horizontal">
				<DocumentViewerContent
					returnUrl={`/${PATHS.supplierinvoices}`}
					isDocumentPDF // Hardcoded, since only PDF's are allowed
					documentId={documentId}
					loading={loading}
					onFinish={onClose}
					preventSplit // Makes use of the Browser default preview
					fileDocument={document}
					onBeforeLeave={onClose}
					canSubmit // Enables the okButton
					cancelButtonProps={{ children: undefined }}
					okButtonProps={{ children: 'Close' }}
				>
					<ViewSupplierDetailsSection />
					{shouldShowInvoiceDetailsSection && <ViewInvoiceDetailsSection />}
				</DocumentViewerContent>
			</Flex>
		</ScrollableLayout>
	);
};

export default ViewSupplierInvoice;
