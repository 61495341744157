import * as React from 'react';
import classNames from 'classnames';
import AntRadioGroup from 'antd/lib/radio/group';

import { RadioGroupProps } from 'antd/lib/radio/interface';
import styles from './RadioGroup.module.scss';

import createFieldComponent from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';

// TODO: Antd new version prop: {buttonStyle} should be reviewed and refactored if fits
export class RadioGroup extends React.Component<RadioGroupProps> {
	static ReduxFormItem: typeof ReduxFormItem;
	render() {
		const { className, ...props } = this.props;
		return (
			<AntRadioGroup
				{...props}
				className={classNames(styles.root, className)}
			/>
		);
	}
}

const mapProps = customMap<RadioGroupProps>(props => ({
	value: props.input.value || props.defaultValue
}));

const ReduxFormItem = createFieldComponent(RadioGroup, mapProps);

RadioGroup.ReduxFormItem = ReduxFormItem;

export default RadioGroup;
