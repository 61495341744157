import * as React from 'react';
import { Flex, Text } from 'components';
import styles from './Screens.module.scss';
import { Justify } from 'components/types';

const Hooray = () => (
	<Flex
		className={styles.hooray}
		align="center"
		direction="vertical"
		justify={Justify.CENTER}
		flex="1"
	>
		<img
			src={require('./images/hooray.svg')}
			alt="You're up to date with your Jobs and Port Call Operations."
		/>
		<hr className={styles.hr} />
		<h1>Hooray!</h1>
		<p className={styles.lead}>
			You're up to date with your Jobs and Port Call Operations.
		</p>
		<Text color="default" weight="semi-bold">
			Check back later for updates on the job you're following.
		</Text>
	</Flex>
);

export default Hooray;
