import {
	getActiveJobStatusTimeComparisonSelector,
	getActivePortJob,
	getActivePortJobStatus,
	getIsJobLinked
} from 'store/portJobs/selectors';
import { PortJobStatus, JobType } from 'store/portJobs/constants';
import { getUserType } from 'store/auth/selectors/user';
import { createSelector } from 'reselect';
import { AppState } from 'store-types.d';

import {
	ActionButtonsByUserRoleAndJobStatus as buttonRuleset,
	ActionButtonTypes
} from 'sections/PortCall/PortJob/PortJobConstants';
import { PortJobStatusComparisonType } from 'services/api/portJobs/portJobsServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';

export const getCustodyRoles = (state: AppState) =>
	state.portjob.dictionaries.custodyRoles;

export const getActionButtonType = createSelector(
	getActivePortJob,
	getUserType,
	getActiveJobStatusTimeComparisonSelector(
		PortJobStatus.AWAITING_ACCEPTANCE,
		PortJobStatusComparisonType.SAME_OR_AFTER
	),
	getIsJobLinked,
	(
		portJob,
		userType,
		isStatusAppointedOrLater,
		isLinked
	): ActionButtonTypes | null => {
		if (!portJob) {
			return null;
		}
		if (
			portJob.jobType === JobType.NOMINATION &&
			isStatusAppointedOrLater &&
			!isLinked
		) {
			return buttonRuleset[portJob.jobType][userType][
				PortJobStatus.AWAITING_APPOINTMENT
			];
		} else if (
			(portJob.jobType === JobType.NOMINATION ||
				portJob.jobType === JobType.CHARTERER_OVERSIGHT) &&
			portJob.status === PortJobStatus.AWAITING_INVITATION
		) {
			return buttonRuleset[portJob.jobType][userType][
				PortJobStatus.AWAITING_INVITATION
			];
		}
		if (
			userType === UserType.HUB &&
			portJob.jobType !== JobType.APPOINTMENT &&
			isLinked
		) {
			return ActionButtonTypes.LINK_CARGO;
		}
		return buttonRuleset[portJob.jobType][userType][portJob.status];
	}
);

export const getIsPortJobStatusConfirmed = createSelector(
	getActivePortJobStatus,
	status => status === PortJobStatus.CONFIRMED
);

export const getIsPortJobStatusPda = createSelector(
	getActivePortJobStatus,
	status => status === PortJobStatus.PDA
);

export const getIsPortJobStatusConfirmedorPDARequested = createSelector(
	getActivePortJobStatus,
	status => status === PortJobStatus.CONFIRMED || PortJobStatus.PDA_REQUESTED
);
