import { CompaniesState, initialState } from '../companiesState';
import {
	RetrieveCompaniesRequest,
	RetrieveCompaniesResponse
} from 'services/api/companies/companiesServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { keyBy } from 'lodash';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function onRetrieveCompaniesSuccess(
	state: CompaniesState,
	action: Success<RetrieveCompaniesRequest, RetrieveCompaniesResponse>
): CompaniesState {
	const {
		result: { elements },
		params
	} = action;

	return {
		...state,
		hasMore: elements.length === (params.limit || DEFAULT_LIST_LIMIT),
		byId: {
			...state.byId,
			...keyBy(elements, item => item.id)
		}
	};
}

export const onResetCompanies = (_state: CompaniesState) => initialState;

export const onRetrieveCompanies = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompaniesRequest,
	RetrieveCompaniesResponse
>('fetchStatuses', 'all', {
	doneReducer: onRetrieveCompaniesSuccess,
	failedReducer: state => ({
		...state,
		hasMore: false
	})
});
