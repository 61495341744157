import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, take, takeLatest, select } from 'redux-saga/effects';
import { retrievePortCall, setActivePortCallId } from 'store/portcalls';
import {
	retrieveEditPortCallDataAsync,
	RetrieveEditPortCallActionPayload,
	retrieveEditPortCallData
} from 'store/portCall/actions/retrieveEditPortCallData';
import { getActivePortCall } from 'store/portcalls/selectors';
import parsePortCallIntoForm from './parsePortCallIntoForm';
import { initialize } from 'redux-form';
import { EDIT_PORTCALL_FORM } from './editPortCallDataTypes';
import { retrievePortCallAsync } from 'store/portcalls/actions/retrievePortCall';
import moment from 'moment';

export function* worker({
	payload
}: Action<RetrieveEditPortCallActionPayload>): SagaIterator {
	const { portCallId } = payload;
	yield put(retrieveEditPortCallDataAsync.started(payload));

	try {
		yield put(setActivePortCallId(portCallId));
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobs' }));
		const result = yield take([
			retrievePortCallAsync.done,
			retrievePortCallAsync.failed
		]);
		if (result.error) {
			throw new Error('Port Call information retrieving has end by error');
		}
		const portCall = yield select(getActivePortCall);
		const editPortCallFormData = parsePortCallIntoForm(portCall);
		const formData = {
			...editPortCallFormData,
			etaPlt: moment(editPortCallFormData.etaPlt).utc(),
			etsPlt: editPortCallFormData.etsPlt
				? moment(editPortCallFormData.etsPlt).utc()
				: null
		};
		yield put(initialize(EDIT_PORTCALL_FORM, formData));
		yield put(
			retrieveEditPortCallDataAsync.done({
				params: payload,
				result: undefined,
				response: null
			})
		);
	} catch (error) {
		yield put(
			retrieveEditPortCallDataAsync.failed({
				error,
				params: payload
			})
		);
	}
}

export default function*() {
	yield takeLatest(retrieveEditPortCallData.type, worker);
}
