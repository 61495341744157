import * as React from 'react';
import { Select } from 'components/antd';
import {
	getThreadTags,
	getIsCurrentUserAssignedToActiveThread
} from 'store/thread/selectors';
import { getActiveThreadId } from 'store/threads/selectors';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import {
	TagType,
	UpdateTagsRequest
} from 'services/api/threads/threadsServiceTypes';
import styles from './ThreadTags.module.scss';
import { updateTags } from 'store/thread';

interface ThreadTagsProps {
	activeThreadId: string;
	currentUserAssignedToThread: boolean;
	tags: TagType[] | undefined;
	updateTags: typeof updateTags;
}

class Tags extends React.Component<ThreadTagsProps> {
	handleChange = (values: string[]) => {
		const { activeThreadId, tags } = this.props;
		const codes: string[] = tags
			? tags.filter(tag => values.includes(tag.name)).map(tag => tag.code)
			: [];
		const prepareTagRequest: UpdateTagsRequest = {
			codes,
			threadId: activeThreadId
		};
		this.props.updateTags(prepareTagRequest);
	};
	userSelectedTags = () => {
		const { tags } = this.props;
		return tags ? tags.filter(tag => tag.isSelected).map(tag => tag.name) : [];
	};
	isDisabled(): boolean {
		const { currentUserAssignedToThread } = this.props;
		return !currentUserAssignedToThread;
	}
	getSelectOptions = () => {
		const { tags } = this.props;
		return tags
			? tags.map(tag => (
					<Select.Option key={tag.code} value={tag.name}>
						{tag.name}
					</Select.Option>
			  ))
			: null;
	};
	render() {
		const selectedTags = this.userSelectedTags();
		return (
			<section className={styles.tags}>
				<span>Tags</span>
				<Select
					mode="multiple"
					defaultValue={selectedTags}
					placeholder="Type to select the Tags"
					onChange={this.handleChange}
					disabled={!this.props.currentUserAssignedToThread}
				>
					{this.getSelectOptions()}
				</Select>
			</section>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			tags: getThreadTags(state),
			activeThreadId: getActiveThreadId(state),
			currentUserAssignedToThread: getIsCurrentUserAssignedToActiveThread(state)
		};
	},
	{
		updateTags
	}
)(Tags);
