import { forEach, get } from 'lodash';
import { FormErrors } from 'redux-form';
import {
	FormData,
	FormPageData
} from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import {
	CreatePortCallDocumentRequest,
	CreatePortCallDocumentRequestData,
	DocumentContextualization
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const preparePlaceholders = ({ placeholders }: FormPageData) => {
	const result: string[] = [];
	forEach(placeholders, (checked, id) => {
		if (checked) {
			result.push(id);
		}
	});
	return result;
};

const preparePage = (page: FormPageData) => ({
	documentTypeCode: page.documentTypeCode || '',
	placeholders: preparePlaceholders(page),
	jobs: page.jobs || []
});

const isPageValid = (
	syncErrors: FormErrors<FormData, string | boolean>,
	index: number
) => !get(syncErrors, ['pages', index]);

const prepareRequest = (
	{ data: { documentId } }: CreatePortCallDocumentRequest,
	{ pages }: FormData,
	syncErrors: FormErrors<FormData, string | boolean>,
	isPDF: boolean
): CreatePortCallDocumentRequestData => {
	const contextualizations = pages.reduce<DocumentContextualization[]>(
		(acc, page, index) => {
			if (!isPageValid(syncErrors, index) || page.ignorePage) {
				return acc;
			}
			const preparedPage: DocumentContextualization = preparePage(page);
			if (isPDF) {
				preparedPage.number = index + 1;
			}
			acc.push(preparedPage);
			return acc;
		},
		[]
	);

	return {
		documentId,
		contextualizations
	};
};

export default prepareRequest;
