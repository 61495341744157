import actionCreator from '../financeActionCreator';
import { DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest } from 'services/api/finance/financeServiceTypes';

export type deleteFinanceDocumentOriginalAndAnnotatedPageActionParams = Pick<
	DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest,
	'documentId' | 'actualNumber' | 'documentType'
>;

export const DELETE_DOCUMENT_ORIGINAL_AND_ANNOTATED_PAGE =
	'DELETE_DOCUMENT_ORIGINAL_AND_ANNOTATED_PAGE';
export const deleteFinanceDocumentOriginalAndAnnotatedPageAsync = actionCreator.async<
	DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest,
	null,
	Error
>(DELETE_DOCUMENT_ORIGINAL_AND_ANNOTATED_PAGE);

export const deleteFinanceDocumentOriginalAndAnnotatedPage = actionCreator<
	deleteFinanceDocumentOriginalAndAnnotatedPageActionParams
>(DELETE_DOCUMENT_ORIGINAL_AND_ANNOTATED_PAGE);
