import {
	retrievePortCallMetadataStart,
	retrievePortCallMetadataEnd,
	retrievePortCallMetadata,
	retrievePortCallMetadataAsync
} from '../actions';
import { SagaIterator } from 'redux-saga';
import {
	call,
	cancel,
	fork,
	put,
	select,
	take,
	delay
} from 'redux-saga/effects';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import { getActivePortCallId } from '../../portcalls/portCallsSelectors';
import { RetrievePortCallMetadataRequest } from 'services/api/portCall/portCallServiceTypes';
import { Action } from 'typescript-fsa';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';

function* refreshCycle(portCallId?: string) {
	const activePortCallId = yield select(getActivePortCallId);
	yield put(
		retrievePortCallMetadata({
			portCallId: portCallId || activePortCallId
		})
	);
}

function* worker({
	payload: { portCallId }
}: Action<RetrievePortCallMetadataRequest>): SagaIterator {
	yield call(refreshCycle, portCallId);
	yield take(retrievePortCallMetadataAsync.done);
	yield put(
		setNotificationActionTypeToNotification([
			retrievePortCallMetadataAsync.type
		])
	);
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield call(refreshCycle, portCallId);
	}
}

export default function*() {
	while (true) {
		const action = yield take(retrievePortCallMetadataStart);
		const pollTask = yield fork(worker, action);
		yield take(retrievePortCallMetadataEnd);
		yield cancel(pollTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrievePortCallMetadataAsync.type
			])
		);
	}
}
