import React from 'react';
import MoreInfo from 'components/MoreInfo/MoreInfo';
import OptionalValue from 'components/OptionalValue/OptionalValue';

interface TermsProps {
	terms: string;
}

export default ({ terms }: TermsProps) => (
	<OptionalValue value={terms}>
		<MoreInfo
			popContent={terms}
			title={'View'}
			popTitle={'Options / Terms'}
			iconColor="primary"
			placement="topRight"
		/>
	</OptionalValue>
);
