import React from 'react';
import { Gap, Text, Box, ScrollableLayout } from 'components';
import { Radio } from 'components/antd';
import { PortCallRow } from 'sections/PortCalls/PortCallRow/PortCallRow';
import { PortCallDuplicate } from 'services/api/portCalls/portCallsServiceTypes';
import styles from './MergePortCallsOptionsList.module.scss';

interface MergePortCallsOptionsListProps {
	duplicates: PortCallDuplicate[];
	onOptionChange: (option: string | boolean) => void;
}

class MergePortCallsOptionsList extends React.PureComponent<
	MergePortCallsOptionsListProps
> {
	componentDidMount() {
		// initialize merging context with default option
		this.props.onOptionChange(false);
	}

	onPortJobClick = (link: string) => {
		const win = window.open(link, '_blank');
		if (win) {
			win.focus();
		}
	};

	render() {
		const { duplicates, onOptionChange } = this.props;
		return (
			<ScrollableLayout>
				<Gap top="md" left="lg" bottom="lg" isBlock outside={false}>
					<Radio.Group
						defaultValue={false}
						onChange={e => onOptionChange(e.target.value)}
					>
						<Gap top left bottom isBlock outside={false}>
							<Radio value={false}>
								I confirm current Port Call is not a duplicate of any of the
								Port Calls below
							</Radio>
						</Gap>
						<Gap top left isBlock outside={false}>
							<Text weight="bold">Or merge current Port Call with</Text>
						</Gap>
						<Gap top left right isBlock outside={false}>
							{duplicates.map(duplicate => (
								<div className={styles.duplicateItem} key={duplicate.id}>
									<Radio
										key={duplicate.id}
										value={duplicate.id}
										className={styles.radioButton}
									/>
									<Box type="bordered">
										<PortCallRow
											portCall={duplicate}
											onPortJobClick={this.onPortJobClick}
											checkPortJobCanView
										/>
									</Box>
								</div>
							))}
						</Gap>
					</Radio.Group>
				</Gap>
			</ScrollableLayout>
		);
	}
}

export default MergePortCallsOptionsList;
