import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';

import DataFetcher from 'utils/components/DataFetcher';
import AppLogoLayout from 'sections/AppLayout/AppLogoLayout/AppLogoLayout';
import {
	PreRegistrationPanelProgress,
	PreRegistrationPanelSuccess,
	PreRegistrationPanelFailed
} from './PreRegistrationPanel';
import PreRegistrationForm from './PreRegistrationForm/PreRegistrationForm';

import {
	retrievePreRegistrationAgentCompany,
	resetPreRegistration
} from 'store/registration/actions';
import {
	getRetrievePreRegistrationAgentCompanyFetchStatus,
	getPreRegistrationAgentCompany,
	getIsPreRegistered
} from 'store/registration/selectors';
import { PreRegistrationAgentCompany } from 'store/registration/registrationState';

import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';
interface PreRegistrationRouteParams {
	agentCompany: string;
}
type PreRegistrationRouteProps = RouteComponentProps<
	PreRegistrationRouteParams
> & {
	agentCompany: PreRegistrationAgentCompany | undefined;
	isPreregistered: boolean;
	fetchStatus: FetchStatus;
	retrieve: typeof retrievePreRegistrationAgentCompany;
	reset: typeof resetPreRegistration;
};
const PreRegistrationRoute = ({
	agentCompany,
	isPreregistered,
	fetchStatus,
	retrieve,
	reset,
	location
}: PreRegistrationRouteProps) => {
	const params = queryString.parse(location.search);
	return (
		<AppLogoLayout>
			<DataFetcher
				fetchStatus={fetchStatus}
				dispatch={() => retrieve({ agentCompany: params.agentCompany })}
				reset={() => reset()}
				error={() => <PreRegistrationPanelFailed />}
			>
				{() => {
					if (!agentCompany) {
						return null;
					}
					if (isPreregistered) {
						return (
							<PreRegistrationPanelSuccess
								id={agentCompany.id}
								email={agentCompany.email}
							/>
						);
					}
					return (
						<div>
							<PreRegistrationPanelProgress {...agentCompany} />
							<PreRegistrationForm />
						</div>
					);
				}}
			</DataFetcher>
		</AppLogoLayout>
	);
};

export default connect(
	(state: AppState) => ({
		agentCompany: getPreRegistrationAgentCompany(state),
		isPreregistered: getIsPreRegistered(state),
		fetchStatus: getRetrievePreRegistrationAgentCompanyFetchStatus(state)
	}),
	{
		retrieve: retrievePreRegistrationAgentCompany,
		reset: resetPreRegistration
	}
)(PreRegistrationRoute);
