import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { Theme } from 'components/types';

import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import { PortCallMetadataActionCode } from 'services/api/portCall/portCallServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { Severity, AlertMetricType, AlertMetricCode } from 'app-constants';

import { getThreadsPendingAlerts } from 'store/threads/selectors';
import { includes, values, flatMap, some } from 'lodash';
import { PortCallAlertsMap, AlertsByMetricType, Alert } from 'app-types';
import { getActivePortJob } from 'store/portJobs/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { getPortJobsForActivePortCall } from 'store/portcalls/selectors';
import { getSafeFinanceDetails } from 'store/finance/selectors';

import { AuthActAsOption } from 'store/auth/authState';
import { MessagesTabsEnum } from 'sections/PortCall/Messages/MessagesList/MessagesListTypes';

export const severityColorMap = {
	[Severity.RED]: Theme.ERROR,
	[Severity.YELLOW]: Theme.WARNING
};

export const getPortCallThreadsFilters = (state: AppState) =>
	state.portCall.threadsFilters;

export const getPortCallThreadsFiltersActiveTab = createSelector(
	getPortCallThreadsFilters,
	({ onlyUnread }) =>
		onlyUnread ? MessagesTabsEnum.Unread : MessagesTabsEnum.All
);

const getPortCallActAsOptions = (state: AppState) => state.portCall.actAs;

export const getActAsOptionsFlaggedForPortCall = createSelector(
	getPortJobsForActivePortCall,
	getPortCallActAsOptions,
	(portJobs, options): AuthActAsOption[] => {
		return options.map(option => {
			const canActAs = portJobs
				// make sure there is a job appointed to current agent company
				.some(portJob => {
					const company = portJob.portJobCompanies.find(
						company =>
							company.companyId === option.id &&
							portJob.performingAgentCompany.id === company.id
					);

					return (
						company &&
						portJob.performingAgentCompany.id === company.id &&
						portJob.status !== PortJobStatus.CREATED &&
						portJob.status !== PortJobStatus.CONFIRMED &&
						portJob.status !== PortJobStatus.AWAITING_APPOINTMENT &&
						portJob.status !== PortJobStatus.AWAITING_INVITATION &&
						portJob.status !== PortJobStatus.AWAITING_ACCEPTANCE &&
						portJob.status !== PortJobStatus.PDA_REQUESTED
					);
				});
			return {
				id: option.id,
				name: option.name,
				disabled: !canActAs
			};
		});
	}
);

const getPortCallAlerts = (state: AppState) => state.portCall.alerts;

const getPortCallAlertGroupsByMetricType = createSelector(
	getPortCallAlerts,
	alerts => alerts.portCallAlertGroupsByMetricType
);

const getPortJobAlertGroupsByMetricType = createSelector(
	getPortCallAlerts,
	alerts => alerts.portJobs
);

export const getPortCallFlattenAlerts = createSelector(
	getPortCallAlertGroupsByMetricType,
	alerts => flatMap(alerts, group => group.alerts || [])
);

export const getPortJobFlattenAlerts = createSelector(
	getPortJobAlertGroupsByMetricType,
	alerts =>
		flatMap(
			flatMap(values(alerts), job => job.portJobAlertGroupsByMetricType),
			group => group.alerts || []
		)
);

export const getPortCallAlertByMetricCode = (
	state: AppState,
	metricCode: AlertMetricCode
) =>
	getPortCallFlattenAlerts(state).find(
		alert => alert.metricCode === metricCode
	);
export const getPortCallAlertIdByMetricCode = createSelector(
	getPortCallAlertByMetricCode,
	alert => alert?.alertId
);
export const getAllMetricAlerts = createSelector(
	getPortCallFlattenAlerts,
	getPortJobFlattenAlerts,
	(portCallAlerts, portJobAlerts) => portCallAlerts.concat(portJobAlerts)
);

export const getIsMerticTypeExistInAlerts = createSelector(
	getAllMetricAlerts,
	(_state: AppState, metricCode: AlertMetricCode) => metricCode,
	(alerts, metricCode) => some(alerts, ['metricCode', metricCode])
);

const getSeverityForAlertsByCodes = (
	alerts: Alert[],
	codes: AlertMetricCode[]
) => {
	if (!alerts) {
		return;
	}
	const filteredAlerts = alerts.filter(alert =>
		includes(codes, alert.metricCode)
	);
	const isRed = filteredAlerts.some(
		alert => alert.alertSeverity === Severity.RED
	);
	if (isRed) {
		return severityColorMap[Severity.RED];
	}
	const isYellow = filteredAlerts.some(
		alert => alert.alertSeverity === Severity.YELLOW
	);
	if (isYellow) {
		return severityColorMap[Severity.YELLOW];
	}
	return;
};

export const getSeverityByCodes = createSelector(
	getAllMetricAlerts,
	(_state: AppState, codes: AlertMetricCode[]) => codes,
	(alerts, codes) => getSeverityForAlertsByCodes(alerts, codes)
);

export const getPortCallMaxAlertSeverity = (
	alerts: PortCallAlertsMap,
	metricTypes: AlertMetricType[]
): Theme | undefined => {
	const groups = alerts?.portCallAlertGroupsByMetricType.filter(group =>
		includes(metricTypes, group.metricType)
	);
	if (!alerts || !groups.length) {
		return;
	}
	const isRed = groups.find(group => group.maxAlertSeverity === Severity.RED);
	if (isRed) {
		return severityColorMap[Severity.RED];
	}
	const isYellow = groups.find(
		group => group.maxAlertSeverity === Severity.YELLOW
	);
	if (isYellow) {
		return severityColorMap[Severity.YELLOW];
	}
	return;
};

export const getCurrentPortCallMaxAlertSeverity = (
	state: AppState,
	metricTypes: AlertMetricType[]
) => getPortCallMaxAlertSeverity(getPortCallAlerts(state), metricTypes);

export const getPortJobMaxAlertSeverity = (
	alertsMap: PortCallAlertsMap,
	jobs: PortJob[],
	metricCodes: AlertMetricCode[]
): Theme | undefined => {
	if (!jobs.length) {
		return;
	}

	const groups: AlertsByMetricType[] = [];

	jobs.forEach(({ id }) => {
		const group = alertsMap.portJobs[id];
		if (group?.portJobAlertGroupsByMetricType) {
			groups.push(...group.portJobAlertGroupsByMetricType);
		}
	});

	if (!groups.length) {
		return;
	}
	const alerts = flatMap(groups, group => group.alerts || []);
	return getSeverityForAlertsByCodes(alerts, metricCodes);
};

const financeTabMetricCodes = [
	AlertMetricCode.SUBMIT_PDA,
	AlertMetricCode.LPA_SUBMIT_PDA,
	AlertMetricCode.VERIFY_PDA,
	AlertMetricCode.APPROVE_PDA,
	AlertMetricCode.PRINCIPAL_APPROVE_PDA,
	AlertMetricCode.SUBMIT_CE,
	AlertMetricCode.LPA_SUBMIT_CE,
	AlertMetricCode.VERIFY_CE,
	AlertMetricCode.APPROVE_CE,
	AlertMetricCode.PRINCIPAL_APPROVE_CE,
	AlertMetricCode.SUBMIT_DA,
	AlertMetricCode.LPA_SUBMIT_DA,
	AlertMetricCode.VERIFY_DA,
	AlertMetricCode.UNASSIGNED_DA,
	AlertMetricCode.PRINCIPAL_APPROVE_DA,
	AlertMetricCode.FUNDING_REQUEST_PENDING,
	AlertMetricCode.FUNDS_REQUESTED_FROM_PRINCIPAL,
	AlertMetricCode.REPLY_TO_HUB_QUERY_PDA_FOR_LPA,
	AlertMetricCode.REPLY_TO_HUB_QUERY_CE_FOR_LPA,
	AlertMetricCode.REPLY_TO_HUB_QUERY_DA_FOR_LPA,
	AlertMetricCode.REPLY_TO_HUB_QUERY_PDA_FOR_HUB,
	AlertMetricCode.REPLY_TO_HUB_QUERY_CE_FOR_HUB,
	AlertMetricCode.REPLY_TO_HUB_QUERY_DA_FOR_HUB,
	AlertMetricCode.REPLY_TO_CLIENT_QUERY_PDA,
	AlertMetricCode.REPLY_TO_CLIENT_QUERY_CE,
	AlertMetricCode.REPLY_TO_CLIENT_QUERY_DA
];
const overivewTabMetricCodes = [
	AlertMetricCode.CONFIRM_JOB,
	AlertMetricCode.PRINCIPAL_CONFIRM_JOB,
	AlertMetricCode.PROCESS_JOB,
	AlertMetricCode.ACCEPT_APPOINTMENT,
	AlertMetricCode.LPA_ACCEPT_APPOINTMENT,
	AlertMetricCode.WAITING_FOR_INVITATION,
	AlertMetricCode.WAITING_FOR_APPOINTMENT
];
const VPTabMetricTypes = [
	AlertMetricType.PORT_CALL_VESSEL_PROGRAMME,
	AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_ACKNOWLEDGE,
	AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_COMMIT,
	AlertMetricType.PORT_CALL_VESSEL_PROGRAMME_ON_TRACK
];
const operationsTabMetricTypes = [
	AlertMetricType.PORT_CALL_DOCUMENTS,
	AlertMetricType.PORT_CALL_EVENTS,
	AlertMetricType.PORT_CALL_MEASURMENTS
];
const financeTabMetricTypes = [
	AlertMetricType.PORT_CALL_VESSEL_PROGRAMME,
	AlertMetricType.PORT_CALL_EVENTS
];
const overivewTabMetricTypes = [AlertMetricType.PORT_CALL_DUPLICATES];

export const getPendingAlertStatus = createSelector(
	getThreadsPendingAlerts,
	getPortCallAlerts,
	getActivePortJob,
	getSafeFinanceDetails,
	(_state: AppState, tabKey: PortCallTabKey) => tabKey,
	(threadsPendingAlerts, alerts, activeJob, finance, tabKey) => {
		if (tabKey === PortCallTabKey.VESSEL_PROGRAMME) {
			const maxSeverity = getPortCallMaxAlertSeverity(alerts, VPTabMetricTypes);
			return maxSeverity;
		}

		if (tabKey === PortCallTabKey.MESSAGES && threadsPendingAlerts) {
			return severityColorMap[Severity.YELLOW];
		}

		if (tabKey === PortCallTabKey.OPERATIONS) {
			return getPortCallMaxAlertSeverity(alerts, operationsTabMetricTypes);
		}

		if (tabKey === PortCallTabKey.OVERVIEW) {
			const overviewJobs = activeJob ? [activeJob] : [];
			const portJobMaxAlertSeverity = getPortJobMaxAlertSeverity(
				alerts,
				overviewJobs,
				overivewTabMetricCodes
			);
			const portCallMaxAlertSeverity = getPortCallMaxAlertSeverity(
				alerts,
				overivewTabMetricTypes
			);
			if (
				portCallMaxAlertSeverity === Theme.ERROR ||
				portJobMaxAlertSeverity === Theme.ERROR
			) {
				return Theme.ERROR;
			}
			if (
				portCallMaxAlertSeverity === Theme.WARNING ||
				portJobMaxAlertSeverity === Theme.WARNING
			) {
				return Theme.WARNING;
			}
		}

		if (tabKey === PortCallTabKey.FINANCE) {
			const financeActiveJob = activeJob ? [activeJob] : [];
			const codes = finance?.jobs ? finance.jobs.map(job => job.code) : [];
			const allJobs = finance?.jobs;
			const financeJobs = financeActiveJob.filter(job =>
				includes(codes, job.code)
			);
			const isOperationsDisabled = allJobs?.every(
				job => job.isOperationsDisabled === true
			);
			const portCallMaxAlertSeverity = getPortCallMaxAlertSeverity(
				alerts,
				financeTabMetricTypes
			);
			if (portCallMaxAlertSeverity === Theme.WARNING && isOperationsDisabled) {
				return Theme.WARNING;
			}
			if (portCallMaxAlertSeverity === Theme.ERROR && isOperationsDisabled) {
				return Theme.ERROR;
			}
			return getPortJobMaxAlertSeverity(
				alerts,
				financeJobs,
				financeTabMetricCodes
			);
		}

		return;
	}
);

export const getRetrievePortCallVesselInfoFetchStatus = (state: AppState) =>
	state.portCall.fetchStatuses.vesselInfo;

const getPortCallMetadataActions = (state: AppState, portCallId: string) =>
	state.portCall.metadata[portCallId];

export const hasPortCallOutdatedEvents = (
	state: AppState,
	portCallId: string
) => {
	const outdatedDataActions = getPortCallMetadataActions(state, portCallId);
	return outdatedDataActions
		? outdatedDataActions.includes(PortCallMetadataActionCode.REFRESH_EVENTS)
		: false;
};
