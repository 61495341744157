import * as React from 'react';

import { getFormValues } from 'redux-form';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import { Input, TextArea, Row, Col } from 'components/antd';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { getCountries } from 'store/countries/countriesSelectors';
import {
	ADD_COMPANY_DRAFT_MODAL_FORM,
	ADD_COMPANY_DRAFT_MODAL,
	AddCompanyDraftFormNames,
	BaseAddCompanyDraftFormData
} from '../constants';
import { formValidate } from '../validation';
import { retrieveCountries } from 'store/countries';
import { Country } from 'services/api/countries/countriesServiceTypes';
import styles from './AddCompanyDraftForm.module.scss';
import { submitCompanyDraft } from 'store/drafts/actions';
import { closeModal } from 'store/modals/actions';
import Autocomplete from 'components/antd/Autocomplete/Autocomplete';

interface FormProps {
	countries: Country[];
	submitForm: typeof submitCompanyDraft;
	formValues: BaseAddCompanyDraftFormData;
	retrieveCountries: typeof retrieveCountries;
	closeCompanyDraftModal: typeof closeModal;
}

class Form extends React.Component<FormProps> {
	componentDidMount() {
		this.props.retrieveCountries(undefined);
	}

	onSubmit = () => {
		this.props.submitForm(this.props.formValues);
		this.props.closeCompanyDraftModal({ name: ADD_COMPANY_DRAFT_MODAL });
	};

	// eslint-disable-next-line @typescript-eslint/require-await
	onCountrySearch = async (searchCountry: string) => {
		const country = searchCountry.toLowerCase();
		return this.props.countries
			.filter(x => x.name.toLowerCase().includes(country))
			.map(x => ({
				...x,
				id: x.code
			}));
	};

	render() {
		return (
			<ReduxForm
				validate={formValidate}
				form={ADD_COMPANY_DRAFT_MODAL_FORM}
				onSubmit={this.onSubmit}
			>
				<Row>
					<Col xs={12}>
						<Input.ReduxFormItem
							name={AddCompanyDraftFormNames.NAME}
							label="Name"
							required
							help
						/>
					</Col>
					<Col xs={6}>
						<Input.ReduxFormItem
							name={AddCompanyDraftFormNames.CITY}
							label="City"
							required
							help
						/>
					</Col>
					<Col xs={6}>
						<Autocomplete.ReduxFormItem
							name={AddCompanyDraftFormNames.COUNTRY}
							label="Country"
							placeholder="Start typing to select"
							allowClear
							required
							help
							onSearch={this.onCountrySearch}
						/>
					</Col>
				</Row>
				<div className={styles.wrapper}>
					<div className={styles.note}>Please add Email or Phone number</div>
					<Row>
						<Col xs={6}>
							<Input.ReduxFormItem
								name={AddCompanyDraftFormNames.EMAIL}
								label="Email"
								required
								help
							/>
						</Col>
						<Col xs={6}>
							<Input.ReduxFormItem
								name={AddCompanyDraftFormNames.PHONE}
								label="Phone"
								required
								help
							/>
						</Col>
					</Row>
				</div>
				<br />
				<Row>
					<Col xs={12}>
						<Input.ReduxFormItem
							name={AddCompanyDraftFormNames.URL}
							label="Web url"
							help
						/>
					</Col>
					<Col xs={12}>
						<TextArea.ReduxFormItem
							name={AddCompanyDraftFormNames.COMMENT}
							label="Comment"
							help
						/>
					</Col>
				</Row>
			</ReduxForm>
		);
	}
}

const ConnectedForm = connect(
	(state: AppState) => ({
		countries: getCountries(state),
		formValues: getFormValues(ADD_COMPANY_DRAFT_MODAL_FORM)(state)
	}),
	{
		submitForm: submitCompanyDraft,
		retrieveCountries,
		closeCompanyDraftModal: closeModal
	}
)(Form);

export default ConnectedForm;
