import { retrieveConfigurationFilesAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse
} from 'services/api/templates/templatesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse,
	Error
>({
	api: Api.Templates.retrieveCompanyTemplateFiles,
	async: retrieveConfigurationFilesAsync
});
