import { VesselsState } from '../vesselsState';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveVessel = makeFetchStatusReducers<
	VesselsState,
	'fetchStatuses',
	string,
	VesselDetails
>('fetchStatuses', vesselId => vesselId, {
	doneReducer: (
		state: VesselsState,
		action: Success<string, VesselDetails>
	): VesselsState => ({
		...state,
		byId: {
			...state.byId,
			[action.params]: action.result
		}
	})
});
