import React from 'react';

import { Modal } from 'components/antd';

import CargoGrid from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoGrid/CargoGrid';

import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

interface CargoGridContainerProps extends Pick<FormData, 'cargoes'> {
	onDelete: (cargoId: string, chartererCompanyId: string) => void;
	onDeleteConfirmed: () => void;
	onEdit: (cargoId: string) => void;
}

interface CargoGridContainerState {
	showWarningModalForDelete: boolean;
	warningModalText: string;
}

const WARNING_MODAL_NORMAL_CARGO_TEXT =
	'Are you sure you want to delete cargo line?';
const WARNING_MODAL_PARENT_CARGO_TEXT =
	'Deletion of this cargo will lead to deletion of the related split cargoes. Are you sure you want to proceed?';

/**
 * @class CargoGridContainer
 * Assumes all data loading is initiated
 * Responsible for:
 *    1. Connecting data for fields
 *    2. Showing warning modal if cargo line deletion was triggered
 */

class CargoGridContainer extends React.Component<
	CargoGridContainerProps,
	CargoGridContainerState
> {
	constructor(props: CargoGridContainerProps) {
		super(props);

		this.state = {
			showWarningModalForDelete: false,
			warningModalText: WARNING_MODAL_NORMAL_CARGO_TEXT
		};
	}

	onDelete = (
		cargoId: string,
		chartererCompanyId: string,
		isParent: boolean
	) => {
		// Show warning modal if cargo line is being deleted
		this.changeWarningModalText(isParent);
		this.toggleWarningModal();
		this.props.onDelete(cargoId, chartererCompanyId);
	};

	deleteConfirmed = () => {
		this.props.onDeleteConfirmed();
		this.toggleWarningModal();
	};

	toggleWarningModal = () => {
		this.setState({
			showWarningModalForDelete: !this.state.showWarningModalForDelete
		});
	};

	changeWarningModalText = (isParent: boolean) => {
		this.setState({
			warningModalText: isParent
				? WARNING_MODAL_PARENT_CARGO_TEXT
				: WARNING_MODAL_NORMAL_CARGO_TEXT
		});
	};

	render() {
		const { cargoes } = this.props;
		const { showWarningModalForDelete } = this.state;
		return (
			<div>
				<CargoGrid
					onDeleteClicked={this.onDelete}
					onEditClicked={this.props.onEdit}
					cargoes={cargoes}
				/>
				<Modal
					visible={showWarningModalForDelete}
					onCancel={this.toggleWarningModal}
					onOk={this.deleteConfirmed}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>{this.state.warningModalText}</p>
				</Modal>
			</div>
		);
	}
}

export default CargoGridContainer;
