import React from 'react';

import { ModalContext } from 'context';
import { ModalContextValue } from 'context/ModalContext/ModalContext';

import InviteUserModal from './InviteUser/InviteUserModal';
import ToggleUserStatusModal from './ToggleUserStatus/ToggleUserStatusModal';
import { UsersModalsName, INVITE_USER_FORM } from './UsersModalsTypes';
import { useUsersModals } from './UsersModals.hooks';

interface UsersModalsConsumerProps {
	value: ModalContextValue;
}

function UsersModalsConsumer({ value }: UsersModalsConsumerProps) {
	const {
		fetchStatus,
		onSubmitInviteUserForm,
		onSubmitToggleUserStatus
	} = useUsersModals({ form: INVITE_USER_FORM, name: value.name });

	return (
		<>
			{value.name === UsersModalsName.INVITE && (
				<InviteUserModal
					modalValue={value.modalValue}
					submitting={value.modalSubmitting}
					onCancel={value.hideModal}
					onSubmit={onSubmitInviteUserForm}
				/>
			)}
			{value.name === UsersModalsName.TOGGLE_STATUS && (
				<ToggleUserStatusModal
					modalValue={value.modalValue}
					submitting={value.modalSubmitting}
					onCancel={value.hideModal}
					onSubmit={onSubmitToggleUserStatus}
				/>
			)}
			<ModalContext.SubmitResolve fetchStatus={fetchStatus} />
		</>
	);
}

function UsersModals() {
	return (
		<ModalContext.Consumer>
			{value => <UsersModalsConsumer value={value} />}
		</ModalContext.Consumer>
	);
}

export default UsersModals;
