import { select, call, put, takeLatest } from 'redux-saga/effects';

import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api/portJobs/portJobsService';
import { AddPortJobRequestPayload } from 'services/api/portJobs/portJobsServiceTypes';
import { addPortJob, addPortJobAsync } from '../actions/addPortJob';
import { resetPortCall } from 'store/portcalls/actions';
import { preparePortJobRequest } from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { DraftType } from 'store/drafts/draftsState';

import {
	getOperationIdFromFormData,
	getAddPortJobFormData
} from 'store/portcalls/sagas/addPortCall/addPortCallSelectors';
import { getOperationById } from 'store/operations/operationsSelectors';

import { Action } from 'typescript-fsa';
import { navigateTo } from 'utils';
import { getIsMainPrincipalTypeCLS } from '../selectors';

const apiCall = Api.addPortJob;

export function* executor(
	actionParams: AddPortJobRequestPayload,
	api: typeof apiCall
): SagaIterator {
	yield put(addPortJobAsync.started(actionParams));
	try {
		const operationId = yield select(getOperationIdFromFormData);
		const operation = yield select(getOperationById, operationId); // getting type from state
		const chartererDrafts = yield select(
			getCompanyDraftsByType,
			DraftType.CHARTERER
		);
		const shipperReceiverDrafts = yield select(
			getCompanyDraftsByType,
			DraftType.SHIPPER_RECEIVER
		);
		const portJobOperationCompanies = chartererDrafts.concat(
			shipperReceiverDrafts
		);

		const form = yield select(getAddPortJobFormData);
		const portJobAgentCompanies = yield select(
			getCompanyDraftsByType,
			DraftType.AGENT
		);
		const portJobAppointerCompanies = yield select(
			getCompanyDraftsByType,
			DraftType.PRINCIPAL
		);

		const portJobCompanies = portJobAgentCompanies.concat(
			portJobAppointerCompanies
		);
		const isMainPrincipalTypeCLS = yield select(getIsMainPrincipalTypeCLS);
		const request = yield call(preparePortJobRequest, {
			form,
			operation,
			portJobOperationCompanies,
			portJobCompanies,
			isMainPrincipalTypeCLS
		});

		const portCall = yield select(getActivePortCall);

		const response = yield call(api, portCall.id, request);

		yield put(
			addPortJobAsync.done({
				result: {
					...response.data,
					portCallId: portCall.id
				},
				params: actionParams,
				response
			})
		);
		const { code } = response.data;
		// Considering that the wizard and an overview pages look to the same place in the state
		// we need to reset port call before navigating, so that we fetch actual port call data and show correct port job tab
		yield put(resetPortCall());
		yield call(navigateTo, `/portcalls/${portCall.id}/jobs/${code}/overview`);
	} catch (error) {
		yield put(
			addPortJobAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<AddPortJobRequestPayload>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(addPortJob.type, worker);
}
