import { RetrievePortJobStatusesResponse } from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

const ACTION_NAME = 'RETRIEVE_PORTJOB_STATUSES';

export const retrievePortJobStatusesAsync = actionCreator.async<
	null,
	RetrievePortJobStatusesResponse,
	Error
>(ACTION_NAME);

export const retrievePortJobStatuses = actionCreator(ACTION_NAME);
