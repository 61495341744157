import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import { put, call } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveDAOutDocumentIdAsync } from '../actions/retrieveDAOutDocumentId';
import {
	RetrieveDAOutCoverSheetRequest,
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse
} from 'services/api/finance/financeServiceTypes';

import Api from 'services/api';
import { makePollDownloadUrlExecutor } from '../../documents/sagas/pollDownloadUrlSaga';
import { downloadURI } from 'utils';

const async = retrieveDAOutDocumentIdAsync;

export const executor = function*(params: RetrieveDAOutDocumentIdRequest) {
	const payload: RetrieveDAOutDocumentIdRequest = yield call(
		getActivePortCallAndPortJobIdsExecutor,
		params
	);

	yield put(async.started(payload));
	try {
		// 1.
		// POST to get the documentId of the new document
		const response: AxiosTypedResponse<RetrieveDAOutDocumentIdResponse> = yield call(
			Api.Finance.retrieveDAOutDocumentId,
			payload
		);
		const { documentId } = response.data;

		// 2.
		// Prevent infinite loop if !documentId
		if (!documentId) {
			throw Error('DaOut has no document associated');
		}

		// 3.
		// Create executor that will poll later to get documentId.downloadUrl
		const downloadUrlExecutor = makePollDownloadUrlExecutor<
			RetrieveDAOutCoverSheetRequest
		>({
			api: Api.Finance.retrieveDAOutCoverSheet
		});

		// 4.
		// GET (by polling the server) until returns downloadUrl for the provided documentId (cover sheet)
		const downloadUrl: string = yield call(downloadUrlExecutor, {
			...payload,
			documentId
		});

		// 5.
		// Manually Call this downloadUrl to download the file
		yield call(downloadURI, downloadUrl);

		yield put(
			async.done({ params: payload, result: { documentId }, response })
		);
	} catch (error) {
		yield put(
			async.failed({
				params: payload,
				error
			})
		);
	}
};

export default makeTakeEveryWatcher<
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse,
	Error
>(
	{
		api: Api.Finance.retrieveDAOutDocumentId,
		async
	},
	executor
);
