import * as React from 'react';
import classNames from 'classnames';
import Popover from 'components/antd/Popover/Popover';
import Tooltip from 'components/antd/Tooltip/Tooltip';
import { TooltipProps as AntTooltipProps } from 'antd/lib/tooltip';
import { Icon } from 'components/antd/Icon/Icon';
import { IconColor } from 'components/types';
import styles from './MoreInfo.module.scss';

interface MoreInfoProps {
	title: React.ReactNode | string;
	className?: string;
	popTitle?: string;
	popContent?: string | React.ReactNode;
	iconColor?: IconColor;
	isTooltip?: boolean;
	placement?: AntTooltipProps['placement'];
	overlayClassName?: string;
}

export default (props: MoreInfoProps) => {
	const {
		title,
		popTitle,
		popContent,
		iconColor,
		isTooltip,
		className,
		placement,
		overlayClassName
	} = props;
	const overlayClassNames = classNames(styles.overlay, overlayClassName);
	return isTooltip ? (
		<Tooltip
			title={popTitle}
			placement={placement}
			overlayClassName={overlayClassNames}
		>
			<span className={classNames(styles.moreInfo, className)}>
				{title}
				<Icon type="info" color={iconColor} offset="left" />
			</span>
		</Tooltip>
	) : (
		<Popover
			content={popContent}
			placement={placement}
			title={popTitle}
			overlayClassName={overlayClassNames}
		>
			<span className={styles.moreInfo}>
				{title} <Icon type="info" color={iconColor} offset="left" />
			</span>
		</Popover>
	);
};
