import {
	RichTextNodeSection,
	HtmlAttribute,
	ElementType,
	TextType,
	LeafType
} from './RichTextTypes';

export const DEFAULT_NODE_SECTION: RichTextNodeSection =
	RichTextNodeSection.CONTENT;
export const DEFAULT_NODE_TYPE = ElementType.DIV;

export const LEAF_TYPE_MAP = {
	[TextType.STRONG]: LeafType.BOLD,
	[TextType.B]: LeafType.BOLD,
	[TextType.I]: LeafType.ITALIC,
	[TextType.EM]: LeafType.ITALIC,
	[TextType.CODE]: LeafType.CODE,
	[TextType.S]: LeafType.STRIKETHROUGH,
	[TextType.U]: LeafType.UNDERLINE
};

export const HTML_ATTRIBUTE_MAP = {
	[HtmlAttribute.STYLE]: 'style',
	[HtmlAttribute.CLASSNAME]: 'className',
	[HtmlAttribute.WIDTH]: 'width',
	[HtmlAttribute.HEIGHT]: 'height',
	[HtmlAttribute.SRC]: 'src',
	[HtmlAttribute.CELLPADDING]: 'cellPadding',
	[HtmlAttribute.CELLSPACING]: 'cellSpacing',
	[HtmlAttribute.BORDER]: 'border',
	[HtmlAttribute.ROWSPAN]: 'rowSpan',
	[HtmlAttribute.COLSPAN]: 'colSpan',
	[HtmlAttribute.HREF]: 'href',
	[HtmlAttribute.BACKGROUND]: 'background',
	[HtmlAttribute.BGCOLOR]: 'bgcolor',
	[HtmlAttribute.VALIGN]: 'valign'
};

export const INLINE_NODE = {
	[ElementType.A]: true,
	[ElementType.SPAN]: true
};
export const VOID_NODE = {
	[ElementType.HR]: true,
	[ElementType.IMG]: true
};

export const HTML_ATTRIBUTES = Object.keys(HTML_ATTRIBUTE_MAP);
export const REACT_ATTRIBUTES = Object.values(HTML_ATTRIBUTE_MAP);

export const NEW_LINE = '\n';

const TOOLBAR_ACTION_MARK = {
	action: 'ToggleMark',
	name: 'Toggling mark: <%= value %>'
};
const TOOLBAR_ACTION_BLOCK = {
	action: 'ChangeBlock',
	name: 'Changing block to <%= value %>'
};
export const TOOLBAR_ACTION_TRACKING_MAP = {
	[LeafType.BOLD]: TOOLBAR_ACTION_MARK,
	[LeafType.ITALIC]: TOOLBAR_ACTION_MARK,
	[LeafType.STRIKETHROUGH]: TOOLBAR_ACTION_MARK,
	[LeafType.UNDERLINE]: TOOLBAR_ACTION_MARK,
	[LeafType.CODE]: TOOLBAR_ACTION_MARK,

	[ElementType.UL]: TOOLBAR_ACTION_BLOCK,
	[ElementType.OL]: TOOLBAR_ACTION_BLOCK,
	[ElementType.BLOCKQUOTE]: TOOLBAR_ACTION_BLOCK,
	[ElementType.H1]: TOOLBAR_ACTION_BLOCK,
	[ElementType.H2]: TOOLBAR_ACTION_BLOCK,
	[ElementType.H3]: TOOLBAR_ACTION_BLOCK,
	[ElementType.PRE]: TOOLBAR_ACTION_BLOCK,
	[ElementType.IMG]: {
		action: 'InsertImage',
		name: `Inserting image with src: <%= value %>`
	},
	[ElementType.A]: {
		action: 'ToggleLink',
		name: 'Toggling link with href: <%= value %>'
	},
	redo: {
		action: 'RedoClick',
		name: 'Redo icon has been clicked'
	},
	undo: {
		action: 'UndoClick',
		name: 'Undo icon has been clicked'
	}
};

// Based on reserved and unreserved allowed charecters from URI generic syntax description
// https://tools.ietf.org/html/rfc3986#section-2
export const URL_REGEXP_PATTERN = /^[\w:\/#\[\]@!$&'()*+,;=~\-?\.]+\.[\w:\/#\[\]@!$&'()*+,;=~\-?]+$/;
export const HTML_PATTERN = /<[^>]*>/gi;

// Rules that apply inside Word/Excel but don't give the same result if used outside of it (e.g. Outlook)
// https://support.dataart.com/browse/IPP-29793
export const IGNORE_CSS_RULE_LIST = ['text-indent'];
