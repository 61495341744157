import { ThreadsState } from 'store/threads/threadsState';
import { FullScreenMode } from 'services/api/threads/threadsServiceTypes';

export const onUpdateFullScreenMode = (
	state: ThreadsState,
	payload: FullScreenMode
): ThreadsState => ({
	...state,
	...payload
});
