import { Success } from 'typescript-fsa';
import { CompaniesState, initialState } from '../companiesState';
import { RetrieveCompaniesConfigurationSettingsResponse } from 'services/api/companies/companiesServiceTypes';

export const onRetrieveCompaniesConfigurationSettingsSuccess = (
	state: CompaniesState,
	{ result }: Success<null, RetrieveCompaniesConfigurationSettingsResponse>
) => ({
	...state,
	configurationSettings: result
});

export const onResetCompaniesConfigurationSettings = (
	state: CompaniesState
) => ({
	...state,
	configurationSettings: initialState.configurationSettings
});
