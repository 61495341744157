/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import AntSelect, {
	OptGroupProps,
	OptionProps,
	SelectProps as AntSelectProps,
	SelectValue
} from 'antd/lib/select';
import { Icon } from 'components/antd';
import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';
import Loading from 'components/Loading/Loading';
import MaybeElement from 'utils/MaybeElement';
import styles from './Select.module.scss';
import uuidv4 from 'uuid/v4';
import { LabeledValue } from 'app-types';
import { DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT } from 'app-constants';

export interface SelectProps extends AntSelectProps {
	isLoading?: boolean;
	enableSameOptionChangeDetection?: boolean;
	containerClassName?: string;
	dataQa?: string; // for QAA
	onChange?: (
		value: SelectValue | null,
		option: React.ReactElement<any> | Array<React.ReactElement<any>>
	) => void;
}

export interface SelectOptionProps extends OptionProps {
	children: React.ReactNode;
}

export interface SelectOption {
	name: string;
	id: string;
	/**
	 * Legacy from previous <Dropdown /> component
	 * @deprecated in v1.0
	 */
	label?: string;
	value?: string;
}

export class Select extends React.Component<SelectProps, {}> {
	static defaultProps: Partial<SelectProps> = AntSelect.defaultProps && {
		notFoundContent: DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT
	};
	static Option: React.ComponentClass<OptionProps> = AntSelect.Option;
	static OptGroup: React.ComponentClass<OptGroupProps> = AntSelect.OptGroup;
	static ReduxFormItem: CreateReduxField<SelectProps>;

	select: AntSelect | null;
	/** Generate a dynamic Id */
	popupContainerId = `select-container-id-${uuidv4()}`;

	onSelect: SelectProps['onSelect'] = (value, option) => {
		if (this.props.onSelect) {
			this.props.onSelect(value, option);
		}

		// Hide dropdown after option selection unless there is a possibility to select more than 1 option
		if (
			this.select &&
			this.props.mode !== 'multiple' &&
			this.props.mode !== 'tags'
		) {
			MaybeElement.of(
				(ReactDOM.findDOMNode(this.select) as Element).querySelector('input')
			).do(input => input.blur());
		}

		/**
		 * Check if the same option but with
		 * new label (e.g. updated from MasterData) is selected
		 */
		const { enableSameOptionChangeDetection } = this.props;
		if (enableSameOptionChangeDetection) {
			const prevValue = this.props.value as LabeledValue;
			const currentValue = value as LabeledValue;
			if (!prevValue || !currentValue) {
				return;
			}
			if (
				prevValue.key === currentValue.key &&
				prevValue.label !== currentValue.label &&
				this.onChange
			) {
				this.onChange(value, option);
			}
		}
	};

	onChange = (value: SelectValue, option: React.ReactElement<any>) => {
		if (this.props.onChange) {
			this.props.onChange(value || null, option);
		}
	};

	render() {
		const { containerClassName, style, dataQa, ...selectProps } = this.props;
		return (
			<div
				id={this.popupContainerId}
				className={classNames(styles.container, containerClassName)}
				data-qa={dataQa}
			>
				<AntSelect
					ref={ref => (this.select = ref)}
					getPopupContainer={() =>
						document.getElementById(this.popupContainerId) as HTMLElement
					}
					{...selectProps}
					onSelect={this.onSelect}
					onChange={this.onChange}
					notFoundContent={
						this.props.isLoading ? (
							<Loading size="xs" />
						) : (
							this.props.notFoundContent
						)
					}
					style={style}
					className={classNames(this.props.className, styles.root)}
					suffixIcon={<Icon type="caret-down" />}
					clearIcon={<Icon type="close" />}
					removeIcon={<Icon type="close" />}
					menuItemSelectedIcon={<Icon type="check" />}
				/>
			</div>
		);
	}
}

/**
 * ReduxForm Input connector
 */
type SelectMapProps = SelectProps & {
	multiple?: boolean;
	options?: SelectOption[];
};

export function createSelectFieldMap<P extends SelectMapProps>() {
	return customMap<P>(props => {
		const value = props.input.value || props.defaultValue;
		const options = props.options;
		if (options && options.length > 0) {
			if (value) {
				return value;
			}
			if (props.multiple) {
				return [options[0].value];
			}
			return options[0].value;
		}
		return {
			value
		};
	});
}

export const selectFieldMap = createSelectFieldMap();

Select.ReduxFormItem = createFieldComponent<SelectProps>(
	Select,
	selectFieldMap
);

export default Select;
