import { get, findIndex, some } from 'lodash';
import {
	FormData,
	CargoLineFormDataTypes
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import uuidv4 from 'uuid/v4';
import numeral from 'numeral';
import { CompanyDraftResponse } from 'services/api/portJobs/portJobsServiceTypes';

export const isChartererAvailableForDelete = (
	cargoes: FormData['cargoes'],
	chartererCompanyId: string
) => {
	return (
		chartererCompanyId !== '' &&
		cargoes.filter(
			cargo => get(cargo.chartererCompany, 'key') === chartererCompanyId
		).length < 2
	);
};

export const getCargoIndex = (cargoes: FormData['cargoes'], id: string) =>
	findIndex(cargoes, { id });

export const getChartererIndex = (
	charterers: FormData['charterers'],
	chartererCompanyId: string
) => findIndex(charterers, { chartererCompanyId });

export const getFormattedCargoLineValues = (
	cargoLine: CargoLineFormDataTypes
) => ({
	...cargoLine,
	// storing id inside redux-form for manipulating cargo, will be removed before sending
	id: cargoLine.id ? cargoLine.id : uuidv4() + '[generated]'
});

export const getIsDuplicateCharterer = (
	portJobCompanies: CompanyDraftResponse[],
	cargoLine: CargoLineFormDataTypes,
	charterers: FormData['charterers']
) => {
	const portJobCompany = portJobCompanies.find(
		company =>
			!!cargoLine.chartererCompany &&
			cargoLine.chartererCompany.key === company.companyId
	);
	// checking for duplicates in portJobCompany list
	const isDuplicateByComapny = portJobCompany
		? some(charterers, { chartererCompanyId: portJobCompany.id })
		: portJobCompany;
	return (
		!cargoLine.chartererCompany ||
		some(
			charterers,
			charterer =>
				!!cargoLine.chartererCompany &&
				cargoLine.chartererCompany.key === charterer.chartererCompanyId
		) ||
		isDuplicateByComapny
	);
};

export const getDefaultCharterer = (cargoLine: CargoLineFormDataTypes) => ({
	operationalInstructions: '',
	isWorldScaleServicesApplied: false,
	isOtherRebillablesApplied: false,
	timeBarisApplied: true,
	maxPortCost: false,
	costSettlementIsDirect: 'true',
	maxPortCostCurrency: 'usd',
	chartererCompanyId: cargoLine.chartererCompany
		? cargoLine.chartererCompany.key
		: ''
});

export const getTotalQuantity = (
	cargoes: FormData['cargoes'],
	parentCargoIndex: number,
	childCargoIndex: number
) => {
	const childQuantity = cargoes[childCargoIndex].commodityQuantity;
	const parentQuantity = cargoes[parentCargoIndex].commodityQuantity;
	return (
		numeral(parentQuantity).value() + numeral(childQuantity).value()
	).toString();
};

export const getChartererIndexToRemove = (
	cargoes: FormData['cargoes'],
	charterers: FormData['charterers'],
	editableCargoId: string,
	cargoLineValues: CargoLineFormDataTypes,
	portJobCompanies: CompanyDraftResponse[]
) => {
	const cargoIndex = getCargoIndex(cargoes, editableCargoId);
	const prevCharterer = cargoes[cargoIndex].chartererCompany;
	/**
	 * no need to delete charterer if it wasn't changed
	 * (need to check this also by portJobCompanyId)
	 */
	const selectedCompanyId =
		cargoLineValues.chartererCompany && cargoLineValues.chartererCompany.key;
	const portJobCompanyBySelectedCompanyId = portJobCompanies.find(
		company => company.companyId === selectedCompanyId
	);
	const selectedPortJobCompanyId = portJobCompanyBySelectedCompanyId?.id;
	const previousCompanyId = prevCharterer?.key;
	if (
		selectedCompanyId === previousCompanyId ||
		selectedPortJobCompanyId === previousCompanyId
	) {
		return undefined;
	}
	// if charterer was changed to another, and previous should be removed from the list
	const chartererShouldBeRemoved =
		prevCharterer && isChartererAvailableForDelete(cargoes, prevCharterer.key);
	return chartererShouldBeRemoved && prevCharterer
		? getChartererIndex(charterers, prevCharterer.key)
		: undefined;
};
