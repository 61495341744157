import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import { DragOperationParams } from '../actions/dragOperation';

export const onDragOperationUnit = (
	state: VesselProgrammeState,
	{ id }: DragOperationParams
): VesselProgrammeState => ({
	...state,
	draggedOperationUnitId: id
});
