import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import {
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { BankAccountsState } from './../bankAccountsState';
import { keyBy } from 'lodash';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

export function onRetrieveBankAccountsSuccess(
	state: BankAccountsState,
	action: Success<RetrieveBankAccountsRequest, RetrieveBankAccountsResponse>
): BankAccountsState {
	const { elements } = action.result;
	return {
		...state,
		indexForNextFetch: state.indexForNextFetch + elements.length,
		hasMore: elements.length === (action.params.limit || DEFAULT_LIST_LIMIT),
		byId: {
			...state.byId,
			...keyBy(elements, item => item.id)
		}
	};
}

export const onRetrieveBankAccounts = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse
>('fetchStatuses', 'byId', {
	doneReducer: onRetrieveBankAccountsSuccess,
	failedReducer: state => ({
		...state,
		hasMore: false
	})
});
