import { FetchStatus } from 'services/api/apiTypes';
import { ContextType } from 'services/api/threads/threadsServiceTypes';
import { ThreadReducerState } from '../threadState';
import { Success } from 'typescript-fsa';
import { UpdateContextParams } from 'services/api/messages/messagesServiceTypes';

export function onUpdateContextStarted(
	state: ThreadReducerState
): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}
export function onUpdateContextSuccess(
	state: ThreadReducerState,
	action: Success<UpdateContextParams, ContextType | null>
): ThreadReducerState {
	if (state.item === null) {
		return {
			...state,
			fetchStatuses: {
				...state.fetchStatuses,
				updating: FetchStatus.SUCCESS
			}
		};
	}
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		},
		item: {
			...state.item,
			context: action.params.threadContext
		}
	};
}
export function onUpdateContextFailed(
	state: ThreadReducerState
): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}
