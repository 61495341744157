import React, { useCallback, useState, useEffect } from 'react';
import { Collapse } from 'components/antd';
import styles from './JobDetails.module.scss';
import { ShowMoreBlock } from 'components/ReadMore';
import { UserType } from 'services/api/users/userServiceTypes';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch } from 'react-redux';
import { updateSavings } from 'store/portJobs/actions';

interface InstructionProps {
	instructionsToHUB: string;
	instructionsToLPA: string;
	userType: UserType;
	savings: string | undefined;
}

export default ({
	instructionsToHUB,
	instructionsToLPA,
	userType,
	savings
}: InstructionProps) => {
	useEffect(() => {
		setSavings(savings);
	}, [savings]);
	const [savingsText, setSavings] = useState(savings);
	const dispatch = useDispatch();
	const onUpdateSavings = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			if (e.target.value !== savings) {
				dispatch(updateSavings({ savings: e.target.value }));
			}
		},
		[dispatch, savings]
	);
	const title =
		userType === UserType.LPA
			? 'INSTRUCTIONS TO LPA'
			: 'INSTRUCTIONS TO HUB / LPA';
	return (
		<Collapse className={styles.instructionsCollapse}>
			<Collapse.Panel header={<span>{title}</span>} key="1">
				{userType !== UserType.LPA && (
					<div className={styles.content}>
						<h4>Instructions to HUB</h4>
						<ShowMoreBlock>
							<p>{instructionsToHUB}</p>
						</ShowMoreBlock>
					</div>
				)}
				<div className={styles.content}>
					<h4>Instructions to LPA</h4>
					<ShowMoreBlock>
						<p>{instructionsToLPA}</p>
					</ShowMoreBlock>
				</div>
				{userType === UserType.HUB && (
					<div className={styles.updateSavings}>
						<h4>Savings</h4>
						<TextArea
							value={savingsText}
							onChange={e => setSavings(e.target.value)}
							maxLength={5000}
							onBlur={onUpdateSavings}
						/>
					</div>
				)}
			</Collapse.Panel>
		</Collapse>
	);
};
