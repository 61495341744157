import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { FundingRequestsState } from '../financeFundingsState';
import {
	RetrieveNewFundingDataRequest,
	RetrieveNewFundingDataResponse,
	FundingRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { NEW_FUNDING_ID } from '../financeFundingsConstants';

export const onRetrieveNewFundingDataSuccess = (
	state: FundingRequestsState,
	{
		params,
		result
	}: Success<RetrieveNewFundingDataRequest, RetrieveNewFundingDataResponse>
): FundingRequestsState => {
	const newFunding = { ...result } as FundingRequest;
	return {
		...state,
		context: {
			...state.context,
			daId: params.daId
		},
		allIds: [NEW_FUNDING_ID],
		byId: {
			...state.byId,
			[NEW_FUNDING_ID]: newFunding
		}
	};
};

export const retrieveNewFundingData = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	RetrieveNewFundingDataRequest,
	RetrieveNewFundingDataResponse
>('fetchStatuses', 'retrieveNewFundingData', {
	doneReducer: onRetrieveNewFundingDataSuccess
});
