import * as React from 'react';
import { connect } from 'react-redux';
import AutocompleteEntity, {
	AutocompleteEntityProps
} from '../AutocompleteEntity';
import Api from 'services/api';
import { getCurrentPortCallPortId } from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import { DEFAULT_AUTOCOMPLETE_PLACEHOLDER } from 'app-constants';
import { AppState } from 'store-types';

interface AutocompletePortLPAProps extends AutocompleteEntityProps {
	currentPortId: string;
}

class AutocompletePortLPA extends React.PureComponent<
	AutocompletePortLPAProps
> {
	onSearch = (searchTerm: string) =>
		Api.Ports.searchPortLPAsForAutocomplete({
			searchTerm,
			portId: this.props.currentPortId
		});

	render() {
		const { currentPortId, ...props } = this.props;
		return (
			<AutocompleteEntity
				{...props}
				onSearch={this.onSearch}
				disabled={!currentPortId}
				placeholder={
					currentPortId
						? DEFAULT_AUTOCOMPLETE_PLACEHOLDER
						: `There's no Port defined`
				}
			/>
		);
	}
}

export default connect(
	(
		state: AppState,
		ownProps: Pick<AutocompletePortLPAProps, 'currentPortId'>
	) => ({
		currentPortId: ownProps.currentPortId || getCurrentPortCallPortId(state)
	})
)(AutocompletePortLPA);
