import client from 'services/client';
import {
	RetrievePortCallSectionsResponse,
	RetrievePortCallSectionsRequest,
	EndOfSeaPassageRequest,
	CommenceOfSeaPassageRequest,
	UpdatePortCallEventRequest,
	RetrievePortCallEventTemplatesRequest,
	RetrievePortCallEventTemplatesResponse,
	AddPortCallEventRequest,
	AddPortCallEventResponse,
	DeletePortCallEventRequest,
	RestorePortCallEventRequest,
	UpdatePortCallRequest,
	RetrievePortCallAccessiblePortJobsResponse,
	UpdatePortCallEventJobAssignmentRequest,
	RetrievePortCallCompanyVesselsResponse,
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse,
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse,
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse
} from './portCallServiceTypes';

export const retrievePortCallSections = ({
	portCallId
}: RetrievePortCallSectionsRequest) =>
	client.get<RetrievePortCallSectionsResponse>(
		`/portcalls/${portCallId}/events/sections`
	);

export const endOfSeaPassage = ({
	portCallId,
	eventCode,
	etsPlt
}: EndOfSeaPassageRequest) => {
	return client.post(`/portcalls/${portCallId}/events/${eventCode}/record`, {
		realDatePlt: etsPlt
	});
};

export const commenceOfSeaPassage = ({
	portCallId,
	eventCode,
	etsPlt
}: CommenceOfSeaPassageRequest) => {
	return client.post(`/portcalls/${portCallId}/events/${eventCode}/record`, {
		realDatePlt: etsPlt
	});
};

export const updatePortCallEvent = (request: UpdatePortCallEventRequest) => {
	const { portCallId, eventId, ...params } = request;
	return client.post(
		`/portcalls/${portCallId}/events/${eventId}/record`,
		params
	);
};

export const retrievePortCallEventTemplates = ({
	portCallId,
	parentId,
	parentType
}: RetrievePortCallEventTemplatesRequest) => {
	return client.get<
		RetrievePortCallEventTemplatesResponse
	>(`/portcalls/${portCallId}/events/templates`, {
		params: {
			parentId,
			parentType
		}
	});
};

export const addPortCallEvent = ({
	portCallId,
	eventCode,
	parentId,
	parentType
}: AddPortCallEventRequest) => {
	return client.post<
		AddPortCallEventResponse
	>(`/portcalls/${portCallId}/events`, {
		eventCode,
		parentId,
		parentType
	});
};

const deletePortCallEvent = ({
	eventId,
	portCallId,
	...params
}: DeletePortCallEventRequest) => {
	return client.delete(`/portcalls/${portCallId}/events/${eventId}`, {
		params
	});
};

const restorePortCallEvent = ({
	eventId,
	portCallId,
	...params
}: RestorePortCallEventRequest) => {
	return client.post(
		`/portcalls/${portCallId}/events/${eventId}/restore`,
		params
	);
};

const updatePortCall = ({ portCallId, payload }: UpdatePortCallRequest) =>
	client.patch(`/portcalls/${portCallId}`, payload);

const retrievePortCallAccessiblePortJobs = (portCallId: string) =>
	client.get<
		RetrievePortCallAccessiblePortJobsResponse
	>(`/portcalls/${portCallId}`, {
		params: {
			expand: 'PortJobsCompany',
			onlyAccepted: true
		}
	});

const updatePortCallEventJobAssignment = ({
	portCallId,
	eventId,
	portJobs,
	concurrencyToken
}: UpdatePortCallEventJobAssignmentRequest) =>
	client.patch(`/portcalls/${portCallId}/events/${eventId}/jobs`, {
		portJobCodes: portJobs.map(portJob => ({ id: portJob.code })),
		concurrencyToken
	});

const retrievePortCallDuplicates = ({
	portCallId
}: RetrievePortCallDuplicatesRequest) =>
	client.get<RetrievePortCallDuplicatesResponse>(
		`/portcalls/${portCallId}/duplicates`
	);

const retrievePortCallCompanyVessels = ({
	portCallId
}: RetrievePortCallCompanyVesselsRequest) =>
	client.get<RetrievePortCallCompanyVesselsResponse>(
		`/portcalls/${portCallId}/vessel-info`
	);

const retrievePortCallMetadata = ({
	portCallId
}: RetrievePortCallMetadataRequest) =>
	client.get<RetrievePortCallMetadataResponse>(
		`/portcalls/${portCallId}/metadata`
	);

const retrievePortCallActAs = ({ portCallId }: RetrievePortCallActAsRequest) =>
	client.get<RetrievePortCallActAsResponse>(`/portcalls/${portCallId}/act-as`);

export default {
	retrievePortCallSections,
	retrievePortCallEventTemplates,
	endOfSeaPassage,
	commenceOfSeaPassage,
	updatePortCallEvent,
	addPortCallEvent,
	deletePortCallEvent,
	restorePortCallEvent,
	updatePortCall,
	retrievePortCallAccessiblePortJobs,
	updatePortCallEventJobAssignment,
	retrievePortCallCompanyVessels,
	retrievePortCallDuplicates,
	retrievePortCallMetadata,
	retrievePortCallActAs
};
