import { ConfigurationTabKey } from '../ConfigurationTypes';
import {
	CompanyGeneralConfigurationDetails,
	CompanyISSOperatorContact,
	CompanyFundingSettings,
	PrincipalMapping,
	CompanyConfigurationEmail
} from 'services/api/companies/companiesServiceTypes';
export const GENERAL_DETAILS_FORM_NAME = ConfigurationTabKey.GENERAL_DETAILS;

export enum CustomerAlertOption {
	NO = 'No',
	GENERIC = 'Generic',
	VESSEL_SPECIFIC = 'VesselSpecific'
}

export enum NominationOption {
	WAIT = 'true',
	DONT_WAIT = 'false'
}

export enum ExchangeRate {
	FIXED_RATE = 'FixedRate',
	BLENDED_RATE = 'BlendedRate'
}

export enum JobType {
	APPOINTMENT = 'Appointment',
	NOMINATION = 'Nomination',
	CHARTERERS_OVERSIGHT = 'Charterers Oversight'
}

export enum FormField {
	USES_EXCHANGE_RATE = 'usesExchangeRate',
	IS_APPROVAL_REQUIRED = 'isApprovalRequired',
	IS_MULTILEVEL_APPROVAL_REQUIRED = 'isMultilevelApprovalRequired',
	MULTILEVEL_APPROVAL_LEVELS = 'levels',
	MULTILEVEL_APPROVAL_LEVEL = 'level',
	MULTILEVEL_APPROVAL_THRESHOLD_VALUE = 'thresholdValue',
	IS_BYPASS_APPROVAL_REQUIRED = 'isBypassApprovalRequired',
	IS_BYPASS_PRINICIPAL_REAPPROVAL_REQUIRED = 'isBypassPrincipalReApprovalRequired',
	IS_MANDATE_APPROVAL_REQUIRED = 'isMandateApprovalRequired',
	BYPASS_TOTAL_AMOUNT = 'bypassTotalAmount',
	FIELD_TO_SHOW_BYPASS_ERROR = 'fieldToShowBypassError',

	APPOINTMENT = 'appointmentIsUsed',
	NOMINATION = 'nominationIsUsed',
	CHARTERERS_OVERSIGHT = 'charterersOversightIsUsed',

	IS_FINANCE_DISABLED_APPOINTMENT = 'isAppointmentFinanceDisabled',
	IS_FINANCE_DISABLED_NOMINATION = 'isNominationFinanceDisabled',
	IS_FINANCE_DISABLED_CHARTERERS = 'isChartererOversightFinanceDisabled',
	IS_OPERATIONS_DISABLED_APPOINTMENT = 'isAppointmentOperationsDisabled',
	IS_OPERATIONS_DISABLED_NOMINATION = 'isNominationOperationsDisabled',
	IS_OPERATIONS_DISABLED_CHARTERERS_OVERSIGHT = 'isChartererOversightOperationsDisabled',

	NOMINATION_OPTION = 'nominationOption',

	DEFAULT_BANK_ACCOUNT_CURRENCY_CODE = 'defaultBankAccountCurrencyCode',

	SEND_CUSTOMER_ALERTS_OPTION = 'sendCustomerAlertsOption',

	ACCESS_TO_PORTCALL_MESSAGES_ENABLED = 'accessToPortCallMessagesEnabled',

	EMAIL_SETTINGS_CENTRAL_INBOX_EMAIL = 'centralInboxEmail',
	EMAIL_SETTINGS_SECONDARY_MAILBOXES = 'secondaryMailboxes',
	EMAIL_SETTINGS_EMAIL = 'email',
	EMAIL_SETTINGS_EMAIL_NAME = 'emailName',
	EMAIL_SETTINGS_GROUP = 'group',
	EMAIL_SETTINGS_EMAIL_ENABLED = 'isEnabled',
	EMAIL_SETTINGS_SPLIT_THREAD = 'isSplitThreadByGroup',

	SEND_FUNDING = 'sendFunding',
	FUNDING_PERCENTAGE = 'fundingPercentage',
	FUNDING_STAGE = 'fundingStage',

	REGION = 'unRegion',
	EMAIL = 'email',
	TELEPHONE = 'telephone',

	READY_TO_GO = 'isReadyToGo',

	APPOINTMENT_ACCEPTANCE_INSTRUCTION = 'appointmentAcceptanceInstruction',
	APPOINTMENT_FROM_NOMINATION_INSTRUCTION = 'nominationAcceptanceInstruction',

	HAS_ACCESS_TO_REPORT_SERVER = 'hasAccessToReportServer',

	IS_PRINCIPAL_INTEGRATION_ENABLED = 'isPrincipalIntegrationEnabled',
	PRINCIPAL_INTEGRATION_USER = 'integrationUserId',
	PRINCIPAL_INTEGRATION_GENERATE_TOKEN = 'generateToken',

	INCLUDE_OPERATIONAL_DATA = 'includeOperationalData',
	ALLOW_OPERATIONAL_DASHBOARD = 'allowOperationalDashboard',
	ALLOW_FINANCIAL_DASHBOARD = 'allowFinancialDashboard'
}

export enum FormSectionField {
	PDA_APPROVAL_SETTINGS = 'pdaApprovalSettings',
	CE_APPROVAL_SETTINGS = 'ceApprovalSettings',
	DA_APPROVAL_SETTINGS = 'daApprovalSettings',
	DETAILS = 'details',
	COMMON_SETTINGS = 'commonSettings',
	EMAIL_SETTINGS = 'emailSettings',
	FUNDING_SETTINGS = 'fundingSettings',
	ISS_OPERATOR_CONTACTS = 'issOperatorContacts',
	OPERATIONS_MAPPING = 'operationsMapping',
	TAG_MAPPING = 'tagMapping',
	REPORT_SETTINGS = 'reportsSettings',
	BI_DASHBOARD_CONFIGURATION = 'biDashboardConfiguration',
	PRINCIPAL_INTEGRATION = 'principalIntegrationSettings'
}

export interface FormData {
	isReadyToGo: boolean;
	details: FormDataDetails;
	fieldToShowBypassError?: '2ndLevel' | 'bypassTotal';
}

export type FormDataDetails = Pick<
	CompanyGeneralConfigurationDetails,
	| 'reportsSettings'
	| 'commonSettings'
	| 'sendCopyToOperatorsEmail'
	| 'appointmentAcceptanceInstruction'
	| 'biDashboardConfiguration'
	| 'principalIntegrationSettings'
	| 'nominationAcceptanceInstruction'
> & {
	emailSettings: FormDataEmailSettings;
	fundingSettings: FormDataFundingSettings;
	issOperatorContacts: FormDataISSOperatorContact[];
	operationsMapping: FormDataPrincipalMapping[];
	tagMapping: FormDataPrincipalMapping[];
};

interface FormDataEmailSettingsEmailBase {
	/**
	 * Custom field containing `centralInboxEmail` mailbox name (first part)
	 */
	emailName: string;
}

interface FormDataEmailSettings {
	centralInboxEmail: FormDataGeneralDetailsEmail;
	secondaryMailboxes: FormDataGeneralDetailsEmail[];
}

type FormDataFundingSettings = Pick<
	CompanyFundingSettings,
	'sendFunding' | 'fundingStage'
> & {
	fundingPercentage?: string;
};

export type FormDataGeneralDetailsEmail = CompanyConfigurationEmail &
	FormDataEmailSettingsEmailBase;
export type FormDataISSOperatorContact = CompanyISSOperatorContact;
export type FormDataPrincipalMapping = PrincipalMapping;

export interface ApprovalFieldConfig {
	label: string;
	fieldSectionName:
		| FormSectionField.PDA_APPROVAL_SETTINGS
		| FormSectionField.CE_APPROVAL_SETTINGS
		| FormSectionField.DA_APPROVAL_SETTINGS;
}
