import React from 'react';
import { connect } from 'react-redux';
import { getSplitOperationIndex } from 'store/portJobs/selectors';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import { Text } from 'components';
import { AppState } from 'store-types';

interface ReferenceNumberExternalProps {
	value: string | undefined;
	id: string;
}

type ReferenceNumberProps = ReturnType<typeof mapStateToProps> &
	ReferenceNumberExternalProps;

const ReferenceNumberColumn: React.SFC<ReferenceNumberProps> = ({
	value,
	splitIndex
}) => {
	return (
		<>
			<OptionalValue value={value}>{value}</OptionalValue>
			{splitIndex && (
				<>
					{` / `}
					<Text color="primary">{splitIndex}</Text>
				</>
			)}
		</>
	);
};

const mapStateToProps = (
	state: AppState,
	props: ReferenceNumberExternalProps
) => ({
	splitIndex: getSplitOperationIndex(state, props.id)
});

export default connect(mapStateToProps)(ReferenceNumberColumn);
