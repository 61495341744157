import { resetActiveSupplierInvoiceId } from './actions/setActiveSupplierInvoiceId';

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './supplierInvoiceState';

// Actions
import {
	retrieveSupplierInvoicesAsync,
	resetSupplierInvoices,
	createSupplierInvoiceAsync,
	retrieveSupplierInvoiceContextualizationAsync,
	retrieveSupplierInvoiceContextualizationForUpdateAsync,
	downloadSupplierInvoiceAsync,
	retrieveSupplierInvoiceJobAsync,
	setActiveSupplierInvoiceId,
	retrieveSupplierInvoiceAsync,
	updateSupplierInvoiceAsync,
	resetActiveSupplierInvoice,
	setPortJobsInSupplierInvoiceContextualization,
	cancelCreditSupplierInvoiceAsync,
	retrieveSupplierInvoiceDocumentAsync,
	setIsSupplierInvoiceContextualization,
	retrieveSplitSupplierInvoiceDocumentAsync,
	downloadSplitSupplierInvoiceAsync,
	deleteSupplierDocumentPageAsync
} from './actions';

// Reducers
import {
	onRetrieveSupplierInvoices,
	onResetSupplierInvoices,
	createSupplierInvoice,
	onRetrieveSupplierInvoiceContextualization,
	onDownloadSupplierInvoice,
	onRetrieveSupplierInvoiceJob,
	onSetActiveSupplierInvoiceId,
	onResetActiveSupplierInvoiceId,
	onRetrieveSupplierInvoice,
	onUpdateSupplierInvoice,
	onResetActiveSupplierInvoice,
	onSetPortJobsInSupplierInvoiceContextualization,
	onCancelCreditSupplierInvoice,
	onRetrieveSupplierInvoiceDocument,
	onSetIsSupplierInvoiceContextualization,
	onRetrieveSplitSupplierInvoiceDocument,
	onDeleteSupplierInvoiceDocumentPageFailed,
	onDeleteSupplierInvoiceDocumentPageStarted,
	onDeleteSupplierInvoiceDocumentPageSuccess
} from './reducers';

export default reducerWithInitialState(initialState)
	.case(
		retrieveSupplierInvoicesAsync.started,
		onRetrieveSupplierInvoices.started
	)
	.case(retrieveSupplierInvoicesAsync.done, onRetrieveSupplierInvoices.done)
	.case(retrieveSupplierInvoicesAsync.failed, onRetrieveSupplierInvoices.failed)
	// Retrieve Single Supplier Invoice
	.case(retrieveSupplierInvoiceAsync.started, onRetrieveSupplierInvoice.started)
	.case(retrieveSupplierInvoiceAsync.done, onRetrieveSupplierInvoice.done)
	.case(retrieveSupplierInvoiceAsync.failed, onRetrieveSupplierInvoice.failed)
	// Retrieve Single Supplier Invoice Document
	.case(
		retrieveSupplierInvoiceDocumentAsync.started,
		onRetrieveSupplierInvoiceDocument.started
	)
	.case(
		retrieveSupplierInvoiceDocumentAsync.done,
		onRetrieveSupplierInvoiceDocument.done
	)
	.case(
		retrieveSupplierInvoiceDocumentAsync.failed,
		onRetrieveSupplierInvoiceDocument.failed
	)
	// Reset Supplier Invoices
	.case(resetSupplierInvoices, onResetSupplierInvoices)
	// Create Supplier Invoice
	.case(createSupplierInvoiceAsync.started, createSupplierInvoice.started)
	.case(createSupplierInvoiceAsync.done, createSupplierInvoice.done)
	.case(createSupplierInvoiceAsync.failed, createSupplierInvoice.failed)
	// Retrieve Supplier Invoice Contextualization
	.case(
		retrieveSupplierInvoiceContextualizationAsync.started,
		onRetrieveSupplierInvoiceContextualization.started
	)
	.case(
		retrieveSupplierInvoiceContextualizationAsync.done,
		onRetrieveSupplierInvoiceContextualization.done
	)
	.case(
		retrieveSupplierInvoiceContextualizationAsync.failed,
		onRetrieveSupplierInvoiceContextualization.failed
	)
	.case(
		retrieveSupplierInvoiceContextualizationForUpdateAsync.started,
		onRetrieveSupplierInvoiceContextualization.started
	)
	.case(
		retrieveSupplierInvoiceContextualizationForUpdateAsync.done,
		onRetrieveSupplierInvoiceContextualization.done
	)
	.case(
		retrieveSupplierInvoiceContextualizationForUpdateAsync.failed,
		onRetrieveSupplierInvoiceContextualization.failed
	)
	.case(
		setPortJobsInSupplierInvoiceContextualization,
		onSetPortJobsInSupplierInvoiceContextualization
	)
	// Download Supplier Invoice
	.case(downloadSupplierInvoiceAsync.started, onDownloadSupplierInvoice.started)
	.case(downloadSupplierInvoiceAsync.done, onDownloadSupplierInvoice.done)
	.case(downloadSupplierInvoiceAsync.failed, onDownloadSupplierInvoice.failed)
	// Retrieve Supplier Invoice Job
	.case(
		retrieveSupplierInvoiceJobAsync.started,
		onRetrieveSupplierInvoiceJob.started
	)
	.case(retrieveSupplierInvoiceJobAsync.done, onRetrieveSupplierInvoiceJob.done)
	.case(
		retrieveSupplierInvoiceJobAsync.failed,
		onRetrieveSupplierInvoiceJob.failed
	)
	.case(setActiveSupplierInvoiceId, onSetActiveSupplierInvoiceId)
	.case(resetActiveSupplierInvoiceId, onResetActiveSupplierInvoiceId)
	.case(resetActiveSupplierInvoice, onResetActiveSupplierInvoice)
	// Update Supplier Invoice
	.case(updateSupplierInvoiceAsync.started, onUpdateSupplierInvoice.started)
	.case(updateSupplierInvoiceAsync.done, onUpdateSupplierInvoice.done)
	.case(updateSupplierInvoiceAsync.failed, onUpdateSupplierInvoice.failed)
	// Cancel Credit Supplier Invoice
	.case(
		cancelCreditSupplierInvoiceAsync.started,
		onCancelCreditSupplierInvoice.started
	)
	.case(
		cancelCreditSupplierInvoiceAsync.done,
		onCancelCreditSupplierInvoice.done
	)
	.case(
		cancelCreditSupplierInvoiceAsync.failed,
		onCancelCreditSupplierInvoice.failed
	)
	.case(
		setIsSupplierInvoiceContextualization,
		onSetIsSupplierInvoiceContextualization
	)
	.case(
		retrieveSplitSupplierInvoiceDocumentAsync.started,
		onRetrieveSplitSupplierInvoiceDocument.started
	)
	.case(
		retrieveSplitSupplierInvoiceDocumentAsync.done,
		onRetrieveSplitSupplierInvoiceDocument.done
	)
	.case(
		retrieveSplitSupplierInvoiceDocumentAsync.failed,
		onRetrieveSplitSupplierInvoiceDocument.failed
	)
	// Download split Supplier Invoice
	.case(
		downloadSplitSupplierInvoiceAsync.started,
		onDownloadSupplierInvoice.started
	)
	.case(downloadSplitSupplierInvoiceAsync.done, onDownloadSupplierInvoice.done)
	.case(
		downloadSplitSupplierInvoiceAsync.failed,
		onDownloadSupplierInvoice.failed
	) // Delete split Supplier Invoice
	.case(
		deleteSupplierDocumentPageAsync.started,
		onDeleteSupplierInvoiceDocumentPageStarted
	)
	.case(
		deleteSupplierDocumentPageAsync.done,
		onDeleteSupplierInvoiceDocumentPageSuccess
	)
	.case(
		deleteSupplierDocumentPageAsync.failed,
		onDeleteSupplierInvoiceDocumentPageFailed
	);
