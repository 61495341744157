import * as React from 'react';
import { connect } from 'react-redux';

import WizardActions from 'store/wizardForm';

import { WizardFormPageState } from 'store/wizardForm/wizardFormState';

import {
	getWizardActivePage,
	getWizardActivePageState
} from 'store/wizardForm/wizardFormSelectors';

import { WizardConfigBase, InjectedWizardFormBaseProps } from './index';
import { AppState } from 'store-types';

export interface Config extends WizardConfigBase {
	page: number;
}

function createWizardFormStep<P>(initialConfig: Config) {
	return (WrappedComponent: React.ComponentType<P>) => {
		class WizardFormStep extends React.Component<
			P & InjectedWizardFormBaseProps
		> {
			render() {
				const propsToPass = {
					wizard: initialConfig.name
				};
				return <WrappedComponent {...this.props} {...propsToPass} />;
			}
		}

		// TODO
		// @see https://github.com/piotrwitek/react-redux-typescript-guide/issues/100
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const HOC = WizardFormStep as React.ComponentType<any>;

		return connect(
			(state: AppState) => ({
				activePage: getWizardActivePage(state),
				activePageState: getWizardActivePageState(state, initialConfig.name)
			}),
			dispatch => {
				const computedActions = {
					setActivePageState: (state: WizardFormPageState) => {
						dispatch(
							WizardActions.setActivePageState({
								name: initialConfig.name,
								...state
							})
						);
					},
					nextPage: () => {
						dispatch(
							WizardActions.nextPage({
								name: initialConfig.name
							})
						);
					}
				};

				return () => computedActions;
			}
		)(HOC);
	};
}

export default createWizardFormStep;
