import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import {
	Commodity,
	MainCommodity
} from 'services/api/commodities/commoditiesServiceTypes';

const getCommoditiesMapSelector = (
	state: AppState
): { [id: string]: Commodity } => state.commodities.byId;

const getCommodityIdsSelector = (state: AppState): string[] =>
	state.commodities.allIds;

export const getMainCommodities = (state: AppState): MainCommodity[] =>
	state.commodities.mainCommodities;

export const getAllCommoditiesSelector = createSelector(
	[getCommodityIdsSelector, getCommoditiesMapSelector],
	(ids, operations) => ids.map(id => operations[id])
);

export const getCommoditiesCount = createSelector(
	getAllCommoditiesSelector,
	commodities => commodities.length
);
