import * as React from 'react';
import { Flex } from 'components';
import { TextColor, ButtonType, IconColor } from 'components/types';
import { Button, Icon, Tabs, Popover, Table } from 'components/antd';
import { ButtonSize } from 'components/antd/Button/Button';
import { IconType } from 'components/antd/Icon/IconTypes';
import { TableProps } from 'components/antd/Table/Table';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { TabPaneProps } from 'antd/lib/tabs';
import uuidv4 from 'uuid/v4';
import classNames from 'classnames';
import styles from './DropdownTable.module.scss';

/** Intersecction type between TabPaneProps && <Table /> props that represents a DropdownTable Tab */
export interface DropdownTableTab<P> extends TabPaneProps {
	dataSource: TableProps<P>['dataSource'];
	columns: Array<ColumnProps<P>>;
	tab: string;
	id: string;
}

/** Array of DropdownTable Tabs */
export type DropdownTableTabs<P> = Array<DropdownTableTab<P>>;

export interface DropdownTableProps<P> {
	children: React.ReactNode;
	tabs: DropdownTableTabs<P>;
	size?: ButtonSize;
	onClick?: () => void;
	placement?: TooltipPlacement;
	buttonType?: TextColor & ButtonType;
	useFixedHeader?: boolean;
	fullWidth?: boolean;
	noContentMessage?: string;
	className?: string;
	icon?: keyof IconType;
	tableProps?: TableProps;
}

interface DropdownTableState {
	visible: boolean;
	activeKey?: string;
}

const ButtonTypeToIconColor: { [i in ButtonType]: IconColor } = {
	primary: 'primary',
	danger: 'error',
	standard: 'standard'
};

export default class DropdownTable<P = { id: string }> extends React.Component<
	DropdownTableProps<P>,
	DropdownTableState
> {
	static defaultProps: Partial<DropdownTableProps<{ id: string }>> = {
		buttonType: 'primary',
		placement: 'bottomLeft',
		useFixedHeader: true,
		fullWidth: false,
		noContentMessage: 'No Content To Display',
		size: 'xsmall'
	};

	state = {
		visible: false
	};

	/** Generate a dynamic Id */
	popoverContainerId = `dropdown-table-container-${uuidv4()}`;

	onVisibleChange = (visible: boolean) => {
		this.setState({ visible });
	};

	onTabChange = (activeKey: string) => {
		this.setState({ activeKey });
	};

	renderTable = (tab: DropdownTableTab<P>) => (
		<Table
			className={classNames({
				[styles.fullWidth]: this.props.fullWidth,
				[styles.fixedHeader]: this.props.useFixedHeader
			})}
			dataSource={tab.dataSource}
			columns={tab.columns}
			size="small"
			showHeader={!tab.columns.length} // Hide whitespace if empty header
			{...this.props.tableProps}
		/>
	);

	renderTabs = () => {
		const { tabs } = this.props;
		const hasTabs = Boolean(tabs.length);
		const hasMoreThanOneTab = tabs.length > 1;

		if (hasTabs) {
			if (!hasMoreThanOneTab) {
				return this.renderTable(tabs[0]);
			}
			return (
				<div
					className={classNames({
						[styles.tableWidth]: !this.props.fullWidth
					})}
				>
					<Tabs className={styles.tabs} onChange={this.onTabChange}>
						{tabs.map((tab: DropdownTableTab<P>) => (
							<Tabs.TabPane forceRender {...tab} key={tab.id}>
								{this.renderTable(tab)}
							</Tabs.TabPane>
						))}
					</Tabs>
				</div>
			);
		}

		return this.props.noContentMessage;
	};

	public render() {
		const { tabs } = this.props;
		const hasTabs = Boolean(tabs.length);

		const {
			buttonType,
			onClick,
			className,
			size,
			icon,
			children,
			...popoverProps
		} = this.props;

		const button = (
			<Button
				type={buttonType}
				transparent
				icon="caret-down"
				iconPos="right"
				onClick={onClick}
				className={classNames(styles.button, className)}
				size={size}
			>
				{children}
			</Button>
		);

		return (
			<div id={this.popoverContainerId}>
				<Popover
					overlayClassName={hasTabs ? styles.root : undefined}
					content={this.renderTabs()}
					getPopupContainer={() =>
						document.getElementById(this.popoverContainerId) as HTMLElement
					}
					trigger="click"
					placement={this.props.placement}
					visible={this.state.visible}
					onVisibleChange={this.onVisibleChange}
					{...popoverProps}
				>
					{icon ? (
						<Flex align="center">
							<Icon
								type={icon}
								size="md"
								color={buttonType && ButtonTypeToIconColor[buttonType]}
							/>
							{button}
						</Flex>
					) : (
						button
					)}
				</Popover>
			</div>
		);
	}
}
