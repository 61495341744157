import actionCreator from 'store/templates/templatesActionCreator';

import {
	RetrieveTemplatesRequest,
	RetrieveTemplatesResponse
} from 'services/api/templates/templateServiceTypes';

export const TEMPLATES_RETRIEVE = 'RETRIEVE_TEMPLATES';
export const retrieveTemplatesAsync = actionCreator.async<
	RetrieveTemplatesRequest,
	RetrieveTemplatesResponse,
	Error
>(TEMPLATES_RETRIEVE);

export const retrieveTemplates = actionCreator(TEMPLATES_RETRIEVE);
