import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import TotalAmountForm, { TotalAmountFormBaseProps } from './TotalAmountForm';
import { Currency } from 'services/api/currencies/currenciesServiceTypes';
import { isModalVisible } from 'store/modals/selectors';
import {
	TOTAL_AMOUNT_MODAL,
	CANCEL_PROCESS_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { openModal, closeModal } from 'store/modals/actions';
import { getCurrencies } from 'store/currencies/currenciesSelectors';
import { getIsTotalAmountLineFormInEditMode } from 'store/portJobs/selectors';
import { submit } from 'redux-form';
import { AppState } from 'store-types';

interface TotalAmountModalProps
	extends Pick<TotalAmountFormBaseProps, 'onSubmit'> {
	// from mapStateToProps
	isVisible: boolean;
	isEditMode: boolean;
	currencies: Currency[];
	// from mapDispatchToProps
	openModal: typeof openModal;
	closeModal: typeof closeModal;
	submitForm: typeof submit;
}

const TotalAmountModal = ({
	onSubmit,
	currencies,
	isVisible,
	isEditMode,
	...props
}: TotalAmountModalProps) => {
	const onCancel = () => props.openModal(CANCEL_PROCESS_MODAL);
	return (
		<Modal
			destroyOnClose
			visible={isVisible}
			title={isEditMode ? 'Edit line' : 'Add a new line'}
			footer={null}
			onCancel={onCancel}
		>
			<TotalAmountForm
				currencies={currencies}
				onSubmit={onSubmit}
				onCancel={onCancel}
			/>
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isVisible: isModalVisible(state, TOTAL_AMOUNT_MODAL),
		isEditMode: getIsTotalAmountLineFormInEditMode(state),
		currencies: getCurrencies(state)
	}),
	{
		openModal,
		closeModal,
		submitForm: submit
	}
)(TotalAmountModal);
