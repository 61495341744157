import actionCreatorFactory from 'typescript-fsa';
import { AddOrDeleteGroupUserRequest } from 'services/api/groups/groupsServiceTypes';

export const actionCreator = actionCreatorFactory();

const DELETE_THIRD_PARTY_LPA_GROUP_USER =
	'group/DELETE_THIRD_PARTY_LPA_GROUP_USER';
export const deleteThirdPartyLPAGroupUserAsync = actionCreator.async<
	AddOrDeleteGroupUserRequest,
	null,
	Error
>(DELETE_THIRD_PARTY_LPA_GROUP_USER, {
	templateParams: {
		entityName: 'Group User'
	}
});
export const deleteThirdPartyLPAGroupUser = actionCreator<
	AddOrDeleteGroupUserRequest
>(deleteThirdPartyLPAGroupUserAsync.type);
