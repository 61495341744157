import React, { useContext, useCallback } from 'react';

import { UseModalContext, ModalContext } from 'context';
import { CopyToClipboardWrapper, Flex, Title, Text } from 'components';
import { Button, Col, Modal, Row, Tag } from 'components/antd';
import styles from '../DeveloperPortal.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { DEVELOPER_PORTAL_FORM } from './constants';

interface DeveloperPortalHeaderProps {
	token: string | undefined;
}

function DevPortalTokenRefreshButton({ token }: DeveloperPortalHeaderProps) {
	const dispatch = useDispatch();
	const { name, showModal, hideModal, modalSubmitting } = useContext(
		UseModalContext
	);
	const formValues = useSelector(
		getFormValues(DEVELOPER_PORTAL_FORM),
		shallowEqual
	);
	const isRequestNewToken = Boolean(formValues?.isRequestNewToken);

	const onCancel = useCallback(() => {
		hideModal();
	}, [hideModal]);

	const onOk = useCallback(() => {
		dispatch(change(DEVELOPER_PORTAL_FORM, `isRequestNewToken`, true));
		onCancel();
	}, [dispatch, onCancel]);

	const onRefresh = useCallback(() => {
		showModal('');
	}, [showModal]);
	return (
		<>
			<Col className="tokenSection">
				<Row>
					<Title>Token:</Title>
					&nbsp; &nbsp;
					<span>{token}</span>
				</Row>
			</Col>
			{token && (
				<Col>
					{!isRequestNewToken ? (
						<Row>
							<Col>
								<Button
									type="primary"
									className="reqNewTokenLink"
									transparent
									icon="refresh"
									onClick={onRefresh}
								>
									Request new token
								</Button>
								{name !== null && (
									<Modal
										visible
										cancelText="Cancel"
										okText="Confirm"
										onOk={onOk}
										onCancel={onCancel}
										okProps={{ loading: modalSubmitting }}
									>
										Refreshing the token will result in making all unfinished
										API calls invalid (which still use current token). New token
										will be generated on updating the configuration. Continue?
									</Modal>
								)}
							</Col>
							<Col className="copyToken">
								<Row>
									<span>Copy Token</span>
									<CopyToClipboardWrapper
										copyContent={token}
										buttonTitle=""
										className={styles.copyIcon}
										initialTooltipTitle="Copy to clipboard"
									/>
								</Row>
							</Col>
						</Row>
					) : (
						<Text color="silent" className={styles.root}>
							Press <Text weight="semi-bold">Update button</Text> to generate a
							new token
						</Text>
					)}
				</Col>
			)}
		</>
	);
}
const DeveloperPortalHeader: React.SFC<DeveloperPortalHeaderProps> = props => {
	return (
		<>
			<Flex direction="horizontal" align="center">
				<Col>
					<Title>Subscription Status:</Title>
				</Col>
				&nbsp;
				<Col>
					<Tag
						bold
						className={
							props?.token
								? styles.subscribedStatus
								: styles.notSubscribedStatus
						}
					>
						{props?.token ? 'Subscribed' : 'Not Subscribed'}
					</Tag>
				</Col>
				<ModalContext.Provider>
					{props?.token && <DevPortalTokenRefreshButton {...props} />}
				</ModalContext.Provider>
			</Flex>
		</>
	);
};

export default DeveloperPortalHeader;
