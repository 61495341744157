import { Success, Failure } from 'typescript-fsa';
import { PortsState } from '../portsState';
import {
	RetrieveAnchoragesRequest,
	RetrieveAnchoragesResponse
} from 'services/api/ports/portsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveAnchoragesStarted = (state: PortsState): PortsState => ({
	...state,
	error: undefined,
	isFetching: FetchStatus.PENDING
});
export const onRetrieveAnchoragesSuccess = (
	state: PortsState,
	action: Success<RetrieveAnchoragesRequest, RetrieveAnchoragesResponse>
): PortsState => ({
	...state,
	isFetching: FetchStatus.SUCCESS,
	anchoragesByPort: {
		...state.anchoragesByPort,
		[action.params.port]: [
			...(state.anchoragesByPort[action.params.port] || []),
			...action.result.elements
		]
	}
});
export const onRetrieveAnchoragesFailed = (
	state: PortsState,
	action: Failure<RetrieveAnchoragesRequest, Error>
): PortsState => ({
	...state,
	isFetching: FetchStatus.FAILURE,
	error: action.error
});
