import { isEqual } from 'lodash';
import { VesselProgrammeState } from '../vesselProgrammeState';
import {
	RetrieveBerthsResponse,
	RetrieveBerthsRequest
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveBerthsStarted(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			berths: FetchStatus.PENDING
		}
	};
}
export function onRetrieveBerthsSuccess(
	state: VesselProgrammeState,
	action: Success<RetrieveBerthsRequest, RetrieveBerthsResponse>
): VesselProgrammeState {
	const { result, params } = action;
	const berths = result.elements;
	const updateBerthList = !isEqual(
		state.berthByTerminalId[params.terminal],
		berths
	);
	if (updateBerthList) {
		return {
			...state,
			berthByTerminalId: {
				...state.berthByTerminalId,
				[params.terminal]: berths
			},
			fetchStatuses: {
				...state.fetchStatuses,
				berths: FetchStatus.SUCCESS
			}
		};
	}
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			berths: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveBerthsFailed(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			berths: FetchStatus.FAILURE
		}
	};
}
