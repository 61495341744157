import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onResetUsers = (state: UsersState): UsersState => ({
	...state,
	byId: {},
	allIds: [],
	hasMore: true,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.IDLE
	}
});
