import { DashboardState } from '../dashboardState';

export const onSetActiveSection = (
	state: DashboardState,
	{ code }: { code: string }
): DashboardState => ({
	...state,
	context: {
		...state.context,
		activeSectionCode: code
	}
});
