import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from '../financeState';
import { RestoreOriginalDocumentRequest } from '../actions';

export const onRestoreOriginalDocument = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RestoreOriginalDocumentRequest,
	null
>('fetchStatuses', 'restoreOriginalDocument');
