import React from 'react';
import { Button } from 'components/antd';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { getActivePortJob } from 'store/portJobs/selectors';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface LinkJobButtonOwnProps {
	portCallId: string;
	jobCode: string;
	onClick?: (jobCode: string, portCallId: string) => void;
	disabled?: boolean;
	appointmentMainPrinciple?: string;
	nominationMainPrinciple?: string;
}

class LinkJobButton extends React.Component<LinkJobButtonOwnProps> {
	render() {
		const { appointmentMainPrinciple, nominationMainPrinciple } = this.props;
		const disabled =
			this.props.disabled &&
			appointmentMainPrinciple !== nominationMainPrinciple;
		const tooltipText: TooltipProps | undefined = disabled
			? {
					title: `This job can't be selected because it was created based on different nomination and has job settings which might contradict to the settings of the job you are linking`,
					placement: 'bottomRight'
			  }
			: undefined;
		return (
			<Button
				type="primary"
				size="small"
				onClick={() =>
					this.props.onClick &&
					this.props.onClick(this.props.jobCode, this.props.portCallId)
				}
				disabled={disabled}
				tooltip={tooltipText}
			>
				Link Appointment
			</Button>
		);
	}
}

export default connect((state: AppState) => {
	const portJob = getActivePortJob(state);
	return {
		nominationMainPrinciple: portJob.hubPrincipalCompany?.name
	};
})(LinkJobButton);
