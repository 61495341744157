import actionCreator from '../portCallActionCreator';
import {
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse
} from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'RETRIEVE_ACT_AS';

export const retrievePortCallActAsAsync = actionCreator.async<
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse,
	Error
>(ACTION_NAME);

export const retrievePortCallActAs = actionCreator<
	RetrievePortCallActAsRequest
>(ACTION_NAME);
export const resetPortCallActAs = actionCreator('RESET_ACT_AS');
