import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { AddPortCallRequest } from 'services/api/portCalls/portCallsServiceTypes';
import {
	AddPortJobRequestPayload,
	PortJobRequestPayload,
	PortJobAgencyRoles,
	PortJobAgencyTypes,
	PortJobTypeCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { Operation } from 'services/api/operations/operationsServiceTypes';
import { CompanyDraft } from 'store/drafts/draftsState';

import { prepareOperationRequest } from './prepareOperationRequest';
import {
	keyToIdField,
	labelToNameField,
	optionalLabelToNameField
} from './prepareRequest.func';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';

/**
 * Prepare Port Job to creation from inside an existing Port Call
 */
interface PreparePortCallRequest {
	form: FormData;
	operation: Operation;
	portJobOperationCompanies: CompanyDraft[];
	portJobCompanies: CompanyDraft[];
	isMainPrincipalTypeCLS: boolean;
}

export const prepareInitialPortJobRequest = (
	form: FormData,
	isMainPrincipalTypeCLS: boolean
): PortJobRequestPayload => ({
	jobType:
		(form.jobTypeId?.label === 'Charterers Oversight'
			? PortJobTypeCode.CHARTERER_OVERSIGHT
			: form.jobTypeId?.label) || null,
	hubPrincipalCompany: form.hubPrincipalCompany
		? keyToIdField(form.hubPrincipalCompany)
		: form.hubPrincipalCompany,
	appointerCompany:
		form.appointerCompany && labelToNameField(form.appointerCompany),
	performingAgentCompany: labelToNameField(form.performingAgent),
	// for csl, payingparty field should be defined
	payingPartyCompany:
		!isMainPrincipalTypeCLS && form.payingparty
			? labelToNameField(form.payingparty)
			: undefined,
	controllingAgentCompany: optionalLabelToNameField(form.controllingAgent),
	nominatorCompany: form.nominator && labelToNameField(form.nominator),
	agentType: form.agencyType.key as PortJobAgencyTypes,
	agentRole: form.agentRole
		? (form.agentRole.label.replace(/ /g, '') as PortJobAgencyRoles)
		: undefined,
	customerReference: form.customerRef || undefined,
	contact1: (form.jobTypeId?.key === 'Nomination' ||
	form.jobTypeId?.key === 'Charterers Oversight' ||
	form.checkIsNonIssHubPrincipal
		? optionalLabelToNameField(form.contact1)
		: labelToNameField(form.contact1)) || { id: null, name: null },
	contact2: optionalLabelToNameField(form.contact2),
	instructionToISS: form.instructionToISS,
	instructionToLPA: form.instructionToLPA,
	isShowAllChecked: form.checkIsNonIssHubPrincipal
});

export const preparePortJobRequest = ({
	form,
	operation,
	portJobOperationCompanies,
	portJobCompanies,
	isMainPrincipalTypeCLS
}: PreparePortCallRequest): AddPortJobRequestPayload => ({
	...prepareInitialPortJobRequest(form, isMainPrincipalTypeCLS),
	operations: [
		{
			...prepareOperationRequest(operation, form),
			portJobCompanies: portJobOperationCompanies
		}
	],
	portJobCompanies
});

/**
 * Prepare portCall Request
 */
export const prepareRequest = ({
	form,
	operation,
	portJobOperationCompanies,
	portJobCompanies,
	isMainPrincipalTypeCLS
}: PreparePortCallRequest): AddPortCallRequest => {
	const externalCode = form.portCall.vessel.label?.split('(')[2]?.split(')')[0];
	return {
		port: keyToIdField(form.portCall.port),
		vessel: keyToIdField(form.portCall.vessel),
		etaPlt: getLocalDateDefaultFormatted(form.portCall.etaPlt),
		etsPlt: form.portCall.etsPlt
			? getLocalDateDefaultFormatted(form.portCall.etsPlt)
			: form.portCall.etsPlt,
		jobs: [
			preparePortJobRequest({
				form,
				operation,
				portJobOperationCompanies,
				portJobCompanies,
				isMainPrincipalTypeCLS
			})
		],
		externalCode: externalCode
	};
};
