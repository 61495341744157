import { destroy as action } from '../actions/destroy';
import { destroy as reduxFormDestroy } from 'redux-form';
import { WizardFormBaseAction as request } from '../wizardFormState';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, call, takeLatest, all, fork } from 'redux-saga/effects';

export function* executor(actionParams: request): SagaIterator {
	yield put(reduxFormDestroy(actionParams.name));
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
