import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { LinkedCargo } from '../linkJobTypes';
import { getUnitByCode } from 'store/units/unitsSelectors';
import { isEmpty } from 'lodash';

export const getLinkJobCargosState = (state: AppState) => state.linkJob;

export const getLinkJobCargosFetchStatus = createSelector(
	getLinkJobCargosState,
	linkJobCargosState => linkJobCargosState.fetchStatuses.linkJob
);

export const getNominationCargos = createSelector(
	getLinkJobCargosState,
	linkJobCargosState => linkJobCargosState.linkedNominationCargoes
);

export const getLinkedAppointmentJob = createSelector(
	getLinkJobCargosState,
	appointmentJobState => appointmentJobState.linkedAppointmentJob
);

export const getLinkedAppointmentJodFetchStatus = createSelector(
	getLinkJobCargosState,
	appointmentJobState => appointmentJobState.fetchStatuses.linkedJob
);

export const getLinkedAppointmentCargos = createSelector(
	getLinkJobCargosState,
	linkJobCargosState => linkJobCargosState.linkedAppointmentCargoes
);

export const getUnlinkedAppointmentCargos = createSelector(
	getLinkJobCargosState,
	linkJobCargosState => linkJobCargosState.unLinkedAppointmentCargoes
);

export const getUpdatedLinkedJobCargosFethStatus = createSelector(
	getLinkJobCargosState,
	linkJobCargosSate => linkJobCargosSate.fetchStatuses.saveUpdatedLinkCargo
);

export const getDispalyCargos = (
	state: AppState,
	getCargos: (state: AppState) => LinkedCargo[]
): LinkedCargo[] => {
	const cargoes = getCargos(state);
	return cargoes.map(cargo => {
		const updatedCargo = {
			...cargo
		};
		if (cargo?.unitOfMeasurement) {
			const unit = getUnitByCode(state, cargo.unitOfMeasurement);
			if (unit) {
				updatedCargo.unitOfMeasurement = unit.display;
			}
		}
		return updatedCargo;
	});
};

export const getLinkJobButtonStatus = createSelector(
	getLinkJobCargosState,
	linkJobCargosState => {
		let appointCargos = 0;
		linkJobCargosState.linkedAppointmentCargoes.forEach(cargo => {
			if (!isEmpty(cargo)) {
				appointCargos++;
			}
		});
		return (
			linkJobCargosState.linkedNominationCargoes.length === 0 ||
			appointCargos !== linkJobCargosState.linkedNominationCargoes.length
		);
	}
);
