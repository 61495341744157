import React from 'react';
import classNames from 'classnames';
import styles from 'components/AutocompleteSearch/AutocompleteSearch.module.scss';

interface OptionProps {
	className?: string;
	highlighted?: boolean;
}

const Option: React.FC<OptionProps> = props => {
	const { highlighted, className, ...restProps } = props;
	return (
		<div
			className={classNames(className, styles.option, {
				[styles.highlighted]: highlighted
			})}
			{...restProps}
		>
			{props.children}
		</div>
	);
};

export default Option;
