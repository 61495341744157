import { Action } from 'typescript-fsa';
import {
	initialize,
	WizardFormInitialize
} from 'store/wizardForm/actions/initialize';
import { select, call, takeLatest, fork } from 'redux-saga/effects';
import { getActiveThreadId } from 'store/threads/selectors/threadsSelectors';
import { executor as retrieveThreadById } from 'store/thread/sagas/retrieveThreadByIdSaga';
import { SagaIterator } from 'redux-saga';

export function* getThreadFromActiveThreadIdExecutor(
	action: Action<WizardFormInitialize>
): SagaIterator {
	if (action.payload.mode === 'assign') {
		const activeThreadId = yield select(getActiveThreadId);
		if (!activeThreadId) {
			throw Error('There`s no Thread to assign this Job');
		}
		call(retrieveThreadById, activeThreadId);
	}
}

function* watcher(): SagaIterator {
	yield takeLatest(initialize.type, getThreadFromActiveThreadIdExecutor);
}

export default function*() {
	yield fork(watcher);
}
