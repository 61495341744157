import {
	FormData,
	CargoLineDischargeOperation
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AddPortJobCargoLineDischarge } from 'services/api/portJobs/portJobsServiceTypes';
import numeral from 'numeral';
import {
	idToIdField,
	labelToNameField,
	optionalLabelToNameField
} from '../prepareRequest.func';
import { omit } from 'lodash';
import { isIdAutoGenerated } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';

const prepareDischargeCargoLineRequest = (
	cargoLine: CargoLineDischargeOperation
): AddPortJobCargoLineDischarge => {
	let cargoLineData = {
		id: cargoLine.id,
		chartererCompany: optionalLabelToNameField(cargoLine.chartererCompany),
		cargoReferenceNumber: cargoLine.referenceNumber,
		principalVoyageRef: cargoLine.principalVoyageReference,
		mainCommodity: idToIdField(cargoLine.mainCommodity),
		commodity: optionalLabelToNameField(cargoLine.commodity),
		commodityQuantity: cargoLine.commodityQuantity
			? numeral(cargoLine.commodityQuantity).value()
			: undefined,
		charterPartyDate: cargoLine.charterPartyDate,
		commodityUnitOfMeasurementCode: cargoLine.commodityUnitOfMeasurementCode,
		terms: cargoLine.terms,
		receiverCompany: optionalLabelToNameField(cargoLine.receiverCompany),
		loadPort: optionalLabelToNameField(cargoLine.loadPort),
		dischargingToVessel: cargoLine.dischargingToVessel
			? labelToNameField(cargoLine.dischargingToVessel)
			: undefined
	};
	if (cargoLine.id && isIdAutoGenerated(cargoLine.id)) {
		cargoLineData = omit(cargoLineData, 'id');
	}
	return cargoLineData;
};

export default (form: FormData) => {
	return {
		cargoes: form.cargoes.map(prepareDischargeCargoLineRequest)
	};
};
