export const SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID =
	'supplierInvoiceContextualization';

export enum FormField {
	IS_CREDIT = 'isCredit',
	MAIN_PRINCIPAL_COMPANY = 'mainPrincipalCompany',
	SUPPLIER_COMPANY = 'supplierCompany',
	INVOICE_DATE = 'invoiceDate',
	RECEIPT_DATE = 'receiptDate',
	REFERENCE_NUMBER = 'referenceNumber',
	CURRENCY_CODE = 'currencyCode',
	TOTAL = 'total',
	DISCOUNT = 'discount',
	COMMENT = 'comment',
	PORT_JOBS = 'portJobs',
	PAGES = 'pages',
	PORT_JOB_SERVICES = 'services',
	PORT_JOB_SERVICE_TOTAL_AMOUNT = 'totalAmount',
	PORT_JOB_SERVICE_COMMENT = 'serviceComment',
	PORT_JOB_SERVICE_IS_SELECTED = 'isSelected'
}

export interface PortJobServiceFormData {
	id: string;
	totalAmount?: string;
	serviceComment?: string;
	isSelected?: boolean;
	pages?: Array<number>;
}

export interface PortJobFormData {
	id: string;
	services: PortJobServiceFormData[];
}

export interface PortJobServicesPageData {
	portJobs: PortJobFormData[];
}

export interface FormData {
	isCredit: boolean;
	mainPrincipalCompany: {
		key: string;
		label: string;
	};
	supplierCompany: {
		key: string;
		label: string;
	};
	invoiceDate: string;
	receiptDate: string;
	referenceNumber: string;
	currencyCode: {
		key: string;
		label: string;
	};
	total: string;
	discount: string;
	comment: string;
	portJobs: PortJobFormData[];
	pages: PortJobServicesPageData[];
}

export interface PortJobError {
	services?: Array<{ totalAmount?: string[] } | undefined>;
}

export interface PageError {
	portJobs?: Array<PortJobError | undefined>;
}
