import React, { useState } from 'react';
import { updateSupplierInvoice } from 'store/supplierInvoice/actions';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentViewerWizardForm } from 'sections/DocumentViewer';
import { PATHS } from 'sections/App/RouteParams';
import { DataFetcher } from 'utils/components';
import { getIsAnyContextualizationPortJobInvalid } from 'store/form/supplierInvoiceContextualization/selectors';
import { useActiveSupplierInvoice } from '../../../SupplierInvoice.hooks';
import ContextualizationForm from '../../AddNewSupplierInvoiceRoute/ContextualizationForm/ContextualizationForm';
import ContextualizationHint from '../../AddNewSupplierInvoiceRoute/ContextualizationHint';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';

export interface EditSupplierInvoiceProps {
	supplierInvoiceId: string;
}

const EditSupplierInvoice: React.SFC<EditSupplierInvoiceProps> = props => {
	const dispatch = useDispatch();
	const isAnyContextualizationPortJobInvalid = useSelector(
		getIsAnyContextualizationPortJobInvalid
	);

	const { documentId, saving, loading, document } = useActiveSupplierInvoice(
		props.supplierInvoiceId
	);
	const [isReduxFormValid, updateIsReduxFormValid] = useState(true);

	const onSubmit = () =>
		dispatch(
			updateSupplierInvoice({
				supplierInvoiceId: props.supplierInvoiceId,
				documentId: documentId
			})
		);

	const canSubmit = isReduxFormValid && !isAnyContextualizationPortJobInvalid;

	return (
		<DocumentViewerWizardForm
			documentId={documentId}
			fileDocument={document}
			title="Edit Supplier Invoice"
			returnUrl={`/${PATHS.supplierinvoices}`}
			loading={saving}
			onSubmit={onSubmit}
			canSubmit={canSubmit}
			hint={<ContextualizationHint />}
			pagination={PaginationState.ENABLED}
		>
			{({
				total,
				activePage,
				onValidChange: onPageValidChange,
				onResetWizardForm
			}) => (
				<DataFetcher isLoading={loading}>
					{() => (
						<ContextualizationForm
							pages={total}
							activePage={activePage}
							onValidChange={updateIsReduxFormValid} //this valid change to update the supplier redux form
							onPageValidChange={onPageValidChange} // this valid change to set true for the page in document viewer wizard form
							onResetWizardForm={onResetWizardForm}
							isCreditCheckboxDisabled
						/>
					)}
				</DataFetcher>
			)}
		</DocumentViewerWizardForm>
	);
};

export default EditSupplierInvoice;
