import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
	ConfirmPortJobPayload,
	ConfirmPortJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { confirmPortJobAsync, retrievePortJob } from 'store/portJobs/actions';
import { getPortJobConcurrencyTokenByCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import Api from 'services/api';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';

const apiCall = Api.PortJobs.confirmPortCallJob;

const confirmPortJob = function*({
	payload: { portCallId, jobCode, modalId }
}: Action<ConfirmPortJobPayload>): SagaIterator {
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		jobCode
	);
	try {
		const response = yield call(apiCall, {
			portCallId,
			jobCode,
			concurrencyToken
		} as ConfirmPortJobParams);
		yield put(
			confirmPortJobAsync.done({
				result: { status: response.data.status },
				params: {
					portCallId,
					jobCode,
					modalId
				},
				response
			})
		);
		yield put(
			retrievePortJob({
				jobCode,
				portCallId,
				expand: ExpandPortJobType.OPERATIONS
			})
		);
	} catch (error) {
		yield put(
			confirmPortJobAsync.failed({
				error,
				params: { portCallId, jobCode, modalId }
			})
		);
	} finally {
		yield put(closeModal(modalId));
	}
};

export default function* confirmPortJobWatcher(): SagaIterator {
	yield takeLatest(confirmPortJobAsync.type, confirmPortJob);
}
