import actionCreator from '../dashboardActionCreator';

const RETRIEVE_DASHBOARD_SUMMARY_CYCLE_START =
	'RETRIEVE_DASHBOARD_SUMMARY_CYCLE_START';

const RETRIEVE_DASHBOARD_SUMMARY_CYCLE_STOP =
	'RETRIEVE_DASHBOARD_SUMMARY_CYCLE_STOP';

export const retrieveDashboardSummaryCycleStart = actionCreator(
	RETRIEVE_DASHBOARD_SUMMARY_CYCLE_START
);

export const retrieveDashboardSummaryCycleStop = actionCreator(
	RETRIEVE_DASHBOARD_SUMMARY_CYCLE_STOP
);
