import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store-types';

import { AppNotifications } from 'containers';
import AppLayout from 'sections/AppLayout/AppLayout';
import RestrictedRoutes from 'sections/App/RestrictedRoutes';
import { RouteComponentProps } from 'react-router';
import { usePrevious } from 'utils/hooks';

import { getHasNotificationsFullPageDisplayType } from 'store/notifications/selectors';

type RestrictedAppProps = RouteComponentProps<{}> & {
	hasFullPageError: boolean;
};

function RestrictedApp(props: RestrictedAppProps) {
	const prevPathname = usePrevious(props.location.pathname);

	const hasFullPageError = useSelector((state: AppState) =>
		getHasNotificationsFullPageDisplayType(state)
	);

	useEffect(() => {
		/**
		 * Reload page when unhandled error has occurred
		 * and user clicks back/backspace to navigate
		 * previous page
		 */
		window.onpopstate = () => {
			if (hasFullPageError) {
				window.location.reload();
			}
		};

		return () => {
			/**
			 * Reset window popstate event listener
			 */
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.onpopstate = () => {};
		};
	}, [hasFullPageError]);

	useEffect(() => {
		if (
			hasFullPageError &&
			prevPathname &&
			props.location.pathname &&
			prevPathname !== props.location.pathname
		) {
			/**
			 * Reload page when unhandled error has occurred
			 * and user navigates to the url provided by a corresponding template
			 */
			window.location.reload();
		}
	}, [hasFullPageError, prevPathname, props.location.pathname]);

	return (
		<AppLayout>
			{hasFullPageError ? <AppNotifications.FullPage /> : <RestrictedRoutes />}
		</AppLayout>
	);
}
export default RestrictedApp;
