import actionCreator from '../actionCreator';
import { ReducerRotationStep } from './../vesselProgrammeState';

const ACTION_NAME = 'DELETE_POI';

export const deletePOIAsync = actionCreator.async<
	ReducerRotationStep['id'],
	undefined,
	Error
>(ACTION_NAME);

export const deletePOI = actionCreator<ReducerRotationStep['id']>(ACTION_NAME);
