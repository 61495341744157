import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { EditPortCallFormData } from './editPortCallDataTypes';

const parsePortCallIntoForm = (portCall: PortCall): EditPortCallFormData => {
	let vesselLabel = `${portCall.vessel.name} (${portCall.imo})`;
	if (portCall.externalCode) {
		vesselLabel = vesselLabel + ` - (${portCall.externalCode})`;
	}
	return {
		etaPlt: portCall.etaPlt,
		etsPlt: portCall.etsPlt,
		port: {
			key: portCall.port.id,
			label: portCall.port.name
		},
		vessel: {
			key: portCall.vessel.id,
			label: vesselLabel
		}
	};
};

export default parsePortCallIntoForm;
