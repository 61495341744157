import { Action, Success } from 'typescript-fsa';
import { call, takeLatest, select } from 'redux-saga/effects';
import { navigateTo } from 'utils';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { CreateFinanceDocumentsRequest } from 'services/api/finance/financeServiceTypes';
import { createFinanceDocumentsAsync } from '../../actions';
import Api from 'services/api';
import prepareRequest from './prepareRequest/prepareRequest';

const executor = makeDefaultExecutor<
	CreateFinanceDocumentsRequest,
	null,
	Error
>({
	api: Api.Finance.createFinanceDocuments,
	async: createFinanceDocumentsAsync,
	*onSuccess({ params }: Success<CreateFinanceDocumentsRequest, null>) {
		yield call(
			navigateTo,
			`/portcalls/${params.portCallId}/jobs/${params.jobCode}/finance`
		);
	}
});

function* worker({ payload }: Action<CreateFinanceDocumentsRequest>) {
	const request = yield select(prepareRequest, payload.data.documentId);
	yield call(executor, {
		portCallId: payload.portCallId,
		jobCode: payload.jobCode,
		data: request
	});
}

export default function*() {
	yield takeLatest(createFinanceDocumentsAsync.type, worker);
}
