import { FinanceState } from '../financeState';
import {
	FinanceRequest,
	RetrieveAvailableSectionCodeResponse
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveAvailableSections = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	FinanceRequest,
	RetrieveAvailableSectionCodeResponse
>('fetchStatuses', 'retrieveAvailableSections', {
	doneReducer: (
		state: FinanceState,
		action: Success<FinanceRequest, RetrieveAvailableSectionCodeResponse>
	) => {
		return {
			...state,
			context: {
				...state.context,
				availableServiceCode: [...action.result.financeAllSection]
			}
		};
	}
});
