import * as React from 'react';
import classNames from 'classnames';
import styles from './ValueList.module.scss';
import { LabeledValue } from 'app-types';

interface ValueListProps {
	inline?: boolean;
	/** consumed data */
	dataSource: LabeledValue[];
}

export default class ValueList extends React.Component<ValueListProps> {
	render() {
		const { inline, dataSource } = this.props;
		return (
			<ul className={classNames(styles.root, { [styles.inline]: inline })}>
				{dataSource.map((data, index) => (
					<li key={index}>
						{data.label}: <strong>{data.key}</strong>
					</li>
				))}
			</ul>
		);
	}
}
