import { FormState } from './formState';

export interface FormStateGet<P> extends FormState<P> {
	initialized: boolean;
	valid: boolean;
	pristine: boolean;
}

/** FieldType from 'redux-form' */
export enum FieldType {
	FIELD = 'Field',
	FIELD_ARRAY = 'FieldArray'
}
