import * as React from 'react';
import classNames from 'classnames';
import styles from './Align.module.scss';

interface AlignProps {
	/**
	 * Apply `text-align` rule. Default: left
	 */
	align?: 'center' | 'left' | 'right' | 'justify';
	className?: string;
}

export const Align: React.FC<AlignProps> = props => {
	const { align = 'left', className } = props;

	return (
		<div className={classNames(className, styles[align])}>{props.children}</div>
	);
};

export default Align;
