import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { makePollDownloadExecutor } from 'utils/sagaHelpers/pollDownloadExecutor';
import { DownloadSingleDARequest } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import { downloadSingleDAAsync as async } from '../actions';

const POSTApi = Api.Finance.downloadSingleDA;
const GETApi = Api.Finance.pollDownloadSingleDA;

const downloadExecutor = makePollDownloadExecutor<
	DownloadSingleDARequest,
	RetrieveDownloadDocumentResponse,
	Error
>({
	async,
	POSTApi,
	GETApi
});

export default makeTakeLatestWatcher<
	DownloadSingleDARequest,
	RetrieveDownloadDocumentResponse,
	Error
>({ async }, downloadExecutor);
