import { WizardFormState, WizardFormBaseAction } from '../wizardFormState';
export const onPrevPage = (
	state: WizardFormState,
	action: WizardFormBaseAction
) => {
	const { name } = action;
	const originalForm = state.forms[action.name];
	const form = {
		...originalForm,
		activePage: originalForm.activePage - 1
	};
	if (!form.activePage) {
		return state;
	}
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
