import { PortCallsState, PortCallError, initialState } from '../portCallsState';
import {
	PortCall,
	RetrievePortCallRequest
} from 'services/api/portCalls/portCallsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export function onRetrievePortCallStarted(
	state: PortCallsState,
	{ id, isSilent }: RetrievePortCallRequest
): PortCallsState {
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: false
		},
		fetchStatuses: {
			...state.fetchStatuses,
			[id]: isSilent ? state.fetchStatuses[id] : FetchStatus.PENDING
		}
	};
}
export function onRetrievePortCallSuccess(
	state: PortCallsState,
	action: {
		result: PortCall;
		params: RetrievePortCallRequest;
	}
): PortCallsState {
	return {
		...state,
		portCall: action.result,
		fetchStatuses: {
			...state.fetchStatuses,
			[action.params.id]: FetchStatus.SUCCESS
		}
	};
}
export function onRetrievePortCallFailed(
	state: PortCallsState,
	action: {
		params: RetrievePortCallRequest;
		error: PortCallError;
	}
): PortCallsState {
	const id = action.params.id;
	const fetchStatuses = {
		...state.fetchStatuses,
		[id]: FetchStatus.FAILURE
	};
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: action.error.response.status === 404
		},
		fetchStatuses
	};
}

export const onResetPortCall = (state: PortCallsState): PortCallsState => {
	const portCall = state.portCall;
	return {
		...state,
		portCall: initialState.portCall,
		fetchStatuses: {
			...state.fetchStatuses,
			...portCall ? { [portCall.id]: FetchStatus.IDLE } : {} // set back to idle state
		}
	};
};
