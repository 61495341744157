import React from 'react';
import { RenderLeafProps } from 'slate-packages/packages/slate-react';

export function Leaf(props: RenderLeafProps): JSX.Element;
export function Leaf(
	props: Omit<RenderLeafProps, 'attributes'> & { attributes: object }
): JSX.Element;

export function Leaf({ attributes, children, leaf }: RenderLeafProps) {
	const { data = {} } = leaf;
	if (leaf.bold) {
		children = <strong {...data.attrs}>{children}</strong>;
	}
	if (leaf.code) {
		children = <code {...data.attrs}>{children}</code>;
	}
	if (leaf.italic) {
		children = <i {...data.attrs}>{children}</i>;
	}
	if (leaf.underline) {
		children = <u {...data.attrs}>{children}</u>;
	}
	if (leaf.strikethrough) {
		children = <s {...data.attrs}>{children}</s>;
	}
	// only for presentation
	if (attributes['data-slate-leaf']) {
		return <span {...attributes}>{children}</span>;
	}

	return children;
}

export default Leaf;
