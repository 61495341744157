import {
	retrieveFinanceDetailsCycleStart,
	retrieveFinanceDetailsCycleStop,
	retrieveFinanceDetails
} from '../actions';
import { SagaIterator } from 'redux-saga';
import { cancel, select, fork, take, put, delay } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getSafeFinanceDetails } from 'store/finance/selectors';

function* worker(): SagaIterator {
	while (true) {
		const portCallId = yield select(getActivePortCallId);
		yield put(
			retrieveFinanceDetails({
				portCallId
			})
		);
		const financeDetails = yield select(getSafeFinanceDetails);

		if (financeDetails && financeDetails.jobs.length !== 0) {
			yield put(retrieveFinanceDetailsCycleStop());
		}

		yield delay(1000);
	}
}

export default function*() {
	while (yield take(retrieveFinanceDetailsCycleStart)) {
		const pollFinanceDetailsTask = yield fork(worker);
		yield take(retrieveFinanceDetailsCycleStop);
		yield cancel(pollFinanceDetailsTask);
	}
}
