import actionCreator from '../portCallActionCreator';

export interface RetrievePortCallVesselInfoActionParams {
	portCallId: string;
}
const ACTION_NAME = 'RETRIEVE_PORTCALL_VESSEL_INFO';
export const retrievePortCallVesselInfo = actionCreator<
	RetrievePortCallVesselInfoActionParams
>(ACTION_NAME);
export const retrievePortCallVesselInfoAsync = actionCreator.async<
	null,
	null,
	Error
>(ACTION_NAME);

export const resetPortCallVesselInfo = actionCreator<null>(
	'RESET_PORTCALL_VESSEL_INFO'
);
