import actionCreator from '../companyVesselsActionCreator';
import {
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_EDIT_COMPANY_VESSEL';

export const retrieveEditCompanyVesselDataAsync = actionCreator.async<
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse,
	Error
>(ACTION_NAME);

export const retrieveEditCompanyVesselData = actionCreator<
	RetrieveCompanyVesselRequest
>(ACTION_NAME);
