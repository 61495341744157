import { Success } from 'typescript-fsa';
import { PortCallDocumentNotIssuedRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';

const onEditNotIssuedPortCallDocumentSuccess = (
	state: PortCallDocumentsState,
	{ params }: Success<PortCallDocumentNotIssuedRequest, null>
) => ({
	...state,

	// Update `jobs`/`comment` values by document ID
	uploadedById: {
		...state.uploadedById,
		[params.documentId]: {
			...state.uploadedById[params.documentId],
			applicability: {
				...state.uploadedById[params.documentId].applicability,
				jobs: params.data.jobs // Update jobs
			},
			comment: params.data.comment // Update comment
		}
	}
});

export { onEditNotIssuedPortCallDocumentSuccess };
