import actionCreatorFactory from 'typescript-fsa';

import { Unit } from 'services/api/units/unitsServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_UNITS_OF_MEASUREMENTS = 'units/RETRIEVE_UNITS_OF_MEASUREMENTS';
export const retrieveUnitsAsync = actionCreator.async<undefined, Unit[], Error>(
	RETRIEVE_UNITS_OF_MEASUREMENTS
);

export const retrieveUnits = actionCreator(retrieveUnitsAsync.type);
