import { retrieveRequiredDocumentsAsync } from '../actions/retrieveRequiredDocuments';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse,
	Error
>({
	api: Api.PortCallDocuments.retrieveRequiredDocuments,
	async: retrieveRequiredDocumentsAsync
});
