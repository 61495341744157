import React from 'react';
import { useSelector } from 'react-redux';
import { ScrollableLayout } from 'components';
import { PermissionContext, AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import DeveloperPortal from './DeveloperPortalTab/DeveloperPortal';
import { getBreadcrumbs } from './DeveloperPortalPage.func';
import { getUserCompanyId } from 'store/auth/selectors';
import DeveloperPortalFooter from './DeveloperPortalTab/DeveloperPortalFooter';

function ConfigurationPage() {
	const activeCompanyId = useSelector(getUserCompanyId);

	return (
		<PermissionContext.Provider value={{ companyId: activeCompanyId }}>
			<ScrollableLayout stretch>
				<RouteBreadcrumbs items={getBreadcrumbs()} />
				<AppNotifications.Alert />
				<AppNotifications.Notification />
				<ScrollableLayout>
					<DeveloperPortal />
				</ScrollableLayout>
				<DeveloperPortalFooter />
			</ScrollableLayout>
		</PermissionContext.Provider>
	);
}

export default ConfigurationPage;
