export interface WizardFormAction {
	pages?: number;
}

export interface WizardFormBaseAction {
	name: string;
	page?: number;
}

export interface WizardFormPageState {
	valid?: boolean;
	onNextClick?: () => void;
	submitted?: boolean;
}

export type WizardFormModes = 'assign' | 'create' | 'jobLinking';

export interface WizardFormProps {
	activePage: number;
	startPage: number;
	mode: WizardFormModes;
	fromPath?: string;
	originUrl?: string;
	pages: {
		[key: string]: WizardFormPageState;
	};
}

export interface WizardForms {
	[name: string]: WizardFormProps;
}

export interface WizardFormState {
	readonly forms: WizardForms;
	readonly activeWizardFormId: string;
}

export const initialState: WizardFormState = {
	forms: {},
	activeWizardFormId: ''
};
