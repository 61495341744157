import { retrievePortCallShipMeasurementsAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse,
	Error
>({
	api: Api.PortCallMeasurements.retrievePortCallShipMeasurements,
	async
});
