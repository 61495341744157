import { without } from 'lodash';
import actionCreatorFactory from 'typescript-fsa';

import { GroupUsersState } from '../groupUsersState';
import { AddOrDeleteGroupUserRequest } from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const actionCreator = actionCreatorFactory();

const DELETE_GROUP_USER = 'group/DELETE_GROUP_USER';
export const deleteGroupUserAsync = actionCreator.async<
	AddOrDeleteGroupUserRequest,
	null,
	Error
>(DELETE_GROUP_USER, {
	templateParams: {
		entityName: 'Group User'
	}
});
export const deleteGroupUser = actionCreator<AddOrDeleteGroupUserRequest>(
	deleteGroupUserAsync.type
);

export function onDeleteGroupUserStarted(
	state: GroupUsersState
): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.PENDING
	};
	return {
		...state,
		fetchStatuses
	};
}

export function onDeleteGroupUserSuccess(
	state: GroupUsersState,
	action: { params: AddOrDeleteGroupUserRequest }
): GroupUsersState {
	const { groupId, userId } = action.params;

	const byGroupId = {
		...state.byGroupId,
		[groupId]: without(state.byGroupId[groupId], userId)
	};
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.SUCCESS
	};
	return {
		...state,
		fetchStatuses,
		byGroupId
	};
}

export function onDeleteGroupUserFailed(
	state: GroupUsersState
): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		deleting: FetchStatus.FAILURE
	};
	return {
		...state,
		fetchStatuses
	};
}
