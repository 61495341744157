import actionCreator from '../dashboardActionCreator';
import {
	RetrieveDashboardPortCallsRequest as Request,
	DashboardPortCallsResponse as Response
} from 'services/api/dashboard/dashboardServiceTypes';

const RETRIEVE_ACTIVE_SECTION = 'RETRIEVE_ACTIVE_SECTION';

export const retrieveActiveSection = actionCreator(RETRIEVE_ACTIVE_SECTION);

export const retrieveActiveSectionAsync = actionCreator.async<
	Request,
	Response,
	Error
>(RETRIEVE_ACTIVE_SECTION);
