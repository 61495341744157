import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
	initializeConfigurationAsync,
	retrieveConfigurationCompanies,
	retrieveConfigurationCompaniesAsync,
	setActiveCompanyId
} from '../actions';

import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';
import { getActiveCompanyId } from 'store/configuration/selectors';

const async = initializeConfigurationAsync;

export function* retrieveCompaniesWorker(permissionCode: PermissionCode[]) {
	yield put(retrieveConfigurationCompanies(permissionCode));
	return yield call(waitAsyncResolve, retrieveConfigurationCompaniesAsync);
}

export function* worker() {
	yield put(async.started(null));

	try {
		const action = yield call(retrieveCompaniesWorker, [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES
		]);

		if (action && !action.payload.error) {
			const result = action.payload.result.elements;
			if (result.length) {
				const activeCompanyId = yield select(getActiveCompanyId);
				yield put(setActiveCompanyId(activeCompanyId || result[0].id));
			}
		}

		yield put(async.done({ params: null, result: null, response: null }));
	} catch (e) {
		yield put(async.failed({ params: null, error: e }));
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
