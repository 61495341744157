import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Node, Text, Element } from 'slate';
import { Element as ElementComponent, Leaf } from '../../nodes';
import {
	RichTextNodeSection,
	RichTextValue,
	ElementType
} from '../../RichTextTypes';
import {
	NEW_LINE,
	DEFAULT_NODE_TYPE
} from 'components/RichText/RichTextConstants';

export const serialize = (
	nodes: RichTextValue,
	selectedSection?: RichTextNodeSection[]
) => {
	const elements = nodes.reduce((acc, node, i) => {
		if (!node.data?.section) {
			return acc;
		}
		const shouldOmit = selectedSection
			? !selectedSection.includes(node.data.section)
			: false;
		if (shouldOmit) {
			return acc;
		}
		const elements = serializeNode(node, i);
		let inner = '';
		if (elements) {
			const html = ReactDOMServer.renderToStaticMarkup(<body>{elements}</body>);
			inner = html.slice(6, -7);
		}
		if (!acc[node.data.section]) {
			acc[node.data.section] = '';
		}
		acc[node.data.section] += inner;
		return acc;
	}, {});
	return elements;
};

export const serializeNode = (node: Node, index?: number): React.ReactNode => {
	node = getNodeForSerialization(node);

	if (Text.isText(node)) {
		const props = {
			leaf: node,
			text: node,
			children: node.text,
			attributes: {}
		};
		return <Leaf {...props} key={index} />;
	}

	const children: React.ReactNode[] = node.children.map(serializeNode);
	if (Element.isElement(node)) {
		const props = {
			element: node,
			children,
			attributes: {}
		};
		return <ElementComponent {...props} key={index} />;
	}

	return children;
};

const BR_ELEMENT: Element = {
	type: ElementType.BR,
	children: []
};
const getNodeForSerialization = (value: Node) => {
	let node = { ...value };

	if (Text.isText(node)) {
		const newLineIndex = node.text.indexOf(NEW_LINE);
		if (newLineIndex === -1) {
			return node;
		} else {
			if (node.text === NEW_LINE) {
				node = BR_ELEMENT;
			} else {
				const endNode = {
					...node,
					text: node.text.substr(newLineIndex + NEW_LINE.length)
				};
				if (newLineIndex === 1) {
					node = {
						children: [BR_ELEMENT, endNode]
					};
				} else {
					node = {
						children: [
							{
								...node,
								text: node.text.substr(0, newLineIndex)
							},
							BR_ELEMENT,
							endNode
						]
					};
				}
			}
		}
	}

	// Empty default block placeholder
	if (Element.isElement(node)) {
		// Populate empty default block with the new line to preserve formatting
		if (node.type === DEFAULT_NODE_TYPE) {
			const { children } = node;
			const [first] = children;
			if (
				!children.length ||
				(node.children.length === 1 && Text.isText(first) && first.text === '')
			) {
				node.children = [BR_ELEMENT];
			}
		}
	}
	return node;
};
