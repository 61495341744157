import React from 'react';
import classNames from 'classnames';
import styles from './Count.module.scss';
import { Text, Flex } from '..';
import { Justify } from '../types';
import { noop } from 'lodash';

interface CountProps {
	count: number;
	type: 'positive' | 'negative' | 'attention';
	title: string;
	// eslint-disable-next-line
	onClick?: (...args: any[]) => void;
}
const format = (count: number) => (count > 99 ? '99+' : count.toString());
const Count = ({ count, type = 'positive', title, onClick }: CountProps) => {
	return (
		<Flex
			justify={Justify.CENTER}
			direction="vertical"
			align="center"
			className={styles.wrapper}
			onClick={onClick || noop}
		>
			<Flex
				align="center"
				justify={Justify.CENTER}
				className={classNames(
					styles.count,
					styles[type],
					onClick && styles.clickable
				)}
			>
				{format(count)}
			</Flex>
			<Text>{title}</Text>
		</Flex>
	);
};
export default Count;
