import { ConfigurationBenchmarkData } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_CONFIGURATION_BENCHMARK';
export const retrieveConfigurationBenchmark = actionCreator(ACTION_NAME);
export const retrieveConfigurationBenchmarkAsync = actionCreator.async<
	null,
	ConfigurationBenchmarkData,
	Error
>(ACTION_NAME);
