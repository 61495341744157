import { AuthState } from '../authState';

export const onTriggerActAsHistoryBlockRegistration = (
	state: AuthState
): AuthState => {
	const historyBlockTriggerCounter = state.actAs.historyBlockTriggerCounter;
	return {
		...state,
		actAs: {
			...state.actAs,
			historyBlockTriggerCounter: historyBlockTriggerCounter + 1
		}
	};
};
