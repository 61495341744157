import { AssignToThreadsRequest } from 'services/api/threads/threadsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from '../threadsState';

export const onUpdateAssignToThreads = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	AssignToThreadsRequest,
	null
>('fetchStatuses', 'assignToUpdating');
