import {
	CompanyVesselsState,
	initialState
} from 'store/companyVessels/companyVesselsState';
import {
	RetrieveCompanyVesselsRequest,
	RetrieveCompanyVesselsResponse,
	RetrieveCompanyVessel
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { keyBy } from 'lodash';

const mapCompanyVesselsById = (
	vessels: RetrieveCompanyVessel[],
	companyId: string
) =>
	keyBy(
		vessels.map(item => ({
			...item,
			companyId
		})),
		item => item.id
	);

export const onRetrieveCompanyVessels = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	RetrieveCompanyVesselsRequest,
	RetrieveCompanyVesselsResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: (
		state,
		{ result: { elements }, params: { limit, companyId } }
	): CompanyVesselsState => ({
		...state,
		byId: {
			...state.byId,
			...mapCompanyVesselsById(elements, companyId)
		},
		hasMore: limit === elements.length
	}),
	failedReducer: state => {
		return {
			...state,
			hasMore: false
		};
	}
});

export const onResetCompanyVessels = (state: CompanyVesselsState) => ({
	...state,
	byId: initialState.byId,
	hasMore: initialState.hasMore
});
