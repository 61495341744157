import { WizardFormState } from '../wizardFormState';
import { WizardFormSetPage } from '../actions/setActivePage';
export const onSetActivePage = (
	state: WizardFormState,
	action: WizardFormSetPage
): WizardFormState => {
	const { name, activePage } = action;
	const formName: string = name ? name : state.activeWizardFormId;
	const form = {
		...state.forms[formName],
		activePage
	};
	return {
		...state,
		forms: {
			...state.forms,
			[formName]: form
		}
	};
};
