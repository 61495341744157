import {
	retrieveFundingRequest,
	retrieveFundingRequestAsync,
	retrieveFundingPageData,
	retrieveFundingPageDataAsync,
	retrieveNewFundingData,
	retrieveNewFundingDataAsync,
	setActiveFundingRequestId
} from '../actions';
import { RetrieveFundingRequestsPageDataRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';
import {
	retrievePortCall,
	retrievePortCallAsync,
	setPortCallContext
} from 'store/portcalls/actions';

import {
	retrievePortJob,
	retrievePortJobAsync,
	setActivePortJobCode
} from 'store/portJobs/actions';
import {
	retrieveFinanceCurrencies,
	retrieveFinanceCurrenciesAsync
} from 'store/finance/actions';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';

import { call, takeLatest, all, put, take } from 'redux-saga/effects';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';

export function* executor(params: Request): SagaIterator {
	yield put(retrieveFundingPageDataAsync.started(params));
	try {
		const { portCallId, jobCode, fundingId, daId } = params;
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
		yield put(
			retrievePortJob({
				portCallId,
				jobCode,
				expand: ExpandPortJobType.PORT_JOBS_BASIC
			})
		);

		yield put(retrieveFinanceCurrencies());
		yield all([
			take([retrievePortCallAsync.done, retrievePortCallAsync.failed]),
			take([retrievePortJobAsync.done, retrievePortJobAsync.failed]),
			take([
				retrieveFinanceCurrenciesAsync.done,
				retrieveFinanceCurrenciesAsync.failed
			])
		]);
		yield put(setActivePortJobCode(jobCode));
		yield put(
			setPortCallContext({
				activePortCallId: portCallId,
				activePortCallTabId: PortCallTabKey.FINANCE
			})
		);

		if (fundingId) {
			yield put(retrieveFundingRequest({ fundingId }));
			yield take([
				retrieveFundingRequestAsync.done,
				retrieveFundingRequestAsync.failed
			]);
			yield put(setActiveFundingRequestId(fundingId));
		} else {
			if (daId) {
				yield put(retrieveNewFundingData({ portCallId, jobCode, daId }));
				yield take([
					retrieveNewFundingDataAsync.done,
					retrieveNewFundingDataAsync.failed
				]);
			}
		}

		yield put(
			retrieveFundingPageDataAsync.done({
				result: undefined,
				params,
				response: null
			})
		);
	} catch (error) {
		yield put(
			retrieveFundingPageDataAsync.failed({
				error,
				params
			})
		);
	}
}

function* worker({ payload }: Action<Request>): SagaIterator {
	yield call(executor, payload);
}

export default function* watcher(): SagaIterator {
	yield takeLatest(retrieveFundingPageData.type, worker);
}
