import React, { RefObject } from 'react';
import Konva from 'konva';
import { Transformer, Path, Rect } from 'react-konva';

interface TransformerComponentProps {
	isSelected: boolean;
	resizeEnabled?: boolean;
	onDelete: () => void;
	shapeRef: RefObject<Konva.Shape>;
	enabledAnchors?: Array<string>;
	showEdit?: boolean;
	onEdit?: () => void;
}

const TransformerComponent = ({
	isSelected,
	shapeRef,
	onDelete,
	showEdit = false,
	...props
}: TransformerComponentProps) => {
	const trRef = React.useRef<Konva.Transformer>(null);
	const deleteRef = React.useRef<Konva.Path>(null);

	React.useEffect(() => {
		if (isSelected && shapeRef.current && trRef.current) {
			// we need to attach transformer manually
			trRef.current.setNode(shapeRef.current);
			// eslint-disable-next-line no-unused-expressions
			trRef.current.getLayer()?.batchDraw();
		}
	}, [isSelected, shapeRef]);

	const onDeleteMouseEnter = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage) {
			stage.container().style.cursor = 'pointer';
		}
	};

	const onDeleteMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage) {
			stage.container().style.cursor = 'default';
		}
	};

	const onClickDelete = (event: Konva.KonvaEventObject<MouseEvent>) => {
		event.cancelBubble = true;
		onDeleteMouseLeave(event);
		onDelete();
	};

	const onClickEdit = (event: Konva.KonvaEventObject<MouseEvent>) => {
		event.cancelBubble = true;
		if (props.onEdit) {
			props.onEdit();
		}
	};

	return (
		<Transformer
			{...props}
			ref={trRef}
			flipEnabled={false}
			borderStroke={'#CACACA'}
			anchorFill={'#CACACA'}
			anchorStroke={'#CACACA'}
			anchorSize={5}
			rotateAnchorOffset={30}
			rotationSnaps={[90, 180, 270, 360]}
			boundBoxFunc={(oldBox, newBox) => {
				// limit resize
				if (newBox.width < 5 || newBox.height < 5) {
					return oldBox;
				}
				return newBox;
			}}
			onTransform={() => {
				const textNode = shapeRef.current;
				if (textNode) {
					textNode.setAttrs({
						width: textNode.width() * textNode.scaleX(),
						scaleX: 1
					});
				}
			}}
		>
			<Rect
				fill={'white'}
				strokeWidth={1}
				stroke={'#CACACA'}
				cornerRadius={3}
				width={20}
				height={20}
				x={0}
				y={-25}
				onClick={onClickDelete}
				onMouseEnter={onDeleteMouseEnter}
				onMouseLeave={onDeleteMouseLeave}
			/>
			<Path
				data={
					'M4.625 2.37372H4.5C4.56875 2.37372 4.625 2.31747 4.625 2.24872V2.37372H9.375V2.24872C9.375 2.31747 9.43125 2.37372 9.5 2.37372H9.375V3.49872H10.5V2.24872C10.5 1.69716 10.0516 1.24872 9.5 1.24872H4.5C3.94844 1.24872 3.5 1.69716 3.5 2.24872V3.49872H4.625V2.37372ZM12.5 3.49872H1.5C1.22344 3.49872 1 3.72216 1 3.99872V4.49872C1 4.56747 1.05625 4.62372 1.125 4.62372H2.06875L2.45469 12.7956C2.47969 13.3284 2.92031 13.7487 3.45313 13.7487H10.5469C11.0813 13.7487 11.5203 13.33 11.5453 12.7956L11.9313 4.62372H12.875C12.9438 4.62372 13 4.56747 13 4.49872V3.99872C13 3.72216 12.7766 3.49872 12.5 3.49872ZM10.4266 12.6237H3.57344L3.19531 4.62372H10.8047L10.4266 12.6237Z'
				}
				fill={'red'}
				scaleX={1}
				scaleY={1}
				ref={deleteRef}
				x={3}
				y={-23}
				onClick={onClickDelete}
				onMouseEnter={onDeleteMouseEnter}
				onMouseLeave={onDeleteMouseLeave}
			/>
			{showEdit && (
				<>
					<Rect
						fill={'white'}
						strokeWidth={1}
						stroke={'#CACACA'}
						cornerRadius={3}
						width={20}
						height={20}
						x={25}
						y={-25}
						onClick={onClickEdit}
						onMouseEnter={onDeleteMouseEnter}
						onMouseLeave={onDeleteMouseLeave}
					/>
					<Path
						data={
							'M13.375 6.00061H8.625C8.55625 6.00061 8.5 6.05686 8.5 6.12561V7.87561C8.5 7.94436 8.55625 8.00061 8.625 8.00061H9.375C9.44375 8.00061 9.5 7.94436 9.5 7.87561V7.00061H10.4375V12.0006H9.71875C9.65 12.0006 9.59375 12.0569 9.59375 12.1256V12.8756C9.59375 12.9444 9.65 13.0006 9.71875 13.0006H12.2812C12.35 13.0006 12.4062 12.9444 12.4062 12.8756V12.1256C12.4062 12.0569 12.35 12.0006 12.2812 12.0006H11.5625V7.00061H12.5V7.87561C12.5 7.94436 12.5562 8.00061 12.625 8.00061H13.375C13.4438 8.00061 13.5 7.94436 13.5 7.87561V6.12561C13.5 6.05686 13.4438 6.00061 13.375 6.00061ZM9.25 4.12561V2.12561C9.25 2.05686 9.19375 2.00061 9.125 2.00061H0.625C0.55625 2.00061 0.5 2.05686 0.5 2.12561V4.12561C0.5 4.19436 0.55625 4.25061 0.625 4.25061H1.5C1.56875 4.25061 1.625 4.19436 1.625 4.12561V3.12561H4.25V11.8756H2.8125C2.74375 11.8756 2.6875 11.9319 2.6875 12.0006V12.8756C2.6875 12.9444 2.74375 13.0006 2.8125 13.0006H6.9375C7.00625 13.0006 7.0625 12.9444 7.0625 12.8756V12.0006C7.0625 11.9319 7.00625 11.8756 6.9375 11.8756H5.5V3.12561H8.125V4.12561C8.125 4.19436 8.18125 4.25061 8.25 4.25061H9.125C9.19375 4.25061 9.25 4.19436 9.25 4.12561Z'
						}
						fill={'black'}
						scaleX={1}
						scaleY={1}
						ref={deleteRef}
						x={28}
						y={-23}
						onClick={onClickEdit}
						onMouseEnter={onDeleteMouseEnter}
						onMouseLeave={onDeleteMouseLeave}
					/>
				</>
			)}
		</Transformer>
	);
};

export default TransformerComponent;
