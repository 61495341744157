import { uniq } from 'lodash';
import { User } from 'services/api/users/userServiceTypes';
import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onReInviteUserStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		inviting: FetchStatus.PENDING
	}
});
export const onReInviteUserSuccess = (
	state: UsersState,
	action: {
		result: User;
	}
): UsersState => {
	const { result } = action;
	const byId = {
		...state.byId,
		[result.id]: result
	};
	const allIds = uniq([action.result.id, ...state.allIds]);
	return {
		...state,
		byId,
		allIds,
		fetchStatuses: {
			...state.fetchStatuses,
			inviting: FetchStatus.SUCCESS
		}
	};
};
export const onReInviteUserFailed = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		inviting: FetchStatus.FAILURE
	}
});
