import { retrieveCompanyGeneralConfigurationAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyGeneralConfigurationRequest,
	RetrieveCompanyGeneralConfigurationResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyGeneralConfigurationRequest,
	RetrieveCompanyGeneralConfigurationResponse,
	Error
>({
	api: Api.Companies.retrieveCompanyGeneralConfiguration,
	async: retrieveCompanyGeneralConfigurationAsync
});
