import { DraftsState } from '../draftsState';
import { SetActiveDraftTypeParams } from '../actions/setActiveDraftType';

export const onSetActiveDraftType = (
	state: DraftsState,
	{ activeDraftType, activeDraftTypeAlias }: SetActiveDraftTypeParams
): DraftsState => ({
	...state,
	context: {
		...state.context,
		activeDraftType,
		activeDraftTypeAlias
	}
});
