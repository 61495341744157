import * as React from 'react';

import { Flex } from 'components/Styles/index';
import Loading from 'components/Loading/Loading';

import styles from '../Settings.module.scss';

import { Group } from 'services/api/groups/groupsServiceTypes';

const TITLE = 'Visible to groups';

const Permissions: React.SFC<{
	threadPermissions: Group[];
	loading?: boolean;
}> = props => {
	const { loading, threadPermissions } = props;
	if (!threadPermissions || threadPermissions.length < 1) {
		return null;
	}
	return (
		<section className={styles.setting}>
			<h4 className={styles.noCaps}>{TITLE}</h4>
			{!loading ? (
				threadPermissions.map((permission, i) => {
					return (
						<Flex
							align="center"
							className={styles.row}
							key={`${permission.id}-${i}`}
						>
							{permission.name}
							{permission.isDeleted && ' - DELETED'}
						</Flex>
					);
				})
			) : (
				<Loading size="xs" inline />
			)}
		</section>
	);
};

export default Permissions;
