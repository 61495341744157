import { Success } from 'typescript-fsa';
import { put } from 'redux-saga/effects';
import { retrieveUploadedDocumentsAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse,
	PortCallDocumentsMetadataActionCode
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import Api from 'services/api';
import { notify } from 'store/notifications/actions';
import { DOCUMENTS_DATA_INCONSISTENCY } from 'store/portCallDocuments/constants';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

export const hasUploadOperationDocumentsMetadata = (
	response: RetrieveUploadedDocumentsResponse
) => {
	return !!getEntityMetadataAction<PortCallDocumentsMetadataActionCode>(
		response.metadata.actions,
		PortCallDocumentsMetadataActionCode.UPLOAD_OPERATION_DOCUMENTS
	);
};

export default makeTakeLatestWatcher<
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse,
	Error
>({
	api: Api.PortCallDocuments.retrieveUploadedDocuments,
	async: retrieveUploadedDocumentsAsync,
	*onSuccess({
		params,
		result
	}: Success<
		RetrieveUploadedDocumentsRequest,
		RetrieveUploadedDocumentsResponse
	>) {
		if (params.showDataInconsistencyAlert) {
			if (!hasUploadOperationDocumentsMetadata(result)) {
				yield put(notify.warning(DOCUMENTS_DATA_INCONSISTENCY));
			}
		}
	}
});
