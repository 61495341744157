import {
	RetrieveCompaniesContactDetailsResponse,
	RetrieveCompaniesContactDetailsRequest
} from 'services/api/companies/companiesServiceTypes';

import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANIES_CONTACT_DETAILS';
export const retrieveCompaniesContactDetailsAsync = actionCreator.async<
	RetrieveCompaniesContactDetailsRequest,
	RetrieveCompaniesContactDetailsResponse,
	Error
>(ACTION_NAME);
export const retrieveCompaniesContactDetails = actionCreator<
	RetrieveCompaniesContactDetailsRequest
>(ACTION_NAME);

export const resetCompaniesContactDetails = actionCreator(
	'RESET_COMPANIES_CONTACT_DETAILS'
);
