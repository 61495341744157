import { OidcUser } from './authTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { LabeledValueItem, Entity } from 'app-types';
import { UserAuth } from 'services/api/users/userServiceTypes';
import { Permission } from 'services/api/permissions/permissionsServiceTypes';

export interface AuthActAsConfig {
	urlPrefix?: string;
	returnUrl?: string;
	allowedUrlPrefixes?: string[];
	disabledMessage?: string;
	leaveModeText?: string;
}

export type AuthActAsOption = Entity & {
	agentId?: string;
	disabled?: boolean;
};

export interface AuthState {
	auth: OidcUser | null;
	authUser: UserAuth | null;
	actingUser: UserAuth | null;
	authPermissions: Permission[];
	actingPermissions: Permission[];

	actAs: {
		value: LabeledValueItem | null;
		canActAs: boolean;
		historyBlockTriggerCounter: number;
		options: AuthActAsOption[];
		config: AuthActAsConfig;
	};

	fetchStatuses: {
		retrieveAuthUser: FetchStatus;
	};

	isAuthenticating: boolean;
}
export const initialState: AuthState = {
	auth: null,
	authUser: null,
	authPermissions: [],
	actingPermissions: [],
	actingUser: null,

	actAs: {
		value: null,
		canActAs: false,
		options: [],
		config: {},
		historyBlockTriggerCounter: 1
	},

	fetchStatuses: {
		retrieveAuthUser: FetchStatus.IDLE
	},

	isAuthenticating: true
};
