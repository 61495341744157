import { UpdateKpiDetailsByIdRequest as Request } from 'services/api/opticConfiguration/opticConfigServiceTypes';
import { OpticConfigState } from '../opticConfigState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateKpiDetailsById = makeFetchStatusReducers<
	OpticConfigState,
	'fetchStatuses',
	Request,
	void
>('fetchStatuses', 'updateKPIDetailsById');
