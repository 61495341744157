import actionCreator from '../portCallOperationsActionCreator';
import { UpdatePortCallEventRequest } from 'services/api/portCall/portCallServiceTypes';

export type ResetPortCallEventPayload = Pick<
	UpdatePortCallEventRequest,
	'portCallId' | 'eventId' | 'realDatePlt' | 'comment'
>;

const ACTION_NAME = 'RESET_EVENT';

export const resetPortCallEventAsync = actionCreator.async<
	ResetPortCallEventPayload,
	void,
	Error
>(ACTION_NAME);

export const resetPortCallEvent = actionCreator<string>(ACTION_NAME);
