import { retrievePortCallMeasurementsAccessAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess[],
	Error
>({
	api: Api.PortCallMeasurements.retrievePortCallMeasurementsAccess,
	async
});
