import * as React from 'react';
import classNames from 'classnames';
import styles from './Title.module.scss';
import { ButtonType } from 'components/types';

enum HeadingClass {
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	H4 = 'h4',
	H5 = 'h5',
	H6 = 'h6'
}

interface HeadingProps {
	componentClass: HeadingClass;
	className?: string;
	style?: object;
}
const Heading: React.FC<HeadingProps> = props => {
	const Tag = props.componentClass;
	return (
		<Tag
			className={classNames(styles.root, props.className)}
			style={props.style}
		>
			{props.children}
		</Tag>
	);
};

interface TitleProps {
	marginBottom?: number | string;
	className?: string;
	type?: ButtonType;
}

export class Title extends React.PureComponent<TitleProps> {
	static displayName = 'Title';

	static Grid: React.ComponentType<TitleProps> = ({
		children,
		marginBottom,
		className,
		type,
		...props
	}) => (
		<Heading
			{...props}
			componentClass={HeadingClass.H3}
			className={classNames(
				styles.gridTitle,
				type ? styles[type] : undefined,
				className
			)}
			style={{ marginBottom }}
		>
			{children}
		</Heading>
	);

	static Label: React.ComponentType<TitleProps> = ({
		children,
		marginBottom,
		className,
		type,
		...props
	}) => (
		<Heading
			{...props}
			componentClass={HeadingClass.H6}
			className={classNames(
				styles.labelTitle,
				type ? styles[type] : undefined,
				className
			)}
			style={{ marginBottom }}
		>
			{children}
		</Heading>
	);

	static Hint: React.ComponentType<TitleProps> = ({
		children,
		marginBottom,
		className,
		type,
		...props
	}) => (
		<Heading
			{...props}
			componentClass={HeadingClass.H1}
			className={classNames(
				styles.hint,
				type ? styles[type] : undefined,
				className
			)}
			style={{ marginBottom }}
		>
			{children}
		</Heading>
	);

	/**
	 * UI Kit Reference: Heading 2
	 * Subtitles, Links, Dropdown Button
	 */
	static H2: React.ComponentType<TitleProps> = ({
		children,
		marginBottom,
		className,
		type,
		...props
	}) => (
		<Heading
			{...props}
			componentClass={HeadingClass.H2}
			className={classNames(
				styles.h2,
				type ? styles[type] : undefined,
				className
			)}
			style={{ marginBottom }}
		>
			{children}
		</Heading>
	);

	/**
	 * UI Kit Reference: Heading 4
	 * Header labels / Data Table labels
	 */
	static H4: React.ComponentType<TitleProps> = ({
		children,
		marginBottom,
		className,
		...props
	}) => (
		<Heading
			{...props}
			componentClass={HeadingClass.H4}
			className={classNames(styles.h4, className)}
			style={{ marginBottom }}
		>
			{children}
		</Heading>
	);

	static Subject: React.ComponentType<{
		unread?: boolean;
		componentClass?: HeadingClass;
	}> = ({ children, unread, componentClass = HeadingClass.H4, ...props }) => {
		return (
			<Heading
				{...props}
				componentClass={componentClass}
				className={classNames(styles.subjectTitle, {
					[styles.subjectTitleUnread]: unread
				})}
			>
				{unread && <i className={styles.unreadDot} />}
				<span className={styles.ellipsis}>{children}</span>
			</Heading>
		);
	};

	render() {
		const { children, marginBottom, ...props } = this.props;

		return (
			<Heading
				{...props}
				componentClass={HeadingClass.H1}
				className={styles.h1}
				style={{ marginBottom }}
			>
				{children}
			</Heading>
		);
	}
}

export default Title;
