import {
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_FINANCE_REVISIONS = 'RETRIEVE_FINANCE_REVISIONS';

export const retrieveFinanceRevisionsAsync = actionCreator.async<
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse,
	Error
>(RETRIEVE_FINANCE_REVISIONS);

export const retrieveFinanceRevisions = actionCreator<
	Partial<RetrieveFinanceRevisionsRequest> | undefined
>(RETRIEVE_FINANCE_REVISIONS);
