import { Action } from 'typescript-fsa';
import { select, takeLatest } from 'redux-saga/effects';

import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	deletePortCallDocumentPageAsync,
	DeletePortCallDocumentPagePayload
} from '../actions';
import {
	getPortCallDocumentPagesLen,
	getPortCallDocumentConcurrencyToken
} from '../selectors';
import Api from 'services/api';

import { deleteDocumentPageWorker } from 'store/documents/sagas/deleteDocumentPageSaga';

function* worker({ payload }: Action<DeletePortCallDocumentPagePayload>) {
	const count: number = yield select(getPortCallDocumentPagesLen);
	const portCallId: string = yield select(getActivePortCallId);
	const concurrencyToken: number = yield select(
		getPortCallDocumentConcurrencyToken
	);
	yield deleteDocumentPageWorker<
		DeletePortCallDocumentPagePayload,
		null,
		Error
	>({
		count,
		returnUrl: `/portcalls/${portCallId}/operations/documents`,
		params: {
			...payload,
			portCallId,
			concurrencyToken
		},
		async: deletePortCallDocumentPageAsync,
		api: Api.PortCallDocuments.deleteDocumentPage
	});
}

export default function*() {
	yield takeLatest(deletePortCallDocumentPageAsync.type, worker);
}
