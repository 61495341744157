import React from 'react';
import { connect } from 'react-redux';
import { InfiniteTable } from 'components';
import { getUserListColumns } from './GroupsColumns';
import { PATHS } from 'sections/App/RouteParams';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { Company } from 'services/api/companies/companiesServiceTypes';
import { retrieveGroups } from 'store/groups/actions';
import {
	getAllGroupsSelector,
	groupsHasMoreSelector,
	isGroupsFetchingSelector,
	isGroupAddingSelector,
	getDefaultCompanyForGroupsFilters
} from 'store/groups/selectors';
import { navigateTo } from 'utils';
import { AppState } from 'store-types';
import classNames from 'classnames';
import styles from '../Groups.module.scss';
import tableStyles from 'components/antd/Table/Table.module.scss';
import { AppNotifications } from 'containers';

interface GroupsTableProps {
	// from mapStateToProps
	groups: Group[];
	groupsFetching: boolean;
	hasMore: boolean;
	defaultCompany: Company | undefined;
	// from mapDispatchToProps
	retrieveGroups: typeof retrieveGroups;
}

class GroupsTable extends React.Component<GroupsTableProps> {
	onRow = (record: Group) => ({
		onClick: () => this.goToGroup(record)
	});

	goToGroup = (record: Group) => {
		navigateTo(`/${PATHS.groups}/${record.id}`);
	};

	retrieveGroups = () => {
		const { defaultCompany } = this.props;
		this.props.retrieveGroups({
			companyId: defaultCompany?.id
		});
	};

	public render() {
		const { groups, groupsFetching, hasMore } = this.props;
		return (
			<AppNotifications.Table
				entityName="Groups"
				actionType={retrieveGroups.type}
			>
				{({ failedLoading }) => (
					<InfiniteTable
						className={classNames(
							styles.groupsTable,
							tableStyles.headerShadowed
						)}
						columns={getUserListColumns()}
						dataSource={groups}
						lazyLoad={{
							loading: groupsFetching,
							onEnter: this.retrieveGroups,
							hasMore,
							failedLoading
						}}
						loading={groupsFetching}
						onRow={this.onRow}
					/>
				)}
			</AppNotifications.Table>
		);
	}
}

export default connect(
	(state: AppState) => ({
		groups: getAllGroupsSelector(state),
		hasMore: groupsHasMoreSelector(state),
		groupsFetching:
			isGroupsFetchingSelector(state) || isGroupAddingSelector(state),
		defaultCompany: getDefaultCompanyForGroupsFilters(state)
	}),
	{
		retrieveGroups
	}
)(GroupsTable);
