import actionCreator from '../portCallOperationsActionCreator';
import {
	UpdatePortCallEventRequest,
	UpdateEventResponse
} from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'UPDATE_EVENT';

export type UpdatePortCallEventPayload = Pick<
	UpdatePortCallEventRequest,
	'eventId' | 'realDatePlt' | 'comment'
>;

export const updatePortCallEventAsync = actionCreator.async<
	UpdatePortCallEventPayload,
	UpdateEventResponse,
	Error
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updatePortCallEvent = actionCreator<UpdatePortCallEventPayload>(
	ACTION_NAME
);
