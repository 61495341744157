import actionCreator from '../financeActionCreator';
import { CreateFinanceDocumentsRequest } from 'services/api/finance/financeServiceTypes';

const CREATE_FINANCE_DOCUMENTS = 'CREATE_FINANCE_DOCUMENTS';
export const createFinanceDocumentsAsync = actionCreator.async<
	CreateFinanceDocumentsRequest,
	null,
	Error
>(CREATE_FINANCE_DOCUMENTS, {
	templateParams: {
		entityName: 'Finance Document'
	}
});

export const createFinanceDocuments = actionCreator<
	CreateFinanceDocumentsRequest
>(CREATE_FINANCE_DOCUMENTS);
