import queryString from 'query-string';
import { AxiosTypedPromise } from 'services/api/apiTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import client from 'services/client';

import {
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse,
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse,
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse,
	EditUploadedDocumentCommentRequest,
	EditUploadedDocumentCommentResponse,
	CreatePortCallDocumentRequest,
	PortCallDocumentNotIssuedRequest,
	DeletePortCallDocumentRequest,
	DownloadPortCallDocumentRequest,
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse,
	DeletePortCallDocumentPageRequest,
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse
} from './portCallDocumentsServiceTypes';

/**
 * Retrieve Required Documents for Operations
 * @see https://conf.dataart.com/display/IPP/%5BPCDOA%5D+PortCall+Documents+-+API+documentation#expand-GetPortCallRequiredDocumentsendpoint1
 */
const retrieveRequiredDocuments = ({
	portCallId
}: RetrieveRequiredDocumentsRequest) =>
	client.get<RetrieveRequiredDocumentsResponse>(
		`portcalls/${portCallId}/required-documents`
	);

/**
 * Retrieve Allowed Documents for Operations
 * @see https://conf.dataart.com/display/IPP/%5BPCDOA%5D+PortCall+Documents+-+API+documentation#expand-GetPortCallDocumentsForContextualizationendpoint
 */
const retrieveAllowedDocuments = ({
	portCallId
}: RetrieveAllowedDocumentsRequest) =>
	client.get<RetrieveAllowedDocumentsResponse>(
		`portcalls/${portCallId}/allowed-documents`
	);

/**
 * Be careful if we remove metadata we have to update interface RetrieveUploadedDocumentsRequest
 */
const retrieveUploadedDocuments = ({
	portCallId
}: RetrieveUploadedDocumentsRequest) =>
	client.get<RetrieveUploadedDocumentsResponse>(
		`portcalls/${portCallId}/uploaded-documents`,
		{
			params: { expand: 'metadata' }
		}
	);

const editUploadedDocumentComment = ({
	portCallId,
	documentId,
	data,
	concurrencyToken
}: EditUploadedDocumentCommentRequest) =>
	client.patch<EditUploadedDocumentCommentResponse>(
		`/portcalls/${portCallId}/documents/${documentId}`,
		{
			...data,
			concurrencyToken
		}
	);

const createPortCallDocument = ({
	portCallId,
	data
}: CreatePortCallDocumentRequest) =>
	client.post(`/portcalls/${portCallId}/documents`, data);

const markPortCallDocumentNotIssued = ({
	portCallId,
	portCallPlaceholderId,
	data,
	...params
}: PortCallDocumentNotIssuedRequest) =>
	client.post(
		`/portcalls/${portCallId}/documents/placeholders/${portCallPlaceholderId}/not-issued`,
		{ ...data, ...params }
	);

const deleteDocument = ({
	portCallId,
	documentId,
	...params
}: DeletePortCallDocumentRequest) =>
	client.delete(`/portcalls/${portCallId}/documents/${documentId}`, {
		params
	});

const deleteDocumentPage = ({
	portCallId,
	documentId,
	actualNumber,
	...params
}: DeletePortCallDocumentPageRequest) =>
	client.delete(
		`/portcalls/${portCallId}/documents/${documentId}/pages/${actualNumber}`,
		{
			params
		}
	);

const retrievePortCallDocumentImage = ({
	portCallId,
	documentId,
	page
}: RetrievePortCallDocumentImageRequest) =>
	client.get<RetrievePortCallDocumentImageResponse>(
		`/portcalls/${portCallId}/documents/${documentId}/pages/${page}`
	);

/**
 * POST to get the downloadUrl of the Document if exist
 */
const requestPostPortCallDocumentUrl = ({
	portCallId,
	...data
}: DownloadPortCallDocumentRequest): AxiosTypedPromise<
	RetrieveDownloadDocumentResponse
> => client.post(`/portcalls/${portCallId}/documents/download`, data);

/**
 * GET to poll until downloadUrl of the Document arrives
 */
const requestGetPortCallDocumentUrl = ({
	portCallId,
	...data
}: DownloadPortCallDocumentRequest) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/portcalls/${portCallId}/documents/download`,
		{
			params: data,
			paramsSerializer: (requestParams: DownloadPortCallDocumentRequest) =>
				queryString.stringify(requestParams, { arrayFormat: 'none' })
		}
	);

const retrievePortCallDocument = ({
	portCallId,
	documentId
}: RetrievePortCallDocumentRequest) =>
	client.get<RetrievePortCallDocumentResponse>(
		`portcalls/${portCallId}/documents/${documentId}`
	);

export default {
	createPortCallDocument,
	deleteDocument,
	deleteDocumentPage,
	retrievePortCallDocumentImage,
	requestPostPortCallDocumentUrl,
	requestGetPortCallDocumentUrl,
	editUploadedDocumentComment,
	markPortCallDocumentNotIssued,
	retrieveAllowedDocuments,
	retrieveRequiredDocuments,
	retrieveUploadedDocuments,
	retrievePortCallDocument
};
