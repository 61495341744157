import React from 'react';
import { WebhookEndPointDetails } from 'services/api/developerPortal/developerPortalServiceTypes';
import { Table, Button, Tag } from 'components/antd';
import { ColumnProps } from 'antd/lib/table';
import WebHookDetailsModal from './WebHookEndPointModal/WebHookDetailsModal';
import { WEB_HOOK_END_POINT_MODAL, EventsMap, AuthTypeMap } from '../constants';
import { useDispatch } from 'react-redux';
import { openModal, closeModal } from 'store/modals/actions';
import { setEditingWebHook } from 'store/developerPortal/actions';

interface WebHookDetailsTableProps {
	fields: WebhookEndPointDetails[];
	onSubmit: (webHook: WebhookEndPointDetails) => void;
	onDelete: (id: string) => void;
}

const webHookTableColumns = (
	onEditWebHook: (id: string) => void,
	onDelete: (id: string) => void
): Array<ColumnProps<WebhookEndPointDetails>> => {
	return [
		{
			title: 'URL',
			dataIndex: 'url',
			width: '20%'
		},
		{
			title: 'Data Format',
			dataIndex: 'dataFormat',
			width: '10%'
		},
		{
			title: 'Events',
			dataIndex: 'events',
			width: '30%',
			render: events => (
				<>
					{events.map((event: string) => (
						<Tag key={event}>{EventsMap[event]}</Tag>
					))}
				</>
			)
		},
		{
			title: 'Authentication Type',
			dataIndex: 'authenticationType',
			width: '10%',
			render: (authType: string) => <>{AuthTypeMap[authType]}</>
		},
		{
			dataIndex: 'id',
			width: '10%',
			align: 'right',
			render: (_value, record: WebhookEndPointDetails) => (
				<>
					<Button
						transparent
						type="primary"
						icon="edit"
						onClick={() => onEditWebHook(record.id)}
					/>
					<Button
						transparent
						type="danger"
						icon="delete"
						onClick={() => onDelete(record.id)}
					/>
				</>
			)
		}
	];
};

export const WebHookDetailsTable = ({
	fields,
	onSubmit,
	onDelete
}: WebHookDetailsTableProps) => {
	const dispatch = useDispatch();
	const onAddWebHook = () => {
		dispatch(openModal(WEB_HOOK_END_POINT_MODAL));
	};
	const hideModal = () => {
		dispatch(setEditingWebHook(''));
		dispatch(closeModal(WEB_HOOK_END_POINT_MODAL));
	};
	const onEditWebHook = (id: string) => {
		dispatch(setEditingWebHook(id));
		dispatch(openModal(WEB_HOOK_END_POINT_MODAL));
	};
	return (
		<>
			<Table
				dataSource={fields}
				columns={webHookTableColumns(onEditWebHook, onDelete)}
				showHeader
			/>
			<Button type="primary" transparent icon="plus" onClick={onAddWebHook}>
				Add web-hook endpoint
			</Button>
			<WebHookDetailsModal hideModal={hideModal} onSubmit={onSubmit} />
		</>
	);
};
