import {
	RetrieveCompanyGeneralConfigurationRequest,
	RetrieveCompanyGeneralConfigurationResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANY_GENERAL_CONFIGURATION';
export const retrieveCompanyGeneralConfigurationAsync = actionCreator.async<
	RetrieveCompanyGeneralConfigurationRequest,
	RetrieveCompanyGeneralConfigurationResponse,
	Error
>(ACTION_NAME);
export const retrieveCompanyGeneralConfiguration = actionCreator<
	RetrieveCompanyGeneralConfigurationRequest
>(ACTION_NAME);
