import { retrieveMessagesMailboxesAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse,
	Error
>({
	api: Api.Mailboxes.retrieveMailboxes,
	async: retrieveMessagesMailboxesAsync
});
