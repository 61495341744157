import { createSelector } from 'reselect';
import { AppState } from 'store-types';

const getMessagesMailboxesMap = (state: AppState) =>
	state.messages.mailboxesById;

export const getDefaultMessagesMailboxes = createSelector(
	getMessagesMailboxesMap,
	mailboxesMap =>
		Object.values(mailboxesMap).filter(mailbox => mailbox.isDefault)
);
