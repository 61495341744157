import * as React from 'react';
import { connect } from 'react-redux';
import { getIsPortJobServiceSelected } from 'store/form/supplierInvoiceContextualization/selectors';
import { SupplierInvoicePortJobService } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { Gap } from 'components';
import { GapProps } from 'components/Styles/Gap/Gap';
import { AppState } from 'store-types';

interface InvoiceDetailRowProps extends Partial<GapProps> {
	portJobId: string;
	service: SupplierInvoicePortJobService;
	// from mapStateToProps
	isPortJobServiceSelected: boolean;
}

const InvoiceDetailRow: React.SFC<InvoiceDetailRowProps> = ({
	isPortJobServiceSelected,
	children,
	...gapProps
}) => {
	return (
		<Gap
			isBlock
			top="md"
			bottom={!isPortJobServiceSelected ? 'md' : undefined}
			{...gapProps}
		>
			{children}
		</Gap>
	);
};

interface ConnectProps
	extends Pick<InvoiceDetailRowProps, 'portJobId' | 'service'>,
		Partial<Pick<InvoiceDetailRowProps, 'isPortJobServiceSelected'>> {}

const InvoiceDetailRowConnected = connect(
	(
		state: AppState,
		{ portJobId, service: { id }, isPortJobServiceSelected }: ConnectProps
	) => ({
		isPortJobServiceSelected: isPortJobServiceSelected
			? isPortJobServiceSelected
			: getIsPortJobServiceSelected(state, portJobId, id)
	})
)(InvoiceDetailRow);

export { InvoiceDetailRow };
export default InvoiceDetailRowConnected;
