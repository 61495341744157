import {
	RetrieveThreadMessageByIdParams,
	Message
} from 'services/api/messages/messagesServiceTypes';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

const RETRIEVE_THREAD_MESSAGE = 'thread/RETRIEVE_THREAD_MESSAGE';

export const retrieveThreadMessageByIdAsync = actionCreator.async<
	RetrieveThreadMessageByIdParams,
	Message | null,
	Error
>(RETRIEVE_THREAD_MESSAGE);

export const retrieveThreadMessageById = actionCreator<
	RetrieveThreadMessageByIdParams
>(retrieveThreadMessageByIdAsync.type);
