import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { get } from 'lodash';
import {
	addPortCallEventAsync,
	deletePortCallEventAsync,
	restorePortCallEventAsync,
	updatePortCallEventAsync,
	updatePortCallEventJobAssignmentAsync,
	retrievePortCallSections
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	isBadRequestError,
	isNotFoundError,
	isConcurrencyError
} from 'services/api/apiErrorUtils';

export function* handleConcurrentFailedRequestsWorker(
	/* eslint-disable @typescript-eslint/no-explicit-any */
	action: Action<any>
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	const { payload } = action;
	const eventId = get(payload, 'params.eventId');
	const error = get(payload, 'error');
	if (
		isBadRequestError(error) ||
		isNotFoundError(error) ||
		isConcurrencyError(error)
	) {
		yield put(
			retrievePortCallSections({
				portCallId,
				eventId,
				isSilent: true
			})
		);
	}
}

export function* handleConcurrentSuccessRequestsWorker(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	yield put(
		retrievePortCallSections({
			portCallId,
			isSilent: true
		})
	);
}

export default function* reactToChangesForEventsWatcher(): SagaIterator {
	yield all([
		takeLatest(
			[
				addPortCallEventAsync.failed,
				deletePortCallEventAsync.failed,
				restorePortCallEventAsync.failed,
				updatePortCallEventAsync.failed,
				updatePortCallEventJobAssignmentAsync.failed
			],
			handleConcurrentFailedRequestsWorker
		),
		takeLatest(
			[
				addPortCallEventAsync.done,
				deletePortCallEventAsync.done,
				restorePortCallEventAsync.done,
				updatePortCallEventAsync.done,
				updatePortCallEventJobAssignmentAsync.done
			],
			handleConcurrentSuccessRequestsWorker
		)
	]);
}
