import React, { useCallback } from 'react';
import { ReduxFormSubmitButton } from 'components';
import { Modal } from 'components/antd';
import ModalForm from './InviteUserForm';
import {
	UsersModalsPropsBase,
	INVITE_USER_FORM,
	CANCEL_INVITE_MODAL,
	InviteUserFormData
} from '../UsersModalsTypes';
import { useInviteUserFormInitialValues } from './InviteUserForm.hooks';
import { AppState } from 'store-types';
import { closeModal, openModal } from 'store/modals/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isModalVisible } from 'store/modals/selectors';

export type InviteUserModalProps = UsersModalsPropsBase<InviteUserFormData>;
function InviteUserModal({
	modalValue,
	submitting,
	onSubmit,
	onCancel
}: InviteUserModalProps) {
	const dispatch = useDispatch();
	const {
		initialValues,
		isCurrentUserCompanyAssigned
	} = useInviteUserFormInitialValues({ modalValue });

	const isCancelModalVisible = useSelector((state: AppState) =>
		isModalVisible(state, CANCEL_INVITE_MODAL)
	);

	const showConfirmJobModal = useCallback(() => {
		dispatch(openModal({ name: CANCEL_INVITE_MODAL }));
	}, [dispatch]);

	const hideConfirmJobModal = useCallback(() => {
		dispatch(closeModal({ name: CANCEL_INVITE_MODAL }));
	}, [dispatch]);

	const title = !modalValue ? 'Invite new User' : 'Re-send invitation';

	return (
		<>
			<Modal
				visible
				title={title}
				onCancel={showConfirmJobModal}
				okButton={
					<ReduxFormSubmitButton
						form={INVITE_USER_FORM}
						type="primary"
						loading={submitting}
						submitForm
					>
						Submit
					</ReduxFormSubmitButton>
				}
			>
				<ModalForm
					form={INVITE_USER_FORM}
					initialValues={initialValues}
					isCurrentUserCompanyAssigned={isCurrentUserCompanyAssigned}
					onSubmit={onSubmit}
				/>
			</Modal>
			<Modal
				visible={isCancelModalVisible}
				cancelText={'No'}
				okText={'Yes'}
				onOk={() => {
					onCancel();
					hideConfirmJobModal();
				}}
				onCancel={hideConfirmJobModal}
				closable={false}
			>
				All entered information will be lost. Are you sure you want to cancel
				the process?
			</Modal>
		</>
	);
}

export default InviteUserModal;
