import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	acknowledgeVPAsync,
	acknowledgeVPWithCommentAsync,
	dragOperationUnit,
	allocateOperationUnit,
	setEventTime,
	startEditVesselProgramme,
	stopEditVesselProgramme,
	updateVPMetadata,
	addVPComment,
	// Retrieve VesselProgramme
	retrieveVPAsync,
	// Update terminal and berth
	retrieveTerminalsAsync,
	updateTerminal,
	retrieveBerthsAsync,
	updateBerth,
	resetVesselProgramme,
	// split operation unit
	openSplitOperationUnitModal,
	cancelSplitOperationUnitModal,
	splitOperationUnit,
	// merge operation unit
	openMergeOperationUnitModal,
	cancelMergeOperationUnitModal,
	mergeOperationUnit,
	// Unnalocate Operation Unit
	unallocateOperationUnit,
	// Retrieve events for allocated unit
	retrieveEventsAsync,
	// Set Active POI
	setActivePOIId,
	// Add POI
	addPOIAsync,
	// Delete POI
	deletePOIAsync,
	// Custody Line
	moveDownCustodyLine,
	moveUpCustodyLine,
	// Update Buoy
	updateBuoy,
	// Update Canal
	updateCanal,
	// Update vp processing
	updateVPprocessing,
	updateVPHash,
	updateSaveVPRestrictions,
	// Update Anchorage
	updateAnchorage,
	// track
	trackVPAsync,
	// commit
	commitVPAsync,
	saveVesselProgrammeAsync,
	updateVPFromFinanceAsync
} from 'store/vesselProgramme/actions';
import {
	onAcknowledgeVP,
	onAcknowledgeVPWithComment,
	onUpdateVPProcessing,
	onResetVesselProgramme,
	onUpdateVPHash,
	onUpdateSaveVPRestrictions,
	onDragOperationUnit,
	onAllocateOperationUnit,
	onSetEventTime,
	onStartEditVesselProgramme,
	onStopEditVesselProgramme,
	onRetrieveVPStarted,
	onRetrieveVPSuccess,
	onRetrieveVPFailed,
	onRetrieveTerminalsStarted,
	onRetrieveTerminalsSuccess,
	onRetrieveTerminalsFailed,
	onUpdateTerminal,
	onRetrieveBerthsStarted,
	onRetrieveBerthsSuccess,
	onRetrieveBerthsFailed,
	onUpdateBerth,
	onSaveVesselProgramme,
	onOpenSplitOperationUnitModal,
	onCancelSplitOperationUnitModal,
	onSplitOperationUnit,
	onOpenMergeOperationUnitModal,
	onCancelMergeOperationUnitModal,
	onMergeOperationUnit,
	onUnallocateOperationUnit,
	onRetrieveEventsStarted,
	onRetrieveEventsSuccess,
	onRetrieveEventsFailed,
	onSetActivePOIId,
	onAddPOI,
	onDeletePOIStarted,
	onDeletePOIFailed,
	onDeletePOISuccess,
	onMoveUpCustodyLine,
	onMoveDownCustodyLine,
	onUpdateBuoy,
	onUpdateCanal,
	onTrack,
	onCommit,
	onAddVPComment,
	onUpdateVPMetadata,
	onUpdateAnchorage,
	onDownloadVPRevisionReducer,
	pendingAlerts,
	onUpdateVPFromFinance
} from './reducers';
import { initialState, VesselProgrammeState } from './vesselProgrammeState';
import { downloadVPRevisionAsync } from './actions/downloadVPRevision';

export default reducerWithInitialState(initialState)
	// updateVPMetadata
	.case(updateVPMetadata, onUpdateVPMetadata)
	// commit
	.case(trackVPAsync.started, onTrack.started)
	.case(trackVPAsync.done, onTrack.done)
	.case(trackVPAsync.failed, onTrack.failed)
	// track
	.case(commitVPAsync.started, onCommit.started)
	.case(commitVPAsync.done, onCommit.done)
	.case(commitVPAsync.failed, onCommit.failed)
	// AcknowledgeVP
	.case(acknowledgeVPAsync.started, onAcknowledgeVP.started)
	.case(acknowledgeVPAsync.done, onAcknowledgeVP.done)
	.case(acknowledgeVPAsync.failed, onAcknowledgeVP.failed)
	// Acknowledge VP with comment
	.case(
		acknowledgeVPWithCommentAsync.started,
		onAcknowledgeVPWithComment.started
	)
	.case(acknowledgeVPWithCommentAsync.done, onAcknowledgeVPWithComment.done)
	.case(acknowledgeVPWithCommentAsync.failed, onAcknowledgeVPWithComment.failed)
	// Add Comment
	.case(addVPComment, onAddVPComment)
	// Update POI
	.case(updateAnchorage, onUpdateAnchorage)
	.case(updateBerth, onUpdateBerth)
	.case(updateBuoy, onUpdateBuoy)
	.case(updateCanal, onUpdateCanal)
	.case(updateTerminal, onUpdateTerminal)
	// Drag n' Drop Operations
	.case(dragOperationUnit, onDragOperationUnit)
	.case(allocateOperationUnit, onAllocateOperationUnit)
	.case(unallocateOperationUnit, onUnallocateOperationUnit)
	// Set Event Time
	.case(setEventTime, onSetEventTime)
	// Edit Vessel Programme
	.case(startEditVesselProgramme, onStartEditVesselProgramme)
	.case(stopEditVesselProgramme, onStopEditVesselProgramme)
	// Retrieve Terminals
	.case(retrieveTerminalsAsync.started, onRetrieveTerminalsStarted)
	.case(retrieveTerminalsAsync.done, onRetrieveTerminalsSuccess)
	.case(retrieveTerminalsAsync.failed, onRetrieveTerminalsFailed)
	// Retrieve Berths
	.case(retrieveBerthsAsync.started, onRetrieveBerthsStarted)
	.case(retrieveBerthsAsync.done, onRetrieveBerthsSuccess)
	.case(retrieveBerthsAsync.failed, onRetrieveBerthsFailed)
	// Retrieve VesselProgramme
	.case(retrieveVPAsync.started, onRetrieveVPStarted)
	.case(retrieveVPAsync.done, onRetrieveVPSuccess)
	.case(retrieveVPAsync.failed, onRetrieveVPFailed)
	// Save VesselProgramme
	.case(saveVesselProgrammeAsync.started, onSaveVesselProgramme.started)
	.case(saveVesselProgrammeAsync.done, onSaveVesselProgramme.done)
	.case(saveVesselProgrammeAsync.failed, onSaveVesselProgramme.failed)
	// resetVesselProgramme
	.case(resetVesselProgramme, onResetVesselProgramme)
	// split operation unit
	.case(openSplitOperationUnitModal, onOpenSplitOperationUnitModal)
	.case(cancelSplitOperationUnitModal, onCancelSplitOperationUnitModal)
	.case(splitOperationUnit, onSplitOperationUnit)
	// merge operation unit
	.case(openMergeOperationUnitModal, onOpenMergeOperationUnitModal)
	.case(cancelMergeOperationUnitModal, onCancelMergeOperationUnitModal)
	.case(mergeOperationUnit, onMergeOperationUnit)
	// Retrieve Events
	.case(retrieveEventsAsync.started, onRetrieveEventsStarted)
	.case(retrieveEventsAsync.done, onRetrieveEventsSuccess)
	.case(retrieveEventsAsync.failed, onRetrieveEventsFailed)
	// Set Active POI
	.case(setActivePOIId, onSetActivePOIId)
	// Add POI
	.case(addPOIAsync.started, onAddPOI.started)
	.case(addPOIAsync.done, onAddPOI.done)
	.case(addPOIAsync.failed, onAddPOI.failed)
	// Delete POI
	.case(deletePOIAsync.started, onDeletePOIStarted)
	.case(deletePOIAsync.done, onDeletePOISuccess)
	.case(deletePOIAsync.failed, onDeletePOIFailed)
	// VP Processing
	.case(updateVPprocessing, onUpdateVPProcessing)
	.case(updateVPHash, onUpdateVPHash)
	.case(updateSaveVPRestrictions, onUpdateSaveVPRestrictions)
	// move custody transfer line
	.case(moveUpCustodyLine, onMoveUpCustodyLine)
	.case(moveDownCustodyLine, onMoveDownCustodyLine)
	// Download VP revision
	.case(downloadVPRevisionAsync.started, onDownloadVPRevisionReducer.started)
	.case(downloadVPRevisionAsync.done, onDownloadVPRevisionReducer.done)
	.case(downloadVPRevisionAsync.failed, onDownloadVPRevisionReducer.failed)
	.case(updateVPFromFinanceAsync.started, onUpdateVPFromFinance.started)
	.case(updateVPFromFinanceAsync.done, onUpdateVPFromFinance.done)
	.case(updateVPFromFinanceAsync.failed, onUpdateVPFromFinance.failed)
	.default((state: VesselProgrammeState, action) => ({
		...state,
		pendingAlerts: pendingAlerts(state.pendingAlerts, action) // attached nested reducer
	}));
