import { FinanceState } from '../financeState';
import { ToggleFinancePayload } from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onToggleFinance = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	ToggleFinancePayload,
	null
>('fetchStatuses', 'toggleFinance');
