import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { RetrieveBenchmarkUrlResponse } from 'services/api/benchmark/benchmarkServiceTypes';
import { retrieveBenchmarkUrl } from 'services/api/benchmark/benchmarkService';
import { retrieveBenchmarkUrlAsync } from '../actions/retrieveBenchmarkUrl';
import { Success } from 'typescript-fsa';

export default makeTakeLatestWatcher<null, RetrieveBenchmarkUrlResponse>({
	api: retrieveBenchmarkUrl,
	async: retrieveBenchmarkUrlAsync,
	*onSuccess({ result: { url } }: Success<null, RetrieveBenchmarkUrlResponse>) {
		if (url) {
			window.open(url, '_blank');
		}
	}
});
