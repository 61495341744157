import { Unit } from 'services/api/units/unitsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface UnitByCode {
	[code: string]: Unit;
}
export interface UnitsState {
	readonly byCode: UnitByCode;
	readonly allCodes: string[];
	readonly fetchStatuses: {
		retrieve: FetchStatus;
	};
}

export const initialState: UnitsState = {
	byCode: {},
	allCodes: [],
	fetchStatuses: {
		retrieve: FetchStatus.IDLE
	}
};
