import React from 'react';
import styles from 'components/AutocompleteSearch/AutocompleteSearch.module.scss';

const NotFound: React.FC<{ text?: string }> = props => (
	<div className={styles.menu}>
		<div className={styles.notFound}>{props.text || 'No results found'}</div>
	</div>
);

export default NotFound;
