import * as React from 'react';
import classNames from 'classnames';
import AntCheckbox, {
	CheckboxProps,
	CheckboxChangeEvent
} from 'antd/lib/checkbox/Checkbox';
import CheckboxGroup from './CheckboxGroup';
import { TooltipProps } from 'antd/lib/tooltip';
import { Tooltip } from 'components/antd';

import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';
import styles from './Checkbox.module.scss';

export interface CustomCheckboxProps extends CheckboxProps {
	tooltip?: TooltipProps;
	tabIndex?: number;
}

export class Checkbox extends React.Component<CustomCheckboxProps, {}> {
	static Group: typeof CheckboxGroup;
	static ReduxFormItem: CreateReduxField<CustomCheckboxProps>;
	static defaultProps: Partial<CustomCheckboxProps>;

	render() {
		const { tooltip, className, ...restProps } = this.props;

		const checkbox = (
			<AntCheckbox
				{...restProps}
				className={classNames(className, styles.root)}
			/>
		);

		return tooltip ? <Tooltip {...tooltip}>{checkbox}</Tooltip> : checkbox;
	}
}

Checkbox.defaultProps = AntCheckbox.defaultProps;
Checkbox.Group = CheckboxGroup;

/**
 * ReduxForm Checkbox connector
 */
const mapProps = customMap(props => ({
	checked: props.input.value || false,
	onChange: (event: CheckboxChangeEvent) => props.input.onChange(event)
}));

Checkbox.ReduxFormItem = createFieldComponent<CustomCheckboxProps>(
	Checkbox,
	mapProps
);

export default Checkbox;
