import actionCreatorFactory from 'typescript-fsa';

import { AddPortCallResponse } from 'services/api/portCalls/portCallsServiceTypes';
import { ErrorStatusCode } from 'services/api/apiErrorTypes';
const actionCreator = actionCreatorFactory();

const ADD_PORTCALL = 'portcalls/ADD_PORTCALL';
export const addPortCallAsync = actionCreator.async<
	null,
	AddPortCallResponse,
	Error
>(ADD_PORTCALL, {
	templateParams: {
		entityName: 'Port Call'
	},
	failed: {
		includeCorrelationId: [ErrorStatusCode.BAD_REQUEST]
	}
});
export const addPortCall = actionCreator(addPortCallAsync.type);
