import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { UncancelPortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { uncancelPortJobAsync } from 'store/portJobs/actions';
import {
	getPortJobConcurrencyTokenByCode,
	getActivePortJobCode
} from '../portJobsSelectors';
import { openModal, closeModal } from 'store/modals/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';

const apiCall = Api.PortJobs.uncancelPortJob;

export const executor = function*(): SagaIterator {
	yield put(
		openModal({
			name: 'uncancelPortJob',
			type: 'confirm',
			data: {
				description:
					'Are you sure you want to reopen the job? All job data will be restored at the same state as at the moment of cancellation.'
			}
		})
	);
	const {
		payload: { isConfirmed }
	} = yield take(closeModal.type);
	if (isConfirmed) {
		const portCallId = yield select(getActivePortCallId);
		const portJobCode = yield select(getActivePortJobCode);
		const concurrencyToken = yield select(
			getPortJobConcurrencyTokenByCode,
			portJobCode
		);
		const request = {
			portCallId,
			portJobCode,
			isCancelled: false,
			concurrencyToken
		};
		yield put(uncancelPortJobAsync.started(request));
		try {
			const response = yield call(apiCall, request);
			yield put(
				uncancelPortJobAsync.done({
					result: undefined,
					params: request,
					response
				})
			);
		} catch (error) {
			yield put(
				uncancelPortJobAsync.failed({
					error,
					params: request
				})
			);
		}
	}
};

export const cancelPortJobWatcher = makeTakeLatestWatcher<
	UncancelPortJobRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async: uncancelPortJobAsync
	},
	executor
);

export default cancelPortJobWatcher;
