import { RetrieveDashboardPortCallsSummaryResponse } from 'services/api/dashboard/dashboardServiceTypes';
import actionCreator from 'store/dashboard/dashboardActionCreator';

const ACTION_NAME = 'RETRIEVE_DASHBOARD_PORTCALLS_SUMMARY';
export const retrieveDashboardPortCallsSummaryAsync = actionCreator.async<
	undefined,
	RetrieveDashboardPortCallsSummaryResponse,
	Error
>(ACTION_NAME);

export const retrieveDashboardPortCallsSummary = actionCreator(ACTION_NAME);
