import actionCreator from '../portCallMeasurementsActionCreator';
import { UpdateShipMeasurementsRequest } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'UPDATE_SHIP_MEASUREMENTS';
export const updateShipMeasurementsAsync = actionCreator.async<
	UpdateShipMeasurementsRequest,
	null,
	Error
>(ACTION_NAME);

export const updateShipMeasurements = actionCreator<
	UpdateShipMeasurementsRequest
>(ACTION_NAME);
