import actionCreator from '../portJobsActionCreator';
import {
	ChangeCustodyRoleParams,
	ChangeCustodyRoleRequest
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'CHANGE_CUSTODY_ROLE';

export const changeCustodyRoleAsync = actionCreator.async<
	ChangeCustodyRoleRequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	templateParams: {
		entityName: 'Custody Role'
	}
});

export const changeCustodyRole = actionCreator<ChangeCustodyRoleParams>(
	ACTION_NAME
);
