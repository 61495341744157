import {
	FinanceRequest,
	ApproveAllServicesResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

export const APPROVE_ALL_SERVICES = 'APPROVE_ALL_SERVICES';
export const approveAllServicesAsync = actionCreator.async<
	FinanceRequest,
	ApproveAllServicesResponse,
	Error
>(APPROVE_ALL_SERVICES);

export const approveAllServices = actionCreator(APPROVE_ALL_SERVICES);
