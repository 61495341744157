import {
	AxiosBadRequestError,
	AxiosBadRequestErrorData,
	AxiosGeneralError,
	AxiosConcurencyError,
	ErrorStatusCode,
	AxiosNotFoundError,
	AxiosConnectionError
} from './apiErrorTypes';
import { flatten, head } from 'lodash';
import { AxiosError } from 'axios';

/* eslint-disable @typescript-eslint/no-explicit-any */
// simple check and type assertion for response error
export const isResponseError = (error: any): error is AxiosGeneralError =>
	Boolean(error?.response);

export const isConnectionError = (
	error: AxiosError
): error is AxiosConnectionError => error.message === 'Network Error';

export const errorStatusCode = (error: AxiosGeneralError) =>
	error.response.status;

export const getRequestMethod = (error: AxiosError) => error.config.method;

const isErrorCodeStatus = (error: AxiosGeneralError, code: ErrorStatusCode) =>
	errorStatusCode(error) === code;

export const isBadRequestError = (
	error: Error
): error is AxiosBadRequestError =>
	isResponseError(error) &&
	isErrorCodeStatus(error, ErrorStatusCode.BAD_REQUEST);

export const isConcurrencyError = (
	error: Error
): error is AxiosConcurencyError =>
	isResponseError(error) &&
	isErrorCodeStatus(error, ErrorStatusCode.CONCURRENCY);

export const isNotFoundError = (error: Error): error is AxiosNotFoundError =>
	isResponseError(error) && isErrorCodeStatus(error, ErrorStatusCode.NOT_FOUND);

export const getBadRequestErrorMessage = (
	errorData: AxiosBadRequestErrorData
) => {
	const errors = flatten(Object.values(errorData));
	return errors.length > 1 ? errors : head(errors);
};

export const getErrorCorrelationId = (error: any): string | undefined => {
	if (!isResponseError(error)) {
		return;
	}
	return error.response.headers['x-correlation-id'];
};

export const getIsClientSideError = (error: AxiosGeneralError) =>
	error.response.status.toString().startsWith('4');
