import * as React from 'react';
import { Menu, MaybeTooltip, Modal } from 'components/antd';
import {
	EntityPermission,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { openModal } from 'store/modals/actions';
import {
	getIsUpdatePortJobPossible,
	getCancelPortJobAction,
	getIsChangeCustodyRolePossible,
	getActiveJobType,
	getCanChangeStatus,
	getIsStatusChangePossible,
	getActivePortJobStatus,
	getIsMovetoPDAPending,
	getIsJobLinked,
	getPortJobByCode,
	getActivePortJobCode,
	getSendCancelEmailJobStatus,
	getIsActivePortJobCancelled,
	getIsDraftAppointer,
	getIsMovetoCEPending,
	getIsReOpenJob
} from 'store/portJobs/portJobsSelectors';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import {
	NO_PERMISSION_MESSAGE,
	PortJobSettingsMenuAction,
	PortJobSettingsMenuModalMap
} from './contants';
import MenuItemWithPermission from 'components/Permission/MenuItem/MenuItem';
import { getIsActionDisabled } from 'store/metadata/utils/metadataUtils';
import {
	PortJobMetadataActionCode,
	PortJob
} from 'services/api/portJobs/portJobsServiceTypes';
import { getIsCurrentUserHub } from 'store/auth/selectors';
import { JobType, PortJobStatus } from 'store/portJobs/constants';
import {
	enableOperationsCheck,
	moveJobToPreviousStatus,
	addAppointmentJobFromNomination
} from 'store/portJobs/actions';
import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

interface PortJobSettingsMenuProps extends EntityPermission {
	canUpdate: boolean;
	cannotUpdateMessage: string;
	isSendCancelEmailJobStatus: boolean;
	isActivePortJobCancelled: boolean;
	isCancelPortJobPossible: boolean;
	canCancel: boolean;
	cannotCancelMessage: string;
	canChangeCustodyRole: boolean;
	isChangeCustodyRolePossible: boolean;
	isMovetoPDAPending: boolean;
	isMovetoCEPending: boolean;
	isReOpenJob: boolean;
	cannotChangeCustodyRoleMessage: string;
	isHubUser: boolean;
	jobType: string;
	canChangeStatus: boolean;
	jobStatus: PortJobStatus | null;
	cannotChangeStatusMessage: string;
	isStatusChangePossible: boolean;
	onUpdate: () => void;
	onToggle: () => void;
	isLinked: boolean;
	isDraftAppointer: boolean;
	onOperationsToggle: () => void;
	onHideMenu: () => void;
	onCreateAppointment: () => void;
	addAppointmentJobFromNomination: typeof addAppointmentJobFromNomination;

	// from mapStateToProps
	portJob: PortJob;
	portCall: PortCall | null;
	openModalDialog: typeof openModal;
	moveJobToPreviousStatus: typeof moveJobToPreviousStatus;
	enableOperationsCheck: typeof enableOperationsCheck;
}

interface PortJobSettingsMenuState {
	isAppointer: boolean;
	showAppointmentFromNominationWarningModal: boolean;
}

const getDocumentBody = () => document.body;

// TODO implement handlers and changes related to portjob menu
class PortJobSettingsMenu extends React.PureComponent<
	PortJobSettingsMenuProps,
	PortJobSettingsMenuState
> {
	constructor(props: PortJobSettingsMenuProps) {
		super(props);

		this.state = {
			showAppointmentFromNominationWarningModal: false,
			isAppointer: false
		};
	}

	componentDidMount(): void {
		this.setState({
			...this.state,
			isAppointer: !!this.props.portJob.appointerCompany
		});
	}

	componentDidUpdate(): void {
		this.setState({
			...this.state,
			isAppointer: !!this.props.portJob.appointerCompany
		});
	}

	onMenuClick = (e: { key: string }) => {
		if (e.key === PortJobSettingsMenuAction.DELETE) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (e.key === PortJobSettingsMenuAction.UPDATE) {
			this.props.onUpdate();
		}
		if (e.key === PortJobSettingsMenuAction.CANCEL) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (e.key === PortJobSettingsMenuAction.SEND_CANCEL_EMAIL) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (e.key === PortJobSettingsMenuAction.CHANGE_CUSTODY_ROLE) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (
			e.key === PortJobSettingsMenuAction.CHANGE_JOB_STATUS_WAIT ||
			e.key === PortJobSettingsMenuAction.CHANGE_JOB_STATUS_DONTWAIT
		) {
			this.props.onToggle();
		}
		if (e.key === PortJobSettingsMenuAction.DISABLE_OPERATIONS) {
			this.props.onOperationsToggle();
		}
		if (e.key === PortJobSettingsMenuAction.ENABLE_OPERATIONS) {
			this.props.enableOperationsCheck();
			this.props.onHideMenu();
		}
		if (e.key === PortJobSettingsMenuAction.MOVE_TO_PDA_PENDING) {
			this.props.moveJobToPreviousStatus({ moveBackToJobStatus: e.key });
		}
		if (e.key === PortJobSettingsMenuAction.MOVE_TO_CE_PENDING) {
			this.props.moveJobToPreviousStatus({ moveBackToJobStatus: e.key });
		}
		if (e.key === PortJobSettingsMenuAction.UNLINK_JOBS) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (e.key === PortJobSettingsMenuAction.CLONE_JOB) {
			this.props.openModalDialog(PortJobSettingsMenuModalMap[e.key]);
		}
		if (e.key === PortJobSettingsMenuAction.RE_OPEN_JOB) {
			this.props.moveJobToPreviousStatus({ moveBackToJobStatus: e.key });
		}
		if (
			e.key === PortJobSettingsMenuAction.CREATE_APPOINTMENT_FROM_NOMINATION
		) {
			this.setState({
				...this.state,
				showAppointmentFromNominationWarningModal: true
			});
		}
	};

	onAppointmentFromNominationCancelOrClose = () => {
		this.setState({
			...this.state,
			showAppointmentFromNominationWarningModal: false
		});
	};

	onConfirmAppointmentFromNomination = () => {
		if (this.props.portCall) {
			const portCallId = this.props.portCall.id;
			const jobCode = this.props.portJob.code;

			this.props.addAppointmentJobFromNomination({
				portCallId,
				jobCode
			});
			this.onAppointmentFromNominationCancelOrClose();
		}
	};

	getAppointmnetFromNominationTooltipContent = () => {
		if (this.props.isLinked) {
			return 'This Nomination already has linked Appointment';
		} else if (!this.state.isAppointer) {
			return `Please wait until Appointer information is provided`;
		} else if (this.props.isDraftAppointer) {
			return `The draft appointer cannot be used to create appointment jobs. Please wait until the draft is converted or choose an existing appointer`;
		} else {
			return undefined;
		}
	};

	render() {
		const {
			portJob,
			canUpdate,
			cannotUpdateMessage,
			isSendCancelEmailJobStatus,
			isActivePortJobCancelled,
			isCancelPortJobPossible,
			canCancel,
			cannotCancelMessage,
			canChangeCustodyRole,
			isMovetoPDAPending,
			isMovetoCEPending,
			isReOpenJob,
			isChangeCustodyRolePossible,
			cannotChangeCustodyRoleMessage,
			isHubUser,
			jobType,
			canChangeStatus,
			jobStatus,
			cannotChangeStatusMessage,
			isStatusChangePossible,
			isLinked,
			isDraftAppointer
		} = this.props;

		return (
			<div>
				<Menu onClick={this.onMenuClick}>
					<MenuItemWithPermission
						disabled={!canUpdate}
						key={PortJobSettingsMenuAction.UPDATE}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
					>
						<MaybeTooltip
							placement="left"
							show={!canUpdate}
							title={cannotUpdateMessage}
							getPopupContainer={getDocumentBody}
						>
							Update
						</MaybeTooltip>
					</MenuItemWithPermission>
					{isCancelPortJobPossible && !isActivePortJobCancelled && (
						<Menu.Item
							disabled={!canCancel}
							key={PortJobSettingsMenuAction.CANCEL}
						>
							<MaybeTooltip
								placement="left"
								show={!canCancel}
								title={cannotCancelMessage}
								getPopupContainer={({ parentElement }) =>
									parentElement?.parentElement
										? parentElement.parentElement
										: document.body
								}
							>
								Cancel
							</MaybeTooltip>
						</Menu.Item>
					)}
					{isChangeCustodyRolePossible && (
						<MenuItemWithPermission
							disabled={!canChangeCustodyRole}
							key={PortJobSettingsMenuAction.CHANGE_CUSTODY_ROLE}
							permissionCode={PermissionCode.APPOINT_AGENT}
						>
							<MaybeTooltip
								placement="left"
								show={!canChangeCustodyRole}
								title={cannotChangeCustodyRoleMessage}
								getPopupContainer={({ parentElement }) =>
									parentElement?.parentElement
										? parentElement.parentElement
										: document.body
								}
							>
								Change custody role
							</MaybeTooltip>
						</MenuItemWithPermission>
					)}
					{isHubUser &&
						jobType === JobType.NOMINATION &&
						isStatusChangePossible &&
						this.props.portCall && (
							<MenuItemWithPermission
								disabled={
									!canChangeStatus ||
									this.props.isLinked ||
									!this.state.isAppointer ||
									this.props.isDraftAppointer
								}
								key={
									PortJobSettingsMenuAction.CREATE_APPOINTMENT_FROM_NOMINATION
								}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
							>
								<MaybeTooltip
									placement="left"
									show={
										!canChangeStatus ||
										this.props.isLinked ||
										!this.state.isAppointer ||
										this.props.isDraftAppointer
									}
									title={this.getAppointmnetFromNominationTooltipContent()}
									getPopupContainer={({ parentElement }) =>
										parentElement?.parentElement
											? parentElement.parentElement
											: document.body
									}
								>
									Create Appointment from Nomination
								</MaybeTooltip>
							</MenuItemWithPermission>
						)}
					{isHubUser &&
						jobType === JobType.NOMINATION &&
						isStatusChangePossible &&
						(jobStatus === PortJobStatus.AWAITING_APPOINTMENT ? (
							<MenuItemWithPermission
								disabled={!canChangeStatus}
								key={PortJobSettingsMenuAction.CHANGE_JOB_STATUS_DONTWAIT}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
							>
								<MaybeTooltip
									placement="left"
									show={!canChangeStatus}
									title={cannotChangeStatusMessage}
									getPopupContainer={({ parentElement }) =>
										parentElement?.parentElement
											? parentElement.parentElement
											: document.body
									}
								>
									Don't wait for appointment
								</MaybeTooltip>
							</MenuItemWithPermission>
						) : (
							<MenuItemWithPermission
								disabled={!canChangeStatus}
								key={PortJobSettingsMenuAction.CHANGE_JOB_STATUS_WAIT}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
							>
								<MaybeTooltip
									placement="left"
									show={!canChangeStatus}
									title={cannotChangeStatusMessage}
									getPopupContainer={({ parentElement }) =>
										parentElement?.parentElement
											? parentElement.parentElement
											: document.body
									}
								>
									Wait for Appointment
								</MaybeTooltip>
							</MenuItemWithPermission>
						))}
					{isHubUser && isLinked && jobType === JobType.NOMINATION ? (
						<MenuItemWithPermission
							disabled={false}
							key={PortJobSettingsMenuAction.UNLINK_JOBS}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
						>
							Unlink jobs
						</MenuItemWithPermission>
					) : null}
					{isHubUser &&
						(portJob.isOperationsDisabled ? (
							<MenuItemWithPermission
								disabled={false}
								key={PortJobSettingsMenuAction.ENABLE_OPERATIONS}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
							>
								Enable Operations
							</MenuItemWithPermission>
						) : (
							<MenuItemWithPermission
								disabled={false}
								key={PortJobSettingsMenuAction.DISABLE_OPERATIONS}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
							>
								Disable Operations
							</MenuItemWithPermission>
						))}
					{isMovetoPDAPending && (
						<MenuItemWithPermission
							disabled={false}
							key={PortJobSettingsMenuAction.MOVE_TO_PDA_PENDING}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
						>
							Move to PDA pending
						</MenuItemWithPermission>
					)}
					{isMovetoCEPending && (
						<MenuItemWithPermission
							disabled={false}
							key={PortJobSettingsMenuAction.MOVE_TO_CE_PENDING}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
						>
							Move to CE pending
						</MenuItemWithPermission>
					)}
					{!isSendCancelEmailJobStatus &&
						isHubUser &&
						isActivePortJobCancelled && (
							<Menu.Item key={PortJobSettingsMenuAction.SEND_CANCEL_EMAIL}>
								Send cancel email
							</Menu.Item>
						)}
					<MenuItemWithPermission
						disabled={!canUpdate || isDraftAppointer}
						key={PortJobSettingsMenuAction.CLONE_JOB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
					>
						<MaybeTooltip
							placement="left"
							show={isDraftAppointer}
							title={
								'Clone job not allowed for job with draft appointer. Please wait until the draft is converted or choose an existing appointer.'
							}
							getPopupContainer={({ parentElement }) =>
								parentElement?.parentElement
									? parentElement.parentElement
									: document.body
							}
						>
							Clone Job
						</MaybeTooltip>
					</MenuItemWithPermission>
					{isReOpenJob && (
						<MenuItemWithPermission
							disabled={false}
							key={PortJobSettingsMenuAction.RE_OPEN_JOB}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
						>
							Re-open job
						</MenuItemWithPermission>
					)}
				</Menu>
				<Modal
					visible={this.state.showAppointmentFromNominationWarningModal}
					onCancel={this.onAppointmentFromNominationCancelOrClose}
					onClose={this.onAppointmentFromNominationCancelOrClose}
					onOk={this.onConfirmAppointmentFromNomination}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>
						Appointment Job will be created based on {this.props.portJob.code}{' '}
						Nomination Job. Are you sure want to proceed?
					</p>
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state: AppState, { canUpdate, canChangeCustodyRole }: EntityPermission) => {
		const isUpdatePortJobPossible = getIsUpdatePortJobPossible(state);
		const cancelAction = getCancelPortJobAction(state);
		const isChangeCustodyRolePossible = getIsChangeCustodyRolePossible(state);
		const isHubUser = getIsCurrentUserHub(state);
		const jobType = getActiveJobType(state);
		const canChangeStatus = getCanChangeStatus(state);
		const jobStatus = getActivePortJobStatus(state);
		const isStatusChangePossible = getIsStatusChangePossible(state);
		const isMovetoPDAPending = getIsMovetoPDAPending(state);
		const isMovetoCEPending = getIsMovetoCEPending(state);
		const isReOpenJob = getIsReOpenJob(state);
		const isLinked = getIsJobLinked(state);
		const isSendCancelEmailJobStatus = getSendCancelEmailJobStatus(state);
		const isActivePortJobCancelled = getIsActivePortJobCancelled(state);
		const activePortJobCode = getActivePortJobCode(state);
		const portCall = getActivePortCall(state);

		return {
			portJob: getPortJobByCode(state, activePortJobCode),
			portCall: portCall,
			canUpdate: canUpdate && isUpdatePortJobPossible,
			isCancelPortJobPossible: !!cancelAction,
			isSendCancelEmailJobStatus: isSendCancelEmailJobStatus,
			isActivePortJobCancelled: isActivePortJobCancelled,
			canCancel:
				cancelAction &&
				!getIsActionDisabled<PortJobMetadataActionCode>(cancelAction),
			canChangeCustodyRole,
			isChangeCustodyRolePossible,
			isMovetoPDAPending,
			isMovetoCEPending,
			isReOpenJob,
			cannotUpdateMessage: isUpdatePortJobPossible
				? NO_PERMISSION_MESSAGE
				: `Job cannot be edited`,
			cannotCancelMessage: `The job can't be cancelled since it has some GL transactions posted or Job Create transaction is still being processed in GL`,
			cannotChangeCustodyRoleMessage: `Changing of custody role is not allowed due to other job's custody role configuration`,
			isHubUser,
			jobType,
			canChangeStatus,
			jobStatus,
			cannotChangeStatusMessage: `Updating job's path is not allowed in the current job status`,
			isStatusChangePossible,
			isLinked,
			isDraftAppointer: getIsDraftAppointer(state)
		};
	},
	{
		openModalDialog: openModal,
		moveJobToPreviousStatus,
		enableOperationsCheck,
		addAppointmentJobFromNomination
	}
)(PortJobSettingsMenu);
