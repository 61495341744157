import { FinanceState } from '../financeState';
import {
	UpdateServiceStatusRequest as Request,
	PortJobService,
	PortCallFinance
} from 'services/api/finance/financeServiceTypes';
import { FetchStatus, ResultCollection } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';
import { find, some } from 'lodash';

const getStateWithFetchStatus = (
	state: FinanceState,
	id: string,
	status: FetchStatus
) => ({
	...state,
	updateStatuses: {
		...state.updateStatuses,
		convertService: {
			...state.updateStatuses.convertService,
			[id]: status
		}
	}
});

export function onConvertServiceStarted(
	state: FinanceState,
	{ jobServiceId }: Request
): FinanceState {
	return getStateWithFetchStatus(state, jobServiceId, FetchStatus.PENDING);
}

export function onConvertServiceSuccess(
	state: FinanceState,
	{
		result: { elements },
		params
	}: Success<Request, ResultCollection<PortJobService>>
): FinanceState {
	const finance = state.finance || ({} as PortCallFinance);
	const serviceElements = finance.services.elements.map(
		service =>
			find(elements, updatedService => updatedService.id === service.id) ||
			service
	);
	const newService = find(
		elements,
		el => !some(serviceElements, service => service.id === el.id)
	);
	if (newService) {
		serviceElements.push(newService);
	}
	return {
		...getStateWithFetchStatus(state, params.jobServiceId, FetchStatus.SUCCESS),
		finance: {
			...finance,
			services: {
				...finance.services,
				elements: serviceElements
			}
		}
	};
}

export function onConvertServiceError(
	state: FinanceState,
	{ params: { jobServiceId } }: Failure<Request, Error>
): FinanceState {
	return getStateWithFetchStatus(state, jobServiceId, FetchStatus.FAILURE);
}
