import { Failure } from 'typescript-fsa';
import { RegistrationState } from '../registrationState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRegisterUserByTokenStarted = (
	state: RegistrationState
): RegistrationState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		adding: FetchStatus.PENDING
	}
});
export const onRegisterUserByTokenFailed = (
	state: RegistrationState,
	action: Failure<{}, boolean>
): RegistrationState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		adding: FetchStatus.FAILURE
	},
	error: action.error
});
export const onRegisterUserByTokenDone = (
	state: RegistrationState
): RegistrationState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		adding: FetchStatus.SUCCESS
	},
	error: false
});
