import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	RetrieveGroupUsersResponse,
	RetrieveGroupUsersRequest
} from 'services/api/groups/groupsServiceTypes';
import { getGroupUsersCount } from 'store/groupUsers/groupUsersSelectors';
import { retrieveUsersFromGroupAsync as async } from 'store/thread/actions/retrieveUsersFromGroup';
import Api from 'services/api';
import { call, select } from 'redux-saga/effects';

const defaultExecutor = makeDefaultExecutor<
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	Error
>({
	async,
	api: Api.Groups.retrieveUsersFromGroup
});

export const customExecutor = function*(payload: RetrieveGroupUsersRequest) {
	const count = yield select(getGroupUsersCount, payload.id);

	const params: RetrieveGroupUsersRequest = {
		index: count,
		sortBy: 'lastActivityOn:desc',
		onlyActive: true,
		...payload
	};

	yield call(defaultExecutor, params);
};

export default makeTakeEveryWatcher<
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	Error
>(
	{
		async,
		api: Api.Groups.retrieveUsersFromGroup
	},
	customExecutor
);
