import { init as initApm, ApmAgent } from '@elastic/apm-rum';
import config from 'services/config';

const environmentPrefix =
	config.baseUrl === 'http://localhost:3000'
		? 'local'
		: config.baseUrl.replace(/http(s|):\/\//g, '').split('.')[0];

class Apm {
	apm: ApmAgent;

	constructor() {
		this.initialize();
	}

	private initialize = () => {
		this.apm = initApm({
			serviceName: String(environmentPrefix).toUpperCase(),
			serverUrl: config.apmServerUrl,
			serviceVersion: JSON.stringify(config.version)
		});
		this.connectToOnError();
	};

	private connectToOnError = () => {
		window.onerror = (msg, _url, lineNo, columnNo, error) => {
			const stack = error ? error.stack : 'Unknown error!';
			this.apm.captureError(
				error || new Error(`${msg}|${lineNo}|${columnNo}|stack: ${stack}`)
			);
		};
	};
}

export default Apm;
