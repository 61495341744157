import { SagaIterator } from 'redux-saga';
import {
	call,
	put,
	all,
	fork,
	takeLatest,
	take,
	select
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	deletePortCallEventAsync,
	hidePortCallEventDeleteModal,
	showPortCallEventDeleteModal
} from '../actions';
import {
	startDeletePortCallEvent,
	DeletePortCallEventActionsPayload,
	deletePortCallEvent,
	endDeletePortCallEvent,
	removePortCallEventFromList,
	setPortCallEventAsNotHappened
} from '../actions/deletePortCallEvent';
import {
	getPortCallEvent,
	getPortCallEventConcurrencyToken
} from '../selectors';
import { getActivePortCallId } from '../../portcalls/selectors';
import Api from 'services/api';
import {
	PortCallEvent,
	PortCallEventDeletable,
	DeletePortCallEventRequest
} from 'services/api/portCall/portCallServiceTypes';

// START DELETE
function* startDeletePortCallEventWorker({
	payload: {
		requestParams: { eventId }
	}
}: Action<DeletePortCallEventActionsPayload>) {
	const event: PortCallEvent = yield select(getPortCallEvent, eventId);

	if (event.deletable === PortCallEventDeletable.COMMENT) {
		yield put(showPortCallEventDeleteModal(eventId));
	}
	if (event.deletable === PortCallEventDeletable.YES) {
		yield put(deletePortCallEvent({ requestParams: { eventId } }));
		// block the channel until deletion is finished in order to block multiple requests
		yield take([
			deletePortCallEventAsync.done,
			deletePortCallEventAsync.failed
		]);
	}
}

function* startDeletePortCallEventWatcher(): SagaIterator {
	while (true) {
		const payload = yield take(startDeletePortCallEvent);
		yield call(startDeletePortCallEventWorker, payload);
	}
}

// EXEC DELETE
export function* deletePortCallEventWorker({
	payload
}: Action<DeletePortCallEventActionsPayload>): SagaIterator {
	yield put(deletePortCallEventAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const concurrencyToken = yield select(
		getPortCallEventConcurrencyToken,
		payload.requestParams.eventId
	);
	const deleteRequest = {
		...payload.requestParams,
		portCallId,
		concurrencyToken
	};
	try {
		const response = yield call(
			Api.PortCall.deletePortCallEvent,
			deleteRequest
		);
		yield put(
			deletePortCallEventAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);

		yield put(endDeletePortCallEvent(deleteRequest));
	} catch (error) {
		yield put(
			deletePortCallEventAsync.failed({
				error,
				params: payload
			})
		);
	}
}

export function* deletePortCallEventWatcher(): SagaIterator {
	yield takeLatest(deletePortCallEventAsync.type, deletePortCallEventWorker);
}

// END DELETE
function* endDeletePortCallEventWorker({
	payload
}: Action<DeletePortCallEventRequest>) {
	const event: PortCallEvent = yield select(getPortCallEvent, payload.eventId);

	if (event.deletable === PortCallEventDeletable.COMMENT) {
		yield put(hidePortCallEventDeleteModal(undefined));
		yield put(setPortCallEventAsNotHappened(payload));
	}
	if (event.deletable === PortCallEventDeletable.YES) {
		yield put(removePortCallEventFromList(payload));
	}
}

function* endDeletePortCallEventWatcher(): SagaIterator {
	yield takeLatest(endDeletePortCallEvent, endDeletePortCallEventWorker);
}

export default function*() {
	yield all([
		fork(startDeletePortCallEventWatcher),
		fork(deletePortCallEventWatcher),
		fork(endDeletePortCallEventWatcher)
	]);
}
