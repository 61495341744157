import { retrieveFinanceRevisionsAsync } from './../actions/retrieveFinanceRevisions';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';

import {
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse
} from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';

const defaultExecutor = makeDefaultExecutor<
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse,
	Error
>({
	api: Api.Finance.retrieveJobFinanceRevisions,
	async: retrieveFinanceRevisionsAsync
});

const executor = function*(
	params: Partial<RetrieveFinanceRevisionsRequest>
): SagaIterator {
	const payload: RetrieveFinanceRevisionsRequest = yield call(
		getActivePortCallAndPortJobIdsExecutor,
		params
	);

	yield call(defaultExecutor, payload);
};

export default makeTakeLatestWatcher<
	Partial<RetrieveFinanceRevisionsRequest>,
	RetrieveFinanceRevisionsResponse,
	Error
>(
	{
		async: retrieveFinanceRevisionsAsync
	},
	executor
);
