import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { DocumentsState } from '../documentsState';

export const onRetrieveDocumentImageStarted = (
	state: DocumentsState,
	params: RetrievePortCallDocumentImageRequest
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		documentImages: {
			...state.fetchStatuses.documentImages,
			[params.documentId]: FetchStatus.PENDING
		}
	}
});

export const onRetrieveDocumentImageDone = (
	state: DocumentsState,
	{
		params: { documentId, page },
		result
	}: Success<
		RetrievePortCallDocumentImageRequest,
		RetrievePortCallDocumentImageResponse
	>
) => ({
	...state,
	documentImages: {
		...state.documentImages,
		[documentId]: {
			...state.documentImages[documentId],
			[page]: result
		}
	},
	fetchStatuses: {
		...state.fetchStatuses,
		documentImages: {
			...state.fetchStatuses.documentImages,
			[documentId]: FetchStatus.SUCCESS
		}
	}
});

export const onRetrieveDocumentImageFailed = (
	state: DocumentsState,
	{ params }: Failure<RetrievePortCallDocumentImageRequest, Error>
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		documentImages: {
			...state.fetchStatuses.documentImages,
			[params.documentId]: FetchStatus.FAILURE
		}
	}
});
