import { PermissionsCheckMode } from './permissionTypes';
import { some, every } from 'lodash';

export const hasAllOfPermissions = (hasPermissions: boolean[]) =>
	every(hasPermissions, hasPermission => hasPermission);

export const hasSomeOfPermissions = (hasPermissions: boolean[]) =>
	some(hasPermissions, hasPermission => hasPermission);

export const hasPermissionsConsideringCheckMode = (
	hasPermissions: boolean[],
	permissionsCheckMode: PermissionsCheckMode
) =>
	permissionsCheckMode === PermissionsCheckMode.AND
		? hasAllOfPermissions(hasPermissions)
		: hasSomeOfPermissions(hasPermissions);
