import { createSelector } from 'reselect';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { AppState } from 'store-types';
import {
	Funding,
	FundingColumnsMetadataKey
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { CollectionView } from 'services/api/finance/financeServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

const getFinanceFundingsState = (state: AppState) => state.financeFundings;

export const getFundingBreadcrumbs = createSelector(
	getPortCallLayoutBreadcrumbs,
	breadcrumbs => [...breadcrumbs, { title: 'Funding request' }]
);

export const getFundingsState = (state: AppState) =>
	getFinanceFundingsState(state).fundings;

export const getPrefundingState = (state: AppState) =>
	getFinanceFundingsState(state).preFundingRequests;

export const getPrefundingFetchStatus = createSelector(
	getPrefundingState,
	prefunding =>
		prefunding.fetchStatuses.sendPrefundingsRequest === FetchStatus.SUCCESS
);

const getFundingsMap = (state: AppState) => getFundingsState(state).byId;

const getFundingsAllIds = (state: AppState) => getFundingsState(state).allIds;

export const getFundings = createSelector(
	getFundingsMap,
	getFundingsAllIds,
	(byId, allIds) => {
		return allIds.map((id: string) => byId[id]).filter(Boolean);
	}
);

export const getFundingsData = createSelector(
	getFundingsState,
	getFundings,
	({ propertiesToView, metadata }, fundings) =>
		({
			propertiesToView,
			elements: fundings,
			metadata
		} as CollectionView<Funding, FundingColumnsMetadataKey>)
);

export const getFundingUpdateFetchStatus = createSelector(
	getFundingsState,
	fundingsState => fundingsState.fetchStatuses.updateFunding
);
