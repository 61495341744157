import { PortCallsState } from '../portCallsState';
import { FetchStatus } from 'services/api/apiTypes';
export const onResetPortCalls = (state: PortCallsState): PortCallsState => ({
	...state,
	byId: {},
	hasMore: true,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.IDLE
	}
});
