import { PagedRequest, ResultCollection } from '../apiTypes';

export interface RetrieveCommoditiesRequest extends PagedRequest {
	mainCommodity?: string;
}

interface CommodityServiceModel {
	id: string;
	name: string;
}

/**Aliases to connect Automatic TS packages with our interface syntax
 * @see https://support.dataart.com/browse/IPP-1664
 */
export type Commodity = CommodityServiceModel;

export enum MainCommodityCode {
	GAS = 'Gas',
	DRY = 'Dry',
	WET = 'Wet'
}

export interface MainCommodity {
	mainCommodityCode: MainCommodityCode;
	name: string;
}

export type RetrieveCommoditiesResponse = ResultCollection<Commodity>;
export type RetrieveMainCommoditiesResponse = ResultCollection<MainCommodity>;
