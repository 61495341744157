import { createSelector } from 'reselect';

import {
	ThreadsFilterParamName,
	ThreadsFiltersParams
} from 'store/threads/filtersSync';
import {
	ThreadsFilterStatus,
	threadsStatusesOptions
} from 'types/sections/ThreadsTypes';
import { getThreadsFilters } from 'store/threads/selectors';
import { LabeledValue, Entity } from 'app-types';
import { Mailbox } from 'services/api/mailboxes/mailboxesServiceTypes';
import { uniqBy } from 'lodash';

export const getAreThreadsFiltersApplied = createSelector(
	getThreadsFilters,
	(values = {}) => Object.keys(values).length > 0
);

export const getThreadListEmptyMessage = createSelector(
	getAreThreadsFiltersApplied,
	areFiltersApplied =>
		areFiltersApplied ? 'No results found' : 'There are no threads to load'
);

/**
 * Select mutates `value` , return shallow copies
 */
export const getThreadsFilterValues = createSelector(
	getThreadsFilters,
	(values = {}): ThreadsFiltersParams => {
		return {
			onlyUnread: values.onlyUnread ? values.onlyUnread[0].key : '',
			status: values.status ? { ...values.status[0] } : undefined,
			assignedToCompany: values.assignedToCompany
				? { ...values.assignedToCompany[0] }
				: undefined,
			assignedTo: values.assignedTo ? { ...values.assignedTo[0] } : undefined,
			threadContextId: values.threadContextId
				? { ...values.threadContextId[0] }
				: undefined,
			tags: values.tags
		};
	}
);

export const getStatusDefaultFilter = (
	options: Array<LabeledValue<string>>
) => {
	const status = options.find(
		option => option.key === ThreadsFilterStatus.UNRESOLVED
	);
	if (!status) {
		return;
	}
	return {
		...status,
		type: ThreadsFilterParamName.STATUS
	};
};

export const getDefaultFieldValues = (): ThreadsFiltersParams => ({
	[ThreadsFilterParamName.STATUS]: getStatusDefaultFilter(
		threadsStatusesOptions
	)
});

export const getFieldValues = createSelector(
	getThreadsFilterValues,
	(values): ThreadsFiltersParams => {
		const defaultValues = getDefaultFieldValues();
		return {
			...values,
			[ThreadsFilterParamName.STATUS]:
				values[ThreadsFilterParamName.STATUS] ||
				defaultValues[ThreadsFilterParamName.STATUS]
		};
	}
);

export const getMailboxCompanies = (mailboxes: Mailbox[]): Entity[] => {
	return uniqBy(mailboxes, obj => obj.company.id).map(
		mailbox => mailbox.company
	);
};
