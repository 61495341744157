import {
	SupplierInvoiceRow,
	SupplierInvoice,
	SupplierInvoicePortJob,
	SupplierInvoiceServiceDocument
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

interface SupplierInvoicesFetchStatuses {
	readonly all: FetchStatus;
	readonly create: FetchStatus;
	readonly contextualization: FetchStatus;
	readonly downloadById: { [id: string]: FetchStatus };
	readonly active: FetchStatus;
	readonly activeDocument: FetchStatus;
	readonly contextualizationJobSearch: FetchStatus;
	readonly update: FetchStatus;
	readonly cancelCreditSupplierInvoice: FetchStatus;
	readonly retrieveSplitSupplierInvoiceServiceDocument: FetchStatus;
	readonly deleteSupplierDocumentPageById: {
		[documentId: string]: {
			[pageNumber: string]: {
				[pageType: string]: FetchStatus;
			};
		};
	};
}

export interface ContextualizationJobSearchFoundJob {
	code?: string;
	portCallId?: string;
}

export interface ContextualizationJobSearch {
	hasSearchBeenPerformed: boolean;
	job?: ContextualizationJobSearchFoundJob;
}

export interface SupplierInvoiceState {
	readonly activeSupplierInvoice?: SupplierInvoice;
	readonly activeSupplierInvoiceDocument?: SupplierInvoiceServiceDocument;
	readonly byId: { [id: string]: SupplierInvoiceRow };
	readonly hasMore: boolean;
	readonly contextualization: {
		portJobs: SupplierInvoicePortJob[];
		jobSearch: ContextualizationJobSearch;
	};
	readonly context: {
		activeSupplierInvoiceId: string;
		isSupplierInvoiceContextualization: boolean;
	};
	readonly splitSupplierInvoiceServiceDocument: PortCallDocument | null;
	readonly fetchStatuses: SupplierInvoicesFetchStatuses;
}

export const initialState: SupplierInvoiceState = {
	byId: {},
	hasMore: true,
	contextualization: {
		portJobs: [],
		jobSearch: {
			hasSearchBeenPerformed: false
		}
	},
	context: {
		activeSupplierInvoiceId: '',
		isSupplierInvoiceContextualization: false
	},
	splitSupplierInvoiceServiceDocument: null,
	fetchStatuses: {
		all: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		contextualization: FetchStatus.IDLE,
		downloadById: {},
		active: FetchStatus.IDLE,
		activeDocument: FetchStatus.IDLE,
		contextualizationJobSearch: FetchStatus.IDLE,
		update: FetchStatus.IDLE,
		cancelCreditSupplierInvoice: FetchStatus.IDLE,
		retrieveSplitSupplierInvoiceServiceDocument: FetchStatus.IDLE,
		deleteSupplierDocumentPageById: {}
	}
};
