import { PreRegisterRequest } from 'services/api/registration/registrationServiceTypes';

export const PRE_REGISTRATION_FORM_NAME = 'PRE_REGISTRATION_FORM_NAME';

export enum PreRegistrationFormField {
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	EMAIL = 'email'
}

export type PreRegistrationFormData = Pick<
	PreRegisterRequest,
	'firstName' | 'lastName' | 'email'
>;
