import React from 'react';
import { navigateTo } from 'utils';
import { Button } from 'components/antd';
import { Align } from 'components';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { ParentCompany } from 'services/api/companies/companiesServiceTypes';
import { MasterDataExplorerTab } from 'sections/MasterDataExplorer/MasterDataExplorerSectionTypes';
import YesOrNoMark from '../../YesOrNoMark';
import styles from '../CompaniesTab.module.scss';

export const getCompaniesTableColumns = () => [
	{
		title: 'Name',
		dataIndex: 'companyName',
		width: '15%'
	},
	{
		title: 'Id',
		dataIndex: 'id',
		width: '15%'
	},
	{
		title: 'goldenRecordId',
		dataIndex: 'goldenRecordId',
		width: '15%'
	},
	{
		title: 'Code',
		dataIndex: 'code',
		width: '6%'
	},
	{
		title: 'Email',
		dataIndex: 'companyEmail',
		width: '14%'
	},
	{
		title: 'createdOn',
		dataIndex: 'createdOn',
		width: '8%',
		render: (date: string) => getLocalDateDefaultFormatted(date)
	},
	{
		title: 'updatedOn',
		dataIndex: 'updatedOn',
		width: '8%',
		render: (date: string) => getLocalDateDefaultFormatted(date)
	},
	{
		title: 'isActive',
		dataIndex: 'isActive',
		width: '5%',
		className: styles.center,
		render: (isActive: boolean) => <YesOrNoMark isSuccess={isActive} />
	},
	{
		title: 'hasParent',
		dataIndex: 'parentCompany',
		width: '5%',
		className: styles.center,
		render: (parentCompany: ParentCompany) => (
			<YesOrNoMark isSuccess={!!parentCompany} />
		)
	},
	{
		width: '8%',
		render: ({ id }: { id: string }) => (
			<Align align="right">
				<Button
					type="primary"
					size="xsmall"
					onClick={() => {
						navigateTo(`${MasterDataExplorerTab.COMPANIES}/${id}`);
					}}
				>
					Show details
				</Button>
			</Align>
		)
	}
];
