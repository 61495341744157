import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
	endOfSeaPassageAction,
	retrieveEditPortCallDataAsync,
	updatePortCallAsync,
	retrievePortCallAlertsAsync,
	retrievePortCallVesselInfoAsync,
	resetPortCallVesselInfo,
	setEditableCargoId,
	setEditableGradeId,
	setEditableTotalAmountId,
	resetPortCallAlerts,
	retrievePortCallMetadataAsync,
	retrievePortCallActAsAsync,
	resetPortCallActAs
} from './actions';

import {
	onRetrieveEditPortCallDataStarted,
	onRetrieveEditPortCallDataSuccess,
	onRetrieveEditPortCallDataFailed,
	onUpdatePortCallStarted,
	onUpdatePortCallSuccess,
	onUpdatePortCallFailed,
	onEndOfSeaPassageStarted,
	onEndOfSeaPassageSuccess,
	onEndOfSeaPassageFailed,
	onRetrievePortCallAlerts,
	onRetrievePortCallVesselInfo,
	onResetPortCallInfo,
	onSetEditableCargoId,
	onSetEditableGradeId,
	onSetEditableTotalAmountId,
	onResetPortCallAlerts,
	onRetrievePortCallMetadata,
	onRetrievePortCallActAs,
	onResetPortCallActAs
} from './reducers';
import { initialState, PortCallState } from './portCallState';
import { setPortCallUpToDate } from './actions/setPortCallUpToDate';
import { onSetPortCallUpToDate } from './reducers/setPortCallUpToDateReducer';
import threadsFiltersReducer from './reducers/threadsFiltersReducer';
export default reducerWithInitialState(initialState)
	.case(setEditableCargoId, onSetEditableCargoId)
	.case(setEditableGradeId, onSetEditableGradeId)
	.case(setEditableTotalAmountId, onSetEditableTotalAmountId)
	.case(endOfSeaPassageAction.async.started, onEndOfSeaPassageStarted)
	.case(endOfSeaPassageAction.async.failed, onEndOfSeaPassageFailed)
	.case(endOfSeaPassageAction.async.done, onEndOfSeaPassageSuccess)
	.case(
		retrieveEditPortCallDataAsync.started,
		onRetrieveEditPortCallDataStarted
	)
	.case(retrieveEditPortCallDataAsync.done, onRetrieveEditPortCallDataSuccess)
	.case(retrieveEditPortCallDataAsync.failed, onRetrieveEditPortCallDataFailed)
	.case(updatePortCallAsync.started, onUpdatePortCallStarted)
	.case(updatePortCallAsync.done, onUpdatePortCallSuccess)
	.case(updatePortCallAsync.failed, onUpdatePortCallFailed)
	.case(retrievePortCallAlertsAsync.started, onRetrievePortCallAlerts.started)
	.case(retrievePortCallAlertsAsync.done, onRetrievePortCallAlerts.done)
	.case(retrievePortCallAlertsAsync.failed, onRetrievePortCallAlerts.failed)
	.case(
		retrievePortCallVesselInfoAsync.started,
		onRetrievePortCallVesselInfo.started
	)
	.case(retrievePortCallVesselInfoAsync.done, onRetrievePortCallVesselInfo.done)
	.case(
		retrievePortCallVesselInfoAsync.failed,
		onRetrievePortCallVesselInfo.failed
	)
	.case(resetPortCallVesselInfo, onResetPortCallInfo)
	.case(resetPortCallAlerts, onResetPortCallAlerts)
	.case(
		retrievePortCallMetadataAsync.started,
		onRetrievePortCallMetadata.started
	)
	.case(retrievePortCallMetadataAsync.failed, onRetrievePortCallMetadata.failed)
	.case(retrievePortCallMetadataAsync.done, onRetrievePortCallMetadata.done)
	.case(setPortCallUpToDate, onSetPortCallUpToDate)
	// Port Call Act As Options
	.case(retrievePortCallActAsAsync.started, onRetrievePortCallActAs.started)
	.case(retrievePortCallActAsAsync.done, onRetrievePortCallActAs.done)
	.case(retrievePortCallActAsAsync.failed, onRetrievePortCallActAs.failed)
	.case(resetPortCallActAs, onResetPortCallActAs)
	.default((state: PortCallState, action) => ({
		...state,
		threadsFilters: threadsFiltersReducer(state.threadsFilters, action)
	}));
