import React from 'react';
import { Amount, Text } from 'components';
import TotalAmountFormFieldName from './TotalAmountForm/TotalAmountFormFieldNames';

const columns = [
	{
		title: 'Amount',
		dataIndex: TotalAmountFormFieldName.AMOUNT,
		key: 'amount',
		width: '50%',
		render: (text: string) => (
			<Text>
				<Amount value={text} />
			</Text>
		)
	},
	{
		title: 'Currency',
		dataIndex: TotalAmountFormFieldName.CURRENCY_CODE,
		key: 'currency',
		width: '50%'
	}
];

export default columns;
