import * as React from 'react';
import { connect } from 'react-redux';
import { PATHS } from 'sections/App/RouteParams';
import {
	getIsGoToJobBtnDisabled,
	getIsJobNotFound,
	getContextualizationFoundJob,
	getIsContextualizationJobSearchInProgress
} from 'store/supplierInvoice/selectors/supplierInvoicesSelectors';

import { retrieveSupplierInvoiceJob } from 'store/supplierInvoice/actions/retrieveSupplierInvoiceJob';
import { ContextualizationJobSearchFoundJob } from 'store/supplierInvoice/supplierInvoiceState';
import { Row, Col, Button, Input, Form } from 'components/antd';
import Gap from 'components/Styles/Gap/Gap';
import { Text, Link } from 'components';
import styles from '../InvoiceDetails.module.scss';
import { AppState } from 'store-types';

interface InvoiceDetailsSearchJobProps {
	// from mapStateToProps
	isGoToJobBtnDisabled: boolean;
	isJobNotFound: boolean;
	foundJob: ContextualizationJobSearchFoundJob;
	isContextualizationJobSearchInProgress: boolean;
	// from mapDispatchToProps
	retrieveSupplierInvoiceJob: typeof retrieveSupplierInvoiceJob;
}

const jobNotFoundError = (
	<Text>
		Job cannot be found, please click&nbsp;
		<Link href={`/${PATHS.portCalls}`} target="_blank" type="standard">
			<Text decoration="underline">here</Text>
		</Link>
		&nbsp;to search the port call grid
	</Text>
);

class InvoiceDetailsSearchJob extends React.Component<
	InvoiceDetailsSearchJobProps
> {
	searchJobs = (e: React.KeyboardEvent<HTMLInputElement>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.props.retrieveSupplierInvoiceJob({ jobCode: (e.target as any).value });
	};

	render() {
		const {
			isGoToJobBtnDisabled,
			isJobNotFound,
			foundJob = {},
			isContextualizationJobSearchInProgress
		} = this.props;
		const { portCallId, code } = foundJob;

		return (
			<Gap bottom="lg" top="lg" isBlock>
				If Job/Service is not in the list, enter Job Code to add service
				<Row between="xs">
					<Col xs={9}>
						<Form.Item
							help={isJobNotFound && jobNotFoundError}
							validateStatus={isJobNotFound ? 'error' : 'success'}
						>
							<Input
								onPressEnter={this.searchJobs}
								placeholder="Search by job code"
								disabled={isContextualizationJobSearchInProgress}
							/>
						</Form.Item>
					</Col>
					<Col xs={3}>
						<Link
							href={`/${PATHS.portCalls}/${portCallId}/jobs/${code}/finance`}
							target="_blank"
							disabled={isGoToJobBtnDisabled}
						>
							<Button
								ghost
								type="primary"
								className={styles.goToJobBtn}
								disabled={isGoToJobBtnDisabled}
							>
								Go to Job
							</Button>
						</Link>
					</Col>
				</Row>
			</Gap>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isGoToJobBtnDisabled: getIsGoToJobBtnDisabled(state),
		isJobNotFound: getIsJobNotFound(state),
		foundJob: getContextualizationFoundJob(state),
		isContextualizationJobSearchInProgress: getIsContextualizationJobSearchInProgress(
			state
		)
	}),
	{ retrieveSupplierInvoiceJob }
)(InvoiceDetailsSearchJob);
