import { Success } from 'typescript-fsa';
import { reject, omit, size } from 'lodash';
import { PortCallDocumentNotIssuedRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import {
	PortCallDocumentsState,
	RequiredByIdState
} from '../portCallDocumentsState';

const onMarkPortCallDocumentNotIssuedSuccess = (
	state: PortCallDocumentsState,
	{ params }: Success<PortCallDocumentNotIssuedRequest, null>
): PortCallDocumentsState => {
	const document = state.requiredById[params.documentId];

	// Remove selected placeholder or
	// remove whole document if the is one last placeholder left
	const requiredById =
		size(document.placeholders) === 1
			? omit<RequiredByIdState>(state.requiredById, params.documentId)
			: {
					...state.requiredById,
					[params.documentId]: {
						...state.requiredById[params.documentId],
						placeholders: reject(
							state.requiredById[params.documentId].placeholders,
							{ id: params.portCallPlaceholderId }
						)
					}
			  };

	return {
		...state,
		requiredById
	};
};

export { onMarkPortCallDocumentNotIssuedSuccess };
