import { retrievePortCallsDuplicatesAsync } from 'store/portcalls/actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';

export const retrievePortCallsDuplicatesWatcher = makeTakeLatestWatcher<
	RetrievePortCallsRequest,
	RetrievePortCallsResponse,
	Error
>({
	api: Api.PortCalls.retrievePortCallsDuplicates,
	async: retrievePortCallsDuplicatesAsync
});
