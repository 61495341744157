import { retrieveFullDirectBillAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveDirectBillResponse,
	RetrieveDirectBillRequest
} from 'services/api/directBills/directBillsServiceTypes';

const api = Api.DirectBills.retrieveSingleDirectBill;

export default makeTakeLatestWatcher<
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse,
	Error
>({
	api,
	async: retrieveFullDirectBillAsync
});
