import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { SupplierInvoiceState } from '../supplierInvoiceState';

export const onRetrieveSupplierInvoiceJobSuccess = (
	state: SupplierInvoiceState,
	action: Success<RetrievePortCallsRequest, RetrievePortCallsResponse>
) => ({
	...state,
	contextualization: {
		...state.contextualization,
		jobSearch: {
			hasSearchBeenPerformed: true,
			job: action.result.elements[0]
				? {
						portCallId: action.result.elements[0].id,
						code: action.params.jobCode
					}
				: undefined
		}
	}
});

export const onRetrieveSupplierInvoiceJob = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
>('fetchStatuses', 'contextualizationJobSearch', {
	doneReducer: onRetrieveSupplierInvoiceJobSuccess
});
