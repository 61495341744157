import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	put,
	call,
	take,
	takeLatest,
	fork,
	select,
	cancel,
	delay
} from 'redux-saga/effects';
import { omit, keys, isEqual } from 'lodash';
import {
	abortProcessVP,
	retrieveVPAsync,
	updateVPHash,
	updateVPMetadata,
	refreshVPProcessAsync,
	checkVPForUpdatesAsync,
	updateSaveVPRestrictions
} from '../actions';
import { RetrieveVesselProgrammeRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import {
	notify,
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
import { getDescription } from '../vesselProgrammeConstants';
import Api from 'services/api';
import {
	isVesselProgrammeInEditMode,
	getVPHash,
	getVPToken
} from 'store/vesselProgramme/selectors';
import { addDefaultValuesToResponse } from './retrieveVesselProgrammeSaga';
import hash from 'object-hash';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import { isNotFoundError } from 'services/api/apiErrorUtils';

export const executor = function*(
	payload: RetrieveVesselProgrammeRequest,
	api: typeof Api.VesselProgramme.retrieveVP
): SagaIterator {
	const { portCallId } = payload;
	const initialVPHash = yield select(getVPHash);
	const concurrencyToken = yield select(getVPToken);

	yield put(refreshVPProcessAsync.started(payload));

	try {
		const response = yield call(api, portCallId, concurrencyToken);
		const responseData = response.data;
		const responseToHash = omit(responseData, [
			'updatedByInfo',
			'updatedOn',
			'concurrencyToken'
		]);

		const vpHash = hash(responseToHash);
		// if response contains only metadata prop, VP wasn't changed, but metadata should be updated
		const isVPChanged = isEqual(keys(responseData), ['metadata'])
			? false
			: vpHash !== initialVPHash;

		yield put(
			checkVPForUpdatesAsync.done({
				result: { isChanged: isVPChanged },
				params: payload,
				response: null
			})
		);

		if (!isVPChanged) {
			yield put(updateVPMetadata(responseData.metadata));
			return;
		}

		yield put(updateVPHash(vpHash));

		const data = addDefaultValuesToResponse(response);
		const isInEditMode = yield select(isVesselProgrammeInEditMode);
		const description = getDescription(isInEditMode, data.updatedByInfo);

		yield put(
			notify.warning({
				description,
				duration: 0
			})
		);
		if (isInEditMode) {
			yield put(updateSaveVPRestrictions(false));
			return;
		}

		yield put(
			retrieveVPAsync.done({
				result: data,
				params: payload,
				response: null
			})
		);
	} catch (error) {
		yield put(
			retrieveVPAsync.failed({
				error,
				params: payload
			})
		);
		if (isNotFoundError(error)) {
			yield put(abortProcessVP);
		}
	}
};

export function* refreshVPworker(
	action: Action<RetrieveVesselProgrammeRequest>
): SagaIterator {
	yield call(executor, action.payload, Api.VesselProgramme.retrieveVP);
}

const workerBgTask = function*(
	payload: Action<RetrieveVesselProgrammeRequest>
) {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield call(refreshVPworker, payload);
	}
};

const mainWorker = function*(action: Action<RetrieveVesselProgrammeRequest>) {
	yield take([retrieveVPAsync.done, retrieveVPAsync.failed]);
	// after initiall call, errors should fallback into notification
	yield put(setNotificationActionTypeToNotification([retrieveVPAsync.type]));
	const task = yield fork(workerBgTask, action);
	yield take(abortProcessVP.type);
	yield cancel(task);
	yield put(unsetNotificationActionTypeToDisplayType([retrieveVPAsync.type]));
};

export default function*() {
	yield takeLatest(refreshVPProcessAsync.type, mainWorker);
}

export const refreshVPWorkerSaga = function*() {
	yield takeLatest(checkVPForUpdatesAsync.type, refreshVPworker);
};
