import { DocumentContextualizationBaseRequestData } from 'services/api/documents/documentsServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';
import { Entity, Metadata, MetadataAction } from 'app-types.d';
import { UserType } from 'services/api/users/userServiceTypes';
import { PortCallMetadataActionCode } from 'services/api/portCall/portCallServiceTypes';
import { CommonMetadataAction } from 'app-constants';

export enum DocumentLevel {
	PORT_JOB = 'PortJob',
	OPERATION = 'Operation',
	OPERATION_UNIT = 'OperationUnit',
	ROTATION_STEP = 'RotationStep'
}

export interface DocumentJob {
	code: string;
	companyName: string;
}

export enum PortCallDocumentsMetadataActionCode {
	SHOW_DOCUMENTATION_ALERT = 'showDocumentationAlert',
	UPLOAD_OPERATION_DOCUMENTS = 'uploadOperationDocuments'
}

export type PortCallDocumentsMetadataAction = MetadataAction<
	PortCallDocumentsMetadataActionCode
>;
// Retrieve Required Documents
// --------------------------

/**
 * Request object to fetch /portcall/{portcall.id}/required-documents
 */
export interface RetrieveRequiredDocumentsRequest {
	portCallId: string;
}

/**
 * Response from the server after fetching /portcall/{portcall.id}/required-documents
 */
export interface RetrieveRequiredDocumentsResponse
	extends ResultCollection<RequiredDocument> {}

/**
 * Structure of a required document placeholder
 */
interface RequiredDocumentPlaceholder extends Entity {
	concurrencyToken: number;
}

/**
 * Collection of Placeholders coming in the response /required-documents
 */
export interface RequiredDocument {
	id: string;
	code: string;
	name: string;
	level: DocumentLevel;
	placeholders: RequiredDocumentPlaceholder[];
}

// Retrieve Allowed Documents
// --------------------------

/**
 * Request object to fetch /portcall/{portcall.id}/allowed-documents
 */
export interface RetrieveAllowedDocumentsRequest {
	portCallId: string;
}

/**
 * Response from the server after fetching /portcall/{portcall.id}/allowed-documents
 */
export type RetrieveAllowedDocumentsResponse = ResultCollection<
	AllowedDocument
>;

/**
 * Structure of an Allowed Documents placeholder
 */
export interface AllowedDocumentPlaceholder {
	id: string;
	name: string;
	level: DocumentLevel;
	job: DocumentJob;
	concurrencyToken: number;
}
/**
 * Collection of Placeholders coming in the response /allowed-documents
 */
export interface AllowedDocument {
	code: string;
	name: string;
	levels: DocumentLevel[];
	placeholders: AllowedDocumentPlaceholder[];
}

// Retrieve Uploaded Documents
// --------------------------

export interface RetrieveUploadedDocumentsRequest {
	portCallId: string;
	showDataInconsistencyAlert?: boolean;
}

export interface RetrieveUploadedDocumentsResponse {
	documents: ResultCollection<UploadedDocument>;
	metadata: Metadata<PortCallDocumentsMetadataActionCode>;
}

export interface DocumentApplicability {
	placeholders: UploadedDocumentPlaceholder[];
	jobs: string[];
}

export interface UploadedDocument {
	id: string;
	name: string;
	fileName: string;
	isRequired: boolean;
	isIssued: boolean;
	pageCount: number;
	downloadUrl: string;
	applicability: DocumentApplicability;
	updatedDate: string;
	updatedPltDate: string;
	updatedBy: {
		id: string;
		fullName: string;
		userType: UserType;
	};
	comment?: string;
	metadata: Metadata<PortCallMetadataActionCode>;
	concurrencyToken: number;
}

interface UploadedDocumentPlaceholder {
	id: string;
	name: string;
	level: DocumentLevel;
	concurrencyToken: number;
}

// Edit Uploaded Document Comment
// --------------------------
export interface EditUploadedDocumentCommentRequest {
	portCallId: string;
	documentId: string;
	data: EditUploadedDocumentCommentRequestData;
	concurrencyToken: number;
}

export type EditUploadedDocumentCommentResponse = UploadedDocument;

interface EditUploadedDocumentCommentRequestData {
	comment: string;
}

// Create Port Call Document
// --------------------------

export interface CreatePortCallDocumentRequest {
	portCallId?: string; // Optional, since can be inferred from activePortCallId
	data: CreatePortCallDocumentRequestData;
}

export interface DocumentContextualization {
	number?: number; // only for PDF
	documentTypeCode: string;
	placeholders: string[];
	jobs: string[];
}

export type CreatePortCallDocumentRequestData = DocumentContextualizationBaseRequestData<
	DocumentContextualization
>;

// Mark Port Call Document As Not Issued
// --------------------------

export interface PortCallDocumentNotIssuedRequest {
	portCallId: string;
	documentId: string;
	portCallPlaceholderId: string;
	data: PortCallDocumentNotIssuedRequestData;
	documentConcurrencyToken?: number;
	placeholderConcurrencyToken: number;
}

interface PortCallDocumentNotIssuedRequestData {
	jobs: string[];
	comment: string;
}

// Delete Document
// --------------------------

export interface DeletePortCallDocumentRequest {
	portCallId: string;
	documentId: string;
	concurrencyToken: number;
}

export interface DeletePortCallDocumentPageRequest {
	portCallId: string;
	documentId: string;
	actualNumber: number;
	concurrencyToken?: number;
}

// Retrieve Document
// --------------------------

export interface RetrievePortCallDocumentRequest {
	portCallId: string;
	documentId: string;
	isSilent?: boolean;
}

export type RetrievePortCallDocumentResponse = PortCallDocument;

export interface PortCallDocument {
	id: string;
	jobFinanceId: string;
	name: string;
	file: {
		fileName: string;
		extension: string;
	};
	pages: PortCallDocumentPage[];
	metadata?: Metadata<CommonMetadataAction>;
	concurrencyToken?: number;
}

// Retrieve Document Image
// ---------------------
export interface RetrievePortCallDocumentImageRequest {
	portCallId: string;
	documentId: string;
	page: number;
}

export interface RetrievePortCallDocumentImageResponse {
	downloadUrl: string;
}

// Download Document(s)
// --------------------------

export interface PortCallDocumentPage {
	number: number;
	actualNumber: number;
	downloadUrl: string;
	annotatedDownloadUrl: string;
	isAnnotated: boolean;
	createdOn: string;
	updatedOn: string;
	annotatedCreatedOn: string;
	annotatedUpdatedOn: string;
	originalFinanceDocumentId: string;
	annotatedFinanceDocumentId: string;
}

export interface DownloadPortCallDocumentRequest {
	portCallDocumentIds: string[];
	portCallId?: string;
	meta?: {
		downloadAll?: boolean;
	};
}
