import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';
import { FilterItem } from 'store/filters/filtersState';
import { ReduxQueryFiltersSync } from 'redux-query-sync';
import { ThreadStatus } from 'services/api/threads/threadsServiceTypes';

export const PORT_MESSAGES_FILTER = 'portcallMessages';

export interface RetrievePortCallMessagesFilters {
	onlyUnread?: boolean;
	search?: string[];
	statuses?: ThreadStatus[];
}

export enum PortCallMessagesFilterParamName {
	SEARCH = 'search',
	ONLY_UNREAD = 'onlyUnread',
	STATUS = 'status'
}

export interface PortCallMessagesFiltersParams {
	onlyUnread?: string;
	search?: FilterItem[];
	status?: FilterItem;
}

const filtersSync: ReduxQueryFiltersSync = {
	[PORT_MESSAGES_FILTER]: {
		selector: (state: AppState) => state.filters[PORT_MESSAGES_FILTER],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return { name: PORT_MESSAGES_FILTER, filters: JSON.parse(value) };
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
