import { navigateTo } from 'utils';
import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { updateUser, updateUserAsync } from 'store/users/actions';
import { RETRIEVE_USER } from 'store/users/actions/retrieveUser';
import Api from 'services/api';
import { retrieveUserWorker } from './retrieveUserSaga';
import { UpdateUserParams } from 'services/api/users/userServiceTypes';
import { Action } from 'typescript-fsa';
import checkUpdatedUserAgainstAuthSaga from './checkUpdatedUserAgainstAuthSaga';
import { getAuthUser } from 'store/auth/selectors';
export function* updateUserWorker(
	action: Action<UpdateUserParams>
): SagaIterator {
	const { id, request } = action.payload;

	try {
		const response = yield call(Api.Users.updateUser, id, request);

		const user = yield call(Api.Users.retrieveUser, { userId: id });
		const authUser = yield select(getAuthUser);
		const isProfilePage = authUser.id === id;

		yield call(checkUpdatedUserAgainstAuthSaga, id);
		yield put(
			updateUserAsync.done({
				result: user.data,
				params: action.payload,
				response
			})
		);
		if (isProfilePage) {
			yield call(retrieveUserWorker, { type: RETRIEVE_USER, payload: id });
		}
	} catch (error) {
		yield put(
			updateUserAsync.failed({
				error,
				params: action.payload
			})
		);
	}
}

function* updateUserWatcher() {
	yield takeLatest(updateUser.type, updateUserWorker);
}

/**
 * Success Watcher
 */
export function* updateUserSuccessWorker(
	action: Action<{ params: UpdateUserParams }>
): SagaIterator {
	const authUser = yield select(getAuthUser);
	const isProfilePage = authUser.id === action.payload.params.id;
	if (!isProfilePage) {
		yield call(navigateTo, '/users');
	}
}

function* updateUserSuccessWatcher() {
	yield takeLatest(updateUserAsync.done, updateUserSuccessWorker);
}

export default function*() {
	yield all([fork(updateUserWatcher), fork(updateUserSuccessWatcher)]);
}
