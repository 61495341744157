import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';
import {
	retrieveFinanceCycleStart,
	retrieveFinanceCycleStop
} from '../actions/retrieveFinanceCycle';
import { SagaIterator } from 'redux-saga';
import {
	all,
	call,
	cancel,
	fork,
	take,
	put,
	takeLatest,
	delay,
	select
} from 'redux-saga/effects';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	retrieveFinance,
	retrieveFinanceDetails,
	retrieveFinanceAsync,
	retrieveFinanceDetailsAsync
} from '../actions';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';
import { getIsAnyPortJobHavingFinanceAvailable } from 'store/portJobs/selectors';
import { getFinanceBenchmarksData } from '../selectors';

function* worker(): SagaIterator {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);

		const isPortCallFinanceAvailable = yield select(
			getIsAnyPortJobHavingFinanceAvailable
		);
		if (!isPortCallFinanceAvailable) {
			return;
		}

		const portCallAndPortJobCode = yield call(
			getActivePortCallAndPortJobIdsExecutor
		);
		const { portCallId, jobCode } = portCallAndPortJobCode;
		const benchmarkData = yield select(getFinanceBenchmarksData);
		if (jobCode) {
			yield all([
				put(
					retrieveFinance({
						...portCallAndPortJobCode,
						benchmarkData,
						isSilent: true,
						expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
					})
				),
				put(retrieveFinanceDetails({ portCallId }))
			]);
		}
	}
}

export default function*() {
	yield takeLatest(retrieveFinanceCycleStart, function*() {
		yield take(retrieveFinanceAsync.done);
		yield put(
			setNotificationActionTypeToNotification([
				retrieveFinanceAsync.type,
				retrieveFinanceDetailsAsync.type
			])
		);
		const pollFinanceTask = yield fork(worker);
		yield take(retrieveFinanceCycleStop);
		yield cancel(pollFinanceTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrieveFinanceAsync.type,
				retrieveFinanceDetailsAsync.type
			])
		);
	});
}
