export const DEVELOPER_PORTAL_FORM = 'developerPortalForm';
export const EMD_FORM = 'emdForm';
export const CANCEL_DEV_PORTAL_EDIT = 'cancelDevPortalEdit';
export const WEB_HOOK_END_POINT_MODAL = 'webHookEndPointModal';
export const WEB_HOOK_END_POINT_FORM = 'webHookEndPointForm';

export enum DownloadApiType {
	HTML = 'html',
	YAML = 'yaml',
	ZIP = 'outbound'
}

export enum ApiGroupList {
	PORTJOB_OPERATIONS = 'PortjobandOperations',
	FINANCE = 'Finance',
	VESSEL_PROGRAMME = 'VesselProgramme',
	EVENTS = 'Events',
	MEASUREMENTS = 'Measurements',
	DOCUMENTS = 'Documents',
	EMD_BUSINESS_ENTITY = 'EMDBusinessEntity',
	EMD_REFERENCE_ENTITY = 'EMDReferenceEntity',
	WEB_HOOK_NOTIFICATION = 'WebhookNotification'
}

export enum EMDApiEntityNameList {
	COMPANY = 'Company',
	BANK = 'Bank',
	BANK_ACCOUNT = 'BankAccount',
	PORT = 'Port',
	COMMODITY = 'Commodity',
	POI = 'POI',
	FINANCE = 'Finance',
	OPERATIONS = 'Operations',
	LOCATIONS = 'Location',
	COMPANY_AGENT_PORT = 'CompanyAgentPort'
}

export const ApiGroupName = {
	[ApiGroupList.PORTJOB_OPERATIONS]: 'Port Job and Operations',
	[ApiGroupList.FINANCE]: 'Port Job Finance',
	[ApiGroupList.VESSEL_PROGRAMME]: 'Vessel Programme',
	[ApiGroupList.EVENTS]: 'Event',
	[ApiGroupList.MEASUREMENTS]: 'Measurements',
	[ApiGroupList.DOCUMENTS]: 'Document'
};

export const EMDGroupName = {
	[ApiGroupList.EMD_BUSINESS_ENTITY]: 'EMD Business Entity',
	[ApiGroupList.EMD_REFERENCE_ENTITY]: 'EMD Reference Entity'
};

export const EMDApiName = {
	[EMDApiEntityNameList.COMPANY]: 'Company',
	[EMDApiEntityNameList.COMMODITY]: 'Commodity',
	[EMDApiEntityNameList.BANK]: 'Bank',
	[EMDApiEntityNameList.BANK_ACCOUNT]: 'Bank Account',
	[EMDApiEntityNameList.FINANCE]: 'Finance',
	[EMDApiEntityNameList.LOCATIONS]: 'Location',
	[EMDApiEntityNameList.OPERATIONS]: 'Operations',
	[EMDApiEntityNameList.POI]: 'POI',
	[EMDApiEntityNameList.PORT]: 'Port',
	[EMDApiEntityNameList.COMPANY_AGENT_PORT]: 'Company Agent Port'
};

export enum ApiSubscriptionsList {
	CREATE_PORTJOB = 'CreatePortJob',
	GET_PORTJOB_DETAILS = 'GetPortJob',
	UPDATE_PORTJOB = 'UpdatePortJob',
	CONFIRM_PORTJOB = 'ConfirmPortJob',
	CANCEL_REACTIVE_PORTJOB = 'CancelOrActivatePortJob',
	ADD_PORTJOB_OPERATION = 'AddJobOperation',
	PORTJOB_OPERATION_DETAILS = 'GetJobOperation',
	UPDATE_PORTJOB_OPERATION = 'UpdateJobOperation',
	DELETE_PORTJOB_OPERATION = 'DeleteJobOperation',
	APPROVE_FINANCE_SERVICES = 'ApproveService',
	APPROVE_ALL_FINANCE_SERVICES = 'ApproveAllServices',
	SUBMIT_FINANCE = 'SubmitFinance',
	GET_FINANCE_DETAILS = 'GetFinanceDetail',
	GET_FUNDING_REQUEST = 'GetFundingRequest',
	GET_FUNDING_DETAILS = 'GetFundingDetail',
	GET_VESSEL_PROGRAMME = 'GetVesselProgram',
	GET_EVENT_DETAILS = 'GetEventDetail',
	GET_CARGO_MEASUREMENTS = 'GetCargoMeasure',
	GET_BUNKERING_MEASUREMENTS = 'GetBunkeringMeasure',
	GET_SHIP_MEASUREMENTS = 'GetShipMeasure',
	GET_DOCUMENTS = 'GetDocuments'
}

export const ApiSubscriptionMethodName = {
	[ApiSubscriptionsList.CREATE_PORTJOB]: 'Create port job',
	[ApiSubscriptionsList.GET_PORTJOB_DETAILS]: 'Get port job',
	[ApiSubscriptionsList.UPDATE_PORTJOB]: 'Update port job',
	[ApiSubscriptionsList.CONFIRM_PORTJOB]: 'Confirm port job',
	[ApiSubscriptionsList.CANCEL_REACTIVE_PORTJOB]: 'Cancel/re-activate port job',
	[ApiSubscriptionsList.ADD_PORTJOB_OPERATION]: 'Add port job operation',
	[ApiSubscriptionsList.PORTJOB_OPERATION_DETAILS]: 'Get port job operation',
	[ApiSubscriptionsList.UPDATE_PORTJOB_OPERATION]: 'Update port job operation',
	[ApiSubscriptionsList.DELETE_PORTJOB_OPERATION]: 'Delete port job operation',
	[ApiSubscriptionsList.APPROVE_FINANCE_SERVICES]: 'Approve finance services',
	[ApiSubscriptionsList.APPROVE_ALL_FINANCE_SERVICES]:
		'Approve all finance services',
	[ApiSubscriptionsList.SUBMIT_FINANCE]: 'Submit finance',
	[ApiSubscriptionsList.GET_FINANCE_DETAILS]: 'Get finance',
	[ApiSubscriptionsList.GET_FUNDING_REQUEST]: 'Get funding request',
	[ApiSubscriptionsList.GET_FUNDING_DETAILS]: 'Get funding',
	[ApiSubscriptionsList.GET_VESSEL_PROGRAMME]: 'Get vessel program',
	[ApiSubscriptionsList.GET_EVENT_DETAILS]: 'Get events',
	[ApiSubscriptionsList.GET_CARGO_MEASUREMENTS]: 'Get cargo measurements',
	[ApiSubscriptionsList.GET_BUNKERING_MEASUREMENTS]:
		'Get bunkering measurements',
	[ApiSubscriptionsList.GET_SHIP_MEASUREMENTS]: 'Get ship measurements',
	[ApiSubscriptionsList.GET_DOCUMENTS]: 'Get documents'
};

export const ApiSubscriptionToolTip = {
	[ApiSubscriptionsList.CREATE_PORTJOB]: `Allows to create a new port job in Optic.`,
	[ApiSubscriptionsList.GET_PORTJOB_DETAILS]: `Allows to send the latest port job details from Optic.`,
	[ApiSubscriptionsList.UPDATE_PORTJOB]: `Allows to update port job in Optic.`,
	[ApiSubscriptionsList.CONFIRM_PORTJOB]: `Allows to confirm port job status in Optic.`,
	[ApiSubscriptionsList.CANCEL_REACTIVE_PORTJOB]: `Allows to cancel or reactivate port jobs in Optic.`,
	[ApiSubscriptionsList.ADD_PORTJOB_OPERATION]: `Allows to add new port job operations in port job existing in Optic.`,
	[ApiSubscriptionsList.PORTJOB_OPERATION_DETAILS]: `Allows to send existing port job operation details from Optic.`,
	[ApiSubscriptionsList.UPDATE_PORTJOB_OPERATION]: `Allows to update port job operations in Optic.`,
	[ApiSubscriptionsList.DELETE_PORTJOB_OPERATION]: `Allows to delete existing port job operation in Optic.`,
	[ApiSubscriptionsList.APPROVE_FINANCE_SERVICES]: `Allows to approve finance services in existing port job in Optic.`,
	[ApiSubscriptionsList.APPROVE_ALL_FINANCE_SERVICES]: `Allows to approve all finance services in existing port job in Optic.`,
	[ApiSubscriptionsList.SUBMIT_FINANCE]: `Allows to submit finance for existing port job in Optic.`,
	[ApiSubscriptionsList.GET_FINANCE_DETAILS]: `Allows to send Finance information for existing port job from Optic.`,
	[ApiSubscriptionsList.GET_FUNDING_DETAILS]: `Allows to send Finance Funding details for existing port job from Optic.`,
	[ApiSubscriptionsList.GET_FUNDING_REQUEST]: `Allow to send Funding status information from Optic.`,
	[ApiSubscriptionsList.GET_VESSEL_PROGRAMME]: `Allow to send Vessel programme information from Optic.`,
	[ApiSubscriptionsList.GET_EVENT_DETAILS]: `Allow to send port job events from Optic.`,
	[ApiSubscriptionsList.GET_CARGO_MEASUREMENTS]: `Allow to send port job cargo measurements from Optic.`,
	[ApiSubscriptionsList.GET_BUNKERING_MEASUREMENTS]: `Allow to send port job bunkering measurements from Optic.`,
	[ApiSubscriptionsList.GET_SHIP_MEASUREMENTS]: `Allow to send port job ship measurements from Optic.`,
	[ApiSubscriptionsList.GET_DOCUMENTS]:
		'Allow to send port job documents from Optic.'
};

export enum RequestType {
	PUT = 'PUT',
	POST = 'POST',
	GET = 'GET',
	DELETE = 'DELETE',
	INBOUND = 'Inbound',
	OUTBOUND = 'Outbound'
}

export const DeveloperPortalGroupsIndex = {
	[ApiSubscriptionsList.CREATE_PORTJOB]: {
		index: 'POI01'
	},
	[ApiSubscriptionsList.GET_PORTJOB_DETAILS]: {
		index: 'POO01'
	},
	[ApiSubscriptionsList.UPDATE_PORTJOB]: {
		index: 'POI02'
	},
	[ApiSubscriptionsList.CONFIRM_PORTJOB]: {
		index: 'POI03'
	},
	[ApiSubscriptionsList.CANCEL_REACTIVE_PORTJOB]: {
		index: 'POI04'
	},
	[ApiSubscriptionsList.ADD_PORTJOB_OPERATION]: {
		index: 'POI05'
	},
	[ApiSubscriptionsList.PORTJOB_OPERATION_DETAILS]: {
		index: 'POI06'
	},
	[ApiSubscriptionsList.UPDATE_PORTJOB_OPERATION]: {
		index: 'POI07'
	},
	[ApiSubscriptionsList.DELETE_PORTJOB_OPERATION]: {
		index: 'POI08'
	},
	[ApiSubscriptionsList.APPROVE_FINANCE_SERVICES]: {
		index: 'PFI01'
	},
	[ApiSubscriptionsList.APPROVE_ALL_FINANCE_SERVICES]: {
		index: 'PFI02'
	},
	[ApiSubscriptionsList.SUBMIT_FINANCE]: {
		index: 'PFI03'
	},
	[ApiSubscriptionsList.GET_FINANCE_DETAILS]: {
		index: 'PFO01'
	},
	[ApiSubscriptionsList.GET_FUNDING_DETAILS]: {
		index: 'PFO02'
	},
	[ApiSubscriptionsList.GET_FUNDING_REQUEST]: {
		index: 'PFO03'
	},
	[ApiSubscriptionsList.GET_VESSEL_PROGRAMME]: {
		index: 'VPO01'
	},
	[ApiSubscriptionsList.GET_EVENT_DETAILS]: {
		index: 'EO01'
	},
	[ApiSubscriptionsList.GET_CARGO_MEASUREMENTS]: {
		index: 'MEO01'
	},
	[ApiSubscriptionsList.GET_BUNKERING_MEASUREMENTS]: {
		index: 'MEO02'
	},
	[ApiSubscriptionsList.GET_SHIP_MEASUREMENTS]: {
		index: 'MEO03'
	},
	[ApiSubscriptionsList.GET_DOCUMENTS]: {
		index: 'DOC01'
	}
};

export enum WebHookConfigOption {
	YES = 'yes',
	NO = 'no'
}

export enum DeveloperPortalFelids {
	IS_WEB_HOOK_END_POINT = 'isWebhookEndPoint',
	WEB_HOOK_END_POINT_RESPONSE = 'webhookEndPointResponse'
}

export enum WebhookEndPointDetailsFields {
	URL = 'url',
	DATA_FORMAT = 'dataFormat',
	EVENTS = 'eventsWithLabels',
	AUTHENTICATION_TYPE = 'authenticationType',
	BASIC_AUTH_USER_NAME = 'basicAuthUserName',
	BASIC_AUTH_PASSWORD = 'basicAuthPassword',
	AUTH_KEY = 'authKey',
	AUTH_VALUE = 'authValue',
	ADD_TO = 'addTo',
	BEARER_TOKEN = 'bearerToken'
}

export enum EventsList {
	FINANCES = 'finances',
	FUNDINGS = 'fundings',
	FUNDING_REQUEST = 'funding-requests',
	VESSEL_PROGRAMMES = 'vessel-programmes',
	EVENTS = 'events',
	DOCUMENTS = 'documents',
	CARGO = 'cargo',
	BUNKERING = 'bunkering',
	SHIP = 'ship',
	STATUS_CHANGE = 'status-change',
	CLONE_JOB = 'clone-job'
}

export const EventsMap = {
	[EventsList.FINANCES]: 'Get finance',
	[EventsList.FUNDINGS]: 'Get funding',
	[EventsList.FUNDING_REQUEST]: 'Get funding request',
	[EventsList.VESSEL_PROGRAMMES]: 'Get vessel program',
	[EventsList.EVENTS]: 'Get event',
	[EventsList.DOCUMENTS]: 'Get documents',
	[EventsList.CARGO]: 'Get cargo measurements',
	[EventsList.BUNKERING]: 'Get bunkering measurements',
	[EventsList.SHIP]: 'Get ship measurements',
	[EventsList.STATUS_CHANGE]: 'Get status change',
	[EventsList.CLONE_JOB]: 'Get clone job'
};

export const outBoundEventsMap = {
	[ApiSubscriptionsList.GET_FINANCE_DETAILS]: EventsList.FINANCES,
	[ApiSubscriptionsList.GET_FUNDING_DETAILS]: EventsList.FUNDINGS,
	[ApiSubscriptionsList.GET_FUNDING_REQUEST]: EventsList.FUNDING_REQUEST,
	[ApiSubscriptionsList.GET_VESSEL_PROGRAMME]: EventsList.VESSEL_PROGRAMMES,
	[ApiSubscriptionsList.GET_EVENT_DETAILS]: EventsList.EVENTS,
	[ApiSubscriptionsList.GET_DOCUMENTS]: EventsList.DOCUMENTS,
	[ApiSubscriptionsList.GET_CARGO_MEASUREMENTS]: EventsList.CARGO,
	[ApiSubscriptionsList.GET_BUNKERING_MEASUREMENTS]: EventsList.BUNKERING,
	[ApiSubscriptionsList.GET_SHIP_MEASUREMENTS]: EventsList.SHIP
};

export const eventsList = {
	[EventsList.FINANCES]: {
		key: EventsList.FINANCES,
		label: EventsMap[EventsList.FINANCES]
	},
	[EventsList.FUNDINGS]: {
		key: EventsList.FUNDINGS,
		label: EventsMap[EventsList.FUNDINGS]
	},
	[EventsList.FUNDING_REQUEST]: {
		key: EventsList.FUNDING_REQUEST,
		label: EventsMap[EventsList.FUNDING_REQUEST]
	},
	[EventsList.VESSEL_PROGRAMMES]: {
		key: EventsList.VESSEL_PROGRAMMES,
		label: EventsMap[EventsList.VESSEL_PROGRAMMES]
	},
	[EventsList.EVENTS]: {
		key: EventsList.EVENTS,
		label: EventsMap[EventsList.EVENTS]
	},
	[EventsList.DOCUMENTS]: {
		key: EventsList.DOCUMENTS,
		label: EventsMap[EventsList.DOCUMENTS]
	},
	[EventsList.CARGO]: {
		key: EventsList.CARGO,
		label: EventsMap[EventsList.CARGO]
	},
	[EventsList.BUNKERING]: {
		key: EventsList.BUNKERING,
		label: EventsMap[EventsList.BUNKERING]
	},
	[EventsList.SHIP]: {
		key: EventsList.SHIP,
		label: EventsMap[EventsList.SHIP]
	}
};

export enum AuthTypeList {
	NO_AUTH = 'NoAuth',
	BASIC_AUTH = 'BasicAuth',
	AUTH_KEY = 'AuthKey',
	BEARER_TOKEN = 'BearerToken'
}

export const AuthTypeMap = {
	[AuthTypeList.NO_AUTH]: 'No Auth',
	[AuthTypeList.BASIC_AUTH]: 'Basic Auth',
	[AuthTypeList.AUTH_KEY]: 'Auth Key',
	[AuthTypeList.BEARER_TOKEN]: 'Bearer Token'
};
