import { EntityPermissionComposeId } from 'services/api/permissions/permissionsServiceTypes';
import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';

export const getEntityPermissions = (
	state: AppState,
	entityType: string,
	entityKey: string
) => {
	const entitiesMap = state.permissions.byEntityType;
	return entitiesMap[entityType] && entitiesMap[entityType][entityKey];
};

export const getEntityPermissionFetchStatus = (
	state: AppState,
	entityType: string,
	entityKey: string
) => {
	const fetchStatuses = state.permissions.fetchStatusesByEntityType;
	return (
		(fetchStatuses[entityType] && fetchStatuses[entityType][entityKey]) ||
		FetchStatus.IDLE
	);
};

export const getIsEntityPermissionLoading = (
	state: AppState,
	composedId: EntityPermissionComposeId
) =>
	getEntityPermissionFetchStatus(
		state,
		composedId.entityType,
		composedId.entityKey
	) === FetchStatus.PENDING;
