import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import { UpdateBunkeringOperationGradeRequest } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { Failure, Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';

const getSetUpdateStatuses = (
	state: State,
	status: FetchStatus,
	bunkeringOerationGradeId: string
) => {
	return {
		...state.updateStatuses,
		bunkering: {
			...state.updateStatuses.bunkering,
			[bunkeringOerationGradeId]: status
		}
	};
};

export const onUpdateBunkeringOperationGradeStarted = (
	state: State,
	{ bunkeringOperationGradeId }: UpdateBunkeringOperationGradeRequest
) => {
	const { bunkering } = state;
	if (!bunkering) {
		return state;
	}
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.PENDING,
		bunkeringOperationGradeId
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdateBunkeringOperationGradeSuccess = (
	state: State,
	action: Success<UpdateBunkeringOperationGradeRequest, null>
) => {
	const { bunkering } = state;
	if (!bunkering) {
		return state;
	}
	const { bunkeringOperationGradeId } = action.params;

	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.SUCCESS,
		bunkeringOperationGradeId
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdateBunkeringOperationGradeFailed = (
	state: State,
	action: Failure<UpdateBunkeringOperationGradeRequest, Error>
) => {
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.FAILURE,
		action.params.bunkeringOperationGradeId
	);
	return {
		...state,
		updateStatuses,
		error: action.error
	};
};
