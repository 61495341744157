import React from 'react';
import { Field as BaseField, BaseFieldProps } from 'redux-form';
import { identity } from 'lodash';

import createFieldComponent, {
	FieldComponentProps
} from './createReduxFieldComponent';

export type ReduxFieldProps<T> = Readonly<
	BaseFieldProps<T & FieldComponentProps> & T & FieldComponentProps
>;

export type CreateReduxField<T> = React.FC<ReduxFieldProps<T>>;

function createReduxField<T>(
	WrappedComponent: React.ComponentType<T>,
	mapProps: (
		props: Readonly<T & FieldComponentProps>
	) => Partial<T & FieldComponentProps> = identity
): CreateReduxField<T> {
	const FieldComponent: React.ComponentType<FieldComponentProps &
		T> = createFieldComponent<T>(WrappedComponent, mapProps);

	const Field: React.FC<ReduxFieldProps<T>> = props => (
		<BaseField {...props} component={FieldComponent} />
	);

	return Field;
}

export default createReduxField;
