import { put } from 'redux-saga/effects';
import { notify } from 'store/notifications/actions';
import { retrievePortJobSummaryAsync as async } from 'store/portJobs/actions/retrievePortJobSummary';
import Api from 'services/api';
import {
	PortJobSummaryRequest as Request,
	PortJobSummaryResponse as Response
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.PortJobs.retrievePortJobSummary;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*onError(params: Request) {
		if (!params.isSilent) {
			yield put(notify.error(`Port Job couldn't be retrieved.`));
		}
	}
});
