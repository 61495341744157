import UserPermission from 'components/UserPermission/UserPermission';
import * as React from 'react';
import { connect } from 'react-redux';
import { Title, Asteriks } from 'components';
import { Select, Tooltip } from 'components/antd';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { ContextType } from 'services/api/threads/threadsServiceTypes';
import { getActiveThreadId } from 'store/threads/selectors';
import {
	getThreadContext,
	getIsCurrentUserAssignedToActiveThread,
	getIsContextDisabled
} from 'store/thread/selectors';
import { updateContext } from 'store/thread';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';
import ContextContainer from './ContextContainer';
import { AppState } from 'store-types';

interface ContextOwnProps {
	contextId?: string;
	showTitle?: boolean;
	isNewThread?: boolean;
	isComposeMessage?: boolean;
	onChange?: (context: string | null) => void;
}
type ContextProps = ContextOwnProps & {
	activeThreadId: string;
	currentUserAssignedToThread: boolean;
	isThreadProtected: boolean;
	context: ContextType | null;
	threadContexts: ContextType[];
	updateContext: typeof updateContext;
};

interface ContextState {
	isDisabled: boolean;
}

class ContextSearch extends React.Component<ContextProps, ContextState> {
	static defaultProps = {
		showTitle: true
	};

	constructor(props: ContextProps) {
		super(props);

		this.state = {
			isDisabled: this.isDisabled()
		};
	}

	componentDidMount() {
		const { contextId, threadContexts, onChange, isNewThread } = this.props;

		// Quick solution
		// Reset value if it doesn't exists in thread's context list when composing a message
		if (isNewThread && contextId && threadContexts.length) {
			const currentContext = threadContexts.find(
				threadContext => threadContext.id === contextId
			);

			if (onChange) {
				onChange(currentContext ? currentContext.id : '');
			}
		}
	}

	componentDidUpdate(prevProps: ContextProps) {
		const { threadContexts, currentUserAssignedToThread } = this.props;

		const isNewThread = this.props.isNewThread !== prevProps.isNewThread;
		const threadHaveNewContextOptions =
			prevProps.threadContexts.length !== threadContexts.length;
		const isCurrentUserAssignedToThread =
			prevProps.currentUserAssignedToThread !== currentUserAssignedToThread;

		if (
			isNewThread ||
			threadHaveNewContextOptions ||
			isCurrentUserAssignedToThread
		) {
			const isDisabled = this.isDisabled();
			this.setState({ isDisabled });
		}
	}

	handleChange = (value: string) => {
		const {
			activeThreadId,
			onChange,
			isComposeMessage,
			threadContexts
		} = this.props;
		const chosenItem =
			threadContexts.find(threadContext => threadContext.name === value) ||
			null;
		if (activeThreadId !== NEW_THREAD_ID && !isComposeMessage) {
			this.props.updateContext({
				threadId: activeThreadId,
				threadContext: chosenItem
			});
		}
		if (onChange) {
			onChange(chosenItem ? chosenItem.id : '');
		}
	};

	isDisabled(): boolean {
		const {
			threadContexts,
			isNewThread,
			currentUserAssignedToThread,
			isThreadProtected,
			isComposeMessage
		} = this.props;
		return (
			(!isComposeMessage && isThreadProtected) ||
			(isNewThread && !threadContexts.length) ||
			(!isNewThread && (!currentUserAssignedToThread || !threadContexts.length))
		);
	}

	filterOption = (
		input: string,
		option: React.ReactElement<{ children: string }>
	) =>
		option.props.children
			.toString()
			.toLowerCase()
			.includes(input.toLowerCase());

	getDefaultValue() {
		const { contextId, context, threadContexts, isNewThread } = this.props;
		let defaultValue;
		// TODO
		// Duplicated the same logic in `didMount`
		if (contextId && threadContexts.length) {
			// create new thread meta data
			const currentContext = threadContexts.find(
				threadContext => threadContext.id === contextId
			);
			defaultValue = currentContext?.name;
		} else if (!isNewThread && context) {
			// thread settings (Thread View)
			defaultValue = context.name;
		}

		return defaultValue || 'Type to select a Context';
	}

	getSelect = () => {
		const { isComposeMessage, threadContexts } = this.props;

		const defaultValue = this.getDefaultValue();

		return (
			<Select
				showSearch
				placeholder={defaultValue}
				optionFilterProp="children"
				onChange={this.handleChange}
				filterOption={this.filterOption}
				disabled={this.state.isDisabled}
				value={defaultValue}
			>
				{!isComposeMessage && (
					<Select.Option value={undefined}> &nbsp; </Select.Option>
				)}
				{threadContexts.map(threadContext => (
					<Select.Option key={threadContext.id} value={threadContext.name}>
						{threadContext.name}
					</Select.Option>
				))}
			</Select>
		);
	};

	render() {
		const { showTitle, isThreadProtected } = this.props;

		return (
			<UserPermission
				code={[
					GroupTypeCodes.ISS_CLUSTER,
					GroupTypeCodes.ISS_HUB,
					GroupTypeCodes.ISS_MANAGE
				]}
			>
				<section>
					<>
						{/* "&nbsp;" required by @QA */}
						{showTitle && <Title.H4>&nbsp;Thread context:</Title.H4>}
						<Asteriks position="right">Context</Asteriks>
						{isThreadProtected ? (
							<Tooltip
								trigger="hover"
								title="Context cannot be changed for this thread"
							>
								{this.getSelect()}
							</Tooltip>
						) : (
							this.getSelect()
						)}
					</>
				</section>
			</UserPermission>
		);
	}
}

const ContextSearchConnected = connect(
	(
		state: AppState,
		ownProps: Partial<
			Pick<ContextProps, 'currentUserAssignedToThread' | 'isNewThread'>
		>
	) => {
		return {
			activeThreadId: getActiveThreadId(state),
			currentUserAssignedToThread: getIsCurrentUserAssignedToActiveThread(
				state
			),
			isThreadProtected: getIsContextDisabled(state, !!ownProps.isNewThread),
			context: getThreadContext(state)
		};
	},
	{
		updateContext
	}
)(ContextSearch);

export default (props: ContextOwnProps) => {
	return (
		<ContextContainer>
			{data => <ContextSearchConnected {...props} threadContexts={data} />}
		</ContextContainer>
	);
};
