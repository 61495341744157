import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
	ToggleOperationsStatusParams,
	ToggleOperationsResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { toggleOperationsStatusAsync } from 'store/portJobs/actions';
import { getActivePortJobCode } from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';

const apiCall = Api.PortJobs.toggleOperationsStatus;

export const executor = function*(
	payload: ToggleOperationsStatusParams
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const request = {
		...payload,
		portCallId,
		portJobCode
	};
	yield put(toggleOperationsStatusAsync.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			toggleOperationsStatusAsync.done({
				result: { isOperationsDisabled: response.data.isOperationsDisabled },
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			toggleOperationsStatusAsync.failed({
				error,
				params: request
			})
		);
	}
};

export const toggleOperationsStatusWatcher = makeTakeLatestWatcher<
	ToggleOperationsStatusParams,
	ToggleOperationsResponse,
	Error
>(
	{
		api: apiCall,
		async: toggleOperationsStatusAsync
	},
	executor
);

export default toggleOperationsStatusWatcher;
