import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import {
	DeleteCompanyVesselRequest,
	DeleteCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { omit } from 'lodash';

export const onDeleteCompanyVessel = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	DeleteCompanyVesselRequest,
	DeleteCompanyVesselResponse
>('fetchStatuses', 'delete', {
	doneReducer: (
		state: CompanyVesselsState,
		action: Success<DeleteCompanyVesselRequest, DeleteCompanyVesselResponse>
	): CompanyVesselsState => ({
		...state,
		byId: omit(state.byId, action.params.id)
	})
});
