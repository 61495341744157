import { RetrieveSupplierInvoiceContextualizationResponse } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../supplierInvoiceContextualizationActionCreator';

export interface InitPortJobsInContextualizationFormParams
	extends RetrieveSupplierInvoiceContextualizationResponse {
	shouldAllServicesBeDeselected?: boolean;
}

export const initPortJobsInContextualizationForm = actionCreator<
	InitPortJobsInContextualizationFormParams
>('INIT_PORT_JOBS_IN_CONTEXTUALIZATION_FORM');
