import {
	VesselProgrammeState,
	OperationUnitsById,
	CustodyTransfer,
	RotationStepsById
} from 'store/vesselProgramme/vesselProgrammeState';
import {
	CustodyAgentType,
	CustodyTransferType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { buildPositionedRotationSteps } from './retrieveVP/normalization';
import { mapValues, includes } from 'lodash';
import { custodyTransferOrdered } from '../vesselProgrammeConstants';
const getNextCustodyLineState = (current: CustodyTransferType) => {
	const position = custodyTransferOrdered.findIndex(
		custody => custody === current
	);
	return position + 1 < custodyTransferOrdered.length
		? custodyTransferOrdered[position + 1]
		: current;
};
const getPrevCustodyLineState = (current: CustodyTransferType) => {
	const position = custodyTransferOrdered.findIndex(
		custody => custody === current
	);
	return position - 1 >= 0 ? custodyTransferOrdered[position - 1] : current;
};
const reAssignOperationUnits = (
	custodyTransfer: CustodyTransfer,
	rotationStepsById: RotationStepsById,
	operationUnitsById: OperationUnitsById
) => {
	const linePosition = custodyTransfer.linePosition;
	const linePositionToAgentModeMap = {
		Before: CustodyAgentType.OUTBOUND,
		After: CustodyAgentType.INBOUND
	};
	if (linePosition && linePosition !== 'During') {
		const assignedUnits =
			rotationStepsById[custodyTransfer.rotationStepId].units;
		// every unit allocated to this step needs to be set to proper agent to be visible
		return mapValues(operationUnitsById, unit =>
			includes(assignedUnits, unit.id)
				? {
						...unit,
						job: {
							...unit.job,
							agentRole: linePositionToAgentModeMap[linePosition]
						}
				  }
				: unit
		);
	}
	return operationUnitsById;
};
export const onMoveUpCustodyLine = (
	state: VesselProgrammeState
): VesselProgrammeState => {
	if (!state.custodyTransfer) {
		return state; // moving line only applicable for existing line
	}
	const newState = {
		...state,
		rotationStepsById: {
			...state.rotationStepsById,
			[state.custodyTransfer.rotationStepId]: {
				...state.rotationStepsById[state.custodyTransfer.rotationStepId],
				custodyTransfer: getPrevCustodyLineState(
					state.custodyTransfer.linePosition
				) // change in orginal rotation step
			}
		},
		custodyTransfer: {
			...state.custodyTransfer,
			linePosition: getPrevCustodyLineState(state.custodyTransfer.linePosition)
		}
	};
	newState.rotationStepsPositionedToCustodyLine = buildPositionedRotationSteps(
		newState.orderedRotationSteps,
		newState.custodyTransfer
	);
	newState.operationUnitsById = reAssignOperationUnits(
		newState.custodyTransfer,
		newState.rotationStepsById,
		newState.operationUnitsById
	);
	return newState;
};
export const onMoveDownCustodyLine = (
	state: VesselProgrammeState
): VesselProgrammeState => {
	if (!state.custodyTransfer) {
		return state; // moving line only applicable for existing line
	}
	const newState = {
		...state,
		rotationStepsById: {
			...state.rotationStepsById,
			[state.custodyTransfer.rotationStepId]: {
				...state.rotationStepsById[state.custodyTransfer.rotationStepId],
				custodyTransfer: getNextCustodyLineState(
					state.custodyTransfer.linePosition
				) // change in orginal rotation step
			}
		},
		custodyTransfer: {
			...state.custodyTransfer,
			linePosition: getNextCustodyLineState(state.custodyTransfer.linePosition)
		}
	};
	newState.rotationStepsPositionedToCustodyLine = buildPositionedRotationSteps(
		newState.orderedRotationSteps,
		newState.custodyTransfer
	);
	newState.operationUnitsById = reAssignOperationUnits(
		newState.custodyTransfer,
		newState.rotationStepsById,
		newState.operationUnitsById
	);
	return newState;
};
