import { onUpdateConfigurationBenchmark } from './reducers/updateConfigurationBenchmark';
import { updateConfigurationBenchmarkAsync } from './actions/updateConfigurationBenchmark';
import { retrieveConfigurationBenchmarkAsync } from './actions/retrieveConfigurationBenchmark';
import { onRetrieveConfigurationBenchmark } from './reducers/retrieveConfigurationBenchmark';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	setActiveCompanyId,
	retrieveConfigurationCompaniesAsync,
	initializeConfigurationAsync,
	updateConfigurationGeneralDetailsAsync,
	updateConfigurationEmailFragmentsAsync,
	setConfigurationHasUnsavedData,
	setVesselTypesTabMode,
	deleteActiveCompanyVesselTypeAsync,
	uploadConfigurationPreferredAgentsFileAsync,
	retrieveMainPrincipalGroupsAsync,
	retrievePrincipalIntegrationSettingsAsync,
	retrieveEventListAndOpticVariablesAsync,
	createFeedbackManagementRequestAsync,
	updateFeedbackManagementRequestAsync,
	uploadRequestFeedbackDocumentDone,
	deleteRequestFeedbackFileInfo,
	deleteAllRequestFeedbackFileInfo,
	retrieveFeedbackManagementGridAsync,
	deleteFeedbackManagementRequestAsync,
	downloadFeedbackManagementRequestAsync,
	updateRequestFeedbackDocumentDone
} from './actions';
import {
	onSetActiveCompanyId,
	onInitializeConfiguration,
	onRetrieveConfigurationCompaniesSuccess,
	onUpdateConfigurationGeneralDetails,
	onUpdateConfigurationEmailFragments,
	onSetConfigurationHasUnsavedData,
	onSetVesselTypesTabMode,
	onDeleteActiveCompanyVesselType,
	onUploadConfigurationPrefferedAgentsFile,
	onRetrieveMainPrincipalGroups,
	onRetrievePrincipalIntegrationSettings,
	onRetrieveEventListAndOpticVariables,
	onCreateFeedbackManagementRequest,
	onUploadRequestFeedbackDocumentDone,
	onDeleteRequestFeedbackFileInfo,
	onDeleteAllRequestFeedbackFileInfo,
	onDeleteFeedbackManagementRequest,
	onDownloadFeedbackManagementRequest,
	onUpdateRequestFeedbackDocumentDone,
	onUpdateFeedbackManagementRequest
} from './reducers';
import { initialState } from './configurationState';
import { retrieveConfigurationFeedbackManagementAsync } from './actions/retrieveConfigurationFeedbackManagement';
import { onRetrieveConfigurationFeedbackManagement } from './reducers/retrieveConfigurationFeedbackManagement';
import { updateConfigurationFeedbackManagementAsync } from './actions/updateConfigurationFeedbackManagement';
import { onUpdateConfigurationFeedbackManagement } from './reducers/updateConfigurationFeedbackManagement';
import { onRetrieveFeedbackManagementGrid } from './reducers/retrieveFeedbackManagementGridReducer';

export default reducerWithInitialState(initialState)
	.case(setActiveCompanyId, onSetActiveCompanyId)
	.case(
		retrieveConfigurationCompaniesAsync.done,
		onRetrieveConfigurationCompaniesSuccess
	)
	.case(setVesselTypesTabMode, onSetVesselTypesTabMode)
	.case(
		deleteActiveCompanyVesselTypeAsync.started,
		onDeleteActiveCompanyVesselType.started
	)
	.case(
		deleteActiveCompanyVesselTypeAsync.done,
		onDeleteActiveCompanyVesselType.done
	)
	.case(
		deleteActiveCompanyVesselTypeAsync.failed,
		onDeleteActiveCompanyVesselType.failed
	)
	// Initialize Configuration
	.case(initializeConfigurationAsync.started, onInitializeConfiguration.started)
	.case(initializeConfigurationAsync.done, onInitializeConfiguration.done)
	.case(initializeConfigurationAsync.failed, onInitializeConfiguration.failed)
	// Update Configuration General Details
	.case(
		updateConfigurationGeneralDetailsAsync.started,
		onUpdateConfigurationGeneralDetails.started
	)
	.case(
		updateConfigurationGeneralDetailsAsync.done,
		onUpdateConfigurationGeneralDetails.done
	)
	.case(
		updateConfigurationGeneralDetailsAsync.failed,
		onUpdateConfigurationGeneralDetails.failed
	)
	// Update Configuration Email Fragments
	.case(
		updateConfigurationEmailFragmentsAsync.started,
		onUpdateConfigurationEmailFragments.started
	)
	.case(
		updateConfigurationEmailFragmentsAsync.done,
		onUpdateConfigurationEmailFragments.done
	)
	.case(
		updateConfigurationEmailFragmentsAsync.failed,
		onUpdateConfigurationEmailFragments.failed
	)
	.case(setConfigurationHasUnsavedData, onSetConfigurationHasUnsavedData)
	// Upload Configuration Preferred Agents File
	.case(
		uploadConfigurationPreferredAgentsFileAsync.started,
		onUploadConfigurationPrefferedAgentsFile.started
	)
	.case(
		uploadConfigurationPreferredAgentsFileAsync.done,
		onUploadConfigurationPrefferedAgentsFile.done
	)
	.case(
		uploadConfigurationPreferredAgentsFileAsync.failed,
		onUploadConfigurationPrefferedAgentsFile.failed
	)
	// Retrieve Main Principal Groups
	.case(
		retrieveMainPrincipalGroupsAsync.started,
		onRetrieveMainPrincipalGroups.started
	)
	.case(
		retrieveMainPrincipalGroupsAsync.done,
		onRetrieveMainPrincipalGroups.done
	)
	.case(
		retrieveMainPrincipalGroupsAsync.failed,
		onRetrieveMainPrincipalGroups.failed
	)
	// Retrieve Principal Integration Settings
	.case(
		retrievePrincipalIntegrationSettingsAsync.started,
		onRetrievePrincipalIntegrationSettings.started
	)
	.case(
		retrievePrincipalIntegrationSettingsAsync.done,
		onRetrievePrincipalIntegrationSettings.done
	)
	.case(
		retrievePrincipalIntegrationSettingsAsync.failed,
		onRetrievePrincipalIntegrationSettings.failed
	)
	.case(
		retrieveConfigurationBenchmarkAsync.started,
		onRetrieveConfigurationBenchmark.started
	)
	.case(
		retrieveConfigurationBenchmarkAsync.done,
		onRetrieveConfigurationBenchmark.done
	)
	.case(
		retrieveConfigurationBenchmarkAsync.failed,
		onRetrieveConfigurationBenchmark.failed
	)
	.case(uploadRequestFeedbackDocumentDone, onUploadRequestFeedbackDocumentDone)
	.case(updateRequestFeedbackDocumentDone, onUpdateRequestFeedbackDocumentDone)
	.case(deleteRequestFeedbackFileInfo, onDeleteRequestFeedbackFileInfo)
	.case(deleteAllRequestFeedbackFileInfo, onDeleteAllRequestFeedbackFileInfo)
	.case(
		updateConfigurationBenchmarkAsync.started,
		onUpdateConfigurationBenchmark.started
	)
	.case(
		updateConfigurationBenchmarkAsync.done,
		onUpdateConfigurationBenchmark.done
	)
	.case(
		updateConfigurationBenchmarkAsync.failed,
		onUpdateConfigurationBenchmark.failed
	)
	.case(
		retrieveConfigurationFeedbackManagementAsync.started,
		onRetrieveConfigurationFeedbackManagement.started
	)
	.case(
		retrieveConfigurationFeedbackManagementAsync.done,
		onRetrieveConfigurationFeedbackManagement.done
	)
	.case(
		createFeedbackManagementRequestAsync.started,
		onCreateFeedbackManagementRequest.started
	)
	.case(
		createFeedbackManagementRequestAsync.failed,
		onCreateFeedbackManagementRequest.failed
	)
	.case(
		createFeedbackManagementRequestAsync.done,
		onCreateFeedbackManagementRequest.done
	)
	.case(
		retrieveFeedbackManagementGridAsync.started,
		onRetrieveFeedbackManagementGrid.started
	)
	.case(
		retrieveFeedbackManagementGridAsync.failed,
		onRetrieveFeedbackManagementGrid.failed
	)
	.case(
		retrieveFeedbackManagementGridAsync.done,
		onRetrieveFeedbackManagementGrid.done
	)
	.case(
		retrieveEventListAndOpticVariablesAsync.started,
		onRetrieveEventListAndOpticVariables.started
	)
	.case(
		retrieveEventListAndOpticVariablesAsync.failed,
		onRetrieveEventListAndOpticVariables.failed
	)
	.case(
		retrieveEventListAndOpticVariablesAsync.done,
		onRetrieveEventListAndOpticVariables.done
	)
	.case(
		retrieveConfigurationFeedbackManagementAsync.failed,
		onRetrieveConfigurationFeedbackManagement.failed
	)
	.case(
		updateConfigurationFeedbackManagementAsync.started,
		onUpdateConfigurationFeedbackManagement.started
	)
	.case(
		updateConfigurationFeedbackManagementAsync.done,
		onUpdateConfigurationFeedbackManagement.done
	)
	.case(
		updateConfigurationFeedbackManagementAsync.failed,
		onUpdateConfigurationFeedbackManagement.failed
	)
	.case(
		updateFeedbackManagementRequestAsync.started,
		onUpdateFeedbackManagementRequest.started
	)
	.case(
		updateFeedbackManagementRequestAsync.done,
		onUpdateFeedbackManagementRequest.done
	)
	.case(
		updateFeedbackManagementRequestAsync.failed,
		onUpdateFeedbackManagementRequest.failed
	)
	.case(
		deleteFeedbackManagementRequestAsync.started,
		onDeleteFeedbackManagementRequest.started
	)
	.case(
		deleteFeedbackManagementRequestAsync.failed,
		onDeleteFeedbackManagementRequest.failed
	)
	.case(
		deleteFeedbackManagementRequestAsync.done,
		onDeleteFeedbackManagementRequest.done
	)
	.case(
		downloadFeedbackManagementRequestAsync.started,
		onDownloadFeedbackManagementRequest.started
	)
	.case(
		downloadFeedbackManagementRequestAsync.started,
		onDownloadFeedbackManagementRequest.started
	)
	.case(
		downloadFeedbackManagementRequestAsync.started,
		onDownloadFeedbackManagementRequest.started
	);
