import {
	RotationStepEvent,
	RetrieveOperationUnit,
	RetrieveRotationStep,
	RotationStepEventParentType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

import {
	ReducerRotationStepEvent,
	ReducerOperationUnit,
	ReducerRotationStep
} from 'store/vesselProgramme/vesselProgrammeState';

import uuidv1 from 'uuid';

const FAKE_ID = '00000000-0000-0000-0000-000000000000';

export const getVPEntityUniqueId = (id: string, getUniqueId = uuidv1) =>
	Boolean(id) && id !== FAKE_ID ? id : `${getUniqueId()}[generated]`;
export const getVPEventTemporaryErrorId = (id: string, getUniqueId = uuidv1) =>
	Boolean(id) && id !== FAKE_ID ? undefined : `${getUniqueId()}[validation]`;

export const getVPEventWithGeneratedUniqueIds = (
	event: RotationStepEvent,
	getUniqueId = uuidv1
) => ({
	...event,
	id: getVPEntityUniqueId(event.id, getUniqueId),
	trackValidationId: getVPEventTemporaryErrorId(event.id, getUniqueId)
});

// shim to ensure the steps and its events and units actually come filled with an usable ID
export const toCorrectlyFilledStep = (
	step: RetrieveRotationStep,
	getUniqueId = uuidv1
): RetrieveRotationStep => {
	return {
		...step,
		isDefault: false,
		id: getVPEntityUniqueId(step.id),
		events: step.events.map(event =>
			getVPEventWithGeneratedUniqueIds(event, getUniqueId)
		),
		units: (step.units || []).map(unit => ({
			...unit,
			id: getVPEntityUniqueId(unit.id),
			events: unit.events.map(event =>
				getVPEventWithGeneratedUniqueIds(event, getUniqueId)
			)
		}))
	};
};

export const toReducerRotationStep = (
	step: RetrieveRotationStep
): ReducerRotationStep => ({
	...step,
	id: step.id,
	events: (step.events || []).map(e => e.id),
	units: (step.units || []).map(u => u.id),
	canEdit: true
});

export const toReducerOperationUnit = (
	unit: RetrieveOperationUnit
): ReducerOperationUnit => ({
	...unit,
	events: unit.events.map(e => e.id),
	allocatedId: ''
});

export const toReducerEvent = (
	event: RotationStepEvent
): ReducerRotationStepEvent => ({
	...event,
	id: event.id,
	parentId: '',
	parentType: RotationStepEventParentType.ROTATION_STEP
});
