import {
	RetrieveAnchoragesRequest,
	RetrieveAnchoragesResponse
} from 'services/api/ports/portsServiceTypes';
import actionCreator from '../actionCreator';

const RETRIEVE_ANCHORAGES = 'RETRIEVE_ANCHORAGES';

export const retrieveAnchoragesAsync = actionCreator.async<
	RetrieveAnchoragesRequest,
	RetrieveAnchoragesResponse,
	Error
>(RETRIEVE_ANCHORAGES);

export const retrieveAnchorages = actionCreator<RetrieveAnchoragesRequest>(
	RETRIEVE_ANCHORAGES
);
