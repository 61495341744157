import {
	GetLinkedCargosRequest as request,
	GetLinkedCargosResponse as response
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../linkJobActionCreator';

const RETRIEVE_ACTION_NAME = 'RETRIEVE_LINKED_CARGO_DETAILS';

export const retrievelinkedJobCargosAsync = actionCreator.async<
	request,
	response,
	Error
>(RETRIEVE_ACTION_NAME);

export const retrievelinkedJobCargos = actionCreator<request>(
	RETRIEVE_ACTION_NAME
);
