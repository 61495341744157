import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	RetrieveMailboxAvailableGroupRequest,
	RetrieveMailboxAvailableGroupResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';
import { retrieveMailboxAvailableGroupsAsync } from '../actions/retrieveMailboxAvailableGroups';

export default makeTakeLatestWatcher<
	RetrieveMailboxAvailableGroupRequest,
	RetrieveMailboxAvailableGroupResponse,
	Error
>({
	api: Api.Mailboxes.retrieveMailboxAvailableGroups,
	async: retrieveMailboxAvailableGroupsAsync
});
