import {
	RefreshBenchmarkResponse,
	RefreshBenchmarkRequest
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const REFRESH_BENCHMARK = 'REFRESH_BENCHMARK';
export const refreshBenchmarkAsync = actionCreator.async<
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse,
	Error
>(REFRESH_BENCHMARK, {
	failed: {
		description: 'No new benchmark has been found'
	}
});

export const refreshBenchmark = actionCreator(REFRESH_BENCHMARK);
