import {
	RetrieveConfigurationFeedbackManagementRequest,
	ConfigurationFeedbackManagementData
} from 'services/api/companies/companiesServiceTypes';
import { getActiveCompanyId } from 'store/configuration/selectors';
import { select } from 'redux-saga/effects';
import { retrieveConfigurationFeedbackManagementAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	null,
	ConfigurationFeedbackManagementData,
	Error,
	RetrieveConfigurationFeedbackManagementRequest
>({
	api: Api.Companies.retrieveFeedbackManagementConfiguration,
	async: retrieveConfigurationFeedbackManagementAsync,
	*getApiParams() {
		const companyId: string = yield select(getActiveCompanyId);
		return {
			companyId
		};
	}
});
