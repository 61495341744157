import actionCreator from '../portJobsActionCreator';
import { DeletePortJobOperationPayload } from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'DELETE_PORTJOB_OPERATION';

export const deletePortJobOperationAsync = actionCreator.async<
	DeletePortJobOperationPayload,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412, 404]
	},
	templateParams: {
		entityName: 'Operation'
	}
});

export const deletePortJobOperation = actionCreator<
	DeletePortJobOperationPayload
>(ACTION_NAME);
