import React from 'react';
import { ReduxForm } from 'components';
import {
	WEB_HOOK_END_POINT_FORM,
	WebhookEndPointDetailsFields,
	AuthTypeList
} from '../../constants';
import { getFormValues, change, resetSection, untouch } from 'redux-form';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
	WebhookEndPointDetailsWithLabel,
	WebhookEventsList
} from 'services/api/developerPortal/developerPortalServiceTypes';
import {
	getWebHookModalValues,
	getEventsList
} from 'store/developerPortal/selectors';
import {
	Row,
	Col,
	Input,
	Select,
	TextArea,
	Tooltip,
	Icon
} from 'components/antd';
import { validate } from './validate';
import styles from './WebHookDetailsForm.module.scss';

const WebHookDetailsForm = () => {
	const dispatch = useDispatch();
	const formValues: WebhookEndPointDetailsWithLabel = useSelector(
		getFormValues(WEB_HOOK_END_POINT_FORM),
		shallowEqual
	);
	const { initialValues } = useSelector(getWebHookModalValues);

	const eventsList = useSelector(getEventsList);

	const updatedEventsList = eventsList.map(list => ({
		...list,
		disabled:
			initialValues?.events &&
			initialValues.events.find(eventName => eventName === list.key)
				? false
				: list.disabled
	}));

	const onAuthenticationTypeChange = (newValue: string) => {
		const resetFields = [
			WebhookEndPointDetailsFields.BASIC_AUTH_USER_NAME,
			WebhookEndPointDetailsFields.BASIC_AUTH_PASSWORD,
			WebhookEndPointDetailsFields.AUTH_KEY,
			WebhookEndPointDetailsFields.AUTH_VALUE,
			WebhookEndPointDetailsFields.ADD_TO,
			WebhookEndPointDetailsFields.BEARER_TOKEN
		];
		resetFields.forEach(field => {
			dispatch(change(WEB_HOOK_END_POINT_FORM, field, ''));
			dispatch(untouch(WEB_HOOK_END_POINT_FORM, field));
		});
		if (
			newValue ===
			initialValues?.[WebhookEndPointDetailsFields.AUTHENTICATION_TYPE]
		) {
			switch (newValue) {
				case AuthTypeList.BASIC_AUTH: {
					dispatch(
						resetSection(
							WEB_HOOK_END_POINT_FORM,
							WebhookEndPointDetailsFields.BASIC_AUTH_USER_NAME,
							WebhookEndPointDetailsFields.BASIC_AUTH_PASSWORD
						)
					);

					break;
				}
				case AuthTypeList.AUTH_KEY: {
					dispatch(
						resetSection(
							WEB_HOOK_END_POINT_FORM,
							WebhookEndPointDetailsFields.AUTH_KEY,
							WebhookEndPointDetailsFields.AUTH_VALUE,
							WebhookEndPointDetailsFields.ADD_TO
						)
					);

					break;
				}
				case AuthTypeList.BEARER_TOKEN: {
					dispatch(
						resetSection(
							WEB_HOOK_END_POINT_FORM,
							WebhookEndPointDetailsFields.BEARER_TOKEN
						)
					);

					break;
				}
			}
		}
	};
	return (
		<ReduxForm
			form={WEB_HOOK_END_POINT_FORM}
			initialValues={initialValues}
			validate={validate}
		>
			<Row>
				<Col xs={6}>
					<Input.ReduxFormItem
						name={WebhookEndPointDetailsFields.URL}
						type="url"
						label={
							<>
								URL{' '}
								<Tooltip
									title={'The URL must have base url, port #, resource path'}
									placement="right"
									trigger="hover"
									getPopupContainer={() => document.body}
								>
									<Icon type="info-circle" color="primary" />
								</Tooltip>
							</>
						}
						required
						help
					/>
				</Col>
				<Col xs={6}>
					<Select.ReduxFormItem
						name={WebhookEndPointDetailsFields.DATA_FORMAT}
						label="Data Format"
						required
					>
						<Select.Option value="application/JSON">
							application/JSON
						</Select.Option>
					</Select.ReduxFormItem>
				</Col>
			</Row>
			<Row>
				<Col xs={6}>
					<Select.ReduxFormItem
						name={WebhookEndPointDetailsFields.EVENTS}
						label={
							<>
								Events{' '}
								<Tooltip
									title={
										'Lists all outbound API selected for subscription (except Get port job API)'
									}
									placement="right"
									trigger="hover"
									getPopupContainer={() => document.body}
								>
									<Icon type="info-circle" color="primary" />
								</Tooltip>
							</>
						}
						required
						mode="multiple"
						getPopupContainer={() => document.body}
						dropdownClassName={styles.eventsSelect}
						labelInValue
					>
						{updatedEventsList.map((event: WebhookEventsList) => (
							<Select.Option
								key={event.key}
								value={event.key}
								disabled={event.disabled}
							>
								{event.label}
							</Select.Option>
						))}
					</Select.ReduxFormItem>
				</Col>
				<Col xs={6}>
					<Select.ReduxFormItem
						name={WebhookEndPointDetailsFields.AUTHENTICATION_TYPE}
						label="Authentication Type"
						required
						getPopupContainer={() => document.body}
						onSelect={onAuthenticationTypeChange}
					>
						<Select.Option value={AuthTypeList.NO_AUTH}>No Auth</Select.Option>
						<Select.Option value={AuthTypeList.BASIC_AUTH}>
							Basic Auth
						</Select.Option>
						<Select.Option value={AuthTypeList.AUTH_KEY}>
							Auth Key
						</Select.Option>
						<Select.Option value={AuthTypeList.BEARER_TOKEN}>
							Bearer Token
						</Select.Option>
					</Select.ReduxFormItem>
				</Col>
			</Row>
			{formValues?.[WebhookEndPointDetailsFields.AUTHENTICATION_TYPE] ===
				AuthTypeList.BASIC_AUTH && (
				<Row>
					<Col xs={6}>
						<Input.ReduxFormItem
							name={WebhookEndPointDetailsFields.BASIC_AUTH_USER_NAME}
							label="User name"
							maxLength={30}
							required
						/>
					</Col>
					<Col xs={6}>
						<Input.ReduxFormItem
							name={WebhookEndPointDetailsFields.BASIC_AUTH_PASSWORD}
							label="Password"
							type="password"
							minLength={8}
							required
						/>
					</Col>
				</Row>
			)}
			{formValues?.[WebhookEndPointDetailsFields.AUTHENTICATION_TYPE] ===
				AuthTypeList.AUTH_KEY && (
				<>
					<Row>
						<Col xs={6}>
							<Input.ReduxFormItem
								name={WebhookEndPointDetailsFields.AUTH_KEY}
								label="Key"
								required
							/>
						</Col>
						<Col xs={6}>
							<Input.ReduxFormItem
								name={WebhookEndPointDetailsFields.AUTH_VALUE}
								label="Value"
								type="password"
								required
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={6}>
							<Select.ReduxFormItem
								name={WebhookEndPointDetailsFields.ADD_TO}
								label="Add to"
								required
								getPopupContainer={() => document.body}
							>
								<Select.Option value="header">Header</Select.Option>
								<Select.Option value="queryParams">Query Params</Select.Option>
							</Select.ReduxFormItem>
						</Col>
					</Row>
				</>
			)}
			{formValues?.[WebhookEndPointDetailsFields.AUTHENTICATION_TYPE] ===
				AuthTypeList.BEARER_TOKEN && (
				<Row>
					<Col xs={12}>
						<TextArea.ReduxFormItem
							label="Token"
							name={WebhookEndPointDetailsFields.BEARER_TOKEN}
							required
						/>
					</Col>
				</Row>
			)}
		</ReduxForm>
	);
};

export default WebHookDetailsForm;
