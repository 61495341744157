import { Action, Failure } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	all,
	call,
	fork,
	put,
	takeMaybe,
	takeLatest,
	select
} from 'redux-saga/effects';
import {
	retrievePortCall,
	retrievePortCallAsync
} from 'store/portcalls/actions';
import {
	retrievePortJobForCycle,
	retrievePortJobForCycleAsync,
	appointToPortJobAsync,
	confirmPortJobAsync,
	acceptPortJobAsync,
	cancelPortJobAsync,
	uncancelPortJobAsync,
	changeCustodyRoleAsync,
	deletePortJobAsync,
	deletePortJobOperationAsync,
	retrieveAppointDataAsync,
	retrievePortJobCustodyRolesAsync,
	retrieveExtendedMessagingDataAsync,
	togglePortJobStatusAsync,
	toggleOperationsStatusAsync
} from 'store/portJobs/actions';
import {
	retrieveEntityPermissionsForPortCallAsync,
	retrieveEntityPermissionsForPortCall
} from 'store/permissions/actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import {
	isNotFoundError,
	isConcurrencyError
} from 'services/api/apiErrorUtils';
import { showFailOutdatedNotification } from 'utils/sagaHelpers/sagaUtils';
import { safeRedirectToPortCallOverview } from './portJobUtilsSagas';
import { retrievePortJobAsync } from '../actions/retrievePortJob';
import { retrieveFinanceDetails } from 'store/finance/actions';
import { RetrieveJobParams } from 'services/api/portJobs/portJobsServiceTypes';
import {
	EntityPermissionType,
	EntityPermissionRequest
} from 'services/api/permissions/permissionsServiceTypes';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';
import { saveVesselProgrammeAsync } from 'store/vesselProgramme/actions';
import { updatePortCallEventAsync } from 'store/portCallOperations/actions';

function* refetchPortJob(fetchOperations?: boolean): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const payload = {
		portCallId,
		jobCode,
		isSilent: true
	};
	yield put(
		retrievePortJobForCycle(
			fetchOperations
				? {
						...payload,
						expand: ExpandPortJobType.OPERATIONS
				  }
				: payload
		)
	);
}

function* refetchAll(): SagaIterator {
	yield all([
		fork(refetchPortCall),
		fork(refetchPortJob),
		// refetch operations
		fork(refetchPortJob, true)
	]);
}

function* refetchEntityPermissions(): SagaIterator {
	const jobCode = yield select(getActivePortJobCode);
	yield put(
		retrieveEntityPermissionsForPortCall({
			entityKey: jobCode,
			entityType: EntityPermissionType.PORTJOB
		})
	);
}

function* refetchFinanceDetails(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	yield put(retrieveFinanceDetails({ portCallId }));
}

function* handleEntityPermissionNotFoundWorker(
	action: Action<Failure<EntityPermissionRequest, AxiosGeneralError>>
): SagaIterator {
	if (isNotFoundError(action.payload.error)) {
		if (action.payload.params.entityType !== EntityPermissionType.OPERATION) {
			return; // handler only
		}
		yield call(refetchPortJob, true);
		yield call(showFailOutdatedNotification);
		return;
	}
}

function* handleFailedActionsWorker({
	payload,
	type
}: Action<Failure<RetrieveJobParams, Error>>): SagaIterator {
	const { params, error } = payload;
	if (isConcurrencyError(error)) {
		yield call(showFailOutdatedNotification);
		yield call(refetchAll);
		return;
	}
	if (isNotFoundError(error)) {
		if (
			[
				retrievePortJobAsync.failed.type,
				retrievePortJobForCycleAsync.failed.type,
				retrieveAppointDataAsync.failed.type,
				retrievePortJobCustodyRolesAsync.failed.type
			].includes(type)
		) {
			yield call(refetchPortCall);
			yield takeMaybe(retrievePortCallAsync.done);
			const portCallId = yield select(getActivePortCallId);
			yield call(safeRedirectToPortCallOverview, portCallId);
			// when the job tab is opened and job is not there anymore
			if (
				type === retrievePortJobForCycleAsync.failed.type ||
				(type !== retrievePortJobAsync.failed.type && !params.skipWarn)
			) {
				yield call(showFailOutdatedNotification);
			}
		} else {
			// inform for deleting operations
			if (
				[
					deletePortJobOperationAsync.failed.type,
					deletePortJobAsync.failed.type
				].includes(type)
			) {
				yield call(showFailOutdatedNotification);
			}

			yield call(refetchAll);
		}
	}
}

function* handleSucceedActionsWorker(): SagaIterator {
	yield call(refetchPortCall);
}

function* handleCancelledStatusChange(): SagaIterator {
	yield call(refetchAll);
	yield call(refetchEntityPermissions);
	yield call(refetchFinanceDetails);
}

function* handleJobAppointmentWorker() {
	yield call(handleSucceedActionsWorker);
	yield call(refetchPortJob, true);
	yield put(
		retrieveEntityPermissionsForPortCall({
			entityType: EntityPermissionType.PORTJOB,
			entityKey: yield select(getActivePortJobCode)
		})
	);
}
export function* refetchPortCall(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	if (!portCallId) {
		return;
	}
	yield put(
		retrievePortCall({
			id: portCallId,
			expand: 'PortJobsBasic',
			isSilent: true
		})
	);
}

export default function* watcher(): SagaIterator {
	yield all([
		takeLatest(
			[
				appointToPortJobAsync.failed,
				confirmPortJobAsync.failed,
				acceptPortJobAsync.failed,
				cancelPortJobAsync.failed,
				uncancelPortJobAsync.failed,
				changeCustodyRoleAsync.failed,
				deletePortJobAsync.failed,
				deletePortJobOperationAsync.failed,
				retrievePortJobForCycleAsync.failed,
				retrieveAppointDataAsync.failed,
				retrievePortJobCustodyRolesAsync.failed,
				retrievePortJobAsync.failed,
				retrieveExtendedMessagingDataAsync.failed,
				togglePortJobStatusAsync.failed
			],
			handleFailedActionsWorker
		),
		takeLatest(
			[
				confirmPortJobAsync.done,
				acceptPortJobAsync.done,
				deletePortJobOperationAsync.done,
				saveVesselProgrammeAsync.done,
				updatePortCallEventAsync.done,
				togglePortJobStatusAsync.done
			],
			handleSucceedActionsWorker
		),
		takeLatest(
			[appointToPortJobAsync.done, toggleOperationsStatusAsync.done],
			handleJobAppointmentWorker
		),
		takeLatest(
			retrieveEntityPermissionsForPortCallAsync.failed,
			handleEntityPermissionNotFoundWorker
		),
		takeLatest(
			[
				cancelPortJobAsync.done,
				uncancelPortJobAsync.done,
				changeCustodyRoleAsync.done
			],
			handleCancelledStatusChange
		)
	]);
}
