import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import actionCreator from '../documentsActionCreator';

const POLL_DOWNLOAD_URL = 'POLL_DOWNLOAD_URL';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const pollDownloadUrlAsync = actionCreator.async<
	any,
	RetrieveDownloadDocumentResponse['downloadUrl'],
	Error
>(POLL_DOWNLOAD_URL, {
	failed: {
		description: 'Error: Download failed. Please try again'
	}
});

export const pollDownloadUrl = actionCreator<any>(POLL_DOWNLOAD_URL);
