import { Action } from 'typescript-fsa';
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { get } from 'lodash';

import { WizardFormProps } from 'store/wizardForm/wizardFormState';

import { getContextualizationFormValues } from 'store/form/operationsContextualization/selectors';
import { CopyFromPreviousPageRequest } from '../operationsContextualizationTypes';
import { copyFromPreviousPageAsync } from '../actions/copyFromPreviousPage';
import {
	CONTEXTUALIZATION_FORM_ID,
	FormData,
	FormPageData
} from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import { getWizardByIdExecutor } from 'store/wizardForm/sagas';
import { SagaIterator } from 'redux-saga';

const async = copyFromPreviousPageAsync;

export const worker = function*(
	action: Action<CopyFromPreviousPageRequest>
): SagaIterator {
	const activeWizardForm: WizardFormProps = yield call(
		getWizardByIdExecutor,
		CONTEXTUALIZATION_FORM_ID
	);

	const activePageNumber: number = get(
		action.payload,
		'activePageIndex',
		activeWizardForm.activePage
	);
	const activeIndex = Math.max(0, activePageNumber - 1);
	const params: CopyFromPreviousPageRequest = { activePageIndex: activeIndex };

	yield put(async.started(params));
	try {
		const { pages }: FormData = yield select(getContextualizationFormValues);

		const previousIndex: number = Math.max(0, activeIndex - 1);
		const previousValues: FormPageData = pages[previousIndex];

		yield put(
			async.done({
				result: previousValues,
				params,
				response: null
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params
			})
		);
	}
};

const watcher = function*() {
	yield takeLatest(async.type, worker);
};

export default watcher;
