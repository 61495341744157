import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { AddTimeSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import {
	addTimeSavings,
	addTimeSavingsAsync,
	retrieveTimeSavings
} from '../actions';
import { getPreparedAddTimeSavingsData } from '../savingsSelectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import { TIME_SAVINGS_MODAL } from 'sections/PortCall/Savings/SavingsGrid/Constants';

const apiCall = Api.Savings.addTimeSavings;

export function* addTimeSavingsExecutor(
	actionParams: AddTimeSavingsRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(addTimeSavingsAsync.started(actionParams));
	try {
		const formData = yield select(getPreparedAddTimeSavingsData);
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		const request: AddTimeSavingsRequest = {
			portCallId,
			jobCode,
			data: { portJobCode: jobCode, ...formData }
		};
		const response = yield call(api, request);

		yield put(
			addTimeSavingsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(retrieveTimeSavings({ portCallId, jobCode }));
	} catch (error) {
		yield put(
			addTimeSavingsAsync.failed({
				error,
				params: actionParams
			})
		);
	} finally {
		yield put(closeModal(TIME_SAVINGS_MODAL));
	}
}

export function* worker({
	payload
}: Action<AddTimeSavingsRequest>): SagaIterator {
	yield call(addTimeSavingsExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(addTimeSavings.type, worker);
}
