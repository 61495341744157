import { Node, Descendant, Text, Element } from 'slate';
import { jsx } from 'slate-hyperscript';
import { flatten, isArray, isEmpty, merge } from 'lodash';
import {
	ElementType,
	TextType,
	RichTextValue,
	NodeType
} from '../../RichTextTypes';
import { LEAF_TYPE_MAP, INLINE_NODE, NEW_LINE } from '../../RichTextConstants';
import { getNodeDataAttributes } from './util';
import { normalizeHtml } from '../html-normalizer/normalize';

export const deserialize = (
	html = '',
	// `shouldNormalize` is needed for testing purposes
	shouldNormalize = true
): RichTextValue => {
	if (shouldNormalize) {
		html = normalizeHtml(html);
	}
	const parsed = new DOMParser().parseFromString(html, 'text/html');
	// COMPAT: in IE 11 body is null if html is an empty string
	const body = parsed.body || window.document.createElement('body');
	let nodes = deserializeElements(body, undefined, shouldNormalize);

	if (shouldNormalize) {
		nodes = normalizeNodes(nodes);
	}
	return nodes;
};

// @see https://docs.slatejs.org/concepts/10-normalizing#built-in-constraints
const normalizeNodes = (nodes: RichTextValue) => {
	if (nodes.length === 1) {
		return nodes;
	}
	let memo: Element | undefined;
	return nodes.reduce<RichTextValue>((acc, node, index) => {
		// Block nodes can only contain other blocks, or inline and text nodes
		if (!Text.isText(node) && !INLINE_NODE[node.type]) {
			if (memo) {
				memo = undefined;
			}
			acc.push(node);
			return acc;
		}

		// initialize text nodes element container
		if (!memo) {
			memo = {
				children: []
			};
			acc.push(memo);
		}

		memo.children.push(node);

		// revert if there were only text/inline nodes to not produce redundant nesting
		if (nodes.length === index + 1) {
			if (acc.length === 1) {
				acc = memo.children;
			}
		}
		return acc;
	}, []);
};

const createText = (
	node: Node | Node[] | string[] | string | null,
	attributes: object
) => {
	return jsx('text', attributes, node);
};

const deserializeElements = (
	el: Document | ChildNode,
	next?: object,
	// `shouldNormalize` is needed for testing purposes
	shouldNormalize = true
): RichTextValue => {
	const nodes: RichTextValue = [];
	const childNodes = flatten(Array.from(el.childNodes));
	childNodes.forEach(childNode => {
		const node = deserializeElement(childNode, next, shouldNormalize);
		if (!node) {
			return;
		}
		const value = isArray(node) ? node : [node];
		nodes.push(...value);
	});
	return nodes;
};

const deserializeElement = (
	el: ChildNode,
	next?: object,
	// `shouldNormalize` is needed for testing purposes
	shouldNormalize = true
): Node | Descendant[] | null => {
	const { nodeName } = el;
	const attributes = getNodeDataAttributes(el);

	const lastNodeAttributes = !isEmpty(next)
		? merge({}, attributes, next)
		: attributes;

	if (el.nodeType === NodeType.TEXT_NDOE) {
		return createText(el.textContent, lastNodeAttributes);
	} else if (el.nodeType !== NodeType.ELEMENT_NODE) {
		return null;
	} else if (ElementType[nodeName] === ElementType.BR) {
		return createText(NEW_LINE, lastNodeAttributes);
	}

	const text = TextType[nodeName];
	const element = ElementType[nodeName];

	let children: RichTextValue = [];
	if (text) {
		const format = LEAF_TYPE_MAP[text];

		next = next || {};
		if (format) {
			next = {
				...next,
				[format]: true
			};
		}
		if (!isEmpty(attributes.data?.attrs)) {
			next = merge({}, next, attributes);
		}
	}
	children = deserializeElements(el, next);

	if (element) {
		if (shouldNormalize) {
			children = normalizeNodes(children);
		}

		// All Element nodes must contain at least one Text descendant
		if (!children.length) {
			children = [createText('', merge(getNodeDataAttributes(), next))];
		}
		return jsx('element', { ...attributes, type: element }, children);
	}

	if (text) {
		// All Element nodes must contain at least one Text descendant
		if (!children.length) {
			children = [createText('', { ...lastNodeAttributes, ...next })];
		}
	}

	return children;
};
