import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SettingsButton } from 'components';
import { PermissionsCheckMode, PermissionWrapper } from 'components/Permission';
import SettingsButtonPopoverContent from './SettingsButtonPopoverContent';
import {
	EntityPermissionComposeId,
	EntityPermissionType,
	EntityPermission,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { getIsEntityPermissionLoading } from 'store/permissions/selectors/entityPermissionSelectors';
import { AppState } from 'store-types';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface SettingsButtonWrapperProps extends EntityPermissionComposeId {
	content: (permissions: EntityPermission) => React.ReactNode;
	disabled?: boolean;
	skipIsLoading?: boolean;
	tooltipPlacement?: TooltipProps['placement'];
	visible?: boolean;
	handleVisibleChange?: (visible: boolean) => void;
}
const SettingsButtonWrapper: FC<SettingsButtonWrapperProps> = ({
	entityType,
	entityKey,
	disabled,
	skipIsLoading,
	tooltipPlacement = 'bottom',
	visible,
	handleVisibleChange,
	...props
}) => {
	const [permissionsCheckMode, permissions] = useMemo(() => {
		if (entityType === EntityPermissionType.PORTJOB) {
			return [
				PermissionsCheckMode.OR,
				[PermissionCode.MANAGE_PORTCALL, PermissionCode.APPOINT_AGENT]
			];
		}
		return [PermissionsCheckMode.AND, [PermissionCode.MANAGE_PORTCALL]];
	}, [entityType]);
	const isLoading = useSelector((state: AppState) =>
		getIsEntityPermissionLoading(state, { entityType, entityKey })
	);

	return (
		<>
			{/** <div /> wrapper for `SettingsButtonWrapper` since it produces <menu /> element on the same level that causes flex children to shift in IE  */}
			<div>
				<PermissionWrapper
					tooltip={{
						placement: tooltipPlacement
					}}
					permissionCode={permissions}
					permissionsCheckMode={permissionsCheckMode}
				>
					{hasPermission => {
						if (!disabled && hasPermission) {
							return (
								<SettingsButton
									content={
										<SettingsButtonPopoverContent
											entityKey={entityKey}
											entityType={entityType}
											{...props}
										/>
									}
									isLoading={skipIsLoading ? false : isLoading}
									visible={visible}
									handleVisibleChange={handleVisibleChange}
								/>
							);
						}
						return <SettingsButton disabled />;
					}}
				</PermissionWrapper>
			</div>
		</>
	);
};

export default SettingsButtonWrapper;
