import { GroupTypeCodes, Group } from 'services/api/groups/groupsServiceTypes';

export interface CreateGroupFormData {
	companyId: string;
	groupType: GroupTypeCodes;
	name: string;
}

export enum GroupsModalsName {
	ADD_GROUP = 'add-group',
	DELETE_GROUP = 'delete-group'
}

export type DeleteGroupModalValue = Group;

export const CREATE_GROUP_FORM = 'createGroup';
