import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, take, takeLatest, select, call } from 'redux-saga/effects';
import {
	retrieveEditPortJobDataAsync,
	RetrieveEditPortJobActionPayload,
	retrieveEditPortJobData
} from 'store/portJobs/actions/retrieveEditPortJobData';
import {
	getPortJobByCode,
	getPortJobFullSummary
} from 'store/portJobs/portJobsSelectors';
import { setActivePortCallId, retrievePortCallsStatic } from 'store/portcalls';
import {
	setActivePortJobCode,
	retrievePortJobSummary,
	retrievePortJobSummaryAsync,
	retrievePortJobMetadataAsync,
	retrievePortJobForCycle,
	retrievePortJobForCycleAsync,
	retrievePortJobMetadata
} from 'store/portJobs/actions';
import { addCompaniesDraft, resetDrafts } from 'store/drafts/actions';
import {
	parsePortJobIntoForm,
	getPortJobAgencyTypes,
	getPortJobAgencyRoles
} from 'store/portJobs/selectors';
import { initialize } from 'redux-form';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { showFailOutdatedNotification } from 'utils/sagaHelpers/sagaUtils';
import { changeRoute } from 'store/route/actions';
import { safeRedirectToPortCallOverview } from '../portJobUtilsSagas';
import { retrievePortCallsStaticAsync } from 'store/portcalls/actions';
import { takeAllDoneOrFailed, areAllActionsSuccess } from 'store/utils';

export function* worker({
	payload
}: Action<RetrieveEditPortJobActionPayload>): SagaIterator {
	const { portCallId, portJobCode } = payload;
	yield put(retrieveEditPortJobDataAsync.started(payload));

	try {
		yield put(setActivePortCallId(portCallId));
		yield put(setActivePortJobCode(portJobCode));
		// We have to get summary before portJob data, because we use this data in scope of processing portJob data
		yield put(
			retrievePortJobSummary({
				portCallId,
				jobCode: portJobCode,
				isSilent: true
			})
		);
		const summaryResult = yield take([
			retrievePortJobSummaryAsync.done,
			retrievePortJobSummaryAsync.failed
		]);
		if (summaryResult.error) {
			// summary was not retrieved we go back to the overview
			yield call(safeRedirectToPortCallOverview, portCallId);
			yield take(changeRoute);
			yield call(showFailOutdatedNotification);
			throw new Error('Port Job Summary retrieving has end by error');
		}

		yield put(retrievePortJobForCycle({ portCallId, jobCode: portJobCode }));
		yield put(retrievePortCallsStatic());
		yield put(retrievePortJobMetadata({ portCallId, portJobCode }));
		const results: Array<Action<object>> = yield call(
			takeAllDoneOrFailed,
			retrievePortJobForCycleAsync,
			retrievePortCallsStaticAsync,
			retrievePortJobMetadataAsync
		);
		if (!areAllActionsSuccess(results)) {
			// one from actions has failed
			throw new Error('Port Job information retrieving has end by error');
		}
		const [portJobData] = results;
		const portJob = yield select(getPortJobByCode, portJobCode);
		const portJobSummary = yield select(getPortJobFullSummary);
		const agentTypes = yield select(getPortJobAgencyTypes);
		const agentRoles = yield select(getPortJobAgencyRoles);
		const editPortJobFormData = parsePortJobIntoForm(
			portJob,
			portJobSummary,
			agentTypes,
			agentRoles
		);

		yield put(initialize('portJob', editPortJobFormData));

		const { portJobCompanies = [] } = portJobData.payload.result as PortJob;
		yield put(resetDrafts());
		yield put(addCompaniesDraft(portJobCompanies));
		yield put(
			retrieveEditPortJobDataAsync.done({
				params: payload,
				result: undefined,
				response: null
			})
		);
	} catch (error) {
		yield put(
			retrieveEditPortJobDataAsync.failed({
				error,
				params: payload
			})
		);
	}
}

export default function*() {
	yield takeLatest(retrieveEditPortJobData.type, worker);
}
