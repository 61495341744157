/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { Log } from 'oidc-client';
import { userManager } from 'services/userManager';

// Actions
import { clearAuth, setAuth } from 'store/auth/actions';
// Utils
import { OidcUser } from 'store/auth/authTypes';

interface OidcProviderProps {
	setAuth: typeof setAuth;
	clearAuth: typeof clearAuth;
}

/**
 * Provider for tracking user's authentication progress
 */
class OidcProvider extends React.Component<OidcProviderProps> {
	constructor(props: OidcProviderProps) {
		super(props);

		// if console for IE support
		if (console) {
			Log.logger = console;
			// Uncomment for debugging
			// Log.level = Log.DEBUG;
		}
		this.bindEvents();
	}

	componentWillUnmount() {
		this.unbindEvents();
	}

	bindEvents = () => {
		userManager.events.addUserLoaded(this.onUserLoaded);
		userManager.events.addUserUnloaded(this.onUserUnloaded);
		userManager.events.addSilentRenewError(this.onSilentRenewError);
		userManager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
		userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
		userManager.events.addUserSignedOut(this.onUserSignedOut);
	};

	unbindEvents = () => {
		userManager.events.removeUserLoaded(this.onUserLoaded);
		userManager.events.removeUserUnloaded(this.onUserUnloaded);
		userManager.events.removeSilentRenewError(this.onSilentRenewError);
		userManager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
		userManager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
		userManager.events.removeUserSignedOut(this.onUserSignedOut);
	};

	/**
	 * Raised when a user session has been established (or re-established)
	 */
	onUserLoaded = (user: OidcUser) => {
		console.warn('provider: onUserLoaded');
		this.props.setAuth(user);
	};

	/**
	 * Raised when a user session has been terminated
	 */
	onUserUnloaded = () => {
		console.warn('provider: onUserUnloaded');
		this.props.clearAuth();
	};

	/**
	 * Raised when the automatic silent renew has failed
	 */
	onSilentRenewError = () => {
		console.warn('provider: onSilentRenewError');
		this.props.clearAuth();
	};

	/**
	 *  Raised after the access token has expired
	 */
	onAccessTokenExpired = () => {
		console.warn('provider: onAccessTokenExpired');
		this.props.clearAuth();
	};

	/**
	 *  Raised prior to the access token expiring, triggered before silent renew
	 */
	onAccessTokenExpiring = () => {
		console.warn('provider: onAccessTokenExpiring');
	};

	/**
	 * Raised when the user's signin status at the OP has changed
	 */
	onUserSignedOut = () => {
		console.warn('provider: onUserSignedOut');
		this.props.clearAuth();
	};

	render() {
		return <>{this.props.children}</>;
	}
}

export default connect(null, {
	setAuth,
	clearAuth
})(OidcProvider);
