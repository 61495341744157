import { FINANCE_CONTAINER_ID } from 'sections/PortCall/Finance/FinanceConstants';
import { includes, intersection } from 'lodash';
import {
	PortJobService,
	ServiceType,
	DaStatus,
	MultilevelApprovalSettings,
	FinanceMetadataAction,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { menuItemMap } from './Services/ColumnComponents/ServiceSettingsColumn/ServiceSettingsButtonConsts';
import { daInStatusMap, FinanceDAStatusLabel } from 'store/finance/constants';
import { UserType } from 'services/api/users/userServiceTypes';
import { Metadata } from 'app-types';

export const hasAction = <Action = FinanceMetadataAction>(
	actionsArr: Action[],
	searchingActions: Action[]
) => {
	return intersection(actionsArr, searchingActions).length > 0;
};

export const getIsServiceSettingsIconVisible = (service: PortJobService) => {
	const actionCodes = service.metadata.actions.map(action => action.actionCode);
	return hasAction(actionCodes, Object.keys(menuItemMap));
};

export const isShowSettingsColumn = (services: PortJobService[] | undefined) =>
	(services || []).some(service => getIsServiceSettingsIconVisible(service));

export interface ColumnEntityProps<T> extends ColumnProps<T> {
	metaDataKey: string;
}

export const getIsCreditService = (service: PortJobService): boolean =>
	service?.isCredit;

export const getIsDirectBillService = (service?: PortJobService): boolean =>
	!!(service && service.serviceTypeCode === ServiceType.DIRECT_BILL);

export const getLpaSubmissionPendingLabel = (userType: UserType) => {
	if (userType === UserType.LPA) {
		return FinanceDAStatusLabel.SUBMISSION_PENDING;
	}
	return FinanceDAStatusLabel.DRAFT;
};

export const getHubSubmissionPendingLabel = (userType: UserType) => {
	if (userType === UserType.LPA) {
		return FinanceDAStatusLabel.VERIFICATION_PENDING;
	}
	return FinanceDAStatusLabel.SUBMISSION_PENDING;
};

export const getDaInQueriedLabel = (
	userType: UserType,
	queriedToHub: boolean,
	queriedToLPA: boolean
) => {
	if (userType === UserType.LPA) {
		if (queriedToHub && !queriedToLPA) {
			return FinanceDAStatusLabel.VERIFIED;
		} else {
			return FinanceDAStatusLabel.QUERIED;
		}
	}
	if (userType === UserType.HUB) {
		if (queriedToHub && !queriedToLPA) {
			return FinanceDAStatusLabel.QUERIED_HUB;
		} else {
			return FinanceDAStatusLabel.QUERIED_LPA;
		}
	}
	return FinanceDAStatusLabel.QUERIED;
};

export const getDaOutQueriedLabel = (
	userType: UserType,
	queriedToHub: boolean,
	queriedToLPA: boolean
) => {
	if (userType === UserType.PRINCIPAL) {
		if (!queriedToHub && queriedToLPA) {
			return FinanceDAStatusLabel.PENDING;
		} else {
			return FinanceDAStatusLabel.QUERIED;
		}
	}
	if (userType === UserType.HUB) {
		if (!queriedToHub && queriedToLPA) {
			return FinanceDAStatusLabel.QUERIED_LPA;
		} else {
			return FinanceDAStatusLabel.QUERIED_HUB;
		}
	}
	return FinanceDAStatusLabel.QUERIED;
};

export const getDaInStatus = (
	userType: UserType,
	daStatus: DaStatus,
	queriedToHub = false,
	queriedToLPA = false
) => {
	switch (daStatus) {
		case DaStatus.DRAFT:
		case DaStatus.SUBMITTED:
		case DaStatus.VERIFIED:
			return { label: daInStatusMap[daStatus] };
		case DaStatus.LPA_SUBMISSION_PENDING:
			return { label: getLpaSubmissionPendingLabel(userType) };
		case DaStatus.HUB_SUBMISSION_PENDING:
			return { label: getHubSubmissionPendingLabel(userType) };
		case DaStatus.QUERIED:
			return {
				label: getDaInQueriedLabel(userType, queriedToHub, queriedToLPA)
			};
		default:
			return { label: daStatus };
	}
};

export const getDaOutStatus = (
	userType: UserType,
	daStatus: DaStatus,
	queriedToHub = false,
	queriedToLPA = false,
	multilevelApprovalSettings?: MultilevelApprovalSettings,
	shouldShowApprovalLevel?: boolean
) => {
	const isHub = userType === UserType.HUB;
	switch (daStatus) {
		case DaStatus.APPROVED:
			return { label: FinanceDAStatusLabel.APPROVED };
		case DaStatus.DRAFT:
			return {
				label: isHub ? FinanceDAStatusLabel.DRAFT : FinanceDAStatusLabel.PENDING
			};
		case DaStatus.SUBMITTED:
			if (shouldShowApprovalLevel) {
				return {
					approvalLevel: multilevelApprovalSettings?.currentApprovalLevel,
					label: FinanceDAStatusLabel.APPROVAL_PENDING
				};
			}
			return { label: FinanceDAStatusLabel.APPROVAL_PENDING };
		case DaStatus.HUB_SUBMISSION_PENDING:
			return {
				label: isHub
					? FinanceDAStatusLabel.SUBMISSION_PENDING
					: FinanceDAStatusLabel.PENDING
			};
		case DaStatus.PRINCIPAL_SUBMISSION_PENDING:
			if (shouldShowApprovalLevel) {
				return {
					approvalLevel: multilevelApprovalSettings?.currentApprovalLevel,
					label: isHub
						? FinanceDAStatusLabel.APPROVAL_PENDING
						: FinanceDAStatusLabel.SUBMISSION_PENDING
				};
			}
			return {
				label: isHub
					? FinanceDAStatusLabel.APPROVAL_PENDING
					: FinanceDAStatusLabel.SUBMISSION_PENDING
			};
		case DaStatus.QUERIED:
			return {
				label: getDaOutQueriedLabel(userType, queriedToHub, queriedToLPA)
			};
		default:
			return { label: daStatus };
	}
};

export const editPropsIncludeKey = (
	metadata: Metadata<FinanceMetadataActionCode>,
	key: string | undefined
) => includes(metadata.propertiesToEdit, key);

export const getFinanceContainerOrDocumentBody = () =>
	document.getElementById(FINANCE_CONTAINER_ID) || document.body;
