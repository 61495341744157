import {
	DownloadEmlFileRequest,
	DownloadEmlFileResponse
} from 'services/api/messages/messagesServiceTypes';
import actionCreator from 'store/messages/messagesActionCreator';

const DOWNLOAD_EML_FILE = 'DOWNLOAD_EML_FILE';
export const downloadEmlFileAsync = actionCreator.async<
	DownloadEmlFileRequest,
	DownloadEmlFileResponse,
	Error
>(DOWNLOAD_EML_FILE);

export const downloadEmlFile = actionCreator<DownloadEmlFileRequest>(
	DOWNLOAD_EML_FILE
);
