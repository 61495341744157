import {
	VesselProgrammeState,
	ReducerRotationStep
} from '../vesselProgrammeState';
export const onSetActivePOIId = (
	state: VesselProgrammeState,
	activePOIId: ReducerRotationStep['id']
): VesselProgrammeState => ({
	...state,
	context: {
		...state.context,
		activePOIId
	}
});
