import { RetrieveServicesResponse } from 'services/api/services/servicesServiceTypes';
import actionCreator from '../servicesActionCreator';

const ACTION_NAME = 'RETRIEVE_SERVICES';

export const retrieveServicesAsync = actionCreator.async<
	null,
	RetrieveServicesResponse
>(ACTION_NAME);

export const retrieveServices = actionCreator(ACTION_NAME);
export const resetServices = actionCreator(`RESET_${ACTION_NAME}`);
