import {
	DaStatus,
	DaUnavailabilityReason,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';

export enum FinanceDAStatusLabel {
	DRAFT = 'Draft',
	PENDING = 'Pending',
	VERIFICATION_PENDING = 'Verification pending',
	APPROVAL_PENDING = 'Approval pending',
	APPROVED = 'Approved',
	VERIFIED = 'Verified',
	SUBMISSION_PENDING = 'Submission pending',
	QUERIED = 'Queried',
	QUERIED_LPA = 'Queried (LPA)',
	QUERIED_HUB = 'Queried (HUB)'
}

export const daInStatusMap: { [i: string]: FinanceDAStatusLabel } = {
	[DaStatus.DRAFT]: FinanceDAStatusLabel.DRAFT,
	[DaStatus.VERIFICATION_PENDING]: FinanceDAStatusLabel.VERIFICATION_PENDING,
	[DaStatus.SUBMITTED]: FinanceDAStatusLabel.VERIFICATION_PENDING,
	[DaStatus.VERIFIED]: FinanceDAStatusLabel.VERIFIED
};

export const daOutStatusMap: { [i: string]: FinanceDAStatusLabel } = {
	[DaStatus.DRAFT]: FinanceDAStatusLabel.DRAFT,
	[DaStatus.SUBMITTED]: FinanceDAStatusLabel.APPROVAL_PENDING,
	[DaStatus.APPROVAL_PENDING]: FinanceDAStatusLabel.APPROVAL_PENDING,
	[DaStatus.APPROVED]: FinanceDAStatusLabel.APPROVED
};

export enum FinanceServiceStatusLabel {
	PENDING = 'Pending',
	APPROVAL_PENDING = 'Approval pending',
	DRAFT = 'Draft',
	APPROVED = 'Approved',
	VERIFICATION_PENDING = 'Verification pending',
	QUERIED = 'Queried',
	QUERIED_LPA = 'Queried (LPA)',
	QUERIED_HUB = 'Queried (Hub)',
	UNKNOWN = 'Unknown'
}

export enum ServiceStatusTabType {
	LPA_QUERY = 'lpaQuery',
	PRINCIPAL_QUERY = 'principalQuery'
}

export enum FinanceCommentsTabType {
	LPA = 'Lpa',
	PRINCIPAL = 'Principal'
}

export const FAKE_COLUMN_KEY = 'fakeColumn';

export const availableServiceActions = [
	FinanceMetadataActionCode.VERIFY,
	FinanceMetadataActionCode.SUBMIT,
	FinanceMetadataActionCode.APPROVE,
	FinanceMetadataActionCode.QUERY_TO_LPA,
	FinanceMetadataActionCode.QUERY_TO_HUB,
	FinanceMetadataActionCode.RESOLVE_QUERY
];

export const FINANCE_REVISIONS_LABEL_ALL = 'All';

export enum FinanceRevisionsTab {
	HUB_IN = 'IN (FROM LPA)',
	HUB_OUT = 'OUT (TO PRINCIPAL)',
	IN = 'IN',
	OUT = 'OUT'
}

export const FINANCE_SUPPLEMENTAL_TMP_DA_ID = 'supplemental';
export const FINANCE_SUPPLEMENTAL_LABEL = 'Supplemental';

export const SERVICES_CONFIRMATION_ID = 'SERVICES_CONFIRMATION';

export const REOPEN_DASET_MODAL_ID = 'reopenDaSetModal';

export const BANK_ACCOUNT_CONFIRMATION_MODAL_ID = 'confirmBankAccountModal';

export const daUnavailabilityReasonMap = {
	[DaUnavailabilityReason.NO_PRINCIPAL_PERMISSION]: `This DA OUT cannot be selected because you are not permissioned for its approval`,
	[DaUnavailabilityReason.CURRENCY_NOT_VALID]: `This DA OUT cannot be selected because the DA IN for this service will be settled using a different currency`,
	[DaUnavailabilityReason.STATUS_NOT_VALUE_FOR_LPA]: `DA is currently being processed by another party and can't be selected`,
	[DaUnavailabilityReason.STATUS_NOT_VALUE_FOR_HUB]: `DA is currently being processed by another party and can't be selected`,
	[DaUnavailabilityReason.STATUS_NOT_VALUE_FOR_PRINCIPAL]: `Only DA OUT in the 'Approval pending' or 'Submission Pending' can be selected`
};

export enum FinanceDocumentType {
	SERVICES = 'services',
	DAS = 'das'
}

export enum PageType {
	ORIGINAL = 'original',
	UPDATED = 'annotated'
}
