import {
	RetrieveRefreshDirectBillRequest,
	RetrieveRefreshDirectBillResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const RETRIEVE_REFRESH_DIRECT_BILL = 'RETRIEVE_REFRESH_DIRECT_BILL';
export const retrieveRefreshDirectBillAsync = actionCreator.async<
	RetrieveRefreshDirectBillRequest,
	RetrieveRefreshDirectBillResponse,
	Error
>(RETRIEVE_REFRESH_DIRECT_BILL);

export const retrieveRefreshDirectBill = actionCreator<void>(
	RETRIEVE_REFRESH_DIRECT_BILL
);
