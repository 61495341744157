import React from 'react';
import { times } from 'lodash';

import { Button, Select } from 'components/antd';
import styles from '../DocumentViewer.module.scss';

import {
	PaginationState,
	DocumentViewerPaginationProps
} from '../DocumentViewerTypes';

export interface DocumentViewerAsidePaginationProps
	extends DocumentViewerPaginationProps {
	pagination?: PaginationState;
}

const DocumentAsideFooterPagination: React.SFC<DocumentViewerAsidePaginationProps> = ({
	total,
	activePage,
	activePageState,
	prevPage,
	nextPage,
	setActivePage,
	activePageFirst,
	activePageLast,
	pagination
}) => {
	const pages = times(total, page => page + 1);
	const controlsDisabled =
		pagination === PaginationState.ENABLED ? false : !activePageState.valid;
	return (
		<>
			<Button
				ghost
				key={activePage - 1}
				type="primary"
				onClick={prevPage}
				disabled={activePageFirst || controlsDisabled}
			>
				Previous Page
			</Button>
			<Select
				containerClassName={styles.pagesSelect}
				value={String(activePage)}
				onChange={page => setActivePage(Number(page))}
				disabled={controlsDisabled}
			>
				{pages.map(page => {
					return <Select.Option key={page}>{page}</Select.Option>;
				})}
			</Select>
			<Button
				ghost
				key={activePage + 1}
				type="primary"
				onClick={nextPage}
				disabled={activePageLast || controlsDisabled}
			>
				Next Page
			</Button>
		</>
	);
};

export default DocumentAsideFooterPagination;
