import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallsState } from 'store/portcalls/portCallsState';
import { Success } from 'typescript-fsa';
import {
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse
} from 'services/api/portCall/portCallServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

const doneReducer = (
	state: PortCallsState,
	{
		params,
		result
	}: Success<
		RetrievePortCallDuplicatesRequest,
		RetrievePortCallDuplicatesResponse
	>
): PortCallsState => ({
	...state,
	duplicates: {
		...state.duplicates,
		[params.portCallId]: result
	}
});

export const onRetrievePortCallDuplicates = makeFetchStatusReducers<
	PortCallsState,
	'fetchStatuses',
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse
>('fetchStatuses', 'duplicates', { doneReducer });

export const onResetPortCallDuplicates = (
	state: PortCallsState,
	{ portCallId }: RetrievePortCallDuplicatesRequest
): PortCallsState => ({
	...state,
	duplicates: {
		...state.duplicates,
		[portCallId]: []
	},
	fetchStatuses: {
		...state.fetchStatuses,
		duplicates: FetchStatus.IDLE
	}
});
