import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'antd/lib/table';
import { fundingRequestColumns } from './FundingRequestTableColumns';
import { getFinanceFundingFormValues } from 'store/form/financeFundings/selectors/financeFundingFormSelectors';
import { FundingRequest } from 'services/api/financeFundings/financeFundingsServiceTypes';

const FundingRequestTable = () => {
	const fundingRequestValues = useSelector(getFinanceFundingFormValues);
	const datasource = [fundingRequestValues];
	return (
		<Table
			pagination={false}
			showHeader
			rowKey={(funding: FundingRequest) => `funding-${funding.id}`}
			dataSource={datasource}
			columns={fundingRequestColumns}
		/>
	);
};

export default FundingRequestTable;
