import { RevertJobStatusRequest } from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const REVERT_JOB_STATUS = 'REVERT_JOB_STATUS';

export const revertJobStatusAsync = actionCreator.async<
	RevertJobStatusRequest,
	null,
	Error
>(REVERT_JOB_STATUS);

export const revertJobStatus = actionCreator(REVERT_JOB_STATUS);
