import * as React from 'react';

import AntCollapse, {
	CollapseProps as AntCollapseProps
} from 'antd/lib/collapse/Collapse';
import { Icon } from 'components/antd';
import { IconType } from 'components/antd/Icon/IconTypes';

import styles from './Collapse.module.scss';
import classNames from 'classnames';

export interface CollapseProps extends AntCollapseProps {
	type?: 'primary' | 'main' | 'filter';
	contentPadding?: boolean;
}

interface ExpandIconCong {
	[type: string]: {
		[isActive: string]: {
			icon: keyof IconType;
			label?: string;
		};
	};
}

const EXPAND_ICON_CONF: ExpandIconCong = {
	primary: {
		active: {
			icon: 'up',
			label: 'Collapse'
		},
		inActive: {
			icon: 'down',
			label: 'Expand'
		}
	},
	main: {
		active: {
			icon: 'minus-square'
		},
		inActive: {
			icon: 'plus-square'
		}
	},
	filter: {
		active: {
			icon: 'caret-down'
		},
		inActive: {
			icon: 'caret-up'
		}
	}
};

class Collapse extends React.Component<CollapseProps> {
	static Panel: typeof AntCollapse.Panel = AntCollapse.Panel;
	render() {
		const { type = 'main', contentPadding = true, ...props } = this.props;
		return (
			<AntCollapse
				{...props}
				className={classNames(
					styles.collapse,
					styles[type],
					this.props.className,
					{
						[styles.noContentPadding]: !contentPadding
					}
				)}
				expandIcon={panelProps => {
					const confByType = EXPAND_ICON_CONF[type];
					const conf = panelProps.isActive
						? confByType.active
						: confByType.inActive;

					return (
						<span className="ant-collapse-arrow">
							{conf.label}
							<Icon type={conf.icon} />
						</span>
					);
				}}
				expandIconPosition={type !== 'main' ? 'right' : 'left'}
			/>
		);
	}
}

export default Collapse;
