import {
	retrievePortCallCargoMeasurements,
	updateCargoMeasurementsAsync,
	updateCargoMeasurements,
	retrievePortCallCargoMeasurementsAsync
} from '../actions';
import Api from 'services/api';
import { UpdateCargoMeasurementsRequest } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { put, call, takeEvery, take } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';

function* updateCargoMeasurementWorker({
	payload
}: Action<UpdateCargoMeasurementsRequest>): SagaIterator {
	yield put(updateCargoMeasurementsAsync.started(payload));
	try {
		const response = yield call(
			Api.PortCallMeasurements.updateCargoMeasurements,
			payload
		);
		yield put(
			retrievePortCallCargoMeasurements({ portCallId: payload.portCallId })
		);
		const result: Action<never> = yield take([
			retrievePortCallCargoMeasurementsAsync.done,
			retrievePortCallCargoMeasurementsAsync.failed
		]);
		if (result.type === retrievePortCallCargoMeasurementsAsync.failed.type) {
			throw new Error('Retrieving cargo measurements after update has failed.');
		}
		yield put(
			updateCargoMeasurementsAsync.done({
				result: response.data.elements,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			updateCargoMeasurementsAsync.failed({
				error,
				params: payload
			})
		);
	}
}

export default function*() {
	yield takeEvery(updateCargoMeasurements, updateCargoMeasurementWorker);
}
