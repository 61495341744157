import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveCompanyVesselsAsync,
	retrieveCompanyVesselAsync,
	retrieveEditCompanyVesselDataAsync,
	setActiveVesselSearchResult,
	deleteCompanyVesselAsync,
	retrievePortCallCompanyVesselsAsync,
	updateCompanyVesselAsync,
	createCompanyVesselAsync,
	resetCompanyVessels,
	retrieveCompanyVesselByIdAsync,
	retrieveEditCompanyVesselByIdDataAsync
} from './actions';
import {
	onRetrieveCompanyVessels,
	onRetrieveCompanyVessel,
	onRetrieveCompanyVesselById,
	onRetrieveEditCompanyVesselData,
	onRetrieveEditCompanyVesselByIdData,
	onSetActiveVesselSearchResult,
	onDeleteCompanyVessel,
	onRetrievePortCallCompanyVessels,
	onUpdateCompanyVessel,
	onCreateCompanyVessel,
	onResetCompanyVessels
} from './reducers';
import { initialState } from './companyVesselsState';

export default reducerWithInitialState(initialState)
	.case(updateCompanyVesselAsync.started, onUpdateCompanyVessel.started)
	.case(updateCompanyVesselAsync.done, onUpdateCompanyVessel.done)
	.case(updateCompanyVesselAsync.failed, onUpdateCompanyVessel.failed)
	.case(createCompanyVesselAsync.started, onCreateCompanyVessel.started)
	.case(createCompanyVesselAsync.done, onCreateCompanyVessel.done)
	.case(createCompanyVesselAsync.failed, onCreateCompanyVessel.failed)
	.case(retrieveCompanyVesselsAsync.started, onRetrieveCompanyVessels.started)
	.case(retrieveCompanyVesselsAsync.done, onRetrieveCompanyVessels.done)
	.case(retrieveCompanyVesselsAsync.failed, onRetrieveCompanyVessels.failed)
	.case(resetCompanyVessels, onResetCompanyVessels)
	.case(retrieveCompanyVesselAsync.started, onRetrieveCompanyVessel.started)
	.case(retrieveCompanyVesselAsync.done, onRetrieveCompanyVessel.done)
	.case(retrieveCompanyVesselAsync.failed, onRetrieveCompanyVessel.failed)
	.case(
		retrieveEditCompanyVesselDataAsync.started,
		onRetrieveEditCompanyVesselData.started
	)
	.case(
		retrieveEditCompanyVesselDataAsync.done,
		onRetrieveEditCompanyVesselData.done
	)
	.case(
		retrieveEditCompanyVesselDataAsync.failed,
		onRetrieveEditCompanyVesselData.failed
	)
	.case(setActiveVesselSearchResult, onSetActiveVesselSearchResult)
	.case(deleteCompanyVesselAsync.started, onDeleteCompanyVessel.started)
	.case(deleteCompanyVesselAsync.done, onDeleteCompanyVessel.done)
	.case(deleteCompanyVesselAsync.failed, onDeleteCompanyVessel.failed)
	.case(
		retrievePortCallCompanyVesselsAsync.started,
		onRetrievePortCallCompanyVessels.started
	)
	.case(
		retrievePortCallCompanyVesselsAsync.done,
		onRetrievePortCallCompanyVessels.done
	)
	.case(
		retrievePortCallCompanyVesselsAsync.failed,
		onRetrievePortCallCompanyVessels.failed
	)
	.case(retrieveCompanyVesselByIdAsync.started, onRetrieveCompanyVesselById.started)
	.case(retrieveCompanyVesselByIdAsync.done, onRetrieveCompanyVesselById.done)
	.case(retrieveCompanyVesselByIdAsync.failed, onRetrieveCompanyVesselById.failed)
	.case(
		retrieveEditCompanyVesselByIdDataAsync.started,
		onRetrieveEditCompanyVesselByIdData.started
	)
	.case(
		retrieveEditCompanyVesselByIdDataAsync.done,
		onRetrieveEditCompanyVesselByIdData.done
	)
	.case(
		retrieveEditCompanyVesselByIdDataAsync.failed,
		onRetrieveEditCompanyVesselByIdData.failed
	);
