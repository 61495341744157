import React from 'react';
import { isEmpty } from 'lodash';
import styles from './AssignedJobsField.module.scss';
import { Select } from 'components/antd';
import { JobCodeTag } from 'components';
import { DocumentJob } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { TooltipProps } from 'antd/lib/tooltip';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';

export type AssignedJobOption = DocumentJob & {
	warn: boolean;
	placeholder?: string;
};

interface AssignedJobsFieldProps {
	name: string;
	options: AssignedJobOption[];
	tooltip?: TooltipProps;
	onOptionChange?: (id: string, condition: boolean) => void;
}

class AssignedJobsField extends React.Component<AssignedJobsFieldProps> {
	onRemoveOption = (jobCode: string) => {
		this.onChangeOption(jobCode, false);
	};
	onChangeOption = (jobCode: string, condition: boolean) => {
		const { options, onOptionChange } = this.props;
		const placeholder = options.find(item => item.code === jobCode)
			?.placeholder;
		if (onOptionChange && placeholder) {
			onOptionChange(placeholder, condition);
		}
	};
	onSelect = (jobCode: string) => {
		this.onChangeOption(jobCode, true);
	};

	render() {
		const { name, options, tooltip } = this.props;

		if (isEmpty(options)) {
			return null;
		}

		return (
			<MaybeTooltip show={!!tooltip} {...tooltip}>
				<Select.ReduxFormItem
					name={name}
					label="Assigned Job (s)"
					className={styles.root}
					dropdownClassName={styles.rootDropdown}
					mode="multiple"
					onSelect={this.onSelect}
					onDeselect={this.onRemoveOption}
					required
					disabled={!!tooltip}
				>
					{options.map(job => {
						return (
							<Select.Option key={job.code}>
								<JobCodeTag jobCode={job.code}>
									{job.code} | {job.companyName}
								</JobCodeTag>
							</Select.Option>
						);
					})}
				</Select.ReduxFormItem>
			</MaybeTooltip>
		);
	}
}

export default AssignedJobsField;
