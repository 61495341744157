import { Severity, AlertMetricCode } from 'app-constants';
import { ResultCollection, PagedRequest } from 'services/api/apiTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { FinanceStage, FinanceStatus } from '../finance/financeServiceTypes';
import { Alert, EntityCode } from 'app-types';

export enum JobSectionType {
	OPERATION_OVERVIEW = 'Operational overview',
	FINANCIAL_OVERVIEW = 'Financial overview'
}

export enum SummaryType {
	HUB_TASKS = 'HubTasks',
	HUB_ALERTS = 'HubAlerts',
	ACTIVE_JOBS = 'ActiveJobs'
}

export enum MetricType {
	PORT_JOB_STATUS = 'PortJobStatus',
	FUNDING_REQUEST_PENDING = 'FundingRequestPending',
	FUNDS_REQUESTED_FROM_PRINCIPAL = 'FundsRequestedFromPrincipal'
}

export interface SummaryJobMetric {
	code: AlertMetricCode;
	name: string;
	metricType: MetricType; // TODO: Have to be enum "PortJobStatus"
	portJobStatus: PortJobStatus;
	financeStage: FinanceStage;
	financeStatus: FinanceStatus;
	severity: Severity;
	totalAlertsCount?: number;
	overdueAlertsCount?: number;
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
}
// TODO: Think about generics here
export interface DashboardSection {
	name: string;
	code: string;
	count?: number;
	elements?: DashboardSection[];
}
export interface SummaryJobSection extends DashboardSection {
	code: JobSectionType;
	elements: SummaryJobMetric[];
}
export interface SummarySection extends DashboardSection {
	code: SummaryType;
	elements?: SummaryJobSection[];
}
export interface Summary {
	count: number;
	elements: SummarySection[];
}

export interface RetrieveDashboardPortCallsSummaryResponse extends Summary {}

// Dashboard sections
export interface JobSection {
	code: AvailableSections;
	name: string;
	count: number;
}
export type JobSections = ResultCollection<JobSection>;

// Dashboard PortCalls
export interface DashboardJob {
	code: string;
	status: PortJobStatus;
	financeStatus: FinanceStatus;
	performingAgentCompany: {
		id: string;
		name: string;
	};
	operations: string[];
	alertSeverity: string;
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
	alerts: Alert[];
	isOperationsDisabled: boolean;
	financeEnabled: boolean;
}

export interface DashboardPortCall {
	id: string;
	vessel: {
		id: string;
		name: string;
	};
	port: {
		id: string;
		code: string;
		name: string;
		timezone: string;
	};
	atbPlt?: string;
	atbUtc?: string;
	etaPlt: string;
	etaUtc: string;
	eospPlt?: string;
	eospUtc?: string;
	etbPlt: string;
	etbUtc: string;
	etsPlt: string;
	etsUtc: string;
	cospPlt?: string;
	cospUtc?: string;
	alerts: Alert[];
	jobs: DashboardJob[];
}

type DashboardPortCalls = ResultCollection<DashboardPortCall>;
export type DashboardPortCallsResponse = DashboardPortCalls;

interface RetrieveDashboardPortCallsPayload {
	statusCode: string;
}
export interface RetrieveDashboardPortCallsRequest
	extends RetrieveDashboardPortCallsPayload,
		PagedRequest {}

export enum AvailableSections {
	EN_ROUTE = 'EnRoute',
	ARRIVED = 'Arrived',
	ALONGSIDE = 'Alongside',
	SAILED = 'Sailed'
}

export const orderedSectionCodes = [
	AvailableSections.EN_ROUTE,
	AvailableSections.ARRIVED,
	AvailableSections.ALONGSIDE,
	AvailableSections.SAILED
];

export const jobSectionsColumnHeadingsMap = {
	[AvailableSections.EN_ROUTE]: ['ETA', 'ETS'],
	[AvailableSections.ARRIVED]: ['EOSP', 'ETS'],
	[AvailableSections.ALONGSIDE]: ['EOSP', 'ETS'],
	[AvailableSections.SAILED]: ['EOSP', 'COSP']
};

// Dashboard Tableau
// ---------------------

export type DashboardTableau = EntityCode;
export type RetrieveDashboardTableauResponse = DashboardTableau[];
export interface RetrieveDashboardTableauInfoRequest {
	mainPrincipalId: string;
	tableauCode: string;
}

export interface DashboardTableauInfo {
	url: string;
}
