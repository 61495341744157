import actionCreator from '../actionCreator';
import { AcknowledgeVPRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'ACKNOWLEDGE_VP';

export const acknowledgeVP = actionCreator(ACTION_NAME);

export const acknowledgeVPAsync = actionCreator.async<
	AcknowledgeVPRequest,
	void,
	Error
>(ACTION_NAME, {
	done: {
		description:
			'Vessel Programme key changes have been successfully acknowledged.'
	}
});
