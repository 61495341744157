import Api from 'services/api';
import { updateContext, updateContextAsync } from '../actions/updateContext';
import { updateThreads } from 'store/threads/actions';
import { UpdateContextParams } from 'services/api/messages/messagesServiceTypes';
import { getActiveThread } from 'store/thread/selectors';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';

export function* updateContextExecutor(
	actionParams: UpdateContextParams,
	api: typeof Api.Threads.updateContext
): SagaIterator {
	yield put(updateContextAsync.started(actionParams));

	try {
		const threadContextId = actionParams.threadContext
			? actionParams.threadContext.id
			: null;
		const response = yield call(api, actionParams.threadId, threadContextId);

		yield put(
			updateContextAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		const activeThread = yield select(getActiveThread);
		yield put(updateThreads(activeThread));
	} catch (error) {
		yield put(
			updateContextAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* updateContextWorker(
	action: Action<UpdateContextParams>
): SagaIterator {
	yield call(updateContextExecutor, action.payload, Api.Threads.updateContext);
}

export function* updateContextWatcher(): SagaIterator {
	yield takeLatest(updateContext.type, updateContextWorker);
}

export default function*() {
	yield all([fork(updateContextWatcher)]);
}
