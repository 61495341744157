import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import EditContact from 'sections/Contact/EditContact/Route';
import ComposeMessageRoute from 'sections/ComposeMessage/ComposeMessageRoute';
import Group from 'sections/Groups/Group/Group';
import GroupsRoute from 'sections/Groups/GroupsRoute';
import DashboardRoute from 'sections/Dashboard/DashboardRoute';
import NewComposeMessageRoute from 'sections/ComposeMessage/NewComposeMessageRoute';
import NewComposeMessagePortCallRoute from 'sections/ComposeMessage/NewComposeMessagePortCallRoute';
import ThreadJobAssignmentRoute from 'sections/ThreadJobAssignment/ThreadJobAssignmentRoute';
import ThreadsRoute from 'sections/ThreadsList/ThreadsRoute';
import Users from 'sections/Users/Users';

import PortCallsRoute from 'sections/PortCalls/PortCallsRoute';
import PortCalls from 'sections/PortCalls/PortCalls';
import PortCallRoute from 'sections/PortCall/PortCallRoute';
import PortCallOverview from 'sections/PortCall/Overview/Overview';
import SavingsRoute from 'sections/PortCall/Savings/SavingsRoute';
import PortCallMessages from 'sections/PortCall/Messages/MessagesRoute';
import OperationsDocumentContextualizationRoute from '../PortCall/Operations/Contextualization/ContextualizationRoute';
import FinanceDocumentContextualizationRoute from '../PortCall/Finance/Contextualization/ContextualizationRoute';
import FinanceDocumentPageViewerRoute from '../PortCall/Finance/FinanceDocumentPageViewer/FinanceDocumentPageViewerRoute';
import FinanceSplitSupplierDocumentPageViewerRoute from '../PortCall/Finance/FinanceSplitSupplierInvoiceDocumentViewer/FinanceSplitSupplierInvoiceDocumentPageViewerRoute';
import OperationDocumentPageViewerRoute from '../PortCall/Operations/OperationDocumentPageViewer/OperationDocumentPageViewerRoute';
import AddOperationRoute from 'sections/PortJob/AddOperation/AddOperationRoute';
import EditOperationRoute from 'sections/PortJob/EditOperation/EditOperationRoute';
import EditPortJobRoute from 'sections/PortJob/EditPortJob/EditPortJobRoute';
import CreatePortJobRoute from 'sections/PortJob/CreatePortJob/CreatePortJobWizardRoute';
import * as RouteParams from './RouteParams';
import {
	PermissionRoute,
	PermissionRouteLayout,
	PermissionsCheckMode
} from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Error404, UserPermission, Loading, Error403 } from 'components';
import { UploadDocumentModal } from 'containers';
import EditPortCallRoute from '../PortCall/EditPortCall/EditPortCallRoute';
import PortCallDuplicatesRoute from 'sections/PortCall/Duplicates/PortCallDuplicatesRoute';
import LinkJobRoute from 'sections/PortCall/Linkjob/LinkjobRoute';
import LinkCargoRoute from 'sections/PortCall/Linkjob/LinkCargo/LinkCargoRoute';
import Modals from 'sections/Modals/Modals';
import VesselInfoRoute from 'sections/PortCall/VesselInfo/VesselInfoRoute';
import BankAccountsRoute from 'sections/BankAccounts/BankAccountsRoute';
import AddBankAccountRoute from 'sections/BankAccounts/AddBankAccount/AddBankAccountRoute';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import FundingPageRoute from 'sections/PortCall/Finance/FundingRequestPage/FundingRequestPageRoute';
import BankAccountDetailsPageRoute from 'sections/PortCall/Finance/BankAccountDetailsPage/BankAccountDetailsPageRoute';
import FinanceSupplierInvoiceDocumentPageViewerRoute from 'sections/PortCall/Finance/FinanceSupplierInvoiceDocumentPageViewerRoute/FinanceSupplierInvoiceDocumentPageViewerRoute';
import ComposeMessagePortCallRoute from 'sections/ComposeMessage/ComposeMessagePortCallRoute';
import MyProfileRoute from 'sections/User/MyProfileRoute';
import EditUserRoute from 'sections/User/EditUserRoute';
import SupplierInvoiceSectionRoute from 'sections/SupplierInvoice/SupplierInvoiceSectionRoute';
import ConfigurationLayout from 'sections/Administration/Configuration/ConfigurationLayout';
import ThreadRoute from 'sections/Thread/ThreadRoute';
import { ErrorRedirect } from 'utils/components';
import MasterDataExplorerSectionRoute from 'sections/MasterDataExplorer/MasterDataExplorerSectionRoute';
import { MasterDataExplorerTab } from 'sections/MasterDataExplorer/MasterDataExplorerSectionTypes';
import RouteLayout from './RouteLayout';
import DeveloperPortalRoute from 'sections/Administration/DeveloperPortal/DeveloperPortalRoute';

const EditGroupRoute = lazy(() =>
	import('sections/Groups/Group/EditGroup/EditGroupRoute')
);
const FinanceRoute = lazy(() =>
	import('sections/PortCall/Finance/FinanceRoute')
);
const PortCallVesselProgramme = lazy(() =>
	import('sections/PortCall/VesselProgramme/VesselProgramme')
);
const PortCallOperations = lazy(() =>
	import('sections/PortCall/Operations/Operations')
);
const ConfigurationRoute = lazy(() =>
	import('sections/Administration/Configuration/ConfigurationRoute')
);
const OpticConfigurationRoute = lazy(() =>
	import('sections/Administration/OpticConfiguration/OpticConfigurationRoute')
);
const KpiAlertsEditRoute = lazy(() =>
	import(
		'sections/Administration/OpticConfiguration/KPIAndAlertsTab/KpiAlertsEdit/KpiAlertsEditRoute'
	)
);
const DirectBillRoute = lazy(() =>
	import(
		'sections/Administration/DataManagement/DirectBills/DirectBill/DirectBillRoute'
	)
);
const DataManagementRoute = lazy(() =>
	import('sections/Administration/DataManagement/DataManagementRoute')
);
const AddEditVesselRoute = lazy(() =>
	import('sections/Administration/Configuration/Vessels/AddEditVesselRoute')
);
const TemplateTestPageRoute = lazy(() =>
	import('sections/TemplateTestPageRoute/TemplateTestPageRoute')
);
const PrintMessageRoute = lazy(() =>
	import('sections/Thread/Message/MessageHeader/PrintMessage/PrintMessageRoute')
);

const ThirdPartyLPAGroupsRoute = lazy(() =>
	import('sections/ThirdPartyLPAGroups/ThirdPartyLPAGroupsRoute')
);

const RestrictedApp = () => {
	const { PATHS } = RouteParams;
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route exact path="/" component={DashboardRoute} />
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}`}
					component={ThreadsRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/contact/edit/:newtab?`}
					component={EditContact}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/${RouteParams.THREAD_ID_URL_PARAM}/job/:newtab?`}
					component={ThreadJobAssignmentRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/${RouteParams.THREAD_ID_URL_PARAM}/job/nomination/:newtab?`}
					component={ThreadJobAssignmentRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/${RouteParams.THREAD_ID_URL_PARAM}/compose/${RouteParams.MESSAGE_ID}?/${RouteParams.MESSAGE_ACTION}?/:newtab?`}
					component={ComposeMessageRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/${RouteParams.THREAD_ID_URL_PARAM}/print/${RouteParams.MESSAGE_ID}`}
					component={PrintMessageRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.messages}/${RouteParams.THREAD_ID_URL_PARAM}/:newtab?/:expand?`}
					component={ThreadRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path="/new-message/:newtab?"
					component={NewComposeMessageRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.MANAGE_USERS}
					exact
					path={`/${PATHS.users}/:userId/edit`}
					component={EditUserRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.MANAGE_USERS}
					exact
					path={`/${PATHS.users}/:userId/edit/:newtab?`}
					component={EditUserRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.VIEW_USERS}
					exact
					path={`/${PATHS.users}/:companyId?/:status?/:searchTerm?/:username?`}
					component={Users}
				/>
				<PermissionRoute
					component={GroupsRoute}
					permissionCode={PermissionCode.VIEW_GROUP}
					exact
					path={`/${PATHS.groups}`}
				/>
				<PermissionRoute
					component={Group}
					permissionCode={PermissionCode.VIEW_GROUP}
					exact
					path={`/${PATHS.groups}/:groupId`}
				/>
				<PermissionRoute
					component={EditGroupRoute}
					permissionCode={PermissionCode.MANAGE_GROUP}
					exact
					path={`/${PATHS.groups}/:groupId/edit/:newtab?`}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}`}
					layout={PortCallsRoute}
					component={PortCalls}
				/>
				<PermissionRoute
					component={AddEditVesselRoute}
					permissionCode={[
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA,
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS
					]}
					permissionsCheckMode={PermissionsCheckMode.OR}
					exact
					path={`/${PATHS.configuration}/vessels/:companyId/:vesselId/:mode/:id?/:newtab?`}
				/>
				{/* Create new portCall by a new portJob */}
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/new/:stepname?/:newtab?`}
					layout={PortCallsRoute}
					component={CreatePortJobRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}`}
					render={({ match }) => (
						<Redirect
							to={`/${PATHS.portCalls}/${match.params.portCallId}/jobs/${match.params.portJobCode}/overview`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_ID_URL_PARAM}`}
					render={({ match }) => (
						<Redirect
							to={`/${PATHS.portCalls}/${match.params.portCallId}/jobs/${match.params.portJobId}/overview`}
						/>
					)}
				/>
				{/* Add new job to an existing portcall */}
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/new/:stepname?/:newtab?`}
					component={CreatePortJobRoute}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/overview`}
					layout={PortCallRoute}
					component={PortCallOverview}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/overview`}
					layout={PortCallRoute}
					component={PortCallOverview}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/savings`}
					layout={PortCallRoute}
					component={SavingsRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/edit/:newtab?`}
					component={EditPortCallRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/vessel-info/:newtab?`}
					component={VesselInfoRoute}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/vessel-programme/${RouteParams.PORT_CALL_NEW_VP}?`}
					layout={PortCallRoute}
					component={PortCallVesselProgramme}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/operations/${RouteParams.PORT_CALL_OPERATION_TAB_ID}?/${RouteParams.PORT_CALL_MEASUREMENTS_TAB_ID}?`}
					layout={PortCallRoute}
					component={PortCallOperations}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_OPERATIONS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/operations/documents/${RouteParams.DOCUMENT_ID}/contextualize/:newtab?`}
					component={OperationsDocumentContextualizationRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_PORTCALLS}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/operations/documents/${RouteParams.DOCUMENT_ID}/preview/:newtab?`}
					component={OperationDocumentPageViewerRoute}
				/>
				<PermissionRouteLayout
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance`}
					layout={PortCallRoute}
					component={FinanceRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/overview`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance/da/${RouteParams.FINANCE_FUNDING_DA_ID}/create-funding/:newtab?`}
					component={FundingPageRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance/funding/${RouteParams.FINANCE_FUNDING_ID}/:newtab?`}
					component={FundingPageRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionsCheckMode={PermissionsCheckMode.OR}
					permissionCode={[
						PermissionCode.MANAGE_PDA_CE,
						PermissionCode.MANAGE_DA
					]}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance/bank-account-details/${RouteParams.BANK_ID}/:newtab?`}
					component={BankAccountDetailsPageRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance/${RouteParams.DOCUMENT_ID}/contextualize/:newtab?`}
					component={FinanceDocumentContextualizationRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/finance/documents/${RouteParams.DOCUMENT_TYPE}/${RouteParams.ID}/preview/${RouteParams.PAGE_NUMBER}?/${RouteParams.PAGE_TYPE}?/:newtab?`}
					component={FinanceDocumentPageViewerRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_FINANCE}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/service/${RouteParams.SERVICE_ID}/finance/documents/supplier-invoices-pages/${RouteParams.SUPPLIER_INVOICE_ID}/preview/${RouteParams.PAGE_NUMBER}?/${RouteParams.PAGE_TYPE}?/:newtab?`}
					component={FinanceSplitSupplierDocumentPageViewerRoute}
					errorComponent={({
						match: {
							params: { portCallId, portJobCode }
						}
					}) => (
						<ErrorRedirect
							targetUrl={`/${PATHS.portCalls}/${portCallId}/jobs/${portJobCode}/finance`}
						/>
					)}
				/>
				<Route
					exact
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/service/${RouteParams.SERVICE_ID}/finance/documents/supplier-invoices/${RouteParams.SUPPLIER_INVOICE_ID}/preview/:newtab?`}
					component={FinanceSupplierInvoiceDocumentPageViewerRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/messages/new/:newtab?`}
					component={NewComposeMessagePortCallRoute}
					errorComponent={() => (
						<ErrorRedirect targetUrl={`/${PATHS.portCalls}`} />
					)}
				/>
				<RouteLayout
					exact
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/messages/${RouteParams.THREAD_ID_URL_PARAM}?`}
					layout={PortCallRoute}
					component={PortCallMessages}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.VIEW_MESSAGES}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/messages/${RouteParams.THREAD_ID_URL_PARAM}/compose/${RouteParams.MESSAGE_ID}?/${RouteParams.MESSAGE_ACTION}?/:newtab?`}
					component={ComposeMessagePortCallRoute}
					errorComponent={() => (
						<ErrorRedirect targetUrl={`/${PATHS.portCalls}`} />
					)}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/edit`}
					component={EditPortJobRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/add-operation/:newtab?`}
					component={AddOperationRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/operations/${RouteParams.PORT_CALL_OPERATION_TAB_ID}/edit`}
					component={EditOperationRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/duplicates/:newtab?`}
					component={PortCallDuplicatesRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/linkjob/:newtab?`}
					component={LinkJobRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_PORTCALL}
					path={`/${PATHS.portCalls}/${RouteParams.PORTCALL_ID_URL_PARAM}/jobs/${RouteParams.PORTJOB_CODE_URL_PARAM}/link-cargo/:newtab?`}
					component={LinkCargoRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_BANK_ACCOUNT_DETAILS}
					path={`/${PATHS.bankAccountDetails}`}
					component={BankAccountsRoute}
				/>
				<PermissionRoute
					exact
					permissionCode={PermissionCode.MANAGE_BANK_ACCOUNT_DETAILS}
					path={`/${PATHS.bankAccountDetails}/add/:newtab?`}
					component={AddBankAccountRoute}
				/>
				<Route
					path={`/${PATHS.supplierinvoices}`}
					component={SupplierInvoiceSectionRoute}
				/>
				<Route
					exact
					path="/admin/templates/:templateName?"
					render={params => (
						<UserPermission
							code={GroupTypeCodes.ISS_ADMIN}
							notFoundElement={<Error404 />}
						>
							<TemplateTestPageRoute {...params} />
						</UserPermission>
					)}
				/>

				<Route exact path="/profile/:newtab?" component={MyProfileRoute} />
				<PermissionRouteLayout
					permissionsCheckMode={PermissionsCheckMode.OR}
					permissionCode={[
						PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS,
						PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES
					]}
					exact
					path={`/${PATHS.configuration}`}
					layout={ConfigurationLayout}
					component={ConfigurationRoute}
				/>
				<PermissionRouteLayout
					permissionsCheckMode={PermissionsCheckMode.OR}
					permissionCode={[
						PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS,
						PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
						PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES
					]}
					exact
					path={`/${PATHS.configuration}/${RouteParams.CONFIGURATION_TAB_ID}`}
					layout={ConfigurationLayout}
					component={ConfigurationRoute}
				/>
				<Route exact path="/profile/:newtab?" component={MyProfileRoute} />
				<Route
					exact
					path={`/${PATHS.opticConfiguration}`}
					render={params => (
						<UserPermission
							code={GroupTypeCodes.ISS_ADMIN}
							notFoundElement={<Error403 />}
						>
							<OpticConfigurationRoute {...params} />
						</UserPermission>
					)}
				/>
				<Route
					exact
					path={`/${PATHS.opticConfiguration}/${RouteParams.OPTIC_CONFIGURATION_TAB_ID}`}
					render={params => (
						<UserPermission
							code={GroupTypeCodes.ISS_ADMIN}
							notFoundElement={<Error403 />}
						>
							<OpticConfigurationRoute {...params} />
						</UserPermission>
					)}
				/>

				<Route
					exact
					path={`/${PATHS.opticConfiguration}/${RouteParams.OPTIC_CONFIGURATION_TAB_ID}/${RouteParams.KPIS_ID}/edit/:newtab?`}
					render={params => (
						<UserPermission
							code={GroupTypeCodes.ISS_ADMIN}
							notFoundElement={<Error403 />}
						>
							<KpiAlertsEditRoute {...params} />
						</UserPermission>
					)}
				/>

				{/* <Route
					exact
					path={`/${PATHS.opticConfiguration}/${RouteParams.OPTIC_CONFIGURATION_TAB_ID}/edit-job-status/:newtab?`}
					component={KpiAlertsEditJobRoute}
				/> */}

				<PermissionRoute
					permissionCode={PermissionCode.MANAGE_API_SUBSCRIPTION}
					exact
					path={`/${PATHS.developerPortal}`}
					component={DeveloperPortalRoute}
				/>

				<PermissionRoute
					permissionCode={PermissionCode.VIEW_DIRECT_BILLS}
					exact
					path={`/${PATHS.opticDataManagement}`}
					component={DataManagementRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.MANAGE_DIRECT_BILLS}
					exact
					path={`/${PATHS.opticDataManagement}/create-direct-bill/:newtab?`}
					component={DirectBillRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.VIEW_DIRECT_BILLS}
					exact
					path={`/${PATHS.opticDataManagement}/direct-bills/${RouteParams.DATA_MANAGEMENT_DIRECT_BILL_ID}/:newtab?`}
					component={DirectBillRoute}
				/>
				<PermissionRoute
					permissionCode={PermissionCode.VIEW_DIRECT_BILLS}
					exact
					path={`/${PATHS.opticDataManagement}/${RouteParams.DATA_MANAGEMENT_TAB_ID}`}
					component={DataManagementRoute}
				/>
				<Route path={`/${PATHS.masterDataExplorer}`} exact>
					<Redirect
						to={`/${PATHS.masterDataExplorer}/${MasterDataExplorerTab.COMPANIES}`}
					/>
				</Route>
				<Route
					path={`/${PATHS.masterDataExplorer}/${RouteParams.MASTER_DATA_EXPLORER_TAB_ID}`}
					render={params => (
						<UserPermission
							code={GroupTypeCodes.ISS_ADMIN}
							notFoundElement={<Error404 />}
						>
							<MasterDataExplorerSectionRoute {...params} />
						</UserPermission>
					)}
				/>
				<Route
					exact
					path={`/${PATHS.thirdPartyLPAGroups}`}
					render={params => (
						<UserPermission
							code={GroupTypeCodes.THIRD_PARTY_LPA}
							notFoundElement={<Error403 />}
						>
							<ThirdPartyLPAGroupsRoute {...params} />
						</UserPermission>
					)}
				/>
				<Route component={Error404} />
			</Switch>
			<UploadDocumentModal />
			<Modals />
		</Suspense>
	);
};

export default RestrictedApp;
