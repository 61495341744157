import { SavingsState } from '../savingsState';
import {
	RetrieveSavingsRequest,
	RetrieveSavingsResponse
} from 'services/api/savings/savingsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';

export function onRetrieveSavingsStarted(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			savings: FetchStatus.PENDING
		}
	};
}
export function onRetrieveSavingsSuccess(
	state: SavingsState,
	action: Success<RetrieveSavingsRequest, RetrieveSavingsResponse>
): SavingsState {
	const { result } = action;
	return {
		...state,
		savings: {
			...result
		},
		fetchStatuses: {
			...state.fetchStatuses,
			savings: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveSavingsFailed(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			savings: FetchStatus.FAILURE
		}
	};
}
