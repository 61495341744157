import { Success } from 'typescript-fsa';
import { filter, find, includes, reject } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { DashboardState } from '../dashboardState';
import {
	RetrieveDashboardPortCallsSummaryResponse,
	SummaryType,
	MetricType,
	Summary,
	SummaryJobSection,
	SummaryJobMetric,
	JobSectionType,
	SummarySection,
	DashboardSection
} from 'services/api/dashboard/dashboardServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { AlertMetricCode } from 'app-constants';

const alertsOrderMap = {
	[PortJobStatus.CREATED]: 1,
	[PortJobStatus.CONFIRMED]: 2,
	[PortJobStatus.AWAITING_INVITATION]: 3,
	[PortJobStatus.AWAITING_APPOINTMENT]: 4,
	[PortJobStatus.AWAITING_ACCEPTANCE]: 5,
	[PortJobStatus.APPOINTED]: 6,
	[PortJobStatus.PDA_REQUESTED]: 7,
	[PortJobStatus.PDA]: 8,
	[PortJobStatus.CE]: 9,
	[PortJobStatus.DA]: 10,
	[PortJobStatus.LPA_SETTLEMENTS]: 11,
	[PortJobStatus.DA_OUT_POSTINGS]: 12,
	[PortJobStatus.HUB_SETTLEMENTS]: 13,
	[PortJobStatus.DA_SETTLEMENTS]: 14,
	[PortJobStatus.CLOSED]: 15
};

const fundingAlertMap = {
	[AlertMetricCode.FUNDING_REQUEST_PENDING]: 15,
	[AlertMetricCode.FUNDS_REQUESTED_FROM_PRINCIPAL]: 16
};

const financeStatusOrderMap = {
	[FinanceStatus.DRAFT]: 1,
	[FinanceStatus.SUBMITTED]: 2,
	[FinanceStatus.QUERIED]: 3,
	[FinanceStatus.VERIFIED]: 4,
	[FinanceStatus.APPROVED]: 5
};

export const alersSortFn = (a: SummaryJobMetric, b: SummaryJobMetric) => {
	let aPriority = alertsOrderMap[a.portJobStatus] || fundingAlertMap[a.code];
	let bPriority = alertsOrderMap[b.portJobStatus] || fundingAlertMap[b.code];
	if (aPriority === bPriority) {
		aPriority = financeStatusOrderMap[a.financeStatus];
		bPriority = financeStatusOrderMap[b.financeStatus];
	}

	aPriority =
		a.code === AlertMetricCode.UNASSIGNED_DA
			? Number.MAX_SAFE_INTEGER
			: aPriority;
	bPriority =
		b.code === AlertMetricCode.UNASSIGNED_DA
			? Number.MAX_SAFE_INTEGER
			: bPriority;

	aPriority = aPriority || Number.MAX_SAFE_INTEGER;
	bPriority = bPriority || Number.MAX_SAFE_INTEGER;
	return aPriority - bPriority;
};

export const getJobSummarySubsections = (jobMetrics: SummaryJobMetric[]) => {
	const operationOverview = {
		name: JobSectionType.OPERATION_OVERVIEW,
		code: JobSectionType.OPERATION_OVERVIEW,
		elements: filter(
			jobMetrics,
			jobMetric =>
				(jobMetric.portJobStatus !== PortJobStatus.DA &&
					jobMetric.metricType === MetricType.PORT_JOB_STATUS) ||
				jobMetric.metricType === MetricType.FUNDING_REQUEST_PENDING ||
				jobMetric.metricType === MetricType.FUNDS_REQUESTED_FROM_PRINCIPAL
		).sort(alersSortFn)
	} as SummaryJobSection;

	const financeOverview = {
		name: JobSectionType.FINANCIAL_OVERVIEW,
		code: JobSectionType.FINANCIAL_OVERVIEW,
		elements: filter(
			jobMetrics,
			jobMetric =>
				jobMetric.portJobStatus === PortJobStatus.DA &&
				jobMetric.metricType === MetricType.PORT_JOB_STATUS
		).sort(alersSortFn)
	} as SummaryJobSection;
	return [operationOverview, financeOverview];
};

export const isJobStatusSection = (section: DashboardSection) => {
	return includes(
		['JobStatus', 'ActiveJobsLpa', 'ActiveJobsPrincipal'],
		section.code
	);
};

export const getDashboardSummarySections = (summary: Summary) => {
	const sections = summary.elements.map((section: SummarySection) => {
		let elements: SummaryJobSection[] = [];
		/**
		 * We have quite inconvenient contract here. Planning to fix it in the next version.
		 */
		if (
			section.code === SummaryType.HUB_TASKS ||
			section.code === SummaryType.ACTIVE_JOBS
		) {
			const jobStatusSection = find<SummaryJobSection>(
				section.elements,
				isJobStatusSection
			);
			const jobStatusesList: SummaryJobMetric[] = jobStatusSection
				? jobStatusSection.elements
				: [];
			elements = [
				...getJobSummarySubsections(jobStatusesList),
				...reject<SummaryJobSection>(section.elements, isJobStatusSection)
			];
		} else {
			const subElements = section.elements || [];
			elements = subElements.map(subsection => {
				const metrics = subsection.elements || [];
				return {
					...subsection,
					elements: metrics.sort(alersSortFn)
				};
			});
		}
		return {
			...section,
			elements
		} as SummarySection;
	});
	return {
		...summary,
		elements: sections
	};
};
export function onRetrieveDashboardSummaryStarted(
	state: DashboardState
): DashboardState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievePortCallsSummary: FetchStatus.PENDING
		}
	};
}

export function onRetrieveDashboardSummarySuccess(
	state: DashboardState,
	action: Success<undefined, RetrieveDashboardPortCallsSummaryResponse>
): DashboardState {
	return {
		...state,
		portCallsSummary: getDashboardSummarySections(action.result),
		fetchStatuses: {
			...state.fetchStatuses,
			retrievePortCallsSummary: FetchStatus.SUCCESS
		}
	};
}

export function onRetrieveDashboardSummaryFailed(
	state: DashboardState
): DashboardState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievePortCallsSummary: FetchStatus.FAILURE
		}
	};
}
