import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';

export function getSplittedOperationCargos(
	nominationCargos: LinkedCargo[],
	appointmentCargos: LinkedCargo[]
) {
	const nominationLoadingCargos: LinkedCargo[] = [],
		nominationDischargeCargos: LinkedCargo[] = [],
		nominationLoadingSTSCargos: LinkedCargo[] = [],
		nominationDischargeSTSCargos: LinkedCargo[] = [],
		appointmentLoadingCargos: LinkedCargo[] = [],
		appointmentDischargeCargos: LinkedCargo[] = [],
		appointmentLoadingSTSCargos: LinkedCargo[] = [],
		appointmentDischargeSTSCargos: LinkedCargo[] = [];

	nominationCargos.forEach((cargo, index) => {
		if (cargo.operationType === OperationTypeCode.LOADING) {
			nominationLoadingCargos.push(cargo);
			appointmentLoadingCargos.push(appointmentCargos[index]);
		} else if (cargo.operationType === OperationTypeCode.DISCHARGING) {
			nominationDischargeCargos.push(cargo);
			appointmentDischargeCargos.push(appointmentCargos[index]);
		} else if (cargo.operationType === OperationTypeCode.LOADING_STS) {
			nominationLoadingSTSCargos.push(cargo);
			appointmentLoadingSTSCargos.push(appointmentCargos[index]);
		} else if (cargo.operationType === OperationTypeCode.DISCHARGING_STS) {
			nominationDischargeSTSCargos.push(cargo);
			appointmentDischargeSTSCargos.push(appointmentCargos[index]);
		}
	});

	return [
		[
			nominationLoadingCargos,
			nominationLoadingSTSCargos,
			nominationDischargeCargos,
			nominationDischargeSTSCargos
		],
		[
			appointmentLoadingCargos,
			appointmentLoadingSTSCargos,
			appointmentDischargeCargos,
			appointmentDischargeSTSCargos
		]
	];
}

export const getSortedUnlinkedAppointmentCargos = (cargos: LinkedCargo[]) => {
	const unlinkedAppointmentLoadingCargos: LinkedCargo[] = [],
		unlinkedAppointmentDischargeCargos: LinkedCargo[] = [],
		unlinkedAppointmentLoadingSTSCargos: LinkedCargo[] = [],
		unlinkedAppointmentDischageSTSCargos: LinkedCargo[] = [];

	cargos.forEach(cargo => {
		if (cargo.operationType === OperationTypeCode.LOADING) {
			unlinkedAppointmentLoadingCargos.push(cargo);
		} else if (cargo.operationType === OperationTypeCode.DISCHARGING) {
			unlinkedAppointmentDischargeCargos.push(cargo);
		} else if (cargo.operationType === OperationTypeCode.LOADING_STS) {
			unlinkedAppointmentLoadingSTSCargos.push(cargo);
		} else if (cargo.operationType === OperationTypeCode.DISCHARGING_STS) {
			unlinkedAppointmentDischageSTSCargos.push(cargo);
		}
	});

	return [
		unlinkedAppointmentLoadingCargos,
		unlinkedAppointmentLoadingSTSCargos,
		unlinkedAppointmentDischargeCargos,
		unlinkedAppointmentDischageSTSCargos
	];
};
