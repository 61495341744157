import Api from 'services/api';
import {
	updateService as action,
	updateServiceAsync as asyncAction
} from 'store/finance/actions/updateService';
import uuidv4 from 'uuid/v4';
import {
	UpdateFinanceServiceRequest as request,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { isConcurrencyError } from 'services/api/apiErrorUtils';
import { notify } from 'store/notifications/actions';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { getServiceById } from 'store/finance/selectors';
import { FINANCE_SUPPLEMENTAL_TMP_DA_ID } from '../constants';

const apiCall = Api.Finance.updateFinanceService;
export const notifyConcurrencyError = (name: string) =>
	notify.warning({
		actionType: name,
		description: `The operation can't be completed due to technical reasons. Please check ${name} service data`,
		duration: 0
	});

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const activePortCallId = yield select(getActivePortCallId);
		const activePortJobCode = yield select(getActivePortJobCode);
		let service: PortJobService | undefined;
		if (actionParams.id) {
			service = yield select(getServiceById, actionParams.id);
		}

		let additionalParams = {};
		if (actionParams.daInId === FINANCE_SUPPLEMENTAL_TMP_DA_ID) {
			// the service was picked as supplemental and it is the temporary one, we need to send some proper generated id
			additionalParams = {
				daInId: uuidv4()
			};
		}

		const response = yield call(api, activePortCallId, activePortJobCode, {
			...service,
			...actionParams,
			...additionalParams
		});

		yield put(
			asyncAction.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
		if (isConcurrencyError(error) && actionParams.id) {
			const { name } = yield select(getServiceById, actionParams.id);
			yield put(notifyConcurrencyError(name));
		}
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeEvery(action.type, worker);
}
