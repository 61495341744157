import React from 'react';
import { AppNotifications } from 'containers';
import { PortCallsBaseProps } from 'sections/PortCalls';

interface PortCallsLayoutProps extends PortCallsBaseProps {}

const PortCallsLayout: React.SFC<PortCallsLayoutProps> = ({ children }) => (
	<>
		<AppNotifications.Alert />
		<AppNotifications.Notification />
		{children}
	</>
);

export default PortCallsLayout;
