import React from 'react';
import { ColumnProps } from 'components/Table/InfiniteTable';
import {
	FundingRequest,
	FundingRequestStatus,
	FundingRequestMetadataKey
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import FundingRequestLobd from './FundingTableColumns/FundingRequestLobd';
import { Metadata } from 'app-types';
import { Checkbox, Tooltip } from 'components/antd';
import { FinanceFundingFormField } from 'store/form/financeFundings/financeFundingTypes';
import { FundingCalculatedInputContainer } from './FundingTableColumns/FundingRequestNumberInput/FundingRequestNumberInput';
import FundingRequestBooleanValue from './FundingTableColumns/FundingRequestBooleanValue';
import FundingRequestDaAmount from './FundingTableColumns/FundingRequestDaAmount';
import FundingRequestStatusLabel from './FundingTableColumns/FundingRequestStatusLabel';
import FinanceAmount from '../../FinanceAmount/FinanceAmount';
import { getDaInAmount } from './FundingTableColumns/FundingRequestNumberInput/FundingRequestNumberInput.func';
import numeral from 'numeral';

enum FundingRequestTableKey {
	TYPE = 'type',
	CURRENCY = 'currencyCode',
	AMOUNT = 'fundingAmount',
	PERCENT = 'fundingPercent',
	DA_IN_AMOUNT = 'daInAmount',
	AGENCY_COSTS = 'daInAmountWithoutAgencyCosts',
	CURRENT_FUNDING = 'currentFundingSentOrRequested',
	BALANCE_AMOUNT = 'balanceAmount',
	LOBD = 'daInBankDetailId',
	URGENT = 'isUrgent',
	STATUS = 'status'
}

const isFieldEditable = (
	propertiesToEdit: string[],
	metadataKey: FundingRequestMetadataKey,
	isNew: boolean
) => {
	return isNew || propertiesToEdit.includes(metadataKey);
};

export const fundingRequestColumns: Array<ColumnProps<FundingRequest>> = [
	{
		title: 'Type',
		dataIndex: FundingRequestTableKey.TYPE,
		key: FundingRequestTableKey.TYPE,
		width: '10%'
	},
	{
		title: 'Currency',
		dataIndex: FundingRequestTableKey.CURRENCY,
		key: FundingRequestTableKey.CURRENCY,
		width: '10%'
	},
	{
		title: 'Funding Requested',
		dataIndex: FundingRequestTableKey.AMOUNT,
		key: FundingRequestTableKey.AMOUNT,
		width: '10%',
		render: (value: number, funding: FundingRequest) => {
			const { metadata = {} as Metadata, currencyCode } = funding;
			const { propertiesToEdit = [] } = metadata;
			const balanceAmount = getDaInAmount(
				funding.balanceForDaInAmount,
				funding.balanceForDaInAmountWithoutAgencyCosts,
				funding.baseAmountTypeCode
			);
			return (
				<Tooltip
					title={
						Number(numeral(value).format('0.[00]')) > balanceAmount
							? 'The Funding request exceeds the Balance Amount'
							: undefined
					}
					placement="topLeft"
					trigger="hover"
				>
					<span>
						<FundingCalculatedInputContainer
							fieldName={FinanceFundingFormField.AMOUNT}
							isEditable={isFieldEditable(
								propertiesToEdit,
								FundingRequestMetadataKey.FUNDING_AMOUNT,
								!funding.id
							)}
							value={value}
							funding={funding}
							currencyCode={currencyCode}
						/>
					</span>
				</Tooltip>
			);
		}
	},
	{
		title: 'Funding Percent',
		dataIndex: FundingRequestTableKey.PERCENT,
		key: FundingRequestTableKey.PERCENT,
		width: '10%',
		render: (value: number, funding: FundingRequest) => {
			const { metadata = {} as Metadata } = funding;
			const { propertiesToEdit = [] } = metadata;
			return (
				<FundingCalculatedInputContainer
					fieldName={FinanceFundingFormField.PERCENT}
					isEditable={isFieldEditable(
						propertiesToEdit,
						FundingRequestMetadataKey.FUNDING_PERCENT,
						!funding.id
					)}
					value={value}
					funding={funding}
					targetCurrencyCode={funding.currencyCode}
				/>
			);
		}
	},
	{
		title: 'DA IN Amount',
		dataIndex: FundingRequestTableKey.DA_IN_AMOUNT,
		key: FundingRequestTableKey.DA_IN_AMOUNT,
		width: '10%',
		render: (value: string, funding: FundingRequest) => (
			<FundingRequestDaAmount
				value={value}
				currencyCode={funding.currencyCode}
			/>
		)
	},
	{
		title: 'DA IN w/o agency costs',
		dataIndex: FundingRequestTableKey.AGENCY_COSTS,
		key: FundingRequestTableKey.AGENCY_COSTS,
		width: '10%',
		render: (value: string, funding: FundingRequest) => (
			<FinanceAmount value={value} currencyCode={funding.currencyCode} />
		)
	},
	{
		title: 'Current Funding Sent/Requested',
		dataIndex: FundingRequestTableKey.CURRENT_FUNDING,
		key: FundingRequestTableKey.CURRENT_FUNDING,
		width: '10%',
		render: (value: string, funding: FundingRequest) => (
			<FinanceAmount value={value} currencyCode={funding.currencyCode} />
		)
	},
	{
		title: 'Balance Amount',
		dataIndex: FundingRequestTableKey.BALANCE_AMOUNT,
		key: FundingRequestTableKey.BALANCE_AMOUNT,
		width: '10%',
		render: (_value: string, funding: FundingRequest) => {
			const balanceAmount = getDaInAmount(
				funding.balanceForDaInAmount,
				funding.balanceForDaInAmountWithoutAgencyCosts,
				funding.baseAmountTypeCode
			);
			return (
				<FinanceAmount
					value={balanceAmount}
					currencyCode={funding.currencyCode}
				/>
			);
		}
	},
	{
		title: 'LOBD',
		align: 'center',
		dataIndex: FundingRequestTableKey.LOBD,
		key: FundingRequestTableKey.LOBD,
		width: '5%',
		render: (value: string) => <FundingRequestLobd bankDetailsId={value} />
	},
	{
		title: 'Urgent',
		align: 'center',
		dataIndex: FundingRequestTableKey.URGENT,
		key: FundingRequestTableKey.URGENT,
		width: '5%',
		render: (isUrgent: boolean, funding: FundingRequest) => {
			const { metadata = {} as Metadata } = funding;
			const { propertiesToEdit = [] } = metadata;
			if (
				!isFieldEditable(
					propertiesToEdit,
					FundingRequestMetadataKey.URGENT,
					!funding.id
				)
			) {
				return <FundingRequestBooleanValue value={isUrgent} />;
			}
			return <Checkbox.ReduxFormItem name={FinanceFundingFormField.URGENT} />;
		}
	},
	{
		title: 'Status',
		dataIndex: FundingRequestTableKey.STATUS,
		key: FundingRequestTableKey.STATUS,
		width: '10%',
		render: (status: FundingRequestStatus) => (
			<FundingRequestStatusLabel status={status} />
		)
	}
];
