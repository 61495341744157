import Api from 'services/api';
import {
	retrieveFinance,
	updateRefreshDirectBillAsync as asyncAction
} from '../actions';
import {
	UpdateRefreshDirectBillResponse,
	UpdateRefreshDirectBillRequest,
	JobFinanceExpandParam
} from 'services/api/finance/financeServiceTypes';

import { select, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.UpdateRefreshDirectBill;

export default makeTakeLatestWatcher<
	UpdateRefreshDirectBillRequest,
	UpdateRefreshDirectBillResponse,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams(payload) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request = {
			portCallId,
			jobCode,
			payload
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
	}
});
