import React from 'react';
import { Rect } from 'react-konva';
import Konva from 'konva';
import { RectNode } from '../ImageViewerV2Types';
import TransformerComponent from './TransformerComponent';

interface RectangleProps {
	shapeProps: RectNode;
	isSelected: boolean;
	scale: number;
	isEditing: boolean;
	onChange: (newAttr: RectNode) => void;
	onSelect: () => void;
	onDelete: () => void;
}

const Rectangle = ({
	shapeProps,
	isSelected,
	scale,
	onSelect,
	onChange,
	onDelete,
	isEditing
}: RectangleProps) => {
	const shapeRef = React.useRef<Konva.Rect>(null);

	const onMouseEnter = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		if (!isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'pointer';
		}
	};

	const onMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'default';
		}
	};

	const onMouseClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		onSelect();
	};

	return (
		<React.Fragment>
			<Rect
				x={shapeProps.x * scale}
				y={shapeProps.y * scale}
				width={shapeProps.width * scale}
				height={shapeProps.height * scale}
				rotation={shapeProps.rotation}
				stroke={shapeProps.color}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				strokeWidth={5}
				onClick={onMouseClick}
				onTap={onMouseClick}
				ref={shapeRef}
				draggable={isSelected}
				strokeScaleEnabled={false}
				onDragEnd={e => {
					onChange({
						...shapeProps,
						x: e.target.x() / scale,
						y: e.target.y() / scale
					});
				}}
				onTransformEnd={_e => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = shapeRef.current;
					if (node) {
						const scaleX = node.scaleX() / scale;
						const scaleY = node.scaleY() / scale;

						// we will reset it back
						node.scaleX(1);
						node.scaleY(1);
						onChange({
							...shapeProps,
							x: node.x() / scale,
							y: node.y() / scale,
							// set minimal value
							width: Math.max(5, node.width() * scaleX),
							height: Math.max(node.height() * scaleY),
							rotation: node.rotation()
						});
					}
				}}
			/>
			{isSelected && (
				<TransformerComponent
					isSelected={isSelected}
					shapeRef={shapeRef}
					onDelete={onDelete}
				/>
			)}
		</React.Fragment>
	);
};

export default Rectangle;
