import React from 'react';
import { Link } from 'components';
import ErrorPageScreen from './ErrorPageScreen/ErrorPageScreen';
import config from 'services/config';

interface ErrorProps {
	description?: React.ReactNode;
}

const Error403: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status={403}
		title="Access Denied"
		description={
			<>
				Sorry, you don't have permission to access this page.
				<br />
				Click <Link href="/">here</Link> to go to the homepage or{' '}
				<a href={config.supportTeamLink}>send email</a> if you think you should
				have access to the page.
				{description}
				{children}
			</>
		}
	/>
);

const Error400: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status={400}
		title="Bad Request"
		description={
			<>
				Your request resulted in an error.
				<br />
				Click <Link href="/">here</Link> to go to the homepage or{' '}
				<a href={config.supportTeamLink}>send email</a> to report this problem.
				{description}
				{children}
			</>
		}
	/>
);

const Error404: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status={404}
		title="Page Not Found"
		description={
			<>
				Sorry, the page you are looking for doesn't exist.
				<br />
				Click <Link href="/">here</Link> to go to the homepage or{' '}
				<a href={config.supportTeamLink}>send email</a> to report this problem.
				{description}
				{children}
			</>
		}
	/>
);

const Error500: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status={500}
		title="Internal Server Error"
		description={
			<>
				The server encountered an internal error and was unable to process your
				request.
				<br />
				Click <Link href="/">here</Link> to go to the homepage or{' '}
				<a href={config.supportTeamLink}>send email</a> to report this problem.
				{description}
				{children}
			</>
		}
	/>
);

const Error503: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status={503}
		title="Under Maintenance"
		description={
			<>
				We´re busy pushing out some new features and improvements.
				<br />
				We should be back shortly. Thank you for your patience.
				{description}
				{children}
			</>
		}
	/>
);

const ErrorUnhandled: React.FC<ErrorProps> = ({ description, children }) => (
	<ErrorPageScreen
		status="UNHANDLED"
		title="Unexpected Error"
		description={
			<>
				A previous action may not have completed successfully.
				<br />
				Click <Link href="/">here</Link> to go to the homepage or{' '}
				<a href={config.supportTeamLink}>send email</a> to report this problem.
				{description}
				{children}
			</>
		}
	/>
);

export { Error403, Error400, Error404, Error500, Error503, ErrorUnhandled };
