import actionCreator from '../savingsActionCreator';
import { AddCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';

const ADD_COST_SAVINGS = 'ADD_COST_SAVINGS';

export const addCostSavingsAsync = actionCreator.async<
	AddCostSavingsRequest,
	void,
	Error
>(ADD_COST_SAVINGS, {
	templateParams: {
		entityName: 'Cost Saving'
	}
});

export const addCostSavings = actionCreator(ADD_COST_SAVINGS);
