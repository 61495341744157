import {
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse
} from 'services/api/groups/groupsServiceTypes';
import { Success, Failure } from 'typescript-fsa';
import {
	ThreadAvailableGroupsReducerState,
	setFetchStatus
} from './../../threadAvailableGroupsReducer';

type State = ThreadAvailableGroupsReducerState;

export const onRetrieveUsersFromGroupStarted = (state: State) =>
	setFetchStatus(state, 'users', true);

export const onRetrieveUsersFromGroupFailed = (
	state: State,
	_action: Failure<RetrieveGroupUsersRequest, Error>
) => setFetchStatus(state, 'users', false);

export const onRetrieveUsersFromGroupSuccess = (
	state: State,
	action: Success<RetrieveGroupUsersRequest, RetrieveGroupUsersResponse>
): State => ({
	...setFetchStatus(state, 'users', false),
	groupUsers: {
		...state.groupUsers,
		[action.params.id]: action.result.elements
	}
});
