import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';
import {
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse
} from 'services/api/threads/threadsServiceTypes';

export const onRetrieveThreadContextsSuccess = (
	state: ThreadsState,
	{
		result
	}: Success<RetrieveThreadContextsRequest, RetrieveThreadContextsResponse>
): ThreadsState => ({
	...state,
	threadContexts: result.elements
});

export const onRetrieveThreadContexts = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse
>('fetchStatuses', 'retrievingThreadContexts', {
	doneReducer: onRetrieveThreadContextsSuccess
});
