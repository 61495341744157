import React from 'react';
import { connect } from 'react-redux';

import { DocumentViewerWizardForm } from 'sections/DocumentViewer';
import ContextualizationForm from './ContextualizationForm/ContextualizationForm';

import {
	createFinanceDocuments,
	retrieveFinanceServicesForContextualization,
	retrieveFinance
} from 'store/finance/actions';
import { getIsCreateFinanceDocumentsLoading } from 'store/finance/selectors';
import { getCanSubmitFinanceContextualization } from 'store/form/financeContextualization/selectors';

import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';
import { AppState } from 'store-types';

interface ContextualizationProps {
	portCallId: string;
	jobCode: string;
	documentId: string;
	loading: boolean;
	isAtLeastOnePageContextualized: boolean;
	createFinanceDocuments: typeof createFinanceDocuments;
	retrieveFinance: typeof retrieveFinance;
	retrieveFinanceServicesForContextualization: typeof retrieveFinanceServicesForContextualization;
}
class Contextualization extends React.Component<ContextualizationProps> {
	componentDidMount() {
		const { portCallId, jobCode } = this.props;
		// Consume `financeStage` to determine if to disable `coverSheet` option
		this.props.retrieveFinance({
			portCallId,
			jobCode
		});
		// Retrieve Data for the Form
		this.props.retrieveFinanceServicesForContextualization({
			portCallId,
			jobCode
		});
	}
	onSubmit = () => {
		this.props.createFinanceDocuments({
			portCallId: this.props.portCallId,
			jobCode: this.props.jobCode,
			data: {
				documentId: this.props.documentId,
				contextualizations: [] // This is filled in worker
			}
		});
	};

	getHint = () => (
		<>
			Please allocate each page to a Service/Cover Sheet.
			<p>
				Please go page by page and tell Optic what each page represents (Cover
				Sheet, Funding Receipt, Receipt, etc.)
			</p>
		</>
	);

	render() {
		const {
			documentId,
			portCallId,
			jobCode,
			loading,
			isAtLeastOnePageContextualized
		} = this.props;
		return (
			<DocumentViewerWizardForm
				documentId={documentId}
				title="Upload Documentation"
				returnUrl={`/portcalls/${portCallId}/jobs/${jobCode}/finance`}
				loading={loading}
				onSubmit={this.onSubmit}
				canSubmit={isAtLeastOnePageContextualized}
				pagination={PaginationState.ENABLED}
				hint={this.getHint()}
			>
				{({ total, activePage, onValidChange }) => (
					<ContextualizationForm
						pages={total}
						activePage={activePage}
						hasPagination={total > 1}
						onValidChange={onValidChange}
					/>
				)}
			</DocumentViewerWizardForm>
		);
	}
}

export default connect(
	(state: AppState) => ({
		loading: getIsCreateFinanceDocumentsLoading(state),
		isAtLeastOnePageContextualized: getCanSubmitFinanceContextualization(state)
	}),
	{
		retrieveFinance,
		createFinanceDocuments,
		retrieveFinanceServicesForContextualization
	}
)(Contextualization);
