import { Success, Failure } from 'typescript-fsa';
import { omit } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { DeletePortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import {
	PortCallDocumentsState,
	UploadedByIdState
} from '../portCallDocumentsState';

const onDeletePortCallDocumentStarted = (
	state: PortCallDocumentsState,
	params: DeletePortCallDocumentRequest
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		deleteDocumentById: {
			...state.fetchStatuses.deleteDocumentById,
			[params.documentId]: FetchStatus.PENDING
		}
	}
});

const onDeletePortCallDocumentSuccess = (
	state: PortCallDocumentsState,
	{ params }: Success<DeletePortCallDocumentRequest, null>
) => ({
	...state,

	uploadedById: omit<UploadedByIdState>(state.uploadedById, params.documentId),

	fetchStatuses: {
		...state.fetchStatuses,
		deleteDocumentById: {
			...state.fetchStatuses.deleteDocumentById,
			[params.documentId]: FetchStatus.SUCCESS
		}
	}
});

const onDeletePortCallDocumentFailed = (
	state: PortCallDocumentsState,
	{ params }: Failure<DeletePortCallDocumentRequest, Error>
) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		deleteDocumentById: {
			...state.fetchStatuses.deleteDocumentById,
			[params.documentId]: FetchStatus.FAILURE
		}
	}
});

export {
	onDeletePortCallDocumentStarted,
	onDeletePortCallDocumentSuccess,
	onDeletePortCallDocumentFailed
};
