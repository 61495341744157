import * as React from 'react';
import classNames from 'classnames';
import { Col as GridCol, ColProps as GridColProps } from 'react-flexbox-grid';
import { Justify, Align } from 'components/types';

import styles from './Col.module.scss';

export interface ColProps extends GridColProps {
	align?: Align;
	justify?: Justify;
	gutter?: boolean;
	style?: object;
}

export const Col: React.FC<ColProps> = (props: ColProps) => {
	const { justify, className, gutter, align, ...colProps } = props;
	const alignClasses = {
		[styles.middle]: align === Align.MIDDLE,
		[styles.bottom]: align === Align.BOTTOM
	};
	const justifyClasses = {
		[styles.end]: justify === Justify.END
	};
	return (
		<GridCol
			{...colProps}
			className={classNames(className, justifyClasses, alignClasses, {
				[styles.gutter]: gutter === false
			})}
		/>
	);
};

Col.defaultProps = {
	gutter: true
};

export default Col;
