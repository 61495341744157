import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveServiceAsync } from '../actions/retrieveService';
import {
	RetrieveServiceRequest as request,
	PortJobService as response
} from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';

export default makeTakeEveryWatcher<request, response, Error>({
	api: Api.Finance.retrieveService,
	async: retrieveServiceAsync
});
