import {
	FinanceDetails,
	RetrieveFinanceDetailsRequest
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_FINANCE_DETAILS = 'RETRIEVE_FINANCE_DETAILS';

export const retrieveFinanceDetailsAsync = actionCreator.async<
	RetrieveFinanceDetailsRequest,
	FinanceDetails,
	Error
>(RETRIEVE_FINANCE_DETAILS);

export const retrieveFinanceDetails = actionCreator<
	RetrieveFinanceDetailsRequest
>(RETRIEVE_FINANCE_DETAILS);
