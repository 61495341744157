import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	UpdateServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import Api from 'services/api';
import { updateServiceCategoryAsync as async } from '../actions';

export default makeTakeLatestWatcher<
	UpdateServiceCategoryRequest,
	ServiceCategory,
	Error
>({
	api: Api.Services.updateServiceCategory,
	async
});
