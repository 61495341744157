import * as React from 'react';
import AntTooltip, { TooltipProps as AntTooltipProps } from 'antd/lib/tooltip';
import { Theme } from 'components/types';
import classNames from 'classnames';
import styles from './Tooltip.module.scss';

export type TooltipProps = AntTooltipProps & {
	type?: 'main' | 'warning' | 'error';
};
const Tooltip = (props: TooltipProps) => {
	const {
		type = Theme.MAIN,
		overlayClassName,
		getPopupContainer,
		...restProps
	} = props;

	const onGetPopupContainer = (triggerNode: HTMLElement) => {
		if (getPopupContainer && !getPopupContainer(triggerNode)) {
			/* eslint-disable no-console */
			console.warn(
				`Couldn't find container element using props.getPopupContainer that you provided. \n` +
					'The fallback of triggerNode.parentElement || document.body will be applied. \n' +
					'triggerNode:'
			);
			console.warn(triggerNode);
			/* eslint-enable */
		}
		return (
			getPopupContainer?.(triggerNode) ||
			triggerNode.parentElement ||
			document.body
		);
	};

	return (
		<AntTooltip
			{...restProps}
			getPopupContainer={onGetPopupContainer}
			overlayClassName={classNames(styles.root, overlayClassName, {
				[styles.main]: type === Theme.MAIN,
				[styles.warning]: type === Theme.WARNING,
				[styles.error]: type === Theme.ERROR
			})}
		/>
	);
};

export default Tooltip;
