import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { DownloadSupplierInvoiceDocumentParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { SupplierInvoiceState } from 'store/supplierInvoice/supplierInvoiceState';

export const onDownloadSupplierInvoice = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	DownloadSupplierInvoiceDocumentParams,
	RetrieveDownloadDocumentResponse
>(
	'fetchStatuses',
	(
		params: DownloadSupplierInvoiceDocumentParams
	): keyof SupplierInvoiceState['fetchStatuses'] => {
		const id = params.supplierInvoiceId;
		return `downloadById.${id}` as keyof SupplierInvoiceState['fetchStatuses'];
	}
);
