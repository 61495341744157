import * as React from 'react';
import AntCard, { CardProps } from 'antd/lib/card';

export class Card extends React.Component<CardProps, {}> {
	render() {
		return <AntCard {...this.props} />;
	}
}

export default Card;
