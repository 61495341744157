import { Menu } from 'components/antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	CostSaving,
	TimeSaving
} from 'services/api/savings/savingsServiceTypes';
import { openModal } from 'store/modals/actions';
import {
	setCostSavingsContext,
	setTimeSavingsContext
} from 'store/savings/actions';
import {
	COST_SAVINGS_MODAL,
	DELETE_SAVINGS_MODAL,
	TIME_SAVINGS_MODAL
} from '../Constants';

interface SettingsProps {
	record?: CostSaving;
	value?: TimeSaving;
	setCostSavingsContext: typeof setCostSavingsContext;
	setTimeSavingsContext: typeof setTimeSavingsContext;
	openModal: typeof openModal;
}

export class SettingsMenu extends Component<SettingsProps> {
	updateSavings = (e: { key: string }) => {
		if (e.key === 'update' && this.props.record) {
			this.props.setCostSavingsContext(this.props.record);
			this.props.openModal(COST_SAVINGS_MODAL);
		}
		if (e.key === 'delete' && this.props.record) {
			this.props.setCostSavingsContext(this.props.record);
			this.props.openModal(DELETE_SAVINGS_MODAL);
		}
		if (e.key === 'update' && this.props.value) {
			this.props.setTimeSavingsContext(this.props.value);
			this.props.openModal(TIME_SAVINGS_MODAL);
		}
		if (e.key === 'delete' && this.props.value) {
			this.props.setTimeSavingsContext(this.props.value);
			this.props.openModal(DELETE_SAVINGS_MODAL);
		}
	};
	render() {
		return (
			<Menu onClick={this.updateSavings}>
				<Menu.Item key="update">Update</Menu.Item>
				<Menu.Item key="delete">Delete</Menu.Item>
			</Menu>
		);
	}
}

export default connect(null, {
	setCostSavingsContext,
	setTimeSavingsContext,
	openModal
})(SettingsMenu);
