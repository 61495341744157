import { FinanceState } from '../financeState';
import {
	AddDAActionPayload,
	AddDAResponse,
	PortCallFinance,
	DaType
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { OpenDAModalPayload } from '../actions';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export function onAddDAModalOpen(
	state: FinanceState,
	openDAModalPayload: OpenDAModalPayload
): FinanceState {
	const { daType, setId, editItem } = openDAModalPayload;
	return {
		...state,
		context: {
			...state.context,
			editItem: editItem || null,
			setId,
			showAddDAModal: {
				...state.context.showAddDAModal,
				[daType]: true
			}
		}
	};
}

export function onAddDAModalClose(
	state: FinanceState,
	daType: DaType
): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			editItem: null,
			setId: undefined,
			showAddDAModal: {
				...state.context.showAddDAModal,
				[daType]: false
			}
		}
	};
}

function onAddDASuccess(
	state: FinanceState,
	{ result }: Success<AddDAActionPayload, AddDAResponse>
): FinanceState {
	const finance = state.finance || ({} as PortCallFinance);
	let dasIn = finance.disbursementAccountIns.elements;
	let dasOut = finance.disbursementAccountOuts.elements;

	if (result.daType === DaType.IN) {
		dasIn = [...dasIn, result];
	} else {
		dasOut = [...dasOut, result];
	}

	return {
		...state,
		finance: {
			...finance,
			disbursementAccountIns: {
				...finance.disbursementAccountIns,
				elements: dasIn
			},
			disbursementAccountOuts: {
				...finance.disbursementAccountOuts,
				elements: dasOut
			}
		},
		...result.daType === DaType.OUT
			? {
					documentByDaOutId: {
						...state.documentByDaOutId,
						[result.id]: {
							documentId: '',
							fetchStatus: FetchStatus.IDLE
						}
					}
				}
			: {}
	};
}

export const onAddDA = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	AddDAActionPayload,
	AddDAResponse
>('fetchStatuses', 'addDA', {
	doneReducer: onAddDASuccess
});
