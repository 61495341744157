import actionCreator from '../configurationActionCreator';
const ACTION_NAME = 'DELETE_VESSEL_TYPE_FOR_ACTIVE_COMPANY';

export const deleteActiveCompanyVesselType = actionCreator<null>(ACTION_NAME);

export const deleteActiveCompanyVesselTypeAsync = actionCreator.async<
	null,
	null,
	Error
>(ACTION_NAME);
