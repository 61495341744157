import { Success, Failure } from 'typescript-fsa';
import { map, reject } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentPage } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { deleteSupplierDocumentPageActionParams } from '../actions';
import { SupplierInvoiceState } from '../supplierInvoiceState';
import { PageType } from 'store/finance/constants';

const updateFetchStatuses = (
	state: SupplierInvoiceState,
	params: deleteSupplierDocumentPageActionParams,
	status: FetchStatus
) => ({
	...state.fetchStatuses,
	deleteSupplierDocumentPageById: {
		...state.fetchStatuses.deleteSupplierDocumentPageById,
		[params.documentId]: {
			...state.fetchStatuses.deleteSupplierDocumentPageById[params.documentId],
			[params.actualNumber]: {
				[params.pageType]: status
			}
		}
	}
});

export const onDeleteSupplierInvoiceDocumentPageStarted = (
	state: SupplierInvoiceState,
	params: deleteSupplierDocumentPageActionParams
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.PENDING)
});

export const onDeleteSupplierInvoiceDocumentPageSuccess = (
	state: SupplierInvoiceState,
	{ params }: Success<deleteSupplierDocumentPageActionParams, null>
) => {
	let document = state.splitSupplierInvoiceServiceDocument;
	if (state.splitSupplierInvoiceServiceDocument) {
		document =
			params.pageType === PageType.UPDATED
				? {
						...state.splitSupplierInvoiceServiceDocument,
						pages: map(
							state.splitSupplierInvoiceServiceDocument.pages,
							page => {
								if (page.actualNumber === params.actualNumber) {
									return {
										number: page.number,
										actualNumber: page.actualNumber,
										downloadUrl: page.downloadUrl,
										createdOn: page.createdOn,
										updatedOn: page.updatedOn,
										originalFinanceDocumentId: page.originalFinanceDocumentId,
										isAnnotated: false
									} as PortCallDocumentPage;
								} else {
									return { ...page };
								}
							}
						)
				  }
				: {
						...state.splitSupplierInvoiceServiceDocument,
						pages: reject(state.splitSupplierInvoiceServiceDocument.pages, {
							actualNumber: params.actualNumber
						})
				  };
	}
	return {
		...state,
		splitSupplierInvoiceServiceDocument: document,
		fetchStatuses: updateFetchStatuses(state, params, FetchStatus.SUCCESS)
	};
};

export const onDeleteSupplierInvoiceDocumentPageFailed = (
	state: SupplierInvoiceState,
	{ params }: Failure<deleteSupplierDocumentPageActionParams, Error>
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.FAILURE)
});
