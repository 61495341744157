import React from 'react';
import { AppointDataSelectedVendor } from 'services/api/portJobs/portJobsServiceTypes';
import AppointVendors from '../AppointToPortJobModal/AppointVendors';
import { Tabs } from 'components/antd';
import styles from './VendorSelectionModal.module.scss';

interface VendorSelectionModalContainerProps {
	onServiceVendorSelect: (vendorSelected: AppointDataSelectedVendor) => void;
}

class VendorSelectionModalContainer extends React.Component<
	VendorSelectionModalContainerProps
> {
	render() {
		const { TabPane } = Tabs;

		return (
			<Tabs type="card" fit className={styles.tabPane}>
				<TabPane tab="vendor" key="vendor" className={styles.vendorTabPane}>
					<AppointVendors
						onServiceVendorSelect={this.props.onServiceVendorSelect}
					/>
				</TabPane>
			</Tabs>
		);
	}
}

export default VendorSelectionModalContainer;
