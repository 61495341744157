import actionCreator from '../actionCreator';
import { SplitOperationUnitPayload } from 'store/vesselProgramme/vesselProgrammeState';

export const openSplitOperationUnitModal = actionCreator<{
	operationUnitId: string;
}>('OPEN_SPLIT_OPERATION_UNIT_MODAL');

export const cancelSplitOperationUnitModal = actionCreator(
	'CANCEL_SPLIT_OPERATION_UNIT_MODAL'
);

export const splitOperationUnit = actionCreator<SplitOperationUnitPayload>(
	'SPLIT_OPERATION_UNIT'
);
