import queryString from 'query-string';
import client from 'services/client';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	CreateUnitRequest,
	CreateUnitResponse,
	SearchUnitsParams,
	Unit
} from './unitsServiceTypes';

const retrieveUnits = (): AxiosTypedPromise<ResultCollection<Unit>> => {
	return client.get('/units-of-measurement');
};

const retrieveUnitsForAutocomplete = (
	searchTerm?: string,
	otherParams?: SearchUnitsParams
): AxiosTypedPromise<ResultCollection<Unit>> =>
	client.get('/units-of-measurement/search', {
		params: { searchTerm, ...otherParams },
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});

const createUnit = (
	request: CreateUnitRequest
): AxiosTypedPromise<CreateUnitResponse> => {
	return client.post('/units-of-measurement', {
		params: {
			model: request
		}
	});
};

export default {
	retrieveUnits,
	retrieveUnitsForAutocomplete,
	createUnit
};
