import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';

export const GROUPS_FILTER_NAME = 'groups';

export enum GroupsFiltersParam {
	SEARCH_TERM = 'searchTerm',
	COMPANY = 'company',
	GROUP_TYPE = 'groupType'
}

const filtersSync = {
	[GROUPS_FILTER_NAME]: {
		selector: (state: AppState) => state.filters[GROUPS_FILTER_NAME],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return {
					name: GROUPS_FILTER_NAME,
					filters: JSON.parse(value)
				};
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
