import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';

import {
	ResendInvitationRequest,
	User
} from 'services/api/users/userServiceTypes';
import {
	reInviteUserAsync,
	ReInviteUserRequestPayload
} from '../actions/reinviteUser';

export default makeTakeLatestWatcher<
	ReInviteUserRequestPayload,
	User,
	Error,
	ResendInvitationRequest,
	{ email: string }
>({
	api: Api.Users.resendUserInvitation,
	async: reInviteUserAsync,
	*getMetaTemplateParams({ params }) {
		return {
			email: params.requestParams.email
		};
	},
	*getApiParams(params) {
		return params.requestParams;
	}
});
