import { keyBy, uniq } from 'lodash';
import {
	RetrieveUsersRequest,
	User
} from 'services/api/users/userServiceTypes';
import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveUsersStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.PENDING
	}
});
export const onRetrieveUsersSuccess = (
	state: UsersState,
	action: {
		params: RetrieveUsersRequest;
		result: User[];
	}
): UsersState => {
	const {
		result,
		params: { limit }
	} = action;
	const byId = { ...state.byId, ...keyBy(result, item => item.id) };
	const allIds: string[] = uniq([
		...state.allIds,
		...result.reduce((acc, user: User) => [...acc, user.id], [])
	]);
	return {
		...state,
		allIds,
		byId,
		hasMore: limit === result.length,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
};
export const onRetrieveUsersFailed = (state: UsersState): UsersState => ({
	...state,
	hasMore: false,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.FAILURE
	}
});
