import { all, fork } from 'redux-saga/effects';
import createNewThreadSaga from './createNewThreadSaga';
import retrieveThreadsSaga from './retrieveThreads/retrieveThreadsSaga';
import retrieveThreadContextsSaga from './retrieveThreadContextsSaga';
import retrieveThreadsCycleSaga from './retrieveThreadsCycleSaga';
import retrieveThreadsStatsSaga from './retrieveThreadsStatsSaga';
import retrieveThreadsStatsCycleSaga from './retrieveThreadsStatsCycleSaga';
import retrieveThreadsPendingAlertsSaga from './retrieveThreadsPendingAlertsSaga';
import retrieveThreadsAvailableGroupsWatcher from './retrieveThreadsAvailableGroupsSaga';
import retrieveThreadTagsWatcher from './retrieveThreadTagsSaga';
import updateAssignToThreadsWatcher from './updateAssignToThreadsSaga';
import updateThreadsCategorizeWatcher from './updateThreadsCategorizeSaga';
import retrieveThreadsMainPrincipalTagsWatcher from './retrieveThreadsMainPrincipalTagsSaga';
import retrieveThreadsMainPrincipalGroupsWatcher from './retrieveThreadsMainPrincipalGroupsSaga';

export default function*() {
	yield all([
		fork(createNewThreadSaga),
		fork(retrieveThreadsSaga),
		fork(retrieveThreadContextsSaga),
		fork(retrieveThreadsCycleSaga),
		fork(retrieveThreadsStatsSaga),
		fork(retrieveThreadsMainPrincipalTagsWatcher),
		fork(retrieveThreadsMainPrincipalGroupsWatcher),
		fork(retrieveThreadsStatsCycleSaga),
		fork(retrieveThreadsPendingAlertsSaga),
		fork(retrieveThreadsAvailableGroupsWatcher),
		fork(retrieveThreadTagsWatcher),
		fork(updateAssignToThreadsWatcher),
		fork(updateThreadsCategorizeWatcher)
	]);
}
