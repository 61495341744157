import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	PortJobBaseRequest,
	PortJobAvailableGroup
} from 'services/api/portJobs/portJobsServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';
import { retrieveAvailableGroupsAsync } from '../actions';

export default makeTakeEveryWatcher<
	PortJobBaseRequest,
	ResultCollection<PortJobAvailableGroup>,
	Error
>({
	api: Api.PortJobs.retrieveAvailableGroups,
	async: retrieveAvailableGroupsAsync
});
