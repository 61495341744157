import actionCreator from '../registrationActionCreator';
import { PreRegisterRequest } from 'services/api/registration/registrationServiceTypes';

const ACTION_NAME = 'PRE_REGISTER';

export const preRegisterAsync = actionCreator.async<
	PreRegisterRequest,
	null,
	Error
>(ACTION_NAME);

export const preRegister = actionCreator<PreRegisterRequest>(ACTION_NAME);

export const preRegisterSubmit = actionCreator(`${ACTION_NAME}_SUBMIT`);
