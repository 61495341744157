const columns = [
	{
		title: 'Grade',
		dataIndex: 'grade',
		key: 'grade',
		width: '25%'
	},
	{
		title: 'Nomination Qty',
		dataIndex: 'quantity',
		key: 'quantity',
		width: '40%'
	},
	{
		title: 'UOM',
		dataIndex: 'unitOfMeasurement',
		key: 'unitOfMeasurement',
		width: '35%'
	}
];

export default columns;
