import Api from 'services/api';
import {
	unassignJobFromThread as action,
	unassignJobFromThreadAsync as asyncAction
} from 'store/thread/actions/unassignJobFromThread';
import { updateThreads } from 'store/threads/actions';
import { getActiveThread } from 'store/thread/selectors';
import { AssignJobToThreadParams as requestParams } from 'services/api/threads/threadsServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, fork, put, select } from 'redux-saga/effects';

const apiCall = Api.Threads.unassignJobFromThread;

export function* executor(
	actionParams: requestParams,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(
			asyncAction.done({
				result: {},
				params: actionParams,
				response
			})
		);
		const activeThread = yield select(getActiveThread);
		yield put(updateThreads(activeThread));
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
	}
}

function* worker({ payload }: Action<requestParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield fork(watcher);
}
