import * as React from 'react';
import AntDatePicker from 'antd/lib/date-picker';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import AntCalendar from 'antd/lib/calendar';
import { Field, WrappedFieldProps } from 'redux-form';
import moment from 'moment-timezone';
import styles from './Datepicker.module.scss';
import Form from 'components/antd/Form/Form';

const { MonthPicker, RangePicker } = AntDatePicker;

interface DatePickerReduxFormItemProps extends DatePickerProps {
	name: string;
	dateFormat?: string;
	showTime?: boolean;
	required?: boolean;
	label?: string;
	showErrors?: boolean;
}

interface WrappedDatePickerReduxFormItemProps
	extends DatePickerProps,
		WrappedFieldProps {}
class DatePicker extends React.Component<DatePickerProps> {
	static Calendar = AntCalendar;
	static RangePicker = RangePicker;
	static MonthPicker = MonthPicker;
	static ReduxFormItem: (
		props: DatePickerReduxFormItemProps
	) => React.ReactElement<Field>;
	render() {
		return <AntDatePicker {...this.props} className={styles.root} />;
	}
}

const prepareError = (errors: string | string[]): string | undefined => {
	if (errors instanceof Array) {
		return errors.join('\n');
	}

	return errors;
};

const wrappedComponent: React.FC<WrappedDatePickerReduxFormItemProps> = ({
	input,
	meta,
	dateFormat,
	showTime,
	label,
	showErrors,
	required
}: // eslint-disable-next-line
any) => {
	const { touched, valid } = meta;

	return (
		<Form.Item
			label={label}
			required={required}
			validateStatus={touched && !valid ? 'error' : 'success'}
			help={showErrors && prepareError(meta.error)}
		>
			<DatePicker
				format={dateFormat || 'MM/DD/YYYY'}
				value={input.value ? moment(input.value) : undefined}
				showTime={showTime || false}
				onChange={input.onChange}
				className={showTime ? 'with-time' : ''}
			/>
		</Form.Item>
	);
};

DatePicker.ReduxFormItem = (
	props: DatePickerReduxFormItemProps
): React.ReactElement<Field> => {
	return (
		// eslint-disable-next-line
		<Field name={props.name} component={wrappedComponent} {...(props as any)} />
	);
};

export default DatePicker;
