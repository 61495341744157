import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	deleteFinanceDocumentOriginalAndAnnotatedPage,
	deleteFinanceDocumentPage,
	downloadFinanceDocument
} from 'store/finance/actions';

import { getCanDeleteDocumentPage } from 'store/finance/selectors';

import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { AppState } from 'store-types';
import { UserType } from 'services/api/users/userServiceTypes';
import { getUserType } from 'store/auth/selectors';
import { CommonMetadataAction } from 'app-constants';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import DocumentPageViewerV2 from 'sections/DocumentPageViewerV2/DocumentPageViewerV2';
import { FinanceDocumentType, PageType } from 'store/finance/constants';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';

interface FinanceDocumentPageViewerProps {
	id: string;
	portCallId: string;
	jobCode: string;
	document: PortCallDocument;
	baseUrl: string;
	activePage: number;
	activePageType: PageType;
	documentType: FinanceDocumentType;
	onSaveAnnotation: (
		request: UploadCustomRequest,
		actualNumber: number
	) => void;
	onRestoreOriginal: () => void;
}

function FinanceDocumentPageViewer({
	portCallId,
	jobCode,
	document,
	baseUrl,
	activePage,
	activePageType,
	documentType,
	id,
	onSaveAnnotation,
	onRestoreOriginal
}: FinanceDocumentPageViewerProps) {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const metadata = document.metadata?.actions || [];
	const canUpload = !!getEntityMetadataAction<CommonMetadataAction>(
		metadata,
		CommonMetadataAction.DELETE
	);

	const canDeletePage = useSelector((state: AppState) =>
		getCanDeleteDocumentPage(state)
	);

	const downloadAllOnFinish = useCallback(() => {
		dispatch(
			downloadFinanceDocument({
				documentId: id,
				isMultiFileDownload: true
			})
		);
	}, [dispatch, id]);

	const onPageDelete = useCallback(
		(page: PortCallDocumentPage, pageType: PageType, deleteBoth = false) => {
			if (deleteBoth) {
				dispatch(
					deleteFinanceDocumentOriginalAndAnnotatedPage({
						documentId: id,
						documentType: documentType,
						actualNumber: page.actualNumber
					})
				);
			} else {
				const documentId =
					pageType === PageType.ORIGINAL
						? page.originalFinanceDocumentId
						: page.annotatedFinanceDocumentId;
				dispatch(
					deleteFinanceDocumentPage({
						documentId: documentId,
						actualNumber: page.actualNumber,
						pageType: pageType
					})
				);
			}
		},
		[dispatch, id, documentType]
	);

	return (
		<DocumentPageViewerV2
			onDownloadAll={downloadAllOnFinish}
			returnUrl={`/portcalls/${portCallId}/jobs/${jobCode}/finance`}
			document={document}
			total={document.pages.length}
			isAnnotatable={userType === UserType.HUB && canUpload}
			isDocumentPDF
			baseUrl={baseUrl}
			activePage={activePage}
			activePageType={activePageType}
			onSaveAnnotation={onSaveAnnotation}
			canDeletePage={!!canDeletePage}
			onPageDelete={onPageDelete}
			onRestoreOriginal={onRestoreOriginal}
		/>
	);
}

export default FinanceDocumentPageViewer;
