import Api from 'services/api';
import { retrievePortByIdAsync as async } from 'store/masterdata';
import {
	RetrievePortByIdRequest as request,
	RetrievePortByIdResponse as response
} from 'services/api/ports/portsServiceTypes';

import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Ports.retrievePortById;

export default makeTakeLatestWatcher<request, response, Error>({ api, async });
