import React from 'react';
import { CopyToClipboardWrapper, Flex } from 'components';
import { Popover, Icon } from 'components/antd';
import { CompanyAgentAutocompleteOption } from 'services/api/ports/portsServiceTypes';
import styles from './InfoAgentPopover.module.scss';
import { IconType } from 'components/antd/Icon/IconTypes';

interface InfoAgentPopoverProps {
	data: CompanyAgentAutocompleteOption;
	icon?: keyof IconType;
	title?: string;
}
const InfoAgentPopover = ({
	data,
	icon = 'info',
	title = `Agent's details`
}: InfoAgentPopoverProps) => {
	const { name, ...company } = data;
	return (
		<div className={styles.wrapper}>
			<span>{name}</span>
			<Popover
				title={title}
				content={
					<table className={styles.table}>
						<tbody>
							<tr>
								<td>Email:</td>
								<td>
									<Flex align="center">
										{company.companyEmail}
										<CopyToClipboardWrapper
											copyContent={company.companyEmail}
											buttonClassName={styles.copyToClipboardBtn}
											buttonTitle=""
											initialTooltipTitle="Copy to clipboard"
										/>
									</Flex>
								</td>
							</tr>
							<tr>
								<td>Phone:</td>
								<td>
									<Flex align="center">
										{company.companyTel}
										<CopyToClipboardWrapper
											copyContent={company.companyTel}
											buttonClassName={styles.copyToClipboardBtn}
											buttonTitle=""
											initialTooltipTitle="Copy to clipboard"
										/>
									</Flex>
								</td>
							</tr>
							<tr>
								<td colSpan={2} className={styles.paddingTop}>
									<strong>Registration address</strong>
								</td>
							</tr>
							<tr>
								<td>Address line 1:</td>
								<td>{company.address?.addressLine1}</td>
							</tr>
							<tr>
								<td>Address line 2:</td>
								<td>{company.address?.addressLine2}</td>
							</tr>
							<tr>
								<td>Address line 3:</td>
								<td>{company.address?.addressLine3}</td>
							</tr>
							<tr>
								<td>Address line 4:</td>
								<td>{company.address?.addressLine4}</td>
							</tr>
							<tr>
								<td>City:</td>
								<td>{company.address?.city}</td>
							</tr>
							<tr>
								<td>Country:</td>
								<td>{company.address?.countryName}</td>
							</tr>
							<tr>
								<td>Postal code</td>
								<td>{company.address?.postalCode}</td>
							</tr>
						</tbody>
					</table>
				}
				trigger="hover"
				placement="topLeft"
				getPopupContainer={() => document.body}
				overlayClassName={styles.overlay}
			>
				<div className={styles.alignCenter}>
					<Icon type={icon} offset="left" color="primary" />
				</div>
			</Popover>
		</div>
	);
};

export default InfoAgentPopover;
