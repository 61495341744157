import actionCreator from '../portCallDocumentsActionCreator';
import {
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const RETRIEVE_PORTCALL_DOCUMENT = 'RETRIEVE_PORTCALL_DOCUMENT';
export const retrievePortCallDocumentAsync = actionCreator.async<
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse,
	Error
>(RETRIEVE_PORTCALL_DOCUMENT);

export const retrievePortCallDocument = actionCreator<
	RetrievePortCallDocumentRequest
>(RETRIEVE_PORTCALL_DOCUMENT);
