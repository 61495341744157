import { createSelector } from 'reselect';
import { LabeledValue } from 'app-types';
import { AllHubPrincipalsOption } from 'store/supplierInvoice/filtersSync';
import { getMainPrincipalCompanies } from 'store/companies/selectors/companiesSelectors';

export const getMainPrincipalCompaniesAsOptions = createSelector(
	getMainPrincipalCompanies,
	(companies): Array<LabeledValue<string>> => {
		return [
			AllHubPrincipalsOption,
			...companies.map(
				(element): LabeledValue<string> => ({
					key: element.id,
					label: element.companyName
				})
			)
		];
	}
);
