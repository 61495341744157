import Api from 'services/api';
import { retrievePortsAsync as async } from '../actions';
import { RetrievePortsResponse as Response } from 'services/api/ports/portsServiceTypes';

import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<null, Response, Error>({
	api: Api.Ports.retrievePorts,
	async
});
