import { createSelector, createStructuredSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading, getValueSelector } from 'store/selectors';
import { filter, map, keyBy, omit, isNull, get, isEmpty } from 'lodash';
import { getPOIDrafts } from 'store/drafts/selectors';

import {
	VesselProgrammeState,
	ReducerRotationStep,
	CustodyLineRotationStep,
	EventsById,
	OperationUnitsById,
	ReducerOperationUnit
} from 'store/vesselProgramme/vesselProgrammeState';
import {
	SeverityColor,
	RotationStepMainType,
	RotationStepMovementType,
	Revision,
	RetrieveOperationUnit,
	RotationStepEvent,
	VPMetadataActionCode
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';
import { getUserType } from 'store/auth/selectors/user';
import { UserType } from 'services/api/users/userServiceTypes';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import { isResponseError } from 'services/api/apiErrorUtils';

export const getVesselProgramme = (state: AppState) => state.vesselProgramme;
const getVesselMetadata = (state: AppState) => state.vesselProgramme.metadata;
export const getVPHash = (state: AppState) => state.vesselProgramme.hash;
export const getVPToken = (state: AppState) =>
	state.vesselProgramme.concurrencyToken;

export const getIsVesselProgrammeReady = createSelector(
	getVesselProgramme,
	vp => vp.isReady
);

export const getIsVesselProgrammeRetrievedByPortCallId = createSelector(
	getVesselProgramme,
	(_state: AppState, id: string) => id,
	(vesselProgramme, id) => vesselProgramme.portCallId === id
);

export const getHasVPMetadataAction = createSelector(
	getVesselMetadata,
	(_state: AppState, action: VPMetadataActionCode) => action,
	(metadata, actionCode) => {
		if (!metadata) {
			return false;
		}
		return !!getEntityMetadataAction<VPMetadataActionCode>(
			metadata.actions,
			actionCode
		);
	}
);

export const getVesselProgrammeValueSelector = getValueSelector<
	VesselProgrammeState
>(getVesselProgramme);

export const getShowErrorPageVP = (state: AppState) => {
	return state.vesselProgramme.context.showErrorPage;
};

export const getRotationStepsById = (state: AppState) =>
	state.vesselProgramme.rotationStepsById;

const getSaveError = (state: AppState) => state.vesselProgramme.saveError;

export const getSaveErrorData = createSelector(getSaveError, saveError =>
	saveError && isResponseError(saveError.error)
		? saveError.error.response.data
		: {}
);
export const getSaveErrorDescription = createSelector(
	getSaveError,
	saveError => saveError?.errorDescriprion || []
);

export const getRotationStepById = (state: AppState, id: string) =>
	getRotationStepsById(state)[id];

function getRotationStepField<Key extends keyof ReducerRotationStep>(
	field: Key
) {
	return (state: AppState, id: string): ReducerRotationStep[Key] => {
		const step = getRotationStepById(state, id);
		return step?.[field];
	};
}

export const getRotationStepName = getRotationStepField('name');
export const getRotationStepAnchorage = getRotationStepField('anchorage');
export const getRotationStepBuoy = getRotationStepField('buoy');
export const getRotationStepCanal = getRotationStepField('canal');
export const getRotationStepType = getRotationStepField('rotationStepType');
export const getRotationStepPoiType = getRotationStepField('poiType');
export const getRotationStepEventIds = getRotationStepField('events');
export const getRotationStepUnitIds = getRotationStepField('units');
export const getRotationStepMovementType = getRotationStepField('movementType');
export const getRotationStepTerminalName = createSelector(
	getRotationStepField('terminal'),
	terminal => terminal?.name || null
);

export const getRotationStepBerthName = createSelector(
	getRotationStepField('berth'),
	berth => berth?.name || null
);

export const getPreviousRotationStepId = (
	{ vesselProgramme: { orderedRotationSteps } }: AppState,
	referenceId: string
) => {
	const pos = orderedRotationSteps.indexOf(referenceId);
	return orderedRotationSteps[pos - 1];
};

export const getPreviousRotationStep = (
	state: AppState,
	referenceId: string
) => {
	return state.vesselProgramme.rotationStepsById[
		getPreviousRotationStepId(state, referenceId)
	];
};

export const getNextRotationStepId = (
	{ vesselProgramme: { orderedRotationSteps } }: AppState,
	referenceId: string
) => {
	const pos = orderedRotationSteps.indexOf(referenceId);

	return orderedRotationSteps[pos + 1];
};

export const getNextRotationStep = (state: AppState, referenceId: string) => {
	return state.vesselProgramme.rotationStepsById[
		getNextRotationStepId(state, referenceId)
	];
};

// terminal selectors
const getTerminalsMap = (state: AppState) => state.vesselProgramme.terminalById;

export const getTerminalsByPortId = (state: AppState, id: string) =>
	getTerminalsMap(state)[id];

export const getTerminalInPortWithName = createSelector(
	getTerminalsByPortId,
	(_state, _portId, name: string) => name,
	(terminals = [], name) =>
		terminals.find(t => name !== null && t.name === name)
);

// berth selectors
const getBerthsByTerminalMap = (state: AppState) =>
	state.vesselProgramme.berthByTerminalId;

export const getBerthsByTerminalId = (state: AppState, terminalId: string) =>
	getBerthsByTerminalMap(state)[terminalId];

export const getTerminalId = createSelector(
	getRotationStepField('terminal'),
	terminal => terminal?.id || ''
);

export const getEventsById = (state: AppState) =>
	state.vesselProgramme.eventsById;

export const getEventById = (state: AppState, id: string) =>
	getEventsById(state)[id];

export const getOperationsById = (state: AppState) =>
	state.vesselProgramme.operationUnitsById;

export const getOperationById = (state: AppState, id: string) =>
	getOperationsById(state)[id];

export const getRotationStepIds = (state: AppState) =>
	state.vesselProgramme.orderedRotationSteps;

const getOrderedRotationStepsById = createSelector(
	getRotationStepIds,
	getRotationStepsById,
	(orderedIds, rotationStepsById) =>
		keyBy(
			orderedIds.map(id => rotationStepsById[id]),
			item => item.id
		)
);

export const isVesselProgrammeInEditMode = (state: AppState) =>
	state.vesselProgramme.editMode;

export const isSaveVesselProgrammeEnabled = (state: AppState) =>
	state.vesselProgramme.saveEnabled;

export const isVesselProgrammeEdited = (state: AppState) =>
	state.vesselProgramme.edited;

export const isVesselProgrammeLoading = createSelector(
	(state: AppState) => state.vesselProgramme.fetchStatuses.retrieve,
	getIsLoading
);

export const isSavingVesselProgramme = createSelector(
	(state: AppState) => state.vesselProgramme.fetchStatuses.save,
	getIsLoading
);

export const getIsAcknowledgeFetchStatusPending = createSelector(
	(state: AppState) => state.vesselProgramme.fetchStatuses.acknowledge,
	getIsLoading
);

export const getIsAcknowledgeWithCommentFetchStatusPending = createSelector(
	(state: AppState) =>
		state.vesselProgramme.fetchStatuses.acknowledgeWithComment,
	getIsLoading
);

export const getVPRevisions = (state: AppState) =>
	state.vesselProgramme.revisions;

const getAllVPRevisions = (state: AppState) =>
	state.vesselProgramme.allRevisions;

export const getVPRevisionsSortedByNewestVersion = createSelector(
	getAllVPRevisions,
	(revisions: Revision[]): Revision[] =>
		revisions.sort(
			(revisionA: Revision, revisionB: Revision): number =>
				revisionB.number - revisionA.number
		)
);

export const getVPCurrentRevisionNumber = createSelector(
	getAllVPRevisions,
	revisions => (isEmpty(revisions) ? 1 : revisions[0].number)
);

export const getVPCurrentUnacknowledgedRevisionId = createSelector(
	getVPRevisions,
	revisions => get(revisions[0], 'id', undefined)
);

export const getLastVPRevisionComment = (state: AppState) =>
	state.vesselProgramme.lastRevision
		? state.vesselProgramme.lastRevision.comment
		: '';

export const getVPRevisionsWithVisibleAlerts = createSelector(
	getVPRevisions,
	revisions =>
		revisions
			.map(revision => ({
				...revision,
				revisionAlerts: revision.revisionAlerts.filter(
					alert => alert.severityColor !== SeverityColor.EMPTY
				)
			}))
			.filter(revision => revision.revisionAlerts.length > 0)
);

export const getHasVPAlerts = createSelector(
	getVPRevisionsWithVisibleAlerts,
	revisions => revisions.length > 0
);

export const getIsVPAlertsVisible = createSelector(
	getHasVPAlerts,
	getUserType,
	isVesselProgrammeInEditMode,
	(isVPAlertsExist, userType, inEditMode) =>
		isVPAlertsExist && userType === UserType.PRINCIPAL && !inEditMode
);

export const canShowAddPOI = createSelector(
	[getRotationStepById, getPreviousRotationStep, getNextRotationStep],
	(
		step: ReducerRotationStep,
		prev: ReducerRotationStep,
		next: ReducerRotationStep
	) => {
		if (!step) {
			return false;
		}

		if (step.rotationStepType === RotationStepMainType.ARRIVAL_POINT) {
			return false;
		}

		if (prev) {
			const isMovementStepAndPrevStepIsPoi =
				step.rotationStepType === RotationStepMainType.MOVEMENT &&
				step.movementType === RotationStepMovementType.INBOUND_PORT_VOYAGE &&
				prev.rotationStepType === RotationStepMainType.POI;
			const isSailingPoint =
				step.rotationStepType === RotationStepMainType.SAILING_POINT &&
				prev.rotationStepType === RotationStepMainType.POI;
			const isPoiStepAndPrevStepIsArrival =
				step.rotationStepType === RotationStepMainType.POI &&
				prev.rotationStepType === RotationStepMainType.ARRIVAL_POINT;

			if (
				isMovementStepAndPrevStepIsPoi ||
				isSailingPoint ||
				isPoiStepAndPrevStepIsArrival
			) {
				return false;
			}
		}

		if (
			next &&
			step.rotationStepType === RotationStepMainType.POI &&
			next.rotationStepType === RotationStepMainType.SAILING_POINT
		) {
			return false;
		}

		return true;
	}
);

export const isAddPOIDialogRestricted = createSelector(
	getVesselProgramme,
	(_state: AppState, id: string) => id,
	(vp, id) => {
		const pos = vp.orderedRotationSteps.indexOf(id);
		return pos === 1 || pos === vp.orderedRotationSteps.length - 1;
	}
);

export const isNewlyCreatedUnit = (id: string): boolean =>
	// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
	isNull(id.match(/^((?!\[).)*$/));

export const isIdAutoGenerated = (id: string): boolean =>
	id.indexOf('generated') > 0;

const getEventsForRequest = (
	reducer: ReducerOperationUnit | ReducerRotationStep,
	events: EventsById
): RotationStepEvent[] =>
	map(reducer.events, id => {
		const event = events[id];

		/**
		 * TODO
		 * TS upgrade: any is used to to types conflict
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let eventForRequest: any;
		if (isIdAutoGenerated(event.id)) {
			eventForRequest = {
				...omit(event, 'id'),
				trackValidationId: event.trackValidationId
			};
		} else {
			eventForRequest = {
				id: event.id,
				code: event.code,
				estimatedDatePlt: event.estimatedDatePlt
			};
		}
		if (isNull(eventForRequest.estimatedDatePlt)) {
			return omit(eventForRequest, 'estimatedDatePlt');
		}
		return eventForRequest;
	});

const getUnitsForRequest = (
	step: ReducerRotationStep,
	units: OperationUnitsById,
	events: EventsById
): RetrieveOperationUnit[] =>
	map(step.units, id => {
		/**
		 * TODO
		 * TS upgrade: any is used to to types conflict
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let unitForRequest: any;
		if (isNewlyCreatedUnit(units[id].id)) {
			unitForRequest = omit(units[id], 'id'); // for new elements remove id, they have parentId[num] form of id
		} else {
			const splitIndex = units[id].splitIndex;
			unitForRequest = {
				id: units[id].id,
				...(splitIndex && { splitIndex }),
				commodityQuantity: units[id].commodityQuantity
			};
		}
		unitForRequest.events = getEventsForRequest(units[id], events);
		return unitForRequest;
	});

const unallocatedUnitsStructuredForApi = createSelector(
	(state: AppState) => state.vesselProgramme.operationUnitsById,
	(state: AppState) => state.vesselProgramme.eventsById,
	(units, events) =>
		map(
			filter(Object.values(units), unit => !unit.allocatedId),
			unit => {
				/**
				 * TODO
				 * TS upgrade: any is used to to types conflict
				 */
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				let unitForRequest: any;
				if (isNewlyCreatedUnit(unit.id)) {
					unitForRequest = omit<ReducerOperationUnit>(unit, 'id');
				} else {
					const splitIndex = unit.splitIndex;
					unitForRequest = {
						id: unit.id,
						commodityQuantity: unit.commodityQuantity,
						...(splitIndex && { splitIndex })
					};
				}
				unitForRequest.events = getEventsForRequest(unit, events);
				return unitForRequest;
			}
		)
);

const rotationStepsStructuredForApi = createSelector(
	[getEventsById, getOrderedRotationStepsById, getOperationsById],
	(events, steps, units) =>
		map(steps, step => {
			/**
			 * TODO
			 * TS upgrade: any is used to to types conflict
			 */
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let stepForRequest: any;
			if (isIdAutoGenerated(step.id)) {
				stepForRequest = omit(step, 'id'); // for new elements remove id, they have parentId[num] form of id
			} else {
				stepForRequest = {
					id: step.id,
					poiType: step.poiType,
					poiId: step.poiId || null,
					rotationStepType: step.rotationStepType
				};
			}
			if (step.custodyTransfer) {
				stepForRequest.custodyTransfer = step.custodyTransfer;
			}
			stepForRequest.events = getEventsForRequest(step, events);
			stepForRequest.units = getUnitsForRequest(step, units, events);
			return stepForRequest;
		})
);

export const getVPComment = (state: AppState) => state.vesselProgramme.comment;

export const getVesselProgrammeStructured = createStructuredSelector({
	unallocatedUnits: unallocatedUnitsStructuredForApi,
	rotationSteps: rotationStepsStructuredForApi,
	POIDrafts: getPOIDrafts,
	comments: getVPComment,
	concurrencyToken: getVPToken
});

export const getIsRotationStepDirectlyAboveLine = ({
	agentMode,
	hasCustodyLine
}: CustodyLineRotationStep) => {
	return !hasCustodyLine || (hasCustodyLine && agentMode === 'Inbound');
};

export const getEventsByOperationUnitId = createSelector(
	getOperationById,
	(unit): string[] => unit.events || []
);

export const getRecordedEventsByIds = createSelector(
	getEventsByOperationUnitId,
	getEventsById,
	(eventIds: string[], events) =>
		eventIds.filter(
			eventId => events[eventId].status === PortCallEventStatus.RECORDED
		)
);

export const getIsDownloadVPRevisionLoading = (
	state: AppState,
	revisionId: string
) =>
	getIsLoading(
		state.vesselProgramme.fetchStatuses.downloadRevisionById[revisionId]
	);

export const getVPPendingAlerts = createSelector(
	getVesselProgramme,
	vp => vp.pendingAlerts
);

export const isUpdateVPFromFinanceLoading = createSelector(
	(state: AppState) => state.vesselProgramme.fetchStatuses.updateVPFromFinance,
	getIsLoading
);
