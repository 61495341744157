import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { saveDocumentAsync } from '../actions';
import {
	SaveDocumentRequest,
	SaveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
import Api from 'services/api';

export default makeTakeEveryWatcher<
	SaveDocumentRequest,
	SaveDocumentResponse,
	Error
>({
	api: Api.Documents.saveDocument,
	async: saveDocumentAsync
});
