import actionCreator from '../actionCreator';
import { UpdateVPFromFinanceRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'UPDATE_VP_FROM_FINANCE';

export const updateVPFromFinance = actionCreator<UpdateVPFromFinanceRequest>(
	ACTION_NAME
);

export const updateVPFromFinanceAsync = actionCreator.async<
	UpdateVPFromFinanceRequest,
	void,
	Error
>(ACTION_NAME);
