import { Success, Failure } from 'typescript-fsa';
import { map, reject } from 'lodash';
import { FinanceState } from '../financeState';
import { FetchStatus } from 'services/api/apiTypes';
import { DeleteFinanceDocumentPageActionParams } from '../actions/deleteFinanceDocumentPage';
import { PageType } from '../constants';
import { PortCallDocumentPage } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const updateFetchStatuses = (
	state: FinanceState,
	params: DeleteFinanceDocumentPageActionParams,
	status: FetchStatus
) => ({
	...state.fetchStatuses,
	deleteDocumentPageById: {
		...state.fetchStatuses.deleteDocumentPageById,
		[params.documentId]: {
			...state.fetchStatuses.deleteDocumentPageById[params.documentId],
			[params.actualNumber]: {
				[params.pageType]: status
			}
		}
	}
});

export const onDeleteFinanceDocumentPageStarted = (
	state: FinanceState,
	params: DeleteFinanceDocumentPageActionParams
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.PENDING)
});

export const onDeleteFinanceDocumentPageSuccess = (
	state: FinanceState,
	{ params }: Success<DeleteFinanceDocumentPageActionParams, null>
) => {
	let document = state.document;
	if (state.document) {
		document =
			params.pageType === PageType.UPDATED
				? {
						...state.document,
						pages: map(state.document.pages, page => {
							if (page.actualNumber === params.actualNumber) {
								return {
									number: page.number,
									actualNumber: page.actualNumber,
									downloadUrl: page.downloadUrl,
									createdOn: page.createdOn,
									updatedOn: page.updatedOn,
									originalFinanceDocumentId: page.originalFinanceDocumentId,
									isAnnotated: false
								} as PortCallDocumentPage;
							} else {
								return { ...page };
							}
						})
				  }
				: {
						...state.document,
						pages: reject(state.document.pages, {
							actualNumber: params.actualNumber
						})
				  };
	}
	return {
		...state,
		document,
		fetchStatuses: updateFetchStatuses(state, params, FetchStatus.SUCCESS)
	};
};

export const onDeleteFinanceDocumentPageFailed = (
	state: FinanceState,
	{ params }: Failure<DeleteFinanceDocumentPageActionParams, Error>
) => ({
	...state,

	fetchStatuses: updateFetchStatuses(state, params, FetchStatus.FAILURE)
});
