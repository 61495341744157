import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveSupplierInvoiceDocumentAsync } from '../actions';
import Api from 'services/api';
import {
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse,
	Error
>({
	async: retrieveSupplierInvoiceDocumentAsync,
	api: Api.SupplierInvoice.retrieveSupplierInvoiceDocument
});
