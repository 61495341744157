import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { FileExtension } from 'app-constants';
import { Action } from 'typescript-fsa';
import { call, select, takeLatest, put } from 'redux-saga/effects';

import { uploadFinanceDocumentationDone, retrieveFinance } from '../actions';

import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { getUploadedFileExtension } from 'store/documents/selectors';

import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import getActivePortCallAndPortJobIdsExecutor, {
	RetrievePortCallAndPortJobCodeResponse
} from 'utils/sagaHelpers/portCallSagaExecutor';

import { navigateTo } from 'utils';

export default function*() {
	yield takeLatest(uploadFinanceDocumentationDone.type, function*(
		action: Action<UploadDocumentDoneResponse>
	) {
		const { document, isDoneSuccess, contextualizePdf } = action.payload;
		if (!isDoneSuccess) {
			return;
		}

		const extension = yield select(getUploadedFileExtension);

		/**
		 * Don't contextualize and refresh finance
		 */
		if (extension.toLowerCase() !== FileExtension.PDF || !contextualizePdf) {
			const portCallIdAndJobCode: RetrievePortCallAndPortJobCodeResponse = yield call(
				getActivePortCallAndPortJobIdsExecutor
			);
			yield put(
				retrieveFinance({
					...portCallIdAndJobCode,
					expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
				})
			);
		} else {
			/** Contextualize only if current file it's a PDF */
			yield call(navigateTo, `finance/${document.id}/contextualize`);
		}

		// Reset state
		yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
	});
}
