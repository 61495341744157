import { all, fork } from 'redux-saga/effects';
import retrieveCompanyVesselTypesWatcher from './retrieveCompanyVesselTypesSaga';
import createCompanyVesselTypeWatcher from './createCompanyVesselTypeSaga';
import updateCompanyVesselTypeWatcher from './updateCompanyVesselTypeSaga';
import deleteCompanyVesselTypeWatcher from './deleteCompanyVesselTypeSaga';

export default function*() {
	yield all([
		fork(retrieveCompanyVesselTypesWatcher),
		fork(createCompanyVesselTypeWatcher),
		fork(updateCompanyVesselTypeWatcher),
		fork(deleteCompanyVesselTypeWatcher)
	]);
}
