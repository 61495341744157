import React from 'react';
import styles from './Operation.module.scss';
import { PortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { Box } from 'components';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import LoadSection from './Sections/LoadOperation';
import GenericSection from './Sections/GenericOperation';
import CanalTransitSection from './Sections/CanalTransitOperation';
import CrewChangeSection from './Sections/CrewChangeOperation';
import DischargeSection from './Sections/DischargeOperation';
import CashToMasterSection from './Sections/CashToMasterOperation';
import BunkeringOperationSection from './Sections/BunkeringOperation/BunkeringOperation';
import CrewWageDisbursementOperationSection from './Sections/CrewWageDisbursementOperation';
import SparesAndDocumentDeliveryOperation from './Sections/SparesAndDocumentDeliveryOperation';
import OperationHeader from './OperationHeader';

const sectionByTypeMap = {
	[OperationTypeCode.LOADING]: LoadSection,
	[OperationTypeCode.LOADING_STS]: LoadSection,
	[OperationTypeCode.DISCHARGING]: DischargeSection,
	[OperationTypeCode.DISCHARGING_STS]: DischargeSection,
	[OperationTypeCode.GENERIC]: GenericSection,
	[OperationTypeCode.CANAL_TRANSIT]: CanalTransitSection,
	[OperationTypeCode.CREW_CHANGE]: CrewChangeSection,
	[OperationTypeCode.CASH_TO_MASTER]: CashToMasterSection,
	[OperationTypeCode.CREW_WAGE_DISBURSEMENT]: CrewWageDisbursementOperationSection,
	[OperationTypeCode.BUNKERING]: BunkeringOperationSection,
	[OperationTypeCode.SPARES_AND_DOCUMENT_DELIVERY]: SparesAndDocumentDeliveryOperation
};

const getSectionByType = (type: OperationTypeCode) => sectionByTypeMap[type];

interface OperationProps {
	operation: PortJobOperation;
	portCallId: string;
	portJobCode: string;
	isPortJobCancelled?: boolean;
}

export const Operation = ({
	portJobCode,
	portCallId,
	operation,
	isPortJobCancelled
}: OperationProps) => {
	const Section = getSectionByType(operation.code);

	return (
		<Box type="primary" className={styles.root}>
			<Box.Header>
				<OperationHeader
					operation={operation}
					portJobCode={portJobCode}
					portCallId={portCallId}
					isPortJobCancelled={isPortJobCancelled}
				/>
			</Box.Header>
			<Box.Content className={styles.content}>
				{Section ? (
					<Section
						operation={
							// TODO different operation types are expected by each `section` component, improvement needed
							// Note: changed during TS upgrade
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							operation as any
						}
					/>
				) : null}
			</Box.Content>
		</Box>
	);
};

export default Operation;
