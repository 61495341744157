import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { VesselProgrammeState } from '../vesselProgrammeState';
import { DownloadVPRevisionActionParams } from '../actions/downloadVPRevision';

export const onDownloadVPRevisionReducer = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	DownloadVPRevisionActionParams,
	RetrieveDownloadDocumentResponse
>(
	'fetchStatuses',
	({
		id
	}: DownloadVPRevisionActionParams): keyof VesselProgrammeState['fetchStatuses'] =>
		`downloadRevisionById.${id}` as keyof VesselProgrammeState['fetchStatuses']
);
