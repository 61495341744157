import client from 'services/client';

import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	Currency,
	RetrieveCurrenciesRequest
} from 'services/api/currencies/currenciesServiceTypes';
import { SearchEntitiesForAutocompleteResponse } from 'containers/Autocomplete/AutocompleteTypes';

export const API_URL = '/currencies';

export const retrieveCurrencies = (
	request: RetrieveCurrenciesRequest | undefined
): AxiosTypedPromise<ResultCollection<Currency>> => {
	return client.get(API_URL, {
		params: request
	});
};

export const retrieveCurrenciesForAutocomplete = (
	searchTerm?: string
): AxiosTypedPromise<SearchEntitiesForAutocompleteResponse> =>
	client.get(`${API_URL}/search`, {
		params: {
			searchTerm
		}
	});

export default {
	retrieveCurrencies,
	retrieveCurrenciesForAutocomplete
};
