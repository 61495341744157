import * as React from 'react';
import { Flex, Status, FormattedTimeExtended } from 'components';
import { Row, Col, Icon } from 'components/antd';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { portCallStatusToLabel } from 'store/portJobs/constants';
import { Justify } from 'components/types';
import { EntityPermissionType } from 'services/api/permissions/permissionsServiceTypes';
import PortCallSettingsMenu from '../components/settings/PortCallSettingsMenu';
import SettingsButton from '../components/SettingsButton';
import { Link } from 'react-router-dom';

export interface HeaderProps {
	portcall: PortCall;
}
const Header: React.SFC<HeaderProps> = props => {
	const { portcall } = props;
	return (
		<Row>
			<Col xs={12} sm={8}>
				<Flex row>
					<Flex>
						<div>
							<Icon type="vessel" color="standard" offset="right" size={16} />
						</div>
						<div>
							Vessel
							<br />
							<strong>
								<Link to={`/portcalls/${portcall.id}/vessel-info`}>
									{portcall.vessel.name}
								</Link>
							</strong>
						</div>
					</Flex>
					<Flex>
						<div>
							<Icon type="anchor" color="standard" offset="right" size={16} />
						</div>
						<div>
							Port
							<br />
							<strong>{portcall.port.name}</strong>
						</div>
					</Flex>
					<Flex>
						<div>
							<Icon type="eta" color="standard" offset="right" size={16} />
						</div>
						<div>
							{portcall.eospPlt ? 'EOSP' : 'ETA'}
							<br />
							<strong>
								<FormattedTimeExtended
									value={portcall.eospPlt || portcall.etaPlt}
									directPortTimeZoneId={portcall.portTimezoneId}
								/>
							</strong>
						</div>
					</Flex>
					<Flex>
						<div>
							<Icon type="ets" color="standard" offset="right" size={16} />
						</div>
						<div>
							{portcall.cospPlt ? 'COSP' : 'ETS'}
							<br />
							<strong>
								{portcall.cospPlt || portcall.etsPlt ? (
									<FormattedTimeExtended
										value={portcall.cospPlt || portcall.etsPlt}
										directPortTimeZoneId={portcall.portTimezoneId}
									/>
								) : (
									'TBD'
								)}
							</strong>
						</div>
					</Flex>
				</Flex>
			</Col>
			<Col xs={12} sm={4}>
				<Flex fit row justify={Justify.END}>
					<div>
						Status
						<br />
						<Status.Label
							value={portCallStatusToLabel(
								portcall.status,
								portcall.isCancelled
							)}
							generic
						/>
					</div>
					<Flex align={'end'}>
						<div>
							Details Last Update
							<br />
							<strong>
								<FormattedTimeExtended
									value={portcall.lastUpdatedOnUtc}
									convertFromUtc
									timeAgo
								/>
							</strong>
						</div>
						<div>
							<SettingsButton
								entityKey={portcall.id}
								entityType={EntityPermissionType.PORTCALL}
								content={permissions => (
									<PortCallSettingsMenu {...permissions} />
								)}
								disabled={portcall.isCancelled}
								tooltipPlacement="bottomRight"
							/>
						</div>
					</Flex>
				</Flex>
			</Col>
		</Row>
	);
};

export default Header;
