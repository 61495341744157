import { FetchStatus } from 'services/api/apiTypes';
import { SavingsState } from '../savingsState';

export function onUpdateTimeSavingsStarted(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.PENDING
		}
	};
}
export function onUpdateTimeSavingsSuccess(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.SUCCESS
		}
	};
}
export function onUpdateTimeSavingsFailed(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.FAILURE
		}
	};
}
