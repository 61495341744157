import * as React from 'react';
import { connect } from 'react-redux';
import {
	reduxForm,
	InjectedFormProps,
	getFormAsyncErrors,
	FormErrors,
	registerField
} from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { Row, Col, Select, Tooltip } from 'components/antd';
import Fields from './Fields';
import { isNil, isEmpty, get } from 'lodash';
import {
	getValueKey,
	retrievePreferredAgents,
	getAgentsOnUpdate,
	onSearchCompaniesFunc
} from './Form.func';

import {
	CompanyVessel,
	SearchCompaniesRequest
} from 'services/api/companies/companiesServiceTypes';

import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import AddCompanyDraftModal from 'containers/Drafts/company/AddCompanyDraftModal';
import {
	ADD_APPOINTER_DRAFT_MODAL,
	ADD_COMPANY_DRAFT_MODAL
} from 'containers/Drafts/constants';
import { openModal } from 'store/modals/actions';

import {
	getIsCurrentUsersGroupTypeISSCluster,
	getIsCurrentUsersGroupTypeISSHub,
	getIsCurrentUserHub,
	getIsCurrentUserPrincipal
} from 'store/auth/selectors';

import validate from './validate';
import onChange from './onChange';
import {
	FORM,
	FormFieldName,
	PORT_JOB_WIZARD_ID
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	getPortCallFormComputedValues,
	getPortCallInitialValues
} from 'sections/PortJob/CreatePortJob/createPortJobSelectors';

import { CompanyAgentResponsibility } from 'services/api/ports/portsServiceTypes';
import asyncValidate from './asyncValidate';

import { retrievePortCallsStatic } from 'store/portcalls/actions';

import {
	getPortJobTypes,
	getIsMainPrincipalTypeCLS,
	getIsAgentFieldEditable
} from 'store/portJobs/selectors';
import { getIsHubPrincipalFieldValid } from '../../page2Selectors';
import FieldHubPrincipal from '../../../Page1/PagePartials/Form/Field.HubPrincipal';
import { Loading, Text, Position } from 'components';
import styles from './CreatePortJobForm.module.scss';
import {
	getActivePortCallId,
	getIsStaticLoaded
} from 'store/portcalls/selectors';
import { StaticItem } from 'store/portcalls/portCallsState';
import {
	setActiveDraftType,
	setActiveDraftFormAndField
} from 'store/drafts/actions';
import { DraftType } from 'store/drafts/draftsState';
import { AppState } from 'store-types';
import { FieldType } from 'store/form/formTypes';
import PageFormWrapper from '../../../PageFormWrapper';
import { getWizardById } from 'store/wizardForm/wizardFormSelectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import {
	getCompanyJobTypes,
	getCompanyJobTypesFetchStatus
} from 'store/companies/selectors';
import { FetchStatus } from 'services/api/apiTypes';
import { resetCompaniesContactDetails } from 'store/companies/actions/retrieveCompaniesContactDetails';
import AddAppointerDraftModal from 'containers/Drafts/AppointerDraft/AddAppointerDraftModal';
import { getCompanyVesselsMapByRecordId } from 'store/companyVessels/selectors';
import { JobType } from 'store/portJobs/constants';

const {
	HUB_PRINCIPAL_COMPANY,
	APPOINTER_COMPANY,
	NOMINATOR,
	PAYING_PARTY,
	JOB_TYPE_ID,
	CONTACT_1,
	CONTACT_2,
	PERFORMING_AGENT,
	CONTROLLING_AGENT
} = FormFieldName;

interface PageOwnProps {
	onFormStateChange?: (valid: boolean) => void;
	isInEditMode?: boolean;
	isAgentFieldDisabled?: boolean;
}

export type PageBaseProps = PageOwnProps & {
	// from mapStateToProps
	activePortCallId: string;
	formValues: FormData;
	initialValues: Partial<FormData>;
	isHubPrincipalFieldValid: boolean;
	isHubPrincipalFieldVisible: boolean;
	isCurrentUsersGroupTypeISSCluster: boolean;
	isCurrentUsersGroupTypeISSHub: boolean;
	isCurrentUserWithRoleHub: boolean;
	isCurrentUserWithRolePrincipal: boolean;
	isMainPrincipalTypeCLS: boolean;
	portJobTypes: StaticItem[];
	formAsyncErrors: FormErrors<FormData, string>;
	isAgentFieldEditable: boolean;
	activeWizard: WizardFormProps;
	jobTypes: string[];
	jobTypesFetchStatus: string;
	vessel: CompanyVessel | undefined;
	// from mapDispatchToProps
	retrievePortCallsStatic: typeof retrievePortCallsStatic;
	openModal: typeof openModal;
	setActiveDraftType: typeof setActiveDraftType;
	setActiveDraftFormAndField: typeof setActiveDraftFormAndField;
	registerFormField: typeof registerField;
	resetCompaniesContactDetails: typeof resetCompaniesContactDetails;
	isLoaded: boolean;
};

export interface PageState {
	preferredPerformingAgents: CompanyAgentResponsibility[];
	preferredControllingAgents: CompanyAgentResponsibility[];
}

export interface PageFormProps
	extends InjectedFormProps<FormData, PageBaseProps>,
		PageBaseProps {}

class PageForm extends React.Component<PageFormProps, PageState> {
	constructor(props: PageFormProps) {
		super(props);
		this.state = {
			preferredPerformingAgents: [],
			preferredControllingAgents: []
		};
	}

	componentDidMount() {
		// for validation to take effect
		this.props.registerFormField(
			this.props.form,
			FormFieldName.HUB_PRINCIPAL_COMPANY,
			FieldType.FIELD
		);
		// we should fetch job types only if data is empty!
		if (isEmpty(this.props.portJobTypes)) {
			this.retrieveInitialData();
		}
	}

	componentDidUpdate(prevProps: PageFormProps, prevState: PageState) {
		const { formValues: prevFormValues, jobTypes: prevJobTypes } = prevProps;
		const {
			formValues,
			valid,
			isHubPrincipalFieldVisible,
			isMainPrincipalTypeCLS,
			jobTypes
		} = this.props;

		const COMPANY =
			formValues.jobTypeId?.key === JobType.APPOINTMENT
				? APPOINTER_COMPANY
				: NOMINATOR;

		if (
			this.props.initialized &&
			!prevProps.initialized &&
			!isHubPrincipalFieldVisible
		) {
			this.props.asyncValidate();
		}

		/**
		 * TODO: Update redux-form and use asyncChangeFields instead asyncBlurFields
		 * We can't fix it with old version of the redux-form
		 * get ised in order to decrease amount of weird logic here!
		 */
		if (
			get(prevFormValues.jobTypeId, 'key') !== get(formValues.jobTypeId, 'key')
		) {
			this.props.asyncValidate();
		}
		/**
		 * Paying Party Field default value to `Appointer` or 'Nominator' based on JobType
		 */
		if (
			!isMainPrincipalTypeCLS &&
			formValues[COMPANY] &&
			!getValueKey(prevFormValues[PAYING_PARTY])
		) {
			this.props.change(PAYING_PARTY, formValues[COMPANY]);
		}
		if (
			prevFormValues[HUB_PRINCIPAL_COMPANY] &&
			getValueKey(formValues[HUB_PRINCIPAL_COMPANY]) !==
				getValueKey(prevFormValues[HUB_PRINCIPAL_COMPANY])
		) {
			if (prevFormValues[JOB_TYPE_ID]) {
				this.props.change(JOB_TYPE_ID, null);
				this.props.untouch(JOB_TYPE_ID);
			}
			if (prevFormValues[APPOINTER_COMPANY]) {
				this.props.change(APPOINTER_COMPANY, null);
			}
			if (prevFormValues[CONTACT_1]) {
				this.props.change(CONTACT_1, null);
			}
			if (prevFormValues[CONTACT_2]) {
				this.props.change(CONTACT_2, null);
			}
		}
		if (valid !== prevProps.valid && this.props.onFormStateChange) {
			this.props.onFormStateChange(valid);
		}

		// If agency type/hub principal field changes, agents are re-fetched
		const preferredAgentsShouldBeDefined =
			formValues.agencyType !== prevFormValues.agencyType ||
			formValues.hubPrincipalCompany !== prevFormValues.hubPrincipalCompany;
		if (
			preferredAgentsShouldBeDefined &&
			formValues.hubPrincipalCompany &&
			formValues.agencyType
		) {
			retrievePreferredAgents({
				portId: this.props.formValues.portCall.port.key,
				mainPrincipalId: formValues.hubPrincipalCompany.key,
				agentType: formValues.agencyType.key
			}).then(newState => {
				this.setState(newState);
			});
		}

		// When agents re-fetched, controlling/performing agents are pre-populated
		// based on previous/current response set in `retrievePreferredAgents`
		const { isAgentFieldEditable, isInEditMode } = this.props;
		const { controllingAgent, performingAgent } = getAgentsOnUpdate(
			this.state,
			prevState.preferredControllingAgents,
			prevState.preferredPerformingAgents
		);

		if (isAgentFieldEditable || !isInEditMode) {
			if (controllingAgent !== undefined) {
				this.props.change(CONTROLLING_AGENT, controllingAgent);
			}
			if (performingAgent !== undefined) {
				this.props.change(PERFORMING_AGENT, performingAgent);
			}
		}

		if (prevJobTypes !== jobTypes && jobTypes.length === 1 && !isInEditMode) {
			this.props.change(JOB_TYPE_ID, { key: jobTypes[0], label: jobTypes[0] });
		}
	}

	isJobTypeIdVisible = () =>
		(this.props.isHubPrincipalFieldValid &&
			!this.props.isMainPrincipalTypeCLS &&
			this.props.jobTypesFetchStatus !== FetchStatus.PENDING) ||
		this.props.isInEditMode;

	onSearchCompanies = (
		options: SearchCompaniesRequest = {},
		withParentId = false
	) => onSearchCompaniesFunc(options, withParentId, this.props.formValues);

	areFieldsVisible = () => {
		const {
			formAsyncErrors,
			isHubPrincipalFieldValid,
			formValues,
			asyncValidating
		} = this.props;

		if (!isNil(formAsyncErrors) || asyncValidating) {
			return false;
		}
		return isHubPrincipalFieldValid && formValues.jobTypeId;
	};

	onAddDraftClick = (draftType: DraftType, formFieldName: string) => {
		this.props.setActiveDraftType({
			activeDraftType: draftType,
			activeDraftTypeAlias: 'Agent'
		});
		this.props.setActiveDraftFormAndField({
			fieldName: formFieldName,
			formId: FORM.portJob
		});
		this.props.openModal({ name: ADD_COMPANY_DRAFT_MODAL });
	};

	onAddAppointerDraftClick = (draftType: DraftType, formFieldName: string) => {
		this.props.setActiveDraftType({
			activeDraftType: draftType
		});
		this.props.setActiveDraftFormAndField({
			fieldName: formFieldName,
			formId: FORM.portJob
		});
		this.props.openModal({ name: ADD_APPOINTER_DRAFT_MODAL });
	};

	onJobTypeChange = () => {
		this.props.resetCompaniesContactDetails();
		this.props.change(NOMINATOR, null);
		this.props.change(APPOINTER_COMPANY, null);
		this.props.untouch(APPOINTER_COMPANY);
		this.props.untouch(CONTACT_1);
		this.props.untouch(NOMINATOR);
	};

	getJobTypeDropdown = (isAssignMode: boolean) => {
		const { activeWizard, jobTypes } = this.props;
		if (isAssignMode) {
			return (
				<Select.ReduxFormItem
					name={JOB_TYPE_ID}
					label="Job Type:"
					labelInValue
					required
					disabled={!isAssignMode && activeWizard.startPage !== 2}
					showArrow={isAssignMode || activeWizard.startPage === 2}
				>
					<Select.Option key="Appointment">Appointment</Select.Option>
				</Select.ReduxFormItem>
			);
		} else {
			if (jobTypes.length === 0) {
				return (
					<Tooltip
						trigger="hover"
						title="Principal is not allowed to create jobs of any type according to the Principal's configuration"
						placement="bottomLeft"
					>
						<Select.ReduxFormItem
							name={FormFieldName.JOB_TYPE_ID}
							label="Job Type:"
							labelInValue
							required
							disabled
						></Select.ReduxFormItem>
					</Tooltip>
				);
			} else {
				return (
					<Select.ReduxFormItem
						name={JOB_TYPE_ID}
						label="Job Type:"
						labelInValue
						required
						disabled={activeWizard.startPage !== 2}
						showArrow={activeWizard.startPage === 2}
						onChange={this.onJobTypeChange}
					>
						{jobTypes.map(job => (
							<Select.Option key={job}>{job}</Select.Option>
						))}
					</Select.ReduxFormItem>
				);
			}
		}
	};

	render() {
		const {
			isCurrentUsersGroupTypeISSCluster,
			isHubPrincipalFieldVisible,
			formValues,
			isAgentFieldDisabled,
			asyncValidating,
			formAsyncErrors = {},
			isInEditMode,
			activeWizard,
			jobTypesFetchStatus
		} = this.props;
		const isAssignMode = activeWizard.mode === 'assign';
		const isLoading = jobTypesFetchStatus === 'pending';
		const error = formAsyncErrors[FormFieldName.HUB_PRINCIPAL_COMPANY];

		return (
			<PageFormWrapper hubPrincipalCompany={formValues.hubPrincipalCompany}>
				<Row>
					<Col sm={10}>
						<Row>
							<Col xs={3}>
								<FieldHubPrincipal
									required
									name={FormFieldName.HUB_PRINCIPAL_COMPANY}
									label="HUB Principal:"
									hubPrincipalCompany={formValues.hubPrincipalCompany}
									isFieldHidden={
										!isHubPrincipalFieldVisible || activeWizard.startPage === 1
									}
									resetOnChange
								/>
							</Col>
						</Row>
						{!isLoading ? (
							<>
								{this.isJobTypeIdVisible() && (
									<Row>
										<Col xs={3}>
											{!isInEditMode ? (
												this.getJobTypeDropdown(isAssignMode)
											) : (
												<Select.ReduxFormItem
													name={JOB_TYPE_ID}
													label="Job Type:"
													labelInValue
													required
													disabled
													showArrow={false}
												></Select.ReduxFormItem>
											)}
										</Col>
									</Row>
								)}
								{asyncValidating && (
									<Position
										fit
										className={styles.loadingOverlay}
										type="absolute"
										position="topLeft"
									>
										<Loading size="lg" />
									</Position>
								)}
								{this.areFieldsVisible() && !asyncValidating && (
									<Fields
										isInEditMode={isInEditMode}
										isAgentFieldDisabled={isAgentFieldDisabled}
										withParentId={!isCurrentUsersGroupTypeISSCluster}
										searchCompanies={this.onSearchCompanies}
										onAddDraftClick={this.onAddDraftClick}
										onAddAppointerDraftClick={this.onAddAppointerDraftClick}
									/>
								)}
								{error && <Text color="error">{error}</Text>}
								<AddCompanyDraftModal />
								<AddAppointerDraftModal />
							</>
						) : (
							<Loading />
						)}
					</Col>
				</Row>
			</PageFormWrapper>
		);
	}

	private retrieveInitialData() {
		this.props.retrievePortCallsStatic();
	}
}

const ReduxForm = reduxForm<FormData, PageBaseProps>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: FORM.portJob,
	validate: validate as any, // eslint-disable-line @typescript-eslint/no-explicit-any
	asyncValidate: asyncValidate as any, // eslint-disable-line @typescript-eslint/no-explicit-any
	asyncBlurFields: [FormFieldName.HUB_PRINCIPAL_COMPANY],
	shouldAsyncValidate: ({ blurredField }: { blurredField?: string }) => {
		return blurredField !== FormFieldName.HUB_PRINCIPAL_COMPANY;
	},
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
	onChange
})(PageForm);

export default connect(
	(state: AppState, ownProps: PageOwnProps) => {
		const formAsyncErrors = getFormAsyncErrors(FORM.portJob)(state);
		const activePortCallId = getActivePortCallId(state);
		const isCurrentUsersGroupTypeISSHub = getIsCurrentUsersGroupTypeISSHub(
			state
		);
		const formValues = getPortCallFormComputedValues(state) as FormData;
		let vessel;
		if (
			formValues.jobTypeId?.key &&
			formValues.hubPrincipalCompany?.key &&
			formValues.portCall &&
			formValues.portCall.vessel
		) {
			vessel = getCompanyVesselsMapByRecordId(
				state,
				formValues.hubPrincipalCompany?.key,
				formValues.portCall.vessel.key
			);
		}

		return {
			formAsyncErrors,
			isLoaded: getIsStaticLoaded(state),
			// used type assertion to not touch logic, must be Partial<T>
			formValues: getPortCallFormComputedValues(state) as FormData,
			initialValues: getPortCallInitialValues(state),
			isHubPrincipalFieldValid: getIsHubPrincipalFieldValid(state),
			isHubPrincipalFieldVisible:
				isCurrentUsersGroupTypeISSHub &&
				!ownProps.isInEditMode &&
				!!activePortCallId,
			isCurrentUsersGroupTypeISSCluster: getIsCurrentUsersGroupTypeISSCluster(
				state
			),
			isCurrentUsersGroupTypeISSHub,
			isCurrentUserWithRoleHub: getIsCurrentUserHub(state),
			isCurrentUserWithRolePrincipal: getIsCurrentUserPrincipal(state),
			portJobTypes: getPortJobTypes(state),
			isMainPrincipalTypeCLS: getIsMainPrincipalTypeCLS(state),
			activePortCallId,
			isAgentFieldEditable: getIsAgentFieldEditable(state),
			activeWizard: getWizardById(state, PORT_JOB_WIZARD_ID),
			jobTypes: getCompanyJobTypes(state),
			jobTypesFetchStatus: getCompanyJobTypesFetchStatus(state),
			vessel
		};
	},
	{
		retrievePortCallsStatic,
		openModal,
		setActiveDraftType,
		setActiveDraftFormAndField,
		registerFormField: registerField,
		resetCompaniesContactDetails
	}
)(ReduxForm);
