import {
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse
} from 'services/api/companies/companiesServiceTypes';
import { retrieveFeedbackManagementGridAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActiveCompanyId } from '../selectors';
import { select } from 'redux-saga/effects';

export default makeTakeLatestWatcher<
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse[],
	Error
>({
	api: Api.Companies.retrieveFeedbackManagementGrid,
	async: retrieveFeedbackManagementGridAsync,
	*getApiParams() {
		const companyId: string = yield select(getActiveCompanyId);
		return {
			companyId
		};
	}
});
