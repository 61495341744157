import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { destroy, isDirty } from 'redux-form';
import {
	ButtonRow,
	Error403,
	Flex,
	Loading,
	PageHeader,
	PageFooter,
	ScrollableLayout
} from 'components';
import { Justify } from 'components/types';
import { Button, Col, Modal, Row } from 'components/antd';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';
import SubHeader from '../SubHeader/SubHeaderWrapper';
import Form from '../CreatePortJob/Pages/Page3/Form/Form';
import { notify } from 'store/notifications/actions';
import { FORM } from '../CreatePortJob/createPortJobConstants';
import { EntityPermissionFetcher, AppNotifications } from 'containers';
import {
	editPortJobOperation,
	setActivePortJobCode,
	retrievePortJobSummary,
	cleanPortJobSummary,
	retrievePortJobOperation,
	resetPortJobOperation,
	resetPortJobs
} from 'store/portJobs/actions';
import {
	isPortJobOperationFetching,
	getEditableOperation,
	getIsPortJobOperationUpToDate,
	isPortJobUpdating
} from 'store/portJobs/portJobsSelectors';
import { PortJobOperationTypes } from 'services/api/portJobs/portJobsServiceTypes';
import {
	EntityPermissionType,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { getPortCallContext } from 'store/portCall/portCallSelectors';
import {
	isOperationTypeLoadingOrDischarge,
	isOperationFormValid
} from '../CreatePortJob/Pages/Page3/helpers';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { openModal, closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';

const REFRESH_MODAL = 'edit-operation-refresh';
interface PortJobRouteParams {
	portCallId: string;
	portJobCode: string;
	operationTabId: string;
}

interface EditOperationRouteProps
	extends RouteComponentProps<PortJobRouteParams> {
	isNewTab: boolean;
	destroy: typeof destroy;
	isPortJobOperationFetching: boolean;
	isFormDirty: boolean;
	operation: PortJobOperationTypes;
	notifySuccess: () => void;
	editPortJobOperation: () => void;
	setActivePortJobCode: () => void;
	retrievePortJobOperation: () => void;
	resetPortJobOperation: () => void;
	retrievePortJobSummary: () => void;
	cleanPortJobSummary: typeof cleanPortJobSummary;
	isLoading: boolean;
	isOperationFormOpen: boolean;
	isLoadingOrDischargeOperation: boolean;
	isRefreshModalVisible: boolean;
	isUpToDate: boolean;
	reset: () => void;
	openRefreshModal: () => void;
	refreshOperation: () => void;
	closeRefreshModal: () => void;
	resetPortJobs: () => void;
}

interface EditOperationRouteState {
	isCancelModalOpen: boolean;
	valid: boolean;
}

export class EditOperationRoute extends React.PureComponent<
	EditOperationRouteProps,
	EditOperationRouteState
> {
	constructor(props: EditOperationRouteProps) {
		super(props);
		this.state = {
			isCancelModalOpen: false,
			valid: false
		};
	}

	componentDidMount() {
		this.props.setActivePortJobCode();
		this.props.retrievePortJobSummary();
		this.props.retrievePortJobOperation();
	}

	componentWillUnmount() {
		this.props.destroy(FORM.portJob);
		this.props.destroy(FORM.cargoLine);
		this.props.resetPortJobOperation();
		this.props.cleanPortJobSummary();
		/**
		 * In order to avoid finding job by its code(when initial map has ids), we reset all.
		 */
		this.props.resetPortJobs();
	}

	onCloseTab = () => {
		this.onCancelCreationModalToggle();
	};

	onCancelCreationModalToggle = () => {
		this.setState({
			isCancelModalOpen: !this.state.isCancelModalOpen
		});
	};

	leavePortJobCreation = () => {
		if (this.props.isNewTab) {
			window.close();
		}
		if (this.props.history.action === 'PUSH') {
			this.props.history.goBack();
			return;
		}
		this.props.history.push(
			`/portcalls/${this.props.match.params.portCallId}/jobs/${this.props.match.params.portJobCode}/overview`
		);
	};

	onConfirmCancelCreation = () => {
		this.leavePortJobCreation();
		this.props.notifySuccess();
	};

	onFormChange = (formValid: boolean) => {
		const { isOperationFormOpen, isLoadingOrDischargeOperation } = this.props;
		const valid = isOperationFormValid({
			formValid,
			isOperationFormOpen,
			isLoadingOrDischargeOperation
		});
		this.setState({ valid });
	};

	onSaveClick = () => {
		this.props.editPortJobOperation();
	};

	onCancelClick = () => {
		if (this.props.isFormDirty) {
			this.onCancelCreationModalToggle();
		} else {
			this.onConfirmCancelCreation();
		}
	};

	render() {
		if (this.props.isPortJobOperationFetching || !this.props.operation) {
			return <Loading />;
		}
		const {
			isUpToDate,
			isRefreshModalVisible,
			refreshOperation,
			closeRefreshModal,
			openRefreshModal,
			isLoading
		} = this.props;
		return (
			<Flex fit direction="vertical" stretch>
				<PageHeader.NewTab
					onClose={this.onCloseTab}
					isNewTab={false}
					hidePopout
				>
					Edit Operation
				</PageHeader.NewTab>
				<ScrollableLayout>
					<AppNotifications.Alert />
					<AppNotifications.Notification />
					<SubHeader
						portCallId={this.props.match.params.portCallId}
						portJobCode={this.props.match.params.portJobCode}
					/>
					<ScrollableLayout asColumn>
						<ContextBar
							title="Please provide the details of the Operation"
							type={ContextBarType.ACTION}
							actor={[UserType.PRINCIPAL, UserType.HUB]}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
							subtitle="First select the Operation Type, and then fill out the other requested details"
							condition={true}
						/>
						<EntityPermissionFetcher
							entityType={EntityPermissionType.OPERATION}
							entityKey={this.props.match.params.operationTabId}
						>
							{({ canUpdate }) => (
								<>
									{canUpdate ? (
										<Form
											onFormStateChange={this.onFormChange}
											operationId={this.props.match.params.operationTabId}
											isInEditMode
										/>
									) : (
										<Error403 />
									)}
								</>
							)}
						</EntityPermissionFetcher>
					</ScrollableLayout>
					<Modal
						visible={isRefreshModalVisible}
						cancelText="Cancel"
						okText="Confirm"
						onOk={refreshOperation}
						onCancel={closeRefreshModal}
					>
						<p>All changes will be lost. Are you sure you want to continue?</p>
					</Modal>
					<PageFooter>
						<Row>
							<Col sm={12} justify={Justify.END}>
								<ButtonRow>
									{!isUpToDate && (
										<Button
											type="primary"
											transparent
											onClick={openRefreshModal}
										>
											Refresh
										</Button>
									)}
									<Button
										type="primary"
										transparent
										onClick={this.onCancelClick}
									>
										Cancel
									</Button>
									<MaybeTooltip
										placement="topLeft"
										show={!isUpToDate}
										title="This Operation has been updated by another user while you were editing it. You cannot save your changes due to that, please refresh the page."
									>
										<Button
											type={'primary'}
											onClick={this.onSaveClick}
											disabled={!this.state.valid || !isUpToDate}
											loading={isLoading}
										>
											Save
										</Button>
									</MaybeTooltip>
								</ButtonRow>
							</Col>
						</Row>
					</PageFooter>
				</ScrollableLayout>
				{this.state.isCancelModalOpen && (
					<Modal
						visible
						okText={'Confirm'}
						closable={false}
						onOk={this.onConfirmCancelCreation}
						onCancel={this.onCancelCreationModalToggle}
					>
						Are you sure you want to cancel edit operation process?
					</Modal>
				)}
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => {
		const operation = getEditableOperation(state);
		return {
			isPortJobOperationFetching: isPortJobOperationFetching(state),
			operation: getEditableOperation(state),
			isOperationFormOpen: !!getPortCallContext(state, 'operationFormVisible'),
			isLoadingOrDischargeOperation: operation
				? isOperationTypeLoadingOrDischarge(operation.code)
				: false,
			isUpToDate: getIsPortJobOperationUpToDate(state),
			isRefreshModalVisible: isModalVisible(state, REFRESH_MODAL),
			isFormDirty: isDirty(FORM.portJob)(state),
			isLoading: isPortJobUpdating(state)
		};
	},
	(
		dispatch,
		{
			match: {
				params: { portCallId, portJobCode, operationTabId }
			}
		}: EditOperationRouteProps
	) => ({
		destroy: (form: string) => {
			dispatch(destroy(form));
		},
		resetPortJobs: () => {
			dispatch(resetPortJobs());
		},
		notifySuccess: () => {
			dispatch(
				notify.success(
					'Operation editing process has been successfully cancelled.'
				)
			);
		},
		editPortJobOperation: () => {
			dispatch(
				editPortJobOperation({
					portCallId,
					jobCode: portJobCode,
					operationId: operationTabId
				})
			);
		},
		setActivePortJobCode: () => {
			dispatch(setActivePortJobCode(portJobCode));
		},
		retrievePortJobOperation: () => {
			dispatch(
				retrievePortJobOperation({
					portCallId,
					jobCode: portJobCode,
					operationId: operationTabId
				})
			);
		},
		resetPortJobOperation: () => {
			dispatch(resetPortJobOperation());
		},
		retrievePortJobSummary: () => {
			dispatch(
				retrievePortJobSummary({
					portCallId,
					jobCode: portJobCode
				})
			);
		},
		cleanPortJobSummary: () => {
			dispatch(cleanPortJobSummary());
		},
		openRefreshModal: () => {
			dispatch(openModal(REFRESH_MODAL));
		},
		refreshOperation: () => {
			dispatch(
				retrievePortJobOperation({
					portCallId,
					jobCode: portJobCode,
					operationId: operationTabId
				})
			);
			dispatch(closeModal(REFRESH_MODAL));
		},
		closeRefreshModal: () => {
			dispatch(closeModal(REFRESH_MODAL));
		}
	})
)(EditOperationRoute);
