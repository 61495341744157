import { getFileExtension } from 'utils';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import {
	getIsDone,
	getIsFailure,
	getIsLoaded,
	getIsLoading
} from 'store/selectors';
import { FileExtension } from 'app-constants';

export const getUploadState = (state: AppState) => state.documents.upload;

export const getIsDocumentProcessingLoading = createSelector(
	getUploadState,
	uploadState => getIsLoading(uploadState.fetchStatus)
);

export const getIsDocumentProcessingDone = createSelector(
	getUploadState,
	uploadState => getIsDone(uploadState.fetchStatus)
);

export const getIsDocumentProcessingDoneWithSuccess = createSelector(
	getUploadState,
	uploadState => getIsLoaded(uploadState.fetchStatus)
);

export const getIsDocumentProcessingDoneWithFailure = createSelector(
	getUploadState,
	uploadState => getIsFailure(uploadState.fetchStatus)
);

export const getIsDocumentProcessingFailureMessage = createSelector(
	getUploadState,
	uploadState => uploadState.statusTerminatedDescription
);

export const getUploadedFile = createSelector(
	getUploadState,
	({ file }) => file
);

export const getUploadedFileExtension = createSelector(
	getUploadedFile,
	file => {
		if (!file) {
			/** Return PDF by default and send user to contextualization process */
			return FileExtension.PDF;
		}
		return getFileExtension(file.name);
	}
);
