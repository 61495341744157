import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse
} from 'services/api/threads/threadsServiceTypes';

export const onRetrieveThreadsMainPrincipalGroupsSuccess = (
	state: ThreadsState,
	{
		result
	}: Success<
		RetrieveThreadsMainPrincipalTagsParam,
		RetrieveThreadsAvailableGroupsResponse
	>
): ThreadsState => ({
	...state,
	mainPrincipalThreadsGroups: result.elements
});

export const onRetrieveThreadsMainPrincipalGroups = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse
>('fetchStatuses', 'retrievingThreadsGroups', {
	doneReducer: onRetrieveThreadsMainPrincipalGroupsSuccess
});
