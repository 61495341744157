import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse
} from 'services/api/portCall/portCallServiceTypes';
import { flow, keyBy } from 'lodash';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';

type Vessels = RetrievePortCallCompanyVesselsResponse['elements'];

const createByIdVessels = (vessels: Vessels): CompanyVesselsState['byId'] =>
	flow(
		(elements: Vessels) =>
			elements.map(({ companyVesselDetails, companyId, companyName }) => ({
				...companyVesselDetails,
				companyId,
				companyName
			})),
		(elements: CompanyVessel[]) => keyBy(elements, ({ id }) => id)
	)(vessels);
const putVesselsIntoById = (vessels: Vessels) => (
	state: CompanyVesselsState
): CompanyVesselsState => ({
	...state,
	byId: {
		...state.byId,
		...createByIdVessels(vessels)
	}
});

const createIdList = (vessels: Vessels) =>
	vessels.map(({ companyVesselDetails: { id } }) => id);
const putVesselIdsInByPortCallId = (vessels: Vessels, portCallId: string) => (
	state: CompanyVesselsState
): CompanyVesselsState => ({
	...state,
	byPortCallId: {
		...state.byPortCallId,
		[portCallId]: createIdList(vessels)
	}
});

export const onRetrievePortCallCompanyVesselsDone = (
	state: CompanyVesselsState,
	portCallId: string,
	vessels: Vessels
): CompanyVesselsState =>
	flow(
		putVesselsIntoById(vessels),
		putVesselIdsInByPortCallId(vessels, portCallId)
	)(state);

export const onRetrievePortCallCompanyVessels = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: (
		state: CompanyVesselsState,
		{
			params: { portCallId },
			result: { elements }
		}: Success<
			RetrievePortCallCompanyVesselsRequest,
			RetrievePortCallCompanyVesselsResponse
		>
	): CompanyVesselsState =>
		onRetrievePortCallCompanyVesselsDone(state, portCallId, elements)
});
