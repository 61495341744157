import {
	ContextBarTitleCode,
	ContextBarSubtitleCode,
	ContextBarsType,
	ContextBarsActionCode
} from './contextBarsTypes';
import {
	moveToStage,
	revertJobStatus,
	toggleFinance
} from 'store/finance/actions';
import { FinanceStage } from 'services/api/finance/financeServiceTypes';
import { Dispatch } from 'redux';

export const contextBarsTitleMap = {
	[ContextBarTitleCode.FINANCE_AWAITING_APPROVAL]: `The finance is awaiting your approval`,
	[ContextBarTitleCode.JOB_FINANCE_BEING_APPROVED]: `Job finance is being approved`,
	[ContextBarTitleCode.DA_AWAITING_APPROVAL]: `The DA is awaiting your approval`,
	[ContextBarTitleCode.DA_BEING_APPROVED]: `The DA is being approved`,
	[ContextBarTitleCode.FINANCE_QUERIED_TO_LPA]: `The finance has been queried to the LPA`,
	[ContextBarTitleCode.YOU_QUERIED_FINANCE]: `You have queried the finance`,
	[ContextBarTitleCode.FINANCE_QUERY_SENT_TO_LPA]: `The finance query has been sent on to the LPA`,
	[ContextBarTitleCode.DA_QUERY_SENT_TO_LPA]: `The DA query has been sent on to the LPA`,
	[ContextBarTitleCode.YOU_QUERIED_DA]: `You have queried the DA`,
	[ContextBarTitleCode.DA_QUERIED_TO_LPA]: `The DA has been queried to the LPA`,
	[ContextBarTitleCode.CREATE_PDA]: `Please create your PDA`,
	[ContextBarTitleCode.FINANCE_VALIDATED_BY_HUB]: `Finance is being validated by the Hub`,
	[ContextBarTitleCode.VALIDATE_FINANCE]: `Please validate the finance`,
	[ContextBarTitleCode.CREATE_CE]: `Please create your Close Estimate`,
	[ContextBarTitleCode.CREATE_DA]: `Please create your DA`,
	[ContextBarTitleCode.WAITING_FINANCE_SUBMITTED_BY_LPA]: `We are waiting for finance to be submitted by the LPA`,
	[ContextBarTitleCode.FINANCE_WAITING_APPROVAL]: `The finance is currently waiting for approval`,
	[ContextBarTitleCode.PDA_CURRENTLY_WAITING_FOR_APPROVAL]: `The finance is currently waiting for approval`,
	[ContextBarTitleCode.CE_CURRENTLY_WAITING_FOR_APPROVAL]: `The finance is currently waiting for approval`,
	[ContextBarTitleCode.DA_CURRENTLY_WAITING_FOR_APPROVAL]: `The DA is currently waiting for approval`,
	[ContextBarTitleCode.FINANCE_APPROVED]: `The finance has been approved`,
	[ContextBarTitleCode.CE_APPROVED]: `Your CE has been approved`,
	[ContextBarTitleCode.DA_APPROVED]: `Your DA has been approved`,
	[ContextBarTitleCode.PROCESS_FUNDING_REQUEST]: `Process Funding request(s)`,
	[ContextBarTitleCode.SOME_ITEMS_QUERIED]: `Some items on your finance page have been queried`,
	[ContextBarTitleCode.FINANCE_QUERIED]: `The finance has been queried`,
	[ContextBarTitleCode.FINANCE_QUERIED_BY_CUSTOMER]: `Finance has been queried by the Customer`,
	[ContextBarTitleCode.ENABLE_FINANCE]: `Enable finance if needed`,
	[ContextBarTitleCode.DISABLE_FINANCE]: `Disable finance if needed`,
	[ContextBarTitleCode.AWAITING_PDA_APPROVAL]: `Awaiting PDA approval`
};

export const contextBarsSubtitleMap = {
	[ContextBarSubtitleCode.PROCESS_FIRST_LEVEL_OF_APPROVAL]: `According to your organisation's configuration, this job requires multi-level approval. Please process the first level of approval: review and approve all service lines, or query a line and we will get back to you. Remember to hit 'Submit' at the top when you're done.`,
	[ContextBarSubtitleCode.PROCESS_NTH_LEVEL_OF_APPROVAL]: `According to your organisation's configuration, this job requires multi-level approval. Initial approval has already been completed by {{ApprovalPrincipalNames}}. Please process the {{CurrentApprovalLevelOrdinal}} approval stage: review and approve all service lines, or query a line and we will get back to you. Remember to hit 'Submit' at the top when you're done.`,
	[ContextBarSubtitleCode.WAIT_FIRST_LEVEL_OF_APPROVAL]: `According to your organisation's configuration, this job requires multi-level approval. Please wait until the first level of approval is completed.`,
	[ContextBarSubtitleCode.WAIT_ALL_LEVEL_OF_APPROVAL]: `According to your organisation's configuration, this job requires multi-level approval. Please wait until all levels of approval are completed. Approval(s) have been completed by {{ApprovalPrincipalNames}}.`,
	[ContextBarSubtitleCode.YOU_COMPLETED_APPROVAL]: `You have completed the approval and finance has been moved to the next approval level. Optic will alert you if there will be any items requiring your attention.`,
	[ContextBarSubtitleCode.YOU_HAVE_ACTIVE_ITEMS_REQUIRING_ATTENTION]: `You have active items requiring your attention - please check and update their status.`,
	[ContextBarSubtitleCode.PLEASE_REVIEW_SERVICE_LINES_AND_APPROVE]: `Please review all service lines and approve if all good, or query and we will get back to you. Remember to hit 'Submit' when you are done!`,
	[ContextBarSubtitleCode.WE_WORKING_OUT_WITH_LPA_ON_FINANCE]: `We are just working out some wrinkles with the LPA to ensure the Finance is perfect. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.WE_RECEIVED_YOUR_QUERY]: `We have received your Query and are working as fast as we can to get back to you. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.WE_NEED_INPUT_FROM_LPA_ON_QUERY]: `We need some further input from the LPA on your query - we'll get back to you as soon as we have the information in hand. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.WE_WORKING_OUT_WITH_LPA_ON_DA]: `We are just working out some wrinkles with the LPA to ensure the DA is perfect. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.WE_NEED_INPUT_FROM_LPA_ON_QUERY_APPROVE_YOUR_DAS]: `We need some further input from the LPA on your query - we'll get back to you as soon as we have the information in hand. For now please make sure you approve the DAs requiring your attention. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.WE_RECEIVED_YOUR_QUERY_APPROVE_YOUR_DAS]: `We have received your Query and are working as fast as we can to get back to you. For now please make sure you approve the DAs requiring your attention. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.APPROVE_YOUR_DAS_AND_WAITING_FROM_LPA]: `Please make sure you approve the DAs requiring your attention. Also there are some items that need further input from the LPA before you can approve them - we'll get back to you as soon as we have the information in hand. Please remember that when all service queries have been resolved, those lines will need to pass through the approval process again.`,
	[ContextBarSubtitleCode.OPTIC_STARTED_BUILD_PDA]: `Optic has started to build this based on your vessel programme. Please add / remove services and submit this for approval ASAP.`,
	[ContextBarSubtitleCode.WE_RECEIVED_FINANCE_FROM_LPA]: `We have received finance from the LPA and are working to make sure it is just right.`,
	[ContextBarSubtitleCode.IF_YOU_NEED_CHANGED_HIT_LINK]: `If you need to make any changes, feel free to hit the "Request Assistance" link.`,
	[ContextBarSubtitleCode.OPTIC_HELPS_PRESENTING_PDA_AS_START_POINT]: `Optic helps by presenting your approved PDA as a start point. Please add / remove services as needed and submit ASAP.`,
	[ContextBarSubtitleCode.OPTIC_HELPS_PRESENTING_CE_AS_START_POINT]: `Optic helps by presenting your approved CE as a start point. Please add / remove services as required, and upload Supporting Documentation using the button at the top of the page. Once you are done, hit 'Submit' to send for approval!`,
	[ContextBarSubtitleCode.ONCE_FINANCE_SUBMITED_OPTIC_WILL_VALIDATE]: `Once finance is submitted, Optic will let you know to come and validate the costs.`,
	[ContextBarSubtitleCode.LPA_HASNT_SUBMITTED_FINANCE]: `The LPA hasn't submitted finance yet. Once they do, the Hub will validate this to make sure everything is as expected.`,
	[ContextBarSubtitleCode.NOTHING_TO_DO_HERE]: `There's nothing for you to do here. Optic will alert you if anything changes.`,
	[ContextBarSubtitleCode.YOU_CAN_UPDATE_AS_REQUIRED]: `You can update as required to reflect the current status of the Job. If you update a line item, you will need to re-submit your finance.`,
	[ContextBarSubtitleCode.YOU_CAN_UPDATE_AS_REQUIRED_OR_MOVE_TO_CE]: `You can update as required to reflect the current status of the Job or click "Move to CE" to continue`,
	[ContextBarSubtitleCode.CLICK_MOVE_TO_DA_OR_EDIT]: `Click "Move to DA" to continue or "Edit" if you would like to make a change and restart the CE approval process.`,
	[ContextBarSubtitleCode.NOTHING_TO_DO_HERE_AND_LINK_FOR_FINANCE_QUESTIONS]: `There's nothing for you to do here. If you have any questions about the finance, feel free to click "Request Assistance". We'll let you know if anything changes.`,
	[ContextBarSubtitleCode.IF_MORE_COSTS_NEEDED_SUBMIT_SUPPLEMENTAL_DA]: `If you need to submit any more costs, you can submit a Supplemental DA by raising the Services below.`,
	[ContextBarSubtitleCode.NOTHING_TO_DO_HERE_AND_LINK_FOR_DA_QUESTIONS]: `There's nothing for you to do here. If you have any questions about the DA, feel free to click "Request Assistance". We'll let you know if anything changes.`,
	[ContextBarSubtitleCode.FUNDING_REQUESTS_WAITING_REVIEW]: `There are funding requests waiting for review.  Please action accordingly.`,
	[ContextBarSubtitleCode.INFORM_AS_LPA_RESPONDS_QUERIES]: `We'll let you know as soon as the LPA responds to your queries or tolerance expires for this task.`,
	[ContextBarSubtitleCode.REVIEW_QUERIED_LINES_AND_RESPOND_TO_HUB]: `Please review the queried line items and respond to the Hub. We can't move forward without your input.`,
	[ContextBarSubtitleCode.INFORM_AS_LPA_RESPONDS_QUERIES_OR_TOLERANCE_EXPIRES]: `We'll let you know as soon as the LPA responds to your queries or tolerance expires for this task. Don't forget, we still owe the customer their response.`,
	[ContextBarSubtitleCode.REVIEW_QUERIED_LINES_AND_RESPOND_TO_PRINCIPAL_OR_LPA]: `Review the queried line items and respond to the customer, or send on to the LPA`,
	[ContextBarSubtitleCode.ENABLE_FINANCE_IF_NEEDED]: `Please enable finance for the job if you need to submit any costs for it`,
	[ContextBarSubtitleCode.DISABLE_FINANCE_IF_NEEED]: `Please disable finance for the job if you don't need to submit any costs for it`,
	[ContextBarSubtitleCode.AWAITING_PDA_APPROVAL]: `Optic requires PDA to be approved for this principal before the job can be moved to CE`
};

export const contextBarsTypeMap = {
	[ContextBarsType.ACTION]: 'warning',
	[ContextBarsType.CONFIRMATION]: 'success',
	[ContextBarsType.INFORMATION]: 'info'
};

export const contextBarsActionMap = {
	[ContextBarsActionCode.MOVE_TO_CE]: {
		type: 'link',
		actionText: 'Move to CE',
		action: (dispatch: Dispatch) =>
			dispatch(moveToStage({ stage: FinanceStage.CE }))
	},
	[ContextBarsActionCode.MOVE_TO_DA]: {
		type: 'button',
		actionText: 'Move to DA',
		action: (dispatch: Dispatch) =>
			dispatch(moveToStage({ stage: FinanceStage.DA }))
	},

	[ContextBarsActionCode.REVERT_FINANCE_STATUS]: {
		type: 'ghost',
		actionText: 'Edit',
		action: (dispatch: Dispatch) => dispatch(revertJobStatus())
	},

	[ContextBarsActionCode.ENABLE_FINANCE]: {
		type: 'button',
		actionText: 'Enable finance',
		action: (dispatch: Dispatch) =>
			dispatch(toggleFinance({ isFinanceEnabled: true }))
	},

	[ContextBarsActionCode.DISABLE_FINANCE]: {
		type: 'button',
		actionText: 'Disable finance',
		action: (dispatch: Dispatch) =>
			dispatch(toggleFinance({ isFinanceEnabled: false }))
	}
};
