import React, { useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UseModalContext, ModalContext } from 'context';
import { ModalContextValue } from 'context/ModalContext/ModalContext';
import AssignGroupModalForm, {
	AssignGroupModalFormProps
} from './AssignGroupModalForm';
import { getUpdatingFetchStatus } from 'store/thread/selectors';
import { ThreadModalName } from './AssignGroupModalTypes';
import { notify } from 'store/notifications/actions';

function AssignGroupModalWrapper(
	props: Omit<AssignGroupModalFormProps, 'hideModal' | 'modalSubmitting'>
) {
	const dispatch = useDispatch();
	const { hideModal, name, modalSubmitting } = useContext(UseModalContext);
	const fetchStatus = useSelector(getUpdatingFetchStatus);
	const submitResolveCallback = useCallback(
		({ hideModal }: ModalContextValue) => {
			// on success, send notification from here, considering that teh action used when submitting, is re-used for other situations.
			dispatch(notify.success('Assignment has been successfully updated.'));
			hideModal();
		},
		[dispatch]
	);
	return (
		<>
			{name === ThreadModalName.ASSIGN_GROUP && (
				<>
					<AssignGroupModalForm
						{...props}
						hideModal={hideModal}
						modalSubmitting={modalSubmitting}
					/>
					<ModalContext.SubmitResolve
						fetchStatus={fetchStatus}
						resolveCallback={submitResolveCallback}
					/>
				</>
			)}
		</>
	);
}

export default AssignGroupModalWrapper;
