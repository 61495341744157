import { UserType } from 'services/api/users/userServiceTypes';
import { createSelector } from 'reselect';
import { intersection, isEmpty } from 'lodash';
import { getUserType } from './userSelectors';

const liftToArray = (elm: any) => [].concat(elm); // eslint-disable-line @typescript-eslint/no-explicit-any

const rolesToUppercase = (elm: string | string[]) =>
	liftToArray(elm)
		.filter(role => role)
		.map((role: string) => role.toUpperCase());

const userRoleSelector = (acceptedRoles: string[]) =>
	createSelector(
		getUserType,
		roles =>
			!isEmpty(
				intersection(rolesToUppercase(acceptedRoles), rolesToUppercase(roles))
			)
	);

export const getIsCurrentUserPrincipal = userRoleSelector([UserType.PRINCIPAL]);
export const getIsCurrentUserLpa = userRoleSelector([UserType.LPA]);
export const getIsCurrentUserHub = userRoleSelector([UserType.HUB]);
