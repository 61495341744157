import React from 'react';
import { Button, Modal, Icon, Tooltip, MaybeTooltip } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import { closeModal, openModal } from 'store/modals/actions';
import { connect } from 'react-redux';
import { CommonActionModalProps } from '../ActionModalTypes';
import { AppointDataService } from 'services/api/portJobs/portJobsServiceTypes';
import {
	getPortJobServiceVendorsWithManyAvailableVendors,
	getIsActivePortJobCancelled,
	getIsAppointer,
	getCurrentMainPrincipalId,
	getIsDraftAppointer
} from 'store/portJobs/portJobsSelectors';
import {
	getIsPortJobControllingAgentDraft,
	getIsPortJobPerformingAgentDraft
} from 'store/portJobs/selectors/agentsSelectors';
import {
	inviteAgentToPortJob,
	setPortJobContext
} from 'store/portJobs/actions';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components';
import { getHasActivePortCallDuplicates } from 'store/portcalls/selectors';
import classNames from 'classnames';
import appointStyles from '../AppointToPortJobModal/AppointToPortJobModal.module.scss';
import { notify } from 'store/notifications/actions';
import ExtendedMessagingContainer from '../ExtendedMessagingModal/ExtendedMessagingContainer';
import { EXTENDED_MESSAGING_MODAL } from '../AppointToPortJobModal/AppointToPortJobModal';
import { EXTENDED_MAIL_TYPE } from '../../PortJobConstants';

interface InviteAgentModalProps extends CommonActionModalProps {
	// from mapStateToProps
	hasPortCallDuplicates: boolean;
	isInvitationDisabled: boolean;
	isAppointer: boolean;
	disabled: boolean;
	availableServices: AppointDataService[];
	hubPrincipleId: string | null;
	isExtendedMessagingModalVisible: boolean;
	isDraftAppointer: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	openModal: typeof openModal;
	inviteAgentToPortJob: typeof inviteAgentToPortJob;
	notifyError: typeof notify.error;
	setPortJobContext: typeof setPortJobContext;
}

interface InviteAgentButtonProps {
	disabled: boolean;
	onInvite?: () => void;
}

interface InviteAgentModalState {
	isWarningVisible: boolean;
}

export const InviteAgentButton: React.FC<InviteAgentButtonProps> = (
	props: InviteAgentButtonProps
) => (
	<Button type="primary" disabled={props.disabled} onClick={props.onInvite}>
		Invite Agent
	</Button>
);

export class InviteAgentModal extends React.PureComponent<
	InviteAgentModalProps,
	InviteAgentModalState
> {
	constructor(props: InviteAgentModalProps) {
		super(props);

		this.state = {
			isWarningVisible: false
		};
	}
	componentWillUnmount() {
		this.closeModal(EXTENDED_MESSAGING_MODAL);
	}
	openModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.currentTarget.blur();
		this.props.openModal({ name: EXTENDED_MESSAGING_MODAL });
	};
	closeModal = (modalName: string) =>
		this.props.closeModal({ name: modalName });

	closeExtendedMessagingModal = () => {
		this.closeModal(EXTENDED_MESSAGING_MODAL);
		const { portCallId, jobCode, modalId } = this.props;
		this.props.inviteAgentToPortJob({
			portCallId,
			jobCode,
			modalId
		});
	};
	handleInvite = () => {
		const { portCallId, jobCode, modalId } = this.props;
		this.props.setPortJobContext({
			isAgentInvited: true
		});
		this.props.inviteAgentToPortJob({
			portCallId,
			jobCode,
			modalId
		});
	};

	onServiceVendorSelect = () => {
		/*Code to be added for direct bills*/
	};

	render() {
		const {
			isInvitationDisabled,
			hasPortCallDuplicates,
			isAppointer,
			disabled,
			isDraftAppointer,
			isExtendedMessagingModalVisible
		} = this.props;
		return (
			<>
				<Permission permissionCode={PermissionCode.APPOINT_AGENT}>
					{hasPermission =>
						hasPermission && (
							<>
								{hasPortCallDuplicates && (
									<Tooltip
										title="Please resolve potential problem with duplicate port calls before you can appoint the agent"
										placement="bottom"
									>
										<Icon type="info-circle" color="primary" valign="middle" />
									</Tooltip>
								)}
								<MaybeTooltip
									placement="left"
									show={isInvitationDisabled && !hasPortCallDuplicates}
									title={`The draft Agent can't be appointed to a Job. Please wait until the draft is converted or choose an existing Agent.`}
								>
									<span
										className={classNames({
											[appointStyles.buttonWrapper]: isInvitationDisabled
										})}
									>
										<Button
											type="primary"
											onClick={e => this.openModal(e)}
											disabled={
												disabled ||
												isInvitationDisabled ||
												!isAppointer ||
												isDraftAppointer
											}
											tooltip={
												isDraftAppointer
													? {
															title:
																'The draft appointer cannot be used to invite agent job. Please wait until the draft is converted or choose an existing appointer.',
															placement: 'bottomLeft'
													  }
													: !isAppointer
													? {
															title:
																'Please wait until Appointer information is provided',
															placement: 'bottomLeft'
													  }
													: undefined
											}
										>
											Invite Agent
										</Button>
									</span>
								</MaybeTooltip>
							</>
						)
					}
				</Permission>
				<Modal
					destroyOnClose
					title="Job details will be sent to"
					visible={isExtendedMessagingModalVisible}
					onClose={() => {
						this.setState({ isWarningVisible: true });
					}}
					width={1366}
					fullSizeModal
					footer={null}
					className={appointStyles.extendedMessagingModal}
				>
					<ExtendedMessagingContainer
						closeExtendedMessagingModal={this.closeExtendedMessagingModal}
						mailType={EXTENDED_MAIL_TYPE.INVITATION_EMAIL}
					/>
				</Modal>
				<Modal
					okText="Yes"
					cancelText="No"
					visible={this.state.isWarningVisible}
					closable={false}
					onCancel={() => this.setState({ isWarningVisible: false })}
					onOk={() => {
						this.setState({
							isWarningVisible: false
						});
						this.closeModal(EXTENDED_MESSAGING_MODAL);
					}}
				>
					The Invite job process will be cancelled. Do you want to continue?
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState) => {
		const hasPortCallDuplicates = getHasActivePortCallDuplicates(state);
		return {
			availableServices: getPortJobServiceVendorsWithManyAvailableVendors(
				state
			),
			isInvitationDisabled:
				getIsPortJobPerformingAgentDraft(state) ||
				getIsPortJobControllingAgentDraft(state) ||
				hasPortCallDuplicates,
			hasPortCallDuplicates,
			isAppointer: getIsAppointer(state),
			isDraftAppointer: getIsDraftAppointer(state),
			disabled: getIsActivePortJobCancelled(state),
			hubPrincipleId: getCurrentMainPrincipalId(state),
			isExtendedMessagingModalVisible: isModalVisible(
				state,
				EXTENDED_MESSAGING_MODAL
			)
		};
	},
	{
		closeModal,
		openModal,
		inviteAgentToPortJob,
		notifyError: notify.error,
		setPortJobContext
	}
)(InviteAgentModal);
