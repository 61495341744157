import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { UnlinkJobsRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { unlinkJobsAsync } from 'store/portJobs/actions';
import { retrievePortCall } from 'store/portcalls/actions';
import {
	getActivePortJobCode,
	getIsLeadNominationJob,
	getLinkedAppJobCodes
} from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { closeModal } from 'store/modals/actions';
import { notify } from 'store/notifications/actions';
import { navigateTo } from 'utils';
import { getActivePortJobParentPortCallId } from 'store/portJobs/selectors';

const apiCall = Api.PortJobs.unlinkJobs;

export const executor = function*(payload: UnlinkJobsRequest): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJob = yield select(getActivePortJobCode);
	const isMutipleLinkedLeadNominationJobs = yield select(
		getIsLeadNominationJob
	);
	const linkedAppJobCode = yield select(getLinkedAppJobCodes);
	const request = {
		...payload,
		portCallId,
		portJob,
		isMutipleLinkedLeadNominationJobs,
		linkedAppJobCode: linkedAppJobCode[0]
	};
	yield put(unlinkJobsAsync.started(request));
	try {
		yield call(apiCall, request);
		const parentPortCallId = yield select(getActivePortJobParentPortCallId);

		if (parentPortCallId) {
			yield call(
				navigateTo,
				`/portcalls/${parentPortCallId}/jobs/${portJob}/overview`
			);
		} else {
			yield put(
				retrievePortCall({
					id: portCallId,
					expand: 'PortJobsBasic'
				})
			);
		}
		yield put(notify.success('Jobs have been unlinked successfully.'));
	} catch (error) {
		yield put(
			unlinkJobsAsync.failed({
				error,
				params: request
			})
		);
	} finally {
		yield put(closeModal(payload.modalId));
	}
};

export const unlinkJobsWatcher = makeTakeLatestWatcher<
	UnlinkJobsRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async: unlinkJobsAsync
	},
	executor
);

export default unlinkJobsWatcher;
