import { Success } from 'typescript-fsa';
import { put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { patchThreadPermissionsAsync } from './../actions/patchThreadPermissions';
import {
	PatchThreadPermissionsRequest as Request,
	PatchThreadPermissionsResponse as Response
} from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';
import { retrieveThreadById, retrieveThreadEvents } from 'store/thread/actions';

/**
 * Update the thread permission list
 */
export default makeTakeLatestWatcher<Request, Response, Error>({
	api: Api.Threads.patchThreadPermissions,
	async: patchThreadPermissionsAsync,
	*onSuccess(action: Success<Request, Response>) {
		yield put(retrieveThreadById({ threadId: action.params.threadId }));
		/**
         * Get the list of events with the new one
         */
		yield put(
			retrieveThreadEvents({
				threadId: action.params.threadId,
				retrieveLatest: true
			})
		);
	}
});
