import { all, fork } from 'redux-saga/effects';
import retrieveCompanySaga from './retrieveCompanySaga';
import retrieveCompaniesSaga from './retrieveCompaniesSaga';
import retrieveCompaniesForUserSaga from './retrieveCompaniesForUserSaga';
import retrieveCompaniesContactDetailsSaga from './retrieveCompaniesContactDetailsSaga';
import retrieveCompanyGeneralConfigurationWatcher from './retrieveCompanyGeneralConfigurationSaga';
import updateCompanyGeneralConfigurationWatcher from './updateCompanyGeneralConfigurationSaga';
import retrieveCompanyGeneralConfigurationReportsWatcher from './retrieveCompanyGeneralConfigurationReportsSaga';
import uploadCompanyPreferredAgentsFileWatcher from './uploadCompanyPreferredAgentsFileSaga';
import retrieveCompanyPreferredAgentsFileInfoWatcher from './retrieveCompanyPreferredAgentsFileInfoSaga';
import retrieveCompaniesConfigurationSettingsWatcher from './retrieveCompaniesConfigurationSettingsSaga';
import retrievePrincipalsForMainPrincipalWatcher from './retrievePrincipalsForMainPrincipalSaga';
import retrieveCompanyBenchmarkWatcher from './retrieveCompanyBenchmarkSaga';
import retrieveCompanyJobTypes from './retrieveCompanyJobTypesSaga';

export default function*() {
	yield all([
		fork(retrieveCompanySaga),
		fork(retrieveCompaniesSaga),
		fork(retrieveCompaniesForUserSaga),
		fork(retrieveCompaniesContactDetailsSaga),
		fork(retrieveCompanyGeneralConfigurationWatcher),
		fork(updateCompanyGeneralConfigurationWatcher),
		fork(retrieveCompanyGeneralConfigurationReportsWatcher),
		fork(uploadCompanyPreferredAgentsFileWatcher),
		fork(retrieveCompanyPreferredAgentsFileInfoWatcher),
		fork(retrieveCompaniesConfigurationSettingsWatcher),
		fork(retrievePrincipalsForMainPrincipalWatcher),
		fork(retrieveCompanyBenchmarkWatcher),
		fork(retrieveCompanyJobTypes)
	]);
}
