import { call, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { DownloadSupplierInvoiceDocumentParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { downloadSupplierInvoiceAsync as async } from './../actions';
import Api from 'services/api';
import { getDownloadUrlExecutor } from 'store/documents/sagas/pollDownloadUrlSaga';

export const downloadExecutor = function*(
	params: DownloadSupplierInvoiceDocumentParams
) {
	/**
	 * Since this document doesn't need to be generated on the fly
	 * only a GET call is enough to get the `downloadUrl
	 */
	const GETApi = params.serviceId
		? Api.SupplierInvoice.pollDownloadForServiceSupplierInvoiceDocument
		: Api.SupplierInvoice.pollDownloadSupplierInvoiceDocument;

	yield put(async.started(params));

	try {
		const result = yield call(getDownloadUrlExecutor, {
			GETApi,
			params
		});
		yield put(
			async.done({
				result,
				params,
				response: null
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params
			})
		);
	}
};

export default makeTakeLatestWatcher<
	DownloadSupplierInvoiceDocumentParams,
	RetrieveDownloadDocumentResponse,
	Error
>({ async }, downloadExecutor);
