import React from 'react';
import { Row, Col } from 'components/antd';
import {
	BankAccount,
	BankAddress
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Title, LabeledText, Content } from 'components';
import styles from './BankAccountDetails.module.scss';

interface GeneralInformationProps {
	bank: BankAccount;
}
const GeneralInformation = (props: GeneralInformationProps) => {
	const { label, bankName, address = {} as BankAddress } = props.bank;
	return (
		<>
			<Title>GENERAL INFORMATION</Title>
			<Content>
				<Row className={styles.row}>
					<Col xs={12} lg={12}>
						<LabeledText label="Label" value={label} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="Bank Name" value={bankName} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Address Line 2" value={address.addressLine2} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Address Line 4" value={address.addressLine4} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Post/ZIP Code" value={address.postalCode} />
					</Col>
				</Row>
				<Row className={styles.row}>
					<Col xs={3} lg={2}>
						<LabeledText label="Address Line 1" value={address.addressLine1} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Address Line 3" value={address.addressLine3} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="City" value={address.city} />
					</Col>
					<Col xs={3} lg={2}>
						<LabeledText label="Country" value={address.countryCode} />
					</Col>
				</Row>
			</Content>
			<hr />
		</>
	);
};

export default GeneralInformation;
