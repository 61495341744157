import { call } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	CancelCreditHubRevenuePayload,
	CancelCreditHubRevenueRequest
} from 'services/api/finance/financeServiceTypes';
import { cancelCreditHubRevenueAsync } from '../actions';
import getActivePortCallAndPortJobIdsExecutor, {
	RetrievePortCallAndPortJobCode
} from 'utils/sagaHelpers/portCallSagaExecutor';

const api = Api.Finance.cancelCreditHubRevenue;

export default makeTakeEveryWatcher<CancelCreditHubRevenueRequest, null, Error>(
	{
		api,
		async: cancelCreditHubRevenueAsync,
		*getApiParams({ jobServiceId }: CancelCreditHubRevenuePayload) {
			const portCallAndPortJobCode: RetrievePortCallAndPortJobCode = yield call(
				getActivePortCallAndPortJobIdsExecutor
			);

			return {
				...portCallAndPortJobCode,
				jobServiceId
			};
		}
	}
);
