import actionCreator from 'store/thread/threadActionCreator';
import { UpdateTagsRequest } from 'services/api/threads/threadsServiceTypes';

const UPDATE_TAGS = 'UPDATE_TAGS';

export const updateTagsAsync = actionCreator.async<
	UpdateTagsRequest,
	null,
	Error
>(UPDATE_TAGS);

export const updateTags = actionCreator<UpdateTagsRequest>(UPDATE_TAGS);
