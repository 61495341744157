import { FinanceState } from '../financeState';
import {
	RetrieveAvailableServicesRequest,
	RetrieveAvailableServicesResponse
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveAvailableServices = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveAvailableServicesRequest,
	RetrieveAvailableServicesResponse
>('fetchStatuses', 'retrieveAvailableServices', {
	doneReducer: (
		state: FinanceState,
		action: Success<
			RetrieveAvailableServicesRequest,
			RetrieveAvailableServicesResponse
		>
	) => {
		const { sectionCode, entityId = '' } = action.params;
		return {
			...state,
			context: {
				...state.context,
				availableServices: {
					...state.context.availableServices,
					[`${sectionCode}-${entityId}`]: action.result.elements
				}
			}
		};
	}
});
