import { retrieveCompanyPreferredAgentsFileInfoAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse,
	Error
>({
	async,
	api: Api.Companies.retrieveCompanyPreferredAgentsFileInfo
});
