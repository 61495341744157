import { call, put } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveFinanceDocumentAsync } from '../actions';
import {
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse
} from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import getActivePortCallAndPortJobIdsExecutor, {
	RetrievePortCallAndPortJobCodeResponse
} from 'utils/sagaHelpers/portCallSagaExecutor';
import { fullPage } from 'store/notifications/actions';
import { get } from 'lodash';

export default makeTakeEveryWatcher<
	RetrieveFinanceDocumentRequest,
	RetrieveFinanceDocumentResponse,
	Error
>({
	api: Api.Finance.retrieveFinanceDocument,
	async: retrieveFinanceDocumentAsync,
	*getApiParams(params: RetrieveFinanceDocumentRequest) {
		const portCallIdAndJobCode: RetrievePortCallAndPortJobCodeResponse = yield call(
			getActivePortCallAndPortJobIdsExecutor,
			params
		);
		return {
			...portCallIdAndJobCode,
			...params
		};
	},
	*onError(_params, error) {
		const status = get(error, 'response.status') || 'UNHANDLED';
		const validationMessageData = get(error, 'response.data');

		yield put(
			fullPage({
				error: {
					status: status,
					correlationId: validationMessageData?.correlationId
				},
				description: validationMessageData?.error
			})
		);
	}
});
