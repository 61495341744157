import React from 'react';
import { connect } from 'react-redux';
import { Gap } from 'components';
import { Modal } from 'components/antd';
import PortCallDetails from '../PortCallDetails';
import { AppState } from 'store-types';
import {
	getActivePortCall,
	getMergePortCallsContext,
	getActivePortCallFetchStatus,
	getPortCallDuplicate,
	getPortCallDuplicatesFetchStatus
} from 'store/portcalls/selectors';
import { MergePortCallsContext } from 'store/portcalls/portCallsState';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';

interface MergePortCallsModalProps {
	currentPortCallId: string;
	masterPortCallId: string;
	isModalVisible: boolean;
	onCancel: () => void;
	onOk: () => void;
	// from mapStateToProps
	portCall: PortCall;
	portCallFetchStatus: FetchStatus;
	portCallDuplicate: PortCall;
	portCallDuplicateFetchStatus: FetchStatus;
	mergePortCallsContext: MergePortCallsContext;
}

const MergePortCallsModal = ({
	isModalVisible,
	onCancel,
	onOk,
	portCall,
	portCallFetchStatus,
	portCallDuplicate,
	portCallDuplicateFetchStatus
}: MergePortCallsModalProps) => (
	<Modal
		visible={isModalVisible}
		title="Merging Port Call"
		cancelText="Cancel"
		okText="Merge Port Calls"
		width={550}
		onCancel={onCancel}
		onOk={onOk}
	>
		By merging port calls the details current port call will be ignored and
		details from master port call will be applied. All jobs and messages will be
		moved without any changes.
		<LoadingPlaceholder fetchStatus={portCallFetchStatus}>
			{() => (
				<Gap top="md" isBlock outside={false}>
					<PortCallDetails portCall={portCall} titlePrefix="Current" />
				</Gap>
			)}
		</LoadingPlaceholder>
		<LoadingPlaceholder fetchStatus={portCallDuplicateFetchStatus}>
			{() => (
				<Gap top="md" bottom="md" isBlock outside={false}>
					<PortCallDetails portCall={portCallDuplicate} titlePrefix="Master" />
				</Gap>
			)}
		</LoadingPlaceholder>
	</Modal>
);

export default connect(
	(
		state: AppState,
		{
			currentPortCallId,
			masterPortCallId
		}: Pick<MergePortCallsModalProps, 'currentPortCallId' | 'masterPortCallId'>
	) => ({
		portCall: getActivePortCall(state),
		portCallFetchStatus: getActivePortCallFetchStatus(state),
		portCallDuplicate: getPortCallDuplicate(
			state,
			currentPortCallId,
			masterPortCallId
		),
		portCallDuplicateFetchStatus: getPortCallDuplicatesFetchStatus(state),
		mergePortCallsContext: getMergePortCallsContext(state)
	})
)(MergePortCallsModal);
