import { retrievePortCallBunkeringMeasurementsAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallBunkeringMeasurementsRequest,
	RetrievePortCallBunkeringMeasurementsResponse
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallBunkeringMeasurementsRequest,
	RetrievePortCallBunkeringMeasurementsResponse,
	Error
>({
	api: Api.PortCallMeasurements.retrievePortCallBunkeringMeasurements,
	async
});
