import actionCreator from '../portCallsActionCreator';

const CYCLE_START_ACTION = 'RETRIEVE_PORT_CALL_CYCLE_START';

const CYCLE_STOP_ACTION = 'RETRIEVE_PORT_CALL_CYCLE_STOP';

export const retrievePortCallCycleStart = actionCreator<string>(
	CYCLE_START_ACTION
);

export const retrievePortCallCycleStop = actionCreator(CYCLE_STOP_ACTION);
