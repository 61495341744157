import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import { AllocateOperationUnitParams } from 'store/vesselProgramme/actions';
import {
	getPreviousAllocatedRotationStep,
	getEventsAfterAllocation,
	getOperationUnitAfterAllocation
} from './normalization';

export const onAllocateOperationUnit = (
	{
		rotationStepsPositionedToCustodyLine,
		rotationStepsById,
		draggedOperationUnitId,
		operationUnitsById,
		eventsById,
		...state
	}: VesselProgrammeState,
	{ stepPosition }: AllocateOperationUnitParams
): VesselProgrammeState => {
	const custodyLineRotationStep =
		rotationStepsPositionedToCustodyLine[stepPosition];
	const rotationStepId = custodyLineRotationStep.rotationStepId;
	const rotationStep = rotationStepsById[rotationStepId];
	const rotationStepMapped = rotationStep;
	if (!rotationStep.units?.includes(draggedOperationUnitId)) {
		rotationStepMapped.units = [...rotationStep.units, draggedOperationUnitId];
	}

	return {
		...state,
		edited: true,
		rotationStepsPositionedToCustodyLine,
		rotationStepsById: {
			...rotationStepsById,
			...getPreviousAllocatedRotationStep(
				operationUnitsById,
				draggedOperationUnitId,
				rotationStepsById
			),
			[rotationStepId]: rotationStepMapped
		},
		draggedOperationUnitId: '',
		eventsById: getEventsAfterAllocation(
			operationUnitsById,
			draggedOperationUnitId,
			eventsById
		),
		operationUnitsById: {
			...operationUnitsById,
			[draggedOperationUnitId]: getOperationUnitAfterAllocation(
				operationUnitsById,
				draggedOperationUnitId,
				rotationStepId,
				custodyLineRotationStep
			)
		}
	};
};
