import {
	RetrievePortCallDocumentRequest,
	RetrievePortCallDocumentResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { Success } from 'typescript-fsa';
import { PortCallDocumentsState } from 'store/portCallDocuments/portCallDocumentsState';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrievePortCallDocumentStarted = (
	state: PortCallDocumentsState,
	{ isSilent }: RetrievePortCallDocumentRequest
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: isSilent
		? state.fetchStatuses
		: {
				...state.fetchStatuses,
				retrieveDocument: FetchStatus.IDLE
			}
});

export const onRetrievePortCallDocumentSuccess = (
	state: PortCallDocumentsState,
	action: Success<
		RetrievePortCallDocumentRequest,
		RetrievePortCallDocumentResponse
	>
): PortCallDocumentsState => ({
	...state,
	document: action.result,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveDocument: FetchStatus.SUCCESS
	}
});

export const onRetrievePortCallDocumentFailed = (
	state: PortCallDocumentsState
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveDocument: FetchStatus.FAILURE
	},
	document: null
});
