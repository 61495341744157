import { call } from 'redux-saga/effects';
import Api from 'services/api';
import {
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse
} from 'services/api/threads/threadsServiceTypes';

import { makeTakeLatestWatcher, makeDefaultExecutor } from 'utils/sagaHelpers';
import { retrieveThreadContextsAsync } from 'store/threads/actions';

export const defaultExecutor = makeDefaultExecutor<
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse,
	Error
>({ api: Api.Threads.retrieveContexts, async: retrieveThreadContextsAsync });

const executor = function*(params: RetrieveThreadContextsRequest) {
	const payload: RetrieveThreadContextsRequest = {
		onlyActive: true,
		includeInternal: false,
		...params
	};

	yield call(defaultExecutor, payload);
};

export const retrievePortJobOperationWatcher = makeTakeLatestWatcher<
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse,
	Error
>(
	{ api: Api.Threads.retrieveContexts, async: retrieveThreadContextsAsync },
	executor
);

export default retrievePortJobOperationWatcher;
