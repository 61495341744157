import actionCreator from '../portCallMeasurementsActionCreator';
import {
	UpdateBunkeringOperationGradeRequest,
	UpdateBunkeringOperationGradeActionPayload
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'UPDATE_BUNKERING_OPERATION_GRADE';
export const updateBunkeringOperationGradeAsync = actionCreator.async<
	UpdateBunkeringOperationGradeRequest,
	null,
	Error
>(ACTION_NAME);

export const updateBunkeringOperationGrade = actionCreator<
	UpdateBunkeringOperationGradeActionPayload
>(ACTION_NAME);
