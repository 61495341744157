import * as React from 'react';

import DocumentViewerPreview from './DocumentViewerPreview/DocumentViewerPreview';
import DocumentViewerAside from './DocumentViewerAside/DocumentViewerAside';
import DocumentViewerPreviewWrapper from './DocumentViewerPreview/DocumentViewerPreviewWrapper';
import {
	BrowserDocumentPreviewContainer,
	DocumentBrowserViewerCommonProps
} from 'sections/DocumentBrowserPageViewer';

import {
	PaginationState,
	DocumentViewerPaginationProps,
	DocumentViewerAndAsideCommonProps
} from './DocumentViewerTypes';
import { UploadFile } from 'antd/lib/upload/interface';

// interface DocumentViewerContentProps extends DocumentViewerProps {
interface DocumentViewerContentProps
	extends DocumentViewerAndAsideCommonProps,
		Partial<DocumentViewerPaginationProps>,
		DocumentBrowserViewerCommonProps {
	documentId: string;
	returnUrl: string;
	onBeforeLeave?: () => void;
	file?: UploadFile;
}

export default class DocumentViewerContent extends React.Component<
	DocumentViewerContentProps
> {
	getPaginationProps = () => {
		const {
			total,
			activePage,
			activePageState,
			activePageFirst,
			activePageLast,
			setActivePage,
			pagination,
			prevPage,
			nextPage,
			okButtonProps,
			cancelButtonProps
		} = this.props;

		const props = {
			total,
			activePage,
			activePageState,
			activePageFirst,
			activePageLast,
			setActivePage,
			pagination,
			prevPage,
			nextPage,
			okButtonProps,
			cancelButtonProps
		};

		if (
			this.props.preventSplit ||
			!props.pagination ||
			(!props.pagination && !!total && total < 1)
		) {
			props.pagination = PaginationState.NONE;
		}
		return props;
	};

	public render() {
		const {
			documentId,
			returnUrl,
			loading,
			isDocumentPDF,
			onFinish,
			canSubmit,
			hint,
			preventSplit,
			activePage = 0,
			children,
			file,
			fileDocument
		} = this.props;

		const paginationProps = this.getPaginationProps();

		return (
			<>
				<DocumentViewerPreviewWrapper>
					{!preventSplit && documentId && !file ? (
						<DocumentViewerPreview
							activePage={activePage}
							documentId={documentId}
							isDocumentPDF={isDocumentPDF}
							preventSplit={preventSplit}
							returnUrl={returnUrl}
						/>
					) : (
						<BrowserDocumentPreviewContainer
							returnUrl={returnUrl}
							file={file}
							fileDocument={fileDocument}
						/>
					)}
				</DocumentViewerPreviewWrapper>
				<DocumentViewerAside
					{...paginationProps}
					hint={hint}
					loading={loading}
					isDocumentPDF={isDocumentPDF}
					preventSplit={preventSplit}
					onFinish={onFinish}
					onCancel={this.props.onBeforeLeave}
					canSubmit={Boolean(canSubmit)}
				>
					{children}
				</DocumentViewerAside>
			</>
		);
	}
}
