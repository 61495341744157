import { Success, Failure } from 'typescript-fsa';
import { map } from 'lodash';
import {
	buildRotationStepsById,
	buildPositionedRotationSteps,
	buildCustodyTransfer,
	buildAllocatedUnits,
	buildSplitUnitsByParentUnitId,
	buildEventsById,
	buildUnitsById,
	buildAllocatedUnitsWithFullEvents
} from './normalization';
import {
	RetrieveVesselProgramme,
	RetrieveVesselProgrammeRequest
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import { PortCallError } from 'store/portcalls/portCallsState';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveVPStarted(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: false
		},
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.PENDING
		}
	};
}

/**
 * TODO: We need to split this reducer
 */
export function onRetrieveVPSuccess(
	state: VesselProgrammeState,
	action: Success<RetrieveVesselProgrammeRequest, RetrieveVesselProgramme>
): VesselProgrammeState {
	const {
		result: {
			rotationSteps,
			updatedOn,
			updatedOnPlt,
			concurrencyToken,
			custodyAgents = [],
			currentAgentRole,
			lastRevision,
			revisions,
			allRevisions,
			isReady
		},
		params: { portCallId }
	} = action;
	const metadata = action.result.metadata;
	const unallocatedUnits = action.result.unallocatedUnits;
	const rotationStepsById = buildRotationStepsById(rotationSteps);
	const orderedRotationSteps: string[] = map(rotationSteps, step => step.id);
	const custodyTransfer = buildCustodyTransfer(rotationSteps);
	const rotationStepsPositionedToCustodyLine = buildPositionedRotationSteps(
		orderedRotationSteps,
		custodyTransfer
	);
	const allocatedUnits = buildAllocatedUnits(rotationSteps);
	const allocatedUnitsWithEvents = buildAllocatedUnitsWithFullEvents(
		rotationSteps
	);
	const splittedUnitsByParentUnitId = buildSplitUnitsByParentUnitId(
		unallocatedUnits,
		allocatedUnits
	);
	const operationUnitsById = buildUnitsById(unallocatedUnits, allocatedUnits);
	const eventsById = buildEventsById(
		rotationSteps,
		unallocatedUnits,
		allocatedUnitsWithEvents
	);
	return {
		...state,
		portCallId,
		edited: false,
		updatedOn,
		updatedOnPlt,
		operationUnitsById,
		rotationStepsById,
		orderedRotationSteps,
		eventsById,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.SUCCESS
		},
		splittedUnitsByParentUnitId,
		custodyAgents,
		currentAgentRole,
		custodyTransfer,
		concurrencyToken,
		lastRevision,
		revisions,
		allRevisions,
		rotationStepsPositionedToCustodyLine,
		metadata,
		isReady
	};
}

export function onRetrieveVPFailed(
	state: VesselProgrammeState,
	action: Failure<RetrieveVesselProgrammeRequest, PortCallError>
): VesselProgrammeState {
	return {
		...state,
		context: {
			...state.context,
			showErrorPage: action.error.response.status === 404
		},
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.FAILURE
		}
	};
}
