import React from 'react';
import Amount, { AmountProps } from 'components/Amount/Amount';
import { getFinanceCurrencyDecimalPart } from 'store/finance/selectors';
import { AppState } from 'store-types';
import { connect } from 'react-redux';

interface FinanceAmountProps extends AmountProps {
	className?: string;
	currencyCode: string;
	negative?: boolean;
	hasCurrencyLabel?: boolean;
	dataQa?: string; // for QAA
	// from mapStateToProps
	decimalPart: number;
	signed?: boolean;
}

const FinanceAmount = (props: FinanceAmountProps) => {
	const { hasCurrencyLabel = false, className, dataQa } = props;

	return (
		<span className={className} data-qa={dataQa}>
			<Amount
				{...props}
				formatType="finance"
				uomCode={hasCurrencyLabel ? props.currencyCode : undefined}
				decimalPart={props.decimalPart}
			/>
		</span>
	);
};

export default connect(
	(state: AppState, props: Pick<FinanceAmountProps, 'currencyCode'>) => ({
		decimalPart: getFinanceCurrencyDecimalPart(state, props.currencyCode)
	})
)(FinanceAmount);
