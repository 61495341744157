import React, { Component } from 'react';
import { Gap, Title } from 'components';
import { Row, Col, Radio, Modal } from 'components/antd';
import {
	WebHookConfigOption,
	DeveloperPortalFelids,
	DEVELOPER_PORTAL_FORM
} from '../constants';
import {
	InjectedFormProps,
	reduxForm,
	resetSection,
	EventWithDataHandler
} from 'redux-form';
import { WebHookDetailsTable } from './WebHookDetailsTable';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import {
	DeveloperPortalData,
	WebhookEndPointDetails,
	WebhookEndPointDetailsWithLabel
} from 'services/api/developerPortal/developerPortalServiceTypes';
import { getValues } from 'store/form/selectors';
import { findIndex, isEqual } from 'lodash';
import {
	getEditingWebHookId,
	getDeveloperPortalValue
} from 'store/developerPortal/selectors';
import { setEditingWebHook } from 'store/developerPortal/actions';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

interface WebHookDetailsBaseProps {
	formValues: DeveloperPortalData;
	initialFormValues: DeveloperPortalData | null;
	editingWebhookId: string;
	setEditingWebHook: typeof setEditingWebHook;
	resetSection: typeof resetSection;
}

interface WebHookDetailsState {
	isWarningModalVisible: boolean;
}

interface WebHookDetailsProps
	extends InjectedFormProps<DeveloperPortalData, WebHookDetailsBaseProps>,
		WebHookDetailsBaseProps {}

class WebHookDetails extends Component<
	WebHookDetailsProps,
	WebHookDetailsState
> {
	constructor(props: WebHookDetailsProps) {
		super(props);
		this.state = {
			isWarningModalVisible: false
		};
	}
	componentWillUnmount() {
		this.props.setEditingWebHook('');
	}
	onSubmit = (webHook: WebhookEndPointDetailsWithLabel) => {
		const {
			array,
			formValues,
			editingWebhookId,
			setEditingWebHook
		} = this.props;
		const updatedWebHookDetails: WebhookEndPointDetails = {
			...webHook,
			events: webHook.eventsWithLabels.map(event => event.key)
		};
		if (!editingWebhookId) {
			array.push(
				DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE,
				updatedWebHookDetails
			);
		} else {
			const id = editingWebhookId;
			const index = findIndex(formValues.webhookEndPointResponse, { id });
			array.splice(
				DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE,
				index,
				1,
				updatedWebHookDetails
			);
			setEditingWebHook('');
		}
	};
	onDelete = (id: string) => {
		const { array, formValues } = this.props;
		const index = findIndex(formValues.webhookEndPointResponse, { id });
		array.remove(DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE, index);
	};
	onOk = () => {
		this.setState({ isWarningModalVisible: false });
		this.props.change(DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE, []);
	};
	onCancel = () => {
		this.setState({ isWarningModalVisible: false });
		this.props.change(DeveloperPortalFelids.IS_WEB_HOOK_END_POINT, 'yes');
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange: EventWithDataHandler<React.ChangeEvent<any>> &
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		((e: RadioChangeEvent) => void) = (event: any) => {
		const {
			formValues: { webhookEndPointResponse }
		} = this.props;
		if (event.target.value === WebHookConfigOption.NO) {
			if (
				!isEqual(
					webhookEndPointResponse,
					this.props.initialFormValues?.webhookEndPointResponse
				)
			) {
				this.setState({ isWarningModalVisible: true });
			} else {
				this.props.change(
					DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE,
					[]
				);
			}
		} else {
			this.props.resetSection(
				DEVELOPER_PORTAL_FORM,
				DeveloperPortalFelids.WEB_HOOK_END_POINT_RESPONSE
			);
		}
	};
	render() {
		const {
			formValues: { isWebhookEndPoint }
		} = this.props;
		return (
			<>
				<Gap all="md" isBlock>
					<Row>
						<Title>WEB-HOOK CONFIGURATION DETAILS</Title>
					</Row>
					<Row>
						<Col sm={12}>
							<Gap top="md" isBlock>
								<Radio.Group.ReduxFormItem
									label="Do you want to configure Web-hook end point?"
									defaultValue={WebHookConfigOption.NO}
									name={DeveloperPortalFelids.IS_WEB_HOOK_END_POINT}
									labelCol={{ span: 10, lg: { span: 6 } }}
									wrapperCol={{ span: 14, lg: { span: 8 } }}
									onChange={this.onChange}
								>
									<Radio value={WebHookConfigOption.YES}>Yes</Radio>
									<Radio value={WebHookConfigOption.NO}>No</Radio>
								</Radio.Group.ReduxFormItem>
							</Gap>
						</Col>
					</Row>
					{isWebhookEndPoint === WebHookConfigOption.YES && (
						<WebHookDetailsTable
							fields={this.props.formValues.webhookEndPointResponse}
							onSubmit={this.onSubmit}
							onDelete={this.onDelete}
						/>
					)}
				</Gap>
				<Modal
					visible={this.state.isWarningModalVisible}
					okText="Continue"
					onOk={this.onOk}
					onCancel={this.onCancel}
					closable={false}
				>
					Are you sure you want to change the webhook configuration? If you
					continue the action, your unsaved data will be lost.
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		formValues: getValues<DeveloperPortalData>(state, DEVELOPER_PORTAL_FORM),
		initialFormValues: getDeveloperPortalValue(state),
		editingWebhookId: getEditingWebHookId(state)
	}),
	{ setEditingWebHook, resetSection }
)(
	reduxForm<DeveloperPortalData, WebHookDetailsBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: DEVELOPER_PORTAL_FORM
	})(WebHookDetails)
);
