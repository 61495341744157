import actionCreator from 'store/portJobs/portJobsActionCreator';
import {
	PortJobSummaryRequest,
	PortJobSummaryResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTJOB_SUMMARY';

export const retrievePortJobSummaryAsync = actionCreator.async<
	PortJobSummaryRequest,
	PortJobSummaryResponse,
	Error
>(ACTION_NAME);

export const retrievePortJobSummary = actionCreator<PortJobSummaryRequest>(
	ACTION_NAME
);

const CLEAN_PORTJOB_SUMMARY = 'CLEAN_PORTJOB_SUMMARY';

export const cleanPortJobSummary = actionCreator(CLEAN_PORTJOB_SUMMARY);
