import {
	IInternalComment,
	InternalCommentCallFailure,
	RetrieveInternalCommentsResponse
} from 'services/api/threads/threadsServiceTypes';
import { InternalCommentsState } from '../internalCommentsState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';
export const onRetrieveInternalCommentsStarted = (
	state: InternalCommentsState
): InternalCommentsState => ({
	...state,
	isFetching: FetchStatus.PENDING
});
export const onRetrieveInternalCommentsFailed = (
	state: InternalCommentsState,
	action: InternalCommentCallFailure
): InternalCommentsState => ({
	...state,
	isFetching: FetchStatus.FAILURE,
	error: action.error
});
export const onRetrieveInternalCommentsDone = (
	state: InternalCommentsState,
	action: Success<string, RetrieveInternalCommentsResponse>
): InternalCommentsState => {
	const threadId = action.params;
	return {
		...state,
		isFetching: FetchStatus.SUCCESS,
		threads: {
			...state.threads,
			[threadId]: (state.threads[threadId] || [])
				.concat(action.result.elements)
				.reduce<IInternalComment[]>((all, cur) => {
					if (!all.find(e => e.id === cur.id)) {
						all.push(cur);
					}
					return all;
				}, [])
		}
	};
};
