import React from 'react';
import { DataFetcher } from 'utils/components';
import { FormattedTimeExtended } from 'components';
import { connect } from 'react-redux';
import { retrievePortById } from 'store/masterdata';
import { AppState } from 'store-types';
import { getActivePortTimeZoneExtended } from 'store/masterdata/ports/selectors';

/**
 * TODO: We need this component only for PortCallInfo, please remove it in case we don't need to retrieve data about port
 */
interface PortCallFormattedTimeProps {
	value: string | null;
	portId: string;
	convertFromUtc?: boolean;
	// from mapStateToProps
	portTimeZoneId: string;
	fetchPort: () => {};
}

function PortCallFormattedTime(props: PortCallFormattedTimeProps) {
	return (
		<DataFetcher
			data={props.portTimeZoneId || props.convertFromUtc ? {} : undefined}
			dispatch={props.fetchPort}
			showLoader={false}
			skipIfHasData
		>
			{() => <FormattedTimeExtended {...props} />}
		</DataFetcher>
	);
}

export default connect(
	(state: AppState, ownProps: Pick<PortCallFormattedTimeProps, 'portId'>) => {
		const { portId = '' } = ownProps;
		return {
			portTimeZoneId: getActivePortTimeZoneExtended(state, portId) || ''
		};
	},
	(dispatch, ownProps: Pick<PortCallFormattedTimeProps, 'portId'>) => {
		const { portId } = ownProps;
		return {
			fetchPort: () => {
				if (portId) {
					dispatch(retrievePortById({ port: portId }));
				}
			}
		};
	}
)(PortCallFormattedTime);
