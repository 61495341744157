import * as React from 'react';
import classNames from 'classnames';
import styles from './TagList.module.scss';

interface TagListProps {}

const TagList: React.FC<TagListProps> = props => {
	return <div className={classNames(styles.root)}>{props.children}</div>;
};

export default TagList;
