import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSupplierInvoice } from 'store/supplierInvoice/actions';
import { DocumentViewerWizardForm } from 'sections/DocumentViewer';
import { PATHS } from 'sections/App/RouteParams';
import { DataFetcher } from 'utils/components';
import ContextualizationForm from '../AddNewSupplierInvoiceRoute/ContextualizationForm/ContextualizationForm';
import ContextualizationHint from '../AddNewSupplierInvoiceRoute/ContextualizationHint';
import { useActiveSupplierInvoice } from '../../SupplierInvoice.hooks';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';

export interface AddSupplierInvoiceViaPostCreditProps {
	supplierInvoiceId: string;
	documentId: string;
}

const AddSupplierInvoiceViaPostCredit: React.FC<AddSupplierInvoiceViaPostCreditProps> = props => {
	const dispatch = useDispatch();

	const { creating, loading } = useActiveSupplierInvoice(
		props.supplierInvoiceId,
		true
	);
	const [canSubmit, updateCanSubmit] = useState(true);

	const onSubmit = () => {
		dispatch(
			createSupplierInvoice({
				documentId: props.documentId,
				originalSupplierInvoiceId: props.supplierInvoiceId
			})
		);
	};

	return (
		<DocumentViewerWizardForm
			documentId={props.documentId}
			title="Upload supplier invoice"
			returnUrl={`/${PATHS.supplierinvoices}`}
			loading={creating}
			onSubmit={onSubmit}
			canSubmit={canSubmit}
			hint={<ContextualizationHint />}
			pagination={PaginationState.ENABLED}
		>
			{({
				total,
				activePage,
				onValidChange: onPageValidChange,
				onResetWizardForm
			}) => (
				<DataFetcher isLoading={loading}>
					{() => (
						<ContextualizationForm
							pages={total}
							activePage={activePage}
							onValidChange={updateCanSubmit} //this valid change to update the supplier redux form
							onPageValidChange={onPageValidChange} // this valid change to set true for the page in document viewer wizard form
							onResetWizardForm={onResetWizardForm}
							isTriggeredViaPostCredit
						/>
					)}
				</DataFetcher>
			)}
		</DocumentViewerWizardForm>
	);
};

export default AddSupplierInvoiceViaPostCredit;
