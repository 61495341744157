import React from 'react';
import { connect } from 'react-redux';
import { PageFooter } from 'components';
import { Justify } from 'components/types';
import { Row, Col, Button } from 'components/antd';
import { ButtonRow } from 'components/ButtonRow/ButtonRow';
import styles from '../LinkJob.module.scss';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import { AppState } from 'store-types';
import {
	getActivePortJobCode,
	getLinkedJobCodeFromActiveJob
} from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	getLinkJobButtonStatus,
	getLinkedAppointmentCargos,
	getNominationCargos,
	getUpdatedLinkedJobCargosFethStatus
} from 'store/linkJob/selectors/linkJobSelectors';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import { saveUpdatedLinkedCargos } from 'store/linkJob/actions';
import { FetchStatus } from 'services/api/apiTypes';

interface LinkCargoFooterProps {
	onCancel: () => void;
	isLinkCargoDisabled: boolean;
	isUpdatedLinkCargo: FetchStatus;
	activePortJobCode: string;
	activePortCallId: string;
	linkedJobCode: string;
	linkedNominationCargos: LinkedCargo[];
	linkedAppointmentCargos: LinkedCargo[];
	saveUpdatedLinkedCargos: typeof saveUpdatedLinkedCargos;
}

class LinkCargoFooter extends React.Component<LinkCargoFooterProps> {
	private onFinish = () => {
		this.props.saveUpdatedLinkedCargos({
			portCallId: this.props.activePortCallId,
			portJob: this.props.activePortJobCode,
			request: {
				portCallId: this.props.activePortCallId,
				portJobCode: this.props.activePortJobCode,
				linkedAppJobCode: this.props.linkedJobCode,
				nominationCargos: this.props.linkedNominationCargos,
				appointmentCargos: this.props.linkedAppointmentCargos
			}
		});
	};

	render() {
		const { isLinkCargoDisabled, isUpdatedLinkCargo, onCancel } = this.props;
		const tooltip: TooltipProps | undefined = isLinkCargoDisabled
			? {
					title: 'Please link all cargoes',
					placement: 'topRight'
			  }
			: undefined;

		return (
			<PageFooter>
				<Row>
					<Col xs={12} justify={Justify.END}>
						<ButtonRow>
							<Button
								type="primary"
								transparent
								onClick={onCancel}
								className={styles.cancelButton}
							>
								Cancel
							</Button>
							<Button
								type={'primary'}
								onClick={this.onFinish}
								disabled={
									isLinkCargoDisabled ||
									isUpdatedLinkCargo === FetchStatus.PENDING ||
										isUpdatedLinkCargo === FetchStatus.SUCCESS
								}
								tooltip={tooltip}
							>
								Link Cargo
							</Button>
						</ButtonRow>
					</Col>
				</Row>
			</PageFooter>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isLinkCargoDisabled: getLinkJobButtonStatus(state),
		activePortJobCode: getActivePortJobCode(state),
		activePortCallId: getActivePortCallId(state),
		linkedJobCode: getLinkedJobCodeFromActiveJob(state),
		linkedNominationCargos: getNominationCargos(state),
		linkedAppointmentCargos: getLinkedAppointmentCargos(state),
		isUpdatedLinkCargo: getUpdatedLinkedJobCargosFethStatus(state)
	}),
	{ saveUpdatedLinkedCargos }
)(LinkCargoFooter);
