import React, { ReactNode } from 'react';
import styles from '../SearchablePopover.module.scss';
import { noop } from 'lodash';
import { List } from 'components/antd';
import { SearchablePopoverDefaultItem as Item } from '../searchablePopoverTypes';
import { ListProps } from 'antd/lib/list';

interface SearchablePopoverListProps {
	data: Item[];
	bordered: boolean;
	renderItem: (item: Item) => ReactNode;
	onSelect: (item: Item) => void;
	listLocaleProps?: ListProps<Item>['locale'];
}

function SearchablePopoverList(props: SearchablePopoverListProps) {
	const {
		data,
		bordered = true,
		renderItem,
		onSelect = noop,
		listLocaleProps
	} = props;
	return (
		<List
			className={styles.list}
			dataSource={data}
			locale={listLocaleProps}
			renderItem={(item: Item) => (
				<div onClick={() => onSelect(item)}>
					<List.Item>{renderItem ? renderItem(item) : item}</List.Item>
				</div>
			)}
			bordered={bordered}
			size="small"
			data-list
		/>
	);
}

export default SearchablePopoverList;
