import { SagaIterator } from 'redux-saga';
import { all, put, call } from 'redux-saga/effects';
import { retrieveThreadEvents, retrieveThreadById } from '../actions';
import { retrieveInternalComments } from 'store/internalComments';
// generator is getting all the data needed for thread also related entities
export function* retrieveThreadWithSubDataById(threadId: string): SagaIterator {
	yield put(retrieveThreadById({ threadId }));
	yield call(retrieveThreadSubData, threadId);
}

export function* retrieveThreadSubData(threadId: string) {
	yield all([
		put(retrieveThreadEvents({ threadId, retrieveLatest: true })),
		put(retrieveInternalComments(threadId))
	]);
}
