import { ConfigurationFeedbackManagementData } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'UPDATE_CONFIGURATION_FEEDBACK_MANAGEMENT';
export const updateConfigurationFeedbackManagement = actionCreator(ACTION_NAME);
export const updateConfigurationFeedbackManagementAsync = actionCreator.async<
	null,
	ConfigurationFeedbackManagementData,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Data'
	}
});
