import { Success, Failure } from 'typescript-fsa';
import {
	UpdatePortCallEventRequest,
	UpdateEventResponse
} from 'services/api/portCall/portCallServiceTypes';
import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import { FetchStatus } from 'services/api/apiTypes';
import { AxiosError } from 'axios';
import { values } from 'lodash';
import { isHandledCode } from 'utils';

const getSetUpdateStatuses = (
	state: PortCallOperationsState,
	eventId: string,
	status: FetchStatus
) => {
	return {
		...state.updateStatuses,
		events: {
			...state.updateStatuses.events,
			[eventId]: status
		}
	};
};

export const onUpdatePortCallEventStarted = (
	state: PortCallOperationsState,
	{ eventId }: UpdatePortCallEventRequest
) => {
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		FetchStatus.PENDING
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdatePortCallEventSuccess = (
	state: PortCallOperationsState,
	action: Success<UpdatePortCallEventRequest, UpdateEventResponse>
) => {
	const { eventId, realDatePlt, comment, portCallId } = action.params;
	const { status } = action.result;
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		FetchStatus.SUCCESS
	);

	const currentEvent =
		state.eventSectionsByPortCallId[portCallId].eventsById[eventId];
	const event = {
		...currentEvent,
		status,
		datePlt: realDatePlt,
		comment,
		error:
			currentEvent && currentEvent.datePlt === realDatePlt
				? currentEvent.error
				: null
	};

	return {
		...state,
		context: {
			...state.context,
			lastUpdatedEventId: eventId
		},
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: event
				}
			}
		},
		updateStatuses
	};
};

const getErrorMessage = (
	action: Failure<UpdatePortCallEventRequest, AxiosError>
) =>
	values(
		action.error.response ? action.error.response.data : action.error
	).join(' ');

export const onUpdatePortCallEventFailed = (
	state: PortCallOperationsState,
	action: Failure<UpdatePortCallEventRequest, AxiosError>
) => {
	const { eventId, portCallId } = action.params;
	const error = getErrorMessage(action);
	const status = (action.error.response && action.error.response.status) || 0;
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		isHandledCode(status, [400])
			? FetchStatus.FAILURE
			: state.updateStatuses.events[eventId]
	);
	const skipErrorObject = !isHandledCode(status, [400]);

	const currentEvent =
		state.eventSectionsByPortCallId[portCallId].eventsById[eventId];
	const event = {
		...currentEvent,
		error: (skipErrorObject && {}) || error
	};

	return {
		...state,
		context: {
			...state.context,
			lastUpdatedEventId: eventId
		},
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: event
				}
			}
		},
		updateStatuses,
		error
	};
};
