import { updateCompanyGeneralConfigurationAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { UpdateCompanyGeneralConfigurationRequest } from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	UpdateCompanyGeneralConfigurationRequest,
	null,
	Error
>({
	api: Api.Companies.updateCompanyGeneralConfiguration,
	async: updateCompanyGeneralConfigurationAsync
});
