import * as React from 'react';
import { Flex, Gap, Link } from 'components';
import { Button } from 'components/antd';
import styles from '../TopNavBar.module.scss';
import Logo from 'components/Logo/Logo';

export default ({
	showLogo,
	showMenu,
	onToggleSidebar
}: {
	showLogo: boolean;
	showMenu: boolean;
	onToggleSidebar(): void;
}) => {
	return (
		<Flex shrink={0}>
			{showMenu ? (
				<Button
					type="primary"
					transparent
					icon="bars"
					iconSize={14}
					className={styles.toggleButton}
					onClick={onToggleSidebar}
				/>
			) : (
				<Gap right />
			)}
			{showLogo ? (
				<Link href="/">
					<Logo />
				</Link>
			) : null}
		</Flex>
	);
};
