import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { Flex, PageHeader, ScrollableLayout } from 'components';
import { AppNotifications } from 'containers';
import { BreadcrumbItem } from 'store/navigation';
import { CancelLinkCargoModal } from './CancelLinkCargoModal';
import { CANCEL_LINK_CARGO_MODAL } from '../constants';
import { AppState } from 'store-types';
import { notify } from 'store/notifications/actions';
import { closeModal, openModal } from 'store/modals/actions';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { isModalVisible } from 'store/modals/selectors';
import { FetchStatus } from 'services/api/apiTypes';
import { DataFetcher } from 'utils/components';
import { navigateTo } from 'utils';
import Page2 from '../Pages/Page2/Page2';
import {
	resetLinkJob,
	retrievelinkedAppointmentJob
} from 'store/linkJob/actions';
import { getLinkedAppointmentJodFetchStatus } from 'store/linkJob/selectors/linkJobSelectors';
import LinkCargoFooter from './LinkCargoFooter';
import { setPortJobContext } from 'store/portJobs/actions';

interface RouteParams {
	portCallId: string;
	portJobCode: string;
	newtab?: string;
}

const getBreadcrumbs = (
	breadcrumbs: BreadcrumbItem[],
	portCallId: string
): BreadcrumbItem[] => {
	if (!breadcrumbs) {
		return [];
	}
	const link = `/portcalls/${portCallId}/overview`;
	return [
		...breadcrumbs.slice(0, -1),
		{ title: 'Overview', link },
		{ title: 'Link cargo' }
	];
};

interface LinkJobRouteProps extends RouteComponentProps<RouteParams> {
	// from mapStateToProps
	appointmentJobFetchStatus: FetchStatus;
	breadcrumbs: BreadcrumbItem[];
	isCancelModalVisible: boolean;
	// from mapDispatchToProps
	initializeData: () => void;
	openCancelModal: () => void;
	onCancelConfirmed: (isConfirmed: boolean) => void;
	onUnmount: () => void;
}

class LinkCargoRoute extends React.Component<LinkJobRouteProps> {
	componentWillUnmount() {
		this.props.onUnmount();
	}
	render() {
		const {
			match: {
				params: { newtab }
			},
			appointmentJobFetchStatus,
			breadcrumbs,
			isCancelModalVisible,
			initializeData,
			openCancelModal,
			onCancelConfirmed
		} = this.props;

		return (
			<DataFetcher
				dispatch={initializeData}
				fetchStatus={appointmentJobFetchStatus}
			>
				{() => (
					<Flex fit direction="vertical" stretch>
						<RouteBreadcrumbs items={breadcrumbs} />
						<PageHeader.NewTab
							isNewTab={Boolean(newtab)}
							onClose={openCancelModal}
							shouldNewTabClose={false}
						>
							Link Cargo
						</PageHeader.NewTab>
						<AppNotifications.Alert />
						<AppNotifications.Notification />
						<ScrollableLayout>
							<Page2 />
							<LinkCargoFooter onCancel={openCancelModal}></LinkCargoFooter>
						</ScrollableLayout>
						<CancelLinkCargoModal
							isModalVisible={isCancelModalVisible}
							onOk={() => onCancelConfirmed(true)}
							onCancel={() => onCancelConfirmed(false)}
						/>
					</Flex>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			match: {
				params: { portCallId }
			}
		}: Pick<LinkJobRouteProps, 'match'>
	) => {
		const breadcrumbs = getPortCallLayoutBreadcrumbs(state);
		return {
			appointmentJobFetchStatus: getLinkedAppointmentJodFetchStatus(state),
			breadcrumbs: getBreadcrumbs(breadcrumbs, portCallId),
			isCancelModalVisible: isModalVisible(state, CANCEL_LINK_CARGO_MODAL)
		};
	},
	(
		dispatch,
		{
			match: {
				params: { portCallId, portJobCode, newtab }
			}
		}: Pick<LinkJobRouteProps, 'match'>
	) => ({
		initializeData: () => {
			dispatch(
				retrievelinkedAppointmentJob({ portCallId, jobCode: portJobCode })
			);
		},
		openCancelModal: () => {
			dispatch(openModal(CANCEL_LINK_CARGO_MODAL));
		},
		onCancelConfirmed: (isConfirmed?: boolean) => {
			dispatch(closeModal(CANCEL_LINK_CARGO_MODAL));
			if (isConfirmed) {
				dispatch(
					notify.success(
						'Nomination job link cargo process has been successfully cancelled'
					)
				);
				newtab
					? window.close()
					: navigateTo(`/portcalls/${portCallId}/jobs/${portJobCode}/overview`);
			}
		},
		onUnmount: () => {
			dispatch(resetLinkJob());
			dispatch(
				setPortJobContext({
					selectedLinkJobPortCallId: '',
					selectedLinkJobCode: '',
					isForLinkCargoProcess: false
				})
			);
		}
	})
)(LinkCargoRoute);
