import { put, call, fork, take } from 'redux-saga/effects';
import { navigateTo } from 'utils';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { ExecutorConfig } from 'utils/sagaHelpers/sagaHelpers';

import { openModal, closeModal } from 'store/modals/actions';

interface DeleteDocumentPageWorkerConfig<P, S, E>
	extends ExecutorConfig<P, S, E> {
	count: number;
	returnUrl: string;
	params: P;
}
function* deleteDocumentPageWorker<P, S, E>(
	config: DeleteDocumentPageWorkerConfig<P, S, E>
) {
	const { async, api, count, returnUrl, params } = config;

	const executor = makeDefaultExecutor<P, S, E>({
		api,
		async,
		*onSuccess() {
			// if count was equal to 1 before making the request,
			// it means that there are no pages left by this moment
			if (count === 1) {
				yield call(navigateTo, returnUrl);
			}
		}
	});

	if (count === 1) {
		yield put(
			openModal({
				name: 'deleteDocumentPage',
				type: 'confirm',
				data: {
					title: 'Delete File',
					description:
						'Deleting this page will permanently delete the uploaded document. Are you sure you want to continue?'
				}
			})
		);

		const {
			payload: { isConfirmed }
		} = yield take(closeModal.type);

		if (isConfirmed) {
			// Proceed with deletion
			yield fork(executor, params);
		}
	} else {
		yield fork(executor, params);
	}
}

export { deleteDocumentPageWorker };
