import Api from 'services/api';
import { deleteDAAsync } from '../actions/deleteDA';
import {
	DeleteDAActionPayload,
	DeleteDARequest,
	DaType
} from 'services/api/finance/financeServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	call,
	takeLatest,
	all,
	fork,
	put,
	take,
	select
} from 'redux-saga/effects';
import { openModal, closeModal } from 'store/modals/actions';
import { closeAddDAModal } from 'store/finance/actions/addDA';

import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';

const apiCall = Api.Finance.deleteDA;

export function* executor(
	actionParams: DeleteDAActionPayload,
	api: typeof apiCall
): SagaIterator {
	yield put(deleteDAAsync.started(actionParams));
	try {
		yield put(
			openModal({
				name: 'deleteDA',
				type: 'confirm',
				data: {
					title: 'Delete DA',
					description: 'Are you sure you want to delete this DA?'
				}
			})
		);

		const { payload } = yield take(closeModal.type);
		if (!payload.isConfirmed) {
			deleteDAAsync.failed({
				error: new Error('Canceled'),
				params: actionParams
			});
			return;
		}

		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		const response = yield call(api, {
			id: actionParams.id,
			portCallId,
			jobCode
		} as DeleteDARequest);
		yield put(
			deleteDAAsync.done({
				result: null,
				params: actionParams,
				response
			})
		);
		yield put(closeAddDAModal(DaType.OUT));
	} catch (error) {
		yield put(
			deleteDAAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<DeleteDAActionPayload>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(deleteDAAsync.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
