import {
	VesselProgrammeState,
	UpdateAnchorageRequest
} from '../vesselProgrammeState';

export const onUpdateAnchorage = (
	state: VesselProgrammeState,
	{ rotationStepId, anchorage }: UpdateAnchorageRequest
): VesselProgrammeState => ({
	...state,
	edited: true,
	rotationStepsById:
		rotationStepId in state.rotationStepsById
			? {
					...state.rotationStepsById,
					[rotationStepId]: {
						...state.rotationStepsById[rotationStepId],
						anchorage,
						poiId:
							anchorage?.id ?? state.rotationStepsById[rotationStepId].poiId
					}
			  }
			: state.rotationStepsById
});
