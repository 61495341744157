import { AppState } from 'store-types';
import {
	PortJob,
	PortJobAgencyRoles,
	PortJobAgencyTypes,
	PortJobMetadataActionCode,
	PortJobSummaryResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { EditPortJobFormData } from 'store/portJobs/sagas/editPortJobData/editPortJobDataTypes';
import {
	nameToLabelField,
	stringToLabelField
} from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest.func';
import { isEmpty } from 'lodash';
import { MetadataStatusCode } from 'app-constants';
import { createSelector } from 'reselect';
import { getActivePortJob, getPortJobsMetadata } from '../portJobsSelectors';
import { StaticItem } from 'store/portcalls/portCallsState';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

export const getRetrieveEditPortJobDataFetchStatus = (state: AppState) =>
	state.portjob.fetchStatuses.retrieveEditPortJobData;

const codeFromName = (items: StaticItem[], name: string) =>
	(items.find(item => item.name === name) || { code: name }).code;

export const parsePortJobIntoForm = (
	portJob: PortJob,
	portJobSummary: PortJobSummaryResponse,
	agentTypes: StaticItem[],
	agentRoles: StaticItem[]
): Partial<EditPortJobFormData> => {
	const agencyType = {
		key: codeFromName(agentTypes, portJob.agentType),
		label: portJob.agentType as PortJobAgencyTypes,
		title: portJob.agentType
	};
	const agentRole = {
		key: codeFromName(agentRoles, portJob.agentRole),
		label: portJob.agentRole as PortJobAgencyRoles,
		title: portJob.agentRole
	};

	return {
		// Page1FormValues
		hubPrincipalCompany: portJob.hubPrincipalCompany
			? nameToLabelField(portJob.hubPrincipalCompany)
			: undefined,
		portCall: {
			vessel: nameToLabelField(portJobSummary.portCallSummary.vessel),
			port: nameToLabelField(portJobSummary.portCallSummary.port),
			etaPlt: portJobSummary.portCallSummary.eta,
			etsPlt: portJobSummary.portCallSummary.ets
				? portJobSummary.portCallSummary.ets
				: ''
		},

		// Page2FormValues
		jobTypeId: stringToLabelField(portJob.jobType),
		appointerCompany: portJob.appointerCompany
			? nameToLabelField(portJob.appointerCompany)
			: undefined,
		customerRef: portJob.customerReference,
		contact1: portJob.contact1 ? nameToLabelField(portJob.contact1) : undefined,
		contact2: portJob.contact2 ? nameToLabelField(portJob.contact2) : undefined,
		agencyType,
		performingAgent: nameToLabelField(portJob.performingAgentCompany),
		agentRole,
		nominator: portJob.nominatorCompany
			? nameToLabelField(portJob.nominatorCompany)
			: undefined,
		payingparty: portJob.payingPartyCompany
			? nameToLabelField(portJob.payingPartyCompany)
			: undefined,
		controllingAgent: portJob.controllingAgentCompany
			? nameToLabelField(portJob.controllingAgentCompany)
			: undefined,
		instructionToISS: portJob.instructionToISS,
		instructionToLPA: portJob.instructionToLPA
	};
};

export const getSwapAgentsAccessInfo = createSelector(
	getActivePortJob,
	getPortJobsMetadata,
	(portJob, metadata) => {
		const result = {
			status: false,
			messageInfo: ''
		};
		if (isEmpty(portJob)) {
			return result;
		}
		const portJobCode = portJob.code;
		if (isEmpty(metadata[portJobCode])) {
			return result;
		}

		const actions = metadata[portJobCode].actions;
		const action = getEntityMetadataAction<PortJobMetadataActionCode>(
			actions,
			PortJobMetadataActionCode.SWAP_AGENTS
		);
		if (!action) {
			return result;
		}
		if (action.statusCode === MetadataStatusCode.ENABLED) {
			result.status = true;
		} else if (action.statusCode === MetadataStatusCode.DISABLED) {
			result.messageInfo = `Agents can't be swapped as the Performing Agent has received prefunding`;
		}
		return result;
	}
);
