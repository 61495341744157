import Api from 'services/api';
import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveFundingActionParams,
	retrieveFundingRequestAsync as async
} from '../actions/retrieveFundingRequest';
import {
	RetrieveFundingRequestRequest as Request,
	RetrieveFundingRequestResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.FinanceFundings.retrieveFinanceFundingRequest;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*getApiParams(params: RetrieveFundingActionParams) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		return {
			...params,
			portCallId,
			jobCode
		};
	}
});
