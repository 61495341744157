import { FinanceFundingFormField } from 'store/form/financeFundings/financeFundingTypes';
import { BaseAmountTypeCodes } from 'services/api/financeFundings/financeFundingsServiceTypes';

export const getDaInAmount = (
	daInAmount: number,
	daInAmountWithoutAgencyCosts: number,
	baseAmountTypeCode: string
) => {
	if (
		baseAmountTypeCode === BaseAmountTypeCodes.DA_IN_AMOUNT_WITHOUT_AGENCY_COSTS
	) {
		return daInAmountWithoutAgencyCosts;
	}
	return daInAmount;
};

export const getTargetFieldName = (fieldName: FinanceFundingFormField) =>
	fieldName === FinanceFundingFormField.PERCENT
		? FinanceFundingFormField.AMOUNT
		: FinanceFundingFormField.PERCENT;

export const getPreFundFieldName = (fieldName: FinanceFundingFormField) =>
	fieldName === FinanceFundingFormField.FUNDING_PERCENT
		? FinanceFundingFormField.PERCENT
		: FinanceFundingFormField.AMOUNT;

export const getPrefundTargetFieldName = (fieldName: FinanceFundingFormField) =>
	fieldName === FinanceFundingFormField.FUNDING_PERCENT
		? FinanceFundingFormField.AMOUNT
		: FinanceFundingFormField.PERCENT;

export const calculatePercentValue = (value: number, amount: number) =>
	amount * (value / 100);

export const calculateAmountValue = (value: number, percentage: number) =>
	value / (percentage / 100);
