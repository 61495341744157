import { forEach, uniqBy, unionBy, dropRight } from 'lodash';
import { DEFAULT_AMOUNT_OF_SEARCH_TERM } from 'app-constants';
import { FiltersState, FilterState } from 'store/filters/filtersState';
import { FilterAddActionParam } from '../actions/setFilters';

const getCombinedFilters = (
	prevFilters: FilterState,
	nextFilters?: FilterState,
	override?: boolean,
	limit: number = DEFAULT_AMOUNT_OF_SEARCH_TERM
) => {
	let result = { ...prevFilters };
	if (!nextFilters) {
		return result;
	}
	forEach(nextFilters, (filters, key) => {
		/**
		 * Should new filter be merged with current list or set as it is
		 * Note: `unionBy` is used here to merge object values by the same `key`.
		 */
		const currentFilters = result[key] || [];

		const newFilters =
			currentFilters.length === limit
				? // Remove the last one (1) and inject the new as the first one
				  unionBy(filters, dropRight(currentFilters, 1), 'key')
				: // Add the new filters into the list
				  unionBy(filters, currentFilters, 'key');
		const values = override ? filters : newFilters;

		result = {
			...result,
			[key]: uniqBy(values, 'key')
		};
	});

	return result;
};

export const onSetFilters = (
	state: FiltersState,
	action: FilterAddActionParam
) => {
	if (!action) {
		return state;
	}
	const { name, filters, override } = action;
	const newFilters = getCombinedFilters(state[name], filters, override);
	return {
		...state,
		[name]: {
			...newFilters
		}
	};
};
