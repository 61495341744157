import * as React from 'react';
import { Icon } from 'components/antd';
import { IconType } from 'components/antd/Icon/IconTypes';

import styles from './PortCallCol.module.scss';
import { Flex } from 'components';
import { Justify } from 'components/types';

interface PortCallColProps {
	icon?: keyof IconType;
	label: string;
	justify?: Justify;
}

export const PortCallCol: React.SFC<PortCallColProps> = ({
	icon,
	label,
	justify,
	children
}) => (
	<Flex justify={justify}>
		{icon && (
			<Icon type={icon} size="md" offset="right" valign="middle" color="dark" />
		)}
		<span className={styles.portCallLabel}>
			<span>{label}:</span> {children}
		</span>
	</Flex>
);

export default PortCallCol;
