import React from 'react';
import classNames from 'classnames';
import { Flex, Text } from 'components';
import styles from './Body.module.scss';
export enum BodyType {
	STANDARD = 'standard',
	MOVEMENT = 'movement'
}

const Header: React.FC = props => {
	const { children } = props;
	return (
		<header className={styles.header}>
			<Text weight="semi-bold">{children}</Text>
		</header>
	);
};

const Content: React.FC = props => {
	const { children } = props;
	return <div className={styles.content}>{children}</div>;
};

interface BodyProps {
	type?: 'standard' | 'movement';
	className?: string;
}
class Body extends React.Component<BodyProps> {
	static Header: typeof Header;
	static Content: typeof Content;
	static defaultProps = {
		type: BodyType.STANDARD
	};
	render() {
		const { type, children, className } = this.props;
		return (
			<Flex
				none
				grow
				className={classNames(className, styles.root, {
					[styles.standard]: type === BodyType.STANDARD,
					[styles.movement]: type === BodyType.MOVEMENT
				})}
			>
				{children}
			</Flex>
		);
	}
}

Body.Header = Header;
Body.Content = Content;

export default Body;
