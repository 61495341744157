import * as React from 'react';
import { Modal } from 'components/antd';
import { PortCallRow } from 'sections/PortCalls/PortCallRow/PortCallRow';
import styles from './PortCallsDuplication.module.scss';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { navigateTo } from 'utils';
import { Flex } from 'components';

interface IPortCallDuplicationProps {
	duplicationPortCalls: PortCall[];
	isFromPathNomination?: boolean;
}
interface IPortCallDuplicationState {
	showWarningModal: boolean;
	linkToPortCall: string;
}
class Duplication extends React.Component<
	IPortCallDuplicationProps,
	IPortCallDuplicationState
> {
	constructor(props: IPortCallDuplicationProps) {
		super(props);
		this.state = {
			showWarningModal: false,
			linkToPortCall: ''
		};
	}

	onShowWarningModal = (link: string) => {
		this.setState({
			showWarningModal: true,
			linkToPortCall: link
		});
	};

	hideWarningModal = () => {
		this.setState({
			showWarningModal: false
		});
	};

	onLeave = () => {
		navigateTo(this.state.linkToPortCall);
		this.hideWarningModal();
	};

	openInNewTab = () => {
		this.hideWarningModal();
		setTimeout(() => {
			window.open(this.state.linkToPortCall, '_blank');
		}, 100);
	};

	render() {
		return (
			<Flex stretch direction="vertical">
				<div>
					<span className={styles.duplicationTitle}>
						{!this.props.isFromPathNomination
							? 'The following Port Calls already exist. Do you mean one of these?'
							: 'The following jobs match your criteria'}
					</span>
				</div>
				<Flex fit direction="vertical">
					{this.props.duplicationPortCalls.map((port: PortCall) => (
						<PortCallRow
							onPortJobClick={this.onShowWarningModal}
							flex
							portCall={port}
							key={port.id}
							checkPortJobCanView
						/>
					))}
				</Flex>
				<Modal
					visible={this.state.showWarningModal}
					onCancel={this.openInNewTab}
					onClose={this.hideWarningModal}
					onOk={this.onLeave}
					cancelText={
						this.props.isFromPathNomination ? 'Open in new tab' : 'Cancel'
					}
					okText="Confirm"
				>
					<p>
						{this.props.isFromPathNomination
							? 'You are going to leave Appointment Job creation process. If needed, you can open Job in a new tab and stay on this screen here.'
							: 'You are leaving the job creation page. The job creation process will be cancelled'}
					</p>
				</Modal>
			</Flex>
		);
	}
}

export default Duplication;
