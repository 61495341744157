import { ReducerRotationStep } from 'store/vesselProgramme/vesselProgrammeState';
import {
	deletePOI as action,
	deletePOIAsync as asyncAction
} from 'store/vesselProgramme/actions/deletePOI';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put, select } from 'redux-saga/effects';
import Api from 'services/api';
import {
	getSurroundingRotationSteps,
	getIsRecordedEventsEmpty,
	isIdAutoGenerated
} from '../selectors';
import { RotationStepMovementType } from '../../../services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { DeletePOIErrorMessages } from '../vesselProgrammeConstants';
import { deletePOIForShiftingSaga } from './deletePOIForShiftingSaga';
import { getActivePortCallId } from 'store/portcalls/selectors';

export function* executor(
	rotationStepId: ReducerRotationStep['id'],
	hasCostsApiCall: typeof Api.Finance.getHasPointOfInterestAssociatedCosts
): SagaIterator {
	yield put(asyncAction.started(rotationStepId));

	try {
		let hasCostsResponse = {
			data: { costAssociated: false }
		};

		if (!isIdAutoGenerated(rotationStepId)) {
			try {
				const portCallId = yield select(getActivePortCallId);
				hasCostsResponse = yield call(
					hasCostsApiCall,
					portCallId,
					rotationStepId
				);
			} catch (e) {
				throw new Error(DeletePOIErrorMessages.SERVER_ERROR);
			}

			if (hasCostsResponse.data.costAssociated) {
				throw new Error(DeletePOIErrorMessages.HAS_COSTS);
			}

			const isRecordedEventsEmpty = yield select(
				getIsRecordedEventsEmpty,
				rotationStepId
			);

			if (!isRecordedEventsEmpty) {
				throw new Error(DeletePOIErrorMessages.HAS_EVENTS);
			}

			const neighboringSteps = yield select(
				getSurroundingRotationSteps,
				rotationStepId
			);
			const hasNeighboringShifting =
				neighboringSteps.filter(
					(step: ReducerRotationStep) =>
						step && step.movementType === RotationStepMovementType.SHIFTING
				).length > 0;

			if (hasNeighboringShifting) {
				yield call(deletePOIForShiftingSaga, neighboringSteps);
			}
		}

		yield put(
			asyncAction.done({
				result: undefined,
				params: rotationStepId,
				response: null
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params: rotationStepId
			})
		);
	}
}

function* worker({ payload }: Action<ReducerRotationStep['id']>): SagaIterator {
	yield call(
		executor,
		payload,
		Api.Finance.getHasPointOfInterestAssociatedCosts
	);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
