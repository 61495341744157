import {
	UpdateFixedRateExchangeRequest,
	UpdateFixedRateExchangePayload
} from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const UPDATE_FIXED_RATE_EXCHANGE = 'UPDATE_FIXED_RATE_EXCHANGE';
export const updateFixedRateExchangeAsync = actionCreator.async<
	UpdateFixedRateExchangeRequest,
	null,
	Error
>(UPDATE_FIXED_RATE_EXCHANGE, {
	templateParams: {
		entityName: 'Exchange Rate'
	}
});

export const updateFixedRateExchange = actionCreator<
	UpdateFixedRateExchangePayload
>(UPDATE_FIXED_RATE_EXCHANGE);
