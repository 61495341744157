import { GroupsState } from '../groupsState';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveGroupStarted(
	state: GroupsState,
	id: string
): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			[id]: FetchStatus.PENDING
		}
	};
}
export function onRetrieveGroupSuccess(
	state: GroupsState,
	action: {
		result: Group;
		params: string;
	}
): GroupsState {
	const { result, params } = action;
	const byId = {
		...state.byId,
		// Extend group as the group model may differ across `GET /groups` and `GET /groups/{groupId}`
		[params]: {
			...(state.byId[params] || {}),
			...result
		}
	};
	const fetchStatuses = {
		...state.fetchStatuses,
		[params]: FetchStatus.SUCCESS
	};
	return {
		...state,
		byId,
		context: {
			...state.context,
			openedGroup: result
		},
		fetchStatuses
	};
}
export function onRetrieveGroupFailed(
	state: GroupsState,
	action: {
		params: string;
	}
): GroupsState {
	const { params } = action;
	const fetchStatuses = {
		...state.fetchStatuses,
		[params]: FetchStatus.FAILURE
	};
	return {
		...state,
		fetchStatuses
	};
}
