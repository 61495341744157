import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';

const getGroupUsersMapSelector = (state: AppState) => state.groups.users.byId;
const getAllGroupUserIdsSelector = (state: AppState, groupId: string) =>
	state.groups.users.byGroupId[groupId] || [];
export const isGroupUsersFetchingSelector = (
	state: AppState,
	groupId: string
) => state.groups.users.fetchStatuses[groupId] === FetchStatus.PENDING;
export const groupUsersHasMoreSelector = (state: AppState) =>
	state.groups.users.hasMore;

export const getAllGroupUsersSelector = createSelector(
	getGroupUsersMapSelector,
	getAllGroupUserIdsSelector,
	(byId, byGroupId = []) => {
		return byGroupId.map((id: string) => byId[id]).filter(item => item);
	}
);

export const getGroupUsersCount = createSelector(
	getAllGroupUserIdsSelector,
	groupUsers => groupUsers.length
);

export const getGroupUserDeletingFetchStatus = (state: AppState) =>
	state.groups.users.fetchStatuses.deleting;

export const getInviteThirdPartyLpaUserFetchStatus = (state: AppState) =>
	state.groups.users.fetchStatuses.inviteThirdPartyLpaUser;
