import * as React from 'react';
import { Tag } from 'components/antd';
import styles from './AssignTo.module.scss';
import classNames from 'classnames';
interface GroupLineProps {
	assignedTo: {
		group: { id: string; name: string; isDeleted: boolean };
		user: { id: string; name: string };
	};
	showingTitle: boolean;
}

const GroupLine: React.SFC<GroupLineProps> = ({ assignedTo, showingTitle }) => {
	if (!assignedTo.group) {
		return <Tag type="warning">No Value</Tag>;
	}
	return (
		<span
			className={classNames(styles.groupLine, {
				[styles.editButton]: !showingTitle
			})}
		>
			{showingTitle && <strong>{assignedTo.group.name}</strong>}

			{!showingTitle && <span>{assignedTo.group.name}</span>}

			{assignedTo.group.isDeleted && ' - DELETED'}
		</span>
	);
};

export default GroupLine;
