import actionCreator from '../portCallOperationsActionCreator';
import {
	AddPortCallEventRequest,
	AddPortCallEventResponse
} from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'ADD_EVENT';

export const addPortCallEventAsync = actionCreator.async<
	AddPortCallEventRequest,
	AddPortCallEventResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Event'
	}
});

export const addPortCallEvent = actionCreator<AddPortCallEventRequest>(
	ACTION_NAME
);
