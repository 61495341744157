import { ThreadsState } from 'store/threads/threadsState';
import { ThreadsMode} from 'services/api/threads/threadsServiceTypes';

export const onUpdateThreadMode = (
	state: ThreadsState,
	payload: ThreadsMode
): ThreadsState => ({
	...state,
	...payload
});
