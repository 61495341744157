import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';
import { getActiveThread } from 'store/thread/selectors/threadSelectors';
import { BreadcrumbItem } from 'store/navigation';
import { createSelector } from 'reselect';

export const getBreadcrumbsForThreadPortJobWizard = createSelector(
	getActiveThread,
	getActiveWizard,
	(thread, form): BreadcrumbItem[] => {
		const initBreadcrumb = [{ title: 'Messages', link: '/messages' }];
		const threadExistingBreadcrumb =
			form && thread
				? [
						{
							title: thread.lastMessage.subject,
							link: `/messages/${thread.id}`
						}
				  ]
				: [];
		const pageTitleBreadcrumb = [];

		if (form && form.fromPath === 'nomination') {
			pageTitleBreadcrumb.push({ title: 'Create Appointment from Nomination' });
		} else {
			const title = thread ? 'Assign/Create Job' : 'Assign Job';
			pageTitleBreadcrumb.push({ title });
		}

		return [
			...initBreadcrumb,
			...threadExistingBreadcrumb,
			...pageTitleBreadcrumb
		];
	}
);
