import actionCreatorFactory from 'typescript-fsa';
import {
	RetrieveUsersRequest,
	User
} from 'services/api/users/userServiceTypes';

const actionCreator = actionCreatorFactory();

const USERS_RETRIEVE = 'users/RETRIEVE_USERS';
export const retrieveUsersAsync = actionCreator.async<
	RetrieveUsersRequest,
	User[],
	Error
>(USERS_RETRIEVE, {
	behaviour: { type: 'section', infiniteLoad: true }
});

export const retrieveUsers = actionCreator(retrieveUsersAsync.type);
