import Api from 'services/api';
import { makeTakeEveryWatcher, makeDefaultExecutor } from 'utils/sagaHelpers';
import { select, call, put } from 'redux-saga/effects';
import {
	UpdateApiSubscriptionParams as Request,
	WebhookEndPointDetails
} from 'services/api/developerPortal/developerPortalServiceTypes';
import {
	updateApiSubscriptionAsync as async,
	retrieveDeveloperPortal
} from '../actions';
import {
	getUpdatedFormValues,
	getSelectedOutBoundList
} from '../selectors/developerPortalSelectors';
import {
	DEVELOPER_PORTAL_FORM,
	EMD_FORM,
	EventsMap
} from 'sections/Administration/DeveloperPortal/DeveloperPortalTab/constants';
import { destroy } from 'redux-form';
import { notify } from 'store/notifications/actions';
import { flatten, difference, isEmpty } from 'lodash';

const api = Api.DeveloperPortal.updateApiSubscription;

export const commonExecutor = makeDefaultExecutor<Request, null, Error>({
	api,
	async,
	*onSuccess() {
		yield put(destroy(EMD_FORM));
		yield put(destroy(DEVELOPER_PORTAL_FORM));
		yield put(retrieveDeveloperPortal());
	}
});

export const executor = function*() {
	const params = yield select(getUpdatedFormValues);

	if (!params.userId || !params.hubPrincipalCompanyId) {
		return;
	}

	let selectedOutboundAPI = yield select(getSelectedOutBoundList);

	selectedOutboundAPI = [...selectedOutboundAPI, 'status-change', 'clone-job'];
	const alreadySelectedEvents = flatten(
		params.webhookEndPointResponse.map(
			(webhook: WebhookEndPointDetails) => webhook.events
		)
	);

	const unsubscribedEvents = difference(
		alreadySelectedEvents,
		selectedOutboundAPI
	);
	if (!isEmpty(unsubscribedEvents)) {
		yield put(
			notify.warning(
				`Web-hook URL for unsubscribed event(s) ${unsubscribedEvents
					.map((event: string) => EventsMap[event])
					.join(', ')} is configured. Please remove.`
			)
		);
		return;
	}

	if (params.apiPermissions.length === 0) {
		yield put(notify.warning('Please select at least one API.'));
		return;
	}

	if (params.isWebhookEndPoint && params.webhookEndPointResponse.length === 0) {
		yield put(notify.warning('Please enter at least one web-hook details.'));
		return;
	}

	yield call(commonExecutor, {
		id: params.id,
		userId: params.userId,
		hubPrincipalCompanyId: params.hubPrincipalCompanyId,
		subscriptionStatus: params.subscriptionStatus,
		apiPermissions: params.apiPermissions,
		isRequestNewToken: params.isRequestNewToken,
		isWebhookEndPoint: params.isWebhookEndPoint,
		webhookEndPointResponse: params.webhookEndPointResponse,
		isWebhookConfigurationUpdated: params.isWebhookConfigurationUpdated
	});
};

export default makeTakeEveryWatcher<Request, null, Error>(
	{ api, async },
	executor
);
