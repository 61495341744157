import * as React from 'react';
import classNames from 'classnames';
import {
	Grid as FlexboxGrid,
	GridProps as AntGridProps
} from 'react-flexbox-grid';
import styles from './Grid.module.scss';

interface GridProps extends AntGridProps {
	fullWidth?: boolean;
}

export const Grid: React.FC<GridProps> = ({
	fullWidth,
	className,
	...props
}: GridProps) => (
	<FlexboxGrid
		{...props}
		className={classNames(className, { [styles.fullWidth]: fullWidth })}
	/>
);

export default Grid;
