import * as React from 'react';
import AntTag, { TagProps as AntTagProps } from 'antd/lib/tag';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import classNames from 'classnames';
import { Icon } from 'components/antd';
import styles from './Tag.module.scss';
import { isFunction } from 'lodash';
import TagList from './TagList';

type TagSize = 'xs' | 'sm';

export type TagType =
	| 'success'
	| 'info'
	| 'warning'
	| 'error'
	| 'link'
	| 'dark'
	| 'gray'
	| 'default';

export interface TagProps extends AntTagProps {
	type?: TagType;
	bold?: boolean;
	ghost?: boolean;
	borderless?: boolean;
	size?: TagSize;
	title?: string;
}

export class Tag extends React.PureComponent<TagProps> {
	static defaultProps: Partial<TagProps> = {
		size: 'sm'
	};
	static CheckableTag: typeof CheckableTag;
	static List: typeof TagList = TagList;

	e: AntTag | null;

	onClose = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (this.e) {
			this.e.handleIconClick(e);
		}
	};

	render() {
		const {
			bold,
			ghost,
			borderless,
			closable,
			children,
			...antTagProps
		} = this.props;
		const tagSizes = antTagProps.size ? styles[antTagProps.size] : null;
		const tagTypes = antTagProps.type ? styles[antTagProps.type] : null;

		const restClasses = {
			[styles.clickable]: isFunction(this.props.onClick),
			[styles.bold]: bold,
			[styles.ghost]: ghost,
			[styles.borderless]: borderless,
			// set color to $white if custom color is used
			[styles.color]: Boolean(antTagProps.color),
			[styles.closable]: this.props.closable
		};

		return (
			<AntTag
				ref={e => (this.e = e)}
				{...antTagProps}
				className={classNames(
					styles.root,
					this.props.className,
					tagTypes,
					tagSizes,
					restClasses
				)}
			>
				{children}
				{closable && <Icon type="close" onClick={this.onClose} />}
			</AntTag>
		);
	}
}

Tag.CheckableTag = CheckableTag;

export default Tag;
