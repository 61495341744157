import Api from 'services/api';
import { makeTakeEveryWatcher, makeDefaultExecutor } from 'utils/sagaHelpers';
import { select, call } from 'redux-saga/effects';
import {
	DeveloperPortalData as Response,
	DeveloperPortalDataRequest as Request
} from 'services/api/developerPortal/developerPortalServiceTypes';
import { retrieveDeveloperPortalAsync as async } from '../actions';
import { getAuthUserId, getUserCompanyId } from 'store/auth/selectors';

const api = Api.DeveloperPortal.retrieveDeveloperPortal;

export const commonExecutor = makeDefaultExecutor<Request, Response, Error>({
	api,
	async
});

export const executor = function*() {
	const userId = yield select(getAuthUserId);
	const companyId = yield select(getUserCompanyId);
	if (!userId || !companyId) {
		return;
	}
	yield call(commonExecutor, {
		userId,
		companyId
	});
};

export default makeTakeEveryWatcher<Request, Response, Error>(
	{ api, async },
	executor
);
