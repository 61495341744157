import { reject } from 'lodash';
import {
	UploadDocumentDoneResponse,
	FileBaseInfo
} from 'services/api/documents/documentsServiceTypes';
import { DirectBillsState } from '../directBillsState';
export const onUploadDirectBillsDocumentationDone = (
	state: DirectBillsState,
	{ document }: UploadDocumentDoneResponse
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedDocuments: [
				...state.context.uploadedDocuments,
				{
					id: document.id,
					name: document.name,
					createdOnUtc: document.createdOn,
					createdByUserName: document.createdByUserName
				} as FileBaseInfo
			]
		}
	};
};

export const onDeleteDirectBillsFileInfo = (
	state: DirectBillsState,
	fileId: string
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedDocuments: reject(
				state.context.uploadedDocuments,
				item => item.id === fileId
			)
		}
	};
};
