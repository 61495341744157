import {
	retrieveThreadsCycleStart,
	retrieveThreadsCycleStop
} from '../actions/retrieveThreadsCycle';
import { SagaIterator } from 'redux-saga';
import {
	call,
	select,
	cancel,
	fork,
	take,
	put,
	delay
} from 'redux-saga/effects';
import { retrieveThreadsAsync } from '../actions/retrieveThreads';
import { RetrieveThreadsResponse } from 'services/api/threads/threadsServiceTypes';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import Api from 'services/api';
import { DEFAULT_LIST_LIMIT, DEFAULT_POLLING_INTERVAL } from 'app-constants';
import { getThreadsFilters } from '../selectors';
import prepareRequest from './retrieveThreads/prepareRequest/prepareRequest';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';

const apiCall = Api.Threads.retrieveThreads;

export function* worker(): SagaIterator {
	// wait for initial request to be finished
	yield take(retrieveThreadsAsync.done);

	// set fallback to notification
	yield put(
		setNotificationActionTypeToNotification([retrieveThreadsAsync.type])
	);
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		const filters = yield select(getThreadsFilters);
		const extraRequestParams = prepareRequest(filters);

		const params = {
			limit: DEFAULT_LIST_LIMIT,
			sortBy: 'UpdatedOn:desc',
			...extraRequestParams
		};
		try {
			const response: AxiosTypedResponse<RetrieveThreadsResponse> = yield call(
				apiCall,
				params
			);
			yield put(
				retrieveThreadsAsync.done({
					result: response.data,
					params,
					response
				})
			);
		} catch (error) {
			yield put(retrieveThreadsAsync.failed({ error, params }));
		}
	}
}

export default function*() {
	while (yield take(retrieveThreadsCycleStart)) {
		const pollThreadsTask = yield fork(worker);
		yield take(retrieveThreadsCycleStop);
		yield cancel(pollThreadsTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([retrieveThreadsAsync.type])
		); // clear fallback
	}
}
