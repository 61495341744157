import React from 'react';
import { Col, Row } from 'components/antd';
import { Text } from 'components';
import styles from '../CargoLinkage.module.scss';
import CargoLine from './CargoLine';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import { isEmpty } from 'lodash';
import { operationDisplayName } from 'sections/PortCall/Linkjob/constants';

interface CargoRowProps {
	nominationOperationCargos: LinkedCargo[];
	appointmentOperationCargos: LinkedCargo[];
	unlinkedAppointmentCargos: LinkedCargo[];
	onDelete: (id: string) => void;
}

function CargoRow({
	nominationOperationCargos,
	appointmentOperationCargos,
	unlinkedAppointmentCargos,
	onDelete
}: CargoRowProps) {
	const operationType = nominationOperationCargos[0].operationType;
	return (
		<div className={styles.container}>
			<Row className={styles.row}>
				<Col sm={6} className={styles.col}>
					<Text weight="bold" className={styles.box}>
						{operationType && operationDisplayName[operationType]}
					</Text>
				</Col>
				<Col sm={6} className={styles.col}>
					<Text weight="bold" className={styles.box}>
						{operationType && operationDisplayName[operationType]}
					</Text>
				</Col>
			</Row>
			{nominationOperationCargos.map((cargo, index) => (
				<CargoLine
					key={cargo.cargoId}
					nominationCargo={cargo}
					appointmentCargo={
						isEmpty(appointmentOperationCargos[index])
							? undefined
							: appointmentOperationCargos[index]
					}
					unlinkedAppointmentCargos={unlinkedAppointmentCargos}
					onDelete={onDelete}
				/>
			))}
		</div>
	);
}

export default CargoRow;
