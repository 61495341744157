import actionCreator from '../directBillsActionCreator';
import { DownloadDirectBillsDocumentRequest } from 'services/api/directBills/directBillsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_PORT_CALL_DOCUMENTS';
export const downloadDirectBillsDocumentsAsync = actionCreator.async<
	DownloadDirectBillsDocumentRequest,
	null,
	Error
>(ACTION_NAME);

export const downloadDirectBillsDocuments = actionCreator<
	DownloadDirectBillsDocumentRequest
>(ACTION_NAME);
