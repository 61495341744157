import * as React from 'react';
import Label from './Label/Label';
import Dropdown from './Dropdown/Dropdown';
import TimeAgo from './TimeAgo/TimeAgo';

class Status extends React.PureComponent {
	static Label: typeof Label = Label;
	static Dropdown: typeof Dropdown = Dropdown;
	static TimeAgo: typeof TimeAgo = TimeAgo;

	render() {
		return null;
	}
}

export default Status;
