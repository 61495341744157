import {
	CompanyVesselsState,
	initialState
} from 'store/companyVessels/companyVesselsState';
import { SetActiveVesselSearchResultPayload } from '../actions/setActiveVesselSearchResult';

export const onSetActiveVesselSearchResult = (
	state: CompanyVesselsState,
	action: SetActiveVesselSearchResultPayload | null
): CompanyVesselsState => {
	if (!action) {
		return {
			...state,
			context: {
				...state.context,
				activeVesselSearchResult: initialState.context.activeVesselSearchResult,
				activeVesselSearchResultMapped:
					initialState.context.activeVesselSearchResultMapped
			}
		};
	}
	return {
		...state,
		context: {
			...state.context,
			activeVesselSearchResult: action.result,
			activeVesselSearchResultMapped: action.isResultMapped
		}
	};
};
