import actionCreator from '../supplierInvoiceActionCreator';
import { RetrieveSupplierInvoiceServiceDocumentRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'RETRIEVE_SPLIT_SUPPLIER_INVOICE_DOCUMENT';

export type RetrieveSplitSupplierInvoiceServiceDocumentResponse = PortCallDocument;
export type RetrieveSplitSupplierInvoiceServiceDocumentRequest = RetrieveSupplierInvoiceServiceDocumentRequest;

export const retrieveSplitSupplierInvoiceDocumentAsync = actionCreator.async<
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse,
	Error
>(ACTION_NAME);

export const retrieveSlitSupplierInvoiceDocument = actionCreator<
	RetrieveSplitSupplierInvoiceServiceDocumentRequest
>(ACTION_NAME);
