import { put, select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	acknowledgeVPWithCommentAsync,
	AcknowledgeVPWithCommentParams
} from '../actions';
import { AcknowledgeVPWithCommentRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { retrieveVP } from 'store/vesselProgramme/actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getVPCurrentUnacknowledgedRevisionId } from 'store/vesselProgramme/selectors';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	AcknowledgeVPWithCommentParams,
	void,
	Error
>({
	api: Api.VesselProgramme.acknowledgeVPWithComment,
	async: acknowledgeVPWithCommentAsync,
	*getApiParams(params: AcknowledgeVPWithCommentParams) {
		const portCallId = yield select(getActivePortCallId);
		const revisionId = yield select(getVPCurrentUnacknowledgedRevisionId);
		const request: AcknowledgeVPWithCommentRequest = {
			portCallId,
			revisionId,
			...params
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		yield put(retrieveVP({ portCallId }));
	}
});
