import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { BunkeringOperationGrade } from 'services/api/portJobs/portJobsServiceTypes';

import { getUnitsByCode } from 'store/units/unitsSelectors';
import { getBunkerGradesMap } from 'store/operations/operationsSelectors';

export const getDataSource = createSelector(
	[
		getBunkerGradesMap,
		getUnitsByCode,
		(_state: AppState, data: BunkeringOperationGrade[]) => data
	],
	(grades, units, data) =>
		data.map(item => {
			// We don't rely on data load status
			const grade = (grades[item.gradeId] || {}).name;
			const unitOfMeasurement = (units[item.unitOfMeasurementCode] || {}).name;

			return {
				grade,
				quantity: item.quantity,
				unitOfMeasurement
			};
		})
);
