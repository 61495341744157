declare const window: Window & {
	phoenixEnvs: {
		version: string;
	};
};

function showVersion(version: string): string {
	const stringifiedVersion = JSON.stringify(version);
	// eslint-disable-next-line
	console.log(
		`%cBuild version: ${stringifiedVersion}`,
		'background: green; color: white; display: block;'
	);
	return stringifiedVersion;
}

export default showVersion(window.phoenixEnvs.version);
