import * as React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Select, Radio, Badge, Icon } from 'components/antd';
import { Flex, Text } from 'components';

import { RadioChangeEvent } from 'antd/lib/radio/interface';
import {
	threadsAssignedToUserKeyMap,
	threadsStatusesOptions
} from 'types/sections/ThreadsTypes';
import {
	ThreadsFilterParamName,
	ThreadsFiltersParams
} from 'store/threads/filtersSync';
import {
	ThreadSearchGroupType,
	ThreadsAvailableGroup,
	ThreadsAssignedToUserFilter,
	ContextType,
	TagType
} from 'services/api/threads/threadsServiceTypes';
import { FiltersStateAndHelpers } from 'components/Filters/Filters';
import { FilterItem } from 'store/filters/filtersState';
import { Mailbox } from 'services/api/mailboxes/mailboxesServiceTypes';

import {
	retrieveThreadsAvailableGroups,
	resetThreadsAvailableGroups,
	retrieveThreadContexts,
	retrieveThreadTags
} from 'store/threads/actions';
import {
	retrieveMessagesMailboxes,
	resetMessagesMailboxes
} from 'store/messages/actions';

import {
	getThreadsAvailableGroups,
	getThreadContexts,
	getThreadTags,
	getIsPreviewModeEnabled
} from 'store/threads/selectors';
import { getDefaultMessagesMailboxes } from 'store/messages/selectors';

import {
	getFieldValues,
	getDefaultFieldValues,
	getMailboxCompanies
} from './ThreadsFiltersSelectors';
import styles from './ThreadsFilters.module.scss';
import ThreadsSearchField from './Fields/ThreadsSearchField';
import { AppState } from 'store-types';

interface ThreadsFiltersProps extends FiltersStateAndHelpers {
	unreadCount: number;
	onModeChange: (isPreviewModeEnabled: boolean) => void;
	// from mapStatToProps
	values: ThreadsFiltersParams;
	groups: ThreadsAvailableGroup[];
	threadContexts: ContextType[];
	mailboxes: Mailbox[];
	threadTags: TagType[];
	isPreviewModeEnabled: boolean;
	// from mapDispatchToProps
	retrieveMessagesMailboxes: typeof retrieveMessagesMailboxes;
	resetMessagesMailboxes: typeof resetMessagesMailboxes;
	retrieveThreadsAvailableGroups: typeof retrieveThreadsAvailableGroups;
	resetThreadsAvailableGroups: typeof resetThreadsAvailableGroups;
	retrieveThreadContexts: typeof retrieveThreadContexts;
	retrieveThreadTags: typeof retrieveThreadTags;
}

export class ThreadsFilters extends React.Component<ThreadsFiltersProps> {
	defaultValues: ThreadsFiltersParams = getDefaultFieldValues();

	componentDidMount() {
		this.props.retrieveMessagesMailboxes(null);
		this.props.retrieveThreadsAvailableGroups();
		this.props.retrieveThreadContexts(undefined);
		this.props.retrieveThreadTags(null);
	}

	componentWillUnmount() {
		this.props.resetMessagesMailboxes();
		this.props.resetThreadsAvailableGroups();
	}

	onFilterSelect = (
		filter: FilterItem,
		name: ThreadsFilterParamName,
		override = true
	) => {
		if (!this.beforeSelect(filter, name)) {
			return;
		}
		this.props.onChange(
			{
				[name]: [filter]
			},
			override
		);
	};

	/**
	 * Select will be stopped if `false` returned
	 * @param {FilterItem} value
	 * @param {ThreadsFilterParamName} name
	 */
	beforeSelect = (value: FilterItem, name: ThreadsFilterParamName) => {
		const defaultValue = this.defaultValues[name] as FilterItem;
		// - prevent putting default value to the store
		// to not show default filters as tags that may be reset if removed
		// - clear current filter to fallback to default
		if (defaultValue && value.key === defaultValue.key) {
			this.props.clearOne(this.props.values[name] as FilterItem);
			return false;
		}
		return true;
	};

	onChangeSearch = (filter: FilterItem, groupType: ThreadSearchGroupType) => {
		let key = filter.key;
		if (groupType === ThreadSearchGroupType.Search) {
			key = filter.label;
		}

		this.onFilterSelect(
			{ ...filter, type: groupType, key },
			ThreadsFilterParamName.SEARCH,
			false
		);
	};

	onChangeStatus = (filter: FilterItem) => {
		this.onFilterSelect(
			{ ...filter, type: ThreadsFilterParamName.STATUS },
			ThreadsFilterParamName.STATUS
		);
	};

	onChangeMailbox = (filter: FilterItem) => {
		this.onFilterSelect(
			{ ...filter, type: ThreadsFilterParamName.ASSIGNED_TO_COMPANY },
			ThreadsFilterParamName.ASSIGNED_TO_COMPANY
		);
	};

	onChangeAssignedTo = (filter: FilterItem) => {
		const isAssignedToUser = Boolean(threadsAssignedToUserKeyMap[filter.key]);
		let value = {
			...filter,
			type: ThreadsFilterParamName.ASSIGNED_TO_GROUP
		};
		if (isAssignedToUser) {
			value = {
				...value,
				label: threadsAssignedToUserKeyMap[filter.key],
				type: ThreadsFilterParamName.ASSIGNED_TO_USER
			};
		}
		this.onFilterSelect(value, ThreadsFilterParamName.ASSIGNED_TO);
	};

	onTagChange = (filter: FilterItem) => {
		const { values } = this.props;
		const isAlreadySelected =
			values.tags && values.tags.find(tag => tag.key === filter.key);
		if (!isAlreadySelected) {
			this.onFilterSelect(
				{ ...filter, type: ThreadsFilterParamName.TAGS },
				ThreadsFilterParamName.TAGS,
				false
			);
		}
	};

	filterByOnlyUnread = (e: RadioChangeEvent) => {
		const { value } = e.target;
		this.onFilterSelect(
			{ key: value, label: '', type: '' },
			ThreadsFilterParamName.ONLY_UNREAD
		);
	};

	onChangeContext = (filter: FilterItem) => {
		this.onFilterSelect(
			{ ...filter, type: ThreadsFilterParamName.THREAD_CONTEXT },
			ThreadsFilterParamName.THREAD_CONTEXT
		);
	};

	render() {
		const filterColumnWidth = 1.83;
		const {
			values,
			groups,
			mailboxes,
			unreadCount,
			threadContexts,
			threadTags,
			isPreviewModeEnabled,
			onModeChange
		} = this.props;
		const tagPlaceholder = !values.tags ? 'Select' : '';
		return (
			<Flex className={styles.root}>
				<Flex none shrink={0} self="end">
					<Radio.Group
						onChange={this.filterByOnlyUnread}
						value={values.onlyUnread}
					>
						<Radio.Button value="">All</Radio.Button>
						<Radio.Button value={ThreadsFilterParamName.ONLY_UNREAD}>
							Unread&nbsp;
							<Badge count={unreadCount} showZero className={styles.count} />
						</Radio.Button>
					</Radio.Group>
				</Flex>
				<div className={styles.fluid}>
					<Row>
						<Col sm={filterColumnWidth}>
							<ThreadsSearchField
								label="Search"
								onChange={this.onChangeSearch}
							/>
						</Col>
						<Col sm={filterColumnWidth} className={styles.selectWidth}>
							Status
							<Select
								value={values.status}
								labelInValue
								onChange={this.onChangeStatus}
								placeholder="Select"
							>
								{threadsStatusesOptions.map(option => (
									<Select.Option key={option.key}>{option.label}</Select.Option>
								))}
							</Select>
						</Col>
						<Col sm={filterColumnWidth} className={styles.selectWidth}>
							Assigned to
							<Select
								value={values.assignedTo}
								labelInValue
								onChange={this.onChangeAssignedTo}
								placeholder="Select"
							>
								<Select.Option key={ThreadsAssignedToUserFilter.ME}>
									<Text color="primary" weight="semi-bold">
										{
											threadsAssignedToUserKeyMap[
												ThreadsAssignedToUserFilter.ME
											]
										}
									</Text>
								</Select.Option>
								<Select.Option key={ThreadsAssignedToUserFilter.UNASSIGNED}>
									<Text color="primary" weight="semi-bold">
										{
											threadsAssignedToUserKeyMap[
												ThreadsAssignedToUserFilter.UNASSIGNED
											]
										}
									</Text>
								</Select.Option>
								{groups.map(group => (
									<Select.Option key={group.id}>{group.name}</Select.Option>
								))}
							</Select>
						</Col>
						{mailboxes.length > 1 && (
							<Col sm={filterColumnWidth} className={styles.selectWidth}>
								Mailbox
								<Select
									value={values.assignedToCompany}
									labelInValue
									onChange={this.onChangeMailbox}
									placeholder="Select"
								>
									{getMailboxCompanies(mailboxes).map(company => (
										<Select.Option key={company.id}>
											{company.name}
										</Select.Option>
									))}
								</Select>
							</Col>
						)}
						<Col sm={filterColumnWidth} className={styles.selectWidth}>
							Context
							<Select
								value={values.threadContextId}
								labelInValue
								onChange={this.onChangeContext}
								placeholder="Select"
							>
								{threadContexts.map(threadContext => (
									<Select.Option key={threadContext.id}>
										{threadContext.name}
									</Select.Option>
								))}
							</Select>
						</Col>
						<Col sm={filterColumnWidth} className={styles.selectWidth}>
							Tags
							<Select
								value={undefined}
								labelInValue
								onChange={this.onTagChange}
								placeholder={tagPlaceholder}
							>
								{threadTags.map(tag => (
									<Select.Option key={tag.code}>{tag.name}</Select.Option>
								))}
							</Select>
						</Col>
						<Col sm={1.02}>
							View
							<div className={styles.iconWrapper}>
								<Icon
									type="full-view"
									valign="middle"
									size={28}
									className={isPreviewModeEnabled ? styles.iconDisbaled : ''}
									onClick={() => onModeChange(false)}
								/>
								|
								<Icon
									type="split-view"
									valign="middle"
									size={28}
									className={!isPreviewModeEnabled ? styles.iconDisbaled : ''}
									onClick={() => onModeChange(true)}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		values: getFieldValues(state),
		mailboxes: getDefaultMessagesMailboxes(state),
		groups: getThreadsAvailableGroups(state),
		threadContexts: getThreadContexts(state),
		threadTags: getThreadTags(state),
		isPreviewModeEnabled: getIsPreviewModeEnabled(state)
	}),
	{
		retrieveMessagesMailboxes,
		resetMessagesMailboxes,
		retrieveThreadsAvailableGroups,
		resetThreadsAvailableGroups,
		retrieveThreadContexts,
		retrieveThreadTags
	}
)(ThreadsFilters);
