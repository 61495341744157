import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { UnitByCode } from './unitsState';
import { MainCommodityCode } from 'services/api/commodities/commoditiesServiceTypes';
import { Unit } from 'services/api/units/unitsServiceTypes';
import { UNIT_TYPES_BY_MAIN_COMMODITY } from './unitsConstants';

const getUnitCodes = (state: AppState): string[] => state.units.allCodes;

export const getUnitsByCode = (state: AppState): UnitByCode =>
	state.units.byCode;

export const getUnits = createSelector(
	[getUnitCodes, getUnitsByCode],
	(codes, operations) => codes.map(code => operations[code])
);

export const getUnitByCode = (
	state: AppState,
	code: string
): Unit | undefined => getUnitsByCode(state)[code];

const compareByDisplayName = (unitA: Unit, unitB: Unit) => {
	if (unitA.name > unitB.name) {
		return 1;
	}
	if (unitA.name < unitB.name) {
		return -1;
	}
	return 0;
};

export const getUnitsByMainCommodity = createSelector(
	getUnits,
	(_: AppState, mainCommodity: MainCommodityCode | undefined) =>
		mainCommodity ? UNIT_TYPES_BY_MAIN_COMMODITY[mainCommodity] : [],
	(units, unitTypes) => {
		if (!unitTypes.length) {
			return units.sort(compareByDisplayName);
		}
		return units
			.filter(unit =>
				unit.unitOfMeasurementTypes.some(unitType =>
					unitTypes.includes(unitType.code)
				)
			)
			.sort(compareByDisplayName);
	}
);
