import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { updateUserStatus, updateUserStatusAsync } from 'store/users/actions';

import Api from 'services/api';
import { UserUpdateStatusParams } from 'services/api/users/userServiceTypes';
import { Action } from 'typescript-fsa';
import checkUpdatedUserAgainstAuthSaga from './checkUpdatedUserAgainstAuthSaga';

export function* updateUserStatusWorker(
	action: Action<UserUpdateStatusParams>
): SagaIterator {
	const { id, request } = action.payload;

	yield put(updateUserStatusAsync.started({ id, request }));

	try {
		const response = yield call(Api.Users.updateUserStatus, id, request);

		const user = yield call(Api.Users.retrieveUser, { userId: id });

		yield call(checkUpdatedUserAgainstAuthSaga, id);

		yield put(
			updateUserStatusAsync.done({
				result: user.data,
				params: action.payload,
				response
			})
		);
	} catch (error) {
		yield put(
			updateUserStatusAsync.failed({
				error,
				params: action.payload
			})
		);
	}
}

function* updateUserStatusWatcher() {
	yield takeLatest(updateUserStatus.type, updateUserStatusWorker);
}

export default function*() {
	yield all([fork(updateUserStatusWatcher)]);
}
