import actionCreator from '../linkJobActionCreator';
import { AddAppointmentCargoManualRequest } from 'services/api/portCalls/portCallsServiceTypes';

const ADD_APPOINTMENT_CARGO_MANUAL = 'ADD_APPOINTMENT_CARGO_MANUAL';
export const addAppointmentCargoManual = actionCreator<
	AddAppointmentCargoManualRequest
>(ADD_APPOINTMENT_CARGO_MANUAL);

export default {
	action: addAppointmentCargoManual
};
