import { Success } from 'typescript-fsa';
import { UserProfile } from 'services/api/users/userServiceTypes';
import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveUserStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		single: FetchStatus.PENDING
	}
});
export const onRetrieveUserSuccess = (
	state: UsersState,
	action: Success<string, UserProfile>
): UsersState => {
	const { result } = action;
	return {
		...state,
		profile: result,
		fetchStatuses: {
			...state.fetchStatuses,
			single: FetchStatus.SUCCESS
		}
	};
};
export const onRetrieveUserFailed = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		single: FetchStatus.FAILURE
	}
});
