import { RetrieveSupplierInvoiceContextualizationResponse } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../supplierInvoiceContextualizationActionCreator';

export interface UpdatePortJobsInContextualizationFormParams
	extends RetrieveSupplierInvoiceContextualizationResponse {
	shouldAllServicesBeDeselected?: boolean;
}

export const updatePortJobsInContextualizationForm = actionCreator<
	UpdatePortJobsInContextualizationFormParams
>('UPDATE_PORT_JOBS_IN_CONTEXTUALIZATION_FORM');
