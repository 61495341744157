import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { downloadEmlFileAction } from '../reducers';
import {
	DownloadEmlFileRequest,
	DownloadEmlFileResponse
} from 'services/api/messages/messagesServiceTypes';
import Api from 'services/api';
import { Success } from 'typescript-fsa';

import {
	base64ToArrayBuffer,
	createAndDownloadEmlFile
} from 'utils/functional';
import { notify } from 'store/notifications/actions';
import { put } from 'redux-saga/effects';

const { async } = downloadEmlFileAction;

export default makeTakeLatestWatcher<
	DownloadEmlFileRequest,
	DownloadEmlFileResponse,
	Error
>({
	api: Api.Messages.downloadEml,
	async: async,
	*onSuccess({
		result: { subject, message }
	}: Success<DownloadEmlFileRequest, DownloadEmlFileResponse>) {
		if (!subject) {
			yield put(notify.error('Something went wrong while downloading.'));
		} else {
			const arrayBuffer = base64ToArrayBuffer(message);
			createAndDownloadEmlFile(arrayBuffer, subject);
		}
	}
});
