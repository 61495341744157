import { CompanyTemplateFile } from 'services/api/templates/templatesServiceTypes';
import { LabeledValue } from 'app-types';

export const CONFIGURATION_FILE_FORM_NAME = 'CONFIGURATION_FILE_FORM_NAME';
export const DEFAULT_VALUE = 'Any';

export enum ConfigurationFileFormField {
	DOCUMENT_ID = 'documentId',
	FILE_NAME = 'fileName',
	PRINCIPAL_IDS = 'principals',
	PORT_IDS = 'ports',
	MAIN_COMMODITIES = 'mainCommodities',
	MESSAGE_TYPES = 'messageTypes'
}

export type ConfigurationFileFormData = Pick<
	CompanyTemplateFile,
	'id' | 'documentId' | 'fileName' | 'userName' | 'createdOnUtc'
> & {
	principals: string[];
	ports: LabeledValue[];
	mainCommodities: string[];
	messageTypes: string[];
};
