import React from 'react';
import { connect } from 'react-redux';
import { getCompanyVesselsInPortCall } from 'store/companyVessels/selectors';
import { getRetrievePortCallVesselInfoFetchStatus } from 'store/portCall/selectors';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { getVesselById } from 'store/masterdata/vessels/selectors';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import { ScrollableLayout, Text } from 'components';
import { Collapse } from 'components/antd';
import VesselDetailsContent from './VesselDetailsContent';
import CompanyVesselDetailsContent from './CompanyVesselDetailsContent';
import LoadingPlaceholder from 'components/LoadingPlaceholder/LoadingPlaceholder';
import { AppState } from 'store-types';

interface VesselInfoProps {
	portCallId: string;
	vesselId: string;

	companyVessels: CompanyVessel[];
	vesselDetails: VesselDetails;
	vesselInfoFetchStatus: FetchStatus;
}

const allActiveKeysForCollapse = (companyVessels: CompanyVessel[]) => [
	...companyVessels.map(({ id }) => id),
	'vessel-details'
];

const VesselInfo = ({
	vesselInfoFetchStatus,
	companyVessels,
	vesselDetails
}: VesselInfoProps) => (
	<LoadingPlaceholder fetchStatus={vesselInfoFetchStatus}>
		{() => (
			<ScrollableLayout>
				<ScrollableLayout>
					<Collapse defaultActiveKey={allActiveKeysForCollapse(companyVessels)}>
						{companyVessels.map(companyVessel => (
							<Collapse.Panel
								header={<Text>Principal: {companyVessel.companyName}</Text>}
								key={companyVessel.id}
							>
								<CompanyVesselDetailsContent companyVessel={companyVessel} />
							</Collapse.Panel>
						))}
						<Collapse.Panel
							key="vessel-details"
							header={<Text upper>Generic Vessel Data</Text>}
						>
							{vesselDetails && (
								<VesselDetailsContent vesselDetails={vesselDetails} />
							)}
						</Collapse.Panel>
					</Collapse>
				</ScrollableLayout>
			</ScrollableLayout>
		)}
	</LoadingPlaceholder>
);

export default connect(
	(
		state: AppState,
		{ portCallId, vesselId }: Pick<VesselInfoProps, 'portCallId' | 'vesselId'>
	) => ({
		companyVessels: getCompanyVesselsInPortCall(state, portCallId),
		vesselDetails: getVesselById(state, vesselId),
		vesselInfoFetchStatus: getRetrievePortCallVesselInfoFetchStatus(state)
	})
)(VesselInfo);
