import actionCreator from '../portJobsActionCreator';
import {
	CheckPortJobPermissionsRequest,
	CheckPortJobPermissionsResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'CHECK_PORTJOB_PERMISSIONS';
export const checkPortJobPermissionsAsync = actionCreator.async<
	CheckPortJobPermissionsRequest,
	CheckPortJobPermissionsResponse,
	Error
>(ACTION_NAME);

export const checkPortJobPermissions = actionCreator<
	CheckPortJobPermissionsRequest
>(ACTION_NAME);
