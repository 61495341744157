import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
	updateThreadStatus as action,
	updateThreadStatusAsync as asyncAction,
	UpdateThreadStatusParams
} from 'store/thread/actions';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	retrieveThreadsPendingAlerts,
	updateThreads
} from 'store/threads/actions';
import { getActiveThread } from 'store/thread/selectors';

const apiCall = Api.Threads.updateThreadStatus;

const executor = function*(
	request: UpdateThreadStatusParams,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(request));
	try {
		const { status } = request;
		const response = yield call(api, request);
		/**
		 * the saga is not created by sagaHelper because of this part:
		 * we use data from params as result, not API response
		 */
		yield put(asyncAction.done({ result: status, params: request, response }));
		const portCallId = yield select(getActivePortCallId);
		yield put(retrieveThreadsPendingAlerts({ portCallId }));
		const activeThread = yield select(getActiveThread);
		yield put(updateThreads(activeThread));
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params: request
			})
		);
	}
};

const worker = function*({
	payload
}: Action<UpdateThreadStatusParams>): SagaIterator {
	yield call(executor, payload, apiCall);
};

export default function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}
