import {
	Commodity,
	MainCommodity
} from 'services/api/commodities/commoditiesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface CommoditiesState {
	readonly mainCommodities: MainCommodity[];
	readonly byId: { [id: string]: Commodity };
	readonly allIds: string[];
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		all: FetchStatus;
		[key: string]: FetchStatus;
	};
}

export const initialState: CommoditiesState = {
	mainCommodities: [],
	byId: {},
	allIds: [],
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE
	}
};
