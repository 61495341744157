/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppState } from 'store-types';
import { getPortCallInitialValues } from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import { createSelector } from 'reselect';

export const getAddPortJobFormData = createSelector(
	(state: AppState) => state,
	getPortCallInitialValues,
	(state, portCallFormInitialValues) => {
		return {
			...portCallFormInitialValues,
			...state.form.portJob.values
		};
	}
);

export const getOperationIdFromFormData = (state: any): string =>
	getAddPortJobFormData(state).operationType.key;
