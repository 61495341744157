import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Select, Tooltip } from 'components/antd';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';
import { AutocompletePorts, AutocompleteVessels } from 'containers';
import FieldVessels from './Field.Vessels';
import FieldHubPrincipal from './Field.HubPrincipal';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	FormFieldName,
	FORM,
	PORT_JOB_WIZARD_ID
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { retrievePortById } from 'store/masterdata';
import { setPortCallContext } from 'store/portcalls';
import { getIsFormFieldHubPrincipalVisible } from '../../page1Selectors';
import { getValues } from 'store/form/selectors';
import { getIsCurrentUsersGroupTypeISSCluster } from 'store/auth/selectors';
import { getWizardById } from 'store/wizardForm/wizardFormSelectors';
import {
	getCompanyJobTypes,
	getCompanyJobTypesFetchStatus
} from 'store/companies/selectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { AppState } from 'store-types';
import { LabeledValue } from 'app-types';
import { getIsMainPrincipalTypeCLS } from 'store/portJobs/selectors';
import { change, resetSection } from 'redux-form';
import Loading from 'components/Loading/Loading';
import { PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE } from './Form.func';
import { resetCompaniesContactDetails } from 'store/companies/actions/retrieveCompaniesContactDetails';
import { retrieveCompanyVesselById } from 'store/companyVessels/actions';

interface FieldsProps {
	// from mapStateToProps
	activeWizard: WizardFormProps;
	shouldGetAllVessels: boolean;
	formValues: Page1FormValues;
	isFormFieldHubPrincipalVisible: boolean;
	isFormFieldJobTypeVisible: boolean;
	isMainPrincipalTypeCLS: boolean;
	selectedJobTypes: string[];
	jobTypesFetchStatus: string;
	// from mapDispatchToProps
	retrievePortById: typeof retrievePortById;
	setPortCallContext: typeof setPortCallContext;
	resetCompaniesContactDetails: typeof resetCompaniesContactDetails;
}

export const FormFields: React.SFC<FieldsProps> = ({
	formValues,
	isFormFieldHubPrincipalVisible,
	isMainPrincipalTypeCLS,
	isFormFieldJobTypeVisible,
	activeWizard,
	shouldGetAllVessels,
	retrievePortById: retrievePort,
	setPortCallContext: setActivePort,
	selectedJobTypes,
	jobTypesFetchStatus
}) => {
	const dispatch = useDispatch();
	const isAssignMode = activeWizard.mode === 'assign';

	const PAGE_2_FIELDS_TO_RESET_ON_JOBTYPE_CHANGE = [
		...PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE,
		FormFieldName.VESSEL,
		FormFieldName.NOMINATOR,
		FormFieldName.CUSTOMER_REF,
		FormFieldName.AGENCY_TYPE,
		FormFieldName.PERFORMING_AGENT,
		FormFieldName.CONTROLLING_AGENT,
		FormFieldName.INSTRUCTION_TO_LPA,
		FormFieldName.INSTRUCTION_TO_ISS
	];

	const onJobTypeChange = React.useCallback(() => {
		dispatch(resetCompaniesContactDetails());
		PAGE_2_FIELDS_TO_RESET_ON_JOBTYPE_CHANGE.forEach(field => {
			dispatch(change(FORM.portJob, field, null));
			dispatch(resetSection(FORM.portJob, field));
		});
	}, [dispatch, PAGE_2_FIELDS_TO_RESET_ON_JOBTYPE_CHANGE]);

	const onVesselChange = React.useCallback(
		(vessel: { key: string }) => {
			if (formValues.hubPrincipalCompany?.key) {
				dispatch(
					retrieveCompanyVesselById({
						companyId: formValues.hubPrincipalCompany.key,
						vesselId: '',
						id: vessel.key
					})
				);
			}
		},
		[dispatch, formValues.hubPrincipalCompany]
	);

	React.useEffect(() => {
		if (selectedJobTypes.length === 1) {
			dispatch(
				change(FORM.portJob, FormFieldName.JOB_TYPE_ID, {
					key: selectedJobTypes[0],
					label: selectedJobTypes[0]
				})
			);
		}
	}, [selectedJobTypes, dispatch]);

	const getLoader = React.useCallback(() => {
		if (isFormFieldJobTypeVisible) {
			return <Loading />;
		} else {
			return null;
		}
	}, [isFormFieldJobTypeVisible]);

	const isLoading = React.useMemo(() => {
		return jobTypesFetchStatus === 'pending';
	}, [jobTypesFetchStatus]);

	return (
		<div>
			{isFormFieldHubPrincipalVisible && (
				<Row>
					<Col sm={2} xs={12}>
						<FieldHubPrincipal
							name={FormFieldName.HUB_PRINCIPAL_COMPANY}
							label="HUB Principal:"
							hubPrincipalCompany={formValues.hubPrincipalCompany}
							required
						/>
					</Col>
				</Row>
			)}
			{(!isLoading || isAssignMode) && formValues.hubPrincipalCompany ? (
				<>
					{isFormFieldJobTypeVisible &&
						!isAssignMode &&
						!isMainPrincipalTypeCLS && (
							<Row>
								<Col sm={2} xs={12}>
									{selectedJobTypes.length === 0 ? (
										<Tooltip
											trigger="hover"
											title="Principal is not allowed to create jobs of any type according to the Principal's configuration"
											placement="bottomLeft"
										>
											<Select.ReduxFormItem
												name={FormFieldName.JOB_TYPE_ID}
												label="Job Type:"
												labelInValue
												required
												disabled
												onChange={onJobTypeChange}
											></Select.ReduxFormItem>
										</Tooltip>
									) : (
										<Select.ReduxFormItem
											name={FormFieldName.JOB_TYPE_ID}
											label="Job Type:"
											labelInValue
											required
											disabled={false}
											onChange={onJobTypeChange}
										>
											{selectedJobTypes.map(job => (
												<Select.Option key={job}>{job}</Select.Option>
											))}
										</Select.ReduxFormItem>
									)}
								</Col>
							</Row>
						)}
					{Boolean(formValues.jobTypeId) ||
					isAssignMode ||
					isMainPrincipalTypeCLS ? (
						<Row>
							<Col sm={2} xs={12}>
								{shouldGetAllVessels ? (
									<AutocompleteVessels
										name={FormFieldName.VESSEL}
										label="Vessel"
										required
										afterFocusClick
									/>
								) : (
									<FieldVessels
										hubPrincipalCompany={formValues.hubPrincipalCompany}
										name={FormFieldName.VESSEL}
										label="Vessel"
										required
										afterFocusClick
										onChange={onVesselChange}
									/>
								)}
							</Col>
							<Col sm={2} xs={12}>
								<AutocompletePorts
									name={FormFieldName.PORT}
									label="Port"
									required
									onSelect={(value: LabeledValue) => {
										const { key: portId } = value;
										retrievePort({ port: portId });
										setActivePort({ activePortCallPortId: portId });
									}}
									afterFocusClick
								/>
							</Col>
							<Col md={2} sm={2} xs={12}>
								<DatePickerExtendedFormItem
									name={FormFieldName.ETA_PLT}
									label="ETA"
									required={!isAssignMode}
									help
									local
								/>
							</Col>
							<Col md={2} sm={2} xs={12}>
								<DatePickerExtendedFormItem
									name={FormFieldName.ETS_PLT}
									label="ETS"
									help
									local
								/>
							</Col>
						</Row>
					) : null}
				</>
			) : (
				getLoader()
			)}
		</div>
	);
};

export default connect(
	(state: AppState) => {
		const formValues: Page1FormValues = getValues(state, FORM.portJob);
		const isFormFieldHubPrincipalVisible = getIsFormFieldHubPrincipalVisible(
			state
		);
		const isFormFieldJobTypeVisible = isFormFieldHubPrincipalVisible
			? Boolean(formValues.hubPrincipalCompany)
			: true;

		return {
			activeWizard: getWizardById(state, PORT_JOB_WIZARD_ID),
			/**
			 * ISS Cluster user group has all available vessels in autocomplete input
			 * Other – only mapped to the main principal
			 */
			shouldGetAllVessels: getIsCurrentUsersGroupTypeISSCluster(state),
			isMainPrincipalTypeCLS: getIsMainPrincipalTypeCLS(state),
			selectedJobTypes: getCompanyJobTypes(state),
			jobTypesFetchStatus: getCompanyJobTypesFetchStatus(state),
			formValues,
			isFormFieldHubPrincipalVisible,
			isFormFieldJobTypeVisible
		};
	},
	{
		retrievePortById,
		setPortCallContext,
		resetCompaniesContactDetails
	}
)(FormFields);
