import { take, put, select, takeLatest, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { updateConfigurationEmailFragmentsAsync as async } from 'store/configuration/actions';
import {
	updateCompanyTemplates,
	updateCompanyTemplatesAsync
} from 'store/templates/actions';

import { getActiveCompanyId } from 'store/configuration/selectors';

import prepareRequest from './prepareRequest';
import initializeConfigurationEmailFragmentsFormWorker from '../initializeConfigurationEmailFragmentsFormWorker';

/*
	As part of OW-13113(UD_10) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

// import Api from 'services/api';
// const extendedMessagingApi = Api.Companies.updateExtendedMessagingConfiguration;

function* worker(): SagaIterator {
	yield put(async.started(null));
	try {
		const companyId: string = yield select(getActiveCompanyId);
		const data = yield select(prepareRequest);

		// const formData = yield select(getConfigurationEmailFragmentsFormValues);
		// const payload = {
		// 	extendedMessagingOption: formData.extendedMessagingOption
		// };
		// yield call(extendedMessagingApi, {
		// 	companyId,
		// 	data: payload
		// });

		// Update Templates
		yield put(
			updateCompanyTemplates({
				companyId,
				data
			})
		);

		const response = yield take([
			updateCompanyTemplatesAsync.done,
			updateCompanyTemplatesAsync.failed
		]);

		if (response.payload.error) {
			throw new Error();
		}

		yield fork(
			initializeConfigurationEmailFragmentsFormWorker,
			response.payload.result
		);
		yield put(async.done({ result: null, params: null, response: null }));
	} catch (e) {
		yield put(async.failed({ error: e, params: null }));
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
