import { select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	AddCreditHubRevenueServiceRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { addCreditHubRevenueServiceAsync as async } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.addCreditHubRevenueService;

export default makeTakeEveryWatcher<
	AddCreditHubRevenueServiceRequest,
	PortJobService,
	Error
>({
	api,
	async,
	*getApiParams(params) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: AddCreditHubRevenueServiceRequest = {
			portCallId,
			jobCode,
			...params
		};
		return request;
	}
});
