import actionCreator from '../commoditiesActionCreator';
import { RetrieveMainCommoditiesResponse } from 'services/api/commodities/commoditiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_MAIN_COMMODITIES';

export const retrieveMainCommoditiesAsync = actionCreator.async<
	null,
	RetrieveMainCommoditiesResponse,
	Error
>(ACTION_NAME);

export const retrieveMainCommodities = actionCreator(ACTION_NAME);
export const resetMainCommodities = actionCreator(`RESET_ ${ACTION_NAME}`);
