import { SystemStatusType } from './commonTypes';

interface SystemState {
	status: SystemStatusType | null;
	statusVerified: boolean;
}

export interface CommonState {
	system: SystemState;
}
export const initialState: CommonState = {
	system: {
		status: null,
		statusVerified: false
	}
};
