import { createCompanyVesselTypeAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse,
	Error
>({
	api: Api.Companies.createCompanyVesselType,
	async: createCompanyVesselTypeAsync
});
