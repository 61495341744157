import { createSelector } from 'reselect';
import { getFormInitialValues } from 'redux-form';
import { AppState } from 'store-types';
import {
	GENERAL_DETAILS_FORM_NAME,
	FormData as GeneralDetailFormData
} from 'sections/Administration/Configuration/GeneralDetails/GeneralDetailsTypes';

import {
	EMAIL_FRAGMENTS_FORM_NAME,
	FormData as EmailFragmentsFormData
} from 'sections/Administration/Configuration/EmailFragments/EmailFragmentsFormTypes';

import {
	BENCHMARK_FORM_NAME,
	FormData as BenchmarkFormData
} from 'sections/Administration/Configuration/Benchmark/BenchmarkTypes';
import { getValues } from 'store/form/selectors';
import { getRegions } from 'store/masterdata/regions/selectors';
import {
	FEEDBACK_MANAGEMENT_FORM_NAME,
	FeedbackManagementFormData,
	FeedbackManagementFormFields
} from 'sections/Administration/Configuration/FeedbackManagement/FeedbackManagementTypes';
import {
	CreateFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse
} from 'services/api/companies/companiesServiceTypes';
import {
	getActiveCompanyId,
	getUploadedRequestFeedbackDocuments
} from './configurationSelectors';
import { LabeledValue } from 'app-types';
import { forEach, isEmpty } from 'lodash';

export const getConfigurationGeneralDetailsFormInitialValues = (
	state: AppState
): Partial<GeneralDetailFormData> =>
	getFormInitialValues(GENERAL_DETAILS_FORM_NAME)(state);

export const getConfigurationGeneralDetailsFormValues = (
	state: AppState
): GeneralDetailFormData =>
	getValues<GeneralDetailFormData>(state, GENERAL_DETAILS_FORM_NAME);

const getGeneralDetailsRegionCodesSelected = createSelector(
	getConfigurationGeneralDetailsFormValues,
	values => {
		if (!values || !values.details || !values.details.issOperatorContacts) {
			return;
		}

		return values.details.issOperatorContacts.map(
			contact => contact.unRegion && contact.unRegion.id
		);
	}
);

export const getGeneralDetailsFormRegionsFlagged = createSelector(
	getRegions,
	getGeneralDetailsRegionCodesSelected,
	(regions, regionCodes) => {
		if (!regionCodes) {
			return regions;
		}
		return regions.map(region => ({
			...region,
			disabled: regionCodes.includes(region.code)
		}));
	}
);

export const getConfigurationEmailFragmentsFormValues = (
	state: AppState
): EmailFragmentsFormData =>
	getValues<EmailFragmentsFormData>(state, EMAIL_FRAGMENTS_FORM_NAME);

export const getConfigurationBenchmarkFormValues = (
	state: AppState
): BenchmarkFormData =>
	getValues<BenchmarkFormData>(state, BENCHMARK_FORM_NAME);

export const getConfigurationFeedbackManagementFormValues = (
	state: AppState
): FeedbackManagementFormData =>
	getValues<FeedbackManagementFormData>(state, FEEDBACK_MANAGEMENT_FORM_NAME);

const getFeedbackManagementJobType = (formData: FeedbackManagementFormData) => {
	const jobType = [];
	if (formData?.appointment) {
		jobType.push(FeedbackManagementFormFields.APPOINTMENT);
	}
	if (formData?.nomination) {
		jobType.push(FeedbackManagementFormFields.NOMINATION);
	}
	if (formData?.chartereroversight) {
		jobType.push(FeedbackManagementFormFields.CHARTERER_OVERSIGHT);
	}
	if (formData?.appointmentfromnomination) {
		jobType.push(FeedbackManagementFormFields.APPOINTMENT_FROM_NOMINATION);
	}

	return jobType;
};

const getFeedbackVariables = (formData: LabeledValue[]) => {
	const data = [] as LabeledValue[];
	forEach(formData, value => {
		if (!isEmpty(value.label) && !isEmpty(value.key)) {
			data.push(value);
		}
	});

	return data;
};

export const getCreateFeedbackManagementRequest = createSelector(
	getConfigurationFeedbackManagementFormValues,
	getUploadedRequestFeedbackDocuments,
	getActiveCompanyId,
	(formValue, filesFormValues, companyId) => {
		const result = {} as CreateFeedbackManagementRequest;
		result.name = formValue.name;
		result.feedbackId = formValue?.feedbackId;
		result.feedbackEvent = formValue.feedbackEvent;
		result.isFirstInstance = formValue?.isFirstInstance || false;
		result.companyId = companyId;
		result.isCompanyEmail = formValue?.isCompanyEmail || false;
		result.isVesselEmail = formValue?.isVesselEmail || false;
		result.isCustomEmail = formValue?.isCustomEmail || false;
		result.customEmails =
			formValue?.customEmails?.map(email => email.label) || [];
		result.emailContent = formValue?.emailContent || '';
		result.onlineSurvey =
			formValue.onlineSurvey === FeedbackManagementFormFields.YES
				? true
				: false;
		result.surveyUrl = formValue?.surveyUrl;
		result.jobType = getFeedbackManagementJobType(formValue);
		result.documentId = filesFormValues?.map(file => file.documentId) || [];
		result.feedbackVariables = getFeedbackVariables(
			formValue?.feedbackVariables
		);

		return result;
	}
);

export const getInitialFormValues = (
	value: RetrieveFeedbackManagementGridResponse
) => {
	const result = {} as FeedbackManagementFormData;
	result.name = value.name;
	result.feedbackId = value.id;
	result.feedbackEvent = value.feedbackEvents;
	result.isFirstInstance = value.isFirstInstance;
	result.isCompanyEmail = value.isCompanyEmail;
	result.isVesselEmail = value.isVesselEmail;
	result.isCustomEmail = value.isCustomEmail;
	result.customEmails = [];
	value.isCustomEmail &&
		value.customEmails.map(email => result.customEmails.push({ label: email }));
	result.emailContent = value.emailContent;
	result.onlineSurvey =
		value.onlineSurvey === true
			? FeedbackManagementFormFields.YES
			: FeedbackManagementFormFields.NO;
	result.surveyUrl = value.surveyUrl || '';
	result.appointment = false;
	result.appointmentfromnomination = false;
	result.chartereroversight = false;
	result.nomination = false;
	if (
		value.feedBackJobType.includes(FeedbackManagementFormFields.APPOINTMENT)
	) {
		result.appointment = true;
	}
	if (value.feedBackJobType.includes(FeedbackManagementFormFields.NOMINATION)) {
		result.nomination = true;
	}
	if (
		value.feedBackJobType.includes(
			FeedbackManagementFormFields.CHARTERER_OVERSIGHT
		)
	) {
		result.chartereroversight = true;
	}
	if (
		value.feedBackJobType.includes(
			FeedbackManagementFormFields.APPOINTMENT_FROM_NOMINATION
		)
	) {
		result.appointmentfromnomination = true;
	}
	result.documentId = value.feedbackDocuments.map(doc => doc.documentId);
	result.feedbackVariables = [];
	value.onlineSurvey && value?.feedBackVariables?.length > 0
		? value.feedBackVariables.map(variable =>
				result.feedbackVariables.push({
					key: variable.surveyVariable,
					label: variable.opticVariable
				})
		  )
		: result.feedbackVariables.push({ key: '', label: '' });
	return result;
};
