import { FinanceState } from 'store/finance/financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	AddCreditHubRevenueServiceRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';

export const onAddCreditHubRevenueService = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	AddCreditHubRevenueServiceRequest,
	PortJobService
>('fetchStatuses', 'addCreditHubRevenueService');
