import actionCreator from '../portJobsActionCreator';
import {
	TogglePortJobStatusParams,
	TogglePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'TOGGLE_JOBSTATUS';

export const togglePortJobStatusAsync = actionCreator.async<
	TogglePortJobStatusParams,
	TogglePortJobResponse,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	done: {
		description: 'Port Job Status has been successfully updated.'
	},
	templateParams: {
		entityName: 'Port Job'
	}
});

export const togglePortJobStatus = actionCreator<TogglePortJobStatusParams>(
	ACTION_NAME
);
