import { ContactType } from 'services/api/messages/messagesServiceTypes';
import actionCreator from 'store/messages/messagesActionCreator';

const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const updateContactAsync = actionCreator.async<
	ContactType,
	undefined,
	Error
>(UPDATE_CONTACT, {
	templateParams: {
		entityName: 'Contact'
	}
});
export const updateContact = actionCreator<ContactType>(UPDATE_CONTACT);
