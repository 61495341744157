import { getCompanyForUserById } from 'store/companies/selectors';
import { FormStateGet } from 'store/form/formTypes';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';

import { getForm } from 'store/form/selectors';

import { getUserGroupType } from 'store/auth/selectors/user';

import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { CREATE_GROUP_FORM, CreateGroupFormData } from '../GroupsModalsTypes';

// TODO refactor file

export const getCreateGroupModalForm = (
	state: AppState
): FormStateGet<CreateGroupFormData> => getForm(state, CREATE_GROUP_FORM);

export const getCompanyIdFromValue = createSelector(
	getCreateGroupModalForm,
	(form): string => form.values.companyId
);
export const getGroupTypeFromValue = createSelector(
	getCreateGroupModalForm,
	(form): GroupTypeCodes =>
		form.values.groupType || GroupTypeCodes.THIRD_PARTY_LPA
);

export const getGroupTypeCodeByUserCompanyOrganisationType = createSelector(
	(state: AppState) =>
		getCompanyForUserById(state, getCompanyIdFromValue(state)),
	getUserGroupType,
	getGroupTypeFromValue,
	(company, userGroupType, defaultValue): GroupTypeCodes => {
		if (!company) {
			return userGroupType;
		}
		const organisationTypeCodes = company.organisationTypes.map(
			type => type.organisationTypeCode
		);

		if (
			organisationTypeCodes.includes(OrganisationType.MAIN_PRINCIPAL) ||
			organisationTypeCodes.includes(OrganisationType.PRINCIPAL)
		) {
			return GroupTypeCodes.CUSTOMER;
		}

		if (organisationTypeCodes.includes(OrganisationType.LPA)) {
			return organisationTypeCodes.includes(OrganisationType.ISS)
				? GroupTypeCodes.ISS_LPA
				: GroupTypeCodes.THIRD_PARTY_LPA;
		}

		return defaultValue;
	}
);
