import { call, takeEvery, all, fork, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	createNewThread as syncAction,
	createNewThreadAsync as asyncAction
} from '../actions/createNewThread';
import Api from 'services/api';
import {
	CreateThreadRequest as request,
	CreateThreadResponse
} from 'services/api/threads/threadsServiceTypes';
import { Action } from 'typescript-fsa';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import { navigateTo } from 'utils';
import Matomo from 'services/analytics/adapters/Matomo';

const apiCall = Api.Threads.createThread;

export interface ExecutorActionPayload {
	data: request;
	isNewTab: boolean;
	pathToRedirectOnSuccess: string;
}

export function* executor(
	actionParams: ExecutorActionPayload,
	api: typeof apiCall
): SagaIterator {
	const params = actionParams.data;
	const { isNewTab, pathToRedirectOnSuccess } = actionParams;

	yield put(asyncAction.started(params));

	try {
		const response: AxiosTypedResponse<CreateThreadResponse> = yield call(
			api,
			params
		);

		if (isNewTab) {
			yield call(() => window.close());
		} else {
			yield call(navigateTo, pathToRedirectOnSuccess || `/messages`);
		}
		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Messages.CreateThread',
			name: `Created thread with id: ${response.data.id}`,
			value: 1
		});
	} catch (err) {
		yield put(
			asyncAction.failed({
				error: err,
				params
			})
		);
	}
}

function* worker(action: Action<ExecutorActionPayload>): SagaIterator {
	yield call(executor, action.payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeEvery(syncAction.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
