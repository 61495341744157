import {
	MovetoPDAPendingRequest,
	MoveBackToJobStatusActionRequest
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';
const ACTION_NAME = 'MOVE_JOB_TO_PREVIOUS_STATUS';

export const moveJobToPreviousStatusAsync = actionCreator.async<
	MovetoPDAPendingRequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	templateParams: {
		// entityName: 'Custody Role'
	}
});

export const moveJobToPreviousStatus = actionCreator<
	MoveBackToJobStatusActionRequest
>(ACTION_NAME);
