import { put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { GENERAL_DETAILS_FORM_NAME } from 'sections/Administration/Configuration/GeneralDetails/GeneralDetailsTypes';
import { RetrieveCompanyGeneralConfigurationResponse } from 'services/api/companies/companiesServiceTypes';
import { initialize } from 'redux-form';
import { getConfigurationGeneralDetailsFormValues } from '../../selectors';
import prepareFormData from './prepareFormData';

export default function* worker(
	response?: RetrieveCompanyGeneralConfigurationResponse
): SagaIterator {
	const data = response
		? yield select(prepareFormData, response)
		: yield select(getConfigurationGeneralDetailsFormValues);

	/**
	 * Form initialization resets form, e.g. initialValues, pristine state, etc.
	 */
	yield put(initialize(GENERAL_DETAILS_FORM_NAME, data));
}
