import {
	updateCompanyVesselAsync,
	updateCompanyVessel
} from 'store/companyVessels/actions';
import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, take, all, fork, put } from 'redux-saga/effects';
import { UpdateCompanyVesselRequest } from 'services/api/companies/companiesServiceTypes';
import { navigateTo } from 'utils';
import { changeRoute } from 'store/route/actions';

export function* updateCompanyVesselExecutor(
	actionParams: UpdateCompanyVesselRequest,
	api: typeof Api.Companies.updateCompanyVessel
): SagaIterator {
	yield put(updateCompanyVesselAsync.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(
			updateCompanyVesselAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield call(navigateTo, '/hub-principal-configuration/vessels');
		yield take(changeRoute);
	} catch (error) {
		yield put(
			updateCompanyVesselAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* updateCompanyVesselWorker(
	action: Action<UpdateCompanyVesselRequest>
): SagaIterator {
	yield call(
		updateCompanyVesselExecutor,
		action.payload,
		Api.Companies.updateCompanyVessel
	);
}

export function* updateCompanyVesselWatcher(): SagaIterator {
	yield takeLatest(updateCompanyVessel.type, updateCompanyVesselWorker);
}

export default function*() {
	yield all([fork(updateCompanyVesselWatcher)]);
}
