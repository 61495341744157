import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { resetCompanies, retrieveCompany } from 'store/companies';

import { getCompanyById } from 'store/companies/selectors';
import {
	getIsCurrentUserOfCustomerGroupType,
	getUserCompanyId
} from 'store/auth/selectors';

import { retrieveGroups, resetGroups } from 'store/groups';
import { getValues } from 'store/form/selectors';
import {
	getAllGroupsSelector,
	getIsGroupsFetching
} from 'store/groups/groupsSelectors';
import { AppState } from 'store-types';
import { usePrevious } from 'utils/hooks';
import { UsersModalsPropsBase, InviteUserFormData } from '../UsersModalsTypes';

interface UseInviteUserFormProps {
	form: string;
}
export function useInviteUserForm({ form }: UseInviteUserFormProps) {
	const dispatch = useDispatch();

	const { formValues, groups, isGroupsLoading } = useSelector(
		(state: AppState) => ({
			formValues: getValues<InviteUserFormData>(state, form),
			groups: getAllGroupsSelector(state),
			isGroupsLoading: getIsGroupsFetching(state)
		}),
		shallowEqual
	);
	const previousCompany = usePrevious(formValues.company);

	const onRetrieveGroups = useCallback(
		(companyId?: string) => {
			if (companyId) {
				dispatch(
					retrieveGroups({
						companyId,
						limit: 1000,
						sortBy: 'name',
						overrideFilters: true
					})
				);
			}
		},
		[dispatch]
	);

	useEffect(() => {
		return () => {
			dispatch(resetGroups());
		};
	}, [dispatch]);

	useEffect(() => {
		if (previousCompany?.key !== formValues.company?.key) {
			onRetrieveGroups(formValues.company?.key);
		}
	}, [previousCompany, formValues.company, onRetrieveGroups]);

	return {
		formValues,

		groups,
		isGroupsLoading,
		onRetrieveGroups
	};
}

export function useInviteUserFormInitialValues({
	modalValue: value
}: Pick<UsersModalsPropsBase, 'modalValue'>) {
	const dispatch = useDispatch();

	const { isCustomer, userCompanyId, userCompany } = useSelector(
		(state: AppState) => {
			const userCompanyId = getUserCompanyId(state);
			return {
				isCustomer: getIsCurrentUserOfCustomerGroupType(state),
				userCompanyId,
				userCompany: getCompanyById(state, userCompanyId)
			};
		},
		shallowEqual
	);

	// for customer group types, when invited, current user's company is assigned
	const shouldUseCurrentUserCompany = useMemo(() => isCustomer && !value?.id, [
		isCustomer,
		value
	]);

	// mounting/unmounting
	useEffect(() => {
		if (shouldUseCurrentUserCompany) {
			dispatch(retrieveCompany(userCompanyId));
		}
		return () => {
			dispatch(resetCompanies());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initialValues = useMemo(() => {
		if (value) {
			return {
				id: value.id,
				email: value.email,
				firstName: value.firstName,
				lastName: value.lastName,
				company: {
					key: value.company.companyId,
					label: value.company.name
				},
				groups: value.groups.map(group => group.id) || [],
				invitationMessage: ''
			};
		}

		if (shouldUseCurrentUserCompany && userCompany) {
			return {
				company: {
					key: userCompany.id,
					label: userCompany.companyName
				}
			};
		}

		return;
	}, [value, shouldUseCurrentUserCompany, userCompany]);

	return useMemo(
		() => ({
			initialValues,
			isCurrentUserCompanyAssigned: shouldUseCurrentUserCompany
		}),
		[initialValues, shouldUseCurrentUserCompany]
	);
}
