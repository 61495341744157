import { ConfigurationBenchmarkData } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveConfigurationBenchmark = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	null,
	ConfigurationBenchmarkData
>('fetchStatuses', 'retrieveBenchmark');
