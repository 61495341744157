import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'components/antd';
import { AppState } from 'store-types';
import { getThreadMainPrincipalId } from 'store/thread/selectors/threadSelectors';
import { addAppointmentJobFromNomination } from 'store/portJobs/actions';
import {
	getJobStatusComparisonSelector,
	isAddPortJobFetching
} from 'store/portJobs/selectors';
import { PortJobStatus } from 'store/portJobs/constants';
import { PortJobStatusComparisonType } from 'services/api/portJobs/portJobsServiceTypes';

interface CreateAppointmentButtonProps {
	hubPrincipalCompanyId: string | undefined;
	portCallId: string;
	jobCode: string;
	isAppointer: boolean;
	isLinkedJob: boolean;
	portJobStatus: string;
	isDraftAppointer: boolean;
	// from mapStateToProps
	currentThreadMainPrincipalId: string;
	isStatusFurtherWaitAppoint: boolean;
	isLoading: boolean;
	// from mapDispatchToProps
	addAppointmentJobFromNomination: typeof addAppointmentJobFromNomination;
}

interface CreateAppointmentState {
	showWarningModal: boolean;
	isLoading: boolean;
}

class CreateAppointmentButton extends React.Component<
	CreateAppointmentButtonProps,
	CreateAppointmentState
> {
	constructor(props: CreateAppointmentButtonProps) {
		super(props);

		this.state = {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			showWarningModal: false,
			isLoading: false
		};
	}

	onCancelOrClose = () => {
		this.setState({ showWarningModal: false });
	};

	onConfirm = () => {
		const { portCallId, jobCode } = this.props;
		this.setState({ isLoading: true });
		this.props.addAppointmentJobFromNomination({
			portCallId,
			jobCode
		});
		this.onCancelOrClose();
	};

	getTooltipContent = (
		isLinkedJob: boolean,
		isSameMailBox: boolean,
		isAppointer: boolean,
		isDraftAppointer: boolean
	) => {
		if (isLinkedJob) {
			return {
				title: 'This Nomination already has linked Appointment'
			};
		} else if (isSameMailBox) {
			return {
				title: `You cannot create Appointment from Nomination using this thread because thread's mailbox does not belong to Nomination Hub Principal`
			};
		} else if (!isAppointer) {
			return {
				title: `Please wait until Appointer information is provided`
			};
		} else if (isDraftAppointer) {
			return {
				title: `The draft appointer cannot be used to create appointment jobs. Please wait until the draft is converted or choose an existing appointer`
			};
		} else {
			return undefined;
		}
	};

	render() {
		const {
			currentThreadMainPrincipalId,
			hubPrincipalCompanyId,
			jobCode,
			isLinkedJob,
			isAppointer,
			isStatusFurtherWaitAppoint,
			isDraftAppointer,
			isLoading
		} = this.props;
		const isSameMailBox =
			currentThreadMainPrincipalId !== hubPrincipalCompanyId;
		const tooltipContent = this.getTooltipContent(
			isLinkedJob,
			isSameMailBox,
			isAppointer,
			isDraftAppointer
		);
		if (!isStatusFurtherWaitAppoint || !jobCode.includes('HN')) {
			return null;
		}

		return (
			<>
				<Button
					type="primary"
					size="small"
					disabled={
						isSameMailBox ||
						isLinkedJob ||
						!isAppointer ||
						isDraftAppointer ||
						isLoading
					}
					onClick={() => this.setState({ showWarningModal: true })}
					tooltip={tooltipContent}
					loading={this.state.isLoading}
				>
					Create Appointment
				</Button>
				<Modal
					visible={this.state.showWarningModal}
					onCancel={this.onCancelOrClose}
					onClose={this.onCancelOrClose}
					onOk={this.onConfirm}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>
						Appointment Job will be created based on {jobCode} Nomination Job.
						Are you sure want to proceed?
					</p>
				</Modal>
			</>
		);
	}
}

export default connect(
	(
		state: AppState,
		props: Pick<CreateAppointmentButtonProps, 'portJobStatus'>
	) => ({
		currentThreadMainPrincipalId: getThreadMainPrincipalId(state),
		isStatusFurtherWaitAppoint: getJobStatusComparisonSelector(
			props.portJobStatus,
			PortJobStatus.AWAITING_APPOINTMENT,
			PortJobStatusComparisonType.SAME_OR_AFTER
		),
		isLoading: isAddPortJobFetching(state)
	}),
	{
		addAppointmentJobFromNomination
	}
)(CreateAppointmentButton);
