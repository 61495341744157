import { CompaniesState } from '../companiesState';
import { FetchStatus } from 'services/api/apiTypes';
import { Failure, Success } from 'typescript-fsa';
import { Company } from 'services/api/companies/companiesServiceTypes';

const getFetchStatuses = (
	fetchStatuses: CompaniesState['fetchStatuses'],
	id: string,
	status: FetchStatus
) => {
	return {
		...fetchStatuses,
		[id]: status
	};
};

export function onRetrieveCompanyStarted(
	state: CompaniesState,
	id: string
): CompaniesState {
	return {
		...state,
		fetchStatuses: getFetchStatuses(
			state.fetchStatuses,
			id,
			FetchStatus.PENDING
		)
	};
}

export function onRetrieveCompanyFailed(
	state: CompaniesState,
	action: Failure<string, Error>
): CompaniesState {
	const id = action.params;
	return {
		...state,
		fetchStatuses: getFetchStatuses(
			state.fetchStatuses,
			id,
			FetchStatus.FAILURE
		)
	};
}

export function onRetrieveCompanySuccess(
	state: CompaniesState,
	action: Success<string, Company>
): CompaniesState {
	const { result } = action;
	const id = result.id;

	const byId = {
		...state.byId,
		[id]: result
	};

	return {
		...state,
		byId,
		fetchStatuses: getFetchStatuses(
			state.fetchStatuses,
			id,
			FetchStatus.SUCCESS
		)
	};
}
