import React from 'react';
import { connect } from 'react-redux';
import { ActAsSwitcher } from 'containers';
import { getActAsOptionsFlaggedForPortCall } from 'store/portCall/selectors';
import {
	retrievePortCallActAs,
	resetPortCallActAs
} from 'store/portCall/actions';
import { AuthActAsOption } from 'store/auth/authState';
import history from 'services/history';
import { AppState } from 'store-types';
import { PortJobStatus } from 'store/portJobs/constants';

interface PortCallActAsSwitcherFetcherProps {
	portCallId: string;
	retrievePortCallActAs: typeof retrievePortCallActAs;
	resetPortCallActAs: typeof resetPortCallActAs;
}

class PortCallActAsSwitcherFetcher extends React.Component<
	PortCallActAsSwitcherFetcherProps
> {
	componentDidMount() {
		this.props.retrievePortCallActAs({ portCallId: this.props.portCallId });
	}
	componentWillUnmount() {
		this.props.resetPortCallActAs();
	}
	render() {
		return null;
	}
}

const PortCallActAsSwitcherFetcherConnected = connect(null, {
	retrievePortCallActAs,
	resetPortCallActAs
})(PortCallActAsSwitcherFetcher);

interface PortCallActAsSwitcherProps {
	portCallId: string;
	options: AuthActAsOption[];
	portJobStatus: PortJobStatus;
}
const PortCallActAsSwitcher: React.SFC<PortCallActAsSwitcherProps> = ({
	options,
	portCallId,
	portJobStatus
}) => {
	const disabledMessage = React.useMemo(() => {
		if (
			portJobStatus === PortJobStatus.CREATED ||
			portJobStatus === PortJobStatus.CONFIRMED
		) {
			return `You can't act as this agent since it hasn't been appointed yet.`;
		} else {
			return '';
		}
	}, [portJobStatus]);

	return (
		<ActAsSwitcher
			initialConfig={{
				urlPrefix: `/portcalls/`,
				allowedUrlPrefixes: ['/bank-account-details'],
				disabledMessage,
				returnUrl: history.location.pathname
			}}
			options={options}
		>
			{() => <PortCallActAsSwitcherFetcherConnected portCallId={portCallId} />}
		</ActAsSwitcher>
	);
};

export default connect((state: AppState) => ({
	options: getActAsOptionsFlaggedForPortCall(state)
}))(PortCallActAsSwitcher);
