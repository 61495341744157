import { createSelector } from 'reselect';
import { size } from 'lodash';
import { AppState } from 'store-types';

import { getAssignedJobsDropdownOptions } from 'store/portCallDocuments/selectors';

import { getUploadedDocumentById } from './uploadedDocumentsSelectors';
import { getRequiredDocumentById } from './requiredDocumentsSelectors';
import { DocumentLevel } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { FormData } from 'sections/PortCall/Operations/OperationDocuments/components/NotIssuedDocumentModal/NotIssuedDocumentModalTypes';

const getRequiredDocumentData = createSelector(
	getRequiredDocumentById,
	getAssignedJobsDropdownOptions,
	(document, options) => {
		if (!document || !options) {
			return;
		}
		const availableJobCodes = options.map(o => o.code);
		const noJobToDefine =
			size(options) > 1 && document.level === DocumentLevel.ROTATION_STEP;
		return {
			jobs: noJobToDefine
				? []
				: document.level !== DocumentLevel.ROTATION_STEP // POI level document doesn't have job
				? [document.code]
				: availableJobCodes,

			levels: [document.level],
			comment: ''
		};
	}
);

const getUploadedDocumentData = createSelector(
	getUploadedDocumentById,
	document => {
		if (!document) {
			return;
		}
		return {
			jobs: document.applicability.jobs,
			levels: document.applicability.placeholders.map(p => p.level),
			comment: document.comment
		};
	}
);

const getNotIssuedModalDocument = (state: AppState, documentId: string) => {
	return (
		getRequiredDocumentData(state, documentId) ||
		getUploadedDocumentData(state, documentId)
	);
};

export const getIsNotIssuedFormJobsFieldVisible = createSelector(
	getNotIssuedModalDocument,
	getAssignedJobsDropdownOptions,
	(document, options) =>
		Boolean(
			document &&
				size(options) > 1 &&
				document.levels.includes(DocumentLevel.ROTATION_STEP)
		)
);

export const getNotIssuedFormInitialValues = (
	state: AppState,
	documentId: string
): Partial<FormData> => {
	const document = getNotIssuedModalDocument(state, documentId);

	if (!document) {
		return {};
	}
	return {
		jobs: document.jobs,
		comment: document.comment
	};
};

/**
 * Return true if any specific level (job, cargo, operation) selected
 * explicitly belongs to the job that's gonna be removed
 */
const getJobCodesForRemoveWithWarning = (
	state: AppState,
	documentId: string
) => {
	const requiredDocument = getRequiredDocumentById(state, documentId);
	const uploadedDocument = getUploadedDocumentById(state, documentId);
	if (
		requiredDocument &&
		requiredDocument.level !== DocumentLevel.ROTATION_STEP
	) {
		return [requiredDocument.code];
	}

	if (uploadedDocument) {
		const belongsToPOI = uploadedDocument.applicability.placeholders.find(
			placeholder => placeholder.level === DocumentLevel.ROTATION_STEP
		);
		return !belongsToPOI ? uploadedDocument.applicability.jobs : [];
	}
	return [];
};

export const getNotIssuedAssignedJobsDropdownOptionsFlagged = createSelector(
	getAssignedJobsDropdownOptions,
	getJobCodesForRemoveWithWarning,
	(options = [], jobCodes) => {
		return options.map(option => ({
			...option,
			warn: jobCodes.includes(option.code)
		}));
	}
);

export const getNotIssuedPortCallPlaceholderConcurrencyToken = createSelector(
	getUploadedDocumentById,
	(_state: AppState, _documentId: string, portCallPlaceholderId: string) =>
		portCallPlaceholderId,
	(document, portCallPlaceholderId) => {
		if (!document) {
			return;
		}
		const portCallPlaceholder = document.applicability.placeholders.find(
			placeholder => placeholder.id === portCallPlaceholderId
		);
		return portCallPlaceholder?.concurrencyToken;
	}
);
