import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Popover, Tooltip } from 'components/antd';
import { onClickEvent } from 'app-types';
import classnames from 'classnames';
import styles from './SettingsButton.module.scss';

interface SettingsButtonProps {
	content?: React.ReactNode;
	isLoading?: boolean;
	disabled?: boolean;
	disabledMessage?: string;
	handleClick?: (event: onClickEvent) => void;
	visible?: boolean;
	handleVisibleChange?: (visible: boolean) => void;
}

const SettingsButton: FC<SettingsButtonProps> = ({
	content,
	isLoading,
	disabled,
	disabledMessage,
	handleClick,
	visible,
	handleVisibleChange
}) => {
	const onClick = useCallback(
		(event: onClickEvent) => {
			if (handleClick) {
				event.stopPropagation();
				handleClick(event);
			}
		},
		[handleClick]
	);

	const [localVisible, setVisible] = useState(false);

	const handleLocalVisibleChange = (visible: boolean) => {
		setVisible(visible);
	};

	const button = useMemo(
		() => (
			<Button
				disabled={disabled}
				transparent
				type="primary"
				icon="settings"
				loading={isLoading}
				onClick={onClick}
				className={classnames({ [styles.disabledButton]: disabled })}
			/>
		),
		[disabled, isLoading, onClick]
	);

	/*
			No need to have a popover in case of disabled button
			+
			Chrome bug https://bugs.chromium.org/p/chromium/issues/detail?id=120132
			Disabled button does not receive mouse events
			workaround from Ant https://github.com/react-component/tooltip/issues/18
			Need to wrap disabled button with <span> and set `pointer-events: none`
			to disabled button
		*/
	if (disabled && disabledMessage) {
		return (
			<Tooltip title={disabledMessage} placement="left">
				<span>{button}</span>
			</Tooltip>
		);
	}
	return (
		<Popover
			content={content}
			type="dropdown"
			trigger="click"
			overlayClassName={styles.settingButtonPopover}
			placement="bottomRight"
			visible={visible || localVisible}
			onVisibleChange={handleVisibleChange || handleLocalVisibleChange}
		>
			{button}
		</Popover>
	);
};

export default SettingsButton;
