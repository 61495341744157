import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveBankAccountsAsync,
	retrieveBankAccountsSectionAsync,
	addBankAccountAsync,
	retrieveBankAccountAsync,
	resetBankAccounts,
	updateBankAccountAsync,
	retrieveLOBDAsync,
	setActiveBankId,
	downloadLOBDAsync
} from './actions';
import {
	onRetrieveBankAccounts,
	onAddBankAccount,
	onRetrieveBankAccount,
	onResetBankAccounts,
	onUpdateBankAccount,
	onRetrieveLOBD,
	onSetActiveBankId,
	onDownloadLOBD
} from './reducers';

import { initialState } from './bankAccountsState';

export default reducerWithInitialState(initialState)
	.cases(
		[
			retrieveBankAccountsAsync.started,
			retrieveBankAccountsSectionAsync.started
		],
		onRetrieveBankAccounts.started
	)
	.cases(
		[retrieveBankAccountsAsync.done, retrieveBankAccountsSectionAsync.done],
		onRetrieveBankAccounts.done
	)
	.cases(
		[retrieveBankAccountsAsync.failed, retrieveBankAccountsSectionAsync.failed],
		onRetrieveBankAccounts.failed
	)
	.case(retrieveBankAccountAsync.started, onRetrieveBankAccount.started)
	.case(retrieveBankAccountAsync.done, onRetrieveBankAccount.done)
	.case(retrieveBankAccountAsync.failed, onRetrieveBankAccount.failed)
	.case(addBankAccountAsync.started, onAddBankAccount.started)
	.case(addBankAccountAsync.done, onAddBankAccount.done)
	.case(addBankAccountAsync.failed, onAddBankAccount.failed)
	.case(resetBankAccounts, onResetBankAccounts)
	.case(updateBankAccountAsync.started, onUpdateBankAccount.started)
	.case(updateBankAccountAsync.done, onUpdateBankAccount.done)
	.case(updateBankAccountAsync.failed, onUpdateBankAccount.failed)
	.case(retrieveLOBDAsync.started, onRetrieveLOBD.started)
	.case(retrieveLOBDAsync.done, onRetrieveLOBD.done)
	.case(retrieveLOBDAsync.failed, onRetrieveLOBD.failed)
	.case(downloadLOBDAsync.started, onDownloadLOBD.started)
	.case(downloadLOBDAsync.done, onDownloadLOBD.done)
	.case(downloadLOBDAsync.failed, onDownloadLOBD.failed)
	.case(setActiveBankId, onSetActiveBankId);
