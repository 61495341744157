import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { PageHeader } from 'components';
import { Row, Col, Select } from 'components/antd';
import { setDashboardTableauCompanyId } from 'store/dashboard/actions';
import { getDashboardTableauCompanyId } from 'store/dashboard/selectors';
import { getAllCompaniesSelector } from 'store/companies/selectors';

import {
	Company,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { DataFetcher } from 'utils/components';
import { retrieveCompanies, resetCompanies } from 'store/companies';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { DashboardTableau } from 'services/api/dashboard/dashboardServiceTypes';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';

interface TableauHeaderProps {
	tab: DashboardTableau;
}

const TableauHeader: FC<TableauHeaderProps> = ({ tab: { code } }) => {
	const dispatch = useDispatch();
	const companyId = useSelector(getDashboardTableauCompanyId);
	const companies = useSelector(getAllCompaniesSelector, shallowEqual);
	const userType = useSelector(getUserType);

	const retrievePrincipals = useCallback(
		() =>
			dispatch(
				retrieveCompanies({
					actionType: [PermissionCode.VIEW_PORTCALLS],
					organisationTypes: [OrganisationType.MAIN_PRINCIPAL],
					forOperationalDashboard: code === 'Operations'
				})
			),
		[code, dispatch]
	);

	const resetPrincipals = useCallback(() => dispatch(resetCompanies()), [
		dispatch
	]);

	if (userType !== UserType.HUB) {
		return null;
	}

	return (
		<PageHeader shadowed>
			<Row>
				<Col sm={3}>
					<DataFetcher
						dispatch={retrievePrincipals}
						reset={resetPrincipals}
						isLoading={false}
					>
						{() => (
							<>
								Company
								<Select
									disabled={!companies.length}
									value={companyId}
									onSelect={(value: string) => {
										dispatch(setDashboardTableauCompanyId(value));
									}}
								>
									{companies.map((company: Company) => (
										<Select.Option key={company.id} value={company.id}>
											{company.companyName}
										</Select.Option>
									))}
								</Select>
							</>
						)}
					</DataFetcher>
				</Col>
			</Row>
		</PageHeader>
	);
};

export default TableauHeader;
