import React from 'react';
import { Text } from 'components';
import numeral from 'numeral';
import { InputNumber } from 'components/antd';
import {
	FinanceFundingFormField,
	FINANCE_FUNDING_FORM_NAME
} from 'store/form/financeFundings/financeFundingTypes';
import { change, touch } from 'redux-form';
import { connect } from 'react-redux';
import { FormChangeCheckbox } from 'app-types';
import { getFinanceCurrencyDecimalPart } from 'store/finance/selectors';
import {
	FundingRequest,
	BaseAmountTypeCodes
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import {
	getDaInAmount,
	getTargetFieldName,
	calculatePercentValue,
	calculateAmountValue,
	getPreFundFieldName,
	getPrefundTargetFieldName
} from './FundingRequestNumberInput.func';
import { AppState } from 'store-types';
import {
	SendFinanceFormFields,
	SEND_FINANCE_FORM
} from 'sections/PortCall/Finance/Header/SendFinanceModal/SendFinanceModalTypes';

const PERCENT_DECIMAL_PART = '2';

interface FundingNumberInputProps {
	fieldName: FinanceFundingFormField;
	value: number;
	isEditable: boolean;
	index?: number;
	onChange?: FormChangeCheckbox;
	onBlur?: FormChangeCheckbox;
	decimalPart: number;
	targetDecimalPart: number;
}

export const FundingNumberInput = (props: FundingNumberInputProps) => {
	if (!props.isEditable && props?.index === null) {
		return <Text>{props.value}</Text>;
	}
	const field =
		props.fieldName === FinanceFundingFormField.AMOUNT ||
		props.fieldName === FinanceFundingFormField.PERCENT;
	const prefund = getPreFundFieldName(props.fieldName);

	return (
		<InputNumber.ReduxFormItem
			required
			name={
				field
					? props.fieldName
					: `${SendFinanceFormFields.FUNDING_TABLE}.${props?.index}.${prefund}`
			}
			onChange={props.onChange}
			onBlur={props.onBlur}
			disabled={!props.isEditable}
			integerPart={13}
			maxDigits={15}
			decimalPart={props.decimalPart}
		/>
	);
};

interface FundingCalculatedInputProps extends FundingNumberInputProps {
	change: typeof change;
	touch: typeof touch;
	funding: FundingRequest;
	currencyCode?: string;
	targetCurrencyCode?: string;
}

class FundingCalculatedInput extends React.Component<
	FundingCalculatedInputProps
> {
	relatedFieldValue: string | null = null;
	baseAmountTypeCode: BaseAmountTypeCodes | null;

	componentDidUpdate(prev: FundingCalculatedInputProps) {
		// Recalculated local state corresponding to amount base chosen in Redux Form
		if (
			this.props.funding.baseAmountTypeCode !==
				prev.funding.baseAmountTypeCode &&
			this.props.fieldName === FinanceFundingFormField.AMOUNT
		) {
			this.recalculateInputs(String(this.props.value));
		}
	}

	recalculateInputs = (textValue: string) => {
		const {
			fieldName,
			targetDecimalPart,
			index,
			funding: {
				balanceForDaInAmount,
				balanceForDaInAmountWithoutAgencyCosts,
				baseAmountTypeCode,
				balanceAmount
			}
		} = this.props;
		const value = Number(numeral(textValue).format('0.[0000]'));
		const amount = getDaInAmount(
			balanceForDaInAmount,
			balanceForDaInAmountWithoutAgencyCosts,
			baseAmountTypeCode
		);
		const targetFieldName = getTargetFieldName(fieldName);
		const targetPrefundFieldName = getPrefundTargetFieldName(fieldName);
		const prefund = getPreFundFieldName(fieldName);

		const resultValue =
			fieldName === FinanceFundingFormField.PERCENT
				? calculatePercentValue(value, amount)
				: calculateAmountValue(value, amount);
		const prefundResultValue =
			fieldName === FinanceFundingFormField.FUNDING_PERCENT
				? calculatePercentValue(value, Number(balanceAmount))
				: calculateAmountValue(value, Number(balanceAmount));

		const result = numeral(resultValue).format(
			`0.[${'0'.repeat(targetDecimalPart)}]`
		);

		const prefundResult = numeral(prefundResultValue).format(
			`0.[${'0'.repeat(targetDecimalPart)}]`
		);

		if (balanceAmount) {
			this.props.change(
				SEND_FINANCE_FORM,
				`${SendFinanceFormFields.FUNDING_TABLE}.${index}.${targetPrefundFieldName}`,
				prefundResult
			);
			this.props.touch(
				SEND_FINANCE_FORM,
				`${SendFinanceFormFields.FUNDING_TABLE}.${index}.${targetPrefundFieldName}`,
				prefund
			);
			this.relatedFieldValue = prefundResult;
		} else {
			this.props.change(FINANCE_FUNDING_FORM_NAME, targetFieldName, result);
			this.props.touch(FINANCE_FUNDING_FORM_NAME, targetFieldName, fieldName);
			this.relatedFieldValue = result;
		}
	};

	recalculateRoundings = () => {
		const {
			fieldName,
			index,
			funding: {
				balanceForDaInAmount,
				balanceForDaInAmountWithoutAgencyCosts,
				baseAmountTypeCode,
				balanceAmount
			}
		} = this.props;

		if (
			fieldName === FinanceFundingFormField.FUNDING_PERCENT &&
			this.relatedFieldValue !== null
		) {
			const recalculatedPercentage =
				Number(this.relatedFieldValue) / (Number(balanceAmount) / 100);
			const roundedPercentage = Number(
				numeral(recalculatedPercentage).format('0.[00]')
			);
			this.props.change(
				SEND_FINANCE_FORM,
				`${SendFinanceFormFields.FUNDING_TABLE}.${index}.${FinanceFundingFormField.PERCENT}`,
				String(roundedPercentage)
			);
		}

		if (
			fieldName === FinanceFundingFormField.PERCENT &&
			this.relatedFieldValue !== null
		) {
			const amount = getDaInAmount(
				balanceForDaInAmount,
				balanceForDaInAmountWithoutAgencyCosts,
				baseAmountTypeCode
			);

			const recalculatedPercentage =
				Number(this.relatedFieldValue) / (amount / 100);
			const roundedPercentage = Number(
				numeral(recalculatedPercentage).format('0.[00]')
			);

			this.props.change(
				FINANCE_FUNDING_FORM_NAME,
				FinanceFundingFormField.PERCENT,
				String(roundedPercentage)
			);
		}
		this.relatedFieldValue = null;
	};

	// Handle the dependency between funding percent and funding amount inputs
	onUpdateValue = (
		_e: React.ChangeEvent<HTMLInputElement>,
		textValue: string
	) => this.recalculateInputs(textValue);

	// After the amount gets rounded - percent value becomes irrelevant and needs to be updated either
	onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.recalculateRoundings();
		event.preventDefault();
	};

	render() {
		const { value, isEditable, fieldName, decimalPart, index } = this.props;
		return (
			<FundingNumberInput
				onChange={this.onUpdateValue}
				onBlur={this.onBlur}
				fieldName={fieldName}
				index={index}
				key={index}
				value={value}
				isEditable={isEditable}
				decimalPart={decimalPart}
				targetDecimalPart={decimalPart}
			/>
		);
	}
}

export const FundingCalculatedInputContainer = connect(
	(
		state: AppState,
		{
			currencyCode,
			targetCurrencyCode
		}: Pick<FundingCalculatedInputProps, 'targetCurrencyCode' | 'currencyCode'>
	) => {
		return {
			decimalPart: getFinanceCurrencyDecimalPart(
				state,
				currencyCode,
				PERCENT_DECIMAL_PART
			),
			targetDecimalPart: getFinanceCurrencyDecimalPart(
				state,
				targetCurrencyCode,
				PERCENT_DECIMAL_PART
			)
		};
	},
	{
		change,
		touch
	}
)(FundingCalculatedInput);
