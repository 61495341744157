import Api from 'services/api';
import { deleteCompanyVesselAsync } from 'store/companyVessels/actions';
import {
	DeleteCompanyVesselResponse,
	DeleteCompanyVesselRequest
} from 'services/api/companies/companiesServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';

export default makeTakeLatestWatcher<
	DeleteCompanyVesselRequest,
	DeleteCompanyVesselResponse,
	Error
>({
	api: Api.Companies.deleteCompanyVessel,
	async: deleteCompanyVesselAsync
});
