import { Success } from 'typescript-fsa';
import { put, select } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { CancelCreditSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import {
	cancelCreditSupplierInvoiceAsync,
	retrieveSupplierInvoices
} from 'store/supplierInvoice/actions';
import { getSupplierInvoiceIndex } from 'store/supplierInvoice/selectors';

const api = Api.SupplierInvoice.cancelCreditSupplierInvoice;

export default makeTakeEveryWatcher<
	CancelCreditSupplierInvoiceRequest,
	void,
	Error
>({
	api,
	async: cancelCreditSupplierInvoiceAsync,
	*onSuccess(action: Success<CancelCreditSupplierInvoiceRequest, void>) {
		const supplierInvoiceIndex = yield select(
			getSupplierInvoiceIndex,
			action.params.supplierInvoiceId
		);
		if (supplierInvoiceIndex !== -1) {
			yield put(
				retrieveSupplierInvoices({
					limit: 1,
					index: supplierInvoiceIndex
				})
			);
		}
	}
});
