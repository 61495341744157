import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { DEFAULT_DATE_FORMAT, ISO_DATE_FORMAT } from 'app-constants';

const getDateFormatted = (format: string) => (value: string) =>
	moment.utc(value).format(format);

// Used as hack to remove `Z` in date time, so that it's not treated as UTC, but PLT
// Note: `utc` will apply adjustment in case of non-zero offset
export const getLocalDateDefaultFormatted = getDateFormatted(
	DEFAULT_DATE_FORMAT
);

// Helper to remove UTC related information (`Z` suffix)
// to treat it as local in `DatePickerExtended`
// Should be temporary until BE updates PLT dates to be returned as local time (without suffix `Z`)
export const getUTCDateFormattedToLocal = getDateFormatted(ISO_DATE_FORMAT);

export const getTimezoneOffset = (timezoneId: string) =>
	momentTimezone.tz(timezoneId).format('Z');
