import {
	put,
	select,
	takeLatest,
	all,
	fork,
	take,
	race
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	setVesselTypesTabMode,
	updateActiveCompanyVesselType
} from '../actions';
import { getActiveCompanyId, getVesselTypesTabMode } from '../selectors';
import {
	updateCompanyVesselTypeAsync,
	updateCompanyVesselType
} from 'store/companyVesselTypes/actions';
import { CreateUpdateCompanyVesselTypeRequestData } from 'services/api/companies/companiesServiceTypes';

import { Action } from 'typescript-fsa';
import { VesselTypesMode, VesselTypesTabMode } from '../configurationState';

export function* worker({
	payload: { name, externalCode }
}: Action<CreateUpdateCompanyVesselTypeRequestData>): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	const tabMode: VesselTypesTabMode = yield select(getVesselTypesTabMode);
	const id = tabMode.vesselTypeId as string;
	yield put(
		updateCompanyVesselType({
			companyId,
			id,
			data: { name: name.trim(), externalCode }
		})
	);
	yield race({
		done: take(updateCompanyVesselTypeAsync.done),
		failed: take(updateCompanyVesselTypeAsync.failed)
	});
	yield put(setVesselTypesTabMode({ mode: VesselTypesMode.DEFAULT }));
}

function* watcher(): SagaIterator {
	yield takeLatest(updateActiveCompanyVesselType, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
