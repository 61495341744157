import { Success } from 'typescript-fsa';
import { SupplierInvoiceState } from '../supplierInvoiceState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse
} from '../actions';

const doneReducer = (
	state: SupplierInvoiceState,
	action: Success<
		RetrieveSplitSupplierInvoiceServiceDocumentRequest,
		RetrieveSplitSupplierInvoiceServiceDocumentResponse
	>
): SupplierInvoiceState => ({
	...state,
	splitSupplierInvoiceServiceDocument: action.result
});

export const onRetrieveSplitSupplierInvoiceDocument = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse
>('fetchStatuses', 'retrieveSplitSupplierInvoiceServiceDocument', {
	doneReducer
});
