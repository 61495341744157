import actionCreator from '../wizardFormActionCreator';

import { WizardFormPageState, WizardFormBaseAction } from '../wizardFormState';

export interface UpdatePageStateAction extends WizardFormBaseAction {
	pages: {
		[key: string]: WizardFormPageState;
	};
}

export const updatePageState = actionCreator<UpdatePageStateAction>(
	'UPDATE_PAGE_STATE'
);
