import { LabeledValue } from 'app-types';

export enum ClonePortCall {
	INSIDE_PORTCALL = 'insidePortCall',
	NEW_PORTCALL = 'newPortCall'
}

export const CLONE_PORTJOB_MODAL_FORM = 'clonePortJobModalForm';

export interface ClonePortJobFormData {
	isNewPortCall: string;
	enableIntegration: string;
	vessel: LabeledValue<string>;
	port: LabeledValue<string>;
	eta: string;
	ets: string;
	performingAgent: LabeledValue<string> | null;
	controllingAgent: LabeledValue<string> | null;
}

export enum ClonePortJobFormFieldName {
	IS_NEW_PORTCALL = 'isNewPortCall',
	ENABLE_INTEGRATION = 'enableIntegration',
	VESSEL = 'vessel',
	PORT = 'port',
	ETA = 'eta',
	ETS = 'ets',
	PERFORMING_AGENT = 'performingAgent',
	CONTROLLING_AGENT = 'controllingAgent'
}

export enum ClonePortCallOption {
	CLONE_INSIDE = 'cloneInside',
	NEW_PORTCALL = 'newPortCall'
}

export enum IntegrationOption {
	YES = 'yes',
	NO = 'no'
}

export interface ParentCloneJobData {
	vessel: LabeledValue<string>;
	port: LabeledValue<string>;
	eta: string;
	ets: string;
}
