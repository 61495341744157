import { FormErrors } from 'redux-form';
import {
	CargoLineFormDataTypes,
	CargoLineDischargeOperation
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { first } from 'lodash';
import runValidations, {
	charMax,
	validateRequired,
	checkDecimalFormat,
	isDateValid
} from 'utils/validations';

/**
 * Run validations for each field
 * @param  {CargoLineFormDataTypes} values List of form values
 */
const validate = (
	values: CargoLineDischargeOperation
): FormErrors<CargoLineFormDataTypes> => ({
	referenceNumber: first(runValidations(values.referenceNumber, [charMax(20)])),
	principalVoyageReference: first(
		runValidations(values.principalVoyageReference, [charMax(20)])
	),
	mainCommodity: validateRequired(values.mainCommodity),
	commodityQuantity: first(
		runValidations(values.commodityQuantity, [checkDecimalFormat(15, 4)])
	),
	charterPartyDate: first(
		runValidations(values.charterPartyDate, [isDateValid])
	)
});

export default validate;
