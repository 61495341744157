import { LabeledValue } from 'app-types';
import { AxiosError } from 'axios';
import { FetchStatus } from 'services/api/apiTypes';

import {
	User,
	UserProfile,
	UserSearchGroupType,
	UserStatusesOption
} from 'services/api/users/userServiceTypes';

export type UserFilterSearchGroupType = UserSearchGroupType | 'Search';
interface UserFilterSearchItem {
	type: UserFilterSearchGroupType;
	id: string;
	name: string;
}

export interface UserFilters {
	status?: LabeledValue<string>;
	search?: UserFilterSearchItem[];
}

export interface UsersState {
	readonly byId: { [key: string]: User };
	readonly allIds: string[];
	readonly profile: UserProfile | null;
	readonly statuses?: UserStatusesOption[];
	readonly filters: UserFilters;
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		all: FetchStatus;
		inviting: FetchStatus;
		single: FetchStatus; // fetching single entity
		updating: FetchStatus;
		updatingStatus: FetchStatus;
		retrievingStatuses: FetchStatus;
		resettingPassword: FetchStatus;
	};
	readonly context: {
		[key: string]: {} | boolean | string | undefined;
	};
	readonly error: Error | AxiosError | false;
}

export const initialState: UsersState = {
	context: {},
	profile: null,
	byId: {},
	allIds: [],
	filters: {},
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE,
		inviting: FetchStatus.IDLE,
		single: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		updatingStatus: FetchStatus.IDLE,
		retrievingStatuses: FetchStatus.IDLE,
		resettingPassword: FetchStatus.IDLE
	},
	error: false
};
