import { RegistrationState } from '../registrationState';
import { BrowserTimezone } from '../settings';
import { Success, Failure } from 'typescript-fsa';
import { RegisterUserRequest } from 'services/api/registration/registrationServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveUserByTokenStarted = (
	state: RegistrationState
): RegistrationState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.PENDING
	}
});
export const onRetrieveUserByTokenFailed = (
	state: RegistrationState,
	action: Failure<{}, boolean>
): RegistrationState => ({
	...state,
	invitationToken: '',
	error: action.error,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.FAILURE
	}
});
export const onRetrieveUserByTokenSuccess = (
	state: RegistrationState,
	action: Success<string, RegisterUserRequest>
): RegistrationState => ({
	...state,
	invitationToken: action.params,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.SUCCESS
	},
	initialValues: {
		...state.initialValues,
		...action.result,
		timeZone: BrowserTimezone
	}
});
