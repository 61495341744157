import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Input, TextArea } from 'components/antd';
import { Align, Flex, Loading, FormattedTimeExtended } from 'components';
import { Justify } from 'components/types';
import {
	IInternalComment,
	EditComposeMessageMetaData
} from 'services/api/threads/threadsServiceTypes';
import {
	failedRetrievingInternalCommentsSelector,
	isFetchingInternalCommentsSelector,
	isPostingInternalCommentsSelector,
	getInternalCommentsForThreadId
} from 'store/internalComments/internalCommentsSelectors';
import {
	postInternalComments,
	retrieveInternalComments
} from 'store/internalComments';
import styles from './InternalComments.module.scss';
import { getCurrentUser } from 'store/auth/selectors';
import { UserAuth } from 'services/api/users/userServiceTypes';
import { saveEditThreadData } from 'store/threads/actions';
import { getEditThreadData } from 'store/threads/selectors';
import { AppState } from 'store-types';

interface InternalCommentProps {
	comment: IInternalComment;
	currentUserId: string;
}

interface InternalCommentsProps {
	threadId: string;
	comments: IInternalComment[];
	canSeeInternalComments: boolean;
	isFetching: boolean;
	isPosting: boolean;
	retrieveInternalComments: typeof retrieveInternalComments;
	postInternalComments: typeof postInternalComments;
	hasSendButton?: boolean;
	currentUser: UserAuth;
	savedThreadData: EditComposeMessageMetaData;

	saveEditThreadData: typeof saveEditThreadData;
}

interface InternalCommentsState {
	comment: string;
}

export class InternalComments extends React.Component<
	InternalCommentsProps,
	InternalCommentsState
> {
	static defaultProps = {
		comments: []
	};
	static Comment: React.SFC<InternalCommentProps>;

	constructor(props: InternalCommentsProps) {
		super(props);
		this.state = { comment: props.savedThreadData.comment || '' };
	}

	componentDidMount() {
		this.props.retrieveInternalComments(this.props.threadId);
	}

	componentDidUpdate(prevProps: InternalCommentsProps) {
		// clear comment text if fetching was done
		if (!this.props.isFetching && prevProps.isFetching) {
			this.setState({ comment: '' });
		}
	}

	onInputEnterPressed = () => {
		if (!this.props.hasSendButton) {
			this.postComment();
		}
	};

	onSendPressed = () => {
		this.postComment();
	};

	postComment() {
		this.props.postInternalComments({
			threadId: this.props.threadId,
			text: this.state.comment
		});
	}

	onChange = (
		e: React.FormEvent<HTMLTextAreaElement> | React.FormEvent<HTMLInputElement>
	) => {
		this.setState({ comment: e.currentTarget.value });
		this.props.saveEditThreadData({ comment: e.currentTarget.value });
	};

	render() {
		const {
			comments,
			canSeeInternalComments,
			isFetching,
			isPosting,
			hasSendButton,
			currentUser
		} = this.props;
		if (!canSeeInternalComments) {
			return null;
		}
		return (
			<section>
				<Loading show={isFetching} />
				{!isFetching && (
					<div>
						{comments.map(comment => (
							<InternalComments.Comment
								currentUserId={currentUser.id}
								comment={comment}
								key={comment.id}
							/>
						))}
						<Align align="right">
							{hasSendButton ? (
								<>
									<TextArea
										onPressEnter={this.onInputEnterPressed}
										className={styles.commentBoxInput}
										maxLength={256}
										placeholder="Your comment"
										disabled={isPosting}
										value={this.state.comment}
										onChange={this.onChange}
									/>
									<Button
										ghost
										type="primary"
										onClick={this.onSendPressed}
										className={styles.sendButton}
									>
										Send
									</Button>
								</>
							) : (
								<Input
									onPressEnter={this.onInputEnterPressed}
									className={styles.commentBoxInput}
									maxLength={256}
									placeholder="Your comment"
									disabled={isPosting}
									value={this.state.comment}
									onChange={this.onChange}
								/>
							)}
						</Align>
					</div>
				)}
			</section>
		);
	}
}

InternalComments.Comment = ({
	comment,
	currentUserId
}: InternalCommentProps) => {
	const align = currentUserId === comment.userId ? 'end' : 'start';

	const userNameLine = `${comment.userName} (${comment.userType})`;

	return (
		<div className={styles.comment}>
			<Flex justify={Justify.BETWEEN} direction="vertical" align={align}>
				<span className={styles.userName}>{userNameLine}</span>
				<FormattedTimeExtended
					value={comment.createdOn}
					convertFromUtc
					showLocalTime
				/>
			</Flex>
			<div className={styles.commentContent}>{comment.text}</div>
		</div>
	);
};

export default connect(
	(state: AppState, { threadId }: Pick<InternalCommentsProps, 'threadId'>) => ({
		comments: getInternalCommentsForThreadId(state, threadId),
		canSeeInternalComments: !failedRetrievingInternalCommentsSelector(state),
		isFetching: isFetchingInternalCommentsSelector(state),
		isPosting: isPostingInternalCommentsSelector(state),
		savedThreadData: getEditThreadData(state),
		currentUser: getCurrentUser(state)
	}),
	{
		retrieveInternalComments,
		postInternalComments,
		saveEditThreadData
	}
)(InternalComments);
