import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrievePortByIdAsync,
	retrieveAnchoragesAsync,
	retrieveBuoysAsync,
	retrieveCanalsAsync,
	retrievePortsAsync,
	resetPorts
} from './actions';
import {
	onRetrievePortId,
	onRetrieveAnchoragesStarted,
	onRetrieveAnchoragesSuccess,
	onRetrieveAnchoragesFailed,
	onRetrieveBuoysStarted,
	onRetrieveBuoysSuccess,
	onRetrieveBuoysFailed,
	onRetrieveCanals,
	onRetrievePorts,
	onResetPorts
} from './reducers';
import { initialState } from './portsState';
export default reducerWithInitialState(initialState)
	.case(retrievePortByIdAsync.started, onRetrievePortId.started)
	.case(retrievePortByIdAsync.done, onRetrievePortId.done)
	.case(retrievePortByIdAsync.failed, onRetrievePortId.failed)
	.case(retrieveAnchoragesAsync.started, onRetrieveAnchoragesStarted)
	.case(retrieveAnchoragesAsync.done, onRetrieveAnchoragesSuccess)
	.case(retrieveAnchoragesAsync.failed, onRetrieveAnchoragesFailed)
	.case(retrieveBuoysAsync.started, onRetrieveBuoysStarted)
	.case(retrieveBuoysAsync.done, onRetrieveBuoysSuccess)
	.case(retrieveBuoysAsync.failed, onRetrieveBuoysFailed)
	.case(retrieveCanalsAsync.started, onRetrieveCanals.started)
	.case(retrieveCanalsAsync.done, onRetrieveCanals.done)
	.case(retrieveCanalsAsync.failed, onRetrieveCanals.failed)
	.case(retrievePortsAsync.started, onRetrievePorts.started)
	.case(retrievePortsAsync.done, onRetrievePorts.done)
	.case(retrievePortsAsync.failed, onRetrievePorts.failed)
	.case(resetPorts, onResetPorts);
