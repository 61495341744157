import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';

import Api from 'services/api';

import { retrieveGroup, retrieveGroupAsync } from '../actions/retrieveGroup';

export function* retrieveGroupWorker(action: Action<string>): SagaIterator {
	yield put(retrieveGroupAsync.started(action.payload));
	try {
		const response = yield call(Api.Groups.retrieveGroup, action.payload);
		yield put(
			retrieveGroupAsync.done({
				result: response.data,
				params: action.payload,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveGroupAsync.failed({
				error,
				params: action.payload
			})
		);
	}
}

export function* retrieveGroupWatcher(): SagaIterator {
	yield takeLatest(retrieveGroup.type, retrieveGroupWorker);
}
