import { combineReducers } from 'redux';
import { MasterdataState } from './masterdataState';

import ports from './ports/portsReducer';
import vessels from './vessels/vesselsReducer';
import bankAccounts from './bankAccounts/bankAccountsReducer';
import regions from './regions/regionsReducer';
import services from './services/servicesReducer';

export default combineReducers<MasterdataState>({
	ports,
	vessels,
	bankAccounts,
	regions,
	services
});
