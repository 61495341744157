import Api from 'services/api';
import {
	retrieveGroupTypes as action,
	retrieveGroupTypesAsync as asyncAction
} from '../actions/retrieveGroupTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';
import { RetrieveGroupTypesRequest as request } from 'services/api/groups/groupsServiceTypes';

const apiCall = Api.Groups.retrieveGroupTypes;

function* executor(actionParams: request, api: typeof apiCall): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(
			asyncAction.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
