import Api from 'services/api';
import {
	deleteUnitCostForm as action,
	deleteUnitCostFormAsync as asyncAction
} from 'store/finance/actions/deleteUnitCostForm';

import {
	DeleteUnitCostForm as request,
	DeleteUnitCostFormRequest
} from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';

import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { getServiceById } from '../selectors/financeSelectors';
import { isConcurrencyError } from 'services/api/apiErrorUtils';
import { notifyConcurrencyError } from './updateServiceSaga';
import { notify } from 'store/notifications/actions';

const apiCall = Api.Finance.deleteUnitForm;

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const portCallId = yield select(getActivePortCallId);
		const portJobCode = yield select(getActivePortJobCode);
		const serviceId = actionParams.jobServiceId;
		const unitCostRateId = actionParams.id;
		const vendorAmountChange = actionParams.vendorAmountChange;

		const payload = {
			portCallId,
			portJobCode,
			serviceId,
			unitCostRateId
		} as DeleteUnitCostFormRequest;

		const response = yield call(api, payload);

		yield put(
			asyncAction.done({
				result: response.data,
				params: actionParams,
				response
			})
		);

		if (vendorAmountChange) {
			const { name } = yield select(getServiceById, actionParams.jobServiceId);

			yield put(
				notify.info(
					`Unit cost associated to ${name} has been removed due to change in amount`
				)
			);
		}
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
		if (isConcurrencyError(error) && actionParams.jobServiceId) {
			const { name } = yield select(getServiceById, actionParams.jobServiceId);
			yield put(notifyConcurrencyError(name));
		}
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeEvery(action.type, worker);
}
