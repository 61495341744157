import { AxiosTypedPromise } from 'services/api/apiTypes';
import queryString from 'query-string';
import client from 'services/client';
import { SupplierInvoicesGroupType } from 'store/supplierInvoice/filtersSync';
import {
	RetrieveSupplierInvoicesParams,
	SearchSupplierInvoicesResponse,
	AddSupplierInvoiceRequest,
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse,
	RetrieveSupplierInvoiceContextualizationForUpdateRequest,
	RetrieveSupplierInvoiceContextualizationForUpdateResponse,
	UpdateSupplierInvoiceRequest,
	DownloadSupplierInvoiceDocumentRequest,
	DownloadForServiceSupplierInvoiceDocumentRequest,
	CancelCreditSupplierInvoiceRequest,
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse,
	RestoreOriginalSupplierInvoiceDocumentRequest,
	DeleteSupplierDocumentPageRequest
} from './supplierInvoicesServiceTypes';
import { RetrieveDownloadDocumentResponse } from '../documents/documentsServiceTypes';
import {
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse
} from 'store/supplierInvoice/actions';

const retrieveSupplierInvoices = (request: RetrieveSupplierInvoicesParams) => {
	return client.get('finances/supplier-invoices', {
		params: request,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, {
				arrayFormat: 'index'
			})
	});
};

const retrieveSupplierInvoice = (
	supplierInvoiceId: RetrieveSupplierInvoiceParams
) =>
	client.get<RetrieveSupplierInvoiceResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}`
	);

const retrieveSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: RetrieveSupplierInvoiceServiceDocumentRequest) =>
	client.get<RetrieveSupplierInvoiceServiceDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/documentForService/${serviceId}`
	);

const retrieveSplitSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: RetrieveSplitSupplierInvoiceServiceDocumentRequest) =>
	client.get<RetrieveSplitSupplierInvoiceServiceDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/documentForService/${serviceId}/split-pages`
	);

const retrieveSupplierInvoiceForPostCreditContextualization = (
	supplierInvoiceId: RetrieveSupplierInvoiceParams
) =>
	client.get<RetrieveSupplierInvoiceResponse>(
		`finances/supplier-invoices/contextualizations/post-credit/${supplierInvoiceId}`
	);

const createSupplierInvoice = (data: AddSupplierInvoiceRequest) =>
	client.post('finances/supplier-invoices', data);

const createSupplierInvoiceViaPostCredit = (data: AddSupplierInvoiceRequest) =>
	client.post('finances/supplier-invoices/post-credit', data);

const updateSupplierInvoice = (data: UpdateSupplierInvoiceRequest) =>
	client.put(`finances/supplier-invoices/${data.supplierInvoiceId}`, data);

const cancelCreditSupplierInvoice = ({
	supplierInvoiceId
}: CancelCreditSupplierInvoiceRequest) =>
	client.put(
		`/finances/supplier-invoices/${supplierInvoiceId}/cancelCreditSupplierInvoice`
	);

const searchSupplierInvoices = (
	searchTerm: string,
	groupType?: SupplierInvoicesGroupType,
	includeCancelled?: string
): AxiosTypedPromise<SearchSupplierInvoicesResponse> => {
	const params = {
		searchTerm,
		groupType,
		showMore: !!groupType,
		includeCancelled
	};

	return client.get<SearchSupplierInvoicesResponse>(
		`/finances/supplier-invoices/search`,
		{
			params
		}
	);
};

/**
 * There are 2 similar end-points. One for `Add` another for `Update`
 * Call this service only during Add Supplier Invoices
 * It'll retrieve the whole list of available services for contextualization
 */
const retrieveSupplierInvoiceContextualization = ({
	mainPrincipalId,
	supplierId,
	currencyCode
}: RetrieveSupplierInvoiceContextualizationRequest): AxiosTypedPromise<RetrieveSupplierInvoiceContextualizationResponse> =>
	client.get<RetrieveSupplierInvoiceContextualizationResponse>(
		`/finances/supplier-invoices/contextualizations/${mainPrincipalId}/${supplierId}/${currencyCode}`
	);

/**
 * Call this service only during Update Supplier Invoices
 * It's quite similar to `retrieveSupplierInvoiceContextualization`
 * with the execption of the last URL segment were we provide the active `supplierInvoiceId`
 * to tell the API to only retrieve the contextualized options for that Supplier Invoice
 */
const retrieveSupplierInvoiceContextualizationForUpdate = ({
	mainPrincipalId,
	supplierId,
	currencyCode,
	supplierInvoiceId
}: RetrieveSupplierInvoiceContextualizationForUpdateRequest): AxiosTypedPromise<RetrieveSupplierInvoiceContextualizationForUpdateResponse> =>
	client.get<RetrieveSupplierInvoiceContextualizationForUpdateResponse>(
		`/finances/supplier-invoices/contextualizationsForUpdate/${mainPrincipalId}/${supplierId}/${currencyCode}/${supplierInvoiceId}`
	);

const pollDownloadSupplierInvoiceDocument = ({
	supplierInvoiceId
}: DownloadSupplierInvoiceDocumentRequest) => {
	return client.get<RetrieveDownloadDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/download`
	);
};

const pollDownloadForServiceSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: DownloadForServiceSupplierInvoiceDocumentRequest) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/downloadForService/${serviceId}`
	);

const postDownloadForSplitServiceSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: DownloadForServiceSupplierInvoiceDocumentRequest) =>
	client.post<RetrieveDownloadDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/downloadForService/${serviceId}/split-pages`
	);

const pollDownloadForSplitServiceSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: DownloadForServiceSupplierInvoiceDocumentRequest) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`finances/supplier-invoices/${supplierInvoiceId}/downloadForService/${serviceId}/split-pages`
	);

const restoreOriginalSupplierInvoiceDocument = ({
	supplierInvoiceId,
	serviceId
}: RestoreOriginalSupplierInvoiceDocumentRequest) =>
	client.post(
		`finances/supplier-invoices/${supplierInvoiceId}/documentForService/${serviceId}/split-pages/restoreOriginal`
	);

const deleteSupplierDocumentPage = ({
	supplierInvoiceId,
	serviceId,
	actualNumber,
	pageType
}: DeleteSupplierDocumentPageRequest) =>
	client.delete(
		`finances/supplier-invoices/${supplierInvoiceId}/documentForService/${serviceId}/split-pages/pages/${actualNumber}/${pageType}`
	);

export default {
	retrieveSupplierInvoices,
	retrieveSupplierInvoice,
	retrieveSupplierInvoiceDocument,
	retrieveSupplierInvoiceForPostCreditContextualization,
	searchSupplierInvoices,
	createSupplierInvoice,
	createSupplierInvoiceViaPostCredit,
	updateSupplierInvoice,
	cancelCreditSupplierInvoice,
	retrieveSupplierInvoiceContextualization,
	retrieveSupplierInvoiceContextualizationForUpdate,
	pollDownloadSupplierInvoiceDocument,
	pollDownloadForServiceSupplierInvoiceDocument,
	retrieveSplitSupplierInvoiceDocument,
	postDownloadForSplitServiceSupplierInvoiceDocument,
	pollDownloadForSplitServiceSupplierInvoiceDocument,
	restoreOriginalSupplierInvoiceDocument,
	deleteSupplierDocumentPage
};
