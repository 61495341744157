import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getActivePortCallId } from 'store/portcalls/selectors';

const getPortCallAccessiblePortJobs = (state: AppState, portCallId: string) => {
	const accessiblePortJobs =
		state.portCallOperations.accessiblePortJobs[portCallId];
	return accessiblePortJobs || [];
};

export const getActivePortCallAccessiblePortJobs = createSelector(
	(state: AppState) => state,
	getActivePortCallId,
	(state, portCallId) => getPortCallAccessiblePortJobs(state, portCallId)
);
