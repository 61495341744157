import { all, fork } from 'redux-saga/effects';
import deleteGroupUserSaga from './deleteGroupUserSaga';
import addGroupUserSaga from './addGroupUserSaga';
import retrieveGroupUsersSaga from './retrieveGroupUsersSaga';
import deleteThirdPartyLPAGroupUser from './deleteThirdPartyLPAGroupUserSaga';
import inviteThirdPartyLpaUserWatcher from './inviteThirdPartyLpaUserSaga';

export default function*() {
	yield all([
		fork(deleteGroupUserSaga),
		fork(addGroupUserSaga),
		fork(retrieveGroupUsersSaga),
		fork(deleteThirdPartyLPAGroupUser),
		fork(inviteThirdPartyLpaUserWatcher)
	]);
}
