import React from 'react';
import { RenderElementProps } from 'slate-packages/packages/slate-react';
import { ElementType } from '../RichTextTypes';
import { BlockquoteElement, PreElement, ImageElement } from './Elements';

export function Element(props: RenderElementProps): JSX.Element;
export function Element(
	props: Omit<RenderElementProps, 'attributes'> & { attributes: object }
): JSX.Element;

export function Element(props: RenderElementProps) {
	const {
		element: { type, data = {} },
		attributes: slateAttributes,
		children
	} = props;

	const attributes = {
		...slateAttributes,
		...data.attrs
	};

	switch (type) {
		case ElementType.DIV:
			return <div {...attributes}>{children}</div>;
		case ElementType.SPAN:
			return <span {...attributes}>{children}</span>;
		case ElementType.IMG:
			return <ImageElement {...props} />;
		case ElementType.P:
			return <p {...attributes}>{children}</p>;
		case ElementType.UL:
			return <ul {...attributes}>{children}</ul>;
		case ElementType.OL:
			return <ol {...attributes}>{children}</ol>;
		case ElementType.LI:
			return <li {...attributes}>{children}</li>;
		case ElementType.BLOCKQUOTE:
			return <BlockquoteElement {...props} />;
		case ElementType.PRE:
			return <PreElement {...props} />;
		case ElementType.TABLE:
			return <table {...attributes}>{children}</table>;
		case ElementType.THEAD:
			return <thead {...attributes}>{children}</thead>;
		case ElementType.TBODY:
			return <tbody {...attributes}>{children}</tbody>;
		case ElementType.TR:
			return <tr {...attributes}>{children}</tr>;
		case ElementType.TD:
			return <td {...attributes}>{children}</td>;
		case ElementType.TH:
			return <th {...attributes}>{children}</th>;
		case ElementType.HR:
			return (
				<>
					<hr {...attributes} />
					{children}
				</>
			);
		case ElementType.BR:
			return <br />;
		case ElementType.A:
			return <a {...attributes}>{children}</a>;
		case ElementType.H1:
			return <h1 {...attributes}>{children}</h1>;
		case ElementType.H2:
			return <h2 {...attributes}>{children}</h2>;
		case ElementType.H3:
			return <h3 {...attributes}>{children}</h3>;
		default:
			// only for presentation
			if (attributes['data-slate-node']) {
				return (
					<div {...attributes} data-section={data.section}>
						{children}
					</div>
				);
			}
			return children;
	}
}

export default Element;
