import { ThreadsState } from 'store/threads/threadsState';
import { uniq } from 'lodash';

export const onAssignJobsToNewThread = (
	state: ThreadsState,
	jobCodes: string[]
): ThreadsState => ({
	...state,
	jobCodesForNewThread: uniq([...state.jobCodesForNewThread, ...jobCodes])
});
