import { AppState } from 'store-types';
import { getValues } from 'store/form/selectors';
import {
	ClonePortCallOption,
	IntegrationOption,
	ClonePortJobFormData,
	CLONE_PORTJOB_MODAL_FORM
} from 'sections/PortCall/PortJob/ActionModal/ClonePortJobModal/constants';
import { createSelector } from 'reselect';
import { ClonePortJobRequestPayload } from 'services/api/portJobs/portJobsServiceTypes';
import {
	labelToNameField,
	optionalLabelToNameField
} from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest.func';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { FetchStatus } from 'services/api/apiTypes';

export const getClonePortJobFormData = (state: AppState) =>
	getValues<ClonePortJobFormData>(state, CLONE_PORTJOB_MODAL_FORM);

export const getClonePortJobRequestData = createSelector(
	getClonePortJobFormData,
	(formData): Partial<ClonePortJobRequestPayload> => {
		if (formData.isNewPortCall === ClonePortCallOption.CLONE_INSIDE) {
			return {
				isNewPortCall: false,
				enableIntegration: formData.enableIntegration === IntegrationOption.YES
			};
		} else {
			return {
				isNewPortCall: true,
				enableIntegration: formData.enableIntegration === IntegrationOption.YES,
				selectedVessel: labelToNameField(formData.vessel),
				selectedPort: labelToNameField(formData.port),
				newPerformAgent: optionalLabelToNameField(formData.performingAgent),
				newControlAgent: optionalLabelToNameField(formData.controllingAgent),
				eTA: getLocalDateDefaultFormatted(formData.eta),
				eTS: formData.ets
					? getLocalDateDefaultFormatted(formData.ets)
					: formData.ets
			};
		}
	}
);

export const isCloneJobFetchPending = (state: AppState) =>
	state.portjob.fetchStatuses.clonePortJob === FetchStatus.PENDING;
