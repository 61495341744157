import React, { FC, useMemo, useState, useCallback } from 'react';
import { MenuProps } from 'antd/lib/menu';
import { TooltipProps } from 'antd/lib/tooltip';
import { Button, Menu, MaybeTooltip, Popover } from 'components/antd';
import { ButtonProps } from 'components/antd/Button/Button';
import styles from './DropdownButton.module.scss';

type MenuItem = {
	key: string;
	label: string;
	disabled?: boolean;
	tooltipPlacement?: TooltipProps['placement'];
	tooltipTitle?: TooltipProps['title'];
};

type DropdownButtonProps = ButtonProps & {
	iconDisabled?: ButtonProps['disabled'];
	iconTooltip?: ButtonProps['tooltip'];
	menuProps: MenuProps;
	menuItems?: MenuItem[];
};

const getDocumentBody = () => document.body;

const DropdownButton: FC<DropdownButtonProps> = ({
	type = 'primary',
	children = 'Submit',
	onClick,
	tooltip,
	disabled,
	iconTooltip = {
		...tooltip,
		title: tooltip?.title,
		getPopupContainer: getDocumentBody
	},
	iconDisabled = disabled,
	menuProps,
	menuItems,
	...restButtonProps
}) => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const menuContent = useMemo(
		() => (
			<Menu selectable={false} {...menuProps}>
				{menuItems?.map(item => (
					<Menu.Item disabled={item.disabled} key={item.key}>
						<MaybeTooltip
							placement={item.tooltipPlacement}
							show={!!item.disabled}
							title={item.tooltipTitle}
							getPopupContainer={getDocumentBody}
						>
							{item.label}
						</MaybeTooltip>
					</Menu.Item>
				))}
			</Menu>
		),
		[menuProps, menuItems]
	);

	const onDropdownVisibilityChange = useCallback(
		isVisible => {
			setIsDropdownVisible(isVisible);
		},
		[setIsDropdownVisible]
	);

	return (
		<Button.Group className={styles.btnGroup}>
			<Button
				type={type}
				onClick={onClick}
				tooltip={disabled ? tooltip : undefined}
				disabled={disabled}
				className={styles.submitBtn}
				{...restButtonProps}
			>
				{children}
			</Button>
			<Popover
				content={menuContent}
				type="dropdown"
				trigger="click"
				placement="bottomRight"
				onVisibleChange={onDropdownVisibilityChange}
			>
				<Button
					type={type}
					icon={isDropdownVisible ? 'caret-up' : 'caret-down'}
					disabled={iconDisabled}
					tooltip={iconDisabled ? iconTooltip : undefined}
					transparent={restButtonProps.transparent}
					className={styles.iconBtn}
				/>
			</Popover>
		</Button.Group>
	);
};

export default DropdownButton;
