import React from 'react';

import Notifications from './Notifications/Notifications';
import Alerts from './Alerts/Alerts';
import FullPage from './FullPage/FullPage';
import PageTable from './Wrappers/PageTable/PageTable';

class NotificationsContainer extends React.Component {
	static Notification = Notifications;
	static Alert = Alerts;
	static FullPage = FullPage;

	static Table = PageTable;

	render() {
		return null;
	}
}

export default NotificationsContainer;
