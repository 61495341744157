import { updatePrincipalServiceAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	UpdatePrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';

export default makeTakeEveryWatcher<
	UpdatePrincipalServiceRequest,
	PrincipalService,
	Error
>({
	api: Api.Services.updatePrincipalService,
	async
});
