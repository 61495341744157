import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { orderedSectionCodes } from 'services/api/dashboard/dashboardServiceTypes';
import { keyBy, map, values, flatten, find, filter, sortBy } from 'lodash';
import { AlertMetricType } from 'app-constants';

const getJobSections = (state: AppState) => state.dashboard.sections;

export const getIsSectionsFirstLoad = (state: AppState) =>
	state.dashboard.context.isSectionsFirstLoad;

export const getSectionsFetchStatus = (state: AppState) =>
	state.dashboard.fetchStatuses.retrieveJobsSections;

const getPortCalls = (state: AppState) => state.dashboard.portCallsByStatusCode;

export const getPortCallsBySectionCode = createSelector(
	getPortCalls,
	(_state: AppState, code: string) => code,
	(portCalls, code) => portCalls[code] || []
);

export const getDashboardPortCallById = createSelector(
	getPortCalls,
	(_state: AppState, portCallId: string) => portCallId,
	(portCalls, portCallId) => {
		const allPortCalls = flatten(values(portCalls));
		const portCall = find(allPortCalls, ['id', portCallId]);
		return portCall;
	}
);

export const getUnifiedAlertsByPortCallId = createSelector(
	getDashboardPortCallById,
	portCall => {
		const alerts = portCall ? portCall.alerts : [];
		// Other than portCall duplicates alerts
		if (alerts?.length === 0) {
			const otherAlerts = portCall?.jobs.find(x => x.alerts);
			if (!otherAlerts) {
				return [];
			}
			const portJobStatusAlerts =
				otherAlerts &&
				filter(otherAlerts.alerts, [
					'metricType',
					AlertMetricType.PORT_JOB_STATUS
				]);
			const firstJobStatusAlert = sortBy(portJobStatusAlerts, [
				'metricCode'
			])[0];
			const filteredAlerts = otherAlerts?.alerts?.filter(
				alert => alert.metricType !== AlertMetricType.PORT_JOB_STATUS
			);
			const unifiedAlerts = filteredAlerts?.concat(
				firstJobStatusAlert ? [firstJobStatusAlert] : []
			);
			return unifiedAlerts;
		}
		// all alerts with metricType: "PortCallMeasurements" should be unified (replaced by first of them)
		const measurementAlerts = filter(alerts, [
			'metricType',
			AlertMetricType.PORT_CALL_MEASURMENTS
		]);
		const firstMeasurementAlert = sortBy(measurementAlerts, ['metricCode'])[0];
		const filteredAlerts = alerts.filter(
			alert => alert.metricType !== AlertMetricType.PORT_CALL_MEASURMENTS
		);
		const unifiedAlerts = filteredAlerts.concat(
			firstMeasurementAlert ? [firstMeasurementAlert] : []
		);
		return unifiedAlerts;
	}
);

export const getOrderedSections = createSelector(getJobSections, sections => {
	const byKey = keyBy(sections.elements, item => item.code);
	return map(orderedSectionCodes, el => byKey[el]).filter(el => el); // get rid of [{obj},undefined] if some section missing
});

export const getActiveSectionCode = (state: AppState) =>
	state.dashboard.context.activeSectionCode;
