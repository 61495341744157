import { IconType } from 'components/antd/Icon/IconTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

export enum PortCallTabKey {
	OVERVIEW = 'overview',
	VESSEL_PROGRAMME = 'vessel-programme',
	FINANCE = 'finance',
	OPERATIONS = 'operations',
	SAVINGS = 'savings',
	MESSAGES = 'messages'
}

export enum PortCallOperationsTabKey {
	EVENTS = 'events',
	DOCUMENTS = 'documents',
	MEASUREMENTS = 'measurements'
}

export enum PortCallOperationsMeasurementsTabKey {
	SHIP = 'ship',
	CARGO = 'cargo',
	BUNKERING = 'bunkering'
}

export interface PortCallTabType {
	tab: string;
	key: PortCallTabKey;
	path?: string;
	icon: keyof IconType;
	permissionCode?: PermissionCode | PermissionCode[];
}
export interface PortCallTabMaps {
	[key: string]: PortCallTabType;
}

export type PortCallTabsConfigType = { [key in PortCallTabKey]?: boolean };
