import { Success } from 'typescript-fsa';
import {
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse
} from 'services/api/directBills/directBillsServiceTypes';
import { DirectBillsState } from '../directBillsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveFullDirectBillSuccess = (
	state: DirectBillsState,
	{ result }: Success<RetrieveDirectBillRequest, RetrieveDirectBillResponse>
) => ({
	...state,
	context: {
		...state.context,
		activeDirectBillId: result.id,
		uploadedDocuments: result.documents,
		directBill: result
	}
});

export const onRetrieveFullDirectBill = makeFetchStatusReducers<
	DirectBillsState,
	'fetchStatuses',
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse
>('fetchStatuses', 'edit', {
	doneReducer: onRetrieveFullDirectBillSuccess
});
