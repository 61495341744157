import React from 'react';

import PortCallContainer from './PortCallContainer';
import PortCallLayout from './Layout/PortCallLayout';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { AppNotifications } from 'containers';

const PortCallRoute: React.SFC<PortCallTabBaseProps> = props => (
	<>
		<PortCallContainer match={props.match}>
			{() => <PortCallLayout {...props} />}
		</PortCallContainer>
		<AppNotifications.Notification />
	</>
);

export default PortCallRoute;
