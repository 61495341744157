import { Country } from 'services/api/countries/countriesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface CountriesState {
	readonly byCode: { [code: string]: Country };
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		all: FetchStatus;
		[key: string]: FetchStatus;
	};
}

export const initialState: CountriesState = {
	byCode: {},
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE
	}
};
