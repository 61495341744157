import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import {
	GRADE_LINE_MODAL,
	CANCEL_PROCESS_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { openModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { getIsGradeLineFormInEditMode } from 'store/portJobs/selectors';
import GradeLineForm, {
	GradeLineFormBaseProps
} from './GradeLineForm/GradeLineForm';
import { AppState } from 'store-types';

interface GradeLineModalProps extends Pick<GradeLineFormBaseProps, 'onSubmit'> {
	// from mapStateToProps
	isGradeModalVisible: boolean;
	isEditMode: boolean;
	// from mapDispatchToProps
	openModal: typeof openModal;
}

const GradeLineModal = ({
	onSubmit,
	isGradeModalVisible,
	isEditMode,
	...props
}: GradeLineModalProps) => {
	const onCancel = () => props.openModal(CANCEL_PROCESS_MODAL);
	return (
		<Modal
			destroyOnClose
			visible={isGradeModalVisible}
			title={isEditMode ? 'Edit grade' : 'Add a new grade'}
			width={460}
			footer={null}
			onCancel={onCancel}
		>
			<GradeLineForm onSubmit={onSubmit} onCancel={onCancel} />
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isGradeModalVisible: isModalVisible(state, GRADE_LINE_MODAL),
		isEditMode: getIsGradeLineFormInEditMode(state)
	}),
	{
		openModal
	}
)(GradeLineModal);
