import actionCreator from '../registrationActionCreator';
import {
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse
} from 'services/api/registration/registrationServiceTypes';

const ACTION_NAME = 'PRE_REGISTRATION_AGENT_COMPANY';

export const retrievePreRegistrationAgentCompanyAsync = actionCreator.async<
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse,
	Error
>(ACTION_NAME);

export const retrievePreRegistrationAgentCompany = actionCreator<
	RetrievePreRegistrationAgentCompanyRequest
>(ACTION_NAME);
