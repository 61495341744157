import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrievePermissionsRequest,
	RetrievePermissionsResponse
} from 'services/api/permissions/permissionsServiceTypes';
import { PermissionsState } from '../permissionsState';

export const onRetrievePermissionsSuccess = (
	state: PermissionsState,
	{
		params: { groupTypeCode },
		result
	}: Success<RetrievePermissionsRequest, RetrievePermissionsResponse>
): PermissionsState => ({
	...state,
	byGroupType: {
		...state.byGroupType,
		[groupTypeCode]: result.elements
	}
});

export const onRetrievePermissions = makeFetchStatusReducers<
	PermissionsState,
	'fetchStatusesByGroupType',
	RetrievePermissionsRequest,
	RetrievePermissionsResponse
>('fetchStatusesByGroupType', ({ groupTypeCode }) => groupTypeCode, {
	doneReducer: onRetrievePermissionsSuccess
});
