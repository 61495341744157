import React from 'react';
import { Flex } from 'components';

import styles from '../DocumentPageViewerV2.module.scss';
import Toolbar from './Toolbar/NewToolbar';
import { DocumentViewerPreviewNotAvailable } from 'sections/DocumentViewer';
import DocumentPage from './DocumentPage';
import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import SidePanel from './SidePanel/SidePanel';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';
import { PageType } from 'store/finance/constants';

interface DocumentContainerProps {
	pageNumber: number;
	imageUrl: string;
	document: PortCallDocument;
	isDocumentPDF: boolean;
	isAnnotatable: boolean;
	fileName: string;
	activePageType: PageType;
	activePage: PortCallDocumentPage;
	isDocumentAnnotated: boolean;
	canDeletePage: boolean;
	isSupplierInvoice?: boolean;
	onPageDelete: (
		page: PortCallDocumentPage,
		pageType: PageType,
		deleteBoth?: boolean
	) => void;
	onSaveAnnotation: (
		request: UploadCustomRequest,
		actualNumber: number
	) => void;
	onPageTypeSelect: (pageType: PageType) => void;
	onRestoreOriginal: () => void;
}

const DocumentContainer: React.FC<DocumentContainerProps> = props => {
	const onSaveAnnotation = (request: UploadCustomRequest) =>
		props.onSaveAnnotation(request, props.activePage.actualNumber);
	const onDeleteBoth = () => {
		props.onPageDelete(props.activePage, PageType.ORIGINAL, true);
	};
	return (
		<Flex className={styles.root} direction="vertical">
			<Toolbar
				isAnnotatable={props.isAnnotatable}
				hadAnnotatedPage={props.activePage.isAnnotated}
				isDocumentAnnotated={props.isDocumentAnnotated}
				onRestoreOriginal={props.onRestoreOriginal}
				isSupplierInvoice={props.isSupplierInvoice}
				onDelete={onDeleteBoth}
			/>
			<Flex direction="horizontal" className={styles.documentContainer}>
				<Flex direction="horizontal" className={styles.documentWrapper}>
					{props.isDocumentPDF ? (
						<DocumentPage
							imageUrl={props.imageUrl}
							fileName={props.fileName}
							isAnnotatable={props.isAnnotatable}
							onSaveAnnotation={onSaveAnnotation}
							activePageType={props.activePageType}
						/>
					) : (
						<DocumentViewerPreviewNotAvailable />
					)}
				</Flex>
				<Flex direction="vertical" className={styles.sideWrapper}>
					<SidePanel
						fileName={props.fileName}
						document={props.document}
						activePageDetails={props.activePage}
						activePageType={props.activePageType}
						onPageTypeSelect={props.onPageTypeSelect}
						canDeletePage={props.canDeletePage}
						onPageDelete={props.onPageDelete}
						isSupplierInvoice={props.isSupplierInvoice}
					/>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default DocumentContainer;
