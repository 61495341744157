import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { FundingRequestsState } from '../financeFundingsState';
import {
	RetrieveFundingRequestRequest,
	RetrieveFundingRequestResponse
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { uniq } from 'lodash';

const onRetrieveFundingRequestSuccess = (
	state: FundingRequestsState,
	{
		params,
		result
	}: Success<RetrieveFundingRequestRequest, RetrieveFundingRequestResponse>
): FundingRequestsState => {
	const { fundingId } = params;
	if (!fundingId) {
		return state;
	}
	return {
		...state,
		allIds: uniq([...state.allIds, result.id]),
		byId: {
			...state.byId,
			[fundingId]: result
		}
	};
};

export const retrieveFundingRequest = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	RetrieveFundingRequestRequest,
	RetrieveFundingRequestResponse
>('fetchStatuses', 'retrieveFundingRequest', {
	doneReducer: onRetrieveFundingRequestSuccess
});
