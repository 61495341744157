import actionCreator from 'store/templates/templatesActionCreator';

import {
	RetrieveTemplateRequest,
	TemplateContent,
	Template
} from 'services/api/templates/templateServiceTypes';

const TEMPLATES_RETRIEVE = 'RETRIEVE_TEMPLATE';

export const retrieveTemplateAsync = actionCreator.async<
	RetrieveTemplateRequest,
	TemplateContent,
	Error
>(TEMPLATES_RETRIEVE);

export const retrieveTemplate = actionCreator<Template['name']>(
	TEMPLATES_RETRIEVE
);
