import * as React from 'react';
import { connect } from 'react-redux';
import { downloadSupplierInvoice } from 'store/supplierInvoice/actions';
import { getIsSupplierInvoiceDownloadingById } from 'store/supplierInvoice/selectors';
import { AppState } from 'store-types';
import { FileIcon } from 'components';

interface DownloadSupplierInvoiceButtonProps {
	supplierInvoiceId: string;
	// from mapStateToProps
	isLoading: boolean;
	// from mapDispatchToProps
	downloadSupplierInvoice: typeof downloadSupplierInvoice;
}

class DownloadSupplierInvoiceButton extends React.Component<
	DownloadSupplierInvoiceButtonProps
> {
	private onDownloadSupplierInvoice = () => {
		this.props.downloadSupplierInvoice({
			supplierInvoiceId: this.props.supplierInvoiceId
		});
	};

	public render() {
		const { isLoading } = this.props;

		return (
			<FileIcon
				count={1}
				disabled={isLoading}
				loading={isLoading}
				onClick={this.onDownloadSupplierInvoice}
			/>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			supplierInvoiceId
		}: Pick<DownloadSupplierInvoiceButtonProps, 'supplierInvoiceId'>
	) => ({
		isLoading: getIsSupplierInvoiceDownloadingById(state, supplierInvoiceId)
	}),
	{
		downloadSupplierInvoice
	}
)(DownloadSupplierInvoiceButton);
