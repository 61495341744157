import actionCreator from '../bankAccountsActionCreator';
import { DownloadLOBDRequest as Request } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { RetrieveDownloadDocumentResponse as Response } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_LOBD';

export const downloadLOBD = actionCreator<Request>(ACTION_NAME);
export const downloadLOBDAsync = actionCreator.async<Request, Response, Error>(
	ACTION_NAME
);
