import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { isValid, isDirty } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import {
	ButtonRow,
	Flex,
	PageFooter,
	PageHeader,
	ScrollableLayout
} from 'components';
import { Justify } from 'components/types';
import { Modal, Row, Col, Button } from 'components/antd';
import { AppNotifications } from 'containers';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AppState } from 'store-types';
import { getValues } from 'store/form/selectors';
import {
	getUpdatePortCallFetchStatus,
	getIsPortCallUpToDate
} from 'store/portCall/portCallSelectors';
import { FetchStatus } from 'services/api/apiTypes';
import {
	EditPortCallFormData,
	EDIT_PORTCALL_FORM
} from 'store/portCall/sagas/editPortCallData/editPortCallDataTypes';
import { isModalVisible } from 'store/modals/selectors';
import { openModal, closeModal } from 'store/modals/actions';
import { navigateTo } from 'utils';
import {
	updatePortCall,
	retrieveEditPortCallData
} from 'store/portCall/actions';
import EditPortCallPermissionWrapper from './EditPortCallPermissionWrapper';
import SubHeader from '../../PortJob/SubHeader/SubHeader';

import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { resetPortCall } from 'store/portcalls/actions';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';

const CANCEL_MODAL = 'edit-portcall-cancel';
const REFRESH_MODAL = 'edit-portcall-refresh';
const PAST_ETA_MODAL = 'past-eta-modal';
interface EditPortCallRouteParams {
	portCallId: string;
	newtab?: string;
}

interface EditPortCallProps
	extends RouteComponentProps<EditPortCallRouteParams> {
	formValues: EditPortCallFormData;
	isUpdating: boolean;
	isFormValid: boolean;
	isFormDirty: boolean;
	isCancelModalVisible: boolean;
	isETAinPastModalVisible: boolean;
	isRefreshModalVisible: boolean;
	isUpToDate: boolean;
	openCancelModal: () => void;
	closeCancelModal: () => void;
	cancelPortCallEdition: () => void;
	openETAInPastModal: () => void;
	closeETAInPastModal: () => void;
	savePortCall: () => void;
	reset: () => void;
	openRefreshModal: () => void;
	refreshPortCall: () => void;
	closeRefreshModal: () => void;
}

export interface PageState {
	values: Partial<Page1FormValues>;
	showWarningModal: boolean;
}

class EditPortCallRoute extends React.Component<EditPortCallProps, PageState> {
	onSubmit = () => {
		const { openETAInPastModal, formValues, savePortCall } = this.props;
		if (moment().isAfter(formValues.etaPlt)) {
			openETAInPastModal();
		} else {
			savePortCall();
		}
	};

	componentWillUnmount() {
		this.props.reset();
		this.props.closeCancelModal();
		this.props.closeETAInPastModal();
	}

	render() {
		const {
			match: {
				params: { portCallId, newtab }
			},
			cancelPortCallEdition,
			closeCancelModal,
			openCancelModal,
			isCancelModalVisible,
			isRefreshModalVisible,
			isETAinPastModalVisible,
			isFormValid,
			isFormDirty,
			isUpdating,
			isUpToDate,
			closeETAInPastModal,
			savePortCall,
			refreshPortCall,
			closeRefreshModal,
			openRefreshModal
		} = this.props;

		const onCancelClickHandler = isFormDirty
			? openCancelModal
			: cancelPortCallEdition;
		return (
			<Flex fit direction="vertical" stretch>
				<PageHeader.NewTab onClose={openCancelModal} isNewTab={Boolean(newtab)}>
					Edit Port Call
				</PageHeader.NewTab>
				<AppNotifications.Notification />
				<ScrollableLayout>
					<SubHeader title="Port Call details" />
					<ScrollableLayout asColumn>
						<ContextBar
							title="Please provide the Port Call details"
							type={ContextBarType.ACTION}
							actor={[UserType.PRINCIPAL, UserType.HUB]}
							permissionCode={PermissionCode.MANAGE_PORTCALL}
							subtitle="Please specify a Port Call. If Optic finds an existing match, we will show you below."
							condition={true}
						/>
						<EditPortCallPermissionWrapper portCallId={portCallId} />
					</ScrollableLayout>
					<Modal
						visible={isETAinPastModalVisible}
						cancelText="Cancel"
						okText="Save"
						onOk={savePortCall}
						onCancel={closeETAInPastModal}
					>
						<p>ETA is in the past. Are you sure you want to continue?</p>
					</Modal>
					<Modal
						visible={isCancelModalVisible}
						cancelText="Cancel"
						okText="Confirm"
						onOk={cancelPortCallEdition}
						onCancel={closeCancelModal}
					>
						<p>Are you sure you want to cancel edit Port Call process?</p>
					</Modal>
					<Modal
						visible={isRefreshModalVisible}
						cancelText="Cancel"
						okText="Confirm"
						onOk={refreshPortCall}
						onCancel={closeRefreshModal}
					>
						<p>All changes will be lost. Are you sure you want to continue?</p>
					</Modal>
					<PageFooter>
						<Row>
							<Col xs={12} justify={Justify.END}>
								<ButtonRow>
									{!isUpToDate && (
										<Button
											type="primary"
											transparent
											onClick={openRefreshModal}
										>
											Refresh
										</Button>
									)}
									<Button
										type="primary"
										transparent
										onClick={onCancelClickHandler}
									>
										Cancel
									</Button>
									<MaybeTooltip
										placement="topLeft"
										show={!isUpToDate}
										title="Port Call has been updated by another user while you were editing it. You cannot save your changes due to that, please refresh the page."
									>
										<Button
											type={'primary'}
											onClick={this.onSubmit}
											disabled={!(isFormValid && isFormDirty) || !isUpToDate}
											loading={isUpdating}
										>
											Save
										</Button>
									</MaybeTooltip>
								</ButtonRow>
							</Col>
						</Row>
					</PageFooter>
				</ScrollableLayout>
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		formValues: getValues<EditPortCallFormData>(state, EDIT_PORTCALL_FORM),
		isFormValid: isValid(EDIT_PORTCALL_FORM)(state),
		isFormDirty: isDirty(EDIT_PORTCALL_FORM)(state),
		isUpdating: getUpdatePortCallFetchStatus(state) === FetchStatus.PENDING,
		isCancelModalVisible: isModalVisible(state, CANCEL_MODAL),
		isRefreshModalVisible: isModalVisible(state, REFRESH_MODAL),
		isETAinPastModalVisible: isModalVisible(state, PAST_ETA_MODAL),
		isUpToDate: getIsPortCallUpToDate(state)
	}),
	(
		dispatch,
		{
			match: {
				params: { portCallId }
			}
		}: EditPortCallProps
	) => ({
		openCancelModal: () => {
			dispatch(openModal(CANCEL_MODAL));
		},
		closeCancelModal: () => {
			dispatch(closeModal(CANCEL_MODAL));
		},
		openRefreshModal: () => {
			dispatch(openModal(REFRESH_MODAL));
		},
		refreshPortCall: () => {
			dispatch(retrieveEditPortCallData({ portCallId }));
			dispatch(closeModal(REFRESH_MODAL));
		},
		closeRefreshModal: () => {
			dispatch(closeModal(REFRESH_MODAL));
		},
		cancelPortCallEdition: () => {
			navigateTo(`/portcalls/${portCallId}/overview`);
		},
		openETAInPastModal: () => {
			dispatch(openModal({ name: PAST_ETA_MODAL }));
		},
		closeETAInPastModal: () => {
			dispatch(closeModal({ name: PAST_ETA_MODAL }));
		},
		savePortCall: () => {
			dispatch(updatePortCall);
		},
		reset: () => {
			dispatch(resetPortCall(undefined));
		}
	})
)(EditPortCallRoute);
