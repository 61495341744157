import { addPrincipalServiceAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	AddPrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
export default makeTakeLatestWatcher<
	AddPrincipalServiceRequest,
	PrincipalService,
	Error
>({
	api: Api.Services.addPrincipalService,
	async
});
