import actionCreator from '../portJobsActionCreator';
import {
	ConfigurationExtendedMessagingData,
	RetrieveExtendedMessagingRequest
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'EXTENDED_MESSAGING_CONFIGURATION';

export const retrieveExtendedMessagingConfigurationAsync = actionCreator.async<
	RetrieveExtendedMessagingRequest,
	ConfigurationExtendedMessagingData,
	Error
>(ACTION_NAME);

export const retrieveExtendedMessagingConfiguration = actionCreator<
	RetrieveExtendedMessagingRequest
>(ACTION_NAME);
