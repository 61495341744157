import {
	AddServicePayload,
	AddServiceResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ADD_SERVICE = 'ADD_SERVICE';
export const addServiceAsync = actionCreator.async<
	AddServicePayload,
	AddServiceResponse,
	Error
>(ADD_SERVICE, {
	templateParams: {
		entityName: 'Service'
	}
});

export const addService = actionCreator<AddServicePayload>(ADD_SERVICE);
