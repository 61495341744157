import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { onRetrieveFinanceReducer } from './reducers';
import { onResetContextBars } from './reducers/resetContextBars';
import { initialState } from './contextBarsState';
import { retrieveFinanceAsync } from 'store/finance/actions';
import { resetContextBars } from './actions/resetContextBars';

export default reducerWithInitialState(initialState)
	.case(resetContextBars, onResetContextBars)
	.case(retrieveFinanceAsync.done, onRetrieveFinanceReducer);
