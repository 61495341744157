import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'UPDATE_CONFIGURATION_GENERAL_DETAILS';
export const updateConfigurationGeneralDetails = actionCreator(ACTION_NAME);
export const updateConfigurationGeneralDetailsAsync = actionCreator.async<
	null,
	null,
	Error
>(ACTION_NAME, {
	failed: {
		// error is added by `updateCompanyGeneralConfigurationAsync`
		omit: true
	}
});
