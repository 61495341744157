import { PortCallState } from '../portCallState';
import { FetchStatus } from 'services/api/apiTypes';

export function onRetrieveEditPortCallDataStarted(
	state: PortCallState
): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortCallData: FetchStatus.PENDING
		}
	};
}

export function onRetrieveEditPortCallDataSuccess(
	state: PortCallState
): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortCallData: FetchStatus.SUCCESS
		}
	};
}

export function onRetrieveEditPortCallDataFailed(
	state: PortCallState
): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveEditPortCallData: FetchStatus.FAILURE
		}
	};
}
