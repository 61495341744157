import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveThreadsMainPrincipalGroupsAsync } from 'store/threads/actions';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse
} from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Threads.retrieveThreadsMainPrincipalGroups;

export const retrieveThreadsMainPrincipalGroupsWatcher = makeTakeLatestWatcher<
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse,
	Error
>({
	api: apiCall,
	async: retrieveThreadsMainPrincipalGroupsAsync
});

export default retrieveThreadsMainPrincipalGroupsWatcher;
