import React, { FC } from 'react';
import { AddSparesAndDocumentDelivery } from 'services/api/portJobs/portJobsServiceTypes';
import { Text } from 'components';
import { ShowMoreText } from 'components/ReadMore';
import { Row, Col } from 'components/antd';

interface SparesAndDocumentDeliveryOperationProps {
	operation: Pick<AddSparesAndDocumentDelivery, 'details' | 'number'>;
}

const SparesAndDocumentDeliveryOperation: FC<SparesAndDocumentDeliveryOperationProps> = ({
	operation: { number, details }
}) => (
	<Row>
		<Col xs={5}>
			<div>
				<Text>Number</Text>
			</div>
			<Text weight="bold">{number}</Text>
		</Col>
		<Col xs={7}>
			<Text upper weight="bold">
				Operation Details
			</Text>
			<ShowMoreText showLines={3}>{details}</ShowMoreText>
		</Col>
	</Row>
);

export default SparesAndDocumentDeliveryOperation;
