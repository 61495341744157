import actionCreator from '../wizardFormActionCreator';
import { WizardFormBaseAction, WizardFormModes } from '../wizardFormState';

export interface WizardFormInitialize extends WizardFormBaseAction {
	pages: number;
	mode: WizardFormModes;
	activePage?: number;
	startPage?: number;
}

const INITIALIZE = 'INITIALIZE';
/**
 * Adds a new entry on the wizardForm reducer
 * and sets the current `activeWizardFormId` as the name of the new WizardForm
 */
export const initialize = actionCreator<WizardFormInitialize>(INITIALIZE);
