import Api from 'services/api';
import { deletePortJobOperationAsync } from '../actions/deletePortJobOperation';
import { DeletePortJobOperationRequest } from 'services/api/portJobs/portJobsServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { openModal, closeModal } from 'store/modals/actions';
import { getPortJobOperationConcurrencyToken } from '../selectors';

const apiCall = Api.PortJobs.deletePortJobOperation;

export function* executor(
	actionParams: DeletePortJobOperationRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(deletePortJobOperationAsync.started(actionParams));
	const { portCallId, portJobCode, portJobOperationId } = actionParams;

	try {
		yield put(
			openModal({
				name: 'deletePortJobOperationModal',
				type: 'confirm',
				data: {
					title: '',
					description: 'Are you sure you want to delete the operation?',
					okText: 'Yes',
					cancelText: 'No'
				}
			})
		);
		const {
			payload: { isConfirmed }
		} = yield take(closeModal.type);
		if (!isConfirmed) {
			return;
		}
		const concurrencyToken = yield select(
			getPortJobOperationConcurrencyToken,
			portJobCode,
			portJobOperationId
		);
		const response = yield call(api, {
			portJobCode,
			portCallId,
			portJobOperationId,
			concurrencyToken
		});
		yield put(
			deletePortJobOperationAsync.done({
				result: undefined,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			deletePortJobOperationAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({
	payload
}: Action<DeletePortJobOperationRequest>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*(): SagaIterator {
	yield takeLatest(deletePortJobOperationAsync.type, worker);
}
