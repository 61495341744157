import React from 'react';
import { isNil } from 'lodash';
import styles from './GLMark.module.scss';

import { FormattedTime } from 'containers';
import { FinanceTransaction } from 'services/api/finance/financeServiceTypes';
import FinanceAmount from 'sections/PortCall/Finance/FinanceAmount/FinanceAmount';

const getGLInfoTable = (transactions: FinanceTransaction[]) =>
	/**
	 * @TODO
	 * We should notify BE to provide the `id` param on the response
	 */
	transactions.map((transaction, index) => (
		<table className={styles.glTable} key={`${index}-${transaction.docNumber}`}>
			<thead>
				<tr>
					<th colSpan={2}>{transaction.name}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Status:</td>
					<td>{transaction.status}</td>
				</tr>
				{!isNil(transaction.amount) && !isNil(transaction.currencyCode) && (
					<tr>
						<td>Amount:</td>
						<td>
							<FinanceAmount
								hasCurrencyLabel
								value={transaction.amount}
								currencyCode={transaction.currencyCode}
							/>
						</td>
					</tr>
				)}
				<tr>
					<td>Request:</td>
					<td>
						{transaction.requestedOn && (
							<FormattedTime convertFromUtc value={transaction.requestedOn} />
						)}
					</td>
				</tr>
				<tr>
					<td>GL document number:</td>
					<td>{transaction.docNumber}</td>
				</tr>
				<tr>
					<td>Response:</td>
					<td>
						{transaction.repliedOn && (
							<FormattedTime convertFromUtc value={transaction.repliedOn} />
						)}
					</td>
				</tr>
			</tbody>
		</table>
	));

export default getGLInfoTable;
