import {
	Template,
	TemplateContent,
	TemplateRendered
} from 'services/api/templates/templateServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface TemplatesState {
	readonly byId: { [templateName: string]: Template };
	readonly current: TemplateContent | null;
	readonly render: TemplateRendered | null;
	readonly fetchStatuses: {
		retrieveTemplates: FetchStatus;
		retrieveTemplate: FetchStatus;
		retrieveTemplateRender: FetchStatus;
		updateCompanyTemplates: FetchStatus;
	};
	readonly error: boolean;
}

export const initialState: TemplatesState = {
	byId: {},
	current: null,
	render: null,
	fetchStatuses: {
		retrieveTemplates: FetchStatus.IDLE,
		retrieveTemplate: FetchStatus.IDLE,
		retrieveTemplateRender: FetchStatus.IDLE,
		updateCompanyTemplates: FetchStatus.IDLE
	},
	error: false
};

export default initialState;
