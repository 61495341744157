import {
	ViewBenchmarkRequest as request,
	ViewBenchmarkResponse as response
} from 'services/api/finance/financeServiceTypes';
import { FinanceState } from '../financeState';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: FinanceState,
	{ result }: Success<request, response>
): FinanceState => {
	return {
		...state,
		bltRedirectionUrl: result.url
	};
};

export const onViewBenchmark = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	request,
	response
>('fetchStatuses', 'viewBenchmark', {
	doneReducer
});
