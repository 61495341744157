import actionCreator from '../operationsActionCreator';
import {
	Operation,
	RetrieveOperationsRequest
} from 'services/api/operations/operationsServiceTypes';

const RETRIEVE_OPERATIONS = 'RETRIEVE_OPERATIONS';
export const retrieveOperationsAsync = actionCreator.async<
	RetrieveOperationsRequest,
	Operation[],
	Error
>(RETRIEVE_OPERATIONS);

export const retrieveOperations = actionCreator<
	RetrieveOperationsRequest | undefined
>(RETRIEVE_OPERATIONS);
