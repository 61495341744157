import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	AddPOIRequest,
	AddPOIResponse
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import { buildVesselProgrammeAfterAddingRotationSteps } from './normalization';
import { buildPositionedRotationSteps } from '../retrieveVP/normalization';

export const onAddPOISuccess = (
	state: VesselProgrammeState,
	{ params, result }: Success<AddPOIRequest, AddPOIResponse>
) => {
	const {
		operationUnitsById,
		orderedRotationSteps,
		rotationStepsById,
		eventsById
	} = buildVesselProgrammeAfterAddingRotationSteps(
		state,
		params.next.id,
		result
	);
	return {
		...state,
		edited: true,
		operationUnitsById,
		orderedRotationSteps,
		rotationStepsById,
		eventsById,
		rotationStepsPositionedToCustodyLine: buildPositionedRotationSteps(
			orderedRotationSteps,
			state.custodyTransfer
		)
	};
};

export const onAddPOI = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	AddPOIRequest,
	AddPOIResponse
>('fetchStatuses', 'addPOI', {
	doneReducer: onAddPOISuccess
});
