import client from 'services/client';
import queryString from 'query-string';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	CreateThreadRequest,
	IThread,
	ThreadItem,
	PatchThreadPermissionsRequest,
	AssignToThreadsRequest,
	RetrieveThreadByIdParams,
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse,
	RetrieveThreadsRequest,
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse,
	ContextType,
	ThreadsSearchGroupType,
	ThreadsSearchRequest,
	ICommentServerReference,
	AssignJobToThreadParams,
	RetrieveThreadsAvailableGroupsResponse,
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse,
	RetrieveThreadsStatsResponse,
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse,
	ThreadsSearchGroupResponse,
	UpdateTagsRequest,
	RetrieveThreadTagsResponse,
	CategorizeThreadsRequest,
	RetrieveThreadsMainPrincipalTagsParam
} from 'services/api/threads/threadsServiceTypes';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { UpdateThreadStatusParams } from 'store/thread/actions';

const retrieveThreads = (
	request: RetrieveThreadsRequest
): AxiosTypedPromise<ResultCollection<IThread>> => {
	return client.get('/threads', {
		params: request,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'index' })
	});
};

const retrieveThreadById = ({
	threadId,
	concurrencyToken
}: RetrieveThreadByIdParams): AxiosTypedPromise<ThreadItem> => {
	return client.get(`/threads/${threadId}`, {
		params: {
			concurrencyToken
		}
	});
};

const retrieveThreadsAvailableGroups = () =>
	client.get<RetrieveThreadsAvailableGroupsResponse>(
		`/threads/available-groups`
	);

const retrieveAvailableGroups = ({
	threadId
}: RetrieveAvailableThreadGroupsRequest): AxiosTypedPromise<RetrieveAvailableThreadGroupsResponse> => {
	return client.get(`/threads/${threadId}/available-groups`);
};

const retrieveThreadEvents = ({
	threadId,
	...request
}: RetrieveThreadEventsRequest): AxiosTypedPromise<RetrieveThreadEventsResponse> => {
	return client.get<RetrieveThreadEventsResponse>(
		`/threads/${threadId}/events`,
		{
			params: request
		}
	);
};

const createThread = (
	request: CreateThreadRequest
): AxiosTypedPromise<{ id: string }> => {
	return client.post('/threads', request);
};

const patchThreadPermissions = ({
	threadId,
	...params
}: PatchThreadPermissionsRequest): AxiosTypedPromise<ResultCollection<
	Group
>> => {
	return client.patch(`/threads/${threadId}/permissions`, {
		assignedToGroupId: params.assignedToGroupId,
		assignedToUserId: params.assignedToUserId,
		threadPermissionGroups: params.threadPermissionGroups
	});
};

const updateAssignToThreads = ({
	threadIds,
	...params
}: AssignToThreadsRequest) => {
	return client.put(`/threads/assignedto`, {
		threadIds: threadIds,
		assignedToGroupId: params.assignedToGroupId,
		assignedToUserId: params.assignedToUserId,
		threadPermissionGroups: params.threadPermissionGroups
	});
};

const updateThreadsCategorize = ({
	threadIds,
	...params
}: CategorizeThreadsRequest) => {
	return client.put(`/threads/update-multithreads`, {
		threadIds: threadIds,
		status: params.status,
		threadContextId: params.threadContextId,
		codes: params.codes,
		jobcodes: params.jobcodes
	});
};

const updateThreadStatus = ({ threadId, status }: UpdateThreadStatusParams) =>
	client.patch(`/threads/${threadId}/status`, {
		status
	});

const retrieveInternalComments = (threadId: string) => {
	return client.get(`/threads/${threadId}/comments`, {
		params: {
			sortBy: 'createdOn:asc',
			limit: 43210
		}
	}); // let's get a large amount of internal comments just in case
};

const postInternalComment = (
	threadId: string,
	text: string
): AxiosTypedPromise<ICommentServerReference> => {
	return client.post(`/threads/${threadId}/comments`, { text });
};

const searchThreads = (
	searchTerm: string,
	showMoreInGroup?: ThreadsSearchGroupType
): AxiosTypedPromise<ThreadsSearchGroupResponse> => {
	const params: ThreadsSearchRequest = {
		searchTerm,
		showMore: false
	};
	if (showMoreInGroup) {
		params.groupType = showMoreInGroup;
		params.showMore = true;
	}
	return client.get(`/threads/search`, {
		params
	});
};

const searchPortJobThreads = (
	searchTerm: string,
	showMoreInGroup?: ThreadsSearchGroupType,
	portJobCode?: string,
	portCallId?: string
): AxiosTypedPromise<ThreadsSearchGroupResponse> => {
	const params: ThreadsSearchRequest = {
		searchTerm,
		showMore: false,
		portJobCode,
		portCallId
	};
	if (showMoreInGroup) {
		params.groupType = showMoreInGroup;
		params.showMore = true;
	}
	return client.get(`/threads/search`, {
		params
	});
};

const retrieveContexts = (
	request: RetrieveThreadContextsRequest
): AxiosTypedPromise<RetrieveThreadContextsResponse> => {
	return client.get<RetrieveThreadContextsResponse>('/thread-contexts', {
		params: request
	});
};

const retrieveThreadsStats = () => {
	return client.get<RetrieveThreadsStatsResponse>('/threads/stats');
};

const updateContext = (
	threadId: string,
	threadContextId: string | null
): AxiosTypedPromise<ContextType> =>
	client.patch(`/threads/${threadId}/context`, {
		threadId,
		threadContextId
	});

const assignJobToThread = ({ jobCode, threadId }: AssignJobToThreadParams) =>
	client.patch(`threads/${threadId}/jobs`, { jobCode });

const unassignJobFromThread = ({
	threadId,
	jobCode
}: AssignJobToThreadParams) =>
	client.delete(`/threads/${threadId}/jobs/${jobCode}`);

const retrieveThreadsPendingAlerts = ({
	portCallId
}: RetrieveThreadsPendingAlertsRequest) =>
	client.get<RetrieveThreadsPendingAlertsResponse>(
		`/threads/${portCallId}/pending-alerts`
	);

const updateThreadTags = ({ threadId, codes }: UpdateTagsRequest) =>
	client.patch(`/threads/${threadId}/tags`, {
		codes
	});

const retrieveThreadTags = () => {
	return client.get<RetrieveThreadTagsResponse>('/threads/tags');
};

const retrieveThreadsMainPrincipalTags = ({
	companyId
}: RetrieveThreadsMainPrincipalTagsParam) => {
	return client.get<RetrieveThreadTagsResponse>(
		`/threads/${companyId}/tag-list`
	);
};

const retrieveThreadsMainPrincipalGroups = ({
	companyId
}: RetrieveThreadsMainPrincipalTagsParam) => {
	return client.get<RetrieveThreadsAvailableGroupsResponse>(
		`/threads/${companyId}/groups-list`
	);
};

export default {
	retrieveThreads,
	retrieveThreadsAvailableGroups,
	retrieveThreadById,
	retrieveThreadEvents,
	retrieveThreadsStats,
	updateThreadStatus,
	createThread,
	searchThreads,
	retrieveContexts,
	updateContext,
	retrieveAvailableGroups,
	retrieveThreadsPendingAlerts,
	updateThreadTags,
	retrieveThreadTags,
	retrieveThreadsMainPrincipalTags,
	retrieveThreadsMainPrincipalGroups,

	// Permissions
	patchThreadPermissions,

	updateAssignToThreads,
	updateThreadsCategorize,

	// Comments
	retrieveInternalComments,
	postInternalComment,
	// PortJobs related
	assignJobToThread,
	unassignJobFromThread,
	searchPortJobThreads
};
