import Api from 'services/api';

import {
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse
} from 'services/api/groups/groupsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveThirdPartyLpaGroupsAsync } from '../actions';

export default makeTakeLatestWatcher<
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse,
	Error
>({
	api: Api.Groups.searchThirdPartyLPAGroups,
	async: retrieveThirdPartyLpaGroupsAsync
});
