import { DeveloperPortalState } from '../developerPortalState';

export const onSetEditingWebhookReducer = (
	state: DeveloperPortalState,
	id: string
): DeveloperPortalState => ({
	...state,
	context: {
		...state.context,
		editingWebHookId: id
	}
});
