import { DraftsState } from '../draftsState';
import { SetActiveDraftFormAndFieldActionPayload } from '../actions';
export const onSetActiveDraftFormAndField = (
	state: DraftsState,
	{ formId, fieldName }: SetActiveDraftFormAndFieldActionPayload
): DraftsState => ({
	...state,
	context: {
		...state.context,
		activeFormId: formId,
		activeFieldName: fieldName
	}
});
