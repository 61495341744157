import { retrieveThreadById } from 'store/thread/actions/retrieveThreadById';
import Api from 'services/api';
import {
	assignJobToThread as action,
	assignJobToThreadAsync as asyncAction
} from 'store/thread/actions/assignJobToThread';
import {
	AssignJobToThreadParams as requestParams,
	AssignJobToThreadResponse as responseParams
} from 'services/api/threads/threadsServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, fork, put, select } from 'redux-saga/effects';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import { getActiveThread } from 'store/thread/selectors';
import { updateThreads } from 'store/threads/actions';

const apiCall = Api.Threads.assignJobToThread;

export function* executor(
	actionParams: requestParams,
	api: typeof apiCall = apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const response: AxiosTypedResponse<responseParams> = yield call(
			api,
			actionParams
		);
		yield put(
			asyncAction.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield call(successExecutor, actionParams);
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
	}
}

function* worker({ payload }: Action<requestParams>): SagaIterator {
	yield call(executor, payload);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

/**
 * Success Worker
 */
export function* successExecutor(actionParams: requestParams): SagaIterator {
	const { threadId } = actionParams;
	const activeThread = yield select(getActiveThread);
	yield put(updateThreads(activeThread));
	yield put(retrieveThreadById({ threadId }));
}

export default function*() {
	yield fork(watcher);
}
