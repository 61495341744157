import React from 'react';
import AgentField from '../AgentField';
import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { AgentProps } from '../types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
	getPortJobFormPerformingAgentCompositeKey,
	getPortJobFormControllingAgent
} from '../../../../page2Selectors';
import classNames from 'classnames';
import styles from './ControllingAgentField.module.scss';
import { clearControllingAgent } from 'store/portJobs/actions/clearPortJobControllingAgent';
import { AppState } from 'store-types';

const DisabledWrapper: React.SFC<{
	onClear: () => void;
}> = ({ children, onClear }) => {
	return (
		<div className={styles.disabledWrapper}>
			<span
				onClick={onClear}
				unselectable="on"
				role="button"
				className={classNames([
					'ant-select-selection__clear',
					styles.clearAgent
				])}
			/>
			{children}
		</div>
	);
};

type ControllingAgentFieldProps = AgentProps & {
	perfomanceAgentKeys: string[];
	isControllingAgentValueSet: boolean;
	clearField: typeof clearControllingAgent;
};
const ControllingAgentField = ({
	disabled,
	search,
	withParentId,
	onAddDraftClick,
	perfomanceAgentKeys,
	isControllingAgentValueSet,
	clearField
}: ControllingAgentFieldProps) => {
	const Agent = (
		<AgentField
			name={FormFieldName.CONTROLLING_AGENT}
			label="Controlling Agent:"
			disabled={disabled}
			disabledKeys={perfomanceAgentKeys} // block choosing the item chosen in perfomance agent
			search={search}
			withParentId={withParentId}
			onAddDraftClick={onAddDraftClick}
			allowClear
			className={styles.field}
		/>
	);
	return disabled && isControllingAgentValueSet ? (
		<DisabledWrapper onClear={clearField}>{Agent}</DisabledWrapper>
	) : (
		Agent
	);
};

export default connect(
	(state: AppState) => ({
		perfomanceAgentKeys: getPortJobFormPerformingAgentCompositeKey(state),
		isControllingAgentValueSet: !isEmpty(getPortJobFormControllingAgent(state))
	}),
	{
		clearField: clearControllingAgent
	}
)(ControllingAgentField);
