import { keyBy } from 'lodash';
import { Success } from 'typescript-fsa';
import {
	ConfigurationFilesState,
	initialState
} from '../configurationFilesState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import {
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse
} from 'services/api/templates/templatesServiceTypes';

const doneReducer = (
	state: ConfigurationFilesState,
	{
		result
	}: Success<
		RetrieveCompanyTemplateFilesRequest,
		RetrieveCompanyTemplateFilesResponse
	>
): ConfigurationFilesState => {
	const byId = keyBy(result.elements, element => element.id);
	return {
		...state,
		allIds: Object.keys(byId),
		byId
	};
};

export const onResetConfigurationFiles = (): ConfigurationFilesState =>
	initialState;

export const onRetrieveConfigurationFiles = makeFetchStatusReducers<
	ConfigurationFilesState,
	'fetchStatuses',
	RetrieveCompanyTemplateFilesRequest,
	RetrieveCompanyTemplateFilesResponse
>('fetchStatuses', 'retrieveCompanyFiles', {
	doneReducer
});
