import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { GroupsState } from '../groupsState';
import {
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse
} from 'services/api/groups/groupsServiceTypes';

function doneReducer(
	state: GroupsState,
	{
		result
	}: Success<
		RetrieveThirdPartyLpaGroupsRequest,
		RetrieveThirdPartyLpaGroupsResponse
	>
): GroupsState {
	return {
		...state,
		thirdPartyLPAGroups: [...result.elements]
	};
}

export const onRetrieveThirdPartyLPAGroups = makeFetchStatusReducers<
	GroupsState,
	'fetchStatuses',
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse
>('fetchStatuses', 'retrieveThirdPartyLPAGroups', {
	doneReducer
});

export const onResetThirdPartyLPAGroups = (state: GroupsState): GroupsState => {
	return {
		...state,
		thirdPartyLPAGroups: []
	};
};
