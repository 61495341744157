import React, { useRef, useEffect, ReactNode } from 'react';
import { isEmpty } from 'lodash';
import { ListProps } from 'antd/lib/list';
import { Loading, Flex } from 'components';
import { Input, Icon } from 'components/antd';
import SearchablePopoverList from './components/SearchablePopoverList';
import styles from './SearchablePopover.module.scss';
import { LoadingType } from './SearchablePopover';
import { SearchablePopoverDefaultItem } from './searchablePopoverTypes';

interface SearchablePopoverContentProps<
	Item extends SearchablePopoverDefaultItem = SearchablePopoverDefaultItem
> {
	placeholder?: string;
	loading: boolean;
	visible: boolean;
	searchTerm: string;
	showResults: boolean;
	hasSearchField: boolean;
	loadingType?: LoadingType;
	data: Item[];
	listLocaleProps?: ListProps<Item>['locale'];
	onChange: (el: React.ChangeEvent<HTMLInputElement>) => void;
	renderItem: (item: Item) => ReactNode;
	onSelect: (item: Item) => void;
}

export default function SearchablePopoverContent(
	props: SearchablePopoverContentProps
) {
	const {
		data,
		searchTerm,
		showResults,
		hasSearchField,
		visible,
		loadingType = LoadingType.Full
	} = props;

	const isFullLoading = props.loading && loadingType === LoadingType.Full;
	const isInputLoading = props.loading && loadingType === LoadingType.Input;
	const input = useRef<Input | null>(null);
	const listProps = {
		data: props.data,
		bordered: false,
		renderItem: props.renderItem,
		onSelect: props.onSelect,
		listLocaleProps: props.listLocaleProps
	};

	useEffect(() => {
		if (visible && input?.current) {
			input.current.focus();
		}
	}, [data, visible, input]);

	return (
		<Flex direction="vertical">
			{hasSearchField && (
				<div className={styles.filterField}>
					<Input
						ref={input}
						placeholder={props.placeholder}
						value={searchTerm}
						onChange={props.onChange}
						disabled={isFullLoading}
						suffix={
							isInputLoading ? <Icon type="loading" /> : <Icon type="search" />
						}
					/>
				</div>
			)}
			{isFullLoading || (isInputLoading && isEmpty(data)) ? (
				/* full loader is also shown where we have loading and no results to show for input loading*/
				<div>
					<Loading size="sm" />
				</div>
			) : (
				showResults && <SearchablePopoverList {...listProps} />
			)}
		</Flex>
	);
}
