import React from 'react';
import { connect } from 'react-redux';
import * as H from 'history';
import history from 'services/history';
import classNames from 'classnames';
import { LoadingPlaceholder } from 'components';
import { Modal } from 'components/antd';
import { AuthActAsConfig } from 'store/auth/authState';
import { isLeavingAuthActAsRoute } from 'store/auth/authUtils';
import { resetAuthActAs } from 'store/auth/actions';
import {
	getAuthActAs,
	getAuthActAsConfig,
	getRetrieveAuthUserFetchStatus,
	getActAsHistoryBlockTriggerCounter
} from 'store/auth/selectors';
import { LabeledValueItem } from 'app-types';
import { navigateTo } from 'utils';
import { FetchStatus } from 'services/api/apiTypes';
import styles from './ActAs.module.scss';
import { AppState } from 'store-types';

const Viewer = ({ isActive }: { isActive: boolean }) => {
	if (!isActive) {
		return null;
	}
	return (
		<>
			<span className={classNames(styles.viewer, styles.vertical)} />
			<span className={classNames(styles.viewer, styles.horizontal)} />
		</>
	);
};

interface ActAsContainerProps {
	key: string | undefined;
	value: LabeledValueItem | null;
	config: AuthActAsConfig;
	fetchStatus: FetchStatus;
	resetAuthActAs: typeof resetAuthActAs;
	historyBlockTriggerCounter: number;
}

interface ActAsContainerState {
	isModalVisible: boolean;
}

class ActAsContainer extends React.Component<
	ActAsContainerProps,
	ActAsContainerState
> {
	pathname: string | undefined;

	state: ActAsContainerState = {
		isModalVisible: false
	};
	componentDidUpdate(prevProps: ActAsContainerProps) {
		if (
			prevProps.historyBlockTriggerCounter !==
			this.props.historyBlockTriggerCounter
		) {
			window.unblock = history.block(this.blockTransitionPrompt);
		}
	}
	componentDidMount() {
		// Attaching listener GLOBALLY to be able reset it,
		// thus not preventing transition due to idle timeout (it is reset in <IdleTimer /> before redirection)
		window.unblock = history.block(this.blockTransitionPrompt);
	}

	componentWillUnmount() {
		if (window.unblock) {
			window.unblock();
		}
	}

	blockTransitionPrompt = (location: H.Location): false | void => {
		if (isLeavingAuthActAsRoute(this.props.config, location.pathname)) {
			if (this.props.value) {
				this.pathname = location.pathname;
				this.setState({
					isModalVisible: true
				});
				return false;
			}
			// Finally reset all related data
			window.unblock();
			this.props.resetAuthActAs();
		}
	};

	onConfirm = () => {
		/**
		 * Reset state if user confirms leaving page
		 */
		if (this.pathname) {
			window.unblock();
			this.props.resetAuthActAs();
			navigateTo(this.pathname);
		}
	};

	onCancel = () => {
		this.setState({
			isModalVisible: false
		});
	};

	render() {
		const { value, config, fetchStatus } = this.props;
		return (
			<LoadingPlaceholder fetchStatus={fetchStatus}>
				{() => (
					<div className={styles.wrapper}>
						{this.props.children}
						<Viewer isActive={Boolean(value)} />
						<Modal
							visible={this.state.isModalVisible}
							cancelText="No"
							okText="Yes"
							onOk={this.onConfirm}
							onCancel={this.onCancel}
						>
							{config.leaveModeText}
						</Modal>
					</div>
				)}
			</LoadingPlaceholder>
		);
	}
}
export default connect(
	(state: AppState) => {
		const value = getAuthActAs(state);
		const historyBlockTriggerCounter = getActAsHistoryBlockTriggerCounter(
			state
		);
		return {
			value,
			config: getAuthActAsConfig(state),
			fetchStatus: getRetrieveAuthUserFetchStatus(state),
			// re-mount current and its children
			key: value ? value.key : undefined,
			historyBlockTriggerCounter
		};
	},
	{
		resetAuthActAs
	}
)(ActAsContainer);
