import { SupplierInvoiceActionCode } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

export const menuItemMap = {
	[SupplierInvoiceActionCode.EDIT]: {
		text: 'Update',
		action: SupplierInvoiceActionCode.EDIT
	},
	[SupplierInvoiceActionCode.CANCEL_CREDIT_SUPPLIER_INVOICE]: {
		text: 'Cancel transaction',
		action: SupplierInvoiceActionCode.CANCEL_CREDIT_SUPPLIER_INVOICE
	},
	[SupplierInvoiceActionCode.CREATE_CREDIT_INVOICE_BASED_ON_REGULAR]: {
		text: 'Post credit',
		action: SupplierInvoiceActionCode.CREATE_CREDIT_INVOICE_BASED_ON_REGULAR
	}
};
