import { take, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	removeServiceMapping,
	updatePrincipalService,
	updatePrincipalServiceAsync
} from '../actions';
import {
	RemoveServiceMappingParams,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import { getPrincipalServiceById } from '../selectors';
import { openModal, closeModal } from 'store/modals/actions';
import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';

function* worker({ payload }: Action<RemoveServiceMappingParams>) {
	const modalName = 'CONFIRM_CLOSE_MODAL_NAME';
	const {
		name,
		principalServiceCode,
		principalServiceCategory,
		services
	}: PrincipalService = yield select(
		getPrincipalServiceById,
		payload.principalServiceId
	);

	yield put(
		openModal({
			type: 'confirm',
			name: modalName,
			data: {
				description:
					'If you delete the mapping, Principals will see ISS service name/code instead of their preferred one. Continue?'
			}
		})
	);

	const modal = yield take(closeModal.type);

	if (modal.payload.isConfirmed) {
		yield put(
			updatePrincipalService(
				{
					id: payload.principalServiceId,
					data: {
						name,
						principalServiceCode,
						principalServiceCategoryId: principalServiceCategory.id,
						serviceCodes: services
							.filter(s => s.code !== payload.issServiceCode)
							.map(s => s.code)
					}
				},
				{
					done: {
						description:
							'Principal Specific Service Mapping has been successfully deleted.'
					}
				}
			)
		);

		yield waitAsyncResolve(updatePrincipalServiceAsync);

		yield put(closeModal({ name: modalName }));
	}
}

export default function*() {
	yield takeLatest(removeServiceMapping, worker);
}
