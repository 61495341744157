import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import {
	TIME_SAVINGS_MODAL,
	TimeSavingsFormData
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import TimeSavingsForm from './TimeSavingsForm';
import { getUpdateTimeSavings } from 'store/savings/savingsSelectors';
import { resetTimeSavingsContext } from 'store/savings/actions';

interface TimeSavingsModalProps {
	// frtom mapStateToProps
	isModalVisible: boolean;
	initialValues: Partial<TimeSavingsFormData>;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	resetTimeSavingsContext: typeof resetTimeSavingsContext;
}

const TimeSavingsModal = ({
	isModalVisible,
	initialValues,
	...props
}: TimeSavingsModalProps) => {
	const onCancel = () => {
		props.closeModal(TIME_SAVINGS_MODAL);
		props.resetTimeSavingsContext();
	};

	return (
		<Modal
			destroyOnClose
			visible={isModalVisible}
			title={initialValues.hours ? 'Edit Time Savings' : 'Add New Time Savings'}
			size="md"
			footer={null}
			onCancel={onCancel}
		>
			<TimeSavingsForm />
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isModalVisible: isModalVisible(state, TIME_SAVINGS_MODAL),
		initialValues: getUpdateTimeSavings(state)
	}),
	{
		resetTimeSavingsContext,
		closeModal
	}
)(TimeSavingsModal);
