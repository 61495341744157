import { isNil } from 'lodash';
import { PortJobState } from '../portJobsState';
export const onSetActivePortJobCode = (
	state: PortJobState,
	activePortJobCode: string
): PortJobState => {
	if (!isNil(activePortJobCode)) {
		return {
			...state,
			context: {
				...state.context,
				activePortJobCode
			}
		};
	}
	return state;
};

export const onResetActivePortJobCode = (
	state: PortJobState
): PortJobState => ({
	...state,
	context: {
		...state.context,
		activePortJobCode: ''
	}
});
