import actionCreator from '../supplierInvoiceActionCreator';
import {
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

const ACTION_NAME = 'RETRIEVE_SUPPLIER_INVOICE_DOCUMENT';

export const retrieveSupplierInvoiceDocumentAsync = actionCreator.async<
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse,
	Error
>(ACTION_NAME);

export const retrieveSupplierInvoiceDocument = actionCreator<
	RetrieveSupplierInvoiceServiceDocumentRequest
>(ACTION_NAME);
