/* eslint-disable */
import * as React from 'react';
import { connect } from 'react-redux';

import WizardActions from 'store/wizardForm';

import {
	getActiveWizardTotal,
	getWizardActivePage,
	getWizardActivePageState,
	isWizardActivePageFirst,
	isWizardActivePageLast
} from 'store/wizardForm/wizardFormSelectors';

import { WizardConfigBase, InjectedWizardFormBaseProps } from './index';
import { injectAdditionalPropsForPages } from './utils';
import {
	WizardFormModes,
	WizardFormAction,
	WizardFormPageState
} from 'store/wizardForm/wizardFormState';
import { AppState } from 'store-types';

export interface Page {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.ComponentType<any>;
}

export interface Config extends WizardConfigBase {
	pages?: Page[];
	total?: number;
}

export interface InjectedWizardFormProps extends InjectedWizardFormBaseProps {}

function createWizardForm<P>(initialConfig: Config) {
	const config: Config = {
		...initialConfig,
		pages: injectAdditionalPropsForPages(initialConfig)
	};

	return (WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
		class WizardFormStep extends React.Component<P & InjectedWizardFormProps> {
			componentWillUnmount() {
				this.props.destroy();
			}

			getCurrentPage = () => {
				const { activePage } = this.props;
				const { pages } = config;
				if (!pages) {
					return null;
				}
				return pages[activePage - 1];
			};

			getComponent = () => {
				const page = this.getCurrentPage();
				return page ? page.component : null;
			};

			render() {
				const propsToPass = {
					wizard: config.name,
					component: this.getComponent()
				};
				return <WrappedComponent {...this.props} {...propsToPass} />;
			}
		}

		// TODO
		// @see https://github.com/piotrwitek/react-redux-typescript-guide/issues/100
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const HOC = WizardFormStep as React.ComponentType<any>;

		return connect(
			(state: AppState) => ({
				total: getActiveWizardTotal(state),
				activePage: getWizardActivePage(state),
				activePageState: getWizardActivePageState(state, config.name),
				activePageFirst: isWizardActivePageFirst(state, config.name),
				activePageLast: isWizardActivePageLast(state, config.name)
			}),
			dispatch => {
				dispatch(
					WizardActions.initialize({
						name: config.name,
						pages: config.pages ? config.pages.length : config.total || 0,
						mode: config.mode
					})
				);

				const computedActions = {
					setActivePage: (activePage: number) => {
						dispatch(
							WizardActions.setActivePage({
								name: initialConfig.name,
								activePage
							})
						);
					},
					setActivePageState: (state: WizardFormPageState) => {
						dispatch(
							WizardActions.setActivePageState({
								name: initialConfig.name,
								...state
							})
						);
					},
					setStartPage: (startPage: number) => {
						dispatch(
							WizardActions.setStartPage({
								name: initialConfig.name,
								startPage
							})
						);
					},
					setMode: (mode: WizardFormModes) => {
						dispatch(WizardActions.setMode({ name: initialConfig.name, mode }));
					},
					nextPage: () => {
						dispatch(
							WizardActions.nextPage({
								name: config.name
							})
						);
					},
					prevPage: () => {
						dispatch(
							WizardActions.prevPage({
								name: config.name
							})
						);
					},
					updateState: (newState: WizardFormAction) => {
						dispatch(
							WizardActions.updateState({
								name: config.name,
								...newState
							})
						);
					},
					destroy: () => {
						dispatch(
							WizardActions.destroy({
								name: config.name
							})
						);
					}
				};

				return () => computedActions;
			}
		)(HOC as any) as any;
	};
}

export default createWizardForm;
