import { FinanceState } from '../financeState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';
import {
	AddServiceResponse,
	PortCallFinance,
	ServiceSection,
	ServiceSectionCode,
	SubSection,
	AddServicePayload
} from 'services/api/finance/financeServiceTypes';
import { getUpdatedDa } from './updateDA.func';

/**
 * Get new array of the service subsection with keeping positions of the subsection in array
 */
function getPositionedSubSections(
	subsections: SubSection[],
	entityId: string,
	serviceId: string
) {
	const updatedSubsections = subsections.map(subsection => {
		if (subsection.entityId !== entityId) {
			return subsection;
		}
		return {
			...subsection,
			services: [...subsection.services, serviceId]
		};
	});
	return updatedSubsections;
}

/**
 * Get new array of the service section with keeping positions of the sections in array
 */
function getPositionedSection(
	serviceSections: ServiceSection[],
	serviceSectionCode: ServiceSectionCode,
	serviceId: string,
	entityId?: string
) {
	const updatedServiceSection = serviceSections.map(section => {
		if (section.code !== serviceSectionCode) {
			return section;
		}

		/**
		 * if we have entityId, that means that we changed subsection.
		 * In case with chaging sction we don't have entityId as a parameter
		 */
		if (entityId) {
			return {
				...section,
				sections: getPositionedSubSections(
					section.sections,
					entityId,
					serviceId
				)
			};
		}

		return {
			...section,
			services: [...section.services, serviceId]
		};
	});
	return updatedServiceSection;
}

export function onAddServiceStarted(
	state: FinanceState,
	payload: AddServicePayload
): FinanceState {
	const { serviceSectionCode, entityId = '' } = payload;
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses
		},
		addServiceStatuses: {
			[`${serviceSectionCode}-${entityId}`]: FetchStatus.PENDING
		}
	};
}

export function onAddServiceSuccess(
	state: FinanceState,
	action: Success<AddServicePayload, AddServiceResponse>
): FinanceState {
	const { entityId = '' } = action.params;
	const service = action.result;
	const finance = state.finance || ({} as PortCallFinance);
	const serviceSectionCode = action.result.serviceSection.code;

	return {
		...state,
		finance: {
			...finance,
			services: {
				...finance.services,
				elements: [...finance.services.elements, action.result]
			},
			serviceSections: getPositionedSection(
				finance.serviceSections,
				serviceSectionCode,
				service.id,
				action.params.entityId
			),
			...getUpdatedDa(finance, service.daInId, service.id)
		},
		addServiceStatuses: {
			[`${serviceSectionCode}-${entityId}`]: FetchStatus.SUCCESS
		}
	};
}

export function onAddServiceFailure(
	state: FinanceState,
	action: Failure<AddServicePayload, Error>
): FinanceState {
	const { serviceSectionCode, entityId = '' } = action.params;
	return {
		...state,
		addServiceStatuses: {
			[`${serviceSectionCode}-${entityId}`]: FetchStatus.FAILURE
		}
	};
}
