import { useCallback, useContext } from 'react';

import { UseModalContext } from 'context';
import { GroupsModalsName, DeleteGroupModalValue } from './GroupsModalsTypes';

export function useGroupsModals() {
	const { showModal, name } = useContext(UseModalContext);

	const showCreateGroupModal = useCallback(() => {
		showModal(GroupsModalsName.ADD_GROUP);
	}, [showModal]);

	const showDeleteGroupModal = useCallback(
		(modalValue: DeleteGroupModalValue) => {
			showModal(GroupsModalsName.DELETE_GROUP, modalValue);
		},
		[showModal]
	);

	return {
		name,
		showCreateGroupModal,
		showDeleteGroupModal
	};
}
