import { DocumentsState } from '../documentsState';

export const onUploadDocumentProgress = (
	state: DocumentsState,
	percent: number
) => ({
	...state,
	upload: {
		...state.upload,
		info: {
			...state.upload.info,
			percent
		}
	}
});
