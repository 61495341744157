import * as React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import Table, {
	TableProps as AntTableProps
} from 'components/antd/Table/Table';
import styles from './InfiniteTable.module.scss';

import { Fit } from 'components/Styles';
import Portal from 'components/Portal/Portal';
import LazyLoad, { LazyLoadBaseProps } from 'components/LazyLoad/LazyLoad';
import withEmptyMessage from '../withEmptyMessage';

export interface InfiniteTableProps extends AntTableProps {
	lazyLoad: LazyLoadBaseProps;
}

interface TableState {
	scroll: {
		y: number;
	};

	/**
	 * Table Header is set to overflow: scroll resulting in scroll always present.
	 * Padding is added to comply with table cells widths.
	 */
	overflows: boolean;
}

const TABLE_SCROLLABLE_CLS = '.ant-table-body';

class InfiniteTable extends React.Component<InfiniteTableProps, TableState> {
	static defaultProps: Partial<InfiniteTableProps> = {
		pagination: false
	};
	container: Table;

	constructor(props: InfiniteTableProps) {
		super(props);

		this.state = {
			// set table to scroll vertically by default
			scroll: {
				y: 1
			},
			overflows: false
		};
	}

	componentDidMount() {
		this.setOverflow();
	}

	componentDidUpdate(prevProps: InfiniteTableProps) {
		const nextLazyLoad = prevProps.lazyLoad;
		const currentLazyLoad = this.props.lazyLoad;
		if (
			currentLazyLoad &&
			nextLazyLoad &&
			nextLazyLoad.loading !== currentLazyLoad.loading
		) {
			this.setOverflow();
		}
	}

	setOverflow = () => {
		const overflows = this.isContentOverflowing();

		if (this.state.overflows !== overflows) {
			this.setState({
				overflows
			});
		}
	};

	setContainerRef = (e: Table) => {
		if (e) {
			this.container = e;
		}
	};

	isContentOverflowing = (): boolean => {
		if (!this.container) {
			return this.state.overflows;
		}

		const container = ReactDOM.findDOMNode(this.container) as Element;
		const scrollable = container.querySelector<HTMLElement>(
			TABLE_SCROLLABLE_CLS
		);
		if (!scrollable) {
			return false;
		}
		return scrollable.offsetHeight < scrollable.scrollHeight;
	};

	/**
	 * Omit waypoint args
	 */
	onEnter = () => {
		if (this.props.lazyLoad) {
			this.props.lazyLoad.onEnter();
		}
	};

	render() {
		const { lazyLoad, className, locale, ...tableProps } = this.props;

		return (
			<Fit>
				<Table
					{...tableProps}
					className={classNames(className, {
						[styles.tableContentOverflows]: this.state.overflows
					})}
					scroll={this.state.scroll}
					ref={this.setContainerRef}
				/>
				<Portal containerSelector={TABLE_SCROLLABLE_CLS} parentComponent={this}>
					<LazyLoad
						{...lazyLoad}
						loading={lazyLoad.loading}
						hasMore={lazyLoad.hasMore}
						doneLoading={lazyLoad.doneLoading}
						onEnter={this.onEnter}
					/>
				</Portal>
			</Fit>
		);
	}
}

const InfiniteTableWithEmptyMessage = withEmptyMessage<InfiniteTableProps>(
	InfiniteTable
);

const Component: React.FC<InfiniteTableProps> = ({ locale, ...props }) => {
	return (
		<InfiniteTableWithEmptyMessage
			{...props}
			showEmptyText={
				!!props.dataSource &&
				!props.dataSource.length &&
				!props.lazyLoad.hasMore &&
				!props.lazyLoad.failedLoading
			}
			locale={{
				emptyText: locale?.emptyText ? locale.emptyText : ''
			}}
		/>
	);
};

export default Component;
