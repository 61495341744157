import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../supplierInvoiceActionCreator';

const ACTION_NAME = 'RETRIEVE_SUPPLIER_INVOICE_JOB';

export const retrieveSupplierInvoiceJobAsync = actionCreator.async<
	RetrievePortCallsRequest,
	RetrievePortCallsResponse,
	Error
>(ACTION_NAME);

export const retrieveSupplierInvoiceJob = actionCreator<
	RetrievePortCallsRequest
>(ACTION_NAME);
