import Api from 'services/api';
import { retrieveBenchmarkStatusAsync } from '../actions/retrieveBenchmarkStatus';
import {
	FinanceRequest,
	RetrieveBenchmarkStatus
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	FinanceRequest,
	RetrieveBenchmarkStatus,
	Error
>({
	api: Api.Finance.retrieveBenchmarkStatus,
	async: retrieveBenchmarkStatusAsync
});
