import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import { getThreadsPermissionGroupsOptionsSelector } from 'store/thread/selectors';
import {
	THREADS_ASSIGNTO_FORM,
	ThreadsAssignToFormData,
	ThreadsAssignToFormFields
} from './AssignToModalFormTypes';

export const useAssignGroupForm = () => {
	const dispatch = useDispatch();
	const formValues = useSelector(
		getFormValues(THREADS_ASSIGNTO_FORM),
		shallowEqual
	) as ThreadsAssignToFormData;
	const changeField = useCallback(
		(
			field: ThreadsAssignToFormFields,
			value?: string | PermissionGroupWithUsers[] | null
		) => {
			dispatch(change(THREADS_ASSIGNTO_FORM, field, value));
		},
		[dispatch]
	);
	const threadPermissionGroupsWithUsersInForm = useSelector(
		getThreadsPermissionGroupsOptionsSelector,
		shallowEqual
	) as PermissionGroupWithUsers[];

	return {
		formValues,
		changeField,
		threadPermissionGroupsWithUsersInForm
	};
};
