import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { SelectProps } from 'components/antd/Select/Select';
import { retrieveCurrencies } from 'store/currencies';
import {
	getCurrencies,
	getIsRetrieveCurrencies
} from 'store/currencies/currenciesSelectors';
import { ReduxFieldProps } from 'components/antd/Form/ReduxField/createReduxField';
import LoadableSelect from 'components/LoadableSelect/LoadableSelect';

const AllCurrencySelect = function(props: ReduxFieldProps<SelectProps>) {
	const dispatch = useDispatch();
	const options = useSelector(getCurrencies, shallowEqual);
	const retrieve = useCallback(() => {
		dispatch(retrieveCurrencies({}));
	}, [dispatch]);
	const fetchStatus = useSelector(getIsRetrieveCurrencies, shallowEqual);

	return (
		<LoadableSelect
			{...props}
			fetchStatus={fetchStatus}
			retrieve={retrieve}
			options={options}
			labelName="code"
		/>
	);
};

export default AllCurrencySelect;
