import actionCreator from '../portJobsActionCreator';
import {
	UpdateSavingsParams,
	UpdateSavingsRequest
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'UPDATE_SAVINGS';

export const updateSavingsAsync = actionCreator.async<
	UpdateSavingsRequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	templateParams: {
		entityName: 'Savings'
	}
});

export const updateSavings = actionCreator<UpdateSavingsParams>(ACTION_NAME);
