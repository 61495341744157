import * as React from 'react';
import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';
import { customMap } from 'components/antd/Form/ReduxField/mapError';
import { isNumber } from 'lodash';
import flags from './images/flags.png';
import styles from './PhoneInput.module.scss';

const ReactTelephoneInput = React.lazy(() =>
	import('react-telephone-input/lib/withStyles')
);

interface PhoneInputProps {
	value?: string;
	defaultCountry?: string;
	onChange?: (value: string) => void;
	disabled?: boolean;
	preferredCountries?: string[];
	inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

interface PhoneInputState {
	maxLength?: number;
}

export class PhoneInput extends React.Component<
	PhoneInputProps,
	PhoneInputState
> {
	static ReduxFormItem: CreateReduxField<PhoneInputProps>;
	format?: string;
	state = {
		maxLength: this.props.inputProps?.maxLength
	};

	getMaxLength = (value = '') => {
		const { inputProps } = this.props;

		if (!inputProps || !isNumber(inputProps.maxLength)) {
			return;
		}

		// Ignore whitespaces when setting max length
		const whitespacesCount = value.split(/\s/g).length - 1;
		return inputProps.maxLength + whitespacesCount;
	};

	// Type issues
	// eslint-disable-next-line
	ref = (el: any) => {
		if (el) {
			const defaultValue = el.__domNode.lastChild.defaultValue;
			// Value is not formatted and actual on initial load, therefore
			// it is not possible to determine real max length while taking into account its whitespace characters
			// which must be ignored during validation
			if (defaultValue !== this.props.value) {
				this.updateMaxLength(defaultValue);
			}
		}
	};

	onChange = (value: string, country: { format: string }) => {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
		if (this.format !== country.format) {
			this.updateMaxLength(country.format);
			this.format = country.format;
		}
	};

	updateMaxLength = (value: string) => {
		const maxLength = this.getMaxLength(value);
		// avoid rerending if there is no difference between props and state
		if (maxLength !== this.state.maxLength) {
			this.setState({
				maxLength: this.getMaxLength(value)
			});
		}
	};

	render() {
		const inputProps = {
			...this.props.inputProps,
			maxLength: this.state.maxLength
		};
		return (
			<ReactTelephoneInput
				{...this.props}
				inputProps={inputProps}
				flagsImagePath={flags}
				className={styles.reactTelInput}
				ref={this.ref}
				onChange={this.onChange}
			/>
		);
	}
}

const InputTelFieldMapProps = customMap(props => ({
	onChange: (value: string) => props.input.onChange(value)
}));

PhoneInput.ReduxFormItem = createFieldComponent(
	PhoneInput,
	InputTelFieldMapProps
);

export default PhoneInput;
