import { uniq, keyBy } from 'lodash';
import { Success, Failure } from 'typescript-fsa';
import { GroupUsersState } from '../groupUsersState';
import {
	GroupUser,
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse
} from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveGroupUsersStarted(
	state: GroupUsersState,
	action: RetrieveGroupUsersRequest
): GroupUsersState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			[action.id]: FetchStatus.PENDING
		}
	};
}

export function onRetrieveGroupUsersSuccess(
	state: GroupUsersState,
	action: Success<RetrieveGroupUsersRequest, RetrieveGroupUsersResponse>
): GroupUsersState {
	const { elements } = action.result;
	const {
		params: { id, limit }
	} = action;

	const byId = {
		...state.byId,
		...keyBy(elements, item => item.id)
	};

	const groupUserIds = uniq([
		...(state.byGroupId[id] || []),
		...elements.map((user: GroupUser) => user.id)
	]);

	const byGroupId = {
		...state.byGroupId,
		[id]: groupUserIds
	};
	const fetchStatuses = {
		...state.fetchStatuses,
		[id]: FetchStatus.SUCCESS
	};

	return {
		...state,
		fetchStatuses,
		byId,
		byGroupId,
		hasMore: limit === elements.length
	};
}
export function onRetrieveGroupUsersFailed(
	state: GroupUsersState,
	action: Failure<RetrieveGroupUsersRequest, Error>
): GroupUsersState {
	return {
		...state,
		hasMore: false,
		fetchStatuses: {
			...state.fetchStatuses,
			[action.params.id]: FetchStatus.FAILURE
		}
	};
}
