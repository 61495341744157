import {
	DaSetStatus,
	DisbursementAccount,
	DaSet,
	DaType,
	PortJobService,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';
import { DaByServiceMap } from './financeSelectorTypes';
import { createSelector } from 'reselect';
import {
	getAllDas,
	getDaSets,
	getFinanceServices,
	getDAsIn,
	getAvailableDasByServiceId,
	getDaById,
	hasFinanceMetadataAction
} from './financeSelectors';
import { AppState } from 'store-types';
import {
	FINANCE_SUPPLEMENTAL_TMP_DA_ID,
	FINANCE_SUPPLEMENTAL_LABEL
} from '../constants';
import {
	getDasFromFutureSet,
	getDaSetsByStatus,
	getDasInDaSetsByStatus
} from './financeDASelectors';
import { flow, reject } from 'lodash';

export const getAvailableDAsForService = createSelector(
	getAllDas,
	getDaSets,
	(das, daSets) =>
		getDasInDaSetsByStatus(das, daSets, [
			DaSetStatus.ACTIVE,
			DaSetStatus.FUTURE
		]) // remove da from locked sets
);

// We don't show real label for any supplemental DA. It should be always renamed
const renameDaToSupplemental = (da: DisbursementAccount) => ({
	...da,
	label: FINANCE_SUPPLEMENTAL_LABEL
});

// the purpose is to apply needed change for DA collection to represent Supplemental DA
const renameFutureDasIntoSupplementalLabeled = (das: DisbursementAccount[]) =>
	das.map(renameDaToSupplemental);

export const getAvailableDAOutsForService = createSelector(
	getAvailableDasByServiceId,
	das => das.filter(({ daType }) => daType === DaType.OUT)
);

// Fix caching here it doesn't work
export const getAvailableDAsInForService = createSelector(
	getAvailableDasByServiceId,
	(state: AppState) =>
		hasFinanceMetadataAction(state, FinanceMetadataActionCode.ADD_SUPPLEMENTAL),
	getDasFromFutureSet,
	(_state: AppState, _serviceId: string, currencyCode: string) => currencyCode,
	(loadedDas, hasAddSupplementalAction, futureDaIns, currencyCode: string) => {
		const availableDas: DisbursementAccount[] = [...loadedDas];
		// The list of available Das should includes supplemental das too, in that case we can remove this code
		if (hasAddSupplementalAction) {
			const availableFutureDas = futureDaIns.filter(
				da => da.currencyCode === currencyCode
			);
			if (availableFutureDas.length === 0) {
				availableDas.push({
					id: FINANCE_SUPPLEMENTAL_TMP_DA_ID,
					label: FINANCE_SUPPLEMENTAL_LABEL,
					daType: DaType.IN
				} as DisbursementAccount);
			}
			availableDas.push(
				...renameFutureDasIntoSupplementalLabeled(availableFutureDas)
			);
		}
		return availableDas;
	}
);

const getAvailableDAsForServiceWithDaType = (
	state: AppState,
	daType: DaType,
	serviceId: string,
	_daiId: string,
	currencyCode: string
) =>
	daType === DaType.IN
		? getAvailableDAsInForService(state, serviceId, currencyCode)
		: getAvailableDAOutsForService(state, serviceId);

export const makeGetCurrentDa = () =>
	createSelector(
		(state: AppState) => getDaSetsByStatus(state, DaSetStatus.FUTURE),
		(
			state: AppState,
			_daType: string,
			_serviceId: string,
			selectedDaId: string
		) => getDaById(state, selectedDaId),
		(futureSets, currentDa) => {
			if (!currentDa) {
				return null;
			}
			const isDaBelongsFutureSet = futureSets.some(
				set => set.id === currentDa.disbursementAccountSetId
			);
			if (isDaBelongsFutureSet) {
				return renameDaToSupplemental(currentDa);
			}
			return currentDa;
		}
	);

export const makeGetAvailableDAsByServiceId = () =>
	createSelector(
		getAvailableDAsForServiceWithDaType,
		(_state: AppState, _daType, _serviceId, selectedDaId: string) =>
			selectedDaId,
		(das: DisbursementAccount[], selectedDaId) => {
			return reject(das, { id: selectedDaId });
		}
	);

const futureDas = (das: DisbursementAccount[]) => (daSets: DaSet[]) =>
	getDasInDaSetsByStatus(das, daSets, [DaSetStatus.FUTURE]);

const filterServicesInDas = (services: PortJobService[]) => (
	das: DisbursementAccount[]
) => services.filter(({ daInId }) => das.some(({ id }) => id === daInId));

export const getServicesWithFutureDAIn = createSelector(
	getFinanceServices,
	getDaSets,
	getDAsIn,
	(services, sets, das) =>
		flow(futureDas(das), filterServicesInDas(services))(sets)
);

const isFutureDa = (
	da: DisbursementAccount,
	dasInFutureSet: DisbursementAccount[]
) => dasInFutureSet.some(futureDa => futureDa.id === da.id);

const daWithProperLabel = (
	da: DisbursementAccount | undefined,
	dasInFutureSet: DisbursementAccount[]
) =>
	da && isFutureDa(da, dasInFutureSet)
		? {
				...da,
				label: FINANCE_SUPPLEMENTAL_LABEL
		  }
		: da;

export const getDAByServices = createSelector(
	getAllDas,
	getFinanceServices,
	getDasFromFutureSet,
	(das, services, dasInFutureSet): DaByServiceMap =>
		services.reduce(
			(acc: DaByServiceMap, { id: serviceId, daInId, daOutId }) => {
				acc[serviceId] = {
					[DaType.IN]: daWithProperLabel(
						das.find(da => da.id === daInId),
						dasInFutureSet
					),
					[DaType.OUT]: das.find(da => da.id === daOutId)
				};
				return acc;
			},
			{}
		)
);
