import { Success, Failure } from 'typescript-fsa';
import { FinanceState } from 'store/finance/financeState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	UpdateFinanceServiceRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';

const getSetUpdateStatuses = (
	state: FinanceState,
	status: FetchStatus,
	serviceId?: string
) => {
	if (!serviceId) {
		return state.updateStatuses;
	}
	return {
		...state.updateStatuses,
		services: {
			...state.updateStatuses.services,
			[serviceId]: status
		}
	};
};

export const onUpdateServiceStarted = (
	state: FinanceState,
	{ id }: UpdateFinanceServiceRequest
) => {
	const { finance } = state;
	if (!finance) {
		return state;
	}
	const updateStatuses = getSetUpdateStatuses(state, FetchStatus.PENDING, id);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdateServiceSuccess = (
	state: FinanceState,
	action: Success<UpdateFinanceServiceRequest, PortJobService>
) => {
	const { finance } = state;
	if (!finance) {
		return state;
	}
	const { id } = action.params;
	// Be careful we have already updated service here
	const servicesElements = finance.services.elements.map(
		(current: PortJobService) => {
			if (current.id === id) {
				return action.result;
			} else {
				return current;
			}
		}
	);
	const updateStatuses = getSetUpdateStatuses(state, FetchStatus.SUCCESS, id);

	return {
		...state,
		finance: {
			...finance,
			services: {
				...finance.services,
				elements: servicesElements
			}
		},
		updateStatuses
	};
};

export const onUpdateServiceFailed = (
	state: FinanceState,
	action: Failure<UpdateFinanceServiceRequest, Error>
) => {
	const updateStatuses = getSetUpdateStatuses(
		state,
		FetchStatus.FAILURE,
		action.params.id
	);
	return {
		...state,
		updateStatuses,
		error: action.error
	};
};
