import {
	EntityPermissionVPActionParams,
	EntityPermissionResponse
} from 'services/api/permissions/permissionsServiceTypes';
import actionCreator from '../permissionsActionCreator';

const ACTION_NAME = 'RETRIEVE_ENTITY_VP';
export const retrieveEntityPermissionsForVPAsync = actionCreator.async<
	EntityPermissionVPActionParams,
	EntityPermissionResponse,
	Error
>(ACTION_NAME);

export const retrieveEntityPermissionsForVP = actionCreator<
	EntityPermissionVPActionParams
>(ACTION_NAME);
