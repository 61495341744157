import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { destroy } from 'redux-form';
import { Flex, PageHeader } from 'components';
import { Modal } from 'components/antd';
import CreatePortJobWizard from './CreatePortJobWizard';
import { FORM } from './createPortJobConstants';
import {
	setActivePortCallId,
	retrievePortCall,
	resetPortCall,
	resetPortCalls
} from 'store/portcalls/actions';
import { notify } from 'store/notifications/actions';
import { toNumber, isNull } from 'lodash';

import { setStartPage } from 'store/wizardForm/actions';
import { AppState } from 'store-types';
import { getWizardActivePage } from 'store/wizardForm/wizardFormSelectors';
import { getBreadcrumbsForCreatePortJobWizard } from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { getActivePortCall } from 'store/portcalls/selectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { resetDrafts } from 'store/drafts/actions';

interface CreatePortJobWizardRouteParams {
	portCallId: string;
	newtab?: string;
	stepname?: string;
}
interface CreatePortJobWizardRouteProps
	extends RouteComponentProps<CreatePortJobWizardRouteParams> {
	// from mapStateToProps
	activeWizardPage: number;
	breadcrumbs: BreadcrumbItem[];
	// from mapDispatchToProps
	activePortCall: PortCall;
	destroy: typeof destroy;
	resetPortCalls: typeof resetPortCalls;
	resetPortCall: typeof resetPortCall;
	notifySuccess: typeof notify.success;
	setActivePortCallId: typeof setActivePortCallId;
	retrievePortCall: typeof retrievePortCall;
	setStartPage: typeof setStartPage;
	resetDrafts: typeof resetDrafts;
}

interface CreatePortJobWizardRouteState {
	cancelCreationModalOpen: boolean;
}

export class CreatePortJobWizardRoute extends React.Component<
	CreatePortJobWizardRouteProps,
	CreatePortJobWizardRouteState
> {
	constructor(props: CreatePortJobWizardRouteProps) {
		super(props);
		this.state = {
			cancelCreationModalOpen: false
		};
	}

	componentDidMount() {
		const portCallId = this.props.match.params.portCallId;
		const stepname = this.props.match.params.stepname;
		this.props.resetDrafts();
		if (portCallId && stepname) {
			this.props.retrievePortCall({
				id: portCallId,
				expand: 'Operations'
			});
			this.props.setActivePortCallId(portCallId);
			if (this.props.activePortCall) {
				this.props.setStartPage({ startPage: toNumber(stepname) });
			}
		}
	}

	componentWillUnmount() {
		this.props.destroy(FORM.portJob);
		this.props.destroy(FORM.cargoLine);
		this.props.resetPortCall();
		this.props.resetPortCalls();
		this.props.resetDrafts();
	}

	/**
	 * Added for the case when we have to wait before we get portCall data.
	 * In that case we shouldn't render portCall wizard with redux-form, because it leads to incorrect field initialization
	 * TODO: In case we check it inside the getInitialValues selector we will face with wizard crashing
	 */
	componentDidUpdate(prevProps: CreatePortJobWizardRouteProps) {
		const { activePortCall } = this.props;
		if (isNull(prevProps.activePortCall) && !isNull(activePortCall)) {
			const stepname = this.props.match.params.stepname;
			this.props.setStartPage({ startPage: toNumber(stepname) });
		}
	}

	onCloseTab = () => {
		this.onCancelCreationModalToggle();
	};

	onCancelCreationModalToggle = () => {
		this.setState({
			cancelCreationModalOpen: !this.state.cancelCreationModalOpen
		});
	};

	leavePortJobCreation = () => {
		if (this.props.history.action === 'PUSH') {
			this.props.history.goBack();
		} else {
			this.props.history.push('/portcalls');
		}
	};

	onConfirmCancelCreation = () => {
		this.props.notifySuccess(
			'Job creation process has been successfully cancelled'
		);
		this.leavePortJobCreation();
	};

	render() {
		const { activePortCall } = this.props;
		const portCallId = this.props.match.params.portCallId;

		return (
			<Flex fit direction="vertical" stretch>
				<RouteBreadcrumbs items={this.props.breadcrumbs} />
				<PageHeader.NewTab
					onClose={this.onCloseTab}
					subtitle={this.props.activeWizardPage === 1 && 'Port Call Details'}
					hidePopout
				>
					Create Job
				</PageHeader.NewTab>
				{(!portCallId || (portCallId && activePortCall)) && (
					<CreatePortJobWizard
						onCancel={this.onCancelCreationModalToggle}
						{...this.props}
					/>
				)}

				{this.state.cancelCreationModalOpen && (
					<Modal
						visible
						cancelText={'No, return'}
						okText={'Yes, Cancel'}
						onOk={this.onConfirmCancelCreation}
						onCancel={this.onCancelCreationModalToggle}
					>
						Are you sure you want to cancel job creation process?
					</Modal>
				)}
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		activeWizardPage: getWizardActivePage(state),
		activePortCall: getActivePortCall(state),
		breadcrumbs: getBreadcrumbsForCreatePortJobWizard(state)
	}),
	{
		destroy,
		resetPortCalls,
		notifySuccess: notify.success,
		retrievePortCall,
		resetPortCall,
		setActivePortCallId,
		setStartPage,
		resetDrafts
	}
)(CreatePortJobWizardRoute);
