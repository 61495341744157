import * as React from 'react';
import {
	DashboardPortCall,
	AvailableSections,
	jobSectionsColumnHeadingsMap
} from 'services/api/dashboard/dashboardServiceTypes';
import { Loading, TR, TD } from 'components';
import { PortCallRow } from './PortCallRow';
import styles from './JobSection.module.scss';
import { getPortCallsBySectionCode } from 'store/dashboard/selectors';
import { connect } from 'react-redux';
import { AppState } from 'store-types';

interface JobSectionProps {
	code: AvailableSections;
	// from mapStateToProps
	portCalls: DashboardPortCall[];
}

class Section extends React.PureComponent<JobSectionProps> {
	render() {
		const { portCalls, code } = this.props;
		return portCalls?.length ? (
			<>
				<table className={styles.titleTable}>
					<tbody>
						<TR>
							<TD>Vessel Name</TD>
							<TD>Port</TD>
							<TD>{jobSectionsColumnHeadingsMap[code][0]}</TD>
							<TD>ETB/ATB</TD>
							<TD>{jobSectionsColumnHeadingsMap[code][1]}</TD>
						</TR>
					</tbody>
				</table>
				<table className={styles.portCallTable}>
					<tbody>
						{portCalls.map(portCall => (
							<PortCallRow portCall={portCall} key={portCall.id} />
						))}
					</tbody>
				</table>
			</>
		) : (
			<Loading />
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<JobSectionProps, 'code'>) => ({
		portCalls: getPortCallsBySectionCode(state, ownProps.code)
	})
)(Section);
