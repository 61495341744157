import React from 'react';
import { isArray, isString, isBoolean, noop, isNumber } from 'lodash';
import classNames from 'classnames';
import { IconSize } from 'components/antd/Icon/Icon';
import FooterLink, { FooterLinkProps } from './Footers/FooterLink';
import {
	getContentLinesLength,
	getContentHeightWhenCollapsed
} from './Utils/ReadMoreUtils';
import { ReadMoreType } from './ReadMoreTypes';
import styles from './ReadMore.module.scss';

export interface ReadMoreProps {
	forceExpand?: boolean;
	/**
	 * if set, collapse/expand state won't be defined based on content measurement.
	 * If undefined, content is measured
	 */
	defaultCollapse?: boolean;
	defaultCollapseHeight?: number;
	className?: string;
	toggleBtnClassName?: string;
	maxItems?: number;
	expandText?: string;
	collapseText?: string;
	onToggle?: (collapsed: boolean) => void;
	onContentHeightChange?: () => void;
	loading?: boolean;
	delimiter?: string;
	iconSize?: IconSize;
	source: React.ReactNode;
	shortSource?: React.ReactNode;
	renderFooterContent?: (props: FooterLinkProps) => JSX.Element;
}

export interface ReadMoreState {
	overflowing: boolean;
	collapsed: boolean;
	collapsedInitialized: boolean;
	type: string;
}

class ReadMore extends React.PureComponent<ReadMoreProps, ReadMoreState> {
	ref: HTMLElement | null;

	onContentChangeHandler: ReturnType<typeof setTimeout>;
	onContentHeightChangeHandler: ReturnType<typeof setTimeout>;

	mounted = false;

	constructor(props: ReadMoreProps) {
		super(props);

		const type = this.getCollapsingType(props);

		this.state = {
			type,
			// conrtolling appearence of toggle controls
			overflowing: true,
			// controlling appearence of the content
			collapsed: props.defaultCollapse ?? true,
			collapsedInitialized: isBoolean(props.defaultCollapse)
		};
	}

	componentDidMount() {
		this.mounted = true;

		this.onContentChange();
		this.toggleOnContentHeightChange();
	}

	componentDidUpdate(prevProps: ReadMoreProps) {
		const { forceExpand } = this.props;
		if (isBoolean(forceExpand) && forceExpand !== prevProps.forceExpand) {
			this.onContentChange(!forceExpand);
		}
		this.toggleOnContentHeightChange();
	}

	componentWillUnmount() {
		this.mounted = false;

		if (this.onContentChangeHandler) {
			clearTimeout(this.onContentChangeHandler);
		}
		if (this.onContentHeightChangeHandler) {
			clearTimeout(this.onContentHeightChangeHandler);
		}
	}

	toggleOnContentHeightChange = () => {
		const { onContentHeightChange } = this.props;
		if (!this.mounted || !onContentHeightChange) {
			return;
		}

		if (this.onContentHeightChangeHandler) {
			clearTimeout(this.onContentHeightChangeHandler);
		}

		this.onContentHeightChangeHandler = setTimeout(() => {
			if (this.mounted) {
				onContentHeightChange();
			}
		}, 500);
	};

	onContentChange = (shouldCollapse?: boolean) => {
		this.onContentChangeHandler = setTimeout(() => {
			if (!this.mounted) {
				return;
			}
			const type = this.getCollapsingType(this.props);
			const overflowing = this.isOverflowing(type, this.props);
			this.setState({
				overflowing,
				collapsed:
					shouldCollapse ??
					this.props.defaultCollapse ??
					(overflowing && !this.props.forceExpand),
				collapsedInitialized: true
			});
		}, 0);
	};

	getCollapsingType = (props: ReadMoreProps): string => {
		let type = ReadMoreType.PRIMARY;

		if (props.maxItems) {
			type = ReadMoreType.LINE;

			if (props.delimiter || isArray(props.source)) {
				type = ReadMoreType.DELIMITER;
			}
		}

		return type;
	};

	isOverflowing = (type: string, props: ReadMoreProps): boolean => {
		let result = false;

		switch (type) {
			case ReadMoreType.PRIMARY:
				result = true;
				break;
			case ReadMoreType.LINE:
			case ReadMoreType.DELIMITER:
				if (!props.maxItems) {
					return result;
				}
				result = this.getContentLengthByType(type, props) > props.maxItems;
				break;
			default:
				break;
		}

		return result;
	};

	getContentLengthByType = (type: string, props: ReadMoreProps): number => {
		let len = 0;
		if (!props.source || !props.maxItems) {
			return len;
		}
		switch (type) {
			case ReadMoreType.LINE:
				len = getContentLinesLength(this.ref);
				break;
			case ReadMoreType.DELIMITER:
				if (isString(props.source)) {
					len = props.source.split(props.delimiter || '').length;
				}
				if (isArray(props.source)) {
					len = props.source.length;
				}
				break;
			default:
				break;
		}
		return len;
	};

	onToggle = () => {
		const collapsed = !this.state.collapsed;

		if (this.props.onToggle) {
			this.props.onToggle(collapsed);
		}

		this.setState({
			collapsed
		});
	};

	getContentHeightWhenCollapsed = () => {
		const { collapsed, type } = this.state;
		if (!collapsed) {
			return;
		}
		if (!this.mounted && type === ReadMoreType.LINE) {
			return this.props.defaultCollapseHeight || 0;
		}
		return getContentHeightWhenCollapsed(this.ref, type, this.props.maxItems);
	};

	getContent = () => {
		const { type, collapsed, collapsedInitialized } = this.state;
		const { delimiter, maxItems } = this.props;
		let source = this.props.source;

		if (!this.mounted && !collapsedInitialized && type === ReadMoreType.LINE) {
			return source;
		}

		if (collapsed) {
			if (this.props.shortSource) {
				return this.props.shortSource;
			}

			// short content
			if (type === ReadMoreType.DELIMITER) {
				if (isString(source) && delimiter) {
					source = source
						.split(delimiter)
						.slice(0, maxItems)
						.join(delimiter);
				} else if (isArray(source)) {
					source = source
						.slice(0, maxItems)
						.map((child, index) =>
							React.cloneElement(<>{child}</>, { key: index })
						);
				}
			}
		}

		return source;
	};

	content = () => {
		const { collapsed, type } = this.state;

		const render = type === ReadMoreType.PRIMARY ? !collapsed : true;

		if (!render) {
			return null;
		}

		const content = this.getContent();

		// output children in case the content is expanded
		return (
			<>
				{content}
				{!collapsed && this.props.children}
			</>
		);
	};

	shouldFooterRenderInline = () => {
		return this.state.type === ReadMoreType.DELIMITER;
	};

	renderFooter = () => {
		const props = {
			collapsed: this.state.collapsed,
			className: this.props.toggleBtnClassName,
			expandText: this.props.expandText || 'Show More',
			collapseText: this.props.collapseText || 'Show Less',
			loading: this.props.loading,
			onToggle: this.onToggle,
			renderFooterContent: this.props.renderFooterContent,
			iconSize: this.props.iconSize
		};
		if (this.state.type === ReadMoreType.DELIMITER && this.props.maxItems) {
			props.expandText = `+${this.getContentLengthByType(
				this.state.type,
				this.props
			) - this.props.maxItems} More`;
		}

		return <FooterLink {...props} />;
	};

	render() {
		const { overflowing, collapsed, collapsedInitialized, type } = this.state;
		const { className } = this.props;

		const collapseTypes = {
			[styles.primaryType]: type === ReadMoreType.PRIMARY,
			[classNames(styles.linesType, 'lines-type')]: type === ReadMoreType.LINE,
			[styles.delimiterType]: type === ReadMoreType.DELIMITER
		};

		const rootClassName = classNames(styles.root, className, collapseTypes, {
			[classNames(styles.collapsed, 'collapsed')]: collapsed,
			[styles.clickable]: collapsed && type === ReadMoreType.LINE
		});

		const inlineFooter = this.shouldFooterRenderInline();

		const bodyStyle: React.CSSProperties = {};

		if (type === ReadMoreType.LINE) {
			// In order to avoid UI flickering, before mounted, we visually hide it
			if (!this.mounted && !collapsedInitialized) {
				bodyStyle.visibility = 'hidden';
			}
			const collapsedHeight = this.getContentHeightWhenCollapsed();
			if (isNumber(collapsedHeight) && (!this.mounted || collapsed)) {
				bodyStyle.height = collapsedHeight;
			}
		}
		return (
			<div
				className={rootClassName}
				onClick={collapsed && type === ReadMoreType.LINE ? this.onToggle : noop}
			>
				<div className={styles.body} style={bodyStyle}>
					<div ref={e => (this.ref = e)}>
						{this.content()}
						{overflowing && inlineFooter && this.renderFooter()}
					</div>
				</div>
				{overflowing && !inlineFooter && this.renderFooter()}
			</div>
		);
	}
}

export default ReadMore;
