import {
	RetrieveFinanceRequest,
	RetrieveFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const RETRIEVE_FINANCE = 'RETRIEVE_FINANCE';
export const retrieveFinanceAsync = actionCreator.async<
	RetrieveFinanceRequest,
	RetrieveFinanceResponse,
	Error
>(RETRIEVE_FINANCE);

export const retrieveFinance = actionCreator<RetrieveFinanceRequest>(
	RETRIEVE_FINANCE
);
