import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { keyBy } from 'lodash';
import { ServicesState, initialState } from '../servicesState';
import {
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse
} from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';

const doneReducer = (
	state: ServicesState,
	{
		result
	}: Success<
		RetrieveServiceCategoriesRequest,
		RetrieveServiceCategoriesResponse
	>
): ServicesState => ({
	...state,
	serviceCategories: keyBy(result.elements, item => item.id)
});

export const onRetrieveServiceCategories = makeFetchStatusReducers<
	ServicesState,
	'fetchStatuses',
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse
>('fetchStatuses', 'retrieveServiceCategories', {
	doneReducer
});

export const onResetServiceCategories = (state: ServicesState) => ({
	...state,
	serviceCategories: initialState.serviceCategories,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieveServiceCategories:
			initialState.fetchStatuses.retrieveServiceCategories,
		addServiceCategory: initialState.fetchStatuses.addServiceCategory,
		updateServiceCategory: initialState.fetchStatuses.updateServiceCategory,
		deleteServiceCategory: initialState.fetchStatuses.updateServiceCategory
	}
});
