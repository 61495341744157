import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { updateTagsAction } from './../reducers';
import { UpdateTagsRequest } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Threads.updateThreadTags;
const { async } = updateTagsAction;

export const updateTagsWatcher = makeTakeLatestWatcher<
	UpdateTagsRequest,
	null,
	Error
>({
	api: apiCall,
	async
});

export default updateTagsWatcher;
