import { Action } from 'typescript-fsa';
import { select, takeLatest } from 'redux-saga/effects';

import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getFinanceDocumentPagesLen } from '../selectors';
import Api from 'services/api';
import { deleteDocumentPageWorker } from 'store/documents/sagas/deleteDocumentPageSaga';
import {
	deleteFinanceDocumentOriginalAndAnnotatedPageActionParams,
	deleteFinanceDocumentOriginalAndAnnotatedPageAsync
} from '../actions';

function* worker({
	payload
}: Action<deleteFinanceDocumentOriginalAndAnnotatedPageActionParams>) {
	const count: number = yield select(getFinanceDocumentPagesLen);
	const portCallId: string = yield select(getActivePortCallId);
	const jobCode: string = yield select(getActivePortJobCode);

	yield deleteDocumentPageWorker({
		count,
		returnUrl: `/portcalls/${portCallId}/jobs/${jobCode}/finance`,
		params: {
			...payload,
			portCallId,
			jobCode
		},
		async: deleteFinanceDocumentOriginalAndAnnotatedPageAsync,
		api: Api.Finance.deleteFinanceDocumentOriginalAndAnnotatedPages
	});
}

export default function*() {
	yield takeLatest(
		deleteFinanceDocumentOriginalAndAnnotatedPageAsync.type,
		worker
	);
}
