import React from 'react';
import styles from '../../OperationsContextualization.module.scss';
import { map, isEmpty } from 'lodash';
import { Text } from 'components';
import { Checkbox, Form } from 'components/antd';
import {
	ContextualizationFormFieldsBaseProps,
	DOCUMENT_LEVEL_TITLE
} from '../ContextualizationFormTypes';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface AssignedToFieldProps {
	options: ContextualizationFormFieldsBaseProps['assignedToOptions'];
	name: string;
	tooltip?: TooltipProps;
}
const AssignedToField: React.SFC<AssignedToFieldProps> = ({
	options,
	name,
	tooltip
}) => {
	if (isEmpty(options)) {
		return null;
	}
	return (
		<Form.Item label="Document Assigned To" required>
			<ul className={styles.levelContainer}>
				{map(options, (placeholders, level) => {
					return (
						<li key={level}>
							<Text weight="semi-bold" className={styles.levelTitle}>
								{DOCUMENT_LEVEL_TITLE[level]} Documents
							</Text>

							{map(placeholders, placeholder => (
								<Checkbox.ReduxFormItem
									key={placeholder.id}
									name={`${name}.${placeholder.id}`}
									tooltip={tooltip}
									disabled={!!tooltip}
								>
									{placeholder.name}
								</Checkbox.ReduxFormItem>
							))}
						</li>
					);
				})}
			</ul>
		</Form.Item>
	);
};

export default AssignedToField;
