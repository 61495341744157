import React from 'react';
import { Flex } from 'components';
import { Align, Justify } from 'components/types';
import { Button, Tag, Row, Col } from 'components/antd';
import { FilterItem } from 'store/filters/filtersState';
import styles from './Tags.module.scss';

interface TagListProps {
	tags: FilterItem[];
	onClearOne: (item: FilterItem) => () => void;
	typeMap?: object;
}
const prepareTags = (tags: FilterItem[], typeMap: object = {}) =>
	tags.filter(tag => Boolean(typeMap[tag.type]) || tag.type);

const TagList = ({ tags, typeMap = {}, onClearOne }: TagListProps) => (
	<>
		{tags.map(tag => (
			<Tag
				className={styles.tag}
				key={`${tag.type}-${tag.key}`}
				closable
				onClose={onClearOne(tag)}
			>
				{typeMap[tag.type] ? typeMap[tag.type] : tag.type} ={' '}
				<strong>{tag.label}</strong>
			</Tag>
		))}
	</>
);

interface TagsProps {
	clearOne: (item: FilterItem) => void;
	clearAll: (event: React.MouseEvent<HTMLElement>) => void;
	tags: FilterItem[];
	typeMap?: object;
}

class Tags extends React.PureComponent<TagsProps> {
	onClearOne = (tag: FilterItem) => () => this.props.clearOne(tag);
	render() {
		const { tags, typeMap } = this.props;
		const actualTags = prepareTags(tags);
		if (!actualTags.length) {
			return null;
		}
		return (
			<Row className={styles.root}>
				<Col sm={10} align={Align.MIDDLE} className={styles.tagsListWrapper}>
					<Flex wrap>
						<h4>Search:</h4>
						<TagList
							tags={actualTags}
							onClearOne={this.onClearOne}
							typeMap={typeMap}
						/>
					</Flex>
				</Col>
				<Col sm={2} justify={Justify.END}>
					<Button type="primary" transparent onClick={this.props.clearAll}>
						Clear Search
					</Button>
				</Col>
			</Row>
		);
	}
}

export default Tags;
