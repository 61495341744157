import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrievePortJobSummaryAsync,
	cleanPortJobSummary
} from './actions/retrievePortJobSummary';
import setActivePortJobCode from './actions/setActivePortJobCode';

import {
	retrievePortJobAsync,
	resetPortJobs,
	appointToPortJobAsync,
	confirmPortJobAsync,
	acceptPortJobAsync,
	cancelPortJobAsync,
	uncancelPortJobAsync,
	retrievePortJobOperationAsync,
	deletePortJobAsync,
	deletePortJobOperationAsync,
	resetPortJobOperation,
	editPortJobOperationAsync,
	editPortJobAsync,
	retrieveEditPortJobDataAsync,
	checkPortJobPermissionsAsync,
	retrieveAppointDataAsync,
	retrieveAcceptanceDataAsync,
	retrieveMailboxesForPrincipalAsync,
	retrievePortJobForCycleAsync,
	retrievePortJobMetadataAsync,
	retrieveAvailableGroupsAsync,
	setDefaultMessagingGroupAsync,
	updateSavingsAsync,
	retrieveExtendedMessagingDataAsync,
	sendExtendedmessageAsync,
	togglePortJobStatusAsync,
	toggleOperationsStatusAsync,
	inviteAgentToPortJobAsync,
	retrieveExtendedMessagingConfigurationAsync,
	requestPDAAsync,
	clonePortJobAsync,
	enableOperationsCheckAsync,
	updateEnableOperationsAsync
} from './actions';
import { addPortJobAsync } from './actions/addPortJob';
import { addAppointmentJobFromNominationAsync } from './actions/addAppointmentJobFromNomination';
import { addPortJobOperationAsync } from './actions/addOperation';
import {
	retrievePortJobThreadsAsync,
	resetPortJobThreads
} from './actions/retrievePortJobThreads';

import {
	setActivePortJobThreadId,
	resetActivePortJobThreadId
} from './actions/setActivePortJobThreadId';

import { retrievePortJobStatusesAsync } from './actions/retrievePortJobStatuses';

import retrieveAppointers from './actions/dictionaries/retrieveAppointers';

import retrieveCustodyRoles from './actions/dictionaries/retrievePortJobCustodyRoles';
import setPortJobContext from './actions/setPortJobContext';
import { setPortJobOperationUpToDate } from './actions/setPortJobOperationUpToDate';
import { onSetPortJobOperationUpToDate } from './reducers/setPortJobOperationUpToDateReducer';
import {
	onRetrievePortJobOperationStarted,
	onRetrievePortJobOperationSuccess,
	onRetrievePortJobOperationFailed,
	onResetPortJobOperation,
	onDeletePortJobStarted,
	onDeletePortJobSuccess,
	onDeletePortJobFailed,
	onRetrieveEditPortJobDataStarted,
	onRetrieveMailboxesForPrincipal,
	onRetrieveEditPortJobDataSuccess,
	onRetrieveEditPortJobDataFailed,
	onDeletePortJobOperationStarted,
	onDeletePortJobOperationSuccess,
	onDeletePortJobOperationFailed,
	onEditPortJobOperationStarted,
	onEditPortJobOperationSuccess,
	onEditPortJobOperationFailed,
	onRetrievePortJobSummaryStarted,
	onRetrievePortJobSummarySuccess,
	onRetrievePortJobSummaryFailed,
	onRetrievePortJobSummaryClean,
	onAddPortJobStarted,
	onAddPortJobFailed,
	onAddPortJobSuccess,
	onAddAppointmentJobFromNomination,
	onAddPortJobOperationStarted,
	onAddPortJobOperationSuccess,
	onAddPortJobOperationFailed,
	onRetrievePortJobsThreadsStarted,
	onRetrievePortJobsThreadsFailed,
	onSavePortJobThreadIds,
	onResetPortJobThreadIds,
	onConfirmPortJobStarted,
	onConfirmPortJobDone,
	onConfirmPortJobFailed,
	onAcceptPortJobFailed,
	onCancelPortJob,
	onUpdateSavings,
	onSetActivePortJobThreadId,
	onResetActivePortJobThreadId,
	onRetrievePortJobStatusesStarted,
	onRetrievePortJobStatusesSuccess,
	onRetrievePortJobStatusesFailed,
	onSetActivePortJobCode,
	onResetActivePortJobCode,
	onRetrievePortJobStarted,
	onRetrievePortJobFailed,
	onRetrievePortJobSuccess,
	onResetPortJobs,
	onAppointToPortJobStarted,
	onAppointToPortJobFailed,
	onAppointToPortJobSuccess,
	onSetPortJobContext,
	onEditPortJobStarted,
	onEditPortJobSuccess,
	onEditPortJobFailed,
	onCheckPortJobPermissionsStarted,
	onCheckPortJobPermissionsSuccess,
	onCheckPortJobPermissionsFailed,
	onRetrieveAppointersStarted,
	onRetrieveAppointersSuccess,
	onRetrieveAppointersFailed,
	onRetrievePortJobCustodyRolesStarted,
	onRetrievePortJobCustodyRolesSuccess,
	onRetrievePortJobCustodyRolesFailed,
	onPortJobCustodyRolesReset,
	onRetrieveAppointData,
	onRetrieveAcceptanceData,
	onRetrievePortJobMetadata,
	onRetrieveAvailableGroups,
	onSetDefaultMessagingGroup,
	onRetrieveExtendedMessagingData,
	onSendExtendedMessages,
	onTogglePortJobStatus,
	onToggleOperationsStatus,
	onInviteAgentToPortJob,
	onRetrieveExtendedMessagingConfigurationData,
	onPDARequest,
	onCloneJob,
	onEnableOperationsCheck,
	onUpdateEnableOperations
} from './reducers';

import { initialState } from './portJobsState';
import { setPortJobUpToDate } from './actions/setPortJobUpToDate';
import { onSetPortJobUpToDate } from './reducers/setPortJobUpToDateReducer';

export default reducerWithInitialState(initialState)
	.case(setActivePortJobCode.set, onSetActivePortJobCode)
	.case(setActivePortJobCode.reset, onResetActivePortJobCode)
	.case(
		retrieveMailboxesForPrincipalAsync.started,
		onRetrieveMailboxesForPrincipal.started
	)
	.case(
		retrieveMailboxesForPrincipalAsync.done,
		onRetrieveMailboxesForPrincipal.done
	)
	.case(
		retrieveMailboxesForPrincipalAsync.failed,
		onRetrieveMailboxesForPrincipal.failed
	)
	// Retrieve Port Job
	.case(retrievePortJobAsync.started, onRetrievePortJobStarted)
	.case(retrievePortJobAsync.failed, onRetrievePortJobFailed)
	.case(retrievePortJobAsync.done, onRetrievePortJobSuccess)
	.case(resetPortJobs, onResetPortJobs)
	.case(retrievePortJobForCycleAsync.started, onRetrievePortJobStarted)
	.case(retrievePortJobForCycleAsync.failed, onRetrievePortJobFailed)
	.case(retrievePortJobForCycleAsync.done, onRetrievePortJobSuccess)
	// Retrieve Port Operation
	.case(
		retrievePortJobOperationAsync.started,
		onRetrievePortJobOperationStarted
	)
	.case(retrievePortJobOperationAsync.failed, onRetrievePortJobOperationFailed)
	.case(retrievePortJobOperationAsync.done, onRetrievePortJobOperationSuccess)
	.case(resetPortJobOperation, onResetPortJobOperation)
	// Edit Port Operation
	.case(editPortJobOperationAsync.started, onEditPortJobOperationStarted)
	.case(editPortJobOperationAsync.failed, onEditPortJobOperationFailed)
	.case(editPortJobOperationAsync.done, onEditPortJobOperationSuccess)
	// Edit Port
	.case(editPortJobAsync.started, onEditPortJobStarted)
	.case(editPortJobAsync.failed, onEditPortJobFailed)
	.case(editPortJobAsync.done, onEditPortJobSuccess)
	// Confirm Port Job
	.case(confirmPortJobAsync.started, onConfirmPortJobStarted)
	.case(confirmPortJobAsync.done, onConfirmPortJobDone)
	.case(confirmPortJobAsync.failed, onConfirmPortJobFailed)
	// Accept Port Job
	.case(acceptPortJobAsync.started, onConfirmPortJobStarted)
	.case(acceptPortJobAsync.done, onConfirmPortJobDone)
	.case(acceptPortJobAsync.failed, onAcceptPortJobFailed)
	// Cancel Port Job
	.case(cancelPortJobAsync.started, onCancelPortJob.started)
	.case(cancelPortJobAsync.done, onCancelPortJob.done)
	.case(cancelPortJobAsync.failed, onCancelPortJob.failed)
	// Uncancel Port Job
	.case(uncancelPortJobAsync.started, onCancelPortJob.started)
	.case(uncancelPortJobAsync.done, onCancelPortJob.done)
	.case(uncancelPortJobAsync.failed, onCancelPortJob.failed)
	// Toggle Port Job
	.case(togglePortJobStatusAsync.started, onTogglePortJobStatus.started)
	.case(togglePortJobStatusAsync.done, onTogglePortJobStatus.done)
	.case(togglePortJobStatusAsync.failed, onTogglePortJobStatus.failed)
	// Toggle Operations Status
	.case(toggleOperationsStatusAsync.started, onToggleOperationsStatus.started)
	.case(toggleOperationsStatusAsync.done, onToggleOperationsStatus.done)
	.case(toggleOperationsStatusAsync.failed, onToggleOperationsStatus.failed)
	// Add Port Job
	.case(addPortJobAsync.started, onAddPortJobStarted)
	.case(addPortJobAsync.failed, onAddPortJobFailed)
	.case(addPortJobAsync.done, onAddPortJobSuccess)
	// Add Appointment Job From Nomination Job
	.case(
		addAppointmentJobFromNominationAsync.started,
		onAddAppointmentJobFromNomination.started
	)
	.case(
		addAppointmentJobFromNominationAsync.failed,
		onAddAppointmentJobFromNomination.failed
	)
	.case(
		addAppointmentJobFromNominationAsync.done,
		onAddAppointmentJobFromNomination.done
	)
	// Add portJob data to edit form
	.case(retrieveEditPortJobDataAsync.started, onRetrieveEditPortJobDataStarted)
	.case(retrieveEditPortJobDataAsync.done, onRetrieveEditPortJobDataSuccess)
	.case(retrieveEditPortJobDataAsync.failed, onRetrieveEditPortJobDataFailed)
	// Delete Port Job
	.case(deletePortJobAsync.started, onDeletePortJobStarted)
	.case(deletePortJobAsync.done, onDeletePortJobSuccess)
	.case(deletePortJobAsync.failed, onDeletePortJobFailed)
	// Port Job Summary
	.case(retrievePortJobSummaryAsync.started, onRetrievePortJobSummaryStarted)
	.case(retrievePortJobSummaryAsync.done, onRetrievePortJobSummarySuccess)
	.case(retrievePortJobSummaryAsync.failed, onRetrievePortJobSummaryFailed)
	.case(cleanPortJobSummary, onRetrievePortJobSummaryClean)
	// Add PortJobOperation To Port Job
	.case(addPortJobOperationAsync.started, onAddPortJobOperationStarted)
	.case(addPortJobOperationAsync.done, onAddPortJobOperationSuccess)
	.case(addPortJobOperationAsync.failed, onAddPortJobOperationFailed)
	// Delete PortJobOperation From Port Job
	.case(deletePortJobOperationAsync.started, onDeletePortJobOperationStarted)
	.case(deletePortJobOperationAsync.done, onDeletePortJobOperationSuccess)
	.case(deletePortJobOperationAsync.failed, onDeletePortJobOperationFailed)
	// Retrieve Port Job Threads
	.case(retrievePortJobThreadsAsync.started, onRetrievePortJobsThreadsStarted)
	.case(retrievePortJobThreadsAsync.done, onSavePortJobThreadIds)
	.case(retrievePortJobThreadsAsync.failed, onRetrievePortJobsThreadsFailed)
	// Reset Port Job Threads
	.case(resetPortJobThreads, onResetPortJobThreadIds)
	// Set Active Port Job Thread Id
	.case(setActivePortJobThreadId, onSetActivePortJobThreadId)
	// Reset Active Port Job Thread Id
	.case(resetActivePortJobThreadId, onResetActivePortJobThreadId)
	.case(retrievePortJobStatusesAsync.started, onRetrievePortJobStatusesStarted)
	.case(retrievePortJobStatusesAsync.done, onRetrievePortJobStatusesSuccess)
	.case(retrievePortJobStatusesAsync.failed, onRetrievePortJobStatusesFailed)
	// PUT Appoint to port job
	.case(appointToPortJobAsync.started, onAppointToPortJobStarted)
	.case(appointToPortJobAsync.done, onAppointToPortJobSuccess)
	.case(appointToPortJobAsync.failed, onAppointToPortJobFailed)
	// GET Custody Roles
	.case(
		retrieveCustodyRoles.async.started,
		onRetrievePortJobCustodyRolesStarted
	)
	.case(retrieveCustodyRoles.async.done, onRetrievePortJobCustodyRolesSuccess)
	.case(retrieveCustodyRoles.async.failed, onRetrievePortJobCustodyRolesFailed)
	// RESET Custody Roles
	.case(retrieveCustodyRoles.reset, onPortJobCustodyRolesReset)
	// GET Appointers
	.case(retrieveAppointers.async.started, onRetrieveAppointersStarted)
	.case(retrieveAppointers.async.done, onRetrieveAppointersSuccess)
	.case(retrieveAppointers.async.failed, onRetrieveAppointersFailed)
	// Update any Context
	.case(setPortJobContext.action, onSetPortJobContext)
	// Check PortJob Permissions
	.case(checkPortJobPermissionsAsync.started, onCheckPortJobPermissionsStarted)
	.case(checkPortJobPermissionsAsync.done, onCheckPortJobPermissionsSuccess)
	.case(checkPortJobPermissionsAsync.failed, onCheckPortJobPermissionsFailed)
	// Retrieve appoint data
	.case(retrieveAppointDataAsync.started, onRetrieveAppointData.started)
	.case(retrieveAppointDataAsync.done, onRetrieveAppointData.done)
	.case(retrieveAppointDataAsync.failed, onRetrieveAppointData.failed)
	// Retrieve acceptance data
	.case(retrieveAcceptanceDataAsync.started, onRetrieveAcceptanceData.started)
	.case(retrieveAcceptanceDataAsync.done, onRetrieveAcceptanceData.done)
	.case(retrieveAcceptanceDataAsync.failed, onRetrieveAcceptanceData.failed)
	.case(setPortJobUpToDate, onSetPortJobUpToDate)
	// Set upToDate flag
	.case(setPortJobOperationUpToDate, onSetPortJobOperationUpToDate)
	// Retrieve port job metadata
	.case(retrievePortJobMetadataAsync.started, onRetrievePortJobMetadata.started)
	.case(retrievePortJobMetadataAsync.done, onRetrievePortJobMetadata.done)
	.case(retrievePortJobMetadataAsync.failed, onRetrievePortJobMetadata.failed)
	// Retrieve available groups
	.case(retrieveAvailableGroupsAsync.started, onRetrieveAvailableGroups.started)
	.case(retrieveAvailableGroupsAsync.done, onRetrieveAvailableGroups.done)
	.case(retrieveAvailableGroupsAsync.failed, onRetrieveAvailableGroups.failed)
	// Set default messaging group
	.case(
		setDefaultMessagingGroupAsync.started,
		onSetDefaultMessagingGroup.started
	)
	.case(setDefaultMessagingGroupAsync.done, onSetDefaultMessagingGroup.done)
	.case(setDefaultMessagingGroupAsync.failed, onSetDefaultMessagingGroup.failed)
	// invite agent to port job
	.case(inviteAgentToPortJobAsync.done, onInviteAgentToPortJob.done)
	// Update Savings
	.case(updateSavingsAsync.started, onUpdateSavings.started)
	.case(updateSavingsAsync.done, onUpdateSavings.done)
	.case(updateSavingsAsync.failed, onUpdateSavings.failed)
	// Retrieve extended messaging data
	.case(
		retrieveExtendedMessagingDataAsync.started,
		onRetrieveExtendedMessagingData.started
	)
	.case(
		retrieveExtendedMessagingDataAsync.done,
		onRetrieveExtendedMessagingData.done
	)
	.case(
		retrieveExtendedMessagingDataAsync.failed,
		onRetrieveExtendedMessagingData.failed
	)
	//Send Extended Messages
	.case(sendExtendedmessageAsync.started, onSendExtendedMessages.started)
	.case(sendExtendedmessageAsync.failed, onSendExtendedMessages.failed)
	.case(sendExtendedmessageAsync.done, onSendExtendedMessages.done)
	//Retrieve Extended Messages Configuration
	.case(
		retrieveExtendedMessagingConfigurationAsync.started,
		onRetrieveExtendedMessagingConfigurationData.started
	)
	.case(
		retrieveExtendedMessagingConfigurationAsync.failed,
		onRetrieveExtendedMessagingConfigurationData.failed
	)
	.case(
		retrieveExtendedMessagingConfigurationAsync.done,
		onRetrieveExtendedMessagingConfigurationData.done
	)
	.case(requestPDAAsync.started, onPDARequest.started)
	.case(requestPDAAsync.failed, onPDARequest.failed)
	.case(requestPDAAsync.done, onPDARequest.done)
	.case(clonePortJobAsync.started, onCloneJob.started)
	.case(clonePortJobAsync.failed, onCloneJob.failed)
	.case(clonePortJobAsync.done, onCloneJob.done)
	.case(enableOperationsCheckAsync.started, onEnableOperationsCheck.started)
	.case(enableOperationsCheckAsync.failed, onEnableOperationsCheck.failed)
	.case(enableOperationsCheckAsync.done, onEnableOperationsCheck.done)
	.case(updateEnableOperationsAsync.started, onUpdateEnableOperations.started)
	.case(updateEnableOperationsAsync.failed, onUpdateEnableOperations.failed)
	.case(updateEnableOperationsAsync.done, onUpdateEnableOperations.done);
