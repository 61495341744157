import React from 'react';
import { isNil } from 'lodash';
import { Radio } from 'components/antd';
import {
	FinanceFundingFormField,
	FinanceFundingFormData
} from 'store/form/financeFundings/financeFundingTypes';
import styles from './FundingBaseAmount.module.scss';
import { BaseAmountTypeCodes } from 'services/api/financeFundings/financeFundingsServiceTypes';

interface FundingBaseAmountProps {
	defaultValues: Partial<FinanceFundingFormData>;
	values: Partial<FinanceFundingFormData>;
}

export function FundingBaseAmount(props: FundingBaseAmountProps) {
	const { defaultValues, values } = props;
	const {
		balanceForDaInAmountWithoutAgencyCosts,
		balanceForDaInAmount
	} = values;
	return (
		<>
			<Radio.Group.ReduxFormItem
				name={FinanceFundingFormField.BASE_AMOUNT_TYPE_CODE}
				defaultValue={defaultValues.baseAmountTypeCode}
				label={<b>Select basis amount for prefunding</b>}
				className={styles.selectBasisRadioGroup}
			>
				<Radio
					value={BaseAmountTypeCodes.DA_IN_AMOUNT_WITHOUT_AGENCY_COSTS}
					disabled={
						!isNil(balanceForDaInAmountWithoutAgencyCosts) &&
						balanceForDaInAmountWithoutAgencyCosts <= 0
					}
				>
					DA amount without agency costs
				</Radio>
				<Radio
					value={BaseAmountTypeCodes.DA_IN_AMOUNT}
					disabled={!isNil(balanceForDaInAmount) && balanceForDaInAmount <= 0}
				>
					Full DA amount
				</Radio>
			</Radio.Group.ReduxFormItem>
		</>
	);
}
export default FundingBaseAmount;
