import React from 'react';
import { Asteriks } from 'components';
import { Modal, Table } from 'components/antd';
import { TotalAmountFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import getColumns from './TotalAmountGridColumns';

interface TotalAmountLinesGridProps {
	dataSource: TotalAmountFormData[];
	onEdit: (index: string) => void;
	onDelete: (index: string) => void;
}

interface TotalAmountLinesGridState {
	showDeleteConfirmationDialog: boolean;
	totalAmountLineId: string;
}

class TotalAmountLinesGrid extends React.PureComponent<
	TotalAmountLinesGridProps,
	TotalAmountLinesGridState
> {
	constructor(props: TotalAmountLinesGridProps) {
		super(props);
		this.state = {
			showDeleteConfirmationDialog: false,
			totalAmountLineId: ''
		};
	}

	onDelete = (totalAmountLineId: string) => {
		this.setState({
			totalAmountLineId,
			showDeleteConfirmationDialog: true
		});
	};

	onDeleteConfirmed = () => {
		const { totalAmountLineId } = this.state;
		this.hideDeleteConfirmationDialog();
		this.props.onDelete(totalAmountLineId);
	};

	hideDeleteConfirmationDialog = () => {
		this.setState({
			showDeleteConfirmationDialog: false
		});
	};

	render() {
		const { dataSource, onEdit } = this.props;
		return (
			<div>
				<Table
					dataSource={dataSource}
					pagination={false}
					columns={getColumns({ onEdit, onDelete: this.onDelete })}
					showHeader
					locale={{
						emptyText: (
							<Asteriks position="right">
								Add Details for the first line
							</Asteriks>
						)
					}}
				/>
				<Modal
					visible={this.state.showDeleteConfirmationDialog}
					onCancel={this.hideDeleteConfirmationDialog}
					onOk={this.onDeleteConfirmed}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>Are you sure you want to delete line?</p>
				</Modal>
			</div>
		);
	}
}

export default TotalAmountLinesGrid;
