import actionCreatorFactory from 'typescript-fsa';

import {
	Currency,
	RetrieveCurrenciesRequest
} from 'services/api/currencies/currenciesServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_CURRENCIES = 'currencies/RETRIEVE_CURRENCIES';
export const retrieveCurrenciesAsync = actionCreator.async<
	RetrieveCurrenciesRequest,
	Currency[],
	Error
>(RETRIEVE_CURRENCIES);

export const retrieveCurrencies = actionCreator<
	RetrieveCurrenciesRequest | undefined
>(retrieveCurrenciesAsync.type);
