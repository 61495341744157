import * as React from 'react';
import { Justify } from 'components/types';
import { Col, Row, Button, Icon } from 'components/antd';
import styles from '../DeveloperPortal.module.scss';
import { RequestType } from './constants';
import classNames from 'classnames';

interface ApiPermissionHeaderProps {
	title: string;
	checked: number;
	size: number;
	requestType: string;
}

const ApiPermissionHeader = ({
	title,
	checked,
	size,
	requestType
}: ApiPermissionHeaderProps) => {
	const button = (
		<Button
			type="primary"
			transparent
			className={classNames(styles.permissionTitle)}
		>
			{title}
			&nbsp;
			{requestType === RequestType.INBOUND && (
				<Icon type="developer-port-in" color="primary" size="xl" />
			)}
			{requestType === RequestType.OUTBOUND && (
				<Icon type="developer-port-out" color="primary" size="xl" />
			)}
		</Button>
	);
	return (
		<Col sm={12} className={classNames(styles.header)}>
			<Row>
				<Col sm={10}>{button}</Col>
				<Col sm={2} justify={Justify.START}>
					<div className={styles.tag}>
						{checked} of {size}
					</div>
				</Col>
			</Row>
		</Col>
	);
};

export default ApiPermissionHeader;
