enum ChartererFormFieldName {
	OPERATIONAL_INSTRUCTIONS = 'operationalInstructions',
	FINANCIAL_INSTRUCTIONS = 'financialInstructions',
	IS_WORLDSCALE_SERVICES_APPLIED = 'isWorldScaleServicesApplied',
	STAND_BY_TUGS = 'standbyTugs',
	BERTHAGE_WHARFAGE = 'berthageWharfage',
	IS_OTHER_REBILLABLES_APPLIED = 'isOtherRebillablesApplied',
	COST_SETTLEMENT_IS_DIRECT = 'costSettlementIsDirect',
	TIME_BAR_IS_APPLIED = 'timeBarisApplied',
	TIME_BAR_DAYS = 'timeBarDays',
	MAX_PORT_COST = 'maxPortCost',
	MAX_PORT_COST_AMOUNT = 'maxPortCostAmount',
	MAX_PORT_COST_CURRENCY = 'maxPortCostCurrency'
}

export default ChartererFormFieldName;
