import React from 'react';

import { ScrollableLayout, PageHeader } from 'components';
import DocumentPageViewerImagePreview from './DocumentPageViewerImagePreview';

import { DocumentViewerAside } from 'sections/DocumentViewer';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

import { navigateTo } from 'utils';

interface DocumentPageViewerHelpers {
	activePage: number;
	onPageSelect: (n: number) => void;
}

interface DocumentPageViewerProps {
	document: PortCallDocument;
	isDocumentPDF: boolean;
	isAnnotatable?: boolean;
	returnUrl: string;
	onFinish: () => void;
	total: number;
	onPageDelete?: (n: number) => void;
	children: (helpers: DocumentPageViewerHelpers) => React.ReactNode;
}

interface DocumentPageViewerState {
	activePage: number;
}

class DocumentPageViewer extends React.Component<
	DocumentPageViewerProps,
	DocumentPageViewerState
> {
	constructor(props: DocumentPageViewerProps) {
		super(props);
		this.state = {
			activePage: 0
		};
	}

	componentDidUpdate(prevProps: DocumentPageViewerProps) {
		if (prevProps.total !== this.props.total) {
			this.updateActivePageOnTotalChange();
		}
	}

	updateActivePageOnTotalChange = () => {
		if (this.state.activePage >= this.props.total) {
			this.onPageSelect(0);
		}
	};

	onFinish = () => {
		this.props.onFinish();
		this.onClose();
	};

	onClose = () => {
		navigateTo(this.props.returnUrl);
	};

	onPageSelect = (activePage: number) => {
		this.setState({ activePage });
	};

	getImageUrl = () => {
		const page = this.props.document.pages[this.state.activePage];
		return page?.downloadUrl;
	};

	getHelpers = (): DocumentPageViewerHelpers => {
		return {
			activePage: this.state.activePage,
			onPageSelect: this.onPageSelect
		};
	};

	render() {
		const { isDocumentPDF, document, isAnnotatable } = this.props;

		return (
			<ScrollableLayout stretch direction="horizontal">
				<PageHeader.NewTab onClose={this.onClose}>
					{document.name}
				</PageHeader.NewTab>
				<ScrollableLayout>
					<DocumentPageViewerImagePreview
						imageUrl={this.getImageUrl()}
						isDocumentPDF={isDocumentPDF}
						isAnnotatable={isAnnotatable}
						fileName={document.file.fileName}
					/>
					<DocumentViewerAside
						pagination={PaginationState.NONE}
						canSubmit={true}
						loading={false}
						isDocumentPDF={isDocumentPDF}
						onFinish={this.onFinish}
						onCancel={this.onClose}
						okButtonProps={{
							children: 'Download All',
							type: 'primary',
							icon: 'download',
							ghost: true
						}}
						cancelButtonProps={{
							children: 'Close'
						}}
					>
						{this.props.children(this.getHelpers())}
					</DocumentViewerAside>
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}

export default DocumentPageViewer;
