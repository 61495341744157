import { first } from 'lodash';
import runValidations, { required } from 'utils/validations';
import {
	FormData,
	FormDataErrors
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

const validate = (values: FormData): FormDataErrors => ({
	details: first(runValidations(values.details, [required]))
});

export default validate;
