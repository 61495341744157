import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { Popover, Icon } from 'components/antd';
import { Flex } from 'components';
import styles from './EditToolbar.module.scss';

const COLORS = ['blue', 'green', 'red', 'gray', 'black', 'orange'];

interface ColorsProps {
	color: string;
	onClick: (color: string) => void;
}

const Colors = (props: ColorsProps) => {
	return (
		<Flex className={styles.palette} direction="horizontal">
			{COLORS.map(color => (
				<div
					className={cn(styles.paletteItemWrapper, {
						[styles.selected]: props.color === styles[`--${color}Color`]
					})}
					onClick={() => {
						props.onClick(color);
					}}
				>
					<div className={cn(styles.paletteItem, styles[color])}></div>
				</div>
			))}
		</Flex>
	);
};

interface ColorPaletePopoverProps {
	color: string;
	onColorChange: (color: string) => void;
}

function ColorPaletePopover(props: ColorPaletePopoverProps) {
	const [visibility, setVisibility] = useState(false);

	const onOpenPalette = useCallback(() => {
		setVisibility(true);
	}, []);

	const onVisibleChange = useCallback((value: boolean) => {
		setVisibility(value);
	}, []);

	return (
		<Popover
			overlayClassName={styles.palettePopover}
			content={<Colors color={props.color} onClick={props.onColorChange} />}
			trigger="click"
			visible={visibility}
			placement="right"
			onVisibleChange={onVisibleChange}
		>
			<Icon size="lg" type="color" onClick={onOpenPalette} />
		</Popover>
	);
}

export default React.memo(ColorPaletePopover);
