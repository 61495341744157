import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from '../financeState';
import {
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse
} from 'services/api/finance/financeServiceTypes';

export const onRetrieveDAStatusHistoryByIdSuccess = (
	state: FinanceState,
	{
		params: { daId },
		result: { elements }
	}: Success<
		RetrieveDAStatusHistoryByIdRequest,
		RetrieveDAStatusHistoryByIdResponse
	>
): FinanceState => {
	return {
		...state,
		daStatusHistoryById: {
			...state.daStatusHistoryById,
			[daId]: elements
		}
	};
};

export const onRetrieveDAStatusHistoryById = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveDAStatusHistoryByIdRequest,
	RetrieveDAStatusHistoryByIdResponse
>(
	'fetchStatuses',
	({
		daId
	}: RetrieveDAStatusHistoryByIdRequest): keyof FinanceState['fetchStatuses'] =>
		`retrieveDAStatusHistoryById.${daId}` as keyof FinanceState['fetchStatuses'],
	{
		doneReducer: onRetrieveDAStatusHistoryByIdSuccess
	}
);
