import actionCreator from '../portJobsActionCreator';
import { PortJobBaseRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { ServiceSection } from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';

const ACTION_NAME = 'ENABLE_OPERATIONS_CHECK';

export const enableOperationsCheck = actionCreator(ACTION_NAME);

export const enableOperationsCheckAsync = actionCreator.async<
	PortJobBaseRequest,
	ServiceSection[],
	Error
>(ACTION_NAME);
