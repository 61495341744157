import { AppState } from 'store-types';
import { PortCallContext } from './portCallState';

export const getPortCallContext = (
	state: AppState,
	key: keyof PortCallContext
) => state.portCall.context[key];

export const getRetrieveEditPortCallDataFetchStatus = (state: AppState) =>
	state.portCall.fetchStatuses.retrieveEditPortCallData;

export const getUpdatePortCallFetchStatus = (state: AppState) =>
	state.portCall.fetchStatuses.updatePortCall;

export const getIsPortCallUpToDate = (state: AppState) =>
	state.portCall.context.isPortCallUpToDate;
