import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';

export const LINK_JOBS_MODAL = 'LinkJobModal';
export const CANCEL_LINK_JOBS_MODAL = 'CancelLinkJobModal';
export const CANCEL_LINK_CARGO_MODAL = 'CancelLinkCargoModal';
export const LINK_JOB_WIZARD_ID = 'linkJob';

export const operationDisplayName = {
	[OperationTypeCode.LOADING]: 'Loading',
	[OperationTypeCode.DISCHARGING]: 'Discharge',
	[OperationTypeCode.LOADING_STS]: 'Loading STS',
	[OperationTypeCode.DISCHARGING_STS]: 'Discharge STS'
};
