import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
	AppointAgentToPortPayload,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { appointToPortJobAsync } from 'store/portJobs/actions';
import { getPortJobConcurrencyTokenByCode } from '../portJobsSelectors';
import { closeModal } from 'store/modals/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { executor as retrievePortJobCustodyRolesSaga } from './dictionaries/retrievePortJobCustodyRolesSaga';

const apiCall = Api.PortJobs.appointToPortJob;

/**
	As part of OW-13116(APA_09) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

export const executor = function*(
	payload: AppointAgentToPortPayload
): SagaIterator {
	const { modalId } = payload;
	try {
		const concurrencyToken = yield select(
			getPortJobConcurrencyTokenByCode,
			payload.jobCode
		);
		// const isExtendedMessagingEnabled = yield select(
		// 	isExtendedMessagingEnabledForPortJob
		// );
		const response = yield call(apiCall, {
			...payload,
			concurrencyToken,
			isGateway: false
		});

		yield put(
			appointToPortJobAsync.done({
				result: { status: response.data.status },
				params: payload,
				response
			})
		);
	} catch (error) {
		const { portCallId, jobCode } = payload;
		/** Retrieve onError to make sure that Dropdown will contain the latest data */
		yield call(retrievePortJobCustodyRolesSaga, { portCallId, jobCode });
		yield put(closeModal(modalId));
		yield put(
			appointToPortJobAsync.failed({
				error,
				params: payload
			})
		);
	}
};

export const appointToPortJobWatcher = makeTakeLatestWatcher<
	AppointAgentToPortPayload,
	PortJobStatusUpdateResponse,
	Error
>(
	{
		api: apiCall,
		async: appointToPortJobAsync
	},
	executor
);

export default appointToPortJobWatcher;
