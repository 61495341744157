import actionCreator from '../opticConfigActionCreator';
import {
	RetrieveKPIDetailsByIdResponse,
	RetrieveKPIDetailsByIdRequest
} from 'services/api/opticConfiguration/opticConfigServiceTypes';

const RETRIEVE_KPI_DETAILS_BY_ID = 'RETRIEVE_KPI_DETAILS_BY_ID';

export const retrieveKpiDetailsById = actionCreator<
	RetrieveKPIDetailsByIdRequest
>(RETRIEVE_KPI_DETAILS_BY_ID);

export const retrieveKpiDetailsByIdAsync = actionCreator.async<
	RetrieveKPIDetailsByIdRequest,
	RetrieveKPIDetailsByIdResponse,
	Error
>(RETRIEVE_KPI_DETAILS_BY_ID);
