import {
	CostSaving,
	Savings,
	TimeSavings,
	TimeSaving
} from 'services/api/savings/savingsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface SavingsState {
	context: {
		activeId: string;
		costSavings: CostSaving | undefined;
		timeSavings: TimeSaving | undefined;
	};
	savings: Savings | undefined;
	timeSavings: TimeSavings | undefined;
	fetchStatuses: {
		context: FetchStatus;
		savings: FetchStatus;
		timeSavings: FetchStatus;
		addCostSavings: FetchStatus;
		addTimeSavings: FetchStatus;
	};
}

export const initialState: SavingsState = {
	context: {
		activeId: '',
		costSavings: undefined,
		timeSavings: undefined
	},
	savings: undefined,
	timeSavings: undefined,
	fetchStatuses: {
		context: FetchStatus.IDLE,
		savings: FetchStatus.IDLE,
		timeSavings: FetchStatus.IDLE,
		addCostSavings: FetchStatus.IDLE,
		addTimeSavings: FetchStatus.IDLE
	}
};
