import { put, takeLatest } from 'redux-saga/effects';

import { closeModal } from 'store/modals/actions';
import { closeConfigurationFileModal } from '../../actions';

import { CONFIGURATION_FILE_FORM_NAME } from '../../configurationFilesTypes';

export function* worker() {
	yield put(closeModal({ name: CONFIGURATION_FILE_FORM_NAME }));
}

export default function*() {
	yield takeLatest(closeConfigurationFileModal.type, worker);
}
