import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';

import DataFetcher from 'utils/components/DataFetcher';
import AppLogoLayout from 'sections/AppLayout/AppLogoLayout/AppLogoLayout';
import {
	PreRegistrationPanelSuccess,
	PreRegistrationPanelFailed
} from './PreRegistrationPanel';

import {
	preRegisterVerify,
	resetPreRegistration
} from 'store/registration/actions';
import {
	getPreRegistrationVerifyFetchStatus,
	getPreRegistrationUserEmail
} from 'store/registration/selectors';

import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

interface RouteParams {
	token: string;
}
type PreRegistrationVerifyRouteProps = RouteComponentProps<RouteParams> & {
	userEmail: string;
	fetchStatus: FetchStatus;
	verify: typeof preRegisterVerify;
	reset: typeof resetPreRegistration;
};
const PreRegistrationVerifyRoute = ({
	userEmail,
	fetchStatus,
	verify,
	reset,
	location
}: PreRegistrationVerifyRouteProps) => {
	const params = queryString.parse(location.search);
	return (
		<AppLogoLayout>
			<DataFetcher
				fetchStatus={fetchStatus}
				dispatch={() => verify({ token: params.token })}
				reset={() => reset()}
				error={() => (
					<PreRegistrationPanelFailed>
						Sorry, the link is not valid. Please start the registration process
						again from the link in your appointment message.
					</PreRegistrationPanelFailed>
				)}
			>
				{() => {
					return <PreRegistrationPanelSuccess email={userEmail} />;
				}}
			</DataFetcher>
		</AppLogoLayout>
	);
};

export default connect(
	(state: AppState) => ({
		userEmail: getPreRegistrationUserEmail(state),
		fetchStatus: getPreRegistrationVerifyFetchStatus(state)
	}),
	{
		verify: preRegisterVerify,
		reset: resetPreRegistration
	}
)(PreRegistrationVerifyRoute);
