import { FundingsState } from '../financeFundingsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	UpdateFundingRequest as Request,
	UpdateFundingResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';

export const updateFunding = makeFetchStatusReducers<
	FundingsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'updateFunding');
