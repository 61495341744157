import * as React from 'react';

import { Button } from 'components/antd';
import { AlertProps } from 'components/antd/Alert/Alert';
import { ModalStatus } from './UploadProgressModalTypes';

interface ConfigItem {
	alertType: AlertProps['type'];
	alertMessage?: string;
	showProgress: boolean;
	footer: (onOk?: () => void, onCancel?: () => void) => React.ReactNode;
}

const config: { [status: string]: ConfigItem } = {
	[ModalStatus.PROGRESS]: {
		alertType: 'info',
		alertMessage:
			'Please do not close this window, uploading a document will take some time',
		showProgress: true,
		footer: () => null
	},
	[ModalStatus.CANCEL]: {
		alertType: 'warning',
		alertMessage:
			'Closing the window will cancel the file upload and uploading progress will be lost. Are you sure you want to continue?',
		showProgress: false,
		footer: (onOk, onCancel) => (
			<>
				<Button key="continue" type="primary" transparent onClick={onOk}>
					Continue Uploading
				</Button>
				<Button key="close" type="primary" transparent onClick={onCancel}>
					Close window
				</Button>
			</>
		)
	}
};

export default config;
