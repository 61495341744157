import actionCreator from 'store/portJobs/portJobsActionCreator';

import {
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const RETRIEVE_CUSTODY_ROLES = 'RETRIEVE_CUSTODY_ROLES';
export const retrievePortJobCustodyRolesAsync = actionCreator.async<
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse,
	Error
>(RETRIEVE_CUSTODY_ROLES);

export const retrievePortJobCustodyRoles = actionCreator<
	PortJobCustodyRolesRequest
>(RETRIEVE_CUSTODY_ROLES);

/**
 * Reset CustodyRoles (triggered everytime before requesting them)
 */
export const resetPortJobCustodyRoles = actionCreator('RESET_CUSTODY_ROLES');

const retrievePortJobCustodyRolesAction = {
	action: retrievePortJobCustodyRoles,
	async: retrievePortJobCustodyRolesAsync,
	reset: resetPortJobCustodyRoles
};

export default retrievePortJobCustodyRolesAction;
