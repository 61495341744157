import * as React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { AppNotifications } from 'containers';
import { PageHeader, PageFooter, Flex, ScrollableLayout } from 'components';
import { Button } from 'components/antd';
import { Justify } from 'components/types';
import AddBankAccountFrom from './AddBankAccountForm';
import BankAccountsActAs from '../BankAccountsActAs/BankAccountsActAs';

import { getForm } from 'store/form/selectors';
import { BANK_ACCOUNT_FORM, breadCrumbs } from '../BankAccountConstants';
import { navigateTo } from 'utils';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { getIsBankAccountsAdding } from 'store/masterdata/bankAccounts/selectors';
import { AppState } from 'store-types';
import styles from './AddBankAccount.module.scss';

interface AddBankAccountRouteProps {
	// from mapStateToProps
	isAddButtonDisabled: boolean;
	loading: boolean;
	// from mapDispatchToProps
	submit: typeof submit;
}

const AddBankAccountRoute = (props: AddBankAccountRouteProps) => {
	const goToList = () => navigateTo('/bank-account-details');

	return (
		<BankAccountsActAs>
			<ScrollableLayout stretch>
				<AppNotifications.Notification />
				<RouteBreadcrumbs items={breadCrumbs.add} />
				<PageHeader.NewTab onClose={goToList}>
					Add Bank Account Details
				</PageHeader.NewTab>
				<ScrollableLayout>
					<AddBankAccountFrom />
				</ScrollableLayout>
				<PageFooter borderTop>
					<Flex justify={Justify.END}>
						<Button
							className={styles.marginRight}
							transparent
							type="primary"
							onClick={goToList}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => props.submit(BANK_ACCOUNT_FORM)}
							disabled={props.isAddButtonDisabled}
							loading={props.loading}
						>
							Save Bank Details
						</Button>
					</Flex>
				</PageFooter>
			</ScrollableLayout>
		</BankAccountsActAs>
	);
};

export default connect(
	(state: AppState) => ({
		isAddButtonDisabled: !getForm(state, BANK_ACCOUNT_FORM).valid,
		loading: getIsBankAccountsAdding(state)
	}),
	{ submit }
)(AddBankAccountRoute);
