import { RetrieveThreadsAvailableGroupsResponse } from 'services/api/threads/threadsServiceTypes';

import actionCreator from '../threadsActionCreator';

const ACTION_NAME = 'RETRIEVE_THREADS_AVAILABLE_GROUPS';

export const retrieveThreadsAvailableGroupsAsync = actionCreator.async<
	void,
	RetrieveThreadsAvailableGroupsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadsAvailableGroups = actionCreator(ACTION_NAME);
export const resetThreadsAvailableGroups = actionCreator(
	'RESET_THREADS_AVAILABLE_GROUPS'
);
