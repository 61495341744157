import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { formInitialValues } from 'sections/Administration/DeveloperPortal/DeveloperPortalTab/DeveloperPortalFunc';
import {
	ApiSubscriptionParams,
	DeveloperPortalData,
	UpdateApiSubscriptionParams,
	WebhookEndPointDetails,
	WebhookEventsList,
	WebhookEndPointDetailsWithLabel
} from 'services/api/developerPortal/developerPortalServiceTypes';
import {
	DEVELOPER_PORTAL_FORM,
	WebHookConfigOption,
	eventsList,
	EventsMap
} from 'sections/Administration/DeveloperPortal/DeveloperPortalTab/constants';
import { getFormValues } from 'redux-form';
import { getAuthUserId, getUserCompanyId } from 'store/auth/selectors';
import { v4 as uuidv4 } from 'uuid';
import { flatten, isEqual } from 'lodash';

const getDeveloperPortal = (state: AppState) => state.developerPortal;

export const getDeveloperPortalValue = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.context.initialDeveloperPortal
);

export const getEmdApiId = createSelector(
	getDeveloperPortalValue,
	values =>
		values?.apiPermission.find(group => group.groupName === 'EMD')?.children[0]
			.id
);

export const getInitialValueFetchStatus = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.fetchStatuses.retrieveDeveloperPortal
);

export const getNewApiSubscriptionFetchStatus = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.fetchStatuses.createNewApiSubscription
);

export const getUpdateApiSubscriptionFetchStatus = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.fetchStatuses.updateApiSubscription
);

export const getFormInitialValue = (data: DeveloperPortalData | null) => {
	const forms = {};

	if (!data) {
		return forms;
	}

	forms[DEVELOPER_PORTAL_FORM] = {
		...forms[DEVELOPER_PORTAL_FORM],
		id: data.id,
		hubPrincipalCompanyId: data.hubPrincipalCompanyId,
		apiPermission: data.apiPermission,
		apiCheckedKeys: data?.apiMappingResponse
			? formInitialValues(data.apiMappingResponse)
			: {},
		subscriptionStatus: data.subscriptionStatus,
		token: data?.userToken,
		isWebhookEndPoint: data.isWebhookEndPoint,
		webhookEndPointResponse: data.webhookEndPointResponse
			? [...data.webhookEndPointResponse]
			: []
	};

	return forms;
};

export const getCreateFormValues = createSelector(
	(state: AppState) => getAuthUserId(state),
	(state: AppState) => getUserCompanyId(state),
	(state: AppState) => getFormValues(DEVELOPER_PORTAL_FORM)(state),
	(state: AppState) => getDeveloperPortalValue(state),
	(
		userId,
		hubPrincipalCompanyId,
		formValues,
		initialData: DeveloperPortalData
	): ApiSubscriptionParams => {
		const formData = Object(formValues);
		const checkedKeys = formData?.apiCheckedKeys;
		const apiPermissions = checkedKeys
			? Object.keys(checkedKeys).filter(key => checkedKeys[key])
			: [];
		const subscriptionStatus = initialData.subscriptionStatus;
		const isWebhookEndPoint =
			formData.isWebhookEndPoint === WebHookConfigOption.YES;
		const webhookEndPointResponse = formData.webhookEndPointResponse;
		const isWebhookConfigurationUpdated = !isEqual(
			formData.webhookEndPointResponse,
			initialData.webhookEndPointResponse
		);

		return {
			userId,
			hubPrincipalCompanyId,
			subscriptionStatus,
			apiPermissions,
			isWebhookEndPoint,
			webhookEndPointResponse,
			isWebhookConfigurationUpdated
		};
	}
);

export const getUpdatedFormValues = createSelector(
	(state: AppState) => getAuthUserId(state),
	(state: AppState) => getUserCompanyId(state),
	(state: AppState) => getFormValues(DEVELOPER_PORTAL_FORM)(state),
	(state: AppState) => getDeveloperPortalValue(state),
	(
		userId,
		hubPrincipalCompanyId,
		formValues,
		initialData: DeveloperPortalData
	): UpdateApiSubscriptionParams => {
		const formData = Object(formValues);
		const id = initialData.id;
		const subscriptionStatus = initialData.subscriptionStatus;
		const checkedKeys = formData?.apiCheckedKeys;
		const apiPermissions = checkedKeys
			? Object.keys(checkedKeys).filter(key => checkedKeys[key])
			: [];
		const isRequestNewToken = formData?.isRequestNewToken;
		const isWebhookEndPoint =
			formData.isWebhookEndPoint === WebHookConfigOption.YES;
		const webhookEndPointResponse = formData.webhookEndPointResponse;
		const isWebhookConfigurationUpdated = !isEqual(
			formData.webhookEndPointResponse,
			initialData.webhookEndPointResponse
		);
		return {
			id,
			userId,
			hubPrincipalCompanyId,
			subscriptionStatus,
			apiPermissions,
			isRequestNewToken,
			isWebhookEndPoint,
			webhookEndPointResponse,
			isWebhookConfigurationUpdated
		};
	}
);

export const getEditingWebHookId = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.context.editingWebHookId
);

export const getWebHookModalValues = createSelector(
	(state: AppState) => getEditingWebHookId(state),
	(state: AppState) =>
		getFormValues(DEVELOPER_PORTAL_FORM)(state) as DeveloperPortalData,
	(id, formValues) => {
		if (id) {
			const webhook = formValues.webhookEndPointResponse.find(
				(webhookEndPoint: WebhookEndPointDetails) => webhookEndPoint.id === id
			);
			return {
				isEditing: true,
				initialValues: {
					...webhook,
					eventsWithLabels: webhook?.events.map(event => ({
						key: event,
						label: EventsMap[event]
					}))
				} as WebhookEndPointDetailsWithLabel
			};
		} else {
			return {
				isEditing: false,
				initialValues: {
					id: uuidv4(),
					dataFormat: 'application/JSON'
				} as WebhookEndPointDetailsWithLabel
			};
		}
	}
);

export const getSelectedOutBoundList = createSelector(
	getDeveloperPortal,
	developerPortal => developerPortal.context.selectedOutBoundApi
);

export const getEventsList = createSelector(
	(state: AppState) => getSelectedOutBoundList(state),
	(state: AppState) =>
		getFormValues(DEVELOPER_PORTAL_FORM)(state) as DeveloperPortalData,
	(list, formValues) => {
		const updatedEventsList: WebhookEventsList[] = [
			{
				key: 'status-change',
				label: 'Get status change',
				disabled: false
			},
			{
				key: 'clone-job',
				label: 'Get clone job',
				disabled: false
			}
		];
		list.forEach(apiName => {
			if (eventsList[apiName]) {
				updatedEventsList.push(eventsList[apiName]);
			}
		});
		const alreadySelectedEvents = flatten(
			formValues.webhookEndPointResponse.map(webhook => webhook.events)
		);
		return updatedEventsList.map(updatedEvent => ({
			...updatedEvent,
			disabled: alreadySelectedEvents.find(
				eventName => eventName === updatedEvent.key
			)
				? true
				: false
		}));
	}
);
