import { FetchStatus } from 'services/api/apiTypes';
import {
	AppointAgentToPortParams,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { PortJobState } from 'store/portJobs/portJobsState';
import { Success, Failure } from 'typescript-fsa';
export const onAppointToPortJobStarted = (state: PortJobState) => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
};
export const onAppointToPortJobSuccess = (
	state: PortJobState,
	action: Success<AppointAgentToPortParams, PortJobStatusUpdateResponse>
) => {
	const updatedById = Object.keys(state.byId).reduce((all, id) => {
		const job = {
			...state.byId[id]
		};
		if (job.code === action.params.jobCode) {
			job.status = PortJobStatus.APPOINTED;
		}
		all[id] = job;
		return all;
	}, {});
	return {
		...state,
		byId: state.byId ? updatedById : {},
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
};
export const onAppointToPortJobFailed = (
	state: PortJobState,
	{ error }: Failure<AppointAgentToPortParams, Error>
) => {
	return {
		...state,
		error,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
};
