import {
	RetrieveAvailableServicesRequest as request,
	RetrieveAvailableServicesResponse as response,
	ServiceSectionCode,
	ServiceType,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_AVAILABLE_SERVICES = 'RETRIEVE_AVAILABLE_SERVICES';
export const retrieveAvailableServicesAsync = actionCreator.async<
	request,
	response,
	Error
>(RETRIEVE_AVAILABLE_SERVICES);

export const retrieveAvailableServices = actionCreator<{
	sectionCode: ServiceSectionCode;
	entityId?: string;
	action: FinanceMetadataActionCode;
	portJobServiceType?: ServiceType;
}>(RETRIEVE_AVAILABLE_SERVICES);
