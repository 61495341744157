import Api from 'services/api';
import { retrieveAvailableSectionCodeAsync as asyncAction } from '../actions/retrieveAvailableSectionCode';
import {
	FinanceRequest,
	RetrieveAvailableSectionCodeResponse
} from 'services/api/finance/financeServiceTypes';

import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.retrieveAvailableSections;

export default makeTakeLatestWatcher<
	FinanceRequest,
	RetrieveAvailableSectionCodeResponse,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: FinanceRequest = {
			portCallId: portCallId,
			jobCode: jobCode
		};
		return request;
	}
});
