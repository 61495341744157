import actionCreator from '../threadActionCreator';

const ACTION_NAME = 'UPDATE_STATUS';

export interface UpdateThreadStatusParams {
	threadId: string;
	status: string;
}

export const updateThreadStatus = actionCreator<UpdateThreadStatusParams>(
	ACTION_NAME
);

export const updateThreadStatusAsync = actionCreator.async<
	UpdateThreadStatusParams,
	string,
	Error
>(ACTION_NAME);
