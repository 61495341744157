import { DeveloperPortalState as State } from '../developerPortalState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	DownloadApiSubscriptionRequest as Request,
	DownloadApiSubscriptionResponse as Response
} from 'services/api/developerPortal/developerPortalServiceTypes';

export const onDownloadApiSubscription = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'downloadApiSubscription');
