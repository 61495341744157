import * as React from 'react';
import classNames from 'classnames';
import styles from './Table.module.scss';

type TableFilterIconProps = React.HTMLAttributes<{}> & {
	filterSelected: boolean;
};
const TableFilterIcon: React.FC<TableFilterIconProps> = ({
	filterSelected,
	className,
	children,
	...props
}) => (
	<span
		className={classNames(className, {
			[styles.filtered]: filterSelected
		})}
		{...props}
	>
		{children}
	</span>
);

export default TableFilterIcon;
