import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { DownloadAllDAsRequest } from 'services/api/finance/financeServiceTypes';
import { FinanceState } from 'store/finance/financeState';

export const onDownloadAllDAs = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DownloadAllDAsRequest,
	RetrieveDownloadDocumentResponse
>('fetchStatuses', 'downloadAllDAs');
