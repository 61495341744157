import { LinkJobState } from '../linkJobState';
import { DeleteLinkedAppointmentCargoRequest } from 'services/api/portCalls/portCallsServiceTypes';

export const onUpdateLinkedAppointmentCargos = (
	state: LinkJobState,
	action: DeleteLinkedAppointmentCargoRequest
): LinkJobState => {
	const deletedAppointmentCargo = state.linkedAppointmentCargoes.find(
		cargo => cargo?.cargoId === action.id && !cargo?.createdFromNomination
	);
	const updatedAppointmentCargos = state.linkedAppointmentCargoes.map(cargo =>
		cargo?.cargoId === action.id ? {} : cargo
	);
	return {
		...state,
		linkedAppointmentCargoes: [...updatedAppointmentCargos],
		unLinkedAppointmentCargoes: [
			...state.unLinkedAppointmentCargoes,
			{ ...deletedAppointmentCargo }
		]
	};
};
