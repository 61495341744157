import actionCreator from '../portCallMeasurementsActionCreator';
import {
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'RETRIEVE_CARGO_MEASUREMENTS';
export const retrievePortCallCargoMeasurementsAsync = actionCreator.async<
	RetrievePortCallCargoMeasurementsRequest,
	CargoMeasurement[],
	Error
>(ACTION_NAME);

export const retrievePortCallCargoMeasurements = actionCreator<
	RetrievePortCallCargoMeasurementsRequest
>(ACTION_NAME);
