import {
	IThread,
	RetrieveThreadsStatsResponse as ThreadsStats,
	ThreadsAvailableGroup,
	ThreadsContext,
	NewComposeMessageMetaData,
	EditComposeMessageMetaData,
	ContextType,
	ThreadsPendingAlerts,
	TagType,
	ThreadItem
} from 'services/api/threads/threadsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Entity } from 'app-types';

export interface ThreadsState {
	readonly byId: { [index: string]: IThread };
	readonly totalUnreadCount: number;
	readonly availableGroups: {
		[groupId: string]: ThreadsAvailableGroup;
	};
	readonly item: ThreadItem | null;
	readonly newPermissions: Entity[];
	readonly newThreadData: NewComposeMessageMetaData | {};
	readonly editThreadData: EditComposeMessageMetaData | {};
	readonly jobCodesForNewThread: string[];
	readonly threadContexts: ContextType[];
	readonly stats: ThreadsStats | {};
	readonly isPreviewModeEnabled: boolean;
	readonly isFullScreenModeEnabled: boolean;
	readonly isThreadPageReload: boolean;
	readonly pendingAlerts: ThreadsPendingAlerts;
	readonly context: ThreadsContext;
	readonly threadTags: TagType[];
	readonly mainPrincipalThreadsTags: TagType[];
	readonly mainPrincipalThreadsGroups: ThreadsAvailableGroup[];
	readonly fetchStatuses: {
		all: FetchStatus;
		creatingThread: FetchStatus;
		adding: FetchStatus;
		updating: FetchStatus;
		assignToUpdating: FetchStatus;
		categorizeMsgUpdating: FetchStatus;
		retrieving: FetchStatus;
		retrievingThreadContexts: FetchStatus;
		retrievingStats: FetchStatus;
		retrievingPendingAlerts: FetchStatus;
		retrieveAvailableGroups: FetchStatus;
		retrievingThreadTags: FetchStatus;
		retrievingThreadsTags: FetchStatus;
		retrievingThreadsGroups: FetchStatus;
	};
	readonly hasMore: boolean;
	readonly error: boolean | Error;
}

export const initialState: ThreadsState = {
	byId: {},
	totalUnreadCount: 0,
	availableGroups: {},
	jobCodesForNewThread: [],
	newThreadData: {},
	editThreadData: {},
	threadContexts: [],
	threadTags: [],
	mainPrincipalThreadsTags: [],
	mainPrincipalThreadsGroups: [],
	item: null,
	newPermissions: [],
	stats: {},
	isPreviewModeEnabled: false,
	isFullScreenModeEnabled: false,
	isThreadPageReload: false,
	pendingAlerts: {
		hasOverdueThreads: false
	},
	context: {
		activeThreadId: '',
		threadsLimit: undefined,
		scrollPosition: 0
	},
	fetchStatuses: {
		all: FetchStatus.IDLE,
		creatingThread: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		assignToUpdating: FetchStatus.IDLE,
		categorizeMsgUpdating: FetchStatus.IDLE,
		retrieving: FetchStatus.IDLE,
		retrievingThreadContexts: FetchStatus.IDLE,
		retrievingStats: FetchStatus.IDLE,
		retrievingPendingAlerts: FetchStatus.IDLE,
		retrieveAvailableGroups: FetchStatus.IDLE,
		retrievingThreadTags: FetchStatus.IDLE,
		retrievingThreadsTags: FetchStatus.IDLE,
		retrievingThreadsGroups: FetchStatus.IDLE
	},
	error: false,
	hasMore: true
};
