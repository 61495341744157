import { Success, Failure } from 'typescript-fsa';
import { PortsState } from '../portsState';
import {
	RetrieveBuoysRequest,
	RetrieveBuoysResponse
} from 'services/api/ports/portsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveBuoysStarted = (state: PortsState): PortsState => ({
	...state,
	error: undefined,
	isFetching: FetchStatus.PENDING
});
export const onRetrieveBuoysSuccess = (
	state: PortsState,
	action: Success<RetrieveBuoysRequest, RetrieveBuoysResponse>
): PortsState => ({
	...state,
	isFetching: FetchStatus.SUCCESS,
	buoysByPort: {
		...state.buoysByPort,
		[action.params.port]: [
			...(state.buoysByPort[action.params.port] || []),
			...action.result.elements
		]
	}
});
export const onRetrieveBuoysFailed = (
	state: PortsState,
	action: Failure<RetrieveBuoysRequest, Error>
): PortsState => ({
	...state,
	isFetching: FetchStatus.FAILURE,
	error: action.error
});
