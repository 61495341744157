import { DraftsState } from '../draftsState';
import { SetActiveTerminalIdRequest } from 'containers/Drafts/AddPOIDraft/addPOIDraftTypes';

export const onSetActiveTerminalId = (
	state: DraftsState,
	{ rotationStepId, terminalId }: SetActiveTerminalIdRequest
): DraftsState => ({
	...state,
	context: {
		...state.context,
		activeTerminalIdByRotationStepId: {
			...state.context.activeTerminalIdByRotationStepId,
			[rotationStepId]: terminalId
		}
	}
});
