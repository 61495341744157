import React, { useEffect, useCallback } from 'react';
import { lowerCase } from 'lodash';
import { Select } from 'components/antd';
import { AppointerType } from 'services/api/portJobs/portJobsServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AppState } from 'store-types';
import {
	getPortJobAppointers,
	getIsLoadingPortJobAppointers
} from 'store/portJobs/selectors';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { retrieveAppointers } from 'store/portJobs';
import { AutocompleteEntityProps } from 'containers/Autocomplete/AutocompleteEntity';
import { resetCompaniesContactDetails } from 'store/companies/actions/retrieveCompaniesContactDetails';
import { getUserCompanyId } from 'store/auth/selectors';

interface FieldAppointerProps extends AutocompleteEntityProps {
	hubPrincipalCompany: FormData['hubPrincipalCompany'];
}

const FieldAppointer: React.FC<FieldAppointerProps> = ({
	hubPrincipalCompany,
	...selectProps
}) => {
	const dispatch = useDispatch();
	const appointers = useSelector<AppState, AppointerType[]>(
		getPortJobAppointers,
		shallowEqual
	);
	const userCompanyId = useSelector(getUserCompanyId);
	const isLoading = useSelector(getIsLoadingPortJobAppointers);
	const hubPrincipalCompanyKey = hubPrincipalCompany?.key;

	useEffect(() => {
		dispatch(
			retrieveAppointers({
				mainPrincipalId: hubPrincipalCompanyKey || userCompanyId,
				actionType: PermissionCode.MANAGE_PORTCALL
			})
		);
	}, [dispatch, hubPrincipalCompanyKey, userCompanyId]);

	const onChange = useCallback(() => {
		dispatch(resetCompaniesContactDetails());
	}, [dispatch]);

	return (
		<Select.ReduxFormItem
			placeholder={'Select ' + lowerCase(selectProps.label)}
			labelInValue
			enableSameOptionChangeDetection
			onChange={onChange}
			isLoading={isLoading}
			{...selectProps}
		>
			{appointers.map(option => (
				<Select.Option key={option.id}>{option.companyName}</Select.Option>
			))}
		</Select.ReduxFormItem>
	);
};

export default FieldAppointer;
