import React, { useCallback } from 'react';

import { Upload } from 'components/antd';
import { UploadButtonProps as UploadButtonBaseProps } from 'components/antd/Upload/Upload';
import UploadProcessDocumentProvider, {
	UploadProcessDocumentProviderProps,
	UploadProcessDocumentProviderData
} from 'containers/UploadProcessDocumentProvider/UploadProcessDocumentProvider';

import { ButtonType } from 'components/types';
import { ButtonProps } from 'components/antd/Button/Button';

import { RcFile } from 'antd/lib/upload';

type DocProviderProps = Partial<
	Pick<
		UploadProcessDocumentProviderProps,
		'uploadType' | 'onDone' | 'showProgress' | 'metadata'
	>
>;
type DocButtonProps = Pick<
	UploadButtonBaseProps,
	| 'link'
	| 'className'
	| 'disabled'
	| 'tooltip'
	| 'customRequest'
	| 'beforeUpload'
	| 'uploadType'
> & {
	buttonType?: ButtonType;
	buttonGhost?: ButtonProps['ghost'];
	beforeUploadRequest?: (
		file: RcFile,
		fileList: RcFile[],
		customRequest: UploadProcessDocumentProviderData['customRequest']
	) => boolean;
};
type UploadDocumentProps = DocProviderProps & DocButtonProps;

class UploadDocument extends React.Component<UploadDocumentProps> {
	render() {
		const {
			onDone,
			metadata,
			uploadType,
			showProgress = true,
			...buttonProps
		} = this.props;
		return (
			<UploadProcessDocumentProvider
				onDone={onDone}
				metadata={metadata}
				uploadType={uploadType}
				showProgress={showProgress}
			>
				{data => (
					<UploadDocumentButton
						{...buttonProps}
						data={data}
						uploadType={uploadType}
					/>
				)}
			</UploadProcessDocumentProvider>
		);
	}
}

type UploadDocumentButtonProps = {
	data: UploadProcessDocumentProviderData;
} & DocButtonProps;
const UploadDocumentButton: React.FunctionComponent<UploadDocumentButtonProps> = ({
	data,
	buttonType = 'primary',
	children,
	beforeUpload: beforeUploadProp,
	beforeUploadRequest,
	...props
}) => {
	const customRequest = props.customRequest || data.customRequest;
	const beforeUpload = useCallback(
		(file: RcFile, fileList: RcFile[]) => {
			if (beforeUploadProp) {
				return beforeUploadProp(file, fileList);
			}
			if (beforeUploadRequest) {
				return beforeUploadRequest(file, fileList, customRequest);
			}
			return true;
		},
		[beforeUploadProp, beforeUploadRequest, customRequest]
	);
	return (
		<Upload.Button
			{...props}
			buttonType={buttonType}
			showUploadList={false}
			customRequest={customRequest}
			accept={data.accept}
			beforeUpload={beforeUpload}
		>
			{children}
		</Upload.Button>
	);
};

export default UploadDocument;
