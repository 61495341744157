import * as React from 'react';

import { PageHeader, Title } from 'components';
import { PermissionButton } from 'components/Permission';
import { Col, Row } from 'components/antd';
import GroupLastUpdated from './GroupLastUpdated';
import { navigateTo } from 'utils';

import { Justify } from 'components/types';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

interface GroupHeaderProps {
	group: Group;
}

export default class GroupHeader extends React.Component<GroupHeaderProps> {
	onEditGroup = () => navigateTo(`/groups/${this.props.group.id}/edit`);
	render() {
		const { group } = this.props;
		return (
			<div>
				<PageHeader shadowed stretch>
					<Row>
						<Col xs={7}>
							<h1>{group.name}</h1>
							{group.company && <span>Company: {group.company.name}</span>}
						</Col>
						<Col xs={5} justify={Justify.END}>
							<GroupLastUpdated vertical updatedOn={group.updatedOn} />
							<PermissionButton
								tooltip={{ placement: 'left' }}
								permissionCode={PermissionCode.MANAGE_GROUP}
								type="primary"
								ghost
								onClick={this.onEditGroup}
							>
								Edit Group
							</PermissionButton>
						</Col>
					</Row>
				</PageHeader>
				<Row>
					<Col xs={12}>
						<Title.Grid>Group Members</Title.Grid>
					</Col>
				</Row>
			</div>
		);
	}
}
