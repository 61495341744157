import { first } from 'lodash';
import { FormErrors } from 'redux-form';
import runValidations, {
	isDateValid,
	required,
	validateDateIsAfter,
	validateRequired,
	validateRequiredPortsWithPermission
} from 'utils/validations';
import { ClonePortJobFormData } from './constants';

export const validate = (
	values: ClonePortJobFormData
): FormErrors<ClonePortJobFormData> => ({
	vessel: validateRequired(values.vessel),
	port: validateRequiredPortsWithPermission(values.port),
	eta: first(runValidations(values.eta, [required, isDateValid])) || '',
	ets:
		first(
			runValidations(values.ets, [
				isDateValid,
				validateDateIsAfter(values.eta, 'ETA must be earlier than ETS')
			])
		) || '',
	performingAgent: first(runValidations(values.performingAgent, [required]))
});
