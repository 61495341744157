import { PagedRequest, SearchRequest } from '../apiTypes';
import { Entity } from 'app-types';

export interface RetrieveOperationBaseRequest {
	portId?: string;
	mainPrincipalId?: string;
}

export type RetrieveOperationsRequest = RetrieveOperationBaseRequest &
	PagedRequest;

export type SearchOperationsRequest = RetrieveOperationBaseRequest &
	SearchRequest;

export enum OperationCode {
	LOADING = 'LOA',
	LOADING_STS = 'LST',
	DISCHARGING = 'DIS',
	DISCHARGING_STS = 'DST',
	CREW_CHANGE = 'CRE',
	BUNKERING = 'BUN',
	CASH_TO_MASTER = 'CTM',
	CANAL_TRANSIT = 'CTR',
	GENERIC = 'GEN',
	DOCUMENT_DELIVERY = 'DDL',
	SPARE_PART_DELIVERY = 'SPD',
	FRESH_WATER_SUPPLY = 'FWS',
	SUPPLY_OF_PROVISION = 'SOP',
	IT_TECHNICAL_COORDINATION = 'ITC',
	CREW_WAGE_DISBURSEMENT = 'CWD',
	SPARES_AND_DOCUMENT_DELIVERY = 'SAD'
}

export enum OperationTypeCode {
	LOADING = 'Loading',
	LOADING_STS = 'LoadingSTS',
	DISCHARGING = 'Discharging',
	DISCHARGING_STS = 'DischargingSTS',
	GENERIC = 'Generic',
	CREW_CHANGE = 'CrewChange',
	CASH_TO_MASTER = 'CashToMaster',
	CANAL_TRANSIT = 'CanalTransit',
	BUNKERING = 'Bunkering',
	CREW_WAGE_DISBURSEMENT = 'CrewWageDisbursement',
	SPARES_AND_DOCUMENT_DELIVERY = 'SparesAndDocumentDelivery'
}

export interface Operation {
	code: OperationCode;
	id: string;
	name: string;
	operationType: string;
	operationTypeCode: OperationTypeCode;
}

export type BunkerGrade = Entity;
