import * as React from 'react';
import ReactDOM from 'react-dom';
import { isString } from 'lodash';

interface PortalProps {
	parentComponent: React.Component;
	/**
	 * ClassName or ID
	 */
	containerSelector: string;
	children: JSX.Element;
}

/**
 * Helps to preserve context
 */
export default class Portal extends React.Component<PortalProps> {
	/**
	 * Parent Component DOM Element
	 */
	parentElement: ReturnType<typeof ReactDOM.findDOMNode>;
	/**
	 * DOM Element inside which children have to be rendered.
	 * Queried by selector passed in containerSelector
	 */
	container: Element | null;
	node: HTMLElement | null;

	componentDidMount() {
		this.parentElement = ReactDOM.findDOMNode(this.props.parentComponent);
		this.container = this.getContainer();
		this.renderPortal();
	}

	componentDidUpdate() {
		this.renderPortal();
	}

	componentWillUnmount() {
		if (this.node && this.container) {
			ReactDOM.unmountComponentAtNode(this.node);
			this.container.removeChild(this.node);
			this.node = null;
			this.container = null;
		}
	}

	renderPortal = () => {
		if (!this.container) {
			return;
		}

		if (!this.node) {
			this.node = document.createElement('div');
			this.container.appendChild(this.node);
		}

		/**
		 *
		 * Allows to propagate the context into that subtree,
		 * which is not possible with ReactDOM.render that renders
		 * a completely isolated tree.
		 * This method should become stable in a future version of React
		 */
		ReactDOM.unstable_renderSubtreeIntoContainer(
			this.props.parentComponent,
			this.props.children,
			this.node
		);
	};

	getContainer = (): HTMLElement | Element | null => {
		if (isString(this.props.containerSelector)) {
			return (this.parentElement as Element).querySelector(
				this.props.containerSelector
			);
		}

		return null;
	};

	render() {
		return null;
	}
}
