import * as React from 'react';
import classNames from 'classnames';
import styles from './Box.module.scss';
import Content, { ContentProps } from 'components/Styles/Content/Content';

interface BoxProps {
	className?: string;
	stretch?: boolean;
	type?: 'primary' | 'standard' | 'bordered';
}

const BoxHeader: React.FC = ({ children }) => {
	return <header>{children}</header>;
};

const BoxContent: React.FC<ContentProps> = ({ children, ...props }) => {
	return (
		<Content {...props} className={classNames(styles.content, props.className)}>
			{children}
		</Content>
	);
};

const BoxFooter: React.FC = ({ children }) => {
	return <footer>{children}</footer>;
};

export class Box extends React.PureComponent<BoxProps> {
	static Header: typeof BoxHeader;
	static Content: typeof BoxContent;
	static Footer: typeof BoxFooter;

	render() {
		const { type, className, stretch, children } = this.props;

		return (
			<section
				className={classNames(styles.root, className, {
					[styles.stretch]: stretch,
					[styles.primary]: type === 'primary',
					[styles.standard]: type === 'standard',
					[styles.bordered]: type === 'bordered'
				})}
			>
				{children}
			</section>
		);
	}
}

Box.Header = BoxHeader;
Box.Content = BoxContent;
Box.Footer = BoxFooter;

export default Box;
