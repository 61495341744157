import actionCreator from '../portJobsActionCreator';
import {
	AcceptanceData,
	PortJobBaseRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_ACCEPTANCE_DATA';
export const retrieveAcceptanceDataAsync = actionCreator.async<
	PortJobBaseRequest,
	AcceptanceData,
	Error
>(ACTION_NAME);
export const retrieveAcceptanceData = actionCreator(ACTION_NAME);
