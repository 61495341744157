import { DashboardState as State } from '../dashboardState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrieveDashboardTableauInfoRequest as Request,
	DashboardTableauInfo as Response
} from 'services/api/dashboard/dashboardServiceTypes';
import { Success } from 'typescript-fsa';

export const onRetrieveDashboardTableauInfo = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>(
	'fetchStatuses',
	({ tableauCode }: Request): keyof State['fetchStatuses'] => {
		return `retrieveTableauInfo.${tableauCode}` as keyof State['fetchStatuses'];
	},
	{
		doneReducer: (
			state: State,
			{ params: { tableauCode }, result }: Success<Request, Response>
		): State => ({
			...state,
			tableauInfoByCode: {
				...state.tableauInfoByCode,
				[tableauCode]: result
			}
		})
	}
);
