import React from 'react';

import { Icon, Tooltip } from 'components/antd';
import styles from '../../ThreadsList/ThreadRowSplitView/ThreadRowSplitView.module.scss';
import classNames from 'classnames';

interface MessageSendFailureIndicatorProps {
	message?: string;
	style?: boolean;
}
const MessageSendFailureIndicator: React.FC<MessageSendFailureIndicatorProps> = ({
	message,
	style
}) => {
	if (!message) {
		return null;
	}
	return (
		<Tooltip title={message} placement="right">
			<Icon
				className={classNames({ [styles.alertSvg]: style })}
				type="warning"
				color="warning"
				offset="right"
			/>
		</Tooltip>
	);
};

export default MessageSendFailureIndicator;
