import actionCreator from '../financeActionCreator';
import { DownloadAllDAsRequest } from 'services/api/finance/financeServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_ALL_DAS';

export const downloadAllDAsAsync = actionCreator.async<
	DownloadAllDAsRequest,
	RetrieveDownloadDocumentResponse,
	Error
>(ACTION_NAME);

export const downloadAllDAs = actionCreator<DownloadAllDAsRequest>(ACTION_NAME);
