import {
	RetrieveNewFundingDataRequest,
	RetrieveNewFundingDataResponse
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'RETRIEVE_NEW_FUNDING_DATA';

export const retrieveNewFundingDataAsync = actionCreator.async<
	RetrieveNewFundingDataRequest,
	RetrieveNewFundingDataResponse,
	Error
>(ACTION_NAME);

export const retrieveNewFundingData = actionCreator<
	RetrieveNewFundingDataRequest
>(ACTION_NAME);
