import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'components/antd';
import styles from './CategorizeMsgModalForm.module.scss';
import { searchPortCallJobsByCode } from 'services/api/portCalls/portCallsService';
import { change } from 'redux-form';
import {
	CATEGORIZE_MSG_MODAL_FORM,
	ThreadsCatergorizeMsgFormFields
} from './CategorizeMsgModalFormTypes';
import { DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT } from 'app-constants';
import { isEmpty } from 'lodash';
import { DEFAULT_MIN_LENGTH } from '../constants';

interface QuickAssignToJobProps {
	isAssignJobButtonDisabled: boolean;
	mainPrincipalId: string | null;
}

const QuickAssignToJobs: FC<QuickAssignToJobProps> = props => {
	const dispatch = useDispatch();

	const [jobCodes, setJobCodes] = useState<string[]>([]);
	const [isSearchTerm, setSearchTerm] = useState<boolean>(false);

	const onChange = (data: string) => {
		dispatch(
			change(
				CATEGORIZE_MSG_MODAL_FORM,
				`${ThreadsCatergorizeMsgFormFields.THREAD_JOBCODES}`,
				data
			)
		);
	};

	const onSearch = (searchTerm: string) => {
		if (props.mainPrincipalId && searchTerm.length >= DEFAULT_MIN_LENGTH) {
			searchPortCallJobsByCode(searchTerm, props.mainPrincipalId).then(data =>
				setJobCodes(data.map(res => res.code))
			);
			setSearchTerm(true);
		} else {
			setSearchTerm(false);
		}
	};

	return (
		<>
			<section className={styles.tags}>
				<span>Search by Job ID</span>
				<Select
					className={styles.selectSpan}
					mode="multiple"
					placeholder="Search by Job ID"
					onChange={onChange}
					onSearch={onSearch}
					onFocus={() => setSearchTerm(false)}
					notFoundContent={
						isSearchTerm &&
						isEmpty(jobCodes) &&
						DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT
					}
					getPopupContainer={() => document.body}
				>
					{isSearchTerm &&
						jobCodes.map(code => (
							<Select.Option key={code} value={code}>
								{code}
							</Select.Option>
						))}
				</Select>
			</section>
		</>
	);
};

export default QuickAssignToJobs;
