import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading } from 'store/selectors';
import {
	getActivePortJob,
	getActivePortJobStatus
} from 'store/portJobs/selectors';
import { entityToLabeledValue } from 'components/antd/Form/Form.func';
import { PortJobStatus } from '../constants';
import { getValues } from 'store/form/selectors';
import {
	ENABLE_OPERATIONS_FORM,
	FormData,
	UpdateSectionList
} from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';

export const getPortCallPrincipalEmail = (state: AppState) =>
	state.portjob.context.activePortJobPrincipalEmail;

export const getPortJobAvailableGroups = (state: AppState) =>
	state.portjob.availableGroups;

export const getPortJobAvailableGroupsSelectOptions = createSelector(
	getPortJobAvailableGroups,
	availableGroups => availableGroups.map(group => entityToLabeledValue(group))
);

export const getIsLoadingDefaultMessagingGroup = createSelector(
	(state: AppState) => state.portjob.fetchStatuses.availableGroups,
	availableGroups => getIsLoading(availableGroups)
);

export const getIsUpdatingDefaultMessagingGroup = createSelector(
	(state: AppState) => state.portjob.fetchStatuses.settingDefaultMessagingGroup,
	settingDefaultMessagingGroup => getIsLoading(settingDefaultMessagingGroup)
);

export const getIsLoadingPortJobAppointers = createSelector(
	(state: AppState) => state.portjob.fetchStatuses.appointers,
	appointersFetchStatus => getIsLoading(appointersFetchStatus)
);

export const getDefaultMessagingGroupValueForActiveJob = createSelector(
	getActivePortJob,
	activePortJob =>
		activePortJob?.defaultMessagingGroup
			? entityToLabeledValue(activePortJob.defaultMessagingGroup)
			: undefined
);

export const isJobStatusIsPDARequested = createSelector(
	getActivePortJobStatus,
	status => status === PortJobStatus.PDA_REQUESTED
);

export const getEnableOptnsModalFormData = (state: AppState) =>
	getValues<FormData>(state, ENABLE_OPERATIONS_FORM);

export const getServiceSection = createSelector(
	getEnableOptnsModalFormData,
	formData => formData?.list[0]?.serviceSection
);

export const getUpdateServiceSection = createSelector(
	getServiceSection,
	sec => {
		const updateSection = [] as UpdateSectionList[];
		if (sec.length > 0) {
			for (let i = 0; i < sec.length; i++) {
				updateSection.push(Object[i]);
			}
			return updateSection.map((value, index) => ({
				...value,
				serviceCode: sec[index].serviceCode,
				serviceName: sec[index].serviceName,
				serviceSectionCode: sec[index].serviceSectionCode,
				serviceSectionName: sec[index].section?.label,
				entityId: sec[index].section?.key,
				portJobServiceId: sec[index].portJobServiceId
			})) as UpdateSectionList[];
		}
		return [];
	}
);

export const getAlternativeSection = createSelector(
	getEnableOptnsModalFormData,
	formData => formData?.list[0]?.alternateServiceSection
);

export const getUpdateAlternativeSection = createSelector(
	getAlternativeSection,
	sec => {
		const updateSection = [] as UpdateSectionList[];
		if (sec.length > 0) {
			for (let i = 0; i < sec.length; i++) {
				updateSection.push(Object[i]);
			}
			return updateSection.map((value, index) => ({
				...value,
				serviceCode: sec[index].service?.key,
				serviceName: sec[index].service?.label,
				serviceSectionCode: sec[index].serviceSectionCode,
				serviceSectionName: sec[index].altSection?.label,
				entityId: sec[index].altSection?.key,
				portJobServiceId: sec[index].portJobServiceId
			})) as UpdateSectionList[];
		}
		return [];
	}
);
/*
	As part of OW-13116(APA_09) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

// export const isExtendedMessagingEnabled = (state: AppState) =>
// 	state.portjob.extendedMessagingOption === 'Yes';

// export const isExtendedMessagingEnabledForPortJob = createSelector(
// 	getActivePortJob,
// 	activePortJob => activePortJob?.isExtendedMessaging
// );

export const isRequestPDAFetching = createSelector(
	(state: AppState) => state.portjob.fetchStatuses.requestPDA,
	requestPDAFetchStatus => getIsLoading(requestPDAFetchStatus)
);

export const isAddPortJobFetching = createSelector(
	(state: AppState) => state.portjob.fetchStatuses.addAppointmentFromNomination,
	addAppointmentFromNomination => getIsLoading(addAppointmentFromNomination)
);
