import { PageFormProps } from './Form';
import OperationsConfig from '../Operations/OperationsConfig';

export const shouldShowChangeOperationWarning = <P>(
	props: P & PageFormProps
) => {
	const operationConfig =
		props.operationTypeCode && OperationsConfig[props.operationTypeCode];
	// eslint-disable-next-line @typescript-eslint/unbound-method
	if (!operationConfig || !operationConfig.shouldShowChangeOperationWarning) {
		return false;
	}
	return operationConfig.shouldShowChangeOperationWarning(props);
};
