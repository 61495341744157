import {
	MoveJobFinanceRequest,
	MoveJobFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const MOVE_JOB_FINANCE = 'MOVE_JOB_FINANCE';
export const moveJobFinanceAsync = actionCreator.async<
	MoveJobFinanceRequest,
	MoveJobFinanceResponse,
	Error
>(MOVE_JOB_FINANCE, {
	done: {
		title: 'The finance has been successfully submitted',
		description: `What's next: You are done. Optic will notify you if any further action is required.`
	},
	// handles 400, and `reactToChanges` handles the rest
	failed: {
		omit: true
	}
});

export const moveJobFinance = actionCreator<MoveJobFinanceRequest>(
	MOVE_JOB_FINANCE
);
