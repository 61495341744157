import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import { FinanceState } from '../financeState';
import { Success } from 'typescript-fsa';

import {
	RetrieveRefreshDirectBillRequest,
	RetrieveRefreshDirectBillResponse
} from 'services/api/finance/financeServiceTypes';

export function onRetrieveRefreshDirectBillSuccess(
	state: FinanceState,
	{
		result
	}: Success<
		RetrieveRefreshDirectBillRequest,
		RetrieveRefreshDirectBillResponse
	>
): FinanceState {
	return {
		...state,
		refreshDirectBill: {
			availableDirectBills: [...result.directBillsMastersResponse],
			currentDirectBills: [...result.directBillsFinanceResponse]
		}
	};
}

export const onRetrieveRefreshDirectBill = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveRefreshDirectBillRequest,
	RetrieveRefreshDirectBillResponse
>('fetchStatuses', 'retrieveRefreshDirectBills', {
	doneReducer: onRetrieveRefreshDirectBillSuccess
});
