import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import { UpdateCargoMeasurementsParams } from '../actions/updateCargoMeasurements';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateCargoMeasurements = makeFetchStatusReducers<
	State,
	'updateStatuses',
	UpdateCargoMeasurementsParams,
	null
>(
	'updateStatuses',
	({
		updatingData
	}: UpdateCargoMeasurementsParams): keyof State['updateStatuses'] =>
		`cargo.${updatingData.operationUnitId}` as keyof State['updateStatuses']
);
