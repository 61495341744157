import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';

const onCreatePortCallDocumentStarted = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		create: FetchStatus.PENDING
	}
});

const onCreatePortCallDocumentSuccess = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		create: FetchStatus.SUCCESS
	}
});

const onCreatePortCallDocumentFailed = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		create: FetchStatus.FAILURE
	}
});

export {
	onCreatePortCallDocumentStarted,
	onCreatePortCallDocumentSuccess,
	onCreatePortCallDocumentFailed
};
