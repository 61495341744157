import actionCreator from '../portJobsActionCreator';
import {
	EditPortJobResponse,
	EditPortJobActionParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { ErrorStatusCode } from 'services/api/apiErrorTypes';

const ACTION_NAME = 'EDIT_PORTJOB';
export const editPortJobAsync = actionCreator.async<
	EditPortJobActionParams,
	EditPortJobResponse,
	Error
>(ACTION_NAME, {
	failed: {
		omit: [ErrorStatusCode.NOT_FOUND] // 404 is handled in saga
	},
	templateParams: {
		entityName: 'Port Job'
	}
});

export const editPortJob = actionCreator(ACTION_NAME);
export const refreshEditPortJob = actionCreator('REFRESH_EDIT_PORTJOB');
