import { call, select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';

import { getIsPortJobStatusConfirmedorPDARequested } from 'store/portJobs/selectors';
import retrievePortJobCustodyRoles from 'store/portJobs/actions/dictionaries/retrievePortJobCustodyRoles';
import {
	// CustodyRole,
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const apiCall = Api.PortJobs.retrievePortJobCustodyRoles;
const { async } = retrievePortJobCustodyRoles;

const commonExecutor = makeDefaultExecutor<
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse,
	Error
>({
	api: apiCall,
	async
});

export const executor = function*(params: PortJobCustodyRolesRequest) {
	const shouldTriggerRequest = yield select(
		getIsPortJobStatusConfirmedorPDARequested
	);

	if (!shouldTriggerRequest) {
		return;
	}
	yield call(commonExecutor, params);
};

export const retrievePortJobCustodyRolesWatcher = makeTakeLatestWatcher<
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse,
	Error
>(
	{
		api: apiCall,
		async
	},
	executor
);

export default retrievePortJobCustodyRolesWatcher;
