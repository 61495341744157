/**
 *
 * @param {number} bytes
 * @param {number} precision
 */
const formatBytes = (bytes: number, precision = 2) => {
	if (bytes < 1) {
		return '0 B';
	}

	const log = Math.log.bind(Math);
	const kilobyte = 1024;
	const scale = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const posInScale = Math.floor(log(bytes) / log(kilobyte));
	const amount = (bytes / Math.pow(kilobyte, posInScale)).toFixed(precision);

	return `${parseFloat(amount)} ${scale[posInScale]}`;
};

export default formatBytes;
