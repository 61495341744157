import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	RetrieveSupplierInvoiceContextualizationForUpdateRequest
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import {
	retrieveSupplierInvoiceContextualizationAsync,
	retrieveSupplierInvoiceContextualizationForUpdateAsync,
	retrieveSupplierInvoiceAsync,
	retrieveSupplierInvoiceContextualizationForAddViaPostCredit,
	setPortJobsInSupplierInvoiceContextualization
} from '../actions';
import { Success } from 'typescript-fsa';
import {
	initPortJobsInContextualizationForm,
	updatePortJobsInContextualizationForm
} from '../../form/supplierInvoiceContextualization/actions';
import { getActiveSupplierInvoiceId } from '../selectors';
import { getPageWizardInitialStateForUpdate } from 'store/form/supplierInvoiceContextualization/selectors';
import { updatePageState } from 'store/wizardForm/actions';
import { DOCUMENT_VIEWER_WIZARD_FORM_NAME } from 'sections/DocumentViewer/DocumentViewerWizardFormTypes';

const addApiCall = Api.SupplierInvoice.retrieveSupplierInvoiceContextualization;
const updateApiCall =
	Api.SupplierInvoice.retrieveSupplierInvoiceContextualizationForUpdate;

const retrieveSupplierInvoiceContextualizationForAdd = makeTakeLatestWatcher<
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	Error
>({
	api: addApiCall,
	async: retrieveSupplierInvoiceContextualizationAsync,
	*onSuccess({
		result
	}: Success<
		RetrieveSupplierInvoiceContextualizationRequest,
		RetrieveSupplierInvoiceContextualizationResponse
	>) {
		yield put(initPortJobsInContextualizationForm(result));
	}
});

const retrieveSupplierInvoiceContextualizationForUpdate = makeTakeLatestWatcher<
	RetrieveSupplierInvoiceContextualizationForUpdateRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	Error
>({
	api: updateApiCall,
	async: retrieveSupplierInvoiceContextualizationForUpdateAsync,
	*onSuccess({
		params: { isFromRefresh },
		result
	}: Success<
		RetrieveSupplierInvoiceContextualizationForUpdateRequest,
		RetrieveSupplierInvoiceContextualizationResponse
	>) {
		if (isFromRefresh) {
			yield put(initPortJobsInContextualizationForm(result));
		} else {
			yield put(updatePortJobsInContextualizationForm(result));
			const pages = yield select(getPageWizardInitialStateForUpdate);
			yield put(
				updatePageState({ name: DOCUMENT_VIEWER_WIZARD_FORM_NAME, pages })
			);
		}
	}
});

export function* retrieveContextualizationForAddViaPostCreditWorker() {
	const id: string = yield select(getActiveSupplierInvoiceId);
	if (!id) {
		return;
	}
	try {
		const {
			data: { contextualizations }
		} = yield call(
			Api.SupplierInvoice.retrieveSupplierInvoiceForPostCreditContextualization,
			id
		);
		/**
		 * InvoiceDetailsPortJobService component needs to use shouldComponentUpdate
		 * to avoid performance issues when editing many form fields.
		 * Here we need to make sure that all services will be rerendered on refresh click
		 */
		yield put(setPortJobsInSupplierInvoiceContextualization([]));
		yield put(
			setPortJobsInSupplierInvoiceContextualization(contextualizations.elements)
		);
		yield put(
			initPortJobsInContextualizationForm({
				...contextualizations,
				shouldAllServicesBeDeselected: true
			})
		);
	} catch (error) {
		yield put(retrieveSupplierInvoiceAsync.failed({ error, params: id }));
	}
}

const retrieveSupplierInvoiceContextualizationForAddViaPostCreditWatcher = function*() {
	yield takeLatest(
		retrieveSupplierInvoiceContextualizationForAddViaPostCredit.type,
		retrieveContextualizationForAddViaPostCreditWorker
	);
};

export {
	retrieveSupplierInvoiceContextualizationForAdd,
	retrieveSupplierInvoiceContextualizationForUpdate,
	retrieveSupplierInvoiceContextualizationForAddViaPostCreditWatcher
};
