import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'UPLOAD_PREFERRED_AGENTS_FILE';

export interface UploadCompanyPreferredAgentsFileActionRequest
	extends Pick<UploadDocumentDoneResponse, 'document'> {
	companyId: string;
}

export const uploadCompanyPreferredAgentsFileAsync = actionCreator.async<
	UploadCompanyPreferredAgentsFileActionRequest,
	null,
	Error
>(ACTION_NAME);

export const uploadCompanyPreferredAgentsFile = actionCreator<
	UploadCompanyPreferredAgentsFileActionRequest
>(ACTION_NAME);
