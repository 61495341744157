import Api from 'services/api';
import { retrieveMainCommoditiesAsync as async } from '../actions';
import { RetrieveMainCommoditiesResponse } from 'services/api/commodities/commoditiesServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const retrieveMainCommoditiesWatcher = makeTakeLatestWatcher<
	null,
	RetrieveMainCommoditiesResponse,
	Error
>({
	api: Api.Commodities.retrieveMainCommodities,
	async
});

export default retrieveMainCommoditiesWatcher;
