import * as React from 'react';
import Charterer from './Charterer/Charterer';

import { OperationWithCharterersDisplay } from './CharterersTypes';

export { OperationWithCharterersDisplay };

export default ({
	operation
}: {
	operation: OperationWithCharterersDisplay;
}) => (
	<>
		{operation.charterers.map(charterer => (
			<Charterer
				key={charterer.chartererCompany.id}
				charterer={charterer}
				operationTypeCode={operation.code}
			/>
		))}
	</>
);
