import actionCreator from '../configurationServicesActionCreator';

const ACTION_NAME = 'REMOVE_PRINCIPAL_SERVICE';

export const removePrincipalServiceAsync = actionCreator.async<
	string,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Service'
	}
});

export const removePrincipalService = actionCreator<string>(ACTION_NAME);

export const onRemovePrincipalService = actionCreator<string>(
	`ON_${ACTION_NAME}`
);
