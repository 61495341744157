import actionCreatorFactory from 'typescript-fsa';
import {
	UserUpdateStatusParams,
	UserProfile
} from 'services/api/users/userServiceTypes';

const actionCreator = actionCreatorFactory();

export const updateUserStatusAsync = actionCreator.async<
	UserUpdateStatusParams,
	UserProfile,
	Error
>('users/UPDATE_USER_STATUS', {
	templateParams: {
		entityName: 'User Status'
	}
});

export const updateUserStatus = actionCreator<UserUpdateStatusParams>(
	updateUserStatusAsync.type
);
