import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'components/antd';
import ExtendedMessagingContainer from '../../ExtendedMessagingModal/ExtendedMessagingContainer';
import { EXTENDED_MAIL_TYPE } from 'sections/PortCall/PortJob/PortJobConstants';
import { requestPDA } from 'store/portJobs/actions';

interface RequestPDAMailModalProps {
	isVisible: boolean;
	closeRequestPDAMailModal: () => void;
}

const RequestPDAMailModal: React.FC<RequestPDAMailModalProps> = ({
	isVisible,
	closeRequestPDAMailModal
}) => {
	const dispatch = useDispatch();
	const [isWarningVisible, setWarning] = React.useState(false);
	const handlePDARequestStatus = () => {
		dispatch(requestPDA());
		closeRequestPDAMailModal();
	};
	return (
		<>
			<Modal
				destroyOnClose
				title="Job details will be sent to"
				visible={isVisible}
				onClose={() => setWarning(true)}
				width={1366}
				fullSizeModal
				footer={null}
			>
				<ExtendedMessagingContainer
					closeExtendedMessagingModal={handlePDARequestStatus}
					mailType={EXTENDED_MAIL_TYPE.REQUEST_QUOTE_EMAIL}
				/>
			</Modal>
			<Modal
				okText="Continue"
				cancelText="Cancel"
				visible={isWarningVisible}
				closable={false}
				onCancel={() => setWarning(false)}
				onOk={() => {
					setWarning(false);
					closeRequestPDAMailModal();
				}}
			>
				The "Request PDA" process will be cancelled. Do you want to continue?
			</Modal>
		</>
	);
};

export default RequestPDAMailModal;
