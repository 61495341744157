import { Success } from 'typescript-fsa';
import { reject } from 'lodash';
import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest } from 'services/api/finance/financeServiceTypes';

export const deleteFinanceDocumentOriginalAndAnnotatedPagesReducer = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest,
	null
>('fetchStatuses', 'deleteFinanceDocumentOriginalAndAnnotated', {
	doneReducer: (
		state: FinanceState,
		{
			params
		}: Success<DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest, null>
	): FinanceState => ({
		...state,
		document: !state.document
			? state.document
			: {
					...state.document,
					pages: reject(state.document.pages, {
						actualNumber: params.actualNumber
					})
			  }
	})
});
