import { TableProps } from './Table';

export type Columns<T> = TableProps<T>['columns'];

/**
 * Process all columns and apply a width in percentage to each of them
 * except that the column have already a `width` defined
 */
export function setCommonColumnWidth<T = {}>(columns: Columns<T>): Columns<T> {
	if (!columns) {
		return [];
	}

	const colsWithWidthDefined = columns.reduce(
		(acc, col) => (col.width ? acc++ : acc),
		0
	);

	const result = columns.map(col => {
		return {
			...col,
			width: col.width
				? col.width
				: `${100 / (columns.length - colsWithWidthDefined)}%`
		};
	});

	return result;
}
