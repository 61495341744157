import actionCreator from '../wizardFormActionCreator';

export interface WizardFormSetStartPage {
	name?: string;
	startPage: number;
}

const SET_START_PAGE = 'SET_START_PAGE';
export const setStartPage = actionCreator<WizardFormSetStartPage>(
	SET_START_PAGE
);
