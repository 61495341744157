import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import {
	DeletePortCallEventRequest,
	PortCallEventStatus
} from 'services/api/portCall/portCallServiceTypes';

export const onSetPortCallEventAsHappened = (
	state: PortCallOperationsState,
	{ eventId, portCallId }: DeletePortCallEventRequest
): PortCallOperationsState => {
	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: {
						...state.eventSectionsByPortCallId[portCallId].eventsById[eventId],
						status: PortCallEventStatus.CREATED
					}
				}
			}
		}
	};
};
