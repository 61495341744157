import { Reducer } from 'redux';
import { FormStateMap } from './formState';
import { reducer as form } from 'redux-form';
import { CONTEXTUALIZATION_FORM_ID as OPERATIONS_CONTEXTUALIZATION_FORM_ID } from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import financeContextualization from './financeContextualization/financeContextualizationReducer';
import operationsContextualization from './operationsContextualization/operationsContextualizationReducer';
import principalServices from './principalServices/principalServicesReducer';
import supplierInvoiceContextualization from './supplierInvoiceContextualization/supplierInvoiceContextualizationReducer';
import { SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID } from './supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';

export default form.plugin({
	financeContextualization,
	principalServices,
	[OPERATIONS_CONTEXTUALIZATION_FORM_ID]: operationsContextualization,
	[SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID]: supplierInvoiceContextualization
}) as Reducer<FormStateMap>;
