import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import { UpdateSavingsRequest } from 'services/api/portJobs/portJobsServiceTypes';

const doneReducer = (
	state: PortJobState,
	{ params: { portJobCode, savings } }: Success<UpdateSavingsRequest, void>
): PortJobState => {
	const portJob = Object.values(state.byId).find(
		job => job.code === portJobCode
	);
	return portJob
		? {
				...state,
				byId: {
					...state.byId,
					[portJob.id]: {
						...state.byId[portJob.id],
						savings
					}
				}
		  }
		: state;
};

export const onUpdateSavings = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	UpdateSavingsRequest,
	void
>('fetchStatuses', 'all', { doneReducer });
