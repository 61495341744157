import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import Api from 'services/api';
import { retrieveThreadEventsAsync } from 'store/thread/actions/retrieveThreadEvents';
import {
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse
} from 'services/api/threads/threadsServiceTypes';
import { getLatestEvent, getOldestEvent } from 'store/thread/eventsSelector';
import { select } from 'redux-saga/effects';

export default makeTakeLatestWatcher<
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse,
	Error
>({
	async: retrieveThreadEventsAsync,
	api: Api.Threads.retrieveThreadEvents,
	*getApiParams(params: RetrieveThreadEventsRequest) {
		const retrieveLatest = params.retrieveLatest || false;
		let payload: RetrieveThreadEventsRequest = {
			threadId: params.threadId,
			expand: 'message,attachment',
			index: 0,
			sortBy: 'CreatedOn:desc',
			...params
		};

		if (!retrieveLatest) {
			const oldestEvent = yield select(getOldestEvent);
			payload = {
				...payload,
				createdOnBefore: oldestEvent ? oldestEvent.createdOn : undefined
			};
		} else {
			const latestEvent = yield select(getLatestEvent);
			payload = {
				...payload,
				createdOnAfter: latestEvent ? latestEvent.createdOn : undefined
			};
		}
		return payload;
	}
});
