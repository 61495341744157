import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { BankAccountsState } from '../bankAccountsState';
import {
	UpdateBankAccountRequest as Request,
	BankAccount as Response
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Success } from 'typescript-fsa';

const updateBankAccountDoneReducer = (
	state: BankAccountsState,
	action: Success<Request, Response>
): BankAccountsState => ({
	...state,
	byId: {
		...state.byId,
		[action.params.bankDetailId]: action.result
	}
});

export const onUpdateBankAccount = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'update', {
	doneReducer: updateBankAccountDoneReducer
});
