import actionCreator from '../portJobsActionCreator';
import {
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'SET_DEFAULT_MESSAGING_GROUP';

export const setDefaultMessagingGroup = actionCreator<
	Pick<SetDefaultMessagingGroupRequest, 'group' | 'portJobId'>
>(ACTION_NAME);

export const setDefaultMessagingGroupAsync = actionCreator.async<
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse
>(ACTION_NAME);
