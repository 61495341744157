import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { all, put, takeEvery } from 'redux-saga/effects';
import { addPOIDraft, setActiveTerminalId } from 'store/drafts/actions';
import {
	updateTerminal,
	updateBerth,
	updateBuoy,
	updateAnchorage,
	updateCanal
} from 'store/vesselProgramme/actions';
import { AddPOIDraftRequest } from 'containers/Drafts/AddPOIDraft/addPOIDraftTypes';
import { RotationStepPOIType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import uuidv4 from 'uuid/v4';

const AddPOIDraftsEffects = {
	[RotationStepPOIType.TERMINAL]: {
		callback: updateTerminal,
		entityField: 'terminal'
	},
	[RotationStepPOIType.BERTH]: {
		callback: updateBerth,
		entityField: 'berth'
	},
	[RotationStepPOIType.BUOY]: {
		callback: updateBuoy,
		entityField: 'buoy'
	},
	[RotationStepPOIType.ANCHORAGE]: {
		callback: updateAnchorage,
		entityField: 'anchorage'
	},
	[RotationStepPOIType.CANAL]: {
		callback: updateCanal,
		entityField: 'canal'
	}
};

function* addPOIDraftWorker({
	payload: {
		rotationStepId,
		berthName,
		parentPOIId: _parentPOIId,
		...draftData
	}
}: Action<AddPOIDraftRequest>): SagaIterator {
	const isAddingTerminalDraft =
		draftData.POIType === RotationStepPOIType.TERMINAL && !!berthName;
	const { callback, entityField } = AddPOIDraftsEffects[draftData.POIType];
	yield put(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(callback as any)({
			rotationStepId,
			[entityField]: {
				id: draftData.id,
				name: draftData.name
			},
			skipBerthReset: isAddingTerminalDraft
		})
	);
	/** if terminal draft was added, berth draft should be added as well (and only after it, as terminal update will remove berth data) */
	if (isAddingTerminalDraft && berthName) {
		yield all([
			put(
				addPOIDraft({
					...draftData,
					id: uuidv4(),
					POIType: RotationStepPOIType.BERTH,
					name: berthName,
					rotationStepId,
					parentPOIId: draftData.id
				})
			),
			put(
				setActiveTerminalId({
					rotationStepId,
					terminalId: draftData.id
				})
			)
		]);
	}
}

function* addPOIDraftWatcher(): SagaIterator {
	yield takeEvery(addPOIDraft.type, addPOIDraftWorker);
}

export default addPOIDraftWatcher;
