import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { editNotIssuedPortCallDocumentAsync } from '../actions';
import { PortCallDocumentNotIssuedPayload } from '../actions/markPortCallDocumentNotIssued';
import { getActivePortCallId } from '../../portcalls/selectors';
import {
	getUploadedDocumentConcurrencyToken,
	getNotIssuedPortCallPlaceholderConcurrencyToken
} from '../selectors';
import Api from 'services/api';

export function* editNotIssuedPortCallDocumentWorker({
	payload
}: Action<PortCallDocumentNotIssuedPayload>): SagaIterator {
	yield put(editNotIssuedPortCallDocumentAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const documentConcurrencyToken = yield select(
		getUploadedDocumentConcurrencyToken,
		payload.documentId
	);
	const placeholderConcurrencyToken = yield select(
		getNotIssuedPortCallPlaceholderConcurrencyToken,
		payload.documentId,
		payload.portCallPlaceholderId
	);
	const updateRequest = { ...payload, portCallId };
	try {
		const response = yield call(
			Api.PortCallDocuments.markPortCallDocumentNotIssued,
			{
				...updateRequest,
				documentConcurrencyToken,
				placeholderConcurrencyToken
			}
		);
		yield put(
			editNotIssuedPortCallDocumentAsync.done({
				result: response.data,
				params: updateRequest,
				response
			})
		);
	} catch (error) {
		yield put(
			editNotIssuedPortCallDocumentAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* editNotIssuedPortCallDocumentWatcher(): SagaIterator {
	yield takeLatest(
		editNotIssuedPortCallDocumentAsync.type,
		editNotIssuedPortCallDocumentWorker
	);
}
