import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

interface VesselById {
	[vesselId: string]: VesselDetails;
}

export interface VesselsState {
	byId: VesselById;
	fetchStatuses: {
		[key: string]: FetchStatus;
	};
}

export const initialState: VesselsState = {
	byId: {},
	fetchStatuses: {}
};
