import { Action } from 'typescript-fsa';
import { put, take, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	uploadCompanyPreferredAgentsFile,
	uploadCompanyPreferredAgentsFileAsync,
	retrieveCompanyPreferredAgentsFileInfo
} from 'store/companies/actions';

import { uploadConfigurationPreferredAgentsFileAsync as async } from '../actions';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import { getActiveCompanyId } from '../selectors';
import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { notify } from 'store/notifications/actions';
import { getResponseErrorMessage } from 'redux/middlewares/api/apiMiddlewareConfigUtils';

export function* worker({
	payload
}: Action<UploadDocumentDoneResponse>): SagaIterator {
	yield put(async.started(payload));
	try {
		const companyId = yield select(getActiveCompanyId);

		yield put(
			uploadCompanyPreferredAgentsFile({
				companyId: companyId,
				document: payload.document
			})
		);

		// Wait for the request to resolve
		const response = yield take([
			uploadCompanyPreferredAgentsFileAsync.done,
			uploadCompanyPreferredAgentsFileAsync.failed
		]);
		if (response.payload.error) {
			const errorMessage = getResponseErrorMessage(response.payload.error);
			yield put(notify.error({ description: errorMessage, duration: 0 }));
			throw new Error();
		}

		// update uploaded files list
		yield put(retrieveCompanyPreferredAgentsFileInfo({ companyId }));

		yield put(
			async.done({
				params: payload,
				result: null,
				response: null
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				params: payload,
				// this done because we get `string` here which doesn`t satisfy `isConnectionError`
				error: new Error()
			})
		);
	} finally {
		yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
