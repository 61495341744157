import client from 'services/client';

import {
	RetrieveServicesResponse,
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse,
	AddServiceCategoryRequest,
	UpdateServiceCategoryRequest,
	DeleteServiceCategoryRequest,
	UpdatePrincipalServiceRequest,
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse,
	AddPrincipalServiceRequest,
	PrincipalService,
	ServiceCategory
} from './servicesServiceTypes';

const retrieveServices = () =>
	client.get<RetrieveServicesResponse>('/masterdata/services');

const retrievePrincipalServices = ({
	mainPrincipalId,
	searchTerm
}: RetrievePrincipalServicesRequest) =>
	client.get<RetrievePrincipalServicesResponse>(
		`/services/principal-services/for-principal/${mainPrincipalId}`,
		{
			params: { searchTerm }
		}
	);

const addPrincipalService = (data: AddPrincipalServiceRequest) =>
	client.post<PrincipalService>(`/services/principal-services`, data);

const updatePrincipalService = ({ id, data }: UpdatePrincipalServiceRequest) =>
	client.put<PrincipalService>(`/services/principal-services/${id}`, data);

const retrievePrincipalService = (id: string) =>
	client.get<PrincipalService>(`/services/principal-services/${id}`);

const removePrincipalService = (id: string) =>
	client.delete(`/services/principal-services/${id}`);

const retrieveServiceCategories = ({
	mainPrincipalId
}: RetrieveServiceCategoriesRequest) =>
	client.get<RetrieveServiceCategoriesResponse>(
		`/services/principal-service-categories/for-principal/${mainPrincipalId}`
	);

const addServiceCategory = ({
	requestParams: data
}: AddServiceCategoryRequest) =>
	client.post<ServiceCategory>(`/services/principal-service-categories`, data);

const updateServiceCategory = ({
	requestParams: { id, ...data }
}: UpdateServiceCategoryRequest) =>
	client.put<ServiceCategory>(
		`/services/principal-service-categories/${id}`,
		data
	);

const deleteServiceCategory = ({ id }: DeleteServiceCategoryRequest) =>
	client.delete(`/services/principal-service-categories/${id}`);

export default {
	retrieveServices,
	updatePrincipalService,
	retrieveServiceCategories,
	addServiceCategory,
	updateServiceCategory,
	deleteServiceCategory,
	retrievePrincipalServices,
	addPrincipalService,
	retrievePrincipalService,
	removePrincipalService
};
