import { getDaById, getServiceById } from 'store/finance/selectors';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import {
	isNull,
	first,
	get,
	take,
	takeRight,
	find,
	each,
	forIn,
	some,
	includes,
	filter,
	isEmpty
} from 'lodash';

import { FetchStatus } from 'services/api/apiTypes';
import {
	FinanceStatus,
	ViaValue,
	DAOutValidationMode,
	DAColumnMetadataKey,
	DisbursementAccount,
	ExchangeRate,
	CurrencyPairDictionary,
	CurrencyPairExchangeRateDictionary,
	DaType,
	DaStatus,
	FinanceStage,
	DaSetStatus,
	DaSet,
	DaSetType,
	ServiceType,
	DAColumnMetadataActionCode,
	FinanceMetadataActionCode,
	PortJobService,
	FixedCurrencyDictionary,
	FixedRate
} from 'services/api/finance/financeServiceTypes';

import {
	getIsCurrentUserLpa,
	getIsCurrentUserPrincipal,
	getUserType
} from 'store/auth/selectors';
import {
	getDocumentsByDaOutId,
	getApprovalSettings,
	getAllDas,
	getIndicativeRateByCurrencyCodesHelper,
	getExchangeRates,
	getFinanceServices,
	getDAsOutCollection,
	getDAsInCollection,
	getFinanceStatus,
	getFinanceStage,
	getDASetById,
	getDaSets,
	getDAsOut,
	getDAsIn,
	getFinanceFetchStatuses,
	getFinanceEntityMetadataAction,
	getFinanceMultilevelApprovalSettings,
	getShouldShowApprovalLevelInDaOutStatus
} from './financeSelectors';

import runValidations, { charMax, required } from 'utils/validations';
import { Metadata } from 'app-types';
import { DEFAULT_CURRENCY_CODE } from 'app-constants';
import { UserType, UserAuthGroup } from 'services/api/users/userServiceTypes';
import { getIsLoading } from 'store/selectors';
import {
	getReasonMessageByAction,
	getIsActionDisabled,
	getEntityMetadataAction
} from 'store/metadata/utils/metadataUtils';
import { getBankAccountsById } from 'store/masterdata/bankAccounts/selectors';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { daTitle } from 'sections/PortCall/Finance/Sections/Summary/summaryUtils';
import {
	getDaInStatus,
	getDaOutStatus
} from 'sections/PortCall/Finance/financeUtils';
import { getActivePortJob } from 'store/portJobs/selectors';
import { PortJobTypeCode } from 'services/api/portJobs/portJobsServiceTypes';

const AVAILABLE_INDICATIVE_RATES_LENGTH = 4;

/**
 * Return the status of a cover sheet document that could being processed at the moment
 */
const getCoverSheetButtonStatus = createSelector(
	getDocumentsByDaOutId,
	(_state: AppState, daOutId: string) => daOutId,
	(documents, daOutId: string): FetchStatus =>
		get(documents, [daOutId, 'fetchStatus'], FetchStatus.IDLE)
);

export const makeGetCoverSheetButtonIsDisabled = () =>
	createSelector(
		getCoverSheetButtonStatus,
		status => status === FetchStatus.PENDING
	);

export const getIsDisabledOfflineApproval = createSelector(
	getApprovalSettings,
	getFinanceStage,
	(approvalSettings, stage) => {
		if (stage === 'PDA') {
			return !approvalSettings.isPDAApprovalRequired;
		}
		if (stage === 'CE') {
			return !approvalSettings.isCEApprovalRequired;
		}
		return !approvalSettings.isDAApprovalRequired;
	}
);

export const validateTextField = (value: string) =>
	first(runValidations(value, [charMax(30), required]));

export const getEditDaItemSelector = (state: AppState) =>
	state.finance.context.editItem;

export const getFinanceDADisplayingStatus = createSelector(
	getUserType,
	(_state: AppState, da: DisbursementAccount) => da,
	getFinanceMultilevelApprovalSettings,
	getShouldShowApprovalLevelInDaOutStatus,
	(userType, da, multilevelApprovalSettings, shouldShowApprovalLevel) => {
		if (da.daType === DaType.IN) {
			return getDaInStatus(
				userType,
				da.status,
				da.queriedToHub,
				da.queriedToLPA
			);
		} else {
			return getDaOutStatus(
				userType,
				da.status,
				da.queriedToHub,
				da.queriedToLPA,
				multilevelApprovalSettings,
				shouldShowApprovalLevel
			);
		}
	}
);

export const getValidationMode = (offlineApproval: boolean, via: ViaValue) => {
	if (offlineApproval) {
		if (via === ViaValue.UPLOAD) {
			return DAOutValidationMode.OFFLINE_UPLOAD;
		} else {
			return DAOutValidationMode.OFFLINE_EMAIL;
		}
	} else {
		return DAOutValidationMode.ONLINE;
	}
};

export const getLiablePartyCompany = createSelector(
	getEditDaItemSelector,
	getActivePortJob,
	(editDaItem, activeJob) => {
		if (isNull(editDaItem) || !editDaItem.liablePartyCompany) {
			if (activeJob.jobType === PortJobTypeCode.APPOINTMENT) {
				return {
					key: activeJob.appointerCompany.id,
					label: activeJob.appointerCompany.name
				};
			} else {
				return {
					key: activeJob.nominatorCompany?.id,
					label: activeJob.nominatorCompany?.name
				};
			}
		}

		return {
			key: editDaItem.liablePartyCompany.id,
			label: editDaItem.liablePartyCompany.name
		};
	}
);

export const getProcessingPartyCompany = createSelector(
	getEditDaItemSelector,
	getActivePortJob,
	(editDaItem, activeJob) => {
		if (isNull(editDaItem) || !editDaItem.processingPartyCompany) {
			if (activeJob.payingPartyCompany.name) {
				//for CLS jobs paying party coming with dummy id and empty name. So checking name
				return {
					key: activeJob.payingPartyCompany.id,
					label: activeJob.payingPartyCompany.name
				};
			} else {
				return null;
			}
		}

		return {
			key: editDaItem.processingPartyCompany.id,
			label: editDaItem.processingPartyCompany.name
		};
	}
);

export const getApprovalGroup = createSelector(
	getEditDaItemSelector,
	editDaItem => {
		if (isNull(editDaItem) || !editDaItem.validationGroup) {
			return null;
		}

		return {
			key: editDaItem.validationGroup.id,
			label: editDaItem.validationGroup.name
		};
	}
);

export const getOfflineApproval = createSelector(
	getEditDaItemSelector,
	getIsDisabledOfflineApproval,
	(editDaItem, isDisabledOfflineApprovalByDefault) => {
		if (isDisabledOfflineApprovalByDefault) {
			return true;
		}

		if (isNull(editDaItem)) {
			return false;
		}

		return (
			editDaItem.validationMode === DAOutValidationMode.OFFLINE_EMAIL ||
			editDaItem.validationMode === DAOutValidationMode.OFFLINE_UPLOAD
		);
	}
);

export const getVia = createSelector(getEditDaItemSelector, editDaItem => {
	if (isNull(editDaItem)) {
		return ViaValue.EMAIL;
	}

	if (editDaItem.validationMode === DAOutValidationMode.OFFLINE_UPLOAD) {
		return ViaValue.UPLOAD;
	}
	return ViaValue.EMAIL;
});

export const isUpdatingDA = (state: AppState, id: string) =>
	state.finance.updateStatuses.das[id] === FetchStatus.PENDING;

const emptyMetadata: Metadata<FinanceMetadataActionCode> = {
	actions: [],
	propertiesToEdit: []
};

const getDAInMetaData = createSelector(getDAsInCollection, ({ metadata }) =>
	metadata ? metadata : emptyMetadata
);

const getDAOutMetaData = createSelector(getDAsOutCollection, ({ metadata }) =>
	metadata ? metadata : emptyMetadata
);

export const getDADeleteDisabledParams = (da?: DisbursementAccount | null) => {
	if (!da) {
		return { disabled: true, disableMessage: '' };
	}
	const action = getEntityMetadataAction<DAColumnMetadataActionCode>(
		da.metadata.actions,
		DAColumnMetadataActionCode.DELETE
	);
	return {
		disabled: getIsActionDisabled<DAColumnMetadataActionCode>(action),
		disableMessage: getReasonMessageByAction<DAColumnMetadataActionCode>(action)
	};
};

// DA In
export const getIsDaInVisible = createSelector(
	getDAsInCollection,
	getIsCurrentUserPrincipal,
	(col, isPrincipal) => Boolean(col.elements.length) && !isPrincipal
);

export const getIsDeleteDaInButtonVisible = createSelector(
	getDAInMetaData,
	metadata =>
		!!getFinanceEntityMetadataAction(metadata, FinanceMetadataActionCode.DELETE)
);

export const getCanCreateDAIn = createSelector(
	getDAInMetaData,
	metadata =>
		!!getFinanceEntityMetadataAction(metadata, FinanceMetadataActionCode.CREATE)
);

export const getCanCreateDAOut = createSelector(
	getDAOutMetaData,
	metadata =>
		!!getFinanceEntityMetadataAction(metadata, FinanceMetadataActionCode.CREATE)
);

// merges all conditions about type and set
export const getCanCreateDA = createSelector(
	getDASetById,
	(state: AppState, _: string, daType: DaType) =>
		daType === DaType.IN ? getCanCreateDAIn(state) : getCanCreateDAOut(state),
	(daSet, canCreateByDaType) => {
		const daSetMetadata = daSet.metadata || emptyMetadata;
		const canCreateByDaSet = getFinanceEntityMetadataAction(
			daSetMetadata,
			FinanceMetadataActionCode.CREATE
		);
		return Boolean(canCreateByDaType && canCreateByDaSet);
	}
);

export const getIsDaInRowExpanded = (state: AppState) =>
	some(getDAsInCollection(state).propertiesToView, prop =>
		includes(
			[
				DAColumnMetadataKey.VERIFIED_ON,
				DAColumnMetadataKey.SUBMITTED_ON,
				DAColumnMetadataKey.SETTLEMENT_ON
			],
			prop
		)
	);

// DA Out
export const getIsDaOutVisible = createSelector(
	getDAsOutCollection,
	getIsCurrentUserLpa,
	(col, isLpa) => {
		return Boolean(col.elements.length) && !isLpa;
	}
);

export const getShouldShowDaOutDates = (state: AppState) =>
	some(getDAsOutCollection(state).propertiesToView, prop =>
		includes(
			[DAColumnMetadataKey.SUBMITTED_ON, DAColumnMetadataKey.APPROVED_ON],
			prop
		)
	);

export const getIsRowDisabled = (
	metadata: Metadata<FinanceMetadataActionCode | DAColumnMetadataActionCode>,
	financeStage: FinanceStage,
	isJobCancelled?: boolean
) => {
	return (
		isJobCancelled ||
		(financeStage === FinanceStage.DA &&
			metadata.propertiesToEdit.length === 0 &&
			(metadata.actions.length === 0 ||
				!!getEntityMetadataAction<
					FinanceMetadataActionCode | DAColumnMetadataActionCode
				>(metadata.actions, FinanceMetadataActionCode.DISABLE)))
	);
};

export const getDisabledInfoTooltip = (
	record: DisbursementAccount,
	userGroups: UserAuthGroup[]
) => {
	// DA In conditions
	if (record.status === DaStatus.APPROVED || record.daType === DaType.IN) {
		return 'The DA cannot be edited as it has already been approved';
	}
	// This part of conditions is needed for DA Out
	let tooltip = `You don't have permission to approve DA. Please contact your team's Optic administrator for assistance`;
	const group = record.validationGroup && record.validationGroup.name;
	if (record.validationMode === DAOutValidationMode.ONLINE) {
		const userBelongsToValidationGroup = userGroups.some(
			userGroup => userGroup.id === record.validationGroup?.id
		);
		if (!userBelongsToValidationGroup) {
			tooltip = `${group} is responsible for approval of this DA and its services`;
		}
	} else {
		tooltip = `This DA is set up to be approved offline (via email or via upload to 3rd party application)`;
	}
	return tooltip;
};

export const getAllowedDas = createSelector(
	getAllDas,
	getIsDaInVisible,
	getIsDaOutVisible,
	(allDas, daInVisible, daOutVisible) => {
		let visibleDAType: DaType | null = null;
		if (daInVisible && daOutVisible) {
			return allDas;
		}
		if (daInVisible) {
			visibleDAType = DaType.IN;
		} else if (daOutVisible) {
			visibleDAType = DaType.OUT;
		}
		return allDas.filter(da => da.daType === visibleDAType);
	}
);

export const getIndicativeRatesPairs = createSelector(
	getAllowedDas,
	getFinanceServices,
	(das, services) => {
		const pairs: CurrencyPairDictionary = {};
		const addCurrencyPairConditionally = (
			condition: boolean,
			currencyA: string,
			currencyB: string
		) => {
			const notAddedYet = !pairs[`${currencyA}/${currencyB}`];
			if (condition && notAddedYet) {
				pairs[`${currencyA}/${currencyB}`] = {
					baseCurrencyCode: currencyA,
					quoteCurrencyCode: currencyB
				};
			}
		};

		each(services, service => {
			const daIn = service.daInId && find(das, { id: service.daInId });
			const daOut = service.daOutId && find(das, { id: service.daOutId });
			const isDbService = service.serviceTypeCode === ServiceType.DIRECT_BILL;
			const dbServiceHasDaInOrDaOut = !!(isDbService && (daIn || daOut));

			// service to DaIn pair
			if (daIn) {
				addCurrencyPairConditionally(
					service.currencyCode !== daIn.currencyCode,
					service.currencyCode,
					daIn.currencyCode
				);
			}

			// service to DaOut pair
			if (daOut) {
				addCurrencyPairConditionally(
					service.currencyCode !== daOut.currencyCode,
					service.currencyCode,
					daOut.currencyCode
				);
			}

			// service to Default Currency pair
			addCurrencyPairConditionally(
				// IPP-25815 ind. rate for DirectBill service without any DA assigned shoudn't be displayed
				service.currencyCode !== DEFAULT_CURRENCY_CODE &&
					(!isDbService || dbServiceHasDaInOrDaOut),
				service.currencyCode,
				DEFAULT_CURRENCY_CODE
			);

			// DaIn to DaOut pair
			if (daIn && daOut) {
				addCurrencyPairConditionally(
					daIn.currencyCode !== daOut.currencyCode,
					daIn.currencyCode,
					daOut.currencyCode
				);
			}
		});

		return pairs;
	}
);

export const getFixedRatesForDASets = (state: AppState, setId: string) => {
	const das = filter(getAllowedDas(state), { disbursementAccountSetId: setId });
	const services = getFinanceServices(state);

	const pairs: FixedCurrencyDictionary = {};
	const addCurrencyPairConditionally = (
		condition: boolean,
		currencyA: string,
		currencyB: string
	) => {
		const notAddedYet = !pairs[`${currencyA}/${currencyB}`];
		if (condition && notAddedYet) {
			pairs[`${currencyA}/${currencyB}`] = {
				currencyCode: currencyA,
				outCurrencyCode: currencyB,
				disbursementAccountSetId: setId,
				rate: ''
			};
		}
	};

	each(services, service => {
		const daIn = service.daInId && find(das, { id: service.daInId });
		const daOut = service.daOutId && find(das, { id: service.daOutId });

		if (service.modeCode === 'Split' && !service.parentServiceId) {
			each(services, splitService => {
				if (splitService.parentServiceId === service.id) {
					const splitDaOut =
						splitService.daOutId && find(das, { id: splitService.daOutId });
					splitDaOut &&
						addCurrencyPairConditionally(
							service.currencyCode !== splitDaOut.currencyCode,
							service.currencyCode,
							splitDaOut.currencyCode
						);
				}
			});
		}

		if (daIn && daOut) {
			addCurrencyPairConditionally(
				daIn.currencyCode !== daOut.currencyCode,
				daIn.currencyCode,
				daOut.currencyCode
			);
		}
	});

	const resultPairs: FixedRate[] = [];
	forIn(pairs, value => {
		resultPairs.push(value);
	});

	return resultPairs;
};

export const getIsPossibleFixedRateSetEmpty = (
	state: AppState,
	setId: string
) => isEmpty(getFixedRatesForDASets(state, setId));

export const isFixedRateIsEnabledInJob = (state: AppState) => {
	const daSets = getDaSets(state);
	const daSet = find(
		daSets,
		set => set.isFixedRate && !getIsPossibleFixedRateSetEmpty(state, set.id)
	);
	return !isEmpty(daSet);
};

const getExchangeRatesPairs = createSelector(getExchangeRates, rates => {
	const pairs: CurrencyPairExchangeRateDictionary = {};
	each(rates, rate => {
		pairs[`${rate.baseCurrencyCode}/${rate.quoteCurrencyCode}`] = rate;
	});
	return pairs;
});

export const getAllIndicativeRates = createSelector(
	getExchangeRatesPairs,
	getIndicativeRatesPairs,
	getExchangeRates,
	(exchangeRatesPairs, indicativeRatesPairs, exchangeRates) => {
		const resultPairs: ExchangeRate[] = [];
		forIn(indicativeRatesPairs, (value, key) => {
			if (exchangeRatesPairs[key]) {
				resultPairs.push(exchangeRatesPairs[key]);
			} else {
				// this part should be removed when the server starts returning all possible indicativeRates
				resultPairs.push({
					baseCurrencyCode: value.baseCurrencyCode,
					quoteCurrencyCode: value.quoteCurrencyCode,
					date: '',
					indicativeRate: `${getIndicativeRateByCurrencyCodesHelper(
						exchangeRates,
						value
					)}`
				});
			}
		});
		return resultPairs;
	}
);

export const getVisibleIndicativeRates = createSelector(
	getAllIndicativeRates,
	allIndicativeRates =>
		take(allIndicativeRates, AVAILABLE_INDICATIVE_RATES_LENGTH)
);

export const getAllIndicativeRatesRest = createSelector(
	getAllIndicativeRates,
	allIndicativeRates =>
		allIndicativeRates.length > AVAILABLE_INDICATIVE_RATES_LENGTH
			? takeRight(
					allIndicativeRates,
					allIndicativeRates.length - AVAILABLE_INDICATIVE_RATES_LENGTH
			  )
			: null
);

export const getIsDaDraftOrDaSubmittedHub = (
	userType: UserType,
	stage: FinanceStage,
	status: FinanceStatus
) =>
	stage === FinanceStage.DA &&
	(status === FinanceStatus.DRAFT ||
		(status === FinanceStatus.SUBMITTED && userType === UserType.HUB));

export const getDaInExtendedPropsToView = createSelector(
	getDAsInCollection,
	getUserType,
	getFinanceStatus,
	getFinanceStage,
	(das, userType, status, stage) => {
		const props = [
			...das.propertiesToView,
			DAColumnMetadataKey.NUMBER_OF_SERVICES,
			DAColumnMetadataActionCode.EDIT,
			DAColumnMetadataActionCode.DELETE,
			DAColumnMetadataKey.DISABLED_INFO
		];
		if (getIsDaDraftOrDaSubmittedHub(userType, stage, status)) {
			props.push(DAColumnMetadataKey.COVER_SHEET);
		}
		return props;
	}
);

export const canViewSettlementOnSelector = createSelector(
	getDaInExtendedPropsToView,
	propsToView => includes(propsToView, DAColumnMetadataKey.SETTLEMENT_ON)
);

// despite looking quite similar as selector above, it's quite different indeed
export const getDaOutExtendedPropsToView = createSelector(
	getDAsOutCollection,
	getUserType,
	getFinanceStatus,
	getFinanceStage,
	(das, userType, status, stage) => {
		const props = [
			...das.propertiesToView,
			DAColumnMetadataKey.NUMBER_OF_SERVICES,
			DAColumnMetadataActionCode.EDIT,
			DAColumnMetadataActionCode.DELETE,
			DAColumnMetadataKey.DISABLED_INFO
		];
		if (getIsDaDraftOrDaSubmittedHub(userType, stage, status)) {
			props.push(DAColumnMetadataKey.COVER_SHEET);
		}
		return props;
	}
);

// rank for sorting, lower = better
const DaSetStatusSortRank = {
	[DaSetStatus.LOCKED]: 1,
	[DaSetStatus.ACTIVE]: 2,
	[DaSetStatus.FUTURE]: 3
};

const DaTypeSortRank = {
	[DaSetType.ORIGINAL]: 1,
	[DaSetType.SUPPLEMENTAL]: 2
};
const compareByStatus = (setA: DaSet, setB: DaSet) =>
	DaSetStatusSortRank[setA.status] - DaSetStatusSortRank[setB.status];
const compareByType = (setA: DaSet, setB: DaSet) =>
	DaTypeSortRank[setA.type] - DaTypeSortRank[setB.type];
const compareByNumber = (setA: DaSet, setB: DaSet) => setA.number - setB.number;
export const getOrderedDaSets = createSelector(getDaSets, sets =>
	sets
		.sort(compareByNumber)
		.sort(compareByType)
		.sort(compareByStatus)
);

export const getSDASets = createSelector(getDaSets, daSets =>
	daSets
		.filter(set => set.type === DaSetType.SUPPLEMENTAL)
		.sort(compareByNumber)
);

export const getActiveSDASets = createSelector(getSDASets, sdaSets =>
	sdaSets.filter(set => set.status === DaSetStatus.ACTIVE).sort(compareByNumber)
);

export const getDaSetsIdByUserType = createSelector(
	getActiveSDASets,
	(_state: AppState, userType: UserType) => userType,
	(sdaSets, userType) =>
		userType === UserType.HUB
			? sdaSets.find(
					(sdaSet, index) =>
						!sdaSet?.hubReasonCode && sdaSets.length === index + 1
			  )
			: sdaSets.find(
					(sdaSet, index) =>
						!sdaSet?.lpaReasonCode && sdaSets.length === index + 1
			  )
);

export const getDaSetReasonCodeById = createSelector(
	getSDASets,
	(_state: AppState, id: string) => id,
	(_state: AppState, _id: string, userType: UserType) => userType,
	(sdaSets, id, userType) =>
		userType === UserType.HUB
			? sdaSets.find(sdaSet => sdaSet.id === id)?.hubReasonCode
			: sdaSets.find(sdaSet => sdaSet.id === id)?.lpaReasonCode
);

export const getDaSetsByStatus = createSelector(
	getDaSets,
	(_state: AppState, setStatus: DaSetStatus) => setStatus,
	(daSets, setStatus: DaSetStatus) =>
		daSets.filter(daSet => daSet.status === setStatus)
);

export const getDasInDaSetsByStatus = (
	das: DisbursementAccount[],
	daSets: DaSet[],
	statuses: DaSetStatus[]
) => {
	const filteredDaSets = daSets.filter(set => statuses.includes(set.status));
	return das.filter(
		da => !!filteredDaSets.find(set => set.id === da.disbursementAccountSetId)
	);
};

export const getAccessibleDaIns = createSelector(
	getDAsIn,
	getDaSets,
	(das, daSets) =>
		getDasInDaSetsByStatus(das, daSets, [
			DaSetStatus.ACTIVE,
			DaSetStatus.LOCKED
		])
);

export const getDaInsFromActiveDaSet = createSelector(
	getDAsIn,
	getDaSets,
	(das, daSets) => getDasInDaSetsByStatus(das, daSets, [DaSetStatus.ACTIVE])
);

export const getDAsInForTransactionSelect = createSelector(
	getDaInsFromActiveDaSet,
	(_state: AppState, currencyCode: string) => currencyCode,
	(_state: AppState, _currencyCode: string, selectedDa?: DisbursementAccount) =>
		selectedDa,
	(daIns, currencyCode, selectedDa) => {
		let das = daIns;
		if (selectedDa && das && !das.find(da => da.id === selectedDa.id)) {
			das = [...daIns, selectedDa];
		}
		return das.filter(daIn => daIn.currencyCode === currencyCode);
	}
);

export const getActiveStatusDaSet = createSelector(
	getDaSets,
	sets => sets.find(set => set.status === DaSetStatus.ACTIVE) // there can be only one active set in a time
);

export const getExistsActiveDaSet = createSelector(
	getActiveStatusDaSet,
	(activeSet: DaSet | null) => !!activeSet
);

export const getDasFromFutureSet = createSelector(
	getAllDas,
	getDaSets,
	(das, daSets) => getDasInDaSetsByStatus(das, daSets, [DaSetStatus.FUTURE])
);

export const makeGetDasFromActiveStatusDaSet = (daType: DaType) =>
	createSelector(
		daType === DaType.IN ? getDAsIn : getDAsOut,
		getDaSets,
		(das, daSets) => getDasInDaSetsByStatus(das, daSets, [DaSetStatus.ACTIVE])
	);

export const getIsReopenDaSetPending = createSelector(
	getFinanceFetchStatuses,
	({ reopenDaSet }) => getIsLoading(reopenDaSet)
);

export const getAllDaInsHaveBankDetails = createSelector(
	getAccessibleDaIns,
	das => {
		return das.every(da => Boolean(da.bankDetail));
	}
);

export const getEditableDaInsBankDetailsIdsAndDaLabels = createSelector(
	getDAsIn,
	getDaSets,
	(das, daSets) => {
		return das.reduce((acc, da) => {
			if (
				!da.metadata.propertiesToEdit.includes(DAColumnMetadataKey.BANK_DETAILS)
			) {
				return acc;
			}
			const id = da.bankDetail?.id || '';
			const daSet = daSets.find(({ id }) => id === da.disbursementAccountSetId);
			if (id && daSet) {
				acc.push({
					id,
					daLabel: daTitle(daSet.number, da.number, daSet.type)
				});
			}
			return acc;
		}, [] as Array<{ id: string; daLabel: string }>);
	}
);

export const getDALabel = createSelector(
	getDaSets,
	(_state: AppState, da: DisbursementAccount) => da,
	(daSets, da) => {
		const daSet = daSets.find(({ id }) => id === da.disbursementAccountSetId);
		if (!daSet) {
			return;
		}
		return daTitle(daSet.number, da.number, daSet.type);
	}
);

export const getEditableDaInsBankDetails = createSelector(
	getBankAccountsById,
	getEditableDaInsBankDetailsIdsAndDaLabels,
	(bankAccountsById, bankAccounts) =>
		bankAccounts.map(
			({ id, daLabel }) =>
				({ ...bankAccountsById[id], daLabel } as BankAccount & {
					daLabel: string;
				})
		)
);

export const getDAStatusHistoryById = (state: AppState, daId: string) =>
	state.finance.daStatusHistoryById[daId] || [];

export const getDAStatusHistoryByIdFetchStatus = (
	state: AppState,
	daId: string
) =>
	state.finance.fetchStatuses.retrieveDAStatusHistoryById[daId] ||
	FetchStatus.IDLE;

export const getDaInByServiceId = (
	state: AppState,
	serviceId?: string | null
) => {
	if (!serviceId) {
		return;
	}
	const service = getServiceById(state, serviceId);
	return service ? getDaById(state, service.daInId) : undefined;
};

export const getSourceCurrency = createSelector(
	(_state: AppState, service: PortJobService) => service,
	(state: AppState, service: PortJobService) =>
		getDaById(state, service.daInId),
	(state: AppState, service: PortJobService) =>
		getDaInByServiceId(state, service.parentServiceId),
	(service, daIn, parentDaIn) => {
		if (
			parentDaIn &&
			service.parentServiceId &&
			service.modeCode !== 'Converted'
		) {
			return parentDaIn.currencyCode;
		}
		return daIn
			? daIn.currencyCode
			: service.daInCurrencyCode || service.currencyCode;
	}
);

export const getDaSetByDaOutId = (state: AppState, daOutId: string) => {
	const daOut = getDaById(state, daOutId);
	const daSet = daOut && getDASetById(state, daOut.disbursementAccountSetId);

	return daSet;
};
