import { DeleteCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import actionCreator from '../savingsActionCreator';

const DELETE_COST_SAVINGS = 'DELETE_COST_SAVINGS';

export const deleteCostSavingsAsync = actionCreator.async<
	DeleteCostSavingsRequest,
	undefined,
	Error
>(DELETE_COST_SAVINGS);

export const deleteCostSavings = actionCreator<DeleteCostSavingsRequest>(
	DELETE_COST_SAVINGS
);
