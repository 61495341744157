import { Success } from 'typescript-fsa';
import { TemplatesState } from '../templatesState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrieveTemplateRequest,
	TemplateContent
} from 'services/api/templates/templateServiceTypes';

export function onRetrieveTemplateStarted(
	state: TemplatesState
): TemplatesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplate: FetchStatus.PENDING
		}
	};
}

export function onRetrieveTemplateSuccess(
	state: TemplatesState,
	action: Success<RetrieveTemplateRequest, TemplateContent>
): TemplatesState {
	const { result } = action;

	return {
		...state,
		current: result,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplate: FetchStatus.SUCCESS
		}
	};
}
