import { SagaIterator } from 'redux-saga';
import { fork, cancel, put, take, delay } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	retrievePortCallSections,
	retrievePortCallSectionsCycleStart,
	retrievePortCallSectionsCycleStop,
	retrievePortCallSectionsAsync
} from '../actions';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import {
	setNotificationActionTypeToNotification,
	unsetNotificationActionTypeToDisplayType
} from 'store/notifications/actions';

const worker = function*({
	payload: portCallId
}: Action<string>): SagaIterator {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield put(
			retrievePortCallSections({
				portCallId,
				isSilent: true
			})
		);
	}
};

export default function*() {
	while (true) {
		const action = yield take(retrievePortCallSectionsCycleStart);
		yield take(retrievePortCallSectionsAsync.done);
		yield put(
			setNotificationActionTypeToNotification([
				retrievePortCallSectionsAsync.type
			])
		);
		const pollPortCallSectionsTask = yield fork(worker, action);
		yield take(retrievePortCallSectionsCycleStop);
		yield cancel(pollPortCallSectionsTask);
		yield put(
			unsetNotificationActionTypeToDisplayType([
				retrievePortCallSectionsAsync.type
			])
		);
	}
}
