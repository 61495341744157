import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';
import { RetrieveThreadTagsResponse } from 'services/api/threads/threadsServiceTypes';

export const onRetrieveThreadTagsSuccess = (
	state: ThreadsState,
	{ result }: Success<null, RetrieveThreadTagsResponse>
): ThreadsState => ({
	...state,
	threadTags: result.elements
});

export const onRetrieveThreadTags = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	null,
	RetrieveThreadTagsResponse
>('fetchStatuses', 'retrievingThreadTags', {
	doneReducer: onRetrieveThreadTagsSuccess
});
