import axios from 'axios';
import {
	all,
	fork,
	take,
	call,
	put,
	race,
	select,
	delay
} from 'redux-saga/effects';
import history from 'services/history';
import config from 'services/config';
import { SystemStatus, SystemStatusType } from '../commonTypes';
import {
	startPollingSystemStatus,
	retrieveSystemStatus,
	retrieveSystemStatusAsync as async
} from '../actions';
import { getSystemStatusType } from '../selectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';

function* poll() {
	while (true) {
		yield put(retrieveSystemStatus());

		const currentStatus = yield select(getSystemStatusType);

		const { done } = yield race({
			done: take(async.done),
			failed: take(async.failed)
		});

		if (done) {
			const { status } = done.payload.result;

			// reload in case the system is back to normal
			if (
				currentStatus === SystemStatusType.UNDER_MAINTENANCE &&
				status === SystemStatusType.NORMAL
			) {
				history.push(history.location.pathname);
			}
		}
		yield delay(DEFAULT_POLLING_INTERVAL);
	}
}

function* pollSystemStatusWatcher() {
	while (true) {
		yield take(startPollingSystemStatus.type);
		yield call(poll);
	}
}

const retrieveSystemStatusWatcher = makeTakeLatestWatcher<
	null,
	SystemStatus,
	Error
>({
	api: () => axios.get<SystemStatus>(`${config.statusBaseUrl}/status.json`),
	async
});

export default function*() {
	yield all([fork(pollSystemStatusWatcher), fork(retrieveSystemStatusWatcher)]);
}
