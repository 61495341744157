import React from 'react';
import { connect } from 'react-redux';

import { Table } from 'components/antd';
import OperationTable from '../../../OperationTable/OperationTable';

import { BunkeringOperationGrade } from 'services/api/portJobs/portJobsServiceTypes';
import { ColumnProps } from 'components/antd/Table/TableTypes';

import { getDataSource } from './GradeLinesGridSelectors';
import columns from './GradeLinesGridColumns';
import { AppState } from 'store-types';

interface DataSourceItem {
	grade: string;
	quantity: number;
	unitOfMeasurement: string;
}
export type Column = ColumnProps<DataSourceItem>;
export interface GradeLinesGridProps {
	data: BunkeringOperationGrade[];
	dataSource: DataSourceItem[];
}

const GradeLinesGrid = (props: GradeLinesGridProps) => (
	<OperationTable>
		<Table
			dataSource={props.dataSource}
			columns={columns}
			rowKey={(_record: object, index: number) => index.toString()}
		/>
	</OperationTable>
);

export default connect(
	(state: AppState, ownProps: Pick<GradeLinesGridProps, 'data'>) => ({
		dataSource: getDataSource(state, ownProps.data)
	})
)(GradeLinesGrid);
