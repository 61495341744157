import { WizardFormState } from '../wizardFormState';
import { toNumber } from 'lodash';
import { WizardFormSetStartPage } from '../actions/setStartPage';
export const onSetStartPage = (
	state: WizardFormState,
	action: WizardFormSetStartPage
): WizardFormState => {
	const { name, startPage } = action;
	const formName: string = name ? name : state.activeWizardFormId;
	/** Automaticaly validate pages that are below the startPage (unreachable by the user) */
	const pages = state.forms[formName].pages;
	const validPages = Object.keys(pages).reduce((acc, key) => {
		acc[key] = {
			...pages[key],
			valid: toNumber(key) < startPage
		};
		return acc;
	}, {});
	const form = {
		...state.forms[formName],
		startPage,
		activePage: startPage,
		pages: validPages
	};
	return {
		...state,
		forms: {
			...state.forms,
			[formName]: form
		}
	};
};
