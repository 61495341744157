import { first } from 'lodash';
import runValidations, { arrayMin } from 'utils/validations';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import validateCharterers from './Charterers/validate';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validate = (values: FormData): any => {
	return {
		cargoes: {
			_error: first(runValidations(values.cargoes, [arrayMin(1)]))
		},
		charterers: validateCharterers(values)
	};
};

export default validate;
