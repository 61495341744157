import { initialState, AuthState } from './authState';

import {
	clearAuth,
	setAuth,
	startAuth,
	stopAuth,
	setAuthCanActAs,
	setAuthActingAs,
	setAuthActAsConfig,
	setAuthActAsOptions,
	resetAuthActAs,
	retrieveAuthUserAsync,
	triggerActAsHistoryBlockRegistration
} from './actions';
import {
	onSetAuthCanActAs,
	onSetAuthActingAs,
	onSetAuthActAsConfig,
	onSetAuthActAsOptions,
	onResetAuthActAs,
	onRetrieveAuthUser,
	onTriggerActAsHistoryBlockRegistration
} from './reducers';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export default reducerWithInitialState<AuthState>(initialState)
	.case(startAuth, state => ({
		...state,
		isAuthenticating: true
	}))
	.case(setAuth, (state, payload) => ({
		...state,
		auth: payload
	}))
	.case(retrieveAuthUserAsync.started, onRetrieveAuthUser.started)
	.case(retrieveAuthUserAsync.done, onRetrieveAuthUser.done)
	.case(retrieveAuthUserAsync.failed, onRetrieveAuthUser.failed)
	.case(stopAuth, state => ({
		...state,
		isAuthenticating: false
	}))
	.case(clearAuth, state => ({
		...state,
		auth: null,
		authUser: null
	}))
	.case(setAuthCanActAs, onSetAuthCanActAs)
	.case(setAuthActingAs, onSetAuthActingAs)
	.case(setAuthActAsConfig, onSetAuthActAsConfig)
	.case(setAuthActAsOptions, onSetAuthActAsOptions)
	.cases([clearAuth, resetAuthActAs], onResetAuthActAs)
	.case(
		triggerActAsHistoryBlockRegistration,
		onTriggerActAsHistoryBlockRegistration
	);
