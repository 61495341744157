import * as React from 'react';
import Charterers, {
	OperationWithCharterersDisplay
} from '../Charterers/Charterers';

export default ({
	operation
}: {
	operation: OperationWithCharterersDisplay;
}) => <Charterers operation={operation} />;
