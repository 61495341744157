import { createSelector } from 'reselect';
import { getPortJobByCode } from 'store/portJobs/selectors';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	PortJob,
	ClassificationCode,
	PortJobTypeCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { DEFAULT_NO_VALUE_PLACEHOLDER } from 'app-constants';
import { Column, ColumnEntry, ColumnEntryKey } from './InfoTypes';

export const getColumns = createSelector(
	getPortJobByCode,
	getUserType,
	(job: PortJob, userType: UserType): Column[] =>
		job
			? [
					{ key: ColumnEntryKey.CONTACT, columns: getContactColumn(job) },
					{ key: ColumnEntryKey.AGENT, columns: getAgentColumn(job) },
					{ key: ColumnEntryKey.CUSTOMER, columns: getCustomerInfoColumn(job) },
					{
						key: ColumnEntryKey.PAYMENT,
						columns: getPaymentColumn(job, userType)
					}
			  ]
			: []
);

export const getContactColumn = (job: PortJob) => {
	if (job.jobType === PortJobTypeCode.APPOINTMENT) {
		const fields = [
			{ title: 'Appointer:', value: job.appointerCompany.name },
			{
				title: 'Contact Person 1:',
				value: job.contact1 ? job.contact1.name : '--'
			},
			{
				title: 'Contact Phone:',
				value: job.contact1 ? job.contact1.phone : '--'
			}
		];
		if (job.contact2 && job.contact2.name) {
			fields.push(
				{ title: 'Contact Person 2:', value: job.contact2.name },
				{ title: 'Contact Phone:', value: job.contact2.phone }
			);
		}
		return fields;
	} else {
		const fields = [
			{
				title:
					job.jobType === PortJobTypeCode.NOMINATION
						? 'Nominator:'
						: 'Charterer:',
				value: job.nominatorCompany ? job.nominatorCompany.name : ''
			},
			{
				title: 'Contact Person 1:',
				value: job.contact1 ? job.contact1.name : '--'
			},
			{
				title: 'Contact Phone:',
				value: job.contact1 ? job.contact1.phone : '--'
			}
		];
		if (job.contact2 && job.contact2.name) {
			fields.push(
				{ title: 'Contact Person 2:', value: job.contact2.name },
				{
					title: 'Contact Phone:',
					value: job.contact2.phone ? job.contact2.phone : '--'
				}
			);
		}
		return fields;
	}
};

export const getAgentColumn = (job: PortJob) => {
	const {
		performingAgentCompany,
		controllingAgentCompany,
		portJobCompanies,
		classification
	} = job;
	const perfAgent = {
		title: 'Performing Agent:',
		value: performingAgentCompany.name,
		details: portJobCompanies.find(
			company => company.id === performingAgentCompany.id
		)?.details
	};
	const contrAgent = controllingAgentCompany
		? {
				title: 'Controlling Agent:',
				value: controllingAgentCompany.name,
				details: portJobCompanies.find(
					company => company.id === controllingAgentCompany.id
				)?.details
		  }
		: undefined;
	const agentType =
		classification.code !== ClassificationCode.C
			? { title: 'Agent Type:', value: job.agentType }
			: undefined;
	const agentRole = { title: 'Agent Role:', value: job.agentRole };

	return [perfAgent, contrAgent, agentType, agentRole].filter(
		field => field
	) as ColumnEntry[];
};

export const getCustomerInfoColumn = (job: PortJob) => {
	const classificationCode = job.classification.code;
	const fields = [];
	if (job.hubPrincipalCompany) {
		const title =
			classificationCode !== ClassificationCode.C
				? 'HUB Principal:'
				: 'CLS Principal:';
		fields.push({ title, value: job.hubPrincipalCompany.name });
	}
	fields.push({
		title: 'Customer Ref:',
		value: job.customerReference?.trim() || '--'
	});
	if (
		job.jobType === 'Appointment' &&
		job.nominatorCompany &&
		classificationCode !== ClassificationCode.C
	) {
		fields.push({ title: 'Nominator:', value: job.nominatorCompany.name });
	}
	if (
		(job.jobType === PortJobTypeCode.NOMINATION ||
			job.jobType === PortJobTypeCode.CHARTERER_OVERSIGHT) &&
		job.nominatorCompany &&
		classificationCode !== ClassificationCode.C
	) {
		fields.push({
			title: 'Appointer:',
			value: job.appointerCompany ? job.appointerCompany.name : '--'
		});
	}
	return fields;
};

export const getPaymentColumn = (job: PortJob, userType: UserType) => {
	const fields = [];
	if (job.classification.code !== ClassificationCode.C) {
		const value =
			(job.payingPartyCompany && job.payingPartyCompany.name) ||
			DEFAULT_NO_VALUE_PLACEHOLDER;
		fields.push({ title: 'Paying Party:', value });
	}
	if (userType === UserType.HUB) {
		fields.push({
			title: 'Job Estimate DA IN (USD):',
			value: !job.financeEnabled
				? 'Finance not enabled'
				: job.jobEstimateDaInUsd || DEFAULT_NO_VALUE_PLACEHOLDER,
			isFormattedValue: job.financeEnabled
		});
		fields.push({
			title: 'Job Estimate DA OUT (USD):',
			value: !job.financeEnabled
				? 'Finance not enabled'
				: job.jobEstimateDaOutUsd || DEFAULT_NO_VALUE_PLACEHOLDER,
			isFormattedValue: job.financeEnabled
		});
	} else {
		let value;
		if (userType === UserType.LPA) {
			value = job.jobEstimateDaInUsd;
		}
		if (userType === UserType.PRINCIPAL) {
			value = job.jobEstimateDaOutUsd;
		}
		fields.push({
			title: 'Job Estimate (USD):',
			value: !job.financeEnabled
				? 'Finance not enabled'
				: value || DEFAULT_NO_VALUE_PLACEHOLDER,
			isFormattedValue: job.financeEnabled
		});
	}

	return fields;
};
