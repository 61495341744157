import { UserStatus } from 'services/api/users/userServiceTypes';
import { TagType } from 'components/antd/Tag/Tag';

export const USER_STATUS_LABEL_MAP = {
	[UserStatus.ACTIVE]: 'Active',
	[UserStatus.DISABLED]: 'Disabled',
	[UserStatus.PERM_LOCKED]: 'Permanent Lock',
	[UserStatus.REGISTRATION_PENDING]: 'Registration Pending',
	[UserStatus.VERIFICATION_PENDING]: 'Verification Pending',
	[UserStatus.TEMP_LOCKED]: 'Temporary Lock'
};

export const USER_STATUS_TAG_TYPE: { [key: string]: TagType } = {
	[UserStatus.ACTIVE]: 'success',
	[UserStatus.DISABLED]: 'error',
	[UserStatus.PERM_LOCKED]: 'error',
	[UserStatus.REGISTRATION_PENDING]: 'warning',
	[UserStatus.VERIFICATION_PENDING]: 'warning',
	[UserStatus.TEMP_LOCKED]: 'error'
};
