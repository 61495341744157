import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { OidcUser } from 'store/auth/authTypes';

export const getAuth = (state: AppState) => state.auth.auth;
export const getAuthProfile = (state: AppState) =>
	state.auth.auth && state.auth.auth.profile;

export const isUserLoggedIn = (auth: OidcUser | null) =>
	auth && auth.expired !== true;

export const isAuthenticationInProgressSelector = (state: AppState) =>
	state.auth.isAuthenticating;

export const getRetrieveAuthUserFetchStatus = (state: AppState) =>
	state.auth.fetchStatuses.retrieveAuthUser;

export const isUserAuthenticated = createSelector(getAuth, isUserLoggedIn);
export const getAuthAccessToken = createSelector(getAuth, auth =>
	auth ? auth.access_token : ''
);
const getActAs = (state: AppState) => state.auth.actAs;
export const getCanAuthActAs = (state: AppState) => getActAs(state).canActAs;
export const getAuthActAs = (state: AppState) => getActAs(state).value;
export const getAuthActAsConfig = (state: AppState) => getActAs(state).config;
export const getAuthActAsOptions = (state: AppState) => getActAs(state).options;
export const getActAsHistoryBlockTriggerCounter = (state: AppState) =>
	getActAs(state).historyBlockTriggerCounter;
