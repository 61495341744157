import { RetrieveServiceErrorsResponse } from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_SERVICE_ERRORS = 'RETRIEVE_SERVICE_ERRORS';
export const retrieveServiceErrorsAsync = actionCreator.async<
	null,
	RetrieveServiceErrorsResponse,
	Error
>(RETRIEVE_SERVICE_ERRORS);

export const retrieveServiceErrors = actionCreator(RETRIEVE_SERVICE_ERRORS);
