import {
	RetrievePortByIdRequest,
	RetrievePortByIdResponse
} from 'services/api/ports/portsServiceTypes';
import actionCreator from '../actionCreator';

const RETRIEVE_PORT_BY_ID = 'RETRIEVE_PORT_BY_ID';

export const retrievePortByIdAsync = actionCreator.async<
	RetrievePortByIdRequest,
	RetrievePortByIdResponse,
	Error
>(RETRIEVE_PORT_BY_ID);

export const retrievePortById = actionCreator<RetrievePortByIdRequest>(
	RETRIEVE_PORT_BY_ID
);
