import { all, fork } from 'redux-saga/effects';
import retrieveCommoditiesSagaWatcher from './retrieveCommoditiesSaga';
import retrieveMainCommoditiesSagaWatcher from './retrieveMainCommoditiesSaga';

export default function*() {
	yield all([
		fork(retrieveCommoditiesSagaWatcher),
		fork(retrieveMainCommoditiesSagaWatcher)
	]);
}
