import { getCustomFormattedValue } from './../../../utils/numbers/getCustomFormattedValue';
import {
	VesselProgrammeState,
	MergeOperationUnitPayload
} from 'store/vesselProgramme/vesselProgrammeState';

export const onOpenMergeOperationUnitModal = (
	state: VesselProgrammeState,
	{
		operationUnitId
	}: {
		operationUnitId: string;
	}
): VesselProgrammeState => ({
	...state,
	mergeModalOperationUnitId: operationUnitId
});
export const onCancelMergeOperationUnitModal = (
	state: VesselProgrammeState
): VesselProgrammeState => ({
	...state,
	mergeModalOperationUnitId: undefined
});

const getStateWithMergedParentCommodity = (childOperationUnitId: string) => (
	state: VesselProgrammeState
) => {
	const childOperation = {
		...state.operationUnitsById[childOperationUnitId]
	};
	const childCommodityQuantity = childOperation.commodityQuantity;
	const { parentOperationUnitId } = childOperation;
	if (!parentOperationUnitId) {
		return state;
	}
	const parentOperation = {
		...state.operationUnitsById[parentOperationUnitId]
	};
	parentOperation.commodityQuantity = Number(
		getCustomFormattedValue(
			parentOperation.commodityQuantity + childCommodityQuantity,
			4
		)
	);

	// removing child property from operationUnitsById (and from rotationStepsById if allocated)
	const operationUnitsById = {
		...state.operationUnitsById,
		[parentOperationUnitId]: parentOperation
	};

	const rotationStepId = operationUnitsById[childOperationUnitId].allocatedId;
	const rotationStepsById = rotationStepId
		? {
				...state.rotationStepsById,
				[rotationStepId]: {
					...state.rotationStepsById[rotationStepId],
					units: state.rotationStepsById[rotationStepId].units.filter(
						unit => unit !== childOperationUnitId
					)
				}
		  }
		: state.rotationStepsById;

	// TODO: probably need refactoring to prevent mutating state;
	delete operationUnitsById[childOperationUnitId];

	// removing split item in splittedUnits array
	const splittedUnitsByParentUnitId = state.splittedUnitsByParentUnitId;
	splittedUnitsByParentUnitId[
		parentOperationUnitId
	] = splittedUnitsByParentUnitId[parentOperationUnitId].filter(
		id => id !== childOperationUnitId
	);
	if (splittedUnitsByParentUnitId[parentOperationUnitId].length === 0) {
		delete parentOperation.splitIndex;
		delete splittedUnitsByParentUnitId[parentOperationUnitId];
	}

	return {
		...state,
		edited: true,
		operationUnitsById,
		splittedUnitsByParentUnitId,
		rotationStepsById
	};
};

export const onMergeOperationUnit = (
	state: VesselProgrammeState,
	{ childOperationUnitId }: MergeOperationUnitPayload
): VesselProgrammeState =>
	getStateWithMergedParentCommodity(childOperationUnitId)(state);
