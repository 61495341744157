import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { AdministrationTab } from '../AdministrationTypes';

export enum ConfigurationTabKey {
	VESSEL_TYPES = 'vesselTypes',
	VESSELS = 'vessels',
	GENERAL_DETAILS = 'generalDetails',
	FILES = 'files',
	PREFERRED_AGENTS = 'preferredAgents',
	EMAIL_FRAGMENTS = 'emailFragments',
	PRINCIPAL_SPECIFIC_SERVICES = 'serviceUpload',
	SERVICES = 'serviceMapping',
	SERVICE_CATEGORIES = 'service-categories',
	BENCHMARK = 'benchmark',
	FEEDBACK_MANAGEMENT = 'feedbackManagement'
}

export interface ConfigurationTab extends AdministrationTab {
	key: ConfigurationTabKey;
}

export const ConfigurationTabsSet: ConfigurationTab[] = [
	{
		tab: 'Vessel types',
		key: ConfigurationTabKey.VESSEL_TYPES,
		permissionCode: [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
			PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS
		]
	},
	{
		tab: 'Vessels',
		key: ConfigurationTabKey.VESSELS,
		permissionCode: [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
			PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS
		]
	},
	{
		tab: 'General Details',
		key: ConfigurationTabKey.GENERAL_DETAILS,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	},
	{
		tab: 'Files',
		key: ConfigurationTabKey.FILES,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	},
	{
		tab: 'Preferred Agents',
		key: ConfigurationTabKey.PREFERRED_AGENTS,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	},
	{
		tab: 'Messaging',
		key: ConfigurationTabKey.EMAIL_FRAGMENTS,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	},
	{
		tab: 'Service Categories',
		key: ConfigurationTabKey.SERVICE_CATEGORIES,
		permissionCode: [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
			PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES
		]
	},
	{
		tab: 'Service Upload',
		key: ConfigurationTabKey.PRINCIPAL_SPECIFIC_SERVICES,
		permissionCode: [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
			PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES
		]
	},
	{
		tab: 'Service Mapping',
		key: ConfigurationTabKey.SERVICES,
		permissionCode: [
			PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
			PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES
		]
	},
	{
		tab: 'Benchmark',
		key: ConfigurationTabKey.BENCHMARK,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	},
	{
		tab: 'Feedback Management',
		key: ConfigurationTabKey.FEEDBACK_MANAGEMENT,
		permissionCode: [PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS]
	}
];

export interface ConfigurationParams {
	configurationTabId?: ConfigurationTabKey;
}

export const ADD_COMPANY_VESSEL_MODAL = 'AddCompanyVesselModal';

export interface ConfigurationFormBaseProps {
	isUpdating: boolean;
	onPristineChange: (isPristine: boolean) => void;
}
