import {
	CancelServiceTransactionPayload,
	CancelServiceTransactionRequest
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'CANCEL_SERVICE_TRANSACTION';

export const cancelServiceTransactionAsync = actionCreator.async<
	CancelServiceTransactionRequest,
	null,
	Error
>(ACTION_NAME);

export const cancelServiceTransaction = actionCreator<
	CancelServiceTransactionPayload
>(ACTION_NAME);
