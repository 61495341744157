import { ModalProps } from 'components/antd/Modal/Modal';
import { AlertProps } from 'components/antd/Alert/Alert';
import actionCreatorFactory from 'typescript-fsa';
import { ReactNode } from 'react';

const actionCreator = actionCreatorFactory('modals');

interface ModalData
	extends Pick<
		ModalProps,
		| 'cancelText'
		| 'okText'
		| 'cancelProps'
		| 'okProps'
		| 'okButton'
		| 'cancelButton'
		| 'hideCancelButton'
	> {
	title?: string;
	description: ReactNode;
}

type ModalType = 'confirm' | 'confirmLeavePage';

export interface ModalParams {
	name: string;
	type?: ModalType;
	alertType?: AlertProps['type'];
	data?: ModalData;
	returnUrl?: string; // used by `confirmLeavePage`
}

export interface CloseModalParams extends ModalParams {
	isConfirmed?: boolean;
}

export type OpenModalAction = ModalParams | string;
export type CloseModalAction = CloseModalParams | string;

export const openModal = actionCreator<OpenModalAction>('OPEN_MODAL');
export const closeModal = actionCreator<CloseModalAction>('CLOSE_MODAL');

export const confirmModal = actionCreator('CONFIRM_MODAL'); // action - helper without any reducer attached
export const cancelModal = actionCreator('CANCEL_MODAL'); // action - helper without any reducer attached

export const closeAllModals = actionCreator('CLOSE_ALL_MODALS');

export const closeByCancel = (name: string) =>
	closeModal({ name, isConfirmed: false });
export const closeByConfirm = (name: string) =>
	closeModal({ name, isConfirmed: true });
