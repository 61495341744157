import { EntityCode, Entity } from 'app-types';
import { ResultCollection } from 'services/api/apiTypes';
import { MainCommodityCode } from 'services/api/commodities/commoditiesServiceTypes';

export enum Template {
	JOB_APPOINTMENT = 'JobAppointment',
	EMAIL_TO_MASTER = 'EmailToMaster',
	JOB_APPOINTMENT_FROM_NOMINATION = 'JobNomination',
	EMAIL_TO_MASTER_FOR_NOMINATION = 'EmailToMasterForNomination'
}

// Companies
// ---------------------
interface CompanyTemplateBaseRequest {
	companyId: string;
	templates: Template[];
}

export type RetrieveCompanyTemplatesRequest = CompanyTemplateBaseRequest;
export type RetrieveCompanyTemplatesResponse = CompanyTemplate[];

/*
	As part of OW-13113(UD_10) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

// export interface CompanyTemplateWithExtendFunctionality {
// 	extendedMessagingOption: string;
// 	list: CompanyTemplate[];
// }

interface CompanyTemplateKeyValue {
	key: string;
	value: string;
}

interface CompanyTemplateFragmentOrFlag extends CompanyTemplateKeyValue {
	label: string;
}

interface CompanyTemplateOption extends CompanyTemplateFragmentOrFlag {
	values: EntityCode[];
}

export interface CompanyTemplate {
	companyId: string;
	flags: CompanyTemplateFragmentOrFlag[];
	fragments: CompanyTemplateFragmentOrFlag[];
	options: CompanyTemplateOption[];
	template: EntityCode<Template>;
}

export interface UpdateCompanyTemplatesRequest {
	companyId: string;
	data: UpdateCompanyTemplateRequestData;
}

export type UpdateCompanyTemplateRequestData = {
	flags?: CompanyTemplateKeyValue[];
	fragments: CompanyTemplateKeyValue[];
	options: CompanyTemplateKeyValue[];
	template: Template;
}[];

export interface RetrieveCompanyTemplateFilesRequest {
	companyId: string;
}

export type RetrieveCompanyTemplateFilesResponse = ResultCollection<
	CompanyTemplateFile
>;

export interface DeleteCompanyTemplateFileRequest {
	companyId: string;
	fileId: string;
}

export interface AddCompanyTemplateFileRequestData {
	principals: Array<Pick<Entity, 'id'>>;
	ports: Array<Pick<Entity, 'id'>>;
	mainCommodities: string[];
	messageTypes: string[];
	documentId: string;
}

export interface AddCompanyTemplateFileRequest {
	companyId: string;
	data: AddCompanyTemplateFileRequestData;
}

export type AddCompanyTemplateFileResponse = CompanyTemplateFile;
export type UpdateCompanyTemplateFileResponse = CompanyTemplateFile;

export type UpdateCompanyTemplateFileRequest = AddCompanyTemplateFileRequest & {
	fileId: string;
};

type PortEntity = Entity & { code: string };

export interface CompanyTemplateFile {
	createdBy: string;
	createdOnUtc: string;
	fileName: string;
	id: string;
	documentId: string;
	updatedBy: string;
	updatedOnUtc: string;
	userName: string;
	principals: Entity[];
	ports: PortEntity[];
	mainCommodities: MainCommodityCode[];
	messageTypes: CompanyTemplateFileMessageType[];
}

export enum CompanyTemplateFileMessageType {
	APPOINTMENT = 'Appointment',
	MASTERS_FEEDBACK_REQUEST = 'MastersFeedbackRequest',
	MESSAGE_TO_MASTER = 'MessageToMaster',
	APPOINTMENT_FROM_NOMINATION = 'AppointmentFromNomination'
}
