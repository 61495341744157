import actionCreator from '../portJobsActionCreator';
import {
	CancelPortJobParams,
	CancelPortJobRequest
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'CANCEL_JOB';

export const cancelPortJobAsync = actionCreator.async<
	CancelPortJobRequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	done: {
		description: 'Port Job has been successfully cancelled.'
	}
});

export const cancelPortJob = actionCreator<CancelPortJobParams>(ACTION_NAME);
