import { ThreadStatus } from 'services/api/threads/threadsServiceTypes';
import { DEFAULT_IGNORED_CODES } from 'app-constants';

export const THREAD_STATUS_OPTIONS = [
	ThreadStatus.OPEN,
	ThreadStatus.QUERIED,
	ThreadStatus.RESOLVED
];

const ThreadStatusOpen = {
	value: ThreadStatus.OPEN,
	label: ThreadStatus.OPEN
};

const ThreadStatusQueried = {
	value: ThreadStatus.QUERIED,
	label: ThreadStatus.QUERIED
};

const ThreadStatusResolved = {
	value: ThreadStatus.RESOLVED,
	label: ThreadStatus.RESOLVED
};

export const THREAD_STATUSES_LIST = [
	ThreadStatusOpen,
	ThreadStatusQueried,
	ThreadStatusResolved
];

export const NEW_THREAD_ID = 'new-thread';

export const THREAD_IGNORED_CODES = [...DEFAULT_IGNORED_CODES, 403];
