import Api from 'services/api';
import { select, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { sendPrefundingsRequestAsync as async } from '../actions';
import { PrefundingsRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';
import {
	getActivePortCall,
	getActivePortCallId
} from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getValues } from 'store/form/selectors';
import { AppState } from 'store-types';
import {
	SendFinanceFormFields,
	SEND_FINANCE_FORM
} from 'sections/PortCall/Finance/Header/SendFinanceModal/SendFinanceModalTypes';
import { prefundRequested } from 'sections/PortCall/Finance/Header/SendFinanceModal/SendFinanceModal.func';
import { retrieveFinance } from 'store/finance/actions';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';

const api = Api.FinanceFundings.sendPrefundingsRequest;

export default makeTakeLatestWatcher<Request, null, Error>({
	api,
	async,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const portCall = yield select(getActivePortCall);
		const formValues = yield select((state: AppState) =>
			getValues<FormData>(state, SEND_FINANCE_FORM)
		);

		const request: Request = {
			portCallId,
			jobCode,
			selectedStatus: formValues.jobStatus,
			sendPrefunding:
				formValues.isPrefundRequested ===
				SendFinanceFormFields.REQUEST_PREFUND_YES,
			portName: portCall.port.name,
			vesselName: portCall.vessel.name,
			fundingRequestUpdateFundingRequestList: prefundRequested(
				formValues.fundingTable
			)
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
	}
});
