import actionCreator from 'store/finance/financeActionCreator';
import {
	UpdatePrefundingStatusPayload,
	UpdatePrefundingStatusRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';

const UPDATE_PREFUNDING_STATUS = 'UPDATE_PREFUNDING_STATUS';

export const updatePrefundingStatusAsync = actionCreator.async<
	UpdatePrefundingStatusRequest,
	UpdatePrefundingStatusPayload,
	Error
>(UPDATE_PREFUNDING_STATUS);

export const updatePrefundingStatus = actionCreator<
	UpdatePrefundingStatusPayload
>(UPDATE_PREFUNDING_STATUS);
