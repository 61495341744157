import actionCreator from '../portCallDocumentsActionCreator';
import {
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const RETRIEVE_REQUIRED_DOCUMENTS = 'RETRIEVE_REQUIRED_DOCUMENTS';
export const retrieveRequiredDocumentsAsync = actionCreator.async<
	RetrieveRequiredDocumentsRequest,
	RetrieveRequiredDocumentsResponse,
	Error
>(RETRIEVE_REQUIRED_DOCUMENTS);

export const retrieveRequiredDocuments = actionCreator<
	RetrieveRequiredDocumentsRequest
>(RETRIEVE_REQUIRED_DOCUMENTS);
