import React, { useCallback } from 'react';
import { DataFetcher } from 'utils/components';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveThread, getThreadFetchStatus } from 'store/thread/selectors';
import { retrieveThreadById, resetThread } from 'store/thread';
import Thread from './Thread';
import { Error404 } from 'components';
import {
	checkForExpand,
	checkForNewTab
} from 'components/PageHeader/PageHeader.func';

interface ThreadRouteProps {
	match: {
		params: {
			threadId: string;
		};
	};
}

function ThreadRoute(props: ThreadRouteProps) {
	const dispatch = useDispatch();
	const { threadId } = props.match.params;
	const newtab = checkForNewTab(window.location.pathname);
	const expand = checkForExpand(window.location.pathname);
	const thread = useSelector(getActiveThread);
	const fetchStatus = useSelector(getThreadFetchStatus);
	const retrieveThread = useCallback(() => {
		dispatch(retrieveThreadById({ threadId }));
	}, [threadId, dispatch]);
	const reset = useCallback(() => {
		dispatch(resetThread());
	}, [dispatch]);
	return (
		<DataFetcher
			dispatch={retrieveThread}
			data={thread}
			fetchStatus={fetchStatus}
			reset={reset}
		>
			{() =>
				thread ? (
					<Thread
						thread={thread}
						isNewTab={!!newtab}
						isPreviewModeEnabled={false}
						isExpand={!!expand}
					/>
				) : (
					<Error404 />
				)
			}
		</DataFetcher>
	);
}

export default ThreadRoute;
