import React from 'react';
import { Modal } from 'components/antd';

interface CancelMergingPortCallsModalProps {
	isModalVisible: boolean;
	onCancel: () => void;
	onOk: () => void;
}

export const CancelMergingPortCallsModal = ({
	isModalVisible,
	onCancel,
	onOk
}: CancelMergingPortCallsModalProps) => (
	<Modal
		visible={isModalVisible}
		cancelText="No"
		okText="Yes"
		onCancel={onCancel}
		onOk={onOk}
	>
		Are you sure you want to cancel the merging process?
	</Modal>
);
