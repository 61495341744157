import {
	put,
	select,
	takeEvery,
	all,
	fork,
	take,
	race,
	cancel,
	call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import {
	setVesselTypesTabMode,
	deleteActiveCompanyVesselType,
	deleteActiveCompanyVesselTypeAsync
} from '../actions';
import { getActiveCompanyId, getVesselTypesTabMode } from '../selectors';
import {
	deleteCompanyVesselType,
	deleteCompanyVesselTypeAsync
} from 'store/companyVesselTypes/actions';
import { VesselTypesMode, VesselTypesTabMode } from '../configurationState';
import {
	retrieveCompanyVessels,
	retrieveCompanyVesselsAsync
} from 'store/companyVessels/actions';
import { getCompanyVesselsByCompanyId } from 'store/companyVessels/selectors';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { openModal, closeModal } from 'store/modals/actions';

function* errorHandler(
	message: string | undefined = 'Error occurred while deleting Vessel Type.'
) {
	yield put(
		deleteActiveCompanyVesselTypeAsync.failed({
			params: null,
			error: new Error(message)
		})
	);
	yield put(setVesselTypesTabMode({ mode: VesselTypesMode.DEFAULT }));
}

const someVesselsInType = (vessels: CompanyVessel[], vesselTypeId: string) =>
	vessels.some(vessel => vessel.companyVesselTypeMapping.id === vesselTypeId);

export function* worker(): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	const tabMode: VesselTypesTabMode = yield select(getVesselTypesTabMode);
	const id = tabMode.vesselTypeId as string;
	// fetching vessels to be up to date
	yield put(retrieveCompanyVessels({ companyId }));
	const { retrieveFailed } = yield race({
		retrieveDone: take(retrieveCompanyVesselsAsync.done),
		retrieveFailed: take(retrieveCompanyVesselsAsync.failed)
	});
	if (retrieveFailed) {
		yield call(errorHandler);
	}
	const vessels: CompanyVessel[] = yield select(
		getCompanyVesselsByCompanyId,
		companyId
	);
	if (someVesselsInType(vessels, id)) {
		yield call(
			errorHandler,
			'Vessel Type can’t be removed as it has real vessels assigned to it.'
		);
		return;
	}
	yield put(openModal(VesselTypesMode.DELETE)); // open confirmation modal
	yield take(deleteActiveCompanyVesselType); // wait for delete action
	yield put(closeModal(VesselTypesMode.DELETE)); // close and start perfoming the deletion
	yield put(deleteActiveCompanyVesselTypeAsync.started(null));
	yield put(deleteCompanyVesselType({ companyId, id }));
	const { done } = yield race({
		done: take(deleteCompanyVesselTypeAsync.done),
		failed: take(deleteCompanyVesselTypeAsync.failed)
	});

	if (done) {
		yield put(
			deleteActiveCompanyVesselTypeAsync.done({
				params: null,
				result: null,
				response: null
			})
		);
		yield put(setVesselTypesTabMode({ mode: VesselTypesMode.DEFAULT }));
	}
}

function* proceedWhenDeleteMode() {
	const { mode }: VesselTypesTabMode = yield select(getVesselTypesTabMode);
	if (mode === VesselTypesMode.DELETE) {
		const deleteProcess = yield fork(worker); // start delete process
		yield take(setVesselTypesTabMode); // wait for any change in tab mode
		yield put(closeModal(VesselTypesMode.DELETE));
		yield cancel(deleteProcess); // stop delete process for any change ( going to different mode )
	}
}

function* watcher(): SagaIterator {
	yield takeEvery(setVesselTypesTabMode, proceedWhenDeleteMode);
}

export default function*() {
	yield all([fork(watcher)]);
}
