import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';

import AutocompleteEntity, {
	AutocompleteEntityProps
} from '../AutocompleteEntity';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';

interface AutocompleteApprovalGroupsProps extends AutocompleteEntityProps {
	portCallId: string;
	jobCode: string;
}

class AutocompleteApprovalGroups extends React.Component<
	AutocompleteApprovalGroupsProps
> {
	onSearch = (searchTerm: string) =>
		Api.Finance.searchApprovalGroupsForAutocomplete({
			portCallId: this.props.portCallId,
			jobCode: this.props.jobCode,
			searchTerm
		});

	render() {
		return <AutocompleteEntity {...this.props} onSearch={this.onSearch} />;
	}
}

export default connect((state: AppState) => ({
	portCallId: getActivePortCallId(state),
	jobCode: getActivePortJobCode(state)
}))(AutocompleteApprovalGroups);
