import { retrievePrincipalServicesAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
} from 'services/api/services/servicesServiceTypes';
export default makeTakeLatestWatcher<
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse,
	Error
>({
	api: Api.Services.retrievePrincipalServices,
	async
});
