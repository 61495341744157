import React from 'react';

import createDocumentViewerForm, {
	ContextualizationFormFormDataBase
} from 'sections/DocumentViewer/DocumentViewerForm';

import {
	FormData,
	FormField,
	CONTEXTUALIZATION_FORM_ID
} from './ContextualizationFormTypes';

import validate from './validate';
import FormPagesFieldArray, {
	FieldArrayPagesProps
} from './FormPages/FormPages';

export interface ContextualizationFormProps extends FieldArrayPagesProps {
	pages: number;
	onValidChange: (valid: boolean) => void;
}

const DocumentViewerForm = createDocumentViewerForm<
	FormData & ContextualizationFormFormDataBase,
	ContextualizationFormProps,
	string | boolean
>({
	form: CONTEXTUALIZATION_FORM_ID,
	validate
});

const ContextualizationForm: React.SFC<ContextualizationFormProps> = ({
	pages,
	onValidChange,
	activePage,
	hasPagination
}) => (
	<DocumentViewerForm
		field={FormField.PAGES}
		pages={pages}
		activePage={activePage}
		onValidChange={onValidChange}
	>
		<FormPagesFieldArray
			name={FormField.PAGES}
			activePage={activePage}
			hasPagination={hasPagination}
		/>
	</DocumentViewerForm>
);

export default ContextualizationForm;
