import { SagaIterator } from 'redux-saga';
import { put, take, takeLatest, call } from 'redux-saga/effects';
import { clearControllingAgent } from '../actions/clearPortJobControllingAgent';
import { openModal, closeModal } from 'store/modals/actions';
import {
	FORM,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { change } from 'redux-form';

export function* executor(): SagaIterator {
	yield put(
		openModal({
			name: 'confirmRemovingControllingAgent',
			type: 'confirm',
			data: {
				title: '',
				description: `Are you sure you want to delete the Controlling Agent? This Action can't be undone.`,
				okText: 'Remove',
				cancelText: 'Cancel'
			}
		})
	);
	const { payload } = yield take(closeModal.type);
	if (payload.isConfirmed) {
		yield put(change(FORM.portJob, FormFieldName.CONTROLLING_AGENT, null));
	}
}

function* worker(): SagaIterator {
	yield call(executor);
}

export default function*() {
	yield takeLatest(clearControllingAgent, worker);
}
