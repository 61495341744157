import { getActiveSupplierInvoiceId } from './../selectors/activeSupplierInvoiceSelectors';
import {
	setActiveSupplierInvoiceId,
	retrieveSupplierInvoice
} from '../actions';
import { takeLatest, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

export function* setActiveSupplierInvoiceSaga(): SagaIterator {
	const id: string = yield select(getActiveSupplierInvoiceId);
	if (!id) {
		return;
	}
	yield put(retrieveSupplierInvoice(id));
}

export default function*() {
	yield takeLatest(
		setActiveSupplierInvoiceId.type,
		setActiveSupplierInvoiceSaga
	);
}
