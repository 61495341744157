import {
	PortJobFormData,
	FormData,
	FormField,
	PortJobServiceFormData,
	PortJobServicesPageData
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { pick } from 'lodash';
import {
	BaseSupplierInvoiceRequest,
	UpdateSupplierInvoiceRequest,
	ContextualizeSupplierInvoice
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { Action } from 'typescript-fsa';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';

export const preparePortJobServices = (
	services: PortJobServiceFormData[],
	portJobServices?: PortJobServiceFormData[]
) =>
	services
		.filter(({ isSelected }) => isSelected)
		.map(({ id }) => {
			const service = portJobServices?.find(
				({ id: serviceId }) => serviceId === id
			);
			return {
				id,
				comment: service?.serviceComment,
				totalAmount: Number(service?.totalAmount)
			};
		});

export const preparePortJobs = (
	portJobsFormData: PortJobFormData[],
	supplierPortJobsFormData: PortJobFormData[]
) =>
	portJobsFormData
		?.filter(({ services }) => services?.find(({ isSelected }) => !!isSelected))
		.map(({ id, services }) => {
			const portJobServices = supplierPortJobsFormData.find(
				({ id: jobId }) => jobId === id
			);

			return {
				id,
				services: preparePortJobServices(services, portJobServices?.services)
			};
		});

export const preparePagesData = (
	supplierPagesFormData: PortJobServicesPageData[],
	supplierPortJobsFormData: PortJobFormData[],
	wizardForm: WizardFormProps
) =>
	supplierPagesFormData.reduce<ContextualizeSupplierInvoice[]>(
		(result, pages, key) => {
			const pageNumber = key + 1;
			const page = wizardForm.pages[pageNumber];
			if (page?.valid) {
				result.push({
					number: pageNumber,
					portJobs: preparePortJobs(pages.portJobs, supplierPortJobsFormData)
				});
			}
			return result;
		},
		[]
	);

export const prepareRequest = (
	formData: FormData,
	wizardForm: WizardFormProps
): BaseSupplierInvoiceRequest => ({
	...pick(formData, [
		FormField.IS_CREDIT,
		FormField.REFERENCE_NUMBER,
		FormField.RECEIPT_DATE,
		FormField.INVOICE_DATE,
		FormField.COMMENT
	]),
	currencyCode: formData[FormField.CURRENCY_CODE].key,
	mainPrincipalCompany: {
		id: formData[FormField.MAIN_PRINCIPAL_COMPANY].key
	},
	supplierCompany: {
		id: formData[FormField.SUPPLIER_COMPANY].key
	},
	discount: formData[FormField.DISCOUNT]
		? Number(formData.discount)
		: undefined,
	total: Number(formData[FormField.TOTAL]),
	contextualizations: preparePagesData(
		formData[FormField.PAGES],
		formData[FormField.PORT_JOBS],
		wizardForm
	)
});

export const prepareUpdateRequest = (
	formData: FormData,
	action: Action<UpdateSupplierInvoiceRequest>,
	wizardForm: WizardFormProps
) => ({
	...prepareRequest(formData, wizardForm),
	supplierInvoiceId: action.payload.supplierInvoiceId,
	documentId: action.payload.documentId
});

export default prepareUpdateRequest;
