import React from 'react';
import { TextArea, InputNumber, Row, Col } from 'components/antd';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';

export enum SparesAndDocumentDeliveryFormFieldName {
	DETAILS = 'details',
	NUMBER = 'number'
}

const SparesAndDocumentDelivery = () => (
	<>
		<Row>
			<Col xs={12} sm={1}>
				<InputNumber.ReduxFormItem
					name={SparesAndDocumentDeliveryFormFieldName.NUMBER}
					label="Number"
					decimalPart={4}
					integerPart={11}
					min={0}
					withDelimiter={false}
					required
				/>
			</Col>
		</Row>
		<TextArea.ReduxFormItem
			name={SparesAndDocumentDeliveryFormFieldName.DETAILS}
			required
			label="Operation Details"
			rows={4}
			maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
		/>
	</>
);

export default SparesAndDocumentDelivery;
