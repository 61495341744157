import * as Redux from 'redux';
import { isArray } from 'lodash';
import { AppState, Action } from 'store-types';
import { ActionSuccess, ActionFailure } from 'app-types';

import { getApiMiddlewareHandleType } from './apiMiddlewareUtils';
import {
	connectionHandler,
	notificationHandler,
	submitHandler,
	otherHandler
} from './apiHandlers';
import { ApiMiddlewareHandleType } from './apiMiddlewareTypes';

import { setNotificationAddons } from 'store/notifications/actions';
import { isActionAsyncDone, isActionAsync } from 'store/utils';

const onGetHandleTypeAction = (
	action: Action | Action[] | undefined,
	next: Redux.Dispatch
) => {
	if (!action) {
		return;
	}
	if (isArray(action)) {
		action.forEach(item => next(item));
	} else {
		next(action);
	}
};

const middleware = (api: Redux.MiddlewareAPI<Redux.Dispatch, AppState>) => (
	next: Redux.Dispatch
) => (action: ActionSuccess | ActionFailure) => {
	const state = api.getState();
	const handleType = getApiMiddlewareHandleType(action, state);

	// pass through all not related to network issues
	if (handleType !== ApiMiddlewareHandleType.CONNECTION) {
		next(action);
	}

	let handleTypeAction: Action | Action[] | undefined = undefined;
	let handleTypeActionTimeout = false;
	const handlerArgs = { action, api, handleType };

	switch (handleType) {
		case ApiMiddlewareHandleType.CONNECTION:
			handleTypeAction = connectionHandler(handlerArgs);

			// postpone notification action to allow custom logic to execute, like navigation, etc
			handleTypeActionTimeout = true;
			break;

		case ApiMiddlewareHandleType.NOTIFICATION:
			handleTypeAction = notificationHandler({
				action,
				api,
				handleType
			});

			// postpone notification action to allow custom logic to execute, like navigation, etc
			handleTypeActionTimeout = true;
			break;

		case ApiMiddlewareHandleType.SUBMIT:
			handleTypeAction = submitHandler(handlerArgs);
			break;

		case ApiMiddlewareHandleType.OTHER:
			handleTypeAction = otherHandler(handlerArgs);
			break;
	}
	if (
		isActionAsync(action) &&
		(action.meta?.behaviour?.type === 'section' ||
			action.meta?.behaviour?.type === 'sub-section') &&
		action.meta?.behaviour?.infiniteLoad
	) {
		const payload = isActionAsyncDone(action)
			? { loaded: true }
			: { errors: true };

		const addons = setNotificationAddons({
			[action.type]: payload
		});

		if (!handleTypeAction) {
			handleTypeAction = [];
		}
		if (isArray(handleTypeAction)) {
			handleTypeAction.push(addons);
		} else {
			handleTypeAction = [handleTypeAction, addons];
		}
	}
	if (handleTypeActionTimeout) {
		setTimeout(() => {
			onGetHandleTypeAction(handleTypeAction, next);
		});
	} else {
		onGetHandleTypeAction(handleTypeAction, next);
	}

	return handleTypeAction;
};

export default middleware;
