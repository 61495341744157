import * as React from 'react';
import classNames from 'classnames';
import { escapeRegExp } from 'lodash';
import './Highlight.scss';

interface HighlightProps {
	term?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
}

export default function Highlight({
	term,
	children,
	...props
}: HighlightProps) {
	if (!term) {
		return children;
	}
	const parts = String(children).split(
		new RegExp(`(${escapeRegExp(term)})`, 'gi')
	);
	return (
		<span {...props}>
			{parts.map((part, key) => {
				const spanProps = {
					key,
					className: classNames({
						highlight: term.toLowerCase() === part.toLowerCase()
					})
				};
				return !!part && <span {...spanProps}>{part}</span>;
			})}
		</span>
	);
}
