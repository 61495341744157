import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';

import Api from 'services/api';

import {
	deleteGroupUser,
	deleteGroupUserAsync
} from '../actions/deleteGroupUser';
import { AddOrDeleteGroupUserRequest } from 'services/api/groups/groupsServiceTypes';

export function* executor(
	action: Action<AddOrDeleteGroupUserRequest>
): SagaIterator {
	const { userId, groupId } = action.payload;

	const requestParams = {
		userId,
		groupId
	};
	yield put(deleteGroupUserAsync.started(requestParams));
	try {
		const response = yield call(Api.Groups.deleteGroupUser, requestParams);
		yield put(
			deleteGroupUserAsync.done({
				result: response.data,
				params: requestParams,
				response
			})
		);
	} catch (error) {
		yield put(
			deleteGroupUserAsync.failed({
				error,
				params: requestParams
			})
		);
	}
}

export function* worker(action: Action<AddOrDeleteGroupUserRequest>) {
	yield call(executor, action);
}

export default function*(): SagaIterator {
	yield takeLatest(deleteGroupUser.type, worker);
}
