import actionCreator from '../registrationActionCreator';
import {
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse
} from 'services/api/registration/registrationServiceTypes';

const ACTION_NAME = 'PRE_REGISTER_VERIFY';

export const preRegisterVerifyAsync = actionCreator.async<
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse,
	Error
>(ACTION_NAME);

export const preRegisterVerify = actionCreator<PreRegisterVerifyRequest>(
	ACTION_NAME
);
