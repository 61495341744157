import { SagaIterator } from 'redux-saga';
import { put, call, delay } from 'redux-saga/effects';
import { DEFAULT_MAX_POLL_ATTEMPTS } from 'app-constants';
import { AsyncActionCreators } from 'typescript-fsa';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { AxiosTypedResponse, AxiosTypedPromise } from 'services/api/apiTypes';
import { pollDownloadUrlAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { downloadURI } from 'utils';
import { forEach } from 'lodash';

type ApiCall<P, S> = (params: P, data?: S) => AxiosTypedPromise<S>;

export interface ExecutorConfig<P, S, E> {
	api: ApiCall<P, S>;
	async?: AsyncActionCreators<P, S, E>;
	maxAttemptCount?: number;
	maxAttemptMessage?: string;
	delayTime?: number;
}

const defaultConfigValues: Pick<
	ExecutorConfig<any, any, Error>, // eslint-disable-line
	'async' | 'maxAttemptMessage' | 'maxAttemptCount'
> & { delayTime: number } = {
	async: pollDownloadUrlAsync,
	maxAttemptCount: DEFAULT_MAX_POLL_ATTEMPTS,
	maxAttemptMessage:
		'Maximum attempts on getting document reached. Please try again in a few seconds',
	delayTime: 2000
};

/**
 * Polling here until isReady === true
 */
export function makePollDownloadUrlExecutor<P>(
	config: ExecutorConfig<P, RetrieveDownloadDocumentResponse, Error>
) {
	const settings = { ...config, ...defaultConfigValues };
	const {
		async: asyncAction,
		api,
		maxAttemptMessage,
		maxAttemptCount,
		delayTime
	} = settings;

	return function* executor(
		params: P,
		data?: RetrieveDownloadDocumentResponse,
		isMultiFileDownload?: boolean
	): SagaIterator {
		const action = asyncAction as typeof pollDownloadUrlAsync;
		let counter = 0;
		do {
			try {
				if (isMultiFileDownload) {
					const response: AxiosTypedResponse<RetrieveDownloadDocumentResponse[]> = yield call(
						api,
						params,
						data
					);

					yield put({
						type: action.type,
						payload: {
							counter
						}
					});

					// make GET call until document.isReady
					yield delay(delayTime);
					counter++;

					const urls = [] as string[];
					forEach(response.data, value => {
						if (value.isReady) {
							value.downloadUrl && urls.push(value.downloadUrl);
						}
					});
					if (urls.length === response.data.length) {
						return urls;
					}
				} else {
					const response: AxiosTypedResponse<RetrieveDownloadDocumentResponse> = yield call(
						api,
						params,
						data
					);

					yield put({
						type: action.type,
						payload: {
							counter
						}
					});

					// make GET call until document.isReady
					yield delay(delayTime);
					counter++;

					if (response.data.isReady) {
						return response.data.downloadUrl;
					}
				}

				if (maxAttemptCount && counter >= maxAttemptCount) {
					throw Error(maxAttemptMessage);
				}
			} catch (error) {
				if (process.env.NODE_ENV !== 'production') {
					// eslint-disable-next-line
					console.error(error);
				}
				yield put(
					action.failed({
						params,
						error
					})
				);
				break;
			}
		} while (maxAttemptCount && counter <= maxAttemptCount);
	};
}

/**
 * TODO: Move all the code below to an external file
 */

/**
 * GET download through a string `downloadUrl` executor
 */
export interface GetDownloadUrlExecutorConfig<P>
	extends Pick<
		DownloadExecutorConfig<P>,
		'GETApi' | 'params' | 'isMultiFileDownload'
	> {
	data?: RetrieveDownloadDocumentResponse;
}
export function* getDownloadUrlExecutor<P>({
	params,
	GETApi,
	data,
	isMultiFileDownload
}: GetDownloadUrlExecutorConfig<P>): SagaIterator {
	const downloadUrlExecutor = makePollDownloadUrlExecutor<P>({
		api: GETApi
	});

	if (isMultiFileDownload) {
		const downloadUrl: string[] = yield call(
			downloadUrlExecutor,
			params,
			data,
			isMultiFileDownload
		);

		if (!downloadUrl) {
			yield put(notify.error('There was an error. Please try again.'));
			return;
		}

		// Manually Call this downloadUrl to download the file\
		for (let i = 0; i < downloadUrl.length; i++) {
			yield call(downloadURI, downloadUrl[i]);
			yield delay(5000);
		}

		return downloadUrl;
	} else {
		const downloadUrl: string = yield call(downloadUrlExecutor, params, data);

		if (!downloadUrl) {
			yield put(notify.error('There was an error. Please try again.'));
			return;
		}

		// Manually Call this downloadUrl to download the file
		yield call(downloadURI, downloadUrl);

		return downloadUrl;
	}
}

export interface DownloadExecutorConfig<
	P,
	S = RetrieveDownloadDocumentResponse
> {
	params: P;
	POSTApi: ApiCall<P, S>;
	GETApi: ApiCall<P, S>;
	isMultiFileDownload?: boolean;
}
/**
 * Fetch POSTApi to get a cached `downloadUrl`
 * if that `downloadUrl` doesnt exist (isReady === false)
 * then poll GETApi endpoint until isReady === true
 */
export function* downloadExecutor<P>({
	params,
	POSTApi,
	GETApi,
	isMultiFileDownload
}: DownloadExecutorConfig<P>): SagaIterator {
	yield put(pollDownloadUrlAsync.started(params));
	try {
		/** Call POST Api service to get file at first */
		const response: AxiosTypedResponse<RetrieveDownloadDocumentResponse> = yield call(
			POSTApi,
			params
		);
		const { data } = response;

		if (data.isReady && data.downloadUrl) {
			// Manually Call this downloadUrl to download the file
			yield call(downloadURI, data.downloadUrl);
			return data.downloadUrl;
		}

		/** If isReady = false, then the client should call GETApi service for polling */
		const downloadUrl: string = yield call(getDownloadUrlExecutor, {
			params,
			GETApi,
			data,
			isMultiFileDownload
		});

		if (!downloadUrl) {
			throw Error('There was an error. Please try again');
		}

		yield put(
			pollDownloadUrlAsync.done({
				params,
				result: downloadUrl,
				response: null
			})
		);

		// Return url for further use
		return downloadUrl;
	} catch (error) {
		yield put(
			pollDownloadUrlAsync.failed({
				params,
				error
			})
		);
	}
}
