import * as React from 'react';
import { isEqual } from 'lodash';
import { setBreadcrumbs, BreadcrumbItem } from 'store/navigation';
import { connect } from 'react-redux';

interface RouteBreadcrumbsProps {
	items: BreadcrumbItem[];
	setBreadcrumbs: typeof setBreadcrumbs;
}

class RouteBreadcrumbs extends React.Component<RouteBreadcrumbsProps> {
	componentDidMount() {
		this.props.setBreadcrumbs(this.props.items);
	}

	componentDidUpdate(prevProps: RouteBreadcrumbsProps) {
		if (!isEqual(this.props.items, prevProps.items)) {
			this.props.setBreadcrumbs(this.props.items);
		}
	}

	componentWillUnmount() {
		this.props.setBreadcrumbs([]);
	}

	render() {
		return null;
	}
}

export default connect(null, {
	setBreadcrumbs
})(RouteBreadcrumbs);
