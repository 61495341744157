import * as React from 'react';
import classNames from 'classnames';
import AntInput, { InputProps } from 'antd/lib/input/Input';
import AntInputGroup, { GroupProps } from 'antd/lib/input/Group';
import AntInputSearch from 'antd/lib/input/Search';
import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';
import formStyles from '../Form/Form.module.scss';
import styles from './Input.module.scss';

const InputGroup: React.FC<GroupProps> = props => {
	return (
		<AntInputGroup
			{...props}
			className={classNames(styles.group, props.className)}
		>
			{props.children}
		</AntInputGroup>
	);
};

export class Input extends React.Component<InputProps> {
	static Group: typeof InputGroup;
	static Search: typeof AntInputSearch = AntInputSearch;
	static ReduxFormItem: CreateReduxField<InputProps>;

	elem: AntInput | null;

	focus() {
		if (this.elem) {
			this.elem.focus();
		}
	}

	render() {
		return (
			<AntInput
				ref={elem => (this.elem = elem)}
				{...this.props}
				className={classNames(
					styles.root,
					formStyles.input,
					this.props.className,
					{
						[styles.addonAfter]: Boolean(this.props.addonAfter)
					}
				)}
			/>
		);
	}
}

/**
 * ReduxForm Input connector
 */

export const textFieldMapProps = customMap(props => ({
	onChange: (event: React.FormEvent<HTMLInputElement>) =>
		props.input.onChange(event)
}));

Input.ReduxFormItem = createFieldComponent<InputProps>(
	Input,
	textFieldMapProps
);

Input.Group = InputGroup;

export default Input;
