import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { RetrievePortCallAccessiblePortJobsResponse } from 'services/api/portCall/portCallServiceTypes';
import { retrievePortCallAccessiblePortJobsAsync } from '../actions';

const apiCall = Api.PortCall.retrievePortCallAccessiblePortJobs;

export default makeTakeLatestWatcher<
	string,
	RetrievePortCallAccessiblePortJobsResponse,
	Error
>({
	api: apiCall,
	async: retrievePortCallAccessiblePortJobsAsync
});
