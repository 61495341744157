import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, all, fork, delay } from 'redux-saga/effects';

import { retrieveVP, retrieveVPAsync, updateVPHash } from '../actions';
import Api from 'services/api';
import uuidv1 from 'uuid';
import hash from 'object-hash';

import {
	RetrieveOperationUnit,
	RetrieveVesselProgrammeRequest,
	RetrieveVesselProgrammeProcess
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';
import { addPOIDrafts } from 'store/drafts/actions';
import { retrieveFinanceDetailsCycleStart } from 'store/finance/actions';
import { POIDraft } from 'store/drafts/draftsState';
import { retrievePortCallsSettings } from '../../portcalls/actions';

export const addDefaultValuesToResponse = (response: {
	data: RetrieveVesselProgrammeProcess;
}) => ({
	...response.data,
	unallocatedUnits: response.data.unallocatedUnits.map(
		(unit: RetrieveOperationUnit) => ({
			...unit,
			events:
				unit.events.length > 0
					? unit.events
					: [
							{
								id: uuidv1(),
								status: PortCallEventStatus.ESTIMATED,
								name: 'ET Commencement',
								code: 'GBRSD12345',
								estimatedDatePlt: null,
								estimatedDateUtc: null,
								realDatePlt: null
							},
							{
								id: uuidv1(),
								status: PortCallEventStatus.ESTIMATED,
								name: 'ET Completion',
								code: 'GBRSD12345',
								estimatedDatePlt: null,
								estimatedDateUtc: null,
								realDatePlt: null
							}
					  ]
		})
	)
});

function* retrieveVPAfterDelay(payload: RetrieveVesselProgrammeRequest) {
	yield delay(2000);
	yield put(retrieveVP(payload));
}

export function* retrieveVesselProgrammeWorker({
	payload
}: Action<RetrieveVesselProgrammeRequest>): SagaIterator {
	const { portCallId, isNewVP = false } = payload;

	yield put(retrieveVPAsync.started(payload));

	try {
		const response = yield call(Api.VesselProgramme.retrieveVP, portCallId);
		const vpHash = hash(response.data);
		yield put(updateVPHash(vpHash));
		// create default operation unit events in case they are empty
		const data = addDefaultValuesToResponse(response);
		const POIDrafts = response.data.poiDrafts;
		if (POIDrafts?.length) {
			// temp requirement from BE: change "parentPortPOIId" to "parentPOIId"
			const preparedPoiDrafts = POIDrafts.map((draft: POIDraft) => {
				if (draft.parentPortPOIId) {
					draft.parentPOIId = draft.parentPortPOIId;
				}
				return draft;
			});
			yield put(addPOIDrafts(preparedPoiDrafts));
		}

		if (isNewVP) {
			yield put(retrieveFinanceDetailsCycleStart());
		}
		yield put(
			retrieveVPAsync.done({
				result: data,
				params: payload,
				response
			})
		);
		if (!data.isReady) {
			// VP is not ready start requesting loop until is ready
			yield call(retrieveVPAfterDelay, payload);
		}
	} catch (error) {
		yield put(
			retrieveVPAsync.failed({
				error,
				params: payload
			})
		);
		// in situation vp is new we try until success
		// this is infinite loop until backend respond with 200
		if (isNewVP) {
			yield call(retrieveVPAfterDelay, payload);
		}
	} finally {
		yield put(retrievePortCallsSettings());
	}
}

export function* retrieveVesselProgrammeWatcher(): SagaIterator {
	yield takeLatest(retrieveVP.type, retrieveVesselProgrammeWorker);
}

export default function*() {
	yield all([fork(retrieveVesselProgrammeWatcher)]);
}
