import React, { FC, ReactNode, useMemo } from 'react';

interface TrsProps {
	header?: boolean;
	className?: string;
	flex?: boolean;
	children?: ReactNode;
}

const TRS: FC<TrsProps> = ({ header, className, children, flex }) => {
	const ComponentTag = useMemo(
		() => (flex && 'div') || (header && 'thead') || 'tbody',
		[flex, header]
	);

	return <ComponentTag className={className}>{children}</ComponentTag>;
};

export default TRS;
