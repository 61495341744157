import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

import { FinanceState } from '../financeState';
import { Success } from 'typescript-fsa';

import {
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
} from 'services/api/finance/financeServiceTypes';

export function onRetrievePrincipalServicesSuccess(
	state: FinanceState,
	{
		params,
		result
	}: Success<
		RetrievePrincipalServicesRequest,
		RetrievePrincipalServicesResponse
	>
): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			principalServices: {
				...state.context.principalServices,
				[`${params.jobServiceId}`]: result.elements
			}
		}
	};
}

export const onRetrievePrincipalServices = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
>('fetchStatuses', 'retrievePrincipalServices', {
	doneReducer: onRetrievePrincipalServicesSuccess
});

export function onResetPrincipalServices(
	state: FinanceState,
	action: { serviceId: string }
): FinanceState {
	const { serviceId } = action;
	return {
		...state,
		context: {
			...state.context,
			principalServices: {
				...state.context.principalServices,
				[`${serviceId}`]: []
			}
		}
	};
}
