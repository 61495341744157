import React from 'react';
import { connect } from 'react-redux';
import { isEqual, isString } from 'lodash';
import { Text, Truncate } from 'components';
import { Popover, Button, Icon, Tag } from 'components/antd';
import { TagProps } from 'components/antd/Tag/Tag';
import ActAsOptions from './ActAsOptions';
import { LabeledValueItem, LabeledValue } from 'app-types';
import { setAuthActingAs } from 'store/auth/actions';
import {
	getAuthActAs,
	getCanAuthActAs,
	getAuthActAsConfig,
	getAuthActAsOptions
} from 'store/auth/selectors';
import {
	getActivePortJobCode,
	getPortJobByCode
} from 'store/portJobs/portJobsSelectors';
import { navigateTo } from 'utils';
import { ActAsBaseProps } from './actAsTypes';
import { AuthActAsConfig } from 'store/auth/authState';
import { AppState } from 'store-types';
import styles from './ActAs.module.scss';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';

export const ActAsTagContainer: React.FC<TagProps> = ({
	children,
	...tagProps
}) => (
	<Tag color="#47b65d" closable={false} {...tagProps}>
		<Text weight="light">{children}</Text>
	</Tag>
);

type ActAsProps = ActAsBaseProps & {
	portJob: PortJob;
	canActAs: boolean;
	config: AuthActAsConfig;
	setAuthActingAs: typeof setAuthActingAs;
};

interface ActAsState {
	value: LabeledValueItem | null;
	isPopoverVisible: boolean;
}

class ActAs extends React.Component<ActAsProps, ActAsState> {
	/**
	 * Return submitted value or
	 * pre-populate <Select /> with value if only 1 option is available
	 */
	getInitialOrCurrentValue = () => {
		const { value, options } = this.props;

		if (value) {
			return value;
		}

		const optionForPrePopulation =
			options.length === 1 && !options[0].disabled ? options[0] : null;

		// pre-populate field
		if (optionForPrePopulation) {
			return {
				key: optionForPrePopulation.id,
				label: optionForPrePopulation.name
			};
		}

		return null;
	};

	state = {
		value: this.getInitialOrCurrentValue(),
		isPopoverVisible: false
	};

	onSelect = (
		value: LabeledValue<React.ReactElement<{ children: string }> | string>
	) => {
		const newValue = {
			key: value.key,
			label: isString(value.label) ? value.label : value.label.props.children
		};
		if (isEqual(this.state.value, newValue)) {
			return;
		}
		this.setState({
			value: newValue
		});
	};

	onStart = () => {
		if (!this.state.value) {
			return;
		}
		this.props.setAuthActingAs(this.state.value);
		this.setState({
			isPopoverVisible: false
		});
	};

	onEnd = () => {
		const { config } = this.props;
		if (
			config.urlPrefix &&
			config.returnUrl &&
			!config.returnUrl.startsWith(config.urlPrefix)
		) {
			navigateTo(config.returnUrl);
		}
		this.props.setAuthActingAs(null);
		this.setState({
			isPopoverVisible: false
		});
	};

	getFormState = () => {
		const current = this.props.value;
		const selected = this.state.value;
		return {
			submitted: Boolean(current),
			pristine:
				(!current && !selected) ||
				(!!current && !!selected && current.key === selected.key)
		};
	};

	onVisibleChange = (visible: boolean) => {
		this.setState({
			value: this.getInitialOrCurrentValue(),
			isPopoverVisible: visible
		});
	};

	render() {
		const { value } = this.state;
		const { canActAs, config, options } = this.props;
		if (!canActAs) {
			return null;
		}

		const { submitted, pristine } = this.getFormState();

		return (
			<Popover
				title="Acting As"
				size="sm"
				visible={this.state.isPopoverVisible}
				trigger="click"
				onVisibleChange={this.onVisibleChange}
				getPopupContainer={() => document.body}
				content={
					<div>
						<ActAsOptions
							value={value}
							onSelect={this.onSelect}
							isValueSubmitted={submitted}
							options={options}
							disabledMessage={config.disabledMessage}
						/>
						<footer>
							<Button
								type="primary"
								transparent
								disabled={!submitted}
								onClick={this.onEnd}
							>
								End Session
								<Icon type="sign-out" offset="left" />
							</Button>
							<Button type="primary" disabled={pristine} onClick={this.onStart}>
								Start
							</Button>
						</footer>
					</div>
				}
			>
				<ActAsTagContainer className={styles.tag}>
					<Truncate>
						{this.props.value ? (
							<>
								Acting as:&nbsp;
								<Text weight="semi-bold">{this.props.value.label}</Text>
							</>
						) : (
							`Act as LPA`
						)}
					</Truncate>
				</ActAsTagContainer>
			</Popover>
		);
	}
}

export default connect(
	(state: AppState) => {
		const activePortJobCode = getActivePortJobCode(state);
		return {
			portJob: getPortJobByCode(state, activePortJobCode),
			value: getAuthActAs(state),
			canActAs: getCanAuthActAs(state),
			config: getAuthActAsConfig(state),
			options: getAuthActAsOptions(state)
		};
	},
	{
		setAuthActingAs
	}
)(ActAs);
