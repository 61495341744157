import { FetchStatus } from 'services/api/apiTypes';
import { OpticConfig } from 'services/api/opticConfiguration/opticConfigServiceTypes';

export interface OpticConfigState {
	readonly sections: {};
	readonly editKpi: OpticConfig;

	readonly fetchStatuses: {
		retrieveKpiActiveSection: FetchStatus;
		retrieveKPIDetailsById: FetchStatus;
		updateKPIDetailsById: FetchStatus;
	};
}

export const initialState: OpticConfigState = {
	sections: {},
	editKpi: {} as OpticConfig,
	fetchStatuses: {
		retrieveKpiActiveSection: FetchStatus.IDLE,
		retrieveKPIDetailsById: FetchStatus.IDLE,
		updateKPIDetailsById: FetchStatus.IDLE
	}
};
