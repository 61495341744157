import { Success, Failure } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { PortCallOperationsState } from '../portCallOperationsState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrievePortCallEventTemplatesRequest,
	PortCallEventTemplate,
	RetrievePortCallEventTemplatesResponse
} from 'services/api/portCall/portCallServiceTypes';
import { portCallSectionKey } from '../portCallOperationsUtils';

const getFetchStatuses = (
	{ fetchStatuses }: PortCallOperationsState,
	{ portCallId, parentId, parentCode }: RetrievePortCallEventTemplatesRequest,
	status: FetchStatus
): PortCallOperationsState['fetchStatuses'] => ({
	...fetchStatuses,
	eventTemplates: {
		...fetchStatuses.eventTemplates,
		[portCallId]: {
			...fetchStatuses.eventTemplates[portCallId],
			[portCallSectionKey({ id: parentId, code: parentCode })]: status
		}
	}
});

export const onRetrievePortCallEventTemplatesStarted = (
	state: PortCallOperationsState,
	action: RetrievePortCallEventTemplatesRequest
) => ({
	...state,
	fetchStatuses: getFetchStatuses(state, action, FetchStatus.PENDING)
});

export const onRetrievePortCallEventTemplatesSuccess = (
	state: PortCallOperationsState,
	{
		params,
		result
	}: Success<
		RetrievePortCallEventTemplatesRequest,
		RetrievePortCallEventTemplatesResponse
	>
): PortCallOperationsState => {
	const { eventTemplates } = state;
	const { portCallId, parentId, parentCode } = params;
	return {
		...state,
		eventTemplates: {
			...eventTemplates,
			[portCallId]: {
				...eventTemplates[portCallId],
				[portCallSectionKey({ id: parentId, code: parentCode })]: keyBy<
					PortCallEventTemplate
				>(result, item => item.code)
			}
		},
		fetchStatuses: getFetchStatuses(state, params, FetchStatus.SUCCESS)
	};
};

export const onRetrievePortCallEventTemplatesFailed = (
	state: PortCallOperationsState,
	action: Failure<RetrievePortCallEventTemplatesRequest, Error>
) => ({
	...state,
	fetchStatuses: getFetchStatuses(state, action.params, FetchStatus.SUCCESS)
});

export const onResetPortCallEventTemplates = (
	state: PortCallOperationsState,
	portCallId: string
): PortCallOperationsState => ({
	...state,
	eventTemplates: {
		...state.eventTemplates,
		[portCallId]: {}
	},
	fetchStatuses: {
		...state.fetchStatuses,
		eventTemplates: {
			...state.fetchStatuses.eventTemplates,
			[portCallId]: {}
		}
	}
});
