import { BENCHMARK_FORM_NAME } from 'sections/Administration/Configuration/Benchmark/BenchmarkTypes';
import {
	RetrieveConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData
} from 'services/api/companies/companiesServiceTypes';
import { getActiveCompanyId } from 'store/configuration/selectors';
import { select, put } from 'redux-saga/effects';
import { retrieveConfigurationBenchmarkAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { initialize } from 'redux-form';

export default makeTakeLatestWatcher<
	null,
	ConfigurationBenchmarkData,
	Error,
	RetrieveConfigurationBenchmarkRequest
>({
	api: Api.Companies.retrieveBenchmarkConfiguration,
	async: retrieveConfigurationBenchmarkAsync,
	*getApiParams() {
		const companyId: string = yield select(getActiveCompanyId);
		return {
			companyId
		};
	},
	*onSuccess({ result }) {
		yield put(initialize(BENCHMARK_FORM_NAME, result));
	}
});
