import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { GenericOpticConfig as Response } from 'services/api/opticConfiguration/opticConfigServiceTypes';
import { retrieveKpiActiveSectionAsync as async } from '../actions';
import { put } from 'redux-saga/effects';
import { notify } from 'store/notifications/actions';

const api = Api.OpticConfiguration.retrieveOpticConfigSections;
export default makeTakeLatestWatcher<void, Response, Error>({
	api,
	async,
	*onError() {
		yield put(notify.error(`Error occurred while retrieving KPI Alerts.`));
	}
});
