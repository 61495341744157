import React from 'react';
import { connect } from 'react-redux';

import { DataFetcher } from 'utils/components';
import PortCallContainer from 'sections/PortCall/PortCallContainer';
import DocumentPageViewer from './OperationDocumentPageViewer';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';

import { retrievePortCallDocument } from 'store/portCallDocuments/actions';

import {
	getRetrievePortCallDocumentFetchStatus,
	getPortCallDocument,
	getIsPortCallDocumentPDF
} from 'store/portCallDocuments/selectors';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';

import { BreadcrumbItem } from 'store/navigation';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { AppState } from 'store-types';

interface OperationDocumentPageViewerRouteProps extends PortCallTabBaseProps {
	document: PortCallDocument;
	isDocumentPDF: boolean;
	breadcrumbs: BreadcrumbItem[];
	fetchStatus: FetchStatus;
	retrievePortCallDocument: typeof retrievePortCallDocument;
}
class OperationDocumentPageViewerRoute extends React.Component<
	OperationDocumentPageViewerRouteProps
> {
	retrieveDocument = () => {
		const { match: { params: { portCallId, documentId } } } = this.props;
		this.props.retrievePortCallDocument({
			portCallId,
			documentId
		});
	};

	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([
			{
				title: 'View Receipt'
			}
		]);

	render() {
		const {
			match: { params: { portCallId } },
			document,
			isDocumentPDF,
			fetchStatus
		} = this.props;
		return (
			<PortCallContainer match={this.props.match}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<DataFetcher fetchStatus={fetchStatus} dispatch={this.retrieveDocument}>
					{() => (
						<DocumentPageViewer
							portCallId={portCallId}
							document={document}
							isDocumentPDF={isDocumentPDF}
						/>
					)}
				</DataFetcher>
			</PortCallContainer>
		);
	}
}

export default connect(
	(state: AppState) => ({
		fetchStatus: getRetrievePortCallDocumentFetchStatus(state),
		isDocumentPDF: getIsPortCallDocumentPDF(state),
		document: getPortCallDocument(state),
		breadcrumbs: getPortCallLayoutBreadcrumbs(state)
	}),
	{
		retrievePortCallDocument
	}
)(OperationDocumentPageViewerRoute);
