import * as React from 'react';
import classNames from 'classnames';
import { isNumber } from 'lodash';
import styles from './Loading.module.scss';
import { Size } from 'components/types';

export type LoadingSize = 'xxs' | Size;

export interface LoadingProps {
	/**
	 * Shows or hides component
	 */
	show?: boolean;
	className?: string;
	size?: LoadingSize | number;
	inline?: boolean;
	position?: 'center-center';
}

export const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
	const sizes = {
		[styles.xxs]: props.size === 'xxs',
		[styles.xs]: props.size === 'xs',
		[styles.sm]: props.size === 'sm',
		[styles.md]: props.size === 'md',
		[styles.lg]: props.size === 'lg'
	};
	const configuredClassNames = {
		[styles.visible]: props.show,
		[styles.hidden]: !props.show,
		[styles.inline]: props.inline,
		[styles.centerCenter]: props.position === 'center-center'
	};
	const className = classNames(
		styles.root,
		props.className,
		sizes,
		configuredClassNames
	);

	const imgAttrs = {
		width: isNumber(props.size) ? props.size : undefined,
		height: isNumber(props.size) ? props.size : undefined
	};

	return (
		<div className={className}>
			<img src={require('./loading.svg')} {...imgAttrs} alt="" />
		</div>
	);
};

Loading.defaultProps = {
	show: true,
	size: 'md'
};

export default Loading;
