import * as React from 'react';
import classNames from 'classnames';

import { Grid } from 'components/antd';
import styles from '../PageHeader.module.scss';
import PageSubHeader from './SubHeader';

export interface PageHeaderProps {
	className?: string;
	fluid?: boolean;
	/** @deprecated */
	justified?: boolean;
	row?: boolean;
	shadowed?: boolean;
	stretch?: boolean;
	/**
	 * 'primary' and 'secondary' changes also the way the Subtitle is rendered
	 */
	type?: 'primary' | 'secondary' | 'main' | 'gullGray';
	subtitle?: React.ReactNode;
}

const Header: React.FC<PageHeaderProps> = ({
	className,
	stretch,
	type,
	justified,
	shadowed,
	children,
	row,
	subtitle,
	fluid
}) => {
	const pageHeaderTypes = {
		[styles.primary]: type === 'primary',
		[styles.secondary]: type === 'secondary',
		[styles.main]: type === 'main',
		[styles.gullGray]: type === 'gullGray'
	};

	const pageHeaderClassName = classNames(
		className,
		{
			[styles.stretch]: stretch
		},
		pageHeaderTypes
	);
	const headerClassName = classNames(styles.root, {
		[styles.row]: row,
		[styles.justified]: justified,
		[styles.shadowed]: shadowed
	});
	const MainHeader = <header className={headerClassName}>{children}</header>;

	const SubHeader = subtitle && (
		<PageSubHeader componentClass="div">{subtitle}</PageSubHeader>
	);

	if (fluid) {
		return (
			<Grid fluid={fluid}>
				{MainHeader}
				{SubHeader}
			</Grid>
		);
	}

	return (
		<div className={pageHeaderClassName}>
			{MainHeader}
			{SubHeader}
		</div>
	);
};

export default Header;
