import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import getLoadColumns from './ColumnsLoad';
import getDischargeColumns from './ColumnsDischarge';

const operationColumnsMap = {
	[OperationTypeCode.LOADING]: getLoadColumns,
	[OperationTypeCode.LOADING_STS]: getLoadColumns,
	[OperationTypeCode.DISCHARGING]: getDischargeColumns,
	[OperationTypeCode.DISCHARGING_STS]: getDischargeColumns
};

const getColumns = (operationTypeCode: OperationTypeCode) =>
	operationColumnsMap[operationTypeCode](operationTypeCode);

export default getColumns;
