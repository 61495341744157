import { reject, forOwn } from 'lodash';
import { PortCallTabKey, PortCallTabMaps } from './portCallsTypes';
import {
	PORTCALL_ID_URL_PARAM,
	PORTJOB_CODE_URL_PARAM,
	PORT_CALL_OPERATION_TAB_ID,
	PORT_CALL_NEW_VP,
	THREAD_ID_URL_PARAM
} from 'sections/App/RouteParams';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	PortJobStatusLabel,
	PortJobStatusEntity
} from 'services/api/portJobs/portJobsServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

export const PORT_CALL_TABS: PortCallTabMaps = {
	[PortCallTabKey.OVERVIEW]: {
		tab: 'Overview',
		key: PortCallTabKey.OVERVIEW,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/jobs/${PORTJOB_CODE_URL_PARAM}/overview`,
		icon: 'overview'
	},
	[PortCallTabKey.VESSEL_PROGRAMME]: {
		tab: 'Vessel Programme',
		key: PortCallTabKey.VESSEL_PROGRAMME,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/vessel-programme/${PORT_CALL_NEW_VP}`,
		icon: 'ship'
	},
	[PortCallTabKey.FINANCE]: {
		tab: 'Finance',
		key: PortCallTabKey.FINANCE,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/jobs/${PORTJOB_CODE_URL_PARAM}/finance`,
		icon: 'finance'
	},
	[PortCallTabKey.OPERATIONS]: {
		tab: 'Operations',
		key: PortCallTabKey.OPERATIONS,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/operations/${PORT_CALL_OPERATION_TAB_ID}`,
		icon: 'operations'
	},
	[PortCallTabKey.SAVINGS]: {
		tab: 'Savings',
		key: PortCallTabKey.SAVINGS,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/jobs/${PORTJOB_CODE_URL_PARAM}/savings`,
		icon: 'savings'
	},
	[PortCallTabKey.MESSAGES]: {
		tab: 'Port Call Messages',
		key: PortCallTabKey.MESSAGES,
		path: `/portcalls/${PORTCALL_ID_URL_PARAM}/jobs/${PORTJOB_CODE_URL_PARAM}/messages/${THREAD_ID_URL_PARAM}`,
		icon: 'messages',
		permissionCode: [
			PermissionCode.VIEW_MESSAGES,
			PermissionCode.VIEW_PORTCALL_MESSAGES
		]
	}
};

export const portCallTabsConfig = {
	[UserType.LPA]: {
		[PortCallTabKey.OVERVIEW]: false,
		[PortCallTabKey.VESSEL_PROGRAMME]: true,
		[PortCallTabKey.FINANCE]: true,
		[PortCallTabKey.OPERATIONS]: true,
		[PortCallTabKey.SAVINGS]: true
	},
	[UserType.HUB]: {
		[PortCallTabKey.OVERVIEW]: false,
		[PortCallTabKey.VESSEL_PROGRAMME]: true,
		[PortCallTabKey.FINANCE]: true,
		[PortCallTabKey.OPERATIONS]: true,
		[PortCallTabKey.SAVINGS]: true,
		[PortCallTabKey.MESSAGES]: false
	},
	[UserType.PRINCIPAL]: {
		[PortCallTabKey.OVERVIEW]: false,
		[PortCallTabKey.VESSEL_PROGRAMME]: true,
		[PortCallTabKey.FINANCE]: true,
		[PortCallTabKey.OPERATIONS]: true,
		[PortCallTabKey.SAVINGS]: true,
		[PortCallTabKey.MESSAGES]: false
	}
};

const getStatuses = () => [
	{
		code: PortJobStatusLabel.AWAITING_CONFIRMATION,
		name: PortJobStatusLabel.AWAITING_CONFIRMATION
	},
	{
		code: PortJobStatusLabel.PROCESS_PENDING,
		name: PortJobStatusLabel.PROCESS_PENDING
	},
	{
		code: PortJobStatusLabel.AWAITING_APPOINTMENT,
		name: PortJobStatusLabel.AWAITING_APPOINTMENT
	},
	{
		code: PortJobStatusLabel.AWAITING_ACCEPTANCE,
		name: PortJobStatusLabel.AWAITING_ACCEPTANCE
	},
	{
		code: PortJobStatusLabel.AWAITING_INVITATION,
		name: PortJobStatusLabel.AWAITING_INVITATION
	},
	{
		code: PortJobStatusLabel.AWAITING_HUB_REVIEW,
		name: PortJobStatusLabel.AWAITING_HUB_REVIEW
	},
	{
		code: PortJobStatusLabel.PDA_REQUESTED,
		name: PortJobStatusLabel.PDA_REQUESTED
	},
	{
		code: PortJobStatusLabel.ACCEPTANCE_PENDING,
		name: PortJobStatusLabel.ACCEPTANCE_PENDING
	},
	{
		code: PortJobStatusLabel.PDA_PENDING,
		name: PortJobStatusLabel.PDA_PENDING
	},
	{
		code: PortJobStatusLabel.PDA_VERIFICATION_PENDING,
		name: PortJobStatusLabel.PDA_VERIFICATION_PENDING
	},
	{
		code: PortJobStatusLabel.PDA_QUERIED,
		name: PortJobStatusLabel.PDA_QUERIED
	},
	{
		code: PortJobStatusLabel.PDA_QUERIED_HUB,
		name: PortJobStatusLabel.PDA_QUERIED_HUB
	},
	{
		code: PortJobStatusLabel.PDA_QUERIED_LPA,
		name: PortJobStatusLabel.PDA_QUERIED_LPA
	},
	{
		code: PortJobStatusLabel.PDA_QUERIED_HUB_LPA,
		name: PortJobStatusLabel.PDA_QUERIED_HUB_LPA
	},
	{
		code: PortJobStatusLabel.PDA_APPROVAL_PENDING,
		name: PortJobStatusLabel.PDA_APPROVAL_PENDING
	},
	{
		code: PortJobStatusLabel.PDA_APPROVED,
		name: PortJobStatusLabel.PDA_APPROVED
	},
	{
		code: PortJobStatusLabel.JOB_IN_PROGRESS,
		name: PortJobStatusLabel.JOB_IN_PROGRESS
	},
	{ code: PortJobStatusLabel.CE_PENDING, name: PortJobStatusLabel.CE_PENDING },
	{
		code: PortJobStatusLabel.CE_QUERIED,
		name: PortJobStatusLabel.CE_QUERIED
	},
	{
		code: PortJobStatusLabel.CE_QUERIED_HUB,
		name: PortJobStatusLabel.CE_QUERIED_HUB
	},
	{
		code: PortJobStatusLabel.CE_QUERIED_LPA,
		name: PortJobStatusLabel.CE_QUERIED_LPA
	},
	{
		code: PortJobStatusLabel.CE_QUERIED_HUB_LPA,
		name: PortJobStatusLabel.CE_QUERIED_HUB_LPA
	},
	{
		code: PortJobStatusLabel.CE_VERIFICATION_PENDING,
		name: PortJobStatusLabel.CE_VERIFICATION_PENDING
	},
	{
		code: PortJobStatusLabel.CE_APPROVAL_PENDING,
		name: PortJobStatusLabel.CE_APPROVAL_PENDING
	},
	{
		code: PortJobStatusLabel.CE_APPROVED,
		name: PortJobStatusLabel.CE_APPROVED
	},
	{ code: PortJobStatusLabel.DA_PENDING, name: PortJobStatusLabel.DA_PENDING },
	{
		code: PortJobStatusLabel.DA_QUERIED,
		name: PortJobStatusLabel.DA_QUERIED
	},
	{
		code: PortJobStatusLabel.DA_QUERIED_HUB,
		name: PortJobStatusLabel.DA_QUERIED_HUB
	},
	{
		code: PortJobStatusLabel.DA_QUERIED_LPA,
		name: PortJobStatusLabel.DA_QUERIED_LPA
	},
	{
		code: PortJobStatusLabel.DA_QUERIED_HUB_LPA,
		name: PortJobStatusLabel.DA_QUERIED_HUB_LPA
	},
	{
		code: PortJobStatusLabel.DA_VERIFICATION_PENDING,
		name: PortJobStatusLabel.DA_VERIFICATION_PENDING
	},
	{
		code: PortJobStatusLabel.DA_APPROVAL_PENDING,
		name: PortJobStatusLabel.DA_APPROVAL_PENDING
	},
	{
		code: PortJobStatusLabel.DA_APPROVED,
		name: PortJobStatusLabel.DA_APPROVED
	},
	{
		code: PortJobStatusLabel.LPA_SETTLEMENT,
		name: PortJobStatusLabel.LPA_SETTLEMENT
	},
	{
		code: PortJobStatusLabel.DA_OUT_POSTING,
		name: PortJobStatusLabel.DA_OUT_POSTING
	},
	{
		code: PortJobStatusLabel.HUB_SETTLEMENT,
		name: PortJobStatusLabel.HUB_SETTLEMENT
	},
	{
		code: PortJobStatusLabel.DA_SETTLEMENT,
		name: PortJobStatusLabel.DA_SETTLEMENT
	},
	{
		code: PortJobStatusLabel.COMPLETE,
		name: PortJobStatusLabel.COMPLETE
	},
	{
		code: PortJobStatusLabel.CLOSED,
		name: PortJobStatusLabel.CLOSED
	}
];

export const getFilteredStatusesByUserType = (userType: UserType) => {
	const statuses = getStatuses();
	const statusFilter = (status: PortJobStatusEntity) => {
		switch (userType) {
			case UserType.HUB:
				return (
					status.code === PortJobStatusLabel.AWAITING_HUB_REVIEW ||
					status.code === PortJobStatusLabel.PDA_QUERIED ||
					status.code === PortJobStatusLabel.CE_QUERIED ||
					status.code === PortJobStatusLabel.DA_QUERIED ||
					status.code === PortJobStatusLabel.PDA_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.CE_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.DA_QUERIED_HUB_LPA
				);
			case UserType.LPA:
				return (
					status.code === PortJobStatusLabel.AWAITING_APPOINTMENT ||
					status.code === PortJobStatusLabel.AWAITING_INVITATION ||
					status.code === PortJobStatusLabel.AWAITING_ACCEPTANCE ||
					status.code === PortJobStatusLabel.AWAITING_CONFIRMATION ||
					status.code === PortJobStatusLabel.AWAITING_HUB_REVIEW ||
					status.code === PortJobStatusLabel.PDA_REQUESTED ||
					status.code === PortJobStatusLabel.PROCESS_PENDING ||
					status.code === PortJobStatusLabel.PDA_VERIFICATION_PENDING ||
					status.code === PortJobStatusLabel.PDA_QUERIED_HUB ||
					status.code === PortJobStatusLabel.PDA_QUERIED_LPA ||
					status.code === PortJobStatusLabel.PDA_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.CE_VERIFICATION_PENDING ||
					status.code === PortJobStatusLabel.CE_QUERIED_HUB ||
					status.code === PortJobStatusLabel.CE_QUERIED_LPA ||
					status.code === PortJobStatusLabel.CE_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.DA_VERIFICATION_PENDING ||
					status.code === PortJobStatusLabel.DA_QUERIED_HUB ||
					status.code === PortJobStatusLabel.DA_QUERIED_LPA ||
					status.code === PortJobStatusLabel.DA_QUERIED_HUB_LPA
				);
			case UserType.PRINCIPAL:
				return (
					status.code === PortJobStatusLabel.AWAITING_APPOINTMENT ||
					status.code === PortJobStatusLabel.AWAITING_INVITATION ||
					status.code === PortJobStatusLabel.AWAITING_ACCEPTANCE ||
					status.code === PortJobStatusLabel.PROCESS_PENDING ||
					status.code === PortJobStatusLabel.PDA_REQUESTED ||
					status.code === PortJobStatusLabel.PDA_QUERIED_HUB ||
					status.code === PortJobStatusLabel.PDA_QUERIED_LPA ||
					status.code === PortJobStatusLabel.PDA_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.CE_QUERIED_HUB ||
					status.code === PortJobStatusLabel.CE_QUERIED_LPA ||
					status.code === PortJobStatusLabel.CE_QUERIED_HUB_LPA ||
					status.code === PortJobStatusLabel.DA_QUERIED_HUB ||
					status.code === PortJobStatusLabel.DA_QUERIED_LPA ||
					status.code === PortJobStatusLabel.DA_QUERIED_HUB_LPA
				);
			default:
				return false;
		}
	};
	return reject(statuses, statusFilter);
};

const statusesTypes = {
	[PortJobStatusLabel.AWAITING_CONFIRMATION]: {
		jobStatus: PortJobStatus.CREATED
	},
	[PortJobStatusLabel.AWAITING_HUB_REVIEW]: {
		jobStatus: PortJobStatus.CONFIRMED
	},
	[PortJobStatusLabel.PROCESS_PENDING]: {
		jobStatus: PortJobStatus.CONFIRMED
	},
	[PortJobStatusLabel.AWAITING_APPOINTMENT]: {
		jobStatus: PortJobStatus.AWAITING_APPOINTMENT
	},
	[PortJobStatusLabel.AWAITING_ACCEPTANCE]: {
		jobStatus: PortJobStatus.AWAITING_ACCEPTANCE
	},
	[PortJobStatusLabel.AWAITING_INVITATION]: {
		jobStatus: PortJobStatus.AWAITING_INVITATION
	},
	[PortJobStatusLabel.PDA_REQUESTED]: {
		jobStatus: PortJobStatus.PDA_REQUESTED
	},
	[PortJobStatusLabel.ACCEPTANCE_PENDING]: {
		jobStatus: PortJobStatus.APPOINTED
	},
	[PortJobStatusLabel.PDA_PENDING]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.DRAFT
	},
	[PortJobStatusLabel.PDA_VERIFICATION_PENDING]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.SUBMITTED
	},
	[PortJobStatusLabel.PDA_QUERIED]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.PDA_QUERIED_HUB]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.PDA_QUERIED_LPA]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.PDA_QUERIED_HUB_LPA]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.PDA_APPROVAL_PENDING]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.VERIFIED
	},
	[PortJobStatusLabel.PDA_APPROVED]: {
		jobStatus: PortJobStatus.PDA,
		financeStatus: FinanceStatus.APPROVED
	},
	[PortJobStatusLabel.JOB_IN_PROGRESS]: {
		jobStatus: PortJobStatus.EXECUTION
	},
	[PortJobStatusLabel.CE_PENDING]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.DRAFT
	},
	[PortJobStatusLabel.CE_QUERIED]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.CE_QUERIED_HUB]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.CE_QUERIED_LPA]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.CE_QUERIED_HUB_LPA]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.CE_VERIFICATION_PENDING]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.SUBMITTED
	},
	[PortJobStatusLabel.CE_APPROVAL_PENDING]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.VERIFIED
	},
	[PortJobStatusLabel.CE_APPROVED]: {
		jobStatus: PortJobStatus.CE,
		financeStatus: FinanceStatus.APPROVED
	},
	[PortJobStatusLabel.DA_PENDING]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.DRAFT
	},
	[PortJobStatusLabel.DA_QUERIED]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.DA_QUERIED_HUB]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.DA_QUERIED_LPA]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.DA_QUERIED_HUB_LPA]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.QUERIED
	},
	[PortJobStatusLabel.DA_VERIFICATION_PENDING]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.SUBMITTED
	},
	[PortJobStatusLabel.DA_APPROVAL_PENDING]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.VERIFIED
	},
	[PortJobStatusLabel.DA_APPROVED]: {
		jobStatus: PortJobStatus.DA,
		financeStatus: FinanceStatus.APPROVED
	},
	[PortJobStatusLabel.LPA_SETTLEMENT]: {
		jobStatus: PortJobStatus.LPA_SETTLEMENTS
	},
	[PortJobStatusLabel.DA_OUT_POSTING]: {
		jobStatus: PortJobStatus.DA_OUT_POSTINGS
	},
	[PortJobStatusLabel.HUB_SETTLEMENT]: {
		jobStatus: PortJobStatus.HUB_SETTLEMENTS
	},
	[PortJobStatusLabel.DA_SETTLEMENT]: {
		jobStatus: PortJobStatus.DA_SETTLEMENTS
	},
	[PortJobStatusLabel.COMPLETE]: {
		jobStatus: PortJobStatus.COMPLETE
	},
	[PortJobStatusLabel.CLOSED]: {
		jobStatus: PortJobStatus.CLOSED
	}
};

// Values used here differ from enum `PortJobStatusLabel` only in letter case
// both of them are used not only in filtering so now need to use lowerCase here
// TBD: Improve JobStatuses naming/labels logic
const statusesTypesLower = {};
forOwn(statusesTypes, (value, key) => {
	statusesTypesLower[key.toLowerCase()] = value;
});
export const statusesTypeMap = statusesTypesLower;
