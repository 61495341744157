import { AppState } from 'store-types';
import { findKey, each, get } from 'lodash';
import {
	Group,
	GroupTypeCodes,
	GroupApplicableScopeTypes,
	DataPermission,
	GroupPermissionScopeAccessTypes,
	PermissionScopeItem,
	GroupPermissionScopeTypes,
	DataPermissionChild,
	ScopeTabTypes
} from 'services/api/groups/groupsServiceTypes';
import { PermissionScopeTabsKeys } from 'sections/Groups/Group/EditGroup/constants';

export const getGroupScopePermissionsSelector = (
	state: AppState,
	scopeType: GroupApplicableScopeTypes
) => state.groups.groups.scopePermissions[scopeType];

const getScopeTypeHelper = (
	applicableScopeTypes: GroupApplicableScopeTypes[],
	checkingTypes: GroupApplicableScopeTypes[]
) => {
	let scopeType = checkingTypes[0];

	checkingTypes.forEach(checkingType => {
		if (applicableScopeTypes.includes(checkingType)) {
			scopeType = checkingType;
		}
	});

	return scopeType;
};

export const getScopeType = (
	tabKey: string,
	applicableScopeTypes: GroupApplicableScopeTypes[]
) => {
	let scopeType;

	switch (tabKey) {
		case PermissionScopeTabsKeys.COMPANIES_TAB_KEY:
			scopeType = getScopeTypeHelper(applicableScopeTypes, [
				GroupApplicableScopeTypes.MAIN_PRINCIPALS,
				GroupApplicableScopeTypes.PRINCIPALS,
				GroupApplicableScopeTypes.LPAS
			]);
			break;
		case PermissionScopeTabsKeys.VESSELS_TAB_KEY:
			scopeType = getScopeTypeHelper(applicableScopeTypes, [
				GroupApplicableScopeTypes.VESSELS
			]);
			break;
		case PermissionScopeTabsKeys.REGIONS_TAB_KEY:
			scopeType = getScopeTypeHelper(applicableScopeTypes, [
				GroupApplicableScopeTypes.COUNTRIES
			]);
			break;
		default:
			scopeType = GroupApplicableScopeTypes.MAIN_PRINCIPALS;
			break;
	}

	return scopeType;
};

export const getCurrentPermission = (
	dataPermissions: DataPermission[],
	scopeType: GroupApplicableScopeTypes
) => dataPermissions.find(item => item.scopeType === scopeType);
export const getAccessType = (
	formValues: {
		permissionScopeType: GroupPermissionScopeAccessTypes;
		values: PermissionScopeItem[];
	},
	currentPermission: DataPermission | undefined
) => {
	const permissionScopeType = formValues?.permissionScopeType;
	const permissionAccessType = currentPermission
		? currentPermission.accessType
		: GroupPermissionScopeAccessTypes.SPECIFIC;
	const accessType = permissionScopeType || permissionAccessType;
	return accessType;
};

const getPermissionScopeCompaniesTabAllMessage = (group: Group) => {
	let message;
	switch (group.groupType.code) {
		case GroupTypeCodes.ISS_LPA:
			message = 'The group has access to all operating entities';
			break;
		case GroupTypeCodes.ISS_HUB:
			message = 'The group has access to all Principals';
			break;
		case GroupTypeCodes.CUSTOMER:
			message = 'The group has access to all companies';
			break;
		default:
			message = '';
			break;
	}
	return message;
};

export const getPermissionScopeAllMessage = (group: Group, tabKey: string) => {
	let message;
	switch (tabKey) {
		case PermissionScopeTabsKeys.COMPANIES_TAB_KEY:
			message = getPermissionScopeCompaniesTabAllMessage(group);
			break;
		case PermissionScopeTabsKeys.VESSELS_TAB_KEY:
			message = 'The group has access to all vessels';
			break;
		case PermissionScopeTabsKeys.REGIONS_TAB_KEY:
			message = 'The group has access to all countries';
			break;
		default:
			message = '';
			break;
	}
	return message;
};

const hasScopeType = (
	applicableScopeTypes: GroupApplicableScopeTypes[] = [],
	scopeType: GroupApplicableScopeTypes
) => applicableScopeTypes.includes(scopeType);

export const isVisibleTab = (
	applicableScopeTypes: GroupApplicableScopeTypes[],
	tabKey: string
) => {
	let isVisible;

	switch (tabKey) {
		case PermissionScopeTabsKeys.COMPANIES_TAB_KEY:
			isVisible =
				hasScopeType(
					applicableScopeTypes,
					GroupApplicableScopeTypes.MAIN_PRINCIPALS
				) ||
				hasScopeType(
					applicableScopeTypes,
					GroupApplicableScopeTypes.PRINCIPALS
				) ||
				hasScopeType(applicableScopeTypes, GroupApplicableScopeTypes.LPAS);
			break;
		case PermissionScopeTabsKeys.VESSELS_TAB_KEY:
			isVisible = hasScopeType(
				applicableScopeTypes,
				GroupApplicableScopeTypes.VESSELS
			);
			break;
		case PermissionScopeTabsKeys.REGIONS_TAB_KEY:
			isVisible = hasScopeType(
				applicableScopeTypes,
				GroupApplicableScopeTypes.COUNTRIES
			);
			break;
		default:
			isVisible = false;
			break;
	}

	return isVisible;
};

export const getScopeTabTypes = (activeSubTabKey: string) => {
	let childType;
	let rootType;
	if (activeSubTabKey === PermissionScopeTabsKeys.VESSELS_TAB_KEY) {
		childType = GroupPermissionScopeTypes.VESSEL;
		rootType = GroupPermissionScopeTypes.VESSEL_TYPE;
	} else if (activeSubTabKey === PermissionScopeTabsKeys.REGIONS_TAB_KEY) {
		childType = GroupPermissionScopeTypes.COUNTRY;
		rootType = GroupPermissionScopeTypes.CONTINENT;
	} else {
		// activeSubTabKey === PermissionScopeTabsKeys.COMPANIES_TAB_KEY
		childType = GroupPermissionScopeTypes.COMPANY;
		rootType = GroupPermissionScopeTypes.OPERATING_ENTITY;
	}

	return {
		childType,
		rootType
	};
};

export const isChecked = (
	item: DataPermissionChild,
	scopeTabTypes: ScopeTabTypes
) => {
	return (
		item.entityType === scopeTabTypes.childType ||
		(item.entityType === scopeTabTypes.rootType && item.includesAllChildren)
	);
};

export const getCheckedPermissionItem = (
	formValues: { [key: string]: { value: string[] } },
	activeSubTabKey: string,
	scopeTabTypes: ScopeTabTypes
) => {
	const checkedKeys: string[] = [];
	each(
		get(formValues, [activeSubTabKey, 'values']),
		(item: DataPermissionChild) => {
			if (isChecked(item, scopeTabTypes)) {
				checkedKeys.push(item.entityId);
			}
		}
	);
	return checkedKeys;
};

export const getDefaultPermissionScopeActiveTab = (
	applicableScopeTypes: GroupApplicableScopeTypes[]
) =>
	findKey(PermissionScopeTabsKeys, (tabKeyValue: string) =>
		isVisibleTab(applicableScopeTypes, tabKeyValue)
	);
