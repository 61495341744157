import { DraftOption } from './draftsTypes';
import { UNSPECIFIED } from 'app-constants';

const DRAFT_RULE = RegExp(/\(Draft\)$/, 'g');
const hasDraftSuffix = (name: string) => {
	// eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
	return DRAFT_RULE.test(name);
};

const appendDraftSuffix = (name: string) =>
	hasDraftSuffix(name) ? name : `${name} (Draft)`;

const appendSuffixToDraft = (draft: DraftOption) => {
	return { ...draft, name: appendDraftSuffix(draft.name) };
};

export const removeDraftSuffix = (name: string) => {
	return name.replace(DRAFT_RULE, '').trim();
};

export const appendSuffixToDrafts = (drafts: DraftOption[]) => {
	return drafts.map(appendSuffixToDraft);
};

export const mergeOptionsWithDrafts = (options: DraftOption[]) => (
	drafts: DraftOption[]
) => {
	return [...drafts, ...options];
};

export const sortOptionsByNameAndKeepUnspecifiedAtTheEnd = (
	options: DraftOption[]
) =>
	options.sort((a, b) => {
		if (a.name === UNSPECIFIED) {
			return 1;
		}
		if (b.name === UNSPECIFIED) {
			return -1;
		}
		return a.name.localeCompare(b.name);
	});
