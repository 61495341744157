import { all, fork } from 'redux-saga/effects';
import retrieveUserSaga from './retrieveUserSaga';
import inviteUserSaga from './inviteUserSaga';
import reInviteUserSaga from './reInviteUserSaga';
import retrieveUsersSaga from './retrieveUsersSaga';
import updateUserSaga from './updateUserSaga';
import updateUserStatusSaga from './updateUserStatusSaga';
import retrieveUserStatusesSaga from './retrieveUserStatusesSaga';

export default function*() {
	yield all([
		fork(retrieveUserSaga),
		fork(inviteUserSaga),
		fork(reInviteUserSaga),
		fork(retrieveUsersSaga),
		fork(updateUserSaga),
		fork(updateUserStatusSaga),
		fork(retrieveUserStatusesSaga)
	]);
}
