import React, { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import history from 'services/history';
import { ScrollableLayout } from 'components';
import { AppNotifications } from 'containers';
import { DataFetcher } from 'utils/components';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import EditUserPageHeader from './EditUserPageHeader';
import EditUser from './EditUser';
import EditUserPageFooter from './EditUserPageFooter';
import {
	getIsUserProfileOfAuthorizedUser,
	getUserProfile
} from 'store/users/usersSelectors';
import { getForm } from 'store/form/selectors';
import { getBreadcrumbsForUserDetails } from './userDetailsSelectors';
import {
	retrieveUser,
	resetUserProfile as resetUser
} from 'store/users/actions';
import { USER_PROFILE_FORM } from 'store/users/usersConstants';
import { AppState } from 'store-types';

interface UserDetailsPageProps {
	userId: string;
}

const UserDetailsPage: FC<UserDetailsPageProps> = ({ userId }) => {
	const dispatch = useDispatch();
	const [isGroupsToAssignChanged, setIsGroupsToAssign] = useState(false);
	const form = useSelector(
		(state: AppState) => getForm(state, USER_PROFILE_FORM),
		shallowEqual
	);
	const breadcrumbs = useSelector<
		AppState,
		ReturnType<typeof getBreadcrumbsForUserDetails>
	>(getBreadcrumbsForUserDetails, shallowEqual);
	const userProfile = useSelector<AppState, ReturnType<typeof getUserProfile>>(
		getUserProfile,
		shallowEqual
	);
	const isProfilePage = useSelector(getIsUserProfileOfAuthorizedUser);

	const getProfileBreadcrumbs = useCallback(() => {
		if (isProfilePage) {
			return [{ title: 'User Profile', link: '/profile' }];
		}
		return breadcrumbs;
	}, [isProfilePage, breadcrumbs]);

	const retrieveUserProfile = useCallback(
		(userId: string) => {
			dispatch(retrieveUser(userId));
		},
		[dispatch]
	);

	const resetUserProfile = useCallback(() => {
		dispatch(resetUser());
	}, [dispatch]);

	const onGroupsToAssignChange = useCallback(() => {
		setIsGroupsToAssign(true);
	}, []);

	const isSaveEnabled = useMemo(
		() => form.valid && (!form.pristine || isGroupsToAssignChanged),
		[form.valid, form.pristine, isGroupsToAssignChanged]
	);

	return (
		<DataFetcher
			dispatch={() => retrieveUserProfile(userId)}
			reset={resetUserProfile}
			data={userProfile}
			isLoading={!userProfile}
		>
			{() =>
				userProfile && (
					<ScrollableLayout direction={'horizontal'} stretch>
						<RouteBreadcrumbs items={getProfileBreadcrumbs()} />
						<EditUserPageHeader userProfile={userProfile} />
						<AppNotifications.Alert />
						<AppNotifications.Notification />
						<EditUser
							userProfile={userProfile}
							isProfilePage={isProfilePage}
							onGroupsToAssignChange={onGroupsToAssignChange}
						/>
						<EditUserPageFooter
							onCancel={goBack}
							disabled={!isSaveEnabled}
							isProfilePage={isProfilePage}
						/>
					</ScrollableLayout>
				)
			}
		</DataFetcher>
	);
};

function goBack() {
	history.goBack();
}

export default UserDetailsPage;
