import * as React from 'react';
import Jobs from './Jobs';
import JobsSummary from './JobsSummary';
import styles from './JobsTab.module.scss';

const JobsTab = () => (
	<div className={styles.jobsTab}>
		<Jobs />
		<JobsSummary />
	</div>
);

export default JobsTab;
