import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	markPortCallDocumentNotIssuedAsync,
	retrieveUploadedDocuments
} from '../actions';
import { PortCallDocumentNotIssuedPayload } from '../actions/markPortCallDocumentNotIssued';
import { getActivePortCallId } from '../../portcalls/selectors';
import { getPortCallPlaceholderConcurrencyToken } from '../selectors';
import Api from 'services/api';

export function* markPortCallDocumentNotIssuedWorker({
	payload
}: Action<PortCallDocumentNotIssuedPayload>): SagaIterator {
	yield put(markPortCallDocumentNotIssuedAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const placeholderConcurrencyToken = yield select(
		getPortCallPlaceholderConcurrencyToken,
		payload.documentId,
		payload.portCallPlaceholderId
	);
	const updateRequest = { ...payload, portCallId };
	try {
		const response = yield call(
			Api.PortCallDocuments.markPortCallDocumentNotIssued,
			{
				...updateRequest,
				placeholderConcurrencyToken
			}
		);
		yield all([
			put(
				markPortCallDocumentNotIssuedAsync.done({
					result: response.data,
					params: updateRequest,
					response
				})
			),
			put(retrieveUploadedDocuments({ portCallId }))
		]);
	} catch (error) {
		yield put(
			markPortCallDocumentNotIssuedAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* markPortCallDocumentNotIssuedWatcher(): SagaIterator {
	yield takeLatest(
		markPortCallDocumentNotIssuedAsync.type,
		markPortCallDocumentNotIssuedWorker
	);
}
