import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallsState } from '../portCallsState';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';

function onRetrievePortCallsDuplicatesSuccess(
	state: PortCallsState,
	action: Success<RetrievePortCallsRequest, RetrievePortCallsResponse>
): PortCallsState {
	const {
		result: { elements },
		params: { limit }
	} = action;

	return {
		...state,
		duplicates: {
			...state.duplicates,
			all: elements
		},
		hasMoreDuplicates: limit === elements.length
	};
}

export const onRetrievePortCallsDuplicates = makeFetchStatusReducers<
	PortCallsState,
	'fetchStatuses',
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
>('fetchStatuses', 'duplicates', {
	doneReducer: onRetrievePortCallsDuplicatesSuccess
});

export const onResetPortCallsDuplicates = (
	state: PortCallsState
): PortCallsState => ({
	...state,
	duplicates: {
		...state.duplicates,
		all: []
	},
	hasMoreDuplicates: true,
	fetchStatuses: {
		...state.fetchStatuses,
		duplicates: FetchStatus.IDLE
	}
});
