import * as React from 'react';
import { connect } from 'react-redux';
import { Popover, Button } from 'components/antd';
import { EntityPermissionComposeId } from 'services/api/permissions/permissionsServiceTypes';
import { getIsEntityPermissionLoading } from 'store/permissions/selectors/entityPermissionSelectors';
import { AppState } from 'store-types';
import { getCargoMenuColumnId } from '../helpers';

interface CargoSettingsPopoverProps extends EntityPermissionComposeId {
	popoverContent: React.ReactNode;
	cargoId: string;
	// from mapStateToProps
	isLoading: boolean;
}

const CargoSettingsPopover = ({
	popoverContent,
	cargoId,
	isLoading,
	entityKey
}: CargoSettingsPopoverProps) => (
	<Popover
		content={popoverContent}
		type="dropdown"
		trigger="click"
		placement="bottomRight"
		getPopupContainer={() =>
			document.getElementById(getCargoMenuColumnId(cargoId)) as HTMLElement
		}
	>
		<Button
			transparent
			type="primary"
			icon="settings"
			loading={!!entityKey && isLoading}
		/>
	</Popover>
);

export default connect(
	(
		state: AppState,
		{
			entityKey,
			entityType
		}: Pick<CargoSettingsPopoverProps, 'entityKey' | 'entityType'>
	) => ({
		isLoading: getIsEntityPermissionLoading(state, {
			entityKey,
			entityType
		})
	})
)(CargoSettingsPopover);
