import actionCreator from '../actionCreator';
import { MergeOperationUnitPayload } from 'store/vesselProgramme/vesselProgrammeState';

export const openMergeOperationUnitModal = actionCreator<{
	operationUnitId: string;
}>('OPEN_MERGE_OPERATION_UNIT_MODAL');

export const cancelMergeOperationUnitModal = actionCreator(
	'CANCEL_MERGE_OPERATION_UNIT_MODAL'
);

export const mergeOperationUnit = actionCreator<MergeOperationUnitPayload>(
	'MERGE_OPERATION_UNIT'
);
