import React from 'react';
import { connect } from 'react-redux';
import {
	getDocumentImageUrl,
	getIsDocumentImageLoaded
} from 'store/documents/selectors';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';

import { retrieveDocumentImage } from 'store/documents/actions';

import DocumentViewerImagePreview from './DocumentViewerImagePreview';
import { AppState } from 'store-types';

export interface DocumentViewerImagePreviewContainerOwnProps {
	documentId: string;
	activePage: number;
}

interface DocumentViewerImagePreviewContainerProps
	extends DocumentViewerImagePreviewContainerOwnProps {
	imageUrl: string;
	imageUrlLoaded: boolean;
	retrieveDocumentImage: typeof retrieveDocumentImage;
	portCallId: string;
}
class DocumentViewerImagePreviewContainer extends React.Component<
	DocumentViewerImagePreviewContainerProps
> {
	componentDidMount() {
		if (this.props.activePage) {
			this.retrieveDocumentImage();
		}
	}

	componentDidUpdate(prevProps: DocumentViewerImagePreviewContainerProps) {
		const { activePage } = this.props;
		if (prevProps.activePage !== activePage) {
			this.retrieveDocumentImage();
		}
	}

	retrieveDocumentImage = () =>
		this.props.retrieveDocumentImage({
			documentId: this.props.documentId,
			portCallId: this.props.portCallId,
			page: this.props.activePage
		});

	render() {
		const { imageUrl, imageUrlLoaded } = this.props;
		return (
			<DocumentViewerImagePreview
				imageUrl={imageUrl}
				imageUrlLoaded={imageUrlLoaded}
			/>
		);
	}
}

export default connect(
	(state: AppState, ownProps: DocumentViewerImagePreviewContainerOwnProps) => ({
		imageUrl: getDocumentImageUrl(
			state,
			ownProps.documentId,
			ownProps.activePage
		),
		imageUrlLoaded: getIsDocumentImageLoaded(state, ownProps.documentId),
		portCallId: getActivePortCallId(state)
	}),
	{
		retrieveDocumentImage
	}
)(DocumentViewerImagePreviewContainer);
