import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveServicesAsync,
	resetServices,
	retrieveServiceCategoriesAsync,
	resetServiceCategories,
	addServiceCategoryAsync,
	updateServiceCategoryAsync,
	deleteServiceCategoryAsync
} from './actions';
import {
	onRetrieveServices,
	onResetServices,
	onRetrieveServiceCategories,
	onResetServiceCategories,
	onAddServiceCategory,
	onUpdateServiceCategory,
	onDeleteServiceCategory
} from './reducers';
import { initialState, ServicesState } from './servicesState';

export default reducerWithInitialState<ServicesState>(initialState)
	// Retrieve Services
	.case(retrieveServicesAsync.started, onRetrieveServices.started)
	.case(retrieveServicesAsync.done, onRetrieveServices.done)
	.case(retrieveServicesAsync.failed, onRetrieveServices.failed)
	.case(resetServices, onResetServices)

	// Retrieve Service Categories
	.case(
		retrieveServiceCategoriesAsync.started,
		onRetrieveServiceCategories.started
	)
	.case(retrieveServiceCategoriesAsync.done, onRetrieveServiceCategories.done)
	.case(
		retrieveServiceCategoriesAsync.failed,
		onRetrieveServiceCategories.failed
	)
	.case(resetServiceCategories, onResetServiceCategories)

	// Add Service Categories
	.case(addServiceCategoryAsync.started, onAddServiceCategory.started)
	.case(addServiceCategoryAsync.done, onAddServiceCategory.done)
	.case(addServiceCategoryAsync.failed, onAddServiceCategory.failed)

	// Update Service Categories
	.case(updateServiceCategoryAsync.started, onUpdateServiceCategory.started)
	.case(updateServiceCategoryAsync.done, onUpdateServiceCategory.done)
	.case(updateServiceCategoryAsync.failed, onUpdateServiceCategory.failed)

	// Delete Service Categories
	.case(deleteServiceCategoryAsync.started, onDeleteServiceCategory.started)
	.case(deleteServiceCategoryAsync.done, onDeleteServiceCategory.done)
	.case(deleteServiceCategoryAsync.failed, onDeleteServiceCategory.failed);
