import { reject } from 'lodash';
import { FinanceState } from '../financeState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	DeleteServiceRequest,
	DeleteServiceResponse,
	PortCallFinance,
	ServiceSection
} from 'services/api/finance/financeServiceTypes';
import { Success, Failure } from 'typescript-fsa';

/**
 * We have to remove id of the removed service from the serviceSections
 */
export function getCleanServiceSections(
	serviceSections: ServiceSection[] = [],
	serviceId: string
) {
	return serviceSections.map(section => {
		if (section.sections && section.sections.length !== 0) {
			return {
				...section,
				services: reject(section.services, id => id === serviceId),
				sections: section.sections.map(subsection => {
					return {
						...subsection,
						services: reject(subsection.services, id => id === serviceId)
					};
				})
			};
		}
		return {
			...section,
			services: reject(section.services, id => id === serviceId)
		};
	});
}

export function onDeleteServiceStarted(
	state: FinanceState,
	action: DeleteServiceRequest
): FinanceState {
	const { jobServiceId } = action;
	return {
		...state,
		updateStatuses: {
			...state.updateStatuses,
			serviceSettings: {
				[jobServiceId]: FetchStatus.PENDING
			}
		}
	};
}

export function onDeleteServiceSuccess(
	state: FinanceState,
	action: Success<DeleteServiceRequest, DeleteServiceResponse>
): FinanceState {
	const { jobServiceId } = action.params;
	const finance = state.finance || ({} as PortCallFinance);
	return {
		...state,
		finance: {
			...finance,
			serviceSections: getCleanServiceSections(
				finance.serviceSections,
				jobServiceId
			),
			services: {
				...finance.services,
				elements: reject(finance.services.elements, { id: jobServiceId })
			}
		},
		updateStatuses: {
			...state.updateStatuses,
			serviceSettings: {
				[jobServiceId]: FetchStatus.SUCCESS
			}
		}
	};
}

export function onDeleteServiceFailure(
	state: FinanceState,
	action: Failure<DeleteServiceRequest, Error>
): FinanceState {
	const { jobServiceId } = action.params;
	return {
		...state,
		updateStatuses: {
			...state.updateStatuses,
			serviceSettings: {
				[jobServiceId]: FetchStatus.FAILURE
			}
		}
	};
}
