import * as React from 'react';
import { CrewWageDisbursementPortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { ShowMoreText } from 'components/ReadMore';
import { OptionalValue, Text } from 'components';
import { Row, Col } from 'components/antd';
import OverviewTotalAmountGrid from '../TotalAmount/OverviewTotalAmountGrid';

export default ({
	operation
}: {
	operation: CrewWageDisbursementPortJobOperation;
}) => (
	<Row>
		<Col xs={4} lg={3}>
			<Row>
				<Col xs={3}>
					<Text weight="bold">Total Number of Crew</Text>
				</Col>
				<Col xs={9}>
					<OptionalValue value={operation.numberOfCrew}>
						<Text>{operation.numberOfCrew}</Text>
					</OptionalValue>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<OverviewTotalAmountGrid data={operation.totalAmounts} />
				</Col>
			</Row>
		</Col>
		<Col xs={4} lg={8}>
			<Text upper weight="bold">
				Operation Details
			</Text>
			<ShowMoreText showLines={3}>{operation.details || 'None'}</ShowMoreText>
		</Col>
	</Row>
);
