import actionCreator from '../opticConfigActionCreator';
import { UpdateKpiDetailsByIdRequest } from 'services/api/opticConfiguration/opticConfigServiceTypes';

const UPDATE_KPI_DETAILS_BY_ID = 'UPDATE_KPI_DETAILS_BY_ID';

export const updateKpiDetailsById = actionCreator(UPDATE_KPI_DETAILS_BY_ID);

export const updateKpiDetailsByIdAsync = actionCreator.async<
	UpdateKpiDetailsByIdRequest,
	void,
	Error
>(UPDATE_KPI_DETAILS_BY_ID, {
	templateParams: {
		entityName: 'KPI & Alerts'
	}
});
