import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
	Row,
	Col,
	Input,
	TextArea,
	Select,
	Checkbox,
	Tooltip
} from 'components/antd';
import Autocomplete, {
	AutocompleteOption
} from 'components/antd/Autocomplete/Autocomplete';

import {
	SearchCompaniesRequest,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';

import {
	FormFieldName,
	FORM
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AppState } from 'store-types';
import {
	getIsAgentsSwapPossible,
	getPortJobAgencyRoles,
	getPortJobAgencyTypes,
	getIsMainPrincipalTypeCLS,
	getActiveJobStatusTimeComparisonSelector,
	getIsStatusFurtherWaitAppointWaitInvite,
	getLinkedAppointerIsNonIssHubPrincipal
} from 'store/portJobs/selectors';
import {
	getIsCurrentUserHub,
	getIsCurrentUserPrincipal
} from 'store/auth/selectors';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import FieldAppointer from './Field.Appointer';
import FieldAppointerContact from './Field.AppointerContact';
import { getValues } from 'store/form/selectors';
import { getJobType, getContactDetailsCompany } from '../../page2Selectors';
import { DraftType, NewCompanyDraft } from 'store/drafts/draftsState';
import Agents from './Field/Agents';
import { PortJobStatus, JobType } from 'store/portJobs/constants';
import { PortJobStatusComparisonType } from 'services/api/portJobs/portJobsServiceTypes';
import { AutoCompleteDrafts } from 'components/Drafts';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { CompanyAgentAutocompleteOption } from 'services/api/ports/portsServiceTypes';
import InfoAgentPopover from 'sections/PortCall/PortJob/Info/InfoAgentPopover/InfoAgentPopover';
import { resetCompaniesContactDetails } from 'store/companies/actions/retrieveCompaniesContactDetails';
import styles from './CreatePortJobForm.module.scss';
import { change } from 'redux-form';
import { getCompanyJobTypes } from 'store/companies/selectors';
import { retrieveCompanyJobTypes } from 'store/companies/actions';

type SearchType = (value: string) => Promise<AutocompleteOption[]>;

const INSTRUCTIONS_MAX_LENGTH = 10000;

export type FieldsProps = {
	withParentId: boolean;
	isAgentFieldDisabled?: boolean;
	onAddDraftClick: (draftType: DraftType, FormFieldName: string) => void;
	onAddAppointerDraftClick: (
		draftType: DraftType,
		FormFieldName: string
	) => void;
	searchCompanies: (
		options?: SearchCompaniesRequest,
		withParentId?: boolean
	) => SearchType;
	isInEditMode?: boolean;
};

const Fields: React.SFC<FieldsProps> = ({
	onAddDraftClick,
	withParentId,
	searchCompanies,
	isAgentFieldDisabled,
	isInEditMode = false,
	onAddAppointerDraftClick
}) => {
	const dispatch = useDispatch();
	const contactDetailsCompany = useSelector(
		getContactDetailsCompany,
		shallowEqual
	);
	const formValues = useSelector(
		(state: AppState) => getValues<FormData>(state, FORM.portJob),
		shallowEqual
	);

	const getConfigJobTypes = useSelector((state: AppState) =>
		getCompanyJobTypes(state)
	);

	const getConfigJobTypesOnEdit = useCallback(
		key => {
			dispatch(retrieveCompanyJobTypes({ companyId: key }));
		},
		[dispatch]
	);

	useEffect(() => {
		return () => {
			getConfigJobTypes.length = 0;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const agencyRoles = useSelector(getPortJobAgencyRoles);
	const agencyTypes = useSelector(getPortJobAgencyTypes);
	const isFormVisible = useSelector(
		(state: AppState) =>
			!!(getPortJobAgencyRoles(state) && getPortJobAgencyTypes(state))
	);
	const isMainPrincipalTypeCLS = useSelector(getIsMainPrincipalTypeCLS);
	const isCurrentUserWithRoleHub = useSelector(getIsCurrentUserHub);
	const isCurrentUserPrincipal = useSelector(getIsCurrentUserPrincipal);
	const isStatusCEOrLater = useSelector(
		getActiveJobStatusTimeComparisonSelector(
			PortJobStatus.CE,
			PortJobStatusComparisonType.SAME_OR_AFTER
		),
		shallowEqual
	);

	const isStatusWaitAcceptOrFurther = useSelector(
		getActiveJobStatusTimeComparisonSelector(
			PortJobStatus.AWAITING_ACCEPTANCE,
			PortJobStatusComparisonType.SAME_OR_AFTER
		),
		shallowEqual
	);

	const jobCode = useSelector(getJobType)?.code;
	const isJobTypeNomination =
		formValues.jobTypeId?.key === 'Nomination' || jobCode === 'Nomination';
	const isJobTypeCharterer =
		formValues.jobTypeId?.key === 'Charterers Oversight' ||
		jobCode === 'ChartererOversight';
	const isJobTypeAppointment =
		formValues.jobTypeId?.key === 'Appointment' || jobCode === 'Appointment';
	const isJobLinkedToAppointment = useSelector(getIsAgentsSwapPossible);
	const isAppointmentLinkedAppointerIsNonIssHubPrincipal = useSelector(
		getLinkedAppointerIsNonIssHubPrincipal
	);
	const isJobStatusBeforeWaitAppoint = useSelector(
		getIsStatusFurtherWaitAppointWaitInvite
	);

	const [showAllChecked, setShowAllChecked] = useState(
		formValues[FormFieldName.SHOW_ALL]
	);

	const changeNonIssCheckbox = useCallback(
		isNonIss => {
			dispatch(
				change(
					FORM.portJob,
					FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL,
					isNonIss
				)
			);
		},
		[dispatch]
	);

	const changeShowAllCheck = useCallback(() => {
		dispatch(change(FORM.portJob, FormFieldName.SHOW_ALL, showAllChecked));
	}, [dispatch, showAllChecked]);

	const isShowAllVisible = useMemo(() => {
		if (!isCurrentUserPrincipal) {
			changeShowAllCheck();
			if (isAppointmentLinkedAppointerIsNonIssHubPrincipal) {
				changeNonIssCheckbox(isAppointmentLinkedAppointerIsNonIssHubPrincipal);
			}

			if (getConfigJobTypes.length === 0 && !isMainPrincipalTypeCLS) {
				getConfigJobTypesOnEdit(formValues.hubPrincipalCompany?.key);
			}

			return getConfigJobTypes.includes(JobType.NOMINATION);
		} else {
			return false;
		}
	}, [
		changeNonIssCheckbox,
		changeShowAllCheck,
		formValues.hubPrincipalCompany,
		getConfigJobTypes,
		getConfigJobTypesOnEdit,
		isAppointmentLinkedAppointerIsNonIssHubPrincipal,
		isCurrentUserPrincipal,
		isMainPrincipalTypeCLS
	]);

	const isAgentsDisabled =
		jobCode === JobType.APPOINTMENT
			? isAgentFieldDisabled
			: isAgentFieldDisabled || !isJobStatusBeforeWaitAppoint;
	const getPrincipalLabel = useCallback(() => {
		if (jobCode === JobType.APPOINTMENT) {
			return 'Appointer';
		} else if (jobCode === JobType.NOMINATION) {
			return 'Nominator';
		} else {
			return 'Charterer';
		}
	}, [jobCode]);

	const getPrincipalFieldName = useCallback(() => {
		return jobCode !== JobType.APPOINTMENT
			? FormFieldName.NOMINATOR
			: FormFieldName.APPOINTER_COMPANY;
	}, [jobCode]);

	const getContactFieldLabel = useCallback(
		(id: string) => {
			if (jobCode === JobType.APPOINTMENT) {
				return `Appointer Contact ${id}:`;
			} else if (jobCode === JobType.NOMINATION) {
				return `Nominator Contact ${id}:`;
			} else {
				return `Charterer Contact ${id}:`;
			}
		},
		[jobCode]
	);

	const isFieldAppointerDisabled = useCallback(() => {
		if (jobCode === JobType.APPOINTMENT && isStatusCEOrLater) {
			return true;
		} else if (jobCode !== JobType.APPOINTMENT && isStatusWaitAcceptOrFurther) {
			return true;
		}
		return false;
	}, [jobCode, isStatusCEOrLater, isStatusWaitAcceptOrFurther]);

	const principalDrafts = useSelector((state: AppState) =>
		getCompanyDraftsByType(state, DraftType.PRINCIPAL)
	);

	const chartererDrafts = useSelector((state: AppState) =>
		getCompanyDraftsByType(state, DraftType.CHARTERER)
	);

	const appointerDrafts = [...principalDrafts, ...chartererDrafts];

	const appointerDraftOptions: CompanyAgentAutocompleteOption[] = useMemo(() => {
		return (appointerDrafts as NewCompanyDraft[]).map(draft => {
			if (draft.isDraft) {
				return {
					id: draft.id,
					name: draft.name,
					companyEmail: draft.email,
					companyTel: draft.telephone,
					address: {
						countryName: draft.countryCode,
						city: draft.city
					}
				};
			}
			return draft;
		}) as CompanyAgentAutocompleteOption[];
	}, [appointerDrafts]);

	const listNonISSHubPrincipal: string[] = [];
	const customOption = useCallback(
		(option: CompanyAgentAutocompleteOption) => {
			option.isNonIssHubPrincipal && listNonISSHubPrincipal.push(option.name);
			return <InfoAgentPopover data={option} title="Company details" />;
		},
		[listNonISSHubPrincipal]
	);

	const onSelected = useCallback(
		option => {
			const isIss = listNonISSHubPrincipal.includes(option.label)
				? true
				: false;
			changeNonIssCheckbox(isIss);
			dispatch(resetCompaniesContactDetails());
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, listNonISSHubPrincipal]
	);

	const isContactRequired = () => {
		if (isJobTypeAppointment) {
			return !formValues[FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL];
		} else {
			return true;
		}
	};

	return (
		<>
			{isFormVisible && (
				<Row>
					<Col sm={3} className={styles.checkBoxParentColumn}>
						{!isJobTypeNomination && !isJobTypeCharterer && isShowAllVisible && (
							<Checkbox.ReduxFormItem
								className={styles.checkBox}
								name={FormFieldName.SHOW_ALL}
								marginBottom={false}
								disabled={isFieldAppointerDisabled()}
								onClick={() => setShowAllChecked(showAllChecked ? false : true)}
								checked={formValues[FormFieldName.SHOW_ALL]}
								value={formValues[FormFieldName.SHOW_ALL]}
							>
								<Tooltip
									trigger="hover"
									title={
										'Lists all Principal’s and Charterer’s to create an appointment to link to a Nomination'
									}
									placement="top"
								>
									Show All
								</Tooltip>
							</Checkbox.ReduxFormItem>
						)}

						{!isJobTypeNomination &&
							!isJobTypeCharterer &&
							isShowAllVisible && (
								<Checkbox.ReduxFormItem
									className={styles.nonIssHubCheckBox}
									name={FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL}
									marginBottom={false}
									checked={
										formValues[FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL]
									}
									value={formValues[FormFieldName.CHECK_NON_ISS_HUB_PRINCIPAL]}
								></Checkbox.ReduxFormItem>
							)}
						{!showAllChecked ? (
							<FieldAppointer
								name={getPrincipalFieldName()}
								label={getPrincipalLabel()}
								hubPrincipalCompany={formValues.hubPrincipalCompany}
								onChange={onSelected}
								required
								disabled={isFieldAppointerDisabled()}
							/>
						) : (
							!isJobTypeNomination &&
							!isJobTypeCharterer && (
								<Autocomplete.ReduxFormItem
									allowClear
									name={getPrincipalFieldName()}
									label={getPrincipalLabel()}
									onSelect={onSelected}
									onSearch={searchCompanies({
										types: [
											OrganisationType.PRINCIPAL,
											OrganisationType.CHARTERER
										]
									})}
									required
									disabled={isFieldAppointerDisabled()}
									getCustomOption={customOption}
								/>
							)
						)}
					</Col>
					<Col sm={3}>
						<Input.ReduxFormItem
							type="text"
							name={FormFieldName.CUSTOMER_REF}
							label="Customer Ref:"
							help
							maxLength={100}
						/>
					</Col>
					<Col sm={3}>
						<FieldAppointerContact
							name={FormFieldName.CONTACT_1}
							label={getContactFieldLabel('1')}
							contactDetailsCompany={contactDetailsCompany}
							anotherContact={formValues[FormFieldName.CONTACT_2]}
							required={isContactRequired()}
							cache={false}
							showAllChecked={showAllChecked}
						/>
					</Col>
					<Col sm={3}>
						<FieldAppointerContact
							name={FormFieldName.CONTACT_2}
							label={getContactFieldLabel('2')}
							contactDetailsCompany={contactDetailsCompany}
							anotherContact={formValues[FormFieldName.CONTACT_1]}
						/>
					</Col>
					<Col sm={3}>
						<Select.ReduxFormItem
							name={FormFieldName.AGENCY_TYPE}
							label="Agency Type:"
							placeholder="Select type"
							labelInValue
							required
							disabled={isStatusCEOrLater}
						>
							{agencyTypes.map(option => (
								<Select.Option key={option.code}>{option.name}</Select.Option>
							))}
						</Select.ReduxFormItem>
					</Col>
					<Col sm={6}>
						<Agents
							isInEditMode={isInEditMode}
							disabled={isAgentsDisabled}
							search={searchCompanies}
							withParentId={withParentId}
							onAddDraftClick={onAddDraftClick}
							IsSwapDisabledForNominatioOrCO={
								isJobLinkedToAppointment &&
								(isJobTypeCharterer || isJobTypeNomination)
							}
						/>
					</Col>
					{isMainPrincipalTypeCLS === false && (
						<>
							<Col sm={3}>
								<Select.ReduxFormItem
									name={FormFieldName.AGENT_ROLE}
									label="Agent role:"
									labelInValue
									placeholder="Select role"
									required
								>
									{agencyRoles.map(option => (
										<Select.Option key={option.code}>
											{option.name}
										</Select.Option>
									))}
								</Select.ReduxFormItem>
							</Col>
							{!isJobTypeNomination && !isJobTypeCharterer && (
								<Col sm={3}>
									<Autocomplete.ReduxFormItem
										allowClear
										name={FormFieldName.NOMINATOR}
										label="Nominator:"
										onSearch={searchCompanies({
											types: [
												OrganisationType.PRINCIPAL,
												OrganisationType.CHARTERER
											]
										})}
										disabled={isStatusCEOrLater}
										getCustomOption={customOption}
									/>
								</Col>
							)}
						</>
					)}
					{(isJobTypeNomination || isJobTypeCharterer) && (
						<Col sm={3}>
							{isCurrentUserWithRoleHub ? (
								<AutoCompleteDrafts.ReduxFormItem
									allowClear
									name={FormFieldName.APPOINTER_COMPANY}
									label="Appointer"
									onSearch={searchCompanies({
										types: [
											OrganisationType.PRINCIPAL,
											OrganisationType.CHARTERER
										]
									})}
									disabled={isStatusCEOrLater || !isJobStatusBeforeWaitAppoint}
									addDraftLinkTitle={'Add Company Manually'}
									onAddDraftClick={() =>
										onAddAppointerDraftClick(
											DraftType.PRINCIPAL,
											FormFieldName.APPOINTER_COMPANY
										)
									}
									getCustomOption={customOption}
									drafts={appointerDraftOptions}
								/>
							) : (
								<Autocomplete.ReduxFormItem
									allowClear
									name={FormFieldName.APPOINTER_COMPANY}
									label="Appointer"
									onSearch={searchCompanies({
										types: [
											OrganisationType.PRINCIPAL,
											OrganisationType.CHARTERER
										]
									})}
									disabled={isStatusCEOrLater || !isJobStatusBeforeWaitAppoint}
									getCustomOption={customOption}
								/>
							)}
						</Col>
					)}
					{isCurrentUserWithRoleHub && !isMainPrincipalTypeCLS && (
						<Col sm={3}>
							<Autocomplete.ReduxFormItem
								name={FormFieldName.PAYING_PARTY}
								label="Paying Party:"
								onSearch={searchCompanies({
									types: [OrganisationType.PRINCIPAL]
								})}
								required
								disabled={isStatusCEOrLater}
							/>
						</Col>
					)}
					<Col sm={12}>
						<TextArea.ReduxFormItem
							name={FormFieldName.INSTRUCTION_TO_ISS}
							label="Instructions to HUB:"
							rows={3}
							maxLength={INSTRUCTIONS_MAX_LENGTH}
						/>
						<TextArea.ReduxFormItem
							name={FormFieldName.INSTRUCTION_TO_LPA}
							label="Instructions to LPA:"
							rows={3}
							maxLength={INSTRUCTIONS_MAX_LENGTH}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default Fields;
