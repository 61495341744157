import { PermissionsState } from '../permissionsState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';
import {
	EntityPermissionRequest,
	EntityPermissionResponse
} from 'services/api/permissions/permissionsServiceTypes';

export function onRetrieveEntityPermissionsStarted(
	state: PermissionsState,
	{ entityType, entityKey }: EntityPermissionRequest
): PermissionsState {
	return {
		...state,
		fetchStatusesByEntityType: {
			...state.fetchStatusesByEntityType,
			[entityType]: {
				...state.fetchStatusesByEntityType[entityType],
				[entityKey]: FetchStatus.PENDING
			}
		}
	};
}

export function onRetrieveEntityPermissionsSuccess(
	state: PermissionsState,
	{
		params: { entityKey, entityType },
		result
	}: Success<EntityPermissionRequest, EntityPermissionResponse>
): PermissionsState {
	return {
		...state,
		byEntityType: {
			...state.byEntityType,
			[entityType]: {
				...state.byEntityType[entityType],
				[entityKey]: result
			}
		},
		fetchStatusesByEntityType: {
			...state.fetchStatusesByEntityType,
			[entityType]: {
				...state.fetchStatusesByEntityType[entityType],
				[entityKey]: FetchStatus.SUCCESS
			}
		}
	};
}

export function onRetrieveEntityPermissionsFailed(
	state: PermissionsState,
	{ params: { entityType, entityKey } }: Failure<EntityPermissionRequest, Error>
): PermissionsState {
	return {
		...state,
		fetchStatusesByEntityType: {
			...state.fetchStatusesByEntityType,
			[entityType]: {
				...state.fetchStatusesByEntityType[entityType],
				[entityKey]: FetchStatus.FAILURE
			}
		}
	};
}
