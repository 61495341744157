import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveCountriesAsync, resetCountries } from './actions';
import {
	onRetrieveCountriesStarted,
	onRetrieveCountriesSuccess,
	onRetrieveCountriesFailed,
	onResetCountries
} from './reducers';

import { initialState } from './countriesState';

export default reducerWithInitialState(initialState)
	.case(retrieveCountriesAsync.started, onRetrieveCountriesStarted)
	.case(retrieveCountriesAsync.done, onRetrieveCountriesSuccess)
	.case(retrieveCountriesAsync.failed, onRetrieveCountriesFailed)
	.case(resetCountries, onResetCountries);
