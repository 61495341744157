import * as React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { isEqual } from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
	SupplierInvoicePortJobService,
	SupplierInvoicesPortJobServiceActionCode
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import {
	Checkbox,
	Row,
	Alert,
	Form,
	TextArea,
	InputNumber
} from 'components/antd';
import {
	FormField,
	PortJobFormData,
	PortJobError
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { Asteriks, Gap, Link, FileIcon } from 'components';
import { InvoiceDetailRow } from 'sections/SupplierInvoice/components/InvoiceDetailRow/InvoiceDetailRow';
import { PATHS } from 'sections/App/RouteParams';
import SupplierDetailsColumn from 'sections/SupplierInvoice/components/SupplierDetailsColumn/SupplierDetailsColumn';
import {
	getEntityMetadataAction,
	getReasonMessageByAction,
	getIsActionAllowed
} from 'store/metadata/utils/metadataUtils';
import { getAddSupplierInvoiceCurrencyDecimalPlaces } from 'store/supplierInvoice/selectors';
import WithMinus, { MinusPosition } from 'components/WithMinus/WithMinus';
import styles from '../InvoiceDetails.module.scss';
import { AppState } from 'store-types';
import { Justify } from 'components/types';
import {
	getCheckedServicesById,
	getPageCountServiceSelected
} from 'store/form/supplierInvoiceContextualization/selectors';

interface InvoiceDetailsPortJobServiceProps {
	portJobFormValues: PortJobFormData | undefined;
	pagesPortJobFormValues: PortJobFormData | undefined;
	portJobErrors: PortJobError | undefined;
	service: SupplierInvoicePortJobService;
	isInitialized: boolean;
	field: string;
	activePage: number;
	isCredit: boolean;
	portJobId: string;
	portJobIdx: number;
	serviceIdx: number;
	change: InjectedFormProps['change'];
	getIsPortJobServiceSelected: (
		portJobId: string,
		serviceId: string,
		activePage: number
	) => boolean;
	// from mapStateToProps
	supplierInvoiceCurrencyDecimalPlaces: number;
	checkedServicesById: { [id: string]: number };
	pageCountServiceSelected: number;
}

const ServiceAlert: React.SFC = ({ children }) => (
	<Gap bottom>
		<Alert showIcon type="warning" message={null} description={children} />
	</Gap>
);
const getServiceFormValue = (
	portJobFormValues: PortJobFormData | undefined,
	serviceId: string
) =>
	portJobFormValues?.services
		? portJobFormValues.services.find(({ id }) => id === serviceId)
		: undefined;

/**
 * This component and all the components it uses (including form items) is
 * intentionally not connected to Redux. The reason is that there might be a lot of services to show
 * and connection to store results in performance issues (https://support.dataart.com/browse/IPP-22795)
 */

class InvoiceDetailsPortJobService extends React.Component<
	InvoiceDetailsPortJobServiceProps
> {
	componentDidMount() {
		if (this.props.isInitialized) {
			this.props.change(
				`${this.props.field}.portJobs.${this.props.portJobIdx}.id`,
				this.props.portJobId
			);
			this.props.change(
				`${this.props.field}.portJobs.${this.props.portJobIdx}.services.${this.props.serviceIdx}`,
				{ id: this.props.service.id }
			);
		}
	}
	shouldComponentUpdate(nextProps: InvoiceDetailsPortJobServiceProps) {
		const {
			service,
			portJobFormValues,
			pagesPortJobFormValues,
			isCredit,
			getIsPortJobServiceSelected
		} = this.props;
		const isPortJobServiceSelected = getIsPortJobServiceSelected(
			nextProps.portJobId,
			nextProps.service.id,
			nextProps.activePage
		);
		return (
			!isEqual(nextProps.service, service) ||
			!isEqual(
				getServiceFormValue(portJobFormValues, service.id),
				getServiceFormValue(nextProps.portJobFormValues, nextProps.service.id)
			) ||
			!isEqual(
				getServiceFormValue(pagesPortJobFormValues, service.id),
				getServiceFormValue(
					nextProps.pagesPortJobFormValues,
					nextProps.service.id
				)
			) ||
			(isPortJobServiceSelected && !isEqual(isCredit, nextProps.isCredit))
		);
	}

	getField = () =>
		`${this.props.field}.portJobs.${this.props.portJobIdx}.services.${this.props.serviceIdx}`;

	getPortJobField = () =>
		`portJobs.${this.props.portJobIdx}.services.${this.props.serviceIdx}`;

	onServiceSelectedChange = (e: CheckboxChangeEvent) => {
		this.props.change(
			`${this.getField()}.${FormField.PORT_JOB_SERVICE_IS_SELECTED}`,
			e.target.checked
		);
		if (this.props.pageCountServiceSelected === 0 && e.target.checked) {
			this.props.change(
				`${this.getPortJobField()}.${FormField.PORT_JOB_SERVICE_IS_SELECTED}`,
				e.target.checked
			);
		}
		if (this.props.pageCountServiceSelected === 1 && !e.target.checked) {
			this.props.change(
				`${this.getPortJobField()}.${FormField.PORT_JOB_SERVICE_IS_SELECTED}`,
				e.target.checked
			);
		}
	};

	onServiceTotalAmountChange = (value: string) => {
		this.props.change(
			`${this.getPortJobField()}.${FormField.PORT_JOB_SERVICE_TOTAL_AMOUNT}`,
			value
		);
	};

	onServiceCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
		this.props.change(
			`${this.getPortJobField()}.${FormField.PORT_JOB_SERVICE_COMMENT}`,
			e.target.value
		);

	getServiceTotalValidationStatus = (): 'success' | 'error' => {
		const { portJobErrors, serviceIdx } = this.props;
		const serviceError = portJobErrors?.services?.[serviceIdx];
		return serviceError?.totalAmount?.length ? 'error' : 'success';
	};

	render() {
		const {
			portJobId,
			service,
			getIsPortJobServiceSelected,
			isCredit,
			portJobFormValues,
			supplierInvoiceCurrencyDecimalPlaces,
			activePage,
			checkedServicesById
		} = this.props;
		const field = this.getField();
		const portJobsField = this.getPortJobField();
		const { metadata, name, associatedToSupplierInvoiceId } = service;
		const serviceFormValue = getServiceFormValue(portJobFormValues, service.id);
		const metadataAction = getEntityMetadataAction<
			SupplierInvoicesPortJobServiceActionCode
		>(metadata.actions, SupplierInvoicesPortJobServiceActionCode.CONTEXTUALIZE);
		const canServiceBeContextualized = getIsActionAllowed(metadataAction);
		const nameComponent = <span className={styles.serviceName}>{name}</span>;
		const href = `/${PATHS.supplierinvoices}/${associatedToSupplierInvoiceId}`;
		const isPortJobServiceSelected = getIsPortJobServiceSelected(
			portJobId,
			service.id,
			activePage
		);
		const disabledMessage = getReasonMessageByAction<
			SupplierInvoicesPortJobServiceActionCode
		>(metadataAction);
		const count = checkedServicesById[service.id];
		return (
			<InvoiceDetailRow
				service={service}
				portJobId={portJobId}
				isPortJobServiceSelected={isPortJobServiceSelected}
			>
				{disabledMessage && (
					<ServiceAlert>
						{disabledMessage}
						{/* finishing message when disabledReasonCode is supplierInvoiceContextualize_HasAssociatedSupplierInvoice */}
						{associatedToSupplierInvoiceId && (
							<Link href={href} target="_blank">
								another supplier invoice
							</Link>
						)}
					</ServiceAlert>
				)}
				<Row between="xs" middle="xs">
					<SupplierDetailsColumn>
						{canServiceBeContextualized ? (
							<Form.Item marginBottom={false}>
								<Checkbox
									name={`${field}.${FormField.PORT_JOB_SERVICE_IS_SELECTED}`}
									key={service.id}
									checked={isPortJobServiceSelected}
									onChange={e => this.onServiceSelectedChange(e)}
								>
									&nbsp;
									{nameComponent}
								</Checkbox>
							</Form.Item>
						) : (
							nameComponent
						)}
					</SupplierDetailsColumn>
					{isPortJobServiceSelected && (
						<SupplierDetailsColumn xs={5}>
							<Form.Item
								marginBottom={false}
								required
								validateStatus={this.getServiceTotalValidationStatus()}
							>
								<InputNumber
									decimalPart={supplierInvoiceCurrencyDecimalPlaces}
									maxDigits={100}
									withDelimiter={false}
									name={`${portJobsField}.${FormField.PORT_JOB_SERVICE_TOTAL_AMOUNT}`}
									addonBefore={
										<WithMinus
											position={isCredit ? MinusPosition.AFTER : undefined}
										>
											<Asteriks position="right">Service Total</Asteriks>
										</WithMinus>
									}
									onChangeFormattedValue={this.onServiceTotalAmountChange}
									value={serviceFormValue?.totalAmount}
								/>
							</Form.Item>
						</SupplierDetailsColumn>
					)}
					<SupplierDetailsColumn
						xs={1}
						sm={1}
						md={1}
						lg={1}
						justify={Justify.END}
					>
						{count > 0 && <FileIcon count={count} />}
					</SupplierDetailsColumn>
				</Row>
				{isPortJobServiceSelected && (
					<Form.Item label="Comment">
						<TextArea
							name={`${portJobsField}.${FormField.PORT_JOB_SERVICE_COMMENT}`}
							maxLength={1000}
							rows={2}
							onChange={this.onServiceCommentChange}
							value={serviceFormValue ? serviceFormValue.serviceComment : ''}
						/>
					</Form.Item>
				)}
			</InvoiceDetailRow>
		);
	}
}

export default connect(
	(
		state: AppState,
		{ service }: Pick<InvoiceDetailsPortJobServiceProps, 'service'>
	) => ({
		supplierInvoiceCurrencyDecimalPlaces: getAddSupplierInvoiceCurrencyDecimalPlaces(
			state
		),
		checkedServicesById: getCheckedServicesById(state),
		pageCountServiceSelected: getPageCountServiceSelected(state, service.id)
	})
)(InvoiceDetailsPortJobService);
