import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import {
	RetrieveDirectBillsResponse,
	RetrieveDirectBillsRequest
} from 'services/api/directBills/directBillsServiceTypes';
import { DirectBillsState } from '../directBillsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveDirectBillsSuccess = (
	state: DirectBillsState,
	{
		result,
		params
	}: Success<RetrieveDirectBillsRequest, RetrieveDirectBillsResponse>
) => {
	const { limit, count } = params;
	return {
		...state,
		count,
		byId: {
			...state.byId,
			...keyBy(result.elements, item => item.id)
		},
		allIds: [
			...state.allIds,
			...result.elements.map(directBill => directBill.id)
		],
		hasMore: limit === result.elements.length
	};
};

const onRetrieveDirectBillsFailure = (state: DirectBillsState) => ({
	...state,
	hasMore: false
});

export const onRetrieveDirectBills = makeFetchStatusReducers<
	DirectBillsState,
	'fetchStatuses',
	RetrieveDirectBillsRequest,
	RetrieveDirectBillsResponse
>('fetchStatuses', 'all', {
	doneReducer: onRetrieveDirectBillsSuccess,
	failedReducer: onRetrieveDirectBillsFailure
});
