import React from 'react';
import { Flex, Ellipsis } from 'components';
import { Justify } from 'components/types';
import { Upload, Icon, Modal } from 'components/antd';
import { UploadDocumentButton } from 'containers';
import UploadedFilesList from './UploadedFilesList';
import {
	UploadCustomRequest,
	DocumentUploadFile,
	UploadType,
	UploadDocumentRequestDoneAction
} from 'services/api/documents/documentsServiceTypes';
import styles from './UploadDraggerBox.module.scss';

interface UploadDraggerBoxProps {
	isOverriding?: boolean;
	isUploading: boolean;
	multiple?: boolean;
	disabled?: boolean;
	confirmationModalText?: string;
	accept?: string;
	dropBoxText?: string;
	onUpload?: (file: DocumentUploadFile) => void;
	type?: UploadType;
	uploadAndProcess?: (request: UploadCustomRequest) => void;

	defaultUpload?: boolean;
	onDone?: UploadDocumentRequestDoneAction; // if `defaultUpload` is true, action to call when upload is finished.
	showProgress?: boolean; // if `defaultUpload` is true, if to show modal with upload progress
}
interface UploadDraggerBoxState {
	file: DocumentUploadFile | null;
}
class UploadDraggerBox extends React.Component<
	UploadDraggerBoxProps,
	UploadDraggerBoxState
> {
	static UploadedFilesList: typeof UploadedFilesList;

	static defaultProps: Partial<UploadDraggerBoxProps> = {
		showProgress: true
	};

	dropBoxTextDefault = 'Drop files to upload or';
	dropBoxDisabledTextDefault = 'Uploading is disabled';

	state = {
		file: null
	};

	customRequest = (request: UploadCustomRequest) => {
		if (this.props.defaultUpload && this.props.uploadAndProcess) {
			this.props.uploadAndProcess(request);
		} else {
			this.upload(request.file);
		}
	};

	upload = (file: DocumentUploadFile | null) => {
		if (!file) {
			return;
		}
		if (this.props.onUpload) {
			this.props.onUpload(file);
		}
	};

	onConfirmUpload = () => {
		this.upload(this.state.file);
		// close modal
		this.setState({
			file: null
		});
	};

	onCancelUpload = () => {
		this.setState({
			file: null
		});
	};

	/**
	 * TODO
	 * Antd Upload new version has added {openFileDialogOnClick} to prevent opening a dialog
	 */
	onOpenFileDialogOnClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
	};

	stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	beforeUpload = (file: DocumentUploadFile) => {
		const canUpload = !this.props.isOverriding;
		if (!canUpload) {
			this.setState({
				file
			});
		}
		return !this.props.isOverriding;
	};

	getUploadProps = () => ({
		customRequest: this.customRequest,
		showUploadList: false,
		accept: this.props.accept,
		disabled: this.props.disabled,
		beforeUpload: this.beforeUpload,
		uploadType: this.props.type
	});

	getDropBoxText = () => {
		return (
			<>
				{this.props.disabled
					? this.dropBoxDisabledTextDefault
					: this.dropBoxTextDefault || this.props.dropBoxText}
				&nbsp;
				{!this.props.disabled && (
					<span onClick={this.stopPropagation} onDrop={this.stopPropagation}>
						{this.getUploadButton()}
					</span>
				)}
			</>
		);
	};

	getUploadButton = () => {
		const { defaultUpload, showProgress = true, disabled } = this.props;
		const uploadProps = this.getUploadProps();

		return defaultUpload ? (
			<UploadDocumentButton
				onDone={this.props.onDone}
				customRequest={this.customRequest}
				disabled={disabled}
				link
				showProgress={showProgress}
				uploadType={this.props.type}
			>
				browse
			</UploadDocumentButton>
		) : (
			<Upload.Button {...uploadProps} buttonType="primary" link>
				browse
			</Upload.Button>
		);
	};

	render() {
		const { multiple } = this.props;
		const uploadProps = this.getUploadProps();

		return (
			<>
				<div onClick={this.onOpenFileDialogOnClick}>
					<Upload.Dragger
						{...uploadProps}
						className={styles.dragAndDropContainer}
						multiple={multiple}
					>
						<Flex align="center" justify={Justify.CENTER}>
							{this.props.isUploading ? (
								<Ellipsis>Uploading File</Ellipsis>
							) : (
								<>
									<Icon type="cloud-upload" color="standard" offset="right" />
									{this.getDropBoxText()}
								</>
							)}
						</Flex>
					</Upload.Dragger>
				</div>
				{this.state.file && this.props.confirmationModalText && (
					<Modal
						visible
						okText="Continue"
						okProps={{ transparent: true }}
						onOk={this.onConfirmUpload}
						onCancel={this.onCancelUpload}
					>
						{this.props.confirmationModalText}
					</Modal>
				)}
			</>
		);
	}
}

UploadDraggerBox.UploadedFilesList = UploadedFilesList;

export default UploadDraggerBox;
