import { DeleteDAActionPayload } from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const DELETE_DA = 'DELETE_DA';

export const deleteDAAsync = actionCreator.async<
	DeleteDAActionPayload,
	null,
	Error
>(DELETE_DA, {
	templateParams: {
		entityName: 'DA'
	},
	behaviour: { type: 'react-to-changes-child' }
});

export const deleteDA = actionCreator<DeleteDAActionPayload>(DELETE_DA);
