import * as React from 'react';
import Threads from './Threads';
import { connect } from 'react-redux';
import { ScrollableLayout, Loading } from 'components';
import { AppNotifications } from 'containers';

import {
	getBreadcrumsForThreads,
	getIsThreadsPageReload
} from 'store/threads/selectors';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { AppState } from 'store-types';

interface ThreadsRouteProps {
	// from mapStateToProps
	breadcrumbs: BreadcrumbItem[];
	isThreadPageReload: boolean;
}

const ThreadsRoute: React.SFC<ThreadsRouteProps> = props => {
	return (
		<ScrollableLayout>
			<RouteBreadcrumbs items={props.breadcrumbs} />
			<AppNotifications.Alert />
			<AppNotifications.Notification />
			{props.isThreadPageReload ? <Loading /> : <Threads />}
		</ScrollableLayout>
	);
};

export default connect((state: AppState) => ({
	breadcrumbs: getBreadcrumsForThreads(state),
	isThreadPageReload: getIsThreadsPageReload(state)
}))(ThreadsRoute);
