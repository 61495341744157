import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import { RetrievePortJobStatusesResponse } from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrievePortJobStatusesStarted = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		statuses: FetchStatus.PENDING
	}
});
export const onRetrievePortJobStatusesSuccess = (
	state: PortJobState,
	action: Success<null, RetrievePortJobStatusesResponse>
) => ({
	...state,
	statuses: action.result,
	fetchStatuses: {
		...state.fetchStatuses,
		statuses: FetchStatus.SUCCESS
	}
});
export const onRetrievePortJobStatusesFailed = (state: PortJobState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		statuses: FetchStatus.FAILURE
	}
});
