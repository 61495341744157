import { FormErrors } from 'redux-form';
import { validateRequired } from 'utils/validations';
import { FormData } from './EnableOperationsFormTypes';

export const validate = (values: FormData): FormErrors<FormData> =>
	(({
		list: values.list?.map(sections => {
			return {
				serviceSection: sections?.serviceSection.map(selectSection => {
					return {
						section: validateRequired(selectSection.section)
					};
				}),
				alternateServiceSection: sections?.alternateServiceSection.map(
					section => {
						return {
							altSection: validateRequired(section.altSection),
							service: validateRequired(section.service)
						};
					}
				)
			};
		})
	} as unknown) as FormErrors<FormData>);
