import * as React from 'react';
import AntLayout from 'antd/lib/layout';
import { BasicProps } from 'antd/lib/layout/layout';
import { SiderProps } from 'antd/lib/layout/Sider';
import styles from './Layout.module.scss';

export class Layout extends React.Component<BasicProps, {}> {
	static Header: React.ComponentClass<BasicProps>;
	static Footer: React.ComponentClass<BasicProps>;
	static Content: React.ComponentClass<BasicProps>;
	static Sider: React.ComponentClass<SiderProps>;

	render() {
		return <AntLayout {...this.props} className={styles.antLayout} />;
	}
}

Layout.Header = AntLayout.Header;
Layout.Footer = AntLayout.Footer;
Layout.Content = AntLayout.Content;
Layout.Sider = AntLayout.Sider;

export default Layout;
