import { call } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';

import { updateContactAction } from './../reducers';
import { ContactType } from 'services/api/messages/messagesServiceTypes';

import Api from 'services/api';
import { navigateTo } from 'utils';

const apiCall = Api.Messages.updateContact;
const { async } = updateContactAction;

export const updateContactWatcher = makeTakeEveryWatcher<
	ContactType,
	undefined,
	Error
>({
	api: apiCall,
	async,
	*onSuccess() {
		yield call(navigateTo, '/messages');
	}
});

export default updateContactWatcher;
