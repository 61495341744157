import * as React from 'react';
import { CanalTransitPortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { ShowMoreText } from 'components/ReadMore';
import { Text } from 'components';
import { Row, Col } from 'components/antd';

export default ({ operation }: { operation: CanalTransitPortJobOperation }) => (
	<Row>
		<Col xs={8} lg={4}>
			Vessel Condition
			<br />
			<strong>{operation.vesselCondition}</strong>
		</Col>
		<Col xs={4} lg={8}>
			<Text upper weight="bold">
				Operation Details
			</Text>
			<ShowMoreText showLines={3}>{operation.details || 'None'}</ShowMoreText>
		</Col>
	</Row>
);
