import {
	CustodyTransferType,
	UpdatedByInfo
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

interface StepButtonHidingAttributes {
	[prop: string]: string;
}

interface StepContextButtonHidingAttributes {
	current?: StepButtonHidingAttributes;
	previous?: StepButtonHidingAttributes;
	next?: StepButtonHidingAttributes;
}

export const attributeCombinationsToHidePOI: StepContextButtonHidingAttributes[] = [
	{
		current: {
			rotationStepType: 'Movement',
			movementType: 'InboundPortVoyage'
		},
		previous: { rotationStepType: 'POI' }
	},
	{
		current: { rotationStepType: 'SailingPoint' },
		previous: { rotationStepType: 'POI' }
	},
	{
		current: { rotationStepType: 'POI' },
		previous: { rotationStepType: 'ArrivalPoint' }
	},
	{
		current: { rotationStepType: 'POI' },
		next: { rotationStepType: 'SailingPoint' }
	},
	{ current: { rotationStep: 'ArrivalPoint' } }
];

export const custodyTransferOrdered: CustodyTransferType[] = [
	'Before',
	'During',
	'After'
];

export const DeletePOIErrorMessages = {
	SERVER_ERROR: `This POI can't be removed. It's not possible to verify wether this POI has associated costs. Please try again later.`,
	HAS_EVENTS: `POI can't be removed as it has actual events associated with it. Please remove events first in order to remove POI.`,
	HAS_EVENTS_SURROUNDING: `POI can't be removed as 'Shifting' has actual events associated with it. Please remove those events first in order to remove POI' and original POI is not deleted.`,
	HAS_COSTS: `POI can't be removed as it has costs associated with it. Please update Finance page first in order to remove POI.`
};

export const ROTATION_STEP_DELETE_MODAL = 'vesselProgramme/rotationStep/delete';

export const getDescription = (
	isInEditMode: boolean,
	updatedByInfo?: UpdatedByInfo
) => {
	const mainDescription = `Vessel programme has been updated by `;
	const userDescription = updatedByInfo
		? `${updatedByInfo.updatedByName} from ${updatedByInfo.updatedByCompanyName}`
		: `another user`;
	const additionalDescription = isInEditMode
		? ` while you were editing it. You cannot save your changes due to that, please refresh the page.`
		: `.`;
	return mainDescription + userDescription + additionalDescription;
};
