import * as React from 'react';

import { UserStatus as StatusTag, Flex } from 'components';
import { Tag, Icon } from 'components/antd';
import { ColumnProps } from 'components/Table/InfiniteTable';
import { FormattedTime } from 'containers';
import { ModalContext } from 'context';

import { UsersModalsName } from './UsersModals/UsersModalsTypes';
import { User, UserStatus } from 'services/api/users/userServiceTypes';
import UserSettingsColumn from './UsersTable/columns/UserSettingsColumn';

export type UserListColumns = Array<ColumnProps<User>>;

const getUserListColumns = (): UserListColumns => {
	return [
		{
			title: 'Full Name',
			dataIndex: 'fullName',
			key: 'fullName',
			width: '10%',
			render: (_text, record) => {
				return (
					<strong>
						{record.firstName} {record.lastName}
					</strong>
				);
			}
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			width: '15%'
		},
		/* isSuperAdmin */ {
			title: 'Company',
			dataIndex: 'company.name',
			key: 'company.name',
			width: '8%',
			render: text => (text ? text : 'N/A')
		},
		{
			title: 'Groups',
			dataIndex: 'groups',
			key: 'groups',
			width: '34%',
			render: (_text, record) => {
				if (!record.groups.length) {
					return 'N/A';
				}
				return record.groups.map(group => (
					<Tag borderless key={`group-${group.id}`}>
						<strong>{group.name}</strong>
						{group.isDeleted && ' - DELETED'}
					</Tag>
				));
			}
		},
		{
			title: 'Cellphone',
			dataIndex: 'cellphone',
			key: 'cellphone',
			width: '10%',
			render: text => (text ? text : 'N/A')
		},
		{
			title: 'Latest Activity',
			dataIndex: 'lastActivityOn',
			key: 'lastActivityOn',
			width: '8%',
			render: text => {
				if (!text) {
					return 'N/A';
				}
				return (
					<span>
						<FormattedTime value={text} convertFromUtc />
					</span>
				);
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			className: 'col-status',
			width: '10%',
			render: (_, record) => {
				if (record.status === UserStatus.REGISTRATION_PENDING) {
					return (
						<Flex>
							<StatusTag status={record.status} />
							<ModalContext.Consumer>
								{({ showModal }) => (
									<Icon
										type="arrow-forward"
										offset="left"
										onClick={() => showModal(UsersModalsName.INVITE, record)}
										title="Resend Invitation"
									/>
								)}
							</ModalContext.Consumer>
						</Flex>
					);
				}
				return <StatusTag status={record.status} />;
			}
		},
		{
			key: 'settings',
			width: '5%',
			render: (_text, user: User) => {
				return <UserSettingsColumn user={user} />;
			}
		}
	];
};

export default getUserListColumns;
