import { FundingRequestsState } from '../financeFundingsState';

export const onSetActiveFundingRequestId = (
	state: FundingRequestsState,
	fundingRequestId: string
): FundingRequestsState => {
	return {
		...state,
		context: {
			...state.context,
			activeFundingRequestId: fundingRequestId
		}
	};
};
