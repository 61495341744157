import * as React from 'react';
import * as H from 'history';
import queryString from 'query-string';
import { signOutRedirect } from 'services/userManager';
import Loading from 'components/Loading/Loading';

interface LogoutProps {
	location: H.Location;
}

class Logout extends React.Component<LogoutProps> {
	componentDidMount() {
		const queryParams = queryString.parse(this.props.location.search || '');
		signOutRedirect(queryParams);
	}

	render() {
		return <Loading />;
	}
}

export default Logout;
