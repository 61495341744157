import React, { FC } from 'react';
import { PortCallTabBaseProps } from '..';
import { Position } from 'components';
import { FINANCE_CONTAINER_ID } from '../Finance/FinanceConstants';
import Savings from './Savings';

type SavingsRouteProps = PortCallTabBaseProps;

const SavingsRoute: FC<SavingsRouteProps> = ({
	match: {
		params: { portCallId, portJobCode }
	}
}) => {
	return (
		<Position id={FINANCE_CONTAINER_ID}>
			<Savings portCallId={portCallId} portJobCode={portJobCode} />
		</Position>
	);
};

export default SavingsRoute;
