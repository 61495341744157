import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { CancelPortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { cancelPortJobAsync } from 'store/portJobs/actions';
import {
	getPortJobConcurrencyTokenByCode,
	getActivePortJobCode,
	getIsJobLinked,
	getSendCancelEmailJobStatus
} from '../portJobsSelectors';
import { closeModal, openModal } from 'store/modals/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { notify } from 'store/notifications/actions';
import { getIsCurrentUserHub } from 'store/auth/selectors';
import { getActivePortJobParentPortCallId } from 'store/portJobs/selectors';
import { navigateTo } from 'utils';
import { CANCEL_EMAIL_MODAL } from 'sections/PortCall/PortJob/ActionModal/CancelPortJobModal';

const apiCall = Api.PortJobs.cancelPortJob;

export const executor = function*(payload: CancelPortJobRequest): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const isJobLinked = yield select(getIsJobLinked);
	const isHubUser = yield select(getIsCurrentUserHub);
	const isNotSendCancelEmailJobStatus = yield select(
		getSendCancelEmailJobStatus
	);
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		portJobCode
	);
	const parentPortCallId = yield select(getActivePortJobParentPortCallId);

	const request = {
		...payload,
		portCallId,
		portJobCode,
		isCancelled: true,
		concurrencyToken
	};
	yield put(cancelPortJobAsync.started(request));
	try {
		const response = yield call(apiCall, request);
		if (parentPortCallId) {
			yield call(
				navigateTo,
				`/portcalls/${parentPortCallId}/jobs/${portJobCode}/overview`
			);
		}
		yield put(
			cancelPortJobAsync.done({
				result: undefined,
				params: request,
				response
			})
		);
		if (isHubUser && isJobLinked) {
			yield put(notify.success('Jobs have been unlinked successfully.'));
		}
		if (isHubUser && !isNotSendCancelEmailJobStatus) {
			yield put(openModal(CANCEL_EMAIL_MODAL));
		}
	} catch (error) {
		yield put(
			cancelPortJobAsync.failed({
				error,
				params: request
			})
		);
	} finally {
		yield put(closeModal(payload.modalId));
	}
};

export const cancelPortJobWatcher = makeTakeLatestWatcher<
	CancelPortJobRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async: cancelPortJobAsync
	},
	executor
);

export default cancelPortJobWatcher;
