import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DocumentPageViewer from 'sections/DocumentPageViewer/DocumentPageViewer';
import DocumentPage from './OperationDocumentPage';

import {
	deletePortCallDocumentPage,
	downloadPortCallDocuments
} from 'store/portCallDocuments/actions';

import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { getCanDeleteOperationsDocumentPage } from 'store/operations/operationsSelectors';

interface OperationDocumentPageViewerProps {
	portCallId: string;
	document: PortCallDocument;
	isDocumentPDF: boolean;
}
function OperationDocumentPageViewer({
	portCallId,
	document,
	isDocumentPDF
}: OperationDocumentPageViewerProps) {
	const dispatch = useDispatch();
	const isDeleteVisible = useSelector(getCanDeleteOperationsDocumentPage);
	const downloadAllOnFinish = useCallback(() => {
		dispatch(
			downloadPortCallDocuments({
				portCallDocumentIds: [document.id]
			})
		);
	}, [dispatch, document]);

	const onPageDelete = useCallback(
		(page: PortCallDocumentPage) => {
			dispatch(
				deletePortCallDocumentPage({
					portCallId,
					documentId: document.id,
					actualNumber: page.actualNumber
				})
			);
		},
		[dispatch, portCallId, document]
	);

	return (
		<DocumentPageViewer
			onFinish={downloadAllOnFinish}
			returnUrl={`/portcalls/${portCallId}/operations/documents`}
			document={document}
			isDocumentPDF={isDocumentPDF}
			total={document.pages.length}
		>
			{({ activePage, onPageSelect }) => (
				<div>
					{document.pages.map((page, index) => {
						return (
							<DocumentPage
								key={page.number}
								index={index}
								activePage={activePage}
								page={page}
								fileName={document.file.fileName}
								documentId={document.id}
								onSelect={onPageSelect}
								onDelete={onPageDelete}
								isDeleteVisible={isDeleteVisible}
							/>
						);
					})}
				</div>
			)}
		</DocumentPageViewer>
	);
}

export default OperationDocumentPageViewer;
