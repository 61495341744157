import * as React from 'react';
import { PageFooter } from 'components';
import { InjectedWizardFormBaseProps } from 'components/WizardForm';
import { Justify } from 'components/types';
import { Row, Col, Button } from 'components/antd';
import { ButtonRow } from 'components/ButtonRow/ButtonRow';
import { connect } from 'react-redux';
import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';
import { getSelectedLinkJob } from 'store/portJobs/portJobsSelectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { getIsPortJobFetching } from 'store/portJobs/selectors';
import { AppState } from 'store-types';
import styles from './LinkJob.module.scss';
import { setPortJobContext } from 'store/portJobs/actions';
import { getLinkJobButtonStatus } from 'store/linkJob/selectors/linkJobSelectors';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface LinkJobWizardFooterProps extends InjectedWizardFormBaseProps {
	onFinish: () => void;
	onCancel: () => void;
	// from mapStateToProps
	isLoading: boolean;
	isLinkJobSelected: string;
	activeWizard: WizardFormProps;
	isLinkJobButtonDisabled: boolean;
	//from mapActionToProps
	setPortJobContext: typeof setPortJobContext;
}

interface LinkJobWizardFooterState {
	isLinkJobBtnDisabled: boolean;
}

class LinkJobWizardFooter extends React.Component<
	LinkJobWizardFooterProps,
	LinkJobWizardFooterState
> {
	constructor(props: LinkJobWizardFooterProps) {
		super(props);
		this.state = {
			isLinkJobBtnDisabled: false
		};
	}
	onNext = () => {
		const { activePageLast, onFinish, activePageState, nextPage } = this.props;
		if (activePageLast) {
			this.setState({
				isLinkJobBtnDisabled: true
			});
			return onFinish();
		}
		if (activePageState.onNextClick) {
			return activePageState.onNextClick();
		}
		return nextPage();
	};

	componentDidUpdate() {
		const { activePageFirst, isLinkJobSelected, nextPage } = this.props;
		if (activePageFirst && isLinkJobSelected) {
			nextPage();
		}
	}

	onBack = () => {
		this.props.setPortJobContext({ selectedLinkJobCode: '' });
		this.props.prevPage();
	};

	render() {
		const {
			activePageFirst,
			onCancel,
			activePageLast,
			isLoading,
			isLinkJobButtonDisabled
		} = this.props;
		const tooltip: TooltipProps | undefined = isLinkJobButtonDisabled
			? {
					title: 'Please link all cargoes before you can link jobs',
					placement: 'topRight'
			  }
			: undefined;

		return (
			<PageFooter>
				<Row>
					<Col xs={4}>
						{!activePageFirst && (
							<Button
								type="primary"
								transparent
								icon="left"
								onClick={this.onBack}
								className={styles.backButton}
							>
								Back
							</Button>
						)}
					</Col>
					<Col xs={8} justify={Justify.END}>
						<ButtonRow>
							<Button
								type="primary"
								transparent
								onClick={onCancel}
								className={styles.cancelButton}
							>
								Cancel
							</Button>

							{activePageLast && (
								<Button
									type={'primary'}
									loading={isLoading}
									onClick={this.onNext}
									disabled={
										isLinkJobButtonDisabled || this.state.isLinkJobBtnDisabled
									}
									tooltip={tooltip}
								>
									Link Job
								</Button>
							)}
						</ButtonRow>
					</Col>
				</Row>
			</PageFooter>
		);
	}
}

export default connect(
	(state: AppState) => ({
		activeWizard: getActiveWizard(state),
		isLoading: getIsPortJobFetching(state),
		isLinkJobSelected: getSelectedLinkJob(state),
		isLinkJobButtonDisabled: getLinkJobButtonStatus(state)
	}),
	{
		setPortJobContext
	}
)(LinkJobWizardFooter);
