import appointToPortJobWatcher from 'store/portJobs/sagas/appointToPortJobSaga';
import confirmPortJobWatcher from 'store/portJobs/sagas/confirmPortJobSaga';
import { all, fork } from 'redux-saga/effects';

import retrievePortJobThreadsSaga from './retrievePortJobThreads/retrievePortJobThreadsSaga';
import retrievePortJobThreadsCycleSaga from './retrievePortJobThreadsCycleSaga';
import retrievePortJobCustodyRolesWatcher from './dictionaries/retrievePortJobCustodyRolesSaga';
import retrievePortJobWatcher from './retrievePortJobSaga';
import retrievePortJobForCycleWatcher from './retrievePortJobForCycleSaga';
import { deletePortJobWatcher } from './deletePortJobSaga';
import retrievePortJobOperationWatcher from './retrievePortJobOperationSaga';
import editPortJobOperationWatcher from './editPortJobOperationSaga';
import editPortJobWatcher from './editPortJobData/editPortJobSaga';
import retrievePortJobSummaryWatcher from './retrievePortJobSummarySaga';
import { retrievePortJobStatusesWatcher } from './retrievePortJobStatusesSaga';
import addPortJobOperationWatcher from './addOperationSaga';
import { retrieveAppointersWatcher } from './dictionaries/retrieveAppointersSaga';
import deletePortJobOperationWatcher from './deletePortJobOperationSaga';
import retrieveEditPortJobDataSaga from './editPortJobData/retrieveEditPortJobDataSaga';
import checkPortJobPermissionsSaga from './checkPortJobPermissionsSaga';
import retrieveAppointDataWatcher from './retrieveAppointDataSaga';
import retrieveAcceptanceDataWatcher from './retrieveAcceptanceDataSaga';
import clearControllingAgentWatcher from './clearPortJobControllingAgentSaga';
import reactToChangesForPortCallOverviewWatcher from './reactToChangesForPortCallOverviewSaga';
import retrieveMailboxesForPrincipalWatcher from './retrieveMailboxesForPrincipalSaga';
import cancelPortJobWatcher from './cancelPortJobSaga';
import changeCustodyRoleWatcher from './changeCustodyRoleSaga';
import updateSavingsWatcher from './updateSavingsSaga';
import uncancelPortJobWatcher from './uncancelPortJobSaga';
import retrievePortJobMetadataWatcher from './retrievePortJobMetadataSaga';
import retrieveAvailableGroupsWatcher from './retrieveAvailableGroupsSaga';
import setDefaultMessagingGroupWatcher from './setDefaultMessagingGroupSaga';
import addAppointmentFromNominationWatcher from './addAppointmentFromNominationSaga';
import togglePortJobStatusWatcher from './togglePortJobStatusSaga';
import moveJobToPreviousStatusWatcher from './moveJobToPreviousStatusSaga';
import requestPDAWatcher from './requestPDASaga';
import retrieveExtendedMessagingDataWatcher from './retrieveExtendedMessagingDataSaga';
import sendExtendedMessagesWatcher from './sendExtendedMessagesSaga';
import inviteAgentToPortJobWatcher from './inviteAgentToPortJobSaga';
import unlinkJobsWatcher from './unlinkJobsSaga';
import retrieveExtendedMessagingConfigurationWatcher from './retrieveExtendedMessagingConfigurationSaga';
import clonePortJobWatcher from './clonePortJobSaga';
import toggleOperationsStatusWatcher from './toggleOperationsStatusSaga';
import enableOperationsCheckWatcher from './enableOperationsCheckSaga';
import updateEnableOperationsWatcher from './updateEnableOperationsSaga';

export default function*() {
	yield all([
		fork(addPortJobOperationWatcher),
		fork(appointToPortJobWatcher),
		fork(retrieveMailboxesForPrincipalWatcher),
		fork(confirmPortJobWatcher),
		fork(cancelPortJobWatcher),
		fork(uncancelPortJobWatcher),
		fork(changeCustodyRoleWatcher),
		fork(updateSavingsWatcher),
		fork(retrievePortJobCustodyRolesWatcher),
		fork(retrievePortJobStatusesWatcher),
		fork(retrievePortJobSummaryWatcher),
		fork(retrievePortJobThreadsSaga),
		fork(retrievePortJobThreadsCycleSaga),
		fork(retrievePortJobWatcher),
		fork(retrievePortJobForCycleWatcher),
		fork(retrievePortJobOperationWatcher),
		fork(editPortJobOperationWatcher),
		fork(editPortJobWatcher),
		fork(retrieveAppointersWatcher),
		fork(retrieveAppointersWatcher),
		fork(deletePortJobWatcher),
		fork(retrieveEditPortJobDataSaga),
		fork(deletePortJobOperationWatcher),
		fork(checkPortJobPermissionsSaga),
		fork(retrieveAppointDataWatcher),
		fork(retrieveAcceptanceDataWatcher),
		fork(clearControllingAgentWatcher),
		fork(reactToChangesForPortCallOverviewWatcher),
		fork(retrievePortJobMetadataWatcher),
		fork(retrieveAvailableGroupsWatcher),
		fork(setDefaultMessagingGroupWatcher),
		fork(moveJobToPreviousStatusWatcher),
		fork(requestPDAWatcher),
		fork(retrieveExtendedMessagingDataWatcher),
		fork(sendExtendedMessagesWatcher),
		fork(addAppointmentFromNominationWatcher),
		fork(togglePortJobStatusWatcher),
		fork(inviteAgentToPortJobWatcher),
		fork(unlinkJobsWatcher),
		fork(retrieveExtendedMessagingConfigurationWatcher),
		fork(clonePortJobWatcher),
		fork(toggleOperationsStatusWatcher),
		fork(enableOperationsCheckWatcher),
		fork(updateEnableOperationsWatcher)
	]);
}
