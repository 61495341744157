import moment from 'moment';
import DatePicker from 'antd/lib/date-picker/locale/en_US';
import Pagination from 'rc-pagination/lib/locale/en_US';
import TimePicker from 'antd/lib/time-picker/locale/en_US';
import Calendar from 'antd/lib/calendar/locale/en_US';
import 'moment/locale/en-gb';

moment.locale('en');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: any = {
	Pagination,
	TimePicker,
	Calendar,
	DatePicker,
	Table: {
		filterTitle: 'Filter Menu',
		filterConfirm: 'OK',
		filterReset: 'Reset',
		emptyText: 'No Data'
	},
	Modal: {
		okText: 'OK',
		cancelText: 'Cancel',
		justOkText: 'OK'
	},
	Popconfirm: {
		okText: 'OK',
		cancelText: 'Cancel'
	},
	Transfer: {
		notFoundContent: 'Not Found',
		searchPlaceholder: 'Search here',
		itemUnit: 'item',
		itemsUnit: 'items'
	},
	Select: {
		notFoundContent: 'Not Found'
	},
	Upload: {
		uploading: 'Uploading...',
		removeFile: 'Remove file',
		uploadError: 'Upload error',
		previewFile: 'Preview file'
	}
};

export default config;
