import { FinanceState } from '../financeState';
import { UpdateBenchmarkActionPayload } from 'services/api/finance/financeServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';

export function onUpdateBenchmarkStarted(
	state: FinanceState,
	payload: UpdateBenchmarkActionPayload
): FinanceState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updateBenchmark: {
				[payload.id]: FetchStatus.PENDING
			}
		}
	};
}

export function onUpdateBenchmarkSuccess(
	state: FinanceState,
	{ params: { id } }: Success<UpdateBenchmarkActionPayload, null>
): FinanceState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updateBenchmark: {
				[id]: FetchStatus.SUCCESS
			}
		}
	};
}

export function onUpdateBenchmarkFailure(
	state: FinanceState,
	{ params: { id } }: Failure<UpdateBenchmarkActionPayload, Error>
): FinanceState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updateBenchmark: {
				[id]: FetchStatus.FAILURE
			}
		}
	};
}
