import { getConfigurationBenchmarkFormValues } from './../selectors/configurationFormsSelectors';
import { BENCHMARK_FORM_NAME } from 'sections/Administration/Configuration/Benchmark/BenchmarkTypes';
import {
	UpdateConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData
} from 'services/api/companies/companiesServiceTypes';
import { getActiveCompanyId } from 'store/configuration/selectors';
import { select, put } from 'redux-saga/effects';
import { updateConfigurationBenchmarkAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { initialize } from 'redux-form';

export default makeTakeLatestWatcher<
	null,
	ConfigurationBenchmarkData,
	Error,
	UpdateConfigurationBenchmarkRequest
>({
	api: Api.Companies.updateBenchmarkConfiguration,
	async: updateConfigurationBenchmarkAsync,
	*getApiParams() {
		const companyId: string = yield select(getActiveCompanyId);
		const data = yield select(getConfigurationBenchmarkFormValues);
		return {
			companyId,
			data
		};
	},
	*onSuccess() {
		const data = yield select(getConfigurationBenchmarkFormValues);
		yield put(initialize(BENCHMARK_FORM_NAME, data));
	}
});
