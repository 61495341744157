import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataFetcher } from 'utils/components';
import { RouteComponentProps } from 'react-router';
import {
	retrieveFundingPageData,
	resetFunding
} from 'store/financeFundings/actions';
import { resetPortCall } from 'store/portcalls/actions';
import { FundingRequestPageParams } from './FundingRequestTypes';
import { navigateTo } from 'utils';
import { getFundingPageDataFetchStatus } from 'store/financeFundings/selectors';
import FundingPageForm from './FundingRequestPageForm';
import { NEW_FUNDING_ID } from 'store/financeFundings/financeFundingsConstants';

interface FundingPageRouteProps
	extends RouteComponentProps<FundingRequestPageParams> {}
function FundingPageRoute(props: FundingPageRouteProps) {
	const dispatch = useDispatch();
	const { fundingId, portCallId, daId, portJobCode } = props.match.params;
	const fetchStatus = useSelector(getFundingPageDataFetchStatus);
	const retrieveData = useCallback(() => {
		dispatch(
			retrieveFundingPageData({
				portCallId,
				jobCode: portJobCode,
				fundingId,
				daId
			})
		);
	}, [portJobCode, portCallId, fundingId, daId, dispatch]);

	useEffect(
		() => {
			return () => {
				dispatch(resetPortCall());
				dispatch(resetFunding());
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const goToFinancePage = useCallback(() => {
		navigateTo(`/portcalls/${portCallId}/jobs/${portJobCode}/finance`);
	}, [portCallId, portJobCode]);

	return (
		<DataFetcher fetchStatus={fetchStatus} dispatch={retrieveData}>
			{() => (
				<FundingPageForm
					fundingId={fundingId ?? NEW_FUNDING_ID}
					portJobCode={portJobCode}
					onCancel={goToFinancePage}
				/>
			)}
		</DataFetcher>
	);
}

export default FundingPageRoute;
