import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { BankAccountsState } from '../bankAccountsState';
import { AddBankAccountRequest } from 'services/api/bankAccounts/bankAccountsServiceTypes';

export const onAddBankAccount = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	AddBankAccountRequest,
	null
>('fetchStatuses', 'add');
