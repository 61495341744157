import { all, fork } from 'redux-saga/effects';
import retrieveBankAccountsSaga from './retrieveBankAccountsSaga';
import retrieveBankAccountWatcher from './retrieveBankAccountSaga';
import addBankAccountSaga from './addBankAccountSaga';
import updateBankAccountSaga from './updateBankAccountSaga';
import uploadLOBDDoneSaga from './uploadLOBDDoneSaga';
import retrieveLOBDSaga from './retrieveLOBDSaga';
import deleteLOBDSaga from './deleteLOBDSaga';
import downloadLOBDSaga from './downloadLOBDSaga';

export default function*() {
	yield all([
		fork(retrieveBankAccountsSaga),
		fork(retrieveBankAccountWatcher),
		fork(addBankAccountSaga),
		fork(updateBankAccountSaga),
		fork(uploadLOBDDoneSaga),
		fork(retrieveLOBDSaga),
		fork(deleteLOBDSaga),
		fork(downloadLOBDSaga)
	]);
}
