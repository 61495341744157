import actionCreator from '../portCallsActionCreator';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTCALLS';

export const retrievePortCallsAsync = actionCreator.async<
	RetrievePortCallsRequest,
	RetrievePortCallsResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'section', infiniteLoad: true } });

export const retrievePortCalls = actionCreator<RetrievePortCallsRequest>(
	ACTION_NAME
);
