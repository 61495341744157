import actionCreator from '../commoditiesActionCreator';
import {
	Commodity,
	RetrieveCommoditiesRequest
} from 'services/api/commodities/commoditiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_COMMODITIES';

export const retrieveCommoditiesAsync = actionCreator.async<
	RetrieveCommoditiesRequest,
	Commodity[],
	Error
>(ACTION_NAME);

export const retrieveCommodities = actionCreator<
	RetrieveCommoditiesRequest | undefined
>(ACTION_NAME);
