import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { select, call } from 'redux-saga/effects';
import {
	AddBankAccountRequest,
	BankAccountBase,
	BankAccountTypeCode
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import Api from 'services/api';
import { addBankAccountAsync as async } from '../actions';
import { getForm } from 'store/form/selectors';
import { BANK_ACCOUNT_FORM } from 'sections/BankAccounts/BankAccountConstants';
import { FormStateGet } from 'store/form/formTypes';
import { BankAccountFormData } from 'sections/BankAccounts/BankAccountTypes';
import { omit } from 'lodash';
import { navigateTo } from 'utils';

export const getApiParams = function*() {
	const form: FormStateGet<BankAccountFormData> = yield select(
		getForm,
		BANK_ACCOUNT_FORM
	);

	return {
		...(omit<BankAccountFormData>(form.values, 'bank') as BankAccountBase),
		bankCode: form.values.bank.key,
		bankName: form.values.bank.label,
		bankAccountTypeCode: BankAccountTypeCode.BENEFICIARY
	};
};

export default makeTakeLatestWatcher<AddBankAccountRequest, null, Error>({
	api: Api.BankAccounts.addBankAccount,
	async,
	getApiParams,
	*onSuccess() {
		yield call(navigateTo, '/bank-account-details');
	}
});
