import {
	VesselProgrammeState,
	ReducerOperationUnit,
	SplitOperationUnit,
	SplitOperationUnitPayload
} from 'store/vesselProgramme/vesselProgrammeState';
import uuidv4 from 'uuid/v4';
import numeral from 'numeral';
import { flow, last } from 'lodash';
import { getCustomFormattedValue } from 'utils/numbers/getCustomFormattedValue';

export const onOpenSplitOperationUnitModal = (
	state: VesselProgrammeState,
	{
		operationUnitId
	}: {
		operationUnitId: string;
	}
): VesselProgrammeState => ({
	...state,
	splitModalOperationUnitId: operationUnitId
});

export const onCancelSplitOperationUnitModal = (
	state: VesselProgrammeState
): VesselProgrammeState => ({
	...state,
	splitModalOperationUnitId: undefined
});

const getStateWithSplitedParentCommodity = (
	parentOperationUnitId: string,
	childCommodity: string
) => (state: VesselProgrammeState) => {
	const parentOperation = {
		...state.operationUnitsById[parentOperationUnitId]
	};

	parentOperation.commodityQuantity = Number(
		getCustomFormattedValue(
			parentOperation.commodityQuantity - numeral(childCommodity).value(),
			4
		)
	);

	return {
		...state,
		operationUnitsById: {
			...state.operationUnitsById,
			[parentOperationUnitId]: parentOperation
		}
	};
};

const addSplittedUnitToUnallocatedOperations = (
	parentOperationUnitId: string,
	splitOperation: SplitOperationUnit,
	splittedUnitId: string
) => (state: VesselProgrammeState) => {
	const parentOperation = state.operationUnitsById[parentOperationUnitId];
	const parentOperationId = parentOperation.id;
	const splittedUnitsByParentUnitId =
		state.splittedUnitsByParentUnitId[parentOperationUnitId];
	const lastSplittedChildId = last(splittedUnitsByParentUnitId);
	let splitIndex = 1; // default value when there are no splitted units
	if (lastSplittedChildId) {
		const lastSplittedChild = state.operationUnitsById[lastSplittedChildId];
		if (lastSplittedChild.splitIndex) {
			splitIndex = lastSplittedChild.splitIndex;
		}
	}
	const splitOperationWithFullData: ReducerOperationUnit = {
		...parentOperation,
		commodityQuantity: numeral(splitOperation.commodityQuantity).value(),
		shipperCompany: {
			id: splitOperation.shipper.key,
			name: splitOperation.shipper.label
		},
		splitIndex: splitIndex + 1,
		isTransshipment: splitOperation.isTransshipment,
		transshipmentVesselName: splitOperation.transshipmentVesselName,
		parentOperationUnitId,
		allocatedId: '',
		events: [],
		id: splittedUnitId
	};
	return {
		...state,
		operationUnitsById: {
			...state.operationUnitsById,
			[parentOperationId]: {
				...parentOperation,
				splitIndex: 1
			},
			[splittedUnitId]: splitOperationWithFullData
		}
	};
};

const addSplittedUnitIdIntoSplittedUnitsByParentUnitId = (
	parentOperationUnitId: string,
	splittedUnitId: string
) => (state: VesselProgrammeState) => ({
	...state,
	edited: true,
	splittedUnitsByParentUnitId: {
		...state.splittedUnitsByParentUnitId,
		[parentOperationUnitId]: [
			...(state.splittedUnitsByParentUnitId[parentOperationUnitId] || []),
			splittedUnitId
		]
	}
});

export const onSplitOperationUnit = (
	state: VesselProgrammeState,
	{ parentOperationUnitId, splitOperation }: SplitOperationUnitPayload
): VesselProgrammeState => {
	const splittedUnitId = uuidv4() + '[new]';
	return flow(
		getStateWithSplitedParentCommodity(
			parentOperationUnitId,
			splitOperation.commodityQuantity
		),
		addSplittedUnitToUnallocatedOperations(
			parentOperationUnitId,
			splitOperation,
			splittedUnitId
		),
		addSplittedUnitIdIntoSplittedUnitsByParentUnitId(
			parentOperationUnitId,
			splittedUnitId
		)
	)(state);
};
