import * as React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button } from 'components/antd';
import {
	getUserCanAssignJobToAThread,
	getJobIsAlreadyAssignedToAThread,
	isJobBeingAssigningToThread
} from 'store/thread/selectors';
import { assignJobToThread } from 'store/thread/actions';
import { assignJobsToNewThread } from 'store/threads/actions';
import { getActiveThreadId, getNewThreadData } from 'store/threads/selectors';

import { NEW_THREAD_ID } from 'store/thread/threadConstants';
import { AppState } from 'store-types';

interface AssignJobToThreadButtonOwnProps {
	portCallId: string;
	jobCode: string;
}

type AssignJobToThreadButtonProps = AssignJobToThreadButtonOwnProps & {
	// from mapStateToProps
	hasNewThreadData: boolean;
	jobIsAlreadyAssigned: boolean;
	userCanAssignJob: boolean;
	activeThreadId: string;
	isJobBeingAssigning: boolean;
	// from mapDispatchToProps
	assignJobToThread: typeof assignJobToThread;
	assignJobsToNewThread: typeof assignJobsToNewThread;
};

class AssignJobToThreadButton extends React.Component<
	AssignJobToThreadButtonProps
> {
	onClick = () => {
		const { activeThreadId, jobCode, hasNewThreadData } = this.props;
		if (activeThreadId === NEW_THREAD_ID || hasNewThreadData) {
			this.props.assignJobsToNewThread([jobCode]);
		} else {
			this.props.assignJobToThread({
				threadId: activeThreadId,
				jobCode
			});
		}
	};

	render() {
		const {
			userCanAssignJob,
			jobIsAlreadyAssigned,
			isJobBeingAssigning
		} = this.props;

		const isDisabled = !userCanAssignJob || jobIsAlreadyAssigned;

		let content = null;
		if (!userCanAssignJob) {
			content = `You can't assign this job as
					it belongs to Hub Principal
					that is different from the Inbox Hub Principal
					the message was sent to`;
		}
		if (jobIsAlreadyAssigned) {
			content = 'Job is already assigned';
		}

		return (
			<Button
				type="primary"
				size="small"
				disabled={isDisabled}
				onClick={this.onClick}
				tooltip={
					isDisabled && content
						? { title: content, placement: 'topRight' }
						: undefined
				}
				loading={isJobBeingAssigning}
			>
				Assign Job
			</Button>
		);
	}
}

export default connect(
	(state: AppState, ownProps: AssignJobToThreadButtonOwnProps) => ({
		jobIsAlreadyAssigned: getJobIsAlreadyAssignedToAThread(
			state,
			ownProps.portCallId,
			ownProps.jobCode
		),
		userCanAssignJob: getUserCanAssignJobToAThread(
			state,
			ownProps.portCallId,
			ownProps.jobCode
		),
		activeThreadId: getActiveThreadId(state),
		hasNewThreadData: !isEmpty(getNewThreadData(state)),
		isJobBeingAssigning: isJobBeingAssigningToThread(state, ownProps.jobCode)
	}),
	{
		assignJobToThread,
		assignJobsToNewThread
	}
)(AssignJobToThreadButton);
