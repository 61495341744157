import { Success } from 'typescript-fsa';
import { ConfigurationState } from '../configurationState';
import {
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveMainPrincipalGroupsSuccess = (
	state: ConfigurationState,
	{ result }: Success<RetrieveGroupsRequest, RetrieveGroupsResponse>
) => ({
	...state,
	generalDetails: {
		...state.generalDetails,
		groups: result.elements
	}
});

export const onRetrieveMainPrincipalGroups = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
>('fetchStatuses', 'retrieveMainPrincipalGroups', {
	doneReducer: onRetrieveMainPrincipalGroupsSuccess
});
