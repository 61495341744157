import { ThreadReducerState } from 'store/thread/threadState';
export const onUpdateThreadMessageReadSuccess = (
	state: ThreadReducerState
): ThreadReducerState => {
	if (state.item === null) {
		return state;
	}
	return {
		...state,
		item: {
			...state.item,
			isRead: true
		}
	};
};
