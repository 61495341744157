import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	MoveJobFinanceRequest,
	MoveJobFinanceResponse
} from 'services/api/finance/financeServiceTypes';

export const onMoveJobFinance = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	MoveJobFinanceRequest,
	MoveJobFinanceResponse
>('fetchStatuses', 'move');
