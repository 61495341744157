import * as React from 'react';
import classNames from 'classnames';
import styles from './Aside.module.scss';
import { ScrollableLayout } from 'components';
import { ThemeScheme, Size } from 'components/types';

interface AsideHeaderProps {
	className?: string;
}

const AsideHeader: React.FC<AsideHeaderProps> = ({ className, children }) => {
	return (
		<header className={classNames(styles.header, className)}>{children}</header>
	);
};

const AsideFooter: React.SFC<AsideHeaderProps> = ({ className, children }) => {
	return (
		<footer className={classNames(styles.footer, className)}>{children}</footer>
	);
};

interface AsideProps {
	type?: ThemeScheme;
	size?: Size;
	placement?: 'right' | 'left';
	scrollable?: boolean;
	className?: string;
}

class Aside extends React.Component<AsideProps> {
	static Header: typeof AsideHeader;
	static Footer: typeof AsideFooter;
	render() {
		const {
			className,
			type,
			size = 'sm',
			placement = 'left',
			scrollable = true,
			children
		} = this.props;
		const asideClassNames = classNames(
			styles.root,
			{
				// Placement
				[styles.placementLeft]: placement === 'left',
				[styles.placementRight]: placement === 'right',

				// Other
				[styles[size]]: Boolean(size),
				[styles.withSize]: Boolean(size),
				[styles.primary]: type === 'primary',
				[styles.scrollable]: scrollable
			},
			className
		);
		const Component = scrollable ? ScrollableLayout : 'aside';
		return <Component className={asideClassNames}>{children}</Component>;
	}
}

Aside.Header = AsideHeader;
Aside.Footer = AsideFooter;

export default Aside;
