import * as React from 'react';
import { Redirect } from 'react-router-dom';
import {
	signInRedirectCallback,
	processSignInRedirectCallback,
	UserManagerState
} from 'services/userManager';
import Loading from 'components/Loading/Loading';
import { navigateTo } from 'utils';

interface LoginCallbackProps {}

interface LoginCallbackState {
	returnUrl: string | null;
}

class LoginCallback extends React.Component<
	LoginCallbackProps,
	LoginCallbackState
> {
	constructor(props: LoginCallbackProps) {
		super(props);
		this.state = {
			returnUrl: null
		};
	}

	componentDidMount() {
		signInRedirectCallback()
			.then(processSignInRedirectCallback)
			.then((state: UserManagerState) => {
				const returnUrl = state.returnUrl || '/';
				this.setState({
					returnUrl
				});
			})
			.catch(error => {
				/* eslint-disable no-console */
				console.warn(`Error handling redirect callback: ${error.message}`);
				navigateTo('/login');
			});
	}

	render() {
		if (this.state.returnUrl) {
			return <Redirect to={this.state.returnUrl} />;
		}
		return <Loading />;
	}
}

export default LoginCallback;
