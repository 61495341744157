import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { DEFAULT_NO_VALUE_PLACEHOLDER } from 'app-constants';

export const isOperationFormValid = ({
	formValid,
	isLoadingOrDischargeOperation,
	isOperationFormOpen
}: {
	formValid: boolean;
	isLoadingOrDischargeOperation: boolean;
	isOperationFormOpen: boolean;
}) => {
	return isLoadingOrDischargeOperation
		? formValid && !isOperationFormOpen
		: formValid;
};

export const isOperationTypeLoadingOrDischarge = (
	operationCode: OperationTypeCode
) =>
	[
		OperationTypeCode.LOADING,
		OperationTypeCode.LOADING_STS,
		OperationTypeCode.DISCHARGING,
		OperationTypeCode.DISCHARGING_STS
	].includes(operationCode);

export const columnContent = (
	content: string | undefined,
	formatContent: (value: string) => React.ReactNode = value => value
) => (content ? formatContent(content) : DEFAULT_NO_VALUE_PLACEHOLDER);

export const getCargoMenuColumnId = (cargoId: string) =>
	`cargo-${cargoId}-menu`;
