import { PagedRequest, ResultCollection, SearchRequest } from '../apiTypes';
import { Entity } from 'app-types';
import { Company, CompanyAddress } from '../companies/companiesServiceTypes';

export interface Port {
	code: string;
	country: string;
	countryCode: string;
	createdOn: string;
	id: string;
	isDeleted: boolean;
	latitude: number;
	longitude: number;
	name: string;
	timeZone: string;
	timeZoneId: string;
	updatedOn: string;
}

export enum AgentResponsibility {
	PERFORMING_AGENT_COMPANY = 'Performing',
	CONTROLLING_AGENT_COMPANY = 'Controlling'
}

export interface CompanyAgentResponsibility {
	id: string;
	name: string;
	agentResponsibility?: {
		code: AgentResponsibility;
		name: AgentResponsibility;
	};
	agentType?: {
		code: string;
		name: string;
	};
}

export interface CompanyAgentAutocompleteOption
	extends Entity,
		Pick<Company, 'companyEmail' | 'companyName'> {
	companyTel: string;
	address: CompanyAddress & { countryName: string };
	isNonIssHubPrincipal: boolean;
}

export type Buoy = Entity;

export type Canal = Entity;

export type Anchorage = Entity;

export type RetrieveBuoysResponse = ResultCollection<Buoy>;

export type RetrieveCanalsResponse = ResultCollection<Canal>;

export type RetrieveAnchoragesResponse = ResultCollection<Anchorage>;

type PortBoundListing = PagedRequest & {
	port: string;
};

export interface RetrievePortByIdRequest {
	port: string;
}

export type RetrievePortByIdResponse = Port;

export type RetrieveBuoysRequest = PortBoundListing;

export type RetrieveCanalsRequest = PortBoundListing;

export type RetrieveAnchoragesRequest = PortBoundListing;

export type RetrievePortsResponse = ResultCollection<Port>;

export type SearchPreferredPortLPAsRequest = SearchRequest & {
	portId: string;
	mainPrincipalId?: string;
	agentType?: string;
	agentResponsibility?: AgentResponsibility;
};

export type SearchPortLPAsRequest = SearchRequest & {
	portId: string;
};
