import React from 'react';
import { connect } from 'react-redux';

import { Flex } from 'components';
import { AppNotifications } from 'containers';
import { DataFetcher } from 'utils/components';
import Dashboard from './Dashboard';
import Welcome from './screens/Welcome';
import { resetDashboardState } from 'store/dashboard/actions';

import { getHasPermission } from 'store/auth/selectors/permissionsSelector';

import { FetchStatus } from 'services/api/apiTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { AppState } from 'store-types';

interface DashboardRouteProps {
	fetchStatus: FetchStatus;
	isWelcomePageVisible: boolean;
	resetDashboard: typeof resetDashboardState;
}

const DashboardRoute = ({
	isWelcomePageVisible,
	resetDashboard
}: DashboardRouteProps) => (
	<Flex stretch fit>
		<AppNotifications.Notification />
		{isWelcomePageVisible ? (
			<Welcome />
		) : (
			<DataFetcher
				data={{}}
				reset={() => {
					resetDashboard();
				}}
				showLoader={false}
				skipIfHasData
			>
				{() => <Dashboard />}
			</DataFetcher>
		)}
	</Flex>
);

export default connect(
	(state: AppState) => ({
		isWelcomePageVisible: !getHasPermission(
			state,
			PermissionCode.VIEW_PORTCALLS
		)
	}),
	{
		resetDashboard: resetDashboardState
	}
)(DashboardRoute);
