import { PortJobState, ContextType } from '../portJobsState';
export const onSetPortJobContext = (
	state: PortJobState,
	// eslint-disable-next-line
	action: ContextType
): PortJobState => ({
	...state,
	context: {
		...state.context,
		...action
	}
});
