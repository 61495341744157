import { Entity } from 'app-types.d';
import { FetchStatus } from 'services/api/apiTypes';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { Message } from 'services/api/messages/messagesServiceTypes';
import { MailboxAvailableGroup } from 'services/api/mailboxes/mailboxesServiceTypes';
import { PortCallJobSearchResult } from 'services/api/portCalls/portCallsServiceTypes';

export interface QuickThreadJobAssigmentState {
	jobs: PortCallJobSearchResult[];
	fetchStatus: FetchStatus;
	assignStatus: FetchStatus;
}
export interface ThreadReducerState {
	readonly item: ThreadItem | null;
	readonly composeMessage: Message | null;
	readonly newPermissions: Entity[];
	readonly quickJobAssigment: QuickThreadJobAssigmentState;
	readonly fetchStatuses: {
		updating: FetchStatus;
		retrieving: FetchStatus;
		retrievingMessage: FetchStatus;
		retrieveMessageData: FetchStatus;
		retrievingMailboxAvailableGroups: FetchStatus;
		addMessage: FetchStatus;
		assigning: {
			[threadId: string]: FetchStatus;
		};
	};
	readonly error: Error | boolean;
	readonly mailboxAvailableGroups: MailboxAvailableGroup[];
	readonly context: {
		lastConcurrencyToken?: number;
		messageCurrentSelectedMailboxCompany?: Entity; // currently picked mail box company id in message form
	};
	readonly isContextAndJobVisible?: boolean;
}

export const initialState: ThreadReducerState = {
	item: null,
	composeMessage: null,
	newPermissions: [],
	mailboxAvailableGroups: [],
	quickJobAssigment: {
		jobs: [],
		fetchStatus: FetchStatus.IDLE,
		assignStatus: FetchStatus.IDLE
	},
	fetchStatuses: {
		updating: FetchStatus.IDLE,
		retrieving: FetchStatus.IDLE,
		retrievingMessage: FetchStatus.IDLE,
		retrieveMessageData: FetchStatus.IDLE,
		retrievingMailboxAvailableGroups: FetchStatus.IDLE,
		addMessage: FetchStatus.IDLE,
		assigning: {}
	},
	error: false,
	context: {},
	isContextAndJobVisible: false
};
