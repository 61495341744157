import { select, call, put, takeLatest } from 'redux-saga/effects';

import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api/portJobs/portJobsService';
import { ClonePortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { clonePortJob, clonePortJobAsync } from '../actions';
import {
	resetPortCall,
	retrievePortCallCycleStart
} from 'store/portcalls/actions';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { CompanyDraft, DraftKind, DraftType } from 'store/drafts/draftsState';

import { Action } from 'typescript-fsa';
import { navigateTo } from 'utils';
import { getClonePortJobRequestData } from '../selectors';
import { closeModal } from 'store/modals/actions';

const apiCall = Api.cloneJob;

export function* executor(
	actionParams: ClonePortJobRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(clonePortJobAsync.started(actionParams));
	try {
		let request = yield select(getClonePortJobRequestData);
		let response;
		if (request.isNewPortCall) {
			const portJobAgentCompanies = yield select(
				getCompanyDraftsByType,
				DraftType.AGENT
			);
			request = {
				...request,
				portCallId: { id: actionParams.portCallId },
				jobCode: actionParams.jobCode,
				portJobAgentCompanies: portJobAgentCompanies.filter(
					(company: CompanyDraft) => company.kind === DraftKind.NEW
				)
			};

			response = yield call(api, request);
		} else {
			request = {
				...request,
				portCallId: { id: actionParams.portCallId },
				jobCode: actionParams.jobCode
			};
			response = yield call(api, request);

			response = yield call(
				Api.addPortJob,
				actionParams.portCallId,
				response.data
			);
		}

		const portCall = yield select(getActivePortCall);

		yield put(
			clonePortJobAsync.done({
				result: {
					...response.data,
					portCallId: portCall.id
				},
				params: actionParams,
				response
			})
		);
		// Considering that the wizard and an overview pages look to the same place in the state
		// we need to reset port call before navigating, so that we fetch actual port call data and show correct port job tab
		yield put(resetPortCall());
		if (request.isNewPortCall) {
			const { id, portJobCode } = response.data;
			yield call(navigateTo, `/portcalls/${id}/jobs/${portJobCode}/overview`);
			yield put(retrievePortCallCycleStart(id));
		} else {
			const { code } = response.data;

			//While navigating to same portcall from same portcall job is not loading so that doing small switch
			yield call(navigateTo, `/portcalls`);
			yield call(navigateTo, `/portcalls/${portCall.id}/jobs/${code}/overview`);
			yield put(retrievePortCallCycleStart(portCall.id));
		}

		yield put(closeModal(actionParams.modalId));
	} catch (error) {
		yield put(
			clonePortJobAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<ClonePortJobRequest>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(clonePortJob.type, worker);
}
