import React from 'react';
import { Position } from 'components';
import { AutoCompleteDrafts } from 'components/Drafts';
import {
	Row,
	Col,
	Input,
	InputNumber,
	TextArea,
	Autocomplete,
	Checkbox
} from 'components/antd';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';
import FieldMainCommodity from '../../Field.MainCommodity';
import { Commodity } from 'services/api/commodities/commoditiesServiceTypes';
import { Country } from 'services/api/countries/countriesServiceTypes';

import CargoLineFormFieldsName from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineFormFieldNames';
import { CargoLineLoadOperation } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import {
	localeSearchIdName,
	localeSearchAlphabetic,
	searchCharterers,
	searchShipperReceivers
} from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineForm.func';
import { CompanyDraft, DraftType } from 'store/drafts/draftsState';
import { onUOMSearch } from 'containers/Autocomplete/AutocompleteEntity.func';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';
import Api from 'services/api';
import { UNIT_TYPES_BY_MAIN_COMMODITY } from 'store/units/unitsConstants';

const {
	REFERENCE_NUMBER,
	PRINCIPAL_VOYAGE_REFERENCE,
	COMMODITY,
	COMMODITY_QUANTITY,
	COMMODITY_UNIT_OF_MEASUREMENT_CODE,
	SHIPPER_COMPANY,
	CHARTERER_COMPANY,
	CHARTER_PARTY_DATE_PLT,
	TERMS,
	LAYCAN_FROM_PLT,
	LAYCAN_TO_PLT,
	TO_ORDERS,
	LOADING_FROM_VESSEL,
	DISCHARGE_PORT,
	DISCHARGE_COUNTRY
} = CargoLineFormFieldsName;

interface CargoLineFieldsProps {
	isSplit: boolean;
	operationTypeCode: OperationTypeCode | undefined;
	commodities: Commodity[];
	countries: Country[];

	onAddDraftClick: (
		type: DraftType,
		formFieldName: string,
		alias: string
	) => void;
	formValues: CargoLineLoadOperation;
	showLaycanFromError: boolean;
	showLaycanToError: boolean;
	chartererDrafts: CompanyDraft[];
	shipperDrafts: CompanyDraft[];
}

const isLoadingSTS = (
	operationTypeCode: CargoLineFieldsProps['operationTypeCode']
) => {
	if (!operationTypeCode) {
		return false;
	}
	return operationTypeCode === OperationTypeCode.LOADING_STS;
};

const CARGOLINE_FIELDS_ID = 'cargoline-fields-id';

const CargoLineFields = (props: CargoLineFieldsProps) => {
	const {
		countries,
		isSplit,
		commodities,
		onAddDraftClick,
		formValues,
		chartererDrafts,
		shipperDrafts,
		operationTypeCode
	} = props;
	const isSTS = isLoadingSTS(operationTypeCode);
	const onSearchUOM = (searchTerm?: string) => {
		const unitsOfMeasurementTypes = formValues.mainCommodity
			? UNIT_TYPES_BY_MAIN_COMMODITY[formValues.mainCommodity]
			: [];
		return onUOMSearch(searchTerm, { unitsOfMeasurementTypes });
	};

	return (
		<div id={CARGOLINE_FIELDS_ID}>
			<Row>
				<Col xs={3}>
					<Input.ReduxFormItem
						type="text"
						name={REFERENCE_NUMBER}
						label="Cargo Ref"
						help
						disabled={isSplit}
					/>
				</Col>
				<Col xs={3}>
					<Input.ReduxFormItem
						type="text"
						name={PRINCIPAL_VOYAGE_REFERENCE}
						label="Voyage Ref"
						help
						disabled={isSplit}
					/>
				</Col>
				<Col xs={3}>
					<FieldMainCommodity
						isSplit={isSplit}
						value={formValues.mainCommodity}
					/>
				</Col>
				<Col xs={3}>
					<Autocomplete.ReduxFormItem
						name={COMMODITY}
						label="Commodity Detail"
						onSearch={localeSearchIdName(commodities)}
						disabled={!formValues.mainCommodity || isSplit}
						getPopupContainer={() => document.body}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={3}>
					<InputNumber.ReduxFormItem
						name={COMMODITY_QUANTITY}
						label="Nomination Qty"
						help
					/>
				</Col>
				<Col xs={3}>
					<AutocompleteEntity
						name={COMMODITY_UNIT_OF_MEASUREMENT_CODE}
						label="UOM"
						getPopupContainer={() => document.body}
						onSearch={onSearchUOM}
						shouldSearchAllOptionsOnFocus
						minLength={1}
					/>
				</Col>
				<Col xs={3}>
					<AutoCompleteDrafts.ReduxFormItem
						name={SHIPPER_COMPANY}
						label="Shipper"
						onSearch={searchShipperReceivers}
						drafts={shipperDrafts}
						addDraftLinkTitle={'Add Shipper Manually'}
						onAddDraftClick={() =>
							onAddDraftClick(
								DraftType.SHIPPER_RECEIVER,
								SHIPPER_COMPANY,
								'Shipper'
							)
						}
						getPopupContainer={() => document.body}
					/>
				</Col>
				<Col xs={3}>
					<AutoCompleteDrafts.ReduxFormItem
						name={CHARTERER_COMPANY}
						label="Charterer"
						onSearch={searchCharterers}
						drafts={chartererDrafts}
						addDraftLinkTitle={'Add Charterer Manually'}
						onAddDraftClick={() =>
							onAddDraftClick(
								DraftType.CHARTERER,
								CHARTERER_COMPANY,
								'Charterer'
							)
						}
						getPopupContainer={() => document.body}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={3}>
					<DatePickerExtendedFormItem
						name={CHARTER_PARTY_DATE_PLT}
						label="Charter Party Date"
						help
						local
						showTime={false}
					/>
				</Col>
				<Col xs={3}>
					<DatePickerExtendedFormItem
						name={LAYCAN_FROM_PLT}
						label="Laycan From"
						help
						local
					/>
				</Col>
				{isSTS && (
					<Col xs={3}>
						<Autocomplete.ReduxFormItem
							name={LOADING_FROM_VESSEL}
							label="Loading From"
							onSearch={Api.Vessels.searchVesselsForAutocomplete}
							getPopupContainer={() => document.body}
						/>
					</Col>
				)}
				<Col xs={3}>
					<DatePickerExtendedFormItem
						name={LAYCAN_TO_PLT}
						label="Laycan To"
						help
						local
					/>
				</Col>
				<Col xs={3}>
					<Position>
						<Position type="absolute" position="topRight">
							<Checkbox.ReduxFormItem name={TO_ORDERS} disabled={isSplit}>
								For Orders
							</Checkbox.ReduxFormItem>
						</Position>
						<Autocomplete.ReduxFormItem
							name={DISCHARGE_PORT}
							label="Discharge Port"
							disabled={formValues.toOrders || isSplit}
							onSearch={Api.Ports.searchPortsForAutocomplete}
							getPopupContainer={() => document.body}
						/>
					</Position>
				</Col>
			</Row>
			<Row>
				{formValues.toOrders && (
					<Col xs={3}>
						<Autocomplete.ReduxFormItem
							name={DISCHARGE_COUNTRY}
							label="Discharge Country"
							onSearch={localeSearchAlphabetic(countries)}
							required
							getPopupContainer={() => document.body}
						/>
					</Col>
				)}
			</Row>
			<Row>
				<Col xs={12}>
					<TextArea.ReduxFormItem
						name={TERMS}
						label="Cargo Options / Terms"
						rows={4}
						maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default CargoLineFields;
