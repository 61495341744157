import * as React from 'react';
import history from 'services/history';

import { Button, Icon } from 'components/antd';
import { Flex, Title } from 'components';
import { ButtonProps } from 'components/antd/Button/Button';
import {
	getNewTabUrl,
	checkForNewTab,
	checkForExpand
} from '../PageHeader.func';
import Header, { PageHeaderProps } from './Header';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface PageHeaderNewTabProps extends PageHeaderProps {
	className?: string;
	/** NewTab Url */
	url?: string;
	shouldCloseCurrentView?: boolean;
	isNewTab?: boolean;
	onClose?: (isNewTab: boolean) => void;
	onExpand?: () => void;
	showExpand?: boolean;
	shouldNewTabClose?: boolean;
	openButtonProps?: ButtonProps;
	closeButtonProps?: ButtonProps;
	children: React.ReactNode;
	hidePopout?: boolean;
}
export enum ButtonAction {
	EXPAND = 'expand',
	NEWTAB = 'newTab'
}

const getButtonTooltip = (
	flag: boolean,
	buttonAction: ButtonAction
): TooltipProps | undefined => {
	let message;
	switch (buttonAction) {
		case ButtonAction.EXPAND:
			message = flag ? 'Expand Thread' : '';
			break;
		case ButtonAction.NEWTAB:
			message = flag ? 'Pop Out Thread' : '';
			break;
		default:
			break;
	}
	return message
		? {
				title: message,
				align: {
					offset: [0, 2]
				},
				placement: 'bottomLeft'
		  }
		: undefined;
};

/**
 * NewTab
 */
class NewTab extends React.PureComponent<PageHeaderNewTabProps> {
	static defaultProps: Partial<PageHeaderNewTabProps> = {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onClose: history.goBack,
		shouldNewTabClose: true
	};

	getIsNewTab = () => {
		return this.props.isNewTab
			? this.props.isNewTab
			: checkForNewTab(this.props.url || window.location.pathname);
	};

	close = (isNewTab: boolean) => {
		return this.props.onClose ? this.props.onClose(isNewTab) : history.goBack();
	};

	onClose = () => {
		const newtab = this.getIsNewTab();
		const expand = checkForExpand(this.props.url || window.location.pathname);

		if (!newtab && !expand) {
			this.close(newtab);
			return;
		}

		if (expand) {
			history.goBack();
			return;
		}

		if (this.props.shouldNewTabClose) {
			window.close();
			return;
		}

		this.close(newtab);
	};

	onNewTabOpen = () => {
		const newtabUrl = this.props.url
			? this.props.url
			: getNewTabUrl(window.location.href, window.location.origin);
		window.open(newtabUrl, '_blank');

		if (this.props.shouldCloseCurrentView) {
			this.onClose();
		}
	};

	render() {
		const {
			className,
			children,
			openButtonProps,
			closeButtonProps,
			type = 'primary',
			justified,
			shouldCloseCurrentView,
			showExpand,
			hidePopout,
			...props
		} = this.props;
		const newtab = this.getIsNewTab();
		const expand = checkForExpand(this.props.url || window.location.pathname);
		return (
			<Header
				{...props}
				type={type}
				className={className}
				justified={justified}
			>
				<Title.H2 marginBottom={0}>{children}</Title.H2>
				<Flex align="center">
					{showExpand && (
						<Button
							type="primary"
							style={{ marginRight: '5px' }}
							transparent
							thick
							tooltip={getButtonTooltip(true, ButtonAction.EXPAND)}
							onClick={this.props.onExpand}
							{...openButtonProps}
						>
							<Icon type="arrows-alt" size="md" offset="right" />
						</Button>
					)}
					{!newtab && !expand && !hidePopout && (
						<Button
							type="primary"
							transparent
							thick
							tooltip={getButtonTooltip(true, ButtonAction.NEWTAB)}
							onClick={this.onNewTabOpen}
							{...openButtonProps}
						>
							<Icon type="new-tab-alt" size="md" />
						</Button>
					)}
					<Button
						type="primary"
						transparent
						thick
						onClick={this.onClose}
						{...closeButtonProps}
					>
						<Icon type="close" size="md" offset="left" />
					</Button>
				</Flex>
			</Header>
		);
	}
}

export default NewTab;
