import { get, map } from 'lodash';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { createSelector } from 'reselect';
import { getValues } from 'store/form/selectors';
import { AppState } from 'store-types';
import { FORM } from '../../createPortJobConstants';
import {
	getOperations,
	getOperationById
} from 'store/operations/operationsSelectors';
import { isOperationTypeLoadingOrDischarge } from './helpers';

export const getChartererCompanyNameFromPortJobCargoLines = (
	cargoes: FormData['cargoes'],
	id: string
) => {
	const charterer = map(cargoes, 'chartererCompany').find(
		chartererCompany => get(chartererCompany, 'key') === id
	);
	return charterer ? charterer.label : '';
};

export const getIsFormOperationTypeLoadingOrDischarge = createSelector(
	(state: AppState) => getValues<FormData>(state, FORM.portJob),
	getOperations,
	(formValues, operations) => {
		const operationId =
			formValues.operationType && formValues.operationType.key;
		if (!operationId) {
			return false;
		}
		const operation = getOperationById.resultFunc(operations, operationId);
		return operation
			? isOperationTypeLoadingOrDischarge(operation.operationTypeCode)
			: false;
	}
);
