import actionCreator from '../groupsActionCreator';
import {
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse
} from 'services/api/groups/groupsServiceTypes';

const RETRIEVE_THIRD_PARTY_LPA_GROUPS = 'RETRIEVE_THIRD_PARTY_LPA_GROUPS';

export const retrieveThirdPartyLpaGroupsAsync = actionCreator.async<
	RetrieveThirdPartyLpaGroupsRequest,
	RetrieveThirdPartyLpaGroupsResponse,
	Error
>(RETRIEVE_THIRD_PARTY_LPA_GROUPS);

export const retrieveThirdPartyLpaGroups = actionCreator<
	RetrieveThirdPartyLpaGroupsRequest
>(RETRIEVE_THIRD_PARTY_LPA_GROUPS);

const RESET_THIRD_PARTY_LPA_GROUPS = 'RESET_THIRD_PARTY_LPA_GROUPS';

export const resetThirdPartyLPAGroups = actionCreator(
	RESET_THIRD_PARTY_LPA_GROUPS
);
