import {
	Document,
	SaveDocumentRequest,
	RetrieveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';
import actionCreator from '../documentsActionCreator';

const PROCESS_DOCUMENT = 'PROCESS_DOCUMENT';
export const processDocumentAsync = actionCreator.async<null, Document, Error>(
	PROCESS_DOCUMENT
);

export const processDocument = actionCreator<SaveDocumentRequest>(
	PROCESS_DOCUMENT
);
export const abortProcessDocument = actionCreator('ABORT_PROCESS_DOCUMENT');

export const updateDocumentProcessing = actionCreator<RetrieveDocumentResponse>(
	'UPDATE_DOCUMENT_PROCESSING'
);
