import { groupBy, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import {
	LoadPortJobOperation,
	DischargePortJobOperation,
	PortJobOperationTypes,
	EmptyCharterer,
	ChartererUnion,
	PortJob,
	PortJobCargoLineBase
} from 'services/api/portJobs/portJobsServiceTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { getPortJobById } from 'store/portJobs/portJobsSelectors';
import { DEFAULT_NO_VALUE_PLACEHOLDER } from 'app-constants';

export const emptyCharterer: EmptyCharterer = {
	chartererCompany: {
		id: DEFAULT_NO_VALUE_PLACEHOLDER,
		name: DEFAULT_NO_VALUE_PLACEHOLDER
	},
	id: DEFAULT_NO_VALUE_PLACEHOLDER
};

function getHasOperationCharterers(
	operation: PortJobOperationTypes
): operation is LoadPortJobOperation | DischargePortJobOperation {
	switch (operation.code) {
		case OperationTypeCode.LOADING:
		case OperationTypeCode.LOADING_STS:
		case OperationTypeCode.DISCHARGING:
		case OperationTypeCode.DISCHARGING_STS:
			return true;
		default:
			return false;
	}
}

// add corresponding cargoes inside each charterer
export const getPortJobOperationsForDisplay = createSelector(
	getPortJobById,
	(portJob: PortJob) => {
		const { operations } = portJob;
		return operations.map(operation => {
			if (getHasOperationCharterers(operation)) {
				const { cargoes = [] } = operation;
				const hasEmptyCharterer = cargoes.some(
					cargo => !cargo.chartererCompany
				);
				const sortedCargoes = sortBy(cargoes, ['positionNumber']);
				const mapByChartererCompanyId = groupBy<PortJobCargoLineBase>(
					sortedCargoes,
					cargo =>
						cargo.chartererCompany
							? cargo.chartererCompany.id
							: DEFAULT_NO_VALUE_PLACEHOLDER
				);
				const charterers: ChartererUnion[] = (hasEmptyCharterer
					? [emptyCharterer]
					: []
				).concat(operation.charterers);
				return {
					...operation,
					charterers: charterers.map(charterer => {
						return {
							...charterer,
							cargoes: mapByChartererCompanyId[charterer.chartererCompany.id]
						};
					})
				};
			}
			return operation;
		});
	}
);
