import { AppState } from 'store-types';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import {
	PortJobOperationTypes,
	BunkeringPortJobOperation,
	CanalTransitPortJobOperation,
	CashToMasterPortJobOperation,
	CrewChangePortJobOperation,
	CrewWageDisbursementPortJobOperation,
	LoadPortJobOperation,
	GenericPortJobOperation,
	DischargePortJobOperation,
	SparesAndDeliveryDocumentsOperation
} from 'services/api/portJobs/portJobsServiceTypes';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { getBunkerGrades } from '../../operations/operationsSelectors';
import { getUnits } from '../../units/unitsSelectors';
import { getCountriesMap } from 'store/countries/countriesSelectors';
import BunkeringFieldName from 'sections/PortJob/CreatePortJob/Pages/Page3/Operations/Bunkering/BunkeringFieldNames';
import { TOTAL_AMOUNT_FIELD_NAME } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	nameToLabelField,
	optionalNameToLabelField,
	nameCodeToLabelField
} from '../../portcalls/sagas/addPortCall/prepareRequest/prepareRequest.func';

const getOperationType = (operation: PortJobOperationTypes) => {
	return {
		key: operation.id,
		label: operation.name as OperationTypeCode,
		title: operation.name
	};
};

const mapBunkeringOperation = (
	state: AppState,
	operation: BunkeringPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	const grades = getBunkerGrades(state);
	const units = getUnits(state);
	const mappedOperation = { operationType, details: operation.details };
	if (
		!operation[BunkeringFieldName.GRADE_LINES] ||
		!operation[BunkeringFieldName.GRADE_LINES].length
	) {
		return mappedOperation;
	}
	mappedOperation[BunkeringFieldName.GRADE_LINES] = operation[
		BunkeringFieldName.GRADE_LINES
	]
		.map(line => {
			const grade = grades
				.map(item => ({ id: item.id, label: item.name }))
				.find(item => item.id === line.gradeId);
			const unitOfMeasurement = units
				.map(item => ({ code: item.code, label: item.name }))
				.find(item => item.code === line.unitOfMeasurementCode);
			if (grade && unitOfMeasurement) {
				return {
					grade: { key: grade.id, label: grade.label },
					quantity: line.quantity.toString(),
					id: line.id,
					unitOfMeasurement: {
						key: unitOfMeasurement.code,
						label: unitOfMeasurement.label
					}
				};
			}
			return null;
		})
		.filter(line => !!line);
	return mappedOperation;
};

const mapCanalTransitOperation = (
	operation: CanalTransitPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	return {
		operationType,
		vesselCondition: operation.vesselCondition,
		details: operation.details
	};
};

const mapCashToMasterOperation = (
	operation: CashToMasterPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	const totalAmounts = operation[TOTAL_AMOUNT_FIELD_NAME] || [];
	return {
		operationType,
		cashToMasterMasterName: operation.masterFullName,
		totalAmounts: totalAmounts.map(totalAmount => ({
			...totalAmount,
			amount: String(totalAmount.amount)
		})),
		cashToMasterDetails: operation.details
	};
};

const emptyStringFallback = <V extends number | string>(value?: V) =>
	value ? value.toString() : '';

const mapCrewChangeOperation = (
	operation: CrewChangePortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	return {
		operationType,
		onSigners: emptyStringFallback(operation.onSigners),
		offSigners: emptyStringFallback(operation.offSigners),
		immigrationAssistances: emptyStringFallback(
			operation.immigrationAssistances
		),
		lettersOfInvitation: emptyStringFallback(operation.lettersOfInvitation),
		okToBoardLetters: emptyStringFallback(operation.okToBoardLetters),
		visaAssistances: emptyStringFallback(operation.visaAssistances),
		customerClearences: emptyStringFallback(operation.customerClearences),
		shorePasses: emptyStringFallback(operation.shorePasses),
		meetAndGreets: emptyStringFallback(operation.meetAndGreets),
		lostLuggageAssistances: emptyStringFallback(
			operation.lostLuggageAssistances
		),
		details: emptyStringFallback(operation.details)
	};
};

const mapCrewWageDisbursementOperation = (
	operation: CrewWageDisbursementPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	const totalAmounts = operation[TOTAL_AMOUNT_FIELD_NAME] || [];
	return {
		operationType,
		crewWageDisbursementNumberOfCrew:
			(operation.numberOfCrew && operation.numberOfCrew.toString()) || '',
		totalAmounts: totalAmounts.map(totalAmount => ({
			...totalAmount,
			amount: String(totalAmount.amount)
		})),
		crewWageDisbursementDetails: operation.details
	};
};

const mapLoadOperation = (
	state: AppState,
	operation: LoadPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);

	const countriesByCode = getCountriesMap(state);
	const cargoes = operation.cargoes.map(cargo => ({
		...(cargo.splitIndex && { splitIndex: cargo.splitIndex }),
		chartererCompany: optionalNameToLabelField(cargo.chartererCompany),
		mainCommodity: cargo.mainCommodity.id,
		commodity: optionalNameToLabelField(cargo.commodity),
		commodityQuantity: cargo.commodityQuantity
			? cargo.commodityQuantity.toString()
			: undefined,
		commodityUnitOfMeasurementCode: cargo.commodityUnitOfMeasurementCode,
		toOrders: cargo.toOrders,
		dischargePort: optionalNameToLabelField(cargo.dischargePort),
		dischargeCountryCode: cargo.dischargeCountryCode,
		dischargeCountry:
			cargo.dischargeCountryCode && countriesByCode[cargo.dischargeCountryCode]
				? nameCodeToLabelField({
						code: cargo.dischargeCountryCode,
						name: countriesByCode[cargo.dischargeCountryCode].name
				  })
				: undefined,
		charterPartyDate: cargo.charterPartyDate,
		laycanFromPlt: cargo.laycanFromPlt,
		laycanToPlt: cargo.laycanToPlt,
		loadingFromVessel: cargo.loadingFromVessel
			? nameToLabelField(cargo.loadingFromVessel)
			: cargo.loadingFromVessel,
		principalVoyageReference: cargo.principalVoyageReference,
		referenceNumber: cargo.referenceNumber,
		shipperCompany: optionalNameToLabelField(cargo.shipperCompany),
		parentCargoId: cargo.parentCargoId,
		terms: cargo.terms,
		id: cargo.id,
		isLinkedCargo: cargo.isLinkedCargo
	}));

	const charterers = operation.charterers.map(charterer => ({
		id: charterer.id,
		chartererCompanyId: charterer.chartererCompany.id,
		costSettlementIsDirect:
			charterer.costSettlementIsDirect !== undefined
				? charterer.costSettlementIsDirect.toString()
				: '',
		isOtherRebillablesApplied: charterer.isOtherRebillablesApplied,
		isWorldScaleServicesApplied: charterer.isWorldScaleServicesApplied,
		maxPortCost: charterer.maxPortCost.applies,
		maxPortCostCurrency: charterer.maxPortCost.currencyCode,
		operationalInstructions: charterer.operationalInstructions,
		timeBarisApplied: charterer.timeBar.applies,
		financialInstructions: charterer.financialInstructions,
		maxPortCostAmount: charterer.maxPortCost.amount
			? charterer.maxPortCost.amount.toString()
			: '0',
		timeBarDays: charterer.timeBar.days
			? charterer.timeBar.days.toString()
			: charterer.timeBar.days
	}));
	return {
		operationType,
		charterers: charterers || [],
		cargoes: cargoes || []
	} as Partial<FormData>;
};

const mapGenericOperation = (
	operation: GenericPortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	return {
		operationType,
		details: operation.details
	};
};

const mapSparesAndDeliveryDocumentsOperation = (
	operation: SparesAndDeliveryDocumentsOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);
	return {
		operationType,
		details: operation.details,
		number: `${operation.number}`
	};
};

const mapDischargeOperation = (
	operation: DischargePortJobOperation
): Partial<FormData> => {
	const operationType = getOperationType(operation);

	const cargoes = operation.cargoes.map(cargo => ({
		...(cargo.splitIndex && { splitIndex: cargo.splitIndex }),
		chartererCompany: optionalNameToLabelField(cargo.chartererCompany),
		charterPartyDate: cargo.charterPartyDate,
		mainCommodity: cargo.mainCommodity.id,
		commodity: optionalNameToLabelField(cargo.commodity),
		commodityQuantity: cargo.commodityQuantity
			? cargo.commodityQuantity.toString()
			: undefined,
		commodityUnitOfMeasurementCode: cargo.commodityUnitOfMeasurementCode,
		loadPort: optionalNameToLabelField(cargo.loadPort),
		dischargingToVessel: cargo.dischargingToVessel
			? nameToLabelField(cargo.dischargingToVessel)
			: undefined,
		principalVoyageReference: cargo.principalVoyageReference,
		referenceNumber: cargo.referenceNumber,
		positionNumber:
			(cargo.positionNumber && cargo.positionNumber.toString()) || undefined,
		terms: cargo.terms,
		id: cargo.id,
		parentCargoId: cargo.parentCargoId,
		receiverCompany: optionalNameToLabelField(cargo.receiverCompany),
		isLinkedCargo: cargo.isLinkedCargo
	}));
	const charterers = operation.charterers.map(charterer => ({
		id: charterer.id,
		chartererCompanyId: charterer.chartererCompany.id,
		costSettlementIsDirect:
			charterer.costSettlementIsDirect !== undefined
				? charterer.costSettlementIsDirect.toString()
				: '',
		isOtherRebillablesApplied: charterer.isOtherRebillablesApplied,
		isWorldScaleServicesApplied: charterer.isWorldScaleServicesApplied,
		maxPortCost: charterer.maxPortCost.applies,
		maxPortCostCurrency: charterer.maxPortCost.currencyCode,
		operationalInstructions: charterer.operationalInstructions,
		timeBarisApplied: charterer.timeBar.applies,
		financialInstructions: charterer.financialInstructions,
		maxPortCostAmount: charterer.maxPortCost.amount
			? charterer.maxPortCost.amount.toString()
			: '0',
		timeBarDays: charterer.timeBar.days
			? charterer.timeBar.days.toString()
			: String(charterer.timeBar.days)
	}));
	return {
		operationType,
		cargoes: cargoes || [],
		charterers: charterers || []
	};
};

export const mapOperationForReduxForm = (
	state: AppState,
	operation: PortJobOperationTypes
): Partial<FormData> => {
	if (!operation) {
		return {};
	}
	switch (operation.code) {
		case OperationTypeCode.BUNKERING:
			return mapBunkeringOperation(
				state,
				operation as BunkeringPortJobOperation
			);
		case OperationTypeCode.CANAL_TRANSIT:
			return mapCanalTransitOperation(
				operation as CanalTransitPortJobOperation
			);
		case OperationTypeCode.CASH_TO_MASTER:
			return mapCashToMasterOperation(
				operation as CashToMasterPortJobOperation
			);
		case OperationTypeCode.CREW_CHANGE:
			return mapCrewChangeOperation(operation as CrewChangePortJobOperation);
		case OperationTypeCode.CREW_WAGE_DISBURSEMENT:
			return mapCrewWageDisbursementOperation(
				operation as CrewWageDisbursementPortJobOperation
			);
		case OperationTypeCode.LOADING:
		case OperationTypeCode.LOADING_STS:
			return mapLoadOperation(state, operation as LoadPortJobOperation);
		case OperationTypeCode.DISCHARGING:
		case OperationTypeCode.DISCHARGING_STS:
			return mapDischargeOperation(operation as DischargePortJobOperation);
		case OperationTypeCode.SPARES_AND_DOCUMENT_DELIVERY:
			return mapSparesAndDeliveryDocumentsOperation(
				operation as SparesAndDeliveryDocumentsOperation
			);
		case OperationTypeCode.GENERIC:
			return mapGenericOperation(operation as GenericPortJobOperation);
		default:
			return {};
	}
};
