import { DocIconType } from 'components/FileIcon/FileIcon';
import { createSelector } from 'reselect';
import { Theme } from 'components/types';
import { AppState } from 'store-types';
import {
	intersection,
	includes,
	find,
	groupBy,
	reduce,
	toNumber,
	isNumber,
	isNil,
	sortBy
} from 'lodash';
import {
	getFinanceServices,
	getExchangeRates,
	getServicesUsdTotalbyIdsHelper,
	getServicesCollection,
	getFinanceServiceSections,
	getFinanceStage,
	getServiceById,
	getIsServiceTaxInfoVisibleHelper,
	getAllDas,
	getFinanceEntityMetadataAction
} from './financeSelectors';
import {
	getUserType,
	getIsCurrentUserPrincipal,
	getIsCurrentUserHub
} from 'store/auth/selectors/user';

import {
	PortJobService,
	ServiceTemplate,
	ServiceSectionCode,
	FinanceStage,
	ServiceColumnsMetadataKey,
	DaType,
	DisbursementAccount,
	ServiceStatus,
	DaStatus,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';
import { getIsLoading } from 'store/selectors';

import {
	getIsCreditService,
	getIsDirectBillService
} from 'sections/PortCall/Finance/financeUtils';
import { UserType } from 'services/api/users/userServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import {
	availableServiceActions,
	FinanceServiceStatusLabel
} from '../constants';
import { Entity, Dictionary, MetadataAction } from 'app-types';
// import { disableFinanceReasonMap } from 'app-labels';
import { DaByServiceMap, CurrencySubTotalItem } from './financeSelectorTypes';
import { getValues } from 'store/form/selectors';
import {
	MoveServiceFormData,
	MOVE_SERVICE_FORM
} from 'sections/PortCall/Finance/Services/MoveServiceModal/constants';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import { MetadataStatusCode } from 'app-constants';

export const getIsSplitServiceModalShown = (state: AppState) =>
	state.finance.context.showSplitServiceModal;

export const getIsSplitServicePending = (state: AppState) =>
	getIsLoading(state.finance.fetchStatuses.splitService);

export const getIsApprovingAllServices = (state: AppState) =>
	getIsLoading(state.finance.fetchStatuses.approveAllServices);

export const makeGetIsServiceSettingsChanging = () => {
	return createSelector(
		(state: AppState) => state.finance.updateStatuses,
		(_state: AppState, jobServiceId: string) => jobServiceId,
		(updateStatuses, jobServiceId: string) =>
			updateStatuses.serviceSettings[jobServiceId] === FetchStatus.PENDING ||
			updateStatuses.convertService[jobServiceId] === FetchStatus.PENDING
	);
};

/**
 * Get a LIST of services by a list of services.id
 */
export const makeGetServicesbyIds = () =>
	createSelector(
		getFinanceServices,
		(_state: AppState, servicesIds: string[]) => servicesIds,
		(services, servicesIds) => {
			return services.filter(service => servicesIds.includes(service.id));
		}
	);

export const getServicesbyIds = makeGetServicesbyIds();

interface ServiceDictionary {
	childrenServicesMap: Dictionary<PortJobService[]>;
	parentServices: PortJobService[];
}

export const getGroupedServicesbyIds = createSelector(
	getServicesbyIds,
	(services): ServiceDictionary => {
		const parentServices = services.filter(s => !s.parentServiceId);
		const childrenServicesMap = services.reduce((acc, service) => {
			const { parentServiceId } = service;
			if (!parentServiceId) {
				return acc;
			}
			if (acc[parentServiceId]) {
				acc[parentServiceId].push(service);
			} else {
				acc[parentServiceId] = [service];
			}
			return acc;
		}, {});

		return {
			childrenServicesMap,
			parentServices
		};
	}
);

export const getSplitModalServiceId = (state: AppState) =>
	state.finance.context.splitModalServiceId;
export const getSplitModalSectionEntityId = (state: AppState) =>
	state.finance.context.splitModalSectionEntityId;

export const getIsMoveServiceModalShown = (state: AppState) =>
	state.finance.context.showMoveServiceModal;

export const getMoveServiceId = (state: AppState) =>
	state.finance.context.moveServiceModalId;

export const getCurrentMoveServiceCode = (state: AppState) =>
	state.finance.context.currentMoveServiceCode;

export const getAvailableServiceSections = (state: AppState) =>
	state.finance.context.availableServiceCode;

export const getMoveServiceFethStatus = (state: AppState) =>
	state.finance.fetchStatuses.moveService;

export const getMoveServiceFormData = (state: AppState) =>
	getValues<MoveServiceFormData>(state, MOVE_SERVICE_FORM);

const getAvailableServicesMap = (state: AppState) =>
	state.finance.context.availableServices;

export const getPrincipalServicesMap = (state: AppState) =>
	state.finance.context.principalServices;

export const makeGetPrincipalServicesByServiceId = () =>
	createSelector(
		getPrincipalServicesMap,
		(_state: AppState, serviceId: string) => serviceId,
		(principalServices, serviceId) => principalServices[serviceId] || []
	);

export const getPrincipalServicesByServiceId = makeGetPrincipalServicesByServiceId();
export const isAvailableServicesFetching = (state: AppState) =>
	state.finance.fetchStatuses.retrieveAvailableServices === FetchStatus.PENDING;

export const isPrincipleServicesFetching = (state: AppState) =>
	state.finance.fetchStatuses.retrievePrincipalServices === FetchStatus.PENDING;

export const makeGetServicesForAddPopover = () => {
	return createSelector(
		(state: AppState, sectionCode: ServiceSectionCode, entityId: string) => {
			return getAvailableServicesMap(state)[`${sectionCode}-${entityId || ''}`];
		},
		(availableServices: ServiceTemplate[] = []) => availableServices
	);
};

export const getAvailableServicesByServiceSection = makeGetServicesForAddPopover();

export const makeGetServicesUsdTotalbyIds = () =>
	createSelector(
		getServicesbyIds,
		getExchangeRates,
		getIsCurrentUserHub,
		getServicesUsdTotalbyIdsHelper
	);

export const getServicesUsdTotalbyIds = makeGetServicesUsdTotalbyIds();

export const getServiceStatusesForEditing = createSelector(
	getServiceById,
	(service: PortJobService) => {
		/**
		 * There is a possibility to have null for this selector with no reason.
		 * Please don't remove this condition if you don't eliminate the real reason of the problem
		 */
		if (!service) {
			return [];
		}
		const actionCodes = service.metadata.actions.map(
			action => action.actionCode
		);
		return intersection(actionCodes, availableServiceActions);
	}
);

export const labels = {
	[FinanceMetadataActionCode.QUERY_TO_LPA]: 'Queried to LPA',
	[FinanceMetadataActionCode.QUERY_TO_HUB]: 'Queried to HUB',
	[FinanceMetadataActionCode.VERIFY]: {
		[UserType.HUB]: 'Verified',
		[UserType.PRINCIPAL]: 'Approval pending'
	},
	[FinanceMetadataActionCode.SUBMIT]: 'Verification pending',
	[FinanceMetadataActionCode.APPROVE]: 'Approved'
};

const getLabel = (userType: UserType, value: FinanceMetadataActionCode) =>
	value === FinanceMetadataActionCode.VERIFY
		? labels[value][userType]
		: labels[value];

const getHasInfoColumn = createSelector(
	getServicesCollection,
	getFinanceStage,
	(collection, financeStage) => {
		const services = collection.elements;
		return !!services.find(service => {
			const { metadata } = service;
			return (
				financeStage === FinanceStage.DA &&
				metadata.actions.length === 0 &&
				metadata.propertiesToEdit.length === 0
			);
		});
	}
);

export const getHasComparisonColumn = (propertiesToView: string[]) => {
	return (
		includes(propertiesToView, ServiceColumnsMetadataKey.CE_AMOUNT_USD) &&
		includes(propertiesToView, ServiceColumnsMetadataKey.PDA_AMOUNT_USD)
	);
};

export const getPropertiesToView = createSelector(
	getServicesCollection,
	getHasInfoColumn,
	(collection, hasInfoCol) => {
		const customPropsToView = [];
		if (hasInfoCol) {
			customPropsToView.push(ServiceColumnsMetadataKey.INFO);
		}
		return [
			...collection.propertiesToView,
			...customPropsToView
		] as ServiceColumnsMetadataKey[];
	}
);

export const getSectionByCode = createSelector(
	getFinanceServiceSections,
	(_state: AppState, code: ServiceSectionCode) => code,
	(sections, code) => find(sections, { code })
);

export const getSectionActionMetadata = createSelector(
	getSectionByCode,
	(_state: AppState, _code, entityId?: string) => entityId,
	(section, entityId) => {
		if (!section) {
			return null;
		}
		if (entityId) {
			const subsection = find(section.sections, { entityId });
			return subsection ? subsection.metadata : null;
		}

		return section.metadata;
	}
);

export const getIsAddServiceButtonVisible = createSelector(
	getSectionActionMetadata,
	metadata =>
		metadata &&
		!!getFinanceEntityMetadataAction(metadata, FinanceMetadataActionCode.CREATE)
);

export const getIsServiceTaxInfoVisible = createSelector(
	getServiceById,
	getIsCurrentUserHub,
	getIsServiceTaxInfoVisibleHelper
);

export const getIsServiceInAgencyCostsSection = createSelector(
	getServiceById,
	service =>
		service && service.serviceSection.code === ServiceSectionCode.AGENCY
);

export const getShouldShowAngleIcon = createSelector(
	(
		_state: AppState,
		{ record }: { record: PortJobService; isNested?: boolean }
	) => getIsDirectBillService(record),
	(state, { record }) => getIsServiceInAgencyCostsSection(state, record.id),
	(_state, { record }) => getIsCreditService(record),
	(_state, { isNested }) => isNested,
	(isDirectBill, isInAgencyCosts, isCredit, isNested) =>
		isNested && (!isCredit || !isInAgencyCosts || isDirectBill)
);

export const getServiceDocType = createSelector(
	getUserType,
	getFinanceStage,
	(
		_state: AppState,
		doc: PortJobService['document'],
		isDaOut: boolean | undefined,
		serviceSectionCode: ServiceSectionCode | undefined,
		modeCode?: PortJobService['modeCode']
	) => ({ doc, isDaOut, serviceSectionCode, modeCode }),
	(
		userType,
		financeStage,
		{ doc, isDaOut, serviceSectionCode, modeCode }
	): DocIconType => {
		if (doc) {
			return Theme.SUCCESS;
		} else {
			const isUserPrincipal = userType === UserType.PRINCIPAL;
			const notDaStage = financeStage !== FinanceStage.DA;
			const isRevenueService =
				isDaOut && serviceSectionCode === ServiceSectionCode.AGENCY;
			const hasBeenConvertedFromPassThrough = modeCode === 'Converted';
			return isUserPrincipal ||
				notDaStage ||
				isRevenueService ||
				hasBeenConvertedFromPassThrough
				? Theme.STANDARD
				: Theme.WARNING;
		}
	}
);

export const getIsServiceWithChildren = createSelector(
	getFinanceServices,
	(_state: AppState, serviceId: string) => serviceId,
	(services, serviceId) => {
		return !!find(services, { parentServiceId: serviceId });
	}
);

const getServiceParent = (state: AppState, service: PortJobService) =>
	service.parentServiceId
		? getServiceById(state, service.parentServiceId)
		: undefined;

export const getIsServiceGrandChild = (
	state: AppState,
	service: PortJobService
) => {
	const parent = getServiceParent(state, service);
	return !!parent?.parentServiceId;
};

export const getCanViewPrincipalServices = createSelector(
	getPropertiesToView,
	getUserType,
	(propertiesToView, userType) =>
		userType !== UserType.PRINCIPAL &&
		includes(propertiesToView, ServiceColumnsMetadataKey.PRINCIPAL_SERVICE_CODE)
);

export const canEditPrincipalServicesHelper = (propsToEdit: string[]) => {
	return includes(
		propsToEdit,
		ServiceColumnsMetadataKey.PRINCIPAL_SERVICE_CODE
	);
};

export const isAddUnitCostButtonVisible = (
	actions: MetadataAction<FinanceMetadataActionCode>[]
) => {
	const action = getEntityMetadataAction<FinanceMetadataActionCode>(
		actions,
		FinanceMetadataActionCode.ADD_UNIT_COST_RATE
	);
	return Boolean(action && action.statusCode === MetadataStatusCode.ENABLED);
};

export const isViewUnitCostButtonVisible = (
	actions: MetadataAction<FinanceMetadataActionCode>[]
) => {
	const action = getEntityMetadataAction<FinanceMetadataActionCode>(
		actions,
		FinanceMetadataActionCode.VIEW_UNIT_COST_RATE
	);
	return Boolean(action && action.statusCode === MetadataStatusCode.ENABLED);
};

export const isEditUnitCostButtonVisible = (
	actions: MetadataAction<FinanceMetadataActionCode>[]
) => {
	const action = getEntityMetadataAction<FinanceMetadataActionCode>(
		actions,
		FinanceMetadataActionCode.EDIT_UNIT_COST_RATE
	);
	return Boolean(action && action.statusCode === MetadataStatusCode.ENABLED);
};

export const getServiceNameInfo = createSelector(
	getIsCurrentUserPrincipal,
	(_state: AppState, record: PortJobService) => record,
	(isPrincipal, record) => {
		if (isPrincipal && record.principalServiceCode) {
			return {
				name: record.principalServiceName,
				code: record.principalServiceCode
			};
		}
		return {
			name: record.name,
			code: record.code
		};
	}
);

export const getIsLoadingService = (state: AppState, id: string) =>
	getIsLoading(state.finance.updateStatuses.services[id]);

export const getServicesQueriesFetchStatus = (
	state: AppState,
	serviceId: string
) => state.finance.fetchStatuses.serviceQueries[serviceId];

export const getServicesQueriesMap = (state: AppState) =>
	state.finance.context.serviceQueries;

export const getQueriesByServiceId = createSelector(
	getServicesQueriesMap,
	(_state: AppState, serviceId: string) => serviceId,
	(serviceQueries, serviceId) => serviceQueries[serviceId]
);
/**
 * TODO: It's temp and will be removed!!!
 * it is not a selector, just function.
 * It is supposed to be temporary solution and supposed to be removed or converted to selector, because
 * we have to use state directly in order to get daByService without passing it in the getColumnsConfig function.
 */
export const getDaCurrencyCodeHelper = (
	service: PortJobService,
	daType: DaType,
	daByService: DaByServiceMap
) => {
	let currentDa = daByService[service.id] && daByService[service.id][daType];
	if (!currentDa && service.parentServiceId) {
		currentDa =
			daByService[service.parentServiceId] &&
			daByService[service.parentServiceId][daType];
	}
	if (!currentDa && service.daInCurrencyCode) {
		return service.daInCurrencyCode;
	}
	const isSameCurrency =
		currentDa && currentDa.currencyCode === service.currencyCode;

	return isSameCurrency || !currentDa
		? service.currencyCode
		: currentDa.currencyCode;
};

export const getServiceStatusTabs = createSelector(
	getServiceById,
	(service: PortJobService) => {
		const tabs = [];
		const isDirectBillQueryable =
			service.metadata &&
			service.metadata.actions.some(action => {
				return (
					action.actionCode === FinanceMetadataActionCode.DIRECT_BILL_QUERYABLE
				);
			});
		if (service.daInId || (service.directBill && !isDirectBillQueryable)) {
			tabs.push({
				id: 'lpaQuery',
				eventsKey: 'lpaQueries',
				label: 'MESSAGES WITH LPA'
			});
		}
		if (service.daOutId) {
			tabs.push({
				id: 'principalQuery',
				eventsKey: 'principalQueries',
				label: 'MESSAGES WITH PRINCIPAL'
			});
		}
		return tabs;
	}
);

export const getVerifiedServiceStatus = (
	userType: UserType,
	daOut?: DisbursementAccount
) => {
	let label = '';
	const status = daOut?.status;
	switch (status) {
		case DaStatus.HUB_SUBMISSION_PENDING:
			// TODO: Refactor that
			switch (userType) {
				case UserType.LPA:
					label = FinanceServiceStatusLabel.APPROVAL_PENDING;
					break;
				case UserType.HUB:
					label = FinanceServiceStatusLabel.APPROVAL_PENDING;
					break;
				case UserType.PRINCIPAL:
					label = FinanceServiceStatusLabel.VERIFICATION_PENDING;
					break;
				default:
					label = FinanceServiceStatusLabel.APPROVAL_PENDING;
			}
			break;
		default:
			label = FinanceServiceStatusLabel.APPROVAL_PENDING;
			break;
	}
	return label;
};

export const getDraftServiceStatus = (userType: UserType) =>
	userType === UserType.PRINCIPAL
		? FinanceServiceStatusLabel.PENDING
		: FinanceServiceStatusLabel.DRAFT;

export const getSubmittedServiceStatus = (userType: UserType) => {
	if (userType === UserType.LPA) {
		return FinanceServiceStatusLabel.APPROVAL_PENDING;
	}
	return FinanceServiceStatusLabel.VERIFICATION_PENDING;
};

export const getQueriedServiceStatus = (
	userType: UserType,
	queriedToHub: boolean,
	queriedToLPA: boolean,
	daIn?: DisbursementAccount,
	daOut?: DisbursementAccount
) => {
	switch (userType) {
		case UserType.LPA:
			// LPA+ HUB- DA In only Hub submission pending or queried
			if (queriedToLPA && !queriedToHub) {
				if (daIn && daIn.status === DaStatus.HUB_SUBMISSION_PENDING) {
					return FinanceServiceStatusLabel.APPROVAL_PENDING;
				} else if (daIn && daIn.status === DaStatus.QUERIED) {
					return FinanceServiceStatusLabel.QUERIED;
				}
				return FinanceServiceStatusLabel.QUERIED;
			}
			// LPA- HUB+
			if (!queriedToLPA && queriedToHub) {
				// This rule can be skipped
				if (daOut && daOut.status === DaStatus.PRINCIPAL_SUBMISSION_PENDING) {
					return FinanceServiceStatusLabel.APPROVAL_PENDING;
				}
				return FinanceServiceStatusLabel.APPROVAL_PENDING;
			}
			// LPA+ HUB+
			if (queriedToLPA && queriedToHub) {
				if (daIn && daIn.status === DaStatus.HUB_SUBMISSION_PENDING) {
					return FinanceServiceStatusLabel.APPROVAL_PENDING;
				}
				return FinanceServiceStatusLabel.QUERIED;
			}
			break;
		case UserType.HUB:
			// LPA+ HUB- DA In only Hub submission pending or queried
			if (queriedToLPA && !queriedToHub) {
				return FinanceServiceStatusLabel.QUERIED_LPA;
			}
			// LPA- HUB+
			if (!queriedToLPA && queriedToHub) {
				if (daOut && daOut.status === DaStatus.PRINCIPAL_SUBMISSION_PENDING) {
					return FinanceServiceStatusLabel.APPROVAL_PENDING;
				}
				return FinanceServiceStatusLabel.QUERIED_HUB;
			}
			// LPA+ HUB+
			if (queriedToLPA && queriedToHub) {
				return FinanceServiceStatusLabel.QUERIED_LPA;
			}
			return FinanceServiceStatusLabel.QUERIED_LPA;
		case UserType.PRINCIPAL:
			// LPA+ HUB- DA In only Hub submission pending or queried
			if (queriedToLPA && !queriedToHub) {
				return FinanceServiceStatusLabel.VERIFICATION_PENDING;
			}
			// LPA- HUB+
			if (!queriedToLPA && queriedToHub) {
				return FinanceServiceStatusLabel.QUERIED;
			}

			if (queriedToLPA && queriedToHub) {
				return FinanceServiceStatusLabel.QUERIED;
			}
			break;
		default:
			return FinanceServiceStatusLabel.QUERIED;
	}
	return FinanceServiceStatusLabel.QUERIED;
};

export const getServiceStatusLabel = createSelector(
	getAllDas,
	getUserType,
	getServiceById,
	(das: DisbursementAccount[], userType, service) => {
		if (!service) {
			return { id: '', name: FinanceServiceStatusLabel.UNKNOWN };
		}
		const daIn = das.find(da => da.id === service.daInId);
		const daOut = das.find(da => da.id === service.daOutId);
		let serviceStatus = '';
		switch (service.status) {
			case ServiceStatus.VERIFIED:
				serviceStatus = getVerifiedServiceStatus(userType, daOut);
				break;
			case ServiceStatus.APPROVED:
				serviceStatus = FinanceServiceStatusLabel.APPROVED;
				break;
			case ServiceStatus.DRAFT:
				serviceStatus = getDraftServiceStatus(userType);
				break;
			case ServiceStatus.SUBMITTED:
				serviceStatus = getSubmittedServiceStatus(userType);
				break;
			case ServiceStatus.QUERIED:
				serviceStatus = getQueriedServiceStatus(
					userType,
					service.queriedToHub,
					service.queriedToLPA,
					daIn,
					daOut
				);
				break;
			default:
				serviceStatus = service.status;
		}

		return {
			id: service.status,
			name: serviceStatus
		};
	}
);

export const getAvailableServiceStatuses = createSelector(
	(state, service: PortJobService) =>
		getServiceStatusesForEditing(state, service.id),
	(state, service) => getServiceStatusLabel(state, service.id),
	getUserType,
	(_state: AppState, service: PortJobService) => service,
	(
		statuses: FinanceMetadataActionCode[],
		currentStatus: Entity,
		userType: UserType,
		service
	) => {
		const result = [
			{ id: service.status, label: currentStatus.name },
			...statuses.map(value => {
				return {
					id: value,
					label: getLabel(userType, value)
				};
			})
		];
		return result;
	}
);

export const getChildSplittedServices = createSelector(
	getFinanceServices,
	(_state: AppState, serviceId: string) => serviceId,
	(services, serviceId) => {
		return services.filter(service => service.parentServiceId === serviceId);
	}
);

export const getDefaultChildDirectBillService = createSelector(
	getChildSplittedServices,
	children => (children.length ? children[0] : undefined)
);

export const getIsDirectBillServiceDefaultChildCredit = createSelector(
	getDefaultChildDirectBillService,
	defaultChild => !!defaultChild && defaultChild.isCredit
);

export const getCurrencyForDirectBillSplitModal = createSelector(
	getDefaultChildDirectBillService,
	(_state, _serviceId, originalCurrencyCode) => originalCurrencyCode,
	(defaultChildDirectBillService, originalCurrencyCode) =>
		defaultChildDirectBillService
			? defaultChildDirectBillService.currencyCode
			: originalCurrencyCode
);

export const getIsSplitNonDirectBillServiceModalInEditMode = createSelector(
	getChildSplittedServices,
	getServiceById,
	(childSplittedServices, service) =>
		!getIsDirectBillService(service || undefined) &&
		childSplittedServices.length > 0
);

export const isDeleteChildSplittedServiceDisabled = createSelector(
	getFinanceStage,
	(_state: AppState, serviceStatus: ServiceStatus | undefined) => serviceStatus,
	(stage, serviceStatus) => {
		if (!serviceStatus) {
			return false;
		}
		return (
			stage === FinanceStage.DA && serviceStatus === ServiceStatus.APPROVED
		);
	}
);

export const makeGetFinanceServicesWithStatus = (statuses: ServiceStatus[]) =>
	createSelector(getServicesCollection, ({ elements: services }) =>
		services.filter(service => statuses.includes(service.status))
	);

const getQueriedOrVerifiedServices = makeGetFinanceServicesWithStatus([
	ServiceStatus.QUERIED,
	ServiceStatus.VERIFIED
]);
export const getExistsQueriedOrVerifiedServices = createSelector(
	getQueriedOrVerifiedServices,
	services => !!services.length
);

export const getServicesCurrencyTotals = createSelector(
	getServicesbyIds,
	services => {
		const groupedServices = groupBy(services, 'currencyCode');
		const subTotalsList = reduce(
			groupedServices,
			(currencyList, services: PortJobService[], currencyCode) => {
				const availableServices = services.filter(
					service =>
						!isNil(service.amount) &&
						(service.modeCode !== 'Split' ||
							(service.modeCode === 'Split' && !service.parentServiceId))
				);
				if (availableServices.length === 0) {
					return currencyList;
				}
				currencyList.push({
					amount: availableServices.length,
					currencyCode,
					total: availableServices.reduce(
						(acc, { isCredit, amount }) =>
							acc + toNumber(isCredit && isNumber(amount) ? -amount : amount),

						0
					)
				});
				return currencyList;
			},
			[] as CurrencySubTotalItem[]
		);
		return sortBy(subTotalsList, ['currencyCode']);
	}
);

export const getIsServiceLPAColHidden = (service: PortJobService) => {
	return (
		!service.amountInDaIn &&
		!service.daInId &&
		!service.metadata.propertiesToEdit.includes(
			ServiceColumnsMetadataKey.DA_IN_AMOUNT
		) &&
		!service.metadata.propertiesToEdit.includes(ServiceColumnsMetadataKey.DA_IN)
	);
};

export const getIsServicePrincipalColHidden = (service: PortJobService) => {
	return (
		service.isDaOutDeleted ||
		(!service.amountInDaOut &&
			!service.daOutId &&
			!service.metadata.propertiesToEdit.includes(
				ServiceColumnsMetadataKey.DA_OUT_AMOUNT
			) &&
			!service.metadata.propertiesToEdit.includes(
				ServiceColumnsMetadataKey.DA_OUT
			))
	);
};
