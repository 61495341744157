import Api from 'services/api';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';

export const searchShipperReceivers = (searchTerm: string) =>
	Api.Companies.searchCompaniesForAutocomplete({
		types: [OrganisationType.SHIPPER],
		searchTerm
	});

export const searchCharterers = (searchTerm: string) =>
	Api.Companies.searchCompaniesForAutocomplete({
		types: [OrganisationType.CHARTERER],
		searchTerm
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const localSearch = (mapValText: any) => (options: any) => (
	searchTerm: string
) => {
	// TODO: Refactor this place
	const uSearchTerm = searchTerm.toUpperCase();
	return Promise.resolve(
		options
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.filter((option: any) =>
				mapValText(option)
					.name.toUpperCase()
					.includes(uSearchTerm)
			)
			.map(mapValText)
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localeSearchIdName = localSearch((option: any) => ({
	id: option.id,
	name: option.name
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const localeSearchAlphabetic = localSearch((option: any) => ({
	name: option.name,
	id: option.code
}));
