import React from 'react';
import { connect } from 'react-redux';

import ActAsContainer from 'containers/ActAs/ActAsContainer';
import PortCallActAsSwitcher from './PortCallActAsSwitcher';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getAuthHasPermission } from 'store/auth/selectors/permissionsSelector';
import { getActivePortJobStatus } from 'store/portJobs/portJobsSelectors';
import { AppState } from 'store-types';
import { PortJobStatus } from 'store/portJobs/constants';

const PortCallActAs: React.SFC<{
	portCallId: string;
	canActAs: boolean;
	portJobStatus: PortJobStatus;
}> = ({ portCallId, canActAs, children, portJobStatus }) => (
	<>
		<ActAsContainer>{children}</ActAsContainer>
		{canActAs && (
			<PortCallActAsSwitcher
				portCallId={portCallId}
				portJobStatus={portJobStatus}
			/>
		)}
	</>
);

export default connect((state: AppState) => ({
	canActAs: getAuthHasPermission(state, PermissionCode.ACTS_AS_LPA),
	portJobStatus: getActivePortJobStatus(state)
}))(PortCallActAs);
