import * as React from 'react';
import { connect } from 'react-redux';

import { DataFetcher } from 'utils/components';
import { retrieveThreadContexts } from 'store/threads/actions';
import { getThreadContexts } from 'store/threads/selectors';
import { ContextType } from 'services/api/threads/threadsServiceTypes';
import { AppState } from 'store-types';

interface ContextContainerProps {
	retrieveContexts: typeof retrieveThreadContexts;
	data: ContextType[];
	children: (data: ContextType[]) => React.ReactNode;
}
const ContextContainer: React.SFC<ContextContainerProps> = ({
	retrieveContexts,
	data,
	children
}) => {
	return (
		<DataFetcher
			dispatch={() => retrieveContexts(undefined)}
			data={data}
			isLoading={false}
		>
			{() => children(data)}
		</DataFetcher>
	);
};

export default connect(
	(state: AppState) => ({
		data: getThreadContexts(state)
	}),
	{
		retrieveContexts: retrieveThreadContexts
	}
)(ContextContainer);
