import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	toggleOperationsStatus,
	updateEnableOperationsAsync as async
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getIsOperationsDisabled
} from '../portJobsSelectors';
import { UpdateServiceSection } from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';
import {
	getUpdateServiceSection,
	getUpdateAlternativeSection
} from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';

const apiCall = Api.PortJobs.enableOperationsUpdate;

const executor = function*(): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const sections = yield select(getUpdateServiceSection);
	const alternateSections = yield select(getUpdateAlternativeSection);
	const isOperationsDisabled = yield select(getIsOperationsDisabled);

	const request = {
		portCallId,
		jobCode,
		portJobServiceEnableOperationUpdateRequestData: sections,
		alternateSectionUpdateRequestData: alternateSections
	} as UpdateServiceSection;
	yield put(async.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			async.done({ result: response.data, params: request, response: null })
		);
		yield put(closeModal('enable-operations-modal'));
		yield put(
			toggleOperationsStatus({
				portCallId,
				jobCode,
				isOperationsDisabled: !isOperationsDisabled
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
};

const updateEnableOperationsWatcher = makeTakeLatestWatcher<
	UpdateServiceSection,
	null,
	Error
>({ api: apiCall, async }, executor);

export default updateEnableOperationsWatcher;
