import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { retrieveGroupUsersAsync } from './actions/retrieveGroupUsers';
import { addGroupUserAsync } from './actions/addGroupUser';
import {
	deleteGroupUserAsync,
	onDeleteGroupUserFailed,
	onDeleteGroupUserStarted,
	onDeleteGroupUserSuccess
} from './actions/deleteGroupUser';
import { deleteThirdPartyLPAGroupUserAsync } from './actions/deleteThirdPartyLPAGroupUser';
import { resetGroupUsers } from './actions/resetGroupUsers';
import { initialState } from './groupUsersState';
import {
	onRetrieveGroupUsersFailed,
	onRetrieveGroupUsersStarted,
	onRetrieveGroupUsersSuccess,
	onAddGroupUserFailed,
	onAddGroupUserStarted,
	onAddGroupUserSuccess,
	onResetGroupUsers,
	onDeleteThirdPartyLPAGroupUserStarted,
	onDeleteThirdPartyLPAGroupUserSuccess,
	onDeleteThirdPartyLPAGroupUserFailed,
	onInviteThirdPartyLpaUser
} from './reducers';
import { inviteThirdPartyLpaUserAsync } from './actions/inviteThirdPartyLpaUser';

export default reducerWithInitialState(initialState)
	.case(retrieveGroupUsersAsync.started, onRetrieveGroupUsersStarted)
	.case(retrieveGroupUsersAsync.failed, onRetrieveGroupUsersFailed)
	.case(retrieveGroupUsersAsync.done, onRetrieveGroupUsersSuccess)

	.case(deleteGroupUserAsync.started, onDeleteGroupUserStarted)
	.case(deleteGroupUserAsync.done, onDeleteGroupUserSuccess)
	.case(deleteGroupUserAsync.failed, onDeleteGroupUserFailed)
	.case(addGroupUserAsync.started, onAddGroupUserStarted)
	.case(addGroupUserAsync.done, onAddGroupUserSuccess)
	.case(addGroupUserAsync.failed, onAddGroupUserFailed)
	.case(resetGroupUsers, onResetGroupUsers)
	.case(
		deleteThirdPartyLPAGroupUserAsync.started,
		onDeleteThirdPartyLPAGroupUserStarted
	)
	.case(
		deleteThirdPartyLPAGroupUserAsync.done,
		onDeleteThirdPartyLPAGroupUserSuccess
	)
	.case(
		deleteThirdPartyLPAGroupUserAsync.failed,
		onDeleteThirdPartyLPAGroupUserFailed
	)
	.case(inviteThirdPartyLpaUserAsync.started, onInviteThirdPartyLpaUser.started)
	.case(inviteThirdPartyLpaUserAsync.done, onInviteThirdPartyLpaUser.done)
	.case(inviteThirdPartyLpaUserAsync.failed, onInviteThirdPartyLpaUser.failed);
