import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallsState as State } from '../portCallsState';
import {
	RetrievePortCallsAlertsRequest as Request,
	RetrievePortCallsAlertsResponse as Response,
	PortCallAlertsGroup
} from 'services/api/portCalls/portCallsServiceTypes';
import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { PortCallAlertsMap } from 'app-types';

const emptyAlertsMap = (portCallIds: string[]): PortCallAlertsMap[] =>
	portCallIds.map(portCallId => ({
		portCallId,
		portCallAlertGroupsByMetricType: [],
		portJobs: {}
	}));
const fillAlertsMap = (groups: PortCallAlertsGroup[]): PortCallAlertsMap[] =>
	groups.map(portCall => ({
		...portCall,
		portJobs: keyBy(portCall.portJobs, job => job.portJobId)
	}));
export const onRetrievePortCallsAlerts = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'alerts', {
	doneReducer: (state: State, action: Success<Request, Response>): State => {
		const {
			params: { portCallIds },
			result
		} = action;
		const portCallsWithJobsById = emptyAlertsMap(portCallIds).concat(
			fillAlertsMap(result)
		);
		return {
			...state,
			metricTypeGroupsByPortCall: {
				...state.metricTypeGroupsByPortCall,
				...keyBy(portCallsWithJobsById, item => item.portCallId)
			}
		};
	}
});
