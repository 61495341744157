import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { ReduxFormProps } from 'components/ReduxForm/ReduxForm';
import EditContactFields from './EditContactFields';
import {
	ADD_CONTACT_FORM_ID,
	ContactEmailOwnerValue,
	EditContactFieldName
} from './EditContactConstants';
import { AppState } from 'store-types';
import {
	getCurrentContact,
	getAddContactEmail
} from 'store/messages/selectors';
import validate from './EditContactValidate';
import { LabeledValue } from 'app-types.d';
import history from 'services/history';
import styles from './EditContact.module.scss';

export interface EditContactFormData {
	email: string;
	company: LabeledValue;
	contactType: string;
	firstName: string;
	lastName: string;
	phone: string;
	notes: string;
}

/**
 * Props injected to every little Stateless form
 */
export interface EditContactInitialData {
	initialValues: Partial<EditContactFormData>;
}

/**
 * Props Injected to Stateless form container
 */
type EditContactFormProps = ReduxFormProps<
	EditContactFormData,
	EditContactInitialData
>;

const EditContactForm: React.SFC<EditContactFormProps> = props => (
	<div className={styles.editContactForm}>
		<EditContactFields initialValues={props.initialValues} />
	</div>
);
const EditContactReduxForm = reduxForm<
	EditContactFormData,
	EditContactInitialData
>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: ADD_CONTACT_FORM_ID,
	enableReinitialize: true,
	validate
})(EditContactForm);

export default connect<EditContactInitialData>((state: AppState) => {
	const contact = getCurrentContact(state);

	const initialValues = contact
		? {
				[EditContactFieldName.EMAIL]: getAddContactEmail(history.location),
				[EditContactFieldName.COMPANY]: {
					key: contact.relatesToCompany.id,
					label: contact.relatesToCompany.name
				},
				[EditContactFieldName.FIRST_NAME]: contact.firstName,
				[EditContactFieldName.LAST_NAME]: contact.lastName,
				[EditContactFieldName.PHONE]: contact.phone,
				[EditContactFieldName.NOTES]: contact.notes,
				[EditContactFieldName.CONTACT_TYPE]: contact.contactType.code
			}
		: {
				[EditContactFieldName.EMAIL]: getAddContactEmail(history.location),
				[EditContactFieldName.CONTACT_TYPE]: ContactEmailOwnerValue.PERSONAL
			};

	return {
		initialValues
	};
})(EditContactReduxForm);
