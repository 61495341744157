import * as React from 'react';
import { Text, Title, SearchablePopover } from 'components';
import { Button } from 'components/antd';
import styles from './AssignTo.module.scss';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
	getThreadAvailableGroupsOptionsSelector,
	getThreadNewPermissionsSelector
} from 'store/thread/selectors';
import { Entity } from 'app-types';
import {
	retrieveAvailableThreadGroups,
	patchThreadPermissions
} from 'store/thread';

interface ConditionalTitleProps {
	showingTitle: boolean;
	onClick: () => void;
	threadId: string;
	assignedGroupId: string;
	hideMoveBtn?: boolean;
	editAssignTo?: boolean;
}

const ConditionalTitle: React.SFC<ConditionalTitleProps> = ({
	showingTitle,
	onClick,
	threadId,
	assignedGroupId,
	hideMoveBtn,
	editAssignTo
}) => {
	React.useEffect(() => {
		dispatch(retrieveAvailableThreadGroups({ threadId }));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	const [isPopupVisible, setPopupVisible] = React.useState(false);

	const dispatch = useDispatch();
	const availableGroups: Entity[] = useSelector(
		getThreadAvailableGroupsOptionsSelector,
		shallowEqual
	);
	const threadPermissionGroupsInitialValues = useSelector(
		getThreadNewPermissionsSelector
	);
	const groupResults = React.useMemo(
		() => availableGroups.filter(grp => assignedGroupId !== grp.id),
		[availableGroups, assignedGroupId]
	);
	const searchResults = React.useMemo(() => {
		return groupResults.map(result => {
			return {
				id: result.id,
				name: result.name,
				code: result.name,
				disabled: false
			};
		});
	}, [groupResults]);

	const onSelect = React.useCallback(
		({ id }) => {
			const groupIds = threadPermissionGroupsInitialValues
				.filter(group => !group.id.includes(id))
				.map(({ id }) => id);
			groupIds.push(id);

			dispatch(
				patchThreadPermissions({
					threadId,
					assignedToGroupId: id,
					assignedToUserId: null,
					threadPermissionGroups: groupIds
				})
			);
		},
		[dispatch, threadPermissionGroupsInitialValues, threadId]
	);

	return showingTitle ? (
		<Title.H4 className={styles.assignedToTitle}>
			<Text weight="bold" className={styles.title}>
				Assigned To
			</Text>
			<div className={styles.btnWrapper}>
				<Button
					type="primary"
					transparent
					className={styles.assignedToBtn}
					onClick={onClick}
					disabled={editAssignTo}
				>
					Edit
				</Button>
				<SearchablePopover
					title="New assigned group"
					data={searchResults}
					loading={false}
					placeholder="Type to search"
					placement="bottomRight"
					onSelect={onSelect}
					onVisibleChange={visible => setPopupVisible(visible)}
					setStateOnPopupVisible={isPopupVisible}
					hideAfterSelect
				>
					{!hideMoveBtn ? (
						<Button
							type="primary"
							transparent
							className={styles.assignedToBtn}
							disabled={editAssignTo}
						>
							Move
						</Button>
					) : null}
				</SearchablePopover>
			</div>
		</Title.H4>
	) : null;
};

export default ConditionalTitle;
