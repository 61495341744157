import { all, fork } from 'redux-saga/effects';
import retrieveUserByTokenWatcher from './retrieveUserByTokenSaga';
import registerUserWatcher from './registerUserByTokenSaga';
import preRegisterWatcher from './preRegisterSaga';
import preRegisterVerifyWatcher from './preRegisterVerifySaga';
import preRegisterSubmitWatcher from './preRegisterSubmitSaga';
import retrievePreRegistrationAgentCompanyWatcher from './retrievePreRegistrationAgentCompanySaga';

export default function*() {
	yield all([
		fork(retrieveUserByTokenWatcher),
		fork(registerUserWatcher),
		fork(preRegisterWatcher),
		fork(preRegisterVerifyWatcher),
		fork(preRegisterSubmitWatcher),
		fork(retrievePreRegistrationAgentCompanyWatcher)
	]);
}
