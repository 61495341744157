import { take, put, fork, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { Template } from 'services/api/templates/templatesServiceTypes';

import { initializeConfigurationEmailFragmentsAsync as async } from '../actions';
import {
	retrieveCompanyTemplates,
	retrieveCompanyTemplatesAsync
} from 'store/templates/actions';

import { getActiveCompanyId } from '../selectors';
import initializeConfigurationEmailFragmentsFormWorker from './initializeConfigurationEmailFragmentsFormWorker';

/*
	As part of OW-13113(UD_10) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

// import Api from 'services/api';

// const extendedMessagingApi =
// 	Api.Companies.retrieveExtendedMessagingConfiguration;

export function* worker(): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	yield put(async.started(null));
	try {
		// Retrieve Data for the form
		yield put(
			retrieveCompanyTemplates({
				companyId,
				templates: [
					Template.JOB_APPOINTMENT,
					Template.EMAIL_TO_MASTER,
					Template.JOB_APPOINTMENT_FROM_NOMINATION,
					Template.EMAIL_TO_MASTER_FOR_NOMINATION
				]
			})
		);
		const response = yield take([
			retrieveCompanyTemplatesAsync.done,
			retrieveCompanyTemplatesAsync.failed
		]);

		// const extendedMessagingResponse = yield call(extendedMessagingApi, {
		// 	companyId
		// });

		if (response.payload.error) {
			throw new Error();
		}
		// const payload = {
		// 	extendedMessagingOption: !extendedMessagingResponse.data
		// 		.extendedMessagingOption
		// 		? 'No'
		// 		: extendedMessagingResponse.data.extendedMessagingOption,
		// 	list: response.payload.result
		// };

		yield fork(
			initializeConfigurationEmailFragmentsFormWorker,
			response.payload.result
		);
		yield put(
			async.done({
				result: null,
				params: null,
				response: null
			})
		);
	} catch (e) {
		yield put(
			async.failed({
				error: e,
				params: null
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
