import { Failure } from 'typescript-fsa';
import { FileExtension } from 'app-constants';
import { getFileExtension } from 'utils';
import { FetchStatus } from 'services/api/apiTypes';
import {
	UploadDocumentRequest,
	UploadType
} from 'services/api/documents/documentsServiceTypes';
import { DocumentsState, initialState } from '../documentsState';
import { CancelUploadAndProcessDocumentRequest } from '../actions';

export const onUploadAndProcessDocumentStarted = (
	state: DocumentsState,
	{
		request: { file },
		type,
		showProgress,
		hideClose = false,
		metadata: { contextualizePdf } = {}
	}: UploadDocumentRequest
): DocumentsState => ({
	...state,
	upload: {
		...state.upload,
		showProgress,
		hideClose,
		inProgress: true,
		file,
		info: {
			...state.upload.info,
			hasSplit:
				((type === UploadType.FINANCE_DOCUMENT && contextualizePdf !== false) ||
					type === UploadType.DOCUMENT ||
					type === UploadType.SUPPLIER_INVOICE) &&
				getFileExtension(file.name) === FileExtension.PDF
		},
		fetchStatus: FetchStatus.PENDING
	}
});

export const onUploadAndProcessDocumentFailed = (
	state: DocumentsState,
	action: Failure<UploadDocumentRequest, Error>
): DocumentsState => ({
	...state,
	upload: {
		...initialState.upload,
		showProgress: action.params.showProgress,
		inProgress: true,
		/**
		 * Need to enable the close button if there is a failure in the process.
		 */
		hideClose: false,
		errorMessage: action.error.message,
		fetchStatus: FetchStatus.FAILURE
	}
});

export const onUploadAndProcessDocumentCancelled = (
	state: DocumentsState,
	{ shouldResetFile }: CancelUploadAndProcessDocumentRequest
): DocumentsState => ({
	...state,
	upload: shouldResetFile
		? initialState.upload
		: { ...initialState.upload, file: state.upload.file }
});
