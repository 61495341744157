import {
	UploadDocumentRequest,
	SaveDocumentRequest
} from 'services/api/documents/documentsServiceTypes';
import actionCreator from '../documentsActionCreator';

const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const uploadDocumentAsync = actionCreator.async<
	UploadDocumentRequest,
	SaveDocumentRequest,
	Error
>(UPLOAD_DOCUMENT);

export const uploadDocumentProgress = actionCreator<number>(
	'UPLOAD_DOCUMENT_PROGRESS'
);
