import actionCreator from 'store/finance/financeActionCreator';
import {
	UpdateFinanceStatusPayload,
	UpdateFinanceStatusRequest
} from 'services/api/finance/financeServiceTypes';

const UPDATE_FINANCE_STATUS = 'UPDATE_FINANCE_STATUS';

export const updateFinanceStatusAsync = actionCreator.async<
	UpdateFinanceStatusRequest,
	null,
	Error
>(UPDATE_FINANCE_STATUS);

export const updateFinanceStatus = actionCreator<UpdateFinanceStatusPayload>(
	UPDATE_FINANCE_STATUS
);
