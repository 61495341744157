import { AnyAction } from 'redux';
import { isEmpty, defaultsDeep } from 'lodash';
import {
	ActionSuccess,
	ActionFailure,
	ActionMetaTemplateParams,
	ActionMetaConfig
} from 'app-types';
import {
	isResponseError,
	errorStatusCode,
	getRequestMethod
} from 'services/api/apiErrorUtils';
import { isActionAsyncDone, isActionAsyncFailure } from 'store/utils';
import { NotificationVariantItem } from 'store/notifications/notificationsState';

import {
	getNotificationVariantItem,
	getNotificationVariantItemError
} from './notificationHandlerMetaUtils';
import { ApiMiddlewareHandleType } from '../../apiMiddlewareTypes';
import API_MIDDLEWARE_CONFIG from '../../apiMiddlewareConfig';
import {
	getConfigValue,
	shouldOmitSettingConfigValue
} from '../../apiMiddlewareConfigUtils';

const { VALUE_CONFIG } = API_MIDDLEWARE_CONFIG[
	ApiMiddlewareHandleType.NOTIFICATION
];

export const getNotification = (
	action: AnyAction,
	// NOTIFICATIONS TODO: temporary done, in order to support existing logic
	forceShowFailure = false
) => {
	if (isActionAsyncDone(action)) {
		return notificationHandlers.done(action);
	}
	if (isActionAsyncFailure(action)) {
		return notificationHandlers.failed(action, forceShowFailure);
	}
	return;
};

export const notificationHandlers = {
	done: ({
		meta = {},
		payload: { response }
	}: ActionSuccess): NotificationVariantItem | undefined => {
		const initialValues: NotificationVariantItem = {
			...VALUE_CONFIG.success,
			description: ''
		};
		const templateParams = {
			...VALUE_CONFIG.templateParams,
			...meta.templateParams
		};
		let partialValues = getNotificationVariantItem(meta.done, templateParams);

		if (response && !isEmpty(response)) {
			const { done: { methods = {} } = {} } = meta;
			const method = response.config.method;

			if (method) {
				const partial = defaultsDeep(
					methods[method],
					getConfigValue({ key: 'method', type: method })
				);
				// assign default values configured by `method` if they weren't previously assigned
				partialValues = defaultsDeep(
					partialValues,
					getNotificationVariantItem(partial, templateParams)
				);
			}
		}

		if (partialValues?.description) {
			return {
				...initialValues,
				...partialValues
			};
		}
		return;
	},
	failed: (
		{
			meta = {
				failed: {
					methods: {},
					statusCodes: {}
				}
			},
			payload: { error }
		}: ActionFailure,
		forceShowFailure = false
	): NotificationVariantItem | undefined => {
		const initialValues = VALUE_CONFIG.error;
		const templateParams: ActionMetaTemplateParams = {
			...(VALUE_CONFIG.templateParams || {}),
			...meta.templateParams
		};

		if (
			meta.templateParams &&
			meta.templateParams.entityName &&
			!meta.templateParams.concurrencyEntityName
		) {
			// we will take entityName as a default one for concurrencyEntityName, if not explicitly provided
			templateParams.concurrencyEntityName = meta.templateParams.entityName;
		}

		let collectedValues =
			getNotificationVariantItem(meta.failed, templateParams) || {};

		// return static configuration based on defaults and action meta values
		if (!isResponseError(error)) {
			return {
				...initialValues,
				description: error.message,
				...collectedValues
			};
		}

		const method = getRequestMethod(error);
		const statusCode = errorStatusCode(error);

		let partialValuesBySource:
			| Partial<NotificationVariantItem>
			| undefined = {};

		// configured by source type
		const bySource =
			VALUE_CONFIG.source[meta.behaviour?.type || ''] ?? undefined;
		if (bySource) {
			if (method && bySource.method && bySource.method[method]) {
				partialValuesBySource = getNotificationVariantItem(
					bySource.method[method].error,
					templateParams
				);
			}
		}

		const partialValuesByStatusDefault = getNotificationVariantItem(
			getConfigValue({ key: 'statusDefault', type: statusCode, error }),
			templateParams
		);

		const shouldOmitByMethodOrStatus =
			shouldOmitSettingConfigValue({ key: 'method', type: method }) ||
			shouldOmitSettingConfigValue({ key: 'status', type: statusCode });
		// in case http method/status has been configured to be omitted,
		// we will rely only on configuration by `source` value since it is meant to be an exception
		if (shouldOmitByMethodOrStatus && !forceShowFailure) {
			if (!isEmpty(partialValuesBySource)) {
				return defaultsDeep(
					{
						...initialValues,
						...partialValuesBySource,
						...collectedValues,
						error: getNotificationVariantItemError(meta, error)
					},
					partialValuesByStatusDefault
				);
			}
			return;
		}

		const { failed: { methods = {}, statusCodes = {} } = {} } = meta;

		// configured by methods
		if (method) {
			collectedValues = {
				...collectedValues,
				...mergeValues(
					methods[method],
					getConfigValue({ key: 'method', type: method, error }),
					templateParams
				)
			};
		}

		// configured by status
		collectedValues = {
			...collectedValues,
			...mergeValues(
				statusCodes[statusCode],
				getConfigValue({ key: 'status', type: statusCode, error }),
				templateParams
			)
		};

		collectedValues = {
			...collectedValues,
			...getNotificationVariantItem(partialValuesBySource, templateParams)
		};

		collectedValues = defaultsDeep(
			collectedValues,
			partialValuesByStatusDefault
		);

		return {
			...initialValues,
			...collectedValues,
			// fallback to `error.message` isnt practically going to happen, more due to TS validation
			description: collectedValues.description || error.message,
			error: getNotificationVariantItemError(meta, error)
		};
	}
};

function mergeValues(
	staticValue: ActionMetaConfig,
	defaultValue: ActionMetaConfig,
	templateParams: ActionMetaTemplateParams
) {
	return getNotificationVariantItem(
		defaultsDeep(staticValue, defaultValue),
		templateParams
	);
}
