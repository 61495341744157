import { takeLatest, put, all, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	setAuthActingAs,
	setAuthActAsConfig,
	resetAuthActAs,
	retrieveAuthUser
} from '../actions';
import { SetAuthActingAsPayload } from '../actions/setAuthActAs';
import { AuthActAsConfig } from '../authState';
import { isLeavingAuthActAsRoute } from '../authUtils';
import { getAuthActAsConfig } from '../selectors';
import { sessionStorageService as storage } from 'services/storage';
import history from 'services/history';

enum ActAsSessionKey {
	VALUE = 'actAs',
	CONFIG = 'actAsConfig'
}

export function* authSyncStorageWorker() {
	const value = storage.get(ActAsSessionKey.VALUE);
	const config = storage.get(ActAsSessionKey.CONFIG);

	if (config) {
		/**
		 * User may navigate to any other urls by changing it from the address bar
		 * which we cannot track, thus ending up with state still in progress
		 * For this reason, we need to reset the state in case he/she is not
		 * at the correct location
		 */
		if (isLeavingAuthActAsRoute(config, history.location.pathname)) {
			yield put(resetAuthActAs());
			return;
		}
		yield put(setAuthActAsConfig(config)); // Finally try to sync values from the storage back to the store
	}

	if (value) {
		yield put(setAuthActingAs(value)); // Finally try to sync values from the storage back to the store
	}

	return value;
}

function* onSetAuthActAsWorker(action: Action<SetAuthActingAsPayload>) {
	if (action.payload) {
		storage.set(ActAsSessionKey.VALUE, action.payload);
	} else {
		storage.clear(ActAsSessionKey.VALUE);
	}

	/**
	 * Always re-fetch user so that state is actualized
	 */
	yield put(retrieveAuthUser());
}

function* onSetAuthActAsConfig({ payload }: Action<AuthActAsConfig>) {
	const config = yield select(getAuthActAsConfig);
	storage.set(ActAsSessionKey.CONFIG, { ...config, ...payload });
}

/**
 * Reset session
 */
function resetAuthActAsWorker() {
	storage.clear(ActAsSessionKey.VALUE);
	storage.clear(ActAsSessionKey.CONFIG);
}

export default function*() {
	yield all([
		takeLatest(setAuthActingAs, onSetAuthActAsWorker),
		takeLatest(setAuthActAsConfig, onSetAuthActAsConfig),
		takeLatest(resetAuthActAs, resetAuthActAsWorker)
	]);
}
