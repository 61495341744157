import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { values, flatten, keyBy } from 'lodash';
import { getIsLoading } from 'store/selectors';
import {
	getContextualizationFormValues,
	getPortJobsFormValues
} from 'store/form/supplierInvoiceContextualization/selectors/contextualizationFormSelectors';
import {
	PortJobServiceFormData,
	SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID,
	FormField
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { Entity, LabeledValue } from 'app-types';
import { SupplierInvoiceRow } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { FinanceTransactionType } from 'services/api/finance/financeServiceTypes';
import { getFinanceCurrencyDecimalPart } from 'store/finance/selectors';
import { DEFAULT_DECIMAL_PLACES } from 'app-constants';
import { getCustomFormattedValue } from 'utils/numbers/getCustomFormattedValue';

const getSupplierInvoicesById = ({ supplierInvoice }: AppState) =>
	supplierInvoice.byId;

export const getSupplierInvoiceIndex = createSelector(
	getSupplierInvoicesById,
	(_state: AppState, supplierInvoiceId: string) => supplierInvoiceId,
	(byId, supplierInvoiceId) => Object.keys(byId).indexOf(supplierInvoiceId)
);

export const getSupplierInvoiceFetchStatuses = ({
	supplierInvoice
}: AppState) => supplierInvoice.fetchStatuses;

const getSupplierInvoiceAllFetchStatus = createSelector(
	getSupplierInvoiceFetchStatuses,
	fetchStatuses => fetchStatuses.all
);

export const getIsFetchingSupplierInvoices = createSelector(
	getSupplierInvoiceAllFetchStatus,
	status => getIsLoading(status)
);

export const getIsDocumentCreateLoading = (state: AppState) =>
	getIsLoading(state.supplierInvoice.fetchStatuses.create);

export const getIsDocumentUpdateLoading = (state: AppState) =>
	getIsLoading(state.supplierInvoice.fetchStatuses.update);

export const getHasInvoiceSplitRequest = ({
	transactionsDetails,
	split
}: SupplierInvoiceRow) => {
	const length = transactionsDetails.length;
	if (!length || split) {
		return false;
	}
	const transaction = transactionsDetails[0];
	if (transaction.type === FinanceTransactionType.SUPPLIER_INVOICE_SPLIT) {
		return true;
	}
	return false;
};

export const getSupplierInvoicesSelector = createSelector(
	getSupplierInvoicesById,
	byId => {
		const invoices = values(byId);
		for (let i = 0; i < invoices.length; i++) {
			const invoice = invoices[i];
			const { docNumber = '', docType = '' } =
				invoice.transactionsDetails[0] || {};
			const glNumberOfSplitInvoice = `${docType}${docNumber}`;

			// BE does not return split as seperate supplier invoice,
			// so we have to add it manually
			if (getHasInvoiceSplitRequest(invoice)) {
				invoices.splice(++i, 0, {
					...invoice,
					split: true,
					supplierCompany: {
						name: 'Split invoice'
					} as Entity,
					glNumber: glNumberOfSplitInvoice
				});
			}
		}
		return invoices;
	}
);

export const getSupplierInvoicesCount = createSelector(
	getSupplierInvoicesSelector,
	supplierInvoices => supplierInvoices.length
);

export const getSupplierInvoicesWithoutSplitCount = createSelector(
	getSupplierInvoicesById,
	byId => values(byId).length
);

export const getSupplierInvoiceContextualization = ({
	supplierInvoice
}: AppState) => supplierInvoice.contextualization;

export const getSupplierInvoiceContextualizationPortJobs = createSelector(
	getSupplierInvoiceContextualization,
	({ portJobs }) => portJobs
);

/**
 * Get a list of all the Services inside PortJobs related to Supplier Invoice
 */
export const getSupplierInvoicePortJobServices = createSelector(
	getSupplierInvoiceContextualizationPortJobs,
	portJobs => flatten(portJobs.map(job => job.services))
);

export const getSupplierInvoicePortJobServicesById = createSelector(
	getSupplierInvoicePortJobServices,
	services => keyBy(services, ({ id }) => id)
);

export const calculateTotalAmountOfJobServices = (
	services: PortJobServiceFormData[]
): number =>
	services.reduce(
		(servicesTotal, { totalAmount, isSelected }) =>
			servicesTotal + (isSelected && totalAmount ? Number(totalAmount) : 0),
		0
	);

export const getIsInvoiceDetailsFormSectionVisible = createSelector(
	getContextualizationFormValues,
	({ mainPrincipalCompany, supplierCompany, currencyCode }) =>
		!!(
			mainPrincipalCompany?.key &&
			supplierCompany &&
			supplierCompany.key &&
			currencyCode
		)
);

const getIsSupplierInvoiceStatusById = (
	{ supplierInvoice }: AppState,
	id: string
) => supplierInvoice.fetchStatuses.downloadById[id];

export const getIsSupplierInvoiceDownloadingById = createSelector(
	getIsSupplierInvoiceStatusById,
	status => getIsLoading(status)
);

export const supplierInvoiceContextualizationJobSearch = createSelector(
	getSupplierInvoiceContextualization,
	({ jobSearch }) => jobSearch
);

export const getContextualizationFoundJob = createSelector(
	supplierInvoiceContextualizationJobSearch,
	({ job }) => job
);

export const getIsContextualizationJobSearchInProgress = createSelector(
	getSupplierInvoiceFetchStatuses,
	({ contextualizationJobSearch }) => getIsLoading(contextualizationJobSearch)
);

export const getIsGoToJobBtnDisabled = createSelector(
	getContextualizationFoundJob,
	getIsContextualizationJobSearchInProgress,
	(job, jobSearchInProgress) => !job || jobSearchInProgress
);

export const getIsJobNotFound = createSelector(
	supplierInvoiceContextualizationJobSearch,
	getContextualizationFoundJob,
	({ hasSearchBeenPerformed }, job) => hasSearchBeenPerformed && !job
);

const addSupplierInvoiceFormSelector = formValueSelector(
	SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID
);

export const getAddSupplierInvoiceCurrencyDecimalPlaces = createSelector(
	(state: AppState) => state,
	(state: AppState) =>
		addSupplierInvoiceFormSelector(state, FormField.CURRENCY_CODE),
	(state, supplierInvoiceCurrency: Partial<LabeledValue>) =>
		getFinanceCurrencyDecimalPart(
			state,
			supplierInvoiceCurrency?.key,
			DEFAULT_DECIMAL_PLACES
		)
);

export const getServicesControlTotal = createSelector(
	getPortJobsFormValues,
	getAddSupplierInvoiceCurrencyDecimalPlaces,
	(portJobs, supplierInvoiceCurrencyDecimalPlaces) =>
		portJobs
			? getCustomFormattedValue(
					portJobs.reduce(
						(jobsTotal, { services }) =>
							jobsTotal + calculateTotalAmountOfJobServices(services),
						0
					),
					supplierInvoiceCurrencyDecimalPlaces
			  )
			: '0'
);

export const getIsSupplierInvoiceContextualization = ({
	supplierInvoice
}: AppState) => supplierInvoice.context.isSupplierInvoiceContextualization;

export const getRetrieveSplitSupplierInvoiceDocumentFetchStatus = createSelector(
	getSupplierInvoiceFetchStatuses,
	fetchStatus => fetchStatus.retrieveSplitSupplierInvoiceServiceDocument
);

export const getSplitSupplierInvoiceServiceDocument = ({
	supplierInvoice
}: AppState) => supplierInvoice.splitSupplierInvoiceServiceDocument;

export const getSupplierInvoiceDocumentPagesLen = (state: AppState) => {
	const document = getSplitSupplierInvoiceServiceDocument(state);
	return document?.pages.length;
};

export const getIsSupplierDocumentPageDeleteLoading = (
	state: AppState,
	documentId: string,
	actualNumber: number,
	pageType: string
) => {
	const fetchStatusesById =
		state.supplierInvoice.fetchStatuses.deleteSupplierDocumentPageById[
			documentId
		];
	return getIsLoading(fetchStatusesById?.[actualNumber]?.[pageType]);
};
