import { createSelector } from 'reselect';
import { get, forIn, isString } from 'lodash';
import { AppState } from 'store-types';
import { getFormValues } from 'redux-form';
import {
	EDIT_GROUP_FORM_NAME,
	FORMS,
	PermissionScopeTabsKeys
} from 'sections/Groups/Group/EditGroup/constants';
import { UpdateGroupActionPayload } from '../actions/updateGroup';
import {
	Group,
	DataPermission,
	GroupPermissionScopeAccessTypes,
	PermissionScopeItem,
	DataPermissionChild,
	GroupPermissionScopeTypes,
	UserCompanyGetResponse
} from 'services/api/groups/groupsServiceTypes';
import { getScopeType } from 'store/groups/groupsPermissionScopeSelectors';
import { getGroupById } from 'store/groups/groupsSelectors';

// eslint-disable-next-line
const shouldUpdateScopePermissions = (formValues: any): boolean => {
	return (
		formValues &&
		(get(
			formValues,
			[PermissionScopeTabsKeys.VESSELS_TAB_KEY, 'values'],
			false
		) ||
			get(
				formValues,
				[PermissionScopeTabsKeys.REGIONS_TAB_KEY, 'values'],
				false
			) ||
			get(
				formValues,
				[PermissionScopeTabsKeys.COMPANIES_TAB_KEY, 'values'],
				false
			))
	);
};

const buildScopePermissionsForRequest = (item: PermissionScopeItem) => {
	const resultPermission: DataPermissionChild = item;
	if (
		(item.entityType === GroupPermissionScopeTypes.VESSEL_TYPE &&
			resultPermission.includesAllChildren !== false) ||
		item.entityType === GroupPermissionScopeTypes.COUNTRY_TYPE
	) {
		resultPermission.includesAllChildren = true;
	}

	return resultPermission;
};

const getDataPermissionsForRequest = (
	// eslint-disable-next-line
	permissionScope: any,
	group: Group
) => {
	const dataPermissions: DataPermission[] = [];

	forIn(
		permissionScope,
		(
			permissionScopeTab: {
				permissionScopeType: string;
				values: PermissionScopeItem[];
			},
			tabKey: string
		) => {
			const accessType =
				permissionScopeTab.permissionScopeType ||
				GroupPermissionScopeAccessTypes.SPECIFIC;
			const scopeTypeToSave = getScopeType(tabKey, group.applicableScopeTypes);

			if (accessType === GroupPermissionScopeAccessTypes.SPECIFIC) {
				const permissions = permissionScopeTab.values
					? permissionScopeTab.values.map(buildScopePermissionsForRequest)
					: [];
				dataPermissions.push({
					scopeType: scopeTypeToSave,
					permissions,
					accessType
				});
			} else if (accessType === GroupPermissionScopeAccessTypes.ALL) {
				dataPermissions.push({
					scopeType: scopeTypeToSave,
					accessType
				});
			}
		}
	);
	return dataPermissions;
};

export interface GeneralInfoForm {
	name: string;
	groupType: string;
	company: UserCompanyGetResponse;
	isMessagingGroup: boolean;
	groupSignature: string | null;
	userSignature: string | null;
}

export const getGroupEditRequest = createSelector(
	(state: AppState) => getFormValues(EDIT_GROUP_FORM_NAME)(state),
	(state: AppState, groupId: string) => getGroupById(state, groupId),
	(_state: AppState, _groupId: string, scopeType?: string) => scopeType,
	(formValues, group: Group, scopeType?: string): UpdateGroupActionPayload => {
		const general: GeneralInfoForm = formValues[FORMS.GeneralInfoForm];
		const generalInState = formValues[FORMS.GeneralInfoForm]; // TODO: Should be implemented
		const permission = formValues[FORMS.PermissionsForm];
		const permissionScope = formValues[FORMS.PermissionScopeForm];
		const functionalPermissions =
			permission && Object.keys(permission).filter(key => permission[key]); // only checked with true value

		const groupId = group.id;
		const name = general?.name || group.name;
		const signature = isString(generalInState.groupSignature)
			? generalInState.groupSignature
			: '';

		const isMessagingGroup = general?.isMessagingGroup;
		const groupType = group.groupType.code;

		let dataPermissions: DataPermission[] = [];

		// Permission Scope Tab Data
		if (shouldUpdateScopePermissions(permissionScope)) {
			dataPermissions = getDataPermissionsForRequest(permissionScope, group);
		}
		return {
			groupId,
			name,
			signature,
			isMessagingGroup,
			groupType,
			functionalPermissions,
			dataPermissions,
			scopeType
		};
	}
);
