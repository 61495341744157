import {
	getActivePortJob,
	getActivePortJobCompanies
} from '../portJobsSelectors';
import { createSelector } from 'reselect';
import { Commodity } from 'services/api/commodities/commoditiesServiceTypes';
import {
	getAllCompaniesSelector,
	getCompaniesFetchStatuses
} from 'store/companies/selectors/companiesSelectors';
import { CompanyDraftResponse } from 'services/api/portJobs/portJobsServiceTypes';

const getActivePortJobPerformingAgent = createSelector(
	getActivePortJob,
	portJob => portJob?.performingAgentCompany
);

const getActivePortJobControllingAgent = createSelector(
	getActivePortJob,
	portJob =>
		portJob?.controllingAgentCompany
			? portJob.controllingAgentCompany
			: ({} as Commodity)
);

export const getAgentsCompaniesForActivePortJob = createSelector(
	getActivePortJobPerformingAgent,
	getActivePortJobControllingAgent,
	getActivePortJobCompanies,
	(performing, controlling, companies) => {
		return {
			performing:
				companies.find(company => performing.id === company.id) ||
				({} as CompanyDraftResponse),
			controlling:
				companies.find(company => controlling.id === company.id) ||
				({} as CompanyDraftResponse)
		};
	}
);

export const getActivePortJobPerformingAgentCompany = createSelector(
	getAgentsCompaniesForActivePortJob,
	({ performing }) => performing
);

const getActivePortJobControllingAgentCompany = createSelector(
	getAgentsCompaniesForActivePortJob,
	({ controlling }) => controlling
);

export const getIsPortJobPerformingAgentDraft = createSelector(
	getAgentsCompaniesForActivePortJob,
	({ performing }) => performing?.isDraft
);

export const getIsPortJobControllingAgentDraft = createSelector(
	getAgentsCompaniesForActivePortJob,
	({ controlling }) => controlling?.isDraft
);

export const getActivePortJobAgentsCompanyDetails = createSelector(
	getActivePortJobPerformingAgentCompany,
	getActivePortJobControllingAgentCompany,
	getAllCompaniesSelector,
	(performing, controlling, companies) => ({
		performing: companies.find(company => company.id === performing.companyId),
		controlling: companies.find(
			company => !!controlling && company.id === controlling.companyId
		)
	})
);

export const getActivePortJobAgentsCompanyFetchStatuses = createSelector(
	getActivePortJobPerformingAgentCompany,
	getActivePortJobControllingAgentCompany,
	getCompaniesFetchStatuses,
	(performing, controlling, fetchStatuses) => {
		const result = [];
		if (fetchStatuses[performing.companyId]) {
			result.push(fetchStatuses[performing.companyId]);
		}
		if (controlling && fetchStatuses[controlling.companyId]) {
			result.push(fetchStatuses[controlling.companyId]);
		}

		return result;
	}
);
