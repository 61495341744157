import FontFaceObserver from 'fontfaceobserver';

function loadStyleSheet(src: string) {
	if (typeof document.createStyleSheet === 'function') {
		document.createStyleSheet(src);
	} else {
		const stylesheet = document.createElement('link');
		stylesheet.href = src;
		stylesheet.rel = 'stylesheet';
		stylesheet.type = 'text/css';
		document.getElementsByTagName('head')[0].appendChild(stylesheet);
	}
}

export default () => {
	// When Open Sans is loaded, add a font-family using Open Sans to the body
	const observer = new FontFaceObserver('Open Sans');
	observer.load().then(
		() => {
			if (document.body) {
				document.body.classList.add('loaded');
			}
		},
		() => {
			if (document.body) {
				document.body.classList.remove('loaded');
			}
		}
	);

	loadStyleSheet(
		'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700'
	);
};
