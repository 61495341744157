import { Entity, EntityCode } from 'app-types';
import {
	SearchResultCollection,
	PagedRequest,
	ResultCollection
} from 'services/api/apiTypes';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { FilterItem } from 'store/filters/filtersState';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import { CompanyDraftPlain, NewCompanyDraft } from 'store/drafts/draftsState';

export interface DirectBillsFilters {
	[id: string]: FilterItem[];
}

export enum AgreementMakerCompanyOrganisationTypeCode {
	ISS = OrganisationType.ISS,
	PRINCIPAL = OrganisationType.PRINCIPAL
}

export enum DirectBillTargetType {
	ISS = 'ISS',
	LPA = 'LPA',
	PRINCIPAL = 'Principal'
}

export enum DirectBillDiscountType {
	PERCENTAGE = 'Percentage',
	ABSOLUTE = 'Absolute'
}

export interface DirectBill {
	id: string;
	mainPrincipalCompany: Entity;
	vendorCompany: Entity;
	agreementMakerCompanyOrganisationTypeCode: string;
	discountValue?: number;
	discountTypeCode: DirectBillDiscountType;
	services: EntityCode[];
	principals: Entity[];
	ports: Entity[];
	countries: EntityCode[];
	isActive: boolean;
	appointmentInstruction: string;
	notes: string;
}

export interface RetrieveDirectBillsRequest extends PagedRequest {
	limit: number;
	count: number;
	filters: DirectBillsFilters;
}

export interface RetrieveDirectBillsResponse {
	count: number;
	elements: DirectBill[];
}

export interface SearchPrincipalsRequest {
	searchTerm: string;
	mainPrincipalId: string;
}

export interface DirectBillDocument {
	id: string;
	documentId: string;
	name: string;
	createdByUserName: string;
	createdOnUtc: string;
}
export interface FullDirectBill {
	id: string;
	mainPrincipalCompany: Entity;
	vendorCompany: Entity;
	validFrom: string;
	validTo: string;
	targetTypeCode: DirectBillTargetType;
	agreementMakerCompanyOrganisationTypeCode: AgreementMakerCompanyOrganisationTypeCode;
	discountTypeCode: DirectBillDiscountType;
	isActive: boolean;
	discountValue?: number;
	creditDays: number;
	appointmentInstruction: string;
	notes: string;
	services: EntityCode[];
	principals: Entity[];
	countries?: EntityCode[];
	ports?: Entity[];
	documents: DirectBillDocument[];
	draftVendorCompanies: CompanyDraftPlain[];
}

export interface UpdateDirectBillRequest {
	mainPrincipalCompany: { id: string };
	vendorCompany: { id: string };
	validFrom: string;
	validTo: string;
	targetTypeCode: DirectBillTargetType;
	agreementMakerCompanyOrganisationTypeCode: AgreementMakerCompanyOrganisationTypeCode;
	draftVendorCompanies: NewCompanyDraft[];
	discountTypeCode: DirectBillDiscountType;
	discountValue?: number;
	creditDays: number;
	appointmentInstruction: string;
	notes: string;
	/* eslint-disable @typescript-eslint/no-explicit-any */
	services: any[]; // Array<{id: string}> replace when typescript is updated;
	principals: any[]; // Array<{id: string}> replace when typescript is updated
	// Either Countries or Ports should be specified exlusively
	countries?: any[]; // Array<{ code: string }>; replace when typescript is updated
	ports?: any[]; // Array<{id: string}> replace when typescript is updated
	documents: any[];
	/* eslint-enable */
}

export interface UpdateDirectBillStatusRequest {
	id: string;
	status: string;
}

export interface UpdateDirectBillStatusPayload {
	status: string;
}

export interface UpdateDirectBillResponse {
	id: string;
	mainPrincipalCompany: {
		name: string;
		id: string;
	};
	vendorCompany: {
		name: string;
		id: string;
	};
	validFrom: string;
	validTo: string;
	targetTypeCode: DirectBillTargetType;
	agreementMakerCompanyOrganisationTypeCode: AgreementMakerCompanyOrganisationTypeCode;
	discountTypeCode: DirectBillDiscountType;
	discountValue?: number;
	isActive: boolean;
	services: EntityCode[];
	principals: Entity[];
	creditDays: number;
	appointmentInstruction: string;
	notes: string;
	// Either Countries or Ports should be specified exlusively
	countries?: EntityCode[];
	ports?: Entity[];
	documents: DirectBillDocument[];
	draftVendorCompanies: CompanyDraftPlain[];
}

export type UpdateDirectBillStatusResponse = UpdateDirectBillResponse;

export enum DirectBillGroupType {
	VENDORS = 'Vendors',
	SERVICES = 'Services',
	PRINCIPALS = 'Principals',
	COUNTRIES = 'Countries'
}

export enum DirectBillCompanyType {
	PRINCIPAL = 'Principal',
	VENDOR = 'Vendor'
}

enum DirectBillPrincipalType {
	CLS = 'CLS',
	COM = 'COM'
}

export interface DirectBillSearchRequest {
	searchTerm: string;
	groupType?: DirectBillGroupType;
	showMore?: boolean;
}

interface DirectBillSearchResultItem {
	id: string;
	name: string;
	code?: string;
	codeCountry?: string;
}

export interface SearchPrincipalRequest {
	mainPrincipalId: string;
	searchTerm: string;
}

export type DirectBillSearchResultCollection = SearchResultCollection<
	DirectBillGroupType,
	DirectBillSearchResultItem
>;

export interface DownloadDirectBillsDocumentRequest {
	directBillId?: string;
	meta?: {
		downloadAll?: boolean;
	};
}

interface MainPrincipalOrganization {
	companyId: string;
	companyName: string;
	companyOrganisationTypeId: string;
	isReadyToGo: boolean;
	mainPrincipalType: DirectBillPrincipalType;
	organisationTypeCOde: string;
	organisationTypeName: string;
}

export interface MainPrincipal extends Entity {
	code: string;
	companyEmail: string;
	companyName: string;
	reatedOn: string;
	goldenRecordId: string;
	id: string;
	isActive: boolean;
	isDeleted: boolean;
	isReadyToGo: boolean;
	mainPrincipalType: {
		code: DirectBillPrincipalType;
		name: string;
	};
	organisationTypes: MainPrincipalOrganization[];
}

export interface EditDirectBillProps {
	directBillId: string;
}
export type RetrieveMainPrincipalsResponse = ResultCollection<MainPrincipal>;
export type RetrieveServicesResponse = ResultCollection<AutocompleteOption>;

export interface RetrieveDirectBillRequest {
	directBillId: string;
}

export interface RetrieveDirectBillResponse extends FullDirectBill {}
