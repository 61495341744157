import { FetchStatus } from 'services/api/apiTypes';
import { MessagesState } from '../messagesState';
import { updateContact, updateContactAsync } from '../actions';

function onUpdateContactStarted(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}

function onUpdateContactSuccess(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
}

function onUpdateContactFailed(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}

export const updateContactAction = {
	action: updateContact,
	async: updateContactAsync,
	started: onUpdateContactStarted,
	success: onUpdateContactSuccess,
	failed: onUpdateContactFailed
};
