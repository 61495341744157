import React from 'react';
import { Button, Modal } from 'components/antd';
import styles from './AcceptPortJobModal.module.scss';
import { connect } from 'react-redux';
import { isModalVisible } from 'store/modals/selectors';
import {
	getIsPortJobAcceptanceDataEmpty,
	getIsActivePortJobCancelled
} from 'store/portJobs/portJobsSelectors';
import { closeModal, openModal } from 'store/modals/actions';
import { acceptPortJob } from 'store/portJobs/actions/acceptPortJob';
import { CommonActionModalProps } from '../ActionModalTypes';
import AcceptPortJobModalContent from './AcceptPortJobModalContent';
import AcceptPortJobModalFooter from './AcceptPortJobModalFooter';
import { AppState } from 'store-types';

interface AcceptPortJobModalProps extends CommonActionModalProps {
	// from mapStateToProps
	visible: boolean;
	isAcceptanceDataEmpty: boolean;
	disabled: boolean;
	// from mapDispatchToProps
	openModal: typeof openModal;
	closeModal: typeof closeModal;
	acceptPortJob: typeof acceptPortJob;
}

interface AcceptPortJobModalState {
	isCheckboxChecked: boolean;
}

export class AcceptPortJobModal extends React.PureComponent<
	AcceptPortJobModalProps,
	AcceptPortJobModalState
> {
	constructor(props: AcceptPortJobModalProps) {
		super(props);
		this.state = {
			isCheckboxChecked: false
		};
	}

	toggleCheckbox = () => {
		this.setState({ isCheckboxChecked: !this.state.isCheckboxChecked });
	};

	openModal = () => this.props.openModal({ name: this.props.modalId });

	closeModal = () => {
		this.props.closeModal({ name: this.props.modalId });
		this.setState({ isCheckboxChecked: false });
	};

	acceptJob = () => {
		const { portCallId, jobCode, modalId } = this.props;
		this.props.acceptPortJob({
			portCallId,
			jobCode,
			modalId
		});
	};

	render() {
		const { isCheckboxChecked } = this.state;
		const { isAcceptanceDataEmpty, disabled } = this.props;
		const modalWidth = isAcceptanceDataEmpty ? 360 : 650;
		return (
			<>
				<Button type="primary" onClick={this.openModal} disabled={disabled}>
					Accept Job
				</Button>
				<Modal
					title="Accept Job"
					className={styles.acceptJobModal}
					visible={this.props.visible}
					onCancel={this.closeModal}
					width={modalWidth}
					footer={
						<AcceptPortJobModalFooter
							isAcceptanceDataEmpty={isAcceptanceDataEmpty}
							isCheckboxChecked={isCheckboxChecked}
							toggleCheckbox={this.toggleCheckbox}
							closeModal={this.closeModal}
							acceptJob={this.acceptJob}
						/>
					}
				>
					<AcceptPortJobModalContent />
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<AcceptPortJobModalProps, 'modalId'>) => ({
		visible: isModalVisible(state, ownProps.modalId),
		isAcceptanceDataEmpty: getIsPortJobAcceptanceDataEmpty(state),
		disabled: getIsActivePortJobCancelled(state)
	}),
	{
		openModal,
		closeModal,
		acceptPortJob
	}
)(AcceptPortJobModal);
