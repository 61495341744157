import {
	EntityPermissionRequest,
	EntityPermissionResponse
} from 'services/api/permissions/permissionsServiceTypes';
import actionCreator from '../permissionsActionCreator';

const ACTION_NAME = 'RETRIEVE_ENTITY_PORTCALL';
export const retrieveEntityPermissionsForPortCallAsync = actionCreator.async<
	EntityPermissionRequest,
	EntityPermissionResponse,
	Error
>(ACTION_NAME);

export const retrieveEntityPermissionsForPortCall = actionCreator<
	EntityPermissionRequest
>(ACTION_NAME);
