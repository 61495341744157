import * as React from 'react';

import ReactDOM from 'react-dom';
import moment from 'moment-timezone';
import AntDatePicker from 'antd/lib/date-picker';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { Field, Validator } from 'redux-form';
import { Form, Input, Icon } from 'components/antd';
import styles from './DatePickerExtended.module.scss';
import MaybeElement from 'utils/MaybeElement';
import { isNull, isString, isNil } from 'lodash';
import classNames from 'classnames';
import { AppState } from 'store-types';
import {
	getUserFullDateFormat,
	getUserDateFormat,
	getPlaceholderByFormat
} from 'store/auth/selectors';
import { connect } from 'react-redux';
import ReduxFormWrapper from './DatePickerExtendedReduxFormWrapper';
import { getUTCDateFormattedToLocal } from 'utils/formatDate';
import { ISO_DATE_FORMAT } from 'app-constants';
import { isDateValid as getDateValidationError } from 'utils/validations';

// for avoiding null and undefined checking
export interface DatePickerExtendedProps {
	value: string | null;
	format: string;
	showTime: boolean;
	renderOutside?: boolean;
	onChange: (value: string | null) => void;
	onFocus?: () => void;
	antDatePicker: DatePickerProps;
	placeholder?: string;
	prefix?: React.ReactNode;
	invalidInput?: boolean;
	validate?: Validator | Validator[];
	onOpenChange?: (open?: boolean) => void;
	disabled?: boolean;
	local?: boolean;
	localReturnFormat?: string;
	shouldShowFormItemErrorMessage?: boolean;
	// custom timezone (i.e. show time related to port local time, instead of user machine time)
	timeZone?: string;
}

// REDUX FORM WRAPPER
interface DatePickerExtendedReduxFormItemProps
	extends Partial<DatePickerExtendedProps> {
	name: string;
	required?: boolean;
	label?: string;
	help?: boolean;
}

interface DatePickerExtendedState {
	value: moment.Moment | null;
	inputValue: string | null;
	invalidInput?: boolean;
}

const DATE_FORMAT = 'MM/DD/YYYY, HH:mm';
const TIME_FORMAT = 'HH:mm';

class DatePicker extends React.Component<
	DatePickerExtendedProps,
	DatePickerExtendedState
> {
	input: MaybeElement;
	datePickerInput: MaybeElement;
	selectTimeSwitcher: MaybeElement;

	static defaultProps = {
		value: null,
		format: DATE_FORMAT,
		showTime: true,
		onChange: () => {
			/* if function will not be passed by props then just do nothing */
		},
		antDatePicker: {},
		disabled: false,
		localReturnFormat: ISO_DATE_FORMAT
	};

	constructor(props: DatePickerExtendedProps) {
		super(props);
		moment.tz.setDefault(this.props.timeZone);
		const value = props.value ? getUTCDateFormattedToLocal(props.value) : null;
		this.state = {
			value: value ? moment(value) : null,
			inputValue: value && moment(value).format(this.props.format),
			invalidInput: props.invalidInput || false
		};
	}

	componentDidMount(): void {
		this.datePickerInput = MaybeElement.of(
			(ReactDOM.findDOMNode(this) as Element).querySelector(
				'.ant-calendar-picker-input.ant-input'
			)
		);
		// datepicker Input mustn't be focused
		this.datePickerInput.do(input => {
			input.setAttribute('tabindex', '-1');
		});

		this.input.do(input => {
			input.addEventListener('paste', e => this.onPasteIntoInput(e));
		});
	}

	componentDidUpdate(prevProps: DatePickerExtendedProps) {
		const { value, format } = this.props;
		if (value !== prevProps.value) {
			const formattedValue = value ? moment(value).format(format) : null;
			const isDateValid =
				formattedValue && moment(formattedValue, format).isValid();
			const inputValue =
				value !== null && isDateValid ? formattedValue : value || '';
			this.setState({
				inputValue,
				invalidInput: !isDateValid && !isNil(value)
			});
		}
	}

	// interface change
	private getProps(): DatePickerExtendedProps {
		return this.props as DatePickerExtendedProps;
	}

	private setInputElement(input: Input | null): void {
		this.input = MaybeElement.of(
			input && (ReactDOM.findDOMNode(input) as Element)
		);
	}

	private updateState(value: string | null): void {
		const isDateValid = Boolean(
			value && moment(value, this.props.format).isValid()
		);
		const date = value ? moment(value, this.props.format) : undefined;
		// for BE team
		if (isDateValid && date) {
			date.seconds(0).milliseconds(0);
		}
		const validationResult = getDateValidationError(value, this.props.format);
		let newValue = validationResult || this.state.inputValue;

		this.setState({
			value: (isDateValid && date) || null,
			inputValue:
				isDateValid && date ? date.format(this.props.format) : newValue
		});

		if (isDateValid && date) {
			newValue = this.props.local
				? date.format(this.props.localReturnFormat)
				: date.toISOString();
		}
		this.getProps().onChange(newValue);
		if (isNull(value)) {
			// ensure to clear input in the picker - picker doesn't have clear property or null possibility
			MaybeElement.of(
				(ReactDOM.findDOMNode(this) as Element).querySelector(
					'i.ant-calendar-picker-clear'
				)
			).do(clearButton => {
				setTimeout(() => clearButton.click()); // call with delay
			});
		}
	}

	private onPasteIntoPicker(e: ClipboardEvent): void {
		e.preventDefault();
		const clipboardData = this.getClipboardDataObject(e);
		if (!clipboardData || !clipboardData.getData) {
			return;
		}
		const dateValue = clipboardData.getData(
			e.clipboardData === undefined ? 'Text' : 'text'
		);
		const date = moment(dateValue, this.props.format);
		if (!date.isValid()) {
			return;
		}
		this.updateState(dateValue);

		MaybeElement.of(
			document.querySelector('a.ant-calendar-ok-btn')
		).do(button => button.click()); // hide the popup by triggering the click
	}

	private onPasteIntoInput(e: ClipboardEvent): void {
		e.preventDefault();
		const clipboardData = this.getClipboardDataObject(e);
		if (!clipboardData || !clipboardData.getData) {
			return;
		}
		const data = clipboardData.getData(
			e.clipboardData === undefined ? 'Text' : 'text'
		);
		this.updateState(data);
	}

	/**
	 * IE doesn't have a paste object in the ClipboardEvent
	 * That's why we have to check it in window
	 */
	private getClipboardDataObject = (e: ClipboardEvent) =>
		e.clipboardData ||
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).clipboardData;

	private onOpenPicker(): void {
		// timeout for execution in next loop, without it is too early to query elements, due to the fact they are created in the fly
		setTimeout(() => {
			MaybeElement.of(
				document.querySelector('input.ant-calendar-input')
			).do(input =>
				input.addEventListener('paste', e => this.onPasteIntoPicker(e))
			);
		});
	}

	// openTime prop for opening picker with time screen inside
	private triggerOpenPicker(openTime?: boolean): void {
		if (this.props.disabled) {
			return;
		}
		this.updateState(this.state.inputValue);
		// open the picker
		this.datePickerInput.do(input => input.click());
		if (openTime) {
			setTimeout(() => {
				MaybeElement.of(
					document.querySelector('a.ant-calendar-time-picker-btn')
				).do(button => button.click());
			});
		}
	}

	private onInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({ ...this.state, inputValue: e.target.value });
	}

	private onInputChangeFinished(): void {
		this.updateState(this.state.inputValue);
	}

	private onOpenChange = (open: boolean) => {
		this.onFocus();
		if (open) {
			this.onOpenPicker();
		}
		if (this.props.onOpenChange) {
			this.props.onOpenChange(open);
		}
	};

	private onFocus = () => {
		if (this.props.onFocus) {
			this.props.onFocus();
		}
	};

	private renderSuffix = () => (
		<>
			<Icon onClick={() => this.triggerOpenPicker()} type="calendar" />
			{this.props.showTime && (
				<Icon
					onClick={() => this.triggerOpenPicker(true)}
					type="clock-circle-o"
				/>
			)}
		</>
	);

	render() {
		const {
			showTime,
			antDatePicker,
			format,
			placeholder,
			disabled,
			prefix,
			shouldShowFormItemErrorMessage,
			renderOutside
		} = this.getProps();

		const { inputValue, value } = this.state;
		const { invalidInput } = this.state;
		const optionalProps = !isNull(value) ? { value } : {}; // hack for optional parameter
		const use12Hours = format.includes('A');
		const currentDate = moment();
		const showTimeParams = showTime && {
			format: TIME_FORMAT,
			use12Hours,
			defaultValue: currentDate
		};

		return (
			<Form.Item
				className={classNames(styles.datePickerExtended, {
					'has-error': invalidInput
				})}
				help={
					shouldShowFormItemErrorMessage &&
					invalidInput &&
					this.state.inputValue // it's error message in this case
				}
			>
				<Input
					ref={ref => this.setInputElement(ref)}
					placeholder={placeholder || format}
					value={
						// TS upgrade: <Input /> doesn't expect null
						isString(inputValue) ? inputValue : undefined
					}
					onFocus={this.onFocus}
					onBlur={() => this.onInputChangeFinished()}
					onChange={e => this.onInputChange(e)}
					onPressEnter={() => this.onInputChangeFinished()}
					prefix={prefix}
					suffix={this.renderSuffix()}
					disabled={disabled}
				/>
				<AntDatePicker
					dropdownClassName={styles.datePickerExtendedPopup}
					showTime={showTimeParams}
					defaultPickerValue={currentDate}
					format={format}
					placeholder={placeholder || format}
					getCalendarContainer={triggerNode =>
						renderOutside
							? document.body
							: triggerNode.parentElement || document.body
					}
					{...antDatePicker}
					onOpenChange={this.onOpenChange}
					onChange={date => this.updateState(date ? date.format(format) : date)}
					{...optionalProps}
					suffixIcon={null}
				/>
			</Form.Item>
		);
	}
}

export const DatePickerExtended = connect(
	(
		state: AppState,
		{ showTime = true, placeholder }: DatePickerExtendedProps
	) => ({
		format: showTime ? getUserFullDateFormat(state) : getUserDateFormat(state),
		placeholder: placeholder || getPlaceholderByFormat(state, showTime)
	})
)(DatePicker);

export default DatePickerExtended;

export const DatePickerExtendedFormItem = (
	props: DatePickerExtendedReduxFormItemProps
): React.ReactElement<Field> => {
	return (
		// eslint-disable-next-line
		<Field name={props.name} component={ReduxFormWrapper} {...(props as any)} />
	);
};
