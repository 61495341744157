import {
	retrieveThreadsStatsCycleStart,
	retrieveThreadsStatsCycleStop
} from '../actions/retrieveThreadsStatsCycle';
import { SagaIterator } from 'redux-saga';
import { cancel, fork, take, put, select, delay } from 'redux-saga/effects';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';
import { retrieveThreadsStats, retrieveThreadsStatsAsync } from '../actions';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';

export function* worker(): SagaIterator {
	const userType: UserType = yield select(getUserType);
	if (userType !== UserType.HUB) {
		return; // thread stats available only for Hub user
	}
	yield put(retrieveThreadsStats({ isSilent: false }));
	yield take([
		retrieveThreadsStatsAsync.done.type,
		retrieveThreadsStatsAsync.failed.type
	]);
	// start polling after first call is finished
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield put(retrieveThreadsStats({ isSilent: true }));
	}
}

export default function*() {
	while (yield take(retrieveThreadsStatsCycleStart)) {
		const threadsStatsTask = yield fork(worker);
		yield take(retrieveThreadsStatsCycleStop);
		yield cancel(threadsStatsTask);
	}
}
