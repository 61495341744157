import Api from 'services/api';
import {
	retrieveThreadById as action,
	retrieveThreadByIdAsync as asyncAction
} from 'store/thread/actions/retrieveThreadById';
import { RetrieveThreadByIdParams as request } from 'services/api/threads/threadsServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';

const apiCall = Api.Threads.retrieveThreadById;

export function* executor(params: request): SagaIterator {
	yield put(asyncAction.started(params));
	try {
		const response = yield call(apiCall, params);
		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params
			})
		);
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
