import { call, put, select } from 'redux-saga/effects';
import Api from 'services/api';
import { saveLinkedCargosAsync as async } from 'store/linkJob/actions';
import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { notify } from 'store/notifications/actions';
import { Success } from 'typescript-fsa';
import { navigateTo } from 'utils';
import { getSelectedLinkJobPortCallId } from 'store/portJobs/portJobsSelectors';
import { resetPortCall, setPortCallContext } from 'store/portcalls/actions';

const api = Api.PortCalls.saveLinkedCargoDetails;

const executor = makeDefaultExecutor<
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse,
	Error
>({
	api,
	async,
	*onSuccess({
		params: { portJob }
	}: Success<SaveLinkedCargosDetailsRequest, MergePortCallsResponse>) {
		const portCallId = yield select(getSelectedLinkJobPortCallId);
		yield put(resetPortCall);
		yield put(
			setPortCallContext({
				activePortCallId: portCallId
			})
		);
		yield call(navigateTo, `/portcalls/${portCallId}/jobs/${portJob}/overview`);
		yield put(notify.success('Port Job has been successfully linked.'));
	}
});

export const linkPortJobCargosWatcher = makeTakeLatestWatcher<
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse,
	Error
>(
	{
		api: Api.PortCalls.saveLinkedCargoDetails,
		async
	},
	executor
);
