import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { includes } from 'lodash';

import { UserAuth, UserType } from 'services/api/users/userServiceTypes';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

export const getAuthUser = (state: AppState) =>
	state.auth.authUser || ({} as UserAuth);

export const getActingUser = (state: AppState) => state.auth.actingUser;

export const getAuthUserId = createSelector(getAuthUser, user => user.id);

export const getAuthUserName = createSelector(
	getAuthUser,
	user => user.fullName
);
export const getAuthUserType = createSelector(
	getAuthUser,
	user => user.userType
);

export const getCurrentUser = createSelector(
	getAuthUser,
	getActingUser,
	(authUser, actingUser) => (actingUser?.id ? actingUser : authUser)
);

export const getUserIdSelector = createSelector(
	getCurrentUser,
	user => user.id
);
export const getUserEmail = createSelector(getCurrentUser, user => user.email);
export const getUserType = createSelector(
	getCurrentUser,
	user => user.userType
);
export const getUserGroupType = createSelector(
	getCurrentUser,
	user => user.userGroupType
);

export const getUserHasLpaApplicationAccess = createSelector(
	getCurrentUser,
	user => user.hasLpaApplicationAccess
);

export const getIsCurrentUserLpaUserType = createSelector(
	getUserType,
	userType => userType === UserType.LPA
);

export const getRestrictAccessToOptic = createSelector(
	getCurrentUser,
	user => user.restrictAccessToOptic
);

export const getUserHasQuarterMasterApplicationAccess = createSelector(
	getCurrentUser,
	user => user.hasQuarterMasterApplicationAccess
);

export const getIsCurrentUserQuartermasterUserType = createSelector(
	getUserType,
	userType => userType === UserType.PRINCIPAL
);

export const getIsCurrentUsersGroupTypeISSCluster = createSelector(
	getUserGroupType,
	userGroupType => userGroupType === GroupTypeCodes.ISS_CLUSTER
);

export const getIsCurrentUsersGroupTypeISSHub = createSelector(
	getUserGroupType,
	userGroupType => userGroupType === GroupTypeCodes.ISS_HUB
);

export const getIsCurrentUsersGroupTypeCustomer = createSelector(
	getUserGroupType,
	userGroupType => userGroupType === GroupTypeCodes.CUSTOMER
);

export const isUserInMessagingTeam = (userGroupType: GroupTypeCodes) =>
	includes(
		[
			GroupTypeCodes.ISS_CLUSTER,
			GroupTypeCodes.ISS_MANAGE,
			GroupTypeCodes.ISS_HUB
		],
		userGroupType
	);

export const isUserOneOfType = createSelector(
	(_state: AppState, userTypes: UserType[]) => userTypes,
	getUserType,
	(userTypes, userType) => userTypes.includes(userType)
);

export const getUserCompanyId = createSelector(
	getCurrentUser,
	user => user.companyId
);

export const getUserGroups = createSelector(
	getCurrentUser,
	user => user.groups
);
