import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

interface TdProps {
	name?: string;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	colspan?: number;
	className?: string;
	flex?: boolean;
}

const COMPONENT_CLASS_NAME_PREFIX = 'table-col';

const TD: FC<TdProps> = (
	{ name, xs, sm, md, lg, className, children, flex, colspan },
	context
) => {
	const ComponentTag = useMemo(() => (context.flex || flex ? 'div' : 'td'), [
		context.flex,
		flex
	]);

	// The number of columns you wish eg.: 'col-sm-6'
	const colWidth = useMemo(
		() => ({
			[`${COMPONENT_CLASS_NAME_PREFIX}-xs-${xs}`]: !!xs && Number.isInteger(xs),
			[`${COMPONENT_CLASS_NAME_PREFIX}-sm-${sm}`]: !!sm && Number.isInteger(sm),
			[`${COMPONENT_CLASS_NAME_PREFIX}-md-${md}`]: !!md && Number.isInteger(md),
			[`${COMPONENT_CLASS_NAME_PREFIX}-lg-${lg}`]: !!lg && Number.isInteger(lg)
		}),
		[xs, sm, md, lg]
	);

	const tableColWidth = useMemo(() => {
		if (!context.flex || !flex) {
			if (xs && Number.isInteger(xs)) {
				return (100 / 12) * xs + '%';
			}
			if (sm && Number.isInteger(sm)) {
				return (100 / 12) * sm + '%';
			}
			if (md && Number.isInteger(md)) {
				return (100 / 12) * md + '%';
			}
			if (lg && Number.isInteger(lg)) {
				return (100 / 12) * lg + '%';
			}
		}
		return;
	}, [context.flex, flex, xs, sm, md, lg]);

	return (
		<ComponentTag
			className={classNames(
				COMPONENT_CLASS_NAME_PREFIX,
				{ [`${COMPONENT_CLASS_NAME_PREFIX}-${name}`]: !!name },
				colWidth,
				className
			)}
			style={tableColWidth ? { width: tableColWidth } : undefined}
			colSpan={colspan}
		>
			{children}
		</ComponentTag>
	);
};

export default TD;
