import { call } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	AddPortCallEventRequest,
	AddPortCallEventResponse
} from 'services/api/portCall/portCallServiceTypes';
import { addPortCallEventAsync } from '../actions';
import Api from 'services/api';
import Matomo from 'services/analytics/adapters/Matomo';

const apiCall = Api.PortCall.addPortCallEvent;

export default makeTakeLatestWatcher<
	AddPortCallEventRequest,
	AddPortCallEventResponse,
	Error
>({
	api: apiCall,
	async: addPortCallEventAsync,
	*onSuccess(action) {
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Events.AddEvent',
			name: `Added operation events ${action.result.join(',')}`,
			value: 1
		});
	}
});
