import { UnitsState, UnitByCode } from '../unitsState';
import { Unit } from 'services/api/units/unitsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveUnitsStarted(state: UnitsState): UnitsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.PENDING
		}
	};
}

const getCodes = (units: Unit[]) => {
	return units.map(unit => unit.code);
};

const getByCode = (units: Unit[]): UnitByCode => {
	return units.reduce((byCode, unit) => {
		return {
			...byCode,
			[unit.code]: unit
		};
	}, {});
};

export function onRetrieveUnitsSuccess(
	state: UnitsState,
	action: {
		result: Unit[];
	}
): UnitsState {
	const { result } = action;
	return {
		...state,
		byCode: getByCode(result),
		allCodes: getCodes(result),
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveUnitsFailed(state: UnitsState): UnitsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieve: FetchStatus.FAILURE
		}
	};
}
