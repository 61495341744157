import * as React from 'react';
import { Menu, MaybeTooltip } from 'components/antd';
import { EntityPermission } from 'services/api/permissions/permissionsServiceTypes';
import { connect } from 'react-redux';
import { getSplitOperationIndex } from 'store/portJobs/selectors';
import { AppState } from 'store-types';
import { getCargoMenuColumnId } from '../helpers';

const DeleteCargo = 'delete-cargo';
const EditCargo = 'edit-cargo';

interface CargoSettingsMenuExternalProps
	extends Pick<EntityPermission, 'canDelete'> {
	onUpdate: () => void;
	onDelete: (isParent: boolean) => void;
	cargoId: string;
	chartererCompanyId: string;
	cannotDeleteMessage?: string;
}

interface CargoSettingsMenuProps extends CargoSettingsMenuExternalProps {
	splitIndex: number | null;
}

class CargoSettingsMenu extends React.PureComponent<CargoSettingsMenuProps> {
	onMenuClick = (e: { key: string }) => {
		if (e.key.includes(EditCargo)) {
			this.props.onUpdate();
		}
		if (e.key.includes(DeleteCargo)) {
			this.props.onDelete(this.props.splitIndex === 1);
		}
	};

	render() {
		const { cargoId, chartererCompanyId, canDelete, splitIndex } = this.props;
		let cannotDeleteMessage = `Cargo can't be deleted as it has real documents or recorded events associated to it`;
		if (splitIndex === 1) {
			cannotDeleteMessage = `Cargo can't be deleted as it has real documents or recorded events associated to it or its split cargoes`;
		}
		return (
			<Menu onClick={this.onMenuClick}>
				<Menu.Item key={cargoId + EditCargo}>Edit Cargo</Menu.Item>
				<Menu.Item key={chartererCompanyId + DeleteCargo} disabled={!canDelete}>
					<MaybeTooltip
						placement="left"
						show={!canDelete}
						title={cannotDeleteMessage}
						getPopupContainer={() =>
							document.getElementById(
								getCargoMenuColumnId(cargoId)
							) as HTMLElement
						}
					>
						Delete Cargo
					</MaybeTooltip>
				</Menu.Item>
			</Menu>
		);
	}
}

export default connect(
	(state: AppState, props: CargoSettingsMenuExternalProps) => ({
		splitIndex: getSplitOperationIndex(state, props.cargoId)
	})
)(CargoSettingsMenu);
