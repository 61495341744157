import { AddAppointmentFromNominationActionParams } from 'services/api/portJobs/portJobsServiceTypes';
import {
	addAppointmentJobFromNominationAsync as async,
	setActivePortJobCode
} from '../actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api/portJobs/portJobsService';
import { resetPortCall } from 'store/portcalls/actions';
import { navigateTo } from 'utils';
import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { destroy } from 'store/filters/actions';
import { THREADS_FILTER_NAME } from 'store/threads/filtersSync';

import { refetchPortCall } from './reactToChangesForPortCallOverviewSaga';

const apiCall = Api.addAppointmentFromNomination;

export function* executor(
	actionParams: AddAppointmentFromNominationActionParams,
	api: typeof apiCall
): SagaIterator {
	yield put(async.started(actionParams));
	try {
		const response = yield call(
			api,
			actionParams.portCallId,
			actionParams.jobCode
		);
		const { code } = response.data;
		yield put(
			async.done({
				result: {
					...response.data,
					portCallId: actionParams.portCallId
				},
				params: actionParams,
				response
			})
		);
		yield put(destroy(THREADS_FILTER_NAME));
		// Considering that the wizard and an overview pages look to the same place in the state
		// we need to reset port call before navigating, so that we fetch actual port call data and show correct port job tab
		yield put(resetPortCall());
		yield call(
			navigateTo,
			`/portcalls/${actionParams.portCallId}/jobs/${code}/overview`
		);
		yield put(setActivePortJobCode(code));
		yield call(refetchPortCall);
	} catch (error) {
		yield put(notify.error('Something went wrong. Please try again later'));
	}
}

function* worker({
	payload
}: Action<AddAppointmentFromNominationActionParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(async.type, worker);
}
