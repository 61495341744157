import { createSelector } from 'reselect';
import { isNil } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';
import {
	FundingRequest,
	FundingRequestStatus,
	FundingRequestMetadataKey,
	FundingRequestMetadataActionCode
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { fundingRequestStatusMap } from 'store/financeFundings/financeFundingsConstants';
import {
	getDaInsFromActiveDaSet,
	getFinanceServices
} from 'store/finance/selectors';
import {
	getBankAccountsById,
	getIsLOBDVisible
} from 'store/masterdata/bankAccounts/selectors';
import {
	DisbursementAccount,
	DaType,
	ServiceSectionCode
} from 'services/api/finance/financeServiceTypes';
import { FundingDaItem } from 'store/form/financeFundings/financeFundingTypes';
import { Metadata } from 'app-types';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

const getFinanceFundingsState = (state: AppState) => state.financeFundings;

const getFundingRequestsState = (state: AppState) =>
	getFinanceFundingsState(state).fundingRequests;

const getFundingRequestsMap = (state: AppState) =>
	getFundingRequestsState(state).byId;

const getFundingRequestsAllIds = (state: AppState) =>
	getFundingRequestsState(state).allIds;

export const getIsFundingRequestUpdating = createSelector(
	getFundingRequestsState,
	({ fetchStatuses }) =>
		fetchStatuses.updateFundingRequest === FetchStatus.PENDING ||
		fetchStatuses.createFundingRequest === FetchStatus.PENDING
);

const getFundingRequestsMetadata = createSelector(
	getFundingRequestsState,
	fundingRequestsState => fundingRequestsState.metadata
);

const hasActionInMetadata = (
	metadata: Metadata<FundingRequestMetadataActionCode>,
	action: FundingRequestMetadataActionCode
) => {
	if (!metadata) {
		return false;
	}
	return !!getEntityMetadataAction<FundingRequestMetadataActionCode>(
		metadata.actions,
		action
	);
};

export const hasFundingRequestsAction = createSelector(
	getFundingRequestsMetadata,
	(_state: AppState, action: FundingRequestMetadataActionCode) => action,
	hasActionInMetadata
);

export const getFundingRequests = createSelector(
	getFundingRequestsMap,
	getFundingRequestsAllIds,
	(byId, allIds) => {
		return allIds.map((id: string) => byId[id]).filter(Boolean);
	}
);

export const getFundingRequestDaId = createSelector(
	getFundingRequestsState,
	fundingRequestsState => fundingRequestsState.context.daId
);

export const getActiveFundingRequestId = createSelector(
	getFundingRequestsState,
	fundingRequestsState => fundingRequestsState.context.activeFundingRequestId
);

export const getFundingPageDataFetchStatus = createSelector(
	getFundingRequestsState,
	fundingRequestsState =>
		fundingRequestsState.fetchStatuses.retrieveFundingPageData
);

export const getFundingRequestById = (state: AppState, id: string) =>
	getFundingRequestsMap(state)[id];

const getMetadataByFundingRequestId = (state: AppState, id: string) =>
	getFundingRequestById(state, id).metadata;

const getPropsToEditByFundingRequestId = (state: AppState, id: string) =>
	getMetadataByFundingRequestId(state, id).propertiesToEdit;

export const hasFundingRequestsActionById = createSelector(
	getMetadataByFundingRequestId,
	(_state: AppState, _id, action: FundingRequestMetadataActionCode) => action,
	hasActionInMetadata
);

export const hasPropToEditByFundingRequestId = createSelector(
	getPropsToEditByFundingRequestId,
	(_state: AppState, _id: string, propName: FundingRequestMetadataKey) =>
		propName,
	(propsToEdit, propName) => propsToEdit?.includes(propName)
);

export const getFundingRequestStatusLabel = (status: FundingRequestStatus) =>
	fundingRequestStatusMap[status];

export const getDasForCreatingFunding = createSelector(
	getDaInsFromActiveDaSet,
	getFinanceServices,
	(das, services) => {
		const agencyServices = services.filter(
			service => service.serviceSection.code === ServiceSectionCode.AGENCY
		);
		return das
			.filter(da => da.daType === DaType.IN)
			.sort((a, b) => a.number - b.number)
			.map((da: DisbursementAccount) => {
				const agencyServicesAmount = agencyServices.reduce((acc, service) => {
					if (da.services.includes(service.id)) {
						acc += Number(service.amountInDaIn);
					}
					return acc;
				}, 0);
				let nonPositiveAmount = false;
				if (!isNil(da.totalAmount)) {
					nonPositiveAmount =
						da.totalAmount > 0 || da.totalAmount - agencyServicesAmount > 0;
				}
				return {
					id: da.id,
					name: da.label,
					disabled: !nonPositiveAmount || !da.bankDetail,
					noBankDetail: !da.bankDetail
				} as FundingDaItem;
			});
	}
);

export const getBankAccountByFundingRequestId = createSelector(
	getFundingRequestById,
	getBankAccountsById,
	(funding: FundingRequest, bankDetailsMap) =>
		bankDetailsMap[funding.daInBankDetailId]
);

export const getIsLOBDVisibleOnFundingRequest = createSelector(
	getIsLOBDVisible,
	getFundingRequestById,
	(visible, funding) =>
		visible &&
		[
			FundingRequestStatus.PENDING_REVIEW,
			FundingRequestStatus.REVIEWED
		].includes(funding.status)
);
