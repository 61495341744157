import { FormErrors } from 'redux-form';
import { TotalAmountFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import runValidations, { required, minValue } from 'utils/validations';
import { first } from 'lodash';

export default function validate(
	values: TotalAmountFormData
): FormErrors<TotalAmountFormData> {
	return {
		amount: first(runValidations(values.amount, [minValue(0), required])),
		currencyCode: first(runValidations(values.currencyCode, [required]))
	};
}
