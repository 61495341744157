import { Action } from 'typescript-fsa';
import { retrieveDirectBillsAsync } from '../actions';
import { call, select, takeLatest } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import {
	RetrieveDirectBillsResponse,
	RetrieveDirectBillsRequest
} from 'services/api/directBills/directBillsServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { getDirectBillsCount, getDirectBillsFilters } from '../selectors';
import { DIRECT_BILLS_FILTER_NAME } from '../filtersSync';

const api = Api.DirectBills.retrieveDirectBills;
const executor = makeDefaultExecutor<
	RetrieveDirectBillsRequest,
	RetrieveDirectBillsResponse,
	Error
>({
	api,
	async: retrieveDirectBillsAsync
});

export const worker = function*({ meta }: Action<RetrieveDirectBillsRequest>) {
	const filters = yield select(getDirectBillsFilters, DIRECT_BILLS_FILTER_NAME);
	const count = yield select(getDirectBillsCount);
	const request: RetrieveDirectBillsRequest = {
		count,
		limit: DEFAULT_LIST_LIMIT,
		filters
	};
	yield call(executor, request, meta);
};

export default function*() {
	yield takeLatest(retrieveDirectBillsAsync.type, worker);
}
