import * as React from 'react';
import { ScrollableLayout } from 'components';
import { AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { connect } from 'react-redux';
import { getSupplierInvoiceBreadcrumbs } from '../../supplierInvoiceUISelectors';
import { RouteComponentProps } from 'react-router';
import { ScrollableLayoutProps } from 'components/Styles/ScrollableLayout/ScrollableLayout';
import { AppState } from 'store-types';

interface SupplierInvoiceLayoutProps
	extends RouteComponentProps<{
		supplierInvoiceId?: string;
	}> {
	layoutProps?: ScrollableLayoutProps;
	header?: React.ReactNode;
	isLoading?: boolean;
	// from mapStateToProps
	breadcrumbs: BreadcrumbItem[];
}

const SupplierInvoiceLayout: React.SFC<SupplierInvoiceLayoutProps> = props => {
	return (
		<ScrollableLayout {...props.layoutProps} stretch>
			{props.header}
			<ScrollableLayout asColumn>
				<RouteBreadcrumbs items={props.breadcrumbs} />
				<AppNotifications.Alert />
				<AppNotifications.Notification />
				{props.children}
			</ScrollableLayout>
		</ScrollableLayout>
	);
};

export default connect((state: AppState) => ({
	breadcrumbs: getSupplierInvoiceBreadcrumbs(state)
}))(SupplierInvoiceLayout);
