import { RestoreOriginalSupplierInvoiceDocumentRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../financeActionCreator';
import { RetrieveFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';

const ACTION_NAME = 'RESTORE_ORIGINAL_DOCUMENT';
export type RestoreOriginalDocumentRequest =
	| RestoreOriginalSupplierInvoiceDocumentRequest
	| RetrieveFinanceDocumentRequest;

export const restoreOriginalDocument = actionCreator<
	RestoreOriginalDocumentRequest
>(ACTION_NAME);

export const restoreOriginalDocumentAsync = actionCreator.async<
	RestoreOriginalDocumentRequest,
	null,
	Error
>(ACTION_NAME, {
	failed: {
		description: 'Error occurred while restoring the original.'
	},
	done: {
		description: 'Restoring original document completed successfully.'
	}
});
