import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import DocumentPageViewer from 'sections/DocumentPageViewer/DocumentPageViewer';
import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
// import DocumentPageViewerPage from 'sections/DocumentPageViewer/DocumentPageViewerPage';
import {
	deleteSupplierDocumentPage,
	downloadSplitSupplierInvoice
} from 'store/supplierInvoice/actions';
import DocumentPageViewerV2 from 'sections/DocumentPageViewerV2/DocumentPageViewerV2';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import { CommonMetadataAction } from 'app-constants';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';
import { PageType } from 'store/finance/constants';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';

interface FinanceDocumentPageViewerProps {
	portCallId: string;
	jobCode: string;
	document: PortCallDocument;
	supplierInvoiceId: string;
	serviceId: string;
	baseUrl: string;
	activePage: number;
	activePageType: PageType;
	onSaveAnnotation: (
		request: UploadCustomRequest,
		actualNumber: number
	) => void;
	onRestoreOriginal: () => void;
}

function FinanceDocumentPageViewer({
	portCallId,
	jobCode,
	document,
	supplierInvoiceId,
	serviceId,
	baseUrl,
	activePage,
	activePageType,
	onSaveAnnotation,
	onRestoreOriginal
}: FinanceDocumentPageViewerProps) {
	const dispatch = useDispatch();

	const userType = useSelector(getUserType);
	const metadata = document.metadata?.actions || [];
	const canUpload = !!getEntityMetadataAction<CommonMetadataAction>(
		metadata,
		CommonMetadataAction.DELETE
	);

	const downloadAllOnFinish = useCallback(() => {
		dispatch(
			downloadSplitSupplierInvoice({
				supplierInvoiceId,
				serviceId,
				isMultiFileDownload: true
			})
		);
	}, [dispatch, serviceId, supplierInvoiceId]);

	const onPageDelete = useCallback(
		(page: PortCallDocumentPage, pageType: PageType) => {
			const id =
				pageType === PageType.ORIGINAL
					? page.originalFinanceDocumentId
					: page.annotatedFinanceDocumentId;
			dispatch(
				deleteSupplierDocumentPage({
					documentId: id,
					actualNumber: page.actualNumber,
					serviceId: serviceId,
					supplierInvoiceId: supplierInvoiceId,
					pageType: pageType
				})
			);
		},
		[dispatch, serviceId, supplierInvoiceId]
	);

	return (
		<DocumentPageViewerV2
			onDownloadAll={downloadAllOnFinish}
			returnUrl={`/portcalls/${portCallId}/jobs/${jobCode}/finance`}
			document={document}
			total={document.pages.length}
			isAnnotatable={userType === UserType.HUB && canUpload}
			isDocumentPDF
			baseUrl={baseUrl}
			activePage={activePage}
			activePageType={activePageType}
			onSaveAnnotation={onSaveAnnotation}
			canDeletePage={canUpload}
			onPageDelete={onPageDelete}
			isSupplierInvoice={true}
			onRestoreOriginal={onRestoreOriginal}
		/>
	);
}

export default FinanceDocumentPageViewer;
