import actionCreator from '../threadsActionCreator';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREADS_MAIN_PRINCIPAL_TAGS';

export const retrieveThreadsMainPrincipalTagsAsync = actionCreator.async<
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadsMainPrincipalTags = actionCreator<
	RetrieveThreadsMainPrincipalTagsParam
>(ACTION_NAME);
