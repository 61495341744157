import { PortJobState } from '../portJobsState';
export const onSetPortJobOperationUpToDate = (
	state: PortJobState,
	isPortJobOperationUpToDate: boolean
): PortJobState => ({
	...state,
	context: {
		...state.context,
		isPortJobOperationUpToDate
	}
});
