import { FinanceState } from '../financeState';

export function onUpdateFinanceValidationErrors(
	state: FinanceState,
	action: { fieldName: string; value: boolean }
): FinanceState {
	const { fieldName, value } = action;
	return {
		...state,
		context: {
			...state.context,
			validationErrors: {
				...state.context.validationErrors,
				[fieldName]: value
			}
		}
	};
}
