import { Success } from 'typescript-fsa';
import { SupplierInvoiceState } from '../supplierInvoiceState';
import {
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: SupplierInvoiceState,
	action: Success<
		RetrieveSupplierInvoiceServiceDocumentRequest,
		RetrieveSupplierInvoiceServiceDocumentResponse
	>
): SupplierInvoiceState => ({
	...state,
	activeSupplierInvoiceDocument: action.result
});

export const onRetrieveSupplierInvoiceDocument = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrieveSupplierInvoiceServiceDocumentRequest,
	RetrieveSupplierInvoiceServiceDocumentResponse
>('fetchStatuses', 'activeDocument', {
	doneReducer
});
