import React from 'react';
import { Text } from 'components';
import styles from './CreditMark.module.scss';

export default function CreditMark() {
	return (
		<Text className={styles.creditMark} upper color="info">
			credit line
		</Text>
	);
}
