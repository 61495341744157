import actionCreator from '../developerPortalActionCreator';
import {
	DeveloperPortalDataRequest as Request,
	DeveloperPortalData as Response
} from 'services/api/developerPortal/developerPortalServiceTypes';

const RETRIEVE_DEVELOPER_PORTAL = 'RETRIEVE_DEVELOPER_PORTAL';

export const retrieveDeveloperPortalAsync = actionCreator.async<
	Request,
	Response,
	Error
>(RETRIEVE_DEVELOPER_PORTAL);

export const retrieveDeveloperPortal = actionCreator(RETRIEVE_DEVELOPER_PORTAL);
