import { Success } from 'typescript-fsa';
import { ThreadsState } from 'store/threads/threadsState';
import { ThreadReducerState } from 'store/thread/threadState';
import {
	AddThreadMessageRequest,
	AddThreadMessageResponse
} from 'services/api/messages/messagesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onAddThreadMessageSuccess = (
	state: ThreadReducerState,
	{ result, params }: Success<AddThreadMessageRequest, AddThreadMessageResponse>
): ThreadReducerState => {
	if (!state.item) {
		return state;
	}
	const { message: { updateThreadStatus } } = params;
	const item = {
		...state.item,
		lastMessage: result
	};
	if (updateThreadStatus) {
		// many type inconsistencies related to `status`
		item.status = updateThreadStatus;
		item.statusUpdatedOn = String(new Date());
	}
	return {
		...state,
		item
	};
};

export const onAddThreadMessage = makeFetchStatusReducers<
	ThreadReducerState,
	'fetchStatuses',
	AddThreadMessageRequest,
	AddThreadMessageResponse
>('fetchStatuses', 'addMessage', {
	doneReducer: onAddThreadMessageSuccess
});

/**
 * List of Threads
 */
export const onUpdateThreadStatusInThreadsReducer = (
	state: ThreadsState,
	action: Success<AddThreadMessageRequest, AddThreadMessageResponse>
): ThreadsState => {
	const { params: { message: { updateThreadStatus } } } = action;
	const statusChanged =
		state.byId[action.params.threadId] &&
		state.byId[action.params.threadId].status !== updateThreadStatus;
	return updateThreadStatus && statusChanged
		? {
				...state,
				byId: {
					...state.byId,
					[action.params.threadId]: {
						...state.byId[action.params.threadId],
						status: updateThreadStatus,
						statusUpdatedOn: String(new Date())
					}
				}
			}
		: state;
};
