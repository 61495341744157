import {
	UserManager,
	UserManagerSettings,
	User,
	OidcClientSettings
} from 'oidc-client';
import { isString } from 'lodash';

import config from 'services/config';

/* eslint-disable @typescript-eslint/camelcase */
const settings: UserManagerSettings = {
	authority: config.idServUrl,
	client_id: 'phoenix',
	redirect_uri: `${config.baseUrl}/login-callback`,
	response_type: 'code',
	scope: 'openid profile phoenix-api client',
	post_logout_redirect_uri: `${config.baseUrl}/login`,
	silent_redirect_uri: `${config.baseUrl}/login-silent-renew`,
	automaticSilentRenew: true
};
/* eslint-enable */

export interface UserManagerState {
	returnUrl?: string;
}

export const userManager = new UserManager(settings);

/**
 * Returns promise to load the User object for the currently authenticated user
 * @returns {Promise<OidcUser>}
 */
export function getUser() {
	return userManager.getUser();
}

/**
 * Returns promise to trigger a redirect of the current window to the authorization endpoint
 *
 * Request parameters
 *
 * @param state - Opaque value set by the RP to maintain state between request and callback
 * 		Parameter preserves some state object set by the client in the Authorization request and makes it available to the client in the response.
 * @param extraQueryParams
 * 		An object containing additional query string parameters to be including in the authorization request
 */
export function signInRedirect(
	state: UserManagerState,
	extraQueryParams: object
) {
	return userManager.signinRedirect({
		state,
		extraQueryParams
	});
}

/**
 * Returns promise to process response from the authorization endpoint. The result of the promise is the authenticated User
 */
export function signInRedirectCallback() {
	return userManager.signinRedirectCallback();
}

/**
 * Returns promise to trigger a redirect of the current window to the end session endpoint
 *
 * Request parameters
 * state - Opaque value set by the RP to maintain state between request and callback
 */
export function signOutRedirect(state: UserManagerState) {
	return userManager
		.signoutRedirect({
			state
		})
		.then(() => {
			return userManager.clearStaleState();
		});
}

/**
 * Returns promise to notify the parent window of response from the authorization endpoint
 */
export function signInSilentCallback() {
	return userManager.signinSilentCallback();
}

/**
 * Get state maintained between request and callback (e.g. sign in, sign out)
 */
export function processSignInRedirectCallback(user: User) {
	return new Promise((resolve, reject) => {
		if (isString(user.state)) {
			/**
			 * TODO: must be legacy code
			 * It is not clear under which circumstances we can have `user.state` as STRING
			 * In case it becomes clear - comment needed
			 * Perhaps this piece of code isn't needed at all!
			 */

			getStateStoreValue(user.state)
				.then(item => {
					let stateValue;
					try {
						stateValue = JSON.parse(item) || {};
					} catch (e) {
						stateValue = {};
					}
					resolve(stateValue.data || {});
				})
				.catch(reject);
		} else {
			resolve(user.state || {});
		}
	});
}

/**
 * Get value by key from WebStorage
 */
function getStateStoreValue(key: string): Promise<string> {
	const clientSettings = (userManager as UserManager & {
		settings: OidcClientSettings;
	}).settings;
	return !clientSettings || !clientSettings.stateStore
		? new Promise(resolve => {
				resolve('{}');
		  })
		: clientSettings.stateStore.get(key);
}
