import {
	PortJobOperationTypes,
	GenericPortJobOperation,
	BunkeringPortJobOperation
} from 'services/api/portJobs/portJobsServiceTypes';
import { LabeledValue } from 'app-types';
import {
	DEFAULT_CURRENCY_CODE,
	MetadataStatusCode,
	MetadataDisabledReasonCode
} from 'app-constants';
import { AppState } from 'store-types';
import numeral from 'numeral';
import { createSelector } from 'reselect';
import {
	flatMap,
	toNumber,
	isNil,
	reject,
	find,
	get,
	isNumber,
	some,
	isEmpty
} from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { getIsLoading } from 'store/selectors';
import { getPermissionCodes } from 'store/auth/selectors/permissionsSelector';

import {
	PortJobService,
	DisbursementAccount,
	ServiceSectionCode,
	ExchangeRate,
	SubSection,
	PortCallFinance,
	FinanceStatus,
	FinanceStage,
	CurrencyPair,
	FinanceServiceErrorType,
	FinanceServiceError,
	FinanceServiceQueries,
	QueryEvent,
	QueryEventTypeCode,
	DaType,
	ServiceType,
	ServiceDirectBillTargetType,
	DaSet,
	FinanceDetails,
	FinanceActivePortJobDetails,
	FinanceMetadataActionCode,
	FinanceMetadata,
	ComparisonColumnName,
	DaSetStatus,
	FinanceTransactionType,
	Invoice
} from 'services/api/finance/financeServiceTypes';

import { UserType } from 'services/api/users/userServiceTypes';
import { getCurrentPortJobOperations } from 'store/portJobs/selectors';
import { getUserType, getUserGroups } from 'store/auth/selectors/user';
import { Funding } from 'services/api/financeFundings/financeFundingsServiceTypes';
import {
	getEntityMetadataAction,
	getReasonMessageByAction
} from 'store/metadata/utils/metadataUtils';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { disabledInfoColumnTitleMap } from 'sections/PortCall/Finance/Services/ColumnComponents/ColumnComponents.func';
import uuid from 'uuid';
import {
	CustodyAgentType,
	RotationStepMainType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { VPEvents } from './financeSelectorTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { NEW_COST_ID } from './../../../sections/PortCall/Finance/Services/ColumnComponents/ServiceNameColumn/UnitCostComponents/unitCostTypes';

export const getFinance = (state: AppState) => state.finance.finance;
const getOptionalFinance = (state: AppState) =>
	state.finance.finance || ({} as PortCallFinance);

export const getFinanceJob = createSelector(
	getOptionalFinance,
	finance => finance.job
);

export const getFinanceStage = (state: AppState) =>
	getOptionalFinance(state).financeStage;

export const getFinancePortJobDetails = (
	state: AppState,
	activeJobCode: string
) =>
	state.finance.financeDetails?.jobs.some(
		job => job.isJobCancelled && job.code === activeJobCode
	);

export const getIsJobClosed = createSelector(
	getOptionalFinance,
	finance => finance.job.status === PortJobStatus.CLOSED
);

export const getIsFinanceEnabaled = (state: AppState) =>
	getOptionalFinance(state).financeEnabled;

export const getFinanceContext = (state: AppState) => state.finance.context;

export const getDirectBills = createSelector(
	getOptionalFinance,
	finance => finance?.invoices?.elements
);

const getHasInvoiceSplitRequest = ({ transactionsDetails }: Invoice) => {
	if (!transactionsDetails) {
		return false;
	}
	const length = transactionsDetails.length;
	if (!length) {
		return false;
	}
	const transaction = transactionsDetails[0];
	if (transaction.type === FinanceTransactionType.SUPPLIER_INVOICE_SPLIT) {
		return true;
	}
	return false;
};

export const getCustodyAgentRole = createSelector(getFinanceJob, finance => {
	if (finance.custodyAgentRole === CustodyAgentType.INBOUND) {
		return 'Commencement Of Sea Passage (COSP)';
	} else if (finance.custodyAgentRole === CustodyAgentType.OUTBOUND) {
		return 'End Of Sea Passage (EOSP)';
	} else if (finance.custodyAgentRole === CustodyAgentType.NONE) {
		return 'None';
	} else {
		return 'Full Agent';
	}
});

export const getDirectBillInvoices = createSelector(
	getDirectBills,
	directBill => {
		if (!isEmpty(directBill) && directBill) {
			const invoices = [...directBill];
			for (let i = 0; i < invoices.length; i++) {
				const invoice = invoices[i];

				// BE does not return split as separate supplier invoice,
				// so we have to add it manually
				if (getHasInvoiceSplitRequest(invoice)) {
					invoices.splice(++i, 0, {
						...invoice,
						isSplit: true
					});
				}
			}
			return { id: uuid(), typeCode: 'SupplierInvoice', invoices: invoices };
		} else return {};
	}
);

export const getFinanceBenchmarksData = (state: AppState) =>
	getOptionalFinance(state).benchmarkData;

export const getFinanceBenchmarkId = (state: AppState) =>
	getOptionalFinance(state).benchmarkData?.id;

export const getBltRedirectionUrl = (state: AppState) =>
	state.finance.bltRedirectionUrl;

export const getFinanceFetchStatuses = (state: AppState) =>
	state.finance.fetchStatuses;

export const getIsConvertDBStatusLoading = (
	state: AppState,
	jobServiceId: string
) => getIsLoading(state.finance.updateStatuses.convertDBStatus[jobServiceId]);

export const getFinanceMultilevelApprovalSettings = createSelector(
	getOptionalFinance,
	({ multilevelApprovalSettings }) => multilevelApprovalSettings
);

export const getShouldShowApprovalLevelInDaOutStatus = createSelector(
	getUserType,
	getFinanceMultilevelApprovalSettings,
	(userType, multilevelApprovalSettings) =>
		(userType === UserType.PRINCIPAL || userType === UserType.HUB) &&
		multilevelApprovalSettings &&
		multilevelApprovalSettings.requiredApprovalLevels >= 2
);

export const getIsFinanceInDAStage = createSelector(
	getFinanceStage,
	financeStage => financeStage === FinanceStage.DA
);

export const getServicesCollection = (state: AppState) =>
	getOptionalFinance(state).services;
export const getFinanceServices = (state: AppState) =>
	getServicesCollection(state).elements;
export const getFinanceStatus = (state: AppState) =>
	getOptionalFinance(state).financeStatus;
export const getPortCurrencies = (state: AppState) =>
	getOptionalFinance(state).portCurrencies || [];
export const getExchangeRates = (state: AppState) =>
	getOptionalFinance(state).exchangeRates || [];
export const getIndicativeRateDate = (state: AppState) =>
	getOptionalFinance(state).indicativeRateDate;
export const getLastUpdatedDateDA = (state: AppState) =>
	getOptionalFinance(state)?.fixedRateDateDA;
export const getLastUpdatedDateSDA = (state: AppState) =>
	getOptionalFinance(state)?.fixedRateDateSDA;
export const getDaSets = (state: AppState) =>
	getOptionalFinance(state).disbursementAccountSets || [];

export const getApprovalSettings = (state: AppState) =>
	getOptionalFinance(state).approvalSettings;

export const getIsIndicativeRateOverride = (state: AppState) =>
	getOptionalFinance(state).isIndicativeRateOverride;

export const getIsIndicativeRateOverrideForService = (
	state: AppState,
	id: string
) =>
	getOptionalFinance(state).services.elements.find(
		service => service.id === id && service.isIndicativeRateOverride
	);

export const getIsPDABypassPrinicipalReApprovalRequired = createSelector(
	getApprovalSettings,
	approvalSettings => approvalSettings.isPDABypassPrinicipalReApprovalRequired
);

export const getServiceByParentId = (
	state: AppState,
	parentServiceId: string | null
) => getFinanceServices(state).find(service => service.id === parentServiceId);

export const getDASetById = (state: AppState, setId: string) =>
	getDaSets(state).find(({ id }) => id === setId) || ({} as DaSet);

export const getComparisonColumnByName = createSelector(
	getFinanceContext,
	(_state: AppState, columnName: ComparisonColumnName) => columnName,
	(context, columnName) => context.comparisonColumn[columnName]
);

export const getDASetSDAReasons = (state: AppState) => state.finance.sdaReasons;

export const getDisbursementAccountIns = createSelector(
	getOptionalFinance,
	finance => finance?.disbursementAccountIns.elements
);

export const getDisbursementAccountOuts = createSelector(
	getOptionalFinance,
	finance => finance?.disbursementAccountOuts.elements
);

export const getCurrentJobCode = createSelector(
	getOptionalFinance,
	finance => finance?.job.code
);

export const getIsSameCurrencyDA = createSelector(
	getOptionalFinance,
	finance => finance.isSameCurrencyDA
);

export const getIsFixedRateDa = createSelector(
	getOptionalFinance,
	finance => finance.isFixedRateDA
);

export const getFixedRates = createSelector(
	getOptionalFinance,
	finance => finance.fixedRates
);

export const getIsFixedRateSda = createSelector(
	getOptionalFinance,
	finance => finance.isFixedRateSDA
);

export const getFinanceJobMetaDataFinanceAlert = (state: AppState) =>
	getOptionalFinance(state).metadataVP;

export const getFinanceJobMetaDataFinanceAlertStatus = createSelector(
	getFinanceJobMetaDataFinanceAlert,
	(_state: AppState, action: FinanceMetadataActionCode) => action,
	(metadata, actionCode) => {
		if (!metadata) {
			return false;
		}
		return !!getEntityMetadataAction<FinanceMetadataActionCode>(
			metadata.actions,
			actionCode
		);
	}
);
export const getFinanceJobMetaData = (state: AppState) =>
	getOptionalFinance(state).metadata;
/**
 * Get a map of document by DAOut { daOutId: Document }
 */
export const getDocumentsByDaOutId = (state: AppState) =>
	state.finance.documentByDaOutId;

export const getDAsInCollection = (state: AppState) =>
	getOptionalFinance(state).disbursementAccountIns;
export const getDAsOutCollection = (state: AppState) =>
	getOptionalFinance(state).disbursementAccountOuts;
export const getDAsIn = createSelector(
	getDAsInCollection,
	collection => collection.elements
);
export const getDAsOut = createSelector(
	getDAsOutCollection,
	collection => collection.elements
);
export const getAllDas = createSelector(getDAsIn, getDAsOut, (ins, outs) => [
	...ins,
	...outs
]);

export const getDAsOutByCurrencyFromActiveDASet = createSelector(
	getDAsOut,
	getDaSets,
	(_state: AppState, currencyCode: string) => currencyCode,
	(das: DisbursementAccount[], daSets: DaSet[], currencyCode: string) =>
		das.filter((da: DisbursementAccount) => {
			const daSet = daSets.find(({ id }) => id === da.disbursementAccountSetId);
			return (
				da.currencyCode === currencyCode && daSet?.status === DaSetStatus.ACTIVE
			);
		})
);

export const getDAOutByFunding = createSelector(
	getDAsOut,
	(_state: AppState, funding: Funding) => funding,
	(das, funding) => das.find(da => da.id === funding.daOutId)
);

export const getFilteredDAsBySetId = (das: DisbursementAccount[], id: string) =>
	das
		.filter(da => da.disbursementAccountSetId === id)
		.sort((a, b) => a.number - b.number);

export const getFinanceDaInBySetId = createSelector(
	getDAsIn,
	(_state: AppState, setId: string) => setId,
	(das, setId) => getFilteredDAsBySetId(das, setId)
);

export const getFinanceDaOutBySetId = createSelector(
	getDAsOut,
	(_state: AppState, setId: string) => setId,
	(das, setId) => getFilteredDAsBySetId(das, setId)
);

export const getAllServicesBySetId = createSelector(
	getFinanceDaInBySetId,
	getFinanceDaOutBySetId,
	(dasIn, dasOut) => flatMap(dasIn.concat(dasOut).map(da => da.services))
);

export const getDaById = createSelector(
	getAllDas,
	(_state: AppState, id: string) => id,
	(das, id) => das.find(da => da.id === id)
);

export const makeGetDaTypeById = () =>
	createSelector(getDaById, (da): DaType | undefined => {
		if (!da) {
			return;
		}
		return da.daType;
	});

export const getDaTypeById = makeGetDaTypeById();

export const getFinanceFetchStatus = (state: AppState) =>
	state.finance.fetchStatuses.retrieve;

/**
 * Get a LIST of services by a list of services.id
 */
const getServicesbyIdsHelper = (
	services: PortJobService[],
	servicesIds: string[]
) => {
	return services.filter(service => servicesIds.includes(service.id));
};

export const isFinanceFetching = (state: AppState) =>
	state.finance.fetchStatuses.retrieve === FetchStatus.PENDING;

export const makeIsFinanceServiceAdding = () =>
	createSelector(
		(state: AppState) => state.finance.addServiceStatuses,
		(_state: AppState, code: ServiceSectionCode) => code,
		(_state: AppState, _code, entityId = '') => entityId,
		(addServiceStatuses, code, entityId) => {
			return addServiceStatuses[`${code}-${entityId}`] === FetchStatus.PENDING;
		}
	);

/**
 * Get a SINGLE service by service.id
 */
export const getServiceById = createSelector(
	getFinanceServices,
	(_state: AppState, id: string) => id,
	(services, serviceId): PortJobService | null =>
		services.length
			? (services.find(service => service.id === serviceId) as PortJobService)
			: null
);

const findExchangeRateBy = (
	rate: ExchangeRate,
	baseCurrencyCode: string = DEFAULT_CURRENCY_CODE,
	quoteCurrencyCode: string = DEFAULT_CURRENCY_CODE
) =>
	rate.baseCurrencyCode === baseCurrencyCode &&
	rate.quoteCurrencyCode === quoteCurrencyCode;

export const getIndicativeRateByCurrencyCodesHelper = (
	rates: ExchangeRate[],
	{ baseCurrencyCode, quoteCurrencyCode = DEFAULT_CURRENCY_CODE }: CurrencyPair
) => {
	if (baseCurrencyCode === quoteCurrencyCode) {
		return 1;
	}
	const directExchangeRate = rates.find((rate: ExchangeRate) =>
		findExchangeRateBy(rate, baseCurrencyCode, quoteCurrencyCode)
	);
	if (directExchangeRate) {
		return Number(directExchangeRate.indicativeRate);
	}

	const exchangeRateToUsd = rates.find((rate: ExchangeRate) =>
		findExchangeRateBy(rate, baseCurrencyCode)
	);
	const exchangeRateFromUsd = rates.find((rate: ExchangeRate) =>
		findExchangeRateBy(rate, DEFAULT_CURRENCY_CODE, quoteCurrencyCode)
	);
	if (exchangeRateToUsd && exchangeRateFromUsd) {
		return (
			Number(exchangeRateToUsd.indicativeRate) *
			Number(exchangeRateFromUsd.indicativeRate)
		);
	}
	return null;
};

export const getIndicativeRateByCurrencyCodes = createSelector(
	getExchangeRates,
	(_state: AppState, baseCurrencyCode: string, quoteCurrencyCode: string) => ({
		baseCurrencyCode,
		quoteCurrencyCode
	}),
	getIndicativeRateByCurrencyCodesHelper
);

/**
 * Returns an array of finance totals in a more representative format for the UI
 * with { label & key }
 */
export const getFinanceTotalWithLabel = createSelector(
	getFinance,
	getUserType,
	(finance, userType: UserType): Array<LabeledValue<string, number>> => {
		const defaultLabel = `*Total (${DEFAULT_CURRENCY_CODE})`;
		if (
			!finance ||
			(!finance.daInTotalAmountInUsd && !finance.daOutTotalAmountInUsd)
		) {
			return [
				{
					label: defaultLabel,
					key: 0
				}
			];
		}

		const totalLabel = userType !== UserType.HUB ? defaultLabel : null;
		const totals = [];
		if (finance.daInTotalAmountInUsd) {
			totals.push({
				label: totalLabel || `Total DA IN (${DEFAULT_CURRENCY_CODE})`,
				key: finance.daInTotalAmountInUsd
			});
		}
		if (finance.daOutTotalAmountInUsd) {
			totals.push({
				label: totalLabel || `Total DA OUT (${DEFAULT_CURRENCY_CODE})`,
				key: finance.daOutTotalAmountInUsd
			});
		}
		return totals;
	}
);

const getFormattedValueHelper = (value: string | number) => {
	return toNumber(numeral(value).format('0.00'));
};

export const getIsServiceTaxInfoVisibleHelper = (
	{
		daInId,
		daOutId,
		serviceSection: { code: serviceSectionCode },
		modeCode
	}: PortJobService,
	isCurrentUserHub?: boolean
) =>
	!!(
		isCurrentUserHub &&
		daOutId &&
		!daInId &&
		(serviceSectionCode === ServiceSectionCode.AGENCY ||
			modeCode === 'Converted')
	);

export const getServicesUsdTotalbyIdsHelper = (services: PortJobService[]) => {
	return services.reduce((total, service) => {
		if (
			isNil(service.amount) ||
			(service.parentServiceId && !service.isCredit) ||
			(service.serviceTypeCode === ServiceType.DIRECT_BILL &&
				service.directBill &&
				service.directBill.targetTypeCode !== ServiceDirectBillTargetType.LPA)
		) {
			return total;
		}
		return total + getFormattedValueHelper(service.amountInUsd);
	}, 0);
};

export const getIsUpdatingService = (state: AppState, id: string) =>
	state.finance.updateStatuses.services[id] === FetchStatus.PENDING;

export const getIsUpdatingServiceFailed = (state: AppState, id: string) =>
	state.finance.updateStatuses.services[id] === FetchStatus.FAILURE;

export const getIsRetrievingAvailableDas = (state: AppState) =>
	state.finance.fetchStatuses.retrieveAvailableDas === FetchStatus.PENDING;

export const getFinanceServiceSections = createSelector(
	getFinance,
	getCurrentPortJobOperations,
	(finance, operation) => {
		const serviceSections = finance?.serviceSections;
		if (operation.length > 0) {
			return serviceSections;
		}
		return reject(serviceSections, { code: ServiceSectionCode.HUSBANDRY });
	}
);

export const getServiceSubSectionsByCode = createSelector(
	getFinanceServiceSections,
	(_state: AppState, code: ServiceSectionCode) => code,
	(sections, code) => {
		const section = find(sections, { code });
		return get(section, 'sections', []) as SubSection[];
	}
);

export const getServiceSectionsByCode = createSelector(
	getFinanceServiceSections,
	(_state: AppState, code: string) => code,
	(serviceSection, code) => {
		const section = serviceSection?.find(sec =>
			sec.sections?.some(sub => sub?.code === code)
		);
		if (section) {
			return get(section, 'code');
		}
		return code as ServiceSectionCode;
	}
);

export const getTotalBySectionCode = createSelector(
	getServiceSubSectionsByCode,
	getFinanceServices,
	(subsections, services) => {
		const servicesIds = flatMap(subsections, subsection => subsection.services);
		const sectionServices = getServicesbyIdsHelper(services, servicesIds);
		return getServicesUsdTotalbyIdsHelper(sectionServices);
	}
);

export const getAddDAFetchStatus = (state: AppState) =>
	state.finance.fetchStatuses.addDA;
export const getIsAddDAModalShown = (state: AppState) =>
	state.finance.context.showAddDAModal;

// MetaData
const getFinanceMetadata = (state: AppState) =>
	getOptionalFinance(state).metadata;

export const getFinanceMetadataAction = createSelector(
	getFinanceMetadata,
	(_state: AppState, actionCode: FinanceMetadataActionCode) => actionCode,
	(metadata, actionCode) =>
		metadata.actions.find(action => action.actionCode === actionCode)
);

export const hasFinanceMetadataAction = createSelector(
	getFinanceMetadataAction,
	action => !!action
);

export const getFinanceEntityMetadataAction = (
	metadata: FinanceMetadata,
	actionCode: FinanceMetadataActionCode
) =>
	getEntityMetadataAction<FinanceMetadataActionCode>(
		metadata.actions,
		actionCode
	);

export const getIsSubmitDisabled = createSelector(
	(state: AppState) =>
		getFinanceMetadataAction(state, FinanceMetadataActionCode.MOVE),
	(state: AppState) => state.finance.context.validationErrors,
	(action, errors) => {
		const canSubmit = action?.statusCode !== MetadataStatusCode.DISABLED;
		const hasValidationError = some(errors, hasError => hasError === true);
		return !canSubmit || hasValidationError;
	}
);

export const getIsDisableFinanceDisabled = createSelector(
	(state: AppState) =>
		getFinanceMetadataAction(state, FinanceMetadataActionCode.DISABLE_FINANCE),
	action => action?.statusCode !== MetadataStatusCode.ENABLED
);

export const SUBMIT_BTN_DISABLED_FINANCE_BEING_UPDATED_HINT_CONFIG = {
	[UserType.LPA]: `Finance is being reviewed/approved`,
	[UserType.HUB]: `Finance is being updated/approved`,
	[UserType.PRINCIPAL]: {
		[FinanceStatus.DRAFT]: `Finance is being prepared/reviewed. Please wait until it's ready for your approval`,
		[FinanceStatus.SUBMITTED]: `Finance is being prepared/reviewed. Please wait until it's ready for your approval`,
		[FinanceStatus.APPROVED]: `Finance has been already approved. No action needed`
	}
};

export const getSubmitFinanceBtnDisabledTooltip = createSelector(
	getUserType,
	getFinanceStatus,
	state => getFinanceMetadataAction(state, FinanceMetadataActionCode.MOVE),
	(userType, financeStatus, financeMoveMetadataAction) => {
		if (financeMoveMetadataAction?.statusCode === 'disabled') {
			if (
				financeMoveMetadataAction.disabledReasonCode ===
				MetadataDisabledReasonCode.FINANCE_IS_BEING_UPDATED_OR_APPROVED
			) {
				return userType === UserType.PRINCIPAL
					? SUBMIT_BTN_DISABLED_FINANCE_BEING_UPDATED_HINT_CONFIG[userType][
							financeStatus
					  ]
					: SUBMIT_BTN_DISABLED_FINANCE_BEING_UPDATED_HINT_CONFIG[userType];
			}
			return getReasonMessageByAction<FinanceMetadataActionCode>(
				financeMoveMetadataAction
			);
		}
	}
);

export const getAvailableSubmitOption = createSelector(
	(state: AppState) =>
		hasFinanceMetadataAction(state, FinanceMetadataActionCode.RETURN_TO_HUB),
	(state: AppState) =>
		hasFinanceMetadataAction(state, FinanceMetadataActionCode.RETURN_TO_LPA),
	(hasReturnToHubAction, hasReturnToLPAAction) => {
		if (hasReturnToHubAction) {
			return {
				action: FinanceMetadataActionCode.RETURN_TO_HUB,
				label: 'Return finance to Hub'
			};
		} else if (hasReturnToLPAAction) {
			return {
				action: FinanceMetadataActionCode.RETURN_TO_LPA,
				label: 'Return finance to LPA'
			};
		}
		return;
	}
);

export const getPDAReapprovalSubmitOption = createSelector(
	(state: AppState) =>
		hasFinanceMetadataAction(
			state,
			FinanceMetadataActionCode.SUBMIT_WITHOUT_PRINICIPAL_REAPPROVAL
		),
	hasSubmitWithoutPrincipalReapproval => {
		if (hasSubmitWithoutPrincipalReapproval) {
			return {
				action: FinanceMetadataActionCode.SUBMIT_WITHOUT_PRINICIPAL_REAPPROVAL,
				label: 'Submit without re-approval'
			};
		}
		return;
	}
);

export const getSendFinanceSubmitOption = createSelector(
	(state: AppState) =>
		hasFinanceMetadataAction(state, FinanceMetadataActionCode.SEND_FINANCE),
	hasSendFinance => {
		if (hasSendFinance) {
			return {
				action: FinanceMetadataActionCode.SEND_FINANCE,
				label: 'Send Finance'
			};
		}
		return;
	}
);

export const getRefreshDirectBillOption = createSelector(
	(state: AppState) =>
		hasFinanceMetadataAction(
			state,
			FinanceMetadataActionCode.REFRESH_DIRECT_BILL_AGREEMENTS
		),
	hasRefreshDirectBill => {
		if (hasRefreshDirectBill) {
			return {
				action: FinanceMetadataActionCode.REFRESH_DIRECT_BILL_AGREEMENTS,
				label: 'Refresh Direct Bill Agreements'
			};
		}
		return;
	}
);

export const getDisabledInfoColumnTooltipTitle = createSelector(
	(_state: AppState, record: PortJobService) => record,
	(_state: AppState, _record: PortJobService, daByService) => daByService,
	getUserGroups,
	getPermissionCodes,
	(record: PortJobService, daByService, userGroups, permissionCodes) => {
		const isDisabledBySupplementalSetDa = !!getFinanceEntityMetadataAction(
			record.metadata,
			FinanceMetadataActionCode.DISABLE
		);
		if (isDisabledBySupplementalSetDa) {
			return disabledInfoColumnTitleMap.DISABLED_BY_SUPPEMENTAL_SET_DA;
		}
		let tooltipTitle = disabledInfoColumnTitleMap.DA_ALREADY_APPROVED;
		const currentDA =
			daByService[record.id] && daByService[record.id][DaType.OUT];

		const validationGroup = currentDA?.validationGroup;
		if (validationGroup) {
			const userBelongsToValidationGroup = userGroups.some(
				userGroup => userGroup.id === validationGroup.id
			);
			if (!userBelongsToValidationGroup) {
				tooltipTitle = disabledInfoColumnTitleMap.DIFFERENT_VALIDATION_GROUP(
					validationGroup.name
				);
			} else if (
				!permissionCodes.includes(PermissionCode.APPROVE_ASSIGNED_DA)
			) {
				tooltipTitle = disabledInfoColumnTitleMap.NO_PERMISSION;
			}
		}
		return tooltipTitle;
	}
);

export const getIsMovingToDa = (state: AppState) =>
	getIsLoading(state.finance.fetchStatuses.moveToStage);

export const getIsBenchmarkUpdating = (state: AppState, benchmarkId: string) =>
	getIsLoading(state.finance.fetchStatuses.updateBenchmark[benchmarkId]);

export const getIsRevertingJobStatus = (state: AppState) =>
	getIsLoading(state.finance.fetchStatuses.revertJobStatus);

export const getIsBenchmarkLoading = createSelector(
	getFinanceFetchStatuses,
	fetchStatuses =>
		getIsLoading(fetchStatuses.refreshBenchmark) ||
		getIsLoading(fetchStatuses.retrieveBenchmarkStatus)
);

export const getFinanceDetails = (state: AppState) =>
	state.finance.financeDetails;

export const getSafeFinanceDetails = (state: AppState) =>
	getFinanceDetails(state) || { jobs: [] };

export const getFinanceDetailsFetchStatus = (state: AppState) =>
	state.finance.fetchStatuses.retrieveFinanceDetails;

const filterActivePortJobs = ({
	jobs
}: FinanceDetails): FinanceActivePortJobDetails[] => jobs;

export const getFinancePortJobsDetails = createSelector(
	getSafeFinanceDetails,
	filterActivePortJobs
);

const getFinanceErrors = (state: AppState) => state.finance.serviceErrors;

const getFinanceCurrencies = (state: AppState) => state.finance.currencies;

export const getFinanceCurrencyDecimalPart = createSelector(
	getFinanceCurrencies,
	(_state: AppState, currencyCode?: string) => currencyCode,
	(_state: AppState, _currencyCode: string, defaultDecimalPart: string) =>
		defaultDecimalPart,
	(currencies, currencyCode, defaultDecimalPart): number => {
		if (!currencyCode) {
			return Number(defaultDecimalPart);
		}
		const currentCurrency = currencies[currencyCode];
		if (currentCurrency && isNumber(currentCurrency.decimalPlaces)) {
			return currentCurrency.decimalPlaces;
		}
		return Number(defaultDecimalPart);
	}
);

export const getFinanceErrorsMapByType = createSelector(
	getFinanceErrors,
	(errors = []) => {
		return errors.reduce(
			(acc, error: FinanceServiceError) => {
				if (error.errorType === FinanceServiceErrorType.INWARDS) {
					acc.lpaQueries.push(error);
				} else {
					acc.principalQueries.push(error);
				}
				return acc;
			},
			{
				lpaQueries: [] as FinanceServiceError[],
				principalQueries: [] as FinanceServiceError[]
			}
		);
	}
);

export const getMessagesAmounts = (queries: FinanceServiceQueries) => {
	const { lpaQueries, principalQueries } = queries;
	const lpaEvents = lpaQueries?.events || [];
	const principalEvents = principalQueries?.events || [];
	const filterMessages = (event: QueryEvent) =>
		event.eventTypeCode === QueryEventTypeCode.MESSAGE_ADDED;
	return {
		lpaQueries: lpaEvents.filter(filterMessages).length,
		principalQueries: principalEvents.filter(filterMessages).length
	};
};

export const getFinancePageStatus = createSelector(
	getOptionalFinance,
	finance => finance.displayStatus
);

export const getFinancePageJobQueriedLPA = createSelector(
	getOptionalFinance,
	finance => finance.queriedToLPA
);

export const getFinanceStatusDA = createSelector(
	getFinanceStage,
	getFinanceStatus,
	(stage: FinanceStage, financeStatus: FinanceStatus) =>
		stage === FinanceStage.DA && financeStatus === FinanceStatus.SUBMITTED
);

export const getFinanceStatusDAApproved = createSelector(
	getFinanceStage,
	getFinanceStatus,
	(stage: FinanceStage, financeStatus: FinanceStatus) =>
		stage === FinanceStage.DA && financeStatus === FinanceStatus.APPROVED
);

export const getIsNotPDAApproved = createSelector(
	getFinanceStage,
	getFinanceStatus,
	(stage: FinanceStage, financeStatus: FinanceStatus) =>
		stage === FinanceStage.PDA && financeStatus !== FinanceStatus.APPROVED
);

export const makeGetDasAvailabilityMap = () =>
	createSelector(
		getFinanceContext,
		(_state: AppState, serviceId: string) => serviceId,
		(context, serviceId) => context.availableDas[serviceId] || {}
	);

const getDasAvailabilityMap = makeGetDasAvailabilityMap();
export const getAvailableDasByServiceId = createSelector(
	getDasAvailabilityMap,
	getAllDas,
	(dasAvailabilityItems, das) => {
		return das.reduce((acc: DisbursementAccount[], da) => {
			if (dasAvailabilityItems[da.id]) {
				acc.push(da);
			}
			return acc;
		}, [] as DisbursementAccount[]);
	}
);

export const getDraftServicesWithIssDirectBill = createSelector(
	getFinanceStatus,
	getFinanceServices,
	(status, services) =>
		status === FinanceStatus.DRAFT
			? services.filter(
					service =>
						service.serviceTypeCode === ServiceType.DIRECT_BILL &&
						service.directBill &&
						service.directBill.targetTypeCode ===
							ServiceDirectBillTargetType.ISS
			  )
			: []
);

export const getFinanceCommentsInfo = (state: AppState) =>
	state.finance.finance?.commentsInfo;

export const getFinanceCommentsTabs = (userType: UserType) => {
	const tabs = [];
	if (userType === UserType.HUB) {
		tabs.push(
			{
				id: 'Lpa',
				eventsKey: 'Lpa',
				label: 'MESSAGES WITH LPA'
			},
			{
				id: 'Principal',
				eventsKey: 'Principal',
				label: 'MESSAGES WITH PRINCIPAL'
			}
		);
	} else {
		tabs.push(
			{
				id: 'Principal',
				eventsKey: 'Principal',
				label: 'Messages with hub'
			},
			{
				id: 'Lpa',
				eventsKey: 'Lpa',
				label: 'Messages between hub/lpa'
			}
		);
	}
	return tabs;
};

export const getOperationsWithDetails = createSelector(
	getCurrentPortJobOperations,
	(operations: PortJobOperationTypes[]) => {
		return operations.filter(
			operation =>
				(operation as GenericPortJobOperation | BunkeringPortJobOperation)
					.details
		);
	}
);

export const getBenchmarkStatus = createSelector(
	getOptionalFinance,
	finance => finance.benchmarkData.benchmarkSynchronizationStatus
);

export const getRefreshDirectBillFetchStatus = createSelector(
	getFinanceFetchStatuses,
	financeFetchStatus => financeFetchStatus.retrieveRefreshDirectBills
);

export const getRefreshDirectBillData = (state: AppState) =>
	state.finance.refreshDirectBill;

export const getDirectBillUpdateFetchStatus = createSelector(
	getFinanceFetchStatuses,
	financeFetchStatus => financeFetchStatus.updateRefreshDirectBill
);

export const isFixedRateUpdating = createSelector(
	getFinanceFetchStatuses,
	financeFetchStatus =>
		financeFetchStatus.updateFixedRateExchangeRate === FetchStatus.PENDING
);

export const getIsOperationsDisabled = createSelector(
	getFinance,
	finance => finance?.isOperationsDisabled
);

export const getVesselProgramDetails = createSelector(getFinance, finance =>
	finance?.rotationSteps?.map(step => {
		return ({
			...step.events[0],
			label:
				step.rotationStepType === RotationStepMainType.ARRIVAL_POINT
					? 'ETA'
					: 'ETS',
			isMandatory: step.rotationStepType !== RotationStepMainType.SAILING_POINT,
			rotationStepType: step.rotationStepType,
			children: [
				{
					...step.events[0],
					datePlt: step.events[0].realDatePlt,
					label:
						step.rotationStepType === RotationStepMainType.ARRIVAL_POINT
							? 'End Of Sea Passage (EOSP)'
							: 'Commencement Of Sea Passage (COSP)'
				}
			]
		} as unknown) as VPEvents;
	})
);

export const getVPRotationStep = createSelector(
	getFinance,
	(_state: AppState, stepName: RotationStepMainType) => stepName,
	(finance, stepName) =>
		finance?.rotationSteps?.find(step => step.rotationStepType === stepName)
);

export const getUnitCostFetchStatus = createSelector(
	getFinanceFetchStatuses,
	(_state: AppState, formId: string) => formId,
	(financeFetchStatus, formId) =>
		formId === NEW_COST_ID
			? financeFetchStatus.submitUnitForm
			: financeFetchStatus.updateUnitForm
);

export const deleteUnitCostFetchStatus = createSelector(
	getFinanceFetchStatuses,
	financeFetchStatus => financeFetchStatus.deleteUnitForm
);

export const getUnitCostRateId = (state: AppState, serviceId: string) =>
	state.finance.finance?.services.elements.find(
		element => element.id === serviceId
	)?.serviceUnitCostRate;
