import * as React from 'react';
import { Button, Checkbox } from 'components/antd';
import Flex from 'components/Styles/Flex/Flex';
import { Justify } from 'components/types';
import styles from './AcceptPortJobModal.module.scss';

interface AcceptPortJobButtonProps {
	isEnabled: boolean;
	onAccept: () => void;
}

export const AcceptPortJobButton: React.SFC<AcceptPortJobButtonProps> = (
	props: AcceptPortJobButtonProps
) => (
	<Button type="primary" onClick={props.onAccept} disabled={!props.isEnabled}>
		Accept Job
	</Button>
);

interface AcceptPortJobModalFooterProps {
	isCheckboxChecked: boolean;
	toggleCheckbox: () => void;
	closeModal: () => void;
	acceptJob: () => void;
	isAcceptanceDataEmpty: boolean;
}

export default ({
	isCheckboxChecked,
	isAcceptanceDataEmpty,
	toggleCheckbox,
	closeModal,
	acceptJob
}: AcceptPortJobModalFooterProps) => (
	<Flex
		align="center"
		justify={Justify.BETWEEN}
		className={styles.footerWrapper}
	>
		<Button type="primary" transparent onClick={closeModal}>
			Cancel
		</Button>
		{!isAcceptanceDataEmpty && (
			<Checkbox
				onChange={toggleCheckbox}
				checked={isCheckboxChecked}
				className={styles.footerCheckbox}
			>
				I have read all requirements
			</Checkbox>
		)}
		<AcceptPortJobButton
			isEnabled={isCheckboxChecked || isAcceptanceDataEmpty}
			onAccept={acceptJob}
		/>
	</Flex>
);
