import {
	Message,
	MessageRecipient
} from 'services/api/messages/messagesServiceTypes';
import { IComposeMessageFormState } from '../ComposeMessage';
import { UserContact } from 'services/api/users/userServiceTypes';
import { ComposeMessageFrom } from '../ComposeMessageTypes';
import validationConfig from './ComposeMessageValidationConfig';
import { uniqBy } from 'lodash';

export const AUTOREPLY_ALLOWED_SUBJECTS = [
	'PortjobChangelog',
	'\\[Alert\\] Vessel Programme Update for Job'
];

export const userRepliesOnlyToHimself = (
	from: MessageRecipient,
	to: MessageRecipient[]
) => to && to.length === 1 && to[0].email === from.email;

const userRepliesOnlyToDefault = (
	message: Message,
	from?: ComposeMessageFrom
) =>
	from &&
	message.from &&
	message.to &&
	message.to.length === 1 &&
	message.from.email === from.defaultMailboxAddress;

export const hasAutoReplyAllowedSubject = (subject: string) =>
	new RegExp(`(${AUTOREPLY_ALLOWED_SUBJECTS.join('|')})`).test(subject);

export const isAutoReplyAllowed = (
	message: Message,
	from: ComposeMessageFrom
) =>
	hasAutoReplyAllowedSubject(message.subject) ||
	userRepliesOnlyToHimself(message.from, message.to) ||
	userRepliesOnlyToDefault(message, from);

const startsWithAny = (value: string, values: string[]) => {
	let starts = '';
	for (let i = 0; i < values.length; i++) {
		const pattern = new RegExp(`^${values[i]}`, 'i');
		if (pattern.test(value)) {
			starts = value.substring(0, values[i].length);
			break;
		}
	}
	return starts;
};

export const getRecipientsError = (
	recipients?: UserContact[]
): string | undefined =>
	recipients?.some(
		({ email }) =>
			!email?.length || email.length > validationConfig.emailMaxLength
	)
		? 'Email should be 256 characters or less'
		: undefined;

const addPrefixToSubject = (prefix: string, strip: string[]) => (
	value?: string
) => {
	let newValue = value || '';
	if (newValue) {
		newValue = newValue.trim();
		const excludes = [prefix, ...strip];
		while (startsWithAny(newValue, excludes)) {
			const starts = startsWithAny(newValue, excludes);
			newValue = newValue.replace(starts, '');
			newValue = newValue.trim();
		}
		return `${prefix} ${newValue}`.substring(
			0,
			validationConfig.subjectMaxLength
		);
	} else {
		return `${prefix} No Subject`;
	}
};

export const addReplyPrefixToSubject = addPrefixToSubject('Re:', ['Fw:']);
export const addForwardPrefixToSubject = addPrefixToSubject('Fw:', ['Re:']);

/**
 * Make sure content history has divider when replying from app/other client
 */
export const getContentHistoryFormatted = (contentHistory?: string) => {
	return contentHistory ? `<hr /> ${contentHistory}` : contentHistory;
};

export const getShouldRecipientBeAdded = (
	emails: UserContact[],
	maxAllowedSumOfCharacters: number = validationConfig.maxCharactersSumInRecipients
) =>
	emails.reduce((acc, curr) => acc + curr.email.length, 0) <=
	maxAllowedSumOfCharacters;

export const prepareContactFromRecipient = ({
	userId: id,
	email,
	fullName
}: MessageRecipient): UserContact => ({
	id,
	email,
	fullName
});

export const filterOutSenderFromRecipients = (
	contacts: UserContact[],
	sender: ComposeMessageFrom
) =>
	contacts.filter((contact: UserContact) => {
		const email = contact.email.toLowerCase();
		const isEmailDifferentThanSenderDefaultMailbox = sender.defaultMailboxAddress
			? email !== sender.defaultMailboxAddress.toLowerCase()
			: true;
		const isEmailDifferentThanSenderEmail =
			email !== sender.email.toLowerCase();
		return (
			isEmailDifferentThanSenderDefaultMailbox &&
			isEmailDifferentThanSenderEmail
		);
	});

export const prepareRecipients = (
	recipients: MessageRecipient[],
	from: ComposeMessageFrom,
	skipRecipientsFiltering?: boolean
): UserContact[] => {
	const contacts = recipients.map(prepareContactFromRecipient);

	return skipRecipientsFiltering
		? uniqBy(contacts, 'email')
		: filterOutSenderFromRecipients(contacts, from);
};

export const getUniqueEmailsFromContacts = (contacts: UserContact[]) =>
	uniqBy(contacts, 'email').map(contact => contact.email);

export const getReplyMessageFrom = ({
	name,
	defaultMailboxAddress
}: ComposeMessageFrom) => ({
	name,
	email: defaultMailboxAddress || ''
});

export function createEmptyMessage(): IComposeMessageFormState {
	return {
		subject: '',
		from: {
			name: '',
			email: ''
		},
		to: [],
		cc: [],
		content: '',
		attachments: [],
		contextId: '',
		jobCodes: [],
		mustStartNewThread: false
	};
}

export function createReplyMessage(
	from: ComposeMessageFrom,
	message: Message
): IComposeMessageFormState {
	return {
		...createEmptyMessage(),
		id: message.id,
		subject: addReplyPrefixToSubject(message.subject),
		from: getReplyMessageFrom(from),
		to: prepareRecipients(
			[message.from],
			from,
			isAutoReplyAllowed(message, from)
		),
		contentHistory: message.contentHistory
	};
}

export function createReplyAllMessage(
	from: ComposeMessageFrom,
	message: Message
): IComposeMessageFormState {
	const to = [];

	// Quick fix
	// We populate recipients with `from` email when it is NOT the default mailbox
	if (
		!from.defaultMailboxAddress ||
		message.from.email !== from.defaultMailboxAddress
	) {
		to.push(message.from);
	}
	return {
		...createReplyMessage(from, message),
		to: prepareRecipients(
			[...to, ...message.to],
			from,
			isAutoReplyAllowed(message, from)
		),
		cc: prepareRecipients(message.cc, from)
	};
}

export function createForwardMessage(
	from: ComposeMessageFrom,
	message: Message
): IComposeMessageFormState {
	return {
		...createEmptyMessage(),
		id: message.id,
		subject: addForwardPrefixToSubject(message.subject),
		from: getReplyMessageFrom(from),
		attachments: message.attachments,
		contentHistory: message.contentHistory
	};
}

export const createReplyLastMessage = createReplyAllMessage;
