import client from 'services/client';

import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import {
	Operation,
	BunkerGrade,
	RetrieveOperationsRequest,
	SearchOperationsRequest
} from 'services/api/operations/operationsServiceTypes';

export const API_URL = '/masterdata/operations';

const retrieveOperations = (
	request: RetrieveOperationsRequest | undefined
): AxiosTypedPromise<ResultCollection<Operation>> =>
	client.get(API_URL, {
		params: request
	});

const retrieveOperationsForAutocomplete = (
	request: SearchOperationsRequest | undefined
): AxiosTypedPromise<ResultCollection<Operation>> =>
	client.get(`${API_URL}/search`, { params: request });

const retrieveBunkerGrades = (): AxiosTypedPromise<
	ResultCollection<BunkerGrade>
> => client.get('/masterdata/bunkergrades');

export default {
	retrieveOperations,
	retrieveOperationsForAutocomplete,
	retrieveBunkerGrades
};
