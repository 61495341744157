import React from 'react';
import { connect } from 'react-redux';
import styles from './JobStatusColumn.module.scss';
import { getUserType } from 'store/auth/selectors';
import { lookupPortJobStatusLabel } from 'store/portJobs/selectors';
import { PortJobStatus } from 'store/portJobs/constants';
import { Severity, severityColorMap, AlertMetricCode } from 'app-constants';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { FilterState } from 'store/filters/filtersState';
import { navigateTo } from 'utils';
import { setFilters } from 'store/filters/actions';
import {
	PORT_CALLS_FILTER_NAME,
	PortCallsFiltersParamEnum
} from 'store/portcalls/filtersSync';
import { Button, Badge } from 'components/antd';
import { ThemeScheme } from 'components/types';
import { AppState } from 'store-types';

interface JobStatusColumnProps {
	portJobStatus: PortJobStatus;
	financeStatus: FinanceStatus;
	setFilters: typeof setFilters;
	code: AlertMetricCode;
	name: string;
	label: string;
	severity: Severity;
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
}

class JobStatusColumn extends React.Component<JobStatusColumnProps> {
	openPortCallsWithFilters = () => {
		const { label, code } = this.props;

		const filters: FilterState = {
			alertSectionCode: [
				{
					key: code,
					label,
					type: PortCallsFiltersParamEnum.ALERT
				}
			]
		};

		navigateTo('/portcalls');
		this.props.setFilters({
			name: PORT_CALLS_FILTER_NAME,
			filters,
			override: true
		});
	};

	render() {
		const { severity, label } = this.props;
		const colorTheme = severityColorMap[severity] as ThemeScheme;
		return (
			<Badge show={!!colorTheme} dot theme={colorTheme} placement="topRight">
				<Button
					type="primary"
					transparent
					className={styles.summaryWidgetBtn}
					onClick={this.openPortCallsWithFilters}
				>
					{label}
				</Button>
			</Badge>
		);
	}
}

export default connect(
	(
		state: AppState,
		props: Pick<
			JobStatusColumnProps,
			| 'portJobStatus'
			| 'financeStatus'
			| 'name'
			| 'queriedToHub'
			| 'queriedToLPA'
		>
	) => {
		const userType = getUserType(state);
		const {
			portJobStatus = '',
			name,
			financeStatus,
			queriedToHub,
			queriedToLPA
		} = props;
		let label = portJobStatus
			? lookupPortJobStatusLabel(
					portJobStatus,
					financeStatus,
					userType,
					queriedToHub,
					queriedToLPA
			  )
			: name;
		if (name === 'Unassigned DA') {
			label = 'DA Unassigned';
		} else if (name === 'Funds Requested From Principal') {
			label = 'Funds requested from Principal';
		}
		return {
			label
		};
	},
	{
		setFilters
	}
)(JobStatusColumn);
