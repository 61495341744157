import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { PermissionRoute } from 'components/Permission';
import * as RouteParams from 'sections/App/RouteParams';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import AddSupplierInvoiceViaPostCreditRoute from './routes/AddSupplierInvoiceViaPostCreditRoute/AddSupplierInvoiceViaPostCreditRoute';
import SupplierInvoicesListRoute from './routes/SupplierInvoicesListRoute/SupplierInvoicesListRoute';
import EditOrViewSupplierInvoiceRoute from './routes/EditOrViewSupplierInvoiceRoute/EditOrViewSupplierInvoiceRoute';
import AddNewSupplierInvoiceRoute from './routes/AddNewSupplierInvoiceRoute/AddNewSupplierInvoiceRoute';

const SupplierInvoiceSectionRoute: React.SFC<RouteComponentProps> = ({
	match: { path }
}) => (
	<Switch>
		<PermissionRoute
			permissionCode={PermissionCode.VIEW_SUPPLIER_INVOICES}
			exact
			path={path}
			component={SupplierInvoicesListRoute}
		/>
		<PermissionRoute
			exact
			permissionCode={PermissionCode.MANAGE_SUPPLIER_INVOICES}
			path={`${path}/documents/${RouteParams.DOCUMENT_ID}/contextualize/:newtab?`}
			component={AddNewSupplierInvoiceRoute}
		/>
		<PermissionRoute
			exact
			permissionCode={PermissionCode.MANAGE_SUPPLIER_INVOICES}
			path={`${path}/documents/${RouteParams.DOCUMENT_ID}/contextualize-via-post-credit/${RouteParams.SUPPLIER_INVOICE_ID}/:newtab?`}
			component={AddSupplierInvoiceViaPostCreditRoute}
		/>
		<PermissionRoute
			exact
			permissionCode={PermissionCode.MANAGE_SUPPLIER_INVOICES}
			path={`${path}/${RouteParams.SUPPLIER_INVOICE_ID}/edit/:newtab?`}
			component={EditOrViewSupplierInvoiceRoute}
		/>
		<PermissionRoute
			exact
			permissionCode={PermissionCode.VIEW_SUPPLIER_INVOICES}
			path={`${path}/${RouteParams.SUPPLIER_INVOICE_ID}/:newtab?`}
			component={EditOrViewSupplierInvoiceRoute}
		/>
	</Switch>
);

export default SupplierInvoiceSectionRoute;
