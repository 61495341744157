import React from 'react';
import classNames from 'classnames';
import { ClickParam } from 'antd/lib/menu';
import { Dropdown as AntDropdown, Menu, Button, Icon } from 'components/antd';
import { defaultStatusMap } from 'components/Status/constants';
import { StatusDropdownBaseProps } from 'components/Status/StatusTypes';
import Label from 'components/Status/Label/Label';
import styles from '../Status.module.scss';
interface DropdownProps extends StatusDropdownBaseProps {
	value: string;
}
interface DropdownState {
	value: string;
	visible: boolean;
}
/**
 * @example
 * 		<Status.Dropdown
 *			 value="Open"
 *			 options={['Queried', 'Resolved']}
 *		 />
 */
class Dropdown extends React.PureComponent<DropdownProps, DropdownState> {
	ref: HTMLDivElement;
	constructor(props: DropdownProps) {
		super(props);
		this.state = {
			value: props.value,
			visible: false
		};
	}

	componentDidUpdate(prevProps: DropdownProps) {
		if (
			this.props.value &&
			this.props.value !== prevProps.value &&
			this.props.value !== this.state.value
		) {
			this.setState({
				value: this.props.value
			});
		}
	}

	onClick = (param: ClickParam) => {
		this.setState({
			value: param.key,
			visible: false
		});
		if (this.props.onChange) {
			this.props.onChange(param.key);
		}
	};

	onVisibleChange = (visible: boolean) => {
		this.setState({ visible });
	};

	setRef = (e: HTMLDivElement | null) => {
		if (e) {
			this.ref = e;
		}
	};

	render() {
		const { visible } = this.state;
		const { options } = this.props;
		const menu = (
			<Menu
				selectedKeys={[this.state.value]}
				onClick={this.onClick}
				className={styles.menu}
			>
				{options.map(option => {
					return (
						<Menu.Item key={option}>
							<Label
								className={styles.dropdownLabel}
								value={option}
								unstyled
								statusDot={defaultStatusMap[option]}
							/>
						</Menu.Item>
					);
				})}
			</Menu>
		);
		return (
			<div
				ref={this.setRef}
				className={classNames(styles.root, styles.dropdown)}
			>
				<AntDropdown
					overlay={menu}
					trigger={['click']}
					placement="bottomRight"
					getPopupContainer={() => this.ref}
					overlayClassName={styles.dropdown}
					onVisibleChange={this.onVisibleChange}
				>
					<Button
						ghost
						type="primary"
						style={{ width: '90px' }} // set by default to the longest text from `defaultStatusMap`
					>
						<Label
							className={styles.dropdownLabel}
							value={this.state.value}
							unstyled
							statusDot={defaultStatusMap[this.state.value]}
						/>
						<Icon type={visible ? 'caret-up' : 'caret-down'} size="md" />
					</Button>
				</AntDropdown>
			</div>
		);
	}
}

export default Dropdown;
