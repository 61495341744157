import { PrincipalService } from 'services/api/services/servicesServiceTypes';
import actionCreator from '../configurationServicesActionCreator';

/**
 * Retrieve principal service for main principal
 */

const ACTION_NAME = 'RETRIEVE_PRINCIPAL_SERVICE';

export const retrievePrincipalServiceAsync = actionCreator.async<
	string,
	PrincipalService,
	Error
>(ACTION_NAME);

export const retrievePrincipalService = actionCreator<string>(ACTION_NAME);
