import actionCreator from '../operationsActionCreator';
import { ResultCollection } from 'services/api/apiTypes';
import { BunkerGrade } from 'services/api/operations/operationsServiceTypes';

const RETRIEVE_BUNKER_GRADES = 'RETRIEVE_BUNKER_GRADES';
export const retrieveBunkerGradesAsync = actionCreator.async<
	null,
	ResultCollection<BunkerGrade>,
	Error
>(RETRIEVE_BUNKER_GRADES);

export const retrieveBunkerGrades = actionCreator(RETRIEVE_BUNKER_GRADES);

export const retrieveBunkerGradesAction = {
	action: retrieveBunkerGrades,
	async: retrieveBunkerGradesAsync
};
