import { Metadata } from 'app-types';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RequiredDocument,
	AllowedDocument,
	UploadedDocument,
	PortCallDocument,
	PortCallDocumentsMetadataActionCode
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

export interface RequiredByIdState {
	[id: string]: RequiredDocument;
}

export interface UploadedByIdState {
	[id: string]: UploadedDocument;
}

export interface PortCallDocumentsState {
	readonly requiredById: RequiredByIdState;
	readonly uploadedById: UploadedByIdState;
	readonly allowedByCode: { [code: string]: AllowedDocument };
	readonly document: PortCallDocument | null;
	readonly metadata: {
		[portCallId: string]: Metadata<PortCallDocumentsMetadataActionCode>;
	};
	readonly fetchStatuses: {
		readonly create: FetchStatus;
		readonly requiredById: FetchStatus;
		readonly uploadedById: FetchStatus;
		readonly allowedByCode: FetchStatus;

		readonly editUploadedById: {
			[id: string]: FetchStatus;
		};
		readonly deleteDocumentById: {
			[id: string]: FetchStatus;
		};
		readonly downloadAll: FetchStatus;
		readonly downloadedById: {
			[id: string]: FetchStatus;
		};
		readonly retrieveDocument: FetchStatus;
		readonly deleteDocumentPageById: {
			[documentId: string]: {
				[pageNumber: string]: FetchStatus;
			};
		};
	};
	readonly error: Error | null;
}

export const initialState: PortCallDocumentsState = {
	requiredById: {},
	uploadedById: {},
	allowedByCode: {},
	document: null,
	metadata: {},
	fetchStatuses: {
		create: FetchStatus.IDLE,
		requiredById: FetchStatus.IDLE,
		uploadedById: FetchStatus.IDLE,
		allowedByCode: FetchStatus.IDLE,
		editUploadedById: {},
		deleteDocumentById: {},
		downloadAll: FetchStatus.IDLE,
		downloadedById: {},
		retrieveDocument: FetchStatus.IDLE,
		deleteDocumentPageById: {}
	},
	error: null
};
