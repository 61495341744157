import { PortJobState } from '../portJobsState';
import { Failure } from 'typescript-fsa';
import { ConfirmPortJobParams } from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onAcceptPortJobFailed = (
	state: PortJobState,
	{ error }: Failure<ConfirmPortJobParams, Error>
) => {
	return {
		...state,
		error,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		}
	};
};
