import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import * as H from 'history';
import history from 'services/history';
import { navigateTo } from 'utils';

import { ScrollableLayout, Flex, PageFooter } from 'components';
import {
	resetThreads,
	retrieveThreads,
	retrieveThreadsCycleStart,
	retrieveThreadsCycleStop,
	resetJobsInNewThread,
	updateThreadsContext,
	resetThreadsContext,
	updateThreadMode,
	updateFullScreenMode,
	retrieveThreadsMainPrincipalTags,
	retrieveThreadsMainPrincipalGroups
} from 'store/threads/actions';
import { resetThread, retrieveThreadEvents } from 'store/thread';
import {
	getThreadsContext,
	getIsPreviewModeEnabled,
	getActiveThreadId,
	makeGetThreadsOrderedByLastMessageTimeSelector
} from 'store/threads/selectors';
import { AppState } from 'store-types';
import { IThread, ThreadItem } from 'services/api/threads/threadsServiceTypes';
import ThreadRowFullView from './ThreadRowFullView/ThreadRowFullView';
import ThreadRowSplitView from './ThreadRowSplitView/ThreadRowSplitView';
import ThreadsList from './ThreadsList';
import ThreadsHeader from './ThreadsHeader';
import ThreadPreview from 'sections/Thread/ThreadPreview';
import { Button, Col, Row } from 'components/antd';
import styles from './Threads.module.scss';
import {
	ThreadsSettingsMenuAction,
	ThreadsSettingsMenuModalMap
} from './ThreadCategorizing/constants';
import { IdField } from 'app-types';
import { Justify } from 'components/types';
import ThreadCategorizeMessagesModal from './ThreadCategorizing/ThreadCategorizingMessagesModal/ThreadCategorizeMessagesModal';
import { openModal } from 'store/modals/actions';
import AssignToModal from './ThreadCategorizing/AssignToModal/AssignToModal';
import { getActiveThread } from 'store/thread/selectors';
import { isEmpty } from 'lodash';

interface ThreadsProps {
	threads: IThread[];
	scrollPosition: number | undefined;
	isPreviewModeEnabled: boolean;
	activeThreadId: string;
	thread: ThreadItem;

	resetThreads: typeof resetThreads;
	retrieveThreads: typeof retrieveThreads;
	retrieveThreadsCycleStart: typeof retrieveThreadsCycleStart;
	retrieveThreadsCycleStop: typeof retrieveThreadsCycleStop;
	resetJobsInNewThread: typeof resetJobsInNewThread;
	updateThreadsContext: typeof updateThreadsContext;
	resetThreadsContext: typeof resetThreadsContext;
	updateThreadMode: typeof updateThreadMode;
	resetThread: typeof resetThread;
	retrieveThreadEvents: typeof retrieveThreadEvents;
	updateFullScreenMode: typeof updateFullScreenMode;
	openModal: typeof openModal;
	retrieveThreadsMainPrincipalTags: typeof retrieveThreadsMainPrincipalTags;
	retrieveThreadsMainPrincipalGroups: typeof retrieveThreadsMainPrincipalGroups;
}

interface ThreadsState {
	menuItem: string | null;
	selectedThreadIds: IdField[];
	mainPrincipalCompanyId: string | null;
	selectedGroup: IThread['assignedToGroup'] | null;
}

class Threads extends Component<ThreadsProps, ThreadsState> {
	public unregister: H.UnregisterCallback;

	scrollPosition: number;

	scroll: ScrollableLayout | null;

	clickCount: number;
	singleClickTimer: number;

	constructor(props: ThreadsProps) {
		super(props);
		this.clickCount = 0;
		this.singleClickTimer = 0;
		this.state = {
			menuItem: null,
			selectedThreadIds: [],
			mainPrincipalCompanyId: null,
			selectedGroup: null
		};
	}

	componentDidMount() {
		window.addEventListener('scroll', this.onScroll, true);

		this.unregister = history.listen(this.onHistoryChanged);
	}

	componentWillUnmount() {
		this.props.resetThreads();
		this.props.retrieveThreadsCycleStop();
		this.props.resetJobsInNewThread();
		if (this.props.isPreviewModeEnabled) {
			this.props.resetThread();
		}
		window.removeEventListener('scroll', this.onScroll, true);
		window.clearTimeout(this.singleClickTimer);

		this.unregister();
	}

	componentDidUpdate(prevProps: ThreadsProps) {
		const { threads } = prevProps;
		if (threads.length === 0 && this.props.threads.length > 0) {
			this.scrollToThread();
		}
		const isThreadAvailble = this.props.threads.find(
			thread => thread.id === this.props.activeThreadId
		);

		if (
			this.props.threads.length > 0 &&
			!isThreadAvailble &&
			this.props.isPreviewModeEnabled
		) {
			navigateTo('/messages/');
			this.navigateToThread(this.props.threads[0].id);
			const el = ReactDOM.findDOMNode(this.scroll) as Element;
			el.scrollTop = 0;
		}
	}

	onHistoryChanged = (location: H.Location) => {
		if (!/\/messages/g.test(location.pathname)) {
			this.props.resetThreadsContext();
		}
	};

	scrollToThread = () => {
		if (this.props.scrollPosition && this.scroll) {
			const el = ReactDOM.findDOMNode(this.scroll) as Element;
			el.scrollTop = this.props.scrollPosition;
		}
	};

	setRef = (el: ScrollableLayout) => {
		this.scroll = el;
	};

	private navigateToThread = (id: string, isDoubleClicked = false) => {
		this.props.resetThread();
		if (isDoubleClicked) {
			this.updateThreadsContext(id);
			this.props.updateFullScreenMode({
				isFullScreenModeEnabled: true
			});
			navigateTo(`/messages/${id}`);
		} else {
			this.updateThreadsContext(id);
			this.props.updateFullScreenMode({
				isFullScreenModeEnabled: false
			});
			this.props.retrieveThreadEvents({
				threadId: id,
				retrieveLatest: true
			});
		}
	};

	private navigateToNewMessage = () => navigateTo(`/new-message`);

	private onLoadThreads = () => {
		this.props.retrieveThreads();
		this.props.retrieveThreadsCycleStart();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onScroll = (event: any) => {
		const scrollTop = event.target.scrollTop;
		if (scrollTop !== this.scrollPosition && event.target === this.scroll) {
			this.scrollPosition = event.target.scrollTop;
		}
	};

	updateThreadsContext = (id: string) => {
		const { threads } = this.props;
		this.props.updateThreadsContext({
			activeThreadId: id,
			threadsLimit: threads.length,
			scrollPosition: this.scrollPosition
		});
	};

	changeMode = (isPreviewModeEnabled: boolean) => {
		this.props.updateThreadMode({ isPreviewModeEnabled });
		if (isPreviewModeEnabled && this.props.threads.length > 0) {
			this.props.resetThreadsContext();
			navigateTo('/messages/');
			this.updateThreadsContext(this.props.threads[0].id);
		} else {
			this.props.resetThread();
		}
	};

	handleClicks = (id: string) => {
		this.clickCount++;
		if (this.clickCount === 1) {
			this.singleClickTimer = window.setTimeout(() => {
				if (id === this.props.activeThreadId) {
					this.clickCount = 0;
					return;
				}
				this.clickCount = 0;
				this.navigateToThread(id, false);
			}, 250);
		} else if (this.clickCount === 2) {
			window.clearTimeout(this.singleClickTimer);
			this.clickCount = 0;
			this.navigateToThread(id, true);
		}
	};

	onMenuItem = (key: ThreadsSettingsMenuAction) => {
		this.setState({
			menuItem: key,
			selectedThreadIds: [],
			mainPrincipalCompanyId: null,
			selectedGroup: null
		});
	};

	onCheckBoxClick = (
		data: string,
		companyId: string,
		group: IThread['assignedToGroup']
	) => {
		if (this.state.selectedThreadIds.find(x => x.id === data)) {
			const newState = this.state.selectedThreadIds.filter(x => x.id !== data);
			this.setState({
				selectedThreadIds: [...newState]
			});
			if (isEmpty(newState)) {
				this.setState({
					mainPrincipalCompanyId: null,
					selectedGroup: null
				});
			}
		} else {
			this.setState({
				selectedThreadIds: [...this.state.selectedThreadIds, { id: data }],
				mainPrincipalCompanyId: companyId,
				selectedGroup: { ...group }
			});
		}
	};

	openSelectedModal = () => {
		if (this.state.mainPrincipalCompanyId && this.state.menuItem) {
			if (
				this.state.menuItem === ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES
			) {
				this.props.retrieveThreadsMainPrincipalTags({
					companyId: this.state.mainPrincipalCompanyId
				});
			} else {
				this.props.retrieveThreadsMainPrincipalGroups({
					companyId: this.state.mainPrincipalCompanyId
				});
			}
			this.props.openModal(ThreadsSettingsMenuModalMap[this.state.menuItem]);
		}
	};

	getCheckBoxDisabledValue = (thread: IThread) => {
		switch (this.state.menuItem) {
			case ThreadsSettingsMenuAction.ASSIGN_TO:
				return this.state.mainPrincipalCompanyId && this.state.selectedGroup
					? thread.company.id !== this.state.mainPrincipalCompanyId ||
							thread.assignedToGroup.id !== this.state.selectedGroup.id
					: false;

			case ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES:
				return this.state.mainPrincipalCompanyId
					? thread.company.id !== this.state.mainPrincipalCompanyId
					: false;

			default:
				return false;
		}
	};

	render() {
		const isEditAssignTo =
			this.state.menuItem === ThreadsSettingsMenuAction.ASSIGN_TO ||
			this.state.menuItem === ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES;
		return (
			<>
				<ThreadsHeader
					{...this.props}
					onNewMessage={this.navigateToNewMessage}
					onModeChange={this.changeMode}
					onMenuClick={this.onMenuItem}
				/>
				{!this.props.isPreviewModeEnabled ? (
					<ScrollableLayout stretch>
						<ThreadsList
							onEnter={this.onLoadThreads}
							customRef={this.setRef}
							actionType={retrieveThreads.type}
						>
							{this.props.threads.map(thread => (
								<ThreadRowFullView
									thread={thread}
									menuItem={this.state.menuItem}
									isThreadIdChecked={this.state.selectedThreadIds.some(
										th => th.id === thread.id
									)}
									isCheckBoxDisabled={this.getCheckBoxDisabledValue(thread)}
									key={thread.id}
									onClick={this.handleClicks}
									onCheckBoxClick={this.onCheckBoxClick}
								/>
							))}
						</ThreadsList>
						{this.state.selectedThreadIds.length > 0 && (
							<PageFooter borderTop>
								<Row>
									<Col xs={12} justify={Justify.END}>
										<Button
											type="primary"
											onClick={this.openSelectedModal}
											disabled={!this.state.selectedThreadIds.length}
										>
											Continue
										</Button>
									</Col>
								</Row>
							</PageFooter>
						)}
					</ScrollableLayout>
				) : (
					<Flex fit className={styles.splitViewWrapper}>
						<Col xs={4} gutter={false} className={styles.threadList}>
							<ScrollableLayout stretch className={styles.threadListScroll}>
								<ThreadsList
									onEnter={this.onLoadThreads}
									customRef={this.setRef}
									actionType={retrieveThreads.type}
								>
									{this.props.threads.map(thread => (
										<ThreadRowSplitView
											thread={thread}
											key={thread.id}
											onClick={this.handleClicks}
											active={this.props.activeThreadId === thread.id}
											menuItem={this.state.menuItem}
											isThreadIdChecked={this.state.selectedThreadIds.some(
												th => th.id === thread.id
											)}
											isCheckBoxDisabled={this.getCheckBoxDisabledValue(thread)}
											onCheckBoxClick={this.onCheckBoxClick}
										/>
									))}
								</ThreadsList>
								{this.state.selectedThreadIds.length > 0 && (
									<PageFooter borderTop>
										<Row>
											<Col xs={12} justify={Justify.END}>
												<Button
													type="primary"
													onClick={this.openSelectedModal}
													disabled={!this.state.selectedThreadIds.length}
												>
													Continue
												</Button>
											</Col>
										</Row>
									</PageFooter>
								)}
							</ScrollableLayout>
						</Col>
						<Col xs={8} gutter={false}>
							{this.props.threads.length > 0 ? (
								<ThreadPreview
									threadId={this.props.activeThreadId}
									isEditAssignTo={isEditAssignTo}
								/>
							) : null}
						</Col>
					</Flex>
				)}
				<AssignToModal
					modalId={
						ThreadsSettingsMenuModalMap[ThreadsSettingsMenuAction.ASSIGN_TO]
					}
					threadIds={this.state.selectedThreadIds}
					assignedTo={{
						group: this.state.selectedGroup || {
							id: '',
							name: '',
							isDeleted: false
						},
						user: {
							id: '',
							name: ''
						}
					}}
				/>
				<ThreadCategorizeMessagesModal
					threadIds={this.state.selectedThreadIds}
					mainPrincipalId={this.state.mainPrincipalCompanyId}
				/>
			</>
		);
	}
}

export default connect(
	() => {
		const getThreadsOrderedByLastMessageTimeSelector = makeGetThreadsOrderedByLastMessageTimeSelector();
		return (state: AppState) => ({
			threads: getThreadsOrderedByLastMessageTimeSelector(state, ['desc']),
			scrollPosition: getThreadsContext(state).scrollPosition,
			isPreviewModeEnabled: getIsPreviewModeEnabled(state),
			activeThreadId: getActiveThreadId(state),
			thread: getActiveThread(state)
		});
	},
	{
		retrieveThreads,
		retrieveThreadsCycleStart,
		retrieveThreadsCycleStop,
		retrieveThreadEvents,
		resetThread,
		resetThreads,
		resetJobsInNewThread,
		updateThreadsContext,
		resetThreadsContext,
		updateThreadMode,
		updateFullScreenMode,
		openModal,
		retrieveThreadsMainPrincipalTags,
		retrieveThreadsMainPrincipalGroups
	}
)(Threads);
