import actionCreator from '../draftsActionCreator';
import { AddCompanyDraftFormData } from 'containers/Drafts/constants';

export interface AddCompanyDraftActionPayload extends AddCompanyDraftFormData {
	id: string;
}

export const addCompanyDraft = actionCreator<AddCompanyDraftActionPayload>(
	'ADD_COMPANY_DRAFT'
);
