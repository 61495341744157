import React from 'react';
import { ErrorPage } from 'components';
import { NotificationDisplayType } from 'store/notifications/notificationsState';
import { useNotifications } from '../Notifications.hooks';

function FullPage() {
	const { list } = useNotifications({
		displayType: NotificationDisplayType.FULL_PAGE
	});
	if (!list.length) {
		return null;
	}
	const item = list[0];
	return (
		<ErrorPage
			description={item.description}
			status={item.error ? item.error.status : undefined}
			correlationId={item.error ? item.error.correlationId : undefined}
		/>
	);
}

export default FullPage;
