import actionCreator from '../portCallDocumentsActionCreator';
import { DeletePortCallDocumentPageRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'DELETE_DOCUMENT_PAGE';

export type DeletePortCallDocumentPagePayload = Pick<
	DeletePortCallDocumentPageRequest,
	'portCallId' | 'documentId' | 'actualNumber' | 'concurrencyToken'
>;

export const deletePortCallDocumentPageAsync = actionCreator.async<
	DeletePortCallDocumentPagePayload,
	null,
	Error
>(ACTION_NAME);

export const deletePortCallDocumentPage = actionCreator<
	DeletePortCallDocumentPagePayload
>(ACTION_NAME);
