import actionCreator from '../permissionsActionCreator';
import {
	RetrievePermissionsRequest,
	RetrievePermissionsResponse
} from 'services/api/permissions/permissionsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PERMISSIONS';

export const retrievePermissionsAsync = actionCreator.async<
	RetrievePermissionsRequest,
	RetrievePermissionsResponse,
	Error
>(ACTION_NAME);

export const retrievePermissions = actionCreator<RetrievePermissionsRequest>(
	ACTION_NAME
);
