import Api from 'services/api';
import { searchEntitiesForAutocomplete } from './Autocomplete.func';
import { SearchUnitsParams } from 'services/api/units/unitsServiceTypes';

export const onUOMSearch = (
	searchTerm?: string,
	otherParams?: SearchUnitsParams
) =>
	searchEntitiesForAutocomplete(
		Api.Units.retrieveUnitsForAutocomplete,
		searchTerm,
		otherParams
	);

export const onCurrencySearch = (searchTerm?: string) =>
	searchEntitiesForAutocomplete(
		Api.Currencies.retrieveCurrenciesForAutocomplete,
		searchTerm
	);
