import { Success, Failure } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallOperationsState } from '../portCallOperationsState';
import { portCallSectionKey } from '../portCallOperationsUtils';
import {
	AddPortCallEventRequest,
	AddPortCallEventResponse,
	PortCallEvent
} from 'services/api/portCall/portCallServiceTypes';

const getAddStatuses = (
	{ addStatuses }: PortCallOperationsState,
	action: AddPortCallEventRequest,
	status: FetchStatus
): PortCallOperationsState['addStatuses'] => ({
	...addStatuses,
	eventTemplates: {
		...addStatuses.eventTemplates,
		[action.eventCode]: status
	}
});

export const onAddPortCallEventStarted = (
	state: PortCallOperationsState,
	params: AddPortCallEventRequest
) => ({
	...state,
	addStatuses: getAddStatuses(state, params, FetchStatus.PENDING)
});

export const onAddPortCallEventSuccess = (
	state: PortCallOperationsState,
	{ params, result }: Success<AddPortCallEventRequest, AddPortCallEventResponse>
): PortCallOperationsState => {
	const { portCallId, parentId, parentCode } = params;
	const { eventSectionsByPortCallId } = state;
	const currentEventSections = eventSectionsByPortCallId[portCallId];
	const sectionKey = portCallSectionKey({ id: parentId, code: parentCode });
	return {
		...state,

		// Add New Event to the section
		eventSectionsByPortCallId: {
			...eventSectionsByPortCallId,
			[portCallId]: {
				...currentEventSections,

				eventsById: {
					...currentEventSections.eventsById,
					...keyBy<PortCallEvent>(
						result.map(event => ({ ...event, rank: 0 })),
						item => item.id
					) // set rank 0 to put it on the bottom
				},

				eventsIdsBySectionId: {
					...currentEventSections.eventsIdsBySectionId,
					[sectionKey]: [
						...(currentEventSections.eventsIdsBySectionId[sectionKey] || []),
						...result.map(event => event.id)
					]
				}
			}
		},
		addStatuses: getAddStatuses(state, params, FetchStatus.SUCCESS)
	};
};

export const onAddPortCallEventFailed = (
	state: PortCallOperationsState,
	action: Failure<AddPortCallEventRequest, Error>
) => ({
	...state,
	addStatuses: getAddStatuses(state, action.params, FetchStatus.FAILURE)
});
