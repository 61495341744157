import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	PortJobBaseRequest,
	AppointData
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveAppointDataSuccess = (
	state: PortJobState,
	{ result }: Success<PortJobBaseRequest, AppointData>
) => ({
	...state,
	appointData: result
});

export const onRetrieveAppointData = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	PortJobBaseRequest,
	AppointData
>('fetchStatuses', 'appointData', {
	doneReducer: onRetrieveAppointDataSuccess
});
