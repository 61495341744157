import { DeveloperPortalState as State } from '../developerPortalState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ApiSubscriptionParams as Request } from 'services/api/developerPortal/developerPortalServiceTypes';

export const onCreateNewApiSubscription = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	null
>('fetchStatuses', 'createNewApiSubscription');
