import { WebhookEndPointDetailsWithLabel } from 'services/api/developerPortal/developerPortalServiceTypes';
import { FormErrors } from 'redux-form';
import runValidations, {
	validateRequired,
	validateURLRequired,
	required,
	charMax,
	charMin
} from 'utils/validations';
import { first } from 'lodash';

export const validate = (
	values: WebhookEndPointDetailsWithLabel
): FormErrors<WebhookEndPointDetailsWithLabel> => ({
	url: validateURLRequired(values.url),
	dataFormat: validateRequired(values.dataFormat),
	eventsWithLabels: validateRequired(values.eventsWithLabels),
	authenticationType: validateRequired(values.authenticationType),
	basicAuthUserName:
		values.authenticationType === 'BasicAuth'
			? first(runValidations(values.basicAuthUserName, [required, charMax(30)]))
			: undefined,
	basicAuthPassword:
		values.authenticationType === 'BasicAuth'
			? first(runValidations(values.basicAuthPassword, [required, charMin(8)]))
			: undefined,
	authKey:
		values.authenticationType === 'AuthKey' && validateRequired(values.authKey),
	authValue:
		values.authenticationType === 'AuthKey' &&
		validateRequired(values.authValue),
	addTo:
		values.authenticationType === 'AuthKey' && validateRequired(values.addTo),
	bearerToken:
		values.authenticationType === 'BearerToken' &&
		validateRequired(values.bearerToken)
});
