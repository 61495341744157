import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { ChangeCustodyRoleRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { changeCustodyRoleAsync as async } from 'store/portJobs/actions';
import {
	getPortJobConcurrencyTokenByCode,
	getActivePortJobCode
} from '../portJobsSelectors';
import { closeModal } from 'store/modals/actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';

const apiCall = Api.PortJobs.changeCustodyRole;

export const executor = function*(
	payload: ChangeCustodyRoleRequest
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		portJobCode
	);
	const request = {
		...payload,
		portCallId,
		portJobCode,
		concurrencyToken
	};
	yield put(async.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			async.done({
				result: undefined,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	} finally {
		yield put(closeModal(payload.modalId));
	}
};

export const changeCustodyRoleWatcher = makeTakeLatestWatcher<
	ChangeCustodyRoleRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async
	},
	executor
);

export default changeCustodyRoleWatcher;
