import * as React from 'react';

import { Loading, Status } from 'components';
import { UserGetResponseStatusCode } from 'services/api/users/userServiceTypes';
import { getTagType } from './UserStatus.func';
import { USER_STATUS_LABEL_MAP } from './userStatusConstants';
import styles from './UserStatus.module.scss';

interface UserStatusProps {
	status: UserGetResponseStatusCode;
	isLoading?: boolean;
}

const UserStatus: React.FC<UserStatusProps> = props => {
	const type = getTagType(props.status);
	if (props.isLoading) {
		return <Loading show={props.isLoading} size="xs" inline />;
	}
	return (
		<Status.Label
			value={USER_STATUS_LABEL_MAP[props.status]}
			statusDot={type}
			className={styles.root}
		/>
	);
};

export default UserStatus;
