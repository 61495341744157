import {
	ApiPermission,
	ApiGroupPermissionList
} from 'services/api/developerPortal/developerPortalServiceTypes';
import { RequestType } from './constants';

export const formInitialValues = (setPermissions: ApiPermission[]) =>
	setPermissions.reduce(
		(formValue, permission) => ({ ...formValue, [permission.id]: true }),
		{}
	);

export const requestType = (code: RequestType) => {
	switch (code) {
		case RequestType.PUT:
			return 'PUT';
		case RequestType.GET:
			return 'GET';
		case RequestType.POST:
			return 'POST';
		case RequestType.DELETE:
			return 'DEL';
		default:
			return null;
	}
};

export const checkCount = (
	rootPermission: ApiGroupPermissionList,
	formValues: object,
	hasParent: boolean
): number =>
	rootPermission.children.reduce(
		(count, permission) => {
			const currentCount = formValues[permission.id] ? count + 1 : count;
			return currentCount;
		},
		hasParent ? 0 : formValues[rootPermission.id] ? 1 : 0
	);

export const getChildrenSize = (
	rootPermission: ApiGroupPermissionList
): number => {
	return rootPermission.children
		? rootPermission.children.reduce(
				(count, item) => count + getChildrenSize(item),
				rootPermission.children.length
		  )
		: 0;
};
