import { Success, Failure } from 'typescript-fsa';
import { GroupsState } from '../groupsState';
import { GroupType } from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrieveGroupTypesStarted = (
	state: GroupsState
): GroupsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.PENDING
	}
});
export const onRetrieveGroupTypesSuccess = (
	state: GroupsState,
	action: Success<{}, GroupType[]>
): GroupsState => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieving: FetchStatus.SUCCESS
		},
		groupTypes: action.result
	};
};
export const onRetrieveGroupTypesFailed = (
	state: GroupsState,
	action: Failure<{}, Error>
): GroupsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		retrieving: FetchStatus.FAILURE
	},
	error: action.error
});
