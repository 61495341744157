import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CompanyVesselTypeState } from '../companyVesselTypesState';
import {
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
import { omit } from 'lodash';

export const onDeleteCompanyVesselType = makeFetchStatusReducers<
	CompanyVesselTypeState,
	'fetchStatuses',
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse
>('fetchStatuses', 'delete', {
	doneReducer: (state, { params: { id } }) => ({
		...state,
		byId: omit(state.byId, id)
	})
});
