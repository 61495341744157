import {
	DeleteUnitCostForm,
	DeleteUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const DELETE_UNIT_COST_FORM = 'DELETE_UNIT_COST_FORM';
export const deleteUnitCostFormAsync = actionCreator.async<
	DeleteUnitCostForm,
	DeleteUnitCostFormResponse,
	Error
>(DELETE_UNIT_COST_FORM);

export const deleteUnitCostForm = actionCreator<{
	jobServiceId: string;
	id: string;
	vendorAmountChange: boolean;
}>(DELETE_UNIT_COST_FORM);
