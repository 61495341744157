import React, { lazy } from 'react';
import {
	createEmptyMessage,
	createForwardMessage,
	createReplyAllMessage,
	createReplyMessage,
	createReplyLastMessage
} from './utils/ComposeMessageUtils';
import { navigateTo } from 'utils';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import {
	MessageAction,
	Message
} from 'services/api/messages/messagesServiceTypes';
import getContentHistoryHeader from 'sections/ComposeMessage/utils/getContentHistoryHeader';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { Fit } from 'components';
import { AppNotifications } from 'containers';
import { ComposeMessageFrom } from './ComposeMessageTypes';
import { BreadcrumbItem } from 'store/navigation';

const ComposeMessage = lazy(() => import('./ComposeMessage'));

interface ComposeMessageWrapperProps {
	prevPath: string;
	message: Message | null;
	thread: ThreadItem;
	messageFrom: ComposeMessageFrom;
	isNewTab: boolean;
	action: MessageAction;
	breadcrumbs: BreadcrumbItem[];
	isMessageHeaderCollapsible?: boolean;
	isPortCallMessage?: boolean;
}

class ComposeMessageWrapper extends React.Component<
	ComposeMessageWrapperProps
> {
	getFromInfo = (thread: ThreadItem) => {
		return {
			...this.props.messageFrom,
			defaultMailboxAddress: thread.defaultMailboxAddress
		};
	};

	getInitialValues() {
		const { thread, message, action } = this.props;
		const initialMessage = createEmptyMessage();
		// make sure thread is loaded prior to message creation to make sure from is set
		if (message && thread) {
			const from = this.getFromInfo(thread);
			switch (action) {
				case MessageAction.REPLY:
					return createReplyMessage(from, message);
				case MessageAction.REPLY_ALL:
					return createReplyAllMessage(from, message);
				case MessageAction.FORWARD:
					return createForwardMessage(from, message);
				case MessageAction.REPLY_LAST:
					return createReplyLastMessage(from, message);
				default:
					return initialMessage;
			}
		}
		return initialMessage;
	}

	goToThread = () => {
		const { thread, prevPath, isNewTab } = this.props;
		if (thread) {
			isNewTab ? window.close() : navigateTo(prevPath);
		}
	};

	getHistoryHeader = () => {
		const { thread, message } = this.props;
		let header = '';
		if (message && thread) {
			const from = this.getFromInfo(thread);
			header = getContentHistoryHeader(from, message);
		}
		return header;
	};

	render() {
		const { thread } = this.props;
		const initialValues = this.getInitialValues();
		const historyHeader = this.getHistoryHeader();
		return (
			<Fit>
				<AppNotifications.Notification />
				<RouteBreadcrumbs items={this.props.breadcrumbs} />
				<ComposeMessage
					isNewTab={this.props.isNewTab}
					initialValues={initialValues}
					historyHeader={historyHeader}
					thread={thread}
					threadId={this.props.thread.id}
					collapsed={false}
					onClose={this.goToThread}
					isMessageHeaderCollapsible={this.props.isMessageHeaderCollapsible}
					isPortCallMessage={this.props.isPortCallMessage}
				/>
			</Fit>
		);
	}
}

export default ComposeMessageWrapper;
