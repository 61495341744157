import actionCreator from '../templatesActionCreator';

import { UpdateCompanyTemplatesRequest } from 'services/api/templates/templatesServiceTypes';

const ACTION_NAME = 'UPDATE_COMPANY_TEMPLATES';
export const updateCompanyTemplates = actionCreator<
	UpdateCompanyTemplatesRequest
>(ACTION_NAME);
export const updateCompanyTemplatesAsync = actionCreator.async<
	UpdateCompanyTemplatesRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Template'
	}
});
