import React from 'react';
import { useSelector } from 'react-redux';
import { Aside, ScrollableLayout, Loading, Gap } from 'components';
import { Alert } from 'components/antd';
import DocumentViewerAsideFooter from './DocumentViewerAsideFooter';
import {
	DocumentViewerAndAsideCommonProps,
	PaginationState,
	DocumentViewerPaginationProps
} from '../DocumentViewerTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import classNames from 'classnames';
import styles from '../DocumentViewer.module.scss';

interface DocumentViewerAsideProps
	extends DocumentViewerAndAsideCommonProps,
		Partial<DocumentViewerPaginationProps> {
	onCancel?: () => void;
}

const DocumentViewerAside: React.SFC<DocumentViewerAsideProps> = props => {
	const { children, hint, loading, ...footerProps } = props;
	const portCallId = useSelector(getActivePortCallId);

	return (
		<Aside
			size="lg"
			type="primary"
			scrollable
			className={classNames({ [styles.asideOverlay]: loading })}
		>
			{Boolean(hint) && (
				<Aside.Header>
					<Alert type="info" message={hint} showIcon />
				</Aside.Header>
			)}
			<ScrollableLayout asColumn>
				<Gap top isBlock>
					{/**
					 * Proceed with rendering only when
					 * 1) Pagination (Wizard) exist
					 * 2) Wizard form state has been initialized with at least 1 page
					 */
					footerProps.pagination !== PaginationState.NONE &&
					!footerProps.total ? (
						<Loading />
					) : loading ? (
						<Loading />
					) : (
						children
					)}
				</Gap>
			</ScrollableLayout>
			<Aside.Footer>
				<DocumentViewerAsideFooter
					{...footerProps}
					loading={loading}
					portCallId={portCallId}
				/>
			</Aside.Footer>
		</Aside>
	);
};

export default DocumentViewerAside;
