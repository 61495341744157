import { AnyAction } from 'redux';
import { isArray } from 'lodash';
import { ActionFailure, ActionSuccess } from 'app-types';
import { isResponseError, isConnectionError } from 'services/api/apiErrorUtils';
import { ApiMiddlewareHandleType, HandleApiArg } from './apiMiddlewareTypes';
import { isActionAsyncDone, isActionAsyncFailure } from 'store/utils';
import {
	getNotificationDisplayTypeConfigByActionType,
	getNotificationAddonsByActionType
} from 'store/notifications/selectors';
import { AppState, Action } from 'store-types';

export const getApiMiddlewareHandleType = (
	action: AnyAction,
	state?: AppState
) => {
	// Do not throw other error notifications than connection related
	if (!navigator.onLine) {
		return ApiMiddlewareHandleType.CONNECTION;
	}
	return getHandleType(action, state);
};

export const getHandleType = (
	action: AnyAction,
	state?: AppState
): ApiMiddlewareHandleType | undefined => {
	if (isActionAsyncDone(action) && !isActionAsyncDoneIgnored(action)) {
		return ApiMiddlewareHandleType.NOTIFICATION;
	}
	if (isActionAsyncFailure(action)) {
		if (isConnectionError(action.payload.error)) {
			return ApiMiddlewareHandleType.CONNECTION;
		}
		if (isActionAsyncFailureTypeSubmit(action)) {
			return ApiMiddlewareHandleType.SUBMIT;
		}
		if (isActionAsyncFailureOther(action)) {
			return ApiMiddlewareHandleType.OTHER;
		}

		const { meta = {} } = action;

		const isBehaviourApplicable =
			meta.behaviour?.type === 'section'
				? !meta.behaviour?.infiniteLoad ||
				  Boolean(
						state &&
							!getNotificationAddonsByActionType(state, action.type)?.loaded
				  )
				: true;

		// NOTE: supporting existing logic
		const isDisplayTypeSetDynamically = Boolean(
			state && getNotificationDisplayTypeConfigByActionType(state, action.type)
		);

		if (
			(!isActionAsyncFailureIgnored(action) || isDisplayTypeSetDynamically) &&
			isBehaviourApplicable
		) {
			return ApiMiddlewareHandleType.NOTIFICATION;
		}
	}
	return;
};

export const isActionAsyncDoneIgnored = (action: ActionSuccess): boolean => {
	const { meta = {} } = action;
	return (
		Boolean(meta.done && meta.done.omit === true) ||
		meta.behaviour?.type === 'self-submit'
	);
};

export const isActionAsyncFailureIgnored = (action: ActionFailure): boolean => {
	const {
		payload: { error },
		meta = {}
	} = action;

	const { failed = {}, behaviour } = meta;

	let shouldOmit =
		failed.omit === true || behaviour?.type === 'react-to-changes-child';

	if (isResponseError(error)) {
		// specific status
		if (
			isArray(failed.omit) &&
			failed.omit.some(status => status === error.response.status)
		) {
			shouldOmit = true;
		}
	}

	return shouldOmit;
};

export const isActionAsyncFailureTypeSubmit = (action: ActionFailure) => {
	return action.meta?.behaviour?.type === 'submit';
};

export const isActionAsyncFailureOther = (action: ActionFailure) => {
	return action.meta?.failed?.returnUrl;
};

export const createApiMiddlewareTypeHandler = <
	T = Action | Action[] | undefined
>(
	type: ApiMiddlewareHandleType | undefined,
	handler: (args: HandleApiArg) => T
) => {
	return (args: HandleApiArg) => {
		const handleType = args.handleType || getHandleType(args.action);
		if (handleType === type) {
			return handler(args);
		}
		return;
	};
};
