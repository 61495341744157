import { isString } from 'lodash';
import actionCreator from '../notificationsActionCreator';
import {
	NotificationDisplayType,
	Notification,
	NotificationVariant,
	NotificationsDisplayTypeConfig,
	NotificationVariantItem,
	NotificationAddons
} from '../notificationsState';

export const addNotification = actionCreator<NotificationVariantItem>(
	'ADD_NOTIFICATION'
);
export const removeNotification = actionCreator<string>('REMOVE_NOTIFICATION');
export const confirmNotification = actionCreator<string>(
	'CONFIRM_NOTIFICATION'
);
export const resetNotifications = actionCreator<NotificationDisplayType>(
	'RESET_NOTIFICATIONS'
);
export const resetAllNotifications = actionCreator('RESET_ALL_NOTIFICATIONS');
export const setNotificationActionTypeToDisplayType = actionCreator<
	NotificationsDisplayTypeConfig
>('SET_NOTIFICATION_DISPLAY_TYPE');
export const unsetNotificationActionTypeToDisplayType = actionCreator<string[]>(
	'UNSET_NOTIFICATION_DISPLAY_TYPE'
);

export const setNotificationAddons = actionCreator<NotificationAddons>(
	'SET_NOTIFICATION_ADDONS'
);
export const resetNotificationAddons = actionCreator(
	'RESET_NOTIFICATION_ADDONS'
);

export type AddNotificationPayload = Notification | string;

export const setNotificationActionTypeToNotification = (
	actionType: string[]
) => {
	const config: NotificationsDisplayTypeConfig = {};

	actionType.forEach(
		type => (config[type] = NotificationDisplayType.NOTIFICATION)
	);

	return setNotificationActionTypeToDisplayType(config);
};

export const fullPage = createNotificationVariant({
	displayType: NotificationDisplayType.FULL_PAGE,
	type: 'error'
});

export const notify = {
	success: createNotificationVariant({
		displayType: NotificationDisplayType.NOTIFICATION,
		duration: 5,
		type: 'success'
	}),
	warning: createNotificationVariant({
		displayType: NotificationDisplayType.NOTIFICATION,
		duration: 5,
		type: 'warning'
	}),
	info: createNotificationVariant({
		displayType: NotificationDisplayType.NOTIFICATION,
		duration: 5,
		type: 'info'
	}),
	error: createNotificationVariant({
		displayType: NotificationDisplayType.NOTIFICATION,
		duration: 5,
		type: 'error'
	})
};

export const alert = {
	success: createNotificationVariant({
		displayType: NotificationDisplayType.ALERT,
		type: 'success'
	}),
	warning: createNotificationVariant({
		displayType: NotificationDisplayType.ALERT,
		type: 'warning'
	}),
	info: createNotificationVariant({
		displayType: NotificationDisplayType.ALERT,
		type: 'info'
	}),
	error: createNotificationVariant({
		displayType: NotificationDisplayType.ALERT,
		type: 'error'
	})
};

function createNotificationVariant(variant: NotificationVariant) {
	return (notification: AddNotificationPayload) => {
		const initialResult: NotificationVariantItem = {
			description: '',
			...variant
		};

		if (isString(notification)) {
			return addNotification({
				...initialResult,
				description: notification
			});
		}

		return addNotification({
			...initialResult,
			...notification
		});
	};
}
