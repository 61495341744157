import actionCreatorFactory from 'typescript-fsa';
import {
	RetrieveGroupTypesRequest,
	GroupType
} from 'services/api/groups/groupsServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_GROUP_TYPES = 'companies/RETRIEVE_GROUP_TYPES';
export const retrieveGroupTypesAsync = actionCreator.async<
	RetrieveGroupTypesRequest,
	GroupType[],
	Error
>(RETRIEVE_GROUP_TYPES);
export const retrieveGroupTypes = actionCreator<RetrieveGroupTypesRequest>(
	retrieveGroupTypesAsync.type
);
