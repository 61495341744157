import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { updatePortCallEventFromFinanceAsync } from '../actions';
import { UpdatePortCallEventFromFinancePayload } from '../actions/updatePortCallEventFromFinance';
import { getActivePortCallId } from '../../portcalls/selectors';
import Api from 'services/api';
import Matomo from 'services/analytics/adapters/Matomo';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { retrieveFinance } from 'store/finance';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { retrievePortCall } from 'store/portcalls';

export function* updatePortCallEventFromFinanceWorker({
	payload
}: Action<UpdatePortCallEventFromFinancePayload>): SagaIterator {
	yield put(updatePortCallEventFromFinanceAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);

	const updateRequest = { ...payload, portCallId };
	try {
		const response = yield call(Api.PortCall.updatePortCallEvent, {
			...updateRequest,
			concurrencyToken: payload.concurrencyToken
		});

		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS],
				isSilent: true
			})
		);
		yield put(
			retrievePortCall({
				id: portCallId,
				expand: 'PortJobsBasic',
				isSilent: true
			})
		);

		yield put(
			updatePortCallEventFromFinanceAsync.done({
				result: response.data,
				params: updateRequest,
				response
			})
		);
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Events.SetEventData',
			name: `Changed event ${payload.eventId} date for port call ${portCallId}`,
			value: 1
		});
	} catch (error) {
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
		yield put(
			updatePortCallEventFromFinanceAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* updatePortCallEventFromFinanceWatcher(): SagaIterator {
	yield takeLatest(
		updatePortCallEventFromFinanceAsync.type,
		updatePortCallEventFromFinanceWorker
	);
}
