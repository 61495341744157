import React from 'react';
import { connect } from 'react-redux';

import { ScrollableLayout } from 'components';
import { DataFetcher } from 'utils/components';
import { FetchStatus } from 'services/api/apiTypes';

import MessagesThreadHeader from '../MessagesThreadHeader/MessagesThreadHeader';
import MessagesThread from './MessagesThread';
import MessagesSettings from '../MessagesSettings/MessagesSettings';
import ThreadContext from 'sections/Thread/ThreadContext';
import { retrieveThreadById } from 'store/thread';

import { getThreadFetchStatus, getActiveThread } from 'store/thread/selectors';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { AppState } from 'store-types';

interface MessagesThreadContainerProps {
	fetchStatus: FetchStatus;
	activeThreadId: string;
	activeThread: ThreadItem;
	retrieveThreadById: typeof retrieveThreadById;
}
const MessagesThreadContainer = (props: MessagesThreadContainerProps) => {
	const { fetchStatus, activeThreadId, activeThread } = props;

	return (
		<DataFetcher
			dispatch={() => props.retrieveThreadById({ threadId: activeThreadId })}
			fetchStatus={fetchStatus}
			data={activeThread}
			skipIfHasData
		>
			{() => (
				<ThreadContext.Consumer>
					{({ isReadOnly }) => (
						<ScrollableLayout direction="horizontal">
							<MessagesThreadHeader
								threadId={activeThreadId}
								isReadOnly={isReadOnly}
							/>
							<ScrollableLayout>
								<ScrollableLayout>
									<MessagesThread
										threadId={activeThreadId}
										isReadOnly={isReadOnly}
									/>
								</ScrollableLayout>
								{!isReadOnly && <MessagesSettings threadId={activeThreadId} />}
							</ScrollableLayout>
						</ScrollableLayout>
					)}
				</ThreadContext.Consumer>
			)}
		</DataFetcher>
	);
};

export default connect(
	(state: AppState) => ({
		fetchStatus: getThreadFetchStatus(state),
		activeThread: getActiveThread(state)
	}),
	{
		retrieveThreadById
	}
)(MessagesThreadContainer);
