import { ThreadsState } from '../threadsState';

export const onUnassignJobFromNewThread = (
	state: ThreadsState,
	jobCode: string
): ThreadsState => ({
	...state,
	jobCodesForNewThread: state.jobCodesForNewThread.filter(
		code => code !== jobCode
	)
});
