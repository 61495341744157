import React, { Component } from 'react';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL } from './CategorizeMsgModalFormTypes';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { resetThreadsGroupsAndTags } from 'store/threads/actions';
import {
	ThreadsSettingsMenuAction,
	ThreadsSettingsMenuModalMap
} from '../constants';

interface ModalProps {
	isVisible: boolean;
	closeModal: typeof closeModal;
	resetThreadsGroupsAndTags: typeof resetThreadsGroupsAndTags;
}

class CategorizeMsgClosePopUpModal extends Component<ModalProps> {
	closeModal = () => {
		this.props.closeModal(CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL);
		this.props.closeModal(
			ThreadsSettingsMenuModalMap[ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES]
		);
		this.props.resetThreadsGroupsAndTags();
	};

	hideConfirmationModal = () => {
		this.props.closeModal(CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL);
	};

	render() {
		const { isVisible } = this.props;
		return (
			<Modal
				visible={isVisible}
				cancelText={'No'}
				okText={'Yes'}
				onOk={this.closeModal}
				onCancel={this.hideConfirmationModal}
			>
				All entered information will be lost. Are you sure you want to cancel
				the process?
			</Modal>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isVisible: isModalVisible(state, CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL)
	}),
	{
		closeModal,
		resetThreadsGroupsAndTags
	}
)(CategorizeMsgClosePopUpModal);
