import * as React from 'react';
import { connect } from 'react-redux';
import { Grid, Layout } from 'components/antd';
import styles from './AppLayout.module.scss';
import TopNavBar from './TopNavBar/TopNavBar';
import AppSidebar from './AppSidebar/AppSidebar';
import { isUserAuthenticated } from 'store/auth/selectors';
import { AppState } from 'store-types';

interface IAppLayoutProps {
	isLoggedIn: boolean;
	onlyChildren?: boolean;
}

interface IAppLayoutState {
	isSidebarOpened: boolean;
}

const AppLayoutContent: React.SFC = ({ children }) => (
	<div className={styles.root}>
		<Grid fluid className={styles.grid}>
			{children}
		</Grid>
	</div>
);

class AppLayout extends React.Component<IAppLayoutProps, IAppLayoutState> {
	constructor(props: IAppLayoutProps) {
		super(props);
		this.state = {
			isSidebarOpened: false
		};
	}

	onSetSidebarOpen = (isSidebarOpened: boolean) => {
		this.setState({
			isSidebarOpened
		});
	};

	onToggleSidebar = () => {
		this.setState((state: IAppLayoutState) => ({
			isSidebarOpened: !state.isSidebarOpened
		}));
	};

	render() {
		const { isLoggedIn, children, onlyChildren } = this.props;

		const content = <AppLayoutContent>{children}</AppLayoutContent>;

		if (onlyChildren) {
			return content;
		}
		return (
			<AppSidebar
				open={this.state.isSidebarOpened}
				onSetOpen={this.onSetSidebarOpen}
			>
				<Layout>
					<TopNavBar
						showLogo
						isLoggedIn={isLoggedIn}
						onToggleSidebar={this.onToggleSidebar}
					/>
					{content}
				</Layout>
			</AppSidebar>
		);
	}
}

export default connect((state: AppState) => ({
	isLoggedIn: isUserAuthenticated(state)
}))(AppLayout);
