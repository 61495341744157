import * as React from 'react';
import { Text } from 'components';
import { Icon, Popover } from 'components/antd';
import styles from './TermsPopover.module.scss';

interface TermsPopoverProps {
	terms: string;
}

export default ({ terms }: TermsPopoverProps) => (
	<Popover
		content={terms}
		placement="topRight"
		overlayClassName={styles.overlay}
	>
		<Text decoration="underline">View</Text>
		<Icon type="info" color="primary" offset="left" />
	</Popover>
);
