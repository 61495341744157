import { put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';

import { InviteUserRequest, User } from 'services/api/users/userServiceTypes';
import {
	inviteUserAsync,
	InviteUserRequestPayload
} from '../actions/inviteUser';
import { resetUsers } from '../actions';

export default makeTakeLatestWatcher<
	InviteUserRequestPayload,
	User,
	Error,
	InviteUserRequest,
	{ email: string }
>({
	api: Api.Users.sendUserInvitation,
	async: inviteUserAsync,
	*getApiParams(params) {
		return params.requestParams;
	},
	*getMetaTemplateParams({ params }) {
		return {
			email: params.requestParams.email
		};
	},
	*onSuccess() {
		// reset the list in order to refresh the list
		yield put(resetUsers());
	}
});
