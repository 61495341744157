import { omit } from 'lodash';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ServicesState } from '../servicesState';
import { DeleteServiceCategoryRequest } from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';

const doneReducer = (
	state: ServicesState,
	{ params: { id } }: Success<DeleteServiceCategoryRequest, null>
): ServicesState => ({
	...state,
	serviceCategories: omit(state.serviceCategories, id)
});

export const onDeleteServiceCategory = makeFetchStatusReducers<
	ServicesState,
	'fetchStatuses',
	DeleteServiceCategoryRequest,
	null
>('fetchStatuses', 'deleteServiceCategory', {
	doneReducer
});
