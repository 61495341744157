import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Flex } from 'components';
import { Justify } from 'components/types';
import { Row, Col, InputNumber, Button } from 'components/antd';
import {
	FORM,
	TOTAL_AMOUNT_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { getValues } from 'store/form/selectors';
import {
	getIsTotalAmountLineFormInEditMode,
	getTotalAmountLineInitialValues
} from 'store/portJobs/selectors';
import { Currency } from 'services/api/currencies/currenciesServiceTypes';
import { TotalAmountFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import TotalAmountFormFieldName from './TotalAmountFormFieldNames';
import { closeModal } from 'store/modals/actions';
import { setEditableTotalAmountId } from 'store/portCall/actions';
import validate from './validate';
import { AppState } from 'store-types';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import { onCurrencySearch } from 'containers/Autocomplete/AutocompleteEntity.func';
import { getCurrencyByCodeForAutocomplete } from 'store/currencies/currenciesSelectors';
import { optionalEntityCodeToLabeledValue } from 'sections/PortJob/CreatePortJob/Pages/Page2/PagePartials/Form/Form.func';
import { LabeledValue } from 'app-types';

export interface TotalAmountFormBaseProps {
	currencies: Currency[];
	onSubmit: (values: {}) => void;
	onCancel: () => void;
	// from mapStateToProps
	formValues: TotalAmountFormData;
	initialValues: Partial<TotalAmountFormData>;
	isEditMode: boolean;
	currencyInitialValue: Currency | undefined;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	setEditableTotalAmountId: typeof setEditableTotalAmountId;
}

interface TotalAmountFormProps
	extends InjectedFormProps<TotalAmountFormData, TotalAmountFormBaseProps>,
		TotalAmountFormBaseProps {}

class TotalAmountForm extends React.PureComponent<TotalAmountFormProps> {
	componentDidUpdate(prevProps: TotalAmountFormProps) {
		const { currencyInitialValue } = this.props;
		if (
			prevProps.currencyInitialValue === undefined &&
			currencyInitialValue !== undefined
		) {
			// setting an initial value for Autocomplete field
			// (API responds with "string" value, need a LabeledValue there)
			this.props.change(
				'currencyCode',
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				optionalEntityCodeToLabeledValue(currencyInitialValue) as any
			);
		}
	}

	onSubmit = (values: TotalAmountFormData & { currencyCode: LabeledValue }) => {
		this.props.onSubmit({
			...values,
			currencyCode: values.currencyCode.key
		});
		this.props.setEditableTotalAmountId(undefined);
		this.props.closeModal(TOTAL_AMOUNT_MODAL);
	};

	render() {
		const { onCancel, valid, isEditMode } = this.props;
		return (
			<form onSubmit={this.props.handleSubmit(this.onSubmit)}>
				<Row>
					<Col xs={6}>
						<InputNumber.ReduxFormItem
							name={TotalAmountFormFieldName.AMOUNT}
							label="Amount"
							decimalPart={6}
							integerPart={13}
							withDelimiter={false}
							required
							help
						/>
					</Col>
					<Col xs={6}>
						<AutocompleteEntity
							name={TotalAmountFormFieldName.CURRENCY_CODE}
							label="Currency"
							getPopupContainer={() => document.body}
							onSearch={onCurrencySearch}
							shouldSearchAllOptionsOnFocus
							minLength={1}
							required
							help
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button type="primary" transparent onClick={onCancel}>
								Cancel
							</Button>
							<Button type="primary" htmlType="submit" disabled={!valid}>
								{isEditMode ? 'Save Details' : 'Add Line'}
							</Button>
						</Flex>
					</Col>
				</Row>
			</form>
		);
	}
}

export default connect(
	(state: AppState) => {
		const formValues = getValues<TotalAmountFormData>(
			state,
			FORM.totalAmountLine
		);
		const currencyInitialValue = formValues.currencyCode
			? getCurrencyByCodeForAutocomplete(state, formValues.currencyCode)
			: undefined;
		return {
			formValues,
			initialValues: getTotalAmountLineInitialValues(state),
			isEditMode: getIsTotalAmountLineFormInEditMode(state),
			currencyInitialValue
		};
	},
	{
		closeModal,
		setEditableTotalAmountId
	}
)(
	reduxForm<TotalAmountFormData, TotalAmountFormBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.totalAmountLine,
		validate,
		forceUnregisterOnUnmount: true
	})(TotalAmountForm)
);
