import {
	MetadataDisabledReasonCode,
	MetadataConfirmationMessageCode
} from 'app-constants';

export const disableReasonMap = {
	[MetadataDisabledReasonCode.FINANCE_ALREADY_SUBMITTED_BY_USER_ON_DIFFERENT_LEVEL]: `You can't submit Finance as you have already conducted submit on the different approval level`,

	[MetadataDisabledReasonCode.LAST_DA_IN_DA_SET]: `The last DA of the last DA set can't be deleted`,
	[MetadataDisabledReasonCode.DA_HAS_SERVICES]: `DA can't be deleted as it has services assigned to it`,
	[MetadataDisabledReasonCode.DA_SET_IS_PENDING]: `DA set is currently being reversed in GL`,
	[MetadataDisabledReasonCode.ANOTHER_DA_SET_IS_PENDING]: `Another DA set is being reverted at the moment. Please wait until this process is done`,
	[MetadataDisabledReasonCode.DA_SET_IS_DONE]: `DA set can't be reopened now as there's some work being done on it. Please try later.`,
	[MetadataDisabledReasonCode.NO_AVAILABLE_SERVICES]: `There are no available services you can add to this section`,

	[MetadataDisabledReasonCode.SPLIT_DISABLED_HAS_CREDIT_SERVICE]: `You can't split service if credit supplier invoice is posted for this service`,
	[MetadataDisabledReasonCode.SPLIT_DISABLED_TRANSACTION_NOT_COMPLETED_OR_INVOICE_ASSIGNED_TO_LOCKED_DA_SET]: `You can't split the service under current conditions (either another post, split or reverse split is in progress or some services are assigned to locked DAs)`,

	[MetadataDisabledReasonCode.REVERSE_SPLIT_DISABLED_HAS_CREDIT_SERVICE]: `You can't reverse the split supplier invoice transaction if credit supplier invoice is posted for this service`,
	[MetadataDisabledReasonCode.REVERSE_SPLIT_DISABLED_TRANSACTION_NOT_COMPLETED_OR_INVOICE_ASSIGNED_TO_LOCKED_DA_SET]: `You can't reverse the split supplier invoice transaction (either split is in progress or some services are assigned to locked DAs)`,

	[MetadataDisabledReasonCode.CONVERT_TO_REGULAR_DISABLED]: `You can't convert it to regular service since there is no DA IN it can be assigned to`,
	[MetadataDisabledReasonCode.CONVERT_TO_REGULAR_DISABLED_BY_SUPPLIER_INVOICE]: `Can't be converted to regular one as there is a supplier invoice associated to it`,
	[MetadataDisabledReasonCode.CONVERT_TO_DIRECT_BILL_DISABLED]: `Service can't be converted without DA OUT assignment`,
	[MetadataDisabledReasonCode.SUPPLIER_INVOICE_CANNOT_BE_EDITED]: `Supplier invoice can't be edited in current status`,
	[MetadataDisabledReasonCode.SERVICE_CANNOT_BE_ASSOCIATED_TO_INVOICE_IN_CURRENT_STAGE]: `Service can't be associated with the supplier invoice in current stage of Finance`,

	[MetadataDisabledReasonCode.SUPPLIER_INVOICE_CONTEXTUALIZE_IN_APPROVAL_PROCESS]: `Service can't be associated with the supplier invoice since it's being approved by the Principal at the moment`,
	// last 3 words of below message are covered in InvoiceDetailsPortJobService since we need to wrap them with <Link />
	[MetadataDisabledReasonCode.SUPPLIER_INVOICE_CONTEXTUALIZE_HAS_ASSOCIATED_SUPPLIER_INVOICE]: `Service can't be associated with the supplier invoice because it has been already associated with `,

	[MetadataDisabledReasonCode.SUPPLIER_INVOICE_CANNOT_BE_CANCELED]: `You either don't have permission to perform this action or current status of the credit supplier service doesn't allow cancellation`,
	[MetadataDisabledReasonCode.DA_IN_ADVANCE_PAYMENT_ACCOIATED_TO_IT]: `DA IN can't be removed as it has advance payment associated to it`,
	[MetadataDisabledReasonCode.DA_IN_SETTLEMENT_PAYMENT_ACCOIATED_TO_IT]: `DA IN can't be removed as it has settlement payment associated to it`,

	[MetadataDisabledReasonCode.CANCEL_HUB_REVENUE_CREDIT_NOTE]: `You either don't have permission to perform this action or transaction can't be cancelled at this stage`,
	[MetadataDisabledReasonCode.ADD_CREDIT_REVENUE_SERVICE]: `You don't have permission to perform this action`,

	[MetadataDisabledReasonCode.CANCEL_HUB_REVENUE_SERVICE]: `You can't cancel revenue transaction until at least one related credit revenue service exists`,

	[MetadataDisabledReasonCode.CREATE_CREDIT_SUPPLIER_INVOICE_BASED_ON_REGULAR_ONE]: `You can't post credit supplier invoice at this stage`,
	[MetadataDisabledReasonCode.DELETE_DA_OUT_WITH_ADVANCED_PAYMENT]: `You can't submit finance since there is an empty DA with funds in associated to it`,

	[MetadataDisabledReasonCode.DELETE_DA_OUT_SERVICE_CANNOT_BE_ASSIGNED_TO_DA_IN_AND_DA_OUT]: `DA OUT can't be removed for this service`,

	[MetadataDisabledReasonCode.DELETE_PORTJOB_OPERATION_HAS_COST_ASSOCIATION]: `You can't delete the operation as it has service(s), associated to it`,
	[MetadataDisabledReasonCode.DELETE_PORTJOB_OPERATION_HAS_RECORDED_EVENTS_OR_DOCUMENT_ASSIGNMENTS]: `Operation can't be deleted as it has real documents or recorded events associated to it`,

	[MetadataDisabledReasonCode.CONVERT_TO_DIRECT_BILL_AMOUNT_IS_LESS_THAN_ZERO]: `You cannot convert service with negative amount into direct bill service`,
	[MetadataDisabledReasonCode.REFRESH_BENCHMARK_NO_PERMISSION_OR_NO_VALID_OPERATION]: `You don't have permissions to perform this action or job configuration doesn't support benchmarks (no valid operation) or finance is in Approved status`
};

export const confirmationFinanceReasonMap = {
	// TODO: Remove this temp code and label after implementation first real confirmation label
	[MetadataConfirmationMessageCode.TEMPORARY]:
		'We need this code and label only fo tests.'
};
