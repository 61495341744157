import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Error503 } from 'components';
import { startPollingSystemStatus } from 'store/common/actions';
import {
	getIsSystemUnderMaintenance,
	getIsSystemStatusVerified
} from 'store/common/selectors';
import { SystemStatusType } from 'store/common/commonTypes';
import { AppState } from 'store-types';

const SystemStatus: React.FunctionComponent = props => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(startPollingSystemStatus());
	}, [dispatch]);

	const { isSystemVerified, isSystemUnderMaintenance } = useSelector(
		(state: AppState) => ({
			isSystemVerified: getIsSystemStatusVerified(state),
			isSystemUnderMaintenance: getIsSystemUnderMaintenance(state)
		})
	);

	if (!isSystemVerified) {
		return null;
	}

	if (
		isSystemUnderMaintenance &&
		localStorage.getItem(SystemStatusType.UNDER_MAINTENANCE) !== '0'
	) {
		return <Error503 />;
	}
	return <>{props.children}</>;
};

export default SystemStatus;
