import { AxiosTypedResponse, AxiosTypedPromise } from 'services/api/apiTypes';
import {
	GroupItemObject,
	ItemObject,
	GroupsResult
} from './AutocompleteSearchTypes';
import { DEFAULT_GROUP_TYPE } from './AutocompleteSearchConstants';

export const getItemGroupType = (
	item: ItemObject,
	items: GroupItemObject[]
) => {
	if (item === null) {
		return DEFAULT_GROUP_TYPE;
	}
	const group = items.find(i => i.results.includes(item));
	return group?.groupType || DEFAULT_GROUP_TYPE;
};

export function prepareAutocompleteSearchResponse<G = string>(
	result: AxiosTypedResponse<GroupsResult<G>>,
	searchTerm: string
) {
	const { groups } = result.data;
	const items = groups.map(item => {
		if (!item.results || !item.results.length) {
			return item;
		}
		const results = item.results.map(entity => ({
			key: entity.id || entity.code,
			label: entity.name,
			...(entity.email && { email: entity.email })
		}));

		return {
			...item,
			results
		};
	});
	return {
		items,
		searchTerm
	};
}

export function searchEntitiesForAutocompleteSearch<G>(
	apiCall: (
		searchTerm: string,
		groupType?: G,
		...otherParams: string[]
	) => AxiosTypedPromise<GroupsResult<G>>,
	searchTerm: string,
	groupType?: G,
	otherParams: string[] = []
) {
	return apiCall(searchTerm, groupType, ...otherParams).then(result =>
		prepareAutocompleteSearchResponse(result, searchTerm)
	);
}

export function searchEntitiesForPortJobAutocompleteSearch<G>(
	apiCall: (
		searchTerm: string,
		groupType?: G,
		portJobCode?: string,
		portCallId?: string,
		...otherParams: string[]
	) => AxiosTypedPromise<GroupsResult<G>>,
	searchTerm: string,
	groupType?: G,
	portJobCode?: string,
	portCallId?: string,
	otherParams: string[] = []
) {
	return apiCall(
		searchTerm,
		groupType,
		portJobCode,
		portCallId,
		...otherParams
	).then(result => prepareAutocompleteSearchResponse(result, searchTerm));
}
