import {
	RetrieveMessageHistoryResponse,
	RetrieveMessageHistoryRequest
} from 'services/api/messages/messagesServiceTypes';
import actionCreator from 'store/messages/messagesActionCreator';

const RETRIEVE_MESSAGE_HISTORY = 'RETRIEVE_MESSAGE_HISTORY';
export const retrieveMessageHistoryAsync = actionCreator.async<
	RetrieveMessageHistoryRequest,
	RetrieveMessageHistoryResponse,
	Error
>(RETRIEVE_MESSAGE_HISTORY);

export const retrieveMessageHistory = actionCreator<
	RetrieveMessageHistoryRequest
>(RETRIEVE_MESSAGE_HISTORY);
