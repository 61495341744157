import { WizardFormState, WizardFormBaseAction } from '../wizardFormState';
export const onSubmitPage = (
	state: WizardFormState,
	action: WizardFormBaseAction
) => {
	const { name, page } = action;
	const originalForm = state.forms[action.name];
	const form = {
		...originalForm,
		pages: page
			? {
					...originalForm.pages,
					[page]: {
						...originalForm.pages[page],
						submitted: true
					}
				}
			: originalForm.pages
	};
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
