import * as React from 'react';
import { connect } from 'react-redux';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	getUserBelongsToGroupType,
	getUserBelongsToUserType
} from './UserPermissionSelectors';
import { AppState } from 'store-types';

interface WithActiveUserInGroupProps {
	code?: GroupTypeCodes[] | GroupTypeCodes;
	userType?: UserType[] | UserType;
	notFoundElement?: React.ReactNode;
	// eslint-disable-next-line
	children: any; // If not present, UserPermission see prop.children as possible undefined
	// from mapStateToProps
	userBelongsToGroupType: boolean;
	userBelongsToUserType: boolean;
}

const UserPermission: React.FC<WithActiveUserInGroupProps> = ({
	userBelongsToGroupType,
	userBelongsToUserType,
	children,
	notFoundElement: notFound
}) => {
	if (userBelongsToGroupType || userBelongsToUserType) {
		return children;
	}
	return notFound || null;
};

export default connect(
	(
		state: AppState,
		ownProps: Pick<WithActiveUserInGroupProps, 'code' | 'userType'>
	) => ({
		userBelongsToGroupType: getUserBelongsToGroupType(state, ownProps.code),
		userBelongsToUserType: getUserBelongsToUserType(state, ownProps.userType)
	})
)(UserPermission);
