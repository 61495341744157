import { FinanceContextualizationFormState } from '../financeContextualizationReducer';
import { RetrieveFinanceServicesForContextualizationResponse } from 'services/api/finance/financeServiceTypes';

export const onInitializeFinanceContextualizationForm = (
	state: FinanceContextualizationFormState,
	result: RetrieveFinanceServicesForContextualizationResponse
) => ({
	...state,
	data: result.das
});
