import { LabeledValue } from 'app-types';
import { FetchStatus } from 'services/api/apiTypes';
import {
	Group,
	GroupType,
	GroupScopePermissions,
	GroupApplicableScopeTypes,
	GroupSearchByType
} from 'services/api/groups/groupsServiceTypes';

export type GroupFilterSearchByType = GroupSearchByType | 'Search';

interface GroupFilterSearchItem {
	type: GroupFilterSearchByType;
	id: string;
	name: string;
}

export interface GroupFilters {
	status?: LabeledValue<string>;
	search?: GroupFilterSearchItem[];
}

export interface GroupsState {
	readonly byId: { [id: string]: Group };
	readonly hasMore: boolean;
	readonly filters: GroupFilters;
	readonly fetchStatuses: {
		all: FetchStatus;
		adding: FetchStatus;
		updating: FetchStatus;
		updatingScopePermissions: FetchStatus;
		retrieving: FetchStatus;
		retrievingScopePermissions: {
			[key in GroupApplicableScopeTypes]?: FetchStatus;
		};
		deleting: FetchStatus;
		checkingPermissions: FetchStatus;
		initializeEditGroup: FetchStatus;
		retrieveThirdPartyLPAGroups: FetchStatus;
	};
	readonly context: {
		// TODO: Move it to separate module
		openedGroup: Group | null;
	};
	groupTypes: GroupType[];
	scopePermissions: {
		[key in GroupApplicableScopeTypes]?: GroupScopePermissions[] | null;
	};
	error?: Error;
	thirdPartyLPAGroups: Group[];
}

export const initialState: GroupsState = {
	byId: {},
	filters: {},
	hasMore: true,
	context: {
		openedGroup: null
	},
	fetchStatuses: {
		all: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		updatingScopePermissions: FetchStatus.IDLE,
		retrieving: FetchStatus.IDLE,
		retrievingScopePermissions: {
			[GroupApplicableScopeTypes.VESSELS]: FetchStatus.IDLE,
			[GroupApplicableScopeTypes.COUNTRIES]: FetchStatus.IDLE,
			[GroupApplicableScopeTypes.PRINCIPALS]: FetchStatus.IDLE,
			[GroupApplicableScopeTypes.MAIN_PRINCIPALS]: FetchStatus.IDLE,
			[GroupApplicableScopeTypes.LPAS]: FetchStatus.IDLE
		},
		deleting: FetchStatus.IDLE,
		checkingPermissions: FetchStatus.IDLE,
		initializeEditGroup: FetchStatus.IDLE,
		retrieveThirdPartyLPAGroups: FetchStatus.IDLE
	},
	groupTypes: [],
	scopePermissions: {
		[GroupApplicableScopeTypes.VESSELS]: null,
		[GroupApplicableScopeTypes.COUNTRIES]: null,
		[GroupApplicableScopeTypes.PRINCIPALS]: null,
		[GroupApplicableScopeTypes.MAIN_PRINCIPALS]: null,
		[GroupApplicableScopeTypes.LPAS]: null
	},
	thirdPartyLPAGroups: []
};
