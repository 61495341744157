import client from 'services/client';
import {
	RetrieveDashboardPortCallsSummaryResponse,
	JobSections,
	RetrieveDashboardPortCallsRequest,
	DashboardPortCallsResponse,
	RetrieveDashboardTableauResponse,
	DashboardTableauInfo,
	RetrieveDashboardTableauInfoRequest
} from './dashboardServiceTypes';

const retrieveDashboardPortCallsSummary = () =>
	client.get<RetrieveDashboardPortCallsSummaryResponse>(
		`/dashboard/portcalls/summary`
	);

const retrieveDashboardSections = () =>
	client.get<JobSections>('/dashboard/portcalls/status');

const retrieveDashBoardPortCallsByStatusCode = ({
	statusCode,
	index,
	limit
}: RetrieveDashboardPortCallsRequest) =>
	client.get<
		DashboardPortCallsResponse
	>(`/dashboard/portcalls/status/${statusCode}`, {
		params: { limit, index }
	});

const retrieveDashboardTableau = () =>
	client.get<RetrieveDashboardTableauResponse>('/dashboard/tableau');

const retrieveDashboardTableauInfo = ({
	tableauCode,
	...params
}: RetrieveDashboardTableauInfoRequest) =>
	client.get<DashboardTableauInfo>(`/dashboard/tableau/${tableauCode}`, {
		params
	});

export default {
	retrieveDashboardPortCallsSummary,
	retrieveDashboardSections,
	retrieveDashBoardPortCallsByStatusCode,
	retrieveDashboardTableau,
	retrieveDashboardTableauInfo
};
