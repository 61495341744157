import actionCreator from '../supplierInvoiceActionCreator';
import { DownloadSupplierInvoiceDocumentParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_SUPPLIER_INVOCES';

export const downloadSupplierInvoiceAsync = actionCreator.async<
	DownloadSupplierInvoiceDocumentParams,
	RetrieveDownloadDocumentResponse,
	Error
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const downloadSupplierInvoice = actionCreator<
	DownloadSupplierInvoiceDocumentParams
>(ACTION_NAME);
