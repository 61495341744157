import { AxiosTypedResponse } from 'services/api/apiTypes';
import { getActiveThreadId } from 'store/threads/selectors';
import { getActiveWizardModeExecutor } from 'store/wizardForm/sagas';
import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api/portCalls/portCallsService';
import { getOperationById } from 'store/operations/operationsSelectors';
import {
	getAddPortJobFormData,
	getOperationIdFromFormData
} from './addPortCallSelectors';

import { notify } from 'store/notifications/actions';
import {
	addPortCallAsync,
	addPortCall
} from 'store/portcalls/actions/addPortCall';
import { prepareRequest } from './prepareRequest/prepareRequest';
import {
	AddPortCallRequest,
	AddPortCallResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { executor as assignJobToThreadExecutor } from 'store/thread/sagas/assignJobToThreadSaga';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { DraftType } from 'store/drafts/draftsState';
import { getOriginUrl } from 'store/wizardForm/wizardFormSelectors';

// Utils
import { navigateTo } from 'utils';
import Matomo from 'services/analytics/adapters/Matomo';
import { getIsMainPrincipalTypeCLS } from 'store/portJobs/selectors';

export function* addPortCallWorker(): SagaIterator {
	try {
		const request: AddPortCallRequest = yield call(getAddPortCallRequest);
		yield put(addPortCallAsync.started(null));
		const response: AxiosTypedResponse<AddPortCallResponse> = yield call(
			Api.addPortCall,
			request
		);

		const newPortJob = response.data;

		/**
		 * Assign Job To Thread if current wizard is on `assign` mode
		 */
		const activeWizardMode = yield call(getActiveWizardModeExecutor);

		if (activeWizardMode === 'assign') {
			const activeThreadId: string = yield select(getActiveThreadId);
			if (!activeThreadId) {
				throw Error('There`s no Thread to assign this Job');
			}
			yield call(assignJobToThreadExecutor, {
				threadId: activeThreadId,
				jobCode: newPortJob.portJobCode
			});
			// navigate after all
			const originUrl = yield select(getOriginUrl);
			yield call(navigateTo, originUrl || `/messages/${activeThreadId}`);
		} else {
			yield call(
				navigateTo,
				`/portcalls/${newPortJob.id}/jobs/${newPortJob.portJobCode}`
			);
		}

		yield put(
			addPortCallAsync.done({
				result: response.data,
				params: null,
				response
			})
		);
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'PortCall.Create',
			name: `port call ${response.data.id}`,
			value: 1
		});
	} catch (error) {
		yield put(
			addPortCallAsync.failed({
				error,
				params: null
			})
		);
		// eslint-disable-next-line no-console
		console.warn(error);
	}
}

function* getAddPortCallRequest() {
	try {
		const operationFormData = yield select(getOperationIdFromFormData);
		const operation = yield select(getOperationById, operationFormData);
		const portJobAgentCompanies = yield select(
			getCompanyDraftsByType,
			DraftType.AGENT
		);
		const portJobAppointerCompanies = yield select(
			getCompanyDraftsByType,
			DraftType.PRINCIPAL
		);

		const portJobCompanies = portJobAgentCompanies.concat(
			portJobAppointerCompanies
		);

		const chartererDrafts = yield select(
			getCompanyDraftsByType,
			DraftType.CHARTERER
		);
		const shipperReceiverDrafts = yield select(
			getCompanyDraftsByType,
			DraftType.SHIPPER_RECEIVER
		);
		const portJobOperationCompanies = chartererDrafts.concat(
			shipperReceiverDrafts
		);
		const formData = yield select(getAddPortJobFormData);
		const isMainPrincipalTypeCLS = yield select(getIsMainPrincipalTypeCLS);
		return prepareRequest({
			form: formData,
			operation,
			portJobOperationCompanies,
			portJobCompanies,
			isMainPrincipalTypeCLS
		});
	} catch (error) {
		// getActiveThreadId parsing data problems
		yield put(notify.error('Port Call could not be saved. Form data issue.'));
		throw error; // don't consume error
	}
}

export function* addPortCallWatcher(): SagaIterator {
	yield takeLatest(addPortCall.type, addPortCallWorker);
}

export default function*() {
	yield all([fork(addPortCallWatcher)]);
}
