import { Success, Failure } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FetchStatus } from 'services/api/apiTypes';
import { DownloadDirectBillsDocumentRequest } from 'services/api/directBills/directBillsServiceTypes';
import { DirectBillsState } from '../directBillsState';

const onDownloadDirectBillsDocumentSuccess = (
	state: DirectBillsState,
	{ params }: Success<DownloadDirectBillsDocumentRequest, null>
): DirectBillsState => {
	const isDownloadingAll = params.meta && params.meta.downloadAll;
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadAll: isDownloadingAll ? FetchStatus.SUCCESS : FetchStatus.IDLE
		}
	};
};

const onDownloadDirectBillsDocumentFailed = (
	state: DirectBillsState,
	{ params }: Failure<DownloadDirectBillsDocumentRequest, Error>
): DirectBillsState => {
	const isDownloadingAll = params.meta && params.meta.downloadAll;
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadAll: isDownloadingAll ? FetchStatus.FAILURE : FetchStatus.IDLE
		}
	};
};

export const onDownloadDirectBillsDocument = makeFetchStatusReducers<
	DirectBillsState,
	'fetchStatuses',
	DownloadDirectBillsDocumentRequest,
	null
>('fetchStatuses', 'downloadAll', {
	doneReducer: onDownloadDirectBillsDocumentSuccess,
	failedReducer: onDownloadDirectBillsDocumentFailed
});
