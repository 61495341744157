import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';
import { initialState } from './permissionsState';
import {
	retrievePermissionsAsync,
	retrieveEntityPermissionsForVPAsync,
	retrieveEntityPermissionsForPortCallAsync
} from './actions';

import {
	onRetrievePermissions,
	onRetrieveEntityPermissionsStarted,
	onRetrieveEntityPermissionsSuccess,
	onRetrieveEntityPermissionsFailed
} from './reducers/';

export default reducerWithInitialState(initialState)
	.case(retrievePermissionsAsync.started, onRetrievePermissions.started)
	.case(retrievePermissionsAsync.done, onRetrievePermissions.done)
	.case(retrievePermissionsAsync.failed, onRetrievePermissions.failed)
	.case(
		retrieveEntityPermissionsForVPAsync.started,
		onRetrieveEntityPermissionsStarted
	)
	.case(
		retrieveEntityPermissionsForVPAsync.done,
		onRetrieveEntityPermissionsSuccess
	)
	.case(
		retrieveEntityPermissionsForVPAsync.failed,
		onRetrieveEntityPermissionsFailed
	)
	.case(
		retrieveEntityPermissionsForPortCallAsync.started,
		onRetrieveEntityPermissionsStarted
	)
	.case(
		retrieveEntityPermissionsForPortCallAsync.done,
		onRetrieveEntityPermissionsSuccess
	)
	.case(
		retrieveEntityPermissionsForPortCallAsync.failed,
		onRetrieveEntityPermissionsFailed
	);
