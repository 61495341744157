import React, { FC, useCallback } from 'react';
import { Text } from 'components';
import { Collapse, Row } from 'components/antd';
import FormPageContent from './FormPageContent';
import { FinanceContextualizationDisbursementAccount } from 'services/api/finance/financeServiceTypes';
import { isEmpty, isArray } from 'lodash';
import styles from '../../Contextualization.module.scss';

interface FormPageProps {
	field: string;
	activeKeys: string[];
	data: FinanceContextualizationDisbursementAccount[];
	onActiveKeysChange: (keys: string[]) => void;
}

const FormPage: FC<FormPageProps> = ({
	field,
	data,
	activeKeys,
	onActiveKeysChange
}) => {
	const onCollapseChange = useCallback(
		(keys: string | string[]) => {
			if (isArray(keys)) {
				onActiveKeysChange(keys);
			}
		},
		[onActiveKeysChange]
	);

	if (!data || isEmpty(data)) {
		/**
		 * Preventing rendering to set `activeKey` properly
		 */
		return null;
	}

	return (
		<Row>
			<Collapse
				type="primary"
				bordered={false}
				className={styles.collapse}
				activeKey={activeKeys}
				onChange={onCollapseChange}
			>
				{data.map((item, index) => {
					const key = `${index + 1}`;
					return (
						<Collapse.Panel
							key={key}
							header={
								<span>
									<Text color="default" weight="semi-bold">
										{item.label}
									</Text>
									&nbsp;{item.servicesCount} Services
								</span>
							}
						>
							<FormPageContent data={item} field={field} />
						</Collapse.Panel>
					);
				})}
			</Collapse>
		</Row>
	);
};

export default FormPage;
