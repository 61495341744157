import { createSelector } from 'reselect';
import { getFilterValues } from 'store/filters/filtersSelectors';
import {
	PortCallsFiltersParam,
	PortCallsFiltersParamEnum
} from 'store/portcalls/filtersSync';
import { FilterItem } from 'store/filters/filtersState';
const {
	MAIN_PRINCIPAL,
	PERFORMING_AGENT,
	PORT,
	COUNTRY,
	VESSEL,
	JOB_TYPE,
	JOB_STATUS,
	PORT_CALL_STATUS,
	SHOW_CANCELLED,
	PORT_JOB_TYPE,
	PORT_JOB_CATEGORY,
	AGENT_TYPE,
	AGENT_ROLE,
	MAIN_CONTACT,
	CHARTERER,
	OPERATION,
	DATE,
	ASSIGNED_TO_ID,
	ASSIGNED_TO
} = PortCallsFiltersParamEnum;

const defaultFilters: PortCallsFiltersParam = {
	mainPrincipalCompanyId: { label: 'All', key: '', type: '' },
	performingAgentCompanyId: { label: 'All', key: '', type: '' },
	portId: { label: 'All Ports', key: '', type: '' },
	countryCode: { label: 'All Countries', key: '', type: '' },
	vesselId: { label: 'All Vessels', key: '', type: '' },
	jobType: { label: 'All', key: '', type: '' },
	jobStatus: { label: 'All', key: '', type: '' },
	portCallStatus: { label: 'All', key: '', type: '' },
	includeCancelled: { label: '', key: '', type: '' },
	portJobType: { label: '', key: '', type: '' },
	portJobCategory: { label: '', key: '', type: '' },
	agentType: undefined,
	agentRole: { label: '', key: '', type: '' },
	customerContact1Id: undefined,
	chartererCompanyId: undefined,
	operation: undefined,
	dateType: undefined,
	daAssignedTo: undefined
};

export const getFieldValues = createSelector(
	getFilterValues,
	(values): PortCallsFiltersParam => {
		if (!values) {
			return defaultFilters;
		}
		let daAssigned: FilterItem[] = [];
		if (values.daAssignedIds) {
			daAssigned = [...values.daAssignedIds];
		}
		if (values.daAssignedTo) {
			daAssigned = [...daAssigned, ...values.daAssignedTo];
		}
		return {
			[MAIN_PRINCIPAL]: values[MAIN_PRINCIPAL]
				? values[MAIN_PRINCIPAL][0]
				: defaultFilters[MAIN_PRINCIPAL],
			[PERFORMING_AGENT]: values[PERFORMING_AGENT]
				? values[PERFORMING_AGENT][0]
				: defaultFilters[PERFORMING_AGENT],
			[PORT]: values[PORT] ? values[PORT][0] : defaultFilters[PORT],
			[COUNTRY]: values[COUNTRY] ? values[COUNTRY][0] : defaultFilters[COUNTRY],
			[VESSEL]: values[VESSEL] ? values[VESSEL][0] : defaultFilters[VESSEL],
			[JOB_TYPE]: values[JOB_TYPE]
				? values[JOB_TYPE][0]
				: defaultFilters[JOB_TYPE],
			[JOB_STATUS]: values[JOB_STATUS]
				? values[JOB_STATUS][0]
				: defaultFilters[JOB_STATUS],
			[PORT_CALL_STATUS]: values[PORT_CALL_STATUS]
				? values[PORT_CALL_STATUS][0]
				: defaultFilters[PORT_CALL_STATUS],
			[SHOW_CANCELLED]: values[SHOW_CANCELLED]
				? values[SHOW_CANCELLED][0]
				: defaultFilters[SHOW_CANCELLED],
			[PORT_JOB_TYPE]: values[PORT_JOB_TYPE]
				? values[PORT_JOB_TYPE][0]
				: defaultFilters[PORT_JOB_TYPE],
			[PORT_JOB_CATEGORY]: values[PORT_JOB_CATEGORY]
				? values[PORT_JOB_CATEGORY][0]
				: defaultFilters[PORT_JOB_CATEGORY],
			[AGENT_TYPE]: values[AGENT_TYPE]
				? values[AGENT_TYPE][0]
				: defaultFilters[AGENT_TYPE],
			[AGENT_ROLE]: values[AGENT_ROLE]
				? values[AGENT_ROLE][0]
				: defaultFilters[AGENT_ROLE],
			[MAIN_CONTACT]: values[MAIN_CONTACT]
				? values[MAIN_CONTACT][0]
				: defaultFilters[MAIN_CONTACT],
			[CHARTERER]: values[CHARTERER]
				? values[CHARTERER][0]
				: defaultFilters[CHARTERER],
			[OPERATION]: values[OPERATION]
				? values[OPERATION][0]
				: defaultFilters[OPERATION],
			[DATE]: values[DATE] ? values[DATE][0] : defaultFilters[DATE],
			[ASSIGNED_TO]: daAssigned
				? [...daAssigned]
				: defaultFilters[ASSIGNED_TO_ID]
		};
	}
);
