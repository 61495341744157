import { createSelector } from 'reselect';
import {
	getActiveThreadId,
	getAssignedToNewThreadJobCodes
} from 'store/threads/selectors/threadsSelectors';
import { getActiveThread } from 'store/thread/selectors';
import { getJobInPortCallByCode } from 'store/portcalls/selectors';
import { NEW_THREAD_ID } from '../threadConstants';

export const getUserCanAssignJobToAThread = createSelector(
	getActiveThreadId,
	getActiveThread,
	getJobInPortCallByCode,
	(activeThreadId, activeThread, portJob): boolean => {
		if (!activeThreadId || !portJob) {
			return false;
		}
		const companyIdsAreEqual = !!(
			activeThread &&
			portJob.hubPrincipalCompany &&
			portJob.hubPrincipalCompany.id === activeThread.assignedToInbox.companyId
		);
		return (
			!!portJob.hubPrincipalCompany &&
			(activeThreadId === NEW_THREAD_ID || companyIdsAreEqual)
		);
	}
);

export const getJobIsAlreadyAssignedToAThread = createSelector(
	getActiveThreadId,
	getActiveThread,
	getJobInPortCallByCode,
	getAssignedToNewThreadJobCodes,
	(activeThreadId, activeThread, portJob, newThreadJobCodes): boolean => {
		if (!activeThreadId || !portJob) {
			return false;
		}
		let jobCodes;

		if (activeThreadId === NEW_THREAD_ID) {
			// in new thread
			jobCodes = newThreadJobCodes;
		} else {
			// if existing thread
			jobCodes = activeThread ? activeThread.jobCodes : [];
			if (newThreadJobCodes.length) {
				// if new message on existing thread
				jobCodes = jobCodes.concat(newThreadJobCodes);
			}
		}
		/**
		 * If Job is already assigned to a thread
		 */
		return jobCodes.includes(portJob.code);
	}
);
