import {
	ThreadStatus,
	ThreadStatusesEnabledToPick
} from 'services/api/threads/threadsServiceTypes';
import { THREAD_STATUS_OPTIONS } from './threadConstants';

export const getAvailableThreadStatus = (
	status?: ThreadStatus
): ThreadStatusesEnabledToPick =>
	status && THREAD_STATUS_OPTIONS.includes(status)
		? status as ThreadStatusesEnabledToPick
		: ThreadStatus.OPEN;
