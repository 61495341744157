import { ServiceSection } from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';
import { PortJobBaseRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState as State } from '../portJobsState';

export const onEnableOperationsCheck = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	PortJobBaseRequest,
	ServiceSection[]
>('fetchStatuses', 'enableOperationsCheck');
