import { FinanceState } from '../financeState';
import { ResetDAStatusHistoryByIdParams } from '../actions/resetDAStatusHistoryById';
import { omit } from 'lodash';

export const onResetDAStatusHistoryById = (
	state: FinanceState,
	{ daId }: ResetDAStatusHistoryByIdParams
): FinanceState => {
	return {
		...state,
		daStatusHistoryById: omit(state.daStatusHistoryById, daId),
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveDAStatusHistoryById: omit(
				state.fetchStatuses.retrieveDAStatusHistoryById,
				daId
			)
		}
	};
};
