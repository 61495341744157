import * as React from 'react';
import classNames from 'classnames';
import AntTabs, {
	TabsProps as AntTabsProps,
	TabPaneProps
} from 'antd/lib/tabs';
import { Icon } from 'components/antd';
import styles from './Tabs.module.scss';
export interface TabsProps extends AntTabsProps {
	fit?: boolean;
	borderless?: boolean;
	customizable?: boolean;
	gapTop?: boolean;
}

export class Tabs extends React.Component<TabsProps, {}> {
	static TabPane: React.ClassicComponentClass<TabPaneProps>;

	render() {
		const { fit, borderless, customizable, gapTop, ...restProps } = this.props;
		const className = classNames(this.props.className, styles.root, {
			[styles.fit]: fit,
			[styles.borderless]: borderless,
			[styles.customizable]: !customizable,
			[styles.gapTop]: gapTop
		});
		return (
			<AntTabs
				{...restProps}
				className={className}
				renderTabBar={(props, DefaultTabBar) => {
					const { prefixCls, tabPosition } = props;
					const isVertical = tabPosition === 'left' || tabPosition === 'right';
					const prevIconType = isVertical ? 'up' : 'left';
					const nextIconType = isVertical ? 'down' : 'right';
					const prevIcon = (
						<span className={`${prefixCls}-tab-prev-icon`}>
							<Icon
								type={prevIconType}
								className={`${prefixCls}-tab-prev-icon-target`}
							/>
						</span>
					);
					const nextIcon = (
						<span className={`${prefixCls}-tab-next-icon`}>
							<Icon
								type={nextIconType}
								className={`${prefixCls}-tab-next-icon-target`}
							/>
						</span>
					);
					return (
						<DefaultTabBar {...props} prevIcon={prevIcon} nextIcon={nextIcon} />
					);
				}}
			/>
		);
	}
}

Tabs.TabPane = AntTabs.TabPane;

export default Tabs;
