import * as React from 'react';
import { FormattedTime } from 'containers';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { GroupColumnsType } from '../GroupsConstants';
import GroupsSettingsColumn from './GroupsSettingsColumn';

export const getUserListColumns = (): GroupColumnsType => [
	{
		title: 'Group Name',
		dataIndex: 'name',
		key: 'name',
		width: '23%'
	},
	{
		title: 'Group Company',
		dataIndex: 'companyName',
		key: 'companyName',
		width: '20%'
	},
	{
		title: '# of Users',
		dataIndex: 'membersCount',
		key: 'membersCount',
		width: '10%'
	},
	{
		title: 'Messaging Group',
		dataIndex: 'isMessagingGroup',
		key: 'isMessagingGroup',
		width: '10%',
		render: (isMessagingGroup: boolean) => (
			<span>{isMessagingGroup ? 'Yes' : 'No'}</span>
		)
	},
	{
		title: 'Details Last Update',
		dataIndex: 'updatedOn',
		key: 'updatedOn',
		width: '15%',
		render: (text: string) => {
			return text && <FormattedTime value={text} />;
		}
	},
	{
		title: 'Group Type',
		dataIndex: 'groupType.name',
		width: '12%'
	},
	{
		title: ' ',
		width: '10%',
		align: 'right',
		render: (_text: string, record: Group) => {
			return <GroupsSettingsColumn group={record} />;
		}
	}
];
