import actionCreator from '../financeActionCreator';
const RETRIEVE_BENCHMARK_STATUS_CYCLE_START =
	'RETRIEVE_BENCHMARK_STATUS_CYCLE_START';
const RETRIEVE_BENCHMARK_STATUS_CYCLE_STOP =
	'RETRIEVE_BENCHMARK_STATUS_CYCLE_STOP';

export const retrieveBenchmarkStatusCycleStart = actionCreator(
	RETRIEVE_BENCHMARK_STATUS_CYCLE_START
);

export const retrieveBenchmarkStatusCycleStop = actionCreator(
	RETRIEVE_BENCHMARK_STATUS_CYCLE_STOP
);
