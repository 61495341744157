import { EventListAndOpticVariablesData } from 'services/api/companies/companiesServiceTypes';
import { retrieveEventListAndOpticVariablesAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	null,
	EventListAndOpticVariablesData,
	Error,
	null
>({
	api: Api.Companies.retrieveEventListAndOpticVariables,
	async: retrieveEventListAndOpticVariablesAsync
});
