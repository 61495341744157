import React, { useCallback } from 'react';
import classNames from 'classnames';

import { Modal, Alert } from 'components/antd';
import { ModalContext } from 'context';

import styles from './DeleteGroupModal.module.scss';
import { useDeleteGroupModal } from './DeleteGroupModal.hooks';
import { ModalContextBaseProps } from 'context/ModalContext/ModalContext';
import { DeleteGroupModalValue } from '../GroupsModalsTypes';

const DeleteGroupModalNote = () => (
	<Alert
		className={styles.deleteGroupModalNote}
		message={
			<p>
				Note: There are users belonging to that group only. They will be
				disabled and won't be able to log in anymore. Continue anyway?
			</p>
		}
		type="warning"
		showIcon
	/>
);

export interface DeleteGroupModalConfigProps {
	onCancel?: () => void;
}

export type DeleteGroupModalProps = ModalContextBaseProps<
	DeleteGroupModalValue
> &
	DeleteGroupModalConfigProps;

function DeleteGroupModal({
	modalValue,
	modalSubmitting,
	hideModal,
	onCancel
}: DeleteGroupModalProps) {
	const { usersCount, fetchStatus, onSubmit } = useDeleteGroupModal({
		modalValue
	});

	const onCancelClick = useCallback(() => {
		if (onCancel) {
			onCancel();
		}
		hideModal();
	}, [onCancel, hideModal]);

	const isActiveGroupName = Boolean(modalValue?.name);
	return (
		<>
			<Modal
				visible
				cancelText="Cancel"
				okText="Confirm"
				onCancel={onCancelClick}
				onOk={onSubmit}
				okProps={{ loading: modalSubmitting }}
			>
				Are you sure you want to delete{' '}
				<span
					className={classNames(styles.noBreakOutText, {
						[styles.bold]: isActiveGroupName
					})}
				>
					{isActiveGroupName ? modalValue.name : 'group'}
				</span>
				?{Boolean(usersCount) && <DeleteGroupModalNote />}
			</Modal>
			<ModalContext.SubmitResolve fetchStatus={fetchStatus} hideModalOnDone />
		</>
	);
}

export default DeleteGroupModal;
