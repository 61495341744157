import {
	DraftsState,
	CompanyDraftPlain,
	CompanyDraft,
	DraftKind
} from '../draftsState';
import {
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse
} from 'services/api/directBills/directBillsServiceTypes';
import { Success } from 'typescript-fsa';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';

const appendDraftInfoToPlainData = (
	drafts: CompanyDraftPlain[]
): CompanyDraft[] =>
	drafts.map((draft): CompanyDraft => ({
		...draft,
		kind: DraftKind.SAVED,
		isDraft: true,
		companyName: draft.name,
		companyId: draft.id,
		companyOrganizationTypeCode: OrganisationType.VENDOR
	}));

export const onRetrieveDirectBillVendorDrafts = (
	state: DraftsState,
	{
		result: { draftVendorCompanies }
	}: Success<RetrieveDirectBillRequest, RetrieveDirectBillResponse>
): DraftsState => ({
	...state,
	draftsByType: {
		...state.draftsByType,
		vendor: appendDraftInfoToPlainData(draftVendorCompanies || [])
	}
});
