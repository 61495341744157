import { PortJobState } from './../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';
export function onAddPortJobStarted(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.PENDING
		},
		context: {
			...state.context,
			activePortJobCode: ''
		}
	};
}
/**
 * Nothing is being saved here because the result after a PortJob creation
 * it's only the PortJob.code so it's necesary to request the entity after creationg
 */
export function onAddPortJobSuccess(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.SUCCESS
		}
	};
}
export function onAddPortJobFailed(state: PortJobState): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.FAILURE
		}
	};
}
