import runValidations, { required, charMax } from 'utils/validations';
import { PageFormProps as Page2FormProps } from './Form';
import { Page2FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { FormErrors } from 'redux-form';
import { first } from 'lodash';
const validate = (
	values: Page2FormValues,
	props: Page2FormProps
): FormErrors<Page2FormValues> => {
	const errors: FormErrors<Page2FormValues> = {
		jobTypeId: first(runValidations(values.jobTypeId, [required])),
		customerRef: first(runValidations(values.customerRef, [charMax(100)])),
		agencyType: first(runValidations(values.agencyType, [required])),
		performingAgent: first(runValidations(values.performingAgent, [required])),
		agentRole: first(runValidations(values.agentRole, [required]))
	};

	if (values.jobTypeId?.key !== 'Appointment') {
		errors.nominator = first(runValidations(values.nominator, [required]));
	}

	if (props.isCurrentUserWithRoleHub && !props.isMainPrincipalTypeCLS) {
		errors.payingparty = first(runValidations(values.payingparty, [required]));
	}
	if (!values.checkIsNonIssHubPrincipal) {
		errors.contact1 = first(runValidations(values.contact1, [required]));
	}

	return errors;
};
export default validate;
