import React from 'react';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { TimeSaving } from 'services/api/savings/savingsServiceTypes';
import { Button, Icon, Popover, Tooltip } from 'components/antd';
import { EntityCode } from 'app-types';
import { tagsRender } from '../CostSavingGrid/CostSavingGridColumns';
import { Permission } from 'containers';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import SettingsMenu from '../Settings/SettingsMenu';
import { UserType } from 'services/api/users/userServiceTypes';

export const getColumns = (
	userType: UserType,
	isClosedJob?: boolean
): Array<ColumnProps<TimeSaving>> => [
	{
		dataIndex: 'category',
		width: '18%',
		title: 'Category',
		render: (category: EntityCode<string>) => <span>{category.name}</span>
	},
	{
		dataIndex: 'services',
		width: '25%',
		title: 'Services',
		render: (services: EntityCode<string>[]) =>
			services?.length ? tagsRender(services) : null
	},
	{
		dataIndex: 'comments',
		width: '15%',
		title: 'Comments',
		align: 'center',
		render: comments =>
			comments ? (
				<Tooltip
					title={comments}
					getPopupContainer={() => document.body}
					placement="bottom"
				>
					<Icon type="comment" color="primary" size="xl" />
				</Tooltip>
			) : (
				<Icon type="comment-empty" color="primary" size="lg" />
			)
	},
	{
		dataIndex: 'savingsBy',
		width: '10%',
		title: 'Saving by'
	},
	{
		dataIndex: 'dummy-1',
		width: '12%'
	},
	{
		dataIndex: 'dummy-2',
		width: '6%'
	},
	{
		dataIndex: 'hours',
		width: '13%',
		align: 'right',
		title: 'Time(HHH:MM)',
		render: (hours, record) => `${hours}h ${record.minutes}m`
	},
	{
		dataIndex: 'id',
		key: 'settings',
		align: 'right',
		width: 100,
		render: (_id: string, value: TimeSaving) => {
			return (
				<Permission permissionCode={PermissionCode.MANAGE_SAVINGS}>
					{hasPermission =>
						(userType === UserType.LPA || hasPermission) && (
							<Popover
								content={<SettingsMenu value={value} />}
								type="dropdown"
								trigger="click"
								placement="bottomRight"
							>
								<Button
									disabled={isClosedJob}
									transparent
									type="primary"
									icon="settings"
								/>
							</Popover>
						)
					}
				</Permission>
			);
		}
	}
];
