import { PrincipalService } from 'services/api/services/servicesServiceTypes';

export enum ModalAction {
	ADD = 'ADD',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE'
}

export type PrincipalServicesFormData = Omit<
	PrincipalService,
	'principalServiceCategory'
> & {
	principalServiceCategoryId: string;
};

export enum PrincipalServicesFormField {
	NAME = 'name',
	CODE = 'principalServiceCode',
	CATEGORY = 'principalServiceCategoryId'
}

export enum Form {
	PRINCIPAL_SPECIFIC_SERVICES = 'principalServices'
}
