import { FetchStatus } from 'services/api/apiTypes';
import {
	PortCall,
	PortCallDuplicate
} from 'services/api/portCalls/portCallsServiceTypes';
import { PORT_CALL_TABS } from './portCallsConstants';
import {
	PortCallTabKey,
	PortCallTabMaps,
	PortCallOperationsTabKey
} from './portCallsTypes';
import { PortCallAlertsMap } from 'app-types';

export interface MergePortCallsContext {
	isDuplicate: boolean;
	portCallDuplicateIds?: string[];
}

export interface PortCallContext {
	portCallTabs: PortCallTabMaps;
	showErrorPage: boolean;
	activePortCallId: string;
	activePortCallTabId: PortCallTabKey;
	portCallOperationsTab: PortCallOperationsTabKey;
	mergePortCallsContext: MergePortCallsContext;
	activePortCallPortId?: string;
}

export interface StaticItem {
	code: string;
	name: string;
}
export enum StaticEntityType {
	AGENT_ROLE = 'AgentRole',
	AGENT_TYPE = 'AgentType',
	PORT_JOB_TYPE = 'PortJobType',
	// are not used on FE right now, but BE return them, might be used in future
	PORT_CALL_STATUS = 'PortCallStatus',
	PORT_CALL_TYPE = 'PortCallType',
	PORT_JOB_STATE = 'PortJobState'
}

interface PortCallsSettings {
	googleApiKey: string;
	mapType: string;
	zoom: number;
}

export type RetrievePortCallsSettingsResponse = PortCallsSettings;

export type RetrievePortCallsStaticResponse = Array<{
	staticEntityType: StaticEntityType;
	items: StaticItem[];
}>;

interface StaticEntitiesByType {
	[type: string]: StaticItem[];
}

// eslint-disable-next-line
export type PortCallContextType = { [key in keyof PortCallContext]?: any };

export interface PortCallsState {
	readonly byId: { [id: string]: PortCall };
	readonly portCall: PortCall | null;
	readonly duplicates: {
		// port call duplicates by vessel, port, ETA
		all: PortCallDuplicate[];
		// port call duplicates for specific port call
		[portCallId: string]: PortCallDuplicate[];
	};
	readonly hasMore: boolean;
	readonly hasMoreDuplicates: boolean;
	readonly static: StaticEntitiesByType;
	readonly settings: PortCallsSettings | null;
	readonly metricTypeGroupsByPortCall: {
		[portCallId: string]: PortCallAlertsMap;
	};
	readonly context: PortCallContext;
	readonly fetchStatuses: {
		all: FetchStatus;
		adding: FetchStatus;
		updating: FetchStatus;
		static: FetchStatus;
		settings: FetchStatus;
		alerts: FetchStatus;
		duplicates: FetchStatus;
		merge: FetchStatus;
		[key: string]: FetchStatus;
	};
}

export interface PortCallError extends Error {
	response: { status: number };
}
export const initialState: PortCallsState = {
	byId: {},
	portCall: null,
	duplicates: {
		all: []
	},
	hasMore: true,
	hasMoreDuplicates: true,
	static: {},
	settings: null,
	metricTypeGroupsByPortCall: {},
	context: {
		portCallTabs: PORT_CALL_TABS,
		activePortCallTabId: PortCallTabKey.OVERVIEW,
		portCallOperationsTab: PortCallOperationsTabKey.EVENTS,
		activePortCallId: '',
		showErrorPage: false,
		mergePortCallsContext: {
			isDuplicate: false
		}
	},
	fetchStatuses: {
		all: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		static: FetchStatus.IDLE,
		settings: FetchStatus.IDLE,
		alerts: FetchStatus.IDLE,
		duplicates: FetchStatus.IDLE,
		merge: FetchStatus.IDLE
	}
};
