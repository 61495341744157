import config from 'services/config';

export const hasRelativeDocImage = (src: string | null) => {
	if (!src) {
		return false;
	}
	const pattern = /^(\/documents\/inline\/).+/g;
	return pattern.test(src);
};

export const imageParserInner = (apiBaseUrl: string) => (
	tagName: string,
	attribs: { [key: string]: string }
) => {
	let src = attribs.src;
	if (hasRelativeDocImage(src)) {
		src = `${apiBaseUrl}${src}`;
		return {
			tagName,
			attribs: { ...attribs, src }
		};
	}
	return { tagName, attribs };
};

export const imageParser = imageParserInner(config.apiBaseUrl);
