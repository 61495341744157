import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	ToggleOperationsStatusParams,
	ToggleOperationsResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const doneReducer = (
	state: PortJobState,
	action: Success<ToggleOperationsStatusParams, ToggleOperationsResponse>
): PortJobState => {
	const jobCodeToUpdate = action.params.jobCode;
	const newById = Object.keys(state.byId).reduce((acc, key) => {
		acc[key] = {
			...state.byId[key],
			isOperationsDisabled:
				state.byId[key].code === jobCodeToUpdate
					? action.params.isOperationsDisabled
					: state.byId[key].isOperationsDisabled
		};
		return acc;
	}, {});
	return {
		...state,
		byId: newById
	};
};

export const onToggleOperationsStatus = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	ToggleOperationsStatusParams,
	ToggleOperationsResponse
>('fetchStatuses', 'all', { doneReducer });
