import client from 'services/client';
import {
	RetrieveFundingRequestRequest,
	RetrieveFundingRequestResponse,
	RetrieveNewFundingDataRequest,
	RetrieveNewFundingDataResponse,
	CreateNewFundingRequestRequest,
	CreateNewFundingRequestResponse,
	UpdateFundingRequestRequest,
	UpdateFundingRequestResponse,
	UpdateFundingRequest,
	UpdateFundingResponse,
	RetrievePrefundingRequestResponse,
	RetrievePrefundingRequest,
	PrefundingsRequest,
	UpdatePrefundingStatusRequest
} from './financeFundingsServiceTypes';

const retrieveFinanceFundingRequest = ({
	portCallId,
	jobCode,
	fundingId
}: RetrieveFundingRequestRequest) => {
	return client.get<RetrieveFundingRequestResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/funding-requests/${fundingId}`
	);
};

const retrievePrefundingRequest = ({
	portCallId,
	jobCode
}: RetrievePrefundingRequest) => {
	return client.get<RetrievePrefundingRequestResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/revisions/customer-prefunding`
	);
};

const updateFundingRequest = ({
	portCallId,
	jobCode,
	fundingId,
	funding,
	action
}: UpdateFundingRequestRequest) => {
	return client.put<UpdateFundingRequestResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/funding-requests/${fundingId}/${action}`,
		funding
	);
};

const updateFunding = ({
	portCallId,
	jobCode,
	id,
	daInId,
	daOutId,
	amount,
	currencyCode,
	typeCode
}: UpdateFundingRequest) => {
	return client.patch<UpdateFundingResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/funding/${id}`,
		{
			daInId,
			daOutId,
			id,
			currencyCode,
			typeCode,
			amount
		}
	);
};

const retrieveNewFundingData = ({
	portCallId,
	jobCode,
	daId
}: RetrieveNewFundingDataRequest) => {
	return client.get<RetrieveNewFundingDataResponse>(
		`/finances/${portCallId}/jobs/${jobCode}/funding-requests/creation-data`,
		{
			params: { daInId: daId }
		}
	);
};

const createFundingRequest = ({
	portCallId,
	jobCode,
	request
}: CreateNewFundingRequestRequest) => {
	return client.post<CreateNewFundingRequestResponse>(
		`finances/${portCallId}/jobs/${jobCode}/funding-requests`,
		request
	);
};

const sendPrefundingsRequest = ({
	portCallId,
	jobCode,
	...params
}: PrefundingsRequest) => {
	return client.put(
		`/finances/${portCallId}/jobs/${jobCode}/funding-requests/update-fundingrequest`,
		params
	);
};

const updatePrefundingStatus = ({
	portCallId,
	jobCode,
	fundingRequestId,
	...params
}: UpdatePrefundingStatusRequest) => {
	return client.put(
		`/finances/${portCallId}/jobs/${jobCode}/funding-requests/${fundingRequestId}/updateprefunding-status`,
		params
	);
};

export default {
	retrieveFinanceFundingRequest,
	retrieveNewFundingData,
	updateFundingRequest,
	createFundingRequest,
	retrievePrefundingRequest,
	updateFunding,
	sendPrefundingsRequest,
	updatePrefundingStatus
};
