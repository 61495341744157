import { FinanceState } from '../financeState';
import { initialState } from 'store/finance/financeState';
import { FetchStatus } from 'services/api/apiTypes';

export function onResetFinanceDetails(state: FinanceState): FinanceState {
	return {
		...state,
		financeDetails: initialState.financeDetails,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveFinanceDetails: FetchStatus.IDLE
		}
	};
}
