import React from 'react';
import styles from '../ImageViewer.module.scss';

interface PanState {
	dragging: boolean;
	startX: number;
	startY: number;
	scrollX: number;
	scrollY: number;
}
class Pan extends React.PureComponent<{}, PanState> {
	ref: Element | null = null;

	state = {
		dragging: false,
		startX: 0,
		startY: 0,
		scrollX: 0,
		scrollY: 0
	};

	componentWillUnmount() {
		if (this.state.dragging) {
			this.removeEventListeners();
		}
	}

	onDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
		if (this.state.dragging || !this.ref) {
			return;
		}

		this.addEventListeners();

		this.setState({
			dragging: true,

			startX: e.clientX,
			startY: e.clientY,

			scrollX: this.ref.scrollLeft,
			scrollY: this.ref.scrollTop
		});
	};

	onDragMove = (e: MouseEvent) => {
		e.preventDefault();

		if (!this.ref) {
			return;
		}

		const { clientX, clientY } = e;

		const dx = clientX - this.state.startX;
		const dy = clientY - this.state.startY;

		this.ref.scrollTop = this.state.scrollY - dy;
		this.ref.scrollLeft = this.state.scrollX - dx;
	};

	onDragStop = () => {
		this.setState({ dragging: false });
		this.removeEventListeners();
	};

	setRef = (ref: HTMLDivElement) => {
		if (!ref) {
			return;
		}
		this.ref = ref;
	};

	addEventListeners = (element = this.ref) => {
		if (!element) {
			return;
		}
		element.addEventListener('mousemove', this.onDragMove);
		element.addEventListener('mouseup', this.onDragStop);
	};

	removeEventListeners = (element = this.ref) => {
		if (!element) {
			return;
		}
		element.removeEventListener('mousemove', this.onDragMove);
		element.removeEventListener('mouseup', this.onDragStop);
	};

	render() {
		return (
			<div
				ref={this.setRef}
				className={styles.scrollable}
				onMouseDown={this.onDragStart}
			>
				{this.props.children}
			</div>
		);
	}
}

export default Pan;
