import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { UpdateSDAReasonRequest } from 'services/api/finance/financeServiceTypes';

export const OnUpdateSDAReason = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateSDAReasonRequest,
	null
>('fetchStatuses', 'UpdateSDAReason', {
	doneReducer: (
		state: FinanceState,
		_action: Success<UpdateSDAReasonRequest, null>
	): FinanceState => ({
		...state,
		sdaReasons: []
	})
});
