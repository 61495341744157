import { createSelector, createStructuredSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading } from 'store/selectors';
import { sortBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';

const getServicesFetchStatuses = (state: AppState) =>
	state.masterdata.services.fetchStatuses;

export const getRetrieveServiceCategoriesFetchStatus = (state: AppState) =>
	getServicesFetchStatuses(state).retrieveServiceCategories;

const getServicesMap = (state: AppState) => state.masterdata.services.byCode;
const getServiceCategoriesMap = (state: AppState) =>
	state.masterdata.services.serviceCategories;

export const getIsServicesLoading = (state: AppState) =>
	getIsLoading(getServicesFetchStatuses(state).retrieveServices);

export const getServices = createSelector(
	getServicesMap,
	services => Object.values(services)
);

export const getServicesSortedByName = createSelector(
	getServices,
	services => sortBy(services, service => service.name)
);

export const getServiceCategories = createSelector(
	getServiceCategoriesMap,
	value => Object.values(value || {})
);

export const getServiceCategoriesFetchStatusesStructured = createStructuredSelector<
	AppState,
	{
		addStatus: FetchStatus;
		updateStatus: FetchStatus;
		deleteStatus: FetchStatus;
	}
>({
	addStatus: state => getServicesFetchStatuses(state).addServiceCategory,
	updateStatus: state => getServicesFetchStatuses(state).updateServiceCategory,
	deleteStatus: state => getServicesFetchStatuses(state).deleteServiceCategory
});
