import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Tooltip } from 'components/antd';
import { ClickParam } from 'antd/lib/menu';
import * as RouteParams from 'sections/App/RouteParams';
import { cancelCreditSupplierInvoice } from 'store/supplierInvoice/actions';
import {
	SupplierInvoiceRow,
	SupplierInvoiceActionCode
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { navigateTo } from 'utils';
import PostCreditSettingsItem from './PostCreditSettingsItem';
import { menuItemMap } from './SupplierInvoiceSettingsButtonConsts';
import { getEntityMenuItems } from 'store/metadata/utils/metadataUtils';

export interface SupplierInvoiceSettingsMenuOwnProps {
	id: string;
	metadata: SupplierInvoiceRow['metadata'];
}

const { EDIT, CANCEL_CREDIT_SUPPLIER_INVOICE } = SupplierInvoiceActionCode;

const SupplierInvoiceSettingsMenu = ({
	id,
	metadata
}: SupplierInvoiceSettingsMenuOwnProps) => {
	const dispatch = useDispatch();
	const menuItems = getEntityMenuItems<SupplierInvoiceActionCode>(
		metadata.actions,
		menuItemMap
	);

	const onMenuClick = (e: ClickParam) => {
		switch (e.key) {
			case EDIT:
				navigateTo(`/${RouteParams.PATHS.supplierinvoices}/${id}/edit/`);
				break;
			case CANCEL_CREDIT_SUPPLIER_INVOICE:
				dispatch(cancelCreditSupplierInvoice({ supplierInvoiceId: id }));
				break;
			default:
				break;
		}
	};

	const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) =>
		e.stopPropagation();

	return (
		<div onClick={stopPropagation}>
			<Menu onClick={onMenuClick} selectable={false}>
				{menuItems.map(({ text, disabled, tooltip, action }) => {
					const clickableText =
						action ===
						SupplierInvoiceActionCode.CREATE_CREDIT_INVOICE_BASED_ON_REGULAR ? (
							<PostCreditSettingsItem label={text} invoiceId={id} />
						) : (
							text
						);
					return (
						<Menu.Item key={action} disabled={disabled}>
							{tooltip ? (
								<Tooltip
									title={tooltip}
									placement="left"
									getPopupContainer={() => document.body}
								>
									{text}
								</Tooltip>
							) : (
								clickableText
							)}
						</Menu.Item>
					);
				})}
			</Menu>
		</div>
	);
};

export default SupplierInvoiceSettingsMenu;
