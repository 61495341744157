import Api from 'services/api';
import {
	DAOutValidationMode,
	AddDAActionPayload,
	AddDARequest,
	DaType
} from 'services/api/finance/financeServiceTypes';
import { addDAAsync, closeAddDAModal } from '../actions/addDA';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

export const worker = function*({
	payload
}: Action<AddDAActionPayload>): SagaIterator {
	yield put(addDAAsync.started(payload));

	const formData = payload.data;
	if (payload.daType === DaType.IN) {
		formData.validationMode = DAOutValidationMode.ONLINE;
	}

	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const request = {
		portCallId,
		jobCode,
		payload: { ...formData, daType: payload.daType }
	} as AddDARequest;
	try {
		const response = yield call(Api.Finance.addDA, request);
		yield put(
			addDAAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
		yield put(closeAddDAModal(payload.daType));
	} catch (error) {
		yield put(
			addDAAsync.failed({
				error,
				params: payload
			})
		);
	}
};

export default function* addDAWatcher(): SagaIterator {
	yield takeLatest(addDAAsync.type, worker);
}
