import React, { Component } from 'react';
import { get, initial, isEmpty } from 'lodash';
import jsPDF from 'jspdf';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Konva from 'konva';
import { Layer, Line, Rect, Stage, Image as CustomImage } from 'react-konva';
import { Stage as KonvaStage } from 'konva/types/Stage';
import { KonvaEventObject } from 'konva/types/Node';

import { UseDocumentContext } from '../../DocumentContext';
import {
	getEmptyLine,
	getEmptyRect,
	getEmptyTextNode,
	getRectDimensions,
	getScaledPoint,
	isLineNonEmpty,
	isRectEmpty
} from './ImageViewerV2.func';
import styles from './ImageViewerV2.module.scss';
import {
	DrawingAction,
	DrawingMode,
	LineNode,
	RectNode,
	TextNode
} from './ImageViewerV2Types';
import LineComponent from './KonvaComponents/LineComponent';
import Rectangle from './KonvaComponents/Rectangle';
import TextComponent from './KonvaComponents/TextComponent';
import TextInsertPopover from './TextBoxComponent/TextInsertPopOver';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';
import { UploadFile } from 'components/antd/Upload/Upload';
import { PageType } from 'store/finance/constants';
import { fullPage } from 'store/notifications/actions';

interface ImageViewerProps {
	imageUrl: string;
	fileName?: string;
	isAnnotatable: boolean;
	activePageType: PageType;
	onSaveAnnotation: (request: UploadCustomRequest) => void;
	fullPage: typeof fullPage;
}

interface ImageDimensions {
	width: number;
	height: number;
}

interface ImageData {
	image: HTMLImageElement;
	width: number;
	height: number;
}

interface HistoryItem {
	type: DrawingMode;
	action: DrawingAction;
	id: string;
	oldState: Array<RectNode | TextNode | LineNode>;
}

interface ImageViewerState {
	dimensions: ImageDimensions;
	textPopover: TextNode | null;
	imageData: ImageData | null;
	isPainting: boolean;
	linePoints: LineNode;
	lines: LineNode[];
	rect: RectNode;
	rects: RectNode[];
	history: HistoryItem[];
	texts: TextNode[];
	rotationDeg: number;
	isSelected: string;
	isEditing: string;
	calculatedScale: number;
}

const initialViewerState = {
	dimensions: { width: 1, height: 1 },
	imageData: null,
	textPopover: null,
	isPainting: false,
	linePoints: getEmptyLine(),
	rect: getEmptyRect(),
	texts: [],
	lines: [],
	rects: [],
	history: [],
	rotationDeg: 0,
	isSelected: '',
	isEditing: '',
	calculatedScale: 1
};

class ImageViewer extends Component<ImageViewerProps, ImageViewerState> {
	static contextType = UseDocumentContext;
	context!: React.ContextType<typeof UseDocumentContext>;
	stage: KonvaStage;
	viewerRef: HTMLDivElement;
	imageNode: Konva.Image | null;
	shareRef: Konva.Rect | null;
	trRef: Konva.Transformer | null;

	constructor(props: ImageViewerProps) {
		super(props);

		this.state = initialViewerState;
	}

	componentDidMount() {
		const newImage = new Image();
		newImage.crossOrigin = 'Anonymous';
		newImage.addEventListener('load', this.onLoad);
		newImage.addEventListener('error', this.onError);
		newImage.src = `${
			this.props.imageUrl
		}&X-Amz-Date=${new Date().toISOString()}`;

		this.context.setActions({
			onSave: this.onSave,
			onUndo: this.onUndo,
			onModeChange: this.setMode,
			onUploadAnnotation: this.onSaveAnnotation
		});
		this.context.setIsHistoryEmpty(isEmpty(this.state.history));
	}

	componentDidUpdate(prevProps: ImageViewerProps, prevState: ImageViewerState) {
		const { texts, lines, rects, history } = this.state;

		if (this.props.imageUrl !== prevProps.imageUrl) {
			this.setState(initialViewerState, () => {
				const newImage = new Image();
				newImage.crossOrigin = 'Anonymous';
				newImage.addEventListener('load', this.onLoad);
				newImage.addEventListener('error', this.onError);
				newImage.src = `${
					this.props.imageUrl
				}&X-Amz-Date=${new Date().toISOString()}`;
			});
		}

		const isAnnotated = !isEmpty([...texts, ...lines, ...rects]);
		if (isAnnotated !== this.context.isAnnotated) {
			this.context.setIsAnnotated(isAnnotated);
		}

		if (isEmpty(history) !== isEmpty(prevState.history)) {
			this.context.setIsHistoryEmpty(isEmpty(history));
		}
	}

	componentWillUnmount() {
		const { imageData } = this.state;
		if (imageData?.image) {
			imageData.image.removeEventListener('load', this.onLoad);
			imageData.image.addEventListener('error', this.onError);
		}
		window.removeEventListener('mouseup', this.resetDrawing);
	}

	// eslint-disable-next-line
	onLoad = (event: any) => {
		const image = event.currentTarget;
		const { scale } = this.context;
		let width = get(this.viewerRef, 'clientWidth', 0);
		let height = get(this.viewerRef, 'clientHeight', 0);
		let calculatedScale = scale;
		if (image) {
			calculatedScale = (width - 200) / image.width;
			width = image.width * calculatedScale;
			height = image.height * calculatedScale;
		}
		this.setState({
			imageData: { image, width: image.width, height: image.height },
			dimensions: { width, height },
			calculatedScale: calculatedScale
		});
	};

	onError = () => {
		this.props.fullPage({
			error: {
				status: 'UNHANDLED'
			},
			description: 'Unable to download the document'
		});
	};

	setViewerRef = (ref: HTMLDivElement) => {
		if (ref) {
			this.viewerRef = ref;
		}
	};

	// eslint-disable-next-line
	setStageRef = (ref: any) => {
		if (ref) {
			this.stage = ref;
		}
	};

	onNodeSelect = (id: string) => {
		if (this.state.isEditing || this.state.textPopover) {
			return;
		}
		this.setState({
			isSelected: id
		});
	};

	onTextNodeEdit = (textNode: TextNode) => {
		if (this.state.isEditing || this.state.textPopover) {
			return;
		}
		this.context.setMode(DrawingMode.TEXT);
		this.setState({
			isEditing: textNode.id,
			textPopover: { ...textNode },
			isPainting: true,
			isSelected: ''
		});
	};

	setMode = () => {
		const { color } = this.context;
		this.setState({
			rect: getEmptyRect(color),
			linePoints: getEmptyLine(color),
			textPopover: null,
			isPainting: false
		});
	};

	onCloseTextPopover = () => {
		this.setState({
			textPopover: null,
			isPainting: false,
			isEditing: ''
		});
	};

	onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
		const { scale, mode, color } = this.context;
		const { linePoints, rect, textPopover } = this.state;
		const { x, y } = getScaledPoint(this.stage, scale);

		//Here if user clicks on Image means we assume like he is going to add new shape
		//So already making already selected one as empty
		const clickedOnEmpty = e.target.className === 'Image';
		if (clickedOnEmpty) {
			this.setState({ isSelected: '', isPainting: true });
			switch (mode) {
				case DrawingMode.TEXT:
					if (textPopover) {
						this.setState({
							textPopover: {
								...textPopover,
								x,
								y
							}
						});
					} else {
						this.setState({
							textPopover: {
								x,
								y,
								...getEmptyTextNode(color)
							}
						});
					}
					break;
				case DrawingMode.LINE:
					this.setState({
						linePoints: {
							...linePoints,
							x,
							y,
							points: [...linePoints.points, 0, 0]
						}
					});
					break;
				case DrawingMode.RECT:
					this.setState({
						rect: { ...rect, x, y, color }
					});
					break;
				default:
			}
			window.addEventListener('mouseup', this.resetDrawing);
		}
	};

	resetDrawing = () => {
		if (!this.state.isPainting) {
			return;
		}
		this.onMouseUp();
	};

	onMouseMove = () => {
		const { isPainting, linePoints, rect } = this.state;
		const { scale, mode, color } = this.context;
		if (!isPainting || !this.stage) {
			return;
		}
		const { x, y } = getScaledPoint(this.stage, scale);
		switch (mode) {
			case DrawingMode.LINE:
				this.setState({
					linePoints: {
						...linePoints,
						color,
						points: [...linePoints.points, x - linePoints.x, y - linePoints.y]
					}
				});
				break;
			case DrawingMode.RECT:
				this.setState({
					rect: {
						x: rect.x,
						y: rect.y,
						width: x - rect.x,
						height: y - rect.y,
						color,
						id: rect.id,
						rotation: rect.rotation
					}
				});
				break;
			default:
		}
	};

	onMouseUp = () => {
		if (!this.stage) {
			return;
		}
		const { history, linePoints, rect, lines, rects, isPainting } = this.state;
		const { scale, mode, color } = this.context;

		const { x, y } = getScaledPoint(this.stage, scale);

		if (isPainting) {
			switch (mode) {
				case DrawingMode.LINE:
					if (isLineNonEmpty(linePoints)) {
						this.setState({
							lines: [
								...lines,
								{
									...linePoints,
									points: [
										...linePoints.points,
										x - linePoints.x,
										y - linePoints.y
									]
								}
							],
							linePoints: getEmptyLine(color),
							history: [
								...history,
								{
									type: mode,
									action: DrawingAction.ADD,
									id: 'null',
									oldState: []
								}
							]
						});
					} else {
						this.setState({
							linePoints: getEmptyLine(color)
						});
					}
					break;
				case DrawingMode.RECT:
					if (!isRectEmpty(rect, { x, y })) {
						this.setState({
							rects: [...rects, getRectDimensions(rect, { x, y })],
							rect: getEmptyRect(color),
							history: [
								...history,
								{
									type: mode,
									action: DrawingAction.ADD,
									id: 'null',
									oldState: []
								}
							]
						});
					} else {
						this.setState({
							rect: getEmptyRect(color)
						});
					}
					break;
				default:
			}
		}
		this.setState({ isPainting: false });
	};

	onSaveText = (
		textNode: Pick<TextNode, 'text' | 'fontSize' | 'fontStyle'>
	) => {
		const { history, textPopover, isEditing } = this.state;
		const texts = [...this.state.texts];
		if (textNode.text.length <= 0) {
			return;
		}
		if (textPopover && isEditing) {
			const index = texts.findIndex(text => text.id === isEditing);
			texts[index] = { ...texts[index], ...textNode };
			this.setState({
				texts: [...texts],
				textPopover: null,
				isPainting: false,
				isEditing: '',
				isSelected: '',
				history: [
					...history,
					{
						type: DrawingMode.TEXT,
						action: DrawingAction.MOVE,
						id: 'null',
						oldState: [...this.state.texts]
					}
				]
			});
		} else if (textPopover) {
			this.setState({
				texts: [...texts, { ...textPopover, ...textNode }],
				textPopover: null,
				isPainting: false,
				history: [
					...history,
					{
						type: DrawingMode.TEXT,
						action: DrawingAction.ADD,
						id: 'null',
						oldState: []
					}
				]
			});
		}
	};

	onSave = () => {
		this.processPDF('download');
	};

	onUndo = () => {
		const { history, rects, texts, lines } = this.state;
		if (!history.length) {
			return;
		}
		const lastItem = history[history.length - 1];
		switch (lastItem.type) {
			case DrawingMode.RECT:
				const newRectState =
					lastItem.action === DrawingAction.MOVE
						? lastItem.oldState
						: initial(rects);
				this.setState({ rects: newRectState as RectNode[] });
				break;
			case DrawingMode.TEXT:
				const newTextState =
					lastItem.action === DrawingAction.MOVE
						? lastItem.oldState
						: initial(texts);
				this.setState({
					texts: newTextState as TextNode[]
				});
				break;
			case DrawingMode.LINE:
				const newLineState =
					lastItem.action === DrawingAction.MOVE
						? lastItem.oldState
						: initial(lines);
				this.setState({ lines: newLineState as LineNode[] });
				break;
			default:
				break;
		}
		this.setState({
			history: initial(history)
		});
	};

	onMouseOver = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const { mode } = this.context;
		const stage = event.target.getStage();
		if (stage) {
			if (mode === DrawingMode.RECT) {
				stage.container().style.cursor = 'crosshair';
			} else if (mode === DrawingMode.TEXT) {
				stage.container().style.cursor = 'text';
			} else {
				stage.container().style.cursor = 'default';
			}
		}
	};

	onMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage) {
			stage.container().style.cursor = 'default';
		}
	};

	processPDF = (action: string) => {
		const { texts, lines, rects, calculatedScale, imageData } = this.state;
		const isAnnotated = !isEmpty([...texts, ...lines, ...rects]);
		const fileName =
			isAnnotated || this.props.activePageType === PageType.UPDATED
				? `${this.props.fileName}_annotated.pdf`
				: `${this.props.fileName}_Original.pdf`;
		// Reset text-popover from canvas
		this.setState({
			textPopover: null,
			isSelected: '',
			isPainting: false,
			isEditing: ''
		});
		if (!imageData) {
			return;
		}

		this.context.setIsDocumentPreparing(true);

		const pageOrientation = imageData.height / imageData.width > 1 ? 'p' : 'l';
		//Need to calculate the page width and height in pt metric as jsPDF works well with that metric
		const width = Math.round(imageData.width * (72 / 96));
		const height = Math.round(imageData.height * (72 / 96));

		// We have to use setTimeout here in order to unblock main thread and allow to hide textPopup
		setTimeout(() => {
			const pdf = new jsPDF({
				orientation: pageOrientation,
				unit: 'pt',
				format: [width, height],
				compress: true
			});
			/**
			 * Current version of the jsPDF have the image blur issue. To solve that need
			 * increase the scale factor. Ref: https://github.com/parallax/jsPDF/issues/762
			 */
			const pdfInternals = pdf.internal;
			pdfInternals.scaleFactor = 2;

			const hiddenLayer = this.stage.getLayers()[0].clone();
			hiddenLayer
				.x(0)
				.y(0)
				.scale({
					x: 1 / (this.context.scale * calculatedScale),
					y: 1 / (this.context.scale * calculatedScale)
				})
				.size({ width: imageData.width, height: imageData.height });

			pdf.addImage(
				hiddenLayer.toDataURL(),
				'PNG',
				0,
				0,
				width,
				height,
				undefined,
				'SLOW'
			);
			if (action === 'upload') {
				const file: UploadFile = (new File([pdf.output('blob')], fileName, {
					type: pdf.output('blob').type,
					lastModified: pdf.output('blob').lastModified
				}) as unknown) as UploadFile;
				this.props.onSaveAnnotation({
					file
				});
			} else {
				pdf.save(fileName);
			}
			this.context.setIsDocumentPreparing(false);
		});
	};

	onSaveAnnotation = () => {
		this.processPDF('upload');
	};

	render() {
		const { color, scale } = this.context;
		const { isAnnotatable } = this.props;
		const {
			textPopover,
			dimensions,
			imageData,
			linePoints,
			rect,
			lines,
			rects,
			texts,
			rotationDeg,
			isSelected,
			isEditing
		} = this.state;

		return (
			<div
				className={classNames(styles.root, {
					[styles.loadingIcon]: !imageData
				})}
				ref={this.setViewerRef}
			>
				{!isAnnotatable ? (
					<div
						className={classNames(styles.content, {
							[styles.contentCenter]: scale < 1
						})}
						style={{ transform: `scale(${scale})` }}
					>
						{imageData && this.props.children}
					</div>
				) : (
					<div
						className={styles.konvaContainer}
						style={{
							width: `${dimensions.width * scale}px`,
							height: `${dimensions.height * scale}px`
						}}
					>
						<Stage
							ref={this.setStageRef}
							width={dimensions.width * scale}
							height={dimensions.height * scale}
							onMouseDown={this.onMouseDown}
							onMouseMove={this.onMouseMove}
							onMouseUp={this.onMouseUp}
							onMouseOver={this.onMouseOver}
							onMouseLeave={this.onMouseLeave}
						>
							<Layer>
								{imageData && (
									<CustomImage
										x={(dimensions.width * scale) / 2}
										y={(dimensions.height * scale) / 2}
										offsetX={(dimensions.width * scale) / 2}
										offsetY={(dimensions.height * scale) / 2}
										width={dimensions.width * scale}
										height={dimensions.height * scale}
										image={imageData.image}
										rotation={rotationDeg}
									/>
								)}
								{lines.map((line, index) => (
									<LineComponent
										key={index}
										shapeProps={line}
										scale={scale}
										isSelected={line.id === isSelected}
										isEditing={!!textPopover}
										onSelect={() => {
											this.onNodeSelect(line.id);
										}}
										onChange={(newAttrs: LineNode) => {
											const lines = this.state.lines.slice();
											lines[index] = newAttrs;
											this.setState({
												lines: [...lines],
												history: [
													...this.state.history,
													{
														action: DrawingAction.MOVE,
														oldState: this.state.lines,
														type: DrawingMode.LINE,
														id: 'null'
													}
												]
											});
										}}
										onDelete={() => {
											const lines = this.state.lines.slice();
											lines.splice(index, 1);
											this.setState({
												lines: [...lines],
												history: [
													...this.state.history,
													{
														action: DrawingAction.MOVE,
														oldState: this.state.lines,
														type: DrawingMode.LINE,
														id: 'null'
													}
												]
											});
										}}
									/>
								))}
								{linePoints && (
									<Line
										x={linePoints.x * scale}
										y={linePoints.y * scale}
										stroke={color}
										scale={{ x: scale, y: scale }}
										strokeWidth={5}
										points={linePoints.points}
									/>
								)}
								{rect && (
									<Rect
										x={rect.x * scale}
										y={rect.y * scale}
										width={rect.width * scale}
										height={rect.height * scale}
										stroke={color}
										strokeWidth={5}
									/>
								)}
								{rects.map((rect, index) => (
									<Rectangle
										key={index}
										shapeProps={rect}
										scale={scale}
										isSelected={rect.id === isSelected}
										isEditing={!!textPopover}
										onSelect={() => {
											this.onNodeSelect(rect.id);
										}}
										onChange={(newAttrs: RectNode) => {
											const rects = this.state.rects.slice();
											rects[index] = newAttrs;
											this.setState({
												rects: [...rects],
												history: [
													...this.state.history,
													{
														action: DrawingAction.MOVE,
														oldState: this.state.rects,
														type: DrawingMode.RECT,
														id: 'null'
													}
												]
											});
										}}
										onDelete={() => {
											const rects = this.state.rects.slice();
											rects.splice(index, 1);
											this.setState({
												rects: [...rects],
												history: [
													...this.state.history,
													{
														action: DrawingAction.MOVE,
														oldState: this.state.rects,
														type: DrawingMode.RECT,
														id: 'null'
													}
												]
											});
										}}
									/>
								))}
								{texts?.map(
									(textNode, index) =>
										isEditing !== textNode.id && (
											<TextComponent
												key={index}
												shapeProps={textNode}
												scale={scale}
												isSelected={textNode.id === isSelected}
												isEditing={!!textPopover}
												onSelect={() => {
													this.onNodeSelect(textNode.id);
												}}
												onEdit={() => {
													this.onTextNodeEdit(textNode);
												}}
												onChange={(newAttrs: TextNode) => {
													const texts = this.state.texts.slice();
													texts[index] = newAttrs;
													this.setState({
														texts: [...texts],
														history: [
															...this.state.history,
															{
																action: DrawingAction.MOVE,
																oldState: this.state.texts,
																type: DrawingMode.TEXT,
																id: 'null'
															}
														]
													});
												}}
												onDelete={() => {
													const texts = this.state.texts.slice();
													texts.splice(index, 1);
													this.setState({
														texts: [...texts],
														history: [
															...this.state.history,
															{
																action: DrawingAction.MOVE,
																oldState: this.state.texts,
																type: DrawingMode.TEXT,
																id: 'null'
															}
														]
													});
												}}
											/>
										)
								)}
							</Layer>
						</Stage>
						{textPopover && (
							<TextInsertPopover
								{...textPopover}
								x={textPopover.x * scale}
								y={textPopover.y * scale}
								onSaveText={this.onSaveText}
								onClose={this.onCloseTextPopover}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default connect(null, {
	fullPage
})(ImageViewer);
