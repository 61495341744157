import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, FormWarnings } from 'redux-form';
import { omitByNil } from 'utils/objects';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { isEqual } from 'lodash';

import Fields from './Fields';
import validate, { warn } from './validate';
import onChange from './onChange';
import {
	FORM,
	FormFieldName,
	PORT_JOB_WIZARD_ID
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	getPortCallFormComputedValues,
	getPortCallInitialValues
} from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import {
	getIsCurrentUsersGroupTypeISSCluster,
	getIsCurrentUsersGroupTypeISSHub,
	getIsCurrentUserHub
} from 'store/auth/selectors';
import { retrievePortCallsStatic } from 'store/portcalls/actions';
import { getIsStaticLoaded } from 'store/portcalls/selectors';
import { Loading } from 'components';
import { AppState } from 'store-types';
import PageFormWrapper from '../../../PageFormWrapper';
import { getCompanyVesselsMapByRecordId } from 'store/companyVessels/selectors';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { getWizardById } from 'store/wizardForm/wizardFormSelectors';

export interface PageBaseProps {
	hasDuplication: boolean;
	onFormStateChange: (params: {
		valid: boolean;
		warning: FormWarnings<FormData>;
	}) => void;
	onFormValuesChange: (params: {
		values: FormData;
		valid: boolean;
		vesselId?: string;
	}) => void;
	isAssignMode?: boolean;
	// from mapStateToProps
	isCurrentUsersGroupTypeISSCluster: boolean;
	isCurrentUsersGroupTypeISSHub: boolean;
	isCurrentUserWithRoleHub: boolean;
	formValues: FormData;
	activeWizard: WizardFormProps;
	initialValues: Partial<FormData>;
	isLoaded: boolean;
	vessel: CompanyVessel | undefined;
	// from dispatchToProps
	retrievePortCallsStatic: typeof retrievePortCallsStatic;
}

export interface PageFormProps
	extends InjectedFormProps<FormData, PageBaseProps>,
		PageBaseProps {}

class PageForm extends React.Component<PageFormProps> {
	componentDidMount() {
		const { isLoaded } = this.props;
		if (!isLoaded) {
			this.props.retrievePortCallsStatic();
		}
	}

	componentDidUpdate(prevProps: PageFormProps) {
		const {
			formValues,
			valid,
			warning,
			hasDuplication,
			vessel,
			isAssignMode
		} = this.props;
		if (
			(!isEqual(
				omitByNil(formValues.portCall),
				omitByNil(prevProps.formValues.portCall)
			) &&
				formValues.portCall &&
				formValues.portCall.vessel) ||
			prevProps.vessel !== vessel
		) {
			if (
				(formValues.jobTypeId?.key || isAssignMode) &&
				formValues.hubPrincipalCompany?.key
			) {
				vessel &&
					setTimeout(() => {
						this.props.onFormValuesChange({
							values: this.props.formValues,
							valid: this.props.valid,
							vesselId: vessel.vessel.id
						});
					});
			} else {
				// valid state is reflected in the next cycle after values
				setTimeout(() => {
					this.props.onFormValuesChange({
						values: this.props.formValues,
						valid: this.props.valid
					});
				});
			}
		}

		const isPortChanged =
			formValues.portCall &&
			prevProps.formValues.portCall &&
			!isEqual(formValues.portCall.port, prevProps.formValues.portCall.port);

		if (isPortChanged) {
			// Check if value should reset to prevent validation to take effect
			if (formValues.performingAgent) {
				this.props.change(FormFieldName.PERFORMING_AGENT, null);
			}
			if (formValues.controllingAgent) {
				this.props.change(FormFieldName.CONTROLLING_AGENT, null);
			}
		}

		if (valid !== prevProps.valid || warning !== prevProps.warning) {
			this.props.onFormStateChange({
				valid,
				warning
			});
		}

		if (hasDuplication !== prevProps.hasDuplication) {
			this.props.change(
				FormFieldName.CREATE_NEW_PORT_CALL,
				hasDuplication ? false : null
			);
		}
	}

	render() {
		const { formValues, isLoaded } = this.props;
		return isLoaded ? (
			<PageFormWrapper hubPrincipalCompany={formValues.hubPrincipalCompany}>
				<Fields />
			</PageFormWrapper>
		) : (
			<Loading />
		);
	}
}

export default connect(
	(state: AppState, { isAssignMode }: Partial<PageBaseProps>) => {
		const formValues = getPortCallFormComputedValues(state) as FormData;
		const activeWizard = getWizardById(state, PORT_JOB_WIZARD_ID);
		let vessel;
		if (
			(formValues.jobTypeId?.key || isAssignMode) &&
			formValues.hubPrincipalCompany?.key &&
			formValues.portCall &&
			formValues.portCall.vessel
		) {
			vessel = getCompanyVesselsMapByRecordId(
				state,
				formValues.hubPrincipalCompany?.key,
				formValues.portCall.vessel.key
			);
		}
		return {
			formValues,
			activeWizard,
			initialValues: getPortCallInitialValues(state),
			isCurrentUsersGroupTypeISSCluster: getIsCurrentUsersGroupTypeISSCluster(
				state
			),
			isCurrentUsersGroupTypeISSHub: getIsCurrentUsersGroupTypeISSHub(state),
			isCurrentUserWithRoleHub: getIsCurrentUserHub(state),
			isLoaded: getIsStaticLoaded(state),
			vessel
		};
	},
	{
		retrievePortCallsStatic
	}
)(
	reduxForm<FormData, PageBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		warn: warn as any, // eslint-disable-line @typescript-eslint/no-explicit-any
		validate: validate as any, // eslint-disable-line @typescript-eslint/no-explicit-any
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true,
		onChange
	})(PageForm)
);
