import {
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';

import actionCreator from '../bankAccountsActionCreator';

const ACTION_NAME = 'RETRIEVE_BANK_ACCOUNT';
export const retrieveBankAccountAsync = actionCreator.async<
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'section' } });

export const retrieveBankAccount = actionCreator<RetrieveBankAccountRequest>(
	ACTION_NAME
);
