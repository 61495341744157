import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getGroupUsersCount } from 'store/groupUsers/groupUsersSelectors';
import { getGroupDeletingFetchStatus } from 'store/groups/groupsSelectors';
import { retrieveGroupUsers } from 'store/groupUsers';
import { deleteGroup } from 'store/groups';
import { AppState } from 'store-types';
import { DeleteGroupModalProps } from './DeleteGroupModal';

export function useDeleteGroupModal({
	modalValue
}: Pick<DeleteGroupModalProps, 'modalValue'>) {
	const [requestedUserCountFor, setRequestedUserCountFor] = useState<string[]>(
		[]
	);
	const dispatch = useDispatch();

	const usersCount = useSelector((state: AppState) =>
		getGroupUsersCount(state, modalValue.id)
	);
	const fetchStatus = useSelector(getGroupDeletingFetchStatus);

	const fetchGroupUsers = useCallback(
		(id: string) => {
			dispatch(retrieveGroupUsers({ id, onlyInThisGroup: true }));
			setRequestedUserCountFor([...requestedUserCountFor, modalValue.id]);
		},
		[dispatch, modalValue.id, requestedUserCountFor]
	);

	useEffect(() => {
		const shouldFetchGroupUsers =
			modalValue &&
			!usersCount &&
			!requestedUserCountFor.includes(modalValue.id);
		if (shouldFetchGroupUsers) {
			fetchGroupUsers(modalValue.id);
		}
	}, [usersCount, modalValue, requestedUserCountFor, fetchGroupUsers]);

	const onSubmit = useCallback(() => {
		dispatch(deleteGroup({ id: modalValue.id, name: modalValue.name }));
	}, [dispatch, modalValue]);

	return {
		usersCount,
		fetchStatus,
		onSubmit
	};
}
