import { keyBy } from 'lodash';
import { FinanceState } from '../financeState';
import {
	RetrieveAvailableDasRequest,
	DaAvailabilityItem
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ResultCollection } from 'services/api/apiTypes';

export const onRetrieveAvailableDas = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveAvailableDasRequest,
	ResultCollection<DaAvailabilityItem>
>('fetchStatuses', 'retrieveAvailableDas', {
	doneReducer: (
		state: FinanceState,
		action: Success<
			RetrieveAvailableDasRequest,
			ResultCollection<DaAvailabilityItem>
		>
	) => {
		const { jobServiceId } = action.params;
		return {
			...state,
			context: {
				...state.context,
				availableDas: {
					...state.context.availableDas,
					[jobServiceId]: keyBy(action.result.elements, 'id')
				}
			}
		};
	}
});
