import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
	TogglePortJobStatusParams,
	TogglePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	retrievePortJob,
	togglePortJobStatusAsync
} from 'store/portJobs/actions';
import {
	getPortJobConcurrencyTokenByCode,
	getActivePortJobCode
} from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';

const apiCall = Api.PortJobs.togglePortCallJobStatus;

export const executor = function*(
	payload: TogglePortJobStatusParams
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const portJobStatus = payload.jobStatus;
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		portJobCode
	);
	const request = {
		...payload,
		portCallId,
		portJobCode,
		portJobStatus,
		concurrencyToken
	};
	yield put(togglePortJobStatusAsync.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			togglePortJobStatusAsync.done({
				result: { status: response.data.status },
				params: request,
				response
			})
		);
		yield put(
			retrievePortJob({
				jobCode: portJobCode,
				portCallId,
				expand: ExpandPortJobType.OPERATIONS
			})
		);
	} catch (error) {
		yield put(
			togglePortJobStatusAsync.failed({
				error,
				params: request
			})
		);
	}
};

export const togglePortJobStatusWatcher = makeTakeLatestWatcher<
	TogglePortJobStatusParams,
	TogglePortJobResponse,
	Error
>(
	{
		api: apiCall,
		async: togglePortJobStatusAsync
	},
	executor
);

export default togglePortJobStatusWatcher;
