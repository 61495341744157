import React from 'react';
import { connect } from 'react-redux';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import DocumentViewerContainer from 'sections/DocumentViewer/DocumentViewerContainer';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { BreadcrumbItem } from 'store/navigation';
import { getSupplierInvoiceBreadcrumbs } from '../../supplierInvoiceUISelectors';
import Contextualization from './Contextualization';
import { AppState } from 'store-types';
import { setIsSupplierInvoiceContextualization } from 'store/supplierInvoice/actions';
import { AppNotifications } from 'containers';

interface AddNewSupplierInvoiceRouteProps extends PortCallTabBaseProps {
	breadcrumbs: BreadcrumbItem[];
	setIsSupplierInvoiceContextualization: typeof setIsSupplierInvoiceContextualization;
}

class AddNewSupplierInvoiceRoute extends React.Component<
	AddNewSupplierInvoiceRouteProps
> {
	componentDidMount() {
		this.props.setIsSupplierInvoiceContextualization({
			isSupplierInvoiceContextualizing: true
		});
	}
	componentWillUnmount() {
		this.props.setIsSupplierInvoiceContextualization({
			isSupplierInvoiceContextualizing: false
		});
	}
	getBreadcrumbs = () => {
		const title = 'New supplier invoice';
		return this.props.breadcrumbs.concat([{ title }]);
	};

	render() {
		const { match } = this.props;

		return (
			<DocumentViewerContainer match={match} withPortCallContainer={false}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<AppNotifications.Alert />
				<AppNotifications.Notification />
				<Contextualization documentId={match.params.documentId} />
			</DocumentViewerContainer>
		);
	}
}

export default connect(
	(state: AppState) => ({
		breadcrumbs: getSupplierInvoiceBreadcrumbs(state)
	}),
	{
		setIsSupplierInvoiceContextualization
	}
)(AddNewSupplierInvoiceRoute);
