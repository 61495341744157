import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import styles from './PreRegistrationForm.module.scss';
import { ReduxFormSubmitButton, Text } from 'components';
import { Input } from 'components/antd';

import { preRegisterSubmit } from 'store/registration/actions';

import {
	PRE_REGISTRATION_FORM_NAME,
	PreRegistrationFormField,
	PreRegistrationFormData
} from 'store/registration/registrationTypes';
import validate from './validate';

interface PreRegistrationFormBaseProps {}
type PreRegistrationFormProps = InjectedFormProps<
	PreRegistrationFormData,
	PreRegistrationFormBaseProps
>;

const PreRegistrationForm = ({ error, form }: PreRegistrationFormProps) => {
	const dispatch = useDispatch();
	const onSubmit = useCallback(() => {
		dispatch(preRegisterSubmit());
	}, [dispatch]);
	return (
		<div className={styles.root}>
			<h2>Pre Registration</h2>

			<Input.ReduxFormItem
				label="First Name"
				required
				name={PreRegistrationFormField.FIRST_NAME}
			/>

			<Input.ReduxFormItem
				label="Last Name"
				required
				name={PreRegistrationFormField.LAST_NAME}
			/>

			<Input.ReduxFormItem
				label="Personal work email (not shared email)"
				required
				name={PreRegistrationFormField.EMAIL}
			/>

			{error && (
				<>
					<Text color="error">{error}</Text>
					<br />
					<br />
				</>
			)}

			<ReduxFormSubmitButton form={form} onClick={onSubmit}>
				Submit
			</ReduxFormSubmitButton>
		</div>
	);
};

export default reduxForm<PreRegistrationFormData, PreRegistrationFormBaseProps>(
	{
		form: PRE_REGISTRATION_FORM_NAME,
		validate
	}
)(PreRegistrationForm);
