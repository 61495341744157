import actionCreator from '../configurationActionCreator';
import { CreateFeedbackManagementRequest } from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'CREATE_FEEDBACK_MANAGEMENT';
export const createFeedbackManagementRequest = actionCreator(ACTION_NAME);

export const createFeedbackManagementRequestAsync = actionCreator.async<
	CreateFeedbackManagementRequest,
	null,
	Error
>(ACTION_NAME);
