import { Failure, Success } from 'typescript-fsa';
import { ThreadsState } from '../threadsState';
import {
	RetrieveThreadsStatsRequest,
	RetrieveThreadsStatsResponse
} from 'services/api/threads/threadsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrieveThreadsStatsStarted = (
	state: ThreadsState,
	{ isSilent }: RetrieveThreadsStatsRequest
): ThreadsState => {
	return isSilent
		? state
		: {
				...state,
				fetchStatuses: {
					...state.fetchStatuses,
					retrievingStats: FetchStatus.PENDING
				}
		  };
};

export const onRetrieveThreadsStatsSuccess = (
	state: ThreadsState,
	{
		params: { isSilent },
		result
	}: Success<RetrieveThreadsStatsRequest, RetrieveThreadsStatsResponse>
): ThreadsState => {
	const newState = {
		...state,
		stats: {
			...state.stats,
			...result
		}
	};
	return isSilent
		? newState
		: {
				...newState,
				fetchStatuses: {
					...state.fetchStatuses,
					retrievingStats: isSilent
						? state.fetchStatuses.retrievingStats
						: FetchStatus.SUCCESS
				}
		  };
};

export const onRetrieveThreadsStatsFailed = (
	state: ThreadsState,
	{ params: { isSilent } }: Failure<RetrieveThreadsStatsRequest, Error>
): ThreadsState => {
	return isSilent
		? state
		: {
				...state,
				fetchStatuses: {
					...state.fetchStatuses,
					retrievingStats: FetchStatus.FAILURE
				}
		  };
};
