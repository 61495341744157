import actionCreator from 'store/finance/financeActionCreator';
import { ReverseSplitResponse } from 'services/api/finance/financeServiceTypes';
import { ReverseSplitServiceRequestParams } from '../sagas/reverseSplitServiceSaga';

const ACTION_NAME = 'REVERSE_SPLIT_SERVICE';

export const reverseSplitServiceAsync = actionCreator.async<
	ReverseSplitServiceRequestParams,
	ReverseSplitResponse,
	Error
>(ACTION_NAME);

export const reverseSplitService = actionCreator<
	ReverseSplitServiceRequestParams
>(ACTION_NAME);
