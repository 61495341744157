import React from 'react';
import { isEmpty } from 'lodash';
import { Text } from 'components';
import { ShowMoreText } from 'components/ReadMore';
import { Row, Col } from 'components/antd';
import GradeLinesGrid from './GradeLinesGrid/GradeLinesGrid';
import { BunkeringPortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';

interface BunkeringOperationProps {
	operation: BunkeringPortJobOperation;
}
const BunkeringOperation = (props: BunkeringOperationProps) => {
	const { operation } = props;
	return (
		<Row>
			<Col xs={8} lg={4}>
				{isEmpty(operation.bunkeringOperationGrades) ? (
					'No grades specified'
				) : (
					<GradeLinesGrid data={operation.bunkeringOperationGrades} />
				)}
			</Col>
			<Col xs={4} lg={8}>
				<Text upper weight="bold">
					Operation Details
				</Text>
				<ShowMoreText showLines={3}>{operation.details || 'None'}</ShowMoreText>
			</Col>
		</Row>
	);
};

export default BunkeringOperation;
