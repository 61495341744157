import actionCreator from '../supplierInvoiceActionCreator';
import { DownloadSupplierInvoiceDocumentParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_SPLIT_SUPPLIER_INVOICES';

export const downloadSplitSupplierInvoiceAsync = actionCreator.async<
	DownloadSupplierInvoiceDocumentParams,
	RetrieveDownloadDocumentResponse,
	Error
>(ACTION_NAME, {
	failed: {
		description: 'Your download has failed. Please try again'
	}
});

export const downloadSplitSupplierInvoice = actionCreator<
	DownloadSupplierInvoiceDocumentParams
>(ACTION_NAME);
