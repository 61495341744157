import actionCreator from '../financeActionCreator';
import { DownloadJobFinanceRevisionRequest } from 'services/api/finance/financeServiceTypes';

export type DownloadJobFinanceRevisionActionParams = Pick<
	DownloadJobFinanceRevisionRequest,
	'revisionId' | 'disbursementAccountType'
>;

const DOWNLOAD_REVISION = 'DOWNLOAD_REVISION';
export const downloadJobFinanceRevisionAsync = actionCreator.async<
	DownloadJobFinanceRevisionRequest,
	null,
	Error
>(DOWNLOAD_REVISION);

export const downloadJobFinanceRevision = actionCreator<
	DownloadJobFinanceRevisionActionParams
>(DOWNLOAD_REVISION);
