export const EDIT_GROUP_FORM_NAME = 'editGroup';
export const PERMISSIONS_FORM_NAME = 'PermissionsForm';
export const GENERAL_INFO_FORM_NAME = 'GeneralInfoForm';
export const PERMISSION_SCOPE_FORM_NAME = 'PermissionScopeForm';

export const FORMS = {
	GeneralInfoForm: GENERAL_INFO_FORM_NAME,
	PermissionsForm: PERMISSIONS_FORM_NAME,
	PermissionScopeForm: PERMISSION_SCOPE_FORM_NAME
};

export enum PermissionScopeTabsKeys {
	COMPANIES_TAB_KEY = 'PERMISSION_SCOPE_COMPANIES',
	VESSELS_TAB_KEY = 'PERMISSION_SCOPE_VESSELS',
	REGIONS_TAB_KEY = 'PERMISSION_SCOPE_REGIONS'
}
