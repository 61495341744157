import { createSelector } from 'reselect';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { getIsActivePortCallLoading } from 'store/portcalls/selectors';
import { getIsPortJobLoading } from 'store/portJobs/selectors';

export const getFinanceBankAccountsBreadcrumbs = createSelector(
	getPortCallLayoutBreadcrumbs,
	breadcrumbs => [
		...breadcrumbs,
		{
			title: 'Bank account details'
		}
	]
);

export const getIsBankAccountsDataLoading = createSelector(
	getIsPortJobLoading,
	getIsActivePortCallLoading,
	(job, call) => job || call
);
