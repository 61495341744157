import { retrievePortCallDuplicatesAsync as async } from 'store/portcalls/actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse
} from 'services/api/portCall/portCallServiceTypes';

export const retrievePortCallDuplicatesWatcher = makeTakeLatestWatcher<
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse,
	Error
>({
	api: Api.PortCall.retrievePortCallDuplicates,
	async
});
