import * as React from 'react';
import { connect } from 'react-redux';

import { Loading, Flex } from 'components';
import { map, isUndefined, isArray } from 'lodash';
import styles from './PortCallLayout.module.scss';

import { Tabs } from 'components/antd';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import PortCallTab from './PortCallTab';

import { navigateTo } from 'utils';
import { retrievePortCall, setPortCallContext } from 'store/portcalls/actions';
import { resetPortCallAlerts } from 'store/portCall/actions';
import { retrieveThreadsPendingAlerts } from 'store/threads/actions';
import {
	getCurrentPortCallTab,
	getPortCallTabs
} from 'store/portcalls/selectors';
import {
	setActivePortJobCode,
	resetPortJobThreads
} from 'store/portJobs/actions';
import { retrieveCountries } from 'store/countries';
import { retrieveUnits } from 'store/units';
import { retrieveBunkerGrades } from 'store/operations';

import {
	getPortJobsForActivePortCall,
	getActivePortCall,
	getIsActivePortCallLoading
} from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import {
	getPortCallLayoutBreadcrumbs,
	getPortCallTabsConfig,
	getPortJobExist
} from './portCallLayoutSelectors';
import {
	getHasPermission,
	getPermissionCodes
} from 'store/auth/selectors/permissionsSelector';
import { PortCallTabBaseProps } from 'sections/PortCall';
import {
	PORTCALL_ID_URL_PARAM,
	PORTJOB_CODE_URL_PARAM,
	PORT_CALL_OPERATION_TAB_ID,
	PORT_CALL_NEW_VP,
	THREAD_ID_URL_PARAM,
	PATHS
} from 'sections/App/RouteParams';
import {
	PortCallTabKey,
	PortCallTabType
} from 'store/portcalls/portCallsTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

import {
	getPortCallId,
	getDefaultJobCode,
	getActivePortCallTabId,
	getPortCallOperationsTab,
	getPortCallThreadId
} from './PortCallLayout.func';
import {
	retrieveFinanceDetails,
	resetFinanceDetails
} from 'store/finance/actions';
import { getSafeFinanceDetails } from 'store/finance/selectors';
import { AppState } from 'store-types';
import { notify } from 'store/notifications/actions';
import { DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE } from 'app-constants';
import { destroy } from 'store/filters/actions';
import { PORT_MESSAGES_FILTER } from 'store/portJobs/portCallMessagesFiltersSync';

export type PortCallLayoutProps = PortCallTabBaseProps &
	ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps;

class PortCallLayout extends React.Component<PortCallLayoutProps> {
	componentDidMount() {
		// Used for CargoGrid
		this.props.retrieveCountries(undefined);

		// Used for GradeLinesGird
		this.props.retrieveUnits();
		this.props.retrieveBunkerGrades();

		const portCallId = getPortCallId(this.props.match);
		if (this.props.hasFinancePermissions) {
			this.props.retrieveFinanceDetails({ portCallId });
		}

		this.props.retrieveThreadsPendingAlerts({ portCallId });

		this.handleDisabledTab();
	}

	componentDidUpdate(prevProps: PortCallLayoutProps) {
		const {
			portcall,
			match: {
				params: { portJobCode }
			},
			activePortCallTab
		} = this.props;

		if (!prevProps.portcall && portcall && portcall.port) {
			this.props.setPortCallContext({
				activePortCallPortId: portcall.port.id
			});
		}
		if (
			(!prevProps.isDisabledTabsConfigReadyToCheck &&
				this.props.isDisabledTabsConfigReadyToCheck) ||
			getActivePortCallTabId(prevProps.match) !==
				getActivePortCallTabId(this.props.match)
		) {
			this.handleDisabledTab();
		}
		if (portJobCode !== prevProps.match.params.portJobCode) {
			this.props.setActivePortJobCode(portJobCode);
		}

		// highlight tab when navigating
		const prevPortCallTab = getActivePortCallTabId(prevProps.match);
		const portCallTab = getActivePortCallTabId(this.props.match);
		if (
			activePortCallTab.key !== portCallTab &&
			prevPortCallTab !== portCallTab
		) {
			this.props.setPortCallContext({
				activePortCallTabId: portCallTab
			});
		}
	}

	componentWillUnmount() {
		this.props.resetPortCallAlerts();
		this.props.resetFinanceDetails();
	}

	getHasTabPermission = (key: PortCallTabKey) => {
		const { portCallTabs, permissionCodes } = this.props;
		const tab = portCallTabs[key];
		return (
			!tab.permissionCode ||
			permissionCodes.some(
				code =>
					(isArray(tab.permissionCode) && tab.permissionCode.includes(code)) ||
					code === tab.permissionCode
			)
		);
	};

	handleDisabledTab = () => {
		const {
			match,
			isDisabledTabsConfigReadyToCheck,
			notifyError,
			tabsDisabledConfig
		} = this.props;
		const portCallTab = getActivePortCallTabId(match);
		const isTabEnabled =
			!isDisabledTabsConfigReadyToCheck || !this.getIsTabDisabled(portCallTab);
		if (
			isTabEnabled &&
			this.getHasTabPermission(portCallTab) &&
			!isUndefined(tabsDisabledConfig[portCallTab])
		) {
			return;
		}
		const jobCodesByTab = this.getPortCallJobCodeByTab();
		const portCallId = getPortCallId(this.props.match);
		const jobCode = jobCodesByTab[PortCallTabKey.OVERVIEW];
		navigateTo(`/${PATHS.portCalls}/${portCallId}/jobs/${jobCode}/overview`);
		// issue with the mounting
		setTimeout(() => {
			notifyError({
				description: DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE,
				duration: 0
			});
		});
	};

	getPortCallJobCodeByTab = (props = this.props) => {
		const { activePortJobCode, availableFinanceJobs, portJobExist } = props;
		const defaultJobCode = getDefaultJobCode(props);
		const isFinanceJobAvailable = availableFinanceJobs.some(
			job => job.code === activePortJobCode
		);
		return {
			[PortCallTabKey.OVERVIEW]: portJobExist
				? activePortJobCode
				: defaultJobCode,
			[PortCallTabKey.FINANCE]: isFinanceJobAvailable
				? activePortJobCode
				: defaultJobCode
		};
	};

	getPathnameForActiveKey = (props: PortCallLayoutProps) => {
		const { activePortCallTab } = props;
		const path = activePortCallTab.path || '';
		const { params } = props.match;

		const jobCodesByTab = this.getPortCallJobCodeByTab(props);

		/**
		 * Obsolete logic is here. It doensn't work for Finance page at all!
		 * That's temp solution here, because we need to remove whole the mechanism with detecting portJob when we switch portCallLayout tabs
		 */
		const jobCode =
			activePortCallTab.key === PortCallTabKey.FINANCE
				? jobCodesByTab[PortCallTabKey.FINANCE]
				: jobCodesByTab[PortCallTabKey.OVERVIEW];

		const portCallThreadId = getPortCallThreadId(props);
		return path
			.replace(PORTCALL_ID_URL_PARAM, getPortCallId(props.match))
			.replace(
				PORT_CALL_OPERATION_TAB_ID,
				getPortCallOperationsTab(props.match)
			)
			.replace(PORT_CALL_NEW_VP, params.isNewVP ? 'isNewVP' : '')
			.replace(PORTJOB_CODE_URL_PARAM, jobCode)
			.replace(
				`/${THREAD_ID_URL_PARAM}`,
				portCallThreadId ? `/${portCallThreadId}` : ''
			);
	};

	onTabChange = (nextPortCallTabKey: PortCallTabKey) => {
		const activeTab = this.props.portCallTabs[nextPortCallTabKey];
		const nextPath = this.getPathnameForActiveKey({
			...this.props,
			activePortCallTab: activeTab
		} as PortCallLayoutProps);
		navigateTo(nextPath);
		this.props.clearFilters(PORT_MESSAGES_FILTER);
	};

	getIsTabDisabled = (tabKey: PortCallTabKey) => {
		return (
			tabKey !== PortCallTabKey.OVERVIEW &&
			this.props.tabsDisabledConfig[tabKey]
		);
	};

	renderTab = (tab: PortCallTabType) => {
		const { children, tabsDisabledConfig, tabsReadOnlyConfig } = this.props;
		const portCallTab = getActivePortCallTabId(this.props.match);
		if (
			isUndefined(tabsDisabledConfig[tab.key]) ||
			!this.getHasTabPermission(tab.key)
		) {
			return null;
		}

		return (
			<Tabs.TabPane
				key={tab.key}
				tab={
					<PortCallTab
						tab={tab}
						isReadOnly={tabsReadOnlyConfig[tab.key]}
						isDisabled={tabsDisabledConfig[tab.key]}
					/>
				}
				disabled={this.getIsTabDisabled(tab.key)}
			>
				{tab.key === portCallTab && children}
			</Tabs.TabPane>
		);
	};

	render() {
		const {
			portCallTabs,
			activePortCallTab,
			isLoadingPortCall,
			portcall
		} = this.props;
		if (!portcall || isLoadingPortCall) {
			return <Loading show />;
		}
		return (
			<Flex fit stretch>
				<RouteBreadcrumbs items={this.props.breadcrumbs} />
				<Tabs
					fit
					tabPosition="left"
					customizable
					className={styles.sidebar}
					activeKey={activePortCallTab.key}
					onChange={this.onTabChange}
				>
					{map(portCallTabs, this.renderTab)}
				</Tabs>
			</Flex>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	const activePortCall = getActivePortCall(state);
	const portJobs = getPortJobsForActivePortCall(state);
	const financeDetails = getSafeFinanceDetails(state);
	const portCallTabsConfig = getPortCallTabsConfig(state);
	return {
		portCallTabs: getPortCallTabs(state),
		activePortCallTab: getCurrentPortCallTab(state),
		activePortJobCode: getActivePortJobCode(state),
		breadcrumbs: getPortCallLayoutBreadcrumbs(state),
		portcall: activePortCall,
		portJobs,
		hasFinancePermissions: getHasPermission(state, PermissionCode.VIEW_FINANCE),
		isLoadingPortCall: getIsActivePortCallLoading(state),
		tabsDisabledConfig: portCallTabsConfig.disabled,
		tabsReadOnlyConfig: portCallTabsConfig.readOnly,
		isDisabledTabsConfigReadyToCheck:
			portCallTabsConfig.isDisabledConfigReadyToCheck,
		portJobExist: getPortJobExist(state),
		availableFinanceJobs: financeDetails?.jobs,
		permissionCodes: getPermissionCodes(state)
	};
};
const mapDispatchToProps = {
	retrievePortCall,
	retrieveCountries,
	resetPortJobThreads,
	setActivePortJobCode,
	resetPortCallAlerts,
	setPortCallContext,
	retrieveUnits,
	retrieveBunkerGrades,
	retrieveFinanceDetails,
	resetFinanceDetails,
	retrieveThreadsPendingAlerts,
	notifyError: notify.error,
	clearFilters: destroy
};
export default connect(mapStateToProps, mapDispatchToProps)(PortCallLayout);
