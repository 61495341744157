import { FetchStatus } from 'services/api/apiTypes';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsLoading, getIsDone } from 'store/selectors';

const getUploadedDocumentsMap = (state: AppState) =>
	state.portCallDocuments.uploadedById;

export const getUploadedDocumentById = (state: AppState, id: string) =>
	getUploadedDocumentsMap(state)[id];

export const getUploadedDocumentConcurrencyToken = createSelector(
	getUploadedDocumentById,
	document => document?.concurrencyToken
);

export const getUploadedDocuments = createSelector(
	getUploadedDocumentsMap,
	uploadedDocuments => Object.values(uploadedDocuments)
);

const getIssuedUploadedDocuments = createSelector(
	getUploadedDocuments,
	uploadedDocuments => uploadedDocuments.filter(doc => doc.isIssued)
);

export const getUploadedDocumentIds = createSelector(
	getUploadedDocuments,
	uploadedDocuments => uploadedDocuments.map(document => document.id)
);

export const getIsEditUploadedDocumentCommentLoading = (
	state: AppState,
	id: string
) => getIsLoading(state.portCallDocuments.fetchStatuses.editUploadedById[id]);

const getDownloadPortCallDocumentStatus = (
	state: AppState,
	portCalDocumentId: string
) => state.portCallDocuments.fetchStatuses.downloadedById[portCalDocumentId];

export const getDownloadPortCallDocumentIsDisabled = createSelector(
	getDownloadPortCallDocumentStatus,
	status => status === FetchStatus.PENDING
);

export const getIsDownloadAllButtonDisabled = createSelector(
	getIssuedUploadedDocuments,
	issuedDocuments => !issuedDocuments.length
);

/**
 * DownloadAll FetchStatus
 */
const getDownloadAllStatus = (state: AppState) =>
	state.portCallDocuments.fetchStatuses.downloadAll;

export const getIsDownloadAllDocumentsStarted = createSelector(
	getDownloadAllStatus,
	status => getIsLoading(status)
);

export const getIsDownloadAllDocumentsDone = createSelector(
	getDownloadAllStatus,
	status => getIsDone(status)
);
