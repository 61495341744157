import { FinanceState } from '../financeState';
import {
	ConvertDBStatusRequest as Request,
	PortJobService,
	PortCallFinance
} from 'services/api/finance/financeServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success, Failure } from 'typescript-fsa';

const setConvertDBStatusFetchStatus = (
	state: FinanceState,
	id: string,
	status: FetchStatus
) => ({
	...state,
	updateStatuses: {
		...state.updateStatuses,
		convertDBStatus: {
			...state.updateStatuses.convertDBStatus,
			[id]: status
		}
	}
});

export function onConvertDBStatusModalOpen(
	state: FinanceState,
	payload: { jobServiceId: string }
): FinanceState {
	const { jobServiceId } = payload;
	return {
		...state,
		context: {
			...state.context,
			convertDBModalServiceId: jobServiceId
		}
	};
}

export function onConvertDBStatusModalClose(state: FinanceState): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			convertDBModalServiceId: null
		}
	};
}

export function onConvertDBStatusStarted(
	state: FinanceState,
	{ jobServiceId }: Request
): FinanceState {
	return setConvertDBStatusFetchStatus(
		state,
		jobServiceId,
		FetchStatus.PENDING
	);
}

export function onConvertDBStatusSuccess(
	state: FinanceState,
	{ result, params }: Success<Request, PortJobService>
): FinanceState {
	const finance = state.finance || ({} as PortCallFinance);
	const serviceElements = finance.services.elements.map(service => {
		if (service.id === result.id) {
			return {
				...service,
				...result
			};
		}
		return service;
	});
	return {
		...setConvertDBStatusFetchStatus(
			state,
			params.jobServiceId,
			FetchStatus.SUCCESS
		),
		finance: {
			...finance,
			services: {
				...finance.services,
				elements: serviceElements
			}
		}
	};
}

export function onConvertDBStatusFailure(
	state: FinanceState,
	{ params: { jobServiceId } }: Failure<Request, Error>
): FinanceState {
	return setConvertDBStatusFetchStatus(
		state,
		jobServiceId,
		FetchStatus.FAILURE
	);
}
