import * as React from 'react';
import { Icon } from 'components/antd';

export interface ValidationItem {
	isValid: boolean;
	message: string;
}

interface ValidationListProps {
	rules: ValidationItem[];
}

const ValidationList: React.FC<ValidationListProps> = ({ rules }) => (
	<ul>
		{rules.map((validate, i) => (
			<li key={`validation-${i}`}>
				<Icon
					type={validate.isValid ? 'check' : 'close'}
					color={validate.isValid ? 'success' : 'error'}
					offset="right"
				/>
				{validate.message}
			</li>
		))}
	</ul>
);

export default ValidationList;
