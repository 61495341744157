import React, { FC, useMemo, useCallback, ReactNode } from 'react';
import { RouteComponentProps } from 'react-router';
import { Tabs } from 'components/antd';
import { PATHS } from 'sections/App/RouteParams';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { navigateTo } from 'utils';
import { ScrollableLayout } from 'components';
import styles from './MasterDataExplorerSectionRoute.module.scss';
import CompaniesTab from './Tabs/CompaniesTab/CompaniesTab';
import BankAccountsTab from './Tabs/BankAccountsTab/BankAccountsTab';
import { MasterDataExplorerTab } from './MasterDataExplorerSectionTypes';

interface MasterDataRouteComponentProps
	extends RouteComponentProps<{
		tabId: string;
	}> {}

interface MasterDataTabConfig {
	key: MasterDataExplorerTab;
	tab: string;
	component: ReactNode;
}

const getTabs = (path: string): MasterDataTabConfig[] => [
	{
		key: MasterDataExplorerTab.COMPANIES,
		tab: 'Companies',
		component: <CompaniesTab path={path} />
	},
	{
		key: MasterDataExplorerTab.BANK_ACCOUNTS,
		tab: 'Bank Accounts',
		component: <BankAccountsTab path={path} />
	}
];

const MasterDataRouteComponent: FC<MasterDataRouteComponentProps> = ({
	match: {
		params: { tabId = MasterDataExplorerTab.COMPANIES },
		path
	}
}) => {
	const tabs = useMemo(() => getTabs(path), [path]);
	const currentTab = tabs.find(
		({ key }) => key === tabId
	) as MasterDataTabConfig;
	const breadcrumbs = useMemo(() => {
		return [
			{
				title: 'Master Data Explorer',
				link: `/${PATHS.masterDataExplorer}`
			},
			{
				title: currentTab.tab,
				link: `/${PATHS.masterDataExplorer}/${currentTab.key}`
			}
		];
	}, [currentTab]);
	const onTabClick = useCallback(key => {
		navigateTo(`/${PATHS.masterDataExplorer}/${key}`);
	}, []);

	return (
		<ScrollableLayout stretch>
			<RouteBreadcrumbs items={breadcrumbs} />
			<ScrollableLayout>
				<Tabs
					type="card"
					fit
					gapTop
					className={styles.tabs}
					onTabClick={onTabClick}
					activeKey={currentTab.key}
				>
					{tabs.map(({ key, tab }) => (
						<Tabs.TabPane tab={tab} key={key}>
							{currentTab.component}
						</Tabs.TabPane>
					))}
				</Tabs>
			</ScrollableLayout>
		</ScrollableLayout>
	);
};

export default MasterDataRouteComponent;
