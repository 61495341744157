import * as React from 'react';
import { Flex } from 'components/Styles';

interface PortJobInfoProps {
	jobTypeName: string;
	performingAgentLabel: string;
}

export default ({ jobTypeName, performingAgentLabel }: PortJobInfoProps) => (
	<section>
		<h1>Job details</h1>
		<Flex row>
			<Flex direction="vertical" flex={1}>
				<h4>Job Type</h4>
				<strong>{jobTypeName}</strong>
			</Flex>
			<Flex direction="vertical" flex={1}>
				<h4>Performing Agent</h4>
				<strong>{performingAgentLabel}</strong>
			</Flex>
		</Flex>
	</section>
);
