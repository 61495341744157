import React from 'react';
import { Flex, Text } from 'components';
import cn from 'classnames';
import { Button } from 'components/antd';
import styles from '../ImageViewer.module.scss';

const toPercent = (scale: number) => (100 * scale).toFixed();

interface ZoomProps {
	defaultValue: number;
	type?: 'panel' | 'default';
	onChange: (value: number) => void;
}

interface ZoomState {
	scale: number;
}
class Zoom extends React.Component<ZoomProps, ZoomState> {
	scaleStep = 0.1;

	constructor(props: ZoomProps) {
		super(props);
		this.state = {
			scale: props.defaultValue
		};
	}

	zoomIn = () => {
		const value = this.state.scale + this.scaleStep;
		this.onChange(value);
	};

	zoomOut = () => {
		const value = this.state.scale - this.scaleStep;
		if (value < this.scaleStep) {
			return;
		}
		this.onChange(value);
	};

	onChange = (value: number) => {
		this.setState({
			scale: Number(value.toFixed(1))
		});

		this.props.onChange(value);
	};

	render() {
		const { scale } = this.state;
		const { type = 'default' } = this.props;
		return (
			<Flex
				className={cn(styles.zoom, styles[type])}
				align="center"
				direction={type === 'panel' ? 'vertical' : 'horizontal'}
			>
				<Button ghost icon="minus" onClick={this.zoomOut} />
				<Text weight="light" className={styles.zoomValue}>
					{toPercent(scale)}%
				</Text>
				<Button ghost icon="plus" onClick={this.zoomIn} />
			</Flex>
		);
	}
}

export default Zoom;
