import React from 'react';
import classNames from 'classnames';
import styles from './Asteriks.module.scss';

interface AsteriksProps {
	position?: 'left' | 'right';
	visible?: boolean;
	className?: string;
}
const Asteriks: React.FC<AsteriksProps> = ({
	position = 'left',
	visible = true,
	className,
	children
}) => {
	return (
		<span
			className={classNames(className, {
				[styles[position]]: visible
			})}
		>
			{children}
		</span>
	);
};

export default Asteriks;
