import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';
import { FilterItem } from 'store/filters/filtersState';
import { ThreadsFilterStatus } from 'types/sections/ThreadsTypes';
import {
	ThreadStatus,
	ThreadSearchGroupType
} from 'services/api/threads/threadsServiceTypes';
import { ReduxQueryFiltersSync } from 'redux-query-sync';

export const THREADS_FILTER_NAME = 'threads';

export const paramsStatusMap = {
	[ThreadsFilterStatus.UNRESOLVED]: [
		ThreadStatus.OPEN,
		ThreadStatus.QUERIED,
		ThreadStatus.OVERDUE
	],
	[ThreadsFilterStatus.ALL]: [],
	[ThreadsFilterStatus.OPEN]: [ThreadStatus.OPEN],
	[ThreadsFilterStatus.QUERIED]: [ThreadStatus.QUERIED],
	[ThreadsFilterStatus.RESOLVED]: [ThreadStatus.RESOLVED],
	[ThreadsFilterStatus.OVERDUE]: [ThreadStatus.OVERDUE]
};

export const DEFAULT_THREADS_FILTER_STATUS = ThreadsFilterStatus.UNRESOLVED;

export enum ThreadsFilterParamName {
	SEARCH = 'searchTerm',
	ONLY_UNREAD = 'onlyUnread',
	STATUS = 'status',
	ASSIGNED_TO_COMPANY = 'assignedToCompany',
	ASSIGNED_TO_GROUP = 'assignedToGroup',
	ASSIGNED_TO_USER = 'assignedToUser',
	ASSIGNED_TO = 'assignedTo',
	THREAD_CONTEXT = 'threadContextId',
	TAGS = 'tags'
}

/**
 * Name for the groups dividers inside the Search
 * and the labels presented in <Filter.Tags>
 */
export const searchTypeMap = {
	[ThreadSearchGroupType.Search]: 'Search Term',
	[ThreadSearchGroupType.Companies]: 'Company',
	[ThreadSearchGroupType.MessageSubjects]: 'Message Subject',
	[ThreadSearchGroupType.From]: 'From',
	[ThreadSearchGroupType.Ports]: 'Ports',
	[ThreadSearchGroupType.Vessels]: 'Vessels',
	[ThreadsFilterParamName.STATUS]: 'Status',
	[ThreadsFilterParamName.ASSIGNED_TO_COMPANY]: 'Mailbox',
	[ThreadsFilterParamName.ASSIGNED_TO_USER]: 'Assigned to',
	[ThreadsFilterParamName.ASSIGNED_TO_GROUP]: 'Assigned to',
	[ThreadsFilterParamName.THREAD_CONTEXT]: 'Context',
	[ThreadsFilterParamName.TAGS]: 'Tags'
};

export const filterTypeMap = {
	[ThreadSearchGroupType.Search]: ThreadsFilterParamName.SEARCH,
	[ThreadSearchGroupType.Companies]: 'companies',
	[ThreadSearchGroupType.MessageSubjects]: 'subjects',
	[ThreadSearchGroupType.From]: 'froms',
	[ThreadSearchGroupType.Ports]: 'ports',
	[ThreadSearchGroupType.Vessels]: 'vessels'
};

/**
 * FilterItem property name to send to the API as value
 */
export const filterIdentifiers = {
	[ThreadSearchGroupType.Search]: 'label',
	[ThreadSearchGroupType.Companies]: 'key',
	[ThreadSearchGroupType.Ports]: 'key',
	[ThreadSearchGroupType.Vessels]: 'key',
	[ThreadSearchGroupType.MessageSubjects]: 'label',
	[ThreadSearchGroupType.From]: 'label'
};

export interface ThreadsFiltersParams {
	onlyUnread?: string;
	search?: FilterItem[];
	status?: FilterItem;
	assignedToCompany?: FilterItem;
	assignedToInbox?: FilterItem;
	assignedTo?: FilterItem;
	assignedToGroup?: FilterItem;
	assignedToUser?: FilterItem;
	threadContextId?: FilterItem;
	tags?: FilterItem[];
}

const filtersSync: ReduxQueryFiltersSync = {
	[THREADS_FILTER_NAME]: {
		selector: (state: AppState) => state.filters[THREADS_FILTER_NAME],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return { name: THREADS_FILTER_NAME, filters: JSON.parse(value) };
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
