import {
	VesselProgrammeState,
	UpdateTerminalRequest
} from '../vesselProgrammeState';

export const onUpdateTerminal = (
	state: VesselProgrammeState,
	{ rotationStepId, terminal }: UpdateTerminalRequest
): VesselProgrammeState => {
	const rotationStep = state.rotationStepsById[rotationStepId];

	return {
		...state,
		edited: true,
		rotationStepsById:
			rotationStepId in state.rotationStepsById
				? {
						...state.rotationStepsById,
						[rotationStepId]: {
							...rotationStep,
							terminal,
							berth: undefined, // always clear berth when terminal change
							/*
								if berth is defined, poiId should be equal to berthId
								else poiId is terminalId
								and on every terminal update, berthId should be cleaned, so poiId should be changed to terminalId
							*/
							poiId: terminal.id
						}
				  }
				: state.rotationStepsById
	};
};
