import client from 'services/client';
import { AxiosTypedPromise } from 'services/api/apiTypes';
import {
	RetrieveCommoditiesRequest,
	RetrieveCommoditiesResponse,
	RetrieveMainCommoditiesResponse
} from './commoditiesServiceTypes';

export const retrieveCommodities = (
	request: RetrieveCommoditiesRequest | undefined
): AxiosTypedPromise<RetrieveCommoditiesResponse> => {
	return client.get('/masterdata/commodities', {
		params: request
	});
};

export const retrieveMainCommodities = () =>
	client.get<RetrieveMainCommoditiesResponse>('/masterdata/maincommodities');

export default {
	retrieveCommodities,
	retrieveMainCommodities
};
