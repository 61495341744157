import actionCreator from '../configurationActionCreator';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'UPLOAD_PREFERRED_AGENTS_FILE';
export const uploadConfigurationPreferredAgentsFile = actionCreator<
	UploadDocumentDoneResponse
>(ACTION_NAME);
export const uploadConfigurationPreferredAgentsFileAsync = actionCreator.async<
	UploadDocumentDoneResponse,
	null,
	Error
>(ACTION_NAME, {
	failed: {
		description: `File validation failed. Import can't be done`
	}
});
