import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { updateBenchmarkAsync as async } from '../actions/updateBenchmark';
import { UpdateBenchmarkActionPayload } from 'services/api/finance/financeServiceTypes';
import { select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

export default makeTakeLatestWatcher<UpdateBenchmarkActionPayload, null, Error>(
	{
		api: Api.Finance.updateBenchmark,
		async,
		*getApiParams(params: { id: string; comment: string }) {
			const portCallId = yield select(getActivePortCallId);
			const jobCode = yield select(getActivePortJobCode);
			return {
				portCallId,
				jobCode,
				...params
			};
		}
	}
);
