import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrievePortCallActAsAsync as async } from 'store/portCall/actions';
import Api from 'services/api';
import {
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse
} from 'services/api/portCall/portCallServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallActAsRequest,
	RetrievePortCallActAsResponse,
	Error
>({
	async,
	api: Api.PortCall.retrievePortCallActAs
});
