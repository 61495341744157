// Actions related to quick job assign to the thread process
import actionCreator from '../threadActionCreator';
import { PortCallJobSearchResult } from 'services/api/portCalls/portCallsServiceTypes';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';
import {
	AssignJobToThreadParams,
	AssignJobToThreadResponse
} from 'services/api/threads/threadsServiceTypes';

export const openThreadJobSearch = actionCreator('OPEN_JOB_SEARCH_FOR_THREAD');
export const closeThreadJobSearch = actionCreator(
	'CLOSE_JOB_SEARCH_FOR_THREAD'
);
const SEARCH_ACTION_NAME = 'SEARCH_JOB_FOR_THREAD';
export interface SearchForJobThreadRequest {
	searchTerm: string;
	mainPrincipalId: string;
}
export const searchJobForThreadAsync = actionCreator.async<
	SearchForJobThreadRequest,
	PortCallJobSearchResult[],
	AxiosGeneralError
>(SEARCH_ACTION_NAME);
export const searchJobForThread = actionCreator<string>(SEARCH_ACTION_NAME);

const QUICK_ASSIGN_ACTION_NAME = 'QUICK_ASSIGN_THREAD_TO_JOB';
export const quickAssignJobToThread = actionCreator<string>(
	QUICK_ASSIGN_ACTION_NAME
);
export const quickAssignJobToThreadAsync = actionCreator.async<
	AssignJobToThreadParams,
	AssignJobToThreadResponse,
	AxiosGeneralError
>(QUICK_ASSIGN_ACTION_NAME);
