import React from 'react';
import { connect } from 'react-redux';
import {
	setActivePortJobCode,
	resetActivePortJobCode,
	retrievePortJob
} from 'store/portJobs/actions';
import { setPortCallContext } from 'store/portcalls';

import {
	getPortJobsForActivePortCall,
	getActivePortCall
} from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import {
	getActivePortCallTabId,
	getActivePortCallId
} from 'store/portcalls/selectors';
import { JobCodeTag, NoColorTag } from 'components';
import { Tabs } from 'components/antd';
import { TabsProps } from 'components/antd/Tabs/Tabs';
import {
	PortCall,
	ExpandPortJobType
} from 'services/api/portCalls/portCallsServiceTypes';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';

import { navigateTo } from 'utils';
import { AppState } from 'store-types';
import {
	getPortJobExist,
	getIsValidJobCode
} from '../../Layout/portCallLayoutSelectors';
import { SHOW_ALL_JOBS_KEY } from 'sections/PortCall/PortJob/PortJobConstants';
import {
	PermissionCode,
	EntityPermissionType,
	EntityPermission
} from 'services/api/permissions/permissionsServiceTypes';
import { EntityPermissionFetcher } from 'containers';
import { sortBy, remove } from 'lodash';
import {
	getIsCurrentUserHub,
	getIsCurrentUserPrincipal
} from 'store/auth/selectors';
import { PermissionButton } from 'components/Permission';
import styles from '../JobDetails.module.scss';

export interface PortJobTab {
	jobCode: PortJob['code'];
	operationIndicator?: React.ReactNode;
	financeIndicator?: React.ReactNode;
	children: React.ReactNode;
}
type JobDetailsProps = ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps &
	TabsProps & {
		tabs: PortJobTab[];
		destroyInactiveTabPane?: boolean;
		onJobCodeChange: (activeKey: string) => void;
		activePortCall: PortCall;
	};

class JobDetailsTabs extends React.Component<JobDetailsProps> {
	componentDidMount() {
		const { activePortCall, activePortJobCode, portJobs } = this.props;
		const code =
			activePortJobCode === SHOW_ALL_JOBS_KEY ||
			!activePortJobCode ||
			!this.isPortJobCodeValid()
				? portJobs[0].code
				: activePortJobCode;

		this.props.setActivePortJobCode(code);
		this.props.setPortCallContext({
			activePortCallPortId: activePortCall.port.id
		});
		this.retrievePortJob();
	}

	componentDidUpdate(prevProps: JobDetailsProps) {
		const { activePortCallTabId, activePortJobCode } = prevProps;

		if (activePortCallTabId !== this.props.activePortCallTabId) {
			/** Prevent Not Found when coming from 'all' to any other tab */
			if (activePortJobCode === SHOW_ALL_JOBS_KEY) {
				this.props.setActivePortJobCode(this.props.portJobs[0].code);
			}
		}

		if (activePortJobCode !== this.props.activePortJobCode) {
			this.retrievePortJob();
		}
	}

	isPortJobCodeValid = () => {
		return this.props.portJobs.some(
			portJob => portJob.code === this.props.activePortJobCode
		);
	};

	retrievePortJob = () => {
		const { activePortCallId, activePortJobCode, isValidJobCode } = this.props;
		/**
		 * Ensure current activePortJobCode is either valid
		 * and not equal to SHOW_ALL_JOBS_KEY
		 */
		if (!isValidJobCode || !activePortJobCode) {
			return;
		}
		this.props.retrievePortJob({
			portCallId: activePortCallId,
			jobCode: activePortJobCode,
			expand: ExpandPortJobType.OPERATIONS,
			// not to show reacting to changes popup
			// when the page is opened directly
			skipWarn: !document.referrer
		});
	};

	onTabChange = (activeKey: string) => {
		this.props.setActivePortJobCode(activeKey);
		this.props.onJobCodeChange(activeKey);
	};

	onAddNewJob = () => {
		this.props.resetActivePortJobCode();
		navigateTo(`/portcalls/${this.props.activePortCallId}/jobs/new/2`);
	};

	getSortedTabs = () => {
		const tabs = [...this.props.tabs];

		// sorting tabs whithout "ALL jobs" tab
		const allJobsTab = remove(tabs, tab => tab.jobCode === SHOW_ALL_JOBS_KEY);
		const sortedTabs = sortBy<PortJobTab>(tabs, tab => tab.jobCode);

		// If we have "All jobs" put it in the beginning of sorted array
		const result = [...allJobsTab, ...sortedTabs];
		return result;
	};

	renderAddNewButton = (entityPermission?: EntityPermission) => (
		<PermissionButton
			permissionCode={PermissionCode.MANAGE_PORTCALL}
			disabled={!entityPermission?.canAdd}
			type="primary"
			transparent
			icon="plus"
			onClick={this.onAddNewJob}
			tooltip={{ placement: 'right' }}
		>
			Add New Job
		</PermissionButton>
	);

	render() {
		const {
			activePortCallId,
			activePortJobCode,
			tabs,
			className,
			isAddNewButtonVisible,
			destroyInactiveTabPane,
			...props
		} = this.props;

		const sortedTabs = this.getSortedTabs();

		return (
			<Tabs
				activeKey={activePortJobCode}
				tabPosition="top"
				type="card"
				onChange={this.onTabChange}
				className={className}
				gapTop
				destroyInactiveTabPane={destroyInactiveTabPane}
				tabBarExtraContent={
					isAddNewButtonVisible && (
						<EntityPermissionFetcher
							entityKey={activePortCallId}
							entityType={EntityPermissionType.PORTCALL}
							showLoader={false}
							skipIfHasData
							error={this.renderAddNewButton}
						>
							{this.renderAddNewButton}
						</EntityPermissionFetcher>
					)
				}
				{...props}
			>
				{sortedTabs.map(
					({ jobCode, operationIndicator, financeIndicator, children }) => (
						<Tabs.TabPane
							tab={
								jobCode === SHOW_ALL_JOBS_KEY ? (
									<NoColorTag
										isSelected={activePortJobCode === SHOW_ALL_JOBS_KEY}
									>
										ALL JOBS
									</NoColorTag>
								) : (
									<span className={styles.jobCodeTab}>
										<JobCodeTag jobCode={jobCode}>{jobCode}</JobCodeTag>
										<span className={styles.operationDisableIcon}>
											{operationIndicator}
											{financeIndicator}
										</span>
									</span>
								)
							}
							key={jobCode === SHOW_ALL_JOBS_KEY ? SHOW_ALL_JOBS_KEY : jobCode}
						>
							{children}
						</Tabs.TabPane>
					)
				)}
			</Tabs>
		);
	}
}
const mapStateToProps = (state: AppState) => ({
	activePortJobCode: getActivePortJobCode(state),
	activePortCall: getActivePortCall(state),
	activePortCallId: getActivePortCallId(state),
	activePortCallTabId: getActivePortCallTabId(state),
	portJobs: getPortJobsForActivePortCall(state),
	portJobExist: getPortJobExist(state),
	isValidJobCode: getIsValidJobCode(state),
	isAddNewButtonVisible:
		getIsCurrentUserHub(state) || getIsCurrentUserPrincipal(state)
});
const mapDispatchToProps = {
	setActivePortJobCode,
	setPortCallContext,
	resetActivePortJobCode,
	retrievePortJob
};
export default connect(mapStateToProps, mapDispatchToProps)(JobDetailsTabs);
