import { CommonMetadataAction } from 'app-constants';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { passArgument } from 'store/utils';
import { getPortCallDocument } from 'store/portCallDocuments/selectors';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

export const getOperations = (state: AppState) => state.operations.operations;

export const getOperationById = createSelector(
	getOperations,
	passArgument<string>(),
	(operations, id) => operations.find(op => op.id === id)
);

export const getOperationTypeCodeById = createSelector(
	getOperationById,
	operation => operation?.operationTypeCode
);

export const getCanDeleteOperationsDocumentPage = createSelector(
	getPortCallDocument,
	document =>
		document?.metadata &&
		!!getEntityMetadataAction<CommonMetadataAction>(
			document.metadata.actions,
			CommonMetadataAction.DELETE
		)
);

export const getBunkerGradesMap = (state: AppState) =>
	state.operations.bunkerGradesById;

export const getBunkerGrades = createSelector(getBunkerGradesMap, grades =>
	Object.values(grades)
);
