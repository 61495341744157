import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
	Align,
	Amount,
	FormattedTimeExtended,
	PageHeader,
	Text
} from 'components';
import { Row, Col, Tooltip, Icon } from 'components/antd';
import { Justify } from 'components/types';
import {
	getSavingsDetails,
	getIndicativeRates,
	getIndicativeRateOn
} from 'store/savings/savingsSelectors';
import { Savings } from 'services/api/savings/savingsServiceTypes';
import SavingsValueList from 'sections/PortCall/Savings/ValueList/SavingsValueList';
import { JobType, jobTypeLabel } from 'store/portJobs/constants';
import { isEmpty } from 'lodash';
import styles from '../Savings.module.scss';
import moment from 'moment';

const FirstRow = ({ job }: { job: Savings }) => {
	const indicativeRate = useSelector(getIndicativeRates);
	const indicativeRateOn = useSelector(getIndicativeRateOn);
	const headerCols = useMemo(() => {
		const cols = [
			{
				label: 'Last Updated',
				key: job.lastUpdated ? (
					<FormattedTimeExtended
						value={job.lastUpdated}
						convertFromUtc
						timeAgo
					/>
				) : (
					'--'
				)
			},
			{ label: 'Job Type', key: jobTypeLabel[job.jobType] }
		];
		if (job.jobType === JobType.APPOINTMENT) {
			cols.push({ label: 'Appointer', key: job.appointer });
		} else if (job.jobType === JobType.NOMINATION) {
			job.nominator && cols.push({ label: 'Nominator', key: job.nominator });
		} else if (job.jobType === JobType.CHARTERER_OVERSIGHT) {
			job.nominator && cols.push({ label: 'Charterer', key: job.nominator });
		}
		cols.push({
			label: 'Agent Type',
			key: job.agentType
		});
		cols.push({
			label: 'Performing Agent',
			key: job.performingAgent
		});
		return cols;
	}, [job]);

	return (
		<Row>
			<Col sm={8} justify={Justify.BETWEEN}>
				<SavingsValueList inline dataSource={headerCols} />
			</Col>
			<Col sm={4}>
				{indicativeRate && !isEmpty(indicativeRate) && (
					<Row>
						<Col>*Ind.Rate:</Col>
						<Align align="right">
							<Tooltip
								placement="topRight"
								title={`Indicative Rate Date: ${moment(indicativeRateOn).format(
									'DD MMM YYYY'
								)}`}
							>
								<Icon type="info" color="primary" offset="right" />
							</Tooltip>
						</Align>

						{indicativeRate.map(rateSet => (
							<Align
								align="right"
								key={rateSet.code}
								className={styles.indicativeRate}
							>
								({rateSet.code})
								<br />
								<Text weight="bold">
									<Amount value={rateSet.name} formatType="rounding" />
								</Text>
							</Align>
						))}
					</Row>
				)}
			</Col>
		</Row>
	);
};
const Header = function() {
	const job = useSelector(getSavingsDetails);
	return <PageHeader shadowed>{job && <FirstRow job={job} />}</PageHeader>;
};

export default Header;
