import { FeedbackManagementRequestId } from 'services/api/companies/companiesServiceTypes';
import { downloadFeedbackManagementRequestAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { call, put } from 'redux-saga/effects';
import { getDownloadUrlExecutor } from 'store/documents/sagas/pollDownloadUrlSaga';

export const downloadExecutor = function*(params: FeedbackManagementRequestId) {
	const GETApi = Api.Companies.downloadFeedbackManagementDocuments;

	yield put(async.started(params));

	try {
		const result = yield call(getDownloadUrlExecutor, {
			GETApi,
			params
		});
		yield put(
			async.done({
				result,
				params,
				response: null
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params
			})
		);
	}
};

export default makeTakeLatestWatcher<FeedbackManagementRequestId, null, Error>(
	{
		async
	},
	downloadExecutor
);
