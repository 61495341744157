import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CreateFinanceDocumentsRequest } from 'services/api/finance/financeServiceTypes';

export const onCreateFinanceDocuments = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	CreateFinanceDocumentsRequest,
	null
>('fetchStatuses', 'createDocuments');
