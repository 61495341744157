import { forEach, first } from 'lodash';
import {
	FundingRequest,
	PrefundingRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import {
	SendFinanceFormErrors,
	SendFinanceFormValues,
	SEND_FINANCE_FORM
} from './SendFinanceModalTypes';
import { FormErrors } from 'redux-form';
import runValidations, {
	hasNumber,
	minValue,
	required,
	maxValue
} from 'utils/validations';
import {
	FinanceStage,
	PortCallFinanceRevision
} from 'services/api/finance/financeServiceTypes';
import { getFormattedValue } from 'components/Amount/Amount.func';

export const validate = (values: SendFinanceFormValues) => {
	let errors = {};
	if (!values?.fundingTable) {
		return (errors = { jobStatus: 'No Data' } as FormErrors<
			SendFinanceFormErrors
		>);
	}
	errors = {
		...errors,
		fundingTable: values.fundingTable.map(fundingRow => {
			if (fundingRow.selectDA) {
				return {
					fundingAmount: first(
						runValidations(fundingRow.fundingAmount, [
							hasNumber,
							minValue(0),
							maxValue(Number(fundingRow.balanceAmount), false),
							required
						])
					),
					fundingPercent: first(
						runValidations(fundingRow.fundingPercent, [
							hasNumber,
							minValue(0),
							maxValue(100, false),
							required
						])
					)
				};
			}
			return {
				selectDA: values.fundingTable.some(fund => fund.selectDA)
					? undefined
					: 'Not Selected'
			};
		}),
		jobStatus: required(values.jobStatus),
		isPrefundRequested: required(values.isPrefundRequested)
	};
	return errors as FormErrors<SendFinanceFormErrors>;
};

export const initialFormData = (
	data: PrefundingRequest | null,
	selectedJobStatus?: FinanceStage
) => {
	const form = {};
	const dataArray = [] as FundingRequest[];
	if (data?.disbursementAccounts) {
		if (selectedJobStatus) {
			const validData = data.disbursementAccounts.filter(
				data => data.financeStage === selectedJobStatus
			);
			forEach(validData, _funding => {
				dataArray.push(Object[validData.length]);
				form[SEND_FINANCE_FORM] = {
					fundingTable: dataArray.map((value, index) => ({
						...value,
						fundingAmount: '0',
						fundingPercent: '0',
						daOutId: validData[index]?.id,
						currencyCode: validData[index]?.currencyCode,
						totalAmount: validData[index]?.totalAmount,
						balanceAmount: validData[index]?.balanceAmount,
						jobStatus: validData[index]?.financeStage,
						selectDA: false,
						disbursementAccountLabel:
							validData[index]?.disbursementAccountNumber
					})) as FundingRequest[]
				};
			});
		} else {
			forEach(data.disbursementAccounts, _funding => {
				dataArray.push(Object[data.disbursementAccounts.length]);
				form[SEND_FINANCE_FORM] = {
					fundingTable: dataArray.map((value, index) => ({
						...value,
						fundingAmount: '0',
						fundingPercent: '0',
						daOutId: data.disbursementAccounts[index]?.id,
						currencyCode: data.disbursementAccounts[index]?.currencyCode,
						totalAmount: data.disbursementAccounts[index]?.totalAmount,
						balanceAmount: data.disbursementAccounts[index]?.balanceAmount,
						jobStatus: data.disbursementAccounts[index]?.financeStage,
						selectDA: false,
						disbursementAccountLabel:
							data.disbursementAccounts[index]?.disbursementAccountNumber
					})) as FundingRequest[]
				};
			});
		}
	}

	return form;
};

export const prefundRequested = (data: FundingRequest[]) => {
	const values = data.filter(fund => fund.selectDA);
	values.forEach(value => {
		value.balanceAmount = getFormattedValue(
			value.balanceAmount,
			'finance',
			2,
			false
		);
		value.totalAmount = getFormattedValue(
			value.totalAmount,
			'finance',
			2,
			false
		);
		value.fundingAmount = getFormattedValue(
			value.fundingAmount,
			'finance',
			2,
			false
		);
		value.fundingPercent = getFormattedValue(
			value.fundingPercent,
			'finance',
			2,
			false
		);
	});
	return values;
};

export const getRevisionIdByJobStatus = (
	jobStatus: FinanceStage,
	data?: PortCallFinanceRevision[]
) => {
	const value = data?.filter(rev => rev.financeStage === jobStatus);
	return value?.find(
		rev => rev.financeStage === jobStatus && rev.number === value?.length
	)?.id;
};
