import { SupplierInvoiceContextualizationFormState } from '../supplierInvoiceContextualizationReducer';
import {
	isPortJobServiceSelected,
	isPortJobServiceInPageSelected
} from '../selectors/contextualizationFormSelectors';
import { InitPortJobsInContextualizationFormParams } from '../actions';

export const onInitPortJobsInContextualizationForm = (
	state: SupplierInvoiceContextualizationFormState,
	{
		elements,
		shouldAllServicesBeDeselected
	}: InitPortJobsInContextualizationFormParams
) => ({
	...state,
	values: {
		...state.values,
		pages: state.values.pages.map((_page, index) => ({
			portJobs: elements.map(({ id: portJobId, services }) => ({
				id: portJobId,
				services: services.map(({ id }) => ({
					id,
					isSelected: shouldAllServicesBeDeselected
						? false
						: isPortJobServiceInPageSelected(
								state.values.pages,
								portJobId,
								id,
								index
						  )
				}))
			}))
		})),
		portJobs: elements.map(({ id: portJobId, services }) => ({
			id: portJobId,
			services: services.map(({ id, totalAmount, comment }) => ({
				id,
				totalAmount: `${totalAmount}`,
				serviceComment: comment,
				isSelected: shouldAllServicesBeDeselected
					? false
					: isPortJobServiceSelected(state.values.portJobs, portJobId, id)
			}))
		}))
	}
});
