import { Action } from 'typescript-fsa';
import { call, select, takeLatest } from 'redux-saga/effects';
import { navigateTo } from 'utils';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { AddSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { FormData } from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { getContextualizationFormValues } from 'store/form/supplierInvoiceContextualization/selectors';
import Api from 'services/api';
import { PATHS } from 'sections/App/RouteParams';
import { createSupplierInvoiceAsync } from '../../actions';
import prepareRequest from './prepareRequest';
import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';

const {
	createSupplierInvoice,
	createSupplierInvoiceViaPostCredit
} = Api.SupplierInvoice;

const makeExecutor = (isCreateViaPostCredit?: boolean) =>
	makeDefaultExecutor<AddSupplierInvoiceRequest, null, Error>({
		api: isCreateViaPostCredit
			? createSupplierInvoiceViaPostCredit
			: createSupplierInvoice,
		async: createSupplierInvoiceAsync,
		*onSuccess() {
			yield call(navigateTo, `/${PATHS.supplierinvoices}`);
		}
	});

export const createExecutor = makeExecutor();
export const createViaPostCreditExecutor = makeExecutor(true);

export const worker = function*({
	payload: { documentId, originalSupplierInvoiceId }
}: Action<AddSupplierInvoiceRequest>) {
	const values: FormData = yield select(getContextualizationFormValues);
	const executor = originalSupplierInvoiceId
		? createViaPostCreditExecutor
		: createExecutor;
	const wizardForm = yield select(getContextualizationWizardForm);

	yield call(
		executor,
		prepareRequest(values, documentId, wizardForm, originalSupplierInvoiceId)
	);
};

export default function*() {
	yield takeLatest(createSupplierInvoiceAsync.type, worker);
}
