import { getValueKey } from 'utils';
import { getIsCurrentUsersGroupTypeISSCluster } from 'store/auth/selectors';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';

import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';

export const getParentId = createSelector(
	(state: AppState) => state.form[FORM.portJob],
	getIsCurrentUsersGroupTypeISSCluster,
	(form, isISSCluster): string | null => {
		return (
			(!isISSCluster && getValueKey(form.values.hubPrincipalCompany)) || null
		);
	}
);
