import * as React from 'react';
import { Gap } from 'components';
import ErrorCorrelationId from './ErrorCorrelationId';
import ErrorPageScreen, {
	ErrorPageScreenProps
} from './ErrorPageScreen/ErrorPageScreen';

import {
	Error400,
	Error403,
	Error404,
	Error500,
	Error503,
	ErrorUnhandled
} from './ErrorPageScreens';

const ERROR_SCREEN = {
	400: Error400,
	403: Error403,
	404: Error404,
	500: Error500,
	503: Error503,
	UNHANDLED: ErrorUnhandled
};

export type ErrorProps = ErrorPageScreenProps & {
	correlationId?: string;
};
const ErrorPage: React.FC<ErrorProps> = ({
	status,
	title,
	description,
	correlationId,
	children
}) => {
	const errorDescription = Boolean(description || correlationId) && (
		<Gap top="lg">
			{description && <>Error: {description}</>}
			<ErrorCorrelationId correlationId={correlationId} />
		</Gap>
	);

	const Screen = (status && ERROR_SCREEN[status]) || null;

	if (Screen) {
		return <Screen description={errorDescription}>{children}</Screen>;
	}

	return (
		<ErrorPageScreen
			status={status}
			title={title}
			description={errorDescription}
		>
			{children}
		</ErrorPageScreen>
	);
};

export default ErrorPage;
