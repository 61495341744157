import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
	ConfirmPortJobPayload,
	ConfirmPortJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import { acceptPortJobAsync } from 'store/portJobs/actions';
import {
	retrievePortCall,
	retrievePortCallAsync,
	setActivePortCallTab
} from 'store/portcalls/actions';
import {
	getIsOperationsDisabled,
	getPortJobConcurrencyTokenByCode
} from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import Api from 'services/api';
import { navigateTo } from 'utils';

const acceptPortJob = function*({
	payload: { portCallId, jobCode, modalId }
}: Action<ConfirmPortJobPayload>): SagaIterator {
	const isOperationsDisabled = yield select(getIsOperationsDisabled);
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		jobCode
	);
	try {
		const response = yield call(Api.PortJobs.acceptPortCallJob, {
			portCallId,
			jobCode,
			concurrencyToken
		} as ConfirmPortJobParams);

		yield put(
			retrievePortCall({
				id: portCallId,
				expand: 'PortJobsBasic'
			})
		);

		yield take(retrievePortCallAsync.done);

		yield put(
			acceptPortJobAsync.done({
				result: { status: response.data.status },
				params: {
					jobCode,
					portCallId,
					modalId
				},
				response
			})
		);

		if (!isOperationsDisabled) {
			yield call(
				navigateTo,
				`/portcalls/${portCallId}/vessel-programme/isNewVP`
			);
			yield put(setActivePortCallTab(PortCallTabKey.VESSEL_PROGRAMME));
		}
	} catch (error) {
		yield put(
			acceptPortJobAsync.failed({
				error,
				params: { portCallId, jobCode, modalId }
			})
		);
	} finally {
		yield put(closeModal(modalId));
	}
};

export function* acceptPortJobWatcher(): SagaIterator {
	yield takeLatest(acceptPortJobAsync.type, acceptPortJob);
}
