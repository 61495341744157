import {
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from '../financeActionCreator';

const RETRIEVE_DOCUMENT_ID = 'RETRIEVE_DOCUMENT_ID';

export const retrieveDAOutDocumentIdAsync = actionCreator.async<
	RetrieveDAOutDocumentIdRequest,
	RetrieveDAOutDocumentIdResponse,
	Error
>(RETRIEVE_DOCUMENT_ID);

export const retrieveDAOutDocumentId = actionCreator<
	RetrieveDAOutDocumentIdRequest
>(RETRIEVE_DOCUMENT_ID);
