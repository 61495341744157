import { all, fork } from 'redux-saga/effects';
import retrieveJobSectionsWatcher from './retrieveJobSectionsSaga';
import retrieveJobSectionsCycleWatcher from './retrieveJobSectionsCycleSaga';
import retrieveDashboardSummaryWatcher from './retrieveDashboardPortCallsSummarySaga';
import retrieveDashboardSummaryCycleWatcher from './retrieveDashboardSummaryCycleSaga';
import retrieveActiveSectionWatcher from './retrieveActiveSectionSaga';
import retrieveDashboardTableauWatcher from './retrieveDashboardTableauSaga';
import retrieveDashboardTableauInfoWatcher from './retrieveDashboardTableauInfoSaga';
import vesselProgrammeJobAlertsSaga from './vesselProgrammeJobAlertsSaga';

export default function*() {
	yield all([
		fork(retrieveDashboardSummaryWatcher),
		fork(retrieveJobSectionsWatcher),
		fork(retrieveJobSectionsCycleWatcher),
		fork(retrieveDashboardSummaryCycleWatcher),
		fork(retrieveActiveSectionWatcher),
		fork(retrieveDashboardTableauWatcher),
		fork(retrieveDashboardTableauInfoWatcher),
		fork(vesselProgrammeJobAlertsSaga)
	]);
}
