import * as React from 'react';
import { NotificationType } from 'store/notifications/notificationsState';
import classNames from 'classnames';
import { Icon } from 'components/antd';
import { IconType } from 'components/antd/Icon/IconTypes';
import AntAlert, { AlertProps as AntAlertProps } from 'antd/lib/alert';
import styles from './Alert.module.scss';

const ICON_TYPE_ICON_MAP: { [key in NotificationType]: keyof IconType } = {
	success: 'check',
	info: 'info',
	warning: 'warning',
	error: 'warning'
};

export interface AlertProps extends AntAlertProps {
	transparent?: boolean;
}

export const Alert: React.FC<AlertProps> = (props: AlertProps) => {
	const type = props.type || 'info'; // Default: info
	const { transparent, ...restProps } = props;
	const iconType = ICON_TYPE_ICON_MAP[type];
	return (
		<AntAlert
			{...restProps}
			className={classNames(props.className, styles.root, {
				[styles.transparent]: transparent
			})}
			icon={<Icon type={iconType} />}
		/>
	);
};

export default Alert;
