import {
	updateBunkeringOperationGradeAsync as async,
	updateBunkeringOperationGrade as action,
	retrievePortCallBunkeringMeasurements
} from '../actions';
import Api from 'services/api';
import {
	UpdateBunkeringOperationGradeActionPayload,
	UpdateBunkeringOperationGradeRequest
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { formatShipQtyValue } from '../portCallMeasurementsHelper';
import { getActivePortCallId } from 'store/portcalls/selectors';

const apiCall = Api.PortCallMeasurements.updateBunkeringOperationGrade;

export function* executor(
	actionParams: UpdateBunkeringOperationGradeActionPayload,
	api: typeof apiCall
): SagaIterator {
	const shipQty = actionParams.updatingGradeData.shipQuantity;
	const shipQtyFormat = formatShipQtyValue(shipQty);
	const params: UpdateBunkeringOperationGradeRequest = {
		...actionParams,
		updatingGradeData: {
			...actionParams.updatingGradeData,
			shipQuantity: shipQtyFormat
		}
	};
	yield put(async.started(params));
	try {
		const response = yield call(api, params);
		yield put(
			async.done({
				result: response.data,
				params,
				response
			})
		);
		const portCallId = yield select(getActivePortCallId);
		yield put(retrievePortCallBunkeringMeasurements({ portCallId }));
	} catch (error) {
		yield put(
			async.failed({
				params,
				error
			})
		);
	}
}

function* worker({
	payload
}: Action<UpdateBunkeringOperationGradeActionPayload>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeEvery(action.type, worker);
}
