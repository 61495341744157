import {
	PortJobService,
	RetrieveServiceRequest
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_SERVICE = 'RETRIEVE_SERVICE';
export const retrieveServiceAsync = actionCreator.async<
	RetrieveServiceRequest,
	PortJobService,
	Error
>(RETRIEVE_SERVICE);

/**
 * We don't need this action anymore. Should be removed after verification that we for sure don't need it
 * @deprecated
 */
export const retrieveService = actionCreator<RetrieveServiceRequest>(
	RETRIEVE_SERVICE
);
