import React from 'react';
import { connect } from 'react-redux';
import {
	FieldArray,
	WrappedFieldArrayProps,
	BaseFieldArrayProps
} from 'redux-form';
import FormPage from './FormPage';
import { FormPageData } from '../ContextualizationFormTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { AppState } from 'store-types';

export interface FieldArrayPagesProps {
	activePage: number;
	hasPagination: boolean;
}

interface FormPagesOwnProps extends FieldArrayPagesProps {
	// from mapStateToProps
	portCallId: string;
}
type FormPagesProps = WrappedFieldArrayProps<FormPageData> & FormPagesOwnProps;

const FormPages: React.SFC<FormPagesProps> = ({
	portCallId,
	fields,
	meta,
	activePage,
	hasPagination
}) => {
	const currentFieldIndex = Math.max(activePage - 1, 0);
	return (
		<div>
			{fields.map((field, i) => {
				if (i !== currentFieldIndex) {
					return null;
				}
				return (
					<FormPage
						key={field}
						portCallId={portCallId}
						form={meta.form}
						hasPagination={hasPagination}
						field={field}
					/>
				);
			})}
		</div>
	);
};

const FormPagesFieldArray = (
	props: Pick<BaseFieldArrayProps, 'name'> & FormPagesOwnProps
) => <FieldArray<FormPagesOwnProps> {...props} component={FormPages} />;

export default connect((state: AppState) => ({
	portCallId: getActivePortCallId(state)
}))(FormPagesFieldArray);
