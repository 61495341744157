import * as React from 'react';
import { isEmpty, isArray } from 'lodash';

import { MessageContentHistoryContext } from 'services/api/messages/messagesServiceTypes';

const hasValue = (value?: string[]): boolean => {
	return isArray(value) && !isEmpty(value);
};

const HistoryHeader: React.SFC<{
	data: MessageContentHistoryContext;
}> = props => {
	const { data } = props;
	return (
		<>
			{data.from && <p>From: {data.from}</p>}
			{hasValue(data.to) && <p>To: {data.to.join(', ')}</p>}
			{hasValue(data.cc) && <p>Cc: {data.cc.join(', ')}</p>}
			{data.subject && <p>Subject: {data.subject}</p>}
		</>
	);
};

export default HistoryHeader;
