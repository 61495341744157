import * as React from 'react';
import AntMenu, { MenuProps } from 'antd/lib/menu';
import './Menu.module.scss';

class Menu extends React.Component<MenuProps, {}> {
	static Divider: React.ComponentClass<{}>;
	static Item: typeof AntMenu.Item;
	static SubMenu: typeof AntMenu.SubMenu;
	static ItemGroup: React.ComponentClass<{
		title?: string | React.ReactNode;
	}>;

	render() {
		return <AntMenu {...this.props} />;
	}
}

Menu.Divider = AntMenu.Divider;
Menu.Item = AntMenu.Item;
Menu.SubMenu = AntMenu.SubMenu;
Menu.ItemGroup = AntMenu.ItemGroup;

export default Menu;
