import { FetchStatus } from 'services/api/apiTypes';
import {
	PortJob,
	PortJobSummaryResponse,
	AppointerType,
	PortJobStatusEntity,
	CustodyRole,
	PortJobOperationTypes,
	AppointData,
	AcceptanceData,
	PortJobMetadataActionCode,
	PortJobAvailableGroup,
	ExtendedMessagingData
} from 'services/api/portJobs/portJobsServiceTypes';
import { Metadata } from 'app-types';

interface PortJobStateDictionaries {
	custodyRoles: CustodyRole[];
	appointers: AppointerType[];
}

// eslint-disable-next-line
export type ContextType = { [key in keyof PortJobStateContext]?: any };

export interface PortJobStateContext {
	activePortJobCode: string;
	activePortJobThreadId: string;
	activePortJobPrincipalEmail: string;
	selectedLinkJobCode: string;
	selectedLinkJobPortCallId: string;
	isForLinkCargoProcess: boolean;
	messages: {
		allIds: string[];
	};
	summary?: PortJobSummaryResponse;
	isMainPrincipalTypeCLS?: boolean;
	creationPortJobPermissions?: {
		hasPermission?: boolean;
		hasVesselMapping?: boolean;
	};
	isJobDataUpToDate: boolean;
	isPortJobOperationUpToDate: boolean;
	isUnlinkNotificationToShow: boolean;
	isAgentInvited: boolean;
	lastSentThreadId: string | undefined;
}

interface PortJobStateFetchStatuses {
	readonly all: FetchStatus;
	readonly adding: FetchStatus;
	readonly summary: FetchStatus;
	readonly updating: FetchStatus;
	readonly deleting: FetchStatus;
	readonly statuses: FetchStatus;
	readonly principalEmail: FetchStatus;
	readonly custodyRoles: FetchStatus;
	readonly appointers: FetchStatus;
	readonly operation: FetchStatus;
	readonly retrieveEditPortJobData: FetchStatus;
	readonly operationDeleting: FetchStatus;
	readonly creationPortJobPermissions: FetchStatus;
	readonly appointData: FetchStatus;
	readonly acceptanceData: FetchStatus;
	readonly metadata: FetchStatus;
	readonly availableGroups: FetchStatus;
	readonly settingDefaultMessagingGroup: FetchStatus;
	readonly extendedMessagingData: FetchStatus;
	readonly sendExtendedMessages: FetchStatus;
	readonly inviteAgentToPortJob: FetchStatus;
	readonly extendedMessagingConfiguration: FetchStatus;
	readonly requestPDA: FetchStatus;
	readonly clonePortJob: FetchStatus;
	readonly operationStatus: FetchStatus;
	readonly enableOperationsCheck: FetchStatus;
	readonly updateEnableOperations: FetchStatus;
	readonly addAppointmentFromNomination: FetchStatus;
}

export interface PortJobState {
	readonly byId: { [id: string]: PortJob };
	readonly error?: Error;
	readonly statuses: PortJobStatusEntity[];
	readonly fetchStatuses: PortJobStateFetchStatuses;
	readonly editableOperation: PortJobOperationTypes | null;
	readonly context: PortJobStateContext;
	readonly dictionaries: PortJobStateDictionaries;
	readonly appointData: AppointData;
	readonly acceptanceData: AcceptanceData;
	readonly metadata: {
		[portJobCode: string]: Metadata<PortJobMetadataActionCode>;
	};
	readonly availableGroups: PortJobAvailableGroup[];
	readonly extendedMessagingOption: string;
	readonly extendedMessagingData: ExtendedMessagingData;
}

export const initialState: PortJobState = {
	byId: {},
	statuses: [],
	editableOperation: null,
	fetchStatuses: {
		all: FetchStatus.IDLE,
		adding: FetchStatus.IDLE,
		summary: FetchStatus.IDLE,
		updating: FetchStatus.IDLE,
		deleting: FetchStatus.IDLE,
		statuses: FetchStatus.IDLE,
		custodyRoles: FetchStatus.IDLE,
		appointers: FetchStatus.IDLE,
		operation: FetchStatus.IDLE,
		retrieveEditPortJobData: FetchStatus.IDLE,
		operationDeleting: FetchStatus.IDLE,
		creationPortJobPermissions: FetchStatus.IDLE,
		appointData: FetchStatus.IDLE,
		principalEmail: FetchStatus.IDLE,
		acceptanceData: FetchStatus.IDLE,
		metadata: FetchStatus.IDLE,
		availableGroups: FetchStatus.IDLE,
		settingDefaultMessagingGroup: FetchStatus.IDLE,
		extendedMessagingData: FetchStatus.IDLE,
		sendExtendedMessages: FetchStatus.IDLE,
		inviteAgentToPortJob: FetchStatus.IDLE,
		extendedMessagingConfiguration: FetchStatus.IDLE,
		requestPDA: FetchStatus.IDLE,
		clonePortJob: FetchStatus.IDLE,
		operationStatus: FetchStatus.IDLE,
		enableOperationsCheck: FetchStatus.IDLE,
		updateEnableOperations: FetchStatus.IDLE,
		addAppointmentFromNomination: FetchStatus.IDLE
	},
	context: {
		activePortJobCode: '',
		activePortJobThreadId: '',
		selectedLinkJobCode: '',
		selectedLinkJobPortCallId: '',
		isForLinkCargoProcess: false,
		activePortJobPrincipalEmail: '',
		messages: {
			allIds: []
		},
		creationPortJobPermissions: {},
		isJobDataUpToDate: false,
		isPortJobOperationUpToDate: false,
		isUnlinkNotificationToShow: false,
		isAgentInvited: false,
		lastSentThreadId: undefined
	},
	dictionaries: {
		custodyRoles: [],
		appointers: []
	},
	appointData: {
		serviceVendors: []
	},
	acceptanceData: {
		vendors: [],
		appointmentAttachments: []
	},
	metadata: {},
	availableGroups: [],
	extendedMessagingOption: '',
	extendedMessagingData: {
		assignedGroup: { id: '', name: '' },
		assignedInbox: { id: '' },
		contextId: '',
		isProtected: false,
		jobCodes: [],
		messages: []
	}
};
