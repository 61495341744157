import * as React from 'react';
import { Input } from 'components/antd';
import { InputProps as AntInputProps } from 'antd/lib/input/Input';
import { FormChangeInput } from 'app-types';
import { format } from './InputNumber.func';

// Redux Form integration
import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';
import { customMap } from 'components/antd/Form/ReduxField/mapError';

export interface InputNumberProps extends AntInputProps {
	/** Maximum decimal part, for 0 - no decimal allowed */
	decimalPart?: number;
	/** Maximum integer part **/
	integerPart?: number;
	/** Maximum digits count (not considering , and .) */
	maxDigits?: number;
	/** Default - true - adds delimiter separator for integer part */
	withDelimiter?: boolean;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onChangeFormattedValue?: (value: string) => void;
	placeholder?: string;
	onBlur?: React.ChangeEventHandler<HTMLInputElement>;
	disabled?: boolean;
	min?: number;
	allowNegative?: boolean;
	allowLeadingZeros?: boolean;
	value?: string; // TS upgrade: set explicitly to avoid further changes
}

interface InputNumberState {
	value?: string;
}

export class InputNumber extends React.Component<
	InputNumberProps,
	InputNumberState
> {
	static ReduxFormItem: CreateReduxField<InputNumberProps>;

	static defaultProps = {
		value: ''
	};

	constructor(props: InputNumberProps) {
		super(props);
		const {
			value,
			decimalPart,
			maxDigits,
			withDelimiter,
			allowNegative,
			allowLeadingZeros
		} = this.props;
		this.state = {
			value: format({
				value,
				decimalPart,
				maxDigits,
				withDelimiter,
				allowNegative,
				allowLeadingZeros
			})
		};
	}

	componentDidUpdate(prevProps: InputNumberProps) {
		const { value } = this.props;
		if (prevProps.value !== value) {
			this.setState({ value });
		}
	}

	onChange = (onChange?: React.ChangeEventHandler<HTMLInputElement>) => (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const {
			onChangeFormattedValue,
			decimalPart,
			maxDigits,
			withDelimiter,
			allowNegative,
			allowLeadingZeros,
			integerPart
		} = this.props;
		const value = format({
			value: event.currentTarget.value,
			decimalPart,
			integerPart,
			maxDigits,
			withDelimiter,
			allowNegative,
			allowLeadingZeros
		});

		this.setState({
			value
		});

		if (onChange) {
			onChange(event);
		}

		if (onChangeFormattedValue) {
			onChangeFormattedValue(value);
		}
	};

	render() {
		const {
			allowNegative,
			allowLeadingZeros,
			value,
			decimalPart,
			integerPart,
			maxDigits,
			withDelimiter,
			onChange,
			onChangeFormattedValue,
			...props
		} = this.props;
		return (
			<Input
				value={this.state.value}
				onChange={this.onChange(onChange)}
				{...props}
			/>
		);
	}
}

const mappedProps = customMap<InputNumberProps>(
	({
		input,
		decimalPart,
		integerPart,
		maxDigits,
		withDelimiter,
		allowNegative,
		allowLeadingZeros
	}) => ({
		onChange: ({ currentTarget: { value } }: FormChangeInput) =>
			input.onChange(
				format({
					value,
					decimalPart,
					integerPart,
					maxDigits,
					withDelimiter,
					allowNegative,
					allowLeadingZeros
				})
			)
	})
);

InputNumber.ReduxFormItem = createFieldComponent<InputNumberProps>(
	InputNumber,
	mappedProps
);

export default InputNumber;
