import React from 'react';
import { connect } from 'react-redux';
import { ScrollableLayout } from 'components';
import { DataFetcher } from 'utils/components';
import { AppState } from 'store-types';
import { getActivePortCall } from 'store/portcalls/selectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import {
	getActivePortJob,
	getSelectedLinkedAppointmentJob
} from 'store/portJobs/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { Alert, Col, Row } from 'components/antd';
import JobDetails from './JobDetails';
import SubHeader from 'sections/PortJob/SubHeader/SubHeader';
import { retrievelinkedJobCargos } from 'store/linkJob/actions';
import { getLinkJobCargosFetchStatus } from 'store/linkJob/selectors/linkJobSelectors';
import { FetchStatus } from 'services/api/apiTypes';
import CargoLinkage from './CargoLinkage/CargoLinkage';
import { retrieveUnits } from 'store/units';

interface Page2Props {
	// from mapStateToProps
	portCall: PortCall;
	portJob: PortJob;
	linkedJob: PortJob;
	linkJobCargosFetchStatus: FetchStatus;
	// from mapDispatchToProps
	retrievelinkedJobCargos: typeof retrievelinkedJobCargos;
	retrieveUnits: typeof retrieveUnits;
}

class Page2 extends React.Component<Page2Props> {
	retrieveCargoDetails = () => {
		this.props.retrieveUnits();
		this.props.retrievelinkedJobCargos({
			portCallId: this.props.portCall.id,
			portJob: this.props.portJob.code,
			linkJob: this.props.linkedJob.code
		});
	};
	render() {
		const {
			portJob,
			linkedJob,
			portCall,
			linkJobCargosFetchStatus
		} = this.props;
		return (
			<>
				<SubHeader title="Job Configuration">
					<Row>
						<Col lg={12}>
							<JobDetails
								portJob={portJob}
								linkedJob={linkedJob}
								portCall={portCall}
							/>
						</Col>
					</Row>
				</SubHeader>
				<ScrollableLayout>
					<DataFetcher
						fetchStatus={linkJobCargosFetchStatus}
						dispatch={this.retrieveCargoDetails}
						error={() => (
							<Alert
								type="warning"
								message="Error while retrieving the cargo details"
							/>
						)}
					>
						{() => <CargoLinkage />}
					</DataFetcher>
				</ScrollableLayout>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		portCall: getActivePortCall(state),
		portJob: getActivePortJob(state),
		linkedJob: getSelectedLinkedAppointmentJob(state),
		linkJobCargosFetchStatus: getLinkJobCargosFetchStatus(state)
	}),
	{ retrievelinkedJobCargos, retrieveUnits }
)(Page2);
