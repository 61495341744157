import { has } from 'lodash';
import { select, call } from 'redux-saga/effects';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { SagaIterator } from 'redux-saga';

function keyExist<T>(object: T, key: string) {
	return has<T>(object, key) && Boolean(object[key]);
}

/**
 * Both fields could be undefined because it can be
 * either passed directly or inferred from the store
 */
export interface RetrievePortCallAndPortJobCode {
	portCallId?: string;
	jobCode?: string;
}

export interface RetrievePortCallAndPortJobCodeResponse {
	portCallId: string;
	jobCode: string;
}

/** Get PortCallId from the store if no param was specified */
export const getActivePortCallIdExecutor = function*(
	params?: RetrievePortCallAndPortJobCode
): SagaIterator {
	const paramExist =
		params && keyExist<RetrievePortCallAndPortJobCode>(params, 'portCallId');
	if (paramExist) {
		return params?.portCallId;
	}
	return yield select(getActivePortCallId);
};

/** Get JobCode from the store if no param was specified */
export const getActivePortJobCodeExecutor = function*(
	params?: RetrievePortCallAndPortJobCode
): SagaIterator {
	const paramExist =
		params && keyExist<RetrievePortCallAndPortJobCode>(params, 'jobCode');
	if (paramExist) {
		return params?.jobCode;
	}
	return yield select(getActivePortJobCode);
};

/**
 * Get PortCallId & JobCode from the store if no param was specified
 */
const getActivePortCallAndPortJobIdsExecutor = function*(
	params?: RetrievePortCallAndPortJobCode
): SagaIterator {
	const portCallId = yield call(getActivePortCallIdExecutor, params);
	const jobCode = yield call(getActivePortJobCodeExecutor, params);

	return {
		...params,
		portCallId,
		jobCode
	} as RetrievePortCallAndPortJobCode;
};

export default getActivePortCallAndPortJobIdsExecutor;
