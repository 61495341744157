import { AppState } from 'store-types';
import { getIsLoaded } from 'store/selectors';

export const getPortCallsStatic = (state: AppState) => state.portcalls.static;

export const getIsStaticLoaded = (state: AppState) =>
	getIsLoaded(state.portcalls.fetchStatuses.static);

export const getPortCallsSettings = (state: AppState) =>
	state.portcalls.settings || { googleApiKey: '', zoom: 15, mapType: 'Hybrid' };
