import { ConfigurationState } from '../configurationState';

export const onSetConfigurationHasUnsavedData = (
	state: ConfigurationState,
	hasUnsavedData: boolean
): ConfigurationState => ({
	...state,
	context: {
		...state.context,
		hasUnsavedData
	}
});
