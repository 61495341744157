import { ThreadReducerState } from '../threadState';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrieveMailboxAvailableGroupRequest,
	RetrieveMailboxAvailableGroupResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

export function onRetrieveMailboxAvailableGroupsStarted(
	state: ThreadReducerState
): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievingMailboxAvailableGroups: FetchStatus.PENDING
		}
	};
}

export function onRetrieveMailboxAvailableGroupsSuccess(
	state: ThreadReducerState,
	{
		result
	}: Success<
		RetrieveMailboxAvailableGroupRequest,
		RetrieveMailboxAvailableGroupResponse
	>
): ThreadReducerState {
	const mailboxAvailableGroups = result.elements;

	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievingMailboxAvailableGroups: FetchStatus.SUCCESS
		},
		mailboxAvailableGroups
	};
}

export function onRetrieveMailboxAvailableGroupsFailed(
	state: ThreadReducerState,
	action: Failure<RetrieveMailboxAvailableGroupRequest, Error>
): ThreadReducerState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrievingMailboxAvailableGroups: FetchStatus.FAILURE
		},
		error: action.error
	};
}
