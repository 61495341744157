import * as React from 'react';
import classNames from 'classnames';

import styles from './ButtonRow.module.scss';

interface ButtonRowProps extends React.HTMLAttributes<HTMLDivElement> {
	/**
	 * Splits buttons inside the group
	 */
	split?: boolean;
}

export const ButtonRow: React.FC<ButtonRowProps> = ({ children, ...props }) => {
	return (
		<div className={classNames(styles.root, props.className)}>{children}</div>
	);
};

export default ButtonRow;
