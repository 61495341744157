import * as React from 'react';
import { PageHeader, Filters } from 'components';
import { Row, Col } from 'components/antd';
import SupplierInvoiceFilters from './SupplierInvoiceFilters';
import {
	SUPPLIER_INVOICE_FORM_ID,
	searchTypeMap
} from 'store/supplierInvoice/filtersSync';
import { FilterItem } from 'store/filters/filtersState';
import { Align, Justify } from 'components/types';
import { connect } from 'react-redux';
import { getFiltersResultByKey } from 'store/filters/filtersSelectors';
import { resetSupplierInvoices } from 'store/supplierInvoice/actions';
import UploadInvoiceButton from './UploadInvoiceButton/UploadInvoiceButton';
import { AppState } from 'store-types';

interface SupplierInvoiceHeaderProps {
	filtersResult: FilterItem[];
	// from mapDispatchToProps
	resetSupplierInvoices: typeof resetSupplierInvoices;
}

class SupplierInvoiceHeader extends React.Component<
	SupplierInvoiceHeaderProps
> {
	resetSupplierInvoices = () => {
		this.props.resetSupplierInvoices();
	};

	render() {
		return (
			<PageHeader shadowed>
				<Filters
					name={SUPPLIER_INVOICE_FORM_ID}
					invokeOnChange={this.resetSupplierInvoices}
				>
					{props => (
						<>
							<Row>
								<Col sm={10}>
									<SupplierInvoiceFilters {...props} />
								</Col>
								<Col sm={2} align={Align.BOTTOM} justify={Justify.END}>
									<UploadInvoiceButton />
								</Col>
							</Row>
							<Filters.Tags
								tags={this.props.filtersResult}
								clearOne={props.clearOne}
								clearAll={props.clearAll}
								typeMap={searchTypeMap}
							/>
						</>
					)}
				</Filters>
			</PageHeader>
		);
	}
}

export default connect(
	(state: AppState) => ({
		filtersResult: getFiltersResultByKey(state, SUPPLIER_INVOICE_FORM_ID)
	}),
	{
		resetSupplierInvoices
	}
)(SupplierInvoiceHeader);
