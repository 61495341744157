import {
	acknowledgeVP,
	acknowledgeVPAsync,
	retrieveVP
} from 'store/vesselProgramme/actions';
import { AcknowledgeVPRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put, select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getVPCurrentUnacknowledgedRevisionId } from 'store/vesselProgramme/selectors';

export function* acknowledgeVPExecutor(
	_actionParams: Action<null>,
	api: typeof Api.VesselProgramme.acknowledgeVP
): SagaIterator {
	const revisionId = yield select(getVPCurrentUnacknowledgedRevisionId);
	if (revisionId === null) {
		return;
	}
	const portCallId = yield select(getActivePortCallId);
	const params: AcknowledgeVPRequest = {
		portCallId,
		revisionId
	};
	yield put(acknowledgeVPAsync.started(params));
	try {
		const response = yield call(api, params);
		yield put(
			acknowledgeVPAsync.done({
				result: undefined,
				params,
				response
			})
		);
		yield put(retrieveVP({ portCallId }));
	} catch (error) {
		yield put(
			acknowledgeVPAsync.failed({
				error,
				params
			})
		);
	}
}

export function* acknowledgeVPWorker(action: Action<null>): SagaIterator {
	yield call(acknowledgeVPExecutor, action, Api.VesselProgramme.acknowledgeVP);
}

export function* acknowledgeVPWatcher(): SagaIterator {
	yield takeLatest(acknowledgeVP.type, acknowledgeVPWorker);
}

export default function*() {
	yield all([fork(acknowledgeVPWatcher)]);
}
