import Api from 'services/api';
import { retrieveFinanceDetailsAsync as async } from '../actions/retrieveFinanceDetails';
import {
	RetrieveFinanceDetailsRequest as request,
	FinanceDetails as response
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<request, response, Error>({
	api: Api.Finance.retrieveFinanceDetails,
	async
});
