import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';

import { retrieveEntityPermissionsForVPAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { EntityPermissionVPActionParams } from 'services/api/permissions/permissionsServiceTypes';
import { isIdAutoGenerated } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';
import { openModal } from 'store/modals/actions';
import { ROTATION_STEP_DELETE_MODAL } from 'store/vesselProgramme/vesselProgrammeConstants';

export default function* rotationStepPermissionSaga(
	request: EntityPermissionVPActionParams,
	api: typeof Api.Permissions.retrieveEntityPermissionsForVP
): SagaIterator {
	if (isIdAutoGenerated(request.entityKey)) {
		yield put(openModal({ name: ROTATION_STEP_DELETE_MODAL }));
		return;
	}
	yield put(retrieveEntityPermissionsForVPAsync.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			retrieveEntityPermissionsForVPAsync.done({
				result: response.data,
				params: request,
				response
			})
		);

		if (!response.data.canDelete) {
			yield put(
				notify.warning(
					`POI can’t be removed as it has  real events/services/documents associated with it. Please remove the associated entities first in order to remove POI.`
				)
			);
			return;
		}

		yield put(openModal({ name: ROTATION_STEP_DELETE_MODAL }));
	} catch (error) {
		yield put(
			retrieveEntityPermissionsForVPAsync.failed({
				error,
				params: request
			})
		);
	}
}
