import React from 'react';
import DocumentViewerPreviewWrapper from 'sections/DocumentViewer/DocumentViewerPreview/DocumentViewerPreviewWrapper';
import DocumentViewerImagePreview, {
	DocumentViewerImagePreviewProps
} from 'sections/DocumentViewer/DocumentViewerPreview/DocumentViewerImagePreview';
import DocumentViewerPreviewNotAvailable from 'sections/DocumentViewer/DocumentViewerPreview/DocumentViewerPreviewNotAvailable';

interface DocumentViewerPreviewProps extends DocumentViewerImagePreviewProps {
	isDocumentPDF: boolean;
	isAnnotatable?: boolean;
	fileName: string;
}

const DocumentViewerPreview: React.SFC<DocumentViewerPreviewProps> = ({
	isDocumentPDF,
	imageUrl,
	imageUrlLoaded,
	isAnnotatable,
	fileName
}) => (
	<DocumentViewerPreviewWrapper>
		{isDocumentPDF ? (
			<DocumentViewerImagePreview
				fileName={fileName}
				imageUrl={imageUrl}
				imageUrlLoaded={imageUrlLoaded}
				isAnnotatable={isAnnotatable}
			/>
		) : (
			<DocumentViewerPreviewNotAvailable />
		)}
	</DocumentViewerPreviewWrapper>
);

export default DocumentViewerPreview;
