// Monadic type for conditional execution on html elements
// it avoids null checking
export default class MaybeElement {
	private el: HTMLElement | null;

	static of(el: Element | null) {
		return new MaybeElement(el);
	}

	constructor(el: Element | null) {
		this.el = el as HTMLElement;
	}

	do(action: (el: HTMLElement) => void) {
		if (this.el) {
			action(this.el);
		}
		return this;
	}
}
