import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';

import Api from 'services/api';

import { updateGroup, updateGroupAsync } from '../actions/updateGroup';
import { getCompanyIds } from 'store/companies/selectors';
import { retrieveGroupScopePermissions } from '../actions/retrieveGroupScopePermissions';
import {
	GroupApplicableScopeTypes,
	UpdateGroupParams
} from 'services/api/groups/groupsServiceTypes';
import { getGroupEditRequest } from '../selectors/updateGroupsRequestSelectors';
import { navigateTo } from 'utils';

export function* updateGroupWorker(
	action: Action<UpdateGroupParams>
): SagaIterator {
	const groupId = action.payload.groupId;
	const groupData = yield select(getGroupEditRequest, groupId);
	let companyIds;
	if (action.payload.companyIds) {
		companyIds = yield select(getCompanyIds, action.payload.companyIds);
	}

	const params = {
		name: groupData.name,
		signature: groupData.signature,
		isMessagingGroup: groupData.isMessagingGroup,
		companyIds,
		groupType: {
			code: groupData.groupType
		},
		functionalPermissions: groupData.functionalPermissions,
		dataPermissions: groupData.dataPermissions
	};
	yield put(updateGroupAsync.started(action.payload));
	try {
		const { scopeType } = action.payload;
		const response = yield call(Api.Groups.updateGroup, groupId, params);
		yield put(
			updateGroupAsync.done({
				result: response.data,
				params: action.payload,
				response
			})
		);
		yield call(navigateTo, `/groups/${groupId}`);
		if (scopeType && scopeType === GroupApplicableScopeTypes.VESSELS) {
			yield put(retrieveGroupScopePermissions({ groupId, scopeType }));
		}
	} catch (error) {
		yield put(
			updateGroupAsync.failed({
				error,
				params: action.payload
			})
		);
	}
}

export function* updateGroupWatcher(): SagaIterator {
	yield takeLatest(updateGroup.type, updateGroupWorker);
}
