import {
	PortCallsState,
	RetrievePortCallsStaticResponse
} from '../portCallsState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';
import { keyBy, mapValues } from 'lodash';

export function onRetrievePortCallsStaticStarted(
	state: PortCallsState
): PortCallsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			static: FetchStatus.PENDING
		}
	};
}

export function onRetrievePortCallsStaticSuccess(
	state: PortCallsState,
	action: Success<undefined, RetrievePortCallsStaticResponse>
): PortCallsState {
	return {
		...state,
		static: mapValues(
			keyBy(action.result, item => item.staticEntityType),
			entity => entity.items
		),
		fetchStatuses: {
			...state.fetchStatuses,
			static: FetchStatus.SUCCESS
		}
	};
}

export function onRetrievePortCallsStaticFailed(state: PortCallsState) {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			static: FetchStatus.FAILURE
		}
	};
}
