import { size } from 'lodash';
import { ApiMiddlewareHandleType } from './apiMiddlewareTypes';
import API_MIDDLEWARE_CONFIG, {
	NotificationValueConfig
} from './apiMiddlewareConfig';
import {
	getBadRequestErrorMessage,
	isResponseError,
	isBadRequestError
} from 'services/api/apiErrorUtils';
import { AxiosError } from 'axios';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';

const { CONFIG, VALUE_CONFIG } = API_MIDDLEWARE_CONFIG[
	ApiMiddlewareHandleType.NOTIFICATION
];

interface GetConfigArgs {
	key: keyof Pick<
		NotificationValueConfig,
		'status' | 'method' | 'statusDefault'
	>;
	type: string | number | undefined;
	error?: AxiosError;
}

function getConfigItem(args: GetConfigArgs) {
	if (!args.type) {
		return;
	}
	switch (args.key) {
		case 'method':
			const byMethod = (CONFIG.method && CONFIG.method[args.type]) || undefined;
			if (!byMethod) {
				return;
			}
			return args.error ? byMethod.error : byMethod.success;
		case 'status':
			return CONFIG.status ? CONFIG.status[args.type] : undefined;
	}

	return;
}

function getConfigValueItem(args: GetConfigArgs, CONFIG = VALUE_CONFIG) {
	const type = args.type || '';
	switch (args.key) {
		case 'method':
			const byMethod = CONFIG.method ? CONFIG.method[type] : undefined;
			if (!byMethod) {
				return;
			}
			return args.error ? byMethod.error : byMethod.success;
		case 'status':
			const byStatus = CONFIG.status[type];
			return byStatus;

		case 'statusDefault':
			const byStatusDefault =
				CONFIG.statusDefault[type] || CONFIG.statusDefault.other;
			return byStatusDefault;
	}

	return;
}

export function shouldOmitSettingConfigValue(args: GetConfigArgs) {
	const config = getConfigItem(args);
	return Boolean(config?.omit);
}

export function getConfigValue(args: GetConfigArgs) {
	const config = getConfigItem(args) || {};
	const value = getConfigValueItem(args);
	if (config.response && isResponseError(args.error)) {
		return {
			...value,
			description: getResponseErrorMessage(args.error)
		};
	}
	return value;
}

export const getResponseErrorMessage = (error: AxiosGeneralError) => {
	if (
		isBadRequestError(error) &&
		!(size(error.response.data) === 1 && error.response.data.error)
	) {
		return getBadRequestErrorMessage(error.response.data);
	}

	return error.response.data.error;
};
