import client from 'services/client';
import { SearchResultCollection } from 'services/api/apiTypes';
import {
	Country,
	RetrieveCountriesRequest,
	RetrieveCountriesResponse,
	RetrieveCountriesResultCollection
} from 'services/api/countries/countriesServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

export const retrieveCountries = (request: RetrieveCountriesRequest) => {
	return client.get<RetrieveCountriesResponse>('/countries', {
		params: request
	});
};

export const getCountryByCode = (
	countryCode: string,
	request: RetrieveCountriesRequest
) =>
	client.get<
		SearchResultCollection<string, Country>
	>(`/countries/${countryCode}`, {
		params: request
	});

const searchCountries = (searchTerm: string) =>
	client
		.get<{
			results: RetrieveCountriesResultCollection;
		}>(`/countries/search`, {
			params: {
				limit: DEFAULT_LIST_LIMIT,
				searchTerm
			}
		})
		.then(response => {
			return response.data.results.map(({ name, code }) => {
				return {
					name,
					id: code
				};
			});
		});

export default {
	retrieveCountries,
	searchCountries,
	getCountryByCode
};
