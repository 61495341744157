import { SHOW_ALL_JOBS_KEY } from 'sections/PortCall/PortJob/PortJobConstants';

import { each, find } from 'lodash';
import { portCallTabsConfig } from 'store/portcalls/portCallsConstants';
import {
	PortCallTabType,
	PortCallTabKey,
	PortCallTabsConfigType
} from 'store/portcalls/portCallsTypes';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import {
	getActivePortCall,
	getPortJobsForActivePortCall,
	isJobAccepted,
	getIsPortCallsFinanceEnabled,
	getIsOperationDisabled,
	activeSavingsPortJobs
} from 'store/portcalls/portCallsSelectors';
import { getCurrentPortCallTab } from 'store/portcalls/selectors';

import { createSelector } from 'reselect';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { getUserType, getIsCurrentUserPrincipal } from 'store/auth/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import {
	getFinanceDetailsFetchStatus,
	getSafeFinanceDetails
} from 'store/finance/selectors/financeSelectors';
import { getIsLoaded } from 'store/selectors';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getHasPermission } from 'store/auth/selectors/permissionsSelector';
import { UserType } from 'services/api/users/userServiceTypes';

export const getPortCallLayoutBreadcrumbs = createSelector(
	getActivePortCall,
	getCurrentPortCallTab,
	(
		portCall: PortCall,
		activePortCallTab: PortCallTabType
	): BreadcrumbItem[] => {
		const breadcrumbs: BreadcrumbItem[] = [
			{ title: 'Port Calls', link: '/portcalls' }
		];
		if (!portCall || !portCall.vessel || !portCall.port) {
			return breadcrumbs;
		}

		return [
			...breadcrumbs,
			{
				title: portCall.vessel.name,
				icon: 'vessel',
				children: [
					{
						title: portCall.port.name,
						icon: 'anchor'
					}
				]
			},
			activePortCallTab.key && {
				title: activePortCallTab.tab
			}
		];
	}
);

export const getNewPortCallMessageBreadcrumbs = createSelector(
	getPortCallLayoutBreadcrumbs,
	breadcrumbs => [...breadcrumbs, { title: 'Compose Message' }]
);

export const getIsJobAccepted = createSelector(
	getPortJobsForActivePortCall,
	portJobs => !!find(portJobs, isJobAccepted)
);

export const getDefaultTabsConfig = createSelector(
	getUserType,
	(userType): PortCallTabsConfigType => portCallTabsConfig[userType]
);

export const getEnabledTabsConfig = (config: PortCallTabsConfigType) => {
	const enabledConfig = { ...config };
	each(config, (_item, key) => {
		enabledConfig[key] = false;
	});
	return enabledConfig;
};

const getIsJobCancelled = createSelector(
	getActivePortCall,
	portCallDetails => portCallDetails?.isCancelled
);

const getIsActiveJobInFinanceDetails = createSelector(
	getSafeFinanceDetails,
	financeDetails => financeDetails.jobs.some(job => job.code)
);

export const getPortCallTabsDisabledMap = createSelector(
	getDefaultTabsConfig,
	getIsJobAccepted,
	getIsActiveJobInFinanceDetails,
	getIsJobCancelled,
	getIsPortCallsFinanceEnabled,
	activeSavingsPortJobs,
	getIsOperationDisabled,
	getUserType,
	(
		defaultTabsMap,
		jobsAccepted,
		isActiveJob,
		jobCancelled,
		financeEnabled,
		savingsEnabled,
		operationDisable,
		userType
	) => {
		if (!jobsAccepted) {
			return {
				...defaultTabsMap,
				[PortCallTabKey.SAVINGS]: jobCancelled || !savingsEnabled
			};
		}

		const disabledMap = {
			...getEnabledTabsConfig(defaultTabsMap),
			[PortCallTabKey.FINANCE]:
				!isActiveJob || (!financeEnabled && userType === UserType.PRINCIPAL),
			[PortCallTabKey.OPERATIONS]: jobCancelled || !operationDisable,
			[PortCallTabKey.VESSEL_PROGRAMME]: jobCancelled || !operationDisable
		};
		return userType !== UserType.LPA
			? {
					...disabledMap,
					[PortCallTabKey.SAVINGS]: jobCancelled || !savingsEnabled
			  }
			: {
					...disabledMap
			  };
	}
);

export const getPortCallTabsConfig = createSelector(
	getPortCallTabsDisabledMap,
	getIsCurrentUserPrincipal,
	getFinanceDetailsFetchStatus,
	state => getHasPermission(state, PermissionCode.VIEW_FINANCE),
	(
		disabled,
		isCurrentUserPrincipal,
		financeDetailsFetchStatus,
		hasViewFinancePermission
	) => {
		return {
			readOnly: {
				[PortCallTabKey.MESSAGES]: isCurrentUserPrincipal
			},
			disabled,
			isDisabledConfigReadyToCheck:
				!hasViewFinancePermission || getIsLoaded(financeDetailsFetchStatus)
		};
	}
);

export const getIsPortCallFinanceTabDisabled = createSelector(
	getPortCallTabsDisabledMap,
	disabledTabs => disabledTabs[PortCallTabKey.FINANCE]
);

export const getPortJobExist = createSelector(
	getActivePortJobCode,
	getPortJobsForActivePortCall,
	getCurrentPortCallTab,
	(activePortJobCode, portJobsFromActivePortCall): boolean => {
		if (!activePortJobCode || !portJobsFromActivePortCall.length) {
			return false;
		}

		const activeJobCode =
			activePortJobCode === SHOW_ALL_JOBS_KEY &&
			portJobsFromActivePortCall.length
				? portJobsFromActivePortCall[0].code
				: activePortJobCode;

		return (
			portJobsFromActivePortCall.some(
				({ code }: PortJob) => activeJobCode === code
			) || activeJobCode === SHOW_ALL_JOBS_KEY
		);
	}
);

export const getIsValidJobCode = createSelector(
	getActivePortJobCode,
	(activePortJobCode): boolean => {
		return activePortJobCode !== SHOW_ALL_JOBS_KEY;
	}
);
