import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { IdField, AlertsByMetricType } from 'app-types';
import {
	PagedRequest,
	ResultCollection,
	SearchResultCollection
} from 'services/api/apiTypes';
import {
	PortJob,
	AddPortJobRequestPayload,
	ClassificationCode,
	PortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';
import { ThemeScheme } from 'components/types';
import { Severity } from 'app-constants';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';

export interface Port {
	id: string;
	name: string;
}

export enum PortCallStatus {
	EN_ROUTE = 'EnRoute',
	ARRIVED = 'Arrived',
	ALONGSIDE = 'Alongside',
	SAILED = 'Sailed',
	CLOSED = 'Closed',
	CANCELLED = 'Cancelled'
}

export interface PortCall {
	id: string;
	port: Port;
	vessel: Vessel;
	status: PortCallStatus;
	etaPlt: string;
	etsPlt: string;
	eospPlt: string;
	cospPlt: string;
	etaUtc: string;
	etsUtc: string;
	eospUtc: string;
	cospUtc: string;
	imo: string;
	jobs: PortJob[];
	lastUpdatedOnUtc: string;
	containsAppointedJobs: boolean;
	hasDuplicates: boolean;
	concurrencyToken: number;
	isCancelled: boolean;
	portTimezoneId: string;
	externalCode?: string;
}

export type PortCallDuplicate = PortCall;

export type ExpandType =
	| 'None'
	| 'PortJobs'
	| 'Operations'
	| 'PortJobsBasic'
	| 'PortCallGrid';

export enum ExpandPortJobType {
	OPERATIONS = 'Operations',
	OPERATION_EVERNTS = 'OperationsEvents', // TODO: Probably we don't need this option
	METADATA = 'Metadata',
	PORT_JOBS_BASIC = 'PortJobsBasic'
}

export interface RetrievePortCallRequest {
	id: string;
	expand?: ExpandType;
	isSilent?: boolean;
}

export interface Vessel {
	id: string;
	name: string;
}

export interface RetrievePortCallsRequest extends PagedRequest {
	vesselId?: string;
	portId?: string;
	jobCode?: string;
	jobType?: string;
	jobStatus?: string;
	financeStatus?: string[];
	queriedToHub?: boolean;
	queriedToLPA?: boolean;
	eta?: string;
	expand?: ExpandType;
	searchTerm?: string;
	ids?: string[];
	userGroupType?: GroupTypeCodes;
	classification?: ClassificationCode[];
	includeCancelled?: boolean;
	principalCompanyId?: string;
	awaitingHubReview?: boolean;
	portJobPerformingCompanyName?: string;
	mainPrincipalCompanyId?: string;
	daAssignedIds?: string[];
	daAssignedTo?: string[];
	operation?: string;
	etaFrom?: string;
	etaTo?: string;
	etsFrom?: string;
	etsTo?: string;
	eospFrom?: string;
	eospTo?: string;
	cospFrom?: string;
	cospTo?: string;
}

export interface RetrievePortCallsResponse extends ResultCollection<PortCall> {}
export interface PortJobAlertsGroup {
	portJobId: string;
	portJobAlertGroupsByMetricType: AlertsByMetricType[];
}
export interface PortCallAlertsGroup {
	portCallId: string;
	portCallAlertGroupsByMetricType: AlertsByMetricType[];
	portJobs: PortJobAlertsGroup[];
}
// Alerts
export interface RetrievePortCallsAlertsRequest {
	portCallIds: string[];
	includeAlerts?: boolean;
	alertSeverities?: Severity[];
}
export type RetrievePortCallsAlertsResponse = PortCallAlertsGroup[];
export interface MetricTypeGroup {
	portCallAlertGroupsByMetricType: AlertsByMetricType[];
	portJobs: {
		[portJobId: string]: {
			portJobAlertGroupsByMetricType: AlertsByMetricType[];
		};
	};
}
export interface PortCallRowVisibleAlert {
	url: string;
	text: string;
	theme: ThemeScheme;
}

// ################### ADD PORT CALL

export interface AddPortCallRequest {
	port: IdField;
	vessel: IdField;
	etaPlt: string;
	etsPlt?: string;
	eospPlt?: string;
	cospPlt?: string;
	jobs: AddPortJobRequestPayload[];
	externalCode?: string;
}

export interface AddPortCallResponse {
	id: string;
	portJobCode: string;
}

export type PortCallJobGroupType =
	| 'PortJobCode'
	| 'PortJobCustomerReference'
	| 'PortJobPrincipal'
	| 'PortJobMainContact'
	| 'PortJobPerformingAgent'
	| 'PortJobOperationType';

export interface PortCallJobSearchRequest {
	searchTerm: string;
	groupType?: PortCallJobGroupType;
	showMore?: boolean;
	mainPrincipalId?: string;
}

export interface PortCallJobSearchResult {
	id: string;
	name: string;
	code: string;
	email?: string;
}

export type PortCallJobSearchGroup = SearchResultCollection<
	PortCallJobGroupType,
	PortCallJobSearchResult
>;

export enum PortCallJobGroupTypeEnum {
	CODE = 'PortJobCode',
	CUSTOMER_REFERENCE = 'PortJobCustomerReference',
	PRINCIPAL = 'PortJobPrincipal',
	MAIN_CONTACT = 'PortJobMainContact',
	PERFORMING_AGENT = 'PortJobPerformingAgent',
	OPERATION_TYPE = 'PortJobOperationType'
}

export interface MergePortCallsRequest {
	portCallId: string;
	isDuplicate: boolean;
	portCallDuplicateIds?: string[];
}

export type MergePortCallsResponse = void;

export interface GetLinkedCargosRequest {
	portCallId: string;
	portJob: string;
	linkJob: string;
}

export interface GetLinkedCargosResponse {
	linkedNominationCargoes: LinkedCargo[];
	linkedAppointmentCargoes: LinkedCargo[];
	operationsNomination: PortJobOperationTypes[];
	operationsAppointment: PortJobOperationTypes[];
}

export interface AddAssinedUserRequest {
	portCallId: string;
	jobCode: string;
	assignedDaId: string;
	assignedDaName: string;
	updateByUserId: string;
	updateByUserName: string;
}

export interface SaveLinkedCargosDetailsRequest {
	portCallId: string;
	portJob: string;
	request: SaveLinkedCargosDetailsPayload;
}

export interface SaveLinkedCargosDetailsPayload {
	portCallId: string;
	portJobCode: string;
	linkedAppJobCode: string;
	nominationCargos: LinkedCargo[];
	appointmentCargos: LinkedCargo[];
}

export interface DeleteLinkedAppointmentCargoRequest {
	id: string;
}

export interface AddAppointmentCargoManualRequest {
	id: string;
	nominationCargoId: string;
}

export interface CreateCargoFromNominationRequest {
	nominationCargoId: string;
}
