import { RetrievePortCallAccessiblePortJobsResponse } from 'services/api/portCall/portCallServiceTypes';
import actionCreator from '../portCallOperationsActionCreator';

const RETRIEVE_PORTCALL_ACCESSIBLE_PORTJOBS =
	'RETRIEVE_PORTCALL_ACCESSIBLE_PORTJOBS';
export const retrievePortCallAccessiblePortJobsAsync = actionCreator.async<
	string,
	RetrievePortCallAccessiblePortJobsResponse,
	Error
>(RETRIEVE_PORTCALL_ACCESSIBLE_PORTJOBS);

export const retrievePortCallAccessiblePortJobs = actionCreator<string>(
	RETRIEVE_PORTCALL_ACCESSIBLE_PORTJOBS
);

export const resetPortCallAccessiblePortJobs = actionCreator<string>(
	'RESET_PORTCALL_ACCESSIBLE_PORTJOBS'
);
