import { AppState } from 'store-types';
import { createSelector, createStructuredSelector } from 'reselect';
import { FilterItem } from 'store/filters/filtersState';
import { getIsLoading } from 'store/selectors';
import { getAuthProfile } from 'store/auth/selectors/authSelectors';
import { FetchStatus } from 'services/api/apiTypes';

const getUsersMapSelector = (state: AppState) => state.users.byId;
const getAllUserIdsSelector = (state: AppState) => state.users.allIds;
export const userHasMoreSelector = (state: AppState) => state.users.hasMore;
export const isUsersFetching = (state: AppState) =>
	getIsLoading(state.users.fetchStatuses.all);

export const getInviteUserFetchStatus = (state: AppState) =>
	state.users.fetchStatuses.inviting;
export const getUsersFiltersSelector = (state: AppState) => state.users.filters;

export const getUsersFetchStatusesStructured = createStructuredSelector<
	AppState,
	{
		inviteStatus: FetchStatus;
		updateStatus: FetchStatus;
	}
>({
	inviteStatus: state => state.users.fetchStatuses.inviting,
	updateStatus: state => state.users.fetchStatuses.updatingStatus
});

export const getAllUsersSelector = createSelector(
	getUsersMapSelector,
	getAllUserIdsSelector,
	(byId, allIds) => {
		return allIds.map((id: string) => byId[id]).filter(item => item);
	}
);

export const getUserProfile = (state: AppState) => state.users.profile;
export const getIsUserProfileOfAuthorizedUser = createSelector(
	getUserProfile,
	getAuthProfile,
	(profile, authProfile) =>
		!!(authProfile && profile) && authProfile.user_Id === profile.id
);

export const getUserFiltersResultSelector = createSelector(
	getUsersFiltersSelector,
	filters => {
		const tags: FilterItem[] = [];
		if (filters.status) {
			if (filters.status.label !== 'All') {
				tags.push({
					type: 'Status',
					label: filters.status.label,
					key: filters.status.key
				});
			}
		}
		if (filters.search && filters.search.length > 0) {
			filters.search.forEach(search => {
				tags.push({
					type: search.type,
					label: search.name,
					key: search.id
				});
			});
		}
		return tags;
	}
);

export const getUsersCount = createSelector(
	getAllUserIdsSelector,
	users => users.length
);
