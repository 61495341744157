import actionCreator from '../configurationServicesActionCreator';
import {
	UpdatePrincipalServiceMappingRequest,
	UpdatePrincipalServiceMappingResponse
} from 'services/api/services/servicesServiceTypes';

const ACTION_NAME = 'UPDATE_PRINCIPAL_SERVICES_MAPPING';

export const updatePrincipalServicesMapping = actionCreator<
	UpdatePrincipalServiceMappingRequest
>(ACTION_NAME);

export const updatePrincipalServicesMappingAsync = actionCreator.async<
	UpdatePrincipalServiceMappingRequest,
	UpdatePrincipalServiceMappingResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Service Mapping'
	}
});
