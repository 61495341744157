import { Success, Failure } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';
import {
	RetrieveThreadsResponse,
	IThread,
	RetrieveThreadsRequest
} from 'services/api/threads/threadsServiceTypes';
import { keyBy } from 'lodash';

export const onRetrieveThreadsStarted = (
	state: ThreadsState
): ThreadsState => ({
	...state,
	error: false
});

export const onRetrieveThreadsSuccess = (
	state: ThreadsState,
	{
		params: { limit },
		result: { elements, totalUnreadCount }
	}: Success<RetrieveThreadsRequest, RetrieveThreadsResponse>
): ThreadsState => ({
	...state,
	hasMore: limit === elements.length,
	byId: {
		...state.byId,
		...keyBy<IThread>(elements, item => item.id)
	},
	totalUnreadCount,
	error: false
});

export const onRetrieveThreadsFailed = (
	state: ThreadsState,
	action: Failure<RetrieveThreadsRequest, Error>
): ThreadsState => ({
	...state,
	hasMore: false,
	error: action.error
});

export const onRetrieveThreads = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	RetrieveThreadsRequest,
	RetrieveThreadsResponse
>('fetchStatuses', 'all', {
	startedReducer: onRetrieveThreadsStarted,
	doneReducer: onRetrieveThreadsSuccess,
	failedReducer: onRetrieveThreadsFailed
});
