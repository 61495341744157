import actionCreator from '../linkJobActionCreator';
import { DeleteLinkedAppointmentCargoRequest } from 'services/api/portCalls/portCallsServiceTypes';

const DELETE_LINKED_APPOINTMENT_CARGO = 'DELETE_LINKED_APPOINTMENT_CARGO';
export const deleteLinkedAppointmentCargo = actionCreator<
	DeleteLinkedAppointmentCargoRequest
>(DELETE_LINKED_APPOINTMENT_CARGO);

export default {
	action: deleteLinkedAppointmentCargo
};
