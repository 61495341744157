import * as React from 'react';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import styles from './LazyLoad.module.scss';
import CursorLoader from 'components/CursorLoader/CursorLoader';
import { Loading } from 'components';

interface CallbackArgs {
	/*
	 * The position that the waypoint has at the moment.
	 * One of Waypoint.below, Waypoint.above, Waypoint.inside, and Waypoint.invisible.
	 */
	currentPosition: string;

	/*
	 * The position that the waypoint had before.
	 * One of Waypoint.below, Waypoint.above, Waypoint.inside, and Waypoint.invisible.
	 */
	previousPosition: string;

	/*
	 * The native scroll event that triggered the callback.
	 * May be missing if the callback wasn't triggered as the result of a scroll
	 */
	event?: Event;

	/*
	 * The waypoint's distance to the top of the viewport.
	 */
	waypointTop: number;

	/*
	 * The distance from the scrollable ancestor to the viewport top.
	 */
	viewportTop: number;

	/*
	 * The distance from the bottom of the scrollable ancestor to the viewport top.
	 */
	viewportBottom: number;
}

export interface LazyLoadBaseProps {
	hasMore?: boolean;
	loading?: boolean;
	/* eslint-disable @typescript-eslint/no-explicit-any */
	onEnter: (args?: any) => void;
	doneLoading?: any;
	failedLoading?: any;
	// eslint-enable no-any
}
interface LazyLoadProps extends LazyLoadBaseProps {
	onLeave?: (args?: CallbackArgs) => void;
	loadingTemplate?: string | React.ReactNode;
	/* eslint-disable @typescript-eslint/no-explicit-any */
	scrollableAncestor?: any;
	// eslint-enable no-any
}

function LazyLoad(props: LazyLoadProps) {
	const {
		doneLoading,
		failedLoading,
		hasMore,
		loadingTemplate = <Loading />,
		loading,
		onEnter,
		scrollableAncestor,
		...partialProps
	} = props;

	const templateClassName = classNames(styles.row, {
		[styles.hidden]: !loading
	});
	return (
		<div>
			<CursorLoader loading={!!loading}>
				{hasMore && !loading ? (
					<Waypoint
						onEnter={onEnter}
						scrollableAncestor={scrollableAncestor}
						{...partialProps}
					>
						<div className={templateClassName}>{loadingTemplate}</div>
					</Waypoint>
				) : (
					<div className={styles.row}>
						{loading ? loadingTemplate : <p>{failedLoading ?? doneLoading}</p>}
					</div>
				)}
			</CursorLoader>
		</div>
	);
}

export default LazyLoad;
