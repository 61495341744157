import React from 'react';
import classNames from 'classnames';
import styles from './Logo.module.scss';

interface LogoProps {
	className?: string;
	stretch?: boolean;
}

export default function Logo(props: LogoProps) {
	const className = classNames(
		{
			[styles.logo]: !props.stretch
		},
		props.className
	);
	return (
		<img
			src={require('./logo.svg')}
			alt="Project Optic"
			className={className}
		/>
	);
}
