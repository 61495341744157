import { SagaIterator } from 'redux-saga';
import {
	select,
	call,
	put,
	takeLatest,
	fork,
	all,
	delay
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getActivePortJobStatus } from 'store/portJobs/portJobsSelectors';
import { PortJobStatus } from 'store/portJobs/constants';
import { navigateTo } from 'utils';

import Api from 'services/api/portJobs/portJobsService';
import {
	AddPortJobOperationResponse,
	AddPortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';

import {
	addPortJobOperationAsync,
	AddPortJobOperationActionParams
} from '../actions/addOperation';
import { notify } from 'store/notifications/actions';
import { prepareOperationRequest } from 'store/portcalls/sagas/addPortCall/prepareRequest';
import { getOperationById } from 'store/operations/operationsSelectors';

import { Action } from 'typescript-fsa';
import {
	getAddPortJobOperationFormData,
	getOperationIdFromAddOperationFormData
} from '../selectors';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { DraftType, CompanyDraft } from 'store/drafts/draftsState';
import { Operation } from 'services/api/operations/operationsServiceTypes';

export function* addPortJobOperationExecutor(
	actionParams: AddPortJobOperationActionParams,
	payload: AddPortJobOperationTypes,
	api: typeof Api.addOperationToPortJob
): SagaIterator {
	yield put(addPortJobOperationAsync.started(actionParams));
	try {
		const response: AxiosResponse<AddPortJobOperationResponse> = yield call(
			api,
			{
				portCallId: actionParams.portCallId,
				jobCode: actionParams.jobCode,
				payload
			}
		);
		yield put(
			addPortJobOperationAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		const status = yield select(getActivePortJobStatus);
		const description =
			status === PortJobStatus.CREATED
				? 'Operation has been successfully added.'
				: 'Thank you for your request. It will be processed within 3 hours.';

		yield call(
			navigateTo,
			`/portcalls/${actionParams.portCallId}/jobs/${actionParams.jobCode}/overview`
		);
		// delay with 0 puts execution of next code after it into the next event loop (the same as setTimeout with 0)
		// delay is needed because alert is able to render in previous page before navigation gets to the next one
		yield delay(0);
		yield put(notify.success(description));
	} catch (error) {
		yield put(
			addPortJobOperationAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* addPortJobOperation(
	action: Action<AddPortJobOperationActionParams>
): SagaIterator {
	try {
		const operationId = yield select(getOperationIdFromAddOperationFormData);
		const formData = yield select(getAddPortJobOperationFormData);

		const operation: Operation = yield select(getOperationById, operationId);
		const chartererDrafts: CompanyDraft[] = yield select(
			getCompanyDraftsByType,
			DraftType.CHARTERER
		);
		const shipperReceiverDrafts: CompanyDraft[] = yield select(
			getCompanyDraftsByType,
			DraftType.SHIPPER_RECEIVER
		);
		const portJobCompanies = [...chartererDrafts, ...shipperReceiverDrafts];
		const preparedRequest = yield call(
			prepareOperationRequest,
			operation,
			formData
		);
		const operationData = {
			...preparedRequest,
			portJobCompanies
		};
		yield call(
			addPortJobOperationExecutor,
			action.payload,
			operationData,
			Api.addOperationToPortJob
		);
	} catch (error) {
		// parsing data problems
		yield put(
			addPortJobOperationAsync.failed({
				error: new Error('Operation could not be saved. Form data issue.'),
				params: action.payload
			})
		);
		throw error; // don't consume error
	}
}

function* addingWatcher() {
	yield takeLatest(addPortJobOperationAsync.type, addPortJobOperation);
}

export default function*(): SagaIterator {
	yield all([fork(addingWatcher)]);
}
