import { retrieveCompanyJobTypesAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse,
	Error
>({
	api: Api.Companies.retrieveCompanyJobTypes,
	async
});
