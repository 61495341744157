import {
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../linkJobActionCreator';

const ACTION_NAME = 'SAVE_UPDATED_LINKEDJOB_CARGOS';

export const saveUpdatedLinkedCargosAsync = actionCreator.async<
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse,
	Error
>(ACTION_NAME);

export const saveUpdatedLinkedCargos = actionCreator<
	SaveLinkedCargosDetailsRequest
>(ACTION_NAME);
