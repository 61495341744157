import { AsyncActionCreators } from 'typescript-fsa';
import { take, put } from 'redux-saga/effects';
import { notify, resetAllNotifications } from 'store/notifications/actions';
import { DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE } from 'app-constants';

export function* waitAsyncResolve(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async: AsyncActionCreators<any, any, any>
) {
	return yield take([async.done, async.failed]);
}

export function* showFailOutdatedNotification() {
	yield put(resetAllNotifications());
	yield put(
		notify.warning({
			description:
				'Your action has failed as you are were working on the outdated version of the page. Please try again.',
			duration: 0
		})
	);
}

export function* showFailOpenPageNotification() {
	yield put(resetAllNotifications());
	yield put(
		notify.error({
			description: DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE,
			duration: 0
		})
	);
}
