import * as React from 'react';
import { connect } from 'react-redux';

import { UserStatus as UserStatusTag, Flex } from 'components';
import { Button, Modal } from 'components/antd';
import { UserProfile, UserStatus } from 'services/api/users/userServiceTypes';
import { getUserProfile } from 'store/users/usersSelectors';
import { USER_PROFILE_STATUS_MODAL_NAME } from 'store/users/usersConstants';
import { updateUserStatus } from 'store/users/actions';
import { closeModal, openModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { getIsUserStatusUpdating } from 'sections/User/EditUser/userDetailsSelectors';
import {
	getOppositeUserStatusText,
	getNextUserStatus,
	getButtonType
} from './UserStatusButton.func';
import { capitalize } from 'lodash';
import { AppState } from 'store-types';

interface UserStatusButtonProps {
	isProfilePage: boolean;
	// from matStateToProps
	user: UserProfile;
	isModalVisible: boolean;
	isUserStatusUpdating: boolean;
	// from mapDispatchToProps
	updateUserStatus: typeof updateUserStatus;
	openModal: typeof openModal;
	closeModal: typeof closeModal;
}

class UserStatusButton extends React.Component<UserStatusButtonProps> {
	private updateUserStatus = () => {
		const { user } = this.props;
		this.props.updateUserStatus({
			id: user.id,
			request: { status: getNextUserStatus(user.status) }
		});
		this.hideConfirmationModal();
	};

	private showConfirmationModal = () =>
		this.props.openModal({ name: USER_PROFILE_STATUS_MODAL_NAME });

	private hideConfirmationModal = () =>
		this.props.closeModal({ name: USER_PROFILE_STATUS_MODAL_NAME });

	render() {
		const { user } = this.props;
		const oppositeStatus = getOppositeUserStatusText(user.status);
		return (
			<>
				{!this.props.isProfilePage &&
					user.status !== UserStatus.REGISTRATION_PENDING && (
						<Button
							type={getButtonType(user.status)}
							transparent
							onClick={this.showConfirmationModal}
						>
							<span>{capitalize(oppositeStatus)} User</span>
						</Button>
					)}
				<Flex self="center" none>
					User Status:{' '}
					<UserStatusTag
						status={user.status}
						isLoading={this.props.isUserStatusUpdating}
					/>
				</Flex>
				<Modal
					visible={this.props.isModalVisible}
					okText="Yes"
					cancelText="No"
					onCancel={this.hideConfirmationModal}
					onOk={this.updateUserStatus}
				>
					Are you sure you want to {oppositeStatus} the user?
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		user: getUserProfile(state),
		isModalVisible: isModalVisible(state, USER_PROFILE_STATUS_MODAL_NAME),
		isUserStatusUpdating: getIsUserStatusUpdating(state)
	}),
	{
		updateUserStatus,
		openModal,
		closeModal
	}
)(UserStatusButton);
