import { RetrieveDownloadDocumentResponse as Response } from 'services/api/documents/documentsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { makePollDownloadExecutor } from 'utils/sagaHelpers/pollDownloadExecutor';
import { DownloadLOBDRequest as Request } from 'services/api/bankAccounts/bankAccountsServiceTypes';

import Api from 'services/api';
import { downloadLOBDAsync as async } from '../actions';

const POSTApi = Api.BankAccounts.downloadLOBD;
const GETApi = Api.BankAccounts.downloadLOBD;

const downloadExecutor = makePollDownloadExecutor<Request, Response, Error>({
	async,
	GETApi,
	POSTApi
});

export default makeTakeLatestWatcher<Request, Response, Error>(
	{ async },
	downloadExecutor
);
