import {
	CheckGroupPermissionRequestWithForm,
	CheckGroupPermissionResponse
} from 'services/api/groups/groupsServiceTypes';
import actionCreator from '../groupsActionCreator';

const CHECK_PERMISSION = 'CHECK_PERMISSION';

const checkPermissionAsync = actionCreator.async<
	CheckGroupPermissionRequestWithForm,
	CheckGroupPermissionResponse,
	Error
>(CHECK_PERMISSION);

export const checkPermission = actionCreator<
	CheckGroupPermissionRequestWithForm
>(CHECK_PERMISSION);

export const checkPermissionAction = {
	action: checkPermission,
	async: checkPermissionAsync
};

export default checkPermissionAction;
