import React, { useCallback, useMemo } from 'react';
import { ScrollableLayout, InfiniteTable } from 'components';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getBankAccountsTableColumns } from './BankAccountsColumns';
import { getFilterValues } from 'store/filters/filtersSelectors';
import { AppState } from 'store-types';
import { retrieveBankAccounts } from 'store/masterdata/bankAccounts/actions';
import {
	getBankAccounts,
	getIsRetrieveBankAccountsLoading,
	getBankAccountsHasMore,
	getBankAccountsIndexForNextFetch
} from 'store/masterdata/bankAccounts/selectors';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { AppNotifications } from 'containers';

const MASTER_DATA_EXPLORER_BANK_ACCOUNTS_FILTERS_ID =
	'md-explorer-bank-accounts-id';

const CompaniesTable = () => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(getBankAccounts, shallowEqual);
	const hasMore = useSelector(getBankAccountsHasMore);
	const index = useSelector(getBankAccountsIndexForNextFetch);
	const isLoading = useSelector(getIsRetrieveBankAccountsLoading);
	const filters = useSelector(
		(state: AppState) =>
			getFilterValues(state, MASTER_DATA_EXPLORER_BANK_ACCOUNTS_FILTERS_ID),
		shallowEqual
	);

	const defaultParams = useMemo(
		() => ({
			index,
			limit: DEFAULT_LIST_LIMIT,
			sortBy: 'bankAccountName'
		}),
		[index]
	);

	/* eslint-disable react-hooks/exhaustive-deps */
	const accountIds = useMemo(
		() =>
			filters ? filters.accounts.map(({ key }: { key: string }) => key) : [],
		[filters]
	);
	/* eslint-enable */

	const onEnter = useCallback(() => {
		if (!isLoading) {
			dispatch(
				retrieveBankAccounts(
					{ ...defaultParams, ids: accountIds },
					{ behaviour: { type: 'sub-section', infiniteLoad: true } }
				)
			);
		}
	}, [dispatch, defaultParams, accountIds, isLoading]);

	const columns = useMemo(getBankAccountsTableColumns, []);

	return (
		<ScrollableLayout>
			<ScrollableLayout>
				<AppNotifications.Table
					entityName="Bank Accounts"
					actionType={retrieveBankAccounts.type}
				>
					{({ failedLoading }) => (
						<InfiniteTable
							lazyLoad={{
								loading: isLoading,
								onEnter,
								hasMore,
								doneLoading: 'No more Bank Accounts to load',
								failedLoading
							}}
							dataSource={bankAccounts}
							columns={columns}
						/>
					)}
				</AppNotifications.Table>
			</ScrollableLayout>
		</ScrollableLayout>
	);
};

export default CompaniesTable;
