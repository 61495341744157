import actionCreator from 'store/finance/financeActionCreator';
import { ToggleFinancePayload } from 'services/api/finance/financeServiceTypes';

const TOGGLE_FINANCE = 'TOGGLE_FINANCE';

export const toggleFinanceAsync = actionCreator.async<
	ToggleFinancePayload,
	null,
	Error
>(TOGGLE_FINANCE);

export const toggleFinance = actionCreator<ToggleFinancePayload>(
	TOGGLE_FINANCE
);
