import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';
import { FilterItem } from 'store/filters/filtersState';

export const PORT_CALLS_FILTER_NAME = 'portcalls';

export const PORT_CALLS_FILTER_AGENT_TYPE_ID =
	'port-calls-filters-agent-type-id';
export const PORT_CALLS_FILTER_CONTACT_ID = 'port-calls-filters-contact-id';
export const PORT_CALLS_FILTER_CHARTERER_ID = 'port-calls-filters-charterer-id';
export const PORT_CALLS_FILTER_OPERATION_ID = 'port-calls-filters-operation-id';
export const PORT_CALLS_FILTER_ASSIGNED_TO_ID =
	'port-calls-filters-assigned-to-id';

export enum PortCallsFiltersParamEnum {
	SEARCH = 'search',
	MAIN_PRINCIPAL = 'mainPrincipalCompanyId',
	PERFORMING_AGENT = 'performingAgentCompanyId',
	PORT = 'portId',
	COUNTRY = 'countryCode',
	VESSEL = 'vesselId',
	JOB_TYPE = 'jobType',
	JOB_STATUS = 'jobStatus',
	PORT_CALL_STATUS = 'portCallStatus',
	ALERT = 'alertSectionCode',
	SHOW_CANCELLED = 'includeCancelled',
	ASSIGNED_TO_ID = 'daAssignedIds',
	ASSIGNED_TO = 'daAssignedTo',
	PORT_JOB_CATEGORY = 'portJobCategory',
	FINANCE_DISABLED = 'financeDisabled',
	OPERATION_DISABLED = 'operationDisabled',
	AGENT_TYPE = 'agentType',
	AGENT_ROLE = 'agentRole',
	PORT_JOB_TYPE = 'portJobType',
	MAIN_CONTACT = 'customerContact1Id',
	CHARTERER = 'chartererCompanyId',
	OPERATION = 'operation',
	DATE = 'dateType'
}

export enum PortCountryGroupType {
	PORTS = 'Ports',
	COUNTRIES = 'Countries'
}

export enum AssignedToUserFilter {
	ME = 'me',
	UNASSIGNED = 'unassigned',
	INACTIVE = 'inactive'
}

export const AssignedToUserKeyMap = {
	[AssignedToUserFilter.ME]: 'Assigned to me',
	[AssignedToUserFilter.UNASSIGNED]: 'Unassigned',
	[AssignedToUserFilter.INACTIVE]: 'Inactive users'
};

export interface PortCallsFiltersParam {
	search?: FilterItem[];
	mainPrincipalCompanyId?: FilterItem;
	performingAgentCompanyId?: FilterItem;
	portId?: FilterItem;
	countryCode?: FilterItem;
	vesselId?: FilterItem;
	jobType?: FilterItem;
	jobStatus?: FilterItem;
	portCallStatus?: FilterItem;
	includeCancelled?: FilterItem;
	daAssignedIds?: FilterItem[];
	daAssignedTo?: FilterItem[];
	portJobCategory?: FilterItem;
	agentType?: FilterItem;
	agentRole?: FilterItem;
	portJobType?: FilterItem;
	customerContact1Id?: FilterItem;
	chartererCompanyId?: FilterItem;
	operation?: FilterItem;
	dateType?: FilterItem;
}

const filtersSync = {
	[PORT_CALLS_FILTER_NAME]: {
		selector: (state: AppState) => state.filters[PORT_CALLS_FILTER_NAME],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return { name: PORT_CALLS_FILTER_NAME, filters: JSON.parse(value) };
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
