import React from 'react';
import { connect } from 'react-redux';
import { ScrollableLayout, PageHeader, Gap } from 'components';
import { AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation';
import FundingFooter from './FundingRequestFooter/FundingRequestFooter';
import { Collapse } from 'components/antd';
import BankAccountDetails from 'sections/BankAccounts/BankAccountDetails/BankAccountDetails';
import {
	getFundingBreadcrumbs,
	getIsLOBDVisibleOnFundingRequest
} from 'store/financeFundings/selectors';
import JobHeaderSmall from '../FinancePartials/JobHeaderSmall';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import {
	FINANCE_FUNDING_FORM_NAME,
	FinanceFundingFormData,
	FinanceFundingFormField
} from 'store/form/financeFundings/financeFundingTypes';
import { InjectedFormProps, reduxForm } from 'redux-form';
import {
	getFundingInitialValues,
	getFinanceFundingFormValues
} from 'store/form/financeFundings/selectors/financeFundingFormSelectors';
import FundingRequestTable from './FundingRequestTable/FundingRequestTable';
import FundingRequestComment from './FundingRequestComment/FundingRequestComment';
import { validate } from './FundingRequestPageForm.func';
import FundingBaseAmount from './FundingBaseAmount/FundingBaseAmount';
import { AppState } from 'store-types';

enum TabKeys {
	BANK_ACCOUNT_DETAILS = 'bankAccountDetails',
	FUNDING_DETAILS = 'fundingDetails',
	COMMENT = 'comment'
}

interface FundingPageBaseProps {
	portJobCode: string;
	fundingId: string;
	onCancel: () => void;
	// from mapStateToProps
	initialValues: Partial<FinanceFundingFormData>;
	formValues: FinanceFundingFormData;
	breadcrumbs: BreadcrumbItem[];
	isLOBDVisible: boolean;
}

interface FundingPageFormProps
	extends InjectedFormProps<FinanceFundingFormData, FundingPageBaseProps>,
		FundingPageBaseProps {}

const FundingPageForm = (props: FundingPageFormProps) => {
	const { daInBankDetailId } = props.initialValues;
	return (
		<ScrollableLayout stretch>
			<AppNotifications.Notification />
			<RouteBreadcrumbs items={props.breadcrumbs} />
			<PageHeader.NewTab>Funding</PageHeader.NewTab>
			<ScrollableLayout>
				<JobHeaderSmall portJobCode={props.portJobCode} />
				<Collapse
					type="primary"
					bordered={false}
					defaultActiveKey={[
						TabKeys.BANK_ACCOUNT_DETAILS,
						TabKeys.FUNDING_DETAILS,
						TabKeys.COMMENT
					]}
				>
					<Collapse.Panel
						key={TabKeys.BANK_ACCOUNT_DETAILS}
						header="Bank Account Details"
					>
						{daInBankDetailId && (
							<BankAccountDetails
								bankDetailId={daInBankDetailId}
								isLobdVisible={props.isLOBDVisible}
							/>
						)}
					</Collapse.Panel>
					<Collapse.Panel
						key={TabKeys.FUNDING_DETAILS}
						header="Funding Details"
					>
						<Gap isBlock all outside={false}>
							<FundingBaseAmount
								defaultValues={props.initialValues}
								values={props.formValues}
							/>
							<FundingRequestTable />
						</Gap>
					</Collapse.Panel>
					<Collapse.Panel key={TabKeys.COMMENT} header="Comment">
						<FundingRequestComment
							fundingId={props.fundingId}
							comment={props.formValues[FinanceFundingFormField.COMMENT]}
						/>
					</Collapse.Panel>
				</Collapse>
			</ScrollableLayout>
			<FundingFooter fundingId={props.fundingId} onCancel={props.onCancel} />
		</ScrollableLayout>
	);
};

export default connect(
	(state: AppState, props: Pick<FundingPageFormProps, 'fundingId'>) => ({
		initialValues: getFundingInitialValues(state, props.fundingId),
		formValues: getFinanceFundingFormValues(state),
		breadcrumbs: getFundingBreadcrumbs(state),
		isLOBDVisible: getIsLOBDVisibleOnFundingRequest(state, props.fundingId)
	})
)(
	reduxForm<FinanceFundingFormData, FundingPageBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FINANCE_FUNDING_FORM_NAME,
		validate
	})(FundingPageForm)
);
