import { Tooltip, Icon } from 'components/antd';
import React, { Component } from 'react';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import {
	getAuthUserId,
	getAuthUserName,
	getUserType,
	getUserGroups
} from 'store/auth/selectors';
import { getPortJobAssignUser } from 'store/portcalls/portCallsSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { Flex } from 'components';
import styles from '../PortJobList.module.scss';
import { resetPortCalls } from 'store/portcalls';
import { uniqBy } from 'lodash';
import { AssignDaModal } from './AssignDaModal';

interface AssignDaPopUpState {
	openAssignModal: boolean;
}

export interface AssignDaPopUpProps {
	portJob: PortJob;
	portCallId: string;
	portCalls: PortCall;
	jobCode: string;
	daAssigned: string;
	userId: string;
	userName: string;
	retrievePortCalls: typeof resetPortCalls;
}

class AssignDaPopUp extends Component<AssignDaPopUpProps, AssignDaPopUpState> {
	constructor(props: AssignDaPopUpProps) {
		super(props);
		this.state = {
			openAssignModal: false
		};
	}

	retrieveUpdatedPortCalls = () => {
		this.props.retrievePortCalls();
	};

	onClick = () => {
		this.setState({ openAssignModal: true });
	};

	onCancelOrClose = () => {
		this.setState({ openAssignModal: false });
	};

	render() {
		const { daAssigned, portJob } = this.props;
		const userList = uniqBy(portJob.portJobDAs, 'id');
		return (
			<>
				<Flex inline align="center">
					<Tooltip title="Assign/Reassign">
						<Icon
							type="user"
							color="primary"
							onClick={this.onClick}
							className={styles.assignToUser}
						/>
					</Tooltip>
					<Tooltip
						title={portJob.daAssignedGroup.map(name => `${name}`).join('|')}
					>
						<span
							className={
								portJob.daAssignedGroup.length > 0 && daAssigned
									? ''
									: styles.inactiveGroupMember
							}
						>
							{daAssigned}
						</span>
					</Tooltip>
				</Flex>

				<AssignDaModal
					{...this.props}
					onClose={this.onCancelOrClose}
					userList={userList}
					openModalState={this.state.openAssignModal}
					reloadPage={this.retrieveUpdatedPortCalls}
				/>
			</>
		);
	}
}

export default connect(
	(state: AppState, ownprops: Pick<AssignDaPopUpProps, 'portCallId'>) => ({
		userId: getAuthUserId(state),
		userName: getAuthUserName(state),
		userGroup: getUserGroups(state),
		portCalls: getPortJobAssignUser(state, ownprops.portCallId),
		userType: getUserType(state)
	}),
	{
		retrievePortCalls: resetPortCalls
	}
)(AssignDaPopUp);
