import { navigateTo } from 'utils';
import { getWizardById } from 'store/wizardForm/wizardFormSelectors';
import { USER_REGISTRATION_FORM_NAME } from '../../registration/settings';
import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api';
import { RegisterUserByTokenRequest as requestParams } from 'services/api/registration/registrationServiceTypes';
import { Action } from 'typescript-fsa';
import {
	registerUserByToken as action,
	registerUserByTokenAsync as actionAsync
} from 'store/registration/actions/registerUserByToken';
import { setActivePage } from 'store/wizardForm/actions';

const apiCall = Api.Registration.registerUserByToken;

export function* executor(
	actionParams: requestParams,
	api: typeof apiCall
): SagaIterator {
	yield put(actionAsync.started(actionParams));
	try {
		const response = yield call(api, actionParams);

		yield put(
			actionAsync.done({
				result: undefined,
				params: actionParams,
				response
			})
		);
		yield call(successExecutor);
	} catch (error) {
		yield put(
			actionAsync.failed({
				error: true,
				params: actionParams
			})
		);
		yield call(failureExecutor);
	}
}

function* worker({ payload }: Action<requestParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

/**
 * Success Worker
 */
export function* successExecutor(): SagaIterator {
	yield call(navigateTo, '/registration/finish');
}

/**
 * Failure Worker
 */
export function* failureExecutor(): SagaIterator {
	const wizard = yield select(getWizardById, USER_REGISTRATION_FORM_NAME);
	/** Pull the wizard one page back */
	yield put(
		setActivePage({
			name: USER_REGISTRATION_FORM_NAME,
			activePage: wizard.activePage
		})
	);
}

export default function*() {
	yield all([fork(watcher)]);
}
