import { addPOI, addPOIAsync } from 'store/vesselProgramme/actions/addPOI';
import { AddPOIRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';

export function* addPOIExecutor(
	actionParams: AddPOIRequest,
	api: typeof Api.VesselProgramme.addPOI
): SagaIterator {
	yield put(addPOIAsync.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(
			addPOIAsync.done({
				result: response.data.rotationSteps,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			addPOIAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* addPOIWorker(action: Action<AddPOIRequest>): SagaIterator {
	yield call(addPOIExecutor, action.payload, Api.VesselProgramme.addPOI);
}

export function* addPOIWatcher(): SagaIterator {
	yield takeLatest(addPOI.type, addPOIWorker);
}

export default function*() {
	yield all([fork(addPOIWatcher)]);
}
