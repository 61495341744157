import React from 'react';
import { useSelector } from 'react-redux';
import Settings, {
	AssignTo,
	Permissions,
	Context,
	AssignToJob,
	InternalComments
} from 'sections/Thread/Settings';
import {
	getIsThreadLoading,
	getActiveThread,
	getThreadGroupPermissions
} from 'store/thread/selectors';
import { Gap, Title, Text, Loading, Error404 } from 'components';
import styles from 'sections/Thread/Thread.module.scss';
import { ModalContext } from 'context';

interface MessagesSettingsProps {
	threadId: string;
}

function MessagesSettings({ threadId }: MessagesSettingsProps) {
	const thread = useSelector(getActiveThread);
	const isThreadLoading = useSelector(getIsThreadLoading);
	const groupPermissions = useSelector(getThreadGroupPermissions);

	if (!thread) {
		return isThreadLoading ? <Loading /> : <Error404 />;
	}

	const assignedTo = {
		group: thread.assignedToGroup,
		user: thread.assignedToUser
	};

	return (
		<Settings>
			<Gap top right bottom="lg" left outside={false} isBlock>
				<ModalContext.Provider>
					<AssignTo
						loading={isThreadLoading}
						assignedTo={assignedTo}
						showTitle={false}
						threadId={threadId}
						threadCompany={thread.company}
						hideMoveBtn={true}
					/>
				</ModalContext.Provider>
				<Permissions
					threadPermissions={groupPermissions}
					loading={isThreadLoading}
				/>
			</Gap>
			<Gap
				top="sm"
				right
				bottom="lg"
				left
				outside={false}
				isBlock
				className={styles.topBorder}
			>
				<Title.H4>
					<Text weight="bold">Context & Job</Text>
				</Title.H4>
				<AssignToJob showTitle thread={thread} />
				<AssignToJob
					thread={thread}
					isAppointmentFromNomination={true}
					isPreviewModeEnabled
				/>
				<Context showTitle={false} />
			</Gap>
			<Gap
				top="sm"
				right
				bottom="lg"
				left
				outside={false}
				isBlock
				className={styles.topBorder}
			>
				<Title.H4>
					<Text weight="bold">Internal Comments</Text>
				</Title.H4>
				<InternalComments threadId={threadId} hasSendButton />
			</Gap>
		</Settings>
	);
}

export default MessagesSettings;
