import actionCreator from '../portCallMeasurementsActionCreator';
import {
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'RETRIEVE_SHIP_MEASUREMENTS';
export const retrievePortCallShipMeasurementsAsync = actionCreator.async<
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse,
	Error
>(ACTION_NAME);

export const retrievePortCallShipMeasurements = actionCreator<
	RetrievePortCallShipMeasurementsRequest
>(ACTION_NAME);
