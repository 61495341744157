import { RetrievePortsResponse } from 'services/api/ports/portsServiceTypes';
import actionCreator from '../actionCreator';

const ACTION_NAME = 'RETRIEVE_PORTS';

export const retrievePortsAsync = actionCreator.async<
	null,
	RetrievePortsResponse,
	Error
>(ACTION_NAME);

export const retrievePorts = actionCreator(ACTION_NAME);

export const resetPorts = actionCreator('RESET_PORTS');
