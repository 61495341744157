import Settings from './Settings';
import AssignTo from './AssignTo/AssignTo';
import AssignToJob from './AssignToJob/AssignToJob';
import Permissions from './Permissions/Permissions';
import Context from './Context/Context';
import InternalComments from './InternalComments/InternalComments';
import ThreadTags from './ThreadTags/ThreadTags';
export {
	AssignTo,
	AssignToJob,
	Context,
	Permissions,
	InternalComments,
	ThreadTags
};

export default Settings;
