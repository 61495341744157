import actionCreator from 'store/portCall/portCallActionCreator';
import {
	UpdatePortCallRequest,
	UpdatePortCallResponse
} from 'services/api/portCall/portCallServiceTypes';

const UPDATE_PORTCALL = 'UPDATE_PORTCALL';

export const updatePortCallAsync = actionCreator.async<
	UpdatePortCallRequest,
	UpdatePortCallResponse,
	Error
>(UPDATE_PORTCALL, {
	templateParams: {
		entityName: 'Port Call'
	}
});

export const updatePortCall = actionCreator(UPDATE_PORTCALL);
