import { Success } from 'typescript-fsa';
import { RegistrationState } from '../registrationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse
} from 'services/api/registration/registrationServiceTypes';

const doneReducer = (
	state: RegistrationState,
	{ result }: Success<PreRegisterVerifyRequest, PreRegisterVerifyResponse>
): RegistrationState => ({
	...state,
	preRegistration: {
		...state.preRegistration,
		userEmail: result.userEmail
	}
});

export const onPreRegisterVerify = makeFetchStatusReducers<
	RegistrationState,
	'fetchStatuses',
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse
>('fetchStatuses', 'preRegisterVerify', { doneReducer });
