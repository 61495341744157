import * as React from 'react';
import { JobSection } from 'services/api/dashboard/dashboardServiceTypes';
import { Collapse } from 'components/antd';
import SectionHeader from './JobSection/SectionHeader';
import Section from './JobSection/JobSection';
import { connect } from 'react-redux';
import {
	setActiveSection,
	retrieveActiveSection
} from 'store/dashboard/actions';

interface JobsCollapseProps {
	sections: JobSection[];
	scrollToTop: () => void;
	// mapDispatchToProps
	setActiveSection: typeof setActiveSection;
	retrieveActiveSection: typeof retrieveActiveSection;
}

class JobsCollapse extends React.PureComponent<JobsCollapseProps> {
	componentDidMount() {
		const section = this.props.sections[0];
		if (section) {
			this.onSectionChange(section.code);
		}
	}
	onSectionChange = (key: string) => {
		this.props.setActiveSection({ code: key });
		this.props.retrieveActiveSection();
		if (key) {
			this.props.scrollToTop();
		}
	};
	render() {
		const codes = [this.props.sections[0] && this.props.sections[0].code];

		return (
			<Collapse
				accordion
				type="primary"
				contentPadding={false}
				onChange={this.onSectionChange}
				defaultActiveKey={codes}
			>
				{this.props.sections.map(section => {
					const header = (
						<SectionHeader name={section.name} count={section.count} />
					);

					return (
						<Collapse.Panel key={section.code} header={header}>
							<Section code={section.code} />
						</Collapse.Panel>
					);
				})}
			</Collapse>
		);
	}
}

export default connect(null, {
	setActiveSection,
	retrieveActiveSection
})(JobsCollapse);
