import * as React from 'react';
import { connect } from 'react-redux';
import { Asteriks } from 'components';
import { Modal, Select } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { cancelPortJob } from 'store/portJobs/actions';
import { CommonActionModalProps } from './ActionModalTypes';
import {
	PortJobCancelReason,
	PortJobCancelReasonMap
} from 'services/api/portJobs/portJobsServiceTypes';
import { AppState } from 'store-types';
import {
	getActivePortJob,
	getIsJobLinked,
	getSendCancelEmailJobStatus
} from 'store/portJobs/selectors';
import { JobType } from 'store/portJobs/constants';
import { getIsCurrentUserHub } from 'store/auth/selectors';
import CancelPortJobMessageModal from './CancelPortJobMessageModal';

interface CancelPortJobModalProps extends CommonActionModalProps {
	jobCode: string;
	portCallId: string;
	okText?: string;
	cancelText?: string;
	// from mapStateToProps
	visible: boolean;
	isJobLinked: boolean;
	jobType: string;
	isSendCancelEmailJobStatus: boolean;
	isHubUser: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	cancelPortJob: typeof cancelPortJob;
}

interface CancelPortJobModalState {
	cancelReason: PortJobCancelReason | null;
}

export const CANCEL_EMAIL_MODAL = 'cancel-email-modal';

class CancelPortJobModal extends React.PureComponent<
	CancelPortJobModalProps,
	CancelPortJobModalState
> {
	constructor(props: CancelPortJobModalProps) {
		super(props);
		this.state = { cancelReason: null };
	}

	hideConfirmationModal = () => {
		const { modalId } = this.props;
		this.props.closeModal(modalId);
		this.resetCancelReason();
	};

	onJobCancellationConfirmed = () => {
		const { modalId } = this.props;
		const { cancelReason } = this.state;
		if (cancelReason) {
			this.props.cancelPortJob({
				cancelReason,
				modalId
			});
			this.resetCancelReason();
		}
	};

	render() {
		const {
			visible,
			isJobLinked,
			jobType,
			isHubUser,
			isSendCancelEmailJobStatus
		} = this.props;
		const { cancelReason } = this.state;
		return (
			<>
				<Modal
					destroyOnClose
					visible={visible}
					okText="Confirm"
					size="md"
					okDisabled={!cancelReason}
					closable={false}
					cancelText="Cancel"
					onCancel={this.hideConfirmationModal}
					onOk={this.onJobCancellationConfirmed}
				>
					{isJobLinked && jobType === JobType.NOMINATION ? (
						<p>
							Cancelling the job will unlink both appointment and nomination
							jobs and, remove all measurements recorded and unallocate all
							cargoes against cancelled jobs in the vessel programme. Do you
							want to proceed?
						</p>
					) : (
						<p>
							Cancelling the job will remove all measurements recorded and
							unallocate all cargoes against cancelled jobs in the vessel
							programme. Do you want to proceed?
						</p>
					)}
					<p>
						<Asteriks position="right">
							Please provide a reason for cancelling the job:
						</Asteriks>
					</p>
					<Select
						placeholder="Select a reason"
						onSelect={(value: PortJobCancelReason) => {
							this.setState({ cancelReason: value });
						}}
					>
						{Object.keys(PortJobCancelReason).map(reason => (
							<Select.Option key={reason} value={reason}>
								{PortJobCancelReasonMap[reason]}
							</Select.Option>
						))}
					</Select>
				</Modal>
				{isHubUser && !isSendCancelEmailJobStatus && (
					<CancelPortJobMessageModal modalId="send-cancel-email-modal" />
				)}
			</>
		);
	}

	private resetCancelReason = () => {
		this.setState({ cancelReason: null });
	};
}

export default connect(
	(state: AppState, ownProps: Pick<CancelPortJobModalProps, 'modalId'>) => {
		const { jobType } = getActivePortJob(state);
		return {
			visible: isModalVisible(state, ownProps.modalId),
			isJobLinked: getIsJobLinked(state),
			jobType: jobType,
			isSendCancelEmailJobStatus: getSendCancelEmailJobStatus(state),
			isHubUser: getIsCurrentUserHub(state)
		};
	},
	{
		closeModal,
		cancelPortJob
	}
)(CancelPortJobModal);
