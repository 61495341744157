import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'components/antd';
import { confirmPortJob } from 'store/portJobs/actions';
import { isModalVisible } from 'store/modals/selectors';
import { getIsActivePortJobCancelled } from 'store/portJobs/selectors';
import { closeModal, openModal } from 'store/modals/actions';
import { CommonActionModalProps } from './ActionModalTypes';
import { AppState } from 'store-types';
import { PermissionButton } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

interface ConfirmPortJobModalProps extends CommonActionModalProps {
	okText?: string;
	cancelText?: string;
}

export const ConfirmPortJobModal: FC<ConfirmPortJobModalProps> = ({
	modalId,
	portCallId,
	jobCode,
	okText = 'Confirm',
	cancelText = 'Cancel'
}) => {
	const dispatch = useDispatch();
	const visible = useSelector((state: AppState) =>
		isModalVisible(state, modalId)
	);
	const disabled = useSelector(getIsActivePortJobCancelled);

	const onJobConfirmed = useCallback(() => {
		dispatch(
			confirmPortJob({
				portCallId,
				jobCode,
				modalId
			})
		);
	}, [dispatch, portCallId, jobCode, modalId]);

	const showConfirmJobModal = useCallback(() => {
		dispatch(openModal({ name: modalId }));
	}, [dispatch, modalId]);

	const hideConfirmJobModal = useCallback(() => {
		dispatch(closeModal({ name: modalId }));
	}, [dispatch, modalId]);

	return (
		<>
			<PermissionButton
				permissionCode={PermissionCode.MANAGE_PORTCALL}
				type="primary"
				onClick={showConfirmJobModal}
				disabled={disabled}
			>
				Confirm Job
			</PermissionButton>
			<Modal
				visible={visible}
				okText={okText}
				closable={false}
				cancelText={cancelText}
				onCancel={hideConfirmJobModal}
				onOk={onJobConfirmed}
			>
				By pressing 'Confirm' button you confirm you are done and the job is
				ready to be processed by hub. Continue?
			</Modal>
		</>
	);
};

export default ConfirmPortJobModal;
