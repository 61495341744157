import { all, fork } from 'redux-saga/effects';
import createPortCallDocumentWatcher from './createPortCallDocument/createPortCallDocumentSaga';
import deletePortCallDocumentWatcher from './deletePortCallDocumentSaga';
import downloadPortCallDocumentsWatcher from './downloadPortCallDocumentsSaga';
import editNotIssuedPortCallDocumentWatcher from './editNotIssuedPortCallDocumentSaga';
import editUploadedDocumentCommentWatcher from './editUploadedDocumentCommentSaga';
import markPortCallDocumentNotIssuedWatcher from './markPortCallDocumentNotIssuedSaga';
import retrieveAllowedDocumentsWatcher from './retrieveAllowedDocumentsSaga';
import retrieveRequiredDocumentsWatcher from './retrieveRequiredDocumentsSaga';
import retrieveUploadedDocumentsWatcher from './retrieveUploadedDocumentsSaga';
import retrievePortCallDocumentsCycleWatcher from './retrievePortCallDocumentsCycleSaga';
import retrievePortCallDocumentWatcher from './retrievePortCallDocumentSaga';
import deletePortCallDocumentPageWatcher from './deletePortCallDocumentPageSaga';
import reactToChangesForDocumentsWatcher from './reactToChangesForDocumentsSaga';

export default function*() {
	yield all([
		fork(createPortCallDocumentWatcher),
		fork(deletePortCallDocumentWatcher),
		fork(downloadPortCallDocumentsWatcher),
		fork(editNotIssuedPortCallDocumentWatcher),
		fork(editUploadedDocumentCommentWatcher),
		fork(markPortCallDocumentNotIssuedWatcher),
		fork(retrieveAllowedDocumentsWatcher),
		fork(retrieveRequiredDocumentsWatcher),
		fork(retrieveUploadedDocumentsWatcher),
		fork(retrievePortCallDocumentsCycleWatcher),
		fork(retrievePortCallDocumentWatcher),
		fork(deletePortCallDocumentPageWatcher),
		fork(reactToChangesForDocumentsWatcher)
	]);
}
