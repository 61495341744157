export const TYPES = {
	primary: 'primary',
	lines: 'lines',
	delimiter: 'delimiter'
};

export enum ReadMoreType {
	PRIMARY = 'primary',
	LINE = 'lines',
	DELIMITER = 'delimiter'
}
