import { AddCompanyDraftFormData } from 'containers/Drafts/constants';
import {
	Terminal,
	RotationStepPOIType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { Port } from 'services/api/portCalls/portCallsServiceTypes';
import { CompanyDraftResponse } from 'services/api/portJobs/portJobsServiceTypes';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';

export enum DraftType {
	CHARTERER = 'charterer',
	AGENT = 'agent',
	SHIPPER_RECEIVER = 'shipperReceiver',
	POI_TERMINAL = 'terminal/berth',
	POI_BERTH = 'berth',
	POI_BUOY = 'buoy',
	POI_CANAL = 'canal',
	POI_ANCHORAGE = 'anchorage',
	VENDOR = 'vendor',
	PRINCIPAL = 'principal'
}

export type CompanyDraftType =
	| DraftType.AGENT
	| DraftType.CHARTERER
	| DraftType.SHIPPER_RECEIVER
	| DraftType.VENDOR
	| DraftType.PRINCIPAL;

export const DraftTypeToOrganisationTypeMap = {
	[DraftType.CHARTERER]: OrganisationType.CHARTERER,
	[DraftType.SHIPPER_RECEIVER]: OrganisationType.SHIPPER,
	[DraftType.AGENT]: [OrganisationType.LPA, OrganisationType.ISS_LPA],
	[DraftType.VENDOR]: [OrganisationType.VENDOR],
	[DraftType.PRINCIPAL]: [OrganisationType.PRINCIPAL]
};

export const RotationStepPOITypeToDraftType = {
	[RotationStepPOIType.TERMINAL]: DraftType.POI_TERMINAL,
	[RotationStepPOIType.BERTH]: DraftType.POI_BERTH,
	[RotationStepPOIType.BUOY]: DraftType.POI_BUOY,
	[RotationStepPOIType.CANAL]: DraftType.POI_CANAL,
	[RotationStepPOIType.ANCHORAGE]: DraftType.POI_ANCHORAGE
};

export const DraftTypeToRotationStepPOIType = {
	[DraftType.POI_TERMINAL]: RotationStepPOIType.TERMINAL,
	[DraftType.POI_BERTH]: RotationStepPOIType.BERTH,
	[DraftType.POI_BUOY]: RotationStepPOIType.BUOY,
	[DraftType.POI_CANAL]: RotationStepPOIType.CANAL,
	[DraftType.POI_ANCHORAGE]: RotationStepPOIType.ANCHORAGE
};

export interface CompanyDraftPlain {
	id: string;
	name: string;
}

export enum DraftKind {
	NEW,
	SAVED
}

interface CompanyDraftBase extends CompanyDraftPlain {
	isDraft: boolean;
	companyOrganizationTypeCode: OrganisationType;
}
export interface NewCompanyDraft
	extends CompanyDraftBase,
		AddCompanyDraftFormData {
	kind: DraftKind.NEW;
	isDraft: true; // newly created drafts are always true
}
interface SavedCompanyDraft extends CompanyDraftBase, CompanyDraftResponse {
	kind: DraftKind.SAVED;
}

export type CompanyDraft = NewCompanyDraft | SavedCompanyDraft;

export interface POIDraft {
	id: string;
	name: string;
	code?: string;
	// temp requirement from BE: interface incompatibility: parentPortPOIId for response, parentPOIId for request
	parentPortPOIId?: string;
	parentPOIId?: string;
	portPOITypeCode: RotationStepPOIType;
	portPOITypeName: RotationStepPOIType;
	latitude: number;
	longitude: number;
	isDeleted?: boolean;
	createdOn?: string;
	updatedOn?: string;
}

export interface POIContext {
	port: Port;
	terminal?: Terminal;
	rotationStepId: string;
}

export interface DraftsByType {
	charterer: CompanyDraft[];
	agent: CompanyDraft[];
	shipperReceiver: CompanyDraft[];
	pointOfInterest: POIDraft[];
	vendor: CompanyDraft[];
	principal: CompanyDraft[];
}

export interface DraftsState {
	readonly draftsByType: DraftsByType;
	readonly context: {
		activeDraftType?: DraftType;
		activeDraftTypeAlias?: string;
		activeFormId?: string;
		activeFieldName?: string;
		activePOIContext?: POIContext;
		activeTerminalIdByRotationStepId: {
			[key: string]: string;
		};
	};
}

export const initialState: DraftsState = {
	draftsByType: {
		charterer: [],
		agent: [],
		shipperReceiver: [],
		pointOfInterest: [],
		vendor: [],
		principal: []
	},
	context: {
		activeTerminalIdByRotationStepId: {}
	}
};
