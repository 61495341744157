import {
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_MAIN_PRINCIPAL_GROUPS';

export const retrieveMainPrincipalGroupsAsync = actionCreator.async<
	RetrieveGroupsRequest,
	RetrieveGroupsResponse,
	Error
>(ACTION_NAME);

export const retrieveMainPrincipalGroups = actionCreator<RetrieveGroupsRequest>(
	ACTION_NAME
);
