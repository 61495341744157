import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { CommonActionModalProps } from '../ActionModalTypes';
import { AppState } from 'store-types';
import { getActivePortJob } from 'store/portJobs/selectors';
import ClonePortJobForm from './ClonePortJobForm';
import { Commodity } from 'services/api/portJobs/portJobsServiceTypes';
import { retrievePortCallCycleStart } from 'store/portcalls/actions';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { nameToLabelField } from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest.func';

interface ClonePortJobModalProps extends CommonActionModalProps {
	jobCode: string;
	portCallId: string;
	// from mapStateToProps
	visible: boolean;
	jobType: string;
	hubPrincipalCompany: Commodity;
	portCall: PortCall;
	isIntegrationDisabled: boolean;
	isIntegrationJob: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	retrievePortCallCycleStart: typeof retrievePortCallCycleStart;
}

class ClonePortJobModal extends React.PureComponent<ClonePortJobModalProps> {
	hideConfirmationModal = () => {
		const { modalId } = this.props;
		this.props.closeModal(modalId);
		this.props.retrievePortCallCycleStart(this.props.portCallId);
	};

	render() {
		const {
			visible,
			jobCode,
			jobType,
			hubPrincipalCompany,
			portCallId,
			portCall,
			isIntegrationDisabled,
			isIntegrationJob,
			modalId
		} = this.props;

		const principleCompany = {
			key: hubPrincipalCompany.id,
			label: hubPrincipalCompany.name
		};

		const parentCloneJobData = {
			vessel: nameToLabelField(portCall.vessel),
			port: nameToLabelField(portCall.port),
			eta: portCall.etaPlt,
			ets: portCall.etsPlt ? portCall.etsPlt : ''
		};
		return (
			<>
				<Modal
					destroyOnClose
					visible={visible}
					size="md"
					title="Clone Job"
					onCancel={this.hideConfirmationModal}
					footer={null}
				>
					<ClonePortJobForm
						jobCode={jobCode}
						jobType={jobType}
						portCallId={portCallId}
						hubPrincipalCompany={principleCompany}
						closeModal={this.hideConfirmationModal}
						parentCloneJobData={parentCloneJobData}
						isIntegrationJob={isIntegrationJob}
						isIntegrationDisabled={isIntegrationDisabled}
						modalId={modalId}
					/>
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<ClonePortJobModalProps, 'modalId'>) => {
		const {
			jobType,
			hubPrincipalCompany,
			isIntegrationJob,
			linkedPortJobDetails,
			isCloneAllowed
		} = getActivePortJob(state);

		const isIntegrationDisabled =
			linkedPortJobDetails.length > 0 || !isCloneAllowed;

		return {
			visible: isModalVisible(state, ownProps.modalId),
			jobType: jobType,
			hubPrincipalCompany: hubPrincipalCompany,
			portCall: getActivePortCall(state),
			isIntegrationJob,
			isIntegrationDisabled
		};
	},
	{
		closeModal,
		retrievePortCallCycleStart
	}
)(ClonePortJobModal);
