import {
	all,
	take,
	call,
	put,
	fork,
	select,
	takeLatest
} from 'redux-saga/effects';
import {
	initializeConfigurationGeneralDetailsAsync as async,
	retrieveMainPrincipalGroups
} from '../actions';
import {
	retrieveCompanyGeneralConfiguration,
	retrieveCompanyGeneralConfigurationAsync,
	retrieveCompaniesConfigurationSettings
} from 'store/companies/actions';
import { retrieveRegions } from 'store/masterdata/regions/actions';
import { retrieveCurrencies } from 'store/currencies/actions';

import { getActiveCompanyId } from '../selectors';
import initializeConfigurationGeneralDetailsFormWorker from './initializeConfigurationGeneralDetailsForm/initializeConfigurationGeneralDetailsFormWorker';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

export function* initializeGeneralDetailsWorker() {
	const companyId: string = yield select(getActiveCompanyId);
	yield put(retrieveCompanyGeneralConfiguration({ companyId }));
	const response = yield take([
		retrieveCompanyGeneralConfigurationAsync.done,
		retrieveCompanyGeneralConfigurationAsync.failed
	]);

	if (response.payload.error) {
		throw new Error();
	}

	yield fork(
		initializeConfigurationGeneralDetailsFormWorker,
		response.payload.result
	);
}

export function* worker() {
	yield put(async.started(null));
	try {
		const companyId: string = yield select(getActiveCompanyId);
		yield all([
			// Extra data
			put(retrieveCompaniesConfigurationSettings()),
			put(retrieveRegions()),
			put(retrieveCurrencies({})),
			put(
				retrieveMainPrincipalGroups({
					mainPrincipalCompanyId: companyId,
					permission: PermissionCode.VIEW_MESSAGES,
					types: [GroupTypeCodes.ISS_HUB],
					limit: undefined,
					index: undefined
				})
			)
		]);
		yield call(initializeGeneralDetailsWorker);
		yield put(
			async.done({
				result: null,
				params: null,
				response: null
			})
		);
	} catch (e) {
		yield put(
			async.failed({
				error: e,
				params: null
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
