import { ConfigurationTabKey } from '../ConfigurationTypes';
import { CompanyTemplate } from 'services/api/templates/templatesServiceTypes';

export const EMAIL_FRAGMENTS_FORM_NAME = ConfigurationTabKey.EMAIL_FRAGMENTS;

export enum FormDataField {
	LIST = 'list'
}

/*
	As part of OW-13113(UD_10) removed the appointing agent without
	extended messaging. Need to delete the commented code once the other 
	jira ticket picked.
*/

export enum FormField {
	// EXTENDED_MESSAGE = 'extendedMessagingOption',
	OPTIONS = 'options',
	FRAGMENTS = 'fragments'
}

export interface FormData {
	// extendedMessagingOption: string;
	list: FormDataObj[];
}
export type FormDataObj = CompanyTemplate;

// export enum ExtendedMessageOptions {
// 	YES = 'Yes',
// 	NO = 'No'
// }
