import React from 'react';
import { Text, ScrollableLayout } from 'components';
import { PortCallRow } from 'sections/PortCalls/PortCallRow/PortCallRow';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { getSelectedLinkJob } from 'store/portJobs/portJobsSelectors';
import { setPortJobContext } from 'store/portJobs/actions';
import { Modal } from 'components/antd';
import { navigateTo } from 'utils';
import styles from '../../LinkJob.module.scss';

interface LinkJobOptionsListProps {
	portCalls: PortCall[];
	selectedLinkJob: string;
	setPortJobContext: typeof setPortJobContext;
}

interface LinkJobOptionsListState {
	showWarningModal: boolean;
	linkToPortCall: string;
}

class LinkJobOptionsList extends React.PureComponent<
	LinkJobOptionsListProps,
	LinkJobOptionsListState
> {
	constructor(props: LinkJobOptionsListProps) {
		super(props);
		this.state = {
			showWarningModal: false,
			linkToPortCall: ''
		};
	}

	onOptionChange = (jobCode: string, portCallId: string) => {
		this.props.setPortJobContext({
			selectedLinkJobCode: jobCode,
			selectedLinkJobPortCallId: portCallId
		});
	};

	onShowWarningModal = (link: string) => {
		this.setState({
			showWarningModal: true,
			linkToPortCall: link
		});
	};

	hideWarningModal = () => {
		this.setState({
			showWarningModal: false
		});
	};
	onLeave = () => {
		navigateTo(this.state.linkToPortCall);
		this.hideWarningModal();
	};

	openInNewTab = () => {
		this.hideWarningModal();
		setTimeout(() => {
			window.open(this.state.linkToPortCall, '_blank');
		}, 100);
	};

	render() {
		const { portCalls } = this.props;
		return (
			<ScrollableLayout>
				{portCalls.length !== 0 ? (
					<>
						<Text weight="bold" className={styles.subTitle}>
							The following jobs match your criteria
						</Text>
						{portCalls.map(portCall => (
							<table key={portCall.id}>
								<tbody>
									<PortCallRow
										portCall={portCall}
										onPortJobClick={this.onShowWarningModal}
										checkPortJobCanView
										onOptionChange={this.onOptionChange}
									/>
								</tbody>
							</table>
						))}
					</>
				) : (
					<Text weight="bold" className={styles.subTitle}>
						No appropriate job of type appointment is found in the port call.
						Please wait until it's created in Optic and repeat the process
					</Text>
				)}
				<Modal
					visible={this.state.showWarningModal}
					onCancel={this.openInNewTab}
					onClose={this.hideWarningModal}
					onOk={this.onLeave}
					cancelText={'Open in new tab'}
					okText="Confirm"
				>
					<p>
						You are leaving the job linking page. The job linkage process will
						be cancelled
					</p>
				</Modal>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		selectedLinkJob: getSelectedLinkJob(state)
	}),
	{
		setPortJobContext
	}
)(LinkJobOptionsList);
