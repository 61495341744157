import actionCreator from 'store/finance/financeActionCreator';
import { UpdateBenchmarkActionPayload } from 'services/api/finance/financeServiceTypes';

const ACTION_NAME = 'UPDATE_BENCHMARK';

export const updateBenchmark = actionCreator<UpdateBenchmarkActionPayload>(
	ACTION_NAME
);
export const updateBenchmarkAsync = actionCreator.async<
	UpdateBenchmarkActionPayload,
	null,
	Error
>(ACTION_NAME);
