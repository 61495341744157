import { call } from 'redux-saga/effects';

import Api from 'services/api';
import { deleteGroupAsync } from '../actions/deleteGroup';
import { DeleteGroupRequest } from 'services/api/groups/groupsServiceTypes';
import { navigateTo } from 'utils';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<DeleteGroupRequest, null, Error>({
	api: Api.Groups.deleteGroup,
	async: deleteGroupAsync,
	*onSuccess() {
		yield call(navigateTo, '/groups');
	}
});
