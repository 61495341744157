import actionCreator from '../supplierInvoiceActionCreator';
import {
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

const ACTION_NAME = 'RETRIEVE_SUPPLIER_INVOICES';

export const retrieveSupplierInvoicesAsync = actionCreator.async<
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'section', infiniteLoad: true } });

export const retrieveSupplierInvoices = actionCreator<
	RetrieveSupplierInvoicesParams
>(ACTION_NAME);
