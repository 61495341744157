import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'components/antd';
import { DashboardTableau } from 'services/api/dashboard/dashboardServiceTypes';
import { getUserType, getUserCompanyId } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	setDashboardTableauCompanyId,
	resetDashboardTableauCompanyId
} from 'store/dashboard/actions';
import JobsTab from './JobsTab/JobsTab';
import Tableau from './Tableau/Tableau';
import styles from './Dashboard.module.scss';

const JOBS_TAB = {
	name: 'JOBS',
	code: 'JOBS'
};

interface DashboardTabsProps {
	tableauTabs: DashboardTableau[];
}

const DashboardTabs: FC<DashboardTabsProps> = ({ tableauTabs }) => {
	const dispatch = useDispatch();
	const userType = useSelector(getUserType);
	const userCompanyId = useSelector(getUserCompanyId);

	const [activeKey, setActiveKey] = useState(JOBS_TAB.code);

	useEffect(() => {
		if (userType !== UserType.HUB) {
			// Operate its own main principal company
			dispatch(setDashboardTableauCompanyId(userCompanyId));
		}
	}, [userType, userCompanyId, dispatch]);

	const renderTabPane = (tab: DashboardTableau) => (
		<Tabs.TabPane key={tab.code} tab={tab.name}>
			{renderTabPaneContent(tab)}
		</Tabs.TabPane>
	);

	const renderTabPaneContent = (tab: DashboardTableau) => {
		if (tab.code !== activeKey) {
			return null;
		}
		if (tab.code === JOBS_TAB.code) {
			return <JobsTab />;
		}
		return <Tableau tab={tab} />;
	};

	const onTabChange = (tabKey: string) => {
		setActiveKey(tabKey);
		/**
		 * Reset the selected company for HUB users
		 * because of different accessible data scopes between two tabs
		 */
		if (userType === UserType.HUB) {
			dispatch(resetDashboardTableauCompanyId());
		}
	};

	return (
		<>
			<Tabs
				className={styles.tabs}
				defaultActiveKey={JOBS_TAB.code}
				onChange={onTabChange}
			>
				{renderTabPane(JOBS_TAB)}
				{tableauTabs.map(tab => renderTabPane(tab))}
			</Tabs>
		</>
	);
};

export default DashboardTabs;
