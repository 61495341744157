import { QuickThreadJobAssigmentState } from '../threadState';
import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import {
	searchJobForThreadAsync,
	quickAssignJobToThreadAsync,
	searchJobForThread,
	SearchForJobThreadRequest,
	closeThreadJobSearch
} from '../actions/quickJobAssignToThread';
import {
	setFetchPending,
	setFetchSuccess,
	setFetchFailed,
	setFetchIdle
} from 'store/utils';
import { PortCallJobSearchResult } from 'services/api/portCalls/portCallsServiceTypes';
import { Success } from 'typescript-fsa';
import { flow } from 'lodash';
import { changeRoute } from 'store/route/actions';
import { setCurrentMailboxCompany } from '../actions/setCurrentMailbox';

const setJobsSearchResult = (
	state: QuickThreadJobAssigmentState,
	{ result }: Success<SearchForJobThreadRequest, PortCallJobSearchResult[]>
): QuickThreadJobAssigmentState => ({
	...state,
	jobs: result
});

const clearJobsSearchResult = (
	state: QuickThreadJobAssigmentState
): QuickThreadJobAssigmentState => ({
	...state,
	jobs: []
});

// TODO remove generic use for 3.x TS, as it can infer that
const clearStatuses = flow(
	setFetchIdle<QuickThreadJobAssigmentState>('assignStatus'),
	setFetchIdle<QuickThreadJobAssigmentState>('fetchStatus')
);

const clearAll = flow(
	clearJobsSearchResult,
	clearStatuses
);

export const quickJobAssignment = reducerWithoutInitialState<
	QuickThreadJobAssigmentState
>()
	.case(setCurrentMailboxCompany, clearJobsSearchResult)
	.case(
		searchJobForThread,
		setFetchIdle<QuickThreadJobAssigmentState>('fetchStatus')
	)
	.case(
		searchJobForThreadAsync.started,
		setFetchPending<QuickThreadJobAssigmentState>('fetchStatus')
	)
	.case(
		searchJobForThreadAsync.done,
		flow(
			setJobsSearchResult,
			setFetchSuccess<QuickThreadJobAssigmentState>('fetchStatus')
		)
	)
	.case(
		searchJobForThreadAsync.failed,
		flow(
			clearJobsSearchResult,
			setFetchFailed<QuickThreadJobAssigmentState>('fetchStatus')
		)
	)
	.case(
		quickAssignJobToThreadAsync.started,
		setFetchPending<QuickThreadJobAssigmentState>('assignStatus')
	)
	.case(
		quickAssignJobToThreadAsync.done,
		setFetchSuccess<QuickThreadJobAssigmentState>('assignStatus')
	)
	.case(
		quickAssignJobToThreadAsync.failed,
		setFetchFailed<QuickThreadJobAssigmentState>('assignStatus')
	)
	.case(changeRoute, clearAll)
	.case(closeThreadJobSearch, clearAll);
