import client from 'services/client';

import {
	RetrieveDocumentRequest,
	RetrieveDocumentResponse,
	RetrieveDocumentConfigParams,
	SaveDocumentRequest,
	SaveDocumentResponse
} from './documentsServiceTypes';

const retrieveDocument = (params: RetrieveDocumentRequest) =>
	client.get<RetrieveDocumentResponse>(`/documents/${params.id}`);

const retrieveUploadConfiguration = (params: RetrieveDocumentConfigParams) =>
	client.get('/documents/upload-configuration', {
		params: {
			type: params.type
		}
	});

/**
 * Inform the upload it's completed
 */
const saveDocument = (params: SaveDocumentRequest) =>
	client.post<SaveDocumentResponse>('/documents', params);

export default {
	retrieveDocument,
	retrieveUploadConfiguration,
	saveDocument
};
