import { FinanceState } from '../financeState';
import {
	FinanceRequest,
	RetrieveBenchmarkStatus
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveBenchmarkStatus = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	FinanceRequest,
	RetrieveBenchmarkStatus
>('fetchStatuses', 'retrieveBenchmarkStatus');
