import {
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveBankAccountAsync } from './../actions/retrieveBankAccount';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse,
	Error
>({
	async: retrieveBankAccountAsync,
	api: Api.BankAccounts.retrieveBankAccount
});
