import { call, put, takeLatest, all, fork, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { retrieveEvents, retrieveEventsAsync } from '../actions';
import {
	RetrieveEventsRequest,
	RetrieveEventsParams
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import {
	getRotationStepByPosition,
	getDraggedOperationUnit
} from 'store/vesselProgramme/selectors';

import { Action } from 'typescript-fsa';

export function* retrieveEventsExecutor(
	actionParams: RetrieveEventsParams,
	api: typeof Api.VesselProgramme.retrieveEvents
): SagaIterator {
	const rotationStep = yield select(
		getRotationStepByPosition,
		actionParams.stepPosition
	);
	const draggedOperation = yield select(getDraggedOperationUnit);
	const { poiType } = rotationStep;
	const operationType = draggedOperation.operationTypeCode;
	const mainCommodity = draggedOperation.mainCommodity.code;
	const params: RetrieveEventsRequest = {
		poiType,
		operationType,
		mainCommodity
	};

	yield put(retrieveEventsAsync.started(actionParams));

	try {
		const response = yield call(api, params);

		yield put(
			retrieveEventsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveEventsAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* retrieveEventsWorker(
	action: Action<RetrieveEventsParams>
): SagaIterator {
	yield call(
		retrieveEventsExecutor,
		action.payload,
		Api.VesselProgramme.retrieveEvents
	);
}

export function* retrieveEventsWatcher(): SagaIterator {
	yield takeLatest(retrieveEvents.type, retrieveEventsWorker);
}

export default function*() {
	yield all([fork(retrieveEventsWatcher)]);
}
