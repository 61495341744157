import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { FetchStatus } from 'services/api/apiTypes';
import { getActivePortCallId } from '../../portcalls/selectors';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';
import {
	PortCallSection,
	PortCallEvent
} from 'services/api/portCall/portCallServiceTypes';

const getPortCallOperations = (state: AppState) =>
	state.portCallOperations.eventSectionsByPortCallId;

const getPortCallOperationsContext = (state: AppState) =>
	state.portCallOperations.context;

const getActivePortCallOperations = createSelector(
	getPortCallOperations,
	getActivePortCallId,
	(operations, portCallId) => operations[portCallId]
);

const getPortCallSectionsByCode = createSelector(
	getActivePortCallOperations,
	eventSections => eventSections?.sections || []
);

export const getPortCallEventsIdsBySectionId = createSelector(
	getActivePortCallOperations,
	eventSections => eventSections.eventsIdsBySectionId
);

export const getPortCallEventsById = createSelector(
	getActivePortCallOperations,
	operations => operations.eventsById
);

export const getPortCallParentSectionByCode = (
	state: AppState,
	parentSectionCode: string
) => {
	return getPortCallSectionsByCode(state)[parentSectionCode];
};

const getParentSectionSubSections = (
	state: AppState,
	parentSectionCode: string
) => getPortCallParentSectionByCode(state, parentSectionCode).sections;

const EMPTY_SECTION = {} as PortCallSection;
export const getPortCallEventSubSectionById = (
	state: AppState,
	parentSectionCode: string,
	sectionId: string
) =>
	getParentSectionSubSections(state, parentSectionCode).find(
		section => section.id === sectionId
	) || EMPTY_SECTION;

export const getPortCallEventsLoadingFetchStatus = (
	state: AppState,
	portCallId: string
) =>
	state.portCallOperations.fetchStatuses.eventSectionsByPortCallId[portCallId];

export const getPortCallEventSections = createSelector(
	getPortCallSectionsByCode,
	portCallEventsMap => Object.values(portCallEventsMap || {})
);

export const isUpdatingEvent = (state: AppState, id: string) =>
	state.portCallOperations.updateStatuses.events[id] === FetchStatus.PENDING;

export const getEventsUpdateStatus = (state: AppState) =>
	state.portCallOperations.updateStatuses.events;

export const getPortCallEvent = (
	state: AppState,
	eventId: string
): PortCallEvent | undefined =>
	state.portCallOperations.eventSectionsByPortCallId[getActivePortCallId(state)]
		.eventsById[eventId];

export const getPortCallEventConcurrencyToken = createSelector(
	getPortCallEvent,
	portCallEvent => portCallEvent?.concurrencyToken
);

const getEventIdsInSection = (state: AppState, sectionId: string) =>
	state.portCallOperations.eventSectionsByPortCallId[getActivePortCallId(state)]
		.eventsIdsBySectionId[sectionId];

const getEvents = (state: AppState) =>
	state.portCallOperations.eventSectionsByPortCallId[getActivePortCallId(state)]
		.eventsById;

export const makeGetPortCallEventsInSection = () =>
	createSelector(getEventIdsInSection, getEvents, (eventIds, events) => {
		return (eventIds || []).map(eventId => events[eventId]);
	});

export const getIsJobAssignmentEditable = createSelector(
	(_state: AppState, isJobAssignmentEditable: boolean) =>
		isJobAssignmentEditable,
	getUserType,
	(isJobAssignmentEditable, userType) =>
		userType === UserType.LPA && isJobAssignmentEditable
);

export const getLastUpdatedPortCallEventId = createSelector(
	getPortCallOperationsContext,
	context => context.lastUpdatedEventId
);
