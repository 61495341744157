import * as React from 'react';

import { FormattedTime } from 'containers';
import { GroupUser } from 'services/api/groups/groupsServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { UserStatus } from 'services/api/users/userServiceTypes';
import { PermissionButton } from 'components/Permission';
import { ColumnProps } from 'components/antd/Table/TableTypes';

export type Column = ColumnProps<GroupUser>;

export const getGroupUsersColumns = (
	onRemoveUser: (record: GroupUser) => void
): Column[] => [
	{
		title: 'First Name',
		dataIndex: 'firstName',
		key: 'firstName',
		width: '15%'
	},
	{
		title: 'Last Name',
		dataIndex: 'lastName',
		key: 'lastName',
		width: '15%'
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		width: '15%'
	},
	{
		title: 'Last Login',
		dataIndex: 'lastLoginDateTime',
		key: 'lastLoginDateTime',
		width: '50%',
		render: text => {
			return (
				<span>
					<FormattedTime value={text} />
				</span>
			);
		}
	},
	{
		title: '',
		width: '5%',
		align: 'right',
		render: (_, record) => (
			<PermissionButton
				permissionCode={PermissionCode.MANAGE_GROUP}
				tooltip={{ placement: 'left' }}
				type="danger"
				icon="delete"
				transparent
				onClick={() => onRemoveUser(record)}
				disabled={
					record.singleGroupUser &&
					record.status === UserStatus.REGISTRATION_PENDING
				}
			/>
		)
	}
];

export default getGroupUsersColumns;
