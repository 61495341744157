import { getIsLoading } from 'store/selectors';
import { AppState } from 'store-types.d';
import { createSelector } from 'reselect';
import { sortBy } from 'lodash';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { FileItemProps } from 'app-types';
import { getFileExtension } from 'utils';
import { FileExtension } from 'app-constants';
import { UserType } from 'services/api/users/userServiceTypes';

export const getBankAccountsById = ({ masterdata }: AppState) =>
	masterdata.bankAccounts.byId;

export const getBankAccounts = createSelector(
	getBankAccountsById,
	bankAccounts => Object.values(bankAccounts)
);

export const getBankAcccount = createSelector(
	getBankAccountsById,
	(_state: AppState, bankAccountId: string) => bankAccountId,
	(bankAccountsById, bankAccountId) =>
		bankAccountsById[bankAccountId] || undefined
);

export const getSortedBankAccounts = createSelector(
	getBankAccounts,
	bankAccounts => sortBy<BankAccount>(bankAccounts, account => account.label)
);

const getBankAccountsState = (state: AppState) => state.masterdata.bankAccounts;

export const getBankAccountsHasMore = createSelector(
	getBankAccountsState,
	({ hasMore }) => hasMore
);

export const getBankAccountsIndexForNextFetch = createSelector(
	getBankAccountsState,
	({ indexForNextFetch }) => indexForNextFetch
);

const getBankAccountsFetchStatuses = (state: AppState) =>
	getBankAccountsState(state).fetchStatuses;

export const getIsRetrieveBankAccountsLoading = createSelector(
	getBankAccountsFetchStatuses,
	({ byId }) => getIsLoading(byId)
);

export const getBankAccountsFetchStatus = (state: AppState) =>
	getBankAccountsFetchStatuses(state).byId;

export const getBankAccountFetchStatus = (state: AppState) =>
	getBankAccountsFetchStatuses(state).retrieveBankAccount;

export const getIsBankAccountsAdding = (state: AppState) =>
	getIsLoading(getBankAccountsState(state).fetchStatuses.add);

export const getActiveBank = (state: AppState) => {
	const id = getBankAccountsState(state).context.activeBankId;
	return id ? getBankAccountsById(state)[id] : undefined;
};

export const getIsLOBDVisible = createSelector(getActiveBank, bank =>
	Boolean(
		bank?.companyInfo &&
			bank.companyInfo.organisationTypeCode === OrganisationType.LPA
	)
);

export const getLOBDFileItemProps = (
	state: AppState
): FileItemProps | undefined => {
	const lobd = getBankAccountsState(state).LOBD;

	return (
		lobd && {
			id: lobd.id,
			name: lobd.name,
			extension: getFileExtension(lobd.name) as FileExtension,
			user: lobd.createdByUserName,
			userType: lobd.createdByUserType as UserType,
			uploadedOn: lobd.createdOn
		}
	);
};

export const getIsBankAccountUpdating = (state: AppState) =>
	getIsLoading(getBankAccountsFetchStatuses(state).update);

export const getIsLOBDLoading = createSelector(
	getIsBankAccountUpdating,
	(state: AppState) => getBankAccountsFetchStatuses(state).retrieveLOBD,
	(updating, fetchStatus) =>
		updating || [FetchStatus.IDLE, FetchStatus.PENDING].includes(fetchStatus)
);

export const getIsLOBDDownloading = (state: AppState) =>
	getIsLoading(getBankAccountsFetchStatuses(state).downloadLOBD);
