import { take, put, takeLatest } from 'redux-saga/effects';
import { deleteLOBD, updateBankAccount } from '../actions';
import { RetrieveBankAccountRequest as Request } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { Action } from 'typescript-fsa';
import { openModal, closeModal, CloseModalParams } from 'store/modals/actions';

const MODAL_NAME = 'DELETE';

export function* deleteLOBDWorker(action: Action<Request>) {
	yield put(
		openModal({
			name: MODAL_NAME,
			type: 'confirm',
			data: {
				description: 'Are you sure you want to permanently delete this file?'
			}
		})
	);

	const modal: Action<CloseModalParams> = yield take(closeModal.type);

	if (modal.payload.isConfirmed) {
		yield put(
			updateBankAccount({
				bankDetailId: action.payload.id,
				lobd: false,
				lobdDocumentId: null
			})
		);
	}
}

export default function*() {
	yield takeLatest(deleteLOBD.type, deleteLOBDWorker);
}
