import { FileExtension } from 'app-constants';
import { IconColor } from 'components/types';
import { IconType } from 'components/antd/Icon/IconTypes';

export const FILE_TYPE_ICON_MAP: { [id: string]: keyof IconType } = {
	[FileExtension.PDF]: 'file-pdf',
	[FileExtension.DOC]: 'file-word',
	[FileExtension.DOCX]: 'file-word',
	[FileExtension.XLS]: 'file-excel',
	[FileExtension.XLSX]: 'file-excel'
};

export const FILE_TYPE_ICON_COLOR_MAP: { [id: string]: IconColor } = {
	[FileExtension.PDF]: 'error',
	[FileExtension.DOC]: 'info',
	[FileExtension.DOCX]: 'info',
	[FileExtension.XLS]: 'success',
	[FileExtension.XLSX]: 'success'
};
