import Api from 'services/api';
import { call, select, put, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { deleteService, deleteServiceAsync } from '../actions';
import { openModal, closeModal } from 'store/modals/actions';
import {
	DeleteServiceRequest as Request,
	PortJobService,
	ServiceType,
	ServiceStatus
} from 'services/api/finance/financeServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import {
	getServiceById,
	getActiveStatusDaSet,
	getDraftServicesWithIssDirectBill,
	getAllServicesBySetId
} from 'store/finance/selectors';

const deleteServiceApiCall = Api.Finance.deleteService;

export const executor = function*({ jobServiceId }: Request) {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const selectedService: PortJobService = yield select(
		getServiceById,
		jobServiceId
	);
	const isServiceRegularOrSubmittedDirectBill =
		selectedService.serviceTypeCode === ServiceType.REGULAR ||
		(selectedService.serviceTypeCode === ServiceType.DIRECT_BILL &&
			selectedService.status === ServiceStatus.SUBMITTED);
	const draftServicesWithIssDirectBill = yield select(
		getDraftServicesWithIssDirectBill
	);
	let isHandlingOnDASetDeletion =
		isServiceRegularOrSubmittedDirectBill &&
		draftServicesWithIssDirectBill.length > 0;
	if (isHandlingOnDASetDeletion) {
		const draftServiceIdsWithIssDirectBill = draftServicesWithIssDirectBill.map(
			(service: PortJobService) => service.id
		);
		const activeDaSet = yield select(getActiveStatusDaSet);
		const servicesByActiveDaSet = activeDaSet
			? yield select(getAllServicesBySetId, activeDaSet.id)
			: ([] as string[]);
		const notDbServicesByActiveDaSet = servicesByActiveDaSet.filter(
			(serviceId: string) =>
				!draftServiceIdsWithIssDirectBill.includes(serviceId)
		);
		isHandlingOnDASetDeletion = notDbServicesByActiveDaSet.length === 1;
	}

	const request = {
		portCallId,
		jobCode,
		jobServiceId
	};

	const confirmationMessage = isHandlingOnDASetDeletion
		? 'Deletion of this service will trigger the deletion of the whole DA set, including the Direct Bill service. Are you sure you want to continue?'
		: `Are you sure you want to delete the service ${selectedService.code} - ${selectedService.name}?`;

	yield put(
		openModal({
			name: 'deleteService',
			type: 'confirm',
			data: {
				title: 'Delete service',
				description: confirmationMessage
			}
		})
	);

	const { payload } = yield take(closeModal.type);
	if (!payload.isConfirmed) {
		deleteServiceAsync.failed({
			error: new Error(),
			params: request
		});
		return;
	}

	yield put(deleteServiceAsync.started(request));

	try {
		const response = yield call(deleteServiceApiCall, request);

		yield put(
			deleteServiceAsync.done({
				result: response?.data,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			deleteServiceAsync.failed({
				error,
				params: request
			})
		);
	}
};

function* worker({ payload }: Action<Request>): SagaIterator {
	yield call(executor, payload);
}

export default function* watcher(): SagaIterator {
	yield takeLatest(deleteService.type, worker);
}
