import actionCreator from '../companyVesselsActionCreator';
import {
	RetrieveCompanyVesselsRequest,
	RetrieveCompanyVesselsResponse
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_COMPANY_VESSELS';

export const retrieveCompanyVesselsAsync = actionCreator.async<
	RetrieveCompanyVesselsRequest,
	RetrieveCompanyVesselsResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'sub-section', infiniteLoad: true } });

export const retrieveCompanyVessels = actionCreator<
	RetrieveCompanyVesselsRequest
>(ACTION_NAME);

export const resetCompanyVessels = actionCreator(`RESET_${ACTION_NAME}`);
