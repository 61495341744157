import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Select,
	InputNumber,
	TextArea,
	Autocomplete
} from 'components/antd';
import { Flex } from 'components';
import { Justify } from 'components/types';
import { AppState } from 'store-types';
import {
	CostSavingsFormData,
	CostSavingsFormFields,
	CostSavingsFormLabels,
	COST_SAVINGS_FORM,
	COST_SAVINGS_MODAL
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import Api from 'services/api';
import { InjectedFormProps, reduxForm, change } from 'redux-form';
import {
	DEFAULT_AUTOCOMPLETE_PLACEHOLDER,
	DEFAULT_REDUX_FORM_CONFIG
} from 'app-constants';
import { EditDirectBillMultipleAutocomplete } from 'sections/Administration/DataManagement/DirectBills/DirectBill/EditDirectBill/Fields/EditDirectBillMultipleAutocomplete';
import styles from './CostSavingsModal.module.scss';
import validate from './validate';
import { Operation } from 'services/api/savings/savingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { closeModal } from 'store/modals/actions';
import {
	addCostSavings,
	resetCostSavingsContext,
	updateCostSavings
} from 'store/savings/actions';
import { FetchStatus } from 'services/api/apiTypes';
import {
	getAddCostSavingFetchStatus,
	getUpdateCostSavings,
	getinitialStateId
} from 'store/savings/savingsSelectors';

export interface CostSavingsFormBaseProps {
	activePortCallId: string;
	activePortJobCode: string;
	initialValues: Partial<CostSavingsFormData>;
	initialStateId: string;
	isLoading: boolean;
	closeModal: typeof closeModal;
	change: typeof change;
	addCostSavings: typeof addCostSavings;
	updateCostSavings: typeof updateCostSavings;
	resetCostSavingsContext: typeof resetCostSavingsContext;
}

export type CostSavingsFormProps = InjectedFormProps<
	CostSavingsFormData,
	CostSavingsFormBaseProps
> &
	CostSavingsFormBaseProps;

interface CostSavingsFormState {
	operationList: Operation[];
	currencies: string[];
}

class CostSavingsForm extends Component<
	CostSavingsFormProps,
	CostSavingsFormState
> {
	constructor(props: CostSavingsFormProps) {
		super(props);
		this.state = {
			operationList: [],
			currencies: []
		};
	}

	componentDidMount() {
		const {
			activePortCallId,
			activePortJobCode,
			initialValues,
			change
		} = this.props;
		Api.Savings.retrieveOperationsValues().then(result => {
			this.setState(prev => ({
				...prev,
				operationList: [...result.data]
			}));
		});
		Api.Savings.retrievePortjobCurrencies({
			portCallId: activePortCallId,
			jobCode: activePortJobCode
		}).then(result => {
			this.setState(prev => ({
				...prev,
				currencies: [...result.data]
			}));
			if (!initialValues.category) {
				if (result.data.find(currency => currency === 'USD')) {
					change(CostSavingsFormFields.CURRENCY, 'USD');
				} else {
					change(CostSavingsFormFields.CURRENCY, result.data[0]);
				}
			}
		});
	}

	onCancel = () => {
		this.props.resetCostSavingsContext();
		this.props.closeModal(COST_SAVINGS_MODAL);
	};

	onCostSavings = () => {
		const { initialStateId } = this.props;
		if (initialStateId) {
			this.props.updateCostSavings();
		} else {
			this.props.addCostSavings();
		}
	};

	render() {
		const { operationList, currencies } = this.state;
		const { isLoading, initialValues } = this.props;
		return (
			<>
				<Row>
					<Col sm={6}>
						<Autocomplete.ReduxFormItem
							name={CostSavingsFormFields.CATEGORY}
							label={CostSavingsFormLabels[CostSavingsFormFields.CATEGORY]}
							required
							placeholder="Type to search"
							showSearch
							allowClear
							localSearch
							optionFilterProp="children"
							filterOption
							getPopupContainer={() => document.body}
							dropdownClassName={styles.currencySelect}
							labelInValue
							disabled={isLoading}
							help
						>
							{operationList.map(operation => (
								<Select.Option
									key={operation.categoryCode}
									title={operation.categoryName}
								>
									{operation.categoryName}
								</Select.Option>
							))}
						</Autocomplete.ReduxFormItem>
					</Col>
					<Col sm={6}>
						<EditDirectBillMultipleAutocomplete
							name={CostSavingsFormFields.SERVICES}
							label={CostSavingsFormLabels[CostSavingsFormFields.SERVICES]}
							placeholder={DEFAULT_AUTOCOMPLETE_PLACEHOLDER}
							disabled={isLoading}
							required
							source={Api.DirectBills.searchServicesForAutocomplete}
							help
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Select.ReduxFormItem
							name={CostSavingsFormFields.SAVING_BY}
							label={CostSavingsFormLabels[CostSavingsFormFields.SAVING_BY]}
							required
							getPopupContainer={() => document.body}
							disabled={isLoading}
							help
						>
							<Select.Option key={'LPA'} title="LPA">
								LPA
							</Select.Option>
							<Select.Option key={'Hub Operations'} title="Hub Operations">
								Hub Operations
							</Select.Option>
							<Select.Option key={'Hub Finance'} title="Hub Finance">
								Hub Finance
							</Select.Option>
							<Select.Option key={'Principal'} title="Principal">
								Principal
							</Select.Option>
						</Select.ReduxFormItem>
					</Col>
					<Col sm={3}>
						<InputNumber.ReduxFormItem
							name={CostSavingsFormFields.AMOUNT_SAVED}
							label={CostSavingsFormLabels[CostSavingsFormFields.AMOUNT_SAVED]}
							placeholder="Enter cost"
							allowNegative={false}
							maxDigits={15}
							integerPart={13}
							required
							min={0}
							decimalPart={2}
							disabled={isLoading}
							help
						/>
					</Col>
					<Col sm={3}>
						<Select.ReduxFormItem
							name={CostSavingsFormFields.CURRENCY}
							label={CostSavingsFormLabels[CostSavingsFormFields.CURRENCY]}
							required
							getPopupContainer={() => document.body}
							disabled={isLoading}
							help
						>
							{currencies.map(currency => (
								<Select.Option key={currency}>{currency}</Select.Option>
							))}
						</Select.ReduxFormItem>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<TextArea.ReduxFormItem
							name={CostSavingsFormFields.COMMENTS}
							label={CostSavingsFormLabels[CostSavingsFormFields.COMMENTS]}
							maxLength={1000}
							rows={3}
							placeholder="Enter comment"
							disabled={isLoading}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button
								type="primary"
								transparent
								onClick={() => this.onCancel()}
								disabled={isLoading}
							>
								Cancel
							</Button>
							<Button
								type="primary"
								disabled={!this.props.valid || this.props.pristine}
								onClick={() => this.onCostSavings()}
								loading={isLoading}
							>
								{initialValues.category
									? 'Save Cost Savings'
									: 'Add Cost Savings'}
							</Button>
						</Flex>
					</Col>
				</Row>
			</>
		);
	}
}

const CostSavingsReduxForm = reduxForm<
	CostSavingsFormData,
	CostSavingsFormBaseProps
>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: COST_SAVINGS_FORM,
	validate
})(CostSavingsForm);

const mapStateToProps = (state: AppState) => {
	const addCostSavingsFetchStatus = getAddCostSavingFetchStatus(state);
	const initialValues = getUpdateCostSavings(state);
	const initialStateId = getinitialStateId(state);
	return {
		initialValues,
		initialStateId,
		activePortCallId: getActivePortCallId(state),
		activePortJobCode: getActivePortJobCode(state),
		isLoading: addCostSavingsFetchStatus === FetchStatus.PENDING
	};
};

const mapDispatchToProps = {
	resetCostSavingsContext,
	updateCostSavings,
	closeModal,
	change,
	addCostSavings
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CostSavingsReduxForm);
