import * as React from 'react';
import { Button } from 'components/antd';
import Autocomplete, {
	AutocompleteProps,
	AutocompleteOption
} from 'components/antd/Autocomplete/Autocomplete';
import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';
import { customMap } from 'components/antd/Form/ReduxField/mapError';
import { getSearchResultsWithDrafts } from './AutoCompleteDrafts.func';
import { DropDownDraftProps } from '../draftsTypes';
import styles from '../Drafts.module.scss';
import { removeDraftSuffix } from '../draftsUtils';
import { isEMDDraftEnabled } from 'app-constants';

type AutoCompleteDraftsProps = AutocompleteProps & DropDownDraftProps;

class AutoCompleteDrafts extends React.Component<AutoCompleteDraftsProps> {
	static ReduxFormItem: CreateReduxField<AutoCompleteDraftsProps>;
	handleSearch = (value: string) => {
		if (!this.props.onSearch) {
			return Promise.resolve([] as AutocompleteOption[]);
		}
		return this.props
			.onSearch(value)
			.then(options =>
				getSearchResultsWithDrafts(options, this.props.drafts, value)
			);
	};

	render() {
		const {
			addDraftLinkTitle,
			drafts,
			onAddDraftClick,
			...autoCompleteProps
		} = this.props;
		return (
			<Autocomplete
				{...autoCompleteProps}
				cache={true}
				key={drafts.length}
				onSearch={this.handleSearch}
				dropdownClassName={styles.drafts}
				dropdownFooter={() => (
					<Button
						type="primary"
						transparent
						icon="plus"
						disabled={!isEMDDraftEnabled}
						onClick={onAddDraftClick}
					>
						{addDraftLinkTitle}
					</Button>
				)}
				dropdownFooterClassName={styles.addOptionMenuItem}
			/>
		);
	}
}

export const selectFieldMap = customMap<AutoCompleteDraftsProps>(props => {
	const value = props.input.value || props.defaultValue;
	if (!value) {
		return { value };
	}
	const label = (value.label && value.label.key) || value.label || '';
	return {
		value: {
			...value,
			label: removeDraftSuffix(label)
		}
	};
});

AutoCompleteDrafts.ReduxFormItem = createFieldComponent(
	AutoCompleteDrafts,
	selectFieldMap
);

export default AutoCompleteDrafts;
