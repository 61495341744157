import {
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse
} from 'services/api/threads/threadsServiceTypes';
import { Success, Failure } from 'typescript-fsa';
import {
	ThreadAvailableGroupsReducerState as State,
	setFetchStatus
} from '../../threadAvailableGroupsReducer';

export const onRetrieveAvailableThreadGroupsStarted = (state: State) =>
	setFetchStatus(state, 'groups', true);

export const onRetrieveAvailableThreadGroupsFailed = (
	state: State,
	_action: Failure<RetrieveAvailableThreadGroupsRequest, Error>
) => setFetchStatus(state, 'groups', false);

export const onRetrieveAvailableThreadGroupsSuccess = (
	state: State,
	action: Success<
		RetrieveAvailableThreadGroupsRequest,
		RetrieveAvailableThreadGroupsResponse
	>
) => ({
	...setFetchStatus(state, 'groups', false),
	groups: action.result.elements
});
