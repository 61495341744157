import Api from 'services/api';
import { retrieveCompanyVesselAsync as async } from 'store/companyVessels/actions';
import {
	RetrieveCompanyVesselRequest as request,
	RetrieveCompanyVesselResponse as response
} from 'services/api/companies/companiesServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';

const api = Api.Companies.retrieveCompanyVessel;

export default makeTakeLatestWatcher<request, response, Error>({
	api,
	async
});
