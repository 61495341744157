import actionCreator from '../financeActionCreator';
import { DeleteFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';

const ACTION_NAME = 'DELETE_FINANCE_DOCUMENT';

export const deleteFinanceDocumentAsync = actionCreator.async<
	DeleteFinanceDocumentRequest,
	number, // status
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Finance Document'
	}
});

export const deleteFinanceDocument = actionCreator<
	DeleteFinanceDocumentRequest
>(ACTION_NAME);
