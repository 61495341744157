import actionCreator from '../financeActionCreator';
import { UpdateSDAReasonRequest } from 'services/api/finance/financeServiceTypes';

const UPDATE_SDA_REASON = 'UPDATE_SDA_REASON ';

export const updateSDAReasonAsync = actionCreator.async<
	UpdateSDAReasonRequest,
	null,
	Error
>(UPDATE_SDA_REASON);

export const updateSDAReason = actionCreator<UpdateSDAReasonRequest>(
	UPDATE_SDA_REASON
);
