import {
	ThreadStatus,
	ThreadsAssignedToUserFilter
} from 'services/api/threads/threadsServiceTypes';
import {
	THREADS_FILTER_NAME,
	ThreadsFilterParamName
} from 'store/threads/filtersSync';
import { threadsAssignedToUserKeyMap } from 'types/sections/ThreadsTypes';

export const getStatusPayload = (status: ThreadStatus) => {
	return {
		// assignedTo has slightly different semantics
		name: THREADS_FILTER_NAME,
		filters: {
			[ThreadsFilterParamName.STATUS]: [
				{
					key: status,
					label: status,
					type: ThreadsFilterParamName.STATUS
				}
			]
		}
	};
};

export const getAssignedToPayload = (
	assignedTo: ThreadsAssignedToUserFilter
) => {
	return {
		// assignedTo has slightly different semantics
		name: THREADS_FILTER_NAME,
		filters: {
			[ThreadsFilterParamName.ASSIGNED_TO]: [
				{
					key: assignedTo,
					label: threadsAssignedToUserKeyMap[assignedTo],
					type: ThreadsFilterParamName.ASSIGNED_TO_USER
				}
			]
		}
	};
};
