import { retrievePrincipalsForMainPrincipalAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePrincipalsForMainPrincipalRequest,
	RetrieveCompaniesResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePrincipalsForMainPrincipalRequest,
	RetrieveCompaniesResponse,
	Error
>({
	api: Api.Companies.retrievePrincipalsForMainPrincipal,
	async
});
