import actionCreator from '../portCallDocumentsActionCreator';
import {
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'RETRIEVE_UPLOADED_DOCUMENTS';

export const retrieveUploadedDocumentsAsync = actionCreator.async<
	RetrieveUploadedDocumentsRequest,
	RetrieveUploadedDocumentsResponse,
	Error
>(ACTION_NAME);

export const retrieveUploadedDocuments = actionCreator<
	RetrieveUploadedDocumentsRequest
>(ACTION_NAME);

export const resetUploadedDocuments = actionCreator('RESET_UPLOADED_DOCUMENTS');
