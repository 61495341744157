import { retrieveAppointersAction } from 'store/portJobs/actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { ResultCollection } from 'services/api/apiTypes';
import {
	AppointerType,
	RetrieveAppointerRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const apiCall = Api.PortJobs.retrieveAppointers;
const { async } = retrieveAppointersAction;

export const retrieveAppointersWatcher = makeTakeLatestWatcher<
	RetrieveAppointerRequest,
	ResultCollection<AppointerType>,
	Error
>({
	api: apiCall,
	async
});
