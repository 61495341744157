import * as React from 'react';
import { connect } from 'react-redux';
import AntUpload, {
	UploadProps as AntUploadProps
} from 'antd/lib/upload/Upload';
import AntDragger from 'antd/lib/upload/Dragger';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import {
	getDocumentAllowedExtensions,
	getAllowedFileNameCharacters
} from 'store/documents/selectors';
import { getFileExtension } from 'utils';
import { notify } from 'store/notifications/actions';
import { UploadType } from 'services/api/documents/documentsServiceTypes';
import { getInvalidFileNameError } from 'utils/validations';
import { defaultType } from 'containers/UploadProcessDocumentProvider/UploadProcessDocumentProvider';
import { AppState } from 'store-types';

export interface UploadProps extends AntUploadProps {
	preventDefaultBeforeUpload?: boolean;
	uploadType?: UploadType;
	isDragger?: boolean;
	// from mapStateToProps
	allowedExtensions: string;
	allowedFileNameCharacters: string[];
	// from mapDispatchToProps
	notifyWarning: typeof notify.warning;
}

class CustomUpload extends React.Component<UploadProps> {
	isFileExtensionValid = (file: UploadFile): boolean => {
		const allowedExtensions = this.props.accept || this.props.allowedExtensions;
		const fileExt = getFileExtension(file.name);
		if (allowedExtensions && fileExt) {
			const isValid = allowedExtensions
				.toLowerCase()
				.includes(fileExt.toLowerCase());
			if (!isValid) {
				this.props.notifyWarning('This file type is not supported.');
				return isValid;
			}
		}
		return true;
	};

	isFileNameValid = (file: RcFile): boolean => {
		const error = getInvalidFileNameError(
			file.name,
			this.props.allowedFileNameCharacters
		);
		if (!error) {
			return true;
		}
		this.props.notifyWarning(error);
		return false;
	};

	beforeUpload = (file: RcFile, FileList: RcFile[]): boolean => {
		/**
		 * IPP-24787 If it's not explicitly prevented, we always validate file name and extension.
		 * Only if they're valid we invoke custom beforeUpload or continue upload process
		 */
		if (!this.props.preventDefaultBeforeUpload) {
			const isFileValid =
				this.isFileExtensionValid(file) && this.isFileNameValid(file);
			if (!isFileValid) {
				return false;
			}
		}

		return this.props.beforeUpload
			? !!this.props.beforeUpload(file, FileList)
			: true;
	};

	render() {
		if (this.props.isDragger) {
			return <AntDragger {...this.props} beforeUpload={this.beforeUpload} />;
		}
		return <AntUpload {...this.props} beforeUpload={this.beforeUpload} />;
	}
}

export default connect(
	(
		state: AppState,
		{ uploadType = defaultType }: Pick<UploadProps, 'uploadType'>
	) => ({
		allowedExtensions: getDocumentAllowedExtensions(state, uploadType),
		allowedFileNameCharacters: getAllowedFileNameCharacters(state, uploadType)
	}),
	{
		notifyWarning: notify.warning
	}
)(CustomUpload);
