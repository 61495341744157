import { SupplierInvoiceState } from '../supplierInvoiceState';
import { SetIsSupplierInvoiceContextualizationPayload } from '../actions';

export const onSetIsSupplierInvoiceContextualization = (
	state: SupplierInvoiceState,
	payload: SetIsSupplierInvoiceContextualizationPayload
) => ({
	...state,
	context: {
		...state.context,
		isSupplierInvoiceContextualization: payload.isSupplierInvoiceContextualizing
	}
});
