import * as React from 'react';
import styles from './Ellipsis.module.scss';

const Ellipsis: React.FC = ({ children, ...props }) => {
	return (
		<span className={styles.root} {...props}>
			{children}
		</span>
	);
};

export default Ellipsis;
