import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import {
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { BankAccountsState } from './../bankAccountsState';

function onRetrieveBankAccountSuccess(
	state: BankAccountsState,
	action: Success<RetrieveBankAccountRequest, RetrieveBankAccountResponse>
): BankAccountsState {
	return {
		...state,
		byId: {
			...state.byId,
			[action.result.id]: action.result
		}
	};
}

export const onRetrieveBankAccount = makeFetchStatusReducers<
	BankAccountsState,
	'fetchStatuses',
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse
>('fetchStatuses', 'retrieveBankAccount', {
	doneReducer: onRetrieveBankAccountSuccess
});
