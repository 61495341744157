import { put, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { retrieveActiveCompanyVesselTypes } from '../actions';
import { getActiveCompanyId } from '../selectors';
import { retrieveCompanyVesselTypes } from 'store/companyVesselTypes/actions';

function* worker(): SagaIterator {
	const companyId: string = yield select(getActiveCompanyId);
	yield put(retrieveCompanyVesselTypes({ companyId }));
}

export default function*() {
	yield takeLatest(retrieveActiveCompanyVesselTypes, worker);
}
