import { PageFormProps } from './Form';
import {
	Page1FormValues,
	Page2FormValues
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';
import { checkPortJobPermissions } from 'store/portJobs/actions';
import { JobType } from 'store/portJobs/constants';

const onChange = (
	values: Page1FormValues & Page2FormValues,
	// eslint-disable-next-line:ban-types
	dispatch: Function,
	props: PageFormProps
) => {
	if (props.isCurrentUserWithRolePrincipal) {
		const { portCall, appointerCompany, nominator, jobTypeId } = values;
		if (
			!portCall ||
			!portCall.vessel ||
			!portCall.port ||
			(!appointerCompany && !nominator && !props.isCurrentUsersGroupTypeISSHub)
		) {
			return;
		}
		dispatch(
			checkPortJobPermissions({
				mainPrincipalId:
					(jobTypeId?.key === JobType.APPOINTMENT
						? appointerCompany?.key
						: nominator?.key) || '',
				vesselId: props.vessel ? props.vessel.vessel.id : portCall.vessel.key,
				portId: portCall.port.key
			})
		);
	}
};

export default debounce(onChange, DEFAULT_DEBOUNCE_DELAY);
