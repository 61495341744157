import { FetchStatus } from 'services/api/apiTypes';
import { SavingsState } from '../savingsState';

export function onAddTimeSavingsStated(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addTimeSavings: FetchStatus.PENDING
		}
	};
}

export function onAddTimeSavingsSuccess(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addTimeSavings: FetchStatus.SUCCESS
		}
	};
}

export function onAddTimeSavingsFailure(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addTimeSavings: FetchStatus.FAILURE
		}
	};
}
