import { PortCallJobGroupTypeEnum } from 'services/api/portCalls/portCallsServiceTypes';
import {
	PortCallsFiltersParamEnum,
	PortCountryGroupType
} from 'store/portcalls/filtersSync';

export const searchGroupTypeMap = {
	[PortCallJobGroupTypeEnum.CODE]: 'Port Job Code',
	[PortCallJobGroupTypeEnum.CUSTOMER_REFERENCE]: 'Port Job Customer Reference',
	[PortCallJobGroupTypeEnum.PRINCIPAL]: 'Port Job Principal',
	[PortCallJobGroupTypeEnum.MAIN_CONTACT]: 'Port Job Main Contact',
	[PortCallJobGroupTypeEnum.PERFORMING_AGENT]: 'Port Job Performing Agent',
	[PortCallJobGroupTypeEnum.OPERATION_TYPE]: 'Port Job Operation Type'
};

export const portCountryTypeMap = {
	[PortCountryGroupType.COUNTRIES]: 'Countries',
	[PortCountryGroupType.PORTS]: 'Ports'
};

export enum AgentType {
	FULL = 'Full',
	INBOUND = 'Inbound',
	OUTBOUND = 'Outbound',
	HUSBANDRY = 'Husbandry'
}

export const advanceFilterAgentTypeMap = {
	[AgentType.FULL]: 'FullAgent',
	[AgentType.INBOUND]: 'InboundAgent',
	[AgentType.OUTBOUND]: 'OutboundAgent',
	[AgentType.HUSBANDRY]: 'HusbandryAgent'
};

export enum PortJobType {
	APPOINTMENT = 'Appointment',
	NOMINATION = 'Nomination',
	CHARTERER_OVERSIGHT = 'Charterers Oversight',
	APPOINTMENT_FROM_NOMINATION = 'Appointment from Nomination'
}

export const advanceFilterJobTypeMap = {
	[PortJobType.APPOINTMENT]: 'Appointment',
	[PortJobType.NOMINATION]: 'Nomination',
	[PortJobType.CHARTERER_OVERSIGHT]: 'ChartererOversight',
	[PortJobType.APPOINTMENT_FROM_NOMINATION]: 'AppointmentFromNomination'
};

export const DateType = [
	{
		id: 'eta',
		label: 'ETA'
	},
	{
		id: 'ets',
		label: 'ETS'
	},
	{
		id: 'eosp',
		label: 'EOSP'
	},
	{
		id: 'cosp',
		label: 'COSP'
	}
];

export const DateMap = {
	eta: 'ETA',
	ets: 'ETS',
	eosp: 'EOSP',
	cosp: 'COSP'
};

export enum PortJobCategory {
	CLS = 'CLS',
	COMMERCIAL = 'Commercial'
}

export const advanceFilterJobCategoryTypeMap = {
	[PortJobCategory.CLS]: 'C',
	[PortJobCategory.COMMERCIAL]: 'H'
};

export const advanceFilterTypeMap = {
	[PortCallsFiltersParamEnum.ASSIGNED_TO]: 'Assigned To',
	[PortCallsFiltersParamEnum.AGENT_TYPE]: 'Agent Type',
	[PortCallsFiltersParamEnum.AGENT_ROLE]: 'Agent Role',
	[PortCallsFiltersParamEnum.FINANCE_DISABLED]: 'Finance Disabled',
	[PortCallsFiltersParamEnum.OPERATION_DISABLED]: 'Operations Disabled',
	[PortCallsFiltersParamEnum.PORT_JOB_CATEGORY]: 'Job Category',
	[PortCallsFiltersParamEnum.PORT_JOB_TYPE]: 'Job Type',
	[PortCallsFiltersParamEnum.MAIN_CONTACT]: 'Main Contact',
	[PortCallsFiltersParamEnum.CHARTERER]: 'Charterer',
	[PortCallsFiltersParamEnum.OPERATION]: 'Operation',
	[PortCallsFiltersParamEnum.DATE]: 'Date'
};

export const filterTypeMap = {
	[PortCallsFiltersParamEnum.MAIN_PRINCIPAL]: 'Main Principal',
	[PortCallsFiltersParamEnum.PERFORMING_AGENT]: 'Performing Agent',
	[PortCallsFiltersParamEnum.ASSIGNED_TO_ID]: 'Assigned To',
	[PortCallsFiltersParamEnum.PORT]: 'Port',
	[PortCallsFiltersParamEnum.COUNTRY]: 'Country',
	[PortCallsFiltersParamEnum.VESSEL]: 'Vessel',
	[PortCallsFiltersParamEnum.JOB_TYPE]: 'Job Type',
	[PortCallsFiltersParamEnum.JOB_STATUS]: 'Job Status',
	[PortCallsFiltersParamEnum.PORT_CALL_STATUS]: 'Port Call Status',
	[PortCallsFiltersParamEnum.ALERT]: 'Alert',
	[PortCallsFiltersParamEnum.SHOW_CANCELLED]: 'Include cancelled',
	...searchGroupTypeMap,
	...advanceFilterTypeMap
};
