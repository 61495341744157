import { SavingsState } from '../savingsState';
import { FetchStatus } from 'services/api/apiTypes';

export function onDeleteSavingsStarted(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.PENDING
		}
	};
}
export function onDeleteSavingsSuccess(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.SUCCESS
		}
	};
}
export function onDeleteSavingsFailed(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			context: FetchStatus.FAILURE
		}
	};
}
