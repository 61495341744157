import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	CompanyVesselTypeState,
	CompanyVesselType
} from '../companyVesselTypesState';
import {
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse,
	RetrieveCompanyVesselType
} from 'services/api/companies/companiesServiceTypes';
import { keyBy, flow } from 'lodash';
export const mapCompanyVesselTypesById = (
	companyId: string,
	vesselTypes: RetrieveCompanyVesselType[]
) =>
	flow(
		(vTypes: RetrieveCompanyVesselType[]) =>
			vTypes.map(vType => ({ ...vType, companyId } as CompanyVesselType)),
		vTypes => keyBy(vTypes, ({ id }) => id)
	)(vesselTypes);

export const onRetrieveCompanyVesselTypes = makeFetchStatusReducers<
	CompanyVesselTypeState,
	'fetchStatuses',
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse
>('fetchStatuses', 'retrieve', {
	doneReducer: (state, { params: { companyId }, result: { elements } }) => ({
		...state,
		byId: {
			...state.byId,
			...mapCompanyVesselTypesById(companyId, elements)
		}
	})
});
