import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	PortJobBaseRequest,
	ExtendedMessagingData
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveExtendedMessagingDataSuccess = (
	state: PortJobState,
	{ result }: Success<PortJobBaseRequest, ExtendedMessagingData>
) => ({
	...state,
	extendedMessagingData: result
});

export const onRetrieveExtendedMessagingData = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	PortJobBaseRequest,
	ExtendedMessagingData
>('fetchStatuses', 'extendedMessagingData', {
	doneReducer: onRetrieveExtendedMessagingDataSuccess
});
