import actionCreator from '../documentsActionCreator';
import {
	RetrieveDocumentConfigParams,
	RetrieveRelativeDocumentConfigResponse
} from 'services/api/documents/documentsServiceTypes';

/**
 * Get document configuration (such as `id` and `form`)
 */
const FETCH_RELATIVE_CONFIGURATION = 'FETCH_RELATIVE_CONFIGURATION';

export const retrieveRelativeDocumentConfigAsync = actionCreator.async<
	RetrieveDocumentConfigParams,
	RetrieveRelativeDocumentConfigResponse,
	Error
>(FETCH_RELATIVE_CONFIGURATION);

export const retrieveRelativeDocumentConfig = actionCreator<
	RetrieveDocumentConfigParams | undefined
>(FETCH_RELATIVE_CONFIGURATION);
