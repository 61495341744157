import actionCreatorFactory from 'typescript-fsa';
import { RetrieveBenchmarkUrlResponse } from 'services/api/benchmark/benchmarkServiceTypes';

const PREFIX = 'navigation';
const actionCreator = actionCreatorFactory(PREFIX);

const ACTION_NAME = 'RETRIEVE_BENCHMARK_URL';
export const retrieveBenchmarkUrlAsync = actionCreator.async<
	null,
	RetrieveBenchmarkUrlResponse,
	Error
>(ACTION_NAME);

export const retrieveBenchmarkUrl = actionCreator(ACTION_NAME);
