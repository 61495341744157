import {
	Permission,
	ParentPermission,
	PermissionCode,
	viewFinanceGroups,
	approvePDACESet,
	approveDaAllSet,
	approveAssignedDaSet,
	approveAssignedPDACESet
} from 'services/api/permissions/permissionsServiceTypes';
import { GroupApplicableScopeTypes } from 'services/api/groups/groupsServiceTypes';
import { AntTreeNode } from 'antd/lib/tree';
import { flattenDeep, difference, uniq } from 'lodash';

export const MAX_PERMISSION_SCOPE_TYPES_AMOUNT = 5;

export const formInitialValues = (setPermissions: Permission[]) =>
	setPermissions.reduce(
		(formValue, permission) => ({ ...formValue, [permission.code]: true }),
		{}
	);

export const checkCount = (
	rootPermission: ParentPermission,
	formValues: object,
	hasParent: boolean
): number =>
	rootPermission.children.reduce(
		(count, permission) => {
			const currentCount = formValues[permission.code] ? count + 1 : count;
			return currentCount + checkCount(permission, formValues, true);
		},
		hasParent ? 0 : formValues[rootPermission.code] ? 1 : 0
	);

export const getChildrenSize = (rootPermission: ParentPermission): number => {
	return rootPermission.children
		? rootPermission.children.reduce(
				(count, item) => count + getChildrenSize(item),
				rootPermission.children.length
		  )
		: 0;
};

enum scopeLabel {
	COMPANY = 'Company',
	REGION = 'Region',
	VESSEL = 'Vessel'
}

export const getPermissionScopeMessage = (
	applicableScopeTypes: GroupApplicableScopeTypes[]
) => {
	if (applicableScopeTypes.length === 0) {
		return 'Permission scope is not applicable';
	}
	let scopeName = scopeLabel.COMPANY;
	if (applicableScopeTypes.length === 1) {
		const scope = applicableScopeTypes[0];
		if (scope === GroupApplicableScopeTypes.COUNTRIES) {
			scopeName = scopeLabel.REGION;
		}
		if (scope === GroupApplicableScopeTypes.VESSELS) {
			scopeName = scopeLabel.VESSEL;
		}
	}

	return `Permission scope (${scopeName}) is only applicable`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChildrenIds = (items: any[]): string[] => [
	...flattenDeep(
		items.map(node => {
			const children = node.children;
			let childrenIds = [node.code];
			if (children?.length) {
				childrenIds = [...childrenIds, ...getChildrenIds(children)];
			}
			return childrenIds;
		})
	)
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getParentIds = (items: ParentPermission[]): string[] => {
	return [
		...flattenDeep(
			items.map(node => {
				const parent = node.parentNode;
				let parentIds: string[] = [] as string[];
				if (parent) {
					parentIds = [parent.code, ...getParentIds([parent])];
				}
				return parentIds;
			})
		)
	] as string[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFirstFinanceLevelIds = (
	items: ParentPermission[]
): string[] => {
	return [
		...flattenDeep(
			items.map(node => {
				let firstLevelIds: string[] = [] as string[];

				if (viewFinanceGroups.includes(node.code)) {
					firstLevelIds = [node.children[0].code];
				}

				return firstLevelIds;
			})
		)
	] as string[];
};

interface PermissionNodeProps {
	eventKey: string;
	dataRef: ParentPermission;
}

export const getCheckedKeys = (
	node: AntTreeNode,
	checked: string[]
): string[] => {
	let keysToUncheck: string[] = [];
	let keysToCheck = checked;
	const { eventKey, dataRef } = node.props as PermissionNodeProps;

	// if parent node is unchecked – child nodes will be also unchecked
	if (dataRef.children && dataRef.children.length) {
		if (!checked.includes(eventKey)) {
			keysToUncheck = getChildrenIds([dataRef]);
		}
	}

	if (
		!checked.includes(dataRef.code) &&
		checked.includes(PermissionCode.APPROVE_PDA_CE)
	) {
		if (!approvePDACESet.some(permission => checked.includes(permission))) {
			keysToUncheck = [...keysToUncheck, PermissionCode.APPROVE_PDA_CE];
		}
	}

	if (
		!checked.includes(dataRef.code) &&
		checked.includes(PermissionCode.APPROVE_ASSIGNED_PDA_CE)
	) {
		if (
			!approveAssignedPDACESet.some(permission => checked.includes(permission))
		) {
			keysToUncheck = [
				...keysToUncheck,
				PermissionCode.APPROVE_ASSIGNED_PDA_CE
			];
		}
	}

	if (
		!checked.includes(dataRef.code) &&
		checked.includes(PermissionCode.APPROVE_ASSIGNED_DA)
	) {
		if (
			!approveAssignedDaSet.some(permission => checked.includes(permission))
		) {
			keysToUncheck = [...keysToUncheck, PermissionCode.APPROVE_ASSIGNED_DA];
		}
	}

	if (
		!checked.includes(dataRef.code) &&
		checked.includes(PermissionCode.APPROVE_DA_ALL)
	) {
		if (!approveDaAllSet.some(permission => checked.includes(permission))) {
			keysToUncheck = [...keysToUncheck, PermissionCode.APPROVE_DA_ALL];
		}
	}

	// if child node is checked – parent node will be also checked
	if (checked.includes(dataRef.code)) {
		keysToCheck = [
			...checked,
			...getParentIds([dataRef]),
			...getFirstFinanceLevelIds([dataRef]) //if any finance group selected first level will be checked
		];
	}

	const checkedKeys = difference(keysToCheck, keysToUncheck);
	return uniq(checkedKeys);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTooltipTitle = (item: any, tooltipProp?: string): string => {
	return tooltipProp && item[tooltipProp]
		? item[tooltipProp]
		: `Permission can't be edited for this group type`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPermissionInfo = (item: any): string | undefined => {
	switch (item.code) {
		case PermissionCode.VIEW_MESSAGES:
			return 'Groups rolled out to Sedna are only able to send messages';
	}
	return;
};
