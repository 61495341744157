import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { VesselProgrammeState } from '../vesselProgrammeState';
import { UpdateVPFromFinanceRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

export const onUpdateVPFromFinance = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	UpdateVPFromFinanceRequest,
	void
>('fetchStatuses', 'updateVPFromFinance');
