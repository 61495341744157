import {
	UpdateDAOutActionPayload,
	UpdateDAResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'UPDATE_DA';

export const updateDAAsync = actionCreator.async<
	UpdateDAOutActionPayload,
	UpdateDAResponse
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateDA = actionCreator<UpdateDAOutActionPayload>(ACTION_NAME, {
	templateParams: {
		entityName: 'DA'
	}
});
