import actionCreator from 'store/finance/financeActionCreator';
import { RetrieveFundingRequestsPageDataRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';

const ACTION_NAME = 'RETRIEVE_FUNDING_REQUESTS_PAGE_DATA';

export const retrieveFundingPageDataAsync = actionCreator.async<
	Request,
	void,
	Error
>(ACTION_NAME);

export const retrieveFundingPageData = actionCreator<Request>(ACTION_NAME);
