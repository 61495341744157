import React, { useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import styles from './Notifications.module.scss';

import { isArray } from 'lodash';
import { Button, Notification, Icon } from 'components/antd';
import ErrorCorrelationId from 'components/ErrorPage/ErrorCorrelationId';

import { ArgsProps } from 'antd/lib/notification';

import {
	NotificationItem,
	NotificationDisplayType,
	NotificationType
} from 'store/notifications/notificationsState';
import { useNotifications } from '../Notifications.hooks';
import { IconType } from 'components/antd/Icon/IconTypes';
import { IconColor } from 'components/types';
interface NotificationConfirmationButtonProps {
	uuid: string;
	title: string;
	onConfirmNotification: (uuid: string) => void;
}

const NotificationTypeToIconMap: {
	[key in NotificationType]: [keyof IconType, IconColor];
} = {
	success: ['check', 'success'],
	info: ['info', 'info'],
	error: ['warning', 'error'],
	warning: ['warning', 'warning']
};

function NotificationConfirmationButton({
	uuid,
	title,
	onConfirmNotification
}: NotificationConfirmationButtonProps) {
	const onConfirm = useCallback(() => {
		Notification.close(uuid);
		onConfirmNotification(uuid);
	}, [uuid, onConfirmNotification]);
	return (
		<Button size="small" onClick={onConfirm}>
			{title}
		</Button>
	);
}

function NotificationDescription(props: { description: string[] }) {
	return (
		<ul className={styles.descriptionList}>
			{props.description.map((item, index) => (
				<li key={index}>{item}</li>
			))}
		</ul>
	);
}

function Notifications() {
	const uuids = useRef<string[]>([]);

	const {
		list,
		onConfirmNotification,
		onRemoveNotification
	} = useNotifications({
		displayType: NotificationDisplayType.NOTIFICATION
	});

	useEffect(() => {
		return () => {
			/**
			 * The code below needs to be investigated.
			 * It's possible that list here is always empty.
			 * If that's true we don't need list.forEach (lines 56-60)
			 * and if (!list.length) check (line 61).
			 */
			list.forEach(item => {
				if (!item.duration) {
					Notification.close(item.uuid);
				}
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getNotificationDescription = useCallback(
		(notification: NotificationItem) => {
			if (!notification.error) {
				return notification.description;
			}
			return (
				<>
					{isArray(notification.description) ? (
						<NotificationDescription description={notification.description} />
					) : (
						notification.description
					)}
					<ErrorCorrelationId
						correlationId={notification.error.correlationId}
					/>
				</>
			);
		},
		[]
	);

	const open = useCallback(
		(notification: NotificationItem) => {
			const [iconType, iconColor] = NotificationTypeToIconMap[
				notification.type
			];
			let config: ArgsProps = {
				key: notification.uuid,
				message: notification.title,
				description: getNotificationDescription(notification),
				duration: notification.duration,
				className: classNames(styles.root, {
					[styles.noMessage]: !notification.title
				}),
				onClose: () => onRemoveNotification(notification.uuid),
				icon: <Icon type={iconType} color={iconColor} size="xl" />
			};
			const action = Notification[notification.type];
			if (notification.confirmation) {
				const { title } = notification.confirmation;
				config = {
					...config,
					key: notification.uuid,
					btn: (
						<NotificationConfirmationButton
							uuid={notification.uuid}
							title={title}
							onConfirmNotification={onConfirmNotification}
						/>
					)
				};
			}

			action(config);
			if (config.duration) {
				onRemoveNotification(notification.uuid);
			}
		},
		[getNotificationDescription, onConfirmNotification, onRemoveNotification]
	);

	useEffect(() => {
		const uuidsCurrent = uuids.current;
		if (list.length) {
			list.forEach((item: NotificationItem) => {
				if (!uuidsCurrent.includes(item.uuid)) {
					uuidsCurrent.push(item.uuid);
					open(item);
				}
			});
		}
		if (!list.length && uuidsCurrent.length) {
			uuidsCurrent.splice(0);
		}
	}, [list, uuids, open]);

	return null;
}

export default Notifications;
