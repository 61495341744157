import React from 'react';
import { Text } from 'components';
import { Col } from 'components/antd';
import { FormattedTime } from 'containers';
import { VesselAttributes } from 'services/api/vessels/vesselsServiceTypes';
import { get } from 'lodash';
import styles from './VesselDetailsSections.module.scss';
import { DEFAULT_NO_VALUE_PLACEHOLDER } from 'app-constants';

interface Attribute {
	name: string;
	path: string;
}

interface VesselDetailsColumnProps {
	colSize: number;
	attributes: Attribute[];
	rowData: VesselAttributes;
	rowIndex: number;
}

interface DescriptionProps {
	rowData: VesselAttributes;
	attribute: Attribute;
	rowIndex: number;
}

const Description = ({ rowData, attribute, rowIndex }: DescriptionProps) => {
	const value: string | undefined = get(
		rowData,
		attribute.path.replace(/\[i\]/g, `[${rowIndex}]`)
	);

	return (
		<>
			{value && attribute.name === 'Start Date' ? (
				<FormattedTime value={value} format="MM/DD/YYYY" />
			) : (
				value || DEFAULT_NO_VALUE_PLACEHOLDER
			)}
		</>
	);
};

const VesselDetailsColumn = ({
	colSize,
	attributes,
	rowData,
	rowIndex
}: VesselDetailsColumnProps) => (
	<Col xs={colSize}>
		<table className={styles.sectionColumn}>
			<tbody>
				{attributes.map((attribute, index) => (
					<tr key={`${attribute.path}-${index}`}>
						<td>
							<Text upper>{attribute.name}:</Text>
						</td>
						<td>
							<Description
								rowData={rowData}
								rowIndex={rowIndex}
								attribute={attribute}
							/>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	</Col>
);

export default VesselDetailsColumn;
