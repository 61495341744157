import actionCreator from '../portJobsActionCreator';
import {
	ConfirmPortJobPayload,
	ConfirmPortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'ACCEPT_JOB';

export const acceptPortJobAsync = actionCreator.async<
	ConfirmPortJobPayload,
	ConfirmPortJobResponse,
	Error
>(ACTION_NAME, {
	failed: {
		// watched by react-to-changes
		omit: [412]
	}
});

export const acceptPortJob = actionCreator<ConfirmPortJobPayload>(ACTION_NAME);
