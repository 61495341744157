import actionCreator from '../portCallDocumentsActionCreator';

const CYCLE_START_ACTION = 'RETRIEVE_PORTCALL_DOCUMENTS_CYCLE_START';

const CYCLE_STOP_ACTION = 'RETRIEVE_PORTCALL_DOCUMENTS_CYCLE_STOP';

export const retrievePortCallDocumentsCycleStart = actionCreator<string>(
	CYCLE_START_ACTION
);

export const retrievePortCallDocumentsCycleStop = actionCreator(
	CYCLE_STOP_ACTION
);
