import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from 'services/history';

import { Loading } from 'components';
import RegistrationFinish from 'sections/Registration/Finish/RegistrationFinish';
import PreRegistrationRoute from 'sections/PreRegistration/PreRegistrationRoute';
import PreRegistrationVerifyRoute from 'sections/PreRegistration/PreRegistrationVerifyRoute';
import Login from 'sections/Login/Login';
import Logout from 'sections/logout/Logout';
import LoginCallback from 'sections/Login/LoginCallback';
import SilentRenew from 'sections/Login/SilentRenew';
import PrivateRoute from './PrivateRoute';
import RestrictedApp from './RestrictedApp';

const RegistrationRoute = lazy(() => import('sections/Registration/Route'));

const TermsAndConditionRoute = lazy(() =>
	import('sections/TermsAndConditions/Route')
);

const App: React.SFC<{}> = () => (
	<Suspense fallback={<Loading />}>
		<Router history={history}>
			<Switch>
				<Route
					exact
					path="/terms-and-conditions"
					component={TermsAndConditionRoute}
				/>
				<Route
					exact
					path="/pre-registration"
					component={PreRegistrationRoute}
				/>
				<Route
					exact
					path="/pre-registration/verify"
					component={PreRegistrationVerifyRoute}
				/>
				<Route exact path="/registration" component={RegistrationRoute} />
				<Route
					exact
					path="/registration/finish"
					component={RegistrationFinish}
				/>
				<Route exact path="/login" component={Login} />
				<Route exact path="/login-callback" component={LoginCallback} />
				<Route exact path="/login-silent-renew" component={SilentRenew} />
				<Route exact path="/logout" component={Logout} />
				<PrivateRoute component={RestrictedApp} redirect="/login" />
			</Switch>
		</Router>
	</Suspense>
);

export default App;
