import React from 'react';
import classNames from 'classnames';
import layoutStyles from '../AppLayout.module.scss';
import styles from './AppLogoLayout.module.scss';
import { Flex } from 'components';
import Logo from 'components/Logo/Logo';

const AppLogoLayout: React.SFC = ({ children }) => (
	<div className={classNames(layoutStyles.root, styles.root)}>
		<Flex fit align="center" direction="vertical">
			<Logo stretch />
			{children}
		</Flex>
	</div>
);

export default AppLogoLayout;
