import actionCreator from '../configurationFilesActionCreator';
import {
	AddCompanyTemplateFileRequest,
	AddCompanyTemplateFileResponse
} from 'services/api/templates/templatesServiceTypes';
const ACTION_NAME = 'ADD_CONFIGURATION_FILE';

export const addConfigurationFileAsync = actionCreator.async<
	AddCompanyTemplateFileRequest,
	AddCompanyTemplateFileResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'File'
	}
});

export const addConfigurationFile = actionCreator<
	AddCompanyTemplateFileRequest
>(ACTION_NAME);
