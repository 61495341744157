import * as React from 'react';

import { connect } from 'react-redux';
import {
	uploadAndProcessDocument,
	retrieveStaticDocumentConfig,
	cancelUploadAndProcessDocument
} from 'store/documents/actions';
import {
	getDocumentAllowedExtensions,
	getIsDocumentProcessingLoading
} from 'store/documents/selectors';
import {
	UploadType,
	UploadCustomRequest,
	UploadDocumentRequestDoneAction,
	DocumentsMetadata
} from 'services/api/documents/documentsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { AppState } from 'store-types';

export interface UploadProcessDocumentProviderData {
	customRequest: (
		request: UploadCustomRequest,
		config?: UploadAndProcessConfigReq
	) => void;
	accept: string;
	activePortCallId: string;
	isUploading: boolean;
}

export interface UploadAndProcessConfigReq {
	metadata?: DocumentsMetadata;
	onDone?: UploadDocumentRequestDoneAction;
}

export interface UploadProcessDocumentProviderProps
	extends UploadAndProcessConfigReq {
	uploadType?: UploadType;
	accept: string;
	showProgress: boolean;

	// from mapStateToProps
	isUploading: boolean;
	activePortCallId: string;
	upload: typeof uploadAndProcessDocument;
	retrieveStaticDocumentConfig: typeof retrieveStaticDocumentConfig;
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
	children: (data: UploadProcessDocumentProviderData) => React.ReactNode;
}

export const defaultType = UploadType.DOCUMENT;

class UploadProcessDocumentProvider extends React.Component<
	UploadProcessDocumentProviderProps
> {
	componentDidMount() {
		// Retrieve config to use `allowedExtensions`
		this.props.retrieveStaticDocumentConfig({ type: this.getUploadType() });
	}

	componentWillUnmount() {
		if (this.props.isUploading) {
			this.props.cancelUploadAndProcessDocument({ shouldResetFile: true });
		}
	}

	getUploadType = () => this.props.uploadType || defaultType;

	customRequest = (
		request: UploadCustomRequest,
		config?: UploadAndProcessConfigReq
	) => {
		const {
			upload,
			activePortCallId,
			metadata,
			onDone,
			showProgress
		} = this.props;
		const uploadConfig = {
			metadata:
				(config?.metadata ?? metadata) ||
				(activePortCallId && { portCallId: activePortCallId }) ||
				undefined,
			onDone
		};
		upload({
			type: this.getUploadType(),
			request: { file: request.file },
			showProgress,
			...uploadConfig
		});
		return {
			abort() {} // eslint-disable-line @typescript-eslint/no-empty-function
		};
	};

	getLocalData = (): UploadProcessDocumentProviderData => {
		return {
			customRequest: this.customRequest,
			accept: this.props.accept,
			activePortCallId: this.props.activePortCallId,
			isUploading: this.props.isUploading
		};
	};

	render() {
		return <>{this.props.children(this.getLocalData())}</>;
	}
}

export default connect(
	(
		state: AppState,
		{ uploadType }: Pick<UploadProcessDocumentProviderProps, 'uploadType'>
	) => ({
		accept: getDocumentAllowedExtensions(state, uploadType || defaultType),
		activePortCallId: getActivePortCallId(state), // TODO? consider removing, use locally where needed due to being section related
		isUploading: getIsDocumentProcessingLoading(state)
	}),
	{
		upload: uploadAndProcessDocument,
		retrieveStaticDocumentConfig,
		cancelUploadAndProcessDocument
	}
)(UploadProcessDocumentProvider);
