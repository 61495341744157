import React from 'react';
import { connect } from 'react-redux';
import { Gap } from 'components';
import { Button } from 'components/antd';
import TotalAmountGrid from './TotalAmountGrid';
import TotalAmountModal from './TotalAmountModal';
import {
	FORM,
	TOTAL_AMOUNT_MODAL,
	TOTAL_AMOUNT_FIELD_NAME
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { openModal } from 'store/modals/actions';
import { setEditableTotalAmountId } from 'store/portCall/actions';
import {
	formValueSelector,
	InjectedFormProps,
	registerField,
	change,
	reduxForm
} from 'redux-form';
import { getEditableTotalAmountId } from 'store/portJobs/selectors';
import {
	TotalAmountFormData,
	FormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { FieldType } from 'store/form/formTypes';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { findIndex } from 'lodash';
import uuidv4 from 'uuid/v4';
import { AppState } from 'store-types';

interface TotalAmountLinesBaseProps {
	// from mapStateToProps
	totalAmounts: TotalAmountFormData[];
	editableTotalAmountId: string | undefined;
	// from mapDispatchToProps
	openModal: typeof openModal;
	registerFormField: typeof registerField;
	changeValue: typeof change;
	setEditableTotalAmountId: typeof setEditableTotalAmountId;
}

interface TotalAmountLinesProps
	extends InjectedFormProps<FormData, TotalAmountLinesBaseProps>,
		TotalAmountLinesBaseProps {}

class TotalAmountLines extends React.PureComponent<TotalAmountLinesProps> {
	componentDidMount() {
		this.props.registerFormField(
			FORM.portJob,
			TOTAL_AMOUNT_FIELD_NAME,
			FieldType.FIELD_ARRAY
		);
	}

	onAddTotalAmountLineModal = () => this.props.openModal(TOTAL_AMOUNT_MODAL);

	onSubmit = (formValues: TotalAmountFormData) => {
		const { totalAmounts, editableTotalAmountId, array } = this.props;
		if (editableTotalAmountId) {
			const index = findIndex(totalAmounts, { id: editableTotalAmountId });
			array.splice(TOTAL_AMOUNT_FIELD_NAME, index, 1, formValues);
			return;
		}
		const data = {
			...formValues,
			id: formValues.id ? formValues.id : uuidv4() + '[generated]'
		};
		array.push(TOTAL_AMOUNT_FIELD_NAME, data);
	};

	onEdit = (totalAmountLineId: string) => {
		this.props.setEditableTotalAmountId(totalAmountLineId);
		this.props.openModal(TOTAL_AMOUNT_MODAL);
	};

	onDelete = (totalAmountLineId: string) => {
		const { totalAmounts, changeValue } = this.props;
		const lines = totalAmounts.filter(
			totalAmountLine => totalAmountLine.id !== totalAmountLineId
		);
		changeValue(FORM.portJob, TOTAL_AMOUNT_FIELD_NAME, lines);
	};

	render() {
		const { totalAmounts } = this.props;
		return (
			<Gap bottom isBlock>
				<TotalAmountGrid
					dataSource={totalAmounts}
					onEdit={this.onEdit}
					onDelete={this.onDelete}
				/>
				<Button
					type="primary"
					icon="plus"
					transparent
					onClick={this.onAddTotalAmountLineModal}
				>
					Add a new line
				</Button>
				<TotalAmountModal onSubmit={this.onSubmit} />
			</Gap>
		);
	}
}

const formSelector = formValueSelector(FORM.portJob);

export default connect(
	(state: AppState) => ({
		totalAmounts: formSelector(state, TOTAL_AMOUNT_FIELD_NAME),
		editableTotalAmountId: getEditableTotalAmountId(state)
	}),
	{
		openModal,
		registerFormField: registerField,
		changeValue: change,
		setEditableTotalAmountId
	}
)(
	reduxForm<FormData, TotalAmountLinesBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true
	})(TotalAmountLines)
);
