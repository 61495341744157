import {
	AllowedDocument,
	AllowedDocumentPlaceholder,
	DocumentLevel
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

/**
 * TODO move to store/form
 * @type {string}
 */

export const CONTEXTUALIZATION_FORM_ID = 'contextualization';

export enum FormField {
	IGNORE_PAGE = 'ignorePage',
	DOCUMENT_TYPE = 'documentTypeCode',
	PLACEHOLDERS = 'placeholders',
	JOBS = 'jobs',
	PAGES = 'pages'
}

export interface FormPageData {
	ignorePage?: boolean;
	documentTypeCode?: string;
	placeholders?: { [id: string]: boolean };
	jobs?: string[];
}

export interface FormData {
	pages: FormPageData[];
}

export interface ContextualizationFormFieldsBaseProps {
	documents: AllowedDocument[];
	placeholderOptions: AllowedDocumentPlaceholder[];
	assignedToOptions: { [level: string]: AllowedDocumentPlaceholder[] };
	assignedJobsInitialValues: string[];
}

export const DOCUMENT_LEVEL_TITLE = {
	[DocumentLevel.OPERATION_UNIT]: 'Cargo',
	[DocumentLevel.OPERATION]: 'Operation',
	[DocumentLevel.PORT_JOB]: 'Job',
	[DocumentLevel.ROTATION_STEP]: 'POI'
};
