import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	openModal,
	closeModal,
	ModalParams,
	OpenModalAction,
	CloseModalAction,
	closeAllModals
} from './actions';

export interface ModalsState {
	activeModals: ModalParams[];
}

export const initialState: ModalsState = {
	activeModals: []
};

export const onOpenModal = (
	state: ModalsState,
	action: OpenModalAction
): ModalsState => {
	const modalData = typeof action === 'string' ? { name: action } : action;
	return {
		activeModals: [...state.activeModals, modalData]
	};
};

export const onCloseModal = (state: ModalsState, action: CloseModalAction) => {
	const modalId = typeof action === 'string' ? action : action.name;
	return {
		activeModals: state.activeModals.filter(modal => modal.name !== modalId)
	};
};

export const onCloseAllModals = () => {
	return initialState;
};

export default reducerWithInitialState(initialState)
	.case(openModal, onOpenModal)
	.case(closeModal, onCloseModal)
	.case(closeAllModals, onCloseAllModals);
