import { ResultCollection } from 'services/api/apiTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { EntityCode, Entity } from 'app-types';
import { PagedRequest, SearchResultCollection } from '../apiTypes';
import { Permission } from '../permissions/permissionsServiceTypes';
import { UserStatus } from '../users/userServiceTypes';

export enum GroupTypeCodes {
	ISS_HUB = 'ISSHub',
	ISS_LPA = 'ISSLpa',
	ISS_MANAGE = 'ISSManage',
	ISS_ADMIN = 'ISSAdmin',
	ISS_CLUSTER = 'ISSCluster',
	CUSTOMER = 'Customer',
	THIRD_PARTY_LPA = 'ThirdPartyLPA'
}

export interface GroupType extends EntityCode<GroupTypeCodes> {}

export enum GroupApplicableScopeTypes {
	MAIN_PRINCIPALS = 'MainPrincipals',
	PRINCIPALS = 'Principals',
	LPAS = 'LPAs',
	VESSELS = 'Vessels',
	COUNTRIES = 'Countries'
}

export enum GroupPermissionScopeAccessTypes {
	SPECIFIC = 'Specific',
	ALL = 'All'
}

export enum GroupPermissionScopeTypes {
	COMPANY = 'Company',
	CONTINENT = 'Continent',
	COUNTRY = 'Country',
	OPERATING_ENTITY = 'OperatingEntity',
	VESSEL = 'Vessel',
	VESSEL_TYPE = 'VesselType',
	COUNTRY_TYPE = 'CountryType'
}

export interface GroupType {
	code: GroupTypeCodes;
	name: string;
}

export interface Group {
	companyName: string;
	id: string;
	name: string;
	companyId: string;
	company: UserCompanyGetResponse;
	createdOn: string;
	functionalPermissions: Permission[];
	updatedOn: string;
	groupSignature: string | null;
	userSignature: string | null;
	isMessagingGroup: boolean;
	companiesForMessaging?: Entity[];
	isHubGroup: boolean;
	isDeleted: boolean;
	companyIds: string[];
	membersCount: number;
	groupType: GroupType;
	applicableScopeTypes: GroupApplicableScopeTypes[];
	dataPermissions: DataPermission[];
	defaultForJobs: string[];
}

export interface DataPermission {
	scopeType: GroupApplicableScopeTypes;
	accessType: GroupPermissionScopeAccessTypes;
	permissions?: DataPermissionChild[];
}

export interface DataPermissionChild {
	entityType: GroupPermissionScopeTypes; // string;
	entityId: string;
	includesAllChildren?: boolean;
}

export interface GroupScopePermissions {
	entityId: string;
	entityType: GroupPermissionScopeTypes; // string;
	name: string;
	children: GroupScopePermissions[] | null;
}

export interface PermissionScopeItem {
	entityId: string;
	entityType: GroupPermissionScopeTypes;
}

export interface GroupUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	lastLoginDateTime: string;
	singleGroupUser: boolean;
	status: UserStatus;
}

export interface GroupUserSearch {
	id: string;
	name: string;
}

export interface AddGroupRequest {
	companyId: string;
	groupType: {
		code: string;
	};
	name: string;
}

export interface UpdateGroupParams {
	groupId: string;
	scopeType: string;
	companyIds?: string[]; // TODO: Do we need this param?
}
export interface UpdateGroupRequest {
	name: string;
	signature: string;
	isMessagingGroup: boolean;
	companyIds?: string[];
	functionalPermissions?: string[];
	dataPermissions?: DataPermission[];
}

export interface UpdateGroupScopePermissionsRequest {
	groupId: string;
	dataPermissions: DataPermission[];
}

export interface SearchGroupUsersRequest {
	searchTerm: string;
	groupType?: GroupSearchByType;
	showMore?: boolean;
}

export enum GroupSearchByType {
	GroupNames = 'GroupNames',
	GroupCompanyNames = 'GroupCompanyNames'
}

export interface GroupSearchResultItem {
	id?: string;
	name: string;
}

export type GroupSearchResultCollection = SearchResultCollection<
	GroupSearchByType,
	GroupSearchResultItem
>;

export interface AddOrDeleteGroupUserRequest {
	groupId: string;
	userId: string;
}

export interface AddOrDeleteGroupUserResponse extends GroupUser {}

export interface RetrieveGroupsWhichCurrentUserBelongsToRequest {
	companyId?: string;
}

export interface DeleteGroupRequest extends PagedRequest {
	id: string;
	name: string;
}

interface GroupSearchRequest {
	searchTerm?: string;
	companyId?: string;
}

/**
 * Retrieve Groups
 */
export interface RetrieveGroupsRequest
	extends PagedRequest,
		GroupSearchRequest {
	ids?: string[];
	companyId?: string;
	types?: GroupTypeCodes[];
	mainPrincipalCompanyId?: string;
	permission?: PermissionCode;
	companiesIds?: string;
	names?: string;
}

export interface RetrieveGroupsParams extends RetrieveGroupsRequest {
	overrideFilters?: boolean;
}

export interface RetrieveGroupsResponse extends ResultCollection<Group> {}

/**
 * Retrieve Group Users
 */
export interface RetrieveGroupUsersRequest extends PagedRequest {
	id: string;
	onlyInThisGroup?: boolean;
	onlyActive?: boolean;
	isRequestedByThirdPartyLpaUser?: boolean;
}

export interface RetrieveGroupUsersResponse
	extends ResultCollection<GroupUser> {}

export interface RetrieveGroupsRequest extends PagedRequest {
	companyId?: string;
	groupType?: string;
}

export interface RetrieveGroupTypesRequest extends PagedRequest {
	organisationType?: string;
}

export interface RetrieveGroupScopePermissionsRequest {
	groupId: string;
	scopeType: string;
}

// From Bitbucket
export interface UserCompanyGetResponse {
	companyId?: string;
	name?: string;
	companies?: string[];
}

export interface CheckGroupPermissionRequest {
	entityId: string; // portId, vesselId...
	entityType: GroupPermissionScopeTypes;
	permission: PermissionCode;
}
export interface CheckGroupPermissionResponse {
	hasPermission: boolean;
}
/**
 * { form: string } parameter to reference the redux-form id on the store
 * This id is needed since Autocomplete.ReduxFormItem is not using the store
 * to perform each API call `onSearch` and therefore there's no reference of the selected
 * value on the store, besides the one inside the redux-form piece of the store.
 */
export type CheckGroupPermissionRequestWithForm = CheckGroupPermissionRequest & {
	formId?: string;
	fieldId?: string;
};

export interface ScopeTabTypes {
	childType: GroupPermissionScopeTypes;
	rootType: GroupPermissionScopeTypes;
}

export interface RetrieveThirdPartyLpaGroupsRequest extends PagedRequest {
	searchTerm?: string;
}

export interface RetrieveThirdPartyLpaGroupsResponse
	extends ResultCollection<Group> {}

export interface InviteThirdPartyLpaUser {
	groupId: string;
	email: string;
	firstName: string;
	lastName: string;
}

export interface ThirdPartyLPAUserInvite {
	groupId: string;
	email: string;
	firstName: string;
	lastName: string;
}

export interface ThirdPartyLPAUserInviteResponse {
	isExistingOpticUser: boolean;
}
