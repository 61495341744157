import { PortsState } from '../portsState';
import {
	RetrieveCanalsRequest,
	RetrieveCanalsResponse
} from 'services/api/ports/portsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveCanals = makeFetchStatusReducers<
	PortsState,
	'fetchStatuses',
	RetrieveCanalsRequest,
	RetrieveCanalsResponse
>('fetchStatuses', 'canals', {
	doneReducer: (state, { params, result }) => ({
		...state,
		canalsByPort: {
			...state.canalsByPort,
			[params.port]: [
				...(state.canalsByPort[params.port] || []),
				...result.elements
			]
		}
	})
});
