import React from 'react';
import { Asteriks } from 'components';
import { Table, Modal } from 'components/antd';
import { GradeLineFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import getColumns from './GradeLinesGridColumns';

export interface GradeLinesGridProps {
	onDelete: (index: string) => void;
	onEdit: (index: string) => void;
	dataSource: GradeLineFormData[];
}

interface GradeLinesGridState {
	showWarningModalForDelete: boolean;
	gradeLineId: string;
}

class GradeLinesGrid extends React.PureComponent<
	GradeLinesGridProps,
	GradeLinesGridState
> {
	constructor(props: GradeLinesGridProps) {
		super(props);

		this.state = {
			showWarningModalForDelete: false,
			gradeLineId: ''
		};
	}

	onDelete = (gradeLineId: string) => {
		this.setState({
			gradeLineId,
			showWarningModalForDelete: true
		});
	};

	onEdit = (gradeLineId: string) => {
		this.props.onEdit(gradeLineId);
	};

	onDeleteConfirmed = () => {
		this.hideWarningModal();
		this.props.onDelete(this.state.gradeLineId);
	};

	hideWarningModal = () => {
		this.setState({
			showWarningModalForDelete: false
		});
	};

	render() {
		const { dataSource } = this.props;
		return (
			<div>
				<Table
					dataSource={dataSource}
					pagination={false}
					columns={getColumns({
						onEdit: this.onEdit,
						onDelete: this.onDelete
					})}
					showHeader
					locale={{
						emptyText: (
							<Asteriks position="right">
								Add Details for First Grade Line
							</Asteriks>
						)
					}}
				/>
				<Modal
					visible={this.state.showWarningModalForDelete}
					onCancel={this.hideWarningModal}
					onOk={this.onDeleteConfirmed}
					cancelText="Cancel"
					okText="Confirm"
				>
					<p>Are you sure you want to delete grade line?</p>
				</Modal>
			</div>
		);
	}
}

export default GradeLinesGrid;
