import React from 'react';

import { debounce, isEmpty } from 'lodash';
import { Select, Icon } from 'components/antd';
import { SelectProps } from 'components/antd/Select/Select';

import { UserContact } from 'services/api/users/userServiceTypes';
import { DEFAULT_DEBOUNCE_DELAY, KeyboardCode } from 'app-constants';
import styles from './RecipientSelect.module.scss';
import { onSearch, toLabelValue } from './RecipientSelect.func';
import { Value } from './RecipientSelectTypes';
import { isString, isArray } from 'util';

const OPTION_KEY_NEW = 'NEW';
const OPTION_KEY_PLACEHOLDER = 'KEY';

export type RecipientSelectItemBaseProps = Pick<SelectProps, 'placeholder'> & {
	value?: Value[];
	onChange?: (value: Value[]) => void;
};

export type RecipientSelectItemProps = RecipientSelectItemBaseProps & {
	currentUserId: string;
};

interface RecipientSelectItemState {
	options: UserContact[];
	isLoading: boolean;
	addOptionSuggestion: Value | null;
}

class RecipientSelectItem extends React.Component<
	RecipientSelectItemProps,
	RecipientSelectItemState
> {
	static defaultProps: Partial<RecipientSelectItemProps> = {
		value: [],
		placeholder: ''
	};

	// Note: used not very elegant way of resetting input value by using internal API.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	el: any = null;

	state: RecipientSelectItemState = {
		addOptionSuggestion: null,
		options: [],
		isLoading: false
	};

	onSearch = debounce((searchTerm: string) => {
		if (searchTerm.length < 2) {
			this.setState({
				options: [],
				isLoading: false
			});
			return;
		}
		this.setState({ isLoading: true });
		onSearch(searchTerm, this.props.value, this.props.currentUserId).then(
			({ validSearchTerm, options }) => {
				this.setState({
					addOptionSuggestion: validSearchTerm
						? { key: OPTION_KEY_NEW, label: searchTerm }
						: null,
					options,
					isLoading: false
				});
			}
		);
	}, DEFAULT_DEBOUNCE_DELAY);

	plainValue = (value: Value[]) => {
		const result = value.reduce<Value[]>((acc = [], item) => {
			let label: string | undefined = isString(item.label)
				? item.label
				: undefined;

			if (isArray(item.label) && isString(item.label[1])) {
				label = item.label[1];
			}
			if (!label) {
				return acc;
			}

			const result = {
				label
			} as Value;

			if (
				!(
					item.key.includes(OPTION_KEY_NEW) ||
					item.key.includes(OPTION_KEY_PLACEHOLDER)
				)
			) {
				result.key = item.key;
			}
			acc.push(result);
			return acc;
		}, []);
		return result;
	};

	onChange = (value: Value[]) => {
		this.setState(
			{
				addOptionSuggestion: null,
				options: []
			},
			() => {
				if (this.props.onChange) {
					this.props.onChange(this.plainValue(value));
				}
			}
		);
	};

	promptAddNewOption = () => {
		const { value } = this.props;
		const { addOptionSuggestion, options } = this.state;
		if (!addOptionSuggestion) {
			return false;
		}
		const hasInOptions = options.some(
			option => option.email === addOptionSuggestion.label
		);
		const hasInValue = (value || []).some(
			option => option.label === addOptionSuggestion.label
		);
		return !(hasInOptions || hasInValue);
	};

	onBlur = () => {
		this.setState({
			options: [],
			addOptionSuggestion: null
		});
	};

	onInputKeyDown: SelectProps['onInputKeyDown'] = e => {
		const { options, addOptionSuggestion } = this.state;
		// Tab
		// `tab` behavior is the same as `enter`, when having options/new option available,
		// the first option will be auto populated
		if (e.keyCode === KeyboardCode.TAB) {
			const newValue =
				addOptionSuggestion || (options.length > 0 && toLabelValue(options[0]));
			if (!newValue) {
				return;
			}
			e.preventDefault();

			this.onChange([...this.props.value, newValue]);

			// Haven't found more elegant way of clearing the input.
			if (this.el) {
				this.el.select.rcSelect.setInputValue('');
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref = (el: any) => {
		this.el = el;
	};

	renderValue = () => {
		return this.props.value?.map((item, index) => {
			return {
				key: item.key || `${OPTION_KEY_PLACEHOLDER}_${index}`,
				label: [
					<Icon type="mail" color="standard" offset="right" />,
					item.label
				],
				title: item.title
			};
		});
	};

	render() {
		const shouldPromptAdd = this.promptAddNewOption();
		const value = this.renderValue();
		return (
			<Select
				ref={this.ref}
				value={value}
				className={styles.root}
				filterOption={false}
				mode="multiple"
				labelInValue
				onChange={this.onChange}
				onSearch={this.onSearch}
				placeholder={this.props.placeholder}
				notFoundContent={isEmpty(this.props.value) ? 'Type to search' : ''}
				onBlur={this.onBlur}
				onInputKeyDown={this.onInputKeyDown}
			>
				{shouldPromptAdd && this.state.addOptionSuggestion && (
					<Select.Option key={this.state.addOptionSuggestion.key}>
						Add {this.state.addOptionSuggestion.label}
					</Select.Option>
				)}
				{this.state.options.map(option => (
					<Select.Option key={option.id}>{option.email}</Select.Option>
				))}
			</Select>
		);
	}
}

export default RecipientSelectItem;
