import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import {
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse
} from 'services/api/threads/threadsServiceTypes';
import { retrieveAvailableThreadGroupsAsync as async } from '../../actions/availableGroups/retrieveAvailableThreadGroups';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse,
	Error
>({
	async,
	api: Api.Threads.retrieveAvailableGroups
});
