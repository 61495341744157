import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	retrieveSavings as action,
	retrieveSavingsAsync as async
} from '../actions/retrieveSavings';
import { SavingsRequest } from 'services/api/savings/savingsServiceTypes';

import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';

export function* retrieveSavingsWorker({
	payload
}: Action<SavingsRequest>): SagaIterator {
	yield put(async.started(payload));
	try {
		const response = yield call(Api.Savings.retrieveSavings, payload);

		yield put(
			async.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			notify.error('Error occurred while retrieving Savings information')
		);
		yield put(
			async.failed({
				error,
				params: payload
			})
		);
	}
}

export function* retrieveSavingsWatcher(): SagaIterator {
	yield takeLatest(action.type, retrieveSavingsWorker);
}
