import actionCreator from '../companyVesselsActionCreator';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';

const ACTION_NAME = 'SET_ACTIVE_VESSEL_SEARCH_RESULT';

export interface SetActiveVesselSearchResultPayload {
	result: VesselDetails;
	isResultMapped: boolean;
}
export const setActiveVesselSearchResult = actionCreator<SetActiveVesselSearchResultPayload | null>(
	ACTION_NAME
);
