import { createSelector } from 'reselect';
import {
	DaType,
	DaSetStatus,
	DisbursementAccount
} from 'services/api/finance/financeServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';
import { getIsLoading } from 'store/selectors';
import { getUserType } from 'store/auth/selectors';
import { getAllDas, getDaSets } from './financeSelectors';
import { getDasInDaSetsByStatus } from './financeDASelectors';
import { FinanceRevisionsTab, FINANCE_REVISIONS_LABEL_ALL } from '../constants';
import { Entity } from 'app-types.d';
import { AppState } from 'store-types.d';
import { isEmpty, flow, sortBy } from 'lodash';

const { HUB_IN, HUB_OUT } = FinanceRevisionsTab;

export interface DaDropdownItem extends Entity {
	daType: DisbursementAccount['daType'];
}

export interface DaDropdownTabMap {
	[HUB_IN]?: DaDropdownItem[];
	[HUB_OUT]?: DaDropdownItem[];
}

export const getSortedDaByNumber = createSelector(
	getAllDas,
	(das): DisbursementAccount[] => das.sort((a, b) => a.number - b.number)
);

const getSortedDaByNumberFromActiveAndLockedSet = createSelector(
	getSortedDaByNumber,
	getDaSets,
	(das, daSets) =>
		getDasInDaSetsByStatus(das, daSets, [
			DaSetStatus.ACTIVE,
			DaSetStatus.LOCKED
		])
);

export const getDaListForDropdown = createSelector(
	getSortedDaByNumberFromActiveAndLockedSet,
	(das): DaDropdownItem[] => {
		if (!das.length) {
			return [];
		}
		const columns = das.map(({ id, label, daType }) => ({
			id,
			name: label,
			daType
		}));

		return sortBy(columns, 'daType', 'name');
	}
);

export const getLabelForAllDa = (daType: DaType) => ({
	id: `all-${daType}`,
	name: FINANCE_REVISIONS_LABEL_ALL,
	daType
});

const filterDasByUser = (das: DaDropdownItem[], userType: UserType) => {
	switch (userType) {
		case UserType.LPA:
			return das.filter(da => da.daType === DaType.IN);
		case UserType.PRINCIPAL:
			return das.filter(da => da.daType === DaType.OUT);
		default:
			return das;
	}
};

const initDropdownMap = (userType: UserType): DaDropdownTabMap => {
	switch (userType) {
		case UserType.LPA:
			return { [HUB_IN]: [] };
		case UserType.PRINCIPAL:
			return { [HUB_OUT]: [] };
		default:
			return { [HUB_IN]: [], [HUB_OUT]: [] };
	}
};

const revisionTabNameFromDaType = (daType: DaType) =>
	daType === DaType.IN ? HUB_IN : HUB_OUT;

const addDasToProperType = (
	acc: DaDropdownTabMap,
	da: DaDropdownItem
): DaDropdownTabMap => {
	const { daType } = da;
	const key = revisionTabNameFromDaType(daType);
	return {
		...acc,
		[key]: [...(acc[key] as DaDropdownItem[]), da]
	};
};

const appendAllItem = (daType: DaType) => (dropdownMap: DaDropdownTabMap) => {
	const key = revisionTabNameFromDaType(daType);
	const items = dropdownMap[key];
	if (isEmpty(items)) {
		return dropdownMap;
	}
	return {
		...dropdownMap,
		[key]: [getLabelForAllDa(daType), ...(items as DaDropdownItem[])]
	};
};

const dasDropDownItems = (das: DaDropdownItem[], userType: UserType) => {
	const availableDaItems = filterDasByUser(das, userType);
	const initData = initDropdownMap(userType);
	return availableDaItems.reduce(addDasToProperType, initData);
};

export const getDasDropdownTabsWithDataSource = createSelector(
	getDaListForDropdown,
	getUserType,
	(das, userType): DaDropdownTabMap => {
		if (!das.length) {
			return {};
		}
		return flow(
			dasDropDownItems,
			appendAllItem(DaType.IN),
			appendAllItem(DaType.OUT)
		)(das, userType);
	}
);

const getSingleDADownloadStatusById = ({ finance }: AppState, id: string) =>
	finance.fetchStatuses.downloadDAById[id];

export const getIsSingleDADownloadingById = createSelector(
	getSingleDADownloadStatusById,
	status => getIsLoading(status)
);

const getAllDAsDownloadStatus = ({ finance }: AppState) =>
	finance.fetchStatuses.downloadAllDAs;
export const getIsDownloadingAllDAs = createSelector(
	getAllDAsDownloadStatus,
	status => getIsLoading(status)
);
