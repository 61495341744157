import { FundingRequest } from 'services/api/financeFundings/financeFundingsServiceTypes';
import { Entity } from 'app-types';

export const FINANCE_FUNDING_FORM_NAME = 'financeFunding';

export enum FinanceFundingFormField {
	PERCENT = 'fundingPercent',
	AMOUNT = 'fundingAmount',
	COMMENT = 'comment',
	URGENT = 'isUrgent',
	BASE_AMOUNT_TYPE_CODE = 'baseAmountTypeCode',
	FUNDING_REQUESTED = 'fundRequestedAmt',
	FUNDING_PERCENT = 'fundPercent',
	FUND_TABLE = 'fundingTable'
}

export interface FundingDaItem extends Entity {
	disabled: boolean;
	noBankDetail?: boolean;
}

export interface FinanceFundingFormData extends FundingRequest {}
