import React, { useState } from 'react';
import { TextArea, Icon, Col } from 'components/antd';
import styles from '../ImageViewerV2.module.scss';
import { Flex } from 'components';
import { Justify } from 'components/types';
import { TextNode } from '../ImageViewerV2Types';
import FontSizeDropdown from './FontSizeDropdown';
import { FontStyle, TextAlign } from './Constants';
import classNames from 'classnames';

interface TextInsertPopoverProps {
	x: number;
	y: number;
	text: string;
	color: string;
	fontSize: number;
	fontStyle: string;
	align: string;
	onSaveText: (textNode: Partial<TextNode>) => void;
	onClose: () => void;
}

function TextInsertPopover(props: TextInsertPopoverProps) {
	const [text, setText] = useState(props.text);
	// const [textDecoration, setTextDecoration] = useState(false);
	const [isBold, setIsBold] = useState(
		props.fontStyle.includes(FontStyle.BOLD)
	);
	const [isItalic, setIsItalic] = useState(
		props.fontStyle.includes(FontStyle.ITALIC)
	);
	const [fontSize, setFontSize] = useState(props.fontSize);
	const [alignment, setAlignment] = useState(props.align);
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setText(e.target.value);
	};
	const onSaveText = () => {
		const fontStyle =
			isBold && isItalic
				? FontStyle.ITALIC_BOLD
				: isBold
				? FontStyle.BOLD
				: isItalic
				? FontStyle.ITALIC
				: FontStyle.NORMAL;
		const textNode = {
			text,
			fontSize,
			fontStyle,
			align: alignment
		};
		props.onSaveText(textNode);
	};

	return (
		<div
			className={styles.textForm}
			style={{ top: props.y - 40, left: props.x }}
		>
			<Flex direction="vertical">
				<Flex
					direction="horizontal"
					className={styles.textTools}
					align="center"
				>
					<Col xs={8} justify={Justify.START} className={styles.tools}>
						<FontSizeDropdown fontSize={fontSize} setFontSize={setFontSize} />
						<span className={styles.divider} />
						<Icon
							type="left-align"
							color="primary"
							size="md"
							className={classNames({
								[styles.active]: alignment === TextAlign.LEFT
							})}
							onClick={() => setAlignment(TextAlign.LEFT)}
						/>
						<Icon
							type="middle-align"
							color="primary"
							size="md"
							className={classNames({
								[styles.active]: alignment === TextAlign.CENTER
							})}
							onClick={() => setAlignment(TextAlign.CENTER)}
						/>
						<Icon
							type="right-align"
							color="primary"
							size="md"
							className={classNames({
								[styles.active]: alignment === TextAlign.RIGHT
							})}
							onClick={() => setAlignment(TextAlign.RIGHT)}
						/>
						<span className={styles.divider} />
						<Icon
							type="bold"
							color="primary"
							size="md"
							className={classNames({
								[styles.active]: isBold
							})}
							onClick={() => setIsBold(!isBold)}
						/>
						<Icon
							type="italic"
							color="primary"
							size="md"
							className={classNames({
								[styles.active]: isItalic
							})}
							onClick={() => setIsItalic(!isItalic)}
						/>
						<span className={styles.divider} />
					</Col>
					<Col xs={3} justify={Justify.END} className={styles.close}>
						<Icon type="check" color="primary" size="md" onClick={onSaveText} />
						<Icon
							type="close"
							color="error"
							size="md"
							onClick={props.onClose}
						/>
					</Col>
				</Flex>
				<TextArea
					style={{
						color: props.color,
						fontSize: fontSize,
						...(isItalic && { fontStyle: FontStyle.ITALIC }),
						...(isBold && { fontWeight: FontStyle.BOLD }),
						...(alignment === TextAlign.LEFT && { textAlign: TextAlign.LEFT }),
						...(alignment === TextAlign.CENTER && {
							textAlign: TextAlign.CENTER
						}),
						...(alignment === TextAlign.RIGHT && { textAlign: TextAlign.RIGHT })
						// ...(textDecoration && { textDecoration: 'underline' })
					}}
					value={text}
					onChange={onChange}
					autoFocus
				/>
			</Flex>
		</div>
	);
}

export default TextInsertPopover;
