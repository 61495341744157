import { FinanceState } from '../financeState';
import { OpenMoveServiceModalPayload } from '../actions';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { MoveServiceRequest } from 'services/api/finance/financeServiceTypes';

export function onMoveServiceModalOpen(
	state: FinanceState,
	openMoveServiceModalPayload: OpenMoveServiceModalPayload
): FinanceState {
	const { serviceId, serviceCode } = openMoveServiceModalPayload;
	return {
		...state,
		context: {
			...state.context,
			moveServiceModalId: serviceId,
			currentMoveServiceCode: serviceCode,
			showMoveServiceModal: true
		}
	};
}

export function onMoveServiceModalClose(state: FinanceState): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			moveServiceModalId: null,
			currentMoveServiceCode: null,
			showMoveServiceModal: false
		}
	};
}

export const onMoveService = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	MoveServiceRequest,
	null
>('fetchStatuses', 'moveService');
