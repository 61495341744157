import { JobSections as Response } from 'services/api/dashboard/dashboardServiceTypes';
import actionCreator from 'store/dashboard/dashboardActionCreator';

const RETRIEVE_JOBS_SECTIONS = 'RETRIEVE_JOBS_SECTIONS';
export const retrieveJobSectionsAsync = actionCreator.async<
	void,
	Response,
	Error
>(RETRIEVE_JOBS_SECTIONS);

export const retrieveJobSections = actionCreator(RETRIEVE_JOBS_SECTIONS);
