import React from 'react';
import styles from './DocumentViewerPreview.module.scss';
import { Flex, Ellipsis } from 'components';
import { Justify } from 'components/types';

interface DocumentViewerPreviewNoAvailableProps {
	isLoading?: boolean;
}

const DocumentViewerPreviewNoAvailable: React.SFC<
	DocumentViewerPreviewNoAvailableProps
> = ({ isLoading }) => {
	return (
		<Flex
			className={styles.previewNotAvailable}
			justify={Justify.CENTER}
			align="center"
		>
			<img
				src={require('./images/preview-not-available.svg')}
				alt="Preview is loading..."
			/>
			<div className={styles.previewNotAvailableNotification}>
				{isLoading ? (
					<Ellipsis>Preview is loading</Ellipsis>
				) : (
					'Preview of this file type is not available'
				)}
			</div>
		</Flex>
	);
};

export default DocumentViewerPreviewNoAvailable;
