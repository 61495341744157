import { FormWarnings } from 'redux-form';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { PageFormProps } from './Form';
import OperationsConfig from '../Operations/OperationsConfig';

const warn = (
	values: FormData,
	props: PageFormProps
): FormWarnings<FormData> => {
	const { operationTypeCode } = props;
	const operationConfig =
		operationTypeCode && OperationsConfig[operationTypeCode];
	// eslint-disable-next-line @typescript-eslint/unbound-method
	if (!operationConfig || !operationConfig.warn) {
		return {};
	}
	return operationConfig.warn(values);
};

export default warn;
