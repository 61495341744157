import React from 'react';
import { get } from 'lodash';

import TermsPopover from './TermsPopover';
import CargoSettingsPopover from './CargoSettingsPopover';

import { ColumnProps } from 'components/antd/Table/TableTypes';
import { Column, CargoGridProps } from './CargoGrid';
import { EntityPermissionFetcher } from 'containers';
import { EntityPermissionType } from 'services/api/permissions/permissionsServiceTypes';
import CargoSettingsMenu from './CargoSettingsMenu';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { isIdAutoGenerated } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';

import OperationsConfig from '../Operations/OperationsConfig';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import { getCargoMenuColumnId } from '../helpers';

const getCargoGridColumns = (props: CargoGridProps) => {
	const { operationTypeCode, onDeleteClicked, onEditClicked } = props;

	const columns: Array<ColumnProps<Column>> = [
		{
			title: 'Options / Terms',
			dataIndex: 'terms',
			key: 'terms',
			render: (text: string) => (
				<OptionalValue value={text}>
					<TermsPopover terms={text} />
				</OptionalValue>
			)
		},
		{
			dataIndex: 'index',
			render: (_text: string, cargoLine: Column) => {
				const cargoId = get(cargoLine, 'id', '') || '';
				const chartererCompanyId =
					get(cargoLine.chartererCompany, 'key', '') || '';
				const entityType =
					operationTypeCode === OperationTypeCode.LOADING ||
					operationTypeCode === OperationTypeCode.LOADING_STS
						? EntityPermissionType.LOADING_CARGO_LINE
						: EntityPermissionType.DISCHARGING_CARGO_LINE;
				const content = (
					<>
						{!isIdAutoGenerated(cargoId) && (
							<EntityPermissionFetcher
								entityType={entityType}
								entityKey={cargoId}
								showLoader={false}
							>
								{entityPermission => (
									<CargoSettingsMenu
										{...entityPermission}
										cargoId={cargoId}
										chartererCompanyId={chartererCompanyId}
										onUpdate={() => onEditClicked(cargoId)}
										onDelete={isParent =>
											onDeleteClicked(cargoId, chartererCompanyId, isParent)
										}
									/>
								)}
							</EntityPermissionFetcher>
						)}
						{isIdAutoGenerated(cargoId) && (
							<CargoSettingsMenu
								canDelete
								cargoId={cargoId}
								chartererCompanyId={chartererCompanyId}
								onUpdate={() => onEditClicked(cargoId)}
								onDelete={isParent =>
									onDeleteClicked(cargoId, chartererCompanyId, isParent)
								}
							/>
						)}
					</>
				);
				return (
					<div id={getCargoMenuColumnId(cargoId)}>
						<CargoSettingsPopover
							popoverContent={content}
							entityKey={cargoId}
							entityType={entityType}
							cargoId={cargoId}
						/>
					</div>
				);
			}
		}
	];
	const operationConfig = OperationsConfig[operationTypeCode];
	// eslint-disable-next-line @typescript-eslint/unbound-method
	if (!operationConfig.getColumns) {
		return columns;
	}
	return [...operationConfig.getColumns(props), ...columns];
};

export default getCargoGridColumns;
