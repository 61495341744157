import React, { useCallback } from 'react';
import { Modal } from 'components/antd';

import { UsersModalsPropsBase } from '../UsersModalsTypes';
import { getOppositeUserStatusText } from 'sections/User/UserStatusButton/UserStatusButton.func';

type ToggleUserStatusModalProps = UsersModalsPropsBase;
function ToggleUserStatusModal({
	modalValue,
	onCancel,
	onSubmit
}: ToggleUserStatusModalProps) {
	const onSubmitClick = useCallback(() => onSubmit(modalValue), [
		onSubmit,
		modalValue
	]);
	if (!modalValue) {
		return null;
	}
	return (
		<Modal
			visible
			okText="Yes"
			cancelText="No"
			onCancel={onCancel}
			onOk={onSubmitClick}
			onlyText
		>
			Are you sure that you want to{' '}
			{getOppositeUserStatusText(modalValue.status)}{' '}
			<strong>{`${modalValue.firstName} ${modalValue.lastName}`}</strong>?
		</Modal>
	);
}

export default ToggleUserStatusModal;
