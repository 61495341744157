import * as React from 'react';
import { connect } from 'react-redux';
import { ComplexTable } from 'components';
import { ComplexTableProps } from 'components/Table/ComplexTable/ComplexTable';
import { BaseComponentProps } from 'app-types';
import { LazyLoadBaseProps } from 'components/LazyLoad/LazyLoad';
import { AppState } from 'store-types';
import { isThreadsFetchingAll } from 'store/threads/selectors';
import { getThreadListEmptyMessage } from './ThreadsFilters/ThreadsFiltersSelectors';
import classnames from 'classnames';
import styles from './Threads.module.scss';
import { AppNotifications } from 'containers';

interface ThreadsListProps extends BaseComponentProps, LazyLoadBaseProps {
	actionType: string;
	hasMore: boolean;
	loading: boolean;
	emptyMessage: string;
	stretch?: ComplexTableProps['stretch'];
	skipDoneLoadingMessage?: boolean;
	noPadding?: boolean;
	customRef?: (elem: React.ReactNode) => void;
}

const ThreadsList: React.SFC<ThreadsListProps> = ({
	actionType,
	loading,
	onEnter,
	hasMore,
	doneLoading,
	emptyMessage,
	skipDoneLoadingMessage,
	noPadding,
	customRef,
	children
}) => {
	return (
		<AppNotifications.Table entityName="Threads" actionType={actionType}>
			{({ failedLoading }) => (
				<ComplexTable
					tableClassName={styles.tableWrap}
					className={classnames(styles.table, {
						[styles.noPadding]: noPadding
					})}
					lazyLoad={{
						loading,
						onEnter,
						hasMore,
						doneLoading: skipDoneLoadingMessage || doneLoading || (
							<p>{'No more Threads to load'}</p>
						),
						failedLoading
					}}
					locale={{ emptyText: emptyMessage }}
					customRef={customRef}
				>
					{children}
				</ComplexTable>
			)}
		</AppNotifications.Table>
	);
};

export default connect(
	(
		state: AppState,
		{ emptyMessage }: Partial<Pick<ThreadsListProps, 'emptyMessage'>>
	) => ({
		hasMore: state.threads.hasMore,
		loading: isThreadsFetchingAll(state),
		emptyMessage: emptyMessage ? emptyMessage : getThreadListEmptyMessage(state)
	})
)(ThreadsList);
