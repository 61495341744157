import * as React from 'react';
import classNames from 'classnames';
import styles from './Popover.module.scss';
import AntPopover, { PopoverProps as AntPopoverProps } from 'antd/lib/popover';
import { Size } from 'components/types';

export interface PopoverProps extends AntPopoverProps {
	/**
	 *	Dropdown: Used to make it look as dropdown itself but with triangles around
	 */
	type?: 'dropdown';
	size?: Size;
}

export const Popover: React.FC<PopoverProps> = ({
	type,
	overlayClassName,
	size,
	getPopupContainer,
	...restProps
}) => {
	const popoverTypes = {
		[styles.dropdownType]: type === 'dropdown'
	};

	const onGetPopupContainer = (triggerNode: HTMLElement) => {
		if (getPopupContainer && !getPopupContainer(triggerNode)) {
			/* eslint-disable no-console */
			console.warn(
				`Couldn't find container element using props.getPopupContainer that you provided. \n` +
					'The fallback of triggerNode.parentElement || document.body will be applied. \n' +
					'triggerNode:'
			);
			console.warn(triggerNode);
			/* eslint-enable */
		}
		return (
			getPopupContainer?.(triggerNode) ||
			triggerNode.parentElement ||
			document.body
		);
	};

	return (
		<AntPopover
			{...restProps}
			getPopupContainer={onGetPopupContainer}
			overlayClassName={classNames(
				styles.root,
				popoverTypes,
				styles[size || ''],
				overlayClassName
			)}
		/>
	);
};

export default Popover;
