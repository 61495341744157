import {
	removeFetchFile,
	addFetchFile
} from 'store/documents/documentsHelpers';
import { DocumentsState } from 'store/documents/documentsState';
import {
	RetrieveStaticDocumentConfigResponse,
	RetrieveDocumentConfigParams
} from 'services/api/documents/documentsServiceTypes';
import { Success } from 'typescript-fsa';

/**
 * Getting Static Config
 */
export function onRetrieveStaticConfigStarted(
	state: DocumentsState
): DocumentsState {
	return {
		...state,
		filesFetchingNumber: addFetchFile(state.filesFetchingNumber),
		attachments: [] // Reset the attachment list at the moment when the <Upload/> component it's mounted
	};
}

/** 
 * Static it's done
 */
export function onRetrieveStaticConfigDone(
	state: DocumentsState,
	action: Success<
		RetrieveDocumentConfigParams,
		RetrieveStaticDocumentConfigResponse
	>
): DocumentsState {
	return {
		...state,
		filesFetchingNumber: removeFetchFile(state.filesFetchingNumber),
		config: {
			...state.config,
			staticByType: {
				...state.config.staticByType,
				[action.params.type]: action.result
			}
		}
	};
}
