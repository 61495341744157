import * as React from 'react';
import { connect } from 'react-redux';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AppState } from 'store-types';
import { getActivePortCallInfo } from './page1Selectors';
import PortJobInfo from 'sections/PortJob/SubHeader/Info/PortCallInfo';

export interface PageInfoProps extends Partial<Page1FormValues> {
	vesselName: React.ReactNode;
	portName: React.ReactNode;
	etaPlt: string;
	etsPlt: string;
}

const PageInfo = (props: PageInfoProps) => <PortJobInfo {...props} />;

export default connect((state: AppState) => {
	return getActivePortCallInfo(state);
})(PageInfo);
