import { all, fork } from 'redux-saga/effects';
import { retrieveSavingsWatcher } from './retrieveSavingsSaga';
import addCostSavingsWatcher from './addCostSavingsSaga';
import addTimeSavingsWatcher from './addTimeSavingsSaga';
import { retrieveTimeSavingsWatcher } from './retrieveTimeSavingsSaga';
import { deleteSavingsWatcher } from './deleteCostSavingsSaga';
import { deleteTimeSavingsWatcher } from './deleteTimeSavingsSaga';
import updateCostSavingsWatcher from './updateCostSavingsSaga';
import updateTimeSavingsWatcher from './updateTimeSavingsSaga';

export default function*() {
	yield all([
		fork(retrieveSavingsWatcher),
		fork(addCostSavingsWatcher),
		fork(addTimeSavingsWatcher),
		fork(updateCostSavingsWatcher),
		fork(updateTimeSavingsWatcher),
		fork(retrieveTimeSavingsWatcher),
		fork(deleteSavingsWatcher),
		fork(deleteTimeSavingsWatcher)
	]);
}
