import {
	RetrieveAvailableDasRequest as request,
	DaAvailabilityItem,
	DaType
} from 'services/api/finance/financeServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';
import { ResultCollection } from 'services/api/apiTypes';
const ACTION_NAME = 'RETRIEVE_AVAILABLE_DAS';
export const retrieveAvailableDasAsync = actionCreator.async<
	request,
	ResultCollection<DaAvailabilityItem>,
	Error
>(ACTION_NAME);

export const retrieveAvailableDas = actionCreator<{
	jobServiceId: string;
	daType: DaType;
}>(ACTION_NAME);
