import React from 'react';

import { Input, Select } from 'components/antd';

import {
	GroupType,
	GroupTypeCodes
} from 'services/api/groups/groupsServiceTypes';
import { Company } from 'services/api/companies/companiesServiceTypes';

export interface CreateGroupFormFieldsProps {
	groupTypes: GroupType[];
	companies: Company[];
	isSelectedCompanyIss: boolean;
	isCurrentUserOfISSAdminGroupType: boolean;
}
function CreateGroupFormFields({
	isCurrentUserOfISSAdminGroupType,
	companies,
	isSelectedCompanyIss,
	groupTypes
}: CreateGroupFormFieldsProps) {
	return (
		<>
			<Input.ReduxFormItem
				type="text"
				name="name"
				label="Group name:"
				maxLength={100}
				required
				help
			/>
			<Select.ReduxFormItem
				name="companyId"
				label="Company:"
				placeholder="Choose company"
				required
				help
				disabled={!isCurrentUserOfISSAdminGroupType}
				getPopupContainer={() => document.body}
			>
				{companies.map(company => (
					<Select.Option key={company.id}>{company.companyName}</Select.Option>
				))}
			</Select.ReduxFormItem>

			{isSelectedCompanyIss && (
				<Select.ReduxFormItem
					name="groupType"
					label="Group Type"
					placeholder="Choose group type"
					required
					help
				>
					{groupTypes
						.filter(type => type.code !== GroupTypeCodes.ISS_CLUSTER)
						.map(type => (
							<Select.Option key={type.code}>{type.name}</Select.Option>
						))}
				</Select.ReduxFormItem>
			)}
		</>
	);
}

export default CreateGroupFormFields;
