import * as React from 'react';
import { connect } from 'react-redux';
import styles from './PortCallLayout.module.scss';
import { PortCallTabType } from 'store/portcalls/portCallsTypes';
import { Icon, Badge } from 'components/antd';
import { getPendingAlertStatus } from 'store/portCall/selectors';
import { AppState } from 'store-types';
import { ThemeScheme } from 'components/types';

interface PortCallTabOwnProps {
	tab: PortCallTabType;
	isReadOnly: boolean;
	isDisabled?: boolean;
}
type PortCallTabProps = ReturnType<typeof mapStateToProps> &
	PortCallTabOwnProps;

const PortCallTab: React.SFC<PortCallTabProps> = ({
	tab,
	isVisibleDot,
	dotStatus
}) => {
	return (
		<Badge
			show={isVisibleDot}
			dot
			theme={
				// types are conflicting. needs to be fixed
				dotStatus as ThemeScheme | undefined
			}
			placement={'topRight'}
			className={styles.tabItem}
			adjustIcon={false}
		>
			<div>
				<Icon type={tab.icon} color="standard" />
				<br />
				{tab.tab}
			</div>
		</Badge>
	);
};

const mapStateToProps = (state: AppState, ownProps: PortCallTabOwnProps) => {
	const dotStatus = getPendingAlertStatus(state, ownProps.tab.key);
	return {
		dotStatus,
		isVisibleDot:
			dotStatus !== undefined && !ownProps.isReadOnly && !ownProps.isDisabled
	};
};

export default connect(mapStateToProps)(PortCallTab);
