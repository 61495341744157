import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { DocumentWithOriginalFile } from 'services/api/documents/documentsServiceTypes';
import { getCurrentUser } from 'store/auth/selectors';

export const isUploadingAttachmentSelector = (state: AppState): boolean =>
	state.documents.filesFetchingNumber > 0;

export const getDocumentsAttachmentsSelector = (
	state: AppState
): DocumentWithOriginalFile[] => state.documents.attachments;

export const getComposeMessageFromSelector = createSelector(
	getCurrentUser,
	user => {
		if (!user) {
			return {
				name: '',
				email: ''
			};
		}
		return {
			name: user.fullName,
			email: user.email
		};
	}
);
