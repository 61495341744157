import { CompanyAgentAutocompleteOption } from 'services/api/ports/portsServiceTypes';

export interface ColumnEntryItem {
	title: string;
	value: string | number;
	isFormattedValue?: boolean;
}

export type ColumnEntryItemAgent = ColumnEntryItem & {
	details: CompanyAgentAutocompleteOption;
};

export enum ColumnEntryKey {
	CONTACT,
	AGENT,
	CUSTOMER,
	PAYMENT
}

export type ColumnEntry = ColumnEntryItem | ColumnEntryItemAgent;

export interface Column {
	key: ColumnEntryKey;
	columns: ColumnEntry[];
}
