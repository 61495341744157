import actionCreator from '../threadsActionCreator';
import { RetrieveThreadTagsResponse } from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREAD_TAGS';

export const retrieveThreadTagsAsync = actionCreator.async<
	null,
	RetrieveThreadTagsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadTags = actionCreator<null>(ACTION_NAME);
