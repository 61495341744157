import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, take, select } from 'redux-saga/effects';
import { retrieveThreadByIdAsync } from 'store/thread/actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { navigateTo } from 'utils';
import { get } from 'lodash';
import { THREAD_IGNORED_CODES } from 'store/thread/threadConstants';
import { retrieveThreadsAsync } from 'store/threads/actions/retrieveThreads';
import {
	retrievePortJobThreads,
	resetPortJobThreads,
	resetActivePortJobThreadId
} from 'store/portJobs/actions';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { showFailOpenPageNotification } from 'utils/sagaHelpers/sagaUtils';

export function* errorHandlingThreadByIWorker({
	payload
}: /* eslint-disable @typescript-eslint/no-explicit-any */
Action<any>): SagaIterator {
	const status = get(payload, 'error.response.status', undefined);
	if (!THREAD_IGNORED_CODES.includes(status)) {
		return;
	}
	const portCallId = yield select(getActivePortCallId);

	if (!portCallId) {
		// global message grid
		yield call(navigateTo, '/messages');
		yield take(retrieveThreadsAsync.done);
		yield call(showFailOpenPageNotification);

		return;
	}
	// messages in portcall
	const jobCode = yield select(getActivePortJobCode);
	yield put(resetActivePortJobThreadId());
	yield put(resetPortJobThreads());
	yield put(
		retrievePortJobThreads({
			jobCode,
			portCallId
		})
	);
	yield call(showFailOpenPageNotification);
}

export default function* errorHandlingThreadByIdWatcher(): SagaIterator {
	yield takeLatest(
		retrieveThreadByIdAsync.failed,
		errorHandlingThreadByIWorker
	);
}
