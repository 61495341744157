import * as React from 'react';
import { connect } from 'react-redux';
import { ScrollableLayout, ComplexTable } from 'components';
import { AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import {
	getAllPortCallsSelector,
	getIsPortCallsFetching,
	portCallsHasMoreSelector,
	getMetricTypeGroups
} from 'store/portcalls/portCallsSelectors';
import {
	retrievePortCalls,
	retrievePortCallsStatic,
	resetPortCalls,
	resetActivePortCallId
} from 'store/portcalls';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { PortCallRow } from './PortCallRow/PortCallRow';
import { navigateTo } from 'utils';
import { resetActivePortJobCode } from 'store/portJobs/actions';
import PortCallsHeader from './PortCallsHeader/PortCallsHeader';
import { PortCallAlertsMap } from 'app-types';
import { PortCallsBaseProps } from './index';
import { AppState } from 'store-types';

interface PortCallsProps extends PortCallsBaseProps {
	// from mapStateToProps
	portCalls: PortCall[];
	isPortCallsFetching: boolean;
	hasMorePortCalls: boolean;
	metricTypeGroups: { [id: string]: PortCallAlertsMap };
	// from mapDispatchToProps
	retrievePortCalls: typeof retrievePortCalls;
	retrievePortCallsStatic: typeof retrievePortCallsStatic;
	resetPortCalls: typeof resetPortCalls;
	resetActivePortJobCode: typeof resetActivePortJobCode;
	resetActivePortCallId: typeof resetActivePortCallId;
}

export class PortCalls extends React.Component<PortCallsProps> {
	onCreateJob = () => {
		navigateTo('/portcalls/new');
	};

	retrievePortCalls = () => {
		this.props.retrievePortCalls({
			expand: 'PortCallGrid'
		});
	};

	componentDidMount() {
		// TODO
		// verify which components are the
		// most suitable for resetting data when unmounted
		// or if this is needed in general
		this.props.resetActivePortJobCode();
		this.props.resetPortCalls();
		this.props.resetActivePortCallId();
		this.retrievePortCalls();
	}

	componentWillUnmount() {
		this.props.resetPortCalls();
	}

	render() {
		const {
			portCalls,
			isPortCallsFetching,
			hasMorePortCalls,
			metricTypeGroups
		} = this.props;

		return (
			<ScrollableLayout>
				<RouteBreadcrumbs items={[{ title: 'Port Calls' }]} />
				<PortCallsHeader onCreateJobClick={this.onCreateJob} />
				<AppNotifications.Table
					entityName="Port Calls"
					actionType={retrievePortCalls.type}
				>
					{({ failedLoading }) => {
						return (
							<ComplexTable
								stretch
								lazyLoad={{
									loading: isPortCallsFetching,
									onEnter: this.retrievePortCalls,
									hasMore: hasMorePortCalls,
									doneLoading: 'No more Port Calls to load',
									failedLoading
								}}
							>
								{portCalls.map(portCall => (
									<PortCallRow
										key={portCall.id}
										portCall={portCall}
										alertsMap={metricTypeGroups[portCall.id]}
									/>
								))}
							</ComplexTable>
						);
					}}
				</AppNotifications.Table>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		portCalls: getAllPortCallsSelector(state),
		isPortCallsFetching: getIsPortCallsFetching(state),
		hasMorePortCalls: portCallsHasMoreSelector(state),
		metricTypeGroups: getMetricTypeGroups(state)
	}),
	{
		retrievePortCalls,
		retrievePortCallsStatic,
		resetPortCalls,
		resetActivePortJobCode,
		resetActivePortCallId
	}
)(PortCalls);
