import React, { lazy } from 'react';
import cn from 'classnames';
import {
	getLatestMessageEvent,
	getThreadEventsList,
	getIsThreadEventsLoading
} from 'store/thread/eventsSelector';
import { connect } from 'react-redux';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { MessageEvent } from 'services/api/messages/messagesServiceTypes';
import { ScrollableLayout } from 'components';
import ThreadAssignToMeAlert from 'containers/ThreadAssignToMeAlert/ThreadAssignToMeAlert';

import {
	retrieveThreadEvents,
	resetThreadEvents,
	updateThreadMessageRead,
	retrieveThreadById,
	retrieveThreadByIdCycleStart,
	retrieveThreadByIdCycleStop,
	resetThread
} from 'store/thread';
import {
	getActiveThread,
	getIsCurrentUserAssignedToActiveThread
} from 'store/thread/selectors';
import { getComposeMessageFromSelector } from 'sections/ComposeMessage/ComposeMessageSelector';
import {
	createReplyLastMessage,
	createEmptyMessage
} from 'sections/ComposeMessage/utils/ComposeMessageUtils';
import getContentHistoryHeader from 'sections/ComposeMessage/utils/getContentHistoryHeader';
import { AppState } from 'store-types';
import { ThreadEvent } from 'sections/Thread/ThreadTypes';
import styles from './MessagesThread.module.scss';

const Events = lazy(() => import('sections/Thread/Events/Events'));

const ComposeMessage = lazy(() =>
	import('sections/ComposeMessage/ComposeMessage')
);

interface MessagesThreadProps {
	events: ThreadEvent[];
	threadId: string;
	thread: ThreadItem;
	currentUserAssignedToThread: boolean;
	// from mapStateToProps
	isEventsLoading: boolean;
	messageFrom: {
		name: string;
		email: string;
	};
	isReadOnly: boolean;
	// from mapDispatchToProps
	retrieveThreadEvents: typeof retrieveThreadEvents;
	resetThreadEvents: typeof resetThreadEvents;
	updateThreadMessageRead: typeof updateThreadMessageRead;
	retrieveThreadById: typeof retrieveThreadById;
	retrieveThreadByIdCycleStart: typeof retrieveThreadByIdCycleStart;
	retrieveThreadByIdCycleStop: typeof retrieveThreadByIdCycleStop;
	resetThread: typeof resetThread;
	latestMessageEvent: MessageEvent;
}

interface MessagesThreadState {
	isThreadRead?: boolean;
}

export class MessagesThread extends React.PureComponent<
	MessagesThreadProps,
	MessagesThreadState
> {
	state = {
		isThreadRead: false
	};

	getLastMessageInitialValues = () => {
		const {
			thread: { lastMessage, id },
			messageFrom
		} = this.props;

		if (lastMessage && id) {
			const from = {
				...messageFrom,
				defaultMailboxAddress: this.props.thread.defaultMailboxAddress
			};
			return createReplyLastMessage(from, lastMessage);
		}
		return createEmptyMessage();
	};

	componentDidMount() {
		// Temporary solution:
		// By the time thread is mounted, active port call thread id may be changed to another one
		// from messages grid
		const { threadId } = this.props;
		if (this.props.thread.id !== threadId) {
			this.props.retrieveThreadById({ threadId });
		}
		this.retrieveThreadEvents();
		this.props.retrieveThreadByIdCycleStart();
	}

	componentWillUnmount() {
		this.resetThreadInfo();
		this.props.retrieveThreadByIdCycleStop();
	}

	componentDidUpdate(prevProps: MessagesThreadProps) {
		const { threadId, latestMessageEvent } = this.props;
		// mark thread as read on initial load
		if (
			!this.state.isThreadRead &&
			latestMessageEvent &&
			!this.props.thread.isRead
		) {
			this.setState({ isThreadRead: true }, () => {
				this.props.updateThreadMessageRead({
					threadId,
					messageId: latestMessageEvent.message.id
				});
			});
		}

		// TODO can this happen?
		if (threadId !== prevProps.threadId) {
			this.resetThreadInfo();
			this.props.retrieveThreadById({ threadId });
		}
	}

	retrieveThreadEvents = () => {
		const { threadId } = this.props;
		return this.props.retrieveThreadEvents({
			threadId,
			retrieveLatest: true
		});
	};

	onLazyLoadEnter = () => {
		this.retrieveThreadEvents();
	};

	render() {
		const {
			thread,
			events,
			isEventsLoading,
			currentUserAssignedToThread,
			isReadOnly
		} = this.props;

		const notAssignedToMe = !currentUserAssignedToThread && !isReadOnly;

		return (
			<ScrollableLayout>
				{/**
				 * Commented out as discussed in IPP-24527.
				 * TODO: bring it back when IPP-24541 is picked up
				 * <OriginatedFrom />
				 */}
				{notAssignedToMe && (
					<ThreadAssignToMeAlert
						thread={thread}
						currentUserAssignedToThread={currentUserAssignedToThread}
					/>
				)}
				<ScrollableLayout>
					<Events
						events={events}
						readMessageId={thread.lastRead ? thread.lastRead.messageId : ''}
						threadId={thread.id}
						isLoading={isEventsLoading}
						className={cn({
							[styles.notAssignedToMe]: notAssignedToMe,
							[styles.assignedToMe]: !notAssignedToMe
						})}
					/>
				</ScrollableLayout>
				{!isReadOnly && (
					<ComposeMessage
						initialValues={this.getLastMessageInitialValues()}
						historyHeader={this.getHistoryHeader(thread)}
						disabledTextarea={!currentUserAssignedToThread}
						isNewTab={false}
						threadId={thread.id}
						thread={thread}
						collapsed
					/>
				)}
			</ScrollableLayout>
		);
	}

	private getFromInfo(thread: ThreadItem) {
		return {
			...this.props.messageFrom,
			defaultMailboxAddress: thread.defaultMailboxAddress
		};
	}

	private getHistoryHeader(thread: ThreadItem) {
		const {
			thread: { lastMessage: message }
		} = this.props;
		let header = '';
		if (message && thread.id) {
			const from = this.getFromInfo(thread);
			header = getContentHistoryHeader(from, message);
		}
		return header;
	}

	private resetThreadInfo() {
		/**
		 * Thread isn't reset here because it causes unexpected behavior
		 * Should be investigated
		 */
		this.props.resetThreadEvents();
		this.props.resetThread();
	}
}

export default connect(
	(state: AppState) => ({
		events: getThreadEventsList(state),
		thread: getActiveThread(state),
		currentUserAssignedToThread: getIsCurrentUserAssignedToActiveThread(state),
		messageFrom: getComposeMessageFromSelector(state),
		latestMessageEvent: getLatestMessageEvent(state),
		isEventsLoading: getIsThreadEventsLoading(state)
	}),
	{
		retrieveThreadEvents,
		resetThreadEvents,
		updateThreadMessageRead,
		retrieveThreadById,
		retrieveThreadByIdCycleStart,
		retrieveThreadByIdCycleStop,
		resetThread
	}
)(MessagesThread);
