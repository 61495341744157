import * as React from 'react';
import { connect } from 'react-redux';
import { Col } from 'components/antd';
import { Flex } from 'components';
import { getFiltersResultByKey } from 'store/filters/filtersSelectors';
import { PORT_MESSAGES_FILTER } from 'store/portJobs/portCallMessagesFiltersSync';
import MessagesList from './MessagesList/MessagesList';
import styles from './Messages.module.scss';
import { getActivePortJobThreadId } from 'store/portJobs/selectors';
import MessagesThreadContainer from './MessagesThread/MessagesThreadContainer';
import { AppState } from 'store-types';
import { FilterItem, FilterState } from 'store/filters/filtersState';

interface MessagesLayoutProps {
	onChange: (filters: FilterState, override?: boolean) => void;
	clearOne: (filter: FilterItem) => void;
	clearAll: () => void;
	filtersResult: FilterItem[];
	activeThreadId: string;
}

export const MessagesLayout: React.SFC<MessagesLayoutProps> = ({
	activeThreadId,
	onChange,
	clearOne,
	clearAll,
	filtersResult
}) => (
	<Flex fit>
		<Col xs={3} gutter={false}>
			<MessagesList
				onChange={onChange}
				clearOne={clearOne}
				clearAll={clearAll}
				filtersResult={filtersResult}
			/>
		</Col>
		<Col xs={9} gutter={false} className={styles.eventsList}>
			{activeThreadId && (
				<MessagesThreadContainer activeThreadId={activeThreadId} />
			)}
		</Col>
	</Flex>
);

export default connect((state: AppState) => ({
	activeThreadId: getActivePortJobThreadId(state),
	filtersResult: getFiltersResultByKey(state, PORT_MESSAGES_FILTER)
}))(MessagesLayout);
