import { Stage } from 'konva/types/Stage';
import { Vector2d } from 'konva/types/types';
import { v4 as uuidv4 } from 'uuid';
import { LineNode, RectNode } from './ImageViewerV2Types';

export const getScaledPoint = (stage: Stage, scale: number) => {
	const { x, y } = stage.getPointerPosition() as Vector2d;
	return { x: x / scale, y: y / scale };
};

export const getEmptyRect = (color = '#338adb') => ({
	x: -2,
	y: -2,
	width: 0,
	height: 0,
	id: uuidv4(),
	rotation: 0,
	color
});

export const getEmptyLine = (color = '#338adb') => ({
	x: 0,
	y: 0,
	id: uuidv4(),
	color,
	points: [],
	rotation: 0
});

export const getEmptyTextNode = (color = '#338adb') => ({
	text: '',
	color,
	fontSize: 16,
	fontStyle: 'normal',
	width: 350,
	id: uuidv4(),
	rotation: 0,
	textDecoration: '',
	align: 'left'
});

export const isRectEmpty = (
	oldDimensions: RectNode,
	newDimensions: { x: number; y: number }
) =>
	!!(
		Math.abs(newDimensions.x - oldDimensions.x) <= 5 ||
		Math.abs(newDimensions.y - oldDimensions.y) <= 5
	);

export const getRectDimensions = (
	rect: RectNode,
	newDimensions: { x: number; y: number }
): RectNode => {
	const width = newDimensions.x - rect.x;
	const height = newDimensions.y - rect.y;
	if (width < 0 && height < 0) {
		return {
			...rect,
			width: Math.abs(width),
			height: Math.abs(height),
			x: newDimensions.x,
			y: newDimensions.y
		};
	} else if (width < 0) {
		return {
			...rect,
			width: Math.abs(width),
			height: Math.abs(height),
			x: newDimensions.x,
			y: rect.y
		};
	} else if (height < 0) {
		return {
			...rect,
			width: Math.abs(width),
			height: Math.abs(height),
			x: rect.x,
			y: newDimensions.y
		};
	}
	return {
		...rect,
		width,
		height
	};
};

export const isLineNonEmpty = (lineNode: LineNode) =>
	lineNode.points.length > 2;
