import { Success, Failure } from 'typescript-fsa';
import { MessagesState } from '../messagesState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse
} from 'services/api/messages/messagesServiceTypes';
import { ValidationStatus } from 'services/api/documents/documentsServiceTypes';

export const STATUS_MESSAGE = {
	[ValidationStatus.UN_SAFE]:
		'File is being checked for viruses. Please try to download it later.',
	[ValidationStatus.QUARANTINE]:
		'Virus threat has been detected. Please contact your ISS representative for assistance.'
};

const onRetrieveMessageAttachmentStarted = (
	state: MessagesState,
	params: RetrieveMessageAttachmentRequest
): MessagesState => ({
	...state,

	attachmentsByIdFetchStatuses: {
		...state.attachmentsByIdFetchStatuses,
		[params.attachmentId]: FetchStatus.PENDING
	}
});

const onRetrieveMessageAttachmentSuccess = (
	state: MessagesState,
	{
		params,
		result
	}: Success<
		RetrieveMessageAttachmentRequest,
		RetrieveMessageAttachmentResponse
	>
): MessagesState => ({
	...state,

	attachmentsById: {
		...state.attachmentsById,
		[result.id]: result
	},

	attachmentsByIdStatusMessage: {
		...state.attachmentsByIdStatusMessage,
		[result.id]: STATUS_MESSAGE[result.status]
	},

	attachmentsByIdFetchStatuses: {
		...state.attachmentsByIdFetchStatuses,
		[params.attachmentId]: FetchStatus.SUCCESS
	}
});

const onRetrieveMessageAttachmentFailed = (
	state: MessagesState,
	{ params }: Failure<RetrieveMessageAttachmentRequest, Error>
): MessagesState => ({
	...state,

	attachmentsByIdFetchStatuses: {
		...state.attachmentsByIdFetchStatuses,
		[params.attachmentId]: FetchStatus.FAILURE
	}
});

export {
	onRetrieveMessageAttachmentStarted,
	onRetrieveMessageAttachmentSuccess,
	onRetrieveMessageAttachmentFailed
};
