import * as React from 'react';
import Button, { ButtonProps } from 'components/antd/Button/Button';
import { ButtonType } from 'components/types';
import { UploadProps } from 'antd/lib/upload/Upload';
import UploadWithValidation from './UploadWithValidation';
import { UploadType } from 'services/api/documents/documentsServiceTypes';
export { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';

export interface Config {
	timeout: number;
	xsrfCookieName: string;
	xsrfHeaderName: string;
	maxContentLength: number;
	headers: {
		accept: string;
		'content-type': string;
		authorization: string;
	};
	baseURL: string;
	method: string;
	url: string;
	data: string;
	transformRequest(data: {}, headers: {}): void;
	transformResponse(data: {}, headers: {}): void;
}

type UploadCustomProps = UploadProps & {
	uploadType?: UploadType;
	preventDefaultBeforeUpload?: boolean;
};

export type UploadButtonProps = UploadCustomProps &
	Partial<Pick<ButtonProps, 'loading' | 'tooltip'>> & {
		buttonType?: ButtonType;
		buttonGhost?: ButtonProps['ghost'];
		link?: boolean;
	};

export class Upload extends React.Component<UploadCustomProps> {
	static Dragger: React.FC<UploadProps> = props => (
		<UploadWithValidation {...props} isDragger />
	);
	static Button: React.ComponentType<UploadButtonProps> = ({
		buttonType,
		buttonGhost,
		loading,
		link,
		tooltip,
		...uploadProps
	}) => (
		<UploadWithValidation {...uploadProps}>
			<Button
				type={buttonType}
				ghost={buttonGhost}
				loading={loading}
				transparent={link}
				disabled={uploadProps.disabled}
				tooltip={tooltip}
			>
				{uploadProps.children}
			</Button>
		</UploadWithValidation>
	);

	render() {
		return <UploadWithValidation {...this.props} />;
	}
}

export default Upload;
