import {
	UpdateUnitCostForm,
	UpdateUnitCostFormPayload,
	UpdateUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const UPDATE_UNIT_COST_FORM = 'UPDATE_UNIT_COST_FORM';
export const updateUnitCostFormAsync = actionCreator.async<
	UpdateUnitCostForm,
	UpdateUnitCostFormResponse,
	Error
>(UPDATE_UNIT_COST_FORM);

export const updateUnitCostForm = actionCreator<{
	jobServiceId: string;
	id: string;
	formValues: UpdateUnitCostFormPayload;
}>(UPDATE_UNIT_COST_FORM);
