import React from 'react';
import { PortJobCargoLineDischarge } from 'services/api/portJobs/portJobsServiceTypes';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import Terms from './Terms';
import { Amount, SplitIndex, Text } from 'components';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import FormattedTime from 'containers/FormattedTime/FormattedTime';
import { Icon } from 'components/antd';

export default (operationTypeCode: OperationTypeCode) => {
	const includeDischargingToVessel =
		operationTypeCode === OperationTypeCode.DISCHARGING_STS;
	const columns: Array<ColumnProps<PortJobCargoLineDischarge>> = [
		{
			title: 'Cargo #',
			dataIndex: 'referenceNumber',
			render: (text, record) => (
				<>
					<OptionalValue value={text} />
					{record.splitIndex && <SplitIndex splitIndex={record.splitIndex} />}
				</>
			),
			width: '10%'
		},
		{
			title: 'Voyage Ref',
			dataIndex: 'principalVoyageReference',
			render: text => <OptionalValue value={text} />,
			width: '10%'
		},
		{
			title: 'Commodity detail',
			dataIndex: 'commodity.name',
			render: (commodity: string, record: PortJobCargoLineDischarge) =>
				commodity ? commodity : record.mainCommodity.name,
			width: '15%'
		},
		{
			title: 'Nomination Qty',
			dataIndex: 'commodityQuantity',
			render: (
				commodityQuantity: number,
				record: PortJobCargoLineDischarge
			) => {
				return (
					<OptionalValue value={commodityQuantity}>
						<Amount
							value={commodityQuantity}
							uomCode={record.commodityUnitOfMeasurementCode}
						/>
					</OptionalValue>
				);
			},
			width: includeDischargingToVessel ? '10%' : '15%'
		},
		{
			title: 'Receiver',
			dataIndex: 'receiverCompany.name',
			render: text => <OptionalValue value={text} />,
			width: includeDischargingToVessel ? '10%' : '15%'
		},
		{
			title: <Text nowrap>Charter Party Date</Text>,
			dataIndex: 'charterPartyDate',
			render: (time: string) => (
				<OptionalValue value={time}>
					<Text nowrap>
						<FormattedTime value={time} format="DD MMM YYYY" />
					</Text>
				</OptionalValue>
			),
			width: '10%'
		},
		{
			title: 'Discharging To',
			dataIndex: 'dischargingToVessel.name',
			key: 'dischargingToVessel',
			render: text => <OptionalValue value={text} />,
			width: includeDischargingToVessel ? '10%' : '0%'
		},
		{
			title: 'Load Port',
			dataIndex: 'loadPort.name',
			render: text => <OptionalValue value={text} />,
			width: '10%'
		},
		{
			title: 'Options/Terms',
			dataIndex: 'terms',
			render: terms => (
				<OptionalValue value={terms}>
					<Terms terms={terms} />
				</OptionalValue>
			),
			width: '15%'
		},
		{
			title: '',
			dataIndex: 'isLinkedCargo',
			render: (isLinkedCargo: boolean) =>
				isLinkedCargo && <Icon type="link" color="primary" />,
			width: '2%'
		}
	];
	if (!includeDischargingToVessel) {
		return columns.filter(item => item.key !== 'dischargingToVessel');
	}
	return columns;
};
