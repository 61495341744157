import { sortBy } from 'lodash';
import { AppState } from 'store-types';
import { getIsLoaded } from 'store/selectors';
import { createSelector } from 'reselect';
import {
	PortCallSection,
	PortCallEventTemplate
} from 'services/api/portCall/portCallServiceTypes';
import { EventTemplatesMap } from '../portCallOperationsState';
import { portCallSectionKey } from '../portCallOperationsUtils';

export const getEventTemplatesMap = (state: AppState, portCallId: string) =>
	state.portCallOperations.eventTemplates[portCallId];

export const getEventTemplatesMapBySectionId = (
	state: AppState,
	portCallId: string,
	section: PortCallSection
) => {
	const eventTemplates = getEventTemplatesMap(state, portCallId);
	return (
		eventTemplates &&
		eventTemplates[portCallSectionKey({ id: section.id, code: section.code })]
	);
};

const getEventTemplatesFetchStatusBySectionId = (
	state: AppState,
	portCallId: string,
	section: PortCallSection
) => {
	const fetchStatuses =
		state.portCallOperations.fetchStatuses.eventTemplates[portCallId];
	return (
		fetchStatuses &&
		fetchStatuses[portCallSectionKey({ id: section.id, code: section.code })]
	);
};

const getSortedEventTemplates = (eventsMap: EventTemplatesMap) =>
	sortBy<PortCallEventTemplate>(
		Object.values(eventsMap || {}),
		event => event.name
	);

export const makeGetSortedEventTemplatesBySectionId = () =>
	createSelector(
		[getEventTemplatesMapBySectionId],
		getSortedEventTemplates
	);

export const getIsEventTemplatesByIdLoaded = createSelector(
	[getEventTemplatesFetchStatusBySectionId],
	fetchStatus => getIsLoaded(fetchStatus)
);
