import * as React from 'react';
import { difference } from 'lodash';
import { Title, ScrollableLayout } from 'components';
import { Select, Table } from 'components/antd';
import styles from './UserGroups.module.scss';
import { GroupUser } from 'services/api/users/userServiceTypes';
import { getUserGroupsColumns } from './UserGroupsColumns';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

interface IUserGroupsProps {
	availableGroups: GroupUser[];
	assignedGroups: GroupUser[];
	onAdd: (g: GroupUser) => void;
	onDelete: (g: GroupUser) => void;
	isUserInMessagingTeam: boolean;
	profilePage: boolean;
}

class UserGroups extends React.Component<IUserGroupsProps> {
	getSelectedGroup = (groupId: string) =>
		this.props.availableGroups.find(group => group.id === groupId);

	isAlreadyAdded = (groupId: string) =>
		this.props.assignedGroups.some(group => group.id === groupId);

	onSelect = (groupId: string) => {
		// Since we disable selected options,
		// we do not need to do additional check for being already added
		const newGroup = this.getSelectedGroup(groupId);
		if (newGroup) {
			this.props.onAdd(newGroup);
		}
	};

	onDeleteGroup = (group: GroupUser) => {
		this.props.onDelete(group);
	};

	getUserGroupType = () =>
		this.props.assignedGroups.length
			? this.props.assignedGroups[0].groupType.code
			: null;

	getFilteredGroups = () =>
		difference(this.props.availableGroups, this.props.assignedGroups);

	isOptionDisabled = (groupType: GroupTypeCodes) =>
		this.props.assignedGroups.length
			? groupType !== this.getUserGroupType()
			: false;

	render() {
		const { assignedGroups, availableGroups } = this.props;
		const columns = getUserGroupsColumns(
			this.onDeleteGroup,
			assignedGroups,
			this.props.isUserInMessagingTeam,
			this.props.profilePage
		);
		const filteredGroups = this.getFilteredGroups();

		return (
			<ScrollableLayout className={styles.root}>
				<header>
					<Title.H4>User Groups</Title.H4>
					{!this.props.profilePage && (
						<Select
							disabled={!filteredGroups.length}
							className={styles.groupsSelector}
							placeholder="Select to add"
							onSelect={this.onSelect}
						>
							{availableGroups.map(option => (
								<Select.Option
									disabled={
										this.isOptionDisabled(option.groupType.code) ||
										this.isAlreadyAdded(option.id)
									}
									key={option.id}
								>
									{option.name}
								</Select.Option>
							))}
						</Select>
					)}
				</header>
				<ScrollableLayout>
					<Table
						dataSource={assignedGroups}
						columns={columns}
						pagination={false}
						bordered={false}
						locale={{
							emptyText: `No groups assigned`
						}}
					/>
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}

export default UserGroups;
