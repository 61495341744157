import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { select, call } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveSupplierInvoicesAsync } from '../actions/retrieveSupplierInvoices';
import Api from 'services/api';
import {
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { SagaIterator } from 'redux-saga';
import { getSupplierInvoicesCount } from '../selectors';
import getSupplierInvoicesFiltersSaga from './getSupplierInvoicesFiltersSaga';

export default makeTakeLatestWatcher<
	RetrieveSupplierInvoicesParams,
	RetrieveSupplierInvoicesResponse,
	Error
>({
	async: retrieveSupplierInvoicesAsync,
	api: Api.SupplierInvoice.retrieveSupplierInvoices,
	*getApiParams(params: RetrieveSupplierInvoicesParams): SagaIterator {
		const count = yield select(getSupplierInvoicesCount);
		const { preventConcatExistingInvoices, ...apiParams } = params;

		const filters = yield call(getSupplierInvoicesFiltersSaga);

		const request: RetrieveSupplierInvoicesParams = {
			index: count,
			limit: DEFAULT_LIST_LIMIT,
			...filters,
			...apiParams
		};

		return request;
	}
});
