import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import client from 'services/client';
import {
	AxiosTypedPromise,
	ResultCollection,
	SearchRequest,
	SearchResultCollection
} from 'services/api/apiTypes';
import {
	RetrieveCompanyVesselRequest,
	VesselDetails
} from './vesselsServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

interface SearchResultItem {
	id: string;
	name: string;
	imo: string;
}

const retrieveVessel = (vesselId: string) => client.get(`/vessels/${vesselId}`);
const retrieveVesselExtend = ({
	vesselId,
	companyId
}: RetrieveCompanyVesselRequest) =>
	client.get(`vessels/${vesselId}/${companyId}`);

const searchVessels = (
	request: SearchRequest
): AxiosTypedPromise<SearchResultCollection<string, SearchResultItem>> => {
	return client.get('/vessels/search', {
		params: {
			limit: DEFAULT_LIST_LIMIT,
			...request
		}
	});
};

const searchVesselsForAutocomplete = (searchTerm: string) =>
	searchVessels({
		searchTerm
	}).then((response): AutocompleteOption[] =>
		response.data.results.map(item => ({
			...item,
			name: `${item.name} (${item.imo})`
		}))
	);

export const searchVesselsForCompanyVesselsAutocomplete = (
	searchTerm: string
) =>
	client
		.get<ResultCollection<VesselDetails>>('/vessels', {
			params: {
				searchTerm
			}
		})
		.then((response): VesselDetails[] => response.data.elements);

export default {
	searchVessels,
	retrieveVessel,
	retrieveVesselExtend,
	searchVesselsForAutocomplete,
	searchVesselsForCompanyVesselsAutocomplete
};
