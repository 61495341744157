import { Entity, EntityFlexible, Metadata, MetadataAction } from 'app-types.d';
import {
	PagedRequest,
	ResultCollection,
	SearchResultCollection
} from 'services/api/apiTypes';
import { SupplierInvoicesGroupType } from 'store/supplierInvoice/filtersSync';
import { FinanceTransaction } from '../finance/financeServiceTypes';
import { PageType } from 'store/finance/constants';

export interface SupplierInvoiceBase {
	id: string;
	isCredit?: boolean;
	isCancelled?: boolean;
	mainPrincipalCompany: Entity;
	supplierCompany: Entity;
	glNumber?: string;
	glDate?: string;
	referenceNumber: string;
	receiptDate: string;
	invoiceDate: string;
	currencyCode: string;
	discount: number;
	total: number;
	comment: string;
	portJobCodes: string[];
	transactionsDetails: FinanceTransaction[];
	metadata: Metadata<SupplierInvoiceActionCode>;
}

export enum SupplierInvoiceActionCode {
	EDIT = 'editSupplierInvoice',
	CANCEL_CREDIT_SUPPLIER_INVOICE = 'cancelCreditSupplierInvoice',
	CREATE_CREDIT_INVOICE_BASED_ON_REGULAR = 'createCreditSupplierInvoiceBasedOnRegularOne'
}

export type SupplierInvoiceAction = MetadataAction<SupplierInvoiceActionCode>;

/**
 * Supplier Invoice used as a Row of a Table
 */
export interface SupplierInvoiceRow extends SupplierInvoiceBase {
	metadata: Metadata<SupplierInvoiceActionCode>;
	split?: boolean;
}

/**
 * Detailed view of a Supplier Invoice
 */
export interface SupplierInvoice extends SupplierInvoiceBase {
	document: SupplierInvoiceDocument;
	contextualizations: SupplierInvoiceContextualizationGetResponse;
}

export interface RetrieveSupplierInvoicesParams extends PagedRequest {
	preventConcatExistingInvoices?: boolean;
}

export interface RetrieveSupplierInvoicesResponse
	extends ResultCollection<SupplierInvoiceRow> {}

/**
 * Single Supplier Invoice
 */
export type SupplierInvoiceServiceDocument = Required<
	Pick<SupplierInvoiceDocument, 'name' | 'downloadUrl'>
>;
export type RetrieveSupplierInvoiceParams = string;
export interface RetrieveSupplierInvoiceResponse extends SupplierInvoice {}
export interface RetrieveSupplierInvoiceServiceDocumentRequest {
	supplierInvoiceId: string;
	serviceId: string;
}

export type RetrieveSupplierInvoiceServiceDocumentResponse = SupplierInvoiceServiceDocument;

export interface SupplierInvoiceDocument {
	downloadUrl: string;
	documentId: string;
	isReady?: boolean;
	size?: number;
	name?: string;
	contentType?: string;
	updatedOn?: string;
}

interface CreateInvoicePortJobService {
	id: string;
	totalAmount: number;
	comment?: string;
}

interface CreateInvoicePortJob {
	id: string;
	services: CreateInvoicePortJobService[];
}

export interface ContextualizeSupplierInvoice {
	number: number;
	portJobs: CreateInvoicePortJob[];
}

export interface BaseSupplierInvoiceRequest {
	isCredit: boolean;
	mainPrincipalCompany: { id: string };
	supplierCompany: { id: string };
	referenceNumber: string;
	receiptDate: string;
	invoiceDate: string;
	currencyCode: string;
	discount?: number;
	comment?: string;
	total: number;
	contextualizations: ContextualizeSupplierInvoice[];
}

export interface AddSupplierInvoiceRequest extends BaseSupplierInvoiceRequest {
	documentId: string;
	originalSupplierInvoiceId?: string;
}

export interface UpdateSupplierInvoiceRequest
	extends BaseSupplierInvoiceRequest {
	supplierInvoiceId: string;
	documentId: string;
}

export interface CancelCreditSupplierInvoiceRequest {
	supplierInvoiceId: string;
}

export enum SupplierInvoicesPortJobServiceActionCode {
	CONTEXTUALIZE = 'contextualize'
}
export type SupplierInvoicesPortJobServiceAction = MetadataAction<
	SupplierInvoicesPortJobServiceActionCode
>;

export interface SupplierInvoicePortJobService {
	id: string;
	name: string;
	totalAmount: number;
	comment?: string;
	pages: Array<number>;
	inApprovalProcess?: boolean;
	associatedToSupplierInvoiceId?: string;
	metadata: Metadata<SupplierInvoicesPortJobServiceActionCode>;
}

export interface SupplierInvoicePortJob {
	id: string;
	code: string;
	portCallId: string;
	port: string;
	vessel: string;
	eta: string;
	eosp?: string;
	cosp: string;
	ets?: string;
	services: SupplierInvoicePortJobService[];
}

/**
 * Retrieve during Add Supplier Invoice
 */
export interface RetrieveSupplierInvoiceContextualizationRequest {
	mainPrincipalId: string;
	supplierId: string;
	currencyCode: string;
}
export interface RetrieveSupplierInvoiceContextualizationResponse
	extends ResultCollection<SupplierInvoicePortJob> {}

/**
 * Retrieve during Update Supplier Invoice
 */
export interface RetrieveSupplierInvoiceContextualizationForUpdateRequest
	extends RetrieveSupplierInvoiceContextualizationRequest {
	supplierInvoiceId: string;
	isFromRefresh: boolean;
}
export interface RetrieveSupplierInvoiceContextualizationForUpdateResponse
	extends RetrieveSupplierInvoiceContextualizationResponse {}

/**
 * Search
 */

export interface SearchSupplierInvoicesResponse {
	groups: Array<
		SearchResultCollection<SupplierInvoicesGroupType, EntityFlexible>
	>;
}

/**
 * Download
 */
export interface DownloadSupplierInvoiceDocumentParams {
	supplierInvoiceId: string;
	serviceId?: string;
	isMultiFileDownload?: boolean;
}

export interface DownloadSupplierInvoiceDocumentRequest {
	supplierInvoiceId: string;
}

export interface DownloadForServiceSupplierInvoiceDocumentRequest {
	supplierInvoiceId: string;
	serviceId: string;
}

/**
 * Contextualizations
 */
export interface SupplierInvoiceContextualizationGetResponse
	extends ResultCollection<SupplierInvoicePortJob> {}

export type RestoreOriginalSupplierInvoiceDocumentRequest = RetrieveSupplierInvoiceServiceDocumentRequest;

export interface DeleteSupplierDocumentPageRequest {
	supplierInvoiceId: string;
	serviceId: string;
	actualNumber: number;
	pageType: PageType;
	documentId: string;
}
