import React from 'react';
import {
	TimeBar,
	MaxPortCost
} from 'services/api/portJobs/portJobsServiceTypes';
import { Row, Col, Icon, Tag } from 'components/antd';
import { Text } from 'components';
import styles from './Details.module.scss';

interface DetailsProps {
	timeBar?: TimeBar;
	maxPortCost?: MaxPortCost;
	costSettlementIsDirect?: boolean;
	isWorldScaleServicesApplied?: boolean;
	isOtherRebillablesApplied?: boolean;
}
export default ({
	timeBar,
	maxPortCost,
	isWorldScaleServicesApplied,
	isOtherRebillablesApplied,
	costSettlementIsDirect
}: DetailsProps) => (
	<div>
		<Row>
			{isWorldScaleServicesApplied && (
				<Col sm={6}>
					<div>
						<span>
							<Icon type="check" color="standard" /> Worldscale Applicable
						</span>
					</div>
				</Col>
			)}
			{isOtherRebillablesApplied && (
				<Col sm={6}>
					<div>
						<span>
							<Icon type="check" color="standard" /> Other Rebillables
						</span>
					</div>
				</Col>
			)}
			{timeBar?.applies && (
				<Col sm={6}>
					<div>
						<span>
							<Icon type="check" color="standard" /> Time Bar Applies
						</span>
					</div>
					<Text.Box left="md">
						<strong>{timeBar.days} Days</strong>
					</Text.Box>
				</Col>
			)}
			{maxPortCost?.applies && (
				<Col sm={6}>
					<div>
						<span>
							<Icon type="check" color="standard" /> Max Port Cost
						</span>
					</div>
					<Text.Box left="md">
						<strong>
							{maxPortCost.amount} {maxPortCost.currencyCode}
						</strong>
					</Text.Box>
				</Col>
			)}
		</Row>
		{(isOtherRebillablesApplied || isWorldScaleServicesApplied) && (
			<Row className={styles.lastRow}>
				<Col sm={12}>
					<span>
						<strong>Charterer Cost Settlement </strong>
						<Tag borderless size="xs" bold className={styles.tag}>
							<Text upper>
								{costSettlementIsDirect ? 'Direct' : 'Via Principal'}
							</Text>
						</Tag>
					</span>
				</Col>
			</Row>
		)}
	</div>
);
