import { RequestPDARequest } from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

const ACTION_NAME = 'PDA_REQUEST';

export const requestPDAAsync = actionCreator.async<
	RequestPDARequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412, 400]
	},
	templateParams: {
		entityName: 'PDA request'
	}
});

export const requestPDA = actionCreator<void>(ACTION_NAME);
