import { Success } from 'typescript-fsa';
import { ConfigurationFilesState } from '../configurationFilesState';
import {
	AddCompanyTemplateFileRequest as Request,
	AddCompanyTemplateFileResponse as Response
} from 'services/api/templates/templatesServiceTypes';

export const onAddConfigurationFileSuccess = (
	state: ConfigurationFilesState,
	{ result }: Success<Request, Response>
): ConfigurationFilesState => ({
	...state,
	allIds: [result.id, ...state.allIds],
	byId: {
		...state.byId,
		[result.id]: result
	}
});
