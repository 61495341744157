import React from 'react';
import { connect } from 'react-redux';
import { getUserType } from 'store/auth/selectors/user';
import { UserType } from 'services/api/users/userServiceTypes';
import { isArray } from 'lodash';
import { AlertProps } from 'components/antd/Alert/Alert';
import {
	ActionAlertButtonProps,
	EmailLinkProps
} from 'components/ActionAlert/ActionAlert';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getPermissionCodes } from 'store/auth/selectors/permissionsSelector';
import { ActionAlert } from 'components';
import { AppState } from 'store-types';

export enum ContextBarType {
	ACTION = 'Action',
	CONFIRMATION = 'Confirmation',
	INFO = 'Info'
}

interface ContextBarProps {
	className?: string;
	buttonProps?: ActionAlertButtonProps;
	emailLinkProps?: EmailLinkProps;
	title: string;
	subtitle?: React.ReactNode;
	actor: UserType | UserType[];
	condition: boolean;
	type: ContextBarType;
	permissionCode?: PermissionCode;
	// for logic "at least one of"
	permissionCodes?: PermissionCode[];

	userType: UserType;
	userPermissionCodes: PermissionCode[];
}

const typeMap = {
	[ContextBarType.ACTION]: 'warning',
	[ContextBarType.CONFIRMATION]: 'success',
	[ContextBarType.INFO]: 'info'
};

const ContextBar: React.FC<ContextBarProps> = (props: ContextBarProps) => {
	const {
		subtitle,
		type,
		condition,
		actor,
		userType,
		buttonProps,
		emailLinkProps,
		title,
		permissionCode,
		className,
		permissionCodes,
		userPermissionCodes
	} = props;
	const isBarVisible =
		// visibility by specific condition
		condition &&
		// visibility by user type
		(isArray(actor) ? actor.includes(userType) : actor === userType) &&
		// visibility by functional permissons
		(permissionCodes
			? permissionCodes.some(code => userPermissionCodes.includes(code))
			: true) &&
		(permissionCode ? userPermissionCodes.includes(permissionCode) : true);
	if (!isBarVisible) {
		return null;
	}
	return (
		<ActionAlert
			className={className}
			title={title}
			subtitle={subtitle}
			type={typeMap[type] as AlertProps['type']}
			buttonProps={buttonProps}
			emailLinkProps={emailLinkProps}
		/>
	);
};

export default connect((state: AppState) => ({
	userType: getUserType(state),
	userPermissionCodes: getPermissionCodes(state)
}))(ContextBar);
