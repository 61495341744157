import { retrieveCompaniesContactDetailsAsync } from 'store/companies/actions/retrieveCompaniesContactDetails';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompaniesContactDetailsRequest,
	RetrieveCompaniesContactDetailsResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompaniesContactDetailsRequest,
	RetrieveCompaniesContactDetailsResponse,
	Error
>({
	api: Api.Companies.retrieveCompaniesContactDetails,
	async: retrieveCompaniesContactDetailsAsync
});
