import { AppState } from 'store-types';
import { setFilters } from 'store/filters/actions';

export const DIRECT_BILLS_FILTER_NAME = 'directBills';

export enum DirectBillsFiltersParam {
	STATUS = 'status',
	SEARCH = 'search'
}

const filtersSync = {
	[DIRECT_BILLS_FILTER_NAME]: {
		selector: (state: AppState) => state.filters[DIRECT_BILLS_FILTER_NAME],
		action: setFilters,
		stringToValue: (value: string) => {
			try {
				return {
					name: DIRECT_BILLS_FILTER_NAME,
					filters: JSON.parse(value)
				};
			} catch (error) {
				return {};
			}
		},
		valueToString: (value: object) => JSON.stringify(value)
	}
};

export default filtersSync;
