import { FormErrors } from 'redux-form';
import runValidations, {
	charMax,
	validateRequired,
	hasNumber,
	minValue,
	required
} from 'utils/validations';
import { CostSavingsFormData } from 'sections/PortCall/Savings/SavingsGrid/Constants';
import { first } from 'lodash';

const validate = (
	values: CostSavingsFormData
): FormErrors<CostSavingsFormData> => {
	const errors: FormErrors<CostSavingsFormData> = {
		category: validateRequired(values.category),
		services: validateRequired(values.services),
		savingsBy: validateRequired(values.savingsBy),
		currency: validateRequired(values.currency),
		amountSaved: first(
			runValidations(values.amountSaved, [hasNumber, minValue(0), required])
		),
		comments: charMax(1000)(values.comments)
	};

	return errors;
};

export default validate;
