import { getEditableOperation } from 'store/portJobs/portJobsSelectors';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { mapOperationForReduxForm } from './mapOperationForReduxFormSelectors';
import { getValues } from 'store/form/selectors';
import {
	OperationFormTypes,
	BunkeringOperationForm,
	CashToMasterOperationForm,
	CrewWageDisbursementOperationForm,
	TotalAmountFormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { isNull } from 'lodash';
import { getCountriesMap } from 'store/countries/countriesSelectors';
import { nameCodeToLabelField } from 'store/portcalls/sagas/addPortCall/prepareRequest/prepareRequest.func';

export const getOperationInitialValues = createSelector(
	[(state: AppState) => state, getEditableOperation],
	mapOperationForReduxForm
);

export const getIsEditablePortJobCompanies = createSelector(
	getEditableOperation,
	operation => operation?.portJobCompanies || []
);

export const getEditableCargoId = (state: AppState) =>
	state.portCall.context.editableCargoId;

export const getEditableGradeId = (state: AppState) =>
	state.portCall.context.editableGradeId;

export const getEditableTotalAmountId = (state: AppState) =>
	state.portCall.context.editableTotalAmountId;

const getPortJobFormValues = (state: AppState) =>
	getValues<OperationFormTypes>(state, FORM.portJob);

const getPortJobBunkeringFormValues = (state: AppState) =>
	getValues<BunkeringOperationForm>(state, FORM.portJob);

export const getIsCargoLineFormInEditMode = (state: AppState) =>
	!!state.portCall.context.editableCargoId;

export const getIsGradeLineFormInEditMode = (state: AppState) =>
	!!state.portCall.context.editableGradeId;

export const getIsTotalAmountLineFormInEditMode = (state: AppState) =>
	!!state.portCall.context.editableTotalAmountId;

export const getLoadCargoLineInitialValues = createSelector(
	getEditableCargoId,
	getPortJobFormValues,
	getCountriesMap,
	(cargoId, operationInitialValues, countriesByCode) => {
		if (!operationInitialValues || !operationInitialValues.cargoes) {
			return {};
		}
		const cargoLine = operationInitialValues.cargoes.find(
			cargo => cargo.id === cargoId
		);
		if (!cargoLine) {
			return {};
		}
		/**
		 * Checking both field "dischargeCountryCode" and "dischargeCountry"
		 * is needed to handle initial values for the form after editing the cargo line of the just saved operation
		 * and for editing the cargo line of not yet saved operation ("dischargeCountryCode" is missing in such a case)
		 */
		const countryCode =
			cargoLine.dischargeCountryCode ||
			(cargoLine.dischargeCountry && cargoLine.dischargeCountry.key);
		return (
			cargoLine && {
				...cargoLine,
				dischargeCountry:
					countryCode && countriesByCode[countryCode]
						? nameCodeToLabelField({
								code: countryCode,
								name: countriesByCode[countryCode].name
						  })
						: undefined
			}
		);
	}
);

export const getGradeLineInitialValues = createSelector(
	getEditableGradeId,
	getPortJobBunkeringFormValues,
	(gradeId, operationInitialValues) => {
		if (
			!operationInitialValues ||
			!operationInitialValues.bunkeringOperationGrades
		) {
			return {};
		}
		return operationInitialValues.bunkeringOperationGrades.find(
			grade => grade.id === gradeId
		);
	}
);

export const getTotalAmountLineInitialValues = createSelector(
	getEditableTotalAmountId,
	(state: AppState) =>
		getValues<CashToMasterOperationForm | CrewWageDisbursementOperationForm>(
			state,
			FORM.portJob
		),
	(totalAmountId, operationInitialValues): Partial<TotalAmountFormData> => {
		if (!operationInitialValues || !operationInitialValues.totalAmounts) {
			return {};
		}
		return (
			operationInitialValues.totalAmounts.find(
				totalAmount => totalAmount.id === totalAmountId
			) || {}
		);
	}
);

export const getSplitOperationIndex = createSelector(
	getPortJobFormValues,
	(_state: AppState, id: string) => id,
	(cargoOperation, id) => {
		if (!cargoOperation?.cargoes) {
			return null;
		}
		const currentCargo = cargoOperation.cargoes.find(cargo => cargo.id === id);
		if (!currentCargo?.splitIndex) {
			return null;
		}
		return currentCargo.splitIndex;
	}
);

export const getIsEditableCargoSplit = createSelector(
	getEditableCargoId,
	state => state,
	(id: string, state) => !isNull(getSplitOperationIndex(state, id))
);
