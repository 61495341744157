import { BreadcrumbItem } from 'store/navigation';

export enum FieldName {
	LABEL = 'label',
	BANK = 'bank',
	ADDRESS_LINE_1 = 'address.addressLine1',
	ADDRESS_LINE_2 = 'address.addressLine2',
	ADDRESS_LINE_3 = 'address.addressLine3',
	ADDRESS_LINE_4 = 'address.addressLine4',
	CITY = 'address.city',
	ZIP_CODE = 'address.postalCode',
	COUNTRY = 'address.countryCode',
	ACCOUNT_BENEFICIARY_NAME = 'bankAccountName',
	CURRENCY = 'bankAccountCurrency',
	ACCOUNT_NUMBER = 'bankAccountNumber',
	SORT_CODE = 'bankSortCode',
	IBAN = 'iban',
	SWIFT_BIC = 'bankSwiftCode',
	NOTES = 'notes',
	ABA = 'aba',
	CPF = 'cpf',
	INN = 'inn',
	BIK = 'bik',
	CNPJ = 'cnpjCode',
	BSB = 'bsb',
	AGENCIA_NUMBER = 'agencyNumber',
	ROUTING_NUMBER = 'routingNumber',
	IFSC = 'ifc',
	PURPOSE_CODE = 'purposeCode',
	PURPOSE_REASON = 'purposeReason',
	TRANSACTION_PUPROSE = 'transactionPurpose'
}

export const title = {
	main: 'Bank Account Details',
	add: 'Add Bank Account Details'
};
export const url = {
	main: '/bank-account-details',
	add: '/bank-account-details/add'
};
export const breadCrumbs: { [name: string]: BreadcrumbItem[] } = {
	main: [{ title: title.main }],
	add: [{ title: title.main, link: url.main }, { title: title.add }]
};
export const BANK_ACCOUNT_FORM = 'BankAccountForm';
