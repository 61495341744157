import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'components/antd';
import {
	retrieveAvailableGroups,
	setDefaultMessagingGroup
} from 'store/portJobs/actions';
import {
	getPortJobAvailableGroupsSelectOptions,
	getActivePortJob,
	getDefaultMessagingGroupValueForActiveJob,
	getIsLoadingDefaultMessagingGroup,
	getIsUpdatingDefaultMessagingGroup,
	getIsActivePortJobCancelled
} from 'store/portJobs/selectors';
import { DEFAULT_SELECT_PLACEHOLDER } from 'app-constants';
import { AppState } from 'store-types';
import { LabeledValueItem } from 'app-types';
import styles from './DefaultMessagingGroupSelect.module.scss';

interface DefaultMessagingGroupSelectProps {
	portCallId: string;
	jobCode: string;
	disabled?: boolean;
	// from mapStateToProps
	portJobId: string;
	defaultMessagingGroupValue: LabeledValueItem | undefined;
	groups: LabeledValueItem[];
	isLoading: boolean;
	isUpdating: boolean;
	isPortJobCancelled: boolean;
	// from mapDispatchToProps
	retrieveAvailableGroups: typeof retrieveAvailableGroups;
	setDefaultMessagingGroup: typeof setDefaultMessagingGroup;
}

class DefaultMessagingGroupSelect extends React.Component<
	DefaultMessagingGroupSelectProps
> {
	componentDidMount() {
		if (!this.props.disabled) {
			this.retrieveAvailableGroups();
		}
	}

	componentDidUpdate(prevProps: DefaultMessagingGroupSelectProps) {
		if (prevProps.disabled && !this.props.disabled) {
			this.retrieveAvailableGroups();
		}
	}

	retrieveAvailableGroups = () => {
		const { portCallId, jobCode } = this.props;
		this.props.retrieveAvailableGroups({
			portCallId,
			jobCode
		});
	};

	setDefaultMesssagingGroup = (option: LabeledValueItem) => {
		const { portJobId } = this.props;
		this.props.setDefaultMessagingGroup({
			group: {
				id: option.key,
				name: option.label
			},
			portJobId
		});
	};

	render() {
		const {
			defaultMessagingGroupValue,
			groups,
			disabled,
			isLoading,
			isUpdating,
			isPortJobCancelled
		} = this.props;
		const value = !isLoading ? defaultMessagingGroupValue : undefined;
		const isFieldDisabled = disabled || isUpdating || isPortJobCancelled;
		return (
			<Select
				value={value}
				labelInValue
				placeholder={DEFAULT_SELECT_PLACEHOLDER}
				disabled={isFieldDisabled}
				isLoading={isLoading}
				onChange={this.setDefaultMesssagingGroup}
				containerClassName={styles.defaultMessagingGroupContainer}
				className={styles.defaultMessagingGroupDropdown}
			>
				{groups.map(group => (
					<Select.Option key={group.key} value={group.key} title={group.label}>
						{group.label}
					</Select.Option>
				))}
			</Select>
		);
	}
}

export default connect(
	(state: AppState) => {
		const activePortJob = getActivePortJob(state);
		return {
			portJobId: activePortJob.id,
			defaultMessagingGroupValue: getDefaultMessagingGroupValueForActiveJob(
				state
			),
			groups: getPortJobAvailableGroupsSelectOptions(state),
			isLoading: getIsLoadingDefaultMessagingGroup(state),
			isUpdating: getIsUpdatingDefaultMessagingGroup(state),
			isPortJobCancelled: getIsActivePortJobCancelled(state)
		};
	},
	{
		retrieveAvailableGroups,
		setDefaultMessagingGroup
	}
)(DefaultMessagingGroupSelect);
