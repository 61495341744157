import { ReducerRotationStep } from 'store/vesselProgramme/vesselProgrammeState';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { getRotationStepRecordedEvents } from '../selectors';
import { DeletePOIErrorMessages } from '../vesselProgrammeConstants';

export function* deletePOIForShiftingSaga(
	neighboringSteps: ReducerRotationStep[]
): SagaIterator {
	const [previous, next] = neighboringSteps;
	const neighborEvents = { previous: [], next: [] };
	const hasBothShiftings = previous && next;

	if (previous) {
		neighborEvents.previous = yield select(
			getRotationStepRecordedEvents,
			previous.id
		);
	}

	if (next) {
		neighborEvents.next = yield select(getRotationStepRecordedEvents, next.id);
	}

	const hasBothAndPreviousHasRecordedEvents = Boolean(
		hasBothShiftings && neighborEvents.previous.length
	);
	const hasPreviousEvents = Boolean(previous && neighborEvents.previous.length);
	const hasNextEvents = Boolean(next && neighborEvents.next.length);

	if (
		hasBothAndPreviousHasRecordedEvents ||
		(hasPreviousEvents && hasNextEvents) ||
		hasNextEvents
	) {
		throw new Error(DeletePOIErrorMessages.HAS_EVENTS_SURROUNDING);
	}
}
