import * as React from 'react';
import { connect } from 'react-redux';
import styles from './JobsTab.module.scss';
import Hooray from '../screens/Hooray';
import { Flex, Title, Loading } from 'components';
import { Button } from 'components/antd';
import { navigateTo } from 'utils';
import { getHasPermission } from 'store/auth/selectors/permissionsSelector';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Justify } from 'components/types';
import {
	getIsSectionsFirstLoad,
	getSectionsFetchStatus,
	getOrderedSections
} from 'store/dashboard/selectors';
import { FetchStatus } from 'services/api/apiTypes';
import {
	retrieveJobSections,
	retrieveJobSectionsCycleStart,
	retrieveJobSectionsCycleStop
} from 'store/dashboard/actions';
import { JobSection } from 'services/api/dashboard/dashboardServiceTypes';
import JobsCollapse from './JobsCollapse';
import JobModals from './JobModals';
import { AppState } from 'store-types';

interface JobsProps {
	// MapStateToProps
	isAddJobButtonVisible: boolean;
	isFirstLoad: boolean;
	sections: JobSection[];
	fetchStatus: FetchStatus;
	// MapDispatchToProps
	retrieveJobSections: typeof retrieveJobSections;
	retrieveJobSectionsCycleStart: typeof retrieveJobSectionsCycleStart;
	retrieveJobSectionsCycleStop: typeof retrieveJobSectionsCycleStop;
}
class Jobs extends React.PureComponent<JobsProps> {
	scrollableRef: HTMLDivElement | null = null;
	createJob = () => {
		navigateTo('/portcalls/new');
	};
	viewAll = () => {
		navigateTo('/portcalls');
	};
	componentDidMount() {
		this.props.retrieveJobSections();
		this.props.retrieveJobSectionsCycleStart();
	}
	componentWillUnmount() {
		this.props.retrieveJobSectionsCycleStop();
	}
	setScrollableRef = (el: HTMLDivElement) => {
		this.scrollableRef = el;
	};
	// We need to scroll to top on Section select. Might be removed in 1.1
	scrollToTop = () => {
		if (this.scrollableRef) {
			// scrollTo() does not work in MS Edge
			// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15534521/
			this.scrollableRef.scrollTop = 0;
		}
	};
	render() {
		const { fetchStatus, sections, isFirstLoad } = this.props;
		return (
			<div className={styles.jobs} ref={this.setScrollableRef}>
				<Flex
					className={styles.header}
					justify={Justify.BETWEEN}
					align="center"
					flex="0 1 auto"
				>
					<Title>JOBS REQUIRING ATTENTION</Title>
					<Flex self="end">
						{this.props.isAddJobButtonVisible && (
							<Button
								className={styles.addButton}
								type="primary"
								onClick={this.createJob}
							>
								Add new job
							</Button>
						)}
						<Button type="primary" ghost onClick={this.viewAll}>
							View all jobs
						</Button>
					</Flex>
				</Flex>
				<Flex flex="1 0 auto" direction="vertical" className={styles.jobsList}>
					{fetchStatus === FetchStatus.PENDING && isFirstLoad ? (
						<Loading />
					) : sections.length ? (
						<JobsCollapse sections={sections} scrollToTop={this.scrollToTop} />
					) : (
						<Hooray />
					)}
					<JobModals />
				</Flex>
			</div>
		);
	}
}
export default connect(
	(state: AppState) => ({
		isAddJobButtonVisible: getHasPermission(
			state,
			PermissionCode.MANAGE_PORTCALL
		),
		isFirstLoad: getIsSectionsFirstLoad(state),
		fetchStatus: getSectionsFetchStatus(state),
		sections: getOrderedSections(state)
	}),
	{
		retrieveJobSections,
		retrieveJobSectionsCycleStart,
		retrieveJobSectionsCycleStop
	}
)(Jobs);
