import * as React from 'react';

import { Button } from 'components/antd';
import { ButtonProps } from 'components/antd/Button/Button';

type ContextButtonProps = ButtonProps & {
	handleClickValue: string;
	handleClick: (handleClickValue: string, handleClickParams?: {}) => void;
	handleClickParams?: {};
};

/**
 * Sub Component
 * @see https://stackoverflow.com/a/29810951
 */
export default class ContextButton extends React.PureComponent<
	ContextButtonProps
> {
	handleClick: ButtonProps['onClick'] = e => {
		e.stopPropagation();
		this.props.handleClick(
			this.props.handleClickValue,
			this.props.handleClickParams
		);
	};

	render() {
		const {
			children,
			handleClick,
			handleClickValue,
			handleClickParams,
			...props
		} = this.props;
		return (
			<Button onClick={this.handleClick} {...props}>
				{this.props.children}
			</Button>
		);
	}
}
