import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import client from 'services/client';
import queryString from 'query-string';
import {
	AxiosTypedPromise,
	ResultCollection,
	SearchRequest,
	SearchResultCollection
} from 'services/api/apiTypes';
import {
	RetrievePortByIdRequest,
	RetrievePortByIdResponse,
	RetrieveAnchoragesRequest,
	RetrieveAnchoragesResponse,
	RetrieveBuoysRequest,
	RetrieveBuoysResponse,
	RetrieveCanalsRequest,
	RetrieveCanalsResponse,
	Port,
	SearchPortLPAsRequest,
	SearchPreferredPortLPAsRequest,
	CompanyAgentResponsibility,
	CompanyAgentAutocompleteOption
} from 'services/api/ports/portsServiceTypes';
import { GroupsResult } from 'components/AutocompleteSearch/AutocompleteSearchTypes';
import { PortCountryGroupType } from 'store/portcalls/filtersSync';

const retrievePorts = (): AxiosTypedPromise<ResultCollection<Port>> => {
	/**
	 * IPP-26140
	 * limit=10000 is applied because otherwise backend uses default limit which is 1k.
	 * And on production there are >4k ports. Anyway we're going to deprecate this endpoint
	 * in favor of ports/search later on
	 */
	return client.get('/ports', { params: { limit: 10000 } });
};

function searchPorts<T = Port>(request: SearchRequest) {
	return client.get<SearchResultCollection<string, T>>('/ports/search', {
		params: {
			...request
		},
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});
}

const searchPortsAndCountriesForAutocomplete = (
	searchTerm?: string,
	groupType?: PortCountryGroupType
) =>
	client.get<GroupsResult<PortCountryGroupType>>(
		'/masterdata/location/search',
		{
			params: {
				searchTerm,
				showMore: !!groupType,
				...(groupType && { groupType })
			}
		}
	);

const retrievePortLPAs = ({
	portId,
	...params
}: SearchPreferredPortLPAsRequest) =>
	client.get<ResultCollection<CompanyAgentResponsibility>>(
		`ports/${portId}/lpas`,
		{
			params
		}
	);

const searchPortLPAs = ({ portId, ...params }: SearchPortLPAsRequest) =>
	client.get<SearchResultCollection<string, CompanyAgentAutocompleteOption>>(
		`ports/${portId}/lpas/search`,
		{
			params
		}
	);

const searchPortLPAsForAutocomplete = (request: SearchPortLPAsRequest) =>
	searchPortLPAs(request).then(response => {
		return response.data.results;
	});

const retrievePortById = ({ port }: RetrievePortByIdRequest) =>
	client.get<RetrievePortByIdResponse>(`/ports/${port}`);

const retrieveAnchorages = (params: RetrieveAnchoragesRequest) => {
	const { port, ...pagingParams } = params;

	return client.get<RetrieveAnchoragesResponse>(`./ports/${port}/anchorages`, {
		params: pagingParams
	});
};

const retrieveBuoys = (params: RetrieveBuoysRequest) => {
	const { port, ...pagingParams } = params;

	return client.get<RetrieveBuoysResponse>(`./ports/${port}/buoys`, {
		params: pagingParams
	});
};

const retrieveCanals = (params: RetrieveCanalsRequest) => {
	const { port, ...paginParams } = params;

	return client.get<RetrieveCanalsResponse>(`/ports/${port}/canals`, {
		params: paginParams
	});
};

const searchPortsForAutocomplete = (searchTerm: string) =>
	searchPorts({
		searchTerm
	}).then((response): AutocompleteOption[] =>
		response.data.results.map(item => ({
			id: item.id,
			name: `${item.name} (${item.code})`
		}))
	);

export default {
	retrievePortLPAs,
	searchPortLPAs,
	retrievePorts,
	searchPorts,
	searchPortLPAsForAutocomplete,
	retrievePortById,
	retrieveAnchorages,
	retrieveBuoys,
	retrieveCanals,
	searchPortsForAutocomplete,
	searchPortsAndCountriesForAutocomplete
};
