import { EntitiesSelectMap } from 'app-types';

export interface FormData {
	pages: FormPageData[];
}

export interface FormPageData {
	das?: EntitiesSelectMap;
	services?: EntitiesSelectMap;
}

export enum FormField {
	PAGES = 'pages',
	SERVICES = 'services',
	DAS = 'das'
}

export const FORM_NAME = 'financeContextualization';
