import { all, fork } from 'redux-saga/effects';

import onAddUpdatePrincipalServiceWatcher from './onAddUpdatePrincipalServiceSaga';
import onAddUpdatePrincipalServiceSubmitWatcher from './onAddUpdatePrincipalServiceSubmitSaga';

export default function*() {
	yield all([
		fork(onAddUpdatePrincipalServiceWatcher),
		fork(onAddUpdatePrincipalServiceSubmitWatcher)
	]);
}
