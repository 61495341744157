import * as React from 'react';
import classNames from 'classnames';
import AntDropdown from 'antd/lib/dropdown';
import { DropDownProps } from 'antd/lib/dropdown/dropdown';
import DropdownButton from 'antd/lib/dropdown/dropdown-button';
import styles from './Dropdown.module.scss';

export class Dropdown extends React.Component<DropDownProps, {}> {
	static Button: typeof DropdownButton = DropdownButton;

	render() {
		return (
			<AntDropdown
				{...this.props}
				className={classNames(styles.root, this.props.className)}
			/>
		);
	}
}

export default Dropdown;
