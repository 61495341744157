import Api from 'services/api';
import { retrieveDashboardPortCallsSummaryAsync as async } from '../actions/retrieveDashboardPortCallsSummary';
import { RetrieveDashboardPortCallsSummaryResponse as response } from 'services/api/dashboard/dashboardServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Dashboard.retrieveDashboardPortCallsSummary;
export default makeTakeLatestWatcher<undefined, response, Error>({
	api,
	async
});
