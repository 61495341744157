import { IconType } from 'components/antd/Icon/IconTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface BreadcrumbItem {
	title: string;
	link?: string;
	icon?: keyof IconType;
	children?: BreadcrumbItem[];
}

export interface NavigationState {
	readonly breadcrumbs: BreadcrumbItem[];
	readonly fetchStatuses: {
		retrieveBenchmarkUrl: FetchStatus;
	};
}
export const initialState: NavigationState = {
	breadcrumbs: [],
	fetchStatuses: {
		retrieveBenchmarkUrl: FetchStatus.IDLE
	}
};
