import React from 'react';
import classNames from 'classnames';
import styles from './SavingsValueList.module.scss';

interface LabeledValue<
	LabelType = React.ReactNode,
	KeyType = string | React.ReactNode
> {
	key: KeyType;
	label: LabelType;
}

interface ValueListProps {
	inline?: boolean;
	dataSource: LabeledValue[];
}

export default class SavingsValueList extends React.Component<ValueListProps> {
	render() {
		const { inline, dataSource } = this.props;
		return (
			<ul className={classNames(styles.root, { [styles.inline]: inline })}>
				{dataSource.map((data, index) => (
					<li key={index} className={styles.maxWidth}>
						{data.label} <br /> <strong>{data.key}</strong>
					</li>
				))}
			</ul>
		);
	}
}
