import {
	BenchmarkStatus,
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse
} from 'services/api/finance/financeServiceTypes';
import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRefreshBenchmark = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse
>('fetchStatuses', 'refreshBenchmark', {
	doneReducer: (state: FinanceState) => {
		if (!state.finance) {
			return state;
		}
		return {
			...state,
			finance: {
				...state.finance,
				benchmarkData: {
					...state.finance.benchmarkData,
					benchmarkSynchronizationStatus: BenchmarkStatus.REQUESTED
				}
			}
		};
	}
});
