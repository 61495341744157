import actionCreator from '../actionCreator';
import { AcknowledgeVPWithCommentRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'ACKNOWLEDGE_VP_WITH_COMMENT';

export type AcknowledgeVPWithCommentParams = Pick<
	AcknowledgeVPWithCommentRequest,
	'comment'
>;

export const acknowledgeVPWithComment = actionCreator<
	AcknowledgeVPWithCommentParams
>(ACTION_NAME);

export const acknowledgeVPWithCommentAsync = actionCreator.async<
	AcknowledgeVPWithCommentParams,
	void,
	Error
>(ACTION_NAME, {
	done: {
		description: 'Your comment has been successfully sent.'
	}
});
