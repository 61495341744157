import React, { useCallback, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { setPortJobContext } from 'store/portJobs/actions';
import {
	getIsCurrentUsersGroupTypeISSHub,
	getIsCurrentUsersGroupTypeCustomer
} from 'store/auth/selectors';
import {
	getPortJobAgencyRoles,
	getPortJobWizardFormInitialized
} from 'store/portJobs/selectors';
import Api from 'services/api';
import { MainPrincipalTypeCodes } from 'services/api/companies/companiesServiceTypes';
import { StaticItem } from 'store/portcalls/portCallsState';
import { AppState } from 'store-types';
import {
	AgencyRoles,
	PortJobTypeCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { FORM, FormFieldName } from '../createPortJobConstants';
import { Loading } from 'components';
import { Page1FormValues } from '../createPortJobTypes';
import { retrieveCompanyJobTypes } from 'store/companies/actions';
import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';

interface PageFormWrapperProps {
	hubPrincipalCompany: Page1FormValues['hubPrincipalCompany'];
}
const PageFormWrapper: React.FC<PageFormWrapperProps> = ({
	hubPrincipalCompany,
	children
}) => {
	const dispatch = useDispatch();

	const isCurrentUsersGroupTypeISSHub = useSelector(
		getIsCurrentUsersGroupTypeISSHub
	);
	const isCurrentUsersGroupTypeCustomer = useSelector(
		getIsCurrentUsersGroupTypeCustomer
	);
	const isInitialized = useSelector(getPortJobWizardFormInitialized);
	const agencyRoles = useSelector<AppState, StaticItem[]>(
		getPortJobAgencyRoles,
		shallowEqual
	);
	const activeWizard = useSelector(getActiveWizard);

	const updateFormValue = useCallback(
		// eslint-disable-next-line
		(field: string, value: any) => {
			dispatch(change(FORM.portJob, field, value));
		},
		[dispatch]
	);

	const hideFieldsForCLSType = useCallback(() => {
		const husbandryAgencyRole = agencyRoles.find(
			role => role.name === AgencyRoles.HUSBANDRY_AGENT
		);
		updateFormValue(FormFieldName.JOB_TYPE_ID, {
			key: PortJobTypeCode.APPOINTMENT,
			label: PortJobTypeCode.APPOINTMENT
		});
		if (husbandryAgencyRole) {
			updateFormValue(FormFieldName.AGENT_ROLE, {
				key: husbandryAgencyRole.code,
				label: husbandryAgencyRole.name
			});
		}
	}, [agencyRoles, updateFormValue]);

	const checkCompanyForCLSType = useCallback(() => {
		const hubPrincipalCompanyKey = hubPrincipalCompany?.key;
		if (
			(isCurrentUsersGroupTypeCustomer || isCurrentUsersGroupTypeISSHub) &&
			hubPrincipalCompanyKey
		) {
			Api.Companies.retrieveCompany(hubPrincipalCompanyKey).then(({ data }) => {
				const isMainPrincipalTypeCLS =
					data.mainPrincipalType.code === MainPrincipalTypeCodes.CLS;
				if (isMainPrincipalTypeCLS) {
					hideFieldsForCLSType();
				}
				dispatch(
					setPortJobContext({
						isMainPrincipalTypeCLS
					})
				);
			});
		} else {
			dispatch(
				setPortJobContext({
					isMainPrincipalTypeCLS: false
				})
			);
		}
		if (hubPrincipalCompanyKey && activeWizard?.mode === 'assign') {
			dispatch(
				change(FORM.portJob, FormFieldName.HUB_PRINCIPAL_COMPANY, {
					key: hubPrincipalCompanyKey,
					value: ''
				})
			);
		}
	}, [
		dispatch,
		hubPrincipalCompany,
		hideFieldsForCLSType,
		isCurrentUsersGroupTypeISSHub,
		isCurrentUsersGroupTypeCustomer,
		activeWizard
	]);

	const retrieveJobTypes = useCallback(() => {
		const hubPrincipalCompanyKey = hubPrincipalCompany?.key;
		if (isCurrentUsersGroupTypeCustomer && hubPrincipalCompanyKey) {
			dispatch(retrieveCompanyJobTypes({ companyId: hubPrincipalCompanyKey }));
		}
	}, [dispatch, hubPrincipalCompany, isCurrentUsersGroupTypeCustomer]);

	useEffect(() => {
		checkCompanyForCLSType();
	}, [checkCompanyForCLSType]);

	useEffect(() => {
		retrieveJobTypes();
	}, [retrieveJobTypes]);

	if (!isInitialized) {
		return <Loading />;
	}
	return <>{children}</>;
};

export default PageFormWrapper;
