import { AppState } from 'store-types.d';
import {
	UserDetailsFormData,
	UserProfile
} from 'services/api/users/userServiceTypes';
import { getUserProfile } from 'store/users/usersSelectors';
import { createSelector } from 'reselect';
import { BreadcrumbItem } from 'store/navigation/navigationState';

import { isEmpty } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';

export const getUserDetailsInitialValues = createSelector(
	getUserProfile,
	(user: UserProfile): Partial<UserDetailsFormData> => {
		return !isEmpty(user)
			? {
					companyName: user.company.name,
					cellphone: user.cellphone,
					email: user.email,
					firstName: user.firstName,
					middleName: user.middleName,
					lastName: user.lastName,
					timeZone: user.timeZone,
					dateFormat: user.dateFormat,
					timeFormat: user.timeFormat
			  }
			: {};
	}
);

export const getBreadcrumbsForUserDetails = createSelector(
	getUserProfile,
	(user: UserProfile): BreadcrumbItem[] => {
		const breadcrumbs: BreadcrumbItem[] = [
			{ title: 'Manage Users', link: '/users' }
		];
		if (!user) {
			return breadcrumbs;
		}
		return [
			...breadcrumbs,
			{
				title: `${user.firstName} ${user.lastName}`
			}
		];
	}
);

export const getIsUserStatusUpdating = (state: AppState): boolean =>
	state.users.fetchStatuses.updatingStatus === FetchStatus.PENDING;
