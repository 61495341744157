import { Dispatch, MiddlewareAPI } from 'redux';

import { AppState } from 'store-types';
import { ActionSuccess, ActionFailure } from 'app-types';

export interface HandleApiArg {
	action: ActionSuccess | ActionFailure;
	api: MiddlewareAPI<Dispatch, AppState>;
	handleType: ApiMiddlewareHandleType | undefined;
}

export enum ApiMiddlewareHandleType {
	NOTIFICATION = 'notification',
	CONNECTION = 'connection',
	SUBMIT = 'submit',
	OTHER = 'other'
}
