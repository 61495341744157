import { LinkJobState } from '../linkJobState';
import { remove } from 'lodash';
import { AddAppointmentCargoManualRequest } from 'services/api/portCalls/portCallsServiceTypes';

export const onAddAppointmentCargoManual = (
	state: LinkJobState,
	action: AddAppointmentCargoManualRequest
): LinkJobState => {
	const newLinkedCargo = state.unLinkedAppointmentCargoes.find(
		cargo => cargo.cargoId === action.id
	);
	const index = state.linkedNominationCargoes.findIndex(
		cargo => cargo.cargoId === action.nominationCargoId
	);
	const updatedAppointmentCargos = state.linkedAppointmentCargoes;
	updatedAppointmentCargos[index] = { ...newLinkedCargo };
	const updatedUnlinkedAppointmentCargos = remove(
		state.unLinkedAppointmentCargoes,
		cargo => cargo.cargoId !== action.id
	);
	return {
		...state,
		linkedAppointmentCargoes: [...updatedAppointmentCargos],
		unLinkedAppointmentCargoes: [...updatedUnlinkedAppointmentCargos]
	};
};
