import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, all, fork, takeLatest } from 'redux-saga/effects';
import { EndOfSeaPassageRequest as request } from 'services/api/portCall/portCallServiceTypes';

import { commenceOfSeaPassageAction } from '../actions/commenceOfSeaPassage';
import { retrievePortCall } from '../../portcalls';

const apiCall = Api.PortCall.commenceOfSeaPassage;

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(commenceOfSeaPassageAction.async.started(actionParams));
	try {
		const { portCallId } = actionParams;
		const response = yield call(api, actionParams);
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
		yield put(
			commenceOfSeaPassageAction.async.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			commenceOfSeaPassageAction.async.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(commenceOfSeaPassageAction.action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
