import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveDirectBillsAsync,
	uploadDirectBillsDocumentationDone,
	downloadDirectBillsDocumentsAsync,
	deleteDirectBillsFileInfo,
	updateDirectBillAsync,
	updateDirectBillStatusAsync,
	retrieveFullDirectBillAsync
} from './actions';
import {
	onRetrieveDirectBills,
	onUploadDirectBillsDocumentationDone,
	onDeleteDirectBillsFileInfo,
	onUpdateDirectBill,
	onUpdateDirectBillStatus,
	onRetrieveFullDirectBill,
	onResetContext,
	onDownloadDirectBillsDocument
} from './reducers';
import { initialState } from './directBillsState';
import { onResetDirectBills } from './reducers/resetDirectBillsReducer'; // is there any reason why is it exported separately?
import { resetDirectBills } from './actions/resetDirectBills';
import { resetContext } from './actions/resetContext';

export default reducerWithInitialState(initialState)
	// retrieve direct bills
	.case(retrieveDirectBillsAsync.started, onRetrieveDirectBills.started)
	.case(retrieveDirectBillsAsync.done, onRetrieveDirectBills.done)
	.case(retrieveDirectBillsAsync.failed, onRetrieveDirectBills.failed)
	// update / create direct bill WIP
	.case(updateDirectBillAsync.started, onUpdateDirectBill.started)
	.case(updateDirectBillAsync.done, onUpdateDirectBill.done)
	.case(updateDirectBillAsync.failed, onUpdateDirectBill.failed)
	// update direct bill status
	.case(updateDirectBillStatusAsync.started, onUpdateDirectBillStatus.started)
	.case(updateDirectBillStatusAsync.done, onUpdateDirectBillStatus.done)
	.case(updateDirectBillStatusAsync.failed, onUpdateDirectBillStatus.failed)
	// fetch full direct bill for edit/view
	.case(retrieveFullDirectBillAsync.started, onRetrieveFullDirectBill.started)
	.case(retrieveFullDirectBillAsync.done, onRetrieveFullDirectBill.done)
	.case(retrieveFullDirectBillAsync.failed, onRetrieveFullDirectBill.failed)
	// reset direct bills
	.case(resetDirectBills, onResetDirectBills)
	// reset direct bill context
	.case(resetContext, onResetContext)
	.case(
		uploadDirectBillsDocumentationDone,
		onUploadDirectBillsDocumentationDone
	)
	.case(deleteDirectBillsFileInfo, onDeleteDirectBillsFileInfo)
	.case(
		downloadDirectBillsDocumentsAsync.started,
		onDownloadDirectBillsDocument.started
	)
	.case(
		downloadDirectBillsDocumentsAsync.done,
		onDownloadDirectBillsDocument.done
	)
	.case(
		downloadDirectBillsDocumentsAsync.failed,
		onDownloadDirectBillsDocument.failed
	);
