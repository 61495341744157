import {
	RetrievePortJobMetadataRequest,
	PortJobMetadataActionCode
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';
import { Metadata } from 'app-types';

const ACTION_NAME = 'RETRIEVE_PORTJOB_METADATA';
export const retrievePortJobMetadataAsync = actionCreator.async<
	RetrievePortJobMetadataRequest,
	Metadata<PortJobMetadataActionCode>,
	Error
>(ACTION_NAME);

export const retrievePortJobMetadata = actionCreator<
	RetrievePortJobMetadataRequest
>(ACTION_NAME);
