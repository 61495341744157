import React from 'react';
import { Icon } from 'components/antd';
import styles from 'components/AutocompleteSearch/AutocompleteSearch.module.scss';

const ClearButton: React.FC<{ onClick: () => void }> = props => (
	<button
		className={styles.clearButton}
		aria-label="clear selection"
		onClick={props.onClick}
	>
		<Icon type="close" className={styles.clearIcon} />
	</button>
);

export default ClearButton;
