import { VesselProgrammeState } from '../vesselProgrammeState';
import { RetrieveTerminalsResponse } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveTerminalsStarted(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			terminals: FetchStatus.PENDING
		}
	};
}
export function onRetrieveTerminalsSuccess(
	state: VesselProgrammeState,
	action: Success<string, RetrieveTerminalsResponse>
): VesselProgrammeState {
	const { result, params } = action;
	const terminals = result.elements;
	return {
		...state,
		terminalById: {
			...state.terminalById,
			[params]: terminals
		},
		fetchStatuses: {
			...state.fetchStatuses,
			terminals: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveTerminalsFailed(
	state: VesselProgrammeState
): VesselProgrammeState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			terminals: FetchStatus.FAILURE
		}
	};
}
