export interface Point {
	x: number;
	y: number;
	rotation: number;
}

export interface RectNode extends Point {
	width: number;
	height: number;
	color: string;
	id: string;
}

export interface LineNode extends Point {
	color: string;
	points: number[];
	id: string;
}

export interface TextNode extends Point {
	id: string;
	text: string;
	color: string;
	fontStyle: string;
	fontSize: number;
	width?: number;
	textDecoration: string;
	align: string;
}

export enum DrawingMode {
	LINE = 'line',
	RECT = 'rect',
	TEXT = 'text',
	NONE = 'none'
}

export enum DrawingAction {
	ADD = 'add',
	DELETE = 'delete',
	MOVE = 'move'
}

export interface LineNode {
	color: string;
	points: number[];
}
