import { call, put, putResolve, select } from 'redux-saga/effects';
import Api from 'services/api';
import {
	mergePortCallsAsync as async,
	setActivePortCallId
} from 'store/portcalls/actions';
import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	MergePortCallsRequest,
	MergePortCallsResponse,
	PortCall
} from 'services/api/portCalls/portCallsServiceTypes';
import { notify } from 'store/notifications/actions';
import { setActivePortJobCode } from 'store/portJobs/actions';
import { Success } from 'typescript-fsa';
import { navigateTo } from 'utils';
import { getPortCallById, getPortCallDuplicate } from '../selectors';
import { sortBy } from 'lodash';

const api = Api.PortCalls.mergePortCalls;

const getFirstJob = (portCall: PortCall, portCallDuplicate?: PortCall) => {
	if (!portCall || !portCall.jobs || !portCall.jobs.length) {
		return null;
	}
	const portJobs = portCall.jobs;
	const duplicatePortJobs = portCallDuplicate?.jobs || [];
	const sortedJobs = sortBy(
		[...portJobs, ...duplicatePortJobs],
		job => job.code
	);
	return sortedJobs[0].code;
};

const executor = makeDefaultExecutor<
	MergePortCallsRequest,
	MergePortCallsResponse,
	Error
>({
	api,
	async,
	*onSuccess({
		params: { isDuplicate, portCallId, portCallDuplicateIds }
	}: Success<MergePortCallsRequest, MergePortCallsResponse>) {
		const message = isDuplicate
			? `Port calls have been successfully merged.`
			: `Port call has been successfully confirmed as the unique port call.`;

		const portCallDuplicateId = portCallDuplicateIds?.[0];
		const portCall = yield select(getPortCallById, portCallId);
		const portCallDuplicate = yield select(
			getPortCallDuplicate,
			portCallId,
			portCallDuplicateId
		);

		const targetPortCallId = isDuplicate
			? portCallDuplicateIds?.[0]
			: portCallId;
		const jobCode = isDuplicate
			? getFirstJob(portCallDuplicate, portCall)
			: getFirstJob(portCall);

		if (!targetPortCallId) {
			return;
		}

		/**
		 * here put should be blocking (putResolve)
		 * since the following steps depend on these store modifications
		 */
		yield putResolve(setActivePortCallId(targetPortCallId));
		if (jobCode) {
			yield putResolve(setActivePortJobCode(jobCode));
		}

		const targetUrl = jobCode
			? `/portcalls/${targetPortCallId}/jobs/${jobCode}/overview`
			: `/portcalls/${targetPortCallId}/overview`;
		yield call(navigateTo, targetUrl);
		yield put(notify.success(message));
	}
});

export const mergePortCallsWatcher = makeTakeLatestWatcher<
	MergePortCallsRequest,
	MergePortCallsResponse,
	Error
>(
	{
		api: Api.PortCalls.mergePortCalls,
		async
	},
	executor
);
