import actionCreator from '../portJobsActionCreator';
import {
	DeletePortJobPayload,
	DeletePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'DELETE_PORTJOB';

export const deletePortJobAsync = actionCreator.async<
	DeletePortJobPayload,
	DeletePortJobResponse,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412, 404]
	},
	templateParams: {
		entityName: 'Port Job'
	}
});

export const deletePortJob = actionCreator<DeletePortJobPayload>(ACTION_NAME);
