import {
	createCompanyVesselAsync,
	createCompanyVessel
} from 'store/companyVessels/actions';
import Api from 'services/api';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, take, all, fork, put } from 'redux-saga/effects';
import { CreateCompanyVesselRequest } from 'services/api/companies/companiesServiceTypes';
import { navigateTo } from 'utils';

import { changeRoute } from 'store/route/actions';
import { notify } from 'store/notifications/actions';
import { PATHS } from 'sections/App/RouteParams';

export function* createCompanyVesselExecutor(
	actionParams: CreateCompanyVesselRequest,
	api: typeof Api.Companies.createCompanyVessel
): SagaIterator {
	yield put(createCompanyVesselAsync.started(actionParams));
	try {
		const response = yield call(api, actionParams);
		yield put(
			createCompanyVesselAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield call(navigateTo, `/${PATHS.configuration}/vessels`);
		yield take(changeRoute);
	} catch (error) {
		yield put(
			createCompanyVesselAsync.failed({
				error,
				params: actionParams
			})
		);
		// if data has 'IMO' inside, this vessel was already added
		if (error.response.data?.IMO) {
			yield call(navigateTo, `/${PATHS.configuration}/vessels`);
			yield put(
				notify.info(
					'The action can’t be completed. This vessel has been just added by another user.'
				)
			);
		} else if (error.response.data?.ExternalCode) {
			yield put(notify.error(error.response.data.ExternalCode[0]));
		}
	}
}

export function* createCompanyVesselWorker(
	action: Action<CreateCompanyVesselRequest>
): SagaIterator {
	yield call(
		createCompanyVesselExecutor,
		action.payload,
		Api.Companies.createCompanyVessel
	);
}

export function* createCompanyVesselWatcher(): SagaIterator {
	yield takeLatest(createCompanyVessel.type, createCompanyVesselWorker);
}

export default function*() {
	yield all([fork(createCompanyVesselWatcher)]);
}
