import * as React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'components';
import { PortCallFormValuesBase } from '../../CreatePortJob/createPortJobTypes';
import { getPortJobFullSummary } from 'store/portJobs/selectors';
import { AppState } from 'store-types';
import PortCallFormattedTime from './PortCallFormattedTime';

interface PortCallInfoProps
	extends Pick<
		PortCallFormValuesBase,
		| 'etaPlt'
		| 'eospPlt'
		| 'cospPlt'
		| 'etaUtc'
		| 'etsUtc'
		| 'eospUtc'
		| 'cospUtc'
	> {
	vesselName: React.ReactNode;
	portName: React.ReactNode;
	etsPlt?: string;
	// from mapStateToProps
	portId: string;
}

const PortCallInfo = ({
	vesselName,
	portId,
	portName,
	etaPlt,
	etsPlt,
	eospPlt,
	cospPlt
}: PortCallInfoProps) => (
	<section>
		<h1>Port Call Details</h1>
		<Flex row>
			<Flex direction="vertical" flex={1}>
				<h4>Vessel</h4>
				<strong>{vesselName}</strong>
			</Flex>
			<Flex direction="vertical" flex={1}>
				<h4>Port</h4>
				<strong>{portName}</strong>
			</Flex>
			<Flex direction="vertical" flex={1}>
				<h4>{eospPlt ? 'EOSP' : 'ETA'}</h4>
				<strong>
					<PortCallFormattedTime value={eospPlt || etaPlt} portId={portId} />
				</strong>
			</Flex>
			<Flex direction="vertical" flex={1}>
				<h4>{cospPlt ? 'COSP' : 'ETS'}</h4>
				<strong>
					{cospPlt ? (
						<PortCallFormattedTime value={cospPlt} portId={portId} />
					) : etsPlt ? (
						<PortCallFormattedTime value={etsPlt} portId={portId} />
					) : (
						'TBD'
					)}
				</strong>
			</Flex>
		</Flex>
	</section>
);

export default connect((state: AppState) => {
	const summary = getPortJobFullSummary(state);
	return { portId: summary?.portCallSummary.port.id };
})(PortCallInfo);
