import { FormErrors } from 'redux-form';
import { validateRequired, validateEmailRequired } from 'utils/validations';

import { PreRegistrationFormData } from 'store/registration/registrationTypes';

const validate = (
	values: PreRegistrationFormData
): FormErrors<PreRegistrationFormData> => ({
	firstName: validateRequired(values.firstName),
	lastName: validateRequired(values.lastName),
	email: validateEmailRequired(values.email)
});

export default validate;
