import { CommonMetadataAction } from 'app-constants';
import { AppState } from 'store-types';
import { getIsLoading } from 'store/selectors';
import { createSelector } from 'reselect';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';
import { getFinanceFetchStatuses } from './financeSelectors';

export const getIsCreateFinanceDocumentsLoading = (state: AppState) =>
	getIsLoading(state.finance.fetchStatuses.createDocuments);

export const getRetrieveFinanceDocumentFetchStatus = (state: AppState) =>
	state.finance.fetchStatuses.retrieveFinanceDocument;
export const getFinanceDocument = (state: AppState) => state.finance.document;
export const getFinanceDocumentPagesLen = (state: AppState) => {
	const document = getFinanceDocument(state);
	return document?.pages.length;
};
export const getIsFinanceDocumentPageDeleteLoading = (
	state: AppState,
	documentId: string,
	actualNumber: number,
	pageType: string
) => {
	const fetchStatusesById =
		state.finance.fetchStatuses.deleteDocumentPageById[documentId];
	return getIsLoading(fetchStatusesById?.[actualNumber]?.[pageType]);
};

export const getCanDeleteDocumentPage = createSelector(
	getFinanceDocument,
	document =>
		document?.metadata &&
		!!getEntityMetadataAction<CommonMetadataAction>(
			document.metadata.actions,
			CommonMetadataAction.DELETE
		)
);

// Revisions
// ---------
export const getIsDownloadJobFinanceRevisionLoading = (
	state: AppState,
	revisionId: string
) => getIsLoading(state.finance.fetchStatuses.downloadRevisionById[revisionId]);

export const getIsRestoreOriginalDocumentLoading = createSelector(
	getFinanceFetchStatuses,
	fetchStatus => getIsLoading(fetchStatus.restoreOriginalDocument)
);

export const getIsDeleteFinanceDocumentOriginalAndAnnotatedLoading = createSelector(
	getFinanceFetchStatuses,
	fetchStatus =>
		getIsLoading(fetchStatus.deleteFinanceDocumentOriginalAndAnnotated)
);
