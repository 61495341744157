import {
	PortCallsState,
	RetrievePortCallsSettingsResponse
} from '../portCallsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

const onRetrievePortCallsSettingSuccess = (
	state: PortCallsState,
	action: Success<void, RetrievePortCallsSettingsResponse>
): PortCallsState => ({
	...state,
	settings: action.result
});

export const onRetrievePortCallsSettings = makeFetchStatusReducers<
	PortCallsState,
	'fetchStatuses',
	void,
	RetrievePortCallsSettingsResponse
>('fetchStatuses', 'settings', {
	doneReducer: onRetrievePortCallsSettingSuccess
});

export default onRetrievePortCallsSettings;
