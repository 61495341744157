import { notify } from './../../notifications/actions/notification';
import { put } from 'redux-saga/effects';
import Api from 'services/api';
import { retrieveFinanceAsync } from '../actions/retrieveFinance';
import {
	BenchmarkStatus,
	RetrieveFinanceRequest,
	PortCallFinance
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveBenchmarkStatusCycleStop } from '../actions';

export default makeTakeLatestWatcher<
	RetrieveFinanceRequest,
	PortCallFinance,
	Error
>({
	api: Api.Finance.retrieveFinance,
	async: retrieveFinanceAsync,
	*onSuccess({ params: { benchmarkData }, result }) {
		const isNoNewBenchmark =
			benchmarkData && benchmarkData.id === result.benchmarkData.id;
		if (
			benchmarkData?.benchmarkSynchronizationStatus ===
				BenchmarkStatus.REQUESTED &&
			result.benchmarkData.benchmarkSynchronizationStatus ===
				BenchmarkStatus.SYNCHRONIZED
		) {
			yield put(retrieveBenchmarkStatusCycleStop());
			if (isNoNewBenchmark) {
				yield put(notify.error('No new benchmark has been found'));
			} else {
				yield put(notify.success('Benchmark has been refreshed'));
			}
		}
	}
});
