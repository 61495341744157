import * as React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

import antLocale from './antDesignLocalization';

addLocaleData([...en]);

const locale = 'en';

export default class LocaleProvider extends React.Component<{}> {
	render() {
		return (
			<ConfigProvider key={locale} locale={antLocale}>
				<IntlProvider key={locale} locale={locale}>
					{this.props.children}
				</IntlProvider>
			</ConfigProvider>
		);
	}
}
