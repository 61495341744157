import { PortCallState } from '../portCallState';
import { FetchStatus } from 'services/api/apiTypes';

export function onUpdatePortCallStarted(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updatePortCall: FetchStatus.PENDING
		}
	};
}

export function onUpdatePortCallSuccess(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updatePortCall: FetchStatus.SUCCESS
		}
	};
}

export function onUpdatePortCallFailed(state: PortCallState): PortCallState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updatePortCall: FetchStatus.FAILURE
		}
	};
}
