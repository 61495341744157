import { times } from 'lodash';

const getInitialPageState = (valid = false): { valid: boolean } => ({
	valid
});

/**
 * Get the initial list of pages
 * Every page below current `startPage` is automatically validated as true
 */
export const initPages = (total = 0, startPage = 1) => {
	return times(total).reduce((acc, pageNumber) => {
		const index = pageNumber + 1;
		acc[index] = getInitialPageState(index < startPage);
		return acc;
	}, {});
};
