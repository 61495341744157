import { DraftsState, CompanyDraft, DraftKind } from '../draftsState';
import { AddCompanyDraftActionPayload } from '../actions';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { getOrganisationTypeFromDraftType } from '../draftsHelpers';

export const onAddCompanyDraft = (
	state: DraftsState,
	formData: AddCompanyDraftActionPayload
): DraftsState => {
	const type = state.context.activeDraftType;
	if (!type) {
		return state;
	}

	const draftData: CompanyDraft = {
		kind: DraftKind.NEW,
		isDraft: true,
		companyOrganizationTypeCode: getOrganisationTypeFromDraftType(
			type
		) as OrganisationType,
		...formData
	};
	return {
		...state,
		draftsByType: {
			...state.draftsByType,
			[type]: [...state.draftsByType[type], draftData]
		}
	};
};
