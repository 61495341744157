import actionCreator from '../actionCreator';
import {
	RetrieveEventsResponse,
	RetrieveEventsParams
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'RETRIEVE_EVENTS';

export const retrieveEventsAsync = actionCreator.async<
	RetrieveEventsParams,
	RetrieveEventsResponse,
	Error
>(ACTION_NAME);

export const retrieveEvents = actionCreator<RetrieveEventsParams>(ACTION_NAME);
