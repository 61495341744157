import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api';
import {
	retrieveUserByToken as action,
	retrieveUserByTokenAsync as actionAsync
} from 'store/registration/actions/retrieveUserByToken';
import { RegisterUserRequest } from 'services/api/registration/registrationServiceTypes';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import { Action } from 'typescript-fsa';

const apiCall = Api.Registration.retrieveUserByToken;

export function* executor(token: string, api: typeof apiCall): SagaIterator {
	yield put(actionAsync.started(token));
	try {
		const response: AxiosTypedResponse<RegisterUserRequest> = yield call(
			api,
			token
		);

		yield put(
			actionAsync.done({
				result: response.data,
				params: token,
				response
			})
		);
	} catch (error) {
		yield put(
			actionAsync.failed({
				error: true,
				params: token
			})
		);
	}
}

function* worker({ payload }: Action<string>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
