import UserPermission from 'components/UserPermission/UserPermission';
import * as React from 'react';
import { connect } from 'react-redux';
import { Select } from 'components/antd';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { ContextType } from 'services/api/threads/threadsServiceTypes';
import { getThreadContext } from 'store/thread/selectors';
import ContextContainer from './ContextContainer';
import { AppState } from 'store-types';
import { change } from 'redux-form';
import {
	CATEGORIZE_MSG_MODAL_FORM,
	ThreadsCatergorizeMsgFormFields
} from '../CategorizeMsgModalFormTypes';

type ContextProps = {
	context: ContextType | null;
	threadContexts: ContextType[];
	change: typeof change;
};

class ContextSearch extends React.Component<ContextProps> {
	handleChange = (value: string) => {
		const { threadContexts, change } = this.props;
		const chosenItem =
			threadContexts.find(threadContext => threadContext.name === value) ||
			null;
		change(
			CATEGORIZE_MSG_MODAL_FORM,
			`${ThreadsCatergorizeMsgFormFields.THREAD_CONTEXT_ID}`,
			chosenItem?.id
		);
	};

	filterOption = (
		input: string,
		option: React.ReactElement<{ children: string }>
	) =>
		option.props.children
			.toString()
			.toLowerCase()
			.includes(input.toLowerCase());

	getSelect = () => {
		const { threadContexts } = this.props;

		return (
			<Select
				showSearch
				placeholder={'Type to select a Context'}
				optionFilterProp="children"
				onChange={this.handleChange}
				filterOption={this.filterOption}
			>
				{threadContexts.map(threadContext => (
					<Select.Option key={threadContext.id} value={threadContext.name}>
						{threadContext.name}
					</Select.Option>
				))}
			</Select>
		);
	};

	render() {
		return (
			<UserPermission
				code={[
					GroupTypeCodes.ISS_CLUSTER,
					GroupTypeCodes.ISS_HUB,
					GroupTypeCodes.ISS_MANAGE
				]}
			>
				<section>
					<>
						<span>Context</span>
						{this.getSelect()}
					</>
				</section>
			</UserPermission>
		);
	}
}

const ContextSearchConnected = connect(
	(state: AppState) => {
		return {
			context: getThreadContext(state)
		};
	},
	{
		change
	}
)(ContextSearch);

export default () => {
	return (
		<ContextContainer>
			{data => <ContextSearchConnected threadContexts={data} />}
		</ContextContainer>
	);
};
