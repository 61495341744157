import uuidv4 from 'uuid/v4';
import { omit, pickBy } from 'lodash';
import {
	NotificationsState,
	NotificationVariantItem,
	NotificationDisplayType,
	NotificationsDisplayTypeConfig,
	NotificationAddons,
	initialState
} from '../notificationsState';

export const onAddNotification = (
	state: NotificationsState,
	notification: NotificationVariantItem
) => {
	const uuid = notification.uuid || uuidv4();
	const notificationItem = {
		...notification,
		uuid
	};
	return {
		...state,
		notifications: {
			...state.notifications,
			[uuid]: notificationItem
		}
	};
};

export const onRemoveNotification = (
	state: NotificationsState,
	notificationUuid: string
) => {
	return {
		...state,
		notifications: omit(state.notifications, notificationUuid)
	};
};

export const onResetNotifications = (
	state: NotificationsState,
	displayType: NotificationDisplayType
) => {
	// get list of UUID by displayType
	const uuids = Object.keys(
		pickBy(
			state.notifications,
			notification => notification.displayType === displayType
		)
	);

	return {
		...state,
		notifications: omit(state.notifications, uuids)
	};
};

export const onResetAllNotifications = (state: NotificationsState) => {
	return {
		...state,
		notifications: initialState.notifications
	};
};

export const onSetNotificationActionTypeToDisplayType = (
	state: NotificationsState,
	config: NotificationsDisplayTypeConfig
) => {
	return {
		...state,
		notificationsConfig: {
			...state.notificationsConfig,
			...config
		}
	};
};

export const onUnSetNotificationActionTypeToDisplayType = (
	state: NotificationsState,
	actionType: string[]
) => {
	return {
		...state,
		notificationsConfig: omit(state.notificationsConfig, actionType)
	};
};

export const onSetNotificationAddons = (
	state: NotificationsState,
	addons: NotificationAddons
) => {
	const newAddons = Object.keys(addons).reduce<NotificationsState['addons']>(
		(acc, key) => {
			const type = key.replace(/_FAILED|_DONE/gi, '');
			acc[type] = {
				...state.addons[type],
				...addons[key]
			};
			return acc;
		},
		{}
	);
	return {
		...state,
		addons: {
			...state.addons,
			...newAddons
		}
	};
};

export const onResetNotificationAddons = (state: NotificationsState) => {
	return {
		...state,
		addons: initialState.addons
	};
};
