import { IThread } from 'services/api/threads/threadsServiceTypes';
import { ThreadsState } from 'store/threads/threadsState';

export const onSetActiveThreadId = (
	state: ThreadsState,
	activeThreadId: IThread['id']
): ThreadsState => ({
	...state,
	context: {
		...state.context,
		activeThreadId
	}
});
