import { Success } from 'typescript-fsa';
import { CompaniesState } from '../companiesState';
import {
	ConfigurationBenchmarkData,
	RetrieveConfigurationBenchmarkRequest
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function doneReducer(
	state: CompaniesState,
	{
		params,
		result
	}: Success<RetrieveConfigurationBenchmarkRequest, ConfigurationBenchmarkData>
): CompaniesState {
	return {
		...state,
		generalConfiguration: {
			...state.generalConfiguration,
			benchmark: {
				...state.generalConfiguration.benchmark,
				[params.companyId]: result
			}
		}
	};
}

export const onRetrieveCompanyBenchmark = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData
>('fetchStatuses', 'retrieveCompanyBenchmark', {
	doneReducer
});
