import Api from 'services/api';
import {
	RetrieveTemplateRequest,
	TemplateContent
} from 'services/api/templates/templateServiceTypes';

import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveTemplateAsync } from 'store/templates/actions/retrieveTemplate';

const apiCall = Api.Templates.retrieveTemplate;
const async = retrieveTemplateAsync;

const commonExecutor = makeDefaultExecutor<
	RetrieveTemplateRequest,
	TemplateContent,
	Error
>({
	api: apiCall,
	async
});

export const retrieveTemplateWatcher = makeTakeLatestWatcher<
	RetrieveTemplateRequest,
	TemplateContent,
	Error
>(
	{
		api: apiCall,
		async
	},
	commonExecutor
);
