import { CompaniesState, initialState } from '../companiesState';
import {
	RetrievePrincipalsForMainPrincipalRequest as Request,
	RetrieveCompaniesResponse as Response
} from 'services/api/companies/companiesServiceTypes';
import { Success } from 'typescript-fsa';

export const onRetrievePrincipalsForMainPrincipalSuccess = (
	state: CompaniesState,
	action: Success<Request, Response>
): CompaniesState => ({
	...state,
	principals: action.result.elements
});

export const onResetPrincipalsFormMainPrincipal = (state: CompaniesState) => ({
	...state,
	principals: initialState.principals
});
