import * as React from 'react';
import { Button } from 'components/antd';
import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';
import {
	SelectProps,
	Select,
	selectFieldMap
} from 'components/antd/Select/Select';
import { DropDownDraftProps, DraftOption } from '../draftsTypes';
import { getOptionsWithDrafts } from './SelectDrafts.func';
import { isEmpty } from 'lodash';
import styles from '../Drafts.module.scss';
import classNames from 'classnames';
import { isEMDDraftEnabled } from 'app-constants';
import { customMap } from 'components/antd/Form/ReduxField/mapError';

type SelectDraftsProps = SelectProps &
	DropDownDraftProps & {
		shouldStopWheelPropagation?: boolean;
		options: DraftOption[];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
	};

class SelectDrafts extends React.Component<SelectDraftsProps> {
	static ReduxFormItem: CreateReduxField<SelectDraftsProps>;

	render() {
		const {
			isAddingDraftEnabled = true,
			addDraftLinkTitle,
			drafts,
			options,
			onAddDraftClick,
			notFoundContent,
			dropdownClassName,
			shouldStopWheelPropagation,
			...selectProps
		} = this.props;
		return (
			<div
				className={styles.draftSelectContainer}
				onWheel={e => shouldStopWheelPropagation && e.stopPropagation()}
			>
				<Select
					{...selectProps}
					dropdownClassName={classNames(dropdownClassName, styles.drafts)}
				>
					{getOptionsWithDrafts(options, drafts).map(option => (
						<Select.Option
							key={option.id}
							value={option.id}
							title={option.name}
						>
							{option.name}
						</Select.Option>
					))}
					{isEmpty(options) && isEmpty(drafts) && (
						<Select.Option key="notFound" disabled>
							{notFoundContent}
						</Select.Option>
					)}
					<Select.Option
						key="footer"
						disabled
						className={styles.addOptionMenuItem}
					>
						<Button
							type="primary"
							transparent
							icon="plus"
							disabled={!isAddingDraftEnabled || !isEMDDraftEnabled}
							onClick={onAddDraftClick}
						>
							{addDraftLinkTitle}
						</Button>
					</Select.Option>
				</Select>
			</div>
		);
	}
}

SelectDrafts.ReduxFormItem = createFieldComponent(
	SelectDrafts,
	customMap<SelectDraftsProps>(props => selectFieldMap(props))
);
export default SelectDrafts;
