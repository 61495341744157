import { getIsHubPrincipalFieldVisible } from 'store/portJobs/componentSelectors';
import {
	getActiveWizard,
	getWizardById
} from 'store/wizardForm/wizardFormSelectors';
import { getActiveThreadId } from 'store/threads/selectors';
import {
	FORM,
	PORT_JOB_WIZARD_ID
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';

import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { PortCallFormValues } from '../../createPortJobTypes';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';

import { getActivePortCall } from 'store/portcalls/portCallsSelectors';

import { has } from 'lodash';
import { getFormValues } from 'redux-form';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';

const getPortCallFormValues = (state: AppState) =>
	getFormValues(FORM.portJob)(state) as PortCallFormValues;

/**
 * Get the raw PortCall data from the store (ids, not names)
 */
const getRawFormattedPortCallFromStore = (
	portCall: PortCall
): PortCallFormValues => ({
	portCall: {
		vessel: { key: portCall.vessel.id, label: portCall.vessel.name },
		port: { key: portCall.port.id, label: portCall.port.name },
		etaPlt: portCall.etaPlt,
		etsPlt: portCall.etsPlt,
		eospPlt: portCall.eospPlt,
		cospPlt: portCall.cospPlt,
		// Display values
		etaUtc: portCall.etaUtc,
		etsUtc: portCall.etsUtc,
		eospUtc: portCall.eospUtc,
		cospUtc: portCall.cospUtc
	}
});

/**
 * To use inside Headers of WizardForm
 */
const getPortCallValues = createSelector(
	getActivePortCall,
	getPortCallFormValues,
	(portCall, values): PortCallFormValues => {
		// Take it from the form if the form has 'portCall' prop
		if (values && has(values, 'portCall')) {
			return values;
		}

		// Take it from the store if there's no values.portCall
		if (portCall) {
			return getRawFormattedPortCallFromStore(portCall);
		}

		return {
			portCall: {
				vessel: { key: '', label: '' },
				port: { key: '', label: '' },
				etaPlt: '',
				etsPlt: ''
			}
		};
	}
);

/**
 * Look for PortCall data on the redux-form store or on the store.portcalls
 * and return it ready to display it on a component
 */
export const getActivePortCallInfo = createSelector(
	getPortCallValues,
	values => ({
		vesselName: values.portCall.vessel.label,
		portName: values.portCall.port.label,
		etaPlt: values.portCall.etaPlt,
		etsPlt: values.portCall.etsPlt,
		eospPlt: values.portCall.eospPlt,
		cospPlt: values.portCall.cospPlt,
		// Display values
		etaUtc: values.portCall.etaUtc,
		etsUtc: values.portCall.etsUtc,
		eospUtc: values.portCall.eospUtc,
		cospUtc: values.portCall.cospUtc
	})
);

export const getPage1InitialValues = createSelector(
	getActivePortCall,
	getPortCallFormValues,
	(portCall, values): Partial<PortCallFormValues> => {
		return portCall ? getRawFormattedPortCallFromStore(portCall) : values;
	}
);

/**
 * Return `true` when the current Wizard is in 'assign' mode and the user is creating a new thread
 * Otherwise, there's no place to gather a company.id to use as a filter for vessels
 */
export const getIsPortJobCreatedFromNewThread = createSelector(
	getActiveThreadId,
	getActiveWizard,
	(activeThreadId, activeWizard): boolean =>
		activeWizard.mode === 'assign' && activeThreadId === NEW_THREAD_ID
);

/**
 * Return `true` if the Field.HubPrincipal is visible
 * and the active WizardForm is in 'create' mode |
 * or if the active WizardForm is in 'assing' mode and no active Thread is present
 */
export const getIsFormFieldHubPrincipalVisible = createSelector(
	getIsHubPrincipalFieldVisible,
	(state: AppState) => getWizardById(state, PORT_JOB_WIZARD_ID),
	getIsPortJobCreatedFromNewThread,
	(
		isHubPrincipalFieldVisible,
		portJobWizard,
		isThreadCreatedByWizard
	): boolean => {
		if (portJobWizard.mode === 'create') {
			return isHubPrincipalFieldVisible;
		}
		if (isThreadCreatedByWizard) {
			return true;
		}

		return false;
	}
);
