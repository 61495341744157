import { LabeledValueItem } from 'app-types';

export const ENABLE_OPERATIONS_FORM = 'enableOperationsForm';
export const ENABLE_OPERATIONS_CONFIRMATION_MODAL =
	'enableOperationsConfirmationModal';

export interface FormData {
	list: FormDataObj[];
}

export type FormDataObj = {
	serviceSection: SelectServiceSection[];
	alternateServiceSection: AlternativeSection[];
};

export enum FormField {
	SERVICE_SECTION = 'serviceSection',
	ALTERNATIVE_SECTION = 'alternateServiceSection'
}

export interface ServiceSection {
	portJobServiceId: string;
	serviceCode: string;
	serviceName: string;
	serviceSectionCode: string;
	enableOperationServiceType: string;
	altSection?: LabeledValueItem;
	section?: LabeledValueItem;
	service?: LabeledValueItem;
}

export interface SelectServiceSection extends ServiceSection {
	sectionList: SectionList[];
}

export interface AlternativeSection extends ServiceSection {
	vpSectionServices: SectionList[];
}

export interface SectionList {
	code: string;
	name: string;
}

export interface UpdateServiceSection {
	portCallId: string;
	jobCode: string;
	portJobServiceEnableOperationUpdateRequestData: UpdateSectionList[];
	alternateSectionUpdateRequestData: UpdateSectionList[];
}

export interface UpdateSectionList {
	serviceCode: string;
	serviceName: string;
	serviceSectionName: string;
	serviceSectionCode: string;
	entityId: string;
	portJobServiceId: string;
}
