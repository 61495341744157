import {
	GroupTypeCodes,
	GroupApplicableScopeTypes
} from '../groups/groupsServiceTypes';
import { CustodyTransferType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';

export enum PermissionCode {
	// @version: 19
	// https://conf.dataart.com/display/IPP/List+of+Functional+permissions#expand-Version19

	VIEW_GROUP = 'ViewGroup',
	MANAGE_GROUP = 'ManageGroup',

	VIEW_MESSAGES = 'ViewMessages',
	VIEW_PORTCALL_MESSAGES = 'ViewPortCallMessages',
	VIEW_ALL_THREADS = 'ViewAllThreads',

	VIEW_USERS = 'ViewUsers',
	MANAGE_USERS = 'ManageUsers',

	VIEW_PORTCALLS = 'ViewPortCalls',
	MANAGE_PORTCALL = 'ManagePortCall',
	ACKNOWLEDGE_VP_CHANGES = 'AcknowledgeVPChanges',
	APPOINT_AGENT = 'AppointAgent',
	MANAGE_VESSEL_PROGRAMME = 'ManageVesselProgramme',
	MANAGE_OPERATIONS = 'ManageOperations',

	VIEW_FINANCE = 'ViewFinance',
	MANAGE_PDA_CE = 'ManagePDACE',
	MANAGE_DA = 'ManageDA',
	ASSIGN_DA = 'AssignDA',
	ELEVATED_APPROVE_FINANCE = 'ElevatedApproveFinance',

	APPROVE_PDA_CE = 'ApprovePDACE',
	APPROVE_PDA_CE_LEVEL_1 = 'ApprovePDACELevel1',
	APPROVE_PDA_CE_LEVEL_2 = 'ApprovePDACELevel2',
	APPROVE_PDA_CE_LEVEL_3 = 'ApprovePDACELevel3',
	APPROVE_PDA_CE_LEVEL_4 = 'ApprovePDACELevel4',
	APPROVE_PDA_CE_LEVEL_5 = 'ApprovePDACELevel5',

	APPROVE_ASSIGNED_PDA_CE = 'ApproveAssignedPDACE',
	APPROVE_ASSIGNED_PDA_CE_LEVEL_1 = 'ApproveAssignedPDACELevel1',
	APPROVE_ASSIGNED_PDA_CE_LEVEL_2 = 'ApproveAssignedPDACELevel2',
	APPROVE_ASSIGNED_PDA_CE_LEVEL_3 = 'ApproveAssignedPDACELevel3',
	APPROVE_ASSIGNED_PDA_CE_LEVEL_4 = 'ApproveAssignedPDACELevel4',
	APPROVE_ASSIGNED_PDA_CE_LEVEL_5 = 'ApproveAssignedPDACELevel5',

	APPROVE_ASSIGNED_DA = 'ApproveAssignedDA',
	APPROVE_ASSIGNED_DA_LEVEL_1 = 'ApproveAssignedDALevel1',
	APPROVE_ASSIGNED_DA_LEVEL_2 = 'ApproveAssignedDALevel2',
	APPROVE_ASSIGNED_DA_LEVEL_3 = 'ApproveAssignedDALevel3',
	APPROVE_ASSIGNED_DA_LEVEL_4 = 'ApproveAssignedDALevel4',
	APPROVE_ASSIGNED_DA_LEVEL_5 = 'ApproveAssignedDALevel5',

	APPROVE_DA_ALL = 'ApproveDAall',
	APPROVE_DA_ALL_LEVEL_1 = 'ApproveDAallLevel1',
	APPROVE_DA_ALL_LEVEL_2 = 'ApproveDAallLevel2',
	APPROVE_DA_ALL_LEVEL_3 = 'ApproveDAallLevel3',
	APPROVE_DA_ALL_LEVEL_4 = 'ApproveDAallLevel4',
	APPROVE_DA_ALL_LEVEL_5 = 'ApproveDAallLevel5',

	MANAGE_SAVINGS = 'ManageSavings',

	MANAGE_CONTACTS = 'ManageContacts',

	VIEW_PRINCIPAL_SPECIFIC_DATA = 'ViewPrincipalSpecificData',
	MANAGE_PRINCIPAL_SPECIFIC_DATA = 'ManagePrincipalSpecificData',
	MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS = 'ManagePrincipalSpecificDataISS',
	VIEW_PRINCIPAL_SPECIFIC_SERVICES = 'ViewPrincipalSpecificServices',
	MANAGE_PRINCIPAL_SPECIFIC_SERVICES = 'ManagePrincipalSpecificServices',

	MANAGE_BANK_ACCOUNT_DETAILS = 'ManageBankAccountDetails',
	VIEW_DIRECT_BILLS = 'ViewDirectBills',
	MANAGE_DIRECT_BILLS = 'ManageDirectBills',
	ACTS_AS_LPA = 'ActAsLpa',

	VIEW_SUPPLIER_INVOICES = 'ViewSupplierInvoices',
	MANAGE_SUPPLIER_INVOICES = 'ManageSupplierInvoices',
	MANAGE_CREDIT_SUPPLIER_INVOICES = 'ManageCreditSupplierInvoices',
	ACCESS_TO_REPORT_SERVER = 'AccessToReports',
	ACCESS_TO_BLT_APPLICATION = 'AccessToBltApplication',
	MANAGE_API_SUBSCRIPTION = 'ManageAPISubscription'
}

export interface ParentPermission {
	code: PermissionCode;
	name: string;
	applicableScopeTypes: GroupApplicableScopeTypes[];
	children: ParentPermission[];
	isEditableInUI: boolean;
	parentNode?: ParentPermission;
}

export type Permission = Pick<ParentPermission, 'code' | 'name'>;

export interface RetrievePermissionsRequest {
	groupTypeCode: GroupTypeCodes;
	groupId?: string;
}

export type RetrievePermissionsResponse = ResultCollection<ParentPermission>;

export enum EntityPermissionType {
	PORTCALL = 'portcall',
	PORTJOB = 'portjob',
	OPERATION = 'operation',
	CARGO = 'cargo',
	CUSTODY_TRANSFER_LINE = 'custodyTransferLine',
	ROTATION_STEP = 'rotationStep',
	LOADING_CARGO_LINE = 'LoadingCargoLine',
	DISCHARGING_CARGO_LINE = 'DischargingCargoLine'
}

export interface EntityPermission {
	canAdd: boolean;
	canUpdate: boolean;
	canDelete: boolean;
	canCancel?: boolean;
	canChangeCustodyRole?: boolean;
	// additional params for 'custodyTransferLine'
	canMoveUp?: boolean;
	canMoveDown?: boolean;
}

interface VPActionParams {
	portCallId: string;
	entityPermission?: keyof EntityPermission;
}

interface VPRequestParams {
	portCallId: string;
	custodyTransfer?: CustodyTransferType;
}

export interface EntityPermissionComposeId {
	entityType: EntityPermissionType;
	entityKey: string;
}

export interface EntityPermissionComposeIdGeneric<T>
	extends EntityPermissionComposeId {
	optionalParams: T;
}

export type EntityPermissionRequest = EntityPermissionComposeId;
export type EntityPermissionVPActionParams = EntityPermissionComposeIdGeneric<
	VPActionParams
>;
export type EntityPermissionVPRequestParams = EntityPermissionComposeIdGeneric<
	VPRequestParams
>;

export type EntityPermissionResponse = EntityPermission;

export const viewFinanceGroups = [
	PermissionCode.APPROVE_DA_ALL,
	PermissionCode.APPROVE_ASSIGNED_DA,
	PermissionCode.APPROVE_PDA_CE,
	PermissionCode.APPROVE_ASSIGNED_PDA_CE
];

export const approveDaAllSet = [
	PermissionCode.APPROVE_DA_ALL_LEVEL_1,
	PermissionCode.APPROVE_DA_ALL_LEVEL_2,
	PermissionCode.APPROVE_DA_ALL_LEVEL_3,
	PermissionCode.APPROVE_DA_ALL_LEVEL_4,
	PermissionCode.APPROVE_DA_ALL_LEVEL_5
];

export const approveAssignedDaSet = [
	PermissionCode.APPROVE_ASSIGNED_DA_LEVEL_1,
	PermissionCode.APPROVE_ASSIGNED_DA_LEVEL_2,
	PermissionCode.APPROVE_ASSIGNED_DA_LEVEL_3,
	PermissionCode.APPROVE_ASSIGNED_DA_LEVEL_4,
	PermissionCode.APPROVE_ASSIGNED_DA_LEVEL_5
];

export const approvePDACESet = [
	PermissionCode.APPROVE_PDA_CE_LEVEL_1,
	PermissionCode.APPROVE_PDA_CE_LEVEL_2,
	PermissionCode.APPROVE_PDA_CE_LEVEL_3,
	PermissionCode.APPROVE_PDA_CE_LEVEL_4,
	PermissionCode.APPROVE_PDA_CE_LEVEL_5
];

export const approveAssignedPDACESet = [
	PermissionCode.APPROVE_ASSIGNED_PDA_CE_LEVEL_1,
	PermissionCode.APPROVE_ASSIGNED_PDA_CE_LEVEL_2,
	PermissionCode.APPROVE_ASSIGNED_PDA_CE_LEVEL_3,
	PermissionCode.APPROVE_ASSIGNED_PDA_CE_LEVEL_4,
	PermissionCode.APPROVE_ASSIGNED_PDA_CE_LEVEL_5
];
