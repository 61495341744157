import { FetchStatus } from 'services/api/apiTypes';
import { getIsLoading } from 'store/selectors';
import { isArray } from 'lodash';

export const isLoading = (fetchStatus: FetchStatus | FetchStatus[]) => {
	if (isArray(fetchStatus)) {
		return fetchStatus.reduce(
			(previousLoading, status: FetchStatus) =>
				previousLoading || getIsLoading(status),
			false
		);
	}
	return getIsLoading(fetchStatus);
};
