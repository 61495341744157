import actionCreator from '../messagesActionCreator';
import {
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

const ACTION_NAME = 'RETRIEVE_MAILBOXES';
export const retrieveMessagesMailboxesAsync = actionCreator.async<
	RetrieveMailboxesRequest,
	RetrieveMailboxesResponse,
	Error
>(ACTION_NAME);
export const retrieveMessagesMailboxes = actionCreator<RetrieveMailboxesRequest | null>(
	ACTION_NAME
);
export const resetMessagesMailboxes = actionCreator('RESET_MAILBOXES');
