import { getThreadsMapSelector } from 'store/threads/selectors';
import { AppState } from 'store-types.d';
import { createSelector } from 'reselect';
import { IThread } from 'services/api/threads/threadsServiceTypes';
import { isEmpty } from 'lodash';
import { PORT_MESSAGES_FILTER } from '../portCallMessagesFiltersSync';

const getThreadIdsFromPortJob = (state: AppState) =>
	state.portjob.context.messages.allIds;

export const getActivePortJobThreadId = (state: AppState) =>
	state.portjob.context.activePortJobThreadId;

export const getThreadsInPortJob = createSelector(
	getThreadIdsFromPortJob,
	getThreadsMapSelector,
	(allIds, byId): IThread[] => allIds.map(id => byId[id])
);

export const getPortJobThreadsCountSelector = createSelector(
	getThreadIdsFromPortJob,
	allIds => allIds.length
);

export const getFirstThreadIdFromActiveJob = createSelector(
	getThreadsInPortJob,
	threads => (!isEmpty(threads) ? threads[0].id : undefined)
);

export const getMessagesFilters = (state: AppState) =>
	state.filters[PORT_MESSAGES_FILTER];
