import React from 'react';
import { FundingRequestStatus } from 'services/api/financeFundings/financeFundingsServiceTypes';
import { Text, Status } from 'components';
import { getFundingRequestStatusLabel } from 'store/financeFundings/selectors';

const colorStatusMap = {
	[FundingRequestStatus.REJECTED]: 'error',
	[FundingRequestStatus.FAILED]: 'error',
	[FundingRequestStatus.CANCELLED]: 'warning',
	[FundingRequestStatus.PENDING_REVIEW]: 'warning',
	[FundingRequestStatus.PENDING]: 'warning',
	[FundingRequestStatus.REVIEWED]: 'info',
	[FundingRequestStatus.SENT_FOR_PAYMENT]: 'info',
	[FundingRequestStatus.COMPLETE]: 'success'
};

interface FundingStatusLabelProps {
	status: FundingRequestStatus;
}

const FundingRequestStatusLabel = (props: FundingStatusLabelProps) => {
	const statusDot = colorStatusMap[props.status];
	return (
		<Text weight="bold">
			<Status.Label
				unstyled
				statusDot={statusDot}
				value={getFundingRequestStatusLabel(props.status)}
			/>
		</Text>
	);
};

export default FundingRequestStatusLabel;
