import {
	RetrievePrefundingRequest,
	RetrievePrefundingRequestResponse
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'RETRIEVE_PREFUNDING_REQUEST';

export const retrievePrefundingRequestAsync = actionCreator.async<
	RetrievePrefundingRequest,
	RetrievePrefundingRequestResponse,
	Error
>(ACTION_NAME);

export const retrievePrefundingRequest = actionCreator<
	RetrievePrefundingRequest
>(ACTION_NAME);
