import {
	PortCall,
	RetrievePortCallRequest
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../portCallsActionCreator';

const ACTION_NAME = 'RETRIEVE_PORT_CALL';

export const retrievePortCallAsync = actionCreator.async<
	RetrievePortCallRequest,
	PortCall,
	Error
>(ACTION_NAME, {
	failed: {
		returnUrl: '/portcalls'
	}
});

export const retrievePortCall = actionCreator<RetrievePortCallRequest>(
	ACTION_NAME
);

export const resetPortCall = actionCreator('RESET_PORT_CALL');
