import { UsersState } from '../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onInviteUserStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		inviting: FetchStatus.PENDING
	}
});
export const onInviteUserSuccess = (state: UsersState): UsersState => {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			inviting: FetchStatus.SUCCESS
		}
	};
};
export const onInviteUserFailed = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		inviting: FetchStatus.FAILURE
	}
});
