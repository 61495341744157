import * as React from 'react';
import { Flex, Truncate, FormattedTimeExtended, Link } from 'components';
import FormattedTimeTooltip from 'components/FormattedTimeExtended/FormattedTimeTooltip/FormattedTimeTooltip';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import {
	PortJob,
	PortJobOperation,
	PortJobTypeCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { withInfo } from 'components/FormattedTimeExtended/FormattedTimeExtended.module.scss';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import styles from '../PortJobList.module.scss';
import AssignJobToThreadButton from './AssignJobToThreadButton';
import CreateAppointmentButton from './CreateAppointmentButton';
import JobStatus from './JobStatus';
import { Justify } from 'components/types';
import { PortCallAlertsMap } from 'app-types';
import { DEFAULT_NOT_AVAILABLE_PLACEHOLDER } from 'app-constants';
import { UserType } from 'services/api/users/userServiceTypes';
import { Icon, Tooltip } from 'components/antd';
import LinkJobButton from './LinkJobButton';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission, PermissionContext } from 'containers';
import AssignDaPopUp from './AssignDaPopUp';
/**
 * Create the columns for the PortJobList.tsx component
 */
function getOperationsList(operations: PortJobOperation[]) {
	const operationsList = operations.map(operation => operation.name).join(', ');
	const popoverContent = <div>{operationsList}</div>;
	return (
		<span className={withInfo}>
			Multiple <FormattedTimeTooltip title={popoverContent} />
		</span>
	);
}

export const getPortJobColumns = (
	portCallId: PortCall['id'],
	activeWizard: WizardFormProps,
	userType: UserType,
	hasPortCallDuplicates: boolean | undefined,
	onPortJobClick?: (link: string) => void,
	portCallAlertsMap?: PortCallAlertsMap,
	checkPortJobCanView?: boolean,
	onOptionChange?: (portJobCode: string, portCallId: string) => void
): Array<ColumnProps<PortJob>> => {
	const columns = [];
	columns.push({
		title: 'Job ID',
		dataIndex: 'code',
		width: '14%',
		render: (code: string, portjob: PortJob) => {
			const link = `/portcalls/${portCallId}/jobs/${code}`;
			const isDisabled = checkPortJobCanView && portjob.canView === false;
			return (
				<Link
					href={link}
					tooltip={
						isDisabled
							? {
									title: `You don't have permission to view this Job`
							  }
							: undefined
					}
					disabled={isDisabled}
					style={{ verticalAlign: 'middle' }}
					onClick={onPortJobClick && (() => onPortJobClick(link))}
				>
					{code}
					{portjob.isOperationsDisabled && (
						<Icon type="operations-disable" color="standard" size={22} />
					)}
					&nbsp;
					{!portjob.financeEnabled && (
						<Icon type="finance-disable" color="standard" size={22} />
					)}
				</Link>
			);
		}
	});

	if (activeWizard && activeWizard.fromPath === 'nomination') {
		columns.push(
			{
				title: 'Job Type',
				dataIndex: 'jobType',
				render: (jobType: string, portJobs: PortJob) => {
					const { code } = portJobs;
					if (jobType === 'Nomination' || code.includes('HN')) {
						return 'Nomination';
					} else {
						return 'Appointment';
					}
				}
			},
			{
				title: 'Appointer',
				dataIndex: 'appointerCompany.name',
				render: (appointerCompany: string) => appointerCompany || '--'
			},
			{
				title: 'Nominator',
				dataIndex: 'nominatorCompany.name',
				render: (nominatorCompany: string) => nominatorCompany || '--'
			}
		);
	} else {
		columns.push(
			{
				title: 'Operation',
				dataIndex: 'operations',
				render: (operations: PortJobOperation[]) => {
					if (!operations || operations.length === 0) {
						return '--';
					}
					return operations.length > 1
						? getOperationsList(operations)
						: operations[0].name;
				}
			},
			{
				title: 'Principal',
				dataIndex: 'jobType',
				render: (jobType: string, portJobs: PortJob) => {
					const { appointerCompany, nominatorCompany } = portJobs;
					if (
						(jobType === PortJobTypeCode.NOMINATION ||
							jobType === PortJobTypeCode.CHARTERER_OVERSIGHT) &&
						!!nominatorCompany
					) {
						return nominatorCompany.name || '--';
					}
					return appointerCompany ? appointerCompany.name : '--';
				}
			},
			{
				title: 'Customer Ref',
				dataIndex: 'customerReference',
				render: (reference: string) => (
					<Truncate words={8}>{reference || '--'}</Truncate>
				)
			},
			{
				title: () => {
					return 'Main Contact';
				},
				dataIndex: 'contact1.name',
				render: (contact: string) => contact || '--'
			}
		);
	}

	columns.push(
		{
			title: 'Performing Agent',
			dataIndex: 'performingAgentCompany.name'
		},
		{
			title: 'Job Status',
			dataIndex: 'status',
			width: '13.5%',
			render: (status: string, record: PortJob) => (
				<JobStatus
					status={status}
					portJob={record}
					portCallAlertsMap={portCallAlertsMap}
				/>
			)
		}
	);

	if (userType === UserType.PRINCIPAL) {
		columns.push({
			title: 'DA Ageing',
			dataIndex: 'daAgeing',
			render: (daAgeing: string) =>
				daAgeing ? (
					<Tooltip title="DA Ageing is calculated only for Original DA set, Supplementals sets are not taken into consideration">{`${daAgeing} day(s)`}</Tooltip>
				) : (
					<Tooltip title="Parameter can't be calculated because COSP is not specified">
						{DEFAULT_NOT_AVAILABLE_PLACEHOLDER}
					</Tooltip>
				)
		});
	}

	columns.push({
		title: 'Last Update',
		width: '9%',
		dataIndex: 'updatedOnUtc',
		render: (updatedOnUtc: PortJob['updatedOnUtc']) => (
			<FormattedTimeExtended timeAgo value={updatedOnUtc} convertFromUtc />
		)
	});

	if (!(userType === UserType.HUB) && !activeWizard && !hasPortCallDuplicates) {
		columns.push({
			title: 'Assign to',
			dataIndex: 'daAssigned',
			width: '8%',
			render: (daAssigned: { id: string; name: string }, portjob: PortJob) => (
				<Tooltip
					title={portjob.daAssignedGroup.map(name => `${name}`).join('|')}
				>
					<span
						className={
							portjob.daAssignedGroup.length > 0 && daAssigned
								? ''
								: styles.inactiveGroupMember
						}
					>
						{daAssigned.name}
					</span>
				</Tooltip>
			)
		});
	}

	if (userType === UserType.HUB && !activeWizard && !hasPortCallDuplicates) {
		columns.push({
			title: 'Assign to',
			dataIndex: 'daAssigned',
			width: '8%',
			render: (daAssigned: { id: string; name: string }, portjob: PortJob) =>
				(portjob.financeStatus === FinanceStatus.SUBMITTED ||
					(portjob.financeStatus === FinanceStatus.QUERIED &&
						!portjob.queriedToLPA)) &&
				portjob.status === PortJobStatus.DA ? (
					<>
						<div id={`${portjob.code}-assign-pop-up`}>
							<PermissionContext.Provider
								value={{
									companyId: portjob.hubPrincipalCompany?.id
										? portjob.hubPrincipalCompany.id
										: null
								}}
							>
								<Permission permissionCode={PermissionCode.ASSIGN_DA}>
									{hasPermission => (
										<>
											{hasPermission ? (
												<AssignDaPopUp
													portCallId={portCallId}
													jobCode={portjob.code}
													portJob={portjob}
													daAssigned={daAssigned.name}
												/>
											) : (
												<Tooltip
													title={portjob.daAssignedGroup
														.map(name => `${name}`)
														.join('|')}
												>
													<span
														className={
															portjob.daAssignedGroup.length > 0 && daAssigned
																? ''
																: styles.inactiveGroupMember
														}
													>
														{daAssigned.name}
													</span>
												</Tooltip>
											)}
										</>
									)}
								</Permission>
							</PermissionContext.Provider>
						</div>
					</>
				) : (
					<Tooltip
						title={portjob.daAssignedGroup.map(name => `${name}`).join('|')}
					>
						<span
							className={
								portjob.daAssignedGroup.length > 0 && daAssigned
									? ''
									: styles.inactiveGroupMember
							}
						>
							{daAssigned.name}
						</span>
					</Tooltip>
				)
		});
	}

	if (
		Boolean(activeWizard) &&
		activeWizard.mode === 'assign' &&
		activeWizard.fromPath === 'nomination'
	) {
		columns.push({
			className: styles.center,
			// eslint-disable-next-line
			render: (_item: any, portjob: PortJob) => (
				<div id={`${portjob.code}-create-appointment-container`}>
					<CreateAppointmentButton
						hubPrincipalCompanyId={portjob.hubPrincipalCompany?.id}
						isAppointer={portjob.appointerCompany ? true : false}
						isDraftAppointer={portjob.isDraftAppointer}
						jobCode={portjob.code}
						portCallId={portCallId}
						portJobStatus={portjob.status}
						isLinkedJob={portjob.linkedJobs.length > 0}
					/>
				</div>
			)
		});
	} else if (Boolean(activeWizard) && activeWizard.mode === 'assign') {
		columns.push({
			className: styles.center,
			// eslint-disable-next-line
			render: (_item: any, portjob: PortJob) => (
				<div id={`${portjob.code}-assign-job-container`}>
					<AssignJobToThreadButton
						jobCode={portjob.code}
						portCallId={portCallId}
					/>
				</div>
			)
		});
	} else if (Boolean(activeWizard) && activeWizard.mode === 'jobLinking') {
		columns.push({
			className: styles.center,
			// eslint-disable-next-line
			render: (_item: any, portjob: PortJob) => (
				<div id={`${portjob.code}-link-job-container`}>
					<LinkJobButton
						jobCode={portjob.code}
						portCallId={portCallId}
						onClick={onOptionChange}
						disabled={!portjob.isAppointmentlinkedToNomination}
						appointmentMainPrinciple={portjob.hubPrincipalCompany?.name}
					/>
				</div>
			)
		});
	} else {
		columns.push({
			title: 'Open Items',
			dataIndex: 'openItems',
			className: styles.center,
			render: (_openItems: string[]) => <Flex justify={Justify.CENTER}>--</Flex>
		});
	}
	return columns;
};
