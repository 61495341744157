import { WizardFormState } from '../wizardFormState';
import { initPages } from '../wizardFormUtils';
import { WizardFormInitialize } from '../actions/initialize';
export const onInitialize = (
	state: WizardFormState,
	action: WizardFormInitialize
): WizardFormState => {
	const { name, ...wizard } = action;
	const activePage = wizard.activePage || 1;
	const startPage = wizard.startPage || 1;
	const form = {
		...state.forms[name],
		...wizard,
		activePage,
		startPage,
		pages: initPages(wizard.pages, startPage)
	};
	return {
		...state,
		activeWizardFormId: name,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
