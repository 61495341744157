import { isEmpty, isUndefined, omitBy } from 'lodash';
import { FormErrors } from 'redux-form';
import {
	validateCheckboxGroupRequired,
	arrayMin,
	validateRequired
} from 'utils/validations';

import { FormData, FormPageData } from './ContextualizationFormTypes';
import { ContextualizationFormProps } from './ContextualizationForm';

const validatePage = (page: FormPageData) => {
	if (page.ignorePage) {
		return;
	}

	const error: Partial<FormPageData> = {
		documentTypeCode: validateRequired(page.documentTypeCode),
		/**
		 * Note: the next validation structure won't influence form state,
		 * In order to mark form as valid/invalid - the initial state structure should be repeated
		 * Since `onDocumentChange` isn't triggered for the next page when previous page is copied (TODO has to be investigated),
		 * we can't know ids, which means can't repeat the structure. Instead, global `_error` is set.
		 */
		placeholders: validateCheckboxGroupRequired(page.placeholders)
	};

	const jobsError = arrayMin(1)(page.jobs);
	if (jobsError) {
		error.jobs = [jobsError];
	}

	return omitBy(error, isUndefined);
};

/**
 * SyncErrors result is used in `prepareRequest` to determine which pages should be submitted,
 * so it is important to properly return validation state of each page.
 */
const validate = (
	values: FormData,
	{ activePage }: ContextualizationFormProps
): FormErrors<FormData, string | boolean> => {
	const errors: FormErrors<FormData, string | boolean> = {};

	if (isEmpty(values.pages)) {
		return errors;
	}

	const pagesErrors: FormPageData[] = [];
	values.pages.forEach((page, index) => {
		const error = validatePage(page);

		if (error && !isEmpty(error)) {
			pagesErrors[index] = error;
		}
	});
	errors.pages = pagesErrors as any; // eslint-disable-line @typescript-eslint/no-explicit-any

	const activePageState = values.pages[activePage - 1];
	return {
		...errors,
		_error: Boolean(
			activePageState &&
				!activePageState.ignorePage &&
				validateCheckboxGroupRequired(activePageState.placeholders)
		)
	} as FormErrors<FormData, string | boolean>;
};

export default validate;
