import actionCreatorFactory from 'typescript-fsa';
import { RetrieveInternalCommentsResponse } from 'services/api/threads/threadsServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_INTERNAL_COMMENTS = 'comments/RETRIEVE_INTERNAL_COMMENTS';

export const retrieveInternalCommentsAsync = actionCreator.async<
	string,
	RetrieveInternalCommentsResponse,
	Error
>(RETRIEVE_INTERNAL_COMMENTS);

export const retrieveInternalComments = actionCreator<string>(
	retrieveInternalCommentsAsync.type
);
