import actionCreatorFactory from 'typescript-fsa';

import {
	RetrieveTemplateRenderRequest,
	TemplateRendered
} from 'services/api/templates/templateServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_TEMPLATE_RENDER = 'templates/RETRIEVE_TEMPLATE_RENDER';

export const retrieveTemplateRenderAsync = actionCreator.async<
	RetrieveTemplateRenderRequest,
	TemplateRendered,
	Error
>(RETRIEVE_TEMPLATE_RENDER);

export const retrieveTemplateRender = actionCreator<
	RetrieveTemplateRenderRequest
>(retrieveTemplateRenderAsync.type);
