import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	updateAssignToThreadsAsync,
	setReloadThreadsFlag
} from 'store/threads/actions';
import { AssignToThreadsRequest } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';
import { put, delay } from 'redux-saga/effects';
import { closeModal } from 'store/modals/actions';
import {
	ThreadsSettingsMenuAction,
	ThreadsSettingsMenuModalMap
} from 'sections/ThreadsList/ThreadCategorizing/constants';
import { notify } from 'store/notifications/actions';

const apiCall = Api.Threads.updateAssignToThreads;

export const updateAssignToThreadsWatcher = makeTakeLatestWatcher<
	AssignToThreadsRequest,
	null,
	Error
>({
	api: apiCall,
	async: updateAssignToThreadsAsync,
	*onSuccess() {
		yield put(
			closeModal(
				ThreadsSettingsMenuModalMap[ThreadsSettingsMenuAction.ASSIGN_TO]
			)
		);
		yield put(setReloadThreadsFlag(true));
		yield put(notify.success('Assignment has been successfully updated.'));
		delay(1000);
		yield put(setReloadThreadsFlag(false));
	},
	*onError() {
		yield put(notify.error('Something went wrong. Please try again later'));
	}
});

export default updateAssignToThreadsWatcher;
