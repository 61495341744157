import actionCreator from 'store/portCall/portCallActionCreator';
import { RetrieveJobThreadsRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { ThreadsFilterStatus } from 'types/sections/ThreadsTypes';

export interface SetPortCallThreadsFiltersParams
	extends Pick<RetrieveJobThreadsRequest, 'onlyUnread'> {
	currentFilter?: ThreadsFilterStatus;
}

/**
 * Filters applied for Api.PortJobs.retrievePortJobThreads triggered by `retrievePortJobThreads` action
 * `setPortCallThreadsFilters` was placed here since they are related rather to port call than to port job
 * @type {ActionCreator<SetPortCallThreadsFiltersParams>}
 */
export const setPortCallThreadsFilters = actionCreator<
	SetPortCallThreadsFiltersParams
>('SET_PORT_CALL_THREADS_FILTERS');

export const resetPortCallThreadsFilters = actionCreator(
	'RESET_PORT_CALL_THREADS_FILTERS'
);
