import { Success } from 'typescript-fsa';
import {
	RetrieveKPIDetailsByIdResponse as Response,
	RetrieveKPIDetailsByIdRequest as Request
} from 'services/api/opticConfiguration/opticConfigServiceTypes';
import { OpticConfigState } from '../opticConfigState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveKpiDetailsByIdSuccess = (
	state: OpticConfigState,
	{ result }: Success<Request, Response>
) => ({
	...state,
	editKpi: {
		...result
	}
});

export const onRetrieveKpiDetailsById = makeFetchStatusReducers<
	OpticConfigState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'retrieveKPIDetailsById', {
	doneReducer: onRetrieveKpiDetailsByIdSuccess
});
