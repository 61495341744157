import actionCreator from '../companyVesselTypesActionCreator';
import {
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse
} from 'services/api/companies/companiesServiceTypes';
const ACTION_NAME = 'RETRIEVE';

export const retrieveCompanyVesselTypesAsync = actionCreator.async<
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselTypesResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanyVesselTypes = actionCreator<
	RetrieveCompanyVesselTypesRequest
>(ACTION_NAME);
