import { all, fork } from 'redux-saga/effects';

import PortsSagas from './ports/sagas';
import VesselsSagas from './vessels/sagas';
import RegionsSagas from './regions/sagas';
import BankAccountsSagas from './bankAccounts/sagas';
import ServicesSagas from './services/sagas';

export default function*() {
	yield all([
		fork(PortsSagas),
		fork(VesselsSagas),
		fork(RegionsSagas),
		fork(BankAccountsSagas),
		fork(ServicesSagas)
	]);
}
