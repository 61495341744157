import { PORT_CALL_TABS } from 'store/portcalls/portCallsConstants';
import {
	PortCallTabKey,
	PortCallOperationsTabKey
} from 'store/portcalls/portCallsTypes';
import { PortCallLayoutProps } from './PortCallLayout';
import { includes, get, findLast } from 'lodash';
import { PortCallTabBaseProps } from 'sections/PortCall';

export const getPortCallId = (match: PortCallTabBaseProps['match']) =>
	match.params.portCallId;

export const getPortJobCode = (match: PortCallTabBaseProps['match']) =>
	match.params.portJobCode;

export const getPortCallThreadId = (props: PortCallLayoutProps) =>
	props.match.params.threadId;

export const getDefaultJobCode = (props: PortCallLayoutProps): string => {
	return props.portcall ? props.portcall.jobs[0].code : props.activePortJobCode;
};

export const getActivePortCallTabId = (
	match: PortCallTabBaseProps['match']
) => {
	let urlSections = match.path.split('/');
	urlSections = urlSections.filter(section => {
		const trimmedSection = section.replace(':', '');
		return !get(match, `params[${trimmedSection}]`);
	});
	const key = findLast(urlSections, el => {
		return includes(Object.keys(PORT_CALL_TABS), el);
	});
	return key ? (key as PortCallTabKey) : PortCallTabKey.OVERVIEW;
};

export const getPortCallOperationsTab = (
	match: PortCallTabBaseProps['match']
): PortCallOperationsTabKey =>
	match.params.operationTabId || PortCallOperationsTabKey.EVENTS;
