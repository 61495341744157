import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'components/antd';
import { Flex } from 'components';

import styles from './NewToolbar.module.scss';
import { UseDocumentContext } from '../../DocumentContext';
import ColorSelectorPopOver from './ColorSelectorPopOver';
import classNames from 'classnames';
import ZoomComponent from './ZoomComponent';
import { DrawingMode } from '../ImageViewer/ImageViewerV2Types';
import { getIsDocumentProcessingLoading } from 'store/documents/selectors';
import {
	getIsDeleteFinanceDocumentOriginalAndAnnotatedLoading,
	getIsRestoreOriginalDocumentLoading
} from 'store/finance/selectors';

interface NewToolbarProps {
	isAnnotatable: boolean;
	isDocumentAnnotated: boolean;
	hadAnnotatedPage?: boolean;
	isSupplierInvoice?: boolean;
	onRestoreOriginal: () => void;
	onDelete: () => void;
}
const NewToolbar: React.FC<NewToolbarProps> = ({
	isAnnotatable,
	isDocumentAnnotated,
	hadAnnotatedPage,
	isSupplierInvoice,
	onRestoreOriginal,
	onDelete
}) => {
	const context = React.useContext(UseDocumentContext);
	const [isModalVisible, setModalVisible] = React.useState(false);
	const [isRestoreModalVisible, setRestoreModalVisible] = React.useState(false);
	const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);

	const changeMode = (mode: DrawingMode) => {
		context.setMode(mode);
		context.actions.onModeChange();
	};
	const isUploading = useSelector(getIsDocumentProcessingLoading);
	const isRestoring = useSelector(getIsRestoreOriginalDocumentLoading);

	const isDeleting = useSelector(
		getIsDeleteFinanceDocumentOriginalAndAnnotatedLoading
	);

	const saveVersion = () => {
		if (hadAnnotatedPage) {
			setModalVisible(true);
		} else {
			context.actions.onUploadAnnotation();
		}
	};

	return (
		<>
			<Flex className={styles.toolbarHead} direction="horizontal">
				<Flex className={styles.iconCol} direction="horizontal">
					<Flex direction="horizontal">
						<Flex>
							{isAnnotatable && (
								<>
									<span className={styles.spacer1} />
									<Button
										icon="download-new"
										iconSize="lg"
										disabled={context.isDocumentPreparing}
										onClick={() => context.actions.onSave()}
									/>
									<span className={styles.spacer2} />
									<Button
										icon="text"
										iconSize="lg"
										onClick={() => changeMode(DrawingMode.TEXT)}
										className={classNames({
											[styles.primary]: context.mode === DrawingMode.TEXT
										})}
									/>
									<Button
										icon="box"
										iconSize="lg"
										onClick={() => changeMode(DrawingMode.RECT)}
										className={classNames({
											[styles.primary]: context.mode === DrawingMode.RECT
										})}
									/>
									<Button
										icon="draw"
										iconSize="lg"
										onClick={() => changeMode(DrawingMode.LINE)}
										className={classNames({
											[styles.primary]: context.mode === DrawingMode.LINE
										})}
									/>
									<ColorSelectorPopOver
										color={context.color}
										onColorChange={context.setColor}
									/>
									<span className={styles.spacer2} />
									<Button
										icon="remove-annotations"
										iconSize="lg"
										className={styles.danger}
										disabled={context.isHistoryEmpty}
										onClick={() => context.actions.onUndo()}
									/>
									<Button
										icon="delete-new"
										iconSize="lg"
										className={classNames(styles.danger, {
											[styles.hide]: isSupplierInvoice
										})}
										disabled={!hadAnnotatedPage || isDeleting}
										onClick={() => setDeleteModalVisible(true)}
										tooltip={
											hadAnnotatedPage && !isSupplierInvoice
												? {
														title: 'Original and annotated page will be deleted'
												  }
												: undefined
										}
									/>
								</>
							)}
						</Flex>
					</Flex>
					<ZoomComponent />
				</Flex>
				<Flex direction="horizontal" className={styles.flexButtons}>
					<Flex>
						{isAnnotatable && (
							<>
								<Button
									type="primary"
									onClick={saveVersion}
									disabled={!context.isAnnotated || context.isDocumentPreparing}
									loading={isUploading}
								>
									Save Version
								</Button>
								<Button
									type="primary"
									ghost
									disabled={!isDocumentAnnotated}
									onClick={() => setRestoreModalVisible(true)}
									loading={isRestoring}
								>
									Restore Original
								</Button>
							</>
						)}
					</Flex>
				</Flex>
			</Flex>
			<Modal
				visible={isModalVisible}
				closable={false}
				cancelText="Cancel"
				okText="Confirm"
				onOk={() => {
					setModalVisible(false);
					context.actions.onUploadAnnotation();
				}}
				onCancel={() => setModalVisible(false)}
				okProps={{
					disabled: context.isDocumentPreparing
				}}
			>
				Previously annotated page will be over-written. Are you sure you want to
				continue?
			</Modal>
			<Modal
				visible={isRestoreModalVisible}
				closable={false}
				cancelText="Cancel"
				okText="Confirm"
				onOk={() => {
					onRestoreOriginal();
					setRestoreModalVisible(false);
				}}
				onCancel={() => setRestoreModalVisible(false)}
			>
				Restore will permanently delete all the annotated pages in document. Are
				you sure you want to continue?
			</Modal>
			<Modal
				visible={isDeleteModalVisible}
				closable={false}
				cancelText="Cancel"
				okText="Confirm"
				onOk={() => {
					onDelete();
					setDeleteModalVisible(false);
				}}
				onCancel={() => setDeleteModalVisible(false)}
			>
				Do you want to delete both - original and updated version? Please
				confirm.
			</Modal>
		</>
	);
};

export default React.memo(NewToolbar);
