import { AssignToThreadsRequest } from 'services/api/threads/threadsServiceTypes';
import actionCreator from '../threadsActionCreator';

const UPDATE_ASSIGN_TO_THREADS = 'UPDATE_ASSIGN_TO_THREADS';

export const updateAssignToThreadsAsync = actionCreator.async<
	AssignToThreadsRequest,
	null,
	Error
>(UPDATE_ASSIGN_TO_THREADS, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateAssignToThreads = actionCreator<AssignToThreadsRequest>(
	UPDATE_ASSIGN_TO_THREADS
);
