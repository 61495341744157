import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { getIsLoading } from 'store/selectors';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';

export const getCompanyVesselsState = (state: AppState) => state.companyVessels;
export const getIsCompanyVesselsHasMore = (state: AppState) =>
	state.companyVessels.hasMore;

export const getCompanyVesselsMap = (state: AppState) =>
	getCompanyVesselsState(state).byId;

export const getCompanyVessels = createSelector(
	getCompanyVesselsMap,
	companyVessels => Object.values(companyVessels)
);

export const getCompanyVesselsCount = createSelector(
	getCompanyVessels,
	companyVessels => companyVessels.length
);

export const getCompanyVesselsByCompanyId = createSelector(
	getCompanyVessels,
	(_state: AppState, companyId: string | undefined) => companyId,
	(companyVessels, companyId) =>
		(companyId &&
			companyVessels.filter(item => item.companyId === companyId)) ||
		[]
);

export const getCompanyVesselsByCompanyIdVesselId = createSelector(
	getCompanyVesselsByCompanyId,
	(_state: AppState, _companyId: string, vesselId: string) => vesselId,
	(companyVessels, vesselId) =>
		companyVessels.find(companyVessel => companyVessel.vesselId === vesselId)
);

export const getCompanyVesselsMapByVesselId = createSelector(
	getCompanyVesselsByCompanyId,
	(_state: AppState, _companyId: string, vesselId: string | undefined) =>
		vesselId,
	(companyVessels, vesselId) =>
		(vesselId &&
			companyVessels.filter(
				companyVessel => companyVessel.vessel.id === vesselId
			)) ||
		[]
);

export const getIsCompanyVesselsFetchingRetrieve = (state: AppState) =>
	getIsLoading(getCompanyVesselsState(state).fetchStatuses.retrieve);

const getCompanyVesselsContext = (state: AppState) =>
	getCompanyVesselsState(state).context;

export const getActiveVesselSearchResult = (state: AppState) =>
	getCompanyVesselsContext(state).activeVesselSearchResult;

export const getIsActiveVesselSearchResultMapped = (state: AppState) =>
	getCompanyVesselsContext(state).activeVesselSearchResultMapped;

interface CompanyVesselsInPortCall {
	[portCallId: string]: CompanyVessel[];
}
export const getCompanyVesselsInPortCalls = createSelector(
	getCompanyVesselsState,
	({ byId, byPortCallId }): CompanyVesselsInPortCall =>
		Object.entries(byPortCallId).reduce(
			(
				result: CompanyVesselsInPortCall,
				[portCallId, vesselIds]: [string, string[]]
			) => ({
				...result,
				[portCallId]: vesselIds.map(id => byId[id])
			}),
			{}
		)
);

export const getCompanyVesselsInPortCall = (
	state: AppState,
	portCallId: string
) => getCompanyVesselsInPortCalls(state)[portCallId] || [];

export const getCompanyVesselsMapByRecordId = createSelector(
	getCompanyVesselsByCompanyId,
	(_state: AppState, _companyId: string, id: string) => id,
	(companyVessels, id) =>
		companyVessels.find(companyVessel => companyVessel.id === id)
);
