import React, { Component } from 'react';
import {
	change,
	FieldArray,
	InjectedFormProps,
	reduxForm,
	WrappedFieldArrayProps
} from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import {
	ENABLE_OPERATIONS_FORM,
	FormData,
	FormDataObj,
	FormField
} from './EnableOperationsFormTypes';
import styles from './EnableOperationsForm.module.scss';
import { Gap, Text } from 'components';
import { Col, Row, Select } from 'components/antd';
import { validate } from './validate';
import { ServiceSelect } from './ServiceSelect';
import { useDispatch } from 'react-redux';

interface EnableOperationsFormBaseProps {}

export type EnableOperationsFormProps = InjectedFormProps<
	FormData,
	EnableOperationsFormBaseProps
>;

type EnableOperationsFormFieldsArrayProps = WrappedFieldArrayProps<FormDataObj>;

const EnableOperationsFieldArray = ({
	fields
}: EnableOperationsFormFieldsArrayProps) => (
	<>
		{fields.map((field, index) => (
			<EnableOperationsFormField
				key={index}
				field={field}
				data={fields.get(index)}
			/>
		))}
	</>
);

const EnableOperationsFormField = ({
	field,
	data
}: {
	field: string;
	data: FormDataObj;
}) => {
	const dispatch = useDispatch();
	const onSectionChange = (fieldName: string) => {
		dispatch(change(ENABLE_OPERATIONS_FORM, fieldName, null));
	};

	return (
		<div>
			{data.serviceSection.length > 0 && (
				<>
					<Gap bottom="md">
						<Text weight="bold" upper>
							Select Service Section
						</Text>
					</Gap>
					<p>
						The following Service(s) need to be mapped to a service section.
						Please select the appropriate Service Section below.
					</p>
					<Row>
						<Col xs={3}>
							<Text weight="bold">Service</Text>
						</Col>
						<Col xs={4}>
							<Text weight="bold">Service Section</Text>
						</Col>
					</Row>
					{data.serviceSection.map((service, index) => (
						<Row key={index} middle="xs">
							<Col xs={3}>
								<div>
									<Text weight="semi-bold">{service.serviceName}</Text>
								</div>
							</Col>
							<Col xs={4} className={styles.formItem}>
								<Select.ReduxFormItem
									key={index}
									name={`${field}.${FormField.SERVICE_SECTION}.${index}.section`}
									placeholder="Select Service Section"
									labelInValue
									required
									getPopupContainer={() => document.body}
								>
									{service.sectionList.map(section => (
										<Select.Option key={section.code} title={section.name}>
											{section.name}
										</Select.Option>
									))}
								</Select.ReduxFormItem>
							</Col>
						</Row>
					))}
				</>
			)}
			{data.alternateServiceSection.length > 0 && (
				<>
					<Gap bottom="md" top="md">
						<Text weight="bold" upper>
							Choose Alternative Service
						</Text>
					</Gap>
					<p>
						The following Service(s) are not mapped to any service scope. To
						enable Operations, the listed service needs to be changed
					</p>
					{data.alternateServiceSection.map((service, index) => (
						<Row key={index} middle="xs">
							<Col xs={3}>
								<div>
									<Text weight="semi-bold">{service.serviceName}</Text>
								</div>
							</Col>
							<Col xs={4} className={styles.formItem}>
								<Select.ReduxFormItem
									key={index}
									name={`${field}.${FormField.ALTERNATIVE_SECTION}.${index}.altSection`}
									placeholder="Select Service Section"
									getPopupContainer={() => document.body}
									dropdownClassName={styles.serviceSectionDropDownSelect}
									labelInValue
									required
									onChange={() =>
										onSectionChange(
											`${field}.${FormField.ALTERNATIVE_SECTION}.${index}.service`
										)
									}
								>
									{service.vpSectionServices.map(vpSection => (
										<Select.Option key={vpSection.code} title={vpSection.name}>
											{vpSection.name}
										</Select.Option>
									))}
								</Select.ReduxFormItem>
							</Col>
							{service.altSection?.key && (
								<Col xs={4} className={styles.formItem}>
									<ServiceSelect
										index={index}
										name={`${field}.${FormField.ALTERNATIVE_SECTION}.${index}.service`}
										entityId={service.altSection.key}
									/>
								</Col>
							)}
						</Row>
					))}
				</>
			)}
		</div>
	);
};

class EnableOperationsForm extends Component<EnableOperationsFormProps> {
	render() {
		return (
			<>
				<FieldArray<{}> name={'list'} component={EnableOperationsFieldArray} />
			</>
		);
	}
}

export default reduxForm<FormData, EnableOperationsFormBaseProps>({
	...DEFAULT_REDUX_FORM_CONFIG,
	form: ENABLE_OPERATIONS_FORM,
	validate
})(EnableOperationsForm);
