import * as React from 'react';
import { connect } from 'react-redux';
import AutocompleteEntity, {
	AutocompleteEntityProps
} from '../AutocompleteEntity';
import Api from 'services/api';
import {
	SearchCompaniesRequest,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { getParentId } from './autocompleteSelectors';
import { onSelectCompany } from '../AutocompleteTypes';
import { AutocompleteProps } from 'components/antd/Autocomplete/Autocomplete';
import { AppState } from 'store-types';

export interface AutocompleteCompanyProps extends AutocompleteEntityProps {
	hasParentId?: string | null;
	params?: SearchCompaniesRequest & { organisationTypes?: OrganisationType[] };
	onSelect?: onSelectCompany;
	onChange?: AutocompleteProps['onChange'];
	dataQa?: string; //for QAA
}

/**
 * Default Autocomplete
 */
class AutocompleteCompany extends React.Component<AutocompleteCompanyProps> {
	static WithParentId: React.ComponentType;
	static defaultProps: Partial<AutocompleteCompanyProps> = {
		hasParentId: null
	};

	onSearch = (searchTerm: string) => {
		const { hasParentId, params, onSearch } = this.props;
		if (onSearch) {
			return onSearch(searchTerm);
		}

		const request: SearchCompaniesRequest = {
			searchTerm,
			sortBy: 'name:asc',
			withParentId: hasParentId,
			types: params?.organisationTypes,
			onlyActive: true, // to not show deleted companies
			...params
		};
		return Api.Companies.searchCompaniesForAutocomplete(request);
	};

	render() {
		const { hasParentId, params, ...props } = this.props;
		return <AutocompleteEntity {...props} onSearch={this.onSearch} />;
	}
}

/**
 * Use it WithParentId
 */
const WithParentId = (
	WrappedComponent: React.ComponentType<Partial<AutocompleteCompanyProps>>
) => {
	const AutocompleteWithParentId = (
		props: Partial<AutocompleteCompanyProps>
	) => <WrappedComponent {...props} />;
	return connect((state: AppState) => ({
		hasParentId: getParentId(state)
	}))(AutocompleteWithParentId);
};

AutocompleteCompany.WithParentId = WithParentId(AutocompleteCompany);

export default AutocompleteCompany;
