import {
	MetadataAction,
	MetadataDisabledAction,
	MetadataEnabledAction
} from 'app-types';
import { MetadataStatusCode } from 'app-constants';
import { disableReasonMap, confirmationFinanceReasonMap } from 'app-labels';
import { EntityMenuItem, EntityMenuItemMap } from './metadataTypes';

export const getEntityMetadataAction = <Enum = string>(
	actions: Array<MetadataAction<Enum>>,
	actionCode: Enum
) => actions.find(action => action.actionCode === actionCode);

export const getEntityMetadataActions = <Enum = string>(
	actions: Array<MetadataAction<Enum>>,
	actionCodes: Enum[]
) => actions.filter(action => actionCodes.includes(action.actionCode));

export const getIsActionAllowed = <Enum>(
	action?: MetadataAction<Enum>
): action is MetadataEnabledAction<Enum> => {
	return Boolean(action && action.statusCode === MetadataStatusCode.ENABLED);
};

export const getIsActionDisabled = <Enum>(
	action?: MetadataAction<Enum>
): action is MetadataDisabledAction<Enum> => {
	return Boolean(action && action.statusCode === MetadataStatusCode.DISABLED);
};

export const getReasonMessageByAction = <ActionCode>(
	action?: MetadataAction<ActionCode>
): string => {
	if (!action) {
		return '';
	}
	switch (action.statusCode) {
		case MetadataStatusCode.DISABLED:
			return action.disabledReasonCode
				? disableReasonMap[action.disabledReasonCode]
				: '';
		case MetadataStatusCode.CONFIRMATION_REQUIRED:
			return action.confirmationReasonCode
				? confirmationFinanceReasonMap[action.confirmationReasonCode]
				: '';
		default:
			return '';
	}
};

export const getEntityMenuItems = function<ActionCode>(
	actions: MetadataAction<ActionCode>[],
	menuItemMap: EntityMenuItemMap<ActionCode>
) {
	const actionCodes = actions.map(action => action.actionCode);
	const keys: unknown[] = Object.keys(menuItemMap);
	const items: EntityMenuItem<ActionCode>[] = (keys as ActionCode[])
		.filter(actionCode => actionCodes.includes(actionCode))
		.map(actionCode => {
			const metadataAction = getEntityMetadataAction<ActionCode>(
				actions,
				actionCode
			);
			const menuItemSettings: EntityMenuItem<ActionCode> = {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				...menuItemMap[actionCode as any]
			};

			menuItemSettings.disabled =
				metadataAction?.statusCode === MetadataStatusCode.DISABLED;
			menuItemSettings.tooltip =
				getIsActionDisabled(metadataAction) && metadataAction.disabledReasonCode
					? disableReasonMap[metadataAction.disabledReasonCode]
					: undefined;
			return menuItemSettings;
		});
	return items;
};
