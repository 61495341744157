import {
	FinanceRequest,
	RetrieveBenchmarkStatus
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_BENCHMARK_STATUS = 'RETRIEVE_BENCHMARK_STATUS';
export const retrieveBenchmarkStatusAsync = actionCreator.async<
	FinanceRequest,
	RetrieveBenchmarkStatus,
	Error
>(RETRIEVE_BENCHMARK_STATUS);

export const retrieveBenchmarkStatus = actionCreator<FinanceRequest>(
	RETRIEVE_BENCHMARK_STATUS
);
