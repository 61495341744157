import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { DownloadSingleDARequest } from 'services/api/finance/financeServiceTypes';
import { FinanceState } from 'store/finance/financeState';

export const onDownloadSingleDA = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DownloadSingleDARequest,
	RetrieveDownloadDocumentResponse
>(
	'fetchStatuses',
	(params: DownloadSingleDARequest): keyof FinanceState['fetchStatuses'] => {
		const id = params.disbursementAccountId;
		return `downloadDAById.${id}` as keyof FinanceState['fetchStatuses'];
	}
);
