import { FinanceMetadataActionCode } from 'services/api/finance/financeServiceTypes';

export const menuItemMap = {
	[FinanceMetadataActionCode.ADD_CREDIT_HUB_REVENUE_SERVICE_CHANGE_REQUESTED]: {
		text: 'Add credit',
		action:
			FinanceMetadataActionCode.ADD_CREDIT_HUB_REVENUE_SERVICE_CHANGE_REQUESTED
	},
	[FinanceMetadataActionCode.ADD_CREDIT_HUB_REVENUE_SERVICE]: {
		text: 'Add credit',
		action: FinanceMetadataActionCode.ADD_CREDIT_HUB_REVENUE_SERVICE
	},
	[FinanceMetadataActionCode.DELETE]: {
		text: 'Delete',
		action: FinanceMetadataActionCode.DELETE
	},
	[FinanceMetadataActionCode.CONVERT]: {
		text: 'Convert from Pass through',
		action: FinanceMetadataActionCode.CONVERT
	},
	[FinanceMetadataActionCode.CONVERT_TO_DIRECT_BILL]: {
		text: 'Convert to Direct Bill',
		action: FinanceMetadataActionCode.CONVERT_TO_DIRECT_BILL
	},
	[FinanceMetadataActionCode.CONVERT_TO_REGULAR_SERVICE]: {
		text: 'Convert to Regular Service',
		action: FinanceMetadataActionCode.CONVERT_TO_REGULAR_SERVICE
	},
	[FinanceMetadataActionCode.CANCEL_REVENUE_INVOICE_TRANSACTION]: {
		text: 'Cancel transaction',
		action: FinanceMetadataActionCode.CANCEL_REVENUE_INVOICE_TRANSACTION
	},
	/**
	 * the corresponding metadata actions for this item and one above
	 * cannot be combined simultaneously by business logic
	 */
	[FinanceMetadataActionCode.CANCEL_CREDIT_HUB_REVENUE]: {
		text: 'Cancel transaction',
		action: FinanceMetadataActionCode.CANCEL_CREDIT_HUB_REVENUE
	},
	[FinanceMetadataActionCode.SPLIT]: {
		text: 'Split/Recode',
		action: FinanceMetadataActionCode.SPLIT
	},
	[FinanceMetadataActionCode.REVERSE_SPLIT]: {
		text: 'Reverse split',
		action: FinanceMetadataActionCode.REVERSE_SPLIT
	},
	[FinanceMetadataActionCode.ADD_DA_OUT]: {
		text: 'Add DA OUT',
		action: FinanceMetadataActionCode.ADD_DA_OUT
	},
	[FinanceMetadataActionCode.DELETE_DA_OUT]: {
		text: 'Remove DA OUT',
		action: FinanceMetadataActionCode.DELETE_DA_OUT
	},
	[FinanceMetadataActionCode.MOVE_SERVICE]: {
		text: 'Move service',
		action: FinanceMetadataActionCode.MOVE_SERVICE
	}
};
