import { DeveloperPortalState as State } from '../developerPortalState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	DeveloperPortalDataRequest as Request,
	DeveloperPortalData as Response,
	ApiGroupPermissionList
} from 'services/api/developerPortal/developerPortalServiceTypes';
import { Success } from 'typescript-fsa';
import {
	DeveloperPortalGroupsIndex,
	WebHookConfigOption,
	ApiGroupName,
	outBoundEventsMap
} from 'sections/Administration/DeveloperPortal/DeveloperPortalTab/constants';
import { flatten } from 'lodash';

const getPreparedApiData = (data: ApiGroupPermissionList[]) =>
	data.map(apiGroup => ({
		...apiGroup,
		children: apiGroup.children.map(api => ({
			...api,
			...DeveloperPortalGroupsIndex[api.methodName]
		}))
	}));

const getPreparedData = (data: Response) => {
	const { apiPermission, apiMappingResponse } = data;
	const outboundApiList = flatten(
		apiPermission
			.filter(api => (api?.groupName ? ApiGroupName[api.groupName] : {}))
			.filter(api => api.direction === 'Outbound')
			.map(list => list.children)
	);
	const selectedList =
		apiMappingResponse?.length >= 0
			? apiMappingResponse.reduce(
					(list, permission) => [...list, permission.id],
					[]
			  )
			: [];

	const selectedOutBoundApi: string[] = [];
	selectedList.forEach(apiId => {
		const api = outboundApiList.find(outboundApi => outboundApi.id === apiId);
		if (api?.methodName && outBoundEventsMap[api.methodName]) {
			selectedOutBoundApi.push(outBoundEventsMap[api.methodName]);
		}
	});
	return { outboundApiList, selectedOutBoundApi };
};

export const onRetrieveDeveloperPortal = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'retrieveDeveloperPortal', {
	doneReducer: (state: State, action: Success<Request, Response>): State => {
		const { outboundApiList, selectedOutBoundApi } = getPreparedData(
			action.result
		);
		return {
			...state,
			context: {
				...state.context,
				outboundApiList,
				selectedOutBoundApi,
				initialDeveloperPortal: {
					...state.context.initialDeveloperPortal,
					id: action.result.id,
					userId: action.result.userId,
					hubPrincipalCompanyId: action.result.hubPrincipalCompanyId,
					subscriptionStatus: action.result.subscriptionStatus,
					apiPermission: getPreparedApiData(action.result.apiPermission),
					apiMappingResponse: action.result.apiMappingResponse,
					userToken: action.result.userToken,
					isWebhookEndPoint: action.result.isWebhookEndPoint
						? WebHookConfigOption.YES
						: WebHookConfigOption.NO,
					webhookEndPointResponse: action.result.webhookEndPointResponse,
					webhookFilePath: action.result.webhookFilePath
				}
			}
		};
	}
});
