import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';

export const THREADS_ASSIGNTO_FORM = 'assignToModalForm';

export enum ThreadsAssignToFormFields {
	ASSIGNED_GROUP_ID = 'assignedGroupId',
	ASSIGNED_USER_ID = 'assignedUserId',
	THREAD_PERMISSION_GROUPS = 'threadPermissionGroups',
	CURRENT_GROUP_ID_TO_ADD = 'currentGroupIdToAdd'
}

export interface ThreadsAssignToFormData {
	[ThreadsAssignToFormFields.ASSIGNED_GROUP_ID]?: string;
	[ThreadsAssignToFormFields.ASSIGNED_USER_ID]?: string;
	[ThreadsAssignToFormFields.THREAD_PERMISSION_GROUPS]: PermissionGroupWithUsers[];
	[ThreadsAssignToFormFields.CURRENT_GROUP_ID_TO_ADD]?: string;
}
