/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { map } from 'lodash';
import { Row, Col } from 'components/antd';
import { CrewChangePortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { Text } from 'components';
import { ShowMoreText } from 'components/ReadMore';

const column1PropsLabels = {
	onSigners: 'On Signers',
	offSigners: 'Off Signers'
};
const column2PropsLabels = {
	immigrationAssistances: 'Immigration assistance',
	lettersOfInvitation: 'Letter of	Invitation',
	okToBoardLetters: 'OK to Board letter',
	visaAssistances: 'Visa assistance',
	customerClearences: 'Custom Clearance',
	shorePasses: 'Shore pass',
	meetAndGreets: 'Meet and Greet at the	airport',
	lostLuggageAssistances: 'Lost luggage	assistance'
};
const NONE_MESSAGE = 'None';

interface CrewChangeRowProps {
	val?: number;
	label: string;
}

const CrewChangeRow = ({ val, label }: CrewChangeRowProps) => {
	return (
		<div>
			{val && (
				<span>
					<strong>{val}</strong> {label}
				</span>
			)}
		</div>
	);
};

interface CrewChangeColProps {
	operation: CrewChangePortJobOperation;
	propList: object;
	title?: string;
}

const CrewChangeCol = ({ operation, propList, title }: CrewChangeColProps) => {
	let isEmptyCol = true;
	const CrewChangeRowsEl = map(propList as any, (item: string, key: string) => {
		if (operation[key]) {
			isEmptyCol = false;
			return <CrewChangeRow key={key} val={operation[key]} label={item} />;
		}
		return null;
	});

	return (
		<Col xs={4} lg={2}>
			<div>{title}</div>
			{isEmptyCol ? NONE_MESSAGE : CrewChangeRowsEl}
		</Col>
	);
};

export default ({ operation }: { operation: CrewChangePortJobOperation }) => (
	<>
		<Row>
			<CrewChangeCol operation={operation} propList={column1PropsLabels} />
			<CrewChangeCol
				operation={operation}
				propList={column2PropsLabels}
				title="Crew related services:"
			/>
			<Col xs={4} lg={8}>
				<Text upper weight="bold">
					Details
				</Text>
				<ShowMoreText showLines={3}>
					{operation.details || NONE_MESSAGE}
				</ShowMoreText>
			</Col>
		</Row>
	</>
);
