import {
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse,
	AddBankAccountRequest,
	RetrieveBanksResponse,
	RetrieveBanksRequest,
	RetrieveBankAccountRequest,
	RetrieveBankAccountResponse,
	UpdateBankAccountRequest,
	BankAccount,
	LOBDDocument,
	DownloadLOBDRequest
} from './bankAccountsServiceTypes';
import client from 'services/client';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import { RetrieveDownloadDocumentResponse } from '../documents/documentsServiceTypes';

const retrieveBankAccounts = (request: RetrieveBankAccountsRequest) =>
	client.get<RetrieveBankAccountsResponse>('/masterdata/bankdetails', {
		params: request
	});

const addBankAccount = (request: AddBankAccountRequest) =>
	client.post('/masterdata/bankdetails', request);

const retrieveBanks = (
	request: RetrieveBanksRequest
): Promise<AutocompleteOption[]> =>
	client
		.get<RetrieveBanksResponse>('/masterdata/bankdetails/banks', {
			params: {
				limit: DEFAULT_LIST_LIMIT,
				...request
			}
		})
		.then(response =>
			response.data.elements.map(bank => ({
				id: bank.code,
				name: bank.bankName
			}))
		);

const retrieveBankAccount = ({ id }: RetrieveBankAccountRequest) =>
	client.get<RetrieveBankAccountResponse>(`/masterdata/bankdetails/${id}`);

const updateBankAccount = (params: UpdateBankAccountRequest) =>
	client.patch<BankAccount>(
		`/masterdata/bankdetails/${params.bankDetailId}`,
		params
	);
const retrieveLOBD = ({ id }: RetrieveBankAccountRequest) =>
	client.get<LOBDDocument>(`/masterdata/bankdetails/${id}/LOBD`);

const downloadLOBD = ({
	documentId,
	letterOfBankDetailDocumentId
}: DownloadLOBDRequest) =>
	client.get<
		RetrieveDownloadDocumentResponse
	>(`/masterdata/bankdetails/${documentId}/LOBD/download`, {
		params: { letterOfBankDetailDocumentId }
	});

export default {
	retrieveBankAccounts,
	retrieveBankAccount,
	addBankAccount,
	retrieveBanks,
	updateBankAccount,
	retrieveLOBD,
	downloadLOBD
};
