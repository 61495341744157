import * as React from 'react';
import { connect } from 'react-redux';

import { LoadingPlaceholder } from 'components';
import JobsTab from './JobsTab/JobsTab';
import DashboardTabs from './DashboardTabs';

import { retrieveDashboardTableau } from 'store/dashboard/actions';
import { getIsCurrentUserLpa } from 'store/auth/selectors';
import {
	getDashboardTableau,
	getDashboardTableauFetchStatus
} from 'store/dashboard/selectors';

import { FetchStatus } from 'services/api/apiTypes';
import { DashboardTableau } from 'services/api/dashboard/dashboardServiceTypes';
import { AppState } from 'store-types';

interface DashboardProps {
	tableau: DashboardTableau[];
	isLpa: boolean;
	fetchStatus: FetchStatus;
	retrieveTableau: typeof retrieveDashboardTableau;
}

class Dashboard extends React.Component<DashboardProps> {
	componentDidMount() {
		this.props.retrieveTableau();
	}
	render() {
		const { isLpa, tableau, fetchStatus } = this.props;
		return (
			<LoadingPlaceholder fetchStatus={fetchStatus}>
				{() => (
					<>{isLpa ? <JobsTab /> : <DashboardTabs tableauTabs={tableau} />}</>
				)}
			</LoadingPlaceholder>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isLpa: getIsCurrentUserLpa(state),
		fetchStatus: getDashboardTableauFetchStatus(state),
		tableau: getDashboardTableau(state)
	}),
	{
		retrieveTableau: retrieveDashboardTableau
	}
)(Dashboard);
