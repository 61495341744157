import { retrievelinkedJobCargosAsync } from 'store/linkJob/actions/retriveLinkedJobCargoDetails';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	GetLinkedCargosRequest,
	GetLinkedCargosResponse
} from 'services/api/portCalls/portCallsServiceTypes';

export const retrieveLinkJobCargosWatcher = makeTakeLatestWatcher<
	GetLinkedCargosRequest,
	GetLinkedCargosResponse,
	Error
>({
	api: Api.PortCalls.getLinkedCargoDetils,
	async: retrievelinkedJobCargosAsync
});
