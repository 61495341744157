import { ThreadsState } from 'store/threads/threadsState';

export const onUpdateUnreadCount = (state: ThreadsState): ThreadsState => {
	let { totalUnreadCount } = state;
	if (totalUnreadCount > 0) {
		totalUnreadCount = totalUnreadCount - 1;
	}
	return {
		...state,
		totalUnreadCount
	};
};
