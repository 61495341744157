import Api from 'services/api';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { Value } from './RecipientSelectTypes';
import { isEmail } from 'utils/validations';
import { UserContact } from 'services/api/users/userServiceTypes';

export function toLabelValue(value: UserContact) {
	return {
		// For initial list of options, `id` may be not set
		key: value.id || '',
		label: value.email,
		title: value.fullName
	};
}

export function onSearch(
	searchTerm: string,
	value: Value[] | undefined,
	currentUserId: string
) {
	return new Promise<ReturnType<typeof onSearchDone>>(resolve => {
		Api.Users.retrieveUserContacts(currentUserId, searchTerm, {
			index: 0,
			limit: DEFAULT_LIST_LIMIT,
			sortBy: 'email'
		})
			.then(response => {
				resolve(onSearchDone(searchTerm, value, response.data.elements));
			})
			.catch(() => {
				resolve(onSearchDone(searchTerm, value));
			});
	});
}

function onSearchDone(
	searchTerm: string,
	value: Value[] | undefined,
	options: UserContact[] = []
) {
	// filter out those that are already selected
	const availableOptions = value
		? options.filter(option => !value.find(v => v.label === option.email))
		: options;

	return {
		validSearchTerm: isEmail(searchTerm),
		options: availableOptions
	};
}
