import * as React from 'react';
import classNames from 'classnames';
import AntInput from 'antd/lib/input';
import { TextAreaProps, HTMLTextareaProps } from 'antd/lib/input/TextArea';
import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { textFieldMapProps } from './Input';
import styles from '../Form/Form.module.scss';

export class TextArea extends React.Component<
	TextAreaProps & HTMLTextareaProps
> {
	static ReduxFormItem: CreateReduxField<TextAreaProps & HTMLTextareaProps>;

	render() {
		return (
			<AntInput.TextArea
				{...this.props}
				className={classNames(styles.input, this.props.className)}
			/>
		);
	}
}

TextArea.ReduxFormItem = createFieldComponent<
	TextAreaProps & HTMLTextareaProps
>(TextArea, textFieldMapProps);

export default TextArea;
