import { all, fork } from 'redux-saga/effects';
import endOfSeaPassageWatcher from './endOfSeaPassageSaga';
import commenceOfSeaPassageWatcher from './commenceOfSeaPassageSaga';
import retrieveEditPortCallDataWatcher from './editPortCallData/retrieveEditPortCallDataSaga';
import updatePortCall from './editPortCallData/updatePortCallSaga';
import retrievePortCallAlertsWatcher from './retrievePortCallAlertsSaga';
import retrievePortCallVesselInfoWatcher from './retrievePortCallVesselInfoSaga';
import retrievePortCallMetadataWatcher from './retrievePortCallMetadataSaga';
import retrievePortCallMetadataCycleWatcher from './retrievePortCallMetadataCycleSaga';
import retrievePortCallActAsWatcher from './retrievePortCallActAsSaga';
import reactingOnChangePortCallAlertsWatcher from './reactingOnChangesAlertsSaga';

export default function*() {
	yield all([
		fork(retrieveEditPortCallDataWatcher),
		fork(updatePortCall),
		fork(endOfSeaPassageWatcher),
		fork(commenceOfSeaPassageWatcher),
		fork(retrievePortCallAlertsWatcher),
		fork(retrievePortCallVesselInfoWatcher),
		fork(retrievePortCallMetadataWatcher),
		fork(retrievePortCallMetadataCycleWatcher),
		fork(retrievePortCallActAsWatcher),
		fork(reactingOnChangePortCallAlertsWatcher)
	]);
}
