import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { updatePortCallEventAsync } from '../actions';
import { UpdatePortCallEventPayload } from '../actions/updatePortCallEvent';
import { getActivePortCallId } from '../../portcalls/selectors';
import {
	getPortCallEvent,
	getPortCallEventConcurrencyToken
} from '../selectors';
import Api from 'services/api';
import Matomo from 'services/analytics/adapters/Matomo';
import { notify } from 'store/notifications/actions';

export function* updatePortCallEventWorker({
	payload
}: Action<UpdatePortCallEventPayload>): SagaIterator {
	const concurrencyToken = yield select(
		getPortCallEventConcurrencyToken,
		payload.eventId
	);
	// it means Port Call Event has been reset in state so we shouldn't try to update it
	if (!concurrencyToken) {
		return;
	}
	yield put(updatePortCallEventAsync.started(payload));
	const portCallId = yield select(getActivePortCallId);
	const updateRequest = { ...payload, portCallId };
	const event = yield select(getPortCallEvent, payload.eventId);
	try {
		const response = yield call(Api.PortCall.updatePortCallEvent, {
			...updateRequest,
			concurrencyToken
		});
		yield put(
			updatePortCallEventAsync.done({
				result: response.data,
				params: updateRequest,
				response
			})
		);
		if (
			event.name === 'Commencement Of Sea Passage (COSP)' &&
			updateRequest.realDatePlt !== null &&
			response.data.mandatePDA
		) {
			yield put(
				notify.info(
					'One or more jobs have not moved to CE pending since PDA approval is mandatory for the principal. Optic will notify you once your CE is required to be entered'
				)
			);
		}
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Events.SetEventData',
			name: `Changed event ${payload.eventId} date for port call ${portCallId}`,
			value: 1
		});
	} catch (error) {
		yield put(
			updatePortCallEventAsync.failed({
				error,
				params: updateRequest
			})
		);
	}
}

export default function* updatePortCallEventWatcher(): SagaIterator {
	yield takeLatest(updatePortCallEventAsync.type, updatePortCallEventWorker);
}
