import {
	RetrievePortCallsAlertsRequest as Request,
	RetrievePortCallsAlertsResponse as Response
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../portCallsActionCreator';

const RETRIEVE_PORTCALLS_ALERTS = 'RETRIEVE_PORTCALLS_ALERTS';

export const retrievePortCallsAlertsAsync = actionCreator.async<
	Request,
	Response,
	Error
>(RETRIEVE_PORTCALLS_ALERTS);

export const retrievePortCallsAlerts = actionCreator<Request>(
	RETRIEVE_PORTCALLS_ALERTS
);
