import Api from 'services/api';
import { select } from 'redux-saga/effects';

import { makeTakeEveryWatcher } from 'utils/sagaHelpers';

import {
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusPayload,
	UpdateDirectBillStatusResponse
} from 'services/api/directBills/directBillsServiceTypes';
import { updateDirectBillStatusAsync as async } from '../actions';
import { getEditableDirectBillId } from '../selectors';

const api = Api.DirectBills.updateDirectBillStatus;

export default makeTakeEveryWatcher<
	UpdateDirectBillStatusRequest,
	UpdateDirectBillStatusResponse,
	Error
>({
	api,
	async,
	*getApiParams({ status }: UpdateDirectBillStatusPayload) {
		const existingDirectBillId = yield select(getEditableDirectBillId);

		return {
			status,
			id: existingDirectBillId
		};
	}
});
