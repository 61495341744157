import React from 'react';
import { connect } from 'react-redux';
import { LoadingPlaceholder } from 'components';
import { getPortJobExtendedMessagingFetchStatus } from 'store/portJobs/selectors';
import { retrieveExtendedMessagingData } from 'store/portJobs/actions';
import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';
import ExtendedMessagingContent from './ExtendedMessagingContent';
import { EXTENDED_MAIL_TYPE } from '../../PortJobConstants';

interface ExtendedMessagingContainerProps {
	/**
	 * This method will called when every message completed(sent/closed).
	 * We use to call any side effects and used to close the opened modal.
	 */
	closeExtendedMessagingModal: () => void;
	mailType: EXTENDED_MAIL_TYPE;
	// from mapStateToProps
	fetchStatus: FetchStatus;
	// from mapDispatchToProps
	retrieveExtendedMessagingData: typeof retrieveExtendedMessagingData;
}

class ExtendedMessagingContainer extends React.Component<
	ExtendedMessagingContainerProps
> {
	componentDidMount() {
		if (this.props.mailType !== EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL) {
			this.props.retrieveExtendedMessagingData({
				mailType: this.props.mailType
			});
		}
	}

	render() {
		const { fetchStatus } = this.props;
		return (
			<LoadingPlaceholder fetchStatus={fetchStatus}>
				{() => (
					<ExtendedMessagingContent
						closeExtendedMessagingModal={this.props.closeExtendedMessagingModal}
						mailType={this.props.mailType}
					/>
				)}
			</LoadingPlaceholder>
		);
	}
}

export default connect(
	(state: AppState) => ({
		fetchStatus: getPortJobExtendedMessagingFetchStatus(state)
	}),
	{
		retrieveExtendedMessagingData
	}
)(ExtendedMessagingContainer);
