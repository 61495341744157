import * as React from 'react';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { connect } from 'react-redux';

import { isArray } from 'lodash';
import { makeGetHasPermissionTable } from 'store/auth/selectors/permissionsSelector';
import { AppState } from 'store-types';
import PermissionContext, { PermissionContextValue } from './PermissionContext';

interface PermissionContextConsumerProps {
	permissionCode: PermissionCode[] | PermissionCode;
	children: (...permissions: boolean[]) => React.ReactNode;
}

type PermissionOwnProps = PermissionContextConsumerProps &
	PermissionContextValue;

type PermissionProps = PermissionOwnProps & {
	permissions: boolean[];
};

const Permission = ({ permissions, children }: PermissionProps) => (
	<>{children(...permissions)}</>
);

const PermissionConnected = connect(() => {
	const getHasPermissionTable = makeGetHasPermissionTable();

	return (
		state: AppState,
		{ permissionCode, companyId }: PermissionOwnProps
	) => {
		const codes = !isArray(permissionCode) ? [permissionCode] : permissionCode;
		return {
			permissions: getHasPermissionTable(state, codes, companyId)
		};
	};
})(Permission);

const PermissionContextConsumer = (props: PermissionContextConsumerProps) => {
	return (
		<PermissionContext.Consumer>
			{({ companyId }) => (
				<PermissionConnected {...props} companyId={companyId} />
			)}
		</PermissionContext.Consumer>
	);
};

export default PermissionContextConsumer;
