import actionCreator from '../portCallDocumentsActionCreator';
import { DownloadPortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

export const DOWNLOAD_PORT_CALL_DOCUMENTS = 'DOWNLOAD_PORT_CALL_DOCUMENTS';
export const downloadPortCallDocumentsAsync = actionCreator.async<
	DownloadPortCallDocumentRequest,
	undefined, // DownloadPortCallDocumentResponse['downloadUrl'],
	Error
>(DOWNLOAD_PORT_CALL_DOCUMENTS);

export const downloadPortCallDocuments = actionCreator<
	DownloadPortCallDocumentRequest
>(DOWNLOAD_PORT_CALL_DOCUMENTS);
