import React from 'react';
import { connect } from 'react-redux';

import { DataFetcher } from 'utils/components';
import PortCallContainer from 'sections/PortCall/PortCallContainer';

import {
	retrieveDocument,
	cancelUploadAndProcessDocument
} from 'store/documents/actions';

import { getDocumentFetchStatus } from 'store/documents/selectors';

import { FetchStatus } from 'services/api/apiTypes';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { AppState } from 'store-types';

interface DocumentViewerContainerProps
	extends Pick<PortCallTabBaseProps, 'match'> {
	documentFetchStatus: FetchStatus;
	withPortCallContainer?: boolean;
	retrieveDocument: typeof retrieveDocument;
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
}

class DocumentViewerContainer extends React.Component<
	DocumentViewerContainerProps
> {
	static defaultProps = {
		withPortCallContainer: true
	};

	retrieveDocument = () =>
		this.props.retrieveDocument({ id: this.props.match.params.documentId });

	onReset = () =>
		this.props.cancelUploadAndProcessDocument({ shouldResetFile: true });

	render() {
		const documentFetcher = (
			<DataFetcher
				dispatch={this.retrieveDocument}
				fetchStatus={this.props.documentFetchStatus}
				reset={this.onReset}
			>
				{() => this.props.children}
			</DataFetcher>
		);

		return this.props.withPortCallContainer ? (
			<PortCallContainer match={this.props.match}>
				{documentFetcher}
			</PortCallContainer>
		) : (
			documentFetcher
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<DocumentViewerContainerProps, 'match'>) => ({
		documentFetchStatus: getDocumentFetchStatus(
			state,
			ownProps.match.params.documentId
		)
	}),
	{
		retrieveDocument,
		cancelUploadAndProcessDocument
	}
)(DocumentViewerContainer);
