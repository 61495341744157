import actionCreator from '../portCallDocumentsActionCreator';
import {
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const RETRIEVE_ALLOWED_DOCUMENTS = 'RETRIEVE_ALLOWED_DOCUMENTS';
export const retrieveAllowedDocumentsAsync = actionCreator.async<
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse,
	Error
>(RETRIEVE_ALLOWED_DOCUMENTS);

export const retrieveAllowedDocuments = actionCreator<
	RetrieveAllowedDocumentsRequest
>(RETRIEVE_ALLOWED_DOCUMENTS);

const RESET_ALLOWED_DOCUMENTS = 'RESET_ALLOWED_DOCUMENTS';
export const resetRequiredDocuments = actionCreator(RESET_ALLOWED_DOCUMENTS);
