import { FinanceState } from '../financeState';

export const onResetComparisonColumn = (state: FinanceState) => {
	return {
		...state,
		context: {
			...state.context,
			comparisonColumn: {}
		}
	};
};
