import {
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANY_GENERAL_CONFIGURATION_ASYNC';
export const retrieveCompanyGeneralConfigurationReportsAsync = actionCreator.async<
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse,
	Error
>(ACTION_NAME);
export const retrieveCompanyGeneralConfigurationReports = actionCreator<
	RetrieveCompanyGeneralConfigurationReportsRequest
>(ACTION_NAME);
