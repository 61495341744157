import React from 'react';
import { connect } from 'react-redux';
import { Gap, LoadingPlaceholder, ScrollableLayout } from 'components';
import PortCallDetails from './PortCallDetails';
import PageSubHeader from './PageSubHeader';
import MergePortCallsOptionsList from './MergePortCallsOptionsList';
import { AppState } from 'store-types';
import { BreadcrumbItem } from 'store/navigation';
import { setPortCallContext } from 'store/portcalls/actions';
import {
	getActivePortCall,
	getActivePortCallFetchStatus,
	getPortCallDuplicates,
	getPortCallDuplicatesFetchStatus
} from 'store/portcalls/selectors';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { FetchStatus } from 'services/api/apiTypes';
import {
	PortCall,
	PortCallDuplicate
} from 'services/api/portCalls/portCallsServiceTypes';

interface PortCallDuplicatesPageProps {
	portCallId: string;
	// from mapStateToProps
	portCall: PortCall;
	portCallDuplicates: PortCallDuplicate[];
	breadcrumbs: BreadcrumbItem[];
	portCallFetchStatus: FetchStatus;
	portCallDuplicatesFetchStatus: FetchStatus;
	// from mapDispatchToProps
	setPortCallContext: typeof setPortCallContext;
}

class PortCallDuplicatesPage extends React.Component<
	PortCallDuplicatesPageProps
> {
	componentWillUnmount() {
		this.props.setPortCallContext({
			mergePortCallsContext: {}
		});
	}

	onDuplicateOptionChange = (option: string | boolean) => {
		const { portCallDuplicates } = this.props;
		this.props.setPortCallContext({
			mergePortCallsContext: {
				isDuplicate: Boolean(option),
				portCallDuplicateIds: option
					? [option]
					: portCallDuplicates.map(duplicate => duplicate.id)
			}
		});
	};

	render() {
		const {
			portCall,
			portCallDuplicates,
			portCallFetchStatus,
			portCallDuplicatesFetchStatus
		} = this.props;
		return (
			<ScrollableLayout>
				<LoadingPlaceholder fetchStatus={portCallFetchStatus}>
					{() => (
						<Gap top left bottom="lg" isBlock outside={false}>
							<PortCallDetails
								portCall={portCall}
								titlePrefix="Current"
								showExtendedTime
							/>
						</Gap>
					)}
				</LoadingPlaceholder>
				<PageSubHeader>Potential Port Call Duplicates</PageSubHeader>
				<LoadingPlaceholder fetchStatus={portCallDuplicatesFetchStatus}>
					{() => (
						<MergePortCallsOptionsList
							duplicates={portCallDuplicates}
							onOptionChange={this.onDuplicateOptionChange}
						/>
					)}
				</LoadingPlaceholder>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(
		state: AppState,
		{ portCallId }: Pick<PortCallDuplicatesPageProps, 'portCallId'>
	) => ({
		portCall: getActivePortCall(state),
		portCallDuplicates: getPortCallDuplicates(state, portCallId),
		breadcrumbs: getPortCallLayoutBreadcrumbs(state),
		portCallFetchStatus: getActivePortCallFetchStatus(state),
		portCallDuplicatesFetchStatus: getPortCallDuplicatesFetchStatus(state)
	}),
	{
		setPortCallContext
	}
)(PortCallDuplicatesPage);
