import { retrieveMainPrincipalGroupsAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveGroupsRequest,
	RetrieveGroupsResponse,
	Error
>({
	api: Api.Groups.retrieveGroups,
	async: retrieveMainPrincipalGroupsAsync
});
