import actionCreator from '../configurationFilesActionCreator';
import {
	UpdateCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileResponse
} from 'services/api/templates/templatesServiceTypes';
const ACTION_NAME = 'UPDATE_CONFIGURATION_FILE';

export const updateConfigurationFileAsync = actionCreator.async<
	UpdateCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'File'
	}
});

export const updateConfigurationFile = actionCreator<
	UpdateCompanyTemplateFileRequest
>(ACTION_NAME);
