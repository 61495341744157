import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ScrollableLayout, InfiniteTable } from 'components';
import { Col } from 'components/antd';

import RemoveGroupUserModal from './RemoveGroupUserModal/RemoveGroupUserModal';
import GroupHeader from './GroupHeader/GroupHeader';
import PageFooter from 'components/PageFooter/PageFooter';
import GroupUserSelect from 'sections/Groups/Group/GroupUserSelect/GroupUserSelect';

import {
	Group as IGroup,
	GroupUser
} from 'services/api/groups/groupsServiceTypes';

import { retrieveGroup, resetGroup } from 'store/groups/actions';

import {
	addGroupUser,
	resetGroupUsers,
	retrieveGroupUsers
} from 'store/groupUsers';
import { getGroupById } from 'store/groups/groupsSelectors';
import {
	getAllGroupUsersSelector,
	groupUsersHasMoreSelector,
	isGroupUsersFetchingSelector
} from 'store/groupUsers/groupUsersSelectors';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { PermissionWrapper } from 'components/Permission';
import { getGroupUsersColumns, Column } from './GroupColumns';
import GroupsLayout from '../GroupsLayout';
import { AppState } from 'store-types';
import { AppNotifications } from 'containers';

export interface GroupParams {
	groupId: string;
}

export interface GroupProps extends RouteComponentProps<GroupParams> {
	group: IGroup;
	hasMore: boolean;
	groupUsers: GroupUser[];
	groupUsersFetching: boolean;
	// from mapDispatchToProps
	retrieveGroup: typeof retrieveGroup;
	retrieveGroupUsers: typeof retrieveGroupUsers;
	resetGroupUsers: typeof resetGroupUsers;
	addGroupUser: typeof addGroupUser;
	resetGroup: typeof resetGroup;
}

interface GroupState {
	isRemoveGroupOpen: boolean;
	groupUserToRemove: GroupUser | null;
}

class Group extends React.Component<GroupProps, GroupState> {
	columns: Column[];

	requestGroupUsers = () => {
		this.props.retrieveGroupUsers(
			{ id: this.props.match.params.groupId },
			{ behaviour: { type: 'section', infiniteLoad: true } }
		);
	};

	constructor(props: GroupProps) {
		super(props);

		this.state = {
			isRemoveGroupOpen: false,
			groupUserToRemove: null
		};

		this.columns = getGroupUsersColumns(this.onRemoveGroupUserModalToggle);
	}

	componentDidMount() {
		this.props.resetGroupUsers(this.props.match.params.groupId);
		this.props.retrieveGroup(this.props.match.params.groupId);
	}

	componentWillUnmount() {
		this.props.resetGroupUsers(this.props.match.params.groupId);
		this.props.resetGroup(); // Reset openedGroup in store
	}

	onSelectGroupUser = (userId: string) => {
		this.props.addGroupUser({
			groupId: this.props.group.id,
			userId
		});
	};

	onRemoveGroupUserModalToggle = (record?: GroupUser) => {
		const groupUserToRemove = record ? record : null;

		this.setState({
			groupUserToRemove,
			isRemoveGroupOpen: !this.state.isRemoveGroupOpen
		});
	};

	render() {
		const { group, groupUsers, groupUsersFetching } = this.props;
		if (!group) {
			return null;
		}
		return (
			<GroupsLayout>
				<GroupHeader group={group} />
				<ScrollableLayout stretch>
					<AppNotifications.Table
						entityName="Users"
						actionType={retrieveGroupUsers.type}
					>
						{({ failedLoading }) => (
							<InfiniteTable
								columns={this.columns}
								dataSource={groupUsers}
								locale={{
									emptyText: 'The group does not have users yet'
								}}
								lazyLoad={{
									loading: groupUsersFetching,
									hasMore: this.props.hasMore,
									onEnter: this.requestGroupUsers,
									failedLoading
								}}
							/>
						)}
					</AppNotifications.Table>
				</ScrollableLayout>
				{this.state.isRemoveGroupOpen && this.state.groupUserToRemove && (
					<RemoveGroupUserModal
						group={group}
						groupUser={this.state.groupUserToRemove}
						onCancel={this.onRemoveGroupUserModalToggle}
					/>
				)}
				<PageFooter stretch borderTop type="primary">
					<Col xs={6} sm={4} lg={2}>
						<PermissionWrapper permissionCode={PermissionCode.MANAGE_GROUP}>
							{hasPermission => (
								<GroupUserSelect
									groupId={group.id}
									groupUsers={groupUsers}
									disabled={!hasPermission}
									onSelectUser={this.onSelectGroupUser}
								/>
							)}
						</PermissionWrapper>
					</Col>
				</PageFooter>
			</GroupsLayout>
		);
	}
}

export default connect(
	(state: AppState, props: RouteComponentProps<GroupParams>) => {
		return {
			group: getGroupById(state, props.match.params.groupId),
			groupUsers: getAllGroupUsersSelector(state, props.match.params.groupId),
			hasMore: groupUsersHasMoreSelector(state),
			groupUsersFetching: isGroupUsersFetchingSelector(
				state,
				props.match.params.groupId
			)
		};
	},
	{
		retrieveGroup,
		retrieveGroupUsers,
		resetGroupUsers,
		resetGroup,
		addGroupUser
	}
)(Group);
