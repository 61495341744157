import { FetchStatus } from 'services/api/apiTypes';
import { PortCallAlertsMap } from 'app-types';
import {
	PortCallActAs,
	PortCallMetadataActionCode
} from 'services/api/portCall/portCallServiceTypes';
import {
	paramsStatusMap,
	DEFAULT_THREADS_FILTER_STATUS
} from 'store/threads/filtersSync';
import { PortCallThreadsFiltersState } from './portCallTypes';

export interface PortCallContext {
	operationFormVisible: boolean;
	editableCargoId?: string;
	editableGradeId?: string;
	editableTotalAmountId?: string;
	isPortCallUpToDate: boolean;
}

export interface PortCallState {
	readonly threadsFilters: PortCallThreadsFiltersState;
	readonly principalEmail: string;
	readonly alerts: PortCallAlertsMap;
	readonly fetchStatuses: {
		endOfSeaPassage: FetchStatus;
		commenceOfSeaPassage: FetchStatus;
		retrieveEditPortCallData: FetchStatus;
		updatePortCall: FetchStatus;
		VPPendingAlerts: FetchStatus;
		alerts: FetchStatus;
		vesselInfo: FetchStatus;
		metadata: FetchStatus;
		principalEmail: FetchStatus;
		retrieveActAs: FetchStatus;
	};
	readonly context: PortCallContext;
	readonly metadata: {
		[portCallId: string]: PortCallMetadataActionCode[];
	};
	readonly actAs: PortCallActAs[];
}

export const initialState: PortCallState = {
	threadsFilters: {
		currentFilter: DEFAULT_THREADS_FILTER_STATUS,
		statuses: paramsStatusMap[DEFAULT_THREADS_FILTER_STATUS]
	},
	principalEmail: '',
	alerts: {
		portCallId: '',
		portCallAlertGroupsByMetricType: [],
		portJobs: {}
	},
	fetchStatuses: {
		endOfSeaPassage: FetchStatus.IDLE,
		commenceOfSeaPassage: FetchStatus.IDLE,
		retrieveEditPortCallData: FetchStatus.IDLE,
		updatePortCall: FetchStatus.IDLE,
		VPPendingAlerts: FetchStatus.IDLE,
		alerts: FetchStatus.IDLE,
		vesselInfo: FetchStatus.IDLE,
		metadata: FetchStatus.IDLE,
		principalEmail: FetchStatus.IDLE,
		retrieveActAs: FetchStatus.IDLE
	},
	context: {
		operationFormVisible: false,
		isPortCallUpToDate: false
	},
	metadata: {},
	actAs: []
};
