export const OppositeUserStatus = {
	Active: 'Disabled',
	Disabled: 'Active',
	PermLocked: 'Active',
	TempLocked: 'Active'
};

export const OppositeUserStatusText = {
	Disabled: 'enable',
	Active: 'disable',
	PermLocked: 'enable',
	TempLocked: 'enable'
};

export const USER_PROFILE_FORM = 'userDetails';
export const USER_PROFILE_STATUS_MODAL_NAME =
	'userDetails/updateUserStatus/modal';
