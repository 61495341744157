import actionCreator from '../actionCreator';
import { DownloadVPRevisionRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_VP_REVISION';

export type DownloadVPRevisionActionParams = Pick<
	DownloadVPRevisionRequest,
	'id'
>;

export const downloadVPRevisionAsync = actionCreator.async<
	DownloadVPRevisionRequest,
	RetrieveDownloadDocumentResponse,
	Error
>(ACTION_NAME);

export const downloadVPRevision = actionCreator<DownloadVPRevisionActionParams>(
	ACTION_NAME
);
