import { RotationStepEvent } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';
import {
	getEventsById,
	getNextRotationStepId,
	getPreviousRotationStepId,
	getRotationStepEventIds,
	getRotationStepUnitIds
} from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';
import { ReducerRotationStep } from '../vesselProgrammeState';
import { AppState } from 'store-types';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import {
	getRotationStepById,
	isIdAutoGenerated,
	getRotationStepPoiType,
	isVesselProgrammeInEditMode,
	getRotationStepsById,
	getPositionedRotationStep
} from 'store/vesselProgramme/selectors';

export const getActivePOIId = (state: AppState) =>
	state.vesselProgramme.context.activePOIId;

export const getActivePOI = createSelector(
	getRotationStepsById,
	getActivePOIId,
	(byId, id): ReducerRotationStep => byId[id]
);

export const getRotationStepByPosition = createSelector(
	getPositionedRotationStep,
	getRotationStepsById,
	(positionedStep, rotationSteps) =>
		rotationSteps[positionedStep.rotationStepId]
);

const getRotationStepEvents = createSelector(
	getRotationStepEventIds,
	getEventsById,
	(stepEvents, allEvents): RotationStepEvent[] =>
		(stepEvents || []).map(id => allEvents[id])
);

const getRotationStepCargoUnits = createSelector(
	getRotationStepUnitIds,
	state => state.vesselProgramme.operationUnitsById,
	(ids, map) => (ids || []).map(id => map[id])
);

const getRotationStepCargoUnitsEventIds = createSelector(
	getRotationStepCargoUnits,
	units =>
		units
			.map(current => current.events || [])
			.reduce((all, current) => all.concat(current), [])
);

const getRotationStepCargoUnitEvents = createSelector(
	getRotationStepCargoUnitsEventIds,
	getEventsById,
	(ids, map) => ids.map(id => map[id])
);

const getRotationStepCargoUnitRecordedEvents = createSelector(
	getRotationStepCargoUnitEvents,
	events =>
		events.filter(event => event.status === PortCallEventStatus.RECORDED)
);

export const getRotationStepRecordedEvents = createSelector(
	getRotationStepEvents,
	events =>
		(events || []).filter(e => e && e.status === PortCallEventStatus.RECORDED)
);

export const getIsRecordedEventsEmpty = createSelector(
	[getRotationStepCargoUnitRecordedEvents, getRotationStepRecordedEvents],
	(
		cargoRecordedEvents: RotationStepEvent[],
		recordedEvents: RotationStepEvent[]
	) => !cargoRecordedEvents.length && !recordedEvents.length
);

export const getCanDeletePoi = createSelector(
	getRotationStepById,
	getRotationStepPoiType,
	isVesselProgrammeInEditMode,
	(rotationStep, poiType, isInEditMode): boolean => {
		const isAutoGenerated = isIdAutoGenerated(rotationStep.id);
		const isDefault = rotationStep.isDefault;
		const isPoiThatCanBeDeleted = !!poiType;
		return (
			(isInEditMode && isPoiThatCanBeDeleted && !isDefault) ||
			(isInEditMode && isPoiThatCanBeDeleted && isAutoGenerated)
		);
	}
);

export const getSurroundingRotationSteps = createSelector(
	[getPreviousRotationStepId, getNextRotationStepId, getRotationStepsById],
	(previous, next, steps) => [previous, next].map(id => id && steps[id])
);

export const getDeletePOIConfirmationMessage = createSelector(
	getActivePOI,
	(activePOI): string => {
		if (!isEmpty(activePOI) && activePOI.units.length) {
			return 'All allocated cargo will be un-allocated. Are you sure you want to remove this POI from the Vessel Programme?';
		}
		return 'Are you sure you want to remove this POI from the Vessel Programme?';
	}
);
