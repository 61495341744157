import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import {
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse,
	ShipMeasurementsType
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrievePortCallShipMeasurementsSuccess = (
	state: State,
	action: Success<
		RetrievePortCallShipMeasurementsRequest,
		RetrievePortCallShipMeasurementsResponse
	>
): State => ({
	...state,
	ship: {
		...state.ship,
		[action.params.portCallId]: [
			{
				...action.result.arrivalShipMeasurements,
				measurementType: ShipMeasurementsType.ARRIVAL
			},
			{
				...action.result.departureShipMeasurements,
				measurementType: ShipMeasurementsType.DEPARTURE
			}
		]
	}
});

export const onRetrievePortCallShipMeasurements = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse
>('fetchStatuses', 'ship', {
	doneReducer: onRetrievePortCallShipMeasurementsSuccess
});
