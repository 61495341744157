import React from 'react';
import { connect } from 'react-redux';
import {
	retrieveBankAccount,
	resetBankAccounts,
	setActiveBankId
} from 'store/masterdata/bankAccounts/actions';
import { DataFetcher } from 'utils/components';
import { FetchStatus } from 'services/api/apiTypes';
import {
	getBankAccountsById,
	getBankAccountFetchStatus
} from 'store/masterdata/bankAccounts/selectors';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import GeneralInformation from './GeneralInformation';
import AccountInformation from './AccountInformation';
import CountrySpecificInformation from './CountrySpecificInformation';
import styles from './BankAccountDetails.module.scss';
import LOBD from './LOBD';
import { Alert } from 'components/antd';
import { Gap } from 'components';
import { AppState } from 'store-types';

interface BankAccountDetailsProps {
	bankDetailId: string;
	isLobdVisible: boolean;
	// store
	bankAccountDetails: BankAccount;
	fetchStatus: FetchStatus;
	// actions
	retrieveBankAccount: typeof retrieveBankAccount;
	resetBankAccounts: typeof resetBankAccounts;
	setActiveBankId: typeof setActiveBankId;
}

class BankAccountDetails extends React.Component<BankAccountDetailsProps> {
	retrieve = () => {
		this.props.retrieveBankAccount({
			id: this.props.bankDetailId
		});
		this.props.setActiveBankId(this.props.bankDetailId);
	};

	componentWillUnmount() {
		this.props.resetBankAccounts();
	}

	render() {
		const { bankAccountDetails, fetchStatus, isLobdVisible } = this.props;
		return (
			<DataFetcher dispatch={this.retrieve} fetchStatus={fetchStatus}>
				{() => (
					<div className={styles.bankDetails}>
						{isLobdVisible &&
							!bankAccountDetails.lobd && (
								<Gap isBlock bottom="sm">
									<Alert
										showIcon
										type="warning"
										message={null}
										description="Please upload LOBD and validate bank account details"
									/>
								</Gap>
							)}
						<GeneralInformation bank={bankAccountDetails} />
						<AccountInformation bank={bankAccountDetails} />
						<CountrySpecificInformation bank={bankAccountDetails} />
						{isLobdVisible && <LOBD bank={bankAccountDetails} />}
					</div>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(state: AppState, props: Pick<BankAccountDetailsProps, 'bankDetailId'>) => ({
		bankAccountDetails: getBankAccountsById(state)[props.bankDetailId],
		fetchStatus: getBankAccountFetchStatus(state)
	}),
	{
		retrieveBankAccount,
		resetBankAccounts,
		setActiveBankId
	}
)(BankAccountDetails);
