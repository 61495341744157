import {
	PortJob,
	RetrieveJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../linkJobActionCreator';

const RETRIEVE_LINKED_APPOINTMENT_JOB = 'RETRIEVE_LINKED_APPOINTMENT_JOB';

export const retrievelinkedAppointmentJobAsync = actionCreator.async<
	RetrieveJobParams,
	PortJob,
	Error
>(RETRIEVE_LINKED_APPOINTMENT_JOB);

export const retrievelinkedAppointmentJob = actionCreator<RetrieveJobParams>(
	RETRIEVE_LINKED_APPOINTMENT_JOB
);
