import { PortCallsState } from '../portCallsState';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
export const onSetActivePortCallId = (
	state: PortCallsState,
	action: PortCall['id']
): PortCallsState => ({
	...state,
	context: {
		...state.context,
		activePortCallId: action
	}
});
export const onResetActivePortCallId = (
	state: PortCallsState
): PortCallsState => ({
	...state,
	context: {
		...state.context,
		activePortCallId: ''
	}
});
