import { Success } from 'typescript-fsa';
import { FundingsState } from '../financeFundingsState';
import {
	RetrieveFinanceRequest,
	RetrieveFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import { keyBy } from 'lodash';

export const onRetrieveFundings = (
	state: FundingsState,
	{ result }: Success<RetrieveFinanceRequest, RetrieveFinanceResponse>
): FundingsState => {
	const { fundings } = result;
	if (!fundings) {
		return state;
	}
	const byId = keyBy(fundings.elements, 'id');
	const allIds = fundings.elements.map(funding => funding.id);

	return {
		...state,
		byId,
		allIds,
		propertiesToView: fundings.propertiesToView,
		metadata: fundings.metadata || null
	};
};
