import React from 'react';
import cn from 'classnames';
import { Text } from 'components';
import { IconType } from 'components/antd/Icon/IconTypes';
import {
	FinanceTransaction,
	FinanceTransactionStatus
} from 'services/api/finance/financeServiceTypes';
import { Icon, Popover } from 'components/antd';
import financeStyles from 'sections/PortCall/Finance/Finance.module.scss';
import getGLInfoTable from './GLInfoTable';
import styles from './GLMark.module.scss';

interface GLMarkProps {
	hasTooltip?: boolean;
	transactions: FinanceTransaction[];
	popupContainerId?: string;
}

class GLMark extends React.Component<GLMarkProps> {
	getIconType = (lastTransaction: FinanceTransaction) => {
		let icon: keyof IconType;

		switch (lastTransaction.status) {
			case FinanceTransactionStatus.FAILED:
				icon = 'close';
				break;
			case FinanceTransactionStatus.COMPLETED:
				if (lastTransaction.isRevertingTransaction) {
					icon = 'undo';
				} else {
					icon = 'check';
				}
				break;
			default:
				icon = 'clock-circle-o';
		}

		return icon;
	};

	render() {
		const { transactions, hasTooltip = true, popupContainerId } = this.props;
		const lastTransaction = transactions[0];
		/**
		 * We have to repeat code with Text and Icon, because in case we put it in the method or variable popover doesn't work
		 * TODO: in case we're going to extend logic of GlMark - we should move it to separate component
		 */
		return (
			<div className={cn(styles.glMark, financeStyles.cannotBeDisabled)}>
				{hasTooltip ? (
					<Popover
						content={getGLInfoTable(transactions)}
						trigger="hover"
						placement="leftBottom"
						title="GL Transactions"
						overlayClassName={styles.glTablePopover}
						getPopupContainer={() =>
							(popupContainerId && document.getElementById(popupContainerId)) ||
							document.body
						}
					>
						<Text weight="bold">GL</Text>
						<Icon
							type={this.getIconType(lastTransaction)}
							size="xs"
							offset="left"
							valign="middle"
						/>
					</Popover>
				) : (
					<>
						<Text weight="bold">GL</Text>
						<Icon
							type={this.getIconType(lastTransaction)}
							size="xs"
							offset="left"
							valign="middle"
						/>
					</>
				)}
			</div>
		);
	}
}

export default GLMark;
