import React, { useEffect } from 'react';
import { getNotificationAddonsByActionType } from 'store/notifications/selectors';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { AppState } from 'store-types';
import { resetNotificationAddons } from 'store/notifications/actions';

interface PageTableData {
	failedLoading?: string;
}
interface PageTableProps {
	entityName: string;
	actionType: string;
	children: (data: PageTableData) => React.ReactNode;
}
function PageTable({ entityName, actionType, children }: PageTableProps) {
	const dispatch = useDispatch();
	const addons = useSelector(
		(state: AppState) =>
			getNotificationAddonsByActionType(state, actionType ?? ''),
		shallowEqual
	);

	useEffect(
		() => {
			return () => {
				// reset state to not collect redundant information
				dispatch(resetNotificationAddons());
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<>
			{children({
				failedLoading: addons?.errors
					? `Failed to load more ${entityName} due to unexpected error. Refresh the page and try again.`
					: undefined
			})}
		</>
	);
}

export default PageTable;
