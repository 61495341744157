import React from 'react';
import { connect } from 'react-redux';
import { PageHeader, ScrollableLayout, Gap } from 'components';
import DocumentViewerContent from './DocumentViewerContent';

import { openModal } from 'store/modals/actions';

import { getIsDocumentPDF } from 'store/documents/selectors';

import {
	DocumentViewerAndAsideCommonProps,
	DocumentViewerPaginationProps
} from './DocumentViewerTypes';
import { AppState } from 'store-types';

interface DocumentViewerBaseProps extends DocumentViewerAndAsideCommonProps {
	isStatic?: boolean;
	returnUrl: string;
	/** Indicates that document is split (applies to PDF files) */
	documentPagesCount: number | undefined;
	// from mapStateToProps
	documentId: string;
	title: string;
	// from mapDispatchToProps
	openModal: typeof openModal;
}

// Shared with DocumentViewerContent.tsx
export interface DocumentViewerProps
	extends DocumentViewerBaseProps,
		Partial<DocumentViewerPaginationProps> {}

class DocumentViewer extends React.Component<DocumentViewerProps> {
	static defaultProps: Partial<DocumentViewerProps> = {
		canSubmit: true
	};

	onBeforeLeave = () => {
		this.props.openModal({
			name: 'DocumentViewer',
			type: 'confirmLeavePage',
			alertType: 'warning',
			returnUrl: this.props.returnUrl,
			data: {
				title: 'Quit document processing',
				description: (
					<>
						Closing the page will not save the uploaded file.
						<Gap top>Are you sure you want to continue?</Gap>
					</>
				),
				cancelText: 'Back to processing',
				okText: 'Yes, continue'
			}
		});
	};

	render() {
		const { isStatic, children, ...props } = this.props;

		return (
			<ScrollableLayout stretch direction="horizontal">
				<PageHeader.NewTab
					onClose={this.onBeforeLeave}
					isNewTab={this.props.preventSplit}
					shouldNewTabClose={!this.props.preventSplit}
				>
					{this.props.title}
				</PageHeader.NewTab>
				<ScrollableLayout>
					<DocumentViewerContent
						{...props}
						onBeforeLeave={!isStatic ? this.onBeforeLeave : undefined}
					>
						{children}
					</DocumentViewerContent>
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<DocumentViewerBaseProps, 'documentId'>) => ({
		documentId: ownProps.documentId,
		isDocumentPDF: getIsDocumentPDF(state, ownProps.documentId)
	}),
	{
		openModal
	}
)(DocumentViewer);
