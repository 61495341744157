import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollableLayout } from 'components';
import { ModalContext } from 'context';
import GroupsHeader from './GroupsHeader/GroupsHeader';
import GroupsTable from './GroupsTable/GroupsTable';
import GroupsLayout from './GroupsLayout';
import GroupsModals from './GroupsModals/GroupsModals';
import { retrieveCompaniesForUser } from 'store/companies';
import { resetGroups } from 'store/groups/actions';
import { getAllCompaniesForUserFetchStatus } from 'store/companies/selectors';
import { AppState } from 'store-types';
import { DataFetcher } from 'utils/components';

const GroupsRoute: FC<{}> = () => {
	const dispatch = useDispatch();
	const companiesFetchStatus = useSelector<
		AppState,
		ReturnType<typeof getAllCompaniesForUserFetchStatus>
	>(getAllCompaniesForUserFetchStatus);

	const retrieve = useCallback(() => {
		dispatch(retrieveCompaniesForUser({ limit: 1000 }));
	}, [dispatch]);

	const reset = useCallback(() => {
		dispatch(resetGroups());
	}, [dispatch]);

	return (
		<ModalContext.Provider>
			<GroupsLayout>
				<GroupsHeader />
				<ScrollableLayout stretch>
					<DataFetcher
						fetchStatus={companiesFetchStatus}
						dispatch={retrieve}
						reset={reset}
					>
						{() => <GroupsTable />}
					</DataFetcher>
				</ScrollableLayout>
				<GroupsModals />
			</GroupsLayout>
		</ModalContext.Provider>
	);
};

export default GroupsRoute;
