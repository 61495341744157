import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	PortJobBaseRequest,
	AcceptanceData
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveAcceptanceDataSuccess = (
	state: PortJobState,
	{ result }: Success<PortJobBaseRequest, AcceptanceData>
) => ({
	...state,
	acceptanceData: result
});

export const onRetrieveAcceptanceData = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	PortJobBaseRequest,
	AcceptanceData
>('fetchStatuses', 'acceptanceData', {
	doneReducer: onRetrieveAcceptanceDataSuccess
});
