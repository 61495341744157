import React, {
	createContext,
	useState,
	useCallback,
	useMemo,
	useEffect,
	useContext
} from 'react';

import { usePrevious } from 'utils/hooks';
import { FetchStatus } from 'services/api/apiTypes';
import { getIsLoading, getIsFailure, getIsLoaded } from 'store/selectors';

/**
 * @file ModalContext
 * @example
 * <ModalContext.Provider>
 *          // trigger modal
 *          <ModalContext.Consumer>
 *              {({ showModal }) => (
 *                  <button onClick={() => { showModal('modalName', optionalData) }} />
 *              )}
 *          <ModalContext.Consumer>
 *
 *          // show modal
 *          <ModalContext.Consumer>
 *              {({ name }) => (
 *                  <>
 *                      {name === 'modalName' && (
 *                          // show modal here
 *                      )}
 *                  </>
 *              )}
 *          </ModalContext.Consumer>
 * </ModalContext.Provider>
 */

type ContextValueName = string | null;
// TODO
type ContextModalValue = any; // eslint-disable-line

export interface ModalContextValue<T = ContextModalValue> {
	name: ContextValueName;
	modalValue: T;
	modalSubmitting: boolean;
	setModalSubmitting: (value: boolean) => void;
	showModal: (name: string, modalValue?: T) => void;
	hideModal: () => void;
}

export type ModalContextBaseProps<T = ContextModalValue> = Pick<
	ModalContextValue<T>,
	'modalValue' | 'modalSubmitting' | 'hideModal'
>;

export const UseModalContext = createContext<ModalContextValue>({
	name: null,
	modalValue: undefined,
	modalSubmitting: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setModalSubmitting: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	showModal: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	hideModal: () => {}
});

const ModalProvider: React.FunctionComponent = ({ children }) => {
	const [name, setName] = useState<ContextValueName>(null);
	const [modalValue, setModalValue] = useState<ContextModalValue>();
	const [submitting, setSubmitting] = useState<boolean>(false);

	const showModal = useCallback((name: string, value?: ContextModalValue) => {
		setModalValue(value);
		setName(name);
	}, []);

	const hideModal = useCallback(() => {
		setName(null);
		setModalValue(undefined);
	}, []);

	const setModalSubmitting = useCallback(value => {
		setSubmitting(value);
	}, []);

	return (
		<UseModalContext.Provider
			value={{
				name,
				modalValue,
				showModal,
				hideModal,
				modalSubmitting: submitting,
				setModalSubmitting
			}}
		>
			{children}
		</UseModalContext.Provider>
	);
};

interface ModalSubmitResolveProps {
	fetchStatus: FetchStatus;

	resolveCallback?: (value: ModalContextValue) => void;
	hideModalOnDone?: boolean;
}
function ModalSubmitResolve({
	fetchStatus,
	resolveCallback,
	hideModalOnDone
}: ModalSubmitResolveProps) {
	const value = useContext(UseModalContext);
	const { modalSubmitting, setModalSubmitting, hideModal } = value;
	const prevFetchStatus = usePrevious(fetchStatus);

	const beforeResolveCallback = useCallback(() => {
		if (resolveCallback) {
			resolveCallback(value);
			return;
		}
		hideModal();
	}, [resolveCallback, hideModal, value]);

	const {
		submitting,
		prevSubmitting,
		submittingFailed,
		submittingSuccess
	} = useMemo(
		() => ({
			prevSubmitting: prevFetchStatus ? getIsLoading(prevFetchStatus) : false,
			submitting: getIsLoading(fetchStatus),
			submittingFailed: getIsFailure(fetchStatus),
			submittingSuccess: getIsLoaded(fetchStatus)
		}),
		[fetchStatus, prevFetchStatus]
	);

	useEffect(() => {
		// keep `modalSubmitting` state up to date
		if (prevSubmitting !== submitting && submitting !== modalSubmitting) {
			setModalSubmitting(submitting);
		}

		if (
			prevSubmitting &&
			!submitting &&
			modalSubmitting &&
			(submittingSuccess || submittingFailed)
		) {
			if (!submittingFailed) {
				beforeResolveCallback();
			}

			if (hideModalOnDone) {
				hideModal();
			}
		}
	}, [
		prevSubmitting,
		submitting,
		submittingFailed,
		submittingSuccess,
		modalSubmitting,
		setModalSubmitting,
		hideModalOnDone,
		hideModal,
		beforeResolveCallback
	]);

	return null;
}

class ModalContext extends React.Component {
	static Consumer = UseModalContext.Consumer;
	static Provider = ModalProvider;
	static SubmitResolve = ModalSubmitResolve;

	render() {
		return null;
	}
}

export default ModalContext;
