import { SavingsState } from '../savingsState';
import {
	RetrieveSavingsRequest,
	RetrieveTimeSavingsResponse
} from 'services/api/savings/savingsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';

export function onRetrieveTimeSavingsStarted(
	state: SavingsState
): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			timeSavings: FetchStatus.PENDING
		}
	};
}
export function onRetrieveTimeSavingsSuccess(
	state: SavingsState,
	action: Success<RetrieveSavingsRequest, RetrieveTimeSavingsResponse>
): SavingsState {
	const { result } = action;
	return {
		...state,
		timeSavings: { ...result },
		fetchStatuses: {
			...state.fetchStatuses,
			timeSavings: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveTimeSavingsFailed(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			timeSavings: FetchStatus.FAILURE
		}
	};
}
