import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { call, put } from 'redux-saga/effects';
import {
	DownloadApiSubscriptionRequest as Request,
	DownloadApiSubscriptionResponse as Response
} from 'services/api/developerPortal/developerPortalServiceTypes';
import { downloadApiSubscriptionAsync as async } from '../actions';
import { AxiosTypedResponse } from 'services/api/apiTypes';
import Axios from 'axios';
import { downloadURI } from 'utils';

const api = Api.DeveloperPortal.downloadApiSubscription;

function* worker(params: Request) {
	yield put(async.started(params));
	try {
		const response: AxiosTypedResponse<Response> = yield call(api, {
			id: params.id,
			fileType: params.fileType,
			fileName: params.fileName
		});

		if (response.data.downloadUrl) {
			if (params.fileName) {
				Axios(response.data.downloadUrl)
					.then(res => new Blob([res.data], { type: 'text/html' }))
					.then(blob => {
						const url = URL.createObjectURL(blob);
						const a = document.createElement('a');
						a.style.display = 'none';
						a.href = url;
						// the filename you want
						a.download = params.fileName;
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						URL.revokeObjectURL(url);
					});
			} else {
				yield call(downloadURI, response.data.downloadUrl);
			}
		}

		yield put(async.done({ params, result: response.data, response: null }));
	} catch (error) {
		yield put(
			async.failed({
				params,
				error
			})
		);
	}
}

export default makeTakeEveryWatcher<Request, Response, Error>(
	{ api, async },
	worker
);
