import React from 'react';
import { connect } from 'react-redux';
import { isFunction } from 'lodash';

import PortCallActAs from './PortCallActAs/PortCallActAs';
import { DataFetcher } from 'utils/components';

import {
	retrievePortCall,
	resetPortCall,
	setPortCallContext,
	resetActivePortCallId
} from 'store/portcalls/actions';
import {
	getActivePortCallFetchStatus,
	getActivePortCallId
} from 'store/portcalls/portCallsSelectors';
import {
	setActivePortJobCode,
	resetActivePortJobCode
} from 'store/portJobs/actions';
import { retrievePortCallActAs } from 'store/portCall/actions';
import {
	getPortCallId,
	getPortJobCode,
	getActivePortCallTabId,
	getPortCallOperationsTab
} from './Layout/PortCallLayout.func';

import { PortCallTabBaseProps } from 'sections/PortCall';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';
import { getAuthActAs } from 'store/auth/selectors';

// TODO temp solution to force PortCallLayout
// to render after async call to getting port call by id is done
type PortCallChildrenFn = () => React.ReactNode;

interface PortCallContainerOwnProps {
	match: PortCallTabBaseProps['match'];
	children?: React.ReactNode | PortCallChildrenFn;
}

interface PortCallContainerProps extends PortCallContainerOwnProps {
	activePortCallId: string;
	fetchStatus: FetchStatus;
	isActingUser: boolean;
	retrievePortCall: typeof retrievePortCall;
	resetPortCall: typeof resetPortCall;
	setPortCallContext: typeof setPortCallContext;
	setActivePortJobCode: typeof setActivePortJobCode;
	resetActivePortJobCode: typeof resetActivePortJobCode;
	resetActivePortCallId: typeof resetActivePortCallId;
	retrievePortCallActAs: typeof retrievePortCallActAs;
}

/**
 * @file Container responsible for setting current port call context
 * Used for `/portcalls/{portCallId}/..` routes
 */
class PortCallContainer extends React.Component<PortCallContainerProps> {
	componentDidMount() {
		const portCallId = getPortCallId(this.props.match);
		const jobCode = getPortJobCode(this.props.match);
		const portCallTab = getActivePortCallTabId(this.props.match);
		const portCallOperationsTab = getPortCallOperationsTab(this.props.match);

		this.props.setPortCallContext({
			activePortCallId: portCallId,
			activePortCallTabId: portCallTab,
			portCallOperationsTab
		});

		if (jobCode) {
			this.props.setActivePortJobCode(jobCode);
		}

		if (!isFunction(this.props.children)) {
			this.retrievePortCall();
		}
	}

	componentDidUpdate(prevProps: PortCallContainerProps) {
		const portCallId = getPortCallId(this.props.match);
		const jobCode = getPortJobCode(this.props.match);
		const portCallTab = getActivePortCallTabId(this.props.match);
		const portCallOperationsTab = getPortCallOperationsTab(this.props.match);

		if (prevProps.activePortCallId !== portCallId) {
			this.props.setPortCallContext({
				activePortCallId: portCallId,
				activePortCallTabId: portCallTab,
				portCallOperationsTab
			});
			if (jobCode) {
				this.props.setActivePortJobCode(jobCode);
			}
			if (!this.props.isActingUser) {
				this.props.retrievePortCallActAs({ portCallId });
			}
			this.retrievePortCall();
		}
	}

	componentWillUnmount() {
		this.props.resetActivePortJobCode();
		this.props.resetPortCall();
		this.props.resetActivePortCallId();
	}

	retrievePortCall = () => {
		this.props.retrievePortCall({
			id: getPortCallId(this.props.match),
			expand: 'PortJobsBasic'
		});
	};

	render() {
		const { fetchStatus, children } = this.props;
		if (!isFunction(children)) {
			return <>{this.props.children}</>;
		}
		return (
			<DataFetcher fetchStatus={fetchStatus} dispatch={this.retrievePortCall}>
				{() => <>{children()}</>}
			</DataFetcher>
		);
	}
}

const PortCallContainerConnected = connect(
	(state: AppState) => ({
		fetchStatus: getActivePortCallFetchStatus(state),
		activePortCallId: getActivePortCallId(state),
		isActingUser: Boolean(getAuthActAs(state))
	}),
	{
		retrievePortCall,
		resetPortCall,
		setPortCallContext,
		setActivePortJobCode,
		resetActivePortCallId,
		resetActivePortJobCode,
		retrievePortCallActAs
	}
)(PortCallContainer);

const withActAs: React.SFC<PortCallContainerOwnProps> = props => {
	return (
		<PortCallActAs portCallId={props.match.params.portCallId}>
			<PortCallContainerConnected {...props} />
		</PortCallActAs>
	);
};

export default withActAs;
