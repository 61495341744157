import actionCreator from '../configurationFilesActionCreator';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'ADD_UPDATE_CONFIGURATION_FILE';

export const openConfigurationFileModal = actionCreator<string | null>(
	`${ACTION_NAME}_OPEN`
);
export const closeConfigurationFileModal = actionCreator(
	`${ACTION_NAME}_CLOSE`
);

export const configurationFileModalSubmit = actionCreator(
	`${ACTION_NAME}_SUBMIT`
);

export const uploadConfigurationFileDone = actionCreator<
	UploadDocumentDoneResponse
>(`${ACTION_NAME}_UPLOAD_DONE`);
