import * as React from 'react';
import AntModal, { ModalProps as AntModalProps } from 'antd/lib/modal/Modal';
import { ButtonProps } from '../Button/Button';
import { Button, Icon } from 'components/antd';
import classNames from 'classnames';
import styles from './Modal.module.scss';

const MODAL_SIZE = {
	sm: 360,
	md: 640,
	lg: 960
};

export interface ModalProps extends AntModalProps {
	okButton?: {};
	okDisabled?: boolean;
	cancelButton?: {};
	hideCancelButton?: boolean;
	onlyText?: boolean;
	size?: number | 'sm' | 'md' | 'lg';
	fullSizeModal?: boolean;

	/**
	 * TODO
	 * Antd latest version has okButtonProps, cancelButtonProps.
	 * Review `okProps/cancelProps` after upgrade
	 */
	okProps?: ButtonProps;
	cancelProps?: ButtonProps;

	/**
	 * Cancel button on top right corner
	 */
	onClose?: () => void;
}

/**
 * React-modal Wrapper for phoenix
 * @param {*} param
 */
export class Modal extends React.Component<ModalProps> {
	static PureAnt: typeof AntModal;

	static defaultProps: Partial<ModalProps> = {
		size: 'sm',
		cancelText: 'Cancel',
		okText: 'Submit',
		wrapClassName: 'vertical-center-modal',
		maskClosable: false
	};

	/**
	 * Add `onClose` callback for close button on top right corner
	 * By default, `onCancel` fn is triggered when cancel button/close button on top right corner/mask are clicked,
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCancel = (e: React.MouseEvent<any>) => {
		const shouldTriggerOnClose =
			e.currentTarget.getAttribute('aria-label') === 'Close';

		if (shouldTriggerOnClose && this.props.onClose) {
			this.props.onClose();
			return;
		}

		if (this.props.onCancel) {
			this.props.onCancel(e);
		}
	};

	render() {
		const {
			className,
			okText,
			okButton,
			okDisabled,
			confirmLoading,
			cancelText,
			cancelButton,
			hideCancelButton,
			footer,
			onlyText,
			okProps,
			cancelProps,
			children,
			size,
			width,
			fullSizeModal,
			...restProps
		} = this.props;

		const Ok = okButton ? (
			okButton
		) : (
			<Button
				key="submit"
				className="qaa_submit_button"
				type="primary"
				onClick={this.props.onOk}
				disabled={okDisabled}
				loading={confirmLoading}
				{...okProps}
			>
				{okText}
			</Button>
		);
		const Cancel = cancelButton ? (
			cancelButton
		) : (
			<Button
				key="back"
				type="primary"
				transparent
				className="qaa_cancel_button"
				onClick={this.props.onCancel}
				{...cancelProps}
			>
				{cancelText}
			</Button>
		);

		const FooterRow =
			footer || footer === null
				? footer
				: [hideCancelButton ? <span /> : Cancel, Ok];

		const withBySize = size ? MODAL_SIZE[size] : size;

		const modalWith = width || withBySize;

		return (
			<AntModal
				className={classNames(
					styles.root,
					{
						[styles.onlyText]: onlyText,
						[styles.withTitle]: !!this.props.title,
						[styles.fullSizeModal]: fullSizeModal
					},
					className
				)}
				footer={FooterRow}
				{...restProps}
				width={modalWith}
				closeIcon={<Icon type="close" />}
				onCancel={this.onCancel}
			>
				{children}
			</AntModal>
		);
	}
}

Modal.PureAnt = AntModal;

export default Modal;
