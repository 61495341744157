import { last } from 'lodash';
import { AppState } from 'store-types';

const getRouteState = (state: AppState) => state.route;

const getPathsHistory = (state: AppState) => getRouteState(state).pathsHistory;

export const getCurrentPath = (state: AppState) => last(getPathsHistory(state));

export const getPreviousPath = (state: AppState) => {
	const history = getPathsHistory(state);
	return history.length >= 2 ? history[history.length - 2] : undefined;
};
