import { omit } from 'lodash';
import {
	WizardForms,
	WizardFormState,
	WizardFormBaseAction
} from '../wizardFormState';
export const onDestroy = (
	state: WizardFormState,
	action: WizardFormBaseAction
): WizardFormState => {
	const forms = omit<WizardForms>(state.forms, action.name);
	return {
		...state,
		activeWizardFormId: '',
		forms
	};
};
