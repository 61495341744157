import actionCreator from '../financeActionCreator';

const RETRIEVE_FINANCE_CYCLE_START = 'RETRIEVE_FINANCE_CYCLE_START';

const RETRIEVE_FINANCE_CYCLE_STOP = 'RETRIEVE_FINANCE_CYCLE_STOP';

export const retrieveFinanceCycleStart = actionCreator(
	RETRIEVE_FINANCE_CYCLE_START
);

export const retrieveFinanceCycleStop = actionCreator(
	RETRIEVE_FINANCE_CYCLE_STOP
);
