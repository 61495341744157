import { reduce, omitBy } from 'lodash';
import { FilterState, FilterItem } from 'store/filters/filtersState';
import { call, select } from 'redux-saga/effects';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	SUPPLIER_INVOICE_FORM_ID,
	SupplierInvoiceFilterParam,
	SupplierInvoiceParam,
	SupplierInvoiceFilters,
	filterTypeMap,
	filterIdentifiers
} from './../filtersSync';
import { AppState } from 'store-types.d';
import { SagaIterator } from 'redux-saga';
import { extractSearchParams, filterExist } from 'store/filters/filters.func';

const { HUB_PRINCIPAL, SEARCH } = SupplierInvoiceFilterParam;

const prepareFor = {
	[HUB_PRINCIPAL]: (filter: FilterItem[]): SupplierInvoiceFilters =>
		filterExist<SupplierInvoiceFilters>(filter, {
			[filter[0].type]: filter[0].key
		}),
	[SEARCH]: (filter: FilterItem[]): SupplierInvoiceFilters =>
		extractSearchParams(filterTypeMap, filter, filterIdentifiers)
};

function prepareRequest(filters: FilterState = {}) {
	const parameters = Object.values(SupplierInvoiceFilterParam);
	const preparedParameters = reduce<FilterState, SupplierInvoiceFilters>(
		filters,
		(result, value, key: SupplierInvoiceFilterParam) => {
			// skip not expected filter parameters
			if (!parameters.includes(key)) {
				return result;
			}

			if (key === HUB_PRINCIPAL) {
				result = {
					...result,
					...prepareFor[HUB_PRINCIPAL](value)
				};
				return result;
			}

			if (key === SEARCH) {
				result = {
					...result,
					...prepareFor[SEARCH](value)
				};
				return result;
			}

			result[key] = value[0].key;

			return result;
		},
		{}
	);

	/** Skip undefined values */
	return omitBy<SupplierInvoiceFilters>(
		preparedParameters,
		parameter => !parameter
	);
}

export default function*(): SagaIterator {
	const filters = yield select(
		(state: AppState): SupplierInvoiceParam => {
			const filterData = state.filters[SUPPLIER_INVOICE_FORM_ID];
			if (!filterData) {
				return {};
			}
			return filterData;
		}
	);

	const params = yield call(prepareRequest, filters);

	return params;
}
