import React, { Component } from 'react';
import { Entity } from 'app-types.d';
import { TD } from 'components/DataTable';
import { Icon, Tooltip } from 'components/antd';
import classNames from 'classnames';
import styles from '../Threads.module.scss';
import { IThread } from 'services/api/threads/threadsServiceTypes';

import { IconColor } from 'components/types';
import { get } from 'lodash';

const COL_ROW_CLASS = 'col-row';
const COL_ACCENT_CLASS = 'col-accent';
const COL_UNASSIGNED_CLASS = 'col-row-unassigned';

interface PortAndVesselColumnProps {
	thread: IThread;
}
enum IconItemType {
	ANCHOR = 'anchor',
	SHIP = 'ship'
}
interface ThreadGridLabelProps {
	className: string;
	type: IconItemType;
	color?: IconColor;
	text: string;
}
interface ThreadGridLabelWithTooltipProps {
	className: string;
	type: IconItemType;
	items: Entity[];
	text?: string;
}

const TooltipContent = (props: { items: Entity[] }) => (
	<>
		{props.items.map(item => (
			<div key={item.id} className={styles.tooltipRow}>
				{item.name}
			</div>
		))}
	</>
);

const ThreadGridLabel = (props: ThreadGridLabelProps) => (
	<div className={props.className}>
		<Icon type={props.type} color={props.color || 'standard'} />
		{props.text}
	</div>
);

const ThreadGridLabelWithTooltip = (props: ThreadGridLabelWithTooltipProps) => (
	<Tooltip
		placement="leftTop"
		title={<TooltipContent items={props.items} />}
		overlayClassName={styles.tooltipContent}
	>
		<div>
			<ThreadGridLabel
				className={props.className}
				type={props.type}
				text={props.text || 'Multiple'}
				color="standard"
			/>
		</div>
	</Tooltip>
);

class PortAndVesselColumn extends Component<PortAndVesselColumnProps> {
	render() {
		const { ports, vessels } = this.props.thread;
		const hasPortValue = ports && ports.length > 0;
		const portClassName = classNames(COL_ROW_CLASS, {
			[COL_ACCENT_CLASS]: hasPortValue,
			[COL_UNASSIGNED_CLASS]: !hasPortValue
		});
		const hasVesselValue = vessels && vessels.length > 0;
		const vesselClassName = classNames(COL_ROW_CLASS, {
			[COL_ACCENT_CLASS]: hasVesselValue,
			[COL_UNASSIGNED_CLASS]: !hasVesselValue
		});
		return (
			<TD>
				{ports && ports.length > 1 ? (
					<ThreadGridLabelWithTooltip
						className={portClassName}
						type={IconItemType.ANCHOR}
						items={ports}
					/>
				) : (
					<ThreadGridLabel
						className={portClassName}
						type={IconItemType.ANCHOR}
						text={get(ports, '[0].name', 'TBD')}
						color={hasPortValue ? 'standard' : 'warning'}
					/>
				)}
				{vessels && vessels.length > 1 ? (
					<ThreadGridLabelWithTooltip
						className={vesselClassName}
						type={IconItemType.SHIP}
						items={vessels}
					/>
				) : (
					<ThreadGridLabel
						className={vesselClassName}
						type={IconItemType.SHIP}
						text={get(vessels, '[0].name', 'TBD')}
						color={hasVesselValue ? 'standard' : 'warning'}
					/>
				)}
			</TD>
		);
	}
}

export default PortAndVesselColumn;
