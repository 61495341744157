import { call } from 'redux-saga/effects';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';

import { ContactType } from 'services/api/messages/messagesServiceTypes';

import Api from 'services/api';
import { navigateTo } from 'utils';
import { addContactAction } from './../reducers';

const apiCall = Api.Messages.addContact;
const { async } = addContactAction;

export const addContactWatcher = makeTakeEveryWatcher<
	ContactType,
	undefined,
	Error
>({
	api: apiCall,
	async,
	*onSuccess() {
		yield call(navigateTo, '/messages');
	},
	*onError(_params: ContactType) {
		yield call(navigateTo, '/messages');
	}
});

export default addContactWatcher;
