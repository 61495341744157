import { getFinanceBenchmarksData } from 'store/finance/selectors';
import { retrieveFinance } from './../actions/retrieveFinance';
import {
	retrieveBenchmarkStatus,
	retrieveBenchmarkStatusAsync
} from './../actions/retrieveBenchmarkStatus';
import { BenchmarkStatus } from 'services/api/finance/financeServiceTypes';
import {
	retrieveBenchmarkStatusCycleStart,
	retrieveBenchmarkStatusCycleStop
} from '../actions';
import { SagaIterator } from 'redux-saga';
import { cancel, select, fork, take, put, delay } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

function* worker(): SagaIterator {
	let currentStatus;
	while (true) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const benchmarkData = yield select(getFinanceBenchmarksData);
		if (currentStatus === BenchmarkStatus.SYNCHRONIZED) {
			yield put(
				retrieveFinance({
					portCallId,
					jobCode,
					benchmarkData: {
						...benchmarkData,
						benchmarkSynchronizationStatus: BenchmarkStatus.REQUESTED
					},
					isSilent: true
				})
			);
			yield put(retrieveBenchmarkStatusCycleStop());
		}

		if (
			benchmarkData.benchmarkSynchronizationStatus === BenchmarkStatus.REQUESTED
		) {
			yield put(retrieveBenchmarkStatus({ portCallId, jobCode }));
			const { payload } = yield take([
				retrieveBenchmarkStatusAsync.done,
				retrieveBenchmarkStatusAsync.failed
			]);
			currentStatus = payload.result.benchmarkSynchronizationStatus;
		}
		yield delay(5000);
	}
}

export default function*() {
	while (yield take(retrieveBenchmarkStatusCycleStart)) {
		const task = yield fork(worker);
		yield take(retrieveBenchmarkStatusCycleStop);
		yield cancel(task);
	}
}
