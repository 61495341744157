import { makeTakeEveryWatcher } from 'utils/sagaHelpers';

import { retrieveContactAction } from './../reducers';
import {
	ContactRequest,
	ContactType
} from 'services/api/messages/messagesServiceTypes';

import Api from 'services/api';

const apiCall = Api.Messages.retrieveContact;
const { async } = retrieveContactAction;

export const retrieveContactWatcher = makeTakeEveryWatcher<
	ContactRequest,
	ContactType,
	Error
>({
	api: apiCall,
	async
});

export default retrieveContactWatcher;
