import ReduxQuerySync from 'redux-query-sync';
import { filtersSync as usersFiltersSync } from 'store/users/actions/setUsersFilters';
import portcallsFiltersSync from 'store/portcalls/filtersSync';
import directBillsFiltersSync from 'store/directBills/filtersSync';
import threadsFiltersSync from 'store/threads/filtersSync';
import supplierInvoiceFiltersSync from 'store/supplierInvoice/filtersSync';
import groupsFiltersSync from 'store/groups/filtersSync';
import portCallMessagesFiltersSync from 'store/portJobs/portCallMessagesFiltersSync';
import history from 'services/history';

export default <T>(store: T) => {
	ReduxQuerySync({
		store,
		params: {
			...directBillsFiltersSync,
			...usersFiltersSync,
			...portcallsFiltersSync,
			...threadsFiltersSync,
			...supplierInvoiceFiltersSync,
			...groupsFiltersSync,
			...portCallMessagesFiltersSync
		},
		initialTruth: 'location',
		replaceState: true,
		history
	});
};
