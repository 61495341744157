import MockAdapter from 'axios-mock-adapter';

// import * as PortCallServiceMocks from './portCall/portCallServiceMocks';
// import * as PortCallsServiceMocks from './portCalls/portCallsServiceMocks';
//  import * as PortJobServiceMocks from './portJobs/portJobsServiceMocks';
// import * as DocumentsServiceMocks from './documents/documentsMocks';
// import * as PortsServiceMocks from './ports/portsServiceMocks';
// import * as TemplatesMocks from './templates/templatesMocks';
// import * as Mocks from './portCallMeasurements/portCallMeasurementsMocks';
// import * as PortCallDocumentsMocks from './portCallDocuments/portCallDocumentsMocks';
// import * as FinanceServiceMocks from './finance/financeServiceMock';
// import * as VesselProgrammeServiceMock from './vesselProgramme/vesselProgrammeServiceMock';
// import * as ThreadsServiceMocks from './threads/threadsServiceMocks';
// import * as TemplatesServiceMock from './templates/templatesMocks';
// import * as CompaniesServiceMock from './companies/companiesServiceMocks';
// import * as UsersServiceMock from './users/usersServiceMocks';
// import * as SupplierInvoicesMock from './supplierInvoices/supplierInvoicesMocks';
// import * as RegistrationMocks from './registration/registrationMocks';
// import * as PermissionServiceMocks from './permissions/permissionServiceMocks';
// import * as BenchmarkUrlMock from './benchmark/benchmarkServiceMock';
// import * as GroupsMock from './groups/groupsServiceMocks';

import { AxiosInstance } from 'axios';

export default function mockifier(client: AxiosInstance): AxiosInstance {
	const mock = new MockAdapter(client, { delayResponse: 1200 });
	// PortJobServiceMocks.mockRetrievePortJobOperations(mock);
	// PortJobServiceMocks.mockRetrieveJobStatuses(mock);
	// PortJobServiceMocks.mockPortJobAppointData(mock);
	// PortJobServiceMocks.mockPortJobAvailableGroups(mock);
	// PortJobServiceMocks.mockSetDefaultMessagingGroup(mock);
	// PortJobServiceMocks.mockAddAppointmentJobFromNomination(mock);
	// PortCallServiceMocks.mockRetrievePortCallActAs(mock);
	// PortCallsServiceMocks.mockRetrievePortCallStatuses(mock);
	// PortCallsServiceMocks.mockMergePortCalls(mock);
	// RegistrationMocks.mockRetrievePreRegistrationAgentCompany(mock);
	// RegistrationMocks.mockPreRegister(mock);
	// RegistrationMocks.mockPreRegisterVerify(mock);
	// DocumentsServiceMocks.mockRetrieveDocumentUploadConfiguration(mock);
	// DocumentsServiceMocks.mockRetrieveDocument(mock);
	// DocumentsServiceMocks.mockSaveDocument(mock);
	// PortsServiceMocks.mockRetrieveCanals(mock);
	// TemplatesMocks.mockRetrieveCompanyTemplate(mock);
	// Mocks.mockRetrievePortCallMeasurementsAccess(mock);
	// Mocks.mockRetrievePortCallBunkeringMeasurements(mock);
	// ThreadsServiceMocks.mockRetrieveThreads(mock);
	// ThreadsServiceMocks.mockRetrieveThreadsAvailableGroups(mock);
	// ThreadsServiceMocks.mockRetrieveTags(mock);
	// TemplatesServiceMock.mockRetrieveCompanyTemplateFiles(mock);
	// TemplatesServiceMock.mockUpdateCompanyTemplateFile(mock);
	// TemplatesServiceMock.mockAddCompanyTemplateFile(mock);
	// FinanceServiceMocks.mockRetrieveFinance(mock);
	// FinanceServiceMocks.mockRetrieveJobFinanceRevisions(mock);
	// FinanceServiceMocks.mockPOSTRetrieveSingleDA(mock);
	// FinanceServiceMocks.mockRetrieveDocumentImage(mock);
	// FinanceServiceMocks.mockUpdateServiceError(mock);
	// FinanceServiceMocks.mockRetrieveDAStatusHistory(mock);
	// FinanceServiceMocks.mockUpdateBenchmark(mock);
	// FinanceServiceMocks.mockViewBenchmark(mock);
	// PortCallDocumentsMocks.mockRetrieveDocumentImage(mock);
	// CompaniesServiceMock.mockRetrieveCompaniesConfigurationSettings(mock);
	// CompaniesServiceMock.mockRetrieveCompanyGeneralConfiguration(mock);
	// CompaniesServiceMock.mockRetrieveMainPrincipals(mock);
	// CompaniesServiceMock.mockRetrieveCompanyJobTypes(mock);
	// UsersServiceMock.mockRetrieveAuthUser(mock);
	// UsersServiceMock.mockRetrieveAuthUser(mock);
	// SupplierInvoicesMock.mockRetrieveSupplierInvoices(mock);
	// SupplierInvoicesMock.mockRetrieveSupplierInvoice(mock);
	// SupplierInvoicesMock.mockCreateSupplierInvoice(mock);
	// SupplierInvoicesMock.mockRetrieveSupplierInvoiceContextualization(mock);
	// SupplierInvoicesMock.mockPollDownloadSupplierInvoiceDocument(mock);
	// SupplierInvoicesMock.mockCancelCreditSupplierInvoice(mock);
	// VesselProgrammeServiceMock.mockAddCanalPOI(mock);
	// VesselProgrammeServiceMock.mockRetrieveVP(mock);
	// VesselProgrammeServiceMock.mockAcknowledgeVP(mock);
	// VesselProgrammeServiceMock.mockAcknowledgeVPWithComment(mock);
	// BenchmarkUrlMock.mockRetrieveBenchmarkUrl(mock);
	// GroupsMock.mockInviteThirdPartyLpa(mock);
	mock.onAny().passThrough();
	return client;
}
