import * as React from 'react';
import { connect } from 'react-redux';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { uncancelPortJob } from 'store/portJobs/actions';
import { PortJobStatus, JobType } from 'store/portJobs/constants';
import { navigateTo } from 'utils';

interface ContextBarsProps {
	portCallId: string;
	status: PortJobStatus;
	emailSubject: string;
	principalEmail: string;
	hasDuplicates: boolean;
	isCancelled: boolean;
	jobType: string;
	isJobLinked: boolean;
	// from mapDispatchToProps
	uncancelPortJob: typeof uncancelPortJob;
}

export const ContextBars = ({
	portCallId,
	status,
	emailSubject,
	principalEmail,
	hasDuplicates,
	isCancelled,
	jobType,
	isJobLinked,
	...props
}: ContextBarsProps) => {
	const emailLinkProps = {
		linkText: 'Request Assistance',
		linkEmail: principalEmail,
		emailSubject
	};
	return (
		<>
			{!isCancelled && (
				<>
					<ContextBar
						title="Add additional operation or confirm the job"
						type={ContextBarType.ACTION}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="For the Hub to appoint the agent, please confirm the job. If you have any more Operations to add, you can do this now, or at any other time after you have confirmed the job."
						condition={
							status === PortJobStatus.CREATED &&
							jobType === JobType.APPOINTMENT
						}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="The Customer needs to confirm this job."
						type={ContextBarType.INFO}
						actor={UserType.HUB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="You have nothing to do yet. We are waiting on the customer to confirm this job - once done, Optiс will inform you to process the job and appoint the LPA."
						condition={
							status === PortJobStatus.CREATED &&
							jobType === JobType.APPOINTMENT
						}
					/>
					<ContextBar
						title="Add additional operation or confirm the job"
						type={ContextBarType.ACTION}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle={
							'For the Hub to start processing the job, please confirm it. If you have any more Operations to add, you can do this now, or at any other time after you have confirmed the job.'
						}
						condition={
							status === PortJobStatus.CREATED &&
							jobType !== JobType.APPOINTMENT
						}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="The Customer needs to confirm this job."
						type={ContextBarType.INFO}
						actor={UserType.HUB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="You have nothing to do yet. We are waiting on the customer to confirm this job - once done, Optiс will inform you to process the job."
						condition={
							status === PortJobStatus.CREATED &&
							jobType !== JobType.APPOINTMENT
						}
					/>
					<ContextBar
						title="Thanks for confirming your Job!"
						type={ContextBarType.CONFIRMATION}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="What's next: This job will be processed by the Hub in the next few hours. We'll let you know if we have any questions."
						condition={status === PortJobStatus.CONFIRMED}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="The Job has been confirmed."
						type={ContextBarType.ACTION}
						actor={UserType.HUB}
						permissionCode={PermissionCode.APPOINT_AGENT}
						subtitle="Please check the Job details and appoint the LPA"
						condition={!hasDuplicates && status === PortJobStatus.CONFIRMED}
					/>
					<ContextBar
						title="Agent has been successfully appointed."
						type={ContextBarType.CONFIRMATION}
						actor={UserType.HUB}
						permissionCode={PermissionCode.APPOINT_AGENT}
						subtitle="What's next: LPA will accept the Job and start working on PDA"
						condition={status === PortJobStatus.APPOINTED}
					/>
					<ContextBar
						title="The Job has been confirmed."
						type={ContextBarType.ACTION}
						actor={UserType.HUB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="Please check the Job details and invite the LPA."
						condition={status === PortJobStatus.AWAITING_INVITATION}
					/>
					<ContextBar
						title="Thanks for confirming your Job!"
						type={ContextBarType.CONFIRMATION}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="What's next: This job will be processed by the Hub in the next few hours. We'll let you know if we have any questions."
						condition={status === PortJobStatus.AWAITING_INVITATION}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="The Job has been confirmed."
						type={ContextBarType.ACTION}
						actor={UserType.HUB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="Please check the job's details and link it to the related Appointment."
						condition={status === PortJobStatus.AWAITING_APPOINTMENT}
					/>
					<ContextBar
						title="Thanks for confirming your Job!"
						type={ContextBarType.CONFIRMATION}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="What's next: This job will be processed by the Hub in the next few hours. We'll let you know if we have any questions."
						condition={status === PortJobStatus.AWAITING_APPOINTMENT}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="The Job has been linked."
						type={ContextBarType.INFO}
						actor={UserType.HUB}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="What's next: LPA will accept the Appointment and start working on both jobs."
						condition={
							status === PortJobStatus.AWAITING_ACCEPTANCE && isJobLinked
						}
					/>
					<ContextBar
						title="Hub is processing your job."
						type={ContextBarType.INFO}
						actor={UserType.PRINCIPAL}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="What's next: This job is being processed by the Hub. We'll let you know if we have any questions."
						condition={status === PortJobStatus.AWAITING_ACCEPTANCE}
						emailLinkProps={emailLinkProps}
					/>
					<ContextBar
						title="Potential duplicate port calls are detected"
						type={ContextBarType.ACTION}
						actor={UserType.HUB}
						permissionCode={PermissionCode.VIEW_PORTCALLS}
						subtitle="Please view potential and resolve the conflict"
						condition={hasDuplicates}
						buttonProps={{
							action: () => navigateTo(`/portcalls/${portCallId}/duplicates`),
							actionText: 'View duplicates',
							actionPermission: {
								permissionCode: PermissionCode.MANAGE_PORTCALL,
								tooltip: {
									title: `You don't have permission to perform this action`,
									placement: 'left'
								}
							}
						}}
					/>
				</>
			)}
			<ContextBar
				title="The Job is cancelled"
				type={ContextBarType.INFO}
				actor={UserType.HUB}
				permissionCode={PermissionCode.MANAGE_PORTCALL}
				subtitle="You can reopen the job by clicking the button on the right."
				condition={isCancelled && status !== PortJobStatus.CLOSED}
				buttonProps={{
					action: () => props.uncancelPortJob(),
					actionText: 'Reopen'
				}}
			/>
		</>
	);
};

export default connect(null, { uncancelPortJob })(ContextBars);
