import React, { FC, useCallback } from 'react';
import { AutocompleteSearch } from 'components';
import {
	searchTypeMap,
	SupplierInvoicesGroupType
} from 'store/supplierInvoice/filtersSync';
import Api from 'services/api';
import { FilterItem } from 'store/filters/filtersState';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';

interface SearchSupplierInvoiceProps {
	onChange: (filter: FilterItem, type: string) => void;
	shouldShowCancelled?: boolean;
}

const SearchSupplierInvoice: FC<SearchSupplierInvoiceProps> = ({
	shouldShowCancelled,
	onChange
}) => {
	const onSearch = useCallback(
		(searchTerm: string, groupType?: SupplierInvoicesGroupType) =>
			searchEntitiesForAutocompleteSearch<SupplierInvoicesGroupType>(
				Api.SupplierInvoice.searchSupplierInvoices,
				searchTerm,
				groupType,
				shouldShowCancelled ? ['true'] : undefined
			),
		[shouldShowCancelled]
	);

	const onChangeHandler = useCallback(
		(option: FilterItem, type: string) => {
			onChange(option, type);
		},
		[onChange]
	);

	return (
		<AutocompleteSearch
			onSearch={onSearch}
			onChange={onChangeHandler}
			placeholder="Search in Supplier Invoices"
			typeMap={searchTypeMap}
			allowSelectInputValue={false}
		/>
	);
};

export default SearchSupplierInvoice;
