import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import { Row, Col, Button } from 'components/antd';
import { CargoLineFormDataTypes } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	FORM,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { Flex } from 'components';
import {
	getAllCommoditiesSelector,
	getMainCommodities
} from 'store/commodities/commoditiesSelectors';
import {
	getUnitsByMainCommodity,
	getUnitByCode
} from 'store/units/unitsSelectors';
import { getCountries } from 'store/countries/countriesSelectors';
import { getOperationTypeCodeById } from 'store/operations/operationsSelectors';
import { getValues } from 'store/form/selectors';
import {
	Commodity,
	MainCommodity
} from 'services/api/commodities/commoditiesServiceTypes';
import { Unit } from 'services/api/units/unitsServiceTypes';
import { Country } from 'services/api/countries/countriesServiceTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { Justify } from 'components/types';
import { getIsCargoLineFormInEditMode } from 'store/portJobs/selectors';
import { setEditableCargoId } from 'store/portCall/actions';
import { AppState } from 'store-types';
import { optionalEntityCodeToLabeledValue } from '../../Page2/PagePartials/Form/Form.func';
import { LabeledValue } from 'app-types';

const { OPERATION_TYPE } = FormFieldName;

interface CargoLineState {
	formValues: CargoLineFormDataTypes;
	operationTypeCode: OperationTypeCode | undefined;
	commodities: Commodity[];
	mainCommodities: MainCommodity[];
	units: Unit[];
	countries: Country[];
	unitInitialValue: Unit | undefined;
}

export interface CargoLineProps {
	onAdd: (cargoLine: CargoLineFormDataTypes) => void;
	onCancel: () => void;
}

interface CargoLineFormBaseProps extends CargoLineState, CargoLineProps {
	children: (handlers: CargoLineState) => React.ReactNode;
	inEditMode: boolean;
	setEditableCargoId: typeof setEditableCargoId;
}
export interface CargoLineFormProps
	extends InjectedFormProps<CargoLineFormDataTypes, CargoLineFormBaseProps>,
		CargoLineFormBaseProps {}

class CargoLineForm extends React.Component<CargoLineFormProps> {
	componentDidUpdate(prevProps: CargoLineFormProps) {
		const { unitInitialValue, units, formValues } = this.props;
		if (
			prevProps.unitInitialValue === undefined &&
			unitInitialValue !== undefined
		) {
			// setting an initial value for Autocomplete field
			// (API responds with "string" value, need a LabeledValue there)
			this.props.change(
				'commodityUnitOfMeasurementCode',
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				optionalEntityCodeToLabeledValue(unitInitialValue) as any
			);
		}

		/**
		 * Upon selecting commodity in Main Commodity dropdown
		 * the system must filter UOM dropdown and display
		 * appropriate Units only based on which commodity is already selected
		 */
		if (
			formValues.mainCommodity !== prevProps.formValues.mainCommodity &&
			formValues.commodityUnitOfMeasurementCode
		) {
			if (
				!units.some(
					unit => unit.code === formValues.commodityUnitOfMeasurementCode
				)
			) {
				this.props.change('commodityUnitOfMeasurementCode', '');
			}
		}
	}

	onSubmit = (
		values: CargoLineFormDataTypes & {
			commodityUnitOfMeasurementCode: LabeledValue;
		}
	) => {
		this.props.onAdd({
			...values,
			commodityUnitOfMeasurementCode: values.commodityUnitOfMeasurementCode
				? values.commodityUnitOfMeasurementCode.key
				: undefined
		});
		this.props.setEditableCargoId(undefined);
	};

	onCancel = () => this.props.onCancel();

	getState = (): CargoLineState => {
		const {
			commodities,
			mainCommodities,
			units,
			countries,
			formValues,
			operationTypeCode,
			unitInitialValue
		} = this.props;
		return {
			commodities,
			mainCommodities,
			units,
			countries,
			formValues,
			operationTypeCode,
			unitInitialValue
		};
	};

	render() {
		const Fields = this.props.children(this.getState());
		return (
			<form onSubmit={this.props.handleSubmit(this.onSubmit)}>
				{Fields}
				<Row>
					<Col sm={12}>
						<Flex justify={Justify.BETWEEN} align="center">
							<Button type="primary" transparent onClick={this.onCancel}>
								Cancel
							</Button>
							<Button
								type="primary"
								htmlType="submit"
								disabled={!this.props.valid}
							>
								{this.props.inEditMode ? 'Save Details' : 'Add Cargo Line'}
							</Button>
						</Flex>
					</Col>
				</Row>
			</form>
		);
	}
}

const selector = formValueSelector(FORM.portJob);

export default connect(
	(state: AppState) => {
		const operationType = selector(state, OPERATION_TYPE);
		const formValues = getValues<CargoLineFormDataTypes>(state, FORM.cargoLine);
		const unitInitialValue = formValues.commodityUnitOfMeasurementCode
			? getUnitByCode(state, formValues.commodityUnitOfMeasurementCode)
			: undefined;
		return {
			operationTypeCode: getOperationTypeCodeById(
				state,
				get(operationType, 'key')
			),
			units: getUnitsByMainCommodity(state, formValues.mainCommodity),
			formValues,
			commodities: getAllCommoditiesSelector(state),
			mainCommodities: getMainCommodities(state),
			inEditMode: getIsCargoLineFormInEditMode(state),
			countries: getCountries(state),
			unitInitialValue
		};
	},
	{
		setEditableCargoId
	}
)(
	reduxForm<CargoLineFormDataTypes, CargoLineFormBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.cargoLine,
		destroyOnUnmount: true,
		forceUnregisterOnUnmount: true
	})(CargoLineForm)
);
