import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';

export const onAddVPComment = (
	state: VesselProgrammeState,
	comment: string
): VesselProgrammeState => ({
	...state,
	edited: !!comment.length,
	comment
});
