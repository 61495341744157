import { ActionMeta } from 'app-types';
import { InviteUserRequest, User } from 'services/api/users/userServiceTypes';
import actionCreator from '../usersActionCreator';

const ACTION_META: ActionMeta = {
	done: {
		description: 'An invitation has been successfully sent to <%= email %>.'
	},
	behaviour: {
		type: 'submit'
	}
};

export interface InviteUserRequestPayload {
	form: string;
	requestParams: InviteUserRequest;
}

const ACTION_NAME = 'INVITE';
export const inviteUserAsync = actionCreator.async<
	InviteUserRequestPayload,
	User,
	Error
>(ACTION_NAME, ACTION_META);

export const inviteUser = actionCreator<InviteUserRequestPayload>(ACTION_NAME);
