import { PreFundingRequestsState } from '../financeFundingsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PrefundingsRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';

export const onSendPrefundingsRequest = makeFetchStatusReducers<
	PreFundingRequestsState,
	'fetchStatuses',
	Request,
	null
>('fetchStatuses', 'sendPrefundingsRequest');
