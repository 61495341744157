import { createContext } from 'react';

export interface ThreadContextValue {
	isReadOnly: boolean;
}

const ThreadContext = createContext<ThreadContextValue>({
	isReadOnly: false
});

export default ThreadContext;
