import * as React from 'react';
import { Link } from 'components';
import { Alert, Col, Row } from 'components/antd';
import styles from './ActionAlert.module.scss';
import { AlertProps } from 'components/antd/Alert/Alert';
import { Justify, Align, Theme } from 'components/types';
import { ButtonProps as AntButtonProps } from 'antd/lib/button/button';
import TimeAgo from 'components/Status/TimeAgo/TimeAgo';
import { PermissionButton, PermissionsCheckMode } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { TooltipProps } from '../antd/Tooltip/Tooltip';

export interface ActionAlertButtonProps {
	action: AntButtonProps['onClick'];
	actionText: string;
	loading?: boolean;
	actionPermission?: {
		permissionCode: PermissionCode | PermissionCode[];
		permissionsCheckMode?: PermissionsCheckMode;
		tooltip: TooltipProps;
	};
}

export interface EmailLinkProps {
	linkText: string;
	linkEmail: string;
	emailSubject: string;
}

interface ActionAlertProps {
	className?: string;
	// buttonProps used for custom action buttons
	buttonProps?: ActionAlertButtonProps;
	// emailLinkProps used for emailto links
	emailLinkProps?: EmailLinkProps;
	title?: string;
	subtitle?: React.ReactNode;
	type: AlertProps['type'];
	timeRemaining?: string;
	timeZone?: string;
}

const ActionAlert: React.FC<ActionAlertProps> = (props: ActionAlertProps) => {
	const {
		subtitle,
		type,
		buttonProps,
		emailLinkProps,
		title,
		timeRemaining,
		timeZone
	} = props;
	const actionPermission = buttonProps?.actionPermission;
	const hasAction = emailLinkProps || buttonProps;
	const content = (
		<Row middle={'xs'}>
			<Col sm={hasAction ? 10 : 12} justify={Justify.START}>
				<Row middle={'xs'}>
					<Col sm={timeRemaining ? 9 : 12} justify={Justify.START}>
						{title && <span>{title}</span>}
						{subtitle && <p>{subtitle}</p>}
					</Col>
					{timeRemaining && type === Theme.ERROR && (
						<Col sm={3} justify={Justify.END} align={Align.MIDDLE}>
							<p>
								Time remaining:{' '}
								<span className={styles.overdue}>
									<TimeAgo date={timeRemaining} timeZone={timeZone} accurate />{' '}
									overdue
								</span>
							</p>
						</Col>
					)}
				</Row>
			</Col>
			{hasAction && (
				<Col sm={2} justify={Justify.END} align={Align.MIDDLE}>
					{buttonProps && (
						<>
							{actionPermission ? (
								<PermissionButton
									permissionCode={actionPermission.permissionCode}
									permissionsCheckMode={actionPermission.permissionsCheckMode}
									type="primary"
									loading={buttonProps.loading}
									transparent
									tooltip={actionPermission.tooltip}
									onClick={buttonProps.action}
								>
									{buttonProps.actionText}
								</PermissionButton>
							) : (
								<Link
									className={styles.link}
									loading={buttonProps.loading}
									onClick={buttonProps.action}
								>
									{buttonProps.actionText}
								</Link>
							)}
						</>
					)}
					{emailLinkProps && (
						<Link
							href={`mailto:${emailLinkProps.linkEmail}?subject=${emailLinkProps.emailSubject}`}
						>
							{emailLinkProps.linkText}
						</Link>
					)}
				</Col>
			)}
		</Row>
	);
	return (
		<div className={styles.root}>
			<Alert
				className={props.className}
				showIcon
				type={type}
				message={content}
			/>
		</div>
	);
};

export default ActionAlert;
