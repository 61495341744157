import { Entity } from 'app-types';

import { GroupUser } from 'services/api/groups/groupsServiceTypes';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { addAvailableThreadGroup } from './actions/availableGroups/addAvailableThreadGroup';

import {
	// Retrieve Available Groups for Thread
	onRetrieveAvailableThreadGroupsStarted,
	onRetrieveAvailableThreadGroupsFailed,
	onRetrieveAvailableThreadGroupsSuccess,
	// Retrieve Users From Group
	onRetrieveUsersFromGroupSuccess,
	onRetrieveUsersFromGroupStarted,
	onRetrieveUsersFromGroupFailed,
	// Add Available Thread Group into the list
	onAddAvailableThreadGroup,
	// Remove Available Thread Group into the list
	onRemoveAvailableThreadGroup
} from './reducers/availableGroups';
import {
	removeAvailableThreadGroup,
	retrieveUsersFromGroupAsync,
	retrieveAvailableThreadGroupsAsync
} from 'store/thread/actions';

export interface ThreadAvailableGroupsReducerState {
	readonly groups: Entity[]; // Group Entity
	readonly groupUsers: { [groupId: string]: GroupUser[] };
	readonly fetchStatuses: {
		groups: boolean;
		users: boolean;
	};
}

const initialState: ThreadAvailableGroupsReducerState = {
	groups: [],
	groupUsers: {},
	fetchStatuses: {
		groups: false,
		users: false
	}
};

export function setFetchStatus(
	state: ThreadAvailableGroupsReducerState,
	type: 'groups' | 'users',
	value: boolean
) {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			[type]: value
		}
	};
}

export default reducerWithInitialState(initialState)
	.case(
		retrieveAvailableThreadGroupsAsync.started,
		onRetrieveAvailableThreadGroupsStarted
	)
	.case(
		retrieveAvailableThreadGroupsAsync.failed,
		onRetrieveAvailableThreadGroupsFailed
	)
	.case(
		retrieveAvailableThreadGroupsAsync.done,
		onRetrieveAvailableThreadGroupsSuccess
	)
	.case(retrieveUsersFromGroupAsync.started, onRetrieveUsersFromGroupStarted)
	.case(retrieveUsersFromGroupAsync.failed, onRetrieveUsersFromGroupFailed)
	.case(retrieveUsersFromGroupAsync.done, onRetrieveUsersFromGroupSuccess)
	.case(addAvailableThreadGroup, onAddAvailableThreadGroup)
	.case(removeAvailableThreadGroup, onRemoveAvailableThreadGroup);
