import { all, fork } from 'redux-saga/effects';

import retrieveEntityPermissionsForPortCallWatcher from './retrieveEntityPermissionsForPortCallSaga';
import retrievePermissionsWatcher from './retrievePermissionsSaga';
import retrieveEntityPermissionsForVPWatcher from './retrieveEntityPermissionsForVPSaga';

export default function*() {
	yield all([
		fork(retrieveEntityPermissionsForPortCallWatcher),
		fork(retrieveEntityPermissionsForVPWatcher),
		fork(retrievePermissionsWatcher)
	]);
}
