import * as H from 'history';
import { mapKeys } from 'lodash';
import { ActionType, CustomDimension } from './matomoTypes';

import { log } from '../analyticsUtils';

import config from 'services/config';
import { UserType } from 'services/api/users/userServiceTypes';

interface MatomoConfigOptions {
	userId?: string;
	userType?: UserType;
	exact?: boolean;
	siteId: string;
}

class Matomo {
	location: H.Location;
	unregister: () => void;
	options: MatomoConfigOptions = {
		siteId: ''
	};

	static getIsInitialized = () => {
		if (
			typeof window._paq === 'undefined' ||
			typeof window._paq.push !== 'function'
		) {
			return false;
		}
		return true;
	};

	static push(
		options: Array<ActionType | CustomDimension | string | object | null>
	) {
		if (!Matomo.getIsInitialized()) {
			return;
		}
		window._paq.push(options);
	}

	static setUserId(userId: string) {
		if (!Matomo.getIsInitialized()) {
			return;
		}
		Matomo.push([ActionType.SET_USER_ID, userId]);
		log('matomo', `called setUserId with ${userId}`);
	}

	static resetUserId() {
		if (!Matomo.getIsInitialized()) {
			return;
		}
		Matomo.push([ActionType.RESET_USER_ID]);
		log('matomo', `called resetUserId`);
	}

	static trackEvent = (event: {
		category: string;
		action: string;
		name?: string;
		value?: number;
	}) => {
		if (!Matomo.getIsInitialized()) {
			return;
		}
		const opts: Array<ActionType | string | number> = [
			ActionType.TRACK_EVENT,
			event.category,
			event.action
		];

		if (event.name) {
			opts.push(event.name);

			if (event.value) {
				opts.push(event.value);
			}
		}

		Matomo.push(opts);
	};

	constructor(options: MatomoConfigOptions) {
		this.options = options;
		this.initialize();
	}

	initialize() {
		const isInitialized = Matomo.getIsInitialized();

		if (!isInitialized) {
			window._paq = window._paq || [];
			if (this.options.userId) {
				Matomo.setUserId(this.options.userId);
			}

			/* tracker methods like "setCustomDimension" should be called before "trackPageView" */
			Matomo.push([ActionType.TRACK_PAGE_VIEW]);
			Matomo.push([ActionType.ENABLE_LINK_TRACKING]);
			Matomo.push([ActionType.ENABLE_JS_ERRORS_TRACKING]);
			this.injectScript();
		}

		return {
			// eslint-disable-next-line @typescript-eslint/unbound-method
			connectToHistory: this.connectToHistory
		};
	}

	injectScript = () => {
		const { matomoServerUrl } = config;
		const URL = matomoServerUrl.endsWith('/')
			? matomoServerUrl
			: `${matomoServerUrl}/`;
		Matomo.push([ActionType.SET_TRACKER_LINK, `${URL}piwik.php`]);
		Matomo.push([ActionType.SET_SITE_ID, this.options.siteId]);

		const g = document.createElement('script');
		const s = document.getElementsByTagName('script')[0];

		g.type = 'text/javascript';
		g.async = true;
		g.defer = true;
		g.src = `${URL}piwik.js`;

		if (s.parentNode) {
			s.parentNode.insertBefore(g, s);
		}
	};

	connectToHistory(history: H.History) {
		// track initial page load
		this.pageView(history.location);

		this.unregister = history.listen(location => {
			// don't track if user is leaving a route
			if (this.options.exact && this.location.pathname !== location.pathname) {
				return;
			}
			this.pageView(location);
		});

		return this.disconnectFromHistory;
	}

	disconnectFromHistory = () => {
		this.unregister();
		Matomo.resetUserId();
	};

	getCustomDimensions = () => ({
		[CustomDimension.USER_TYPE]: this.options.userType || null
	});

	pageView = (location: H.Location) => {
		// It's recommended not to call trackPageView() on the Site Search Result page,
		// instead Matomo.push(['trackSiteSearch'])

		if (this.location && this.location.pathname === location.pathname) {
			return;
		}

		const dimensions = this.getCustomDimensions();
		const dimensionsForTrack = mapKeys(
			dimensions,
			(_value, key) => `dimension${key}`
		);
		// masking sensitive URL parameters
		const safeURL = this.maskSensitiveURLParameters(location.pathname);
		Matomo.push([ActionType.SET_CUSTOM_URL, safeURL]);
		Matomo.push([
			ActionType.TRACK_PAGE_VIEW,
			document.title,
			dimensionsForTrack
		]);
		log(
			'matomo',
			`called trackPageView with path ${location.pathname}, userRole: ${
				dimensions[CustomDimension.USER_TYPE]
			}`
		);

		this.location = location;
	};

	private maskSensitiveURLParameters = (url: string): string => {
		// mask "token" for registration URLs
		let maskToken;
		// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
		if (url.includes('/registration') && url.match(/token=\w+/)) {
			maskToken = true;
		}
		// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
		if (url.includes(config.reportServerUrl) && url.match(/token=\w+/)) {
			maskToken = true;
		}

		if (maskToken) {
			return this.setURLParameter(url, 'token', 'xxx');
		}
		return url;
	};

	private setURLParameter = (
		uri: string,
		parameter: string,
		value: string
	): string => {
		const p = new RegExp(`/(.*${parameter}=)([^&]+)(.*)`, 'gi');
		return uri.replace(p, (_, $1, $2, $3) => {
			if ($2) {
				return `${$1}${value}${$3}`;
			}
			return _;
		});
	};
}

export default Matomo;
