import { all, fork } from 'redux-saga/effects';
import retrievePortCallMeasurementsAccessSaga from './retrievePortCallMeasurementsAccessSaga';
import retrievePortCallBunkeringMeasurementsSaga from './retrievePortCallBunkeringMeasurementsSaga';
import retrievePortCallShipMeasurementsSaga from './retrievePortCallShipMeasurementsSaga';
import retrievePortCallCargoMeasurementsSaga from './retrievePortCallCargoMeasurementsSaga';
import updateBunkeringOperationGradeSaga from './updateBunkeringOperationGradeSaga';
import updateShipMeasurementsSaga from './updateShipMeasurementsSaga';
import updateCargoMeasurementsSaga from './updateCargoMeasurementsSaga';
import retrievePortCallMeasurmentsCycleSaga from './portCallMeasurmentsSaga';

export default function*() {
	yield all([
		fork(retrievePortCallMeasurementsAccessSaga),
		fork(retrievePortCallBunkeringMeasurementsSaga),
		fork(retrievePortCallShipMeasurementsSaga),
		fork(retrievePortCallCargoMeasurementsSaga),
		fork(updateBunkeringOperationGradeSaga),
		fork(updateShipMeasurementsSaga),
		fork(updateCargoMeasurementsSaga),
		fork(retrievePortCallMeasurmentsCycleSaga)
	]);
}
