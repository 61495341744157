import {
	UpdateRefreshDirectBillRequest,
	UpdateRefreshDirectBillResponse,
	UpdateRefreshDirectBillPayload
} from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const UPDATE_REFRESH_DIRECT_BILL = 'UPDATE_REFRESH_DIRECT_BILL';
export const updateRefreshDirectBillAsync = actionCreator.async<
	UpdateRefreshDirectBillRequest,
	UpdateRefreshDirectBillResponse,
	Error
>(UPDATE_REFRESH_DIRECT_BILL, {
	templateParams: {
		entityName: 'Update DirectBill'
	}
});

export const updateRefreshDirectBill = actionCreator<
	UpdateRefreshDirectBillPayload
>(UPDATE_REFRESH_DIRECT_BILL);
