import { RetrieveDashboardTableauResponse } from 'services/api/dashboard/dashboardServiceTypes';
import actionCreator from 'store/dashboard/dashboardActionCreator';

const ACTION_NAME = 'RETRIEVE_DASHBOARD_TABLEAU';
export const retrieveDashboardTableauAsync = actionCreator.async<
	null,
	RetrieveDashboardTableauResponse,
	Error
>(ACTION_NAME);

export const retrieveDashboardTableau = actionCreator(ACTION_NAME);
