import actionCreator from '../financeActionCreator';
import { DownloadSingleDARequest } from 'services/api/finance/financeServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

const ACTION_NAME = 'DOWNLOAD_SINGLE_DA';

export const downloadSingleDAAsync = actionCreator.async<
	DownloadSingleDARequest,
	RetrieveDownloadDocumentResponse,
	Error
>(ACTION_NAME);

export const downloadSingleDA = actionCreator<DownloadSingleDARequest>(
	ACTION_NAME
);
