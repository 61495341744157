import Api from 'services/api';

import { AddOrDeleteGroupUserRequest } from 'services/api/groups/groupsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { deleteThirdPartyLPAGroupUserAsync } from '../actions/deleteThirdPartyLPAGroupUser';

export default makeTakeLatestWatcher<AddOrDeleteGroupUserRequest, null, Error>({
	api: Api.Groups.deleteThirdPartyGroupUser,
	async: deleteThirdPartyLPAGroupUserAsync
});
