import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getValues } from 'store/form/selectors';
import { CompanyTemplateFile } from 'services/api/templates/templatesServiceTypes';
import {
	CONFIGURATION_FILE_FORM_NAME,
	ConfigurationFileFormData
} from '../configurationFilesTypes';
import {
	prepareFormValuesForEdit,
	prepareFormValuesForSubmit
} from './selectorsUtils';

const getConfigurationFilesAllIds = (state: AppState) =>
	state.configurationFiles.allIds;

const getConfigurationFilesMap = (state: AppState) =>
	state.configurationFiles.byId;

export const getConfigurationFilesContextIsNew = (state: AppState) =>
	state.configurationFiles.context.isNew;

export const getRetrieveConfigurationFilesFetchStatus = (state: AppState) =>
	state.configurationFiles.fetchStatuses.retrieveCompanyFiles;

const getConfigurationFileById = (state: AppState, fileId: string) =>
	getConfigurationFilesMap(state)[fileId];

export const getConfigurationFiles = createSelector(
	getConfigurationFilesAllIds,
	getConfigurationFilesMap,
	(allIds, filesMap): CompanyTemplateFile[] => allIds.map(id => filesMap[id])
);

const getConfigurationFileFormValues = (state: AppState) =>
	getValues<ConfigurationFileFormData>(state, CONFIGURATION_FILE_FORM_NAME);

export const getConfigurationFileFormValuesForSubmit = (state: AppState) =>
	prepareFormValuesForSubmit(getConfigurationFileFormValues(state));

export const getConfigurationFileFormDataForInit = (
	state: AppState,
	fileId: string
): Partial<ConfigurationFileFormData> => {
	const file = getConfigurationFileById(state, fileId);
	return prepareFormValuesForEdit({
		principals: file.principals.length ? file.principals.map(v => v.id) : [],
		ports: file.ports.length
			? file.ports.map(v => ({ key: v.id, label: `${v.name} (${v.code})` }))
			: [],
		mainCommodities: file.mainCommodities,
		messageTypes: file.messageTypes,
		documentId: file.documentId,
		fileName: file.fileName,
		userName: file.userName,
		createdOnUtc: file.createdOnUtc,
		id: fileId
	});
};
