import Api from 'services/api';
import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { PageFormProps } from './Form';
import { get } from 'lodash';
import { Dispatch } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const asyncValidate = (
	values: Page1FormValues,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	_: Dispatch<any>,
	{ isHubPrincipalFieldVisible }: PageFormProps
) => {
	const { portCall, hubPrincipalCompany, jobTypeId } = values;
	if (
		portCall &&
		(!hubPrincipalCompany || !portCall.vessel || !portCall.port)
	) {
		return Promise.resolve();
	}
	const ApiCall = Api.PortJobs.checkPortJobPermissions;
	return ApiCall({
		mainPrincipalId: get(hubPrincipalCompany, 'key', ''),
		vesselId: portCall.vessel.key,
		portId: portCall.port.key
	}).then(({ data: { hasVesselMapping, hasPermission } }) => {
		let error;
		if (!hasVesselMapping && jobTypeId?.key) {
			// if the field is initialized and there is no possibility to change it from UI
			error = !isHubPrincipalFieldVisible
				? `You can't create a job as port call's vessel is not mapped to your company`
				: `You can't create a job for this Hub principal as port call's vessel is not mapped to it`;
		}

		if (!hasPermission && isHubPrincipalFieldVisible) {
			error = `You cannot create a job for this Hub principal`;
		}
		if (error) {
			/* eslint-disable  no-throw-literal */
			throw { [FormFieldName.HUB_PRINCIPAL_COMPANY]: error };
		}
	});
};

export default asyncValidate;
