import { retrieveCompanyGeneralConfigurationReportsAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse,
	Error
>({
	api: Api.Companies.retrieveCompanyGeneralConfigurationReports,
	async
});
