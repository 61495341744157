import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveSDAReasonAsync } from '../actions/retrieveSDAReason';
import {
	RetrieveSDAReasonRequest,
	SDAReasons
} from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';

export default makeTakeEveryWatcher<
	RetrieveSDAReasonRequest,
	SDAReasons[],
	Error
>({
	api: Api.Finance.retrieveSDAReason,
	async: retrieveSDAReasonAsync
});
