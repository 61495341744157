import React from 'react';
import { useDispatch } from 'react-redux';
import { SupplierInvoiceActionCode } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import {
	getEntityMetadataAction,
	getIsActionAllowed
} from 'store/metadata/utils/metadataUtils';
import { PageHeader } from 'components';
import EditSupplierInvoice from './EditSupplierInvoice/EditSupplierInvoice';
import ViewSupplierInvoice from './ViewSupplierInvoice/ViewSupplierInvoice';
import SupplierInvoiceLayout from '../../components/SupplierInvoiceLayout/SupplierInvoiceLayout';
import { useActiveSupplierInvoice } from 'sections/SupplierInvoice/SupplierInvoice.hooks';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { setIsSupplierInvoiceContextualization } from 'store/supplierInvoice/actions';
import DocumentViewerContainer from 'sections/DocumentViewer/DocumentViewerContainer';

interface EditOrViewSupplierInvoiceRouteProps
	extends PortCallTabBaseProps<{
		supplierInvoiceId: string;
		documentId?: string;
	}> {}

function EditOrViewSupplierInvoiceRoute(
	props: EditOrViewSupplierInvoiceRouteProps
) {
	const { supplierInvoiceId } = props.match.params;
	const { activeSupplierInvoice, documentId } = useActiveSupplierInvoice(
		supplierInvoiceId,
		true
	);
	let isEditable = false;
	let { match } = props;
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(
			setIsSupplierInvoiceContextualization({
				isSupplierInvoiceContextualizing: true
			})
		);
		return () => {
			dispatch(
				setIsSupplierInvoiceContextualization({
					isSupplierInvoiceContextualizing: false
				})
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (activeSupplierInvoice) {
		const action = getEntityMetadataAction<SupplierInvoiceActionCode>(
			activeSupplierInvoice.metadata.actions,
			SupplierInvoiceActionCode.EDIT
		);

		isEditable = getIsActionAllowed<SupplierInvoiceActionCode>(action);
	}
	if (documentId) {
		match = {
			...match,
			params: {
				...match.params,
				documentId
			}
		};
	}

	const header = !isEditable ? (
		<PageHeader.NewTab>Supplier invoice</PageHeader.NewTab>
	) : null;
	return (
		<SupplierInvoiceLayout
			{...props}
			layoutProps={{ direction: 'horizontal' }}
			header={header}
		>
			{isEditable ? (
				<DocumentViewerContainer match={match} withPortCallContainer={false}>
					<EditSupplierInvoice supplierInvoiceId={supplierInvoiceId} />
				</DocumentViewerContainer>
			) : (
				<ViewSupplierInvoice supplierInvoiceId={supplierInvoiceId} />
			)}
		</SupplierInvoiceLayout>
	);
}

export default EditOrViewSupplierInvoiceRoute;
