import React, { FC } from 'react';
import { isNumber } from 'lodash';
import {
	DEFAULT_NOT_AVAILABLE_PLACEHOLDER,
	DEFAULT_NO_VALUE_PLACEHOLDER,
	DEFAULT_NOT_PROVIDED_PLACEHOLDER
} from 'app-constants';

export enum OptionalValueModes {
	NO_VALUE = 'no-value',
	NOT_AVAILABLE = 'not-available',
	NOT_PROVIDED = 'not-provided'
}

interface OptionalValueProps {
	value: React.ReactNode;
	mode?: OptionalValueModes;
}

const DefaultValue = {
	[OptionalValueModes.NO_VALUE]: DEFAULT_NO_VALUE_PLACEHOLDER,
	[OptionalValueModes.NOT_AVAILABLE]: DEFAULT_NOT_AVAILABLE_PLACEHOLDER,
	[OptionalValueModes.NOT_PROVIDED]: DEFAULT_NOT_PROVIDED_PLACEHOLDER
};

const OptionalValue: FC<OptionalValueProps> = ({
	value,
	mode = OptionalValueModes.NO_VALUE,
	children
}) => {
	const innerValue = isNumber(value) ? String(value) : value;

	return <>{(innerValue && children) || innerValue || DefaultValue[mode]}</>;
};

export default OptionalValue;
