import actionCreator from '../portJobsActionCreator';
import {
	UnlinkJobsParams,
	UnlinkJobsRequest
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'UNLINK_JOBS';

export const unlinkJobsAsync = actionCreator.async<
	UnlinkJobsRequest,
	void,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	}
});

export const unlinkJobs = actionCreator<UnlinkJobsParams>(ACTION_NAME);
