import React from 'react';
import { Select } from 'components/antd';
import styles from './EnableOperationsForm.module.scss';
import Api from 'services/api';
import {
	FinanceMetadataActionCode,
	ServiceSectionCode,
	ServiceTemplate
} from 'services/api/finance/financeServiceTypes';
import { useSelector } from 'react-redux';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

interface ServiceSelectProps {
	index: number;
	name: string;
	entityId: string;
}

export const ServiceSelect = ({
	index,
	name,
	entityId
}: ServiceSelectProps) => {
	const [availableServices, setAvailableServices] = React.useState<
		ServiceTemplate[]
	>();
	const portCallId = useSelector(getActivePortCallId);
	const jobCode = useSelector(getActivePortJobCode);

	React.useEffect(() => {
		setAvailableServices(undefined);
		Api.Finance.retrieveAvailableServices({
			portCallId,
			jobCode,
			sectionCode: ServiceSectionCode.VESSEL_PROGRAMME,
			entityId: entityId,
			action: FinanceMetadataActionCode.MOVE_SERVICE,
			isOperationsEnabledRequested: false
		}).then(response => {
			setAvailableServices(response.data.elements);
		});
	}, [entityId, jobCode, portCallId]);
	return (
		<Select.ReduxFormItem
			key={index}
			name={name}
			disabled={false}
			labelInValue
			placeholder="Select Service"
			dropdownClassName={styles.serviceSectionDropDownSelect}
			required
			getPopupContainer={() => document.body}
		>
			{availableServices?.map(section => (
				<Select.Option key={section.code} title={section.name}>
					{section.name}
				</Select.Option>
			))}
		</Select.ReduxFormItem>
	);
};
