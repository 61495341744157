import { all, fork } from 'redux-saga/effects';
import {
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import {
	retrieveBankAccountsAsync,
	retrieveBankAccountsSectionAsync
} from './../actions/retrieveBankAccounts';
import Api from 'services/api';

const retrieveBankAccountsWatcher = makeTakeLatestWatcher<
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse,
	Error
>({
	async: retrieveBankAccountsAsync,
	api: Api.BankAccounts.retrieveBankAccounts
});

const retrieveBankAccountsSectionWatcher = makeTakeLatestWatcher<
	RetrieveBankAccountsRequest,
	RetrieveBankAccountsResponse,
	Error
>({
	async: retrieveBankAccountsSectionAsync,
	api: Api.BankAccounts.retrieveBankAccounts
});

export default function*() {
	yield all([
		fork(retrieveBankAccountsWatcher),
		fork(retrieveBankAccountsSectionWatcher)
	]);
}
