import Api from 'services/api';
import { retrieveAvailableDasAsync as asyncAction } from '../actions/retrieveAvailableDas';
import {
	RetrieveAvailableDasRequest,
	DaAvailabilityItem,
	DaType
} from 'services/api/finance/financeServiceTypes';

import { select } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { ResultCollection } from 'services/api/apiTypes';

const api = Api.Finance.retrieveAvailableDas;

export default makeTakeLatestWatcher<
	RetrieveAvailableDasRequest,
	ResultCollection<DaAvailabilityItem>,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams(params: { jobServiceId: string; daType: DaType }) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request = {
			portCallId,
			jobCode,
			...params
		};
		return request;
	}
});
