import { retrieveAllowedDocumentsAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse,
	Error
>({
	api: Api.PortCallDocuments.retrieveAllowedDocuments,
	async: retrieveAllowedDocumentsAsync
});
