import actionCreator from '../dashboardActionCreator';
import { AlertMetricCode } from 'app-constants';

export interface VesselProgrammeDashboardAlertResolvingStartPayload {
	portCallId: string;
	alertId: string;
	alertCode: AlertMetricCode;
}

export const startVPAlertResolving = actionCreator<
	VesselProgrammeDashboardAlertResolvingStartPayload
>('VESSEL_PROGRAMME_ALERT_RESOLVE_START');
export const redirectToVP = actionCreator('VESSEL_PROGRAMME_ALERT_REDIRECT');
