import actionCreator from '../portCallOperationsActionCreator';
import { DeletePortCallEventRequest } from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'DELETE_PORTCALL_EVENT';

export interface DeletePortCallEventActionsPayload {
	form?: string;
	requestParams: Pick<DeletePortCallEventRequest, 'eventId' | 'comment'>;
}

export const deletePortCallEventAsync = actionCreator.async<
	DeletePortCallEventActionsPayload,
	undefined,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Event'
	},
	behaviour: {
		type: 'submit'
	}
});

export const deletePortCallEvent = actionCreator<
	DeletePortCallEventActionsPayload
>(ACTION_NAME);

export const startDeletePortCallEvent = actionCreator<
	DeletePortCallEventActionsPayload
>('START_DELETE_PORTCALL_EVENT');

export const endDeletePortCallEvent = actionCreator<DeletePortCallEventRequest>(
	'END_DELETE_PORTCALL_EVENT'
);

export const setPortCallEventAsNotHappened = actionCreator<
	DeletePortCallEventRequest
>('SET_EVENT_AS_NOT_HAPPENED');

export const removePortCallEventFromList = actionCreator<
	DeletePortCallEventRequest
>('REMOVE_EVENT_FROM_LIST');
