import { FetchStatus } from 'services/api/apiTypes';
import { MessagesState } from '../messagesState';
import { downloadEmlFile, downloadEmlFileAsync } from '../actions';

function downloadEmlFileStarted(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadingEmlFile: FetchStatus.PENDING
		}
	};
}

function downloadEmlFileSuccess(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadingEmlFile: FetchStatus.SUCCESS
		}
	};
}

function downloadEmlFileFailed(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadingEmlFile: FetchStatus.FAILURE
		}
	};
}

export const downloadEmlFileAction = {
	action: downloadEmlFile,
	async: downloadEmlFileAsync,
	started: downloadEmlFileStarted,
	success: downloadEmlFileSuccess,
	failed: downloadEmlFileFailed
};
