import { NotificationDisplayType } from 'store/notifications/notificationsState';

import { notify, alert, fullPage } from 'store/notifications/actions';
import { getNotificationDisplayTypeConfigByActionType } from 'store/notifications/selectors';
import { getNotification } from './notificationHandlerUtils';
import {
	HandleApiArg,
	ApiMiddlewareHandleType
} from '../../apiMiddlewareTypes';
import { createApiMiddlewareTypeHandler } from '../../apiMiddlewareUtils';

const handler = createApiMiddlewareTypeHandler(
	ApiMiddlewareHandleType.NOTIFICATION,
	notificationHandler
);

function notificationHandler({ action, api: { getState } }: HandleApiArg) {
	const state = getState();
	const notificationDisplayTypeDynamic = getNotificationDisplayTypeConfigByActionType(
		state,
		action.type
	);

	const notification = getNotification(
		action,
		Boolean(notificationDisplayTypeDynamic)
	);
	if (!notification) {
		return;
	}

	const {
		displayType: notificationDisplayType,
		type,
		...payload
	} = notification;

	const displayType = notificationDisplayTypeDynamic || notificationDisplayType;

	let displayTypeAction = fullPage;

	switch (displayType) {
		case NotificationDisplayType.ALERT:
			displayTypeAction = alert[type];
			break;

		case NotificationDisplayType.NOTIFICATION:
			displayTypeAction = notify[type];
			break;

		case NotificationDisplayType.FULL_PAGE:
			displayTypeAction = fullPage;
			break;
	}

	return displayTypeAction(payload);
}

export default handler;
