import React from 'react';
import { connect } from 'react-redux';
import { PageFooter, Flex, ButtonRow, Gap } from 'components';
import { Button } from 'components/antd';
import { Justify } from 'components/types';
import {
	updateFundingRequest,
	createFundingRequest
} from 'store/financeFundings/actions';
import { getIsFundingRequestUpdating } from 'store/financeFundings/selectors';
import {
	getCanRejectFunding,
	getCanApproveFunding,
	getIsRejectable,
	getIsApprovable,
	getApproveTooltip,
	getIsAllDaSetLocked,
	getIsStatusPendingReview
} from 'store/form/financeFundings/selectors/financeFundingFormSelectors';
import { NEW_FUNDING_ID } from 'store/financeFundings/financeFundingsConstants';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';
import { AppState } from 'store-types';
import { FundingRequestMetadataActionCode } from 'services/api/financeFundings/financeFundingsServiceTypes';

interface FundingRequestFooterProps {
	fundingId: string;
	canReject: boolean;
	isRejectable: boolean;
	approveTooltip: TooltipProps;
	canApprove: boolean;
	isApprovable: boolean;
	isLoading: boolean;
	isAllDaSetLocked: boolean;
	isStatusPendingReview: boolean;
	updateFunding: typeof updateFundingRequest;
	createFunding: typeof createFundingRequest;
	onCancel: () => void;
}

class FundingRequestFooter extends React.Component<FundingRequestFooterProps> {
	getFundingRequestParams = (action: FundingRequestMetadataActionCode) => ({
		fundingId: this.props.fundingId,
		action
	});

	reject = () => {
		if (this.props.fundingId !== NEW_FUNDING_ID) {
			this.props.updateFunding(
				this.getFundingRequestParams(FundingRequestMetadataActionCode.REJECT)
			);
		} else {
			this.props.createFunding(
				this.getFundingRequestParams(FundingRequestMetadataActionCode.REJECT)
			);
		}
	};

	approve = () => {
		if (this.props.fundingId !== NEW_FUNDING_ID) {
			this.props.updateFunding(
				this.getFundingRequestParams(FundingRequestMetadataActionCode.APPROVE)
			);
		} else {
			this.props.createFunding(
				this.getFundingRequestParams(FundingRequestMetadataActionCode.APPROVE)
			);
		}
	};

	render() {
		const {
			isLoading,
			canApprove,
			canReject,
			isAllDaSetLocked,
			isStatusPendingReview,
			approveTooltip,
			isRejectable
		} = this.props;
		let rejectTooltip;
		if (isRejectable && !canReject) {
			rejectTooltip = {
				title: 'In order to reject the request please provide a comment'
			};
		}
		return (
			<PageFooter>
				<Gap isBlock right="md" left="md">
					<Flex justify={Justify.END} stretch>
						<ButtonRow>
							<Button type="ghost" onClick={this.props.onCancel}>
								Cancel
							</Button>
							<Button
								type="ghost"
								disabled={!canReject}
								tooltip={rejectTooltip}
								loading={isLoading}
								onClick={this.reject}
							>
								Reject
							</Button>
							&nbsp;
							<Button
								type="primary"
								disabled={!canApprove || isAllDaSetLocked}
								tooltip={
									isAllDaSetLocked && isStatusPendingReview
										? {
												title:
													"You can't approve the request since there is no active DA set",
												placement: 'topLeft'
										  }
										: approveTooltip
								}
								loading={isLoading}
								onClick={this.approve}
							>
								Approve
							</Button>
						</ButtonRow>
					</Flex>
				</Gap>
			</PageFooter>
		);
	}
}

export default connect(
	(state: AppState, props: Pick<FundingRequestFooterProps, 'fundingId'>) => {
		const isRejectable = getIsRejectable(state, props.fundingId);
		const isApprovable = getIsApprovable(state, props.fundingId);
		const isAllDaSetLocked =
			props.fundingId !== NEW_FUNDING_ID &&
			getIsAllDaSetLocked(state, props.fundingId);
		const isStatusPendingReview = getIsStatusPendingReview(
			state,
			props.fundingId
		);
		return {
			isLoading: getIsFundingRequestUpdating(state),
			canReject: getCanRejectFunding(state, isRejectable),
			canApprove: getCanApproveFunding(state, isApprovable, props.fundingId),
			approveTooltip: getApproveTooltip(state, isApprovable, props.fundingId),
			isRejectable,
			isApprovable,
			isAllDaSetLocked,
			isStatusPendingReview
		};
	},
	{
		updateFunding: updateFundingRequest,
		createFunding: createFundingRequest
	}
)(FundingRequestFooter);
