import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallsAlertsRequest,
	RetrievePortCallsAlertsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { retrievePortCallsAlertsAsync as async } from '../actions';

const api = Api.PortCalls.retrievePortCallsAlerts;

export const retrievePortCallsAlertsWatcher = makeTakeLatestWatcher<
	RetrievePortCallsAlertsRequest,
	RetrievePortCallsAlertsResponse,
	Error
>({ api, async });
