import React, { FC, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Content } from 'components';
import { Collapse } from 'components/antd';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import UserDetailsFormsValidation from './UserDetailsFormsValidation';
import UserDetailsPreferences from './UserDetailsPreferences';
import UserDateTimePreferences from './UserDateTimePreferences';
import { UserDetailsFormData } from 'services/api/users/userServiceTypes';
import { getTimezonesOptionsSelector } from 'store/registration/selectors';
import { USER_PROFILE_FORM } from 'store/users/usersConstants';
import { getUserDetailsInitialValues } from 'sections/User/EditUser/userDetailsSelectors';
import { AppState } from 'store-types';

interface UserDetailsFormProps {
	isProfilePage?: boolean;
	isInternalUser?: boolean;
	onSubmit: (formValues: UserDetailsFormData) => void;
}

const UserDetailsForm: FC<UserDetailsFormProps> = ({
	isProfilePage,
	isInternalUser,
	onSubmit
}) => {
	const initialValues = useSelector(getUserDetailsInitialValues, shallowEqual);
	const timezonesOptions = useSelector<
		AppState,
		ReturnType<typeof getTimezonesOptionsSelector>
	>(getTimezonesOptionsSelector, shallowEqual);

	const defaultActiveKeys = useMemo(() => ['1', '2'], []);

	return (
		<ReduxForm
			form={USER_PROFILE_FORM}
			initialValues={initialValues}
			enableReinitialize
			validate={UserDetailsFormsValidation}
			onSubmit={onSubmit}
		>
			<Collapse defaultActiveKey={defaultActiveKeys} type="primary">
				<Collapse.Panel header="User Details" key="1">
					<Content>
						<UserDetailsPreferences
							email={initialValues.email}
							companyName={initialValues.companyName}
							isProfilePage={isProfilePage}
							isInternalUser={isInternalUser}
						/>
					</Content>
				</Collapse.Panel>
				<Collapse.Panel header="Date/Time Preferences" key="2">
					<Content>
						<UserDateTimePreferences
							dateFormat={initialValues.dateFormat}
							timeFormat={initialValues.timeFormat}
							timezonesOptions={timezonesOptions}
						/>
					</Content>
				</Collapse.Panel>
			</Collapse>
		</ReduxForm>
	);
};

export default UserDetailsForm;
