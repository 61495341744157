import actionCreator from 'store/finance/financeActionCreator';
import {
	CreateNewFundingRequestRequest as Request,
	CreateNewFundingRequestResponse as Response,
	FundingRequestMetadataActionCode
} from 'services/api/financeFundings/financeFundingsServiceTypes';

const ACTION_NAME = 'CREATE_FUNDING_REQUEST';

export const createFundingRequestAsync = actionCreator.async<
	Request,
	Response,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Funding'
	}
});

export const createFundingRequest = actionCreator<{
	action: FundingRequestMetadataActionCode;
}>(ACTION_NAME);
