import { retrieveGroupScopePermissionsAsync } from 'store/groups/actions/retrieveGroupScopePermissions';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { ResultCollection } from 'services/api/apiTypes';
import {
	RetrieveGroupScopePermissionsRequest,
	GroupScopePermissions
} from 'services/api/groups/groupsServiceTypes';

const apiCall = Api.Groups.retrieveGroupScopePermissions;
const async = retrieveGroupScopePermissionsAsync;

const commonExecutor = makeDefaultExecutor<
	RetrieveGroupScopePermissionsRequest,
	ResultCollection<GroupScopePermissions>,
	Error
>({
	api: apiCall,
	async
});

export const retrieveGroupScopePermissionsWatcher = makeTakeLatestWatcher<
	RetrieveGroupScopePermissionsRequest,
	ResultCollection<GroupScopePermissions>,
	Error
>(
	{
		api: apiCall,
		async
	},
	commonExecutor
);
