import { all, fork } from 'redux-saga/effects';
import retrieveDirectBillsWatcher from './retrieveDirectBillsSaga';
import updateDirectBillWatcher from './updateDirectBillSaga';
import updateDirectBillStatusWatcher from './updateDirectBillStatusSaga';
import uploadDirectBillsDocumentationDoneWatcher from './uploadDirectBillsDocumentationDoneSaga';
import downloadDirectBillsDocumentsWatcher from './downloadDirectBillsDocumentsSaga';
import retrieveFullDirectBillWatcher from './retrieveFullDirectBillSaga';

export default function*() {
	yield all([
		fork(retrieveDirectBillsWatcher),
		fork(updateDirectBillWatcher),
		fork(updateDirectBillStatusWatcher),
		fork(uploadDirectBillsDocumentationDoneWatcher),
		fork(downloadDirectBillsDocumentsWatcher),
		fork(retrieveFullDirectBillWatcher)
	]);
}
