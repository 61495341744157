import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'components/antd';
import {
	OPTION_UNASSIGNED,
	getThreadNewPermissionsSelector
} from 'store/thread/selectors';
import {
	retrieveUsersFromGroup,
	retrieveAvailableThreadGroups,
	patchThreadPermissions
} from 'store/thread';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import { ReduxForm } from 'components';
import AddGroupFields from './AddGroupFields';
import AssignGroupFields from './AssignGroupFields';
import { useAssignGroupForm } from './AssignGroupModal.hooks';
import {
	ThreadGroupAssignmentFormData,
	THREAD_GROUP_ASSIGNMENT_FORM_ID
} from './AssignGroupModalTypes';

export interface AssignGroupModalFormProps {
	className?: string;
	threadId: string;
	assignedTo: {
		group?: {
			id: string;
			name: string;
		};
		user?: {
			id: string;
			name: string;
		};
	};
	hideModal: () => void;
	modalSubmitting: boolean;
}

const turnUnassignedToNull = (val: string) =>
	val === OPTION_UNASSIGNED ? null : val;

const AssignGroupModalForm: FC<AssignGroupModalFormProps> = ({
	hideModal,
	modalSubmitting,
	threadId,
	assignedTo
}) => {
	const dispatch = useDispatch();
	const threadPermissionGroupsInitialValues = useSelector(
		getThreadNewPermissionsSelector
	);
	const {
		threadPermissionGroupsWithUsersInForm,
		formValues
	} = useAssignGroupForm();

	useEffect(() => {
		threadPermissionGroupsInitialValues.forEach(
			(group: PermissionGroupWithUsers) =>
				dispatch(retrieveUsersFromGroup({ id: group.id }))
		);
		dispatch(retrieveAvailableThreadGroups({ threadId }));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onCancel = useCallback(() => {
		hideModal();
	}, [hideModal]);

	const onSubmit = useCallback(() => {
		const { assignedGroupId = '', assignedUserId = '' } = formValues || {};
		dispatch(
			patchThreadPermissions({
				threadId,
				assignedToGroupId: assignedGroupId,
				assignedToUserId: turnUnassignedToNull(assignedUserId),
				threadPermissionGroups: threadPermissionGroupsWithUsersInForm.map(
					({ id }) => id
				)
			})
		);
	}, [threadId, formValues, threadPermissionGroupsWithUsersInForm, dispatch]);

	const initialValues = useMemo(
		(): ThreadGroupAssignmentFormData => ({
			assignedGroupId: assignedTo.group && assignedTo.group.id,
			assignedUserId: assignedTo.user && assignedTo.user.id,
			threadPermissionGroups: threadPermissionGroupsInitialValues
		}),
		[assignedTo, threadPermissionGroupsInitialValues]
	);

	return (
		<Modal
			visible
			onCancel={onCancel}
			width={650}
			title="Edit Assignment"
			okButton={
				<Button onClick={onSubmit} type="primary" loading={modalSubmitting}>
					Submit
				</Button>
			}
		>
			<ReduxForm
				form={THREAD_GROUP_ASSIGNMENT_FORM_ID}
				initialValues={initialValues}
			>
				<AssignGroupFields />
				<hr />
				<AddGroupFields />
			</ReduxForm>
		</Modal>
	);
};

export default AssignGroupModalForm;
