export const copyTextToClipboard = async (text: string) => {
	if (navigator.clipboard) {
		// using Clipboard API if it's available
		await navigator.clipboard.writeText(text);
	} else {
		// fallback way of copying to clipboard
		const input = document.createElement('textarea');
		input.style.position = 'fixed';
		input.style.height = '0';
		input.style.overflow = 'hidden';
		input.value = text;
		document.body.appendChild(input);
		input.focus();
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);
	}
};
