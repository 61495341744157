import actionCreator from 'store/portCall/portCallActionCreator';

export interface RetrieveEditPortCallActionPayload {
	portCallId: string;
}

const RETRIEVE_EDITPORTCALL_DATA = 'RETRIEVE_EDITPORTCALL_DATA';
export const retrieveEditPortCallDataAsync = actionCreator.async<
	RetrieveEditPortCallActionPayload,
	undefined,
	Error
>(RETRIEVE_EDITPORTCALL_DATA);
export const retrieveEditPortCallData = actionCreator<
	RetrieveEditPortCallActionPayload
>(RETRIEVE_EDITPORTCALL_DATA);
