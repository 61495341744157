import { UserAuth } from 'services/api/users/userServiceTypes';
import actionCreator from '../authActionCreator';

const ACTION_NAME = 'RETRIEVE_AUTH_USER';

export const retrieveAuthUserAsync = actionCreator.async<null, UserAuth, Error>(
	ACTION_NAME
);

export const retrieveAuthUser = actionCreator(ACTION_NAME);
