import { Action } from 'typescript-fsa';

import {
	takeLatest,
	fork,
	take,
	race,
	cancel,
	put,
	select,
	delay,
	all
} from 'redux-saga/effects';

import { getAuthUserName } from 'store/auth/selectors';
import {
	uploadAndProcessDocumentAsync,
	cancelUploadAndProcessDocument,
	uploadDocumentAsync,
	processDocument,
	processDocumentAsync,
	abortProcessDocument
} from '../actions';

import {
	getUploadState,
	getIsDocumentProcessingDoneWithSuccess
} from '../selectors';

import {
	UploadDocumentRequest,
	ValidationStatus
} from 'services/api/documents/documentsServiceTypes';

import { worker as uploadDocumentWorker } from './uploadDocumentSaga';
import { notify } from 'store/notifications/actions';

const worker = function*(action: Action<UploadDocumentRequest>) {
	yield put(uploadAndProcessDocumentAsync.started(action.payload));

	// Start upload request
	const task = yield fork(uploadDocumentWorker, action);

	// Wait for the first action to happen
	const { done, failed, cancelled } = yield race({
		done: take(uploadDocumentAsync.done),
		failed: take(uploadDocumentAsync.failed),
		cancelled: take(cancelUploadAndProcessDocument.type)
	});

	if (done) {
		const {
			payload: { result }
		} = done;
		const metadata = action.payload.metadata;
		const uploadState = yield select(getUploadState);
		const createdByUserName = yield select(getAuthUserName);

		yield put(
			processDocument({
				...result,
				splitPdf: uploadState.info.hasSplit,
				metadata
			})
		);

		// Wait when  processing is finished
		const { payload } = yield take(processDocumentAsync.done);

		yield delay(1000);
		if (action.payload.onDone && payload.result.processed) {
			const isDoneSuccess = yield select(
				getIsDocumentProcessingDoneWithSuccess
			);
			const onDoneAction = action.payload.onDone({
				document: {
					...payload.result,
					createdByUserName
				},
				contextualizePdf: metadata?.contextualizePdf,
				metadata: metadata,
				isDoneSuccess
			});
			// Due to redux lib versioning issues, additional check has been added
			if (!onDoneAction) {
				throw new Error(
					`'onDone' must return action in 'uploadAndProcessSaga'`
				);
			}
			yield put(onDoneAction);
		}
		if (
			!payload.result.processed &&
			payload.result.status === ValidationStatus.QUARANTINE &&
			!action.payload.showProgress
		) {
			yield put(notify.error('File upload failed - virus detected'));
		}

		if (
			!payload.result.processed &&
			payload.result.status &&
			payload.result.status !== ValidationStatus.QUARANTINE &&
			!action.payload.showProgress
		) {
			yield put(notify.error('File upload failed. Please try again'));
		}
	}

	if (failed) {
		const {
			payload: { params, error }
		} = failed;
		yield put(
			uploadAndProcessDocumentAsync.failed({
				params,
				error
			})
		);
	}

	if (cancelled) {
		yield cancel(task);
	}
};

const uploadAndProcessDocumentAsyncWorker = function*() {
	yield take(cancelUploadAndProcessDocument.type);

	// Abort `processDocument` task
	yield put(abortProcessDocument());
};

export default function*() {
	yield all([
		takeLatest(uploadAndProcessDocumentAsync.type, worker),
		takeLatest(
			uploadAndProcessDocumentAsync.type,
			uploadAndProcessDocumentAsyncWorker
		)
	]);
}
