import {
	FinanceServiceQueries,
	RetrieveServiceQueriesRequest
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_SERVICE_QUERIES = 'RETRIEVE_SERVICE_QUERIES';
export const retrieveServiceQueriesAsync = actionCreator.async<
	RetrieveServiceQueriesRequest,
	FinanceServiceQueries,
	Error
>(RETRIEVE_SERVICE_QUERIES);

export const retrieveServiceQueries = actionCreator<{ jobServiceId: string }>(
	RETRIEVE_SERVICE_QUERIES
);

const RESET_SERVICE_QUERIES = 'RESET_SERVICE_QUERIES';
export const resetServiceQueries = actionCreator<string>(RESET_SERVICE_QUERIES);
