import {
	VesselProgrammeState,
	CustodyLineRotationStep
} from 'store/vesselProgramme/vesselProgrammeState';

export const getPreviousAllocatedRotationStep = (
	operationUnitsById: VesselProgrammeState['operationUnitsById'],
	draggedOperationUnitId: VesselProgrammeState['draggedOperationUnitId'],
	rotationStepsById: VesselProgrammeState['rotationStepsById']
) => {
	const previousAllocatedRotationStepId =
		operationUnitsById[draggedOperationUnitId]?.allocatedId;
	const previousRotationStep = previousAllocatedRotationStepId
		? {
				...rotationStepsById[previousAllocatedRotationStepId],
				units:
					rotationStepsById[previousAllocatedRotationStepId]?.units.filter(
						unitId => unitId !== draggedOperationUnitId
					) || []
		  }
		: undefined;
	return previousRotationStep
		? { [previousAllocatedRotationStepId]: previousRotationStep }
		: {};
};

export const getOriginalOperationUnit = (
	operationUnitsById: VesselProgrammeState['operationUnitsById'],
	draggedOperationUnitId: VesselProgrammeState['draggedOperationUnitId']
) => operationUnitsById[draggedOperationUnitId];

export const getOperationUnitAfterAllocation = (
	operationUnitsById: VesselProgrammeState['operationUnitsById'],
	draggedOperationUnitId: VesselProgrammeState['draggedOperationUnitId'],
	rotationStepId: string,
	custodyLineRotationStep?: CustodyLineRotationStep
) => {
	const allocatedUnitAgentMode =
		custodyLineRotationStep?.agentMode ||
		operationUnitsById[draggedOperationUnitId]?.job.agentRole;
	const previousUnit = getOriginalOperationUnit(
		operationUnitsById,
		draggedOperationUnitId
	);
	return {
		...previousUnit,
		allocatedId: rotationStepId,
		job: {
			...previousUnit?.job,
			agentRole: allocatedUnitAgentMode
		}
	};
};

export const getEventsAfterAllocation = (
	operationUnitsById: VesselProgrammeState['operationUnitsById'],
	draggedOperationUnitId: VesselProgrammeState['draggedOperationUnitId'],
	eventsById: VesselProgrammeState['eventsById']
) => {
	const updatedEvents = Object.keys(eventsById)
		.filter(id =>
			getOriginalOperationUnit(
				operationUnitsById,
				draggedOperationUnitId
			)?.events?.includes(id)
		)
		.map(id => eventsById[id])
		.map(event => ({
			...event,
			estimatedDatePlt: null,
			realDatePlt: null
		}))
		.reduce(
			(all, current) => ({
				...all,
				[current.id]: current
			}),
			{}
		);
	return {
		...eventsById,
		...updatedEvents
	};
};
