import { select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { setDefaultMessagingGroupAsync } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getPortJobConcurrencyTokenByCode
} from 'store/portJobs/selectors';

export default makeTakeEveryWatcher<
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse,
	Error
>({
	api: Api.PortJobs.setDefaultMessagingGroup,
	async: setDefaultMessagingGroupAsync,
	*getApiParams(
		params: Pick<SetDefaultMessagingGroupRequest, 'group' | 'portJobId'>
	) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const concurrencyToken = yield select(
			getPortJobConcurrencyTokenByCode,
			jobCode
		);

		return {
			...params,
			portCallId,
			jobCode,
			concurrencyToken
		};
	}
});
