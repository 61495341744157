import React from 'react';
import { connect } from 'react-redux';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import {
	Message,
	MessageAction
} from 'services/api/messages/messagesServiceTypes';
import { ComposeMessageFrom } from './ComposeMessageTypes';
import { getComposeMessageFromSelector } from './ComposeMessageSelector';
import {
	getComposeMessage,
	getRetrieveMessageDataFetchStatus,
	getActiveThread,
	getComposeMessagePrevPath
} from 'store/thread/selectors';
import { getPortCallId } from 'sections/PortCall/Layout/PortCallLayout.func';
import { PortCallTabBaseProps } from 'sections/PortCall';
import ComposeMessageWrapper from './ComposeMessageWrapper';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import {
	setPortCallContext,
	resetActivePortCallId
} from 'store/portcalls/actions';
import { resetThread, retrieveMessageData } from 'store/thread/actions';
import { getNewPortCallMessageBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import { BreadcrumbItem } from 'store/navigation';
import { DataFetcher } from 'utils/components';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

interface RouteParams {
	threadId: string;
	messageId?: string;
	action: MessageAction;
}

export interface IComposeMessageRouteProps
	extends PortCallTabBaseProps<RouteParams> {
	isNewTab: boolean;
	breadcrumbs: BreadcrumbItem[];
	message: Message | null;
	thread: ThreadItem;
	messageFrom: ComposeMessageFrom;
	retrieveMessageDataFetchStatus: FetchStatus;
	retrieveMessageData: typeof retrieveMessageData;
	resetThread: typeof resetThread;
	setPortCallContext: typeof setPortCallContext;
	resetActivePortCallId: typeof resetActivePortCallId;
	composeMessagePrevPath: string;
}

class ComposeMessagePortCallRoute extends React.Component<
	IComposeMessageRouteProps
> {
	private portCallId = getPortCallId(this.props.match);

	retrieveMessageData = () => {
		const params = this.props.match.params;
		this.props.setPortCallContext({
			activePortCallTabId: PortCallTabKey.MESSAGES,
			activePortCallId: this.portCallId
		});
		if (params.messageId && params.action) {
			this.props.retrieveMessageData({
				portCallId: this.portCallId,
				threadId: params.threadId,
				messageId: params.messageId
			});
		}
	};

	reset = () => {
		this.props.resetThread();
		this.props.resetActivePortCallId();
	};

	render() {
		const {
			thread,
			message,
			messageFrom,
			isNewTab,
			retrieveMessageDataFetchStatus,
			breadcrumbs,
			composeMessagePrevPath
		} = this.props;
		const params = this.props.match.params;

		return (
			<DataFetcher
				fetchStatus={retrieveMessageDataFetchStatus}
				dispatch={this.retrieveMessageData}
				reset={this.reset}
			>
				{() => (
					<ComposeMessageWrapper
						thread={thread}
						message={message}
						messageFrom={messageFrom}
						isNewTab={isNewTab}
						breadcrumbs={breadcrumbs}
						action={params.action}
						prevPath={composeMessagePrevPath}
						isPortCallMessage
					/>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			match: {
				params: { threadId, portJobCode, portCallId }
			}
		}: IComposeMessageRouteProps
	) => ({
		thread: getActiveThread(state),
		message: getComposeMessage(state),
		messageFrom: getComposeMessageFromSelector(state),
		retrieveMessageDataFetchStatus: getRetrieveMessageDataFetchStatus(state),
		breadcrumbs: getNewPortCallMessageBreadcrumbs(state),
		composeMessagePrevPath: getComposeMessagePrevPath(state, threadId, [
			portCallId,
			portJobCode
		])
	}),
	{
		retrieveMessageData,
		resetThread,
		setPortCallContext,
		resetActivePortCallId
	}
)(ComposeMessagePortCallRoute);
