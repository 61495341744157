import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	PortJobOperationTypes,
	RetrieveOperationParams
} from 'services/api/portJobs/portJobsServiceTypes';

import { FetchStatus } from 'services/api/apiTypes';

export const onRetrievePortJobOperationStarted = (
	state: PortJobState
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		operation: FetchStatus.PENDING
	}
});

export const onRetrievePortJobOperationSuccess = (
	state: PortJobState,
	action: Success<RetrieveOperationParams, PortJobOperationTypes>
): PortJobState => ({
	...state,
	editableOperation: action.result,
	fetchStatuses: {
		...state.fetchStatuses,
		operation: FetchStatus.SUCCESS
	}
});

export const onRetrievePortJobOperationFailed = (
	state: PortJobState
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		operation: FetchStatus.FAILURE
	}
});

export const onResetPortJobOperation = (state: PortJobState): PortJobState => ({
	...state,
	editableOperation: null,
	fetchStatuses: {
		...state.fetchStatuses,
		operation: FetchStatus.IDLE
	}
});
