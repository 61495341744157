import * as React from 'react';

const ContextualizationHint: React.SFC = () => {
	return (
		<>
			Process Supplier Invoice
			<p>
				Please fill Supplier Details section. After that you will be able to add
				invoice details
			</p>
		</>
	);
};

export default ContextualizationHint;
