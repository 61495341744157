import React from 'react';
import { CostSaving } from 'services/api/savings/savingsServiceTypes';
import { EntityCode } from 'app-types';
import { Amount, MultipleEntities } from 'components';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { Button, Icon, Popover, Tag, Tooltip } from 'components/antd';
import { Permission } from 'containers';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import SettingsMenu from '../Settings/SettingsMenu';
import { UserType } from 'services/api/users/userServiceTypes';

export const tagsRender = (tags: EntityCode<string>[]) => {
	if (tags.length > 3) {
		return <MultipleEntities entities={tags.map(tag => tag.name)} />;
	}
	return (
		<>
			{tags.map((tag, index) => (
				<Tag key={index}>{tag.name}</Tag>
			))}
		</>
	);
};

export const getColumns = (
	userType: UserType,
	isClosedJob?: boolean
): Array<ColumnProps<CostSaving>> => [
	{
		dataIndex: 'category',
		width: '18%',
		title: 'Category',
		render: (category: EntityCode<string>) => <span>{category.name}</span>
	},
	{
		dataIndex: 'services',
		width: '25%',
		title: 'Services',
		render: (services: EntityCode<string>[]) => tagsRender(services)
	},
	{
		dataIndex: 'comments',
		width: '15%',
		title: 'Comments',
		align: 'center',
		render: comments =>
			comments ? (
				<Tooltip
					title={comments}
					getPopupContainer={() => document.body}
					placement="bottom"
				>
					<Icon type="comment" color="primary" size="xl" />
				</Tooltip>
			) : (
				<Icon type="comment-empty" color="primary" size="lg" />
			)
	},
	{
		dataIndex: 'savingsBy',
		width: '10%',
		title: 'Saving by'
	},
	{
		dataIndex: 'amountSaved',
		width: '12%',
		title: 'Amount Saved',
		align: 'right',
		render: amountSaved => <Amount value={amountSaved} />
	},
	{
		dataIndex: 'currency',
		width: '6%',
		align: 'center',
		title: 'Currency'
	},
	{
		dataIndex: 'amountInUSD',
		width: '13%',
		title: 'Amount(USD)',
		align: 'right',
		render: amountInUSD => <Amount value={amountInUSD} />
	},
	{
		dataIndex: 'id',
		key: 'settings',
		align: 'right',
		width: 100,
		render: (_id: string, record: CostSaving) => {
			return (
				<Permission permissionCode={PermissionCode.MANAGE_SAVINGS}>
					{hasPermission =>
						(userType === UserType.LPA || hasPermission) && (
							<Popover
								content={<SettingsMenu record={record} />}
								type="dropdown"
								trigger="click"
								placement="bottomRight"
							>
								<Button
									disabled={isClosedJob}
									transparent
									type="primary"
									icon="settings"
								/>
							</Popover>
						)
					}
				</Permission>
			);
		}
	}
];
