import { AppState } from 'store-types';
import { IInternalComment } from 'services/api/threads/threadsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const isFetchingInternalCommentsSelector = (state: AppState): boolean =>
	state.internalComments.isFetching === FetchStatus.PENDING;

export const isPostingInternalCommentsSelector = (state: AppState): boolean =>
	state.internalComments.lastComment.isPosting === FetchStatus.PENDING;

export const failedRetrievingInternalCommentsSelector = (
	state: AppState
): Error | undefined => state.internalComments.error;

const getAllThreadsComments = (state: AppState) =>
	state.internalComments.threads;

export const getInternalCommentsForThreadId = (
	state: AppState,
	threadId: string
): IInternalComment[] => getAllThreadsComments(state)[threadId] || [];
