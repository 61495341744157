import { createSelector } from 'reselect';
import { getIsLoading } from 'store/selectors';
import { MessageEvent } from 'services/api/messages/messagesServiceTypes';
import { AppState } from 'store-types';
import { EventTypes } from 'sections/Thread/Events/Event/EventConstants';
import { getThreadLastReadMessage } from './selectors/threadSelectors';
import { last } from 'lodash';
import { passArgument } from 'store/utils';
import { getUserIdSelector } from 'store/auth/selectors';

export const getThreadEventsFetchStatus = (state: AppState) =>
	state.thread.events.fetchStatuses.retrieveEvents;

export const getIsThreadEventsLoading = (state: AppState) =>
	getIsLoading(state.thread.events.fetchStatuses.retrieveEvents);

export const getThreadEventsSelector = (state: AppState) =>
	state.thread.events.list;

// internal usage
const isEventMessageReply = (currentUserId: string, event: MessageEvent) => {
	return event.message.from && currentUserId
		? event.message.from.userId === currentUserId
		: false;
};

export const getThreadEventsList = createSelector(
	getUserIdSelector,
	getThreadEventsSelector,
	(currentUserId, events) =>
		events.map(event => {
			if (event.eventType === EventTypes.MessageAdded) {
				return {
					...event,
					isReply: isEventMessageReply(currentUserId, event)
				};
			}
			return event;
		})
);

const getMessageEvents = (state: AppState) =>
	state.thread.events.list.filter(
		event => event.eventType === EventTypes.MessageAdded
	);

export const getEventById = (state: AppState, id: string) =>
	state.thread.events.list.find(event => event.id === id) as MessageEvent;

export const getMessageByEventId = createSelector(
	getEventById,
	event => event.message
);

export const hasMoreEventsSelector = (state: AppState) =>
	state.thread.events.hasMore;

export const getOldestEvent = createSelector(getThreadEventsSelector, events =>
	events.length > 0 ? events[0] : null
);

export const getLatestEvent = createSelector(getThreadEventsSelector, events =>
	events.length > 0 ? events[events.length - 1] : null
);

export const isMessageEventFirstUnread = createSelector(
	[getEventById, getMessageEvents, getThreadLastReadMessage, getUserIdSelector],
	(currentEvent, messageEvents, lastRead, currentUserId) => {
		// check for unread status if it is a message and it is incoming
		if (
			!currentEvent ||
			currentEvent.eventType !== EventTypes.MessageAdded ||
			isEventMessageReply(currentUserId, currentEvent)
		) {
			return false;
		}

		// if none is read then current event has to be first the list to be considered as first unread
		if (!lastRead) {
			const firstMessageEvent = messageEvents[0];
			const isCurrentEventFirstInTheList =
				firstMessageEvent.id === currentEvent.id;
			return isCurrentEventFirstInTheList;
		}

		const readMessageEvent = messageEvents.find(
			e => e.message.id === lastRead.messageId
		);
		if (!readMessageEvent) {
			return false;
		}

		const currIndex = messageEvents.indexOf(currentEvent);
		const readIndex = messageEvents.indexOf(readMessageEvent);

		const messageEventBetweenTargetAndOrigin = messageEvents.find(
			(_e, i) => i > readIndex && i < currIndex
		);
		return currIndex > readIndex && !messageEventBetweenTargetAndOrigin;
	}
);

export const getLatestMessageEvent = createSelector(
	getMessageEvents,
	messages => last(messages)
);

export const getIsLatestMessageEvent = createSelector(
	getLatestMessageEvent,
	passArgument<string>(),
	(lastMessage, messageId) => !!lastMessage && lastMessage.id === messageId
);

export const getMessageHistoryByEventId = createSelector(
	getMessageByEventId,
	(state: AppState) => state.messages.messagesHistory,
	(message, allMessagesHistory): string | undefined =>
		allMessagesHistory[message.id] &&
		allMessagesHistory[message.id].contentHistory
);
