import { VPMetadataActionCode } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import { Metadata } from 'app-types';

export const onUpdateVPMetadata = (
	state: VesselProgrammeState,
	metadata: Metadata<VPMetadataActionCode>
): VesselProgrammeState => ({
	...state,
	metadata
});
