import { PortJobState } from '../portJobsState';
import { FetchStatus } from 'services/api/apiTypes';

export function onEditPortJobOperationStarted(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}

export function onEditPortJobOperationSuccess(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		}
	};
}

export function onEditPortJobOperationFailed(
	state: PortJobState
): PortJobState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}
