import { FormErrors } from 'redux-form';
import { RecursivePartial } from 'app-types';
import { first } from 'lodash';
import { FinanceFundingFormData } from 'store/form/financeFundings/financeFundingTypes';
import runValidations, {
	hasNumber,
	minValue,
	required,
	maxValue
} from 'utils/validations';
import { getDaInAmount } from './FundingRequestTable/FundingTableColumns/FundingRequestNumberInput/FundingRequestNumberInput.func';

export const validate = (values: FinanceFundingFormData) => {
	let errors: RecursivePartial<FinanceFundingFormData> = {};
	if (!values) {
		return errors as FormErrors<FinanceFundingFormData>;
	}
	const { fundingAmount, fundingPercent } = values;
	const balanceAmount = getDaInAmount(
		values.balanceForDaInAmount,
		values.balanceForDaInAmountWithoutAgencyCosts,
		values.baseAmountTypeCode
	);

	errors = {
		fundingAmount: first(
			runValidations(fundingAmount, [
				hasNumber,
				minValue(0),
				maxValue(balanceAmount, false),
				required
			])
		),
		fundingPercent: first(
			runValidations(fundingPercent, [
				hasNumber,
				minValue(0),
				maxValue(100, false),
				required
			])
		)
	};
	return errors as FormErrors<FinanceFundingFormData>;
};
