import { retrieveThreadsAvailableGroupsAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { RetrieveThreadsAvailableGroupsResponse } from 'services/api/threads/threadsServiceTypes';

export default makeTakeLatestWatcher<
	void,
	RetrieveThreadsAvailableGroupsResponse,
	Error
>({
	api: Api.Threads.retrieveThreadsAvailableGroups,
	async: retrieveThreadsAvailableGroupsAsync
});
