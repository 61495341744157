import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { ScrollableLayout } from 'components/Styles';
import { Alert } from 'components/antd';
import Header from './PagePartials/Header';
import Form from './PagePartials/Form/Form';
import { InjectedWizardFormBaseProps } from 'components/WizardForm';
import { submitPage } from 'store/wizardForm/actions';
import { getWizardById } from 'store/wizardForm/wizardFormSelectors';
import { isCreationPortJobSecondStepPermissionError } from 'store/portJobs/selectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { PORT_JOB_WIZARD_ID } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';

export interface PageProps extends InjectedWizardFormBaseProps {
	// from mapStateToProps
	activeWizard: WizardFormProps;
	isPermissionError: boolean;
	// from mapDispatchToProps
	submitPage: typeof submitPage;
}

export interface PageState {
	showAlert: boolean;
}

class Page extends React.Component<PageProps, PageState> {
	constructor(props: PageProps) {
		super(props);
		this.state = {
			showAlert: true
		};
	}

	componentDidMount() {
		this.props.setActivePageState({
			onNextClick: this.onFormNextClick
		});
	}

	onFormStateChange = (valid: boolean) => {
		if (this.props.activePageState.valid !== valid) {
			this.props.setActivePageState({ valid });
		}
	};

	private onFormNextClick = () => {
		const { isPermissionError, activeWizard } = this.props;
		this.props.submitPage({
			name: PORT_JOB_WIZARD_ID,
			page: activeWizard.activePage
		});
		this.setState({ showAlert: true });
		if (!isPermissionError) {
			this.props.nextPage();
		}
	};

	onAlertClose = () => {
		this.setState({
			showAlert: false
		});
	};

	render() {
		const { activePageState, isPermissionError } = this.props;
		const { showAlert } = this.state;
		const showPermissionError =
			isPermissionError && activePageState.submitted && showAlert;

		return (
			<>
				<Header />
				<ScrollableLayout asColumn>
					{showPermissionError && (
						<Alert
							message={`You are not allowed to create a job for the provided combination of  Appointer, Vessel and Port. Please contact your Optic administrator.`}
							type="error"
							showIcon
							closable
							onClose={this.onAlertClose}
						/>
					)}
					<ContextBar
						title="Please provide the details of the Job"
						type={ContextBarType.ACTION}
						actor={[UserType.PRINCIPAL, UserType.HUB]}
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						subtitle="First select the Job Type, and then fill out the other details about the Job."
						condition={true}
					/>
					<Form onFormStateChange={this.onFormStateChange} />
				</ScrollableLayout>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		activeWizard: getWizardById(state, PORT_JOB_WIZARD_ID),
		isPermissionError: isCreationPortJobSecondStepPermissionError(state)
	}),
	{
		submitPage
	}
)(Page);
