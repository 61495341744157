import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';

export const getAddPortJobOperationFormData = (state: AppState) =>
	state.form.portJob.values;

export const getOperationIdFromAddOperationFormData = (state: AppState) =>
	getAddPortJobOperationFormData(state).operationType.key;

export const getIsPortJobFetching = (state: AppState) =>
	state.portjob.fetchStatuses.adding === FetchStatus.PENDING;
