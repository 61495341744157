import { SystemStatus } from '../commonTypes';
import actionCreator from '../commonActionCreator';

const ACTION_NAME = 'RETRIEVE_SYSTEM_STATUS';

export const retrieveSystemStatusAsync = actionCreator.async<
	null,
	SystemStatus,
	Error
>(ACTION_NAME);

export const retrieveSystemStatus = actionCreator(ACTION_NAME);
export const startPollingSystemStatus = actionCreator(
	`START_POLL_${ACTION_NAME}`
);
