import { all, fork } from 'redux-saga/effects';

import retrievePortCallEventTemplatesWatcher from './retrievePortCallEventTemplatesSaga';
import retrievePortCallSectionsWatcher from './retrievePortCallSectionsSaga';
import retrievePortCallSectionsCycleWatcher from './retrievePortCallSectionsCycleSaga';
import updatePortCallEventWatcher from './updatePortCallEventSaga';
import addPortCallEventWatcher from './addPortCallEventSaga';
import uploadDocumentDoneWatcher from './uploadDocumentDoneSaga';
import retrievePortCallAccessiblePortJobs from './retrievePortCallAccessiblePortJobsSaga';
import updatePortCallEventJobAssignment from './updatePortCallEventJobAssignmentSaga';
import reactToChangesForEventsWatcher from './reactToChangesForEventsSaga';
import resetPortCallEventWatcher from './resetPortCallEventSaga';
import updatePortCallEventFromFinanceWatcher from './updatePortCallEventsFromFinanceSaga';

export default function*() {
	yield all([
		fork(updatePortCallEventWatcher),
		fork(retrievePortCallSectionsWatcher),
		fork(retrievePortCallSectionsCycleWatcher),
		fork(retrievePortCallEventTemplatesWatcher),
		fork(addPortCallEventWatcher),
		fork(uploadDocumentDoneWatcher),
		fork(retrievePortCallAccessiblePortJobs),
		fork(updatePortCallEventJobAssignment),
		fork(reactToChangesForEventsWatcher),
		fork(resetPortCallEventWatcher),
		fork(updatePortCallEventFromFinanceWatcher)
	]);
}
