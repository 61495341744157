import { all, fork } from 'redux-saga/effects';
import retrieveDeveloperPortalWatcher from './retrieveDeveloperPortalSaga';
import createNewApiSubscriptionWatcher from './createNewApiSubscriptionSaga';
import updateApiSubscriptionWatcher from './updateApiSubscriptionSaga';
import downloadApiSubscriptionWacther from './downloadApiSubscriptionSaga';

export default function*() {
	yield all([
		fork(retrieveDeveloperPortalWatcher),
		fork(createNewApiSubscriptionWatcher),
		fork(updateApiSubscriptionWatcher),
		fork(downloadApiSubscriptionWacther)
	]);
}
