import actionCreator from '../portCallDocumentsActionCreator';
import { CreatePortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'CREATE_PORT_CALL_DOCUMENT';
export const createPortCallDocumentAsync = actionCreator.async<
	CreatePortCallDocumentRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Port Call Document'
	}
});

export const createPortCallDocument = actionCreator<
	CreatePortCallDocumentRequest
>(ACTION_NAME);
