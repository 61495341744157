export class StorageGenericManager<T> {
	private storage: Storage;

	constructor(storage: Storage) {
		this.storage = storage;
	}

	set<K extends keyof T>(key: K, value: T[K]): void {
		this.storage.setItem(key as string, JSON.stringify(value));
	}

	get<K extends keyof T>(key: K): T[K] | null {
		const item = this.storage.getItem(key as string);
		return item ? JSON.parse(item) : null;
	}

	clear<K extends keyof T>(key: K): void {
		this.storage.removeItem(key as string);
	}
}
