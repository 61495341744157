import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';
import {
	RetrieveOperationParams,
	PortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	retrievePortJobOperationAsync,
	retrievePortJobOperation
} from 'store/portJobs/actions';
import { put, takeLatest, call, takeMaybe } from 'redux-saga/effects';
import { addCompaniesDraft, resetDrafts } from 'store/drafts/actions';
import { setPortJobOperationUpToDate } from 'store/portJobs/actions/setPortJobOperationUpToDate';
import { changeRoute } from 'store/route/actions';
import { showFailOutdatedNotification } from 'utils/sagaHelpers/sagaUtils';
import { safeRedirectToPortCallOverview } from './portJobUtilsSagas';

const apiCall = Api.PortJobs.retrievePortJobOperation;

export function* executor(
	actionParams: RetrieveOperationParams,
	api: typeof apiCall
): SagaIterator {
	yield put(retrievePortJobOperationAsync.started(actionParams));

	try {
		const response = yield call(api, actionParams);

		const { portJobCompanies = [] } = response.data as PortJobOperationTypes;
		yield put(resetDrafts());
		yield put(addCompaniesDraft(portJobCompanies));

		yield put(
			retrievePortJobOperationAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(setPortJobOperationUpToDate(true));
	} catch (error) {
		yield put(
			retrievePortJobOperationAsync.failed({
				error,
				params: actionParams
			})
		);
		yield call(
			safeRedirectToPortCallOverview,
			actionParams.portCallId,
			actionParams.jobCode
		);
		yield takeMaybe(changeRoute);
		yield call(showFailOutdatedNotification);
	}
}

function* worker({ payload }: Action<RetrieveOperationParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(retrievePortJobOperation, worker);
}
