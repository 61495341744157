import React from 'react';
import { connect } from 'react-redux';
import styles from '../PortCall.module.scss';
import { PageHeader, Error403 } from 'components';
import { AppNotifications } from 'containers';
import { ScrollableLayout } from 'components/Styles';
import JobDetailsTab, {
	PortJobTab
} from 'sections/PortCall/PortJob/JobDetailsTabs/JobDetailsTabs';
import JobInfo from 'sections/PortCall/PortJob/JobInfo';
import Header from './Header';
import { PortCallTabBaseProps } from 'sections/PortCall';
import {
	getActivePortCall,
	getPortJobsForActivePortCall
} from 'store/portcalls/portCallsSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { navigateTo } from 'utils';
import { Permission } from 'utils/components';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import {
	retrievePortCallCycleStart,
	retrievePortCallCycleStop
} from 'store/portcalls/actions';
import { AppState } from 'store-types';
import { setActivePortJobCode } from 'store/portJobs/actions';
import { getPortCallId } from '../Layout/PortCallLayout.func';
import { Icon } from 'components/antd';

interface PortCallOverviewTabProps extends PortCallTabBaseProps {
	// from mapStateToProps
	activePortJobCode: string;
	portCall: PortCall;
	portJobs: PortJob[];
	// from mapDispatchToProps
	retrievePortCallCycleStart: typeof retrievePortCallCycleStart;
	retrievePortCallCycleStop: typeof retrievePortCallCycleStop;
	setActivePortJobCode: typeof setActivePortJobCode;
}

class PortCallOverviewTab extends React.Component<PortCallOverviewTabProps> {
	getPortCallId = () => getPortCallId(this.props.match);

	componentDidMount() {
		const portCallId = this.getPortCallId();
		this.props.retrievePortCallCycleStart(portCallId);
	}

	componentWillUnmount() {
		this.props.retrievePortCallCycleStop();
	}

	onJobCodeChange = (code: string) => {
		if (code !== 'new') {
			navigateTo(`/portcalls/${this.getPortCallId()}/jobs/${code}/overview`);
		}
	};

	render() {
		const { activePortJobCode, portCall, portJobs } = this.props;
		return (
			<Permission permissionCode={PermissionCode.VIEW_PORTCALLS}>
				{hasPermission =>
					hasPermission ? (
						<ScrollableLayout>
							<PageHeader shadowed className={styles.header}>
								{portCall && <Header portcall={portCall} />}
							</PageHeader>
							<AppNotifications.Alert />
							<ScrollableLayout>
								<JobDetailsTab
									onJobCodeChange={this.onJobCodeChange}
									tabs={portJobs.map(
										(job): PortJobTab => ({
											jobCode: job.code,
											operationIndicator: job.isOperationsDisabled && (
												<Icon
													type="operations-disable"
													color="standard"
													offset="left"
													size={20}
												/>
											),
											financeIndicator: !job.financeEnabled && (
												<Icon
													type="finance-disable"
													color="standard"
													offset="left"
													size={20}
												/>
											),
											children: activePortJobCode === job.code && <JobInfo />
										})
									)}
								/>
							</ScrollableLayout>
						</ScrollableLayout>
					) : (
						<Error403 />
					)
				}
			</Permission>
		);
	}
}

export default connect(
	(state: AppState) => ({
		activePortJobCode: getActivePortJobCode(state),
		portCall: getActivePortCall(state),
		portJobs: getPortJobsForActivePortCall(state)
	}),
	{
		setActivePortJobCode,
		retrievePortCallCycleStart,
		retrievePortCallCycleStop
	}
)(PortCallOverviewTab);
