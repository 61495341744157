import { PortCallState } from '../portCallState';
export const onSetPortCallUpToDate = (
	state: PortCallState,
	isPortCallUpToDate: boolean
): PortCallState => ({
	...state,
	context: {
		...state.context,
		isPortCallUpToDate
	}
});
