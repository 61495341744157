import { PageFormProps } from './Form';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { debounce, get } from 'lodash';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';
import { checkPortJobPermissions } from 'store/portJobs/actions';

const onChange = (
	values: Page1FormValues,
	// eslint-disable-next-line:ban-types
	dispatch: Function,
	props: PageFormProps
) => {
	if (props.isCurrentUserWithRoleHub) {
		const { portCall, hubPrincipalCompany } = values;
		if (
			!portCall ||
			!portCall.vessel ||
			!portCall.port ||
			(!hubPrincipalCompany && !props.isCurrentUsersGroupTypeISSCluster)
		) {
			return;
		}
		dispatch(
			checkPortJobPermissions({
				mainPrincipalId: get(hubPrincipalCompany, 'key', ''),
				vesselId: portCall.vessel.key,
				portId: portCall.port.key
			})
		);
	}
};

export default debounce(onChange, DEFAULT_DEBOUNCE_DELAY);
