import {
	AppointAgentToPortPayload,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import actionCreator from '../portJobsActionCreator';

const ACTION_NAME = 'APPOINT_TO_PORTJOB';

export const appointToPortJob = actionCreator<AppointAgentToPortPayload>(
	ACTION_NAME
);
export const appointToPortJobAsync = actionCreator.async<
	AppointAgentToPortPayload,
	PortJobStatusUpdateResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Port Job'
	},
	failed: {
		// react-to-changes
		omit: [412]
	}
});
