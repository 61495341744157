import { PortsState } from '../portsState';
import {
	RetrievePortByIdRequest,
	RetrievePortByIdResponse
} from 'services/api/ports/portsServiceTypes';
import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrievePortId = makeFetchStatusReducers<
	PortsState,
	'fetchStatuses',
	RetrievePortByIdRequest,
	RetrievePortByIdResponse
>('fetchStatuses', ({ port }) => port, {
	doneReducer: (
		state: PortsState,
		action: Success<RetrievePortByIdRequest, RetrievePortByIdResponse>
	): PortsState => ({
		...state,
		portsById: {
			...state.portsById,
			[action.params.port]: action.result
		}
	})
});
