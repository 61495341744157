import * as React from 'react';
import { isEmpty, debounce } from 'lodash';
import { LabeledValue, Entity } from 'app-types';
import {
	KeyboardEventKey,
	DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT,
	DEFAULT_AUTOCOMPLETE_PLACEHOLDER,
	DEFAULT_DEBOUNCE_DELAY
} from 'app-constants';
import Select, { SelectProps } from 'components/antd/Select/Select';
import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';
import { customMap } from 'components/antd/Form/ReduxField/mapError';
import styles from './Autocomplete.module.scss';
import classNames from 'classnames';

/**
 * Allows more complex item with extended data
 */
export interface AutocompleteOption extends Entity {}

export interface AutocompleteProps extends SelectProps {
	minLength?: number;
	onSearch?: (value?: string) => Promise<AutocompleteOption[]>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange?: (value: any, option: any) => any;
	value?: LabeledValue;
	cache?: boolean;
	closeOnEnter?: boolean;
	disabledKeys?: string[];
	dropdownFooter?: (options: AutocompleteOption[]) => React.ReactNode;
	dropdownFooterClassName?: string;
	initialOptions?: AutocompleteOption[];
	shouldSearchAllOptionsOnFocus?: boolean;
	localSearch?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getCustomOption?: (props: any) => React.ReactElement<any>;
	afterFocusClick?: boolean;
	isDefaultOptionsEnabled?: boolean;
	defaultOptions?: AutocompleteOption[];
	checkIsNonISSHubPrincipal?: boolean;
}

interface AutocompleteState {
	options: AutocompleteOption[];
	searched: boolean;
}

class Autocomplete extends React.Component<
	AutocompleteProps,
	AutocompleteState
> {
	static defaultProps: Partial<AutocompleteProps> = {
		cache: true,
		initialOptions: [],
		disabledKeys: [],
		minLength: 2,
		notFoundContent: DEFAULT_AUTOCOMPLETE_NOT_FOUND_TEXT,
		placeholder: DEFAULT_AUTOCOMPLETE_PLACEHOLDER
	};
	static ReduxFormItem: CreateReduxField<AutocompleteProps>;

	constructor(props: AutocompleteProps) {
		super(props);

		this.state = {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			options: this.props.initialOptions!, // quarantine from default props
			searched: false
		};

		this.oldSearchValue = '';
	}

	private oldSearchValue: string;

	onBlur = () => {
		// clear search state on blur
		this.resetState();

		if (this.props.onBlur) {
			// is called without `value` argument intentionally.
			// since when `getCustomOption` is defined, label will have value of react element instead of a string
			// for this, in `onSelect`, we intentionally set label with value of `title`.
			// since we don't get `title` value here and in order not to change value back to react element,
			// we skip passing on the value
			(this.props.onBlur as () => void)();
		}
	};

	onSearch = debounce((value = '') => {
		const { minLength } = this.props;
		// minLength always has a default value, checking is needed to avoid a TS warning
		if (
			minLength &&
			value.length >= minLength &&
			value !== this.oldSearchValue
		) {
			this.performSearch(value);
			this.oldSearchValue = value;
			return;
		}
		if (!this.props.cache) {
			this.resetState();
		}
	}, DEFAULT_DEBOUNCE_DELAY);

	onFocus = () => {
		if (this.props.shouldSearchAllOptionsOnFocus) {
			this.performSearch();
		}
		if (this.props.isDefaultOptionsEnabled && this.props.defaultOptions) {
			this.setState({ options: [...this.props.defaultOptions] });
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelect = (value: LabeledValue, option: React.ReactElement<any>) => {
		const { getCustomOption } = this.props;
		/**
		 * Specific mapping for the case with custom label for Select.Option
		 * (when getCustomOption prop is used)
		 */
		let selectedValue: LabeledValue = value;
		if (getCustomOption) {
			/**
			 * Usage of getCustomOption means that a label is actually a React component,
			 * so we have to map it from the corresponding option props properly
			 */
			selectedValue = {
				key: value.key,
				label: option?.props?.title
			};
		}
		// TODO verify need in calling onChange here, called 2 times
		// (might be existing for redux-form item)
		if (this.props.onChange) {
			this.props.onChange(selectedValue, option);
		}
		if (this.props.onSelect) {
			this.props.onSelect(selectedValue, option);
		}
	};

	performSearch = (value?: string) => {
		if (this.props.localSearch) {
			return;
		}
		this.setState({ searched: false });
		if (this.props.onSearch) {
			this.props
				.onSearch(value)
				.then(options => {
					if (this.props.isDefaultOptionsEnabled && value) {
						const defaultValues = this.props.defaultOptions?.filter(x =>
							x.name.toLowerCase().includes(value)
						);
						if (defaultValues) {
							options = [...defaultValues, ...options];
						}
					}
					this.setState({ options });
				})
				.catch(() => this.resetState())
				.then(() => this.setState({ searched: true }));
		}
	};

	resetState = () => {
		this.setState({
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			options: this.props.initialOptions!, // quarantee from default props
			searched: false
		});
		this.oldSearchValue = '';
	};

	isSearchWithoutResults() {
		return this.state.searched && isEmpty(this.state.options);
	}

	isOptionDisabled = (id: string) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.props.disabledKeys!.some(key => key === id); // disabledKeys quarantee as array from default props
	};

	onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (this.props.onInputKeyDown) {
			this.props.onInputKeyDown(e);
		}
		if (this.props.localSearch && !this.state.searched) {
			this.setState({ searched: true });
		}
		if (!this.props.closeOnEnter) {
			return;
		}
		if (e.key === KeyboardEventKey.ENTER) {
			this.resetState();
		}
	};

	private getOptionItem = () => {
		const { localSearch, children, getCustomOption } = this.props;
		const { options } = this.state;
		if (localSearch) {
			return children;
		}
		return options.map(({ id, name }, index) => {
			const option = options[index];
			return (
				<Select.Option
					key={id}
					title={name}
					disabled={this.isOptionDisabled(id)}
				>
					{getCustomOption?.(option) || name}
				</Select.Option>
			);
		});
	};

	render() {
		const {
			className,
			dropdownFooter,
			notFoundContent,
			localSearch,
			dropdownFooterClassName,
			getCustomOption,
			afterFocusClick,
			isDefaultOptionsEnabled,
			...props
		} = this.props;
		const { options, searched } = this.state;

		return (
			<Select
				{...props}
				className={classNames(styles.autocomplete, className)}
				showSearch
				labelInValue
				onSearch={(!localSearch && this.onSearch) || undefined}
				onSelect={this.onSelect}
				onFocus={this.onFocus}
				onInputKeyDown={this.onInputKeyDown}
				defaultActiveFirstOption={false}
				notFoundContent={this.isSearchWithoutResults() ? notFoundContent : ''}
				filterOption={Boolean(localSearch)}
				onBlur={this.onBlur}
				showAction={afterFocusClick ? ['focus', 'click'] : undefined}
			>
				{this.getOptionItem()}
				{dropdownFooter && this.isSearchWithoutResults() && (
					<Select.Option key="notFound" disabled={true}>
						{notFoundContent}
					</Select.Option>
				)}
				{dropdownFooter && searched && (
					<Select.Option
						key="footer"
						disabled={true}
						className={dropdownFooterClassName}
					>
						{dropdownFooter(options)}
					</Select.Option>
				)}
			</Select>
		);
	}
}

/**
 * label is lost when new search result is set. labelInValue as a work around
 * setting `labelInValue` prop to `Select` helps preserve label
 * in case of options being replaced with new search result
 */
export const selectFieldMap = customMap<AutocompleteProps>(props => {
	const value = props.input.value || props.defaultValue;
	return { value };
});

Autocomplete.ReduxFormItem = createFieldComponent<AutocompleteProps>(
	Autocomplete,
	selectFieldMap
);

export default Autocomplete;
