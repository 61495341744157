import * as React from 'react';
import { signInSilentCallback } from 'services/userManager';
import Loading from 'components/Loading/Loading';

class SilentRenew extends React.Component<{}> {
	componentDidMount() {
		signInSilentCallback();
	}

	render() {
		return <Loading />;
	}
}

export default SilentRenew;
