import { keyBy } from 'lodash';
import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveFinanceCurrenciesResponse } from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';

export const retrieveFinanceCurrencies = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	null,
	RetrieveFinanceCurrenciesResponse
>('fetchStatuses', 'retrieveFinanceCurrencies', {
	doneReducer: (
		state: FinanceState,
		action: Success<null, RetrieveFinanceCurrenciesResponse>
	): FinanceState => ({
		...state,
		currencies: keyBy(action.result.elements, 'code')
	})
});
