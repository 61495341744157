import * as React from 'react';
import { SupplierInvoicePortJob } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { Collapse } from 'components/antd';

import styles from './InvoicePortJobHeader.module.scss';
import InvoicePortJobHeader from './InvoicePortJobHeader';

interface InvoicePortJobCollapseProps {
	portJob: SupplierInvoicePortJob;
	portJobIdx?: number;
	children: (element: React.ReactNode, id?: number) => void;
}

const InvoicePortJobCollapse: React.SFC<InvoicePortJobCollapseProps> = ({
	portJob,
	children
}) => {
	const { services = [], ...portJobHeaderProps } = portJob;
	return (
		<Collapse type="primary" defaultActiveKey={[portJob.code]} bordered>
			<Collapse.Panel
				key={portJob.code}
				header={
					<InvoicePortJobHeader
						portJob={portJobHeaderProps}
						nbOfServices={services.length}
					/>
				}
			>
				{services.map((service, idx) => (
					<div key={service.id}>
						{children(service, idx)}
						{idx !== services.length - 1 && <hr className={styles.divider} />}
					</div>
				))}
			</Collapse.Panel>
		</Collapse>
	);
};

export default InvoicePortJobCollapse;
