import React from 'react';
import numeral from 'numeral';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import {
	Column,
	CargoGridProps
} from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoGrid/CargoGrid';

import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import CargoLineFormFieldsName from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineFormFieldNames';
import { columnContent } from '../../../helpers';
import ReferenceNumberColumn from '../../ReferenceNumberColumn';
import { FormattedTime } from 'containers';

const {
	REFERENCE_NUMBER,
	PRINCIPAL_VOYAGE_REFERENCE,
	COMMODITY,
	COMMODITY_QUANTITY,
	COMMODITY_UNIT_OF_MEASUREMENT_CODE,
	CHARTERER_COMPANY,
	CHARTER_PARTY_DATE_PLT,
	RECEIVER_COMPANY,
	DISCHARGING_TO,
	LOAD_PORT
} = CargoLineFormFieldsName;

function getCargoLineColumns<P>({
	operationTypeCode,
	unitsByCode
}: P & CargoGridProps) {
	const columns: Array<ColumnProps<Column>> = [
		{
			title: 'Cargo Ref',
			dataIndex: REFERENCE_NUMBER,
			key: REFERENCE_NUMBER,
			render: (text: string, cargoLine: Column) =>
				cargoLine.id && <ReferenceNumberColumn value={text} id={cargoLine.id} />
		},
		{
			title: 'Principal Voyage Ref',
			dataIndex: PRINCIPAL_VOYAGE_REFERENCE,
			key: PRINCIPAL_VOYAGE_REFERENCE,
			render: text => columnContent(text)
		},
		{
			title: 'Commodity Detail',
			dataIndex: `${COMMODITY}.label`,
			key: COMMODITY,
			render: (text: string, cargoLine: Column) =>
				text ? text : cargoLine.mainCommodity
		},
		{
			title: 'Nomination Qty',
			dataIndex: COMMODITY_QUANTITY,
			key: COMMODITY_QUANTITY,
			render: text =>
				columnContent(text, value => numeral(value).format('0,0.00'))
		},
		{
			title: 'UOM',
			dataIndex: COMMODITY_UNIT_OF_MEASUREMENT_CODE,
			key: COMMODITY_UNIT_OF_MEASUREMENT_CODE,
			render: code => {
				const currentCode = unitsByCode[code];
				const displayCode = currentCode ? currentCode.display : '';
				return columnContent(displayCode);
			}
		},
		{
			title: 'Receiver',
			dataIndex: `${RECEIVER_COMPANY}.label`,
			key: RECEIVER_COMPANY,
			render: text => columnContent(text)
		},
		{
			title: 'Charterer',
			dataIndex: `${CHARTERER_COMPANY}.label`,
			key: CHARTERER_COMPANY,
			render: text => columnContent(text)
		},
		{
			title: 'Charter Party Date',
			dataIndex: `${CHARTER_PARTY_DATE_PLT}`,
			key: CHARTER_PARTY_DATE_PLT,
			render: text =>
				columnContent(text, date => (
					<FormattedTime value={date} format="DD MMM YYYY" />
				))
		},
		{
			title: 'Discharging To',
			dataIndex: `${DISCHARGING_TO}.label`,
			key: DISCHARGING_TO,
			render: text => columnContent(text)
		},
		{
			title: 'Load Port',
			dataIndex: `${LOAD_PORT}.label`,
			key: LOAD_PORT,
			render: text => columnContent(text)
		}
	];
	if (operationTypeCode !== OperationTypeCode.DISCHARGING_STS) {
		return columns.filter(item => item.key !== DISCHARGING_TO);
	}
	return columns;
}

export default getCargoLineColumns;
