import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getIsActivePortJobCancelled,
	getIsJobLinked,
	getIsAppointer,
	getIsDraftAppointer
} from 'store/portJobs/selectors';
import { CommonActionModalProps } from './ActionModalTypes';
import { PermissionButton } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { navigateTo } from 'utils';
import { MaybeTooltip } from 'components/antd';

export const LinkJobButton: FC<CommonActionModalProps> = ({
	portCallId,
	jobCode
}) => {
	const disabled = useSelector(getIsActivePortJobCancelled);
	const isLinked = useSelector(getIsJobLinked);
	const isAppointer = useSelector(getIsAppointer);
	const isDraftAppointer = useSelector(getIsDraftAppointer);
	const onClick = () =>
		navigateTo(`/portcalls/${portCallId}/jobs/${jobCode}/linkjob`);

	const getTooltipMessage = () => {
		if (isDraftAppointer) {
			return 'The draft appointer cannot be used to Link job. Please wait until the draft is converted or choose an existing appointer.';
		} else if (!isAppointer) {
			return 'Please wait until Appointer information is provided';
		} else {
			return 'Please enable the operations to link the job';
		}
	};

	return (
		<>
			{!isLinked && (
				<MaybeTooltip
					placement={'bottomLeft'}
					title={getTooltipMessage()}
					show={!isAppointer || isDraftAppointer}
				>
					<span>
						<PermissionButton
							permissionCode={PermissionCode.MANAGE_PORTCALL}
							type="primary"
							onClick={onClick}
							disabled={disabled || !isAppointer || isDraftAppointer}
						>
							Link job
						</PermissionButton>
					</span>
				</MaybeTooltip>
			)}
		</>
	);
};

export default LinkJobButton;
