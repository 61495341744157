import {
	getUserHasLpaApplicationAccess,
	isUserLoggedIn,
	getIsCurrentUserLpaUserType,
	getUserHasQuarterMasterApplicationAccess,
	getRestrictAccessToOptic,
	getIsCurrentUserQuartermasterUserType
} from 'store/auth/selectors';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery, take, delay, select } from 'redux-saga/effects';
import {
	checkAuth,
	setAuth,
	clearAuth,
	startAuth,
	stopAuth,
	retrieveAuthUser,
	retrieveAuthUserAsync
} from '../actions';
import { authSyncStorageWorker } from './setAuthActAsSaga';
import { getUser } from 'services/userManager';
import { OidcUser } from 'store/auth/authTypes';
import config from 'services/config';
import {
	hasUserSwitchedOptic,
	setUserChoice
} from '../../../services/storage/appRedirectStorage';
import { getAuthUserId } from '../selectors/user/userSelectors';
import { getQueryParams } from 'components/PageHeader/PageHeader.func';
import {
	USER_SWITCH_OPTIC_PARAM,
	USER_SWITCH_QM_PARAM,
	USER_SWITCH_TO_OPTIC,
	USER_SWITCH_TO_QM
} from 'app-constants';

function* retrieveUserWorker() {
	yield put(retrieveAuthUser());
	// wait until resolves
	yield take([retrieveAuthUserAsync.done, retrieveAuthUserAsync.failed]);
	// make sure the store is updated before
	// dispatching the next actions that depend on the state
	yield delay(0);
}

function* retrieveAuthAndActingUserWorker() {
	yield call(retrieveUserWorker);
	const result = yield call(authSyncStorageWorker);
	if (result) {
		yield call(retrieveUserWorker);
	}
}

function* checkRedirectToLpaApp(): SagaIterator {
	const { lpaAppUrl } = config;

	const hasLpaApplicationAccess = yield select(getUserHasLpaApplicationAccess);
	const isCurrentUserLpaUserType = yield select(getIsCurrentUserLpaUserType);

	if (
		hasLpaApplicationAccess &&
		isCurrentUserLpaUserType &&
		lpaAppUrl.startsWith('https://')
	) {
		const queryParams = getQueryParams(window.location.href);
		const currentUserId = yield select(getAuthUserId);
		/// Checks if user has switched to 'Optic' or whether URL Parameter has 'application=optic'
		/// then allows user to stay back in 'Optic' application
		/// else redirects user to LPA Application
		if (
			hasUserSwitchedOptic(currentUserId) ||
			queryParams.includes(USER_SWITCH_OPTIC_PARAM)
		) {
			setUserChoice(currentUserId, USER_SWITCH_TO_OPTIC);
		} else window.location.replace(lpaAppUrl);
	}
}

function* checkRedirectToQuartermasterApp(): SagaIterator {
	const { quartermasterAppUrl } = config;

	const restrictOpticApplicationAccess = yield select(getRestrictAccessToOptic);

	const hasQuarterMasterApplicationAccess = yield select(
		getUserHasQuarterMasterApplicationAccess
	);
	const isCurrentUserPrincipalUserType = yield select(
		getIsCurrentUserQuartermasterUserType
	);

	if (
		hasQuarterMasterApplicationAccess &&
		isCurrentUserPrincipalUserType &&
		quartermasterAppUrl.startsWith('https://')
	) {
		const queryParams = getQueryParams(window.location.href);
		const currentUserId = yield select(getAuthUserId);

		if (restrictOpticApplicationAccess) {
			setUserChoice(currentUserId, USER_SWITCH_TO_QM);
			window.location.replace(`${quartermasterAppUrl}?${USER_SWITCH_QM_PARAM}`);
		}

		/// Checks if user has switched to 'Optic' or whether URL Parameter has 'application=optic'
		/// then allows user to stay back in 'Optic' application
		/// else redirects user to QuarterMaster Application
		if (
			hasUserSwitchedOptic(currentUserId) ||
			queryParams.includes(USER_SWITCH_OPTIC_PARAM)
		) {
			setUserChoice(currentUserId, USER_SWITCH_TO_OPTIC);
		} else window.location.replace(quartermasterAppUrl);
	}
}

export function* checkAuthSagaWorker(): SagaIterator {
	yield put(startAuth());
	const oidcUser: OidcUser = yield call(getUser);
	yield put(setAuth(oidcUser));
	if (!isUserLoggedIn(oidcUser)) {
		yield put(clearAuth());
		yield put(stopAuth());
		return;
	}
	/**
	 * Wait for `auth` to retrieve current user and acting user if needed
	 * and call `stopAuth` after all requests are resolved to not
	 * to fallback to permission error screen while users are being loaded
	 */
	yield call(retrieveAuthAndActingUserWorker);
	yield put(stopAuth());

	yield call(checkRedirectToLpaApp);
	yield call(checkRedirectToQuartermasterApp);
}

export default function*() {
	yield takeEvery(checkAuth.type, checkAuthSagaWorker);
}
