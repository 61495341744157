import { Success } from 'typescript-fsa';
import { keyBy } from 'lodash';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';
import {
	AllowedDocument,
	RetrieveAllowedDocumentsRequest,
	RetrieveAllowedDocumentsResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const onRetrieveAllowedDocumentsStarted = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		allowedByCode: FetchStatus.PENDING
	}
});

const onRetrieveAllowedDocumentsDone = (
	state: PortCallDocumentsState,
	{
		result
	}: Success<RetrieveAllowedDocumentsRequest, RetrieveAllowedDocumentsResponse>
) => ({
	...state,
	allowedByCode: keyBy<AllowedDocument>(result.elements, item => item.code),
	fetchStatuses: {
		...state.fetchStatuses,
		allowedByCode: FetchStatus.SUCCESS
	}
});

const onRetrieveAllowedDocumentsFailed = (state: PortCallDocumentsState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		allowedByCode: FetchStatus.FAILURE
	}
});

export {
	onRetrieveAllowedDocumentsStarted,
	onRetrieveAllowedDocumentsDone,
	onRetrieveAllowedDocumentsFailed
};
