// Types
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// Utils
import { initialState, DocumentsState } from './documentsState';

import { onRemoveAttachment } from './reducers/removeAttachmentReducers';
import { removeAttachment } from './actions/removeAttachment';
import {
	onBeforeUploadAttachment,
	onSaveAttachmentDone,
	onSaveAttachmentFailed,
	onSaveAttachmentStarted
} from './reducers/saveAttachmentReducer';
import { onRetrieveRelativeConfigDone } from './reducers/retrieveRelativeConfigurationReducer';
import { retrieveRelativeDocumentConfigAsync } from './actions/retrieveRelativeConfiguration';
import {
	onRetrieveStaticConfigStarted,
	onRetrieveStaticConfigDone
} from './reducers/retrieveStaticConfigurationReducer';
import { removeFetchFile } from './documentsHelpers';
import { retrieveStaticDocumentConfigAsync } from './actions/retrieveStaticConfiguration';
import {
	beforeUploadAttachment,
	saveAttachmentAsync
} from './actions/saveAttachment';

import {
	updateDocumentProcessing,
	uploadDocumentProgress,
	cancelUploadAndProcessDocument,
	uploadAndProcessDocumentAsync,
	retrieveDocumentAsync,
	retrieveDocumentImageAsync
} from './actions';

import {
	onUpdateDocumentProcessing,
	onUploadDocumentProgress,
	onUploadAndProcessDocumentCancelled,
	onUploadAndProcessDocumentStarted,
	onUploadAndProcessDocumentFailed,

	// Retrieve Document
	onRetrieveDocumentStarted,
	onRetrieveDocumentDone,
	onRetrieveDocumentFailed,

	// Retrieve Document Image
	onRetrieveDocumentImageStarted,
	onRetrieveDocumentImageDone,
	onRetrieveDocumentImageFailed
} from './reducers';

function onRetrieveUploadConfigFailed(state: DocumentsState): DocumentsState {
	return {
		...state,
		filesFetchingNumber: removeFetchFile(state.filesFetchingNumber)
	};
}

/**
 * Actions
 */
export default reducerWithInitialState(initialState)
	// Retrieve Static Config
	.case(
		retrieveStaticDocumentConfigAsync.started,
		onRetrieveStaticConfigStarted
	)
	.case(retrieveStaticDocumentConfigAsync.done, onRetrieveStaticConfigDone)
	.case(retrieveStaticDocumentConfigAsync.failed, onRetrieveUploadConfigFailed)
	// Fetch Relative Config
	.case(retrieveRelativeDocumentConfigAsync.done, onRetrieveRelativeConfigDone)
	.case(
		retrieveRelativeDocumentConfigAsync.failed,
		onRetrieveUploadConfigFailed
	)
	// Retrieve Document
	.case(retrieveDocumentAsync.started, onRetrieveDocumentStarted)
	.case(retrieveDocumentAsync.done, onRetrieveDocumentDone)
	.case(retrieveDocumentAsync.failed, onRetrieveDocumentFailed)
	// Retrieve Document Image
	.case(retrieveDocumentImageAsync.started, onRetrieveDocumentImageStarted)
	.case(retrieveDocumentImageAsync.done, onRetrieveDocumentImageDone)
	.case(retrieveDocumentImageAsync.failed, onRetrieveDocumentImageFailed)
	// Upload Document it's complete
	.case(saveAttachmentAsync.started, onSaveAttachmentStarted)
	.case(saveAttachmentAsync.done, onSaveAttachmentDone)
	.case(saveAttachmentAsync.failed, onSaveAttachmentFailed)
	// Remove Attachment
	.case(removeAttachment, onRemoveAttachment)
	.case(beforeUploadAttachment, onBeforeUploadAttachment)
	.case(
		uploadAndProcessDocumentAsync.started,
		onUploadAndProcessDocumentStarted
	)
	.case(uploadAndProcessDocumentAsync.failed, onUploadAndProcessDocumentFailed)
	.case(uploadDocumentProgress, onUploadDocumentProgress)
	// Uploaded Document Processing
	.case(updateDocumentProcessing, onUpdateDocumentProcessing)
	// Cancel upload
	.case(cancelUploadAndProcessDocument, onUploadAndProcessDocumentCancelled);
