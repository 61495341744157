import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrievelinkedJobCargosAsync,
	retrievelinkedAppointmentJobAsync,
	saveUpdatedLinkedCargosAsync,
	resetLinkJob
} from './actions';
import deleteLinkedAppointmentCargo from './actions/deleteLinkedAppointmentCargo';
import addAppointmentCargoManual from './actions/addAppointmentCargoLinkedJob';
import createCargoFromNomination from './actions/createCargoFromNomination';
import {
	onRetrieveLinkJobCargos,
	onUpdateLinkedAppointmentCargos,
	onAddAppointmentCargoManual,
	onCreateCargoFromNomination,
	onRetrieveLinkedAppointmentJob,
	onSaveUpdatedLinkJobCargos
} from './reducers';
import { initialState } from './linkJobState';
import { onResetLinkJob } from './reducers/resetLinkJobReducer';

export default reducerWithInitialState(initialState)
	.case(retrievelinkedJobCargosAsync.started, onRetrieveLinkJobCargos.started)
	.case(retrievelinkedJobCargosAsync.failed, onRetrieveLinkJobCargos.failed)
	.case(retrievelinkedJobCargosAsync.done, onRetrieveLinkJobCargos.done)
	.case(deleteLinkedAppointmentCargo.action, onUpdateLinkedAppointmentCargos)
	.case(addAppointmentCargoManual.action, onAddAppointmentCargoManual)
	.case(createCargoFromNomination.action, onCreateCargoFromNomination)
	.case(
		saveUpdatedLinkedCargosAsync.started,
		onSaveUpdatedLinkJobCargos.started
	)
	.case(saveUpdatedLinkedCargosAsync.failed, onSaveUpdatedLinkJobCargos.failed)
	.case(saveUpdatedLinkedCargosAsync.done, onSaveUpdatedLinkJobCargos.done)
	.case(
		retrievelinkedAppointmentJobAsync.started,
		onRetrieveLinkedAppointmentJob.started
	)
	.case(
		retrievelinkedAppointmentJobAsync.failed,
		onRetrieveLinkedAppointmentJob.failed
	)
	.case(
		retrievelinkedAppointmentJobAsync.done,
		onRetrieveLinkedAppointmentJob.done
	)
	.case(resetLinkJob, onResetLinkJob);
