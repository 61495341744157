import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { DeleteServiceCategoryRequest } from 'services/api/services/servicesServiceTypes';
import Api from 'services/api';
import { deleteServiceCategoryAsync as async } from '../actions';

export default makeTakeLatestWatcher<DeleteServiceCategoryRequest, null, Error>(
	{
		api: Api.Services.deleteServiceCategory,
		async
	}
);
