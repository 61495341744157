import * as React from 'react';
import CargoLineFormFieldsName from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineFormFieldNames';
import { DEFAULT_SELECT_PLACEHOLDER } from 'app-constants';
import { Select } from 'components/antd';
import { MainCommodity } from 'services/api/commodities/commoditiesServiceTypes';
import { getMainCommodities } from 'store/commodities/commoditiesSelectors';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { retrieveCommodities } from 'store/commodities/actions';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { AppState } from 'store-types';

interface FieldMainCommodityProps {
	isSplit: boolean;
	value: string;

	mainCommodities: MainCommodity[];
	change: typeof change;
	retrieveCommodities: typeof retrieveCommodities;
}

const { MAIN_COMMODITY, COMMODITY } = CargoLineFormFieldsName;

class FieldMainCommodity extends React.Component<FieldMainCommodityProps> {
	componentDidUpdate() {
		const { value } = this.props;
		if (value) {
			this.props.retrieveCommodities({ mainCommodity: value });
		}
	}

	onSelect = (mainCommodity: string) => {
		if (mainCommodity !== this.props.value) {
			this.props.change(FORM.cargoLine, COMMODITY, null);
		}
	};

	render() {
		const { isSplit, mainCommodities } = this.props;
		return (
			<Select.ReduxFormItem
				name={MAIN_COMMODITY}
				label="Main Commodity"
				placeholder={DEFAULT_SELECT_PLACEHOLDER}
				disabled={isSplit}
				required
				onSelect={this.onSelect}
			>
				{mainCommodities.map(commodity => (
					<Select.Option
						key={commodity.mainCommodityCode}
						value={commodity.mainCommodityCode}
						title={commodity.mainCommodityCode}
					>
						{commodity.name}
					</Select.Option>
				))}
			</Select.ReduxFormItem>
		);
	}
}

export default connect(
	(state: AppState) => ({
		mainCommodities: getMainCommodities(state)
	}),
	{
		retrieveCommodities,
		change
	}
)(FieldMainCommodity);
