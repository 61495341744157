import React from 'react';
import { OptionalValue } from 'components';
import FormattedTimeExtended from 'components/FormattedTimeExtended/FormattedTimeExtended';
import numeral from 'numeral';

import { ColumnProps } from 'components/antd/Table/TableTypes';
import {
	Column,
	CargoGridProps
} from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoGrid/CargoGrid';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { columnContent } from '../../../helpers';
import ReferenceNumberColumn from '../../ReferenceNumberColumn';
import { FormattedTime } from 'containers';

function getCargoLineColumns<P>({
	operationTypeCode,
	unitsByCode,
	countriesByCode
}: P & CargoGridProps) {
	const columns: Array<ColumnProps<Column>> = [
		{
			title: 'Cargo Ref',
			dataIndex: 'referenceNumber',
			key: 'referenceNumber',
			render: (text: string, cargoLine: Column) =>
				cargoLine.id && <ReferenceNumberColumn value={text} id={cargoLine.id} />
		},
		{
			title: 'Principal Voyage Ref',
			dataIndex: 'principalVoyageReference',
			key: 'principalVoyageReference',
			render: text => columnContent(text)
		},
		{
			title: 'Commodity Detail',
			dataIndex: 'commodity.label',
			render: (text: string, cargoLine: Column) =>
				text ? text : cargoLine.mainCommodity
		},
		{
			title: 'Nomination Qty',
			dataIndex: 'commodityQuantity',
			key: 'nominationQuantity',
			render: text =>
				columnContent(text, value => numeral(value).format('0,0.00'))
		},
		{
			title: 'UOM',
			dataIndex: 'commodityUnitOfMeasurementCode',
			key: 'commodityUnitOfMeasurementCode',
			render: code => {
				const currentCode = unitsByCode[code];
				const displayCode = currentCode ? currentCode.display : '';
				return columnContent(displayCode);
			}
		},
		{
			title: 'Shipper',
			dataIndex: 'shipperCompany.label',
			key: 'shipperCompany',
			render: text => columnContent(text)
		},
		{
			title: 'Charterer',
			dataIndex: 'chartererCompany.label',
			key: 'chartererCompany',
			render: text => columnContent(text)
		},
		{
			title: 'Charter Party Date',
			dataIndex: 'charterPartyDate',
			key: 'charterPartyDate',
			render: text =>
				columnContent(text, date => (
					<FormattedTime value={date} format="DD MMM YYYY" />
				))
		},
		{
			title: 'Laycan From',
			dataIndex: 'laycanFromPlt',
			key: 'laycanFrom',
			render: text =>
				columnContent(text, date => <FormattedTimeExtended value={date} />)
		},
		{
			title: 'Loading From',
			dataIndex: 'loadingFromVessel.label',
			key: 'loadingFromVessel',
			render: text => columnContent(text)
		},
		{
			title: 'Laycan To',
			dataIndex: 'laycanToPlt',
			key: 'laycanTo',
			render: text =>
				columnContent(text, date => <FormattedTimeExtended value={date} />)
		},
		{
			title: 'Discharge Port',
			dataIndex: 'toOrders',
			key: 'toOrders',
			render: (toOrders: boolean, cargoLine: Column) => {
				if (!toOrders) {
					return (
						<OptionalValue
							value={cargoLine.dischargePort && cargoLine.dischargePort.label}
						/>
					);
				}
				/**
				 * dischargeCountry keeps the most actual value
				 * when submit button is not clicked yet (saved on BE side),
				 * and dischargeCountryCode is synchonized just after BE request completion
				 */
				const countryCode =
					(cargoLine.dischargeCountry && cargoLine.dischargeCountry.key) ||
					cargoLine.dischargeCountryCode;
				const country =
					(countryCode && countriesByCode[countryCode]) || undefined;
				return <OptionalValue value={country?.name} />;
			}
		}
	];
	if (operationTypeCode !== OperationTypeCode.LOADING_STS) {
		return columns.filter(item => item.key !== 'loadingFromVessel');
	}
	return columns;
}

export default getCargoLineColumns;
