import { getFinanceBenchmarksData } from './../selectors/financeSelectors';
import { call, put, select, take } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse
} from 'services/api/finance/financeServiceTypes';
import {
	refreshBenchmarkAsync as async,
	retrieveFinance,
	retrieveBenchmarkStatusCycleStart
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { Success } from 'typescript-fsa';
import { openModal, closeModal } from 'store/modals/actions';

const api = Api.Finance.refreshBenchmark;
const commonExecutor = makeDefaultExecutor<
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse,
	Error
>({
	api,
	async,
	*onSuccess({
		params
	}: Success<RefreshBenchmarkRequest, RefreshBenchmarkResponse>) {
		const benchmarkData = yield select(getFinanceBenchmarksData);
		yield put(retrieveFinance({ ...params, benchmarkData, isSilent: true }));
		yield put(retrieveBenchmarkStatusCycleStart());
	}
});

export const executor = function*() {
	const benchmarkData = yield select(getFinanceBenchmarksData);
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	if (benchmarkData?.items.length !== 0) {
		yield put(
			openModal({
				name: 'confirmFinanceBenchmarkRefresh',
				type: 'confirm',
				data: {
					title: '',
					description: `Existing benchmark and all comments will be lost. Are you sure you want to refresh it?`,
					okText: 'Yes',
					cancelText: 'No'
				}
			})
		);
		const { payload } = yield take(closeModal.type);
		if (payload.isConfirmed) {
			yield call(commonExecutor, {
				portCallId,
				jobCode
			});
		}
		return;
	}

	yield call(commonExecutor, {
		portCallId,
		jobCode
	});
};

export default makeTakeEveryWatcher<
	RefreshBenchmarkRequest,
	RefreshBenchmarkResponse,
	Error
>(
	{
		api,
		async
	},
	executor
);
