import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	retrieveOperations as action,
	retrieveOperationsAsync as async
} from '../actions/retrieveOperations';
import { RetrieveOperationsRequest } from 'services/api/operations/operationsServiceTypes';

import { Action } from 'typescript-fsa';

export function* retrieveOperationsWorker(
	retrieval: Action<RetrieveOperationsRequest>
): SagaIterator {
	const request: RetrieveOperationsRequest = retrieval.payload;

	yield put(async.started(request));
	try {
		const response = yield call(Api.Operations.retrieveOperations, request);

		yield put(
			async.done({
				result: response.data.elements,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
}

export function* retrieveOperationsWatcher(): SagaIterator {
	yield takeLatest(action.type, retrieveOperationsWorker);
}
