import {
	SupplierInvoiceRow,
	SupplierInvoiceActionCode
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { getEntityMetadataActions } from 'store/metadata/utils/metadataUtils';
import { menuItemMap } from './routes/SupplierInvoicesListRoute/SupplierInvoiceTable/columns/SupplierInvoiceSettingsButton/SupplierInvoiceSettingsButtonConsts';

export const getIsSupplierInvoiceSettingsIconVisible = (
	supplierInvoice: SupplierInvoiceRow
) => {
	const supplierInvoiceMetadata = getEntityMetadataActions<
		SupplierInvoiceActionCode
	>(supplierInvoice.metadata.actions, Object.keys(
		menuItemMap
	) as SupplierInvoiceActionCode[]);
	return supplierInvoiceMetadata.length > 0 || supplierInvoice.isCancelled;
};

export const isShowSupplierInvoiceSettingsColumn = (
	supplierInvoices: SupplierInvoiceRow[] = []
) =>
	supplierInvoices.some(supplierInvoice =>
		getIsSupplierInvoiceSettingsIconVisible(supplierInvoice)
	);
