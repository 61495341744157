import {
	trackVP,
	trackVPAsync,
	retrieveVP,
	startTrackVP,
	startTrackVPFromFinance,
	retrieveVPAsync,
	commitVPAsync,
	startCommitVP,
	commitVP
} from 'store/vesselProgramme/actions';
import Api from 'services/api';
import { Action, ActionCreator, AsyncActionCreators } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import {
	takeEvery,
	put,
	race,
	take,
	select,
	call,
	fork,
	cancel
} from 'redux-saga/effects';
import {
	addVPAlertToPendingResolveList,
	removeVPAlertFromPendingList
} from '../actions/pendingAlerts';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { notify } from 'store/notifications/actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getPortCallAlertByMetricCode } from 'store/portCall/selectors';
import { AlertMetricCode } from 'app-constants';
import { Alert } from 'app-types';
import {
	vesselProgrammePendingAlertsPolling,
	AlertResolveStatus
} from './pollingVPAlertsSaga';
import { changeRoute } from 'store/route/actions';
import {
	getActivePortJobCode,
	getIsOperationsDisabled
} from 'store/portJobs/portJobsSelectors';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { retrieveFinance } from 'store/finance';

function* submitAlert(
	action: ActionCreator<string>,
	asyncAction: AsyncActionCreators<string, null, Error>,
	metricCode: AlertMetricCode,
	successMessage: string,
	errorMessage = 'Alert was not resolved.'
) {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const isOperationDisabled = yield select(getIsOperationsDisabled);
	const { alertId }: Alert = yield select(
		getPortCallAlertByMetricCode,
		metricCode
	);
	yield put(action(portCallId));
	yield put(addVPAlertToPendingResolveList(alertId));
	const { failed }: { failed: Action<string> } = yield race({
		_: take(asyncAction.done),
		failed: take(asyncAction.failed)
	});
	if (failed) {
		yield put(notify.warning(errorMessage));
		yield put(removeVPAlertFromPendingList(alertId));
		return;
	}
	const pollingResult: AlertResolveStatus = yield call(
		vesselProgrammePendingAlertsPolling,
		portCallId,
		alertId
	);
	if (pollingResult === AlertResolveStatus.RESOLVED) {
		yield put(notify.success(successMessage)); // for max tries we dont show the message as it is edge case and we do not know alert was resolved
	}
	yield put(
		retrieveVP({
			portCallId
		})
	);
	yield race({
		done: take(retrieveVPAsync.done),
		failed: take(retrieveVPAsync.failed)
	});

	yield put(removeVPAlertFromPendingList(alertId));

	if (isOperationDisabled) {
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS],
				isSilent: true
			})
		);
	}
}

export const trackVPWatcher = makeTakeLatestWatcher<string, null, Error>({
	api: Api.VesselProgramme.trackVP,
	async: trackVPAsync
});
export const commitVPWatcher = makeTakeLatestWatcher<string, null, Error>({
	api: Api.VesselProgramme.commitVP,
	async: commitVPAsync
});

export function* trackVPFlowWatcher(): SagaIterator {
	yield takeEvery(startTrackVP, function* worker() {
		const task = yield fork(
			submitAlert,
			trackVP,
			trackVPAsync,
			AlertMetricCode.VESSEL_PROGRAMME_ON_TRACK,
			'You have successfully confirmed your vessel programme.'
		);
		yield take(changeRoute);
		yield cancel(task);
	});
	yield takeEvery(startTrackVPFromFinance, function* worker() {
		const task = yield fork(
			submitAlert,
			trackVP,
			trackVPAsync,
			AlertMetricCode.VESSEL_PROGRAMME_ON_TRACK,
			'You have successfully confirmed your programme.'
		);
		yield take(changeRoute);
		yield cancel(task);
	});
}

export function* commitVpFlowWatcher(): SagaIterator {
	yield takeEvery(startCommitVP, function* worker() {
		const task = yield fork(
			submitAlert,
			commitVP,
			commitVPAsync,
			AlertMetricCode.VESSEL_PROGRAMME_COMMIT,
			'You have successfully commited your vessel programme'
		);
		yield take(changeRoute);
		yield cancel(task);
	});
}
