import React from 'react';
import { Line } from 'react-konva';
import Konva from 'konva';
import { LineNode } from '../ImageViewerV2Types';
import TransformerComponent from './TransformerComponent';

interface LineComponentProps {
	shapeProps: LineNode;
	isSelected: boolean;
	scale: number;
	isEditing: boolean;
	onChange: (newAttr: LineNode) => void;
	onSelect: () => void;
	onDelete: () => void;
}

const LineComponent = ({
	shapeProps,
	isSelected,
	scale,
	onSelect,
	onChange,
	onDelete,
	isEditing
}: LineComponentProps) => {
	const shapeRef = React.useRef<Konva.Line>(null);

	const onMouseEnter = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		if (!isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'pointer';
		}
	};

	const onMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'default';
		}
	};

	const onMouseClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		onSelect();
	};

	return (
		<React.Fragment>
			<Line
				x={shapeProps.x * scale}
				y={shapeProps.y * scale}
				scale={{ x: scale, y: scale }}
				scaleX={scale}
				scaleY={scale}
				points={shapeProps.points}
				stroke={shapeProps.color}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				strokeWidth={5}
				rotation={shapeProps.rotation}
				onClick={onMouseClick}
				onTap={onMouseClick}
				ref={shapeRef}
				draggable={isSelected}
				strokeScaleEnabled={false}
				onDragEnd={e => {
					onChange({
						...shapeProps,
						x: e.target.x() / scale,
						y: e.target.y() / scale
					});
				}}
				onTransformEnd={_e => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = shapeRef.current;
					if (node) {
						// we will reset it back
						node.scaleX(1);
						node.scaleY(1);
						onChange({
							...shapeProps,
							x: node.x() / scale,
							y: node.y() / scale,
							rotation: node.rotation()
						});
					}
				}}
			/>
			{isSelected && (
				<TransformerComponent
					isSelected={isSelected}
					shapeRef={shapeRef}
					onDelete={onDelete}
					resizeEnabled={false}
				/>
			)}
		</React.Fragment>
	);
};

export default LineComponent;
