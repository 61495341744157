import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	EntityPermissionRequest,
	EntityPermissionResponse
} from 'services/api/permissions/permissionsServiceTypes';
import { retrieveEntityPermissionsForPortCallAsync } from '../actions/retrieveEntityPermissionsForPortCall';

export default makeTakeEveryWatcher<
	EntityPermissionRequest,
	EntityPermissionResponse,
	Error
>({
	api: Api.Permissions.retrieveEntityPermissionsForPortCall,
	async: retrieveEntityPermissionsForPortCallAsync
});
