import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveThreadsMainPrincipalTagsAsync } from 'store/threads/actions';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse
} from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Threads.retrieveThreadsMainPrincipalTags;

export const retrieveThreadsMainPrincipalTagsWatcher = makeTakeLatestWatcher<
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadTagsResponse,
	Error
>({
	api: apiCall,
	async: retrieveThreadsMainPrincipalTagsAsync
});

export default retrieveThreadsMainPrincipalTagsWatcher;
