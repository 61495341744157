import { createSelector } from 'reselect';
import { getTimezoneOffset } from 'utils/formatDate';
import { AppState } from 'store-types.d';
import { IDropdownOption } from 'app-types';
import { keys, omit, sortBy } from 'lodash';
import Timezones from 'utils/timezone.json';
import { getIsLoading } from 'store/selectors';
import moment from 'moment-timezone';

/**
 * At the moment there is no trusted way to get official timezones with moment-timezone
 * (moment.tz.names() returns a lot of old names which we don't want,
 * using regex to filter them out isn't safe enough).
 * That's why we use static JSON file with all the timezones user can choose.
 *
 * However this is a known issue / feature request so once it's supported we should
 * change implementation and use moment-timezone directly:
 * https://github.com/moment/moment-timezone/issues/227
 */
const getTimezones = () => Timezones;

export const getInvitationToken = (state: AppState) =>
	state.registration.invitationToken;

export const getRegistrationUserInitialValues = (state: AppState) =>
	state.registration.initialValues;

/**
 * Transform the original Timezones array into a format readeable for the Dropdown component
 * @param  {array} getTimezones - Array of all the world timezones
 * @return {array}              - Array of timezone objects
 * @example
 * {
 * 	value: 'String',
 * 	label: 'String'
 * }
 */
export const getTimezonesOptionsSelector = createSelector(
	getTimezones,
	(timezones): IDropdownOption[] =>
		sortBy(
			keys(timezones).map(label => ({
				value: timezones[label],
				// the time zone label with actual UTC offset
				// (DST is being considered)
				label: `(GMT${getTimezoneOffset(timezones[label])})${label.slice(11)}`,
				utcOffset: moment.tz(timezones[label]).utcOffset()
			})),
			['utcOffset', 'label']
		).map(item => omit(item, 'utcOffset'))
);

export const getPasswordValidationRules = (state: AppState) =>
	getRegistrationUserInitialValues(state).passwordValidationRules;

export const getIsRetrieveingUserByToken = (state: AppState) =>
	getIsLoading(state.registration.fetchStatuses.retrieving);
