import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { PageHeader, Filters } from 'components';
import { Row, Col } from 'components/antd';
import { Justify } from 'components/types';
import { PermissionButton } from 'components/Permission';
import { resetGroups } from 'store/groups/actions';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import GroupsFilters from './GroupsFilters/GroupsFilters';

import { useGroupsModals } from '../GroupsModals/GroupsModals.hooks';
import { getGroupsFilters } from 'store/groups/selectors';
import { GROUPS_FILTER_NAME } from 'store/groups/filtersSync';
import { AppState } from 'store-types';
import { getFiltersResultByKey } from 'store/filters/filtersSelectors';
import { groupsFiltersTypeMap } from '../GroupsConstants';

const GroupsHeader: FC<{}> = () => {
	const dispatch = useDispatch();
	const { showCreateGroupModal } = useGroupsModals();
	const filters = useSelector(
		(state: AppState) => getGroupsFilters(state, GROUPS_FILTER_NAME),
		shallowEqual
	);

	const filterResults = useSelector((state: AppState) =>
		getFiltersResultByKey(state, GROUPS_FILTER_NAME)
	);

	const filtersResult = filterResults.filter(filter => filter.key !== 'All');

	const onAddNewGroupClick = useCallback(() => {
		showCreateGroupModal();
	}, [showCreateGroupModal]);

	const onFiltersChange = useCallback(() => {
		dispatch(resetGroups());
	}, [dispatch]);

	return (
		<PageHeader stretch shadowed>
			<Filters
				name={GROUPS_FILTER_NAME}
				invokeOnChange={onFiltersChange}
				destroyOnUnmount={false}
			>
				{props => (
					<>
						<Row>
							<Col sm={9} xs={12}>
								<GroupsFilters {...props} filters={filters} />
							</Col>
							<Col sm={3} xs={12} justify={Justify.END}>
								<div>
									<PermissionButton
										permissionCode={PermissionCode.MANAGE_GROUP}
										type="primary"
										className="qaa_add_new_group_button"
										onClick={onAddNewGroupClick}
										tooltip={{ placement: 'bottomRight' }}
									>
										Add New Group
									</PermissionButton>
								</div>
							</Col>
						</Row>
						<Filters.Tags
							tags={filtersResult}
							clearOne={props.clearOne}
							clearAll={props.clearAll}
							typeMap={groupsFiltersTypeMap}
						/>
					</>
				)}
			</Filters>
		</PageHeader>
	);
};

export default GroupsHeader;
