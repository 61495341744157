import {
	RetrieveConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData
} from 'services/api/companies/companiesServiceTypes';
import { retrieveCompanyBenchmarkAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	RetrieveConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData,
	Error
>({
	api: Api.Companies.retrieveBenchmarkConfiguration,
	async: retrieveCompanyBenchmarkAsync
});
