import React from 'react';

class CursorLoader extends React.Component<{ loading: boolean }> {
	setCursorStyle = () => {
		document.body.style.cursor = this.props.loading ? 'wait' : 'auto';
	};
	componentDidUpdate() {
		this.setCursorStyle();
	}
	componentDidMount() {
		this.setCursorStyle();
	}
	componentWillUnmount() {
		document.body.style.cursor = 'auto';
	}
	render() {
		return <>{this.props.children}</>;
	}
}
export default CursorLoader;
