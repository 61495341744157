import actionCreator from '../supplierInvoiceActionCreator';
import { SupplierInvoiceBase } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

const SET_ACTIVE_SUPPLIER_INVOICE_ID = 'SET_ACTIVE_SUPPLIER_INVOICE_ID';
export const setActiveSupplierInvoiceId = actionCreator<
	SupplierInvoiceBase['id']
>(SET_ACTIVE_SUPPLIER_INVOICE_ID);

const RESET_ACTIVE_SUPPLIER_INVOICE_ID = 'RESET_ACTIVE_SUPPLIER_INVOICE_ID';
export const resetActiveSupplierInvoiceId = actionCreator(
	RESET_ACTIVE_SUPPLIER_INVOICE_ID
);
