import React from 'react';
import WithMinus, { MinusPosition } from 'components/WithMinus/WithMinus';
import { getFormattedValue } from './Amount.func';

export type FormatType = 'default' | 'rounding' | 'finance';

export interface AmountProps {
	value: string | number;
	decimalPart?: number;
	/* unit of measurement code */
	uomCode?: string;
	/* if show uomCode inside brackets or not - default value = false */
	brackets?: boolean;
	negative?: boolean;
	formatType?: FormatType;
	signed?: boolean;
}
const Amount = ({
	value,
	uomCode = '',
	brackets = false,
	formatType,
	decimalPart,
	negative = false,
	signed = false
}: AmountProps) => {
	const formattedValue = getFormattedValue(
		value,
		formatType,
		decimalPart,
		signed
	);
	const formattedUomCode =
		Boolean(uomCode) && brackets ? `(${uomCode})` : uomCode;
	return (
		<WithMinus position={negative ? MinusPosition.BEFORE : undefined}>
			<span data-qa="amount">{formattedValue}</span>
			{formattedUomCode && (
				<span data-qa="currency">&nbsp;{formattedUomCode}</span>
			)}
		</WithMinus>
	);
};

export default Amount;
