import {
	retrieveMessageData as action,
	retrieveMessageDataAsync as asyncAction
} from 'store/thread/actions/retrieveMessageData';
import { RetrieveMessageDataRequest } from 'services/api/threads/threadsServiceTypes';

import {
	retrieveThreadMessageById,
	retrieveThreadMessageByIdAsync,
	retrieveThreadById,
	retrieveThreadByIdAsync
} from 'store/thread/actions';
import {
	retrievePortCall,
	retrievePortCallAsync
} from 'store/portcalls/actions';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, take, put } from 'redux-saga/effects';

export function* executor(request: RetrieveMessageDataRequest): SagaIterator {
	yield put(asyncAction.started(request));
	try {
		const { threadId, messageId, portCallId } = request;
		if (portCallId) {
			yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
			yield take([retrievePortCallAsync.done, retrievePortCallAsync.failed]);
		}
		if (threadId && messageId) {
			yield put(retrieveThreadById({ threadId }));
			yield put(
				retrieveThreadMessageById({
					threadId,
					messageId,
					params: { expand: 'contentHistory' }
				})
			);
			yield all([
				take([retrieveThreadByIdAsync.done, retrieveThreadByIdAsync.failed]),
				take([
					retrieveThreadMessageByIdAsync.done,
					retrieveThreadMessageByIdAsync.failed
				])
			]);
		}

		yield put(
			asyncAction.done({ result: null, params: request, response: null })
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params: request
			})
		);
	}
}

export function* worker({
	payload
}: Action<RetrieveMessageDataRequest>): SagaIterator {
	yield call(executor, payload);
}

export default function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}
