import { map } from 'lodash';
import { PortCallSection } from 'services/api/portCall/portCallServiceTypes';
import { PortCallEventIdsBySectionCode } from '../../portCallOperationsState';
import { portCallSectionKey } from '../../portCallOperationsUtils';
const getEventIdsBySectionId = (
	sections: PortCallSection[]
): PortCallEventIdsBySectionCode =>
	sections.reduce(
		(sectionsMap, section) => ({
			...sectionsMap,
			[portCallSectionKey({ id: section.id, code: section.code })]: map(
				section.elements || [],
				'id'
			),
			...getEventIdsBySectionId(section.sections || [])
		}),
		{}
	);

export default getEventIdsBySectionId;
