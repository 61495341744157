import React from 'react';
import { Loading } from 'components';
import styles from '../DocumentPageViewerV2.module.scss';
import ImageViewer from './ImageViewer/ImageViewerV2';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';
import { PageType } from 'store/finance/constants';

export interface DocumentPageProps {
	imageUrl: string;
	fileName?: string;
	isAnnotatable: boolean;
	activePageType: PageType;
	onSaveAnnotation: (request: UploadCustomRequest) => void;
}

const DocumentPage: React.FC<DocumentPageProps> = ({
	imageUrl,
	fileName,
	isAnnotatable,
	activePageType,
	onSaveAnnotation
}) => {
	const imageUrlAvailable = !!imageUrl;
	return (
		<>
			{imageUrlAvailable ? (
				<ImageViewer
					imageUrl={imageUrl}
					fileName={fileName}
					isAnnotatable={isAnnotatable}
					onSaveAnnotation={onSaveAnnotation}
					activePageType={activePageType}
				>
					<img src={imageUrl} alt="preview" className={styles.fullWidth} />
				</ImageViewer>
			) : (
				<Loading size="lg" />
			)}
		</>
	);
};

export default DocumentPage;
