import React from 'react';
import { Select } from 'components/antd';

import styles from '../ImageViewerV2.module.scss';

interface FontSizeDropdownProps {
	fontSize: number;
	setFontSize: (value: number) => void;
}

const FontSizeDropdown: React.FC<FontSizeDropdownProps> = ({
	fontSize,
	setFontSize
}) => {
	const fontSizes = [
		8,
		9,
		10,
		11,
		12,
		13,
		14,
		15,
		16,
		18,
		20,
		22,
		24,
		26,
		36,
		72
	];

	return (
		<Select
			size="small"
			className={styles.select}
			defaultValue={fontSize}
			onChange={(value: number) => setFontSize(value)}
			showSearch
			filterOption
		>
			{fontSizes.map((font, index) => (
				<Select.Option key={index} value={font}>
					{`${font} px`}
				</Select.Option>
			))}
		</Select>
	);
};

export default FontSizeDropdown;
