import React from 'react';

interface ErrorCorrelationIdProps {
	correlationId?: string;
}

const ErrorCorrelationId = ({ correlationId }: ErrorCorrelationIdProps) => {
	if (!correlationId) {
		return null;
	}
	return (
		<>
			<br />
			<span>Correlation Id: {correlationId}</span>
		</>
	);
};

export default ErrorCorrelationId;
