import { select, put } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	ConvertDBStatusRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import {
	convertToRegularAsync as async,
	closeConvertDBStatusModal
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.convertToRegular;

export default makeTakeEveryWatcher<
	ConvertDBStatusRequest,
	PortJobService,
	Error
>({
	api,
	async,
	*getApiParams(params: ConvertDBStatusRequest) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: ConvertDBStatusRequest = {
			portCallId,
			jobCode,
			...params
		};
		return request;
	},
	*onError() {
		yield put(closeConvertDBStatusModal());
	},
	*onSuccess() {
		yield put(closeConvertDBStatusModal());
	}
});
