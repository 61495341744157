import { Success } from 'typescript-fsa';
import { ConfigurationState } from '../configurationState';
import {
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrievePrincipalIntegrationSettingsSuccess = (
	state: ConfigurationState,
	{
		result
	}: Success<
		RetrievePrincipalIntegrationSettingsRequest,
		PrincipalIntegrationSettings
	>
) => ({
	...state,
	principalIntegrationSettings: result
});

export const onRetrievePrincipalIntegrationSettings = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings
>('fetchStatuses', 'retrievePrincipalIntegrationSettings', {
	doneReducer: onRetrievePrincipalIntegrationSettingsSuccess
});
