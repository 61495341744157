import React from 'react';
import { Gap } from 'components';
import styles from './CargoLinkage.module.scss';
import { Col, Row } from 'components/antd';
import CargoRow from './CargoRow/CargoRow';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import {
	getSplittedOperationCargos,
	getSortedUnlinkedAppointmentCargos
} from './Cargos.func';

interface CargosContainerProps {
	nominationCargos: LinkedCargo[];
	appointmentCargos: LinkedCargo[];
	unlinkedAppointmentCargos: LinkedCargo[];
	onDelete: (id: string) => void;
}

function CargosContainer({
	nominationCargos,
	appointmentCargos,
	unlinkedAppointmentCargos,
	onDelete
}: CargosContainerProps) {
	const [
		sortedNominationCargos,
		sortedAppointmentCargos
	] = getSplittedOperationCargos(nominationCargos, appointmentCargos);
	const sortedUnlinkedAppointmentCargos = getSortedUnlinkedAppointmentCargos(
		unlinkedAppointmentCargos
	);
	return (
		<Gap isBlock left="lg" top="sm">
			<Row className={styles.rowHeader}>
				<Col sm={6}>
					<span className={styles.subHeader}>Nomination Cargoes</span>
				</Col>
				<Col sm={6}>
					<span className={styles.subHeader}>Appointment Cargoes</span>
				</Col>
			</Row>
			{sortedNominationCargos.map((operationCargos, index) =>
				!operationCargos.length ? null : (
					<CargoRow
						key={index}
						nominationOperationCargos={operationCargos}
						appointmentOperationCargos={sortedAppointmentCargos[index]}
						unlinkedAppointmentCargos={sortedUnlinkedAppointmentCargos[index]}
						onDelete={onDelete}
					/>
				)
			)}
		</Gap>
	);
}

export default CargosContainer;
