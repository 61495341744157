import { AppState } from 'store-types';
import { find, toString, flatMap, times } from 'lodash';
import { createSelector } from 'reselect';
import { getValues, getErrorByFieldId } from 'store/form/selectors';
import { getActiveSupplierInvoice } from 'store/supplierInvoice/selectors/activeSupplierInvoiceSelectors';
import {
	SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID,
	FormData,
	FormField,
	PortJobFormData,
	PortJobServicesPageData,
	PortJobError
} from '../supplierInvoiceContextualizationFormTypes';

export const getContextualizationFormValues = (state: AppState): FormData => {
	return getValues<FormData>(state, SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID);
};

export const getContextualizationPortJobsFormErrors = (
	state: AppState
): PortJobError[] | undefined =>
	getErrorByFieldId(
		state,
		SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID,
		FormField.PORT_JOBS
	);

export const getIsAnyContextualizationPortJobInvalid = createSelector(
	getContextualizationPortJobsFormErrors,
	portJobsErrors =>
		!!(
			portJobsErrors?.length &&
			portJobsErrors.some(
				({ services }: { services: PortJobError['services'] }) =>
					services?.length &&
					services.some(service => service?.totalAmount?.length)
			)
		)
);

export const getIsSupplierSelectDisabled = createSelector(
	getContextualizationFormValues,
	values => {
		const principal = values[FormField.MAIN_PRINCIPAL_COMPANY];
		return !principal || !principal.key;
	}
);

export const getIsCreditInvoice = createSelector(
	getContextualizationFormValues,
	({ isCredit }) => isCredit
);

export const getPortJobsFormValues = createSelector(
	getContextualizationFormValues,
	({ portJobs }) => portJobs
);

export const isPortJobServiceSelected = (
	portJobs: PortJobFormData[],
	portJobId: string,
	serviceId: string
) => {
	const portJob = find(portJobs, ({ id }) => id === portJobId);
	const service =
		portJob && find(portJob.services, ({ id }) => id === serviceId);
	return service?.isSelected || false;
};

export const getPagesFormValues = createSelector(
	getContextualizationFormValues,
	({ pages }) => pages
);

export const isPortJobServiceInPageSelected = (
	pages: PortJobServicesPageData[],
	portJobId: string,
	serviceId: string,
	activePage: number
) => {
	const portJobs = pages[activePage].portJobs;
	return isPortJobServiceSelected(portJobs, portJobId, serviceId);
};

export const isInitialPortJobServiceInPageSelected = (
	portJobs: PortJobFormData[],
	portJobId: string,
	serviceId: string,
	activePage: number
): boolean => {
	const portJob = find(portJobs, ({ id }) => id === portJobId);
	const service =
		portJob && find(portJob.services, ({ id }) => id === serviceId);
	return (
		(service?.isSelected &&
			!!service?.pages?.find(element => element === activePage + 1)) ||
		false
	);
};

export const getIsPortJobServiceSelectedForPage = (
	state: AppState,
	portJobId: string,
	serviceId: string,
	activePage: number
) => {
	const pages = getPagesFormValues(state);
	return isPortJobServiceInPageSelected(
		pages,
		portJobId,
		serviceId,
		activePage
	);
};

export const getIsPortJobServiceSelected = (
	state: AppState,
	portJobId: string,
	serviceId: string
) => {
	const portJobs = getPortJobsFormValues(state);
	return isPortJobServiceSelected(portJobs, portJobId, serviceId);
};

export const getSupplierInvoiceContextualizationInitialValues = createSelector(
	getActiveSupplierInvoice,
	(
		_state: AppState,
		{ isTriggeredViaPostCredit }: { isTriggeredViaPostCredit?: boolean }
	) => isTriggeredViaPostCredit,
	(supplierInvoice, isTriggeredViaPostCredit): Partial<FormData> => {
		if (!supplierInvoice) {
			return {
				[FormField.IS_CREDIT]: false
			};
		}

		const jobs = supplierInvoice.contextualizations.elements || [];

		return {
			isCredit: supplierInvoice.isCredit || isTriggeredViaPostCredit,
			mainPrincipalCompany: {
				key: supplierInvoice.mainPrincipalCompany.id,
				label: supplierInvoice.mainPrincipalCompany.name
			},
			supplierCompany: {
				label: supplierInvoice.supplierCompany.name,
				key: supplierInvoice.supplierCompany.id
			},
			invoiceDate: supplierInvoice.invoiceDate,
			receiptDate: supplierInvoice.receiptDate,
			referenceNumber: supplierInvoice.referenceNumber,
			currencyCode: {
				label: supplierInvoice.currencyCode,
				key: supplierInvoice.currencyCode
			},
			total: toString(supplierInvoice.total),
			discount: toString(supplierInvoice.discount),
			comment: supplierInvoice.comment,
			portJobs: jobs.map(job => ({
				id: job.id,
				services: job.services.map(service => ({
					id: service.id,
					totalAmount: toString(service.totalAmount),
					serviceComment: service.comment,
					pages: [...service.pages],
					isSelected: true
				}))
			}))
		};
	}
);

export const getCheckedServicesById = createSelector(
	getPagesFormValues,
	pages => {
		const servicesList = flatMap(pages, page =>
			flatMap(page.portJobs, portJob => portJob.services)
		);

		return servicesList.reduce<{ [id: string]: number }>((result, service) => {
			if (service.isSelected) {
				result[service.id] = (result[service.id] || 0) + 1;
			}
			return result;
		}, {});
	}
);

export const getIsAnyServiceSelectedInPage = createSelector(
	getPagesFormValues,
	(_state: AppState, activePage: number) => activePage,
	(pages, activePage) => {
		if (!pages || !pages[activePage - 1]) {
			return false;
		}
		const page = pages[activePage - 1];
		return !!(
			page?.portJobs?.length &&
			page.portJobs.some(
				portJob =>
					portJob?.services?.length &&
					portJob.services.some(service => service?.isSelected)
			)
		);
	}
);

export const getPageWizardInitialStateForUpdate = createSelector(
	getPagesFormValues,
	(state: AppState) => state,
	(pages, state) => {
		if (!pages.length) {
			return {};
		}
		return times(pages.length).reduce((acc, pageNumber) => {
			const index = pageNumber + 1;
			acc[index] = { valid: getIsAnyServiceSelectedInPage(state, index) };
			return acc;
		}, {});
	}
);

export const getPageCountServiceSelected = createSelector(
	getPagesFormValues,
	(_state: AppState, serviceId: string) => serviceId,
	(pages, serviceId) => {
		let count = 0;
		pages.forEach(page => {
			if (page?.portJobs?.length) {
				page.portJobs.forEach(portJob => {
					if (portJob?.services?.length) {
						portJob.services.forEach(service => {
							if (service.id === serviceId && service.isSelected) {
								count++;
							}
						});
					}
				});
			}
		});
		return count;
	}
);
