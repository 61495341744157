import {
	UpdateFundingRequest as Request,
	UpdateFundingResponse as Response,
	Funding
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'UPDATE_FUNDING';

export const updateFundingAsync = actionCreator.async<Request, Response, Error>(
	ACTION_NAME,
	{
		behaviour: { type: 'react-to-changes-child' }
	}
);

export const updateFunding = actionCreator<Funding>(ACTION_NAME);
