import { PortCallMeasurementsState as State } from '../portCallMeasurementsState';
import {
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrievePortCallMeasurementsAccessSuccess = (
	state: State,
	action: Success<
		RetrievePortCallMeasurementsAccessRequest,
		MeasurementsAccess[]
	>
): State => ({
	...state,
	access: {
		...state.access,
		[action.params.portCallId]: action.result
	}
});

export const onRetrievePortCallMeasurementsAccess = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	RetrievePortCallMeasurementsAccessRequest,
	MeasurementsAccess[]
>('fetchStatuses', 'access', {
	doneReducer: onRetrievePortCallMeasurementsAccessSuccess
});
