import { ButtonProps } from 'components/antd/Button/Button';
import { InjectedWizardFormProps } from 'components/WizardForm';

export enum PaginationState {
	DEFAULT = 'default',
	ENABLED = 'enabled',
	NONE = 'none'
}

export interface DocumentViewerPaginationProps
	extends Pick<
			InjectedWizardFormProps,
			| 'prevPage'
			| 'nextPage'
			| 'activePage'
			| 'activePageState'
			| 'total'
			| 'setActivePage'
			| 'activePageFirst'
			| 'activePageLast'
		>,
		Pick<
			DocumentViewerAndAsideCommonProps,
			'pagination' | 'okButtonProps' | 'cancelButtonProps'
		> {}

export interface DocumentViewerAndAsideCommonProps {
	onFinish: () => void;
	loading: boolean;
	/** Should be true when the document is .pdf */
	isDocumentPDF: boolean;
	/** Uses the default Browser preview */
	preventSplit?: boolean;
	/** Toogle navigation between pages control on/off */
	pagination?: PaginationState;
	/** Toogle disable state of okButton */
	canSubmit?: boolean;
	hint?: React.ReactNode;
	okButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
	fileUrl?: string;
}
