import * as React from 'react';
import { map } from 'lodash';
import {
	Message,
	MessageContentHistoryContext
} from 'services/api/messages/messagesServiceTypes';
import { ComposeMessageFrom } from '../ComposeMessageTypes';

// TODO: Find out what's happening here
import ReactDOMServer from 'react-dom/server';
import HistoryHeader from 'sections/ComposeMessage/components/HistoryHeader/HistoryHeader';

const getContentHistoryHeader = (
	from: ComposeMessageFrom,
	message: Message
) => {
	const lastMessageFields: MessageContentHistoryContext = {
		from: message.from.email || from.defaultMailboxAddress || from.email,
		to: map(message.to, 'email'),
		cc: map(message.cc, 'email'),
		subject: message.subject,
		content: message.content
	};

	// we do not need to sanitize Html here since it will be done by the RTE
	const historyContent = lastMessageFields.content
		? `<hr />${lastMessageFields.content}`
		: '';

	return (
		ReactDOMServer.renderToStaticMarkup(
			<HistoryHeader data={lastMessageFields} />
		) + historyContent
	);
};

export default getContentHistoryHeader;
