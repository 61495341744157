import * as React from 'react';
import { ScrollableLayout, PageHeader } from 'components';
import { navigateTo } from 'utils';
import BrowserDocumentPreviewContainer from './BrowserDocumentPreview/BrowserDocumentPreviewContainer';
import { DocumentBrowserViewerCommonProps } from './DocumentBrowserViewerTypes';
import { DocumentViewerAside } from 'sections/DocumentViewer';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';

interface DocumentBrowserPageViewerProps
	extends DocumentBrowserViewerCommonProps {
	onFinish?: () => void;
}

export default class DocumentBrowserPageViewer extends React.Component<
	DocumentBrowserPageViewerProps
> {
	onClose = () => {
		navigateTo(this.props.returnUrl);
	};

	onFinish = () => {
		if (this.props.onFinish) {
			this.props.onFinish();
		}
		this.onClose();
	};

	public render() {
		const { fileDocument, documentName, returnUrl } = this.props;
		const title = this.props.fileDocument
			? this.props.fileDocument.name || documentName
			: '';

		return (
			<ScrollableLayout stretch direction="horizontal">
				<PageHeader.NewTab onClose={this.onClose}>{title}</PageHeader.NewTab>
				<ScrollableLayout>
					<BrowserDocumentPreviewContainer
						fileDocument={fileDocument}
						returnUrl={returnUrl}
					/>
					<DocumentViewerAside
						pagination={PaginationState.NONE}
						canSubmit={true}
						loading={false}
						isDocumentPDF={true}
						onFinish={this.onFinish}
						onCancel={this.onClose}
						okButtonProps={{
							children: 'Download All',
							type: 'primary',
							icon: 'download',
							ghost: true
						}}
						cancelButtonProps={{
							children: 'Close'
						}}
					>
						{this.props.children}
					</DocumentViewerAside>
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}
