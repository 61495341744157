import {
	removeFetchFile,
	addFetchFile
} from 'store/documents/documentsHelpers';
import { DocumentWithOriginalFile } from 'services/api/documents/documentsServiceTypes';
import { DocumentsState } from 'store/documents/documentsState';
import { Success } from 'typescript-fsa';
import { omit, unionBy } from 'lodash';

export function onBeforeUploadAttachment(
	state: DocumentsState
): DocumentsState {
	return {
		...state,
		filesFetchingNumber: addFetchFile(state.filesFetchingNumber)
	};
}

export function onSaveAttachmentStarted(
	state: DocumentsState,
	payload: DocumentWithOriginalFile
): DocumentsState {
	return {
		...state,
		upload: {
			...state.upload,
			file: payload.originFileObj
		}
	};
}

export function onSaveAttachmentDone(
	state: DocumentsState,
	action: Success<{}, DocumentWithOriginalFile>
): DocumentsState {
	return {
		...state,
		filesFetchingNumber: removeFetchFile(state.filesFetchingNumber),
		attachments: unionBy(state.attachments, [action.result], 'id'),
		byId: omit(state.byId, [action.result.id])
	};
}

export function onSaveAttachmentFailed(state: DocumentsState): DocumentsState {
	return {
		...state,
		filesFetchingNumber: removeFetchFile(state.filesFetchingNumber)
	};
}
