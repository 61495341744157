import { flow } from 'lodash';
import { DraftOption } from '../draftsTypes';
import { appendSuffixToDrafts, mergeOptionsWithDrafts } from '../draftsUtils';
const searchDrafts = (value: string) => (drafts: DraftOption[]) => {
	if (!value) {
		return [];
	}
	return drafts.filter(draft =>
		draft.name.toLowerCase().includes(value.toLowerCase())
	);
};

export const getSearchResultsWithDrafts = (
	options: DraftOption[],
	drafts: DraftOption[],
	value: string
): DraftOption[] => {
	if (value === '__') {
		return flow([
			appendSuffixToDrafts,
			mergeOptionsWithDrafts(value ? options : [])
		])(drafts);
	} else {
		return flow([
			searchDrafts(value),
			appendSuffixToDrafts,
			mergeOptionsWithDrafts(value ? options : [])
		])(drafts);
	}
};
