import * as React from 'react';
import classNames from 'classnames';
import styles from './ScrollableLayout.module.scss';

export interface ScrollableLayoutProps {
	id?: string;
	className?: string;
	stretch?: boolean;
	asColumn?: boolean;
	direction?: 'horizontal';
	customRef?: (elem: React.ReactNode) => void;
}

class ScrollableLayout extends React.Component<ScrollableLayoutProps> {
	render() {
		const {
			id,
			className,
			stretch,
			asColumn,
			direction,
			children,
			customRef
		} = this.props;
		return (
			<div
				className={classNames(className, styles.root, {
					[styles.stretch]: stretch,
					[styles.asColumn]: asColumn,
					[styles.horizontal]: direction === 'horizontal'
				})}
				ref={customRef}
				id={id}
			>
				{children}
			</div>
		);
	}
}

export default ScrollableLayout;
