import { ResultCollection } from '../apiTypes';
import queryString from 'query-string';
import {
	AddPortJobRequestPayload,
	AddPortJobResponse,
	PortJobSummaryResponse,
	RetrieveJobParams,
	RetrieveOperationParams,
	PortJobThread,
	AppointerType,
	PortJob,
	CheckPortJobPermissionsRequest,
	PortJobBaseRequest,
	AppointData,
	AcceptanceData,
	ConfirmPortJobParams,
	ChangeCustodyRoleRequest,
	PortJobMetadataActionCode,
	PortJobAvailableGroup,
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse,
	PortJobStatusEntity,
	UpdateSavingsRequest,
	MovetoPDAPendingRequest,
	MovetoCEPendingRequest,
	RequestPDARequest,
	ExtendedMessagingData,
	TogglePortJobStatusParams,
	TogglePortJobResponse,
	UnlinkJobsRequest,
	ExtendedMessagingRequest,
	InviteExtendedMessagingRequest,
	ClonePortJobRequestPayload,
	ToggleOperationsStatusParams,
	ToggleOperationsResponse,
	PrefundingExtendedMessagingData
} from './portJobsServiceTypes';
import client from 'services/client';
import {
	ConfirmPortJobResponse,
	PortJobSummaryRequest,
	AddPortJobOperationRequest,
	EditPortJobOperationRequest,
	DeletePortJobOperationRequest,
	AddPortJobOperationResponse,
	RetrieveJobThreadsRequest,
	PortJobStatusUpdateResponse,
	AppointAgentToPortParams,
	PortJobCustodyRolesRequest,
	PortJobCustodyRolesResponse,
	PortJobOperationTypes,
	EditPortJobResponse,
	UpdatePortJobRequest,
	DeletePortJobRequest,
	DeletePortJobResponse,
	CheckPortJobPermissionsResponse,
	RetrieveAppointerRequest,
	CancelPortJobRequest,
	ReOpenJob,
	UncancelPortJobRequest,
	RetrievePortJobMetadataRequest,
	InviteAgentToPortJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { AxiosTypedPromise } from 'services/api/apiTypes';
import { AxiosPromise } from 'axios';
import { Metadata } from 'app-types';
import { ExpandPortJobType } from '../portCalls/portCallsServiceTypes';
import {
	ServiceSection,
	UpdateServiceSection
} from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';

const retrievePortJob = ({
	portCallId,
	jobCode,
	expand
}: RetrieveJobParams): AxiosPromise<PortJob> => {
	const expandParams = [ExpandPortJobType.METADATA];
	if (expand) {
		expandParams.push(expand);
	}
	return client.get(`/portcalls/${portCallId}/jobs/${jobCode}`, {
		params: {
			expand: expandParams
		},
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});
};

const retrievePortJobOperation = ({
	portCallId,
	jobCode,
	operationId
}: RetrieveOperationParams): AxiosPromise<PortJobOperationTypes> =>
	client.get(
		`/portcalls/${portCallId}/jobs/${jobCode}/operations/${operationId}`
	);

const retrievePortJobStatuses = (): AxiosTypedPromise<PortJobStatusEntity[]> =>
	client.get<PortJobStatusEntity[]>('/portjobs-states');

const confirmPortCallJob = ({
	portCallId,
	jobCode,
	...params
}: ConfirmPortJobParams): AxiosTypedPromise<ConfirmPortJobResponse> =>
	client.put<ConfirmPortJobResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/confirm`,
		params
	);

const acceptPortCallJob = ({
	portCallId,
	jobCode,
	...params
}: ConfirmPortJobParams): AxiosTypedPromise<ConfirmPortJobResponse> =>
	client.put<ConfirmPortJobResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/accept`,
		params
	);

const appointToPortJob = ({
	portCallId,
	jobCode,
	code,
	name,
	...params
}: AppointAgentToPortParams): AxiosTypedPromise<PortJobStatusUpdateResponse> =>
	client.put<PortJobStatusUpdateResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/appoint`,
		{
			custodyAgentRole: {
				code,
				name
			},
			...params
		}
	);

const inviteAgentToPortJob = ({
	portCallId,
	jobCode,
	...params
}: InviteAgentToPortJobParams): AxiosTypedPromise<PortJobStatusUpdateResponse> =>
	client.put<PortJobStatusUpdateResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/invite`,
		{
			...params
		}
	);

const cancelPortJob = ({
	portCallId,
	portJobCode,
	modalId: _modalId,
	...params
}: CancelPortJobRequest) =>
	client.patch(`/portcalls/${portCallId}/jobs/${portJobCode}/cancel`, params);

const uncancelPortJob = ({
	portCallId,
	portJobCode,
	...params
}: UncancelPortJobRequest) =>
	client.patch(`/portcalls/${portCallId}/jobs/${portJobCode}/cancel`, params);

const changeCustodyRole = ({
	portCallId,
	portJobCode,
	modalId: _modalId,
	...params
}: ChangeCustodyRoleRequest) =>
	client.patch(
		`/portcalls/${portCallId}/jobs/${portJobCode}/change-role`,
		params
	);

const movetoPDAPending = ({
	portcallId,
	jobCode,
	...params
}: MovetoPDAPendingRequest) =>
	client.put(
		`/portcalls/${portcallId}/jobs/${jobCode}/revertjob-cetopdapending`,
		params
	);

const movetoCEPending = ({
	portcallId,
	jobCode,
	...params
}: MovetoCEPendingRequest) =>
	client.put(
		`/portcalls/${portcallId}/jobs/${jobCode}/revertjob-datocepending`,
		params
	);

const reopenJob = ({ portcallId, jobCode, ...params }: ReOpenJob) =>
	client.put(
		`/portcalls/${portcallId}/jobs/${jobCode}/portjobstatusupdate`,
		params
	);

const updateSavings = ({
	portCallId,
	portJobCode,
	...params
}: UpdateSavingsRequest) =>
	client.put(
		`/portcalls/${portCallId}/jobs/${portJobCode}/update-savings`,
		params
	);

const retrieveAppointData = ({
	portCallId,
	jobCode
}: PortJobBaseRequest): AxiosTypedPromise<AppointData> =>
	client.get<AppointData>(
		`/portcalls/${portCallId}/jobs/${jobCode}/appoint-data`
	);

const retrieveExtendedMessagingDatawithPayload = ({
	portCallId,
	jobCode,
	mailType,
	...payload
}: ExtendedMessagingRequest): AxiosTypedPromise<ExtendedMessagingData> =>
	client.put<ExtendedMessagingData>(
		`portcalls/${portCallId}/jobs/${jobCode}/${mailType}`,
		payload
	);

const retrieveExtendedMessagingData = ({
	portCallId,
	jobCode,
	mailType
}: InviteExtendedMessagingRequest): AxiosTypedPromise<ExtendedMessagingData> =>
	client.get<ExtendedMessagingData>(
		`portcalls/${portCallId}/jobs/${jobCode}/${mailType}`
	);

const retrievePrefundingExtendedMessagingData = ({
	portCallId,
	jobCode,
	mailType,
	...params
}: PrefundingExtendedMessagingData): AxiosTypedPromise<ExtendedMessagingData> =>
	client.put<ExtendedMessagingData>(
		`portcalls/${portCallId}/jobs/${jobCode}/${mailType}`,
		params
	);

const retrieveAcceptanceData = ({
	portCallId,
	jobCode
}: PortJobBaseRequest): AxiosTypedPromise<AcceptanceData> =>
	client.get<AcceptanceData>(
		`/portcalls/${portCallId}/jobs/${jobCode}/acceptance-data`
	);

const addPortJob = (portCallId: string, request: AddPortJobRequestPayload) => {
	return client.post<AddPortJobResponse>(
		`/portcalls/${portCallId}/jobs`,
		request
	);
};

const addAppointmentFromNomination = (portCallId: string, jobCode: string) => {
	return client.post<AddPortJobResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}`
	);
};

const retrievePortJobSummary = ({
	portCallId,
	jobCode
}: PortJobSummaryRequest): AxiosTypedPromise<PortJobSummaryResponse> =>
	client.get<PortJobSummaryResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/summary`
	);

const addOperationToPortJob = ({
	portCallId,
	jobCode,
	payload
}: AddPortJobOperationRequest) =>
	client.post<AddPortJobOperationResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/operations`,
		payload
	);

const editOperationInPortJob = ({
	portCallId,
	jobCode,
	operationId,
	payload
}: EditPortJobOperationRequest) =>
	client.patch<AddPortJobOperationResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/operations/${operationId}`,
		payload
	);

const updatePortJob = ({
	portCallId,
	jobCode,
	payload
}: UpdatePortJobRequest) =>
	client.patch<EditPortJobResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}`,
		payload
	);

const deletePortJobOperation = ({
	portJobCode,
	portCallId,
	portJobOperationId,
	...params
}: DeletePortJobOperationRequest): AxiosTypedPromise<void> =>
	client.delete(
		`/portcalls/${portCallId}/jobs/${portJobCode}/operations/${portJobOperationId}`,
		{
			params
		}
	);

const retrievePortJobThreads = (
	request: RetrieveJobThreadsRequest
): AxiosTypedPromise<ResultCollection<PortJobThread>> => {
	const { jobCode, ...extraParams } = request;
	const params = !jobCode ? extraParams : request;
	return client.get('/threads', {
		params,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'index' })
	});
};

const retrievePortJobCustodyRoles = ({
	portCallId,
	jobCode
}: PortJobCustodyRolesRequest): AxiosTypedPromise<PortJobCustodyRolesResponse> =>
	client.get(
		`/portcalls/${portCallId}/jobs/${jobCode}/available-custody-roles`
	);

const retrieveAppointers = ({
	mainPrincipalId,
	actionType
}: RetrieveAppointerRequest) => {
	return client.get<ResultCollection<AppointerType>>(
		`/companies/mainprincipals/${mainPrincipalId}/principals?actionType=${actionType}`
	);
};

const deletePortJob = ({
	portCallId,
	jobCode,
	...params
}: DeletePortJobRequest): AxiosTypedPromise<DeletePortJobResponse> =>
	client.delete(`/portcalls/${portCallId}/jobs/${jobCode}`, {
		params
	});

const checkPortJobPermissions = ({
	mainPrincipalId,
	portId,
	vesselId
}: CheckPortJobPermissionsRequest) =>
	client.get<CheckPortJobPermissionsResponse>(`/portcalls/check-permission`, {
		params: {
			companyId: mainPrincipalId,
			portId,
			vesselId
		}
	});

const retrievePortJobMetadata = ({
	portCallId,
	portJobCode
}: RetrievePortJobMetadataRequest): AxiosTypedPromise<Metadata<
	PortJobMetadataActionCode
>> => client.get(`/portcalls/${portCallId}/jobs/${portJobCode}/metadata`);

const retrieveAvailableGroups = ({
	portCallId,
	jobCode
}: PortJobBaseRequest): AxiosTypedPromise<ResultCollection<
	PortJobAvailableGroup
>> =>
	client.get<ResultCollection<PortJobAvailableGroup>>(
		`/portcalls/${portCallId}/jobs/${jobCode}/available-groups`
	);

const setDefaultMessagingGroup = ({
	portCallId,
	jobCode,
	group,
	concurrencyToken
}: SetDefaultMessagingGroupRequest): AxiosTypedPromise<SetDefaultMessagingGroupResponse> =>
	client.patch<SetDefaultMessagingGroupResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/group`,
		{
			groupId: group.id,
			concurrencyToken
		}
	);
const togglePortCallJobStatus = ({
	portCallId,
	jobCode,
	jobStatus,
	...params
}: TogglePortJobStatusParams) =>
	client.patch<TogglePortJobResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/toggleJobStatus`,
		params
	);

const unlinkJobs = ({
	portCallId,
	portJob,
	modalId: _modalId,
	...payload
}: UnlinkJobsRequest) =>
	client.post(
		`/portcalls/${portCallId}/jobs/${portJob}/DeleteLinkedJobs`,
		payload
	);
const requestPDA = ({ portCallId, portJobCode }: RequestPDARequest) =>
	client.patch(
		`portcalls/${portCallId}/jobs/${portJobCode}/updaterequestpdastatus`
	);

const cloneJob = (request: ClonePortJobRequestPayload) =>
	client.post(`/portcalls/clone`, request);

const toggleOperationsStatus = ({
	portCallId,
	jobCode,
	isOperationsDisabled
}: ToggleOperationsStatusParams) =>
	client.patch<ToggleOperationsResponse>(
		`/portcalls/${portCallId}/jobs/${jobCode}/toggle-operations/${isOperationsDisabled}`
	);

const enableOperations = ({ portCallId, jobCode }: PortJobBaseRequest) =>
	client.get<ServiceSection[]>(
		`/finances/${portCallId}/jobs/${jobCode}/enable-operations`
	);

const enableOperationsUpdate = ({
	portCallId,
	jobCode,
	...params
}: UpdateServiceSection) =>
	client.put(
		`/finances/${portCallId}/jobs/${jobCode}/enable-operations-update`,
		params
	);

export default {
	acceptPortCallJob,
	addOperationToPortJob,
	editOperationInPortJob,
	deletePortJobOperation,
	addPortJob,
	addAppointmentFromNomination,
	updatePortJob,
	deletePortJob,
	appointToPortJob,
	confirmPortCallJob,
	cancelPortJob,
	uncancelPortJob,
	changeCustodyRole,
	updateSavings,
	movetoPDAPending,
	movetoCEPending,
	reopenJob,
	retrievePortJob,
	retrievePortJobCustodyRoles,
	retrievePortJobStatuses,
	retrievePortJobSummary,
	retrievePortJobThreads,
	retrievePortJobOperation,
	retrieveAppointers,
	retrieveAppointData,
	checkPortJobPermissions,
	retrieveAcceptanceData,
	retrievePortJobMetadata,
	retrieveAvailableGroups,
	setDefaultMessagingGroup,
	requestPDA,
	retrieveExtendedMessagingData,
	retrievePrefundingExtendedMessagingData,
	retrieveExtendedMessagingDatawithPayload,
	togglePortCallJobStatus,
	toggleOperationsStatus,
	inviteAgentToPortJob,
	unlinkJobs,
	cloneJob,
	enableOperations,
	enableOperationsUpdate
};
