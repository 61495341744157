import React, { FC, useState, useCallback } from 'react';
import { Button, Popover } from 'components/antd';
import { Flex } from 'components';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import UnlinkedCargosList from './UnlinkedCargosList';
import {
	addAppointmentCargoManual,
	createCargoFromNomination
} from 'store/linkJob/actions';
import styles from '../CargoLinkage.module.scss';
import { useDispatch } from 'react-redux';
import { Justify } from 'components/types';

interface LinkCargoPopoverProps {
	cargosList: LinkedCargo[];
	nominationCargoId?: string;
}

const LinkCargoPopover: FC<LinkCargoPopoverProps> = ({
	cargosList,
	nominationCargoId
}) => {
	const dispatch = useDispatch();
	const [isVisible, setIsPopupVisible] = useState(false);
	const onDropdownVisibilityChange = useCallback(
		isVisible => {
			setIsPopupVisible(isVisible);
		},
		[setIsPopupVisible]
	);
	const onSubmit = useCallback(
		id => {
			setIsPopupVisible(false);
			nominationCargoId && id
				? dispatch(addAppointmentCargoManual({ id, nominationCargoId }))
				: nominationCargoId &&
				  dispatch(createCargoFromNomination({ nominationCargoId }));
		},
		[dispatch, nominationCargoId]
	);
	const onDropdownClick = useCallback(() => onDropdownVisibilityChange(true), [
		onDropdownVisibilityChange
	]);
	return (
		<Popover
			overlayClassName={styles.popover}
			title="Link Cargo"
			placement="bottomLeft"
			type="dropdown"
			content={
				isVisible && (
					<>
						<UnlinkedCargosList
							cargosList={cargosList}
							onClickList={onSubmit}
						/>
						<footer className={styles.popoverFooter}>
							<Flex justify={Justify.END}>
								<Button type="primary" onClick={() => onSubmit('')}>
									Create cargo from nomination
								</Button>
							</Flex>
						</footer>
					</>
				)
			}
			trigger="click"
			visible={isVisible}
			onVisibleChange={onDropdownVisibilityChange}
		>
			<Button icon="plus" transparent type="primary" onClick={onDropdownClick}>
				Link Cargo
			</Button>
		</Popover>
	);
};

export default LinkCargoPopover;
