import {
	AllowedDocumentPlaceholder,
	PortCallDocumentsMetadataActionCode,
	AllowedDocument,
	DocumentJob
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { isJobAccepted } from 'store/portcalls/selectors';
import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { createSelector } from 'reselect';
import { keyBy, groupBy, get, flatten, values, has, isEmpty } from 'lodash';
import { AppState } from 'store-types';
import { FileExtension } from 'app-constants';
import { getIsFileExtensionOfType } from 'utils';
import { getIsLoading } from 'store/selectors';
import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';
import {
	getCurrentContextualizationFormPage,
	getContextualizationFormValues
} from 'store/form/operationsContextualization/selectors';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

const getAllowedDocumentsMap = (state: AppState) =>
	state.portCallDocuments.allowedByCode;

const getAllowedDocumentByCode = (
	state: AppState,
	code: string
): AllowedDocument => state.portCallDocuments.allowedByCode[code];

export const getAllowedDocuments = createSelector(
	getAllowedDocumentsMap,
	documents => Object.values(documents)
);

/**
 * Returns an Array of placeholders by documentTypeCode
 */
const getAllowedDocumentPlaceholders = createSelector(
	getAllowedDocumentByCode,
	document => (document ? document.placeholders : [])
);

/**
 * Returns a Dictionary of an Array of placeholders from the selected DocumentTypeCode
 * grouped by `level` as key
 */
export const getPlaceholdersDropdownOptions = createSelector(
	getAllowedDocumentPlaceholders,
	placeholders => groupBy(placeholders, 'level')
);

/**
 * Get the selected DocumentTypeCode of the current page
 * and search on the store for the placeholders which belongs to that DocumentType
 */
const getPlaceholdersFromSelectedDocument = createSelector(
	getCurrentContextualizationFormPage,
	state => state,
	(formPageData, state) => {
		const code = formPageData.documentTypeCode;
		if (!code) {
			return [];
		}
		const document: AllowedDocument = getAllowedDocumentByCode(state, code);
		if (!document) {
			return [];
		}
		return document.placeholders;
	}
);

/**
 * Returns a list of Selected Placeholders.id of the current wizard page
 */
const getSelectedPlaceholderIds = createSelector(
	getCurrentContextualizationFormPage,
	(formPage): string[] => {
		const placeholders = formPage.placeholders || {};
		const placeholderIds = Object.keys(placeholders);
		/** Get only the ones marked as `true` (selected) */
		return placeholderIds.filter(id => placeholders[id]);
	}
);

/**
 * Returns a Dictionary of an Array of placeholders from the selected DocumentTypeCode
 * grouped by `id` as key
 * Usefull to match against selected values in form
 */
const getPlaceholdersFromSelectedDocumentGroupedById = createSelector(
	getPlaceholdersFromSelectedDocument,
	placeholders => {
		const result = keyBy<AllowedDocumentPlaceholder>(
			placeholders,
			item => item.id
		);
		return result;
	}
);

/**
 * Returns an Array of placeholders belogning to a DocumentTypeCode
 * previously passed in the stack of selectors
 */
export const getPlaceholderOptions = createSelector(
	getPlaceholdersDropdownOptions,
	placeholderOptions =>
		flatten<AllowedDocumentPlaceholder>(values(placeholderOptions))
);

export const getContextualizationFormPagesWithoutContext = createSelector(
	getContextualizationWizardForm,
	wizardForm => {
		const pages = wizardForm.pages || {};
		return Object.values(pages).reduce<number[]>((acc, page, index) => {
			if (!page.valid) {
				acc = [...acc, index + 1];
			}
			return acc;
		}, []);
	}
);

export const getIgnoredContextualizationFormPages = createSelector(
	getContextualizationFormValues,
	formValues => {
		return formValues.pages.reduce((acc, page, index) => {
			if (page.ignorePage) {
				/** form.page index +1 to equals the wizardForm.page number */
				const pageNumber = index + 1;
				return [...acc, pageNumber];
			}
			return acc;
		}, []);
	}
);

export const getIsDocumentCreateLoading = (state: AppState) =>
	getIsLoading(state.portCallDocuments.fetchStatuses.create);

/**
 * Returns an Array of the Accepted Jobs that the current PortCall contains
 */
export const getAssignedJobsDropdownOptions = createSelector(
	getActivePortCall,
	portCall => {
		if (!portCall) {
			return;
		}
		const jobs: DocumentJob[] = [];
		portCall.jobs.forEach(job => {
			if (isJobAccepted(job)) {
				jobs.push({
					code: job.code,
					companyName: job.companyName
				});
			}
		});
		return jobs;
	}
);

const getJobsFromSelectedPlaceholders = createSelector(
	getPlaceholdersFromSelectedDocumentGroupedById,
	getSelectedPlaceholderIds,
	(placeholdersById, selectedPlaceholderIds): string[] => {
		return selectedPlaceholderIds
			.filter(placeholderId => {
				// Skip POI Placeholder types
				return has(placeholdersById, [placeholderId, 'job']);
			})
			.map(placeholderId => {
				return get(placeholdersById, [placeholderId, 'job', 'code']);
			});
	}
);

export const getContextualizationAssignedJobsDropdownOptionsFlagged = createSelector(
	getAssignedJobsDropdownOptions,
	getJobsFromSelectedPlaceholders,
	getPlaceholdersDropdownOptions,
	(options = [], jobCodes, placeholdersOptions) => {
		const portJobPlaceholderOptions = placeholdersOptions.PortJob || [];
		return options.map(option => ({
			...option,
			warn: jobCodes.includes(option.code),
			placeholder: portJobPlaceholderOptions.find(
				job => option.code === job.name
			)?.id
		}));
	}
);

/**
 * Conditions are split to multiple `if` statements
 * for an easier understanding
 */
export const getCopyPageButtonIsVisible = createSelector(
	getIgnoredContextualizationFormPages,
	getContextualizationWizardForm,
	getContextualizationFormValues,
	(ignoredPages, wizardForm, formValues): boolean => {
		const activeIndex = wizardForm.activePage - 1;
		const { documentTypeCode, ignorePage } = formValues.pages[activeIndex];

		/** Wizard */
		const previousPageNumber = Math.max(wizardForm.activePage - 1, 0);
		const previousPageWasIgnored = ignoredPages.includes(previousPageNumber);

		/** ReduxForm */
		const previousPageIndex = Math.max(0, activeIndex - 1);
		const previousFormPageValues = formValues.pages[previousPageIndex];
		const previousPageWasContextualized =
			!isEmpty(previousFormPageValues) ||
			Boolean(previousFormPageValues?.ignorePage);

		/**
		 * Return false if is the first page of the contextualization process,
		 * therefore the previous page doesn't exist
		 */
		if (wizardForm.activePage === wizardForm.startPage) {
			return false;
		}

		if (previousPageWasIgnored) {
			return false;
		}

		return previousPageWasContextualized && !documentTypeCode && !ignorePage;
	}
);

export const getIsAtLeastOnePageContextualized = createSelector(
	getContextualizationFormValues,
	getContextualizationWizardForm,
	(formValues, wizardForm) => {
		if (!formValues.pages) {
			return false;
		}

		return formValues.pages.some((page, index) => {
			const activePage = index + 1;
			const pageIsIgnored = !page || page.ignorePage;
			const pageIsValid =
				wizardForm.pages &&
				wizardForm.pages[activePage] &&
				wizardForm.pages[activePage].valid;

			return Boolean(!pageIsIgnored && pageIsValid);
		});
	}
);

// Retrieve Port Call Documents
// ----------------------------
export const getRetrievePortCallDocumentFetchStatus = (state: AppState) =>
	state.portCallDocuments.fetchStatuses.retrieveDocument;

export const getPortCallDocument = (state: AppState) =>
	state.portCallDocuments.document;

export const getPortCallDocumentConcurrencyToken = (state: AppState) => {
	const document = getPortCallDocument(state);
	return document?.concurrencyToken;
};

export const getIsPortCallDocumentPDF = createSelector(
	getPortCallDocument,
	document => {
		return (
			!document ||
			getIsFileExtensionOfType(document.file.extension, FileExtension.PDF)
		);
	}
);

export const getPortCallDocumentPagesLen = (state: AppState) => {
	const document = getPortCallDocument(state);
	return document?.pages.length;
};

export const getIsPortCallDocumentPageDeleteLoading = (
	state: AppState,
	documentId: string,
	actualNumber: number
) => {
	const fetchStatusesById =
		state.portCallDocuments.fetchStatuses.deleteDocumentPageById[documentId];
	return getIsLoading(fetchStatusesById?.[actualNumber]);
};

const getPortCallDocumentsMetadata = (state: AppState, portCallId: string) =>
	state.portCallDocuments.metadata[portCallId];

export const hasPortCallDocumentsMetadataAction = createSelector(
	getPortCallDocumentsMetadata,
	(_: AppState, __: string, actionCode: PortCallDocumentsMetadataActionCode) =>
		actionCode,
	(metadata, actionCode) => {
		return metadata && !!getEntityMetadataAction(metadata.actions, actionCode);
	}
);
