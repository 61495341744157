import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState } from '../portJobsState';
import {
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

const onRetrieveMailboxesForPrincipalSuccess = (
	state: PortJobState,
	action: Success<
		RetrieveMailboxesForPrincipalRequest,
		RetrieveMailboxesResponse
	>
): PortJobState => ({
	...state,
	context: {
		...state.context,
		// always array with 1 element inside
		activePortJobPrincipalEmail: action.result.elements[0].email
	}
});

export const onRetrieveMailboxesForPrincipal = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	RetrieveMailboxesForPrincipalRequest,
	RetrieveMailboxesResponse
>('fetchStatuses', 'principalEmail', {
	doneReducer: onRetrieveMailboxesForPrincipalSuccess
});
