import { first } from 'lodash';
import runValidations, {
	charMax,
	email,
	required,
	arrayMin,
	validateRequired
} from 'utils/validations';
import { InviteUserFormData } from '../UsersModalsTypes';
import config from 'services/config';

const allowedForISSDomainsList = config.allowedForISSDomainsList;
const useLegacyIdentity = config.useLegacyIdentity === 'true';

const validateEmail = (values: InviteUserFormData) => {
	const error = first(
		runValidations(values.email, [email, charMax(256), required])
	);
	if (error || useLegacyIdentity) {
		return error;
	}

	const emailDomain = values.email.substring(values.email.lastIndexOf('@') + 1);
	const isDomainAllowedForISS = !allowedForISSDomainsList.find(
		validDomain => validDomain.toLowerCase() === emailDomain.toLowerCase()
	);

	if (values.company && values.company.label === 'ISS Group Holdings Ltd') {
		if (isDomainAllowedForISS) {
			return 'Please enter ISS Group Holdings Ltd allowed mail address';
		}
	}
	if (values.company && values.company.label !== 'ISS Group Holdings Ltd') {
		if (!isDomainAllowedForISS) {
			return 'Please enter other than ISS Group Holdings Ltd allowed mail address';
		}
	}
	return undefined;
};

const validate = (values: InviteUserFormData) => {
	return {
		email: validateEmail(values),
		firstName: first(
			runValidations(values.firstName, [charMax(256), required])
		),
		lastName: first(runValidations(values.lastName, [charMax(256), required])),
		company: validateRequired(values.company),
		invitationMessage: first(
			runValidations(values.invitationMessage, [charMax(1024)])
		),
		/**
		 * Validate only if there's at least one group selected
		 **/
		groups: first(runValidations(values.groups, [arrayMin(1)]))
	};
};

export default validate;
