import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { PageFooter, ButtonRow } from 'components';
import { Button, Row, Col } from 'components/antd';
import { Justify } from 'components/types';
import UserStatusButton from '../UserStatusButton/UserStatusButton';
import { USER_PROFILE_FORM } from 'store/users/usersConstants';

interface EditUserPageFooterProps {
	onCancel: () => void;
	disabled: boolean;
	isProfilePage: boolean;
}

const EditUserPageFooter: FC<EditUserPageFooterProps> = ({
	onCancel,
	disabled,
	isProfilePage
}) => {
	const dispatch = useDispatch();

	const onSave = useCallback(() => {
		dispatch(submit(USER_PROFILE_FORM));
	}, [dispatch]);

	return (
		<PageFooter>
			<Row start="sm" middle="xs">
				<Col sm={6}>
					<ButtonRow>
						<UserStatusButton isProfilePage={isProfilePage} />
					</ButtonRow>
				</Col>
				<Col sm={6} justify={Justify.END}>
					<ButtonRow>
						<Button type="primary" transparent onClick={onCancel}>
							Cancel
						</Button>
						<Button type="primary" onClick={onSave} disabled={disabled}>
							Save
						</Button>
					</ButtonRow>
				</Col>
			</Row>
		</PageFooter>
	);
};

export default EditUserPageFooter;
