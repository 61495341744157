import client from 'services/client';
import { User } from 'services/api/users/userServiceTypes';
import {
	RegisterUserByTokenRequest,
	RetrievePreRegistrationAgentCompanyRequest,
	RetrievePreRegistrationAgentCompanyResponse,
	PreRegisterRequest,
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse
} from './registrationServiceTypes';

const registerUserByToken = ({
	token,
	...request
}: RegisterUserByTokenRequest) =>
	client.post(`users/by-invitation/${token}/register`, request.params);

const retrieveUserByToken = (token: string) =>
	client.get<User>(`/users/by-invitation/${token}`);

const retrievePreRegistrationAgentCompany = (
	params: RetrievePreRegistrationAgentCompanyRequest
) =>
	client.get<RetrievePreRegistrationAgentCompanyResponse>(`/pre-registration`, {
		params
	});

const preRegister = (data: PreRegisterRequest) =>
	client.post(`/pre-registration`, data, {
		params: { agentCompany: data.agentCompanyId }
	});

const preRegisterVerify = (data: PreRegisterVerifyRequest) =>
	client.put<PreRegisterVerifyResponse>(`/pre-registration/verify`, null, {
		params: { token: data.token }
	});

export default {
	registerUserByToken,
	retrieveUserByToken,
	retrievePreRegistrationAgentCompany,
	preRegister,
	preRegisterVerify
};
