import * as React from 'react';
import { connect } from 'react-redux';
import { Flex } from 'components';
import { FormattedTime } from 'containers';
import { TooltipProps } from 'antd/lib/tooltip';
import LocalDateAndTimeTooltip, {
	LocalDateAndTimeTooltipProps
} from './FormattedTimeTooltip/LocalDateAndTimeTooltip';
import FormattedTimeTooltip, {
	FormattedTimeTooltipProps
} from './FormattedTimeTooltip/FormattedTimeTooltip';
import { getActivePortTimeZoneExtended } from 'store/masterdata/ports/selectors';
import { AppState } from 'store-types';
import { isNil } from 'lodash';
import styles from './FormattedTimeExtended.module.scss';
import { getUserTimezone } from 'store/auth/selectors';
import { Tooltip } from 'components/antd';

export interface FormattedTimeWithPopoverOwnProps {
	value: string | null;
	timeAgo?: boolean;
	portId?: string;
	directPortTimeZoneId?: string;
	inlineTooltip?: boolean;
	convertFromUtc?: boolean;
	convertFromUtcToPlt?: boolean;
	showLocalTime?: boolean; // local time in both places – main label and tooltip
	tooltipProps?: Partial<TooltipProps>;
	showOnlyUserTimezoneInTooltip?: boolean;
	detailsTooltip?: boolean;
	tooltipTitle?: React.ReactNode;
}

export interface FormattedTimeWithPopoverProps
	extends FormattedTimeWithPopoverOwnProps {
	// from mapStateToProps
	portTimeZoneId: string;
	userTimeZone: string;
}

class FormattedTimeExtended extends React.Component<
	FormattedTimeWithPopoverProps
> {
	static Tooltip: React.ComponentType<
		FormattedTimeTooltipProps
	> = FormattedTimeTooltip;
	static LocalDateAndTimeTooltip: React.ComponentType<
		LocalDateAndTimeTooltipProps
	> = LocalDateAndTimeTooltip;

	render() {
		const {
			value,
			timeAgo,
			portTimeZoneId,
			convertFromUtc,
			convertFromUtcToPlt,
			children,
			inlineTooltip,
			showLocalTime,
			tooltipProps,
			showOnlyUserTimezoneInTooltip,
			userTimeZone,
			detailsTooltip,
			tooltipTitle
		} = this.props;
		if (isNil(value)) {
			return <span> TBD </span>;
		}
		const FormattedTimeComponent = (
			<FormattedTime
				value={value}
				timeAgo={timeAgo}
				portTimeZoneId={portTimeZoneId}
				convertFromPlt={timeAgo && !convertFromUtc}
				convertFromUtc={showLocalTime && convertFromUtc}
				convertFromUtcToPlt={convertFromUtcToPlt}
			/>
		);
		return (
			<Flex inline align="center">
				<span className={styles.withInfo}>
					{!detailsTooltip && FormattedTimeComponent}
					{detailsTooltip && (
						<Tooltip
							placement="top"
							title={tooltipTitle}
							overlayClassName={styles.approverDetailsPopover}
						>
							<span>{FormattedTimeComponent}</span>
						</Tooltip>
					)}
					{children}
					{showOnlyUserTimezoneInTooltip && (
						<FormattedTimeTooltip title={userTimeZone} />
					)}
					{!showOnlyUserTimezoneInTooltip && inlineTooltip && (
						<LocalDateAndTimeTooltip
							value={value}
							convertFromUtc={convertFromUtc}
							portTimeZoneId={portTimeZoneId}
						/>
					)}
				</span>
				{!showOnlyUserTimezoneInTooltip && !inlineTooltip && (
					<LocalDateAndTimeTooltip
						value={value}
						convertFromUtc={convertFromUtc}
						portTimeZoneId={portTimeZoneId}
						tooltipProps={tooltipProps}
					/>
				)}
			</Flex>
		);
	}
}

export default connect(
	(
		state: AppState,
		ownProps: Pick<
			FormattedTimeWithPopoverProps,
			'portId' | 'directPortTimeZoneId'
		>
	) => {
		const { portId = '', directPortTimeZoneId } = ownProps;
		return {
			userTimeZone: getUserTimezone(state),
			portTimeZoneId:
				directPortTimeZoneId ||
				getActivePortTimeZoneExtended(state, portId) ||
				''
		};
	}
)(FormattedTimeExtended);
