import { FinanceState } from '../financeState';
import {
	SubmitUnitCostForm,
	SubmitUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onSubmitUnitCostForm = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	SubmitUnitCostForm,
	SubmitUnitCostFormResponse
>('fetchStatuses', 'submitUnitForm');
