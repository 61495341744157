import { DashboardState } from '../dashboardState';

export const onSetDashboardTableauCompanyId = (
	state: DashboardState,
	payload: string
): DashboardState => ({
	...state,
	context: {
		...state.context,
		tableauCompanyId: payload
	}
});
