import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
	ThreadsAssignToFormData,
	THREADS_ASSIGNTO_FORM
} from './AssignToModalFormTypes';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import { retrieveUsersFromGroup } from 'store/thread';
import { ReduxForm } from 'components';
import AssignToGroupFields from './AssignToGroupFields';
import AddGroupFields from './AddGroupFields';
import { retrieveThreadsAvailableGroups } from 'store/threads/actions';

export interface AssignToModalFormProps {
	assignedTo: {
		group?: {
			id: string;
			name: string;
		};
		user?: {
			id: string;
			name: string;
		};
	};
}

const AssignToModalForm: FC<AssignToModalFormProps> = ({ assignedTo }) => {
	const dispatch = useDispatch();

	const threadPermissionGroupsInitialValues = assignedTo.group
		? [{ ...assignedTo.group }]
		: [];

	useEffect(() => {
		threadPermissionGroupsInitialValues.forEach(
			(group: PermissionGroupWithUsers) =>
				dispatch(retrieveUsersFromGroup({ id: group.id }))
		);
		dispatch(retrieveThreadsAvailableGroups());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const initialValues = useMemo(
		(): ThreadsAssignToFormData => ({
			assignedGroupId: assignedTo.group && assignedTo.group.id,
			assignedUserId: assignedTo.user && assignedTo.user.id,
			threadPermissionGroups: threadPermissionGroupsInitialValues
		}),
		[assignedTo, threadPermissionGroupsInitialValues]
	);

	return (
		<>
			<ReduxForm form={THREADS_ASSIGNTO_FORM} initialValues={initialValues}>
				<AssignToGroupFields />
				<hr />
				<AddGroupFields />
			</ReduxForm>
		</>
	);
};

export default AssignToModalForm;
