import React from 'react';
import { connect } from 'react-redux';
import { AutocompleteSearch } from 'components';
import { Align } from 'components/types';
import { FiltersStateAndHelpers } from 'components/Filters/Filters';
import { Row, Col, Select, Autocomplete, Checkbox } from 'components/antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { retrievePortCallsStatic } from 'store/portcalls';
import {
	getUserType,
	getUserCompanyId,
	getIsCurrentUserHub
} from 'store/auth/selectors';
import {
	PORT_CALLS_FILTER_NAME,
	PortCallsFiltersParam,
	PortCallsFiltersParamEnum,
	PortCountryGroupType
} from 'store/portcalls/filtersSync';
import { FilterItem } from 'store/filters/filtersState';
import { getFieldValues } from './PortCallsFiltersSelectors';
import {
	searchGroupTypeMap,
	portCountryTypeMap
} from './PortCallsFiltersConstants';
import { portCallStatusToLabel } from 'store/portJobs/constants';
import { portCallStatusOrdered } from 'services/api/portCalls/portCallsConsts';
import { searchEntitiesForAutocomplete } from 'containers/Autocomplete/Autocomplete.func';
import { UserType } from 'services/api/users/userServiceTypes';
import Api from 'services/api';
import { getFilteredStatusesByUserType } from 'store/portcalls/portCallsConstants';
import styles from './PortCallsFilters.module.scss';
import { AppState } from 'store-types';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';
import AdvanceFilters from './AdvanceFilters/AdvanceFilters';

interface PortCallsFiltersProps extends FiltersStateAndHelpers {
	// from mapStateToProps
	values: PortCallsFiltersParam;
	userType: UserType;
	companyId: string;
	isHubUser: boolean;
	// from mapDispatchToProps
	retrievePortCallsStatic: typeof retrievePortCallsStatic;
}

class PortCallsFilters extends React.PureComponent<PortCallsFiltersProps> {
	componentDidMount() {
		this.props.retrievePortCallsStatic();
	}

	onFilterSelect = (filter: FilterItem, name: string, override?: boolean) => {
		this.props.onChange(
			{
				[name]: [filter]
			},
			override
		);
	};

	onChangeSearch = (filter: FilterItem, groupType: string) => {
		this.onFilterSelect(
			{ ...filter, type: groupType },
			PortCallsFiltersParamEnum.SEARCH,
			false
		);
	};

	onChange(name: string) {
		return (filter: FilterItem) => {
			this.onFilterSelect({ ...filter, type: name }, name);
		};
	}

	onVesselSearch = (searchTerm: string) => {
		const { userType } = this.props;
		if (userType === UserType.PRINCIPAL) {
			return Api.Companies.searchCompanyUniqVesselsForAutocomplete({
				searchTerm,
				companyId: this.props.companyId
			});
		}
		return Api.Vessels.searchVesselsForAutocomplete(searchTerm);
	};

	onShowCancelledChange = (event: CheckboxChangeEvent) => {
		const isChecked = event.target.checked;
		const filter = {
			type: PortCallsFiltersParamEnum.SHOW_CANCELLED,
			key: 'yes',
			label: 'yes'
		};
		if (isChecked) {
			this.onChange(PortCallsFiltersParamEnum.SHOW_CANCELLED)(filter);
		} else {
			this.props.clearOne(filter);
		}
	};

	onMainPrincipalSearch = (searchTerm?: string) =>
		searchEntitiesForAutocomplete(
			Api.Companies.searchMainPrincipalsForAutocomplete,
			searchTerm
		);

	onPerformingAgentSearch = (searchTerm?: string) =>
		searchEntitiesForAutocomplete(
			Api.Companies.searchPerformingAgentForAutocomplete,
			searchTerm
		);

	onPortsAndCountriesSearch = (
		searchTerm: string,
		groupType?: PortCountryGroupType
	) =>
		searchEntitiesForAutocompleteSearch<PortCountryGroupType>(
			Api.Ports.searchPortsAndCountriesForAutocomplete,
			searchTerm,
			groupType
		);

	onPortAndCountriesChange = (
		filter: FilterItem,
		type: PortCountryGroupType
	) => {
		const {
			values: { portId, countryCode },
			clearOne
		} = this.props;
		if (type === PortCountryGroupType.COUNTRIES) {
			if (portId) {
				clearOne(portId);
			}
			this.onChange(PortCallsFiltersParamEnum.COUNTRY)(filter);
		} else {
			if (countryCode) {
				clearOne(countryCode);
			}
			this.onChange(PortCallsFiltersParamEnum.PORT)(filter);
		}
	};

	render() {
		const { values, isHubUser } = this.props;
		const statuses = getFilteredStatusesByUserType(this.props.userType);
		const filterColumnWidth = isHubUser ? 1.71 : 2.4;
		return (
			<Row>
				<Col sm={12}>
					<Row className={styles.portCallFilterContainer}>
						<Col sm={filterColumnWidth}>
							Search
							<AutocompleteSearch
								onSearch={Api.PortCalls.searchPortCallsForAutocomplete}
								onChange={this.onChangeSearch}
								placeholder="Search results will be shown after entering at least 2 characters"
								typeMap={searchGroupTypeMap}
								allowSelectInputValue={false}
							/>
						</Col>
						{isHubUser && (
							<Col sm={filterColumnWidth}>
								Main Principal
								<Autocomplete
									onSearch={this.onMainPrincipalSearch}
									onSelect={this.onChange(
										PortCallsFiltersParamEnum.MAIN_PRINCIPAL
									)}
									value={values.mainPrincipalCompanyId}
									shouldSearchAllOptionsOnFocus
								/>
							</Col>
						)}
						<Col sm={filterColumnWidth}>
							Performing Agent
							<Autocomplete
								onSearch={this.onPerformingAgentSearch}
								onSelect={this.onChange(
									PortCallsFiltersParamEnum.PERFORMING_AGENT
								)}
								value={values.performingAgentCompanyId}
							/>
						</Col>
						<Col sm={filterColumnWidth}>
							Country/Port
							<AutocompleteSearch
								onSearch={this.onPortsAndCountriesSearch}
								onChange={this.onPortAndCountriesChange}
								typeMap={portCountryTypeMap}
								placeholder="All Countries/Ports"
							/>
						</Col>
						<Col sm={filterColumnWidth}>
							Vessel
							<Autocomplete
								onSearch={this.onVesselSearch}
								onSelect={this.onChange(PortCallsFiltersParamEnum.VESSEL)}
								value={values.vesselId}
							/>
						</Col>
						<Col sm={filterColumnWidth}>
							Job Status
							<Autocomplete
								onChange={this.onChange(PortCallsFiltersParamEnum.JOB_STATUS)}
								value={values.jobStatus}
								localSearch
								showSearch
								labelInValue
								filterOption
								optionFilterProp="children"
							>
								{statuses.map(status => (
									<Select.Option key={status.code}>{status.name}</Select.Option>
								))}
							</Autocomplete>
						</Col>
						<Col sm={filterColumnWidth}>
							Port Call Status
							<Autocomplete
								onChange={this.onChange(
									PortCallsFiltersParamEnum.PORT_CALL_STATUS
								)}
								value={values.portCallStatus}
								localSearch
								showSearch
								labelInValue
								filterOption
								optionFilterProp="children"
							>
								{portCallStatusOrdered.map(status => (
									<Select.Option key={status}>
										{portCallStatusToLabel(status)}
									</Select.Option>
								))}
							</Autocomplete>
						</Col>
						<Col align={Align.BOTTOM}>
							<AdvanceFilters
								values={values}
								onFilterSelect={this.onFilterSelect}
							/>
						</Col>
						<Col
							sm={filterColumnWidth}
							align={Align.BOTTOM}
							className={styles.fixedWidth}
						>
							<Checkbox
								onChange={this.onShowCancelledChange}
								checked={Boolean(
									values.includeCancelled && values.includeCancelled.label
								)}
								className={styles.showCancelledCheckbox}
							>
								Show cancelled jobs
							</Checkbox>
						</Col>
					</Row>
				</Col>
			</Row>
		);
	}
}

export default connect(
	(state: AppState) => ({
		values: getFieldValues(state, PORT_CALLS_FILTER_NAME),
		userType: getUserType(state),
		companyId: getUserCompanyId(state),
		isHubUser: getIsCurrentUserHub(state)
	}),
	{
		retrievePortCallsStatic
	}
)(PortCallsFilters);
