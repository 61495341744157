import { Success } from 'typescript-fsa';
import { SupplierInvoiceState } from '../supplierInvoiceState';
import {
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveSupplierInvoiceSuccess = (
	state: SupplierInvoiceState,
	action: Success<
		RetrieveSupplierInvoiceParams,
		RetrieveSupplierInvoiceResponse
	>
) => ({
	...state,
	activeSupplierInvoice: action.result
});

export const onRetrieveSupplierInvoice = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse
>('fetchStatuses', 'active', {
	doneReducer: onRetrieveSupplierInvoiceSuccess
});
