import { AppState } from 'store-types';

import { FormData } from 'sections/Administration/Configuration/EmailFragments/EmailFragmentsFormTypes';

import { getConfigurationEmailFragmentsFormValues } from 'store/configuration/selectors';
import { UpdateCompanyTemplateRequestData } from 'services/api/templates/templatesServiceTypes';

const prepareRequest = (state: AppState): UpdateCompanyTemplateRequestData => {
	const values: FormData = getConfigurationEmailFragmentsFormValues(state);
	return values.list.map(value => {
		return {
			options: value.options.map(option => ({
				key: option.key,
				value: option.value
			})),
			fragments: value.fragments.map(fragment => ({
				key: fragment.key,
				value: fragment.value
			})),
			template: value.template.code
		};
	});
};

export default prepareRequest;
