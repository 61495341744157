import * as React from 'react';
import classNames from 'classnames';
import { isFunction, debounce, find } from 'lodash';
import Downshift, {
	StateChangeOptions,
	ControllerStateAndHelpers
} from 'downshift';
import { Loading, Highlight } from 'components';
import { Input } from 'components/antd';
import {
	Option,
	Menu,
	ClearButton,
	NotFound,
	OptionGroup,
	ShowMore
} from 'components/AutocompleteSearch/Components';

import {
	AutocompleteSearchProps,
	ItemObject,
	GroupItemObject,
	SearchResponse
} from 'components/AutocompleteSearch/AutocompleteSearchTypes';
import { KeyboardEventKey, DEFAULT_DEBOUNCE_DELAY } from 'app-constants';
import { getItemGroupType } from './AutocompleteSearch.func';
import styles from 'components/AutocompleteSearch/AutocompleteSearch.module.scss';

interface AutocompleteSearchState {
	inputId: string;
	inputValue: string;
	items: GroupItemObject[];
	loading: boolean;
	cursorStart: number | null;
	cursorEnd: number | null;
}

export class AutocompleteSearch extends React.PureComponent<
	AutocompleteSearchProps,
	AutocompleteSearchState
> {
	static defaultProps: Partial<AutocompleteSearchProps> = {
		showMoreMinLength: 3,
		minLength: 2,
		allowSelectInputValue: true
	};
	input: Input | null;

	constructor(props: AutocompleteSearchProps) {
		super(props);
		this.state = {
			inputId: '',
			inputValue: '',
			items: [],
			loading: false,
			cursorStart: null,
			cursorEnd: null
		};
	}

	componentDidUpdate() {
		const { inputId, cursorStart, cursorEnd } = this.state;
		const input = document.getElementById(inputId);
		this.setSelectionRange(input, cursorStart, cursorEnd);
	}

	setInputRef = (elem: Input | null) => {
		this.input = elem;
	};

	setSelectionRange = (
		// eslint-disable-next-line
		input: any,
		cursorStart: number | null,
		cursorEnd: number | null
	) => {
		if (input) {
			input.focus();
			if (input.setSelectionRange) {
				input.setSelectionRange(cursorStart, cursorEnd);
			}
		}
	};

	makeOnInputChange = (props: ControllerStateAndHelpers) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const id = e.target.id;
		const cursorStart = e.target.selectionStart;
		const cursorEnd = e.target.selectionEnd;
		this.setState({
			inputId: id,
			cursorStart,
			cursorEnd
		});
		const inputProps = props.getInputProps();
		if (inputProps?.onChange) {
			inputProps.onChange(e);
		}
		const input = document.getElementById(id);
		this.setSelectionRange(input, cursorStart, cursorEnd);
	};

	/**
	 * Reflect internal state change
	 * @param {StateChangeOptions} changes - properties that actually have changed since the last state change
	 */
	onStateChange = (changes: StateChangeOptions) => {
		// eslint-disable-next-line no-prototype-builtins
		if (changes.hasOwnProperty('inputValue')) {
			if (isFunction(this.props.onInputValueChange)) {
				this.props.onInputValueChange(changes.inputValue);
			}
			this.setState({ inputValue: changes.inputValue }, () =>
				this.onSearch(null)
			);
		}
	};

	isInputValueValid = () =>
		!!this.props.minLength &&
		this.state.inputValue.length >= this.props.minLength;

	/**
	 * Called when a user selects an item with regardless to a previously selected
	 * @param {ItemObject} item
	 * @param {ControllerStateAndHelpers} stateAndHelpers
	 */
	onChange = (item: ItemObject, stateAndHelpers: ControllerStateAndHelpers) => {
		this.onChangeItem(item, stateAndHelpers);
	};

	onChangeItem = (
		item: ItemObject,
		stateAndHelpers: ControllerStateAndHelpers
	) => {
		if (!isFunction(this.props.onChange) || item === null) {
			return;
		}
		stateAndHelpers.clearSelection();
		const groupType = getItemGroupType(item, this.state.items);
		this.props.onChange(item, groupType);
	};

	setItems = (items: GroupItemObject[], loading = false) => {
		this.setState({ items, loading });
	};

	onSearch = debounce((groupType: string | null) => {
		this.setState({ loading: true });
		if (!this.isInputValueValid()) {
			this.setItems([]);
			return;
		}
		this.props
			.onSearch(this.state.inputValue, groupType)
			/**
			 * Filter on non-empty lists
			 */
			.then(({ items, searchTerm }: SearchResponse) => ({
				items: items.filter(item => item.results.length > 0),
				searchTerm
			}))
			.then(({ items, searchTerm }: SearchResponse) => {
				/**
				 * IPP-28189 - we need to prevent updating list of options when previous API call
				 * takes longer than the latest one. So we update list of options only if
				 * searchTerm sent in request matches current input value.
				 */
				if (searchTerm !== this.state.inputValue) {
					return;
				}
				/**
				 * If group type is defined, it means that `show more` button has been clicked,
				 * let's merge result with existing then
				 */

				if (!groupType) {
					return this.setItems(items);
				}
				const mappedItems = this.state.items.map(item => {
					const newItem = find(items, {
						groupType: item.groupType
					});
					return newItem || item;
				});
				this.setItems(mappedItems);
			})
			.catch(() => {
				this.setItems([]);
			});
	}, DEFAULT_DEBOUNCE_DELAY);

	onKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement>,
		stateAndHelpers: ControllerStateAndHelpers
	) => {
		switch (e.key) {
			case KeyboardEventKey.ENTER:
				this.handleKeyDownEnter(stateAndHelpers);
				break;
			case KeyboardEventKey.ARROW_UP:
				this.handleKeyDownArrowUp(stateAndHelpers, e);
				break;
			default:
				break;
		}
	};

	handleKeyDownEnter = (stateAndHelpers: ControllerStateAndHelpers) => {
		// proceed if no option has been selected and input value is defined
		if (
			stateAndHelpers.highlightedIndex !== null ||
			!stateAndHelpers.inputValue
		) {
			return;
		}

		// select input value
		if (this.isInputValueValid() && this.props.allowSelectInputValue) {
			this.onChangeItem(
				{
					key: '',
					label: stateAndHelpers.inputValue
				},
				stateAndHelpers
			);
		}
	};

	handleKeyDownArrowUp = (
		stateAndHelpers: ControllerStateAndHelpers,
		e: React.KeyboardEvent<HTMLInputElement>
	) => {
		// Prevent moving to the end of the list when input value is allowed to be selected
		if (
			stateAndHelpers.highlightedIndex === 0 &&
			this.props.allowSelectInputValue
		) {
			e.preventDefault();
			stateAndHelpers.setHighlightedIndex(-1);
		}
	};

	getDefaultHighlightedIndex = () => {
		return !this.props.allowSelectInputValue ? 0 : null;
	};

	shouldShowMenu = (isOpen: boolean) => {
		return isOpen && this.isInputValueValid();
	};

	itemToString = (item: ItemObject | null) => {
		if (isFunction(this.props.itemToString)) {
			return this.props.itemToString(item);
		}
		return item == null ? '' : String(item.label);
	};

	getGroupTitle = (groupType: string) => {
		const { typeMap } = this.props;
		return typeMap?.[groupType] || groupType;
	};

	onShowMoreClick = (groupType: string) => {
		this.onSearch(groupType);

		/**
		 * Focus input after `show more` clicked
		 */
		if (this.input) {
			this.input.focus();
		}
	};

	renderMenu = (props: ControllerStateAndHelpers) => {
		const { items } = this.state;

		/**
		 * Render Menu
		 */
		if (!items.length) {
			return <NotFound />;
		}
		/**
		 * Count options inside each group to be able to highlight
		 * option by comparing with `highlightedIndex`
		 */
		let count = 0;
		return (
			<Menu>
				{items.map(group => {
					const optionGroup = this.renderOptionGroup(group, props, count);
					count += group.results.length;
					return optionGroup;
				})}
			</Menu>
		);
	};

	renderOptionGroup = (
		group: GroupItemObject,
		props: ControllerStateAndHelpers,
		count: number
	) => {
		const { inputValue, getItemProps, highlightedIndex } = props;
		const { groupType, results, needShowMore } = group;
		return (
			<OptionGroup key={groupType} title={this.getGroupTitle(groupType)}>
				{results.map((item, index) => (
					<Option
						{...getItemProps({ item })}
						key={`${groupType}-${this.itemToString(item)}`}
						highlighted={count + index === highlightedIndex}
					>
						<Highlight term={inputValue || ''}>
							{this.itemToString(item)}
						</Highlight>
					</Option>
				))}
				<ShowMore
					show={needShowMore}
					groupType={groupType}
					onClick={this.onShowMoreClick}
				/>
			</OptionGroup>
		);
	};

	render() {
		const { loading } = this.state;
		const { placeholder } = this.props;
		return (
			<div className={styles.root}>
				<Downshift
					inputValue={this.state.inputValue}
					onStateChange={this.onStateChange}
					onChange={this.onChange}
					itemToString={this.itemToString}
					defaultHighlightedIndex={this.getDefaultHighlightedIndex()}
				>
					{props => (
						<div
							className={classNames({
								[styles.expanded]: this.shouldShowMenu(props.isOpen) && !loading
							})}
						>
							<Input
								ref={this.setInputRef}
								{...props.getInputProps({
									placeholder,
									onKeyDown: event => {
										this.onKeyDown(event, props);
									}
								})}
								onChange={e => this.makeOnInputChange(props)(e)}
							/>
							<Loading show={loading} className={styles.loading} size="xs" />
							{!loading && props.inputValue && (
								<ClearButton onClick={props.clearSelection} />
							)}
							{this.shouldShowMenu(props.isOpen) && this.renderMenu(props)}
						</div>
					)}
				</Downshift>
			</div>
		);
	}
}

export default AutocompleteSearch;
