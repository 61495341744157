import * as React from 'react';
import AntBreadcrumb, { BreadcrumbProps } from 'antd/lib/breadcrumb/Breadcrumb';
import BreadcrumbItem, {
	BreadcrumbItemProps
} from 'antd/lib/breadcrumb/BreadcrumbItem';

export class Breadcrumb extends React.Component<BreadcrumbProps, {}> {
	static Item: React.ComponentClass<BreadcrumbItemProps>;

	render() {
		return <AntBreadcrumb {...this.props} />;
	}
}

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
