import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ServicesState } from '../servicesState';
import {
	AddServiceCategoryRequest,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';

const doneReducer = (
	state: ServicesState,
	{ result }: Success<AddServiceCategoryRequest, ServiceCategory>
): ServicesState => ({
	...state,
	serviceCategories: {
		[result.id]: result,
		...state.serviceCategories
	}
});

export const onAddServiceCategory = makeFetchStatusReducers<
	ServicesState,
	'fetchStatuses',
	AddServiceCategoryRequest,
	ServiceCategory
>('fetchStatuses', 'addServiceCategory', {
	doneReducer
});
