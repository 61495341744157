import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { retrieveFinanceCurrenciesAsync } from '../actions/retrieveFinanceCurrencies';
import { RetrieveFinanceCurrenciesResponse } from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';

export default makeTakeEveryWatcher<
	null,
	RetrieveFinanceCurrenciesResponse,
	Error
>({
	api: Api.Finance.retrieveFinanceCurrencies,
	async: retrieveFinanceCurrenciesAsync
});
