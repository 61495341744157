import * as React from 'react';

import { Header, NewTab, SubHeader } from './components';

import { PageHeaderProps } from './components/Header';

export class PageHeader extends React.Component<PageHeaderProps> {
	static NewTab: typeof NewTab;
	static SubHeader: typeof SubHeader;

	render() {
		return <Header {...this.props} />;
	}
}

PageHeader.NewTab = NewTab;
PageHeader.SubHeader = SubHeader;

export default PageHeader;
