import {
	RetrieveRotationStep,
	RotationStepEventParentType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { VesselProgrammeState } from 'store/vesselProgramme/vesselProgrammeState';
import {
	toReducerOperationUnit,
	toCorrectlyFilledStep,
	toReducerRotationStep,
	toReducerEvent
} from 'store/vesselProgramme/utils/adapters';

export const buildVesselProgrammeAfterAddingRotationSteps = (
	{
		operationUnitsById,
		orderedRotationSteps,
		rotationStepsById,
		eventsById
	}: VesselProgrammeState,
	nextId: string,
	rotationSteps: RetrieveRotationStep[]
) => {
	const updatedUnitsById = { ...operationUnitsById };
	let updatedStepIds = [...orderedRotationSteps];
	const updatedStepsById = { ...rotationStepsById };
	const updatedEventsById = { ...eventsById };
	rotationSteps.forEach(rotationStep => {
		// remove the following line if IDs start actually coming in the future
		const step = toCorrectlyFilledStep(rotationStep);
		const reducerStep = toReducerRotationStep(step);
		const nextIdPos = updatedStepIds.indexOf(nextId);
		(step.units || []).forEach(unit => {
			updatedUnitsById[unit.id] = toReducerOperationUnit(unit);
			unit.events.forEach(
				event =>
					(updatedEventsById[event.id] = {
						...toReducerEvent(event),
						parentId: unit.id,
						parentType: RotationStepEventParentType.OPERATION_UNIT
					})
			);
		});
		step.events.forEach(
			event =>
				(updatedEventsById[event.id] = {
					...toReducerEvent(event),
					parentId: step.id,
					parentType: RotationStepEventParentType.ROTATION_STEP
				})
		);
		updatedStepsById[step.id] = reducerStep;
		updatedStepIds = [
			...updatedStepIds.slice(0, nextIdPos),
			step.id,
			...updatedStepIds.slice(nextIdPos)
		];
	});

	return {
		operationUnitsById: updatedUnitsById,
		orderedRotationSteps: updatedStepIds,
		rotationStepsById: updatedStepsById,
		eventsById: updatedEventsById
	};
};
