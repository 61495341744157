import React, { Component } from 'react';
import { Modal } from 'components/antd';
import ExtendedMessagingContainer from './ExtendedMessagingModal/ExtendedMessagingContainer';
import { EXTENDED_MAIL_TYPE } from '../PortJobConstants';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { CANCEL_EMAIL_MODAL } from './CancelPortJobModal';

interface CancelPropsJobMessageModalState {
	isWarningVisible: boolean;
}

interface CancelPortJobMessageModalProps {
	isCancelExtendedMessagingModalVisible: boolean;
	isCancelExtendedMessagingModalIdVisible: boolean;
	modalId: string;
	closeModal: typeof closeModal;
}

class CancelPortJobMessageModal extends Component<
	CancelPortJobMessageModalProps,
	CancelPropsJobMessageModalState
> {
	constructor(props: CancelPortJobMessageModalProps) {
		super(props);
		this.state = {
			isWarningVisible: false
		};
	}

	closeModal = () => {
		const { closeModal, modalId } = this.props;
		if (modalId) {
			closeModal(modalId);
		}
		closeModal(CANCEL_EMAIL_MODAL);
	};

	render() {
		const {
			isCancelExtendedMessagingModalVisible,
			isCancelExtendedMessagingModalIdVisible
		} = this.props;
		return (
			<>
				<Modal
					destroyOnClose
					title="Job details will be sent to"
					visible={
						isCancelExtendedMessagingModalVisible ||
						isCancelExtendedMessagingModalIdVisible
					}
					onClose={() => {
						this.setState({ isWarningVisible: true });
					}}
					width={1366}
					fullSizeModal
					footer={null}
				>
					<ExtendedMessagingContainer
						mailType={EXTENDED_MAIL_TYPE.CANCEL_EMAIL}
						closeExtendedMessagingModal={() => this.closeModal()}
					/>
				</Modal>
				<Modal
					okText="Yes"
					cancelText="No"
					visible={this.state.isWarningVisible}
					closable={false}
					onCancel={() => this.setState({ isWarningVisible: false })}
					onOk={() => {
						this.setState({
							isWarningVisible: false
						});
						this.closeModal();
					}}
				>
					Are you sure you do not want to send the message?
				</Modal>
			</>
		);
	}
}

export default connect(
	(
		state: AppState,
		ownProps: Pick<CancelPortJobMessageModalProps, 'modalId'>
	) => ({
		isCancelExtendedMessagingModalVisible: isModalVisible(
			state,
			CANCEL_EMAIL_MODAL
		),
		isCancelExtendedMessagingModalIdVisible: isModalVisible(
			state,
			ownProps.modalId
		)
	}),
	{ closeModal }
)(CancelPortJobMessageModal);
