import { Action } from 'typescript-fsa';
import { makeDefaultExecutor } from 'utils/sagaHelpers/sagaHelpers';
import { call, select, takeLatest } from 'redux-saga/effects';

import Api from 'services/api';
import { retrieveGroupsAsync } from '../actions/retrieveGroups';
import { getGroupsCount, getGroupsFilters } from '../groupsSelectors';

import {
	RetrieveGroupsParams,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';
import { GROUPS_FILTER_NAME } from '../filtersSync';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { FilterItem } from 'store/filters/filtersState';

const api = Api.Groups.retrieveGroups;
export const executor = makeDefaultExecutor<
	RetrieveGroupsParams,
	RetrieveGroupsResponse,
	Error
>({
	api,
	async: retrieveGroupsAsync
});

export const worker = function*({
	payload: params,
	meta
}: Action<RetrieveGroupsParams>) {
	const filters = yield select(getGroupsFilters, GROUPS_FILTER_NAME);
	const count = yield select(getGroupsCount);
	const filtersPayload: Partial<RetrieveGroupsParams> = {
		searchTerm: filters?.searchTerm?.[0]?.key,
		companyId: filters?.company?.[0]?.key,
		groupType: filters?.groupType?.[0]?.key
	};

	if (filters.search && filters.search.length > 0) {
		filtersPayload.names = filters.search
			.filter((item: FilterItem) => item.type === 'GroupNames')
			.map((item: FilterItem) => item.label)
			.join(',');
		filtersPayload.companiesIds = filters.search
			.filter((item: FilterItem) => item.type === 'GroupCompanyNames')
			.map((item: FilterItem) => item.key)
			.join(',');
		const searchTerm = filters.search.filter(
			(item: FilterItem) => item.type === 'Search'
		);
		if (searchTerm) {
			filtersPayload.searchTerm = searchTerm
				.map((item: FilterItem) => item.label)
				.join(',');
		}
	}

	const payload: RetrieveGroupsParams = {
		limit: DEFAULT_LIST_LIMIT,
		index: count,
		sortBy: 'updatedOn:desc',
		...(params.overrideFilters
			? { ...filtersPayload, ...params }
			: { ...params, ...filtersPayload })
	};
	yield call(executor, payload, meta);
};

export default function*() {
	yield takeLatest(retrieveGroupsAsync.type, worker);
}
