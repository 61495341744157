import client from 'services/client';

import { AxiosTypedPromise } from 'services/api/apiTypes';
import {
	Savings,
	RetrieveSavingsRequest,
	RetrieveSavingsResponse,
	RetrieveOperationsValuesResponse,
	AddCostSavingsRequest,
	AddTimeSavingsRequest,
	RetrieveTimeSavingsResponse,
	TimeSavings,
	UpdateCostSavingsRequest,
	UpdateTimeSavingsRequest,
	DeleteCostSavingsRequest,
	DeleteTimeSavingsRequest
} from 'services/api/savings/savingsServiceTypes';

const retrieveSavings = (
	request: RetrieveSavingsRequest
): AxiosTypedPromise<Savings> =>
	client.get<RetrieveSavingsResponse>(
		`/finances/${request.portCallId}/jobs/${request.jobCode}/costsavings`
	);

const retrieveTimeSavings = (
	request: RetrieveSavingsRequest
): AxiosTypedPromise<TimeSavings> =>
	client.get<RetrieveTimeSavingsResponse>(
		`/finances/${request.portCallId}/jobs/${request.jobCode}/timesavings`
	);

const retrieveOperationsValues = () =>
	client.get<RetrieveOperationsValuesResponse>(
		'/finances/static/costsavings/categories'
	);

const retrievePortjobCurrencies = ({
	portCallId,
	jobCode
}: RetrieveSavingsRequest) =>
	client.get<string[]>(
		`/finances/${portCallId}/jobs/${jobCode}/costsavings/currencies`
	);

const addCostSavings = ({ portCallId, jobCode, data }: AddCostSavingsRequest) =>
	client.post(`/finances/${portCallId}/jobs/${jobCode}/costsavings`, data);

const addTimeSavings = ({ portCallId, jobCode, data }: AddTimeSavingsRequest) =>
	client.post(`/finances/${portCallId}/jobs/${jobCode}/timesavings`, data);

const updateCostSavings = ({
	portCallId,
	jobCode,
	data
}: UpdateCostSavingsRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/costsavings`, data);

const updateTimeSavings = ({
	portCallId,
	jobCode,
	data
}: UpdateTimeSavingsRequest) =>
	client.put(`/finances/${portCallId}/jobs/${jobCode}/timesavings`, data);

const deleteCostSavings = ({
	portCallId,
	jobCode,
	id
}: DeleteCostSavingsRequest) =>
	client.delete(`/finances/${portCallId}/jobs/${jobCode}/costsavings/${id}`);

const deleteTimeSavings = ({
	portCallId,
	jobCode,
	id
}: DeleteTimeSavingsRequest) =>
	client.delete(`/finances/${portCallId}/jobs/${jobCode}/timesavings/${id}`);

export default {
	retrieveSavings,
	retrieveTimeSavings,
	retrieveOperationsValues,
	retrievePortjobCurrencies,
	addCostSavings,
	addTimeSavings,
	updateCostSavings,
	updateTimeSavings,
	deleteCostSavings,
	deleteTimeSavings
};
