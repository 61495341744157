import { omitBy, isEmpty, isNil } from 'lodash';
import runValidations, { required, charMax, minValue } from 'utils/validations';
import {
	PortJobCharterer,
	FormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

const validate = (values: FormData) => {
	const errors: object[] = [];
	if (!values.charterers) {
		return errors;
	}
	values.charterers.forEach((charterer, index) => {
		let error: Partial<PortJobCharterer> = {};
		if (charterer.isOtherRebillablesApplied) {
			error.financialInstructions = runValidations(
				charterer.financialInstructions,
				[required]
			) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
		}
		if (charterer.timeBarisApplied) {
			error.timeBarDays = runValidations(charterer.timeBarDays, [
				required,
				minValue(0),
				charMax(5)
			]) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
		}
		if (charterer.maxPortCost) {
			error.maxPortCostAmount = runValidations(charterer.maxPortCostAmount, [
				required
			]) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
		}
		error = omitBy(error, isNil);
		if (!isEmpty(error)) {
			errors[index] = error;
		}
	});
	return errors;
};

export default validate;
