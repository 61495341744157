import React from 'react';
import { FinanceFundingFormField } from 'store/form/financeFundings/financeFundingTypes';
import { TextArea } from 'components/antd';
import { FundingRequestMetadataKey } from 'services/api/financeFundings/financeFundingsServiceTypes';
import { hasPropToEditByFundingRequestId } from 'store/financeFundings/selectors';
import { connect } from 'react-redux';
import { Text, Content } from 'components';
import styles from './FundingRequestComment.module.scss';
import { NEW_FUNDING_ID } from 'store/financeFundings/financeFundingsConstants';
import { AppState } from 'store-types';

interface FundingCommentProps {
	fundingId: string;
	isEditable: boolean;
	comment?: string;
}

const FundingRequestComment = (props: FundingCommentProps) => {
	const { comment, isEditable } = props;
	return (
		<Content>
			{isEditable ? (
				<TextArea.ReduxFormItem
					name={FinanceFundingFormField.COMMENT}
					label="Comment (required in case of rejection)"
					maxLength={240}
					rows={3}
				/>
			) : (
				<div className={styles.comment}>
					<Text>{comment}</Text>
				</div>
			)}
		</Content>
	);
};

export default connect(
	(state: AppState, props: Pick<FundingCommentProps, 'fundingId'>) => ({
		isEditable:
			props.fundingId === NEW_FUNDING_ID ||
			hasPropToEditByFundingRequestId(
				state,
				props.fundingId,
				FundingRequestMetadataKey.COMMENT
			)
	})
)(FundingRequestComment);
