import { Success, Failure } from 'typescript-fsa';

import { FetchStatus } from 'services/api/apiTypes';
import { MessagesState } from '../messagesState';
import {
	ContactRequest,
	ContactType
} from 'services/api/messages/messagesServiceTypes';
import { retrieveContact, retrieveContactAsync } from '../actions';

function onRetrieveContactStarted(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieving: FetchStatus.PENDING
		}
	};
}

function onRetrieveContactSuccess(
	state: MessagesState,
	action: Success<ContactRequest, ContactType>
): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieving: FetchStatus.SUCCESS
		},
		currentContact: action.result
	};
}

function onRetrieveContactFailed(
	state: MessagesState,
	action: Failure<ContactRequest, Error>
): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieving: FetchStatus.FAILURE
		},
		error: action.error
	};
}

export const retrieveContactAction = {
	action: retrieveContact,
	async: retrieveContactAsync,
	started: onRetrieveContactStarted,
	success: onRetrieveContactSuccess,
	failed: onRetrieveContactFailed
};
