import * as React from 'react';
import { Link } from 'components';
import { Button, Modal } from 'components/antd';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { closeByCancel, closeByConfirm } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { redirectToVP } from 'store/dashboard/actions';
import {
	DASHBOARD_TRACK_MODAL,
	DASHBOARD_COMMIT_MODAL
} from 'store/dashboard/constants';

interface JobModalsProps {
	isCommitModalVisible: boolean;
	isTrackModalVisible: boolean;
	closeByCancel: typeof closeByCancel;
	closeByConfirm: typeof closeByConfirm;
	redirect: typeof redirectToVP;
}

class JobModals extends React.Component<JobModalsProps> {
	onTrack = () => {
		this.props.closeByConfirm(DASHBOARD_TRACK_MODAL);
	};

	onCommit = () => {
		this.props.closeByConfirm(DASHBOARD_COMMIT_MODAL);
	};

	onClose = (name: string) => () => this.props.closeByCancel(name);

	render() {
		const { isCommitModalVisible, isTrackModalVisible, redirect } = this.props;
		return (
			<>
				<Modal
					destroyOnClose
					visible={isCommitModalVisible}
					title={`Commit Programme`}
					width={380}
					onCancel={this.onClose(DASHBOARD_COMMIT_MODAL)}
					okButton={
						<Button
							key={DASHBOARD_COMMIT_MODAL}
							type="primary"
							onClick={this.onCommit}
						>
							Commit
						</Button>
					}
				>
					You are about to commit your VP. If you want to make sure it is
					updated properly, please, navigate to{' '}
					<Link onClick={() => redirect()}>view the VP</Link>
				</Modal>
				<Modal
					destroyOnClose
					visible={isTrackModalVisible}
					title={`On Track`}
					width={380}
					onCancel={this.onClose(DASHBOARD_TRACK_MODAL)}
					okButton={
						<Button
							key={DASHBOARD_TRACK_MODAL}
							type="primary"
							onClick={this.onTrack}
						>
							On Track
						</Button>
					}
				>
					You are about to confirm that your VP is up to date. If you want to
					make sure it is, please, navigate to{' '}
					<Link onClick={() => redirect()}>view the VP</Link>
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isCommitModalVisible: isModalVisible(state, DASHBOARD_COMMIT_MODAL),
		isTrackModalVisible: isModalVisible(state, DASHBOARD_TRACK_MODAL)
	}),
	{
		closeByCancel,
		closeByConfirm,
		redirect: redirectToVP
	}
)(JobModals);
