import { getIsLoading } from 'store/selectors';
import { AppState } from 'store-types.d';
import { createSelector } from 'reselect';

import {
	FinanceAdditionalDocument,
	FinanceAdditionalDocumentActionCode
} from 'services/api/finance/financeServiceTypes';
import { getFinance } from './financeSelectors';
import { getEntityMetadataAction } from 'store/metadata/utils/metadataUtils';

export const getAllAdditionalDocuments = createSelector(
	getFinance,
	(finance): FinanceAdditionalDocument[] => {
		return finance ? finance.additionalDocuments : [];
	}
);

export const getIsOtherDocumentationVisible = createSelector(
	getAllAdditionalDocuments,
	docs => Boolean(docs.length)
);

export const getIsDeleteEnabledById = createSelector(
	getAllAdditionalDocuments,
	(_state: AppState, id: string) => id,
	(docs, id) => {
		const file = docs.find(doc => doc.id === id);
		if (!file) {
			return false;
		}

		return (
			file.metadata &&
			!!getEntityMetadataAction<FinanceAdditionalDocumentActionCode>(
				file.metadata.actions,
				FinanceAdditionalDocumentActionCode.DELETE
			)
		);
	}
);

const getDownloadDocumentByIdStatus = ({ finance }: AppState, id: string) =>
	finance.fetchStatuses.downloadDocumentById[id];

export const getIsAdditionalDocumentBeingDownloaded = createSelector(
	getDownloadDocumentByIdStatus,
	doc => {
		return getIsLoading(doc);
	}
);
