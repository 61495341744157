import client from 'services/client';
import {
	ApiSubscriptionParams,
	DeveloperPortalData,
	DeveloperPortalDataRequest,
	UpdateApiSubscriptionParams,
	DownloadApiSubscriptionRequest,
	DownloadApiSubscriptionResponse
} from './developerPortalServiceTypes';

const retrieveDeveloperPortal = ({
	userId,
	companyId
}: DeveloperPortalDataRequest) =>
	client.get<DeveloperPortalData>(`users/${userId}/Company/${companyId}`);

const createNewApiSubscription = ({
	userId,
	hubPrincipalCompanyId,
	...params
}: ApiSubscriptionParams) =>
	client.post(`users/${userId}/Company/${hubPrincipalCompanyId}`, params);

const updateApiSubscription = ({
	userId,
	hubPrincipalCompanyId,
	...params
}: UpdateApiSubscriptionParams) =>
	client.put(`users/${userId}/Company/${hubPrincipalCompanyId}`, params);

const downloadApiSubscription = ({
	id,
	fileType
}: DownloadApiSubscriptionRequest) =>
	client.get<DownloadApiSubscriptionResponse>(
		`documents/${id}/downloadUrl/${fileType}`
	);

export default {
	retrieveDeveloperPortal,
	createNewApiSubscription,
	updateApiSubscription,
	downloadApiSubscription
};
