import { DashboardState as State } from '../dashboardState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveDashboardTableauResponse as Response } from 'services/api/dashboard/dashboardServiceTypes';
import { Success } from 'typescript-fsa';

export const onRetrieveDashboardTableau = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	null,
	Response
>('fetchStatuses', 'retrieveTableau', {
	doneReducer: (state: State, action: Success<null, Response>): State => ({
		...state,
		tableau: action.result
	})
});
