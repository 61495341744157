import { UpdateServiceSection } from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState as State } from '../portJobsState';

export const onUpdateEnableOperations = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	UpdateServiceSection,
	null
>('fetchStatuses', 'updateEnableOperations');
