import { FeedbackManagementRequestId } from 'services/api/companies/companiesServiceTypes';
import {
	deleteFeedbackManagementRequestAsync,
	retrieveFeedbackManagementGrid
} from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { put } from 'redux-saga/effects';

export default makeTakeLatestWatcher<FeedbackManagementRequestId, null, Error>({
	api: Api.Companies.deleteFeedbackManagementRequest,
	async: deleteFeedbackManagementRequestAsync,
	*getApiParams(params) {
		return {
			feedbackId: params.feedbackId
		};
	},
	*onSuccess() {
		yield put(retrieveFeedbackManagementGrid());
	}
});
