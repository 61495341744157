import * as React from 'react';
import classNames from 'classnames';
import styles from './Fit.module.scss';

interface FitProps {
	className?: string;
	style?: React.CSSProperties;
}

export const Fit: React.FC<FitProps> = props => {
	const { className, ...restProps } = props;
	return (
		<div className={classNames(className, styles.root)} {...restProps}>
			{props.children}
		</div>
	);
};

export default Fit;
