import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import { retrieveUnits, retrieveUnitsAsync } from '../actions/retrieveUnits';

export function* retrieveUnitsWorker(): SagaIterator {
	yield put(retrieveUnitsAsync.started(undefined));
	try {
		const response = yield call(Api.Units.retrieveUnits);
		yield put(
			retrieveUnitsAsync.done({
				result: response.data.elements,
				params: undefined,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveUnitsAsync.failed({
				error,
				params: undefined
			})
		);
	}
}

export function* retrieveUnitsWatcher(): SagaIterator {
	yield takeLatest(retrieveUnits.type, retrieveUnitsWorker);
}
