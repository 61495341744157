import { AppState } from 'store-types';
import {
	POIDraft,
	POIContext,
	CompanyDraft,
	NewCompanyDraft,
	CompanyDraftType
} from '../draftsState';
import { createSelector } from 'reselect';
import { RotationStepPOIType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { isNewCompanyDraft } from '../draftsHelpers';

export const getActiveDraftType = (state: AppState) =>
	state.drafts.context.activeDraftType;

export const getActiveDraftTypeAlias = (state: AppState) =>
	state.drafts.context.activeDraftTypeAlias;

export const getActiveDraftFormId = (state: AppState) =>
	state.drafts.context.activeFormId;

export const getActiveDraftFieldName = (state: AppState) =>
	state.drafts.context.activeFieldName;

export const getActivePOIContext = (state: AppState) =>
	state.drafts.context.activePOIContext || ({} as POIContext);

export const getActiveTerminalId = (state: AppState, rotationStepid: string) =>
	state.drafts.context.activeTerminalIdByRotationStepId[rotationStepid];

export const getAllCompanyDraftsByType = (
	state: AppState,
	draftType: CompanyDraftType
): CompanyDraft[] =>
	state.drafts.draftsByType[draftType]
		? state.drafts.draftsByType[draftType]
		: [];

export const getNewlyCreatedCompanyDraftsFromType = createSelector(
	getAllCompanyDraftsByType,
	(drafts): NewCompanyDraft[] => drafts.filter(isNewCompanyDraft)
);

export const getCompanyDraftsByType = createSelector(
	getAllCompanyDraftsByType,
	drafts => drafts.filter(company => company.isDraft !== false)
);

export const getPOIDrafts = (state: AppState) =>
	state.drafts.draftsByType.pointOfInterest;

export const getPOIDraftsByPOIType = createSelector(
	getPOIDrafts,
	(_state: AppState, POIType: RotationStepPOIType) => POIType,
	(POIDrafts: POIDraft[], POIType) =>
		POIDrafts.filter(draft => draft.portPOITypeCode === POIType)
);

export const getBerthDrafts = createSelector(
	[
		getActiveTerminalId,
		(state: AppState) =>
			state.drafts.draftsByType.pointOfInterest.filter(
				draft => draft.portPOITypeCode === RotationStepPOIType.BERTH
			)
	],
	(terminalId, drafts) =>
		drafts.filter(draft => draft.parentPOIId === terminalId)
);

export const getActiveTerminalDraft = createSelector(
	getActiveTerminalId,
	getPOIDrafts,
	(activeTerminalId, drafts) =>
		drafts.find(
			draft =>
				draft.portPOITypeCode === RotationStepPOIType.TERMINAL &&
				draft.id === activeTerminalId
		)
);
