import AssignedJobsField from './AssignedJobsField/AssignedJobsField';
import AssignedToField from './AssignedToField';
import IgnorePageField from './IgnorePageField';
import DocumentTypeField from './DocumentTypeField';
import CopyPageButton from './CopyPageButton';

export {
	AssignedJobsField,
	AssignedToField,
	IgnorePageField,
	DocumentTypeField,
	CopyPageButton
};
