import React from 'react';
import { Row, Col, TextArea, InputNumber } from 'components/antd';
import { Content } from 'components';
import { Align } from 'components/types';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import styles from './CrewChange.module.scss';
import {
	InjectedFormProps,
	isInvalid,
	getFormMeta,
	FieldState
} from 'redux-form';
import { connect } from 'react-redux';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';
import { AppState } from 'store-types';

export enum CrewChangeFormNames {
	ON_SIGNERS = 'onSigners',
	OFF_SIGNERS = 'offSigners',
	IMMIGRATION_ASSISTANCES = 'immigrationAssistances',
	LETTERS_OF_INVITATION = 'lettersOfInvitation',
	OK_TO_BOARD_LETTERS = 'okToBoardLetters',
	VISA_ASSISTANCES = 'visaAssistances',
	CUSTOM_CLEARANCES = 'customerClearences', // There is a difference between real name and hwo we use it
	SHORE_PASSES = 'shorePasses',
	MEET_AND_GREETS = 'meetAndGreets',
	LOST_LUGGAGE_ASSISTANCES = 'lostLuggageAssistances',
	DETAILS = 'details'
}

const MAX_ALLOWED_DIGITS = 4;

const InputCol = ({ name, label }: { name: string; label?: string }) => (
	<Col xs={2}>
		<InputNumber.ReduxFormItem
			decimalPart={0}
			maxDigits={MAX_ALLOWED_DIGITS}
			withDelimiter={false}
			name={name}
			label={label}
		/>
	</Col>
);

const LabelCol = ({ label, size = 4 }: { label: string; size?: number }) => (
	<Col xs={size} className={styles.labelCol}>
		{label}
	</Col>
);

const ValidationErrorMessage = ({ show = false }: { show: boolean }) =>
	show ? (
		<Col xs={6} className={styles.validationError} align={Align.MIDDLE}>
			at least one of these 2 fields is required
		</Col>
	) : null;

interface FormMeta {
	onSigners?: FieldState;
	offSigners?: FieldState;
}

interface CrewChangeProps extends InjectedFormProps<FormData> {
	formMeta: FormMeta;
	formIsInValid: boolean;
}

const hasError = (formMeta: FormMeta, formIsInValid: boolean) => {
	const offSignersIsTouched =
		formMeta.offSigners && formMeta.offSigners.touched;
	const onSignersIsTouched = formMeta.onSigners && formMeta.onSigners.touched;

	return !!((offSignersIsTouched || onSignersIsTouched) && formIsInValid);
};

class CrewChange extends React.Component<CrewChangeProps> {
	render() {
		return (
			<>
				<Content className={styles.root} fullHeight={false}>
					<Row>
						<InputCol
							name={CrewChangeFormNames.ON_SIGNERS}
							label="On Signers"
						/>
						<InputCol
							name={CrewChangeFormNames.OFF_SIGNERS}
							label="Off Signers"
						/>
						<ValidationErrorMessage
							show={hasError(this.props.formMeta, this.props.formIsInValid)}
						/>
					</Row>
					<h4>
						<strong>Crew related services</strong>
					</h4>
					<Row>
						<LabelCol size={2} label="Qty" />
						<LabelCol label="Service" />
						<LabelCol size={2} label="Qty" />
						<LabelCol label="Service" />
					</Row>
					<Row>
						<InputCol name={CrewChangeFormNames.IMMIGRATION_ASSISTANCES} />
						<LabelCol label="Immigration assistance" />
						<InputCol name={CrewChangeFormNames.CUSTOM_CLEARANCES} />
						<LabelCol label="Custom Clearance" />
					</Row>
					<Row>
						<InputCol name={CrewChangeFormNames.LETTERS_OF_INVITATION} />
						<LabelCol label="Letter of Invitation" />
						<InputCol name={CrewChangeFormNames.SHORE_PASSES} />
						<LabelCol label="Shore pass" />
					</Row>
					<Row>
						<InputCol name={CrewChangeFormNames.OK_TO_BOARD_LETTERS} />
						<LabelCol label="Ok to Board letter" />
						<InputCol name={CrewChangeFormNames.MEET_AND_GREETS} />
						<LabelCol label="Meet and Greet at the airport" />
					</Row>
					<Row>
						<InputCol name={CrewChangeFormNames.VISA_ASSISTANCES} />
						<LabelCol label="Visa assistance" />
						<InputCol name={CrewChangeFormNames.LOST_LUGGAGE_ASSISTANCES} />
						<LabelCol label="Lost luggage assistance" />
					</Row>
				</Content>
				<Content>
					<h4 className={styles.detailsHeader}>
						<strong>CREW CHANGE DETAILS</strong>
					</h4>
					<TextArea.ReduxFormItem
						name={CrewChangeFormNames.DETAILS}
						rows={4}
						maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
					/>
				</Content>
			</>
		);
	}
}

export default connect((state: AppState) => ({
	formMeta: getFormMeta(FORM.portJob)(state) || {},
	formIsInValid: isInvalid(FORM.portJob)(state)
}))(CrewChange);
