import actionCreator from '../portCallMeasurementsActionCreator';
import {
	RetrievePortCallBunkeringMeasurementsRequest,
	RetrievePortCallBunkeringMeasurementsResponse
} from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

const ACTION_NAME = 'RETRIEVE_BUNKERING_MEASUREMENTS';
export const retrievePortCallBunkeringMeasurementsAsync = actionCreator.async<
	RetrievePortCallBunkeringMeasurementsRequest,
	RetrievePortCallBunkeringMeasurementsResponse,
	Error
>(ACTION_NAME);

export const retrievePortCallBunkeringMeasurements = actionCreator<
	RetrievePortCallBunkeringMeasurementsRequest
>(ACTION_NAME);
