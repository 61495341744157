import actionCreator from '../groupsActionCreator';

const ACTION_NAME = 'INITIALIZE_EDIT_GROUP';
export const initializeEditGroupAsync = actionCreator.async<
	string,
	null,
	Error
>(ACTION_NAME);

export const initializeEditGroup = actionCreator<string>(ACTION_NAME);
