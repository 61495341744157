import * as React from 'react';
import { connect } from 'react-redux';
import { PageHeader } from 'components';
import Filters from 'components/Filters/Filters';
import { Button, Col, Menu, Popover, Row } from 'components/antd';
import ThreadsFilters from './ThreadsFilters/ThreadsFilters';
import { Justify } from 'components/types';
import { FilterState, FilterItem } from 'store/filters/filtersState';
import { getFiltersResultByKey } from 'store/filters/filtersSelectors';
import { THREADS_FILTER_NAME, searchTypeMap } from 'store/threads/filtersSync';
import { resetThreads, resetThreadsContext } from 'store/threads/actions';
import {
	getThreadsFilters,
	getTotalUnreadMessagesCount
} from 'store/threads/selectors';
import { AppState } from 'store-types';
import styles from './ThreadHeader.module.scss';
import { ThreadsSettingsMenuAction } from './ThreadCategorizing/constants';

interface ThreadsHeaderProps {
	onNewMessage: () => void;
	onMenuClick: (key: string) => void;
	filters: FilterState;
	filtersResult: FilterItem[];
	unreadMessagesCount: number;
	resetThreads: typeof resetThreads;
	resetThreadsContext: typeof resetThreadsContext;
	onModeChange: (isPreviewModeEnabled: boolean) => void;
}

interface ThreadsHeaderState {
	visible: boolean;
}

export class ThreadsHeader extends React.Component<
	ThreadsHeaderProps,
	ThreadsHeaderState
> {
	constructor(props: ThreadsHeaderProps) {
		super(props);
		this.state = {
			visible: false
		};
	}

	static defaultProps: Partial<ThreadsHeaderProps> = {
		filters: {}
	};

	handleNewMessage = () => {
		this.props.onNewMessage();
	};

	handleOnChangeInvoked = () => {
		this.props.resetThreadsContext();
		this.props.resetThreads();
	};

	onMenuClick = (e: { key: string }) => {
		this.props.onMenuClick(e.key);
		this.setState({ visible: false });
	};

	handleVisibleChange = (data: boolean) => {
		this.setState({ visible: data });
	};

	render() {
		const { filtersResult, unreadMessagesCount } = this.props;
		return (
			<PageHeader stretch shadowed>
				<Filters
					name={THREADS_FILTER_NAME}
					invokeOnChange={this.handleOnChangeInvoked}
					destroyOnUnmount={false}
				>
					{({ onChange, clearOne, clearAll }) => (
						<>
							<Row middle="xs">
								<Col sm={11} xs={12}>
									<ThreadsFilters
										onChange={onChange}
										clearOne={clearOne}
										clearAll={clearAll}
										unreadCount={unreadMessagesCount}
										onModeChange={this.props.onModeChange}
									/>
								</Col>
								<Col
									sm={1}
									xs={12}
									justify={Justify.END}
									className={styles.newMessageBtn}
								>
									<Button
										type="primary"
										className="qaa_new_message_button"
										onClick={this.handleNewMessage}
									>
										New Message
									</Button>
									<Popover
										content={
											<Menu onClick={this.onMenuClick}>
												<Menu.Item key={ThreadsSettingsMenuAction.ASSIGN_TO}>
													Assign To
												</Menu.Item>
												<Menu.Item
													key={ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES}
												>
													Categorize Messages
												</Menu.Item>
											</Menu>
										}
										type="dropdown"
										trigger="click"
										placement="bottomRight"
										visible={this.state.visible}
										onVisibleChange={this.handleVisibleChange}
									>
										<Button
											disabled={false}
											type="primary"
											transparent
											icon="settings"
										/>
									</Popover>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<Filters.Tags
										tags={filtersResult}
										clearOne={clearOne}
										clearAll={clearAll}
										typeMap={searchTypeMap}
									/>
								</Col>
							</Row>
						</>
					)}
				</Filters>
			</PageHeader>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			filters: getThreadsFilters(state),
			filtersResult: getFiltersResultByKey(state, THREADS_FILTER_NAME),
			unreadMessagesCount: getTotalUnreadMessagesCount(state)
		};
	},
	{
		resetThreads,
		resetThreadsContext
	}
)(ThreadsHeader);
