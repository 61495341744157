import { call, put, select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { RevertJobStatusRequest } from 'services/api/finance/financeServiceTypes';
import { revertJobStatusAsync as async, retrieveFinance } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { Success } from 'typescript-fsa';

const api = Api.Finance.revertJobStatus;
const commonExecutor = makeDefaultExecutor<
	RevertJobStatusRequest,
	null,
	Error
>({
	api,
	async,
	*onSuccess({ params }: Success<RevertJobStatusRequest, null>) {
		yield put(retrieveFinance(params));
	}
});

export const executor = function*() {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);

	yield call(commonExecutor, {
		portCallId,
		jobCode
	});
};

export default makeTakeEveryWatcher<RevertJobStatusRequest, null, Error>(
	{ api, async },
	executor
);
