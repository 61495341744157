import React from 'react';
import { Flex, Text, FormattedTimeExtended } from 'components';
import { connect } from 'react-redux';
import { getPortJobByCode } from 'store/portJobs/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import styles from './JobHeaderSmall.module.scss';
import { AppState } from 'store-types';

interface JobHeaderSmallProps {
	portJobCode: string;
	job: PortJob;
	portcall: PortCall;
}

const LabelValue: React.FC<{ label: string }> = ({ label, children }) => (
	<div className={styles.label}>
		{label}: <Text weight="semi-bold">{children}</Text>
	</div>
);

const JobHeaderSmall = (props: JobHeaderSmallProps) => {
	if (!props.job) {
		return null;
	}
	return (
		<Flex direction="horizontal" className={styles.header}>
			<LabelValue label="Job Type">{props.job.jobType}</LabelValue>
			<LabelValue label="Appointer">
				{props.job.appointerCompany.name}
			</LabelValue>
			<LabelValue label="Performing Agent">
				{props.job.performingAgentCompany.name}
			</LabelValue>
			<LabelValue label="ETA">
				<FormattedTimeExtended
					value={props.portcall.etaPlt}
					directPortTimeZoneId={props.portcall.portTimezoneId}
				/>
			</LabelValue>
		</Flex>
	);
};

export default connect(
	(state: AppState, props: Pick<JobHeaderSmallProps, 'portJobCode'>) => {
		return {
			job: getPortJobByCode(state, props.portJobCode),
			portcall: getActivePortCall(state)
		};
	}
)(JobHeaderSmall);
