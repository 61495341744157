import { omit } from 'lodash';
import { FiltersState, initialState } from 'store/filters/filtersState';

export const onDestroy = (
	state: FiltersState,
	name: string | 'all'
): FiltersState => {
	if (name === 'all') {
		return initialState;
	}

	const filters = omit(state, name);
	return {
		...filters
	};
};
