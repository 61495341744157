export const PORTCALL_ID_URL_PARAM = ':portCallId';
export const PORTJOB_ID_URL_PARAM = ':portJobId';
export const PORTJOB_CODE_URL_PARAM = ':portJobCode';
export const THREAD_ID_URL_PARAM = ':threadId';
export const PORT_CALL_OPERATION_TAB_ID = ':operationTabId';
export const PORT_CALL_MEASUREMENTS_TAB_ID = ':measurementsTabId';
export const PORT_CALL_NEW_VP = ':isNewVP';
export const DOCUMENT_ID = ':documentId';
export const DATA_MANAGEMENT_TAB_ID = ':dataManagementTabId';
export const CONFIGURATION_TAB_ID = ':configurationTabId';
export const OPTIC_CONFIGURATION_TAB_ID = ':opticConfigurationTabId';
export const DATA_MANAGEMENT_DIRECT_BILL_ID = ':directBillId';
export const FINANCE_FUNDING_ID = ':fundingId';
export const FINANCE_FUNDING_DA_ID = ':daId';
export const BANK_ID = ':bankId';
export const SUPPLIER_INVOICE_ID = ':supplierInvoiceId';
export const SERVICE_ID = ':serviceId';
export const MESSAGE_ID = ':messageId';
export const MESSAGE_ACTION = ':action';
export const MASTER_DATA_EXPLORER_TAB_ID = ':tabId';
export const KPIS_ID = ':kpiId';
export const DOCUMENT_TYPE = ':documentType';
export const ID = ':id';
export const PAGE_NUMBER = ':pageNumber';
export const PAGE_TYPE = ':pageType';

export const PATHS = {
	dashboard: 'dashboard',
	messages: 'messages',
	users: 'users',
	groups: 'groups',
	portCalls: 'portcalls',
	configuration: 'hub-principal-configuration',
	opticConfiguration: 'optic-configuration',
	opticDataManagement: 'data-management',
	administration: 'administration',
	bankAccountDetails: 'bank-account-details',
	supplierinvoices: 'supplier-invoices',
	masterDataExplorer: 'master-data-explorer',
	thirdPartyLPAGroups: 'third-party-lpa-groups',
	developerPortal: 'developer-portal'
};
