import * as React from 'react';
import { connect } from 'react-redux';
import Dropdown from 'antd/lib/dropdown';
import { Menu, Icon } from 'components/antd';
import { Flex, Truncate, Text } from 'components';
import { ActAs } from 'containers';
import {
	getAuthUserName,
	getAuthUserType,
	getUserHasQuarterMasterApplicationAccess,
	getAuthUserId,
	getUserHasLpaApplicationAccess
} from 'store/auth/selectors';
import styles from './UserProfile.module.scss';
import { navigateTo } from 'utils';
import { UserType } from 'services/api/users/userServiceTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getAuthHasPermission } from 'store/auth/selectors/permissionsSelector';
import { AppState } from 'store-types';
import config from 'services/config';
import { Button } from 'antd';
import {
	USER_SWITCH_TO_QM,
	USER_SWITCH_QM_PARAM,
	USER_SWITCH_TO_LPA,
	USER_SWITCH_LPA_PARAM
} from 'app-constants';
import { setUserChoice } from 'services/storage/appRedirectStorage';
interface UserProfileProps {
	userName: string;
	userType: UserType;
	canActAs: boolean;
	hasQMAccess: boolean;
	userId: string;
	hasLPAAccess: boolean;
}

class UserProfile extends React.Component<UserProfileProps> {
	onLogoutClick = () => {
		navigateTo('/logout');
	};

	onLoginClick = () => {
		navigateTo('/profile');
	};

	onMenuClick = (e: { key: string }) => {
		switch (e.key) {
			case 'logout':
				this.onLogoutClick();
				break;
			case 'profile':
				this.onLoginClick();
				break;
			case 'redirect':
				setUserChoice(this.props.userId, USER_SWITCH_TO_QM);
				window.location.replace(
					`${config.quartermasterAppUrl}?${USER_SWITCH_QM_PARAM}`
				);
				break;
			case 'redirectToLPA':
				setUserChoice(this.props.userId, USER_SWITCH_TO_LPA);
				window.location.replace(`${config.lpaAppUrl}?${USER_SWITCH_LPA_PARAM}`);
				break;
			default:
				break;
		}
	};

	render() {
		const menu = (
			<Menu onClick={this.onMenuClick}>
				<Menu.Item key="profile">Profile</Menu.Item>
				<Menu.Item key="logout">Logout</Menu.Item>
				{this.props.userType === UserType.PRINCIPAL && this.props.hasQMAccess && (
					<Menu.Item key="redirect">
						<b>DA Management</b>{' '}
						<Button className={styles.newButton}>New</Button>
						<p>
							<Text weight="light">
								OneCape is a better way to manage <br />
								your Disbursement Accounts (DAs).
							</Text>
						</p>
						<p>
							<Text weight="bold" className={styles.newButtonText}>
								Try it now
							</Text>
						</p>
					</Menu.Item>
				)}
				{this.props.userType === UserType.LPA && this.props.hasLPAAccess && (
					<Menu.Item key="redirectToLPA">
						<b>Optic LPA</b> <Button className={styles.newButton}>New</Button>
						<p>
							<Text weight="light">
								Description that helps the user to understand
								<br />
								the app and its relevance
							</Text>
						</p>
						<p>
							<Text weight="bold" className={styles.newButtonText}>
								Try it now
							</Text>
						</p>
					</Menu.Item>
				)}
			</Menu>
		);

		return (
			<Flex className={styles.root} align="center">
				{this.props.canActAs && (
					<Flex>
						<ActAs />
					</Flex>
				)}
				<Flex
					self="center"
					onClick={this.onLoginClick}
					className={styles.username}
				>
					<div className={styles.usernameContent}>
						<Text upper size="xs" weight="light">
							{this.props.userType}
						</Text>
						<br />
						<Truncate>
							<Text weight="semi-bold">{this.props.userName}</Text>
						</Truncate>
					</div>
				</Flex>

				<Flex className={styles.root} align="center">
					{((this.props.userType === UserType.PRINCIPAL &&
						this.props.hasQMAccess) ||
						(this.props.userType === UserType.LPA &&
							this.props.hasLPAAccess)) && (
						<Button className={styles.newButton} icon="">
							New
						</Button>
					)}
					<Dropdown overlay={menu} trigger={['click']}>
						<Icon
							type="caret-down"
							size="xs"
							className="qaa_user_menu_dropdown"
						/>
					</Dropdown>
				</Flex>
			</Flex>
		);
	}
}

export default connect((state: AppState) => ({
	userName: getAuthUserName(state),
	userType: getAuthUserType(state),
	canActAs: getAuthHasPermission(state, PermissionCode.ACTS_AS_LPA),
	hasQMAccess: getUserHasQuarterMasterApplicationAccess(state),
	userId: getAuthUserId(state),
	hasLPAAccess: getUserHasLpaApplicationAccess(state)
}))(UserProfile);
