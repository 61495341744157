import React from 'react';
import { UploadDocumentButton } from 'containers/index';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components/index';
import { uploadDocumentDone } from 'store/supplierInvoice/actions/index';
import { UploadType } from 'services/api/documents/documentsServiceTypes';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';
import styles from './UploadInvoiceButton.module.scss';

const UploadInvoiceButton: React.SFC = () => (
	<Permission
		permissionCode={[
			PermissionCode.VIEW_SUPPLIER_INVOICES,
			PermissionCode.MANAGE_SUPPLIER_INVOICES
		]}
	>
		{(hasViewInvoicesPermission, hasManageInvoicesPermission) =>
			hasViewInvoicesPermission && (
				<MaybeTooltip
					title="You don't have permission to perform this action"
					show={!hasManageInvoicesPermission}
					placement="bottom"
				>
					<div className={styles.uploadButton}>
						<UploadDocumentButton
							onDone={uploadDocumentDone}
							uploadType={UploadType.SUPPLIER_INVOICE}
							disabled={!hasManageInvoicesPermission}
							metadata={{ contextualizePdf: true }}
						>
							Add supplier invoice
						</UploadDocumentButton>
					</div>
				</MaybeTooltip>
			)
		}
	</Permission>
);

export default UploadInvoiceButton;
