import { call, put, select, delay } from 'redux-saga/effects';
import { includes } from 'lodash';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	ExtendedMessagingData,
	ExtendedMessagingRequest
} from 'services/api/portJobs/portJobsServiceTypes';
import { retrieveExtendedMessagingDataAsync } from '../actions';
import {
	getActivePortCall,
	getActivePortCallId
} from 'store/portcalls/selectors';
import {
	getActivePortJobCode,
	getPortJobConcurrencyTokenByCode
} from 'store/portJobs/selectors';
import { notify } from 'store/notifications/actions';
import { closeModal } from 'store/modals/actions';
import { getValues } from 'store/form/selectors';

import {
	EXTENDED_MESSAGING_MODAL,
	REQUEST_PDA_EXTENDED_MAIL_MODAL
} from 'sections/PortCall/PortJob/ActionModal/AppointToPortJobModal/AppointToPortJobModal';
import { SagaIterator } from 'redux-saga';
import { EXTENDED_MAIL_TYPE } from 'sections/PortCall/PortJob/PortJobConstants';
import {
	SendFinanceFormFields,
	SEND_FINANCE_FORM
} from 'sections/PortCall/Finance/Header/SendFinanceModal/SendFinanceModalTypes';
import { prefundRequested } from 'sections/PortCall/Finance/Header/SendFinanceModal/SendFinanceModal.func';

const api = Api.PortJobs.retrieveExtendedMessagingDatawithPayload;

const retrieveExtendedMessagingData =
	Api.PortJobs.retrieveExtendedMessagingData;

const prefundingApi = Api.PortJobs.retrievePrefundingExtendedMessagingData;

export const executor = function*(
	payload: ExtendedMessagingRequest
): SagaIterator {
	yield put(retrieveExtendedMessagingDataAsync.started(payload));
	try {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const concurrencyToken = yield select(
			getPortJobConcurrencyTokenByCode,
			jobCode
		);

		let response;
		if (payload.mailType === EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL) {
			response = yield call(api, {
				portCallId,
				jobCode,
				mailType: payload.mailType,
				serviceVendors: payload.serviceVendors,
				concurrencyToken
			});
		} else if (payload.mailType === EXTENDED_MAIL_TYPE.PRE_FUNDING_EMAIL) {
			const portCall = yield select(getActivePortCall);
			const formValues = yield select(getValues, SEND_FINANCE_FORM);
			yield delay(3000);
			response = yield call(prefundingApi, {
				portCallId,
				jobCode,
				mailType: payload.mailType,
				selectedStatus: formValues.jobStatus,
				sendPrefunding:
					formValues.isPrefundRequested ===
					SendFinanceFormFields.REQUEST_PREFUND_YES,
				portName: portCall.port.name,
				vesselName: portCall.vessel.name,
				fundingRequestUpdateFundingRequestList: prefundRequested(
					formValues.fundingTable
				),
				documentId: formValues?.documentId
			});
		} else {
			response = yield call(retrieveExtendedMessagingData, {
				portCallId,
				jobCode,
				mailType: payload.mailType
			});
		}

		yield put(
			retrieveExtendedMessagingDataAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveExtendedMessagingDataAsync.failed({
				error,
				params: payload
			})
		);
		yield put(closeModal({ name: EXTENDED_MESSAGING_MODAL }));
		if (payload.mailType === EXTENDED_MAIL_TYPE.REQUEST_QUOTE_EMAIL) {
			yield put(closeModal({ name: REQUEST_PDA_EXTENDED_MAIL_MODAL }));
			if (
				error.response.status === 400 &&
				includes(error.response.data.error, 'BadRequest')
			) {
				yield put(
					notify.error({
						description: 'Performing Agent email address mandatory.',
						duration: 0
					})
				);
				return;
			}
		}
		if (payload.mailType !== EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL) {
			const defaultMessage = `Error occurred while retrieving extended messages`;
			yield put(
				notify.error(error.response.data.PortJob?.[0] || defaultMessage)
			);
		}
	}
};

export default makeTakeLatestWatcher<
	ExtendedMessagingRequest,
	ExtendedMessagingData,
	Error
>(
	{
		api: api,
		async: retrieveExtendedMessagingDataAsync
	},
	executor
);
