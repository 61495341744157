import Api from 'services/api';
import { retrieveDashboardTableauInfoAsync as async } from '../actions';
import {
	DashboardTableauInfo as Response,
	RetrieveDashboardTableauInfoRequest as Request
} from 'services/api/dashboard/dashboardServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Dashboard.retrieveDashboardTableauInfo;
export default makeTakeLatestWatcher<Request, Response>({
	api,
	async
});
