import { FetchStatus } from 'services/api/apiTypes';
import {
	BankAccount,
	LOBDDocument
} from 'services/api/bankAccounts/bankAccountsServiceTypes';

export interface BankAccountsState {
	readonly byId: { [id: string]: BankAccount };
	readonly hasMore: boolean;
	readonly indexForNextFetch: number;
	readonly LOBD?: LOBDDocument;
	readonly fetchStatuses: {
		byId: FetchStatus;
		add: FetchStatus;
		update: FetchStatus;
		retrieveBanks: FetchStatus;
		retrieveBankAccount: FetchStatus;
		retrieveLOBD: FetchStatus;
		downloadLOBD: FetchStatus;
	};
	context: {
		activeBankId?: string;
	};
}

export const initialState: BankAccountsState = {
	byId: {},
	hasMore: true,
	indexForNextFetch: 0,
	fetchStatuses: {
		byId: FetchStatus.IDLE,
		add: FetchStatus.IDLE,
		update: FetchStatus.IDLE,
		retrieveBanks: FetchStatus.IDLE,
		retrieveBankAccount: FetchStatus.IDLE,
		retrieveLOBD: FetchStatus.IDLE,
		downloadLOBD: FetchStatus.IDLE
	},
	context: {}
};
