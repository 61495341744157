import * as React from 'react';
import classNames from 'classnames';
import styles from './Position.module.scss';
interface PositionProps {
	className?: string;
	type?: 'relative' | 'absolute';
	id?: string;
	fit?: boolean;
	position?: 'topLeft' | 'topRight' | 'center';
}
const Position: React.FC<PositionProps> = ({
	type = 'relative',
	fit,
	position = 'topLeft',
	...props
}) => {
	return (
		<div
			{...props}
			className={classNames(props.className, styles[type], {
				[styles.fit]: fit,
				[styles[position]]: type === 'absolute'
			})}
		>
			{props.children}
		</div>
	);
};

export default Position;
