import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	restorePortCallEventAsync,
	setPortCallEventAsHappened
} from '../actions/restorePortCallEvent';
import { getPortCallEventConcurrencyToken } from '../selectors';
import { getActivePortCallId } from '../../portcalls/selectors';
import Api from 'services/api';

export function* restorePortCallEventWorker({
	payload: eventId
}: Action<string>): SagaIterator {
	yield put(restorePortCallEventAsync.started(eventId));
	const portCallId = yield select(getActivePortCallId);
	const concurrencyToken = yield select(
		getPortCallEventConcurrencyToken,
		eventId
	);
	const restoreRequest = { portCallId, eventId };
	try {
		const response = yield call(Api.PortCall.restorePortCallEvent, {
			...restoreRequest,
			concurrencyToken
		});
		yield put(
			restorePortCallEventAsync.done({
				result: response.data,
				params: eventId,
				response
			})
		);

		yield put(setPortCallEventAsHappened(restoreRequest));
	} catch (error) {
		yield put(
			restorePortCallEventAsync.failed({
				error,
				params: eventId
			})
		);
	}
}

export default function* restorePortCallEventWatcher(): SagaIterator {
	while (true) {
		const payload = yield take(restorePortCallEventAsync.type);
		yield call(restorePortCallEventWorker, payload);
	}
}
