import React from 'react';
import { connect } from 'react-redux';

import { DataFetcher } from 'utils/components';
import PortCallContainer from 'sections/PortCall/PortCallContainer';
import DocumentPageViewer from './FinanceSplitSupplierInvoiceDocumentPageViewer';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';

import { BreadcrumbItem } from 'store/navigation';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { FetchStatus } from 'services/api/apiTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { AppState } from 'store-types';
import {
	getRetrieveSplitSupplierInvoiceDocumentFetchStatus,
	getSplitSupplierInvoiceServiceDocument
} from 'store/supplierInvoice/selectors';
import { retrieveSlitSupplierInvoiceDocument } from 'store/supplierInvoice/actions';
import { ErrorUnhandled } from 'components';
import {
	UploadCustomRequest,
	UploadType
} from 'services/api/documents/documentsServiceTypes';
import {
	cancelUploadAndProcessDocument,
	uploadAndProcessDocument
} from 'store/documents/actions';
import {
	restoreOriginalDocument,
	uploadAnnotatedDocumentationDone
} from 'store/finance/actions';
import { AppNotifications } from 'containers';

interface FinanceSplitSupplierDocumentPageViewerRouteProps
	extends PortCallTabBaseProps {
	document: PortCallDocument;
	breadcrumbs: BreadcrumbItem[];
	fetchStatus: FetchStatus;
	retrieveSlitSupplierInvoiceDocument: typeof retrieveSlitSupplierInvoiceDocument;
	uploadAndProcessDocument: typeof uploadAndProcessDocument;
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
	restoreOriginalDocument: typeof restoreOriginalDocument;
}
class FinanceSplitSupplierDocumentPageViewerRoute extends React.Component<
	FinanceSplitSupplierDocumentPageViewerRouteProps
> {
	componentWillUnmount(): void {
		this.props.cancelUploadAndProcessDocument({ shouldResetFile: true });
	}

	retrieveFinanceDocument = () => {
		const {
			match: {
				params: { supplierInvoiceId, serviceId }
			}
		} = this.props;
		this.props.retrieveSlitSupplierInvoiceDocument({
			serviceId,
			supplierInvoiceId
		});
	};

	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([
			{
				title: 'View Receipt'
			}
		]);

	onSaveAnnotation = (request: UploadCustomRequest, actualNumber: number) => {
		const {
			match: {
				params: {
					portCallId,
					portJobCode,
					supplierInvoiceId,
					serviceId,
					pageNumber
				}
			},
			document
		} = this.props;
		this.props.uploadAndProcessDocument({
			type: UploadType.SUPPLIER_INVOICE,
			request,
			showProgress: true,
			/**
			 * Once upload process starts from the FE we can stop polling for the completion.
			 * But in background document will process and attaches to the service.
			 * So need to hide the close button.
			 */
			hideClose: true,
			onDone: uploadAnnotatedDocumentationDone,
			metadata: {
				contextualizePdf: true,
				portCallId: portCallId,
				pageNumber: Number(actualNumber),
				actNumber: Number(pageNumber),
				isAnnotated: true,
				jobCode: portJobCode,
				jobFinanceId: document.jobFinanceId,
				supplierInvoiceId: supplierInvoiceId,
				portJobServiceId: serviceId
			}
		});
	};

	onRestoreOriginal = () => {
		const {
			match: {
				params: { supplierInvoiceId, serviceId }
			}
		} = this.props;
		this.props.restoreOriginalDocument({
			serviceId,
			supplierInvoiceId
		});
	};

	render() {
		const {
			match: {
				params: {
					portCallId,
					portJobCode,
					supplierInvoiceId,
					serviceId,
					pageNumber,
					pageType
				}
			},
			fetchStatus,
			document
		} = this.props;
		const baseUrl = `/portcalls/${portCallId}/jobs/${portJobCode}/service/${serviceId}/finance/documents/supplier-invoices-pages/${supplierInvoiceId}/preview`;

		return (
			<PortCallContainer match={this.props.match}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<AppNotifications.Notification />
				<DataFetcher
					fetchStatus={fetchStatus}
					dispatch={this.retrieveFinanceDocument}
					error={() => <ErrorUnhandled />}
				>
					{() => (
						<DocumentPageViewer
							portCallId={portCallId}
							jobCode={portJobCode}
							supplierInvoiceId={supplierInvoiceId}
							document={document}
							serviceId={serviceId}
							baseUrl={baseUrl}
							activePage={Number(pageNumber)}
							activePageType={pageType}
							onSaveAnnotation={this.onSaveAnnotation}
							onRestoreOriginal={this.onRestoreOriginal}
						/>
					)}
				</DataFetcher>
			</PortCallContainer>
		);
	}
}

export default connect(
	(state: AppState) => ({
		fetchStatus: getRetrieveSplitSupplierInvoiceDocumentFetchStatus(state),
		document: getSplitSupplierInvoiceServiceDocument(state),
		breadcrumbs: getPortCallLayoutBreadcrumbs(state)
	}),
	{
		retrieveSlitSupplierInvoiceDocument,
		uploadAndProcessDocument,
		cancelUploadAndProcessDocument,
		restoreOriginalDocument
	}
)(FinanceSplitSupplierDocumentPageViewerRoute);
