import Api from 'services/api';
import {
	retrieveThreadMessageById as action,
	retrieveThreadMessageByIdAsync as asyncAction
} from 'store/thread/actions/retrieveThreadMessageById';
import { RetrieveThreadMessageByIdParams as requestType } from 'services/api/messages/messagesServiceTypes';

import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';

const apiCall = Api.Messages.retrieveThreadMessageById;

export function* executor(
	request: requestType,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			asyncAction.done({
				result: response.data,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				error,
				params: request
			})
		);
	}
}

export function* worker({ payload }: Action<requestType>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
