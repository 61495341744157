import * as React from 'react';
import AutocompleteEntity, {
	AutocompleteEntityProps
} from '../AutocompleteEntity';
import Api from 'services/api';

interface AutocompleteVesselsProps extends AutocompleteEntityProps {}

export default class extends React.Component<AutocompleteVesselsProps> {
	onSearch = (searchTerm: string) =>
		Api.Vessels.searchVesselsForAutocomplete(searchTerm);

	render() {
		return <AutocompleteEntity onSearch={this.onSearch} {...this.props} />;
	}
}
