import * as React from 'react';
import { LabeledValue } from 'app-types';
import { Gap, Text, Link } from 'components';
import { Icon, Tooltip } from 'components/antd';
import { FormattedTime } from 'containers';
import { SupplierInvoicePortJob } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { PATHS } from 'sections/App/RouteParams';
import styles from './InvoicePortJobHeader.module.scss';

interface InvoicePortJobHeaderProps {
	portJob: Pick<
		SupplierInvoicePortJob,
		| 'id'
		| 'code'
		| 'port'
		| 'vessel'
		| 'eta'
		| 'eosp'
		| 'ets'
		| 'cosp'
		| 'portCallId'
	>;
	nbOfServices?: number;
}

interface TimeProps {
	mainTime: LabeledValue;
	secondTime: LabeledValue;
}

const Time = ({ mainTime, secondTime }: TimeProps) => {
	if (!mainTime.key && !secondTime.key) {
		return null;
	}
	return (
		<span>
			{mainTime.key ? mainTime.label : secondTime.label}: &nbsp;
			<FormattedTime value={mainTime.key || secondTime.key || ''} />
		</span>
	);
};

const formatNbOfServices = (nb = 0) =>
	`${nb} ${nb === 1 ? 'service' : 'services'}`;

const InvoicePortJobHeader: React.SFC<InvoicePortJobHeaderProps> = ({
	portJob: {
		code,
		port,
		vessel,
		portCallId,
		eta = '',
		eosp = '',
		ets = '',
		cosp = ''
	},
	nbOfServices
}) => {
	const eospOrEta = (
		<Time
			mainTime={{ key: eosp, label: 'EOSP' }}
			secondTime={{ key: eta, label: 'ETA' }}
		/>
	);
	const cospOrEts = (
		<Time
			mainTime={{ key: cosp, label: 'COSP' }}
			secondTime={{ key: ets, label: 'ETS' }}
		/>
	);
	const tooltipText = (
		<span>
			Port: {port} <br />
			Vessel: {vessel} <br />
			{eospOrEta} <br />
			{cospOrEts}
		</span>
	);

	return (
		<span>
			<span onClick={e => e.stopPropagation()}>
				<Link
					type="black"
					href={`/${PATHS.portCalls}/${portCallId}/jobs/${code}/finance`}
					target="_blank"
				>
					<Text decoration="underline">{code}</Text>
				</Link>
			</span>
			<Gap left="sm" right="sm">
				<Tooltip title={tooltipText}>
					<Icon type="info" color="primary" className={styles.infoIcon} />
				</Tooltip>
			</Gap>
			<Text weight="normal">{formatNbOfServices(nbOfServices)}</Text>
		</span>
	);
};

export default InvoicePortJobHeader;
