import { SagaIterator } from 'redux-saga';
import { put, takeLatest, select } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { resetPortCallEventAsync } from '../actions';
import { getActivePortCallId } from '../../portcalls/selectors';
import { getLastUpdatedPortCallEventId, getPortCallEvent } from '../selectors';
import { isEmpty } from 'lodash';

export function* resetPortCallEventWorker({
	payload: eventId
}: Action<string>): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const lastUpdatedEventId = yield select(getLastUpdatedPortCallEventId);
	if (!lastUpdatedEventId || eventId === lastUpdatedEventId) {
		return;
	}
	const lastUpdatedEvent = yield select(getPortCallEvent, lastUpdatedEventId);
	if (!lastUpdatedEvent) {
		return;
	}
	const { comment, error, datePlt } = lastUpdatedEvent;
	// checking if the field has an error state
	if (!isEmpty(error)) {
		// reset an error state and restore last valid value
		yield put(
			resetPortCallEventAsync.done({
				params: {
					eventId: lastUpdatedEventId,
					portCallId,
					comment,
					realDatePlt: datePlt
				},
				result: undefined,
				response: null
			})
		);
	}
}

export default function* resetPortCallEventWatcher(): SagaIterator {
	yield takeLatest(resetPortCallEventAsync.type, resetPortCallEventWorker);
}
