import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { openModal } from 'store/modals/actions';
import { getIsCargoLineFormInEditMode } from 'store/portJobs/selectors';
import { isModalVisible } from 'store/modals/selectors';
import {
	CARGO_LINE_MODAL,
	CANCEL_PROCESS_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { AppState } from 'store-types';

interface CargoLineModalProps {
	form: React.ReactNode;
	// from mapStateToProps
	isAddingVisible: boolean;
	inEditMode: boolean;
	// from mapDispatchToProps
	openModal: typeof openModal;
}

const CargoLineModal = ({
	form,
	inEditMode,
	isAddingVisible,
	...props
}: CargoLineModalProps) => {
	const onCancel = () => props.openModal(CANCEL_PROCESS_MODAL);
	return (
		<Modal
			destroyOnClose
			visible={isAddingVisible}
			title={inEditMode ? 'Edit Cargo Line' : 'Add new Cargo Line'}
			size="lg"
			footer={null}
			onCancel={onCancel}
		>
			{isAddingVisible && form}
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isAddingVisible: isModalVisible(state, CARGO_LINE_MODAL),
		inEditMode: getIsCargoLineFormInEditMode(state)
	}),
	{
		openModal
	}
)(CargoLineModal);
