import { initialState } from './dashboardState';
import { reducerWithInitialState } from 'typescript-fsa-reducers/dist';

import {
	onRetrieveJobsSectionsStarted,
	onRetrieveJobsSectionsSuccess,
	onRetrieveJobsSectionsFailed,
	onRetrieveDashboardSummaryStarted,
	onRetrieveDashboardSummarySuccess,
	onRetrieveDashboardSummaryFailed,
	onResetDashboardState,
	onRetrieveActiveSection,
	onRetrieveDashboardTableau,
	onRetrieveDashboardTableauInfo,
	onSetDashboardTableauCompanyId,
	onResetDashboardTableauCompanyId
} from './reducers';
import {
	retrieveDashboardPortCallsSummaryAsync,
	retrieveJobSectionsAsync,
	resetDashboardState,
	retrieveActiveSectionAsync,
	setActiveSection,
	retrieveDashboardTableauAsync,
	retrieveDashboardTableauInfoAsync,
	setDashboardTableauCompanyId,
	resetDashboardTableauCompanyId
} from './actions';
import { onSetActiveSection } from './reducers/setActiveSectionReducer';

export default reducerWithInitialState(initialState)
	.case(retrieveJobSectionsAsync.started, onRetrieveJobsSectionsStarted)
	.case(retrieveJobSectionsAsync.done, onRetrieveJobsSectionsSuccess)
	.case(retrieveJobSectionsAsync.failed, onRetrieveJobsSectionsFailed)
	.case(
		retrieveDashboardPortCallsSummaryAsync.started,
		onRetrieveDashboardSummaryStarted
	)
	.case(
		retrieveDashboardPortCallsSummaryAsync.done,
		onRetrieveDashboardSummarySuccess
	)
	.case(
		retrieveDashboardPortCallsSummaryAsync.failed,
		onRetrieveDashboardSummaryFailed
	)
	.case(resetDashboardState, onResetDashboardState)
	.case(setActiveSection, onSetActiveSection)
	.case(setDashboardTableauCompanyId, onSetDashboardTableauCompanyId)
	.case(resetDashboardTableauCompanyId, onResetDashboardTableauCompanyId)
	.case(retrieveActiveSectionAsync.started, onRetrieveActiveSection.started)
	.case(retrieveActiveSectionAsync.done, onRetrieveActiveSection.done)
	.case(retrieveActiveSectionAsync.failed, onRetrieveActiveSection.failed)
	// Retrieve Tableau
	.case(
		retrieveDashboardTableauAsync.started,
		onRetrieveDashboardTableau.started
	)
	.case(retrieveDashboardTableauAsync.done, onRetrieveDashboardTableau.done)
	.case(retrieveDashboardTableauAsync.failed, onRetrieveDashboardTableau.failed)
	// Retrieve Tableau
	.case(
		retrieveDashboardTableauInfoAsync.started,
		onRetrieveDashboardTableauInfo.started
	)
	.case(
		retrieveDashboardTableauInfoAsync.done,
		onRetrieveDashboardTableauInfo.done
	)
	.case(
		retrieveDashboardTableauInfoAsync.failed,
		onRetrieveDashboardTableauInfo.failed
	);
