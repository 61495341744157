import React from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { findIndex } from 'lodash';
import {
	reduxForm,
	InjectedFormProps,
	registerField,
	change,
	formValueSelector
} from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';

import { Button } from 'components/antd';
import GradeLinesGrid from '../GradeLinesGrid/GradeLinesGrid';
import {
	FORM,
	GRADE_LINE_MODAL
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	FormData,
	GradeLineFormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { getPortCallContext } from 'store/portCall/portCallSelectors';
import GradeLineModal from '../GradeLineModal';
import BunkeringFieldName from '../BunkeringFieldNames';
import { openModal } from 'store/modals/actions';
import { setEditableGradeId } from 'store/portCall/actions';
import { getEditableGradeId } from 'store/portJobs/selectors';
import { FieldType } from 'store/form/formTypes';
import { AppState } from 'store-types';

const { GRADE_LINES } = BunkeringFieldName;

const formSelector = formValueSelector(FORM.portJob);
const mapStateToProps = (state: AppState) => ({
	gradeLines: formSelector(state, GRADE_LINES) as GradeLineFormData[],
	editableGradeId: getEditableGradeId(state),
	operationFormVisible: getPortCallContext(state, 'operationFormVisible')
});

const mapDispatchToProps = {
	openModal,
	setEditableGradeId,
	registerFormField: registerField,
	changeValue: change
};

type GradeLinesBaseProps = ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps;
interface GradeLinesProps
	extends InjectedFormProps<FormData, GradeLinesBaseProps>,
		GradeLinesBaseProps {}

class GradeLines extends React.PureComponent<GradeLinesProps> {
	componentDidMount() {
		this.props.registerFormField(
			FORM.portJob,
			GRADE_LINES,
			FieldType.FIELD_ARRAY
		);
	}

	onSubmit = (formValues: GradeLineFormData) => {
		const { gradeLines, editableGradeId, array } = this.props;
		if (editableGradeId) {
			const index = findIndex(gradeLines, { id: editableGradeId });
			array.splice(GRADE_LINES, index, 1, formValues);
			return;
		}
		const data = {
			...formValues,
			id: formValues.id ? formValues.id : uuidv4() + '[generated]'
		};
		array.push(GRADE_LINES, data);
	};

	onEdit = (gradeLineId: string) => {
		this.props.setEditableGradeId(gradeLineId);
		this.props.openModal(GRADE_LINE_MODAL);
	};

	onDelete = (gradeLineId: string) => {
		const { gradeLines, changeValue } = this.props;
		const lines = gradeLines.filter(gradeLine => gradeLine.id !== gradeLineId);
		changeValue(FORM.portJob, GRADE_LINES, lines);
	};

	onAddNewGradeLineModal = () => this.props.openModal(GRADE_LINE_MODAL);

	render() {
		return (
			<>
				<GradeLinesGrid
					dataSource={this.props.gradeLines}
					onDelete={this.onDelete}
					onEdit={this.onEdit}
				/>
				<GradeLineModal onSubmit={this.onSubmit} />
				<Button
					type="primary"
					transparent
					icon="plus"
					onClick={this.onAddNewGradeLineModal}
				>
					Add new Grade Line
				</Button>
			</>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm<FormData, GradeLinesBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true
	})(GradeLines)
);
