import actionCreator from '../../actionCreator';
import {
	RetrieveVesselProgramme,
	RetrieveVesselProgrammeRequest
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'RETRIEVE_VESSEL_PROGRAMME';

export const retrieveVPAsync = actionCreator.async<
	RetrieveVesselProgrammeRequest,
	RetrieveVesselProgramme,
	Error
>(ACTION_NAME);

export const retrieveVP = actionCreator<RetrieveVesselProgrammeRequest>(
	ACTION_NAME
);
