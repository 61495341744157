import { FetchStatus } from 'services/api/apiTypes';
import { RetrieveCompanyVesselType } from 'services/api/companies/companiesServiceTypes';
export type CompanyVesselType = Pick<
	RetrieveCompanyVesselType,
	'id' | 'name' | 'externalCode'
> & {
	companyId: string;
};
export interface CompanyVesselTypeState {
	readonly byId: { [id: string]: CompanyVesselType };
	readonly fetchStatuses: {
		retrieve: FetchStatus;
		create: FetchStatus;
		update: FetchStatus;
		delete: FetchStatus;
	};
}
export const initialState: CompanyVesselTypeState = {
	byId: {},
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		create: FetchStatus.IDLE,
		update: FetchStatus.IDLE,
		delete: FetchStatus.IDLE
	}
};
