import * as React from 'react';
import classNames from 'classnames';
import { Justify, LayoutDirection } from 'components/types';
import styles from './Flex.module.scss';

export interface FlexProps {
	direction?: LayoutDirection;
	justify?: Justify; // TODO to union type
	/**
	 * Vertical Align elements
	 */
	align?: 'start' | 'center' | 'end' | 'baseline'; // TODO rename to top, middle, bottom
	self?: 'center' | 'end';
	className?: string;
	fit?: boolean;
	stretch?: boolean;
	row?: boolean;
	wrap?: boolean;
	flex?: number | string;

	/**
	 * Should grow to available width
	 */
	grow?: boolean;
	shrink?: number;

	/**
	 * Should behave as inline element
	 * Note: with `inline-flex` we make the element of `inline-level`,
	 * which, by default, is vertically aligned to `baseline`. This rule
	 * may shift an element.
	 */
	inline?: boolean;

	/**
	 * Should be flex at all or is it a child that needs to be aligned within flex parent
	 */
	none?: boolean;
	style?: React.CSSProperties;
}

export const Flex: React.FC<FlexProps & React.HTMLAttributes<{}>> = props => {
	const {
		className,
		justify,
		align,
		fit,
		direction,
		stretch,
		row,
		wrap,
		grow,
		shrink,
		inline,
		self,
		none,
		flex,
		style,
		...restProps
	} = props;

	const justifyClasses = {
		[styles.justifyBetween]: justify === Justify.BETWEEN,
		[styles.justifyEnd]: justify === Justify.END,
		[styles.justifyCenter]: justify === Justify.CENTER,
		[styles.justifyAround]: justify === Justify.AROUND
	};

	const alignClasses = {
		[styles.alignCenter]: align === 'center',
		[styles.alignStart]: align === 'start',
		[styles.alignEnd]: align === 'end',
		[styles.alignBaseline]: align === 'baseline'
	};

	const selfClasses = {
		[styles.selfCenter]: self === 'center',
		[styles.selfEnd]: self === 'end'
	};

	const restClasses = {
		[styles.none]: none,
		[styles.fit]: fit,
		[styles.stretch]: stretch,
		[styles.row]: row,
		[styles.wrap]: wrap,
		[styles.grow]: grow,
		[styles.noShrink]: shrink === 0,
		[styles.inline]: inline,
		[styles.vertical]: direction === 'vertical'
	};

	return (
		<div
			className={classNames(
				styles.root,
				className,
				justifyClasses,
				alignClasses,
				selfClasses,
				restClasses
			)}
			style={{
				flex,
				...style
			}}
			{...restProps}
		>
			{props.children}
		</div>
	);
};

Flex.defaultProps = {
	direction: 'horizontal'
};

export default Flex;
