import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveUnitsAsync as async } from './actions/retrieveUnits';
import {
	onRetrieveUnitsStarted as started,
	onRetrieveUnitsSuccess as success,
	onRetrieveUnitsFailed as failed
} from './reducers';
import { initialState } from './unitsState';

export default reducerWithInitialState(initialState)
	.case(async.started, started)
	.case(async.done, success)
	.case(async.failed, failed);
