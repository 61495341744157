import actionCreator from 'store/finance/financeActionCreator';
import { CancelCreditSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

const ACTION_NAME = 'CANCEL_CREDIT_SSUPPLIER_INVOICE';

export const cancelCreditSupplierInvoice = actionCreator<
	CancelCreditSupplierInvoiceRequest
>(ACTION_NAME);

export const cancelCreditSupplierInvoiceAsync = actionCreator.async<
	CancelCreditSupplierInvoiceRequest,
	void,
	Error
>(ACTION_NAME);
