import { UserType, UserContact } from 'services/api/users/userServiceTypes';
import {
	Attachment,
	Message,
	MessageCreateRequest,
	MessageEvent
} from 'services/api/messages/messagesServiceTypes';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { ComposeMessageFrom } from 'sections/ComposeMessage/ComposeMessageTypes';
import {
	ResultCollection,
	PagedRequest,
	SearchResultCollection
} from 'services/api/apiTypes';

import { Entity } from 'app-types.d';

/* Requests */
import { SelectOption } from 'components/antd/Select/Select';
import { Group } from 'services/api/groups/groupsServiceTypes';
import {
	Mailbox,
	MailboxAvailableGroup
} from '../mailboxes/mailboxesServiceTypes';

export enum ThreadsAssignedToUserFilter {
	ME = 'me',
	UNASSIGNED = 'unassigned'
}

export interface RetrieveThreadsFilters {
	onlyUnread?: boolean;
	searchTerm?: string[];
	statuses?: ThreadStatus[];
	assignedToInbox?: string;
	assignedToGroup?: string;
	assignedToUser?: ThreadsAssignedToUserFilter | string;
	tags?: string[];
}

export interface RetrieveThreadsRequest
	extends PagedRequest,
		RetrieveThreadsFilters {
	expand?: string[];
}

export interface RetrieveThreadsResponse extends ResultCollection<IThread> {
	totalUnreadCount: number;
}

export interface RetrieveThreadByIdParams {
	threadId: ThreadItem['id'];
	isSilent?: boolean;
	concurrencyToken?: number;
}

export interface RetrieveThreadEventsRequest extends PagedRequest {
	threadId: string;
	expand?: 'message' | 'message,attachment';
	createdOnAfter?: string;
	createdOnBefore?: string;
	// Not for the API
	retrieveLatest?: boolean;
}

export type RetrieveThreadEventsResponse = ResultCollection<MessageEvent>;

/** actions/assignJobToThread.ts */
export interface AssignJobToThreadParams {
	threadId: ThreadItem['id'];
	jobCode: PortJob['code'];
}
export interface AssignJobToThreadResponse {
	threadId: ThreadItem['id'];
	jobCodes: Array<PortJob['code']>;
}

export interface ContextType {
	id: string;
	threadContextRef: number;
	name: string;
	description: string;
	isActive: boolean;
	isInternal: boolean;
}

export interface TagType {
	code: string;
	name: string;
	isSelected: boolean;
}

export interface UpdateTagsRequest {
	threadId: string;
	codes: string[];
}

export interface RetrieveThreadsMainPrincipalTagsParam {
	companyId: string;
}

export type RetrieveThreadTagsResponse = ResultCollection<TagType>;

export interface CreateThreadRequest {
	message: MessageCreateRequest;
	assignedGroup: {
		id: string;
		name: string;
	};
	assignedInbox: {
		id: string;
	};
	port?: {
		id: string;
		name: string;
	};
	vessel?: {
		id: string;
		name: string;
	};
	subject?: string;
	tags?: string[];
	contextId: string;
	jobCodes?: string[];
	threadId?: string;
}

export interface CreateThreadResponse {
	id: string;
}

export enum ThreadStatus {
	OPEN = 'Open',
	QUERIED = 'Queried',
	RESOLVED = 'Resolved',
	OVERDUE = 'Overdue'
}

export type ThreadStatusesEnabledToPick =
	| ThreadStatus.OPEN
	| ThreadStatus.QUERIED
	| ThreadStatus.RESOLVED;

/**
 * Thread listed in `/messages`
 */
export interface IThread {
	id: string;
	isProtected: boolean;
	createdOn: string;
	updatedOn: string;
	company: Entity;
	ports: Entity[];
	vessels: Entity[];
	assignedToInbox: { id: string; name: string; companyId: string };
	assignedToGroup: {
		id: string;
		name: string;
		groupSignature: string | null;
		userSignature: string | null;
		isDeleted: boolean;
	};
	assignedToUser: Entity;
	status: ThreadStatus;
	hasAttachments: boolean;
	lastMessage: Message;
	statusUpdatedOn: string;
	isRead: boolean;
	jobCodes: string[];
	context: ContextType | null;
	hasFailedMessages: boolean;
	hasUnsupportedAttachments: boolean;
	messageCount: number;
}

export interface ThreadsContext {
	activeThreadId?: string;
	threadsLimit?: number;
	scrollPosition?: number;
}

export interface ThreadsMode {
	isPreviewModeEnabled: boolean;
}

export interface FullScreenMode {
	isFullScreenModeEnabled: boolean;
}

/**
 * Thread opened in `/messages/${thread.id}`
 */
export interface ThreadItem extends IThread {
	context: ContextType | null;
	tags: TagType[] | null;
	isProtected: boolean;
	defaultMailboxAddress: string;
	lastRead?: {
		messageId: string;
	};
	permissions: Group[];
	concurrencyToken?: number;
}

/**
 * Returns a list of groups with their users.
 * Meant to be used on the threadSelectors
 */
export interface PermissionGroupWithUsers extends Entity {
	options?: SelectOption[];
}

/**
 * Threads can have intenral comments
 */
export interface IInternalComment {
	id: string;
	userId: string;
	userName: string;
	text: string;
	createdOn: string; // "2017-09-28T15:37:33.997Z"
	userType: UserType;
}

export interface ICommentServerReference {
	id: string;
	name: string;
}

export interface InternalCommentCallFailure {
	error: Error;
}

export type RetrieveInternalCommentsResponse = ResultCollection<
	IInternalComment
>;

// Thread Context
export interface RetrieveThreadContextsRequest {
	onlyActive?: boolean;
	includeInternal?: boolean;
}

export type RetrieveThreadContextsResponse = ResultCollection<ContextType>;

// Internal comments
export interface PostInternalCommentsRequest {
	threadId: string;
	text: string;
}

export interface PostInternalCommentsResponse {
	id: string;
	name: string;
}

/** How groupType names come from the API during Filters search  */
export enum ThreadSearchGroupType {
	Companies = 'Companies',
	MessageSubjects = 'MessageSubjects',
	From = 'From',
	Ports = 'Ports',
	Vessels = 'Vessels',
	Search = 'Search'
}

export type ThreadsSearchGroupType =
	| 'Emails'
	| 'Companies'
	| 'Usernames'
	| 'Messages'
	| 'From'
	| 'Ports'
	| 'Vessels';

export interface ThreadsSearchRequest {
	searchTerm: string;
	groupType?: ThreadsSearchGroupType;
	showMore?: boolean;
	portJobCode?: string;
	portCallId?: string;
}

interface ThreadsSearchResult {
	id: string;
	name: string;
	groupType?: ThreadsSearchGroupType;
	index?: number;
}

interface ThreadsSearchGroup
	extends SearchResultCollection<ThreadsSearchGroupType, ThreadsSearchResult> {}

export interface ThreadsSearchGroupResponse {
	groups: ThreadsSearchGroup[];
}

export interface PatchThreadPermissionsRequest {
	threadId: string;
	assignedToGroupId: string;
	assignedToUserId: string | null;
	threadPermissionGroups: string[];
}

export interface PatchThreadPermissionsResponse
	extends ResultCollection<Group> {}

export interface AssignToThreadsRequest {
	threadIds: string[];
	assignedToGroupId: string;
	assignedToUserId?: string | null;
	threadPermissionGroups: string[];
}

export interface CategorizeThreadsRequest {
	threadIds: string[];
	status: string | null;
	threadContextId: string | null;
	codes?: string[];
	jobcodes?: string[];
}

export interface CategorizeThreadsResponse extends ResultCollection<Group> {}

interface ComposeMessageMetaData {
	to?: UserContact[];
	cc?: UserContact[];
	status?: ThreadStatus;
	subject?: string;
	attachments?: Attachment[];
	contextId?: string;
	jobCodes?: string[];
	text?: string;
}

export interface NewComposeMessageMetaData extends ComposeMessageMetaData {
	from?: Mailbox;
	assignedTo?: MailboxAvailableGroup;
}

export interface EditComposeMessageMetaData extends ComposeMessageMetaData {
	from?: ComposeMessageFrom;
	isContextAndJobVisible?: boolean;
	mustStartNewThread?: boolean;
	content?: string;
	comment?: string;
}

export type RetrieveThreadsAvailableGroupsResponse = ResultCollection<
	ThreadsAvailableGroup
>;
export type ThreadsAvailableGroup = Entity;

export interface RetrieveAvailableThreadGroupsRequest {
	threadId: string;
}
export interface RetrieveAvailableThreadGroupsResponse
	extends ResultCollection<Group> {}

export interface RetrieveThreadsStatsRequest {
	isSilent?: boolean;
}

export interface RetrieveThreadsStatsResponse {
	open: number;
	assigned: number;
	unassigned: number;
	overdue: number;
}

export interface RetrieveThreadsPendingAlertsRequest {
	portCallId: string;
}
export interface RetrieveThreadsPendingAlertsResponse {
	hasOverdueThreads: boolean;
}

export type ThreadsPendingAlerts = RetrieveThreadsPendingAlertsResponse;

export interface RetrieveMessageDataRequest {
	threadId: string;
	messageId: string;
	portCallId?: string;
}
