import * as React from 'react';
import { connect } from 'react-redux';

import { AppState } from 'store-types';
import { EntityPermissionTooltipWrapper, PermissionContext } from 'containers';
import { Loading, Box, SettingsButton } from 'components';
import { Grid, Row, Col, Button, Icon } from 'components/antd';
import Operations from './Operations/Operations';
import JobInfoHeader from './JobInfoHeader';
import Info from './Info/Info';
import AppointmentAttachments from './AppointmentAttachments';
import Instructions from './Instructions';
import {
	getPortJobByCode,
	getEmailSubject,
	getActivePortJobCode,
	getIsActivePortJobCancelled,
	getTogglePortJobStatus,
	getIsJobLinked,
	getSendCancelEmailJobStatus
} from 'store/portJobs/portJobsSelectors';
import {
	getActivePortCallId,
	getHasActivePortCallDuplicates
} from 'store/portcalls/portCallsSelectors';
import { getPortCallPrincipalEmail } from 'store/portJobs/selectors';
import { navigateTo } from 'utils';
import PortJobActionButton from 'sections/PortCall/PortJob/PortJobActionButton';
import PortCallSettingsButton from '../components/SettingsButton';
import PortJobSettingsMenu from '../components/settings/PortJobSettingsMenu';
import {
	EntityPermissionType,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components';
import DeletePortJobModal from './ActionModal/DeletePortJobModal';
import CancelPortJobModal from './ActionModal/CancelPortJobModal';
import UnlinkJobsModal from './ActionModal/UnlinkJobsModal';
import ContextBars from './ContextBars';
import classnames from 'classnames';
import styles from './JobDetails.module.scss';
import {
	getUserType,
	getIsCurrentUserHub,
	getIsCurrentUserPrincipal
} from 'store/auth/selectors/user';
import ChangeCustodyRoleModal from './ActionModal/ChangeCustodyRoleModal';
import ClonePortJobModal from './ActionModal/ClonePortJobModal/ClonePortJobModal';
import {
	PortJobSettingsMenuAction,
	PortJobSettingsMenuModalMap
} from '../components/settings/contants';
import { togglePortJobStatus } from 'store/portJobs/actions/togglePortJobStatus';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';
import CancelPortJobMessageModal from './ActionModal/CancelPortJobMessageModal';
import { toggleOperationsStatus } from 'store/portJobs/actions/toggleOperationsStatus';
import EnableOperationsModal from './ActionModal/EnableOperationsModal/EnableOperationsModal';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';

type JobInfoProps = {
	//mapStateToProps
	portJob: PortJob;
	activePortCallId: string;
	emailSubject: string;
	principalEmail: string;
	userType: UserType;
	hasActivePortCallDuplicates: boolean;
	activePortJobCode: string;
	isActivePortJobCancelled: boolean;
	isAddNewButtonVisible: boolean;
	toggleJobStatus: string;
	isJobLinked: boolean;
	isNotSendCancelEmail: boolean;
	//mapActionToProps
	togglePortJobStatus: typeof togglePortJobStatus;
	toggleOperationsStatus: typeof toggleOperationsStatus;
};

interface JobInfoState {
	visible: boolean;
}

class JobInfo extends React.Component<JobInfoProps, JobInfoState> {
	state = {
		visible: false
	};

	addOperation = () => {
		const { activePortCallId, activePortJobCode } = this.props;
		navigateTo(
			`/portcalls/${activePortCallId}/jobs/${activePortJobCode}/add-operation`
		);
	};

	onUpdate = (portCallId: string, portJobCode: string) =>
		navigateTo(`/portcalls/${portCallId}/jobs/${portJobCode}/edit`);

	handleVisibleChange = (visible: boolean) => {
		this.setState({ visible });
	};

	onToggleStatus = (portCallId: string, jobCode: string, jobStatus: string) => {
		this.setState({
			visible: false
		});
		this.props.togglePortJobStatus({
			portCallId,
			jobCode,
			jobStatus
		});
	};

	hideMenu = () => {
		this.setState({
			visible: false
		});
	};

	onToggleDisableOperations = (
		portCallId: string,
		jobCode: string,
		isOperationsDisabled: boolean
	) => {
		this.setState({
			visible: false
		});
		this.props.toggleOperationsStatus({
			portCallId,
			jobCode,
			isOperationsDisabled: !isOperationsDisabled
		});
	};

	render() {
		const {
			portJob,
			activePortCallId,
			hasActivePortCallDuplicates,
			userType,
			emailSubject,
			principalEmail,
			activePortJobCode,
			isActivePortJobCancelled,
			isAddNewButtonVisible,
			toggleJobStatus,
			isJobLinked,
			isNotSendCancelEmail
		} = this.props;
		if (!portJob) {
			return <Loading />;
		}
		return (
			<PermissionContext.Provider
				value={{
					companyId: portJob.hubPrincipalCompany?.id
						? portJob.hubPrincipalCompany.id
						: null
				}}
			>
				<Grid fluid>
					<ContextBars
						portCallId={activePortCallId}
						status={portJob.status}
						hasDuplicates={hasActivePortCallDuplicates}
						emailSubject={emailSubject}
						principalEmail={principalEmail}
						isCancelled={isActivePortJobCancelled}
						jobType={portJob.jobType}
						isJobLinked={isJobLinked}
					/>
					<JobInfoHeader portCallId={activePortCallId} />
					<Box stretch className={styles.jobInfoBody}>
						<Box.Content>
							<Row>
								<Col xs={9}>
									<Info portJobCode={activePortJobCode} />
								</Col>
								<Col xs={3}>
									<div className={styles.actionButtonLine}>
										<PortJobActionButton />
										<div
											className={classnames({
												[styles.settingsButtonWrapper]: hasActivePortCallDuplicates
											})}
										>
											<EntityPermissionTooltipWrapper
												entityType={EntityPermissionType.PORTJOB}
												entityKey={portJob.code}
												skipIfHasData
												checkAllPermissions
												tooltip={{ placement: 'bottomRight' }}
											>
												{(_, __, hasNoPermission) =>
													hasNoPermission ? (
														<SettingsButton disabled />
													) : (
														<PortCallSettingsButton
															entityKey={portJob.code}
															entityType={EntityPermissionType.PORTJOB}
															content={permissions => (
																<PortJobSettingsMenu
																	{...permissions}
																	onUpdate={() =>
																		this.onUpdate(
																			activePortCallId,
																			activePortJobCode
																		)
																	}
																	onToggle={() =>
																		this.onToggleStatus(
																			activePortCallId,
																			activePortJobCode,
																			toggleJobStatus
																		)
																	}
																	onOperationsToggle={() =>
																		this.onToggleDisableOperations(
																			activePortCallId,
																			activePortJobCode,
																			portJob.isOperationsDisabled
																		)
																	}
																	onCreateAppointment={() => {
																		navigateTo(
																			`/messages/${NEW_THREAD_ID}/job/nomination`,
																			{
																				shouldStayInNewTab: true
																			}
																		);
																	}}
																	onHideMenu={() => this.hideMenu()}
																/>
															)}
															disabled={
																isActivePortJobCancelled && isNotSendCancelEmail
															}
															skipIsLoading
															tooltipPlacement="bottomRight"
															visible={this.state.visible}
															handleVisibleChange={this.handleVisibleChange}
														/>
													)
												}
											</EntityPermissionTooltipWrapper>

											{hasActivePortCallDuplicates && (
												<Icon type="warning" color="warning" />
											)}
										</div>
										<DeletePortJobModal
											jobCode={activePortJobCode}
											portCallId={activePortCallId}
										/>
										<CancelPortJobModal
											jobCode={activePortJobCode}
											portCallId={activePortCallId}
											modalId="cancel-portjob-modal"
										/>
										<UnlinkJobsModal
											jobCode={activePortJobCode}
											portCallId={activePortCallId}
											modalId="unlink-jobs-modal"
										/>
										<ClonePortJobModal
											jobCode={activePortJobCode}
											portCallId={activePortCallId}
											modalId="clone-portjob-modal"
										/>
										<ChangeCustodyRoleModal
											jobCode={activePortJobCode}
											portCallId={activePortCallId}
											modalId={
												PortJobSettingsMenuModalMap[
													PortJobSettingsMenuAction.CHANGE_CUSTODY_ROLE
												]
											}
										/>
										{userType === UserType.HUB && !isNotSendCancelEmail && (
											<CancelPortJobMessageModal modalId="send-cancel-email-modal" />
										)}
										{userType === UserType.HUB && (
											<EnableOperationsModal
												jobCode={activePortJobCode}
												portCallId={activePortCallId}
												modalId={
													PortJobSettingsMenuModalMap[
														PortJobSettingsMenuAction.ENABLE_OPERATIONS
													]
												}
											/>
										)}
									</div>
								</Col>
							</Row>
						</Box.Content>
					</Box>
					{portJob.appointmentAttachments.length > 0 && (
						<Box stretch>
							<AppointmentAttachments
								attachments={portJob.appointmentAttachments}
							/>
						</Box>
					)}
					<Box stretch>
						<Instructions
							instructionsToHUB={portJob.instructionToISS}
							instructionsToLPA={portJob.instructionToLPA}
							savings={portJob.savings}
							userType={userType}
						/>
					</Box>
					{portJob.operations && (
						<Operations
							portJobId={portJob.id}
							portJobCode={activePortJobCode}
							portCallId={activePortCallId}
						/>
					)}
					{isAddNewButtonVisible && (
						<Permission permissionCode={PermissionCode.MANAGE_PORTCALL}>
							{hasPermission => (
								<EntityPermissionTooltipWrapper
									entityType={EntityPermissionType.PORTJOB}
									entityKey={activePortJobCode}
									permission="canAdd"
									tooltip={{ placement: 'right' }}
									skipIfHasData
								>
									{({ canAdd }) => (
										<Button
											type="primary"
											disabled={
												isActivePortJobCancelled || !canAdd || !hasPermission
											}
											onClick={this.addOperation}
										>
											Add New Operation
										</Button>
									)}
								</EntityPermissionTooltipWrapper>
							)}
						</Permission>
					)}
				</Grid>
			</PermissionContext.Provider>
		);
	}
}

const mapStateToProps = (state: AppState) => {
	const activePortJobCode = getActivePortJobCode(state);
	const toggleJobStatus = getTogglePortJobStatus(state);
	return {
		portJob: getPortJobByCode(state, activePortJobCode),
		activePortCallId: getActivePortCallId(state),
		emailSubject: getEmailSubject(state),
		principalEmail: getPortCallPrincipalEmail(state),
		userType: getUserType(state),
		hasActivePortCallDuplicates: getHasActivePortCallDuplicates(state),
		activePortJobCode,
		isActivePortJobCancelled: getIsActivePortJobCancelled(state),
		isAddNewButtonVisible:
			getIsCurrentUserHub(state) || getIsCurrentUserPrincipal(state),
		toggleJobStatus,
		isJobLinked: getIsJobLinked(state),
		isNotSendCancelEmail: getSendCancelEmailJobStatus(state)
	};
};

const mapActionToProps = {
	togglePortJobStatus,
	toggleOperationsStatus
};

export default connect(mapStateToProps, mapActionToProps)(JobInfo);
