import actionCreator from '../portCallDocumentsActionCreator';
import { DeletePortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'DELETE_PORT_CALL_DOCUMENT';

export type DeletePortCallDocumentPayload = Pick<
	DeletePortCallDocumentRequest,
	'portCallId' | 'documentId'
>;

export const deletePortCallDocumentAsync = actionCreator.async<
	DeletePortCallDocumentPayload,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Document'
	}
});

export const onDeletePortCallDocument = actionCreator<
	DeletePortCallDocumentPayload
>('ON_DELETE_PORT_CALL_DOCUMENT');
