import * as React from 'react';
import classNames from 'classnames';
import styles from './WizardProgress.module.scss';
import { Flex } from 'components';
import { Icon } from 'components/antd';

export interface WizardProgressStep {
	key?: string;
	label: string;
	description?: React.ReactNode;
}

interface WizardProgressItemProps {
	step: WizardProgressStep;
	active: boolean;
	completed: boolean;
}
const WizardProgressItem: React.FC<WizardProgressItemProps> = ({
	active,
	completed,
	step: { label, description }
}) => {
	return (
		<li
			className={classNames({
				[styles.active]: active,
				[styles.completed]: completed
			})}
		>
			<Flex>
				<div className={styles.step}>
					{completed && <Icon type="check" color="success" size="md" />}
				</div>
				<Flex none grow>
					{label && <div className={styles.label}>{label}</div>}
					{active &&
						description && (
							<div className={styles.description}>{description}</div>
						)}
				</Flex>
			</Flex>
		</li>
	);
};

export default WizardProgressItem;
