import { all, fork } from 'redux-saga/effects';

import operationsContextualizationWatchers from './operationsContextualization/sagas';
import principalServicesWatchers from './principalServices/sagas';

export default function*() {
	yield all([
		fork(operationsContextualizationWatchers),
		fork(principalServicesWatchers)
	]);
}
