import { camelCase, isEmpty } from 'lodash';
import {
	NodeDataAttributes,
	NodeDataHtmlAttributes,
	HtmlAttribute,
	NodeType
} from '../../RichTextTypes';
import {
	HTML_ATTRIBUTE_MAP,
	HTML_ATTRIBUTES,
	IGNORE_CSS_RULE_LIST
} from '../../RichTextConstants';

/**
 * Gather all attributes
 */
export const getNodeDataAttributes = (
	el?: ChildNode,
	value: Partial<NodeDataAttributes> = {}
): Partial<{ data: NodeDataAttributes }> => {
	const data: NodeDataAttributes = {};
	const attrs = el ? getAttributes(el) : value.attrs;
	if (!isEmpty(attrs)) {
		data.attrs = attrs;
	}
	if (value.section) {
		data.section = value.section;
	}
	if (!isEmpty(data)) {
		return { data };
	}
	return {};
};

/**
 * Gather all attributes defined by HTML_ATTRIBUTES
 */
export const getAttributes = (el: ChildNode): NodeDataHtmlAttributes => {
	const attributes: NodeDataHtmlAttributes = {};
	const element = el as HTMLElement;

	// target element nodes
	if (el.nodeType === NodeType.ELEMENT_NODE) {
		HTML_ATTRIBUTES.forEach(name => {
			const value =
				name !== HtmlAttribute.STYLE
					? element.getAttribute(name)
					: getStyleAttribute(element.getAttribute(name));

			if (isEmpty(value)) {
				return;
			}
			attributes[HTML_ATTRIBUTE_MAP[name]] = value;
		});
	}

	return attributes;
};

export const getStyleAttribute = (
	value: string | null = null
): NodeDataHtmlAttributes['style'] => {
	if (!value) {
		return {};
	}
	const rules = value.trim().split(';');
	return rules.reduce((acc, rule) => {
		if (!rule) {
			return acc;
		}
		const index = rule.indexOf(':');
		if (index === -1) {
			return acc;
		}
		const [name, value] = [rule.slice(0, index), rule.slice(index + 1)];
		if (IGNORE_CSS_RULE_LIST.includes(name)) {
			return acc;
		}
		acc[camelCase(name)] = value.trim();
		return acc;
	}, {});
};
