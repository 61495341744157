import { ApiMiddlewareHandleType } from './apiMiddlewareTypes';
import { NotificationDisplayType } from 'store/notifications/notificationsState';
import { ActionMetaTemplateParams, ActionMetaConfig } from 'app-types';
import { ErrorStatusCode } from 'services/api/apiErrorTypes';

export interface NotificationConfigBase<
	T = Readonly<ActionMetaConfig>,
	C = T & Required<Pick<ActionMetaConfig, 'type' | 'displayType'>>,
	D = T & Required<Pick<ActionMetaConfig, 'description'>>
> {
	templateParams: ActionMetaTemplateParams;
	success: C;
	error: C;
	method: {
		[method: string]: {
			success: T;
			error: T;
		};
	};
	status: {
		[status: number]: T;
	};
	statusDefault: {
		[status: number]: T;
		other: D;
	};
	source: {
		section: Partial<NotificationConfigBase>;
	};
}

export interface NotificationConfigItem {
	omit: boolean;
	response: boolean;
}

export type NotificationConfig = Partial<
	NotificationConfigBase<NotificationConfigItem>
>;
export type NotificationValueConfig = NotificationConfigBase;

const NOTIFICATION_CONFIG: NotificationConfig = {
	status: {
		[ErrorStatusCode.BAD_REQUEST]: {
			response: true,
			omit: false
		}
	},
	method: {
		get: {
			success: { omit: true, response: false },
			error: { omit: true, response: false }
		}
	}
};

const NOTIFICATION_VALUE_CONFIG: NotificationValueConfig = {
	templateParams: {
		concurrencyEntityName: 'data'
	},

	/**
	 * Default values for success responses
	 */
	success: {
		displayType: NotificationDisplayType.NOTIFICATION,
		type: 'success'
	},

	/**
	 * Default values for failed responses
	 */
	error: {
		displayType: NotificationDisplayType.NOTIFICATION,
		type: 'error',
		duration: 0
	},

	/**
	 * Default values for success/failed responses by methods/status codes/source
	 */
	method: {
		post: {
			success: {
				description: '<%= entityName %> has been successfully created.'
			},
			error: {
				description: 'Error occurred while creating <%= entityName %>.'
			}
		},
		put: {
			success: {
				description: '<%= entityName %> has been successfully updated.'
			},
			error: {
				description: 'Error occurred while updating <%= entityName %>.'
			}
		},
		patch: {
			success: {
				description: '<%= entityName %> has been successfully updated.'
			},
			error: {
				description: 'Error occurred while updating <%= entityName %>.'
			}
		},
		delete: {
			success: {
				description: '<%= entityName %> has been successfully deleted.'
			},
			error: {
				description: 'Error occurred while deleting <%= entityName %>.'
			}
		}
	},
	status: {
		[ErrorStatusCode.CONCURRENCY]: {
			displayType: NotificationDisplayType.NOTIFICATION,
			type: 'warning',
			description: `This <%= concurrencyEntityName %> has been updated by another user while you were editing it. You cannot save your changes due to that, please refresh the page.`,
			duration: 0
		}
	},
	statusDefault: {
		[ErrorStatusCode.FORBIDDEN]: {
			description: 'Access forbidden.'
		},
		[ErrorStatusCode.NOT_FOUND]: {
			description: 'Resource not found.'
		},
		[ErrorStatusCode.SERVER]: {
			description: 'Server error.'
		},
		other: {
			description: 'Something went wrong.'
		}
	},
	source: {
		section: {
			method: {
				get: {
					error: {
						displayType: NotificationDisplayType.FULL_PAGE
					},
					success: {}
				}
			}
		}
	}
};

export default {
	[ApiMiddlewareHandleType.CONNECTION]: {
		offline: 'Your device seems to be offline',
		online: 'Optic lost connection with one of its services. Please contact ISS'
	},
	[ApiMiddlewareHandleType.NOTIFICATION]: {
		VALUE_CONFIG: NOTIFICATION_VALUE_CONFIG,
		CONFIG: NOTIFICATION_CONFIG
	}
};
