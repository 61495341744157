import * as React from 'react';
import { connect } from 'react-redux';
import { EntityPermissionFetcher } from 'containers';
import { wizardForm, Error403 } from 'components';
import { ScrollableLayout } from 'components/Styles';
import { retrievePortCall, resetPortCalls } from 'store/portcalls';
import { notify } from 'store/notifications/actions';

import { InjectedWizardFormProps } from 'components/WizardForm';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import LinkJobWizardFooter from './LinkJobWizardFooter';
import { LINK_JOB_WIZARD_ID } from './constants';
import { EntityPermissionType } from 'services/api/permissions/permissionsServiceTypes';

import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { setPortJobContext } from 'store/portJobs/actions';
import {
	getActivePortJobCode,
	getSelectedLinkJob
} from 'store/portJobs/selectors';
import {
	getLinkedAppointmentCargos,
	getNominationCargos
} from 'store/linkJob/selectors/linkJobSelectors';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import { saveLinkedCargos } from 'store/linkJob/actions';
// Utils
import { navigateTo } from 'utils';
import { AppState } from 'store-types';

interface LinkJobWizardProps {
	onCancel: () => void;
	onFinish?: () => void;
	// from mapStateToProps
	activePortCallId: string;
	portJob: string;
	linkedJob: string;
	linkedNominationCargos: LinkedCargo[];
	linkedAppointmentCargos: LinkedCargo[];
	// from mapDispatchToProps
	retrievePortCall: typeof retrievePortCall;
	resetPortCalls: typeof resetPortCalls;
	notifyError: typeof notify.error;
	setPortJobContext: typeof setPortJobContext;
	saveLinkedCargos: typeof saveLinkedCargos;
}

interface LinkJobWizardFormProps
	extends InjectedWizardFormProps,
		LinkJobWizardProps {}

class LinkJobWizard extends React.Component<LinkJobWizardFormProps> {
	componentWillUnmount() {
		this.props.resetPortCalls();
		this.props.destroy();
		this.props.setPortJobContext({ selectedLinkJobCode: '' });
	}

	componentDidUpdate(prevProps: LinkJobWizardProps) {
		if (this.props.activePortCallId && !prevProps.activePortCallId) {
			navigateTo('/portcalls');
			this.props.notifyError(`That Port Call doesn't exist.`);
		}
	}

	private onFinish = () => {
		this.props.saveLinkedCargos({
			portCallId: this.props.activePortCallId,
			portJob: this.props.portJob,
			request: {
				portCallId: this.props.activePortCallId,
				portJobCode: this.props.portJob,
				linkedAppJobCode: this.props.linkedJob,
				nominationCargos: this.props.linkedNominationCargos,
				appointmentCargos: this.props.linkedAppointmentCargos
			}
		});
	};

	render() {
		const { activePortCallId, component } = this.props;
		const Page = component;
		return (
			<ScrollableLayout>
				{activePortCallId && (
					<EntityPermissionFetcher
						entityKey={activePortCallId}
						entityType={EntityPermissionType.PORTCALL}
					>
						{({ canAdd }) =>
							(canAdd && (
								<ScrollableLayout>
									{Page && <Page />}
									<LinkJobWizardFooter
										{...this.props}
										onFinish={this.onFinish}
										onCancel={this.props.onCancel}
									/>
								</ScrollableLayout>
							)) || <Error403 />
						}
					</EntityPermissionFetcher>
				)}
			</ScrollableLayout>
		);
	}
}

const WizardForm = wizardForm<LinkJobWizardProps>({
	name: LINK_JOB_WIZARD_ID,
	mode: 'jobLinking', // default state
	pages: [
		{
			component: Page1
		},
		{
			component: Page2
		}
	]
})(LinkJobWizard);

export default connect(
	(state: AppState) => ({
		activePortCallId: getActivePortCallId(state),
		portJob: getActivePortJobCode(state),
		linkedJob: getSelectedLinkJob(state),
		linkedNominationCargos: getNominationCargos(state),
		linkedAppointmentCargos: getLinkedAppointmentCargos(state)
	}),
	{
		retrievePortCall,
		resetPortCalls,
		notifyError: notify.error,
		setPortJobContext,
		saveLinkedCargos
	}
)(WizardForm);
