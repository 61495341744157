import {
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../linkJobActionCreator';

const ACTION_NAME = 'SAVE_LINKEDJOB_CARGOS';

export const saveLinkedCargosAsync = actionCreator.async<
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse,
	Error
>(ACTION_NAME);

export const saveLinkedCargos = actionCreator<SaveLinkedCargosDetailsRequest>(
	ACTION_NAME
);
