import * as React from 'react';

import { Icon } from 'components/antd';
import styles from './AssignTo.module.scss';

interface UserLineProps {
	assignedTo: {
		group: { id: string; name: string; isDeleted: boolean };
		user: { id: string; name: string };
	};
	showingTitle: boolean;
}

const UserLine: React.SFC<UserLineProps> = ({ assignedTo }) => {
	return (
		<>
			<Icon
				type="user"
				color="standard"
				offset="right"
				className={styles.userIcon}
			/>
			{!assignedTo.user ? <i>Unassigned</i> : assignedTo.user.name}
		</>
	);
};

export default UserLine;
