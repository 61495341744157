import { AppState } from 'store-types';
import { BreadcrumbItem } from 'store/navigation';
import { has } from 'lodash';
import { createSelector } from 'reselect';
import { getValues } from 'store/form/selectors';
import queryString from 'query-string';
import H from 'history';
import { isEmail } from 'utils/validations';
import { getCompaniesMapSelector } from 'store/companies/selectors';
import { EditContactFormData } from 'sections/Contact/EditContact/EditContactForm';
import { ContactType } from 'services/api/messages/messagesServiceTypes';

export const getEditContactMessageId = (location: H.Location): string => {
	const params = queryString.parse(location.search);
	return params.messageId;
};

export const getAddContactEmail = (location: H.Location): string => {
	const params = queryString.parse(location.search);
	const email = params.email;
	return isEmail(email) ? email : '';
};

export const getCurrentContact = (state: AppState): ContactType | undefined => {
	return state.messages.currentContact;
};

export const getEditContactEmail = (location: H.Location): string => {
	const params = queryString.parse(location.search);
	const email = params.email;
	return isEmail(email) ? email : '';
};

export const getGroupsMapSelector = (state: AppState) =>
	state.groups.groups.byId;

export const getEditContactSelectedCompanyAddress = createSelector(
	getValues,
	getCompaniesMapSelector,
	(form: EditContactFormData, companies): string => {
		const companyId = has(form, 'company.key') ? form.company.key : '';
		const company = companies[companyId];
		if (company && has(company, 'mailingAddress')) {
			const address = [
				company.mailingAddress.addressLine1,
				company.mailingAddress.city,
				company.mailingAddress.countryCode
			].filter(field => field);
			if (address.length) {
				return address.join(', ');
			}
		}
		return '';
	}
);

export const getEditContactBreadcrumbs = (
	isExistingContact: boolean
): BreadcrumbItem[] => [
	{ title: 'Messages', link: '/messages' },
	{ title: isExistingContact ? 'Edit Contact' : 'Add Contact' }
];
