import { all, fork } from 'redux-saga/effects';
import retrieveKpiSectionsWatcher from './retrieveKpiActiveSectionsSaga';
import retrieveKpiDetailsByIdWatcher from './retrieveKpiDetailsByIdSaga';
import updateKpiDetailsByIdWatcher from './updateKpiDetailsByIdSaga';

export default function*() {
	yield all([
		fork(retrieveKpiSectionsWatcher),
		fork(retrieveKpiDetailsByIdWatcher),
		fork(updateKpiDetailsByIdWatcher)
	]);
}
