import actionCreator from '../actionCreator';

const ACTION_NAME = 'SAVE_VESSEL_PROGRAMME';

export const saveVesselProgrammeAsync = actionCreator.async<null, null, Error>(
	ACTION_NAME,
	{
		done: {
			description:
				'Vessel Programme has been successfully updated. All interested parties have been notified.'
		},
		templateParams: {
			entityName: 'Vessel Programme'
		}
	}
);

export const saveVesselProgramme = actionCreator(ACTION_NAME);
