import actionCreator from 'store/finance/financeActionCreator';
import {
	ConvertServicePayload as Payload,
	ConvertServiceRequest as Request,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';
const CONVERT_SERVICE = 'CONVERT_SERVICE';

export const convertService = actionCreator<Payload>(CONVERT_SERVICE);
export const convertServiceAsync = actionCreator.async<
	Request,
	ResultCollection<PortJobService>,
	Error
>(CONVERT_SERVICE);
