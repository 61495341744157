import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveSavingsAsync,
	addCostSavingsAsync,
	addTimeSavingsAsync,
	retrieveTimeSavingsAsync,
	setCostSavingsContext,
	resetCostSavingsContext,
	deleteCostSavingsAsync,
	deleteTimeSavingsAsync,
	updateCostSavingsAsync,
	updateTimeSavingsAsync,
	setTimeSavingsContext,
	resetTimeSavingsContext
} from './actions';
import {
	onRetrieveSavingsStarted,
	onRetrieveSavingsSuccess,
	onRetrieveSavingsFailed,
	onAddCostSavingsStated,
	onAddCostSavingsFailure,
	onAddCostSavingsSuccess,
	onAddTimeSavingsStated,
	onAddTimeSavingsSuccess,
	onAddTimeSavingsFailure,
	onRetrieveTimeSavingsFailed,
	onRetrieveTimeSavingsStarted,
	onRetrieveTimeSavingsSuccess,
	onSetCostSavingsContext,
	onResetCostSavingsContext,
	onDeleteSavingsStarted,
	onDeleteSavingsSuccess,
	onDeleteSavingsFailed,
	onDeleteTimeSavingsSuccess,
	onDeleteTimeSavingsStarted,
	onDeleteTimeSavingsFailed,
	onUpdateCostSavingsStarted,
	onUpdateCostSavingsSuccess,
	onUpdateCostSavingsFailed,
	onUpdateTimeSavingsStarted,
	onUpdateTimeSavingsSuccess,
	onUpdateTimeSavingsFailed,
	onSetTimeSavingsContext,
	onResetTimeSavingsContext
} from './reducers';

import { initialState } from './savingsState';

export default reducerWithInitialState(initialState)
	.case(retrieveSavingsAsync.started, onRetrieveSavingsStarted)
	.case(retrieveSavingsAsync.done, onRetrieveSavingsSuccess)
	.case(retrieveSavingsAsync.failed, onRetrieveSavingsFailed)
	.case(retrieveTimeSavingsAsync.started, onRetrieveTimeSavingsStarted)
	.case(retrieveTimeSavingsAsync.done, onRetrieveTimeSavingsSuccess)
	.case(retrieveTimeSavingsAsync.failed, onRetrieveTimeSavingsFailed)
	.case(addCostSavingsAsync.started, onAddCostSavingsStated)
	.case(addCostSavingsAsync.done, onAddCostSavingsSuccess)
	.case(addCostSavingsAsync.failed, onAddCostSavingsFailure)
	.case(updateCostSavingsAsync.started, onUpdateCostSavingsStarted)
	.case(updateCostSavingsAsync.done, onUpdateCostSavingsSuccess)
	.case(updateCostSavingsAsync.failed, onUpdateCostSavingsFailed)
	.case(updateTimeSavingsAsync.started, onUpdateTimeSavingsStarted)
	.case(updateTimeSavingsAsync.done, onUpdateTimeSavingsSuccess)
	.case(updateTimeSavingsAsync.failed, onUpdateTimeSavingsFailed)
	.case(addTimeSavingsAsync.started, onAddTimeSavingsStated)
	.case(addTimeSavingsAsync.done, onAddTimeSavingsSuccess)
	.case(addTimeSavingsAsync.failed, onAddTimeSavingsFailure)
	.case(addTimeSavingsAsync.failed, onAddTimeSavingsFailure)
	.case(setCostSavingsContext, onSetCostSavingsContext)
	.case(setTimeSavingsContext, onSetTimeSavingsContext)
	.case(resetCostSavingsContext, onResetCostSavingsContext)
	.case(resetTimeSavingsContext, onResetTimeSavingsContext)
	.case(deleteCostSavingsAsync.started, onDeleteSavingsStarted)
	.case(deleteCostSavingsAsync.done, onDeleteSavingsSuccess)
	.case(deleteCostSavingsAsync.failed, onDeleteSavingsFailed)
	.case(deleteTimeSavingsAsync.started, onDeleteTimeSavingsStarted)
	.case(deleteTimeSavingsAsync.done, onDeleteTimeSavingsSuccess)
	.case(deleteTimeSavingsAsync.failed, onDeleteTimeSavingsFailed);
