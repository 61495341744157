import { RouteParams } from './loginTypes';

export const getSignInRedirectState = (routeParams: RouteParams) => {
	if (!routeParams.returnUrl) {
		return {};
	}
	return {
		returnUrl: routeParams.returnUrl
	};
};

export const getSignInRedirectExtraQueryParams = (routeParams: RouteParams) => {
	if (!routeParams.agentCompany) {
		return {};
	}
	return {
		agentCompany: routeParams.agentCompany
	};
};
