import * as React from 'react';
import { connect } from 'react-redux';
import { getActiveSupplierInvoice } from 'store/supplierInvoice/selectors';
import { Gap, Text, Amount } from 'components';
import { Row, Col, Checkbox } from 'components/antd';
import { FormattedTime } from 'containers';
import SupplierDetailsColumn from '../../../components/SupplierDetailsColumn/SupplierDetailsColumn';
import { AppState } from 'store-types';

type ViewSupplierDetailsSectionProps = ReturnType<typeof mapStateToProps>;

const ViewSupplierDetailsSection: React.SFC<ViewSupplierDetailsSectionProps> = ({
	activeSupplierInvoice
}) => {
	if (!activeSupplierInvoice) {
		return null;
	}
	return (
		<>
			<Gap bottom="lg">
				<Row>
					<Col xs={12}>
						<Text weight="semi-bold">Supplier Details</Text>
					</Col>
				</Row>
			</Gap>
			<Row>
				<SupplierDetailsColumn isBlock={false}>
					<Checkbox checked={activeSupplierInvoice.isCredit} disabled>
						Credit invoice
					</Checkbox>
				</SupplierDetailsColumn>
			</Row>
			<Row>
				<SupplierDetailsColumn
					label="Hub Principal"
					value={activeSupplierInvoice.mainPrincipalCompany.name}
				/>
				<SupplierDetailsColumn
					label="Supplier"
					value={activeSupplierInvoice.supplierCompany.name}
				/>
			</Row>
			<Row>
				<SupplierDetailsColumn
					label="Supplier Invoice Date"
					value={<FormattedTime value={activeSupplierInvoice.invoiceDate} />}
				/>
				<SupplierDetailsColumn
					label="Invoice Receipt Date"
					value={<FormattedTime value={activeSupplierInvoice.receiptDate} />}
				/>
			</Row>
			<Row>
				<SupplierDetailsColumn
					label="Invoice reference"
					value={activeSupplierInvoice.referenceNumber}
				/>
				<SupplierDetailsColumn
					label="Currency"
					value={activeSupplierInvoice.currencyCode}
					xsOffset={3}
				/>
				<SupplierDetailsColumn
					label="Invoice Total"
					value={
						<Amount
							value={activeSupplierInvoice.total}
							negative={activeSupplierInvoice.isCredit}
						/>
					}
				/>
			</Row>
			<Row>
				<SupplierDetailsColumn
					label="Discount"
					value={activeSupplierInvoice.discount}
				/>
				<SupplierDetailsColumn
					label="Comment"
					value={activeSupplierInvoice.comment}
				/>
			</Row>
		</>
	);
};

const mapStateToProps = (state: AppState) => ({
	activeSupplierInvoice: getActiveSupplierInvoice(state)
});

export default connect(mapStateToProps)(ViewSupplierDetailsSection);
