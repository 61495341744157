import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import AssignToModalForm from './AssignToModalForm';
import {
	ThreadsAssignToFormData,
	THREADS_ASSIGNTO_FORM
} from './AssignToModalFormTypes';
import { getFormValues, isInvalid } from 'redux-form';
import {
	getActiveThreadId,
	isThreadsAssignToFetchStatusPending
} from 'store/threads/selectors';
import {
	ThreadsSettingsMenuAction,
	ThreadsSettingsMenuModalMap
} from '../constants';
import {
	updateAssignToThreads,
	resetThreadsGroupsAndTags
} from 'store/threads/actions';
import { OPTION_UNASSIGNED } from 'store/thread/selectors';
import { IdField } from 'app-types';

interface AssignToModalProps {
	modalId: string;
	assignedTo: {
		group: { id: string; name: string; isDeleted: boolean };
		user: { id: string; name: string };
	};
	threadIds: IdField[];
	// from mapStateToProps
	threadId: string;
	visible: boolean;
	isFormValid: boolean;
	formValues: ThreadsAssignToFormData;
	isFetchStatusPending: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	resetThreadsGroupsAndTags: typeof resetThreadsGroupsAndTags;
	updateAssignToThreads: typeof updateAssignToThreads;
}

const turnUnassignedToNull = (val?: string) =>
	val === OPTION_UNASSIGNED ? null : val;

class AssignToModal extends Component<AssignToModalProps> {
	hideConfirmationModal = () => {
		this.props.resetThreadsGroupsAndTags();
		this.props.closeModal(
			ThreadsSettingsMenuModalMap[ThreadsSettingsMenuAction.ASSIGN_TO]
		);
	};

	onSubmit = () => {
		const {
			assignedGroupId,
			assignedUserId,
			threadPermissionGroups
		} = this.props.formValues;
		if (assignedGroupId) {
			this.props.updateAssignToThreads({
				threadIds: this.props.threadIds.map(({ id }) => id),
				assignedToGroupId: assignedGroupId,
				assignedToUserId: turnUnassignedToNull(assignedUserId),
				threadPermissionGroups: threadPermissionGroups.map(({ id }) => id)
			});
		}
	};
	render() {
		const { visible, isFormValid, isFetchStatusPending } = this.props;

		return (
			<>
				<Modal
					destroyOnClose
					visible={visible}
					title="Edit Assignment"
					onCancel={this.hideConfirmationModal}
					onOk={this.onSubmit}
					okDisabled={
						this.props.formValues?.assignedGroupId === '' ||
						isFormValid ||
						isFetchStatusPending
					}
					size={'md'}
				>
					<AssignToModalForm assignedTo={this.props.assignedTo} />
				</Modal>
			</>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<AssignToModalProps, 'modalId'>) => ({
		visible: isModalVisible(state, ownProps.modalId),
		threadId: getActiveThreadId(state),
		isFormValid: isInvalid(THREADS_ASSIGNTO_FORM)(state),
		formValues: getFormValues(THREADS_ASSIGNTO_FORM)(state),
		isFetchStatusPending: isThreadsAssignToFetchStatusPending(state)
	}),
	{
		closeModal,
		resetThreadsGroupsAndTags,
		updateAssignToThreads
	}
)(AssignToModal);
