import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getPortCallsStatic } from 'store/portcalls/selectors';
import { StaticEntityType } from 'store/portcalls/portCallsState';

const getPortJobDictionaries = (state: AppState) => state.portjob.dictionaries;

export const getPortJobAgencyTypes = createSelector(
	getPortCallsStatic,
	dictionaries => dictionaries[StaticEntityType.AGENT_TYPE]
);
export const getPortJobAgencyRoles = createSelector(
	getPortCallsStatic,
	dictionaries => dictionaries[StaticEntityType.AGENT_ROLE]
);
export const getPortJobTypes = createSelector(
	getPortCallsStatic,
	dictionaries => dictionaries[StaticEntityType.PORT_JOB_TYPE] || []
);
export const getPortJobAppointers = createSelector(
	getPortJobDictionaries,
	dictionaries => dictionaries.appointers
);
