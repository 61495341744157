import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
	configurationFileModalSubmit,
	closeConfigurationFileModal,
	addConfigurationFile,
	addConfigurationFileAsync,
	updateConfigurationFile,
	updateConfigurationFileAsync
} from '../../actions';

import { getActiveCompanyId } from 'store/configuration/selectors';
import {
	getConfigurationFilesContextIsNew,
	getConfigurationFileFormValuesForSubmit
} from '../../selectors';

import { CONFIGURATION_FILE_FORM_NAME } from '../../configurationFilesTypes';
import prepareRequest from './prepareRequest';
import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';
import { ActionMeta } from 'app-types';

export function* worker() {
	const companyId = yield select(getActiveCompanyId);
	const formValues = yield select(getConfigurationFileFormValuesForSubmit);

	let asyncAction:
		| typeof addConfigurationFileAsync
		| typeof updateConfigurationFileAsync
		| null = null;

	const data = prepareRequest(formValues);
	const meta: ActionMeta = {
		behaviour: {
			type: 'submit',
			formName: CONFIGURATION_FILE_FORM_NAME
		}
	};
	const isNew = yield select(getConfigurationFilesContextIsNew);
	if (isNew) {
		yield put(
			addConfigurationFile(
				{
					companyId,
					data
				},
				meta
			)
		);
		asyncAction = addConfigurationFileAsync;
	} else {
		yield put(
			updateConfigurationFile(
				{
					companyId,
					fileId: formValues.id,
					data
				},
				meta
			)
		);
		asyncAction = updateConfigurationFileAsync;
	}

	const result = yield call(waitAsyncResolve, asyncAction);

	if (!result.error) {
		yield put(closeConfigurationFileModal());
	}
}

export default function*() {
	yield takeLatest(configurationFileModalSubmit.type, worker);
}
