import { filter } from 'lodash';
import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { NotificationDisplayType } from '../notificationsState';

const getNotifications = (state: AppState) => state.notifications.notifications;
export const getNotificationAddonsByActionType = (
	state: AppState,
	actionType: string
) => {
	const type = actionType.replace(/_FAILED|_DONE/gi, '');
	return state.notifications.addons[type];
};

export const getNotificationByUuid = (state: AppState, uuid: string) =>
	getNotifications(state)[uuid];

export const getNotificationDisplayTypeConfigByActionType = (
	state: AppState,
	actionType: string
) => {
	const type = actionType
		.split('_')
		.filter(s => s !== 'FAILED')
		.join('_');
	return state.notifications.notificationsConfig[type];
};

export const makeGetNotificationsByDisplayType = (
	displayType: NotificationDisplayType
) => {
	return createSelector(getNotifications, notifications =>
		filter(notifications, { displayType })
	);
};

const getNotificationsFullPageDisplayType = makeGetNotificationsByDisplayType(
	NotificationDisplayType.FULL_PAGE
);

export const getHasNotificationsFullPageDisplayType = createSelector(
	getNotificationsFullPageDisplayType,
	notifications => notifications.length > 0
);
