import React from 'react';
import { Title, Text, OptionalValue } from 'components';
import { Flex } from 'components/Styles';
import { LayoutDirection } from 'components/types';
import { FlexProps } from 'components/Styles/Flex/Flex';

// TODO: Remove export here
export interface LabeledTextProps {
	label: React.ReactNode;
	type?: LayoutDirection;
	hasColon?: boolean;
	value?: React.ReactNode;
	className?: string;
	labelClassName?: string;
	align?: FlexProps['align'];
	children?: React.ReactNode;
	breakBoldedWordInIE?: boolean;
}

const LabeledText = (props: LabeledTextProps) => {
	const {
		type = 'vertical',
		hasColon = false,
		className,
		labelClassName,
		align,
		label,
		value,
		breakBoldedWordInIE
	} = props;
	return (
		<Flex className={className} direction={type} align={align}>
			<Title.Label className={labelClassName}>{label}</Title.Label>
			{hasColon && ':'}
			{type === 'horizontal' && '\u00A0' // &nbsp;
			}
			<Text weight="bold" breakWordInIE={breakBoldedWordInIE}>
				<OptionalValue value={value || props.children} />
			</Text>
		</Flex>
	);
};

export default LabeledText;
