import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	postInternalComments,
	postInternalCommentsAsync
} from '../actions/postInternalComments';
import { retrieveInternalComments } from '../actions/retrieveInternalComments';
import { PostInternalCommentsRequest } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';

export function* postInternalCommentsWorker(
	action: Action<PostInternalCommentsRequest>
) {
	const { threadId, text } = action.payload;
	yield put(postInternalCommentsAsync.started(action.payload));
	try {
		const response = yield call(
			Api.Threads.postInternalComment,
			action.payload.threadId,
			action.payload.text
		);
		yield put(
			postInternalCommentsAsync.done({
				result: {
					id: response.id,
					name: response.name
				},
				params: {
					threadId,
					text
				},
				response
			})
		);
		yield put(retrieveInternalComments(threadId));
	} catch (err) {
		yield put(
			postInternalCommentsAsync.failed({
				error: err,
				params: {
					threadId,
					text
				}
			})
		);
	}
}

export function* postInternalCommentsWatcher(): SagaIterator {
	yield takeLatest(postInternalComments.type, postInternalCommentsWorker);
}
