import { reject } from 'lodash';
import { FeedbackDocument } from 'services/api/companies/companiesServiceTypes';
import {
	UploadDocumentDoneResponse,
	FileBaseInfo
} from 'services/api/documents/documentsServiceTypes';
import { ConfigurationState } from '../configurationState';

export const onUploadRequestFeedbackDocumentDone = (
	state: ConfigurationState,
	{ document }: UploadDocumentDoneResponse
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedRequestFeedbackDocuments: [
				...state.context.uploadedRequestFeedbackDocuments,
				{
					id: document.id,
					documentId: document.id,
					name: document.name,
					createdOnUtc: document.createdOn,
					createdByUserName: document.createdByUserName
				} as FileBaseInfo
			]
		}
	};
};

export const onUpdateRequestFeedbackDocumentDone = (
	state: ConfigurationState,
	result: FeedbackDocument
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedRequestFeedbackDocuments: [
				...state.context.uploadedRequestFeedbackDocuments,
				{
					id: result.id,
					documentId: result.documentId,
					name: result.documentName,
					createdByUserName: result.documentCreatedBy,
					createdOnUtc: result.documentCreatedOn
				} as FileBaseInfo
			]
		}
	};
};

export const onDeleteRequestFeedbackFileInfo = (
	state: ConfigurationState,
	fileId: string
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedRequestFeedbackDocuments: reject(
				state.context.uploadedRequestFeedbackDocuments,
				item => item.id === fileId
			)
		}
	};
};

export const onDeleteAllRequestFeedbackFileInfo = (
	state: ConfigurationState
) => {
	return {
		...state,
		context: {
			...state.context,
			uploadedRequestFeedbackDocuments: []
		}
	};
};
