import { FetchStatus } from 'services/api/apiTypes';
import { MessageEvent } from 'services/api/messages/messagesServiceTypes';

export interface ThreadEventsReducerState {
	list: MessageEvent[];
	hasMore: boolean;
	fetchStatuses: {
		add: boolean;
		retrieveEvents: FetchStatus;
	};
}

export const initialState: ThreadEventsReducerState = {
	list: [],
	hasMore: true,
	fetchStatuses: {
		add: false,
		retrieveEvents: FetchStatus.IDLE
	}
};
