import { Success } from 'typescript-fsa';
import { FinanceState } from './../financeState';
import {
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse
} from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveFinanceRevisionsSuccess = (
	state: FinanceState,
	action: Success<
		RetrieveFinanceRevisionsRequest,
		RetrieveFinanceRevisionsResponse
	>
): FinanceState => ({
	...state,
	revisions: action.result.elements
});

export const onRetrieveFinanceRevisions = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveFinanceRevisionsRequest,
	RetrieveFinanceRevisionsResponse
>('fetchStatuses', 'retrieveRevisions', {
	doneReducer: onRetrieveFinanceRevisionsSuccess
});
