import * as React from 'react';
import { connect } from 'react-redux';
import { retrieveUserStatuses } from 'store/users/actions';
import { Select } from 'components/antd';
import { SelectProps } from 'components/antd/Select/Select';
import styles from './UsersFilters.module.scss';
import { AppState } from 'store-types';
import { IDropdownOption } from 'app-types';
import { FetchStatus } from 'services/api/apiTypes';

type UsersFiltersDropdownProps = SelectProps & {
	name: string;
	// from mapStateToProps
	userStatuses: IDropdownOption[];
	isLoadingUserStatuses: boolean;
	// from mapDispatchToProps
	retrieveUserStatuses: typeof retrieveUserStatuses;
};

class UsersFiltersDropdown extends React.Component<UsersFiltersDropdownProps> {
	componentDidMount() {
		this.props.retrieveUserStatuses();
	}

	render() {
		return (
			<Select
				className={styles.statusSelect}
				value={this.props.value}
				labelInValue
				onChange={this.props.onChange}
			>
				{this.props.userStatuses.map(option => (
					<Select.Option key={option.value}>{option.label}</Select.Option>
				))}
			</Select>
		);
	}
}

const getUserStatusesAsOptions = (state: AppState): IDropdownOption[] => {
	const options = state.users.statuses
		? state.users.statuses.map(status => ({
				label: status.name,
				value: status.code
		  }))
		: [];
	const allOption = {
		label: 'All',
		value: 'all'
	};

	return [allOption, ...options];
};

export default connect(
	(state: AppState) => ({
		userStatuses: getUserStatusesAsOptions(state),
		isLoadingUserStatuses:
			state.users.fetchStatuses.retrievingStatuses === FetchStatus.PENDING
	}),
	{
		retrieveUserStatuses
	}
)(UsersFiltersDropdown);
