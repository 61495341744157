import * as React from 'react';
import { get } from 'lodash';
import { EditContactInitialData, EditContactFormData } from './EditContactForm';
import { Title, ValueList, Position, Box } from 'components';
import { Input, Row, Col, Radio, TextArea } from 'components/antd';
import {
	EditContactFieldName,
	ContactEmailOwnerValue,
	ADD_CONTACT_FORM_ID
} from './EditContactConstants';
import { AutocompleteCompany } from 'containers';
import { ComponentOrganisationTypes } from 'containers/Autocomplete/autocompleteEntityConstants';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { Company } from 'services/api/companies/companiesServiceTypes';
import { getCompanyById } from 'store/companies/selectors';
import { getValues } from 'store/form/selectors';
import { retrieveCompany } from 'store/companies';
import { LabeledValue } from 'app-types';
import {
	getEditContactSelectedCompanyAddress,
	getCurrentContact
} from 'store/messages/selectors';
import styles from './EditContact.module.scss';
import { ContactType } from 'services/api/messages/messagesServiceTypes';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';

interface EditContactFieldsProps {
	// from mapStateToProps
	formValues: EditContactFormData;
	selectedCompany: Company;
	selectedCompanyAddress: string;
	// from mapDispatchToProps
	retrieveCompany: typeof retrieveCompany;
	contact: ContactType | undefined;
}

class EditContactFields extends React.PureComponent<
	EditContactInitialData & EditContactFieldsProps
> {
	isFetchedInitialCompany: boolean;

	componentDidUpdate() {
		const { formValues } = this.props;
		if (formValues.company && !this.isFetchedInitialCompany) {
			this.isFetchedInitialCompany = true;
			this.props.retrieveCompany(formValues.company.key);
		}
	}

	retrieveCompany = (option: LabeledValue) => {
		this.props.retrieveCompany(option.key);
	};

	render() {
		const { props } = this;
		const contactTypeDisabled = !!get(props.contact, 'relatesToUser', false);

		return (
			<Row>
				<Col xs={12}>
					<div>
						<div>Contact Email Address</div>
						<strong>{props.formValues.email}</strong>
					</div>
					<AutocompleteCompany
						name={EditContactFieldName.COMPANY}
						label="Company"
						required
						params={{
							organisationTypes: ComponentOrganisationTypes.ALL
						}}
						onChange={this.retrieveCompany}
					/>
					{Boolean(props.selectedCompany) &&
					(props.selectedCompanyAddress ||
						props.selectedCompany.companyEmail) ? (
						<Position id={'company-details-container'}>
							<Box type="standard" className={styles.companyBox}>
								<Box.Header>
									<Title.H4 marginBottom={0}>Company Details</Title.H4>
								</Box.Header>
								<Box.Content>
									<ValueList
										dataSource={[
											...(props.selectedCompanyAddress
												? [
														{
															label: 'Company Address',
															key: props.selectedCompanyAddress
														}
												  ]
												: []),
											...(props.selectedCompany.companyEmail
												? [
														{
															label: 'Company Email Address',
															key: props.selectedCompany.companyEmail
														}
												  ]
												: [])
										]}
									/>
								</Box.Content>
							</Box>
						</Position>
					) : null}
					<MaybeTooltip
						show={contactTypeDisabled}
						title="User with such email has been already invited and his/her contact type cannot be changed"
					>
						<Radio.Group.ReduxFormItem
							disabled={contactTypeDisabled}
							name={EditContactFieldName.CONTACT_TYPE}
							defaultValue={ContactEmailOwnerValue.PERSONAL}
							required
						>
							<Radio value={ContactEmailOwnerValue.PERSONAL}>
								Personal Email
							</Radio>
							<Radio value={ContactEmailOwnerValue.GENERIC}>
								Shared/Company Email
							</Radio>
						</Radio.Group.ReduxFormItem>
					</MaybeTooltip>
				</Col>
				<Col xs={12} sm={6}>
					<Input.ReduxFormItem
						name={EditContactFieldName.FIRST_NAME}
						label="First Name"
						help
					/>
				</Col>
				<Col xs={12} sm={6}>
					<Input.ReduxFormItem
						name={EditContactFieldName.LAST_NAME}
						label="Last Name"
						help
					/>
				</Col>
				<Col xs={12}>
					<Input.ReduxFormItem
						name={EditContactFieldName.PHONE}
						label="Phone"
						help
					/>
					<TextArea.ReduxFormItem
						name={EditContactFieldName.NOTES}
						label="Notes"
						help
					/>
				</Col>
			</Row>
		);
	}
}

export default connect(
	(state: AppState) => {
		const formValues = getValues<EditContactFormData>(
			state,
			ADD_CONTACT_FORM_ID
		);
		return {
			formValues,
			contact: getCurrentContact(state),
			selectedCompany: getCompanyById(
				state,
				formValues.company ? formValues.company.key : ''
			),
			selectedCompanyAddress: getEditContactSelectedCompanyAddress(
				state,
				ADD_CONTACT_FORM_ID
			)
		};
	},
	{ retrieveCompany }
)(EditContactFields);
