import Api from 'services/api';
import { select, put } from 'redux-saga/effects';
import { toggleFinanceAsync } from '../actions/toggleFinance';
import {
	ToggleFinancePayload,
	ToggleFinanceRequest,
	JobFinanceExpandParam
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { retrieveFinance, retrieveFinanceDetails } from 'store/finance/actions';
import { retrievePortCall } from 'store/portcalls/actions';

export default makeTakeLatestWatcher<ToggleFinancePayload, null, Error>({
	api: Api.Finance.toggleFinance,
	async: toggleFinanceAsync,
	*getApiParams({ isFinanceEnabled }) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request: ToggleFinanceRequest = {
			portCallId,
			jobCode,
			isFinanceEnabled
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
		yield put(retrieveFinanceDetails({ portCallId }));
		yield put(
			retrievePortCall({
				id: portCallId,
				expand: 'PortJobsBasic'
			})
		);
	}
});
