import * as React from 'react';
import { Row as FlexboxRow, RowProps } from 'react-flexbox-grid';

interface ExtendedRowProps extends RowProps {
	className?: string;
	style?: React.CSSProperties;
}

export const Row: React.FC<ExtendedRowProps> = props => (
	<FlexboxRow {...props} />
);

export default Row;
