import { CommoditiesState, initialState } from '../commoditiesState';
import { MainCommodity } from 'services/api/commodities/commoditiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ResultCollection } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';

const onRetrieveMainCommoditiesSuccess = (
	state: CommoditiesState,
	action: Success<null, ResultCollection<MainCommodity>>
) => ({
	...state,
	mainCommodities: action.result.elements
});

export const onRetrieveMainCommodities = makeFetchStatusReducers<
	CommoditiesState,
	'fetchStatuses',
	null,
	ResultCollection<MainCommodity>
>('fetchStatuses', 'all', {
	doneReducer: onRetrieveMainCommoditiesSuccess
});

export const onResetMainCommodities = (state: CommoditiesState) => ({
	...state,
	mainCommodities: initialState.mainCommodities
});
