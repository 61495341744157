import {
	RetrieveCompaniesRequest,
	RetrieveCompaniesResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANIES';

export const retrieveCompaniesAsync = actionCreator.async<
	RetrieveCompaniesRequest,
	RetrieveCompaniesResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanies = actionCreator<
	RetrieveCompaniesRequest | undefined
>(ACTION_NAME);

export const resetCompanies = actionCreator('RESET_COMPANIES');
