import React, { FC } from 'react';
import { Flex, Link, Title } from 'components';
import { Row, Col, Input } from 'components/antd';
import { Align } from 'components/types';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import config from 'services/config';
import styles from './EditUser.module.scss';
import { isBoolean } from 'lodash';

interface UserDetailsPreferencesProps {
	email?: string;
	companyName?: string;
	isProfilePage?: boolean;
	isInternalUser?: boolean;
}

const UserDetailsPreferences: FC<UserDetailsPreferencesProps> = ({
	email,
	companyName,
	isProfilePage,
	isInternalUser
}) => {
	return (
		<>
			<Row>
				<Col sm={4}>
					<Flex fit>
						<div className={styles.rightGap}>
							<Title.Label>Company</Title.Label>
							<strong>{companyName}</strong>
						</div>
						<div>
							<Title.Label>User Email</Title.Label>
							<strong>{email}</strong>
						</div>
					</Flex>
				</Col>
				{isProfilePage && isBoolean(isInternalUser) && !isInternalUser && (
					<Col sm={4} align={Align.BOTTOM}>
						<Link
							href={`${config.idServUrl}/account/updatePassword?email=${email &&
								encodeURIComponent(email)}`}
							target="_self"
						>
							Change Password
						</Link>
					</Col>
				)}
			</Row>
			<Row>
				<Col sm={3}>
					<Input.ReduxFormItem
						name="firstName"
						label="First Name"
						help
						required
					/>
					<Input.ReduxFormItem name="middleName" label="Middle Name" help />
					<Input.ReduxFormItem
						name="lastName"
						label="Last Name"
						help
						required
					/>
					<PhoneInput.ReduxFormItem
						defaultCountry="gb"
						label="Phone Number"
						name="cellphone"
						inputProps={{ maxLength: 20 }}
						required
						help
					/>
				</Col>
			</Row>
		</>
	);
};

export default UserDetailsPreferences;
