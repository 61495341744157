import {
	put,
	take,
	fork,
	cancel,
	call,
	select,
	takeLatest,
	race
} from 'redux-saga/effects';
import {
	openThreadJobSearch,
	searchJobForThreadAsync,
	searchJobForThread,
	quickAssignJobToThread,
	quickAssignJobToThreadAsync
} from '../actions/quickJobAssignToThread';
import { PortCallJobSearchResult } from 'services/api/portCalls/portCallsServiceTypes';
import { searchPortCallJobsByCode } from 'services/api/portCalls/portCallsService';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import Api from 'services/api';
import {
	AssignJobToThreadParams,
	AssignJobToThreadResponse
} from 'services/api/threads/threadsServiceTypes';
import { getActiveThreadId } from 'store/threads/selectors';
import { assignJobsToNewThread, updateThreads } from 'store/threads/actions';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';
import { changeRoute } from 'store/route/actions';
import { retrieveThreadById, retrieveThreadByIdAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { getCurrentPath } from 'store/route/selectors';
import {
	getThreadMainPrincipalId,
	getNewTreadMainPrincipalId
} from '../selectors';
import { isInComposeMessageRoute } from 'store/route/routeUtils';
import { getActiveThread } from 'store/thread/selectors';

const assignJobToThread = makeDefaultExecutor<
	AssignJobToThreadParams,
	AssignJobToThreadResponse,
	AxiosGeneralError
>({
	api: Api.Threads.assignJobToThread,
	async: quickAssignJobToThreadAsync
});

function* isInNewThreadCreationFlow() {
	const route = yield select(getCurrentPath);
	return isInComposeMessageRoute(route);
}

function* assignJobToThreadProcess({ payload: jobCode }: Action<string>) {
	const threadId: string = yield select(getActiveThreadId);
	const isNewThread = yield call(isInNewThreadCreationFlow);
	if (isNewThread) {
		// new thread, only local state set
		yield put(assignJobsToNewThread([jobCode]));
		return;
	}
	yield fork(assignJobToThread, { threadId, jobCode });
	const { failed } = yield race({
		done: take(quickAssignJobToThreadAsync.done),
		failed: take(quickAssignJobToThreadAsync.failed)
	});
	if (failed) {
		return;
	}
	// refresh thread to see assignment
	yield put(
		retrieveThreadById({
			threadId,
			isSilent: true
		})
	);
	yield take(retrieveThreadByIdAsync.done);
	yield put(notify.success('Job has been sucessfully assigned.'));
	const activeThread = yield select(getActiveThread);
	yield put(updateThreads(activeThread));
}

function* retrieveJobsBySearch({
	payload: searchTerm
}: Action<string>): SagaIterator {
	if (searchTerm.length < 2) {
		return; // to small text for search
	}
	const isNewThread = yield call(isInNewThreadCreationFlow);
	let mainPrincipalId: string;
	if (isNewThread) {
		mainPrincipalId = yield select(getNewTreadMainPrincipalId);
	} else {
		mainPrincipalId = yield select(getThreadMainPrincipalId);
	}
	try {
		yield put(searchJobForThreadAsync.started({ searchTerm, mainPrincipalId }));
		const result: PortCallJobSearchResult[] = yield call(
			searchPortCallJobsByCode,
			searchTerm,
			mainPrincipalId
		);
		yield put(
			searchJobForThreadAsync.done({
				params: { searchTerm, mainPrincipalId },
				result,
				response: null
			})
		);
	} catch (error) {
		yield put(
			searchJobForThreadAsync.failed({
				error,
				params: { searchTerm, mainPrincipalId }
			})
		);
	}
}

export default function*() {
	while (true) {
		// init listeners only after opening search box
		yield take(openThreadJobSearch);

		// actions available only after search box was opened in this route
		const process = yield fork(function* listen() {
			yield takeLatest(searchJobForThread, retrieveJobsBySearch);
			yield takeLatest(quickAssignJobToThread, assignJobToThreadProcess);
		});

		yield take(changeRoute);
		// remove listeneres when user change the route
		yield cancel(process);
	}
}
