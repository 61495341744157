import { validateRequired, validateGroupNameMax } from 'utils/validations';
import { CreateGroupFormData } from '../GroupsModalsTypes';
import { CreateGroupFormProps } from './CreateGroupForm';

const validate = (values: CreateGroupFormData, props: CreateGroupFormProps) => {
	let validationResults: Partial<CreateGroupFormData> = {
		name: validateGroupNameMax(values.name),
		companyId: validateRequired(values.companyId)
	};

	if (props.isSelectedCompanyIss) {
		validationResults = {
			...validationResults,
			groupType: validateRequired(values.groupType)
		};
	}

	return validationResults;
};

export default validate;
