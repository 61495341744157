import { AxiosError } from 'axios';
import { UserStatusesOption } from 'services/api/users/userServiceTypes';
import actionCreator from '../usersActionCreator';

const ACTION_NAME = 'users/RETRIEVE_USERS_STATUSES';
export const retrieveUserStatusesAsync = actionCreator.async<
	undefined,
	UserStatusesOption[],
	AxiosError
>(ACTION_NAME);

export const retrieveUserStatuses = actionCreator(
	retrieveUserStatusesAsync.type
);
