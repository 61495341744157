import { keyBy } from 'lodash';
import { CountriesState, initialState } from '../countriesState';
import {
	RetrieveCountriesRequest,
	RetrieveCountriesResponse
} from 'services/api/countries/countriesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';
export function onRetrieveCountriesStarted(
	state: CountriesState
): CountriesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.PENDING
		}
	};
}
export function onRetrieveCountriesSuccess(
	state: CountriesState,
	action: Success<RetrieveCountriesRequest, RetrieveCountriesResponse>
): CountriesState {
	const { result, params } = action;
	const { elements } = result;
	const byCode = {
		...state.byCode,
		...keyBy(elements, item => item.code)
	};
	return {
		...state,
		byCode,
		hasMore: params?.limit === elements.length,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveCountriesFailed(
	state: CountriesState
): CountriesState {
	return {
		...state,
		hasMore: false,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		}
	};
}

export function onResetCountries(state: CountriesState): CountriesState {
	return {
		...state,
		byCode: initialState.byCode,
		hasMore: initialState.hasMore,
		fetchStatuses: {
			...state.fetchStatuses,
			all: initialState.fetchStatuses.all
		}
	};
}
