import actionCreator from '../documentsActionCreator';
import {
	SaveDocumentRequest,
	SaveDocumentResponse
} from 'services/api/documents/documentsServiceTypes';

/**
 * Notify the  API about the uploaded document
 */
const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const saveDocumentAsync = actionCreator.async<
	SaveDocumentRequest,
	SaveDocumentResponse,
	Error
>(SAVE_DOCUMENT, {
	failed: {
		omit: true
	}
});
export const saveDocument = actionCreator<SaveDocumentRequest>(SAVE_DOCUMENT);
