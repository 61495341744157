
import { default as AddShape } from './svg/add-shape.svg';
import { default as AddText } from './svg/add-text.svg';
import { default as AdvancedFilter } from './svg/advanced-filter.svg';
import { default as AgencyCost } from './svg/agency-cost.svg';
import { default as Anchor } from './svg/anchor.svg';
import { default as AnnotatedDocument } from './svg/annotated-document.svg';
import { default as ArrowDownNew } from './svg/arrow-down-new.svg';
import { default as ArrowDown } from './svg/arrow-down.svg';
import { default as ArrowForward } from './svg/arrow-forward.svg';
import { default as ArrowLeft } from './svg/arrow-left.svg';
import { default as ArrowRight } from './svg/arrow-right.svg';
import { default as ArrowUpNew } from './svg/arrow-up-new.svg';
import { default as ArrowUp } from './svg/arrow-up.svg';
import { default as ArrowsAlt } from './svg/arrows-alt.svg';
import { default as Bars } from './svg/bars.svg';
import { default as Bell } from './svg/bell.svg';
import { default as Bold } from './svg/bold.svg';
import { default as Box } from './svg/box.svg';
import { default as Briefcase } from './svg/briefcase.svg';
import { default as Buoy } from './svg/buoy.svg';
import { default as CalendarCheck } from './svg/calendar-check.svg';
import { default as Calendar } from './svg/calendar.svg';
import { default as CanalTransit } from './svg/canal-transit.svg';
import { default as Canal } from './svg/canal.svg';
import { default as CaretDown } from './svg/caret-down.svg';
import { default as CaretUp } from './svg/caret-up.svg';
import { default as Check } from './svg/check.svg';
import { default as ClockCircleO } from './svg/clock-circle-o.svg';
import { default as CloseNew } from './svg/close-new.svg';
import { default as Close } from './svg/close.svg';
import { default as CloudUpload } from './svg/cloud-upload.svg';
import { default as Code } from './svg/code.svg';
import { default as ColorNew } from './svg/color-new.svg';
import { default as Color } from './svg/color.svg';
import { default as CommentEmpty } from './svg/comment-empty.svg';
import { default as CommentPlus } from './svg/comment-plus.svg';
import { default as Comment } from './svg/comment.svg';
import { default as Container } from './svg/container.svg';
import { default as Copy } from './svg/copy.svg';
import { default as DeleteLarge } from './svg/delete-large.svg';
import { default as DeleteNew } from './svg/delete-new.svg';
import { default as Delete } from './svg/delete.svg';
import { default as DeveloperPortIn } from './svg/developer-port-in.svg';
import { default as DeveloperPortOut } from './svg/developer-port-out.svg';
import { default as DocumentClock } from './svg/document-clock.svg';
import { default as DocumentStack } from './svg/document-stack.svg';
import { default as Document } from './svg/document.svg';
import { default as DollarInOut } from './svg/dollar-in-out.svg';
import { default as DollarIn } from './svg/dollar-in.svg';
import { default as DollarOut } from './svg/dollar-out.svg';
import { default as Dollar } from './svg/dollar.svg';
import { default as Down } from './svg/down.svg';
import { default as DownloadNew } from './svg/download-new.svg';
import { default as Download } from './svg/download.svg';
import { default as Draft } from './svg/draft.svg';
import { default as Drag } from './svg/drag.svg';
import { default as Draw } from './svg/draw.svg';
import { default as Drawing } from './svg/drawing.svg';
import { default as Edit } from './svg/edit.svg';
import { default as Eta } from './svg/eta.svg';
import { default as Ets } from './svg/ets.svg';
import { default as FileExcel } from './svg/file-excel.svg';
import { default as FileImage } from './svg/file-image.svg';
import { default as FilePdf } from './svg/file-pdf.svg';
import { default as FileWord } from './svg/file-word.svg';
import { default as Filter } from './svg/filter.svg';
import { default as FinanceDisable } from './svg/finance-disable.svg';
import { default as Finance } from './svg/finance.svg';
import { default as FullView } from './svg/full-view.svg';
import { default as Funding } from './svg/funding.svg';
import { default as FxRate } from './svg/fx-rate.svg';
import { default as GeneralPort } from './svg/general-port.svg';
import { default as History } from './svg/history.svg';
import { default as Inbox } from './svg/inbox.svg';
import { default as InfoCircle } from './svg/info-circle.svg';
import { default as Info } from './svg/info.svg';
import { default as Italic } from './svg/italic.svg';
import { default as Jobs } from './svg/jobs.svg';
import { default as LeftAlign } from './svg/left-align.svg';
import { default as Left } from './svg/left.svg';
import { default as LevelDown } from './svg/level-down.svg';
import { default as Link } from './svg/link.svg';
import { default as ListOl } from './svg/list-ol.svg';
import { default as ListUl } from './svg/list-ul.svg';
import { default as Loading } from './svg/loading.svg';
import { default as Lock } from './svg/lock.svg';
import { default as LongArrowDown } from './svg/long-arrow-down.svg';
import { default as LongArrowLeft } from './svg/long-arrow-left.svg';
import { default as LongArrowRight } from './svg/long-arrow-right.svg';
import { default as LongArrowUp } from './svg/long-arrow-up.svg';
import { default as MailForward } from './svg/mail-forward.svg';
import { default as MailReplyAll } from './svg/mail-reply-all.svg';
import { default as MailReply } from './svg/mail-reply.svg';
import { default as Mail } from './svg/mail.svg';
import { default as Merge } from './svg/merge.svg';
import { default as Messages } from './svg/messages.svg';
import { default as MiddleAlign } from './svg/middle-align.svg';
import { default as MinusSquare } from './svg/minus-square.svg';
import { default as Minus } from './svg/minus.svg';
import { default as More } from './svg/more.svg';
import { default as NewTabAlt } from './svg/new-tab-alt.svg';
import { default as OperationsDisable } from './svg/operations-disable.svg';
import { default as Operations } from './svg/operations.svg';
import { default as Overview } from './svg/overview.svg';
import { default as PageLeft } from './svg/page-left.svg';
import { default as PageRight } from './svg/page-right.svg';
import { default as PaperClip } from './svg/paper-clip.svg';
import { default as Picture } from './svg/picture.svg';
import { default as PlusCircle } from './svg/plus-circle.svg';
import { default as PlusSquare } from './svg/plus-square.svg';
import { default as Plus } from './svg/plus.svg';
import { default as PortVoyage } from './svg/port-voyage.svg';
import { default as Print } from './svg/print.svg';
import { default as QuestionCircle } from './svg/question-circle.svg';
import { default as QuoteRight } from './svg/quote-right.svg';
import { default as Redo } from './svg/redo.svg';
import { default as Refresh } from './svg/refresh.svg';
import { default as RemoveAnnotations } from './svg/remove-annotations.svg';
import { default as RightAlign } from './svg/right-align.svg';
import { default as Right } from './svg/right.svg';
import { default as RollbackDocument } from './svg/rollback-document.svg';
import { default as Rotate } from './svg/rotate.svg';
import { default as SavePdf } from './svg/save-pdf.svg';
import { default as Savings } from './svg/savings.svg';
import { default as Search } from './svg/search.svg';
import { default as ServicesLine } from './svg/services-line.svg';
import { default as Settings } from './svg/settings.svg';
import { default as Ship } from './svg/ship.svg';
import { default as SignOut } from './svg/sign-out.svg';
import { default as Slideshare } from './svg/slideshare.svg';
import { default as Snooze } from './svg/snooze.svg';
import { default as SplitView } from './svg/split-view.svg';
import { default as Split } from './svg/split.svg';
import { default as Star } from './svg/star.svg';
import { default as Strikethrough } from './svg/strikethrough.svg';
import { default as Swap } from './svg/swap.svg';
import { default as Terminal } from './svg/terminal.svg';
import { default as Text } from './svg/text.svg';
import { default as Underline } from './svg/underline.svg';
import { default as Undo } from './svg/undo.svg';
import { default as UnitCost } from './svg/unit-cost.svg';
import { default as Unlink } from './svg/unlink.svg';
import { default as Up } from './svg/up.svg';
import { default as User } from './svg/user.svg';
import { default as Users } from './svg/users.svg';
import { default as VesselProgramme } from './svg/vessel-programme.svg';
import { default as Vessel } from './svg/vessel.svg';
import { default as Visible } from './svg/visible.svg';
import { default as Warning } from './svg/warning.svg';
import { default as ZoomIn } from './svg/zoom-in.svg';
import { default as ZoomOut } from './svg/zoom-out.svg';

export interface IconType {
	'add-shape': string;
	'add-text': string;
	'advanced-filter': string;
	'agency-cost': string;
	'anchor': string;
	'annotated-document': string;
	'arrow-down-new': string;
	'arrow-down': string;
	'arrow-forward': string;
	'arrow-left': string;
	'arrow-right': string;
	'arrow-up-new': string;
	'arrow-up': string;
	'arrows-alt': string;
	'bars': string;
	'bell': string;
	'bold': string;
	'box': string;
	'briefcase': string;
	'buoy': string;
	'calendar-check': string;
	'calendar': string;
	'canal-transit': string;
	'canal': string;
	'caret-down': string;
	'caret-up': string;
	'check': string;
	'clock-circle-o': string;
	'close-new': string;
	'close': string;
	'cloud-upload': string;
	'code': string;
	'color-new': string;
	'color': string;
	'comment-empty': string;
	'comment-plus': string;
	'comment': string;
	'container': string;
	'copy': string;
	'delete-large': string;
	'delete-new': string;
	'delete': string;
	'developer-port-in': string;
	'developer-port-out': string;
	'document-clock': string;
	'document-stack': string;
	'document': string;
	'dollar-in-out': string;
	'dollar-in': string;
	'dollar-out': string;
	'dollar': string;
	'down': string;
	'download-new': string;
	'download': string;
	'draft': string;
	'drag': string;
	'draw': string;
	'drawing': string;
	'edit': string;
	'eta': string;
	'ets': string;
	'file-excel': string;
	'file-image': string;
	'file-pdf': string;
	'file-word': string;
	'filter': string;
	'finance-disable': string;
	'finance': string;
	'full-view': string;
	'funding': string;
	'fx-rate': string;
	'general-port': string;
	'history': string;
	'inbox': string;
	'info-circle': string;
	'info': string;
	'italic': string;
	'jobs': string;
	'left-align': string;
	'left': string;
	'level-down': string;
	'link': string;
	'list-ol': string;
	'list-ul': string;
	'loading': string;
	'lock': string;
	'long-arrow-down': string;
	'long-arrow-left': string;
	'long-arrow-right': string;
	'long-arrow-up': string;
	'mail-forward': string;
	'mail-reply-all': string;
	'mail-reply': string;
	'mail': string;
	'merge': string;
	'messages': string;
	'middle-align': string;
	'minus-square': string;
	'minus': string;
	'more': string;
	'new-tab-alt': string;
	'operations-disable': string;
	'operations': string;
	'overview': string;
	'page-left': string;
	'page-right': string;
	'paper-clip': string;
	'picture': string;
	'plus-circle': string;
	'plus-square': string;
	'plus': string;
	'port-voyage': string;
	'print': string;
	'question-circle': string;
	'quote-right': string;
	'redo': string;
	'refresh': string;
	'remove-annotations': string;
	'right-align': string;
	'right': string;
	'rollback-document': string;
	'rotate': string;
	'save-pdf': string;
	'savings': string;
	'search': string;
	'services-line': string;
	'settings': string;
	'ship': string;
	'sign-out': string;
	'slideshare': string;
	'snooze': string;
	'split-view': string;
	'split': string;
	'star': string;
	'strikethrough': string;
	'swap': string;
	'terminal': string;
	'text': string;
	'underline': string;
	'undo': string;
	'unit-cost': string;
	'unlink': string;
	'up': string;
	'user': string;
	'users': string;
	'vessel-programme': string;
	'vessel': string;
	'visible': string;
	'warning': string;
	'zoom-in': string;
	'zoom-out': string;
}

export const IconMap = {
	'add-shape': AddShape,
	'add-text': AddText,
	'advanced-filter': AdvancedFilter,
	'agency-cost': AgencyCost,
	'anchor': Anchor,
	'annotated-document': AnnotatedDocument,
	'arrow-down-new': ArrowDownNew,
	'arrow-down': ArrowDown,
	'arrow-forward': ArrowForward,
	'arrow-left': ArrowLeft,
	'arrow-right': ArrowRight,
	'arrow-up-new': ArrowUpNew,
	'arrow-up': ArrowUp,
	'arrows-alt': ArrowsAlt,
	'bars': Bars,
	'bell': Bell,
	'bold': Bold,
	'box': Box,
	'briefcase': Briefcase,
	'buoy': Buoy,
	'calendar-check': CalendarCheck,
	'calendar': Calendar,
	'canal-transit': CanalTransit,
	'canal': Canal,
	'caret-down': CaretDown,
	'caret-up': CaretUp,
	'check': Check,
	'clock-circle-o': ClockCircleO,
	'close-new': CloseNew,
	'close': Close,
	'cloud-upload': CloudUpload,
	'code': Code,
	'color-new': ColorNew,
	'color': Color,
	'comment-empty': CommentEmpty,
	'comment-plus': CommentPlus,
	'comment': Comment,
	'container': Container,
	'copy': Copy,
	'delete-large': DeleteLarge,
	'delete-new': DeleteNew,
	'delete': Delete,
	'developer-port-in': DeveloperPortIn,
	'developer-port-out': DeveloperPortOut,
	'document-clock': DocumentClock,
	'document-stack': DocumentStack,
	'document': Document,
	'dollar-in-out': DollarInOut,
	'dollar-in': DollarIn,
	'dollar-out': DollarOut,
	'dollar': Dollar,
	'down': Down,
	'download-new': DownloadNew,
	'download': Download,
	'draft': Draft,
	'drag': Drag,
	'draw': Draw,
	'drawing': Drawing,
	'edit': Edit,
	'eta': Eta,
	'ets': Ets,
	'file-excel': FileExcel,
	'file-image': FileImage,
	'file-pdf': FilePdf,
	'file-word': FileWord,
	'filter': Filter,
	'finance-disable': FinanceDisable,
	'finance': Finance,
	'full-view': FullView,
	'funding': Funding,
	'fx-rate': FxRate,
	'general-port': GeneralPort,
	'history': History,
	'inbox': Inbox,
	'info-circle': InfoCircle,
	'info': Info,
	'italic': Italic,
	'jobs': Jobs,
	'left-align': LeftAlign,
	'left': Left,
	'level-down': LevelDown,
	'link': Link,
	'list-ol': ListOl,
	'list-ul': ListUl,
	'loading': Loading,
	'lock': Lock,
	'long-arrow-down': LongArrowDown,
	'long-arrow-left': LongArrowLeft,
	'long-arrow-right': LongArrowRight,
	'long-arrow-up': LongArrowUp,
	'mail-forward': MailForward,
	'mail-reply-all': MailReplyAll,
	'mail-reply': MailReply,
	'mail': Mail,
	'merge': Merge,
	'messages': Messages,
	'middle-align': MiddleAlign,
	'minus-square': MinusSquare,
	'minus': Minus,
	'more': More,
	'new-tab-alt': NewTabAlt,
	'operations-disable': OperationsDisable,
	'operations': Operations,
	'overview': Overview,
	'page-left': PageLeft,
	'page-right': PageRight,
	'paper-clip': PaperClip,
	'picture': Picture,
	'plus-circle': PlusCircle,
	'plus-square': PlusSquare,
	'plus': Plus,
	'port-voyage': PortVoyage,
	'print': Print,
	'question-circle': QuestionCircle,
	'quote-right': QuoteRight,
	'redo': Redo,
	'refresh': Refresh,
	'remove-annotations': RemoveAnnotations,
	'right-align': RightAlign,
	'right': Right,
	'rollback-document': RollbackDocument,
	'rotate': Rotate,
	'save-pdf': SavePdf,
	'savings': Savings,
	'search': Search,
	'services-line': ServicesLine,
	'settings': Settings,
	'ship': Ship,
	'sign-out': SignOut,
	'slideshare': Slideshare,
	'snooze': Snooze,
	'split-view': SplitView,
	'split': Split,
	'star': Star,
	'strikethrough': Strikethrough,
	'swap': Swap,
	'terminal': Terminal,
	'text': Text,
	'underline': Underline,
	'undo': Undo,
	'unit-cost': UnitCost,
	'unlink': Unlink,
	'up': Up,
	'user': User,
	'users': Users,
	'vessel-programme': VesselProgramme,
	'vessel': Vessel,
	'visible': Visible,
	'warning': Warning,
	'zoom-in': ZoomIn,
	'zoom-out': ZoomOut,
}