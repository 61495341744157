import { StorageGenericManager } from './StorageGenericManager';
import { USER_SWITCH_PREFIX, USER_SWITCH_TO_OPTIC } from 'app-constants';

interface AppRedirection {
	[name: string]: string;
}
const appRedirectStorage = new StorageGenericManager<AppRedirection>(
	localStorage
);

export function setUserChoice(currentUserId: string, value: string) {
	const localStorageKey = USER_SWITCH_PREFIX + currentUserId;
	appRedirectStorage.set(localStorageKey, value);
}

function getUserChoice(currentUserId: string): string | null {
	const localStorageKey = USER_SWITCH_PREFIX + currentUserId;
	return appRedirectStorage.get(localStorageKey);
}

export function hasUserSwitchedOptic(currentUserId: string) {
	return getUserChoice(currentUserId) === USER_SWITCH_TO_OPTIC;
}
