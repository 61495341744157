import React from 'react';
import { reduxForm, InjectedFormProps, ConfigProps } from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';

import { times } from 'lodash';

export interface ContextualizationFormFormDataBase {
	activePage?: number;
}

interface ContextualizationFormOwnProps {
	field: string;
	pages: number;
	activePage: number;
	onValidChange: (valid: boolean) => void;
}

function createDocumentViewerForm<
	FormData extends ContextualizationFormFormDataBase,
	P,
	ErrorType = string
>(config: ConfigProps<FormData, ContextualizationFormOwnProps & P, ErrorType>) {
	interface ContextualizationFormProps
		extends InjectedFormProps<
				FormData,
				ContextualizationFormOwnProps,
				ErrorType
			>,
			ContextualizationFormOwnProps {}

	class ContextualizationForm extends React.Component<
		ContextualizationFormProps
	> {
		componentDidMount() {
			/**
			 * IPP-25435 - If there are a lot of pages to initialize
			 * this method blocks UI before actually showing it
			 */
			setTimeout(this.initializePages);
		}

		componentDidUpdate(prevProps: ContextualizationFormProps) {
			if (this.props.valid !== prevProps.valid) {
				this.props.onValidChange(this.props.valid);
			}
			/**
			 * Set `activePage` so that `validate`
			 * function is triggered on page change
			 */
			if (this.props.activePage !== prevProps.activePage) {
				this.props.change('activePage', this.props.activePage);
			}
		}

		initializePages = () => {
			const { pages, field } = this.props;

			times(pages, () => {
				this.props.array.push(field, {});
			});
		};

		render() {
			return <>{this.props.children}</>;
		}
	}

	return reduxForm<FormData, ContextualizationFormOwnProps, ErrorType>({
		...DEFAULT_REDUX_FORM_CONFIG,
		...config
	})(ContextualizationForm);
}

export default createDocumentViewerForm;
