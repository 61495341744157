import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallsState } from '../portCallsState';
import {
	MergePortCallsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';

const onMergePortCallsSuccess = (state: PortCallsState): PortCallsState => ({
	...state,
	context: {
		...state.context,
		mergePortCallsContext: { isDuplicate: false }
	}
});

export const onMergePortCalls = makeFetchStatusReducers<
	PortCallsState,
	'fetchStatuses',
	MergePortCallsRequest,
	MergePortCallsResponse
>('fetchStatuses', 'merge', {
	doneReducer: onMergePortCallsSuccess
});
