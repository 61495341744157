import * as React from 'react';
import { Menu } from 'components/antd';
import { Permission } from 'utils/components';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';

interface PermissionMenuItemProps extends MenuItemProps {
	permissionCode: PermissionCode | PermissionCode[];
	disabled: boolean;
}

export default class PermissionMenuItem extends React.Component<
	PermissionMenuItemProps
> {
	render() {
		const { permissionCode, disabled, children, ...menuProps } = this.props;
		return (
			<Permission permissionCode={permissionCode}>
				{hasPermission =>
					hasPermission && (
						<Menu.Item disabled={Boolean(disabled)} {...menuProps}>
							{children}
						</Menu.Item>
					)
				}
			</Permission>
		);
	}
}
