import * as React from 'react';
import classNames from 'classnames';
import { ThemeScheme, Size } from 'components/types';
import styles from './HeroPanel.module.scss';

const HeroPanelTitle: React.FC = ({ children }) => (
	<h2 className={styles.title}>{children}</h2>
);

class HeroPanel extends React.PureComponent<{
	type: ThemeScheme;
	size?: Size;
	center?: boolean;
}> {
	static Title = HeroPanelTitle;
	render() {
		const { type, size = 'sm', center = false, children } = this.props;
		return (
			<div
				className={classNames(styles.root, styles[type], styles[size], {
					[styles.center]: center
				})}
			>
				{children}
			</div>
		);
	}
}

export default HeroPanel;
