import Api from 'services/api';
import { select } from 'redux-saga/effects';
import { viewBenchmarkAsync } from '../actions/viewBenchmark';
import {
	ViewBenchmarkRequest,
	ViewBenchmarkResponse
} from 'services/api/finance/financeServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getFinanceBenchmarkId } from '../selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';

export default makeTakeLatestWatcher<
	ViewBenchmarkRequest,
	ViewBenchmarkResponse,
	Error
>({
	api: Api.Finance.viewBenchmark,
	async: viewBenchmarkAsync,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const benchmarkId = yield select(getFinanceBenchmarkId);
		return {
			portCallId,
			jobCode,
			benchmarkId
		};
	}
});
