import React from 'react';
import { Text } from 'components';
import { ShowMoreText } from 'components/ReadMore';
import { Row, Col } from 'components/antd';
import styles from './Charterer.module.scss';
import CargoGrid from './CargoGrid/CargoGrid';
import Details from './Details/Details';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';

import { ChartererDisplay } from '../CharterersTypes';

interface ChartererProps {
	charterer: ChartererDisplay;
	operationTypeCode: OperationTypeCode;
}

export default ({ charterer, operationTypeCode }: ChartererProps) => (
	<div className={styles.charterer}>
		Charterer:&nbsp;
		<Text upper weight="bold">
			{charterer.chartererCompany.name}
		</Text>
		<CargoGrid
			cargoes={charterer.cargoes}
			operationTypeCode={operationTypeCode}
		/>
		<Row>
			<Col sm={5}>
				<div className={styles.instruction}>
					<Text upper weight="bold">
						Operational Instructions
					</Text>
					<br />
					{charterer.operationalInstructions ? (
						<ShowMoreText>
							<p>{charterer.operationalInstructions}</p>
						</ShowMoreText>
					) : (
						'None'
					)}
				</div>
				{charterer.isOtherRebillablesApplied && (
					<div className={styles.instruction}>
						<Text upper weight="bold">
							Financial Instructions
						</Text>
						<br />
						<ShowMoreText>
							<p>{charterer.financialInstructions}</p>
						</ShowMoreText>
					</div>
				)}
			</Col>
			<Col sm={5}>
				<Details
					costSettlementIsDirect={charterer.costSettlementIsDirect}
					isWorldScaleServicesApplied={charterer.isWorldScaleServicesApplied}
					isOtherRebillablesApplied={charterer.isOtherRebillablesApplied}
					maxPortCost={charterer.maxPortCost}
					timeBar={charterer.timeBar}
				/>
			</Col>
		</Row>
	</div>
);
