import { ConfigurationState, VesselTypesTabMode } from '../configurationState';

export const onSetVesselTypesTabMode = (
	state: ConfigurationState,
	vesselTypesTabMode: VesselTypesTabMode
): ConfigurationState => ({
	...state,
	context: {
		...state.context,
		vesselTypesTabMode
	}
});
