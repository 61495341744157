import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Text, Amount } from 'components';
import { Row, Col } from 'components/antd';
import { SupplierInvoicePortJobService } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import InvoiceDetailRow from '../../../components/InvoiceDetailRow/InvoiceDetailRow';
import SupplierDetailsColumn from '../../../components/SupplierDetailsColumn/SupplierDetailsColumn';
import { getAddSupplierInvoiceCurrencyDecimalPlaces } from 'store/supplierInvoice/selectors';

interface InvoiceDetailsSectionProps {
	portJobId: string;
	service: SupplierInvoicePortJobService;
	isCredit?: boolean;
}

const InvoiceDetailsSection: FC<InvoiceDetailsSectionProps> = ({
	service,
	portJobId,
	isCredit
}) => {
	const decimalPlaces = useSelector(getAddSupplierInvoiceCurrencyDecimalPlaces);

	return (
		<InvoiceDetailRow
			service={service}
			portJobId={portJobId}
			outside={false}
			bottom="md"
		>
			<Row between="xs" middle="xs">
				<Col xs={6}>
					<SupplierDetailsColumn>
						<Text weight="semi-bold">{service.name}</Text>
					</SupplierDetailsColumn>
				</Col>
				<Col xs={6}>
					<SupplierDetailsColumn
						type="horizontal"
						hasColon
						label="Service Total"
						value={
							<Amount
								formatType="finance"
								value={service.totalAmount}
								negative={isCredit}
								decimalPart={decimalPlaces}
							/>
						}
					/>
				</Col>
			</Row>
			<Row between="xs" middle="xs">
				<Col xs={12}>
					<SupplierDetailsColumn label="Comment" value={service.comment} />
				</Col>
			</Row>
		</InvoiceDetailRow>
	);
};

export default InvoiceDetailsSection;
