import { Node, Text, Element } from 'slate';
import { flatten } from 'lodash';

import { deserialize } from './deserialize';
import { serialize } from './serialize';

import {
	RichTextBlock,
	RichTextNodeSection,
	RichTextValue,
	ElementType
} from '../../RichTextTypes';
import {
	DEFAULT_NODE_SECTION,
	DEFAULT_NODE_TYPE,
	HTML_PATTERN
} from '../../RichTextConstants';

const descendants = [
	{
		type: DEFAULT_NODE_TYPE,
		children: [{ text: '' }]
	}
];
export function convertFromHtml(
	blocks: RichTextBlock[],
	defaultSection: RichTextNodeSection = DEFAULT_NODE_SECTION
): RichTextValue {
	// https://docs.slatejs.org/concepts/10-normalizing#built-in-constraints
	// All Element nodes must contain at least one Text descendant

	const nodes = flatten(
		blocks.reduce<RichTextValue>((acc, block) => {
			// omit empty blocks unless it is `default`
			if (block.section !== defaultSection && !block.html) {
				return acc;
			}
			let children = deserialize(block.html);

			if (!children.length) {
				children = descendants;
			}
			if (Text.isTextList(children)) {
				children = [{ children }];
			}
			const node: Element = {
				data: {
					section: block.section
				},
				children
			};
			acc.push(node);
			return acc;
		}, [])
	);
	if (!nodes.length) {
		nodes.push({
			data: { section: defaultSection },
			children: descendants
		});
	}
	return nodes;
}

export function convertToHtml(nodes: RichTextValue): { [key: string]: string };
export function convertToHtml(
	nodes: RichTextValue,
	section: RichTextNodeSection[]
): string;

export function convertToHtml(
	nodes: RichTextValue,
	section?: RichTextNodeSection[]
) {
	const serialized = serialize(nodes, section);
	return (!section && serialized) || Object.values(serialized).join('');
}

export function isRichTextEmpty(
	nodes: RichTextValue,
	section: RichTextNodeSection[]
) {
	const match = nodes.some(child => {
		if (!child.data || !section.includes(child.data.section)) {
			return false;
		}
		if (child.children.some(({ type }: Node) => type === ElementType.IMG)) {
			return true;
		}
		const texts = Node.texts(child);
		for (const [node] of texts) {
			if (node.text.length > 0) {
				return true;
			}
		}
		return false;
	});
	return !match;
}

export function withoutHtml(html: string) {
	return html.replace(HTML_PATTERN, '');
}
