import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortCallState } from '../portCallState';
import { FetchStatus } from 'services/api/apiTypes';

export const onRetrievePortCallVesselInfo = makeFetchStatusReducers<
	PortCallState,
	'fetchStatuses',
	null,
	null
>('fetchStatuses', 'vesselInfo');

export const onResetPortCallInfo = (state: PortCallState) => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		vesselInfo: FetchStatus.IDLE
	}
});
