import Api from 'services/api';
import {
	RetrieveTemplatesResponse,
	RetrieveTemplatesRequest
} from 'services/api/templates/templateServiceTypes';

import { makeDefaultExecutor, makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveTemplatesAsync } from 'store/templates/actions/retrieveTemplates';

const apiCall = Api.Templates.retrieveTemplates;
const async = retrieveTemplatesAsync;

const commonExecutor = makeDefaultExecutor<
	RetrieveTemplatesRequest,
	RetrieveTemplatesResponse,
	Error
>({
	api: apiCall,
	async
});

export const retrieveTemplatesWatcher = makeTakeLatestWatcher<
	RetrieveTemplatesRequest,
	RetrieveTemplatesResponse,
	Error
>(
	{
		api: apiCall,
		async
	},
	commonExecutor
);
