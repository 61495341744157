import React from 'react';
import moment from 'moment';
import config from 'services/config';
import { Redirect } from 'react-router';
import { debounce } from 'lodash';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';

interface IdleTimerProps {
	events?: string[];
}

interface IdleTimerState {
	expired: boolean;
}

class IdleTimer extends React.Component<IdleTimerProps, IdleTimerState> {
	timer: number | void;

	static defaultProps = {
		events: ['keydown', 'wheel', 'mousedown']
	};

	constructor(props: IdleTimerProps) {
		super(props);

		this.state = {
			expired: false
		};
	}

	componentDidMount() {
		// Start timeout in case the page is visible to a user
		if (!document.hidden) {
			this.setTimer();
		}

		this.setLastActive();
		this.bindEvents();
	}

	componentWillUnmount() {
		this.stopTimer();
		this.unbindEvents();
	}

	bindEvents = () => {
		// Track certain events to consider user as being active
		(this.props.events || []).forEach(event =>
			document.addEventListener(event, this.handleEvent)
		);

		document.addEventListener('visibilitychange', this.onVisibilityChange);

		/**
		 * These handlers are aimed at detecting if the user's device has woken up after its hibernation
		 */
		window.addEventListener('blur', this.onWindowBlur);
		window.addEventListener('focus', this.onWindowFocus);
	};

	unbindEvents = () => {
		(this.props.events || []).forEach(event =>
			document.removeEventListener(event, this.handleEvent)
		);

		document.removeEventListener('visibilitychange', this.onVisibilityChange);

		window.removeEventListener('blur', this.onWindowBlur);
		window.removeEventListener('focus', this.onWindowFocus);
	};

	stopTimer = () => {
		if (this.timer) {
			this.timer = window.clearTimeout(this.timer);
		}
	};

	setTimer = () => {
		this.stopTimer();
		this.timer = window.setTimeout(
			this.checkIfTimerExpired,
			config.idleTimeout
		);
	};

	/**
	 * Restart the timer, update `lastActive`
	 */
	handleEvent = () => {
		this.setTimer();
		this.setLastActive();
	};

	checkIfTimerExpired = () => {
		if (this.getHasTimeExpiredSinceLastActivityInAnyWindow()) {
			this.stopTimer();
			this.unbindEvents();

			// re-setting global listeners if such exist so that transition isn't prevented
			// addressing such places as:
			// - <ActAsContainer /> of Act As LPA feature
			if (window.unblock) {
				window.unblock();
			}
			this.setState({
				expired: true
			});
		}
	};

	getHasTimeExpiredSinceLastActivityInAnyWindow = () => {
		const lastActive = localStorage.getItem('lastActive');
		const millisecondsSinceLastActivity = moment().diff(
			moment(Number(lastActive)),
			'milliseconds'
		);
		return !lastActive || millisecondsSinceLastActivity >= config.idleTimeout;
	};

	/**
	 * Debounce is used to reduce the callings of the handler while e.g. user is scrolling some UI containers
	 */
	onVisibilityChange = debounce(() => {
		if (document.hidden) {
			this.stopTimer();
		} else {
			this.checkIfTimerExpired();
			this.setTimer();
		}
	}, DEFAULT_DEBOUNCE_DELAY);

	onWindowBlur = (_event: FocusEvent) => {
		this.setLastActive();
	};

	onWindowFocus = (_event: FocusEvent) => {
		this.checkIfTimerExpired();
		/**
		 * timer needs to be restarted after waking up from the hibernation,
		 * since there is no way to detect this particular case – it'll be restarted always
		 */
		if (!this.state.expired) {
			this.setTimer();
		}
	};

	setLastActive = () => {
		localStorage.setItem('lastActive', String(+moment()));
	};

	render() {
		if (this.state.expired) {
			return <Redirect to="/logout" />;
		}
		return <>{this.props.children}</>;
	}
}

export default IdleTimer;
