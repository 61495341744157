import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePortJobOperation } from 'store/portJobs/actions/deletePortJobOperation';
import {
	PortJobOperation,
	PortJobOperationMetadataActionCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { navigateTo } from 'utils';
import { Menu, Tooltip } from 'components/antd';
import { ClickParam } from 'antd/lib/menu';
import { getEntityMenuItems } from 'store/metadata/utils/metadataUtils';
import { getActivePortJob } from 'store/portJobs/selectors';

interface OperationSettingsMenuProps {
	operation: PortJobOperation;
	portCallId: string;
	portJobCode: string;
}

const menuActionItemsMap = {
	[PortJobOperationMetadataActionCode.EDIT]: {
		text: 'Update',
		action: PortJobOperationMetadataActionCode.EDIT
	},
	[PortJobOperationMetadataActionCode.DELETE]: {
		text: 'Delete',
		action: PortJobOperationMetadataActionCode.DELETE
	}
};

function OperationSettingsMenu(props: OperationSettingsMenuProps) {
	const dispatch = useDispatch();
	const { portCallId, portJobCode, operation } = props;
	const activeJob = useSelector(getActivePortJob);

	const HEADER_ID = `operation-${operation.id}-header`;
	const actions = operation?.metadata?.actions || [];
	const menuItems = getEntityMenuItems<PortJobOperationMetadataActionCode>(
		actions,
		menuActionItemsMap
	);
	const hasLinkedCargo = operation.isLinkedOperation ? true : false;
	const isMutipleLinkedLeadNominationJobs = activeJob.isMutipleLinkedLeadNominationJobs
		? true
		: false;

	const onMenuClick = useCallback(
		(e: ClickParam) => {
			switch (e.key) {
				case PortJobOperationMetadataActionCode.EDIT:
					navigateTo(
						`/portcalls/${portCallId}/jobs/${portJobCode}/operations/${operation.portJobOperationId}/edit`
					);
					break;
				case PortJobOperationMetadataActionCode.DELETE:
					dispatch(
						deletePortJobOperation({
							portCallId,
							portJobCode,
							portJobOperationId: operation.portJobOperationId,
							isMutipleLinkedLeadNominationJobs,
							hasLinkedCargo
						})
					);
					break;
				default:
					break;
			}
		},
		[
			portCallId,
			portJobCode,
			operation.portJobOperationId,
			dispatch,
			isMutipleLinkedLeadNominationJobs,
			hasLinkedCargo
		]
	);

	return (
		<Menu onClick={onMenuClick} selectable={false}>
			{menuItems.map(item => (
				<Menu.Item key={item.action} disabled={item.disabled}>
					{item.tooltip ? (
						<Tooltip
							title={item.tooltip}
							placement="left"
							getPopupContainer={() =>
								document.getElementById(HEADER_ID) as HTMLElement
							}
						>
							{item.text}
						</Tooltip>
					) : (
						item.text
					)}
				</Menu.Item>
			))}
		</Menu>
	);
}

export default React.memo(OperationSettingsMenu);
