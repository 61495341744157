import React from 'react';
import { isNil } from 'lodash';
import { Text } from 'components';
import { Severity } from 'app-constants';
import classNames from 'classnames';
import styles from './MetricValueColumn.module.scss';

const MetricSeverityLabel = (props: { severity: Severity; text: string }) => {
	const labelClass = classNames({
		[styles.red]: props.severity === Severity.RED
	});
	return <Text className={labelClass}>{`${props.text}`}</Text>;
};

export default (props: { count?: number; overdue?: number }) => {
	const { count, overdue } = props;
	return (
		<span>
			{!isNil(count) && count > 0 && (
				<MetricSeverityLabel severity={Severity.NORMAL} text={`${count} `} />
			)}
			{!isNil(overdue) && overdue > 0 && (
				<MetricSeverityLabel severity={Severity.RED} text={`(${overdue})`} />
			)}
		</span>
	);
};
