import actionCreator from '../savingsActionCreator';
import {
	RetrieveSavingsRequest,
	RetrieveTimeSavingsResponse
} from 'services/api/savings/savingsServiceTypes';

const RETRIEVE_TIME_SAVINGS = 'RETRIEVE_TIME_SAVINGS';
export const retrieveTimeSavingsAsync = actionCreator.async<
	RetrieveSavingsRequest,
	RetrieveTimeSavingsResponse,
	Error
>(RETRIEVE_TIME_SAVINGS);

export const retrieveTimeSavings = actionCreator<RetrieveSavingsRequest>(
	RETRIEVE_TIME_SAVINGS
);
