import { UserType } from 'services/api/users/userServiceTypes';
import { flatten } from 'lodash';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { getUserGroupType, getUserType } from 'store/auth/selectors/user';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';

export const getUserBelongsToGroupType = createSelector(
	getUserGroupType,
	(
		_: AppState,
		validUserGroupTypes: GroupTypeCodes[] | GroupTypeCodes | undefined
	) => validUserGroupTypes,
	(userGroupType, validUserGroupTypes) =>
		!!validUserGroupTypes &&
		Boolean(flatten([validUserGroupTypes]).find(g => g === userGroupType))
);

export const getUserBelongsToUserType = createSelector(
	getUserType,
	(_: AppState, validUserTypes: UserType[] | UserType | undefined) =>
		validUserTypes,
	(currentUserType, validUserTypes) =>
		!!validUserTypes &&
		Boolean(flatten([validUserTypes]).find(g => g === currentUserType))
);
