import { Success } from 'typescript-fsa';
import { DocumentsState } from 'store/documents/documentsState';
import {
	RetrieveRelativeDocumentConfigResponse,
	RetrieveDocumentConfigParams
} from 'services/api/documents/documentsServiceTypes';

/**
 * Getting Relative Config
 */

/**
 * Relative it's done
 */
export function onRetrieveRelativeConfigDone(
	state: DocumentsState,
	action: Success<
		RetrieveDocumentConfigParams,
		RetrieveRelativeDocumentConfigResponse
	>
): DocumentsState {
	return {
		...state,
		config: {
			...state.config,
			relative: action.result
		}
	};
}
