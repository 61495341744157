import * as React from 'react';
import { Button, Modal, Icon, Tooltip, MaybeTooltip } from 'components/antd';
import { values, isEmpty } from 'lodash';
import CustodyRoleSelect from '../../CustodyRoleSelect/CustodyRoleSelect';
import { CustodyAgentType } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import {
	AppointDataSelectedVendor,
	AppointDataService
} from 'services/api/portJobs/portJobsServiceTypes';
import { retrieveCompany } from 'store/companies';
import {
	getPortJobServiceVendorsWithManyAvailableVendors,
	getIsActivePortJobCancelled,
	getCurrentMainPrincipalId
} from 'store/portJobs/portJobsSelectors';
import appointStyles from './AppointToPortJobModal.module.scss';
import { connect } from 'react-redux';
import { closeModal, openModal } from 'store/modals/actions';
import {
	appointToPortJob,
	retrieveAppointData,
	retrieveExtendedMessagingData
} from 'store/portJobs/actions';
import { CommonActionModalProps } from '../ActionModalTypes';
import { isModalVisible } from 'store/modals/selectors';
import { getHasActivePortCallDuplicates } from 'store/portcalls/selectors';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components';
import classNames from 'classnames';
import {
	getIsPortJobControllingAgentDraft,
	getIsPortJobPerformingAgentDraft
} from 'store/portJobs/selectors/agentsSelectors';
import { AppState } from 'store-types';
import {
	isJobStatusIsPDARequested,
	isRequestPDAFetching,
	getPortJobAppointDataFetchStatus
} from 'store/portJobs/selectors';
import DropdownButton from 'components/DropdownButton/DropdownButton';
import { notify } from 'store/notifications/actions';
import { EXTENDED_MAIL_TYPE } from '../../PortJobConstants';
import VendorSelectionModalContainer from '../VendorSelectionModal/VendorSelectionModalContainer';
import { FetchStatus } from 'services/api/apiTypes';
import AppointmentMailModal from './ExtendedMessagingModals/AppointmentMailModal';
import RequestPDAMailModal from './ExtendedMessagingModals/RequestPDAMailModal';

export const EXTENDED_MESSAGING_MODAL = 'extended-messaging-modal';
export const VENDOR_SELECTION_MODAL = 'vendor-selection-modal';
export const REQUEST_PDA_EXTENDED_MAIL_MODAL =
	'request-pda-extended-mail-modal';

interface AppointToPortJobModalProps extends CommonActionModalProps {
	// from mapStateToProps
	isExtendedMessagingModalVisible: boolean;
	isVendorSelectionModalVisible: boolean;
	isAppointmentDisabled: boolean;
	hasPortCallDuplicates: boolean;
	availableServices: AppointDataService[];
	disabled: boolean;
	isPDARequested: boolean;
	hubPrincipleId: string | null;
	isPDARequestLoading: boolean;
	fetchStatus: FetchStatus;
	isRequestPDAMailModalVisible: boolean;
	// from mapDispatchToProps
	appointToPortJob: typeof appointToPortJob;
	closeModal: typeof closeModal;
	openModal: typeof openModal;
	retrieveCompany: typeof retrieveCompany;
	notifyError: typeof notify.error;
	retrievePortJobAppointData: typeof retrieveAppointData;
	retrieveExtendedMessagingData: typeof retrieveExtendedMessagingData;
}

interface AppointToPortJobButtonProps {
	disabled: boolean;
	onAppoint?: () => void;
}

interface AppointToPortJobModalState {
	custodyAgentType?: CustodyAgentType;
	serviceVendorsAppointed: AppointDataSelectedVendor[];
}

export const AppointToPortJobButton: React.FC<AppointToPortJobButtonProps> = (
	props: AppointToPortJobButtonProps
) => (
	<Button type="primary" onClick={props.onAppoint} disabled={props.disabled}>
		Appoint agent
	</Button>
);

export const SubmitVendorSelectionButton: React.FC<AppointToPortJobButtonProps> = (
	props: AppointToPortJobButtonProps
) => (
	<Button type="primary" onClick={props.onAppoint} disabled={props.disabled}>
		Submit
	</Button>
);

export class AppointToPortJobModal extends React.PureComponent<
	AppointToPortJobModalProps,
	AppointToPortJobModalState
> {
	constructor(props: AppointToPortJobModalProps) {
		super(props);

		this.state = {
			serviceVendorsAppointed: []
		};
	}

	componentWillUnmount() {
		this.closeModal(EXTENDED_MESSAGING_MODAL);
		this.closeModal(VENDOR_SELECTION_MODAL);
	}

	openModal = () => {
		this.props.retrievePortJobAppointData();
	};

	closeModal = (modalName: string) =>
		this.props.closeModal({ name: modalName });

	handleSelectCustodyAgentType = (custodyAgentType: CustodyAgentType) => {
		this.setState({ custodyAgentType });
	};

	onServiceVendorSelect = (newServiceVendor: AppointDataSelectedVendor) => {
		const serviceVendorsAppointed = {
			...this.state.serviceVendorsAppointed,
			[newServiceVendor.serviceCode]: newServiceVendor
		};

		this.setState({
			serviceVendorsAppointed
		});
	};

	handleAppoint = () => {
		const { custodyAgentType, serviceVendorsAppointed } = this.state;
		const { portCallId, jobCode, modalId } = this.props;
		if (custodyAgentType) {
			const keyForType = Object.keys(CustodyAgentType).find(
				key => CustodyAgentType[key] === custodyAgentType
			) as keyof typeof CustodyAgentType;
			this.props.appointToPortJob({
				code: keyForType,
				name: custodyAgentType,
				portCallId,
				jobCode,
				modalId,
				serviceVendors: values(serviceVendorsAppointed)
			});
		}
	};

	handleVendorSelection = () => {
		const { serviceVendorsAppointed } = this.state;
		this.props.retrieveExtendedMessagingData({
			mailType: EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL,
			serviceVendors: values(serviceVendorsAppointed)
		});
		this.closeModal(VENDOR_SELECTION_MODAL);
		this.props.openModal({ name: EXTENDED_MESSAGING_MODAL });
	};

	closeAppointmentMailModal = () => {
		this.closeModal(EXTENDED_MESSAGING_MODAL);
		this.handleAppoint();
	};

	resetVendorSelection = () => {
		this.setState({
			serviceVendorsAppointed: []
		});
		this.closeModal(EXTENDED_MESSAGING_MODAL);
	};

	handleRequestPDAClick = () => {
		this.props.openModal({ name: REQUEST_PDA_EXTENDED_MAIL_MODAL });
	};

	closeRequestPDAMailModal = () => {
		this.closeModal(REQUEST_PDA_EXTENDED_MAIL_MODAL);
	};

	isAppointmentBtnDisabled = () => {
		const { availableServices } = this.props;
		const { serviceVendorsAppointed } = this.state;
		return (
			!isEmpty(availableServices) &&
			values(serviceVendorsAppointed).length !== availableServices.length
		);
	};

	render() {
		const {
			// isModalVisible: isVisible,
			isAppointmentDisabled,
			hasPortCallDuplicates,
			isPDARequested,
			disabled,
			isExtendedMessagingModalVisible,
			isPDARequestLoading,
			isVendorSelectionModalVisible,
			fetchStatus,
			isRequestPDAMailModalVisible
		} = this.props;
		const { custodyAgentType } = this.state;
		const isButtonDisabled = disabled || !custodyAgentType;
		const isCustodyRoleSelectDisabled = disabled || isAppointmentDisabled;

		return (
			<>
				<Permission permissionCode={PermissionCode.APPOINT_AGENT}>
					{hasPermission =>
						hasPermission && (
							<>
								{hasPortCallDuplicates && (
									<Tooltip
										title="Please resolve potential problem with duplicate port calls before you can appoint the agent"
										placement="bottom"
									>
										<Icon type="info-circle" color="primary" valign="middle" />
									</Tooltip>
								)}
								<CustodyRoleSelect
									onSelect={this.handleSelectCustodyAgentType}
									disabled={isCustodyRoleSelectDisabled}
								/>
								<MaybeTooltip
									placement="left"
									show={isAppointmentDisabled && !hasPortCallDuplicates}
									title={`The draft Agent can't be appointed to a Job. Please wait until the draft is converted or choose an existing Agent.`}
								>
									<span
										className={classNames({
											[appointStyles.buttonWrapper]: isAppointmentDisabled
										})}
									>
										<DropdownButton
											onClick={this.openModal}
											disabled={isButtonDisabled || isAppointmentDisabled}
											iconDisabled={disabled}
											menuProps={{
												onClick: this.handleRequestPDAClick
											}}
											menuItems={[
												{
													key: 'requestPDA',
													label: 'Request PDA',
													disabled: isPDARequested || isPDARequestLoading,
													tooltipTitle:
														isPDARequested &&
														'You have already sent PDA request to performing agent',
													tooltipPlacement: 'leftTop'
												}
											]}
											loading={fetchStatus === FetchStatus.PENDING}
										>
											Appoint Agent
										</DropdownButton>
									</span>
								</MaybeTooltip>
							</>
						)
					}
				</Permission>
				<Modal
					destroyOnClose
					title="Job details will be sent to"
					visible={isVendorSelectionModalVisible}
					onCancel={() => {
						this.setState({
							serviceVendorsAppointed: []
						});
						this.closeModal(VENDOR_SELECTION_MODAL);
					}}
					okButton={
						<SubmitVendorSelectionButton
							key="appoint"
							disabled={this.isAppointmentBtnDisabled()}
							onAppoint={this.handleVendorSelection}
						/>
					}
					width={640}
				>
					<VendorSelectionModalContainer
						onServiceVendorSelect={this.onServiceVendorSelect}
					/>
				</Modal>
				<AppointmentMailModal
					isVisible={isExtendedMessagingModalVisible}
					closeAppointmentMailModal={this.closeAppointmentMailModal}
					resetVendorSelection={this.resetVendorSelection}
				/>
				<RequestPDAMailModal
					isVisible={isRequestPDAMailModalVisible}
					closeRequestPDAMailModal={this.closeRequestPDAMailModal}
				/>
			</>
		);
	}
}

export default connect(
	(state: AppState) => {
		const hasPortCallDuplicates = getHasActivePortCallDuplicates(state);
		return {
			isExtendedMessagingModalVisible: isModalVisible(
				state,
				EXTENDED_MESSAGING_MODAL
			),
			isVendorSelectionModalVisible: isModalVisible(
				state,
				VENDOR_SELECTION_MODAL
			),
			isRequestPDAMailModalVisible: isModalVisible(
				state,
				REQUEST_PDA_EXTENDED_MAIL_MODAL
			),
			availableServices: getPortJobServiceVendorsWithManyAvailableVendors(
				state
			),
			isAppointmentDisabled:
				getIsPortJobPerformingAgentDraft(state) ||
				getIsPortJobControllingAgentDraft(state) ||
				hasPortCallDuplicates,
			hasPortCallDuplicates,
			disabled: getIsActivePortJobCancelled(state),
			isPDARequested: isJobStatusIsPDARequested(state),
			hubPrincipleId: getCurrentMainPrincipalId(state),
			isPDARequestLoading: isRequestPDAFetching(state),
			fetchStatus: getPortJobAppointDataFetchStatus(state)
		};
	},
	{
		appointToPortJob,
		closeModal,
		openModal,
		retrieveCompany,
		notifyError: notify.error,
		retrievePortJobAppointData: retrieveAppointData,
		retrieveExtendedMessagingData
	}
)(AppointToPortJobModal);
