import { User, InviteUserRequest } from 'services/api/users/userServiceTypes';
import { LabeledValueItem } from 'app-types';

type ModalValue = User | undefined;
export type InviteUserFormData = Omit<InviteUserRequest, 'companyId'> & {
	company: LabeledValueItem;
	id?: string;
};
export type UsersModalsOnSubmit<S = InviteUserFormData> = (values: S) => void;
export interface UsersModalsPropsBase<S = ModalValue, T = ModalValue> {
	modalValue?: T;
	submitting: boolean;
	onSubmit: UsersModalsOnSubmit<S>;
	onCancel: () => void;
}

export type InviteUserModalProps = UsersModalsPropsBase<InviteUserFormData>;
export type ToggleUserStatusModalProps = UsersModalsPropsBase;

export enum UsersModalsName {
	INVITE = 'invite',
	TOGGLE_STATUS = 'toggle-status'
}

export const INVITE_USER_FORM = 'inviteUser';

export const CANCEL_INVITE_MODAL = 'cancelInviteModal';
