import actionCreator from 'store/finance/financeActionCreator';
import { ReopenDaRequest } from 'services/api/finance/financeServiceTypes';
const ACTION_NAME = 'REOPEN_SET';

export const reopenDaSet = actionCreator<string>(ACTION_NAME);

export const reopenDaSetAsync = actionCreator.async<
	ReopenDaRequest,
	undefined,
	Error
>(ACTION_NAME);
