import * as React from 'react';
import { Text } from 'components';
import { Button } from 'components/antd';
import { copyFromPreviousPage } from 'store/form/operationsContextualization/actions';
import { connect } from 'react-redux';

interface CopyPageButtonProps {
	// from mapDispatchToProps
	copyFromPreviousPage: typeof copyFromPreviousPage;
}

class CopyPageButton extends React.Component<CopyPageButtonProps> {
	private onCopyPageClick = () => {
		this.props.copyFromPreviousPage(undefined);
	};

	public render() {
		return (
			<>
				<Button
					type="primary"
					transparent
					icon="copy"
					onClick={this.onCopyPageClick}
				>
					Copy from previous Page
				</Button>
				<br />
				<Text size="sm" color="silent">
					If this page is a continuation of the previous page, use this option
					to copy the attribution information. All pages with the exact same
					attribution information will get assembled into a single document.
				</Text>
			</>
		);
	}
}

export default connect(null, {
	copyFromPreviousPage
})(CopyPageButton);
