import { retrievePortCallMetadataAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse
} from 'services/api/portCall/portCallServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse,
	Error
>({
	api: Api.PortCall.retrievePortCallMetadata,
	async
});
