import { WizardFormState } from '../wizardFormState';
import { WizardFormSetOriginUrl } from '../actions/setOriginUrl';
export const onSetOriginUrl = (
	state: WizardFormState,
	action: WizardFormSetOriginUrl
) => {
	const { originUrl, name } = action;
	const formName: string = name ? name : state.activeWizardFormId;
	const form = {
		...state.forms[formName],
		originUrl
	};
	return {
		...state,
		forms: {
			...state.forms,
			[formName]: form
		}
	};
};
