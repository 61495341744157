import { call } from 'redux-saga/effects';

import Api from 'services/api';

import { addGroupAsync, AddGroupRequestPayload } from '../actions/addGroup';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { navigateTo } from 'utils';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	AddGroupRequestPayload,
	Group,
	Error,
	AddGroupRequestPayload['requestParams']
>({
	api: Api.Groups.addGroup,
	async: addGroupAsync,
	*getApiParams(params) {
		return params.requestParams;
	},
	*onSuccess({ result }) {
		yield call(navigateTo, `/groups/${result.id}`);
	}
});
