import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { ResultCollection } from '../apiTypes';
import { PortCallEventStatus } from 'services/api/portCall/portCallServiceTypes';
import { Metadata, EntityCode, MetadataAction } from 'app-types';
import { POIDraft } from 'store/drafts/draftsState';
import { UserType } from 'services/api/users/userServiceTypes';
import { PortJobCancelReason } from 'services/api/portJobs/portJobsServiceTypes';

export enum VPMetadataActionCode {
	REFRESH_EVENTS = 'refreshEvents',
	TRACK = 'onTrack',
	COMMIT = 'commit',
	EDIT = 'edit'
}

export type VPMetadataAction = MetadataAction<VPMetadataActionCode>;

export interface RetrieveVesselProgramme {
	id: string;
	rotationSteps: RetrieveRotationStep[];
	poiDrafts: POIDraft[];
	unallocatedUnits: RetrieveOperationUnit[];
	updatedOn: string;
	updatedOnPlt: string;
	currentAgentRole: CustodyAgentType;
	custodyAgents: CustodyAgent[];
	metadata: Metadata<VPMetadataActionCode>;
	concurrencyToken: number;
	// only acknowledged ones
	revisions: Revision[];
	// just all revisions
	allRevisions: Revision[];
	lastRevision: Revision;
	isReady: boolean;
}

interface CreatedByResponse {
	email: string;
	firstName: string;
	fullName: string;
	id: string;
	lastName: string;
	userType: UserType;
}

export enum SeverityColor {
	RED = '100',
	AMBER = '50',
	EMPTY = '0'
}

interface RevisionAlert {
	description: string;
	id: string;
	severity: number;
	severityColor: SeverityColor;
	value: number;
	vesselProgrammeAlertRuleCode: string;
}

export interface Revision {
	comment: string;
	createdBy: string;
	createdByResponse: CreatedByResponse;
	createdByRole: CustodyAgentType;
	createdByCompanyName: string;
	createdOn: string;
	createdOnUtc: string;
	createdOnPlt: string;
	id: string;
	number: number;
	revisionAlerts: RevisionAlert[];
	performingAgentCompanyId: string;
}

export interface UpdatedByInfo {
	updatedByName: string;
	updatedByCompanyName: string;
}

export interface RetrieveVesselProgrammeProcess
	extends RetrieveVesselProgramme {
	updatedByInfo?: UpdatedByInfo;
}

export type RetrieveVesselProgrammeProcessResponse = RetrieveVesselProgrammeProcess | null;

export interface RetrieveVesselProgrammeRequest {
	portCallId: string;
	isNewVP?: boolean;
	withTimestamp?: boolean;
}

export enum CustodyAgentType {
	INBOUND = 'Inbound',
	OUTBOUND = 'Outbound',
	FULL = 'Full',
	NONE = 'None'
}

export interface CustodyAgent {
	agentRole: CustodyAgentType;
	performingAgentCompany: {
		id: string;
		name: string;
		email: string;
		cellPhone: string;
	};
}

export interface RetrieveBerthsRequest {
	id: string;
	terminal: string;
}

export interface RotationEntity {
	id: string;
	name: string;
}

interface OperationEntity {
	id: string;
	name: string;
}

export enum RotationStepTypes {
	ANCHORAGE = 'Anchorage',
	ARRIVAL_POINT = 'ArrivalPoint',
	BERTH = 'Berth',
	BUOY = 'Buoy',
	CANAL = 'Canal',
	TERMINAL = 'Terminal',
	INBOUND_PORT_VOYAGE = 'InboundPortVoyage',
	OUTBOUND_PORT_VOYAGE = 'OutboundPortVoyage',
	POI = 'POI',
	SAILING_POINT = 'SailingPoint',
	SHIFTING = 'Shifting',
	MOVEMENT = 'Movement'
}

export enum RotationStepMainType {
	ARRIVAL_POINT = RotationStepTypes.ARRIVAL_POINT,
	POI = RotationStepTypes.POI,
	MOVEMENT = RotationStepTypes.MOVEMENT,
	SAILING_POINT = RotationStepTypes.SAILING_POINT
}

export enum RotationStepPOIType {
	BERTH = RotationStepTypes.BERTH,
	ANCHORAGE = RotationStepTypes.ANCHORAGE,
	BUOY = RotationStepTypes.BUOY,
	CANAL = RotationStepTypes.CANAL,
	TERMINAL = RotationStepTypes.TERMINAL
}

export enum RotationStepMovementType {
	INBOUND_PORT_VOYAGE = RotationStepTypes.INBOUND_PORT_VOYAGE,
	OUTBOUND_PORT_VOYAGE = RotationStepTypes.OUTBOUND_PORT_VOYAGE,
	SHIFTING = RotationStepTypes.SHIFTING
}

type CustodyTransferBaseType = 'Before' | 'During' | 'After';
export type CustodyTransferType = CustodyTransferBaseType | null;

export interface Terminal {
	createdOn: string;
	id: string;
	name: string;
	isDeleted: boolean;
	updatedOn: string;
}

export interface Berth {
	createdOn: string;
	id: string;
	name: string;
	isDeleted: boolean;
	updatedOn: string;
}

export interface RotationStep {
	id: string;
	rotationStepType: RotationStepMainType;
	poiType?: RotationStepPOIType;
	name?: string;
	canEdit: boolean;
	acceptedUnitTypes?: string[];
	terminal?: RotationEntity | null;
	movementType?: RotationStepMovementType;
	anchorage?: RotationEntity;
	berth?: RotationEntity | null;
	buoy?: RotationEntity | null;
	canal?: RotationEntity | null;
	custodyTransfer?: CustodyTransferType;
	isDefault: boolean;
	poiId?: string;
}

export interface RetrieveRotationStep extends RotationStep {
	events: RotationStepEvent[];
	units?: RetrieveOperationUnit[];
}

export enum RotationStepEventParentType {
	ROTATION_STEP = 'rotationStep',
	OPERATION_UNIT = 'operationUnit'
}

export interface RotationStepEvent {
	id: string;
	// `trackValidationId`
	// Field added by FE and maintained by BE when POI isn't yet saved to DB (doesn't have its own ID)
	// Used to track validation response.
	// Not used by already submitted POIs
	trackValidationId?: string;
	status: PortCallEventStatus;
	name: string;
	comment?: string;
	code: string;
	estimatedDatePlt: string | null;
	estimatedDateUtc: string | null;
	realDatePlt: string | null;
}

export interface Job {
	code: string;
	agentRole: CustodyAgentType;
}

type OperationUnitType = 'Cargo';
export interface OperationUnit {
	chartererCompany: OperationEntity;
	shipperCompany: OperationEntity;
	mainCommodity: EntityCode;
	commodity: OperationEntity;
	commodityQuantity: number;
	commodityUnitOfMeasurementCode: string;
	cargoReferenceNumber: string;
	id: string;
	unitType: OperationUnitType;
	operationId: string;
	operationName: string;
	operationTypeCode: OperationTypeCode;
	operationTypeName: string;
	portJobCode: string;
	parentOperationUnitId: string | null;
	isTransshipment: boolean | null;
	transshipmentVesselName: string | null;
	terms: string;
	splitIndex?: number;
	job: Job;
	isCancelled: boolean;
	cancelReason?: PortJobCancelReason;
	cancelReasonName?: string;
}

export interface RetrieveOperationUnit extends OperationUnit {
	events: RotationStepEvent[];
}

export interface SaveVesselProgrammeResponse {
	status: string;
}

export interface RetrieveTerminalsResponse extends ResultCollection<Terminal> {}
export interface RetrieveBerthsResponse extends ResultCollection<Berth> {}
export interface RetrieveEventsResponse
	extends ResultCollection<RotationStepEvent> {}

export interface GetPOICostAssociationResponse {
	costAssociated: boolean;
}

export interface AddPOIRequest {
	portcallId: string;
	poiType: string;
	previous: {
		type: RotationStepMainType;
		id: string;
	};
	next: {
		type: RotationStepMainType;
		id: string;
	};
}

export type AddPOIResponse = RetrieveRotationStep[];

export interface AcknowledgeVPRequest {
	portCallId: string;
	revisionId: string;
}

export interface AcknowledgeVPWithCommentRequest extends AcknowledgeVPRequest {
	comment: string;
}

export interface RetrieveEventsRequest {
	poiType: string;
	operationType: string;
	commodityId?: string;
	mainCommodity: string;
}

export interface RetrieveEventsParams {
	stepPosition: number;
	draggedUnitId: string;
}

export interface DownloadVPRevisionRequest {
	id: string;
	portCallId: string;
}

export interface UpdateVPFromFinanceRequest {
	portCallId: string;
	rotationStepName: RotationStepMainType;
	payload: RetrieveRotationStep;
}
