import { DocumentsState, ProcessingStatus } from '../documentsState';
import { FetchStatus } from 'services/api/apiTypes';
import {
	RetrieveDocumentResponse,
	ValidationStatus,
	SplitStatus
} from 'services/api/documents/documentsServiceTypes';

export const STATUS_MESSAGE = {
	[ValidationStatus.QUARANTINE]: 'File upload failed - virus detected',
	[SplitStatus.FAILED]: 'File upload failed. Please try again'
};

export const getStatus = (document?: RetrieveDocumentResponse) => {
	if (!document) {
		return ProcessingStatus.UPLOAD;
	}
	const {
		status,
		split: { status: splitStatus }
	} = document;

	if (status !== ValidationStatus.SAFE) {
		return ProcessingStatus.SCAN; // document until being safe is at scan status
	}

	// Last status must always be defined

	if (splitStatus !== SplitStatus.NONE) {
		return ProcessingStatus.SPLIT; // split starts as last so if split status is not none - this is this status
	}

	return ProcessingStatus.PROCESSING;
};

export const getStatusDescription = (document: RetrieveDocumentResponse) => {
	const {
		status,
		split: { status: splitStatus }
	} = document;
	return STATUS_MESSAGE[status] || STATUS_MESSAGE[splitStatus];
};

export const getDocumentProcessingFetchStatus = (
	state: DocumentsState,
	document: RetrieveDocumentResponse
) => {
	const {
		status,
		split: { status: splitStatus },
		processed
	} = document;
	let fetchStatus = FetchStatus.IDLE;

	// such situation CANNOT happen. in order to get rid of such situation that file
	// may be null, is to reorganize `upload` state
	if (!state.upload.file) {
		return FetchStatus.FAILURE;
	}

	if (
		status === ValidationStatus.SAFE &&
		((splitStatus === SplitStatus.NONE && processed) ||
			splitStatus === SplitStatus.COMPLETED)
	) {
		fetchStatus = FetchStatus.SUCCESS;
	} else if (
		status === ValidationStatus.QUARANTINE ||
		splitStatus === SplitStatus.FAILED
	) {
		fetchStatus = FetchStatus.FAILURE;
	} else {
		fetchStatus = FetchStatus.PENDING;
	}

	return fetchStatus;
};

export const onUpdateDocumentProcessing = (
	state: DocumentsState,
	document: RetrieveDocumentResponse
): DocumentsState => ({
	...state,
	upload: {
		...state.upload,
		status: getStatus(document),
		statusTerminatedDescription: getStatusDescription(document),
		fetchStatus: getDocumentProcessingFetchStatus(state, document),
		/**
		 * Need to enable the close button if there is a failure in the process.
		 */
		hideClose:
			state.upload.hideClose === true &&
			getDocumentProcessingFetchStatus(state, document) === FetchStatus.FAILURE
				? false
				: state.upload.hideClose
	}
});
