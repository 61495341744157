import moment from 'moment-timezone';

export const TimeSettings = {
	Hours12: 'Hours12',
	Hours24: 'Hours24'
};

export const DateSettings = {
	MonthFirst: 'MonthFirst',
	DayFirst: 'DayFirst'
};

export const DateSettingsLabels = {
	[DateSettings.DayFirst]: 'DD/MM/YYYY',
	[DateSettings.MonthFirst]: 'MM/DD/YYYY'
};

export const TimeSettingsLabels = {
	[TimeSettings.Hours12]: 'AM/PM',
	[TimeSettings.Hours24]: '24h'
};

export const DefaultTimezone = 'Europe/London';
export const BrowserTimezone = moment.tz.guess();

export const USER_REGISTRATION_FORM_NAME = 'userRegistration';
export const USER_REGISTRATION_WIZARD_PAGES: {
	[page: string]: { number: number; name: string };
} = {
	TERMS_OF_SERVICE: {
		number: 1,
		name: 'Terms Of Service'
	},
	GENERAL_INFORMATION: {
		number: 2,
		name: 'General Information'
	},
	SETTINGS: {
		number: 3,
		name: 'Settings'
	},
	REGISTRATION_FINISH: {
		number: 4,
		name: 'Registration Finish'
	}
};

/**
 * Common form options
 * @type {Object}
 */
export const userRegistrationFormSettings = {
	// A unique name for the form
	form: USER_REGISTRATION_FORM_NAME,
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true
};
