// eslint-disable no-var-requires

import { AxiosInstance } from 'axios';

let client: AxiosInstance;

if (process.env.NODE_ENV !== 'test') {
	client = require('./client').default;
} else {
	client = require('axios').default;
}

export default client;
