import actionCreator from '../portJobsActionCreator';
import {
	ConfirmPortJobPayload,
	ConfirmPortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'CONFIRM_JOB';

export const confirmPortJobAsync = actionCreator.async<
	ConfirmPortJobPayload,
	ConfirmPortJobResponse,
	Error
>(ACTION_NAME, {
	failed: {
		// react-to-changes
		omit: [412]
	},
	templateParams: {
		entityName: 'Port Job'
	}
});

export const confirmPortJob = actionCreator<ConfirmPortJobPayload>(ACTION_NAME);
