import React from 'react';
import { Dispatch, Action } from 'redux';
import { Row, Col, Icon } from 'components/antd';
import styles from '../CreatePortJobForm.module.scss';
import PerformingAgentField from './PerformingAgentField';
import ControllingAgentField from './ControllingAgentField';
import { AgentProps, OptionalAgentValue } from './types';
import { connect } from 'react-redux';
import {
	FORM,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	getPortJobFormPerforminAgent,
	getPortJobFormControllingAgent
} from '../../../page2Selectors';
import { LabeledValue } from 'app-types';
import { change } from 'redux-form';
import { AppState } from 'store-types';
import { getIsCurrentUserHub } from 'store/auth/selectors';
import { isEmpty } from 'lodash';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';
import { getSwapAgentsAccessInfo } from 'store/portJobs/selectors/editPortJobSelectors';
import classNames from 'classnames';

const swap = (dispatch: Dispatch<Action>) => (
	performingAgent: LabeledValue,
	controllingAgent: LabeledValue
) => {
	dispatch(
		change(FORM.portJob, FormFieldName.PERFORMING_AGENT, controllingAgent)
	);
	dispatch(
		change(FORM.portJob, FormFieldName.CONTROLLING_AGENT, performingAgent)
	);
};

type AgentsProps = AgentProps & {
	isInEditMode: boolean;
	performingAgent: OptionalAgentValue;
	controllingAgent: OptionalAgentValue;
	isHub: boolean;
	IsSwapDisabledForNominatioOrCO: boolean;
	swapAgentsAccessInfo: {
		status: boolean;
		messageInfo: string;
	};
	doSwap: (a: LabeledValue, b: LabeledValue) => void;
};

class Agents extends React.Component<AgentsProps> {
	onClickHandler = () => {
		const {
			performingAgent,
			controllingAgent,
			swapAgentsAccessInfo
		} = this.props;
		if (swapAgentsAccessInfo.status) {
			this.props.doSwap(
				performingAgent as LabeledValue,
				controllingAgent as LabeledValue
			);
		}
	};

	isSwapPossible = () => {
		const {
			isInEditMode,
			performingAgent,
			controllingAgent,
			isHub
		} = this.props;

		return (
			isInEditMode &&
			!isEmpty(performingAgent) &&
			!isEmpty(controllingAgent) &&
			isHub
		);
	};

	render() {
		const {
			disabled,
			search,
			withParentId,
			swapAgentsAccessInfo,
			performingAgent,
			controllingAgent,
			IsSwapDisabledForNominatioOrCO,
			isHub
		} = this.props;

		const isSwapDisabled =
			!isEmpty(performingAgent) &&
			!isEmpty(controllingAgent) &&
			IsSwapDisabledForNominatioOrCO &&
			isHub;

		return (
			<Row>
				<Col sm={6} className={styles.swapContainer}>
					<PerformingAgentField
						disabled={disabled}
						search={search}
						withParentId={withParentId}
						onAddDraftClick={this.props.onAddDraftClick}
					/>
					{isSwapDisabled ? (
						<MaybeTooltip
							show={true}
							title={
								"You can't swap agents since it linked job has different controlling agent"
							}
						>
							<Icon
								className={classNames(styles.swapIcon, styles.disableSwapIcon)}
								type="swap"
								size="lg"
								color="grayBlue"
							/>
						</MaybeTooltip>
					) : (
						this.isSwapPossible() && (
							<MaybeTooltip
								show={!swapAgentsAccessInfo.status}
								title={swapAgentsAccessInfo.messageInfo}
							>
								<Icon
									className={styles.swapIcon}
									type="swap"
									size="lg"
									color="success"
									onClick={this.onClickHandler}
								/>
							</MaybeTooltip>
						)
					)}
				</Col>
				<Col sm={6}>
					<ControllingAgentField
						disabled={disabled}
						search={search}
						withParentId={withParentId}
						onAddDraftClick={this.props.onAddDraftClick}
					/>
				</Col>
			</Row>
		);
	}
}

export default connect(
	(state: AppState) => ({
		performingAgent: getPortJobFormPerforminAgent(state),
		controllingAgent: getPortJobFormControllingAgent(state),
		isHub: getIsCurrentUserHub(state),
		swapAgentsAccessInfo: getSwapAgentsAccessInfo(state)
	}),
	dispatch => ({
		doSwap: swap(dispatch)
	})
)(Agents);
