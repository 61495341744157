import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import {
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveEditCompanyVesselData = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselResponse
>('fetchStatuses', 'retrieve');
