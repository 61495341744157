import { Success, Failure } from 'typescript-fsa';
import {
	RetrieveMessageHistoryRequest,
	RetrieveMessageHistoryResponse
} from 'services/api/messages/messagesServiceTypes';
import { MessagesState } from '../messagesState';
import { getContentHistoryFormatted } from 'sections/ComposeMessage/utils/ComposeMessageUtils';

export const onRetrieveHistoryStart = (
	state: MessagesState,
	params: RetrieveMessageHistoryRequest
) => ({
	...state,
	messagesHistory: {
		...state.messagesHistory,
		[params.messageId]: {
			...state.messagesHistory[params.messageId]
		}
	}
});

export const onRetrieveHistorySuccess = (
	state: MessagesState,
	action: Success<RetrieveMessageHistoryRequest, RetrieveMessageHistoryResponse>
) => {
	const { result } = action;
	return {
		...state,
		messagesHistory: {
			...state.messagesHistory,
			[result.id]: {
				...state.messagesHistory[result.id],
				contentHistory:
					result.contentHistory &&
					getContentHistoryFormatted(result.contentHistory)
			}
		}
	};
};

export const onRetrieveHistoryFailure = (
	state: MessagesState,
	{ error, params }: Failure<RetrieveMessageHistoryRequest, Error>
) => ({
	...state,
	messagesHistory: {
		[params.messageId]: {
			...state.messagesHistory[params.messageId],
			error
		}
	}
});
