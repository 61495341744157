import { AppState } from 'store-types';
import { SystemStatusType } from '../commonTypes';

export const getSystemStatusType = (state: AppState) =>
	state.common.system.status;

export const getIsSystemStatusVerified = (state: AppState) =>
	state.common.system.statusVerified;

export const getIsSystemUnderMaintenance = (state: AppState) =>
	Boolean(getSystemStatusType(state) === SystemStatusType.UNDER_MAINTENANCE);
