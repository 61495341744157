import Api from 'services/api';
import { select, call } from 'redux-saga/effects';
import { addServiceAsync as async } from '../actions';
import {
	AddServiceRequest as request,
	AddServiceResponse as response,
	AddServicePayload
} from 'services/api/finance/financeServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Matomo from 'services/analytics/adapters/Matomo';

const api = Api.Finance.addService;
export default makeTakeLatestWatcher<
	AddServicePayload,
	response,
	Error,
	request
>({
	api,
	async,
	*getApiParams(payload: AddServicePayload) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request = {
			portCallId,
			jobCode,
			payload
		};
		return request;
	},
	*onSuccess(response) {
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Finances.AddService',
			name: `Added finance service ${response.result.id}`,
			value: 1
		});
	}
});
