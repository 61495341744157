import actionCreator from 'store/messages/messagesActionCreator';
import {
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse
} from 'services/api/messages/messagesServiceTypes';

const RETRIEVE_MESSAGE_ATTACHMENT = 'RETRIEVE_MESSAGE_ATTACHMENT';

export const retrieveMessageAttachmentAsync = actionCreator.async<
	RetrieveMessageAttachmentRequest,
	RetrieveMessageAttachmentResponse,
	Error
>(RETRIEVE_MESSAGE_ATTACHMENT);

export const retrieveMessageAttachment = actionCreator<
	RetrieveMessageAttachmentRequest
>(RETRIEVE_MESSAGE_ATTACHMENT);
