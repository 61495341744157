import React from 'react';

import {
	ScrollableLayout,
	PageHeader,
	PageFooter,
	Gap,
	Flex
} from 'components';
import { AppNotifications } from 'containers';
import BankAccountDetails from 'sections/BankAccounts/BankAccountDetails/BankAccountDetails';
import { RouteComponentProps } from 'react-router';
import { BankAccountDetailsPageRouteParams } from './BankAccountTypes';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { connect } from 'react-redux';
import { BreadcrumbItem } from 'store/navigation';
import {
	getFinanceBankAccountsBreadcrumbs,
	getIsBankAccountsDataLoading
} from 'store/financeBankAccounts/selectors/financeBankAccountsSelectors';
import JobHeaderSmall from '../FinancePartials/JobHeaderSmall';
import { Collapse, Button } from 'components/antd';
import { Justify } from 'components/types';
import history from 'services/history';
import styles from './BankAccountDetailsPagesRoute.module.scss';
import { DataFetcher } from 'utils/components';
import {
	retrievePortCall,
	setPortCallContext,
	resetPortCall
} from 'store/portcalls/actions';
import { retrievePortJob } from 'store/portJobs/actions';
import { getIsLOBDVisible } from 'store/masterdata/bankAccounts/selectors';
import { AppState } from 'store-types';
import { ExpandPortJobType } from 'services/api/portCalls/portCallsServiceTypes';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';

interface BankAccountDetailsPageRouteProps
	extends RouteComponentProps<BankAccountDetailsPageRouteParams> {
	// store
	breadcrumbs: BreadcrumbItem[];
	loading: boolean;
	isLOBDVisible: boolean;
	// actions
	retrievePortCall: typeof retrievePortCall;
	retrievePortJob: typeof retrievePortJob;
	setPortCallContext: typeof setPortCallContext;
	resetPortCall: typeof resetPortCall;
}

enum Panel {
	BAD = 'bank_acccount_details'
}

class BankAccountDetailsPageRoute extends React.Component<
	BankAccountDetailsPageRouteProps
> {
	componentDidMount() {
		const { portCallId, portJobCode } = this.props.match.params;
		this.props.retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' });
		this.props.retrievePortJob({
			portCallId,
			jobCode: portJobCode,
			expand: ExpandPortJobType.PORT_JOBS_BASIC
		});
		this.props.setPortCallContext({
			activePortCallId: portCallId,
			activePortCallTabId: PortCallTabKey.FINANCE
		});
	}

	componentWillUnmount() {
		this.props.resetPortCall();
	}

	render() {
		const { breadcrumbs, match, loading, isLOBDVisible } = this.props;

		return (
			<ScrollableLayout stretch>
				<AppNotifications.Notification />
				<RouteBreadcrumbs items={breadcrumbs} />
				<PageHeader.NewTab>Bank Account Details</PageHeader.NewTab>
				<ScrollableLayout>
					<DataFetcher isLoading={loading}>
						{() => (
							<>
								<JobHeaderSmall portJobCode={match.params.portJobCode} />
								<Collapse
									type="primary"
									defaultActiveKey={[Panel.BAD]}
									className={styles.collapse}
								>
									<Collapse.Panel key={Panel.BAD} header="Bank Account Details">
										<BankAccountDetails
											bankDetailId={match.params.bankId}
											isLobdVisible={isLOBDVisible}
										/>
									</Collapse.Panel>
								</Collapse>
							</>
						)}
					</DataFetcher>
				</ScrollableLayout>
				<PageFooter borderTop>
					<Flex justify={Justify.END}>
						<Gap top="sm" bottom="sm">
							<Button
								type="primary"
								transparent
								onClick={() => history.goBack()}
							>
								Cancel
							</Button>
						</Gap>
					</Flex>
				</PageFooter>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		breadcrumbs: getFinanceBankAccountsBreadcrumbs(state),
		loading: getIsBankAccountsDataLoading(state),
		isLOBDVisible: getIsLOBDVisible(state)
	}),
	{
		retrievePortCall,
		retrievePortJob,
		setPortCallContext,
		resetPortCall
	}
)(BankAccountDetailsPageRoute);
