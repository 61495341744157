import actionCreator from '../portJobsActionCreator';
import {
	PortJobOperationTypes,
	RetrieveOperationParams
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTJOB_OPERATION';
export const retrievePortJobOperationAsync = actionCreator.async<
	RetrieveOperationParams,
	PortJobOperationTypes,
	Error
>(ACTION_NAME);

export const retrievePortJobOperation = actionCreator<RetrieveOperationParams>(
	ACTION_NAME
);
export const resetPortJobOperation = actionCreator('RESET_PORTJOB_OPERATION');
