import actionCreator from '../actionCreator';
import { RetrieveTerminalsResponse } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

export const retrieveTerminalsAsync = actionCreator.async<
	string,
	RetrieveTerminalsResponse,
	Error
>('RETRIEVE_TERMINALS');

export const retrieveTerminals = actionCreator<string>('RETRIEVE_TERMINALS');
