import { LabeledValueItem } from 'app-types';

export const TIME_SAVINGS_MODAL = 'TimeSavingsModal';
export const TIME_SAVINGS_FORM = 'timeSavingsForm';

export enum TimeSavingsFormFields {
	CATEGORY = 'category',
	SERVICES = 'services',
	SAVING_BY = 'savingsBy',
	TIME_SAVED = 'hours',
	TIME_SAVED_MM = 'minutes',
	COMMENTS = 'comments'
}

export interface TimeSavingsFormData {
	category: LabeledValueItem;
	services: LabeledValueItem[];
	savingsBy: string;
	hours: string;
	minutes: string;
	comments: string;
}

export const TimeSavingsFormLabels = {
	[TimeSavingsFormFields.CATEGORY]: 'Category',
	[TimeSavingsFormFields.SERVICES]: 'Services',
	[TimeSavingsFormFields.SAVING_BY]: 'Saving by',
	[TimeSavingsFormFields.TIME_SAVED]: 'Time',
	[TimeSavingsFormFields.COMMENTS]: 'Comments'
};

export const COST_SAVINGS_MODAL = 'CostSavingsModal';
export const COST_SAVINGS_FORM = 'costSavingsForm';
export const DELETE_SAVINGS_MODAL = 'deleteSavingsModal';

export enum CostSavingsFormFields {
	CATEGORY = 'category',
	SERVICES = 'services',
	SAVING_BY = 'savingsBy',
	AMOUNT_SAVED = 'amountSaved',
	CURRENCY = 'currency',
	COMMENTS = 'comments'
}

export interface CostSavingsFormData {
	category: LabeledValueItem;
	services: LabeledValueItem[];
	savingsBy: string;
	amountSaved: string;
	currency: string;
	comments: string;
}

export const CostSavingsFormLabels = {
	[CostSavingsFormFields.CATEGORY]: 'Category',
	[CostSavingsFormFields.SERVICES]: 'Services',
	[CostSavingsFormFields.SAVING_BY]: 'Saving by',
	[CostSavingsFormFields.AMOUNT_SAVED]: 'Amount Saved',
	[CostSavingsFormFields.CURRENCY]: 'Currency',
	[CostSavingsFormFields.COMMENTS]: 'Comments'
};
