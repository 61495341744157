import { Action } from 'typescript-fsa';
import { call, takeLatest, put } from 'redux-saga/effects';
import { uploadDocumentDone } from '../actions';
import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import { navigateTo } from 'utils';
import Matomo from 'services/analytics/adapters/Matomo';

export default function*() {
	yield takeLatest(uploadDocumentDone.type, function*({
		payload: {
			document: { id },
			isDoneSuccess
		}
	}: Action<UploadDocumentDoneResponse>) {
		if (isDoneSuccess) {
			yield call(navigateTo, `documents/${id}/contextualize`);

			// Reset state
			yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
			yield call(Matomo.trackEvent, {
				category: 'UserEvent',
				action: 'Operations.Documents.Upload',
				name: `Document ${id} has been updated`,
				value: 1
			});
		}
	});
}
