import * as React from 'react';
import Loading, { LoadingProps } from 'components/Loading/Loading';
import { FetchStatus } from 'services/api/apiTypes';
import { isLoading } from './LoadingPlaceholder.func';

type LoadingPlaceholderProps = LoadingProps & {
	fetchStatus: FetchStatus | FetchStatus[];
	children: () => React.ReactNode;
};

const LoadingPlaceholder: React.FC<LoadingPlaceholderProps> = ({
	children,
	fetchStatus,
	show = true,
	...loadingProps
}) => (
	<>
		{isLoading(fetchStatus) ? (
			<Loading show={show} {...loadingProps} />
		) : (
			children()
		)}
	</>
);
export default LoadingPlaceholder;
