import { AuthActAsConfig } from './authState';

export const isLeavingAuthActAsRoute = (
	config: Partial<AuthActAsConfig>,
	pathname: string
) => {
	const urlPrefix = config.urlPrefix || '';

	const isAllowed = (config.allowedUrlPrefixes || []).some(allowedUrlPrefix =>
		pathname.startsWith(allowedUrlPrefix)
	);
	return !isAllowed && !pathname.startsWith(urlPrefix);
};
