import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import {
	DeletePortCallEventRequest,
	PortCallEventStatus
} from 'services/api/portCall/portCallServiceTypes';

export const onSetPortCallEventAsNotHappened = (
	state: PortCallOperationsState,
	{ eventId, comment, portCallId }: DeletePortCallEventRequest
): PortCallOperationsState => {
	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: {
						...state.eventSectionsByPortCallId[portCallId].eventsById[eventId],
						comment: comment || '',
						datePlt: '',
						status: PortCallEventStatus.DID_NOT_HAPPEN
					}
				}
			}
		}
	};
};
