import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FundingRequestsState } from '../financeFundingsState';
import {
	CreateNewFundingRequestRequest as Request,
	CreateNewFundingRequestResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';

export const createFundingRequest = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'createFundingRequest');
