enum CargoLineFormFieldsName {
	REFERENCE_NUMBER = 'referenceNumber',
	PRINCIPAL_VOYAGE_REFERENCE = 'principalVoyageReference',
	MAIN_COMMODITY = 'mainCommodity',
	COMMODITY = 'commodity',
	COMMODITY_QUANTITY = 'commodityQuantity',
	COMMODITY_UNIT_OF_MEASUREMENT_CODE = 'commodityUnitOfMeasurementCode',
	SHIPPER_COMPANY = 'shipperCompany',
	CHARTERER_COMPANY = 'chartererCompany',
	CHARTER_PARTY_DATE_PLT = 'charterPartyDate',
	TERMS = 'terms',

	// Load
	LAYCAN_FROM_PLT = 'laycanFromPlt',
	LAYCAN_TO_PLT = 'laycanToPlt',
	LOADING_FROM_VESSEL = 'loadingFromVessel',
	DISCHARGE_PORT = 'dischargePort',
	DISCHARGE_COUNTRY = 'dischargeCountry',
	TO_ORDERS = 'toOrders',
	// Load

	// Discharge
	LOAD_PORT = 'loadPort',
	RECEIVER_COMPANY = 'receiverCompany',
	DISCHARGING_TO = 'dischargingToVessel'
	// Discharge
}

export default CargoLineFormFieldsName;
