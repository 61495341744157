import * as React from 'react';
import { Gap } from 'components';
import { Row, Input, Col } from 'components/antd';
import { FieldName } from '../BankAccountConstants';

const CountrySpecificFormFields = () => (
	<>
		<Gap top="md" />
		<Row>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.ABA}
					label="ABA Number"
					maxLength={11}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.CPF}
					label="CPF Code"
					maxLength={9}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.INN}
					label="INN Number"
					maxLength={11}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.PURPOSE_REASON}
					label="Purpose Reason"
					maxLength={200}
				/>
			</Col>
		</Row>
		<Row>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.BIK}
					label="BIK Number"
					maxLength={11}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.BSB}
					label="BSB Number"
					maxLength={11}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.AGENCIA_NUMBER}
					label="Agencia Number"
					maxLength={7}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.ROUTING_NUMBER}
					label="Routing Number"
					maxLength={9}
				/>
			</Col>
		</Row>
		<Row>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.CNPJ}
					label="CNPJ Code"
					maxLength={14}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.IFSC}
					label="IFSC Code"
					maxLength={11}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.PURPOSE_CODE}
					label="Purpose Code"
					maxLength={4}
				/>
			</Col>
			<Col xs={3} lg={2}>
				<Input.ReduxFormItem
					name={FieldName.TRANSACTION_PUPROSE}
					label="Transaction Purpose Code"
					maxLength={7}
				/>
			</Col>
		</Row>
	</>
);

export default CountrySpecificFormFields;
