import React, { useMemo } from 'react';
import { Tag, Button, Modal } from 'components/antd';
import { Flex, FormattedTimeExtended, Gap, Text } from 'components';
import { SidePanelProps } from './SidePanel';
import styles from './SidePanel.module.scss';
import classNames from 'classnames';
import { TooltipProps } from 'components/antd/Tooltip/Tooltip';

interface DocumentDetailsRowProps extends SidePanelProps {
	isOriginalPage: boolean;
	updatedOn?: string;
	fileName: string;
	fullFileName: string;
	isDocumentActive: boolean;
	isDocumentDeleting: boolean;
	onDelete: (e: React.MouseEvent<HTMLElement>) => void;
	onDocumentSelect: () => void;
}

const DocumentDetailsRow: React.FC<DocumentDetailsRowProps> = ({
	isOriginalPage,
	updatedOn,
	fileName,
	fullFileName,
	isDocumentActive,
	isDocumentDeleting,
	onDelete,
	onDocumentSelect,
	...props
}: DocumentDetailsRowProps) => {
	const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);

	const pageCount = props.document.pages.length;

	const onDeletingPage = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation();

		if (pageCount > 1 || !isOriginalPage || props.isSupplierInvoice) {
			setDeleteModalVisible(true);
		} else {
			onDelete(e);
		}
	};

	const getDeleteToolTip = useMemo(
		() =>
			props.canDeletePage &&
			isOriginalPage &&
			props.activePageDetails.isAnnotated &&
			!props.isSupplierInvoice
				? ({
						title: 'Delete is not allowed.',
						placement: 'topRight'
				  } as TooltipProps)
				: undefined,
		[
			isOriginalPage,
			props.activePageDetails.isAnnotated,
			props.canDeletePage,
			props.isSupplierInvoice
		]
	);

	return (
		<>
			<Flex
				direction="horizontal"
				className={classNames(styles.sideBar, {
					[styles.sideBarActive]: isDocumentActive
				})}
				onClick={onDocumentSelect}
			>
				<div>
					<Gap all>
						<Gap isBlock bottom="sm">
							<Text weight="semi-bold">
								<span title={fullFileName}>{fileName}</span>
							</Text>
						</Gap>
						<span>
							{isOriginalPage ? 'Uploaded:' : 'Last Update:'} &nbsp;
							{updatedOn && (
								<FormattedTimeExtended value={updatedOn} convertFromUtc />
							)}
						</span>
					</Gap>
				</div>
				<div className={styles.fileInfoWithDeleteBtn}>
					<Tag
						type={isOriginalPage ? 'info' : 'warning'}
						className={styles.tag}
					>
						{isOriginalPage ? 'Original' : 'Updated'}
					</Tag>
					<Button
						className={classNames({
							[styles.hide]:
								(props.isSupplierInvoice && isOriginalPage) ||
								!props.canDeletePage
						})}
						icon="delete-new"
						type="danger"
						transparent
						iconSize="lg"
						onClick={onDeletingPage}
						tooltip={getDeleteToolTip}
						loading={isDocumentDeleting}
						disabled={props.activePageDetails.isAnnotated && isOriginalPage}
					/>
				</div>
			</Flex>
			<Modal
				visible={isDeleteModalVisible}
				closable={false}
				cancelText="Cancel"
				okText="Confirm"
				onOk={e => {
					onDelete(e);
					setDeleteModalVisible(false);
				}}
				onCancel={() => setDeleteModalVisible(false)}
			>
				Are you sure you want to delete the page?
			</Modal>
		</>
	);
};

export default React.memo(DocumentDetailsRow);
