import { LabeledValue } from 'app-types';

export const EDIT_PORTCALL_FORM = 'editPortCall';
export const EditPortCallFormFields = {
	VESSEL: 'vessel',
	PORT: 'port',
	ETA_PLT: 'etaPlt',
	ETS_PLT: 'etsPlt'
};
export interface EditPortCallFormData {
	vessel: LabeledValue;
	port: LabeledValue;
	etaPlt: string;
	etsPlt?: string;
}
