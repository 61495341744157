import actionCreator from '../portCallsActionCreator';
import {
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse
} from 'services/api/portCall/portCallServiceTypes';

const RETRIEVE_ACTION_NAME = 'RETRIEVE_PORTCALL_DUPLICATES';

export const retrievePortCallDuplicatesAsync = actionCreator.async<
	RetrievePortCallDuplicatesRequest,
	RetrievePortCallDuplicatesResponse,
	Error
>(RETRIEVE_ACTION_NAME);

export const retrievePortCallDuplicates = actionCreator<
	RetrievePortCallDuplicatesRequest
>(RETRIEVE_ACTION_NAME);

const RESET_ACTION_NAME = 'RESET_PORTCALL_DUPLICATES';

export const resetPortCallDuplicates = actionCreator<
	RetrievePortCallDuplicatesRequest
>(RESET_ACTION_NAME);
