import actionCreator from '../developerPortalActionCreator';
import { UpdateApiSubscriptionParams as Request } from 'services/api/developerPortal/developerPortalServiceTypes';

const UPDATE_API_SUBSCRIPTION = 'UPDATE_API_SUBSCRIPTION';

export const updateApiSubscriptionAsync = actionCreator.async<
	Request,
	null,
	Error
>(UPDATE_API_SUBSCRIPTION);

export const updateApiSubscription = actionCreator(UPDATE_API_SUBSCRIPTION);
