import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success, Failure } from 'typescript-fsa';
import { GroupsState } from '../groupsState';
import {
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';
import { keyBy } from 'lodash';

const onRetrieveGroupsStarted = (state: GroupsState): GroupsState => ({
	...state,
	error: undefined
});

const onRetrieveGroupsSuccess = (
	state: GroupsState,
	action: Success<RetrieveGroupsRequest, RetrieveGroupsResponse>
): GroupsState => ({
	...state,
	byId: { ...state.byId, ...keyBy(action.result.elements, item => item.id) },
	hasMore: action.params.limit === action.result.elements.length
});

const onRetrieveGroupsFailed = (
	state: GroupsState,
	action: Failure<RetrieveGroupsRequest, Error>
): GroupsState => ({
	...state,
	error: action.error,
	hasMore: false
});

export const onRetrieveGroups = makeFetchStatusReducers<
	GroupsState,
	'fetchStatuses',
	RetrieveGroupsRequest,
	RetrieveGroupsResponse
>('fetchStatuses', 'all', {
	startedReducer: onRetrieveGroupsStarted,
	doneReducer: onRetrieveGroupsSuccess,
	failedReducer: onRetrieveGroupsFailed
});
