import { FinanceState } from '../financeState';
import { UpdateFixedRateExchangeRequest } from 'services/api/finance/financeServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onUpdateFixedRateExchangeRate = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	UpdateFixedRateExchangeRequest,
	null
>('fetchStatuses', 'updateFixedRateExchangeRate');
