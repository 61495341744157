import { without, values, findKey } from 'lodash';
import { FormPageProps } from './FormPages/FormPage';

/**
 * TODO Cover with tests
 */

export const getIncludesSelectedPlaceholders = ({
	fieldValues: { placeholders }
}: FormPageProps) => {
	return values(placeholders).includes(true);
};

const getJobForPlaceholder = (
	id: string,
	{ placeholderOptions }: FormPageProps
) => {
	const placeholder = placeholderOptions.find(item => item.id === id);
	if (!placeholder) {
		return;
	}
	return placeholder.job;
};

const getJobOnPlaceholderChange = (id: string, props: FormPageProps) => {
	if (props.assignedJobOptions.length === 1) {
		// If user has access to only one job within the port call,
		// then that job should be pre-populated in the assigned job field
		return props.assignedJobOptions[0];
	}
	return getJobForPlaceholder(id, props);
};

/**
 * Keep selected jobs updated
 */
export const getJobsForSelectedPlaceholders = (
	props: FormPageProps,
	prevProps: FormPageProps
) => {
	const {
		fieldValues: { jobs, placeholders = {} }
	} = props;
	const prevPlaceholders = prevProps.fieldValues.placeholders;
	if (!prevPlaceholders && !placeholders) {
		return jobs;
	}

	let result = jobs?.length ? jobs : [];

	findKey(placeholders, (checked, id) => {
		const isPreviouslyChecked = prevPlaceholders ? prevPlaceholders[id] : false;

		// Proceed if currently changed placeholder
		if (checked === isPreviouslyChecked) {
			return;
		}

		const job = getJobOnPlaceholderChange(id, props);
		if (!job) {
			return;
		}
		if (checked) {
			if (!result.includes(job.code)) {
				result = result.concat([job.code]);
			}
		} else {
			const includesSelectedPlaceholders = getIncludesSelectedPlaceholders(
				props
			);
			if (!includesSelectedPlaceholders) {
				return (result = []);
			}
			result = without(result, job.code);
		}

		return id;
	});

	return result;
};
