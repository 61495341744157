import { select, put } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	AppointData,
	PortJobBaseRequest
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	retrieveAppointDataAsync,
	retrieveExtendedMessagingData
} from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { isEmpty } from 'lodash';
import { closeModal, openModal } from 'store/modals/actions';
import {
	VENDOR_SELECTION_MODAL,
	EXTENDED_MESSAGING_MODAL
} from 'sections/PortCall/PortJob/ActionModal/AppointToPortJobModal/AppointToPortJobModal';
import { EXTENDED_MAIL_TYPE } from 'sections/PortCall/PortJob/PortJobConstants';

const api = Api.PortJobs.retrieveAppointData;

export default makeTakeEveryWatcher<PortJobBaseRequest, AppointData, Error>({
	api,
	async: retrieveAppointDataAsync,
	*getApiParams() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		return {
			portCallId,
			jobCode
		};
	},
	*onSuccess({ result }) {
		const { serviceVendors } = result;
		if (isEmpty(serviceVendors)) {
			yield put(closeModal(VENDOR_SELECTION_MODAL));
			yield put(openModal(EXTENDED_MESSAGING_MODAL));
			yield put(
				retrieveExtendedMessagingData({
					mailType: EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL
				})
			);
		} else {
			yield put(openModal(VENDOR_SELECTION_MODAL));
		}
	}
});
