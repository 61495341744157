import React, { FC } from 'react';
import { Row, Col, Select, Radio } from 'components/antd';
import {
	DateSettingsLabels,
	TimeSettingsLabels
} from 'store/registration/settings';
import { IDropdownOption } from 'app-types';
import { keys } from 'lodash';

interface UserDateTimePreferencesProps {
	dateFormat?: string;
	timeFormat?: string;
	timezonesOptions: IDropdownOption[];
}

const UserDateTimePreferences: FC<UserDateTimePreferencesProps> = ({
	dateFormat = '',
	timeFormat = '',
	timezonesOptions
}) => {
	return (
		<Row>
			<Col sm={3}>
				<Select.ReduxFormItem
					label="Date Format"
					name="dateFormat"
					defaultValue={dateFormat}
				>
					{keys(DateSettingsLabels).map(value => (
						<Select.Option
							key={value}
							value={value}
							title={DateSettingsLabels[value]}
						>
							{DateSettingsLabels[value]}
						</Select.Option>
					))}
				</Select.ReduxFormItem>
				<Select.ReduxFormItem
					label="Time Zone"
					name="timeZone"
					placeholder="Select your timezone"
				>
					{timezonesOptions.map(option => (
						<Select.Option
							key={option.value}
							value={option.value}
							title={option.label}
						>
							{option.label}
						</Select.Option>
					))}
				</Select.ReduxFormItem>
			</Col>
			<Col sm={4}>
				<Radio.Group.ReduxFormItem
					name="timeFormat"
					label="Time Format"
					defaultValue={timeFormat}
				>
					{keys(TimeSettingsLabels).map(value => (
						<Radio key={value} value={value}>
							{TimeSettingsLabels[value]}
						</Radio>
					))}
				</Radio.Group.ReduxFormItem>
			</Col>
		</Row>
	);
};

export default UserDateTimePreferences;
