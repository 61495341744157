import { retrieveCompanyTemplatesAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveCompanyTemplatesRequest,
	RetrieveCompanyTemplatesResponse
} from 'services/api/templates/templatesServiceTypes';

export default makeTakeLatestWatcher<
	RetrieveCompanyTemplatesRequest,
	RetrieveCompanyTemplatesResponse,
	Error
>({
	api: Api.Templates.retrieveCompanyTemplates,
	async: retrieveCompanyTemplatesAsync
});
