import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './portCallMeasurementsState';
import {
	retrievePortCallMeasurementsAccessAsync,
	retrievePortCallBunkeringMeasurementsAsync,
	retrievePortCallShipMeasurementsAsync,
	retrievePortCallCargoMeasurementsAsync,
	updateBunkeringOperationGradeAsync,
	updateShipMeasurementsAsync,
	updateCargoMeasurementsAsync
} from './actions';
import {
	onRetrievePortCallMeasurementsAccess,
	onRetrievePortCallBunkeringMeasurements,
	onRetrievePortCallShipMeasurements,
	onRetrievePortCallCargoMeasurements,
	onUpdateBunkeringOperationGradeStarted,
	onUpdateBunkeringOperationGradeSuccess,
	onUpdateBunkeringOperationGradeFailed,
	onUpdateShipMeasurementStarted,
	onUpdateShipMeasurementsSuccess,
	onUpdateShipMeasurementsFailed,
	onUpdateCargoMeasurements
} from './reducers';

export default reducerWithInitialState(initialState)
	.case(
		retrievePortCallMeasurementsAccessAsync.started,
		onRetrievePortCallMeasurementsAccess.started
	)
	.case(
		retrievePortCallMeasurementsAccessAsync.failed,
		onRetrievePortCallMeasurementsAccess.failed
	)
	.case(
		retrievePortCallMeasurementsAccessAsync.done,
		onRetrievePortCallMeasurementsAccess.done
	)
	.case(
		retrievePortCallBunkeringMeasurementsAsync.started,
		onRetrievePortCallBunkeringMeasurements.started
	)
	.case(
		retrievePortCallBunkeringMeasurementsAsync.failed,
		onRetrievePortCallBunkeringMeasurements.failed
	)
	.case(
		retrievePortCallBunkeringMeasurementsAsync.done,
		onRetrievePortCallBunkeringMeasurements.done
	)
	.case(
		updateBunkeringOperationGradeAsync.started,
		onUpdateBunkeringOperationGradeStarted
	)
	.case(
		updateBunkeringOperationGradeAsync.failed,
		onUpdateBunkeringOperationGradeFailed
	)
	.case(
		updateBunkeringOperationGradeAsync.done,
		onUpdateBunkeringOperationGradeSuccess
	)
	.case(
		retrievePortCallShipMeasurementsAsync.started,
		onRetrievePortCallShipMeasurements.started
	)
	.case(
		retrievePortCallShipMeasurementsAsync.failed,
		onRetrievePortCallShipMeasurements.failed
	)
	.case(
		retrievePortCallShipMeasurementsAsync.done,
		onRetrievePortCallShipMeasurements.done
	)
	.case(updateShipMeasurementsAsync.started, onUpdateShipMeasurementStarted)
	.case(updateShipMeasurementsAsync.failed, onUpdateShipMeasurementsFailed)
	.case(updateShipMeasurementsAsync.done, onUpdateShipMeasurementsSuccess)
	.case(
		retrievePortCallCargoMeasurementsAsync.started,
		onRetrievePortCallCargoMeasurements.started
	)
	.case(
		retrievePortCallCargoMeasurementsAsync.failed,
		onRetrievePortCallCargoMeasurements.failed
	)
	.case(
		retrievePortCallCargoMeasurementsAsync.done,
		onRetrievePortCallCargoMeasurements.done
	)
	.case(updateCargoMeasurementsAsync.started, onUpdateCargoMeasurements.started)
	.case(updateCargoMeasurementsAsync.failed, onUpdateCargoMeasurements.failed)
	.case(updateCargoMeasurementsAsync.done, onUpdateCargoMeasurements.done);
