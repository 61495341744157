import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetBankAccounts } from 'store/masterdata/bankAccounts/actions';
import BankAccountDetails from './BankAccountDetails/BankAccountDetails';
import BankAccountsTable from './BankAccountsTable/BankAccountsTable';

interface BankAccountsTabProps {
	path: string;
}

const BankAccountsTab: React.SFC<BankAccountsTabProps> = ({ path }) => {
	const dispatch = useDispatch();

	useEffect(
		() => () => {
			dispatch(resetBankAccounts());
		},
		[dispatch]
	);

	return (
		<>
			<Route component={BankAccountsTable} path={path} exact />
			<Route path={`${path}/:id`} component={BankAccountDetails} />
		</>
	);
};

export default BankAccountsTab;
