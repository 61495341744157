import {
	DisbursementAccount,
	DAColumnMetadataKey,
	DaSetType,
	DAColumnMetadataActionCode,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';
import { ColumnDaProps } from './summaryTypes';
import { FAKE_COLUMN_KEY } from 'store/finance/constants';
import { includes } from 'lodash';
import moment from 'moment-timezone';

export const prepareColumns = (
	currentPropsToView: Array<
		FinanceMetadataActionCode | DAColumnMetadataKey | DAColumnMetadataActionCode
	>,
	additionalPropsToView: Array<
		FinanceMetadataActionCode | DAColumnMetadataKey | DAColumnMetadataActionCode
	>,
	columnsConfig: Array<ColumnDaProps<DisbursementAccount>>
): Array<ColumnDaProps<DisbursementAccount>> => {
	return columnsConfig.filter(config => {
		const hasColumn = includes(currentPropsToView, config.metaDataKey);
		const hasAdditionalColumn = includes(
			additionalPropsToView,
			config.metaDataKey
		);
		if (
			hasColumn &&
			hasAdditionalColumn &&
			config.dataIndex === FAKE_COLUMN_KEY
		) {
			return false;
		}
		if (config.dataIndex === FAKE_COLUMN_KEY) {
			return hasAdditionalColumn;
		}
		return hasColumn;
	});
};

const titlePrefix = (type: DaSetType) =>
	type === DaSetType.SUPPLEMENTAL ? 'SDA' : 'DA';

const titleSuffix = (daSetNumber: number, daNumber: number) =>
	daSetNumber + '.' + daNumber;

export const daTitle = (
	daSetNumber: number,
	daNumber: number,
	daSetType: DaSetType
) => titlePrefix(daSetType) + ' #' + titleSuffix(daSetNumber, daNumber);

export const getSettlementDateValidationError = (
	momentDate: moment.Moment,
	dateFormat: string,
	nowDate = moment()
) => {
	const clonedMomentDate = momentDate.clone();
	if (!clonedMomentDate.isValid()) {
		return `Please enter valid date ${dateFormat}`;
	} else if (clonedMomentDate?.hour(23).isBefore(nowDate.subtract(1, 'y'))) {
		return 'Selected date is more than 1 year in the past';
	}
	return;
};
