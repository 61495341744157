import actionCreatorFactory from 'typescript-fsa';
import { User } from 'services/api/users/userServiceTypes';

const actionCreator = actionCreatorFactory();

export const RETRIEVE_USER = 'users/RETRIEVE_SINGLE_USER';
export const retrieveUserAsync = actionCreator.async<string, User, Error>(
	RETRIEVE_USER,
	{
		failed: {
			returnUrl: '/users'
		}
	}
);

export const retrieveUser = actionCreator<string>(retrieveUserAsync.type);
