import * as React from 'react';
import styles from './BrowserDocumentPreview.module.scss';

interface BrowserDocumentPreviewProps
	extends Partial<Pick<HTMLObjectElement, 'name' | 'type'>> {
	url: string;
}

const BrowserDocumentPreview: React.SFC<BrowserDocumentPreviewProps> = ({
	url,
	...props
}) => <embed {...props} src={url} className={styles.root} />;

export default BrowserDocumentPreview;
