import { ConfigurationFeedbackManagementData } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_CONFIGURATION_FEEDBACK_MANAGEMENT';
export const retrieveConfigurationFeedbackManagement = actionCreator(
	ACTION_NAME
);
export const retrieveConfigurationFeedbackManagementAsync = actionCreator.async<
	null,
	ConfigurationFeedbackManagementData,
	Error
>(ACTION_NAME);
