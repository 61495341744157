import { GroupsState } from '../groupsState';
import { FetchStatus } from 'services/api/apiTypes';
export function onCheckPermissionStarted(state: GroupsState): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			checkingPermissions: FetchStatus.PENDING
		}
	};
}
export function onCheckPermissionSuccess(state: GroupsState): GroupsState {
	// No FetchStatus here since there's nothing to show if the response is Successful
	return state;
}
export function onCheckPermissionFailed(state: GroupsState): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			checkingPermissions: FetchStatus.FAILURE
		}
	};
}
