import {
	UpdateBankAccountRequest as Request,
	BankAccount as Response
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { select, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { updateBankAccountAsync as async } from './../actions';
import Api from 'services/api';
import { getActiveFundingRequestId } from 'store/financeFundings/selectors';
import { retrieveFundingRequest } from 'store/financeFundings/actions';

export default makeTakeLatestWatcher<Request, Response, Error>({
	async,
	api: Api.BankAccounts.updateBankAccount,
	*onSuccess() {
		const fundingId = yield select(getActiveFundingRequestId);
		if (fundingId) {
			yield put(retrieveFundingRequest({ fundingId }));
		}
	}
});
