import React from 'react';
import DocumentViewerImagePreviewContainer, {
	DocumentViewerImagePreviewContainerOwnProps
} from './DocumentViewerImagePreviewContainer';
import DocumentViewerPreviewNotAvailable from './DocumentViewerPreviewNotAvailable';
import {
	DocumentBrowserViewerCommonProps,
	BrowserDocumentPreviewContainer
} from 'sections/DocumentBrowserPageViewer';

interface DocumentViewerPreviewProps
	extends DocumentViewerImagePreviewContainerOwnProps,
		DocumentBrowserViewerCommonProps {
	isDocumentPDF: boolean;
	returnUrl: string;
	preventSplit?: boolean;
}

const DocumentViewerPreview: React.SFC<DocumentViewerPreviewProps> = ({
	isDocumentPDF,
	activePage,
	documentId,
	preventSplit,
	returnUrl,
	fileDocument
}) => {
	if (preventSplit) {
		return (
			<BrowserDocumentPreviewContainer
				returnUrl={returnUrl}
				fileDocument={fileDocument}
			/>
		);
	}

	if (!isDocumentPDF) {
		return <DocumentViewerPreviewNotAvailable />;
	}

	return (
		<DocumentViewerImagePreviewContainer
			activePage={activePage}
			documentId={documentId}
		/>
	);
};

export default DocumentViewerPreview;
