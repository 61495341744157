import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobState } from '../portJobsState';
import { RequestPDARequest as Request } from 'services/api/portJobs/portJobsServiceTypes';

export const onPDARequest = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	Request,
	void
>('fetchStatuses', 'requestPDA');
