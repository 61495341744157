import { all, fork } from 'redux-saga/effects';
import { retrieveLinkJobCargosWatcher } from './retriveLinkedJobCargoSaga';
import { linkPortJobCargosWatcher } from './saveLinkedJobCargosSaga';
import retrieveLinkedAppointmentJobWatcher from './retrieveLinkedAppointmentJobSaga';
import { updateLinkPortJobCargosWatcher } from './saveUpdatedLinkedJobCargosSaga';

export default function*() {
	yield all([
		fork(retrieveLinkJobCargosWatcher),
		fork(linkPortJobCargosWatcher),
		fork(retrieveLinkedAppointmentJobWatcher),
		fork(updateLinkPortJobCargosWatcher)
	]);
}
