import { FetchStatus } from 'services/api/apiTypes';
import { Group } from 'services/api/groups/groupsServiceTypes';
import {
	Company,
	PrincipalIntegrationSettings,
	RetrieveFeedbackManagementGridResponse
} from 'services/api/companies/companiesServiceTypes';
import { FileBaseInfo } from 'services/api/documents/documentsServiceTypes';

export enum VesselTypesMode {
	DEFAULT = 'DEFAULT',
	ADD = 'ADD',
	EDIT = 'EDIT',
	DELETE = 'DELETE'
}

interface VesselTypesModeNoId {
	mode: VesselTypesMode.DEFAULT | VesselTypesMode.ADD;
	vesselTypeId?: undefined; // always undefined in this mode
}

interface VesselTypesModeWithId {
	mode: VesselTypesMode.EDIT | VesselTypesMode.DELETE;
	vesselTypeId: string;
}

export type VesselTypesTabMode = VesselTypesModeNoId | VesselTypesModeWithId;

interface VesselsTabState {
	deleteVesselId?: string;
}

export interface ConfigurationState {
	readonly companies: Company[];
	readonly fetchStatuses: {
		initialize: FetchStatus;
		updateGeneralDetails: FetchStatus;
		updateEmailFragments: FetchStatus;
		uploadPreferredAgentsFile: FetchStatus;
		deleteVesselType: FetchStatus;
		retrieveMainPrincipalGroups: FetchStatus;
		retrievePrincipalIntegrationSettings: FetchStatus;
		retrieveBenchmark: FetchStatus;
		updateBenchmark: FetchStatus;
		retrieveFeedbackManagement: FetchStatus;
		createFeedbackManagementStatus: FetchStatus;
		retrieveFeedbackManagementGridStatus: FetchStatus;
		retrieveEventListAndOpticVariables: FetchStatus;
		downloadFeedbackManagementFetchStatus: FetchStatus;
		deleteFeedbackManagementFetchStatus: FetchStatus;
		updateFeedbackManagement: FetchStatus;
		updateFeedbackManagementGridStatus: FetchStatus;
	};
	readonly context: {
		activeCompanyId?: string;
		vesselTypesTabMode: VesselTypesTabMode;
		vesselsTab: VesselsTabState;
		hasUnsavedData: boolean;
		uploadedRequestFeedbackDocuments: FileBaseInfo[];
	};
	readonly isFeedbackManagementEnabled: boolean;
	readonly triggerEvent: string[];
	readonly opticVariable: string[];
	readonly feedbackManagementGrid: RetrieveFeedbackManagementGridResponse[];
	readonly generalDetails: {
		groups: Group[];
	};
	readonly principalIntegrationSettings: PrincipalIntegrationSettings;
}

export const initialState: ConfigurationState = {
	companies: [],
	context: {
		vesselTypesTabMode: {
			mode: VesselTypesMode.DEFAULT
		},
		vesselsTab: {},
		hasUnsavedData: false,
		uploadedRequestFeedbackDocuments: []
	},
	isFeedbackManagementEnabled: false,
	triggerEvent: [],
	opticVariable: [],
	feedbackManagementGrid: [],
	generalDetails: {
		groups: []
	},
	principalIntegrationSettings: {
		isPrincipalIntegrationEnabled: false
	},
	fetchStatuses: {
		initialize: FetchStatus.IDLE,
		updateGeneralDetails: FetchStatus.IDLE,
		updateEmailFragments: FetchStatus.IDLE,
		uploadPreferredAgentsFile: FetchStatus.IDLE,
		deleteVesselType: FetchStatus.IDLE,
		retrieveMainPrincipalGroups: FetchStatus.IDLE,
		retrievePrincipalIntegrationSettings: FetchStatus.IDLE,
		retrieveBenchmark: FetchStatus.IDLE,
		updateBenchmark: FetchStatus.IDLE,
		retrieveFeedbackManagement: FetchStatus.IDLE,
		createFeedbackManagementStatus: FetchStatus.IDLE,
		retrieveEventListAndOpticVariables: FetchStatus.IDLE,
		retrieveFeedbackManagementGridStatus: FetchStatus.IDLE,
		downloadFeedbackManagementFetchStatus: FetchStatus.IDLE,
		deleteFeedbackManagementFetchStatus: FetchStatus.IDLE,
		updateFeedbackManagement: FetchStatus.IDLE,
		updateFeedbackManagementGridStatus: FetchStatus.IDLE
	}
};
