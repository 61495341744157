import actionCreator from '../portJobsActionCreator';
import {
	RetrieveJobThreadsResponse,
	RetrieveJobThreadsRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTJOB_THREADS';
export const retrievePortJobThreadsAsync = actionCreator.async<
	RetrieveJobThreadsRequest,
	RetrieveJobThreadsResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'sub-section', infiniteLoad: true } });
export const retrievePortJobThreads = actionCreator<RetrieveJobThreadsRequest>(
	ACTION_NAME
);

/**
 * Reset PortJob Threads
 */
export const resetPortJobThreads = actionCreator('RESET_PORTJOB_THREADS');
