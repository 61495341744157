import moment from 'moment-timezone';

export const getTimeAgo = (
	date: string,
	timeZone?: string,
	accurate?: boolean
) => {
	let currentDate = moment();
	let previousDate = moment(date);
	if (timeZone) {
		currentDate = moment.utc();
		previousDate = moment.utc(date);
		const offset = moment.tz(moment.utc(), timeZone).utcOffset();
		if (offset > 0) {
			previousDate.subtract(offset, 'minutes');
		} else {
			previousDate.add(offset, 'minutes');
		}
	}
	if (currentDate.isDST() && !previousDate.isDST()) {
		previousDate.subtract(1, 'h');
	} else if (!currentDate.isDST() && previousDate.isDST()) {
		previousDate.add(1, 'h');
	}
	const duration = moment.duration(currentDate.diff(previousDate));
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();

	if (!accurate) {
		if (days >= 1) {
			return `${days} ${days === 1 ? 'day' : 'days'}`;
		}

		if (hours >= 1) {
			return `${hours}h`;
		}

		if (minutes >= 1) {
			return `${minutes}m`;
		}

		return `< min`;
	}

	if (days < 1 && hours < 1 && minutes < 1) {
		return `< min`;
	}

	let timeAgo = '';

	if (days >= 1) {
		timeAgo += `${days}d`;
	}

	if (hours >= 1) {
		timeAgo += ` ${hours}h`;
	}

	if (minutes >= 1) {
		timeAgo += ` ${minutes}m`;
	}

	return timeAgo;
};
