import * as React from 'react';
import { isNumber } from 'lodash';
import classNames from 'classnames';
import styles from './Text.module.scss';
import Gap, { GapProps } from 'components/Styles/Gap/Gap';
import { TextColor } from 'components/types';

interface TextProps extends React.DOMAttributes<{}> {
	className?: string;
	upper?: boolean;
	color?: TextColor;
	weight?: 'light' | 'normal' | 'medium' | 'semi-bold' | 'bold';
	decoration?: 'underline' | 'line-through';
	size?: number | 'xs' | 'sm' | 'md';
	breakLines?: boolean;
	breakWordInIE?: boolean;
	nowrap?: boolean;
	dataQa?: string; // for QAA
}

class Text extends React.PureComponent<TextProps> {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	static Box: typeof TextBox;
	render() {
		const {
			className,
			upper,
			children,
			color = '',
			weight,
			decoration,
			size,
			breakLines,
			breakWordInIE,
			nowrap,
			dataQa
		} = this.props;

		const weightTypes = {
			[styles.lightWeight]: weight === 'light',
			[styles.normalWeight]: weight === 'normal',
			[styles.mediumWeight]: weight === 'medium',
			[styles.semiBoldWeight]: weight === 'semi-bold',
			[styles.boldWeight]: weight === 'bold'
		};
		const decorationTypes = {
			[styles.decorationUnderline]: decoration === 'underline',
			[styles.decorationLineThrough]: decoration === 'line-through'
		};
		const fontSize = {
			[styles.xs]: size === 'xs',
			[styles.sm]: size === 'sm',
			[styles.md]: size === 'md'
		};
		return (
			<span
				className={classNames(
					className,
					weightTypes,
					decorationTypes,
					fontSize,
					{
						[styles.upper]: upper,
						[styles[color]]: Boolean(color),
						[styles.breakLines]: breakLines,
						[styles.breakWordInIE]: breakWordInIE,
						[styles.nowrap]: nowrap
					}
				)}
				style={{
					fontSize: isNumber(size) ? size : undefined
				}}
				data-qa={dataQa}
			>
				{children}
			</span>
		);
	}
}

interface TextBoxProps extends TextProps, GapProps {}
const TextBox: React.FC<TextBoxProps> = props => {
	const { top, right, bottom, left, children, ...restProps } = props;
	return (
		<Gap top={top} right={right} bottom={bottom} left={left}>
			<Text {...restProps}>{children}</Text>
		</Gap>
	);
};

Text.Box = TextBox;
export default Text;
