import React from 'react';
import { connect } from 'react-redux';
import {
	reduxForm,
	InjectedFormProps,
	FieldArray,
	change,
	registerField
} from 'redux-form';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';

import { Button } from 'components/antd';
import Charterers from './Charterers/Charterers';
import CargoGridContainer from '../CargoGrid/CargoGridContainer';
import CargoLineModal from '../CargoLineModal/CargoLineModal';
import {
	FormData,
	CargoLineFormDataTypes
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	FORM,
	CARGO_LINE_MODAL,
	CANCEL_PROCESS_MODAL,
	FormFieldName
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';

import { getValues } from 'store/form/selectors';
import {
	isChartererAvailableForDelete,
	getFormattedCargoLineValues,
	getCargoIndex,
	getTotalQuantity,
	getIsDuplicateCharterer,
	getDefaultCharterer,
	getChartererIndexToRemove,
	getChartererIndex
} from './CargoLines.func';
import { openModal, closeModal } from 'store/modals/actions';
import { setEditableCargoId } from 'store/portCall/actions';
import {
	getEditableCargoId,
	getIsEditablePortJobCompanies
} from 'store/portJobs/selectors';
import { FieldType } from 'store/form/formTypes';
import { AppState } from 'store-types';
import { getIsCurrentUserHub } from 'store/auth/selectors';

const { CARGO_LINES, CHARTERERS } = FormFieldName;

interface CargoLinesHandlers {
	onSubmit: (cargoLine: CargoLineFormDataTypes) => void;
	onCancel: () => void;
}

type CargoLinesBaseProps = ReturnType<typeof mapStateToProps> & {
	children: (handlers: CargoLinesHandlers) => React.ReactNode;
	isHubUser: boolean;
	// from mapDispatchToProps
	registerFormField: typeof registerField;
	openModal: typeof openModal;
	closeModal: typeof closeModal;
	changeValue: typeof change;
	setEditableCargoId: typeof setEditableCargoId;
};

interface CargoLinesState {
	cargoLineId: string;
	chartererId: string;
	chartererAvailableForDelete: boolean;
	showWarningModalForUnlink: boolean;
	cargoLine: CargoLineFormDataTypes;
	editableCargoId: string;
}

interface CargoLinesProps
	extends InjectedFormProps<FormData, CargoLinesBaseProps>,
		CargoLinesBaseProps {}

/**
 * @class CargoLines
 * 1. Registers cargoLines/charterers fields
 * 2. Operates over them on add/delete
 */

class CargoLines extends React.Component<CargoLinesProps, CargoLinesState> {
	constructor(props: CargoLinesProps) {
		super(props);
		this.state = {
			cargoLineId: '',
			chartererId: '',
			chartererAvailableForDelete: false,
			showWarningModalForUnlink: false,
			cargoLine: {},
			editableCargoId: ''
		};
	}

	componentDidMount() {
		this.props.registerFormField(
			FORM.portJob,
			CARGO_LINES,
			FieldType.FIELD_ARRAY
		);
	}

	/**
	 * User initiates delete from grid actions.
	 * Save all related info to make delete happen when user confirms it from the modal
	 */
	onDeleteCargoLine = (cargoLineId: string, chartererId: string) => {
		const {
			formValues: { cargoes }
		} = this.props;
		this.setState({
			cargoLineId,
			chartererId,
			chartererAvailableForDelete: isChartererAvailableForDelete(
				cargoes,
				chartererId
			)
		});
	};

	onEditCargoLine = (cargoId: string) => {
		this.props.setEditableCargoId(cargoId);
		this.props.openModal(CARGO_LINE_MODAL);
	};

	deleteCargoLine = () => {
		const {
			cargoLineId,
			chartererId,
			chartererAvailableForDelete
		} = this.state;
		const { array, formValues } = this.props;
		const cargoIndex = getCargoIndex(formValues.cargoes, cargoLineId);
		const chartererIndex = getChartererIndex(
			formValues.charterers,
			chartererId
		);
		// Remove related charterer
		if (chartererAvailableForDelete) {
			array.remove(CHARTERERS, chartererIndex);
		}
		const parentCargoId = formValues.cargoes[cargoIndex].parentCargoId;
		if (parentCargoId) {
			const parentCargoIndex = getCargoIndex(formValues.cargoes, parentCargoId);
			const cargoLineValues = {
				...formValues.cargoes[parentCargoIndex],
				commodityQuantity: getTotalQuantity(
					formValues.cargoes,
					parentCargoIndex,
					cargoIndex
				)
			};
			array.splice(CARGO_LINES, parentCargoIndex, 1, cargoLineValues);
		}

		// Remove cargo line from the form and its children if they exist
		const cargoes = formValues.cargoes.filter(
			cargo => cargo.parentCargoId !== cargoLineId && cargo.id !== cargoLineId
		);
		this.props.changeValue(FORM.portJob, CARGO_LINES, cargoes);
	};

	onAddNewCargoLineModal = () => this.props.openModal(CARGO_LINE_MODAL);

	onCancel = () => this.props.openModal(CANCEL_PROCESS_MODAL);

	toggleWarningModalUnlink = () => {
		this.setState({
			showWarningModalForUnlink: !this.state.showWarningModalForUnlink
		});
	};

	onSubmit = (cargoLine: CargoLineFormDataTypes) => {
		const { editableCargoId, array, formValues, portJobCompanies } = this.props;
		const cargoLineValues = getFormattedCargoLineValues(cargoLine);
		if (!editableCargoId) {
			array.push(CARGO_LINES, cargoLineValues);
			this.updateCharterers(cargoLineValues);
			this.props.closeModal(CARGO_LINE_MODAL);
			return;
		}
		const cargoIndex = getCargoIndex(formValues.cargoes, editableCargoId);
		const chartererIndexToRemove = getChartererIndexToRemove(
			formValues.cargoes,
			formValues.charterers,
			editableCargoId,
			cargoLineValues,
			portJobCompanies
		);
		array.splice(CARGO_LINES, cargoIndex, 1, cargoLineValues);
		this.updateCharterers(cargoLineValues, chartererIndexToRemove);
		this.props.closeModal(CARGO_LINE_MODAL);
	};

	updateCharterers = (
		cargoLine: CargoLineFormDataTypes,
		chartererIndexToRemove?: number
	) => {
		const {
			formValues: { charterers },
			array,
			portJobCompanies = []
		} = this.props;
		// remove carterer from the list if it was removed from cargo
		if (chartererIndexToRemove !== undefined) {
			array.remove(CHARTERERS, chartererIndexToRemove);
		}
		// do not add duplicates and empty charterers
		if (!getIsDuplicateCharterer(portJobCompanies, cargoLine, charterers)) {
			this.props.array.push(CHARTERERS, getDefaultCharterer(cargoLine));
			this.props.touch(CHARTERERS);
		}
	};

	getStateAndHelpers = (): CargoLinesHandlers => {
		return {
			onSubmit: this.onSubmit,
			onCancel: this.onCancel
		};
	};

	render() {
		const {
			formValues: { cargoes = [] }
		} = this.props;
		const CargoLineForm = this.props.children(this.getStateAndHelpers());

		return (
			<>
				<CargoGridContainer
					cargoes={cargoes}
					onDelete={this.onDeleteCargoLine}
					onEdit={this.onEditCargoLine}
					onDeleteConfirmed={this.deleteCargoLine}
				/>
				<Button
					type="primary"
					transparent
					icon="plus"
					onClick={this.onAddNewCargoLineModal}
				>
					Add new Cargo Line
				</Button>
				<CargoLineModal form={CargoLineForm} />
				<FieldArray<{}> name={CHARTERERS} component={Charterers} />
			</>
		);
	}
}

const mapStateToProps = (state: AppState) => ({
	formValues: getValues<FormData>(state, FORM.portJob),
	editableCargoId: getEditableCargoId(state),
	portJobCompanies: getIsEditablePortJobCompanies(state),
	isHubUser: getIsCurrentUserHub(state)
});

export default connect(mapStateToProps, {
	openModal,
	closeModal,
	changeValue: change,
	setEditableCargoId,
	registerFormField: registerField
})(
	reduxForm<FormData, CargoLinesBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true
	})(CargoLines)
);
