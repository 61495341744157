import React from 'react';
import { ReduxFormSubmitButton, Loading } from 'components';
import { Modal } from 'components/antd';

import { ModalContext } from 'context';
import { ModalContextBaseProps } from 'context/ModalContext/ModalContext';

import CreateGroupForm from './CreateGroupForm';
import { CREATE_GROUP_FORM } from '../GroupsModalsTypes';
import { useCreateGroupModal } from './CreateGroupModal.hooks';
import { useCreateGroupForm } from './CreateGroupForm.hooks';

function CreateGroupModal({
	modalSubmitting,
	hideModal
}: Omit<ModalContextBaseProps, 'modalValue'>) {
	const { onSubmit, fetchStatus } = useCreateGroupModal({
		form: CREATE_GROUP_FORM
	});
	const {
		isCompaniesLoading,
		initialValues,
		companies,
		groupTypes,
		isSelectedCompanyIss,
		isCurrentUserOfISSAdminGroupType
	} = useCreateGroupForm({ form: CREATE_GROUP_FORM });

	return (
		<>
			<Modal
				visible
				title="Add Group"
				onCancel={hideModal}
				okButton={
					<ReduxFormSubmitButton
						form={CREATE_GROUP_FORM}
						type="primary"
						loading={modalSubmitting}
						submitForm
					>
						Submit
					</ReduxFormSubmitButton>
				}
			>
				{isCompaniesLoading ? (
					<Loading />
				) : (
					<CreateGroupForm
						form={CREATE_GROUP_FORM}
						initialValues={initialValues}
						onSubmit={onSubmit}
						isSelectedCompanyIss={isSelectedCompanyIss}
						companies={companies}
						groupTypes={groupTypes}
						isCurrentUserOfISSAdminGroupType={isCurrentUserOfISSAdminGroupType}
					/>
				)}
			</Modal>
			<ModalContext.SubmitResolve fetchStatus={fetchStatus} />
		</>
	);
}

export default CreateGroupModal;
