import * as React from 'react';
import { connect } from 'react-redux';
import { ScrollableLayout } from 'components';
import { AppNotifications } from 'containers';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';

import { BreadcrumbItem } from 'store/navigation';

import { getGroupsBreadcrumbs } from './GroupsSelectors';
import { AppState } from 'store-types';

interface GroupsLayoutProps {
	currentBreadcrumb?: BreadcrumbItem;
	// from mapStateToProps
	breadcrumbs: BreadcrumbItem[];
}

const GroupsLayout: React.SFC<GroupsLayoutProps> = props => {
	return (
		<ScrollableLayout>
			<RouteBreadcrumbs items={props.breadcrumbs} />
			<AppNotifications.Alert />
			<AppNotifications.Notification />
			{props.children}
		</ScrollableLayout>
	);
};

export default connect(
	(
		state: AppState,
		{ currentBreadcrumb }: Partial<Pick<GroupsLayoutProps, 'currentBreadcrumb'>>
	) => {
		const breadcrumbs = getGroupsBreadcrumbs(state);
		return {
			breadcrumbs: currentBreadcrumb
				? breadcrumbs.concat(currentBreadcrumb)
				: breadcrumbs
		};
	}
)(GroupsLayout);
