import React from 'react';
import { useSelector } from 'react-redux';
import { getBankAccountsById } from 'store/masterdata/bankAccounts/selectors';
import FundingRequestBooleanValue from './FundingRequestBooleanValue';

interface FundingRequestLobdProps {
	bankDetailsId: string;
}

const FundingRequestLobd = (props: FundingRequestLobdProps) => {
	const bankAccount = useSelector(getBankAccountsById);
	const lobd = bankAccount[props.bankDetailsId]?.lobd || false;
	return <FundingRequestBooleanValue value={lobd} />;
};

export default FundingRequestLobd;
