import {
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse
} from 'services/api/portCall/portCallServiceTypes';
import actionCreator from '../portCallActionCreator';

const RETRIEVE_PORTCALL_METADATA = 'RETRIEVE_PORTCALL_METADATA';
export const retrievePortCallMetadataAsync = actionCreator.async<
	RetrievePortCallMetadataRequest,
	RetrievePortCallMetadataResponse,
	Error
>(RETRIEVE_PORTCALL_METADATA);

export const retrievePortCallMetadata = actionCreator<
	RetrievePortCallMetadataRequest
>(RETRIEVE_PORTCALL_METADATA);

const RETRIEVE_PORTCALL_METADATA_CYCLE_START =
	'RETRIEVE_PORTCALL_METADATA_CYCLE_START';
const RETRIEVE_PORTCALL_METADATA_CYCLE_END =
	'RETRIEVE_PORTCALL_METADATA_CYCLE_END';

export const retrievePortCallMetadataStart = actionCreator<RetrievePortCallMetadataRequest | void>(
	RETRIEVE_PORTCALL_METADATA_CYCLE_START
);

export const retrievePortCallMetadataEnd = actionCreator(
	RETRIEVE_PORTCALL_METADATA_CYCLE_END
);
