import * as React from 'react';
import classNames from 'classnames';
import AntForm, { FormProps as AntFormProps } from 'antd/lib/form/Form';
import Item from './FormItem';
import styles from './Form.module.scss';

interface FormProps extends AntFormProps {
	formItemMarginBottom?: boolean;
}
export class Form extends React.Component<FormProps> {
	static Item: typeof Item;
	static create: typeof AntForm.create;

	render() {
		const { formItemMarginBottom = true, ...props } = this.props;
		return (
			<AntForm
				{...props}
				className={classNames(styles.root, this.props.className, {
					[styles.noFormItemMarginBottom]: !formItemMarginBottom
				})}
			/>
		);
	}
}

Form.Item = Item;
Form.create = AntForm.create;

export default Form;
