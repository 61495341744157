import { updateConfigurationFileAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	UpdateCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileResponse
} from 'services/api/templates/templatesServiceTypes';

export default makeTakeLatestWatcher<
	UpdateCompanyTemplateFileRequest,
	UpdateCompanyTemplateFileResponse,
	Error
>({
	api: Api.Templates.updateCompanyTemplateFile,
	async: updateConfigurationFileAsync
});
