import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { DataFetcher } from 'utils/components';
import { DataFetcherProps } from 'utils/components/DataFetcher';
import {
	EntityPermissionComposeId,
	EntityPermission,
	EntityPermissionType
} from 'services/api/permissions/permissionsServiceTypes';
import {
	getEntityPermissions,
	getEntityPermissionFetchStatus
} from 'store/permissions/selectors/entityPermissionSelectors';
import { retrieveEntityPermissionsForPortCall } from 'store/permissions/actions';
import { AppState } from 'store-types';

const EntityPermissionActionsMap = {
	[EntityPermissionType.PORTCALL]: retrieveEntityPermissionsForPortCall,
	[EntityPermissionType.PORTJOB]: retrieveEntityPermissionsForPortCall,
	[EntityPermissionType.OPERATION]: retrieveEntityPermissionsForPortCall,
	[EntityPermissionType.LOADING_CARGO_LINE]: retrieveEntityPermissionsForPortCall,
	[EntityPermissionType.DISCHARGING_CARGO_LINE]: retrieveEntityPermissionsForPortCall
};

type EntityPermissionFetcherProps = EntityPermissionComposeId &
	Pick<DataFetcherProps, 'skipIfHasData' | 'error'> & {
		children: (entityPermission: EntityPermission) => React.ReactNode;
		showLoader?: boolean;
		skipIfHasData?: boolean;
	};

const EntityPermissionFetcher: FC<EntityPermissionFetcherProps> = ({
	entityType,
	entityKey,
	children,
	showLoader = true,
	skipIfHasData,
	error
}) => {
	const dispatch = useDispatch();
	const retrievePermissionsMethod = useMemo(
		() => EntityPermissionActionsMap[entityType],
		[entityType]
	);
	const entityPermissions = useSelector<
		AppState,
		ReturnType<typeof getEntityPermissions>
	>(
		(state: AppState) => getEntityPermissions(state, entityType, entityKey),
		shallowEqual
	);
	const fetchStatus = useSelector<
		AppState,
		ReturnType<typeof getEntityPermissionFetchStatus>
	>((state: AppState) =>
		getEntityPermissionFetchStatus(state, entityType, entityKey)
	);

	const retrievePermissions = useCallback(() => {
		dispatch(
			retrievePermissionsMethod({
				entityKey,
				entityType
			})
		);
	}, [dispatch, retrievePermissionsMethod, entityType, entityKey]);

	return (
		<DataFetcher
			forceFetch={false}
			data={entityPermissions}
			fetchStatus={fetchStatus}
			dispatch={retrievePermissions}
			showLoader={showLoader}
			skipIfHasData={skipIfHasData}
			error={error}
		>
			{children}
		</DataFetcher>
	);
};

export default EntityPermissionFetcher;
