import * as React from 'react';
import classnames from 'classnames';
import { FileExtension } from 'app-constants';
import { FileItemProps as FileItemFileProps } from 'app-types';
import { Flex, Gap, Title, Text } from 'components';
import { Button, Icon } from 'components/antd';
import { IconType } from 'components/antd/Icon/IconTypes';
import styles from './FileItem.module.scss';
import { Justify, IconColor } from 'components/types';
import { Truncate } from 'components/Styles';
import { getFileExtension } from 'utils';
import Loading from 'components/Loading/Loading';
import { FormattedTime } from 'containers';

interface FileItemProps {
	file: FileItemFileProps;
	onClick?: (file: FileItemFileProps) => void;
	onDelete?: (file: FileItemFileProps) => void;
	isLoading?: boolean;
	iconColor?: IconColor;
}

/**
 * Usin Map instead of an object literal for an easier type definition
 */
const iconType = new Map<FileExtension, keyof IconType>([
	[FileExtension.DOC, 'file-word'],
	[FileExtension.DOCX, 'file-word'],
	[FileExtension.XLS, 'file-excel'],
	[FileExtension.XLSX, 'file-excel'],
	[FileExtension.PDF, 'file-pdf']
]);

const FileItem: React.FC<FileItemProps> = ({
	file,
	onClick,
	onDelete,
	isLoading,
	iconColor
}) => {
	const extension = getFileExtension(file.extension) as FileExtension;
	const icon =
		Boolean(extension) && iconType.get(extension)
			? iconType.get(extension)
			: 'document';

	const extensionClass = styles[`ext-${extension || 'generic'}`];

	return (
		<Flex
			className={classnames(styles.root, {
				[styles.isClickeable]: Boolean(onClick) && !isLoading,
				[styles.isLoading]: isLoading
			})}
			align="center"
			justify={Justify.BETWEEN}
			onClick={() => !isLoading && onClick && onClick(file)}
		>
			<Flex>
				<Gap left="xs" right="xs">
					<Flex fit align="center">
						{isLoading ? (
							<Loading show={isLoading} size="xs" />
						) : (
							<Icon
								className={classnames(styles.icon, extensionClass)}
								type={icon as keyof IconType}
								color={iconColor}
							/>
						)}
					</Flex>
				</Gap>
				<Flex className={styles.rightColWrapper}>
					<Gap
						className={styles.fileNameWrapper}
						left="sm"
						right="sm"
						outside={false}
					>
						<Flex fit direction="vertical" className={styles.info}>
							<Title.H2 type={onClick ? 'primary' : undefined} marginBottom={0}>
								<Truncate>{file.name}</Truncate>
							</Title.H2>
							<Text size="xs" color="silent">
								<Truncate>
									{file.user} ({<Text upper>{file.userType}</Text>}),{' '}
									<FormattedTime value={file.uploadedOn} />
								</Truncate>
							</Text>
						</Flex>
					</Gap>
				</Flex>
			</Flex>
			{onDelete && (
				<Button
					type="danger"
					transparent
					icon="delete"
					onClick={e => {
						e.stopPropagation();
						return !isLoading && onDelete(file);
					}}
				/>
			)}
		</Flex>
	);
};

export default FileItem;
