import { CurrenciesState } from '../currenciesState';
import { Currency } from 'services/api/currencies/currenciesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export function onRetrieveCurrenciesStarted(
	state: CurrenciesState
): CurrenciesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.PENDING
		}
	};
}
export function onRetrieveCurrenciesSuccess(
	state: CurrenciesState,
	action: {
		result: Currency[];
	}
): CurrenciesState {
	const { result } = action;
	const byCode = {
		...state.byCode,
		...result.reduce((acc, operation: Currency) => {
			acc[operation.code] = operation;
			return acc;
		}, {})
	};
	return {
		...state,
		byCode,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
}
export function onRetrieveCurrenciesFailed(
	state: CurrenciesState
): CurrenciesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		}
	};
}
