import { Success } from 'typescript-fsa';
import { CommonState } from '../commonState';
import { SystemStatus } from '../commonTypes';

export const onRetrieveSystemStatus = {
	done: (state: CommonState, { result }: Success<null, SystemStatus>) => ({
		...state,
		system: {
			status: result.status,
			statusVerified: true
		}
	}),
	failed: (state: CommonState): CommonState => ({
		...state,
		system: {
			...state.system,
			statusVerified: true
		}
	})
};
