import actionCreator from '../portJobsActionCreator';
import {
	CreateThreadRequest,
	CreateThreadResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'SEND_EXTENDED_MESSAGE';

export const sendExtendedmessageAsync = actionCreator.async<
	CreateThreadRequest,
	CreateThreadResponse,
	Error
>(ACTION_NAME);

export const sendExtendedmessage = actionCreator<CreateThreadRequest>(
	ACTION_NAME
);
