export const CATEGORIZE_MSG_CANCEL_CONFIRMATION_MODAL =
	'categorizeMsgCancelConfirmationModal';

export const CATEGORIZE_MSG_MODAL_FORM = 'catergorizeMsgModalForm';

export enum ThreadsCatergorizeMsgFormFields {
	THREAD_CONTEXT_ID = 'threadContextId',
	THREAD_TAGS = 'codes',
	THREAD_JOBCODES = 'jobcodes',
	THREAD_STATUS = 'status'
}

export interface ThreadsCatergorizeMsgFormData {
	[ThreadsCatergorizeMsgFormFields.THREAD_CONTEXT_ID]?: string;
	[ThreadsCatergorizeMsgFormFields.THREAD_TAGS]?: string[];
	[ThreadsCatergorizeMsgFormFields.THREAD_JOBCODES]: string[];
	[ThreadsCatergorizeMsgFormFields.THREAD_STATUS]?: string;
}
