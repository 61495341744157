import {
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse
} from 'services/api/groups/groupsServiceTypes';
import actionCreator from '../threadActionCreator';
const RETRIEVE_USERS_FROM_GROUP = 'RETRIEVE_USERS_FROM_GROUP';

export const retrieveUsersFromGroupAsync = actionCreator.async<
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	Error
>(RETRIEVE_USERS_FROM_GROUP);

export const retrieveUsersFromGroup = actionCreator<RetrieveGroupUsersRequest>(
	RETRIEVE_USERS_FROM_GROUP
);
