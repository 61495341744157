import React, { FC, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Title } from 'components';
import { Row, Col /*, Checkbox */ } from 'components/antd';
import { FilterItem } from 'store/filters/filtersState';
import { FiltersStateAndHelpers } from 'components/Filters/Filters';
import {
	SupplierInvoiceFilterParam,
	SUPPLIER_INVOICE_FORM_ID,
	AllHubPrincipalsOption,
	filterTypeMap,
	SupplierInvoicesFilterLabels
} from 'store/supplierInvoice/filtersSync';
import { getFilterValues } from 'store/filters/filtersSelectors';
import { AppState } from 'store-types';
//import { Align } from 'components/types';
//import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import SelectHubPrincipal from './Fields/SelectHubPrincipal';
import SearchSupplierInvoice from './Fields/SearchSupplierInvoice';
//import styles from './SupplierInvoiceFilters.module.scss';

type SupplierInvoiceFiltersProps = FiltersStateAndHelpers;

const SupplierInvoiceFilters: FC<SupplierInvoiceFiltersProps> = ({
	onChange,
	clearOne
}) => {
	const filters =
		useSelector(
			(state: AppState) => getFilterValues(state, SUPPLIER_INVOICE_FORM_ID),
			shallowEqual
		) || {};

	const hubPrincipalValue =
		filters[SupplierInvoiceFilterParam.HUB_PRINCIPAL] &&
		filters[SupplierInvoiceFilterParam.HUB_PRINCIPAL][0];
	const shouldShowCancelled = !!(
		filters.includeCancelled && filters.includeCancelled[0].key
	);

	const onFilterSelect = useCallback(
		(filter: FilterItem, name: string, override?: boolean) => {
			onChange(
				{
					[name]: [filter]
				},
				override
			);
		},
		[onChange]
	);

	const onChangeSearch = useCallback(
		(filter: FilterItem, groupType: string) => {
			const { SEARCH } = SupplierInvoiceFilterParam;
			let resultGroupType = groupType;
			if (groupType === filterTypeMap[SEARCH]) {
				filter.key = filter.label;
				resultGroupType = SEARCH;
			}

			onFilterSelect({ ...filter, type: resultGroupType }, SEARCH, false);
		},
		[onFilterSelect]
	);

	const createOnChange = useCallback(
		(name: string) => (filter: FilterItem) => {
			if (filter.key === AllHubPrincipalsOption.key) {
				clearOne(hubPrincipalValue);
				return;
			}
			onFilterSelect({ ...filter, type: name }, name);
		},
		[clearOne, hubPrincipalValue, onFilterSelect]
	);

	/* Temporary commented as Cancel credit supplier is not supported for completed transaction
	const onShowCancelledChange = useCallback(
		({ target: { checked } }: CheckboxChangeEvent) => {
			const filter = {
				type: SupplierInvoiceFilterParam.SHOW_CANCELLED,
				key: 'true',
				label: 'yes'
			};
			if (checked) {
				createOnChange(SupplierInvoiceFilterParam.SHOW_CANCELLED)(filter);
			} else {
				clearOne(filter);
			}
		},
		[clearOne, createOnChange]
	);
     */
	return (
		<Row>
			<Col sm={4}>
				<Title.Label>
					{
						SupplierInvoicesFilterLabels[
							SupplierInvoiceFilterParam.HUB_PRINCIPAL
						]
					}
				</Title.Label>
				<SelectHubPrincipal
					onChange={createOnChange(SupplierInvoiceFilterParam.HUB_PRINCIPAL)}
					value={hubPrincipalValue}
				/>
			</Col>
			<Col sm={4}>
				<Title.Label>
					{SupplierInvoicesFilterLabels[SupplierInvoiceFilterParam.SEARCH]}
				</Title.Label>
				<SearchSupplierInvoice
					onChange={onChangeSearch}
					shouldShowCancelled={shouldShowCancelled}
				/>
			</Col>
			{/**
				 * IPP-31288 - we temporarily hide credit invoices functionality
				

				<Col sm={4} align={Align.BOTTOM}>
					<Checkbox
						onChange={onShowCancelledChange}
						checked={shouldShowCancelled}
						className={styles.showCancelledCheckbox}
					>
						Show cancelled supplier invoices
					</Checkbox>
				</Col>
				 */}
		</Row>
	);
};

export default SupplierInvoiceFilters;
