import {
	race,
	take,
	put,
	select,
	takeLatest,
	fork,
	call
} from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { UpdateCompanyGeneralConfigurationRequest } from 'services/api/companies/companiesServiceTypes';
import { updateConfigurationGeneralDetailsAsync as async } from '../../actions';
import {
	updateCompanyGeneralConfiguration,
	updateCompanyGeneralConfigurationAsync
} from 'store/companies/actions';
import initializeConfigurationGeneralDetailsFormWorker from '../initializeConfigurationGeneralDetailsForm/initializeConfigurationGeneralDetailsFormWorker';
import prepareRequest from './prepareRequest';
import { initializeGeneralDetailsWorker } from '../initializeConfigurationGeneralDetailsSaga';

function* worker(): SagaIterator {
	yield put(async.started(null));
	try {
		const request: UpdateCompanyGeneralConfigurationRequest = yield select(
			prepareRequest
		);
		yield put(updateCompanyGeneralConfiguration(request));
		const { success, failed } = yield race({
			success: take(updateCompanyGeneralConfigurationAsync.done),
			failed: take(updateCompanyGeneralConfigurationAsync.failed)
		});
		if (success) {
			// re-fetch to keep principal integration section up to date
			yield call(initializeGeneralDetailsWorker);
			yield put(
				async.done({
					result: null,
					params: null,
					response: null
				})
			);

			// Initialize form on each successful update
			// in order to keep form state actual
			yield fork(initializeConfigurationGeneralDetailsFormWorker);
		}
		if (failed) {
			throw Error;
		}
	} catch (e) {
		yield put(
			async.failed({
				error: e,
				params: null
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
