import {
	RetrieveFinanceServicesForContextualizationRequest,
	RetrieveFinanceServicesForContextualizationResponse
} from 'services/api/finance/financeServiceTypes';

import actionCreator from '../financeActionCreator';

const RETRIEVE_SERVICES_FOR_CONTEXTUALIZATION =
	'RETRIEVE_SERVICES_FOR_CONTEXTUALIZATION';
export const retrieveFinanceServicesForContextualizationAsync = actionCreator.async<
	RetrieveFinanceServicesForContextualizationRequest,
	RetrieveFinanceServicesForContextualizationResponse,
	Error
>(RETRIEVE_SERVICES_FOR_CONTEXTUALIZATION);

export const retrieveFinanceServicesForContextualization = actionCreator<
	RetrieveFinanceServicesForContextualizationRequest
>(RETRIEVE_SERVICES_FOR_CONTEXTUALIZATION);
