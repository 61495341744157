import { GroupUser } from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export interface GroupUsersState {
	readonly byId: { [id: string]: GroupUser };
	readonly byGroupId: { [groupdId: string]: string[] };
	readonly hasMore: boolean;
	readonly fetchStatuses: { [key: string]: FetchStatus };
}

export const initialState: GroupUsersState = {
	byId: {},
	byGroupId: {},
	hasMore: true,
	fetchStatuses: {}
};
