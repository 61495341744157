import Api from 'services/api';
import {
	retrieveUserStatuses as action,
	retrieveUserStatusesAsync as asyncAction
} from '../actions/retrieveUserStatuses';

import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put } from 'redux-saga/effects';

const apiCall = Api.Users.retrieveUserStatuses;

export function* executor(api: typeof apiCall): SagaIterator {
	const params = undefined;
	yield put(asyncAction.started(params));
	try {
		const response = yield call(api);
		yield put(
			asyncAction.done({
				result: response.data,
				params,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				params,
				error
			})
		);
	}
}

function* worker(): SagaIterator {
	yield call(executor, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
