import React, { lazy } from 'react';
import { Asteriks, PageFooter, ScrollableLayout } from 'components';
import { Col, Form, Input, Row, Layout, Button } from 'components/antd';
import { RecipientSelect } from 'containers';
import UploadAttachment from 'sections/ComposeMessage/components/UploadAttachment/UploadAttachment';
import styles from './ExtendedMessagingContent.module.scss';
import { UserContact } from 'services/api/users/userServiceTypes';
import {
	getRecipientsError,
	getShouldRecipientBeAdded
} from 'sections/ComposeMessage/utils/ComposeMessageUtils';
import validationConfig from 'sections/ComposeMessage/utils/ComposeMessageValidationConfig';
import { RichTextValue } from 'components/RichText/RichTextTypes';
import { ExtendedMessage } from 'services/api/portJobs/portJobsServiceTypes';
import { Justify } from 'components/types';

const RichText = lazy(() => import('components/RichText/RichTextWrapper'));

interface MessageContainerProps {
	message: ExtendedMessage;
	onAddAttachmentStarted: (threadId: string) => void;
	updateRecipient: (
		target: string,
		recipients: UserContact[],
		threadId: string
	) => void;
	updateSubject: (threadId: string, value: string) => void;
	onRemoveAttachment: (threadId: string, id: string) => void;
	onChangeTextEditor: (threadId: string, value: RichTextValue) => void;
	isFormValid: boolean;
	onSubmit: (threadId: string) => void;
	isUploadingAttachment: boolean;
	isMessageSending: boolean;
}

export default function MessageContainer({
	message,
	updateRecipient,
	onAddAttachmentStarted,
	updateSubject,
	onRemoveAttachment,
	onChangeTextEditor,
	isFormValid,
	onSubmit,
	isUploadingAttachment,
	isMessageSending
}: MessageContainerProps) {
	const { Content } = Layout;
	const onChangeTo = (values: UserContact[]) => {
		if (getShouldRecipientBeAdded(values)) {
			updateRecipient('to', values, message.threadId);
		}
	};

	const onChangeCC = (values: UserContact[]) => {
		if (getShouldRecipientBeAdded(values)) {
			updateRecipient('cc', values, message.threadId);
		}
	};

	const onChangeSubject = (event: React.FormEvent<HTMLInputElement>) => {
		const value: string = event.currentTarget.value;
		updateSubject(message.threadId, value);
	};

	const toError = getRecipientsError(message.to);
	const ccError = getRecipientsError(message.cc);

	return (
		<Layout>
			<Content className={styles.content}>
				<ScrollableLayout>
					<div className={styles.messageHeader}>
						<Form layout="horizontal" className={styles.form}>
							<Form.Item>
								<Row>
									<Col xs={12} sm={1}>
										<label className={styles.label}>From</label>
									</Col>
									<Col xs={12} sm={10}>
										<Input
											type="text"
											name="from"
											value={message.from.email}
											disabled
										/>
									</Col>
								</Row>
							</Form.Item>
							<Row>
								<Col xs={12} sm={1}>
									<Asteriks position="right" className={styles.label}>
										To
									</Asteriks>
								</Col>
								<Col xs={12} sm={10}>
									<Form.Item
										validateStatus={toError ? 'error' : 'success'}
										help={toError}
									>
										<RecipientSelect value={message.to} onChange={onChangeTo} />
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col xs={12} sm={1}>
									<label className={styles.label}>Cc</label>
								</Col>
								<Col xs={12} sm={10}>
									<Form.Item
										validateStatus={ccError ? 'error' : 'success'}
										help={ccError}
									>
										<RecipientSelect value={message.cc} onChange={onChangeCC} />
									</Form.Item>
								</Col>
							</Row>
							<Form.Item>
								<Row>
									<Col xs={12} sm={1}>
										<label className={styles.label}>Subject</label>
									</Col>
									<Col xs={12} sm={10}>
										<Input
											type="text"
											name="subject"
											maxLength={validationConfig.subjectMaxLength}
											value={message.subject}
											onChange={onChangeSubject}
										/>
										<UploadAttachment
											attachments={message.attachments}
											onAddAttachmentStarted={() =>
												onAddAttachmentStarted(message.threadId)
											}
											onRemoveAttachment={(id: string) =>
												onRemoveAttachment(message.threadId, id)
											}
										>
											Add Attachment
										</UploadAttachment>
									</Col>
								</Row>
							</Form.Item>
						</Form>
					</div>
					<div>
						<RichText
							content={message.content}
							onChange={(value: RichTextValue) =>
								onChangeTextEditor(message.threadId, value)
							}
							autoFocus={false}
						/>
					</div>
				</ScrollableLayout>
			</Content>
			<PageFooter stretch>
				<Row end="xs">
					<Col xs={3} justify={Justify.END}>
						<Button
							type="primary"
							onClick={() => onSubmit(message.threadId)}
							disabled={!isFormValid || isUploadingAttachment}
							loading={isMessageSending}
						>
							Send Message
						</Button>
					</Col>
				</Row>
			</PageFooter>
		</Layout>
	);
}
