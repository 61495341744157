export const base64ToArrayBuffer = (base64: string) => {
	const binaryString = window.atob(base64);
	const bytes = new Uint8Array(binaryString.length);
	return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

export const createAndDownloadEmlFile = (
	body: Uint8Array,
	filename: string
) => {
	const blob = new Blob([body], { type: 'eml/html' });
	const fileName = `${filename}.eml`;
	if (navigator.msSaveBlob) {
		// IE 10+
		navigator.msSaveBlob(blob, fileName);
	} else {
		const link = document.createElement('a');
		// Browsers that support HTML5 download attribute
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute('href', url);
			link.setAttribute('download', fileName);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};
