import {
	RetrieveStaticDocumentConfigResponse,
	RetrieveRelativeDocumentConfigResponse,
	DocumentWithOriginalFile,
	Document
} from 'services/api/documents/documentsServiceTypes';
import { RetrievePortCallDocumentImageResponse } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { UploadFile } from 'antd/lib/upload/interface';

export enum ProcessingStatus {
	UPLOAD = 'upload',
	SCAN = 'scan',
	PROCESSING = 'processing',
	SPLIT = 'split'
}

export interface DocumentProcessingState {
	showProgress: boolean;
	inProgress: boolean;
	file?: UploadFile;
	info: {
		percent: number;
		hasSplit: boolean;
	};
	status: ProcessingStatus;
	statusTerminatedDescription?: string;
	errorMessage?: string;
	fetchStatus: FetchStatus;
	/**
	 * This property is used to hide the close button in upload process modal.
	 */
	hideClose?: boolean;
}

export interface DocumentsState {
	readonly config: {
		staticByType: {
			[type: string]: RetrieveStaticDocumentConfigResponse;
		};
		relative: RetrieveRelativeDocumentConfigResponse;
	};
	readonly attachments: DocumentWithOriginalFile[]; // List of attachments id's

	readonly byId: { [id: string]: Document };
	readonly documentImages: {
		[id: string]: { [n: number]: RetrievePortCallDocumentImageResponse };
	};
	readonly fetchStatuses: {
		documents: { [id: string]: FetchStatus };
		documentImages: { [id: string]: FetchStatus };
	};

	readonly filesFetchingNumber: number;
	readonly upload: DocumentProcessingState;
}

export const initialState: DocumentsState = {
	config: {
		staticByType: {},
		relative: {
			id: '',
			form: {
				key: '',
				acl: '',
				'x-amz-credential': '',
				'x-amz-algorithm': '',
				'x-amz-date': '',
				'x-amz-signature': '',
				Policy: ''
			}
		}
	},
	attachments: [],
	byId: {},
	documentImages: {},
	fetchStatuses: {
		documents: {},
		documentImages: {}
	},
	filesFetchingNumber: 0,
	upload: {
		showProgress: false,
		inProgress: false,
		info: {
			percent: 0,
			hasSplit: false
		},
		status: ProcessingStatus.UPLOAD,
		fetchStatus: FetchStatus.IDLE,
		hideClose: false
	}
};
