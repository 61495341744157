import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isString } from 'lodash';

import { UserContact } from 'services/api/users/userServiceTypes';
import { LabeledValue } from 'app-types';

import { toLabelValue } from './RecipientSelect.func';
import { Value } from './RecipientSelectTypes';
import { getUserIdSelector } from 'store/auth/selectors';

export interface RecipientSelectContainerData {
	value: Value[];
	onChange?: (value: Value[]) => void;
	currentUserId: string;
}

export interface RecipientSelectContainerProps {
	value?: UserContact[];
	onChange?: (value: UserContact[]) => void;
	children: (data: RecipientSelectContainerData) => React.ReactNode;
}
function RecipientSelectContainer({
	value = [],
	onChange: onChangeProp,
	children
}: RecipientSelectContainerProps) {
	const currentUserId = useSelector(getUserIdSelector);

	const labeledValue = useMemo((): LabeledValue[] => {
		// map keys to fake ids (onChange - we will remove them)
		return value.map(toLabelValue);
	}, [value]);

	const onChange = useCallback(
		(value: Value[]) => {
			if (onChangeProp) {
				const values = value.reduce<UserContact[]>((acc, option) => {
					if (!isString(option.label)) {
						return acc;
					}

					// return result;
					const result: UserContact = {
						email: option.label
					};
					if (option.key) {
						result.id = option.key;
					}
					acc.push(result);
					return acc;
				}, []);

				onChangeProp(values);
			}
		},
		[onChangeProp]
	);

	return (
		<>
			{children({
				value: labeledValue,
				onChange,
				currentUserId
			})}
		</>
	);
}

export default RecipientSelectContainer;
