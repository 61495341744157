import actionCreator from 'store/portJobs/portJobsActionCreator';

export interface RetrieveEditPortJobActionPayload {
	portCallId: string;
	portJobCode: string;
}

const ACTION_NAME = 'RETRIEVE_EDITPORTJOB_DATA';
export const retrieveEditPortJobDataAsync = actionCreator.async<
	RetrieveEditPortJobActionPayload,
	undefined,
	Error
>(ACTION_NAME);
export const retrieveEditPortJobData = actionCreator<
	RetrieveEditPortJobActionPayload
>(ACTION_NAME);
