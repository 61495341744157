import { DeveloperPortalState as State } from '../developerPortalState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { UpdateApiSubscriptionParams as Request } from 'services/api/developerPortal/developerPortalServiceTypes';

export const onUpdateApiSubscription = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	null
>('fetchStatuses', 'updateApiSubscription');
