import {
	EndOfSeaPassageResponse,
	EndOfSeaPassageRequest
} from 'services/api/portCall/portCallServiceTypes';
import actionCreator from 'store/portCall/portCallActionCreator';

const END_OF_SEA_PASSAGE = 'END_OF_SEA_PASSAGE';
const endOfSeaPassageAsync = actionCreator.async<
	EndOfSeaPassageRequest,
	EndOfSeaPassageResponse,
	Error
>(END_OF_SEA_PASSAGE);
export const endOfSeaPassage = actionCreator<EndOfSeaPassageRequest>(
	END_OF_SEA_PASSAGE
);

export const endOfSeaPassageAction = {
	action: endOfSeaPassage,
	async: endOfSeaPassageAsync
};
