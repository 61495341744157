import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components';
import { Error403 } from 'components';
import { PermissionsCheckMode, hasPermissionsConsideringCheckMode } from '..';

interface PermissionRouteLayoutProps extends RouteProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	layout: React.ComponentType<RouteComponentProps<any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: React.ComponentType<RouteComponentProps<any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errorComponent?: React.ComponentType<RouteComponentProps<any>>;
	permissionCode: PermissionCode | PermissionCode[];
	permissionsCheckMode?: PermissionsCheckMode;
}

const PermissionRouteLayout: FC<PermissionRouteLayoutProps> = ({
	layout,
	component,
	errorComponent,
	permissionCode,
	permissionsCheckMode = PermissionsCheckMode.AND,
	render,
	...restProps
}) => {
	const Layout = layout;
	const Component = component;
	const ErrorComponent = errorComponent;
	return (
		<Route
			{...restProps}
			render={routeProps => {
				if (render) {
					return render(routeProps);
				}
				return (
					<Permission permissionCode={permissionCode}>
						{(...hasPermissions) => {
							const hasPermission = hasPermissionsConsideringCheckMode(
								hasPermissions,
								permissionsCheckMode
							);
							return hasPermission ? (
								<Layout {...routeProps}>
									<Component {...routeProps} />
								</Layout>
							) : (
								(ErrorComponent && <ErrorComponent {...routeProps} />) || (
									<Error403 />
								)
							);
						}}
					</Permission>
				);
			}}
		/>
	);
};

export default PermissionRouteLayout;
