import { flow } from 'lodash';
import { DraftOption } from '../draftsTypes';
import {
	appendSuffixToDrafts,
	mergeOptionsWithDrafts,
	sortOptionsByNameAndKeepUnspecifiedAtTheEnd
} from '../draftsUtils';

export const getOptionsWithDrafts = (
	options: DraftOption[],
	drafts: DraftOption[]
): DraftOption[] => {
	return flow(
		appendSuffixToDrafts,
		mergeOptionsWithDrafts(options),
		sortOptionsByNameAndKeepUnspecifiedAtTheEnd
	)(drafts);
};
