import actionCreator from '../companyVesselsActionCreator';
import {
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse
} from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'RETRIEVE_PORTCALL_COMPANY_VESSELS';

export const retrievePortCallCompanyVesselsAsync = actionCreator.async<
	RetrievePortCallCompanyVesselsRequest,
	RetrievePortCallCompanyVesselsResponse,
	Error
>(ACTION_NAME);

export const retrievePortCallCompanyVessels = actionCreator<
	RetrievePortCallCompanyVesselsRequest
>(ACTION_NAME);
