import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';

interface TrProps {
	className?: string;
	onClick?: (idx?: string) => void;
	index?: string;
	flex?: boolean;
}

const TR: FC<TrProps> = ({ onClick, index, className, children, flex }) => {
	const onClickHandler = useCallback(() => {
		if (onClick) {
			onClick(index);
		}
	}, [onClick, index]);

	const ComponentTag = useMemo(() => (flex ? 'div' : 'tr'), [flex]);

	return (
		<ComponentTag
			className={classNames('table-row', className)}
			onClick={onClickHandler}
		>
			{children}
		</ComponentTag>
	);
};

export default TR;
