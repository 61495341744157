import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	SDAReasons,
	RetrieveSDAReasonRequest
} from 'services/api/finance/financeServiceTypes';
import { Success } from 'typescript-fsa';

export const RetrieveSDAReason = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	RetrieveSDAReasonRequest,
	SDAReasons[]
>('fetchStatuses', 'retrieveSDAReason', {
	doneReducer: (
		state: FinanceState,
		action: Success<RetrieveSDAReasonRequest, SDAReasons[]>
	): FinanceState => ({
		...state,
		sdaReasons: action.response?.data
	})
});
