import ActAs from './ActAs/ActAs';
import ActAsSwitcher from './ActAs/ActAsSwitcher';

import AutocompleteCompany from './Autocomplete/AutocompleteCompanies/AutocompleteCompany';
import AutocompletePortLPA from './Autocomplete/AutocompleteCompanies/AutocompletePortLPA';
import AutocompletePrincipal from './Autocomplete/AutocompleteCompanies/AutocompletePrincipal';
import AutocompleteMainPrincipal from './Autocomplete/AutocompleteCompanies/AutocompleteMainPrincipal';
import AutocompleteLPA from './Autocomplete/AutocompleteCompanies/AutocompleteLPA';

import AutocompletePorts from './Autocomplete/AutocompletePorts/AutocompletePorts';
import AutocompleteVessels from './Autocomplete/AutocompleteVessels/AutocompleteVessels';
import AutocompleteApprovalGroups from './Autocomplete/AutocompleteGroups/AutocompleteApprovalGroups';

import PermissionContext from './Permission/PermissionContext';
import EntityPermissionFetcher from './Permission/EntityPermissionFetcher';
import EntityPermissionTooltipWrapper from './Permission/EntityPermissionTooltipWrapper/EntityPermissionTooltipWrapper';
import FormattedTime from './FormattedTime/FormattedTime';
import ApmTracker from './ApmTracker/ApmTracker';
import MatomoTracker from './MatomoTracker/MatomoTracker';
import AppNotifications from './Notifications/Notifications';
import Permission from './Permission/Permission';

import UploadDocumentButton from './UploadDocumentButton/UploadDocumentButton';
import UploadDocumentModal from './UploadDocumentModal/UploadDocumentModal';
import UploadProcessDocumentProvider from './UploadProcessDocumentProvider/UploadProcessDocumentProvider';
import ContextBars from './ContextBar/ContextBars';

import RecipientSelect from './RecipientSelect/RecipientSelect';

export {
	ActAs,
	ActAsSwitcher,
	/**
	 * Search (onlyActive) companies
	 */
	AutocompleteCompany,
	/**
	 * Search companies with OrganisationType.Principal belonging to a Specified Port (id)
	 */
	AutocompletePortLPA,
	/**
	 * Search companies with OrganisationType.Principal
	 */
	AutocompletePrincipal,
	/**
	 * Search companies with OrganisationType.MAIN_PRINCIPAL
	 */
	AutocompleteMainPrincipal,
	/**
	 * Search companies with OrganisationType.LPA or OrganisationType.ISS_LPA
	 */
	AutocompleteLPA,
	/**
	 * Search Ports
	 */
	AutocompletePorts,
	/**
	 * Search Vessels
	 */
	AutocompleteVessels,
	/**
	 * Search Approval Groups
	 */
	AutocompleteApprovalGroups,
	EntityPermissionFetcher,
	EntityPermissionTooltipWrapper,
	FormattedTime,
	ApmTracker,
	MatomoTracker,
	AppNotifications,
	Permission,
	PermissionContext,
	// Upload
	UploadDocumentButton,
	UploadDocumentModal,
	UploadProcessDocumentProvider,
	ContextBars,
	RecipientSelect
};
