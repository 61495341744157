import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveNewFundingDataAsync as async } from '../actions/retrieveNewFundingData';
import {
	RetrieveNewFundingDataRequest as Request,
	RetrieveNewFundingDataResponse as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';

const api = Api.FinanceFundings.retrieveNewFundingData;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async
});
