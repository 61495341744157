import React, { Component } from 'react';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { ENABLE_OPERATIONS_CONFIRMATION_MODAL } from './EnableOperationsFormTypes';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import { connect } from 'react-redux';

interface ModalProps {
	isVisible: boolean;
	closeModal: typeof closeModal;
}

class EnableOperationsClosePopUpModal extends Component<ModalProps> {
	closeModal = () => {
		this.props.closeModal(ENABLE_OPERATIONS_CONFIRMATION_MODAL);
		this.props.closeModal('enable-operations-modal');
	};

	hideConfirmationModal = () => {
		this.props.closeModal(ENABLE_OPERATIONS_CONFIRMATION_MODAL);
	};

	render() {
		const { isVisible } = this.props;
		return (
			<Modal
				visible={isVisible}
				cancelText={'No'}
				okText={'Yes'}
				onOk={this.closeModal}
				onCancel={this.hideConfirmationModal}
			>
				All entered information will be lost. Are you sure you want to cancel
				the process?
			</Modal>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isVisible: isModalVisible(state, ENABLE_OPERATIONS_CONFIRMATION_MODAL)
	}),
	{
		closeModal
	}
)(EnableOperationsClosePopUpModal);
