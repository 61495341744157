import actionCreator from '../companyVesselsActionCreator';
import {
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'RETRIEVE_EDIT_COMPANY_VESSEL_BY_ID';

export const retrieveEditCompanyVesselByIdDataAsync = actionCreator.async<
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselResponse,
	Error
>(ACTION_NAME);

export const retrieveEditCompanyVesselByIdData = actionCreator<
	RetrieveCompanyVesselByIdRequest
>(ACTION_NAME);
