import actionCreator from '../groupUsersActionCreator';

import {
	RetrieveGroupUsersResponse,
	RetrieveGroupUsersRequest
} from 'services/api/groups/groupsServiceTypes';

const ACTION_NAME = 'RETRIEVE_GROUP_USERS';

export const retrieveGroupUsersAsync = actionCreator.async<
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	Error
>(ACTION_NAME);

export const retrieveGroupUsers = actionCreator<RetrieveGroupUsersRequest>(
	ACTION_NAME
);
