import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { Table } from 'components/antd';
import { getPortJobColumns } from './Columns/PortJobColumns';
import styles from './PortJobList.module.scss';
import { getActiveWizard } from 'store/wizardForm/wizardFormSelectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { PortCallAlertsMap } from 'app-types';
import { getUserType } from 'store/auth/selectors';
import { getHasDupilcatePortCalls } from 'store/portcalls/portCallsSelectors';

interface PortJobListProps {
	portCallId: PortCall['id'];
	portId: string;
	portJobs: PortJob[];
	portCallAlertsMap?: PortCallAlertsMap;
	onPortJobClick?: (link: string) => void;
	checkPortJobCanView?: boolean;
	optionValue?: string;
	onOptionChange?: (portJobCode: string, portCallId: string) => void;
}

function PortJobList(props: PortJobListProps) {
	const {
		portJobs,
		portCallId,
		checkPortJobCanView,
		portCallAlertsMap,
		onPortJobClick,
		onOptionChange
	} = props;
	const activeWizard = useSelector(getActiveWizard);
	const userType = useSelector(getUserType);
	const hasPortCallDuplicates = useSelector(getHasDupilcatePortCalls);
	const columns = useMemo(
		() =>
			getPortJobColumns(
				portCallId,
				activeWizard,
				userType,
				hasPortCallDuplicates,
				onPortJobClick,
				portCallAlertsMap,
				checkPortJobCanView,
				onOptionChange
			),
		[
			portCallId,
			activeWizard,
			onPortJobClick,
			portCallAlertsMap,
			checkPortJobCanView,
			userType,
			hasPortCallDuplicates,
			onOptionChange
		]
	);
	return (
		<Table
			className={styles.root}
			dataSource={portJobs}
			columns={columns}
			pagination={false}
			bordered={false}
			normalizeColumns
			locale={{
				emptyText: `This Port Call doesn't have any Job yet`
			}}
		/>
	);
}

export default PortJobList;
