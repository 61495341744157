import React, { FC } from 'react';
import { EntityPermissionFetcher } from 'containers';
import {
	EntityPermission,
	EntityPermissionComposeId
} from 'services/api/permissions/permissionsServiceTypes';

interface SettingsButtonPopoverContentProps extends EntityPermissionComposeId {
	content: (permissions: EntityPermission) => React.ReactNode;
}

const SettingsButtonPopoverContent: FC<SettingsButtonPopoverContentProps> = ({
	entityType,
	entityKey,
	content
}) => {
	return (
		<EntityPermissionFetcher
			entityType={entityType}
			entityKey={entityKey}
			showLoader={false}
			skipIfHasData
		>
			{content}
		</EntityPermissionFetcher>
	);
};

export default SettingsButtonPopoverContent;
