import { first } from 'lodash';
import { FormErrors } from 'redux-form';
import runValidations, { required } from 'utils/validations';
import {
	FormData,
	FormDataErrors
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { PageFormProps } from './Form';
import OperationsConfig from '../Operations/OperationsConfig';

const validate = (
	values: FormData,
	props: PageFormProps
): FormErrors<FormData> => {
	const errors: FormDataErrors = {
		operationType: first(runValidations(values.operationType, [required]))
	};
	const { operationTypeCode } = props;
	const operationConfig =
		operationTypeCode && OperationsConfig[operationTypeCode];
	// eslint-disable-next-line @typescript-eslint/unbound-method
	if (!operationConfig || !operationConfig.validate) {
		return errors;
	}
	return {
		...errors,

		// `validate` of separate operation related component isn't called when appropriate.
		// For ex. when textarea is focused in Generic operation form
		...operationConfig.validate(values)
	} as FormErrors<FormData>;
};

export default validate;
