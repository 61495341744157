import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveMessageAttachmentAsync,
	retrieveMessagesMailboxesAsync,
	resetMessagesMailboxes,
	retrieveMessageHistoryAsync
} from './actions';
import {
	retrieveContactAction,
	updateContactAction,
	resetCurrentContactAction,
	onRetrieveMessageAttachmentStarted,
	onRetrieveMessageAttachmentSuccess,
	onRetrieveMessageAttachmentFailed,
	onRetrieveMessagesMailboxes,
	onResetMessagesMailboxes,
	onRetrieveHistoryStart,
	onRetrieveHistorySuccess,
	onRetrieveHistoryFailure,
	downloadEmlFileAction
} from './reducers';
import { initialState } from './messagesState';

export default reducerWithInitialState(initialState)
	.case(resetCurrentContactAction.action, resetCurrentContactAction.reducer)
	.case(retrieveContactAction.async.started, retrieveContactAction.started)
	.case(retrieveContactAction.async.done, retrieveContactAction.success)
	.case(retrieveContactAction.async.failed, retrieveContactAction.failed)
	.case(updateContactAction.async.started, updateContactAction.started)
	.case(updateContactAction.async.done, updateContactAction.success)
	.case(updateContactAction.async.failed, updateContactAction.failed)
	.case(
		retrieveMessageAttachmentAsync.started,
		onRetrieveMessageAttachmentStarted
	)
	.case(retrieveMessageAttachmentAsync.done, onRetrieveMessageAttachmentSuccess)
	.case(
		retrieveMessageAttachmentAsync.failed,
		onRetrieveMessageAttachmentFailed
	)
	// Retrieve Mailboxes
	.case(
		retrieveMessagesMailboxesAsync.started,
		onRetrieveMessagesMailboxes.started
	)
	.case(retrieveMessagesMailboxesAsync.done, onRetrieveMessagesMailboxes.done)
	.case(
		retrieveMessagesMailboxesAsync.failed,
		onRetrieveMessagesMailboxes.failed
	)
	.case(resetMessagesMailboxes, onResetMessagesMailboxes)
	.case(retrieveMessageHistoryAsync.started, onRetrieveHistoryStart)
	.case(retrieveMessageHistoryAsync.done, onRetrieveHistorySuccess)
	.case(retrieveMessageHistoryAsync.failed, onRetrieveHistoryFailure)
	.case(downloadEmlFileAction.async.started, downloadEmlFileAction.started)
	.case(downloadEmlFileAction.async.done, downloadEmlFileAction.success)
	.case(downloadEmlFileAction.async.failed, downloadEmlFileAction.failed);
