import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	RetrieveJobThreadsRequest,
	RetrieveJobThreadsResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { keyBy, uniq } from 'lodash';
import { ThreadsState } from '../../threads/threadsState';
import { FetchStatus } from 'services/api/apiTypes';
export const onRetrievePortJobsThreadsStarted = (
	state: PortJobState
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.PENDING
	}
});
export const onRetrievePortJobsThreadsFailed = (
	state: PortJobState
): PortJobState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.FAILURE
	}
});
/**
 * Update the PortJobState
 */
export const onSavePortJobThreadIds = (
	state: PortJobState,
	action: Success<RetrieveJobThreadsRequest, RetrieveJobThreadsResponse>
): PortJobState => {
	const { elements } = action.result;
	const allIds = state.context.messages.allIds;
	return {
		...state,
		context: {
			...state.context,
			messages: {
				...state.context.messages,
				allIds: uniq([...allIds, ...elements.map(thread => thread.id)])
			}
		},
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
};
/**
 * Update the ThreadsState
 */
export const onRetrievePortJobsThreadsStatusStarted = (
	state: ThreadsState
): ThreadsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.PENDING
	}
});
export const onRetrievePortJobsThreadsStatusFailed = (
	state: ThreadsState
): ThreadsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.FAILURE
	}
});
export const onSavePortJobThreads = (
	state: ThreadsState,
	action: Success<RetrieveJobThreadsRequest, RetrieveJobThreadsResponse>
): ThreadsState => {
	const { elements } = action.result;

	return {
		...state,
		byId: {
			...state.byId,
			...keyBy(elements, item => item.id)
		},
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		},
		hasMore: action.params.limit === elements.length
	};
};

/**
 * Update the PortJobState
 */
export const onResetPortJobThreadIds = (state: PortJobState): PortJobState => ({
	...state,
	context: {
		...state.context,
		messages: {
			...state.context.messages,
			allIds: []
		}
	}
});
/**
 * Update the ThreadsState
 */
export const onResetPortJobThreads = (state: ThreadsState): ThreadsState => ({
	...state,
	hasMore: true,
	byId: {},
	fetchStatuses: {
		...state.fetchStatuses,
		all: FetchStatus.IDLE
	},
	error: false
});
