import { OpticConfigState } from '../opticConfigState';
import {
	GenericOpticConfig,
	GenericOpticConfig as Response
} from 'services/api/opticConfiguration/opticConfigServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';
import { kpiGroupsIndex } from 'sections/Administration/OpticConfiguration/KPIAndAlertsTab/constants';
import { groupBy } from 'lodash';

const getPreparedSectionsData = (data: GenericOpticConfig) => {
	const sectionsArray = data.map(kpi => ({
		...kpi,
		...kpiGroupsIndex[kpi.name]
	}));
	return groupBy(sectionsArray, 'groupName');
};

export function onRetrieveKpiActiveSectionsStarted(
	state: OpticConfigState
): OpticConfigState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveKpiActiveSection: FetchStatus.PENDING
		}
	};
}

export function onRetrieveKpiActiveSectionsSuccess(
	state: OpticConfigState,
	{ result }: Success<void, Response>
): OpticConfigState {
	const sections = getPreparedSectionsData(result);
	return {
		...state,
		sections: { ...sections },
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveKpiActiveSection: FetchStatus.SUCCESS
		}
	};
}

export function onRetrieveKpiAlertsSectionsFailed(
	state: OpticConfigState
): OpticConfigState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveKpiActiveSection: FetchStatus.FAILURE
		}
	};
}
