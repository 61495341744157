import actionCreator from '../companyVesselsActionCreator';
import {
	DeleteCompanyVesselRequest,
	DeleteCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'DELETE_COMPANY_VESEL';

export const deleteCompanyVesselAsync = actionCreator.async<
	DeleteCompanyVesselRequest,
	DeleteCompanyVesselResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Vessel'
	}
});

export const deleteCompanyVessel = actionCreator<DeleteCompanyVesselRequest>(
	ACTION_NAME
);
