import React from 'react';
import { connect } from 'react-redux';

import { ScrollableLayout, Flex, Text } from 'components';
import TableauHeader from './TableauHeader';
import TableauInfo from './TableauInfo';
import styles from './Tableau.module.scss';

import { getDashboardTableauCompanyId } from 'store/dashboard/selectors';

import { Justify } from 'components/types';
import { DashboardTableau } from 'services/api/dashboard/dashboardServiceTypes';
import { AppState } from 'store-types';

interface TableauProps {
	companyId: string;
	tab: DashboardTableau;
}
const Tableau = ({ companyId, tab }: TableauProps) => (
	<ScrollableLayout>
		<TableauHeader tab={tab} />
		<ScrollableLayout className={styles.root}>
			{companyId ? (
				<TableauInfo
					key={companyId}
					companyId={companyId}
					tableauCode={tab.code}
				/>
			) : (
				<Flex fit justify={Justify.CENTER} align="center">
					<Text color="default">
						Please select Main Principal to see {tab.name}
					</Text>
				</Flex>
			)}
		</ScrollableLayout>
	</ScrollableLayout>
);

export default connect((state: AppState) => ({
	companyId: getDashboardTableauCompanyId(state)
}))(Tableau);
