import React, { useCallback } from 'react';
import { Button, Col, Row, Select } from 'components/antd';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { retrieveUsersFromGroup } from 'store/thread';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import { useAssignGroupForm } from './AssignToModal.hooks';
import { ThreadsAssignToFormFields } from './AssignToModalFormTypes';
import {
	isThreadsGroupsFetchStatusPending,
	getMainPrincipalThreadGroups
} from 'store/threads/selectors';
import { Entity } from 'app-types';
import { isEmpty } from 'lodash';
import { getThreadsAvailableGroupsOptionsSelector } from 'store/thread/selectors';
import { AppState } from 'store-types';

const {
	THREAD_PERMISSION_GROUPS,
	CURRENT_GROUP_ID_TO_ADD,
	ASSIGNED_GROUP_ID,
	ASSIGNED_USER_ID
} = ThreadsAssignToFormFields;

const AddGroupFields = () => {
	const { formValues, changeField } = useAssignGroupForm();
	const dispatch = useDispatch();
	const isLoading = useSelector(isThreadsGroupsFetchStatusPending);
	const groups: Entity[] = useSelector(
		getMainPrincipalThreadGroups,
		shallowEqual
	);
	const availableGroups: Entity[] = useSelector(
		(state: AppState) =>
			getThreadsAvailableGroupsOptionsSelector(state, groups),
		shallowEqual
	);

	const onGroupAdd = useCallback(() => {
		const selectedGroup = formValues[CURRENT_GROUP_ID_TO_ADD];
		if (!selectedGroup) {
			return;
		}

		const newGroup = {
			...availableGroups.find(({ id }) => selectedGroup === id),
			options: []
		} as PermissionGroupWithUsers;
		const newGroups = [...formValues[THREAD_PERMISSION_GROUPS], newGroup];
		dispatch(retrieveUsersFromGroup({ id: selectedGroup }));
		changeField(THREAD_PERMISSION_GROUPS, newGroups);
		changeField(CURRENT_GROUP_ID_TO_ADD, null);
		changeField(ASSIGNED_GROUP_ID, selectedGroup);
		changeField(ASSIGNED_USER_ID, null);
	}, [availableGroups, dispatch, formValues, changeField]);

	const disabled = !formValues[CURRENT_GROUP_ID_TO_ADD];

	if (isEmpty(availableGroups) && !isLoading) {
		return <p>Sorry, there are no Groups to assign to this Thread</p>;
	}
	return (
		<Row>
			<Button
				type="primary"
				transparent
				icon="plus"
				disabled={disabled}
				title={
					disabled ? 'Please select a group from the list first' : undefined
				}
				onClick={onGroupAdd}
			/>
			<Col xs={6}>
				<Select.ReduxFormItem
					name={CURRENT_GROUP_ID_TO_ADD}
					placeholder="-- Select Group --"
					getPopupContainer={() => document.body}
					isLoading={isLoading}
				>
					{availableGroups.map(group => (
						<Select.Option key={group.id} value={group.id}>
							{group.name}
						</Select.Option>
					))}
				</Select.ReduxFormItem>
			</Col>
		</Row>
	);
};

export default AddGroupFields;
