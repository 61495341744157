import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ConfigurationServicesState } from '../configurationServicesState';
import {
	UpdatePrincipalServiceMappingRequest,
	UpdatePrincipalServiceMappingResponse
} from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';
import { includes, mapValues } from 'lodash';

export const doneReducer = (
	state: ConfigurationServicesState,
	{
		params,
		result
	}: Success<
		UpdatePrincipalServiceMappingRequest,
		UpdatePrincipalServiceMappingResponse
	>
): ConfigurationServicesState => ({
	...state,
	principalServices: mapValues(state.principalServices, pService => {
		const issServices = result.filter(iService =>
			includes(params.issServices, iService.code)
		);
		return {
			...pService,
			services: includes(
				params.principalServices,
				pService.principalServiceCode
			)
				? [...pService.services, ...issServices]
				: pService.services
		};
	})
});

export const onUpdatePrincipalServicesMapping = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	UpdatePrincipalServiceMappingRequest,
	UpdatePrincipalServiceMappingResponse
>('fetchStatuses', 'updatePrincipalServicesMapping', { doneReducer });
