import React from 'react';
import { Select } from 'components/antd';
import MaybeTooltip from 'components/antd/Tooltip/MaybeTooltip';

import { LabeledValueItem } from 'app-types';
import { ActAsBaseProps } from './actAsTypes';

type ActAsOptionsProps = ActAsBaseProps & {
	isValueSubmitted: boolean;
	disabledMessage?: string;
	onSelect: (value: LabeledValueItem) => void;
};

const ActAsOptions: React.FC<ActAsOptionsProps> = ({
	value,
	isValueSubmitted,
	onSelect,
	disabledMessage,
	options
}) => {
	return (
		<div>
			Select LPA
			<Select
				labelInValue
				value={value || undefined}
				onSelect={onSelect}
				disabled={isValueSubmitted}
			>
				{options.map(option => (
					<Select.Option key={option.id} disabled={option.disabled}>
						<MaybeTooltip
							show={Boolean(option.disabled && disabledMessage)}
							title={disabledMessage}
							placement="bottom"
							trigger="hover"
							getPopupContainer={() => document.body}
						>
							{option.name}
						</MaybeTooltip>
					</Select.Option>
				))}
			</Select>
		</div>
	);
};

export default ActAsOptions;
