export enum TextAlign {
	RIGHT = 'right',
	LEFT = 'left',
	CENTER = 'center'
}

export enum FontStyle {
	NORMAL = 'normal',
	BOLD = 'bold',
	ITALIC = 'italic',
	ITALIC_BOLD = 'italic bold'
}

export enum FontDecoration {
	UNDERLINE = 'underline'
}
