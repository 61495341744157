import React from 'react';
import { Title, Content, UploadDraggerBox, FileItem } from 'components';
import { Row, Col, Select } from 'components/antd';
import { connect } from 'react-redux';
import {
	updateBankAccount,
	uploadLOBDDone,
	retrieveLOBD,
	deleteLOBD,
	downloadLOBD
} from 'store/masterdata/bankAccounts/actions';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import {
	UploadCustomRequest,
	UploadType
} from 'services/api/documents/documentsServiceTypes';
import { uploadAndProcessDocument } from 'store/documents/actions';
import { DataFetcher } from 'utils/components';
import {
	getLOBDFileItemProps,
	getIsBankAccountUpdating,
	getIsLOBDLoading,
	getIsLOBDDownloading
} from 'store/masterdata/bankAccounts/selectors';
import { AppState } from 'store-types';
import { FileItemProps } from 'app-types';

interface LobdProps {
	bank: BankAccount;
	// state
	LOBDFile: FileItemProps;
	LOBDLoading: boolean;
	LOBDDownloading: boolean;
	bankUpdating: boolean;
	// actions
	updateBankAccount: typeof updateBankAccount;
	uploadAndProcessDocument: typeof uploadAndProcessDocument;
	retrieveLOBD: typeof retrieveLOBD;
	deleteLOBD: typeof deleteLOBD;
	downloadLOBD: typeof downloadLOBD;
}

enum isValid {
	NO,
	YES
}

class LOBD extends React.Component<LobdProps> {
	updateValid = (value: string) => {
		this.props.updateBankAccount({
			bankDetailId: this.props.bank.id,
			lobd: Boolean(value),
			lobdDocumentId: this.props.bank.lobdDocumentId
		});
	};

	uploadAndProcess = (request: UploadCustomRequest) => {
		this.props.uploadAndProcessDocument({
			type: UploadType.LOBD,
			request,
			onDone: uploadLOBDDone,
			showProgress: true
		});
	};

	retrieveLOBD = () => {
		this.props.retrieveLOBD({
			id: this.props.bank.id
		});
	};

	deleteLOBD = () => {
		this.props.deleteLOBD({ id: this.props.bank.id });
	};

	downloadLOBD = () => {
		if (this.props.bank.lobdDocumentId) {
			this.props.downloadLOBD({
				documentId: this.props.bank.id,
				letterOfBankDetailDocumentId: this.props.bank.lobdDocumentId
			});
		}
	};

	render() {
		const {
			bank,
			LOBDFile,
			LOBDLoading,
			bankUpdating,
			LOBDDownloading
		} = this.props;
		const defaultValue = [bank.lobd ? isValid.YES : isValid.NO];
		return (
			<>
				<Title>LOBD</Title>
				<Content>
					<Row>
						<Col xs={2}>
							Validated
							<Row>
								<Col xs={12} lg={6}>
									<Select
										onChange={this.updateValid}
										value={defaultValue}
										disabled={!bank.lobdDocumentId || bankUpdating}
									>
										<Select.Option value={isValid.YES}>Yes</Select.Option>
										<Select.Option value={isValid.NO}>No</Select.Option>
									</Select>
								</Col>
							</Row>
						</Col>
						<Col xs={10}>
							{!bank.lobdDocumentId ? (
								<UploadDraggerBox
									isUploading={false}
									defaultUpload
									uploadAndProcess={this.uploadAndProcess}
									type={UploadType.LOBD}
								/>
							) : (
								<DataFetcher
									dispatch={this.retrieveLOBD}
									isLoading={LOBDLoading}
								>
									{() => (
										<Row>
											<Col xs={6} lg={3}>
												<b>DOCUMENT</b>
												<FileItem
													file={LOBDFile}
													onDelete={this.deleteLOBD}
													onClick={this.downloadLOBD}
													iconColor="standard"
													isLoading={LOBDDownloading}
												/>
											</Col>
										</Row>
									)}
								</DataFetcher>
							)}
						</Col>
					</Row>
				</Content>
			</>
		);
	}
}

export default connect(
	(state: AppState) => ({
		LOBDFile: getLOBDFileItemProps(state),
		LOBDLoading: getIsLOBDLoading(state),
		LOBDDownloading: getIsLOBDDownloading(state),
		bankUpdating: getIsBankAccountUpdating(state)
	}),
	{
		updateBankAccount,
		uploadAndProcessDocument,
		retrieveLOBD,
		deleteLOBD,
		downloadLOBD
	}
)(LOBD);
