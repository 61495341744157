import * as React from 'react';
import { Autocomplete } from 'components/antd';
import { AutocompleteProps } from 'components/antd/Autocomplete/Autocomplete';
import { onSelectCompany, onSearchEntity } from './AutocompleteTypes';

export interface AutocompleteEntityProps extends AutocompleteProps {
	name: string;
	label: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	params?: {};
	onFocus?: () => void;
	onBlur?: () => void;
	onSearch?: onSearchEntity;
	onChange?: AutocompleteProps['onChange'];
	cache?: boolean;
	help?: boolean;
	allowClear?: boolean;
	shouldSearchAllOptionsOnFocus?: boolean;
	minLength?: number;
	getPopupContainer?: AutocompleteProps['getPopupContainer'];
	disabledKeys?: AutocompleteProps['disabledKeys'];
	dropdownClassName?: string;
	afterFocusClick?: boolean;
}

interface AutocompleteCustomProps extends AutocompleteEntityProps {
	onSelect?: onSelectCompany;
	onSearch: onSearchEntity;
}

/**
 * Generic container for Autocomplete.${entity} component
 */
const AutocompleteEntity = (props: AutocompleteCustomProps) => {
	const { params, onSearch, ...selectProps } = props;
	return (
		<Autocomplete.ReduxFormItem
			cache={false}
			onSearch={onSearch}
			{...selectProps}
		/>
	);
};

export default AutocompleteEntity;
