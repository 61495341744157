import { GroupsState, initialState } from '../groupsState';
export function onResetGroup(state: GroupsState): GroupsState {
	return {
		...state,
		byId: initialState.byId,
		context: {
			...state.context,
			openedGroup: null
		},
		fetchStatuses: {
			...state.fetchStatuses,
			initializeEditGroup: initialState.fetchStatuses.initializeEditGroup
		}
	};
}
