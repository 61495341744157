import React from 'react';
import { Gap, Title, Text, Flex } from 'components';
import { VesselDetaisSection } from './types';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import styles from './VesselDetailsSections.module.scss';
import VesselDetailsColumn from './VesselDetailsColumn';
import { Justify } from 'components/types';

interface VesselDetailsSectionsProps {
	sections: VesselDetaisSection[];
	vesselDetails: VesselDetails;
}
const VesselDetailsSections = ({
	sections,
	vesselDetails: { attributes }
}: VesselDetailsSectionsProps) => (
	<>
		{sections.map((section, index, all) => (
			<React.Fragment key={section.sectionTitle}>
				<Gap left="lg" isBlock outside={false}>
					<Gap left isBlock outside={false}>
						<Title.Subject>
							<Text upper>{section.sectionTitle}</Text>
						</Title.Subject>
						<Flex justify={Justify.START} className={styles.sectionContent}>
							{section.columns.map((column, ind) => (
								<VesselDetailsColumn
									key={`${section.sectionTitle}-${ind}`}
									colSize={3}
									attributes={column.attributes}
									rowData={attributes}
									rowIndex={ind}
								/>
							))}
						</Flex>
					</Gap>
				</Gap>
				{index + 1 !== all.length && <hr className={styles.division} />}
			</React.Fragment>
		))}
	</>
);

export default VesselDetailsSections;
