import {
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings
} from 'services/api/companies/companiesServiceTypes';

import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_PRINCIPAL_INTEGRATION_SETTINGS';

export const retrievePrincipalIntegrationSettingsAsync = actionCreator.async<
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings,
	Error
>(ACTION_NAME);

export const retrievePrincipalIntegrationSettings = actionCreator<
	RetrievePrincipalIntegrationSettingsRequest
>(ACTION_NAME);
