import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	DeletePortJobRequest,
	DeletePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export const onDeletePortJobStarted = (state: PortJobState): PortJobState => ({
	...state,
	error: undefined,
	fetchStatuses: {
		...state.fetchStatuses,
		deleting: FetchStatus.PENDING
	}
});

export const onDeletePortJobSuccess = (
	state: PortJobState,
	action: Success<DeletePortJobRequest, DeletePortJobResponse>
): PortJobState => {
	const jobCodeToDelete = action.params.jobCode;
	const newById = Object.keys(state.byId).reduce((acc, key) => {
		if (state.byId[key].code !== jobCodeToDelete) {
			return { ...acc, [key]: state.byId[key] };
		}
		return acc;
	}, {});
	return {
		...state,
		byId: newById,
		fetchStatuses: { ...state.fetchStatuses, deleting: FetchStatus.SUCCESS }
	};
};

export const onDeletePortJobFailed = (state: PortJobState) => ({
	...state,
	error: new Error('Cannot delete portJob'),
	fetchStatuses: {
		...state.fetchStatuses,
		deleting: FetchStatus.FAILURE
	}
});
