import actionCreator from '../actionCreator';
import {
	RetrieveVesselProgrammeProcessResponse,
	RetrieveVesselProgrammeRequest
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

export interface CheckVesselProgrammeForUpdatesPayload {
	isChanged: boolean;
}

const ACTION_NAME = 'REFRESH_VP_PROCESS';

const CHECK_VP_FOR_UPDATES = 'CHECK_VP_FOR_UPDATES';
export const refreshVPProcessAsync = actionCreator.async<
	RetrieveVesselProgrammeRequest,
	null,
	Error
>(ACTION_NAME);

export const checkVPForUpdatesAsync = actionCreator.async<
	RetrieveVesselProgrammeRequest,
	CheckVesselProgrammeForUpdatesPayload,
	Error
>(CHECK_VP_FOR_UPDATES);

export const refreshVPProcess = actionCreator<RetrieveVesselProgrammeRequest>(
	ACTION_NAME
);
export const checkVPForUpdates = actionCreator<RetrieveVesselProgrammeRequest>(
	CHECK_VP_FOR_UPDATES
);

export const abortProcessVP = actionCreator('ABORT_PROCESS_VP');
export const updateVPHash = actionCreator<string>('UPDATE_HASH_VP');
export const updateSaveVPRestrictions = actionCreator<boolean>(
	'UPDATE_SAVE_VP_RESTRICTIONS'
);

export const updateVPprocessing = actionCreator<
	RetrieveVesselProgrammeProcessResponse
>('UPDATE_VP_PROCESSING');
