import { ConfigurationBenchmarkData, RetrieveConfigurationBenchmarkRequest } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANY_BENCHMARK';
export const retrieveCompanyBenchmarkAsync = actionCreator.async<
    RetrieveConfigurationBenchmarkRequest,
	ConfigurationBenchmarkData,
	Error
>(ACTION_NAME);
export const retrieveCompanyBenchmark = actionCreator<
    RetrieveConfigurationBenchmarkRequest
>(ACTION_NAME);
