import React from 'react';
import { Text } from 'components';
import { Checkbox, Row, Col, Badge, Icon } from 'components/antd';

import { EntitiesSelectMap } from 'app-types';
import { Justify } from 'components/types';
import { FormField } from 'store/form/financeContextualization/financeContextualizationFormData';
import { FinanceService } from 'services/api/finance/financeServiceTypes';

interface CategoryServiceBaseProps {
	field: string;
	currency: string;
	servicesSelection: EntitiesSelectMap<number>;
	disabled?: boolean;
}

interface CategoryServiceProps extends CategoryServiceBaseProps {
	service: FinanceService;
}
const CategoryService: React.SFC<CategoryServiceProps> = ({
	field,
	service,
	currency,
	servicesSelection,
	disabled
}) => {
	const total = servicesSelection[service.id];
	return (
		<Row>
			<Col xs={7}>
				<Checkbox.ReduxFormItem
					name={`${field}.${FormField.SERVICES}.${service.id}`}
					disabled={disabled}
				>
					{service.name}
					<br />
					<Text color="default">{service.poiDescription}</Text>
				</Checkbox.ReduxFormItem>
			</Col>
			<Col xs={3} justify={Justify.END}>
				<Text weight="bold">
					{service.amount} {currency}
				</Text>
			</Col>
			<Col sm={2} justify={Justify.END}>
				{total > 0 && (
					<div>
						<Badge count={total}>
							<Icon type={total === 1 ? 'document' : 'document-stack'} />
						</Badge>
					</div>
				)}
			</Col>
		</Row>
	);
};

interface CategoryServicesProps extends CategoryServiceBaseProps {
	services: FinanceService[];
}
const CategoryServices: React.SFC<CategoryServicesProps> = ({
	field,
	currency,
	services,
	servicesSelection,
	disabled
}) => (
	<ul>
		{services.map(service => (
			<li key={service.id}>
				<CategoryService
					service={service}
					field={field}
					currency={currency}
					servicesSelection={servicesSelection}
					disabled={disabled}
				/>
			</li>
		))}
	</ul>
);

export default CategoryServices;
