import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { isEqual, once } from 'lodash';
import { DEFAULT_REDUX_FORM_CONFIG } from 'app-constants';
import {
	retrieveSupplierInvoiceContextualization,
	retrieveSupplierInvoiceContextualizationForUpdate,
	retrieveSupplierInvoiceContextualizationForAddViaPostCredit
} from 'store/supplierInvoice/actions';
import { retrieveFinanceCurrencies } from 'store/finance/actions';
import {
	FormData,
	SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID,
	FormField
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import {
	getContextualizationFormValues,
	getSupplierInvoiceContextualizationInitialValues,
	getIsAnyServiceSelectedInPage
} from 'store/form/supplierInvoiceContextualization/selectors/contextualizationFormSelectors';
import {
	getIsInvoiceDetailsFormSectionVisible,
	getServicesControlTotal
} from 'store/supplierInvoice/selectors/supplierInvoicesSelectors';
import validate, { areInvoiceTotalAndControlTotalEqual } from './validate';
import SupplierDetailsFormSection from './SupplierDetails/SupplierDetailsFormSection';
import InvoiceDetailsFormSection from './InvoiceDetails/InvoiceDetailsFormSection';
import { getActiveSupplierInvoiceId } from 'store/supplierInvoice/selectors';
import { AppState } from 'store-types';
import createDocumentViewerForm, {
	ContextualizationFormFormDataBase
} from 'sections/DocumentViewer/DocumentViewerForm';

interface ContextualizationFormBaseProps {
	pages: number;
	activePage: number;
	onValidChange: (valid: boolean) => void;
	isCreditCheckboxDisabled?: boolean;
	isTriggeredViaPostCredit?: boolean;
	onPageValidChange: (valid: boolean) => void;
	onResetWizardForm: () => void;
	// mapStateToProps
	activeSupplierInvoiceId: string;
	formValues: FormData;
	initialValues: Partial<FormData>;
	isInvoiceDetailsFormSectionVisible: boolean;
	isAnyServiceSelected: boolean;
	controlTotal: string;
	// mapDispatchToProps
	retrieveSupplierInvoiceContextualization: typeof retrieveSupplierInvoiceContextualization;
	retrieveSupplierInvoiceContextualizationForUpdate: typeof retrieveSupplierInvoiceContextualizationForUpdate;
	retrieveSupplierInvoiceContextualizationForAddViaPostCredit: typeof retrieveSupplierInvoiceContextualizationForAddViaPostCredit;
	retrieveFinanceCurrencies: typeof retrieveFinanceCurrencies;
}

export interface ContextualizationFormProps
	extends InjectedFormProps<FormData, ContextualizationFormBaseProps>,
		ContextualizationFormBaseProps {}

const DocumentViewerForm = createDocumentViewerForm<
	FormData & ContextualizationFormFormDataBase,
	ContextualizationFormProps
>({
	form: SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID,
	destroyOnUnmount: true
});

class ContextualizationForm extends React.Component<
	ContextualizationFormProps
> {
	componentDidMount() {
		this.props.retrieveFinanceCurrencies();
	}

	componentDidUpdate(prevProps: ContextualizationFormProps) {
		const {
			formValues,
			valid,
			onValidChange,
			isAnyServiceSelected,
			onPageValidChange,
			onResetWizardForm
		} = this.props;
		const { formValues: prevFormValues } = prevProps;
		onPageValidChange(isAnyServiceSelected);
		if (valid !== prevProps.valid) {
			onValidChange(valid);
		}
		if (
			!isEqual(
				formValues[FormField.MAIN_PRINCIPAL_COMPANY],
				prevFormValues[FormField.MAIN_PRINCIPAL_COMPANY]
			) ||
			!isEqual(
				formValues[FormField.CURRENCY_CODE],
				prevFormValues[FormField.CURRENCY_CODE]
			) ||
			!isEqual(
				formValues[FormField.SUPPLIER_COMPANY],
				prevFormValues[FormField.SUPPLIER_COMPANY]
			)
		) {
			this.retrievePortJobs();
			onResetWizardForm();
		}
		if (
			(formValues[FormField.TOTAL] || this.props.controlTotal) &&
			!areInvoiceTotalAndControlTotalEqual(
				formValues[FormField.TOTAL],
				this.props.controlTotal
			)
		) {
			this.triggerInvoiceTotalTouchOnce();
		}
	}

	triggerInvoiceTotalTouchOnce = once(() => this.props.touch(FormField.TOTAL));

	retrievePortJobs = (isFromRefresh = false) => {
		const {
			isTriggeredViaPostCredit,
			activeSupplierInvoiceId,
			formValues,
			retrieveSupplierInvoiceContextualization: retrieveContextualization,
			retrieveSupplierInvoiceContextualizationForUpdate: retrieveContextualizationForUpdate,
			retrieveSupplierInvoiceContextualizationForAddViaPostCredit: retrieveContextualizationForAddViaPostCredit,
			isInvoiceDetailsFormSectionVisible,
			onResetWizardForm
		} = this.props;

		if (isInvoiceDetailsFormSectionVisible) {
			const {
				mainPrincipalCompany,
				supplierCompany,
				currencyCode
			} = formValues;
			const supplierId = supplierCompany?.key;

			const params = {
				mainPrincipalId: mainPrincipalCompany.key,
				supplierId,
				currencyCode: currencyCode.key
			};
			if (isTriggeredViaPostCredit) {
				retrieveContextualizationForAddViaPostCredit();
				onResetWizardForm();
			} else if (activeSupplierInvoiceId) {
				retrieveContextualizationForUpdate({
					...params,
					supplierInvoiceId: activeSupplierInvoiceId,
					isFromRefresh: isFromRefresh
				});
			} else {
				retrieveContextualization(params);
			}
		}
	};

	render() {
		const { pages, activePage, onValidChange, formValues } = this.props;
		return (
			<form>
				<SupplierDetailsFormSection
					isCreditCheckboxDisabled={this.props.isCreditCheckboxDisabled}
					isTriggeredViaPostCredit={this.props.isTriggeredViaPostCredit}
				/>
				<DocumentViewerForm
					field={FormField.PAGES}
					pages={pages}
					activePage={activePage}
					onValidChange={onValidChange}
				>
					{this.props.isInvoiceDetailsFormSectionVisible && (
						<InvoiceDetailsFormSection
							retrievePortJobs={this.retrievePortJobs}
							change={this.props.change}
							formValues={formValues}
							activePage={activePage}
						/>
					)}
				</DocumentViewerForm>
			</form>
		);
	}
}

const ContextualizationReduxForm = reduxForm<
	FormData,
	ContextualizationFormBaseProps
>({
	...DEFAULT_REDUX_FORM_CONFIG,
	validate,
	form: SUPPLIER_INVOICE_CONTEXTUALIZATION_FORM_ID
})(ContextualizationForm);

export default connect(
	(
		state: AppState,
		{
			isTriggeredViaPostCredit,
			activePage
		}: Pick<
			ContextualizationFormBaseProps,
			'isTriggeredViaPostCredit' | 'activePage'
		>
	) => {
		return {
			initialValues: getSupplierInvoiceContextualizationInitialValues(state, {
				isTriggeredViaPostCredit
			}),
			activeSupplierInvoiceId: getActiveSupplierInvoiceId(state),
			formValues: getContextualizationFormValues(state),
			isInvoiceDetailsFormSectionVisible: getIsInvoiceDetailsFormSectionVisible(
				state
			),
			controlTotal: getServicesControlTotal(state),
			isAnyServiceSelected: getIsAnyServiceSelectedInPage(state, activePage)
		};
	},
	{
		retrieveSupplierInvoiceContextualization,
		retrieveSupplierInvoiceContextualizationForUpdate,
		retrieveSupplierInvoiceContextualizationForAddViaPostCredit,
		retrieveFinanceCurrencies
	}
)(ContextualizationReduxForm);
