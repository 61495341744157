import {
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../configurationServicesActionCreator';

/**
 * Retrieve principal services for main principal
 */
const ACTION_NAME = 'RETRIEVE_PRINCIPAL_SERVICES';

export const retrievePrincipalServicesAsync = actionCreator.async<
	RetrievePrincipalServicesRequest,
	RetrievePrincipalServicesResponse,
	Error
>(ACTION_NAME);

export const retrievePrincipalServices = actionCreator<
	RetrievePrincipalServicesRequest
>(ACTION_NAME);

export const resetPrincipalServices = actionCreator('RESET_PRINCIPAL_SERVICES');
