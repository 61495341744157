import Api from 'services/api';
import {
	RetrieveTemplateRenderRequest,
	TemplateRendered
} from 'services/api/templates/templateServiceTypes';

import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveTemplateRenderAsync } from 'store/templates/actions/retrieveTemplateRender';

const apiCall = Api.Templates.retrieveTemplateRender;
const async = retrieveTemplateRenderAsync;

export const retrieveTemplateRenderWatcher = makeTakeLatestWatcher<
	RetrieveTemplateRenderRequest,
	TemplateRendered,
	Error
>({
	api: apiCall,
	async
});
