import { Action } from 'typescript-fsa';
import { select, put, takeLatest, all, call } from 'redux-saga/effects';

import { updatePrincipalServicesMappingAsync as async } from '../actions';
import {
	UpdatePrincipalServiceMappingRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import Api from 'services/api';
import { getPrincipalServicesFilteredByCodes } from '../selectors';
import { getServices } from 'store/masterdata/services/selectors';

export function* worker({
	payload
}: Action<UpdatePrincipalServiceMappingRequest>) {
	yield put(async.started(payload));
	const pServices: PrincipalService[] = yield select(
		getPrincipalServicesFilteredByCodes,
		payload.principalServices
	);
	try {
		const responses = yield all(
			pServices.map(
				({
					id,
					name,
					principalServiceCode,
					principalServiceCategory,
					services
				}) =>
					call(Api.Services.updatePrincipalService, {
						id,
						data: {
							name,
							principalServiceCode,
							principalServiceCategoryId: principalServiceCategory.id,
							serviceCodes: [
								...services.map(service => service.code),
								...payload.issServices
							]
						}
					})
			)
		);
		const services = yield select(getServices);
		yield put(
			async.done({
				params: payload,
				result: services,
				// if we got to this point, it means all of the responses were successfully and there is at least one
				// there is no difference which of them we pass to `response` field as long as it has general information on the request
				response: responses[0]
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: payload
			})
		);
	}
}

export default function*() {
	yield takeLatest(async.type, worker);
}
