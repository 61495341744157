import { BreadcrumbItem, NavigationState } from '../navigationState';
export function onSetBreadcrumb(
	state: NavigationState,
	payload: BreadcrumbItem[]
) {
	return {
		...state,
		breadcrumbs: payload
	};
}
