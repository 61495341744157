import { Success } from 'typescript-fsa';
import { ConfigurationServicesState } from '../configurationServicesState';
import { PrincipalService } from 'services/api/services/servicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrievePrincipalServiceSuccess = (
	state: ConfigurationServicesState,
	{ result }: Success<string, PrincipalService>
) => ({
	...state,
	principalServices: {
		...state.principalServices,
		[result.id]: result
	}
});

export const onRetrievePrincipalService = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	string,
	PrincipalService
>('fetchStatuses', 'retrievePrincipalService', {
	doneReducer: onRetrievePrincipalServiceSuccess
});
