import { LabeledValue } from 'app-types';

export const ADD_COMPANY_DRAFT_MODAL_FORM = 'AddCompanyDraftModalForm';
export const ADD_COMPANY_DRAFT_MODAL = 'AddCompanyDraftModal';
export const ADD_POI_DRAFT_MODAL = 'AddPOIDraftModal';
export const ADD_POI_DRAFT_FORM = 'AddPOIDraftForm';
export const ADD_APPOINTER_DRAFT_MODAL = 'AddAppointerDraftModal';
export const ADD_APPOINTER_DRAFT_MODAL_FORM = 'AddAppointerDraftForm';

export enum AddCompanyDraftFormNames {
	NAME = 'name',
	CITY = 'city',
	COUNTRY = 'countryCode',
	EMAIL = 'email',
	PHONE = 'telephone',
	URL = 'url',
	COMMENT = 'note'
}

export interface BaseAddCompanyDraftFormData {
	name: string;
	city: string;
	countryCode: LabeledValue<string>;
	email: string;
	telephone: string;
	url: string;
	note: string;
}

export interface AddCompanyDraftFormData {
	name: string;
	city: string;
	countryCode: string;
	email: string;
	telephone: string;
	url: string;
	note: string;
}

export enum AddAppointerDraftFormNames {
	NAME = 'name',
	CITY = 'city',
	COUNTRY = 'countryCode',
	EMAIL = 'email',
	PHONE = 'telephone',
	TYPE = 'companyOrganizationTypeCode',
	COMMENT = 'note'
}

export interface BaseAddAppointerDraftFormData
	extends BaseAddCompanyDraftFormData {
	companyOrganizationTypeCode: string;
}

export interface AddAppointerDraftFormDate {
	name: string;
	city: string;
	countryCode: string;
	email: string;
	telephone: string;
	companyOrganizationTypeCode: string;
	note: string;
}
