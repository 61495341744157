import * as React from 'react';
import styles from './JobDetails.module.scss';
import { AppointmentAttachment } from 'services/api/portJobs/portJobsServiceTypes';
import { Collapse, Icon } from 'components/antd';
import { Link } from 'components';

export interface ColumnData {
	title: string;
	value: string | number;
	isFormattedValue?: boolean;
}

interface AppointmentAttachmentsProps {
	attachments: AppointmentAttachment[];
}

const AppointmentAttachments = ({
	attachments
}: AppointmentAttachmentsProps) => (
	<Collapse className={styles.instructionsCollapse}>
		<Collapse.Panel header={'Appointment Attachments'} key="1">
			<ul className={styles.linkList}>
				{attachments.map(attachment => (
					<li key={attachment.documentId}>
						<Link
							href={attachment.downloadLink}
							key={attachment.documentId}
							type="black"
							download
						>
							<span>{attachment.fileName}</span>
							<Icon type="file-pdf" offset="left" />
						</Link>
					</li>
				))}
			</ul>
		</Collapse.Panel>
	</Collapse>
);

export default AppointmentAttachments;
