import { RetrieveMessageDataRequest } from 'services/api/threads/threadsServiceTypes';
import actionCreator from '../threadActionCreator';

const ACTIONA_NAME = 'RETRIEVE_MESSAGE_DATA';

export const retrieveMessageDataAsync = actionCreator.async<
	RetrieveMessageDataRequest,
	null,
	Error
>(ACTIONA_NAME);

export const retrieveMessageData = actionCreator<RetrieveMessageDataRequest>(
	ACTIONA_NAME
);
