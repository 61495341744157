import client from 'services/client';
import { SearchResultCollection } from 'services/api/apiTypes';
import { SearchAnchorageRequest } from './anchorageServiceTypes';

const search = ({ port, searchTerm }: SearchAnchorageRequest) =>
	client.get<SearchResultCollection>(`./ports/${port}/anchorages`, {
		params: { searchTerm }
	});

export default {
	search
};
