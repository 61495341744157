import {
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse
} from 'services/api/threads/threadsServiceTypes';
import actionCreator from '../../threadActionCreator';

const RETRIEVE_AVAILABLE_GROUPS = 'RETRIEVE_AVAILABLE_GROUPS';

export const retrieveAvailableThreadGroupsAsync = actionCreator.async<
	RetrieveAvailableThreadGroupsRequest,
	RetrieveAvailableThreadGroupsResponse,
	Error
>(RETRIEVE_AVAILABLE_GROUPS);

export const retrieveAvailableThreadGroups = actionCreator<
	RetrieveAvailableThreadGroupsRequest
>(RETRIEVE_AVAILABLE_GROUPS);
