import actionCreator from '../portJobsActionCreator';
import {
	PortJob,
	RetrieveJobParams
} from 'services/api/portJobs/portJobsServiceTypes';
import { AxiosGeneralError } from 'services/api/apiErrorTypes';

const ACTION_NAME = 'RETRIEVE_PORTJOB_LOOPED';

export const retrievePortJobForCycleAsync = actionCreator.async<
	RetrieveJobParams,
	PortJob,
	AxiosGeneralError
>(ACTION_NAME);

export const retrievePortJobForCycle = actionCreator<RetrieveJobParams>(
	ACTION_NAME
);
