import * as React from 'react';
import classNames from 'classnames';
import styles from './PageFooter.module.scss';

export interface PageFooterProps {
	className?: string;
	stretch?: boolean;
	borderTop?: boolean;
	type?: 'primary';
}

export const PageFooter: React.FC<PageFooterProps> = ({
	className,
	stretch,
	borderTop,
	type,
	children
}) => {
	return (
		<footer
			className={classNames(styles.root, className, {
				[styles.stretch]: stretch,
				[styles.borderTop]: borderTop,
				[styles.primary]: type === 'primary'
			})}
		>
			{children}
		</footer>
	);
};

export default PageFooter;
