import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveCommoditiesAsync,
	retrieveMainCommoditiesAsync,
	resetMainCommodities
} from './actions';
import {
	onRetrieveCommoditiesStarted,
	onRetrieveCommoditiesSuccess,
	onRetrieveCommoditiesFailed,
	onRetrieveMainCommodities,
	onResetMainCommodities
} from './reducers';

import { initialState } from './commoditiesState';

export default reducerWithInitialState(initialState)
	.case(retrieveCommoditiesAsync.started, onRetrieveCommoditiesStarted)
	.case(retrieveCommoditiesAsync.done, onRetrieveCommoditiesSuccess)
	.case(retrieveCommoditiesAsync.failed, onRetrieveCommoditiesFailed)
	.case(retrieveMainCommoditiesAsync.started, onRetrieveMainCommodities.started)
	.case(retrieveMainCommoditiesAsync.done, onRetrieveMainCommodities.done)
	.case(retrieveMainCommoditiesAsync.failed, onRetrieveMainCommodities.failed)
	.case(resetMainCommodities, onResetMainCommodities);
