import { isBoolean, capitalize } from 'lodash';
import { GapPosition, GapSize } from './Gap';

export const createGetClassNameMapByPosition = (styles: object) => (
	position: keyof GapPosition,
	positionSize?: GapSize
) => {
	if (!positionSize) {
		return {};
	}
	const result = {
		[styles[`${position}Default`]]: isBoolean(positionSize)
	};
	['xs', 'sm', 'md', 'lg'].forEach(size => {
		const stylesKey = `${position}${capitalize(size)}`;
		const className = styles[stylesKey];
		if (className) {
			result[className] = size === positionSize;
		}
	});
	return result;
};
