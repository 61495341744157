import Api from 'services/api';
import {
	retrieveFinance,
	updateFixedRateExchangeAsync as asyncAction
} from '../actions';
import {
	UpdateFixedRateExchangePayload,
	JobFinanceExpandParam
} from 'services/api/finance/financeServiceTypes';

import { select, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { EXCHANGE_RATE_MODAL } from 'sections/PortCall/Finance/Sections/Summary/DaSectionPartials/ExchangeRate/ExchangeRateModal';
import { closeModal } from 'store/modals/actions';

const api = Api.Finance.setFixedRate;

export default makeTakeLatestWatcher<
	UpdateFixedRateExchangePayload,
	null,
	Error
>({
	api,
	async: asyncAction,
	*getApiParams(payload) {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		const request = {
			portCallId,
			jobCode,
			...payload
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		yield put(closeModal(EXCHANGE_RATE_MODAL));
		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
			})
		);
	}
});
