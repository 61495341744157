import React from 'react';
import { Button } from 'components/antd';
import { Option } from 'components/AutocompleteSearch/Components/index';
interface ShowMoreProps {
	onClick: (groupType: string) => void;
	show: boolean;
	groupType: string;
}
class ShowMore extends React.PureComponent<ShowMoreProps> {
	onShowMoreClick = () => {
		this.props.onClick(this.props.groupType);
	};
	render() {
		if (!this.props.show) {
			return null;
		}
		return (
			<Option highlighted={false}>
				<Button
					type="primary"
					transparent
					size="small"
					onClick={this.onShowMoreClick}
				>
					Show More
				</Button>
			</Option>
		);
	}
}

export default ShowMore;
