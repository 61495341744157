import { VesselDetaisSection } from './types';

export const VESSEL_INFO_SECTIONS: VesselDetaisSection[] = [
	{
		sectionTitle: 'General Details',
		columns: [
			{
				attributes: [
					{ name: 'IMO', path: 'IMO' },
					{ name: 'Vessel Name', path: 'Name' },
					{ name: 'Year of Build', path: 'YearOfBuild' },
					{ name: 'Flag', path: 'Flag.Code' }
				]
			},
			{
				attributes: [
					{ name: 'Call Sign', path: 'CallSign' },
					{ name: 'MMSI', path: 'MMSI' },
					{ name: 'Port of Registry', path: 'PortOfRegistry' },
					{ name: 'Gross', path: 'Gross' }
				]
			},
			{
				attributes: [
					{ name: 'Net', path: 'Net' },
					{ name: 'DWT', path: 'Deadweight' },
					{ name: 'Gen Type', path: 'GenType' },
					{ name: 'Sub Type', path: 'SubType' }
				]
			},
			{
				attributes: [
					{ name: 'Vessel Type', path: 'VesselType.Name' },
					{ name: 'ISS Vessel Type Name', path: 'VesselType.Name' },
					{ name: 'ISS Vessel Type Code', path: 'VesselType.Code' },
					{ name: 'Status', path: 'Status' }
				]
			}
		]
	},
	{
		sectionTitle: 'Vessel Dimensions',
		columns: [
			{
				attributes: [
					{ name: 'Breadth Extreme', path: 'Dimensions.BreadthExtreme' },
					{ name: 'Breadth Moulded', path: 'Dimensions.BreadthMoulded' },
					{ name: 'Depth', path: 'Dimensions.Depth' },
					{ name: 'GT Less SBT', path: 'GTLessSBT' }
				]
			},
			{
				attributes: [
					{ name: 'Draft', path: 'Dimensions.Draft' },
					{ name: 'LBP', path: 'Dimensions.LBP' },
					{ name: 'LOA', path: 'Dimensions.LOA' }
				]
			},
			{
				attributes: [
					{ name: 'LRG', path: 'Dimensions.LRG' },
					{ name: 'Manifold to Bow', path: 'Dimensions.ManifoldToBow' },
					{
						name: 'Parallel Body Length',
						path: 'Dimensions.ParallelBodyLength'
					}
				]
			},
			{
				attributes: [
					{
						name: 'Manifold Deck To Centre',
						path: 'Dimensions.MenifoldDeckToCentre'
					},
					{ name: 'KTM', path: 'Dimensions.KTM' },
					{ name: 'Formula DWT', path: 'Dimensions.FormulaDWT' }
				]
			}
		]
	},
	{
		sectionTitle: 'Vessel Capacities',
		columns: [
			{
				attributes: [
					{ name: 'Bale Capacity', path: 'Capacities.BaleCapacity' },
					{ name: 'Ballast Capacity', path: 'Capacities.BallastCapacity' },
					{ name: 'Car Capacity', path: 'Capacities.CarCapacity' }
				]
			},
			{
				attributes: [
					{ name: 'Trailer Capacity', path: 'Capacities.TrailerCapacity' },
					{ name: 'Gas Capacity', path: 'Capacities.GasCapacity' },
					{ name: 'Grain Capacity', path: 'Capacities.GrainCapacity' }
				]
			},
			{
				attributes: [
					{ name: 'Liquid Capacity', path: 'Capacities.LiquidCapacity' },
					{ name: 'Ore Capacity Tonnes', path: 'Capacities.OreCapacityTonnes' },
					{ name: 'Pump Capacity Cubic', path: 'Capacities.PumpCapacityCubic' }
				]
			},
			{
				attributes: [
					{
						name: 'Pump Capacity Tonnes',
						path: 'Capacities.PumpCapacityTonnes'
					},
					{ name: 'Slop Tank Capacity', path: 'Capacities.SlopTankCapacity' },
					{ name: 'Teu Capacity', path: 'Capacities.TeuCapacity' }
				]
			}
		]
	},
	{
		sectionTitle: 'Vessel Tonnage',
		columns: [
			{
				attributes: [
					{ name: 'Displacement', path: 'Tonnage.Displacement' },
					{ name: 'Panama NRT', path: 'Tonnage.PanamaNRT' },
					{ name: 'Suez NRT', path: 'Tonnage.SuezNRT' }
				]
			}
		]
	},
	{
		sectionTitle: 'Vessel Design, Superstructure and Misc',
		columns: [
			{
				attributes: [
					{ name: 'Bulbous Bow Ind', path: 'Specification.BulbousBowInd' },
					{ name: 'No Decks', path: 'Specification.NoDecks' },
					{ name: 'Thruster Ind', path: 'Specification.ThrusterIND' }
				]
			},
			{
				attributes: [
					{ name: 'Total Banks', path: 'Specification.TotalBanks' },
					{ name: 'Speed', path: 'Specification.Speed' },
					{ name: 'SpeedType', path: 'Specification.SpeedType' }
				]
			},
			{
				attributes: [
					{ name: 'No Passengers', path: 'Specification.NoPassengers' },
					{ name: 'No Berths', path: 'Specification.NoBerths' },
					{ name: 'Lane Length', path: 'Specification.LaneLength' }
				]
			},
			{
				attributes: [
					{ name: 'Lane Width', path: 'Specification.LaneWidth' },
					{ name: 'Gearless Ind', path: 'Specification.GearlessInd' }
				]
			}
		]
	},
	{
		sectionTitle: 'Vessel Name History',
		columns: [
			{
				attributes: [
					{ name: 'Name', path: 'NameHistory[i].Name' },
					{ name: 'Current Ind', path: 'NameHistory[i].CurrentInd' },
					{ name: 'Start Date', path: 'NameHistory[i].StartDate' }
				]
			},
			{
				attributes: [
					{ name: 'Start Qualifier', path: 'NameHistory[i].StartQualifier' },
					{ name: 'End Date', path: 'NameHistory[i].EndDate' },
					{ name: 'End Qualifier', path: 'NameHistory[i].EndQualifier' }
				]
			}
		]
	},
	{
		sectionTitle: 'Tanker Details',
		columns: [
			{
				attributes: [
					{ name: 'SPM Ind', path: 'Tanker.SPMIND' },
					{ name: 'Bow Load Discharge', path: 'Tanker.BowLoadDischarge' },
					{ name: 'Stern Load Discharge', path: 'Tanker.SternLoadDischarge' },
					{ name: 'Manifold Type', path: 'Tanker.ManifoldType' }
				]
			},
			{
				attributes: [
					{ name: 'Manifold Distance', path: 'Tanker.ManifoldDistance' },
					{ name: 'No Grades', path: 'Tanker.NoGrades' },
					{ name: 'No Lines', path: 'Tanker.NoLines' },
					{ name: 'SBT Ind', path: 'Tanker.SBTIND' }
				]
			},
			{
				attributes: [
					{
						name: 'No Stainless Steel Tanks',
						path: 'Tanker.NoStainlessSteelTanks'
					},
					{ name: 'Total Pumps', path: 'Tanker.TotalPumps' },
					{ name: 'COW Ind', path: 'Tanker.TotalPumps' },
					{ name: 'Flash Point 60 Ind', path: 'Tanker.FlashPoint60Ind' }
				]
			},
			{
				attributes: [
					{ name: 'Gas Min Pressure', path: 'Tanker.GasMinPressure' },
					{ name: 'Gas Max Pressure', path: 'Tanker.GasMaxPressure' }
				]
			}
		]
	},
	{
		sectionTitle: 'Cargo Handling Details',
		columns: [
			{
				attributes: [
					{
						name: 'Lifting Gear Type Code',
						path: 'LiftingGear[i].LiftingGearTypeCode'
					},
					{
						name: 'Lifting Gear Type Name',
						path: 'LiftingGear[i].LiftingGearTypeName'
					},
					{
						name: 'Number Of Lifting Gear',
						path: 'LiftingGear[i].NumberOfLiftingGear'
					},
					{ name: 'Safe Working Load', path: 'LiftingGear[i].SafeWorkingLoad' }
				]
			},
			{
				attributes: [
					{
						name: 'Lifting Gear Type Code',
						path: 'LiftingGear[i].LiftingGearTypeCode'
					},
					{
						name: 'Lifting Gear Type Name',
						path: 'LiftingGear[i].LiftingGearTypeName'
					},
					{
						name: 'Number Of Lifting Gear',
						path: 'LiftingGear[i].NumberOfLiftingGear'
					},
					{ name: 'Safe Working Load', path: 'LiftingGear[i].SafeWorkingLoad' }
				]
			},
			{
				attributes: [
					{
						name: 'Lifting Gear Type Code',
						path: 'LiftingGear[i].LiftingGearTypeCode'
					},
					{
						name: 'Lifting Gear Type Name',
						path: 'LiftingGear[i].LiftingGearTypeName'
					},
					{
						name: 'Number Of Lifting Gear',
						path: 'LiftingGear[i].NumberOfLiftingGear'
					},
					{ name: 'Safe Working Load', path: 'LiftingGear[i].SafeWorkingLoad' }
				]
			},
			{
				attributes: [
					{
						name: 'Lifting Gear Type Code',
						path: 'LiftingGear[i].LiftingGearTypeCode'
					},
					{
						name: 'Lifting Gear Type Name',
						path: 'LiftingGear[i].LiftingGearTypeName'
					},
					{
						name: 'Number Of Lifting Gear',
						path: 'LiftingGear[i].NumberOfLiftingGear'
					},
					{ name: 'Safe Working Load', path: 'LiftingGear[i].SafeWorkingLoad' }
				]
			}
		]
	},
	{
		sectionTitle: 'Ownership Details',
		columns: [
			{
				attributes: [
					{ name: 'Relationship Type', path: 'Owners[i].RelationType' },
					{ name: 'Company Name', path: 'Owners[i].CompanyName' },
					{ name: 'Start Date', path: 'Owners[i].StartDate' }
				]
			},
			{
				attributes: [
					{ name: 'Start Qualifier', path: 'Owners[i].StartQualifier' },
					{ name: 'No Hatches', path: 'Owners[i].NoHatches' },
					{ name: 'No Holds', path: 'Owners[i].NoHolds' }
				]
			}
		]
	}
];
