import { Action } from 'typescript-fsa';
import { call, select, takeLatest } from 'redux-saga/effects';
import { navigateTo } from 'utils';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { UpdateSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { FormData } from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import { getContextualizationFormValues } from 'store/form/supplierInvoiceContextualization/selectors';
import Api from 'services/api';
import { PATHS } from 'sections/App/RouteParams';
import { updateSupplierInvoiceAsync } from '../../actions';
import { prepareUpdateRequest } from './prepareUpdateRequest';
import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';

export const executor = makeDefaultExecutor<
	UpdateSupplierInvoiceRequest,
	null,
	Error
>({
	api: Api.SupplierInvoice.updateSupplierInvoice,
	async: updateSupplierInvoiceAsync,
	*onSuccess() {
		yield call(navigateTo, `/${PATHS.supplierinvoices}`);
	}
});

export const worker = function*(action: Action<UpdateSupplierInvoiceRequest>) {
	const values: FormData = yield select(getContextualizationFormValues);
	const wizardForm = yield select(getContextualizationWizardForm);
	yield call(executor, prepareUpdateRequest(values, action, wizardForm));
};

export default function*() {
	yield takeLatest(updateSupplierInvoiceAsync.type, worker);
}
