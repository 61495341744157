import {
	RetrieveMailboxAvailableGroupRequest,
	RetrieveMailboxAvailableGroupResponse
} from 'services/api/mailboxes/mailboxesServiceTypes';

import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_MAILBOX_AVAILABLE_GROUP = 'RETRIEVE_MAILBOX_AVAILABLE_GROUP';

export const retrieveMailboxAvailableGroupsAsync = actionCreator.async<
	RetrieveMailboxAvailableGroupRequest,
	RetrieveMailboxAvailableGroupResponse
>(RETRIEVE_MAILBOX_AVAILABLE_GROUP);

export const retrieveMailboxAvailableGroups = actionCreator<
	RetrieveMailboxAvailableGroupRequest
>(RETRIEVE_MAILBOX_AVAILABLE_GROUP);
