import {
	AddDAOutResponse,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

type AddDaOutParams = { serviceId: PortJobService['id'] };
const ACTION_NAME = 'ADD_DA_OUT';
export const addDAOutAsync = actionCreator.async<
	AddDaOutParams,
	AddDAOutResponse,
	Error
>(ACTION_NAME);

export const addDAOut = actionCreator<AddDaOutParams>(ACTION_NAME);
