import React from 'react';
import { Flex } from 'components';
import { Button } from 'components/antd';
import { Justify } from 'components/types';
import DocumentAsideFooterPagination, {
	DocumentViewerAsidePaginationProps
} from './DocumentAsideFooterPagination';
import {
	DocumentViewerAndAsideCommonProps,
	PaginationState
} from '../DocumentViewerTypes';

export interface DocumentViewerAsideFooterProps
	extends DocumentViewerAndAsideCommonProps {
	onCancel?: () => void;
	portCallId: string;
}

const DocumentViewerAsideFooter: React.SFC<
	DocumentViewerAsideFooterProps & Partial<DocumentViewerAsidePaginationProps>
> = ({ pagination = PaginationState.NONE, ...props }) => {
	const cancelButtonProps = {
		children: 'Cancel',
		...props.cancelButtonProps
	};
	const okButtonProps = {
		children: 'Done',
		...props.okButtonProps
	};

	return (
		<Flex justify={Justify.BETWEEN}>
			<Button
				type="primary"
				transparent
				onClick={props.onCancel}
				{...cancelButtonProps}
			/>

			{pagination !== PaginationState.NONE && (
				<DocumentAsideFooterPagination
					{...(props as DocumentViewerAsidePaginationProps)}
					pagination={pagination}
				/>
			)}

			<Button
				type="primary"
				onClick={props.onFinish}
				loading={props.loading}
				disabled={!props.canSubmit}
				{...okButtonProps}
			/>
		</Flex>
	);
};

export default DocumentViewerAsideFooter;
