import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCurrentUserOfISSAdminGroupType } from 'store/auth/selectors';
import {
	getCompaniesForUserSelector,
	getGroupTypes,
	isFetchingCompaniesSelector,
	getIsCompanyFromUserType
} from 'store/companies/selectors';
import { getValues } from 'store/form/selectors';

import { retrieveCompaniesForUser } from 'store/companies';
import { retrieveGroupTypes } from 'store/groups/actions/retrieveGroupTypes';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { AppState } from 'store-types';
import { CreateGroupFormData } from '../GroupsModalsTypes';
import { usePrevious } from 'utils/hooks';

interface UseCreateGroupFormProps {
	form: string;
}
export function useCreateGroupForm({ form }: UseCreateGroupFormProps) {
	const dispatch = useDispatch();

	const formValues = useSelector((state: AppState) =>
		getValues<CreateGroupFormData>(state, form)
	);
	const previousCompanyId = usePrevious(formValues.companyId);

	const isCompaniesLoading = useSelector(isFetchingCompaniesSelector);
	const companies = useSelector(getCompaniesForUserSelector);
	const isCurrentUserOfISSAdminGroupType = useSelector(
		getIsCurrentUserOfISSAdminGroupType
	);
	const isSelectedCompanyIss = useSelector((state: AppState) =>
		getIsCompanyFromUserType(state, formValues.companyId, OrganisationType.ISS)
	);
	const groupTypes = useSelector(getGroupTypes);

	const refreshGroupTypesList = useCallback(() => {
		dispatch(
			retrieveGroupTypes({
				organisationType: isSelectedCompanyIss ? 'ISS' : 'Hub'
			})
		);
	}, [dispatch, isSelectedCompanyIss]);

	const initialValues = useMemo(() => {
		if (!companies.length) {
			return;
		}
		const issCompany = companies.find(
			company =>
				!!company.organisationTypes.find(
					type => type.organisationTypeCode === OrganisationType.ISS
				)
		);
		const companyId =
			isCurrentUserOfISSAdminGroupType && issCompany
				? issCompany.id
				: companies[0].id;

		return {
			companyId
		};
	}, [isCurrentUserOfISSAdminGroupType, companies]);

	useEffect(() => {
		if (isSelectedCompanyIss && previousCompanyId !== formValues.companyId) {
			refreshGroupTypesList();
		}
	}, [
		refreshGroupTypesList,
		isSelectedCompanyIss,
		previousCompanyId,
		formValues.companyId
	]);

	useEffect(() => {
		dispatch(retrieveCompaniesForUser({ limit: 100 }));
		// is used for mount/unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isCompaniesLoading,
		initialValues,
		companies,
		groupTypes,
		isSelectedCompanyIss,
		isCurrentUserOfISSAdminGroupType
	};
}
