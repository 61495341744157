import Api from 'services/api';
import { select, put, call, all, fork, takeLatest } from 'redux-saga/effects';
import { updateFinanceStatusAsync as async } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import {
	UpdateFinanceStatusRequest,
	UpdateFinanceStatusPayload
} from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';

const api = Api.Finance.updateFinanceStatus;

function* executor({
	action,
	comment
}: UpdateFinanceStatusPayload): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const request: UpdateFinanceStatusRequest = {
		portCallId,
		jobCode,
		action,
		comment
	};
	yield put(async.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			async.done({
				result: null,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
}

function* worker({
	payload
}: Action<UpdateFinanceStatusRequest>): SagaIterator {
	yield call(executor, payload);
}

function* watcher(): SagaIterator {
	yield takeLatest(async.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
