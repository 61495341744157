import history from 'services/history';
import queryString from 'query-string';
import { isNil, omitBy, isString, isArray, intersection, last } from 'lodash';
import { SelectOptionProps } from 'components/antd/Select/Select';
import { LabeledValue } from 'app-types';
import { FileExtension } from 'app-constants';
import MockAdapter from 'axios-mock-adapter/types';

/**
 * Navigation
 *
 * @param {string} pathname - An url to go to
 * @param {object} queryParams - Url query parameters
 * @param {object} state - Url query parameters
 */

interface NavigateToQuery {
	query?: {};
	state?: {};
	shouldStayInNewTab?: boolean;
}

export function navigateTo(
	pathname: string,
	params: NavigateToQuery = {
		query: {},
		state: {},
		shouldStayInNewTab: false
	}
) {
	let pathToNavigate = pathname || history.location.pathname;
	const currentPath = window.location.pathname;
	const query = omitBy(params.query, isNil);

	if (params.shouldStayInNewTab) {
		const isNewTab = last(currentPath.split('/')) === 'newtab';
		const pathToNavigateAlreadyHasNewTab = pathToNavigate
			.split('/')
			.includes('newtab');

		if (isNewTab && !pathToNavigateAlreadyHasNewTab) {
			pathToNavigate = pathToNavigate.concat(
				`${last(pathToNavigate) === '/' ? '' : '/'}newtab`
			);
		}
	}

	history.push({
		pathname: pathToNavigate,
		search: queryString.stringify(query),
		state: params.state
	});
}

export const getValueKey = (value: LabeledValue | null) => {
	return value ? value.key : null;
};

export function filterForSelect(
	inputValue: string,
	option: React.ReactElement<SelectOptionProps>
): boolean {
	/**
	 * By using the type `SelectOptionProps` we ensure that
	 * { children } will always exist
	 */
	const { children } = option.props;

	if (!isString(children)) {
		return true;
	}

	return children.toLowerCase().includes(inputValue.toLowerCase());
}

/**
 * Returns file extension without leading dot `.`
 */
export const getFileExtension = (fileName: string) => {
	return ((fileName || '').split('.').pop() || '').toLowerCase();
};

export const getIsFileExtensionOfType = (
	extension: string,
	type: FileExtension
) => {
	return getFileExtension(extension) === type;
};

/**
 * @see https://gist.github.com/mathewbyrne/1280286
 */
export const toSlug = (text: string) => {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w\-]+/g, '') // Remove all non-word chars
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, ''); // Trim - from end of text
};

export const isHandledCode = (
	status: number | number[],
	handledCodes: number[]
): boolean =>
	isArray(status)
		? !!intersection(status, handledCodes).length
		: handledCodes.includes(status);

export const sortAlphabetically = (a: string, b: string) =>
	a < b ? -1 : a > b ? 1 : 0;

export const downloadURI = (uri: string) => {
	const link = document.createElement('a');
	link.href = uri;
	link.setAttribute('download', '');
	link.hidden = true;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const logMockedRequest = (mock: MockAdapter, httpMethod: string) => {
	const currentRequestConfig = last(mock.history[httpMethod]);
	if (currentRequestConfig) {
		const { method, url, params } = currentRequestConfig;
		// eslint-disable-next-line no-console
		console.debug(method?.toUpperCase(), url, 'PARAMS:', params);
	}
};
