import { Success } from 'typescript-fsa';
import { ConfigurationServicesState } from '../configurationServicesState';
import {
	UpdatePrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: ConfigurationServicesState,
	{ result }: Success<UpdatePrincipalServiceRequest, PrincipalService>
): ConfigurationServicesState => ({
	...state,
	principalServices: {
		...state.principalServices,
		[result.id]: result
	}
});

export const onUpdatePrincipalService = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	UpdatePrincipalServiceRequest,
	PrincipalService
>('fetchStatuses', 'updatePrincipalService', {
	doneReducer
});
