import actionCreator from '../portJobsActionCreator';
import {
	AppointData,
	PortJobBaseRequest
} from 'services/api/portJobs/portJobsServiceTypes';

const ACTION_NAME = 'RETRIEVE_APPOINT_DATA';

export const retrieveAppointDataAsync = actionCreator.async<
	PortJobBaseRequest,
	AppointData,
	Error
>(ACTION_NAME);

export const retrieveAppointData = actionCreator(ACTION_NAME);
