import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	moveDownCustodyLine,
	moveUpCustodyLine
} from 'store/vesselProgramme/actions';

import Api from 'services/api';

import { retrieveEntityPermissionsForVPAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { EntityPermissionVPActionParams } from 'services/api/permissions/permissionsServiceTypes';
import { getCustodyTransfer } from 'store/vesselProgramme/selectors';

const actionByType = {
	canMoveUp: moveUpCustodyLine,
	canMoveDown: moveDownCustodyLine
};

export default function* custodyLinePermissionSaga(
	request: EntityPermissionVPActionParams,
	api: typeof Api.Permissions.retrieveEntityPermissionsForVP
): SagaIterator {
	const custodyTransfer = yield select(getCustodyTransfer);
	const requiredPermission = request.optionalParams.entityPermission;
	if (!requiredPermission) {
		return;
	}
	const move = actionByType[requiredPermission];
	if (custodyTransfer.linePosition !== 'During') {
		yield put(move());
		return;
	}
	yield put(retrieveEntityPermissionsForVPAsync.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			retrieveEntityPermissionsForVPAsync.done({
				result: response.data,
				params: request,
				response
			})
		);

		if (response.data[requiredPermission]) {
			yield put(move());
		} else {
			yield put(
				notify.warning(
					`Custody can't be changed since there is some operational or financial data associated to one of the agents' job(s).`
				)
			);
		}
	} catch (error) {
		yield put(
			retrieveEntityPermissionsForVPAsync.failed({
				error,
				params: request
			})
		);
	}
}
