import { FormErrors } from 'redux-form';
import { charMax, validateRequired } from 'utils/validations';
import { TimeSavingsFormData } from 'sections/PortCall/Savings/SavingsGrid/Constants';

const timeErrorMessage =
	'Please provide time (hour, minute) details greater than 0';

const validateHoursField = (value: string, minutes: string) => {
	if (!value && !minutes) {
		return 'Field is mandatory.';
	}

	if (value) {
		const parsedVal = parseFloat(value);
		if (parsedVal <= 0 && !minutes) {
			return timeErrorMessage;
		} else if (parsedVal <= 0 && minutes) {
			const parsedValMinutes = parseFloat(minutes);
			if (parsedValMinutes <= 0) {
				return timeErrorMessage;
			} else {
				return;
			}
		} else {
			return;
		}
	} else {
		return;
	}
};

const validateMinutesField = (value: string, hours: string) => {
	if (!value && !hours) {
		return;
	}

	if (value) {
		const parsedVal = parseFloat(value);
		if (parsedVal <= 0 && !hours) {
			return timeErrorMessage;
		} else if (parsedVal <= 0 && hours) {
			const parsedValMinutes = parseFloat(hours);
			if (parsedValMinutes <= 0) {
				return timeErrorMessage;
			} else {
				return;
			}
		} else if (parsedVal >= 60) {
			return 'Minutes should be less than 60';
		} else {
			return;
		}
	} else {
		return;
	}
};

const validate = (
	values: TimeSavingsFormData
): FormErrors<TimeSavingsFormData> => {
	const errors: FormErrors<TimeSavingsFormData> = {
		category: validateRequired(values.category),
		savingsBy: validateRequired(values.savingsBy),
		hours: validateHoursField(values.hours, values.minutes),
		minutes: validateMinutesField(values.minutes, values.hours),
		comments: charMax(1000)(values.comments)
	};
	return errors;
};

export default validate;
