import { PortJobState } from '../portJobsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	AddPortJobResponse,
	AddAppointmentFromNominationActionParams
} from 'services/api/portJobs/portJobsServiceTypes';

export const onAddAppointmentJobFromNomination = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	AddAppointmentFromNominationActionParams,
	AddPortJobResponse
>('fetchStatuses', 'addAppointmentFromNomination');
