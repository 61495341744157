import { Action } from 'typescript-fsa';
import { select, takeLatest } from 'redux-saga/effects';

import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/selectors';
import {
	deleteFinanceDocumentPageAsync,
	DeleteFinanceDocumentPageActionParams
} from '../actions/deleteFinanceDocumentPage';
import { getFinanceDocumentPagesLen } from '../selectors';
import Api from 'services/api';
import { deleteDocumentPageWorker } from 'store/documents/sagas/deleteDocumentPageSaga';
import { PageType } from '../constants';

function* worker({ payload }: Action<DeleteFinanceDocumentPageActionParams>) {
	let count: number = yield select(getFinanceDocumentPagesLen);
	const portCallId: string = yield select(getActivePortCallId);
	const jobCode: string = yield select(getActivePortJobCode);
	if (count === 1 && payload.pageType === PageType.UPDATED) {
		count += 1;
	}
	yield deleteDocumentPageWorker({
		count,
		returnUrl: `/portcalls/${portCallId}/jobs/${jobCode}/finance`,
		params: {
			...payload,
			portCallId
		},
		async: deleteFinanceDocumentPageAsync,
		api: Api.Finance.deleteDocumentPage
	});
}

export default function*() {
	yield takeLatest(deleteFinanceDocumentPageAsync.type, worker);
}
