import Api from 'services/api';
import { retrieveVesselExtendAsync } from '../actions';
import {
	RetrieveCompanyVesselRequest,
	VesselDetails as response
} from 'services/api/vessels/vesselsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { all, fork } from 'redux-saga/effects';

const api = Api.Vessels.retrieveVesselExtend;

const onRetrieveVesselExtend = makeTakeLatestWatcher<
	RetrieveCompanyVesselRequest,
	response,
	Error
>({
	api,
	async: retrieveVesselExtendAsync
});

export default function*() {
	yield all([fork(onRetrieveVesselExtend)]);
}
