import Api from 'services/api';
import { retrieveVesselAsync, retrieveVesselSectionAsync } from '../actions';
import { VesselDetails as response } from 'services/api/vessels/vesselsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { all, fork } from 'redux-saga/effects';

const api = Api.Vessels.retrieveVessel;

const onRetrieveVessel = makeTakeLatestWatcher<string, response, Error>({
	api,
	async: retrieveVesselAsync
});

const onRetrieveVesselSection = makeTakeLatestWatcher<string, response, Error>({
	api,
	async: retrieveVesselSectionAsync
});

export default function*() {
	yield all([fork(onRetrieveVessel), fork(onRetrieveVesselSection)]);
}
