import { createStructuredSelector, createSelector } from 'reselect';
import { AppState } from 'store-types';
import { isModalVisible } from 'store/modals/selectors';
import { getValues } from 'store/form/selectors';
import {
	Form,
	PrincipalServicesFormData,
	ModalAction
} from '../principalServicesFormData';

const isPrincipalServiceModalAdding = (state: AppState) =>
	isModalVisible(state, ModalAction.ADD);
const isPrincipalServiceModalUpdating = (state: AppState) =>
	isModalVisible(state, ModalAction.UPDATE);

const isPrincipalServiceModalVisible = createSelector(
	isPrincipalServiceModalAdding,
	isPrincipalServiceModalUpdating,
	(adding, updating) => adding || updating
);

export const getPrincipalServiceModalState = createStructuredSelector({
	visible: isPrincipalServiceModalVisible,
	adding: isPrincipalServiceModalAdding
});

export const getPrincipalServiceFormValues = (state: AppState) =>
	getValues<PrincipalServicesFormData>(state, Form.PRINCIPAL_SPECIFIC_SERVICES);
