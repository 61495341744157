import React from 'react';
import {
	RenderElementProps,
	useSelected,
	useFocused
} from 'slate-packages/packages/slate-react';
import classNames from 'classnames';
import styles from './Nodes.module.scss';

export const ImageElement = ({
	element,
	attributes,
	children
}: RenderElementProps) => {
	const selected = useSelected();
	const focused = useFocused();

	const attrs: React.HTMLAttributes<HTMLImageElement> = {
		...element.data?.attrs
	};

	const className = classNames({
		[styles.imageElementActive]: selected && focused
	});
	if (className) {
		attrs.className = className;
	}

	return (
		<>
			<img alt="" {...attrs} {...attributes} />
			{children}
		</>
	);
};

/**
 * We have to hardcode this styles inline in order to keep it in Outlook
 */
export const BlockquoteElement = (props: RenderElementProps) => (
	<blockquote
		style={{
			borderLeft: '5px solid #cdd6dd',
			color: '#666',
			fontStyle: 'italic',
			margin: '16px 0',
			padding: '10px'
		}}
		{...props.attributes}
	>
		{props.children}
	</blockquote>
);

/**
 * Fixes diffent styles for "pre" tag in Outlook (IPP-26563)
 */
export const PreElement = (props: RenderElementProps) => (
	<pre
		style={{
			margin: '10px 0',
			padding: '2px',
			backgroundColor: '#f0f1f3'
		}}
		{...props.attributes}
	>
		{props.children}
	</pre>
);
