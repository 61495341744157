import { UserType } from 'services/api/users/userServiceTypes';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';
import { ClassificationCode } from 'services/api/portJobs/portJobsServiceTypes';
import { FinanceStatus } from 'services/api/finance/financeServiceTypes';

const LegacyPortJobStatus = {
	AWAITING_APPOINTMENT: 'AwaitingAppointment',
	AWAITING_INVITATION: 'WaitingInvitation',
	AWAITING_CONFIRMATION: 'AwaitingConfirmation',
	ACCEPTANCE_PENDING: 'AcceptancePending',
	PDA_PENDING: 'PDA_PENDING'
};

export enum JobType {
	NOMINATION = 'Nomination',
	APPOINTMENT = 'Appointment',
	CHARTERER_OVERSIGHT = 'ChartererOversight'
}

export const jobTypeLabel = {
	[JobType.NOMINATION]: 'Nomination',
	[JobType.APPOINTMENT]: 'Appointment',
	[JobType.CHARTERER_OVERSIGHT]: 'Charterer Oversight'
};

export enum PortJobStatus {
	CREATED = 'Created',
	CONFIRMED = 'Confirmed',
	AWAITING_INVITATION = 'WaitInvite',
	AWAITING_APPOINTMENT = 'WaitAppont',
	AWAITING_ACCEPTANCE = 'WaitAccept',
	PDA_REQUESTED = 'PDARequest',
	APPOINTED = 'Appointed',
	PDA = 'PDA',
	EXECUTION = 'Execution',
	CE = 'CE',
	DA = 'DA',
	LPA_SETTLEMENTS = 'LPASettlement',
	DA_OUT_POSTINGS = 'DAOUTPosting',
	HUB_SETTLEMENTS = 'HUBSettlement',
	DA_SETTLEMENTS = 'DASettlement',
	COMPLETE = 'Complete',
	CLOSED = 'Closed'
}

// https://conf.dataart.com/display/IPP/%5BPCJSM%5D+Job+workflow+-+State+Machine#expand-Version14
export const PORT_JOB_STATUS_STATE_MACHINE = [
	PortJobStatus.CREATED,
	PortJobStatus.CONFIRMED,
	PortJobStatus.AWAITING_INVITATION,
	PortJobStatus.AWAITING_APPOINTMENT,
	PortJobStatus.AWAITING_ACCEPTANCE,
	PortJobStatus.PDA_REQUESTED,
	PortJobStatus.APPOINTED,
	PortJobStatus.PDA,
	PortJobStatus.EXECUTION,
	PortJobStatus.CE,
	PortJobStatus.DA,
	PortJobStatus.LPA_SETTLEMENTS,
	PortJobStatus.DA_OUT_POSTINGS,
	PortJobStatus.HUB_SETTLEMENTS,
	PortJobStatus.DA_SETTLEMENTS,
	PortJobStatus.COMPLETE,
	PortJobStatus.CLOSED
];

const statusMap = {
	[LegacyPortJobStatus.AWAITING_CONFIRMATION]: 'Awaiting Confirmation',
	[LegacyPortJobStatus.AWAITING_INVITATION]: 'Waiting Invitation',
	[LegacyPortJobStatus.ACCEPTANCE_PENDING]: 'Acceptance Pending',
	[LegacyPortJobStatus.PDA_PENDING]: 'PDA Pending'
};
const defaultStatus = '';

const Principal = {
	[LegacyPortJobStatus.AWAITING_APPOINTMENT]:
		'Awaiting Appointment (PRINCIPAL)',
	[LegacyPortJobStatus.AWAITING_CONFIRMATION]:
		'Awaiting Confirmation (PRINCIPAL)',
	[LegacyPortJobStatus.ACCEPTANCE_PENDING]: 'Acceptance Pending (Principal)',
	[LegacyPortJobStatus.PDA_PENDING]: 'PDA Pending (Principal)'
};

const Hub = {
	[LegacyPortJobStatus.AWAITING_APPOINTMENT]: 'Awaiting Appointment (Hub)',
	[LegacyPortJobStatus.AWAITING_INVITATION]: 'Waiting Invitation (Hub)',
	[LegacyPortJobStatus.AWAITING_CONFIRMATION]: 'Awaiting Confirmation (Hub)',
	[LegacyPortJobStatus.ACCEPTANCE_PENDING]: 'Acceptance Pending (Hub)',
	[LegacyPortJobStatus.PDA_PENDING]: 'PDA Pending (Hub)'
};

export const JobStatusByFinanceStatusAndUserRole = {
	[PortJobStatus.CREATED]: {
		[UserType.HUB]: 'Awaiting confirmation',
		[UserType.PRINCIPAL]: 'Awaiting confirmation',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.AWAITING_APPOINTMENT]: {
		[UserType.HUB]: 'Appointment pending',
		[UserType.PRINCIPAL]: 'Awaiting hub review',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.AWAITING_INVITATION]: {
		[UserType.HUB]: 'Invitation pending',
		[UserType.PRINCIPAL]: 'Awaiting hub review',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.AWAITING_ACCEPTANCE]: {
		[UserType.HUB]: 'Appointment acceptance pending',
		[UserType.PRINCIPAL]: 'Awaiting hub review',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.CONFIRMED]: {
		[UserType.HUB]: 'Process pending',
		[UserType.PRINCIPAL]: 'Awaiting hub review',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.PDA_REQUESTED]: {
		[UserType.HUB]: 'PDA requested',
		[UserType.PRINCIPAL]: 'Awaiting hub review',
		[UserType.LPA]: undefined
	},
	[PortJobStatus.APPOINTED]: {
		[UserType.HUB]: 'Acceptance pending',
		[UserType.PRINCIPAL]: 'Acceptance pending',
		[UserType.LPA]: 'Acceptance pending'
	},
	[PortJobStatus.PDA]: {
		[FinanceStatus.DRAFT]: {
			[UserType.HUB]: 'PDA pending',
			[UserType.PRINCIPAL]: 'PDA pending',
			[UserType.LPA]: 'PDA pending'
		},
		[FinanceStatus.SUBMITTED]: {
			[UserType.HUB]: 'PDA verification pending',
			[UserType.PRINCIPAL]: 'PDA verification pending',
			[UserType.LPA]: 'PDA approval pending'
		},
		[FinanceStatus.VERIFIED]: {
			[UserType.HUB]: 'PDA approval pending',
			[UserType.PRINCIPAL]: 'PDA approval pending',
			[UserType.LPA]: 'PDA approval pending'
		},
		[FinanceStatus.APPROVED]: {
			[UserType.HUB]: 'PDA approved',
			[UserType.PRINCIPAL]: 'PDA approved',
			[UserType.LPA]: 'PDA approved'
		}
	},
	[PortJobStatus.EXECUTION]: {
		[UserType.HUB]: 'Job in progress',
		[UserType.PRINCIPAL]: 'Job in progress',
		[UserType.LPA]: 'Job in progress'
	},
	[PortJobStatus.CE]: {
		[FinanceStatus.DRAFT]: {
			[UserType.HUB]: 'CE pending',
			[UserType.PRINCIPAL]: 'CE pending',
			[UserType.LPA]: 'CE pending'
		},
		[FinanceStatus.SUBMITTED]: {
			[UserType.HUB]: 'CE verification pending',
			[UserType.PRINCIPAL]: 'CE verification pending',
			[UserType.LPA]: 'CE approval pending'
		},
		[FinanceStatus.VERIFIED]: {
			[UserType.HUB]: 'CE approval pending',
			[UserType.PRINCIPAL]: 'CE approval pending',
			[UserType.LPA]: 'CE approval pending'
		},
		[FinanceStatus.APPROVED]: {
			[UserType.HUB]: 'CE approved',
			[UserType.PRINCIPAL]: 'CE approved',
			[UserType.LPA]: 'CE approved'
		}
	},
	[PortJobStatus.DA]: {
		[FinanceStatus.DRAFT]: {
			[UserType.HUB]: 'DA pending',
			[UserType.PRINCIPAL]: 'DA pending',
			[UserType.LPA]: 'DA pending'
		},
		[FinanceStatus.SUBMITTED]: {
			[UserType.HUB]: 'DA verification pending',
			[UserType.PRINCIPAL]: 'DA verification pending',
			[UserType.LPA]: 'DA approval pending'
		},
		[FinanceStatus.VERIFIED]: {
			[UserType.HUB]: 'DA approval pending',
			[UserType.PRINCIPAL]: 'DA approval pending',
			[UserType.LPA]: 'DA approval pending'
		},
		[FinanceStatus.APPROVED]: {
			[UserType.HUB]: 'DA approved',
			[UserType.PRINCIPAL]: 'DA approved',
			[UserType.LPA]: 'DA approved'
		}
	},
	[PortJobStatus.LPA_SETTLEMENTS]: {
		[UserType.HUB]: 'LPA Settlement',
		[UserType.PRINCIPAL]: 'LPA Settlement',
		[UserType.LPA]: 'LPA Settlement'
	},
	[PortJobStatus.DA_OUT_POSTINGS]: {
		[UserType.HUB]: 'DA Out Posting',
		[UserType.PRINCIPAL]: 'DA Out Posting',
		[UserType.LPA]: 'DA Out Posting'
	},
	[PortJobStatus.HUB_SETTLEMENTS]: {
		[UserType.HUB]: 'HUB Settlement',
		[UserType.PRINCIPAL]: 'HUB Settlement',
		[UserType.LPA]: 'HUB Settlement'
	},
	[PortJobStatus.DA_SETTLEMENTS]: {
		[UserType.HUB]: 'DA Settlement',
		[UserType.PRINCIPAL]: 'DA Settlement',
		[UserType.LPA]: 'DA Settlement'
	},
	[PortJobStatus.COMPLETE]: {
		NONE: {
			[UserType.HUB]: 'Settlement Complete',
			[UserType.PRINCIPAL]: 'Settlement Complete',
			[UserType.LPA]: 'Settlement Complete'
		}
	},
	[PortJobStatus.CLOSED]: {
		NONE: {
			[UserType.HUB]: PortJobStatus.CLOSED,
			[UserType.PRINCIPAL]: PortJobStatus.CLOSED,
			[UserType.LPA]: PortJobStatus.CLOSED
		}
	}
};

export const JobClassificationFiltersByGroupType = {
	[GroupTypeCodes.ISS_HUB]: [ClassificationCode.L],
	[GroupTypeCodes.ISS_CLUSTER]: [ClassificationCode.H, ClassificationCode.C],
	[GroupTypeCodes.CUSTOMER]: [ClassificationCode.H, ClassificationCode.C],
	[GroupTypeCodes.ISS_LPA]: [
		ClassificationCode.L,
		ClassificationCode.H,
		ClassificationCode.C
	],
	[GroupTypeCodes.THIRD_PARTY_LPA]: [
		ClassificationCode.L,
		ClassificationCode.H,
		ClassificationCode.C
	]
};

/**
 * @deprecated
 * Review this method and remove it if we don't need it
 * @param userType
 * @param status
 */
export const mapStatusToLabel = (userType: UserType, status = '') => {
	let collection;
	switch (userType) {
		case UserType.PRINCIPAL:
			collection = Principal;
			break;
		case UserType.HUB:
			collection = Hub;
			break;
		case UserType.LPA:
			collection = statusMap;
			break;
		default:
			collection = defaultStatus;
			break;
	}
	return collection[status] || defaultStatus;
};

export const portCallStatusToLabel = (label: string, isCancelled?: boolean) => {
	if (isCancelled) {
		return 'Cancelled';
	}
	if (label === 'EnRoute') {
		return 'En Route';
	}
	return label;
};
