import { Success, Failure } from 'typescript-fsa';
import {
	UpdatePortCallEventRequest,
	UpdateEventResponse
} from 'services/api/portCall/portCallServiceTypes';
import { PortCallOperationsState } from 'store/portCallOperations/portCallOperationsState';
import { FetchStatus } from 'services/api/apiTypes';
import { AxiosError } from 'axios';

const getSetUpdateStatuses = (
	state: PortCallOperationsState,
	eventId: string,
	status: FetchStatus
) => {
	return {
		...state.updateStatuses,
		events: {
			...state.updateStatuses.events,
			[eventId]: status
		}
	};
};

export const onUpdatePortCallEventFromFinanceStarted = (
	state: PortCallOperationsState,
	{ eventId }: UpdatePortCallEventRequest
) => {
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		FetchStatus.PENDING
	);
	return {
		...state,
		updateStatuses
	};
};

export const onUpdatePortCallEventFromFinanceSuccess = (
	state: PortCallOperationsState,
	action: Success<UpdatePortCallEventRequest, UpdateEventResponse>
) => {
	const { eventId } = action.params;
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		FetchStatus.SUCCESS
	);

	return {
		...state,
		updateStatuses
	};
};

export const onUpdatePortCallEventFromFinanceFailed = (
	state: PortCallOperationsState,
	action: Failure<UpdatePortCallEventRequest, AxiosError>
) => {
	const { eventId } = action.params;
	const updateStatuses = getSetUpdateStatuses(
		state,
		eventId,
		FetchStatus.FAILURE
	);

	return {
		...state,
		updateStatuses
	};
};
