import * as React from 'react';
import { Col, Row } from 'components/antd';
import SubHeader from 'sections/PortJob/SubHeader/SubHeader';
import Page1Info from 'sections/PortJob/CreatePortJob/Pages/Page1/Page1Info';
import Page2Info from 'sections/PortJob/CreatePortJob/Pages/Page2/Page2Info';

const Header = () => (
	<SubHeader title="Operation Details">
		<Row>
			<Col md={5} lg={4}>
				<Page1Info />
			</Col>
			<Col md={5} lg={4}>
				<Page2Info />
			</Col>
		</Row>
	</SubHeader>
);

export default Header;
