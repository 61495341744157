import actionCreatorFactory from 'typescript-fsa';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

const actionCreator = actionCreatorFactory();

const SET_ACTIVE_PORTCALL = 'portcalls/SET_ACTIVE_PORTCALL';
export const setActivePortCallId = actionCreator<PortCall['id']>(
	SET_ACTIVE_PORTCALL
);

const RESET_ACTIVE_PORTCALL = 'portcalls/RESET_ACTIVE_PORTCALL';
export const resetActivePortCallId = actionCreator(RESET_ACTIVE_PORTCALL);
