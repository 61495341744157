import { select } from 'redux-saga/effects';
import Api from 'services/api';
import { retrieveCountriesAsync as async } from '../actions/retrieveCountries';
import {
	RetrieveCountriesRequest as request,
	RetrieveCountriesResponse as response
} from 'services/api/countries/countriesServiceTypes';
import { getCountriesCount } from './../countriesSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Countries.retrieveCountries;
export default makeTakeLatestWatcher<request, response, Error>({
	api,
	async,
	*getApiParams(payload) {
		const count = yield select(getCountriesCount);
		const request: request = {
			index: count,
			sortBy: 'name:asc',
			...payload
		};
		return request;
	}
});
