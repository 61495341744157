import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import { Flex, Status, TR, TD, Title, JobCodeTag } from 'components';
import { Icon } from 'components/antd';
import MessageSendFailureIndicator from 'sections/Thread/Message/MessageSendFailureIndicator';
import { Justify } from 'components/types';
import { FormattedTime } from 'containers';
import { PortJobThread } from 'services/api/portJobs/portJobsServiceTypes';
import styles from './MessageRow.module.scss';

interface MessageRowProps {
	thread: PortJobThread;
	active: boolean;
	onSelect: (id: string) => void;
}

const MessageRow: React.SFC<MessageRowProps> = ({
	thread,
	active,
	onSelect: handleClick
}) => (
	<TR>
		<TD>
			<div
				className={classNames(styles.root, {
					selected: thread.isRead,
					[styles.active]: active
				})}
				onClick={() => handleClick(thread.id)}
			>
				<Flex justify={Justify.BETWEEN} align="center" className={styles.about}>
					<MessageSendFailureIndicator
						message={
							thread.hasFailedMessages
								? `There's a message in this thread which wasn't sent`
								: ''
						}
					/>
					<FormattedTime
						value={
							thread.lastMessage.updatedOn ||
							thread.updatedOn ||
							thread.createdOn
						}
						format="D MMM YYYY, HH:mm"
						convertFromUtc
						className={styles.updatedOn}
					/>
					<Status.Label value={thread.status} date={thread.statusUpdatedOn} />
				</Flex>
				<Flex className={styles.title}>
					<Title.Subject unread={!thread.isRead}>
						{thread.lastMessage.subject}
					</Title.Subject>
				</Flex>
				<Flex align="center" justify={Justify.BETWEEN}>
					<Flex inline>
						<ul>
							{thread.jobCodes.map(code => (
								<li key={code} className={styles.jobCodeTagItem}>
									<JobCodeTag jobCode={code}>{code}</JobCodeTag>
								</li>
							))}
							<li className={styles.jobCodeTagItem}>
								{get(thread.context, 'name')}
							</li>
							{thread.hasAttachments && (
								<li className={styles.jobCodeTagItem}>
									<Icon type="paper-clip" />
								</li>
							)}
						</ul>
					</Flex>
					<Icon color="standard" type="user" />
				</Flex>
			</div>
		</TD>
	</TR>
);

export default MessageRow;
