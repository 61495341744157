import actionCreator from '../threadsActionCreator';
import {
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREADS_MAIN_PRINCIPAL_GROUPS';

export const retrieveThreadsMainPrincipalGroupsAsync = actionCreator.async<
	RetrieveThreadsMainPrincipalTagsParam,
	RetrieveThreadsAvailableGroupsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadsMainPrincipalGroups = actionCreator<
	RetrieveThreadsMainPrincipalTagsParam
>(ACTION_NAME);
