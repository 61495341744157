import { Descendant, Editor } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-packages/packages/slate-react';

export enum ElementType {
	DIV = 'div',
	IMG = 'image',
	P = 'paragraph',
	UL = 'bulleted-list',
	OL = 'numbered-list',
	LI = 'list-item',
	BLOCKQUOTE = 'blockquote',
	PRE = 'pre',
	TABLE = 'table',
	THEAD = 'table-head',
	TBODY = 'table-body',
	TR = 'table-row',
	TD = 'table-cell',
	TH = 'table-head-cell',
	HR = 'hr',
	BR = 'br',
	A = 'link',
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	SPAN = 'span'
}

export enum TextType {
	STRONG = 'strong',
	B = 'b',
	I = 'i',
	EM = 'em',
	CODE = 'code',
	S = 'strikethrough',
	U = 'underline'
}

export enum LeafType {
	BOLD = 'bold',
	ITALIC = 'italic',
	CODE = 'code',
	STRIKETHROUGH = 'strikethrough',
	UNDERLINE = 'underline'
}

export enum HtmlAttribute {
	STYLE = 'style',
	CLASSNAME = 'class',
	WIDTH = 'width',
	HEIGHT = 'height',
	SRC = 'src',
	CELLPADDING = 'cellpadding',
	CELLSPACING = 'cellspacing',
	BORDER = 'border',
	ROWSPAN = 'rowspan',
	COLSPAN = 'colspan',
	HREF = 'href',
	BACKGROUND = 'background',
	BGCOLOR = 'bgcolor',
	VALIGN = 'valign'
}

// @see https://developer.mozilla.org/ru/docs/Web/API/Node/nodeType
export enum NodeType {
	ELEMENT_NODE = 1,
	TEXT_NDOE = 3
}

export enum RichTextNodeSection {
	CONTENT = 'content',
	HISTORY = 'history',
	SIGNATURE = 'signature'
}

export interface RichTextBlock {
	section: RichTextNodeSection;
	html: string;
}

export interface NodeDataAttributes {
	section?: RichTextNodeSection;
	attrs?: NodeDataHtmlAttributes;
}

export interface NodeDataHtmlAttributes extends React.AllHTMLAttributes<{}> {
	bgcolor?: string;
}

export type RichTextEditor = Editor & ReactEditor & HistoryEditor;
export type RichTextValue = Descendant[];
