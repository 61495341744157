import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveConfigurationFilesAsync,
	resetConfigurationFiles,
	addConfigurationFileAsync,
	openConfigurationFileModal,
	closeConfigurationFileModal,
	updateConfigurationFileAsync,
	deleteConfigurationFileAsync
} from './actions';
import {
	onRetrieveConfigurationFiles,
	onResetConfigurationFiles,
	onAddConfigurationFileSuccess,
	onUpdateConfigurationFileSuccess,
	onOpenConfigurationFileModal,
	onCloseConfigurationFileModal,
	onDeleteCompanyTemplateFileSuccess
} from './reducers';
import { initialState } from './configurationFilesState';

export default reducerWithInitialState(initialState)
	.case(openConfigurationFileModal, onOpenConfigurationFileModal)
	.case(closeConfigurationFileModal, onCloseConfigurationFileModal)
	// Retrieve Configuration Files
	.case(
		retrieveConfigurationFilesAsync.started,
		onRetrieveConfigurationFiles.started
	)
	.case(retrieveConfigurationFilesAsync.done, onRetrieveConfigurationFiles.done)
	.case(
		retrieveConfigurationFilesAsync.failed,
		onRetrieveConfigurationFiles.failed
	)
	.case(resetConfigurationFiles, onResetConfigurationFiles)
	.case(addConfigurationFileAsync.done, onAddConfigurationFileSuccess)
	.case(updateConfigurationFileAsync.done, onUpdateConfigurationFileSuccess)
	.case(deleteConfigurationFileAsync.done, onDeleteCompanyTemplateFileSuccess);
