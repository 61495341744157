import actionCreator from '../threadsActionCreator';
import {
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREAD_CONTEXTS';

export const retrieveThreadContextsAsync = actionCreator.async<
	RetrieveThreadContextsRequest,
	RetrieveThreadContextsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadContexts = actionCreator<
	RetrieveThreadContextsRequest | undefined
>(ACTION_NAME);
