import React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { getDisplayCargoList } from 'store/portJobs/selectors';
import { Table } from 'components/antd';
import OperationTable from '../../../OperationTable/OperationTable';
import getColumns from './CargoGridColumns';

export interface CargoGridProps {
	cargoes: object[];
	// from mapStateToProps
	displayCargoes: object[];
	operationTypeCode: OperationTypeCode;
}

const CargoGrid = (props: CargoGridProps) => (
	<OperationTable>
		<Table
			rowKey="id"
			dataSource={props.displayCargoes}
			columns={getColumns(props.operationTypeCode)}
			bordered={false}
			locale={{
				emptyText: `This Port Call doesn\'t have any Job yet`
			}}
		/>
	</OperationTable>
);

export default connect(
	(state: AppState, ownProps: Pick<CargoGridProps, 'cargoes'>) => ({
		displayCargoes: getDisplayCargoList(state, ownProps.cargoes)
	})
)(CargoGrid);
