import { getIsFailure, getIsLoaded } from 'store/selectors';
import {
	PortCallFinanceRevision,
	DaType
} from 'services/api/finance/financeServiceTypes';
import { createSelector } from 'reselect';
import { AppState } from 'store-types.d';
import { first } from 'lodash';
import { FinanceRevisionsTab } from '../constants';

export const getFinanceRevisions = (state: AppState) => state.finance.revisions;
export const getFirstFinanceRevision = createSelector(
	getFinanceRevisions,
	revisions => first<PortCallFinanceRevision>(revisions)
);

/** Returns true if fetching finance revisions had success */
const getRetrieveFinanceRevisionsSuccess = (state: AppState) =>
	getIsLoaded(state.finance.fetchStatuses.retrieveRevisions);

/** Returns true if fetching finance revisions had failed */
const getRetrieveFinanceRevisionsFailure = (state: AppState) =>
	getIsFailure(state.finance.fetchStatuses.retrieveRevisions);

/** Returns true if fetching finance revisions is over (successfully or not) */
export const getFinanceRevisionsDoneLoading = createSelector(
	getRetrieveFinanceRevisionsSuccess,
	getRetrieveFinanceRevisionsFailure,
	(success, failed) => success || failed
);

export const getFinanceHasRevisions = createSelector(
	getFinanceRevisions,
	getFinanceRevisionsDoneLoading,
	(revisions, doneLoading) =>
		doneLoading && (revisions && Boolean(revisions.length))
);

export const getFinanceRevisionsPermissions = createSelector(
	getFirstFinanceRevision,
	(revision: PortCallFinanceRevision): DaType[] => {
		if (!revision) {
			return [];
		}
		const { daInTotalAmountInUsd, daOutTotalAmountInUsd } = revision;

		const hasDaInAmount = Boolean(daInTotalAmountInUsd);
		const hasDaOutAmount = Boolean(daOutTotalAmountInUsd);

		if (hasDaInAmount && hasDaOutAmount) {
			return [DaType.IN, DaType.OUT];
		}
		if (hasDaInAmount) {
			return [DaType.IN];
		}
		if (hasDaOutAmount) {
			return [DaType.OUT];
		}
		return [];
	}
);

export const getFinanceRevisionsTabs = createSelector(
	/**
	 * Why do we use the FirstFinanceRevision as reference?
	 *
	 * For the BE, there's no distinction between Da In/Out revisions
	 * Being said so, all the revisions coming from the API should follow
	 * the same criteria. So, there shouldn't be a case
	 * where there're two revisions in the list with different daIn/daOut null values
	 */
	getFirstFinanceRevision,
	(revision): string[] => {
		if (!revision) {
			return [];
		}
		const { daInTotalAmountInUsd, daOutTotalAmountInUsd } = revision;

		const hasDaInAmount = Boolean(daInTotalAmountInUsd);
		const hasDaOutAmount = Boolean(daOutTotalAmountInUsd);

		if (hasDaInAmount && hasDaOutAmount) {
			return [FinanceRevisionsTab.HUB_IN, FinanceRevisionsTab.HUB_OUT];
		}
		if (hasDaInAmount) {
			return [FinanceRevisionsTab.IN];
		}
		if (hasDaOutAmount) {
			return [FinanceRevisionsTab.OUT];
		}
		return [];
	}
);
