import actionCreator from '../threadActionCreator';

const RETRIEVE_THREAD_BY_ID_CYCLE_START = 'RETRIEVE_THREAD_BY_ID_CYCLE_START';

const RETRIEVE_THREAD_BY_ID_CYCLE_STOP = 'RETRIEVE_THREAD_BY_ID_CYCLE_STOP';

export const retrieveThreadByIdCycleStart = actionCreator(
	RETRIEVE_THREAD_BY_ID_CYCLE_START
);

export const retrieveThreadByIdCycleStop = actionCreator(
	RETRIEVE_THREAD_BY_ID_CYCLE_STOP
);
