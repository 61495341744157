import { getPortCallInitialValues } from 'sections/PortJob/CreatePortJob/createPortJobSelectors';
import { getIsCurrentUsersGroupTypeISSCluster } from 'store/auth/selectors';
import { getIsHubPrincipalFieldVisible } from 'store/portJobs/componentSelectors';
import { getPortCallFormValues } from '../../createPortJobSelectors';
import { AppState } from 'store-types';
import { getForm, getFormSingleValue } from 'store/form/selectors';
import { AutoCompleteObjectReference } from 'app-types';
import { createSelector } from 'reselect';
import {
	FORM,
	FormFieldName,
	PORT_JOB_WIZARD_ID
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';

import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { getAddPortJobFormData } from 'store/portcalls/sagas/addPortCall/addPortCallSelectors';

import {
	getPortJobTypes,
	getActivePortJobCompanies
} from 'store/portJobs/selectors';
import { LabeledValue } from 'antd/lib/select';
import { get, isNil } from 'lodash';
import { StaticItem } from 'store/portcalls/portCallsState';
import { CompanyDraftResponse } from 'services/api/portJobs/portJobsServiceTypes';

export const getJobAppointer = (state: AppState): AutoCompleteObjectReference =>
	get(getForm(state, FORM.portJob), ['values', 'appointerCompany']);

export const getPerformingAgent = (
	state: AppState
): AutoCompleteObjectReference =>
	get(getForm(state, FORM.portJob), ['values', 'performingAgent']);

const getJobTypeId = (state: AppState): string =>
	get(getForm(state, FORM.portJob), ['values', 'jobTypeId']);

export const getJobType = createSelector(
	[getJobTypeId, getPortJobTypes, getAddPortJobFormData],
	(id: string, list: StaticItem[], form: Partial<FormData>): StaticItem => {
		let jobType = list.find(p => p.code === id);
		if (!jobType) {
			const formType = form.jobTypeId as LabeledValue;

			jobType = {
				code: formType.key,
				name: formType.label as string
			};
		}

		return jobType;
	}
);

export const getIsHubPrincipalFieldValid = createSelector(
	getPortCallFormValues,
	getPortCallInitialValues,
	getIsHubPrincipalFieldVisible,
	getIsCurrentUsersGroupTypeISSCluster,
	(
		formValues,
		initialValues,
		isHubPrincipalFieldVisible,
		isCurrentUsersGroupTypeISSCluster
	): boolean => {
		const values: Partial<FormData> = {
			...initialValues,
			...formValues
		};
		if (!isHubPrincipalFieldVisible) {
			return (
				Boolean(get(values, FormFieldName.HUB_PRINCIPAL_COMPANY)) ||
				(isCurrentUsersGroupTypeISSCluster &&
					isNil(get(values, FormFieldName.HUB_PRINCIPAL_COMPANY)))
			);
		} else {
			return Boolean(get(values, FormFieldName.HUB_PRINCIPAL_COMPANY));
		}
	}
);

export const getFieldAppointerContactDisabled = createSelector(
	getPortCallFormValues,
	getIsCurrentUsersGroupTypeISSCluster,
	(formValues, isUserGroupISSCluster): boolean =>
		isUserGroupISSCluster && !formValues[FormFieldName.APPOINTER_COMPANY]
);

export const getContactDetailsCompany = createSelector(
	getPortCallFormValues,
	getIsCurrentUsersGroupTypeISSCluster,
	(
		formValues,
		isUserGroupISSCluster
	):
		| FormData[FormFieldName.APPOINTER_COMPANY]
		| FormData[FormFieldName.HUB_PRINCIPAL_COMPANY]
		| null =>
		isUserGroupISSCluster
			? get(formValues, FormFieldName.APPOINTER_COMPANY, null)
			: get(formValues, FormFieldName.HUB_PRINCIPAL_COMPANY, null)
);

export const getPortJobFormPerforminAgent = (
	state: AppState
): LabeledValue | {} =>
	getFormSingleValue<FormData>(
		state,
		PORT_JOB_WIZARD_ID,
		FormFieldName.PERFORMING_AGENT
	) || {};

export const getPortJobFormControllingAgent = (
	state: AppState
): LabeledValue | {} =>
	getFormSingleValue<FormData>(
		state,
		PORT_JOB_WIZARD_ID,
		FormFieldName.CONTROLLING_AGENT
	) || {};

// composite key is a structure of tuple [id,companyId] or [id] if no companyId
const getAgentCompositeKey = (
	{ key }: LabeledValue,
	companies: CompanyDraftResponse[]
) => {
	const company = companies.find(({ id }) => id === key);
	return company ? [key, company.companyId] : [key];
};

export const getPortJobFormPerformingAgentCompositeKey = createSelector(
	getPortJobFormPerforminAgent,
	getActivePortJobCompanies,
	getAgentCompositeKey
);

export const getPortJobForControllingAgentCompositeKey = createSelector(
	getPortJobFormControllingAgent,
	getActivePortJobCompanies,
	getAgentCompositeKey
);
