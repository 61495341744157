import actionCreator from '../companyVesselsActionCreator';
import {
	CompanyVesselData,
	CreateCompanyVesselRequest
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'CREATE_COMPANY_VESEL';

export const createCompanyVesselAsync = actionCreator.async<
	CreateCompanyVesselRequest,
	CompanyVesselData,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Vessel'
	},
	failed: {
		omit: true
	}
});

export const createCompanyVessel = actionCreator<CreateCompanyVesselRequest>(
	ACTION_NAME
);
