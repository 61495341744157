import * as React from 'react';

import { isInvalid, submit } from 'redux-form';
import { Button, Modal, Alert } from 'components/antd';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { closeModal } from 'store/modals/actions';
import {
	ADD_COMPANY_DRAFT_MODAL_FORM,
	ADD_COMPANY_DRAFT_MODAL
} from '../constants';
import { isModalVisible } from 'store/modals/selectors';
import ConnectedForm from './AddCompanyDraftForm';
import { getActiveDraftTypeAlias } from 'store/drafts/selectors';

interface AddCompanyDraftModalProps {
	isVisible: boolean;
	invalid: boolean;
	submitForm: typeof submit;
	closeCompanyDraftModal: typeof closeModal;
	activeDraftTypeAlias: string;
}

const AddCompanyDraftModal = ({
	isVisible,
	invalid,
	submitForm,
	activeDraftTypeAlias,
	closeCompanyDraftModal
}: AddCompanyDraftModalProps) => (
	<Modal
		zIndex={1500} // this modal should have custom z-index in case this modal is opened inside another model
		destroyOnClose
		visible={isVisible}
		cancelText="Cancel"
		title={`Add ${activeDraftTypeAlias}`}
		width={540}
		onCancel={() => closeCompanyDraftModal({ name: ADD_COMPANY_DRAFT_MODAL })}
		okButton={
			<Button
				key="AddCompanyDraftModal"
				type="primary"
				disabled={invalid}
				onClick={() => {
					submitForm(ADD_COMPANY_DRAFT_MODAL_FORM);
				}}
			>
				Submit
			</Button>
		}
	>
		<Alert
			type="info"
			message={
				<p>
					This form will allow you to create a "draft" version of this company.
					Our master data team will ensure that this entry is properly accounted
					for in our database.
				</p>
			}
			showIcon
		/>
		<br />
		{isVisible && <ConnectedForm />}
	</Modal>
);

export default connect(
	(state: AppState) => ({
		invalid: isInvalid(ADD_COMPANY_DRAFT_MODAL_FORM)(state),
		isVisible: isModalVisible(state, ADD_COMPANY_DRAFT_MODAL),
		activeDraftTypeAlias: getActiveDraftTypeAlias(state)
	}),
	{
		submitForm: submit,
		closeCompanyDraftModal: closeModal
	}
)(AddCompanyDraftModal);
