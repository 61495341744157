import {
	DaType,
	PortCallFinance,
	DisbursementAccount
} from 'services/api/finance/financeServiceTypes';

/**
 * Get updated DA here.
 * In case of the fake id for da in the second parameter serviceId will be removed
 */
export const getUpdatedDa = (
	finance: PortCallFinance,
	daId: string,
	jobServiceId: string,
	daType?: DaType
): Pick<
	PortCallFinance,
	'disbursementAccountIns' | 'disbursementAccountOuts'
> => {
	const getDA = (da: DisbursementAccount) => {
		if (daType && da.daType !== daType) {
			return da;
		}
		const services =
			da.id === daId
				? [...da.services, jobServiceId]
				: da.services.filter(service => service !== jobServiceId);
		return {
			...da,
			services
		};
	};
	return {
		disbursementAccountIns: {
			...finance.disbursementAccountIns,
			elements: finance.disbursementAccountIns.elements.map(getDA)
		},
		disbursementAccountOuts: {
			...finance.disbursementAccountOuts,
			elements: finance.disbursementAccountOuts.elements.map(getDA)
		}
	};
};
