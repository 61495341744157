import { ColumnProps } from 'components/antd/Table/TableTypes';
import { BankAccount } from 'services/api/bankAccounts/bankAccountsServiceTypes';

const bankAccountColumns: Array<ColumnProps<BankAccount>> = [
	{
		title: 'Label',
		dataIndex: 'label'
	},
	{
		title: 'Account/Beneficiary Name',
		dataIndex: 'bankAccountName'
	},
	{
		title: 'Account Number',
		dataIndex: 'bankAccountNumber'
	},
	{
		title: 'Account Currency',
		dataIndex: 'bankAccountCurrency'
	}
];

export default bankAccountColumns;
