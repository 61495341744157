import { Success } from 'typescript-fsa';
import { ConfigurationServicesState } from '../configurationServicesState';
import {
	AddPrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const doneReducer = (
	state: ConfigurationServicesState,
	{ result }: Success<AddPrincipalServiceRequest, PrincipalService>
): ConfigurationServicesState => ({
	...state,
	principalServices: {
		[result.id]: result,
		...state.principalServices
	}
});

export const onAddPrincipalService = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	AddPrincipalServiceRequest,
	PrincipalService
>('fetchStatuses', 'addPrincipalService', {
	doneReducer
});
