import * as React from 'react';
import { Gap } from 'components';
import { DASHBOARD_JOBS_LIMIT } from 'store/dashboard/constants';

interface SectionHeaderProps {
	name: string;
	count: number;
}

const SectionHeader = ({ name, count }: SectionHeaderProps) => (
	<span>
		<Gap right>Vessels {name}</Gap>
		{count > DASHBOARD_JOBS_LIMIT
			? `${DASHBOARD_JOBS_LIMIT} of ${count} Vessels`
			: `${count} Vessels`}
	</span>
);

export default SectionHeader;
