import { addConfigurationFileAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	AddCompanyTemplateFileRequest,
	AddCompanyTemplateFileResponse
} from 'services/api/templates/templatesServiceTypes';

export default makeTakeLatestWatcher<
	AddCompanyTemplateFileRequest,
	AddCompanyTemplateFileResponse,
	Error
>({
	api: Api.Templates.uploadCompanyTemplateFile,
	async: addConfigurationFileAsync
});
