import React from 'react';
import { useSelector } from 'react-redux';
import Tags from './Tags/Tags';
import Filter from './Filter/Filter';
import { FilterItem, FilterState } from 'store/filters/filtersState';
import { getAreFiltersEmpty } from 'store/filters/filtersSelectors';

export interface FiltersStateAndHelpers {
	onChange: (filters: FilterState, override?: boolean) => void;
	clearOne: (filter: FilterItem) => void;
	clearAll: () => void;
}

export interface FiltersProps {
	name: string;
	invokeOnChange?: () => void;
	destroyOnUnmount?: boolean;
	children: (options: FiltersStateAndHelpers) => React.ReactNode;
}

const Filters = (props: FiltersProps) => {
	const areFiltersEmpty = useSelector(getAreFiltersEmpty);
	return <Filter {...props} areFiltersEmpty={areFiltersEmpty} />;
};

Filters.Tags = Tags;

export default Filters;
