import { call, put, select, take } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	MoveToStageJobFinanceRequest,
	MoveToStageJobFinanceResponse,
	MoveToStagePayload as Payload
} from 'services/api/finance/financeServiceTypes';
import { moveToStageAsync as async, retrieveFinance } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { Success } from 'typescript-fsa';
import { openModal, closeModal } from 'store/modals/actions';

const api = Api.Finance.moveToStageJobFinance;
const commonExecutor = makeDefaultExecutor<
	MoveToStageJobFinanceRequest,
	MoveToStageJobFinanceResponse,
	Error
>({
	api,
	async,
	*onSuccess({
		params
	}: Success<MoveToStageJobFinanceRequest, MoveToStageJobFinanceResponse>) {
		yield put(retrieveFinance(params));
	}
});

export const executor = function*({ stage }: Payload) {
	yield put(
		openModal({
			name: 'confirmFinanceMoveToStage',
			type: 'confirm',
			data: {
				title: '',
				description: `Are you sure you want to proceed?`,
				okText: 'Yes',
				cancelText: 'No'
			}
		})
	);
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const { payload } = yield take(closeModal.type);
	if (payload.isConfirmed) {
		yield call(commonExecutor, {
			portCallId,
			jobCode,
			stage
		});
	}
};

export default makeTakeEveryWatcher<
	Payload,
	MoveToStageJobFinanceResponse,
	Error
>({ api, async }, executor);
