import { Action } from 'typescript-fsa';
import Api from 'services/api';
import { retrieveCompanyVesselsAsync as async } from 'store/companyVessels/actions';
import {
	RetrieveCompanyVesselsRequest as Request,
	RetrieveCompanyVesselsResponse as Response
} from 'services/api/companies/companiesServiceTypes';
import { makeDefaultExecutor } from 'utils/sagaHelpers/sagaHelpers';
import { select, takeLatest, call } from 'redux-saga/effects';
import { getCompanyVesselsCount } from '../selectors';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

const api = Api.Companies.retrieveCompanyVessels;

const executor = makeDefaultExecutor<Request, Response, Error>({
	api,
	async
});

function* worker({ payload, meta }: Action<Request>) {
	const count = yield select(getCompanyVesselsCount);
	const requestParams: Request = {
		...payload,
		sortBy: 'vessel.Name',
		index: count,
		limit: DEFAULT_LIST_LIMIT
	};
	yield call(executor, requestParams, meta);
}

export default function*() {
	yield takeLatest(async.type, worker);
}
