import * as React from 'react';
import { get, trim } from 'lodash';
import classNames from 'classnames';
import { IThread } from 'services/api/threads/threadsServiceTypes';
import PortAndVesselColumn from '../ThreadColumns/PortAndVesselColumn';
import { ContextButton, Status, TD, TR, Flex } from 'components';
import { Checkbox, Icon, Tooltip } from 'components/antd';
import MessageSendFailureIndicator from 'sections/Thread/Message/MessageSendFailureIndicator';
import { FormattedTime } from 'containers';
import styles from '../Threads.module.scss';
import stylesThreadRow from './ThreadRowFullView.module.scss';
import { navigateTo } from 'utils';

interface ThreadRowFullViewProps {
	thread: IThread;
	menuItem: string | null;
	isCheckBoxDisabled: boolean;
	isThreadIdChecked: boolean;
	onClick: (id: string) => void;
	onCheckBoxClick: (
		id: string,
		allowedGroupId: string,
		group: IThread['assignedToGroup']
	) => void;
}

interface QueryToContextualize {
	email: string;
	messageId?: string;
}

const ContactActionButton = ({
	type,
	query
}: {
	type: 'edit' | 'plus';
	query: QueryToContextualize;
}) => (
	<ContextButton
		type="primary"
		transparent
		icon={type}
		shape="circle"
		size="small"
		handleClick={navigateTo}
		handleClickValue={`/messages/contact/edit`}
		handleClickParams={{
			query
		}}
	/>
);

export const ThreadRowFullView = (props: ThreadRowFullViewProps) => {
	const { thread, menuItem, isCheckBoxDisabled, isThreadIdChecked } = props;
	const {
		assignedToGroup,
		hasAttachments,
		lastMessage,
		status,
		context,
		jobCodes,
		hasFailedMessages,
		hasUnsupportedAttachments,
		messageCount
	} = thread;
	const statusUpdatedOn = status !== 'Resolved' ? thread.statusUpdatedOn : '';

	const { subject, from } = lastMessage;

	const jobCodesLabel =
		jobCodes.length > 0 ? jobCodes.join(', ') : 'No Job Defined';
	const contextLabel = get(context, 'name') || 'No Context Defined';
	const isAssigned = thread.assignedToUser;
	const COL_ROW_CLASS = 'col-row';
	const COL_ACCENT_CLASS = 'col-accent';
	const COL_UNASSIGNED_CLASS = 'col-row-unassigned';
	const queryToContextualize: QueryToContextualize = {
		email: from.email
	};
	if (!from.isContextualised) {
		queryToContextualize.messageId = lastMessage.id;
	}
	return (
		<TR
			className={classNames({
				[styles.unread]: !thread.isRead,
				[styles.row]: !!styles.row
			})}
			onClick={props.onClick}
			index={thread.id}
			key={thread.id}
		>
			<TD name="read">
				{menuItem && (
					<Checkbox
						key={thread.id}
						checked={isThreadIdChecked}
						disabled={isCheckBoxDisabled}
						onClick={() =>
							props.onCheckBoxClick(
								thread.id,
								thread.company.id,
								thread.assignedToGroup
							)
						}
					></Checkbox>
				)}
			</TD>
			<TD name="status">
				<span>
					<span>
						<MessageSendFailureIndicator
							message={
								hasFailedMessages
									? `There's a message in this thread which wasn't sent`
									: ''
							}
						/>
						<FormattedTime value={lastMessage.updatedOn} convertFromUtc />
					</span>
					<br />
					<Status.Label value={status} date={statusUpdatedOn} />
				</span>
			</TD>
			<TD name="subject" sm={2}>
				{hasUnsupportedAttachments && (
					<>
						<Tooltip
							title={
								'There are messages with unsupported attachment types in this thread. Those attachments were automatically excluded from the message.'
							}
							placement="top"
							getPopupContainer={() => document.body}
						>
							<Icon type="warning" color="warning" />
						</Tooltip>
						<Icon type="paper-clip" color="grayBlue" />
					</>
				)}
				{hasAttachments && !hasUnsupportedAttachments && (
					<Icon type="paper-clip" color="grayBlue" />
				)}
				<span className="subject">
					{subject || <span className="label label-warning">No subject</span>} (
					{messageCount})
				</span>
				{jobCodes.length > 0 || context ? (
					<span className="label">
						{jobCodesLabel}: {contextLabel}
					</span>
				) : (
					'--'
				)}
			</TD>
			<TD name="company">
				<Flex
					align="center"
					className={classNames(COL_ROW_CLASS, {
						[COL_ACCENT_CLASS]: !!from.userId,
						[COL_UNASSIGNED_CLASS]: !from.userId
					})}
				>
					<Icon type="briefcase" color={from.userId ? 'standard' : 'warning'} />
					{from.companyName || 'N/A'}
				</Flex>
				<div
					className={classNames(COL_ROW_CLASS, {
						[COL_ACCENT_CLASS]: !!from.userId,
						[COL_UNASSIGNED_CLASS]: !from.userId
					})}
				>
					{!from.userId ? (
						<Flex className={stylesThreadRow.contactInfo} align="center">
							<Icon
								type={from.isContextualised ? 'user' : 'question-circle'}
								color="warning"
							/>
							{from.isContextualised
								? trim(from.name) || from.email
								: from.email}
							<ContactActionButton
								query={queryToContextualize}
								type={from.isContextualised ? 'edit' : 'plus'}
							/>
						</Flex>
					) : (
						<Flex className={stylesThreadRow.contactInfo} align="center">
							<Icon type="user" color="standard" />
							{from.name}
						</Flex>
					)}
				</div>
			</TD>
			<PortAndVesselColumn thread={thread} />
			<TD>
				{thread.company && thread.company.name && (
					<Flex className="col-row col-accent" align="center">
						<Icon type="inbox" color="standard" />
						{thread.company.name}
					</Flex>
				)}
				<Flex className="col-row col-accent" align="center">
					<Icon type="users" color="standard" />{' '}
					{assignedToGroup ? (
						<span>
							{thread.assignedToGroup.name}
							{thread.assignedToGroup.isDeleted && ' - DELETED'}
						</span>
					) : (
						''
					)}
				</Flex>
				<Flex
					className={classNames(COL_ROW_CLASS, {
						'col-row-unassigned': !isAssigned
					})}
					align="center"
				>
					<Icon type="user" color={isAssigned ? 'standard' : 'warning'} />{' '}
					{isAssigned ? thread.assignedToUser.name : 'Unassigned'}
				</Flex>
			</TD>
		</TR>
	);
};

export default ThreadRowFullView;
