import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { FinanceContextualizationDisbursementAccount } from 'services/api/finance/financeServiceTypes';

import { initializeFinanceContextualizationForm } from './actions';
import { onInitializeFinanceContextualizationForm } from './reducers';
import { FormState } from '../formState';
import { FormData } from './financeContextualizationFormData';

export type FinanceContextualizationFormState = FormState<
	FormData,
	FinanceContextualizationDisbursementAccount[]
>;

export const initialState: FinanceContextualizationFormState = {
	data: [],
	values: {} as FormData
};

export default reducerWithInitialState(initialState).case(
	initializeFinanceContextualizationForm,
	onInitializeFinanceContextualizationForm
);
