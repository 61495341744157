import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { CommonActionModalProps } from './ActionModalTypes';
import { AppState } from 'store-types';
import { getIsLeadNominationJob } from 'store/portJobs/selectors';
import { unlinkJobs } from 'store/portJobs/actions';
import { UnlinkWarningMessages } from 'app-constants';

interface UnlinkJobsModalProps extends CommonActionModalProps {
	jobCode: string;
	portCallId: string;
	modalId: string;
	// from mapStateToProps
	visible: boolean;
	isLeadNomination: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	unlinkJobs: typeof unlinkJobs;
}

interface UnlinkJobsModalState {
	disableOkBtn: boolean;
}

class UnlinkJobsModal extends React.PureComponent<
	UnlinkJobsModalProps,
	UnlinkJobsModalState
> {
	state = {
		disableOkBtn: false
	};
	hideConfirmationModal = () => {
		const { modalId } = this.props;
		this.props.closeModal(modalId);
	};

	onConfirmingUnlinkJobs = () => {
		const { modalId } = this.props;
		this.setState({
			disableOkBtn: true
		});
		this.props.unlinkJobs({ modalId });
	};

	render() {
		const { visible, isLeadNomination } = this.props;
		return (
			<Modal
				destroyOnClose
				visible={visible}
				okText="Yes"
				closable={false}
				cancelText="No"
				onCancel={this.hideConfirmationModal}
				onOk={this.onConfirmingUnlinkJobs}
				title={'Unlink jobs'}
				okDisabled={this.state.disableOkBtn}
			>
				{isLeadNomination ? (
					<p>{UnlinkWarningMessages.WARNING_MESSAGE_LEAD_NOMINATION}</p>
				) : (
					<p>{UnlinkWarningMessages.WARNING_MESSAGE_NORMAL}</p>
				)}
			</Modal>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<UnlinkJobsModalProps, 'modalId'>) => ({
		visible: isModalVisible(state, ownProps.modalId),
		isLeadNomination: getIsLeadNominationJob(state)
	}),
	{
		closeModal,
		unlinkJobs
	}
)(UnlinkJobsModal);
