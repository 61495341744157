import React from 'react';
import { connect } from 'react-redux';
import { Tag, Badge } from 'components/antd';
import { getUserType } from 'store/auth/selectors';
import { lookupPortJobStatusLabel } from 'store/portJobs/selectors';
import { severityColorMap } from 'app-constants';
import { navigateTo } from 'utils';
import { setFilters } from 'store/filters/actions';
import { ThemeScheme } from 'components/types';
import JobStatusButton from 'sections/PortCalls/PortJobList/Columns/JobStatusButton';
import { DashboardJob } from 'services/api/dashboard/dashboardServiceTypes';
import { UserType } from 'services/api/users/userServiceTypes';
import { AppState } from 'store-types';

interface JobStatusColumnProps {
	status: string;
	portCallId: string;
	portJob: DashboardJob;

	userType: UserType;
	setFilters: typeof setFilters;
}

class JobStatusColumn extends React.Component<JobStatusColumnProps> {
	render() {
		const { portJob, portCallId, userType, status } = this.props;
		const {
			financeStatus,
			queriedToHub,
			queriedToLPA,
			alertSeverity,
			code,
			alerts
		} = portJob;
		const theme = severityColorMap[alertSeverity] as ThemeScheme;
		const goToJob = (link: string) => navigateTo(link);
		const hasAlert = alerts.map(alert => alert.metricType);

		return (
			<JobStatusButton hasAlert={hasAlert}>
				<Badge show={!!theme} dot theme={theme} placement="topRight">
					<Tag
						type="default"
						size="xs"
						onClick={() => {
							goToJob(`/portcalls/${portCallId}/jobs/${code}`);
						}}
					>
						{lookupPortJobStatusLabel(
							status,
							financeStatus,
							userType,
							queriedToHub,
							queriedToLPA
						)}
					</Tag>
				</Badge>
			</JobStatusButton>
		);
	}
}

export default connect(
	(state: AppState) => ({
		userType: getUserType(state)
	}),
	{
		setFilters
	}
)(JobStatusColumn);
