import {
	RetrieveCanalsRequest,
	RetrieveCanalsResponse
} from 'services/api/ports/portsServiceTypes';
import actionCreator from '../actionCreator';

const RETRIEVE_CANALS = 'RETRIEVE_CANALS';

export const retrieveCanalsAsync = actionCreator.async<
	RetrieveCanalsRequest,
	RetrieveCanalsResponse,
	Error
>(RETRIEVE_CANALS);

export const retrieveCanals = actionCreator<RetrieveCanalsRequest>(
	RETRIEVE_CANALS
);
