import { retrieveRelativeDocumentConfigAsync } from '../actions/retrieveRelativeConfiguration';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	RetrieveDocumentConfigParams,
	RetrieveRelativeDocumentConfigResponse
} from 'services/api/documents/documentsServiceTypes';
import Api from 'services/api';

const apiCall = Api.Documents.retrieveUploadConfiguration;
const async = retrieveRelativeDocumentConfigAsync;

/**
 * Get the Static config onMount for the upload 
 */
export default makeTakeEveryWatcher<
	RetrieveDocumentConfigParams,
	RetrieveRelativeDocumentConfigResponse,
	Error
>({
	api: apiCall,
	async
});
