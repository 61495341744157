import { SagaIterator } from 'redux-saga';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { put, call } from 'redux-saga/effects';
import { DownloadPortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import Api from 'services/api';
import { downloadPortCallDocumentsAsync } from '../actions';
import { downloadExecutor } from '../../documents/sagas/pollDownloadUrlSaga';
import { getActivePortCallIdExecutor } from 'utils/sagaHelpers/portCallSagaExecutor';

const async = downloadPortCallDocumentsAsync;
const POSTApi = Api.PortCallDocuments.requestPostPortCallDocumentUrl;
const GETApi = Api.PortCallDocuments.requestGetPortCallDocumentUrl;

export const executor = function*(
	params: DownloadPortCallDocumentRequest
): SagaIterator {
	// Get PortCallId from the store if no param was specified
	const portCallId: string = yield call(getActivePortCallIdExecutor, params);

	// Ignore non-request params
	const { meta, ...requestParams } = params;
	const payload: DownloadPortCallDocumentRequest = {
		portCallId,
		...requestParams
	};

	yield put(async.started(payload));
	try {
		/**
		 * Call POSTApi service to get file at first
		 * If isReady = false, then the client should call GETApi service for polling
		 */
		yield call(downloadExecutor, {
			params: payload,
			POSTApi,
			GETApi
		});

		yield put(
			async.done({ params: payload, result: undefined, response: null })
		);
	} catch (error) {
		yield put(
			async.failed({
				params: payload,
				error
			})
		);
	}
};

export default makeTakeLatestWatcher<
	DownloadPortCallDocumentRequest,
	undefined,
	Error
>(
	{
		async
	},
	executor
);
