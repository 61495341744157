import React from 'react';
import { connect } from 'react-redux';
import { UploadProgressModal } from 'components';

import {
	getUploadState,
	getIsDocumentProcessingDone
} from 'store/documents/selectors';

import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { DocumentProcessingState } from 'store/documents/documentsState';
import { AppState } from 'store-types';

interface UploadDocumentModalProps {
	doneProcessing: boolean;
	documentState: DocumentProcessingState;
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
}
const UploadDocumentModal: React.FC<UploadDocumentModalProps> = ({
	documentState: {
		showProgress,
		inProgress,
		file,
		status,
		statusTerminatedDescription,
		info,
		errorMessage,
		hideClose = false
	},
	doneProcessing,
	...props
}) => {
	if (!showProgress || !inProgress) {
		return null;
	}
	return (
		<UploadProgressModal
			visible
			hideClose={hideClose}
			status={status}
			statusTerminatedDescription={statusTerminatedDescription}
			alertMessage={errorMessage}
			done={doneProcessing}
			file={file}
			percent={info.percent}
			hasSplit={info.hasSplit}
			onClose={() =>
				props.cancelUploadAndProcessDocument({ shouldResetFile: true })
			}
		/>
	);
};

export default connect(
	(state: AppState) => ({
		documentState: getUploadState(state),
		doneProcessing: getIsDocumentProcessingDone(state)
	}),
	{
		cancelUploadAndProcessDocument
	}
)(UploadDocumentModal);
