import actionCreator from '../portCallActionCreator';
import {
	RetrievePortCallsAlertsRequest as Request,
	RetrievePortCallsAlertsResponse as Response
} from 'services/api/portCalls/portCallsServiceTypes';

const RETRIEVE_PORTCALL_ALERTS = 'RETRIEVE_PORTCALL_ALERTS';

export const retrievePortCallAlerts = actionCreator<Request>(
	RETRIEVE_PORTCALL_ALERTS
);
export const retrievePortCallAlertsAsync = actionCreator.async<
	Request,
	Response,
	Error
>(RETRIEVE_PORTCALL_ALERTS);
