import { createSelector } from 'reselect';
import { AppState } from 'store-types.d';
import {
	PortCallTabType,
	PortCallTabMaps,
	PortCallTabKey
} from '../portCallsTypes';

export const getPortCallTabs = (state: AppState): PortCallTabMaps =>
	state.portcalls.context.portCallTabs;

export const getActivePortCallTabId = (state: AppState): PortCallTabKey =>
	state.portcalls.context.activePortCallTabId;

export const getCurrentPortCallTab = createSelector(
	getPortCallTabs,
	getActivePortCallTabId,
	(tabs, currentTab): PortCallTabType => tabs[currentTab]
);
