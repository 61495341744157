import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrievePortCallAlertsAsync as async } from 'store/portCall/actions';
import Api from 'services/api';
import { select } from 'redux-saga/effects';
import {
	RetrievePortCallsAlertsRequest as Request,
	RetrievePortCallsAlertsResponse as Response
} from 'services/api/portCalls/portCallsServiceTypes';
import { getUserType } from 'store/auth/selectors/user';
import { UserType } from 'services/api/users/userServiceTypes';
import { Severity } from 'app-constants';

export default makeTakeLatestWatcher<Request, Response, Error>({
	async,
	api: Api.PortCalls.retrievePortCallsAlerts,
	*getApiParams(params: Request) {
		const userType = yield select(getUserType);
		// for Hub all allAlertSeverities types should be retrieved
		const alertSeverities =
			userType === UserType.HUB
				? [Severity.NORMAL, Severity.RED, Severity.YELLOW]
				: undefined;
		return {
			...params,
			includeAlerts: true,
			alertSeverities
		};
	}
});
