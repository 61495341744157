import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	RetrieveJobParams,
	PortJob
} from 'services/api/portJobs/portJobsServiceTypes';
import { addCompaniesDraft, resetDrafts } from 'store/drafts/actions';
import {
	retrievePortJobForCycle,
	retrievePortJobForCycleAsync
} from 'store/portJobs/actions';
import { setPortJobUpToDate } from '../actions/setPortJobUpToDate';
import Api from 'services/api';

const apiCall = Api.PortJobs.retrievePortJob;

export function* executor(
	actionParams: RetrieveJobParams,
	api: typeof apiCall
): SagaIterator {
	yield put(retrievePortJobForCycleAsync.started(actionParams));

	try {
		const response = yield call(api, actionParams);

		const { portJobCompanies = [] } = response.data as PortJob;
		yield put(resetDrafts());
		yield put(addCompaniesDraft(portJobCompanies));

		yield put(
			retrievePortJobForCycleAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);

		yield put(setPortJobUpToDate(true));
	} catch (error) {
		yield put(
			retrievePortJobForCycleAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

function* worker({ payload }: Action<RetrieveJobParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(retrievePortJobForCycle, worker);
}
