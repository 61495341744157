import * as React from 'react';
import SupplierInvoiceLayout from '../../components/SupplierInvoiceLayout/SupplierInvoiceLayout';
import AddSupplierInvoiceViaPostCredit from './AddSupplierInvoiceViaPostCredit';
import DocumentViewerContainer from 'sections/DocumentViewer/DocumentViewerContainer';
import { PortCallTabBaseProps } from 'sections/PortCall';
import { useDispatch } from 'react-redux';
import { setIsSupplierInvoiceContextualization } from 'store/supplierInvoice/actions';

interface AddSupplierInvoiceViaPostCreditRouteProps
	extends PortCallTabBaseProps<{
		supplierInvoiceId: string;
		documentId: string;
	}> {}

const AddSupplierInvoiceViaPostCreditRoute: React.FC<AddSupplierInvoiceViaPostCreditRouteProps> = props => {
	const { supplierInvoiceId, documentId } = props.match.params;
	const { match } = props;
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(
			setIsSupplierInvoiceContextualization({
				isSupplierInvoiceContextualizing: true
			})
		);
		return () => {
			dispatch(
				setIsSupplierInvoiceContextualization({
					isSupplierInvoiceContextualizing: false
				})
			);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<DocumentViewerContainer match={match} withPortCallContainer={false}>
			<SupplierInvoiceLayout
				{...props}
				layoutProps={{ direction: 'horizontal' }}
			>
				<AddSupplierInvoiceViaPostCredit
					supplierInvoiceId={supplierInvoiceId}
					documentId={documentId}
				/>
			</SupplierInvoiceLayout>
		</DocumentViewerContainer>
	);
};

export default AddSupplierInvoiceViaPostCreditRoute;
