import { PortJobState as State } from '../portJobsState';
import {
	RetrievePortJobMetadataRequest,
	PortJobMetadataActionCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { Metadata } from 'app-types';

export const onRetrievePortJobMetadataSuccess = (
	state: State,
	action: Success<
		RetrievePortJobMetadataRequest,
		Metadata<PortJobMetadataActionCode>
	>
): State => ({
	...state,
	metadata: {
		...state.metadata,
		[action.params.portJobCode]: action.result
	}
});

export const onRetrievePortJobMetadata = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	RetrievePortJobMetadataRequest,
	Metadata<PortJobMetadataActionCode>
>('fetchStatuses', 'metadata', {
	doneReducer: onRetrievePortJobMetadataSuccess
});
