import React from 'react';

import {
	Row,
	Col,
	Input,
	InputNumber,
	TextArea,
	Autocomplete
} from 'components/antd';
import AutocompleteEntity from 'containers/Autocomplete/AutocompleteEntity';
import { onUOMSearch } from 'containers/Autocomplete/AutocompleteEntity.func';

import { Commodity } from 'services/api/commodities/commoditiesServiceTypes';
import { Unit } from 'services/api/units/unitsServiceTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import { AutoCompleteDrafts } from 'components/Drafts';
import FieldMainCommodity from '../../Field.MainCommodity';
import {
	localeSearchIdName,
	searchShipperReceivers,
	searchCharterers
} from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineForm.func';
import CargoLineFormFieldsName from 'sections/PortJob/CreatePortJob/Pages/Page3/CargoLineForm/CargoLineFormFieldNames';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';
import { CompanyDraft, DraftType } from 'store/drafts/draftsState';
import Api from 'services/api';
import { CargoLineDischargeOperation } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { UNIT_TYPES_BY_MAIN_COMMODITY } from 'store/units/unitsConstants';
import { DatePickerExtendedFormItem } from 'components/DatePickerExtended/DatePickerExtended';

const isDischargingSTS = (
	operationTypeCode: CargoLineFieldsProps['operationTypeCode']
) => {
	if (!operationTypeCode) {
		return false;
	}
	return operationTypeCode === OperationTypeCode.DISCHARGING_STS;
};

const {
	REFERENCE_NUMBER,
	PRINCIPAL_VOYAGE_REFERENCE,
	COMMODITY,
	COMMODITY_QUANTITY,
	COMMODITY_UNIT_OF_MEASUREMENT_CODE,
	CHARTERER_COMPANY,
	CHARTER_PARTY_DATE_PLT,
	TERMS,
	RECEIVER_COMPANY,
	LOAD_PORT,
	DISCHARGING_TO
} = CargoLineFormFieldsName;

interface CargoLineFieldsProps {
	isSplit: boolean;
	operationTypeCode: OperationTypeCode | undefined;
	commodities: Commodity[];
	units: Unit[];
	onAddDraftClick: (
		type: DraftType,
		formFieldName: string,
		alias: string
	) => void;
	formValues: CargoLineDischargeOperation;
	chartererDrafts: CompanyDraft[];
	receiverDrafts: CompanyDraft[];
}

const CargoLineFields = (props: CargoLineFieldsProps) => {
	const {
		commodities,
		isSplit,
		operationTypeCode,
		formValues,
		onAddDraftClick,
		chartererDrafts,
		receiverDrafts
	} = props;
	const isSTS = isDischargingSTS(operationTypeCode);
	const onSearchUOM = (searchTerm?: string) => {
		const unitsOfMeasurementTypes = formValues.mainCommodity
			? UNIT_TYPES_BY_MAIN_COMMODITY[formValues.mainCommodity]
			: [];
		return onUOMSearch(searchTerm, { unitsOfMeasurementTypes });
	};

	return (
		<>
			<Row>
				<Col xs={3}>
					<Input.ReduxFormItem
						type="text"
						name={REFERENCE_NUMBER}
						label="Cargo Ref"
						help
						disabled={isSplit}
					/>
				</Col>
				<Col xs={3}>
					<Input.ReduxFormItem
						type="text"
						name={PRINCIPAL_VOYAGE_REFERENCE}
						label="Voyage Ref"
						help
						disabled={isSplit}
					/>
				</Col>
				<Col xs={3}>
					<FieldMainCommodity
						isSplit={isSplit}
						value={formValues.mainCommodity}
					/>
				</Col>
				<Col xs={3}>
					<Autocomplete.ReduxFormItem
						name={COMMODITY}
						label="Commodity Detail"
						onSearch={localeSearchIdName(commodities)}
						disabled={!formValues.mainCommodity || isSplit}
						getPopupContainer={() => document.body}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={3}>
					<InputNumber.ReduxFormItem
						name={COMMODITY_QUANTITY}
						label="Nomination Qty"
						help
					/>
				</Col>
				<Col xs={3}>
					<AutocompleteEntity
						name={COMMODITY_UNIT_OF_MEASUREMENT_CODE}
						label="UOM"
						getPopupContainer={() => document.body}
						onSearch={onSearchUOM}
						shouldSearchAllOptionsOnFocus
						minLength={1}
					/>
				</Col>
				<Col xs={3}>
					<AutoCompleteDrafts.ReduxFormItem
						name={RECEIVER_COMPANY}
						label="Receiver"
						onSearch={searchShipperReceivers}
						drafts={receiverDrafts}
						addDraftLinkTitle={'Add Receiver Manually'}
						onAddDraftClick={() =>
							onAddDraftClick(
								DraftType.SHIPPER_RECEIVER,
								RECEIVER_COMPANY,
								'Receiver'
							)
						}
						getPopupContainer={() => document.body}
					/>
				</Col>
				<Col xs={3}>
					<AutoCompleteDrafts.ReduxFormItem
						name={CHARTERER_COMPANY}
						label="Charterer"
						onSearch={searchCharterers}
						drafts={chartererDrafts}
						addDraftLinkTitle={'Add Charterer Manually'}
						onAddDraftClick={() =>
							onAddDraftClick(
								DraftType.CHARTERER,
								CHARTERER_COMPANY,
								'Charterer'
							)
						}
						getPopupContainer={() => document.body}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={3}>
					<DatePickerExtendedFormItem
						name={CHARTER_PARTY_DATE_PLT}
						label="Charter Party Date"
						help
						local
						showTime={false}
					/>
				</Col>
				{isSTS && (
					<Col xs={3}>
						<Autocomplete.ReduxFormItem
							name={DISCHARGING_TO}
							label="Discharging To"
							onSearch={Api.Vessels.searchVesselsForAutocomplete}
							getPopupContainer={() => document.body}
						/>
					</Col>
				)}
				<Col xs={3}>
					<Autocomplete.ReduxFormItem
						name={LOAD_PORT}
						label="Load Port"
						onSearch={Api.Ports.searchPortsForAutocomplete}
						getPopupContainer={() => document.body}
						disabled={isSplit}
					/>
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					<TextArea.ReduxFormItem
						name={TERMS}
						label="Cargo Options / Terms"
						rows={4}
						maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
					/>
				</Col>
			</Row>
		</>
	);
};

export default CargoLineFields;
