import actionCreator from '../portCallDocumentsActionCreator';
import { PortCallDocumentNotIssuedPayload } from './markPortCallDocumentNotIssued';

const ACTION_NAME = 'EDIT_NOT_ISSUED_PORT_CALL_DOCUMENT';

export const editNotIssuedPortCallDocumentAsync = actionCreator.async<
	PortCallDocumentNotIssuedPayload,
	null,
	Error
>(ACTION_NAME, {
	done: {
		description: 'Document has been successfully updated.'
	}
});

export const editNotIssuedPortCallDocument = actionCreator<
	PortCallDocumentNotIssuedPayload
>(ACTION_NAME);
