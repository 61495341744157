import actionCreator from '../actionCreator';
import {
	AddPOIRequest,
	AddPOIResponse
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

const ACTION_NAME = 'ADD_POI';

export const addPOIAsync = actionCreator.async<
	AddPOIRequest,
	AddPOIResponse,
	Error
>(ACTION_NAME);

export const addPOI = actionCreator<AddPOIRequest>(ACTION_NAME);
