import * as React from 'react';
import { connect } from 'react-redux';
import { getActiveSupplierInvoice } from 'store/supplierInvoice/selectors';
import { SupplierInvoicePortJobService } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { Text, LabeledText, Gap, Amount } from 'components';
import InvoicePortJobCollapse from '../../../components/InvoicePortJobCollapse/InvoicePortJobCollapse';
import InvoiceDetailsSection from './InvoiceDetailsSection';
import { Justify } from 'components/types';
import { Col, Row } from 'components/antd';
import { AppState } from 'store-types';

type ViewInvoiceDetailsSectionProps = ReturnType<typeof mapStateToProps>;

const ViewInvoiceDetailsSection: React.SFC<ViewInvoiceDetailsSectionProps> = ({
	activeSupplierInvoice
}) => {
	if (!activeSupplierInvoice) {
		return null;
	}
	return (
		<>
			<Row>
				<Col xs={12} gutter={false}>
					<hr />
				</Col>
			</Row>
			<Gap isBlock top="md" bottom="md">
				<Row>
					<Col xs={6} justify={Justify.START}>
						<Text weight="semi-bold">Invoice Details</Text>
					</Col>
					<Col xs={6} justify={Justify.END}>
						<LabeledText
							type="horizontal"
							label="Control Total: "
							value={
								<Amount
									value={activeSupplierInvoice.total}
									negative={activeSupplierInvoice.isCredit}
								/>
							}
							align="baseline"
						/>
					</Col>
				</Row>
			</Gap>
			{activeSupplierInvoice.contextualizations.elements.length > 0 && (
				<Row>
					<Col xs={12} gutter={false}>
						{activeSupplierInvoice.contextualizations.elements.map(portJob => (
							<InvoicePortJobCollapse portJob={portJob} key={portJob.id}>
								{(service: SupplierInvoicePortJobService) => (
									<InvoiceDetailsSection
										portJobId={portJob.id}
										service={service}
										isCredit={activeSupplierInvoice.isCredit}
									/>
								)}
							</InvoicePortJobCollapse>
						))}
					</Col>
				</Row>
			)}
		</>
	);
};
const mapStateToProps = (state: AppState) => ({
	activeSupplierInvoice: getActiveSupplierInvoice(state)
});
export default connect(mapStateToProps)(ViewInvoiceDetailsSection);
