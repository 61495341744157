import { all, fork } from 'redux-saga/effects';

import addConfigurationFileWatcher from './addConfigurationFileSaga';
import openConfigurationFileModalWatcher from './addUpdateConfigurationFile/openConfigurationFileModalSaga';
import closeConfigurationFileModalWatcher from './addUpdateConfigurationFile/closeConfigurationFileModalSaga';
import configurationFileModalSubmitWatcher from './addUpdateConfigurationFile/configurationFileModalSubmitSaga';
import retrieveConfigurationFilesWatcher from './retrieveConfigurationFilesSaga';
import uploadConfigurationFileDoneWatcher from './uploadConfigurationFileDoneSaga';
import deleteConfigurationFileWatcher from './deleteConfigurationFileSaga';
import openConfigurationFileDeleteModalWatcher from './openConfigurationFileDeleteModalSaga';
import updateConfigurationFileWatcher from './updateConfigurationFileSaga';

export default function*() {
	yield all([
		fork(addConfigurationFileWatcher),
		fork(updateConfigurationFileWatcher),

		fork(openConfigurationFileModalWatcher),
		fork(closeConfigurationFileModalWatcher),
		fork(configurationFileModalSubmitWatcher),
		fork(openConfigurationFileDeleteModalWatcher),

		fork(retrieveConfigurationFilesWatcher),
		fork(uploadConfigurationFileDoneWatcher),
		fork(deleteConfigurationFileWatcher)
	]);
}
