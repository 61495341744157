import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import { PortCallsState } from 'store/portcalls/portCallsState';
export const onSetActivePortCallTab = (
	state: PortCallsState,
	action: PortCallTabKey
): PortCallsState => ({
	...state,
	context: {
		...state.context,
		activePortCallTabId: action
	}
});
export const onResetActivePortCallTab = (
	state: PortCallsState
): PortCallsState => ({
	...state,
	context: {
		...state.context,
		activePortCallTabId: PortCallTabKey.OVERVIEW
	}
});
