import { FinanceState } from '../financeState';
import { OpenSplitServiceModalPayload } from '../actions';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	SplitServiceRequestPayload,
	SplitServiceResponse
} from 'services/api/finance/financeServiceTypes';

export function onSplitServiceModalOpen(
	state: FinanceState,
	openSplitServiceModalPayload: OpenSplitServiceModalPayload
): FinanceState {
	const { serviceId, sectionEntityId } = openSplitServiceModalPayload;
	return {
		...state,
		context: {
			...state.context,
			splitModalServiceId: serviceId,
			splitModalSectionEntityId: sectionEntityId,
			showSplitServiceModal: true
		}
	};
}

export function onSplitServiceModalClose(state: FinanceState): FinanceState {
	return {
		...state,
		context: {
			...state.context,
			splitModalServiceId: null,
			splitModalSectionEntityId: undefined,
			showSplitServiceModal: false
		}
	};
}

export const onSplitService = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	SplitServiceRequestPayload,
	SplitServiceResponse
>('fetchStatuses', 'splitService');
