import React from 'react';
import { connect } from 'react-redux';

import JobSummaryWidget from './JobSummaryWidget/JobSummaryWidget';
import styles from './JobsTab.module.scss';
import {
	getDashboardPortCallsSummarySections,
	getDashboardPortCallsSummaryFetchStatus
} from 'store/dashboard/selectors/dashboardSummarySelectors';
import {
	retrieveDashboardSummaryCycleStart,
	retrieveDashboardSummaryCycleStop,
	retrieveDashboardPortCallsSummary
} from 'store/dashboard/actions';
import { DataFetcher } from 'utils/components';
import { FetchStatus } from 'services/api/apiTypes';
import { ScrollableLayout } from 'components';
import { SummarySection } from 'services/api/dashboard/dashboardServiceTypes';
import MessagesSummary from './MessagesSummary/MessagesSummary';
import { Alert } from 'components/antd';
import { AppState } from 'store-types';

interface JobsSummaryProps {
	summarySections: SummarySection[];
	summaryDashboardFetchStatus: FetchStatus;
	retrieveStart: typeof retrieveDashboardSummaryCycleStart;
	retrieveStop: typeof retrieveDashboardSummaryCycleStop;
	retrieveSummary: typeof retrieveDashboardPortCallsSummary;
}

class JobsSummary extends React.Component<JobsSummaryProps> {
	timer: number;

	componentDidMount() {
		this.props.retrieveStart();
	}

	componentWillUnmount() {
		this.props.retrieveStop();
	}

	retrieveDashboardSummary = () => {
		this.props.retrieveSummary();
	};

	render() {
		const { summarySections, summaryDashboardFetchStatus } = this.props;
		return (
			<div className={styles.summary}>
				<DataFetcher
					data={summarySections}
					dispatch={this.retrieveDashboardSummary}
					fetchStatus={summaryDashboardFetchStatus}
					skipIfHasData={true}
					error={() => (
						<Alert
							type="warning"
							message="Retrieving Summary informations failed"
						/>
					)}
				>
					{() => (
						<ScrollableLayout>
							<ScrollableLayout>
								<MessagesSummary />
								{summarySections.map((section, index) => (
									<JobSummaryWidget
										key={index}
										section={section}
										title={section.name}
									/>
								))}
							</ScrollableLayout>
						</ScrollableLayout>
					)}
				</DataFetcher>
			</div>
		);
	}
}

export default connect(
	(state: AppState) => ({
		summarySections: getDashboardPortCallsSummarySections(state),
		summaryDashboardFetchStatus: getDashboardPortCallsSummaryFetchStatus(state)
	}),
	{
		retrieveStart: retrieveDashboardSummaryCycleStart,
		retrieveStop: retrieveDashboardSummaryCycleStop,
		retrieveSummary: retrieveDashboardPortCallsSummary
	}
)(JobsSummary);
