import {
	VesselProgrammeState,
	UpdateBerthNameRequest
} from '../vesselProgrammeState';

export const onUpdateBerth = (
	state: VesselProgrammeState,
	{ rotationStepId, berth }: UpdateBerthNameRequest
): VesselProgrammeState => {
	const rotationStep = state.rotationStepsById[rotationStepId];
	const terminalId = rotationStep?.terminal?.id;
	/*
		if berth is defined, poiId should be equal to berthId
		else poiId is terminalId
	*/
	const poiId = berth.id || terminalId;
	return {
		...state,
		edited: true,
		rotationStepsById:
			rotationStepId in state.rotationStepsById
				? {
						...state.rotationStepsById,
						[rotationStepId]: {
							...state.rotationStepsById[rotationStepId],
							berth,
							poiId
						}
				  }
				: state.rotationStepsById
	};
};
