import React from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal';

import { navigateTo } from 'utils';

class ConfirmLeavePage extends React.Component<ConfirmationModalProps> {
	onOk = () => navigateTo(this.props.returnUrl || '/');

	render() {
		return (
			<ConfirmationModal {...this.props} onOk={this.onOk} onClose={this.onOk} />
		);
	}
}

export default ConfirmLeavePage;
