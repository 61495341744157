import {
	RetrievePortCallMeasurementsAccessRequest,
	RetrievePortCallBunkeringMeasurementsRequest,
	RetrievePortCallBunkeringMeasurementsResponse,
	RetrievePortCallShipMeasurementsRequest,
	RetrievePortCallShipMeasurementsResponse,
	RetrievePortCallCargoMeasurementsRequest,
	UpdateBunkeringOperationGradeRequest,
	UpdateShipMeasurementsRequest,
	UpdateCargoMeasurementsRequest,
	CargoMeasurement
} from './portCallMeasurementsServiceTypes';
import client from 'services/client';

const retrievePortCallMeasurementsAccess = ({
	portCallId
}: RetrievePortCallMeasurementsAccessRequest) =>
	client.get(`portcalls/${portCallId}/measurements/access`);

const retrievePortCallBunkeringMeasurements = ({
	portCallId
}: RetrievePortCallBunkeringMeasurementsRequest) =>
	client.get<RetrievePortCallBunkeringMeasurementsResponse>(
		`portcalls/${portCallId}/measurements/bunkering`
	);

const retrievePortCallShipMeasurements = ({
	portCallId
}: RetrievePortCallShipMeasurementsRequest) =>
	client.get<RetrievePortCallShipMeasurementsResponse>(
		`portcalls/${portCallId}/measurements/ship`
	);

const retrievePortCallCargoMeasurements = ({
	portCallId
}: RetrievePortCallCargoMeasurementsRequest) =>
	client.get<CargoMeasurement[]>(`portcalls/${portCallId}/measurements/cargo`);

const updateBunkeringOperationGrade = ({
	portCallId,
	bunkeringOperationGradeId,
	updatingGradeData
}: UpdateBunkeringOperationGradeRequest) =>
	client.patch(
		`/portcalls/${portCallId}/measurements/bunkering/${bunkeringOperationGradeId}`,
		updatingGradeData
	);

const updateShipMeasurements = ({
	portCallId,
	updatingData
}: UpdateShipMeasurementsRequest) =>
	client.patch(`/portcalls/${portCallId}/measurements/ship`, updatingData);

const updateCargoMeasurements = ({
	portCallId,
	updatingData
}: UpdateCargoMeasurementsRequest) =>
	client.put(`/portcalls/${portCallId}/measurements/cargo`, updatingData);

export default {
	retrievePortCallMeasurementsAccess,
	retrievePortCallBunkeringMeasurements,
	retrievePortCallShipMeasurements,
	retrievePortCallCargoMeasurements,
	updateBunkeringOperationGrade,
	updateShipMeasurements,
	updateCargoMeasurements
};
