import actionCreator from 'store/dashboard/dashboardActionCreator';

const RETRIEVE_JOB_SECTIONS_CYCLE_START = 'RETRIEVE_JOB_SECTIONS_CYCLE_START';
const RETRIEVE_JOB_SECTIONS_CYCLE_STOP = 'REFRESH_JOB_SECTIONS_STOP';

export const retrieveJobSectionsCycleStart = actionCreator(
	RETRIEVE_JOB_SECTIONS_CYCLE_START
);
export const retrieveJobSectionsCycleStop = actionCreator(
	RETRIEVE_JOB_SECTIONS_CYCLE_STOP
);
