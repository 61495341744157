import {
	FormData,
	GradeLineFormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { BunkeringOperationGrade } from 'services/api/portJobs/portJobsServiceTypes';
import numeral from 'numeral';
import { isIdAutoGenerated } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';
import { omit } from 'lodash';

import { keyToStringField } from '../prepareRequest.func';

const prepareOperationGradeFormData = (
	values: GradeLineFormData
): BunkeringOperationGrade => {
	let gradeLine = {
		gradeId: keyToStringField(values.grade),
		quantity: numeral(values.quantity).value(),
		id: values.id,
		unitOfMeasurementCode: keyToStringField(values.unitOfMeasurement)
	};
	if (values.id && isIdAutoGenerated(values.id)) {
		gradeLine = omit(gradeLine, 'id');
	}
	return gradeLine;
};

export default (form: FormData) => ({
	bunkeringOperationGrades: form.bunkeringOperationGrades
		? form.bunkeringOperationGrades.map(prepareOperationGradeFormData)
		: undefined,
	details: form.details
});
