import Api from 'services/api';
import { call, put, takeLatest, select, take, all } from 'redux-saga/effects';
import { RetrieveJobParams } from 'services/api/portJobs/portJobsServiceTypes';
import {
	retrievelinkedAppointmentJob,
	retrievelinkedAppointmentJobAsync
} from '../actions';
import { SagaIterator } from 'redux-saga';
import {
	getLinkedJobPortCallIdFromActiveJob,
	getLinkedJobCodeFromActiveJob,
	getActivePortJob
} from 'store/portJobs/portJobsSelectors';
import {
	retrievePortJob,
	retrievePortJobAsync
} from 'store/portJobs/actions/retrievePortJob';
import { setPortJobContext } from 'store/portJobs/actions';
import {
	retrievePortCall,
	retrievePortCallAsync,
	setPortCallContext
} from 'store/portcalls/actions';
import { Action } from 'typescript-fsa';
import { forEach } from 'lodash';
import {
	PortCall,
	RetrievePortCallsRequest
} from 'services/api/portCalls/portCallsServiceTypes';
import { getActivePortCall } from 'store/portcalls/portCallsSelectors';
import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { getLinkedAppointmentPortJob } from 'sections/PortCall/Linkjob/LinkCargo/LinkCargo.func';

const apiCall = Api.PortCalls.retrievePortCallsDuplicates;

export function* retrieveLinkedAppointmentJobExecutor(
	actionParams: RetrieveJobParams,
	api: typeof apiCall
): SagaIterator {
	yield put(retrievelinkedAppointmentJobAsync.started(actionParams));
	try {
		yield put(
			setPortJobContext({
				activePortJobCode: actionParams.jobCode,
				isForLinkCargoProcess: true
			})
		);
		yield put(
			setPortCallContext({ activePortCallId: actionParams.portCallId })
		);
		yield put(
			retrievePortCall({ id: actionParams.portCallId, expand: 'PortJobsBasic' })
		);
		yield put(
			retrievePortJob({
				portCallId: actionParams.portCallId,
				jobCode: actionParams.jobCode
			})
		);
		const valid = yield all([
			take([retrievePortCallAsync.done, retrievePortCallAsync.failed]),
			take([retrievePortJobAsync.done, retrievePortJobAsync.failed])
		]);
		forEach(valid, array => {
			if (array.error) {
				throw new Error('Failed to load link cargo page');
			}
		});
		const linkedPortCallId = yield select(getLinkedJobPortCallIdFromActiveJob);
		const linkedPortJobCode = yield select(getLinkedJobCodeFromActiveJob);
		const activePortCall = yield select(getActivePortCall);
		const activePortJob = yield select(getActivePortJob);
		const request: RetrievePortCallsRequest = {
			vesselId: activePortCall.vessel.id,
			portId: activePortCall.port.id,
			eta:
				activePortCall.etaPlt &&
				getLocalDateDefaultFormatted(activePortCall.etaPlt),
			jobType: 'Appointment',
			expand: 'Operations',
			portJobPerformingCompanyName: activePortJob.performingAgentCompany.name,
			mainPrincipalCompanyId: activePortJob.hubPrincipalCompany?.id
		};

		const response = yield call(api, request);
		const duplicatePortCalls = response.data.elements as PortCall[];
		const job = getLinkedAppointmentPortJob(
			duplicatePortCalls,
			linkedPortCallId,
			linkedPortJobCode
		);

		if (job) {
			yield put(
				retrievelinkedAppointmentJobAsync.done({
					result: job,
					params: actionParams,
					response
				})
			);
		}
		yield put(
			setPortJobContext({
				selectedLinkJobPortCallId: linkedPortCallId,
				selectedLinkJobCode: linkedPortJobCode
			})
		);
	} catch (error) {
		yield put(
			retrievelinkedAppointmentJobAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* worker({ payload }: Action<RetrieveJobParams>): SagaIterator {
	yield call(retrieveLinkedAppointmentJobExecutor, payload, apiCall);
}

export default function*() {
	yield takeLatest(retrievelinkedAppointmentJob.type, worker);
}
