import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { Country } from 'services/api/countries/countriesServiceTypes';

export const getCountriesMap = (state: AppState) => state.countries.byCode;

export const getCountries = createSelector([getCountriesMap], byCode =>
	Object.values(byCode)
);

export const getCountryByCode = (state: AppState, code: string): Country =>
	state.countries.byCode[code];

export const getCountriesCount = createSelector(
	getCountries,
	countries => countries.length
);

export const getIsRetrieveCountries = (state: AppState) =>
	state.countries.fetchStatuses.all;
