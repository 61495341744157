import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import {
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselResponse
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const onRetrieveEditCompanyVesselByIdData = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	RetrieveCompanyVesselByIdRequest,
	RetrieveCompanyVesselResponse
>('fetchStatuses', 'retrieve');
