import React, { Component } from 'react';
import styles from '../DaSet.module.scss';
import { Modal, Row, Col, Radio, InputNumber } from 'components/antd';
import { FixedRate } from 'services/api/finance/financeServiceTypes';
import { Align } from 'components/types';
import { isEmpty } from 'lodash';
import { ExchangeRate } from 'sections/Administration/Configuration/GeneralDetails/GeneralDetailsTypes';
import { connect } from 'react-redux';
import { AppState } from 'store-types';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { updateFixedRateExchange } from 'store/finance/actions';
import { isFixedRateUpdating } from 'store/finance/selectors/financeSelectors';
import numeral from 'numeral';

export const EXCHANGE_RATE_MODAL = 'exchangeRateModal';

interface ExchangeRateModalProps {
	fixedRate: FixedRate[];
	isFixedRate: boolean;
	daSet: string;
	isModalVisible: boolean;
	isFixedRateUpdating: boolean;
	closeModal: typeof closeModal;
	updateFixedRateExchange: typeof updateFixedRateExchange;
}

interface ExchangeRateModalState {
	setExchangeRate: string;
	fixedRate: FixedRate[];
	isFixedRateSet: boolean;
}

export class ExchangeRateModal extends Component<
	ExchangeRateModalProps,
	ExchangeRateModalState
> {
	constructor(props: ExchangeRateModalProps) {
		super(props);
		this.state = {
			setExchangeRate: 'BlendedRate',
			fixedRate: [],
			isFixedRateSet: false
		};
	}

	componentDidMount() {
		const { isFixedRate } = this.props;
		if (isFixedRate) {
			this.setState({
				setExchangeRate: 'FixedRate',
				isFixedRateSet: true,
				fixedRate: [...this.props.fixedRate]
			});
		} else {
			this.setState({
				fixedRate: [...this.props.fixedRate]
			});
		}
	}

	componentWillUnmount() {
		this.props.closeModal(EXCHANGE_RATE_MODAL);
	}

	fixedRate = (index: number, value: string) => {
		const fixedRate = [...this.state.fixedRate];
		fixedRate[index].rate = value;
		this.setState({
			fixedRate: [...fixedRate]
		});
	};

	onSaveFixedRate = () => {
		const { fixedRate, isFixedRateSet } = this.state;
		const { daSet } = this.props;
		this.props.updateFixedRateExchange({
			isFixedRate: isFixedRateSet,
			daType: daSet,
			exchangeRates: isFixedRateSet ? fixedRate : []
		});
	};

	setExchangeRate = (rate: string) => {
		if (rate === ExchangeRate.BLENDED_RATE) {
			this.setState({ setExchangeRate: 'BlendedRate', isFixedRateSet: false });
			return ExchangeRate.BLENDED_RATE;
		}
		if (rate === ExchangeRate.FIXED_RATE) {
			this.setState({ setExchangeRate: 'FixedRate', isFixedRateSet: true });
			return ExchangeRate.FIXED_RATE;
		}
		return;
	};

	render() {
		const { setExchangeRate, fixedRate, isFixedRateSet } = this.state;
		const { isModalVisible, isFixedRateUpdating, closeModal } = this.props;
		const disabled =
			setExchangeRate === ExchangeRate.FIXED_RATE
				? !isEmpty(
						fixedRate.filter(eachSet => {
							const value = Number(
								numeral(eachSet.rate).format('0.[00000000]')
							);
							return value === 0;
						})
				  )
				: false;
		return (
			<Modal
				size="md"
				className={styles.modalContainer}
				destroyOnClose
				okDisabled={disabled}
				title={'Assign Exchange Rate'}
				visible={isModalVisible}
				onOk={this.onSaveFixedRate}
				confirmLoading={isFixedRateUpdating}
				onCancel={() => closeModal(EXCHANGE_RATE_MODAL)}
			>
				<strong>Exchange Rate Type</strong>
				<div className={styles.modalContent}>
					<Row>
						<Radio.Group>
							<Radio
								checked={isFixedRateSet}
								value={ExchangeRate.FIXED_RATE}
								onClick={() => this.setExchangeRate(ExchangeRate.FIXED_RATE)}
							>
								Fixed Rate
							</Radio>
							<Radio
								checked={!isFixedRateSet}
								value={ExchangeRate.BLENDED_RATE}
								onClick={() => this.setExchangeRate(ExchangeRate.BLENDED_RATE)}
							>
								Blended Rate
							</Radio>
						</Radio.Group>
					</Row>
					{setExchangeRate === ExchangeRate.FIXED_RATE && isFixedRateSet ? (
						<Row className={styles.popoverContent}>
							{
								'Note: The fixed exchange rate is used to calculate the DA OUT Amount. Fixed Exchange Rate * Currency Value = DA OUT Value.'
							}
							<Col sm={12} className={styles.popoverContent}>
								<Row>
									<Col sm={2}>
										<strong>{'Currency'}</strong>
									</Col>
									<Col sm={6}>
										<strong>{'Fixed Rate'}</strong>
									</Col>
								</Row>
								{fixedRate.map((fixedRateSet, index) => (
									<Row key={index}>
										<Col
											sm={2}
											align={Align.MIDDLE}
										>{`${fixedRateSet.currencyCode}/${fixedRateSet.outCurrencyCode}`}</Col>
										<Col sm={6}>
											{
												<InputNumber
													className={styles.inputField}
													required={true}
													value={`${fixedRateSet.rate}`}
													decimalPart={9}
													maxDigits={19}
													onChangeFormattedValue={value => {
														this.fixedRate(index, value);
													}}
												/>
											}
										</Col>
									</Row>
								))}
							</Col>
						</Row>
					) : (
						undefined
					)}
				</div>
			</Modal>
		);
	}
}

export default connect(
	(state: AppState) => ({
		isModalVisible: isModalVisible(state, EXCHANGE_RATE_MODAL),
		isFixedRateUpdating: isFixedRateUpdating(state)
	}),
	{ updateFixedRateExchange, closeModal }
)(ExchangeRateModal);
