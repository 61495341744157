import { Entity } from 'app-types.d';
import { ThreadAvailableGroupsReducerState as State } from '../../threadAvailableGroupsReducer';

export const onRemoveAvailableThreadGroup = (
	state: State,
	payload: Entity
) => ({
	...state,
	groups: state.groups.filter(group => group.id !== payload.id)
});
