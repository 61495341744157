import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'UPDATE_CONFIGURATION_EMAIL_FRAGMENTS';
export const updateConfigurationEmailFragments = actionCreator(ACTION_NAME);
export const updateConfigurationEmailFragmentsAsync = actionCreator.async<
	null,
	null,
	Error
>(ACTION_NAME, {
	// omitting here since is handled by `updateCompanyTemplatesAsync` action
	done: { omit: true },
	failed: { omit: true }
});
