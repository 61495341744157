export const ADD_CONTACT_FORM_ID = 'addContact';
export enum EditContactFieldName {
	EMAIL = 'email',
	COMPANY = 'company',
	CONTACT_TYPE = 'contactType',
	FIRST_NAME = 'firstName',
	LAST_NAME = 'lastName',
	PHONE = 'phone',
	NOTES = 'notes'
}

export enum ContactEmailOwnerValue {
	PERSONAL = 'Personal',
	GENERIC = 'Generic'
}
