import React, { useCallback, useContext } from 'react';
import { capitalize } from 'lodash';
import { Align } from 'components';
import { Button, Dropdown, Menu } from 'components/antd';
import { ClickParam } from 'antd/lib/menu';
import { PermissionWrapper } from 'components/Permission';
import { UseModalContext } from 'context';

import { UsersModalsName } from '../../UsersModals/UsersModalsTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { User, UserStatus } from 'services/api/users/userServiceTypes';
import { getOppositeUserStatusText } from 'sections/User/UserStatusButton/UserStatusButton.func';
import { navigateTo } from 'utils';

enum UserSettingsMenuKey {
	EDIT = 'edit',
	TOGGLE_STATUS = 'toggle',
	RESET_PASSWORD = 'reset'
}

interface UserSettingsColumnProps {
	user: User;
}

function getUserStatusDisabledMessage(user: User) {
	switch (user.status) {
		case UserStatus.VERIFICATION_PENDING:
			return `Users in verification pending status can't be edited`;
		case UserStatus.PERM_LOCKED:
			if (!user.company.isDeleted) {
				return;
			}
			return `You can't perform any actions with user because user belongs to disabled company`;
	}
	return;
}

export default function UserSettingsColumn({ user }: UserSettingsColumnProps) {
	const { showModal } = useContext(UseModalContext);

	const onMenuItemClick = useCallback(
		({ key }: ClickParam) => {
			if (key === UserSettingsMenuKey.EDIT) {
				navigateTo(`users/${user.id}/edit`);
			}
			if (key === UserSettingsMenuKey.TOGGLE_STATUS) {
				showModal(UsersModalsName.TOGGLE_STATUS, user);
			}
		},
		[showModal, user]
	);

	const UserSettingsMenu = (
		<Menu onClick={onMenuItemClick}>
			<Menu.Item key={UserSettingsMenuKey.EDIT}>Edit user</Menu.Item>
			{user.status !== UserStatus.REGISTRATION_PENDING && (
				<Menu.Item key={UserSettingsMenuKey.TOGGLE_STATUS}>
					{capitalize(getOppositeUserStatusText(user.status))} user
				</Menu.Item>
			)}
		</Menu>
	);

	return (
		<div id={`dropdown-container-${user.id}`}>
			<Align align="right">
				<PermissionWrapper permissionCode={PermissionCode.MANAGE_USERS}>
					{hasPermission => {
						const message = getUserStatusDisabledMessage(user);
						const disabled = !hasPermission || Boolean(message);

						const button = (
							<Button
								type="primary"
								transparent
								icon="settings"
								disabled={disabled}
								tooltip={
									message
										? { title: message, placement: 'topRight' }
										: undefined
								}
							/>
						);
						if (disabled) {
							return button;
						}
						return (
							<Dropdown
								overlay={UserSettingsMenu}
								trigger={['click']}
								getPopupContainer={() =>
									document.getElementById(
										`dropdown-container-${user.id}`
									) as HTMLElement
								}
							>
								{button}
							</Dropdown>
						);
					}}
				</PermissionWrapper>
			</Align>
		</div>
	);
}
