import { Success } from 'typescript-fsa';
import {
	RetrieveCompaniesForUserRequest,
	RetrieveCompaniesForUserResponse
} from 'services/api/companies/companiesServiceTypes';
import { CompaniesForUserState } from '../companiesState';
import { FetchStatus } from 'services/api/apiTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { keyBy } from 'lodash';

/**
 * Reducers
 */
export function onRetrieveCompaniesForUserStarted(
	state: CompaniesForUserState
): CompaniesForUserState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.PENDING
		}
	};
}
export function onRetrieveCompaniesForUserFailed(
	state: CompaniesForUserState
): CompaniesForUserState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.FAILURE
		},
		hasMore: false
	};
}
export function onRetrieveCompaniesForUserSuccess(
	state: CompaniesForUserState,
	action: Success<
		RetrieveCompaniesForUserRequest,
		RetrieveCompaniesForUserResponse
	>
): CompaniesForUserState {
	const {
		result: { elements },
		params
	} = action;
	const byId = {
		...state.byId,
		...keyBy(elements, item => item.id)
	};

	return {
		...state,
		hasMore: elements.length === (params.limit || DEFAULT_LIST_LIMIT),
		byId,
		fetchStatuses: {
			...state.fetchStatuses,
			all: FetchStatus.SUCCESS
		}
	};
}
