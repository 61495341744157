import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getIsActivePortJobCancelled,
	getIsJobLinked,
	isCargoLinkButtonActive
} from 'store/portJobs/selectors';
import { CommonActionModalProps } from './ActionModalTypes';
import { PermissionButton } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { navigateTo } from 'utils';

export const LinkCargoButton: FC<CommonActionModalProps> = ({
	portCallId,
	jobCode
}) => {
	const disabled = useSelector(getIsActivePortJobCancelled);
	const isLinked = useSelector(getIsJobLinked);
	const isNotAllCargoesLinked = useSelector(isCargoLinkButtonActive);

	const onClick = () =>
		navigateTo(`/portcalls/${portCallId}/jobs/${jobCode}/link-cargo`);

	return (
		<>
			{isLinked && (
				<span>
					<PermissionButton
						permissionCode={PermissionCode.MANAGE_PORTCALL}
						type="primary"
						onClick={onClick}
						disabled={disabled || isNotAllCargoesLinked}
					>
						Link Cargo
					</PermissionButton>
				</span>
			)}
		</>
	);
};

export default LinkCargoButton;
