import React from 'react';
import { Button, Tabs } from 'components/antd';
import { Align } from 'components';
import MessageContainer from './MessageContainer';
import styles from './ExtendedMessagingContent.module.scss';
import {
	EmailTypesTitle,
	ExtendedMessage,
	ExtendedMessagingData
} from 'services/api/portJobs/portJobsServiceTypes';
import { connect } from 'react-redux';
import {
	getExtendedMessageData,
	getExtendedMessages,
	getSentThreadId,
	isExtendedMessageSending
} from 'store/portJobs/selectors';
import { AppState } from 'store-types';
import { every, partition, reject, remove, uniqBy } from 'lodash';
import { UserContact } from 'services/api/users/userServiceTypes';

import { DocumentWithOriginalFile } from 'services/api/documents/documentsServiceTypes';
import {
	getDocumentsAttachmentsSelector,
	isUploadingAttachmentSelector
} from 'sections/ComposeMessage/ComposeMessageSelector';
import { Attachment } from 'services/api/messages/messagesServiceTypes';
import { removeAttachment } from 'store/documents/actions';
import {
	RichTextNodeSection,
	RichTextValue
} from 'components/RichText/RichTextTypes';
import { getRecipientsError } from 'sections/ComposeMessage/utils/ComposeMessageUtils';
import {
	convertToHtml,
	isRichTextEmpty
} from 'components/RichText/RichTextUtils';
import { sendExtendedmessage, setPortJobContext } from 'store/portJobs/actions';
import { EXTENDED_MAIL_TYPE } from '../../PortJobConstants';

type RecipientType = 'to' | 'cc';

interface TabTitleContentProps {
	title: string;
	onClose: () => void;
	showCloseIcon: boolean;
}

const TabTitleContent: React.FC<TabTitleContentProps> = (
	props: TabTitleContentProps
) => (
	<div className={styles.container}>
		{EmailTypesTitle[props.title]}
		{props.showCloseIcon && (
			<Button
				transparent
				type="default"
				icon="close"
				onClick={props.onClose}
				className={styles.iconButton}
			/>
		)}
	</div>
);

interface ExtendedMessagingContentState {
	messages: ExtendedMessage[];
	sendMessages: ExtendedMessage[];
}

interface ExtendedMessagingContentProps {
	closeExtendedMessagingModal: () => void;
	mailType: EXTENDED_MAIL_TYPE;
	// from mapStateToProps
	attachmentList: DocumentWithOriginalFile[];
	messages: ExtendedMessage[];
	extendedMessage: ExtendedMessagingData;
	isUploadingAttachment: boolean;
	isMessageSending: boolean;
	sentThreadId: string | undefined;
	// from mapDispatchToProps
	removeAttachment: typeof removeAttachment;
	sendExtendedmessage: typeof sendExtendedmessage;
	setPortJobContext: typeof setPortJobContext;
}

class ExtendedMessagingContent extends React.Component<
	ExtendedMessagingContentProps,
	ExtendedMessagingContentState
> {
	attachmentAddInProgress = false;
	attachmentAddTo = '';
	constructor(props: ExtendedMessagingContentProps) {
		super(props);
		this.state = {
			messages: [],
			sendMessages: []
		};
	}
	componentDidMount() {
		const [sendWithoutDisplayMessages, displayMessages] = partition(
			this.props.messages,
			['isExtendedMessageDisplay', false]
		);
		this.setState({
			messages: [...displayMessages],
			sendMessages: [...sendWithoutDisplayMessages]
		});
	}
	componentDidUpdate(prevProps: ExtendedMessagingContentProps) {
		const { attachmentList, sentThreadId } = this.props;

		if (this.attachmentAddInProgress) {
			//As uploaded attachments from different tabs are storing in
			//redux-store in single property. So moving those to component
			//store and removing from the redux.
			if (attachmentList.length >= 1) {
				this.updateAttachments(this.attachmentAddTo, attachmentList);
				this.attachmentAddInProgress = false;
				this.props.removeAttachment({
					uid: attachmentList[0].originFileObj.uid
				});
			}
		}

		if (prevProps.sentThreadId === undefined && sentThreadId) {
			this.onClose(sentThreadId);
			this.props.setPortJobContext({ lastSentThreadId: undefined });
		}
	}
	private getMessageData(data: ExtendedMessage) {
		let text;
		if (data.isContentTouched && data.editedContent) {
			text = convertToHtml(data.editedContent, [RichTextNodeSection.CONTENT]);
		} else {
			text = data.content;
		}
		return {
			message: {
				subject: data.subject || 'No subject',
				from: data.from,
				to: data.to.map((contact: UserContact) => ({
					email: contact.email
				})),
				cc: data.cc.map((contact: UserContact) => ({
					email: contact.email
				})),
				updateThreadStatus: data.updateThreadStatus,
				content: text,
				contentHistory: '',
				messageType: 'ManageAppointment',
				attachments: data.attachments,
				isQuickResponse: data.isQuickResponse,
				emailType: data.emailType
			},
			jobCodes: data.jobCodes,
			contextId: data.contextId,
			subject: data.subject,
			isProtected: true,
			isRead: false,
			assignedGroup: data.assignedGroup,
			assignedInbox: data.assignedInbox,
			threadId: data.threadId
		};
	}
	onClose = (threadId: string) => {
		const messages = this.state.messages;
		remove(messages, message => message.threadId === threadId);
		this.setState(
			{
				messages: [...messages]
			},
			() => {
				if (this.state.messages.length === 0) {
					if (this.state.sendMessages.length !== 0) {
						this.state.sendMessages.forEach(message => {
							const data = this.getMessageData(message);
							this.props.sendExtendedmessage(data);
						});
					}
					this.props.closeExtendedMessagingModal();
				}
			}
		);
	};
	updateRecipient = (
		target: RecipientType,
		recipients: UserContact[],
		threadId: string
	) => {
		const values = uniqBy(recipients, (contact: UserContact) => contact.email);
		const messages = this.state.messages.map(message =>
			message.threadId === threadId ? { ...message, [target]: values } : message
		);
		this.setState({
			messages: [...messages]
		});
	};
	updateSubject = (threadId: string, value: string) => {
		const messages = this.state.messages.map(message =>
			message.threadId === threadId ? { ...message, subject: value } : message
		);

		this.setState({
			messages: [...messages]
		});
	};
	updateAttachments = (threadId: string, attachment: Attachment[]) => {
		const messages = this.state.messages.map(message =>
			message.threadId === threadId
				? { ...message, attachments: [...message.attachments, ...attachment] }
				: message
		);

		this.setState({
			messages: [...messages]
		});
	};

	onRemoveAttachment = (threadId: string, id: string) => {
		// newly uploaded attachments are saved to global store, old ones stay locally
		// we do all below in order not to lose local attachments
		const messages = this.state.messages.map(message =>
			message.threadId === threadId
				? { ...message, attachments: reject(message.attachments, { id }) }
				: message
		);
		this.setState({
			messages: [...messages]
		});
	};

	onChangeTextEditor = (threadId: string, value: RichTextValue) => {
		const messages = this.state.messages.map(message =>
			message.threadId === threadId
				? {
						...message,
						editedContent: value,
						isContentTouched: true
				  }
				: message
		);
		this.setState({
			messages: [...messages]
		});
	};

	onAddAttachmentStarted = (threadId: string) => {
		this.attachmentAddInProgress = true;
		this.attachmentAddTo = threadId;
	};

	isFormInvalid = (message: ExtendedMessage) => {
		if (message.isContentTouched) {
			return (
				!message.editedContent ||
				isRichTextEmpty(message.editedContent, [RichTextNodeSection.CONTENT]) ||
				message.to.length === 0 ||
				getRecipientsError(message.to) ||
				getRecipientsError(message.cc)
			);
		} else {
			return (
				message.to.length === 0 ||
				getRecipientsError(message.to) ||
				getRecipientsError(message.cc)
			);
		}
	};

	sendAllButtonValidation = () => {
		const isValid = this.state.messages.map(
			message => !this.isFormInvalid(message)
		);
		return every(isValid, Boolean);
	};

	onSubmit = (threadId: string) => {
		const message = this.state.messages.find(
			message => message.threadId === threadId
		);
		if (message) {
			const data = this.getMessageData(message);
			this.props.sendExtendedmessage(data);
		}
	};

	onSendAllClick = () => {
		this.state.messages.forEach(message => {
			const data = this.getMessageData(message);
			this.props.sendExtendedmessage(data);
		});
	};

	render() {
		const { TabPane } = Tabs;
		const { isUploadingAttachment, isMessageSending, mailType } = this.props;

		const isShowAllAndCloseButtonVisible = [
			EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL,
			EXTENDED_MAIL_TYPE.INVITATION_EMAIL
		].includes(mailType);

		return (
			<Tabs
				tabBarExtraContent={
					<Align align="right">
						{isShowAllAndCloseButtonVisible && (
							<Button
								type="primary"
								disabled={
									this.props.messages.length === 0 ||
									!this.sendAllButtonValidation() ||
									isUploadingAttachment ||
									isMessageSending
								}
								onClick={this.onSendAllClick}
							>
								Send All
							</Button>
						)}
					</Align>
				}
				type="card"
				className={styles.tabPane}
				fit
			>
				{this.state.messages.map(message => (
					<TabPane
						tab={
							<TabTitleContent
								title={message.emailType}
								onClose={() => this.onClose(message.threadId)}
								showCloseIcon={isShowAllAndCloseButtonVisible}
							/>
						}
						key={message.threadId}
					>
						<MessageContainer
							message={message}
							onAddAttachmentStarted={this.onAddAttachmentStarted}
							updateRecipient={this.updateRecipient}
							updateSubject={this.updateSubject}
							onRemoveAttachment={this.onRemoveAttachment}
							onChangeTextEditor={this.onChangeTextEditor}
							isFormValid={!this.isFormInvalid(message)}
							isUploadingAttachment={isUploadingAttachment}
							onSubmit={this.onSubmit}
							isMessageSending={isMessageSending}
						/>
					</TabPane>
				))}
			</Tabs>
		);
	}
}

export default connect(
	(state: AppState) => {
		return {
			attachmentList: getDocumentsAttachmentsSelector(state),
			messages: getExtendedMessages(state),
			extendedMessage: getExtendedMessageData(state),
			isUploadingAttachment: isUploadingAttachmentSelector(state),
			isMessageSending: isExtendedMessageSending(state),
			sentThreadId: getSentThreadId(state)
		};
	},
	{
		removeAttachment,
		sendExtendedmessage,
		setPortJobContext
	}
)(ExtendedMessagingContent);
