import { Entity, EntityCode, MetadataAction } from 'app-types';
import { RotationStepTypes } from '../vesselProgramme/vesselProgrammeServiceTypes';
import {
	ServiceSectionCode,
	FinanceStatus
} from '../finance/financeServiceTypes';
import { OperationTypeCode } from '../operations/operationsServiceTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import {
	PortCallStatus,
	PortCallDuplicate
} from 'services/api/portCalls/portCallsServiceTypes';
import { RetrieveCompanyVessel } from '../companies/companiesServiceTypes';
import { ResultCollection } from '../apiTypes';

export enum PortCallMetadataActionCode {
	EDIT = 'edit',
	EDIT_COMMENT = 'editComment',
	DELETE = 'delete',
	REFRESH_EVENTS = 'refreshEvents'
}

export type PortCallMetadataAction = MetadataAction<PortCallMetadataActionCode>;

export enum PortCallEventStatus {
	CREATED = 'Created',
	ESTIMATED = 'Estimated',
	RECORDED = 'Recorded',
	DID_NOT_HAPPEN = 'DidNotHappen'
}

export enum Level {
	OPERATION = 'Operation',
	OPERATION_UNIT = 'OperationUnit',
	ROTATION_STEP = 'RotationStep'
}

export enum PortCallEventDeletable {
	YES = 'Yes',
	NO = 'No',
	COMMENT = 'Comment'
}

export type PortCallSectionCode =
	| ServiceSectionCode
	| RotationStepTypes
	| OperationTypeCode;

interface ParentPortCallSection {
	name: string;
	code: PortCallSectionCode;
	elements: PortCallEvent[];
	sections: PortCallSection[];
	rank: number;
}
export interface PortCallSection extends ParentPortCallSection {
	id: string;
	meta?: PortCallEventSectionMeta;
}

export interface RetrievePortCallSectionsRequest {
	portCallId: string;
	isSilent?: boolean;
	eventId?: string;
}

export type RetrievePortCallSectionsResponse = PortCallSection[];

export interface AssignedPortJob extends Entity {}

export interface PortCallEvent {
	id: string;
	code: string;
	name: string;
	error: string | {} | null;
	status: PortCallEventStatus;
	comment: string;
	datePlt: string | null;
	isMandatory: boolean;
	isVesselProgrammeEvent: boolean;
	deletable: PortCallEventDeletable;
	rank: number;
	portJobs: AssignedPortJob[];
	isJobAssignmentEditable: boolean;
	concurrencyToken: number;
}

export interface PortCallEventSectionMeta
	extends Partial<PortCallEventSectionBaseMeta>,
		Partial<RotationStepMeta> {
	from?: PortCallEventSectionBaseMeta;
	to?: PortCallEventSectionBaseMeta;
	parent?: PortCallEventSectionBaseMeta;
}

interface PortCallEventSectionBaseMeta {
	name: string;
	poiType: RotationStepTypes;
	poiTypeName: string;
	level: Level;
	titleParams: string[];
}

interface RotationStepMeta {
	cargoRefNumber: string;
	voyageRef: string;
	mainCommodity: EntityCode;
	commodity: Entity;
	quantity: number;
	shipperReceiver: Entity;
	unitOfMeasurementCode: string;
	laycanFrom?: string;
	laycanTo?: string;
	splitIndex?: number;
	loadDischargePort: Entity;
	terms: string;
}

export interface EndOfSeaPassageRequest {
	portCallId: string;
	eventCode: string;
	etsPlt: string;
}
export interface EndOfSeaPassageResponse {}
export interface CommenceOfSeaPassageRequest {
	portCallId: string;
	eventCode: string;
	etsPlt: string;
}
export interface CommenceOfSeaPassageResponse {}

export interface UpdatePortCallEventRequest {
	realDatePlt: string | null;
	comment: string;
	portCallId: string;
	eventId: string;
	concurrencyToken: number;
}

export interface UpdateEventResponse {
	status: PortCallEventStatus;
}

export interface PortCallEventTemplate {
	code: string;
	name: string;
	prospectName: string;
	isCommentMandatory: boolean;
	hasAlreadyBeenAdded: boolean;
	scopeType: string;
}

// Retrieve port call event templates

export interface RetrievePortCallEventTemplatesRequest {
	portCallId: string; // url parameter
	parentId: string;
	parentType: Level;
	parentCode: PortCallSectionCode; // used for key creation
}

export type RetrievePortCallEventTemplatesResponse = PortCallEventTemplate[];

// Add port call event
export interface AddPortCallEventRequest {
	eventCode: string;
	portCallId: string; // url parameter
	parentId: string;
	parentType: Level;
	parentCode: PortCallSectionCode; // used for key creation
}

export type AddPortCallEventResponse = PortCallEvent[];

export interface DeletePortCallEventRequest {
	eventId: string;
	portCallId: string;
	comment?: string;
	concurrencyToken: number;
}

export interface RestorePortCallEventRequest {
	eventId: string;
	portCallId: string;
	concurrencyToken: number;
}

export interface UpdatePortCallRequest {
	portCallId: string;
	payload: {
		vessel: {
			id: string;
			name: string;
		};
		etaPlt: string;
		etsPlt: string | null;
		concurrencyToken: number;
		externalCode?: string;
	};
}

export interface UpdatePortCallResponse {
	portCallId: string;
	payload: {
		vessel: {
			id: string;
			name: string;
		};
		etaPlt: string;
		etsPlt: string | null;
	};
}

export interface AccessiblePortJob {
	id: string;
	code: string;
	customerCompany: {
		id: string;
		name: string;
	};
	status: PortJobStatus;
	financeStatus: FinanceStatus;
	isOperationsDisabled: boolean;
}

export interface RetrievePortCallAccessiblePortJobsResponse {
	id: string;
	port: {
		id: string;
		name: string;
	};
	vessel: {
		id: string;
		name: string;
	};
	status: PortCallStatus;
	etaPlt: string;
	etaUtc: string;
	portCountryCode: string;
	jobs: AccessiblePortJob[];
	containsAppointedJobs: boolean;
	lastUpdatedOnUtc: string;
}

export interface UpdatePortCallEventJobAssignmentRequest {
	portCallId: string;
	eventId: string;
	portJobs: AccessiblePortJob[];
	concurrencyToken: number;
}

export interface RetrievePortCallCompanyVesselsRequest {
	portCallId: string;
}
export type RetrievePortCallCompanyVesselsResponse = ResultCollection<{
	companyId: string;
	companyName: string;
	companyVesselDetails: RetrieveCompanyVessel;
}>;

export interface RetrievePortCallMetadataRequest {
	portCallId: string;
}
export interface RetrievePortCallMetadataResponse {
	actions: PortCallMetadataActionCode[];
}

export interface RetrievePortCallDuplicatesRequest {
	portCallId: string;
}

export type RetrievePortCallDuplicatesResponse = PortCallDuplicate[];

export interface RetrievePortCallActAsRequest {
	portCallId: string;
}
export type PortCallActAs = Entity & {
	agentId?: string;
	disabled?: boolean;
};

export type RetrievePortCallActAsResponse = ResultCollection<PortCallActAs>;
