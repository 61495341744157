import {
	validatePhoneFormatRequired,
	validateEmailRequired,
	validateNameMaxRequired,
	validateNameMax
} from 'utils/validations';
import { FormErrors } from 'redux-form';
import { UserDetailsFormData } from 'services/api/users/userServiceTypes';

export default function(
	values: UserDetailsFormData
): FormErrors<UserDetailsFormData> {
	return {
		cellphone: validatePhoneFormatRequired(values.cellphone),
		email: validateEmailRequired(values.email),
		firstName: validateNameMaxRequired(values.firstName),
		middleName: validateNameMax(values.middleName),
		lastName: validateNameMaxRequired(values.lastName)
	};
}
