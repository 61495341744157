import React from 'react';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';
import { Gap } from 'components';
import { VESSEL_INFO_SECTIONS } from 'sections/Administration/Configuration/Vessels/VesselDetailsCollapse/Sections/constants';
import VesselDetailsSections from 'sections/Administration/Configuration/Vessels/VesselDetailsCollapse/Sections/VesselDetailsSections';

interface VesselDetailsContentProps {
	vesselDetails: VesselDetails;
}

const VesselDetailsContent = ({ vesselDetails }: VesselDetailsContentProps) => (
	<Gap left="xs" top bottom isBlock>
		<VesselDetailsSections
			sections={VESSEL_INFO_SECTIONS}
			vesselDetails={vesselDetails}
		/>
	</Gap>
);

export default VesselDetailsContent;
