import * as React from 'react';
import { ColProps, Col } from 'components/antd/Grid/Col';
import { LabeledText, Gap } from 'components';
import { GapProps } from 'components/Styles/Gap/Gap';
import { LabeledTextProps } from 'components/LabeledText/LabeledText';
import { breakWord } from './SupplierDetailsColumn.module.scss';

interface SupplierDetailsColumnProps
	extends Partial<
			Pick<LabeledTextProps, 'label' | 'value' | 'hasColon' | 'type'>
		>,
		Pick<GapProps, 'isBlock' | 'bottom'>,
		ColProps {}

/**
 * Centralize the common Gap between columns
 */
const SupplierDetailsColumn: React.SFC<SupplierDetailsColumnProps> = ({
	label,
	value,
	hasColon,
	type,
	xs = 6,
	sm,
	md,
	lg,
	children,
	isBlock = true,
	bottom = 'md',
	...props
}) => (
	<Col {...props} xs={xs} sm={sm} md={md} lg={lg}>
		<Gap isBlock={isBlock} bottom={bottom} className={breakWord}>
			{label && value ? (
				<LabeledText
					type={type ? type : 'vertical'}
					label={label}
					value={value}
					hasColon={hasColon}
					align="baseline"
				/>
			) : (
				children
			)}
		</Gap>
	</Col>
);

export default SupplierDetailsColumn;
