import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { initialState } from './regionsState';
import { retrieveRegionsAsync, resetRegions } from './actions';
import { onRetrieveRegions, onResetRegions } from './reducers';

export default reducerWithInitialState(initialState)
	.case(retrieveRegionsAsync.started, onRetrieveRegions.started)
	.case(retrieveRegionsAsync.done, onRetrieveRegions.done)
	.case(retrieveRegionsAsync.failed, onRetrieveRegions.failed)
	.case(resetRegions, onResetRegions);
