import React from 'react';
import { Title, Gap, Text } from 'components';
import { CompanyVessel } from 'services/api/companies/companiesServiceTypes';
import { Col, Row } from 'components/antd';
import ownStyles from './VesselInfo.module.scss';
import OptionalValue from 'components/OptionalValue/OptionalValue';
import styles from 'sections/Administration/Configuration/Vessels/VesselDetailsCollapse/Sections/VesselDetailsSections.module.scss';

interface SectionProps {
	label: string;
	isLast?: boolean;
}
const Section: React.SFC<SectionProps> = ({ label, children }) => (
	<Gap top left="lg" isBlock>
		<Title.Subject>
			<Text upper>{label}:</Text>
		</Title.Subject>
		<Row>{children}</Row>
	</Gap>
);

const Column: React.SFC<{ large?: boolean }> = ({ large, children }) => (
	<Col xs={large ? 6 : 3}>
		<table className={styles.sectionColumn}>
			<tbody>{children}</tbody>
		</table>
	</Col>
);

interface AttributeProps {
	label: string;
	value?: string;
}
const Attribute = ({ label, value }: AttributeProps) => (
	<tr>
		<td>{label}:</td>
		<td>
			<OptionalValue value={value} />
		</td>
	</tr>
);

const ContractDetailsAttribute = ({
	value,
	label = 'Additional Contact Details'
}: {
	value?: string;
	label?: string;
}) =>
	value ? (
		<Gap isBlock left>
			<Gap isBlock bottom="sm">
				{label}:
			</Gap>
			<Text weight="bold">{value}</Text>
		</Gap>
	) : (
		<Column>
			<Attribute label={label} value={value} />
		</Column>
	);

interface CompanyVesselDetailsContentProps {
	companyVessel: CompanyVessel;
}
const CompanyVesselDetailsContent = ({
	companyVessel
}: CompanyVesselDetailsContentProps) => (
	<Gap left isBlock>
		<Section label="General Details">
			<Column>
				<Attribute
					label="Principal Vessel Type"
					value={companyVessel.companyVesselTypeMapping.name}
				/>
			</Column>
			<Column>
				<Attribute
					label="Principal Number"
					value={companyVessel.externalCode}
				/>
			</Column>
		</Section>
		<hr className={ownStyles.division} />
		<Section label="Contact Details">
			<Column large>
				<Attribute label="Email" value={companyVessel.email} />
			</Column>
			<Column large>
				<Attribute
					label="Operator Email(s)"
					value={companyVessel.operatorEmails.join(', ')}
				/>
			</Column>
			<Column>
				<Attribute label="Telephone 1" value={companyVessel.telephone1} />
				<Attribute label="Telex 1" value={companyVessel.telex1} />
			</Column>
			<Column>
				<Attribute label="Telephone 2" value={companyVessel.telephone2} />
				<Attribute label="Telex 2" value={companyVessel.telex2} />
			</Column>
			<Column>
				<Attribute label="Mobile Phone" value={companyVessel.mobilePhone} />
			</Column>
			<Column>
				<Attribute label="Fax" value={companyVessel.fax} />
			</Column>
			<ContractDetailsAttribute
				value={companyVessel.additionalContactDetails}
			/>
		</Section>
	</Gap>
);
export default CompanyVesselDetailsContent;
