import { BankAccountsState } from '../bankAccountsState';

export const onSetActiveBankId = (
	state: BankAccountsState,
	activeBankId: string
): BankAccountsState => ({
	...state,
	context: {
		...state.context,
		activeBankId
	}
});
