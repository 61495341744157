import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setFilters, destroy, clearOne } from './actions';
import { initialState } from './filtersState';

import { onSetFilters, onDestroy, onClearOne } from './reducers';

export default reducerWithInitialState(initialState)
	.case(setFilters, onSetFilters)
	.case(destroy, onDestroy)
	.case(clearOne, onClearOne);
