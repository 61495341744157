import * as React from 'react';
import { connect } from 'react-redux';

import { Collapse } from 'components/antd';
import { BANK_ACCOUNT_FORM } from '../BankAccountConstants';
import ReduxForm from 'components/ReduxForm/ReduxForm';
import GeneralFormFields from './GeneralFormFields';
import CountrySpecificFormFields from './CountrySpecificFormFields';
import { addBankAccount } from 'store/masterdata/bankAccounts/actions';

interface AddBankAccountFormProps {
	// mapDispatchToProps
	addBankAccount: typeof addBankAccount;
}

enum Panel {
	GENERAL = 'general',
	COUNTRY_SPECIFIC = 'countrySpecific'
}

const AddBankAccountForm = (props: AddBankAccountFormProps) => (
	<ReduxForm form={BANK_ACCOUNT_FORM} onSubmit={() => props.addBankAccount()}>
		<Collapse defaultActiveKey={[Panel.GENERAL]} type="primary">
			<Collapse.Panel key={Panel.GENERAL} header="General Information">
				<GeneralFormFields />
			</Collapse.Panel>
			<Collapse.Panel
				key={Panel.COUNTRY_SPECIFIC}
				header="Country Specific Information"
			>
				<CountrySpecificFormFields />
			</Collapse.Panel>
		</Collapse>
	</ReduxForm>
);

export default connect(null, { addBankAccount })(AddBankAccountForm);
