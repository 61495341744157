import actionCreator from 'store/finance/financeActionCreator';
import {
	UpdateServiceStatusPayload,
	UpdateServiceStatusRequest as request,
	PortJobService
} from 'services/api/finance/financeServiceTypes';

const UPDATE_SERVICE_STATUS = 'UPDATE_SERVICE_STATUS';

export const updateServiceStatusAsync = actionCreator.async<
	request,
	PortJobService,
	Error
>(UPDATE_SERVICE_STATUS, {
	behaviour: { type: 'react-to-changes-child' }
});

export const updateServiceStatus = actionCreator<UpdateServiceStatusPayload>(
	UPDATE_SERVICE_STATUS
);
