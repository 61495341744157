import actionCreatorFactory from 'typescript-fsa';
import { BreadcrumbItem } from '../navigationState';

/**
 * Actions
 */

const actionCreator = actionCreatorFactory();

const SET_BREADCRUMBS = 'navigation/SET_BREADCRUMBS';
export const setBreadcrumbs = actionCreator<BreadcrumbItem[]>(SET_BREADCRUMBS);
