import actionCreator from '../companyVesselsActionCreator';
import {
	CompanyVesselData,
	UpdateCompanyVesselRequest
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'UPDATE_COMPANY_VESEL';

export const updateCompanyVesselAsync = actionCreator.async<
	UpdateCompanyVesselRequest,
	CompanyVesselData,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Vessel'
	}
});

export const updateCompanyVessel = actionCreator<UpdateCompanyVesselRequest>(
	ACTION_NAME
);
