import { FormFieldName } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import {
	Page1FormValues,
	FormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { first, some } from 'lodash';
import runValidations, {
	validateDateIsAfter,
	isAfter,
	isDateValid,
	validateRequiredPortsWithPermission,
	validateRequired,
	required
} from 'utils/validations';

import { PageFormProps, PageBaseProps } from './Form';
import { LabeledValue } from 'app-types';
import { FormWarnings } from 'redux-form';

export const validatePortCall = ({
	vessel,
	port,
	etaPlt,
	etsPlt
}: {
	vessel: LabeledValue;
	port: LabeledValue;
	etaPlt: string;
	etsPlt?: string;
}) => {
	const isAfterETA = isAfter(etaPlt, 'ETA must be earlier than ETS');
	return {
		vessel: validateRequired(vessel),
		port: validateRequiredPortsWithPermission(port),
		etaPlt: first(runValidations(etaPlt, [required, isDateValid])),
		etsPlt: first(runValidations(etsPlt, [isDateValid, isAfterETA]))
	};
};

const validate = (
	values: Page1FormValues,
	props: PageBaseProps
): Partial<Page1FormValues> => {
	const portCall = values.portCall || {};
	const isAssignMode = props.isAssignMode;

	const portCallErrors = {
		vessel: validateRequired(portCall.vessel),
		port: validateRequiredPortsWithPermission(portCall.port),
		etaPlt:
			first(
				runValidations(portCall.etaPlt, [
					...(!isAssignMode ? [required] : []),
					isDateValid
				])
			) || '',
		etsPlt:
			first(
				runValidations(portCall.etsPlt, [
					isDateValid,
					validateDateIsAfter(portCall.etaPlt, 'ETA must be earlier than ETS')
				])
			) || ''
	};

	const portCallHasErrors = some(portCallErrors, value => value !== undefined)
		? portCallErrors
		: undefined;

	if (!props.isCurrentUsersGroupTypeISSCluster) {
		return {
			portCall: portCallHasErrors,
			hubPrincipalCompany: validateRequired(values.hubPrincipalCompany)
		};
	}

	return {
		portCall: portCallHasErrors
	};
};

export const warn = (
	values: FormData,
	props: PageFormProps
): FormWarnings<FormData> => {
	const warnings = {
		_warning: ''
	};
	if (props.hasDuplication && !values[FormFieldName.CREATE_NEW_PORT_CALL]) {
		warnings._warning = 'Duplicated port call(s) exist';
	}
	return warnings as FormWarnings<FormData>;
};

export default validate;
