import { all, fork } from 'redux-saga/effects';
import retrieveGroupsWatcher from './retrieveGroupsSaga';
import { retrieveGroupWatcher } from './retrieveGroupSaga';
import { retrieveGroupScopePermissionsWatcher } from './retrieveGroupScopePermissionsSaga';
import addGroupWatcher from './addGroupSaga';
import { updateGroupWatcher } from './updateGroupSaga';
import deleteGroupWatcher from './deleteGroupSaga';
import initializeEditGroupWatcher from './initializeEditGroupSaga';
import retrieveThirdPartyLPAGroupsWatcher from './retrieveThirdPartyLPAGroupsSaga';

export default function*() {
	yield all([
		fork(addGroupWatcher),
		fork(deleteGroupWatcher),
		fork(retrieveGroupScopePermissionsWatcher),
		fork(retrieveGroupsWatcher),
		fork(retrieveGroupWatcher),
		fork(updateGroupWatcher),
		fork(initializeEditGroupWatcher),
		fork(retrieveThirdPartyLPAGroupsWatcher)
	]);
}
