import { call, put, takeLatest, select } from 'redux-saga/effects';
import { waitAsyncResolve } from 'utils/sagaHelpers/sagaUtils';

import { closeAllModals } from 'store/modals/actions';
import {
	addPrincipalService,
	addPrincipalServiceAsync,
	updatePrincipalService,
	updatePrincipalServiceAsync
} from 'store/configurationServices/actions';

import { onAddUpdatePrincipalServiceSubmit } from '../actions';

import {
	getPrincipalServiceFormValues,
	getPrincipalServiceModalState
} from '../selectors';
import { getActiveCompanyId } from 'store/configuration/selectors';

import { Form, PrincipalServicesFormData } from '../principalServicesFormData';

export function* worker() {
	const values = yield select(getPrincipalServiceFormValues);
	const { adding } = yield select(getPrincipalServiceModalState);

	let result;
	if (adding) {
		result = yield call(addWorker, values);
	} else {
		result = yield call(updateWorker, values);
	}

	if (!result.error) {
		yield put(closeAllModals());
		return;
	}
}

export function* addWorker(values: PrincipalServicesFormData) {
	yield put(
		addPrincipalService(
			{
				name: values.name,
				principalServiceCode: values.principalServiceCode,
				mainPrincipalId: yield select(getActiveCompanyId),
				principalServiceCategoryId: values.principalServiceCategoryId
			},
			{
				behaviour: {
					type: 'submit',
					formName: Form.PRINCIPAL_SPECIFIC_SERVICES
				}
			}
		)
	);

	const action = yield call(waitAsyncResolve, addPrincipalServiceAsync);
	return action;
}

export function* updateWorker(values: PrincipalServicesFormData) {
	yield put(
		updatePrincipalService(
			{
				id: values.id,
				data: {
					name: values.name,
					principalServiceCode: values.principalServiceCode,
					serviceCodes: (values.services || []).map(service => service.code),
					principalServiceCategoryId: values.principalServiceCategoryId
				}
			},
			{
				behaviour: {
					type: 'submit',
					formName: Form.PRINCIPAL_SPECIFIC_SERVICES
				}
			}
		)
	);
	return yield call(waitAsyncResolve, updatePrincipalServiceAsync);
}

export default function*() {
	yield takeLatest(onAddUpdatePrincipalServiceSubmit.type, worker);
}
