import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { UpdateSavingsRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { updateSavingsAsync as async } from 'store/portJobs/actions';
import { getActivePortJobCode } from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';

const apiCall = Api.PortJobs.updateSavings;

export const executor = function*(payload: UpdateSavingsRequest): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const request = {
		...payload,
		portCallId,
		portJobCode
	};
	yield put(async.started(request));
	try {
		const response = yield call(apiCall, request);
		yield put(
			async.done({
				result: undefined,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
};

export const updateSavingsWatcher = makeTakeLatestWatcher<
	UpdateSavingsRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async
	},
	executor
);

export default updateSavingsWatcher;
