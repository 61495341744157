import { Success } from 'typescript-fsa';
import { GroupUsersState } from '../groupUsersState';
import {
	AddOrDeleteGroupUserRequest,
	AddOrDeleteGroupUserResponse
} from 'services/api/groups/groupsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { uniq } from 'lodash';

export function onAddGroupUserStarted(state: GroupUsersState): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		adding: FetchStatus.PENDING
	};
	return {
		...state,
		fetchStatuses
	};
}

export function onAddGroupUserSuccess(
	state: GroupUsersState,
	action: Success<AddOrDeleteGroupUserRequest, AddOrDeleteGroupUserResponse>
): GroupUsersState {
	const { result, params } = action;
	const { groupId } = params;

	const byGroupId = {
		...state.byGroupId,
		[groupId]: uniq([...(state.byGroupId[groupId] || []), result.id])
	};
	const byId = {
		...state.byId,
		[result.id]: result
	};
	const fetchStatuses = {
		...state.fetchStatuses,
		adding: FetchStatus.SUCCESS
	};
	return {
		...state,
		fetchStatuses,
		byGroupId,
		byId
	};
}

export function onAddGroupUserFailed(state: GroupUsersState): GroupUsersState {
	const fetchStatuses = {
		...state.fetchStatuses,
		adding: FetchStatus.FAILURE
	};
	return {
		...state,
		fetchStatuses
	};
}
