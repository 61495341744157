import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	setActiveDraftType,
	setActivePOIContext,
	setActiveTerminalId,
	addCompanyDraft,
	addCompaniesDraft,
	addPOIDraft,
	addPOIDrafts,
	resetDrafts,
	setActiveDraftFormAndField,
	resetDraftsContext
} from './actions';
import {
	onSetActiveDraftType,
	onSetActivePOIContext,
	onSetActiveTerminalId,
	onAddCompanyDraft,
	onAddCompaniesDraft,
	onAddPOIDraft,
	onAddPOIDrafts,
	onResetDrafts,
	onSetActiveDraftFormAndField,
	onResetDraftsContext
} from './reducers';
import { initialState } from './draftsState';
import { retrieveFullDirectBillAsync } from 'store/directBills/actions';
import { onRetrieveDirectBillVendorDrafts } from './reducers/retrieveDirectBillVendorDraftsReducer';

export default reducerWithInitialState(initialState)
	.case(addCompanyDraft, onAddCompanyDraft)
	.case(setActiveDraftFormAndField, onSetActiveDraftFormAndField)
	.case(addCompaniesDraft, onAddCompaniesDraft)
	.case(addPOIDraft, onAddPOIDraft)
	.case(addPOIDrafts, onAddPOIDrafts)
	.case(resetDrafts, onResetDrafts)
	.case(resetDraftsContext, onResetDraftsContext)
	.case(setActiveDraftType, onSetActiveDraftType)
	.case(setActivePOIContext, onSetActivePOIContext)
	.case(setActiveTerminalId, onSetActiveTerminalId)
	.case(retrieveFullDirectBillAsync.done, onRetrieveDirectBillVendorDrafts);
