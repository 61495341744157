import { createSelector } from 'reselect';
import { getStatusDefaultFilter } from 'sections/ThreadsList/ThreadsFilters/ThreadsFiltersSelectors';
import {
	PortCallMessagesFiltersParams,
	PortCallMessagesFilterParamName
} from 'store/portJobs/portCallMessagesFiltersSync';
import { getMessagesFilters } from 'store/portJobs/selectors';
import { threadsStatusesOptions } from 'types/sections/ThreadsTypes';
import { MessagesTabsEnum } from './MessagesListTypes';

export const getPortCallMessagesFilterValues = createSelector(
	getMessagesFilters,
	(values = {}): PortCallMessagesFiltersParams => {
		return {
			onlyUnread: values.onlyUnread ? values.onlyUnread[0].key : '',
			status: values.status ? { ...values.status[0] } : undefined
		};
	}
);

export const getDefaultFieldValues = (): PortCallMessagesFiltersParams => ({
	[PortCallMessagesFilterParamName.ONLY_UNREAD]: MessagesTabsEnum.All,
	[PortCallMessagesFilterParamName.STATUS]: getStatusDefaultFilter(
		threadsStatusesOptions
	)
});

export const getMessagesFilterFieldValues = createSelector(
	getPortCallMessagesFilterValues,
	(values): PortCallMessagesFiltersParams => {
		const defaultValues = getDefaultFieldValues();
		return {
			...values,
			[PortCallMessagesFilterParamName.ONLY_UNREAD]:
				values[PortCallMessagesFilterParamName.ONLY_UNREAD] ||
				defaultValues[PortCallMessagesFilterParamName.ONLY_UNREAD],
			[PortCallMessagesFilterParamName.STATUS]:
				values[PortCallMessagesFilterParamName.STATUS] ||
				defaultValues[PortCallMessagesFilterParamName.STATUS]
		};
	}
);
