import Api from 'services/api';
import { select, call } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { createFundingRequestAsync as async } from '../actions/createFundingRequest';
import {
	CreateNewFundingRequestRequest as Request,
	CreateNewFundingRequestResponse as Response,
	FundingRequestMetadataActionCode
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getFundingUpdate } from 'store/form/financeFundings/selectors/financeFundingFormSelectors';
import { getFundingRequestDaId } from 'store/financeFundings/selectors';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';

const api = Api.FinanceFundings.createFundingRequest;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async,
	*getApiParams({ action }: { action: FundingRequestMetadataActionCode }) {
		const portCallId = yield select(getActivePortCallId);
		const daInId = yield select(getFundingRequestDaId);
		const jobCode = yield select(getActivePortJobCode);
		const funding = yield select(getFundingUpdate);
		const request: Request = {
			portCallId,
			jobCode,
			action,
			request: {
				...funding,
				daInId,
				action
			}
		};
		return request;
	},
	*onSuccess() {
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);
		yield call(
			navigateTo,
			`/${PATHS.portCalls}/${portCallId}/jobs/${jobCode}/finance`
		);
	}
});
