import * as React from 'react';
import { Icon } from 'components/antd';
import { Flex } from 'components/Styles';
import { Justify } from 'components/types';
import styles from './Screens.module.scss';
const Home = () => {
	return (
		<Flex
			fit
			direction="vertical"
			justify={Justify.CENTER}
			align="center"
			className={styles.root}
		>
			<h1>Welcome to Optic</h1>
			<p>
				Please navigate using the{' '}
				<Icon color="standard" type="bars" className={styles.icon} /> menu in
				the top left of the screen.
			</p>
		</Flex>
	);
};

export default Home;
