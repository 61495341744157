import actionCreator from 'store/filters/actionCreator';
import { FilterBaseActionParam, FilterState } from 'store/filters/filtersState';

export interface FilterAddActionParam extends FilterBaseActionParam {
	filters?: FilterState;
	override?: boolean;
}

const SET_FILTERS = 'SET_FILTERS';
export const setFilters = actionCreator<FilterAddActionParam>(SET_FILTERS);
