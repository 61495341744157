import Api from 'services/api';
import {
	SplitServiceRequestPayload,
	SplitServiceRequest
} from 'services/api/finance/financeServiceTypes';
import {
	splitServiceAsync,
	closeSplitServiceModal
} from '../actions/splitService';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getSplitModalServiceId } from 'store/finance/selectors/financeServicesSelectors';

const apiCall = Api.Finance.splitService;

export const executor = function*(
	payload: SplitServiceRequestPayload,
	api: typeof apiCall
): SagaIterator {
	yield put(splitServiceAsync.started(payload));

	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const serviceId = yield select(getSplitModalServiceId);

	const request = {
		portCallId,
		jobCode,
		serviceId,
		payload
	} as SplitServiceRequest;
	try {
		const response = yield call(api, request);
		yield put(
			splitServiceAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			splitServiceAsync.failed({
				error,
				params: payload
			})
		);
	}
	yield put(closeSplitServiceModal());
};

function* worker({
	payload
}: Action<SplitServiceRequestPayload>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function* splitServiceWatcher(): SagaIterator {
	yield takeLatest(splitServiceAsync.type, worker);
}
