import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { IDropdownOption } from 'app-types';
import {
	DateSettings,
	DefaultTimezone,
	TimeSettings
} from 'store/registration/settings';
import { getCurrentUser } from './userSelectors';
import { find } from 'lodash';
import { getTimezonesOptionsSelector } from '../../../registration/selectors';

const getUserDateFormatSettings = createSelector(
	getCurrentUser,
	user => user.dateFormat || DateSettings.MonthFirst
);

const getUserTimeFormatSettings = createSelector(
	getCurrentUser,
	user => user.timeFormat || TimeSettings.Hours12
);

export const getUserDateFormat = createSelector(
	getUserDateFormatSettings,
	dateFormat =>
		dateFormat === DateSettings.DayFirst ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
);

const getUserTimeFormat = (timeOffset = false) =>
	createSelector(
		getUserTimeFormatSettings,
		timeFormat => {
			const formatOffSet = timeOffset ? 'z (Z)' : '';
			return timeFormat === TimeSettings.Hours12
				? `hh:mm A ${formatOffSet}`.trim()
				: `HH:mm ${formatOffSet}`.trim();
		}
	);

export const getTimeFormatWithOffset = (timeOffset = false) => {
	const formatOffSet = timeOffset ? 'z (Z)' : '';
	return `DD MMM YYYY HH:mm ${formatOffSet}`;
};

export const getUserFullDateFormat = (state: AppState, timeOffset = false) =>
	`${getUserDateFormat(state)} ${getUserTimeFormat(timeOffset)(state)}`;

export const getUserTimezone = createSelector(
	getCurrentUser,
	user => user.timeZone || DefaultTimezone
);

export const getPlaceholderByFormat = createSelector(
	getUserDateFormat,
	getUserTimeFormatSettings,
	(_state: AppState, showTime: boolean): boolean => showTime,
	(datePlaceholder, timeFormat, showTime) => {
		if (showTime) {
			const timePlaceholder =
				timeFormat === TimeSettings.Hours12 ? 'hh:mm am/pm' : 'HH:mm';
			return `${datePlaceholder} ${timePlaceholder}`;
		}

		return datePlaceholder;
	}
);

export const getFullUserTimeZoneData = createSelector(
	getUserTimezone,
	getTimezonesOptionsSelector,
	(userTimezone, timezones): IDropdownOption | undefined =>
		find(timezones, { value: userTimezone }) ||
		find(timezones, { value: DefaultTimezone })
);
