import {
	UpdateFundingRequestRequest as Request,
	UpdateFundingRequestResponse as Response,
	FundingRequestMetadataActionCode
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'UPDATE_FUNDING_REQUEST';
export const updateFundingRequestAsync = actionCreator.async<
	Request,
	Response,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Funding'
	}
});

export const updateFundingRequest = actionCreator<{
	fundingId?: string;
	action: FundingRequestMetadataActionCode;
}>(ACTION_NAME);
