import actionCreator from '../directBillsActionCreator';
import {
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse
} from 'services/api/directBills/directBillsServiceTypes';

const ACTION_NAME = 'RETRIEVE_FULL_DIRECT_BILL';

export const retrieveFullDirectBillAsync = actionCreator.async<
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse,
	Error
>(ACTION_NAME);

export const retrieveFullDirectBill = actionCreator<{ directBillId: string }>(
	ACTION_NAME
);
