import { ThreadReducerState } from '../threadState';
import { reducerWithoutInitialState } from 'typescript-fsa-reducers';
import { setCurrentMailboxCompany } from '../actions/setCurrentMailbox';

// TODO this reducer should have all actions related to this state part
export const threadContextReducer = reducerWithoutInitialState<
	ThreadReducerState['context']
>().case(
	setCurrentMailboxCompany,
	(context, messageCurrentSelectedMailboxCompany) => ({
		...context,
		messageCurrentSelectedMailboxCompany
	})
);
