import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from '../financeState';
import {
	DeleteUnitCostForm,
	DeleteUnitCostFormResponse
} from 'services/api/finance/financeServiceTypes';

export const onDeleteUnitCostForm = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	DeleteUnitCostForm,
	DeleteUnitCostFormResponse
>('fetchStatuses', 'deleteUnitForm');
