import * as React from 'react';
import { AppState } from 'store-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getIsFetchingSupplierInvoices } from 'store/supplierInvoice/selectors';
import SupplierInvoiceTable from './SupplierInvoiceTable/SupplierInvoiceTable';
import SupplierInvoiceLayout from '../../components/SupplierInvoiceLayout/SupplierInvoiceLayout';
import SupplierInvoiceHeader from '../../components/SupplierInvoiceHeader/SupplierInvoiceHeader';

export interface SupplierInvoicesListRouteProps
	extends RouteComponentProps<{
		supplierInvoiceId?: string;
	}> {
	isLoading: boolean;
}

const SupplierInvoicesListRoute: React.SFC<
	SupplierInvoicesListRouteProps
> = props => {
	return (
		<SupplierInvoiceLayout {...props} header={<SupplierInvoiceHeader />}>
			<SupplierInvoiceTable />
		</SupplierInvoiceLayout>
	);
};

export default connect((state: AppState) => ({
	isLoading: getIsFetchingSupplierInvoices(state)
}))(SupplierInvoicesListRoute);
