import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { getActiveModals } from 'store/modals/selectors';
import { closeModal, ModalParams } from 'store/modals/actions';
import ConfirmModal from './Confirm/ConfirmationModal';
import ConfirmLeavePage from './Confirm/ConfirmLeavePage';
import { AppState } from 'store-types';

const MODAL_TYPE_MAP = {
	confirm: ConfirmModal,
	confirmLeavePage: ConfirmLeavePage
};

interface ModalsProps {
	modals: ModalParams[];
	closeModal: typeof closeModal;
}

const Modals: React.SFC<ModalsProps> = (props: ModalsProps) => (
	<div>
		{map(props.modals, modal => {
			if (!modal.type) {
				return null;
			}
			const ComponentType = MODAL_TYPE_MAP[modal.type];
			return (
				<ComponentType
					key={modal.name}
					{...modal}
					closeModal={props.closeModal}
				/>
			);
		})}
	</div>
);

export default connect(
	(state: AppState) => ({
		modals: getActiveModals(state)
	}),
	{
		closeModal
	}
)(Modals);
