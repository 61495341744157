import React from 'react';
import { connect } from 'react-redux';

import tableStyles from 'components/antd/Table/Table.module.scss';
import { ScrollableLayout } from 'components';
import InfiniteTable from 'components/Table/InfiniteTable/InfiniteTable';
import { AppNotifications } from 'containers';
import { ModalContext } from 'context';

import UsersHeader from './UsersHeader/UsersHeader';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { UserProfile, User } from 'services/api/users/userServiceTypes';
import { retrieveUsers } from 'store/users/actions';
import {
	getAllUsersSelector,
	isUsersFetching,
	userHasMoreSelector
} from 'store/users/usersSelectors';

import getUserListColumns, { UserListColumns } from './UserListColumns';
import UsersModals from './UsersModals/UsersModals';
import { AppState } from 'store-types';

type UsersProps = ReturnType<typeof mapStateToProps> &
	typeof mapDispatchToProps & {
		profile: UserProfile;
		activeUser: User;
	};

class Users extends React.Component<UsersProps> {
	columns: UserListColumns;

	constructor(props: UsersProps) {
		super(props);

		this.columns = getUserListColumns();
	}

	render() {
		const { users, usersFetching } = this.props;

		return (
			<ModalContext.Provider>
				<ScrollableLayout>
					<RouteBreadcrumbs items={[{ title: 'Manage Users' }]} />
					<UsersHeader />
					<AppNotifications.Alert />
					<AppNotifications.Notification />
					<ScrollableLayout stretch>
						<AppNotifications.Table
							entityName="users"
							actionType={retrieveUsers.type}
						>
							{({ failedLoading }) => (
								<InfiniteTable
									className={tableStyles.headerShadowed}
									columns={this.columns}
									dataSource={users}
									locale={{
										emptyText: 'No more users to load'
									}}
									lazyLoad={{
										loading: usersFetching,
										onEnter: this.props.retrieveUsers,
										hasMore: this.props.hasMore,
										failedLoading
									}}
								/>
							)}
						</AppNotifications.Table>
					</ScrollableLayout>
					<UsersModals />
				</ScrollableLayout>
			</ModalContext.Provider>
		);
	}
}
const mapStateToProps = (state: AppState) => ({
	users: getAllUsersSelector(state),
	usersFetching: isUsersFetching(state),
	hasMore: userHasMoreSelector(state)
});
const mapDispatchToProps = {
	retrieveUsers
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
