import React from 'react';
import { connect } from 'react-redux';

import styles from './ThreadAssignToMeAlert.module.scss';

import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { Group } from 'services/api/groups/groupsServiceTypes';

import { Alert, Button } from 'components/antd';

import { patchThreadPermissions } from 'store/thread';

import { getUserIdSelector } from 'store/auth/selectors';
import { getThreadNewPermissionsSelector } from 'store/thread/selectors';
import { preparePermissions } from 'sections/Thread/threadUtils';
import { AppState } from 'store-types';

interface ThreadAssignToMeAlertProps {
	thread: ThreadItem;
	newPermissionGroups: Group[];
	currentUserId: string;
	currentUserAssignedToThread: boolean;
	// from mapDispatchToProps
	patchThreadPermissions: typeof patchThreadPermissions;
}

class ThreadAssignToMeAlert extends React.Component<
	ThreadAssignToMeAlertProps
> {
	onSelfAssign = () => {
		const { currentUserId, thread } = this.props;
		const newPermissions = preparePermissions(this.props.newPermissionGroups);
		this.props.patchThreadPermissions({
			threadId: thread.id,
			assignedToGroupId: thread.assignedToGroup.id,
			assignedToUserId: currentUserId,
			threadPermissionGroups: newPermissions
		});
	};

	render() {
		if (this.props.currentUserAssignedToThread) {
			return null;
		}
		const message = (
			<p>
				To be able to work on current Message you need to assign it to yourself.{' '}
				<Button type="primary" transparent onClick={this.onSelfAssign}>
					Assign to me
				</Button>
			</p>
		);
		return (
			<div className={styles.assignToMeAlert}>
				<Alert message={message} type="warning" showIcon />
			</div>
		);
	}
}

export default connect(
	(state: AppState) => ({
		currentUserId: getUserIdSelector(state),
		newPermissionGroups: getThreadNewPermissionsSelector(state)
	}),
	{
		patchThreadPermissions
	}
)(ThreadAssignToMeAlert);
