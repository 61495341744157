import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { Currency } from 'services/api/currencies/currenciesServiceTypes';

const getCurrenciesMap = (state: AppState): { [code: string]: Currency } =>
	state.currencies.byCode;

export const getCurrencies = createSelector(getCurrenciesMap, codes =>
	Object.values(codes)
);

export const getCurrencyByCode = (state: AppState, code: string) =>
	getCurrenciesMap(state)[code];

export const getCurrencyByCodeForAutocomplete = createSelector(
	getCurrencyByCode,
	currency => (currency && { ...currency, name: currency.code }) || undefined
);

export const getIsRetrieveCurrencies = (state: AppState) =>
	state.currencies.fetchStatuses.all;
