import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Modal } from 'components/antd';
import { closeModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import {
	CostSavingsFormData,
	TimeSavingsFormData,
	DELETE_SAVINGS_MODAL
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import {
	getUpdateCostSavings,
	getUpdateTimeSavings,
	getinitialStateId,
	getDeleteSavingFetchStatus
} from 'store/savings/savingsSelectors';
import { deleteCostSavings } from 'store/savings/actions/deleteCostSavings';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	deleteTimeSavings,
	resetCostSavingsContext,
	resetTimeSavingsContext
} from 'store/savings/actions';

interface DeleteSavingsModalProps {
	// from mapStateToProps
	activePortCallId: string;
	activePortJobCode: string;
	isModalVisible: boolean;
	initialValues: Partial<CostSavingsFormData>;
	initialValue: Partial<TimeSavingsFormData>;
	initialStateId: string;
	// from mapDispatchToProps
	resetCostSavingsContext: typeof resetCostSavingsContext;
	resetTimeSavingsContext: typeof resetTimeSavingsContext;
	deleteCostSavings: typeof deleteCostSavings;
	deleteTimeSavings: typeof deleteTimeSavings;
	closeModal: typeof closeModal;
}

const DeleteSavingsModal = ({
	activePortCallId,
	activePortJobCode,
	isModalVisible,
	initialValues,
	initialValue,
	initialStateId,
	...props
}: DeleteSavingsModalProps) => {
	const isOkDisabled = useSelector(getDeleteSavingFetchStatus);
	const onCancel = () => {
		if (initialValues.amountSaved) {
			props.closeModal(DELETE_SAVINGS_MODAL);
			props.resetCostSavingsContext();
		} else if (initialValue.hours) {
			props.closeModal(DELETE_SAVINGS_MODAL);
			props.resetTimeSavingsContext();
		}
	};
	const deleteSavings = () => {
		if (initialValues.amountSaved) {
			props.deleteCostSavings({
				portCallId: activePortCallId,
				jobCode: activePortJobCode,
				id: initialStateId
			});
		} else if (initialValue.hours) {
			props.deleteTimeSavings({
				portCallId: activePortCallId,
				jobCode: activePortJobCode,
				id: initialStateId
			});
		}
	};
	return (
		<Modal
			destroyOnClose
			visible={isModalVisible}
			title="Delete Category Line"
			size="sm"
			okText="Delete"
			okType="primary"
			onOk={() => deleteSavings()}
			confirmLoading={isOkDisabled}
			onCancel={onCancel}
		>
			{initialValues.category
				? `Are you sure you want to delete cost savings category -
			"${initialValues.category?.label}"?`
				: `Are you sure you want to delete time savings category -
			"${initialValue.category?.label}"?`}
		</Modal>
	);
};

export default connect(
	(state: AppState) => ({
		isModalVisible: isModalVisible(state, DELETE_SAVINGS_MODAL),
		initialValues: getUpdateCostSavings(state),
		initialValue: getUpdateTimeSavings(state),
		initialStateId: getinitialStateId(state),
		activePortCallId: getActivePortCallId(state),
		activePortJobCode: getActivePortJobCode(state)
	}),
	{
		resetCostSavingsContext,
		resetTimeSavingsContext,
		deleteCostSavings,
		deleteTimeSavings,
		closeModal
	}
)(DeleteSavingsModal);
