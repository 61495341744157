import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { isUndefined } from 'lodash';
import { AppState } from 'store-types';
import {
	getEditContactEmail,
	getEditContactMessageId,
	getEditContactBreadcrumbs
} from 'store/messages/selectors';

import {
	ButtonRow,
	ErrorPage,
	Link,
	ScrollableLayout,
	PageHeader,
	PageFooter
} from 'components';
import { navigateTo } from 'utils';

import EditContactForm, { EditContactFormData } from './EditContactForm';
import { Row, Col, Button } from 'components/antd';
import { Justify } from 'components/types';
import { getForm } from 'store/form/selectors';
import { FormStateGet } from 'store/form/formTypes';
import { ADD_CONTACT_FORM_ID } from './EditContactConstants';
import {
	addContact,
	updateContact,
	retrieveContact,
	resetCurrentContact
} from 'store/messages/actions';
import { getUserCompanyId } from 'store/auth/selectors';
import RouteBreadcrumbs from '../../App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation';
import { ContactType } from 'services/api/messages/messagesServiceTypes';

interface EditContactRouteProps extends RouteComponentProps<{}> {
	// from mapStateToPros
	breadcrumbs: BreadcrumbItem[];
	contactEmail: string;
	userCompanyId: string;
	messageId: string;
	isExistingContact: boolean;
	form: FormStateGet<EditContactFormData>;
	// from mapDispatchToProps
	addContact: typeof addContact;
	updateContact: typeof updateContact;
	retrieveContact: typeof retrieveContact;
	resetCurrentContact: typeof resetCurrentContact;
}

class EditContactRoute extends React.Component<EditContactRouteProps> {
	componentDidMount() {
		const { userCompanyId, contactEmail, isExistingContact } = this.props;
		if (isExistingContact) {
			this.props.retrieveContact({ userCompanyId, email: contactEmail });
		}
	}

	componentWillUnmount() {
		const { isExistingContact } = this.props;

		if (isExistingContact) {
			this.props.resetCurrentContact();
		}
	}

	goToMessages = () => navigateTo('/messages');

	onSubmit = () => {
		const { form, userCompanyId, contactEmail, isExistingContact } = this.props;
		const { values } = form;

		const request: ContactType = {
			userCompanyId,
			email: contactEmail,
			contactType: {
				code: values.contactType
			},
			relatesToCompany: {
				id: values.company.key
			},
			firstName: values.firstName,
			lastName: values.lastName,
			phone: values.phone,
			notes: values.notes,
			message: { id: this.props.messageId }
		};

		isExistingContact
			? this.props.updateContact(request)
			: this.props.addContact(request);
	};

	render() {
		if (!this.props.contactEmail) {
			return (
				<ErrorPage
					title={'Oops!'}
					description={`Either that's not a valid email or doesn't exist`}
				>
					<p>
						<Link href="/messages">Click here</Link> to return to Messages
					</p>
				</ErrorPage>
			);
		}

		const title = this.props.isExistingContact ? 'Edit Contact' : 'Add Contact';
		const saveBtnLabel = this.props.isExistingContact ? 'Save' : 'Add Contact';

		return (
			<ScrollableLayout stretch>
				<RouteBreadcrumbs items={this.props.breadcrumbs} />
				<PageHeader.NewTab
					subtitle="Contact Details"
					type="secondary"
					onClose={this.goToMessages}
				>
					{title}
				</PageHeader.NewTab>
				<ScrollableLayout asColumn>
					<Row>
						<Col md={4} sm={6}>
							<EditContactForm />
						</Col>
					</Row>
				</ScrollableLayout>
				<PageFooter>
					<Row>
						<Col sm={12} justify={Justify.END}>
							<ButtonRow>
								<Link href="/messages">Cancel</Link>
								<Button
									type={'primary'}
									disabled={!this.props.form.valid}
									onClick={this.onSubmit}
								>
									{saveBtnLabel}
								</Button>
							</ButtonRow>
						</Col>
					</Row>
				</PageFooter>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState, ownProps: EditContactRouteProps) => {
		const messageId = getEditContactMessageId(ownProps.location);
		const isExistingContact = isUndefined(messageId);

		return {
			breadcrumbs: getEditContactBreadcrumbs(isExistingContact),
			contactEmail: getEditContactEmail(ownProps.location),
			isExistingContact,
			messageId,
			userCompanyId: getUserCompanyId(state),
			form: getForm<EditContactFormData>(state, ADD_CONTACT_FORM_ID)
		};
	},
	{
		addContact,
		updateContact,
		retrieveContact,
		resetCurrentContact
	}
)(EditContactRoute);
