export enum ThreadsSettingsMenuAction {
	ASSIGN_TO = 'assign-to',
	CATEGORIZE_MESSAGES = 'categorize-messages'
}

export const ThreadsSettingsMenuModalMap = {
	[ThreadsSettingsMenuAction.ASSIGN_TO]: 'assign-to-modal',
	[ThreadsSettingsMenuAction.CATEGORIZE_MESSAGES]: 'categorize-messages-modal'
};

export const DEFAULT_MIN_LENGTH = 2;
