import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveInternalCommentsAsync } from './actions/retrieveInternalComments';
import { postInternalCommentsAsync } from './actions/postInternalComments';
import { initialState } from './internalCommentsState';
import {
	onRetrieveInternalCommentsDone,
	onRetrieveInternalCommentsFailed,
	onRetrieveInternalCommentsStarted,
	onAddInternalCommentFailed,
	onAddInternalCommentsDone,
	onAddInternalCommentStarted
} from './reducers';

export default reducerWithInitialState(initialState)
	.case(
		retrieveInternalCommentsAsync.started,
		onRetrieveInternalCommentsStarted
	)
	.case(retrieveInternalCommentsAsync.failed, onRetrieveInternalCommentsFailed)
	.case(retrieveInternalCommentsAsync.done, onRetrieveInternalCommentsDone)
	.case(postInternalCommentsAsync.started, onAddInternalCommentStarted)
	.case(postInternalCommentsAsync.failed, onAddInternalCommentFailed)
	.case(postInternalCommentsAsync.done, onAddInternalCommentsDone);
