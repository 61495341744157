import {
	CreateThreadRequest,
	CreateThreadResponse
} from 'services/api/threads/threadsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from 'store/threads/threadsState';

export const onCreateNewThread = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	CreateThreadRequest,
	CreateThreadResponse
>('fetchStatuses', 'creatingThread');
