import * as React from 'react';

import styles from './CreatePortJobCharterer.module.scss';
import {
	Row,
	Col,
	Radio,
	Select,
	Checkbox,
	InputNumber,
	TextArea
} from 'components/antd';
import { Flex } from 'components/Styles';
import { Justify } from 'components/types';

import { PortJobCharterer } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import ChartererFormFieldName from './CharterersFieldNames';
const {
	OPERATIONAL_INSTRUCTIONS,
	FINANCIAL_INSTRUCTIONS,
	IS_WORLDSCALE_SERVICES_APPLIED,
	// STAND_BY_TUGS,
	// BERTHAGE_WHARFAGE,
	IS_OTHER_REBILLABLES_APPLIED,
	COST_SETTLEMENT_IS_DIRECT,
	TIME_BAR_IS_APPLIED,
	TIME_BAR_DAYS,
	MAX_PORT_COST,
	MAX_PORT_COST_AMOUNT,
	MAX_PORT_COST_CURRENCY
} = ChartererFormFieldName;
interface ChartererFieldsProps {
	field: string;
	values: PortJobCharterer;
}

const ChartererFields = (props: ChartererFieldsProps) => {
	const { field, values } = props;
	return (
		<Row>
			<Col sm={5}>
				<TextArea.ReduxFormItem
					name={`${field}.${OPERATIONAL_INSTRUCTIONS}`}
					label="Operational Instructions"
					rows={4}
				/>
				{values.isOtherRebillablesApplied && (
					<TextArea.ReduxFormItem
						name={`${field}.${FINANCIAL_INSTRUCTIONS}`}
						required
						label="Financial Instructions"
						rows={4}
					/>
				)}
			</Col>
			<Col sm={6}>
				<Row className={styles.checkboxRow}>
					<Col sm={4}>
						<Checkbox.ReduxFormItem
							name={`${field}.${IS_WORLDSCALE_SERVICES_APPLIED}`}
						>
							Worldscale Applicable
						</Checkbox.ReduxFormItem>
						{/**
						 * Temporary disabling of the fields according to the issue
						 * https://support.dataart.com/browse/IPP-7158
						 */}
						{/* {values.isWorldScaleServicesApplied && (
							<ul className={styles.worldscaleApplicable}>
								<li>
									<Checkbox.ReduxFormItem name={`${field}.${STAND_BY_TUGS}`}>
										Standby tugs
									</Checkbox.ReduxFormItem>
								</li>
								<li>
									<Checkbox.ReduxFormItem
										name={`${field}.${BERTHAGE_WHARFAGE}`}
									>
										Berthage and Wharfage
									</Checkbox.ReduxFormItem>
								</li>
							</ul>
						)} */}
						<Checkbox.ReduxFormItem
							name={`${field}.${IS_OTHER_REBILLABLES_APPLIED}`}
						>
							Other Rebillables
						</Checkbox.ReduxFormItem>
						{values.isWorldScaleServicesApplied ||
						values.isOtherRebillablesApplied ? (
							<div>
								<Radio.Group.ReduxFormItem
									name={`${field}.${COST_SETTLEMENT_IS_DIRECT}`}
									defaultValue="true"
								>
									<Radio value="true">Direct</Radio>
									<Radio value="false">Via Principal</Radio>
								</Radio.Group.ReduxFormItem>
							</div>
						) : null}
					</Col>
					<Col sm={3}>
						<Checkbox.ReduxFormItem name={`${field}.${TIME_BAR_IS_APPLIED}`}>
							Time Bar Applies
						</Checkbox.ReduxFormItem>
						{values.timeBarisApplied && (
							<div className={styles.timeBarDays}>
								<InputNumber.ReduxFormItem
									name={`${field}.${TIME_BAR_DAYS}`}
									label="Time Bar Days"
									maxDigits={3}
									decimalPart={0}
									required
									withDelimiter={false}
								/>
							</div>
						)}
					</Col>
					<Col sm={5}>
						<Checkbox.ReduxFormItem name={`${field}.${MAX_PORT_COST}`}>
							Max Port Cost
						</Checkbox.ReduxFormItem>
						{values.maxPortCost && (
							<div className={styles.maxPortCostChild}>
								<Flex direction="horizontal" justify={Justify.BETWEEN}>
									<InputNumber.ReduxFormItem
										name={`${field}.${MAX_PORT_COST_AMOUNT}`}
										label="Amount"
										decimalPart={2}
										className={styles.amount}
										required
									/>
									<Select.ReduxFormItem
										name={`${field}.${MAX_PORT_COST_CURRENCY}`}
										label="Currency:"
										defaultValue="usd"
										className={styles.currency}
										required
									>
										<Select.Option value="usd" key="usd">
											USD
										</Select.Option>
									</Select.ReduxFormItem>
								</Flex>
							</div>
						)}
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ChartererFields;
