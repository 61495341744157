import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { getIsFailure } from 'store/selectors';

import { MessageAttachment } from 'services/api/messages/messagesServiceTypes';
import { getMessageByEventId } from 'store/thread/selectors';

const getAttachmentsMap = (state: AppState) => state.messages.attachmentsById;
const getAttachmentsStatusMessageMap = (state: AppState) =>
	state.messages.attachmentsByIdStatusMessage;
const getAttachmentsFetchStatusesMap = (state: AppState) =>
	state.messages.attachmentsByIdFetchStatuses;

export const getAttachmentStatusMessage = (
	state: AppState,
	attachmentId: string
) => getAttachmentsStatusMessageMap(state)[attachmentId];

export const getHasAttachmentFailedToLoad = (
	state: AppState,
	attachmentId: string
) => getIsFailure(getAttachmentsFetchStatusesMap(state)[attachmentId]);

export const getAttachmentsForMessage = createSelector(
	getAttachmentsMap,
	getMessageByEventId,
	(attachmentsMap, message): MessageAttachment[] => {
		return message.attachments.map(attachment => {
			return {
				...attachment,
				...attachmentsMap[attachment.id]
			};
		});
	}
);
