import { NavigationState } from 'store/navigation/navigationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { RetrieveBenchmarkUrlResponse } from 'services/api/benchmark/benchmarkServiceTypes';

export const retrieveBenchmarkUrl = makeFetchStatusReducers<
	NavigationState,
	'fetchStatuses',
	null,
	RetrieveBenchmarkUrlResponse
>('fetchStatuses', 'retrieveBenchmarkUrl');
