import { AxiosTypedResponse } from 'services/api/apiTypes';
import { SagaIterator } from 'redux-saga';
import { omit, includes } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
	retrieveCompanies,
	retrieveCompaniesAsync
} from 'store/companies/actions/retrieveCompanies';
import {
	Company,
	RetrieveCompaniesRequest,
	RetrieveCompaniesResponse,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { getCompaniesCount } from '../selectors';

import { Action } from 'typescript-fsa';
import Api from 'services/api';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

/** We only care about { data } in sagas layer */
interface CompaniesResponse
	extends Pick<AxiosTypedResponse<RetrieveCompaniesResponse>, 'data'> {}

const apiNamespace = Api.Companies;

export function* retrieveCompaniesExecutor(
	{ payload, meta }: Action<RetrieveCompaniesRequest>,
	api: typeof apiNamespace
) {
	const count = yield select(getCompaniesCount);
	const request: RetrieveCompaniesRequest = {
		limit: DEFAULT_LIST_LIMIT,
		index: count,
		sortBy: 'name:asc',
		...omit(payload, 'organisationTypes')
	};
	yield put(retrieveCompaniesAsync.started(request));

	try {
		/**
		 * Temporary solution.
		 * Added a few new end-points for the different type of company.
		 */
		const organisationTypes = payload?.organisationTypes || [];

		const defaultCompaniesResponse: CompaniesResponse = {
			data: {
				count: 0,
				elements: []
			}
		};
		let iss: CompaniesResponse = defaultCompaniesResponse;
		let principals: CompaniesResponse = defaultCompaniesResponse;
		let lpas: CompaniesResponse = defaultCompaniesResponse;
		let shippers: CompaniesResponse = defaultCompaniesResponse;
		let all: CompaniesResponse = defaultCompaniesResponse;

		const { retrieveCompanyType, retrieveCompanies } = api;

		if (!organisationTypes.length) {
			all = yield call(retrieveCompanies, request);
		}

		if (includes(organisationTypes, OrganisationType.PRINCIPAL)) {
			principals = yield call(retrieveCompanyType.Principal, request);
		}
		if (includes(organisationTypes, OrganisationType.MAIN_PRINCIPAL)) {
			/**
			 * TODO: Fixed temporary for issue IPP-9124.
			 * Readonly vs Result collections problem. Should be used one of them in such case.
			 * This implementation should be removed or applied for all collections here, it depends on BE solution
			 */
			const { data } = yield call(retrieveCompanyType.MainPrincipal, request);
			principals = { data };
		}
		if (includes(organisationTypes, OrganisationType.ISS_LPA)) {
			lpas = yield call(retrieveCompanyType.ISSLPA, request);
		}
		if (includes(organisationTypes, OrganisationType.LPA)) {
			lpas = yield call(retrieveCompanyType.ThirdPartyLPA, request);
		}
		if (includes(organisationTypes, OrganisationType.SHIPPER)) {
			shippers = yield call(retrieveCompanyType.ShipperReceiver, request);
		}
		if (includes(organisationTypes, OrganisationType.ISS)) {
			iss = yield call(retrieveCompanyType.ISS, request);
		}

		const elements: Company[] = [
			...principals.data.elements,
			...lpas.data.elements,
			...shippers.data.elements,
			...iss.data.elements,
			...all.data.elements
		];
		const result: RetrieveCompaniesResponse = {
			count: elements.length,
			elements
		};
		yield put(
			retrieveCompaniesAsync.done(
				{
					result,
					params: request,
					response: null
				},
				meta
			)
		);
	} catch (error) {
		yield put(
			retrieveCompaniesAsync.failed(
				{
					error,
					params: request
				},
				meta
			)
		);
	}
}

function* worker(action: Action<RetrieveCompaniesRequest>) {
	yield call(retrieveCompaniesExecutor, action, apiNamespace);
}

export default function*(): SagaIterator {
	yield takeEvery(retrieveCompanies.type, worker);
}
