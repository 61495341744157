import React, { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isInvalid, isPristine, isSubmitting, submit } from 'redux-form';

import { Button } from 'components/antd';
import { ButtonProps } from 'components/antd/Button/Button';
import { AppState } from 'store-types';

type ReduxFormSubmitButtonOwnProps = ButtonProps & {
	form: string;
	submitForm?: boolean;
};
type ReduxFormSubmitButtonProps = ReduxFormSubmitButtonOwnProps & {
	invalid: boolean;
	submitting: boolean;
};
const ReduxFormSubmitButton: React.FC = ({
	form,
	invalid,
	submitting,
	type,
	disabled,
	loading,
	onClick,
	submitForm,
	children
}: ReduxFormSubmitButtonProps) => {
	const dispatch = useDispatch();
	// remote submit
	const onSubmitClick: ButtonProps['onClick'] = useCallback(
		e => {
			if (submitForm) {
				dispatch(submit(form));
			}

			if (onClick) {
				onClick(e);
			}
		},
		[dispatch, form, onClick, submitForm]
	);

	return (
		<Button
			onClick={onSubmitClick}
			disabled={invalid || disabled}
			loading={submitting || loading}
			type={type || 'primary'}
			className="qaa_submit_button"
		>
			{children}
		</Button>
	);
};
export default connect(
	(state: AppState, { form }: ReduxFormSubmitButtonOwnProps) => ({
		invalid: isInvalid(form)(state) || isPristine(form)(state),
		submitting: isSubmitting(form)(state)
	})
)(ReduxFormSubmitButton);
