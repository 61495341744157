import {
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_PREFERRED_AGENTS_FILE_INFO';

export const retrieveCompanyPreferredAgentsFileInfoAsync = actionCreator.async<
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanyPreferredAgentsFileInfo = actionCreator<
	RetrieveCompanyPreferredAgentsFileInfoRequest
>(ACTION_NAME);

export const resetCompanyPreferredAgentsFileInfo = actionCreator<string>(
	'RESET_PREFERRED_AGENTS_FILE_INFO'
);
