import {
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../servicesActionCreator';

const ACTION_NAME = 'RETRIEVE_SERVICE_CATEGORIES';

export const retrieveServiceCategoriesAsync = actionCreator.async<
	RetrieveServiceCategoriesRequest,
	RetrieveServiceCategoriesResponse
>(ACTION_NAME);

export const retrieveServiceCategories = actionCreator<
	RetrieveServiceCategoriesRequest
>(ACTION_NAME);
export const resetServiceCategories = actionCreator(`RESET_${ACTION_NAME}`);
