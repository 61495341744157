import { RouteState } from '../routeState';

const MAX_PATHS_HISTORY_LENGTH = 2; // we're keeping just the current and the previous path

export const onChangeRoute = (state: RouteState) => {
	const { pathsHistory } = state;
	const pathToAdd = window.location.pathname;

	return {
		...state,
		pathsHistory: [
			...(pathsHistory.length >= MAX_PATHS_HISTORY_LENGTH
				? pathsHistory.slice(1)
				: pathsHistory),
			pathToAdd
		]
	};
};
