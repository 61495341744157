import React from 'react';
import Konva from 'konva';
import { Text } from 'react-konva';
import { TextNode } from '../ImageViewerV2Types';
import TransformerComponent from './TransformerComponent';

interface TextComponentProps {
	shapeProps: TextNode;
	isSelected: boolean;
	scale: number;
	isEditing: boolean;
	onChange: (newAttr: TextNode) => void;
	onSelect: () => void;
	onEdit: () => void;
	onDelete: () => void;
}

const TextComponent = ({
	shapeProps,
	isSelected,
	onChange,
	onSelect,
	onEdit,
	onDelete,
	scale,
	isEditing
}: TextComponentProps) => {
	const shapeRef = React.useRef<Konva.Text>(null);

	const onMouseEnter = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		if (!isSelected && stage && !isEditing) {
			stage.container().style.cursor = 'pointer';
		}
	};

	const onMouseLeave = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'default';
		}
	};

	const onMouseClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const stage = event.target.getStage();
		if (stage && !isEditing) {
			stage.container().style.cursor = 'move';
		}
		onSelect();
	};

	return (
		<React.Fragment>
			<Text
				x={shapeProps.x * scale}
				y={shapeProps.y * scale}
				fill={shapeProps.color}
				width={shapeProps.width}
				text={shapeProps.text}
				fontSize={shapeProps.fontSize}
				rotation={shapeProps.rotation}
				strokeWidth={1}
				fontStyle={shapeProps.fontStyle}
				align={shapeProps.align}
				scale={{ x: scale, y: scale }}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onClick={onMouseClick}
				onTap={onMouseClick}
				ref={shapeRef}
				draggable={isSelected}
				strokeScaleEnabled={false}
				onDragEnd={e => {
					onChange({
						...shapeProps,
						x: e.target.x() / scale,
						y: e.target.y() / scale
					});
				}}
				onTransformEnd={_e => {
					// transformer is changing scale of the node
					// and NOT its width or height
					// but in the store we have only width and height
					// to match the data better we will reset scale on transform end
					const node = shapeRef.current;
					if (node) {
						const scaleX = node.scaleX() / scale;

						// we will reset it back
						node.scaleX(1);
						node.scaleY(1);
						onChange({
							...shapeProps,
							x: node.x() / scale,
							y: node.y() / scale,
							// set minimal value
							width: Math.max(5, node.width() * scaleX),
							rotation: node.rotation()
						});
					}
				}}
			/>
			{isSelected && (
				<TransformerComponent
					isSelected={isSelected}
					shapeRef={shapeRef}
					onDelete={onDelete}
					showEdit
					onEdit={onEdit}
					enabledAnchors={['middle-left', 'middle-right']}
				/>
			)}
		</React.Fragment>
	);
};

export default TextComponent;
