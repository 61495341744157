import { FetchStatus } from 'services/api/apiTypes';
import {
	DirectBill,
	FullDirectBill
} from 'services/api/directBills/directBillsServiceTypes';
import { FileBaseInfo } from 'services/api/documents/documentsServiceTypes';

export interface DirectBillsState {
	context: {
		activeDirectBillId: string;
		uploadedDocuments: FileBaseInfo[];
		directBill: FullDirectBill | null;
	};
	readonly byId: { [key: string]: DirectBill };
	readonly allIds: string[];
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		downloadAll: FetchStatus;
		all: FetchStatus;
		update: FetchStatus;
		edit: FetchStatus;
	};
}

export const initialState: DirectBillsState = {
	context: {
		activeDirectBillId: '',
		uploadedDocuments: [],
		directBill: null
	},
	byId: {},
	allIds: [],
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE,
		update: FetchStatus.IDLE,
		downloadAll: FetchStatus.IDLE,
		edit: FetchStatus.IDLE
	}
};
