import React from 'react';
import { connect } from 'react-redux';
import { UploadFile } from 'antd/lib/upload/interface';
import { getUploadedFile } from 'store/documents/selectors';
import { cancelUploadAndProcessDocument } from 'store/documents/actions/uploadAndProcessDocument';
import BrowserDocumentPreview from './BrowserDocumentPreview';
import { DocumentViewerPreviewNotAvailable } from '../../DocumentViewer';
import { DocumentBrowserViewerCommonProps } from 'sections/DocumentBrowserPageViewer';
import { isString } from 'lodash';
import { AppState } from 'store-types';

interface BrowserDocumentPreviewContainerProps
	extends DocumentBrowserViewerCommonProps {
	/** Using `file?:` throws an unexpected TS error even when there's no extended prop with the same name */
	file: UploadFile | undefined;
	// from mapDispatchToProps
	cancelUploadAndProcessDocument: typeof cancelUploadAndProcessDocument;
}

interface BrowserDocumentPreviewContainerState {
	file?: UploadFile | Blob | string;
	isLoading: boolean;
	downloadPromptHasBeenShown: boolean;
}

class BrowserDocumentPreviewContainer extends React.Component<
	BrowserDocumentPreviewContainerProps,
	BrowserDocumentPreviewContainerState
> {
	constructor(props: BrowserDocumentPreviewContainerProps) {
		super(props);

		/**
		 * AddSupplierInvoice
		 * This component expect a File object coming from the <input type="file" /> in the AddSupplierInvoice process (this.props.file)
		 *
		 * During the ViewSupplierInvoice,
		 * This File object doesn't exist since user is getting the document through API response, in the shape of a url.
		 * In this case, we have to fetch the desired url, convert the result into a Blob object
		 * and then pass it to <BrowserDocumentPreview /> as the result of URL.createObjectURL(this.state.file)
		 *
		 * Both File object, either coming from an <input /> or a Blob, are saved in the local state of the component into (this.state.file)
		 */
		this.state = {
			file:
				props.file || (props.fileDocument && props.fileDocument.downloadUrl),
			isLoading: false,
			downloadPromptHasBeenShown: false
		};
	}

	componentDidMount() {
		const isDownloadUrl = isString(this.state.file);
		if (isDownloadUrl) {
			this.fetchFile(this.state.file as string);
		}
		this.openDownloadPromptInIE();
	}

	componentDidUpdate(prevProps: BrowserDocumentPreviewContainerProps) {
		const prevDownloadUrl =
			(prevProps.fileDocument && prevProps.fileDocument.downloadUrl) || '';
		const { fileDocument } = this.props;

		if (
			!this.state.file &&
			fileDocument &&
			fileDocument.downloadUrl &&
			fileDocument.downloadUrl !== prevDownloadUrl
		) {
			this.fetchFile(fileDocument.downloadUrl);
		}
		this.openDownloadPromptInIE();
	}

	componentWillUnmount() {
		cancelUploadAndProcessDocument({ shouldResetFile: true });
	}

	fetchFile = (downloadUrl: string) => {
		this.setState({ isLoading: true });
		fetch(downloadUrl)
			.then(r => r.blob())
			.then(blob => {
				const file = new Blob([blob], { type: 'application/pdf' });
				this.setState({ file, isLoading: false });
			});
	};

	getShouldOpenDownloadPromptInIEOrEdge() {
		/**
		 * If file is a string, it means it hasn't been downloaded yet.
		 * If it's an object with size property, it means it's a file that can be downloaded
		 * */
		return !!(
			!isString(this.state.file) &&
			this.state.file?.size &&
			navigator.msSaveOrOpenBlob
		);
	}

	openDownloadPromptInIE() {
		const { fileDocument } = this.props;
		if (
			this.getShouldOpenDownloadPromptInIEOrEdge() &&
			!this.state.downloadPromptHasBeenShown
		) {
			this.setState({ downloadPromptHasBeenShown: true }, () => {
				const file = this.state.file as UploadFile;
				const name = file.name || fileDocument?.name || 'document';
				navigator.msSaveOrOpenBlob(file, name);
			});
		}
	}

	render() {
		/**
		 * Prevents URL.createObjectURL() from failing
		 * if file is a string or there's no file at all.
		 * Also as IE doesn't have built-in doc previewer
		 * we need to open download prompt instead (IPP-26718)
		 **/
		const isDownloadUrl = isString(this.state.file);
		if (
			isDownloadUrl ||
			!this.state.file ||
			this.getShouldOpenDownloadPromptInIEOrEdge()
		) {
			return (
				<DocumentViewerPreviewNotAvailable isLoading={this.state.isLoading} />
			);
		}

		return (
			<BrowserDocumentPreview
				url={URL.createObjectURL(this.state.file)}
				type="application/pdf"
				name={this.props.fileDocument && this.props.fileDocument.name}
			/>
		);
	}
}

export default connect(
	(
		state: AppState,
		{ file }: Partial<Pick<BrowserDocumentPreviewContainerProps, 'file'>>
	) => ({
		file: file || getUploadedFile(state)
	}),
	{ cancelUploadAndProcessDocument }
)(BrowserDocumentPreviewContainer);
