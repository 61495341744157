import * as React from 'react';
import { Text } from '../Styles';

const SplitIndex = ({ splitIndex }: { splitIndex: number }) => (
	<>
		{' '}
		/ <Text color="primary">{splitIndex}</Text>
	</>
);

export default SplitIndex;
