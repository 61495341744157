import actionCreatorFactory from 'typescript-fsa';
import { RegisterUserByTokenRequest } from 'services/api/registration/registrationServiceTypes';
/**
 * Actions
 */
const actionCreator = actionCreatorFactory();

const REGISTER_USER = 'registration/REGISTER_USER';

export const registerUserByTokenAsync = actionCreator.async<
	RegisterUserByTokenRequest,
	undefined,
	boolean
>(REGISTER_USER);

export const registerUserByToken = actionCreator<RegisterUserByTokenRequest>(
	registerUserByTokenAsync.type
);
