import React from 'react';
import { Icon } from 'components/antd';
import { IconProps } from 'components/antd/Icon/Icon';
import {
	FILE_TYPE_ICON_MAP,
	FILE_TYPE_ICON_COLOR_MAP
} from './FileTypeIconTypes';
import { getFileExtension } from 'utils';

type FileTypeIconProps = Omit<IconProps, 'type'> & {
	fileName: string;
	defaultColorMap?: boolean;
	defaultColor?: IconProps['color'];
};
const FileTypeIcon = ({
	fileName,
	defaultColorMap,
	defaultColor = 'standard',
	...iconProps
}: FileTypeIconProps) => {
	const extension = getFileExtension(fileName);
	const iconType = (extension && FILE_TYPE_ICON_MAP[extension]) || 'document';
	const iconColor = defaultColorMap
		? (extension && FILE_TYPE_ICON_COLOR_MAP[extension]) || defaultColor
		: defaultColor;

	return <Icon {...iconProps} type={iconType} color={iconColor} />;
};

export default FileTypeIcon;
