import * as React from 'react';
import classNames from 'classnames';
import styles from './GroupLastUpdated.module.scss';

import { Flex, FlexProps } from 'components/Styles/Flex/Flex';
import { Justify } from 'components/types';
import { FormattedTime } from 'containers';

const GroupLastUpdated: React.SFC<{
	updatedOn: string;
	vertical?: boolean;
}> = props => {
	const flexProps: Partial<FlexProps> = {};
	if (props.vertical) {
		flexProps.direction = 'vertical';
		flexProps.justify = Justify.BETWEEN;
	}
	if (!props.updatedOn) {
		return null;
	}
	return (
		<Flex
			{...flexProps}
			className={classNames(styles.root, {
				[styles.vertical]: props.vertical,
				[styles.horizontal]: !props.vertical
			})}
		>
			<div>
				Details last update
				{!props.vertical && ':'}
			</div>
			<span>
				<FormattedTime value={props.updatedOn} />
			</span>
		</Flex>
	);
};

export default GroupLastUpdated;
