import * as React from 'react';

import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { AllHubPrincipalsOption } from 'store/supplierInvoice/filtersSync';
import { Select } from 'components/antd';
import { LabeledValue } from 'app-types';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { retrieveCompanies, resetCompanies } from 'store/companies/actions';
import { connect } from 'react-redux';
import { getMainPrincipalCompaniesAsOptions } from './SelectHubPrincipalSelectors';
import { AppState } from 'store-types';

export interface SelectHubPrincipalProps {
	value?: LabeledValue;
	name?: string;
	onChange: (value: LabeledValue) => void;
	// from mapStateToProps
	options: Array<LabeledValue<string>>;
	// from mapDispatchToProps
	retrieveCompanies: typeof retrieveCompanies;
	resetCompanies: typeof resetCompanies;
}

class SelectHubPrincipal extends React.Component<SelectHubPrincipalProps> {
	componentDidMount() {
		this.props.retrieveCompanies({
			organisationTypes: [OrganisationType.MAIN_PRINCIPAL],
			actionType: [PermissionCode.VIEW_SUPPLIER_INVOICES]
		});
	}

	componentWillUnmount() {
		this.props.resetCompanies();
	}

	public render() {
		const value = this.props.value ? this.props.value : AllHubPrincipalsOption;

		return (
			<Select
				value={value}
				labelInValue
				onChange={this.props.onChange}
				placeholder="Select"
			>
				{this.props.options.map(option => (
					<Select.Option key={option.key}>{option.label}</Select.Option>
				))}
			</Select>
		);
	}
}

export default connect(
	(state: AppState) => ({
		options: getMainPrincipalCompaniesAsOptions(state)
	}),
	{
		retrieveCompanies,
		resetCompanies
	}
)(SelectHubPrincipal);
