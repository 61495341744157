import { call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import Api from 'services/api';
import {
	retrieveCurrencies as action,
	retrieveCurrenciesAsync as async
} from '../actions';
import { RetrieveCurrenciesRequest } from 'services/api/currencies/currenciesServiceTypes';
import { Action } from 'typescript-fsa';

const apiCall = Api.Currencies.retrieveCurrencies;
export function* retrieveCurrenciesExecutor(
	request: RetrieveCurrenciesRequest,
	api: typeof apiCall
) {
	yield put(async.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			async.done({
				result: response.data.elements,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
}

function* worker({ payload }: Action<RetrieveCurrenciesRequest>): SagaIterator {
	yield call(retrieveCurrenciesExecutor, payload, apiCall);
}

export function* retrieveCurrenciesWatcher(): SagaIterator {
	yield takeLatest(action.type, worker);
}
