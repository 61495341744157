import client from 'services/client';
import {
	GenericOpticConfig,
	RetrieveKPIDetailsByIdResponse,
	RetrieveKPIDetailsByIdRequest,
	RetrieveFunctionalPermissionByUserTypeRequest,
	RetrieveFunctionalPermissionByUserTypeResponse,
	UpdateKpiDetailsByIdRequest
} from './opticConfigServiceTypes';

const retrieveOpticConfigSections = () =>
	client.get<GenericOpticConfig>('/dashboard/metrics');

const retrieveKPIDetailsById = ({ kpiId }: RetrieveKPIDetailsByIdRequest) =>
	client.get<RetrieveKPIDetailsByIdResponse>(`/dashboard/metrics/${kpiId}`);

const retrieveFunctionalPermissionByUserType = ({
	userType
}: RetrieveFunctionalPermissionByUserTypeRequest) =>
	client.get<RetrieveFunctionalPermissionByUserTypeResponse>(
		`/dashboard/metrics/functionalpermissions/${userType}`
	);

const updateKPIDetailsById = ({ id, payload }: UpdateKpiDetailsByIdRequest) =>
	client.put(`/dashboard/metrics/${id}`, payload);

export default {
	retrieveOpticConfigSections,
	retrieveKPIDetailsById,
	retrieveFunctionalPermissionByUserType,
	updateKPIDetailsById
};
