import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Operation from './Operation/Operation';

import { getPortJobOperationsForDisplay } from './OperationsSelectors';
import { getIsActivePortJobCancelled } from 'store/portJobs/portJobsSelectors';
import { AppState } from 'store-types';
import { PortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';

interface OperationsProps {
	portJobCode: string;
	portJobId: string;
	portCallId: string;
}

const Operations = (props: OperationsProps) => {
	const { portJobCode, portCallId, portJobId } = props;
	const operations: PortJobOperation[] = useSelector(
		(state: AppState) => getPortJobOperationsForDisplay(state, portJobId),
		shallowEqual
	);
	const isActivePortJobCancelled = useSelector(getIsActivePortJobCancelled);

	return (
		<>
			{operations.map((operation, index) => (
				<Operation
					key={index}
					operation={operation}
					portJobCode={portJobCode}
					portCallId={portCallId}
					isPortJobCancelled={isActivePortJobCancelled}
				/>
			))}
		</>
	);
};

export default Operations;
