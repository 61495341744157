import {
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse
} from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrieveFeedbackManagementGridSuccess = (
	state: ConfigurationState,
	{
		result
	}: Success<
		RetrieveConfigurationFeedbackManagementRequest,
		RetrieveFeedbackManagementGridResponse[]
	>
) => ({
	...state,
	feedbackManagementGrid: result
});

export const onRetrieveFeedbackManagementGrid = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse[]
>('fetchStatuses', 'retrieveFeedbackManagementGridStatus', {
	doneReducer: onRetrieveFeedbackManagementGridSuccess
});
