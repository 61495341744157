import actionCreator from '../supplierInvoiceActionCreator';
import {
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

const ACTION_NAME = 'RETRIEVE_SUPPLIER_INVOICE';

export const retrieveSupplierInvoiceAsync = actionCreator.async<
	RetrieveSupplierInvoiceParams,
	RetrieveSupplierInvoiceResponse,
	Error
>(ACTION_NAME, { behaviour: { type: 'section' } });

export const retrieveSupplierInvoice = actionCreator<
	RetrieveSupplierInvoiceParams
>(ACTION_NAME);
