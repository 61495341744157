import React from 'react';
import { Align } from 'components';
import { Button } from 'components/antd';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import TotalAmountFormFieldName from './TotalAmountForm/TotalAmountFormFieldNames';
import { TotalAmountFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

type Column = ColumnProps<{}>;

interface ColumnsProps {
	onDelete: (index: string) => void;
	onEdit: (index: string) => void;
}

const getColumns = (props: ColumnsProps): Column[] => [
	{
		title: 'Amount',
		dataIndex: TotalAmountFormFieldName.AMOUNT,
		key: 'amount',
		width: '40%'
	},
	{
		title: 'Currency',
		dataIndex: TotalAmountFormFieldName.CURRENCY_CODE,
		key: 'currency',
		width: '40%'
	},
	{
		dataIndex: '',
		width: '20%',
		render: (_text: string, record: TotalAmountFormData) => (
			<Align align="right">
				<Button
					type="primary"
					icon="edit"
					transparent
					onClick={() => {
						if (record.id) {
							props.onEdit(record.id);
						}
					}}
				/>
				<Button
					type="danger"
					icon="delete"
					transparent
					onClick={() => {
						if (record.id) {
							props.onDelete(record.id);
						}
					}}
				/>
			</Align>
		)
	}
];

export default getColumns;
