import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrieveKPIDetailsByIdResponse as Response,
	RetrieveKPIDetailsByIdRequest as Request
} from 'services/api/opticConfiguration/opticConfigServiceTypes';
import { retrieveKpiDetailsByIdAsync as async } from '../actions';

const api = Api.OpticConfiguration.retrieveKPIDetailsById;
export default makeTakeLatestWatcher<Request, Response, Error>({
	api,
	async
});
