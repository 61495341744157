import React from 'react';
import { Table } from 'components/antd';
import OperationTable from '../OperationTable/OperationTable';
import columns from './OverviewTotalAmountGridColumns';
import { TotalAmountFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

interface OverviewTotalAmountGridProps {
	data: TotalAmountFormData[];
}

const OverviewTotalAmountGrid = ({ data }: OverviewTotalAmountGridProps) => (
	<OperationTable>
		<Table
			dataSource={data}
			columns={columns}
			rowKey={(_record: object, index: number) => index.toString()}
		/>
	</OperationTable>
);

export default OverviewTotalAmountGrid;
