import { all, fork } from 'redux-saga/effects';
import retrieveCountriesWatcher from 'store/countries/sagas/retrieveCountriesSaga';
import { retrieveCurrenciesWatcher } from 'store/currencies/sagas/retrieveCurrenciesSaga';

import retrieveInternalCommentsWatcher from 'store/internalComments/sagas/retrieveInternalCommentsSaga';
import { postInternalCommentsWatcher } from 'store/internalComments/sagas/postInternalCommentsSaga';

import { retrieveUnitsWatcher } from 'store/units/sagas/retrieveUnitsSaga';
import retrieveGroupTypesWatcher from 'store/groups/sagas/retrieveGroupTypesSaga';

import {
	retrievePortCallsWatcher,
	retrievePortCallCycleWatcher,
	retrievePortCallsDuplicatesWatcher,
	retrievePortCallsAlertsWatcher,
	retrievePortCallsStaticWatcher,
	retrievePortCallsSettingsWatcher,
	retrievePortCallWatcher,
	retrievePortCallDuplicatesWatcher,
	mergePortCallsWatcher
} from 'store/portcalls/sagas';

import AddPortCallWatchers from 'store/portcalls/sagas/addPortCall/addPortCallSaga';
import AddPortJobWatchers from 'store/portJobs/sagas/addPortJobSaga';
import { acceptPortJobWatcher } from 'store/portJobs/sagas/acceptPortJobSaga';

// Companies
import CompaniesSagas from 'store/companies/sagas';
// CompanyVessel Types
import CompanyVesselTypesSaga from 'store/companyVesselTypes/sagas';
// CompanyVessels
import CompanyVesselsSaga from 'store/companyVessels/sagas';
// GroupUsers
import GroupUsersSagas from 'store/groupUsers/sagas';
// Port Call Operations
import PortCallOperationsSagas from 'store/portCallOperations/sagas';
// Documents
import DocumentsSagas from 'store/documents/sagas';
// Dashboard
import DashboardSagas from 'store/dashboard/sagas';
// DeveloperPortal
import DeveloperPortalSagas from 'store/developerPortal/sagas';
// Optic Configuration
import OpticConfigSagas from 'store/opticConfiguration/sagas';
// Hub Principal configuration
import ConfigurationSagas from 'store/configuration/sagas';
import ConfigurationFilesSagas from 'store/configurationFiles/sagas';
import ConfigurationServicesSagas from 'store/configurationServices/sagas';
import CommonSagas from 'store/common/sagas';

// Direct bills
import DirectBillsSagas from 'store/directBills/sagas';
// CommoditiesSagas
import CommoditiesSagas from 'store/commodities/sagas';
// Drafts
import DraftsSagas from 'store/drafts/sagas';
// Operations
import OperationsSagas from 'store/operations/sagas';
// User
import UserSagas from './users/sagas';
// User Registration
import UserRegistrationSagas from 'store/registration/sagas';
// User Auth
import UserAuthSagas from 'store/auth/sagas';
// VesselProgramme
import VesselProgrammeSagas from 'store/vesselProgramme/sagas';
// Threads
import ThreadsSagas from 'store/threads/sagas';
// Thread (single)
import ThreadSagas from 'store/thread/sagas';
// Messages
import MessagesSagas from 'store/messages/sagas';
// Finance
import FinanceSagas from 'store/finance/sagas';
// Savings
import SavingsSagas from 'store/savings/sagas';
// Finance Fundings
import financeFundingsSagas from 'store/financeFundings/sagas';
// Masterdata
import MasterdataSagas from 'store/masterdata/sagas';
// PortJob
import PortCallSagas from 'store/portCall/sagas';
// Port Call Documents
import PortCallDocumentsSagas from 'store/portCallDocuments/sagas';
// Port Call Measurements
import PortCallMeasurementsSagas from 'store/portCallMeasurements/sagas';
// PortCall
import PortJobSagas from 'store/portJobs/sagas';
// Groups
import GroupSagas from 'store/groups/sagas';
// Wizard Form
import WizardFormSagas from 'store/wizardForm/sagas';
// Permissions
import PermissionsSagas from 'store/permissions/sagas';

import DeletePortCallEventSagas from 'store/portCallOperations/sagas/deletePortCallEventSaga';

import RestorePortCallEventSaga from 'store/portCallOperations/sagas/restorePortCallEventSaga';
// Suplier Invoices
import SupplierInvoiceSaga from 'store/supplierInvoice/sagas';
// Form
import FormSagas from 'store/form/formSagas';
import TemplatesSagas from 'store/templates/sagas';

//Navigation
import NavigationSagas from 'store/navigation/sagas';

//LinkJob
import LinkJobSagas from 'store/linkJob/sagas';

export default function* root() {
	yield all([
		fork(acceptPortJobWatcher),
		fork(postInternalCommentsWatcher),
		fork(retrieveCountriesWatcher),
		fork(retrieveCurrenciesWatcher),
		fork(retrieveInternalCommentsWatcher),
		fork(retrievePortCallsWatcher),
		fork(retrievePortCallCycleWatcher),
		fork(retrievePortCallsDuplicatesWatcher),
		fork(mergePortCallsWatcher),
		fork(retrievePortCallsStaticWatcher),
		fork(retrievePortCallsSettingsWatcher),
		fork(retrievePortCallWatcher),
		fork(retrievePortCallDuplicatesWatcher),
		fork(retrievePortCallsAlertsWatcher),
		fork(retrieveUnitsWatcher),
		fork(retrieveGroupTypesWatcher),
		// Companies
		fork(CompaniesSagas),
		fork(CompanyVesselTypesSaga),
		// CompanyVessels
		fork(CompanyVesselsSaga),
		// Group Users
		fork(GroupUsersSagas),
		// Documents
		fork(DocumentsSagas),
		// Dashboard
		fork(DashboardSagas),
		// Optic Configuration
		fork(OpticConfigSagas),
		// Hub Principal Configuration
		fork(ConfigurationSagas),
		fork(ConfigurationFilesSagas),
		fork(ConfigurationServicesSagas),
		fork(CommonSagas),
		// Data Management
		fork(DirectBillsSagas),
		// CommoditiesSagas
		fork(CommoditiesSagas),
		// Drafts
		fork(DraftsSagas),
		// Operations
		fork(OperationsSagas),
		// Group
		fork(GroupSagas),
		// Threads
		fork(ThreadsSagas),
		// Thread (single)
		fork(ThreadSagas),
		// Masterdata
		fork(MasterdataSagas),
		// Messages
		fork(MessagesSagas),
		// PortCalls - Add
		fork(AddPortCallWatchers),
		// PortJobs - Add
		fork(AddPortJobWatchers),
		// User Registration
		fork(UserRegistrationSagas),
		// User Auth
		fork(UserAuthSagas),
		// Vessel Programme
		fork(VesselProgrammeSagas),
		// Finance
		fork(FinanceSagas),
		// Savings
		fork(SavingsSagas),
		// Finance Findings
		fork(financeFundingsSagas),
		// Users
		fork(UserSagas),
		// PortJob
		fork(PortJobSagas),
		// PortCall
		fork(PortCallSagas),
		// PortCall Documents
		fork(PortCallDocumentsSagas),
		// PortCall Measurements
		fork(PortCallMeasurementsSagas),
		// PortCall Operations
		fork(PortCallOperationsSagas),
		// Wizard Form Saga
		fork(WizardFormSagas),
		// Permissions
		fork(PermissionsSagas),
		// Delete PortCall Event
		fork(DeletePortCallEventSagas),
		// Restore PortCall Event
		fork(RestorePortCallEventSaga),
		// Supplier Invoices
		fork(SupplierInvoiceSaga),
		// Template Rendering Tool
		fork(TemplatesSagas),
		fork(FormSagas),
		// Navigation
		fork(NavigationSagas),
		//LinkJob
		fork(LinkJobSagas),
		//DeveloperPortal
		fork(DeveloperPortalSagas)
	]);
}
