import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Matomo from 'services/analytics/adapters/Matomo';
import { UserType } from 'services/api/users/userServiceTypes';
import { getAuthUserId, getAuthUserType } from 'store/auth/selectors';
import config from 'services/config';
import { toNumber, isNaN } from 'lodash';
import { AppState } from 'store-types';

interface MatomoTrackerBaseProps {
	userId: string;
	userType: UserType;
	exact?: boolean;
}
type MatomoTrackerProps = MatomoTrackerBaseProps & RouteComponentProps<{}>;

class MatomoTracker extends React.Component<MatomoTrackerProps> {
	unregister: () => void | undefined;
	constructor(props: MatomoTrackerProps) {
		super(props);
		if (!isNaN(toNumber(config.matomoSiteId))) {
			const matomo = new Matomo({
				userId: props.userId,
				userType: props.userType,
				exact: props.exact,
				siteId: config.matomoSiteId
			});
			this.unregister = matomo.connectToHistory(props.history);
		}
	}

	componentWillUnmount() {
		if (this.unregister) {
			this.unregister();
		}
	}

	render() {
		return <>{this.props.children}</>;
	}
}

export default connect((state: AppState) => ({
	userId: getAuthUserId(state),
	userType: getAuthUserType(state)
}))(withRouter<MatomoTrackerProps>(MatomoTracker));
