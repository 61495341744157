import client from 'services/client';
import { RetrieveRegionsResponse } from './regionsServiceTypes';

export const retrieveRegions = () => {
	return client.get<RetrieveRegionsResponse>('/masterdata/un-regions');
};

export default {
	retrieveRegions
};
