import actionCreator from '../groupsActionCreator';

import {
	RetrieveGroupsParams,
	RetrieveGroupsResponse
} from 'services/api/groups/groupsServiceTypes';

const RETRIEVE_GROUPS = 'RETRIEVE_GROUPS';

export const retrieveGroupsAsync = actionCreator.async<
	RetrieveGroupsParams,
	RetrieveGroupsResponse,
	Error
>(RETRIEVE_GROUPS, {
	behaviour: { type: 'section', infiniteLoad: true }
});

export const retrieveGroups = actionCreator<RetrieveGroupsParams>(
	RETRIEVE_GROUPS
);
