import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadsState } from '../threadsState';
import {
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse
} from 'services/api/threads/threadsServiceTypes';

export const onRetrieveThreadsPendingAlertsSuccess = (
	state: ThreadsState,
	{
		result
	}: Success<
		RetrieveThreadsPendingAlertsRequest,
		RetrieveThreadsPendingAlertsResponse
	>
): ThreadsState => ({
	...state,
	pendingAlerts: {
		...state.pendingAlerts,
		...result
	}
});

export const onRetrieveThreadsPendingAlerts = makeFetchStatusReducers<
	ThreadsState,
	'fetchStatuses',
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse
>('fetchStatuses', 'retrievingPendingAlerts', {
	doneReducer: onRetrieveThreadsPendingAlertsSuccess
});
