import { all, fork } from 'redux-saga/effects';
import checkAuthWatcher from './checkAuthSaga';
import retrieveAuthUserWatcher from './retrieveAuthUserSaga';
import setAuthActAsWatcher from './setAuthActAsSaga';

export default function*() {
	yield all([
		fork(checkAuthWatcher),
		fork(retrieveAuthUserWatcher),
		fork(setAuthActAsWatcher)
	]);
}
