import React from 'react';
import { Loading, Fit } from 'components';
import ImageViewer from '../ImageViewer/ImageViewer';
import { isBoolean } from 'lodash';
import styles from './DocumentViewerPreview.module.scss';

export interface DocumentViewerImagePreviewProps {
	imageUrl: string;
	imageUrlLoaded?: boolean;
	fileName?: string;
	isAnnotatable?: boolean;
}
const DocumentViewerImagePreview: React.SFC<DocumentViewerImagePreviewProps> = ({
	imageUrl,
	fileName,
	imageUrlLoaded,
	isAnnotatable = false
}) => {
	const imageUrlAvailable = !isBoolean(imageUrlLoaded) || imageUrlLoaded;
	return (
		<Fit>
			{imageUrlAvailable ? (
				<ImageViewer
					imageUrl={imageUrl}
					fileName={fileName}
					isAnnotatable={isAnnotatable}
				>
					<img src={imageUrl} alt="preview" className={styles.fullWidth} />
				</ImageViewer>
			) : (
				<Loading size="sm" />
			)}
		</Fit>
	);
};

export default DocumentViewerImagePreview;
