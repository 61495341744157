import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
	retrieveCompany,
	retrieveCompanyAsync
} from 'store/companies/actions/retrieveCompany';
import Api from 'services/api';

export function* executor(
	action: Action<string>,
	api: typeof Api.Companies.retrieveCompany = Api.Companies.retrieveCompany
) {
	const companyId = action.payload;
	yield put(retrieveCompanyAsync.started(companyId));
	try {
		const response = yield call(api, companyId);

		yield put(
			retrieveCompanyAsync.done({
				result: response.data,
				params: companyId,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveCompanyAsync.failed({
				error,
				params: companyId
			})
		);
	}
}

export function* worker(action: Action<string>) {
	yield call(executor, action);
}

export default function*(): SagaIterator {
	yield takeEvery(retrieveCompany.type, worker);
}
