import actionCreator from '../documentsActionCreator';
import {
	SaveDocumentRequest,
	DocumentWithOriginalFile
} from 'services/api/documents/documentsServiceTypes';
/**
 * Notify about starting of the upload
 */
export const beforeUploadAttachment = actionCreator('BEFORE_UPLOAD_ATTACHMENT');

/**
 * Notify the  API about the uploaded document
 */
const SAVE_ATTACHMENT = 'SAVE_ATTACHMENT';
export const saveAttachmentAsync = actionCreator.async<
	SaveDocumentRequest,
	DocumentWithOriginalFile, // SaveDocumentResponse,
	Error
>(SAVE_ATTACHMENT, {
	templateParams: {
		entityName: 'Attachment'
	}
});
export const saveAttachment = actionCreator<SaveDocumentRequest>(
	SAVE_ATTACHMENT
);
