import { Success } from 'typescript-fsa';
import { TemplatesState } from '../templatesState';
import { FetchStatus } from '../../../services/api/apiTypes';
import {
	RetrieveTemplateRequest,
	RetrieveTemplatesResponse
} from '../../../services/api/templates/templateServiceTypes';
import { keyBy } from 'lodash';

export function onRetrieveTemplatesStarted(
	state: TemplatesState
): TemplatesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplates: FetchStatus.PENDING
		}
	};
}

export function onRetrieveTemplatesSuccess(
	state: TemplatesState,
	action: Success<RetrieveTemplateRequest, RetrieveTemplatesResponse>
): TemplatesState {
	const { result } = action;
	const { elements } = result;

	return {
		...state,
		byId: keyBy(elements, 'type'),
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveTemplates: FetchStatus.SUCCESS
		}
	};
}
