import actionCreator from '../portCallsActionCreator';
import { RetrievePortCallsSettingsResponse } from '../portCallsState';

const RETRIEVE_SETTINGS = 'RETRIEVE_SETTINGS';

export const retrievePortCallsSettings = actionCreator(RETRIEVE_SETTINGS);
export const retrievePortCallsSettingsAsync = actionCreator.async<
	void,
	RetrievePortCallsSettingsResponse,
	Error
>(RETRIEVE_SETTINGS);
