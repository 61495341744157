import { FundingRequestsState } from '../financeFundingsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	UpdatePrefundingStatusRequest as Request,
	UpdatePrefundingStatusPayload as Response
} from 'services/api/financeFundings/financeFundingsServiceTypes';

export const updatePrefundingStatus = makeFetchStatusReducers<
	FundingRequestsState,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'updatePrefundingStatus');
