import React from 'react';
import { Row, Col, TextArea, InputNumber } from 'components/antd';

import {
	DEFAULT_TEXTAREA_MAX_LENGTH,
	DEFAULT_REDUX_FORM_CONFIG
} from 'app-constants';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { Currency } from 'services/api/currencies/currenciesServiceTypes';
import { retrieveCurrencies } from 'store/currencies';
import TotalAmountLines from 'sections/PortCall/PortJob/Operations/Operation/TotalAmount/TotalAmountLines';

export enum CrewWageDisbursementFormFieldName {
	CREW_WAGE_DISBURSEMENT_NUMBER_OF_CREW = 'crewWageDisbursementNumberOfCrew',
	CREW_WAGE_DISBURSEMENT_DETAILS = 'crewWageDisbursementDetails'
}

interface CrewWageDisbursementFormBaseProps {
	currencies: Currency[];
	retrieveCurrencies: typeof retrieveCurrencies;
}
interface CrewWageDisbursementFormProps
	extends InjectedFormProps<FormData, CrewWageDisbursementFormBaseProps>,
		CrewWageDisbursementFormBaseProps {}

const MAX_ALLOWED_DIGITS = 4;

class CrewWageDisbursement extends React.Component<
	CrewWageDisbursementFormProps
> {
	componentDidMount() {
		this.props.retrieveCurrencies({});
	}

	render() {
		return (
			<>
				<Row>
					<Col xs={2}>
						<InputNumber.ReduxFormItem
							decimalPart={0}
							withDelimiter={false}
							name={
								CrewWageDisbursementFormFieldName.CREW_WAGE_DISBURSEMENT_NUMBER_OF_CREW
							}
							label="Total Number of Crew"
							help
							maxDigits={MAX_ALLOWED_DIGITS}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={4}>
						<TotalAmountLines />
					</Col>
				</Row>
				<TextArea.ReduxFormItem
					name={
						CrewWageDisbursementFormFieldName.CREW_WAGE_DISBURSEMENT_DETAILS
					}
					label="Operation Details"
					rows={4}
					maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
				/>
			</>
		);
	}
}

export default connect(null, { retrieveCurrencies })(
	reduxForm<FormData, CrewWageDisbursementFormBaseProps>({
		...DEFAULT_REDUX_FORM_CONFIG,
		form: FORM.portJob,
		destroyOnUnmount: false,
		forceUnregisterOnUnmount: true
	})(CrewWageDisbursement)
);
