import {
	DEFAULT_TEXTAREA_MAX_LENGTH,
	DEFAULT_DATE_FORMAT,
	DEFAULT_DECIMAL_SEPARATOR,
	DEFAULT_EMAIL_VALIDATION_REGEX
} from 'app-constants';
import {
	isEmpty,
	isObject,
	isArray,
	isString,
	isBoolean,
	difference,
	has,
	first,
	values,
	flowRight as compose
} from 'lodash';
import moment from 'moment';
import validator from 'validator';

/**
 * Evaluate if the given string is a valid email
 * @param  {string}  value A given string, probably an email
 * @return {Boolean}
 */
export function isEmail(value: string): boolean {
	const pattern = new RegExp(DEFAULT_EMAIL_VALIDATION_REGEX, 'i');
	return pattern.test(value);
}

/**
 * Evaluate if the given string is a valid url
 * @param  {string}  value A given string, probably an url
 * @return {Boolean}
 */
export const isURL = (value: string): boolean => validator.isURL(value);

/**
 * Validation rules and messages for url
 * @param  {string} value url
 * @return {string|undefined}       Validation message or true
 */
export const url = (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	message = 'Invalid url address.'
): string | undefined => {
	if (isEmpty(value)) {
		return;
	}
	if (!isURL(value)) {
		return message;
	}
	return;
};

/**
 * Validation rules and messages for Email
 * @param  {string} value Email
 * @return {string|undefined}       Validation message or true
 */
export const email = (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	message = 'Invalid email address.'
): string | undefined => {
	if (isEmpty(value)) {
		return;
	}
	if (!isEmail(value)) {
		return message;
	}
	return;
};

/**
 * Required message
 * @param  {string} value Given Value
 * @return {string|undefined}
 */
export const required = (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	message = `Field is mandatory.`
) => {
	// pre-format
	if (isString(value)) {
		value = value.trim();
	}

	// Object or Array
	if (isBoolean(value) && !value) {
		return message;
	}
	if (
		((isObject(value) && !isBoolean(value)) || isArray(value)) &&
		isEmpty(value)
	) {
		return message;
	}
	// String
	if ((isString(value) && !value) || (isEmpty(value) && !isBoolean(value))) {
		return message;
	}
	return;
};

export const checkboxGroupRequired = (
	value: { [key: string]: boolean },
	message = `Field is mandatory.`
) => {
	if (!value || !values(value).includes(true)) {
		return message;
	}
	return;
};

// Field should not be required only if second value passed 'required' validation
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requiredOneOfTwo = (anotherValue: any) => (value: any) => {
	return required(anotherValue) ? required(value) : undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const requiredWithCustomMessage = (message: string) => (value: any) =>
	typeof required(value) !== 'undefined' ? message : undefined;

export const maxValue = (max: number, strict = true) => (
	value: string,
	message = `It should be less than ${max}.`
) => {
	if (!value) {
		return message;
	}
	// Value may come formatted from relevant inputs, like 10,00,00.52. We need to clean it.
	const parsedValue = parseFloat(value.replace(/,/g, ''));
	const hasValidationFailed = strict ? parsedValue >= max : parsedValue > max;
	if (hasValidationFailed) {
		return message;
	}
	return;
};

export const minValue = (min: number, strict = true) => (
	value: string,
	message = `It should be more than ${min}.`
) => {
	if (!value) {
		return message;
	}
	// Value may come formatted from relevant inputs, like 10,00,00.52. We need to clean it.
	const parsedValue = parseFloat(value.replace(/,/g, ''));
	const hasValidationFailed = strict ? parsedValue <= min : parsedValue < min;
	if (hasValidationFailed) {
		return message;
	}
	return;
};

/**
 * Set an amount of valid characters
 */
export const charMax = (max: number) => (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	message = `It should be less than ${max} characters.`
) => {
	value = value?.trim();
	if (isEmpty(value)) {
		return;
	}
	if ((value && value.length > max) || value.length === 0) {
		return message;
	}
	return;
};

/**
 * Set an amount of non-empty and not "+" valid characters
 */
export const phoneNumberCharsMax = (max: number) => (
	value: any, // eslint-disable-line @typescript-eslint/no-explicit-any
	message = `It should be less than ${max} characters.`
) => {
	if (isEmpty(value)) {
		return;
	}
	value = value.replace(/\s/g, '');
	if ((value && value.length > max) || value.length === 0) {
		return message;
	}
	return;
};

/**
 * Check a scale and precision of a decimal number
 */
export const checkDecimalFormat = (scale: number, precision: number) => (
	value: string,
	message = `Integer part should be up to ${scale} digits, decimal part should be up to ${precision} digits.`
) => {
	if (isEmpty(value)) {
		return;
	}
	// remove formatting commas to validate the number length
	value = value.replace(/\,/g, '').trim();
	const hasDecimalPart = value.includes(DEFAULT_DECIMAL_SEPARATOR);
	const integerPart = hasDecimalPart
		? value.substring(0, value.indexOf(DEFAULT_DECIMAL_SEPARATOR))
		: value;
	const decimalPart = hasDecimalPart
		? value.substring(value.indexOf(DEFAULT_DECIMAL_SEPARATOR) + 1)
		: '';
	if (integerPart.length > scale || decimalPart.length > precision) {
		return message;
	}
	return;
};

/**
 * Set minimum value of valid characters
 */
export const arrayMin = (min: number) => (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any[] | undefined,
	message = `It should have more than ${min}.`
) => {
	if (!value || value.length < min) {
		return message;
	}
	return;
};

/**
 * Set minimum value of valid characters
 */
export const arrayMax = (max: number) => (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any[] | undefined,
	message = `It should have less or equal than ${max}.`
) => {
	if (!value || !value.length) {
		return;
	}
	if (value.length > max) {
		return message;
	}
	return;
};

/**
 * Set minimum value of valid characters
 */
export const charMin = (min: number) => (
	value: string,
	message = `It should have more than ${min} characters.`
) => {
	if (isEmpty(value)) {
		return message;
	}
	value = value.trim();
	if ((value && value.length < min) || value.length === 1) {
		return message;
	}
	return;
};

/**
 * Set minimum value of non-empty and not "+" valid characters
 */
export const phoneNumberCharsMin = (min: number) => (
	value: string,
	message = `It should have more than ${min} characters.`
) => {
	if (isEmpty(value)) {
		return message;
	}
	value = value.replace(/\s/g, '');
	if ((value && value.length < min) || value.length === 1) {
		return message;
	}
	return;
};

/**
 * Requires at least one uppercase letter
 */
export const requireUppercase = (
	value: string,
	message = 'It should have at least one uppercase letter.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const pattern = /[A-Z]/;
	if (!pattern.test(value)) {
		return message;
	}
	return;
};

/**
 * Requires at least one lowercase letter
 */
export const requireLowercase = (
	value: string,
	message = 'It should have at least one lowercase letter.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const pattern = /[a-z]/;
	if (!pattern.test(value)) {
		return message;
	}
	return;
};

/**
 * Search if the given string has at least one number
 */
export const hasNumber = (
	value: string,
	message = 'It should have at least one numeric character.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const pattern = /\d/;
	if (!pattern.test(value)) {
		return message;
	}
	return;
};

/**
 * Search if the given string has at least one non-alphanumeric character
 */
export const hasAlphanumeric = (
	value: string,
	message = 'It should have at least one non-alphanumeric character.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const pattern = /[^a-zA-Z\d\s:]/;
	if (!pattern.test(value)) {
		return message;
	}
	return;
};

/**
 * Search if the given string positive number
 */
export const isPositiveNumberOrEmpty = (
	value: string,
	message = 'It should be a positive number.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const parsedVal = parseFloat(value);
	if (parsedVal <= 0) {
		return message;
	}
	return;
};

/**
 * Figure out wether a given Regular Expression matches against the provided value
 * @param {RegExp} regex
 */
const matchesRegex = (regex: RegExp) => (
	value: string,
	message = 'Invalid characters.'
) => (!regex.test(value) ? message : '');

/**
 * Checks if the given string has valid phone syntax
 */
export const phoneNumber = (
	value: string,
	message = 'Only numbers and "+", "( )" and "-" are valid.'
) => {
	if (isEmpty(value)) {
		return;
	}
	const pattern = /[^\d\+\(\)\-\s]/;
	if (pattern.test(value)) {
		return message;
	}
	return;
};

/**
 * matchAgainst
 */
export const matchAgainst = (label: string, target: string) => (
	value: string
) => {
	if (isEmpty(value)) {
		return `This field can't be empty.`;
	}
	if (value !== target) {
		return `This field should be the same as ${label}.`;
	}
	return;
};

export function onlyLettersAndDigitsAndSpaces(
	value: string,
	message = 'Invalid characters. Please use letters or numbers.'
) {
	const pattern = /^[a-zA-Z\d\s\b]+$/;
	if (!pattern.test(value)) {
		return message;
	}
	return;
}
const formatDate = (date: string, format = "'MM-DD-YYYY, h:mm a'") => {
	return moment(date).format(format);
};

export function isAfter(
	target: string,
	message = 'Please choose a previous date.'
) {
	/**
	 *  Input dates are coming in different formats. Should reduce input dates to one format
	 */
	const targetDate = formatDate(target);

	return (value: string) => {
		if (!value) {
			return;
		}
		const valueDate = formatDate(value);

		return moment(targetDate).isAfter(valueDate) || targetDate === valueDate
			? message
			: undefined;
	};
}

export function isDateValid(
	value: string | null,
	format = DEFAULT_DATE_FORMAT
) {
	if (!value) {
		return;
	}
	const momentDate = moment(value, format);
	if (momentDate.year() === 0) {
		return 'Invalid date.';
	}
	if (!momentDate.isValid()) {
		return 'Invalid date/time format.';
	}
	return;
}

export const getInvalidFileNameError = (
	fileName?: string,
	whitelist?: string[]
): string | undefined => {
	if (!fileName) {
		return 'File name cannot be empty.';
	} else if (!whitelist) {
		return;
	}

	const nameWithoutExtension = fileName
		.split('.')
		.slice(0, -1)
		.join('.');
	const invalidCharacters = difference(
		nameWithoutExtension.split(''),
		whitelist
	);

	if (invalidCharacters.length) {
		return `File name includes forbidden characters ${invalidCharacters.join(
			''
		)}. Please change file name and upload it again.`;
	}

	return;
};

const PORT_PERMISSION_MESSAGE = `You can't create port call for the selected Port since you are not allowed to work with it. Please contact your Optic administrator.`;
const optionHasPermissions = (option: { hasPermission: boolean }) =>
	has(option, 'hasPermission') && !option.hasPermission
		? PORT_PERMISSION_MESSAGE
		: undefined;

/**
 * Loops through an array of validation functions and validate each one against a given `value`
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldValue = any;

type RuleFn = (value: FieldValue) => string | undefined;

const getValue = (value: FieldValue) => {
	if (
		!value ||
		isString(value) ||
		isArray(value) ||
		moment.isMoment(value) ||
		isBoolean(value)
	) {
		return value;
	}
	return value.key;
};

export default function runValidations(
	value: FieldValue,
	rules: RuleFn[]
): string[] | undefined {
	const v = getValue(value);
	const validations = rules.reduce((acc: string[], rule: RuleFn) => {
		const result = rule(v);
		if (result) {
			acc.push(result);
		}
		return acc;
	}, []);

	if (validations.length) {
		return validations;
	}
	return;
}

/**
 * Validation Rules
 * - Required rules end with 'Required' suffix
 * - Non required ones, just don't
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectFirstError = (arr: any[]) => first(arr.filter(v => v));

const mapError = selectFirstError;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createValidator = (validators: RuleFn[]) => (value: any) =>
	validators.map(validator => validator(value));

export const validateRequired = compose(mapError, createValidator([required]));

export const validateCheckboxGroupRequired = compose(
	mapError,
	createValidator([checkboxGroupRequired])
);

/**
 * Email
 */
export const validateEmail = compose(
	mapError,
	createValidator([email, charMax(256)])
);
export const validateEmailRequired = compose(
	mapError,
	createValidator([required, email, charMax(256)])
);
/**
 * URL
 */
export const validateURLRequired = compose(
	mapError,
	createValidator([required, url])
);
export const validateNameMax = compose(
	mapError,
	createValidator([charMax(256)])
);
export const validateNameMaxRequired = compose(
	mapError,
	createValidator([required, charMax(256)])
);

export const validateTelephoneRequired = compose(
	mapError,
	createValidator([required, phoneNumber])
);

export const validatePassword = (password: string, mask: RegExp) => {
	return matchesRegex(mask)(password);
};

export const validateSecretAnswer = compose(
	mapError,
	createValidator([charMin(2), charMax(256), onlyLettersAndDigitsAndSpaces])
);
export const validateSecretAnswerRequired = compose(
	mapError,
	createValidator([
		charMin(2),
		charMax(256),
		onlyLettersAndDigitsAndSpaces,
		required
	])
);

export const validateTextAreaCustomMaxLength = (
	max: number = DEFAULT_TEXTAREA_MAX_LENGTH
) => compose(mapError, createValidator([charMax(max)]));

/**
 * Phone
 */
export const validatePhoneFormatCustomMaxLength = (max: number) =>
	compose(mapError, createValidator([phoneNumber, phoneNumberCharsMax(max)]));

export const validatePhoneFormatRequired = compose(
	mapError,
	createValidator([
		required,
		phoneNumberCharsMax(20),
		phoneNumberCharsMin(7),
		phoneNumber
	])
);

/**
 * Date & Time
 */
export const validateDateIsAfter = (target: string, message: string) =>
	compose(mapError, createValidator([isAfter(target, message)]));

/**
 * Groups
 */
export const validateGroupNameMax = compose(
	mapError,
	createValidator([charMax(100), required])
);

/**
 * Ports
 */
export const validateRequiredPortsWithPermission = compose(
	mapError,
	createValidator([required, optionHasPermissions])
);
