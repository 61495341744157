import {
	SDAReasons,
	RetrieveSDAReasonRequest
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const RETRIEVE_SDA_REASON = 'RETRIEVE_SDA_REASON ';
export const retrieveSDAReasonAsync = actionCreator.async<
	RetrieveSDAReasonRequest,
	SDAReasons[],
	Error
>(RETRIEVE_SDA_REASON);

export const retrieveSDAReason = actionCreator<RetrieveSDAReasonRequest>(
	RETRIEVE_SDA_REASON
);
