import { FetchStatus } from 'services/api/apiTypes';
import { MessagesState } from '../messagesState';
import { addContact, addContactAsync } from '../actions';

function onAddContactStarted(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.PENDING
		}
	};
}

function onAddContactSuccess(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.SUCCESS
		}
	};
}

function onAddContactFailed(state: MessagesState): MessagesState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			adding: FetchStatus.FAILURE
		}
	};
}

export const addContactAction = {
	action: addContact,
	async: addContactAsync,
	started: onAddContactStarted,
	success: onAddContactSuccess,
	failed: onAddContactFailed
};
