import React from 'react';
import { Text } from 'components';
import { RetrieveThreadsStatsResponse as Stats } from 'services/api/threads/threadsServiceTypes';
import {
	getThreadsStats,
	getThreadsStatsFetchStatus
} from 'store/threads/selectors';
import { connect } from 'react-redux';
import { DataFetcher, Permission } from 'utils/components';
import {
	retrieveThreadsStatsCycleStart,
	retrieveThreadsStatsCycleStop
} from 'store/threads/actions/';
import { FetchStatus } from 'services/api/apiTypes';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import SummaryContent from './SummaryContent';
import { AppState } from 'store-types';

const SummaryHeader = () => (
	<Text upper weight="bold">
		Messages
	</Text>
);

interface MessagesSummaryProps {
	stats: Stats;
	retrieveStart: typeof retrieveThreadsStatsCycleStart;
	retrieveStop: typeof retrieveThreadsStatsCycleStop;
	fetchStatus: FetchStatus;
}
class MessagesSummary extends React.Component<MessagesSummaryProps> {
	componentDidMount() {
		this.props.retrieveStart();
	}

	componentWillUnmount() {
		this.props.retrieveStop();
	}

	render() {
		const { stats, fetchStatus } = this.props;
		return (
			<Permission permissionCode={[PermissionCode.VIEW_MESSAGES]}>
				{hasPermission =>
					hasPermission ? (
						<div>
							<SummaryHeader />
							<DataFetcher
								data={stats}
								forceFetch={true}
								fetchStatus={fetchStatus}
								loadingSize="sm"
							>
								{() => <SummaryContent stats={stats} />}
							</DataFetcher>
						</div>
					) : null}
			</Permission>
		);
	}
}
export default connect(
	(state: AppState) => ({
		stats: getThreadsStats(state),
		fetchStatus: getThreadsStatsFetchStatus(state)
	}),
	{
		retrieveStart: retrieveThreadsStatsCycleStart,
		retrieveStop: retrieveThreadsStatsCycleStop
	}
)(MessagesSummary);
