import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Permission } from 'utils/components';
import { Error403 } from 'components';
import { PermissionsCheckMode, hasPermissionsConsideringCheckMode } from '..';

interface PermissionRouteProps extends RouteProps {
	permissionCode: PermissionCode | PermissionCode[];
	permissionsCheckMode?: PermissionsCheckMode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errorComponent?: React.ComponentType<RouteComponentProps<any>>;
}

const PermissionRoute: React.FC<PermissionRouteProps> = ({
	component,
	permissionCode,
	permissionsCheckMode = PermissionsCheckMode.AND,
	render,
	errorComponent,
	...restProps
}) => {
	const Component = component;
	const ErrorComponent = errorComponent;
	return (
		<Route
			{...restProps}
			render={routeProps => {
				if (render) {
					return render(routeProps);
				}
				return (
					<Permission permissionCode={permissionCode}>
						{(...hasPermissions) => {
							const hasPermission = hasPermissionsConsideringCheckMode(
								hasPermissions,
								permissionsCheckMode
							);
							return hasPermission
								? Component && <Component {...routeProps} />
								: (ErrorComponent && <ErrorComponent {...routeProps} />) || (
										<Error403 />
								  );
						}}
					</Permission>
				);
			}}
		/>
	);
};

export default PermissionRoute;
