import { omit } from 'lodash';
import { Success } from 'typescript-fsa';
import { ConfigurationServicesState } from '../configurationServicesState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRemovePrincipalServiceSuccess = (
	state: ConfigurationServicesState,
	{ params: id }: Success<string, null>
): ConfigurationServicesState => ({
	...state,
	principalServices: omit(state.principalServices, id)
});
export const onRemovePrincipalService = makeFetchStatusReducers<
	ConfigurationServicesState,
	'fetchStatuses',
	string,
	null
>('fetchStatuses', 'removePrincipalService', {
	doneReducer: onRemovePrincipalServiceSuccess
});
