import { call } from 'redux-saga/effects';
import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	CancelServiceTransactionPayload,
	CancelServiceTransactionRequest
} from 'services/api/finance/financeServiceTypes';
import { cancelServiceTransactionAsync } from '../actions';
import getActivePortCallAndPortJobIdsExecutor from 'utils/sagaHelpers/portCallSagaExecutor';

const api = Api.Finance.cancelServiceTransaction;

export default makeTakeEveryWatcher<
	CancelServiceTransactionRequest,
	null,
	Error
>({
	api,
	async: cancelServiceTransactionAsync,
	*getApiParams({ jobServiceId }: CancelServiceTransactionPayload) {
		const portCallAndPortJobCode = yield call(
			getActivePortCallAndPortJobIdsExecutor
		);

		return {
			...portCallAndPortJobCode,
			jobServiceId
		};
	}
});
