export interface Point {
	x: number;
	y: number;
}

export interface RectNode extends Point {
	width: number;
	height: number;
	color: string;
}

export interface LineNode {
	color: string;
	points: number[];
}

export interface TextNode extends Point {
	text: string;
	color: string;
}

export enum DrawingMode {
	LINE = 'line',
	RECT = 'rect',
	TEXT = 'text'
}

export enum DrawingAction {
	ADD = 'add',
	DELETE = 'delete'
}

export interface LineNode {
	color: string;
	points: number[];
}
