import { Success } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { CompaniesState } from '../companiesState';
import {
	RetrieveCompaniesContactDetailsResponse,
	RetrieveCompaniesContactDetailsRequest
} from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

function onRetrieveCompaniesContactDetailsSuccess(
	state: CompaniesState,
	action: Success<
		RetrieveCompaniesContactDetailsRequest,
		RetrieveCompaniesContactDetailsResponse
	>
): CompaniesState {
	return {
		...state,
		contactDetails: action.result.elements
	};
}

export function onResetCompaniesContactDetails(
	state: CompaniesState
): CompaniesState {
	return {
		...state,
		contactDetails: [],
		fetchStatuses: {
			...state.fetchStatuses,
			contactDetails: FetchStatus.IDLE
		}
	};
}

export const onRetrieveCompaniesContactDetails = makeFetchStatusReducers<
	CompaniesState,
	'fetchStatuses',
	RetrieveCompaniesContactDetailsRequest,
	RetrieveCompaniesContactDetailsResponse
>('fetchStatuses', 'contactDetails', {
	doneReducer: onRetrieveCompaniesContactDetailsSuccess
});
