import { PrefundingsRequest as Request } from 'services/api/financeFundings/financeFundingsServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'SEND_PRE_FUNDINGS_REQUEST';
export const sendPrefundingsRequestAsync = actionCreator.async<
	Request,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Prefunding'
	}
});

export const sendPrefundingsRequest = actionCreator<{
	portCallId: string;
	portJobCode: string;
}>(ACTION_NAME);
