import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import {
	Message,
	MessageAction
} from 'services/api/messages/messagesServiceTypes';
import { ComposeMessageFrom } from './ComposeMessageTypes';
import { getComposeMessageFromSelector } from './ComposeMessageSelector';
import { resetThread } from 'store/thread';
import {
	getActiveThread,
	getComposeMessage,
	getRetrieveMessageDataFetchStatus,
	getComposeMessagePrevPath
} from 'store/thread/selectors';
import ComposeMessageWrapper from './ComposeMessageWrapper';
import { DataFetcher } from 'utils/components';
import { retrieveMessageData } from 'store/thread/actions';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

interface RouteParams {
	threadId: string;
	messageId?: string;
	action: MessageAction;
}

export interface IComposeMessageRouteProps
	extends RouteComponentProps<RouteParams> {
	retrieveMessageDataFetchStatus: FetchStatus;
	isNewTab: boolean;
	message: Message | null;
	thread: ThreadItem;
	messageFrom: ComposeMessageFrom;
	retrieveMessageData: typeof retrieveMessageData;
	resetThread: typeof resetThread;
	composeMessagePrevPath: string;
}

export class ComposeMessageRoute extends React.Component<
	IComposeMessageRouteProps
> {
	get breadcrumbs() {
		return [
			{ title: 'Messages', link: '/messages' },
			{ title: 'Compose message ' }
		];
	}

	retrieveMessageData = () => {
		const params = this.props.match.params;
		if (params.messageId && params.action) {
			this.props.retrieveMessageData({
				threadId: params.threadId,
				messageId: params.messageId
			});
		}
	};

	reset = () => {
		this.props.resetThread();
	};

	render() {
		const {
			thread,
			message,
			messageFrom,
			isNewTab,
			retrieveMessageDataFetchStatus,
			composeMessagePrevPath
		} = this.props;
		const params = this.props.match.params;

		return (
			<DataFetcher
				fetchStatus={retrieveMessageDataFetchStatus}
				dispatch={this.retrieveMessageData}
				reset={this.reset}
			>
				{() => (
					<ComposeMessageWrapper
						thread={thread}
						message={message}
						messageFrom={messageFrom}
						isNewTab={isNewTab}
						breadcrumbs={this.breadcrumbs}
						isMessageHeaderCollapsible
						action={params.action}
						prevPath={composeMessagePrevPath}
					/>
				)}
			</DataFetcher>
		);
	}
}

export default connect(
	(
		state: AppState,
		{
			match: {
				params: { threadId }
			}
		}: IComposeMessageRouteProps
	) => ({
		thread: getActiveThread(state),
		message: getComposeMessage(state),
		messageFrom: getComposeMessageFromSelector(state),
		retrieveMessageDataFetchStatus: getRetrieveMessageDataFetchStatus(state),
		composeMessagePrevPath: getComposeMessagePrevPath(state, threadId)
	}),
	{
		retrieveMessageData,
		resetThread
	}
)(ComposeMessageRoute);
