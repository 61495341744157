import { SupplierInvoicePortJob } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { SupplierInvoiceState } from '../supplierInvoiceState';

export const onSetPortJobsInSupplierInvoiceContextualization = (
	state: SupplierInvoiceState,
	portJobs: SupplierInvoicePortJob[]
) => ({
	...state,
	contextualization: {
		...state.contextualization,
		portJobs
	}
});
