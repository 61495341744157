import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	CompanyVesselData,
	UpdateCompanyVesselRequest
} from 'services/api/companies/companiesServiceTypes';

export const onUpdateCompanyVessel = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	UpdateCompanyVesselRequest,
	CompanyVesselData
>('fetchStatuses', 'update');
