import { FetchStatus } from 'services/api/apiTypes';
import { BreadcrumbItem } from 'store/navigation';
import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { getActiveThread } from 'store/thread/selectors/threadSelectors';
import { orderBy } from 'lodash';
import { Entity } from 'app-types';

const getThreads = (state: AppState) => state.threads;
export const getThreadsMapSelector = (state: AppState) =>
	getThreads(state).byId;

const getAllThreadsSelector = createSelector([getThreadsMapSelector], byId =>
	Object.values(byId)
);

export const isThreadsAssignToFetchStatusPending = createSelector(
	getThreads,
	threads => threads.fetchStatuses.assignToUpdating === FetchStatus.PENDING
);

export const makeGetThreadsOrderedByLastMessageTimeSelector = () => {
	const iterateesFn = (thread: ThreadItem) => thread.lastMessage.updatedOn;
	return createSelector(
		getAllThreadsSelector,
		(_state: AppState, order: Array<'asc' | 'desc'>) => order,
		(threads, order) => orderBy(threads, iterateesFn, order)
	);
};

const getThreadsAvailableGroupsMap = (state: AppState) =>
	state.threads.availableGroups;

export const getThreadsNewPermissionsSelector = (state: AppState): Entity[] =>
	state.threads.newPermissions;

export const getThreadsAvailableGroups = createSelector(
	getThreadsAvailableGroupsMap,
	availableGroupsMap => Object.values(availableGroupsMap)
);

export const getThreadsContext = createSelector(
	getThreads,
	threads => threads.context
);

export const getThreadsCurrentAmount = createSelector(
	getThreadsContext,
	context => context.threadsLimit
);

export const getThreadsPendingAlerts = (state: AppState) =>
	getThreads(state).pendingAlerts.hasOverdueThreads;

export const getThreadsCountSelector = createSelector(
	getAllThreadsSelector,
	threads => threads.length
);

export const getTotalUnreadMessagesCount = (state: AppState) =>
	getThreads(state).totalUnreadCount;

export const isThreadsFetchingAll = (state: AppState) =>
	getThreads(state).fetchStatuses.all === FetchStatus.PENDING;

export const getActiveThreadId = (state: AppState) =>
	getThreads(state).context.activeThreadId;

export const getIsPreviewModeEnabled = (state: AppState) =>
	getThreads(state).isPreviewModeEnabled;

export const getIsFullScreenModeEnabled = (state: AppState) =>
	getThreads(state).isFullScreenModeEnabled;

export const getIsThreadsPageReload = (state: AppState) =>
	getThreads(state).isThreadPageReload;

export const getBreadcrumsForThreads = createSelector(
	getActiveThread,
	getThreadsCountSelector,
	(activeThread, count): BreadcrumbItem[] => {
		const breadcrumbs = [{ title: 'Messages', link: '/messages/' }];

		const threadBreadcrumbs =
			count > 0 && activeThread?.lastMessage
				? [{ title: activeThread.lastMessage.subject }]
				: [];

		return [...breadcrumbs, ...threadBreadcrumbs];
	}
);

export const getAssignedToNewThreadJobCodes = (state: AppState) => {
	return getThreads(state).jobCodesForNewThread;
};

export const getNewThreadData = (state: AppState) =>
	getThreads(state).newThreadData;

export const getEditThreadData = (state: AppState) =>
	getThreads(state).editThreadData;

export const getThreadContexts = (state: AppState) =>
	getThreads(state).threadContexts || [];

export const getThreadTags = (state: AppState) =>
	getThreads(state).threadTags || [];

export const getMainPrincipalThreadTags = (state: AppState) =>
	getThreads(state).mainPrincipalThreadsTags;

export const getMainPrincipalThreadGroups = (state: AppState) =>
	getThreads(state).mainPrincipalThreadsGroups;

export const isThreadsGroupsFetchStatusPending = (state: AppState) =>
	getThreads(state).fetchStatuses.retrievingThreadsGroups ===
	FetchStatus.PENDING;

export const isUpdateCategorizePending = (state: AppState) =>
	getThreads(state).fetchStatuses.categorizeMsgUpdating === FetchStatus.PENDING;

export const getThreadsStats = (state: AppState) => getThreads(state).stats;

export const getThreadsStatsFetchStatus = (state: AppState) =>
	getThreads(state).fetchStatuses.retrievingStats;
