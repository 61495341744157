import {
	ParentPermission,
	EntityPermission
} from 'services/api/permissions/permissionsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

// key word os being used because for portjob we use code not id
interface EntityPermissionByKey {
	[key: string]: EntityPermission;
}
interface EntityPermissionFetchStatusByKey {
	[key: string]: FetchStatus;
}
// We could use here enum in code, type instead of string but typescript not yet support it
// https://github.com/Microsoft/TypeScript/issues/13042
export interface PermissionsState {
	readonly byGroupType: { [code: string]: ParentPermission[] };
	readonly byEntityType: { [type: string]: EntityPermissionByKey };
	readonly fetchStatusesByGroupType: { [code: string]: FetchStatus };
	readonly fetchStatusesByEntityType: {
		[type: string]: EntityPermissionFetchStatusByKey;
	};
}

export const initialState: PermissionsState = {
	byGroupType: {},
	fetchStatusesByGroupType: {},
	byEntityType: {},
	fetchStatusesByEntityType: {}
};
