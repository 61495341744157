import { FinanceState } from 'store/finance/financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CancelCreditHubRevenueRequest } from 'services/api/finance/financeServiceTypes';

export const onCancelCreditHubRevenue = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	CancelCreditHubRevenueRequest,
	null
	>('fetchStatuses', 'cancelCreditHubRevenue');
