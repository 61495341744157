import {
	ViewBenchmarkRequest,
	ViewBenchmarkResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const VIEW_BENCHMARK = 'VIEW_BENCHMARK';
export const viewBenchmarkAsync = actionCreator.async<
	ViewBenchmarkRequest,
	ViewBenchmarkResponse,
	Error
>(VIEW_BENCHMARK);

export const viewBenchmark = actionCreator(VIEW_BENCHMARK);
