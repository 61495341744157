import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { RetrievePermissionsRequest } from 'services/api/permissions/permissionsServiceTypes';
import { retrievePermissionsAsync } from '../actions/retrievePermissions';
import Api from 'services/api';

// exported for unit testing
export function* executor(
	payload: RetrievePermissionsRequest,
	api: typeof Api.Permissions.retrievePermissions
): SagaIterator {
	yield put(retrievePermissionsAsync.started(payload));
	try {
		const response = yield call(api, payload);

		yield put(
			retrievePermissionsAsync.done({
				result: response.data,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			retrievePermissionsAsync.failed({
				error,
				params: payload
			})
		);
	}
}

function* worker(action: Action<RetrievePermissionsRequest>): SagaIterator {
	yield call(executor, action.payload, Api.Permissions.retrievePermissions);
}

function* watcher(): SagaIterator {
	yield takeLatest(retrievePermissionsAsync.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
