import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { getCurrentUser } from 'store/auth/selectors';
import {
	CustodyAgent,
	CustodyAgentType
} from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import { uniqWith, isEqual } from 'lodash';

export const getCustodyAgents = createSelector(
	(state: AppState) => state.vesselProgramme.custodyAgents,
	(custodyAgents: CustodyAgent[]) => custodyAgents
);

export const getCustodyAgentsForEditMode = createSelector(
	getCustodyAgents,
	agents => agents.filter(agent => agent.agentRole !== 'None')
);

export const getUniqCustodyAgentsForEditMode = createSelector(
	getCustodyAgentsForEditMode,
	agents => uniqWith(agents, isEqual)
);

export const getCustodyAgentByRole = (state: AppState, agentRole: string) =>
	state.vesselProgramme.custodyAgents.find(
		agent => agent.agentRole === agentRole
	);

export const getCurrentUserCustodyAgent = createSelector(
	[getCustodyAgents, getCurrentUser],
	(agents, user) =>
		agents.find(agent => agent.performingAgentCompany.id === user.companyId)
);

export const getPerformingAgentCompanyName = createSelector(
	getCustodyAgentByRole,
	agent => (agent ? agent.performingAgentCompany.name : 'External LPA')
);

export const getInboundAgent = createSelector(
	getCustodyAgents,
	agents => agents.find(agent => agent.agentRole === 'Inbound')
);

export const getOutboundAgent = createSelector(
	getCustodyAgents,
	agents => agents.find(agent => agent.agentRole === 'Outbound')
);

export const getFullAgent = createSelector(
	getCustodyAgents,
	agents => agents.find(agent => agent.agentRole === 'Full')
);

export const getCurrentAgentRole = (state: AppState) =>
	state.vesselProgramme.currentAgentRole;

export const isCurrentAgentRole = (state: AppState, type: CustodyAgentType) =>
	getCurrentAgentRole(state) === type;
