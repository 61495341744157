import actionCreator from '../portCallOperationsActionCreator';
import {
	UpdatePortCallEventRequest,
	UpdateEventResponse
} from 'services/api/portCall/portCallServiceTypes';

const ACTION_NAME = 'UPDATE_EVENT_FROM_FINANCE';

export type UpdatePortCallEventFromFinancePayload = Pick<
	UpdatePortCallEventRequest,
	'eventId' | 'realDatePlt' | 'comment' | 'concurrencyToken'
>;

export const updatePortCallEventFromFinanceAsync = actionCreator.async<
	UpdatePortCallEventFromFinancePayload,
	UpdateEventResponse,
	Error
>(ACTION_NAME);

export const updatePortCallEventFromFinance = actionCreator<
	UpdatePortCallEventFromFinancePayload
>(ACTION_NAME);
