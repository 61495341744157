import {
	ValidateJobFinanceRequest,
	ValidateJobFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';

const ACTION_NAME = 'VALIDATE_JOB_FINANCE';

export const validateJobFinanceAsync = actionCreator.async<
	ValidateJobFinanceRequest,
	ValidateJobFinanceResponse,
	Error
>(ACTION_NAME, {
	// handles 400, and `reactToChanges` handles the rest
	failed: {
		omit: true
	}
});

export const validateJobFinance = actionCreator<ValidateJobFinanceRequest>(
	ACTION_NAME
);
