import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { keyBy } from 'lodash';
import { PortCallsState } from '../portCallsState';
import {
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import { Success } from 'typescript-fsa';

function onRetrievePortCallsSuccess(
	state: PortCallsState,
	action: Success<RetrievePortCallsRequest, RetrievePortCallsResponse>
): PortCallsState {
	const {
		result: { elements },
		params: { limit }
	} = action;

	return {
		...state,
		byId: {
			...state.byId,
			...keyBy(elements, item => item.id)
		},
		hasMore: limit === elements.length
	};
}

export const onRetrievePortCalls = makeFetchStatusReducers<
	PortCallsState,
	'fetchStatuses',
	RetrievePortCallsRequest,
	RetrievePortCallsResponse
>('fetchStatuses', 'all', {
	doneReducer: onRetrievePortCallsSuccess,
	failedReducer: state => ({
		...state,
		hasMore: false
	})
});
