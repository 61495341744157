import { makeTakeLatestWatcher, makeDefaultExecutor } from 'utils/sagaHelpers';
import { retrieveServiceQueriesAsync } from '../actions/retrieveServiceQueries';
import { call, select } from 'redux-saga/effects';
import {
	RetrieveServiceQueriesRequest,
	FinanceServiceQueries
} from 'services/api/finance/financeServiceTypes';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.retrieveServiceQueries;

const commonExecutor = makeDefaultExecutor<
	RetrieveServiceQueriesRequest,
	FinanceServiceQueries,
	Error
>({
	api,
	async: retrieveServiceQueriesAsync
});

export const executor = function*(params: RetrieveServiceQueriesRequest) {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const commonRequest = {
		portCallId,
		jobCode,
		...params
	};
	yield call(commonExecutor, commonRequest);
};

export default makeTakeLatestWatcher<
	RetrieveServiceQueriesRequest,
	FinanceServiceQueries,
	Error
>(
	{
		api,
		async: retrieveServiceQueriesAsync
	},
	executor
);
