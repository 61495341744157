import { PortJobState } from '../portJobsState';
export const onSetActivePortJobThreadId = (
	state: PortJobState,
	activePortJobThreadId: string
) => ({
	...state,
	context: {
		...state.context,
		activePortJobThreadId
	}
});
export const onResetActivePortJobThreadId = (state: PortJobState) => ({
	...state,
	context: {
		...state.context,
		activePortJobThreadId: ''
	}
});
