import { getAuthUser } from 'store/auth/selectors';
import { put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { retrieveAuthUser } from 'store/auth/actions';

export default function*(id: string): SagaIterator {
	const authUser = yield select(getAuthUser);

	if (id === authUser.id) {
		yield put(retrieveAuthUser());
	}
}
