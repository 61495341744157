import { FormErrors } from 'redux-form';
import { isEmpty } from 'lodash';
import { FormData } from 'store/form/financeContextualization/financeContextualizationFormData';
import { ContextualizationFormProps } from './ContextualizationForm';
import { validateCheckboxGroupRequired } from 'utils/validations';

const validate = (
	values: FormData,
	{ activePage }: ContextualizationFormProps
) => {
	if (isEmpty(values.pages)) {
		return {};
	}

	const errors: FormErrors<FormData> = {};
	const activePageState = values.pages[activePage - 1];

	/**
	 * If at least one DA/service has been selected
	 */
	const hasContext =
		!validateCheckboxGroupRequired(activePageState.das) ||
		!validateCheckboxGroupRequired(activePageState.services);

	return {
		...errors,
		_error: !hasContext
	};
};

export default validate;
