import React from 'react';
import { reset, InjectedFormProps, FormWarnings } from 'redux-form';
import { ColumnProps } from 'components/antd/Table/TableTypes';

import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import {
	FormData,
	FormDataErrors
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	FORM,
	FormFieldName,
	TOTAL_AMOUNT_FIELD_NAME
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { PageFormProps } from '../Form/Form';
import BunkeringFieldName from './Bunkering/BunkeringFieldNames';
import Generic, { GenericFormFieldName } from './Generic/Generic';
import CanalTransit, {
	CanalTransitFormFieldName
} from './CanalTransit/CanalTransit';
import CrewWageDisbursement, {
	CrewWageDisbursementFormFieldName
} from './CrewWageDisbursement/CrewWageDisbursement';
import CashToMaster, {
	CashToMasterFormNames
} from './CashToMaster/CashToMasterForm';
import CrewChange, { CrewChangeFormNames } from './CrewChange/CrewChange';

import Load from './Load/Load';
import Discharge from './Discharge/Discharge';
import Bunkering from './Bunkering/Bunkering';

import validateGeneric from './Generic/validate';
import validateCashToMaster from './CashToMaster/validate';
import validateCrewWageDisbursement from './CrewWageDisbursement/validate';
import validateCrewChange from './CrewChange/validate';
import validateBunkering from './Bunkering/validate';
import validateCargoLines from '../CargoLines/validate'; // for load/load sts
import validateSparesAndDocumentDelivery from './SparesAndDocumentDelivery/validate';

import warnBunkering from './Bunkering/warn';

import getLoadCargoLineColumns from '../Operations/Load/CargoLine/CargoLineColumns';
import getDischargeCargoLineColumns from '../Operations/Discharge/CargoLine/CargoLineColumns';
import SparesAndDocumentDelivery, {
	SparesAndDocumentDeliveryFormFieldName
} from './SparesAndDocumentDelivery/SparesAndDocumentDelivery';

const shouldShowChangeOperationWarning = () => true;

const shouldShowChangeOperationWarningForLoadOrDischarge = ({
	valid,
	cargoLineForm,
	areCharterersTouched
}: PageFormProps) => !cargoLineForm.pristine || valid || areCharterersTouched;

const resetOperationForLoadOrDischarge = (
	change: InjectedFormProps['change'],
	resetForm: typeof reset
) => {
	change(FormFieldName.CARGO_LINES, []);
	change(FormFieldName.CHARTERERS, []);
	resetForm(FORM.cargoLine);
};

const resetFields = (
	fields: { [key: number]: string },
	change: InjectedFormProps['change'],
	untouch: InjectedFormProps['untouch']
) =>
	Object.values(fields).forEach((fieldName: string) => {
		change(fieldName, '');
		untouch(FORM.portJob, fieldName);
	});

/**
 * {React.ComponentType} component
 * 		Component which to render when operation is selected
 *
 * {Function} resetOperation
 * 		Function that will be called when operation is unselected.
 * 		Here any changes may be unset
 *
 * {Function} validate
 * 		Synchronous Validation Function that will be called on any form's state change
 *
 * {Function} warn
 *		Synchronous Warning Function that will be called on any form's state change.
 *		The same as `validate`, but does not mark form as invalid
 *
 * {Function} shouldShowChangeOperationWarning
 * 		Function that will be called before operation is unset.
 * 		Configure if confirmation modal should appear
 *
 * {Function} getColumns
 * 		Function that return list of columns to render in table (if there is one)
 */
interface OperationConfigItem {
	component: React.ComponentType;
	resetOperation?(
		change: InjectedFormProps['change'],
		resetForm: typeof reset,
		untouch: InjectedFormProps['untouch']
	): void;
	validate?(values: FormData): FormDataErrors;
	warn?(values: FormData): FormWarnings<FormData>;
	shouldShowChangeOperationWarning?(props: object): boolean;
	getColumns?<P, T>(props: P): Array<ColumnProps<T>>;
}

const OperationConfig: { [operationTypeCode: string]: OperationConfigItem } = {
	[OperationTypeCode.GENERIC]: {
		component: Generic,
		shouldShowChangeOperationWarning,
		validate: validateGeneric,
		resetOperation: (change, _reset, untouch) =>
			resetFields(GenericFormFieldName, change, untouch)
	},
	[OperationTypeCode.LOADING]: {
		component: Load,
		getColumns: getLoadCargoLineColumns,
		shouldShowChangeOperationWarning: shouldShowChangeOperationWarningForLoadOrDischarge,
		resetOperation: resetOperationForLoadOrDischarge,
		validate: validateCargoLines
	},
	[OperationTypeCode.LOADING_STS]: {
		component: Load,
		getColumns: getLoadCargoLineColumns,
		shouldShowChangeOperationWarning: shouldShowChangeOperationWarningForLoadOrDischarge,
		resetOperation: resetOperationForLoadOrDischarge,
		validate: validateCargoLines
	},
	[OperationTypeCode.DISCHARGING]: {
		component: Discharge,
		getColumns: getDischargeCargoLineColumns,
		shouldShowChangeOperationWarning: shouldShowChangeOperationWarningForLoadOrDischarge,
		resetOperation: resetOperationForLoadOrDischarge,
		validate: validateCargoLines
	},
	[OperationTypeCode.DISCHARGING_STS]: {
		component: Discharge,
		getColumns: getDischargeCargoLineColumns,
		shouldShowChangeOperationWarning: shouldShowChangeOperationWarningForLoadOrDischarge,
		resetOperation: resetOperationForLoadOrDischarge,
		validate: validateCargoLines
	},
	[OperationTypeCode.CREW_CHANGE]: {
		component: CrewChange,
		shouldShowChangeOperationWarning,
		validate: validateCrewChange,
		resetOperation: (change, _reset, untouch) =>
			resetFields(CrewChangeFormNames, change, untouch)
	},
	[OperationTypeCode.CASH_TO_MASTER]: {
		component: CashToMaster,
		shouldShowChangeOperationWarning,
		validate: validateCashToMaster,
		resetOperation: (
			change: InjectedFormProps['change'],
			resetForm: typeof reset,
			untouch
		) => {
			resetFields(CashToMasterFormNames, change, untouch);
			change(TOTAL_AMOUNT_FIELD_NAME, []);
			resetForm(FORM.totalAmountLine);
		}
	},
	[OperationTypeCode.BUNKERING]: {
		component: Bunkering,
		resetOperation: (
			change: InjectedFormProps['change'],
			resetForm: typeof reset,
			_untouch
		) => {
			change(BunkeringFieldName.GRADE_LINES, []);
			change(BunkeringFieldName.DETAILS, '');
			resetForm(FORM.gradeLine);
		},
		shouldShowChangeOperationWarning,
		validate: validateBunkering,
		warn: warnBunkering
	},
	[OperationTypeCode.CANAL_TRANSIT]: {
		component: CanalTransit,
		resetOperation: (change, _reset, untouch) =>
			resetFields(CanalTransitFormFieldName, change, untouch),
		shouldShowChangeOperationWarning
	},
	[OperationTypeCode.CREW_WAGE_DISBURSEMENT]: {
		component: CrewWageDisbursement,
		shouldShowChangeOperationWarning,
		validate: validateCrewWageDisbursement,
		resetOperation: (
			change: InjectedFormProps['change'],
			resetForm: typeof reset,
			untouch
		) => {
			resetFields(CrewWageDisbursementFormFieldName, change, untouch);
			change(TOTAL_AMOUNT_FIELD_NAME, []);
			resetForm(FORM.totalAmountLine);
		}
	},
	[OperationTypeCode.SPARES_AND_DOCUMENT_DELIVERY]: {
		component: SparesAndDocumentDelivery,
		shouldShowChangeOperationWarning,
		validate: validateSparesAndDocumentDelivery,
		resetOperation: (change, _reset, untouch) =>
			resetFields(SparesAndDocumentDeliveryFormFieldName, change, untouch)
	}
};

export default OperationConfig;
