import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, takeLatest, all, fork, put, select } from 'redux-saga/effects';
import {
	updateVPFromFinance,
	updateVPFromFinanceAsync
} from 'store/vesselProgramme/actions';
import { UpdateVPFromFinanceRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { retrieveFinance } from 'store/finance';
import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { retrievePortCall } from 'store/portcalls';
import { notify } from 'store/notifications/actions';

export function* updateVPFromFinanceExecuter(
	actionParams: UpdateVPFromFinanceRequest,
	api: typeof Api.VesselProgramme.updateVPFromFinance
): SagaIterator {
	yield put(updateVPFromFinanceAsync.started(actionParams));
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);

	try {
		const response = yield call(api, actionParams);

		yield put(
			retrieveFinance({
				portCallId,
				jobCode,
				expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS],
				isSilent: true
			})
		);
		yield put(
			retrievePortCall({
				id: portCallId,
				expand: 'PortJobsBasic',
				isSilent: true
			})
		);
		yield put(
			updateVPFromFinanceAsync.done({
				result: response.data.rotationSteps,
				params: actionParams,
				response
			})
		);
		if (
			actionParams.payload.events.some(
				e => e.estimatedDatePlt && e.code === 'EOS'
			)
		) {
			yield put(
				notify.success('You have successfully confirmed your programme.')
			);
		}
	} catch (error) {
		yield put(
			updateVPFromFinanceAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* updateVPFromFinanceWorker(
	action: Action<UpdateVPFromFinanceRequest>
): SagaIterator {
	yield call(
		updateVPFromFinanceExecuter,
		action.payload,
		Api.VesselProgramme.updateVPFromFinance
	);
}

export function* updateVPFromFinanceWatcher(): SagaIterator {
	yield takeLatest(updateVPFromFinance.type, updateVPFromFinanceWorker);
}

export default function*() {
	yield all([fork(updateVPFromFinanceWatcher)]);
}
