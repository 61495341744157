import { FetchStatus } from 'services/api/apiTypes';
import {
	Service,
	ServiceCategory
} from 'services/api/services/servicesServiceTypes';

export interface ServicesState {
	byCode: {
		[serviceId: string]: Service;
	};
	serviceCategories: {
		[serviceCategoryId: string]: ServiceCategory;
	};
	fetchStatuses: {
		retrieveServices: FetchStatus;
		addServiceCategory: FetchStatus;
		updateServiceCategory: FetchStatus;
		deleteServiceCategory: FetchStatus;
		retrieveServiceCategories: FetchStatus;
	};
}

export const initialState: ServicesState = {
	byCode: {},
	serviceCategories: {},
	fetchStatuses: {
		retrieveServices: FetchStatus.IDLE,
		addServiceCategory: FetchStatus.IDLE,
		updateServiceCategory: FetchStatus.IDLE,
		deleteServiceCategory: FetchStatus.IDLE,
		retrieveServiceCategories: FetchStatus.IDLE
	}
};
