import actionCreator from '../groupsActionCreator';
import { AddGroupRequest, Group } from 'services/api/groups/groupsServiceTypes';

export interface AddGroupRequestPayload {
	form: string;
	requestParams: AddGroupRequest;
}

const ACTION_NAME = 'ADD_GROUP';
export const addGroupAsync = actionCreator.async<
	AddGroupRequestPayload,
	Group,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Group'
	},
	behaviour: {
		type: 'submit'
	}
});
export const addGroup = actionCreator<AddGroupRequestPayload>(ACTION_NAME);
