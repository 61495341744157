import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onSetDefaultMessagingGroupSuccess = (
	state: PortJobState,
	{
		params,
		result
	}: Success<SetDefaultMessagingGroupRequest, SetDefaultMessagingGroupResponse>
): PortJobState => ({
	...state,
	byId: {
		[params.portJobId]: {
			...state.byId[params.portJobId],
			defaultMessagingGroup: params.group,
			concurrencyToken: result.concurrencyToken
		}
	}
});

export const onSetDefaultMessagingGroup = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	SetDefaultMessagingGroupRequest,
	SetDefaultMessagingGroupResponse
>('fetchStatuses', 'settingDefaultMessagingGroup', {
	doneReducer: onSetDefaultMessagingGroupSuccess
});
