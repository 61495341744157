import { UploadFile } from 'components/antd/Upload/Upload';
import { ValidationStatus } from 'services/api/documents/documentsServiceTypes';
import { EntityId } from '../apiTypes';
import {
	ContextType,
	ThreadStatus
} from 'services/api/threads/threadsServiceTypes';
import { UserStatus } from 'services/api/users/userServiceTypes';

export enum MessageAction {
	REPLY = 'reply',
	REPLY_ALL = 'replyAll',
	FORWARD = 'forward',
	REPLY_LAST = 'replyLast',
	SAVE = 'save',
	PRINT = 'print'
}
/**
 * Message recipient interface used in thread/message/compose
 */
export interface MessageRecipient {
	userId: string;
	fullName: string;
	email: string;
	name: string;
	companies: string[];
	isContextualised: boolean;
	selected?: boolean;
	status?: UserStatus;
	companyName?: string;
	companyId?: string;
}

export interface MessageEventAssign {
	id: string;
	name: string;
}
type MessageEventType =
	| 'MessageAdded'
	| 'StatusUpdated'
	| 'AssignedGroupUpdated'
	| 'AssignedUserUpdated';

export interface MessageEvent {
	id: string;
	message: Message;
	isReply: boolean;
	eventType: MessageEventType;
	newStatus: string;
	createdOn: string;
	createdBy: MessageEventAssign;
	newAssignedToGroup: MessageEventAssign;
	newAssignedToUser: MessageEventAssign;
}

export interface Message {
	id: string;
	subject: string;
	content: string;
	contentHistory?: string;
	hasContentHistory: boolean;
	from: MessageRecipient;
	to: MessageRecipient[];
	cc: MessageRecipient[];
	attachments: Attachment[];
	createdOn: string;
	updatedOn: string;
	sentOn: string;
	sentStatus: {
		isSuccess: boolean;
		failedRecipients: string[];
	};
	unsupportedAttachments: Array<{
		name: string;
		contentType: string;
		size: number;
		retrievalDate: string;
	}>;
}

export interface MessageContentHistoryContext {
	subject: string;
	from: string;
	to: string[];
	cc: string[];
	content: string;
}

/**
 * Attachment (file) structure that it's saved on the Database
 */
export interface Attachment {
	id: string;
	name: string;
	contentType?: string;
	size: number;
	originFileObj?: UploadFile;
	processed?: boolean;
}

export interface MessageAttachment extends Attachment {
	status: ValidationStatus;
	url: string;
}

export interface RetrieveMessageAttachmentRequest {
	threadId: string;
	messageId: string;
	attachmentId: string;
}

export type RetrieveMessageAttachmentResponse = MessageAttachment;

export interface MessageCreateRequest {
	subject?: string;
	updateThreadStatus?: ThreadStatus;
	to?: Array<{ email: string; name?: string; userId?: string }>;
	cc?: Array<{ email: string; name?: string; userId?: string }>;
	replyTo?: Array<{ email: string; name?: string; userId?: string }>;
	messageType?: string;
	emailType?: string;
	from?: { email: string; name?: string; userId?: string };
	content?: string;
	contentHistory?: string;
	email?: {
		conversationId: string;
		importance: string;
		messageId: string;
		resourceId: string;
	};
	attachments: Attachment[];
	isQuickResponse: boolean;
	contextId?: string;
	jobCodes?: string[];
	mustStartNewThread?: boolean;
}

export interface RetrieveThreadMessageByIdParams {
	threadId: string;
	messageId: string;
	params?: {
		expand?: string | string[];
	};
}

export interface ContactRequest {
	userCompanyId: string;
	email: string;
}

export interface ContactType {
	userCompanyId?: string;
	contactType: {
		code: string;
		name?: string;
	};
	email: string;
	firstName: string;
	lastName: string;
	notes?: string;
	phone?: string;
	relatesToUser?: EntityId;
	relatesToCompany: {
		id: string;
		name?: string;
		email?: string;
	};
	message?: {
		id: string;
	};
}

/**
 * Add Thread Message
 */

export interface AddThreadMessageRequest {
	threadId: string;
	message: MessageCreateRequest;
	// TODO: Remove once Thread is fully moved into Sagas
	onSuccess?: (response: AddThreadMessageResponse) => void;
}

export type AddThreadMessageResponse = Message;

export interface UpdateContextParams {
	threadId: string;
	threadContext: ContextType | null;
}

export interface UpdateThreadReadRequest {
	threadId: string;
	messageId: string;
}

export interface RetrieveMessageHistoryRequest {
	messageId: string;
	threadId: string;
}

export interface RetrieveMessageHistoryResponse extends Message {}

/** 
 Downloading EML byteArray
*/

export interface DownloadEmlFileRequest {
	threadId: string;
	messageId: string;
}

export interface DownloadEmlFileResponse {
	messageId: string;
	message: string;
	subject: string;
}
