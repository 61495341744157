import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { VesselProgrammeState } from '../vesselProgrammeState';
import { AcknowledgeVPWithCommentRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

export const onAcknowledgeVPWithComment = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	AcknowledgeVPWithCommentRequest,
	void
>('fetchStatuses', 'acknowledgeWithComment');
