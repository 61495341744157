import {
	FormData,
	FormField,
	PortJobServiceFormData,
	PortJobFormData
} from 'store/form/supplierInvoiceContextualization/supplierInvoiceContextualizationFormTypes';
import runValidations, {
	required,
	minValue,
	isDateValid
} from 'utils/validations';
import { ContextualizationFormProps } from './ContextualizationForm';
import { getCustomFormattedValue } from 'utils/numbers/getCustomFormattedValue';

interface SupplierInvoiceValidation {
	[key: string]: string[] | undefined | object;
}

export const CONTROL_AND_INVOICE_TOTAL_NOT_EQUAL_ERROR =
	'Invoice Total and Control Total are not equal';

export const REQUIRED_FLAT_FIELDS = [
	FormField.MAIN_PRINCIPAL_COMPANY,
	FormField.SUPPLIER_COMPANY,
	FormField.INVOICE_DATE,
	FormField.RECEIPT_DATE,
	FormField.REFERENCE_NUMBER,
	FormField.CURRENCY_CODE,
	FormField.TOTAL
];

export const calculatePortJobServiceErrors = (
	service: PortJobServiceFormData
) => {
	if (!service.isSelected) {
		return {};
	}
	return {
		totalAmount: runValidations(
			service[FormField.PORT_JOB_SERVICE_TOTAL_AMOUNT],
			[required, minValue(0)]
		)
	};
};

export const calculatePortJobsErrors = (portJobsValues: PortJobFormData[]) =>
	portJobsValues?.map(job => ({
		services: job[FormField.PORT_JOB_SERVICES].map(
			calculatePortJobServiceErrors
		)
	}));

export const applyValidationRules = (
	values: FormData,
	fields: string[]
): SupplierInvoiceValidation => {
	const errors = {};
	fields.forEach(field => {
		errors[field] = runValidations(values[field], [required]);
	});
	return {
		...errors,
		[FormField.PORT_JOBS]: calculatePortJobsErrors(values[FormField.PORT_JOBS]),
		[FormField.TOTAL]: runValidations(values[FormField.TOTAL], [
			required,
			minValue(0)
		]),
		[FormField.INVOICE_DATE]: runValidations(values[FormField.INVOICE_DATE], [
			required,
			isDateValid
		]),
		[FormField.RECEIPT_DATE]: runValidations(values[FormField.RECEIPT_DATE], [
			required,
			isDateValid
		])
	};
};

export const areInvoiceTotalAndControlTotalEqual = (
	invoiceTotal: string,
	controlTotal: string
) =>
	getCustomFormattedValue(invoiceTotal, 2) ===
	getCustomFormattedValue(controlTotal, 2);

const validate = (
	values: FormData,
	{ controlTotal }: ContextualizationFormProps
): SupplierInvoiceValidation => {
	if (
		areInvoiceTotalAndControlTotalEqual(values[FormField.TOTAL], controlTotal)
	) {
		return applyValidationRules(values, REQUIRED_FLAT_FIELDS);
	}
	return {
		...applyValidationRules(values, REQUIRED_FLAT_FIELDS),
		[FormField.TOTAL]: [CONTROL_AND_INVOICE_TOTAL_NOT_EQUAL_ERROR]
	};
};

export default validate;
