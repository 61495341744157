import React from 'react';
import OperationSettingsButton from 'sections/PortCall/components/settings/OperationSettingsButton';
import { PortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';
import { Flex } from 'components';
import { Justify } from 'components/types';
import styles from './Operation.module.scss';

interface OperationHeaderProps {
	operation: PortJobOperation;
	portCallId: string;
	portJobCode: string;
	isPortJobCancelled?: boolean;
}

const OperationHeader = ({
	portJobCode,
	portCallId,
	operation,
	isPortJobCancelled
}: OperationHeaderProps) => {
	const HEADER_ID = `operation-${operation.id}-header`;
	const hasNoMetadataAction = operation?.metadata?.actions.length === 0;
	return (
		<div id={HEADER_ID}>
			<Flex justify={Justify.BETWEEN}>
				<strong className={styles.header}>{operation.name}</strong>
				<OperationSettingsButton
					disabled={isPortJobCancelled || hasNoMetadataAction}
					operation={operation}
					portCallId={portCallId}
					portJobCode={portJobCode}
				/>
			</Flex>
		</div>
	);
};

export default React.memo(OperationHeader);
