import * as React from 'react';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import { GroupUser } from 'services/api/users/userServiceTypes';
import { Button } from 'components/antd';

export const getUserGroupsColumns = (
	onDeleteGroup: (group: GroupUser) => void,
	assignedGroups: GroupUser[],
	isUserInMessagingTeam: boolean,
	profilePage: boolean
) => {
	const columns: Array<ColumnProps<GroupUser>> = [
		{
			title: 'Group',
			dataIndex: 'name'
		}
	];
	if (isUserInMessagingTeam) {
		columns.splice(1, 0, {
			title: 'Messaging Group',
			dataIndex: 'isMessagingGroup',
			width: 150,
			render: isMessagingGroup => {
				return isMessagingGroup ? 'Yes' : 'No';
			}
		});
	}
	if (!profilePage) {
		columns.push({
			width: 50,
			align: 'right',
			render: group =>
				assignedGroups.length > 1 ? (
					<Button
						onClick={() => {
							onDeleteGroup(group);
						}}
						transparent
						icon="delete"
						type="danger"
					/>
				) : null
		});
	}
	return columns;
};
