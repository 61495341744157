import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';

import { retrieveEntityPermissionsForVPAsync } from '../actions';
import { notify } from 'store/notifications/actions';
import { EntityPermissionVPActionParams } from 'services/api/permissions/permissionsServiceTypes';
import { openMergeOperationUnitModal } from 'store/vesselProgramme/actions';
import { isNewlyCreatedUnit } from 'store/vesselProgramme/selectors/vesselProgrammeSelectors';

export function* cargoPermissionSaga(
	request: EntityPermissionVPActionParams,
	api: typeof Api.Permissions.retrieveEntityPermissionsForVP
): SagaIterator {
	if (isNewlyCreatedUnit(request.entityKey)) {
		yield put(
			openMergeOperationUnitModal({ operationUnitId: request.entityKey })
		);
		return;
	}
	yield put(retrieveEntityPermissionsForVPAsync.started(request));
	try {
		const response = yield call(api, request);
		yield put(
			retrieveEntityPermissionsForVPAsync.done({
				result: response.data,
				params: request,
				response
			})
		);

		if (!response.data.canUpdate) {
			yield put(
				notify.warning(
					`Cargo can't be merged as it has operational data (events, documents) associated to it. Please remove this data first in order to be able to merge it.`
				)
			);
			return;
		}

		yield put(
			openMergeOperationUnitModal({ operationUnitId: request.entityKey })
		);
	} catch (error) {
		yield put(
			retrieveEntityPermissionsForVPAsync.failed({
				error,
				params: request
			})
		);
	}
}
