import { FetchStatus } from 'services/api/apiTypes';
import {
	Summary,
	JobSections,
	DashboardPortCall,
	DashboardTableau,
	DashboardTableauInfo
} from 'services/api/dashboard/dashboardServiceTypes';
export interface DashboardState {
	portCallsSummary: Summary | null;
	sections: JobSections;
	portCallsByStatusCode: {
		[statusCode: string]: DashboardPortCall[];
	};
	tableau: DashboardTableau[];
	tableauInfoByCode: {
		[tableauCode: string]: DashboardTableauInfo;
	};
	context: {
		isSectionsFirstLoad: boolean;
		alertModalId?: string;
		activeSectionCode: string;
		tableauCompanyId: string | undefined;
	};
	fetchStatuses: {
		retrievePortCallsSummary: FetchStatus;
		retrieveJobsSections: FetchStatus;
		retrieveActiveSection: FetchStatus;
		retrieveTableau: FetchStatus;
		retrieveTableauInfo: {
			[tableauCode: string]: FetchStatus;
		};
	};
}

export const initialState: DashboardState = {
	portCallsSummary: null,
	sections: {
		count: 0,
		elements: []
	},
	portCallsByStatusCode: {},
	context: {
		isSectionsFirstLoad: true,
		activeSectionCode: '',
		tableauCompanyId: ''
	},
	tableau: [],
	tableauInfoByCode: {},
	fetchStatuses: {
		retrievePortCallsSummary: FetchStatus.IDLE,
		retrieveJobsSections: FetchStatus.IDLE,
		retrieveActiveSection: FetchStatus.IDLE,
		retrieveTableau: FetchStatus.IDLE,
		retrieveTableauInfo: {}
	}
};
