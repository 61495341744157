import { SagaIterator } from 'redux-saga';
import { call, delay } from 'redux-saga/effects';
import api from 'services/api';
import { AxiosResponse } from 'axios';
import { RetrievePortCallsAlertsResponse } from 'services/api/portCalls/portCallsServiceTypes';

const AMOUNT_OF_ALERT_CHECKS = 20;
const DELAY_BETWEEN_ALERT_CHECKS_IN_MS = 2000;

const isAlertInResponse = (
	alertId: string,
	[portCall]: RetrievePortCallsAlertsResponse
) => {
	const portCallAlerts = portCall?.portCallAlertGroupsByMetricType || [];
	const alerts = (portCallAlerts[0] && portCallAlerts[0].alerts) || [];
	return alerts.some(alert => alert.alertId === alertId);
};

export enum AlertResolveStatus {
	RESOLVED,
	MAX_CHECKS_EXHOUSTED
}
export function* vesselProgrammePendingAlertsPolling(
	portCallId: string,
	alertId: string,
	tries: number = AMOUNT_OF_ALERT_CHECKS
): SagaIterator {
	// prevent portCallIds from having no ids in the "retrievePortCallsAlerts" call
	if (!portCallId) {
		return;
	}
	let triesLeft = tries;
	while (triesLeft > 0) {
		try {
			const {
				data
			}: AxiosResponse<RetrievePortCallsAlertsResponse> = yield call(
				api.PortCalls.retrievePortCallsAlerts,
				{
					portCallIds: [portCallId],
					includeAlerts: true
				}
			);
			if (!isAlertInResponse(alertId, data)) {
				return AlertResolveStatus.RESOLVED; // no alert id in response we are done
			}
		} catch (e) {
			// for failure we just try again, if response never came it will end by triesLeft variable
		}
		triesLeft--;
		yield delay(DELAY_BETWEEN_ALERT_CHECKS_IN_MS);
	}
	return AlertResolveStatus.MAX_CHECKS_EXHOUSTED;
}
