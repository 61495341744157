import React from 'react';

import { Text } from 'components';
import { Modal, Alert } from 'components/antd';
import { ModalProps } from 'components/antd/Modal/Modal';

interface DocumentViewerCompletionModalProps
	extends Pick<ModalProps, 'onOk' | 'onCancel'> {
	pages: number[];
}
const DocumentViewerCompletionModal: React.SFC<
	DocumentViewerCompletionModalProps
> = ({ pages, onOk, onCancel }) => {
	return (
		<Modal
			visible
			title="Completion of document processing"
			okText="Yes, continue"
			onOk={onOk}
			cancelText="Back to processing"
			onCancel={onCancel}
		>
			<Alert
				type="warning"
				message={
					<p>
						The following pages have no context:{' '}
						<Text weight="bold">{pages.join(', ')}</Text>. If you continue, they
						will be ignored. Are you sure you want to continue?
					</p>
				}
				transparent
				showIcon
			/>
		</Modal>
	);
};

export default DocumentViewerCompletionModal;
