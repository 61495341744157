import React, { Component } from 'react';
import { DateType, DateMap } from '../PortCallsFiltersConstants';
import { Select } from 'components/antd';
import moment, { Moment } from 'moment-timezone';
import { isEmpty } from 'lodash';
import { FilterItem } from 'store/filters/filtersState';
import { PortCallsFiltersParamEnum } from 'store/portcalls/filtersSync';
import styles from '../PortCallsFilters.module.scss';
import { DatePickerExtended } from 'components/DatePickerExtended/DatePickerExtended';

interface DateFilterProps {
	date: FilterItem | undefined;
	onChange: (name: string) => (filter: FilterItem) => void;
}

interface DateFilterState {
	dateType: string;
	fromDate: string | undefined;
	toDate: string | undefined;
}

const initialState = {
	dateType: '',
	fromDate: undefined,
	toDate: undefined
};

class DateFilter extends Component<DateFilterProps, DateFilterState> {
	constructor(props: DateFilterProps) {
		super(props);
		if (props?.date?.key) {
			const [dateType, fromDate, toDate] = props.date.key.split(' ');
			this.state = {
				dateType: dateType || '',
				fromDate: fromDate || undefined,
				toDate: toDate || undefined
			};
		} else {
			this.state = initialState;
		}
	}
	private prepareDisplayText(
		dateType: string,
		fromDate: string | undefined,
		toDate: string | undefined,
		format: string,
		label?: boolean
	) {
		return label
			? `${dateType} - ${moment(fromDate).format(format)} - ${moment(
					toDate
			  ).format(format)}`
			: `${dateType} ${moment(fromDate).format(format)} ${moment(toDate).format(
					format
			  )}`;
	}
	componentDidUpdate(prevProps: DateFilterProps, prevState: DateFilterState) {
		const { dateType, fromDate, toDate } = this.state;
		if (dateType && fromDate && toDate) {
			const prevValue = this.prepareDisplayText(
				prevState.dateType,
				prevState.fromDate,
				prevState.toDate,
				'YYYY-MM-DD'
			);
			const value = this.prepareDisplayText(
				dateType,
				fromDate,
				toDate,
				'YYYY-MM-DD'
			);
			if (value !== prevValue) {
				this.props.onChange(PortCallsFiltersParamEnum.DATE)({
					key: value,
					label: this.prepareDisplayText(
						DateMap[dateType],
						fromDate,
						toDate,
						'DD/MM/YYYY',
						true
					),
					type: PortCallsFiltersParamEnum.DATE
				});
			}
		}
		if (prevProps.date && !this.props.date) {
			this.setState({ ...initialState });
		}
	}
	onSelectDateType = (value: string) => {
		this.setState({
			dateType: value,
			fromDate: undefined,
			toDate: undefined
		});
	};
	disabledStartDate = (fromDate: Moment) => {
		const toDate = this.state.toDate;
		if (!fromDate || !toDate) {
			return false;
		}
		return (
			fromDate
				.local(true)
				.startOf('day')
				.valueOf() > moment(toDate).valueOf()
		);
	};

	disabledEndDate = (toDate: Moment) => {
		const fromDate = this.state.fromDate;
		if (!toDate || !fromDate) {
			return false;
		}
		return (
			toDate
				.local(true)
				.startOf('day')
				.valueOf() < moment(fromDate).valueOf()
		);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange = (field: any, value: string) => {
		this.setState({
			[field]: value
		});
	};

	onStartChange = (value: string) => {
		this.onChange('fromDate', value);
	};

	onEndChange = (value: string) => {
		this.onChange('toDate', value);
	};

	render() {
		const { dateType, fromDate, toDate } = this.state;
		return (
			<>
				<Select
					onSelect={this.onSelectDateType}
					placeholder="Select date type"
					value={dateType || undefined}
				>
					{DateType.map(eachDate => (
						<Select.Option
							key={eachDate.id}
							value={eachDate.id}
							title={eachDate.label}
						>
							{eachDate.label}
						</Select.Option>
					))}
				</Select>
				{!isEmpty(this.state.dateType) && (
					<>
						<div className={styles.marginTop}>
							From
							<DatePickerExtended
								showTime={false}
								format="DD/MM/YYYY"
								onChange={this.onStartChange}
								value={fromDate}
								renderOutside
								antDatePicker={{
									disabledDate: this.disabledStartDate,
									showToday: false
								}}
								local
							/>
						</div>
						<div className={styles.marginTop}>
							To
							<DatePickerExtended
								showTime={false}
								format="DD/MM/YYYY"
								onChange={this.onEndChange}
								value={toDate}
								renderOutside
								antDatePicker={{
									disabledDate: this.disabledEndDate,
									showToday: false
								}}
								local
							/>
						</div>
					</>
				)}
			</>
		);
	}
}

export default DateFilter;
