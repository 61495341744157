import { CostSaving } from 'services/api/savings/savingsServiceTypes';
import { SavingsState } from '../savingsState';

export function onSetCostSavingsContext(
	state: SavingsState,
	action: CostSaving
): SavingsState {
	return {
		...state,
		context: {
			activeId: action.id,
			costSavings: { ...action },
			timeSavings: undefined
		}
	};
}
