import React from 'react';
import classNames from 'classnames';
import styles from './DocumentPageViewer.module.scss';

import { Text, Flex } from 'components';
import { Button } from 'components/antd';

import { Justify } from 'components/types';
import { PortCallDocumentPage } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { isNil } from 'lodash';

export interface DocumentPageProps {
	fileName: string;
	index?: number;
	activePage?: number;
	page?: PortCallDocumentPage;
	onSelect?: (activePage: number) => void;
	onDelete?: (page: PortCallDocumentPage) => void;
	loading?: boolean;
	isDeleteVisible?: boolean;
}

export const DocumentPageViewerPage: React.SFC<DocumentPageProps> = ({
	index,
	page,
	fileName,
	activePage,
	onSelect,
	onDelete,
	loading,
	isDeleteVisible = true
}) => {
	const isActive = activePage === index;
	const hasIndex = !isNil(index);

	return (
		<Flex
			stretch
			justify={Justify.BETWEEN}
			onClick={
				onSelect && hasIndex ? () => onSelect(index as number) : undefined
			}
			className={classNames(styles.item, {
				[styles.selected]: isActive
			})}
		>
			<Text weight="bold" color={isActive ? 'default' : 'primary'}>
				{fileName}
				{page?.number ? `, p.${page.number}` : ''}.pdf
			</Text>
			{isDeleteVisible && (
				<Button
					type="danger"
					transparent
					icon="delete"
					loading={loading}
					onClick={(e: React.FormEvent<{}>) => {
						e.stopPropagation();
						if (onDelete && page) {
							onDelete(page);
						}
					}}
				/>
			)}
		</Flex>
	);
};

export default DocumentPageViewerPage;
