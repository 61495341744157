import { WizardFormState, WizardFormBaseAction } from '../wizardFormState';
export const onNextPage = (
	state: WizardFormState,
	action: WizardFormBaseAction
) => {
	const { name } = action;
	const originalForm = state.forms[action.name];
	const form = {
		...originalForm,
		activePage: originalForm.activePage + 1
	};
	if (form.activePage > Object.keys(form.pages).length) {
		return state;
	}
	return {
		...state,
		forms: {
			...state.forms,
			[name]: form
		}
	};
};
