import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { User } from 'services/api/users/userServiceTypes';
import EditUserPage from './EditUser/EditUserPage';

type EditUserRouteProps = RouteComponentProps<{ userId: User['id'] }>;

const EditUserRoute: FC<EditUserRouteProps> = ({
	match: {
		params: { userId }
	}
}) => {
	return <EditUserPage userId={userId} />;
};

export default EditUserRoute;
