import actionCreator from '../financeActionCreator';
import { DownloadFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';

const DOWNLOAD_FINANCE_DOCUMENT = 'DOWNLOAD_FINANCE_DOCUMENT';
export const downloadFinanceDocumentAsync = actionCreator.async<
	DownloadFinanceDocumentRequest,
	null,
	Error
>(DOWNLOAD_FINANCE_DOCUMENT, {
	failed: {
		description: 'Your download has failed. Please try again'
	}
});

export const downloadFinanceDocument = actionCreator<
	DownloadFinanceDocumentRequest
>(DOWNLOAD_FINANCE_DOCUMENT);
