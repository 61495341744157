import * as React from 'react';
import { connect } from 'react-redux';
import { Flex, PageHeader } from 'components';
import { RouteComponentProps } from 'react-router';

import { retrieveThreadById } from 'store/thread/actions';
import { setActiveThreadId } from 'store/threads/actions';
import { setMode, setFromPath } from 'store/wizardForm/actions';
// import { THREAD_ID_URL_PARAM } from 'sections/App/RouteParams';
import CreatePortJobWizard from 'sections/PortJob/CreatePortJob/CreatePortJobWizard';
import { PORT_JOB_WIZARD_ID } from '../PortJob/CreatePortJob/createPortJobConstants';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { getBreadcrumbsForThreadPortJobWizard } from 'store/threads/selectors';
import { navigateTo } from 'utils';
import { getOriginUrl } from 'store/wizardForm/wizardFormSelectors';
import { NEW_THREAD_ID } from 'store/thread/threadConstants';
import { AppState } from 'store-types';
import { AppNotifications } from 'containers';

// extends RouteComponentProps<{}>
interface ThreadJobAssignmentRouteProps
	extends RouteComponentProps<{ threadId: string }> {
	// from mapStateToProps
	breadcrumbs: BreadcrumbItem[];
	originUrl: string;
	// from mapDispatchToProps
	setMode: typeof setMode;
	retrieveThreadById: typeof retrieveThreadById;
	setActiveThreadId: typeof setActiveThreadId;
	setFromPath: typeof setFromPath;
}

class ThreadJobAssignmentRoute extends React.Component<
	ThreadJobAssignmentRouteProps
> {
	isNominationJob: boolean;

	constructor(props: ThreadJobAssignmentRouteProps) {
		super(props);
		this.isNominationJob = false;
	}

	componentDidMount() {
		const { threadId } = this.props.match.params;
		this.isNominationJob = this.props.location.pathname.includes('nomination');
		this.props.setMode({ mode: 'assign', name: PORT_JOB_WIZARD_ID });
		if (this.isNominationJob) {
			this.props.setFromPath({
				fromPath: 'nomination',
				name: PORT_JOB_WIZARD_ID
			});
		}
		threadId && !this.isNewThread()
			? this.props.retrieveThreadById({ threadId })
			: this.props.setActiveThreadId(NEW_THREAD_ID);
	}

	isNewThread() {
		const { threadId } = this.props.match.params;
		return threadId === NEW_THREAD_ID;
	}

	goToMessage = () => {
		let messageUrl: string;

		if (this.props.originUrl) {
			messageUrl = this.props.originUrl;
		} else {
			const { threadId } = this.props.match.params;
			messageUrl = !this.isNewThread()
				? `/messages/${threadId}`
				: '/new-message';
		}
		navigateTo(messageUrl);
	};

	render() {
		return (
			<Flex fit direction="vertical" stretch>
				<RouteBreadcrumbs items={this.props.breadcrumbs} />
				<AppNotifications.Notification />
				{this.isNominationJob ? (
					<PageHeader.NewTab
						onClose={this.goToMessage}
						subtitle={this.isNominationJob && 'Port Call Details'}
					>
						Create Appointment from Nomination
					</PageHeader.NewTab>
				) : (
					<PageHeader.NewTab onClose={this.goToMessage}>
						{this.isNewThread() ? 'Assign Job' : 'Assign Job/Create Job'}
					</PageHeader.NewTab>
				)}
				<CreatePortJobWizard onCancel={this.goToMessage} />
			</Flex>
		);
	}
}

export default connect(
	(state: AppState) => ({
		breadcrumbs: getBreadcrumbsForThreadPortJobWizard(state),
		originUrl: getOriginUrl(state)
	}),
	{
		retrieveThreadById,
		setActiveThreadId,
		setMode,
		setFromPath
	}
)(ThreadJobAssignmentRoute);
