import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveOperationsAsync } from './actions/retrieveOperations';
import { keyBy } from 'lodash';
import { retrieveBunkerGradesAction } from './actions';
import { combineReducers } from 'redux';
import { FetchStatus } from 'services/api/apiTypes';
import { OperationsState } from './operationsState';
import { BunkerGrade } from 'services/api/operations/operationsServiceTypes';

export const operationsReducer = reducerWithInitialState<
	OperationsState['operations']
>([]).case(
	retrieveOperationsAsync.done,
	(_state, { result: operations }) => operations
);

export const bunkerGradesReducer = reducerWithInitialState<
	OperationsState['bunkerGradesById']
>({}).case(
	retrieveBunkerGradesAction.async.done,
	(_state, { result: { elements } }) =>
		keyBy<BunkerGrade>(elements, item => item.id)
);

const fetchStatusesReducer = reducerWithInitialState<
	OperationsState['fetchStatuses']
>({
	operations: FetchStatus.IDLE
})
	.case(retrieveOperationsAsync.started, () => ({
		operations: FetchStatus.PENDING
	}))
	.case(retrieveOperationsAsync.done, () => ({
		operations: FetchStatus.SUCCESS
	}))
	.case(retrieveOperationsAsync.failed, () => ({
		operations: FetchStatus.FAILURE
	}));

export default combineReducers({
	operations: operationsReducer,
	bunkerGradesById: bunkerGradesReducer,
	fetchStatuses: fetchStatusesReducer
});
