import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, select } from 'redux-saga/effects';
import {
	addCompanyDraft,
	submitCompanyDraft,
	SubmitCompanyDraftActionPayload
} from '../actions';
import { change } from 'redux-form';
import uuidv4 from 'uuid/v4';
import {
	getCompanyDraftsByType,
	getActiveDraftType,
	getActiveDraftFormId,
	getActiveDraftFieldName
} from '../selectors';
import { CompanyDraft, CompanyDraftType } from '../draftsState';
import { last } from 'lodash';

function* submitCompanyDraftWorker({
	payload
}: Action<SubmitCompanyDraftActionPayload>): SagaIterator {
	const payloadData = {
		...payload,
		id: uuidv4(),
		countryCode: payload.countryCode.key
	};
	yield put(addCompanyDraft(payloadData));
	const activeFormId: string = yield select(getActiveDraftFormId);
	const activeFieldName: string = yield select(getActiveDraftFieldName);
	const activeDraftType: CompanyDraftType = yield select(getActiveDraftType);
	const companyDrafts: CompanyDraft[] = yield select(
		getCompanyDraftsByType,
		activeDraftType
	);
	const lastCompanyDraft = last(companyDrafts) as CompanyDraft; // we have for sure at least one element, as it was put in this by addCompanyDraft
	yield put(
		change(activeFormId, activeFieldName, {
			key: lastCompanyDraft.id,
			label: lastCompanyDraft.name
		})
	);
}

function* submitCompanyDraftWatcher(): SagaIterator {
	yield takeLatest(submitCompanyDraft.type, submitCompanyDraftWorker);
}

export default submitCompanyDraftWatcher;
