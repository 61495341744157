import { Metadata, MetadataAction } from 'app-types';
import { MainCommodityCode } from 'services/api/commodities/commoditiesServiceTypes';

export enum MeasurementsAccess {
	SHIP = 'Ship',
	CARGO = 'Cargo',
	BUNKERING = 'Bunkering'
}

export enum MeasurementsMetadataActionCode {
	EDIT = 'edit'
}

export type MeasurementsMetadataAction = MetadataAction<
	MeasurementsMetadataActionCode
>;

export enum ShipMeasurementsType {
	ARRIVAL = 'Arrival',
	DEPARTURE = 'Departure'
}

export interface BunkeringMeasurement {
	metadata: Metadata<MeasurementsMetadataActionCode>;
	bunkeringOperationGradeId: string;
	bunkerGradeId: string;
	nominationQuantity: number;
	nominationUnitOfMeasurementCode: string;
	shipQuantity?: number;
	shipUnitOfMeasurementCode?: string;
}

interface ShipMeasurementResponse {
	metadata: Metadata<MeasurementsMetadataActionCode>;
	draftFieldsRequired: boolean;
	vesselProgrammeId: string;
	numOfTugs?: number;
	fwd?: number;
	fwdUnitOfMeasurementCode?: string;
	aft?: number;
	aftUnitOfMeasurementCode?: string;
	lsfo?: number;
	lsfoUnitOfMeasurementCode?: string;
	hfo?: number;
	hfoUnitOfMeasurementCode?: string;
	lsgo?: number;
	lsgoUnitOfMeasurementCode?: string;
	do?: number;
	doUnitOfMeasurementCode?: string;
	go?: number;
	goUnitOfMeasurementCode?: string;
	lubeOil?: number;
	lubeOilUnitOfMeasurementCode?: string;
	water?: number;
	waterUnitOfMeasurementCode?: string;
}

export interface ShipMeasurement extends ShipMeasurementResponse {
	measurementType: ShipMeasurementsType;
}

export interface CargoMeasurement {
	metadata: Metadata<MeasurementsMetadataActionCode>;
	operationUnitId: string;
	operationTypeName: string;
	portJobCode: string;
	commodityName: string;
	mainCommodityCode: MainCommodityCode;
	nominationQuantity?: number;
	nominationUOMCode?: string;
	shipQuantity?: number;
	shipUOMCode?: string;
	shoreQuantity?: number;
	shoreUOMCode?: string;
	blQuantity?: number;
	bluomCode?: string;
	isCompleted: boolean;
}

export interface RetrievePortCallMeasurementsAccessRequest {
	portCallId: string;
}

export interface RetrievePortCallBunkeringMeasurementsRequest {
	portCallId: string;
}

export interface RetrievePortCallBunkeringMeasurementsResponse {
	metadata: Metadata<MeasurementsMetadataActionCode>;
	bunkeringMeasurements: BunkeringMeasurement[];
}

export interface RetrievePortCallShipMeasurementsRequest
	extends RetrievePortCallBunkeringMeasurementsRequest {}

export interface RetrievePortCallShipMeasurementsResponse {
	arrivalShipMeasurements: ShipMeasurementResponse;
	departureShipMeasurements: ShipMeasurementResponse;
}

export interface RetrievePortCallCargoMeasurementsRequest
	extends RetrievePortCallBunkeringMeasurementsRequest {}

interface UpdateBunkeringOperationGradeBase {
	portCallId: string;
	bunkeringOperationGradeId: string;
}

export interface UpdateBunkeringOperationGradeRequest
	extends UpdateBunkeringOperationGradeBase {
	updatingGradeData: {
		shipQuantity: number | null;
		shipUnitOfMeasurementCode: string;
	};
}

export interface UpdateBunkeringOperationGradeActionPayload
	extends UpdateBunkeringOperationGradeBase {
	updatingGradeData: {
		shipQuantity: string;
		shipUnitOfMeasurementCode: string;
	};
}

export interface UpdateShipMeasurementsRequest {
	portCallId: string;
	updatingData: ShipMeasurement;
}

export interface UpdateCargoMeasurementsRequest {
	portCallId: string;
	updatingData: CargoMeasurement;
}
