import { GroupsState } from '../groupsState';
import { FetchStatus } from 'services/api/apiTypes';
export function onUpdateGroupStarted(state: GroupsState): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.PENDING
		}
	};
}
export function onUpdateGroupSuccess(state: GroupsState): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.SUCCESS
		},
		context: {
			...state.context,
			openedGroup: null
		}
	};
}
export function onUpdateGroupFailed(state: GroupsState): GroupsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			updating: FetchStatus.FAILURE
		}
	};
}
