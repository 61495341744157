import actionCreator from '../wizardFormActionCreator';

import { WizardFormModes } from '../wizardFormState';

export interface WizardFormSetPage {
	name?: string;
	mode: WizardFormModes;
}

const SET_MODE = 'SET_MODE';
export const setMode = actionCreator<WizardFormSetPage>(SET_MODE);
