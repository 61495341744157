import { SupplierInvoiceState } from '../supplierInvoiceState';

export function onSetActiveSupplierInvoiceId(
	state: SupplierInvoiceState,
	id: string
): SupplierInvoiceState {
	return {
		...state,
		context: {
			...state.context,
			activeSupplierInvoiceId: id
		}
	};
}

export function onResetActiveSupplierInvoiceId(
	state: SupplierInvoiceState
): SupplierInvoiceState {
	return {
		...state,
		context: {
			...state.context,
			activeSupplierInvoiceId: ''
		}
	};
}
