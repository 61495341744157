import { SupplierInvoiceState } from 'store/supplierInvoice/supplierInvoiceState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CancelCreditSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

export const onCancelCreditSupplierInvoice = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	CancelCreditSupplierInvoiceRequest,
	void
>('fetchStatuses', 'cancelCreditSupplierInvoice');
