import { createSelector } from 'reselect';
import { getIsLoading } from 'store/selectors';
import { AppState } from 'store-types';
import { includes, sortBy } from 'lodash';

const getStatuses = (state: AppState) =>
	state.configurationServices.fetchStatuses;

const getPrincipalServicesMap = (state: AppState) =>
	state.configurationServices.principalServices;

export const getRetrievePrincipalServicesFetchStatus = (state: AppState) =>
	getStatuses(state).retrievePrincipalServices;

export const getIsPrincipalServicesLoading = (state: AppState) =>
	getIsLoading(getStatuses(state).retrievePrincipalServices);

export const getPrincipalServiceById = (state: AppState, serviceId: string) =>
	getPrincipalServicesMap(state)[serviceId];

export const getPrincipalServices = createSelector(
	getPrincipalServicesMap,
	principalServices => Object.values(principalServices)
);

export const getPrincipalServicesSortedByName = createSelector(
	getPrincipalServices,
	principalServices => sortBy(principalServices, service => service.name)
);

export const getPrincipalServicesFilteredByCodes = createSelector(
	getPrincipalServices,
	(_state: AppState, codes: string[]) => codes,
	(services, codes) =>
		services.filter(service => includes(codes, service.principalServiceCode))
);
