import * as React from 'react';
import * as RouteParams from 'sections/App/RouteParams';
import { connect } from 'react-redux';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getPortCallLayoutBreadcrumbs } from 'sections/PortCall/Layout/portCallLayoutSelectors';
import PortCallContainer from 'sections/PortCall/PortCallContainer';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import { DataFetcher } from 'utils/components';
import { PortCallTabBaseProps } from 'sections/PortCall';
import {
	retrieveSupplierInvoiceDocument,
	downloadSupplierInvoice
} from 'store/supplierInvoice/actions';
import {
	getActiveSupplierInvoiceDocumentFetchStatus,
	getActiveSupplierInvoiceDocument
} from 'store/supplierInvoice/selectors';
import { DocumentBrowserPageViewer } from 'sections/DocumentBrowserPageViewer';
import DocumentPageViewerPage from 'sections/DocumentPageViewer/DocumentPageViewerPage';
import { AppState } from 'store-types';

type FinanceSupplierInvoiceDocumentPageViewerRouteProps = ReturnType<
	typeof mapStateToProps
> &
	typeof mapDispatchToProps &
	PortCallTabBaseProps<{
		supplierInvoiceId?: string;
		serviceId?: string;
	}>;

class FinanceSupplierInvoiceDocumentPageViewerRoute extends React.Component<
	FinanceSupplierInvoiceDocumentPageViewerRouteProps
> {
	getBreadcrumbs = () =>
		this.props.breadcrumbs.concat([
			{
				title: 'View Supplier Invoice'
			}
		]);

	retrieveSupplierInvoice = () => {
		const {
			match: {
				params: { supplierInvoiceId, serviceId }
			}
		} = this.props;
		if (supplierInvoiceId && serviceId) {
			this.props.retrieveSupplierInvoiceDocument({
				supplierInvoiceId,
				serviceId
			});
		}
	};

	onDownloadDocument = () => {
		const {
			match: {
				params: { supplierInvoiceId, serviceId }
			}
		} = this.props;
		if (supplierInvoiceId) {
			this.props.downloadSupplierInvoice({ supplierInvoiceId, serviceId });
		}
	};

	render() {
		const { PATHS } = RouteParams;
		const {
			match,
			fetchStatus,
			activePortCallId,
			activePortJobCode,
			fileDocument
		} = this.props;

		// * Returns to Finance screen
		const returnUrl = `/${PATHS.portCalls}/${activePortCallId}/jobs/${activePortJobCode}/finance`;

		return (
			<PortCallContainer match={match}>
				<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				<DataFetcher
					fetchStatus={fetchStatus}
					dispatch={this.retrieveSupplierInvoice}
				>
					{() =>
						fileDocument && (
							<DocumentBrowserPageViewer
								fileDocument={fileDocument}
								returnUrl={returnUrl}
								onFinish={this.onDownloadDocument}
							>
								<DocumentPageViewerPage
									fileName="Supplier Invoice"
									isDeleteVisible={false}
								/>
							</DocumentBrowserPageViewer>
						)
					}
				</DataFetcher>
			</PortCallContainer>
		);
	}
}
const mapStateToProps = (state: AppState) => ({
	fileDocument: getActiveSupplierInvoiceDocument(state),
	breadcrumbs: getPortCallLayoutBreadcrumbs(state),
	fetchStatus: getActiveSupplierInvoiceDocumentFetchStatus(state),
	activePortCallId: getActivePortCallId(state),
	activePortJobCode: getActivePortJobCode(state)
});
const mapDispatchToProps = {
	retrieveSupplierInvoiceDocument,
	downloadSupplierInvoice
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FinanceSupplierInvoiceDocumentPageViewerRoute);
