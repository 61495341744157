import { AppState } from 'store-types.d';
import { PortJobCargoLine } from 'services/api/portJobs/portJobsServiceTypes';
import { getCountriesMap } from 'store/countries/countriesSelectors';
import { getUnitByCode } from 'store/units/unitsSelectors';

/**
 * Returns a list of CargoLines with the mapped country (in case dischargePortIsUnknown)
 */
export const getDisplayCargoList = (
	state: AppState,
	cargoes: PortJobCargoLine[] = []
): PortJobCargoLine[] => {
	const countriesByCode = getCountriesMap(state);
	return cargoes.map(cargo => {
		const country = countriesByCode[cargo.dischargeCountryCode || ''];
		const updatedCargo = {
			...cargo,
			dischargeCountryName: cargo.dischargePortIsUnknown
				? country?.name || ''
				: ''
		};
		if (cargo.commodityUnitOfMeasurementCode) {
			const unit = getUnitByCode(state, cargo.commodityUnitOfMeasurementCode);
			if (unit) {
				updatedCargo.commodityUnitOfMeasurementCode = unit.display;
			}
		}
		return updatedCargo;
	});
};
