import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { retrieveGroupsAsync } from './actions/retrieveGroups';
import { retrieveGroupAsync } from './actions/retrieveGroup';
import { addGroupAsync } from './actions/addGroup';
import { deleteGroupAsync } from './actions/deleteGroup';
import { updateGroupAsync } from './actions/updateGroup';
import { resetGroups } from './actions/resetGroups';
import { resetGroup } from './actions/resetGroup';
import { retrieveGroupTypesAsync } from './actions/retrieveGroupTypes';
import { retrieveGroupScopePermissionsAsync } from './actions/retrieveGroupScopePermissions';
import checkPermissionAction from './actions/checkPermission';
import {
	initializeEditGroupAsync,
	retrieveThirdPartyLpaGroupsAsync,
	resetThirdPartyLPAGroups
} from './actions';
import { initialState } from './groupsState';

import {
	onRetrieveGroupTypesStarted,
	onRetrieveGroupTypesSuccess,
	onRetrieveGroupFailed,
	onRetrieveGroupTypesFailed,
	onRetrieveGroups,
	onRetrieveGroupStarted,
	onRetrieveGroupSuccess,
	onRetrieveGroupScopePermissionsStarted,
	onRetrieveGroupScopePermissionsSuccess,
	onRetrieveGroupScopePermissionsFailed,
	onAddGroup,
	onDeleteGroup,
	onUpdateGroupStarted,
	onUpdateGroupSuccess,
	onUpdateGroupFailed,
	onResetGroups,
	onResetGroup,
	onCheckPermissionStarted,
	onCheckPermissionSuccess,
	onCheckPermissionFailed,
	onInitializeEditGroup,
	onRetrieveThirdPartyLPAGroups,
	onResetThirdPartyLPAGroups
} from './reducers';

export default reducerWithInitialState(initialState)
	// Retrieve Groups
	.case(retrieveGroupsAsync.started, onRetrieveGroups.started)
	.case(retrieveGroupsAsync.done, onRetrieveGroups.done)
	.case(retrieveGroupsAsync.failed, onRetrieveGroups.failed)
	// Retrieve one Group
	.case(retrieveGroupTypesAsync.started, onRetrieveGroupTypesStarted)
	.case(retrieveGroupTypesAsync.done, onRetrieveGroupTypesSuccess)
	.case(retrieveGroupTypesAsync.failed, onRetrieveGroupTypesFailed)
	.case(retrieveGroupAsync.started, onRetrieveGroupStarted)
	.case(retrieveGroupAsync.done, onRetrieveGroupSuccess)
	.case(retrieveGroupAsync.failed, onRetrieveGroupFailed)
	.case(
		retrieveGroupScopePermissionsAsync.started,
		onRetrieveGroupScopePermissionsStarted
	)
	.case(
		retrieveGroupScopePermissionsAsync.done,
		onRetrieveGroupScopePermissionsSuccess
	)
	.case(
		retrieveGroupScopePermissionsAsync.failed,
		onRetrieveGroupScopePermissionsFailed
	)
	.case(addGroupAsync.started, onAddGroup.started)
	.case(addGroupAsync.done, onAddGroup.done)
	.case(addGroupAsync.failed, onAddGroup.failed)
	.case(deleteGroupAsync.started, onDeleteGroup.started)
	.case(deleteGroupAsync.done, onDeleteGroup.done)
	.case(deleteGroupAsync.failed, onDeleteGroup.failed)
	.case(updateGroupAsync.started, onUpdateGroupStarted)
	.case(updateGroupAsync.done, onUpdateGroupSuccess)
	.case(updateGroupAsync.failed, onUpdateGroupFailed)
	.case(resetGroups, onResetGroups)
	.case(resetGroup, onResetGroup)
	.case(initializeEditGroupAsync.started, onInitializeEditGroup.started)
	.case(initializeEditGroupAsync.done, onInitializeEditGroup.done)
	.case(initializeEditGroupAsync.failed, onInitializeEditGroup.failed)
	// Check Permissions
	.case(checkPermissionAction.async.started, onCheckPermissionStarted)
	.case(checkPermissionAction.async.done, onCheckPermissionSuccess)
	.case(checkPermissionAction.async.failed, onCheckPermissionFailed)
	.case(
		retrieveThirdPartyLpaGroupsAsync.started,
		onRetrieveThirdPartyLPAGroups.started
	)
	.case(
		retrieveThirdPartyLpaGroupsAsync.done,
		onRetrieveThirdPartyLPAGroups.done
	)
	.case(
		retrieveThirdPartyLpaGroupsAsync.failed,
		onRetrieveThirdPartyLPAGroups.failed
	)
	.case(resetThirdPartyLPAGroups, onResetThirdPartyLPAGroups);
