import * as React from 'react';
import { Checkbox } from 'components/antd';
import { FormChangeCheckbox } from 'app-types';

interface IgnorePageFieldProps {
	name: string;
	onChange: FormChangeCheckbox;
}

const IgnorePageField: React.SFC<IgnorePageFieldProps> = ({
	name,
	onChange
}) => {
	return (
		<Checkbox.ReduxFormItem name={name} onChange={onChange}>
			Ignore Page
		</Checkbox.ReduxFormItem>
	);
};

export default IgnorePageField;
