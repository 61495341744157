import React, { FC } from 'react';
import styles from './PrettyJSON.module.scss';
import { Flex } from 'components';
import { Justify } from 'components/types';
import { usePrettifiedJSON } from 'sections/MasterDataExplorer/MasterDataExplorerHooks';

interface PrettyJSONProps {
	objToPrettify: object;
}

const PrettyJSON: FC<PrettyJSONProps> = ({ objToPrettify }) => {
	const { prettifiedJSON } = usePrettifiedJSON(objToPrettify);

	return (
		<Flex justify={Justify.CENTER} className={styles.wrapper}>
			<pre className={styles.pre}>{prettifiedJSON}</pre>
		</Flex>
	);
};

export default PrettyJSON;
