import { PrincipalService } from 'services/api/services/servicesServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

export interface ConfigurationServicesState {
	readonly principalServices: {
		[serviceId: string]: PrincipalService;
	};
	readonly fetchStatuses: {
		retrievePrincipalServices: FetchStatus;
		retrievePrincipalService: FetchStatus;
		addPrincipalService: FetchStatus;
		updatePrincipalService: FetchStatus;
		updatePrincipalServicesMapping: FetchStatus;
		removePrincipalService: FetchStatus;
	};
}

export const initialState: ConfigurationServicesState = {
	principalServices: {},
	fetchStatuses: {
		retrievePrincipalServices: FetchStatus.IDLE,
		retrievePrincipalService: FetchStatus.IDLE,
		addPrincipalService: FetchStatus.IDLE,
		updatePrincipalService: FetchStatus.IDLE,
		updatePrincipalServicesMapping: FetchStatus.IDLE,
		removePrincipalService: FetchStatus.IDLE
	}
};
