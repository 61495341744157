import React from 'react';
import styles from 'components/AutocompleteSearch/AutocompleteSearch.module.scss';

const OptionGroup: React.FC<{ title: string }> = props => (
	<div>
		<div className={styles.optionGroupTitle}>{props.title}</div>
		{props.children}
	</div>
);

export default OptionGroup;
