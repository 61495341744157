import * as React from 'react';
import { connect } from 'react-redux';
import styles from '../../JobDetails.module.scss';
import classNames from 'classnames';
import { DataFetcher } from 'utils/components';
import { Gap } from 'components';
import uuidv4 from 'uuid/v4';

import { AcceptanceDataVendor } from 'services/api/portJobs/portJobsServiceTypes';
import { retrieveAcceptanceData as retrieveAcceptanceDataAction } from 'store/portJobs/actions';

import {
	getPortJobAcceptanceVendors,
	getPortJobAcceptanceDataFetchStatus
} from 'store/portJobs/portJobsSelectors';
import { FetchStatus } from 'services/api/apiTypes';
import { AppState } from 'store-types';

interface AcceptanceVendorsProps {
	acceptanceVendors: AcceptanceDataVendor[];
	acceptanceVendorsFetchStatus: FetchStatus;

	retrieveAcceptanceData: typeof retrieveAcceptanceDataAction;
}

const AcceptanceVendors = ({
	acceptanceVendors,
	acceptanceVendorsFetchStatus,
	retrieveAcceptanceData
}: AcceptanceVendorsProps) => (
	<DataFetcher
		fetchStatus={acceptanceVendorsFetchStatus}
		dispatch={() => retrieveAcceptanceData()}
	>
		{() => (
			<>
				{acceptanceVendors.length !== 0 &&
					acceptanceVendors.map(vendor => (
						<Gap isBlock bottom="md" key={uuidv4()}>
							<h4>Vendor:</h4>
							<div>
								{vendor.name} (
								<span className={classNames(styles.tag, styles.email)}>
									{vendor.email}
								</span>
								)
							</div>

							<Gap isBlock top="sm">
								<h4>Services(s):</h4>
								<div>
									{vendor.serviceNames.map((name, index) => (
										<Gap right="sm" className={styles.tag} key={index}>
											{name}
										</Gap>
									))}
								</div>
							</Gap>

							{vendor.instructions && (
								<Gap isBlock top="sm">
									<h4>Vendor instructions(s):</h4>
									<div>{vendor.instructions}</div>
								</Gap>
							)}
						</Gap>
					))}
			</>
		)}
	</DataFetcher>
);

export default connect(
	(state: AppState) => ({
		acceptanceVendors: getPortJobAcceptanceVendors(state),
		acceptanceVendorsFetchStatus: getPortJobAcceptanceDataFetchStatus(state)
	}),
	{
		retrieveAcceptanceData: retrieveAcceptanceDataAction
	}
)(AcceptanceVendors);
