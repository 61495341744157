import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { ErrorRedirect } from 'utils/components';
import { makeGetHasPermissionTable } from 'store/auth/selectors/permissionsSelector';
import { AppState } from 'store-types';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getIsCurrentUserPrincipal } from 'store/auth/selectors';
import Messages from './Messages';
import { PATHS } from 'sections/App/RouteParams';
import { getPortCallTabsConfig } from '../Layout/portCallLayoutSelectors';
import { PortCallTabKey } from 'store/portcalls/portCallsTypes';
import ThreadContext from 'sections/Thread/ThreadContext';

function MessagesRoute(props: RouteComponentProps) {
	const getHasPermissionTable = useMemo(() => makeGetHasPermissionTable(), []);
	const isCurrentUserPrincipal = useSelector(getIsCurrentUserPrincipal);
	const isReadOnly = useSelector(
		(state: AppState) =>
			getPortCallTabsConfig(state).readOnly[PortCallTabKey.MESSAGES]
	);
	const hasPermission = useSelector((state: AppState) => {
		const codes: PermissionCode[] = [PermissionCode.VIEW_PORTCALLS];
		codes.push(
			isCurrentUserPrincipal
				? PermissionCode.VIEW_PORTCALL_MESSAGES
				: PermissionCode.VIEW_MESSAGES
		);
		return getHasPermissionTable(state, codes).every(code => code);
	});
	if (hasPermission) {
		return (
			<ThreadContext.Provider value={{ isReadOnly }}>
				<Messages {...props} isReadOnly={isReadOnly} />
			</ThreadContext.Provider>
		);
	}
	return <ErrorRedirect targetUrl={`/${PATHS.portCalls}`} />;
}

export default MessagesRoute;
