import {
	AddPrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../configurationServicesActionCreator';

const ACTION_NAME = 'ADD_PRINCIPAL_SERVICE';

export const addPrincipalServiceAsync = actionCreator.async<
	AddPrincipalServiceRequest,
	PrincipalService,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Service'
	}
});

export const addPrincipalService = actionCreator<AddPrincipalServiceRequest>(
	ACTION_NAME
);
