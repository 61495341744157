import { SessionState, LocalStorageState } from './storageTypes';

/**
 * Note:
 * If used more than in one place, should be reworked with generic approach inside `store`
 */

const createStorage = <State>(storage: Storage) => ({
	set<K extends keyof State>(key: K, value: State[K]) {
		storage.setItem(key, JSON.stringify(value));
	},
	get<K extends keyof State>(key: K): State[K] | null {
		const item = storage.getItem(key);
		return item ? JSON.parse(item) : null;
	},
	clear<K extends keyof State>(key: K) {
		storage.removeItem(key);
	}
});

export const sessionStorageService = createStorage<SessionState>(
	sessionStorage
);
export const localStorageService = createStorage<LocalStorageState>(
	localStorage
);
