import { FormErrors } from 'redux-form';
import { GradeLineFormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';

import { first } from 'lodash';
import runValidations, { required, minValue } from 'utils/validations';

export default function validate(
	values: GradeLineFormData
): FormErrors<GradeLineFormData> {
	return {
		grade: first(runValidations(values.grade, [required])),
		quantity: first(runValidations(values.quantity, [minValue(0), required])),
		unitOfMeasurement: first(
			runValidations(values.unitOfMeasurement, [required])
		)
	};
}
