import React from 'react';
import { SummaryJobMetric } from 'services/api/dashboard/dashboardServiceTypes';
import MetricValueColumn from './MetricValueColumn/MetricValueColumn';
import JobStatusColumn from './JobStatusColumn/JobStatusColumn';

const getJobsSummaryColumnConfig = (
	tableName: string,
	isJobsSection: boolean
) => {
	return [
		{
			title: tableName,
			key: 'name',
			render: (record: SummaryJobMetric) => {
				return (
					<JobStatusColumn
						name={record.name}
						code={record.code}
						severity={record.severity}
						financeStatus={record.financeStatus}
						portJobStatus={record.portJobStatus}
						queriedToHub={record.queriedToHub}
						queriedToLPA={record.queriedToLPA}
					/>
				);
			}
		},
		{
			title: isJobsSection ? '# (Overdue)' : '(Overdue)',
			key: 'overdue',
			width: 100,
			render: (record: SummaryJobMetric) => {
				return (
					<MetricValueColumn
						count={record.totalAlertsCount}
						overdue={record.overdueAlertsCount}
					/>
				);
			}
		}
	];
};

export default getJobsSummaryColumnConfig;
