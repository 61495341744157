import { Success } from 'typescript-fsa';
import { omit, without } from 'lodash';
import { ConfigurationFilesState } from '../configurationFilesState';
import { DeleteCompanyTemplateFileRequest } from 'services/api/templates/templatesServiceTypes';

export const onDeleteCompanyTemplateFileSuccess = (
	state: ConfigurationFilesState,
	{ params: { fileId } }: Success<DeleteCompanyTemplateFileRequest, null>
): ConfigurationFilesState => {
	return {
		...state,
		byId: omit(state.byId, fileId),
		allIds: without(state.allIds, fileId)
	};
};
