import * as React from 'react';
import { ButtonRow, PageFooter } from 'components';
import { Justify } from 'components/types';
import { Row, Col, Button } from 'components/antd';

interface FooterProps {
	valid: boolean;
	onOkClick: () => void;
	onCancelClick: () => void;
	okButtonText?: string;
}

export default ({
	valid,
	onOkClick,
	onCancelClick,
	okButtonText
}: FooterProps) => (
	<PageFooter>
		<Row>
			<Col sm={12} justify={Justify.END}>
				<ButtonRow>
					<Button type="primary" transparent onClick={onCancelClick}>
						Cancel
					</Button>
					<Button type="primary" onClick={onOkClick} disabled={!valid}>
						{okButtonText || 'Add Operation'}
					</Button>
				</ButtonRow>
			</Col>
		</Row>
	</PageFooter>
);
