import * as React from 'react';
import { isEqual, debounce, find } from 'lodash';
import { Select } from 'components/antd';
import {
	GroupUser,
	GroupUserSearch
} from 'services/api/groups/groupsServiceTypes';
import Api from 'services/api';
import { DEFAULT_DEBOUNCE_DELAY } from 'app-constants';

interface GroupUserSelectProps {
	groupId: string;
	groupUsers: GroupUser[];
	onSelectUser: (userId: string) => void;
	disabled?: boolean;
}

interface GroupUserSelectState {
	options: GroupUserSearch[];
}

export class GroupUserSelect extends React.Component<
	GroupUserSelectProps,
	GroupUserSelectState
> {
	static defaultProps = {
		disabled: false
	};
	constructor(props: GroupUserSelectProps) {
		super(props);

		this.state = {
			options: []
		};
	}

	onSelect = (userId: string) => {
		if (userId) {
			this.props.onSelectUser(userId);
		}
	};

	updateOptions = (options: GroupUserSearch[] = []) => {
		if (!isEqual(this.state.options, options)) {
			this.setState({
				options
			});
		}
	};

	getMembers = debounce((value: string) => {
		const searchTerm = value || '';
		if (searchTerm.length < 2) {
			this.updateOptions();
		} else {
			Api.Groups.searchGroupUsers(this.props.groupId, {
				searchTerm
			})
				.then(response => {
					const options = response.data.elements.filter(
						group => !find(this.props.groupUsers, { id: group.id })
					);

					this.updateOptions(options);
				})
				.catch(() => {
					this.updateOptions();
				});
		}
	}, DEFAULT_DEBOUNCE_DELAY);

	render() {
		return (
			<Select
				value={undefined}
				onSelect={this.onSelect}
				onSearch={this.getMembers}
				disabled={this.props.disabled}
				showSearch
				filterOption={false}
				placeholder="Type to add new member"
				notFoundContent="Type to search"
				defaultActiveFirstOption={false}
			>
				{this.state.options.map(option => {
					return <Select.Option key={option.id}>{option.name}</Select.Option>;
				})}
			</Select>
		);
	}
}

export default GroupUserSelect;
