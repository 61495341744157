import {
	DraftType,
	DraftTypeToOrganisationTypeMap,
	CompanyDraft,
	NewCompanyDraft,
	DraftKind
} from './draftsState';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { first, flatten, findKey } from 'lodash';

const transformIntoTuple = (type: DraftType): [OrganisationType] =>
	flatten([DraftTypeToOrganisationTypeMap[type]]) as [OrganisationType];
export const getOrganisationTypeFromDraftType = (draftType: DraftType) =>
	first(transformIntoTuple(draftType));

interface DraftTypeToOrganisationTypes {
	[key: string]: OrganisationType[];
}
const transformElementsIntoTuple = (): DraftTypeToOrganisationTypes =>
	Object.entries(DraftTypeToOrganisationTypeMap).reduce(
		(acc, [key, value]) => ({ ...acc, [key]: flatten([value]) }),
		{}
	);
const findDraftType = (
	organisationType: OrganisationType,
	types: DraftTypeToOrganisationTypes
) =>
	findKey<DraftTypeToOrganisationTypes>(types, (value: OrganisationType[]) =>
		value.some(el => el === organisationType)
	);
export const getDraftTypeFromOrganisationType = (
	organisationType: OrganisationType
) => findDraftType(organisationType, transformElementsIntoTuple());

export const isNewCompanyDraft = (
	companyDraft: CompanyDraft
): companyDraft is NewCompanyDraft => companyDraft.kind === DraftKind.NEW;
