import actionCreator from '../financeActionCreator';

const RETRIEVE_FINANCE_DETAILS_CYCLE_START =
	'RETRIEVE_FINANCE_DETAILS_CYCLE_START';

const RETRIEVE_FINANCE_DETAILS_CYCLE_STOP =
	'RETRIEVE_FINANCE_DETAILS_CYCLE_STOP';

export const retrieveFinanceDetailsCycleStart = actionCreator(
	RETRIEVE_FINANCE_DETAILS_CYCLE_START
);

export const retrieveFinanceDetailsCycleStop = actionCreator(
	RETRIEVE_FINANCE_DETAILS_CYCLE_STOP
);
