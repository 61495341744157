import actionCreator from '../actionCreator';

const ACTION_NAME = 'TRACK_VP';
export const trackVP = actionCreator<string>(ACTION_NAME);
export const trackVPAsync = actionCreator.async<string, null, Error>(
	ACTION_NAME
);
export const startTrackVP = actionCreator('START_TRACK_VP_FLOW');
export const startTrackVPFromFinance = actionCreator(
	'START_TRACK_VP_FROM_FINANCE'
);
