import sanitizeHTML from 'sanitize-html';
import { isUndefined } from 'lodash';
import { sanitizeOptions } from 'components/HtmlSanitizer/constants';
import rules from './rules';

const normalize = (html?: string) => {
	if (!html) {
		return '';
	}

	let result = html;

	rules.forEach(val => {
		val.rules.forEach(({ rule, replaceValue }) => {
			if (isUndefined(replaceValue)) {
				return;
			}

			// eslint-disable-next-line
			result = result.replace(rule, replaceValue as any);
		});
	});

	return result;
};

export const normalizeHtml = (html?: string): string => {
	if (!html) {
		return '';
	}

	html = normalize(html);
	return sanitizeHTML(html, sanitizeOptions);
};
