import { JobFinanceExpandParam } from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { all, put, takeMaybe, takeLatest, select } from 'redux-saga/effects';
import { get, isArray, intersection } from 'lodash';
import uuidv4 from 'uuid/v4';

import {
	addDAAsync,
	updateDAAsync,
	deleteDAAsync,
	addDAOutAsync,
	deleteDAOutAsync,
	validateJobFinanceAsync,
	updateServiceAsync,
	retrieveFinance,
	retrieveFinanceAsync,
	retrieveFinanceDetails,
	updateServiceStatusAsync,
	updateBenchmarkAsync,
	splitServiceAsync,
	reverseSplitServiceAsync,
	convertServiceAsync,
	deleteServiceAsync,
	addServiceAsync,
	approveAllServicesAsync,
	moveToStageAsync,
	convertToDirectBillAsync,
	convertToRegularAsync,
	cancelServiceTransactionAsync,
	cancelCreditHubRevenueAsync,
	deleteFinanceDocumentAsync,
	updateFinanceStatusAsync,
	addCreditHubRevenueServiceAsync,
	submitUnitCostFormAsync,
	updateUnitCostFormAsync,
	deleteUnitCostFormAsync
} from '../actions';
import { retrievePortCall } from '../../portcalls/actions';
import {
	getActivePortCall,
	getActivePortCallId
} from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { notify, confirmNotification } from 'store/notifications/actions';
import { DEFAULT_IGNORED_CODES } from 'app-constants';
import { updateFundingAsync } from 'store/financeFundings/actions';
import { setAuthActingAs } from 'store/auth/actions';
import { getFinance } from '../selectors';

const isHandledCode = (status: number | number[]): boolean =>
	isArray(status)
		? !!intersection(status, DEFAULT_IGNORED_CODES).length
		: DEFAULT_IGNORED_CODES.includes(status);

export function* reactToChangesForFinancesWorker({
	payload,
	type: actionType
}: Action<{ error: Error }>): SagaIterator {
	const status = get(payload, 'error.response.status') || 0;
	const defaultMessage = `The operation can't be completed due to data inconsistency. Probably someone has updated the page. Click Refresh to update the content of the page.`;
	const validationMessageData = get(payload, 'error.response.data');
	let validationMessage =
		validationMessageData?.ValidationGroup ||
		validationMessageData?.ValidationError;
	if (actionType === updateServiceStatusAsync.failed.type) {
		validationMessage = defaultMessage;
	}

	if (isHandledCode(status)) {
		const portCall: PortCall = yield select(getActivePortCall);

		if (portCall?.id) {
			const notificationId = uuidv4();
			yield put(
				notify.warning({
					uuid: notificationId,
					description: validationMessage || defaultMessage,
					duration: 0,
					...(!validationMessage && {
						confirmation: {
							title: 'Refresh'
						}
					})
				})
			);
			yield takeMaybe(
				({ type, payload: id }: Action<string>) =>
					type === confirmNotification.type && id === notificationId
			);
			const portJobCode: string = yield select(getActivePortJobCode);
			yield put(
				retrieveFinance({
					portCallId: portCall.id,
					jobCode: portJobCode,
					expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
				})
			);
		}
	}
}

export function* refetchPortCallWorker({
	payload
}: Action<{ error: Error }>): SagaIterator {
	const status = get(payload, 'error.response.status') || 0;
	if (isHandledCode(status)) {
		const portCall: PortCall = yield select(getActivePortCall);
		if (portCall?.id) {
			yield put(
				retrievePortCall({
					id: portCall.id,
					expand: 'PortJobsBasic'
				})
			);
		}
	}
}

export function* silentRetrieveFinancePageWorker(): SagaIterator {
	const jobCode = yield select(getActivePortJobCode);
	const portCallId = yield select(getActivePortCallId);
	yield put(
		retrieveFinance({
			portCallId,
			jobCode,
			isSilent: true,
			expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
		})
	);
}

function* refetchFinanceDetailsWorker() {
	const finance = yield select(getFinance);
	const portCall = yield select(getActivePortCall);
	// refetch finance details only if finance and portCall are initialized
	if (!finance || !portCall) {
		return;
	}
	yield put(
		retrieveFinanceDetails({
			portCallId: portCall.id
		})
	);
}

export default function* reactToChangesForOperationsWatcher(): SagaIterator {
	yield all([
		takeLatest(
			[
				addDAAsync.done,
				updateDAAsync.done,
				deleteDAAsync.done,
				addDAOutAsync.done,
				deleteDAOutAsync.done,
				addServiceAsync.done,
				splitServiceAsync.done,
				reverseSplitServiceAsync.done,
				convertServiceAsync.done,
				convertToDirectBillAsync.done,
				convertToRegularAsync.done,
				updateServiceAsync.done,
				cancelServiceTransactionAsync.done,
				addCreditHubRevenueServiceAsync.done,
				cancelCreditHubRevenueAsync.done,
				updateServiceStatusAsync.done,
				deleteServiceAsync.done,
				approveAllServicesAsync.done,
				deleteFinanceDocumentAsync.done,
				updateFinanceStatusAsync.done,
				updateFundingAsync.done,
				updateBenchmarkAsync.done,
				submitUnitCostFormAsync.done,
				updateUnitCostFormAsync.done,
				deleteUnitCostFormAsync.done
			],
			silentRetrieveFinancePageWorker
		),
		takeLatest(
			[
				addDAAsync.failed,
				updateDAAsync.failed,
				deleteDAAsync.failed,
				validateJobFinanceAsync.failed,
				moveToStageAsync.failed,
				updateServiceAsync.failed,
				updateServiceStatusAsync.failed,
				addCreditHubRevenueServiceAsync.failed
			],
			reactToChangesForFinancesWorker
		),
		takeLatest(retrieveFinanceAsync.failed, refetchPortCallWorker),
		takeLatest(setAuthActingAs, refetchFinanceDetailsWorker)
	]);
}
