import React from 'react';
import { connect } from 'react-redux';
import { DocumentViewerWizardForm } from 'sections/DocumentViewer';
import ContextualizationForm from './ContextualizationForm/ContextualizationForm';
import { PaginationState } from 'sections/DocumentViewer/DocumentViewerTypes';
import { createPortCallDocument } from 'store/portCallDocuments/actions';
import {
	getIsDocumentCreateLoading,
	getIsAtLeastOnePageContextualized,
	hasPortCallDocumentsMetadataAction
} from 'store/portCallDocuments/selectors';
import { PortCallDocumentsMetadataActionCode } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { AppState } from 'store-types';

interface ContextualizationProps {
	portCallId: string;
	documentId: string;
	// from mapStateToProps
	loading: boolean;
	isAtLeastOnePageContextualized: boolean;
	hasUploadOperationDocumentsMetadata: boolean;
	// from mapDispatchToProps
	createPortCallDocument: typeof createPortCallDocument;
}

class Contextualization extends React.Component<ContextualizationProps> {
	onSubmit = () => {
		this.props.createPortCallDocument({
			data: {
				documentId: this.props.documentId,
				contextualizations: [] // This is filled in worker
			}
		});
	};

	render() {
		const {
			documentId,
			portCallId,
			loading,
			isAtLeastOnePageContextualized,
			hasUploadOperationDocumentsMetadata
		} = this.props;
		return (
			<DocumentViewerWizardForm
				documentId={documentId}
				title="Upload New Document"
				returnUrl={`/portcalls/${portCallId}/operations/documents`}
				loading={loading}
				pagination={PaginationState.DEFAULT}
				onSubmit={this.onSubmit}
				canSubmit={
					isAtLeastOnePageContextualized && hasUploadOperationDocumentsMetadata
				}
			>
				{props => (
					<ContextualizationForm
						pages={props.total}
						activePage={props.activePage}
						hasPagination={props.total > 1}
						onValidChange={props.onValidChange}
					/>
				)}
			</DocumentViewerWizardForm>
		);
	}
}

export default connect(
	(
		state: AppState,
		{ portCallId }: Pick<ContextualizationProps, 'portCallId' | 'documentId'>
	) => ({
		loading: getIsDocumentCreateLoading(state),
		isAtLeastOnePageContextualized: getIsAtLeastOnePageContextualized(state),
		hasUploadOperationDocumentsMetadata: hasPortCallDocumentsMetadataAction(
			state,
			portCallId,
			PortCallDocumentsMetadataActionCode.UPLOAD_OPERATION_DOCUMENTS
		)
	}),
	{
		createPortCallDocument
	}
)(Contextualization);
