import Api from 'services/api';
import { retrievePortCallsStaticAsync as async } from '../actions';
import { RetrievePortCallsStaticResponse as response } from '../portCallsState';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.PortCalls.retrievePortCallsStatic;

export const retrievePortCallsStaticWatcher = makeTakeLatestWatcher<
	undefined,
	response,
	Error
>({
	api,
	async
});
