import { createContext } from 'react';

export interface PermissionContextValue {
	companyId: string | null;
}

const PermissionContext = createContext<PermissionContextValue>({
	companyId: null
});

export default PermissionContext;
