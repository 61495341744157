import {
	UpdateConfigurationFeedbackManagementRequest,
	ConfigurationFeedbackManagementData
} from 'services/api/companies/companiesServiceTypes';
import {
	getActiveCompanyId,
	getFeedbackManagementEnabled
} from 'store/configuration/selectors';
import { put, select } from 'redux-saga/effects';
import {
	retrieveConfigurationFeedbackManagement,
	updateConfigurationFeedbackManagementAsync
} from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

export default makeTakeLatestWatcher<
	null,
	ConfigurationFeedbackManagementData,
	Error,
	UpdateConfigurationFeedbackManagementRequest
>({
	api: Api.Companies.updateFeedbackManagementConfiguration,
	async: updateConfigurationFeedbackManagementAsync,
	*getApiParams() {
		const companyId: string = yield select(getActiveCompanyId);
		const isFeedbackManagementEnabled = yield select(
			getFeedbackManagementEnabled
		);
		return {
			companyId,
			data: {
				isFeedbackManagementEnabled: !isFeedbackManagementEnabled
			}
		};
	},
	*onSuccess() {
		yield put(retrieveConfigurationFeedbackManagement);
	}
});
