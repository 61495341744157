import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';

interface RouteLayoutProps extends RouteProps {
	layout: React.ComponentType<RouteComponentProps<any>>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const RouteLayout: React.SFC<RouteLayoutProps> = ({
	component,
	layout,
	...restProps
}) => {
	const Layout = layout;
	const Component = component;
	return (
		<Route
			{...restProps}
			render={routeProps => {
				return (
					<Layout {...routeProps}>
						{Component && <Component {...routeProps} />}
					</Layout>
				);
			}}
		/>
	);
};

export default RouteLayout;
