import { createSelector } from 'reselect';
import { AppState } from 'store-types';
import { RequiredDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const getRequiredDocumentsMap = (state: AppState) =>
	state.portCallDocuments.requiredById;

export const getRequiredDocuments = createSelector(
	getRequiredDocumentsMap,
	documents =>
		Object.values(documents).map(doc => ({
			...doc,
			placeholders: doc.placeholders.sort((a, b) =>
				a.name.localeCompare(b.name)
			)
		}))
);

export const getRequiredDocumentById = (
	state: AppState,
	id: string
): RequiredDocument | undefined => getRequiredDocumentsMap(state)[id];

export const getPortCallPlaceholderConcurrencyToken = createSelector(
	getRequiredDocumentById,
	(_state: AppState, _documentId: string, portCallPlaceholderId: string) =>
		portCallPlaceholderId,
	(document, portCallPlaceholderId) => {
		if (!document) {
			return;
		}
		const portCallPlaceholder = document.placeholders.find(
			placeholder => placeholder.id === portCallPlaceholderId
		);
		return portCallPlaceholder?.concurrencyToken;
	}
);
