import React, { useCallback } from 'react';
import { SettingsButton } from 'components';
import { onClickEvent } from 'app-types';
import { NO_PERMISSION_MESSAGE } from './contants';
import OperationSettingsMenu from './OperationSettingsMenu';
import { PortJobOperation } from 'services/api/portJobs/portJobsServiceTypes';

export interface OperationSettingsButtonProps {
	operation: PortJobOperation;
	portCallId: string;
	portJobCode: string;
	disabled?: boolean;
}

const OperationSettingsButton = function(props: OperationSettingsButtonProps) {
	const { operation, portCallId, portJobCode } = props;
	const onClick = useCallback((event: onClickEvent) => {
		event.stopPropagation();
	}, []);

	return (
		<SettingsButton
			content={
				<OperationSettingsMenu
					operation={operation}
					portCallId={portCallId}
					portJobCode={portJobCode}
				/>
			}
			handleClick={onClick}
			disabled={props.disabled}
			disabledMessage={NO_PERMISSION_MESSAGE}
		/>
	);
};

export default React.memo(OperationSettingsButton);
