import { preRegisterVerifyAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse
} from 'services/api/registration/registrationServiceTypes';

export default makeTakeLatestWatcher<
	PreRegisterVerifyRequest,
	PreRegisterVerifyResponse,
	Error
>({
	api: Api.Registration.preRegisterVerify,
	async
});
