import actionCreator from '../configurationActionCreator';
import { CreateFeedbackManagementRequest } from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'UPDATE_FEEDBACK_MANAGEMENT';
export const updateFeedbackManagementRequest = actionCreator(ACTION_NAME);

export const updateFeedbackManagementRequestAsync = actionCreator.async<
	CreateFeedbackManagementRequest,
	null,
	Error
>(ACTION_NAME);
