import actionCreator from '../financeActionCreator';
import { DeleteFinanceDocumentPageRequest } from 'services/api/finance/financeServiceTypes';

export type DeleteFinanceDocumentPageActionParams = Pick<
	DeleteFinanceDocumentPageRequest,
	'documentId' | 'actualNumber' | 'pageType'
>;

export const DELETE_DOCUMENT_PAGE = 'DELETE_DOCUMENT_PAGE';
export const deleteFinanceDocumentPageAsync = actionCreator.async<
	DeleteFinanceDocumentPageRequest,
	null,
	Error
>(DELETE_DOCUMENT_PAGE);

export const deleteFinanceDocumentPage = actionCreator<
	DeleteFinanceDocumentPageActionParams
>(DELETE_DOCUMENT_PAGE);
