import * as React from 'react';
import AutocompleteCompany, {
	AutocompleteCompanyProps
} from './AutocompleteCompany';
import { ComponentOrganisationTypes } from '../autocompleteEntityConstants';

export default (props: AutocompleteCompanyProps) => (
	<AutocompleteCompany
		{...props}
		params={{
			organisationTypes: ComponentOrganisationTypes.PRINCIPAL
		}}
	/>
);
