import { MAX_NUMERALS_IN_NUMBER } from './../../sections/PortCall/Finance/FinanceConstants';
import numeral from 'numeral';
import { isString } from 'lodash';

export const getCustomFormattedValue = (
	value: string | number,
	decimalPart: number
) => {
	if (value === '') {
		return value;
	}
	let formattedValue = String(
		numeral(value).format(`0[.][${'0'.repeat(decimalPart)}]`)
	);
	if (isString(value) && value.length > MAX_NUMERALS_IN_NUMBER) {
		formattedValue = value;
	}
	return formattedValue === 'NaN'
		? Number(value).toFixed(decimalPart)
		: formattedValue;
};
