import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { MovetoPDAPendingRequest } from 'services/api/portJobs/portJobsServiceTypes';
import { moveJobToPreviousStatusAsync as async } from 'store/portJobs/actions';
import {
	getPortJobConcurrencyTokenByCode,
	getActivePortJobCode
} from '../portJobsSelectors';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import Api from 'services/api';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { retrievePortCall } from 'store/portcalls/actions';
import { PortJobSettingsMenuAction } from 'sections/PortCall/components/settings/contants';

const moveToCEApi = Api.PortJobs.movetoCEPending;

const apiCall = Api.PortJobs.movetoPDAPending;

const reopenApi = Api.PortJobs.reopenJob;

export const executor = function*(
	payload: MovetoPDAPendingRequest
): SagaIterator {
	const portCallId = yield select(getActivePortCallId);
	const portJobCode = yield select(getActivePortJobCode);
	const concurrencyToken = yield select(
		getPortJobConcurrencyTokenByCode,
		portJobCode
	);
	const request = {
		...payload,
		portcallId: portCallId,
		jobCode: portJobCode,
		concurrencyToken
	};
	yield put(async.started(request));
	try {
		let response;
		if (
			payload.moveBackToJobStatus ===
			PortJobSettingsMenuAction.MOVE_TO_PDA_PENDING
		) {
			response = yield call(apiCall, request);
		} else if (
			payload.moveBackToJobStatus === PortJobSettingsMenuAction.RE_OPEN_JOB
		) {
			response = yield call(reopenApi, request);
		} else {
			response = yield call(moveToCEApi, request);
		}
		yield put(
			async.done({
				result: undefined,
				params: request,
				response
			})
		);
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: request
			})
		);
	}
};

export const moveJobToPreviousStatusWatcher = makeTakeLatestWatcher<
	MovetoPDAPendingRequest,
	void,
	Error
>(
	{
		api: apiCall,
		async
	},
	executor
);

export default moveJobToPreviousStatusWatcher;
