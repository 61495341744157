import { AddSupplierInvoiceRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../supplierInvoiceActionCreator';

const ACTION_NAME = 'CREATE_SUPPLIER_INVOICE';
export const createSupplierInvoiceAsync = actionCreator.async<
	AddSupplierInvoiceRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Supplier Invoice'
	}
});

export const createSupplierInvoice = actionCreator<{
	documentId: string;
	originalSupplierInvoiceId?: string;
}>(ACTION_NAME);
