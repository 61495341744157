import {
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'RETRIEVE_COMPANY_JOB_TYPES';

export const retrieveCompanyJobTypesAsync = actionCreator.async<
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse,
	Error
>(ACTION_NAME);

export const retrieveCompanyJobTypes = actionCreator<
	RetrieveCompanyJobTypesRequest
>(ACTION_NAME);
