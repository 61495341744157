import { AxiosTypedPromise } from 'services/api/apiTypes';
import client from 'services/client';

import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';

import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import { searchEntitiesForAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';

// Incoming imports
import queryString from 'query-string';
import {
	RetrieveDirectBillsResponse,
	SearchPrincipalsRequest,
	DirectBillCompanyType,
	RetrieveDirectBillsRequest,
	DirectBillSearchRequest,
	DirectBillSearchResultCollection,
	DirectBillGroupType,
	DownloadDirectBillsDocumentRequest,
	RetrieveMainPrincipalsResponse,
	SearchPrincipalRequest,
	UpdateDirectBillResponse,
	UpdateDirectBillStatusResponse,
	UpdateDirectBillRequest,
	UpdateDirectBillStatusRequest,
	RetrieveDirectBillRequest,
	RetrieveDirectBillResponse
} from './directBillsServiceTypes';
import { SearchResultCollection } from '../apiTypes';
import { EntityCode } from 'app-types';

const SERVICE_ACTION_TYPE = 'ManageDirectBills';

const searchServices = (searchTerm: string) =>
	client.get<SearchResultCollection<string, EntityCode>>(
		`/masterdata/services/search`,
		{
			params: {
				limit: DEFAULT_LIST_LIMIT,
				searchTerm
			}
		}
	);

const searchPrincipals = (searchParams: SearchPrincipalRequest) =>
	client.get<{ results: AutocompleteOption[] }>(`/companies/search`, {
		params: {
			Types: DirectBillCompanyType.PRINCIPAL,
			WithParentId: searchParams.mainPrincipalId,
			limit: DEFAULT_LIST_LIMIT,
			searchTerm: searchParams.searchTerm
		}
	});

const retrieveMainPrincipals = () => {
	return client
		.get<RetrieveMainPrincipalsResponse>(`/companies/mainprincipals`, {
			params: {
				actionType: SERVICE_ACTION_TYPE
			}
		})
		.then(mainPrincipals =>
			mainPrincipals.data.elements.map(({ companyName, code }) => {
				return {
					label: companyName,
					name: code
				};
			})
		);
};

const retrieveDirectBills = (params: RetrieveDirectBillsRequest) => {
	const { status, search = [] } = params.filters;
	const statusKey = status?.[0]?.key;
	const searchParams = search.reduce((acc, item) => {
		if (!acc[item.type]) {
			acc[item.type] = [item.key];
			return acc;
		}
		acc[item.type].push(item.key);
		return acc;
	}, {});
	const isActive = statusKey === 'all' ? undefined : statusKey === 'active';
	return client.get<RetrieveDirectBillsResponse>(`/masterdata/direct-bills`, {
		params: {
			index: params.count,
			limit: params.limit,
			isActive,
			...searchParams
		},
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'none' })
	});
};

const retrieveSingleDirectBill = ({
	directBillId
}: RetrieveDirectBillRequest) =>
	client.get<RetrieveDirectBillResponse>(
		`masterdata/direct-bills/${directBillId}`
	);

const searchDirectBills = (
	searchTerm: string,
	showMoreInGroup: DirectBillGroupType | null
) => {
	const request: DirectBillSearchRequest = {
		searchTerm,
		showMore: false
	};
	if (showMoreInGroup !== null) {
		request.showMore = true;
		request.groupType = showMoreInGroup;
	}
	return client.get<{
		groups: DirectBillSearchResultCollection[];
	}>(`/masterdata/direct-bills/search`, {
		params: request
	});
};

const searchDirectBillsForAutocomplete = (
	searchTerm: string,
	showMoreInGroup: DirectBillGroupType | null
) =>
	searchEntitiesForAutocompleteSearch<DirectBillGroupType | null>(
		searchDirectBills,
		searchTerm,
		showMoreInGroup
	);

/**
 * POST to get the downloadUrl of the Document if exist
 */
const requestPostDirectBillsDocumentUrl = ({
	directBillId,
	...data
}: DownloadDirectBillsDocumentRequest): AxiosTypedPromise<RetrieveDownloadDocumentResponse> =>
	client.post(`/masterdata/direct-bills/${directBillId}/downloadAll`, data);

/**
 * GET to poll until downloadUrl of the Document arrives
 */
const requestGetDirectBillsDocumentUrl = ({
	directBillId,
	...data
}: DownloadDirectBillsDocumentRequest) =>
	client.get<RetrieveDownloadDocumentResponse>(
		`/masterdata/direct-bills/${directBillId}/downloadAll`,
		{
			params: data,
			paramsSerializer: (requestParams: DownloadDirectBillsDocumentRequest) =>
				queryString.stringify(requestParams, { arrayFormat: 'none' })
		}
	);

const searchServicesForAutocomplete = (searchTerm: string) =>
	searchServices(searchTerm)
		.then(response => {
			return response.data.results;
		})
		.then(companies => {
			return companies.map(({ name, code }) => {
				return {
					name,
					id: code
				};
			});
		});

const searchMainPrincipalsForAutocomplete = () =>
	client
		.get<RetrieveMainPrincipalsResponse>(`/companies/mainprincipals`, {
			params: { actionType: SERVICE_ACTION_TYPE }
		})
		.then(response => {
			return response.data.elements.map(({ companyName, id }) => {
				return {
					name: companyName,
					id
				};
			});
		});

const searchPrincipalsForAutocomplete = (
	searchProps: SearchPrincipalsRequest
) =>
	searchPrincipals(searchProps).then((response): AutocompleteOption[] => {
		return response.data.results;
	});

const createDirectBill = (directBill: UpdateDirectBillRequest) =>
	client.post<UpdateDirectBillResponse>('/masterdata/direct-bills', directBill);

const updateDirectBill = (directBill: UpdateDirectBillRequest, id: string) =>
	client.patch<UpdateDirectBillResponse>(
		`/masterdata/direct-bills/${id}`,
		directBill
	);

const updateDirectBillStatus = ({
	id,
	status
}: UpdateDirectBillStatusRequest) =>
	client.put<UpdateDirectBillStatusResponse>(
		`/masterdata/direct-bills/${id}/${status}`
	);

export default {
	retrieveSingleDirectBill,
	retrieveDirectBills,
	retrieveMainPrincipals,
	searchDirectBillsForAutocomplete,
	searchMainPrincipalsForAutocomplete,
	searchPrincipalsForAutocomplete,
	searchServicesForAutocomplete,
	createDirectBill,
	updateDirectBill,
	requestPostDirectBillsDocumentUrl,
	requestGetDirectBillsDocumentUrl,
	updateDirectBillStatus
};
