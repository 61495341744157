import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	retrieveTerminals,
	retrieveTerminalsAsync
} from '../actions/retrieveTerminals';

import { Action } from 'typescript-fsa';

export function* retrieveTerminalsExecutor(
	actionParams: string,
	api: typeof Api.VesselProgramme.retrieveTerminals
): SagaIterator {
	yield put(retrieveTerminalsAsync.started(actionParams));

	try {
		const response = yield call(api, actionParams);

		yield put(
			retrieveTerminalsAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveTerminalsAsync.failed({
				error,
				params: actionParams
			})
		);
	}
}

export function* retrieveTerminalsWorker(action: Action<string>): SagaIterator {
	yield call(
		retrieveTerminalsExecutor,
		action.payload,
		Api.VesselProgramme.retrieveTerminals
	);
}

export function* retrieveTerminalsWatcher(): SagaIterator {
	yield takeLatest(retrieveTerminals.type, retrieveTerminalsWorker);
}

export default function*() {
	yield all([fork(retrieveTerminalsWatcher)]);
}
