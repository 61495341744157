import {
	HandleApiArg,
	ApiMiddlewareHandleType
} from '../../apiMiddlewareTypes';
import { resetAllNotifications, notify } from 'store/notifications/actions';

import { isActionAsyncFailure } from 'store/utils';
import { navigateTo } from 'utils';
import { DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE } from 'app-constants';
import { createApiMiddlewareTypeHandler } from '../../apiMiddlewareUtils';

const handler = createApiMiddlewareTypeHandler(
	ApiMiddlewareHandleType.OTHER,
	otherHandler
);

function otherHandler({ action }: HandleApiArg) {
	if (!isActionAsyncFailure(action) || !action.meta?.failed?.returnUrl) {
		return;
	}
	navigateTo(action.meta.failed.returnUrl);

	return [
		resetAllNotifications(),
		notify.error({
			description: DEFAULT_NO_ACCESS_NOTIFICATION_MESSAGE,
			duration: 0
		})
	];
}

export default handler;
