import { retrievePrincipalIntegrationSettingsAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings
} from 'services/api/companies/companiesServiceTypes';

export default makeTakeLatestWatcher<
	RetrievePrincipalIntegrationSettingsRequest,
	PrincipalIntegrationSettings,
	Error
>({
	api: Api.Companies.retrievePrincipalIntegrationSettings,
	async: retrievePrincipalIntegrationSettingsAsync
});
