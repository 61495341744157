import React, { useCallback, useEffect, useState } from 'react';
import { isPristine, reset } from 'redux-form';
import { Button, Col, Modal, Row } from 'components/antd';
import { ButtonRow, PageFooter } from 'components';
import { AppState } from 'store-types';
import { Justify } from 'components/types';
import styles from '../DeveloperPortal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	DEVELOPER_PORTAL_FORM,
	CANCEL_DEV_PORTAL_EDIT,
	EMD_FORM
} from './constants';
import { closeModal, openModal } from 'store/modals/actions';
import { isModalVisible } from 'store/modals/selectors';
import {
	getDeveloperPortalValue,
	getNewApiSubscriptionFetchStatus,
	getUpdateApiSubscriptionFetchStatus
} from 'store/developerPortal/selectors';
import {
	createNewApiSubscription,
	retrieveDeveloperPortal,
	updateApiSubscription
} from 'store/developerPortal/actions';
import history from 'services/history';
import { navigateTo } from 'utils';
import { FetchStatus } from 'services/api/apiTypes';

const DeveloperPortalFooter = () => {
	const dispatch = useDispatch();

	const [leavingPagePath, setLeavingPagePath] = useState(String);
	const initialValues = useSelector(getDeveloperPortalValue);
	const newAPISubscriptionStatus = useSelector(
		getNewApiSubscriptionFetchStatus
	);

	const isUpdatePending = useSelector(getUpdateApiSubscriptionFetchStatus);

	const cancelModelVisibility = useSelector((state: AppState) =>
		isModalVisible(state, CANCEL_DEV_PORTAL_EDIT)
	);
	const isDisabled = useSelector(isPristine(DEVELOPER_PORTAL_FORM));

	const onConfirmCancelEdit = () => {
		onCloseModal();
		cancel();
		if (leavingPagePath && leavingPagePath !== history.location.pathname) {
			navigateTo(leavingPagePath);
		}
		setLeavingPagePath('');
	};

	const onCloseModal = () => {
		dispatch(closeModal(CANCEL_DEV_PORTAL_EDIT));
		setLeavingPagePath('');
	};

	const cancel = useCallback(() => {
		dispatch(reset(EMD_FORM));
		dispatch(reset(DEVELOPER_PORTAL_FORM));
		dispatch(retrieveDeveloperPortal());
	}, [dispatch]);

	const onCancel = useCallback(() => {
		if (!isDisabled) {
			//for edit only when something was changed in the form
			dispatch(openModal(CANCEL_DEV_PORTAL_EDIT));
		} else {
			cancel();
		}
	}, [isDisabled, dispatch, cancel]);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		let unblock = () => {};
		if (!cancelModelVisibility) {
			unblock = history.block(({ pathname }: typeof history.location):
				| false
				| void => {
				if (pathname !== '/logout') {
					setLeavingPagePath(pathname);
					if (!isDisabled) {
						// We unblock history here in order to have possibility to call navigateTo
						unblock();
						onCancel();
						return false;
					}
				}
			});
		}
		return () => {
			unblock();
		};
	}, [isDisabled, onCancel, cancelModelVisibility]);

	const onSubscribe = () => {
		dispatch(createNewApiSubscription());
	};

	const onUpdate = () => {
		dispatch(updateApiSubscription());
	};

	return (
		<>
			<PageFooter borderTop>
				<Row>
					<Col xs={12} justify={Justify.END}>
						<ButtonRow>
							<Button
								transparent
								className={styles.cancelButton}
								disabled={isDisabled}
								onClick={onCancel}
							>
								Cancel
							</Button>
							{initialValues?.userToken ? (
								<Button
									type="primary"
									loading={isUpdatePending === FetchStatus.PENDING}
									disabled={isDisabled}
									onClick={onUpdate}
								>
									Update
								</Button>
							) : (
								<Button
									type="primary"
									disabled={isDisabled}
									loading={newAPISubscriptionStatus === FetchStatus.PENDING}
									onClick={onSubscribe}
								>
									Subscribe
								</Button>
							)}
						</ButtonRow>
					</Col>
				</Row>
			</PageFooter>

			<Modal
				visible={cancelModelVisibility}
				cancelText={'Cancel'}
				okText={'Confirm'}
				onOk={onConfirmCancelEdit}
				onCancel={onCloseModal}
				closable={false}
			>
				Are you sure you want to cancel the process?
			</Modal>
		</>
	);
};

export default DeveloperPortalFooter;
