import {
	MergePortCallsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';
import actionCreator from '../portCallsActionCreator';

const ACTION_NAME = 'MERGE_PORTCALLS';

export const mergePortCallsAsync = actionCreator.async<
	MergePortCallsRequest,
	MergePortCallsResponse,
	Error
>(ACTION_NAME);

export const mergePortCalls = actionCreator<MergePortCallsRequest>(ACTION_NAME);
