import React from 'react';
import { connect } from 'react-redux';
import { isEqual, values, flatten, zipObject, difference } from 'lodash';
import { change } from 'redux-form';
import { getContextualizationFormFieldValues } from 'store/form/operationsContextualization/selectors';
import {
	getPlaceholdersDropdownOptions,
	getPlaceholderOptions,
	getCopyPageButtonIsVisible,
	getContextualizationAssignedJobsDropdownOptionsFlagged,
	hasPortCallDocumentsMetadataAction
} from 'store/portCallDocuments/selectors';
import {
	FormField,
	FormPageData,
	ContextualizationFormFieldsBaseProps
} from '../ContextualizationFormTypes';
import {
	getJobsForSelectedPlaceholders,
	getIncludesSelectedPlaceholders
} from '../ContextualizationFormUtils';

import {
	AssignedToField,
	AssignedJobsField,
	IgnorePageField,
	DocumentTypeField,
	CopyPageButton
} from '../fields';
import { AssignedJobOption } from '../fields/AssignedJobsField/AssignedJobsField';
import { FormChangeCheckbox } from 'app-types';
import { AppState } from 'store-types';
import { PortCallDocumentsMetadataActionCode } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { NO_DOCUMENTS_TYPE_AVAILABLE } from 'store/portCallDocuments/constants';
import { alert, removeNotification } from 'store/notifications/actions';
import { AppNotifications } from 'containers';

export interface FormPageOwnProps {
	portCallId: string;
	form: string;
	field: string;
	hasPagination: boolean;
}

export interface FormPageProps
	extends FormPageOwnProps,
		Pick<
			ContextualizationFormFieldsBaseProps,
			'placeholderOptions' | 'assignedToOptions'
		> {
	// from mapStateToProps
	fieldValues: FormPageData;
	hasPagination: boolean;
	copyPageButtonIsVisible: boolean;
	hasShowDocumentationAlertMetadata: boolean;
	assignedJobOptions: AssignedJobOption[];
	// from mapDispatchToProps
	change: typeof change;
	alertWarning: typeof alert.warning;
	removeNotification: typeof removeNotification;
}

class FormPage extends React.Component<FormPageProps> {
	componentDidUpdate(prevProps: FormPageProps) {
		const { copyPageButtonIsVisible, fieldValues } = this.props;
		const { documentTypeCode, placeholders } = fieldValues;

		this.showAndHideWarningOnJobCodesChange(prevProps);

		const isCopingFromPreviousPage = !(
			prevProps.copyPageButtonIsVisible &&
			copyPageButtonIsVisible !== prevProps.copyPageButtonIsVisible
		);
		if (
			documentTypeCode &&
			documentTypeCode !== prevProps.fieldValues.documentTypeCode &&
			/**
			 * After action COPY_FROM_PREVIOUS_PAGE is fired, all values including
			 * FormField.DOCUMENT_TYPE are updated in `this.onDocumentTypeChange()`, and without this check, if the FormField.DOCUMENT_TYPE
			 * changes then all the placeholder checkboxes will be reset to false and rendered as unchecked.
			 *
			 * This check ensures that the placeholders are reset only when
			 * the `copyPageButton` is still visible or hidden, meaning that if FormField.DOCUMENT_TYPE has changed:
			 * - 1. `copyPageButton` was visible, it will became hidden => no reset placeholders
			 * - 2. `copyPageButton` was hidden, it will remain hidden => reset placeholders
			 * If `copyPageButton` is hidden, it will became visible only when user check and uncheck FormField.IGNORE_PAGE => reset placeholders
			 */
			isCopingFromPreviousPage
		) {
			this.onDocumentTypeChange();
		}

		if (!isEqual(placeholders, prevProps.fieldValues.placeholders)) {
			this.onPlaceholdersChange(prevProps);
		}
	}

	showAndHideWarningOnJobCodesChange = (prevProps: FormPageProps) => {
		const { fieldValues, removeNotification, alertWarning } = this.props;
		const newPropsJobs = fieldValues.jobs || [];
		const prevPropsJobs = prevProps.fieldValues.jobs || [];
		const [removedJob] = difference(newPropsJobs, prevPropsJobs);
		const [addedJob] = difference(prevPropsJobs, newPropsJobs);
		if (removedJob) {
			removeNotification(removedJob);
		}
		if (addedJob) {
			alertWarning({
				uuid: addedJob,
				description: `If you remove job ${addedJob}, Documents assigned to it won’t be shown to the Principal. Consider adding the job back if you are not sure.`
			});
		}
	};
	/**
	 * TODO: Make Selector
	 */
	getAllFieldNamesFromActivePage = () => [
		FormField.DOCUMENT_TYPE,
		FormField.PLACEHOLDERS,
		FormField.JOBS
	];

	/**
	 * Reset all Form Fields EXCEPT ${FormField.IGNORE_PAGE}
	 * ${FormField.DOCUMENT_TYPE} field is handled by <DocumentTypeField> itself
	 */
	resetFormFields = () => {
		const fields = this.getAllFieldNamesFromActivePage();
		fields.forEach(field => this.change(field, null));
	};

	onIgnorePageChange: FormChangeCheckbox = (_event, value: boolean) => {
		if (!value) {
			return;
		}
		this.resetFormFields();
	};

	onCheckboxChange = (id: string, checked: boolean) => {
		const { fieldValues } = this.props;
		const placeholders = fieldValues.placeholders || {};
		const newPlaceholders = { ...placeholders, [id]: checked };
		this.change(FormField.PLACEHOLDERS, newPlaceholders);
	};

	// Initialize fields when document type has changed
	onDocumentTypeChange = () => {
		const { assignedToOptions } = this.props;

		const ids = flatten(values(assignedToOptions)).map(option => option.id);
		this.change(
			FormField.PLACEHOLDERS,
			zipObject(
				ids,
				ids.map(() => false)
			)
		);
		this.change(FormField.JOBS, null);
	};

	onPlaceholdersChange = (prevProps: FormPageProps) => {
		// Manage add/remove of jobs when options are selected/de-selected
		const newJobs = getJobsForSelectedPlaceholders(this.props, prevProps);
		const { jobs } = this.props.fieldValues;

		if (Boolean(newJobs) && !isEqual(newJobs, jobs)) {
			this.change(FormField.JOBS, newJobs);
		}
	};

	change = (
		name: string,
		value: any // eslint-disable-line @typescript-eslint/no-explicit-any
	) => {
		const { form } = this.props;
		const pages = this.props.field;
		this.props.change(form, `${pages}.${name}`, value);
	};

	render() {
		const {
			field,
			assignedToOptions,
			hasPagination,
			copyPageButtonIsVisible,
			assignedJobOptions,
			hasShowDocumentationAlertMetadata
		} = this.props;
		const fieldValues = this.props.fieldValues;

		const isPageIgnored = Boolean(fieldValues[FormField.IGNORE_PAGE]);
		const assignedJobsFieldVisible = getIncludesSelectedPlaceholders(
			this.props
		);
		const tooltip = !hasShowDocumentationAlertMetadata
			? {
					title: NO_DOCUMENTS_TYPE_AVAILABLE
			  }
			: undefined;

		return (
			<div>
				{hasPagination && (
					<IgnorePageField
						name={`${field}.${FormField.IGNORE_PAGE}`}
						onChange={this.onIgnorePageChange}
					/>
				)}

				<DocumentTypeField
					name={`${field}.${FormField.DOCUMENT_TYPE}`}
					disabled={isPageIgnored || !hasShowDocumentationAlertMetadata}
					tooltip={tooltip}
				/>

				{copyPageButtonIsVisible && <CopyPageButton />}

				{!isPageIgnored && (
					<>
						<AssignedToField
							name={`${field}.${FormField.PLACEHOLDERS}`}
							options={assignedToOptions}
							tooltip={tooltip}
						/>
						<AppNotifications.Alert />
						{assignedJobsFieldVisible && (
							<AssignedJobsField
								name={`${field}.${FormField.JOBS}`}
								options={assignedJobOptions}
								tooltip={tooltip}
								onOptionChange={this.onCheckboxChange}
							/>
						)}
					</>
				)}
			</div>
		);
	}
}
export default connect(
	(state: AppState, ownProps: FormPageOwnProps) => {
		const fieldValues = getContextualizationFormFieldValues(
			state,
			ownProps.field
		);
		const { documentTypeCode = '' } = fieldValues;
		return {
			fieldValues,
			placeholderOptions: getPlaceholderOptions(state, documentTypeCode),
			copyPageButtonIsVisible: getCopyPageButtonIsVisible(state),

			// Dropdown Field Options
			assignedToOptions: getPlaceholdersDropdownOptions(
				state,
				documentTypeCode
			),
			assignedJobOptions: getContextualizationAssignedJobsDropdownOptionsFlagged(
				state,
				documentTypeCode
			),
			hasShowDocumentationAlertMetadata: hasPortCallDocumentsMetadataAction(
				state,
				ownProps.portCallId,
				PortCallDocumentsMetadataActionCode.UPLOAD_OPERATION_DOCUMENTS
			)
		};
	},
	{
		change,
		alertWarning: alert.warning,
		removeNotification
	}
)(FormPage);
