import * as React from 'react';
import AntRadio from 'antd/lib/radio/radio';
import { RadioProps as AntRadioProps } from 'antd/lib/radio/interface';
import AntRadioButton from 'antd/lib/radio/radioButton';
import RadioGroup from './RadioGroup';
import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';
// Types
import { ReactChangeHandler } from 'app-types';

type RadioProps = AntRadioProps & {
	onFocus?: ReactChangeHandler;
};

export class Radio extends React.Component<RadioProps, {}> {
	static Group: typeof RadioGroup;
	static Button: typeof AntRadioButton;
	static ReduxFormItem: CreateReduxField<RadioProps>;

	render() {
		return <AntRadio {...this.props} />;
	}
}

Radio.Button = AntRadioButton;
Radio.Group = RadioGroup;

/**
 * ReduxForm Checkbox connector
 */

const mapProps = customMap(props => {
	return {
		value: props.input.value,
		onChange: () => {
			return props.input.onChange(props.input.value);
		},
		onBlur: () => {
			return props.input.onBlur(props.input.value);
		}
	};
});

Radio.ReduxFormItem = createFieldComponent<RadioProps>(Radio, mapProps);

export default Radio;
