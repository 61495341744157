import { IInternalComment } from 'services/api/threads/threadsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
export interface InternalCommentsState {
	threads: {
		[threadId: string]: IInternalComment[];
	};
	lastComment: {
		text: string;
		isPosting: FetchStatus;
		error?: Error;
	};
	isFetching: FetchStatus;
	error?: Error;
}

export const initialState: InternalCommentsState = {
	threads: {},
	lastComment: {
		text: '',
		isPosting: FetchStatus.IDLE
	},
	isFetching: FetchStatus.IDLE
};
