import React from 'react';
import { Radio, TextArea } from 'components/antd';

import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';

export enum CanalTransitFormFieldName {
	DETAILS = 'details',
	VESSEL_CONDITION = 'vesselCondition',
	LADEN = 'Laden',
	BALLAST = 'Ballast'
}

const CanalTransit = () => (
	<>
		<Radio.Group.ReduxFormItem
			name="vesselCondition"
			label="Vessel Condition"
			defaultValue={CanalTransitFormFieldName.LADEN}
		>
			<Radio value={CanalTransitFormFieldName.LADEN}>
				{CanalTransitFormFieldName.LADEN}
			</Radio>
			<Radio value={CanalTransitFormFieldName.BALLAST}>
				{CanalTransitFormFieldName.BALLAST}
			</Radio>
		</Radio.Group.ReduxFormItem>
		<TextArea.ReduxFormItem
			name={CanalTransitFormFieldName.DETAILS}
			label="Operation Details"
			rows={4}
			maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
		/>
	</>
);

export default CanalTransit;
