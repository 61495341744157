import { LabeledValueItem } from 'app-types';
import {
	DirectBillTargetType,
	DirectBillDiscountType,
	AgreementMakerCompanyOrganisationTypeCode,
	DirectBillDocument
} from 'services/api/directBills/directBillsServiceTypes';

export const CREATE_DIRECT_BILL_FORM_NAME = 'createDirectBillForm';
export enum EditDirectBillFields {
	MAIN_PRINCIPAL = 'mainPrincipalCompany',
	PRINCIPAL = 'principals',
	COUNTRIES = 'countries',
	PORTS = 'ports',
	VENDOR = 'vendorCompany',
	PERIOD_OF_VALIDITY_FROM = 'validFrom',
	PERIOD_OF_VALIDITY_TO = 'validTo',
	VALIDITY_SHOULD_NOT_EXPIRE = 'validityShouldNotExpire',
	DISCOUNT_TYPE = 'discountTypeCode',
	DISCOUNT_VALUE = 'discountValue',
	AGREEMENT_MAKER = 'agreementMakerCompanyOrganisationTypeCode',
	SERVICES = 'services',
	CREDIT_DAYS = 'creditDays',
	DIRECT_BILL_ORGANIZATION = 'targetTypeCode'
}

export interface EditDirectBillFormData {
	mainPrincipalCompany: LabeledValueItem;
	vendorCompany: LabeledValueItem;
	validFrom: string;
	validTo: string;
	targetTypeCode: DirectBillTargetType;
	agreementMakerCompanyOrganisationTypeCode: AgreementMakerCompanyOrganisationTypeCode;
	discountTypeCode: DirectBillDiscountType;
	discountValue: string;
	creditDays: string;
	appointmentInstruction: string;
	notes: string;
	validityShouldNotExpire: boolean;
	services: LabeledValueItem[];
	principals: LabeledValueItem[];
	countries: LabeledValueItem[];
	ports: LabeledValueItem[];
	documents: DirectBillDocument[];
}

export const DirectBillFormLabels = {
	[EditDirectBillFields.MAIN_PRINCIPAL]: 'Main Principal',
	[EditDirectBillFields.PRINCIPAL]: 'Principal',
	[EditDirectBillFields.COUNTRIES]: 'Country',
	[EditDirectBillFields.PORTS]: 'Port',
	[EditDirectBillFields.VENDOR]: 'Vendor',
	[EditDirectBillFields.PERIOD_OF_VALIDITY_FROM]: 'Period of Validity From',
	[EditDirectBillFields.PERIOD_OF_VALIDITY_TO]: 'Period of Validity To',
	[EditDirectBillFields.VALIDITY_SHOULD_NOT_EXPIRE]: 'Not Expire',
	[EditDirectBillFields.DISCOUNT_TYPE]: 'Discount',
	[EditDirectBillFields.DISCOUNT_VALUE]: 'Discount',
	[EditDirectBillFields.SERVICES]: 'Services',
	[EditDirectBillFields.AGREEMENT_MAKER]: 'Who Made the Agreement',
	[EditDirectBillFields.CREDIT_DAYS]: 'Credit Days',
	[EditDirectBillFields.DIRECT_BILL_ORGANIZATION]: 'Vendor Invoice To'
};
