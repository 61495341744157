import React from 'react';
import { Link } from 'components';
import { Alert, Col, Row } from 'components/antd';
import styles from './ActionAlert.module.scss';
import { AlertProps } from 'components/antd/Alert/Alert';
import { Justify, Align, Theme } from 'components/types';
import Button from 'antd/lib/button/button';
import TimeAgo from 'components/Status/TimeAgo/TimeAgo';
import { ContextBarButtonAction } from 'store/contextBars/contextBarsTypes';
import { getFinancePortJobDetails } from 'store/finance/selectors';
import { useSelector } from 'react-redux';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { AppState } from 'store-types';

export interface EmailLinkProps {
	linkText: string;
	linkEmail: string;
	emailSubject: string;
}

interface ActionAlertProps {
	className?: string;
	buttons?: ContextBarButtonAction[];
	emailLinkProps?: EmailLinkProps;
	title?: string;
	subtitle?: React.ReactNode;
	type: AlertProps['type'];
	timeRemaining?: string;
	timeZone?: string;
}

/**
 * This is temporary component we need till we will have migrated all our context bars to BE
 */
const NewActionAlert: React.FC<ActionAlertProps> = (
	props: ActionAlertProps
) => {
	const {
		subtitle,
		type,
		buttons,
		emailLinkProps,
		title,
		timeRemaining,
		timeZone
	} = props;
	const hasAction = emailLinkProps || buttons?.length;
	const isActivePortJobCode = useSelector(getActivePortJobCode);
	const isJobCancelled = useSelector((state: AppState) =>
		getFinancePortJobDetails(state, isActivePortJobCode)
	);
	const content = (
		<Row middle={'xs'}>
			<Col sm={hasAction ? 10 : 12} justify={Justify.START}>
				<Row middle={'xs'}>
					<Col sm={timeRemaining ? 9 : 12} justify={Justify.START}>
						{title && <span>{title}</span>}
						{subtitle && <p>{subtitle}</p>}
					</Col>
					{timeRemaining && type === Theme.ERROR && (
						<Col sm={3} justify={Justify.END} align={Align.MIDDLE}>
							<p>
								Time remaining:{' '}
								<span className={styles.overdue}>
									<TimeAgo date={timeRemaining} timeZone={timeZone} accurate />{' '}
									overdue
								</span>
							</p>
						</Col>
					)}
				</Row>
			</Col>
			{hasAction && (
				<Col sm={2} justify={Justify.END} align={Align.MIDDLE}>
					<>
						{buttons?.map((button, index) => (
							<Button
								className={styles.button}
								ghost={button.type === 'ghost'}
								key={index}
								type="primary"
								loading={button.loading}
								onClick={button.action}
								disabled={isJobCancelled}
							>
								{button.actionText}
							</Button>
						))}
						{emailLinkProps && (
							<Link
								href={`mailto:${emailLinkProps.linkEmail}?subject=${emailLinkProps.emailSubject}`}
								disabled={isJobCancelled}
							>
								{emailLinkProps.linkText}
							</Link>
						)}
					</>
				</Col>
			)}
		</Row>
	);
	return (
		<div className={styles.root}>
			<Alert
				className={props.className}
				showIcon
				type={type}
				message={content}
			/>
		</div>
	);
};

export default NewActionAlert;
