import {
	RetrieveBankAccountRequest as Request,
	LOBDDocument as Response
} from 'services/api/bankAccounts/bankAccountsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { retrieveLOBDAsync as async } from './../actions';
import Api from 'services/api';

export default makeTakeLatestWatcher<Request, Response, Error>({
	async,
	api: Api.BankAccounts.retrieveLOBD
});
