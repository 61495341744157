import { UploadFile } from 'components/antd/Upload/Upload';
import { ActionCreator, Action } from 'typescript-fsa';

export enum UploadType {
	ATTACHMENT = 'attachment',
	DOCUMENT = 'portCallDocument',
	FINANCE_DOCUMENT = 'financeDocument',
	DIRECT_BILL_DOCUMENT = 'directBillDocument',
	SUPPLIER_INVOICE = 'supplierInvoice',
	TEMPLATE_ATTACHMENT = 'templateAttachment',
	LOBD = 'lobd',
	INLINE_RESOURCE = 'InlineResource',
	PREFERRED_AGENTS = 'preferredAgent'
}

export enum ValidationStatus {
	SAFE = 'Safe',
	UN_SAFE = 'Unsafe',
	QUARANTINE = 'Quarantine'
}

export enum SplitStatus {
	NONE = 'None',
	PENDING = 'Pending',
	PROCESSING = 'Processing',
	COMPLETED = 'Completed',
	FAILED = 'Failed' // Not 100%
}

export interface DocumentsMetadata {
	portCallId?: string;
	jobFinanceId?: string;
	contextualizePdf?: boolean;
	disbursementAccountId?: string;
	supplierInvoiceId?: string;
	portJobServiceId?: string;
	isAnnotated?: boolean;
	pageNumber?: number;
	jobCode?: string;
	actNumber?: number;
}

export interface DocumentContextualizationBaseRequestData<T> {
	documentId: string;
	contextualizations: T[];
}

/**
 * Accepted parameters for GET Upload Attachment Config Request
 * @see https://conf.dataart.com/display/IG/Documents+storage+-+Technical+definition
 */
export interface RetrieveDocumentConfigParams {
	type: UploadType;
}

export interface DocumentUploadConfigurationForm {
	key: string;
	acl: string;
	'x-amz-credential': string;
	'x-amz-algorithm': string;
	'x-amz-date': string;
	'x-amz-signature': string;
	Policy: string;
}

/**
 * Static Document Configuration that doesn't change
 */
export interface RetrieveStaticDocumentConfigResponse {
	id: string;
	method: string;
	enctype: string;
	documentType: string;
	url: string;
	maxFileSize: number;
	maxFileSizeMb: number;
	allowedExtensions: string[];
	allowedContentTypes: string[];
	allowedFileNameCharacters: string[];
}
/**
 * Relative Document Configuration that changes on every request
 */
export interface RetrieveRelativeDocumentConfigResponse {
	id: string;
	form: DocumentUploadConfigurationForm;
}

/**
 * Interface for POST to AWS
 */
export interface UploadParams extends DocumentUploadConfigurationForm {
	file: UploadFile;
}

/**
 * Interface with neccessary arguments to notify Optic API about the Attachment Data
 */
export interface Document {
	id: string;
	documenttype: string; // Not in camelCase due to AWS fault
	name: string;
	size: number;
	processed: boolean;
	split: {
		status: SplitStatus;
		name: string;
		count: number;
	};
	contenttype?: string; // Not in camelCase due to AWS fault
	status: ValidationStatus;
	createdOn?: string;
}
/** CREATED for having a conection between the newly added attachment and the file cached by the <Upload> Component */
export interface DocumentWithOriginalFile extends Document {
	originFileObj: UploadFile; // Upload interface
}

export interface FileBaseInfo {
	id: string;
	documentId: string;
	name: string;
	createdByUserName: string;
	createdOnUtc: string;
}

export interface SaveDocumentRequest {
	id: string;
	name: string;
	contenttype?: string; // Not in camelCase due to AWS fault
	documenttype: string; // Not in camelCase due to AWS fault
	size: number;
	splitPdf?: boolean; // Default: false
	metadata?: DocumentsMetadata;
}

/**
 * Data from the uploaded file
 */
export interface SaveDocumentResponse extends Document {}
export interface RetrieveDocumentRequest {
	id: string;
}

export type RetrieveDocumentResponse = Document;

export interface UploadDocumentDoneResponse
	extends Pick<DocumentsMetadata, 'contextualizePdf'> {
	isDoneSuccess: boolean;

	document: Document & { createdByUserName?: string };
	metadata?: DocumentsMetadata;
}

export type UploadDocumentRequestDoneAction<T = UploadDocumentDoneResponse> = (
	data: T
) => Action<T> | ActionCreator<T>;

export interface UploadDocumentRequest {
	type: UploadType;
	request: UploadCustomRequest;
	onDone?: UploadDocumentRequestDoneAction;
	metadata?: DocumentsMetadata;
	showProgress: boolean;
	/**
	 * This property is used to hide the close button in upload process modal.
	 */
	hideClose?: boolean;
}

export type DocumentUploadFile = UploadFile;

export interface UploadCustomRequest {
	file: UploadFile;
}

export interface RetrieveDownloadDocumentResponse {
	isReady: boolean;
	downloadUrl: string | null;
	documentId?: string;
}
