import actionCreator from '../configurationActionCreator';

import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';
import { FeedbackDocument } from 'services/api/companies/companiesServiceTypes';

export const uploadRequestFeedbackDocumentDone = actionCreator<
	UploadDocumentDoneResponse
>('UPLOAD_REQUEST_FEEDBACK_DOCUMENTATION_DONE');

export const updateRequestFeedbackDocumentDone = actionCreator<
	FeedbackDocument
>('UPDATE_REQUEST_FEEDBACK_DOCUMENTATION_DONE');

export const deleteRequestFeedbackFileInfo = actionCreator<string>(
	'DELETE_REQUEST_FEEDBACK_FILE_INFO'
);

export const deleteAllRequestFeedbackFileInfo = actionCreator(
	'DELETE_ALL_REQUEST_FEEDBACK_FILE_INFO'
);
