import * as React from 'react';
import { connect } from 'react-redux';
import * as H from 'history';
import Sidebar from 'react-sidebar';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Icon, Menu } from 'components/antd';
import styles from './AppSidebar.module.scss';
import { SelectParam } from 'antd/lib/menu';
import { Permission } from 'utils/components';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { changeRoute } from 'store/route/actions';
import { destroy } from 'store/filters/actions';
import { PATHS } from 'sections/App/RouteParams';
import { getCanAuthActAs, getUserGroupType } from 'store/auth/selectors';
import { ActAsTagContainer } from 'containers/ActAs/ActAs';
import AppSidebarDataProvider from './AppSidebarDataProvider';
import { AppState } from 'store-types';
import { retrieveBenchmarkUrl } from 'store/navigation';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

const siderBarPermissions = [
	PermissionCode.VIEW_MESSAGES,
	PermissionCode.VIEW_USERS,
	PermissionCode.VIEW_GROUP,
	PermissionCode.VIEW_PORTCALLS,
	PermissionCode.VIEW_PRINCIPAL_SPECIFIC_DATA,
	PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_DATA_ISS,
	PermissionCode.VIEW_DIRECT_BILLS,
	PermissionCode.MANAGE_DIRECT_BILLS,
	PermissionCode.MANAGE_BANK_ACCOUNT_DETAILS,
	PermissionCode.VIEW_SUPPLIER_INVOICES,
	PermissionCode.VIEW_PRINCIPAL_SPECIFIC_SERVICES,
	PermissionCode.MANAGE_PRINCIPAL_SPECIFIC_SERVICES,
	PermissionCode.ACCESS_TO_REPORT_SERVER,
	PermissionCode.ACCESS_TO_BLT_APPLICATION,
	PermissionCode.MANAGE_API_SUBSCRIPTION
];

interface AppSidebarProps {
	isActingAs: boolean;
	open: boolean;
	onSetOpen(opened: boolean): void;
	changeRoute: typeof changeRoute;
	// from mapDispatchToProps
	destroyFilters: typeof destroy;
	retrieveBenchmarkUrl: typeof retrieveBenchmarkUrl;
	userGroupType: GroupTypeCodes;
}
interface AppSidebarPropsWithRouter
	extends RouteComponentProps<{}>,
		AppSidebarProps {}

interface AppSidebarState {
	selected: string;
}
declare const window: Window & {
	phoenixEnvs: {
		majorVersion: string;
		lastUpdate: string;
		releaseVersion: string;
	};
};
const releaseDate = window.phoenixEnvs.lastUpdate.slice(0, -10);
const releaseVersion = window.phoenixEnvs.releaseVersion;

const pathToArray = (path = '') => {
	const sanitizedPath = path.startsWith('/') ? path.substring(1) : path;
	return sanitizedPath.split('/');
};

export class AppSidebar extends React.Component<
	AppSidebarPropsWithRouter,
	AppSidebarState
> {
	public unregister: H.UnregisterCallback;

	constructor(props: AppSidebarPropsWithRouter) {
		super(props);
		this.state = {
			selected: PATHS.dashboard
		};
	}

	componentDidMount() {
		this.unregister = this.props.history.listen(this.onHistoryChanged);
		this.setPathAsSelectedId(this.props.location.pathname);
	}

	componentWillUnmount() {
		this.unregister();
	}

	setPathAsSelectedId = (
		pathname: string,
		defaultSelection: string = PATHS.dashboard
	) => {
		const pathAsArray = pathToArray(pathname);
		const pathAsId = pathAsArray.length ? pathAsArray[0] : PATHS.dashboard;
		this.setState({ selected: pathAsId ? pathAsId : defaultSelection });
	};

	onHistoryChanged = (e: H.Location) => {
		this.setPathAsSelectedId(e.pathname);
		this.props.changeRoute();
	};

	onItemSelection = (e: SelectParam) => {
		this.props.destroyFilters('all');
		this.props.onSetOpen(false);
		if (e.key === 'report-server') {
			return;
		}
		if (e.key === 'benchmark-application') {
			this.props.retrieveBenchmarkUrl();
			return;
		}
		if (e.key === 'close') {
			this.props.history.push(this.props.location.pathname);
		} else {
			if (e.key === PATHS.dashboard) {
				this.props.history.push(`/`);
			} else {
				this.props.history.push(`/${e.key}`);
			}
		}
	};

	renderSidebar = () => (
		<AppSidebarDataProvider>
			{({ reportServer, benchmarkApplication }) => (
				<Permission permissionCode={siderBarPermissions}>
					{(
						messagesManagementPermission,
						usersManagementPermission,
						groupManagementPermission,
						portcallManagementPermission,
						principalSpecificDataViewPermission,
						principalSpecificDataISSManagementPermission,
						viewDirectBillsPermission,
						manageDirectBillsPermission,
						manageBankAccountDetails,
						viewSupplierInvoices,
						viewPrincipalSpecificServicesPermission,
						managePrincipalSpecificServicesPermission,
						accessToReportServer,
						accessToBLTApplication,
						manageApiSubscription
					) => {
						const hasDataManagement =
							viewDirectBillsPermission || manageDirectBillsPermission;
						const hasConfiguration =
							principalSpecificDataViewPermission ||
							principalSpecificDataISSManagementPermission ||
							viewPrincipalSpecificServicesPermission ||
							managePrincipalSpecificServicesPermission;
						return (
							<Menu
								onClick={this.onItemSelection}
								selectedKeys={[this.state.selected]}
								defaultOpenKeys={[PATHS.administration]}
								mode="inline"
								className={styles.menu}
								inlineCollapsed={false}
							>
								<Menu.Item key="close">
									<Icon type="arrow-left" size="lg" />
								</Menu.Item>
								<Menu.Item key={PATHS.dashboard}>Dashboard</Menu.Item>
								{messagesManagementPermission && (
									<Menu.Item key={PATHS.messages}>Messages</Menu.Item>
								)}
								{usersManagementPermission && (
									<Menu.Item key={PATHS.users}>Users</Menu.Item>
								)}
								{groupManagementPermission && (
									<Menu.Item key={PATHS.groups}>Groups</Menu.Item>
								)}
								{portcallManagementPermission && (
									<Menu.Item key={PATHS.portCalls}>Port Calls</Menu.Item>
								)}
								{viewSupplierInvoices && (
									<Menu.Item key={PATHS.supplierinvoices}>
										Supplier Invoices
									</Menu.Item>
								)}
								{(hasDataManagement || hasConfiguration) && (
									<Menu.SubMenu
										key={PATHS.administration}
										title="Administration"
									>
										{hasDataManagement && (
											<Menu.Item key={PATHS.opticDataManagement}>
												Optic data management
											</Menu.Item>
										)}
										{hasConfiguration && (
											<Menu.Item key={PATHS.configuration}>
												Principal configuration
											</Menu.Item>
										)}
										{this.props.userGroupType === GroupTypeCodes.ISS_ADMIN && (
											<Menu.Item key={PATHS.opticConfiguration}>
												Optic configuration
											</Menu.Item>
										)}
										{manageApiSubscription && (
											<Menu.Item key={PATHS.developerPortal}>
												Developer Portal
											</Menu.Item>
										)}
									</Menu.SubMenu>
								)}
								{manageBankAccountDetails && (
									<Menu.Item key={PATHS.bankAccountDetails}>
										Bank Account Details
										{this.props.isActingAs && (
											<ActAsTagContainer className={styles.menuItemTag}>
												Act As LPA
											</ActAsTagContainer>
										)}
									</Menu.Item>
								)}
								{accessToReportServer && reportServer.isVisible && (
									<Menu.Item key="report-server">
										<a href={reportServer.url} target="_blank">
											Report Server
										</a>
									</Menu.Item>
								)}
								{accessToBLTApplication && benchmarkApplication.isVisible && (
									<Menu.Item key="benchmark-application">BLT App</Menu.Item>
								)}
								{principalSpecificDataISSManagementPermission && (
									<Menu.Item key={`${PATHS.masterDataExplorer}`}>
										Master Data Explorer
									</Menu.Item>
								)}
								{this.props.userGroupType === GroupTypeCodes.THIRD_PARTY_LPA &&
									!this.props.isActingAs && (
										<Menu.Item key={PATHS.thirdPartyLPAGroups}>
											Groups
										</Menu.Item>
									)}
								<p className={styles.releaseVersion}>
									Version: {releaseVersion}
								</p>
								<p className={styles.releaseDate}>
									<span>Release Date: </span>
									{releaseDate}
								</p>
							</Menu>
						);
					}}
				</Permission>
			)}
		</AppSidebarDataProvider>
	);

	render() {
		return (
			<Sidebar
				contentClassName={styles.sidebarContent}
				open={this.props.open}
				onSetOpen={this.props.onSetOpen}
				sidebar={this.renderSidebar()}
				overlayClassName={styles.overlay}
				sidebarClassName={styles.sidebar}
			>
				{this.props.children}
			</Sidebar>
		);
	}
}

const WithRouterAppBar = withRouter<AppSidebarPropsWithRouter>(AppSidebar);

export default connect(
	(state: AppState) => ({
		isActingAs: getCanAuthActAs(state),
		userGroupType: getUserGroupType(state)
	}),
	{
		changeRoute,
		destroyFilters: destroy,
		retrieveBenchmarkUrl
	}
)(WithRouterAppBar);
