import React, { FC } from 'react';
import classNames from 'classnames';
import AntItem, {
	FormItemProps as AntFormItemProps
} from 'antd/lib/form/FormItem';
import { Asteriks } from 'components/Styles';
import styles from './Form.module.scss';

export interface FormItemProps extends AntFormItemProps {
	marginBottom?: boolean;
	withAsterisk?: boolean;
}
const FormItem: FC<FormItemProps> = ({
	marginBottom = true,
	className,
	withAsterisk,
	...props
}) => (
	<div
		className={classNames(styles.formItemWrapper, {
			[styles.withAsterisk]: withAsterisk
		})}
	>
		<AntItem
			{...props}
			className={classNames(styles.item, className, {
				[styles.noMarginBottom]: !marginBottom
			})}
		/>
		{withAsterisk && <Asteriks className={styles.formItemAsterisk} />}
	</div>
);

export default FormItem;
