import {
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	RetrieveSupplierInvoiceContextualizationForUpdateRequest
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import actionCreator from '../supplierInvoiceActionCreator';

const ADD_ACTION_NAME = 'RETRIEVE_SUPPLIER_INVOICE_CONTEXTUALIZATION';

export const retrieveSupplierInvoiceContextualizationAsync = actionCreator.async<
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	Error
>(ADD_ACTION_NAME);

export const retrieveSupplierInvoiceContextualization = actionCreator<
	RetrieveSupplierInvoiceContextualizationRequest
>(ADD_ACTION_NAME);

const UPDATE_ACTION_NAME =
	'RETRIEVE_SUPPLIER_INVOICE_CONTEXTUALIZATION_FOR_UPDATE';

export const retrieveSupplierInvoiceContextualizationForUpdateAsync = actionCreator.async<
	RetrieveSupplierInvoiceContextualizationForUpdateRequest,
	RetrieveSupplierInvoiceContextualizationResponse,
	Error
>(UPDATE_ACTION_NAME);

export const retrieveSupplierInvoiceContextualizationForUpdate = actionCreator<
	RetrieveSupplierInvoiceContextualizationForUpdateRequest
>(UPDATE_ACTION_NAME);

const ADD_VIA_POST_CREDIT_ACTION_NAME =
	'RETRIEVE_SUPPLIER_INVOICE_CONTEXTUALIZATION_FOR_POST_CREDIT';

export const retrieveSupplierInvoiceContextualizationForAddViaPostCredit = actionCreator(
	ADD_VIA_POST_CREDIT_ACTION_NAME
);
