import React from 'react';
import { DraftVendorWithServices } from './appointToPortJobModalTypes';
import { Text, Gap } from 'components';
type Keys = keyof DraftVendorWithServices;
type Columns = Array<{
	dataIndex: Keys;
	key: Keys;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[k: string]: any;
}>;
export const vendorDraftsColumns: Columns = [
	{
		title: 'Vendor Name',
		dataIndex: 'name',
		key: 'name',
		width: '60%'
	},
	{
		title: 'Related services',
		dataIndex: 'services',
		key: 'services',
		width: '40%',
		render(_: string, { services }: DraftVendorWithServices) {
			return services.map(serviceName => (
				<Gap isBlock key={serviceName}>
					<Text color={'error'}>{serviceName}</Text>
				</Gap>
			));
		}
	}
];
