import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import numeral from 'numeral';
import { getValues } from 'store/form/selectors';
import {
	COST_SAVINGS_FORM,
	CostSavingsFormData,
	TimeSavingsFormData,
	TIME_SAVINGS_FORM
} from 'sections/PortCall/Savings/SavingsGrid/Constants';
import { AddCostPayload } from 'services/api/savings/savingsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import { LabeledValue } from 'app-types';
import { getUserType } from 'store/auth/selectors';

export const getSavings = (state: AppState) => state.savings;

export const getSavingsDetails = createSelector(
	getSavings,
	savings => savings.savings
);

export const getTimeSavings = (state: AppState) => state.savings.timeSavings;

export const getSavingsTableData = createSelector(
	getSavingsDetails,
	savings => savings?.costSavings
);

export const getCostSavingsContext = (state: AppState) => state.savings.context;

const getInitialCostSavings = {
	category: undefined,
	services: [],
	savingsBy: '',
	amountSaved: '',
	currency: '',
	comments: undefined
};

const multiSelectFromArr = (key: string) => (entity: {
	name: string;
}): LabeledValue<string> => ({
	key: entity[key],
	label: entity.name
});

const getUpdatedCostSavings = createSelector(
	getCostSavingsContext,
	(context): Partial<CostSavingsFormData> => {
		const { costSavings } = context;
		if (!costSavings?.id) {
			return getInitialCostSavings;
		}

		return {
			category: multiSelectFromArr('code')(costSavings.category),
			services: costSavings.services.map(multiSelectFromArr('code')),
			savingsBy: costSavings.savingsBy,
			amountSaved: String(costSavings.amountSaved),
			currency: costSavings.currency,
			comments: costSavings.comments ? costSavings.comments : undefined
		};
	}
);

export const getUpdateCostSavings = createSelector(
	getUpdatedCostSavings,
	savings => {
		return { ...savings };
	}
);

const getInitialTimeSavings = {
	category: undefined,
	services: [],
	savingsBy: '',
	hours: '',
	minutes: undefined,
	comments: undefined
};

const getUpdatedTimeSavings = createSelector(
	getCostSavingsContext,
	(context): Partial<TimeSavingsFormData> => {
		const { timeSavings } = context;
		if (!timeSavings?.id) {
			return getInitialTimeSavings;
		}

		return {
			category: multiSelectFromArr('code')(timeSavings.category),
			services: timeSavings.services
				? timeSavings.services.map(multiSelectFromArr('code'))
				: undefined,
			savingsBy: timeSavings.savingsBy,
			hours: String(timeSavings.hours),
			minutes: timeSavings.minutes ? String(timeSavings.minutes) : undefined,
			comments: timeSavings.comments ? timeSavings.comments : undefined
		};
	}
);

export const getUpdateTimeSavings = createSelector(
	getUpdatedTimeSavings,
	savings => {
		return { ...savings };
	}
);

export const getinitialStateId = (state: AppState) =>
	state.savings.context.activeId;

export const getAddSavingsFormData = (state: AppState) =>
	getValues<CostSavingsFormData>(state, COST_SAVINGS_FORM);

export const getPreparedAddCostSavingsData = createSelector(
	getAddSavingsFormData,
	getUserType,
	(formData, userType): Partial<AddCostPayload> => {
		const formatMultipleFormField = (
			field: Array<{ key: string; label: string }>
		) => {
			return field.map(entry => ({ code: entry.key, name: entry.label }));
		};
		return {
			...formData,
			category: { code: formData.category.key, name: formData.category.label },
			savingsBy: formData.savingsBy,
			services: formatMultipleFormField(formData.services),
			amountSaved: Number(numeral(formData.amountSaved).format('0.[00000000]')),
			amountInUSD: 0,
			userType
		};
	}
);

export const getDeleteSavingFetchStatus = (state: AppState) =>
	state.savings.fetchStatuses.context === FetchStatus.PENDING;

export const getSavingsFetchStatus = (state: AppState) =>
	state.savings.fetchStatuses.savings === FetchStatus.PENDING ||
	state.savings.fetchStatuses.timeSavings === FetchStatus.PENDING;

export const getAddCostSavingFetchStatus = (state: AppState) =>
	state.savings.fetchStatuses.addCostSavings;

export const getAddTimeSavingFetchStatus = (state: AppState) =>
	state.savings.fetchStatuses.addTimeSavings;

export const getTotalAmountInUSD = createSelector(
	getSavingsDetails,
	savings => savings?.totalAmountInUSD
);

export const getTotalHoursSaved = createSelector(
	getTimeSavings,
	timeSavings => timeSavings?.totalTime
);

export const getIndicativeRates = createSelector(
	getSavingsDetails,
	savings => savings?.indicativeRates
);

export const getIndicativeRateOn = createSelector(
	getSavingsDetails,
	savings => savings?.indicativeRateOn
);

export const getAddTimeSavingsFormData = (state: AppState) =>
	getValues<TimeSavingsFormData>(state, TIME_SAVINGS_FORM);

export const getPreparedAddTimeSavingsData = createSelector(
	getAddTimeSavingsFormData,
	getUserType,
	(formData, userType): Partial<AddCostPayload> => {
		const formatMultipleFormField = (
			field: Array<{ key: string; label: string }>
		) => {
			return field.map(entry => ({ code: entry.key, name: entry.label }));
		};
		return {
			...formData,
			category: { code: formData.category.key, name: formData.category.label },
			savingsBy: formData.savingsBy,
			services: formData.services
				? formatMultipleFormField(formData.services)
				: [],
			userType
		};
	}
);

export const getTimeSavingsData = createSelector(
	getTimeSavings,
	timeSavings => timeSavings?.timeSavings
);
