import actionCreator from '../threadActionCreator';
import {
	PatchThreadPermissionsRequest,
	PatchThreadPermissionsResponse
} from 'services/api/threads/threadsServiceTypes';

const PATCH_THREAD_PERMISSIONS = 'PATCH_THREAD_PERMISSIONS';

export const patchThreadPermissionsAsync = actionCreator.async<
	PatchThreadPermissionsRequest,
	PatchThreadPermissionsResponse,
	Error
>(PATCH_THREAD_PERMISSIONS, {
	behaviour: { type: 'react-to-changes-child' }
});

export const patchThreadPermissions = actionCreator<
	PatchThreadPermissionsRequest
>(PATCH_THREAD_PERMISSIONS);
