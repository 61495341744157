import * as React from 'react';
import { connect } from 'react-redux';
import { EntityPermissionFetcher } from 'containers';
import { Loading, wizardForm, Error403 } from 'components';
import { ScrollableLayout } from 'components/Styles';
import { addPortCall, retrievePortCall, resetPortCalls } from 'store/portcalls';
import { addPortJob, setPortJobContext } from 'store/portJobs/actions';
import { notify } from 'store/notifications/actions';

import { InjectedWizardFormProps } from 'components/WizardForm';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import CreatePortJobWizardFooter from './CreatePortJobWizardFooter';
import { PORT_JOB_WIZARD_ID } from './createPortJobConstants';
import { EntityPermissionType } from 'services/api/permissions/permissionsServiceTypes';

import {
	getIsPortCallAdding,
	getActivePortCallId
} from 'store/portcalls/portCallsSelectors';

// Utils
import { navigateTo } from 'utils';
import { AppState } from 'store-types';

interface CreatePortJobWizardProps {
	onCancel: () => void;
	onFinish?: () => void;
	// from mapStateToProps
	adding: boolean;
	activePortCallId: string;
	// from mapDispatchToProps
	addPortCall: typeof addPortCall;
	addPortJob: typeof addPortJob;
	setPortJobContext: typeof setPortJobContext;
	retrievePortCall: typeof retrievePortCall;
	resetPortCalls: typeof resetPortCalls;
	notifyError: typeof notify.error;
}

interface CreatePortJobWizardFormProps
	extends InjectedWizardFormProps,
		CreatePortJobWizardProps {}

class CreatePortJobWizard extends React.Component<
	CreatePortJobWizardFormProps
> {
	componentWillUnmount() {
		this.props.resetPortCalls();
		this.props.setPortJobContext({
			isMainPrincipalTypeCLS: undefined
		});
		this.props.destroy();
	}

	componentDidUpdate(prevProps: CreatePortJobWizardProps) {
		if (this.props.activePortCallId && !prevProps.activePortCallId) {
			navigateTo('/portcalls');
			this.props.notifyError(`That Port Call doesn't exist.`);
		}
	}

	private onFinish = () => {
		if (this.props.onFinish) {
			this.props.onFinish();
			return;
		}
		const { activePortCallId } = this.props;
		if (!activePortCallId) {
			this.props.addPortCall();
			return;
		}
		this.props.addPortJob();
	};

	render() {
		const { activePortCallId, adding, component } = this.props;
		const Page = component;
		return (
			<ScrollableLayout>
				{(activePortCallId && (
					<EntityPermissionFetcher
						entityKey={activePortCallId}
						entityType={EntityPermissionType.PORTCALL}
					>
						{({ canAdd }) =>
							(canAdd && (
								<ScrollableLayout>
									<Loading show={adding} />
									{!adding && Page && <Page />}
									{!adding && (
										<CreatePortJobWizardFooter
											{...this.props}
											onFinish={this.onFinish}
											onCancel={this.props.onCancel}
										/>
									)}
								</ScrollableLayout>
							)) || <Error403 />
						}
					</EntityPermissionFetcher>
				)) || (
					<>
						<Loading show={adding} />
						{!adding && Page && <Page />}
						{!adding && (
							<CreatePortJobWizardFooter
								{...this.props}
								onFinish={this.onFinish}
								onCancel={this.props.onCancel}
							/>
						)}
					</>
				)}
			</ScrollableLayout>
		);
	}
}

const WizardForm = wizardForm<CreatePortJobWizardProps>({
	name: PORT_JOB_WIZARD_ID,
	mode: 'create', // default state
	pages: [
		{
			component: Page1
		},
		{
			component: Page2
		},
		{
			component: Page3
		}
	]
})(CreatePortJobWizard);

export default connect(
	(state: AppState) => ({
		adding: getIsPortCallAdding(state),
		// replaceable props when re using this component
		activePortCallId: getActivePortCallId(state)
	}),
	{
		// replaceable props when re using this component
		addPortCall,
		retrievePortCall,
		resetPortCalls,
		addPortJob,
		notifyError: notify.error,
		setPortJobContext
	}
)(WizardForm);
