import React, { FC, useState, useEffect, useCallback } from 'react';
import { getIsCurrentUsersGroupTypeISSHub } from 'store/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import {
	FormFieldName,
	FORM
} from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { Page1FormValues } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { AutocompleteEntityProps } from 'containers/Autocomplete/AutocompleteEntity';
import Api from 'services/api';
import { change, resetSection } from 'redux-form';
import { Select } from 'components/antd';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import {
	PAGE_1_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE,
	PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE,
	prepareHubPrincipalOptions
} from './Form.func';
import { retrieveCompanyJobTypes } from 'store/companies/actions';
import { resetCompaniesContactDetails } from 'store/companies/actions/retrieveCompaniesContactDetails';

interface FieldHubPrincipalProps extends AutocompleteEntityProps {
	hubPrincipalCompany: Page1FormValues['hubPrincipalCompany'];
	isFieldHidden?: boolean;
	resetOnChange?: boolean;
}

const FieldHubPrincipal: FC<FieldHubPrincipalProps> = ({
	hubPrincipalCompany,
	isFieldHidden,
	resetOnChange,
	...autocompleteProps
}) => {
	const hubPrincipalCompanyKey = hubPrincipalCompany?.key;
	const dispatch = useDispatch();

	const isCurrentUsersGroupTypeISSHub = useSelector(
		getIsCurrentUsersGroupTypeISSHub
	);

	const activePortCallId = useSelector(getActivePortCallId);

	const [options, setOptions] = useState<AutocompleteOption[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const updateFormValue = useCallback(
		// eslint-disable-next-line
		(field: string, value: any) => {
			dispatch(change(FORM.portJob, field, value));
			dispatch(resetSection(FORM.portJob, field));
		},
		[dispatch]
	);

	const resetFormFields = useCallback(
		(formFieldsToReset: FormFieldName[]) => {
			formFieldsToReset.forEach(field => updateFormValue(field, null));
		},
		[updateFormValue]
	);

	const onHubPrincipalChange = useCallback(
		({ key }) => {
			if (resetOnChange === false) {
				return;
			}
			const fieldsToReset = [
				...(!activePortCallId
					? PAGE_1_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE
					: []),
				...PAGE_2_FIELDS_TO_RESET_ON_PRINCIPAL_CHANGE
			];
			dispatch(resetCompaniesContactDetails());
			resetFormFields(fieldsToReset);
			dispatch(retrieveCompanyJobTypes({ companyId: key }));
		},
		[resetFormFields, resetOnChange, activePortCallId, dispatch]
	);

	const setDefaultHubPrincipalValue = useCallback(
		options => {
			if (
				isCurrentUsersGroupTypeISSHub &&
				!hubPrincipalCompanyKey &&
				options.length === 1
			) {
				updateFormValue(FormFieldName.HUB_PRINCIPAL_COMPANY, options[0]);
				dispatch(retrieveCompanyJobTypes({ companyId: options[0].key }));
			}
		},
		[
			dispatch,
			isCurrentUsersGroupTypeISSHub,
			hubPrincipalCompanyKey,
			updateFormValue
		]
	);

	const retrieveHubPrincipalOptions = useCallback(() => {
		setIsLoading(true);
		Api.Companies.retrieveCompanyType
			.MainPrincipal({
				actionType: [PermissionCode.MANAGE_PORTCALL]
			})
			.then(response => {
				const options = prepareHubPrincipalOptions(response.data.elements);
				setOptions(options);
				setDefaultHubPrincipalValue(options);
				setIsLoading(false);
			});
	}, [setDefaultHubPrincipalValue]);

	useEffect(() => {
		retrieveHubPrincipalOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isFieldHidden) {
		return null;
	}

	return (
		<Select.ReduxFormItem
			{...autocompleteProps}
			labelInValue
			onChange={onHubPrincipalChange}
			isLoading={isLoading}
		>
			{options.map(option => (
				<Select.Option key={option.id} value={option.id}>
					{option.name}
				</Select.Option>
			))}
		</Select.ReduxFormItem>
	);
};

export default FieldHubPrincipal;
