import { ThreadsState } from 'store/threads/threadsState';
import { ThreadsContext } from 'services/api/threads/threadsServiceTypes';

export const onUpdateThreadsContext = (
	state: ThreadsState,
	payload: ThreadsContext
): ThreadsState => ({
	...state,
	context: {
		...state.context,
		...payload
	}
});
