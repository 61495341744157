import { Autocomplete, Col, Modal, Row, Select } from 'components/antd';
import React, { Component } from 'react';
import { PortJobDAs } from 'services/api/portJobs/portJobsServiceTypes';
import Api from 'services/api';
import { Text } from 'components';
import styles from '../PortJobList.module.scss';

export interface AssignDaModalProps {
	portCallId: string;
	jobCode: string;
	userId: string;
	userName: string;
	openModalState: boolean;
	userList: PortJobDAs[];
	onClose: () => void;
	reloadPage: () => void;
}

enum defaultAssign {
	ASSIGN_TO_ME = 'Assign to me'
}

export class AssignDaModal extends Component<AssignDaModalProps> {
	onChange = (value: { key: string; label: string }) => {
		this.props.onClose();
		const { jobCode, portCallId, userId, userName, reloadPage } = this.props;
		return Api.PortCalls.addAssignedUserToPortJob({
			portCallId,
			jobCode,
			updateByUserId: userId,
			updateByUserName: userName,
			assignedDaId: value.key,
			assignedDaName: value.key === userId ? userName : value.label
		}).then(() => reloadPage());
	};
	render() {
		const { userList, userName, userId, onClose } = this.props;
		return (
			<Modal
				size="sm"
				destroyOnClose
				visible={this.props.openModalState}
				title="Assign/Reassign"
				onClose={onClose}
				footer={null}
			>
				<Row>
					<Col xs={9}>
						Assignee
						<Autocomplete
							showSearch
							onSelect={this.onChange}
							optionFilterProp="children"
							filterOption
							localSearch
							allowClear
							placeholder={'select'}
							dropdownClassName={styles.assignDaDropDownSelect}
							getPopupContainer={() => document.body}
						>
							<Select.Option key={userId}>
								{
									<Text color="primary" weight="semi-bold">
										{defaultAssign.ASSIGN_TO_ME}
									</Text>
								}
							</Select.Option>
							{userList
								.filter(x => x.name !== userName)
								.map(option => (
									<Select.Option key={option.id}>{option.name}</Select.Option>
								))}
						</Autocomplete>
					</Col>
				</Row>
			</Modal>
		);
	}
}

export default AssignDaModal;
