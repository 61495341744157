import { FetchStatus, ResultCollection } from 'services/api/apiTypes';
import { GroupsState } from '../groupsState';
import { Success, Failure } from 'typescript-fsa';
import {
	GroupScopePermissions,
	RetrieveGroupScopePermissionsRequest
} from 'services/api/groups/groupsServiceTypes';

function getState(
	state: GroupsState,
	action: {
		params?: RetrieveGroupScopePermissionsRequest;
		result?: ResultCollection<GroupScopePermissions>;
		error?: Error;
		scopeType?: string;
	},
	fetchStatus: FetchStatus
) {
	let scopeType = action.params ? action.params.scopeType : action.scopeType;
	if (!scopeType) {
		scopeType = '';
	}
	const elements = action.result ? action.result.elements : null;
	const error = action.error;

	const fetchStatuses = {
		...state.fetchStatuses
	};

	const resultState = {
		...state,
		fetchStatuses
	};

	resultState.fetchStatuses.retrievingScopePermissions[scopeType] = fetchStatus;
	resultState.scopePermissions[scopeType] = elements;

	if (error) {
		resultState.error = error;
	}

	return resultState;
}

export function onRetrieveGroupScopePermissionsStarted(
	state: GroupsState,
	action: {
		scopeType: string;
		groupId: string;
	}
): GroupsState {
	return getState(state, action, FetchStatus.PENDING);
}

export function onRetrieveGroupScopePermissionsSuccess(
	state: GroupsState,
	action: Success<
		RetrieveGroupScopePermissionsRequest,
		ResultCollection<GroupScopePermissions>
	>
): GroupsState {
	return getState(state, action, FetchStatus.SUCCESS);
}

export function onRetrieveGroupScopePermissionsFailed(
	state: GroupsState,
	action: Failure<RetrieveGroupScopePermissionsRequest, Error>
): GroupsState {
	return getState(state, action, FetchStatus.FAILURE);
}
