import { AppState } from 'store-types';
import { EntitiesSelectMap } from 'app-types';
import { get, some, flatMap, forEach } from 'lodash';
import { createSelector } from 'reselect';

import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';

import { FinanceContextualizationFormState } from '../financeContextualizationReducer';

import {
	FinanceContextualizationDisbursementAccount,
	FinanceService,
	FinanceMetadataActionCode
} from 'services/api/finance/financeServiceTypes';
import { getFinanceEntityMetadataAction } from 'store/finance/selectors';

const getFinanceContextualization = (state: AppState) =>
	state.form.financeContextualization;

export const getFinanceContextualizationData = (
	state: AppState
): FinanceContextualizationFormState['data'] =>
	get(getFinanceContextualization(state), 'data');

const getFinanceContextualizationDataById = (
	state: AppState,
	disbursementAccountId: string
) => {
	const data = getFinanceContextualizationData(state) || [];
	return data.find(item => item.id === disbursementAccountId);
};

export const getCanContextualizeFinanceDocumentCoverSheetById = createSelector(
	getFinanceContextualizationDataById,
	data => {
		if (!data || !data.coverSheet) {
			return false;
		}
		return !!getFinanceEntityMetadataAction(
			data.coverSheet.metadata,
			FinanceMetadataActionCode.CONTEXTUALIZE
		);
	}
);
export const getCanContextualizeFinanceDocumentServicesById = createSelector(
	getFinanceContextualizationDataById,
	data =>
		Boolean(
			data &&
				getFinanceEntityMetadataAction(
					data.metadata,
					FinanceMetadataActionCode.CONTEXTUALIZE
				)
		)
);

export const getCanSubmitFinanceContextualization = createSelector(
	getContextualizationWizardForm,
	wizardForm => some(wizardForm.pages, { valid: true })
);

export const getFinanceContextualizationFormValues = createSelector(
	getFinanceContextualization,
	form => form.values
);

const getContextualizationFormEntitiesSelectMapCount = (
	selection: Array<EntitiesSelectMap | undefined>
) => {
	return selection.reduce<{ [id: string]: number }>((result, value) => {
		forEach(value, (checked, id) => {
			if (checked) {
				result[id] = (result[id] || 0) + 1;
			}
		});
		return result;
	}, {});
};

export const getContextualizationDAByIdSelection = createSelector(
	getFinanceContextualizationData,
	getFinanceContextualizationFormValues,
	(formData = [], formValues) => {
		const pages = formValues.pages.map(page => page.das);
		const formSelection = getContextualizationFormEntitiesSelectMapCount(pages);

		// Set initial count
		const ids = {};
		formData.forEach(({ id, coverSheet }) => {
			const pagesCount = coverSheet?.pagesCount || 0;
			ids[id] = pagesCount + (formSelection[id] || 0);
		});

		return ids;
	}
);

export const getContextualizationServicesByIdSelection = createSelector(
	getFinanceContextualizationData,
	getFinanceContextualizationFormValues,
	(formData, formValues) => {
		// Gather all services
		const services = flatMap<
			FinanceContextualizationDisbursementAccount,
			FinanceService
		>(formData, item =>
			flatMap(item.serviceCategories, category => category.services)
		);

		// Add services selected for the form
		const pages = formValues.pages.map(page => page.services);
		const formSelection = getContextualizationFormEntitiesSelectMapCount(pages);

		// Set initial count
		const ids = {};
		services.forEach(({ id, document }) => {
			ids[id] =
				// Add initial count
				(document ? document.pagesCount : 0) + (formSelection[id] || 0);
		});

		return ids;
	}
);
