import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index';

import { store, runSaga } from './redux/store';
import { Provider } from 'react-redux';
import OidcProvider from './redux/providers/OidcProvider';
import fontLoader from './services/fontLoader';
import App from './sections/App/App';
import SystemStatus from './containers/SystemStatus/SystemStatus';
import LocaleProvider from './utils/localization/LocaleProvider';
import rootSaga from './store/sagas';
import './utils/showVersion';

fontLoader();
runSaga(rootSaga);

const rootEl = document.getElementById('app');

const renderApp = (Component: React.FC<{}>) => {
	ReactDOM.render(
		<Provider store={store}>
			<OidcProvider>
				<LocaleProvider>
					<SystemStatus>
						<Component />
					</SystemStatus>
				</LocaleProvider>
			</OidcProvider>
		</Provider>,
		rootEl
	);
};

renderApp(App);

if (module.hot) {
	module.hot.accept('./sections/App/App', () => {
		const NextApp = require('./sections/App/App').default;
		renderApp(NextApp);
	});
}
