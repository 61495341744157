import { all, fork } from 'redux-saga/effects';
import retrieveSupplierInvoicesSaga from './retrieveSupplierInvoicesSaga';
import uploadDocumentDoneSaga from './uploadDocumentDoneSaga';
import createSupplierInvoiceSaga from './createSupplierInvoice/createSupplierInvoiceSaga';
import {
	retrieveSupplierInvoiceContextualizationForAdd,
	retrieveSupplierInvoiceContextualizationForUpdate,
	retrieveSupplierInvoiceContextualizationForAddViaPostCreditWatcher
} from './retrieveSupplierInvoiceContextualizationSaga';
import downloadSupplierInvoiceSaga from './downloadSupplierInvoiceSaga';
import retrieveSupplierInvoiceJobSaga from './retrieveSupplierInvoiceJobSaga';
import retrieveSupplierInvoiceSaga from './retrieveSupplierInvoiceSaga';
import retrieveSupplierInvoiceDocumentSaga from './retrieveSupplierInvoiceDocumentSaga';
import setActiveSupplierInvoiceSaga from './setActiveSupplierInvoiceSaga';
import updateSupplierInvoiceSaga from './updateSupplierInvoice/updateSupplierInvoiceSaga';
import retrieveSupplierInvoicesCycleSaga from './retrieveSupplierInvoicesCycleSaga';
import cancelCreditSupplierInvoiceSaga from './cancelCreditSupplierInvoiceSaga';
import retrieveSplitSupplierInvoiceDocumentSaga from './retrieveSplitSupplierInvoiceServiceDocumentSaga';
import downloadSplitSupplierInvoiceSaga from './downloadSplitSupplierInvoiceSaga';
import deleteSupplierInvoiceDocumentPageSaga from './deleteSupplierInvoiceDocumentPageSaga';

export default function*() {
	yield all([
		fork(retrieveSupplierInvoicesSaga),
		fork(retrieveSupplierInvoicesCycleSaga),
		fork(uploadDocumentDoneSaga),
		fork(createSupplierInvoiceSaga),
		fork(retrieveSupplierInvoiceContextualizationForAdd),
		fork(retrieveSupplierInvoiceContextualizationForUpdate),
		fork(retrieveSupplierInvoiceContextualizationForAddViaPostCreditWatcher),
		fork(downloadSupplierInvoiceSaga),
		fork(retrieveSupplierInvoiceJobSaga),
		fork(retrieveSupplierInvoiceSaga),
		fork(retrieveSupplierInvoiceDocumentSaga),
		fork(setActiveSupplierInvoiceSaga),
		fork(updateSupplierInvoiceSaga),
		fork(cancelCreditSupplierInvoiceSaga),
		fork(retrieveSplitSupplierInvoiceDocumentSaga),
		fork(downloadSplitSupplierInvoiceSaga),
		fork(deleteSupplierInvoiceDocumentPageSaga)
	]);
}
