import Api from 'services/api';
import {
	JobFinanceExpandParam,
	MoveServiceRequest
} from 'services/api/finance/financeServiceTypes';
import {
	moveServiceAsync,
	closeMoveServiceModal
} from '../actions/moveService';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';
import { getMoveServiceId } from '../selectors';
import { retrieveFinance } from '../actions/retrieveFinance';
import { navigateTo } from 'utils';

const apiCall = Api.Finance.moveService;

export const executor = function*(
	payload: MoveServiceRequest,
	api: typeof apiCall
): SagaIterator {
	yield put(moveServiceAsync.started(payload));

	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	const jobServiceId = yield select(getMoveServiceId);

	const request = {
		portCallId,
		jobCode,
		sectionCode: payload.sectionCode,
		serviceCode: payload.serviceCode,
		jobServiceId,
		entityId: payload.entityId
	} as MoveServiceRequest;
	try {
		const response = yield call(api, request);
		yield put(
			moveServiceAsync.done({
				result: null,
				params: payload,
				response
			})
		);
	} catch (error) {
		yield put(
			moveServiceAsync.failed({
				error,
				params: payload
			})
		);
	}
	yield put(closeMoveServiceModal());
	yield call(navigateTo, `/portcalls/${portCallId}/jobs/${jobCode}/finance`);
	yield put(
		retrieveFinance({
			portCallId: portCallId,
			jobCode,
			expand: [JobFinanceExpandParam.ADDITIONAL_DOCUMENTS]
		})
	);
};

function* worker({ payload }: Action<MoveServiceRequest>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function* moveServiceWatcher(): SagaIterator {
	yield takeLatest(moveServiceAsync.type, worker);
}
