import {
	RetrieveCompaniesContactDetailsRequest,
	RetrieveCompaniesContactDetailsResponse,
	RetrieveCompanyVesselTypesResponse,
	RetrieveCompanyVesselTypesRequest,
	RetrieveCompanyVesselsRequest,
	RetrieveCompanyVesselRequest,
	RetrieveCompanyVesselsResponse,
	RetrieveCompanyVesselResponse,
	UpdateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeRequest,
	CreateCompanyVesselTypeResponse,
	UpdateCompanyVesselTypeResponse,
	DeleteCompanyVesselTypeRequest,
	CreateCompanyVesselRequest,
	UpdateCompanyVesselRequest,
	DeleteCompanyVesselRequest,
	RetrievePrincipalIntegrationSettingsRequest,
	ConfigurationBenchmarkData,
	PrincipalIntegrationSettings,
	RetrieveConfigurationBenchmarkRequest,
	UpdateConfigurationBenchmarkRequest,
	RetrieveExtendedMessagingRequest,
	ConfigurationExtendedMessagingData,
	UpdateConfigurationExtendedMessageRequest,
	RetrieveCompanyJobTypesRequest,
	RetrieveCompanyJobTypesResponse,
	RetrieveCompanyVesselByIdResponse,
	RetrieveCompanyVesselByIdRequest,
	MainContactResponse,
	RetrieveConfigurationFeedbackManagementRequest,
	ConfigurationFeedbackManagementData,
	UpdateConfigurationFeedbackManagementRequest,
	EventListAndOpticVariablesData,
	CreateFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse,
	FeedbackManagementRequestId
} from './companiesServiceTypes';
import { DEFAULT_LIST_LIMIT } from 'app-constants';
import { CompanyVessel } from 'services/api/vessels/vesselsServiceTypes';
import { AutocompleteOption } from 'components/antd/Autocomplete/Autocomplete';
import client from 'services/client';
import queryString from 'query-string';
import {
	AxiosTypedPromise,
	ResultCollection,
	SearchResultCollection
} from 'services/api/apiTypes';
import {
	Company,
	RetrieveCompaniesRequest,
	RetrieveCompaniesConfigurationSettingsResponse,
	RetrieveCompaniesForUserRequest,
	SearchCompaniesRequest,
	OrganisationType,
	SearchVesselsByCompanyRequest,
	RetrieveCompanyGeneralConfigurationRequest,
	RetrieveCompanyGeneralConfigurationResponse,
	RetrieveCompanyGeneralConfigurationReportsRequest,
	RetrieveCompanyGeneralConfigurationReportsResponse,
	UpdateCompanyGeneralConfigurationRequest,
	UploadCompanyPreferredAgentsFileRequest,
	RetrieveCompanyPreferredAgentsFileInfoRequest,
	RetrieveCompanyPreferredAgentsFileInfoResponse,
	RetrieveCompaniesResponse,
	RetrievePrincipalsForMainPrincipalRequest
} from 'services/api/companies/companiesServiceTypes';
import { SearchEntitiesForAutocompleteResponse } from 'containers/Autocomplete/AutocompleteTypes';
import { PermissionCode } from '../permissions/permissionsServiceTypes';
import { uniqBy } from 'lodash';

const retrieveCompanies = (request: RetrieveCompaniesForUserRequest) =>
	client.get<RetrieveCompaniesResponse>('/companies', {
		params: request,
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'none' })
	});

const retrieveCompaniesForUser = (request: RetrieveCompaniesForUserRequest) =>
	client.get<RetrieveCompaniesResponse>('/companies/for-user', {
		params: request
	});

const retrieveCompanyWithOrganisationType = (type: string) => ({
	organisationTypes,
	actionType,
	ids,
	onlyActive,
	withParentId,
	limit,
	index,
	sortBy,
	forOperationalDashboard
}: RetrieveCompaniesRequest) =>
	client.get<RetrieveCompaniesResponse>(`/companies/${type}`, {
		params: {
			organisationTypes,
			actionType,
			ids,
			onlyActive,
			withParentId,
			limit,
			index,
			sortBy,
			forOperationalDashboard
		},
		paramsSerializer: requestParams =>
			queryString.stringify(requestParams, { arrayFormat: 'index' })
	});

const retrievePrincipalsForMainPrincipal = ({
	mainPrincipalId,
	...params
}: RetrievePrincipalsForMainPrincipalRequest) =>
	client.get<RetrieveCompaniesResponse>(
		`/companies/mainprincipals/${mainPrincipalId}/principals`,
		{ params }
	);

const retrieveCompany = (companyId: string) =>
	client.get<Company>(`/companies/${companyId}`);

const retrieveCompaniesConfigurationSettings = () =>
	client.get<RetrieveCompaniesConfigurationSettingsResponse>(
		`/companies/configuration/settings`
	);

const retrieveCompanyJobTypes = ({
	companyId
}: RetrieveCompanyJobTypesRequest) =>
	client.get<RetrieveCompanyJobTypesResponse>(
		`/companies/${companyId}/configuration/getJobTypeInformation`
	);

const retrieveCompanyType = {
	[OrganisationType.ISS]: retrieveCompanyWithOrganisationType('iss'),
	[OrganisationType.OPERATING_ENTITY]: retrieveCompanyWithOrganisationType(
		'operatingentity'
	),
	[OrganisationType.LPA]: retrieveCompanyWithOrganisationType('thirdpartylpa'),
	[OrganisationType.ISS_LPA]: retrieveCompanyWithOrganisationType('lpas'),
	[OrganisationType.MAIN_PRINCIPAL]: retrieveCompanyWithOrganisationType(
		'mainPrincipals'
	),
	[OrganisationType.PRINCIPAL]: retrieveCompanyWithOrganisationType(
		'principals'
	),
	[OrganisationType.CHARTERER]: retrieveCompanyWithOrganisationType(
		'charterers'
	),
	[OrganisationType.SHIPPER]: retrieveCompanyWithOrganisationType('shippers'),
	[OrganisationType.VENDOR]: retrieveCompanyWithOrganisationType('vendor')
};

const searchCompanies = (request: SearchCompaniesRequest) =>
	client.get<SearchResultCollection>('/companies/search', {
		params: request,
		paramsSerializer: params =>
			queryString.stringify(params, { arrayFormat: 'none' })
	});

const searchVendors = (searchTerm: string) =>
	client.get<{ results: AutocompleteOption[] }>(`/companies/search`, {
		params: {
			limit: DEFAULT_LIST_LIMIT,
			searchTerm,
			types: OrganisationType.VENDOR
		}
	});

const searchCompaniesForAutocomplete = (request: SearchCompaniesRequest) =>
	searchCompanies(request).then(
		(response): AutocompleteOption[] => response.data.results
	);

const searchVendorsForAutocomplete = (searchTerm: string) =>
	searchVendors(searchTerm).then((response): AutocompleteOption[] => {
		return response.data.results;
	});

const searchMainPrincipalsForAutocomplete = (
	searchTerm?: string,
	otherParams?: { actionType: PermissionCode }
): AxiosTypedPromise<SearchEntitiesForAutocompleteResponse> =>
	client.get('/companies/mainPrincipals/search', {
		params: {
			searchTerm,
			...(otherParams && { actionType: otherParams.actionType })
		}
	});

const searchPerformingAgentForAutocomplete = (
	searchTerm?: string
): AxiosTypedPromise<SearchEntitiesForAutocompleteResponse> =>
	client.get('/companies/search-performing-agents', {
		params: {
			searchTerm,
			limit: DEFAULT_LIST_LIMIT
		}
	});

const searchAssignedToForAutocomplete = (
	searchTerm?: string
): AxiosTypedPromise<SearchEntitiesForAutocompleteResponse> =>
	client.get('/users/search-da-assignees', {
		params: {
			searchTerm,
			limit: DEFAULT_LIST_LIMIT
		}
	});

const searchMainContactForAutoComplete = (searchTerm?: string) =>
	client
		.get('/companies/mainContacts/search', {
			params: {
				searchTerm,
				limit: DEFAULT_LIST_LIMIT
			}
		})
		.then((result): AutocompleteOption[] =>
			result.data.elements.map(({ id, fullName }: MainContactResponse) => ({
				id: id,
				name: fullName
			}))
		);

const retrieveCompaniesContactDetails = (
	request: RetrieveCompaniesContactDetailsRequest
) =>
	client.get<RetrieveCompaniesContactDetailsResponse>(
		'/companies/contact-details',
		{
			params: request
		}
	);

const searchCompanyVesselsForAutocomplete = ({
	searchTerm,
	companyId,
	...request
}: SearchVesselsByCompanyRequest) => {
	/**
	 * TODO: For the sprint 31, we should use `SearchResultCollection<Vessel>` as a type
	 * instead of AxiosTypedPromise<ResultCollection<Vessel>>
	 */

	const response: AxiosTypedPromise<ResultCollection<
		CompanyVessel
	>> = client.get(`companies/${companyId}/vessels/search`, {
		params: {
			searchTerm,
			...request
		}
	});
	return response.then((result): AutocompleteOption[] =>
		result.data.elements.map(({ id, imo, vessel, externalCode }) => ({
			id: id,
			name: externalCode
				? `${vessel.name} (${imo}) - (${externalCode})`
				: `${vessel.name} (${imo})`
		}))
	);
};

const searchCompanyUniqVesselsForAutocomplete = ({
	searchTerm,
	companyId,
	...request
}: SearchVesselsByCompanyRequest) => {
	const response: AxiosTypedPromise<ResultCollection<
		CompanyVessel
	>> = client.get(`companies/${companyId}/vessels/search`, {
		params: {
			searchTerm,
			...request
		}
	});
	return response.then((result): AutocompleteOption[] =>
		uniqBy(
			result.data.elements.map(({ imo, vessel }) => ({
				id: vessel.id,
				name: `${vessel.name} (${imo})`
			})),
			'id'
		)
	);
};

const retrieveCompanyGeneralConfiguration = ({
	companyId
}: RetrieveCompanyGeneralConfigurationRequest) =>
	client.get<RetrieveCompanyGeneralConfigurationResponse>(
		`companies/${companyId}/configuration/general`
	);

const retrieveCompanyGeneralConfigurationReports = ({
	companyId
}: RetrieveCompanyGeneralConfigurationReportsRequest) =>
	client.get<RetrieveCompanyGeneralConfigurationReportsResponse>(
		`companies/${companyId}/configuration/general/reports`
	);

const updateCompanyGeneralConfiguration = ({
	companyId,
	...data
}: UpdateCompanyGeneralConfigurationRequest) =>
	client.put(`companies/${companyId}/configuration/general`, data);

const retrieveCompanyVesselTypes = ({
	companyId
}: RetrieveCompanyVesselTypesRequest) =>
	client.get<RetrieveCompanyVesselTypesResponse>(
		`companies/${companyId}/vessel-types`
	);

const createCompanyVesselType = ({
	companyId,
	data
}: CreateCompanyVesselTypeRequest) =>
	client.post<CreateCompanyVesselTypeResponse>(
		`/companies/${companyId}/vessel-types`,
		data
	);

const updateCompanyVesselType = ({
	id,
	companyId,
	data
}: UpdateCompanyVesselTypeRequest) =>
	client.patch<UpdateCompanyVesselTypeResponse>(
		`/companies/${companyId}/vessel-types/${id}`,
		data
	);

const deleteCompanyVesselType = ({
	companyId,
	id
}: DeleteCompanyVesselTypeRequest) =>
	client.delete(`/companies/${companyId}/vessel-types/${id}`);

const retrieveCompanyVessels = ({
	companyId,
	...params
}: RetrieveCompanyVesselsRequest) =>
	client.get<RetrieveCompanyVesselsResponse>(
		`companies/${companyId}/vessels/page`,
		{ params }
	);

const retrieveCompanyVessel = ({
	companyId,
	vesselId
}: RetrieveCompanyVesselRequest) =>
	client.get<RetrieveCompanyVesselResponse>(
		`companies/${companyId}/vessels/${vesselId}`
	);

const retrieveCompanyVesselById = ({
	companyId,
	id
}: RetrieveCompanyVesselByIdRequest) =>
	client.get<RetrieveCompanyVesselByIdResponse>(
		`companies/${companyId}/vessels/${id}/byId`
	);

const createCompanyVessel = ({ companyId, data }: CreateCompanyVesselRequest) =>
	client.post(`companies/${companyId}/vessels`, data);

const updateCompanyVessel = ({
	companyId,
	companyVesselId,
	data
}: UpdateCompanyVesselRequest) =>
	client.put(`companies/${companyId}/vessels/${companyVesselId}`, data);

const deleteCompanyVessel = ({ companyId, id }: DeleteCompanyVesselRequest) =>
	client.delete(`/companies/${companyId}/vessels/${id}`);

const uploadCompanyPreferredAgentsFile = ({
	companyId,
	documentId,
	fileName
}: UploadCompanyPreferredAgentsFileRequest) =>
	client.get(
		`companies/${companyId}/configuration/preferred-agents/${documentId}/${fileName}`
	);

const retrieveCompanyPreferredAgentsFileInfo = ({
	companyId
}: RetrieveCompanyPreferredAgentsFileInfoRequest) =>
	client.get<RetrieveCompanyPreferredAgentsFileInfoResponse>(
		`companies/${companyId}/configuration/preferred-agents/file-info`
	);

const retrievePrincipalIntegrationSettings = ({
	companyId
}: RetrievePrincipalIntegrationSettingsRequest) =>
	client.get<PrincipalIntegrationSettings>(
		`companies/${companyId}/configuration/general/principal-integration`
	);

const retrieveBenchmarkConfiguration = ({
	companyId
}: RetrieveConfigurationBenchmarkRequest) =>
	client.get<ConfigurationBenchmarkData>(
		`companies/${companyId}/configuration/benchmarks`
	);

const updateBenchmarkConfiguration = ({
	companyId,
	data
}: UpdateConfigurationBenchmarkRequest) =>
	client.put<ConfigurationBenchmarkData>(
		`companies/${companyId}/configuration/benchmarks`,
		data
	);

const retrieveFeedbackManagementConfiguration = ({
	companyId
}: RetrieveConfigurationFeedbackManagementRequest) =>
	client.get<ConfigurationFeedbackManagementData>(
		`companies/${companyId}/configuration/feedbackmanagement`
	);

const retrieveEventListAndOpticVariables = () =>
	client.get<EventListAndOpticVariablesData>(
		`masterdata/feedbackManagement/GetEventsListandOpticVariables`
	);

const retrieveFeedbackManagementGrid = ({
	companyId
}: RetrieveConfigurationFeedbackManagementRequest) =>
	client.get<RetrieveFeedbackManagementGridResponse[]>(
		`masterdata/feedbackManagement/companies/${companyId}`
	);

const deleteFeedbackManagementRequest = ({
	feedbackId
}: FeedbackManagementRequestId) =>
	client.delete(`masterdata/feedbackManagement/${feedbackId}`);

const downloadFeedbackManagementDocuments = ({
	feedbackId
}: FeedbackManagementRequestId) =>
	client.get(`/masterdata/feedbackManagement/downloadDocuments/${feedbackId}`);

const createFeedbackManagementRequest = ({
	companyId,
	...params
}: CreateFeedbackManagementRequest) =>
	client.post(`masterdata/feedbackManagement/companies/${companyId}`, params);

const updateFeedbackManagementRequest = ({
	companyId,
	...params
}: CreateFeedbackManagementRequest) =>
	client.put(`masterdata/feedbackManagement/companies/${companyId}`, params);

const updateFeedbackManagementConfiguration = ({
	companyId,
	data
}: UpdateConfigurationFeedbackManagementRequest) =>
	client.put<ConfigurationFeedbackManagementData>(
		`companies/${companyId}/configuration/feedbackmanagement`,
		data
	);

const retrieveExtendedMessagingConfiguration = ({
	companyId
}: RetrieveExtendedMessagingRequest) =>
	client.get<ConfigurationExtendedMessagingData>(
		`companies/${companyId}/configuration/extendedmessaging`
	);

const updateExtendedMessagingConfiguration = ({
	companyId,
	data
}: UpdateConfigurationExtendedMessageRequest) =>
	client.put<ConfigurationExtendedMessagingData>(
		`companies/${companyId}/configuration/extendedmessaging`,
		data
	);

export default {
	retrieveCompanies,
	retrieveCompaniesForUser,
	retrieveCompany,
	retrieveCompanyVessel,
	retrieveCompanyVesselById,
	retrieveCompanyType,
	retrieveCompaniesConfigurationSettings,
	searchCompanies,
	searchVendorsForAutocomplete,
	retrievePrincipalsForMainPrincipal,
	createCompanyVessel,
	updateCompanyVessel,
	searchCompaniesForAutocomplete,
	retrieveCompaniesContactDetails,
	searchCompanyVesselsForAutocomplete,
	searchMainPrincipalsForAutocomplete,
	searchPerformingAgentForAutocomplete,
	retrieveCompanyGeneralConfiguration,
	updateCompanyGeneralConfiguration,
	retrieveCompanyGeneralConfigurationReports,
	retrieveCompanyVesselTypes,
	createCompanyVesselType,
	updateCompanyVesselType,
	deleteCompanyVesselType,
	retrieveCompanyVessels,
	deleteCompanyVessel,
	uploadCompanyPreferredAgentsFile,
	retrieveCompanyPreferredAgentsFileInfo,
	retrievePrincipalIntegrationSettings,
	retrieveBenchmarkConfiguration,
	updateBenchmarkConfiguration,
	retrieveFeedbackManagementConfiguration,
	retrieveEventListAndOpticVariables,
	createFeedbackManagementRequest,
	updateFeedbackManagementRequest,
	retrieveFeedbackManagementGrid,
	updateFeedbackManagementConfiguration,
	downloadFeedbackManagementDocuments,
	deleteFeedbackManagementRequest,
	retrieveExtendedMessagingConfiguration,
	updateExtendedMessagingConfiguration,
	retrieveCompanyJobTypes,
	searchAssignedToForAutocomplete,
	searchCompanyUniqVesselsForAutocomplete,
	searchMainContactForAutoComplete
};
