import { Action } from 'typescript-fsa';
import { takeLatest, put } from 'redux-saga/effects';

import { uploadDirectBillsDocumentationDone } from '../actions';

import { cancelUploadAndProcessDocument } from 'store/documents/actions';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

export default function*() {
	yield takeLatest(uploadDirectBillsDocumentationDone.type, function*({
		payload: { isDoneSuccess }
	}: Action<UploadDocumentDoneResponse>) {
		if (isDoneSuccess) {
			// Reset state
			yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));
		}
	});
}
