import { ConfigurationFeedbackManagementData } from 'services/api/companies/companiesServiceTypes';
import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';

export const onRetrieveFeedbackManagementSuccess = (
	state: ConfigurationState,
	{ result }: Success<null, ConfigurationFeedbackManagementData>
) => ({
	...state,
	isFeedbackManagementEnabled: result.isFeedbackManagementEnabled
});

export const onRetrieveConfigurationFeedbackManagement = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	null,
	ConfigurationFeedbackManagementData
>('fetchStatuses', 'retrieveFeedbackManagement', {
	doneReducer: onRetrieveFeedbackManagementSuccess
});
