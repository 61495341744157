import actionCreator from '../threadsActionCreator';
import {
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse
} from 'services/api/threads/threadsServiceTypes';
const ACTION_NAME = 'RETRIEVE_THREADS_PENDING_ALERTS';

export const retrieveThreadsPendingAlerts = actionCreator<
	RetrieveThreadsPendingAlertsRequest
>(ACTION_NAME);
export const retrieveThreadsPendingAlertsAsync = actionCreator.async<
	RetrieveThreadsPendingAlertsRequest,
	RetrieveThreadsPendingAlertsResponse,
	Error
>(ACTION_NAME);
