import { Action } from 'typescript-fsa';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { registerField, initialize } from 'redux-form';

import { openModal } from 'store/modals/actions';
import { openConfigurationFileModal } from '../../actions';

import { getConfigurationFileFormDataForInit } from '../../selectors';

import {
	CONFIGURATION_FILE_FORM_NAME,
	DEFAULT_VALUE,
	ConfigurationFileFormField,
	ConfigurationFileFormData
} from '../../configurationFilesTypes';
import { FieldType } from 'store/form/formTypes';

export function* worker({ payload: fileId }: Action<string>) {
	if (!fileId) {
		// initialize UPLOAD
		const initialValues: Partial<ConfigurationFileFormData> = {
			principals: [DEFAULT_VALUE],
			ports: [{ key: DEFAULT_VALUE, label: DEFAULT_VALUE }],
			mainCommodities: [DEFAULT_VALUE],
			messageTypes: [DEFAULT_VALUE]
		};
		yield all([
			put(
				// register field in order for validation to have effect
				registerField(
					CONFIGURATION_FILE_FORM_NAME,
					ConfigurationFileFormField.DOCUMENT_ID,
					FieldType.FIELD
				)
			),
			put(initialize(CONFIGURATION_FILE_FORM_NAME, initialValues))
		]);
	} else {
		const initialValues: ConfigurationFileFormData = yield select(
			getConfigurationFileFormDataForInit,
			fileId
		);

		// initialize UPDATE
		yield put(initialize(CONFIGURATION_FILE_FORM_NAME, initialValues));
	}

	yield put(openModal({ name: CONFIGURATION_FILE_FORM_NAME }));
}

export default function*() {
	yield takeLatest(openConfigurationFileModal.type, worker);
}
