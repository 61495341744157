import * as React from 'react';
import AntCheckboxGroup, { CheckboxGroupProps } from 'antd/lib/checkbox/Group';

/**
 * TODO extends with ReduxFormItem
 */
export class CheckboxGroup extends React.Component<CheckboxGroupProps> {
	render() {
		return <AntCheckboxGroup {...this.props} />;
	}
}

export default CheckboxGroup;
