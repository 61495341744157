import { Success } from 'typescript-fsa';
import {
	RetrieveFinanceRequest,
	RetrieveFinanceResponse
} from 'services/api/finance/financeServiceTypes';
import { ContextBarsState } from '../contextBarsState';

export const onRetrieveFinanceReducer = function(
	state: ContextBarsState,
	action: Success<RetrieveFinanceRequest, RetrieveFinanceResponse>
) {
	const { contextBars } = action.result;
	if (contextBars && contextBars.length > 0) {
		return {
			...state,
			list: contextBars
		};
	}
	return state;
};
