import runValidations, {
	validateRequired,
	charMax,
	email as emailValidation,
	phoneNumber,
	required,
	requiredOneOfTwo
} from 'utils/validations';
import { FormErrors } from 'redux-form';
import { first } from 'lodash';
import {
	AddAppointerDraftFormDate,
	AddCompanyDraftFormData
} from './constants';

const validateNameField = (value: string) =>
	first(runValidations(value, [charMax(240), required]));

const validateCityField = (value: string) =>
	first(runValidations(value, [charMax(35), required]));

const validateEmailField = (value: string, telephone: string) =>
	first(
		runValidations(value, [
			charMax(128),
			emailValidation,
			requiredOneOfTwo(telephone)
		])
	);

const validatePhoneField = (value: string, email: string) =>
	first(
		runValidations(value, [charMax(20), phoneNumber, requiredOneOfTwo(email)])
	);

export const formValidate = ({
	name,
	city,
	countryCode,
	email,
	telephone,
	note
}: AddCompanyDraftFormData): FormErrors<AddCompanyDraftFormData> => ({
	name: validateNameField(name),
	city: validateCityField(city),
	countryCode: validateRequired(countryCode),
	email: validateEmailField(email, telephone),
	telephone: validatePhoneField(telephone, email),
	note: charMax(1000)(note)
});

export const appointerFormValidate = ({
	name,
	city,
	countryCode,
	email,
	telephone,
	companyOrganizationTypeCode,
	note
}: AddAppointerDraftFormDate): FormErrors<AddAppointerDraftFormDate> => ({
	name: validateNameField(name),
	city: validateCityField(city),
	countryCode: validateRequired(countryCode),
	email: validateEmailField(email, telephone),
	telephone: validatePhoneField(telephone, email),
	companyOrganizationTypeCode: validateRequired(companyOrganizationTypeCode),
	note: charMax(1000)(note)
});
