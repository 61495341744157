import React, { useState } from 'react';
import { Modal } from 'components/antd';
import {
	WEB_HOOK_END_POINT_FORM,
	WEB_HOOK_END_POINT_MODAL
} from '../../constants';
import { useSelector, shallowEqual } from 'react-redux';
import { getFormValues, isValid, isDirty } from 'redux-form';
import { WebhookEndPointDetailsWithLabel } from 'services/api/developerPortal/developerPortalServiceTypes';
import { getWebHookModalValues } from 'store/developerPortal/selectors';
import { isModalVisible } from 'store/modals/selectors';
import { AppState } from 'store-types';
import WebHookDetailsForm from './WebHookDetailsForm';

interface WebHookDetailsModalProps {
	onSubmit: (webHook: WebhookEndPointDetailsWithLabel) => void;
	hideModal: () => void;
}

const WebHookDetailsModal = ({
	hideModal,
	onSubmit
}: WebHookDetailsModalProps) => {
	const [isWarningVisible, setWarningVisible] = useState(false);
	const formValues: WebhookEndPointDetailsWithLabel = useSelector(
		getFormValues(WEB_HOOK_END_POINT_FORM),
		shallowEqual
	);
	const isFormValid = useSelector(isValid(WEB_HOOK_END_POINT_FORM));
	const isFormChanged = useSelector(isDirty(WEB_HOOK_END_POINT_FORM));
	const isVisible = useSelector((state: AppState) =>
		isModalVisible(state, WEB_HOOK_END_POINT_MODAL)
	);

	const { isEditing } = useSelector(getWebHookModalValues);

	const onOk = () => {
		onSubmit(formValues);
		hideModal();
	};

	const onCancel = () => {
		if (isFormChanged) {
			setWarningVisible(true);
		} else {
			hideModal();
		}
	};

	const onWarningOk = () => {
		setWarningVisible(false);
		hideModal();
	};

	return (
		<>
			<Modal
				visible={isVisible}
				size="md"
				title={isEditing ? 'Edit Web-hook endpoint' : 'Add Web-hook end point'}
				onCancel={onCancel}
				okText={isEditing ? 'Update endpoint' : 'Add endpoint'}
				okDisabled={!isFormValid || !isFormChanged}
				onOk={onOk}
				destroyOnClose
			>
				{isVisible && <WebHookDetailsForm />}
			</Modal>
			<Modal
				visible={isWarningVisible}
				okText="Yes, Cancel"
				cancelText="No, return"
				zIndex={1001}
				onOk={onWarningOk}
				onCancel={() => setWarningVisible(false)}
			>
				Are you sure you want to cancel the process? All entered data will be
				lost
			</Modal>
		</>
	);
};

export default WebHookDetailsModal;
