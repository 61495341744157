import { Action } from 'typescript-fsa';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { select, takeLatest, call } from 'redux-saga/effects';
import { getGroupUsersCount } from '../groupUsersSelectors';
import { DEFAULT_LIST_LIMIT } from 'app-constants';

import {
	RetrieveGroupUsersResponse,
	RetrieveGroupUsersRequest
} from 'services/api/groups/groupsServiceTypes';
import { retrieveGroupUsersAsync as async } from 'store/groupUsers/actions/retrieveGroupUsers';
import Api from 'services/api';

const api = Api.Groups.retrieveUsersFromGroup;
const executor = makeDefaultExecutor<
	RetrieveGroupUsersRequest,
	RetrieveGroupUsersResponse,
	Error
>({
	api,
	async
});

function* worker({ payload, meta }: Action<RetrieveGroupUsersRequest>) {
	const count = yield select(getGroupUsersCount, payload.id);

	const request: RetrieveGroupUsersRequest = {
		...payload,
		index: count,
		limit: DEFAULT_LIST_LIMIT,
		sortBy: 'lastActivityOn:desc'
	};
	yield call(executor, request, meta);
}

export default function*() {
	yield takeLatest(async.type, worker);
}
