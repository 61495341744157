import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	PortJobBaseRequest,
	PortJobAvailableGroup
} from 'services/api/portJobs/portJobsServiceTypes';
import { ResultCollection } from 'services/api/apiTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

const onRetrieveAvailableGroupsSuccess = (
	state: PortJobState,
	{
		result
	}: Success<PortJobBaseRequest, ResultCollection<PortJobAvailableGroup>>
) => ({
	...state,
	availableGroups: result.elements
});

export const onRetrieveAvailableGroups = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	PortJobBaseRequest,
	ResultCollection<PortJobAvailableGroup>
>('fetchStatuses', 'availableGroups', {
	doneReducer: onRetrieveAvailableGroupsSuccess
});
