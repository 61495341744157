import * as React from 'react';
import { connect } from 'react-redux';
import { navigateTo } from 'utils';
import styles from './MessagesList.module.scss';

import { Tabs, Select, Row, Col } from 'components/antd';
import {
	ScrollableLayout,
	Content,
	Flex,
	Filters,
	AutocompleteSearch
} from 'components';
import ThreadsList from 'sections/ThreadsList/ThreadsList';
import MessageRow from '../MessageRow/MessageRow';

import {
	retrievePortJobThreads,
	retrievePortJobThreadsCycleStart,
	retrievePortJobThreadsCycleStop,
	resetActivePortJobThreadId,
	resetPortJobThreads
} from 'store/portJobs/actions';
import { setActivePortJobThreadId } from 'store/portJobs/actions/setActivePortJobThreadId';
import { resetThreads } from 'store/threads/actions';
import { retrieveThreadById } from 'store/thread';

import {
	setPortCallThreadsFilters,
	resetPortCallThreadsFilters
} from 'store/portCall/actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import {
	getThreadsInPortJob,
	getActivePortJobThreadId
} from 'store/portJobs/selectors';

import { AppState } from 'store-types';
import { PortJobThread } from 'services/api/portJobs/portJobsServiceTypes';
import { Justify } from 'components/types';
import { SHOW_ALL_JOBS_KEY } from 'sections/PortCall/PortJob/PortJobConstants';
import { MessagesFilterTabs, MessagesTabsEnum } from './MessagesListTypes';
import { searchTypeMap } from 'store/threads/filtersSync';
import { threadsStatusesOptions } from 'types/sections/ThreadsTypes';
import {
	PortCallMessagesFilterParamName,
	PortCallMessagesFiltersParams
} from 'store/portJobs/portCallMessagesFiltersSync';
import { FilterState, FilterItem } from 'store/filters/filtersState';
import {
	getMessagesFilterFieldValues,
	getDefaultFieldValues
} from './MessagesFiltersSelectors';
import { ThreadSearchGroupType } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';
import { UserSearchGroupType } from 'services/api/users/userServiceTypes';
import { searchEntitiesForPortJobAutocompleteSearch } from 'components/AutocompleteSearch/AutocompleteSearch.func';

interface MessagesListProps {
	onChange: (filters: FilterState, override?: boolean) => void;
	clearOne: (filter: FilterItem) => void;
	clearAll: () => void;
	filtersResult: FilterItem[];
	// from mapStateToProps
	values: PortCallMessagesFiltersParams;
	threads: PortJobThread[];
	activePortCallId: string;
	activePortJobCode: string;
	activePortJobThreadId: string;
	// from mapDispatchToProps
	retrievePortJobThreads: typeof retrievePortJobThreads;
	retrievePortJobThreadsCycleStart: typeof retrievePortJobThreadsCycleStart;
	retrievePortJobThreadsCycleStop: typeof retrievePortJobThreadsCycleStop;
	setActivePortJobThreadId: typeof setActivePortJobThreadId;
	resetThreads: typeof resetThreads;
	resetPortJobThreads: typeof resetPortJobThreads;
	resetActivePortJobThreadId: typeof resetActivePortJobThreadId;
	retrieveThreadById: typeof retrieveThreadById;
	setPortCallThreadsFilters: typeof setPortCallThreadsFilters;
	resetPortCallThreadsFilters: typeof resetPortCallThreadsFilters;
}

class MessagesList extends React.Component<MessagesListProps> {
	defaultValues: PortCallMessagesFiltersParams = getDefaultFieldValues();

	private loadThreads = (haveToResetThreads = false) => {
		const { activePortCallId, threads, activePortJobCode } = this.props;
		const params = {
			portCallId: activePortCallId,
			jobCode: activePortJobCode !== SHOW_ALL_JOBS_KEY ? activePortJobCode : '',
			limit: 10,
			index: haveToResetThreads ? 0 : threads.length
		};
		this.props.retrievePortJobThreads(params);
		this.props.retrievePortJobThreadsCycleStart(params);
	};

	private onSearch = (searchTerm: string, groupType?: UserSearchGroupType) => {
		const { activePortCallId, activePortJobCode } = this.props;

		const portJobCode =
			activePortJobCode !== SHOW_ALL_JOBS_KEY ? activePortJobCode : '';

		return searchEntitiesForPortJobAutocompleteSearch(
			Api.Threads.searchPortJobThreads,
			searchTerm,
			groupType,
			portJobCode,
			activePortCallId
		);
	};

	componentDidUpdate(prevProps: MessagesListProps) {
		const {
			activePortJobThreadId,
			activePortCallId,
			activePortJobCode
		} = this.props;
		if (
			activePortJobThreadId &&
			activePortJobThreadId !== prevProps.activePortJobThreadId
		) {
			navigateTo(
				`/portcalls/${activePortCallId}/jobs/${activePortJobCode}/messages/${activePortJobThreadId}`
			);
		}
	}

	componentWillUnmount() {
		// this prevents any other consumers of threads from receiving confusing data
		this.props.resetThreads();
		this.props.resetPortJobThreads();
		this.props.resetActivePortJobThreadId();
		this.props.retrievePortJobThreadsCycleStop();
		this.props.resetPortCallThreadsFilters();
	}

	onFilterSelect = (
		filter: FilterItem,
		name: PortCallMessagesFilterParamName,
		override = true
	) => {
		if (!this.beforeSelect(filter, name)) {
			return;
		}
		this.props.onChange(
			{
				[name]: [filter]
			},
			override
		);
	};

	/**
	 * Select will be stopped if `false` returned
	 * @param {FilterItem} value
	 * @param {PortCallMessagesFilterParamName} name
	 */
	beforeSelect = (value: FilterItem, name: PortCallMessagesFilterParamName) => {
		const defaultValue = this.defaultValues[name] as FilterItem;
		// - prevent putting default value to the store
		// to not show default filters as tags that may be reset if removed
		// - clear current filter to fallback to default
		if (defaultValue && value.key === defaultValue.key) {
			this.props.clearOne(this.props.values[name] as FilterItem);
			return false;
		}
		return true;
	};

	private getListFilters = (): MessagesFilterTabs[] => [
		{ tab: 'All', key: MessagesTabsEnum.All },
		{ tab: 'Unread', key: MessagesTabsEnum.Unread }
	];

	// We have to use wrapper here for parameters compatibility inside the loadThreads
	onEnterThreads = () => {
		this.loadThreads();
	};

	private onSelectThread = (activeThread: string) => {
		this.props.setActivePortJobThreadId(activeThread);
	};

	onChangeSearch = (filter: FilterItem, groupType: ThreadSearchGroupType) => {
		let key = filter.key;
		if (groupType === ThreadSearchGroupType.Search) {
			key = filter.label;
		}

		this.onFilterSelect(
			{ ...filter, type: groupType, key },
			PortCallMessagesFilterParamName.SEARCH,
			false
		);
	};

	filterByOnlyUnread = (newTab: MessagesTabsEnum) => {
		this.onFilterSelect(
			{ key: newTab, label: '', type: '' },
			PortCallMessagesFilterParamName.ONLY_UNREAD
		);
	};

	onChangeStatus = (filter: FilterItem) => {
		this.onFilterSelect(
			{ ...filter, type: '' },
			PortCallMessagesFilterParamName.STATUS
		);
	};

	render() {
		const {
			values,
			threads,
			activePortJobThreadId,
			clearAll,
			clearOne,
			filtersResult
		} = this.props;
		const tabs = this.getListFilters();
		return (
			<Content className={styles.contentWrapper}>
				<ScrollableLayout>
					<Row>
						<Col xs={12}>
							<AutocompleteSearch
								onSearch={this.onSearch}
								onChange={this.onChangeSearch}
								placeholder="Search in Messages"
								typeMap={searchTypeMap}
								minLength={3}
							/>
							<Filters.Tags
								tags={filtersResult}
								clearOne={clearOne}
								clearAll={clearAll}
								typeMap={searchTypeMap}
							/>
						</Col>
					</Row>
					<ScrollableLayout stretch className={styles.scrollableLayout}>
						<Flex
							className={styles.tabsContainer}
							direction="horizontal"
							justify={Justify.BETWEEN}
						>
							<Tabs
								fit
								borderless
								activeKey={values.onlyUnread}
								onChange={this.filterByOnlyUnread}
								className={styles.tabs}
							>
								{tabs.map(tab => (
									<Tabs.TabPane tab={tab.tab} key={tab.key} />
								))}
							</Tabs>
							<Flex none self="center" shrink={0}>
								<Select
									value={values.status}
									labelInValue
									onChange={this.onChangeStatus}
									className={styles.statusSelect}
								>
									{threadsStatusesOptions.map(option => (
										<Select.Option key={option.key} value={option.key}>
											{option.key}
										</Select.Option>
									))}
								</Select>
							</Flex>
						</Flex>
						<ThreadsList
							onEnter={this.onEnterThreads}
							emptyMessage="No threads are assigned to this Job"
							skipDoneLoadingMessage
							noPadding
							actionType={retrievePortJobThreads.type}
						>
							{threads.map(thread => (
								<MessageRow
									thread={thread}
									key={thread.id}
									onSelect={this.onSelectThread}
									active={activePortJobThreadId === thread.id}
								/>
							))}
						</ThreadsList>
					</ScrollableLayout>
				</ScrollableLayout>
			</Content>
		);
	}
}
export default connect(
	(state: AppState) => ({
		values: getMessagesFilterFieldValues(state),
		threads: getThreadsInPortJob(state),
		activePortCallId: getActivePortCallId(state),
		activePortJobCode: getActivePortJobCode(state),
		activePortJobThreadId: getActivePortJobThreadId(state)
	}),
	{
		retrievePortJobThreads,
		retrievePortJobThreadsCycleStart,
		retrievePortJobThreadsCycleStop,
		setActivePortJobThreadId,
		retrieveThreadById,
		resetThreads,
		resetPortJobThreads,
		resetActivePortJobThreadId,
		setPortCallThreadsFilters,
		resetPortCallThreadsFilters
	}
)(MessagesList);
