import React from 'react';
import { connect } from 'react-redux';
import { getFormSyncWarnings } from 'redux-form';
import { get } from 'lodash';

import { TextArea, Row, Col } from 'components/antd';
import GradeLines from './GradeLines/GradeLines';
import BunkeringFieldName from './BunkeringFieldNames';
import { FORM } from 'sections/PortJob/CreatePortJob/createPortJobConstants';
import { DEFAULT_TEXTAREA_MAX_LENGTH } from 'app-constants';
import { AppState } from 'store-types';
const { DETAILS, GRADE_LINES } = BunkeringFieldName;

interface BunkeringProps {
	gradeLinesEmpty: boolean;
}
const Bunkering: React.SFC<BunkeringProps> = ({
	gradeLinesEmpty
}: BunkeringProps) => (
	<>
		<Row>
			<Col sm={4}>
				<GradeLines />
			</Col>
		</Row>
		<TextArea.ReduxFormItem
			name={DETAILS}
			label="Operation Details"
			rows={4}
			required={gradeLinesEmpty}
			maxLength={DEFAULT_TEXTAREA_MAX_LENGTH}
		/>
	</>
);

const formSyncWarningsSelector = getFormSyncWarnings(FORM.portJob);
export default connect((state: AppState) => ({
	gradeLinesEmpty: Boolean(get(formSyncWarningsSelector(state), GRADE_LINES))
}))(Bunkering);
