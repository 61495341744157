declare const window: Window & {
	phoenixEnvs: {
		version: string;
		baseUrl: string;
		apiBaseUrl: string;
		statusBaseUrl: string;
		apmServerUrl: string;
		matomoServerUrl: string;
		idServUrl: string;
		matomoSiteId: string;
		supportTeamLink: string;
		reportServerUrl: string;
		allowedForISSDomainsList: Array<string>;
		useLegacyIdentity: string;
		quartermasterAppUrl: string;
		lpaAppUrl: string;
	};
};

const phoenixEnvs = window.phoenixEnvs || {};

const config = {
	baseUrl: '',
	apiBaseUrl: '',
	idServUrl: '',
	statusBaseUrl: '',
	apmServerUrl: '',
	matomoServerUrl: '',
	lpaAppUrl: '',
	quartermasterAppUrl: '',
	matomoSiteId: '',
	supportTeamLink: '',
	reportServerUrl: '',
	useLegacyIdentity: '',
	idleTimeout:
		process.env.NODE_ENV !== 'production'
			? 1000 * 60 * 60 * 10
			: 1000 * 60 * 60, // 60 min prod 10 hs dev
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		Accept: 'application/json',
		'Content-Type': 'application/json'
	},
	...phoenixEnvs
};

export default config;
