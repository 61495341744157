import { omitBy, reduce } from 'lodash';
import { MessagesTabsEnum } from 'sections/PortCall/Messages/MessagesList/MessagesListTypes';
import { FilterState } from 'store/filters/filtersState';
import {
	PortCallMessagesFilterParamName,
	RetrievePortCallMessagesFilters
} from 'store/portJobs/portCallMessagesFiltersSync';
import { paramsStatusMap } from 'store/threads/filtersSync';
import { prepareForSearch } from 'store/threads/sagas/retrieveThreads/prepareRequest/prepareRequest';
import { ThreadsFilterStatus } from 'types/sections/ThreadsTypes';

const { SEARCH, ONLY_UNREAD, STATUS } = PortCallMessagesFilterParamName;

export default function prepareRequest(filters: FilterState = {}) {
	const parameters = Object.values(PortCallMessagesFilterParamName);
	const preparedParameters = reduce<
		FilterState,
		RetrievePortCallMessagesFilters
	>(
		filters,
		(result, value, key: PortCallMessagesFilterParamName) => {
			// skip not expected filter parameters
			if (!parameters.includes(key)) {
				return result;
			}

			const parameterValue = value[0].key;

			if (key === SEARCH) {
				result = {
					...result,
					...prepareForSearch(value)
				};
				return result;
			}

			if (key === ONLY_UNREAD) {
				result.onlyUnread = parameterValue === MessagesTabsEnum.Unread;
				return result;
			}

			if (key === STATUS) {
				result.statuses = paramsStatusMap[parameterValue];
				return result;
			}

			return result;
		},
		{}
	);

	if (!preparedParameters.statuses) {
		// we should display statuses that belong to `UNRESOLVED` by default
		preparedParameters.statuses =
			paramsStatusMap[ThreadsFilterStatus.UNRESOLVED];
	}

	return omitBy<RetrievePortCallMessagesFilters>(
		preparedParameters,
		parameter => !parameter
	);
}
