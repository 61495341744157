import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { keyBy } from 'lodash';
import { ServicesState, initialState } from '../servicesState';
import { RetrieveServicesResponse } from 'services/api/services/servicesServiceTypes';
import { Success } from 'typescript-fsa';

const doneReducer = (
	state: ServicesState,
	{ result }: Success<null, RetrieveServicesResponse>
): ServicesState => ({
	...state,
	byCode: keyBy(result.elements, item => item.code)
});

export const onRetrieveServices = makeFetchStatusReducers<
	ServicesState,
	'fetchStatuses',
	null,
	RetrieveServicesResponse
>('fetchStatuses', 'retrieveServices', {
	doneReducer
});

export const onResetServices = (state: ServicesState) => ({
	...state,
	byCode: initialState.byCode
});
