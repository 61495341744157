import actionCreator from '../linkJobActionCreator';
import { CreateCargoFromNominationRequest } from 'services/api/portCalls/portCallsServiceTypes';

const CREATE_CARGO_FROM_NOMINATION = 'CREATE_CARGO_FROM_NOMINATION';
export const createCargoFromNomination = actionCreator<
	CreateCargoFromNominationRequest
>(CREATE_CARGO_FROM_NOMINATION);

export default {
	action: createCargoFromNomination
};
