import { ConfigurationBenchmarkData } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'UPDATE_CONFIGURATION_BENCHMARK';
export const updateConfigurationBenchmark = actionCreator(ACTION_NAME);
export const updateConfigurationBenchmarkAsync = actionCreator.async<
	null,
	ConfigurationBenchmarkData,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Data'
	}
});
