import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { setBreadcrumbs } from './actions/setBreadcrumbs';
import { onSetBreadcrumb, retrieveBenchmarkUrl } from './reducers';
import { initialState } from './navigationState';
import { retrieveBenchmarkUrlAsync } from './actions/retrieveBenchmarkUrl';

export default reducerWithInitialState(initialState)
	.case(setBreadcrumbs, onSetBreadcrumb)
	.case(retrieveBenchmarkUrlAsync.started, retrieveBenchmarkUrl.started)
	.case(retrieveBenchmarkUrlAsync.done, retrieveBenchmarkUrl.done)
	.case(retrieveBenchmarkUrlAsync.failed, retrieveBenchmarkUrl.failed);
