import { Action } from 'typescript-fsa';
import { takeLatest, put, take } from 'redux-saga/effects';

import {
	uploadAnnotatedDocumentationDone,
	retrieveFinanceDocument,
	retrieveFinanceDocumentAsync
} from '../actions';

import { cancelUploadAndProcessDocument } from 'store/documents/actions';

import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

import { navigateTo } from 'utils';
import { FinanceDocumentType, PageType } from '../constants';
import {
	retrieveSlitSupplierInvoiceDocument,
	retrieveSplitSupplierInvoiceDocumentAsync
} from 'store/supplierInvoice/actions';
import { notify } from 'store/notifications/actions';

export default function*() {
	yield takeLatest(uploadAnnotatedDocumentationDone.type, function*(
		action: Action<UploadDocumentDoneResponse>
	) {
		const { isDoneSuccess, metadata } = action.payload;

		if (!isDoneSuccess) {
			return;
		}

		yield put(notify.success('Annotated version saved successfully.'));

		// Reset state
		yield put(cancelUploadAndProcessDocument({ shouldResetFile: true }));

		if (metadata) {
			const {
				supplierInvoiceId,
				disbursementAccountId,
				portCallId,
				jobCode,
				portJobServiceId,
				actNumber
			} = metadata;

			if (supplierInvoiceId && portJobServiceId) {
				yield put(
					retrieveSlitSupplierInvoiceDocument({
						supplierInvoiceId,
						serviceId: portJobServiceId
					})
				);
				//need to wait till the retrieveFinanceDocument is started so page navigation issue will not occur
				yield take(retrieveSplitSupplierInvoiceDocumentAsync.started);
				navigateTo(
					`/portcalls/${portCallId}/jobs/${jobCode}/service/${portJobServiceId}/finance/documents/supplier-invoices-pages/${supplierInvoiceId}/preview/${actNumber}/${PageType.UPDATED}`
				);
			} else if (disbursementAccountId) {
				yield put(
					retrieveFinanceDocument({
						portCallId,
						jobCode: jobCode,
						id: disbursementAccountId,
						documentType: FinanceDocumentType.DAS
					})
				);
				//need to wait till the retrieveFinanceDocument is started so page navigation issue will not occur
				yield take(retrieveFinanceDocumentAsync.started);
				navigateTo(
					`/portcalls/${portCallId}/jobs/${jobCode}/finance/documents/${FinanceDocumentType.DAS}/${disbursementAccountId}/preview/${actNumber}/${PageType.UPDATED}`
				);
			} else if (portJobServiceId) {
				yield put(
					retrieveFinanceDocument({
						portCallId,
						jobCode: jobCode,
						id: portJobServiceId,
						documentType: FinanceDocumentType.SERVICES
					})
				);
				//need to wait till the retrieveFinanceDocument is started so page navigation issue will not occur
				yield take(retrieveFinanceDocumentAsync.started);
				navigateTo(
					`/portcalls/${portCallId}/jobs/${jobCode}/finance/documents/${FinanceDocumentType.SERVICES}/${portJobServiceId}/preview/${actNumber}/${PageType.UPDATED}`
				);
			}
		}
	});
}
