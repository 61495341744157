import { unionBy } from 'lodash';
import { Success } from 'typescript-fsa';
import { AuthState } from '../authState';
import { UserAuth } from 'services/api/users/userServiceTypes';
import { Permission } from 'services/api/permissions/permissionsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';

export const getPermissions = (result: UserAuth) => {
	const groups = result.groups;
	const allGroupsPermissions = groups.reduce<Permission[]>(
		(acc, group) => [...acc, ...group.functionalPermissions],
		[]
	);
	return unionBy<Permission>(allGroupsPermissions, 'code'); // only uniq by code
};

const onRetrieveAuthUserSuccess = (
	state: AuthState,
	{ result }: Success<null, UserAuth>
): AuthState => {
	const newState: Partial<AuthState> = {};
	if (state.actAs.value) {
		newState.actingUser = result;
		newState.actingPermissions = getPermissions(result);
	} else {
		newState.authUser = result;
		newState.authPermissions = getPermissions(result);
	}

	return {
		...state,
		...newState
	};
};

export const onRetrieveAuthUser = makeFetchStatusReducers<
	AuthState,
	'fetchStatuses',
	null,
	UserAuth
>('fetchStatuses', 'retrieveAuthUser', {
	doneReducer: onRetrieveAuthUserSuccess
});
