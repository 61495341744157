import { call, takeEvery, all, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';

import { Action } from 'typescript-fsa';
import { retrieveEntityPermissionsForVPAsync } from '../actions';
import {
	EntityPermissionVPActionParams,
	EntityPermissionType
} from 'services/api/permissions/permissionsServiceTypes';
import { cargoPermissionSaga } from './retrieveEntityPermissionsForCargoSaga';
import deleteRotationStepPermissionSaga from './retrieveEntityPermissionsForDeleteRotationStepSaga';
import updateRotationStepPermissionSaga from './retrieveEntityPermissionsForUpdateRotationStepSaga';
import custodyLinePermissionSaga from './retrieveEntityPermissionsForCustodyLineSaga';

export function* executor(
	request: EntityPermissionVPActionParams,
	api: typeof Api.Permissions.retrieveEntityPermissionsForVP
): SagaIterator {
	switch (request.entityType) {
		case EntityPermissionType.CARGO:
			yield call(cargoPermissionSaga, request, api);
			break;
		case EntityPermissionType.CUSTODY_TRANSFER_LINE:
			yield call(custodyLinePermissionSaga, request, api);
			break;
		case EntityPermissionType.ROTATION_STEP:
			request.optionalParams.entityPermission === 'canDelete'
				? yield call(deleteRotationStepPermissionSaga, request, api)
				: yield call(updateRotationStepPermissionSaga, request, api);
			break;
		default:
			return;
	}
}

function* worker(action: Action<EntityPermissionVPActionParams>): SagaIterator {
	yield call(
		executor,
		action.payload,
		Api.Permissions.retrieveEntityPermissionsForVP
	);
}

function* watcher(): SagaIterator {
	yield takeEvery(retrieveEntityPermissionsForVPAsync.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
