import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	InviteUserModalProps,
	ToggleUserStatusModalProps,
	UsersModalsName
} from './UsersModalsTypes';
import {
	inviteUser,
	reInviteUser,
	updateUserStatus
} from 'store/users/actions';
import { getUsersFetchStatusesStructured } from 'store/users/usersSelectors';
import { getNextUserStatus } from 'sections/User/UserStatusButton/UserStatusButton.func';

interface UseUsersModalsProps {
	form: string;
	name?: string | null;
}

export function useUsersModals({ form, name }: UseUsersModalsProps) {
	const dispatch = useDispatch();

	const { inviteStatus, updateStatus } = useSelector(
		getUsersFetchStatusesStructured
	);

	const onSubmitInviteUserForm: InviteUserModalProps['onSubmit'] = useCallback(
		values => {
			const requestParams = {
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
				groups: values.groups,
				invitationMessage: values.invitationMessage,
				companyId: values.company.key
			};

			if (values.id) {
				dispatch(
					reInviteUser({
						form,
						requestParams: {
							...requestParams,
							userId: values.id
						}
					})
				);
			} else {
				dispatch(
					inviteUser({
						form,
						requestParams
					})
				);
			}
		},
		[dispatch, form]
	);

	const onSubmitToggleUserStatus: ToggleUserStatusModalProps['onSubmit'] = useCallback(
		values => {
			if (values) {
				dispatch(
					updateUserStatus({
						id: values.id,
						request: { status: getNextUserStatus(values.status) }
					})
				);
			}
		},
		[dispatch]
	);

	const resolveStatusByMode = useMemo(
		() => ({
			[UsersModalsName.INVITE]: inviteStatus,
			[UsersModalsName.TOGGLE_STATUS]: updateStatus
		}),
		[inviteStatus, updateStatus]
	);

	return {
		fetchStatus: resolveStatusByMode[name || ''],
		onSubmitInviteUserForm,
		onSubmitToggleUserStatus
	};
}
