import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Loading } from 'components';
import { Row, Col, Radio, Tag, Select, Button } from 'components/antd';
import RadioGroup from 'components/antd/Radio/RadioGroup';
import { SelectValue } from 'antd/lib/select';
import {
	isFetchingUsersSelector,
	OPTION_UNASSIGNED,
	getActiveThread
} from 'store/thread/selectors';
import { useAssignGroupForm } from './AssignGroupModal.hooks';
import { ThreadGroupAssignmentFormFields } from './AssignGroupModalTypes';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import styles from './AssignGroupModalForm.module.scss';

const {
	ASSIGNED_GROUP_ID,
	THREAD_PERMISSION_GROUPS,
	ASSIGNED_USER_ID
} = ThreadGroupAssignmentFormFields;

const AssignGroupFields = () => {
	const isFetchingUsers = useSelector(isFetchingUsersSelector);
	const {
		threadPermissionGroupsWithUsersInForm,
		formValues,
		changeField
	} = useAssignGroupForm();
	const assignedGroupValue = formValues[ASSIGNED_GROUP_ID];
	const assignedUserValue = formValues[ASSIGNED_USER_ID];
	const currentThread = useSelector(getActiveThread, shallowEqual);
	const assignedUser =
		currentThread.assignedToUser?.id === assignedUserValue &&
		currentThread.assignedToUser;

	const getGroupUsersOptions = useCallback(
		(groupId: string) => {
			const { options = [] } =
				threadPermissionGroupsWithUsersInForm.find(
					({ id }) => id === groupId
				) || {};
			return options;
		},
		[threadPermissionGroupsWithUsersInForm]
	);

	const onAssignedUserChange = useCallback(
		(userId?: SelectValue) => {
			changeField(ASSIGNED_USER_ID, userId as string | undefined);
		},
		[changeField]
	);

	const onAssignedGroupChange = useCallback(
		event => {
			changeField(ASSIGNED_GROUP_ID, event.target.value);
			onAssignedUserChange(OPTION_UNASSIGNED);
		},
		[changeField, onAssignedUserChange]
	);

	const onRemoveGroup = useCallback(
		(groupId: string) => {
			const newGroups = formValues[THREAD_PERMISSION_GROUPS].filter(
				({ id }) => id !== groupId
			);
			changeField(THREAD_PERMISSION_GROUPS, newGroups);
			if (groupId === formValues[ASSIGNED_GROUP_ID]) {
				const firstAvailableGroupId = newGroups[0].id;
				changeField(ASSIGNED_GROUP_ID, firstAvailableGroupId);
				changeField(ASSIGNED_USER_ID, null);
			}
		},
		[changeField, formValues]
	);

	const getAssignToOptions = useCallback(
		(group: PermissionGroupWithUsers) => {
			const assignedToAnotherGroup = !getGroupUsersOptions(group.id).some(
				user => user.id === assignedUser?.id
			);
			return [
				/**
				 * the option from `thread.assignedToUser` to prevent just ID appearing
				 * when an assigned user does not belong to the current group
				 **/
				...(assignedToAnotherGroup && assignedUser ? [assignedUser] : []),
				// all other options
				...getGroupUsersOptions(group.id)
			];
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[assignedUserValue, assignedUser, getGroupUsersOptions]
	);

	if (isFetchingUsers) {
		return <Loading size="sm" />;
	} else if (!formValues[THREAD_PERMISSION_GROUPS].length) {
		return (
			<p>
				There isn't a Group assigned. Be the first to add one using the dropdown
				below
			</p>
		);
	}

	return (
		<RadioGroup.ReduxFormItem
			name={ASSIGNED_GROUP_ID}
			value={assignedGroupValue}
			onChange={onAssignedGroupChange}
			className={styles.radioGroup}
		>
			{threadPermissionGroupsWithUsersInForm.map(group => (
				<Row key={group.id} between="xs" middle="xs">
					<Col xs={6}>
						<Radio value={group.id}>
							{group.name ? (
								<span>
									<strong>{group.name}</strong>
								</span>
							) : (
								<Tag type="warning">No Name</Tag>
							)}
						</Radio>
					</Col>
					{group.id === assignedGroupValue && (
						<Col xs={5} className={styles.userSelectCol}>
							<Select.ReduxFormItem
								name={ASSIGNED_USER_ID}
								disabled={!group.options || !group.options.length}
								onSelect={onAssignedUserChange}
								getPopupContainer={() => document.body}
								defaultValue={OPTION_UNASSIGNED}
							>
								{getAssignToOptions(group).map(user => (
									<Select.Option
										key={user.id}
										value={user.id}
										title={user.name}
									>
										{user.name}
									</Select.Option>
								))}
							</Select.ReduxFormItem>
						</Col>
					)}
					<Col xs={1}>
						<Button
							type="danger"
							transparent
							icon="delete"
							onClick={() => onRemoveGroup(group.id)}
							htmlType="button"
							disabled={threadPermissionGroupsWithUsersInForm.length <= 1}
						/>
					</Col>
				</Row>
			))}
		</RadioGroup.ReduxFormItem>
	);
};

export default AssignGroupFields;
