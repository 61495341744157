import { retrievePortJobMetadataAsync as async } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	RetrievePortJobMetadataRequest,
	PortJobMetadataActionCode
} from 'services/api/portJobs/portJobsServiceTypes';
import { Metadata } from 'app-types';

export default makeTakeLatestWatcher<
	RetrievePortJobMetadataRequest,
	Metadata<PortJobMetadataActionCode>,
	Error
>({
	api: Api.PortJobs.retrievePortJobMetadata,
	async
});
