import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { put, take, takeLatest, select } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import { VESSEL_FORM } from 'sections/Administration/Configuration/Vessels/constants';
import {
	retrieveEditCompanyVesselData,
	retrieveEditCompanyVesselDataAsync,
	retrieveCompanyVessel,
	retrieveCompanyVesselAsync
} from '../actions';
import {
	RetrieveCompanyVesselRequest,
	CompanyVessel
} from 'services/api/companies/companiesServiceTypes';
import { getCompanyVesselsByCompanyIdVesselId } from 'store/companyVessels/selectors';

export function* worker({
	payload
}: Action<RetrieveCompanyVesselRequest>): SagaIterator {
	const { companyId, vesselId } = payload;
	yield put(retrieveEditCompanyVesselDataAsync.started(payload));

	try {
		yield put(retrieveCompanyVessel(payload));
		const result = yield take([
			retrieveCompanyVesselAsync.done,
			retrieveCompanyVesselAsync.failed
		]);
		if (result.error) {
			throw new Error('Company Vessel information retrieving has end by error');
		}
		const companyVessel: CompanyVessel = yield select(
			getCompanyVesselsByCompanyIdVesselId,
			companyId,
			vesselId
		);
		const formValues = {
			...companyVessel,
			companyVesselTypeMapping: companyVessel.companyVesselTypeMapping.id,
			operatorEmails: companyVessel.operatorEmails.map(contact => ({
				label: contact
			}))
		};
		yield put(initialize(VESSEL_FORM, formValues));
		yield put(
			retrieveEditCompanyVesselDataAsync.done({
				params: payload,
				result: companyVessel,
				response: null
			})
		);
	} catch (error) {
		yield put(
			retrieveEditCompanyVesselDataAsync.failed({
				error,
				params: payload
			})
		);
	}
}

export default function*() {
	yield takeLatest(retrieveEditCompanyVesselData.type, worker);
}
