import numeral from 'numeral';
import {
	AddPortJobCharterer,
	AddPortJobOperationTypes
} from 'services/api/portJobs/portJobsServiceTypes';
import {
	PortJobCharterer,
	FormData,
	TotalAmountFormData
} from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import {
	OperationTypeCode,
	Operation
} from 'services/api/operations/operationsServiceTypes';

import getCrewChangeFormData from './operations/crewChange';
import getLoadOperationFormData from './operations/load';
import getDischargeOperationFormData from './operations/discharge';
import getBunkeringOperationFormData from './operations/bunkering';

import { idToIdField } from './prepareRequest.func';
import { omit } from 'lodash';

const omitGeneratedId = (object: TotalAmountFormData) => {
	if (object.id && object.id.includes('[generated]')) {
		return omit(object, 'id');
	}
	return object;
};

const prepareChartererRequest = (
	charterer: PortJobCharterer
): AddPortJobCharterer => ({
	chartererCompany: idToIdField(charterer.chartererCompanyId),
	timeBar: {
		isApplied: charterer.timeBarisApplied,
		days: parseFloat(charterer.timeBarDays)
	},
	maxPortCost: {
		isApplied: charterer.maxPortCost,
		amount: numeral(charterer.maxPortCostAmount).value(),
		currencyCode: charterer.maxPortCostCurrency
	},
	isOtherRebillablesApplied: charterer.isOtherRebillablesApplied,
	isWorldScaleServicesApplied: charterer.isWorldScaleServicesApplied,
	costSettlementIsDirect:
		charterer.isOtherRebillablesApplied || charterer.isWorldScaleServicesApplied
			? charterer.costSettlementIsDirect === 'false'
				? false
				: true
			: null,
	operationalInstructions: charterer.operationalInstructions,
	financialInstructions: charterer.financialInstructions,
	id: charterer.id
});

export const prepareOperationRequest = (
	operation: Operation,
	form: FormData
): AddPortJobOperationTypes => {
	const request = {
		operationType: operation.operationTypeCode,
		code: operation.code
	};
	switch (request.operationType) {
		case OperationTypeCode.GENERIC:
			return {
				...request,
				details: form.details
			};
		case OperationTypeCode.CANAL_TRANSIT:
			return {
				...request,
				details: form.details,
				vesselCondition: form.vesselCondition
			};
		case OperationTypeCode.CREW_WAGE_DISBURSEMENT:
			return {
				...request,
				numberOfCrew: form.crewWageDisbursementNumberOfCrew,
				totalAmounts: (form.totalAmounts || []).map(totalAmount =>
					omitGeneratedId(totalAmount)
				),
				details: form.crewWageDisbursementDetails
			};
		case OperationTypeCode.CREW_CHANGE:
			return {
				...request,
				...getCrewChangeFormData(form)
			};
		case OperationTypeCode.CASH_TO_MASTER:
			return {
				...request,
				masterFullName: form.cashToMasterMasterName,
				totalAmounts: (form.totalAmounts || []).map(totalAmount =>
					omitGeneratedId(totalAmount)
				),
				details: form.cashToMasterDetails
			};
		case OperationTypeCode.LOADING:
		case OperationTypeCode.LOADING_STS:
			return {
				...request,
				...getLoadOperationFormData(form),
				charterers: form.charterers
					? form.charterers.map(prepareChartererRequest)
					: []
			};
		case OperationTypeCode.DISCHARGING:
		case OperationTypeCode.DISCHARGING_STS:
			return {
				...request,
				...getDischargeOperationFormData(form),
				charterers: form.charterers
					? form.charterers.map(prepareChartererRequest)
					: []
			};
		case OperationTypeCode.BUNKERING:
			return {
				...request,
				...getBunkeringOperationFormData(form)
			};
		case OperationTypeCode.SPARES_AND_DOCUMENT_DELIVERY:
			return {
				...request,
				details: form.details,
				number: numeral(form.number).value()
			};
		default:
			return request;
	}
};
