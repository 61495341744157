import {
	DashboardTableauInfo,
	RetrieveDashboardTableauInfoRequest
} from 'services/api/dashboard/dashboardServiceTypes';
import actionCreator from 'store/dashboard/dashboardActionCreator';

const ACTION_NAME = 'RETRIEVE_DASHBOARD_TABLEAU_INFO';
export const retrieveDashboardTableauInfoAsync = actionCreator.async<
	RetrieveDashboardTableauInfoRequest,
	DashboardTableauInfo,
	Error
>(ACTION_NAME);

export const retrieveDashboardTableauInfo = actionCreator<
	RetrieveDashboardTableauInfoRequest
>(ACTION_NAME);
