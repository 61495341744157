import React from 'react';
import Tooltip, { TooltipProps } from './Tooltip';

// This component is handy to show tooltip conditionally
type MaybeTooltipProps = Partial<TooltipProps> & {
	show: boolean;
};
const MaybeTooltip = ({
	show,
	title,
	overlay,
	children,
	...tooltipProps
}: MaybeTooltipProps) => {
	return show && (title || overlay) ? (
		<Tooltip {...tooltipProps} title={title} overlay={overlay}>
			{children}
		</Tooltip>
	) : (
		<>{children}</>
	);
};
export default MaybeTooltip;
