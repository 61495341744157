import actionCreator from '../groupUsersActionCreator';
import { ActionMeta } from 'app-types';
import {
	ThirdPartyLPAUserInvite,
	ThirdPartyLPAUserInviteResponse
} from 'services/api/groups/groupsServiceTypes';

const ACTION_META: ActionMeta = {
	behaviour: {
		type: 'submit'
	}
};

const INVITE_THIRD_PARTY_LPA_USER = 'INVITE_THIRD_PARTY_LPA_USER';

export interface ThirdPartyLPAUserInvitePayload {
	form: string;
	requestParams: ThirdPartyLPAUserInvite;
}

export const inviteThirdPartyLpaUserAsync = actionCreator.async<
	ThirdPartyLPAUserInvitePayload,
	ThirdPartyLPAUserInviteResponse,
	Error
>(INVITE_THIRD_PARTY_LPA_USER);

export const inviteThirdPartyLpaUser = actionCreator<
	ThirdPartyLPAUserInvitePayload
>(INVITE_THIRD_PARTY_LPA_USER, ACTION_META);
