import { createSelector } from 'reselect';
import { getUserGroupType } from './userSelectors';
import { GroupTypeCodes } from 'services/api/groups/groupsServiceTypes';

export const isUserOfGroupTypeSelector = (groupTypeCode: GroupTypeCodes) =>
	createSelector(
		getUserGroupType,
		currentUserGroupType => currentUserGroupType === groupTypeCode
	);

export const getIsCurrentUserOfISSAdminGroupType = isUserOfGroupTypeSelector(
	GroupTypeCodes.ISS_ADMIN
);
export const getIsCurrentUserOfCustomerGroupType = isUserOfGroupTypeSelector(
	GroupTypeCodes.CUSTOMER
);
