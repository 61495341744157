import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { retrieveCompanyAsync } from './actions/retrieveCompany';

import {
	retrieveCompaniesAsync,
	resetCompanies
} from './actions/retrieveCompanies';

import {
	retrieveCompaniesContactDetailsAsync,
	resetCompaniesContactDetails
} from './actions/retrieveCompaniesContactDetails';

import { retrieveCompaniesForUserAsync } from './actions/retrieveCompaniesForUser';
import {
	retrieveCompanyPreferredAgentsFileInfoAsync,
	resetCompanyPreferredAgentsFileInfo,
	retrieveCompaniesConfigurationSettingsAsync,
	resetCompaniesConfigurationSettings,
	retrievePrincipalsForMainPrincipalAsync,
	resetPrincipalsForMainPrincipal,
	retrieveCompanyGeneralConfigurationReportsAsync,
	retrieveCompanyBenchmarkAsync,
	retrieveCompanyJobTypesAsync
} from './actions';

import {
	onRetrieveCompaniesForUserSuccess,
	onRetrieveCompaniesForUserFailed,
	onRetrieveCompaniesForUserStarted,
	onRetrieveCompanies,
	onRetrieveCompanyStarted,
	onRetrieveCompanySuccess,
	onRetrieveCompanyFailed,
	onRetrieveCompaniesContactDetails,
	onResetCompaniesContactDetails,
	onResetCompanies,
	onRetrieveCompanyPreferredAgentsFileInfo,
	onResetCompanyPreferredAgentsFileInfo,
	onRetrieveCompaniesConfigurationSettingsSuccess,
	onResetCompaniesConfigurationSettings,
	onRetrievePrincipalsForMainPrincipalSuccess,
	onResetPrincipalsFormMainPrincipal,
	onRetrieveCompanyGeneralConfigurationReports,
	onRetrieveCompanyBenchmark,
	onRetrieveCompanyJobTypes
} from './reducers';

import { initialState, initialStateForUser } from './companiesState';

const companiesReducer = reducerWithInitialState(initialState)
	// Company
	.case(retrieveCompanyAsync.started, onRetrieveCompanyStarted)
	.case(retrieveCompanyAsync.done, onRetrieveCompanySuccess)
	.case(retrieveCompanyAsync.failed, onRetrieveCompanyFailed)
	// Companies
	.case(retrieveCompaniesAsync.started, onRetrieveCompanies.started)
	.case(retrieveCompaniesAsync.done, onRetrieveCompanies.done)
	.case(retrieveCompaniesAsync.failed, onRetrieveCompanies.failed)
	// Retrieve Company Benchmark
	.case(
		retrieveCompanyBenchmarkAsync.started,
		onRetrieveCompanyBenchmark.started
	)
	.case(retrieveCompanyBenchmarkAsync.failed, onRetrieveCompanyBenchmark.failed)
	.case(retrieveCompanyBenchmarkAsync.done, onRetrieveCompanyBenchmark.done)
	// Contact details
	.case(
		retrieveCompaniesContactDetailsAsync.started,
		onRetrieveCompaniesContactDetails.started
	)
	.case(
		retrieveCompaniesContactDetailsAsync.done,
		onRetrieveCompaniesContactDetails.done
	)
	.case(
		retrieveCompaniesContactDetailsAsync.failed,
		onRetrieveCompaniesContactDetails.failed
	)
	.case(resetCompaniesContactDetails, onResetCompaniesContactDetails)
	.case(resetCompanies, onResetCompanies)
	// Retrieve Preferred Agents File Info
	.case(
		retrieveCompanyPreferredAgentsFileInfoAsync.started,
		onRetrieveCompanyPreferredAgentsFileInfo.started
	)
	.case(
		retrieveCompanyPreferredAgentsFileInfoAsync.done,
		onRetrieveCompanyPreferredAgentsFileInfo.done
	)
	.case(
		retrieveCompanyPreferredAgentsFileInfoAsync.failed,
		onRetrieveCompanyPreferredAgentsFileInfo.failed
	)
	.case(
		resetCompanyPreferredAgentsFileInfo,
		onResetCompanyPreferredAgentsFileInfo
	)
	// Configuration Settings
	.case(
		retrieveCompaniesConfigurationSettingsAsync.done,
		onRetrieveCompaniesConfigurationSettingsSuccess
	)
	.case(
		resetCompaniesConfigurationSettings,
		onResetCompaniesConfigurationSettings
	)
	// Report Server
	.case(
		retrieveCompanyGeneralConfigurationReportsAsync.started,
		onRetrieveCompanyGeneralConfigurationReports.started
	)
	.case(
		retrieveCompanyGeneralConfigurationReportsAsync.done,
		onRetrieveCompanyGeneralConfigurationReports.done
	)
	.case(
		retrieveCompanyGeneralConfigurationReportsAsync.failed,
		onRetrieveCompanyGeneralConfigurationReports.failed
	)
	// Get principals list for main principal
	.case(
		retrievePrincipalsForMainPrincipalAsync.done,
		onRetrievePrincipalsForMainPrincipalSuccess
	)
	.case(resetPrincipalsForMainPrincipal, onResetPrincipalsFormMainPrincipal)
	// Get job list for principal
	.case(retrieveCompanyJobTypesAsync.started, onRetrieveCompanyJobTypes.started)
	.case(retrieveCompanyJobTypesAsync.done, onRetrieveCompanyJobTypes.done)
	.case(retrieveCompanyJobTypesAsync.failed, onRetrieveCompanyJobTypes.failed);

const companiesForUserReducer = reducerWithInitialState(initialStateForUser)
	// Companies for user
	.case(
		retrieveCompaniesForUserAsync.started,
		onRetrieveCompaniesForUserStarted
	)
	.case(retrieveCompaniesForUserAsync.done, onRetrieveCompaniesForUserSuccess)
	.case(retrieveCompaniesForUserAsync.failed, onRetrieveCompaniesForUserFailed);

export {
	companiesReducer as companies,
	companiesForUserReducer as companiesForUser
};
