import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Flex, PageHeader } from 'components';
import { DataFetcher } from 'utils/components';
import VesselInfoActAs from './VesselInfoActAs';
import { connect } from 'react-redux';
import {
	getPortCallByIdFetchStatus,
	getRetrievedSinglePortCall
} from 'store/portcalls/selectors';
import { AppState } from 'store-types';
import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import VesselInfo from './VesselInfo';
import { navigateTo } from 'utils';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { BreadcrumbItem } from 'store/navigation';
import {
	retrievePortCallVesselInfo,
	resetPortCallVesselInfo
} from 'store/portCall/actions';

// Private module functions
const vesselInfoBreadrcumbs = (portCall: PortCall): BreadcrumbItem[] => [
	{ title: 'Port Calls', link: '/portcalls' },
	{
		title: portCall.vessel.name,
		icon: 'vessel',
		children: [
			{
				title: portCall.port.name,
				icon: 'anchor'
			}
		]
	},
	{
		title: 'Overview'
	},
	{
		title: 'Vessel Details'
	}
];
const firstJobCode = ([firstJob]: PortJob[]) => firstJob.code;
const redirectPath = ({ id, jobs }: PortCall) =>
	`/portcalls/${id}/jobs/${firstJobCode(jobs)}/overview`;
const closePage = (newTab = false, portCall: PortCall) =>
	newTab ? window.close() : navigateTo(redirectPath(portCall));

// Component
interface RouteParams {
	portCallId: string;
	newTab?: string;
}
type VesselInfoProps = RouteComponentProps<RouteParams> & {
	portCall: PortCall;
	portCallRetriveFetchStatus: FetchStatus;
	retrieve: typeof retrievePortCallVesselInfo;
	reset: typeof resetPortCallVesselInfo;
};
const VesselInfoRoute = ({
	match: {
		params: { portCallId, newTab }
	},
	retrieve,
	reset,
	portCallRetriveFetchStatus,
	portCall
}: VesselInfoProps) => (
	<DataFetcher
		dispatch={() => retrieve({ portCallId })}
		fetchStatus={portCallRetriveFetchStatus}
		reset={() => reset(null)}
	>
		{() => (
			<VesselInfoActAs>
				<Flex fit direction="vertical" stretch>
					<RouteBreadcrumbs items={vesselInfoBreadrcumbs(portCall)} />
					<PageHeader.NewTab
						onClose={() => {
							closePage(Boolean(newTab), portCall);
						}}
					>
						Vessel Details: {portCall.vessel.name}
					</PageHeader.NewTab>
					<VesselInfo portCallId={portCallId} vesselId={portCall.vessel.id} />
				</Flex>
			</VesselInfoActAs>
		)}
	</DataFetcher>
);
export default connect(
	(
		state: AppState,
		{
			match: {
				params: { portCallId }
			}
		}: VesselInfoProps
	) => ({
		portCall: getRetrievedSinglePortCall(state),
		portCallRetriveFetchStatus: getPortCallByIdFetchStatus(state, portCallId)
	}),
	{
		retrieve: retrievePortCallVesselInfo,
		reset: resetPortCallVesselInfo
	}
)(VesselInfoRoute);
