import { FeedbackManagementRequestId } from 'services/api/companies/companiesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ConfigurationState } from '../configurationState';

export const onDownloadFeedbackManagementRequest = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	FeedbackManagementRequestId,
	null
>('fetchStatuses', 'downloadFeedbackManagementFetchStatus');
