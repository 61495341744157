import React from 'react';
import { isFunction } from 'lodash';
import { connect } from 'react-redux';

import { setFilters, destroy, clearOne } from 'store/filters/actions';
import { FiltersProps, FiltersStateAndHelpers } from '../Filters';
import { FilterState, FilterItem } from 'store/filters/filtersState';

interface FilterProps extends FiltersProps {
	setFilters: typeof setFilters;
	clearOne: typeof clearOne;
	destroy: typeof destroy;
	areFiltersEmpty: boolean;
}

class Filter extends React.Component<FilterProps> {
	static defaultProps: Partial<FilterProps> = {
		destroyOnUnmount: true
	};

	componentWillUnmount() {
		if (this.props.destroyOnUnmount) {
			this.destroy();
		}
	}
	componentDidUpdate(prevProps: FilterProps) {
		const { areFiltersEmpty } = this.props;
		if (!prevProps.areFiltersEmpty && areFiltersEmpty) {
			this.onFiltersChange();
		}
	}
	onChange = (filters: FilterState, override = true) => {
		const { name } = this.props;

		this.props.setFilters({
			name,
			filters,
			override
		});
		this.onFiltersChange();
	};

	clearOne = (filter: FilterItem) => {
		const { name } = this.props;

		this.props.clearOne({
			name,
			filter
		});
		this.onFiltersChange();
	};

	destroy = () => {
		this.props.destroy(this.props.name);
		this.onFiltersChange();
	};

	onFiltersChange = () => {
		if (isFunction(this.props.invokeOnChange)) {
			this.props.invokeOnChange();
		}
	};

	getStateAndHelpers(): FiltersStateAndHelpers {
		return {
			onChange: this.onChange,
			clearOne: this.clearOne,
			clearAll: this.destroy
		};
	}

	render() {
		const element = this.props.children(this.getStateAndHelpers());
		return <>{element}</>;
	}
}

export default connect(null, {
	setFilters,
	clearOne,
	destroy
})(Filter);
