import { AppState } from 'store-types';

export const getDashboardTableau = (state: AppState) => state.dashboard.tableau;
export const getDashboardTableauCompanyId = (state: AppState) =>
	state.dashboard.context.tableauCompanyId;

export const getDashboardTableauInfo = (state: AppState, tableauCode: string) =>
	state.dashboard.tableauInfoByCode[tableauCode];

export const getDashboardTableauFetchStatus = (state: AppState) =>
	state.dashboard.fetchStatuses.retrieveTableau;

export const getDashboardTableauInfoFetchStatus = (
	state: AppState,
	tableauCode: string
) => state.dashboard.fetchStatuses.retrieveTableauInfo[tableauCode];
