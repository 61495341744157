import * as React from 'react';
import { connect } from 'react-redux';
import { getTimeFormatWithOffset, getUserTimezone } from 'store/auth/selectors';
import { getFormattedTime } from './FormattedTime.func';
import { AppState } from 'store-types';

export interface IFormattedTimeProps {
	value: string;
	format?: string;
	timeAgo?: boolean;
	timeOffset?: boolean;
	portTimeZoneId?: string;
	convertFromPlt?: boolean;
	convertFromUtc?: boolean;
	convertFromUtcToPlt?: boolean;
	className?: string;
	// from mapStateToProps
	userTimeZoneId: string;
	standardFormat: string;
}

const FormattedTime: React.FC<IFormattedTimeProps> = ({
	className,
	...props
}) => {
	const date = getFormattedTime(props);
	return <span className={className}>{date}</span>;
};
export default connect(
	(
		state: AppState,
		{ timeOffset }: Pick<IFormattedTimeProps, 'timeOffset'>
	) => ({
		userTimeZoneId: getUserTimezone(state),
		standardFormat: getTimeFormatWithOffset(timeOffset)
	})
)(FormattedTime);
