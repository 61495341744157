import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Content, Box, Title, Text, Amount } from 'components';
import { Button, Row, Col } from 'components/antd';
import { Justify } from 'components/types';
import Header from './Header/Header';
import CostSavingsGridTable from './SavingsGrid/CostSavingGrid/CostSavingsGridTable';
import TimeSavingGridTable from './SavingsGrid/TimeSavingGrid/TimeSavingGridTable';
import * as styles from './Savings.module.scss';
import CostSavingsModal from './SavingsGrid/CostSavingGrid/CostSavingsModal/CostSavingsModal';
import TimeSavingsModal from './SavingsGrid/TimeSavingGrid/TimeSavingsModal/TimeSavingsModal';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { openModal } from 'store/modals/actions';
import {
	COST_SAVINGS_MODAL,
	TIME_SAVINGS_MODAL
} from './SavingsGrid/Constants';
import { Permission, PermissionContext } from 'containers';
import { PortJobStatus } from 'store/portJobs/constants';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import {
	getTotalAmountInUSD,
	getTotalHoursSaved
} from 'store/savings/savingsSelectors';
import DeleteSavingsModal from './SavingsGrid/Settings/DeleteSavingsModal';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';

interface SavingsContentProps {
	job: PortJob;
}

const SavingsContent = ({ job }: SavingsContentProps) => {
	const dispatch = useDispatch();

	const isJobClosed = job.status === PortJobStatus.CLOSED;

	const totalAmountInUSD = useSelector(getTotalAmountInUSD);

	const totalHoursSaved = useSelector(getTotalHoursSaved);

	const userType = useSelector(getUserType);

	const onAddCostSavingsModal = () => {
		dispatch(openModal(COST_SAVINGS_MODAL));
	};

	const onAddTimeSavingsModal = () => {
		dispatch(openModal(TIME_SAVINGS_MODAL));
	};

	return (
		<PermissionContext.Provider
			value={{
				companyId: job.hubPrincipalCompany?.id
					? job.hubPrincipalCompany.id
					: null
			}}
		>
			<Header />
			<Content className={styles.savingContent}>
				<>
					<Box type="standard" className={styles.savingsHeader}>
						<Box.Header>
							<Row>
								<Col xs={10}>
									<Title>
										<Text upper>Cost Savings</Text>
									</Title>
								</Col>
							</Row>
						</Box.Header>
					</Box>
					<Row>
						<Col sm={12} justify={Justify.START}>
							<CostSavingsGridTable userType={userType} />
						</Col>
					</Row>
					<Row className={styles.addSavingsBtnRow}>
						<Col sm={6} justify={Justify.START}>
							<Permission permissionCode={PermissionCode.MANAGE_SAVINGS}>
								{hasPermission =>
									(userType === UserType.LPA || hasPermission) && (
										<Button
											type="primary"
											transparent
											icon="plus"
											onClick={onAddCostSavingsModal}
											disabled={isJobClosed}
										>
											Add Cost Saving
										</Button>
									)
								}
							</Permission>
						</Col>
						<Col
							className={styles.savingsTotalCol}
							sm={6}
							justify={Justify.END}
						>
							<div>
								<span>
									Amount Total (USD):{' '}
									<strong>
										{totalAmountInUSD ? (
											<Amount value={totalAmountInUSD} />
										) : (
											'0.00'
										)}
									</strong>
								</span>
							</div>
						</Col>
					</Row>
					<Box type="standard" className={styles.savingsHeader}>
						<Box.Header>
							<Row>
								<Col xs={10}>
									<Title>
										<Text upper>Time Savings</Text>
									</Title>
								</Col>
							</Row>
						</Box.Header>
					</Box>

					<Row>
						<Col sm={12} justify={Justify.START}>
							<TimeSavingGridTable userType={userType} />
						</Col>
					</Row>
					<Row className={styles.addSavingsBtnRow}>
						<Col sm={6} justify={Justify.START}>
							<Permission permissionCode={PermissionCode.MANAGE_SAVINGS}>
								{hasPermission =>
									(userType === UserType.LPA || hasPermission) && (
										<Button
											type="primary"
											transparent
											icon="plus"
											onClick={onAddTimeSavingsModal}
											disabled={isJobClosed}
										>
											Add Time Saving
										</Button>
									)
								}
							</Permission>
						</Col>
						<Col
							className={styles.savingsTotalCol}
							sm={6}
							justify={Justify.END}
						>
							<div>
								<span>
									Time Total:{' '}
									<strong>{totalHoursSaved ? totalHoursSaved : '0h 0m'}</strong>
								</span>
							</div>
						</Col>
					</Row>
					<CostSavingsModal />
					<DeleteSavingsModal />
					<TimeSavingsModal />
				</>
			</Content>
		</PermissionContext.Provider>
	);
};

export default SavingsContent;
