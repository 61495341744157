import moment from 'moment-timezone';

import { getLocalDateDefaultFormatted } from 'utils/formatDate';
import { IFormattedTimeProps } from './FormattedTime';

const convertPltToUtc = (
	pltDate: string,
	portTimeZoneId: string
): moment.Moment => {
	const formattedPltDate = getLocalDateDefaultFormatted(pltDate);
	const pltMoment = moment.tz(formattedPltDate, portTimeZoneId);
	return moment.utc(pltMoment);
};

export const getFormattedTime = ({
	value,
	format,
	convertFromPlt,
	convertFromUtc,
	convertFromUtcToPlt,
	timeAgo,
	portTimeZoneId,
	userTimeZoneId,
	standardFormat
}: IFormattedTimeProps) => {
	if (timeAgo) {
		const actualPastTime =
			convertFromPlt && portTimeZoneId
				? convertPltToUtc(value, portTimeZoneId)
				: moment.tz(value, userTimeZoneId);
		const now = portTimeZoneId ? moment.utc() : moment();
		// to avoid "in a few seconds" message which is caused by unsync between server and browser
		return now >= actualPastTime ? actualPastTime.fromNow() : now.fromNow();
	}
	const dateFormat = format || standardFormat;
	if (convertFromPlt && portTimeZoneId) {
		const date = convertPltToUtc(value, portTimeZoneId);
		const localDate = moment.tz(date, userTimeZoneId);
		return localDate.format(dateFormat);
	}
	if (convertFromUtc && userTimeZoneId) {
		return moment.tz(value, userTimeZoneId).format(dateFormat);
	}
	if (convertFromUtcToPlt && portTimeZoneId) {
		return moment.tz(value, portTimeZoneId).format(dateFormat);
	}
	return moment.utc(value).format(dateFormat);
};
