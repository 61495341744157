import Api from 'services/api';
import {
	submitUnitCostForm as action,
	submitUnitCostFormAsync as asyncAction
} from 'store/finance/actions/submitUnitCostForm';
import {
	SubmitUnitCostForm as request,
	SubmitUnitCostFormRequest
} from 'services/api/finance/financeServiceTypes';
import { Action } from 'typescript-fsa';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { getServiceById } from '../selectors/financeSelectors';
import { isConcurrencyError } from 'services/api/apiErrorUtils';
import { notifyConcurrencyError } from './updateServiceSaga';

const apiCall = Api.Finance.submitUnitForm;

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(asyncAction.started(actionParams));
	try {
		const portCallId = yield select(getActivePortCallId);
		const portJobCode = yield select(getActivePortJobCode);
		const serviceId = actionParams.jobServiceId;

		if (!actionParams.formValues) {
			throw new Error('Missing form values');
		}

		const request = {
			portCallId,
			portJobCode,
			serviceId,
			payload: actionParams.formValues
		} as SubmitUnitCostFormRequest;

		const response = yield call(api, request);

		yield put(
			asyncAction.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
	} catch (error) {
		yield put(
			asyncAction.failed({
				params: actionParams,
				error
			})
		);
		if (isConcurrencyError(error) && actionParams.jobServiceId) {
			const { name } = yield select(getServiceById, actionParams.jobServiceId);
			yield put(notifyConcurrencyError(name));
		}
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeEvery(action.type, worker);
}
