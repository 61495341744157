import * as React from 'react';
import { Stub } from 'components/antd';

import { InfiniteTableProps } from './InfiniteTable/InfiniteTable';

interface WithEmptyMessageProps extends InfiniteTableProps {}

/**
 * Typescript syntax for arrow functions
 * @see https://stackoverflow.com/a/45576880
 */
const withEmptyMessage = <P extends object>(
	WrappedComponent: React.ComponentType<P>
): React.FC<P & WithEmptyMessageProps> => props => {
	if (!props.showEmptyText) {
		return <WrappedComponent {...props} />;
	}
	return (
		<Stub
			title={
				props.locale && props.locale.emptyText
					? props.locale.emptyText
					: 'There are no more items to load'
			}
		/>
	);
};

export default withEmptyMessage;
