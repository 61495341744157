import {
	VesselProgrammeState,
	SetVPEventTimeParams
} from 'store/vesselProgramme/vesselProgrammeState';

export const onSetEventTime = (
	state: VesselProgrammeState,
	{ eventId, time }: SetVPEventTimeParams
): VesselProgrammeState => {
	return {
		...state,
		edited: true,
		// eslint-disable-next-line no-prototype-builtins
		eventsById: state.eventsById.hasOwnProperty(eventId)
			? {
					...state.eventsById,
					[eventId]: {
						...state.eventsById[eventId],
						estimatedDatePlt: time
					}
			  }
			: state.eventsById
	};
};
