import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	ConfigurationExtendedMessagingData,
	RetrieveExtendedMessagingRequest
} from 'services/api/companies/companiesServiceTypes';

const onRetrieveExtendedMessagingConfigurationDataSuccess = (
	state: PortJobState,
	{
		result
	}: Success<
		RetrieveExtendedMessagingRequest,
		ConfigurationExtendedMessagingData
	>
) => ({
	...state,
	extendedMessagingOption: result.extendedMessagingOption
});

export const onRetrieveExtendedMessagingConfigurationData = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	RetrieveExtendedMessagingRequest,
	ConfigurationExtendedMessagingData
>('fetchStatuses', 'extendedMessagingConfiguration', {
	doneReducer: onRetrieveExtendedMessagingConfigurationDataSuccess
});
