import actionCreator from '../companyVesselTypesActionCreator';
import {
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'UPDATE';

export const updateCompanyVesselTypeAsync = actionCreator.async<
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Vessel Type'
	}
});

export const updateCompanyVesselType = actionCreator<
	UpdateCompanyVesselTypeRequest
>(ACTION_NAME);
