import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	retrieveCommodities,
	retrieveCommoditiesAsync
} from '../actions/retrieveCommodities';
import { RetrieveCommoditiesRequest } from 'services/api/commodities/commoditiesServiceTypes';

import { Action } from 'typescript-fsa';
import { getCommoditiesCount } from './../commoditiesSelectors';

export function* retrieveCommoditiesWorker(
	action: Action<RetrieveCommoditiesRequest>
): SagaIterator {
	const count = yield select(getCommoditiesCount);

	const request: RetrieveCommoditiesRequest = {
		index: count,
		sortBy: 'updatedOn:desc',
		...action.payload
	};

	yield put(retrieveCommoditiesAsync.started(request));
	try {
		const response = yield call(Api.Commodities.retrieveCommodities, request);
		yield put(
			retrieveCommoditiesAsync.done({
				result: response.data.elements,
				params: request,
				response
			})
		);
	} catch (error) {
		yield put(
			retrieveCommoditiesAsync.failed({
				error,
				params: request
			})
		);
	}
}

export default function* retrieveCommoditiesWatcher(): SagaIterator {
	yield takeLatest(retrieveCommodities.type, retrieveCommoditiesWorker);
}
