import { get } from 'lodash';
import { Success, Failure } from 'typescript-fsa';
import { FetchStatus } from 'services/api/apiTypes';
import { DownloadPortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { PortCallDocumentsState } from '../portCallDocumentsState';

function assignStatus(list: string[], status: FetchStatus) {
	return list.reduce((acc, id) => {
		acc[id] = status;
		return acc;
	}, {});
}

export const onDownloadPortCallDocumentStarted = (
	state: PortCallDocumentsState,
	params: DownloadPortCallDocumentRequest
): PortCallDocumentsState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		downloadAll: FetchStatus.PENDING,
		downloadedById: {
			...state.fetchStatuses.downloadedById,
			...assignStatus(params.portCallDocumentIds, FetchStatus.PENDING)
		}
	}
});

export const onDownloadPortCallDocumentSuccess = (
	state: PortCallDocumentsState,
	{ params }: Success<DownloadPortCallDocumentRequest, undefined>
): PortCallDocumentsState => {
	const isDownloadingAll = get(params, ['meta', 'downloadAll'], false);
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadAll: isDownloadingAll ? FetchStatus.SUCCESS : FetchStatus.IDLE,
			downloadedById: {
				...state.fetchStatuses.downloadedById,
				...assignStatus(params.portCallDocumentIds, FetchStatus.SUCCESS)
			}
		}
	};
};

export const onDownloadPortCallDocumentFailed = (
	state: PortCallDocumentsState,
	{ params }: Failure<DownloadPortCallDocumentRequest, Error>
): PortCallDocumentsState => {
	const isDownloadingAll = get(params, ['meta', 'downloadAll'], false);
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			downloadAll: isDownloadingAll ? FetchStatus.FAILURE : FetchStatus.IDLE,
			downloadedById: {
				...state.fetchStatuses.downloadedById,
				...assignStatus(params.portCallDocumentIds, FetchStatus.FAILURE)
			}
		}
	};
};
