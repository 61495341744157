import { PortCall } from 'services/api/portCalls/portCallsServiceTypes';

export const getLinkedAppointmentPortJob = (
	data: PortCall[],
	linkedPortCallId: string,
	linkedPortJobCode: string
) => {
	const linkedPortCall = data.find(
		portCall => portCall.id === linkedPortCallId
	);
	const linkedJob = linkedPortCall?.jobs.find(
		job => job.code === linkedPortJobCode
	);
	if (linkedJob) {
		return linkedJob;
	}
	return null;
};
