import React from 'react';
import { RouteComponentProps } from 'react-router';
import queryString from 'query-string';
import { signInRedirect } from 'services/userManager';
import Loading from 'components/Loading/Loading';
import { RouteParams } from './loginTypes';
import {
	getSignInRedirectState,
	getSignInRedirectExtraQueryParams
} from './loginUtils';

type LoginProps = RouteComponentProps<RouteParams>;

class Login extends React.Component<LoginProps> {
	componentDidMount() {
		const queryParams = queryString.parse(this.props.location.search || '');
		signInRedirect(
			getSignInRedirectState(queryParams),
			getSignInRedirectExtraQueryParams(queryParams)
		);
	}

	render() {
		return <Loading />;
	}
}

export default Login;
