import { isEmpty, omit, reduce } from 'lodash';
import { FiltersState } from 'store/filters/filtersState';
import { FilterRemoveActionParam } from '../actions/clear';
export const onClearOne = (
	state: FiltersState,
	action: FilterRemoveActionParam
) => {
	const { name, filter } = action;
	const newFilters = reduce(
		state[name],
		(result, value, key) => {
			const newValue = value.filter(item => {
				if (item.type === filter.type && item.key === filter.key) {
					return undefined;
				}
				return item;
			});

			// don't add filter if no values are left.
			// as an option, this can be done by adding saga to dispatch `destroy` action
			if (newValue.length) {
				result[key] = newValue;
			}
			return result;
		},
		{}
	);

	if (isEmpty(newFilters)) {
		return omit<FiltersState>(state, name);
	}

	return { ...state, [name]: newFilters };
};
