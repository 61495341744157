import { createSelector } from 'reselect';
import { get } from 'lodash';

import { AppState } from 'store-types';
import { getIsLoaded } from 'store/selectors';
import { FileExtension } from 'app-constants';
import { getFileExtension } from 'utils';
import { UploadType } from 'services/api/documents/documentsServiceTypes';

export const getDocumentStaticConfigByType = (
	state: AppState,
	type: UploadType
) => {
	return (
		state.documents.config.staticByType[type] || {
			documentType: '',
			url: '',
			maxFileSize: 52428800, // bytes = 50mb
			allowedExtensions: [],
			allowedContentTypes: []
		}
	);
};
export const getDocumentRelativeConfig = (state: AppState) =>
	state.documents.config.relative;

export const getAllowedFileNameCharacters = createSelector(
	getDocumentStaticConfigByType,
	config => config.allowedFileNameCharacters
);

export const getDocumentAllowedExtensions = createSelector(
	getDocumentStaticConfigByType,
	config => config.allowedExtensions.map((ext: string) => `.${ext}`).join(', ')
);

const getDocumentsMap = (state: AppState) => state.documents.byId;
const getDocumentFetchStatuses = (state: AppState) =>
	state.documents.fetchStatuses;

export const getDocumentFetchStatus = createSelector(
	getDocumentFetchStatuses,
	(_state: AppState, documentId: string) => documentId,
	(fetchStatuses, documentId) => fetchStatuses.documents[documentId]
);

export const getIsDocumentImageLoaded = createSelector(
	getDocumentFetchStatus,
	fetchStatus => getIsLoaded(fetchStatus)
);

const getDocument = (state: AppState, documentId: string) =>
	getDocumentsMap(state)[documentId];

export const getIsDocumentPDF = createSelector(
	getDocument,
	document =>
		!!(document && getFileExtension(document.name) === FileExtension.PDF)
);

export const getDocumentPagesCount = createSelector(getDocument, document =>
	get(document, 'split.count')
);

const getDocumentImages = (state: AppState, documentId: string) =>
	state.documents.documentImages[documentId];

export const getDocumentImageByPage = createSelector(
	getDocumentImages,
	(_state, _documentId, page: number) => page,
	(images, page) => {
		if (!images) {
			return;
		}
		return images[page];
	}
);

export const getDocumentImageUrl = createSelector(
	getDocumentImageByPage,
	pageImage => (pageImage ? pageImage.downloadUrl : '')
);
