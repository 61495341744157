import { Failure } from 'typescript-fsa';
import { isResponseError } from 'services/api/apiErrorUtils';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	VesselProgrammeState,
	initialState
} from 'store/vesselProgramme/vesselProgrammeState';
import moment from 'moment-timezone';
import { reduce } from 'lodash';

const getErrorDescriptions = (error?: Error) =>
	isResponseError(error)
		? /**
		   * TODO error interface not clear
		   * eventually fix typings
		   */
		  // eslint-disable-next-line @typescript-eslint/no-explicit-any
		  reduce<any, string[]>(
				error.response.data,
				(arr, val) => {
					const count = val.length > 1 ? ` [${val.length}]` : ``;
					arr.push(`${val[0]}${count}`);
					return arr;
				},
				[]
		  )
		: [];

export const onSaveVesselProgrammeSuccess = (
	state: VesselProgrammeState
): VesselProgrammeState => ({
	...state,
	editMode: false,
	comment: '',
	saveError: initialState.saveError,
	updatedOn: moment.tz().format()
});

export const onSaveVesselProgrammeFailed = (
	state: VesselProgrammeState,
	{ error }: Failure<null, Error>
): VesselProgrammeState => ({
	...state,
	editMode: true,
	comment: state.comment,
	saveError: {
		error,
		errorDescriprion: getErrorDescriptions(error)
	},
	updatedOn: moment.tz().format()
});

export const onSaveVesselProgramme = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	null,
	null
>('fetchStatuses', 'save', {
	doneReducer: onSaveVesselProgrammeSuccess,
	failedReducer: onSaveVesselProgrammeFailed
});
