import { UpdateCompanyGeneralConfigurationRequest } from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../companiesActionCreator';

const ACTION_NAME = 'UPDATE_COMPANY_GENERAL_CONFIGURATION';
export const updateCompanyGeneralConfigurationAsync = actionCreator.async<
	UpdateCompanyGeneralConfigurationRequest,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Data'
	}
});
export const updateCompanyGeneralConfiguration = actionCreator<
	UpdateCompanyGeneralConfigurationRequest
>(ACTION_NAME);
