import React from 'react';
import { Button, Col, Icon, Row } from 'components/antd';
import { Text, Flex, Amount } from 'components';
import styles from '../CargoLinkage.module.scss';
import { Justify } from 'components/types';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import { OperationTypeCode } from 'services/api/operations/operationsServiceTypes';
import LinkCargoPopover from '../LinkCargo/LinkCargoPopover';

interface CargoLineProps {
	nominationCargo: LinkedCargo;
	appointmentCargo?: LinkedCargo;
	unlinkedAppointmentCargos: LinkedCargo[];
	onDelete: (id: string) => void;
}

export const getCargoBox = (
	cargo: LinkedCargo,
	className = '',
	showLink = false
) => {
	return (
		<Flex className={styles.box} justify={Justify.BETWEEN}>
			<Flex className={styles.insideBox}>
				<Text.Box right bottom top="sm">
					<Text className={styles.subHeader}>Cargo / Booking #</Text>
					<br />
					<Text weight="bold">
						{cargo.cargoReferenceNumber ? cargo.cargoReferenceNumber : '--'}
					</Text>
				</Text.Box>
			</Flex>
			<Flex className={styles.insideBox}>
				<Text.Box right bottom top="sm">
					<Text className={styles.subHeader}>Commodity Details</Text>
					<br />
					<Text weight="bold">
						{cargo.commodityName
							? cargo.commodityName
							: cargo.mainCommodityCode}
					</Text>
				</Text.Box>
			</Flex>
			<Flex className={styles.insideBox}>
				<Text.Box right bottom top="sm">
					<Text className={styles.subHeader}>Nomination Qty</Text>
					<br />
					<Text weight="bold" className={className}>
						{cargo.commodityQuantity ? (
							<Amount
								value={cargo.commodityQuantity}
								uomCode={cargo.unitOfMeasurement}
							/>
						) : (
							'--'
						)}
					</Text>
				</Text.Box>
			</Flex>
			<Flex className={styles.insideBox}>
				<Text.Box right bottom top="sm">
					<Text className={styles.subHeader}>
						{cargo.operationType === OperationTypeCode.LOADING ||
						cargo.operationType === OperationTypeCode.LOADING_STS
							? 'Discharge Port'
							: 'Load Port'}
					</Text>
					<br />
					<Text weight="bold">
						{cargo.loadingPortName ? cargo.loadingPortName : '--'}
					</Text>
				</Text.Box>
			</Flex>
			{showLink && (
				<Flex>
					<Text.Box right bottom top="sm">
						<Icon type="link" color="primary" />
					</Text.Box>
				</Flex>
			)}
		</Flex>
	);
};

function CargoLine({
	nominationCargo,
	appointmentCargo,
	unlinkedAppointmentCargos,
	onDelete
}: CargoLineProps) {
	const className =
		appointmentCargo &&
		(nominationCargo.commodityQuantity !== appointmentCargo.commodityQuantity ||
			nominationCargo.unitOfMeasurement !== appointmentCargo.unitOfMeasurement)
			? styles.bgColor
			: '';
	return (
		<Row className={styles.row}>
			<Col sm={6} className={styles.col}>
				{getCargoBox(nominationCargo, className)}
				<Icon
					type="long-arrow-right"
					size="lg"
					color="primary"
					className={styles.icon}
				/>
			</Col>

			{appointmentCargo ? (
				<Col sm={6} className={styles.col}>
					{getCargoBox(appointmentCargo, className, true)}
					<Button
						icon="delete"
						transparent
						type="danger"
						className={styles.iconButton}
						iconSize="lg"
						onClick={() =>
							appointmentCargo.cargoId ? onDelete(appointmentCargo.cargoId) : ''
						}
					/>
				</Col>
			) : (
				<Col sm={6} className={styles.buttonCol}>
					<LinkCargoPopover
						cargosList={unlinkedAppointmentCargos}
						nominationCargoId={nominationCargo.cargoId}
					/>
				</Col>
			)}
		</Row>
	);
}

export default CargoLine;
