import { all, fork } from 'redux-saga/effects';

import retrievePrincipalServicesWatcher from './retrievePrincipalServicesSaga';
import addPrincipalServiceWatcher from './addPrincipalServiceSaga';
import retrievePrincipalServiceWatcher from './retrievePrincipalServiceSaga';
import updatePrincipalServiceWatcher from './updatePrincipalServiceSaga';
import updatePrincipalServicesMappingWatcher from './updatePrincipalServicesMappingSaga';
import removePrincipalServiceWatcher from './removePrincipalServiceSaga';
import onRemovePrincipalServiceWatcher from './onRemovePrincipalServiceSaga';
import removeServiceMappingWatcher from './removeServiceMappingSaga';

export default function*() {
	yield all([
		fork(retrievePrincipalServicesWatcher),
		fork(addPrincipalServiceWatcher),
		fork(retrievePrincipalServiceWatcher),
		fork(updatePrincipalServiceWatcher),
		fork(updatePrincipalServicesMappingWatcher),
		fork(removePrincipalServiceWatcher),
		fork(onRemovePrincipalServiceWatcher),
		fork(removeServiceMappingWatcher)
	]);
}
