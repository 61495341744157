import * as React from 'react';
import { TR, TD, FormattedTimeExtended, TRS } from 'components';
import { DashboardPortCall } from 'services/api/dashboard/dashboardServiceTypes';
import { PortJobs } from './PortJobs';
import styles from './JobSection.module.scss';
//import PortCallRowAlerts from './PortCallRowAlerts';

export interface PortCallRowProps {
	portCall: DashboardPortCall;
}

export const PortCallRow = ({ portCall }: PortCallRowProps) => (
	<>
		<TR className={styles.portCallRow}>
			<TD>
				<table>
					<TRS header className={styles.tableHeader}>
						<TR>
							<TD className={styles.bold}>{portCall.vessel.name}</TD>
							<TD className={styles.bold}>{portCall.port.name}</TD>
							<TD>
								<FormattedTimeExtended
									value={portCall.eospPlt || portCall.etaPlt}
									directPortTimeZoneId={portCall.port.timezone}
								/>
							</TD>
							<TD>
								<FormattedTimeExtended
									value={portCall.atbPlt || portCall.etbPlt}
									directPortTimeZoneId={portCall.port.timezone}
								/>
							</TD>
							<TD>
								<FormattedTimeExtended
									value={portCall.cospPlt || portCall.etsPlt}
									directPortTimeZoneId={portCall.port.timezone}
								/>
							</TD>
						</TR>
					</TRS>
				</table>
				{portCall.jobs.length > 0 && (
					<PortJobs jobs={portCall.jobs} portCallId={portCall.id} />
				)}
			</TD>
		</TR>
		{/*<PortCallRowAlerts
			portCallId={portCall.id}
			defaultJobCode={portCall.jobs[0].code}
			portCallTimeZone={portCall.port.timezone}
		/>*/}
	</>
);
