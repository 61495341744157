import { CompanyVesselsState } from 'store/companyVessels/companyVesselsState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	CompanyVesselData,
	CreateCompanyVesselRequest
} from 'services/api/companies/companiesServiceTypes';

export const onCreateCompanyVessel = makeFetchStatusReducers<
	CompanyVesselsState,
	'fetchStatuses',
	CreateCompanyVesselRequest,
	CompanyVesselData
>('fetchStatuses', 'create');
