import React from 'react';

import RecipientSelectContainer, {
	RecipientSelectContainerProps
} from './RecipientSelectContainer';
import RecipientSelectItem, {
	RecipientSelectItemProps
} from './RecipientSelectItem';
import { createSelectFieldMap } from 'components/antd/Select/Select';

import createFieldComponent, {
	CreateReduxField
} from 'components/antd/Form/ReduxField/createReduxField';

type RecipientSelectReduxFieldProps = Omit<
	RecipientSelectItemProps,
	'currentUserId'
>;
type RecipientSelectProps = Omit<RecipientSelectContainerProps, 'children'>;

class RecipientSelect extends React.Component<RecipientSelectProps> {
	static ReduxFormItem: CreateReduxField<RecipientSelectReduxFieldProps>;

	render() {
		const { value, onChange: onChangeProp, ...props } = this.props;
		return (
			<RecipientSelectContainer value={value} onChange={onChangeProp}>
				{({ value, onChange, currentUserId }) => (
					<RecipientSelectItem
						{...props}
						value={value}
						onChange={onChange}
						currentUserId={currentUserId}
					/>
				)}
			</RecipientSelectContainer>
		);
	}
}

const RecipientSelectItemContainer = (
	props: RecipientSelectReduxFieldProps
) => {
	return (
		<RecipientSelectContainer>
			{({ currentUserId }) => (
				<RecipientSelectItem {...props} currentUserId={currentUserId} />
			)}
		</RecipientSelectContainer>
	);
};
RecipientSelect.ReduxFormItem = createFieldComponent<
	RecipientSelectReduxFieldProps
>(
	RecipientSelectItemContainer,
	createSelectFieldMap<RecipientSelectReduxFieldProps>()
);

export default RecipientSelect;
