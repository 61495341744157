import { UpdateCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';
import actionCreator from '../savingsActionCreator';

const UPDATE_COST_SAVINGS = 'UPDATE_COST_SAVINGS';

export const updateCostSavingsAsync = actionCreator.async<
	UpdateCostSavingsRequest,
	void,
	Error
>(UPDATE_COST_SAVINGS);

export const updateCostSavings = actionCreator(UPDATE_COST_SAVINGS);
