import React, { useEffect, useCallback } from 'react';
import {
	PageFooter,
	PageHeader,
	Flex,
	Loading,
	Gap,
	ScrollableLayout
} from 'components';
import { Button } from 'components/antd';
import { navigateTo } from 'utils';
import { PATHS } from 'sections/App/RouteParams';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AppState } from 'store-types';
import PrettyJSON from 'sections/MasterDataExplorer/components/PrettyJSON/PrettyJSON';
import { Justify } from 'components/types';
import { getBankAcccount } from 'store/masterdata/bankAccounts/selectors';
import { retrieveBankAccount } from 'store/masterdata/bankAccounts/actions';
import { MasterDataExplorerTab } from 'sections/MasterDataExplorer/MasterDataExplorerSectionTypes';
import { usePrettifiedJSON } from 'sections/MasterDataExplorer/MasterDataExplorerHooks';
import CopyToClipboardWrapper from 'components/CopyToClipboardWrapper/CopyToClipboardWrapper';

type BankAccountDetailsProps = RouteComponentProps<{ id: string }>;

const goBack = () =>
	navigateTo(
		`/${PATHS.masterDataExplorer}/${MasterDataExplorerTab.BANK_ACCOUNTS}`
	);

const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({
	match: {
		params: { id }
	}
}) => {
	const dispatch = useDispatch();
	const bankAccount = useSelector(
		(state: AppState) => getBankAcccount(state, id),
		shallowEqual
	);
	const { prettifiedJSON } = usePrettifiedJSON(bankAccount);

	useEffect(() => {
		if (!bankAccount) {
			dispatch(retrieveBankAccount({ id }));
		}
	}, [id, dispatch, bankAccount]);

	const goToCompany = useCallback(() => {
		navigateTo(
			`/${PATHS.masterDataExplorer}/${MasterDataExplorerTab.COMPANIES}/${bankAccount.companyInfo.company.id}`
		);
	}, [bankAccount]);

	if (!bankAccount) {
		return <Loading />;
	}

	return (
		<ScrollableLayout>
			<PageHeader.NewTab onClose={goBack} isNewTab shouldNewTabClose={false}>
				{bankAccount.bankAccountName}
			</PageHeader.NewTab>
			<PageHeader shadowed>
				<Flex justify={Justify.BETWEEN}>
					<div>
						{bankAccount.companyInfo && (
							<Gap right>
								<Button type="link" onClick={goToCompany}>
									Related Company
								</Button>
							</Gap>
						)}
					</div>
					<CopyToClipboardWrapper copyContent={prettifiedJSON} />
				</Flex>
			</PageHeader>
			<ScrollableLayout>
				<Gap top="lg" bottom="md" isBlock>
					<PrettyJSON objToPrettify={bankAccount} />
				</Gap>
			</ScrollableLayout>
			<PageFooter>
				<Flex justify={Justify.BETWEEN}>
					<Button type="primary" transparent icon="left" onClick={goBack}>
						Back
					</Button>
				</Flex>
			</PageFooter>
		</ScrollableLayout>
	);
};

export default BankAccountDetails;
