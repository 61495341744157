import React, { useCallback } from 'react';
import { DataFetcher } from 'utils/components';
import { Error403 } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { EntityPermissionFetcher, AppNotifications } from 'containers';
import {
	EntityPermissionType,
	PermissionCode
} from 'services/api/permissions/permissionsServiceTypes';
import { retrieveEditPortJobData } from 'store/portJobs/actions';
import {
	getRetrieveEditPortJobDataFetchStatus,
	getIsUpdatePortJobPossible,
	getIsAgentFieldDisabled
} from 'store/portJobs/selectors';
import { ScrollableLayout } from 'components/Styles';
import SubHeader from '../SubHeader/SubHeaderWrapper';
import Form from '../CreatePortJob/Pages/Page2/PagePartials/Form/Form';
import ContextBar, { ContextBarType } from 'containers/ContextBar/ContextBar';
import { UserType } from 'services/api/users/userServiceTypes';

interface EditPortJobPermissionWrapperProps {
	portCallId: string;
	portJobCode: string;
}

const EditPortJobPermissionWrapper = ({
	portJobCode,
	portCallId
}: EditPortJobPermissionWrapperProps) => {
	const dispatch = useDispatch();
	const isUpdatePortJobPossible = useSelector(getIsUpdatePortJobPossible);
	const fetchStatus = useSelector(getRetrieveEditPortJobDataFetchStatus);
	const isAgentFieldDisabled = useSelector(getIsAgentFieldDisabled);
	const retrieveFormData = useCallback(() => {
		dispatch(retrieveEditPortJobData({ portCallId, portJobCode }));
	}, [dispatch, portCallId, portJobCode]);

	return (
		<EntityPermissionFetcher
			entityType={EntityPermissionType.PORTJOB}
			entityKey={portJobCode}
		>
			{({ canUpdate }) => {
				if (!canUpdate) {
					return <Error403 />;
				}
				if (!isUpdatePortJobPossible) {
					return <Error403 description={<p>Port Job cannot be edited</p>} />;
				}
				return (
					<ScrollableLayout>
						<SubHeader
							portCallId={portCallId}
							portJobCode={portJobCode}
							isPortJobDetailsHidden
							title={'JOB DETAILS'}
						/>
						<ScrollableLayout asColumn>
							<AppNotifications.Alert />
							<ContextBar
								title="Please provide the details of the Job"
								type={ContextBarType.ACTION}
								actor={[UserType.PRINCIPAL, UserType.HUB]}
								permissionCode={PermissionCode.MANAGE_PORTCALL}
								subtitle="First select the Job Type, and then fill out the other details about the Job."
								condition
							/>
							<DataFetcher
								fetchStatus={fetchStatus}
								dispatch={retrieveFormData}
							>
								{() => (
									<Form
										isInEditMode
										isAgentFieldDisabled={isAgentFieldDisabled}
									/>
								)}
							</DataFetcher>
						</ScrollableLayout>
					</ScrollableLayout>
				);
			}}
		</EntityPermissionFetcher>
	);
};

export default EditPortJobPermissionWrapper;
