import { call, select } from 'redux-saga/effects';
import Api from 'services/api';
import { makeDefaultExecutor, makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	UpdateServiceStatusRequest as request,
	UpdateServiceStatusPayload,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import { updateServiceStatusAsync as async } from '../actions';
import { getActivePortCallId } from 'store/portcalls/selectors';
import { getActivePortJobCode } from 'store/portJobs/selectors';

const api = Api.Finance.updateServiceStatus;
const commonExecutor = makeDefaultExecutor<request, PortJobService, Error>({
	api,
	async
});

export const executor = function*({
	action,
	jobServiceId,
	queries
}: UpdateServiceStatusPayload) {
	const portCallId = yield select(getActivePortCallId);
	const jobCode = yield select(getActivePortJobCode);
	yield call(commonExecutor, {
		portCallId,
		jobCode,
		action,
		jobServiceId,
		queries
	});
};

export const updateServiceStatusWatcher = makeTakeEveryWatcher<
	UpdateServiceStatusPayload,
	PortJobService,
	Error
>({ api, async }, executor);
