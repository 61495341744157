import { AppState } from 'store-types';
import { createSelector } from 'reselect';
import { getPositionedRotationStep, getRotationStepByPosition } from '.';
import { ReducerOperationUnit } from '../vesselProgrammeState';
import { find, groupBy, sortBy } from 'lodash';

const getVesselProgrammeOperationUnits = (state: AppState) =>
	Object.values(state.vesselProgramme.operationUnitsById);

export const getUnallocatedOperations = createSelector(
	getVesselProgrammeOperationUnits,
	operations => {
		const groupedByParentId = groupBy(
			operations,
			operation => operation?.parentOperationUnitId || 'parents'
		);
		// if there is no any operation unit, "parents" will be undefined, so we need to have a default value there ([])
		const parents = groupedByParentId['parents'] || [];
		const result = parents.reduce((acc, parent) => {
			const childrenByParentId =
				sortBy(
					groupedByParentId[parent.id]?.filter(
						operation => !operation.allocatedId
					),
					'splitIndex'
				) || [];
			if (parent.allocatedId) {
				return [...acc, ...childrenByParentId];
			}
			return [...acc, parent, ...childrenByParentId];
		}, []);
		return result;
	}
);

export const getAllocatedOperations = createSelector(
	getVesselProgrammeOperationUnits,
	getPositionedRotationStep,
	getRotationStepByPosition,
	(operations, positionedRotationStep, rotationStep) => {
		const filteredOperations = operations.filter(
			operation =>
				operation.allocatedId === positionedRotationStep.rotationStepId &&
				operation.job.agentRole === positionedRotationStep.agentMode
		);
		// below part is sorting the operations in the right order
		return rotationStep.units
			.filter(unitId => !!filteredOperations.find(unit => unit.id === unitId))
			.map(
				unitId =>
					filteredOperations.find(
						unit => unit.id === unitId
					) as ReducerOperationUnit
			);
	}
);

export const getAllocatedCargoByRotationStepId = createSelector(
	getVesselProgrammeOperationUnits,
	(_state: AppState, rotationStepId: string | undefined) => rotationStepId,
	(operations, rotationStepId) =>
		!!find(operations, { allocatedId: rotationStepId })
);
