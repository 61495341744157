import { useCallback, useContext } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getFormValues, change } from 'redux-form';
import { PermissionGroupWithUsers } from 'services/api/threads/threadsServiceTypes';
import { getThreadPermissionGroupsOptionsSelector } from 'store/thread/selectors';
import { UseModalContext } from 'context';
import {
	ThreadModalName,
	THREAD_GROUP_ASSIGNMENT_FORM_ID,
	ThreadGroupAssignmentFormData,
	ThreadGroupAssignmentFormFields
} from './AssignGroupModalTypes';

export function useAssignGroupModal() {
	const { showModal } = useContext(UseModalContext);

	const showAssignGroupModal = useCallback(() => {
		showModal(ThreadModalName.ASSIGN_GROUP);
	}, [showModal]);

	return {
		showAssignGroupModal
	};
}

export const useAssignGroupForm = () => {
	const dispatch = useDispatch();
	const formValues = useSelector(
		getFormValues(THREAD_GROUP_ASSIGNMENT_FORM_ID),
		shallowEqual
	) as ThreadGroupAssignmentFormData;
	const changeField = useCallback(
		(
			field: ThreadGroupAssignmentFormFields,
			value?: string | PermissionGroupWithUsers[] | null
		) => {
			dispatch(change(THREAD_GROUP_ASSIGNMENT_FORM_ID, field, value));
		},
		[dispatch]
	);
	const threadPermissionGroupsWithUsersInForm = useSelector(
		getThreadPermissionGroupsOptionsSelector,
		shallowEqual
	) as PermissionGroupWithUsers[];

	return {
		formValues,
		changeField,
		threadPermissionGroupsWithUsersInForm
	};
};
