import actionCreator from '../threadActionCreator';
import {
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREAD_EVENTS';

export const retrieveThreadEventsAsync = actionCreator.async<
	RetrieveThreadEventsRequest,
	RetrieveThreadEventsResponse,
	Error
>(ACTION_NAME);

export const retrieveThreadEvents = actionCreator<RetrieveThreadEventsRequest>(
	ACTION_NAME
);

export const resetThreadEvents = actionCreator('RESET_THREAD_EVENTS');
