import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { FinanceState } from '../financeState';
import { ReopenDaRequest } from 'services/api/finance/financeServiceTypes';

export const onReopenDaSet = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	ReopenDaRequest,
	undefined
>('fetchStatuses', 'reopenDaSet');
