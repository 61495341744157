import { RetrieveRegionsResponse } from 'services/api/regions/regionsServiceTypes';
import actionCreator from '../regionsActionCreator';

const ACTION_NAME = 'RETRIEVE_REGIONS';

export const retrieveRegionsAsync = actionCreator.async<
	null,
	RetrieveRegionsResponse,
	Error
>(ACTION_NAME);

export const retrieveRegions = actionCreator(ACTION_NAME);
export const resetRegions = actionCreator('RESET_REGIONS');
