import actionCreator from '../directBillsActionCreator';

import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

export const uploadDirectBillsDocumentationDone = actionCreator<
	UploadDocumentDoneResponse
>('UPLOAD_DIRECT_BILLS_DOCUMENTATION_DONE');

export const deleteDirectBillsFileInfo = actionCreator<string>(
	'DELETE_DIRECT_BILLS_FILE_INFO'
);
