import * as React from 'react';
import styles from './Settings.module.scss';

import { Col } from 'components/antd';

const Settings: React.SFC = props => {
	return (
		<Col sm={3} className={styles.root}>
			{props.children}
		</Col>
	);
};

export default Settings;
