import actionCreatorFactory from 'typescript-fsa';
import { RegisterUserRequest } from 'services/api/registration/registrationServiceTypes';

const actionCreator = actionCreatorFactory();

const RETRIEVE_USER_BY_TOKEN = 'registration/RETRIEVE_USER_BY_TOKEN';
export const retrieveUserByTokenAsync = actionCreator.async<
	string,
	RegisterUserRequest,
	boolean
>(RETRIEVE_USER_BY_TOKEN);

export const retrieveUserByToken = actionCreator<string>(
	retrieveUserByTokenAsync.type
);
