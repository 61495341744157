import * as React from 'react';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import { TRS } from 'components/DataTable';
import classNames from 'classnames';

import styles from './ComplexTable.module.scss';
import { ScrollableLayout } from 'components/Styles';

import { InfiniteTableProps } from '../InfiniteTable/InfiniteTable';
import withEmptyMessage from '../withEmptyMessage';

export interface ComplexTableProps extends InfiniteTableProps {
	tableClassName?: string;
	flex?: boolean;
	stretch?: boolean;
	customRef?: (elem: React.ReactNode) => void;
}

const ComplexTable: React.FC<ComplexTableProps> = ({
	tableClassName,
	className,
	lazyLoad,
	flex,
	stretch,
	customRef,
	children
}) => {
	const doneLoading = lazyLoad.doneLoading
		? lazyLoad.doneLoading
		: 'No more to load';

	return (
		<ScrollableLayout stretch={stretch} customRef={customRef}>
			<div>
				<table
					cellPadding="0"
					className={classNames(styles.table, tableClassName)}
				>
					<TRS className={className} flex={flex}>
						{children}
					</TRS>
				</table>
				<LazyLoad
					onEnter={lazyLoad.onEnter}
					hasMore={lazyLoad.hasMore}
					loading={lazyLoad.loading}
					doneLoading={doneLoading}
				/>
			</div>
		</ScrollableLayout>
	);
};

ComplexTable.defaultProps = {
	stretch: false
};

const ComplexTableWithEmptyMessage = withEmptyMessage<ComplexTableProps>(
	ComplexTable
);

const Component: React.FC<ComplexTableProps> = ({ locale, ...props }) => {
	return (
		<ComplexTableWithEmptyMessage
			{...props}
			showEmptyText={
				React.Children.count(props.children) === 0 && !props.lazyLoad.hasMore
			}
			locale={{
				emptyText: locale?.emptyText ? locale.emptyText : ''
			}}
		/>
	);
};

export default Component;
