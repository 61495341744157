import { call, put } from 'redux-saga/effects';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { DownloadSupplierInvoiceDocumentParams } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { RetrieveDownloadDocumentResponse } from 'services/api/documents/documentsServiceTypes';
import { downloadSplitSupplierInvoiceAsync as async } from './../actions';
import Api from 'services/api';
import { downloadExecutor } from 'store/documents/sagas/pollDownloadUrlSaga';
import { notify } from 'store/notifications/actions';

const POSTApi =
	Api.SupplierInvoice.postDownloadForSplitServiceSupplierInvoiceDocument;
const GETApi =
	Api.SupplierInvoice.pollDownloadForSplitServiceSupplierInvoiceDocument;

export const worker = function*(params: DownloadSupplierInvoiceDocumentParams) {
	yield put(async.started(params));
	try {
		yield put(notify.info('Your download is in progress...'));
		const result = yield call(downloadExecutor, {
			params,
			POSTApi,
			GETApi,
			isMultiFileDownload: params.isMultiFileDownload
		});
		yield put(
			async.done({
				result,
				params,
				response: null
			})
		);
	} catch (error) {
		yield put(
			async.failed({
				error,
				params
			})
		);
	}
};

export default makeTakeLatestWatcher<
	DownloadSupplierInvoiceDocumentParams,
	RetrieveDownloadDocumentResponse,
	Error
>({ async }, worker);
