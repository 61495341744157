import React, { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { ReduxFieldProps } from 'components/antd/Form/ReduxField/createReduxField';
import { SelectProps } from 'components/antd/Select/Select';
import { retrieveCountries } from 'store/countries';
import {
	getCountries,
	getIsRetrieveCountries
} from 'store/countries/countriesSelectors';
import LoadableSelect from 'components/LoadableSelect/LoadableSelect';

const AllCountrySelect = function(props: ReduxFieldProps<SelectProps>) {
	const dispatch = useDispatch();
	const options = useSelector(getCountries, shallowEqual);
	const retrieve = useCallback(() => {
		dispatch(retrieveCountries({}));
	}, [dispatch]);
	const fetchStatus = useSelector(getIsRetrieveCountries, shallowEqual);

	return (
		<LoadableSelect
			{...props}
			fetchStatus={fetchStatus}
			retrieve={retrieve}
			options={options}
			labelName="name"
		/>
	);
};

export default AllCountrySelect;
