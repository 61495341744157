import {
	removeAttachmentFromArr,
	removeFetchFile
} from 'store/documents/documentsHelpers';
import { DocumentsState } from 'store/documents/documentsState';

/**
 * Remove attachment
 */
export function onRemoveAttachment(
	state: DocumentsState,
	action: { uid: string }
): DocumentsState {
	return {
		...state,
		attachments: removeAttachmentFromArr(state.attachments, action.uid),
		filesFetchingNumber: removeFetchFile(state.filesFetchingNumber)
	};
}
