import actionCreator from '../supplierInvoiceActionCreator';
import { DeleteSupplierDocumentPageRequest } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';

export type deleteSupplierDocumentPageActionParams = Pick<
	DeleteSupplierDocumentPageRequest,
	'supplierInvoiceId' | 'serviceId' | 'actualNumber' | 'pageType' | 'documentId'
>;

export const DELETE_SUPPLIER_DOCUMENT_PAGE = 'DELETE_SUPPLIER_DOCUMENT_PAGE';
export const deleteSupplierDocumentPageAsync = actionCreator.async<
	DeleteSupplierDocumentPageRequest,
	null,
	Error
>(DELETE_SUPPLIER_DOCUMENT_PAGE);

export const deleteSupplierDocumentPage = actionCreator<
	deleteSupplierDocumentPageActionParams
>(DELETE_SUPPLIER_DOCUMENT_PAGE);
