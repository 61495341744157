import {
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse
} from 'services/api/companies/companiesServiceTypes';
import actionCreator from '../configurationActionCreator';

const ACTION_NAME = 'RETRIEVE_FEEDBACK_MANAGEMENT_GRID';
export const retrieveFeedbackManagementGrid = actionCreator(ACTION_NAME);
export const retrieveFeedbackManagementGridAsync = actionCreator.async<
	RetrieveConfigurationFeedbackManagementRequest,
	RetrieveFeedbackManagementGridResponse[],
	Error
>(ACTION_NAME);
