import React from 'react';
import { navigateTo } from 'utils';
import { Button } from 'components/antd';
import { Align } from 'components';
import { MasterDataExplorerTab } from 'sections/MasterDataExplorer/MasterDataExplorerSectionTypes';
import { LOBDDocument } from 'services/api/bankAccounts/bankAccountsServiceTypes';
import YesOrNoMark from '../../YesOrNoMark';
import styles from '../BankAccountsTab.module.scss';

export const getBankAccountsTableColumns = () => [
	{
		title: 'Account Name',
		dataIndex: 'bankAccountName',
		width: '15%'
	},
	{
		title: 'Id',
		dataIndex: 'id',
		width: '15%'
	},
	{
		title: 'goldenRecordId',
		dataIndex: 'goldenRecordId',
		width: '15%'
	},
	{
		title: 'Account Number',
		dataIndex: 'bankAccountNumber',
		width: '11%'
	},
	{
		title: 'Account Type Code',
		dataIndex: 'bankAccountTypeCode',
		width: '8%'
	},
	{
		title: 'Bank Name',
		dataIndex: 'bankName',
		width: '13%'
	},
	{
		title: 'isDraft',
		dataIndex: 'isDraft',
		width: '5%',
		className: styles.center,
		render: (isDraft: boolean) => <YesOrNoMark isSuccess={isDraft} />
	},
	{
		title: 'isDeleted',
		dataIndex: 'isDeleted',
		width: '5%',
		className: styles.center,
		render: (isDeleted: boolean) => <YesOrNoMark isSuccess={isDeleted} />
	},
	{
		title: 'LOBD',
		dataIndex: 'lobd',
		width: '5%',
		className: styles.center,
		render: (lobd: LOBDDocument) => <YesOrNoMark isSuccess={!!lobd} />
	},
	{
		width: '8%',
		render: ({ id }: { id: string }) => (
			<Align align="right">
				<Button
					type="primary"
					size="xsmall"
					onClick={() =>
						navigateTo(`${MasterDataExplorerTab.BANK_ACCOUNTS}/${id}`)
					}
				>
					Show details
				</Button>
			</Align>
		)
	}
];
