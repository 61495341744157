import { ConfigurationState } from '../configurationState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { UploadDocumentDoneResponse } from 'services/api/documents/documentsServiceTypes';

export const onUploadConfigurationPrefferedAgentsFile = makeFetchStatusReducers<
	ConfigurationState,
	'fetchStatuses',
	UploadDocumentDoneResponse,
	null
>('fetchStatuses', 'uploadPreferredAgentsFile');
