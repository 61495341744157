import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import EditUserPage from 'sections/User/EditUser/EditUserPage';
import { getUserIdSelector } from 'store/auth/selectors';

const MyProfileRoute: FC<{}> = () => {
	const userId = useSelector(getUserIdSelector);

	return <EditUserPage userId={userId} />;
};

export default MyProfileRoute;
