import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import {
	CheckPortJobPermissionsRequest,
	CheckPortJobPermissionsResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { checkPortJobPermissionsAsync } from '../actions';
import Api from 'services/api';

const apiCall = Api.PortJobs.checkPortJobPermissions;

export default makeTakeEveryWatcher<
	CheckPortJobPermissionsRequest,
	CheckPortJobPermissionsResponse,
	Error
>({
	api: apiCall,
	async: checkPortJobPermissionsAsync
});
