import { combineReducers } from 'redux';

// App reducers
import notifications from 'store/notifications/notificationsReducer';
import auth from 'store/auth/authReducer';
import availableGroups from 'store/thread/threadAvailableGroupsReducer';
import commodities from 'store/commodities/commoditiesReducer';
import { companies, companiesForUser } from 'store/companies/companiesReducer';
import companyVessels from 'store/companyVessels/companyVesselsReducer';
import countries from 'store/countries/countriesReducer';
import currencies from 'store/currencies/currenciesReducer';
import common from 'store/common/commonReducer';

import dashboard from 'store/dashboard/dashboardReducer';
import developerPortal from 'store/developerPortal/developerPortalReducer';
import opticConfiguration from 'store/opticConfiguration/opticConfigReducer';

import configuration from 'store/configuration/configurationReducer';
import configurationFiles from 'store/configurationFiles/configurationFilesReducer';
import configurationServices from 'store/configurationServices/configurationServicesReducer';

import directBills from 'store/directBills/directBillsReducer';
import documents from 'store/documents/documentsReducer';
import threadEvents from 'store/thread/eventsReducer';
import finance from 'store/finance/financeReducer';
import savings from 'store/savings/savingsReducer';
import financeFundings from 'store/financeFundings/financeFundingsReducer';
import groups from 'store/groups/groupsReducer';
import groupUsers from 'store/groupUsers/groupUsersReducer';
import internalComments from 'store/internalComments/internalCommentsReducer';
import masterdata from 'store/masterdata/masterdataReducer';
import messages from 'store/messages/messagesReducer';
import navigation from 'store/navigation/navigationReducer';
import operations from 'store/operations/operationsReducer';
import portCallDocuments from 'store/portCallDocuments/portCallDocumentsReducer';
import portCallMeasurements from 'store/portCallMeasurements/portCallMeasurementsReducer';
import portcalls from 'store/portcalls/portCallsReducer';
import portCall from 'store/portCall/portCallReducer';
import linkJob from 'store/linkJob/linkJobReducer';
import portCallOperations from 'store/portCallOperations/portCallOperationsReducer';
import portjob from 'store/portJobs/portJobsReducer';
import registration from 'store/registration/registrationReducer';
import templates from 'store/templates/templatesReducer';
import thread from 'store/thread/threadReducer';
import threads from 'store/threads/threadsReducer';
import units from 'store/units/unitsReducer';
import users from 'store/users/usersReducer';
import wizardForm from 'store/wizardForm/wizardFormReducer';
import vesselProgramme from 'store/vesselProgramme/vesselProgrammeReducer';
import drafts from 'store/drafts/draftsReducer';
import modals from 'store/modals/reducers';
import permissions from 'store/permissions/permissionsReducer';
import filters from 'store/filters/filtersReducer';
import form from 'store/form/formReducer';
import companyVesselTypes from 'store/companyVesselTypes/companyVesselTypesReducers';
import supplierInvoice from 'store/supplierInvoice/supplierInvoiceReducer';
import contextBars from 'store/contextBars/contextBarsReducer';
import route from 'store/route/routeReducer';

export default combineReducers({
	auth,
	commodities,
	companies,
	companiesForUser,
	companyVessels,
	countries,
	currencies,
	contextBars,
	dashboard,
	developerPortal,
	configuration,
	configurationFiles,
	configurationServices,
	common,
	directBills,
	documents,
	drafts,
	finance,
	savings,
	financeFundings,
	form,
	groups: combineReducers({
		groups,
		users: groupUsers
	}),
	internalComments,
	masterdata,
	messages,
	modals,
	navigation,
	notifications,
	operations,
	opticConfiguration,
	linkJob,
	portcalls,
	portCall,
	portCallDocuments,
	portCallMeasurements,
	portCallOperations,
	portjob,
	registration,
	supplierInvoice,
	threads,
	thread: combineReducers({
		thread,
		events: threadEvents,
		availableGroups
	}),
	templates,
	units,
	users,
	wizardForm,
	vesselProgramme,
	permissions,
	filters,
	companyVesselTypes,
	route
});
