import * as React from 'react';

import { Col, Row } from 'components/antd';
import { PageHeader, Filters } from 'components';
import { ModalContext } from 'context';
import UsersFilters, {
	DEFAULT_STATUS_FILTER_VALUE
} from '../UsersFilters/UsersFilters';
import { resetUsers, setUserFilters } from 'store/users/actions';

import { UserFilters } from 'store/users/usersState';
import { FilterItem } from 'store/filters/filtersState';
import { connect } from 'react-redux';
import {
	getUsersFiltersSelector,
	getUserFiltersResultSelector
} from 'store/users/usersSelectors';
import { PermissionButton } from 'components/Permission';
import { PermissionCode } from 'services/api/permissions/permissionsServiceTypes';
import { Justify } from 'components/types';
import { AppState } from 'store-types';
import { UsersModalsName } from '../UsersModals/UsersModalsTypes';

interface UsersHeaderProps {
	// from mapStateToProps
	filters: UserFilters;
	filtersResult: FilterItem[];
	resetUsers: typeof resetUsers;
	setUserFilters: typeof setUserFilters;
}

export class UsersHeader extends React.Component<UsersHeaderProps> {
	componentWillUnmount() {
		this.props.resetUsers();
	}

	onFiltersChange = (searchOptions: UserFilters) => {
		this.props.setUserFilters(searchOptions);
		this.props.resetUsers();
	};

	onClearAllFilters = () => {
		this.onFiltersChange({
			search: [],
			status: DEFAULT_STATUS_FILTER_VALUE
		});
	};

	onClearOneFilter = (tag: FilterItem) => {
		if (tag.type === 'Status') {
			this.onFiltersChange({
				search: this.props.filters.search,
				status: DEFAULT_STATUS_FILTER_VALUE
			});
		} else {
			/**
			 * Type of a group should be taken into account
			 * since items with the same `id` can be repeated in multiple groups
			 */
			const search = (this.props.filters.search || []).filter(item =>
				item.type === tag.type ? item.id !== tag.key : item
			);
			this.onFiltersChange({
				search,
				status: this.props.filters.status
			});
		}
	};

	render() {
		const { filters, filtersResult } = this.props;
		return (
			<PageHeader stretch shadowed>
				<Row>
					<Col sm={9} xs={12}>
						<UsersFilters filters={filters} onChange={this.onFiltersChange} />
					</Col>
					<Col sm={3} xs={12} justify={Justify.END}>
						<div>
							<ModalContext.Consumer>
								{({ showModal }) => (
									<PermissionButton
										tooltip={{ placement: 'left' }}
										permissionCode={PermissionCode.MANAGE_USERS}
										type="primary"
										className="qaa_invite_new_user_button"
										onClick={() => showModal(UsersModalsName.INVITE)}
									>
										Invite New User
									</PermissionButton>
								)}
							</ModalContext.Consumer>
						</div>
					</Col>
				</Row>
				<Filters.Tags
					tags={filtersResult}
					clearOne={this.onClearOneFilter}
					clearAll={this.onClearAllFilters}
				/>
			</PageHeader>
		);
	}
}

export default connect(
	(state: AppState) => ({
		filters: getUsersFiltersSelector(state),
		filtersResult: getUserFiltersResultSelector(state)
	}),
	{
		setUserFilters,
		resetUsers
	}
)(UsersHeader);
