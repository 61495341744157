import Api from 'services/api';
import { LabeledValue, EntityCode } from 'app-types';
import {
	AgentResponsibility,
	SearchPreferredPortLPAsRequest,
	CompanyAgentResponsibility
} from 'services/api/ports/portsServiceTypes';
import { isNil, includes, isEqual } from 'lodash';
import {
	SearchCompaniesRequest,
	OrganisationType
} from 'services/api/companies/companiesServiceTypes';
import { FormData } from 'sections/PortJob/CreatePortJob/createPortJobTypes';
import { PageState } from './Form';
import { entityToLabeledValue } from 'components/antd/Form/Form.func';

export const getValueKey = (value: LabeledValue | null | undefined) => {
	return value ? value.key : null;
};

export const optionalEntityCodeToLabeledValue = (
	obj: EntityCode | undefined
): LabeledValue<string> | undefined =>
	obj && {
		key: obj.code,
		label: obj.name
	};

export const retrievePreferredAgents = (
	params: SearchPreferredPortLPAsRequest
) => {
	return Promise.all([
		Api.Ports.retrievePortLPAs({
			...params,
			agentResponsibility: AgentResponsibility.CONTROLLING_AGENT_COMPANY
		}),
		Api.Ports.retrievePortLPAs({
			...params,
			agentResponsibility: AgentResponsibility.PERFORMING_AGENT_COMPANY
		})
	]).then(values => {
		const filterFunc = (agent: CompanyAgentResponsibility) =>
			!isNil(agent.agentResponsibility);
		return {
			preferredControllingAgents: values[0].data.elements.filter(filterFunc),
			preferredPerformingAgents: values[1].data.elements.filter(filterFunc)
		};
	});
};

/**
 * Retrieve companies with provided role.
 * If role isn't defined, all companies will be retrieved.
 * Companies belonging to selected HUB Principal
 * are retrieved if current user has role - HUB
 * Note LPAs are singled out and retrieved from the already selected port
 */
export const onSearchCompaniesFunc = (
	options: SearchCompaniesRequest = {} as SearchCompaniesRequest,
	withParentId = false,
	formValues: FormData
) => (searchTerm: string) => {
	const params: SearchCompaniesRequest = {
		searchTerm,
		sortBy: 'name:asc',
		onlyActive: true, // to not show deleted companies
		...options
	};

	const principalCompanyId = getValueKey(formValues.hubPrincipalCompany);
	if (withParentId && principalCompanyId) {
		params.withParentId = principalCompanyId;
	}

	if (includes(options.organisationTypes, OrganisationType.LPA)) {
		return Api.Ports.searchPortLPAsForAutocomplete({
			portId: formValues.portCall.port.key,
			searchTerm: params.searchTerm
		});
	} else {
		return Api.Companies.searchCompaniesForAutocomplete(params);
	}
};

// change agents to preferred
export const getAgentsOnUpdate = (
	nextState: PageState,
	preferredControllingAgents: CompanyAgentResponsibility[],
	preferredPerformingAgents: CompanyAgentResponsibility[]
) => {
	let performingAgent;
	let controllingAgent;
	const performingAgentShouldBeChanged =
		nextState.preferredPerformingAgents &&
		!isEqual(nextState.preferredPerformingAgents, preferredPerformingAgents);
	const controllingAgentShouldBeChanged =
		nextState.preferredControllingAgents &&
		!isEqual(nextState.preferredControllingAgents, preferredControllingAgents);
	if (performingAgentShouldBeChanged) {
		performingAgent =
			nextState.preferredPerformingAgents.length > 0
				? entityToLabeledValue(nextState.preferredPerformingAgents[0])
				: null;
	}
	if (controllingAgentShouldBeChanged) {
		controllingAgent =
			nextState.preferredControllingAgents.length > 0
				? entityToLabeledValue(nextState.preferredControllingAgents[0])
				: null;
	}
	if (isEqual(controllingAgent, performingAgent)) {
		return { performingAgent };
	}
	// return undefined if change is not required
	return { performingAgent, controllingAgent };
};
