import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Gap, ScrollableLayout } from 'components';
import { Button, Col, Modal, Row, Tabs, Tag } from 'components/antd';
import { Align, Justify } from 'components/types';
import {
	PortCallDocument,
	PortCallDocumentPage
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

import { navigateTo } from 'utils';
import { AppState } from 'store-types';

import styles from './DocumentPageViewerV2.module.scss';
import DocumentContainer from './Components/DocumentContainer';
import { PageType } from 'store/finance/constants';
import { UploadCustomRequest } from 'services/api/documents/documentsServiceTypes';
import DocumentContext, { UseDocumentContext } from './DocumentContext';
import { getIsDocumentProcessingLoading } from 'store/documents/selectors';

interface DocumentPageViewerHelpers {
	activePage: number;
	onPageSelect: (n: number) => void;
}

interface DocumentPageViewerV2Props {
	document: PortCallDocument;
	isDocumentPDF: boolean;
	isAnnotatable: boolean;
	returnUrl: string;
	onDownloadAll: () => void;
	total: number;
	baseUrl: string;
	activePage: number;
	activePageType: PageType;
	canDeletePage: boolean;
	isSupplierInvoice?: boolean;
	onPageDelete: (
		page: PortCallDocumentPage,
		pageType: PageType,
		deleteBoth?: boolean
	) => void;
	onSaveAnnotation: (
		request: UploadCustomRequest,
		actualNumber: number
	) => void;
	onRestoreOriginal: () => void;
	//mapStateToProps
	isAnnotationSaving: boolean;
}

interface DocumentPageViewerV2State {
	activePage: number;
	isModalVisible: boolean;
	nextPage: number;
	onModalConfirm: () => void;
}

class DocumentPageViewer extends Component<
	DocumentPageViewerV2Props,
	DocumentPageViewerV2State
> {
	static contextType = UseDocumentContext;
	context!: React.ContextType<typeof UseDocumentContext>;

	constructor(props: DocumentPageViewerV2Props) {
		super(props);
		this.state = {
			activePage: props.activePage || 1,
			isModalVisible: false,
			nextPage: 1,
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onModalConfirm: () => {}
		};
	}

	componentDidMount() {
		if (!this.props.activePage || this.props.activePage > this.props.total) {
			this.updateActivePageOnTotalChange();
		}
		if (
			this.props.activePageType === PageType.UPDATED &&
			!this.props.document.pages[this.props.activePage - 1]
				?.annotatedDownloadUrl &&
			this.props.activePage <= this.props.total
		) {
			navigateTo(
				`${this.props.baseUrl}/${this.state.activePage}/${PageType.ORIGINAL}`
			);
		}
	}

	componentDidUpdate(prevProps: DocumentPageViewerV2Props) {
		if (!this.props.activePage || prevProps.total !== this.props.total) {
			this.updateActivePageOnTotalChange();
		}
		if (prevProps.activePage !== this.props.activePage) {
			this.changeTab(this.props.activePage);
		}
		if (
			this.props.activePageType === PageType.UPDATED &&
			!this.props.document.pages[this.props.activePage - 1]
				?.annotatedDownloadUrl &&
			prevProps.total === this.props.total
		) {
			navigateTo(
				`${this.props.baseUrl}/${this.state.activePage}/${PageType.ORIGINAL}`
			);
		}
	}

	updateActivePageOnTotalChange = () => {
		const activePage = this.props.activePage || 1;
		const pageNumber =
			this.props.activePage > this.props.total ? this.props.total : activePage;
		navigateTo(`${this.props.baseUrl}/${pageNumber}/${PageType.ORIGINAL}`);
	};

	onDownloadAll = () => {
		this.props.onDownloadAll();
	};

	onClose = () => {
		if (this.context.isAnnotated) {
			this.setState({
				isModalVisible: true,
				onModalConfirm: this.onCloseConfirm
			});
		} else {
			this.close();
		}
	};

	close = () => {
		navigateTo(this.props.returnUrl);
	};

	changeTab = (activePage: number) => {
		this.setState({ activePage: Number(activePage) });
	};

	onTabSelect = (activePage: string | number) => {
		if (this.context.isAnnotated) {
			this.setState({
				isModalVisible: true,
				nextPage: Number(activePage),
				onModalConfirm: this.onTabSwitchConfirm
			});
		} else {
			navigateTo(`${this.props.baseUrl}/${activePage}/${PageType.ORIGINAL}`);
		}
	};

	onPageTypeSelect = (pageType: PageType) => {
		if (this.context.isAnnotated) {
			this.setState({
				isModalVisible: true,
				onModalConfirm: () => {
					this.setState({ isModalVisible: false });
					this.context.setIsAnnotated(false);
					navigateTo(
						`${this.props.baseUrl}/${this.state.activePage}/${pageType}`
					);
				}
			});
		} else {
			navigateTo(`${this.props.baseUrl}/${this.state.activePage}/${pageType}`);
		}
	};

	onModalClose = () => {
		this.setState({ isModalVisible: false });
	};

	onTabSwitchConfirm = () => {
		this.setState({
			isModalVisible: false
		});
		this.context.setIsAnnotated(false);
		navigateTo(
			`${this.props.baseUrl}/${this.state.nextPage}/${PageType.ORIGINAL}`
		);
	};

	onCloseConfirm = () => {
		this.setState({
			isModalVisible: false
		});
		navigateTo(this.props.returnUrl);
	};

	getImageUrl = (index: number) => {
		const page = this.props.document.pages[index];
		const url =
			this.props.activePageType === PageType.UPDATED
				? page.annotatedDownloadUrl
				: page.downloadUrl;

		return url || '';
	};

	getDocumentTag = (isAnnotated: boolean) =>
		isAnnotated ? (
			<Tag type="warning">Updated</Tag>
		) : (
			<Tag type="info">Original</Tag>
		);

	render() {
		const {
			isDocumentPDF,
			isAnnotatable,
			document,
			activePageType,
			canDeletePage,
			onPageDelete,
			onRestoreOriginal,
			onSaveAnnotation,
			isAnnotationSaving,
			isSupplierInvoice = false
		} = this.props;
		const { isModalVisible, activePage, onModalConfirm } = this.state;
		const isDocumentAnnotated = document.pages.some(page => page.isAnnotated);
		return (
			<>
				<ScrollableLayout stretch className={styles.wrapper}>
					<Row className={styles.row}>
						<Col xs={9} align={Align.MIDDLE}>
							<Gap left>
								<span className={styles.header}>{document.name}</span>
							</Gap>
							<Gap left>{this.getDocumentTag(isDocumentAnnotated)}</Gap>
						</Col>
						<Col xs={3} justify={Justify.END}>
							<Button
								icon="download-new"
								type="primary"
								ghost
								onClick={this.onDownloadAll}
							>
								Download All
							</Button>
							<Gap left right>
								<Button
									icon="close-new"
									type="primary"
									ghost
									onClick={this.onClose}
									disabled={isAnnotationSaving}
									tooltip={
										isAnnotationSaving
											? {
													title: 'Saving in progress.',
													placement: 'bottomRight'
											  }
											: undefined
									}
								>
									Close
								</Button>
							</Gap>
						</Col>
					</Row>
					<Tabs
						className={styles.tab}
						activeKey={String(activePage)}
						onChange={this.onTabSelect}
						destroyInactiveTabPane
					>
						{this.props.document.pages.map((page, index) => {
							const number = index + 1;
							return (
								<Tabs.TabPane
									tab={`Page ${page.number}`}
									key={String(number)}
									disabled={isAnnotationSaving}
								>
									<DocumentContainer
										pageNumber={number}
										imageUrl={this.getImageUrl(index)}
										document={document}
										isDocumentPDF={isDocumentPDF}
										isAnnotatable={isAnnotatable}
										fileName={document.file.fileName}
										activePage={page}
										activePageType={activePageType}
										onSaveAnnotation={onSaveAnnotation}
										onPageTypeSelect={this.onPageTypeSelect}
										isDocumentAnnotated={isDocumentAnnotated}
										canDeletePage={canDeletePage}
										onPageDelete={onPageDelete}
										isSupplierInvoice={isSupplierInvoice}
										onRestoreOriginal={onRestoreOriginal}
									/>
								</Tabs.TabPane>
							);
						})}
					</Tabs>
				</ScrollableLayout>
				<Modal
					visible={isModalVisible}
					closable={false}
					cancelText="Cancel"
					okText="Confirm"
					onOk={onModalConfirm}
					onCancel={this.onModalClose}
				>
					Unsaved annotation will be lost. Are you sure to proceed?
				</Modal>
			</>
		);
	}
}

const DocumentPageViewerV2: React.FC<DocumentPageViewerV2Props> = props => (
	<DocumentContext.Provider>
		<DocumentPageViewer {...props} />
	</DocumentContext.Provider>
);

export default connect((state: AppState) => ({
	isAnnotationSaving: getIsDocumentProcessingLoading(state)
}))(DocumentPageViewerV2);
