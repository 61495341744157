import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	TogglePortJobStatusParams,
	TogglePortJobResponse
} from 'services/api/portJobs/portJobsServiceTypes';

const doneReducer = (
	state: PortJobState,
	action: Success<TogglePortJobStatusParams, TogglePortJobResponse>
): PortJobState => {
	const jobCodeToUpdate = action.params.jobCode;
	const newById = Object.keys(state.byId).reduce((acc, key) => {
		acc[key] = {
			...state.byId[key],
			status:
				state.byId[key].code === jobCodeToUpdate
					? action.result.status
					: state.byId[key].status
		};
		return acc;
	}, {});
	return {
		...state,
		byId: newById
	};
};

export const onTogglePortJobStatus = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	TogglePortJobStatusParams,
	TogglePortJobResponse
>('fetchStatuses', 'all', { doneReducer });
