import Api from 'services/api';
import { SagaIterator } from 'redux-saga';
import { Action } from 'typescript-fsa';
import { put, takeLatest, call, takeMaybe } from 'redux-saga/effects';
import {
	RetrieveJobParams,
	PortJob
} from 'services/api/portJobs/portJobsServiceTypes';
import { addCompaniesDraft, resetDrafts } from 'store/drafts/actions';
import {
	retrievePortJobAsync as async,
	retrieveMailboxesForPrincipal
} from 'store/portJobs/actions';
import { setPortJobUpToDate } from '../actions/setPortJobUpToDate';

import { safeRedirectToPortCallOverview } from './portJobUtilsSagas';
import { changeRoute } from 'store/route/actions';
import { showFailOutdatedNotification } from 'utils/sagaHelpers/sagaUtils';

const apiCall = Api.PortJobs.retrievePortJob;

export function* executor(
	actionParams: RetrieveJobParams,
	api: typeof apiCall
): SagaIterator {
	yield put(async.started(actionParams));

	try {
		const response = yield call(api, actionParams);

		const { portJobCompanies = [] } = response.data as PortJob;
		yield put(resetDrafts());
		yield put(addCompaniesDraft(portJobCompanies));

		yield put(
			async.done({
				result: response.data,
				params: actionParams,
				response
			})
		);
		yield put(
			retrieveMailboxesForPrincipal({
				companyId: response.data.hubPrincipalCompany.id,
				onlyDefaultForCompany: true
			})
		);

		yield put(setPortJobUpToDate(true));
	} catch (error) {
		yield put(
			async.failed({
				error,
				params: actionParams
			})
		);
		yield call(
			safeRedirectToPortCallOverview,
			actionParams.portCallId,
			actionParams.jobCode
		);
		yield takeMaybe(changeRoute);
		yield call(showFailOutdatedNotification);
	}
}

function* worker({ payload }: Action<RetrieveJobParams>): SagaIterator {
	yield call(executor, payload, apiCall);
}

export default function*() {
	yield takeLatest(async.type, worker);
}
