/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig } from 'axios';
import { Entity } from 'app-types';

export interface PagedRequest {
	limit?: number;
	index?: number;
	sortBy?: string;
}

export interface ResultCollection<T> {
	count: number;
	elements: T[];
}

export interface SearchResultCollection<T = string, R = Entity> {
	groupType: T;
	needShowMore: boolean;
	results: R[];
}

export interface AxiosTypedResponse<T> {
	data: T;
	status: number;
	statusText: string;
	headers: any;
	config: AxiosRequestConfig;
}

export interface AxiosTypedPromise<T> extends Promise<AxiosTypedResponse<T>> {}

export interface SearchRequest {
	searchTerm?: string;
	limit?: number;
	countryCodes?: string[];
}

export interface EntityId {
	id: string;
}

export enum FetchStatus {
	IDLE = 'idle',
	PENDING = 'pending',
	SUCCESS = 'success',
	FAILURE = 'failure'
}
