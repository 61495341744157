import actionCreator from '../documentsActionCreator';
import {
	RetrieveDocumentConfigParams,
	RetrieveStaticDocumentConfigResponse
} from 'services/api/documents/documentsServiceTypes';

/**
 * Get basic file configuration (action, documentType, maxFileSize, allowedExtension & allowedContentTypes)
 */
const FETCH_STATIC_CONFIGURATION = 'FETCH_STATIC_CONFIGURATION';

export const retrieveStaticDocumentConfigAsync = actionCreator.async<
	RetrieveDocumentConfigParams,
	RetrieveStaticDocumentConfigResponse,
	Error
>(FETCH_STATIC_CONFIGURATION);

export const retrieveStaticDocumentConfig = actionCreator<
	RetrieveDocumentConfigParams | undefined
>(FETCH_STATIC_CONFIGURATION);
