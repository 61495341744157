import React from 'react';
import { Modal } from 'components/antd';
import ExtendedMessagingContainer from '../../ExtendedMessagingModal/ExtendedMessagingContainer';
import { EXTENDED_MAIL_TYPE } from 'sections/PortCall/PortJob/PortJobConstants';

interface AppointMailModalProps {
	isVisible: boolean;
	closeAppointmentMailModal: () => void;
	resetVendorSelection: () => void;
}

const AppointmentMailModal: React.FC<AppointMailModalProps> = ({
	isVisible,
	closeAppointmentMailModal,
	resetVendorSelection
}) => {
	const [isWarningVisible, setWarning] = React.useState(false);
	return (
		<>
			<Modal
				destroyOnClose
				title="Job details will be sent to"
				visible={isVisible}
				onClose={() => setWarning(true)}
				width={1366}
				fullSizeModal
				footer={null}
			>
				<ExtendedMessagingContainer
					closeExtendedMessagingModal={closeAppointmentMailModal}
					mailType={EXTENDED_MAIL_TYPE.APPOINTMENT_EMAIL}
				/>
			</Modal>
			<Modal
				okText="Yes"
				cancelText="No"
				visible={isWarningVisible}
				closable={false}
				onCancel={() => setWarning(false)}
				onOk={() => {
					setWarning(false);
					resetVendorSelection();
				}}
			>
				The Appoint job process will be cancelled. Do you want to continue?
			</Modal>
		</>
	);
};

export default AppointmentMailModal;
