import actionCreator from '../threadsActionCreator';
import {
	RetrieveThreadsStatsRequest,
	RetrieveThreadsStatsResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'RETRIEVE_THREADS_STATS';

export const retrieveThreadsStats = actionCreator<RetrieveThreadsStatsRequest>(
	ACTION_NAME
);
export const retrieveThreadsStatsAsync = actionCreator.async<
	RetrieveThreadsStatsRequest,
	RetrieveThreadsStatsResponse,
	Error
>(ACTION_NAME);
