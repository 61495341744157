import { AxiosError } from 'axios';
import actionCreator from '../threadActionCreator';
import {
	AssignJobToThreadParams,
	AssignJobToThreadResponse
} from 'services/api/threads/threadsServiceTypes';

const ACTION_NAME = 'ASSIGN_JOB_TO_THREAD';

export const assignJobToThreadAsync = actionCreator.async<
	AssignJobToThreadParams,
	AssignJobToThreadResponse,
	AxiosError
>(ACTION_NAME, {
	behaviour: { type: 'react-to-changes-child' }
});

export const assignJobToThread = actionCreator<AssignJobToThreadParams>(
	ACTION_NAME
);
