import * as React from 'react';
import { MultipleEntities, Gap } from 'components';
import { FormattedTime } from 'containers';
import { SupplierInvoiceRow } from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { ColumnProps } from 'components/antd/Table/TableTypes';
import DownloadSupplierInvoiceButton from './DownloadSupplierInvoiceButton';
import SupplierInvoiceTotal from './columns/SupplierInvoiceTotal';
import SupplierInvoiceSettingsButton from './columns/SupplierInvoiceSettingsButton/SupplierInvoiceSettingsButton';
import GLMark from 'components/GLMark/GLMark';
import { Icon, Tag } from 'components/antd';
import {
	DEFAULT_NO_VALUE_PLACEHOLDER,
	DEFAULT_NOT_AVAILABLE_PLACEHOLDER
} from 'app-constants';
import CreditMark from 'components/CreditMark/CreditMark';
import {
	getIsSupplierInvoiceSettingsIconVisible,
	isShowSupplierInvoiceSettingsColumn
} from 'sections/SupplierInvoice/supplierInvoiceUtils';
import styles from './SupplierInvoiceTable.module.scss';

export interface SupplierInvoiceColumn extends ColumnProps<SupplierInvoiceRow> {
	getIsVisible?: (supplierInvoices?: SupplierInvoiceRow[]) => boolean;
}

function getSupplierInvoiceColumns(): SupplierInvoiceColumn[] {
	return [
		{
			title: 'Supplier',
			dataIndex: 'supplierCompany.name',
			render: (text, { split, isCredit, isCancelled }) => (
				<Gap top={isCredit} isBlock>
					{isCredit && (
						<span className={styles.creditMark}>
							<CreditMark />
						</span>
					)}
					{split && (
						<Gap left="sm" right="sm">
							<Icon type="services-line" color="standard" />
						</Gap>
					)}
					{text}
					{isCancelled && <Tag>Cancelled</Tag>}
				</Gap>
			)
		},
		{
			title: 'GL Document #',
			dataIndex: 'glNumber'
		},
		{
			title: 'Job Id',
			dataIndex: 'portJobCodes',
			render: (jobs: SupplierInvoiceRow['portJobCodes'], { isCancelled }) => {
				if (isCancelled) {
					return DEFAULT_NOT_AVAILABLE_PLACEHOLDER;
				} else if (!jobs.length) {
					return 'TBC';
				}
				return jobs.length > 2 ? (
					<MultipleEntities entities={jobs} />
				) : (
					<>{jobs.join(', ')}</>
				);
			}
		},
		{
			title: 'HUB Principal',
			dataIndex: 'mainPrincipalCompany.name'
		},
		{
			title: 'Invoice Reference',
			dataIndex: 'referenceNumber'
		},
		{
			title: 'Supplier Inv Date',
			dataIndex: 'invoiceDate',
			render: (invoiceDate?: string) => (
				<>
					{invoiceDate ? (
						<FormattedTime value={invoiceDate} />
					) : (
						DEFAULT_NO_VALUE_PLACEHOLDER
					)}
				</>
			)
		},
		{
			title: 'Inv Receipt Date',
			dataIndex: 'receiptDate',
			render: (receiptDate: string) => <FormattedTime value={receiptDate} />
		},
		{
			title: 'Currency',
			dataIndex: 'currencyCode'
		},
		{
			title: 'Total',
			dataIndex: 'total',
			render: (total: string, { currencyCode, isCredit }) => (
				<SupplierInvoiceTotal
					currencyCode={currencyCode}
					total={total}
					isCredit={isCredit}
				/>
			)
		},
		{
			title: 'Receipt',
			dataIndex: 'id',
			align: 'center',
			render: (id: string, { split }) => {
				return split ? (
					DEFAULT_NO_VALUE_PLACEHOLDER
				) : (
					<DownloadSupplierInvoiceButton supplierInvoiceId={id} />
				);
			}
		},
		{
			align: 'center',
			width: '32px',
			render: (_text, record) =>
				record.transactionsDetails.length ? (
					<GLMark
						hasTooltip={!record.split}
						transactions={record.transactionsDetails}
					/>
				) : null
		},
		{
			dataIndex: 'id',
			key: 'settings',
			align: 'center',
			width: '32px',
			render: (
				id: string,
				{ metadata, split, isCancelled }: SupplierInvoiceRow
			) => {
				const isSettingsIconVisible = getIsSupplierInvoiceSettingsIconVisible({
					metadata,
					isCancelled
				} as SupplierInvoiceRow);
				return split || !isSettingsIconVisible ? null : (
					<SupplierInvoiceSettingsButton
						id={id}
						metadata={metadata}
						isCancelled={isCancelled}
					/>
				);
			},
			getIsVisible: supplierInvoices =>
				isShowSupplierInvoiceSettingsColumn(supplierInvoices)
		}
	];
}

export default (
	supplierInvoices: SupplierInvoiceRow[]
): ColumnProps<SupplierInvoiceRow>[] => {
	return getSupplierInvoiceColumns().filter(
		supplierInvoiceColumn =>
			!supplierInvoiceColumn.getIsVisible ||
			supplierInvoiceColumn.getIsVisible(supplierInvoices)
	);
};
