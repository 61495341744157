import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import {
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse,
	retrieveSplitSupplierInvoiceDocumentAsync
} from '../actions';
import Api from 'services/api';
import { get } from 'lodash';
import { put } from 'redux-saga/effects';
import { fullPage } from 'store/notifications/actions';

export default makeTakeLatestWatcher<
	RetrieveSplitSupplierInvoiceServiceDocumentRequest,
	RetrieveSplitSupplierInvoiceServiceDocumentResponse,
	Error
>({
	async: retrieveSplitSupplierInvoiceDocumentAsync,
	api: Api.SupplierInvoice.retrieveSplitSupplierInvoiceDocument,
	*onError(_params, error) {
		const status = get(error, 'response.status') || 'UNHANDLED';
		const validationMessageData = get(error, 'response.data');

		yield put(
			fullPage({
				error: {
					status: status,
					correlationId: validationMessageData?.correlationId
				},
				description: validationMessageData?.error
			})
		);
	}
});
