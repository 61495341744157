import { FetchStatus } from 'services/api/apiTypes';
import {
	FundingRequest,
	FundingRequestMetadataActionCode,
	Funding,
	FundingColumnsMetadataKey,
	PrefundingRequest
} from 'services/api/financeFundings/financeFundingsServiceTypes';
import { FinanceMetadataActionCode } from 'services/api/finance/financeServiceTypes';
import { Metadata } from 'app-types';

export interface FinanceFundingsState {
	fundingRequests: {
		context: {
			daId: string | null;
			activeFundingRequestId: string | null;
		};
		readonly byId: { [key: string]: FundingRequest };
		readonly allIds: string[];
		readonly propertiesToView: string[];
		readonly metadata: Metadata<FundingRequestMetadataActionCode> | null;
		readonly fetchStatuses: {
			retrieveFundingRequest: FetchStatus;
			retrieveFundingPageData: FetchStatus;
			retrieveNewFundingData: FetchStatus;
			updateFundingRequest: FetchStatus;
			createFundingRequest: FetchStatus;
			updatePrefundingStatus: FetchStatus;
		};
	};
	fundings: {
		context: {};
		readonly byId: { [key: string]: Funding };
		readonly allIds: string[];
		readonly propertiesToView: string[];
		readonly metadata: Metadata<
			FinanceMetadataActionCode,
			FundingColumnsMetadataKey
		> | null;
		readonly fetchStatuses: {
			updateFunding: FetchStatus;
		};
	};
	preFundingRequests: {
		readonly prefunding: PrefundingRequest;
		readonly fetchStatuses: {
			retrievePreFundingRequest: FetchStatus;
			sendPrefundingsRequest: FetchStatus;
		};
	};
}

export type FundingRequestsState = FinanceFundingsState['fundingRequests'];
export type FundingsState = FinanceFundingsState['fundings'];
export type PreFundingRequestsState = FinanceFundingsState['preFundingRequests'];

export const preFundingRequestsInitialState: PreFundingRequestsState = {
	prefunding: {
		disbursementAccounts: [],
		isCEAvailable: false,
		isPDAAvailable: false,
		isDAAvailable: false
	},
	fetchStatuses: {
		retrievePreFundingRequest: FetchStatus.IDLE,
		sendPrefundingsRequest: FetchStatus.IDLE
	}
};

export const fundingRequestsInitialState: FundingRequestsState = {
	context: {
		daId: null,
		activeFundingRequestId: null
	},
	byId: {},
	allIds: [],
	propertiesToView: [],
	metadata: null,
	fetchStatuses: {
		retrieveFundingRequest: FetchStatus.IDLE,
		retrieveFundingPageData: FetchStatus.IDLE,
		retrieveNewFundingData: FetchStatus.IDLE,
		updateFundingRequest: FetchStatus.IDLE,
		updatePrefundingStatus: FetchStatus.IDLE,
		createFundingRequest: FetchStatus.IDLE
	}
};

export const fundingsInitialState: FundingsState = {
	context: {},
	byId: {},
	allIds: [],
	propertiesToView: [],
	metadata: null,
	fetchStatuses: {
		updateFunding: FetchStatus.IDLE
	}
};
