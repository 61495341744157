import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
	initPortJobsInContextualizationForm,
	updatePortJobsInContextualizationForm
} from './actions';
import {
	onInitPortJobsInContextualizationForm,
	onUpdatePortJobsInContextualizationForm
} from './reducers';
import { FormState } from '../formState';
import { FormData } from './supplierInvoiceContextualizationFormTypes';

export type SupplierInvoiceContextualizationFormState = FormState<FormData>;

const contextualizationFormState: SupplierInvoiceContextualizationFormState = {
	values: {} as FormData
};

export default reducerWithInitialState(contextualizationFormState)
	.case(
		initPortJobsInContextualizationForm,
		onInitPortJobsInContextualizationForm
	)
	.case(
		updatePortJobsInContextualizationForm,
		onUpdatePortJobsInContextualizationForm
	);
