import { WizardFormState } from '../wizardFormState';
import { WizardFormSetPage } from '../actions/setMode';
export const onSetMode = (
	state: WizardFormState,
	action: WizardFormSetPage
) => {
	const { name, mode } = action;
	const formName: string = name ? name : state.activeWizardFormId;
	const form = {
		...state.forms[formName],
		mode
	};
	return {
		...state,
		forms: {
			...state.forms,
			[formName]: form
		}
	};
};
