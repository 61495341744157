import { createSelector } from 'reselect';
import { FormErrors, getFormSyncErrors } from 'redux-form';
import { get } from 'lodash';
import { AppState } from 'store-types';
import { getContextualizationWizardForm } from 'store/wizardForm/wizardFormSelectors';

import { getValues } from 'store/form/selectors';
import {
	CONTEXTUALIZATION_FORM_ID,
	FormData,
	FormPageData
} from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';

export const getContextualizationFormValues = (state: AppState): FormData => {
	return getValues<FormData>(state, CONTEXTUALIZATION_FORM_ID);
};

export const getContextualizationFormFieldValues = (
	state: AppState,
	field: string
): FormPageData => {
	const values = getContextualizationFormValues(state);
	return get(values, field, {});
};

export const getContextualizationFormSyncErrors = (
	state: AppState
): FormErrors<FormData> => getFormSyncErrors(CONTEXTUALIZATION_FORM_ID)(state);

/**
 * Return values from Active Contextualization FromPageData
 */
export const getCurrentContextualizationFormPage = createSelector(
	getContextualizationFormValues,
	getContextualizationWizardForm,
	(form, wizardForm) => {
		const index = Math.max(0, wizardForm.activePage - 1);
		const formPageData = form.pages[index];
		return formPageData;
	}
);
