import { LabeledValue } from 'app-types';

/**
 * Used with `redux-query-sync`
 */

export interface FilterItem extends LabeledValue<string> {
	type: string;
}

export interface FilterBaseActionParam {
	name: string;
}

export interface FilterState {
	[name: string]: FilterItem[];
}

export interface FiltersState {
	[name: string]: FilterState;
}

export const initialState: FiltersState = {};
