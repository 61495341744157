import actionCreator from '../portJobsActionCreator';
import { UpdateServiceSection } from 'sections/PortCall/PortJob/ActionModal/EnableOperationsModal/EnableOperationsFormTypes';

const ACTION_NAME = 'UPDATE_ENABLE_OPERATIONS';

export const updateEnableOperations = actionCreator(ACTION_NAME);

export const updateEnableOperationsAsync = actionCreator.async<
	UpdateServiceSection,
	null,
	Error
>(ACTION_NAME);
