import React from 'react';
import { Gap, Text } from 'components';
import { LinkedCargo } from 'store/linkJob/linkJobTypes';
import { getCargoBox } from '../CargoRow/CargoLine';
import styles from '../CargoLinkage.module.scss';

interface UnlinkedCargosListProps {
	cargosList: LinkedCargo[];
	onClickList: (id: string) => void;
}

function UnlinkedCargosList({
	cargosList,
	onClickList
}: UnlinkedCargosListProps) {
	return (
		<div className={styles.cargoContainer}>
			{cargosList.length ? (
				<>
					<Gap top="sm" left isBlock outside={false}>
						<Text.Box weight="semi-bold">
							Select relevant Cargo to link with
						</Text.Box>
					</Gap>
					{cargosList.map((cargo, index) => (
						<li
							key={index}
							className={styles.cargoList}
							onClick={() => cargo.cargoId && onClickList(cargo.cargoId)}
						>
							{getCargoBox(cargo)}
						</li>
					))}
				</>
			) : (
				<Gap top="sm" left bottom="sm" isBlock outside={false}>
					<Text.Box weight="semi-bold">No cargo available</Text.Box>
				</Gap>
			)}
		</div>
	);
}

export default UnlinkedCargosList;
