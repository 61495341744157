import * as React from 'react';
import classNames from 'classnames';
import AntInput, { InputProps } from 'antd/lib/input/Input';
import createFieldComponent, {
	CreateReduxField
} from '../Form/ReduxField/createReduxField';
import { customMap } from '../Form/ReduxField/mapError';
import formStyles from '../Form/Form.module.scss';
import styles from './Input.module.scss';
import { FormChangeInput } from 'app-types';
import { getAllowedValue } from './CustomInput.func';

export class CustomInput extends React.Component<InputProps> {
	static ReduxFormItem: CreateReduxField<InputProps>;

	elem: AntInput | null;

	focus() {
		if (this.elem) {
			this.elem.focus();
		}
	}

	render() {
		return (
			<AntInput
				ref={elem => (this.elem = elem)}
				{...this.props}
				className={classNames(
					styles.root,
					formStyles.input,
					this.props.className,
					{
						[styles.addonAfter]: Boolean(this.props.addonAfter)
					}
				)}
			/>
		);
	}
}

/**
 * ReduxForm Input connector
 */

export const textFieldMapProps = customMap(props => ({
	onChange: ({ currentTarget: { value } }: FormChangeInput) =>
		props.input.onChange(getAllowedValue(value))
}));

CustomInput.ReduxFormItem = createFieldComponent<InputProps>(
	CustomInput,
	textFieldMapProps
);

export default CustomInput;
