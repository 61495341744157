import actionCreator from '../portJobsActionCreator';
import { UncancelPortJobRequest } from 'services/api/portJobs/portJobsServiceTypes';
const ACTION_NAME = 'UNCANCEL_JOB';

export const uncancelPortJobAsync = actionCreator.async<
	UncancelPortJobRequest,
	void,
	Error
>(ACTION_NAME, {
	done: {
		description: 'Port Job has been successfully reopened.'
	},
	failed: {
		// react-to-changes handles omit cases
		omit: [412]
	},
	templateParams: {
		entityName: 'Port Job'
	}
});

export const uncancelPortJob = actionCreator(ACTION_NAME);
