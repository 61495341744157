import { AxiosError } from 'axios';
import { ThreadReducerState } from './../threadState';
import { Success, Failure } from 'typescript-fsa';
import {
	AssignJobToThreadParams,
	AssignJobToThreadResponse
} from 'services/api/threads/threadsServiceTypes';
import { FetchStatus } from 'services/api/apiTypes';

/**
 * Single Thread Reducer
 */
export const onAssignJobToThreadsStarted = (
	state: ThreadReducerState,
	action: AssignJobToThreadParams
): ThreadReducerState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		assigning: {
			[action.jobCode]: FetchStatus.PENDING
		}
	}
});
export const onAssignJobToThreadsFailed = (
	state: ThreadReducerState,
	action: Failure<AssignJobToThreadParams, AxiosError>
): ThreadReducerState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		assigning: {
			[action.params.jobCode]: FetchStatus.FAILURE
		}
	},
	error: action.error.response ? action.error.response.data : action.error
});

/**
 * Thread Context API. It will return the list of all the jobCodes assigned to that thread
 */
export const onAssignJobToThreadsSuccess = (
	state: ThreadReducerState,
	action: Success<AssignJobToThreadParams, AssignJobToThreadResponse>
): ThreadReducerState => ({
	...state,
	item:
		state.item && state.item.id === action.result.threadId
			? {
					...state.item,
					jobCodes: action.result.jobCodes
			  }
			: state.item,
	fetchStatuses: {
		...state.fetchStatuses,
		assigning: {
			[action.params.jobCode]: FetchStatus.SUCCESS
		}
	},
	error: false
});
