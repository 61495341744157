import { DashboardState } from '../dashboardState';
import { FetchStatus } from 'services/api/apiTypes';
import { Success } from 'typescript-fsa';
import { JobSections as Response } from 'services/api/dashboard/dashboardServiceTypes';

export function onRetrieveJobsSectionsStarted(
	state: DashboardState
): DashboardState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveJobsSections: FetchStatus.PENDING
		}
	};
}

export function onRetrieveJobsSectionsSuccess(
	state: DashboardState,
	{ result }: Success<void, Response>
): DashboardState {
	return {
		...state,
		sections: result,
		context: {
			...state.context,
			isSectionsFirstLoad: false
		},
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveJobsSections: FetchStatus.SUCCESS
		}
	};
}

export function onRetrieveJobsSectionsFailed(
	state: DashboardState
): DashboardState {
	return {
		...state,
		context: {
			...state.context,
			isSectionsFirstLoad: false
		},
		fetchStatuses: {
			...state.fetchStatuses,
			retrieveJobsSections: FetchStatus.FAILURE
		}
	};
}
