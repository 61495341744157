import Api from 'services/api';
import { makeTakeEveryWatcher } from 'utils/sagaHelpers';
import { UpdateThreadReadRequest } from 'services/api/messages/messagesServiceTypes';
import { updateThreads, updateUnreadCount } from 'store/threads/actions';
import { getActiveThread } from 'store/thread/selectors';
import { put, select } from 'redux-saga/effects';
import { ThreadItem } from 'services/api/threads/threadsServiceTypes';
import { updateThreadMessageReadAsync as async } from '../actions/updateThreadMessageRead';

let activeThread = {} as ThreadItem;
export default makeTakeEveryWatcher<UpdateThreadReadRequest, null, Error>({
	api: Api.Messages.updateThreadMessageRead,
	async,
	*getApiParams(params) {
		activeThread = yield select(getActiveThread);
		return params;
	},
	*onSuccess() {
		activeThread.isRead = true;
		yield put(updateThreads(activeThread));
		yield put(updateUnreadCount());
	}
});
