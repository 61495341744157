import { UsersState } from './../usersState';
import { FetchStatus } from 'services/api/apiTypes';
export const onUpdateUserStarted = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		updating: FetchStatus.PENDING
	}
});
export const onUpdateUserSuccess = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		updating: FetchStatus.SUCCESS
	}
});
export const onUpdateUserFailed = (state: UsersState): UsersState => ({
	...state,
	fetchStatuses: {
		...state.fetchStatuses,
		updating: FetchStatus.FAILURE
	}
});
