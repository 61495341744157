import * as React from 'react';
import { DatePickerExtendedProps } from './DatePickerExtended';
import { WrappedFieldProps } from 'redux-form';
import { Form } from 'components/antd';
import { DatePickerExtended } from 'components';

interface WrappedDatePickerReduxFormItemProps
	extends Partial<DatePickerExtendedProps>,
		WrappedFieldProps {}

export default class ReduxFormWrapper extends React.PureComponent<
	WrappedDatePickerReduxFormItemProps,
	{ isSelectingDate: boolean }
> {
	constructor(props: WrappedDatePickerReduxFormItemProps) {
		super(props);
		this.state = {
			isSelectingDate: false
		};
	}

	onOpenChange = (isSelectingDate: boolean) => {
		this.setState({ isSelectingDate });
	};

	getValidateStatus = (help: boolean) => {
		const { meta } = this.props;

		if (help) {
			return meta.touched && !meta.valid;
		}

		return !meta.pristine && !meta.valid;
	};

	render() {
		const { input, meta, label, required, help } = this.props as any; // eslint-disable-line @typescript-eslint/no-explicit-any
		const { error, valid, touched } = meta;
		const { value, onChange } = input;

		return (
			<Form.Item
				label={label}
				required={required}
				validateStatus={this.getValidateStatus(help) ? 'error' : 'success'}
				help={help && touched && !valid && error}
			>
				<DatePickerExtended
					{...this.props}
					value={value || null}
					onChange={onChange}
					onOpenChange={this.onOpenChange}
				/>
			</Form.Item>
		);
	}
}
