import React, { FC } from 'react';
import { Icon, Tooltip } from 'components/antd';
import { TooltipProps } from 'antd/lib/tooltip';
import styles from './FormattedTimeTooltip.module.scss';

export type FormattedTimeTooltipProps = TooltipProps;

const FormattedTimeTooltip: FC<FormattedTimeTooltipProps> = ({
	title,
	...props
}) => {
	return (
		<Tooltip
			placement="bottomRight"
			overlayClassName={styles.timePopover}
			title={title}
			{...props}
		>
			<Icon type="info" color="primary" className={styles.infoIcon} />
		</Tooltip>
	);
};

export default FormattedTimeTooltip;
