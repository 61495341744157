import { SagaIterator } from 'redux-saga';
import { all, fork, cancel, put, take, delay } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	retrieveAllowedDocuments,
	retrieveRequiredDocuments,
	retrieveUploadedDocuments,
	retrievePortCallDocumentsCycleStart,
	retrievePortCallDocumentsCycleStop
} from '../actions';
import { DEFAULT_POLLING_INTERVAL } from 'app-constants';

const worker = function*({
	payload: portCallId
}: Action<string>): SagaIterator {
	while (true) {
		yield delay(DEFAULT_POLLING_INTERVAL);
		yield all([
			put(retrieveAllowedDocuments({ portCallId })),
			put(retrieveRequiredDocuments({ portCallId })),
			put(retrieveUploadedDocuments({ portCallId }))
		]);
	}
};

export default function*() {
	while (true) {
		const action = yield take(retrievePortCallDocumentsCycleStart);
		let pollPortCallDocumentsTask = null;
		if (action) {
			pollPortCallDocumentsTask = yield fork(worker, action);
		}
		yield take(retrievePortCallDocumentsCycleStop);
		yield cancel(pollPortCallDocumentsTask);
	}
}
