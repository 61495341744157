import { FetchStatus } from 'services/api/apiTypes';
import { CompanyTemplateFile } from 'services/api/templates/templatesServiceTypes';

export interface ConfigurationFilesState {
	readonly allIds: string[];
	readonly byId: { [fileId: string]: CompanyTemplateFile };
	readonly context: {
		isNew: boolean;
	};
	readonly fetchStatuses: {
		retrieveCompanyFiles: FetchStatus;
	};
}

export const initialState: ConfigurationFilesState = {
	allIds: [],
	byId: {},
	context: {
		isNew: false
	},
	fetchStatuses: {
		retrieveCompanyFiles: FetchStatus.IDLE
	}
};
