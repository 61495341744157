import { retrieveInternalCommentsAsync as async } from '../actions/retrieveInternalComments';
import { RetrieveInternalCommentsResponse as response } from 'services/api/threads/threadsServiceTypes';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';

const api = Api.Threads.retrieveInternalComments;
export default makeTakeLatestWatcher<string, response, Error>({
	api,
	async
});
