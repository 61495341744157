import { ThreadsState } from '../threadsState';
import { NewComposeMessageMetaData } from 'services/api/threads/threadsServiceTypes';

export const onSaveNewThreadData = (
	state: ThreadsState,
	newThreadData: NewComposeMessageMetaData
): ThreadsState => ({
	...state,
	newThreadData
});
