import Api from 'services/api';
import {
	RetrieveThreadsStatsRequest,
	RetrieveThreadsStatsResponse
} from 'services/api/threads/threadsServiceTypes';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { retrieveThreadsStatsAsync } from '../actions/retrieveThreadsStats';
export default makeTakeLatestWatcher<
	RetrieveThreadsStatsRequest,
	RetrieveThreadsStatsResponse,
	Error
>({
	api: Api.Threads.retrieveThreadsStats,
	async: retrieveThreadsStatsAsync
});
