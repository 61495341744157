import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { initialState, CommonState } from './commonState';

import { retrieveSystemStatusAsync } from './actions';
import { onRetrieveSystemStatus } from './reducers';

export default reducerWithInitialState<CommonState>(initialState)
	.case(retrieveSystemStatusAsync.done, onRetrieveSystemStatus.done)
	.case(retrieveSystemStatusAsync.failed, onRetrieveSystemStatus.failed);
