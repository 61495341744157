import { SagaIterator } from 'redux-saga';
import { race, call, put, select, take } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import {
	saveVesselProgrammeAsync,
	checkVPForUpdates,
	checkVPForUpdatesAsync
} from 'store/vesselProgramme/actions';
import { getVesselProgrammeStructured } from 'store/vesselProgramme/selectors';
import { getPortCallContext } from 'store/portcalls/portCallsSelectors';
import { retrieveVP } from '../actions/retrieveVP';
import { resetDraftsContext } from 'store/drafts/actions';

import Api from 'services/api';
import Matomo from 'services/analytics/adapters/Matomo';

export function* saveVesselProgrammeExecutor(
	_actionParams: Action<null>,
	api: typeof Api.VesselProgramme.saveVP
): SagaIterator {
	yield put(saveVesselProgrammeAsync.started(null));
	try {
		const portCallId = yield select(getPortCallContext, 'activePortCallId');
		yield put(checkVPForUpdates({ portCallId }));
		const { done, failed } = yield race({
			done: take(checkVPForUpdatesAsync.done),
			failed: take(checkVPForUpdatesAsync.failed)
		});

		// dont send request for update if version is outdated
		if (done?.payload.result.isChanged || failed) {
			return;
		}

		const vesselProgramme = yield select(getVesselProgrammeStructured);
		const response = yield call(api, { vesselProgramme, portCallId });
		yield put(
			saveVesselProgrammeAsync.done({
				params: null,
				result: null,
				response
			})
		);
		yield put(resetDraftsContext());
		yield put(retrieveVP({ portCallId }));
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'VP.Update.Save',
			name: `Vessel Programme has been saved`,
			value: 1
		});
	} catch (error) {
		// refresh VP process after save failure
		const portCallId = yield select(getPortCallContext, 'activePortCallId');
		yield put(checkVPForUpdates({ portCallId }));

		yield put(
			saveVesselProgrammeAsync.failed({
				params: null,
				error
			})
		);
	}
}

export function* saveVesselProgrammeWorker(action: Action<null>): SagaIterator {
	yield call(saveVesselProgrammeExecutor, action, Api.VesselProgramme.saveVP);
}

function* saveVesselProgrammeWatcher() {
	while (true) {
		const action = yield take(saveVesselProgrammeAsync.type);
		yield call(saveVesselProgrammeWorker, action);
	}
}

export default saveVesselProgrammeWatcher;
