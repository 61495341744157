import { all, fork } from 'redux-saga/effects';
import retrieveVesselProgrammeSaga from './retrieveVesselProgrammeSaga';
import retrieveBerthsSaga from './retrieveBerthsSaga';
import retrieveEventsSaga from './retrieveEventsSaga';
import retrieveTerminalsSaga from './retrieveTerminalsSaga';
import saveVesselProgrammeSaga from './saveVesselProgrammeSaga';
import AddPOIToVesselProgramme from './addPOISaga';
import acknowledgeVP from './acknowledgeVPSaga';
import acknowledgeVPWithCommentWatcher from './acknowledgeVPWithCommentSaga';
import {
	trackVPFlowWatcher,
	trackVPWatcher,
	commitVPWatcher,
	commitVpFlowWatcher
} from './trackCommitVPSaga';
import downloadVPRevision from './downloadVPRevisionSaga';
import DeletePOIToVesselProgramme from './deletePOISaga';
import refreshVPProcessSaga, {
	refreshVPWorkerSaga
} from './refreshVPProcessSaga';
import updateVPFromFinance from './updateVPFromFinanceSaga';

export default function*() {
	yield all([
		fork(retrieveVesselProgrammeSaga),
		fork(refreshVPProcessSaga),
		fork(refreshVPWorkerSaga),
		fork(commitVPWatcher),
		fork(commitVpFlowWatcher),
		fork(trackVPFlowWatcher),
		fork(trackVPWatcher),
		fork(retrieveBerthsSaga),
		fork(retrieveEventsSaga),
		fork(retrieveTerminalsSaga),
		fork(saveVesselProgrammeSaga),
		fork(AddPOIToVesselProgramme),
		fork(acknowledgeVP),
		fork(acknowledgeVPWithCommentWatcher),
		fork(DeletePOIToVesselProgramme),
		fork(downloadVPRevision),
		fork(updateVPFromFinance)
	]);
}
