import { ActionMeta } from 'app-types';
import {
	ResendInvitationRequest,
	User
} from 'services/api/users/userServiceTypes';
import actionCreator from '../usersActionCreator';

const ACTION_META: ActionMeta = {
	done: {
		description: 'An invitation has been successfully resent to <%= email %>.'
	},
	templateParams: {
		entityName: 'User'
	},
	behaviour: {
		type: 'submit'
	}
};

export interface ReInviteUserRequestPayload {
	form: string;
	requestParams: ResendInvitationRequest;
}

const ACTION_NAME = 'RE_INVITE';
export const reInviteUserAsync = actionCreator.async<
	ReInviteUserRequestPayload,
	User,
	Error
>(ACTION_NAME, ACTION_META);

export const reInviteUser = actionCreator<ReInviteUserRequestPayload>(
	ACTION_NAME
);
