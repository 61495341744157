import actionCreator from '../portCallMeasurementsActionCreator';
import { UpdateCargoMeasurementsRequest } from 'services/api/portCallMeasurements/portCallMeasurementsServiceTypes';

export type UpdateCargoMeasurementsParams = Pick<
	UpdateCargoMeasurementsRequest,
	'updatingData'
>;

const ACTION_NAME = 'UPDATE_CARGO_MEASUREMENTS';
export const updateCargoMeasurementsAsync = actionCreator.async<
	UpdateCargoMeasurementsRequest,
	null,
	Error
>(ACTION_NAME);

export const updateCargoMeasurements = actionCreator<
	UpdateCargoMeasurementsRequest
>(ACTION_NAME);
