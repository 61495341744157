import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { resetUsers } from './actions/resetUsers';
import { resetUserProfile } from './actions/resetUserProfile';

import { retrieveUsersAsync } from './actions/retrieveUsers';

import { retrieveUserAsync } from './actions/retrieveUser';

import { inviteUserAsync } from './actions/inviteUser';

import { reInviteUserAsync } from './actions/reinviteUser';

import { updateUserAsync } from './actions/updateUser';

import { updateUserStatusAsync } from './actions/updateUserStatus';

import { retrieveUserStatusesAsync } from './actions/retrieveUserStatuses';

import { setUserFilters } from './actions/setUsersFilters';
import { initialState } from './usersState';

import {
	onResetUsers,
	onResetUserProfile,
	onRetrieveUsersFailed,
	onRetrieveUsersStarted,
	onRetrieveUsersSuccess,
	onRetrieveUserFailed,
	onRetrieveUserStarted,
	onRetrieveUserSuccess,
	onInviteUserFailed,
	onInviteUserStarted,
	onInviteUserSuccess,
	onReInviteUserFailed,
	onReInviteUserStarted,
	onReInviteUserSuccess,
	onUpdateUserSuccess,
	onUpdateUserStarted,
	onUpdateUserFailed,
	onUpdateUserStatusSuccess,
	onUpdateUserStatusStarted,
	onUpdateUserStatusFailed,
	onRetrieveUserStatusesSuccess,
	onRetrieveUserStatusesStarted,
	onRetrieveUserStatusesFailed,
	onSetUserFilters
} from './reducers';

export default reducerWithInitialState(initialState)
	.case(resetUsers, onResetUsers)
	.case(resetUserProfile, onResetUserProfile)
	// Get All
	.case(retrieveUsersAsync.started, onRetrieveUsersStarted)
	.case(retrieveUsersAsync.done, onRetrieveUsersSuccess)
	.case(retrieveUsersAsync.failed, onRetrieveUsersFailed)
	// Get a single User
	.case(retrieveUserAsync.started, onRetrieveUserStarted)
	.case(retrieveUserAsync.done, onRetrieveUserSuccess)
	.case(retrieveUserAsync.failed, onRetrieveUserFailed)
	// Update a single user
	.case(updateUserAsync.started, onUpdateUserStarted)
	.case(updateUserAsync.done, onUpdateUserSuccess)
	.case(updateUserAsync.failed, onUpdateUserFailed)
	// Update User Status
	.case(updateUserStatusAsync.started, onUpdateUserStatusStarted)
	.case(updateUserStatusAsync.done, onUpdateUserStatusSuccess)
	.case(updateUserStatusAsync.failed, onUpdateUserStatusFailed)
	// Invitation
	.case(inviteUserAsync.started, onInviteUserStarted)
	.case(inviteUserAsync.done, onInviteUserSuccess)
	.case(inviteUserAsync.failed, onInviteUserFailed)
	// Re Invite
	.case(reInviteUserAsync.started, onReInviteUserStarted)
	.case(reInviteUserAsync.done, onReInviteUserSuccess)
	.case(reInviteUserAsync.failed, onReInviteUserFailed)
	// Retrieve User Statuses
	.case(retrieveUserStatusesAsync.started, onRetrieveUserStatusesStarted)
	.case(retrieveUserStatusesAsync.done, onRetrieveUserStatusesSuccess)
	.case(retrieveUserStatusesAsync.failed, onRetrieveUserStatusesFailed)
	// Set Filters
	.case(setUserFilters, onSetUserFilters);
