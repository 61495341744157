import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { CompanyVesselTypeState } from '../companyVesselTypesState';
import {
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';

export const onUpdateCompanyVesselType = makeFetchStatusReducers<
	CompanyVesselTypeState,
	'fetchStatuses',
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse
>('fetchStatuses', 'update', {
	doneReducer: (state, { params: { id, data } }) => ({
		...state,
		byId: {
			...state.byId,
			[id]: {
				...state.byId[id],
				id,
				...data
			}
		}
	})
});
