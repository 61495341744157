import React from 'react';
import { Icon, Menu, Popover } from 'components/antd';
import { DownloadApiType } from './constants';
import { downloadApiSubscription } from 'store/developerPortal/actions';
import { useDispatch } from 'react-redux';
import styles from '../DeveloperPortal.module.scss';
import classNames from 'classnames';
import { ApiGroupPermissionList } from 'services/api/developerPortal/developerPortalServiceTypes';

interface DownloadApiPopUpProps {
	id: string;
	downloadAll?: boolean;
	apiDetails?: ApiGroupPermissionList;
	isOutbound?: boolean;
}

const DownloadApiPopUp: React.FC<DownloadApiPopUpProps> = ({
	id,
	downloadAll,
	apiDetails,
	isOutbound
}) => {
	const dispatch = useDispatch();

	const downloadApi = (e: { key: string }) => {
		// This condition is introduced to segregate download option for html filetypes and other filetypes.
		if (
			e.key === DownloadApiType.HTML &&
			apiDetails?.methodName &&
			apiDetails.methodName !== 'EMD'
		) {
			const name =
				(apiDetails.htmlDownloadLink &&
					apiDetails.htmlDownloadLink.split('/').pop()) ||
				apiDetails.methodName;
			dispatch(
				downloadApiSubscription({
					id,
					fileType: DownloadApiType.HTML,
					fileName: name
				})
			);
		} else {
			dispatch(
				downloadApiSubscription({
					id,
					fileType: e.key,
					fileName: ''
				})
			);
		}
	};

	const popoverContent = (
		<Menu onClick={e => downloadApi(e)}>
			<Menu.Item key={DownloadApiType.YAML} hidden={isOutbound}>
				Download YAML
			</Menu.Item>
			<Menu.Item key={DownloadApiType.HTML} hidden={isOutbound}>
				Download HTML
			</Menu.Item>
			<Menu.Item key={DownloadApiType.ZIP} hidden={!isOutbound}>
				Download ZIP
			</Menu.Item>
		</Menu>
	);

	return (
		<Popover
			type="dropdown"
			placement="bottomLeft"
			trigger="click"
			overlayClassName={classNames(
				styles.downloadApiPopover,
				styles.downloadApiPopUp
			)}
			content={popoverContent}
		>
			<Icon type="download" color="primary" className={styles.pointer} />
			&nbsp;
			<span className={styles.pointer}>
				{downloadAll ? 'Download All' : 'Download'}
			</span>
		</Popover>
	);
};

export default DownloadApiPopUp;
