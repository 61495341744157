import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { VesselProgrammeState } from '../vesselProgrammeState';
import { AcknowledgeVPRequest } from 'services/api/vesselProgramme/vesselProgrammeServiceTypes';

export const onAcknowledgeVP = makeFetchStatusReducers<
	VesselProgrammeState,
	'fetchStatuses',
	AcknowledgeVPRequest,
	void
>('fetchStatuses', 'acknowledge');
