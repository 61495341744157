import React, { lazy } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
	Flex,
	ScrollableLayout,
	PageHeader,
	Status,
	Gap,
	Title,
	Text
} from 'components';
import { AppNotifications } from 'containers';
import { Col, Row } from 'components/antd';
import * as H from 'history';
import history from 'services/history';
import { isNil } from 'lodash';
import RouteBreadcrumbs from 'sections/App/RouteBreadcrumbs';
import Settings, {
	AssignTo,
	AssignToJob,
	Permissions,
	Context,
	ThreadTags,
	InternalComments
} from './Settings';
import ThreadAssignToMeAlert from 'containers/ThreadAssignToMeAlert/ThreadAssignToMeAlert';
import {
	updateThreadStatus,
	resetThread,
	resetThreadEvents,
	retrieveThreadEvents,
	retrieveThreadByIdCycleStart,
	retrieveThreadByIdCycleStop,
	updateThreadMessageRead
} from 'store/thread';
import { resetPortCallsDuplicates } from 'store/portcalls/actions';
import {
	getThreadPermissionGroupsOptionsSelector,
	getIsCurrentUserAssignedToActiveThread,
	getIsThreadLoading,
	getThreadGroupPermissions
} from 'store/thread/selectors';
import {
	getThreadEventsList,
	getLatestMessageEvent,
	getIsThreadEventsLoading
} from 'store/thread/eventsSelector';
import { getIsPreviewModeEnabled } from 'store/threads/selectors';
import { navigateTo } from 'utils';
import {
	createEmptyMessage,
	createReplyLastMessage
} from 'sections/ComposeMessage/utils/ComposeMessageUtils';
import { getComposeMessageFromSelector } from 'sections/ComposeMessage/ComposeMessageSelector';
import { ComposeMessageFrom } from 'sections/ComposeMessage/ComposeMessageTypes';
// Styles
import styles from './Thread.module.scss';
import {
	PermissionGroupWithUsers,
	ThreadItem,
	ThreadStatus
} from 'services/api/threads/threadsServiceTypes';
import { MessageEvent } from 'services/api/messages/messagesServiceTypes';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import getContentHistoryHeader from 'sections/ComposeMessage/utils/getContentHistoryHeader';
import {
	setActiveThreadId,
	resetThreadsContext,
	updateFullScreenMode
} from 'store/threads/actions';
import { THREAD_STATUS_OPTIONS } from 'store/thread/threadConstants';
import { AppState } from 'store-types';
import { ModalContext } from 'context';
import { ThreadEvent } from './ThreadTypes';
const Events = lazy(() => import('./Events/Events'));

const ComposeMessage = lazy(() =>
	import('sections/ComposeMessage/ComposeMessage')
);

interface ThreadProps {
	thread: ThreadItem;
	isNewTab: boolean;
	isExpand: boolean;
	isPreviewModeEnabled?: boolean;
	editAssignTo?: boolean;
	// from mapStateToProps
	getIsPreviewModeEnabled: boolean;
	currentUserAssignedToThread: boolean;
	events: ThreadEvent[];
	groupPermissions: Group[];
	isEventsLoading: boolean;
	isThreadLoading: boolean;
	latestMessageEvent: MessageEvent | undefined;
	messageFrom: Omit<ComposeMessageFrom, 'defaultMailboxAddress'>;
	threadPermissionGroups: PermissionGroupWithUsers[];
	// from mapDispatchToProps
	resetThreadEvents: typeof resetThreadEvents;
	resetPortCallsDuplicates: typeof resetPortCallsDuplicates;
	retrieveThreadByIdCycleStart: typeof retrieveThreadByIdCycleStart;
	retrieveThreadByIdCycleStop: typeof retrieveThreadByIdCycleStop;
	retrieveThreadEvents: typeof retrieveThreadEvents;
	setActiveThreadId: typeof setActiveThreadId;
	updateThreadMessageRead: typeof updateThreadMessageRead;
	updateThreadStatus: typeof updateThreadStatus;
	resetThreadsContext: typeof resetThreadsContext;
	resetThread: typeof resetThread;
	updateFullScreenMode: typeof updateFullScreenMode;
}

interface ThreadState {
	isThreadRead?: boolean;
}

class Thread extends React.Component<ThreadProps, ThreadState> {
	public unregister: H.UnregisterCallback;

	hasPosition = false;

	state: Readonly<ThreadState> = {
		isThreadRead: false
	};

	goToThreads = () => {
		this.props.updateFullScreenMode({
			isFullScreenModeEnabled: false
		});
		this.hasPosition = true;
		this.props.isNewTab ? window.close() : navigateTo(`/messages/`);
	};

	onExpand = () => {
		this.hasPosition = true;
		navigateTo(`/messages/${this.props.thread.id}/expand`);
	};

	componentDidMount() {
		this.retrieveThreadEvents();
		this.props.retrieveThreadByIdCycleStart();

		this.unregister = history.listen(this.onHistoryChanged);
	}

	onHistoryChanged: H.LocationListener = (_location, action) => {
		/**
		 * Preventing multiple invocations if there are no PUSH actions
		 * E.g. IPP-32182 was related to resetting the threads context because of firing the REPLACE action
		 * before PUSH while redirecting to the '/messages/' page
		 */
		if (action !== 'PUSH') {
			return;
		}
		if (!this.hasPosition && !this.props.getIsPreviewModeEnabled) {
			this.props.resetThreadsContext();
		}
		this.hasPosition = false;
	};

	componentDidUpdate(prevProps: ThreadProps) {
		const {
			thread,
			latestMessageEvent,
			currentUserAssignedToThread
		} = this.props;
		// Request latest event to show on UI if user has assigned thread to him/herself
		if (!prevProps.currentUserAssignedToThread && currentUserAssignedToThread) {
			this.retrieveThreadEvents();
		}

		// mark thread as read on initial load
		if (!this.state.isThreadRead && latestMessageEvent && !thread.isRead) {
			this.setState({ isThreadRead: true }, () => {
				this.props.updateThreadMessageRead({
					threadId: thread.id,
					messageId: latestMessageEvent.message.id
				});
			});
		}

		if (
			prevProps.thread.status !== ThreadStatus.RESOLVED &&
			this.props.thread.status === ThreadStatus.RESOLVED
		) {
			this.goToThreads();
		}
	}

	componentWillUnmount() {
		this.props.resetThreadEvents();
		this.props.resetPortCallsDuplicates();
		this.props.retrieveThreadByIdCycleStop();
		this.props.resetThread();
		this.unregister();
	}

	updateThreadStatus = (status: string) => {
		this.props.updateThreadStatus({
			threadId: this.props.thread.id,
			status
		});
	};

	getFromInfo = (thread: ThreadItem) => {
		return {
			...this.props.messageFrom,
			defaultMailboxAddress: thread.defaultMailboxAddress
		};
	};

	getLastMessageInitialValues() {
		const message = this.props.thread.lastMessage;
		if (message && this.props.thread.id) {
			const from = {
				...this.props.messageFrom,
				defaultMailboxAddress: this.props.thread.defaultMailboxAddress
			};
			return createReplyLastMessage(from, message);
		}
		return createEmptyMessage();
	}

	getHistoryHeader = (thread: ThreadItem) => {
		const message = this.props.thread.lastMessage;
		let header = '';
		if (message && thread.id) {
			const from = this.getFromInfo(thread);
			header = getContentHistoryHeader(from, message);
		}
		return header;
	};

	getBreadcrumbs() {
		const lastMessage = this.props.thread.lastMessage;
		const items: BreadcrumbItem[] = [{ title: 'Messages', link: '/messages' }];
		if (lastMessage) {
			items.push({ title: lastMessage.subject || 'Message' });
		}
		return items;
	}

	retrieveThreadEvents = () => {
		return this.props.retrieveThreadEvents({
			threadId: this.props.thread.id,
			retrieveLatest: true
		});
	};

	render() {
		const {
			thread,
			events,
			currentUserAssignedToThread,
			groupPermissions,
			isThreadLoading,
			isEventsLoading,
			isPreviewModeEnabled,
			isExpand,
			editAssignTo
		} = this.props;

		const assignedTo = {
			group: thread.assignedToGroup,
			user: thread.assignedToUser
		};

		const statusUpdatedOn =
			thread.status !== ThreadStatus.RESOLVED ? thread.statusUpdatedOn : '';
		const jobCodes = this.props.thread.jobCodes.length
			? this.props.thread.jobCodes.join(', ')
			: 'No Job Defined';

		return (
			<ScrollableLayout direction="horizontal">
				{!isPreviewModeEnabled ? (
					<RouteBreadcrumbs items={this.getBreadcrumbs()} />
				) : null}
				<AppNotifications.Notification />
				{!isPreviewModeEnabled ? (
					<PageHeader.NewTab
						stretch
						shouldCloseCurrentView
						onClose={this.goToThreads}
						openButtonProps={{ className: 'qaa_thread_button_new_tab' }}
						closeButtonProps={{ className: 'qaa_thread_button_close_tab' }}
						showExpand={!isExpand}
						onExpand={this.onExpand}
					>
						{thread.lastMessage ? thread.lastMessage.subject : ''}
					</PageHeader.NewTab>
				) : null}
				{!this.props.isExpand && (
					<Row
						className={cn(styles.threadHeader, {
							[styles.marginLeft]: isPreviewModeEnabled
						})}
						middle="xs"
					>
						<Col xs={9}>
							{jobCodes}:&nbsp;
							<span>
								{isNil(this.props.thread.context)
									? `No Context Defined`
									: this.props.thread.context.name}
							</span>
						</Col>
						<Col xs={3} style={{ paddingLeft: 0 }}>
							<Status.Dropdown
								onChange={this.updateThreadStatus}
								value={thread.status}
								disabled={!currentUserAssignedToThread}
								options={THREAD_STATUS_OPTIONS.filter(
									option => option !== thread.status
								)}
							/>
							{statusUpdatedOn && (
								<span className={styles.threadHeaderStatusUpdated}>
									Last Update:{' '}
									<strong>
										<Status.TimeAgo date={statusUpdatedOn} />
									</strong>
								</span>
							)}
						</Col>
					</Row>
				)}

				<ScrollableLayout stretch>
					<Flex style={{ flexBasis: '0' }} direction="vertical" grow>
						<ThreadAssignToMeAlert
							thread={thread}
							currentUserAssignedToThread={currentUserAssignedToThread}
						/>
						<Events
							events={events}
							readMessageId={thread.lastRead ? thread.lastRead.messageId : ''}
							threadId={thread.id}
							isLoading={isEventsLoading}
							className={cn({
								[styles.paddingTop]: !currentUserAssignedToThread
							})}
							isPreviewModeEnabled={isPreviewModeEnabled}
							isExpand={isExpand}
						/>
						{!this.props.isExpand && (
							<ComposeMessage
								initialValues={this.getLastMessageInitialValues()}
								historyHeader={this.getHistoryHeader(thread)}
								disabledTextarea={!currentUserAssignedToThread}
								isNewTab={this.props.isNewTab}
								threadId={thread.id}
								thread={thread}
								collapsed
							/>
						)}
					</Flex>
					{!this.props.isExpand && (
						<Settings>
							<Gap top right bottom="lg" left outside={false} isBlock>
								<ModalContext.Provider>
									<AssignTo
										loading={isThreadLoading}
										assignedTo={assignedTo}
										showTitle={false}
										threadId={thread.id}
										threadCompany={thread.company}
										editAssignTo={editAssignTo}
									/>
								</ModalContext.Provider>
								<Permissions
									threadPermissions={groupPermissions}
									loading={isThreadLoading}
								/>
							</Gap>
							<Gap
								top="sm"
								right
								bottom="lg"
								left
								outside={false}
								isBlock
								className={styles.topBorder}
							>
								<Title.H4>
									<Text weight="bold">{`Context & Job`}</Text>
								</Title.H4>
								<AssignToJob
									thread={thread}
									isAppointmentFromNomination={false}
								/>
								<AssignToJob
									thread={thread}
									isAppointmentFromNomination={true}
									isPreviewModeEnabled={isPreviewModeEnabled}
								/>
								<Context showTitle={false} />
								<ThreadTags />
							</Gap>
							<Gap
								top="sm"
								right
								bottom="lg"
								left
								outside={false}
								isBlock
								className={styles.topBorder}
							>
								<Title.H4>
									<Text weight="bold">Internal Comments</Text>
								</Title.H4>
								<InternalComments threadId={thread.id} />
							</Gap>
						</Settings>
					)}
				</ScrollableLayout>
			</ScrollableLayout>
		);
	}
}

export default connect(
	(state: AppState) => ({
		currentUserAssignedToThread: getIsCurrentUserAssignedToActiveThread(state),
		events: getThreadEventsList(state),
		groupPermissions: getThreadGroupPermissions(state),
		isEventsLoading: getIsThreadEventsLoading(state),
		isThreadLoading: getIsThreadLoading(state),
		latestMessageEvent: getLatestMessageEvent(state),
		messageFrom: getComposeMessageFromSelector(state),
		threadPermissionGroups: getThreadPermissionGroupsOptionsSelector(state),
		getIsPreviewModeEnabled: getIsPreviewModeEnabled(state)
	}),
	{
		resetThreadEvents,
		resetPortCallsDuplicates,
		retrieveThreadByIdCycleStart,
		retrieveThreadByIdCycleStop,
		retrieveThreadEvents,
		setActiveThreadId,
		updateThreadMessageRead,
		updateThreadStatus,
		resetThreadsContext,
		resetThread,
		updateFullScreenMode
	}
)(Thread);
