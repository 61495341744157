import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	AsyncActionCreators,
	Action,
	Success,
	Failure,
	Meta
} from 'typescript-fsa';
import { AxiosTypedPromise, ResultCollection } from 'services/api/apiTypes';
import { isFunction } from 'lodash';
import { ActionMeta } from 'app-types';

type CustomMeta = ActionMeta | Meta;

/**
 * P - initial API parameters
 * S - response
 * E - error
 * A - actual API parameters. Default: equal to initial - P
 * T - meta template parameters. Default: equal to initial - P
 */
export interface ExecutorConfig<P, S, E, A = P, T = P> {
	async: AsyncActionCreators<P, S, E>;
	api?: (
		params?: A
	) => AxiosTypedPromise<ResultCollection<S>> | AxiosTypedPromise<S>;
	getApiParams?: (params: P) => SagaIterator;
	getMetaTemplateParams?: (
		action: Success<P, S> | Failure<P, E>
	) => IterableIterator<T>;
	// Be careful: params are not API params
	onSuccess?: (action: Success<P, S>) => SagaIterator;
	onError?: (params: P, error: Error) => SagaIterator;
}

/**
 * Return executor function with standard content.
 * put started action
 * call API method
 * put done in case of succes respose and failed in the opposite case
 * @param config Allows to pass async action from action and API method
 */
export function makeDefaultExecutor<P, S, E, A = P, T = P>(
	config: ExecutorConfig<P, S, E, A, T>
) {
	const {
		async,
		api,
		getApiParams,
		onSuccess,
		onError,
		getMetaTemplateParams
	} = config;

	if (!api) {
		throw Error('[makeDefaultExecutor] Missing API call in executor');
	}

	return function* executor(params: P, meta?: CustomMeta): SagaIterator {
		let commonMeta: CustomMeta | undefined = meta;
		yield put(async.started(params));
		try {
			const apiParams: A = getApiParams
				? yield call(getApiParams, params)
				: params;

			const response = yield call(api, apiParams);
			const payload: Success<P, S> = {
				result: response?.data,
				params,
				response
			};

			if (getMetaTemplateParams) {
				const dynamicTemplateParams = yield call(
					getMetaTemplateParams,
					payload
				);
				commonMeta = {
					...commonMeta,
					templateParams: {
						...commonMeta?.templateParams,
						...dynamicTemplateParams
					}
				};
			}
			yield put(async.done(payload, commonMeta));
			if (isFunction(onSuccess)) {
				yield call(onSuccess, payload);
			}
		} catch (error) {
			const payload: Failure<P, E> = {
				error,
				params
			};

			if (getMetaTemplateParams) {
				const dynamicTemplateParams = yield call(
					getMetaTemplateParams,
					payload
				);
				commonMeta = {
					...commonMeta,
					templateParams: {
						...commonMeta?.templateParams,
						...dynamicTemplateParams
					}
				};
			}

			yield put(async.failed(payload, commonMeta));
			if (isFunction(onError)) {
				yield call(onError, params, error);
			}
		}
	};
}

/**
 * Watcher based on the take saga effect which is provided as a third parameter
 * @param config Pass config to the executor
 * @param externalExecutor It's not necessary parameter. Expected custom executor
 * @param takeEffect The redux saga effect which is to be created to wait for the action specified in the "config"
 */
function makeTakeWatcher<P, S, E = Error, A = P, T = P>(
	config: ExecutorConfig<P, S, E, A, T>,
	takeEffect: typeof takeLatest | typeof takeEvery,
	externalExecutor?: (params?: P, meta?: ActionMeta | null) => SagaIterator
) {
	const executor =
		externalExecutor || makeDefaultExecutor<P, S, E, A, T>(config);
	const worker = function*({ payload, meta }: Action<P>) {
		yield call(executor, payload, meta);
	};

	return function*() {
		yield takeEffect(config.async.type, worker);
	};
}

/**
 * Watcher based on the takeLatest saga effect
 * @param config
 * @param externalExecutor
 */
export function makeTakeLatestWatcher<P, S, E = Error, A = P, T = P>(
	config: ExecutorConfig<P, S, E, A, T>,
	externalExecutor?: (params?: P, meta?: ActionMeta | null) => SagaIterator
) {
	return makeTakeWatcher(config, takeLatest, externalExecutor);
}

/**
 * Watcher based on the takeEvery saga effect
 * @param config
 * @param externalExecutor
 */
export function makeTakeEveryWatcher<P, S, E = Error, A = P, T = P>(
	config: ExecutorConfig<P, S, E, A, T>,
	externalExecutor?: (params?: P, meta?: ActionMeta | null) => SagaIterator
) {
	return makeTakeWatcher(config, takeEvery, externalExecutor);
}
