import * as React from 'react';
import { connect } from 'react-redux';
import { Tag, Badge } from 'components/antd';
import { getThemeByMetricTypeGroup } from 'store/portcalls/selectors';
import { PortJob } from 'services/api/portJobs/portJobsServiceTypes';
import { PortCallAlertsMap } from 'app-types';
import portCallStyles from '../../PortCalls.module.scss';
import { lookupPortJobStatusLabel } from 'store/portJobs/portJobsSelectors';
import { UserType } from 'services/api/users/userServiceTypes';
import { getUserType } from 'store/auth/selectors';
import JobStatusButton from './JobStatusButton';
import { getIsJobHasFundingAlert } from 'store/portcalls/portCallsSelectors';
import { AppState } from 'store-types';
import { AlertMetricType } from 'app-constants';

interface JobStatusProps {
	status: string;
	portJob: PortJob;
	portCallAlertsMap?: PortCallAlertsMap;

	hasAlert: AlertMetricType[];
	userType: UserType;
}

class JobStatus extends React.Component<JobStatusProps> {
	render() {
		const {
			portCallAlertsMap,
			userType,
			status,
			portJob,
			hasAlert
		} = this.props;
		const {
			financeStatus,
			id,
			queriedToHub,
			queriedToLPA,
			isCancelled
		} = portJob;

		const theme = getThemeByMetricTypeGroup(id, portCallAlertsMap);
		return (
			<JobStatusButton hasAlert={hasAlert}>
				<Badge show={!!theme} dot placement="topRight" theme={theme}>
					<Tag className={portCallStyles.tag}>
						{lookupPortJobStatusLabel(
							status,
							financeStatus,
							userType,
							queriedToHub,
							queriedToLPA,
							isCancelled
						)}
					</Tag>
				</Badge>
			</JobStatusButton>
		);
	}
}

export default connect(
	(state: AppState, ownProps: Pick<JobStatusProps, 'portJob'>) => ({
		userType: getUserType(state),
		hasAlert: getIsJobHasFundingAlert(state, ownProps.portJob.id)
	})
)(JobStatus);
