import { Success } from 'typescript-fsa';
import {
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse
} from 'services/api/supplierInvoices/supplierInvoicesServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { SupplierInvoiceState } from '../supplierInvoiceState';

export const onRetrieveSupplierInvoiceContextualizationSuccess = (
	state: SupplierInvoiceState,
	action: Success<
		RetrieveSupplierInvoiceContextualizationRequest,
		RetrieveSupplierInvoiceContextualizationResponse
	>
) => ({
	...state,
	contextualization: {
		...state.contextualization,
		portJobs: action.result.elements
	}
});

export const onRetrieveSupplierInvoiceContextualization = makeFetchStatusReducers<
	SupplierInvoiceState,
	'fetchStatuses',
	RetrieveSupplierInvoiceContextualizationRequest,
	RetrieveSupplierInvoiceContextualizationResponse
>('fetchStatuses', 'contextualization', {
	doneReducer: onRetrieveSupplierInvoiceContextualizationSuccess
});
