import * as React from 'react';
import { connect } from 'react-redux';

import { getPerformingAgent, getJobType } from './page2Selectors';

import { AutoCompleteObjectReference } from 'app-types';
import { retrievePortCallsStatic } from 'store/portcalls/actions';
import PortJobInfo from 'sections/PortJob/SubHeader/Info/PortJobInfo';
import { StaticItem } from 'store/portcalls/portCallsState';
import { AppState } from 'store-types';

export interface PageInfoProps {
	jobType: StaticItem;
	performingAgent: AutoCompleteObjectReference;
	retrievePortCallsStatic: typeof retrievePortCallsStatic;
}

export class PageInfo extends React.PureComponent<PageInfoProps> {
	static defaultProps = {
		jobTypes: [],
		jobType: { code: '', name: '' },
		performingAgent: { id: '', label: '' },
		appointer: { id: '', label: '' }
	};

	componentDidMount() {
		this.props.retrievePortCallsStatic();
	}

	render() {
		const { performingAgent, jobType } = this.props;

		return (
			<PortJobInfo
				performingAgentLabel={performingAgent.label}
				jobTypeName={jobType.name}
			/>
		);
	}
}

export default connect(
	(state: AppState) => ({
		performingAgent: getPerformingAgent(state),
		jobType: getJobType(state)
	}),
	{
		retrievePortCallsStatic
	}
)(PageInfo);
