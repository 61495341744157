import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	addPrincipalServiceAsync,
	retrievePrincipalServicesAsync,
	retrievePrincipalServiceAsync,
	removePrincipalServiceAsync,
	updatePrincipalServiceAsync,
	resetPrincipalServices,
	updatePrincipalServicesMappingAsync
} from './actions';
import {
	onAddPrincipalService,
	onRetrievePrincipalServices,
	onResetPrincipalServices,
	onRetrievePrincipalService,
	onRemovePrincipalService,
	onUpdatePrincipalService,
	onUpdatePrincipalServicesMapping
} from './reducers';
import { initialState } from './configurationServicesState';

export default reducerWithInitialState(initialState)
	// Add
	.case(addPrincipalServiceAsync.started, onAddPrincipalService.started)
	.case(addPrincipalServiceAsync.done, onAddPrincipalService.done)
	.case(addPrincipalServiceAsync.failed, onAddPrincipalService.failed)
	// Update
	.case(updatePrincipalServiceAsync.started, onUpdatePrincipalService.started)
	.case(updatePrincipalServiceAsync.done, onUpdatePrincipalService.done)
	.case(updatePrincipalServiceAsync.failed, onUpdatePrincipalService.failed)
	.case(
		updatePrincipalServicesMappingAsync.started,
		onUpdatePrincipalServicesMapping.started
	)
	.case(
		updatePrincipalServicesMappingAsync.done,
		onUpdatePrincipalServicesMapping.done
	)
	.case(
		updatePrincipalServicesMappingAsync.failed,
		onUpdatePrincipalServicesMapping.failed
	)
	// Retrieve all
	.case(
		retrievePrincipalServicesAsync.started,
		onRetrievePrincipalServices.started
	)
	.case(retrievePrincipalServicesAsync.done, onRetrievePrincipalServices.done)
	.case(resetPrincipalServices, onResetPrincipalServices)
	// Remove
	.case(removePrincipalServiceAsync.started, onRemovePrincipalService.started)
	.case(removePrincipalServiceAsync.done, onRemovePrincipalService.done)
	.case(removePrincipalServiceAsync.failed, onRemovePrincipalService.failed)
	// Retrieve one
	.case(
		retrievePrincipalServicesAsync.failed,
		onRetrievePrincipalServices.failed
	)
	.case(
		retrievePrincipalServiceAsync.started,
		onRetrievePrincipalService.started
	)
	.case(retrievePrincipalServiceAsync.done, onRetrievePrincipalService.done)
	.case(
		retrievePrincipalServiceAsync.failed,
		onRetrievePrincipalService.failed
	);
