import { AlertProps } from 'antd/lib/alert';

export enum ContextBarsType {
	INFORMATION = 'Information',
	ACTION = 'Action',
	CONFIRMATION = 'Confirmation'
}

export enum ContextBarTitleCode {
	FINANCE_AWAITING_APPROVAL = 'FinanceAwaitingApproval',
	JOB_FINANCE_BEING_APPROVED = 'JobFinanceBeingApproved',
	DA_AWAITING_APPROVAL = 'DaAwaitingApproval',
	DA_BEING_APPROVED = 'DaBeingApproved',
	FINANCE_QUERIED_TO_LPA = 'FinanceQueriedToLPA',
	YOU_QUERIED_FINANCE = 'YouQueriedFinance',
	FINANCE_QUERY_SENT_TO_LPA = 'FinanceQuerySentToLPA',
	DA_QUERY_SENT_TO_LPA = 'DAQuerySentToLPA',
	YOU_QUERIED_DA = 'YouQueriedDA',
	DA_QUERIED_TO_LPA = 'DAQueriedToLPA',
	CREATE_PDA = 'CreatePDA',
	FINANCE_VALIDATED_BY_HUB = 'FinanceValidatedByHub',
	FINANCE_WAITING_APPROVAL = 'FinanceWaitingApproval',
	VALIDATE_FINANCE = 'ValidateFinance',
	CREATE_CE = 'CreateCE',
	CREATE_DA = 'CreateDA',
	WAITING_FINANCE_SUBMITTED_BY_LPA = 'WaitingFinanceSubmittedByLPA',
	PDA_CURRENTLY_WAITING_FOR_APPROVAL = 'PDACurrentlyWaitingForApproval',
	CE_CURRENTLY_WAITING_FOR_APPROVAL = 'CECurrentlyWaitingForApproval',
	DA_CURRENTLY_WAITING_FOR_APPROVAL = 'DACurrentlyWaitingForApproval',
	FINANCE_APPROVED = 'FinanceApproved',
	CE_APPROVED = 'CEApproved',
	DA_APPROVED = 'DAApproved',
	PROCESS_FUNDING_REQUEST = 'ProcessFundingRequest',
	SOME_ITEMS_QUERIED = 'SomeItemsQueried',
	FINANCE_QUERIED = 'FinanceQueried',
	FINANCE_QUERIED_BY_CUSTOMER = 'FinanceQueriedByCustomer',
	ENABLE_FINANCE = 'EnableFinance',
	DISABLE_FINANCE = 'DisableFinance',
	AWAITING_PDA_APPROVAL = 'AwaitingPDAApproval'
}

export enum ContextBarSubtitleCode {
	PROCESS_FIRST_LEVEL_OF_APPROVAL = 'ProcessFirstLevelOfApproval',
	PROCESS_NTH_LEVEL_OF_APPROVAL = 'ProcessNthLevelOfApproval',
	WAIT_FIRST_LEVEL_OF_APPROVAL = 'WaitFirstLevelOfApproval',
	WAIT_ALL_LEVEL_OF_APPROVAL = 'WaitAllLevelOfApproval',
	YOU_COMPLETED_APPROVAL = 'YouCompletedApproval',
	YOU_HAVE_ACTIVE_ITEMS_REQUIRING_ATTENTION = 'YouHaveActiveItemsRequiringAttention',
	PLEASE_REVIEW_SERVICE_LINES_AND_APPROVE = 'PleaseReviewServiceLinesAndApprove',
	WE_WORKING_OUT_WITH_LPA_ON_FINANCE = 'WeWorkingOutWithLPAOnFinance',
	WE_RECEIVED_YOUR_QUERY = 'WeReceivedYourQuery',
	WE_NEED_INPUT_FROM_LPA_ON_QUERY = 'WeNeedInputFromLPAOnQuery',
	WE_WORKING_OUT_WITH_LPA_ON_DA = 'WeWorkingOutWithLPAOnDA',
	WE_NEED_INPUT_FROM_LPA_ON_QUERY_APPROVE_YOUR_DAS = 'WeNeedInputFromLPAOnQueryApproveYourDAs',
	WE_RECEIVED_YOUR_QUERY_APPROVE_YOUR_DAS = 'WeReceivedYourQueryApproveYourDAs',
	APPROVE_YOUR_DAS_AND_WAITING_FROM_LPA = 'ApproveYourDAsAndWaitingFromLPA',
	OPTIC_STARTED_BUILD_PDA = 'OpticStartedBuildPDA',
	WE_RECEIVED_FINANCE_FROM_LPA = 'WeReceivedFinanceFromLPA',
	IF_YOU_NEED_CHANGED_HIT_LINK = 'IfYouNeedChangedHitLink',
	OPTIC_HELPS_PRESENTING_PDA_AS_START_POINT = 'OpticHelpsPresentingPDAAsStartPoint',
	OPTIC_HELPS_PRESENTING_CE_AS_START_POINT = 'OpticHelpsPresentingCEAsStartPoint',
	ONCE_FINANCE_SUBMITED_OPTIC_WILL_VALIDATE = 'OnceFinanceSubmitedOpticWillValidate',
	LPA_HASNT_SUBMITTED_FINANCE = 'LPAHasntSubmittedFinance',
	NOTHING_TO_DO_HERE = 'NothingToDoHere',
	YOU_CAN_UPDATE_AS_REQUIRED = 'YouCanUpdateAsRequired',
	YOU_CAN_UPDATE_AS_REQUIRED_OR_MOVE_TO_CE = 'YouCanUpdateAsRequiredOrMoveToCE',
	CLICK_MOVE_TO_DA_OR_EDIT = 'ClickMoveToDAOrEdit',
	NOTHING_TO_DO_HERE_AND_LINK_FOR_FINANCE_QUESTIONS = 'NothingToDoHereAndLinkForFinanceQuestions',
	IF_MORE_COSTS_NEEDED_SUBMIT_SUPPLEMENTAL_DA = 'IfMoreCostsNeededSubmitSupplementalDA',
	NOTHING_TO_DO_HERE_AND_LINK_FOR_DA_QUESTIONS = 'NothingToDoHereAndLinkForDAQuestions',
	FUNDING_REQUESTS_WAITING_REVIEW = 'FundingRequestsWaitingReview',
	INFORM_AS_LPA_RESPONDS_QUERIES = 'InformAsLPARespondsQueries',
	REVIEW_QUERIED_LINES_AND_RESPOND_TO_HUB = 'ReviewQueriedLinesAndRespondToHub',
	INFORM_AS_LPA_RESPONDS_QUERIES_OR_TOLERANCE_EXPIRES = 'InformAsLPARespondsQueriesOrToleranceExpires',
	REVIEW_QUERIED_LINES_AND_RESPOND_TO_PRINCIPAL_OR_LPA = 'ReviewQueriedLinesAndRespondToPrincipalOrLPA',
	ENABLE_FINANCE_IF_NEEDED = 'EnableFinanceIfNeeded',
	DISABLE_FINANCE_IF_NEEED = 'DisableFinanceIfNeeded',
	AWAITING_PDA_APPROVAL = 'AwaitingPDAApproval'
}

export enum ContextBarsActionCode {
	EMAIL_TO_MAIN_PRINCIPAL = 'EmailToMainPrincipal',
	MOVE_TO_DA = 'MoveToDA',
	MOVE_TO_CE = 'MoveToCE',
	REVERT_FINANCE_STATUS = 'RevertFinanceStatus',
	ENABLE_FINANCE = 'EnableFinance',
	DISABLE_FINANCE = 'DisableFinance'
}

export interface ContextBarView {
	title: string;
	subtitle: string;
	type: AlertProps['type'];
	actionCodes?: ContextBarsActionCode[];
}

export interface ContextBarPlaceholder {
	name: string;
	value: string;
}

export interface ContextBar {
	titleCode: string;
	subtitleCode: string;
	type: ContextBarsType;
	placeholders?: ContextBarPlaceholder[];
	actionCodes?: ContextBarsActionCode[];
}

export interface ContextBarButtonAction {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action: () => any;
	actionText: string;
	type: 'ghost' | 'button';
	loading: boolean;
}
