import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
	addThreadPermissions,
	retrieveMailboxAvailableGroupsAsync,
	retrieveThreadByIdAsync,
	retrieveThreadMessageByIdAsync,
	assignJobToThreadAsync,
	resetThread,
	unassignJobFromThreadAsync,
	updateContextAsync,
	retrieveMessageDataAsync,
	addThreadMessageAsync,
	updateThreadStatusAsync,
	updateThreadMessageReadAsync,
	patchThreadPermissionsAsync,
	setIsContextAndJobVisible
} from './actions';

import {
	onUnassignJobFromThreadFailed,
	onUnassignJobFromThreadStarted,
	onUnassignJobFromThreadSuccess,
	onRetrieveThreadByIdStarted,
	onRetrieveThreadByIdFailed,
	onRetrieveThreadByIdSuccess,
	onRetrieveThreadMessageByIdStarted,
	onRetrieveThreadMessageByIdFailed,
	onRetrieveThreadMessageByIdSuccess,
	onAssignJobToThreadsStarted,
	onAssignJobToThreadsFailed,
	onAssignJobToThreadsSuccess,
	onUpdateContextStarted,
	onUpdateContextSuccess,
	onUpdateContextFailed,
	onAddThreadPermissionsSuccess,
	onAddThreadMessage,
	onUpdateThreadStatusSuccess,
	onPatchThreadPermissions,
	onRetrieveMessageData,
	onUpdateThreadMessageReadSuccess,
	onSetIsContextAndJobVisible
} from './reducers';

import {
	onRetrieveMailboxAvailableGroupsStarted,
	onRetrieveMailboxAvailableGroupsSuccess,
	onRetrieveMailboxAvailableGroupsFailed
} from './reducers/retrieveMailboxAvailableGroups';

import { initialState, ThreadReducerState } from './threadState';
import { quickJobAssignment } from './reducers/quickJobToThreadAssigmentReducers';
import { threadContextReducer } from './reducers/threadContextReducer';

export default reducerWithInitialState(initialState)
	// Retrieve Thread By Id
	.case(retrieveThreadByIdAsync.started, onRetrieveThreadByIdStarted)
	.case(retrieveThreadByIdAsync.failed, onRetrieveThreadByIdFailed)
	.case(retrieveThreadByIdAsync.done, onRetrieveThreadByIdSuccess)
	/**
	 * Add Message
	 */
	.case(addThreadMessageAsync.started, onAddThreadMessage.started)
	.case(addThreadMessageAsync.done, onAddThreadMessage.done)
	.case(addThreadMessageAsync.failed, onAddThreadMessage.failed)
	// Retrieve Mailbox Available Groups
	.case(
		retrieveMailboxAvailableGroupsAsync.started,
		onRetrieveMailboxAvailableGroupsStarted
	)
	.case(
		retrieveMailboxAvailableGroupsAsync.failed,
		onRetrieveMailboxAvailableGroupsFailed
	)
	.case(
		retrieveMailboxAvailableGroupsAsync.done,
		onRetrieveMailboxAvailableGroupsSuccess
	)
	// Retrieve ThreadMessage By Id
	.case(
		retrieveThreadMessageByIdAsync.started,
		onRetrieveThreadMessageByIdStarted
	)
	.case(
		retrieveThreadMessageByIdAsync.failed,
		onRetrieveThreadMessageByIdFailed
	)
	.case(retrieveThreadMessageByIdAsync.done, onRetrieveThreadMessageByIdSuccess)
	// Assign a Job Code to a Thread
	.case(assignJobToThreadAsync.started, onAssignJobToThreadsStarted)
	.case(assignJobToThreadAsync.failed, onAssignJobToThreadsFailed)
	.case(assignJobToThreadAsync.done, onAssignJobToThreadsSuccess)
	// Un-assign a Job Code from a Thread
	.case(unassignJobFromThreadAsync.started, onUnassignJobFromThreadStarted)
	.case(unassignJobFromThreadAsync.failed, onUnassignJobFromThreadFailed)
	.case(unassignJobFromThreadAsync.done, onUnassignJobFromThreadSuccess)
	.case(resetThread, () => initialState)
	.case(updateThreadStatusAsync.done, onUpdateThreadStatusSuccess)
	// update context
	.case(updateContextAsync.started, onUpdateContextStarted)
	.case(updateContextAsync.done, onUpdateContextSuccess)
	.case(updateContextAsync.failed, onUpdateContextFailed)
	// Update Thread Assigned Group
	.case(patchThreadPermissionsAsync.started, onPatchThreadPermissions.started)
	.case(patchThreadPermissionsAsync.failed, onPatchThreadPermissions.failed)
	.case(patchThreadPermissionsAsync.done, onPatchThreadPermissions.done)
	.case(addThreadPermissions, onAddThreadPermissionsSuccess)
	.case(retrieveMessageDataAsync.started, onRetrieveMessageData.started)
	.case(retrieveMessageDataAsync.failed, onRetrieveMessageData.failed)
	.case(retrieveMessageDataAsync.done, onRetrieveMessageData.done)
	/**
	 * Update current thread `isRead`
	 */
	.case(updateThreadMessageReadAsync.done, onUpdateThreadMessageReadSuccess)
	.case(setIsContextAndJobVisible, onSetIsContextAndJobVisible)
	.default((state: ThreadReducerState, action) => ({
		...state,
		quickJobAssigment: quickJobAssignment(state.quickJobAssigment, action),
		context: threadContextReducer(state.context, action)
	}));
