import { DashboardState as State } from '../dashboardState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	RetrieveDashboardPortCallsRequest as Request,
	DashboardPortCallsResponse as Response
} from 'services/api/dashboard/dashboardServiceTypes';
import { Success } from 'typescript-fsa';

export const onRetrieveActiveSection = makeFetchStatusReducers<
	State,
	'fetchStatuses',
	Request,
	Response
>('fetchStatuses', 'retrieveActiveSection', {
	doneReducer: (state: State, action: Success<Request, Response>): State => ({
		...state,
		portCallsByStatusCode: {
			...state.portCallsByStatusCode,
			[action.params.statusCode]: action.result.elements
		}
	})
});
