import { FetchStatus } from 'services/api/apiTypes';
import {
	Company,
	CompaniesContactDetails,
	CompaniesConfigurationSettings,
	RetrieveCompanyGeneralConfigurationReportsResponse,
	ConfigurationBenchmarkData
} from 'services/api/companies/companiesServiceTypes';

import { FileBaseInfo } from 'services/api/documents/documentsServiceTypes';

export interface CompaniesState {
	readonly byId: { [id: string]: Company };
	readonly principals: Company[];
	readonly byOrganisation: { [type: string]: Company[] };
	readonly configurationSettings: CompaniesConfigurationSettings | null;
	readonly preferredAgentsFileInfo: {
		[companyId: string]: FileBaseInfo;
	};
	readonly contactDetails: CompaniesContactDetails[];
	readonly generalConfiguration: {
		reports: {
			[companyId: string]: RetrieveCompanyGeneralConfigurationReportsResponse | null;
		};
		benchmark: {
			[companyId: string]: ConfigurationBenchmarkData | null;
		};
	};
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		all: FetchStatus;
		contactDetails: FetchStatus;
		retrieveGeneralConfigurationReports: FetchStatus;
		retrieveCompanyBenchmark: FetchStatus;
		updateGeneralConfiguration: FetchStatus;
		retrieveForOrganisation: FetchStatus;
		retrievePreferredAgentsFileInfo: FetchStatus;
		retrieveCompanyJobTypes: FetchStatus;
		[status: string]: FetchStatus;
	};
	readonly jobTypes: string[];
}

export const initialState: CompaniesState = {
	byId: {},
	principals: [],
	byOrganisation: {},
	configurationSettings: null,
	preferredAgentsFileInfo: {},
	contactDetails: [],
	generalConfiguration: {
		reports: {},
		benchmark: {}
	},
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE,
		contactDetails: FetchStatus.IDLE,
		retrieveGeneralConfigurationReports: FetchStatus.IDLE,
		retrieveCompanyBenchmark: FetchStatus.IDLE,
		updateGeneralConfiguration: FetchStatus.IDLE,
		retrieveForOrganisation: FetchStatus.IDLE,
		retrievePreferredAgentsFileInfo: FetchStatus.IDLE,
		retrieveCompanyJobTypes: FetchStatus.IDLE
	},
	jobTypes: []
};

export interface CompaniesForUserState {
	readonly byId: { [id: string]: Company };
	readonly hasMore: boolean;
	readonly fetchStatuses: {
		all: FetchStatus;
		[status: string]: FetchStatus;
	};
}

export const initialStateForUser: CompaniesForUserState = {
	byId: {},
	hasMore: true,
	fetchStatuses: {
		all: FetchStatus.IDLE
	}
};
