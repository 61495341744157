import {
	UpdatePrincipalServiceRequest,
	PrincipalService
} from 'services/api/services/servicesServiceTypes';
import actionCreator from '../configurationServicesActionCreator';

const ACTION_NAME = 'UPDATE_PRINCIPAL_SERVICE';

export const updatePrincipalServiceAsync = actionCreator.async<
	UpdatePrincipalServiceRequest,
	PrincipalService,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Principal Specific Service'
	}
});

export const updatePrincipalService = actionCreator<
	UpdatePrincipalServiceRequest
>(ACTION_NAME);
