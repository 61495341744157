export const isExternalLink = (url: string) => {
	const host = window.location.hostname;

	const linkHost = ((linkUrl: string) => {
		if (/^https?:\/\//.test(linkUrl)) {
			// Absolute URL.
			// The easy way to parse an URL, is to create <a> element.
			// @see: https://gist.github.com/jlong/2428561
			const parser = document.createElement('a');
			parser.href = linkUrl;
			return parser.hostname;
		} else {
			// Relative URL.
			return window.location.hostname;
		}
	})(url);

	return host !== linkHost;
};
