import { createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';
import { AppState } from 'store-types';
import { FetchStatus } from 'services/api/apiTypes';

export const getValueSelector = <T>(selector: (state: AppState) => T) => {
	return <K extends keyof T>(state: AppState, field: K) => {
		const value = selector(state);
		return value[field];
	};
};
export const getIsIdle = (status: FetchStatus) => status === FetchStatus.IDLE;

export const getIsLoading = (status: FetchStatus) =>
	status === FetchStatus.PENDING;

export const getIsLoaded = (status: FetchStatus) =>
	status === FetchStatus.SUCCESS;

export const getIsDone = (status: FetchStatus) =>
	status === FetchStatus.SUCCESS || status === FetchStatus.FAILURE;

export const getIsFailure = (status: FetchStatus) =>
	status === FetchStatus.FAILURE;

export const createDeepEqualSelector = createSelectorCreator(
	defaultMemoize,
	isEqual
);
