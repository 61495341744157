import { Success } from 'typescript-fsa';
import { PortJobState } from '../portJobsState';
import {
	InviteAgentToPortJobParams,
	PortJobStatusUpdateResponse
} from 'services/api/portJobs/portJobsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { PortJobStatus } from 'store/portJobs/constants';

const onInviteAgentToPortJobSuccess = (
	state: PortJobState,
	{ result }: Success<InviteAgentToPortJobParams, PortJobStatusUpdateResponse>
) => {
	const updatedById = Object.keys(state.byId).reduce((all, id) => {
		const job = {
			...state.byId[id]
		};
		if (result.status === PortJobStatus.EXECUTION) {
			job.status = PortJobStatus.EXECUTION;
		}
		all[id] = job;
		return all;
	}, {});
	return {
		...state,
		byId: state.byId ? updatedById : {}
	};
};

export const onInviteAgentToPortJob = makeFetchStatusReducers<
	PortJobState,
	'fetchStatuses',
	InviteAgentToPortJobParams,
	PortJobStatusUpdateResponse
>('fetchStatuses', 'inviteAgentToPortJob', {
	doneReducer: onInviteAgentToPortJobSuccess
});
