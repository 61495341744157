import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import {
	RestoreOriginalDocumentRequest,
	restoreOriginalDocumentAsync,
	retrieveFinanceDocument
} from '../actions';
import { retrieveSlitSupplierInvoiceDocument } from 'store/supplierInvoice/actions';
import Api from 'services/api';
import { RestoreOriginalFinanceDocumentRequest } from 'services/api/finance/financeServiceTypes';

const worker = function*({
	payload
}: Action<RestoreOriginalDocumentRequest>): SagaIterator {
	try {
		yield put(restoreOriginalDocumentAsync.started(payload));

		if ('supplierInvoiceId' in payload) {
			const { supplierInvoiceId, serviceId } = payload;
			yield call(Api.SupplierInvoice.restoreOriginalSupplierInvoiceDocument, {
				supplierInvoiceId,
				serviceId
			});
			yield put(
				retrieveSlitSupplierInvoiceDocument({
					supplierInvoiceId,
					serviceId
				})
			);
		} else {
			const { portCallId, id, jobCode, documentType } = payload;

			yield call(Api.Finance.restoreOriginalFinanceDocument, {
				portCallId,
				jobCode: jobCode,
				id,
				documentType
			} as RestoreOriginalFinanceDocumentRequest);

			yield put(
				retrieveFinanceDocument({
					portCallId,
					jobCode: jobCode,
					id,
					documentType
				})
			);
		}
		yield put(
			restoreOriginalDocumentAsync.done({
				result: null,
				params: payload,
				response: null
			})
		);
	} catch (error) {
		yield put(restoreOriginalDocumentAsync.failed({ error, params: payload }));
	}
};

export default function* restoreOriginalDocumentWatcher(): SagaIterator {
	yield takeLatest(restoreOriginalDocumentAsync.type, worker);
}
