import { getActivePortCallId } from 'store/portcalls/selectors';
import { Action, Success } from 'typescript-fsa';
import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { navigateTo } from 'utils';
import { makeDefaultExecutor } from 'utils/sagaHelpers';
import { CreatePortCallDocumentRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { FormData } from 'sections/PortCall/Operations/Contextualization/ContextualizationForm/ContextualizationFormTypes';
import {
	createPortCallDocumentAsync,
	retrieveUploadedDocuments,
	retrieveUploadedDocumentsAsync
} from '../../actions';
import { notify } from 'store/notifications/actions';
import {
	getContextualizationFormValues,
	getContextualizationFormSyncErrors
} from 'store/form/operationsContextualization/selectors';
import { getIsDocumentPDF } from 'store/documents/selectors';
import Api from 'services/api';
import prepareRequest from './prepareRequest/prepareRequest';
import { DOCUMENTS_DATA_INCONSISTENCY } from 'store/portCallDocuments/constants';
import { hasUploadOperationDocumentsMetadata } from '../retrieveUploadedDocumentsSaga';

const executor = makeDefaultExecutor<
	CreatePortCallDocumentRequest,
	null,
	Error
>({
	api: Api.PortCallDocuments.createPortCallDocument,
	async: createPortCallDocumentAsync,
	*onSuccess({ params }: Success<CreatePortCallDocumentRequest, null>) {
		yield call(
			navigateTo,
			`/portcalls/${params.portCallId}/operations/documents`
		);
	}
});

function* worker({ payload }: Action<CreatePortCallDocumentRequest>) {
	/** Get Active portCallId from the store */
	const portCallId = yield select(getActivePortCallId);
	// Requesting the metadata for validation in advance

	yield put(
		retrieveUploadedDocuments({
			portCallId
		})
	);
	// Cheching UPLOAD_OPERATION_DOCUMENTS metadata
	const { payload: response } = yield take([
		retrieveUploadedDocumentsAsync.done,
		retrieveUploadedDocumentsAsync.failed
	]);
	if (response.error) {
		return;
	}
	if (!hasUploadOperationDocumentsMetadata(response?.result)) {
		yield put(notify.warning(DOCUMENTS_DATA_INCONSISTENCY));
		return;
	}

	const params = {
		portCallId,
		...payload
	};
	const values: FormData = yield select(getContextualizationFormValues);
	const syncErrors = yield select(getContextualizationFormSyncErrors);
	const isPDF = yield select(getIsDocumentPDF, params.data.documentId);
	const request = prepareRequest(params, values, syncErrors, isPDF);

	yield call(executor, {
		portCallId: params.portCallId,
		data: request
	});
}

export default function*() {
	yield takeLatest(createPortCallDocumentAsync.type, worker);
}
