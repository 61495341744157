import { VesselProgrammeState, initialState } from '../vesselProgrammeState';

export const onUpdateVPProcessing = (
	state: VesselProgrammeState
): VesselProgrammeState => ({
	...state
});

export const onUpdateVPHash = (
	state: VesselProgrammeState,
	hash: string
): VesselProgrammeState => ({
	...state,
	hash
});

export const onUpdateSaveVPRestrictions = (
	state: VesselProgrammeState,
	saveEnabled: boolean
): VesselProgrammeState => ({
	...state,
	saveEnabled,
	...(!saveEnabled && {
		fetchStatuses: {
			...state.fetchStatuses,
			save: initialState.fetchStatuses.save
		}
	})
});
