import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { LinkJobState } from '../linkJobState';
import {
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse
} from 'services/api/portCalls/portCallsServiceTypes';

export const onSaveUpdatedLinkJobCargos = makeFetchStatusReducers<
	LinkJobState,
	'fetchStatuses',
	SaveLinkedCargosDetailsRequest,
	MergePortCallsResponse
>('fetchStatuses', 'saveUpdatedLinkCargo');
