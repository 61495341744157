import { SagaIterator } from 'redux-saga';
import {
	call,
	takeLatest,
	all,
	fork,
	put,
	select,
	takeMaybe
} from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { isEmpty, keys } from 'lodash';
import {
	ValidateJobFinanceRequest as request,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
import {
	validateJobFinance,
	validateJobFinanceAsync,
	moveJobFinance,
	updateSDAReason
} from '../actions';
import { notify } from 'store/notifications/actions';
import { openModal, closeModal, CloseModalParams } from 'store/modals/actions';
import {
	getAllDaInsHaveBankDetails,
	getDaSetsIdByUserType,
	getEditableDaInsBankDetails
} from 'store/finance/selectors';
import { getIsCurrentUserLpa, getUserType } from 'store/auth/selectors';
import { getServicesWithFutureDAIn } from '../selectors/financeDaForServicesSelectors';
import {
	SERVICES_CONFIRMATION_ID,
	BANK_ACCOUNT_CONFIRMATION_MODAL_ID
} from '../constants';
import Api from 'services/api';
import { AppState } from 'store-types';

const apiCall = Api.Finance.validateJobFinance;

enum ErrorCodeEnum {
	NoBankDetails
}

class MoveFinanceValidationError extends Error {
	code: number;
	constructor(code: number, message: string) {
		super(message);
		this.code = code;
	}
}

export function* preValidateJobFinanceExecutor() {
	const isUserLpa = yield select(getIsCurrentUserLpa);
	const dasInHaveBankDetails = yield select(getAllDaInsHaveBankDetails);
	if (isUserLpa && !dasInHaveBankDetails) {
		throw new MoveFinanceValidationError(
			ErrorCodeEnum.NoBankDetails,
			`Some of the DAs you want to submit don't have bank account details specified. Please select them before submitting`
		);
	}

	// additional conditional confirmation for LPA
	if (isUserLpa) {
		// checking whether there are services with Future Set
		const servicesWithFutureDas: PortJobService[] = yield select(
			getServicesWithFutureDAIn
		);
		if (!isEmpty(servicesWithFutureDas)) {
			yield put(openModal(SERVICES_CONFIRMATION_ID));
			const {
				payload: { isConfirmed }
			}: Action<CloseModalParams> = yield takeMaybe(closeModal.type);
			if (!isConfirmed) {
				// validation is not passed
				return false;
			}
		}
	}

	return true;
}

export function* postValidateJobFinanceExecutor() {
	const isUserLpa = yield select(getIsCurrentUserLpa);
	// another additional conditional confirmation for LPA (after all the other validation)
	if (isUserLpa) {
		// checking whether the bank details are indicated and will be locked for further changes
		const editableDasInBankDetails = yield select(getEditableDaInsBankDetails);
		if (editableDasInBankDetails?.length) {
			yield put(openModal(BANK_ACCOUNT_CONFIRMATION_MODAL_ID));
			const {
				payload: { isConfirmed }
			}: Action<CloseModalParams> = yield takeMaybe(closeModal.type);
			if (!isConfirmed) {
				// validation is not passed
				return false;
			}
		}
	}

	return true;
}

export function* executor(
	actionParams: request,
	api: typeof apiCall
): SagaIterator {
	yield put(validateJobFinanceAsync.started(actionParams));

	try {
		const preValidationPassed = yield call(preValidateJobFinanceExecutor);
		if (!preValidationPassed) {
			// cancel the process
			return;
		}

		const response = yield call(api, actionParams);

		const postValidationPassed = yield call(postValidateJobFinanceExecutor);
		if (!postValidationPassed) {
			// cancel the process
			return;
		}

		yield put(
			validateJobFinanceAsync.done({
				result: response.data,
				params: actionParams,
				response
			})
		);

		const userType = yield select(getUserType);
		const daSetsRequiredSDAReason = yield select((state: AppState) =>
			getDaSetsIdByUserType(state, userType)
		);

		if (actionParams.sdaReason && daSetsRequiredSDAReason.id) {
			yield put(
				updateSDAReason({
					portCallId: actionParams.portCallId,
					jobCode: actionParams.jobCode,
					disbursementAccountSetId: daSetsRequiredSDAReason.id,
					sdaReason: actionParams.sdaReason,
					userType
				})
			);
		}
		// start submitting just after validation
		yield put(moveJobFinance(actionParams));
	} catch (error) {
		if (error.code === ErrorCodeEnum.NoBankDetails) {
			yield put(notify.warning(error.message));
		}

		// intentionally not dispatching `failed` for 400 http status code
		// reactToChanges watcher is listening to failed action in order to
		// add warning notification with `refresh` button, thus simulating concurrency status code error, thus showing that finance is outdated.
		// considering that 412 isnt always an indicator of an outdated version, also 400/500 are possible, but also not always :)
		// so here when we get `ValidationError` field, `failed` isn't dispatched to prevent showing notifications from `reactToChanges` watcher
		if (
			error.response &&
			keys(error.response.data).includes('ValidationError')
		) {
			const defaultMessage =
				'Some required data is missing. Please make sure all Disbursment accounts and services have all required information filled.';

			// in this situation, showing `correlationId` isn't needed since it is bussiness error
			yield put(
				notify.error(error.response.data.ValidationError[0] || defaultMessage)
			);
		} else {
			yield put(
				validateJobFinanceAsync.failed({
					error,
					params: actionParams
				})
			);
		}
	}
}

function* worker({ payload }: Action<request>): SagaIterator {
	yield call(executor, payload, apiCall);
}

function* watcher(): SagaIterator {
	yield takeLatest(validateJobFinance.type, worker);
}

export default function*() {
	yield all([fork(watcher)]);
}
