import React, { useContext } from 'react';

import DeleteGroupModal, {
	DeleteGroupModalConfigProps
} from './DeleteGroupModal/DeleteGroupModal';
import CreateGroupModal from './CreateGroupModal/CreateGroupModal';

import { UseModalContext } from 'context';
import { GroupsModalsName } from './GroupsModalsTypes';

interface GroupsModalsProps {
	deleteModalConfigProps?: DeleteGroupModalConfigProps;
}

function GroupsModals({ deleteModalConfigProps }: GroupsModalsProps) {
	const { name, modalValue, modalSubmitting, hideModal } = useContext(
		UseModalContext
	);

	return (
		<>
			{name === GroupsModalsName.DELETE_GROUP && (
				<DeleteGroupModal
					modalValue={modalValue}
					modalSubmitting={modalSubmitting}
					hideModal={hideModal}
					{...deleteModalConfigProps}
				/>
			)}

			{name === GroupsModalsName.ADD_GROUP && (
				<CreateGroupModal
					modalSubmitting={modalSubmitting}
					hideModal={hideModal}
				/>
			)}
		</>
	);
}

export default GroupsModals;
