import { all, fork } from 'redux-saga/effects';
import retrieveBunkerGradesWatcher from './retrieveBunkerGradesSaga';
import { retrieveOperationsWatcher } from './retrieveOperationsSaga';

export default function*() {
	yield all([
		fork(retrieveBunkerGradesWatcher),
		fork(retrieveOperationsWatcher)
	]);
}
