import actionCreator from '../financeActionCreator';
import { RetrieveDAStatusHistoryByIdRequest } from 'services/api/finance/financeServiceTypes';

const ACTION_NAME = 'RESET_DA_STATUS_HISTORY_BY_ID';

export type ResetDAStatusHistoryByIdParams = Pick<
	RetrieveDAStatusHistoryByIdRequest,
	'daId'
>;

export const resetDAStatusHistoryById = actionCreator<
	ResetDAStatusHistoryByIdParams
>(ACTION_NAME);
