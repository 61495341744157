import actionCreator from '../portCallDocumentsActionCreator';
import { PortCallDocumentNotIssuedRequest } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

const ACTION_NAME = 'MARK_PORT_CALL_DOCUMENT_NOT_ISSUED';

export type PortCallDocumentNotIssuedPayload = Pick<
	PortCallDocumentNotIssuedRequest,
	'portCallId' | 'documentId' | 'portCallPlaceholderId' | 'data'
>;

export const markPortCallDocumentNotIssuedAsync = actionCreator.async<
	PortCallDocumentNotIssuedPayload,
	null,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Document'
	}
});

export const markPortCallDocumentNotIssued = actionCreator<
	PortCallDocumentNotIssuedPayload
>(ACTION_NAME);
