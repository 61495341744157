import { updateCompanyTemplatesAsync } from '../actions';
import Api from 'services/api';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import { UpdateCompanyTemplatesRequest } from 'services/api/templates/templatesServiceTypes';

export default makeTakeLatestWatcher<
	UpdateCompanyTemplatesRequest,
	null,
	Error
>({
	api: Api.Templates.updateCompanyTemplates,
	async: updateCompanyTemplatesAsync
});
