export type Size = 'xs' | 'sm' | 'md' | 'lg';

type AntThemeScheme = 'primary' | 'error' | 'warning' | 'success' | 'info';
export type Placement =
	| 'topRight'
	| 'topLeft'
	| 'rightTop'
	| 'rightBottom'
	| 'bottomLeft'
	| 'bottomRight'
	| 'leftTop'
	| 'leftBottom';

export type ThemeScheme = AntThemeScheme | 'default' | 'standard';

export enum Theme {
	PRIMARY = 'primary',
	ERROR = 'error',
	WARNING = 'warning',
	SUCCESS = 'success',
	INFO = 'info',
	SILENT = 'silent',
	STANDARD = 'standard',
	MAIN = 'main'
}

export type TextColor =
	| ThemeScheme
	| 'silent'
	| 'default'
	| 'main'
	| 'disabled';

export type IconColor =
	| AntThemeScheme
	| 'standard'
	| 'dark'
	| 'darkBlue'
	| 'grayBlue';

export type ButtonType = 'primary' | 'danger' | 'standard';

export enum Align {
	TOP = 'top',
	MIDDLE = 'middle',
	BOTTOM = 'bottom'
}

export enum Justify {
	START = 'start',
	CENTER = 'center',
	END = 'end',
	AROUND = 'around',
	BETWEEN = 'between'
}

export type LayoutDirection = 'horizontal' | 'vertical';
