import { Stage } from 'konva/types/Stage';
import { Vector2d } from 'konva/types/types';

export const getScaledPoint = (stage: Stage, scale: number) => {
	const { x, y } = stage.getPointerPosition() as Vector2d;
	return { x: x / scale, y: y / scale };
};

export const getEmptyRect = (color: string) => ({
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	color
});
