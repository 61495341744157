import actionCreator from '../templatesActionCreator';

import {
	RetrieveCompanyTemplatesRequest,
	RetrieveCompanyTemplatesResponse
} from 'services/api/templates/templatesServiceTypes';

const ACTION_NAME = 'RETRIEVE_COMPANY_TEMPLATES';

export const retrieveCompanyTemplates = actionCreator<
	RetrieveCompanyTemplatesRequest
>(ACTION_NAME);
export const retrieveCompanyTemplatesAsync = actionCreator.async<
	RetrieveCompanyTemplatesRequest,
	RetrieveCompanyTemplatesResponse,
	Error
>(ACTION_NAME);
