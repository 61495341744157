import { AppState } from 'store-types';
import { sortBy } from 'lodash';
import {
	FormData,
	FormDataDetails
} from 'sections/Administration/Configuration/GeneralDetails/GeneralDetailsTypes';
import {
	RetrieveCompanyGeneralConfigurationResponse,
	CompanyGeneralConfigurationDetails,
	CompanyConfigurationEmail,
	CommonSettings,
	ApprovalSettings
} from 'services/api/companies/companiesServiceTypes';
import { FinanceStage } from 'services/api/finance/financeServiceTypes';

const prepareCentralInboxEmailName = (email: string) => {
	return !email ? email : email.substring(0, email.indexOf('@'));
};

const prepareFundingSettings = (
	values: CompanyGeneralConfigurationDetails['fundingSettings']
): FormDataDetails['fundingSettings'] => {
	const { sendFunding, fundingPercentage, fundingStage } = values;
	return {
		sendFunding,
		fundingPercentage: fundingPercentage
			? String(values.fundingPercentage)
			: undefined,
		fundingStage: !fundingStage ? FinanceStage.PDA : fundingStage
	};
};

export const prepareApprovalSettings = (
	values: ApprovalSettings
): ApprovalSettings => ({
	...values,
	levels: sortBy(values.levels || [], ({ level }) => level).map(level => ({
		...level,
		thresholdValue: `${level.thresholdValue ?? ''}`
	})),
	bypassTotalAmount: `${values.bypassTotalAmount ?? '0'}`
});

const prepareCommonSettings = (
	values: CommonSettings
): FormDataDetails['commonSettings'] => ({
	...values,
	pdaApprovalSettings: prepareApprovalSettings(values.pdaApprovalSettings),
	ceApprovalSettings: prepareApprovalSettings(values.ceApprovalSettings),
	daApprovalSettings: prepareApprovalSettings(values.daApprovalSettings)
});

const getEmailEntity = (value: CompanyConfigurationEmail | undefined) => ({
	...(value as CompanyConfigurationEmail),
	email: value?.email || '',
	group: value?.group || { id: '', name: '' },
	emailName: prepareCentralInboxEmailName(value?.email || '')
});

const prepareFormData = (
	_: AppState, // TODO change to helper function
	response: RetrieveCompanyGeneralConfigurationResponse
): FormData => {
	const values = response;
	const {
		details: { fundingSettings, commonSettings }
	} = values;

	return {
		isReadyToGo: values.isReadyToGo,
		details: {
			...values.details,
			emailSettings: {
				...values.details.emailSettings,
				centralInboxEmail: getEmailEntity(
					values.details.emailSettings.centralInboxEmail
				),
				secondaryMailboxes: (
					values.details.emailSettings.secondaryMailboxes || []
				).map(getEmailEntity)
			},
			fundingSettings: prepareFundingSettings(fundingSettings),
			commonSettings: prepareCommonSettings(commonSettings),
			principalIntegrationSettings: {
				generateToken: false,
				...values.details.principalIntegrationSettings
			}
		}
	};
};

export default prepareFormData;
