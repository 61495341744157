import actionCreator from '../configurationActionCreator';
import { FeedbackManagementRequestId } from 'services/api/companies/companiesServiceTypes';

const ACTION_NAME = 'DOWNLOAD_FEEDBACK_MANAGEMENT';
export const downloadFeedbackManagementRequest = actionCreator<
	FeedbackManagementRequestId
>(ACTION_NAME);

export const downloadFeedbackManagementRequestAsync = actionCreator.async<
	FeedbackManagementRequestId,
	null,
	Error
>(ACTION_NAME);
