import { getPage1InitialValues } from './Pages/Page1/page1Selectors';

import { getIsHubPrincipalFieldVisible } from 'store/portJobs/componentSelectors';
import { getIsPortJobFetching } from 'store/portJobs/selectors';
import { WizardFormProps } from 'store/wizardForm/wizardFormState';
import { FormData, PortCallFormValues } from './createPortJobTypes';
import {
	getUserCompanyId,
	getIsCurrentUsersGroupTypeCustomer
} from 'store/auth/selectors';
import { FORM, PORT_JOB_WIZARD_ID } from './createPortJobConstants';
import { AppState } from 'store-types.d';
import { BreadcrumbItem } from 'store/navigation/navigationState';
import { createSelector } from 'reselect';
import {
	getActivePortCall,
	getHubPrincipalFromPortJob,
	getIsPortCallsFetching
} from 'store/portcalls/selectors';
import { getActiveThread } from 'store/thread/selectors';

export const getPortCallForm = (state: AppState) => state.form[FORM.portJob];

export const getPortCallFormValues = createSelector(
	getPortCallForm,
	form => (form ? form.values : {}) as PortCallFormValues
);

export const getBreadcrumbsForCreatePortJobWizard = createSelector(
	getActivePortCall,
	(portCall): BreadcrumbItem[] => {
		const breadcrumbs: BreadcrumbItem[] = [
			{ title: 'Port Calls', link: '/portcalls' }
		];
		if (portCall) {
			breadcrumbs.push({
				title: portCall.vessel.name,
				icon: 'vessel',
				children: [
					{
						title: portCall.port.name,
						icon: 'anchor'
					}
				]
			});
			breadcrumbs.push({
				title: 'Overview'
			});
		}
		const action = !portCall ? 'Create' : 'Add';
		breadcrumbs.push({ title: `${action} new Job` });
		return breadcrumbs;
	}
);

export const getCurrentPortCallPortId = createSelector(
	getPortCallFormValues,
	values => values.portCall.port.key || ''
);

export const getIsEtaSpecified = createSelector(getPortCallFormValues, values =>
	Boolean(values?.portCall?.etaPlt)
);

const getPortJobWizard = (state: AppState): WizardFormProps =>
	state.wizardForm.forms[PORT_JOB_WIZARD_ID];

export const getPortCallInitialValues = createSelector(
	getPage1InitialValues,
	getIsHubPrincipalFieldVisible,
	getUserCompanyId,
	getActiveThread,
	getPortJobWizard,
	getHubPrincipalFromPortJob,
	getIsCurrentUsersGroupTypeCustomer,
	(
		initialValues,
		isHubPrincipalFieldVisible,
		userCompanyId,
		activeThread,
		portJobWizard,
		currentHubPrincipal,
		isCurrentUsersGroupTypeCustomer
	): Partial<FormData> => {
		/**
		 * HubPrincipalField is not visible but
		 * user is on the first page of the wizard
		 */
		if (
			portJobWizard &&
			((!isHubPrincipalFieldVisible && portJobWizard.activePage === 1) ||
				portJobWizard.activePage === 2)
		) {
			const hubPrincipalCompany = !isCurrentUsersGroupTypeCustomer
				? null
				: {
						label: '',
						key: userCompanyId
				  };
			return {
				...initialValues,
				hubPrincipalCompany
			};
		}

		/**
		 * If it's assigning a job
		 */
		if (portJobWizard && portJobWizard.mode === 'assign') {
			/**
			 * If the assignment comes from a Thread
			 */
			if (activeThread) {
				return {
					...initialValues,
					hubPrincipalCompany: {
						label: activeThread.assignedToInbox.name,
						key: activeThread.assignedToInbox.companyId
					}
				};
			}
		}

		/**
		 * If the user is trying to add a new job for
		 * an existing PortCall
		 */
		if (currentHubPrincipal) {
			return {
				...initialValues,
				hubPrincipalCompany: {
					label: currentHubPrincipal.name,
					key: currentHubPrincipal.id
				}
			};
		}

		return initialValues;
	}
);

/**
 * Get Computed values of PortCall Form using a combination of intialValues + formValues
 */
export const getPortCallFormComputedValues = createSelector(
	getPortCallFormValues,
	getPortCallInitialValues,
	(values, initialValues): Partial<FormData> => {
		return {
			...initialValues,
			...values
		};
	}
);

/**
 * Return state of Continue button on the wizard
 */
export const getIsPortJobWizardSubmitBtnDisabled = createSelector(
	getIsPortCallsFetching,
	getIsPortJobFetching,
	getPortJobWizard,
	(isFetchingPortCalls, isPortJobFetching, portJobWizard): boolean => {
		const activePageState = portJobWizard.pages[portJobWizard.activePage];
		return !activePageState.valid || isFetchingPortCalls || isPortJobFetching;
	}
);
