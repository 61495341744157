import { updateCompanyVesselTypeAsync } from '../actions';
import { makeTakeLatestWatcher } from 'utils/sagaHelpers';
import {
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
import Api from 'services/api';

export default makeTakeLatestWatcher<
	UpdateCompanyVesselTypeRequest,
	UpdateCompanyVesselTypeResponse,
	Error
>({
	api: Api.Companies.updateCompanyVesselType,
	async: updateCompanyVesselTypeAsync
});
