import {
	RetrieveThreadByIdParams,
	ThreadItem
} from 'services/api/threads/threadsServiceTypes';
import actionCreator from '../threadActionCreator';

const RETRIEVE_THREAD = 'RETRIEVE_THREAD';

export const retrieveThreadByIdAsync = actionCreator.async<
	RetrieveThreadByIdParams,
	ThreadItem,
	Error
>(RETRIEVE_THREAD);

export const retrieveThreadById = actionCreator<RetrieveThreadByIdParams>(
	RETRIEVE_THREAD
);
