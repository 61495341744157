import { FinanceState } from '../financeState';
import { SetComparisonColumnRequest } from 'services/api/finance/financeServiceTypes';

export function onSetComparisonColumn(
	state: FinanceState,
	action: SetComparisonColumnRequest
): FinanceState {
	const { columnName, serviceFieldName } = action;
	if (!columnName || !serviceFieldName) {
		return state;
	}

	return {
		...state,
		context: {
			...state.context,
			comparisonColumn: {
				...state.context.comparisonColumn,
				[columnName]: serviceFieldName
			}
		}
	};
}
