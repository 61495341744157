import { Success } from 'typescript-fsa';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { GroupsState } from '../groupsState';
import { Group } from 'services/api/groups/groupsServiceTypes';
import { AddGroupRequestPayload } from '../actions';

function doneReducer(
	state: GroupsState,
	{ result }: Success<AddGroupRequestPayload, Group>
): GroupsState {
	return {
		...state,
		byId: {
			...state.byId,
			[result.id]: result
		}
	};
}

export const onAddGroup = makeFetchStatusReducers<
	GroupsState,
	'fetchStatuses',
	AddGroupRequestPayload,
	Group
>('fetchStatuses', 'adding', {
	doneReducer
});
