import axios from 'axios';
import { Action } from 'typescript-fsa';
import { channel, buffers } from 'redux-saga';
import { call, put, take, fork, cancelled } from 'redux-saga/effects';
import formatBytes from 'utils/formatBytes';

import {
	// Config
	retrieveRelativeDocumentConfig,
	retrieveRelativeDocumentConfigAsync,

	// Async Action
	uploadDocumentAsync,
	uploadDocumentProgress
} from '../actions';

import {
	UploadDocumentRequest,
	DocumentUploadConfigurationForm
} from 'services/api/documents/documentsServiceTypes';

const async = uploadDocumentAsync;

// Token to cancel upload request
let cancelToken = axios.CancelToken.source().cancel;

const uploadProgressChannel = channel(buffers.sliding());

const uploadProgressChannelTask = function*() {
	while (true) {
		const action = yield take(uploadProgressChannel);
		yield put(action);
	}
};

export const worker = function*({ payload }: Action<UploadDocumentRequest>) {
	try {
		const { type, request } = payload;

		// TODO
		// Eventually merge into one action
		yield put(retrieveRelativeDocumentConfig({ type }));

		// Wait for config
		const relativeConfigAction = yield take(
			retrieveRelativeDocumentConfigAsync.done
		);
		const relativeConfig = relativeConfigAction.payload.result;

		const { file } = request;

		yield put(async.started(payload));

		// Before Upload Validation

		if (file.size >= relativeConfig.maxFileSize) {
			const errorMessage = `File size exceeds maximum allowed size of ${formatBytes(
				relativeConfig.maxFileSize
			)}. Please select a different file.`;

			yield put(
				async.failed(
					{
						params: payload,
						error: new Error(errorMessage)
					},
					{
						failed: {
							omit: payload.showProgress
						}
					}
				)
			);
			return;
		}

		// Before Upload Validation

		try {
			// Begin with queue tracking
			yield fork(uploadProgressChannelTask);

			const formData = new FormData();
			const form = relativeConfig.form;
			if (form) {
				Object.keys(form).forEach(
					(key: keyof DocumentUploadConfigurationForm) => {
						if (key === 'key') {
							formData.append('key', form.key.replace('{filename}', file.name));
							return;
						}
						formData.append(key, form[key]);
					}
				);
				formData.append('file', file as any); // eslint-disable-line @typescript-eslint/no-explicit-any
			}

			// eslint-disable-next-line @typescript-eslint/unbound-method
			yield call(axios.post, relativeConfig.url, formData, {
				cancelToken: new axios.CancelToken(c => (cancelToken = c)),
				onUploadProgress: (ev: { total: number; loaded: number }) => {
					const percent = Math.round((ev.loaded / ev.total) * 100);
					uploadProgressChannel.put(uploadDocumentProgress(percent));
				}
			});

			const document = {
				id: relativeConfig.id,
				name: file.name,
				documenttype: relativeConfig.documentType,
				contenttype: file.type,
				size: file.size
			};

			yield put(
				async.done({
					params: payload,
					result: document,
					response: null
				})
			);
		} catch (error) {
			yield put(
				async.failed({
					params: payload,
					error: new Error('File upload failed. Please try again')
				})
			);
		}
	} finally {
		if (cancelled()) {
			cancelToken();
		}
	}
};
