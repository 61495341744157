import actionCreator from 'store/finance/financeActionCreator';
import {
	ConvertDBStatusRequest,
	PortJobService
} from 'services/api/finance/financeServiceTypes';
const CONVERT_TO_DIRECT_BILL = 'CONVERT_TO_DIRECT_BILL';
const CONVERT_DB_STATUS_MODAL_OPEN = 'CONVERT_DB_STATUS_MODAL_OPEN';
const CONVERT_DB_STATUS_MODAL_CLOSE = 'CONVERT_DB_STATUS_MODAL_CLOSE';

export const convertToDirectBill = actionCreator<{
	jobServiceId: string;
	comment: string;
}>(CONVERT_TO_DIRECT_BILL);
export const convertToDirectBillAsync = actionCreator.async<
	ConvertDBStatusRequest,
	PortJobService,
	Error
>(CONVERT_TO_DIRECT_BILL, {
	templateParams: {
		entityName: 'Service'
	}
});

interface ConvertDBStatusModalData {
	jobServiceId: string;
}

export const openConvertDBStatusModal = actionCreator<ConvertDBStatusModalData>(
	CONVERT_DB_STATUS_MODAL_OPEN
);
export const closeConvertDBStatusModal = actionCreator(
	CONVERT_DB_STATUS_MODAL_CLOSE
);
