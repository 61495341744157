import * as React from 'react';
import classNames from 'classnames';

import { Title } from 'components';

import styles from '../PageHeader.module.scss';

const PageSubHeader: React.FC<{
	className?: string;
	componentClass?: string;
}> = ({ children, componentClass, ...props }) => {
	// TODO review
	// see https://github.com/Microsoft/TypeScript/issues/28768
	// Note: changed during TS upgrade
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Header = componentClass ? componentClass : ('header' as any);
	return (
		<Header className={classNames(styles.subtitle, props.className)} {...props}>
			<Title.H2 marginBottom={0}>{children}</Title.H2>
		</Header>
	);
};

export default PageSubHeader;
