import { call, put, takeLatest, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import Api from 'services/api';
import {
	deleteCostSavings as action,
	deleteCostSavingsAsync as async
} from '../actions/deleteCostSavings';
import { DeleteCostSavingsRequest } from 'services/api/savings/savingsServiceTypes';

import { Action } from 'typescript-fsa';
import { notify } from 'store/notifications/actions';
import { getActivePortCallId } from 'store/portcalls/portCallsSelectors';
import { getActivePortJobCode } from 'store/portJobs/portJobsSelectors';
import { resetCostSavingsContext, retrieveSavings } from '../actions';
import { closeModal } from 'store/modals/actions';
import { DELETE_SAVINGS_MODAL } from 'sections/PortCall/Savings/SavingsGrid/Constants';

export function* deleteSavingsWorker({
	payload
}: Action<DeleteCostSavingsRequest>): SagaIterator {
	yield put(async.started(payload));
	try {
		const response = yield call(Api.Savings.deleteCostSavings, payload);
		const portCallId = yield select(getActivePortCallId);
		const jobCode = yield select(getActivePortJobCode);

		yield put(
			async.done({
				result: response.data,
				params: payload,
				response
			})
		);
		yield put(retrieveSavings({ portCallId, jobCode }));
	} catch (error) {
		yield put(
			notify.error('Error occurred while delete cost Savings information')
		);
		yield put(
			async.failed({
				error,
				params: payload
			})
		);
	} finally {
		yield put(resetCostSavingsContext());
		yield put(closeModal(DELETE_SAVINGS_MODAL));
	}
}

export function* deleteSavingsWatcher(): SagaIterator {
	yield takeLatest(action.type, deleteSavingsWorker);
}
