import * as React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'components/antd';
import { isModalVisible } from 'store/modals/selectors';
import { closeModal } from 'store/modals/actions';
import { CommonActionModalProps } from './ActionModalTypes';
import { changeCustodyRole } from 'store/portJobs/actions';
import { AppState } from 'store-types';

interface ChangeCustodyRoleModalProps extends CommonActionModalProps {
	jobCode: string;
	portCallId: string;
	modalId: string;
	// from mapStateToProps
	visible: boolean;
	// from mapDispatchToProps
	closeModal: typeof closeModal;
	changeCustodyRole: typeof changeCustodyRole;
}

class ChangeCustodyRoleModal extends React.PureComponent<
	ChangeCustodyRoleModalProps
> {
	hideConfirmationModal = () => {
		const { modalId } = this.props;
		this.props.closeModal(modalId);
	};

	onConfirmChangingCustodyRole = () => {
		const { modalId } = this.props;
		this.props.changeCustodyRole({ modalId });
	};

	render() {
		const { visible } = this.props;
		return (
			<Modal
				destroyOnClose
				visible={visible}
				okText="Confirm"
				closable={false}
				cancelText="Cancel"
				onCancel={this.hideConfirmationModal}
				onOk={this.onConfirmChangingCustodyRole}
				title={'Changing custody role'}
			>
				<p>
					You're going to upgrade agent's custody role to Full. This action
					cannot be reversed.
				</p>
				<p>Do you want to proceed?</p>
			</Modal>
		);
	}
}

export default connect(
	(
		state: AppState,
		ownProps: Pick<ChangeCustodyRoleModalProps, 'modalId'>
	) => ({
		visible: isModalVisible(state, ownProps.modalId)
	}),
	{
		closeModal,
		changeCustodyRole
	}
)(ChangeCustodyRoleModal);
