import { Success, Failure } from 'typescript-fsa';
import { PortCallOperationsState } from '../portCallOperationsState';
import { FetchStatus } from 'services/api/apiTypes';
import { UpdatePortCallEventJobAssignmentRequest } from 'services/api/portCall/portCallServiceTypes';
import { AxiosError } from 'axios';
import { DEFAULT_UPDATE_CONFLICT_CODE } from 'app-constants';
import { isHandledCode } from 'utils';

export const onUpdatePortCallEventJobAssignmentStarted = (
	state: PortCallOperationsState,
	{ eventId }: UpdatePortCallEventJobAssignmentRequest
) => ({
	...state,
	updateStatuses: {
		...state.updateStatuses,
		events: { ...state.updateStatuses.events, [eventId]: FetchStatus.PENDING }
	}
});

export const onUpdatePortCallEventJobAssignmentSuccess = (
	state: PortCallOperationsState,
	{
		params: { portCallId, eventId, portJobs }
	}: Success<UpdatePortCallEventJobAssignmentRequest, void>
): PortCallOperationsState => {
	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: {
						...state.eventSectionsByPortCallId[portCallId].eventsById[eventId],
						portJobs: portJobs.map(portJob => ({
							id: portJob.code,
							name: portJob.customerCompany.name
						}))
					}
				}
			}
		},
		updateStatuses: {
			...state.updateStatuses,
			events: {
				...state.updateStatuses.events,
				[eventId]: FetchStatus.SUCCESS
			}
		}
	};
};

export const onUpdatePortCallEventJobAssignmentFailed = (
	state: PortCallOperationsState,
	action: Failure<UpdatePortCallEventJobAssignmentRequest, AxiosError>
): PortCallOperationsState => {
	const {
		params: { portCallId, eventId }
	} = action;
	const response = action.error.response;
	const error = response ? response.data : action.error;
	const status = response?.status || 0;
	const skipErrorObject = isHandledCode(status, [DEFAULT_UPDATE_CONFLICT_CODE]);
	return {
		...state,
		eventSectionsByPortCallId: {
			...state.eventSectionsByPortCallId,
			[portCallId]: {
				...state.eventSectionsByPortCallId[portCallId],
				eventsById: {
					...state.eventSectionsByPortCallId[portCallId].eventsById,
					[eventId]: {
						...state.eventSectionsByPortCallId[portCallId].eventsById[eventId],
						error: (skipErrorObject && {}) || error
					}
				}
			}
		},
		updateStatuses: {
			...state.updateStatuses,
			events: { ...state.updateStatuses.events, [eventId]: FetchStatus.FAILURE }
		}
	};
};
