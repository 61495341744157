import { all, fork } from 'redux-saga/effects';

import retrieveCompanyTemplatesWatcher from './retrieveCompanyTemplatesSaga';
import updateCompanyTemplatesWatcher from './updateCompanyTemplatesSaga';
import { retrieveTemplateWatcher } from './retrieveTemplateSagas';
import { retrieveTemplatesWatcher } from './retrieveTemplatesSagas';
import { retrieveTemplateRenderWatcher } from './retrieveTemplateRenderSagas';

export default function*() {
	yield all([
		fork(retrieveCompanyTemplatesWatcher),
		fork(updateCompanyTemplatesWatcher),
		fork(retrieveTemplatesWatcher),
		fork(retrieveTemplateWatcher),
		fork(retrieveTemplateRenderWatcher)
	]);
}
