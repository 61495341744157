import actionCreator from '../vesselsActionCreator';
import { VesselDetails } from 'services/api/vessels/vesselsServiceTypes';

const ACTION_NAME = 'RETRIEVE_VESSEL';
const ACTION_NAME_SECTION = 'RETRIEVE_VESSEL_SECTION';

export const retrieveVesselAsync = actionCreator.async<
	string,
	VesselDetails,
	Error
>(ACTION_NAME);

export const retrieveVesselSectionAsync = actionCreator.async<
	string,
	VesselDetails,
	Error
>(ACTION_NAME_SECTION, {
	behaviour: { type: 'section' }
});

export const retrieveVessel = actionCreator<string>(ACTION_NAME);
export const retrieveVesselSection = actionCreator<string>(ACTION_NAME_SECTION);
