import { FetchStatus } from 'services/api/apiTypes';
import { SavingsState } from '../savingsState';

export function onAddCostSavingsStated(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addCostSavings: FetchStatus.PENDING
		}
	};
}

export function onAddCostSavingsSuccess(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addCostSavings: FetchStatus.SUCCESS
		}
	};
}

export function onAddCostSavingsFailure(state: SavingsState): SavingsState {
	return {
		...state,
		fetchStatuses: {
			...state.fetchStatuses,
			addCostSavings: FetchStatus.FAILURE
		}
	};
}
