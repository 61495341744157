import { AppState } from 'store-types.d';
import { createSelector } from 'reselect';
import { getUserType } from 'store/auth/selectors';
import { UserType } from 'services/api/users/userServiceTypes';

const getCreationPortJobPermissions = (state: AppState) =>
	state.portjob.context.creationPortJobPermissions;

export const isCreationPortJobFirstStepPermissionError = createSelector(
	[getCreationPortJobPermissions, getUserType],
	(creationPortJobPermissions, currentUserType) => {
		return (
			currentUserType === UserType.HUB &&
			(!creationPortJobPermissions || !creationPortJobPermissions.hasPermission)
		);
	}
);

export const isCreationPortJobSecondStepPermissionError = createSelector(
	[getCreationPortJobPermissions, getUserType],
	(creationPortJobPermissions, currentUserType) => {
		return (
			currentUserType === UserType.PRINCIPAL &&
			(!creationPortJobPermissions || !creationPortJobPermissions.hasPermission)
		);
	}
);
