import { call, put, select, race, take } from 'redux-saga/effects';
import {
	getPortJobsForActivePortCall,
	getActivePortCall
} from 'store/portcalls/selectors';
import { navigateTo } from 'utils';
import { setActivePortJobCode } from '../actions';
import { retrievePortCall } from 'store/portcalls';
import {
	retrievePortCallAsync,
	resetActivePortCallId
} from 'store/portcalls/actions';

function* goToPortCalls() {
	yield call(navigateTo, '/portcalls');
	yield put(resetActivePortCallId);
}

// it guarantee redirection to valid job, or in expection case goes to portcalls
export const safeRedirectToPortCallOverview = function*(
	portCallId: string,
	portJobCode?: string
) {
	const activePortCall = yield select(getActivePortCall);
	if (!activePortCall) {
		// no active prtcall it means no jobs in the state
		// jobs needs to be fetched
		yield put(retrievePortCall({ id: portCallId, expand: 'PortJobsBasic' }));

		const { portCallFailure } = yield race({
			portCallFailure: take(retrievePortCallAsync.failed),
			portCallSuccess: take(retrievePortCallAsync.done)
		});

		if (portCallFailure) {
			return; // redirection already in retrievePortCall
		}
	}
	const portJobs = yield select(getPortJobsForActivePortCall);
	if (!portJobs.length) {
		yield call(goToPortCalls);
	} else {
		const jobCode = portJobCode || portJobs[0].code; // default first job
		yield put(setActivePortJobCode(jobCode));
		yield call(navigateTo, `/portcalls/${portCallId}/jobs/${jobCode}/overview`);
	}
};
