import {
	PatchThreadPermissionsRequest,
	PatchThreadPermissionsResponse
} from 'services/api/threads/threadsServiceTypes';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import { ThreadReducerState } from './../threadState';
import { Success, Failure } from 'typescript-fsa';

const onPatchThreadPermissionsFailed = (
	state: ThreadReducerState,
	_action: Failure<PatchThreadPermissionsRequest, Error>
): ThreadReducerState => {
	return {
		...state,
		newPermissions: state.item ? [...state.item.permissions] : []
	};
};

const onPatchThreadPermissionsDone = (
	state: ThreadReducerState,
	action: Success<PatchThreadPermissionsRequest, PatchThreadPermissionsResponse>
): ThreadReducerState => {
	if (state.item === null) {
		return state;
	}
	/** Update existing Thread (item) permissions */
	return {
		...state,
		item: {
			...state.item,
			permissions: action.result.elements
		}
	};
};

export const onPatchThreadPermissions = makeFetchStatusReducers<
	ThreadReducerState,
	'fetchStatuses',
	PatchThreadPermissionsRequest,
	PatchThreadPermissionsResponse
>('fetchStatuses', 'updating', {
	failedReducer: onPatchThreadPermissionsFailed,
	doneReducer: onPatchThreadPermissionsDone
});
