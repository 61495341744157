import actionCreator from '../companyVesselTypesActionCreator';
import {
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse
} from 'services/api/companies/companiesServiceTypes';
const ACTION_NAME = 'DELETE';

export const deleteCompanyVesselTypeAsync = actionCreator.async<
	DeleteCompanyVesselTypeRequest,
	DeleteCompanyVesselTypeResponse,
	Error
>(ACTION_NAME, {
	templateParams: {
		entityName: 'Vessel Type'
	}
});

export const deleteCompanyVesselType = actionCreator<
	DeleteCompanyVesselTypeRequest
>(ACTION_NAME);
