import { makeTakeLatestWatcher } from 'utils/sagaHelpers/sagaHelpers';
import { Success } from 'typescript-fsa';
import { put, select, call } from 'redux-saga/effects';
import Api from 'services/api';
import {
	addThreadMessageAsync,
	retrieveThreadEvents
} from 'store/thread/actions';
import {
	AddThreadMessageRequest,
	AddThreadMessageResponse
} from 'services/api/messages/messagesServiceTypes';
import { isFunction } from 'lodash';
import { getAvailableThreadStatus } from '../threadUtils';
import { retrieveThreadsPendingAlerts } from '../../threads/actions/retrieveThreadsPendingAlerts';
import { getActivePortCallId } from '../../portcalls/portCallsSelectors';
import Matomo from 'services/analytics/adapters/Matomo';

export default makeTakeLatestWatcher<
	AddThreadMessageRequest,
	AddThreadMessageResponse,
	Error
>({
	async: addThreadMessageAsync,
	api: Api.Messages.createThreadMessage,
	*getApiParams(params: AddThreadMessageRequest) {
		return {
			...params,
			message: {
				...params.message,
				updateThreadStatus: getAvailableThreadStatus(
					params.message.updateThreadStatus
				)
			}
		} as AddThreadMessageRequest;
	},
	*onSuccess(
		action: Success<AddThreadMessageRequest, AddThreadMessageResponse>
	) {
		yield call(Matomo.trackEvent, {
			category: 'UserEvent',
			action: 'Messages.NewThreadMessage',
			name: `Added thread message for thread ${action.params.threadId}`,
			value: 1
		});
		/**
		 * re-request pending alerts if the thread status
		 * has been changed after sent message
		 */
		const portCallId = yield select(getActivePortCallId);
		yield put(retrieveThreadsPendingAlerts(portCallId));

		if (!isFunction(action.params.onSuccess)) {
			return;
		}
		action.params.onSuccess(action.result);
		if (!action.params.message.mustStartNewThread) {
			// Get the list of events with the new one added above
			// if it is not new thread
			yield put(
				retrieveThreadEvents({
					threadId: action.params.threadId,
					retrieveLatest: true
				})
			);
		}
	}
});
