import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { AutoCompleteDrafts } from 'components/Drafts';
import InfoAgentPopover from 'sections/PortCall/PortJob/Info/InfoAgentPopover/InfoAgentPopover';
import {
	DraftType,
	CompanyDraft,
	NewCompanyDraft,
	DraftKind
} from 'store/drafts/draftsState';
import { OrganisationType } from 'services/api/companies/companiesServiceTypes';
import { getCompanyDraftsByType } from 'store/drafts/selectors';
import { SearchCompaniesFunc, HandleOnDraftClick } from './types';
import { AppState } from 'store-types';
import { CompanyAgentAutocompleteOption } from 'services/api/ports/portsServiceTypes';

interface AgentFieldProps {
	disabled?: boolean;
	drafts: CompanyDraft[];
	search: SearchCompaniesFunc;
	name: string;
	label: string;
	required?: boolean;
	withParentId: boolean;
	onAddDraftClick: HandleOnDraftClick;
	disabledKeys?: string[];
	allowClear?: boolean;
	className?: string;
	onlyShowNewDrafts?: boolean;
}

const AgentField = ({
	disabled = false,
	drafts,
	withParentId,
	search,
	onAddDraftClick,
	name,
	label,
	required = false,
	disabledKeys,
	allowClear,
	className,
	onlyShowNewDrafts = false
}: AgentFieldProps) => {
	// Computation of `draftOptions` was moved from other component as is
	// Type assertions were used here to overcome types conflicts
	// Good candidate for refactoring of interfaces
	const draftOptions: CompanyAgentAutocompleteOption[] = useMemo(() => {
		return !onlyShowNewDrafts
			? ((drafts as NewCompanyDraft[]).map(draft => {
					if (draft.isDraft) {
						return {
							id: draft.id,
							name: draft.name,
							companyEmail: draft.email,
							companyTel: draft.telephone,
							address: {
								countryName: draft.countryCode,
								city: draft.city
							}
						};
					}
					return draft;
			  }) as CompanyAgentAutocompleteOption[])
			: (((drafts as NewCompanyDraft[]).filter(draft => {
					if (draft.isDraft && draft.kind === DraftKind.NEW) {
						return {
							id: draft.id,
							name: draft.name,
							companyEmail: draft.email,
							companyTel: draft.telephone,
							address: {
								countryName: draft.countryCode,
								city: draft.city
							}
						};
					} else {
						return false;
					}
			  }) as unknown) as CompanyAgentAutocompleteOption[]);
	}, [drafts, onlyShowNewDrafts]);

	const customOption = useCallback((option: CompanyAgentAutocompleteOption) => {
		return <InfoAgentPopover data={option} />;
	}, []);

	return (
		<AutoCompleteDrafts.ReduxFormItem
			name={name}
			label={label}
			disabled={disabled}
			required={required}
			drafts={draftOptions}
			disabledKeys={disabledKeys}
			onSearch={search(
				{
					organisationTypes: [OrganisationType.LPA, OrganisationType.ISS_LPA]
				},
				withParentId
			)}
			addDraftLinkTitle={'Add Agent Manually'}
			onAddDraftClick={() => onAddDraftClick(DraftType.AGENT, name)}
			getCustomOption={customOption}
			allowClear={allowClear}
			className={className}
		/>
	);
};
export default connect((state: AppState) => ({
	drafts: getCompanyDraftsByType(state, DraftType.AGENT)
}))(AgentField);
