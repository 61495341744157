import React, { FC, useMemo } from 'react';
import { Text } from 'components';
import { FormattedTime } from 'containers';
import { TooltipProps } from 'antd/lib/tooltip';
import FormattedTimeTooltip from './FormattedTimeTooltip';

export interface LocalDateAndTimeTooltipProps {
	value: string;
	portTimeZoneId: string;
	convertFromUtc?: boolean;
	tooltipProps?: Partial<TooltipProps>;
}

const LocalDateAndTimeTooltip: FC<LocalDateAndTimeTooltipProps> = ({
	value,
	portTimeZoneId,
	convertFromUtc,
	tooltipProps
}) => {
	const title = useMemo(
		() => (
			<>
				Your local date & time:
				<br />
				<Text weight="medium">
					<FormattedTime
						value={value}
						portTimeZoneId={portTimeZoneId}
						convertFromPlt={!convertFromUtc}
						convertFromUtc={convertFromUtc}
						timeOffset
					/>
				</Text>
			</>
		),
		[value, portTimeZoneId, convertFromUtc]
	);

	return <FormattedTimeTooltip title={title} {...tooltipProps} />;
};

export default LocalDateAndTimeTooltip;
