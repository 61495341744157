import {
	AddThreadMessageRequest,
	AddThreadMessageResponse
} from 'services/api/messages/messagesServiceTypes';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

const ADD_THREAD_MESSAGE = 'thread/ADD_THREAD_MESSAGE';

export const addThreadMessageAsync = actionCreator.async<
	AddThreadMessageRequest,
	AddThreadMessageResponse,
	Error
>(ADD_THREAD_MESSAGE, {
	behaviour: { type: 'react-to-changes-child' }
});

export const addThreadMessage = actionCreator<AddThreadMessageRequest>(
	ADD_THREAD_MESSAGE
);
